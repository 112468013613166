import React from 'react';
import BusComponent from '../BusComponent';
import classNames from 'classnames';
import { placements } from '../../helpers/MenuPlacement';
import AppConfig from '../../appConfig';

import RcTrigger from 'rc-trigger';

class DropdownMenu extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            container: document.querySelector(`#${context.container}`),
            destroyOnHide: !!props.destroyOnHide,
            modalClasses: undefined,
        };
        this.bindGluBusEvents({
            CLOSE_DROPDOWN: this.onCloseDropdown,
        });
        this.isVisible = false;
        this.boundOnModalState = this.onModalState;
    }

    componentWillMount() {
        this.setState({
            position: this.props.position || 'bottomLeft',
            fixed: this.props.fixed || false,
        });
        this.bus.once('MODAL_STATE', this.boundOnModalState);
        this.emit('GET_MODAL_STATE');
    }

    componentWillUnmount() {
        this.bus.off('MODAL_STATE', this.boundOnModalState);
        this.unbindGluBusEvents();
    }

    onCloseDropdown() {
        if (this.button && this.isVisible) {
            window.requestAnimationFrame(() => {
                this.button.click();
            });
        }
    }

    onModalState = eventMap => {
        if (eventMap.visible) {
            this.setState({
                modalClasses: 'dropdown__menu--modal',
            });
        }
    }

    onVisibleChange = visible => {
        if (this.props.onVisibleChange) {
            this.props.onVisibleChange(visible);
        }
        this.isVisible = visible;
    };

    onAnchorClick = () => AppConfig.sentryRecordClick(`dropdown menu [${this.props.name}]`);

    render() {
        const dropdownClasses = classNames('dropdown', this.props.dropdownClasses, { disabled: this.props.disabled !== undefined ? this.props.disabled : false });
        if (this.props.pinned) {
            return (<div className={dropdownClasses}>
                <RcTrigger
                    popupVisible
                    action="click"
                    prefixCls="dropdown__menu"
                    popupClassName={classNames('flex-column', this.props.className, this.state.modalClasses)}
                    popup={this.props.children}
                    mouseEnterDelay={1}
                    destroyPopupOnHide={this.state.destroyOnHide}
                    mouseLeaveDelay={0.1}
                    popupAlign={this.props.dropdownAlign}
                    popupPlacement={this.state.position}
                    getPopupContainer={() => this.state.container}
                    builtinPlacements={placements}
                    onPopupVisibleChange={this.onVisibleChange}
                >
                    <div
                        ref={btn => { this.button = btn; }}
                        className="dropdown__button"
                        onClick={this.onAnchorClick}
                    >
                        {this.props.anchor}
                    </div>
                </RcTrigger>
            </div>);
        }
        return (<div className={dropdownClasses}>
            <RcTrigger
                action="click"
                prefixCls="dropdown__menu"
                popupClassName={classNames('flex-column', this.props.className, this.state.modalClasses)}
                popup={this.props.children}
                mouseEnterDelay={1}
                destroyPopupOnHide={this.state.destroyOnHide}
                mouseLeaveDelay={0.1}
                popupAlign={this.props.dropdownAlign}
                popupPlacement={this.state.position}
                getPopupContainer={() => this.state.container}
                builtinPlacements={placements}
                onPopupVisibleChange={this.onVisibleChange}
            >
                <div
                    ref={btn => { this.button = btn; }}
                    className="dropdown__button"
                    onClick={this.onAnchorClick}
                >
                    {this.props.anchor}
                </div>
            </RcTrigger>
        </div>);
    }
}

export default DropdownMenu;
