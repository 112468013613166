import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import AvailableYearsLinks from './AvailableYearsLinks';

const UnavailableDataCategory = ({ dataCategory, className, onSelectedDataCategoryChange }) => (<div
    className={classnames('data-category flex-it flex-start center no-shrink', className)}
>
    <img
        className="data-category__icon"
        src={dataCategory.iconUrl}
        alt="Data category icon"
    />
    <div className="data-category__info flex-it column">
        <h5 className="data-category__title">{dataCategory.name}</h5>
        <AvailableYearsLinks
            onSelectedDataCategoryChange={onSelectedDataCategoryChange}
            dataCategory={dataCategory}
        />
    </div>
</div>);

UnavailableDataCategory.propTypes = {
    className: PropTypes.string,
    dataCategory: PropTypes.object.isRequired,
    onSelectedDataCategoryChange: PropTypes.func,
    available: PropTypes.bool,
};

UnavailableDataCategory.defaultProps = {
    className: '',
    onSelectedDataCategoryChange: undefined,
    available: false,
};

export default UnavailableDataCategory;
