import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import RcSlider from 'rc-slider';

const Handle = RcSlider.Handle;

const HandleWithTooltip = props => {
    const { value, dragging, index, ...restProps } = props;
    const tooltipClasses = classnames('slider-tooltip', { 'slider-tooltip--active': dragging });
    return (
        <Handle key={index} value={value} {...restProps}>
            <div className={tooltipClasses}>
                <div className="slider-tooltip__value">{value}</div>
            </div>
        </Handle>
    );
};

HandleWithTooltip.propTypes = {
    dragging: PropTypes.bool.isRequired,
    value: PropTypes.number.isRequired,
    restProps: PropTypes.any.isRequired,
    index: PropTypes.number.isRequired,
};

class Slider extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            value: props.value !== undefined ? props.value : props.min,
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            value: nextProps.value !== undefined ? nextProps.value : nextProps.min,
        });
    }

    onChange = value => {
        this.setState({
            value,
        });
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    renderValueLabel() {
        if (this.props.showValueLabel) {
            let valueLabel = this.state.value;
            if (this.props.valueLabelFormatter) {
                valueLabel = this.props.valueLabelFormatter(this.state.value);
            }
            return <span className="slider__value">{valueLabel}</span>;
        }
        return null;
    }

    renderLabel() {
        const labelClasses = classnames('body-1 slider__label', this.props.labelClassName);
        if (this.props.label) {
            return <span className={labelClasses}>{this.props.label}</span>;
        }
        return null;
    }

    render() {
        const { className, vertical, disabled, min, max, step, tooltip } = this.props;
        const { value } = this.state;

        const classes = classnames('slider flex-it center space-between no-shrink', className, {
            'slider--vertical column': this.props.vertical,
            'slider--disabled': this.props.disabled,
        });
        return (<div className={classes}>
            {this.renderLabel()}
            <div className={classnames('slider__control-wrapper grow flex-it center flex-end', { column: vertical })}>
                <RcSlider
                    className="slider__control"
                    disabled={disabled}
                    vertical={vertical}
                    value={value}
                    min={min}
                    max={max}
                    step={step}
                    handle={tooltip ? HandleWithTooltip : undefined}
                    onChange={this.onChange}
                />
                {this.renderValueLabel()}
            </div>
        </div>);
    }
}

Slider.propTypes = {
    className: PropTypes.string,
    labelClassName: PropTypes.string,
    disabled: PropTypes.bool,
    step: PropTypes.number,
    max: PropTypes.number.isRequired,
    min: PropTypes.number.isRequired,
    value: PropTypes.number,
    label: PropTypes.string,
    onChange: PropTypes.func,
    valueLabelFormatter: PropTypes.func,
    showValueLabel: PropTypes.bool,
    tooltip: PropTypes.bool,
    vertical: PropTypes.bool,
};

Slider.defaultProps = {
    className: '',
    labelClassName: '',
    disabled: false,
    value: undefined,
    step: 1,
    label: undefined,
    onChange: undefined,
    valueLabelFormatter: undefined,
    showValueLabel: false,
    tooltip: false,
    vertical: false,
};

export default Slider;
