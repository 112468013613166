import React from 'react';
import { injectIntl } from 'react-intl';
import BusComponent from '../BusComponent';
import Checkbox from '../form/Checkbox';
import Slider from '../form/Slider';

class MapSatelliteOptions extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            isColorSatellite: !!props.mapInstance.satelliteDataOverlaySatelliteHasColor,
            dataOpacity: props.mapInstance.satelliteDataOverlayDataOpacity * 100,
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            isColorSatellite: !!nextProps.mapInstance.satelliteDataOverlaySatelliteHasColor,
            dataOpacity: nextProps.mapInstance.satelliteDataOverlayDataOpacity * 100,
        });
    }

    onToggleColorSatellite = () => {
        this.emit('TOGGLE_SATELLITE_DATA_OVERLAY_SATELLITE_COLOR_REQUEST', {
            mapInstanceId: this.props.mapInstance.id,
        });
        this.setState({
            isColorSatellite: !this.state.isColorSatellite,
        });
    }

    onDataOpacityChanged = opacity => {
        this.emit('SET_SATELLITE_DATA_OVERLAY_DATA_OPACITY_REQUEST', {
            mapInstanceId: this.props.mapInstance.id,
            opacity: opacity / 100,
        });
        this.setState({
            dataOpacity: opacity,
        });
    }

    render() {
        return (<div className="satellite-options flex-it column">
            <div className="satellite-options__section">
                <Slider
                    className="slider--map"
                    label={this.props.intl.formatMessage({ id: 'dataBrowser.dataOpacity' })}
                    value={this.state.dataOpacity}
                    max={100}
                    min={0}
                    step={10}
                    onChange={this.onDataOpacityChanged}
                    showValueLabel
                    valueLabelFormatter={value => `${value}%`}
                />
            </div>
            <div className="satellite-options__section flex-it center space-between">
                <span className="satellite-options__section-title">
                    {this.props.intl.formatMessage({ id: 'dataBrowser.satelliteColor' })}
                </span>
                <Checkbox
                    checked={this.state.isColorSatellite}
                    onCheck={this.onToggleColorSatellite}
                />
            </div>
        </div>);
    }
}

export default injectIntl(MapSatelliteOptions);
