const MapExportAspectRatio = {
    FREEHAND: {
        value: 'free',
        factor: undefined,
        label: 'free',
        primaryText: 'Free',
    },
    SQUARE: {
        value: '1:1',
        factor: 1,
        label: '1:1',
        primaryText: 'Square (1:1)',
    },
    FOUR_THREE: {
        value: '4/3',
        factor: (4 / 3),
        label: '4:3 (L)',
        primaryText: 'Normal (4:3) - Landscape',
    },
    THREE_FOUR: {
        value: '3/4',
        factor: (3 / 4),
        label: '4:3 (P)',
        primaryText: 'Normal (4:3) - Portrait',
    },
    SIXTEEN_NINE: {
        value: '16/9',
        factor: (16 / 9),
        label: '16:9 (L)',
        primaryText: 'Widescreen (16:9) - Landscape',
    },
    NINE_SIXTEEN: {
        value: '9/16',
        factor: (9 / 16),
        label: '16:9 (P)',
        primaryText: 'Widescreen (16:9) - Portrait',
    },
};

export default MapExportAspectRatio;
