import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import BusComponent from '../../BusComponent';
import withReportAvailabilityCheck from '../../../hoc/ReportAvailabilityProvider';
import withVariableSelectionMetadataSurvey from '../../../hoc/VariableSelectionMetadataSurveyProvider';

class CreateReportMenuItem extends BusComponent {
    onClick = () => {
        this.emit('ENTER_REPORT_MODE', {
            mapInstanceId: this.props.mapInstance.id,
        });
    };

    render() {
        const { available, mapInstance, survey, icon, labelId } = this.props;
        const { isChangeOverTimeApplied } = mapInstance.dataTheme;
        const disabled =
            isChangeOverTimeApplied ||
            !survey.reportsAsArray.length ||
            !available;

        return (
            <div
                className={classNames('material-menu__item', {
                    'material-menu__item--disabled': disabled,
                })}
                onClick={this.onClick}
            >
                <div className="flex-it center">
                    <i
                        className={classNames(
                            'material-menu__item__icon',
                            icon,
                        )}
                    />
                    <FormattedMessage id={labelId} />
                </div>
            </div>
        );
    }
}

CreateReportMenuItem.propTypes = {
    mapInstance: PropTypes.object.isRequired,
    survey: PropTypes.object.isRequired,
    available: PropTypes.bool.isRequired,
    icon: PropTypes.string.isRequired,
    labelId: PropTypes.string.isRequired,
};

export default withVariableSelectionMetadataSurvey(
    withReportAvailabilityCheck(CreateReportMenuItem));
