import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param {object} pram0
 * @param {string} pram0.label
 */
const ClassesItemLabel = ({ label }) => (
    <span className="layer-library-classes__item-label">{label}</span>
);

ClassesItemLabel.propTypes = {
    label: PropTypes.string.isRequired,
};

export default ClassesItemLabel;
