const AnnotationsStyleProperties = {
    'paint_fill-color': 'fill',
    'paint_fill-opacity': 'fill-opacity',
    'paint_line-color': 'stroke',
    'paint_line-width': 'stroke-width',
    'paint_line-opacity': 'stroke-opacity',
    'layout_icon-image': 'marker-symbol',
};

export default AnnotationsStyleProperties;
