'use strict';var ShelfPack = require('@mapbox/shelf-pack');
var ref = require('../util/image');
var RGBAImage = ref.RGBAImage;
var ref$1 = require('./image_atlas');
var imagePosition = ref$1.imagePosition;
var Texture = require('./texture');
var padding = 1;
var ImageManager = function ImageManager() {
    this.images = {};
    this.loaded = false;
    this.requestors = [];
    this.shelfPack = new ShelfPack(64, 64, { autoResize: true });
    this.patterns = {};
    this.atlasImage = RGBAImage.create({
        width: 64,
        height: 64
    });
    this.dirty = true;
};
ImageManager.prototype.isLoaded = function isLoaded() {
    return this.loaded;
};
ImageManager.prototype.setLoaded = function setLoaded(loaded) {
    var this$1 = this;
    if (this.loaded === loaded) {
        return;
    }
    this.loaded = loaded;
    if (loaded) {
        for (var i = 0, list = this$1.requestors; i < list.length; i += 1) {
            var ref = list[i];
            var ids = ref.ids;
            var callback = ref.callback;
            this$1._notify(ids, callback);
        }
        this.requestors = [];
    }
};
ImageManager.prototype.getImage = function getImage(id) {
    return this.images[id];
};
ImageManager.prototype.addImage = function addImage(id, image) {
    this.images[id] = image;
};
ImageManager.prototype.removeImage = function removeImage(id) {
    delete this.images[id];
    var pattern = this.patterns[id];
    if (pattern) {
        this.shelfPack.unref(pattern.bin);
        delete this.patterns[id];
    }
};
ImageManager.prototype.getImages = function getImages(ids, callback) {
    var this$1 = this;
    var hasAllDependencies = true;
    if (!this.isLoaded()) {
        for (var i = 0, list = ids; i < list.length; i += 1) {
            var id = list[i];
            if (!this$1.images[id]) {
                hasAllDependencies = false;
            }
        }
    }
    if (this.isLoaded() || hasAllDependencies) {
        this._notify(ids, callback);
    } else {
        this.requestors.push({
            ids: ids,
            callback: callback
        });
    }
};
ImageManager.prototype._notify = function _notify(ids, callback) {
    var this$1 = this;
    var response = {};
    for (var i = 0, list = ids; i < list.length; i += 1) {
        var id = list[i];
        var image = this$1.images[id];
        if (image) {
            response[id] = image;
        }
    }
    callback(null, response);
};
ImageManager.prototype.getPixelSize = function getPixelSize() {
    return {
        width: this.shelfPack.w,
        height: this.shelfPack.h
    };
};
ImageManager.prototype.getPattern = function getPattern(id) {
    var pattern = this.patterns[id];
    if (pattern) {
        return pattern.position;
    }
    var image = this.getImage(id);
    if (!image) {
        return null;
    }
    var width = image.data.width + padding * 2;
    var height = image.data.height + padding * 2;
    var bin = this.shelfPack.packOne(width, height);
    if (!bin) {
        return null;
    }
    RGBAImage.resize(this.atlasImage, this.getPixelSize());
    var src = image.data;
    var dst = this.atlasImage;
    var x = bin.x + padding;
    var y = bin.y + padding;
    var w = src.width;
    var h = src.height;
    RGBAImage.copy(src, dst, {
        x: 0,
        y: 0
    }, {
        x: x,
        y: y
    }, {
        width: w,
        height: h
    });
    RGBAImage.copy(src, dst, {
        x: 0,
        y: h - 1
    }, {
        x: x,
        y: y - 1
    }, {
        width: w,
        height: 1
    });
    RGBAImage.copy(src, dst, {
        x: 0,
        y: 0
    }, {
        x: x,
        y: y + h
    }, {
        width: w,
        height: 1
    });
    RGBAImage.copy(src, dst, {
        x: w - 1,
        y: 0
    }, {
        x: x - 1,
        y: y
    }, {
        width: 1,
        height: h
    });
    RGBAImage.copy(src, dst, {
        x: 0,
        y: 0
    }, {
        x: x + w,
        y: y
    }, {
        width: 1,
        height: h
    });
    this.dirty = true;
    var position = imagePosition(bin, image);
    this.patterns[id] = {
        bin: bin,
        position: position
    };
    return position;
};
ImageManager.prototype.bind = function bind(gl) {
    if (!this.atlasTexture) {
        this.atlasTexture = new Texture(gl, this.atlasImage, gl.RGBA);
    } else if (this.dirty) {
        this.atlasTexture.update(this.atlasImage);
        this.dirty = false;
    }
    this.atlasTexture.bind(gl.LINEAR, gl.CLAMP_TO_EDGE);
};
module.exports = ImageManager;