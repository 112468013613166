import React from 'react';
import { injectIntl } from 'react-intl';
import BusComponent from '../BusComponent';
import googleTagManagerEvents from '../../helpers/GoogleTagManagerEventsWrapper';
import FrameType from '../../enums/FrameType';
import { switchWarningPopupButtonEvents } from '../../enums/GoogleTagManagerEvents';

const GTMButton = googleTagManagerEvents(
    'button',
    switchWarningPopupButtonEvents,
);

/**
 * @typedef Props
 * @property {string} mapInstanceId
 * @property {string} analysisTypeId
 * @property {import('react-intl').intlShape} intl
 *
 * @typedef State
 * @property {boolean} isSwitching
 *
 * @extends {BusComponent<Props>}
 */
class SingleMapSwitchWarningPopup extends BusComponent {
    constructor(props, context) {
        super(props, context);

        this.state = {
            isSwitching: false,
        };
    }

    componentDidMount() {
        this.setGTMEvents();
    }

    componentDidUpdate() {
        this.setGTMEvents();
    }

    setGTMEvents = () => {
        const { analysisTypeId } = this.props;

        const gtmL = `Creating ${analysisTypeId} analysis, but not a single-map.`;
        if (this.cancelButton) {
            this.cancelButton.setCustomAttribute('gtm-eventl', `${gtmL} Canceled.`);
        }
        if (this.continueButton) {
            this.continueButton.setCustomAttribute('gtm-eventl', `${gtmL} Continued.`);
        }
    }

    onContinue = () => {
        this.setState({ isSwitching: true });
        this.bus.once('MAP_RENDERED', this.onFrameUpdated);
        this.emit('CHANGE_PROJECT_FRAME_TYPE_REQUEST', {
            frameType: FrameType.SINGLE_MAP,
            options: {
                keepMapInstanceId: this.props.mapInstanceId,
            },
        });
    };

    onFrameUpdated = () => {
        const { mapInstanceId, analysisTypeId } = this.props;

        this.emit('SET_LOCATION_ANALYSIS_TYPE', {
            mapInstanceId,
            analysisTypeId,
        });
        this.onClosePopup();
    }

    onClosePopup = () => {
        this.emit('CLOSE_MODAL');
    }

    render() {
        const { isSwitching } = this.state;
        const { intl } = this.props;

        return (
            <div className="switcher-warning-popup">
                <div className="switcher-warning-popup__text">
                    <p className="switcher-warning-popup__text__paragraph">
                        <b>{intl.formatMessage({ id: 'modals.analysisSingleMapOnlyWarning' })}</b>
                    </p>
                    <p className="switcher-warning-popup__text__paragraph">
                        {intl.formatMessage({ id: 'modals.singleMapSwitchWarning' })}
                    </p>
                </div>
                <div className="flex-it flex-end">
                    <GTMButton
                        type="button"
                        tabIndex="0"
                        ref={btnRef => { this.cancelButton = btnRef; }}
                        onClick={this.onClosePopup}
                        className="btn-flat margin-right-10"
                    >
                        {intl.formatMessage({ id: 'cancel' })}
                    </GTMButton>
                    <GTMButton
                        type="button"
                        tabIndex="0"
                        ref={btnRef => { this.continueButton = btnRef; }}
                        className="btn-flat"
                        onClick={this.onContinue}
                        disabled={isSwitching}
                    >
                        {isSwitching ?
                            intl.formatMessage({ id: 'continuing' })
                        :
                            intl.formatMessage({ id: 'continue' })}
                    </GTMButton>
                </div>
            </div>
        );
    }
}

export default injectIntl(SingleMapSwitchWarningPopup);
