import React from 'react';
import BusComponent from '../BusComponent';
import NumberFormat from '../../enums/NumberFormat';
import format from '../../helpers/NumberFormatter';
import AppConfig from '../../appConfig';

class ReportGeosLimitPopup extends BusComponent {

    onExit = () => {
        this.emit('CLOSE_MODAL');
    }

    onTablesClicked = () => {
        window.open(AppConfig.constants.links.tables);
    }

    render() {
        const maxLimitFormated = format({
            number: this.props.maxLimit,
            numberFormat: NumberFormat.FORMAT_NUMBER,
        });
        const excludedCount = this.props.ignoredGeos.length;

        return (<div className="report-geos-limit-popup flex-column">
            <div className="report-geos-limit-popup__info">
                <span className="report-geos-limit-popup__info-text">Report will display only the first <strong>{maxLimitFormated}</strong> geographies.</span>
                <span className="report-geos-limit-popup__info-text"> To download more geographies please use the </span>
                <a className="report-geos-limit-popup__tables-link" onClick={this.onTablesClicked}>Tables </a>
                <span className="report-geos-limit-popup__info-text">page. </span>
            </div>
            <span className="report-geos-limit-popup__list-title">
                <strong>{excludedCount}</strong>{` ${excludedCount > 1 ? 'geographies' : 'geography'} excluded:`}
            </span>
            <div className="report-geos-limit-popup__list vertical-scrollbar">
                {this.props.ignoredGeos.map(geo => geo.id).join(', ')}
            </div>
            <div className="report-geos-limit-popup__bottom flex-it center flex-end">
                <div className="btn-raised" onClick={this.onExit}>OK</div>
            </div>
        </div>);
    }
}

export default ReportGeosLimitPopup;
