import AppConstants from '../../appConstants';

class ReportData {
    constructor(mapInstance, mapConfig, layers, sources) {
        this._mapConfig = mapConfig;
        this._mapInstance = mapInstance;

        this._dataHighlightLayer = layers.find(dl => dl.metadata.isHighlightLayer);

        this._reportSourceId = Object.keys(sources).find(id => id.startsWith('socialexplorerdata'));
        this._reportSourceLayerId = this._dataHighlightLayer['auto-source'][0]['source-layer'];
        this._reportDragonflyLayer = undefined;

        this._buildReportDragonflyLayer();
        // figure out where to push report layer
        let idx;
        if (this._mapConfig.streetsLayer) { // insert after streets layer
            idx = layers.findIndex(dl => dl.id === this._mapConfig.streetsLayer.id) + 1;
        } else { // insert before highlight layer
            idx = layers.findIndex(dl => dl.id === this._dataHighlightLayer.id) - 1;
        }
        layers.splice(idx, 0, this._reportDragonflyLayer);
    }

    get layer() {
        return this._reportDragonflyLayer;
    }

    applySummaryLevelUpdate() {
        this._reportSourceLayerId = this._dataHighlightLayer['auto-source'][0]['source-layer'];
        this._reportDragonflyLayer['source-layer'] = this._reportSourceLayerId;
        this._reportDragonflyLayer.minzoom = this._dataHighlightLayer.minzoom;
        this._reportDragonflyLayer.maxzoom = this._dataHighlightLayer.maxzoom;
        this._reportDragonflyLayer.metadata.defaultMinZoom = this._reportDragonflyLayer.minzoom;
        this._reportDragonflyLayer.metadata.defaultMaxZoom = this._reportDragonflyLayer.maxzoom;
    }

    _buildReportDragonflyLayer() {
        this._reportDragonflyLayer = {
            id: 'report-layer',
            filter: ['in', 'Geo_FIPS'], // not initialy visible
            metadata: {
                defaultMinZoom: this._dataHighlightLayer.minzoom,
                defaultMaxZoom: this._dataHighlightLayer.maxzoom,
                socialexplorer: {},
            },
            type: 'fill',
            paint: {
                'fill-color': AppConstants.COLOR.REPORT_FILL,
                'fill-outline-color': AppConstants.COLOR.REPORT_FILL_OUTLINE,
            },
            minzoom: this._dataHighlightLayer.minzoom,
            maxzoom: this._dataHighlightLayer.maxzoom,
            source: this._reportSourceId,
            'source-layer': this._reportSourceLayerId,
        };
    }
}

export default ReportData;
