// @ts-check
import React from 'react';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';

import BusComponent from '../BusComponent';
import LocationAnalysisHeader from './LocationAnalysisHeader';
import LocationAnalysisValueSelection from './LocationAnalysisValueSelection';
import LocationAnalysisCreateReport from './LocationAnalysisCreateReport';
import LocationAnalysisCreateReportLoader from './LocationAnalysisCreateReportLoader';
import LocationAnalysisFooter from './LocationAnalysisFooter';
import AppConfig from '../../appConfig';

export const SELECTION_LIMIT = 3;

/**
 * @typedef Props
 * @property {import('../../objects/MapInstance').default} mapInstance
 * @property {import('../../objects/LocationAnalysisItem').default} locationAnalysisItem
 *
 * @typedef State
 * @property {boolean} createReportExpanded
 *
 * @extends {BusComponent<Props, State>}
 */
class LocationAnalysis extends BusComponent {
    constructor(props, context) {
        super(props, context);


        /** @type {State} */
        this.state = {
            createReportExpanded: false,
        };
    }

    componentDidMount() {
        this.bindGluBusEvents({
            UPDATE_CREATE_REPORT_VISIBILITY: this.onChangeVisibilityOptions,
        });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    /**
     * @param {object} param0
     * @param {boolean} param0.visible
     * @param {object} param0.mapInstanceId
     */
    onChangeVisibilityOptions = ({ visible, mapInstanceId }) => {
        if (mapInstanceId === this.props.mapInstance.id) {
            this.setState({ createReportExpanded: visible });
        }
    };

    render() {
        return (
            <div
                className={classNames(
                    'location-analysis',
                    'location-analysis__container',
                )}
            >
                <LocationAnalysisHeader
                    selectedItem={this.props.locationAnalysisItem}
                    mapInstance={this.props.mapInstance}
                    createReportExpanded={this.state.createReportExpanded}
                />
                <div
                    className={classNames(
                        'location-analysis__section-content',
                        {
                            'location-analysis__section-content--expanded':
                                this.state.createReportExpanded,
                        },
                    )}
                >
                    {
                        !this.props.locationAnalysisItem.isGeoAvailable &&
                            <LocationAnalysisValueSelection
                                analysisType={
                                    this.props.locationAnalysisItem.analysisType
                                }
                                selection={this.props.locationAnalysisItem.selection}
                                mapInstanceId={this.props.mapInstance.id}
                                defaultMinimized={
                                    !!AppConfig.constants
                                        .defaultLocationAnalysisSelectionType &&
                                    AppConfig.constants.defaultLocationAnalysisSelection
                                        .length
                                }
                            />
                    }
                    <LocationAnalysisCreateReport
                        note={this.props.locationAnalysisItem.analysisType.NOTE}
                        mapInstance={this.props.mapInstance}
                    />
                    <LocationAnalysisCreateReportLoader />
                </div>
                <LocationAnalysisFooter mapInstance={this.props.mapInstance} />
            </div>
        );
    }
}

export default injectIntl(LocationAnalysis);
