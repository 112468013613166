import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import BusComponent from '../BusComponent';
import Tooltip from '../Tooltip';
import Mixpanel, { MIXPANEL_EVENTS, MIXPANEL_LOCATIONS } from '../../helpers/Mixpanel';

class ShareButton extends BusComponent {
    onClick = event => {
        this.bus.once('SHARE_SNAPSHOT_LOAD_SUCCESS', eventMap => {
            this.bus.emit('SHARE_POPUP_REQUEST', eventMap);
        });
        this.emit('SHARE_SNAPSHOT_LOAD_REQUEST');
        this.emit('SET_ON_MODAL_CLOSE_FOCUS_ELEMENT', event.target);
        Mixpanel.emitUserEvent(MIXPANEL_EVENTS.SHARE_BUTTON, {
            Location: MIXPANEL_LOCATIONS.topNav,
        });
    };

    render() {
        return (
            <Tooltip placement="bottom" mouseEnterDelay={0.5} mouseLeaveDelay={0} overlay="Share">
                <button
                    type="button"
                    tabIndex="0"
                    className={classNames(
                        'btn-icon',
                        'btn-icon--light',
                        'application-header__button',
                        {
                            'btn-icon--disabled': this.props.disabled,
                        },
                    )}
                    onClick={this.onClick}
                >
                    <i className="material-icons">share</i>
                </button>
            </Tooltip>
        );
    }
}

ShareButton.propTypes = {
    disabled: PropTypes.bool,
};

ShareButton.defaultProps = {
    disabled: false,
};

export default ShareButton;
