class Metadata {
    /** @type {Object.<string, import('./MetaSurvey').default>} key is surveyCode */
    get surveys() {
        return this._surveys;
    }

    set surveys(surveys) {
        this._surveys = surveys;
    }

    get systemDataCategories() {
        return this._systemDataCategories;
    }

    set systemDataCategories(systemDataCategories) {
        this._systemDataCategories = systemDataCategories;
    }

    /** @returns {import('./CategoryFilters').default[]} */
    get systemCategoryFilters() {
        return this._systemCategoryFilters;
    }

    set systemCategoryFilters(systemCategoryFilters) {
        this._systemCategoryFilters = systemCategoryFilters;
    }

    get surveysAsArray() {
        if (this.surveys) {
            return Object.keys(this.surveys).map(s => this.surveys[s]);
        }
        return [];
    }

    get systemDataCategoriesAsArray() {
        if (this.systemDataCategories) {
            return Object.keys(this.systemDataCategories).map(c => this.systemDataCategories[c]);
        }

        return [];
    }

    /**
     * @param {import('../').Groups} groupMetadata
     * @returns {import('./MetaSurvey').default[]}
     */
    sortedSurveys(groupMetadata) {
        return groupMetadata.base_maps_ids.reduce((array, surveyName) => {
            if (this.surveys[surveyName]) array.push(this.surveys[surveyName]);
            return array;
        }, []);
    }

    updatePermissions(userInfo) {
        // if there is no surveys loaded or no user info we have nothing to do
        if (!this.surveys || !userInfo) {
            return;
        }
        // update user availability and enabled flag for metadata
        const surveyNames = Object.keys(this.surveys);
        surveyNames.forEach(surveyName => {
            const survey = this.surveys[surveyName];
            survey.availableToUser = (survey.productTags.length > 0) ? userInfo.hasAnyRole(survey.productTags) : true;
            const datasetsAbbrevations = Object.keys(survey.datasets);
            datasetsAbbrevations.forEach(datasetAbbrevation => {
                const dataset = survey.datasets[datasetAbbrevation];
                dataset.availableToUser = (dataset.productTags.length > 0) ? userInfo.hasAnyRole(dataset.productTags) : true;
                const tableNames = Object.keys(dataset.tables);
                tableNames.forEach(tableName => {
                    const table = dataset.tables[tableName];
                    table.availableToUser = (table.productTags.length > 0) ? userInfo.hasAnyRole(table.productTags) : userInfo.isProUser;
                    table.enabled = table.availableToUser;
                    const variableNames = Object.keys(table.variables);
                    variableNames.forEach(variableName => {
                        const variable = table.variables[variableName];
                        variable.availableToUser = table.availableToUser;
                        variable.enabled = table.enabled;
                    });
                });
            });
        });
    }

    toJSON() {
        return {
            surveys: this.surveys,
            systemDataCategories: this.systemDataCategories,
            systemCategoryFilters: this.systemCategoryFilters,
        };
    }
}

export default Metadata;
