import React from 'react';
import { injectIntl } from 'react-intl';
import uuid from 'node-uuid';
import BusComponent from '../BusComponent';
import Errors from '../../enums/Error';
import Panel from '../panel/Panel';
import MapAnnotatorPanel from './MapAnnotatorPanel';
import AnnotationMetadata from '../../enums/AnnotationMetadata';
import { isStorageAvailable } from '../../helpers/Util';
import AnnotationPalette from './AnnotationPalette';
import AnnotationHelper from './AnnotationHelper';
import AnnotationSelectionCardList from './AnnotationSelectionCardList';
import AnnotationSelectionHeader from './AnnotationSelectionHeader';
import Loader from '../Loader';
import { annotationClasses } from '../../map/data/AnnotationData';
import { doneAnnotateButtonEvents } from '../../enums/GoogleTagManagerEvents';
import googleTagManagerEvents from '../../helpers/GoogleTagManagerEventsWrapper';

const STORAGE = window.localStorage;
const IS_STORAGE_AVAILABLE = isStorageAvailable(STORAGE);
const DoneButton = googleTagManagerEvents('button', doneAnnotateButtonEvents);

class MapAnnotator extends BusComponent {
    constructor(props, context) {
        super(props, context);
        let storedAnnotations;

        if (IS_STORAGE_AVAILABLE) {
            const storedJSON = localStorage.getItem('annotations');
            try {
                storedAnnotations = storedJSON !== null ? JSON.parse(storedJSON.replace(/null/g, '"undefined"')) : undefined;
            } catch (error) {
                this.emit('ANNOTATION_STORAGE_ERROR', {
                    level: Errors.ERROR,
                    error,
                    originalError: error.message,
                    additionalInfo: 'Error while saving current project',
                });
            }
        }

        this.state = {
            annotations: [],
            checkedAnnotations: [],
            annotationLegend: undefined,
            drawingAnnotation: undefined,
            ready: false,
            editMode: false,
            annotationsDirty: false,
            dragonflyDrawCreated: false,
            pasteDisabled: !IS_STORAGE_AVAILABLE || storedAnnotations === undefined,
        };

        this.bindGluBusEvents({
            ANNOTATION_LEGEND_UPDATED: this.onAnnotationLegendUpdated,
            ANNOTATIONS_FETCHED: this.onAnnationsFetched,
            ANNOTATIONS_EDIT_MODE_ENTERED: this.onAnnotationModeEnter,
            ANNOTATIONS_DELETED: this.fetchAnnotations,
            DRAWING_MODE_STOPPED: this.onDrawingModeStopped,
            ANNOTATION_DRAWING_FINISHED: this.onDrawingModeStopped,
            MULTIPLE_ANNOTATIONS_DRAWING_FINISHED: this.onMultipleDrawingModeStopped,
            MAP_VIEWER: this.onMapViewer,
        });

        this.annotationTypes = AnnotationMetadata;
        this.annotationTypes.flowarrow.callback = this.onDrawAnnotationClicked.bind(this, 'flowarrow');
        this.annotationTypes.freehand.callback = this.onDrawAnnotationClicked.bind(this, 'freehand');
        this.annotationTypes.shape.callback = this.onDrawAnnotationClicked.bind(this, 'shape');
        this.annotationTypes.hotspot.callback = this.onDrawAnnotationClicked.bind(this, 'hotspot');
        this.annotationTypes.image.callback = this.onDrawAnnotationClicked.bind(this, 'image');
        this.annotationTypes.marker.callback = this.onDrawAnnotationClicked.bind(this, 'marker');
        this.annotationTypes.polyline.callback = this.onDrawAnnotationClicked.bind(this, 'polyline');
        this.annotationTypes.polygon.callback = this.onDrawAnnotationClicked.bind(this, 'polygon');
        this.annotationTypes.label.callback = this.onDrawAnnotationClicked.bind(this, 'label');
    }

    componentDidMount() {
        this.fetchAnnotations();
        this.emit('MAP_GET_MAP_VIEWER');
        this.emit('MAP_DISABLE_FEATURE_INTERACTIVITY_REQUEST', { mapInstanceId: this.props.mapInstance.id });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.editMode && (prevState.editMode !== this.state.editMode)) {
            this.fetchAnnotations();
        }
    }

    componentWillUnmount() {
        this.emit('EXIT_ANNOTATIONS_EDIT_MODE');
        this.emit('MAP_ENABLE_FEATURE_INTERACTIVITY_REQUEST', { mapInstanceId: this.props.mapInstance.id });
        this.unbindGluBusEvents();
    }

    onMapViewer = ({ source }) => {
        if (source.id !== this.props.mapInstance.id) return;

        if (source.annotationsReady) {
            this.setState({
                dragonflyDrawCreated: true,
            }, () => {
                this.startEditMode();
            });
        } else {
            this.bus.once('MAP_DRAGONFLY_DRAW_CREATED', this.onDragonflyDrawCreated);
        }
    };

    onEditClicked = () => {
        this.setState({
            editMode: true,
        });
    }

    onExportClicked = payload => {
        this.emit('ANNOTATION_SELECTED', { selectedAnnotationIds: [] });
        this.onCancelDrawing();
        this.emit('EXPORT_ANNOTATIONS_REQUEST', payload);
    }

    onDrawAnnotationClicked = type => {
        this.emit(`DRAW_${type.toUpperCase()}_REQUEST`);
        this.setState({
            drawingAnnotation: type,
        });
    }

    onCancelDrawing = () => {
        this.emit('CANCEL_DRAWING_REQUEST');
        this.setState({
            drawingAnnotation: undefined,
        });
    }

    onDrawingModeStopped = () => {
        this.setState({
            drawingAnnotation: undefined,
            annotationsDirty: true,
        });
    }

    onMultipleDrawingModeStopped = () => {
        this.setState({
            annotationsDirty: true,
        });
    }

    onDragonflyDrawCreated = () => {
        this.setState({ dragonflyDrawCreated: true }, () => {
            this.startEditMode();
        });
    }

    fetchAnnotations = () => {
        this.emit('FETCH_ANNOTATIONS', { mapInstanceId: this.props.mapInstance.id });
    }

    onAnnotationLegendUpdated = eventMap => {
        const { annotationLegend } = eventMap;
        this.setState({ annotationLegend });
    }

    onAnnotationModeEnter = () => {
        this.setState({ annotationModeEntered: true });
    }

    onAnnationsFetched = eventMap => {
        const { annotations, annotationLegend } = eventMap;
        this.setState({ annotations, annotationLegend, ready: true });
        // if user was in edit mode, and deleted all annotations
        // set edit mode to false
        if (!annotations.length && this.state.editMode) {
            this.setState({ editMode: false });
        }
    }

    onAnnotationChecked = (annotationId, checked) => {
        const { checkedAnnotations } = this.state;
        if (checked) {
            checkedAnnotations.push(annotationId);
        } else {
            const index = checkedAnnotations.indexOf(annotationId);
            checkedAnnotations.splice(index, 1);
        }

        this.emit('ANNOTATION_SELECTED', { selectedAnnotationIds: checkedAnnotations, editMode: true });
        this.setState({ checkedAnnotations, selectedAnnotationId: undefined });
    }

    onAllAnnotationsChecked = checked => {
        let checkedAnnotations;
        if (checked) {
            checkedAnnotations = this.state.annotations.map(a => a.id);
        } else {
            checkedAnnotations = [];
        }
        this.emit('ANNOTATION_SELECTED', { selectedAnnotationIds: checkedAnnotations, editMode: true });
        this.setState({
            checkedAnnotations,
            selectedAnnotationId: undefined,
        });
    }

    onAnnotationsCopy = () => {
        if (!IS_STORAGE_AVAILABLE) return;
        const annotationsJSON = this.state.checkedAnnotations.map(id => this.state.annotations.find(a => a.id === id).toJSON());
        const stringifiedJSON = JSON.stringify(annotationsJSON);
        localStorage.setItem('annotations', stringifiedJSON);
        this.setState({
            pasteDisabled: false,
        });
        this.emit('CLOSE_DROPDOWN');
    }

    onAnnotationsPaste = () => {
        this.setState({
            annotationsDirty: true,
            checkedAnnotations: [],
        });
        if (!IS_STORAGE_AVAILABLE) return;
        setTimeout(() => {
            const storedJSON = localStorage.getItem('annotations');
            let storedAnnotations;
            try {
                storedAnnotations = storedJSON !== null ? JSON.parse(storedJSON.replace(/null/g, '"undefined"')) : undefined;
            } catch (e) {
                this.emit('ANNOTATIONS_PASTE_ERROR', {
                    level: Errors.ERROR,
                    additionalInfo: 'Pasting from storage failed',
                });
            }
            if (storedAnnotations) {
                const annotations = storedAnnotations.map(a => {
                    const type = a.type;
                    delete a.type;
                    a.id = `${a.id}_${uuid.v4()}`;
                    return new annotationClasses[type](a);
                });
                this.emit('ADD_MULTIPLE_ANNOTATIONS_REQUEST', {
                    annotations,
                    mapInstanceId: this.props.mapInstance.id,
                });
                this.emit('CLOSE_DROPDOWN');
            }
        }, 10);
    }

    onAnnotationsDuplicate = () => {
        const annotations = this.state.checkedAnnotations.map(id => {
            const annotation = this.state.annotations.find(a => a.id === id).toJSON();
            const type = annotation.type;
            const newAnnotation = { ...annotation };
            delete newAnnotation.type;
            newAnnotation.id = `${newAnnotation.id}_${uuid.v4()}`;
            return new annotationClasses[type](newAnnotation);
        });
        this.emit('ADD_MULTIPLE_ANNOTATIONS_REQUEST', {
            annotations,
            mapInstanceId: this.props.mapInstance.id,
        });
        this.emit('CLOSE_DROPDOWN');
        this.setState({
            annotationsDirty: true,
            checkedAnnotations: [],
        });
    }

    onAnnotationsDelete = () => {
        this.emit('DELETE_SELECTED_ANNOTATIONS_REQUEST');
        this.setState({ checkedAnnotations: [] });
    }

    onAnnotationsClearSelection = () => {
        this.setState({
            checkedAnnotations: [],
            editMode: false,
        });
    }

    render() {
        const { ready, dragonflyDrawCreated, editMode, annotations, annotationLegend, checkedAnnotations, annotationModeEntered } = this.state;
        const { mapInstance } = this.props;

        let content;
        if (!ready || !dragonflyDrawCreated) {
            content = <Loader text={this.props.intl.formatMessage({ id: 'dataBrowser.loadingAnnotations' })} />;
        } else if (editMode) {
            content = (<AnnotationSelectionCardList
                annotations={annotations}
                checkedAnnotations={checkedAnnotations}
                onAnnotationChecked={this.onAnnotationChecked}
                onAllAnnotationsChecked={this.onAllAnnotationsChecked}
            />);
        } else {
            content = (<MapAnnotatorPanel
                mapInstanceId={mapInstance.id}
                annotations={annotations}
                editMode={editMode}
                annotationLegend={annotationLegend}
                onEditClicked={this.onEditClicked}
                onExportClicked={this.onExportClicked}
            />);
        }

        const annotationsPanelHeaderActions = [];
        if (this.state.annotationsDirty) {
            annotationsPanelHeaderActions.push(<DoneButton
                className="btn-raised map-annotator-container__done-button"
                key="done-btn"
                onClick={this.props.onClose}
            >
                {this.props.intl.formatMessage({ id: 'done' })}
            </DoneButton>);
        }

        return (<div className="map-annotator-container flex-it grow">
            <Panel
                title={this.props.intl.formatMessage({ id: 'dataBrowser.annotateMap' })}
                headerStyle="white"
                headerActions={annotationsPanelHeaderActions}
                customHeader={this.state.editMode ? <AnnotationSelectionHeader
                    isStorageAvailable={IS_STORAGE_AVAILABLE}
                    numberOfSelectedAnnotations={this.state.checkedAnnotations.length}
                    numberOfAllAnnotations={this.state.annotations.length}
                    onAnnotationsCopy={this.onAnnotationsCopy}
                    onAnnotationsDelete={this.onAnnotationsDelete}
                    onAnnotationsPaste={this.onAnnotationsPaste}
                    onAnnotationsDuplicate={this.onAnnotationsDuplicate}
                    onAnnotationsClearSelection={this.onAnnotationsClearSelection}
                    pasteDisabled={this.state.pasteDisabled}
                /> : undefined}
                onCloseClick={this.state.annotationsDirty ? undefined : this.props.onClose}
            >
                {content}
            </Panel>
            {dragonflyDrawCreated && ready && annotationModeEntered &&
                <AnnotationPalette
                    onCancelDrawing={this.onCancelDrawing}
                    annotationTypes={this.annotationTypes}
                    drawingAnnotation={this.state.drawingAnnotation}
                />
            }
            {this.state.drawingAnnotation && <AnnotationHelper
                drawingAnnotation={this.state.drawingAnnotation}
            />}
        </div>);
    }

    startEditMode() {
        this.emit('ENTER_ANNOTATIONS_EDIT_MODE');
        this.fetchAnnotations();
    }
}

export default injectIntl(MapAnnotator);
