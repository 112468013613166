import Snapshot from '../objects/Snapshot';

/** Helper class for User Info XML parsing */
class ShareXMLParser {
    /**
     * Constructs ShareXMLParser for the given document
     * @constructor
     * @param {collaboratorXMLDocument} collaboratorXMLDocument
     */
    constructor(collaboratorXMLDocument) {
        this.collaboratorXMLDocument = collaboratorXMLDocument;
    }

    static _parseSnapshot(projectInfoNode) {
        return new Snapshot({
            projectId: projectInfoNode.getAttribute('ProjectId'),
            title: projectInfoNode.getAttribute('Title'),
            description: projectInfoNode.getAttribute('Description'),
            createdDate: projectInfoNode.getAttribute('CreatedDate'),
            modifiedDate: projectInfoNode.getAttribute('ModifiedDate'),
            userId: projectInfoNode.getAttribute('UserId'),
            projectType: projectInfoNode.getAttribute('ProjectType'),
            newProject: projectInfoNode.getAttribute('NewProject'),
            viewCode: projectInfoNode.getAttribute('ViewCode'),
            etag: projectInfoNode.getAttribute('etag'),
            ownedBySe: projectInfoNode.getAttribute('OwnedBySe'),
            projectThumbUrl: projectInfoNode.getAttribute('ProjectThumbUrl'),
            isShareSnapshot: projectInfoNode.getAttribute('IsShareSnapshot'),
            assetsGuid: projectInfoNode.getAttribute('AssetsGuid'),
        });
    }
}

export default ShareXMLParser;
