import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import MenuItem from 'material-ui/MenuItem';
import DropDownMenu from 'material-ui/DropDownMenu';

import PopoverAnimationUpwards from '../../../dropdown/PopoverAnimationUpwards';
import { DropdownStyle, LabelStyle, IconStyle, UnderlineStyle } from './MapExportFrameControlsStyles';
import MapExportAspectRatio from '../../../../enums/MapExportAspectRatio';

const MapExportFrameAspectRatioControl = props => {
    const { targetAspectRatio, onChangeAspectRatio } = props;

    const dropDownMenuItems = Object.values(MapExportAspectRatio).map(item =>
        <MenuItem
            key={item.label}
            value={item.value}
            label={item.label}
            primaryText={item.primaryText}
        />
    );

    return (
        <div className="map-export-frame__export-strip__controls__aspect-ratio">
            <div>
                {props.intl.formatMessage({ id: 'map.aspectRatio' })}
            </div>
            <div>
                <DropDownMenu
                    animation={PopoverAnimationUpwards}
                    style={DropdownStyle}
                    labelStyle={LabelStyle}
                    underlineStyle={UnderlineStyle}
                    iconStyle={IconStyle}
                    value={targetAspectRatio}
                    onChange={onChangeAspectRatio}
                >
                    {dropDownMenuItems}
                </DropDownMenu>
            </div>
        </div>
    );
};

MapExportFrameAspectRatioControl.propTypes = {
    targetAspectRatio: PropTypes.string,
    onChangeAspectRatio: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
};

MapExportFrameAspectRatioControl.defaultProps = {
    targetAspectRatio: undefined,
};

export default injectIntl(MapExportFrameAspectRatioControl);
