import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import BusComponent from '../../../BusComponent';
import ChangeOverTimeType from '../../../../enums/ChangeOverTimeType';
import VisualizationTypeListItem from './VisualizationTypeListItem';

const ChangeOverTimeTypeDescription = {
    [ChangeOverTimeType.COMPUTE_CHANGE]: 'dataBrowser.displaysActualChange',
    [ChangeOverTimeType.COMPUTE_CHANGE_PERCENT]: 'dataBrowser.displaysDifferenceBetweenTwoNumerical',
    [ChangeOverTimeType.COMPUTE_CHANGE_COMPOSITION]: 'dataBrowser.displaysDifferenceBetweenTwoPercentages',
};

class ChangeOverTimeVisualizationTypeList extends BusComponent {
    handleTypeClick = changeType => {
        const { mapInstance } = this.props;

        this.emit('APPLY_CHANGE_OVER_TIME_REQUEST', {
            changeType,
            compareWithVariableItem: mapInstance.dataTheme.appliedChangeOverTimeCompareSelection,
            pointOfReferenceItem: mapInstance.dataTheme.appliedChangeOverTimePointOfReference,
            mapInstanceId: mapInstance.id,
        });
    };

    render() {
        const { mapInstance, intl } = this.props;
        const activeType = mapInstance.dataTheme.appliedChangeOverTimeType;

        const changeCountDisabled = false;
        const changePercentDisabled = false;
        const changeCompositionDisabled = true;

        return (
            <div className="visualization-editor__section">
                <h4 className="visualization-editor__title">
                    {intl.formatMessage({ id: 'dataBrowser.visualizeChangeOverTimeAs' })}
                </h4>
                <div className="visualization-editor__types">
                    <VisualizationTypeListItem
                        onClick={this.handleTypeClick}
                        type={ChangeOverTimeType.COMPUTE_CHANGE}
                        title={intl.formatMessage({ id: 'dataBrowser.difference' })}
                        icon={<i className="socex-icon-bubble-v2" />}
                        selected={activeType === ChangeOverTimeType.COMPUTE_CHANGE}
                        disabled={changeCountDisabled}
                    />
                    <VisualizationTypeListItem
                        onClick={this.handleTypeClick}
                        type={ChangeOverTimeType.COMPUTE_CHANGE_PERCENT}
                        title={intl.formatMessage({ id: 'dataBrowser.percentChange' })}
                        icon={<i className="socex-icon-choropletth-v2" />}
                        selected={activeType === ChangeOverTimeType.COMPUTE_CHANGE_PERCENT}
                        disabled={changePercentDisabled}
                    />
                    {!changeCompositionDisabled && <VisualizationTypeListItem
                        onClick={this.handleTypeClick}
                        type={ChangeOverTimeType.COMPUTE_CHANGE_COMPOSITION}
                        title={intl.formatMessage({ id: 'dataBrowser.compositionChange' })}
                        icon={<i className="socex-icon-choropletth-v2" />}
                        selected={activeType === ChangeOverTimeType.COMPUTE_CHANGE_COMPOSITION}
                        disabled={changeCompositionDisabled}
                    />}
                </div>
                <div className="visualization-editor__description">
                    {intl.formatMessage({ id: ChangeOverTimeTypeDescription[activeType] })}
                </div>
            </div>
        );
    }
}

ChangeOverTimeVisualizationTypeList.propTypes = {
    mapInstance: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(ChangeOverTimeVisualizationTypeList);
