import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import BusComponent from '../BusComponent';
import googleTagManagerEvents from '../../helpers/GoogleTagManagerEventsWrapper';
import { saveProjectEvents } from '../../enums/GoogleTagManagerEvents';

const SaveProjectButton = googleTagManagerEvents('button', saveProjectEvents);

class SaveButton extends BusComponent {
    componentDidMount() {
        this.setGTMAttributes();
    }

    componentDidUpdate() {
        this.setGTMAttributes();
    }

    setGTMAttributes = () => {
        if (this.props.showSave) {
            this.saveButton.setCustomAttribute('gtm-eventc', 'Map projects');
            this.saveButton.setCustomAttribute('gtm-eventl', this.props.projectTitle);
        }
    }

    onUpgradeProjectClick = () => {
        this.emit('PROJECT_UPGRADE_REQUEST');
    };

    onSaveProjectClick = () => {
        this.emit('PROJECT_UPDATE_REQUEST');
    };

    onMakeACopyClick = () => {
        this.emit('SAVE_AS_PROJECT_POPUP_REQUEST', { projectTitle: this.props.projectTitle, saveAsNew: false });
    };

    render() {
        const { showSaveAs, showSave, showUpgrade, disabled, buttonVariant } = this.props;

        const buttonClasses = classNames(
            {
                'btn-raised': buttonVariant === 'raised',
            },
            {
                'btn-flat': buttonVariant === 'flat',
            },
            {
                'btn-flat--light': buttonVariant === 'flat',
            },
            'application-header__button',
            {
                'btn-raised--disabled': disabled,
            },
        );

        switch (true) {
        case showUpgrade:
            return (
                <button
                    type="button"
                    title="Upgrade and save project"
                    className={buttonClasses}
                    onClick={this.onUpgradeProjectClick}
                    disabled={disabled}
                >
                    Upgrade and save
                </button>
            );
        case showSave:
            return (
                <SaveProjectButton
                    ref={c => { this.saveButton = c; }}
                    className={buttonClasses}
                    onClick={this.onSaveProjectClick}
                >
                    Save
                </SaveProjectButton>
            );
        case showSaveAs:
            return (
                <button
                    type="button"
                    className={buttonClasses}
                    onClick={this.onMakeACopyClick}
                    disabled={disabled}
                >
                    {`${this.props.intl.formatMessage({ id: 'header.saveAs' })}...`}
                </button>
            );
        default:
            return null;
        }
    }
}

SaveButton.propTypes = {
    showSaveAs: PropTypes.bool,
    showSave: PropTypes.bool,
    showUpgrade: PropTypes.bool,
    disabled: PropTypes.bool,
    projectTitle: PropTypes.string,
    buttonVariant: PropTypes.string,
    intl: PropTypes.object.isRequired,
};

SaveButton.defaultProps = {
    showSaveAs: false,
    showSave: false,
    showUpgrade: false,
    disabled: false,
    projectTitle: undefined,
    buttonVariant: 'raised',
};

export default injectIntl(SaveButton);
