import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';

import Popover from './Popover';

const MARGIN_LEFT = 12;

class InfoPopover extends React.Component {
    constructor(props) {
        super(props);
        this.state = { open: false };
    }

    onToggle = () => {
        this.setState(prevState => ({ open: !prevState.open }));
    };

    render() {
        const { open } = this.state;
        const { intl, infoMessage, infoImgUrl, showClose } = this.props;
        return (
            <div className="flex-it">
                <button
                    ref={e => { this.trigger = e; }}
                    className={classNames('info-popover__btn', 'material-icons',
                        { 'info-popover__btn--active': open })}
                    onClick={this.onToggle}
                    aria-label={intl.formatMessage({ id: 'help' })}
                >
                    info_outline
                </button>
                {this.trigger && (
                    <Popover
                        anchorEl={this.trigger}
                        className="info-popover"
                        open={open}
                        marginLeft={MARGIN_LEFT}
                        showClose={showClose}
                        triggerToggle
                        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                        targetOrigin={{ horizontal: 'left', vertical: 'top' }}
                        onCloseRequest={this.onToggle}
                    >
                        <div>
                            {infoImgUrl &&
                                <div
                                    className="info-popover__img"
                                    style={{ backgroundImage: `url('${infoImgUrl}')` }}
                                />
                            }
                            <div className="info-popover__message">
                                {infoMessage}
                            </div>
                        </div>
                    </Popover>
                )}
            </div>
        );
    }
}

InfoPopover.propTypes = {
    intl: PropTypes.object.isRequired,
    infoMessage: PropTypes.string.isRequired,
    infoImgUrl: PropTypes.string,
    showClose: PropTypes.bool,
};

InfoPopover.defaultProps = {
    infoImgUrl: undefined,
    showClose: true,
};

export default injectIntl(InfoPopover);
