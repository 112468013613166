import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import ColorPaletteSample from './ColorPaletteSample';
import FlipColors from '../../FlipColors';
import Tooltip from '../../../Tooltip';
import ColorPaletteEditor from '../../../colorPaletteEditor/ColorPaletteEditor';
import classNames from 'classnames';

const ColorPaletteSelector = props => {
    const {
        selectedColorPalette,
        colorPaletteFlipped,
        percentageControl,
        colorPaletteFlippable,
        onFlipColorPalette,
        mapInstance,
        onPaletteClick,
    } = props;

    const colorPaletteEditorType = ColorPaletteEditor.getEditorType(selectedColorPalette.type);
    const colorPaletteTitleClasses = classNames('visualization-editor__palette-title', 'ellipsis', colorPaletteEditorType.toLowerCase());
    const colorPaletteSample = (<div className="flex-it center grow">
        <ColorPaletteSample
            flipped={colorPaletteFlipped}
            colorPalette={selectedColorPalette}
        />
        <div className={colorPaletteTitleClasses}>
            {selectedColorPalette.title}
        </div>
    </div>);
    const title = props.intl.formatMessage({ id: 'visualisationType.changeColorPalette' });
    return (
        <div className="visualization-editor__section">
            <div className="visualization-editor__title flex-it space-between">
                <h4>{props.intl.formatMessage({ id: 'visualisationType.colorPalette' })}</h4>
                {percentageControl}
            </div>
            <div className="flex-it center visualization-editor__palette-wrapper">
                {colorPaletteFlippable && <FlipColors
                    onFlipColorsClick={onFlipColorPalette}
                    colorPaletteFlipped={colorPaletteFlipped}
                    dataTheme={mapInstance.dataTheme}
                />}
                {colorPaletteFlippable && <div className="divider divider--vertical divider--btn-separator" />}
                <Tooltip
                    placement="bottom"
                    mouseEnterDelay={0.5}
                    mouseLeaveDelay={0}
                    overlay={title}
                >
                    <div
                        className="visualization-editor__palette-selector"
                        onClick={onPaletteClick}
                    >
                        {colorPaletteSample}
                        <button className="btn-flat-icon" aria-label={title}>
                            <i className="material-icons">keyboard_arrow_right</i>
                        </button>
                    </div>
                </Tooltip>
            </div>
        </div>
    );
};

ColorPaletteSelector.propTypes = {
    colorPaletteFlipped: PropTypes.bool,
    colorPaletteFlippable: PropTypes.bool,
    mapInstance: PropTypes.object.isRequired,
    selectedColorPalette: PropTypes.object.isRequired,
    onFlipColorPalette: PropTypes.func.isRequired,
    onPaletteClick: PropTypes.func.isRequired,
    percentageControl: PropTypes.object,
    intl: PropTypes.object.isRequired,
};

ColorPaletteSelector.defaultProps = {
    colorPaletteFlippable: false,
    colorPaletteFlipped: false,
    percentageControl: undefined,
};

export default injectIntl(ColorPaletteSelector);
