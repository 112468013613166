import React from 'react';
import PropTypes from 'prop-types';
import dragonfly from 'dragonfly-v3';

import BusComponent from './BusComponent';
import AppConfig from '../appConfig';
import MapData from '../map/MapData';
import MapHandler from '../map/MapHandler';
import ApplicationMode from '../enums/ApplicationMode';
import { addResizeListener, removeResizeListener } from '../helpers/DomResizeListener';
import { getMapCanvasImageData } from '../helpers/Util';
import MapFooter from './mapControls/MapFooter';
import InfoBubbleMode from '../enums/InfoBubbleMode';

class MapViewer extends BusComponent {
    constructor(props, context) {
        super(props, context);

        this.state = {};

        this.bindGluBusEvents({
            MAP_GET_MAP_VIEWER: this.onMapGetMapViewer,
            MAP_RECONSTRUCT_CURRENT_MAP: this.onMapReconstructCurrentMap,
            MAP_GET_CURRENT_MAP_INSTANCE_REQUEST: this.onMapGetCurrentMapInstanceRequest,
            MAP_ENABLE_FEATURE_INTERACTIVITY_REQUEST: this.onMapEnableFeatureInteractivityRequest,
            MAP_DISABLE_FEATURE_INTERACTIVITY_REQUEST: this.onMapDisableFeatureInteractivityRequest,
            MAP_APPLY_DATA_THEME_REQUEST: this.onMapApplyDataThemeRequest,
            INFO_BUBBLE_MODE_UPDATE_SUCCESS: this.onSetInfoBubbleMode,
        });

        // map bus listeners
        this.boundOnMapMove = this._onMapMove.bind(this);
        this.boundOnMapZoom = this._onMapZoom.bind(this);
        this.boundOnMapZoomEnd = this._onMapZoomEnd.bind(this);
        this.boundOnMapMouseOut = this._onMapMouseOut.bind(this);
        this.boundOnMapLoad = this._onMapLoad.bind(this);
        this.boundOnMapMoveStart = this._onMapMoveStart.bind(this);
        this.boundOnMapMoveEnd = this._onMapMoveEnd.bind(this);
        this.boundOnMapMouseMove = this._onMapMouseMove.bind(this);
        this.boundOnMapMouseClick = this._onMapMouseClick.bind(this);
        this.bounOnMapResize = this._onMapResize.bind(this);
        this.boundOnMapWebGLInitializationFailed = this._onMapWebGLInitializationFailed.bind(this);
        this.boundOnMapWebGLContextLost = this._onMapWebGLContextLost.bind(this);
        this.boundOnMapWebGLContextRestored = this._onMapWebGLContextRestored.bind(this);
        this.boundOnCurrentMapsRetrieved = this.onCurrentMapsRetrieved.bind(this);
        this.boundHandleResize = this.handleResize.bind(this);

        dragonfly.accessToken = AppConfig.constants.mapboxAccessToken;

        this._currentMap = undefined;
        this._annotationsState = props.annotationsState;
        this._dragonflyMap = undefined;
        this._dragonflyMapControls = {};
        /** @type {MapData} */
        this._dragonflyMapData = undefined;
        this._dragonflyMapHandler = undefined;
        this.isFeatureInteractivityEnabled = true;
        this._mapWebGLContextLostCounter = 0;

        if (window.DingoDebug.dragonflyDebug === undefined) {
            window.DingoDebug.dragonflyDebug = {};
        }

        window.DingoDebug.dragonflyDebug[props.mapInstance.id] = val => {
            this._dragonflyMap.showCollisionBoxes = val;
            this._dragonflyMap.showTileBoundaries = val;
        };
    }

    componentDidMount() {
        delete this.currentMaps;
        delete this._currentMap;
        this.bus.once('CURRENT_MAPS', this.boundOnCurrentMapsRetrieved);
        this.bus.once('CURRENT_INFO_BUBBLE_MODE', this.onSetInfoBubbleMode);
        this.emit('CURRENT_INFO_BUBBLE_MODE_REQUEST');
        this.emit('CURRENT_MAPS_REQUEST', { source: this });

        const mapId = `map-${this.props.mapInstance.id}`;
        addResizeListener(document.getElementById(mapId), this.boundHandleResize);
    }

    componentDidUpdate() {
        // wait until changes are drawn and then resize map
        // this is needed for cases when parent changes size due to code and not window resize
        // in those cases dragonfly does not register resize by itself
        setTimeout(() => {
            window.requestAnimationFrame(() => {
                if (this.dragonflyMap) {
                    this.dragonflyMap.resize();
                }
            });
        }, 0);
    }

    componentWillReceiveProps(nextProps) {
        if (this._dragonflyMapHandler) {
            this._dragonflyMapHandler.update(nextProps);
        }
        if (
            this.props.mapInstance !== nextProps.mapInstance ||
            this.props.mapInstance.id !== nextProps.mapInstance.id
        ) {
            this.setState(
                {
                    mapInstance: nextProps.mapInstance,
                },
                this.constructDragonflyMap,
            );
        }
    }

    componentWillUnmount() {
        clearTimeout(this._generateThumbnailTimeout);
        this.unbindGluBusEvents();
        this.bus.off('CURRENT_MAPS', this.boundOnCurrentMapsRetrieved);
        removeResizeListener(
            document.getElementById(`map-${this.props.mapInstance.id}`),
            this.boundHandleResize,
        );

        if (this._dragonflyMap) {
            this.disposeDragonflyMap();
        }
    }

    onSetInfoBubbleMode = ({ infoBubbleMode }) => {
        this.infoBubbleMode = infoBubbleMode;
    };

    onCurrentMapsRetrieved(baseMaps) {
        this.currentMaps = baseMaps;
        this.constructDragonflyMap();
    }

    onMapGetMapViewer() {
        this.emit('MAP_VIEWER', { source: this });
    }

    onMapGetCurrentMapInstanceRequest(eventMap) {
        if (eventMap.mapInstanceId === this.props.mapInstance.id) {
            this.emit('MAP_CURRENT_MAP_INSTANCE', {
                source: this,
                mapInstance: this.props.mapInstance,
                target: eventMap.source,
            });
        }
    }

    onMapEnableFeatureInteractivityRequest(eventMap) {
        if (eventMap.mapInstanceId === this.props.mapInstance.id) {
            this.isFeatureInteractivityEnabled = true;
            this.emit('MAP_FEATURE_INTERACTIVITY_ENABLED', { source: this });
        }
    }

    onMapDisableFeatureInteractivityRequest(eventMap) {
        if (eventMap.mapInstanceId === this.props.mapInstance.id) {
            this.isFeatureInteractivityEnabled = false;

            this.emit('MAP_FEATURE_INTERACTIVITY_DISABLED', { source: this });

            this.emit('MAP_FEATURES_ROLL_OUT', {
                source: this,
                originalEvent: eventMap,
            });
        }
    }

    onMapReconstructCurrentMap(eventMap) {
        if (eventMap.mapInstanceId === this.props.mapInstance.id) {
            this.constructDragonflyMap();
            this.emit('MAP_CURRENT_MAP_RECONSTRUCTED', { source: this });
        }
    }

    onMapApplyDataThemeRequest(eventMap) {
        if (eventMap.mapInstanceId === this.props.mapInstance.id) {
            if (this._currentMap && this._currentMap.id === this.props.mapInstance.currentMapId) {
                this.emit('MAP_APPLY_RENDERER_UPDATE', { source: this });
            } else {
                this.constructDragonflyMap();
                this.emit('MAP_NEW_DATA_THEME_APPLIED', {
                    source: this,
                    appliedDataTheme: this.appliedDataTheme,
                });
            }
        }
    }

    render() {
        const { mapInstance, leftMap } = this.props;
        return (
            <div>
                <div
                    ref={c => (this.map = c)}
                    id={`map-${mapInstance.id}`}
                    className="dragonfly-map"
                />
                <MapFooter mapInstance={mapInstance} leftMap={leftMap} />
            </div>
        );
    }

    _onMapLoad(e) {
        // bind map listeners
        this._dragonflyMap.on('move', this.boundOnMapMove);
        this._dragonflyMap.on('zoom', this.boundOnMapZoom);
        this._dragonflyMap.on('zoomend', this.boundOnMapZoomEnd);
        this._dragonflyMap.on('mouseout', this.boundOnMapMouseOut);
        this._dragonflyMap.on('movestart', this.boundOnMapMoveStart);
        this._dragonflyMap.on('moveend', this.boundOnMapMoveEnd);
        this._dragonflyMap.on('mousemove', this.boundOnMapMouseMove);
        this._dragonflyMap.on('click', this.boundOnMapMouseClick);
        this._dragonflyMap.on('resize', this.bounOnMapResize);
        this._dragonflyMap.on('rendered', this.handleMapRendered);

        this.emit('MAP_LOAD', {
            source: this,
            originalEvent: e,
            boundingBox: this._dragonflyMap.getBounds().toArray(),
        });
    }

    handleMapRendered = () => {
        if (this._dragonflyMap.loaded()) {
            // all tiles are loaded so...
            this.generateThumbnail();

            if (typeof this.props.onRendered === 'function') {
                this.props.onRendered();
            }
        }
        this.emit('MAP_RENDERED', { source: this, mapInstanceId: this.props.mapInstance.id });
    };

    generateThumbnail() {
        const { onImageReady } = this.props;
        const { applicationMode } = this.context;

        const canEditVisualization =
            applicationMode === ApplicationMode.EXPLORE || applicationMode === ApplicationMode.EDIT;

        clearTimeout(this._generateThumbnailTimeout);

        if (!onImageReady || !canEditVisualization) return;

        this._generateThumbnailTimeout = setTimeout(() => {
            this._generateThumbnailTimeout = undefined;
            const cnv = this.dragonflyMap.getCanvas();
            const image = getMapCanvasImageData(cnv);

            if (image) {
                onImageReady(image);
            }
        }, 100);
    }

    _onMapMoveStart(e) {
        this.emit('MAP_MOVE_START', { source: this, originalEvent: e });
        // if the map is in click mode remove the info bubble when the map is moved
        if (this.infoBubbleMode === InfoBubbleMode.CLICK) {
            this.emit('MAP_MOUSE_OUT', { source: this, originalEvent: e });
        }
        clearTimeout(this._generateThumbnailTimeout);
    }

    _onMapMove(e) {
        const payload = {
            source: this,
            originalEvent: e,
            center: this._dragonflyMap.getCenter(),
        };
        this.emit('MAP_MOVE', payload);

        if (typeof this.props.onMove === 'function') {
            this.props.onMove(payload);
        }
    }

    _onMapMoveEnd(e) {
        this.emit('MAP_MOVE_END', {
            source: this,
            originalEvent: e,
            boundingBox: this._dragonflyMap.getBounds().toArray(),
            center: this._dragonflyMap.getCenter(),
            zoom: this._dragonflyMap.getZoom(),
        });
    }

    _onMapZoom(e) {
        this.emit('MAP_ZOOM', {
            source: this,
            originalEvent: e,
            boundingBox: this._dragonflyMap.getBounds().toArray(),
            center: this._dragonflyMap.getCenter(),
            zoom: this._dragonflyMap.getZoom(),
        });

        // If the info bubble is in HOVER mode:
        // Trigger feature highlighting to change info bubble using last registered mouse move event
        // else (in CLICK mode):
        // Trigger the removal of info bubble
        if (this.infoBubbleMode === InfoBubbleMode.HOVER) {
            this.emit('MAP_MOUSE_MOVE', {
                source: this,
                originalEvent: e,
                interactive: this.isFeatureInteractivityEnabled,
            });
        } else {
            this.emit('MAP_MOUSE_OUT', { source: this, originalEvent: e });
        }
    }

    _onMapZoomEnd(e) {
        this.emit('MAP_ZOOM_END', {
            source: this,
            originalEvent: e,
            boundingBox: this._dragonflyMap.getBounds().toArray(),
            center: this._dragonflyMap.getCenter(),
            zoom: this._dragonflyMap.getZoom(),
        });
    }

    _onMapMouseOut(e) {
        // When in CLICK mode we do not want the info bubble to be removed when mouse leaves the map
        if (this.infoBubbleMode === InfoBubbleMode.HOVER) {
            this.emit('MAP_MOUSE_OUT', { source: this, originalEvent: e });
        }
    }

    _onMapMouseMove(e) {
        // When in HOVER mode on mouse move the info bubble must be updated
        // as the user is moving the mouse while when in CLICK mode moving the mouse
        // should have no effect on the info bubble except that this event is used to add
        // the click event to the document (mouseenter event doesn't work as expected)
        if (this.infoBubbleMode === InfoBubbleMode.HOVER) {
            this.emit('MAP_MOUSE_MOVE', {
                source: this,
                originalEvent: e,
                interactive: this.isFeatureInteractivityEnabled,
            });
        } else {
            this.emit('MAP_MOUSE_MOVE_CLICK_MODE', {
                source: this,
                originalEvent: e,
                interactive: this.isFeatureInteractivityEnabled,
            });
        }
    }

    _onMapMouseClick = e => {
        if (this.infoBubbleMode === InfoBubbleMode.CLICK) {
            this.emit('MAP_MOUSE_CLICK', {
                source: this,
                originalEvent: e,
                interactive: this.isFeatureInteractivityEnabled,
            });
        }
    };

    _onMapResize(e) {
        this.emit('MAP_RESIZE', { source: this, originalEvent: e });
    }

    _onMapWebGLContextLost() {
        if (this._dragonflyMap) {
            // Try map rebuild three times before rising the error page
            if (this._mapWebGLContextLostCounter < 3) {
                setTimeout(() => {
                    this._mapWebGLContextLostCounter += 1;
                    console.warn('WEBGL CONTEXT LOST', 'Reconstruct the map');
                    AppConfig.sentryRecordEvent('Reconstruct the map upon Web GL context lost');
                    this.constructDragonflyMap();
                }, 2000);
            } else {
                AppConfig.sentryRecordEvent(
                    'Web GL context lost too many times, error page raised',
                );
                this.emit('MAP_WEBGL_CONTEXT_LOST', { source: this });
            }
        }
    }

    _onMapWebGLInitializationFailed() {
        this.emit('MAP_WEBGL_INITIALIZATION_FAILED', { source: this });
        if (this._dragonflyMap) {
            this.disposeDragonflyMap();
        }
    }

    _onMapWebGLContextRestored() {
        this.emit('MAP_WEBGL_CONTEXT_RESTORED', { source: this });
    }

    disposeDragonflyMap() {
        // unbind map listeners
        this._dragonflyMapHandler.unbind();
        this._dragonflyMap.off('rendered', this.handleMapRendered);
        this._dragonflyMap.off('load', this.boundOnMapLoad);
        this._dragonflyMap.off('move', this.boundOnMapMove);
        this._dragonflyMap.off('zoom', this.boundOnMapZoom);
        this._dragonflyMap.off('zoomend', this.boundOnMapZoomEnd);
        this._dragonflyMap.off('mouseout', this.boundOnMapMouseOut);
        this._dragonflyMap.off('movestart', this.boundOnMapMoveStart);
        this._dragonflyMap.off('moveend', this.boundOnMapMoveEnd);
        this._dragonflyMap.off('mousemove', this.boundOnMapMouseMove);
        this._dragonflyMap.off('resize', this.bounOnMapResize);
        this._dragonflyMap.off(
            'webglinitializationfailed',
            this.boundOnMapWebGLInitializationFailed,
        );
        this._dragonflyMap.off('webglcontextlost', this.boundOnMapWebGLContextLost);
        this._dragonflyMap.off('webglcontextrestored', this.boundOnMapWebGLContextRestored);
        this._dragonflyMap.off();
        this._dragonflyMap.remove();
        this._dragonflyMap = undefined;
        // remove controls
        this._dragonflyMapControls = {};
    }

    constructDragonflyMap() {
        if (this._dragonflyMap) {
            this.disposeDragonflyMap();
        }

        this._currentMap = this.currentMaps[this.props.mapInstance.currentMapId].clone();
        const mapOptions = Object.assign(
            {
                dynamicLabelsContrast: this._currentMap.isDynamicLabelsContrastEnabled,
                customMapMarkers: true,
                // used in MapHandler, not MapData
                userLocation: true,
            },
            this.props,
        );
        this._dragonflyMapData = new MapData(this._currentMap, this.props.mapInstance, mapOptions);

        const transition =
            this.props.annotationsState === 'editor'
                ? { duration: 0, delay: 0 }
                : {
                      duration: 200,
                      delay: 0,
                  };

        const useTwoFingerPan = this.context.isIframe && this.context.isMobileDevice;

        const mapJSON = {
            container: this.map,
            touchZoomRotate: !useTwoFingerPan,
            dragPan: !useTwoFingerPan,
            dragonflyTouchZoom: useTwoFingerPan,
            dragonflyTouchPan: useTwoFingerPan,
            dragRotate: false,
            style: {
                version: this._currentMap.version,
                glyphs: this._currentMap.glyphs,
                sprite: this._currentMap.sprite,
                transition,
                sources: this._dragonflyMapData.sources,
                layers: this._dragonflyMapData.layers,
            },
            zoom: this.props.mapInstance.initialView.zoom,
            selectionMode: this.props.selection,
            center: this.props.mapInstance.initialView.center,
            clickDistanceTolerance: 5,
            preserveDrawingBuffer: true,
            attributionControl: false,
            minZoom: this._dragonflyMapData.minZoom,
            maxZoom: this._dragonflyMapData.maxZoom,
        };

        // create dragonfly map
        this._dragonflyMap = new dragonfly.Map(mapJSON);
        this._dragonflyMap.once(
            'webglinitializationfailed',
            this.boundOnMapWebGLInitializationFailed,
        );
        this._dragonflyMap.once('webglcontextlost', this.boundOnMapWebGLContextLost);
        this._dragonflyMap.once('webglcontextrestored', this.boundOnMapWebGLContextRestored);
        this._dragonflyMap.once('load', this.boundOnMapLoad);

        // bind map handlers
        this._dragonflyMapHandler = new MapHandler(this, mapOptions);
        this._dragonflyMapHandler.bind();

        this._dragonflyMap.touchZoomRotate.disableRotation();

        if (window.DingoDebug.mapViewer === undefined) {
            window.DingoDebug.mapViewer = {};
        }

        if (window.DingoDebug.mapStyle === undefined) {
            window.DingoDebug.mapStyle = {};
        }

        window.DingoDebug.mapViewer[this.props.mapInstance.id] = this;
        window.DingoDebug.mapStyle[this.props.mapInstance.id] = mapJSON.style;

        this.emit('DRAGONFLY_MAP_CREATED', { source: this });
    }

    get annotationsReady() {
        if (!this._dragonflyMapHandler || !this._dragonflyMapHandler.annotationsHandler)
            return false;
        return !(this._dragonflyMapHandler.annotationsHandler.dragonflyDraw == null);
    }

    get id() {
        return this.props.mapInstance.id;
    }

    get dragonflyMap() {
        return this._dragonflyMap;
    }

    get dragonflyMapControls() {
        return this._dragonflyMapControls;
    }

    // TODO: try to remove this getter
    get dragonflyDraw() {
        return this._dragonflyMapControls.draw;
    }

    get mapInstance() {
        return this.props.mapInstance;
    }

    get appliedDataTheme() {
        return this.props.mapInstance.dataTheme;
    }

    get isSatelliteView() {
        return this.hasSatelliteLayer && this.satelliteLayer.layout.visibility === 'visible';
    }

    get hasSatelliteLayer() {
        return this.satelliteLayer !== undefined;
    }

    get satelliteLayer() {
        return this._dragonflyMapData
            ? this._dragonflyMapData.mapInstanceData.satelliteLayer
            : undefined;
    }

    // TODO: remove this getter
    get currentBaseMap() {
        return this._currentMap;
    }

    get currentMap() {
        return this._currentMap;
    }

    get dragonflyMapLayers() {
        return this._dragonflyMapData ? this._dragonflyMapData.layers : [];
    }

    get activeSummaryLevel() {
        if (this.preferredSummaryLevel) return this.preferredSummaryLevel;
        return this.automaticSummaryLevel;
    }

    get preferredSummaryLevel() {
        if (!this._currentMap) return undefined;
        return this._currentMap.getSummaryLevelForDataSourceId(
            this.props.mapInstance.preferredDataLayerId,
        );
    }

    get automaticSummaryLevel() {
        let currentZoom;
        if (this._dragonflyMap) {
            currentZoom = this._dragonflyMap.getZoom();
        } else {
            currentZoom = this.props.mapInstance.initialView.zoom;
        }
        return this._currentMap ? this._currentMap.getSummaryLevelOnZoom(currentZoom) : undefined;
    }

    get higlightLayerId() {
        return this._dragonflyMapData
            ? this._dragonflyMapData.mapInstanceData.highlightLayer.id
            : undefined;
    }

    get higlightLayer() {
        return this._dragonflyMapData
            ? this._dragonflyMapData.mapInstanceData.highlightLayer
            : undefined;
    }

    get dataLayers() {
        return this._dragonflyMapData ? this._dragonflyMapData.mapInstanceData.dataLayers : [];
    }

    get dataPlaceholderLayerId() {
        if (this._currentMap && this._currentMap.dataPlaceholder) {
            return this._currentMap.dataPlaceholder.id;
        }
        return undefined;
    }

    get dragonflyMapData() {
        return this._dragonflyMapData;
    }

    get visibleTilesInViewport() {
        // check all source caches
        // there are tiles for each source
        // getVisibleCoordinates() returns tiles for each source
        // return max tiles of each source as number of visible tiles in viewport
        return Math.max(
            ...Object.values(this._dragonflyMap.style.sourceCaches).map(
                sourceCache => sourceCache.getVisibleCoordinates().length,
            ),
        );
    }

    handleResize() {
        window.requestAnimationFrame(() => {
            setTimeout(() => {
                if (this._dragonflyMap) {
                    this._dragonflyMap.resize();
                }
            }, 0);

            if (this.props.onResize) {
                this.props.onResize();
            }
        });
    }
}

MapViewer.propTypes = {
    id: PropTypes.string,
    view: PropTypes.bool,
    search: PropTypes.bool,
    location: PropTypes.bool,
    selection: PropTypes.bool,
    userData: PropTypes.bool,
    libraryData: PropTypes.bool,
    interactivity: PropTypes.bool,
    annotations: PropTypes.bool,
    mask: PropTypes.bool,
    report: PropTypes.bool,
    customMapSelection: PropTypes.bool,
    onImageReady: PropTypes.func,
    generateThumbnail: PropTypes.bool,
    annotationsState: PropTypes.string,
    leftMap: PropTypes.bool,
};

MapViewer.defaultProps = {
    view: true,
    search: true,
    location: true,
    selection: false,
    userData: true,
    libraryData: true,
    interactivity: true,
    annotations: false,
    mask: false,
    report: false,
    generateThumbnail: false,
    annotationsState: 'static',
    customMapSelection: true,
    onImageReady: undefined,
    leftMap: undefined,
};

export default MapViewer;
