import React from 'react';
import { injectIntl } from 'react-intl';

import BusComponent from '../../BusComponent';
import AppConfig from '../../../appConfig';

class AutoAdjustFailedPopup extends BusComponent {
    closePopover = () => {
        this.emit('CLOSE_MODAL');
    }

    render() {
        const { intl } = this.props;
        return (
            <div className="auto-adjust-failed-content">
                <p>
                    {intl.formatMessage({ id: 'modals.autoAdjustFailedDescription' },
                        {
                            limit: AppConfig.constants.autoAdjustGeographiesLimit,
                        },
                    )}
                </p>
                <p>
                    {intl.formatMessage({ id: 'modals.autoAdjustFailedOptions' })}
                </p>
                <ul>
                    <li>
                        {intl.formatMessage({ id: 'modals.autoAdjustFailedOption1' })}
                    </li>
                    <li>
                        {intl.formatMessage({ id: 'modals.autoAdjustFailedOption2' })}
                    </li>
                </ul>
                <div className="modal-box--auto-adjust-failed__footer flex-it row flex-end">
                    <button className="btn btn-raised" onClick={this.closePopover}>
                        {intl.formatMessage({ id: 'okGotIt' })}
                    </button>
                </div>
            </div>
        );
    }
}

export default injectIntl(AutoAdjustFailedPopup);
