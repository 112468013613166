// @ts-check
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import FilterCombiner from '../../enums/FilterCombiner';

import BusComponent from '../BusComponent';
import TypeDropdown from '../dataFilter/TypeDropdown';
import Filter from './Filter';
import NewCriterion from './NewCriterion';
import AppConfig from '../../appConfig';
import classNames from 'classnames';

/**
 * @typedef State
 * @property {import('../../').PointsFilterField[]} filters
 * @property {import('../../').AppliedPointFilters} appliedFilters
 * @property {import('../../').PointsFilterField[]} addedCriteria
 * @property {string} filterCombinerType
 * @property {boolean} hidden
 * @property {() => void} onClose
 *
 * @typedef Props
 * @property {import('react-intl').intlShape} intl
 * @property {boolean} hidden
 *
 * @extends {BusComponent<Props, State>}
 */
class FilterPanel extends BusComponent {
    constructor(props, context) {
        super(props, context);
        /** @type {State} */
        this.state = {
            filters: [],
            appliedFilters: {},
            filterCombinerType: FilterCombiner.MATCH_ALL.type,
            addedCriteria: [],
        };
    }

    componentDidMount() {
        this.bindGluBusEvents({
            POINTS_FILTERS_RESPONSE: this.onPointsFiltersResponse,
            CLEAR_FACILITY_FILTER_FROM_PANEL: this.clearFilters,
        });
        // Toggle clustering only if user is on zoom level higher than 9
        if (this.props.mapInstance.initialView.zoom < 9 && AppConfig.constants.shouldShowPointClusterToggle) {
            this.emit('TOGGLE_CLUSTERING');
        }
        this.emit('POINTS_FILTERS_REQUEST');
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    /**
     *
     * @param {import('../../').PointsFilterField[]} filters
     * @param {import('../../').PointsFilterField[]} receivedAddedCriteria
     * @param {import('../../').AppliedPointFilters} receivedAppliedFilters
     * @property {string} receivedFilterCombinerType
     */
    onPointsFiltersResponse = (filters, receivedAddedCriteria, receivedAppliedFilters, receivedFilterCombinerType) => {
        // Figure out if there are criteria and filters that need to be added by default
        // Those filters should have filterType and value already set
        const appliedFilters = Object.keys(receivedAppliedFilters).length > 0 ? receivedAppliedFilters : { ...this.state.appliedFilters };
        const addedCriteria = receivedAddedCriteria.length > 0 ? receivedAddedCriteria : [...this.state.addedCriteria];
        const filterCombinerType = receivedFilterCombinerType === null ? this.state.filterCombinerType : receivedFilterCombinerType;
        filters
            .filter(filter => filter.value !== undefined && filter.filterType)
            .forEach((filter, idx) => {
                const key = `${filter.property}${idx}`;
                appliedFilters[key] = {
                    label: filter.label,
                    type: filter.type,
                    value: filter.value,
                    filterType: filter.filterType,
                };
                const newFilter = { ...filter, property: key };
                addedCriteria.push(newFilter);
            });
        this.setState({ filters, appliedFilters, addedCriteria, filterCombinerType });
    };

    /**
     *
     * @param {import('../../').PointsFilterField} filter
     * @param {string | number} value
     * @param {import('../../').FilterType} filterType
     */
    addFilter = (filter, value, filterType) => {
        let criteria = [];
        if (this.state.addedCriteria.length > 0 && value !== null) {
            criteria = this.state.addedCriteria;
            const singleCriteria = criteria.find(crit => {
                if (crit.property === filter.property) {
                    return crit;
                }
                return null;
            });
            if (singleCriteria) {
                singleCriteria.value = value;
                singleCriteria.filterType = filterType;
                const index = criteria.indexOf(singleCriteria);
                criteria[index] = singleCriteria;
            }
        }
        this.setState({
            appliedFilters: {
                ...this.state.appliedFilters,
                [filter.property]: {
                    label: filter.label,
                    type: filter.type,
                    value,
                    filterType,
                },
            },
            addedCriteria: criteria,
        });
    };

    /**
     *
     * @param {string} property
     */
    removeFilter = property => {
        const appliedFilters = {
            ...this.state.appliedFilters,
        };
        delete appliedFilters[property];
        if (Object.keys(appliedFilters).length === 0) {
            this.emit('HIDE_FILTER_AND_MASK_INDICATOR');
        }
        this.setState({ appliedFilters });
    };

    applyFilters = () => {
        this.emit('APPLY_POINTS_FILTERS_REQUEST', {
            filterCombinerType: this.state.filterCombinerType,
            appliedFilters: this.state.appliedFilters,
            addedCriteria: this.state.addedCriteria,
        });
    };

    clearFilters = () => {
        this.setState({
            filters: [],
            appliedFilters: {},
            addedCriteria: [],
            filterCombinerType: FilterCombiner.MATCH_ALL.type
        });
        this.emit('CLEAR_FACILITY_FILTER');
        this.emit('HIDE_FILTER_AND_MASK_INDICATOR');
    };

    /** @param {string} filterCombinerType */
    onCombinerChange = filterCombinerType => {
        this.setState({ filterCombinerType });
    };

    addCriterion = filter => {
        // Make a new property value for the filter. Use current value and append Datetime to it
        const filterProperty = `${filter.property}@${Date.now()}`;
        // add to applied filters
        this.addFilter({ ...filter, property: filterProperty }, filter.value, filter.filterType);
        this.setState({ addedCriteria: [...this.state.addedCriteria, { ...filter, property: filterProperty }] });
    };

    removeCriterion = filter => {
        const addedCriteria = [...this.state.addedCriteria];
        const indexOfCriteriaWithProperty = addedCriteria.findIndex(
            f => f.property === filter.property,
        );
        if (indexOfCriteriaWithProperty !== -1) {
            addedCriteria.splice(indexOfCriteriaWithProperty, 1);
        }
        this.emit('REMOVE_FACILITY_FILTER', {
            appliedFiltersProperty: filter.property,
        });
        // remove from applied filters
        this.removeFilter(filter.property);
        this.setState({ addedCriteria });
    };

    render() {
        const filters = this.state.addedCriteria.map(filter => (
            <div
                key={filter.property}
                className="flex-it center filter-wrapper"
            >
                <Filter
                    filter={filter}
                    addFilter={this.addFilter}
                    removeFilter={this.removeFilter}
                />
                <div className="flex-it center">
                    <div className="divider divider--vertical divider--btn-separator" />
                    <button
                        className="btn-icon btn-icon--small"
                        onClick={() => this.removeCriterion(filter)}
                    >
                        <i className="material-icons">close</i>
                    </button>
                </div>
            </div>
        ));

        return (
            <div
                className={classNames('points-filter-panel', {
                    hidden: this.props.hidden,
                })}
            >
                <div className="flex-it center points-filter-panel__header">
                    <FormattedMessage
                        id={`${AppConfig.constants.searchBox.findByCriteriaDescription}`}
                        values={{
                            chunk: (
                                <TypeDropdown
                                    value={this.state.filterCombinerType}
                                    onChange={this.onCombinerChange}
                                    types={Object.values(FilterCombiner).map(
                                        type => ({
                                            ...type,
                                            text: this.props.intl.formatMessage(
                                                {
                                                    id: type.text,
                                                },
                                            ),
                                        }),
                                    )}
                                    dropdownClassName="simple-dropdown--primary-rounded"
                                />
                            ),
                        }}
                    />
                </div>
                <div className="points-filter-panel__filters">{filters}</div>
                <div className="flex-it center points-filter-panel__criterion">
                    <NewCriterion
                        addCriterion={this.addCriterion}
                        addedCriteria={this.state.addedCriteria}
                    />
                </div>
                <div className="flex-it center flex-end points-filter-panel__footer">
                    {Object.keys(this.state.appliedFilters).length > 0 &&
                        <button
                            className="points-filter-panel__clear-filters"
                            onClick={this.clearFilters}
                        >
                            Clear filters
                        </button>
                    }
                    <button
                        className="btn-raised"
                        disabled={
                            !Object.keys(this.state.appliedFilters).length
                        }
                        onClick={this.applyFilters}
                    >
                        {this.props.intl.formatMessage({
                            id: `${AppConfig.constants.filterButtonText}`,
                        })}
                    </button>
                </div>
            </div>
        );
    }
}

export default injectIntl(FilterPanel);
