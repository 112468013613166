// @ts-check
import LocationAnalysisType from '../enums/LocationAnalysisType';

/**
 * @typedef LocationAnalysisItemDefinition
 * @property { number | string } id
 * @property {string} type
 * @property {string} icon
 * @property {string} value
 * @property {object} metadata
 * @property {object} itemOriginMetadata
 * @property {import('mapbox-gl').LngLat} point
 * @property {string} itemOrigin
 * @property {string} [analysisTypeId]
 * @property {Set<number>} [selection]
 * @property {string} [selectionType]
 * @property {string} [mapboxRoutingProfile]
 * @property {import('./LocationContourData').default} [sourceData]
 */

class LocationAnalysisItem {
  /** @param {LocationAnalysisItemDefinition} values */
  constructor(values) {
    this.selection = new Set();
    /**
     * We want our isGeoAvailable property to be false by default
     * because every time we switch analysis type we want it to
     * be false, except when our analysis type is Geo, then we
     * explicitly set it to true. This parameter is used to
     * filter our analysis types
     */
    this.isGeoAvailable = false;
    if (values) {
      Object.keys(values).forEach(k => {
        if (this.constructor.prototype.hasOwnProperty(k)) {
          this[k] = values[k];
        }
      });
    }
  }

  set id(id) {
    this._id = id;
  }

  /** @returns {number | string} */
  get id() {
    return this._id;
  }

  set point(coordinates) {
    this._point = {
      lng: coordinates.lng,
      lat: coordinates.lat,
    };
  }

  /** @return {import('../').Point} */
  get point() {
    return this._point;
  }

  get contourType() {
    return LocationAnalysisType[this._analysisTypeId].CONTOUR_TYPE;
  }

  /** @returns {string} */
  get type() {
    return this._type;
  }

  set type(type) {
    this._type = type;
  }

  /** @returns {object} */
  get metadata() {
    return this._metadata;
  }

  set metadata(metadata) {
    this._metadata = metadata;
  }

  /** @returns {string} */
  get value() {
    return this._value;
  }

  set value(value) {
    this._value = value;
  }

  get itemOriginMetadata() {
    return this._itemOriginMetadata;
  }

  set itemOriginMetadata(value) {
    this._itemOriginMetadata = value;
  }

  /** @returns {Set<number>} */
  get selection() {
    return this._selection;
  }

  /** @returns {number[]} */
  get sortedSelectionAsArray() {
    return [...this._selection].sort((a, b) => a - b);
  }

  /** @param {Set<number>} selection */
  set selection(selection) {
    this._selection = selection;
  }

  /** @returns {string} */
  get selectionType() {
    return this._selectionType;
  }

  set selectionType(selectionType) {
    this._selectionType = selectionType;
  }

  /** @returns {import('../').MapboxRoutingProfile | undefined}*/
  get mapboxRoutingProfile() {
    return this.analysisType.MAPBOX_ROUTING_PROFILE;
  }

  /** @returns {import('./LocationContourData').default} */
  get sourceData() {
    return this._sourceData;
  }

  /** @param sourceData {import('./LocationContourData').default}*/
  set sourceData(sourceData) {
    this._sourceData = sourceData;
  }

  /** @returns {import('../').LocationAnalysisTypeId} */
  get analysisTypeId() {
    return this._analysisTypeId;
  }

  /** @param analysisTypeId {import('../').LocationAnalysisTypeId} */
  set analysisTypeId(analysisTypeId) {
    this._analysisTypeId = analysisTypeId;
  }

  get analysisType() {
    return LocationAnalysisType[this._analysisTypeId];
  }

  set icon(icon) {
    this._icon = icon;
  }

  get icon() {
    return this._icon;
  }

  /** @returns {import('../').ReportRingType | undefined} */
  get reportRingType() {
    switch (this.analysisType.ID) {
      case 'DRIVING_TIME':
      case 'WALKING_TIME':
      case 'CYCLING_TIME':
        return this.analysisType.MAPBOX_ROUTING_PROFILE;
      case 'RADIUS':
        return 'radius';
      default:
        return undefined;
    }
  }

  /** @returns {boolean} */
  get isIsochrone() {
    switch (this.analysisType.ID) {
      case 'DRIVING_TIME':
      case 'WALKING_TIME':
      case 'CYCLING_TIME':
        return true;
      default:
        return false;
    }
  }

  /** @returns {boolean} */
  get isGeoAvailable() {
    return this._isGeoAvailable;
  }

  /** @param isGeoAvailable {boolean} */
  set isGeoAvailable(isGeoAvailable) {
    this._isGeoAvailable = isGeoAvailable;
  }

  // Used to determine which popup should be rendered
  /** @returns {string} */
  get searchBoxOrigin() {
    return this._searchBoxOrigin;
  }

  set searchBoxOrigin(searchBoxOrigin) {
    this._searchBoxOrigin = searchBoxOrigin;
  }

  get feature() {
    return this._feature;
  }

  set feature(feature) {
    this._feature = feature;
  }

  /** @returns {boolean} */
  get isGeography() {
    return this.analysisType != null
      ? this.analysisType.ISGEOGRAPHY === true
      : false;
  }

  // return the selection distance labels
  get polygonLabelFeatures() {
    /**
     * Based on our analysis type we have different
     * calculations for our labels. Because of that
     * we have separate functions that we call, both
     * of them return labelFeatures that we loop trough
     * and push to our features array.
     */
    let labelFeatures;
    const features = [];
    switch (this._analysisTypeId) {
      case 'GEO': {
        labelFeatures = this.geographyLabelFeatures;
        break;
      }
      case 'RADIUS':
      case 'DRIVING_TIME':
      case 'WALKING_TIME':
      case 'CYCLING_TIME': {
        labelFeatures = this.distanceLabelFeatures;
        break;
      }
      default: {
        console.error('No analysis type');
      }
    }

    labelFeatures.forEach(labelFeature => {
      const { label, coordinates, geometry } = labelFeature;
      // Distance point label
      features.push({
        type: 'Feature',
        properties: {
          label,
        },
        geometry: {
          type: 'Point',
          coordinates,
        },
      });
      // Distance on-line label
      features.push({
        type: 'Feature',
        properties: {
          label,
        },
        geometry: {
          ...geometry,
          type: 'MultiLineString',
        },
      });
    });
    return { features, type: 'FeatureCollection' };
  }

  get distanceLabelFeatures() {
    const labelFeatures = this.selectionFeatures.features.map(dataFeature => {
      const { properties, geometry } = dataFeature;
      const { value } = properties;
      const label = this.isIsochrone ? `${value} min` : `${value} mi.`;
      // Isochrone coordinates go counter clockwise and we want the label to display a upper right, so approx 2/3 of the length.
      // Circle label should be rendered most south, so taking the middle point is solution
      const onLineCoordinateIndex = this.isIsochrone
        ? Math.floor((2 * geometry.coordinates[0].length) / 3)
        : Math.floor(geometry.coordinates[0].length / 2);
      const coordinates = geometry.coordinates[0][onLineCoordinateIndex];
      return { label, coordinates, geometry };
    });

    return labelFeatures;
  }

  get geographyLabelFeatures() {
    let indexOflargestPolygon;
    let singlePolygon;
    // @ts-ignore
    const label = this.sourceData.query.value;
    // @ts-ignore
    const labelFeatures = this.sourceData.data.features.map(dataFeature => {
      /**
       * We loop trough every feature in data and take it's
       * geometry, then if our geometry is multipolygon we
       * take the largest polygon (polygon with the most dots)
       * and use it's coordinates to place our label on the
       * first coodrinates in that polygon. If our geometry
       * is polygon we take first coordinates array and use
       * first coordinate to place our label on.
       */
      const { geometry } = dataFeature;
      if (geometry.coordinates.length > 1) {
        const polygonLengths = geometry.coordinates.map(
          coordinate => coordinate.length,
        );
        indexOflargestPolygon = polygonLengths.indexOf(
          Math.max(...polygonLengths),
        );
        singlePolygon = geometry.coordinates[indexOflargestPolygon];
      } else if (geometry.coordinates.length === 1) {
        indexOflargestPolygon = 0;
        singlePolygon = geometry.coordinates[0];
      } else {
        console.error('No polygon loaded');
      }
      // Used same logic that we have for isochrone labels
      const onLineCoordinateIndex = Math.floor(
        (2 * singlePolygon[0].length) / 3,
      );
      const coordinates = singlePolygon[0][onLineCoordinateIndex];
      return { label, coordinates, geometry };
    });

    return labelFeatures;
  }

  // return the features for the selected profile and selections
  /** @returns {import('../').GeoJson} */
  get selectionContourFeatures() {
    /** @type {import('../').Feature[]} */
    const features = [];

    // Data for one of the profiles
    const selectedProfileData = this.sourceData[this.contourType];

    this.sortedSelectionAsArray.forEach(selection => {
      if (selectedProfileData[selection]) {
        features.push(selectedProfileData[selection]);
      }
    });

    return { features, type: 'FeatureCollection' };
  }

  get selectionGeographyFeatures() {
    // @ts-ignore
    return this.sourceData.data;
  }

  /** @returns {import('../').GeoJson} */
  get selectionFeatures() {
    switch (this.contourType) {
      case 'custom_geo':
        return this.selectionGeographyFeatures;
      default:
        return this.selectionContourFeatures;
    }
  }

  /** @returns {boolean} to be complete the analysis type has to be selected and at least one selection active,
   * or it has to be a geographical analysis that has no selection.
   * */
  get isComplete() {
    return (
      this._analysisTypeId !== undefined &&
      (this._selection.size > 0 || this.isGeography)
    );
  }

  /** @returns {string} */
  get itemOrigin() {
    return this._itemOrigin;
  }

  set itemOrigin(itemOrigin) {
    this._itemOrigin = itemOrigin;
  }

  /**
   * @param {LocationAnalysisItem} that
   * @returns {boolean}
   * returns true if center points are equal
   */
  equalCenter(that) {
    return (
      this.point.lat === that.point.lat && this.point.lng === that.point.lng
    );
  }

  clone() {
    return new LocationAnalysisItem({
      id: this.id,
      type: this.type,
      value: this.value,
      point: this.point,
      analysisTypeId: this.analysisTypeId,
      selection: new Set(this.selection),
      itemOrigin: this.itemOrigin,
      isGeoAvailable: this.isGeoAvailable,
      searchBoxOrigin: this.searchBoxOrigin,
      feature: this.feature,
      icon: this.icon,
      itemOriginMetadata: this.itemOriginMetadata,
      sourceData: Object.assign({}, this.sourceData),
    });
  }
}

export default LocationAnalysisItem;
