import React from 'react';
import { injectIntl } from 'react-intl';
import BusComponent from '../BusComponent';
import PropTypes from 'prop-types';
import Checkbox from '../form/Checkbox';
import SatelliteOptions from './MapSatelliteOptions';
import AppConfig from '../../appConfig';

class MapSatellite extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            visible: props.visible,
            satelliteOptionsVisible: props.visible,
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            visible: nextProps.visible,
            satelliteOptionsVisible: nextProps.visible,
        });
    }

    onToggleVisibility = visible => {
        const { mapInstance } = this.props;
        if (visible) {
            this.emit('SHOW_SATELLITE_REQUEST', { mapInstanceId: mapInstance.id });
        } else {
            this.emit('HIDE_SATELLITE_REQUEST', { mapInstanceId: mapInstance.id });
        }
        this.setState({
            visible,
        });
    }

    onToggleSatelliteOptionsVisibility = () => {
        this.setState({
            satelliteOptionsVisible: !this.state.satelliteOptionsVisible,
        });
    }

    render() {
        const { visible, satelliteOptionsVisible } = this.state;
        const { mapInstance, title } = this.props;
        return (
            <div className="map-satellite flex-it column">
                <div className="map-layer flex-it center space-between">
                    <div className="map-layer__title-wrapper flex-it center grow">
                        <img className="map-satellite__image" src={`${AppConfig.constants.assetsBaseURL}/icons/sat_map.png`} alt="map_type" />
                        <span className="body-1 map-layer__title">{title}</span>
                    </div>
                    <div className="flex-it center">
                        {visible && <div className="flex-it center">
                            <button
                                className="material-icons btn-icon"
                                onClick={this.onToggleSatelliteOptionsVisibility}
                                aria-expanded={satelliteOptionsVisible}
                                aria-label={satelliteOptionsVisible ? this.props.intl.formatMessage({ id: 'showHide.hide.satelliteViewOptions' }) : this.props.intl.formatMessage({ id: 'showHide.show.satelliteViewOptions' })}
                            >
                                {satelliteOptionsVisible ? 'arrow_drop_up' : 'arrow_drop_down'}
                            </button>
                            <div className="divider divider--vertical divider--btn-separator" />
                        </div>}
                        <Checkbox
                            checked={visible}
                            onCheck={this.onToggleVisibility}
                        />
                    </div>
                </div>
                {satelliteOptionsVisible && <SatelliteOptions mapInstance={mapInstance} />}
            </div>
        );
    }
}

MapSatellite.propTypes = {
    title: PropTypes.string.isRequired,
    visible: PropTypes.bool,
    mapInstance: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,

};
MapSatellite.defaultProps = {
    visible: false,
};

export default injectIntl(MapSatellite);
