import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import SimpleDropdown from '../dropdown/SimpleDropdown';

const ValueDropDown = ({ options, value, onChange, className, dropdownClassName, intl }) => {
    const selectedItem = options.find(option => option === value);
    return (
        <SimpleDropdown
            onItemClick={onChange}
            items={options.map(opt => ({ id: opt, text: opt }))}
            selectedItem={selectedItem === undefined ? { id: '0', text: intl.formatMessage({ id: 'dataBrowser.chooseVariable' }) } : { id: selectedItem, text: selectedItem }}
            name="filter-value-dropdown"
            selectedItemClassName={className}
            className={dropdownClassName}
        />
    );
};

ValueDropDown.propTypes = {
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    value: PropTypes.string,
    className: PropTypes.string,
    dropdownClassName: PropTypes.string,
    intl: PropTypes.object.isRequired,
};

ValueDropDown.defaultProps = {
    value: undefined,
    className: '',
    dropdownClassName: '',
};

export default injectIntl(ValueDropDown);
