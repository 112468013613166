class MaskData {
    constructor(mapInstance, mapConfig, layers, sources) {
        this._mapConfig = mapConfig;
        this._mapInstance = mapInstance;

        this._dragonflySourceId = Object.keys(sources).find(id => id.startsWith('socialexplorerdata'));
        this._dataHighlightLayer = layers.find(dl => dl.metadata.isHighlightLayer);

        this._maskSourceLayerId = this._dataHighlightLayer['auto-source'][0]['source-layer'];
        this._maskDragonflyLayers = [];
        this._maskDataDragonflyLayer = undefined;
        this._maskHighlightDragonflyLayer = undefined;

        this._buildMaskDataDragonflyLayer();
        this._buildMaskHighlightDragonflyLayer();
        this._maskDragonflyLayers.forEach(dl => layers.push(dl));
    }

    get dataLayer() {
        return this._maskDataDragonflyLayer;
    }

    get highlightLayer() {
        return this._maskHighlightDragonflyLayer;
    }

    get layers() {
        return this._maskDragonflyLayers;
    }

    applySummaryLevelUpdate() {
        this._maskSourceLayerId = this._dataHighlightLayer['auto-source'][0]['source-layer'];
        this._maskDragonflyLayers.forEach(dl => {
            dl['source-layer'] = this._maskSourceLayerId;
            dl.minzoom = this._dataHighlightLayer.minzoom;
            dl.maxzoom = this._dataHighlightLayer.maxzoom;
            dl.metadata.defaultMinZoom = dl.minzoom;
            dl.metadata.defaultMaxZoom = dl.maxzoom;
        });
    }

    _buildMaskDataDragonflyLayer() {
        this._maskDataDragonflyLayer = {
            id: 'filter-data-layer',
            metadata: {
                defaultMinZoom: this._dataHighlightLayer.minzoom,
                defaultMaxZoom: this._dataHighlightLayer.maxzoom,
                socialexplorer: {},
            },
            type: 'fill',
            paint: {
                'fill-color': 'rgba(0,0,0,0)',
            },
            minzoom: this._dataHighlightLayer.minzoom,
            maxzoom: this._dataHighlightLayer.maxzoom,
            source: this._dragonflySourceId,
            'source-layer': this._maskSourceLayerId,
        };
        this._maskDragonflyLayers.push(this._maskDataDragonflyLayer);
    }

    _buildMaskHighlightDragonflyLayer() {
        this._maskHighlightDragonflyLayer = {
            id: 'filter-highlight-layer',
            metadata: {
                defaultMinZoom: this._dataHighlightLayer.minzoom,
                defaultMaxZoom: this._dataHighlightLayer.maxzoom,
                socialexplorer: {},
            },
            type: 'line',
            paint: {
                'line-color': 'rgba(0,0,0,0)',
            },
            minzoom: this._dataHighlightLayer.minzoom,
            maxzoom: this._dataHighlightLayer.maxzoom,
            source: this._dragonflySourceId,
            'source-layer': this._maskSourceLayerId,
        };
        this._maskDragonflyLayers.push(this._maskHighlightDragonflyLayer);
    }
}

export default MaskData;
