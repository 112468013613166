//      

                
                    
                                  
                          
                         
   

const config         = {
    API_URL: 'https://api.mapbox.com',
    SOCEX_API_URL: 'https://s3.amazonaws.com/dev-mapstudio.socialexplorer.com',
    REQUIRE_ACCESS_TOKEN: true,
    ACCESS_TOKEN: null
};

module.exports = config;
