import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class DataSurveyYear extends React.Component {
    onClick = () => {
        this.props.onClick(this.props.year);
    };

    render() {
        const { active, inputRef, year } = this.props;

        const classes = classnames('survey-year flex-it center justify-center', {
            'survey-year--selected': active,
        });

        return (<button
            ref={inputRef}
            className={classes}
            onClick={this.onClick}
        >
            {year}
        </button>);
    }
}

DataSurveyYear.propTypes = {
    year: PropTypes.number.isRequired,
    active: PropTypes.bool,
    inputRef: PropTypes.func,
    onClick: PropTypes.func,
};

DataSurveyYear.defaultProps = {
    active: false,
    inputRef: undefined,
    onClick: undefined,
};

export default DataSurveyYear;
