import GLU from '../glu2.js/src/index';

class UserLocationsApi extends GLU.Api {
  constructor(endpoint) {
    super(endpoint);

    this.createApiActions(
      {
        name: 'getUserLocations',
        method: GLU.Api.Get,
        path: '',
        credentials: true,
      },
      {
        name: 'addUserLocation',
        method: GLU.Api.Post,
        path: '',
        credentials: true,
      },
      {
        name: 'updateUserLocation',
        method: GLU.Api.Post,
        path: '/update',
        credentials: true,
      },
      {
        name: 'deleteUserLocation',
        method: GLU.Api.Delete,
        path: '',
        credentials: true,
      },
    );
  }
}

export default UserLocationsApi;
