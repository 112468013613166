import React from 'react';
import { injectIntl } from 'react-intl';
import AppConfig from '../../appConfig';
import BusComponent from '../BusComponent';

class LoginButton extends BusComponent {
    onClick = () => {
        this.emit('LOGIN_REQUEST', {
            checkCount: 30,
            url: AppConfig.constants.links.login,
        });
    };

    render() {
        return (
            <button
                type="button"
                tabIndex="0"
                key="login"
                className="btn-flat btn-flat--light application-header__button"
                onClick={this.onClick}
            >
                {this.props.intl.formatMessage({ id: 'header.logIn' })}
            </button>
        );
    }
}

export default injectIntl(LoginButton);
