import GLU from '../glu2.js/src/index';

class ProjectApi extends GLU.Api {
  constructor(endpoint) {
    super(endpoint);

    this.createApiActions(
      {
        name: 'getProject',
        method: GLU.Api.Get,
        path: '/project',
        credentials: true,
      },
      {
        name: 'saveProject',
        method: GLU.Api.Post,
        path: '/project/{projectId}',
        credentials: true,
      },
      {
        name: 'mapProject',
        method: GLU.Api.Post,
        path: '/project',
        credentials: true,
      },
      {
        name: 'editProjectInfo',
        method: GLU.Api.Post,
        path: '/project/{projectId}/rename',
        credentials: true,
      },
      {
        name: 'canSaveProject',
        method: GLU.Api.Get,
        path: '/can_save',
        credentials: true,
      },
      {
        name: 'updateProjectVisibility',
        method: GLU.Api.Post,
        path: '/project/{projectId}/update-visibility',
        credentials: true,
      },
    );
  }
}

export default ProjectApi;
