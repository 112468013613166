import Api from '../apis/Api';
import GLU from '../glu2.js/src/index';
import AppConfig from '../appConfig';

class UploadDataSource extends GLU.DataSource {
    static get name() {
        return 'UploadDataSource';
    }

    static getInstance() {
        return new UploadDataSource();
    }

    signS3(e) {
        const query = {
            key: e.key,
        };
        return new Promise((resolve, reject) => {
            Api.s3.sign({ query })
                .then(r => {
                    const signedUrl = r.text;
                    resolve({
                        signedUrl,
                    });
                }, reject);
        });
    }

    upload(e) {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.onload = () => {
                if (xhr.status >= 200 && xhr.status < 300) {
                    resolve(xhr.response);
                } else {
                    reject({
                        status: xhr.status,
                        statusText: xhr.statusText,
                    });
                }
            };
            xhr.onerror = () => {
                reject({
                    status: xhr.status,
                    statusText: xhr.statusText,
                });
            };
            xhr.open('PUT', e.signedUrl, true);
            xhr.send(e.data);
        });
    }

}

export default UploadDataSource;
