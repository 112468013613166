//      

                             
                                                
                                         
                                                      

/**
 * @typedef {Object} MapMouseEvent
 * @property {string} type The event type.
 * @property {Map} target The `Map` object that fired the event.
 * @property {MouseEvent} originalEvent
 * @property {Point} point The pixel coordinates of the mouse event target, relative to the map
 *   and measured from the top left corner.
 * @property {LngLat} lngLat The geographic location on the map of the mouse event target.
 */
                             
                     
                   
                 
                    
                     
                      
                      
                     
                    
                        
             
                              
                 
                  
  

/**
 * @typedef {Object} MapTouchEvent
 * @property {string} type The event type.
 * @property {Map} target The `Map` object that fired the event.
 * @property {TouchEvent} originalEvent
 * @property {Point} point The pixel coordinates of the center of the touch event points, relative to the map
 *   and measured from the top left corner.
 * @property {LngLat} lngLat The geographic location on the map of the center of the touch event points.
 * @property {Array<Point>} points The array of pixel coordinates corresponding to
 *   a [touch event's `touches`](https://developer.mozilla.org/en-US/docs/Web/API/TouchEvent/touches)
 *   property.
 * @property {Array<LngLat>} lngLats The geographical locations on the map corresponding to
 *   a [touch event's `touches`](https://developer.mozilla.org/en-US/docs/Web/API/TouchEvent/touches)
 *   property.
 */
                             
                      
                    
                        
             
                              
                   
                         
                          
  

/**
 * @typedef {Object} MapBoxZoomEvent
 * @property {MouseEvent} originalEvent
 * @property {LngLatBounds} boxZoomBounds The bounding box of the "box zoom" interaction.
 *   This property is only provided for `boxzoomend` events.
 */
                               
                        
                      
                          
             
                              
                               
  

/**
 * A `MapDataEvent` object is emitted with the {@link Map.event:data}
 * and {@link Map.event:dataloading} events. Possible values for
 * `dataType`s are:
 *
 * - `'source'`: The non-tile data associated with any source
 * - `'style'`: The [style](https://www.mapbox.com/mapbox-gl-style-spec/) used by the map
 *
 * @typedef {Object} MapDataEvent
 * @property {string} type The event type.
 * @property {string} dataType The type of data that has changed. One of `'source'`, `'style'`.
 * @property {boolean} [isSourceLoaded] True if the event has a `dataType` of `source` and the source has no outstanding network requests.
 * @property {Object} [source] The [style spec representation of the source](https://www.mapbox.com/mapbox-gl-style-spec/#sources) if the event has a `dataType` of `source`.
 * @property {string} [sourceDataType] Included if the event has a `dataType` of `source` and the event signals
 * that internal data has been received or changed. Possible values are `metadata` and `content`.
 * @property {Object} [tile] The tile being loaded or changed, if the event has a `dataType` of `source` and
 * the event is related to loading of a tile.
 * @property {Coordinate} [coord] The coordinate of the tile if the event has a `dataType` of `source` and
 * the event is related to loading of a tile.
 */
                            
                 
                    
  

                               
                                                      
                                    
 

                      
       
                                                                                
      
                       
                    
                
                                     
                                                                                                                                    
                                                                                                 
       
                 

       
                                                                                 
      
                     
                    
                
                                     
                                                                                                                                    
                                                                                                 
       
               

       
                                                                              
      
                       
                    
                
                                     
                                                                                                               
                                                                                                                   
                                                                                                   
       
                 

       
                                                                                                           
      
                   
                    
                
                                     
                                                                                     
                                                                                                               
       
             

       
                                                                                                    
      
                      
                    
                
                                     
       
                

       
                                                                                                        
                                                                                                               
                                                                                        
      
                        
                    
                
                                     
       
                  

       
                                                                                                              
                                                                                                            
                                                             
      
                        
                    
                
                                     
                                                                                                                   
       
                  

       
                                
      
                       
                    
                
                                     
       
                 

       
                                                                           
      
                      
                    
                
                                     
       
                

       
                                                                                                             
      
                         
                    
                
                                     
       
                   

       
                                                                                                                               
      
                        
                    
                
                                     
       
                  

       
                                                                                                                           
      
                      
                    
                
                                     
       
                

       
                                                                                                                             
      
                       
                    
                
                                     
       
                 

       
                                                                                                                                 
      
                         
                    
                
                                     
       
                   

       
                                                             
                                                                                                       
      
                       
                    
                
                                                  
       
                 

       
                                                                      
                                                                                              
      
                  
                    
                
                                                     
       
            

       
                                                               
                                                                                                       
      
                     
                    
                
                                                  
                                                                                                                
                                                                                                                           
       
               

       
                                                                                 
      
                       
                    
                
                                                  
       
                 

       
                                                                                       
      
                  
                    
                
                                                     
       
            

       
                                                                               
      
                     
                    
                
                                                  
       
               

       
                                                                                    
                                                                                     
      
                       
                    
                
                                                     
       
                 

       
                                                                                     
                                                                                     
      
                  
                    
                
                                                     
                                                                                                                       
       
            

       
                                                                                      
                                                                                     
      
                     
                    
                
                                                     
       
               

       
                                                                                       
      
                         
                    
                
                                                     
       
                   

       
                                                                                             
      
                    
                    
                
                                                     
       
              

       
                                                                                     
      
                       
                    
                
                                                     
       
                 

       
                                                               
                                                                                   
      
                        
                    
                
                                    
       
                  

       
                                                        
                                                                                  
      
                   
                    
                
                                    
       
             

       
                                                                          
                                                                                  
      
                      
                    
                
                                    
       
                

       
                                                                              
      
                          
                    
                
                                       
       
                    

       
                                                                             
      
                        
                    
                
                     
                                       
       
                  

       
                                                                                                                               
                                  
      
                           
                    
                
                                       
       
                     

       
                                                        
      
                    
                    
                
       
              

       
                                            
      
                              
                    
                
       
                        

       
                                                
      
                                  
                    
                
       
                            

       
                                                                           
                                                                         
      
                  
                    
                
                     
                                                                                               
                                                                                               
                                                                                                    
       
            

       
                                                                      
      
                                                                
                                    
                                     
                                                                     
      
                    
                    
                
       
              

       
                                                                                      
      
                    
                    
                
       
              

       
                                                                          
                                                                          
                                                                                  
                                            
      
                   
                    
                
                                                  
       
             

       
                                                                         
                            
      
                  
                    
                
                                    
       
            

       
                                                       
                                                 
      
                       
                    
                
                                    
       
                 

       
                                                                                          
                                                                                   
      
                        
                    
                
                                    
       
                  

       
                                                                           
                                                                                
                                                                       
      
                         
                    
                
                                    
       
                   

       
                                                                           
                                                                  
                                                                       
      
                              
                    
                
                                    
       
                        

       
                                                                                    
                                                                                      
                                                     
      
                               
                    
                
                                    
       
                         

       
                        
                    
                
               
       
                   
