import React from 'react';
import PropTypes from 'prop-types';
import MarkerPicker from '../markerPicker/MarkerPicker';
import ColorPickerCompact from '../colorPickerCompact/ColorPickerCompact';

const MarkerEditorColorsAndIcons = props => {
    const { markerColor, markerPathId, iconHasBackground, onColorChange, onMarkerChange, onMoreIconsClick } = props;
    const backgroundColor = iconHasBackground ? markerColor : 'transparent';
    const color = iconHasBackground ? '#fff' : markerColor;

    return (
        <div className="marker-editor-compact__content">
            <div className="marker-editor-compact__color-picker">
                <p>Color</p>
                <ColorPickerCompact
                    color={markerColor}
                    onChange={onColorChange}
                />
            </div>
            <div className="marker-editor-compact__divider" />
            <div className="marker-editor-compact__marker-icons">
                <p>Icon</p>
                <MarkerPicker
                    backgroundColor={backgroundColor}
                    color={color}
                    marker={markerPathId}
                    limit={27}
                    onChange={onMarkerChange}
                />
            </div>
            <div className="marker-editor-compact__actions">
                <button className="btn-flat" onClick={onMoreIconsClick}>More icons</button>
            </div>
        </div>
    );
};

MarkerEditorColorsAndIcons.propTypes = {
    markerPathId: PropTypes.string.isRequired,
    markerColor: PropTypes.string.isRequired,
    iconHasBackground: PropTypes.bool,
    onColorChange: PropTypes.func.isRequired,
    onMarkerChange: PropTypes.func.isRequired,
    onMoreIconsClick: PropTypes.func.isRequired,
};

MarkerEditorColorsAndIcons.defaultProps = {
    iconHasBackground: true,
};

export default MarkerEditorColorsAndIcons;
