import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import BusComponent from '../BusComponent';
import classNames from 'classnames';

import Slider from '../form/Slider';
import { addEvent, removeEvent, hasParentNode } from '../../helpers/Util';

const TOOLTIP_POSITION = {
    TOP: 0,
    BOTTOM: 1,
};

class MapZoom extends BusComponent {
    constructor(props, context) {
        super(props, context);

        this.state = {
            minZoom: 0,
            maxZoom: 20,
            zoom: this.props.mapInstance.initialView.zoom,
            isSliderVisible: false,
            isTooltipVisible: false,
            tooltipVerticalPosition: TOOLTIP_POSITION.BOTTOM,
            canZoomIn: this.props.mapInstance.initialView.zoom < 20,
            canZoomOut: this.props.mapInstance.initialView.zoom > 0,
        };

        this.bindGluBusEvents({
            DRAGONFLY_MAP_CREATED: this.onMapViewChanged,
            MAP_ZOOM: this.onMapViewChanged,
            MAP_LOAD: this.onMapViewChanged,
            MAP_MAX_ZOOM_APPLIED: this.onMapViewChanged,
            MAP_MIN_ZOOM_APPLIED: this.onMapViewChanged,
        });
    }

    componentWillUnmount() {
        clearTimeout(this._showTooltipCallback);
        removeEvent(document, 'mousemove', this.onCheckToHideTooltip);
        this.unbindGluBusEvents();
    }

    componentDidUpdate() {
        if (this.state.isTooltipVisible && !this.isTooltipEventAdded) {
            addEvent(this._tooltipButtonNode, 'blur', this.onHideTooltipAfterFocusChange);
            this.isTooltipEventAdded = true;
        } else {
            removeEvent(this._tooltipButtonNode, 'blur', this.onHideTooltipAfterFocusChange);
            this.isTooltipEventAdded = false;
        }
    }

    onMapViewChanged(eventMap) {
        if (eventMap.source.id === this.props.mapInstance.id) {
            if (eventMap.originalEvent && eventMap.originalEvent.isPreciseZoomSlider) {
                return;
            }

            const map = eventMap.source.dragonflyMap;

            this.setState({
                minZoom: map.getMinZoom(),
                maxZoom: map.getMaxZoom(),
                zoom: map.getZoom(),
                canZoomIn: map.getZoom() < map.getMaxZoom(),
                canZoomOut: map.getZoom() > map.getMinZoom(),
            });
        }
    }

    onToggleSlider = () => {
        this.setState({
            isSliderVisible: !this.state.isSliderVisible,
        });
    };

    zoomIn = () => {
        this.emit('MAP_ZOOM_IN_REQUEST', { mapInstanceId: this.props.mapInstance.id });
    };

    zoomOut = () => {
        this.emit('MAP_ZOOM_OUT_REQUEST', { mapInstanceId: this.props.mapInstance.id });
    };

    setZoom = zoom => {
        this.emit('MAP_ZOOM_TO_REQUEST', {
            mapInstanceId: this.props.mapInstance.id,
            zoom,
            isPreciseZoomSlider: true,
        });

        this.setState({
            zoom,
            canZoomIn: zoom < this.state.maxZoom,
            canZoomOut: zoom > this.state.minZoom,
        });
    };

    onRegisterToShowTooltip = e => {
        clearTimeout(this._showTooltipCallback);

        // Determine tooltip position
        let nextVerticalPosition = TOOLTIP_POSITION.BOTTOM;
        if (this.state.isSliderVisible && e.target === this._zoomInBtnNode) {
            nextVerticalPosition = TOOLTIP_POSITION.TOP;
        }

        this._showTooltipCallback = setTimeout(() => {
            this.setState({
                isTooltipVisible: true,
                tooltipVerticalPosition: nextVerticalPosition,
            });
        }, 200);

        addEvent(document, 'mousemove', this.onCheckToHideTooltip);
    }

    hideTooltip = () => {
        clearTimeout(this._showTooltipCallback);
        removeEvent(document, 'mousemove', this.onCheckToHideTooltip);

        this.setState({ isTooltipVisible: false });
    }

    onHideTooltipAfterFocusChange = e => {
        const { relatedTarget } = e;
        if (this._rootNode && relatedTarget && !hasParentNode(relatedTarget, this._rootNode)) {
            this.hideTooltip();
        }
    }

    onCheckToHideTooltip = e => {
        if (this._rootNode && !hasParentNode(e.target, this._rootNode)) {
            this.hideTooltip();
        }
    }

    render() {
        const { minZoom, maxZoom, zoom, canZoomOut, canZoomIn, isSliderVisible } = this.state;
        const { isTooltipVisible, tooltipVerticalPosition } = this.state;
        const { className, alignLeft } = this.props;

        const classes = classNames(className, 'map-controls__group map-zoom flex-it column', {
            'map-zoom--expanded': isSliderVisible,
            'map-zoom--collapsed': !isSliderVisible,
        });

        const tooltipClassess = classNames('map-zoom__tooltip flex-it column center', {
            'map-zoom__tooltip--right': alignLeft,
            'map-zoom__tooltip--left': !alignLeft,
            'map-zoom__tooltip--top': tooltipVerticalPosition === TOOLTIP_POSITION.TOP,
            'map-zoom__tooltip--bottom': tooltipVerticalPosition === TOOLTIP_POSITION.BOTTOM,
        });

        const zoomInButtonClasses = classNames('map-btn map-zoom__btn', {
            'map-btn--disabled': !canZoomIn,
            'map-btn--single': isSliderVisible,
        });

        const zoomOutButtonClasses = classNames('map-btn map-zoom__btn', {
            'map-btn--disabled': !canZoomOut,
            'map-btn--single': isSliderVisible,
        });

        const groupDividerClasses = classNames('map-controls__group-divider', {
            hidden: isSliderVisible,
        });

        return (<div className={classes} ref={root => { this._rootNode = root; }}>
            <button
                className={zoomInButtonClasses}
                ref={btn => { this._zoomInBtnNode = btn; }}
                onMouseEnter={this.onRegisterToShowTooltip}
                onFocus={this.onRegisterToShowTooltip}
                onBlur={this.onHideTooltipAfterFocusChange}
                onClick={this.zoomIn}
                aria-label={this.props.intl.formatMessage({ id: 'map.zoomIn' })}
            >
                <i className="material-icons" >add</i>
            </button>
            <div className={groupDividerClasses} />
            {isSliderVisible &&
                <Slider
                    vertical
                    className="slider--zoom"
                    min={minZoom}
                    max={maxZoom}
                    value={zoom}
                    step={0.1}
                    onChange={this.setZoom}
                    tooltip
                />
            }
            <button
                className={zoomOutButtonClasses}
                onMouseEnter={this.onRegisterToShowTooltip}
                onFocus={this.onRegisterToShowTooltip}
                onBlur={this.onHideTooltipAfterFocusChange}
                onClick={this.zoomOut}
                aria-label={this.props.intl.formatMessage({ id: 'map.zoomOut' })}
            >
                <i className="material-icons" >remove</i>
            </button>
            {isTooltipVisible &&
                <div className={tooltipClassess}>
                    <div className="map-zoom__tooltip__content">
                        <span className="light-text">
                            {this.props.intl.formatMessage({ id: 'map.zoom' })}
                        </span>
                        <button
                            ref={c => { this._tooltipButtonNode = c; }}
                            className="map-zoom__toggle-slider"
                            onClick={this.onToggleSlider}
                        >
                            {isSliderVisible ? this.props.intl.formatMessage({ id: 'showHide.hide.slider' }) : this.props.intl.formatMessage({ id: 'showHide.show.slider' })}
                        </button>
                    </div>
                </div>
            }
        </div>);
    }
}

MapZoom.propTypes = {
    mapInstance: PropTypes.object.isRequired,
    className: PropTypes.string,
    alignLeft: PropTypes.bool,
    intl: PropTypes.object.isRequired,
};

MapZoom.defaultProps = {
    className: '',
    alignLeft: false,
};

export default injectIntl(MapZoom);
