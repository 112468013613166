import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import BusComponent from '../../BusComponent';
import DataVariable from './DataVariable';
import VariableSelection from '../../../objects/VariableSelection';
import VariableSelectionItem from '../../../objects/VariableSelectionItem';
import DataFilterWarningTypes from '../../../enums/DataFilterWarningTypes';

class DataVariablesList extends BusComponent {
    onDataSelectionChange = newDataSelection => {
        const { mapInstanceId } = this.props;

        this.emit('DATA_SELECTION_CHANGE', {
            mapInstanceId,
            newDataSelection,
        });
    };

    resetHighlight = anchorElement => {
        // Hide the checkbox button for survey change in DataList
        const checkboxElement = anchorElement.querySelector('i');
        if (checkboxElement) {
            checkboxElement.classList.add('hidden');
        }
    };

    onSelectedVariable = ({ variable, currentTarget }) => {
        const { hasDataFilter, currentMetadataSelection, mapInstanceId } = this.props;
        const variableSelection = new VariableSelection();
        const newVariableSelection = this._getVariableSelectionFromVariable(variable);
        variableSelection.items = [newVariableSelection];
        const newDataSelection = { variableSelection };

        // If a filter is present and a user selects a variable from a survey that's different
        // from the current variable's survey a warning is shown
        if (
            hasDataFilter &&
            currentMetadataSelection.survey.name !== newVariableSelection.surveyName
        ) {
            // Find div that holds checkbox element
            const anchorElement = currentTarget.lastElementChild;
            // Show the checkbox button for survey change in DataList
            const checkBoxElement = anchorElement.querySelector('i');
            if (checkBoxElement) {
                checkBoxElement.classList.remove('hidden');
            }
            // Show the popup
            this.emit('SHOW_CLEAR_DATA_FILTER_WARNING', {
                mapInstanceId,
                anchorElement,
                position: DataFilterWarningTypes.CHANGE_SURVEY_WARNING,
                onConfirm: () => this.onDataSelectionChange(newDataSelection),
                onCancel: () => this.resetHighlight(anchorElement),
            });
        } else {
            this.emit('DATA_SELECTION_CHANGE', {
                mapInstanceId,
                newDataSelection: { variableSelection },
            });
            this.emit('COUNTER_LOG_REQUEST', [
                {
                    event_type: 'item_request',
                    event_value: variable.uuid,
                },
                {
                    event_type: 'item_investigation',
                    event_value: variable.uuid,
                },
            ]);
        }
    };

    onAddVariableToSelection = variable => {
        const variableSelection = new VariableSelection();
        variableSelection.items = [
            ...this.props.currentMetadataSelection.variables.map(v =>
                this._getVariableSelectionFromVariable(v),
            ),
            this._getVariableSelectionFromVariable(variable),
        ];
        this.emit('DATA_SELECTION_CHANGE', {
            mapInstanceId: this.props.mapInstanceId,
            newDataSelection: { variableSelection },
        });
        const counterEventValue = variableSelection.items.map(v => v.variableGuid).join('|');
        this.emit('COUNTER_LOG_REQUEST', [
            { event_type: 'item_request', event_value: counterEventValue },
            { event_type: 'item_investigation', event_value: counterEventValue },
        ]);
    };

    onRemoveVariableFromSelection = variable => {
        const variableSelection = new VariableSelection();
        variableSelection.items = this.props.currentMetadataSelection.variables
            .filter(v => v.uuid !== variable.uuid)
            .map(v => this._getVariableSelectionFromVariable(v));
        this.emit('DATA_SELECTION_CHANGE', {
            mapInstanceId: this.props.mapInstanceId,
            newDataSelection: { variableSelection },
        });
        const counterEventValue = variableSelection.items.map(v => v.variableGuid).join('|');
        this.emit('COUNTER_LOG_REQUEST', [
            { event_type: 'item_request', event_value: counterEventValue },
            { event_type: 'item_investigation', event_value: counterEventValue },
        ]);
    };

    renderVariables() {
        return this.props.variables.map(variable => {
            const variableSelectionInfo =
                this.props.currentMetadataSelection.variableSelectionInfo.find(
                    info => info.variableGuid === variable.uuid,
                );
            // variable not in current selection so just render standard case
            if (!variableSelectionInfo) {
                return (
                    <DataVariable
                        className="data-variables-list__item"
                        key={variable.uuid}
                        variable={variable}
                        onSelect={this.onSelectedVariable}
                    />
                );
            }
            // variable in current selection so decide the rest of the cases
            let refCallback;
            if (variableSelectionInfo.selected && variableSelectionInfo.isFirstInSelection) {
                refCallback = this.props.selectedVariableRef;
            }
            return (
                <DataVariable
                    className="data-variables-list__item"
                    inputRef={refCallback}
                    key={variable.uuid}
                    variable={variable}
                    selected={variableSelectionInfo.selected}
                    isMultipleSelection={variableSelectionInfo.isMultipleSelection}
                    availableForMultipleSelection={
                        variableSelectionInfo.availableForMultipleSelection
                    }
                    onSelect={this.onSelectedVariable}
                    onAddToSelection={this.onAddVariableToSelection}
                    onRemoveFromSelection={this.onRemoveVariableFromSelection}
                />
            );
        });
    }

    render() {
        return (
            <div
                ref={this.props.inputRef}
                className={classnames(
                    'data-variables-list flex-it column stretch no-shrink',
                    this.props.className,
                )}
            >
                {this.renderVariables()}
            </div>
        );
    }

    _getVariableSelectionFromVariable(variable) {
        return new VariableSelectionItem({
            variableGuid: variable.uuid,
            tableGuid: variable.table.uuid,
            datasetAbbreviation: variable.table.dataset.abbrevation,
            surveyName: variable.table.dataset.survey.name,
        });
    }
}

DataVariablesList.propTypes = {
    className: PropTypes.string,
    mapInstanceId: PropTypes.string.isRequired,
    hasDataFilter: PropTypes.bool,
    currentMetadataSelection: PropTypes.object,
    variables: PropTypes.array.isRequired,
    selectedVariableRef: PropTypes.func,
    inputRef: PropTypes.func,
};

DataVariablesList.defaultProps = {
    className: '',
    currentMetadataSelection: { variables: [], variableSelectionInfo: [] },
    selectedVariableRef: undefined,
    inputRef: undefined,
    hasDataFilter: false,
};

export default DataVariablesList;
