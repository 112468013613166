import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

const MAX_SUGGESTIONS_WHEN_NOT_EXPANDED = 6;

/**
 * @typedef Props
 * @property {string[]} suggestions
 * @property {(suggestion: string) => void} onClick
 * @property {import('react-intl').intlShape} intl
 *
 * @typedef State
 * @property {boolean} expanded
 *
 * @extends {React.Component<Props,State>}
 */
class Suggestions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
        };
    }

    onToggle = () => {
        this.setState(state => ({
            expanded: !state.expanded,
        }));
    };

    render() {
        if (this.props.suggestions.length === 0) {
            return null;
        }

        const showExpand =
            this.props.suggestions.length > MAX_SUGGESTIONS_WHEN_NOT_EXPANDED;
        // determine how many suggestions to show
        let maxNoOfSuggestions = this.props.suggestions.length;
        if (showExpand) {
            maxNoOfSuggestions = this.state.expanded
                ? maxNoOfSuggestions
                : MAX_SUGGESTIONS_WHEN_NOT_EXPANDED;
        }

        return (
            <div className="search-suggestions">
                <div className="search-suggestions__header">
                    <i className="material-icons" aria-hidden>auto_awesome</i>
                    <div>Add related term:</div>
                </div>
                <div className="flex-it wrap center">
                    {this.props.suggestions
                        .slice(0, maxNoOfSuggestions)
                        .map(suggestion => (
                            <button
                                className="search-suggestion-button"
                                key={suggestion}
                                onClick={() => this.props.onClick(suggestion)}
                                aria-label={`${this.props.intl.formatMessage({
                                    id: 'dataBrowser.suggestion',
                                })} ${suggestion}`}
                            >
                                {suggestion}
                            </button>
                        ))}
                    {showExpand && (
                        <button
                            className="search-suggestions__expand-btn"
                            onClick={this.onToggle}
                            aria-label={this.props.intl.formatMessage({
                                id: this.state.expanded
                                    ? 'showHide.show.less'
                                    : 'showHide.show.more',
                            })}
                        >
                            <i className="material-icons">
                                {this.state.expanded
                                    ? 'expand_less'
                                    : 'expand_more'}
                            </i>
                        </button>
                    )}
                </div>
            </div>
        );
    }
}

Suggestions.propTypes = {
    suggestions: PropTypes.array.isRequired,
    onClick: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(Suggestions);
