import BaseController from './BaseController';
import InfoBubbleModeDataSource from '../dataSources/InfoBubbleModeDataSource';

class InfoBubbleModeController extends BaseController {
    static get name() {
        return 'InfoBubbleModeController';
    }

    static getInstance(options) {
        return new InfoBubbleModeController(options);
    }

    onActivate() {
        this.bindGluBusEvents({
            CURRENT_INFO_BUBBLE_MODE_REQUEST: this.onCurrentInfoBubbleModeRequest,
            INFO_BUBBLE_MODE_UPDATE_REQUEST: this.onInfoBubbleModeUpdateRequest,
            INFO_BUBBLE_MODE_RESTORE_PREVIOUS_REQUEST: this.onInfoBubbleModeRestorePreviousRequest,
        });
        this.infoBubbleModeDataSource = this.activateSource(InfoBubbleModeDataSource);
    }

    onCurrentInfoBubbleModeRequest() {
        const infoBubbleMode = this.infoBubbleModeDataSource.currentInfoBubbleMode;
        this.bus.emit('CURRENT_INFO_BUBBLE_MODE', { infoBubbleMode });
    }

    onInfoBubbleModeUpdateRequest(infoBubbleModeUpdateRequest) {
        if(infoBubbleModeUpdateRequest.storePreviousMode) {
            this.infoBubbleModeDataSource.updatePreviousInfoBubbleMode(this.infoBubbleModeDataSource.currentInfoBubbleMode)
        }

        this.infoBubbleModeDataSource.updateInfoBubbleMode(infoBubbleModeUpdateRequest.infoBubbleMode);
        const infoBubbleMode = this.infoBubbleModeDataSource.currentInfoBubbleMode;
        this.bus.emit('INFO_BUBBLE_MODE_UPDATE_SUCCESS', { infoBubbleMode });
    }

    onInfoBubbleModeRestorePreviousRequest() {
        const previousInfoBubbleMode = this.infoBubbleModeDataSource.previousInfoBubbleMode;
        if(previousInfoBubbleMode) {
            this.infoBubbleModeDataSource.updateInfoBubbleMode(previousInfoBubbleMode);
            const infoBubbleMode = this.infoBubbleModeDataSource.currentInfoBubbleMode;
            this.bus.emit('INFO_BUBBLE_MODE_UPDATE_SUCCESS', { infoBubbleMode });
        }
    }

    onDeactivate() {
        this.unbindGluBusEvents();
    }
}

export default InfoBubbleModeController;
