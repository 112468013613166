import React from 'react';
import BusComponent from '../../BusComponent';

const withHighlight = Component => class extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            highlighted: props.rule.highlighted,
        };
        this.bindGluBusEvents({
            MAP_SET_HIGHLIGHT_RULE: this.onHighlightRule,
        });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    onHighlightRule = e => {
        if (this.props.mapInstanceId === e.mapInstanceId &&
        this.props.rule.title !== e.rule.title && this.state.highlighted) {
            this.setState({
                highlighted: false,
            });
        }
    }

    onMouseClick = () => {
        if (this.props.onClick) this.props.onClick(!this.state.highlighted);
        if (this.props.isInsufficientDataRule) return;
        this.emit('MAP_CLEAR_HIGHLIGHT_RULE', {
            mapInstanceId: this.props.mapInstanceId,
            rule: this.props.rule,
        });
        if (!this.state.highlighted) {
            this.emit('MAP_SET_HIGHLIGHT_RULE', {
                mapInstanceId: this.props.mapInstanceId,
                rule: this.props.rule,
                variableIdx: this.props.variableIdx,
                ruleIdx: this.props.ruleIdx,
            });
        }
        this.setState({
            highlighted: !this.state.highlighted,
        });
        this.emit('MAP_LEGEND_RULE_HIGHLIGHTED', { source: { id: this.props.mapInstanceId } });
    };

    render() {
        return (<Component
            {...this.props}
            highlighted={this.state.highlighted}
            onClick={this.onMouseClick}
        />);
    }
    };
export default withHighlight;
