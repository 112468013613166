import React from 'react';
import PropTypes from 'prop-types';

import YearSelectionTab from './YearSelectionTab';
import YearSelectionMode from '../../../enums/YearSelectionMode';
import YearSelectionInfoPopup from './YearSelectionInfoPopup';

class YearSelectionTabs extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            infoPopupOpen: false,
        };
    }

    onTabClick = mode => {
        this.props.onChangeMode(mode);
    };

    onInfoClick = () => {
        this.setState({ infoPopupOpen: !this.state.infoPopupOpen });
    }

    onClosePopup = e => {
        if (!e.target.classList.contains('year-selection__tab-icon')) {
            this.setState({ infoPopupOpen: false });
        }
    }

    render() {
        const { currentMode } = this.props;
        const { infoPopupOpen } = this.state;

        const isSingleYearSelection = currentMode === YearSelectionMode.SINGLE;

        return (
            <div className="flex-it row margin-bottom-20" ref={element => { this.infoPopup = element; }}>
                <YearSelectionTab
                    title="Single year"
                    active={isSingleYearSelection}
                    onClick={() => this.onTabClick(YearSelectionMode.SINGLE)}
                />
                <YearSelectionTab
                    title="Change over time"
                    active={!isSingleYearSelection}
                    infoSelected={infoPopupOpen}
                    onClick={() => this.onTabClick(YearSelectionMode.RANGE)}
                    onInfoClick={this.onInfoClick}
                />
                <YearSelectionInfoPopup
                    anchorElement={this.infoPopup}
                    onClosePopup={this.onClosePopup}
                    open={infoPopupOpen}
                />
            </div>);
    }
}

YearSelectionTabs.propTypes = {
    onChangeMode: PropTypes.func.isRequired,
    currentMode: PropTypes.string.isRequired,
};

export default YearSelectionTabs;
