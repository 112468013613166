/* eslint class-methods-use-this: 0 */

import Curve from './Curve';

class FlowArrow {
    constructor(values) {
        if (values) {
            Object.keys(values).forEach(k => {
                if (this.constructor.prototype.hasOwnProperty(k)) {
                    this[k] = values[k];
                }
            });
        }
    }

    get type() {
        return 'FlowArrow';
    }

    set id(id) {
        this._id = id;
    }

    get id() {
        return this._id;
    }

    set strokeWeight(strokeWeight) {
        this._strokeWeight = strokeWeight;
    }

    get strokeWeight() {
        return this._strokeWeight;
    }

    set strokeColor(strokeColor) {
        this._strokeColor = strokeColor;
    }

    get strokeColor() {
        return this._strokeColor;
    }

    set fromWidth(fromWidth) {
        this._fromWidth = fromWidth;
    }

    get fromWidth() {
        return this._fromWidth;
    }

    set curves(curves) {
        this._curves = curves.map(c => {
            if (c instanceof Curve) return c;
            return new Curve(c);
        });
    }

    get curves() {
        return this._curves;
    }

    set toWidth(toWidth) {
        this._toWidth = toWidth;
    }

    get toWidth() {
        return this._toWidth;
    }

    set tipWidth(tipWidth) {
        this._tipWidth = tipWidth;
    }

    get tipWidth() {
        return this._tipWidth;
    }

    set fillColor(fillColor) {
        this._fillColor = fillColor;
    }

    get fillColor() {
        return this._fillColor;
    }

    get useFill() {
        return this._useFill;
    }

    set useFill(useFill) {
        this._useFill = useFill;
    }

    get opacity() {
        return this._opacity;
    }

    set opacity(opacity) {
        this._opacity = opacity;
    }

    get title() {
        return this._title;
    }

    set title(title) {
        this._title = title;
    }

    get createdAtZoomLevel() {
        return this._createdAtZoomLevel;
    }

    set createdAtZoomLevel(createdAtZoomLevel) {
        this._createdAtZoomLevel = createdAtZoomLevel;
    }

    get minZoom() {
        return this._minZoom;
    }

    set minZoom(minZoom) {
        this._minZoom = minZoom;
    }

    get maxZoom() {
        return this._maxZoom;
    }

    set maxZoom(maxZoom) {
        this._maxZoom = maxZoom;
    }

    get description() {
        return this._description;
    }

    set description(description) {
        this._description = description;
    }

    get legendOrder() {
        return this._legendOrder;
    }

    set legendOrder(legendOrder) {
        this._legendOrder = legendOrder;
    }

    get includedInLegend() {
        return this._includedInLegend;
    }

    set includedInLegend(includedInLegend) {
        this._includedInLegend = includedInLegend;
    }

    equals(that) {
        const curvesEqual = this.curves.length === that.curves.length && !this.curves.find(c1 => !that.curves.find(c2 => c1.equals(c2)));
        return (this.id === that.id &&
        this.strokeWeight === that.strokeWeight &&
        this.strokeColor === that.strokeColor &&
        this.fromWidth === that.fromWidth &&
        curvesEqual &&
        this.toWidth === that.toWidth &&
        this.tipWidth === that.tipWidth &&
        this.fillColor === that.fillColor &&
        this.useFill === that.useFill &&
        this.opacity === that.opacity &&
        this.title === that.title &&
        this.createdAtZoomLevel === that.createdAtZoomLevel &&
        this.minZoom === that.minZoom &&
        this.maxZoom === that.maxZoom &&
        this.includedInLegend === that.includedInLegend &&
        this.legendOrder === that.legendOrder &&
        this.description === that.description);
    }

    clone() {
        return new FlowArrow({
            id: `${this.id}`,
            strokeWeight: this.strokeWeight,
            strokeColor: this.strokeColor,
            fromWidth: this.fromWidth,
            curves: this.curves.map(c => c.clone()),
            toWidth: this.toWidth,
            tipWidth: this.tipWidth,
            fillColor: this.fillColor,
            useFill: this.useFill,
            opacity: this.opacity,
            title: this.title,
            createdAtZoomLevel: this.createdAtZoomLevel,
            minZoom: this.minZoom,
            maxZoom: this.maxZoom,
            includedInLegend: this.includedInLegend,
            description: this.description,
            legendOrder: this.legendOrder,
        });
    }

    toJSON() {
        return ({
            id: `${this.id}`,
            type: this.type,
            strokeWeight: this.strokeWeight,
            strokeColor: this.strokeColor,
            fromWidth: this.fromWidth,
            curves: this.curves.map(c => c.toJSON()),
            toWidth: this.toWidth,
            tipWidth: this.tipWidth,
            fillColor: this.fillColor,
            useFill: this.useFill,
            opacity: this.opacity,
            title: this.title,
            createdAtZoomLevel: this.createdAtZoomLevel,
            minZoom: this.minZoom,
            maxZoom: this.maxZoom,
            includedInLegend: this.includedInLegend,
            description: this.description,
            legendOrder: this.legendOrder,
        });
    }
}

export default FlowArrow;
