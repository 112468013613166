/* eslint class-methods-use-this: 0 */
import BaseController from './BaseController';
import UploadDataSource from '../dataSources/UploadDataSource';
import Errors from '../enums/Error';

import uuid from 'node-uuid';

class UploadController extends BaseController {
    static get name() {
        return 'UploadController';
    }

    static getInstance(options) {
        return new UploadController(options);
    }

    onActivate() {
        this.bindGluBusEvents({
            UPLOAD_FILE_LOAD_REQUEST: this.onUploadFileLoadRequest,
        });
        this.uploadDataSource = this.activateSource(UploadDataSource);
    }

    onUploadFileLoadRequest(e) {
        const fileName = uuid.v4();
        const fileType = e.fileType.split('/')[1];
        const key = `projects/${e.assetsGuid}/${fileName}.${fileType}`;
        const params = {
            key,
        };
        const onError = error => {
            this.bus.emit('S3_SIGN_ERROR', {
                level: Errors.Error,
                originalError: error,
                additionalInfo: 'Failed while trying to sign s3 request',
            });
        };
        this.uploadDataSource.signS3(params).then(r1 => {
            const uploadError = error => {
                this.bus.emit('S3_UPLOAD_ERROR', {
                    level: Errors.Error,
                    originalError: error,
                    additionalInfo: 'An error occoured while uploading file',
                });
            };
            const uploadParams = {
                key,
                signedUrl: r1.signedUrl,
                fileType: e.fileType,
                data: e.data,
            };
            this.uploadDataSource.upload(uploadParams).then(() => {
                this.bus.emit('UPLOAD_FILE_LOAD_SUCCESS', { fileName, fileType });
            }).catch(uploadError);
        }).catch(onError);
    }

    onDeactivate() {
        this.unbindGluBusEvents();
    }
}

export default UploadController;
