import NumberFormats from '../enums/NumberFormat';


function formatNumber(num, numberFormat) {
    return numeral(num).format(numberFormat);
}

export default function format(params) {
    const num = params.number;

    // check if value is finite number
    if (isNaN(parseFloat(num)) || !isFinite(parseFloat(num))) {
        return num.toString();
    }

    const numberFormat = params.numberFormat;
    const currencySymbol = params.currencySymbol ? params.currencySymbol : '$';
    const placeCurrencySymbolBeforeValue = params.placeCurrencySymbolBeforeValue ? params.placeCurrencySymbolBeforeValue : true;

    // eslint-disable-next-line dot-notation
    if (numeral.locales['socex'] === undefined) {
        numeral.register('locale', 'socex', {
            delimiters: {
                thousands: ',',
                decimal: '.',
            },
            abbreviations: {
                thousand: 'k',
                million: 'm',
                billion: 'b',
                trillion: 't',
            },
            ordinal: number => {
                const b = number % 10;
                // eslint-disable-next-line
                if (~~(number % 100 / 10) === 1) {
                    return 'th';
                }
                switch (b) {
                case 1:
                    return 'st';
                case 2:
                    return 'nd';
                case 3:
                    return 'rd';
                default:
                    return 'th';
                }
            },
            currency: {
                symbol: currencySymbol,
            },
        });
    }

// switch between languages
    numeral.locale('socex');
    switch (numberFormat) {
    case NumberFormats.FORMAT_PERCENT:
        return `${formatNumber(num, '0.[00]')}%`;
    case NumberFormats.FORMAT_PERCENT_NO_DECIMAL:
        return `${formatNumber(num, '0')}%`;
    case NumberFormats.FORMAT_PERCENT_1_DECIMAL:
        return `${formatNumber(num, '0.0')}%`;
    case NumberFormats.FORMAT_PERCENT_2_DECIMAL:
        return `${formatNumber(num, '0.00')}%`;
    case NumberFormats.FORMAT_PERCENT_3_DECIMAL:
        return `${formatNumber(num, '0.000')}%`;
    case NumberFormats.FORMAT_PERCENT_4_DECIMAL:
        return `${formatNumber(num, '0.0000')}%`;

    case NumberFormats.FORMAT_REAL_PERCENT:
        return `${num * 100}%`;
    case NumberFormats.FORMAT_REAL_PERCENT_NO_DECIMAL:
        return formatNumber(num, '0%');
    case NumberFormats.FORMAT_REAL_PERCENT_1_DECIMAL:
        return formatNumber(num, '0.0%');
    case NumberFormats.FORMAT_REAL_PERCENT_2_DECIMAL:
        return formatNumber(num, '0.00%');
    case NumberFormats.FORMAT_REAL_PERCENT_3_DECIMAL:
        return formatNumber(num, '0.000%');
    case NumberFormats.FORMAT_REAL_PERCENT_4_DECIMAL:
        return formatNumber(num, '0.0000%');


    case NumberFormats.FORMAT_CURRENCY:
        return formatNumber(num, `${placeCurrencySymbolBeforeValue ? '$' : ''}0,0.[00000000]${!placeCurrencySymbolBeforeValue ? '$' : ''}`);
    case NumberFormats.FORMAT_CURRENCY0_DECIMAL:
        return formatNumber(num, `${placeCurrencySymbolBeforeValue ? '$' : ''}0,0${!placeCurrencySymbolBeforeValue ? '$' : ''}`);
    case NumberFormats.FORMAT_CURRENCY1_DECIMAL:
        return formatNumber(num, `${placeCurrencySymbolBeforeValue ? '$' : ''}0,0.0${!placeCurrencySymbolBeforeValue ? '$' : ''}`);
    case NumberFormats.FORMAT_CURRENCY2_DECIMAL:
        return formatNumber(num, `${placeCurrencySymbolBeforeValue ? '$' : ''}0,0.00${!placeCurrencySymbolBeforeValue ? '$' : ''}`);

    case NumberFormats.FORMAT_NUMBER:
        return formatNumber(num, '0,0.[00000000]');
    case NumberFormats.FORMAT_NUMBER_NO_DECIMAL:
        return formatNumber(num, '0,0');
    case NumberFormats.FORMAT_NUMBER_1_DECIMAL:
        return formatNumber(num, '0,0.0');
    case NumberFormats.FORMAT_NUMBER_2_DECIMAL:
        return formatNumber(num, '0,0.00');
    case NumberFormats.FORMAT_NUMBER_3_DECIMAL:
        return formatNumber(num, '0,0.000');
    case NumberFormats.FORMAT_NUMBER_4_DECIMAL:
        return formatNumber(num, '0,0.0000');
    case NumberFormats.FORMAT_NUMBER_5_DECIMAL:
        return formatNumber(num, '0,0.00000');

    case NumberFormats.FORMAT_NUMBER_NO_FORMAT:
        return num.toString();
    case NumberFormats.FORMAT_NUMBER_NO_FORMAT_NO_DECIMAL:
        return formatNumber(num, '0');
    case NumberFormats.FORMAT_NUMBER_NO_FORMAT_1_DECIMAL:
        return formatNumber(num, '0.0');
    case NumberFormats.FORMAT_NUMBER_NO_FORMAT_2_DECIMAL:
        return formatNumber(num, '0.00');
    case NumberFormats.FORMAT_NUMBER_NO_FORMAT_3_DECIMAL:
        return formatNumber(num, '0.000');
    case NumberFormats.FORMAT_NUMBER_NO_FORMAT_4_DECIMAL:
        return formatNumber(num, '0.0000');
    case NumberFormats.FORMAT_NUMBER_NO_FORMAT_5_DECIMAL:
        return formatNumber(num, '0.00000');

    default:
        return num.toString();

    }
}
