import React from 'react';
import { injectIntl } from 'react-intl';

import BusComponent from '../BusComponent';
import ResetCustomValuesInfoPopover from './ResetCustomValuesInfoPopover';

/**
 *
 * @typedef Props
 * @property {import('react-intl').intlShape} intl
 *
 * @extends {BusComponent<Props>}
 */
class ResetCustomValuesButton extends BusComponent {
    onClick = () => {
        this.emit('RESET_CUSTOM_LOCATION_ANALYSIS_VALUES_REQUEST');
        // Once custom values are emptied from the store, show the changes in the view
        this.emit('CUSTOM_LOCATION_ANALYSIS_VALUES_REQUEST');
    };

    render() {
        return (
            <div className="location-analysis__reset-values-btn-group">
                <button
                    className="btn-link location-analysis__reset-values-btn"
                    onClick={this.onClick}
                >
                    {this.props.intl.formatMessage({
                        id: 'reset',
                    })}
                </button>
                <ResetCustomValuesInfoPopover />
            </div>
        );
    }
}

export default injectIntl(ResetCustomValuesButton);
