import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import BusComponent from '../BusComponent';
import FrameType from '../../enums/FrameType';
import { POI_TYPES } from '../../enums/PoiTypes';
import Mixpanel, { MIXPANEL_EVENTS } from '../../helpers/Mixpanel';

/**
 * @typedef Props
 * @property {import('react-intl').intlShape} intl
 * @property {import('../../objects/LocationAnalysisItem').default} locationAnalysisItem
 * @property {string} mapInstanceId
 *
 * @typedef State
 * @property {string | null} frameType
 *
 * @extends {BusComponent<Props, State>}
 */
class AnalyzeButton extends BusComponent {
    constructor(props) {
        super(props);
        /** @type {State} */
        this.state = {
            frameType: null,
        };
    }

    componentDidMount() {
        this.bindGluBusEvents({
            CURRENT_FRAME: this.onCurrentFrame,
            FRAME_UPDATED: this.onFrameUpdated,
        });
        this.bus.emit('CURRENT_FRAME_REQUEST');
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    /**
     * @param {import('../../objects/Frame').default} currentFrame
     */
    onCurrentFrame = currentFrame => {
        this.setState({ frameType: currentFrame.type });
    };

    /**
     * @param {object} param0
     * @param {import('../../objects/Frame').default} param0.frame
     */
    onFrameUpdated = ({ frame }) => {
        this.setState({ frameType: frame.type });
    };

    onClick = () => {
        const { point, selection, analysisType, value, type, id } = this.props.locationAnalysisItem;
        const additionalParams = POI_TYPES.some(poiType => poiType === type)
            ? {
                  'point-type': type,
                  'facility-id': id,
              }
            : {
                  'point-type': type,
              };
        /** @type {import('../../../../types').VisualReport} */
        const visualReport = {
            title: value,
            point,
            selection: Array.from(selection),
            profile: analysisType.VISUAL_REPORT_PROFILE,
            additionalParams,
            pointsOfInterest: [id],
        };

        // TODO: this emit events listened only by components. We should try
        // hard not to use this pattern. Communication should be done using
        // data store
        this.emit('SHOW_INSIGHTS', visualReport);
        this.emit('MINIMIZE_LOCATION_PANEL', {
            mapInstanceId: this.props.mapInstanceId,
        });
        Mixpanel.emitUserEvent(MIXPANEL_EVENTS.GET_INSIGHTS, {
            'Location Name': value,
            'Location ID': id,
        });
    };

    render() {
        const { frameType } = this.state;
        // Analysis should be disabled for any frame except single map frame (for now)
        if (frameType !== FrameType.SINGLE_MAP) {
            return null;
        }
        return (
            <button className="btn-raised" onClick={this.onClick}>
                {this.props.intl.formatMessage({ id: 'getInsights' })}
            </button>
        );
    }
}

AnalyzeButton.propTypes = {
    intl: PropTypes.object.isRequired,
    locationAnalysisItem: PropTypes.object.isRequired,
    mapInstanceId: PropTypes.string.isRequired,
};

export default injectIntl(AnalyzeButton);
