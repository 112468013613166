//!
const vtpb = require('vt-pbf');
const Vt = require('@mapbox/vector-tile').VectorTile;
const Vtl = require('@mapbox/vector-tile').VectorTileLayer;
const Pbf = require('pbf');
const evaluateExpression = require('../util/dragonfly_util').evaluateExpression;

exports.url = function(layers, params) {
    // Not in dragonfly sources style, thus return the default mapbox vector tile source
    if (!params.request || !params.request.url || params.request.url.indexOf('{layers}') === -1) return params.request.url;

    // Evaluate which layers are visible
    const visibleLayerIds = Object.keys(layers).filter((layerId) => {
        const layer = layers[layerId];
        const sameSource = layer.source === params.source;
        const withinMinZoom = layer.minzoom === undefined || layer.minzoom <= params.zoom;
        const withinMaxZoom = layer.maxzoom === undefined || layer.maxzoom >= params.zoom;
        const visible = layer.layout === undefined || layer.layout.visibility !== 'none';

        return sameSource && withinMinZoom && withinMaxZoom && visible;
    });

    const visibleLayers = [];
    visibleLayerIds.forEach(vId => {
        visibleLayers.push(layers[vId]);
    });

    const visibleLayersIds = []; // [layerId,...]
    const visibleColumns = []; // [layerId.datasetId.column,...]

    const layersDatasets = {};
    for (let l = 0; l < Object.keys(visibleLayers).length; l++) {

        const visibleLayer = visibleLayers[l];
        let visibleLayerId = visibleLayer.sourceLayer;
        if (visibleLayer.autoSource !== undefined) {
            for (let al = 0; al < visibleLayer.autoSource.length; al++) {
                const autoLayer = visibleLayer.autoSource[al];
                if (autoLayer.minzoom <= params.zoom && autoLayer.maxzoom > params.zoom) {
                    visibleLayer.sourceLayer = visibleLayerId = autoLayer['source-layer'];
                    break;
                }
            }
        }
        // Filter out layers not within this source
        if (visibleLayer.source !== params.source) continue;
        // No duplicates
        if (visibleLayersIds.indexOf(visibleLayerId) === -1)
            visibleLayersIds.push(visibleLayerId);

        // Evaluate columns if layer contains datasets
        if (params.sources[params.source] && params.sources[params.source].layers) {
            const sourcesLayers = params.sources[params.source].layers;
            for (let sl = 0; sl < sourcesLayers.length; sl++) {
                const sourceLayer = sourcesLayers[sl];
                if (sourceLayer) {
                    if (visibleLayerId !== sourceLayer.layerId) continue;

                    if (sourceLayer.datasets) {
                        layersDatasets[visibleLayerId] = layersDatasets[visibleLayerId] || [];
                        for (let sld = 0; sld < sourceLayer.datasets.length; sld++)
                            layersDatasets[visibleLayerId].push(sourceLayer.datasets[sld]);
                    }
                }
            }
        }
    }

    for (const layerId in layersDatasets)
        if (layersDatasets.hasOwnProperty(layerId)) {

            const datasets = layersDatasets[layerId];

            if (datasets && datasets.length > 0) {
                for (let d = 0; d < datasets.length; d++) {

                    const dataset = datasets[d];

                    if (dataset.columns)
                        for (let c = 0; c < dataset.columns.length; c++) {

                            const column = dataset.columns[c];

                            if (column.indexOf('->') !== -1) continue;

                            const visibleColumn = `${layerId}.${dataset.datasetId}.${column}`;

                            // No duplicates
                            if (visibleColumns.indexOf(visibleColumn) === -1)
                                visibleColumns.push(visibleColumn);
                        }
                }
            }
        }

    // Inject area into columns to fetch when layer is of type dotdensity
    for (let l = 0; l < visibleLayers.length; l++) {

        const visibleLayer = visibleLayers[l];

        if (visibleLayer.type === "dotdensity") {
            const areaColumn = `${visibleLayer.sourceLayer}.-1.__area__`;
            if (visibleColumns.indexOf(areaColumn) === -1)
                visibleColumns.push(areaColumn);
        }
        if (params.selectionMode && visibleLayer.sourceLayer.indexOf('p') === -1 && visibleLayer.type === 'fill') {
            const areaColumn = `${visibleLayer.sourceLayer}.-1.__area__`;
            if (visibleColumns.indexOf(areaColumn) === -1)
                visibleColumns.push(areaColumn);
            const boundingBoxXmax = `${visibleLayer.sourceLayer}.-1.__xmax__`;
            if (visibleColumns.indexOf(boundingBoxXmax) === -1)
                visibleColumns.push(boundingBoxXmax);
            const boundingBoxXmin = `${visibleLayer.sourceLayer}.-1.__xmin__`;
            if (visibleColumns.indexOf(boundingBoxXmin) === -1)
                visibleColumns.push(boundingBoxXmin);
            const boundingBoxYmax = `${visibleLayer.sourceLayer}.-1.__ymax__`;
            if (visibleColumns.indexOf(boundingBoxYmax) === -1)
                visibleColumns.push(boundingBoxYmax);
            const boundingBoxYmin = `${visibleLayer.sourceLayer}.-1.__ymin__`;
            if (visibleColumns.indexOf(boundingBoxYmin) === -1)
                visibleColumns.push(boundingBoxYmin);
        }
    }

    if (visibleLayersIds.length === 0) return undefined;

    return params.request.url
        .replace('{layers}', visibleLayersIds.join('.'))
        .replace('{columns}', encodeURIComponent(visibleColumns.join(',')))
        .concat("&v=2"); // version 2.0

};

exports.serialize = function(sources) {
    const sourcesSerialized = {};
    for (const source in sources)
        if (sources.hasOwnProperty(source) && sources[source]._source._options)
            sourcesSerialized[source] = { 'layers': sources[source]._source._options.layers };
    return sourcesSerialized;
};

// Returns the modified vectorTile and pbf.
// Returns undefined if no computation took place, i.e. nothing changed.
exports.calculateComputedColumns = function(sources, vectorTile) {
    if (!sources) return;

    if (!vectorTile || !vectorTile.layers || Object.keys(vectorTile.layers).length === 0) return;

    // Evaluate computed columns from sources
    const computedColumns = [];
    for (const sourceId in sources) {
        if (!sources.hasOwnProperty(sourceId)) continue;
        const source = sources[sourceId];
        const layers = source.layers;
        if (!layers) continue;
        for (let l = 0; l < layers.length; l++) {
            const layer = layers[l];
            if (!layer) continue;
            const datasets = layer.datasets;
            if (!datasets) continue;
            for (let d = 0; d < datasets.length; d++) {
                const dataset = datasets[d];
                const columns = dataset.columns;
                if (!columns) continue;
                for (let c = 0; c < columns.length; c++) {
                    const column = columns[c];
                    if (column.indexOf('->') !== -1) {
                        const name = column.split('->')[0];
                        const formula = column.split('->')[1];
                        computedColumns.push({
                            name: name,
                            formula: formula,
                            layerId: layer.layerId
                        });
                    }
                }
            }
        }
    }

    if (computedColumns.length === 0) return;

    // Serialize updated vector tile into pbf
    const vectorTilePbf = fromVectorTile(vectorTile, computedColumns);
    return {
        pbf: vectorTilePbf,
        // Deserialize again to update the pbf across layers, because because
        // sortLayerIntoBuckets(data, bucketsById) function in worker_tile.js
        // will call layer.feature(i) which would extract the values from pbf.
        vectorTile: new Vt(new Pbf(vectorTilePbf))
    };
};

exports.fromVectorTile = function(vectorTile) {
    const layers = [];

    for (const l in vectorTile.layers) {
        layers.push(prepareLayer(vectorTile.layers[l], []));
    }

    return vtpb({ layers: layers });
};

/** PBF writer START **/
/** From: /dragonfly/node_modules/vt-pbf/index.js **/

function fromVectorTile(vectorTile, computedColumns) {
    const layers = [];
    Object.keys(vectorTile.layers).forEach(l => {
        const computedColumnsForLayer = computedColumns.filter((computedColumn) => {
            const vectorTileLayerId = l.indexOf('_c') > -1 ? l.substr(0, l.indexOf('_c')) : l;
            return computedColumn.layerId === vectorTileLayerId;
        });

        layers.push(prepareLayer(vectorTile.layers[l], computedColumnsForLayer));
    });
    return vtpb({ layers: layers });
}

function featureFunction(i) {
    return this._features[i];
}

function prepareLayer(layer, computedColumnsForLayer) {
    const preparedLayer = new Vtl(layer._pbf);
    preparedLayer.feature = featureFunction;
    preparedLayer.version = layer.version || 1;
    preparedLayer.name = layer.name || '';
    preparedLayer.extent = layer.extent || 4096;
    preparedLayer.length = 0;
    preparedLayer._keys = [];
    preparedLayer._values = [];
    preparedLayer._features = [];

    const keyCache = {};
    const valueCache = {};

    for (let i = 0; i < layer.length; i++) {
        const feature = layer.feature(i);
        feature.geometry = encodeGeometry(feature.loadGeometry());
        feature.id = feature.id || feature._id;

        // Inject computed columns values into data
        for (let cc = 0; cc < computedColumnsForLayer.length; cc++) {
            const computedColumn = computedColumnsForLayer[cc];
            const evaluatedValue = evaluateExpression(computedColumn.formula, feature.properties);
            if (evaluatedValue !== undefined)
                feature.properties[computedColumn.name] = evaluatedValue;
        }

        const tags = [];
        for (const key in feature.properties) {
            if (!feature.properties.hasOwnProperty(key)) continue;
            let keyIndex = keyCache[key];
            if (typeof keyIndex === 'undefined') {
                preparedLayer._keys.push(key);
                keyIndex = preparedLayer._keys.length - 1;
                keyCache[key] = keyIndex;
            }
            const value = wrapValue(feature.properties[key]);
            let valueIndex = valueCache[value.key];
            if (typeof valueIndex === 'undefined') {
                preparedLayer._values.push(value);
                valueIndex = preparedLayer._values.length - 1;
                valueCache[value.key] = valueIndex;
            }
            tags.push(keyIndex);
            tags.push(valueIndex);
        }

        feature.tags = tags;
        preparedLayer._features.push(feature);
    }
    preparedLayer.length = preparedLayer._features.length;
    return preparedLayer;
}

function command(cmd, length) {
    return (length << 3) + (cmd & 0x7);
}

function zigzag(num) {
    return (num << 1) ^ (num >> 31);
}

function encodeGeometry(geometry) {
    const encoded = [];
    let x = 0;
    let y = 0;
    const rings = geometry.length;
    for (let r = 0; r < rings; r++) {
        const ring = geometry[r];
        encoded.push(command(1, 1)); // moveto
        for (let i = 0; i < ring.length; i++) {
            if (i === 1) {
                encoded.push(command(2, ring.length - 1)); // lineto
            }
            const dx = ring[i].x - x;
            const dy = ring[i].y - y;
            encoded.push(zigzag(dx), zigzag(dy));
            x += dx;
            y += dy;
        }
    }

    return encoded;
}

function wrapValue(value) {
    let result;
    const type = typeof value;
    if (type === 'string') {
        result = { stringValue: value };
    } else if (type === 'boolean') {
        result = { boolValue: value };
    } else if (type === 'number') {
        if (value !== (value | 0)) {
            result = { floatValue: value };
        } else if (value < 0) {
            result = { sintValue: value };
        } else {
            result = { uintValue: value };
        }
    } else {
        result = { stringValue: `${value}` };
    }

    result.key = `${type}:${value}`;
    return result;
}

/** PBF writer END **/
