// @ts-check
import React from 'react';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';

import BusComponent from '../BusComponent';

/**
 * @typedef Props
 * @property {import('../../types').VisualReport} visualReport
 * @property {import('react-intl').intlShape} intl
 *
 * @typedef State
 * @property {boolean} saved
 * @property {import('../../types').VisualReport} visualReport
 *
 * @extends {BusComponent<Props, State>}
 */
class VisualReportSave extends BusComponent {
    constructor(props, context) {
        super(props, context);
        /** @type {State} */
        this.state = {
            saved: false,
            visualReport: props.visualReport,
        };
    }

    componentDidMount() {
        this.bindGluBusEvents({
            VISUAL_REPORT_SAVE_SUCCESS: this.onSaveSuccess,
            SHOW_INSIGHTS: this.onReset,
        });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    onSaveSuccess = () => {
        this.setState({ saved: true });
    };

    onSave = () => {
        this.emit('VISUAL_REPORT_SAVE_POPUP_REQUEST', {
            visualReport: this.props.visualReport,
        });
    };

    onReset = visualReport => {
        // TODO: a bit unstable check, since JSON.stringify will return 2 different
        // strings for following objects: {a:1,b:2} and {b:2,a:1}
        if (
            JSON.stringify(this.state.visualReport) !==
            JSON.stringify(visualReport)
        ) {
            this.setState({ saved: false, visualReport });
        }
    };

    render() {
        const disabled = this.state.saved;
        const classes = classNames('btn-raised', {
            disabled,
        });

        return (
            <button
                className={classes}
                disabled={disabled}
                onClick={this.onSave}
            >
                {this.props.intl.formatMessage({
                    id: this.state.saved ? 'saved' : 'save',
                })}
            </button>
        );
    }
}

export default injectIntl(VisualReportSave);
