import React from 'react';
import PropTypes from 'prop-types';

const UserMapViewItem = props => {
    const { item, onEdit, onRemove, onSelect } = props;

    return (
        <div className="user-map-views__item">
            <button
                className="user-map-views__item__title"
                onClick={() => onSelect(item)}
                title={item.title}
            >
                {item.title}
            </button>
            <div className="user-map-views__item__controls">
                <button
                    className="material-icons btn-icon font-22"
                    onClick={() => onEdit(item)}
                >
                        edit
                </button>
                <button
                    className="material-icons btn-icon font-22"
                    onClick={() => onRemove(item)}
                >
                    delete
                </button>
            </div>
        </div>
    );
};

UserMapViewItem.propTypes = {
    item: PropTypes.object.isRequired,
    onSelect: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
};

export default UserMapViewItem;
