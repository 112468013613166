/* eslint react/no-array-index-key: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const GradientColorPalettePreview = props => {
    const colors = props.colorPalette.interpolateColors(props.numberOfSamples).map((c, i) => (<div
        key={i} className="grow"
        style={{ backgroundColor: `${c}` }}
    />));
    const colorPaletteClasses = classNames('gradient-color-palette-preview flex-it');
    return (<div className={colorPaletteClasses}>{colors}</div>);
};


GradientColorPalettePreview.propTypes = {
    numberOfSamples: PropTypes.number,
    colorPalette: PropTypes.object.isRequired,
};

GradientColorPalettePreview.defaultProps = {
    numberOfSamples: 10,
};

export default GradientColorPalettePreview;
