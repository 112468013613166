import DragonflySource from '../../objects/DragonflySource';
import AppConfig from '../../appConfig';
import MapSelectionControl from '../control/MapSelectionControl';
import uuid from 'node-uuid';

class SelectionData {
    constructor(mapInstance, mapConfig, layers, sources) {
        this._mapConfig = mapConfig;
        this._mapInstance = mapInstance;
        this._dragonflyLayers = layers;
        this._dragonflySources = sources;
        this._renderer = this._mapInstance.dataTheme.rendering[0];
        this._dataHighlightLayer = layers.find(dl => dl.metadata.isHighlightLayer);

        this._selectionDragonflyLayers = [];
        this._selectionDragonflySourceLayerId = `${this._dataHighlightLayer['auto-source'][0]['source-layer']}p`;
        this._selectionDragonflySource = undefined;
        this._selectionDragonflyPointLayer = undefined;
        this._selectionDragonflyFillLayer = undefined;

        let fields, filter;
        // if filter is present we need to add the filter to the selection helper layer
        // and the filter fields to the selection source layer
        if (this._mapInstance.hasDataFilter) {
            const dataFilter = this._mapInstance.dataFilter;
            ({ filter, fields } = dataFilter.getFormattedFilter());
        }

        this._createSelectionDragonflySource(fields);
        this._createSelectionDragonflyLayers(filter);

        this._dragonflySources[this._selectionDragonflySource.id] = this._selectionDragonflySource.toJSON();
        this._selectionDragonflyLayers.forEach(sl => this._dragonflyLayers.push(sl));

        this._buildSelectionControl();
    }

    get layers() {
        return this._selectionDragonflyLayers;
    }

    get source() {
        return this._selectionDragonflySource;
    }

    get selectionControl() {
        return this._selectionControl;
    }

    filterAvailableFeatures(availablePrimaryKeyFields) {
        const geoNameField = this._mapInstance.dataTheme.rendering[0].fieldList.fields.find(f => f.isGeoNameField);
        const dataSource = this._mapConfig.getDataSourceForSummaryLevelId(this._dataHighlightLayer['auto-source'][0].id);
        const dataset = dataSource.findDataset(geoNameField.surveyName, geoNameField.datasetAbbreviation);
        const dragonflyFilter = ['starts-with', dataset.primaryKeyField];
        availablePrimaryKeyFields.forEach(value => {
            dragonflyFilter.push(value);
        });
        this.layers.forEach(dl => {
            dl.filter = dragonflyFilter;
        });
    }

    clearAvailableFeaturesFilter() {
        this.layers.forEach(dl => {
            delete dl.filter;
        });
    }

    applySummaryLevelUpdate() {
        this._selectionDragonflySourceLayerId = `${this._dataHighlightLayer['auto-source'][0]['source-layer']}p`;
        this._selectionDragonflyLayers.forEach(dl => {
            dl['source-layer'] = this._selectionDragonflySourceLayerId;
            dl.minzoom = this._dataHighlightLayer.minzoom;
            dl.maxzoom = this._dataHighlightLayer.maxzoom;
            dl.metadata.defaultMinZoom = dl.minzoom;
            dl.metadata.defaultMaxZoom = dl.maxzoom;
        });
    }

    _buildSelectionControl() {
        this._selectionControl = new MapSelectionControl({
            ignoreEmptySelection: true,
        });
    }

    _applyPreferredSummaryLevel() {
        const summaryLevel = this._mapConfig.getSummaryLevelForDataSourceId(this._mapInstance.preferredDataLayerId);
        if (!summaryLevel) return;
        let preferredAutoSourceLayer = this.autoSource.find(aSLayer => aSLayer.id === summaryLevel.id);
        preferredAutoSourceLayer = Object.assign({}, preferredAutoSourceLayer);
        if (summaryLevel.visible) {
            preferredAutoSourceLayer.minzoom = summaryLevel['absolute-min-zoom'] !== undefined ? summaryLevel['absolute-min-zoom'] : this._mapConfig.minZoom;
            preferredAutoSourceLayer.maxzoom = this._mapConfig.maxZoom + 1;
        } else {
            preferredAutoSourceLayer.minzoom = this._mapConfig.maxZoom + 1;
            preferredAutoSourceLayer.maxzoom = this._mapConfig.maxZoom + 1;
        }
        this.layers.forEach(dl => {
            dl['auto-source'] = [preferredAutoSourceLayer];
            dl.minzoom = preferredAutoSourceLayer.minzoom;
            dl.maxzoom = preferredAutoSourceLayer.maxzoom;
        });
    }

    _createSelectionDragonflySource(filterFields) {
        this._selectionDragonflySource = new DragonflySource([AppConfig.constants.backends.tiles]);
        this._selectionDragonflySource.id = `socialexplorerpointdata-${uuid.v4()}`;
        Object.values(this._mapConfig.dataSources).forEach(dataSource => {
            this._selectionDragonflySource.createLayer(`${dataSource.layerId}p`);
            this._renderer.fieldList.fields.forEach(field => {
                if (field.isComputed || field.fieldName.indexOf('__') !== -1) return;
                this._selectionDragonflySource.getLayer(`${dataSource.layerId}p`)
                    .getDataset(dataSource.findDataset(field.surveyName, field.datasetAbbreviation).id)
                    .push(field.fieldName);
            });

            if (filterFields) {
                filterFields.forEach(field => {
                    this._selectionDragonflySource.getLayer(`${dataSource.layerId}p`)
                        .getDataset(dataSource.findDataset(field.surveyName, field.datasetAbbreviation).id)
                        .push(field.fieldName)
                        .push(field.baseFieldName);
                });
            }

            const dragonflySourceLayer = this._selectionDragonflySource.layers[`${dataSource.layerId}p`];
            Object.keys(dragonflySourceLayer.datasets).map(id => dragonflySourceLayer.datasets[id]).forEach(ds => {
                if (ds.id > 0) {
                    ds.push(dataSource.datasets[ds.id].primaryKeyField);
                }
            });
        });
    }

    _createSelectionDragonflyLayers(filter) {
        this._selectionDragonflyFillLayer = {
            id: 'helper-fill-layer',
            metadata: {
                isHelperPointLayer: true,
                defaultMinZoom: this._dataHighlightLayer.minzoom,
                defaultMaxZoom: this._dataHighlightLayer.maxzoom,
                socialexplorer: {
                    'layer-group-id': this._mapConfig.dataPlaceholder.metadata.socialexplorer['layer-group-id'],
                },
            },
            type: 'fill',
            paint: {
                'fill-opacity': 0,
            },
            minzoom: this._dataHighlightLayer.minzoom,
            maxzoom: this._dataHighlightLayer.maxzoom,
            source: this._selectionDragonflySource.id,
            'source-layer': this._selectionDragonflySourceLayerId,
        };
        this._selectionDragonflyPointLayer = {
            id: 'helper-circle-layer',
            metadata: {
                defaultMinZoom: this._dataHighlightLayer.minzoom,
                defaultMaxZoom: this._dataHighlightLayer.maxzoom,
                isHelperPointLayer: true,
                socialexplorer: {
                    'layer-group-id': this._mapConfig.dataPlaceholder.metadata.socialexplorer['layer-group-id'],
                },
            },
            type: 'circle',
            paint: {
                'circle-opacity': 0,
                'circle-radius': 2,
            },
            minzoom: this._dataHighlightLayer.minzoom,
            maxzoom: this._dataHighlightLayer.maxzoom,
            source: this._selectionDragonflySource.id,
            'source-layer': this._selectionDragonflySourceLayerId,
        };

        // we need to add filter (if exists) to the selection layer as well otherwise the user will be able
        // to select the geographies that are filtered out
        if (filter) {
            this._selectionDragonflyFillLayer.filter = filter;
            this._selectionDragonflyPointLayer.filter = filter;
        }

        this._selectionDragonflyLayers.push(this._selectionDragonflyFillLayer);
        this._selectionDragonflyLayers.push(this._selectionDragonflyPointLayer);
    }
}

export default SelectionData;
