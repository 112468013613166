import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

/**
 * @param {object} props
 * @param {import('../../../').SearchDidYouMean} props.suggestion
 * @param {(suggest: import('../../../').SearchDidYouMean, optionIndex: number) => void} props.onClick
 * @param {import('react-intl').intlShape} props.intl
 */
const DidYouMeanSuggestion = ({ suggestion, onClick, intl }) => {
    const elements = suggestion.options.map((option, index) => (
        <button
            key={option}
            className="search-suggestion-button"
            onClick={() => onClick(suggestion, index)}
            aria-label={`${intl.formatMessage({
                id: 'dataBrowser.suggestion',
            })} ${option}`}
        >
            {option}
        </button>
    ));
    return (
        <div className="search-did-you-mean-suggestion">
            {elements}
            {intl.formatMessage({
                id: 'dataBrowser.didYouMeanConnection',
            })}
            <strong>{suggestion.text}</strong>?
        </div>
    );
};

DidYouMeanSuggestion.propTypes = {
    suggestion: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(DidYouMeanSuggestion);
