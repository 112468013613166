class LibraryLayer {
    constructor(values) {
        if (values) {
            Object.keys(values).forEach(k => {
                if (this.constructor.prototype.hasOwnProperty(k)) {
                    this[k] = values[k];
                }
            });
        }
    }

    get id() {
        return this._id;
    }

    set id(id) {
        this._id = id;
    }

    get title() {
        return this._title;
    }

    set title(title) {
        this._title = title;
    }

    /** @type {boolean} */
    get visible() {
        return this._visible;
    }

    set visible(visible) {
        this._visible = visible;
    }

    equals(that) {
        return (this.id === that.id &&
        this.title === that.title &&
        this.visible === that.visible);
    }

    clone() {
        const libraryLayer = new LibraryLayer();
        libraryLayer.id = this.id;
        libraryLayer.title = this.title;
        libraryLayer.visible = this.visible;

        return libraryLayer;
    }

    toJSON() {
        return {
            id: this.id,
            title: this.title,
            visible: this.visible,
        };
    }
}

export default LibraryLayer;
