import GLU from '../glu2.js/src/index';

class UserApi extends GLU.Api {
  constructor(endpoint) {
    super(endpoint);

    this.createApiActions(
      {
        name: 'getUserInfo',
        method: GLU.Api.Get,
        path: '/user',
        credentials: true,
      },
      {
        name: 'logOut',
        method: GLU.Api.Get,
        path: '/sign_out',
        credentials: true,
      },
    );
  }
}

export default UserApi;
