import React from 'react';
import BusComponent from '../../BusComponent';

class UnmatchedSelectionPopup extends BusComponent {

    onExit = () => {
        this.emit('CLOSE_MODAL');
    }

    render() {
        const { warnings, summaryLevels, surveyName } = this.props;
        const unmatched = [];
        Object.keys(warnings).forEach(id => {
            const summaryLevel = summaryLevels.find(sl => sl.name === id);
            let title = id;
            if (summaryLevel) {
                title = warnings[id].length > 1 ? summaryLevel.pluralName : summaryLevel.label;
            }
            unmatched.push(<div
                key={title}
                className="geo-selection-import-popup__list-item flex-fixed margin-bottom-10"
            >
                {`${title}: ${warnings[id].join(',')}`}
            </div>);
        });
        return (<div className="geo-selection-import-popup flex-column">
            <div className="geo-selection-import-popup__info font-15 font-bold-5 margin-bottom-10">
                {`Some geographies could not be matched for survey ${surveyName}`}
            </div>
            <div className="geo-selection-import-popup__list flex-one flex-column vertical-scrollbar">
                {unmatched}
            </div>
            <div className="flex-it center flex-end">
                <div className="btn-raised" onClick={this.onExit}>OK</div>
            </div>
        </div>);
    }
}

export default UnmatchedSelectionPopup;
