/* global d3 */
/* eslint func-names: ["error", "never"]*/
import React from 'react';
import BusComponent from './../BusComponent';
import DataClassificationEvents from '../../enums/DataClassificationEvents';

const ARROWS = 'M5.66,7.07L4.24,8.49,1.41,5.66,0,4.24,4.24,0,5.66,1.41,2.83,4.24Zm7.17-2.83L10,7.07l1.41,1.41,4.24-4.24L14.24,2.83,11.41,0,10,1.41Z';
const PENCIL = 'M3,17.25 L3,21 L6.75,21 L17.81,9.94 L14.06,6.19 L3,17.25 Z M20.71,7.04 C21.1,6.65 21.1,6.02 20.71,5.63 L18.37,3.29 C17.98,2.9 17.35,2.9 16.96,3.29 L15.13,5.12 L18.88,8.87 L20.71,7.04 Z';
const PADDING = { l: 40, t: 10, r: 30, b: 114 };
const NAVIGATION_HEIGHT = 40;

// PLEASE NOTE: we have to use function(){} instead of a short hand lambda syntax at some places in the code,
// because the D3 binds given element as the event handler context... using short hand notation would overwrite
// that information with DataClassificationChart context, and there would be no way for us to get the element
// that is being dragged...
class DataClassificationChart extends BusComponent {
    constructor(props, context) {
        super(props, context);

        this.interval = null;

        this.onDragStartEvent = this.onDragStart.bind(this);
        this.onDragEvent = this.onDrag.bind(this);
        this.onDragEndEvent = this.onDragEnd.bind(this);

        this.onBrushEvent = this.onBrush.bind(this);

        this.onZoomEvent = this.onZoom.bind(this);

        this.onRedrawEvent = this.onRedraw.bind(this);
        this.onRequestRedrawDrag = this.requestRedrawDrag.bind(this);
        this.onRerenderPlotBarsEvent = this.reRenderPlotBars.bind(this);

        this.size = {
            top: 0,
            left: 0,
            width: 100,
            height: 100,
        };
        this.currentScale = 1;
        this.dragElement = null;
        this.y = null;
        this.x = null;
        this.chartSVG = null;
        this.chartBrush = null;
        this.state = {
            options: props.dsOptions,
        };
        this.variables = null;

        this.currentExtent = null;

        this.bindGluBusEvents({
            MANUAL_CUTPOINT_VALUE_REQUEST: this.onManualCutpointValueRequest,
        });
    }

    componentDidMount() {
        this.chartSVG = this.svg;
        this.chart = d3.select(this.chartSVG);

        const parentBottomPadding = parseInt(getComputedStyle(this.chartSVG.parentNode).paddingBottom, 10);
        const width = this.chartSVG.clientWidth || this.chartSVG.parentNode.clientWidth;
        const height = this.chartSVG.clientHeight || this.chartSVG.parentNode.clientHeight;

        this.size = {
            top: PADDING.t,
            left: PADDING.l,
            width: width - PADDING.l - PADDING.r,
            height: height - PADDING.t - PADDING.b - parentBottomPadding,
        };
        this.prepareElements();
        this.appendElements();
        this.calculateScaleAndOffset();
        window.requestAnimationFrame(this.onRedrawEvent);
        window.addEventListener('resize', this.forceReRender);
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
        window.removeEventListener('resize', this.forceReRender);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.dsOptions !== this.state.options) {
            this.chart.selectAll('*').remove();
            this.setState({
                options: nextProps.dsOptions,
            });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.state.options !== nextState.options;
    }

    componentDidUpdate() {
        this.prepareElements();
        this.appendElements();
        this.calculateScaleAndOffset();
        window.requestAnimationFrame(this.onRedrawEvent);
    }

    forceReRender = () => {
        this.chart.selectAll('*').remove();
        const parentBottomPadding = parseInt(getComputedStyle(this.chartSVG.parentNode).paddingBottom, 10);
        const width = this.chartSVG.parentNode.clientWidth > (PADDING.l + PADDING.r) ? this.chartSVG.parentNode.clientWidth : (PADDING.l + PADDING.r);
        const height = this.chartSVG.parentNode.clientHeight - parentBottomPadding > (PADDING.t + PADDING.b) ?
            this.chartSVG.parentNode.clientHeight - parentBottomPadding
            : (PADDING.t + PADDING.b);

        this.size = {
            top: PADDING.t,
            left: PADDING.l,
            width: width - PADDING.l - PADDING.r,
            height: height - PADDING.t - PADDING.b,
        };
        this.prepareElements();
        this.appendElements();
        this.calculateScaleAndOffset();
        window.requestAnimationFrame(this.onRedrawEvent);
    }

    onManualCutpointValueRequest(eventMap) {
        const plotBar = eventMap.plotBar;
        let newValue = eventMap.value;
        d3.selectAll('g.plotHandle').classed('selected', false);
        if (newValue < plotBar.__previousBand__.from) newValue = plotBar.__previousBand__.from;
        if (newValue > plotBar.__nextBand__.to) newValue = plotBar.__nextBand__.to;
        plotBar.__data__ = newValue;
        plotBar.__origin__ = this.x(plotBar.__data__);
        plotBar.__previousBand__.to = plotBar.__data__;
        plotBar.__previousBand__.width = plotBar.__previousBand__.to - plotBar.__previousBand__.from;
        plotBar.__nextBand__.from = plotBar.__data__;
        plotBar.__nextBand__.width = plotBar.__nextBand__.to - plotBar.__nextBand__.from;
        window.requestAnimationFrame(this.onRedrawEvent);
        this.emit(DataClassificationEvents.CLASSES_DRAG_END, this.plotBars);
    }

    onDragStart(element) {
        this.dragElement = element;
        this.dragElement.__previousBand__ = this.plotBars[this.dragElement.__originIndex__];
        this.dragElement.__nextBand__ = this.plotBars[this.dragElement.__originIndex__ + 1];
        this.dragElement.__maxLeft__ = this.x(this.dragElement.__previousBand__.from);
        this.dragElement.__maxRight__ = this.x(this.dragElement.__nextBand__.to);

        this.currentMouseX = (this.xHidden(this.dragElement.__data__) * this.currentScale) - this.offsetLeftPixels;

        this.onRequestRedrawDrag();
        this.interval = setInterval(this.onRequestRedrawDrag, 33);

        // set selected cutpoint
        d3.selectAll('g.plotHandle').classed('selected', false);
        d3.select(this.dragElement).classed('dragging', true);
        d3.select(this.dragElement).classed('selected', true);
        this.emit('DATA_CLASSIFICATION_CUTPOINT_SELECTED', {
            activePlotBar: element,
            positionOffset: PADDING.l,
            xAxis: this.x,
            extent: this.currentExtent,
        });
    }

    onDrag() {
        const adjustedMousePosition = d3.mouse(this.chartSVG)[0] - PADDING.l;
        this.currentMouseX = Math.max(this.dragElement.__maxLeft__, Math.min(this.dragElement.__maxRight__, adjustedMousePosition));
        this.dragElement.__data__ = Number.parseInt(this.x.invert(this.currentMouseX) * 100, 10) / 100;
        if (this.dragElement.__data__ > this.dragElement.__nextBand__.to) {
            this.dragElement.__data__ = this.dragElement.__nextBand__.to;
        }
        if (this.dragElement.__data__ < this.dragElement.__previousBand__.from) {
            this.dragElement.__data__ = this.dragElement.__previousBand__.from;
        }
        this.currentMouseX = this.x(this.dragElement.__data__);
        this.dragElement.__origin__ = this.currentMouseX;
        this.dragElement.__previousBand__.to = this.dragElement.__data__;
        this.dragElement.__previousBand__.width = this.dragElement.__previousBand__.to - this.dragElement.__previousBand__.from;
        this.dragElement.__nextBand__.from = this.dragElement.__data__;
        this.dragElement.__nextBand__.width = this.dragElement.__nextBand__.to - this.dragElement.__nextBand__.from;
        this.emit('DATA_CLASSIFICATION_CUTPOINT_VALUE_CHANGED', {
            left: this.currentMouseX + PADDING.l,
        });
    }

    onDragEnd() {
        d3.select(this.dragElement).classed('dragging', false);
        delete this.dragElement;
        clearInterval(this.interval);
        this.emit(DataClassificationEvents.CLASSES_DRAG_END, this.plotBars);
    }

    onBrush() {
        if (this.chartBrush.empty()) {
            this.chartBrush.extent(this.xHidden.domain());
        }
        this.currentExtent = this.chartBrush.extent();
        this.x.domain(this.currentExtent);
        const s = this.x.domain();
        const sOrig = this.xHidden.domain();
        const newS = (sOrig[1] - sOrig[0]) / (s[1] - s[0]);
        const t = (s[0] - sOrig[0]) / (sOrig[1] - sOrig[0]);
        const trans = this.size.width * newS * t;
        this.zoomBehavior.scale(newS);
        this.zoomBehavior.translate([-trans, 0]);
        this.emit('DATA_CLASSIFICATION_BRUSH_EVENT', {
            brushExtent: this.currentExtent,
        });
        this.calculateScaleAndOffset();
        window.requestAnimationFrame(this.onRedrawEvent);
    }

    onRedraw() {
        this.chart.selectAll('g.x')
            .call(this.xAxis);

        this.xAxisSVG.selectAll('g.tick line')
        // .attr('y2', this.isLineOriginEvent)
            .attr('class', DataClassificationChart.isLineFat);

        this.chart.selectAll('g.brush')
            .call(this.chartBrush);

        this.variables.forEach(variable => {
            // variable.svgHolder.selectAll('defs').remove();
            // variable.svgHolder.call(this.textureLines);
            variable.svgLineHolder
                .attr('transform', `translate(-${this.offsetLeftPixels},0)`)
                .attr('d', variable.svgLine(variable.data));
            variable.svgNavigationLineHolder
                .attr('d', variable.svgNavigationLine(variable.data));
            // .style('fill', this.textureLines.url());
        });
        this.reRenderPlotBars();
        this.chartNavigation.call(this.zoomBehavior);
    }

    onZoom() {
        const t = d3.event.translate;
        const s = d3.event.scale;
        const size = this.size.width * s;
        t[0] = Math.min(t[0], 0);
        t[0] = Math.max(t[0], this.size.width - size);
        this.zoomBehavior.translate(t);
        this.currentExtent = this.x.domain();
        this.chartBrush.extent(this.currentExtent);

        this.emit('DATA_CLASSIFICATION_BRUSH_EVENT', {
            brushExtent: this.currentExtent,
        });
        this.calculateScaleAndOffset();
        window.requestAnimationFrame(this.onRedrawEvent);
    }

    render() {
        return (<svg
            ref={svg => { this.svg = svg; }}
            id={this.props.chartID}
            className={this.props.className}
            width={this.props.width}
            height={this.props.height}
        />);
    }

    calculateScaleAndOffset() {
        const absDistance = Math.abs(this.state.options.xmax - this.state.options.xmin);
        const currentDistance = Math.abs(this.currentExtent[0] - this.currentExtent[1]);
        this.currentScale = absDistance / currentDistance;
        this.offsetLeftPixels = this.xHidden(this.currentExtent[0]) * this.currentScale;
        this.decimals = currentDistance < 50 ? 1 : 0;
        this.decimals = currentDistance < 10 ? 2 : this.decimals;
    }

    reRenderPlotBars() {
        const self = this;
        if (!this.state.options.isMultiVariable) {
            this.chartPane.selectAll('rect.plotBars')
                .each(function (d) {
                    d3.select(this)
                        .attr('transform', `translate(-${self.offsetLeftPixels},0)`)
                        .attr('x', self.xHidden(d.from) * self.currentScale)
                        .attr('width', ((self.xHidden(d.width) - self.xHidden(0)) * self.currentScale));
                });
            this.navigationChartPane.selectAll('rect.plotBars')
                .each(function (d) {
                    d3.select(this)
                        .attr('x', self.xHidden(d.from))
                        .attr('width', ((self.xHidden(d.width) - self.xHidden(0))));
                });
        }

        this.chartPaneHandles.selectAll('g.plotHandle')
            .each(function (d) {
                d3.select(this)
                    .attr('transform', `translate(${((self.xHidden(d) * self.currentScale) - 5) - self.offsetLeftPixels},0)`);
            });
    }

    requestRedrawDrag() {
        window.requestAnimationFrame(this.onRerenderPlotBarsEvent);
    }

    static isLineFat(value) {
        return value === 0 ? 'nullLine' : null;
    }

    prepareElements() {
        this.y = d3.scale.linear()
            .domain([this.state.options.ymax, this.state.options.ymin])
            .range([0, this.size.height])
            .nice();

        this.yAxis = d3.svg.axis()
            .orient('left')
            .innerTickSize(-(this.size.width + 5))
            .outerTickSize(0)
            .tickPadding(6)
            .scale(this.y)
            .tickFormat(e => {
                if (Math.floor(e) !== e) {
                    return null;
                }
                const prefix = d3.formatPrefix(e);
                return `${prefix.scale(e)}${prefix.symbol}`;
            });

        if (this.currentExtent === null) {
            this.currentExtent = [this.state.options.xmin, this.state.options.xmax];
        } else {
            if (this.currentExtent[0] !== this.state.options.xmin) {
                this.currentExtent[0] = this.state.options.xmin;
            }
            if (this.currentExtent[1] !== this.state.options.xmax) {
                this.currentExtent[1] = this.state.options.xmax;
            }
        }

        this.x = d3.scale.linear()
            .domain(this.currentExtent)
            .range([0, this.size.width]);

        this.x2 = d3.scale.linear()
            .domain([this.state.options.xmin, this.state.options.xmax])
            .range([0, this.size.width]);

        this.xHidden = d3.scale.linear()
            .domain([this.state.options.xmin, this.state.options.xmax])
            .range([0, this.size.width]);

        this.xAxis = d3.svg.axis()
            .innerTickSize(-5)
            .outerTickSize(0)
            .tickPadding(3)
            .scale(this.x)
            .tickFormat(e => {
                const prefix = d3.formatPrefix(e);
                return `${prefix.scale(e)}${prefix.symbol}`;
            });

        this.chartBrush = d3.svg.brush()
            .x(this.x2)
            .extent(this.currentExtent)
            .on('brush', this.onBrushEvent);

        /* NAVIGATION DATA */
        this.navigationY = d3.scale.linear()
            .domain([this.state.options.ymax, this.state.options.ymin])
            .range([0, NAVIGATION_HEIGHT])
            .nice();
        this.navigationX = d3.scale.linear()
            .domain([this.state.options.xmin, this.state.options.xmax])
            .range([0, this.size.width]);
        this.xNavigationAxis = d3.svg.axis()
            .innerTickSize(-1)
            .outerTickSize(0)
            .tickPadding(3)
            .scale(this.navigationX)
            .tickFormat(e => {
                const prefix = d3.formatPrefix(e);
                return `${prefix.scale(e)}${prefix.symbol}`;
            });

        this.variables = [];
        Object.keys(this.state.options.variablesData).forEach(variable => {
            // In some casses the data team adds a variable with no values due to consistency across years
            // so we need to skip those variables since a chart cannot be drawn without data
            if (this.state.options.variablesData[variable].data === undefined) return;
            const svgLine = d3.svg.area()
                .x(d => this.x(d.x) - this.x(0))
                .y0(this.y(0)) //
                .y1(d => this.y(d.y));
            const svgNavigationLine = d3.svg.area()
                .x(d => this.navigationX(d.x) - this.navigationX(0))
                .y0(this.navigationY(0)) //
                .y1(d => this.navigationY(d.y));
            this.variables.push({
                data: this.state.options.variablesData[variable].data,
                colors: this.state.options.variablesData[variable].colors,
                svgLine,
                svgNavigationLine,
            });
        }, this);

        const self = this;
        this.dragger = d3.behavior.drag()
            .on('dragstart', function () {
                self.onDragStartEvent(this);
            })
            .on('drag', function () {
                self.onDragEvent(this);
            })
            .on('dragend', function () {
                self.onDragEndEvent(this);
            });
        this.zoomBehavior = d3.behavior.zoom()
            .x(this.x)
            .scaleExtent([1, 1000])
            .on('zoom', function () {
                self.onZoomEvent(this);
            });

        this.preparePlotBars();
    }

    preparePlotBars() {
        this.plotHandles = [];
        this.plotBars = [];
        const filterSet = this.state.options.filterSet.filters;
        if (this.state.options.isMultiVariable) {
            this.plotBars = filterSet.map((filter, filterIdx) => {
                const plotBar = {
                    from: filter.from,
                    to: filter.to,
                };
                if (filterIdx < filterSet.length - 1) {
                    this.plotHandles.push(filter.to);
                }
                plotBar.width = Math.max(0.01, Math.abs(filter.to - filter.from));
                return plotBar;
            });
        } else {
            this.plotBars = filterSet.map((filter, filterIdx) => {
                const plotBar = {
                    fill: this.variables[0].colors[filterIdx],
                    from: filter.from,
                    to: filter.to,
                };
                if (filterIdx < filterSet.length - 1) {
                    this.plotHandles.push(filter.to);
                }
                plotBar.width = Math.max(0.01, Math.abs(filter.to - filter.from));
                return plotBar;
            });
        }
    }

    appendElements() {
        const self = this;
        /* PLOT BARS */
        this.chartPane = this.chart.append('svg')
            .attr('class', 'pane')
            .attr('width', this.size.width)
            .attr('height', this.size.height)
            .attr('x', PADDING.l)
            .attr('y', PADDING.t);

        // this.chartPane.node().addEventListener('wheel', this.onMouseWheelEvent, false);

        if (!this.state.options.isMultiVariable) {
            this.chartPane.selectAll('rect')
                .data(this.plotBars)
                .enter()
                .append('rect')
                .attr('class', 'plotBars')
                .attr('y', 0)
                .attr('height', this.size.height)
                .style('fill', d => d.fill);
        }
        this.chartNavigation = this.chartPane.append('rect')
            .attr('class', 'zoom')
            .attr('width', this.size.width)
            .attr('height', this.size.height);
        this.chartNavigation.call(this.zoomBehavior);

        /* X and Y AXIS */
        this.xAxisSVG = this.chart.append('g')
            .attr('class', 'x')
            .attr('transform', `translate(${PADDING.l},${this.size.height + PADDING.t + 5})`)
            .call(this.xAxis);

        this.chart.append('g')
            .attr('class', `y ${this.state.options.isMultiVariable ? ' multi' : ''}`)
            .attr('transform', `translate(${PADDING.l - 5},${PADDING.t})`)
            .call(this.yAxis);

        // this.textureLines = textures.lines();

        /* DATA LINES */
        this.variables.forEach((variable, idx) => {
            variable.svgLineHolder = this.chart.append('svg')
                .attr('class', `line line${idx}${this.state.options.isMultiVariable ? ' multi' : ''}`)
                .attr('pointer-events', 'none')
                .attr('width', this.size.width)
                .attr('height', this.size.height)
                .attr('x', PADDING.l)
                .attr('y', PADDING.t)
                .append('path')
                .attr('class', `line line${idx}`);

            if (this.state.options.isMultiVariable) {
                variable.svgLineHolder
                    .style('fill', variable.colors[variable.colors.length - 1]);
            }
            variable.svgHolder = this.chart.select('svg.line');
        });


        /* DRAG HANDLES */
        this.chartPaneHandles = this.chart.append('svg')
            .attr('class', 'plotHandles')
            .attr('width', this.size.width)
            .attr('height', this.size.height)
            .attr('x', PADDING.l)
            .attr('y', PADDING.t);

        this.chartPaneHandles.selectAll('rect')
            .data(this.plotHandles)
            .enter()
            .append('g')
            .attr('class', 'plotHandle')
            .attr('height', this.size.height)
            .property('__origin__', d => this.x(d))
            .property('__originIndex__', (d, i) => i)
            .attr('transform', d => `translate(${this.x(d) - 5},0)`)
            .on('mouseover', function () {
                return d3.select(this).classed('mouseover', true);
            })
            .on('mouseout', function () {
                return d3.select(this).classed('mouseover', false);
            })
            .on('click', function () {
                d3.selectAll('g.plotHandle').classed('selected', false);
                self.emit('DATA_CLASSIFICATION_CUTPOINT_SELECTED', {
                    activePlotBar: d3.select(this)[0][0],
                    positionOffset: PADDING.l,
                    xAxis: self.x,
                    extent: self.currentExtent,
                });
                return d3.select(this).classed('selected', true);
            })
            .style('cursor', 'pointer')
            .call(this.dragger);

        const distanceBtwCircles = 29;
        const allHandles = this.chartPaneHandles.selectAll('g.plotHandle');

        const length = Math.floor(allHandles[0].length / 2) * distanceBtwCircles;
        let startPosition = (self.size.height / 2) - length;
        startPosition = startPosition < 15 ? 15 : startPosition;
        allHandles.each(function () {
            const g = d3.select(this);
            const circlePosition = startPosition > self.size.height - 15 ? self.size.height - 15 : startPosition;
            g
                .append('rect')
                .attr('class', 'plotHandleCapture')
                .attr('width', 4)
                .attr('x', 4)
                .attr('height', self.size.height);
            g
                .append('circle')
                .attr('class', 'plotHandleCapture')
                .attr('cx', 5)
                .attr('cy', circlePosition)
                .attr('r', 12);

            g
                .append('rect')
                .attr('class', 'plotHandleBackground')
                .attr('width', 2)
                .attr('y', -2)
                .attr('x', 4)
                .attr('height', self.size.height + 4);

            g
                .append('rect')
                .attr('class', 'plotHandleVline')
                .attr('width', 2)
                .attr('x', 4)
                .attr('height', self.size.height);
            g
                .append('circle')
                .attr('class', 'plotHandleCircleBack')
                .attr('cx', 5)
                .attr('cy', circlePosition)
                .attr('r', 13);
            g
                .append('circle')
                .attr('class', 'plotHandleCircle')
                .attr('cx', 5)
                .attr('cy', circlePosition)
                .attr('r', 12);

            g
                .append('path')
                .attr('class', 'plotHandlePencil')
                .attr('transform', `translate(-3,${(circlePosition) - 9}) scale(0.7)`)
                .attr('d', PENCIL);
            g
                .append('path')
                .attr('class', 'plotHandleArrows')
                .attr('transform', `translate(-3,${(circlePosition) - 4})`)
                .attr('d', ARROWS);
            startPosition += distanceBtwCircles;
        });

        /* BRUSH NAVIGATION CHART */
        this.navigationChartPane = this.chart.append('svg')
            .attr('class', 'pane')
            .attr('width', this.size.width)
            .attr('height', NAVIGATION_HEIGHT)
            .attr('y', this.size.height + PADDING.t + (PADDING.b - 60))
            .attr('x', PADDING.l);
        if (!this.state.options.isMultiVariable) {
            this.navigationChartPane.selectAll('rect')
                .data(this.plotBars)
                .enter()
                .append('rect')
                .attr('class', 'plotBars')
                .attr('height', NAVIGATION_HEIGHT)
                .style('fill', d => `rgb(${chroma(d.fill).desaturate(5).rgb().join(',')})`);
        }
        this.variables.forEach((variable, idx) => {
            variable.svgNavigationLineHolder = this.chart.append('svg')
                .attr('class', `line line${idx}${this.state.options.isMultiVariable ? ' multi' : ''}`)
                .attr('pointer-events', 'none')
                .attr('width', this.size.width)
                .attr('height', NAVIGATION_HEIGHT)
                .attr('x', PADDING.l)
                .attr('y', this.size.height + PADDING.t + (PADDING.b - 60))
                .append('path')
                .attr('class', `line line${idx}`);
        });
        this.xNavigationAxisSVG = this.chart.append('g')
            .attr('class', 'x-navigation')
            .attr('transform', `translate(${PADDING.l},${this.size.height + PADDING.t + (PADDING.b - 20)})`)
            .call(this.xNavigationAxis);

        /* BRUSH SCRUBBER */
        this.chart.append('g')
            .attr('class', 'brush')
            .attr('transform', `translate(${PADDING.l},${this.size.height + PADDING.t + (PADDING.b - 60)})`)
            .call(this.chartBrush);

        const brush = this.chart.selectAll('g.brush');
        const brushHandleE = d3.select('g.resize.e');
        const brushHandleW = d3.select('g.resize.w');

        brush.selectAll('rect').attr('height', 20);

        brush.select('rect.background')
            .attr('height', NAVIGATION_HEIGHT)
            .style('visibility', 'visible');
        brush.select('rect.extent')
            .attr('height', NAVIGATION_HEIGHT)
            .style('visibility', 'visible');

        brushHandleE.select('rect').remove();
        brushHandleW.select('rect').remove();
        brushHandleE.append('path')
            .attr('class', 'handle')
            .attr('d', 'M -4.5 0.5 L 3.5 0.5 L 3.5 15.5 L -4.5 15.5 L -4.5 0.5 M -1.5 4 L -1.5 12 M 0.5 4 L 0.5 12')
            .attr('transform', 'translate(0,13)');
        brushHandleW.append('path')
            .attr('class', 'handle')
            .attr('d', 'M -4.5 0.5 L 3.5 0.5 L 3.5 15.5 L -4.5 15.5 L -4.5 0.5 M -1.5 4 L -1.5 12 M 0.5 4 L 0.5 12')
            .attr('transform', 'translate(0,13)');
    }
}

export default DataClassificationChart;
