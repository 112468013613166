// @ts-check
import React from 'react';
import PropTypes from 'prop-types';

import Variable from './Variable';

/**
 * @typedef Props
 * @property {import('../../../').SearchResultTableVariable[]} variables
 * @property {import('../../../').TableComparableSurvey} selectedSurvey
 * @property {string} mapInstanceId
 * @property {boolean} disabled
 * @property {import('../../../objects/VariableSelectionItem').default[]} currentVariableSelectionItems
 * @property {string} query
 *
 * @param {Props} props
 */
const Variables = props => (
    <div className="data-browser-search-result-table__variables">
        {props.variables.map(variable => (
            <Variable
                key={variable.guid}
                variable={variable}
                selectedSurvey={props.selectedSurvey}
                mapInstanceId={props.mapInstanceId}
                currentVariableSelectionItems={
                    props.currentVariableSelectionItems
                }
                disabled={props.disabled}
                query={props.query}
            />
        ))}
    </div>
);

Variables.propTypes = {
    currentVariableSelectionItems: PropTypes.array.isRequired,
    variables: PropTypes.array.isRequired,
    selectedSurvey: PropTypes.object.isRequired,
    mapInstanceId: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    query: PropTypes.string.isRequired,
};

export default Variables;
