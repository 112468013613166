import React from 'react';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';

import BusComponent from '../components/BusComponent';
import Loader from '../components/Loader';
import ApplicationHeader from '../components/header/ApplicationHeader';
import FrameContainer from '../components/mapFrame/FrameContainer';
import ModalHandler from '../components/ModalHandler';
import ApplicationMode from '../enums/ApplicationMode';
import AutomaticDownload from '../components/automaticDownload/AutomaticDownload';
import HelpTour from '../components/helpTour/HelpTour';
import TouchPanHelp from '../components/touchPanHelp/TouchPanHelp';
import DataFilterWarning from '../components/dataFilter/DataFilterWarning';
import AccessibleAlertBox from '../components/accessibility/AccessibilityAlertBox';

import AppConfig from '../appConfig';

class ExplorePage extends BusComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      currentProjectFrameIndex: 0,
    };

    this.bindGluBusEvents({
      PROJECT_LOAD_SUCCESS: this.onProjectLoadSuccess,
      PROJECT_LOAD_ERROR: this.onProjectLoadError,
      GROUPS_METADATA_LOAD_SUCCESS: this.onGroupsMetadataLoadSuccess,
      GROUPS_METADATA_LOAD_ERROR: this.onGroupsMetadataLoadError,
      FEATURED_CATEGORIES_DATA_LOAD_SUCCESS:
        this.onFeaturedCategoriesDataLoadSuccess,
      CATEGORIES_DATA_LOAD_ERROR: this.onFeaturedCategoriesDataLoadError,
      LIBRARY_DATA_LOAD_SUCCESS: this.onLibraryDataLoadSuccess,
      LIBRARY_DATA_LOAD_ERROR: this.onLibraryDataLoadError,
      USER_INFO_LOAD_SUCCESS: this.onUserInfoSuccess,
      LOG_OUT_SUCCESS: this.onUserLogOutSuccess,
      USER_INFO_LOAD_ERROR: this.onUserInfoLoadError,
      DISPLAY_OVERLAY_REQUEST: this.onDisplayOverlayRequest,
      HIDE_OVERLAY_REQUEST: this.onHideOverlayRequest,
      PROJECT_UPDATE_SUCCESS: this.onProjectUpdated,
      PROJECT_UPGRADE_SUCCESS: this.onProjectUpdated,
      EDIT_CURRENT_PROJECT_INFO_SUCCESS: this.onProjectUpdated,
      CURRENT_PROJECT_EDIT_RIGHTS_UPDATED: this.onProjectUpdated,
      DRAGONFLY_MAP_CREATED: this.onDragonflyMapCreated,
      MAP_WEBGL_CONTEXT_LOST: this.onWebGLContextLost,
      FRAME_UPDATED: this.onFrameUpdated,
    });

    window.onbeforeunload = this.onWindowUnload.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.onWindowResize);
    const { applicationMode } = this.context;

    this.emit('USER_INFO_LOAD_REQUEST');
    this.emit('HELP_TOUR_STATUS_REQUEST');
    this.emit('CPI_VALUES_LOAD_REQUEST');

    // we need this here in embed mode since there is no user in embed mode
    if (applicationMode === ApplicationMode.EMBED) {
      // load groups available to user
      this.emit('GROUPS_METADATA_LOAD_REQUEST');
    }
  }

  componentWillUnmount() {
    this.unbindGluBusEvents();
  }

  onDisplayOverlayRequest(e) {
    const overlayClassNames = e && e.classNames;
    const closeModal = e && e.closeModal;
    let overlayMessage = e && e.overlayMessage;
    if (e && e.key) {
      overlayMessage = this.props.intl.formatMessage({ id: e.key });
    }

    this.setState({
      overlayMessage,
      overlayClassNames,
      displayOverlay: true,
    });

    if (closeModal) {
      this.emit('CLOSE_MODAL');
      this.emit('CLOSE_PAGE_MODAL');
    }
  }

  onHideOverlayRequest() {
    this.emit('HELP_TOUR_STATUS_REQUEST');

    this.setState({ displayOverlay: false });
  }

  onUserInfoSuccess(userInfo) {
    /* eslint-disable */
    window.hj =
      window.hj ||
      function () {
        (hj.q = hj.q || []).push(arguments);
      };
    hj('identify', userInfo.userId, {
      'plan title': userInfo.licenseInfo,
      tenant: AppConfig.constants.tenant,
    });
    hj('tagRecording', [
      `se-user-id: ${userInfo.userId}`,
      `plan-status: ${userInfo.licenseInfo}`,
    ]);
    /* eslint-enable */

    this.setState({
      userInfo,
    });
    // if the user is logged in get user settings and start hotjar recording
    if (userInfo.userId !== -1) {
      window.hj('event', 'start_recording');
      this.emit('USER_SETTINGS_LOAD_REQUEST');
    }
    // load groups available to user
    this.emit('GROUPS_METADATA_LOAD_REQUEST');
  }

  onUserLogOutSuccess = () => {
    // redirect to login page
    window.location.replace(AppConfig.constants.links.login);
  };

  onUserInfoLoadError() {
    this.context.onError('Could not load user info.');
  }

  onGroupsMetadataLoadSuccess() {
    this.emit('FEATURED_CATEGORIES_DATA_LOAD_REQUEST');
    this.emit('GEO_JSON_METADATA_LOAD_REQUEST');
  }

  onGroupsMetadataLoadError() {
    this.unbindGluBusEvents();
    this.context.onError('Could not load groups metadata.');
  }

  onFeaturedCategoriesDataLoadSuccess() {
    this.emit('LIBRARY_DATA_LOAD_REQUEST');
  }

  onFeaturedCategoriesDataLoadError() {
    this.unbindGluBusEvents();
    this.context.onError('Could not load featured categories data.');
  }

  onLibraryDataLoadSuccess() {
    const { projectURL, viewCode } = this.context;
    const payload = {
      source: this,
    };

    if (projectURL) {
      payload.projectURL = projectURL;
    } else if (viewCode) {
      payload.viewCode = viewCode;
    } else {
      this.context.onError('No project to load.');
    }

    this.emit('PROJECT_LOAD_REQUEST', payload);
  }

  onLibraryDataLoadError() {
    this.unbindGluBusEvents();
    this.context.onError('Could not load library layers.');
  }

  onProjectLoadSuccess(project, frame, frameIndex) {
    this.lastSystemState = project.clone();

    this.setState({
      currentProjectFrame: frame,
      currentProject: project,
      currentProjectFrameIndex: frameIndex,
    });
  }

  onProjectLoadError() {
    this.context.onError('Could not load project.');
  }

  onDragonflyMapCreated(eventMap) {
    // apply bounding box only when project is first time loaded on the screen
    if (eventMap.source.mapInstance.initialView.applyBoundingBox) {
      eventMap.source.mapInstance.initialView.applyBoundingBox = false;
      this.emit('INITIAL_VIEW_BOUNDING_BOX_APPLIED');
      this.lastSystemState = this.state.currentProject.clone();
    }
  }

  onWindowUnload() {
    const { currentProject } = this.state;
    const { applicationMode } = this.context;

    if (
      currentProject &&
      !currentProject.equals(this.lastSystemState) &&
      !currentProject.ownedBySe &&
      (applicationMode === ApplicationMode.EXPLORE ||
        applicationMode === ApplicationMode.EDIT)
    ) {
      return 'You have unsaved changes, are you sure you want to leave this page?';
    }
    return undefined;
  }

  onProjectUpdated(e) {
    this.setState({ currentProject: e.project });
    this.lastSystemState = e.project.clone();
  }

  onFrameUpdated({ frameIndex, frame }) {
    this.setState({
      currentProjectFrameIndex: frameIndex,
      currentProjectFrame: frame,
    });
  }

  onWebGLContextLost() {
    this.context.onError(
      "WebGL context was lost. You have probably depleted your computer's resources.",
      'Please try using fewer browser tabs or free your memory by reducing the number of running applications.',
    );
  }

  renderOverlay() {
    const { overlayClassNames, overlayMessage } = this.state;

    return (
      <div className={classNames('overlay', overlayClassNames)}>
        <Loader text={overlayMessage} />
      </div>
    );
  }

  render() {
    const {
      currentProject,
      currentProjectFrameIndex,
      userInfo,
      displayOverlay,
      currentProjectFrame,
    } = this.state;

    if (!currentProject || !currentProjectFrame) {
      return (
        <div className="loading-screen">
          <Loader
            text={this.props.intl.formatMessage({
              id: 'loadingPleaseWait',
            })}
          />
        </div>
      );
    }

    const {
      applicationMode,
      isEmbedded,
      isIframe,
      isMobileDevice,
      isCondensedLayout,
    } = this.context;

    const canEdit =
      applicationMode === ApplicationMode.EXPLORE ||
      applicationMode === ApplicationMode.EDIT;
    const showTour =
      !isCondensedLayout && currentProjectFrameIndex > -1 && canEdit;

    return (
      <div className="application-content explore-page">
        <AccessibleAlertBox />
        <ModalHandler />
        <AutomaticDownload />
        {showTour && <HelpTour />}
        <DataFilterWarning mapInstances={currentProjectFrame.mapInstances} />
        {!isEmbedded && (
          <ApplicationHeader
            currentFrameType={currentProjectFrame.type}
            currentFrameLockMode={currentProjectFrame.lockMode}
            mapInstances={currentProjectFrame.mapInstances}
            projectTitle={currentProject.title}
            isPublic={currentProject.isPublic}
            isDingoProject={currentProject.isMadeWithDingo}
            canSave={currentProject.canSave}
          />
        )}
        <div className="flex-it grow">
          <FrameContainer
            frame={currentProjectFrame}
            isUserLoggedIn={userInfo && userInfo.isLogged}
          />
        </div>
        {displayOverlay && this.renderOverlay()}
        {isIframe && isMobileDevice && <TouchPanHelp />}
      </div>
    );
  }
}

export default injectIntl(ExplorePage);
