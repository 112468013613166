import Api from '../apis/Api';
import GLU from '../glu2.js/src/index';
import AppConfig from '../appConfig';

class ReportDataSource extends GLU.DataSource {
    static get name() {
        return 'ReportDataSource';
    }

    static getInstance() {
        return new ReportDataSource();
    }

    constructor() {
        super(() => false);
    }

    validateGeoSelectionForSurvey(selectionData, selectionInfo, surveyName, surveySummaryLevels, mapSummaryLevels) {
        return new Promise((resolve, reject) => {
            try {
                const result = {
                    selection: {},
                    warnings: {},
                };
                // 1. get available summary levels as intersection of survey and maps summary levels
                const availableSummaryLevels = {};
                mapSummaryLevels.forEach(sl => {
                    availableSummaryLevels[sl.id] = surveySummaryLevels[sl.id];
                });
                // 2. validate fips selectionData with summary levels metadata
                Object.keys(selectionData).forEach(summaryLevelId => {
                    // 2.1. use only summary levels that are available
                    if (!availableSummaryLevels[summaryLevelId]) {
                        result.warnings[summaryLevelId] = result.warnings[summaryLevelId] || [];
                        result.warnings[summaryLevelId].push('Not available for current survey');
                        delete selectionData[summaryLevelId];
                        return;
                    }
                    const summaryLevelMetadata = availableSummaryLevels[summaryLevelId];
                    // 2.2. if selectionData contains empty fips code it overides all other selections
                    if (selectionData[summaryLevelId].find(geo => geo.fips === '')) {
                        selectionData[summaryLevelId] = [{
                            fips: '',
                            name: `All ${summaryLevelMetadata.pluralName}`,
                            isParentSelection: true,
                        }];
                        return;
                    }
                    // 2.3. if data does not originate from SE then we execute zero padding for FIPS codes
                    if (!selectionInfo.isSE && summaryLevelMetadata.fipsCodeLength) {
                        selectionData[summaryLevelId].forEach(geo => {
                            if (geo.fips.length === summaryLevelMetadata.fipsCodeLength - 1 && geo.fips.indexOf('0') !== 0) {
                                geo.fips = `0${geo.fips}`;
                            }
                        });
                    }
                    // 2.4. set isParentSelection flag
                    if (summaryLevelMetadata.fipsCodeLength) {
                        selectionData[summaryLevelId].forEach(geo => {
                            geo.isParentSelection = geo.fips.length < summaryLevelMetadata.fipsCodeLength;
                        });
                    }
                });
                if (Object.keys(selectionData).length === 0) {
                    reject(`No matching geographies were found for survey ${surveyName}`);
                    return;
                }

                // if selection data is from the same survey as current one and has QName then assume it is valid
                if (selectionInfo.surveyName === surveyName && selectionInfo.hasQName) {
                    result.selection = selectionData;
                    resolve(result);
                    return;
                }
                // 3. validate fips selectionData for current survey and get QName
                const summaryLevelsIds = Object.keys(selectionData);
                let numberOfSummaryLevels = summaryLevelsIds.length;
                const callbackWrapper = summaryLevelId => {
                    numberOfSummaryLevels -= 1;
                    // remove this summary level if there are no valid selections for it
                    if (result.selection[summaryLevelId] && !result.selection[summaryLevelId].length) {
                        delete result.selection[summaryLevelId];
                    }
                    // validation is done and there is no valid selection
                    if (numberOfSummaryLevels === 0 && !Object.keys(result.selection).length) {
                        reject(`No matching geographies were found for survey ${surveyName}`);
                        return;
                    }
                    // validation is done
                    if (numberOfSummaryLevels === 0) {
                        resolve(result);
                    }
                };
                summaryLevelsIds.forEach(summaryLevelId => {
                    const fipsCodes = selectionData[summaryLevelId].map(geo => geo.fips).join(',');
                    // do not validate empty FIPS selectiona against server because it will ignore it
                    if (fipsCodes === '') {
                        callbackWrapper(summaryLevelId);
                        return;
                    }
                    // validate FIPS list for this summary level agains current survey
                    this.getGeoItemsByFipsCodes(fipsCodes, summaryLevelId, surveyName).then(data => {
                        const summaryLevelMetadata = availableSummaryLevels[summaryLevelId];
                        result.selection[summaryLevelId] = data.items.map(item => {
                            item.isParentSelection = item.fips.length < summaryLevelMetadata.fipsCodeLength;
                            return item;
                        });
                        if (data.unmatched.length) {
                            result.warnings[summaryLevelMetadata.name] = result.warnings[summaryLevelMetadata.name] || [];
                            data.unmatched.forEach(d => result.warnings[summaryLevelMetadata.name].push(d));
                        }
                        callbackWrapper(summaryLevelId);
                    }).catch(() => {
                        if (numberOfSummaryLevels !== -1) {
                            reject(`No matching geographies were found for survey ${surveyName}`);
                            numberOfSummaryLevels = -1;
                        }
                    });
                });
            } catch (error) {
                console.warn('Geo selection validation error. Unexpected error:', error);
                reject(['Error occured while validating geo selection']);
            }
        });
    }

    getGeoItemsByFipsCodes(fipsCodes, summaryLevelId, surveyName) {
        const payload = {
            bOnlyMajorGeographies: true,
            sFipsCodes: fipsCodes,
            sRequestedGeoType: summaryLevelId,
            sSurveyName: surveyName,
        };
        // reject(`No matching geographies were found for survey ${surveyName}`);
        const logic = (resolve, reject) => {
            Api.report.getGeosByFipsCodes({ payload })
                .then(response => {
                    if (response.body) {
                        resolve(this._parseGeoItems(response.body.d));
                    } else {
                        reject();
                    }
                }, reject);
        };

        return new Promise((resolve, reject) => {
            this.cacheRequest(payload, logic).then(responses => {
                resolve(responses[0]);
            }, reject);
        });
    }

    createReport(definition) {
        // sanitation of definition is done on backend
        const payload = {
            tenant: AppConfig.constants.tenant,
            definition,
        };

        return new Promise((resolve, reject) => {
            Api.newReport.create({ payload })
                .then(response => {
                    if (response.body) {
                        resolve(response.body.id);
                    } else {
                        reject();
                    }
                }, reject);
        });
    }

    _parseGeoItems(data) {
        const result = {
            items: [],
            unmatched: [],
        };
        data.GeoSelection.GeoItems.forEach(geoItem => {
            result.items.push({
                fips: geoItem.FIPS,
                name: geoItem.Label,
            });
        });
        if (data.NotMatched) {
            result.unmatched = data.NotMatched.split('\n').filter(d => d);
        }
        return [result];
    }
}

export default ReportDataSource;
