import React from 'react';
import { injectIntl } from 'react-intl';

import BusComponent from '../BusComponent';
import ButtonMenu from '../buttonMenu/ButtonMenu';

/**
 * @typedef State
 * @property {import('../../').PointsFilterField[]} filters
 *
 * @typedef Props
 * @property {import('react-intl').intlShape} intl
 * @property {() => void} addCriterion
 * @property {import('../../').PointsFilterField[]} addedCriteria
 *
 * @extends {BusComponent<Props, State>}
 */
class NewCriterion extends BusComponent {
    constructor(props) {
        super(props);
        /** @type {State} */
        this.state = {
            filters: [],
        };
    }

    componentDidMount() {
        this.bindGluBusEvents({
            POINTS_FILTERS_RESPONSE: this.onPointsFiltersResponse,
        });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    /**
     *
     * @param {import('../../').PointsFilterField[]} filters
     */
    onPointsFiltersResponse = filters => {
        this.setState({ filters });
    };

    render() {
        const criteria = this.state.filters
            .filter(
                filter =>
                    !this.props.addedCriteria.some(
                        c => c.property === filter.property,
                    ),
            )
            .map(filter => (
                <div
                    onClick={() => {
                        this.props.addCriterion(filter);
                    }}
                    key={filter.property}
                    className="material-menu__item"
                >
                    {filter.label}
                </div>
            ));

        if (!criteria.length) return null;

        return (
            <ButtonMenu
                label={this.props.intl.formatMessage({
                    id: 'points.addCriterion',
                })}
                icon={<i className="material-icons">add</i>}
                tooltip="Add new criterion"
            >
                <div className="material-menu">{criteria}</div>
            </ButtonMenu>
        );
    }
}

export default injectIntl(NewCriterion);
