import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import BusComponent from '../BusComponent';
import SimpleDropdown from '../dropdown/SimpleDropdown';
import AppConfig from '../../appConfig';
import { CPICorrectionButtonEvents } from '../../enums/GoogleTagManagerEvents';
import googleTagManagerEvents from '../../helpers/GoogleTagManagerEventsWrapper';

const CPICorrectionButton = googleTagManagerEvents(
    'button',
    CPICorrectionButtonEvents,
);

class CPICorrection extends BusComponent {
    static NAME = 'CPICorrection';

    constructor(props, context) {
        super(props, context);
        this.state = {
            /** @type {boolean} */
            cpiValuesLoading: true,
            /** @type {import('../../../..').CpiByYear} */
            cpiValuesByYear: undefined,
        };
    }

    componentDidMount() {
        this.bindGluBusEvents({
            CPI_VALUES: this.onCpiValues,
        });
        this.emit('CPI_VALUES_REQUEST', { source: this });
    }

    /**
     *
     * @param {import('../../').CpiByYear} cpiValuesByYear
     * @param {BusComponent} source
     */
    onCpiValues = (cpiValuesByYear, source) => {
        if (source === this) {
            this.setState({ cpiValuesLoading: false, cpiValuesByYear });
        }
    };

    handleDollarYearChange = selectedYear => {
        const { mapInstanceId, currentYear } = this.props;

        this.emit('APPLY_DOLLAR_YEAR_ADJUSTMENT', {
            currentYear,
            mapInstanceId,
            adjustmentYear: selectedYear,
        });
    };

    onReset = () => {
        const { mapInstanceId } = this.props;
        this.emit('CLEAR_DOLLAR_YEAR_ADJUSTMENT', { mapInstanceId });
    };

    render() {
        const { cpiValuesByYear, cpiValuesLoading } = this.state;

        // Don't render anything until CPI values are not loaded
        if (cpiValuesLoading) {
            return null;
        }

        const {
            mapInstanceId,
            currentYear,
            adjustmentDollarYear,
        } = this.props;

        const items = Object.keys(cpiValuesByYear)
            // sort years descending
            .sort((a, b) => (parseInt(a, 10) < parseInt(b, 10) ? 1 : -1))
            .map(cv => ({ id: cv, text: cv }));

        const selectedItem = {
            id: adjustmentDollarYear || -1,
            text: adjustmentDollarYear || this.props.intl.formatMessage({ id: 'dataBrowser.selectDollarYear' }),
        };

        return (
            <div className="data-info cpi-correction">
                <div className="data-info__header">
                    <h4 className="data-info__header-title">
                        {this.props.intl.formatMessage({ id: 'dataBrowser.cpiCorrection' })}
                    </h4>
                    <button className="btn-icon" onClick={this.props.onClose}>
                        <i className="material-icons">close</i>
                    </button>
                </div>
                <div className="data-info__content data-info__content--with-overflow">
                    <div className="flex-it start grow margin-top-10 min-width-0">
                        <div className="flex-it column start">
                            <img
                                className="margin-left-10 margin-right-10"
                                alt="cpi-icon"
                                src={`${AppConfig.constants.assetsBaseURL}/svg/cpi.svg`}
                            />
                        </div>
                        <div className="flex-it grow column margin-left-20 margin-bottom-20 min-width-0">
                            {this.props.intl.formatMessage({ id: 'dataBrowser.adjustDollarsFromCurrentYear' }, { currentYear })}
                            <SimpleDropdown
                                className="margin-top-20"
                                onItemClick={this.handleDollarYearChange}
                                mapInstanceId={mapInstanceId}
                                items={items}
                                selectedItem={selectedItem}
                            />
                        </div>
                    </div>
                </div>
                <div className="data-info__actions">
                    <div className="flex-it flex-end">
                        <button className="btn btn-flat margin-right-10" onClick={this.onReset}>
                            {this.props.intl.formatMessage({ id: 'reset' })}
                        </button>
                        <CPICorrectionButton className="btn btn-raised" onClick={this.props.onClose}>
                            {this.props.intl.formatMessage({ id: 'done' })}
                        </CPICorrectionButton>
                    </div>
                </div>
            </div>
        );
    }
}

CPICorrection.propTypes = {
    mapInstanceId: PropTypes.string.isRequired,
    currentYear: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    adjustmentDollarYear: PropTypes.string,
};

CPICorrection.defaultProps = {
    adjustmentDollarYear: undefined,
};

export default injectIntl(CPICorrection);
