import uuid from 'node-uuid';

import BaseController from './BaseController';
import UserDataUploadDataSource from '../dataSources/UserDataUploadDataSource';
import ProjectDataSource from '../dataSources/ProjectDataSource';
import parseCSV from '../helpers/CSVParser';
import UserDataLayer from '../objects/UserDataLayer';
import PointLayerStyle from '../objects/PointLayerStyle';
import Filter from '../objects/Filter';
import FilterComparisonType from '../enums/FilterComparisonType';
import Brush from '../objects/Brush';
import Symbol from '../objects/Symbol';
import FilterRule from '../objects/FilterRule';
import DataType from '../enums/DataType';
import { GEOCODE_FIELDS, GEOJSON_FILENAME } from '../helpers/UserDataUploadHelper';

const MAX_GEOCODING_FEATURES_NO = 100;

class UserDataUploadController extends BaseController {
    static get name() {
        return 'UserDataUploadController';
    }

    static getInstance(options) {
        return new UserDataUploadController(options);
    }

    onActivate() {
        this.bindGluBusEvents({
            MAP_LOAD: this.onMapLoad,
            RETRIEVE_USER_DATA: this.onRetreiveUserData,
            USER_DATA_CSV_TO_GEOJSON_REQUEST: this.onCSVToGeoJSONRequest,
            PARSE_USER_DATA_UPLOAD: this.onUserDataUploaded,
            CREATE_USER_DATA_LAYER_REQUEST: this.onCreateUserDataLayerRequest,
            DELETE_USER_DATA: this.onDeleteUserData,
            UPDATE_USER_LAYER_VISIBILITY: this.onUpdateUserLayerVisibility,
            UPDATE_USER_LAYER_TITLE: this.onUpdateUserLayerTitle,
            ADD_USER_LAYER_POPUP_COLUMN: this.onAddUserLayerPopupColumn,
            REMOVE_USER_LAYER_POPUP_COLUMN: this.onRemoveUserLayerPopupColumn,
            UPDATE_USER_LAYER_POPUP_TITLE_COLUMN: this.onUpdateUserLayerPopupTitleColumn,
            UPDATE_USER_LAYER_LABELING_COLUMN: this.onUpdateUserLayerLabelingColumn,
            UPDATE_USER_LAYER_RULE_MARKER_STYLE: this.onUpdateUserLayeRuleMarkerStyle,
            UPDATE_USER_LAYER_RULE_VISIBILITY: this.onUpdateUserLayerRuleVisibility,
            UPDATE_USER_LAYER_OVERLAP: this.onUpdateUserLayerOverlap,
            UPDATE_USER_LAYER_INTERACTIVITY: this.onUpdateUserLayerInteractivity,
            UPDATE_USER_LAYER_STYLE_BY_OPTION: this.onUpdateUserLayerStyleByOption,
            DELETE_USER_LAYER: this.onDeleteUserLayer,
            GET_USER_DATA_LAYER_REQUEST: this.onGetUserDataLayerRequest,
            DOWNLOAD_USER_DATA: this.onUserDataDownload,
            GEOCODE_USER_DATA: this.onUserDataGeocode,
        });

        this.userDataUploadDataSource = this.activateSource(UserDataUploadDataSource);
        this.projectDataSource = this.activateSource(ProjectDataSource);
    }

    async onMapLoad(e) {
        const mapInstanceId = e.source.id;
        const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);
        for (const userDataLayer of mapInstance.userDataLayers) {
            await this.userDataUploadDataSource.loadUserDataGeoJson(
                userDataLayer.metadata.userDataId,
            );
            const geoJSON =
                this.userDataUploadDataSource.loadedGeoJson[userDataLayer.metadata.userDataId];
            if (geoJSON) {
                userDataLayer.geoJSON = geoJSON;
                this.bus.emit('MAP_APPLY_USER_LAYERS_UPDATE', {
                    userDataLayer,
                    mapInstanceId,
                });
            }
        }
    }

    async onUserDataDownload({ userDataId }) {
        try {
            // Fetch geoJSON first
            await this.userDataUploadDataSource.loadUserDataGeoJson(userDataId);
            const geoJSONWithMetadata = this.userDataUploadDataSource.loadedGeoJson[userDataId];
            if (geoJSONWithMetadata) {
                const geoJson = {
                    type: geoJSONWithMetadata.type,
                    features: geoJSONWithMetadata.features,
                };
                // Create a blob from the JSON data
                const blob = new Blob([JSON.stringify(geoJson, null, 2)], {
                    type: 'application/json',
                });

                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'geoJson.json';

                // Append the link to the body, trigger the download, then remove the link
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } catch (e) {
            console.log('Could not download');
        }
    }

    async onRetreiveUserData() {
        await this.userDataUploadDataSource.loadUserUploadedData();
        this.bus.emit('USER_DATA', this.userDataUploadDataSource.userData);
    }

    async onGetUserDataLayerRequest({ userDataLayerId, mapInstanceId }) {
        const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);
        const userDataLayer = mapInstance.userDataLayers.find(
            layer => layer.id === userDataLayerId,
        );
        if (!userDataLayer) {
            this.bus.emit('USER_DATA_ERROR', {
                type: 'UPLOAD_FILE_ERROR',
                userMessage: 'Could not find user data layer',
            });
            return;
        }
        // Fetch geoJSON first
        const userDataId = userDataLayer.metadata.userDataId;
        await this.userDataUploadDataSource.loadUserDataGeoJson(userDataId);
        const geoJSONWithMetadata = this.userDataUploadDataSource.loadedGeoJson[userDataId];
        const metadata = geoJSONWithMetadata.metadata || {};

        this.bus.emit('USER_DATA_LAYER_READY', {
            userDataLayer,
            userDataLayerMetadata: {
                availableProperties: metadata.columns.map(c => c.title),
                discreteProperties: metadata.columns
                    .map(c => {
                        const uniqueData = [...new Set(c.data)];
                        return {
                            title: c.title,
                            data: uniqueData,
                        };
                    })
                    .filter(c => c.data.length <= 5), // limit to 5 different style options (categories)
            },
        });
    }

    async onCreateUserDataLayerRequest({ userDataId, title, mapInstanceId }) {
        await this.userDataUploadDataSource.loadUserDataGeoJson(userDataId);
        const geoJSON = this.userDataUploadDataSource.loadedGeoJson[userDataId];
        if (!geoJSON) {
            this.bus.emit('USER_DATA_ERROR', {
                type: 'UPLOAD_FILE_ERROR',
                userMessage: 'Error while generating geoJson',
            });
            return;
        }

        this.createUserDataLayer(mapInstanceId, userDataId, geoJSON, title);
    }

    async onDeleteUserData(userData) {
        await this.userDataUploadDataSource.deleteUserData(userData.id);
        this.bus.emit('USER_DATA', this.userDataUploadDataSource.userData);
    }

    generateGeoJSON = (userUpload, lat = undefined, lng = undefined) => {
        // Initialize the GeoJSON object
        let geoJSON = {
            type: 'FeatureCollection',
            features: [],
            metadata: {
                columns: userUpload.columns,
            },
        };

        // Populate the features array
        userUpload.columns[0].data.forEach((_, index) => {
            let feature = {
                type: 'Feature',
                id: index,
                properties: {},
                geometry: {
                    type: 'Point',
                    coordinates: [],
                },
            };

            // Build properties and coordinates
            userUpload.columns.forEach(column => {
                let value = column.data[index];
                // Check column type and convert accordingly
                if (column.properties.type === 'number') {
                    value = Number(value);
                } else if (column.properties.type === 'boolean') {
                    value = value.toLowerCase() === 'true';
                }

                switch (column.id) {
                    case lat:
                        feature.geometry.coordinates[1] = parseFloat(value); // Latitude
                        feature.properties[column.title] = value;
                        break;
                    case lng:
                        feature.geometry.coordinates[0] = parseFloat(value); // Longitude
                        feature.properties[column.title] = value;
                        break;
                    default:
                        feature.properties[column.title] = value;
                        break;
                }
            });

            // Add feature to the features array
            geoJSON.features.push(feature);
        });
        return geoJSON;
    };

    jsonToZip = async geoJsonData => {
        const zip = new JSZip();
        const geoJsonAsString = JSON.stringify(geoJsonData);

        // Add a JSON file to the zip
        zip.file(GEOJSON_FILENAME, geoJsonAsString);

        // Generate ZIP file and trigger download
        const zipFile = await zip.generateAsync({ type: 'blob' });
        return zipFile;
    };

    uploadGeoJson = async (geoJSON, userUpload) => {
        const zipFile = await this.jsonToZip(geoJSON);
        if (!zipFile) {
            this.bus.emit('USER_DATA_ERROR', {
                type: 'UPLOAD_FILE_ERROR',
                userMessage: 'Error while generating zip file',
            });
            return;
        }

        // Define filename and title
        const fileName = `${userUpload.file.name.replace(/\s+/g, '_')}.zip`;

        // Upload data
        const uploadResponse = await this.userDataUploadDataSource.getUserDataUploadUrl({
            fileName,
            contentType: zipFile.type,
            contentLength: zipFile.size,
        });
        if (!uploadResponse) {
            this.bus.emit('USER_DATA_ERROR', {
                type: 'UPLOAD_FILE_ERROR',
                userMessage: 'Error while fetching signed upload url',
            });
            return;
        }

        // Upload file to signed url
        const uploaded = await this.userDataUploadDataSource.uploadFileToSignedS3Url(
            zipFile,
            uploadResponse.signedS3UploadUrl,
        );
        if (!uploaded) {
            this.bus.emit('USER_DATA_ERROR', {
                type: 'UPLOAD_FILE_ERROR',
                userMessage: 'Error while uploading zip file',
            });
            return;
        }

        // Let rails know that the file has been uploaded
        const userData = await this.userDataUploadDataSource.addUserData({
            userDataId: uploadResponse.fileId,
        });
        if (!userData) {
            this.bus.emit('USER_DATA_ERROR', {
                type: 'UPLOAD_FILE_ERROR',
                userMessage: 'Upload error',
            });
            return;
        }

        // Update already loaded geoJson
        this.userDataUploadDataSource.updateLoadedGeoJson(userData.id, geoJSON);
        return userData;
    };

    onCSVToGeoJSONRequest = async ({ mapInstanceId, userUpload, lat, lng }) => {
        const geoJSON = this.generateGeoJSON(userUpload, lat, lng);
        // Upload geoJson
        const userData = await this.uploadGeoJson(geoJSON, userUpload);
        if (userData) {
            // Create data layer
            this.createUserDataLayer(mapInstanceId, userData.id, geoJSON, userData.title);
        }
    };

    onUserDataUploaded({ file }) {
        const extension = file.name.split('.').pop();

        if (extension !== 'csv') {
            this.bus.emit('USER_DATA_ERROR', {
                type: 'UPLOAD_FILE_ERROR',
                userMessage: 'Please choose a CSV file',
            });
            return;
        }

        parseCSV({ file, preview: 1000 }).then(
            result => this.bus.emit('USER_DATA_UPLOAD_PARSED', { userUpload: { ...result, file } }),
            error =>
                this.bus.emit('USER_DATA_ERROR', { type: 'UPLOAD_FILE_ERROR', userMessage: error }),
        );
    }

    createUserDataLayer(mapInstanceId, userDataId, geoJSON, title, id = uuid.v4()) {
        const layerStyle = new PointLayerStyle();
        const userDataLayer = new UserDataLayer({
            id,
            title,
            geoJSON,
            layerStyle,
            styleRules: [layerStyle],
            metadata: {
                userDataId,
            },
        });

        // Add the layer to the map
        const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);
        mapInstance.userDataLayers.push(userDataLayer);

        this.bus.emit('MAP_APPLY_USER_LAYERS_UPDATE', { mapInstanceId });
        this.bus.emit('EDIT_USER_LAYER_REQUEST', userDataLayer);
    }

    async onUserDataGeocode({ mapInstanceId, userUpload, geocodeFields }) {
        // Generate inital geoJSON (without geometry)
        const geoJSON = this.generateGeoJSON(userUpload);

        // Only MAX_GEOCODING_FEATURES_NO will be geocoded
        geoJSON.features = geoJSON.features.slice(0, MAX_GEOCODING_FEATURES_NO);

        const geocodeProperties = GEOCODE_FIELDS.reduce((prev, current) => {
            const fieldId = geocodeFields[current.id];
            const property = userUpload.columns.find(c => c.id === fieldId);
            if (property) {
                prev.push(property.title);
            }
            return prev;
        }, []);

        for (const feature of geoJSON.features) {
            try {
                const geoCodeTerm = geocodeProperties
                    .reduce((term, property) => {
                        if (feature.properties[property]) {
                            term.push(feature.properties[property]);
                        }
                        return term;
                    }, [])
                    .join(',');
                const position = await this.userDataUploadDataSource.geocode(geoCodeTerm);
                if (position) {
                    feature.geometry.coordinates = [position.lng, position.lat];
                }
            } catch (error) {
                console.error('Fetch error:', error);
            }
        }

        // Upload geoJson
        const userData = await this.uploadGeoJson(geoJSON, userUpload);
        if (userData) {
            // Create data layer
            this.createUserDataLayer(mapInstanceId, userData.id, geoJSON, userData.title);
        }
    }

    onUpdateUserLayerVisibility({ layerId, visible, mapInstanceId }) {
        const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);
        const userDataLayer = mapInstance.userDataLayers.find(l => l.id === layerId);
        userDataLayer.visible = visible;
        this.bus.emit('MAP_APPLY_USER_LAYERS_UPDATE', {
            userDataLayer,
            mapInstanceId,
        });
    }

    onUpdateUserLayerTitle({ layerId, title, mapInstanceId }) {
        const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);
        const userDataLayer = mapInstance.userDataLayers.find(l => l.id === layerId);
        userDataLayer.title = title;
        this.bus.emit('MAP_APPLY_USER_LAYERS_UPDATE', {
            userDataLayer,
            mapInstanceId,
        });
    }

    onAddUserLayerPopupColumn({ layerId, column, mapInstanceId }) {
        const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);
        const userDataLayer = mapInstance.userDataLayers.find(l => l.id === layerId);
        if (column) {
            userDataLayer.popupOtherColumns.push(column);
            this.bus.emit('MAP_APPLY_USER_LAYERS_UPDATE', {
                userDataLayer,
                mapInstanceId,
            });
        }
    }

    onRemoveUserLayerPopupColumn({ layerId, column, mapInstanceId }) {
        const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);
        const userDataLayer = mapInstance.userDataLayers.find(l => l.id === layerId);
        const index = userDataLayer.popupOtherColumns.findIndex(c => c === column);
        userDataLayer.popupOtherColumns.splice(index, 1);
        this.bus.emit('MAP_APPLY_USER_LAYERS_UPDATE', {
            userDataLayer,
            mapInstanceId,
        });
    }

    onUpdateUserLayerPopupTitleColumn({ layerId, column, mapInstanceId }) {
        const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);
        const userDataLayer = mapInstance.userDataLayers.find(l => l.id === layerId);
        userDataLayer.popupTitleColumn = column || undefined;
        this.bus.emit('MAP_APPLY_USER_LAYERS_UPDATE', {
            userDataLayer,
            mapInstanceId,
        });
    }

    onUpdateUserLayerLabelingColumn({ layerId, pointLabel, mapInstanceId }) {
        const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);
        const userDataLayer = mapInstance.userDataLayers.find(l => l.id === layerId);
        userDataLayer.pointLabel = pointLabel;
        this.bus.emit('MAP_APPLY_USER_LAYERS_UPDATE', {
            userDataLayer,
            mapInstanceId,
        });
    }

    onUpdateUserLayeRuleMarkerStyle({ layerId, styleRule, newStyle, mapInstanceId }) {
        const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);
        const userDataLayer = mapInstance.userDataLayers.find(l => l.id === layerId);
        const styleToChange = userDataLayer.styleRules.find(s => s === styleRule);
        styleToChange.markerPathId = newStyle.markerPathId;
        styleToChange.markerColor = newStyle.markerColor;
        // userDataLayer.layerStyle.markerPathId = newStyle.markerPathId;
        // userDataLayer.layerStyle.markerColor = newStyle.markerColor;
        this.bus.emit('MAP_APPLY_USER_LAYERS_UPDATE', {
            userDataLayer,
            mapInstanceId,
        });
    }

    onUpdateUserLayerRuleVisibility({ layerId, styleRule, isHidden, mapInstanceId }) {
        const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);
        const userDataLayer = mapInstance.userDataLayers.find(l => l.id === layerId);
        const styleToChange = userDataLayer.styleRules.find(s => s.equals(styleRule));
        styleToChange.isHidden = isHidden;
        this.bus.emit('MAP_APPLY_USER_LAYERS_UPDATE', {
            userDataLayer,
            mapInstanceId,
        });
    }

    onUpdateUserLayerOverlap({ layerId, allowOverlap, mapInstanceId }) {
        const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);
        const userDataLayer = mapInstance.userDataLayers.find(l => l.id === layerId);
        userDataLayer.allowOverlap = allowOverlap;
        this.bus.emit('MAP_APPLY_USER_LAYERS_UPDATE', {
            userDataLayer,
            mapInstanceId,
        });
    }

    onUpdateUserLayerInteractivity({ layerId, interactive, mapInstanceId }) {
        const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);
        const userDataLayer = mapInstance.userDataLayers.find(l => l.id === layerId);
        userDataLayer.interactive = interactive;
        this.bus.emit('MAP_APPLY_USER_LAYERS_UPDATE', {
            userDataLayer,
            mapInstanceId,
        });
    }

    onUpdateUserLayerStyleByOption({ mapInstanceId, layerId, styleByColumn, dataValues }) {
        const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);
        const userDataLayer = mapInstance.userDataLayers.find(l => l.id === layerId);

        // handles user change of Style selection (ALL SAME or BY VALUE)
        if (styleByColumn) {
            // in case of `BY VALUE` ser the valueColumn, pull the data of column and create MULTIPLE styles for individual values (categories/buckets)
            userDataLayer.valueColumn = styleByColumn;
            userDataLayer.styleRules = dataValues.map(value => new PointLayerStyle({ value }));
        } else {
            // in case of `ALL SAME` clear the valueColumn and set the default style of a SINGLE marker (in case of point layers)
            userDataLayer.valueColumn = undefined;
            userDataLayer.styleRules = [new PointLayerStyle()];
        }

        this.bus.emit('MAP_APPLY_USER_LAYERS_UPDATE', {
            userDataLayer,
            mapInstanceId,
        });
    }

    onDeleteUserLayer({ userDataLayerId, mapInstanceId }) {
        const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);
        mapInstance.userDataLayers.splice(
            mapInstance.userDataLayers.findIndex(l => l.id === userDataLayerId),
            1,
        );
        this._bus.emit('MAP_APPLY_USER_LAYERS_UPDATE', { mapInstanceId });
    }

    createRules(colorColumn, colorPalette, numberOfCutpoints) {
        let breaks = [];

        if (colorColumn.type === DataType.TEXT) {
            breaks = colorColumn.properties.discreteValues.map(val => ({ valueStr: val }));
        } else {
            const step =
                (colorColumn.properties.max - colorColumn.properties.min) /
                (numberOfCutpoints * 1.0);
            breaks = [{ to: colorColumn.properties.min }];
            let lastVal = colorColumn.properties.min;
            for (let i = 0; i < numberOfCutpoints - 2; i += 1) {
                breaks.push({ from: lastVal, to: lastVal + step });
                lastVal += step;
            }
            breaks.push([{ from: lastVal }]);
        }
        const colors = colorPalette.interpolateColors(breaks.length);
        const strokeColors = colorPalette.interpolateStrokeColors(breaks.length);

        const rules = breaks.map((br, index) => {
            const filter = new Filter();
            filter.fieldName = colorColumn.title;
            if (colorColumn.type === DataType.TEXT) {
                filter.comparisonType = FilterComparisonType.MATCH_VALUE_STR;
                filter.valueStr = br.valueStr;
                filter.label = br.valueStr;
            } else {
                filter.comparisonType = FilterComparisonType.MATCH_RANGE;
                filter.from = br.from;
                filter.to = br.to;

                if (br.from === undefined) {
                    filter.label = `< ${br.to}`;
                } else if (br.to === undefined) {
                    filter.label = `> ${br.from}`;
                } else {
                    filter.label = `${br.from} - ${br.to}`;
                }
            }

            const bubbleBrush = new Brush({
                fillColor: colors[index],
                fillOpacity: 0.8,
                strokeColor: strokeColors[index],
                strokeWidth: 1,
                strokeOpacity: 0.25,
            });

            const bubbleSymbol = new Symbol();
            bubbleSymbol.type = 'BubbleSymbol';
            bubbleSymbol.brushes = [bubbleBrush];

            const filterRule = new FilterRule();
            filterRule.filter = filter;
            filterRule.title = filter.label;
            filterRule.symbols = [bubbleSymbol];

            return filterRule;
        });
        return rules;
    }

    onDeactivate() {
        this.unbindGluBusEvents();
    }
}

export default UserDataUploadController;
