import React from 'react';
import { injectIntl } from 'react-intl';
import BusComponent from '../../BusComponent';

import GeoSelectionListItem from './GeoSelectionListItem';

import classnames from 'classnames';

class GeoSelectionListSection extends BusComponent {

    onLoadMore = () => {
        if (this.props.onLoadMore) {
            this.props.onLoadMore(this.props.summaryLevel);
        }
    };

    onRemoveGeo = geo => {
        if (this.props.onRemoveGeo) {
            this.props.onRemoveGeo(geo, this.props.summaryLevel);
        }
    }

    renderSelectedGeos() {
        const geoListItems = this.props.geos.map(geo => (<GeoSelectionListItem
            key={geo.id}
            className="selected-geos-section__list-item"
            geo={geo}
            summaryLevel={this.props.summaryLevel}
            onRemove={this.onRemoveGeo}
        />));
        if (this.props.hasMore) {
            geoListItems.push(<button
                key="show-more"
                className="selected-geos-section__show_more"
                onClick={this.onLoadMore}
            >
                {this.props.intl.formatMessage({ id: 'showHide.show.more' })}
            </button>);
        }
        return geoListItems;
    }

    render() {
        const title = this.props.summaryLevel.title ? this.props.summaryLevel.title.replace('Data:', '') : '';

        return (<div className={classnames('selected-geos-section', this.props.className)}>
            <span className="selected-geos-section__title">{title}</span>
            <div className="selected-geos-section__list flex-it column">
                {this.renderSelectedGeos()}
            </div>
        </div>);
    }
}

export default injectIntl(GeoSelectionListSection);
