export default function parse({ rawData, limit = undefined }) {
    return new Promise((resolve, reject) => {
        try {
            const columns = { SEGID: undefined, FIPS: undefined, GEOTYPE: undefined, SURVEY: undefined, QNAME: [] };
            const result = {
                data: {},
                info: {
                    surveyName: undefined,
                    hasQName: false,
                    isSE: false,
                },
                excludedData: [],
            };
            // get data for each column
            rawData.columns.forEach(column => {
                columns[column.title.replace(' ', '')] = column.data;
            });

            // validate that mandatory columns are present
            if (!(columns.SEGID || (columns.FIPS && columns.GEOTYPE))) {
                reject('Column SEGID or combination of FIPS and GEOTYPE columns must be present');
                return;
            }

            // collect info
            result.info.surveyName = columns.SURVEY ? columns.SURVEY[0] : undefined;
            result.info.hasQName = columns.QNAME.length > 0;
            result.info.isSE = columns.SEGID !== undefined;
            // reform raw data
            const numberOfRows = (columns.SEGID || columns.FIPS).length;
            for (let i = 0; i < numberOfRows; i += 1) {
                let summaryLevelId, fips;
                if (result.info.isSE) { // parse summary level and fips from SEGID column if present
                    const seGID = columns.SEGID[i];
                    summaryLevelId = seGID.substring(seGID.indexOf('T') + 1, seGID.indexOf('G'));
                    if (summaryLevelId.length < 3) summaryLevelId = `0${summaryLevelId}`;
                    summaryLevelId = `SL${summaryLevelId}`;
                    fips = seGID.substring(seGID.indexOf('G') + 1, seGID.length);
                } else {
                    summaryLevelId = columns.GEOTYPE[i];
                    fips = columns.FIPS[i];
                }
                if (limit !== undefined && i >= limit) {
                    result.excludedData.push({
                        fips,
                        name: columns.QNAME[i],
                    });
                } else {
                    result.data[summaryLevelId] = result.data[summaryLevelId] || [];
                    result.data[summaryLevelId].push({
                        fips,
                        name: columns.QNAME[i],
                    });
                }
                resolve(result);
            }
        } catch (error) {
            console.warn('Geo selection parse error. Unexpected error:', error);
            reject(['Error occured while importing geo selection']);
        }
    });
}
