'use strict';var pixelsToTileUnits = require('../source/pixels_to_tile_units');
exports.isPatternMissing = function (image, painter) {
    if (!image) {
        return false;
    }
    var imagePosA = painter.imageManager.getPattern(image.from);
    var imagePosB = painter.imageManager.getPattern(image.to);
    return !imagePosA || !imagePosB;
};
exports.prepare = function (image, painter, program) {
    var gl = painter.gl;
    var imagePosA = painter.imageManager.getPattern(image.from);
    var imagePosB = painter.imageManager.getPattern(image.to);
    gl.uniform1i(program.uniforms.u_image, 0);
    gl.uniform2fv(program.uniforms.u_pattern_tl_a, imagePosA.tl);
    gl.uniform2fv(program.uniforms.u_pattern_br_a, imagePosA.br);
    gl.uniform2fv(program.uniforms.u_pattern_tl_b, imagePosB.tl);
    gl.uniform2fv(program.uniforms.u_pattern_br_b, imagePosB.br);
    var ref = painter.imageManager.getPixelSize();
    var width = ref.width;
    var height = ref.height;
    gl.uniform2fv(program.uniforms.u_texsize, [
        width,
        height
    ]);
    gl.uniform1f(program.uniforms.u_mix, image.t);
    gl.uniform2fv(program.uniforms.u_pattern_size_a, imagePosA.displaySize);
    gl.uniform2fv(program.uniforms.u_pattern_size_b, imagePosB.displaySize);
    gl.uniform1f(program.uniforms.u_scale_a, image.fromScale);
    gl.uniform1f(program.uniforms.u_scale_b, image.toScale);
    gl.activeTexture(gl.TEXTURE0);
    painter.imageManager.bind(gl);
};
exports.setTile = function (tile, painter, program) {
    var gl = painter.gl;
    gl.uniform1f(program.uniforms.u_tile_units_to_pixels, 1 / pixelsToTileUnits(tile, 1, painter.transform.tileZoom));
    var numTiles = Math.pow(2, tile.coord.z);
    var tileSizeAtNearestZoom = tile.tileSize * Math.pow(2, painter.transform.tileZoom) / numTiles;
    var pixelX = tileSizeAtNearestZoom * (tile.coord.x + tile.coord.w * numTiles);
    var pixelY = tileSizeAtNearestZoom * tile.coord.y;
    gl.uniform2f(program.uniforms.u_pixel_coord_upper, pixelX >> 16, pixelY >> 16);
    gl.uniform2f(program.uniforms.u_pixel_coord_lower, pixelX & 65535, pixelY & 65535);
};