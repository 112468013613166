import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Key from '../../enums/Key';

// indent is used to show hierarchy of variables
const INDENT_WIDTH = 10;

class TableVariableElement extends React.Component {
    onKeyUp = e => {
        if (e.keyCode === Key.ENTER) {
            this.props.onSelectVariable();
        }
    }

    render() {
        const { variable, selected, onSelectVariable } = this.props;

        return (
            <li
                key={variable.uuid}
                role="option"
                tabIndex={0}
                aria-selected={selected}
                onClick={onSelectVariable}
                onKeyDown={this.onKeyUp}
                className={classNames('variable-item', 'flex-it', 'center', 'space-between', { selected })}
            >
                <div className="variable-item__title flex-it center">
                    <div style={{ width: `${INDENT_WIDTH * variable.indent}px` }} />
                    <div className="body-1">{variable.getLabel(0, false)}</div>
                </div>
            </li>
        );
    }
}

TableVariableElement.propTypes = {
    onSelectVariable: PropTypes.func.isRequired,
    variable: PropTypes.object.isRequired,
    selected: PropTypes.bool,
};

TableVariableElement.defaultProps = {
    selected: false,
};

export default TableVariableElement;
