import React from 'react';
import { injectIntl } from 'react-intl';
import BusComponent from '../BusComponent';
import classNames from 'classnames';
import ReportMissingFacilityButton from './ReportMissingFacilityButton';

import InfoBubbleMode from '../../enums/InfoBubbleMode';

class MapInfoBubbleSwitch extends BusComponent {
    constructor(props, context) {
        super(props, context);

        this.state = {};
    }

    componentDidMount() {
        this.bus.once('CURRENT_INFO_BUBBLE_MODE', this.onSetMode);
        this.emit('CURRENT_INFO_BUBBLE_MODE_REQUEST');
    }

    onSetMode = ({ infoBubbleMode }) => {
        this.setState({ infoBubbleMode });
    };

    onClick = () => {
        this.emit('INFO_BUBBLE_MODE_UPDATE_REQUEST', { infoBubbleMode: InfoBubbleMode.CLICK });
        this.setState({ infoBubbleMode: InfoBubbleMode.CLICK });
    };

    onHover = () => {
        this.emit('INFO_BUBBLE_MODE_UPDATE_REQUEST', { infoBubbleMode: InfoBubbleMode.HOVER });
        this.setState({ infoBubbleMode: InfoBubbleMode.HOVER });
    };

    render() {
        const { infoBubbleMode } = this.state;
        const isClickMode = infoBubbleMode === InfoBubbleMode.CLICK;
        return (<div className="map__info-bubble-switch">
            <ReportMissingFacilityButton />
            <span className="info">
                {this.props.intl.formatMessage({ id: 'showHide.show.tooltipOn' })}
            </span>
            <div className="buttons-area">
                <button
                    className={classNames('btn-link', 'switch-btn', { selected: !isClickMode })}
                    onClick={this.onHover}
                    aria-label={this.props.intl.formatMessage({ id: 'showHide.show.infoBubbleOnHover' })}
                >
                    {this.props.intl.formatMessage({ id: 'map.hover' })}
                </button>
                <button
                    className={classNames('btn-link', 'switch-btn', { selected: isClickMode })}
                    onClick={this.onClick}
                    aria-label={this.props.intl.formatMessage({ id: 'showHide.show.infoBubbleOnClick' })}
                >
                    {this.props.intl.formatMessage({ id: 'map.click' })}
                </button>
            </div>
        </div>);
    }
}
export default injectIntl(MapInfoBubbleSwitch);
