import React from 'react';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';

import BusComponent from '../BusComponent';
import Tooltip from '../Tooltip';
import Errors from '../../enums/Error';
import Loader from '../Loader';

const LocationState = {
    LOCATION_OFF: 0,
    PENDING_PERMISSION: 1,
    LOCATION_ON: 2,
};

class MapGeoLocator extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            userLocation: LocationState.LOCATION_OFF,
        };
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.mapInstanceId !== nextProps.mapInstanceId) {
            this.setState({
                userLocation: LocationState.LOCATION_OFF,
            }, () => {
                this.emit('MAP_CLEAR_CURRENT_LOCATION_REQUEST', { mapInstanceId: this.props.mapInstanceId });
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.userLocation !== LocationState.LOCATION_ON && this.state.userLocation === LocationState.LOCATION_ON) {
            const objectToEmit = {
                position: this.state.position,
                mapInstanceId: this.props.mapInstanceId,
            };
            this.emit('MAP_DISPLAY_CURRENT_LOCATION_REQUEST', objectToEmit);
        }
    }

    onLocateButtonClicked = () => {
        const nextState = {};
        if (this.state.userLocation === LocationState.LOCATION_ON) {
            nextState.userLocation = LocationState.LOCATION_OFF;
            this.emit('MAP_CLEAR_CURRENT_LOCATION_REQUEST', { mapInstanceId: this.props.mapInstanceId });
        } else if (this.state.userLocation === LocationState.LOCATION_OFF) {
            nextState.userLocation = LocationState.PENDING_PERMISSION;
            this.locationPermissionTimeout = setTimeout(() => {
                this.setState({
                    userLocation: LocationState.LOCATION_OFF,
                });
            }, 10000);
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(position => {
                    this.setState({
                        position,
                        userLocation: LocationState.LOCATION_ON,
                    });
                    clearTimeout(this.locationPermissionTimeout);
                });
            } else {
                this.emit('GEOLOCATION_LOAD_ERROR', {
                    level: Errors.ERROR,
                    originalError: this.props.intl.formatMessage({ id: 'map.geolocationIsNotSupportedByThisBrowser' }),
                    additionalInfo: '',
                });
                clearTimeout(this.locationPermissionTimeout);
            }
        }
        this.setState(nextState);
    }

    render() {
        let tooltipLabel;
        const { userLocation } = this.state;

        switch (userLocation) {
        case LocationState.LOCATION_OFF:
            tooltipLabel = this.props.intl.formatMessage({ id: 'map.yourLocation' });
            break;
        case LocationState.PENDING_PERMISSION:
            tooltipLabel = this.props.intl.formatMessage({ id: 'map.locating' });
            break;
        case LocationState.LOCATION_ON:
            tooltipLabel = this.props.intl.formatMessage({ id: 'map.hideYourPositionMarkerFromTheMap' });
        }

        const classes = classNames('map-btn', {
            'map-btn--active': userLocation === LocationState.LOCATION_ON,
        });

        return (
            <div className="map-geo-locator">
                <Tooltip
                    trigger={['hover']}
                    placement="left"
                    mouseLeaveDelay={0}
                    overlay={<span className="light-text">{tooltipLabel}</span>}
                >
                    <button className={classes} onClick={this.onLocateButtonClicked} aria-label={tooltipLabel}>
                        {
                            userLocation === LocationState.PENDING_PERMISSION
                            ? <Loader />
                            : <i className="material-icons">my_location</i>
                        }
                    </button>
                </Tooltip>
            </div>
        );
    }
}

export default injectIntl(MapGeoLocator);
