/* global JSZip */
/* n.b. If you try to import JSZip as a node package, dragonfly workers will explode. You have been warned. */

import BaseController from './BaseController';

import ProjectDataSource from '../dataSources/ProjectDataSource';
import MetadataDataSource from '../dataSources/MetadataDataSource';
import ExportDataSource from '../dataSources/ExportDataSource';

import MarkerAssets from '../map/assets/MarkerAssets';
import format from '../helpers/NumberFormatter';
import getCustomMapMarkers from '../helpers/CustomMapMarkersHelper';
import {
    composeScales,
    getMapResolutionInMeters,
    getBestScaleIndex,
    fitText,
    wrapText,
    calculateTextLines,
    retrieveFontHeight,
    parseFieldName,
    roundNumberToPrecision,
    download,
} from '../helpers/Util';

import {
    snapshotMapBoundsAtZoomLevel,
    canvasToBlob,
    MARKER_MAPPINGS,
} from '../helpers/Export';
import { tractiqLogo } from '../workers/assets-loader/assets-loader';

import Unit from '../enums/Unit';
import FrameTypes from '../enums/FrameType';
import VariableValueType from '../enums/VariableValueType';
import NumberFormat from '../enums/NumberFormat';
import MapExportFormat from '../enums/MapExportFormat';
import ImageScreenSize from '../enums/ImageScreenSize';
import AnnotationsMarkers from '../enums/AnnotationsMarkers';
import FilterComparisonType from '../enums/FilterComparisonType';
import LayerOverrideVisibility from '../enums/LayerOverrideVisibility';

const FEET_TO_METER_RATIO = 3.2808399;

class ExportController extends BaseController {
    static get name() {
        return 'ExportController';
    }

    static getInstance(options) {
        return new ExportController(options);
    }

    onActivate() {
        this.bindGluBusEvents({
            EXPORT_MAP_REQUEST: this.onExportMapRequest,
            THUMBNAIL_LOAD_REQUEST: this.onThumbnailLoadRequest,
            CREATE_AND_DOWNLOAD_GEO_LIST_CSV_REQUEST: this.onCreateAndDownloadGeoListCSVRequest,
            DOWNLOAD_FILE_REQUEST: this.onDownloadFileRequest,
            DOWNLOAD_MAP_EXPORT_REQUEST: this.onDownloadMapExportRequest,
            CHECK_EXPORT_SETTINGS: this.onCheckExportSettings,
            SAVE_EXPORT_SETTINGS: this.onSaveExportSettings,
            RESTORE_EXPORT_SETTINGS: this.onRestoreExportSettings,
        });

        this.projectDataSource = this.activateSource(ProjectDataSource);
        this.metadataDataSource = this.activateSource(MetadataDataSource);
        this.exportDataSource = this.activateSource(ExportDataSource);
        this.nrOfSummaryLevelRequests = undefined;
        this.mapViewers = undefined;
        this.nrOfMapViewers = undefined;
        this.mapViewersToClrSummaryLevel = undefined;
    }


    drawMapLegend(mapViewer) {
        if (mapViewer.mapInstance.layerOverrides.Data &&
            mapViewer.mapInstance.layerOverrides.Data.visibility === LayerOverrideVisibility.ALWAYS_HIDE) return null;
        /*
        Draw legend image
        */
        const legendCanvas = document.createElement('canvas');
        const legendCanvasCtx = legendCanvas.getContext('2d');

        /*
            This is going to change in the next version, so I just copy-pasted the code here
            without any effort to refactor it other than make it work...
            Ugly? Yes. Can we make it prettier? Yes. Does it work? Yes.
         */
        switch (mapViewer.appliedDataTheme.rendering[0].type) {
        case 'ValueRenderer': {
            legendCanvasCtx.font = 'Bold 13px Source Sans Pro';
            legendCanvasCtx.textAlign = 'left';
            const nullDataIndex = mapViewer.appliedDataTheme.rendering[0].nullDataRuleIndex;
            const numberOfFields = nullDataIndex > -1 ? mapViewer.appliedDataTheme.rendering[0].rules.length - 1 : mapViewer.appliedDataTheme.rendering[0].rules.length;
            const legendRectHeight = 20;
            const legendRectWidth = 50;
            const legendRectSpacing = 2;
            const legendPadding = 10;
            let legendWidth = legendRectWidth + legendPadding;
            legendWidth += mapViewer.appliedDataTheme.rendering[0].rules.reduce((width, rule) => {
                const ruleTitleWidth = legendCanvasCtx.measureText(rule.title).width;
                width = width < ruleTitleWidth ? ruleTitleWidth : width;
                return width;
            }, 0);
            legendWidth += (2 * legendPadding);
            const legendHeight = (numberOfFields * (legendRectHeight + legendRectSpacing)) + (4 * legendPadding);

            legendCanvas.width = legendWidth * window.devicePixelRatio;
            legendCanvas.height = legendHeight * window.devicePixelRatio;

            legendCanvasCtx.scale(window.devicePixelRatio, window.devicePixelRatio);

            let counter = 0;
            mapViewer.appliedDataTheme.rendering[0].rules.forEach((rule, index) => {
                if (index !== nullDataIndex) {
                    legendCanvasCtx.fillStyle = `${rule.symbols[0].brushes[0].fillColor}`;
                    const padding = counter > 0 ? legendRectSpacing : 0;
                    const verticalPosition = (2 * legendPadding) + (counter * (legendRectHeight + padding));
                    legendCanvasCtx.fillRect(legendPadding, verticalPosition, legendRectWidth, legendRectHeight);
                    counter += 1;
                    legendCanvasCtx.fillStyle = '#323232';
                    legendCanvasCtx.fillText(rule.title, legendPadding + legendRectWidth + 10, verticalPosition + (legendRectHeight - 6));
                }
            });
            break;
        }
        case 'MultiValueRenderer': {
            const renderer = mapViewer.appliedDataTheme.rendering[0];

            const lineHeight = retrieveFontHeight(12, 'Bold', 'Source Sans Pro');
            const percentLineHeight = retrieveFontHeight(11, 'Normal', 'Source Sans Pro');
            const legendPadding = 10;
            const legendRectHeight = 20;
            const legendWidth = 300;
            let legendHeight = (2 * legendPadding);
            // add rules height to legend height
            legendCanvasCtx.font = 'Normal 12px Source Sans Pro';
            mapViewer.appliedDataTheme.rendering[0].rules.forEach((rulesArray, arrayIndex) => {
                const infoFieldName = parseFieldName(renderer.valuesFieldsNames[arrayIndex]).variableGuid;
                const infoField = renderer.fieldList.fields.find(field => field.fieldName === infoFieldName);
                const numberOfLines = calculateTextLines(legendCanvasCtx, infoField.label, legendWidth - (2 * legendPadding));
                legendHeight += (numberOfLines * lineHeight) + legendPadding + legendRectHeight + percentLineHeight + legendPadding;
            });

            legendCanvas.width = legendWidth * window.devicePixelRatio;
            legendCanvas.height = legendHeight * window.devicePixelRatio;

            legendCanvasCtx.scale(window.devicePixelRatio, window.devicePixelRatio);

            legendCanvasCtx.shadowColor = 'rgba(0, 0, 0, 0.0)';
            legendCanvasCtx.shadowBlur = 0;
            legendCanvasCtx.shadowOffsetX = 0;
            legendCanvasCtx.shadowOffsetY = 0;

            let ruleY = legendPadding + lineHeight;
            const ruleX = legendPadding;
            renderer.rules.forEach((rulesArray, arrayIndex) => {
                const nullDataIndex = renderer.nullDataRuleIndex[arrayIndex] !== undefined ? renderer.nullDataRuleIndex[arrayIndex] : -1;
                const insuffDataIndex = renderer.insufficientDataRuleIndex[arrayIndex] !== undefined ? renderer.insufficientDataRuleIndex[arrayIndex] : -1;
                const infoFieldName = parseFieldName(renderer.valuesFieldsNames[arrayIndex]).variableGuid;
                const infoField = renderer.fieldList.fields.find(field => field.fieldName === infoFieldName);
                let counter = 0;
                const numberOfHorizontalFields = nullDataIndex > -1 ? rulesArray.length - 1 : rulesArray.length;
                const availableWidth = legendWidth - (2 * legendPadding);
                const legendRectWidth = (availableWidth - 5) / numberOfHorizontalFields;
                legendCanvasCtx.fillStyle = '#323232';
                legendCanvasCtx.font = 'Normal 12px Source Sans Pro';
                legendCanvasCtx.textAlign = 'left';
                const result = wrapText(legendCanvasCtx, infoField.label, ruleX, ruleY, availableWidth, lineHeight);

                const ruleLabels = [];
                let rulesToDisplay = [];
                const currentRulesLabelWidths = [];
                legendCanvasCtx.font = 'Normal 11px Source Sans Pro';
                rulesArray.forEach((rule, index) => {
                    if (index !== insuffDataIndex && index !== nullDataIndex && index !== rulesArray.length - 1) {
                        let ruleLabel;
                        if (rule.filter.comparisonType === FilterComparisonType.MATCH_VALUE_STR || rule.filter.valueStr) {
                            ruleLabel = rule.filter.valueStr;
                            currentRulesLabelWidths.push(legendCanvasCtx.measureText(ruleLabel).width);
                        } else if (rule.filter.comparisonType === FilterComparisonType.MATCH_VALUE_NUM || rule.filter.valueNum) {
                            ruleLabel = rule.filter.valueNum;
                            currentRulesLabelWidths.push(legendCanvasCtx.measureText(ruleLabel).width);
                        } else if (rule.filter.to !== Number.MAX_SAFE_INTEGER && !Number.isNaN(rule.filter.to)) {
                            const parsedFieldName = parseFieldName(rule.filter.fieldName);
                            const ruleField = renderer.fieldList.fields.find(f => f.fieldName === parsedFieldName.variableGuid);
                            ruleLabel = format({
                                number: rule.filter.to,
                                numberFormat: ruleField.formatting,
                            });
                            currentRulesLabelWidths.push(legendCanvasCtx.measureText(ruleLabel).width);
                            ruleLabels.push(ruleLabel);
                        }
                    }
                });
                const numberOfRules = insuffDataIndex !== -1 ? numberOfHorizontalFields - 1 : numberOfHorizontalFields;
                const availableGradientWidth = numberOfRules * legendRectWidth;
                if (ruleLabels.reduce((prev, curr) => prev + curr) < availableGradientWidth) {
                    rulesToDisplay = [];
                } else if (ruleLabels.length + 1 === 10) {
                    rulesToDisplay = [0, 8, 4, 2, 6];
                } else if (ruleLabels.length + 1 === 9) {
                    rulesToDisplay = [0, 7, 2, 5];
                } else if (ruleLabels.length + 1 === 7) {
                    // show first and last
                    rulesToDisplay = [0, 5];
                } else if ((ruleLabels.length + 1) % 2 === 0) {
                    // show first, last and middle
                    rulesToDisplay = [0, ruleLabels.length - 1, (ruleLabels.length - 1) / 2];
                } else if ((ruleLabels.length + 1) % 3 === 2) {
                    // add first, last and every other 3k + 1
                    rulesToDisplay = [0, ruleLabels.length - 1];
                    for (let i = 1; i < ruleLabels.length; i += 1) {
                        if (i * 3 === ruleLabels.length - 1) break;
                        rulesToDisplay.push(i * 3);
                    }
                }

                let insuffPadding = insuffDataIndex !== -1 ? 5 : 0;
                rulesArray.forEach((rule, index) => {
                    // Skip null fields
                    if (index === nullDataIndex) return;
                    // Fields
                    legendCanvasCtx.fillStyle = `${rule.symbols[0].brushes[0].fillColor}`;
                    let ruleRectX = ruleX + (counter * legendRectWidth);
                    if (insuffPadding && index !== insuffDataIndex) {
                        ruleRectX += insuffPadding;
                        insuffPadding = 0;
                    }
                    legendCanvasCtx.fillRect(ruleRectX, result.y + legendPadding, legendRectWidth, legendRectHeight);
                    // Text
                    legendCanvasCtx.fillStyle = '#323232';
                    legendCanvasCtx.font = 'Normal 11px Source Sans Pro';
                    const labelPosition = insuffDataIndex !== -1 ? counter - 1 : counter;
                    if ((rulesToDisplay.length === 0 ||
                        rulesToDisplay.includes(labelPosition)) &&
                        index !== insuffDataIndex &&
                        index !== rulesArray.length - 1) {
                        const ruleLabel = ruleLabels[labelPosition];
                        const ruleLabelX = (ruleRectX + legendRectWidth) - Math.round(legendCanvasCtx.measureText(ruleLabel).width / 2);
                        const ruleLabelY = result.y + legendPadding + legendRectHeight + percentLineHeight;
                        legendCanvasCtx.fillText(ruleLabel, ruleLabelX, ruleLabelY);
                    }
                    counter += 1;
                });
                ruleY = result.y + legendPadding + legendRectHeight + percentLineHeight + legendPadding + lineHeight;
            });
        }
            break;
        case 'ValueDotDensityRenderer': {
            const renderer = mapViewer.appliedDataTheme.rendering[0];
            const descriptionText = `One dot represents value of ${format({
                number: renderer.dotValue,
                numberFormat: NumberFormat.FORMAT_NUMBER,
            })}`;
            legendCanvasCtx.font = 'Normal 13px Source Sans Pro';
            legendCanvasCtx.textAlign = 'left';
            const descriptionTextWidth = legendCanvasCtx.measureText(descriptionText).width;
            const descriptionTextHeight = 10;

            const legendPadding = 10;
            const descriptionPointRadius = 3;
            const ruleDotRadius = 6;
            const ruleLineHeight = retrieveFontHeight(12, 'Normal', 'Source Sans Pro');
            let legendWidth = (2 * descriptionPointRadius) + legendPadding + descriptionTextWidth;
            legendWidth += 2 * legendPadding;
            const ruleTextAvailableWidth = legendWidth - (2 * legendPadding) - (2 * ruleDotRadius) - legendPadding;
            let legendHeight = (4 * legendPadding) + descriptionTextHeight;
            renderer.dotValueFieldNames.forEach(dotValueFieldName => {
                const dotField = renderer.fieldList.fields.find(field => field.qualifiedName === dotValueFieldName);
                const text = dotField ? dotField.label : '';
                const numberOfLines = calculateTextLines(legendCanvasCtx, text, ruleTextAvailableWidth);
                if (numberOfLines * ruleLineHeight > ruleDotRadius * 2) {
                    legendHeight += numberOfLines * ruleLineHeight;
                } else {
                    legendHeight += ruleDotRadius * 2;
                }
                legendHeight += legendPadding;
            });

            legendCanvas.width = legendWidth * window.devicePixelRatio;
            legendCanvas.height = legendHeight * window.devicePixelRatio;

            legendCanvasCtx.scale(window.devicePixelRatio, window.devicePixelRatio);

            // Legend box
            legendCanvasCtx.shadowColor = 'rgba(0, 0, 0, 0.0)';
            legendCanvasCtx.shadowBlur = 0;
            legendCanvasCtx.shadowOffsetX = 0;
            legendCanvasCtx.shadowOffsetY = 0;
            legendCanvasCtx.fillStyle = '#323232';
            // Description text
            const descriptionX = legendPadding + (2 * descriptionPointRadius) + legendPadding;
            const descriptionY = (2 * legendPadding) + descriptionTextHeight;
            legendCanvasCtx.fillText(descriptionText, descriptionX, descriptionY);
            // Description point
            const pointX = legendPadding + descriptionPointRadius;
            const pointY = (2 * legendPadding) + Math.round(descriptionTextHeight / 2);
            legendCanvasCtx.beginPath();
            legendCanvasCtx.arc(pointX, pointY, descriptionPointRadius, 0, 2 * Math.PI, false);
            legendCanvasCtx.fillStyle = 'black';
            legendCanvasCtx.fill();
            // Rules
            const ruleTextX = legendPadding + (2 * ruleDotRadius) + legendPadding;
            const ruleDotX = legendPadding + ruleDotRadius;
            let ruleTextY = descriptionY + legendPadding + ruleLineHeight;
            renderer.dotValueFieldNames.forEach((dotValueFieldName, idx) => {
                legendCanvasCtx.fillStyle = '#323232';
                legendCanvasCtx.font = 'Normal 12px Source Sans Pro';
                const dotField = renderer.fieldList.fields.find(field => field.qualifiedName === dotValueFieldName);
                const text = dotField ? dotField.label : '';
                const result = wrapText(legendCanvasCtx, text, ruleTextX, ruleTextY, ruleTextAvailableWidth, ruleLineHeight);
                const ruleDotY = (result.y - Math.round((result.numberOfLines * ruleLineHeight) / 2)) + ruleDotRadius;
                legendCanvasCtx.beginPath();
                legendCanvasCtx.arc(ruleDotX, ruleDotY, ruleDotRadius, 0, 2 * Math.PI, false);
                legendCanvasCtx.fillStyle = renderer.symbols[idx].brushes[0].textColor;
                legendCanvasCtx.fill();
                ruleTextY = result.y + legendPadding + ruleLineHeight;
            });
        }
            break;
        case 'BubbleRenderer': {
            legendCanvasCtx.font = 'Bold 13px Source Sans Pro';
            const normalLineHeight = retrieveFontHeight(13, 'Normal', 'Source Sans Pro');
            const boldLineHeight = retrieveFontHeight(13, 'Bold', 'Source Sans Pro');
            const lowCircleRadius = 10;
            const mediumCircleRadius = 20;
            const highCircleRadius = 30;
            const maxCircleRadius = 35;
            const circlesWidth = 100;
            const legendPadding = 10;
            const legendCircleRadius = 10;
            const labelLineWidth = 60;
            const padding = 5;

            const renderer = mapViewer.appliedDataTheme.rendering[0];
            const tableGuid = mapViewer.appliedDataTheme.variableSelection.items[0].tableGuid;
            const variableGuid = mapViewer.appliedDataTheme.variableSelection.items[0].variableGuid;
            const dataset = mapViewer.appliedDataTheme.variableSelection.items[0].datasetAbbreviation;
            const survey = mapViewer.appliedDataTheme.variableSelection.items[0].surveyName;
            const variableLabel = this.metadataDataSource.currentMetadata.surveys[survey].datasets[dataset].getTableByGuid(tableGuid)
                .getVariableByGuid(variableGuid).label;
            const bubbleSizeFactor = renderer.bubbleSizeFactor;
            const maxBubbleRadius = renderer.maxBubbleSize;
            let maxCircleLabel = roundNumberToPrecision(((maxBubbleRadius ** 2) * Math.PI) / bubbleSizeFactor);
            maxCircleLabel = `> ${format({
                number: maxCircleLabel,
                numberFormat: NumberFormat.FORMAT_NUMBER_NO_DECIMAL,
            })}`;
            let highCircleLabel = roundNumberToPrecision(((highCircleRadius ** 2) * Math.PI) / bubbleSizeFactor);
            highCircleLabel = format({
                number: highCircleLabel,
                numberFormat: NumberFormat.FORMAT_NUMBER_NO_DECIMAL,
            });
            let mediumCircleLabel = roundNumberToPrecision(((mediumCircleRadius ** 2) * Math.PI) / bubbleSizeFactor);
            mediumCircleLabel = format({
                number: mediumCircleLabel,
                numberFormat: NumberFormat.FORMAT_NUMBER_NO_DECIMAL,
            });
            let lowCircleLabel = roundNumberToPrecision(((lowCircleRadius ** 2) * Math.PI) / bubbleSizeFactor);
            lowCircleLabel = format({
                number: lowCircleLabel,
                numberFormat: NumberFormat.FORMAT_NUMBER_NO_DECIMAL,
            });

            // calculate legend width
            let legendWidth = (2 * legendPadding);
            // if max circle label cannot fit into current legend width expand it
            legendCanvasCtx.font = '14px Source Sans Pro';
            if (legendPadding + circlesWidth + legendCanvasCtx.measureText(maxCircleLabel).width + legendPadding > legendWidth) {
                legendWidth = legendPadding + circlesWidth + legendCanvasCtx.measureText(maxCircleLabel).width + legendPadding;
            }
            // if rules text cannot fit into current legend width expand it
            if (mapViewer.appliedDataTheme.bubbleValueType !== VariableValueType.NUMBER) {
                legendCanvasCtx.font = 'Normal 13px Source Sans Pro';
                let rulesWidth = 0;
                renderer.rules.forEach((rule, idx) => {
                    if (idx === renderer.nullDataRuleIndex) return;
                    const ruleWidth = legendCanvasCtx.measureText(rule.title).width;
                    rulesWidth = rulesWidth < ruleWidth ? ruleWidth : rulesWidth;
                });
                if (legendPadding + (2 * legendCircleRadius) + rulesWidth + legendPadding > legendWidth) {
                    legendWidth = legendPadding + (2 * legendCircleRadius) + rulesWidth + legendPadding;
                }
            }

            // calculate legend height
            let legendHeight = (4 * legendPadding);
            // add description text height
            legendHeight += normalLineHeight;
            const availableWidth = legendWidth - (2 * legendPadding);
            legendCanvasCtx.font = 'Bold 13px Source Sans Pro';
            legendHeight += calculateTextLines(legendCanvasCtx, variableLabel, availableWidth) * boldLineHeight;
            // add circles height
            legendHeight += legendPadding + (2 * maxCircleRadius) + 20;

            if (mapViewer.appliedDataTheme.bubbleValueType === VariableValueType.NUMBER) {
                // add positive/negative text height
                legendHeight += normalLineHeight;
            } else {
                // add rules height
                const ruleHeight = (legendCircleRadius * 2) + padding;
                legendHeight += renderer.nullDataRuleIndex !== -1 ? (renderer.rules.length - 1) * ruleHeight : renderer.rules.length * ruleHeight;
            }

            legendCanvas.width = legendWidth * window.devicePixelRatio;
            legendCanvas.height = legendHeight * window.devicePixelRatio;

            legendCanvasCtx.scale(window.devicePixelRatio, window.devicePixelRatio);

            legendCanvasCtx.shadowColor = 'rgba(0, 0, 0, 0.0)';
            legendCanvasCtx.shadowBlur = 0;
            legendCanvasCtx.shadowOffsetX = 0;
            legendCanvasCtx.shadowOffsetY = 0;
            legendCanvasCtx.textAlign = 'left';
            legendCanvasCtx.fillStyle = '#323232';
            // Description
            legendCanvasCtx.font = 'Normal 13px Source Sans Pro';
            const descriptionPosition = legendPadding + normalLineHeight;
            legendCanvasCtx.fillText('Bubble size represents count of', legendPadding, descriptionPosition);
            legendCanvasCtx.fillStyle = '#323232';
            legendCanvasCtx.font = 'Bold 13px Source Sans Pro';
            const result = wrapText(legendCanvasCtx, variableLabel, legendPadding + 2, descriptionPosition + normalLineHeight, availableWidth, boldLineHeight);
            legendCanvasCtx.fillStyle = 'black';

            const highCircleY = result.y + (2 * legendPadding) + 15 + maxCircleRadius;
            const mediumCircleY = highCircleY + (mediumCircleRadius / 2);
            const lowCircleY = mediumCircleY + lowCircleRadius;

            const circlesX = legendPadding + maxCircleRadius;

            const maxCircleLineY = highCircleY - maxCircleRadius - 15;
            const highCircleLineY = highCircleY - highCircleRadius;
            const mediumCircleLineY = mediumCircleY - mediumCircleRadius;
            const lowCircleLineY = lowCircleY - lowCircleRadius;

            const maxLabelY = maxCircleLineY + 2;
            const highLabelY = highCircleLineY + 2;
            const mediumLabelY = mediumCircleLineY + 2;
            const lowLabelY = lowCircleLineY + 2;
            const labelX = legendPadding + circlesWidth;

            // Labels
            legendCanvasCtx.strokeStyle = 'black';
            legendCanvasCtx.lineWidth = 0.5;
            legendCanvasCtx.font = '14px Source Sans Pro';
            legendCanvasCtx.fillText(maxCircleLabel, labelX, maxLabelY);
            legendCanvasCtx.fillText(highCircleLabel, labelX, highLabelY);
            legendCanvasCtx.fillText(mediumCircleLabel, labelX, mediumLabelY);
            legendCanvasCtx.fillText(lowCircleLabel, labelX, lowLabelY);

            // Circles
            legendCanvasCtx.strokeStyle = '#d7d7d7';
            legendCanvasCtx.lineWidth = 3;
            legendCanvasCtx.moveTo(circlesX, highCircleY);
            legendCanvasCtx.beginPath();
            legendCanvasCtx.arc(circlesX, highCircleY, maxCircleRadius, 0, 2 * Math.PI, false);
            legendCanvasCtx.stroke();
            legendCanvasCtx.strokeStyle = 'black';
            legendCanvasCtx.lineWidth = 0.5;
            legendCanvasCtx.moveTo(circlesX, highCircleY - maxCircleRadius);
            legendCanvasCtx.lineTo(circlesX, maxCircleLineY);
            legendCanvasCtx.moveTo(circlesX, maxCircleLineY);
            legendCanvasCtx.lineTo(circlesX + labelLineWidth, maxCircleLineY);
            legendCanvasCtx.stroke();
            legendCanvasCtx.moveTo(circlesX, highCircleY);
            legendCanvasCtx.beginPath();
            legendCanvasCtx.arc(circlesX, highCircleY, highCircleRadius, 0, 2 * Math.PI, false);
            legendCanvasCtx.stroke();
            legendCanvasCtx.moveTo(circlesX, highCircleLineY);
            legendCanvasCtx.lineTo(circlesX + labelLineWidth, highCircleLineY);
            legendCanvasCtx.stroke();
            legendCanvasCtx.moveTo(circlesX, mediumCircleY);
            legendCanvasCtx.beginPath();
            legendCanvasCtx.arc(circlesX, mediumCircleY, mediumCircleRadius, 0, 2 * Math.PI, false);
            legendCanvasCtx.stroke();
            legendCanvasCtx.moveTo(circlesX, mediumCircleLineY);
            legendCanvasCtx.lineTo(circlesX + labelLineWidth, mediumCircleLineY);
            legendCanvasCtx.stroke();
            legendCanvasCtx.moveTo(circlesX, lowCircleY);
            legendCanvasCtx.beginPath();
            legendCanvasCtx.arc(circlesX, lowCircleY, lowCircleRadius, 0, 2 * Math.PI, false);
            legendCanvasCtx.stroke();
            legendCanvasCtx.moveTo(circlesX, lowCircleLineY);
            legendCanvasCtx.lineTo(circlesX + labelLineWidth, lowCircleLineY);
            legendCanvasCtx.stroke();

            const horizontalPosition = legendPadding + legendCircleRadius;
            let verticalPosition = highCircleY + maxCircleRadius + (2 * legendPadding) + padding;

            if (mapViewer.appliedDataTheme.bubbleValueType === VariableValueType.NUMBER) {
                // Color palette for number
                legendCanvasCtx.beginPath();
                legendCanvasCtx.arc(horizontalPosition, verticalPosition - 5, 5, 0, 2 * Math.PI, false);
                legendCanvasCtx.fillStyle = renderer.rules[0].symbols[0].brushes[0].fillColor;
                legendCanvasCtx.fill();

                legendCanvasCtx.beginPath();
                legendCanvasCtx.arc(horizontalPosition + 70, verticalPosition - 5, 5, 0, 2 * Math.PI, false);
                legendCanvasCtx.fillStyle = renderer.rules[1].symbols[0].brushes[0].fillColor;
                legendCanvasCtx.fill();

                legendCanvasCtx.font = 'Normal 13px Source Sans Pro';
                legendCanvasCtx.fillStyle = '#323232';
                legendCanvasCtx.fillText('Negative', horizontalPosition + 10, verticalPosition);
                legendCanvasCtx.fillText('Positive values', horizontalPosition + 80, verticalPosition);
            } else {
                // Color palette for percentage
                legendCanvasCtx.font = 'Normal 13px Source Sans Pro';
                let counter = 0;
                renderer.rules.forEach((rule, index) => {
                    // Skip null fields
                    if (index !== renderer.nullDataRuleIndex) {
                        // Fields
                        verticalPosition = highCircleY + maxCircleRadius + (2 * legendPadding) + (counter * ((legendCircleRadius * 2) + padding));
                        legendCanvasCtx.beginPath();
                        legendCanvasCtx.arc(horizontalPosition, verticalPosition, legendCircleRadius, 0, 2 * Math.PI, false);
                        legendCanvasCtx.fillStyle = `${rule.symbols[0].brushes[0].fillColor}`;
                        legendCanvasCtx.fill();
                        counter += 1;
                        // Text
                        legendCanvasCtx.fillStyle = '#323232';
                        legendCanvasCtx.fillText(rule.title, horizontalPosition + legendCircleRadius + 10, (verticalPosition + legendCircleRadius) - 6);
                    }
                });
            }
        }
            break;
        case 'DotDensityRenderer': {
            const descriptionText = `One dot represents value of ${format({
                number: mapViewer.appliedDataTheme.rendering[0].dotValue,
                numberFormat: NumberFormat.FORMAT_NUMBER,
            })}`;
            legendCanvasCtx.font = 'Normal 13px Source Sans Pro';
            legendCanvasCtx.textAlign = 'left';
            const descriptionTextWidth = legendCanvasCtx.measureText(descriptionText).width;
            const descriptionTextHeight = 10;

            const legendPadding = 10;
            const descriptionPointRadius = 3;
            let legendWidth = (2 * descriptionPointRadius) + legendPadding + descriptionTextWidth;
            legendWidth += 2 * legendPadding;
            const legendHeight = (4 * legendPadding) + descriptionTextHeight;

            legendCanvas.width = legendWidth * window.devicePixelRatio;
            legendCanvas.height = legendHeight * window.devicePixelRatio;

            legendCanvasCtx.scale(window.devicePixelRatio, window.devicePixelRatio);
            // Legend box
            legendCanvasCtx.shadowColor = 'rgba(0, 0, 0, 0.0)';
            legendCanvasCtx.shadowBlur = 0;
            legendCanvasCtx.shadowOffsetX = 0;
            legendCanvasCtx.shadowOffsetY = 0;
            legendCanvasCtx.fillStyle = '#323232';

            // Description text
            const descriptionX = legendPadding + (2 * descriptionPointRadius) + legendPadding;
            const descriptionY = (2 * legendPadding) + descriptionTextHeight;
            legendCanvasCtx.fillText(descriptionText, descriptionX, descriptionY);
            // Description point
            const pointX = legendPadding + descriptionPointRadius;
            const pointY = (2 * legendPadding) + Math.round(descriptionTextHeight / 2);
            legendCanvasCtx.beginPath();
            legendCanvasCtx.arc(pointX, pointY, descriptionPointRadius, 0, 2 * Math.PI, false);
            legendCanvasCtx.fillStyle = mapViewer.appliedDataTheme.rendering[0].symbols[0].brushes[0].textColor;
            legendCanvasCtx.fill();
            break;
        }
        }

        return legendCanvas;
    }

    static truncate(string, length) {
        if (string.length > length) { return `${string.substring(0, length)}...`; }
        return string;
    }

    static drawAnnotationMapLegend(mapViewer, scaledLegendWidth) {
        if (!mapViewer.mapInstance.annotationLegend ||
            !mapViewer.mapInstance.annotationLegend.visible ||
            mapViewer.mapInstance.annotationLegend.legendItems.length === 0 ||
            mapViewer.mapInstance.annotationLegend.legendItems.every(li => !li.included)) return null;
        const annotationLegendCanvas = document.createElement('canvas');
        const annotationLegendCanvasCtx = annotationLegendCanvas.getContext('2d');

        annotationLegendCanvasCtx.font = 'Bold 13px Source Sans Pro';
        annotationLegendCanvasCtx.textAlign = 'left';
        const hasTitle = mapViewer.mapInstance.annotationLegend.title && mapViewer.mapInstance.annotationLegend.title !== '';
        const numberOfFields = mapViewer.mapInstance.annotationLegend.legendItems.filter(li => li.included).length;
        const legendIconSize = 20;
        const legendIconSpacing = 2;
        const legendPadding = 10;
        const legendTitleSpace = hasTitle ? 25 : 0;
        const legendHeight = (numberOfFields * (legendIconSize + legendIconSpacing)) + legendPadding + legendTitleSpace;

        annotationLegendCanvas.width = scaledLegendWidth;
        annotationLegendCanvas.height = legendHeight * window.devicePixelRatio;

        annotationLegendCanvasCtx.scale(window.devicePixelRatio, window.devicePixelRatio);

        if (hasTitle) {
            // Draw title
            annotationLegendCanvasCtx.font = 'Bold 14px Source Sans Pro';
            annotationLegendCanvasCtx.fillStyle = '#323232';
            annotationLegendCanvasCtx.fillText(ExportController.truncate(mapViewer.mapInstance.annotationLegend.title, 23), legendPadding, legendPadding * 2);
        }

        let counter = hasTitle ? 1 : 0;
        mapViewer.mapInstance.annotationLegend.legendItems.slice(0).reverse().forEach(legendItem => {
            if (!legendItem.included) return;
            const padding = counter > 1 ? legendIconSpacing : 0;
            const verticalPosition = legendPadding + (counter * (legendIconSize + padding));
            annotationLegendCanvasCtx.font = '15px social-explorer';
            annotationLegendCanvasCtx.fillStyle = `${legendItem.color}`;
            const legendIcon = MARKER_MAPPINGS[legendItem.markerPathId] || MARKER_MAPPINGS[legendItem.type.toLowerCase()];
            annotationLegendCanvasCtx.fillText(legendIcon, legendPadding, verticalPosition + (legendIconSize - 3));
            annotationLegendCanvasCtx.font = '14px Source Sans Pro';
            annotationLegendCanvasCtx.fillStyle = '#323232';
            annotationLegendCanvasCtx.fillText(ExportController.truncate(legendItem.title, 23), (legendPadding * 3) + legendIconSize, verticalPosition + (legendIconSize - 6));
            counter += 1;
        });

        return annotationLegendCanvas;
    }

    static drawDataFilterMapLegend(mapViewer, metadataDataSource, scaledLegendWidth) {
        const { hasDataFilter } = mapViewer.mapInstance;

        if (!hasDataFilter) return null;
        const dataFilterLegendCanvas = document.createElement('canvas');
        const dataFilterLegendCanvasCtx = dataFilterLegendCanvas.getContext('2d');

        const legendHeight = 55;

        dataFilterLegendCanvas.width = scaledLegendWidth;
        dataFilterLegendCanvas.height = legendHeight * window.devicePixelRatio;
        dataFilterLegendCanvasCtx.scale(window.devicePixelRatio, window.devicePixelRatio);

        dataFilterLegendCanvasCtx.font = 'Bold 14px Source Sans Pro';
        dataFilterLegendCanvasCtx.fillStyle = '#323232';
        dataFilterLegendCanvasCtx.fillText('Data filter', 10, 15);

        dataFilterLegendCanvasCtx.font = 'Normal 12px Source Sans Pro';
        dataFilterLegendCanvasCtx.fillStyle = '#323232';
        dataFilterLegendCanvasCtx.fillText('Areas that do not meet the', 40, 35);
        dataFilterLegendCanvasCtx.fillText('applied criteria', 40, 48);

        dataFilterLegendCanvasCtx.arc(20, 37, 10, 0, 2 * Math.PI);
        dataFilterLegendCanvasCtx.strokeStyle = '#DDDDDD';
        dataFilterLegendCanvasCtx.stroke();
        dataFilterLegendCanvasCtx.clip();

        const color1 = '#4A778B', color2 = '#FFFFFF';
        const numberOfStripes = 50;
        for (let i = 0; i < numberOfStripes * 2; i += 1) {
            const thickness = 1.5;
            const move = 3.5;
            dataFilterLegendCanvasCtx.beginPath();
            dataFilterLegendCanvasCtx.strokeStyle = i % 2 ? color1 : color2;
            dataFilterLegendCanvasCtx.lineWidth = thickness;
            dataFilterLegendCanvasCtx.lineCap = 'round';

            dataFilterLegendCanvasCtx.moveTo(100 - (i * move), 0);
            dataFilterLegendCanvasCtx.lineTo(50 - (i * move), 50);
            dataFilterLegendCanvasCtx.stroke();
        }
        return dataFilterLegendCanvas;
    }

    static iePolyfill() {
        /* eslint-disable */
        // polyfill for IE...
        if (!HTMLCanvasElement.prototype.toBlob) {
            Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
                value: function(callback, type, quality) {
                    var canvas = this;
                    setTimeout(function() {

                        var binStr = atob(canvas.toDataURL(type, quality).split(',')[1]),
                            len = binStr.length,
                            arr = new Uint8Array(len);

                        for (var i = 0; i < len; i++) {
                            arr[i] = binStr.charCodeAt(i);
                        }

                        callback(new Blob([arr], { type: type || 'image/png' }));

                    });
                }
            });
        }
        /* eslint-enable */
    }

    static async drawWatermark(canvas) {
        /*
            Draw slim watermark image in the bottom right corner of the map image
        */
        const ctx = canvas.getContext('2d');
        const img = new Image();
        // Return base64 format via assets-handler
        img.src = await tractiqLogo;
        // Assurement that image is loaded
        await new Promise((resolve, reject) => {
            img.onload = resolve;
            img.onerror = reject;
        });

        // Scale image down to not take up much space on smaller crops
        const imgWidth = img.width * 0.8;
        const imgHeight = img.height * 0.8;

        const xPos = (canvas.width - imgWidth) / 2;
        const yPos = canvas.height - imgHeight - 10;
        ctx.drawImage(img, xPos, yPos, imgWidth, imgHeight);

        return canvas;
    }

    static drawScalebar(mapViewer, targetZoomLevel) {
        /*
            Draw scalebar
        */
        const scalebarCanvas = document.createElement('canvas');
        const scalebarCanvasCtx = scalebarCanvas.getContext('2d');

        let scalebarLength;
        let scalebarLabel;
        const { meterScale, mileScale } = composeScales();
        if (mapViewer.mapInstance.scaleUnit === Unit.METERS) {
            const currentScaleInMeters = () => {
                const res = getMapResolutionInMeters(mapViewer.dragonflyMap.getCenter(), targetZoomLevel) * 100;
                const indexScale = getBestScaleIndex(res, meterScale);
                return {
                    scalebarLength: (meterScale[indexScale].value / res) * 100,
                    scalebarLabel: meterScale[indexScale].label,
                };
            };
            scalebarLabel = currentScaleInMeters().scalebarLabel;
            scalebarLength = Math.ceil(currentScaleInMeters().scalebarLength);
        } else {
            const currentScaleInMiles = () => {
                const res = getMapResolutionInMeters(mapViewer.dragonflyMap.getCenter(), targetZoomLevel) * FEET_TO_METER_RATIO * 100;
                const indexScale = getBestScaleIndex(res, mileScale);

                return {
                    scalebarLength: (mileScale[indexScale].value / res) * 100,
                    scalebarLabel: mileScale[indexScale].label,
                };
            };
            scalebarLabel = currentScaleInMiles().scalebarLabel;
            scalebarLength = Math.ceil(currentScaleInMiles().scalebarLength);
        }

        scalebarCanvasCtx.font = 'Bold 12px Source Sans Pro';
        scalebarCanvasCtx.fillStyle = 'black';
        scalebarCanvasCtx.textAlign = 'left';

        const scalebarPadding = 5;
        const scalebarWidth = scalebarLength + scalebarCanvasCtx.measureText(scalebarLabel).width + (3 * scalebarPadding);
        const scalebarHeight = 25;

        scalebarCanvas.width = scalebarWidth * window.devicePixelRatio;
        scalebarCanvas.height = scalebarHeight * window.devicePixelRatio;
        scalebarCanvasCtx.scale(window.devicePixelRatio, window.devicePixelRatio);

        scalebarCanvasCtx.strokeStyle = 'black';
        scalebarCanvasCtx.lineJoin = 'miter';
        const scalebarLineX = scalebarPadding;
        const scalebarLineY = Math.floor(scalebarHeight / 2) - 4;

        scalebarCanvasCtx.lineWidth = 2;
        scalebarCanvasCtx.beginPath();
        scalebarCanvasCtx.moveTo(scalebarLineX, scalebarLineY);
        scalebarCanvasCtx.lineTo(scalebarLineX, scalebarLineY + 7);
        scalebarCanvasCtx.moveTo(scalebarLineX - 1, scalebarLineY + 6);
        scalebarCanvasCtx.lineTo(scalebarLineX + scalebarLength, scalebarLineY + 6);
        scalebarCanvasCtx.moveTo(scalebarLineX + (scalebarLength - 1), scalebarLineY);
        scalebarCanvasCtx.lineTo(scalebarLineX + (scalebarLength - 1), scalebarLineY + 7);
        scalebarCanvasCtx.stroke();

        scalebarCanvasCtx.fillText(scalebarLabel, scalebarLineX + scalebarLength + scalebarPadding, scalebarLineY + 7);

        return scalebarCanvas;
    }

    async onExportMapRequest({ mapViewer, bounds = mapViewer.dragonflyMap.getBounds(), targetZoomLevel = mapViewer.dragonflyMap.getZoom(), targetPixelRatio = 1 }) {
        const sourceMapLayersMasks = mapViewer._dragonflyMapData.mapInstanceData.layersMasks;

        const markersInUse = mapViewer._dragonflyMapData.userDataLayersData._userDataLayers
            .reduce((uniqueMarkers, userLayer) => {
                // Filter skips adding non-marker points to the export assets list
                // Bubble point data doesn't have marker path and color attributes
                userLayer._styleRules
                    .filter(style => style.type === 'symbol')
                    .forEach(style => {
                        const markerAssetsUndefined = !style._markerPathId || !style._markerColor;
                        if (markerAssetsUndefined) return;

                        const existingMarkerAssets = uniqueMarkers.some(marker => marker.markerPathId === style._markerPathId && marker.markerColor === style._markerColor);
                        if (existingMarkerAssets) return;

                        uniqueMarkers.push({
                            markerPathId: style._markerPathId,
                            markerColor: style._markerColor,
                        });
                    });
                return uniqueMarkers;
            }, []);

        let extraImages = markersInUse.map(marker => {
            const markerId = `${marker.markerPathId}${marker.markerColor}`;
            const markerPng = MarkerAssets.getMarkerPng(markerId, marker, {
                useBackground: true,
                strokeWidth: 2,
                outputSize: 44,
            });
            return { id: markerId, image: markerPng, pixelRatio: 2 };
        });

        mapViewer.mapInstance.annotations.reduce((annotations, annotation) => {
            if (annotation.type !== 'Marker') return annotations;
            const marker = AnnotationsMarkers.find(am => am.id === annotation.markerPathId);
            const markerId = `${marker.fileName}_${annotation.fillColor}`;
            const markerPng = MarkerAssets.getMarkerPng(markerId, {
                markerPathId: marker.fileName,
                markerColor: annotation.fillColor,
            }, {});
            if (extraImages.every(ei => ei.id !== markerId)) {
                extraImages.push({ id: markerId, image: markerPng, pixelRatio: 2 });
            }
            return annotations;
        }, []);

        extraImages = [...extraImages, ...getCustomMapMarkers()];

        try {
            const canvas = await snapshotMapBoundsAtZoomLevel(mapViewer.dragonflyMap, bounds, targetZoomLevel, targetPixelRatio * 96, sourceMapLayersMasks, extraImages, progress => {
                this.bus.emit('EXPORT_MAP_PROGRESS', {
                    percentage: ((((progress.row - 1) * progress.totalColumns) + progress.column) * 100) / (progress.totalRows * progress.totalColumns),
                    progress,
                });
            });
            ExportController.iePolyfill();

            const originalTargetPixelRatio = window.devicePixelRatio;

            Object.defineProperty(window, 'devicePixelRatio', {
                get: () => targetPixelRatio,
            });

            let defaultHeight = 200;
            let defaultWidth = 300 * window.devicePixelRatio;

            await ExportController.drawWatermark(canvas);
            const mapImage = await canvasToBlob(canvas);

            const legendCanvas = this.drawMapLegend(mapViewer);
            const legendImage = legendCanvas !== null && await canvasToBlob(legendCanvas);

            if (legendCanvas) {
                defaultHeight = legendCanvas.height;
                defaultWidth = legendCanvas.width;
            }

            const annotationLegendCanvas = ExportController.drawAnnotationMapLegend(mapViewer, defaultWidth);
            const annotationLegendImage = annotationLegendCanvas !== null && await canvasToBlob(annotationLegendCanvas);

            const dataFilterLegendCanvas = ExportController.drawDataFilterMapLegend(mapViewer, this.metadataDataSource, defaultWidth);
            const dataFilterImage = dataFilterLegendCanvas !== null && await canvasToBlob(dataFilterLegendCanvas);

            const scalebarCanvas = ExportController.drawScalebar(mapViewer, targetZoomLevel);
            const scalebarImage = await canvasToBlob(scalebarCanvas);

            const outputWidth = Math.max(scalebarCanvas.width + defaultWidth, canvas.width);
            const outputHeight = Math.max(scalebarCanvas.height, defaultHeight, canvas.height);

            const outputImageCanvas = document.createElement('canvas');
            const outputImageCanvasCtx = outputImageCanvas.getContext('2d');

            outputImageCanvas.width = outputWidth;
            outputImageCanvas.height = outputHeight;

            outputImageCanvasCtx.drawImage(canvas, (outputImageCanvas.width - canvas.width) / 2, (outputImageCanvas.height - canvas.height) / 2);
            outputImageCanvasCtx.drawImage(scalebarCanvas, 0, outputImageCanvas.height - scalebarCanvas.height);

            let verticalOffset = outputImageCanvas.height;

            // Legend placement
            if (legendImage) {
                const legendHorizontalPosition = outputImageCanvas.width - defaultWidth;
                const legendVerticalPosition = verticalOffset - legendCanvas.height;

                outputImageCanvasCtx.fillStyle = 'rgba(255, 255, 255, 0.75)';
                outputImageCanvasCtx.fillRect(legendHorizontalPosition, legendVerticalPosition, defaultWidth, legendCanvas.height);
                outputImageCanvasCtx.drawImage(legendCanvas, legendHorizontalPosition, legendVerticalPosition);

                verticalOffset = legendVerticalPosition - (20 * window.devicePixelRatio);
            }

            // Annotation legend placement
            if (annotationLegendImage) {
                const annotationLegendHorizontalPosition = outputImageCanvas.width - annotationLegendCanvas.width;
                const annotationLegendVerticalPosition = verticalOffset - annotationLegendCanvas.height;

                outputImageCanvasCtx.fillStyle = 'rgba(255, 255, 255, 0.75)';
                outputImageCanvasCtx.fillRect(annotationLegendHorizontalPosition, annotationLegendVerticalPosition, annotationLegendCanvas.width, annotationLegendCanvas.height);
                outputImageCanvasCtx.drawImage(annotationLegendCanvas, annotationLegendHorizontalPosition, annotationLegendVerticalPosition);

                verticalOffset = annotationLegendVerticalPosition;
            }

            // df legend placement
            if (dataFilterImage) {
                const dataFilterHorizontalPosition = outputImageCanvas.width - dataFilterLegendCanvas.width;
                const dataFilterVerticalPosition = verticalOffset - dataFilterLegendCanvas.height;

                outputImageCanvasCtx.fillStyle = 'rgba(255, 255, 255, 0.75)';
                outputImageCanvasCtx.fillRect(dataFilterHorizontalPosition, dataFilterVerticalPosition, dataFilterLegendCanvas.width, dataFilterLegendCanvas.height);
                outputImageCanvasCtx.drawImage(dataFilterLegendCanvas, dataFilterHorizontalPosition, dataFilterVerticalPosition);
            }

            // title
            const title = mapViewer.mapInstance.userEnteredTitle !== undefined && mapViewer.mapInstance.userEnteredTitle !== '' ? mapViewer.mapInstance.userEnteredTitle : mapViewer.appliedDataTheme.title;
            const result = fitText(30, outputImageCanvas.width - 40, title, 16, outputImageCanvasCtx, 'Bold', 16, 10);
            const lineHeight = retrieveFontHeight(result.fontSize, 'Bold', 'Source Sans Pro');
            outputImageCanvasCtx.font = `Bold ${result.fontSize}px Source Sans Pro`;
            const titleHeight = calculateTextLines(outputImageCanvasCtx, result.text, outputImageCanvas.width - 40) * lineHeight;

            // subtitle
            const subtitle = this.metadataDataSource.currentMetadata.surveys[mapViewer.appliedDataTheme.variableSelection.items[0].surveyName].displayName;
            const subtitleHeight = retrieveFontHeight(14, 'Normal', 'Source Sans Pro');

            outputImageCanvasCtx.scale(window.devicePixelRatio, window.devicePixelRatio);
            outputImageCanvasCtx.font = `Bold ${result.fontSize}px Source Sans Pro`;
            const titleWidth = outputImageCanvasCtx.measureText(title).width;
            outputImageCanvasCtx.fillStyle = 'rgba(255, 255, 255, 0.75)';
            outputImageCanvasCtx.fillRect(0, 0, titleWidth + 16, titleHeight + 16);
            outputImageCanvasCtx.fillStyle = 'rgba(0, 0, 0, 1)';
            outputImageCanvasCtx.fillText(title, 5, titleHeight);

            outputImageCanvasCtx.font = 'Normal 14px Source Sans Pro';
            const subtitleWidth = outputImageCanvasCtx.measureText(subtitle).width;
            outputImageCanvasCtx.fillStyle = 'rgba(255, 255, 255, 0.75)';
            outputImageCanvasCtx.fillRect(0, titleHeight + 16, subtitleWidth + 16, subtitleHeight);
            outputImageCanvasCtx.fillStyle = 'rgba(0, 0, 0, 1)';
            outputImageCanvasCtx.fillText(subtitle, 5, subtitleHeight + titleHeight);

            const combinedImage = await canvasToBlob(outputImageCanvas);

            Object.defineProperty(window, 'devicePixelRatio', {
                get: () => originalTargetPixelRatio,
            });

            this.bus.emit('EXPORT_MAP_SUCCESS', {
                mapImage,
                mapImageCanvas: canvas,
                legendImage,
                legendCanvas,
                scalebarImage,
                scalebarCanvas,
                annotationLegendImage,
                annotationLegendCanvas,
                dataFilterImage,
                dataFilterLegendCanvas,
                combinedImage,
            });
        } catch (error) {
            console.warn('Unexpected error', error);
            this.bus.emit('EXPORT_MAP_ERROR', { error });
        }
    }

    onCreateAndDownloadGeoListCSVRequest(e) {
        const summaryLevelsData = e.summaryLevelsData;
        const surveyName = e.surveyName || '';
        const content = ['SEGID,SURVEY,QNAME,FIPS'];
        summaryLevelsData.forEach(summaryLevelData => {
            summaryLevelData.geos.forEach(geo => {
                const seGID = `${summaryLevelData.summaryLevel.id.replace('SL', 'T')}G${geo.id}`;
                content.push(`${seGID},${surveyName},"${geo.title.replace(/"/g, '\\"')}","${geo.id}"`);
            });
        });
        download(content.join('\n'), 'selected-geo-list.csv', 'text/csv');
    }


    onThumbnailLoadRequest() {
        this.finishExport();
        this.mapViewers = [];
        this.nrOfMapViewers = this.projectDataSource.currentProject.frames[0].mapInstances.length;
        this.nrOfSummaryLevelRequests = undefined;
        this.mapViewersToClrSummaryLevel = [];
        this.flag = 'thumbnail';
        this.size = ImageScreenSize.CURRENT;
        this.bus.on('MAP_VIEWER', this.onMapViewer);
        this.bus.emit('MAP_GET_MAP_VIEWER');
    }

    onDownloadMapExportRequest({
        mapImage,
        legendImage,
        scalebarImage,
        annotationLegendImage,
        dataFilterImage,
        combinedImage,
        targetFormat,
    }) {
        if (targetFormat === MapExportFormat.ZIP) {
            const jsZip = new JSZip();
            jsZip.file('map.png', mapImage);
            jsZip.file('scalebar.png', scalebarImage);
            if (legendImage) {
                jsZip.file('legend.png', legendImage);
            }
            if (annotationLegendImage) {
                jsZip.file('annotationLegend.png', annotationLegendImage);
            }
            if (dataFilterImage) {
                jsZip.file('dataFilterLegend.png', dataFilterImage);
            }
            jsZip
                .generateAsync({ type: 'blob' })
                .then(content => download(content, 'map_export.zip', 'application/zip'));
        } else {
            download(combinedImage, 'image.png', 'image/png');
        }
    }

    onDownloadFileRequest(payload) {
        download(payload.content, payload.fileName, payload.mimeType);
    }

    onCheckExportSettings() {
        this.bus.emit('EXPORT_SETTINGS_CHECKED', { hasSavedSettings: this.exportDataSource.hasSavedSettings() });
    }

    onSaveExportSettings(payload) {
        this.exportDataSource.saveSettings(payload);
        this.bus.emit('EXPORT_SETTINGS_SAVED');
    }

    onRestoreExportSettings() {
        const settings = this.exportDataSource.loadSettings();
        this.bus.emit('EXPORT_SETTINGS_RESTORED', settings);
    }

    onMapPreferredSummaryLevelChanged = () => {
        if (this.nrOfSummaryLevelRequests !== undefined) this.nrOfSummaryLevelRequests -= 1;
        if (this.nrOfSummaryLevelRequests === 0) {
            this.bus.off('MAP_PREFERRED_SUMMARY_LEVEL_CHANGED', this.onMapPreferredSummaryLevelChanged);
            this.nrOfSummaryLevelRequests = undefined;
            this.drawImageExport();
        }
    }

    onMapViewer = e => {
        if (this.mapViewers === undefined) return;
        this.mapViewers.push(e.source);
        if (this.mapViewers.length !== this.nrOfMapViewers) return;
        // do not listen to map viewer request anymore
        this.bus.off('MAP_VIEWER', this.onMapViewer);

        if (this.flag === 'thumbnail') {
            const image = this.drawThumbnailExport(this.mapViewers);
            this.finishExport();
            this.bus.emit('THUMBNAIL_LOAD_SUCCESS', { image });
            return;
        }

        // force maps to current summary level to avoid summary level change
        this.mapViewersToClrSummaryLevel = this.mapViewers.filter(mv => !mv.preferredSummaryLevel && mv.activeSummaryLevel);
        this.nrOfSummaryLevelRequests = this.mapViewersToClrSummaryLevel.length;
        if (this.nrOfSummaryLevelRequests > 0) {
            this.bus.on('MAP_PREFERRED_SUMMARY_LEVEL_CHANGED', this.onMapPreferredSummaryLevelChanged);
            this.mapViewersToClrSummaryLevel.forEach(mapViewer => {
                this.bus.emit('SET_PREFERRED_SUMMARY_LEVEL_REQUEST', {
                    source: this,
                    mapInstanceId: mapViewer.id,
                    summaryLevel: mapViewer.activeSummaryLevel,
                });
            });
        } else {
            this.drawImageExport();
        }
    }

    drawThumbnailExport(mapViewers) {
        const boundingRect = document.getElementsByClassName('frame-container')[0].getBoundingClientRect();
        const size = {
            width: boundingRect.width,
            height: boundingRect.height,
        };
        const outputCanvas = document.createElement('canvas');
        outputCanvas.width = size.width;
        outputCanvas.height = size.height;
        const outputContext = outputCanvas.getContext('2d');

        switch (this.projectDataSource.currentFrame.type) {
        case FrameTypes.SINGLE_MAP:
            outputContext.drawImage(mapViewers[0].dragonflyMap.getCanvas(), 0, 0, size.width, size.height);
            break;
        case FrameTypes.SIDE_BY_SIDE_MAPS:
            outputContext.drawImage(mapViewers[0].dragonflyMap.getCanvas(), 0, 0, size.width, size.height);
            outputContext.drawImage(mapViewers[1].dragonflyMap.getCanvas(), size.width / 2, 0, size.width, size.height);
            break;
        case FrameTypes.SWIPE:
            outputContext.drawImage(mapViewers[0].dragonflyMap.getCanvas(), 0, 0, size.width, size.height);
            outputContext.drawImage(mapViewers[1].dragonflyMap.getCanvas(), size.width / 2, 0, size.width / 2, size.height, size.width / 2, 0, size.width / 2, size.height);
            break;
        }

        if (mapViewers.length > 1) {
            // Add separator line between maps
            outputContext.beginPath();
            outputContext.moveTo(size.width / 2, 0);
            outputContext.lineTo(size.width / 2, size.height);
            outputContext.stroke();
        }
        const outputImage = outputCanvas.toDataURL('image/jpeg', 0.7);
        return outputImage.substr(outputImage.indexOf(',') + 1);
    }

    drawImageExport() {
        let size;
        if (this.size !== ImageScreenSize.CURRENT) {
            size = {
                width: this.size.width,
                height: this.size.height,
            };
        } else {
            const boundingRect = document.getElementsByClassName('frame-container')[0].getBoundingClientRect();
            size = {
                width: boundingRect.width,
                height: boundingRect.height,
            };
        }
        this.drawMapViewers(size, this.mapViewers, outputCanvas => {
            const outputImage = outputCanvas.toDataURL();
            this.finishExport();
            this.bus.emit('EXPORT_IMAGE_LOAD_SUCCESS', { image: outputImage });
        });
    }

    finishExport() {
        this.bus.off('MAP_VIEWER', this.onMapViewer);
        this.bus.off('MAP_PREFERRED_SUMMARY_LEVEL_CHANGED', this.onMapPreferredSummaryLevelChanged);
        if (this.mapViewersToClrSummaryLevel) {
            this.mapViewersToClrSummaryLevel.forEach(mapViewer => {
                this.bus.emit('CLEAR_PREFERRED_SUMMARY_LEVEL_REQUEST', {
                    source: this,
                    mapInstanceId: mapViewer.id,
                });
            });
        }
        this.nrOfSummaryLevelRequests = undefined;
        this.mapViewers = undefined;
        this.nrOfMapViewers = undefined;
        this.mapViewersToClrSummaryLevel = undefined;
    }

    drawMapViewers(size, mapViewers, callback) {
        const outputCanvas = document.createElement('canvas');
        outputCanvas.width = size.width;
        outputCanvas.height = size.height;
        const outputContext = outputCanvas.getContext('2d');
        // Single map frame
        if (mapViewers.length === 1) {
            const map = mapViewers[0].dragonflyMap;
            // Send an event to hide the map while resizing
            this.bus.emit('DISPLAY_OVERLAY_REQUEST', { message: '', classNames: 'overlay--no-loader' });
            const originalWidth = map.getContainer().getBoundingClientRect().width;
            const originalHeight = map.getContainer().getBoundingClientRect().height;
            const currentBounds = map.getBounds();
            map.getContainer().style.width = `${size.width}px`;
            map.getContainer().style.height = `${size.height}px`;
            // callback when dragonfly finished rendering map
            const onRenderCallback = () => {
                map.off('rendered', onRenderCallback);
                outputCanvas.width = size.width;
                outputCanvas.height = size.height;
                outputContext.drawImage(map.getCanvas(), 0, 0, outputCanvas.width, outputCanvas.height);
                map.getContainer().style.width = `${originalWidth}px`;
                map.getContainer().style.height = `${originalHeight}px`;
                const finalRenderCallback = () => {
                    map.getContainer().style.width = 'auto';
                    map.getContainer().style.height = 'auto';
                    this.bus.emit('HIDE_OVERLAY_REQUEST');
                    // Screenshooting is done, call callback function
                    // Resize back to original size
                    callback(outputCanvas);
                    map.off('rendered', finalRenderCallback);
                };
                // Canvas manipulation done, map can be shown again
                map.on('rendered', finalRenderCallback);
                map.resize();
                map.fitBounds(currentBounds);
            };
            map.on('rendered', onRenderCallback);
            map.resize();
            map.fitBounds(currentBounds);
            // Multiple maps frame
        } else {
            const mapOne = mapViewers[0].dragonflyMap;
            const mapTwo = mapViewers[1].dragonflyMap;
            // Flags are needed because two map viewers are being
            // re-rendered. There is no guarantee in which order they will
            // finish rendering. So one waits for the other and vice versa.
            let mapOneFlag = false;
            let mapTwoFlag = false;
            // Send an event to hide the map while resizing
            this.bus.emit('DISPLAY_OVERLAY_REQUEST', { message: '', classNames: 'overlay--no-loader' });
            const originalWidthOne = mapOne.getContainer().getBoundingClientRect().width;
            const originalWidthTwo = mapTwo.getContainer().getBoundingClientRect().width;
            const originalHeight = mapOne.getContainer().getBoundingClientRect().height;
            mapOne.getContainer().style.height = `${size.height}px`;
            mapTwo.getContainer().style.height = `${size.height}px`;
            mapOne.getContainer().style.width = `${size.width / 2}px`;
            mapTwo.getContainer().style.width = `${size.width / 2}px`;
            const mapOneBounds = mapOne.getBounds();
            const mapTwoBounds = mapTwo.getBounds();
            // Wait for the elements to re-render
            const onRenderedCallback = () => {
                outputCanvas.width = size.width;
                outputCanvas.height = size.height;
                const tempCanvas = document.createElement('canvas');
                tempCanvas.width = size.width / 2;
                tempCanvas.height = size.height;
                const tempContext = tempCanvas.getContext('2d');
                tempContext.drawImage(mapOne.getCanvas(), 0, 0, tempCanvas.width, tempCanvas.height);
                outputContext.drawImage(tempCanvas, 0, 0);
                tempContext.drawImage(mapTwo.getCanvas(), 0, 0, tempCanvas.width, tempCanvas.height);
                outputContext.drawImage(tempCanvas, size.width / 2, 0);
                // Add separator line between maps
                outputContext.beginPath();
                outputContext.moveTo(size.width / 2, 0);
                outputContext.lineTo(size.width / 2, size.height);
                outputContext.stroke();
                // Screenshooting is done, call callback function
                mapOne.getContainer().style.width = `${originalWidthOne}px`;
                mapOne.getContainer().style.height = `${originalHeight}px`;
                mapTwo.getContainer().style.width = `${originalWidthTwo}px`;
                mapTwo.getContainer().style.height = `${originalHeight}px`;
                // Canvas manipulation done, map can be shown again
                mapOneFlag = false;
                mapTwoFlag = false;
                const mapOneFinalRenderedCallback = () => {
                    mapOneFlag = true;
                    mapOne.off('rendered', mapOneFinalRenderedCallback);
                    if (mapOneFlag && mapTwoFlag) {
                        this.bus.emit('HIDE_OVERLAY_REQUEST');
                        callback(outputCanvas);
                    }
                };
                const mapTwoFinalRenderedCallback = () => {
                    mapTwoFlag = true;
                    mapTwo.off('rendered', mapTwoFinalRenderedCallback);
                    if (mapOneFlag && mapTwoFlag) {
                        this.bus.emit('HIDE_OVERLAY_REQUEST');
                        callback(outputCanvas);
                    }
                };
                mapOne.on('rendered', mapOneFinalRenderedCallback);
                mapTwo.on('rendered', mapTwoFinalRenderedCallback);
                mapOne.resize();
                mapOne.fitBounds(mapOneBounds);
                mapTwo.resize();
                mapTwo.fitBounds(mapTwoBounds);
            };
            // Render callbacks
            const mapOneOnRenderedCallback = () => {
                mapOne.off('rendered', mapOneOnRenderedCallback);
                mapOneFlag = true;
                if (mapOneFlag && mapTwoFlag) {
                    onRenderedCallback();
                }
            };

            const mapTwoOnRenderedCallback = () => {
                mapTwo.off('rendered', mapTwoOnRenderedCallback);
                mapTwoFlag = true;
                if (mapOneFlag && mapTwoFlag) {
                    onRenderedCallback();
                }
            };
            mapOne.on('rendered', mapOneOnRenderedCallback);
            mapTwo.on('rendered', mapTwoOnRenderedCallback);
            mapOne.resize();
            mapOne.fitBounds(mapOneBounds);
            mapTwo.resize();
            mapTwo.fitBounds(mapTwoBounds);
        }
    }

    onDeactivate() {
        this.unbindGluBusEvents();
    }
}

export default ExportController;
