import React from 'react';
import PropTypes from 'prop-types';
import DataVariablesList from '../dataVariables/DataVariablesList';
import classnames from 'classnames';

class DataCategorySurvey extends React.Component {
    onToggleCollapsed = () => {
        if (this.props.onToggleCollapsed) {
            this.props.onToggleCollapsed(this.props.surveyName);
        }
    };

    renderTables() {
        const {
            collapsed,
            tables,
            selectedVariableRef,
            mapInstanceId,
            currentMetadataSelection,
            hasDataFilter,
        } = this.props;

        if (collapsed) return null;

        return tables.map(table => (
            <div key={table.id} className="flex-fixed flex-column flex-stretch">
                <div className="data-category-survey__table-title flex-it center flex-fixed">
                    {table.name}
                    <div className="data-category-survey__vertical-divider" />
                </div>
                <DataVariablesList
                    selectedVariableRef={selectedVariableRef}
                    variables={table.variables}
                    mapInstanceId={mapInstanceId}
                    hasDataFilter={hasDataFilter}
                    currentMetadataSelection={currentMetadataSelection}
                />
            </div>
        ));
    }

    render() {
        const { className, available, collapsed, surveyDisplayName } = this.props;

        const wrapperClasses = classnames('data-category-survey flex-it column no-shrink', className, {
            'data-category-survey--disabled': !available,
        });

        return (<div className={wrapperClasses}>
            <button
                className="data-category-survey__title flex-it center space-between"
                onClick={this.onToggleCollapsed}
                aria-expanded={!collapsed}
            >
                <span>{surveyDisplayName}</span>
                <i className="material-icons btn-flat-icon" aria-label={collapsed ? 'Down arrow icon' : 'Up arrow icon'}>
                    {collapsed ? 'arrow_drop_down' : 'arrow_drop_up'}
                </i>
            </button>
            <div className="data-category-survey__tables-list">
                {this.renderTables()}
            </div>
        </div>);
    }
}


DataCategorySurvey.propTypes = {
    className: PropTypes.string,
    available: PropTypes.bool,
    collapsed: PropTypes.bool,
    surveyDisplayName: PropTypes.string,
    surveyName: PropTypes.string,
    tables: PropTypes.array.isRequired,
    mapInstanceId: PropTypes.string.isRequired,
    hasDataFilter: PropTypes.bool,
    currentMetadataSelection: PropTypes.object.isRequired,
    onToggleCollapsed: PropTypes.func,
    selectedVariableRef: PropTypes.func,
};

DataCategorySurvey.defaultProps = {
    className: '',
    available: false,
    collapsed: false,
    surveyDisplayName: '',
    surveyName: undefined,
    onToggleCollapsed: undefined,
    selectedVariableRef: undefined,
    hasDataFilter: false,
};

export default DataCategorySurvey;
