// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import UserUploadDataGroup from './UserUploadDataGroup';

 /**
  * @param {object} param0
  * @param {import('../../../').UserUploadDataGroup[]} param0.userUploadDataGroups
  * @param {string} param0.mapInstanceId
  * @param {string|undefined} param0.className
 */
const UserUploadDataLegend = ({ userUploadDataGroups, mapInstanceId, className }) => {
    const groupItems = userUploadDataGroups
        .filter(layerGroup => layerGroup.includeInLegend && layerGroup.isPointVisualization)
        .map(layerGroup => (
            <UserUploadDataGroup
                key={layerGroup.id}
                layerGroup={layerGroup}
                mapInstanceId={mapInstanceId}
            />
        ));

    if (groupItems.length === 0) return null;

    const layerLibraryClasses = classNames(
        'layer-library-legend',
        className,
    );

    return <div className={layerLibraryClasses}>{groupItems}</div>;
};

UserUploadDataLegend.propTypes = {
    userUploadDataGroups: PropTypes.array.isRequired,
    mapInstanceId: PropTypes.string.isRequired,
    className: PropTypes.string,
};

UserUploadDataLegend.defaultProps = {
    className: undefined,
};

export default UserUploadDataLegend;
