import UserRole from '../enums/UserRole';

class UserInfo {
    get userId() {
        return this._userId;
    }

    set userId(userId) {
        this._userId = userId;
    }

    get geobufferToken() {
        return this._geobufferToken;
    }

    set geobufferToken(geobufferToken) {
        this._geobufferToken = geobufferToken;
    }

    get geobufferPermission() {
        return this._geobufferPermission;
    }

    set geobufferPermission(geobufferPermission) {
        this._geobufferPermission = geobufferPermission;
    }

    get name() {
        return this._name;
    }

    set name(name) {
        this._name = name;
    }

    get orgName() {
        return this._orgName;
    }

    set orgName(orgName) {
        this._orgName = orgName;
    }

    get product() {
        return this._product;
    }

    set product(product) {
        this._product = product;
    }

    get permanentCookie() {
        return this._permanentCookie;
    }

    set permanentCookie(permanentCookie) {
        this._permanentCookie = permanentCookie;
    }

    get edition() {
        return UserRole.PRODUCT_PRO; // tractiq hack
        // if (this.hasRole(UserRole.PRODUCT_PRO)) return UserRole.PRODUCT_PRO;
        // if (this.hasRole(UserRole.PRODUCT_STUDENT)) return UserRole.PRODUCT_STUDENT;
        // return UserRole.PRODUCT_FREE;
    }

    get isProUser() {
        return true; // this.hasRole(UserRole.PRODUCT_PRO);
    }

    get isLogged() {
        return this.userId !== -1;
    }

    get licenseInfo() {
        return this._licenseInfo;
    }

    set licenseInfo(licenseInfo) {
        this._licenseInfo = licenseInfo;
    }

    get planInfo() {
        return this._planInfo;
    }

    set planInfo(planInfo) {
        this._planInfo = planInfo;
    }

    /**
     * Get JSON representation of the userInfo
     * @returns {object} JSON representation of the userInfo
     */
    toJSON() {
        return {
            userId: this.userId,
            name: this.name,
            orgName: this.orgName,
            product: this.product,
            permanentCookie: this.permanentCookie,
            licenseInfo: this.licenseInfo,
            planInfo: this.planInfo,
        };
    }

    hasRole(role) {
        return this.product.some(productName => role.toLowerCase() === productName.toLowerCase());
    }

    hasAnyRole(roles) {
        // allow all to students for now
        const userInfo = this;
        if (userInfo.hasRole(UserRole.PRODUCT_STUDENT)) return true;
        // if it is PRO user and array of roler is empty return true
        if (userInfo.hasRole(UserRole.PRODUCT_PRO) && roles.length === 0) return true;
        return roles.some(roleName => userInfo.hasRole(roleName));
    }

}

export default UserInfo;
