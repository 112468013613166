'use strict';var VertexArrayObject = function VertexArrayObject() {
    this.boundProgram = null;
    this.boundVertexBuffer = null;
    this.boundVertexBuffer2 = null;
    this.boundIndexBuffer = null;
    this.boundVertexOffset = null;
    this.boundDynamicVertexBuffer = null;
    this.vao = null;
};
VertexArrayObject.prototype.bind = function bind(gl, program, layoutVertexBuffer, indexBuffer, vertexBuffer2, vertexOffset, dynamicVertexBuffer, dynamicVertexBuffer2) {
    if (gl.extVertexArrayObject === undefined) {
        gl.extVertexArrayObject = gl.getExtension('OES_vertex_array_object');
    }
    var isFreshBindRequired = !this.vao || this.boundProgram !== program || this.boundVertexBuffer !== layoutVertexBuffer || this.boundVertexBuffer2 !== vertexBuffer2 || this.boundIndexBuffer !== indexBuffer || this.boundVertexOffset !== vertexOffset || this.boundDynamicVertexBuffer !== dynamicVertexBuffer || this.boundDynamicVertexBuffer2 !== dynamicVertexBuffer2;
    if (!gl.extVertexArrayObject || isFreshBindRequired) {
        this.freshBind(gl, program, layoutVertexBuffer, indexBuffer, vertexBuffer2, vertexOffset, dynamicVertexBuffer, dynamicVertexBuffer2);
        this.gl = gl;
    } else {
        gl.extVertexArrayObject.bindVertexArrayOES(this.vao);
        if (dynamicVertexBuffer) {
            dynamicVertexBuffer.bind();
        }
        if (indexBuffer && indexBuffer.dynamicDraw) {
            indexBuffer.bind();
        }
        if (dynamicVertexBuffer2) {
            dynamicVertexBuffer2.bind();
        }
    }
};
VertexArrayObject.prototype.freshBind = function freshBind(gl, program, layoutVertexBuffer, indexBuffer, vertexBuffer2, vertexOffset, dynamicVertexBuffer, dynamicVertexBuffer2) {
    var numPrevAttributes;
    var numNextAttributes = program.numAttributes;
    if (gl.extVertexArrayObject) {
        if (this.vao) {
            this.destroy();
        }
        this.vao = gl.extVertexArrayObject.createVertexArrayOES();
        gl.extVertexArrayObject.bindVertexArrayOES(this.vao);
        numPrevAttributes = 0;
        this.boundProgram = program;
        this.boundVertexBuffer = layoutVertexBuffer;
        this.boundVertexBuffer2 = vertexBuffer2;
        this.boundIndexBuffer = indexBuffer;
        this.boundVertexOffset = vertexOffset;
        this.boundDynamicVertexBuffer = dynamicVertexBuffer;
        this.boundDynamicVertexBuffer2 = dynamicVertexBuffer2;
    } else {
        numPrevAttributes = gl.currentNumAttributes || 0;
        for (var i = numNextAttributes; i < numPrevAttributes; i++) {
            gl.disableVertexAttribArray(i);
        }
    }
    layoutVertexBuffer.enableAttributes(gl, program);
    if (vertexBuffer2) {
        vertexBuffer2.enableAttributes(gl, program);
    }
    if (dynamicVertexBuffer) {
        dynamicVertexBuffer.enableAttributes(gl, program);
    }
    if (dynamicVertexBuffer2) {
        dynamicVertexBuffer2.enableAttributes(gl, program);
    }
    layoutVertexBuffer.bind();
    layoutVertexBuffer.setVertexAttribPointers(gl, program, vertexOffset);
    if (vertexBuffer2) {
        vertexBuffer2.bind();
        vertexBuffer2.setVertexAttribPointers(gl, program, vertexOffset);
    }
    if (dynamicVertexBuffer) {
        dynamicVertexBuffer.bind();
        dynamicVertexBuffer.setVertexAttribPointers(gl, program, vertexOffset);
    }
    if (indexBuffer) {
        indexBuffer.bind();
    }
    if (dynamicVertexBuffer2) {
        dynamicVertexBuffer2.bind();
        dynamicVertexBuffer2.setVertexAttribPointers(gl, program, vertexOffset);
    }
    gl.currentNumAttributes = numNextAttributes;
};
VertexArrayObject.prototype.destroy = function destroy() {
    if (this.vao) {
        this.gl.extVertexArrayObject.deleteVertexArrayOES(this.vao);
        this.vao = null;
    }
};
module.exports = VertexArrayObject;