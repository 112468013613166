//      

const TileCoord = require('./tile_coord');
const DragonflyIntersectionTests = require('../data/dragonfly_intersection_tests'); //!

                                              
                                                   
                                                

exports.rendered = function(sourceCache             ,
                            styleLayers                        ,
                            queryGeometry                   ,
                            params                                                                                                 , //!
                            zoom        ,
                            bearing        ) {
    const tilesIn = sourceCache.tilesIn(queryGeometry);

    tilesIn.sort(sortTilesIn);

    const renderedFeatureLayers = [];
    for (const tileIn of tilesIn) {
        renderedFeatureLayers.push({
            wrappedTileID: tileIn.coord.wrapped().id,
            queryResults: tileIn.tile.queryRenderedFeatures(
                styleLayers,
                tileIn.queryGeometry,
                tileIn.scale,
                params,
                bearing,
                sourceCache.id,
                tileIn.tile)
        });
    }

    return mergeRenderedFeatureLayers(renderedFeatureLayers);
};

exports.source = function(sourceCache             , params     ) {
    const tiles = sourceCache.getRenderableIds().map((id) => {
        return sourceCache.getTileByID(id);
    });

    const result = [];

    const dataTiles = {};
    for (let i = 0; i < tiles.length; i++) {
        const tile = tiles[i];
        const dataID = new TileCoord(Math.min(tile.sourceMaxZoom, tile.coord.z), tile.coord.x, tile.coord.y, 0).id;
        if (!dataTiles[dataID]) {
            dataTiles[dataID] = true;
            tile.querySourceFeatures(result, params);
        }
    }

    return result;
};

function sortTilesIn(a, b) {
    const coordA = a.coord;
    const coordB = b.coord;
    return (coordA.z - coordB.z) || (coordA.y - coordB.y) || (coordA.w - coordB.w) || (coordA.x - coordB.x);
}

function mergeRenderedFeatureLayers(tiles) {
    // Merge results from all tiles, but if two tiles share the same
    // wrapped ID, don't duplicate features between the two tiles
    const result = {};
    const wrappedIDLayerMap = {};
    const partialFeatures = {}; //!
    for (const tile of tiles) {
        const queryResults = tile.queryResults;
        const wrappedID = tile.wrappedTileID;
        const wrappedIDLayers = wrappedIDLayerMap[wrappedID] = wrappedIDLayerMap[wrappedID] || {};
        for (const layerID in queryResults) {
            const featuresPerLayer = partialFeatures[layerID] = partialFeatures[layerID] || {}; //!
            const tileFeatures = queryResults[layerID];
            const wrappedIDFeatures = wrappedIDLayers[layerID] = wrappedIDLayers[layerID] || {};
            const resultFeatures = result[layerID] = result[layerID] || [];
            for (const tileFeature of tileFeatures) {
                const areaMetadata = tileFeature.feature.areaMetadata; //!
                if (areaMetadata && areaMetadata.shouldCheckForPartials) { //!
                    DragonflyIntersectionTests.mergePartialFeatures(tileFeature, featuresPerLayer, resultFeatures, wrappedID); //!
                } else if (areaMetadata && !areaMetadata.shouldCheckForPartials) { //!
                    resultFeatures.push(tileFeature.feature); //!
                } else { //!
                    if (!wrappedIDFeatures[tileFeature.featureIndex]) {
                        wrappedIDFeatures[tileFeature.featureIndex] = true;
                        resultFeatures.push(tileFeature.feature);
                    }
                } //!
            }
        }
    }
    return result;
}

