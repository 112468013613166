import BaseController from './BaseController';
import AppConfig from '../appConfig';

import ProjectDataSource from '../dataSources/ProjectDataSource';
import MetadataDataSource from '../dataSources/MetadataDataSource';
import UserInfoDataSource from '../dataSources/UserInfoDataSource';
import ColorPaletteDataSource from '../dataSources/ColorPaletteDataSource';
import ApplicationModeDataSource from '../dataSources/ApplicationModeDataSource';
import CustomMapSelectionDataSource from '../dataSources/CustomMapSelectionDataSource';
import UploadDataSource from '../dataSources/UploadDataSource';
import MapDataSource from '../dataSources/MapDataSource';

import FrameLockMode from '../enums/FrameLockMode';
import Errors from '../enums/Error';
import ApplicationMode from '../enums/ApplicationMode';

class ProjectController extends BaseController {
    static get name() {
        return 'ProjectController';
    }

    static getInstance(options) {
        return new ProjectController(options);
    }

    onActivate() {
        this.bindGluBusEvents({
            CURRENT_PROJECT_REQUEST: this.onCurrentProjectRequest,
            PROJECT_LOAD_REQUEST: this.onProjectLoadRequest,
            PROJECT_UPDATE_REQUEST: this.onProjectUpdateRequest,
            PROJECT_UPGRADE_REQUEST: this.onProjectUpgradeRequest,
            SAVE_AS_NEW_PROJECT_REQUEST: this.onSaveAsNewProjectRequest,
            EDIT_CURRENT_PROJECT_INFO_REQUEST: this.onEditCurrentProjectInfoRequest,
            USER_INFO_LOAD_SUCCESS: this.onUserInfoLoadSuccess,
            LOG_OUT_SUCCESS: this.onLogOutSuccess,
            CHANGE_PROJECT_FRAME_TYPE_REQUEST: this.onChangeProjectFrameTypeRequest,
            SWITCH_PROJECT_FRAME_MAP_INSTANCES_REQUEST: this.onSwitchProjectFrameMapInstancesRequest,
            TOGGLE_PROJECT_FRAME_LOCK_MODE_REQUEST: this.onToggleProjectFrameLockModeRequest,
            CURRENT_FRAME_REQUEST: this.onCurrentFrameRequest,
            UPDATE_CURRENT_FRAME_THUMBNAIL_REQUEST: this.onUpdateCurrentFrameThumbnailRequest,
            EDIT_CURRENT_PROJECT_REQUEST: this.onEditCurrentProjectRequest,
            PROJECT_UPDATE_VISIBILITY_REQUEST: this.onUpdateProjectVisibilityRequest,
        });

        this.userInfoDataSource = this.activateSource(UserInfoDataSource);
        this.metadataDataSource = this.activateSource(MetadataDataSource);
        this.projectDataSource = this.activateSource(ProjectDataSource);
        this.colorPaletteDataSource = this.activateSource(ColorPaletteDataSource);
        this.applicationModeDataSource = this.activateSource(ApplicationModeDataSource);
        this.mapDataSource = this.activateSource(MapDataSource);
        this.uploadDataSource = this.activateSource(UploadDataSource);
        this.customMapSelectionDataSource = this.activateSource(CustomMapSelectionDataSource);
    }

    onUpdateProjectVisibilityRequest = editProjectVisibilityInfoRequest => {
        const onError = error => {
            this.bus.emit('PROJECT_UPDATE_ERROR',
                {
                    editProjectVisibilityInfoRequest,
                    level: Errors.ERROR,
                    error,
                    originalError: error.message,
                    additionalInfo: 'Error while saving current project',
                });
        };

        this.projectDataSource.updateVisiblity(this.userInfoDataSource.currentUser.userId,
            editProjectVisibilityInfoRequest.isPublic)
            .then(() => {
                this.bus.emit('PROJECT_UPDATE_SUCCESS', {
                    project: this.projectDataSource.currentProject,
                });
            }).catch(onError);
    };

    onEditCurrentProjectRequest() {
        this.applicationModeDataSource.updateApplicationMode(ApplicationMode.EDIT);
        // reset current frame with new application mode
        this.projectDataSource.setCurrentFrame(this.projectDataSource.currentFrameIndex);
        this.bus.emit('APPLICATION_MODE_UPDATE_SUCCESS', this.applicationModeDataSource.currentApplicationMode);
        this.bus.emit('FRAME_UPDATED', {
            source: this,
            frame: this.projectDataSource.currentFrame,
            frameIndex: this.projectDataSource.currentFrameIndex,
        });
    }

    onUpdateCurrentFrameThumbnailRequest({ thumbnail, thumbnailLarge }) {
        this.projectDataSource.updateCurrentFrameThumbnail(thumbnail, thumbnailLarge);
        this.bus.emit('CURRENT_FRAME_THUMBNAIL_UPDATED', {
            source: this,
            frame: this.projectDataSource.currentFrame,
            frameIndex: this.projectDataSource.currentFrameIndex,
        });
    }

    onCurrentFrameRequest() {
        this.bus.emit('CURRENT_FRAME', this.projectDataSource.currentFrame);
    }

    onChangeProjectFrameTypeRequest(e) {
        this.projectDataSource.updateProjectFrameType(e.frameType, e.options);
        this.bus.emit('FRAME_UPDATED', {
            source: this,
            frame: this.projectDataSource.currentFrame,
            frameIndex: this.projectDataSource.currentFrameIndex,
        });
    }

    onSwitchProjectFrameMapInstancesRequest() {
        this.projectDataSource.switchFrameMapInstances();
        this.bus.emit('FRAME_MAPS_SWAPPED', { source: this, frame: this.projectDataSource.currentFrame });
        this.bus.emit('FRAME_UPDATED', {
            source: this,
            frame: this.projectDataSource.currentFrame,
            frameIndex: this.projectDataSource.currentFrameIndex,
        });
    }

    onToggleProjectFrameLockModeRequest() {
        this.projectDataSource.toggleFrameLockMode();
        const frame = this.projectDataSource.currentFrame;

        if (frame.lockMode === FrameLockMode.POSITION) {
            const center = [frame.mapInstances[0].initialView.centerLng, frame.mapInstances[0].initialView.centerLat];
            const zoom = frame.mapInstances[0].initialView.zoom;
            this.bus.emit('MAP_FLY_TO_REQUEST', {
                source: this,
                mapInstanceId: frame.mapInstances[1].id,
                center,
                zoom,
                lockMode: true,
            });
        }

        this.bus.emit('FRAME_UPDATED', {
            source: this,
            frame: this.projectDataSource.currentFrame,
            frameIndex: this.projectDataSource.currentFrameIndex,
        });
    }

    onUserInfoLoadSuccess() {
        // update user rights on current project
        if (this.projectDataSource.currentProject) {
            this.projectDataSource.updateCurrentProjectEditRights(this.userInfoDataSource.currentUser.userId)
                .then(project => {
                    this.bus.emit('CURRENT_PROJECT_EDIT_RIGHTS_UPDATED', { project });
                });
        }
    }

    onLogOutSuccess() {
        if (this.projectDataSource.currentProject) {
            this.projectDataSource.currentProject.canSave = false;
            this.bus.emit('CURRENT_PROJECT_EDIT_RIGHTS_UPDATED', { project: this.projectDataSource.currentProject });
        }
    }

    onCurrentProjectRequest(r) {
        this.bus.emit('CURRENT_PROJECT', { project: this.projectDataSource.currentProject, target: r.source });
    }

    _uploadImageToS3(fileName, mimeType, data) {
        return new Promise((resolve, reject) => {
            const fileType = mimeType.split('/')[1];
            const key = `projects/${this.projectDataSource.currentProject.assetsGuid}/${fileName}.${fileType}`;

            this.uploadDataSource.signS3({
                key,
            }).then(r1 => {
                const uploadParams = {
                    key,
                    signedUrl: r1.signedUrl,
                    data,
                };
                this.uploadDataSource.upload(uploadParams).then(resolve, reject);
            }).catch(reject);
        });
    }

    _uploadFrameThumbnails() {
        return new Promise((resolve, reject) => {
            const allFrames = this.projectDataSource.currentProject.frames;
            const canvas = document.createElement('canvas');
            canvas.width = 90;
            canvas.height = (56 * (allFrames.length + 1)) + 1; // caiman had a bug where the thumbnail image was 1 pixel higher than it should be... yep.
            const ctx = canvas.getContext('2d');
            Promise.all(allFrames.map((frame, index) =>
                new Promise(resolveImage => {
                    const image = new Image();
                    image.crossOrigin = 'anonymous';
                    image.onload = () => {
                        ctx.drawImage(image, 0, index * 56, 90, 56);
                        resolveImage();
                    };
                    image.src = frame.thumbnail;
                })
            )).then(() => {
                const titleFrame = this.projectDataSource.currentProject.titleFrame;
                if (titleFrame && titleFrame.media && titleFrame.media.length > 0 && titleFrame.media[0].type === 'image' && titleFrame.media[0].userDefinedFileName) {
                    const image = new Image();
                    image.crossOrigin = 'anonymous';
                    image.onload = () => {
                        ctx.drawImage(image, 0, this.projectDataSource.currentProject.frames.length * 56, 90, 56);
                        canvas.toBlob(blob => {
                            canvas.width = image.width;
                            canvas.height = image.height;
                            const titlePageFileName = titleFrame.media[0].userDefinedFileName;
                            const titlePageFileNameArray = titlePageFileName.split('.');
                            const extension = titlePageFileNameArray[titlePageFileNameArray.length - 1];
                            canvas.getContext('2d').drawImage(image, 0, 0);
                            canvas.toBlob(titleBlob => {
                                Promise.all([
                                    this._uploadImageToS3(titlePageFileNameArray.slice(0, titlePageFileNameArray.length - 1)
                                        .join('.'), `image/${extension}`, titleBlob),
                                    this._uploadImageToS3('thumbs', 'image/png', blob),
                                ]).then(() => {
                                    titleFrame.media[0].fileName = `${AppConfig.constants.links.amazonS3Bucket}/projects/${this.projectDataSource.currentProject.assetsGuid}/${encodeURIComponent(titlePageFileName)}?${(new Date()).getTime()}`;
                                    titleFrame.media[0].userDefinedFileName = titlePageFileName;
                                    resolve();
                                }, reject);
                            });
                        });
                    };
                    image.src = titleFrame.media[0].fileName;
                } else {
                    canvas.toBlob(blob => {
                        this._uploadImageToS3('thumbs', 'image/png', blob).then(resolve, reject);
                    });
                }
            }, reject);
        });
    }

    async onSaveAsNewProjectRequest(r) {
        const onError = error => {
            // dispatch error event for error handler
            this.bus.emit('SAVE_AS_NEW_PROJECT_REQUEST_ERROR', {
                level: Errors.ERROR,
                error,
                originalError: error.message,
                additionalInfo: 'Error while creating new project from current',
            });
            this.bus.emit('SAVE_PROJECT_ERROR_POPUP_REQUEST', { error });
            this.bus.emit('HIDE_OVERLAY_REQUEST');
        };
        this.bus.emit('DISPLAY_OVERLAY_REQUEST', { classNames: 'overlay--see-through' });
        await this._loadThumbnail();
        if (this._hasStateChanged()) {
            this._lastSystemState = this.projectDataSource.currentProject.clone();
            this._projectXML = this.projectDataSource.currentProject.toXML();
        }
        this._uploadFrameThumbnails().then(() => {
            this.projectDataSource.createNewProjectFromCurrent(r.title,
                r.description,
                this._projectXML,
                this._thumbnail,
                this.userInfoDataSource.currentUser.userId)
                .then(() => {
                    this.bus.emit('PROJECT_LOAD_SUCCESS', this.projectDataSource.currentProject, this.projectDataSource.currentFrame, this.projectDataSource.currentFrameIndex);
                    this.bus.emit('HIDE_OVERLAY_REQUEST');
                }).catch(onError);
        });
    }

    async onProjectLoadRequest(projectLoadRequest) {
        const onError = error => {
            this.bus.emit('PROJECT_LOAD_ERROR', {
                level: Errors.CRITICAL,
                originalRequest: projectLoadRequest,
                originalError: error.originalError,
                additionalInfo: error.additionalInfo,
            });
        };

        this.bus.emit('DISPLAY_OVERLAY_REQUEST', { key: 'loadingPleaseWait' });

        let project;
        const currentApplicationMode = this.applicationModeDataSource.currentApplicationMode;
        const isViewMode = currentApplicationMode === ApplicationMode.VIEW || currentApplicationMode === ApplicationMode.EMBED;

        if (projectLoadRequest.viewCode) {
            try {
                project = await this.projectDataSource.loadProjectByViewCode(
                    projectLoadRequest.viewCode,
                    projectLoadRequest.frameIndex,
                    isViewMode,
                );
            } catch (e) {
                return onError({ originalError: e, additionalInfo: 'Could not load project definition.' });
            }
        } else if (projectLoadRequest.projectURL) {
            try {
                project = await this.projectDataSource.loadProjectByURL(
                    projectLoadRequest.projectURL,
                    projectLoadRequest.frameIndex,
                    isViewMode,
                );
            } catch (e) {
                return onError({ originalError: e, additionalInfo: 'Could not load project definition.' });
            }
        } else {
            return onError({ originalError: null, additionalInfo: 'Invalid project load request.' });
        }

        // check if dingo can open project
        if (!this.projectDataSource.canDingoOpenProject(this.metadataDataSource.groupsMetadata)) {
            const applicationMode = this.applicationModeDataSource.currentApplicationMode;
            window.location.replace(`${AppConfig.constants.backends.website}/${project.viewCode}/${applicationMode}?unsupported=true`);
            return null;
        }

        // We need to also preload the contour location analysis layer data
        await this.customMapSelectionDataSource.preloadProjectContourFeatures(this.projectDataSource.currentProject);

        try {
            await this._loadProjectAssets(project, isViewMode);
            this.bus.emit('HIDE_OVERLAY_REQUEST');
        } catch (e) {
            return onError({ originalError: e, additionalInfo: 'Could not load project assets.' });
        }
        return null;
    }

    onEditCurrentProjectInfoRequest(editCurrentProjectInfoRequest) {
        const onError = error => {
            this.bus.emit('EDIT_CURRENT_PROJECT_INFO_ERROR',
                {
                    editCurrentProjectInfoRequest,
                    level: Errors.ERROR,
                    error,
                    originalError: error.message,
                    additionalInfo: 'Error while saving project info for current project',
                });
            this.bus.emit('SAVE_PROJECT_ERROR_POPUP_REQUEST', { error });
        };

        this.projectDataSource.editCurrentProjectInfo(editCurrentProjectInfoRequest.title,
            editCurrentProjectInfoRequest.description,
            this.userInfoDataSource.currentUser.userId)
            .then(project => {
                this.bus.emit('EDIT_CURRENT_PROJECT_INFO_SUCCESS', { project });
            }).catch(onError);
    }

    async onProjectUpgradeRequest(projectUpgradeRequest) {
        const onError = error => {
            this.bus.emit('PROJECT_UPGRADE_ERROR',
                {
                    projectUpgradeRequest,
                    level: Errors.ERROR,
                    error,
                    originalError: error.message,
                    additionalInfo: 'Error while upgrading current project',
                });
            this.bus.emit('SAVE_PROJECT_ERROR_POPUP_REQUEST', { error });
            this.bus.emit('HIDE_OVERLAY_REQUEST');
        };
        this.bus.emit('DISPLAY_OVERLAY_REQUEST', { classNames: 'overlay--see-through' });
        await this._loadThumbnail();

        this._uploadFrameThumbnails().then(() => {
            this.projectDataSource.upgradeProject(this._thumbnail,
                this.userInfoDataSource.currentUser.userId)
                .then(project => {
                    this._lastSystemState = this.projectDataSource.currentProject.clone();
                    this._projectXML = this.projectDataSource.currentProject.toXML();
                    this.bus.emit('PROJECT_UPGRADE_SUCCESS', { project, frame: this.projectDataSource.currentFrame });
                    this.bus.emit('HIDE_OVERLAY_REQUEST');
                }, onError);
        });
    }

    async onProjectUpdateRequest(projectUpdateRequest) {
        const onError = error => {
            this.bus.emit('PROJECT_UPDATE_ERROR',
                {
                    projectUpdateRequest,
                    level: Errors.ERROR,
                    error,
                    originalError: error.message,
                    additionalInfo: 'Error while saving current project',
                });
            this.bus.emit('SAVE_PROJECT_ERROR_POPUP_REQUEST', { error });
            this.bus.emit('HIDE_OVERLAY_REQUEST');
        };
        this.bus.emit('DISPLAY_OVERLAY_REQUEST', { classNames: 'overlay--see-through' });
        await this._loadThumbnail();

        if (this._hasStateChanged()) {
            this._lastSystemState = this.projectDataSource.currentProject.clone();
            this._projectXML = this.projectDataSource.currentProject.toXML();
        }

        this._uploadFrameThumbnails().then(() => {
            this.projectDataSource.updateProject(this._projectXML,
                this._thumbnail,
                this.userInfoDataSource.currentUser.userId)
                .then(project => {
                    this._lastSystemState = this.projectDataSource.currentProject.clone();
                    this._projectXML = this.projectDataSource.currentProject.toXML();
                    this.bus.emit('PROJECT_UPDATE_SUCCESS', { project, frame: this.projectDataSource.currentFrame });
                    this.bus.emit('HIDE_OVERLAY_REQUEST');
                }).catch(onError);
        });
    }

    async _loadProjectAssets(project, isViewMode) {
        const mapsIds = [];
        project.frames.forEach(frame => {
            frame.mapInstances.forEach(mapInstance => {
                if (mapsIds.indexOf(mapInstance.currentMapId) === -1) {
                    mapsIds.push(mapInstance.currentMapId);
                }
            });
        });

        const { libraryData, currentMetadata } = this.metadataDataSource;
        this.mapDataSource.libraryLayers = libraryData.layers.map(layer => layer.id);

        await Promise.all(mapsIds
            .map(id => `${AppConfig.constants.mapsURL}/${id}.json`)
            .map(async mapURL => {
                await this.mapDataSource.loadMapByURL(mapURL).then(async map => {
                    if (map.colorPalettes === undefined) {
                        await this.colorPaletteDataSource.updateMapColorPalettes(map);
                    }
                });
            }).concat([this.metadataDataSource.loadPartialMetadata(project.partialMetadataInfo)])
        );

        if (!ProjectController._validateMaps(this.mapDataSource.currentMaps, mapsIds)) {
            this.bus.emit('DISPLAY_OVERLAY_REQUEST', {
                message: 'Map(s) not found. Please select another (preferably valid) project.',
                closeModal: true,
            });
            return;
        }
        await this.colorPaletteDataSource.updateColorPaletteList(project, this.mapDataSource.currentMaps);
        this.projectDataSource.applyColorPalettesToProject(isViewMode, this.mapDataSource.currentMaps, this.colorPaletteDataSource.currentColorPalettes);
        currentMetadata.updatePermissions(this.userInfoDataSource.currentUser);

        window.DingoDebug.project = project;

        this.bus.emit('PROJECT_LOAD_SUCCESS', project, this.projectDataSource.currentFrame, this.projectDataSource.currentFrameIndex);
    }

    static _validateMaps(maps, mapsIds) {
        return mapsIds.every(mapId => maps[mapId] !== undefined);
    }

    _hasStateChanged() {
        if (this._lastSystemState === undefined) {
            return true;
        }
        const currentProject = this.projectDataSource.currentProject;
        return !currentProject.equals(this._lastSystemState);
    }

    _loadThumbnail() {
        return new Promise(resolve => {
            if (this._thumbnail === undefined || this._hasStateChanged()) {
                this.bus.once('THUMBNAIL_LOAD_SUCCESS', thumbnail => {
                    this._thumbnail = thumbnail;
                    resolve();
                });
                this.bus.emit('THUMBNAIL_LOAD_REQUEST', { source: this });
            } else {
                resolve();
            }
        });
    }

    onDeactivate() {
        this.unbindGluBusEvents();
    }
}

export default ProjectController;
