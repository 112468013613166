'use strict';var ref = require('../types');
var ColorType = ref.ColorType;
var ValueType = ref.ValueType;
var NumberType = ref.NumberType;
var ref$1 = require('../values');
var Color = ref$1.Color;
var validateRGBA = ref$1.validateRGBA;
var RuntimeError = require('../runtime_error');
var types = {
    'to-number': NumberType,
    'to-color': ColorType
};
var Coercion = function Coercion(type, args) {
    this.type = type;
    this.args = args;
};
Coercion.parse = function parse(args, context) {
    if (args.length < 2) {
        return context.error('Expected at least one argument.');
    }
    var name = args[0];
    var type = types[name];
    var parsed = [];
    for (var i = 1; i < args.length; i++) {
        var input = context.parse(args[i], i, ValueType);
        if (!input) {
            return null;
        }
        parsed.push(input);
    }
    return new Coercion(type, parsed);
};
Coercion.prototype.evaluate = function evaluate(ctx) {
    var this$1 = this;
    if (this.type.kind === 'color') {
        var input;
        var error;
        for (var i = 0, list = this$1.args; i < list.length; i += 1) {
            var arg = list[i];
            input = arg.evaluate(ctx);
            error = null;
            if (typeof input === 'string') {
                var c = ctx.parseColor(input);
                if (c) {
                    return c;
                }
            } else if (Array.isArray(input)) {
                if (input.length < 3 || input.length > 4) {
                    error = 'Invalid rbga value ' + JSON.stringify(input) + ': expected an array containing either three or four numeric values.';
                } else {
                    error = validateRGBA(input[0], input[1], input[2], input[3]);
                }
                if (!error) {
                    return new Color(input[0] / 255, input[1] / 255, input[2] / 255, input[3]);
                }
            }
        }
        throw new RuntimeError(error || 'Could not parse color from value \'' + (typeof input === 'string' ? input : JSON.stringify(input)) + '\'');
    } else {
        var value = null;
        for (var i$1 = 0, list$1 = this$1.args; i$1 < list$1.length; i$1 += 1) {
            var arg$1 = list$1[i$1];
            value = arg$1.evaluate(ctx);
            if (value === null) {
                continue;
            }
            var num = Number(value);
            if (isNaN(num)) {
                continue;
            }
            return num;
        }
        throw new RuntimeError('Could not convert ' + JSON.stringify(value) + ' to number.');
    }
};
Coercion.prototype.eachChild = function eachChild(fn) {
    this.args.forEach(fn);
};
module.exports = Coercion;