import React from 'react';
import classNames from 'classnames';
import BusComponent from '../../BusComponent';
import GeoSelectionListSection from './GeoSelectionListSection';
import NumberFormat from '../../../enums/NumberFormat';
import format from '../../../helpers/NumberFormatter';
import googleTagManagerEvents from '../../../helpers/GoogleTagManagerEventsWrapper';
import { downloadButtonEvents } from '../../../enums/GoogleTagManagerEvents';

import Tooltip from '../../Tooltip';
import MiniSearch from '../../miniSearch/MiniSearch';

const ELEMENTS_PER_PAGE = 5;

const DownloadButton = googleTagManagerEvents('button', downloadButtonEvents);

class GeoSelectionList extends BusComponent {
    constructor(props, context) {
        super(props, context);
        const summaryLevelsData = Object.keys(props.summaryLevelsGeos).map(sId => {
            const summaryLevel = props.summaryLevels.find(sl => sl.id === sId);
            return {
                summaryLevel,
                geos: props.summaryLevelsGeos[sId],
                filteredGeos: undefined,
                elementsCount: ELEMENTS_PER_PAGE,
            };
        });
        const selectedGeosCount = summaryLevelsData.reduce((sum, data) => sum + data.geos.length, 0);
        const selectedGeosCountLabel = `${format({
            number: selectedGeosCount,
            numberFormat: NumberFormat.FORMAT_NUMBER,
        })} ${selectedGeosCount > 1 ? 'geographies' : 'geography'} selected`;

        this.state = {
            elementsCount: ELEMENTS_PER_PAGE,
            filterText: '',
            searchExpanded: false,
            summaryLevelsGeos: props.summaryLevelsGeos,
            summaryLevels: props.summaryLevels,
            filteredFeatures: undefined,
            summaryLevelsData,
            selectedGeosCountLabel,
        };
    }

    componentWillReceiveProps(nextProps) {
        const summaryLevelsData = Object.keys(nextProps.summaryLevelsGeos).map(sId => {
            const currentSummaryLevelData = this.state.summaryLevelsData.find(sd => sd.summaryLevel.id === sId);
            const summaryLevel = nextProps.summaryLevels.find(sl => sl.id === sId);
            return {
                summaryLevel,
                geos: nextProps.summaryLevelsGeos[sId],
                filteredGeos: undefined,
                elementsCount: currentSummaryLevelData ? currentSummaryLevelData.elementsCount : ELEMENTS_PER_PAGE,
            };
        });
        const selectedGeosCount = summaryLevelsData.reduce((sum, data) => sum + data.geos.length, 0);
        const selectedGeosCountLabel = `${format({
            number: selectedGeosCount,
            numberFormat: NumberFormat.FORMAT_NUMBER,
        })} ${selectedGeosCount > 1 ? 'geographies' : 'geography'} selected`;
        this.setState({
            summaryLevelsGeos: nextProps.summaryLevelsGeos,
            summaryLevels: nextProps.summaryLevels,
            summaryLevelsData,
            filterText: '',
            selectedGeosCountLabel,
        });
    }

    onLoadMore = summaryLevel => {
        const summaryLevelData = this.state.summaryLevelsData.find(sd => sd.summaryLevel.id === summaryLevel.id);
        summaryLevelData.elementsCount += ELEMENTS_PER_PAGE;
        this.setState({
            summaryLevelsData: this.state.summaryLevelsData,
        });
    }

    onFilterTextChange = value => {
        const summaryLevelsData = this.state.summaryLevelsData;
        summaryLevelsData.forEach(summaryLevelData => {
            let filteredGeos;
            if (value && value.length > 0) {
                const filterText = value.toLowerCase().trim();
                const filterWords = filterText.split(' ');
                filteredGeos = [];
                for (let i = 0; i < summaryLevelData.geos.length; i += 1) {
                    const geo = summaryLevelData.geos[i];
                    if (filterWords.find(word => geo.title.toLowerCase().indexOf(word) > -1)) {
                        filteredGeos.push(geo);
                    }
                }
            }
            summaryLevelData.filteredGeos = filteredGeos;
        });
        this.setState({
            filterText: value,
            summaryLevelsData,
            elementsCount: ELEMENTS_PER_PAGE,
        });
    }

    onDownloadSelectedGeoList = () => {
        this.emit('CREATE_AND_DOWNLOAD_GEO_LIST_CSV_REQUEST', {
            summaryLevelsData: this.state.summaryLevelsData,
            surveyName: this.props.surveyName,
        });
        this.emit('SET_ACCESSIBLE_ALERT_TEXT', 'Download started');
    }

    onRemoveGeo = (geo, summaryLevel) => {
        if (this.props.onRemoveGeo) {
            this.props.onRemoveGeo(geo, summaryLevel);
        }
    }

    onSearchIconClicked = () => {
        this.setState({
            searchExpanded: !this.state.searchExpanded,
        });
    }

    renderGeoSections() {
        const geoSections = [];
        this.state.summaryLevelsData.forEach(summaryLevelData => {
            let geos = summaryLevelData.filteredGeos || summaryLevelData.geos;
            const hasMore = geos.length > summaryLevelData.elementsCount;
            geos = geos.slice(0, summaryLevelData.elementsCount);
            if (geos.length > 0) {
                const geoSection = (<GeoSelectionListSection
                    key={summaryLevelData.summaryLevel.id}
                    summaryLevel={summaryLevelData.summaryLevel}
                    geos={geos}
                    hasMore={hasMore}
                    onRemoveGeo={this.onRemoveGeo}
                    onLoadMore={this.onLoadMore}
                />);
                // show active summary level section first
                if (this.props.activeSummaryLevel && this.props.activeSummaryLevel.id === summaryLevelData.summaryLevel.id) {
                    geoSections.unshift(geoSection);
                } else {
                    geoSections.push(geoSection);
                }
            }
        });
        return geoSections;
    }

    render() {
        return (<div className={classNames('selected-geos-list flex-it column', this.props.className)}>
            <div className="selected-geos-list__header no-shrink flex-it center space-between">
                <div className="selected-geos-list__info flex-it center">
                    <h5>{this.state.selectedGeosCountLabel}</h5>
                </div>
                <MiniSearch
                    onChange={this.onFilterTextChange}
                    value={this.state.filterText}
                    placeholder="Search for geographies"
                    searchExpanded={this.state.searchExpanded}
                    expandedWidth={422}
                    onSearchIconClicked={this.onSearchIconClicked}
                />
                <div className="mask-summary-selector__divider" />
                <Tooltip
                    placement="bottom"
                    mouseEnterDelay={0.2}
                    mouseLeaveDelay={0}
                    overlay={'Download geography list'}
                >
                    <DownloadButton
                        className="selected-geos-list__download material-icons btn-flat-icon"
                        onClick={this.onDownloadSelectedGeoList}
                    >file_download
                    </DownloadButton>
                </Tooltip>
            </div>
            <div className="selected-geos-list__sections flex-it column vertical-scrollbar">
                {this.renderGeoSections()}
            </div>
        </div>);
    }
}

export default GeoSelectionList;
