// @ts-check
import React from 'react';
import { FormattedMessage } from 'react-intl';

const ReportInfoOverlay = () => (
    <div className="report-options-info-overlay">
        <i className="material-icons report-options-info-overlay__icon">info</i>
        <span className="report-options-info-overlay__message">
            <FormattedMessage
                id={'dataBrowser.yourReportWillBeDisplayedInNewWindow'}
                values={{
                    br: <br />,
                }}
            />
        </span>
    </div>
);

export default ReportInfoOverlay;
