import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import Keys from '../../enums/Key';
import SearchResultListItem from './SearchResultListItem';

class SearchResultList extends React.Component {
    constructor(props, context) {
        super(props, context);

        const { selectedItem, items } = this.props;

        this.state = {
            selectedItemIndex: this.getSelectedItemIndex(selectedItem, items),
        };
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleDocumentKeyDown, { capture: true });
    }

    componentWillReceiveProps({ items }) {
        if (!items || !items.length) {
            this.setState({ selectedItemIndex: -1 });
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleDocumentKeyDown, { capture: true });
    }

    getSelectedItemIndex(item, items) {
        if (!items || !items.length) return -1;

        if (item) {
            return items.findIndex(i => i.id === item.id);
        }

        return -1;
    }

    handleDocumentKeyDown = e => {
        let { selectedItemIndex } = this.state;
        const { items } = this.props;
        if (!items || !items.length) return;
        let doSelectItem = true;

        const key = e.which || e.keyCode;
        switch (key) {
        case Keys.UP:
            e.preventDefault();
            selectedItemIndex = Math.max(0, selectedItemIndex - 1);
            doSelectItem = false;
            break;
        case Keys.DOWN:
            e.preventDefault();
            selectedItemIndex = Math.min(items.length - 1, selectedItemIndex + 1);
            doSelectItem = false;
            break;
        case Keys.ENTER:
            doSelectItem = true;
            break;
        default:
            return;
        }

        if (items[selectedItemIndex]) {
            this.setState({ selectedItemIndex }, () => {
                this.props.onSelect(items[selectedItemIndex], doSelectItem);
            });
        }
    };

    handleItemSelect = item => {
        const selectedItemIndex = this.props.items.findIndex(i => i.id === item.id);
        this.setState({ selectedItemIndex }, () => {
            this.props.onSelect(item);
        });
    };

    render() {
        const { selectedItemIndex } = this.state;
        const { items, searchTerm, errorMessageId, intl } = this.props;

        if (!items) return null;
        let content;

        if (errorMessageId) {
            content = (
                <div className="search-results__search-error">{intl.formatMessage({ id: errorMessageId })}</div>
            );
        } else if (items.length === 0) {
            content = (
                <div className="search-results__nothing-to-show">{intl.formatMessage({ id: 'noResultsFound' })}</div>
            );
        } else {
            content = (
                <ul className="search-results__list">
                    {
                        items.map((item, elementIdx) => (
                            <SearchResultListItem
                                key={item.id}
                                item={item}
                                active={elementIdx === selectedItemIndex}
                                onSelect={this.handleItemSelect}
                                searchTerm={searchTerm}
                            />
                        ))
                    }
                </ul>
            );
        }

        return (
            <div className="search-results">
                {content}
            </div>
        );
    }
}

SearchResultList.propTypes = {
    onSelect: PropTypes.func.isRequired,
    items: PropTypes.array,
    selectedItem: PropTypes.object,
    searchTerm: PropTypes.string,
    errorMessageId: PropTypes.string,
    intl: PropTypes.object.isRequired,
};

SearchResultList.defaultProps = {
    items: undefined,
    selectedItem: undefined,
    searchTerm: undefined,
    errorMessageId: undefined,
};

export default injectIntl(SearchResultList);
