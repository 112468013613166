'use strict';var StyleLayer = require('../style_layer');
var SymbolBucket = require('../../data/bucket/symbol_bucket');
var resolveTokens = require('../../util/token');
var ref = require('../../style-spec/expression');
var isExpression = ref.isExpression;
var SymbolStyleLayer = function (StyleLayer) {
    function SymbolStyleLayer() {
        StyleLayer.apply(this, arguments);
    }
    if (StyleLayer)
        SymbolStyleLayer.__proto__ = StyleLayer;
    SymbolStyleLayer.prototype = Object.create(StyleLayer && StyleLayer.prototype);
    SymbolStyleLayer.prototype.constructor = SymbolStyleLayer;
    SymbolStyleLayer.prototype.getLayoutValue = function getLayoutValue(name, globals, feature) {
        var value = StyleLayer.prototype.getLayoutValue.call(this, name, globals, feature);
        if (value !== 'auto') {
            return value;
        }
        switch (name) {
        case 'text-rotation-alignment':
        case 'icon-rotation-alignment':
            return this.getLayoutValue('symbol-placement', globals, feature) === 'line' ? 'map' : 'viewport';
        case 'text-pitch-alignment':
            return this.getLayoutValue('text-rotation-alignment', globals, feature);
        case 'icon-pitch-alignment':
            return this.getLayoutValue('icon-rotation-alignment', globals, feature);
        default:
            return value;
        }
    };
    SymbolStyleLayer.prototype.getLayoutDeclaration = function getLayoutDeclaration(name) {
        return this._layoutDeclarations[name];
    };
    SymbolStyleLayer.prototype.isLayoutValueFeatureConstant = function isLayoutValueFeatureConstant(name) {
        var declaration = this._layoutDeclarations[name];
        return !declaration || declaration.isFeatureConstant();
    };
    SymbolStyleLayer.prototype.isLayoutValueZoomConstant = function isLayoutValueZoomConstant(name) {
        var declaration = this._layoutDeclarations[name];
        return !declaration || declaration.isZoomConstant();
    };
    SymbolStyleLayer.prototype.getValueAndResolveTokens = function getValueAndResolveTokens(name, globals, feature) {
        var value = this.getLayoutValue(name, globals, feature);
        var declaration = this._layoutDeclarations[name];
        if (this.isLayoutValueFeatureConstant(name) && !isExpression(declaration.value)) {
            return resolveTokens(feature.properties, value);
        }
        return value;
    };
    SymbolStyleLayer.prototype.createBucket = function createBucket(parameters) {
        return new SymbolBucket(parameters);
    };
    SymbolStyleLayer.prototype.isOpacityZero = function isOpacityZero(zoom, property) {
        return this.isPaintValueFeatureConstant(property) && this.getPaintValue(property, { zoom: zoom }) === 0;
    };
    SymbolStyleLayer.prototype.queryRadius = function queryRadius() {
        return 0;
    };
    SymbolStyleLayer.prototype.queryIntersectsFeature = function queryIntersectsFeature() {
        return false;
    };
    return SymbolStyleLayer;
}(StyleLayer);
module.exports = SymbolStyleLayer;