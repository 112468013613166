import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ColorPicker from '../../../colorPickerCompact/ColorPicker';
import Checkbox from '../../../form/Checkbox';

const ColorPropertyEditor = props => {
    const { enabled, color, label, withBorder, onChangeAvailability, onColorChanged } = props;

    const classes = classNames('annotation-property', {
        'annotation-property--with-margin': enabled === undefined,
    });

    return (
        <div className={classes}>
            {enabled === undefined &&
                <div className="body-1 annotation-property__label">{label}</div>
            }
            {enabled !== undefined &&
                <Checkbox
                    label={label}
                    checked={enabled}
                    onCheck={onChangeAvailability}
                    className="body-1"
                />
            }
            {(enabled === undefined || enabled) &&
                <div className="annotation-property__value">
                    <ColorPicker
                        onChange={onColorChanged}
                        color={color}
                        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                        targetOrigin={{ horizontal: 'left', vertical: 'top' }}
                        withBorder={withBorder}
                    />
                </div>
            }
        </div>
    );
};

ColorPropertyEditor.propTypes = {
    enabled: PropTypes.bool,
    color: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    withBorder: PropTypes.bool,
    onChangeAvailability: PropTypes.func,
    onColorChanged: PropTypes.func.isRequired,
};

ColorPropertyEditor.defaultProps = {
    enabled: undefined,
    withBorder: false,
    onChangeAvailability: undefined,
};

export default ColorPropertyEditor;
