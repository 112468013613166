import React from 'react';
import { injectIntl } from 'react-intl';
import BusComponent from '../BusComponent';

class EditProjectButton extends BusComponent {
    onClick = () => {
        this.emit('EDIT_CURRENT_PROJECT_REQUEST');
    };

    render() {
        return (
            <button
                type="button"
                tabIndex="0"
                key="edit-project-button"
                title={this.props.intl.formatMessage({ id: 'header.editProject' })}
                className="btn-raised application-header__button"
                onClick={this.onClick}
            >
                {this.props.intl.formatMessage({ id: 'header.editProject' })}
            </button>
        );
    }
}

export default injectIntl(EditProjectButton);
