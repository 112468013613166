import React from 'react';
import PropTypes from 'prop-types';

import MenuItem from 'material-ui/MenuItem';
import DropDownMenu from 'material-ui/DropDownMenu';

import PopoverAnimationUpwards from '../../../dropdown/PopoverAnimationUpwards';
import { DropdownStyle, LabelStyle, IconStyle, UnderlineStyle } from './MapExportFrameControlsStyles';

const DPI_OPTIONS = [
    <MenuItem
        key={96}
        value={96}
        label="96"
        primaryText="96 (Normal)"
    />,
    <MenuItem
        key={192}
        value={192}
        label="192"
        primaryText="192 (Retina)"
    />,
    <MenuItem
        key={288}
        value={288}
        label="288"
        primaryText="288 (Print)"
    />,
];

// If users screen has specific DPI settings include it as option for dropdown menu
const currentScreenDPI = Math.round(window.devicePixelRatio * 96);
const currentScreenDPIOption = DPI_OPTIONS.find(mi => mi.props.value === currentScreenDPI);

if (currentScreenDPIOption === undefined) {
    DPI_OPTIONS.push(<MenuItem
        key={currentScreenDPI}
        value={currentScreenDPI}
        label={`${currentScreenDPI}`}
        primaryText={`${currentScreenDPI} (Your screen)`}
    />);

    DPI_OPTIONS.sort((a, b) => {
        if (a.props.value < b.props.value) {
            return -1;
        } else if (a.props.value > b.props.value) {
            return 1;
        }

        return 0;
    });
}

const MapExportFrameDPIControl = props => {
    const { targetDPI, onChangeDPI } = props;

    return (
        <div className="map-export-frame__export-strip__controls__target-dpi">
            <div>DPI</div>
            <div>
                <DropDownMenu
                    className="dropdown-menu-trigger"
                    animation={PopoverAnimationUpwards}
                    value={targetDPI}
                    style={DropdownStyle}
                    labelStyle={LabelStyle}
                    iconStyle={IconStyle}
                    underlineStyle={UnderlineStyle}
                    onChange={onChangeDPI}
                >
                    {DPI_OPTIONS}
                </DropDownMenu>
            </div>
        </div>
    );
};

MapExportFrameDPIControl.propTypes = {
    targetDPI: PropTypes.number.isRequired,
    onChangeDPI: PropTypes.func.isRequired,
};

export default MapExportFrameDPIControl;
