import DragonflySourceLayer from './DragonflySourceLayer';

class DragonflySource {
    constructor(tiles) {
        this._layers = {};
        this._tiles = tiles || [];
    }

    /** @type {string} */
    get id() {
        return this._id;
    }

    set id(id) {
        this._id = id;
    }

    get layers() {
        return this._layers;
    }

    set layers(layers) {
        this._layers = layers;
    }

    get tiles() {
        return this._tiles;
    }

    getLayer(id) {
        if (this._layers[id] === undefined) {
            this._layers[id] = new DragonflySourceLayer(id);
        }
        return this._layers[id];
    }

    createLayer(id) {
        return this.getLayer(id);
    }

    pushTileSource(tileSource) {
        if (this.tiles.indexOf(tileSource) === -1) {
            this.tiles.push(tileSource);
        }
    }

    merge(other) {
        other.tiles.forEach(tileSource => {
            if (this._tiles.indexOf(tileSource) === -1) {
                this._tiles.push(tileSource);
            }
        });

        Object.keys(other.layers).forEach(layerId => {
            if (this._layers[layerId] === undefined) {
                this._layers[layerId] = other.layers[layerId];
            } else {
                this._layers[layerId].merge(other.layers[layerId]);
            }
        });

        return this;
    }

    remove(other, shouldRemoveTiles = false) {
        if (shouldRemoveTiles) {
            other.tiles.forEach(ot => {
                const tIdx = this._tiles.indexOf(ot);
                if (tIdx !== -1) {
                    this._tiles.splice(tIdx, 1);
                }
            });
        }

        Object.keys(other.layers).forEach(layerId => {
            if (this._layers[layerId] !== undefined) {
                this._layers[layerId].remove(other.layers[layerId]);
            }

            const datasets = Object.keys(this._layers[layerId].datasets).map(datasetId => this._layers[layerId].datasets[datasetId]);
            if (datasets.length === 0) {
                delete this._layers[layerId];
            }
        });

        return this;
    }

    clone() {
        const newDragonflySource = new DragonflySource(this._tiles);
        newDragonflySource.id = this._id;
        newDragonflySource.layers = Object.keys(this._layers).reduce((layers, v) => {
            layers[v] = this._layers[v].clone();
            return layers;
        }, {});
        return newDragonflySource;
    }

    equals(that) {
        return (
            this.id === that.id &&
            this.tiles.length === that.tiles.length &&
            this.tiles.every((t, i) => t === that.tiles[i]) &&
            Object.keys(this._layers).length === Object.keys(that.layers).length &&
            Object.keys(this._layers).every(layerId => that._layers[layerId] && this._layers[layerId].equals(that.layers[layerId])));
    }

    toJSON() {
        return {
            type: 'vector',
            tiles: this._tiles,
            layers: Object.keys(this._layers).map(layerId => this._layers[layerId].toJSON()) || [],
        };
    }
}

export default DragonflySource;
