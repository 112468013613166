const markerSvgPaths = {
    marker_airfield: 'M6.588,1.882c-0.522,0-0.941,0.419-0.941,0.941c0,0.522,0.419,0.941,0.941,0.941h7.529l-0.941,5.647v1.882L0,15.059v3.765h13.176v7.529l-4.706,1.882v1.882h15.059v-1.882l-4.706-1.882v-3.704v-3.825H32v-3.765l-13.176-3.765V9.412l-0.941-5.647h7.529c0.522,0,0.941-0.419,0.941-0.941c0-0.522-0.419-0.941-0.941-0.941H6.588',
    marker_airport: 'M32,19.765v0.941l-13.176-1.882v7.529l3.765,3.765c0,0.941-0.941,1.882-1.882,1.882c-3.765,0-9.412,0-9.412,0c-0.941,0-1.882-0.941-1.882-1.882l3.765-3.765v-7.529L0,20.706v-0.941c0-0.941,0.577-1.202,0.941-1.412l12.235-7.059c0,0,0-0.626,0-3.765C13.176,5.647,14.118,0,16,0s2.824,5.647,2.824,7.529c0,3.138,0,3.765,0,3.765l12.235,7.059C31.581,18.654,32,18.807,32,19.765',
    marker_america_football: 'M16,5.053C5.053,5.053,0,16,0,16s5.053,10.947,16,10.947S32,16,32,16S26.947,5.053,16,5.053z M10.947,13.474c0.467,0,0.842,0.375,0.842,0.842v0.842h3.368v-0.842c0-0.467,0.375-0.842,0.842-0.842s0.842,0.375,0.842,0.842v0.842h3.368v-0.842c0-0.467,0.375-0.842,0.842-0.842c0.467,0,0.842,0.375,0.842,0.842v0.842h2.526c0.467,0,0.842,0.375,0.842,0.842c0,0.467-0.375,0.842-0.842,0.842h-2.526v0.842c0,0.467-0.375,0.842-0.842,0.842c-0.467,0-0.842-0.375-0.842-0.842v-0.842h-3.368v0.842c0,0.467-0.375,0.842-0.842,0.842s-0.842-0.375-0.842-0.842v-0.842H11.79v0.842c0,0.467-0.375,0.842-0.842,0.842c-0.467,0-0.842-0.375-0.842-0.842v-0.842H7.579c-0.467,0-0.842-0.375-0.842-0.842c0-0.467,0.375-0.842,0.842-0.842h2.526v-0.842C10.105,13.849,10.48,13.474,10.947,13.474',
    marker_art_gallery: 'M13.474,3.368C5.053,3.368,0,10.105,0,13.474s1.684,5.895,5.053,5.895c3.368,0,5.053,0.843,5.053,3.369c0,3.368,2.526,5.895,5.895,5.895c10.947,0,16-5.895,16-11.79C32,6.737,23.579,3.368,13.474,3.368z M11.368,6.737c1.163,0,2.105,0.943,2.105,2.104s-0.942,2.105-2.105,2.105c-1.163,0-2.105-0.944-2.105-2.105S10.206,6.737,11.368,6.737z M18.105,6.737c1.163,0,2.105,0.943,2.105,2.104s-0.942,2.105-2.105,2.105c-1.163,0-2.105-0.944-2.105-2.105S16.942,6.737,18.105,6.737z M24.842,10.105c1.163,0,2.105,0.944,2.105,2.105s-0.942,2.104-2.105,2.104c-1.163,0-2.105-0.943-2.105-2.104S23.679,10.105,24.842,10.105z M5.474,11.789c1.163,0,2.105,0.944,2.105,2.105S6.637,16,5.474,16c-1.163,0-2.105-0.944-2.105-2.105S4.311,11.789,5.474,11.789z M16,20.211c1.395,0,2.526,1.131,2.526,2.526c0,1.395-1.132,2.525-2.526,2.525c-1.396,0-2.526-1.131-2.526-2.525C13.474,21.342,14.604,20.211,16,20.211',
    marker_bakery: 'M15.208,8.001c-2.399,0-4.421,0.858-3.899,3.2l2.299,10.398c0.082,0.369,0.303,0.8,0.8,0.8h1.6h1.6c0.499,0,0.719-0.431,0.8-0.8l2.3-10.398c0.52-2.342-1.501-3.2-3.901-3.2h-0.8H15.208z M6.409,12.801c-1.6,0-3.122,0.959-2.399,2.399l3.199,6.399c0.267,0.534,0.8,0.8,1.6,0.8h1.6c0,0,0.984,0.028,0.8-0.8l-1.6-7.199c-0.18-0.805-0.8-1.6-1.6-1.6H6.409z M24.006,12.801c-0.8,0-1.422,0.795-1.6,1.6l-1.6,7.199c-0.184,0.828,0.8,0.8,0.8,0.8h1.6c0.8,0,1.334-0.266,1.6-0.8l3.2-6.399c0.722-1.44-0.8-2.399-2.4-2.399H24.006z M2.31,17.8c-0.31-0.001-0.57,0.189-0.7,0.6l-1.533,4.79c-0.19,0.6-0.061,0.906,0.75,0.75l3.982-0.741c0.786-0.15,0.916-0.425,0.7-0.8L3.208,18.4C2.977,17.997,2.619,17.801,2.31,17.8z M29.706,17.8c-0.31,0.001-0.667,0.197-0.9,0.6l-2.3,3.999c-0.215,0.375-0.086,0.65,0.7,0.8l3.924,0.759c0.811,0.156,0.99-0.151,0.8-0.75L30.405,18.4C30.274,17.989,30.015,17.798,29.706,17.8',
    marker_bank: 'M1.684,5.895C0.751,5.895,0,6.645,0,7.579v16.842c0,0.935,0.751,1.685,1.684,1.685h28.632c0.934,0,1.684-0.75,1.684-1.685V7.579c0-0.935-0.75-1.685-1.684-1.685H1.684z M0.842,9.263h30.315v3.369H0.842V9.263z M1.684,19.368h5.053v3.368H1.684V19.368z M16.842,21.053h11.789v1.684H16.842V21.053',
    marker_bar: 'M1.882,0C0.941,0,0,0,0,0.471S0.645,1.5,0.941,1.883l14.117,14.705v11.647l-7.529,1.882C6.588,30.43,5.647,31.059,5.647,32h20.706c0-0.941-0.941-1.57-1.882-1.883l-7.529-1.882V16.588L31.059,1.883C31.355,1.5,32,0.941,32,0.471S31.059,0,30.118,0H1.882z',
    marker_baseball: 'M3.765,0C1.408,0,0,1.417,0,3.765c0,1.883,3.765,4.706,5.647,6.588c3.765,3.765,9.98,7.156,16,13.177l5.647,5.647l-0.941,0.94c0,0.941,0.941,1.883,1.882,1.883l1.882-1.883L32,28.235c0-0.941-0.941-1.883-1.882-1.883l-0.941,0.941l-5.647-5.646c-5.39-5.39-9.412-12.235-13.176-16C8.471,3.765,5.647,0,3.765,0z M10.353,18.823c-1.561,0-2.824,1.265-2.824,2.824c0,1.559,1.263,2.823,2.824,2.823s2.823-1.265,2.823-2.823C13.176,20.088,11.914,18.823,10.353,18.823',
    marker_basketball: 'M8.471,0L0,7.529v15.059c0,0.941,0.941,1.883,1.882,1.883h6.706l3.647,6.588C12.759,32,13.176,32,14.118,32h3.765c0.941,0,1.36,0,1.882-0.941l3.647-6.588h6.706c0.941,0,1.882-0.941,1.882-1.883V7.529L23.529,0H8.471z M7.529,7.529h16.941v11.294H7.529V7.529z M9.412,9.412v7.529h13.177V9.412H9.412z M10.471,24.471h2.706v1.882h1.882v-1.882h1.883v1.882h1.882v-1.882h2.706l-1.059,1.882h-1.647v1.883h0.647l-0.647,1.177v-1.177h-1.882v1.882h-1.883v-1.882h-1.882v1.177l-0.647-1.177h0.647v-1.883h-1.647L10.471,24.471z M15.059,28.235h1.883v-1.883h-1.883V28.235',
    marker_beer: 'M1.882,0l3.765,30.117C5.765,31.059,6.588,32,7.529,32h16.941c0.941,0,1.765-0.941,1.882-1.883L30.118,0H1.882z M6.118,3.765h19.765L25,11.294H6.998L6.118,3.765',
    marker_bicycle: 'M20,3.372c-0.945,0.131-1.072,1.783-0.158,2.052l3.737,1.474v3.21H11.791V8.424h2.315c0.556,0,1.053-0.497,1.053-1.053c0-0.556-0.497-1.061-1.053-1.052H7.58c-0.104-0.017-0.21-0.017-0.317,0C6.713,6.401,6.288,6.98,6.37,7.529c0.083,0.551,0.66,0.977,1.21,0.895h2.526v1.684l-1.791,5.263c-0.505-0.122-1.036-0.211-1.579-0.211C3.016,15.16,0,18.177,0,21.897s3.016,6.736,6.736,6.736s6.737-3.016,6.737-6.736c0-0.507-0.105-1-0.21-1.474h1.896c0.335-0.017,0.658-0.194,0.842-0.474l5.684-8.158h0.211h1.684l1.159,3.421c-3.475,0.27-6.212,3.142-6.212,6.684c0,3.72,3.017,6.736,6.737,6.736S32,25.617,32,21.897c0-3.079-2.071-5.617-4.895-6.422l-1.842-5.367V6.161c0-0.177-0.073-0.357-0.158-0.528c-0.006-0.017,0.007-0.041,0-0.053C25.081,5.531,25.033,5.475,25,5.424c-0.051-0.076-0.092-0.145-0.158-0.21c-0.028-0.024-0.074-0.026-0.104-0.053c-0.031-0.024-0.072-0.029-0.107-0.049c-0.054-0.033-0.097-0.085-0.158-0.105l-0.053-0.054l-0.735-0.26L20.58,3.429c-0.108-0.044-0.25-0.049-0.369-0.054c-0.079,0-0.131-0.018-0.21,0V3.372z M11.791,11.792h7.42l-4.58,6.526h-2.21c-0.521-0.832-1.178-1.518-2-2.052L11.791,11.792z M6.738,17.687c2.325,0,4.21,1.885,4.21,4.21c0,2.325-1.885,4.21-4.21,4.21c-2.326,0-4.21-1.885-4.21-4.21C2.528,19.571,4.413,17.687,6.738,17.687z M25.264,17.687c2.325,0,4.21,1.885,4.21,4.21c0,2.325-1.885,4.21-4.21,4.21c-2.326,0-4.21-1.885-4.21-4.21c0-0.291,0.049-0.569,0.107-0.842C21.55,19.137,23.229,17.687,25.264,17.687',
    marker_boom: 'M16.968,8.062l9.183-5.449l-4.67,9.027L32,12.768l-9.896,6.656l3.083,12.453l-9.31-9.185l-4.515,9.029l-1.089-10.897L0,25.029l6.816-7.938L0,11.954l8.093,0.131L4.513,2.303l7.172,5.604l4.192-7.783L16.968,8.062',
    marker_building: 'M13.474,1.685L0.842,12.632C0.242,13.151,0,13.474,0,13.895c0,0.836,0.842,1.264,1.684,1.264h1.685v13.474c0,0.934,0.751,1.684,1.684,1.684H16h10.947c0.934,0,1.684-0.75,1.684-1.684V15.158h1.685c0.842,0,1.684-0.428,1.684-1.264c0-0.421-0.233-0.755-0.842-1.263L18.526,1.685H13.474',
    marker_bus: 'M9.412,0v1.882H5.647c-0.941,0-1.882,0.941-1.882,1.882v24.471h1.882V32h3.765v-3.765h13.177V32h3.765v-3.765h1.882V3.765c0-0.941-0.941-1.882-1.882-1.882h-3.765V0H9.412z M7.529,5.647h16.941v9.412H7.529V5.647z M9.412,20.706c1.042,0,1.882,0.838,1.882,1.882c0,1.044-0.84,1.882-1.882,1.882c-1.042,0-1.882-0.838-1.882-1.882C7.529,21.544,8.37,20.706,9.412,20.706z M22.588,20.706c1.044,0,1.882,0.838,1.882,1.882c0,1.044-0.838,1.882-1.882,1.882c-1.044,0-1.882-0.838-1.882-1.882C20.706,21.544,21.544,20.706,22.588,20.706',
    marker_cafe: 'M5.053,4.21c-0.933,0-1.684,0.75-1.684,1.684v15.158c0,0.934,0.751,1.684,1.684,1.684h18.526c0.934,0,1.684-0.75,1.684-1.684v-1.685h0.842c0.842,0,0.842-0.842,0.842-0.842v-0.842h1.684C30.316,17.684,32,16,32,14.316v-1.685V9.263c0-0.842-0.842-1.684-1.684-1.684h-5.053V5.895c0-0.934-0.75-1.684-1.684-1.684H5.053z M25.263,9.263h4.21c0.842,0,0.842,0.842,0.842,0.842v4.211c0,0.842-0.842,1.684-1.685,1.684h-1.684h-1.684V9.263z M0,24.421l1.684,1.684l1.685,1.685h21.895l1.684-1.685l1.684-1.684H0',
    marker_camera: 'M30.316,7.578h-6.737V5.895c0-0.932-0.757-1.684-1.685-1.684h-6.737c-0.931,0-1.684,0.752-1.684,1.684v1.684H8.421c0-0.93-0.755-1.684-1.684-1.684H5.053c-0.929,0-1.684,0.754-1.684,1.684H1.684C0.755,7.578,0,8.332,0,9.264v16.842c0,0.93,0.755,1.684,1.684,1.684h28.632c0.928,0,1.684-0.754,1.684-1.684V9.264C32,8.332,31.244,7.578,30.316,7.578z M18.526,24.422c-3.72,0-6.737-3.018-6.737-6.738s3.017-6.736,6.737-6.736s6.737,3.016,6.737,6.736S22.247,24.422,18.526,24.422z',
    marker_campsite: 'M15.892,1.892L18.488,0l0.392,0.646l-2.509,1.782L30.005,32h-8.411l-5.702-13.741l-5.483,13.739H1.995L15.629,2.426l-2.507-1.78L13.514,0l2.601,1.89',
    marker_car: 'M7.529,2.824c-1.882,0-2.471,0.941-2.823,1.883l-2.824,7.529C1.529,13.176,0,14.117,0,16v9.412h3.765v1.883c0,0.941,0.941,1.881,1.882,1.881h1.882c0.941,0,1.882-0.939,1.882-1.881v-1.883h13.177v1.883c0,0.941,0.941,1.881,1.882,1.881h1.882c0.941,0,1.882-0.939,1.882-1.881v-1.883H32V16c0-1.883-1.39-2.449-1.882-3.764l-2.824-7.529c-0.353-0.941-0.941-1.883-2.823-1.883C18.197,2.824,7.529,2.824,7.529,2.824z M8.471,6.588h15.059l1.882,5.648H6.588L8.471,6.588z M7.529,17.883c1.044,0,1.882,0.838,1.882,1.883c0,1.043-0.838,1.881-1.882,1.881c-1.044,0-1.882-0.838-1.882-1.881C5.647,18.721,6.485,17.883,7.529,17.883z M24.471,17.883c1.044,0,1.882,0.838,1.882,1.883c0,1.043-0.838,1.881-1.882,1.881c-1.044,0-1.882-0.838-1.882-1.881C22.588,18.721,23.428,17.883,24.471,17.883',
    marker_cemetery: 'M16,0C9.852,0,7,4,7,10v10v8c-1,0-2,1-2,2v2h22v-2c0-1-1-2-2-2V10C25,4,22,0,16,0',
    marker_chemist: 'M9.941,0c-1.34,0-2.412,0.838-2.412,1.883c0,1.044,1.072,1.882,2.412,1.882h1.353v7.529L1.882,30.117L3.706,32h5.706h13.177h1.941h3.647l1.941-1.883l-9.412-18.823V3.765h1.353c1.342,0,2.412-0.838,2.412-1.882c0-1.045-1.07-1.883-2.412-1.883H9.941z M15.059,3.765h1.882v7.529l5.647,11.294H9.412l5.647-11.294V3.765',
    marker_cinema: 'M27.294,0l-4.235,1.235l2.059,5.588l4.06-1.177L27.294,0z M20.471,1.941l-5.529,1.588l0.117,0.235l1.059,2.882l0.941,2.471l5.529-1.588L20.471,1.941z M12.412,4.235L7.529,5.647l2.118,5.588l4.882-1.412l-1.235-3.177L12.412,4.235z M5,6.353L0.941,7.529l1.882,5.647l4.235-1.235L5,6.353z M2.823,13.176v16.941C2.823,31.162,3.664,32,4.706,32h24.471c1.044,0,1.882-0.838,1.882-1.882V13.176H2.823z M6.588,16.941h20.706v5.647H6.588V16.941',
    marker_circle_1: 'M16,0.001c-8.836,0-16,7.162-16,16c0,8.834,7.164,15.998,16,15.998c8.834,0,16-7.164,16-15.998C32,7.163,24.834,0.001,16,0.001z M16,21.916c-3.267,0-5.919-2.65-5.919-5.919s2.65-5.919,5.919-5.919s5.919,2.65,5.919,5.919S19.269,21.916,16,21.916',
    marker_circle_2: 'M15.932,27.422c-6.308,0-11.421-5.111-11.421-11.423c0-6.308,5.113-11.423,11.421-11.423c6.31,0,11.425,5.115,11.425,11.423C27.356,22.311,22.241,27.422,15.932,27.422z M16,0C7.163,0,0.001,7.162,0.001,15.999S7.163,32,16,32c8.835,0,15.999-7.164,15.999-16.001S24.835,0,16,0z M15.894,31.022c-8.275,0-14.981-6.708-14.981-14.981S7.619,1.06,15.894,1.06c8.271,0,14.981,6.708,14.981,14.981C30.873,24.312,24.165,31.022,15.894,31.022',
    marker_circle: 'M0,16c0,8.834,7.164,16,16,16s16-7.166,16-16S24.836,0,16,0S0,7.166,0,16',
    marker_city: 'M3.765,0v3.765H0v24.471h15.059V3.765h-3.765V0H3.765z M20.706,3.765v5.647h-3.765V32H32V9.412h-3.765V3.765h-1.882v5.647h-3.765V3.765H20.706z M1.882,7.529h3.765v1.882H1.882V7.529z M9.412,7.529h3.765v1.882H9.412V7.529z M1.882,11.294h3.765v1.882H1.882V11.294z M9.412,11.294h3.765v1.882H9.412V11.294z M18.824,13.176h3.765v1.882h-3.765V13.176z M26.353,13.176h3.765v1.882h-3.765V13.176z M1.882,15.059h3.765v1.883H1.882V15.059z M9.412,15.059h3.765v1.883H9.412V15.059z M18.824,16.941h3.765v1.882h-3.765V16.941z M26.353,16.941h3.765v1.882h-3.765V16.941z M1.882,18.824h3.765v1.882H1.882V18.824z M9.412,18.824h3.765v1.882H9.412V18.824z M18.824,20.706h3.765v1.882h-3.765V20.706z M26.353,20.706h3.765v1.882h-3.765V20.706z M1.882,22.588h3.765v1.882H1.882V22.588z M9.412,22.588h3.765v1.882H9.412V22.588z M18.824,24.471h3.765v1.882h-3.765V24.471z M26.353,24.471h3.765v1.882h-3.765V24.471',
    marker_clothing_store: 'M10.851,3.392c-0.21-0.017-0.458,0.031-0.735,0.158C8.404,4.295,6.752,5.073,6.752,5.073s-4.745,4.378-6.516,6.147c-0.307,0.309-0.323,0.797,0,1.209l4.204,4.15c0.294,0.344,0.914,0.404,1.261,0.053l1.051-1.471h1.682v13.45H16h7.566v-13.45h1.681l1.052,1.471c0.345,0.35,0.967,0.291,1.261-0.053l4.203-4.15c0.323-0.412,0.309-0.9,0-1.209c-1.77-1.77-6.516-6.147-6.516-6.147s-1.651-0.776-3.362-1.523c-1.113-0.501-1.658,0.299-1.682,0.737C20.231,6.032,18.369,8.483,16,8.437c-2.371,0.047-4.233-2.404-4.204-4.151C11.778,3.957,11.473,3.425,10.851,3.392L10.851,3.392',
    marker_college: 'M16,4.211l-16,8.42l16,8.422l9.263-4.684c0,0,0,4.246,0,6.367c0,3.369,3.368,3.369,3.368,3.369v-1.684c0,0-1.684-0.096-1.684-1.686c0-2.525,0-7.314,0-7.314L32,12.631L16,4.211z M8.421,19.369v3.367c0,0.836,0.548,1.957,1.685,2.527L16,27.789l5.053-2.525c1.136-0.57,2.605-1.695,2.526-2.527v-3.367L16,23.053L8.421,19.369',
    marker_commercial: 'M5.053,0C4.211,0,3.368,0.842,3.368,1.685V32h10.105v-5.053h5.053V32h10.105V1.685C28.632,0.842,27.789,0,26.947,0H5.053z M8.421,5.053h5.053v1.685H8.421V5.053z M18.526,5.053h5.053v1.685h-5.053V5.053z M8.421,10.105h5.053v1.684H8.421V10.105z M18.526,10.105h5.053v1.684h-5.053V10.105z M8.421,15.158h5.053v1.684H8.421V15.158z M18.526,15.158h5.053v1.684h-5.053V15.158z M8.421,20.211h5.053v1.684H8.421V20.211z M18.526,20.211h5.053v1.684h-5.053V20.211',
    marker_corn: 'M15.948,0c2.422,2.796,3.73,12.684,3.67,17.042l-1.353,9.969l4.343-12.482c1.212-0.608,11.65,1.049,6.019,1.313C20.699,16.203,17.89,32,17.89,32h-3.778c0,0-2.813-15.797-10.738-16.159c-5.631-0.264,4.803-1.921,6.02-1.313l4.339,12.482c0,0-1.325-7.456-1.35-9.974C12.274,4.601,15.948,0,15.948,0',
    marker_cross: 'M2.039,0C1.02,0,0,1.021,0,2.039c0,0.509,0.166,0.994,0.574,1.402L13.132,16L0.574,28.559C0.166,28.967,0,29.452,0,29.96C0,30.979,1.02,32,2.039,32c0.509,0,1.02-0.125,1.465-0.573L16,18.93l12.496,12.497C28.941,31.875,29.452,32,29.959,32C30.981,32,32,30.978,32,29.96c0-0.508-0.166-0.993-0.574-1.401L18.868,16L31.426,3.441C31.834,3.033,32,2.548,32,2.039C32,1.021,30.981,0,29.959,0c-0.507,0-1.018,0.125-1.463,0.573L16,13.066L3.505,0.573C3.059,0.125,2.548,0,2.039,0',
    marker_dam: 'M0,4.706c0,4.156,0,12.471,0,12.471V21v3.706v2.588c0,0.941,0.941,1.883,1.882,1.883h9.412L3.765,2.823c0,0-1.255,0-1.882,0C0.941,2.823,0,3.765,0,4.706z M16,16c-0.199,0-0.408,0.063-0.588,0.117l-4.588,1.591l1,3.529L16,19.765l5.059,1.765c0.195,0.063,0.39,0.118,0.588,0.118c0.199,0,0.404-0.062,0.588-0.118l6-1.765L32,21v-3.823l-3.176-1.06C28.636,16.055,28.434,16,28.235,16c-0.199,0-0.397,0.055-0.588,0.117l-6,1.765l-5.059-1.765C16.39,16.052,16.199,16,16,16z M16,23.529c-0.199,0-0.408,0.063-0.588,0.118l-2.588,0.884l0.998,3.529L16,27.294l5.059,1.765c0.195,0.063,0.39,0.118,0.588,0.118c0.199,0,0.404-0.063,0.588-0.118l6-1.765L32,28.529v-3.823l-3.176-1.059c-0.188-0.063-0.39-0.118-0.588-0.118c-0.199,0-0.397,0.056-0.588,0.118l-6,1.765l-5.059-1.765C16.39,23.581,16.199,23.529,16,23.529',
    marker_danger: 'M16,0c-4.849,0-8.727,3.879-8.727,7.758v3.878c0,1.939,3.878,1.939,3.878,3.879v1.939L16,19.394l4.849-1.939v-1.939c0-1.939,3.879-1.939,3.879-3.879V7.758C24.728,3.879,20.849,0,16,0z M13.091,5.818c1.07,0,1.939,0.869,1.939,1.939c0,1.07-0.869,1.939-1.939,1.939c-1.07,0-1.939-0.869-1.939-1.939C11.151,6.688,12.021,5.818,13.091,5.818z M18.909,5.818c1.072,0,1.939,0.869,1.939,1.939c0,1.07-0.868,1.939-1.939,1.939S16.97,8.828,16.97,7.758C16.97,6.688,17.837,5.818,18.909,5.818z M3.394,17.455c-0.97,0-1.939,0.97-1.939,1.939v0.97l10.182,4.364L1.455,29.091v0.97c0,0.97,0.97,1.939,1.939,1.939l27.151-11.636v-0.97c0-0.97-0.97-1.939-1.939-1.939L16,22.849L3.394,17.455z M23.758,26.182l-4.364,1.879L28.606,32c0.97,0,1.939-0.97,1.939-1.939v-0.97L23.758,26.182',
    marker_disability: 'M14.4,0c-2.209,0-4,1.791-4,4s1.791,4,4,4s4-1.791,4-4S16.609,0,14.4,0z M13.6,9.6c-1.6,0-3.2,1.6-3.2,3.2v5.6c0,2.4,1.6,4,4,4h8l4.2,5.6c0.362,0.531,0.775,0.8,1.4,0.8c0.8,0,1.6-0.8,1.6-1.6c0-0.469-0.266-0.832-0.45-1.15L24.8,20c-0.291-0.503-0.8-0.8-1.6-0.8h-4l-1.6-6.4c-0.388-1.553-0.8-3.2-2.4-3.2H13.6z M8.8,12.8c-3.729,1.317-6.4,5.419-6.4,9.6c0,5.3,4.3,9.6,9.6,9.6c4.761,0,8.694-3.453,9.45-8H18.4c-0.861,2.769-2.948,4.8-6,4.8c-3.755,0-6.8-3.043-6.8-6.8c0-2.428,1.284-4.797,3.2-6V12.8',
    marker_dog_park: 'M21.895,6.737l3.369-1.684h1.684l-1.684,1.684v0.842L32,10.947l-0.842,2.526c-4.21,0-6.737,0-7.579,1.685l-3.369-5.053L21.895,6.737z M22.737,16l-3.368-5.053l-2.526,0.842H7.579L5.053,8.421l1.684-3.369H5.053L3.369,8.421l1.684,5.052c-1.684,1.685-1.684,3.369-1.684,5.053L0,26.947h3.369c-0.811-2.338,1.27-4.016,2.526-5.895l-0.842,5.895h3.368c-1.69-2.671,0.674-5.727,1.685-8.421c1.684,0,2.526,1.684,6.737,1.684l-1.685,6.737h3.369c-1.476-1.881-0.128-3.112,0.842-5.052l2.526,5.052h3.369c-2.658-1.533-2.666-3.927-3.369-6.737L22.737,16',
    marker_embassy: 'M11.596,0.292L8.073,2.053L4.55,3.815v26.423C4.55,31.119,5.431,32,6.312,32s1.761-0.881,1.761-1.762V14.384c0,0,1.762-0.881,3.523-1.762c3.523-1.762,7.418,5.099,10.569,3.523c3.523-1.762,5.285-2.643,5.285-2.643V1.172c0,0-1.761,0.881-5.285,2.643C19.014,5.391,15.119-1.47,11.596,0.292',
    marker_emergency_telephone: 'M8.589,0L0.001,8.588h8.588V0z M21.949,0.954c-1.909,0-2.531,0.623-3.817,1.909L2.864,18.131c-0.954,0.954-1.909,1.908-1.909,3.817v2.862c0,1.909,0.955,2.863,1.909,3.817l1.074,1.073l6.68-6.68l-1.074-1.073c-0.954-0.954,0-1.909,0-1.909L20.04,9.543c0,0,0.954-0.955,1.909,0l1.073,1.073l6.68-6.68l-1.074-1.073c-0.954-0.955-1.909-1.909-3.817-1.909H21.949z M30.417,4.652l-6.68,6.68l1.075,1.075c0.675,0.677,2.187,0.677,2.863,0l3.817-3.817c0.675-0.676,0.675-2.186,0-2.862L30.417,4.652z M11.333,23.737l-6.68,6.68l1.076,1.075c0.675,0.677,2.186,0.677,2.863,0l3.817-3.816c0.675-0.677,0.675-2.187,0-2.863L11.333,23.737',
    marker_entrance: 'M15.059,8.467v4.706H2.824C1.265,13.173,0,14.445,0,16.004s1.265,2.816,2.824,2.816h12.233l0.001,4.705c0.009,0.673,0.912,1.197,1.469,0.82l9.432-7.566c0.497-0.327,0.529-1.163,0.059-1.529l-9.43-7.478C15.731,7.184,15.059,7.761,15.059,8.467z M9.412,0C8.471,0,7.515,1.004,7.529,1.883v7.529h3.765V3.765h16.941v24.471H11.294v-5.647H7.529v7.529C7.529,31.103,8.426,32,9.412,32h20.706C31.103,32,32,31.103,32,30.117V1.883C32,0.897,31.103,0,30.118,0C23.114,0,16.386,0,9.412,0',
    marker_explosion: 'M6.956,32L0,20.909l6.159,4.08L1.603,13.115l6.303,6.31L5.144,0.344L14.4,16.987v-6.659l2.889,5.362L20.8,0v17.553l3.2-4.121v5.248l8-9.367l-6.922,17.156l4.975-1.638l-3.491,3.994L29.906,32h-8.094l3.003-3.471l-3.003,0.536l1.802-6.729l-4.547,3.773l-0.516-6.297L16.837,24.1l-0.944-2.456l-0.514,3.753l-4.117-6.35l0.372,8.381L8,24.12L9.888,32H6.956',
    marker_farm: 'M24,10.4v16h8v-16C32,8,31.2,5.6,28,5.6S24,8,24,10.4z M6.4,10.4c-0.8,0-1.6,0.799-2.4,1.6l-4,4.801h1.6v9.6h11.2V21.6H16V26.4h4.8v-9.6h1.6L18.4,12c-0.8-0.801-1.6-1.6-2.4-1.6H6.4z M14.4,13.6c0,0,3.2,2.008,3.2,3.201v1.6h-6.4v-1.6C11.2,15.607,14.4,13.6,14.4,13.6',
    marker_fast_food: 'M11.79,5.053c-5.053,0-10.105,2.526-10.105,6.737v3.368h28.632V11.79c0-4.211-5.053-6.737-10.105-6.737H11.79z M12.631,6.737c0.467,0,0.842,0.375,0.842,0.842s-0.375,0.842-0.842,0.842c-0.467,0-0.842-0.375-0.842-0.842S12.165,6.737,12.631,6.737z M19.369,6.737c0.467,0,0.842,0.375,0.842,0.842s-0.375,0.842-0.842,0.842c-0.467,0-0.842-0.375-0.842-0.842S18.901,6.737,19.369,6.737z M9.263,10.105c0.467,0,0.842,0.375,0.842,0.842c0,0.468-0.375,0.843-0.842,0.843s-0.842-0.375-0.842-0.843C8.421,10.48,8.796,10.105,9.263,10.105z M16,10.105c0.467,0,0.842,0.375,0.842,0.842c0,0.468-0.375,0.843-0.842,0.843s-0.842-0.375-0.842-0.843C15.158,10.48,15.533,10.105,16,10.105z M22.737,10.105c0.467,0,0.842,0.375,0.842,0.842c0,0.468-0.375,0.843-0.842,0.843s-0.842-0.375-0.842-0.843C21.895,10.48,22.27,10.105,22.737,10.105z M1.684,16.842C0.842,16.842,0,17.685,0,18.526s0.842,1.685,1.684,1.685h28.632c0.842,0,1.684-0.843,1.684-1.685s-0.842-1.685-1.684-1.685H1.684z M1.684,21.895v1.685c0,1.685,1.685,3.368,3.369,3.368h21.895c1.684,0,3.369-1.684,3.369-3.368v-1.685H1.684',
    marker_feathers: 'M1.142,30.854l7.898-7.827l1.088,1.091L2.266,32L1.142,30.854z M23.079,15.827c-2.553,2.683-11.01,8.521-11.871,8.637c-0.886,0.119-2.63-1.514-2.63-2.406c0-1.212,5.124-9.789,7.569-12.568C18.921,6.345,27.331,2.957,30.512,0C32.417,0.531,25.966,12.785,23.079,15.827z M22.68,9.944l-0.742-0.681L9.041,23.027l1.088,1.091L22.68,9.944',
    marker_ferry: 'M12,0c-0.888,0-1.6,0.712-1.6,1.6H7.2c-0.887,0-1.6,0.712-1.6,1.6v9.7l-3.2,1.5l3.2,6.4V24c2.4,0,4,1.6,5.6,1.6c1.6,0,2.4-1.6,4.8-1.6s3.2,1.6,4.8,1.6S24,24,26.4,24v-3.2l3.2-6.4l-3.2-1.5V3.2c0-0.888-0.712-1.6-1.6-1.6h-3.2C21.6,0.712,20.887,0,20,0H12z M8.8,4.8h14.4v6.6L16,8l-7.2,3.4V4.8z M5.6,27.2c-1.109,0-2.144,0.646-3.2,1v3.25c0,0,1.6-1.05,3.2-1.05c1.6,0,3.2,1.6,5.6,1.6c2.4,0,3.16-1.544,4.8-1.6c1.6,0,2.4,1.6,4.8,1.6s4-1.6,5.6-1.6c1.122,0,3.2,1.05,3.2,1.05V28.2c-1.028-0.396-2.15-1-3.2-1c-2.4,0-4,1.6-5.6,1.6s-2.4-1.6-4.8-1.6s-3.2,1.6-4.8,1.6C9.6,28.8,8,27.2,5.6,27.2',
    marker_fire_station: 'M16,0L9.412,9.412L5.647,3.765C4.399,5.958,0,11.294,0,16.941C0,25.257,7.684,32,16,32s16-6.743,16-15.059c0-5.647-4.401-10.984-5.647-13.177l-3.765,5.647L16,0z M16,15.059c0,0,6.588,5.055,6.588,9.412c0,1.882-2.824,3.765-6.588,3.765s-6.588-1.882-6.588-3.765C9.412,20.261,16,15.059,16,15.059',
    marker_fish: 'M26.109,15.862c0,0-7.917,8.099-14.202,7.606C2.569,22.74,0,15.572,0,15.572c0.522-2.476,6.06-7.728,11.938-7.728c7.028,0,14.167,7.599,14.167,7.599L32,8.083v16.072L26.109,15.862',
    marker_fuel: 'M4.444,0C3.46,0,2.667,0.792,2.667,1.778v28.444C2.667,31.111,3.556,32,4.444,32h16c0.889,0,1.778-0.889,1.778-1.778v-8.889h0.889C24,21.333,24,22.222,24,22.222v3.556c0,1.778,0.889,2.667,2.667,2.667c1.777,0,2.666-0.889,2.666-2.667c0-2.073,0-8,0-9.778s-3.555-3.556-3.555-5.333V5.333H24l-1.777-1.778V1.778C22.223,0.792,21.431,0,20.444,0H4.444z M6.223,3.556h12.444v7.111H6.223V3.556z M22.223,8.889H24c0,0,0,1.481,0,2.667c0,1.778,3.556,3.556,3.556,5.333v8.889c0,0.889-0.889,0.889-0.889,0.889s-0.889,0-0.889-0.889c0,0,0-3.556,0-4.444c0-0.889-0.89-1.778-1.778-1.778c-0.594,0-1.777,0-1.777,0V8.889',
    marker_garden: 'M7.6,0C7.094,0,7,1.121,7,2v4c0,4.063,4,6.125,8,6v16c-2-4-8-10-14-8c0,0,5,12,15,12s15-12,15-12c-6-2-12,4-14,8V12c4,0.125,8-1.938,8-6V2c0-0.879-0.094-2-0.6-2c-0.387,0-0.854,0.453-1.4,1l-3,3l-3-3.375C16.688,0.271,16.506,0,16,0s-0.686,0.271-1,0.625L12,4L9,1C8.453,0.453,7.986,0,7.6,0',
    marker_golf: 'M10.667,0c-0.606,0-0.889,0-0.889,0.889v25.667c-3.556,0.111-5.333,2.777-5.333,4.556C4.444,32,5.333,32,5.333,32h21.333c0,0,0.889,0,0.889-0.889c0-4.444-2.667-8-5.333-8c-2.795,0-4.344,4.94-7.111,5.333c-1.215,0.17-2.385-0.614-3.555-1.222V10.667L24,5.333L12.446,0.382C11.778,0.098,11.111,0,10.667,0',
    marker_grapes: 'M9.081,9.277c0,1.907-1.549,3.456-3.46,3.456c-1.909,0-3.458-1.549-3.458-3.456c0-1.911,1.549-3.459,3.458-3.459C7.532,5.818,9.081,7.366,9.081,9.277z M12.538,5.818c-1.906,0-3.457,1.548-3.457,3.459c0,1.907,1.551,3.456,3.457,3.456c1.913,0,3.46-1.549,3.46-3.456C15.998,7.366,14.451,5.818,12.538,5.818z M19.458,5.818c-1.907,0-3.46,1.548-3.46,3.459c0,1.907,1.553,3.456,3.46,3.456c1.911,0,3.455-1.549,3.455-3.456C22.913,7.366,21.369,5.818,19.458,5.818z M26.377,5.818c-1.909,0-3.457,1.548-3.457,3.459c0,1.907,1.548,3.456,3.457,3.456c1.911,0,3.46-1.549,3.46-3.456C29.837,7.366,28.288,5.818,26.377,5.818z M9.078,12.24c-1.906,0-3.457,1.549-3.457,3.46c0,1.909,1.551,3.455,3.457,3.455c1.914,0,3.46-1.546,3.46-3.455C12.538,13.789,10.992,12.24,9.078,12.24z M15.998,12.24c-1.904,0-3.455,1.549-3.455,3.46c0,1.909,1.551,3.455,3.455,3.455c1.916,0,3.46-1.546,3.46-3.455C19.458,13.789,17.914,12.24,15.998,12.24z M22.917,12.24c-1.908,0-3.455,1.549-3.455,3.46c0,1.909,1.547,3.455,3.455,3.455c1.914,0,3.456-1.546,3.456-3.455C26.373,13.789,24.833,12.24,22.917,12.24z M12.538,18.66c-1.906,0-3.457,1.551-3.457,3.462c0,1.909,1.551,3.456,3.457,3.456c1.913,0,3.46-1.547,3.46-3.456C15.998,20.211,14.451,18.66,12.538,18.66z M19.458,18.66c-1.904,0-3.458,1.551-3.458,3.462c0,1.909,1.551,3.456,3.453,3.456c1.92,0,3.462-1.547,3.462-3.456C22.915,20.211,21.376,18.66,19.458,18.66z M15.998,25.087c-1.904,0-3.455,1.547-3.455,3.458c0,1.904,1.551,3.455,3.455,3.455c1.916,0,3.46-1.551,3.46-3.455C19.458,26.634,17.914,25.087,15.998,25.087z M19.018,1.947c-0.97,1.1-2.529,5.449-2.529,5.449L24.379,0C24.379,0.002,20.271,0.529,19.018,1.947z M15.515,7.496h0.974v-6.13h-0.974V7.496',
    marker_grocery: 'M30.118,0.941c-1.099,0-2.074,0.309-2.765,1c-0.691,0.691-1,1.667-1,2.765H0l3.765,16.941h22.588v1.882H5.647c-1.882,0-1.882,1.883,0,1.883h2.824h15.059h2.824c0.941,0,1.882-0.941,1.882-1.883v-1.882V6.589V4.706c0,0,0.162-1.221,0.412-1.471c0.25-0.248,0.688-0.411,1.471-0.411c0.209,0,0.551,0,0.753,0C31.813,2.824,32,2.355,32,1.883c0-0.465-0.188-0.941-1.129-0.941C30.625,0.941,30.375,0.941,30.118,0.941z M23.529,25.412c-1.559,0-2.823,1.265-2.823,2.823s1.265,2.823,2.823,2.823c1.559,0,2.824-1.265,2.824-2.823S25.088,25.412,23.529,25.412z M8.471,25.412c-1.561,0-2.824,1.265-2.824,2.823s1.263,2.823,2.824,2.823s2.823-1.265,2.823-2.823S10.031,25.412,8.471,25.412',
    marker_hairdresser: 'M4.923,4.513C2.204,4.513,0,6.716,0,9.436s2.204,4.923,4.923,4.923c1.125,0,2.147-0.393,2.975-1.025L11.846,16l-3.949,2.667c-0.827-0.635-1.85-1.026-2.975-1.026C2.204,17.641,0,19.846,0,22.563c0,2.719,2.204,4.924,4.923,4.924c2.72,0,4.923-2.205,4.923-4.924c0-0.436-0.1-0.826-0.205-1.23L32,6.41c-0.82-0.547-1.641-1.077-3.282-1.077h-1.641c-1.641,0-2.463,0.532-3.282,1.077l-9.846,6.564l-4.308-2.308c0.105-0.402,0.205-0.795,0.205-1.231C9.846,6.716,7.643,4.513,4.923,4.513z M19.897,17.487l-4.513,3.025l7.589,5.075c0.819,0.545,1.641,1.077,3.282,1.077h2.461c1.641,0,2.462-0.529,3.282-1.077L19.897,17.487',
    marker_harbor: 'M25.412,0c-3.64,0-6.589,2.949-6.589,6.588c0,0.823,0.195,1.625,0.471,2.353L3.765,24.471c0-1.882,0-5.647,0-7.529c0-2.824,1.883-3.765,1.883-3.765l1.882,1.882c0,0,0-5.02,0-7.529c0-1.882-0.941-0.941-1.882,0C0,13.176,0,13.125,0,16.941v7.529v5.647C0,31.059,0.941,32,1.883,32h5.646h7.529c3.816,0,6.901-3.138,9.412-5.647c0.941-0.941,1.882-1.857,0-1.882h-7.529l1.882,1.882c0,0-0.94,1.882-3.765,1.882c-1.882,0-5.646,0-7.529,0l15.529-15.529c0.729,0.275,1.529,0.47,2.354,0.47c3.64,0,6.588-2.948,6.588-6.588S29.052,0,25.412,0z M25.412,3.765c1.559,0,2.823,1.265,2.823,2.824c0,1.559-1.265,2.823-2.823,2.823c-1.56,0-2.824-1.265-2.824-2.823C22.588,5.029,23.853,3.765,25.412,3.765',
    marker_heart: 'M30.654,14.083c-2.856,5.521-10.822,13.489-14.655,16.977C12.166,27.572,4.201,19.603,1.345,14.083c-5.86-11.316,8.979-18.86,14.654-7.544C21.674-4.778,36.515,2.766,30.654,14.083',
    marker_helioport: 'M15.579,26.105h14.736c0.843,0,1.685-0.842,1.685-1.685v-5.053c0-1.684-0.81-2.493-1.685-3.368c0,0-1.121-1.123-1.684-1.685c-0.563-0.561-1.685-1.684-3.369-1.684h-5.052V9.264h6.736h3.368C31.25,9.264,32,8.514,32,7.579s-0.75-1.685-1.685-1.685h-3.368h-5.895h-1.685h-7.579h-1.684H8.421c-0.933,0-1.684,0.75-1.684,1.685s0.751,1.685,1.684,1.685h1.685h1.684h6.737v3.368h-5.053H6.263c-0.582-1.007-1.647-1.685-2.895-1.685C1.51,10.947,0,12.457,0,14.315c0,1.859,1.51,3.369,3.368,3.369c0.973,0,1.807-0.447,2.421-1.105l6,6.158C13.474,24.464,14.737,26.105,15.579,26.105z M3.368,12.632c0.93,0,1.685,0.755,1.685,1.684c0,0.93-0.755,1.685-1.685,1.685c-0.929,0-1.684-0.755-1.684-1.685C1.685,13.387,2.439,12.632,3.368,12.632',
    marker_hospital: 'M12.8,0c-1.184,0-2.133,0.95-2.133,2.133v8.533H2.134C0.952,10.667,0,11.617,0,12.8v6.4c0,1.183,0.952,2.133,2.134,2.133h8.533v8.533C10.667,31.05,11.616,32,12.8,32h6.4c1.186,0,2.133-0.95,2.133-2.133v-8.533h8.533c1.186,0,2.134-0.95,2.134-2.133v-6.4c0-1.183-0.948-2.133-2.134-2.133h-8.533V2.133C21.333,0.95,20.386,0,19.2,0H12.8',
    marker_industrial: 'M22.588,2.823c0,2.51,0,7.529,0,7.529h-5.646V4.706l-7.529,5.646V4.706l-7.529,5.646C1.048,10.979,0,11.294,0,13.177v16c0,1.044,0.84,1.882,1.883,1.882h28.234c1.045,0,1.883-0.838,1.883-1.882V12.235c0-1.044-0.838-1.883-1.883-1.883h-3.765c0,0,0-5.02,0-7.529c0-0.94-0.996-1.882-1.882-1.882S22.588,1.883,22.588,2.823',
    marker_land_use: 'M6.588,0c-0.94,0-1.474,0.371-1.882,0.941L0,7.529h3.765v5.647h9.412V7.529h3.765l-4.706-6.588C11.827,0.371,11.294,0,10.353,0H6.588z M30.117,0v3.765h-7.529c-0.94,0-1.882,0.941-1.882,1.883v7.529H32V0H30.117z M26.353,15.059l-5.646,7.529h2.823l-2.823,5.647h3.765V32h3.765v-3.765H32l-2.823-5.647H32L26.353,15.059z M3.765,16.941c-0.941,0-1.882,0.941-1.882,1.882V32h3.765v-3.765h1.882V32h7.529V18.823c0-0.94-0.941-1.882-1.882-1.882H3.765z M5.647,20.706h1.882v1.882H5.647V20.706z M9.412,20.706h1.882v1.882H9.412V20.706z M5.647,24.471h1.882v1.882H5.647V24.471z M9.412,24.471h1.882v1.882H9.412V24.471z M9.412,28.235h1.882v1.882H9.412V28.235',
    marker_library: 'M0,2.824v18.822c5.647,5.648,13.177,3.766,13.177,7.529h1.882V12.236C15.059,6.588,5.647,6.588,0,2.824z M32,2.824c-5.647,3.764-15.059,3.764-15.059,9.412v16.939h1.882c0-3.764,7.529-1.881,13.177-7.529V2.824',
    marker_lighthouse: 'M22.736,13.474v-3.368h-1.684V4.211c0,0,0.008-1.175-0.842-1.685L16,0l-4.211,2.526c-0.865,0.52-0.842,1.685-0.842,1.685v5.895H9.264v3.368l1.684,1.685v1.684L9.264,32h13.473l-1.684-15.158v-1.684L22.736,13.474z M17.684,9.263h-3.367V5.895c0,0,0-1.684,1.684-1.684s1.684,1.684,1.684,1.684V9.263',
    marker_liquor_store: 'M6.119,0C5.075,0,5.178,1.18,5.178,1.883v4.705c0,0.941-0.938,2.352-1.882,2.824c-1.883,0.94-1.883,1.882-1.883,3.765v16.94C1.413,31.162,1.311,32,2.354,32h9.412c1.04,0,0.941-0.838,0.941-1.883v-16.94c0-1.883,0-2.824-1.883-3.765C9.884,8.943,8.942,7.529,8.942,6.588V1.883C8.942,0.941,9.042,0,8.002,0H6.119z M18.354,13.177v5.646c0,1.883,0.941,3.765,2.823,3.765h1.883v4.706c0,0.941-1.883,2.823-3.765,2.823c-1.883,0-1.883,1.883-1.883,1.883H30.59c0,0,0-1.883-1.882-1.883c-1.883,0-3.766-1.882-3.766-2.823v-4.706h1.883c1.883,0,2.823-1.882,2.823-3.765v-5.646H18.354',
    marker_lodging: 'M3.765,3.765C1.688,3.765,0,5.452,0,7.529s1.688,3.765,3.765,3.765s3.765-1.688,3.765-3.765S5.842,3.765,3.765,3.765z M9.412,7.529c0,3.119-2.528,5.647-5.647,5.647H0.941c-0.475,0-0.943,0-0.941,0.94c0,0.256,0,1.16,0,1.883c0,0.941,0.731,0.941,0.941,0.941H32v-3.765c0-1.883,0-5.647-9.412-5.647H9.412z M1.883,18.823C0.941,18.823,0,19.765,0,20.706c0,1.863,0,4.036,0,5.646c0,0.941,0.941,1.883,1.883,1.883c0.94,0,1.882-0.941,1.882-1.883v-3.765h24.471v3.765c0,0.941,0.941,1.883,1.882,1.883c0.941,0,1.883-0.941,1.883-1.883v-5.646c0-0.941-0.941-1.883-1.883-1.883H1.883',
    marker_logging: 'M26.353,0L12.235,14.118l2.353,2.353L28.235,2.824l0.941,0.941L15.529,17.412l2.354,2.353L32,5.647V1.882L30.117,0H26.353z M4.706,10.353L0,15.059L0.941,16h1.882l1.883-1.882L6.588,16L0,22.588v3.765L5.647,32h3.765l8.471-8.471L4.706,10.353',
    marker_marker_default: 'M27.556,11.556C27.556,17.778,18.667,28.444,16,32C13.333,28.444,4.444,17.778,4.444,11.556S10.261,0,16,0S27.556,5.333,27.556,11.556',
    marker_minefield: 'M16,1.882l-4.706,13.176L0,7.529l3.765,12.235c0.862,2.803,1.883,2.824,3.765,2.824h3.765v-1.882h9.412v1.882h3.765c1.882,0,2.834-0.813,3.765-2.824L32,7.529l-11.294,7.529L16,1.882z M13.177,22.588v1.882c-4.171,0-7.529,1.259-7.529,2.823c0,1.564,3.358,2.824,7.529,2.824h5.646c4.171,0,7.529-1.259,7.529-2.824c0-1.564-3.358-2.823-7.529-2.823v-1.882H13.177',
    marker_minerals: 'M26.964,30.69h4.626L32,29.519l-1.083-0.761v-1.405l-1.142-0.35l-1.027,0.672l-0.612-1.14l-1.245-0.177l-1.229-15.235l-3.359-2.286L18.92,9.709l-1.996,9.744V2.653L14.17,1.31l-2.622,2.017L9.935,17.91l-1.344-1.881L7.2,17.668l-1.638,7.695H4.216l-0.352,0.175L3.25,26.681l-1.025-0.673L1.083,26.36v1.405L0,28.527l0.352,2.163H26.964',
    marker_mobile_phone: 'M9.715,0C8.971,0,8.381,0.589,8.381,1.333v1.762C7.529,3.186,6.857,3.814,6.857,4.619v25.81C6.857,31.294,7.625,32,8.57,32h14.857c0.947,0,1.715-0.706,1.715-1.571V4.619c0-0.868-0.768-1.571-1.715-1.571h-12V1.333C11.428,0.589,10.84,0,10.096,0H9.715z M11.428,6.095h9.143c0.813,0,1.525,0.78,1.525,1.524v4.571c0,0.741-0.713,1.524-1.525,1.524h-9.143c-0.811,0-1.523-0.783-1.523-1.524V7.619C9.904,6.875,10.617,6.095,11.428,6.095z M9.904,16.762h3.049v3.048H9.904V16.762z M14.477,16.81h3.047v3h-3.047V16.81z M19.047,16.81h3.049v3h-3.049V16.81z M9.904,21.333h3.049v3.047H9.904V21.333z M14.477,21.381h3.047v3h-3.047V21.381z M19.047,21.381h3.049v3h-3.049V21.381z M9.904,25.905h3.049v3.047H9.904V25.905z M14.477,25.952h3.047v3h-3.047V25.952z M19.047,25.952h3.049v3h-3.049V25.952',
    marker_monument: 'M16,0c0,0-4.361,3.375-4.211,5.053v18.526H8.422c-0.934,0-1.686,0.75-1.686,1.684v3.369H5.053c-0.932,0-1.684,0.75-1.684,1.684V32h25.262v-1.685c0-0.934-0.75-1.684-1.684-1.684h-1.684v-3.369c0-0.934-0.75-1.684-1.684-1.684h-3.369V5.053C20.211,3.368,16,0,16,0',
    marker_museum: 'M16,0L4.444,8.889h23.111L16,0z M6.222,10.667c-0.985,0-1.639,0.804-1.777,1.777v12.444l-3.111,5.334c-0.264,0.451-0.444,0.632-0.444,0.889C0.889,32,2.014,32,2.666,32h26.667c0.632,0,1.778,0,1.778-0.889c0-0.261-0.153-0.393-0.445-0.889l-3.11-5.334V12.444c0-0.986-0.792-1.777-1.778-1.777H6.222z M9.777,14.223c0.889,0,1.778,0,2.667,0S14.222,16,14.222,16L16,20.444L17.777,16c0.396-0.993,0.889-1.777,1.778-1.777c0.889,0,1.777,0,2.666,0C23.208,14.223,24,15.014,24,16v8.889c0,0.889-0.889,1.778-1.778,1.778c-0.889,0-1.777-0.89-1.777-1.778v-7.111l-2.667,5.334c0,0-0.889,1.777-1.777,1.777s-1.778-1.777-1.778-1.777l-2.666-5.334v7.111c0,0.889-0.89,1.778-1.778,1.778S8,25.777,8,24.889v-1.777V16C8,15.014,8.791,14.223,9.777,14.223',
    marker_music: 'M11.295,0c-0.941,0-1.883,0.941-1.883,1.883v18.823H7.529c-3.117,0-5.646,2.529-5.646,5.646C1.883,29.471,4.412,32,7.529,32s5.646-2.527,5.646-5.647V9.412h13.178v11.294h-1.883c-3.117,0-5.646,2.529-5.646,5.646c0,3.118,2.529,5.647,5.646,5.647s5.646-2.527,5.646-5.647V1.883C30.117,0.941,29.176,0,28.234,0H11.295',
    marker_oil_well: 'M12.444,0c-0.889,0-1.504,0.889-1.778,1.777l-9.5,28.39c-0.069,0.201-0.277,0.739-0.277,0.944C0.889,32,1.777,32,2.666,32c0.89,0,1.727,0,2.056-0.889l1.5-4L16,22.223l9.777,4.889l1.5,4C27.607,32,28.444,32,29.333,32s1.778,0,1.778-0.889c0-0.205-0.209-0.743-0.278-0.944l-9.5-28.39C21.059,0.889,20.444,0,19.556,0H12.444z M20.444,12.444l1.222,3.389L16,18.667l-5.667-2.834l1.223-3.389H20.444z M9.222,18.833l3.223,1.611l-4.667,2.333L9.222,18.833z M22.777,18.833l1.444,3.944l-4.666-2.333L22.777,18.833',
    marker_parking_garage: 'M16,0L2.715,4.088v4.088L16,4.088l13.285,4.088V4.088L16,0z M9.676,8.687V32h4.918v-8.303h2.172c2.895,0,5.143-0.651,6.707-1.982c1.572-1.331,2.299-3.237,2.299-5.75c0-2.417-0.689-4.215-2.17-5.427c-1.471-1.224-3.621-1.851-6.451-1.851H9.676z M14.594,12.71h2.301c1.354,0,2.311,0.275,2.938,0.831c0.639,0.555,0.959,1.459,0.959,2.619c0,1.15-0.396,2.012-1.15,2.619c-0.754,0.595-1.865,0.895-3.385,0.895h-1.662V12.71',
    marker_parking: 'M11.732,15.058h2.232c2.084,0,3.654-0.408,4.684-1.229c1.035-0.826,1.555-2.033,1.555-3.612c0-1.584-0.438-2.761-1.314-3.524c-0.857-0.755-2.215-1.138-4.07-1.138h-3.086V15.058 M27.055,9.981c0,3.442-1.078,6.08-3.24,7.9c-2.145,1.825-5.203,2.733-9.172,2.733h-2.91V32H4.945V0h10.223c3.881,0,6.83,0.837,8.844,2.517C26.041,4.178,27.055,6.668,27.055,9.98',
    marker_park: 'M16,0L6.222,8.889h5.334L4.444,16h5.333l-5.333,7.111h8.889v5.333v0.889v0.89c0,0.985,0.792,1.777,1.778,1.777h1.777c0.986,0,1.778-0.792,1.778-1.777v-0.89v-0.889v-5.333h8.889L22.222,16h5.334l-1.778-1.777l-5.333-5.334h5.333L16,0',
    marker_pharmacy: 'M16,0c-5.115,0-9.264,1.885-9.264,4.21v5.053c0,2.326,4.148,4.21,9.264,4.21s9.264-1.885,9.264-4.21V4.21C25.264,1.885,21.115,0,16,0z M6.736,15.158V27.79C6.736,30.115,10.885,32,16,32s9.264-1.885,9.264-4.21V15.158c0-1.684-0.842-1.684-1.686-0.842c-1.41,1.506-3.752,2.526-7.578,2.526c-3.75,0-6.121-1.069-7.578-2.526C7.578,13.474,6.736,13.474,6.736,15.158z M11.789,20.21c1.268,0.296,2.691,0.474,4.211,0.474c1.523,0,2.943-0.178,4.211-0.474v6.737c-1.268,0.296-2.688,0.474-4.211,0.474c-1.52,0-2.943-0.177-4.211-0.474V20.21',
    marker_pitch: 'M15.158,0C13.3,0,11.79,1.51,11.79,3.369c0,1.858,1.51,3.368,3.368,3.368s3.368-1.51,3.368-3.368C18.526,1.51,17.017,0,15.158,0z M5.474,6.737l0.842,3.369C6.737,11.79,7.743,11.79,9,11.79h2.79c1,1.684,0.529,4.161,0.842,6.737h-1.526c-1.685,0-4.368,1.684-2.685,5.053l2.526,5.052c1.685,3.369,4.211,0.842,3.368-0.842l-2.525-5.053c-0.422-0.842,0.443-0.842,0.842-0.842h4.158c0,0,1.993,4.424,4.342,7.458C21.997,30.474,22.957,32,24.161,32c0.842,0,2.283-1.303,0.747-3.135c-2.875-3.431-4.697-6.971-4.697-8.655c0-1.96-0.343-6.085-0.843-8.421h2.526C22.717,13.438,24,16,24,16c0.931,1.613,2.947,1.49,2.947,0c0-0.206,0-0.655-0.236-1.142L24,9.263c-0.174-0.357-0.428-0.842-1.685-0.842H9.264L8,4.21c-0.507-1.684-2.947-1.684-2.947,0C5.053,5.053,5.349,6.076,5.474,6.737',
    marker_place_of_workship: 'M16,1.882c0,0-4.706,1.882-4.706,3.765c0,1.255,0,2.629,0,3.765c0,1.882-3.765,1.882-3.765,3.765c0,4.392,0,16.941,0,16.941h16.941c0,0,0-12.55,0-16.941c0-1.882-3.765-1.882-3.765-4.118c0-1.138,0-2.275,0-3.414C20.706,3.765,16,1.882,16,1.882z M0,16.941c0,4.392,0,13.177,0,13.177h3.765c0,0,0-8.785,0-13.177c0-1.882-1.882-1.882-1.882-1.882S0,15.059,0,16.941z M28.235,16.941c0,4.392,0,13.177,0,13.177H32c0,0,0-8.785,0-13.177c0-1.882-1.883-1.882-1.883-1.882S28.235,15.059,28.235,16.941',
    marker_playground: 'M19.368,0.842c-1.401,0-2.526,1.127-2.526,2.527c0,1.398,1.125,2.525,2.526,2.525s2.526-1.127,2.526-2.525C21.895,1.969,20.77,0.842,19.368,0.842z M9.263,5.895c-1.4,0-2.525,1.127-2.525,2.527c0,1.398,1.125,2.525,2.525,2.525c1.401,0,2.526-1.127,2.526-2.525C11.789,7.021,10.664,5.895,9.263,5.895z M21.685,6.684c-0.293,0.094-0.49,0.49-0.632,0.896l-3.315,9.578l-5.422,1.684l-4.053-6.42c-0.588-0.951-1.213-0.771-1.474,0.053l-3.315,9.156L0,22.736l0.947,3.053l7.842-2.473l-3.421,4c-0.52,0.502-0.349,1.365,0,1.736c0.454,0.484,1.276,0.686,1.895,0c0,0,6.816-7.816,6.211-7.158c0.017-0.018-0.007-0.035,0-0.053L16,21.053l-4.211,10.105h10.105l-4.105-10.684l2.474-0.791l6.425,4.527c0.628,0.428,1.714,0.211,1.944-0.684c0.224-0.873-0.257-1.26-0.79-1.633l-4.526-3.158L32,16l-0.947-3.053l-3.474,1.105c-1.191-2.053-4.053-5.65-4.737-6.525C22.362,6.793,21.977,6.59,21.685,6.684',
    marker_point: 'M0,16c0,8.836,7.164,16,16,16s16-7.164,16-16S24.836,0,16,0S0,7.164,0,16',
    marker_police: 'M11.896,0l-1.23,1.761v1.757h10.668V1.761L20.104,0H11.896z M10.666,5.333v0.825c0,3.402,2.16,6.286,5.334,6.286s5.334-2.884,5.334-6.286V5.333H10.666z M8.889,14.223c-2.457,0-3.555,4.257-3.555,6.894V32h4.41l11.59-17.777H8.889z M23.111,14.223L12.205,32h14.461V21.116C26.666,18.479,25.57,14.223,23.111,14.223',
    marker_polling_place: 'M1.882,2.831C0.842,2.831,0,3.671,0,4.713v24.459c0,1.043,0.842,1.881,1.882,1.881h24.459c1.043,0,1.881-0.838,1.881-1.881V17.883h-3.763v9.407H3.763V6.594h15.052V2.831H1.882z M27.046,2.003L15.052,16.349l-3.469-4.055c-1.011-1.186-3.028-1.329-4.2-0.304c-1.173,1.025-0.93,2.945-0.033,4.008l5.622,6.647c1.073,1.22,3.256,1.192,4.292-0.058L31.396,5.591c0.592-0.827,0.819-2.345,0.356-3.166c-0.555-0.984-1.62-1.526-2.591-1.475C28.222,0.998,27.502,1.422,27.046,2.003L27.046,2.003',
    marker_post: 'M1.883,5.646c-1.883,0-0.941,0.639,0,1.412L16,17.971L30.117,7.059c0.941-0.773,1.883-1.412,0-1.412H15.059H1.883z M0,8.471v16c0,0.941,0.952,1.883,1.706,1.883h28.588c0.754,0,1.706-0.941,1.706-1.883v-16L16,20.795L0,8.471',
    marker_prison: 'M1.883,1.882C0.838,1.882,0,2.721,0,3.765v5.647v13.177v5.647c0,1.044,0.838,1.882,1.883,1.882c1.044,0,1.882-0.838,1.882-1.882v-3.765h5.647v3.765c0,1.044,0.838,1.882,1.882,1.882s1.883-0.838,1.883-1.882v-3.765h5.646v3.765c0,1.044,0.839,1.882,1.883,1.882s1.882-0.838,1.882-1.882v-3.765h5.647v3.765c0,1.044,0.838,1.882,1.882,1.882c1.045,0,1.883-0.838,1.883-1.882V3.765c0-1.044-0.838-1.882-1.883-1.882c-1.044,0-1.882,0.838-1.882,1.882v3.765h-5.647V3.765c0-1.044-0.838-1.882-1.882-1.882s-1.883,0.838-1.883,1.882v3.765h-5.646V3.765c0-1.044-0.839-1.882-1.883-1.882S9.412,2.721,9.412,3.765v3.765H3.765V3.765C3.765,2.721,2.927,1.882,1.883,1.882z M3.765,11.294h5.647v9.412H3.765V11.294z M13.177,11.294h5.646v9.412h-5.646V11.294z M22.588,11.294h5.647v9.412h-5.647V11.294',
    marker_rail: 'M10.105,0L4.211,6.737v11.79v5.053c0,0.934-0.096,1.684,0.842,1.684h21.895c0.934,0,0.842-0.75,0.842-1.684v-5.053V6.737L21.895,0H10.105z M10.947,3.369h10.105v3.368H10.947V3.369z M5.895,8.421h20.211v6.737H5.895V8.421z M8.422,18.526c0.928,0,1.684,0.753,1.684,1.684c0,0.931-0.756,1.684-1.684,1.684c-0.932,0-1.686-0.753-1.686-1.684C6.736,19.28,7.486,18.526,8.422,18.526z M23.58,18.526c0.926,0,1.684,0.753,1.684,1.684c0,0.931-0.758,1.684-1.684,1.684c-0.936,0-1.686-0.753-1.686-1.684C21.895,19.28,22.645,18.526,23.58,18.526z M13.475,26.947H8.422L3.369,32h6.736L13.475,26.947z M18.527,26.947h5.053L28.631,32h-6.736L18.527,26.947',
    marker_religious_christian: 'M16,0c-1.479,0-2.667,1.189-2.667,2.667v6.222H4.444v5.333h8.889V32H16h2.667V14.222h8.889V8.889h-8.889V2.667C18.667,1.189,17.479,0,16,0',
    marker_religious_jewish: 'M16,0l-5.334,7.111H1.777L7.111,16l-5.334,8.889h8.889L16,32l5.334-7.111h8.889L24.889,16l5.334-8.889h-8.889L16,0',
    marker_religious_muslim: 'M15.111,0.889C6.768,0.889,0,7.656,0,16s6.768,15.111,15.111,15.111c4.666,0,8.84-2.122,11.611-5.444c-1.81,1.181-3.955,1.889-6.278,1.889c-6.382,0-11.556-5.174-11.556-11.556S14.063,4.444,20.444,4.444c2.323,0,4.469,0.708,6.278,1.889C23.951,3.011,19.777,0.889,15.111,0.889z M24,8l-1.777,5.333H16l5.333,3.556l-2.666,6.223L24,19.556l5.333,3.556l-2.666-6.223L32,13.333h-6.223L24,8',
    marker_restaurant: 'M7.578,0C6.736,0,6.736,0.842,6.736,0.842v10.105c0,0.842,0.842,1.685,1.686,2.526C9.264,14.315,9.264,15.158,9.264,16c0,0,0,1.685,0,2.526S8.422,29.474,8.422,29.474C8.355,30.313,9.264,32,10.947,32s2.592-1.685,2.527-2.526c0,0-0.844-10.105-0.844-10.947s0-2.526,0-2.526c0-0.842,0-1.685,0.844-2.526c0.775-0.774,1.684-1.685,1.684-2.526V0.842c0,0,0-0.842-0.842-0.842s-0.842,0.842-0.842,0.842v7.579c0,0,0,0.842-0.844,0.842c-0.842,0-0.842-0.842-0.842-0.842V0.842c0,0,0-0.842-0.842-0.842s-0.842,0.842-0.842,0.842v7.579c0,0,0,0.842-0.842,0.842S8.422,8.421,8.422,8.421V0.842C8.422,0.842,8.422,0,7.578,0z M25.264,0c-1.686,0-4.83,0.395-5.895,2.526c-0.844,1.685-0.844,5.895-0.844,7.579v8.421c0,0.842,0.844,1.685,1.686,1.685h1.684v10.104c0,0.843,0.842,1.685,1.684,1.685c0.844,0,1.686-0.842,1.686-1.685V0',
    marker_road_block: 'M16,0C7.165,0,0,7.165,0,16s7.165,16,16,16s16-7.165,16-16S24.835,0,16,0z M5.647,13.177h20.705v5.646H5.647V13.177',
    marker_rocket: 'M32,0h-2c-4,0-9.25,1.25-15,7c-6,6-9,13-9,13c0,2,4,6,6,6c0,0,6.5-2.5,12.5-8.5C30.93,11.072,32,6,32,2V0z M21,8c1.656,0,3,1.344,3,3s-1.344,3-3,3s-3-1.344-3-3S19.344,8,21,8z M2,12l-2,6h5l3.5-6H2z M20,23.5L14,27v5l6-2V23.5',
    marker_school: 'M19.429,2.285c-1.479,0-2.667,1.188-2.667,2.667c0,1.479,1.188,2.666,2.667,2.666s2.667-1.188,2.667-2.666C22.096,3.473,20.908,2.285,19.429,2.285z M7.238,5.333c-1.479,0-2.667,1.188-2.667,2.666c0,1.479,1.188,2.667,2.667,2.667c1.479,0,2.666-1.188,2.666-2.667C9.904,6.521,8.717,5.333,7.238,5.333z M21.333,8.381c-0.568,0-1.503,0.205-2.285,0.762l-4.952,3.523c-1.346,0.955-0.113,2.64,1.143,1.81l3.238-2.143c0,0,2.134,4.577,1.333,6.714c-0.026,0.071-0.051,0.137-0.048,0.19l-3.333,8.857c-0.384,1.018,0.354,1.63,1.095,1.619c0.485-0.006,0.953-0.295,1.19-0.857l4.144-9.81c0,0,0.762,4.571,3.81,4.571c1.83,0,3.175,0,4.19,0C32,23.618,32,22.476,32,22.476s0-1.143-1.143-1.143c-0.762,0-2.667,0-3.429,0c-1.625,0-1.524-2.443-1.524-3.81c0-2.286-1.523-6.096-1.523-6.096l4.571,3.048c1.381,0.92,2.577-0.71,1.238-1.714l-5.048-3.619c-0.919-0.655-1.214-0.762-2.285-0.762C22.857,8.381,22.096,8.381,21.333,8.381z M5.333,11.428c-0.762,0-1.122,0.36-1.523,0.762l-3.238,3.238C0.154,15.845,0,16.22,0,16.762c0,0.762,1.164,1.119,1.81,0.476l2.762-2.762c1.524,0,2.096,3.81,1.524,5.333L3.81,25.904c-0.634,1.688,1.744,2.208,2.286,0.762l2.285-6.095c0,0,0.762,1.523,2.286,1.523h4.571c1.523,0,1.523-2.286,0-2.286h-3.81c-0.762,0-0.762-1.523-0.762-2.285c0-3.048-1.524-6.096-3.048-6.096H5.333',
    marker_scooter: 'M21.333,2.666c0,0-0.889,0-0.889,0.889s0.889,0.889,0.889,0.889H24l1.277,5l-6.166,8.779c-1.219,1.309-1.806,2.221-3.111,2.221h-3.556v-8.889h1.778c0.985,0,1.777-0.791,1.777-1.777S15.208,8,14.223,8H1.777C0.792,8,0,8.791,0,9.777s0.792,1.777,1.777,1.777h0.89c0.493,0,0.889,0.396,0.889,0.889c0,0.494-0.396,0.891-0.889,0.891h-0.89C0.889,13.334,0,14.223,0,15.111V24h16c2.896,0,4.656-1.902,5.777-3.111c1.274-1.371,2.039-2.223,3.111-2.223H32v-0.889c0-0.889-1.444-1.777-1.777-2.666L28.444,8c0.986,0,1.778-0.791,1.778-1.777V4.443c0-0.984-0.792-1.777-1.778-1.777H21.333z M27.556,20.443c-2.455,0-4.444,1.99-4.444,4.445s1.989,4.445,4.444,4.445S32,27.344,32,24.889S30.011,20.443,27.556,20.443z M1.889,25.777c0.413,2.025,2.185,3.557,4.334,3.557c2.148,0,3.92-1.531,4.333-3.557H1.889',
    marker_shells: 'M0,29.472h32c0,0-0.39-1.467-1.029-1.604l-4.662-0.113c5.402-6.907,6.267-8.814,4.489-12.832c-0.361-0.822-0.168-3.125-0.855-4.242c-0.816-1.33-2.166-1.911-2.745-2.403c-0.543-0.464-1.182-2.218-2.521-2.902c-1.547-0.795-2.576-0.327-3.572-0.699c-1.017-0.38-2.194-1.443-3.569-1.919c-0.893-0.308-1.985-0.308-3.063,0.002c-1.406,0.404-2.561,1.539-3.577,1.917C9.903,5.049,8.878,4.581,7.327,5.376C5.984,6.06,5.346,7.814,4.803,8.278c-0.579,0.495-1.929,1.073-2.745,2.403c-0.683,1.118-0.494,3.42-0.855,4.244c-1.776,4.015-0.913,5.922,4.493,12.83l-4.666,0.113C0.39,28.004,0,29.472,0,29.472z M26.031,12.72l0.178,0.06l-4.812,13.117l-0.346-0.126L26.031,12.72z M20.843,6.1l0.197,0.036l-3.851,19.76l-0.386-0.076L20.843,6.1z M11.157,6.1l4.039,19.72l-0.386,0.076l-3.847-19.76L11.157,6.1z M5.96,12.72l4.984,13.05l-0.346,0.126L5.792,12.78L5.96,12.72',
    marker_shop: 'M13.176,0c-1.881,0-3.551,1.894-3.764,3.765L8.766,9.412H5.646c-0.941,0-1.881,0.941-1.881,1.882L1.883,30.118C1.883,31.059,2.824,32,3.766,32h24.469c0.941,0,1.883-0.941,1.883-1.882l-1.883-18.824c0-0.941-0.939-1.882-1.881-1.882h-3.119l-0.646-5.647C22.375,1.894,20.705,0,18.824,0H13.176z M13.176,1.882h5.648c0.941,0,1.775,0.947,1.881,1.882l0.648,5.647H10.646l0.648-5.647C11.4,2.829,12.234,1.882,13.176,1.882',
    marker_skiing: 'M15.158,2.526c-1.685,0-3.369,0.842-3.369,3.369c0,0.904,0.073,1.057,0.264,1.842l1.158,4.79l-6.264,3.842l-5.685-3.316C1.075,12.941,0.829,12.938,0.526,13S0,13.419,0,13.79s0.161,0.585,0.421,0.737l24.421,14.316c0.767,0.444,1.461,0.622,2.105,0.631c0.451,0,0.895-0.059,1.264-0.21c0.891-0.367,1.45-1.03,1.842-1.421c0.197-0.196,0.263-0.435,0.263-0.685c0-0.355-0.217-0.733-0.684-0.789c-0.303-0.035-0.546,0.076-0.737,0.315c-0.395,0.503-0.888,0.878-1.315,1.053c-0.428,0.176-0.898,0.208-1.895-0.368L10.053,18.21l6.053-3.528c0.559-0.383,0.845-1.128,0.684-1.791l-0.684-2.786l6.022,3.546l1.135,4.329c0.132,0.483,0.49,0.903,0.948,1.105l3.842,1.685c2.704,1.186,3.092-1.392,1.372-2.138l-3.162-1.369L23.579,6.739l-5.158-2.948C17.096,3.026,16,2.528,15.158,2.526L15.158,2.526z M28.632,5.895c-1.858,0-3.369,1.51-3.369,3.368s1.511,3.369,3.369,3.369S32,11.122,32,9.263S30.49,5.895,28.632,5.895',
    marker_skins: 'M19.635,3.563c2.852,0.442,6.751,1.417,9.225-0.277c2.777-1.895-5.026,5.889-5.313,13.747c-0.308,8.375,7.051,16.359,3.843,14.596c-4.243-2.335-11.391-0.456-11.391-0.456s-8.229-2.029-11.39,0.456c-2.878,2.264,4.154-6.221,3.847-14.596C8.17,9.174,0.367,1.389,3.14,3.286c2.491,1.705,6.377,0.726,9.233,0.275l0.889-2.416c0.335-0.286,1.554-0.52,1.554-0.52S15.093,0.002,15.999,0c0.846-0.002,1.188,0.625,1.188,0.625s1.22,0.238,1.559,0.52L19.635,3.563',
    marker_slaughterhouse: 'M1.685,5.053l1.684,1.684L0,10.105v1.684l5.053,0.842c0,2.526,4.26,1.14,5.053,5.895v8.421h1.684l1.685-6.737l1.685,6.737h1.684V20.21c3.369,0,5.053-1.684,6.737-1.684c0,0,0.842,2.78,0.842,4.21c0,1.431-0.842,4.21-0.842,4.21h1.684l2.526-6.737l2.526,6.737H32c0,0,0-4.492,0-6.737c0-0.842-1.685-1.684-1.685-2.526c0-2.378,1.685-2.526,1.685-4.21c0-0.479,0-4.21,0-5.052c0-0.842-0.842-1.685-1.685-1.685H8.421L6.737,5.053H1.685',
    marker_soccer: 'M16,0C7.184,0,0,7.184,0,16s7.184,16,16,16s16-7.184,16-16S24.816,0,16,0z M16,2.823c5.019,0,9.364,2.776,11.588,6.883c-2.103,1.394-4.029,2.123-4.059,3.471c-0.019,0.94,0,1.206,0,1.882c0,0.941,0.489,2.154,1.883,2.824l3.294,1.588c-0.375,1.394-0.981,2.654-1.765,3.823l-2.471-1.646c-1.412-0.941-2.823-1.57-3.765-0.941l-2.823,1.882c-1.306,0.872-1.17,1.883-0.941,2.824l0.882,3.646c-0.599,0.081-1.202,0.118-1.823,0.118c-1.305,0-2.577-0.181-3.765-0.529v-3.235c0-1.883-0.941-2.824-1.883-3.765c-0.94-0.941-1.882-1.883-3.765-1.883H3.353C3.004,18.577,2.823,17.305,2.823,16C2.823,8.7,8.698,2.823,16,2.823z M10.353,9.412l-0.94,3.765c-0.269,1.065-0.979,2.169,0,2.823l2.823,1.883c1.882,1.253,2.713,0.525,3.765,0l1.883-0.941c1.404-0.702,1.882-0.941,1.882-2.824v-3.765c0-0.94,0.015-1.501-1.882-1.882l-4.706-0.941C11.206,7.136,10.835,7.485,10.353,9.412',
    marker_square_stroked: 'M1.883,0C0.838,0,0,0.838,0,1.883v28.234C0,31.162,0.838,32,1.883,32h28.234C31.162,32,32,31.162,32,30.117V1.883C32,0.838,31.162,0,30.117,0H1.883z M3.765,3.765h24.471v24.471H3.765V3.765',
    marker_square: 'M1.883,0h28.234C31.158,0,32,0.844,32,1.883v28.234C32,31.158,31.158,32,30.117,32H1.883C0.842,32,0,31.158,0,30.117V1.883C0,0.844,0.842,0,1.883,0',
    marker_star_stroked: 'M16,0l-3.556,10.667H0.889l8.889,8.889L5.333,32L16,24.889L26.667,32l-4.444-12.444l8.889-8.889H19.556L16,0z M16,7.111l1.777,6.222h7.111l-6.222,6.222l2.666,6.222L16,21.333l-5.333,4.444l2.666-6.222l-6.222-6.222h7.111L16,7.111',
    marker_star: 'M16,0l-3.556,10.667H0.889l8.889,8.889L5.333,32L16,24.889L26.666,32l-4.444-12.444l8.89-8.889H20.444L16,0',
    marker_suitcase: 'M30.118,7.529h-7.529V3.765c0-1.042-0.844-1.882-1.882-1.882h-9.412c-1.039,0-1.882,0.841-1.882,1.882v3.765H1.882C0.844,7.529,0,8.371,0,9.412v18.824c0,1.041,0.844,1.882,1.882,1.882h28.235c1.039,0,1.882-0.842,1.882-1.882V9.412C32,8.371,31.156,7.529,30.118,7.529z M7.529,28.235H5.647V9.412h1.882V28.235z M20.706,7.529h-9.412V3.765h9.412V7.529z M26.353,28.235h-1.882V9.412h1.882V28.235',
    marker_swimming: 'M20.941,2.824c-0.298,0-0.816,0.174-1.057,0.293l-7.529,3.648c-1.004,0.473-1.37,1.934-0.706,2.822l2.294,3.119l-8.882,6.881c2.123,0.432,3.717,1.766,5.292,1.766c1.882,0,2.823-1.883,5.647-1.883s3.765,1.883,5.647,1.883c0.776,0,1.57-0.33,2.412-0.707L16.12,9.117l5.704-2.881c1.134-0.566,1.061-1.537,0.941-2.119C22.654,3.57,21.974,2.824,20.941,2.824z M24.471,8.471c-2.081,0-3.765,1.688-3.765,3.766c0,2.076,1.684,3.764,3.765,3.764c2.077,0,3.765-1.688,3.765-3.764C28.235,10.158,26.548,8.471,24.471,8.471z M3.765,23.529c-1.307,0-2.522,0.762-3.765,1.178v3.822c0,0,1.882-1.234,3.765-1.234s3.765,1.881,6.588,1.881c2.823,0,3.713-1.814,5.647-1.881c1.882,0,2.824,1.881,5.647,1.881c2.824,0,4.706-1.881,6.588-1.881c1.32,0,3.765,1.234,3.765,1.234v-3.822c-1.209-0.469-2.529-1.178-3.765-1.178c-2.824,0-4.706,1.883-6.588,1.883S18.824,23.529,16,23.529s-3.765,1.883-5.647,1.883S6.588,23.529,3.765,23.529',
    marker_telephone: 'M21.64,0c-1.967,0-2.609,0.642-3.935,1.967L1.968,17.705c-0.984,0.983-1.967,1.967-1.967,3.934c0,1.639,0,1.968,0,2.951c0,1.967,0.983,2.951,1.967,3.935l1.106,1.106l6.885-6.885l-1.106-1.107c-0.984-0.983,0-1.967,0-1.967L19.672,8.853c0,0,0.983-0.983,1.967,0l1.106,1.106l6.885-6.885c0,0-0.738-0.738-1.106-1.107C27.541,0.983,26.558,0,24.59,0C23.607,0,21.64,0,21.64,0z M30.369,3.812l-6.885,6.885l1.108,1.108c0.695,0.697,2.253,0.697,2.951,0l3.935-3.934c0.695-0.698,0.695-2.254,0-2.951L30.369,3.812z M10.698,23.483l-6.885,6.885l1.108,1.108c0.695,0.698,2.253,0.698,2.951,0l3.935-3.934c0.695-0.697,0.695-2.254,0-2.951L10.698,23.483',
    marker_tennis: 'M11.294,0C5.057,0,0,5.058,0,11.294c0,6.235,5.057,11.294,11.294,11.294h9.412l8.47,8.47C29.772,31.654,30.129,32,30.882,32c0.235,0,0.528,0,0.824-0.294C32.002,31.412,32,31.118,32,30.882c0-0.753-0.41-1.175-0.941-1.706l-8.47-8.47v-9.412C22.588,5.058,17.531,0,11.294,0z M20.706,15.059v5.647h-5.647L20.706,15.059z M2.824,22.588C1.263,22.588,0,23.853,0,25.412c0,1.559,1.263,2.824,2.824,2.824c1.561,0,2.823-1.265,2.823-2.824C5.647,23.853,4.384,22.588,2.824,22.588',
    marker_theatre: 'M2.667,0C1.83,0,0.889,0.889,0.889,1.778v12.444c0,3.939,1.778,8.889,8.889,8.889c0.64,0,1.219-0.031,1.778-0.111v-5.222H4.444c0,0,0.889-3.556,5.333-3.556c0.89,0,1.238,0.132,1.778,0.334v-3.89c0-1.63,0.649-2.992,1.777-3.555c1.778-0.889,2.497-0.53,3.556,0C18.667,8,18.667,8,18.667,8V1.778C18.667,0.889,17.726,0,16.889,0c-1.777,0-3.556,1.778-7.111,1.778C6.223,1.778,4.444,0,2.667,0L2.667,0z M5.777,6.222C7.005,6.222,8,7.218,8,8.444s-0.995,2.222-2.223,2.222c-1.227,0-2.222-0.996-2.222-2.222S4.551,6.222,5.777,6.222z M15.111,8.889c-0.837,0-1.778,0.889-1.778,1.778v12.445c0,3.939,1.778,8.889,8.89,8.889c7.11,0,8.889-4.95,8.889-8.889V10.667c0-0.889-0.941-1.778-1.778-1.778c-1.777,0-3.556,1.778-7.11,1.778C18.667,10.667,16.889,8.889,15.111,8.889z M18.223,15.111c1.227,0,2.222,0.996,2.222,2.222c0,1.226-0.995,2.222-2.222,2.222c-1.228,0-2.223-0.997-2.223-2.222C16,16.107,16.995,15.111,18.223,15.111z M26.223,15.111c1.227,0,2.222,0.996,2.222,2.222c0,1.226-0.995,2.222-2.222,2.222c-1.228,0-2.223-0.997-2.223-2.222C24,16.107,24.995,15.111,26.223,15.111z M16.889,24.889h10.667c0,0-0.889,3.556-5.333,3.556C17.777,28.444,16.889,24.889,16.889,24.889',
    marker_toilets: 'M9.684,0c-2.326,0-4.21,1.885-4.21,4.211c0,2.325,1.885,4.21,4.21,4.21s4.21-1.885,4.21-4.21C13.895,1.885,12.01,0,9.684,0z M23.158,0c-2.326,0-4.21,1.885-4.21,4.211c0,2.325,1.885,4.21,4.21,4.21s4.21-1.885,4.21-4.21C27.368,1.885,25.483,0,23.158,0z M8.842,10.105c-1.684,0-3.368,1.684-3.368,3.368v3.368l-2.526,7.579c0,0.842,0.842,0.842,1.684,0.842h2.526V32h5.053v-6.737h2.526c0.842,0,1.684,0,1.684-0.842l-2.526-7.579v-3.368c0-1.685-1.684-3.368-3.368-3.368H8.842z M18.947,10.105c-0.842,0-1.684,0.842-1.684,1.684l3.368,10.105v3.368V32h5.053v-6.737v-3.368l3.369-10.105c0-0.842-0.842-1.684-1.685-1.684H18.947',
    marker_town_hall: 'M16,0.842L3.369,9.264h25.263L16,0.842z M5.053,10.947c-0.934,0-1.684,0.75-1.684,1.684v11.791l-2.948,5.053C0.171,29.902,0,30.072,0,30.316c0,0.842,1.064,0.842,1.684,0.842h28.632c0.599,0,1.684,0,1.684-0.842c0-0.248-0.145-0.373-0.421-0.842l-2.948-5.053V12.631c0-0.934-0.75-1.684-1.684-1.684H5.053z M8.421,14.316c0.935,0,1.53,0.764,1.685,1.684v10.105H6.737v-3.369V16C6.737,15.066,7.487,14.316,8.421,14.316z M16,14.316c0.934,0,1.699,0.75,1.684,1.684v6.736v3.369h-3.368v-3.369V16C14.316,15.066,15.066,14.316,16,14.316z M23.579,14.316c0.934,0,1.684,0.75,1.684,1.684v10.105h-3.369v-3.369V16C21.895,15.066,22.645,14.316,23.579,14.316',
    marker_town: 'M7.529,3.765L1.883,6.588C0.921,7.07,0,8.471,0,9.412v15.059h5.647v-5.647h3.765v5.647h5.646V9.412c0-0.941-0.944-2.354-1.882-2.823L7.529,3.765z M24.471,7.529l-5.647,2.824c-0.963,0.481-1.882,1.882-1.882,2.823v15.059h5.646v-5.647h3.765v5.647H32V13.176c0-0.941-0.945-2.354-1.883-2.823L24.471,7.529z M1.883,11.294h3.765v1.882H1.883V11.294z M9.412,11.294h3.765v1.882H9.412V11.294z M1.883,15.059h3.765v1.882H1.883V15.059z M9.412,15.059h3.765v1.882H9.412V15.059z M18.823,15.059h3.765v1.882h-3.765V15.059z M26.353,15.059h3.765v1.882h-3.765V15.059z M18.823,18.823h3.765v1.883h-3.765V18.823z M26.353,18.823h3.765v1.883h-3.765V18.823',
    marker_triangle_stroked: 'M16,2.526c-0.842,0-1.224,0.403-1.474,0.842L0.211,26.947C0.063,27.208,0,27.499,0,27.79c0,1.263,0.842,1.684,1.685,1.684h28.631c0.843,0,1.685-0.421,1.685-1.684c0-0.291-0.063-0.582-0.211-0.842L17.474,3.369C17.224,2.929,16.842,2.526,16,2.526z M16,7.579l11.421,18.526H4.579L16,7.579',
    marker_triangle: 'M16,2.526c-0.842,0-1.222,0.403-1.474,0.842L0.211,26.947C0.063,27.207,0,27.498,0,27.789c0,1.264,0.842,1.685,1.685,1.685h28.631c0.843,0,1.685-0.421,1.685-1.685c0-0.291-0.063-0.582-0.211-0.842L17.474,3.368C17.224,2.93,16.842,2.526,16,2.526',
    marker_village: 'M8.18,4.704l-7.82,6.951c-0.868,0.869,0,1.738,0.869,1.738h1.738v8.688h1.737h1.738v-5.214h3.476v5.214h1.737h1.738v-8.688h1.737c0.869,0,1.738-0.869,0.869-1.738L8.18,4.704z M23.82,9.917L16,16.868c-0.869,0.869,0,1.738,0.869,1.738h1.737v8.689h1.738h1.737v-5.214h3.476v5.214h1.738h1.737v-8.689h1.738c0.868,0,1.737-0.869,0.868-1.738L23.82,9.917',
    marker_warehouse: 'M13.474,1.685L0.842,12.632C0.233,13.14,0,13.474,0,13.895c0,0.836,0.842,1.264,1.685,1.264h1.684v13.474c0,0.934,0.75,1.684,1.685,1.684h21.895c0.935,0,1.685-0.75,1.685-1.684V15.158h1.684c0.843,0,1.685-0.428,1.685-1.264c0-0.421-0.233-0.755-0.842-1.263L18.526,1.685H13.474z M10.105,16.842h11.789v0.843H10.105V16.842z M10.105,20.211h11.789v0.842H10.105V20.211z M10.105,23.579h11.789v0.842H10.105V23.579z M10.105,26.947h11.789v0.843H10.105V26.947',
    marker_waste_basket: 'M12.631,0c-0.467,0-0.842,0.376-0.842,0.842v2.526H5.053c-0.934,0-1.684,0.75-1.684,1.684c0,0.933,0.75,1.684,1.684,1.684h21.895c0.934,0,1.684-0.751,1.684-1.684c0-0.934-0.75-1.684-1.684-1.684h-6.736V0.842C20.211,0.376,19.836,0,19.369,0H12.631z M13.475,1.684h5.053v1.685h-5.053V1.684z M5.053,8.421c-0.934,0-1.813,0.765-1.658,1.685l3.342,20.21C6.889,31.237,7.486,32,8.422,32H23.58c0.934,0,1.529-0.763,1.684-1.684l3.342-20.21c0.15-0.919-0.725-1.685-1.658-1.685H5.053',
    marker_water: 'M27.556,20.444C27.556,14.223,18.667,3.556,16,0C13.333,3.556,4.444,14.223,4.444,20.444C4.444,26.667,10.261,32,16,32S27.556,26.667,27.556,20.444',
    marker_wetland: 'M24.471,2.824c-3.765,0-6.772,3.764-7.529,7.529l-2.118,10.471c0.354-0.074,0.747-0.117,1.177-0.117c2.823,0,3.765,1.881,5.647,1.881c0.371,0,0.738-0.059,1.117-0.176c0.425-2.111,1.412-6.828,1.706-8.295c0.36-1.791,1.882-3.764,3.765-3.764h1.882c0-0.941-0.94-1.883-1.882-1.883h-1.883c-1.563,0-3.015,0.578-4.059,1.412l0.294-1.412c0.372-1.846,1.883-3.764,3.765-3.764h1.883c0-0.941-0.941-1.883-1.883-1.883H24.471L24.471,2.824z M3.765,8.471c-0.941,0-1.882,0.941-1.882,1.883h1.882c1.883,0,3.404,1.973,3.765,3.764c0.287,1.424,1.246,6.029,1.706,8.295c0.379,0.117,0.746,0.176,1.117,0.176c1.066,0,1.85-0.592,2.824-1.117l-1.883-9.234c-0.379-1.883-2.823-3.766-5.646-3.766H3.765L3.765,8.471z M3.765,23.529c-1.305,0-2.522,0.762-3.765,1.178v3.822c0,0,1.883-1.234,3.765-1.234c1.883,0,3.765,1.881,6.588,1.881c2.824,0,3.716-1.814,5.647-1.881c1.883,0,2.823,1.881,5.647,1.881c2.823,0,4.705-1.881,6.588-1.881c1.319,0,3.765,1.234,3.765,1.234v-3.822c-1.208-0.467-2.529-1.178-3.765-1.178c-2.823,0-4.706,1.883-6.588,1.883c-1.883,0-2.824-1.883-5.647-1.883s-3.765,1.883-5.647,1.883C8.471,25.412,6.588,23.529,3.765,23.529',
};
export default markerSvgPaths;
