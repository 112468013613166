import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import BusComponent from '../BusComponent';
import DataVariablesList from './dataVariables/DataVariablesList';
import Loader from '../Loader';
import Upgrade from '../upgrade/Upgrade';
import SimpleDropdown from '../dropdown/SimpleDropdown';
import classnames from 'classnames';

class DataBrowserBySurvey extends BusComponent {
    constructor(props, context) {
        super(props, context);

        const datasets = props.currentMetadataSelection.survey.datasetsAsArray.filter(ds => (
            props.mapsGroupAvailableDatasets[props.currentMetadataSelection.survey.name].indexOf(ds.abbrevation) !== -1
        ));
        this.state = {
            hasData: false,
            selectedSurvey: props.currentMetadataSelection.survey,
            datasets,
            selectedDataset: props.currentMetadataSelection.dataset,
            tables: [],
            selectedTable: undefined,
            shouldScrollToSelected: true,
        };

        this.bindGluBusEvents({
            DATASET_LOAD_SUCCESS: this.onDatasetLoadSuccess,
            TABLE_LOAD_SUCCESS: this.onTableLoadSuccess,
        });
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.mapInstanceId !== nextProps.mapInstanceId) {
            const datasets = nextProps.currentMetadataSelection.survey.datasetsAsArray.filter(ds => (
                nextProps.mapsGroupAvailableDatasets[nextProps.currentMetadataSelection.survey.name].indexOf(ds.abbrevation) !== -1
            ));
            this.setState({
                hasData: false,
                selectedSurvey: nextProps.currentMetadataSelection.survey,
                datasets,
                selectedDataset: nextProps.currentMetadataSelection.dataset,
                tables: [],
                selectedTable: undefined,
            });
             // selected dataset may not be fully loaded with all tables so we must load it
            this.emit('DATASET_LOAD_REQUEST', {
                surveyName: nextProps.currentMetadataSelection.survey.name,
                datasetName: nextProps.currentMetadataSelection.dataset.abbrevation,
                mapInstanceId: nextProps.mapInstanceId,
                source: this,
            });
        }
    }

    componentDidMount() {
        // selected dataset may not be fully loaded with all tables so we must load it
        this.emit('DATASET_LOAD_REQUEST', {
            surveyName: this.state.selectedSurvey.name,
            datasetName: this.state.selectedDataset.abbrevation,
            mapInstanceId: this.props.mapInstanceId,
            source: this,
        });
        const eventValue = `${this.state.selectedSurvey.name}|${this.state.selectedDataset.abbrevation}`;
        this.emit('COUNTER_LOG_REQUEST',
            [{
                event_type: 'search_regular',
                event_value: eventValue,
            }, {
                event_type: 'search_platform',
                event_value: eventValue }]);
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    onDatasetLoadSuccess(eventMap) {
        const dataset = eventMap.dataset;
        const datasetTables = dataset.tablesAsArray;
        let selectedTable = datasetTables[0];
        // if selection is from this dataset show selected table
        if (this.props.currentMetadataSelection.survey.name === this.state.selectedSurvey.name &&
            this.props.currentMetadataSelection.dataset.abbrevation === dataset.abbrevation) {
            selectedTable = this.props.currentMetadataSelection.table;
        }
        this.setState({
            selectedDataset: dataset,
            tables: datasetTables,
            selectedTable,
        });
        // table may not be fully loaded so we must load it
        this.emit('TABLE_LOAD_REQUEST', {
            surveyName: this.state.selectedSurvey.name,
            datasetName: dataset.abbrevation,
            tableName: selectedTable.name,
            mapInstanceId: this.props.mapInstanceId,
            source: this,
        });
    }

    onTableLoadSuccess(eventMap) {
        this.setState({
            selectedTable: eventMap.table,
            filterInputText: '',
            hasData: true,
        }, this.scrollToSelectedVariable);
    }


    onSelectedSurveyChanged = newSurveyName => {
        const selectedSurvey = this.props.mapsGroupSurveys.find(survey => survey.name === newSurveyName);
        const datasets = selectedSurvey.datasetsAsArray.filter(ds => (
            this.props.mapsGroupAvailableDatasets[selectedSurvey.name].indexOf(ds.abbrevation) !== -1
        ));
        const selectedDataset = datasets[0];

        // show loader
        this.setState({
            selectedSurvey,
            datasets,
            selectedDataset,
            hasData: false,
            shouldScrollToSelected: true,
        });

        this.emit('DATASET_LOAD_REQUEST', {
            surveyName: selectedSurvey.name,
            datasetName: selectedDataset.abbrevation,
            mapInstanceId: this.props.mapInstanceId,
            source: this,
        });
        const eventValue = `${selectedSurvey.name}|${selectedDataset.abbrevation}`;
        this.emit('COUNTER_LOG_REQUEST',
            [{
                event_type: 'search_regular',
                event_value: eventValue,
            }, {
                event_type: 'search_platform',
                event_value: eventValue }]);
    }

    onSelectedDatasetChanged = newDatasetAbbrevation => {
        // show loader
        this.setState({
            hasData: false,
            shouldScrollToSelected: true,
        });
        // load dataset
        this.emit('DATASET_LOAD_REQUEST', {
            surveyName: this.state.selectedSurvey.name,
            datasetName: newDatasetAbbrevation,
            mapInstanceId: this.props.mapInstanceId,
            source: this,
        });
        const eventValue = `${this.state.selectedSurvey.name}|${newDatasetAbbrevation}`;
        this.emit('COUNTER_LOG_REQUEST',
            [{
                event_type: 'search_regular',
                event_value: eventValue,
            }, {
                event_type: 'search_platform',
                event_value: eventValue }]);
    }

    onSelectedTableChanged = newTableName => {
        if (newTableName !== this.state.selectedTable.name) {
            this.setState({
                hasData: false,
                shouldScrollToSelected: true,
            });
            // load table
            this.emit('TABLE_LOAD_REQUEST', {
                surveyName: this.state.selectedSurvey.name,
                datasetName: this.state.selectedDataset.abbrevation,
                tableName: newTableName,
                mapInstanceId: this.props.mapInstanceId,
                source: this,
            });
            const eventValue = `${this.state.selectedSurvey.name}|${this.state.selectedDataset.abbrevation}|${newTableName}`;
            this.emit('COUNTER_LOG_REQUEST',
                [{
                    event_type: 'search_regular',
                    event_value: eventValue,
                }, {
                    event_type: 'search_platform',
                    event_value: eventValue }]);
        }
    }

    renderSelectedTable() {
        if (this.state.selectedTable) {
            return (<DataVariablesList
                className={classnames({ disabled: !this.state.selectedTable.availableToUser })}
                key="variables"
                selectedVariableRef={selectedVariable => { this.selectedVariableRef = selectedVariable; }}
                variables={this.state.selectedTable.variablesAsArray}
                mapInstanceId={this.props.mapInstanceId}
                hasDataFilter={this.props.hasDataFilter}
                currentMetadataSelection={this.props.currentMetadataSelection}
            />);
        }
        return null;
    }

    renderProLink() {
        if (this.state.selectedTable && !this.state.selectedTable.availableToUser) {
            return (<Upgrade key="upgrade" className="flex-fixed" />);
        }
        return null;
    }

    render() {
        const { intl, mapInstanceId, mapsGroupSurveys, className } = this.props;
        const classes = classnames('data-survey-browser flex-it column stretch justify-center', className);
        if (!this.state.hasData) {
            return (<div className={classes}>
                <Loader text={intl.formatMessage({ id: 'dataBrowser.gettingSurveyDetails' })} />
            </div>);
        }

        return (<div className={classes}>
            <div className="data-survey-browser__selection no-shrink">
                <div className="flex-it center">
                    <div className="flex-it column stretch data-survey-browser__selection-item">
                        <SimpleDropdown
                            title={intl.formatMessage({ id: 'source' })}
                            className="simple-dropdown--metadata-selection"
                            onItemClick={this.onSelectedSurveyChanged}
                            mapInstanceId={mapInstanceId}
                            items={mapsGroupSurveys.map(s => ({ id: s.name, text: s.displayName }))}
                            selectedItem={{ id: this.state.selectedSurvey.name, text: this.state.selectedSurvey.displayName }}
                            showFilter
                            filterPlaceholder={intl.formatMessage({ id: 'dataBrowser.searchSource' })}
                        />
                    </div>
                    <div className="flex-it column stretch data-survey-browser__selection-item">
                        <SimpleDropdown
                            title={intl.formatMessage({ id: 'dataset' })}
                            className="simple-dropdown--metadata-selection"
                            onItemClick={this.onSelectedDatasetChanged}
                            mapInstanceId={mapInstanceId}
                            items={this.state.datasets.map(d => ({ id: d.abbrevation, text: d.displayName }))}
                            selectedItem={{ id: this.state.selectedDataset.abbrevation, text: this.state.selectedDataset.displayName }}
                        />
                    </div>
                </div>
                <div className="flex-it column stretch data-survey-browser__selection-item">
                    <SimpleDropdown
                        title={intl.formatMessage({ id: 'table' })}
                        className="simple-dropdown--metadata-selection simple-dropdown--metadata-selection--long"
                        onItemClick={this.onSelectedTableChanged}
                        mapInstanceId={mapInstanceId}
                        items={this.state.tables.map(t => ({ id: t.name, text: `${t.displayName}-${t.title}` }))}
                        selectedItem={this.state.selectedTable ? {
                            id: this.state.selectedTable.name,
                            text: `${this.state.selectedTable.displayName}-${this.state.selectedTable.title}`,
                        } : { id: undefined, text: 'Select table' }}
                        showFilter
                        filterPlaceholder="Search table"
                    />
                </div>
            </div>
            {this.renderProLink()}
            <div
                ref={variablesList => { this.variablesListRef = variablesList; }}
                className="data-survey-browser__variables grow flex-it column stretch vertical-scrollbar"
            >
                {this.renderSelectedTable()}
            </div>
        </div>);
    }

    scrollToSelectedVariable() {
        if (this.state.shouldScrollToSelected) {
            this.setState({
                shouldScrollToSelected: false,
            });
            window.requestAnimationFrame(() => {
                if (this.variablesListRef && this.selectedVariableRef) {
                    this.variablesListRef.scrollTop = this.selectedVariableRef.offsetTop - (this.variablesListRef.clientHeight / 2);
                }
            });
        }
    }

}

DataBrowserBySurvey.propTypes = {
    className: PropTypes.string,
    mapInstanceId: PropTypes.string.isRequired,
    currentMetadataSelection: PropTypes.object.isRequired,
    mapsGroupAvailableDatasets: PropTypes.object.isRequired,
    mapsGroupSurveys: PropTypes.array.isRequired,
    intl: PropTypes.object.isRequired,
};

DataBrowserBySurvey.defaultProps = {
    className: '',
};

export default injectIntl(DataBrowserBySurvey);
