import React from 'react';
import PropTypes from 'prop-types';
import AppConfig from '../../appConfig';
import { startTourButtonEvents } from '../../enums/GoogleTagManagerEvents';
import googleTagManagerEvents from '../../helpers/GoogleTagManagerEventsWrapper';

const StartTourButton = googleTagManagerEvents('button', startTourButtonEvents);

class HelpTourWelcome extends React.Component {
    onTourStartClick = () => {
        this.props.onTourStart();
    }

    onClose = () => {
        this.props.onClose();
    }

    render() {
        return (
            <div
                className="flex-it column help-tour-welcome"
                ref={this.props.helpTourRef}
            >
                <div className="flex-it flex-end padded-top-20 padded-right-20 help-tour-welcome-header">
                    <button className="clickable" onClick={this.onClose}>
                        <i className="material-icons">close</i>
                    </button>
                </div>
                <div className="flex-it grow space-between column help-tour-welcome-content">
                    <div className="flex-it justify-center">
                        <img
                            src={`${AppConfig.constants.assetsBaseURL}/svg/take-a-tour.svg`}
                            alt="take-a-tour"
                        />
                    </div>
                    <div className="flex-it justify-center text-center">
                        <h3>Welcome to {AppConfig.constants.applicationName}</h3>
                    </div>
                    <div className="text-center">
                        Our mapping application offers easy and dynamic ways to visualize and analyze a wealth of data.
                        We&apos;d love to show you around! It’ll only take a minute or two.
                    </div>
                    <div className="flex-it justify-center">
                        <StartTourButton
                            className="btn-raised"
                            onClick={this.onTourStartClick}
                        >
                            START TOUR
                        </StartTourButton>
                    </div>
                </div>
            </div>
        );
    }
}

HelpTourWelcome.propTypes = {
    onClose: PropTypes.func.isRequired,
    onTourStart: PropTypes.func.isRequired,
    helpTourRef: PropTypes.func,
};

HelpTourWelcome.defaultProps = {
    helpTourRef: undefined,
};

export default HelpTourWelcome;
