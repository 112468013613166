/* global chroma */
import React from 'react';
import PropTypes from 'prop-types';

import { convertNumberToColor } from '../../helpers/Util';

import ColorItem from './ColorItem';

const parseToHex = value => {
    try {
        return chroma(value).hex();
    } catch (error) {
        return undefined;
    }
};

// We'll keep the colors in int format for memory preservation
const COLORS = [
    0xe6b8af, 0xf4cccc, 0xfce5cd, 0xfff2cc, 0xd9ead3, 0xd0e0e3, 0xc9daf8, 0xcfe2f3, 0xd9d2e9, 0xead1dc,
    0xd4d4d4, 0xdd7e6b, 0xea9999, 0xf9cb9c, 0xffe599, 0xb6d7a8, 0xa2c4c9, 0xa4c2f4, 0x9fc5e8, 0xb4a7d6, 0xd5a6bd,
    0xa8a8a8, 0xcc4125, 0xe06666, 0xf6b26b, 0xffd966, 0x93c47d, 0x76a5af, 0x6d9eeb, 0x6fa8dc, 0x8e7cc3, 0xc27ba0,
    0x7c7c7c, 0xa61c00, 0xcc0000, 0xe69138, 0xf1c232, 0x6aa84f, 0x45818e, 0x3c78d8, 0x3d85c6, 0x674ea7, 0xa64d79,
    0x545454, 0x85200c, 0x990000, 0xb45f06, 0xbf9000, 0x38761d, 0x134f5c, 0x1155cc, 0x0b5394, 0x351c75, 0x741b47,
    0x282828, 0x5b0f00, 0x660000, 0x783f04, 0x7f6000, 0x274e13, 0x0c343d, 0x1c4587, 0x073763, 0x20124d, 0x4c1130,
    0x000000, 0xF5F5F5,
];

class ColorPickerCompact extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.color,
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ value: nextProps.color });
    }

    onColorClick = color => {
        this.props.onChange(color);
    };

    onInputChange = e => {
        this.setState({ value: e.target.value });
    }

    onInputBlur = e => {
        const { value } = e.target;
        const parsed = parseToHex(value);
        if (parsed) {
            this.props.onChange(parsed);
        } else {
            // this.setState();
        }
    }

    onInputKeyUp = e => {
        if (e.key.toLowerCase() === 'enter') {
            this.onInputBlur(e);
        }
    }

    renderColors() {
        const colors = COLORS.map(color => {
            const value = convertNumberToColor(color);
            return <ColorItem key={color} value={value} selected={this.props.color === value} onClick={this.onColorClick} />;
        });
        return (
            <div className="color-picker-compact__colors">
                {colors}
            </div>
        );
    }

    renderInput() {
        return (
            <div className="color-picker-compact__input">
                <input type="text" value={this.state.value} onChange={this.onInputChange} onBlur={this.onInputBlur} onKeyUp={this.onInputKeyUp} />
            </div>
        );
    }

    render() {
        return (<div className="color-picker-compact">
            {this.renderColors()}
            {this.renderInput()}
        </div>);
    }
}

ColorPickerCompact.propTypes = {
    onChange: PropTypes.func.isRequired,
    color: PropTypes.string.isRequired,
};

export default ColorPickerCompact;
