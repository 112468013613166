import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import BusComponent from '../BusComponent';
import TextInput from '../form/TextInput';
import googleTagManagerEvents from '../../helpers/GoogleTagManagerEventsWrapper';
import { editProjectDetailsButtonEvents } from '../../enums/GoogleTagManagerEvents';

const SaveProjectDetailsButton = googleTagManagerEvents('button', editProjectDetailsButtonEvents);

class EditProjectInfoPopup extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            title: '',
            description: '',
        };
        this.bindGluBusEvents({
            EDIT_CURRENT_PROJECT_INFO_SUCCESS: this.onEditCurrentProjectInfoSuccess,
            EDIT_CURRENT_PROJECT_INFO_ERROR: this.onEditCurrentProjectInfoError,
        });
        this.boundOnCurrentProjectRetrieved = this.onCurrentProjectRetrieved;
    }

    componentDidMount() {
        this.bus.on('CURRENT_PROJECT', this.boundOnCurrentProjectRetrieved);
        this.emit('CURRENT_PROJECT_REQUEST', { source: this });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    onCurrentProjectRetrieved = eventMap => {
        if (eventMap.target === this) {
            this.bus.off('CURRENT_PROJECT', this.boundOnCurrentProjectRetrieved);
            this.setState({
                title: eventMap.project.title || '',
                description: eventMap.project.description || '',
            });
        }
    }

    onEditCurrentProjectInfoError(eventMap) {
        this.emit('SAVE_PROJECT_ERROR_POPUP_REQUEST', { error: eventMap.error });
    }

    onEditCurrentProjectInfoSuccess() {
        this.emit('CLOSE_MODAL');
    }

    onSaveProjectInfo = () => {
        this.emit('EDIT_CURRENT_PROJECT_INFO_REQUEST', {
            title: this.state.title,
            description: this.state.description,
        });
    }

    onTitleChanged = value => {
        this.setState({
            title: value,
        });
    }

    onDescriptionChanged = value => {
        this.setState({
            description: value,
        });
    }

    render() {
        return (<div className="edit-project-info-content flex-item flex-column">
            <FormattedMessage id="title" />
            <TextInput
                initialFocus
                value={this.state.title}
                onChange={this.onTitleChanged}
                onEnter={this.onSaveProjectInfo}
            />
            <FormattedMessage id="description" />
            <TextInput
                value={this.state.description}
                onChange={this.onDescriptionChanged}
                multiline
            />
            <div className="flex-it flex-end">
                <SaveProjectDetailsButton
                    className="btn-raised"
                    onClick={this.onSaveProjectInfo}
                >
                    SAVE
                </SaveProjectDetailsButton>
            </div>
        </div>);
    }
}

export default injectIntl(EditProjectInfoPopup);
