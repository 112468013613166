// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import BusComponent from '../../BusComponent';

import ClassesItemImage from '../ClassesItemImage';
import ClassesItemLabel from '../ClassesItemLabel';

/**
 * @typedef Props
 * @property {import('../../../').LayerLibraryItemMetadataClass} item
 * @property {boolean} interactive
 * @property {string} layerLibraryGroupId
 * @property {string} mapInstanceId
 * @property {boolean} layerVisibility
 *
 * @extends BusComponent<Props>
 */
class LayerLibraryLegendItem extends BusComponent {
    onToggle = () => {
        const {
            item,
            layerLibraryGroupId,
            layerVisibility,
            mapInstanceId,
            interactive,
        } = this.props;

        if (!interactive) return;

        this.emit('UPDATE_LAYER_LIBRARY_LAYER_VISIBILITY', {
            id: item['layer-group-id'],
            groupId: layerLibraryGroupId,
            visible: !layerVisibility,
            mapInstanceId,
        });
    };

    render() {
        const { item, layerVisibility, interactive } = this.props;

        const { type, style, label, svg } = item;
        const containerClasses = classNames('layer-library-legend__item', {
            'layer-library-legend__item--disabled': !layerVisibility,
            'layer-library-legend__item--interactive': interactive,
        });

        return (
            <div className={containerClasses} onClick={this.onToggle}>
                <ClassesItemImage svg={svg} type={type} style={style} />
                <ClassesItemLabel label={label} />
            </div>
        );
    }
}

LayerLibraryLegendItem.propTypes = {
    mapInstanceId: PropTypes.string.isRequired,
    item: PropTypes.object.isRequired,
    layerLibraryGroupId: PropTypes.string.isRequired,
    layerVisibility: PropTypes.bool.isRequired,
    interactive: PropTypes.bool.isRequired,
};

export default LayerLibraryLegendItem;
