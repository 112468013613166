import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import UserAvatar from './UserAvatar';
import HeaderLogo from './HeaderLogo';
import SearchBoxPanel from '../searchBox/SearchBoxPanel';
import LoginButton from './LoginButton';
import SignupButton from './SignupButton';

const ReportEditorApplicationHeader = ({ reportMapInstance, userInfo, intl }) => (
    <header className="application-header">
        <div className="section section--full-width">
            <HeaderLogo />
            {
                // Geo search input is ok in edit mode.
                reportMapInstance && (
                    <SearchBoxPanel
                        mapInstanceIds={[reportMapInstance.id]}
                        isReportEditor
                    />
                )
            }
        </div>
        <div className="section flex-fixed">
            <div className="header-notification">
                {intl.formatMessage({ id: 'header.mapOptionsAreNotAvailableInCurrentMode' })}
            </div>
            <div className="user-info-wrapper">
                {userInfo.isLogged && (
                    <UserAvatar userInfo={userInfo} />
                )}
                {!userInfo.isLogged && <LoginButton />}
                {!userInfo.isLogged && <SignupButton />}
            </div>
        </div>
    </header>
);

ReportEditorApplicationHeader.propTypes = {
    reportMapInstance: PropTypes.object,
    userInfo: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
};

ReportEditorApplicationHeader.defaultProps = {
    reportMapInstance: undefined,
};

export default injectIntl(ReportEditorApplicationHeader);
