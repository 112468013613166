// @ts-check
import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import BusComponent from '../BusComponent';
import Mixpanel, { MIXPANEL_EVENTS, MIXPANEL_LOCATIONS } from '../../helpers/Mixpanel';

/**
 * @typedef Props
 * @property {import('../../objects/MapInstance').default} mapInstance
 * @property {boolean} createButtonDisabled
 * @property {boolean} downloadReport
 * @property {import('react-intl').intlShape} intl
 *
 * @typedef State
 * @property {string} error
 *
 * @extends {BusComponent<Props, State>}
 */

class LocationAnalysisCreateDownloadReportButton extends BusComponent {
    constructor(props, context) {
        super(props, context);

        /** @type {State} */
        this.state = {
            error: undefined,
            selectedReportTopic: undefined,
        };
    }

    componentDidMount() {
        this.bindGluBusEvents({
            CREATE_REPORT_ERROR: this.onCreateReportError,
            DOWNLOAD_REPORT_ERROR: this.onCreateReportError,
            CUSTOM_MAP_SELECTION_REPORT_PARAMS_CHANGE: this.onCustomMapSelectionReportParamsChange,
        });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    /** @param {string} error Error message */
    onCreateReportError = error => {
        this.setState({
            error,
        });
    };

    onCancel = () => {
        this.emit('CLOSE_LOCATION_ANALYSIS_REQUEST', { mapInstanceId: this.props.mapInstance.id });
    };

    onCreateReport = () => {
        this.emit('CREATE_CUSTOM_MAP_SELECTION_REPORT_REQUEST', {
            mapInstanceId: this.props.mapInstance.id,
        });
        Mixpanel.emitUserEvent(MIXPANEL_EVENTS.CREATE_REPORT, {
            Location: MIXPANEL_LOCATIONS.accordion,
            'Report Name': this.state.selectedReportTopic,
        });
    };

    onCustomMapSelectionReportParamsChange = (payload) => {
        if (payload && payload.reportParams && payload.reportParams.selectedReportTopic && payload.reportParams.selectedReportTopic.text) {
            this.setState({ selectedReportTopic: payload.reportParams.selectedReportTopic.text });
        }
    };

    render() {
        const { error } = this.state;
        if (error && error.message) return null;

        const { intl, createButtonDisabled, downloadReport } = this.props;

        return (
            <div className="location-analysis__actions">
                <div className="flex-it">
                    <button className="btn-flat margin-right-10" onClick={this.onCancel}>
                        {intl.formatMessage({ id: 'cancel' })}
                    </button>
                    <button
                        className="btn-raised"
                        onClick={this.onCreateReport}
                        disabled={createButtonDisabled}
                    >
                        {intl.formatMessage({
                            id: downloadReport
                                ? 'dataBrowser.downloadReport'
                                : 'dataBrowser.createReport',
                        })}
                    </button>
                </div>
            </div>
        );
    }
}

LocationAnalysisCreateDownloadReportButton.propTypes = {
    mapInstance: PropTypes.object.isRequired,
    createButtonDisabled: PropTypes.bool.isRequired,
    downloadReport: PropTypes.bool.isRequired,
    intl: PropTypes.object.isRequired,
};

LocationAnalysisCreateDownloadReportButton.defaultProps = {};

export default injectIntl(LocationAnalysisCreateDownloadReportButton);
