//      

const createVertexArrayType = require('./vertex_array_type');
const packUint8ToFloat = require('../shaders/encode_attribute').packUint8ToFloat;
const VertexBuffer = require('../gl/vertex_buffer');
const bindDragonflyAttribute = require('./dragonfly_program_configuration').bindDragonflyAttribute; //!

                                                   
                                                                                                                  
                                             
                                                                        
                                                  

                        
                 
                   
                       
 

                       
                     
                  
                            
 

                                       
                                       
 

                                
                                             
                                       
                                                     
                                               
                                                 
                                            
                                        
 

function packColor(color       )                   {
    return [
        packUint8ToFloat(255 * color.r, 255 * color.g),
        packUint8ToFloat(255 * color.b, 255 * color.a)
    ];
}

                  
                     
                 
                                         
                                               
                                                           
                                     
                                      
                                               

                             
                                         
                                                                                              
                                          
                                 
                                  
                                                          
 

class ConstantBinder                   {
                 
                 
                     
                            

    constructor(name        , type        , property        , useIntegerZoom         ) {
        this.name = name;
        this.type = type;
        this.property = property;
        this.useIntegerZoom = useIntegerZoom;
    }

    defines() {
        return [`#define HAS_UNIFORM_u_${this.name}`];
    }
    vertexDragonflyPragmaOverride() { return ''; }
    populatePaintArray() {}
    id() {
        return '';
    }
    setAttributes() {}

    setUniforms(gl                       , program         , layer            , {zoom}                  ) {
        const value = layer.transitionDisabled ? layer.paint[this.property] : layer.getPaintValue(this.property, { zoom: this.useIntegerZoom ? Math.floor(zoom) : zoom });
        if (this.type === 'color') {
            gl.uniform4f(program.uniforms[`u_${this.name}`], value.r, value.g, value.b, value.a);
        } else {
            gl.uniform1f(program.uniforms[`u_${this.name}`], value);
        }
    }
}

class SourceFunctionBinder                   {
                 
                 
                     

    constructor(name        , type        , property        ) {
        this.name = name;
        this.type = type;
        this.property = property;
    }

    id() {
        return '';
    }
    setAttributes() {}
    vertexDragonflyPragmaOverride() { return ''; }
    defines() {
        return [];
    }

    populatePaintArray(layer            ,
                       paintArray             ,
                       statistics                         ,
                       start        ,
                       length        ,
                       feature         ) {
        const value = layer.getPaintValue(this.property, {zoom: 0}, feature);

        if (this.type === 'color') {
            const color = packColor(value);
            for (let i = start; i < length; i++) {
                const struct      = paintArray.get(i);
                struct[`a_${this.name}0`] = color[0];
                struct[`a_${this.name}1`] = color[1];
            }
        } else {
            for (let i = start; i < length; i++) {
                const struct      = paintArray.get(i);
                struct[`a_${this.name}`] = value;
            }

            const stats = statistics[this.property];
            stats.max = Math.max(stats.max, value);
        }
    }

    setUniforms(gl                       , program         ) {
        gl.uniform1f(program.uniforms[`a_${this.name}_t`], 0);
    }
}

class CompositeFunctionBinder                   {
                 
                 
                     
                            
                 

    constructor(name        , type        , property        , useIntegerZoom         , zoom        ) {
        this.name = name;
        this.type = type;
        this.property = property;
        this.useIntegerZoom = useIntegerZoom;
        this.zoom = zoom;
    }
    id() {
        return '';
    }
    setAttributes() {}
    vertexDragonflyPragmaOverride() { return ''; }
    defines() {
        return [];
    }

    populatePaintArray(layer            ,
                       paintArray             ,
                       statistics                         ,
                       start        ,
                       length        ,
                       feature         ) {
        const min = layer.getPaintValue(this.property, {zoom: this.zoom    }, feature);
        const max = layer.getPaintValue(this.property, {zoom: this.zoom + 1}, feature);

        if (this.type === 'color') {
            const minColor = packColor(min);
            const maxColor = packColor(max);
            for (let i = start; i < length; i++) {
                const struct      = paintArray.get(i);
                struct[`a_${this.name}0`] = minColor[0];
                struct[`a_${this.name}1`] = minColor[1];
                struct[`a_${this.name}2`] = maxColor[0];
                struct[`a_${this.name}3`] = maxColor[1];
            }
        } else {
            for (let i = start; i < length; i++) {
                const struct      = paintArray.get(i);
                struct[`a_${this.name}0`] = min;
                struct[`a_${this.name}1`] = max;
            }

            const stats = statistics[this.property];
            stats.max = Math.max(stats.max, min, max);
        }
    }

    setUniforms(gl                       , program         , layer            , {zoom}                  ) {
        const f = layer.getPaintInterpolationFactor(this.property, this.useIntegerZoom ? Math.floor(zoom) : zoom, this.zoom, this.zoom + 1);
        gl.uniform1f(program.uniforms[`a_${this.name}_t`], f);
    }
}

                                              
                                 
                                    
                                       
  

/**
 * ProgramConfiguration contains the logic for binding style layer properties and tile
 * layer feature data into GL program uniforms and vertex attributes.
 *
 * Non-data-driven property values are bound to shader uniforms. Data-driven property
 * values are bound to vertex attributes. In order to support a uniform GLSL syntax over
 * both, [Mapbox GL Shaders](https://github.com/mapbox/mapbox-gl-shaders) defines a `#pragma`
 * abstraction, which ProgramConfiguration is responsible for implementing. At runtime,
 * it examines the attributes of a particular layer, combines this with fixed knowledge
 * about how layers of the particular type are implemented, and determines which uniforms
 * and vertex attributes will be required. It can then substitute the appropriate text
 * into the shader source code, create and link a program, and bind the uniforms and
 * vertex attributes in preparation for drawing.
 *
 * When a vector tile is parsed, this same configuration information is used to
 * populate the attribute buffers needed for data-driven styling using the zoom
 * level and feature property data.
 *
 * @private
 */
class ProgramConfiguration {
                                  
                     
                                 
                                         

                      
                                  
                                                     
                                     

    constructor() {
        this.binders = {};
        this.cacheKey = '';
    }

    static createDynamic(programInterface                  , layer            , zoom        ) {
        const self = new ProgramConfiguration();
        const attributes = [];

        for (const attribute of programInterface.paintAttributes || []) {
            const property = attribute.property;
            const useIntegerZoom = attribute.useIntegerZoom || false;
            const name = attribute.name || property.replace(`${layer.type}-`, '').replace(/-/g, '_');
            const type = layer._paintSpecifications[property].type;

            bindDragonflyAttribute(self, attributes, layer, property, name, type); //!
            if (self.binders[name]) continue; //!

            if (layer.isPaintValueFeatureConstant(property)) {
                self.binders[name] = new ConstantBinder(name, type, property, useIntegerZoom);
                self.cacheKey += `/u_${name}`;
            } else if (layer.isPaintValueZoomConstant(property)) {
                self.binders[name] = new SourceFunctionBinder(name, type, property);
                self.cacheKey += `/a_${name}`;
                attributes.push({
                    name: `a_${name}`,
                    type: 'Float32',
                    components: type === 'color' ? 2 : 1
                });
            } else {
                self.binders[name] = new CompositeFunctionBinder(name, type, property, useIntegerZoom, zoom);
                self.cacheKey += `/z_${name}`;
                attributes.push({
                    name: `a_${name}`,
                    type: 'Float32',
                    components: type === 'color' ? 4 : 2
                });
            }
        }

        self.PaintVertexArray = createVertexArrayType(attributes);
        self.interface = programInterface;
        self.layer = layer;

        return self;
    }

    static createBasicFill() {
        const self = new ProgramConfiguration();

        self.binders.color = new ConstantBinder('color', 'color', 'fill-color', false);
        self.cacheKey += `/u_color`;

        self.binders.opacity = new ConstantBinder('opacity', 'number', 'fill-opacity', false);
        self.cacheKey += `/u_opacity`;

        return self;
    }

    // Since this object is accessed frequently during populatePaintArray, it
    // is helpful to initialize it ahead of time to avoid recalculating
    // 'hidden class' optimizations to take effect
    createPaintPropertyStatistics() {
        const paintPropertyStatistics                          = {};
        for (const name in this.binders) {
            paintPropertyStatistics[this.binders[name].property] = {
                max: -Infinity
            };
        }
        return paintPropertyStatistics;
    }

    populatePaintArray(length        , feature         ) {
        const paintArray = this.paintVertexArray;
        if (paintArray.bytesPerElement === 0) return;

        const start = paintArray.length;
        paintArray.resize(length);

        for (const name in this.binders) {
            this.binders[name].populatePaintArray(
                this.layer, paintArray,
                this.paintPropertyStatistics,
                start, length,
                feature);
        }
    }

    defines()                {
        const result = [];
        for (const name in this.binders) {
            result.push.apply(result, this.binders[name].defines());
        }
        return result;
    }

    setUniforms(gl                       , program         , layer            , globalProperties                  ) {
        for (const name in this.binders) {
            this.binders[name].setUniforms(gl, program, layer, globalProperties);
        }
    }

    serialize(transferables                      )                                  {
        if (this.paintVertexArray.length === 0) {
            return null;
        }
        return {
            array: this.paintVertexArray.serialize(transferables),
            type: this.paintVertexArray.constructor.serialize(),
            statistics: this.paintPropertyStatistics
        };
    }

    static deserialize(programInterface                  , layer            , zoom        , serialized                                 ) {
        const self = ProgramConfiguration.createDynamic(programInterface, layer, zoom);
        if (serialized) {
            self.PaintVertexArray = createVertexArrayType(serialized.type.members);
            self.paintVertexArray = new self.PaintVertexArray(serialized.array);
            self.paintPropertyStatistics = serialized.statistics;
        }
        return self;
    }

    upload(gl                       ) {
        if (this.paintVertexArray) {
            this.paintVertexBuffer = new VertexBuffer(gl, this.paintVertexArray);
        }
    }

    destroy() {
        if (this.paintVertexBuffer) {
            this.paintVertexBuffer.destroy();
        }
    }
}

class ProgramConfigurationSet {
                                                            

    constructor(programInterface                  , layers                            , zoom        , arrays                                               ) {
        this.programConfigurations = {};
        if (arrays) {
            for (const layer of layers) {
                this.programConfigurations[layer.id] = ProgramConfiguration.deserialize(programInterface, layer, zoom, arrays[layer.id]);
            }
        } else {
            for (const layer of layers) {
                const programConfiguration = ProgramConfiguration.createDynamic(programInterface, layer, zoom);
                programConfiguration.paintVertexArray = new programConfiguration.PaintVertexArray();
                programConfiguration.paintPropertyStatistics = programConfiguration.createPaintPropertyStatistics();
                this.programConfigurations[layer.id] = programConfiguration;
            }
        }
    }

    populatePaintArrays(length        , feature         ) {
        for (const key in this.programConfigurations) {
            this.programConfigurations[key].populatePaintArray(length, feature);
        }
    }

    serialize(transferables                      ) {
        const result = {};
        for (const layerId in this.programConfigurations) {
            const serialized = this.programConfigurations[layerId].serialize(transferables);
            if (!serialized) continue;
            result[layerId] = serialized;
        }
        return result;
    }

    get(layerId        ) {
        return this.programConfigurations[layerId];
    }

    upload(gl                       ) {
        for (const layerId in this.programConfigurations) {
            this.programConfigurations[layerId].upload(gl);
        }
    }

    destroy() {
        for (const layerId in this.programConfigurations) {
            this.programConfigurations[layerId].destroy();
        }
    }
}

module.exports = {
    ProgramConfiguration,
    ProgramConfigurationSet
};
