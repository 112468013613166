class Dataset {
    /** @type {number} */
    get id() {
        return this._id;
    }

    set id(id) {
        this._id = id;
    }

    get surveyName() {
        return this._surveyName;
    }

    set surveyName(surveyName) {
        this._surveyName = surveyName;
    }

    get datasetAbbreviation() {
        return this._datasetAbbreviation;
    }

    set datasetAbbreviation(datasetAbbreviation) {
        this._datasetAbbreviation = datasetAbbreviation;
    }

    /** @type {string} */
    get primaryKeyField() {
        return this._primaryKeyField;
    }

    set primaryKeyField(primaryKeyField) {
        this._primaryKeyField = primaryKeyField;
    }

    get geoNameField() {
        return this._geoNameField;
    }

    set geoNameField(geoNameField) {
        this._geoNameField = geoNameField;
    }

    get geoQNameField() {
        return this._geoQNameField;
    }

    set geoQNameField(geoQNameField) {
        this._geoQNameField = geoQNameField;
    }

    get geoTypeName() {
        return this._geoTypeName;
    }

    set geoTypeName(geoTypeName) {
        this._geoTypeName = geoTypeName;
    }

    clone() {
        const dataset = new Dataset();

        dataset.id = this.id;
        dataset.surveyName = this.surveyName;
        dataset.datasetAbbreviation = this.datasetAbbreviation;
        dataset.primaryKeyField = this.primaryKeyField;
        dataset.geoNameField = this.geoNameField;
        dataset.geoQNameField = this.geoQNameField;
        dataset.geoTypeName = this.geoTypeName;

        return dataset;
    }
}

export default Dataset;
