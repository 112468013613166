import React from 'react';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ColorPaletteSample from '../ColorPaletteSample';
import ColorPaletteEditor from '../../../../colorPaletteEditor/ColorPaletteEditor';

class ColorPaletteRow extends React.Component {

    onColorPaletteClick = () => {
        this.props.onColorPaletteClick(this.props.palette);
    };

    onEditPalette = event => {
        this.props.onEditPaletteClick(this.props.palette, event);
    };

    render() {
        const {
            paletteGroup,
            paletteEditable,
            selected,
            palette,
            intl,
        } = this.props;
        const paletteRowClasses = classNames('color-palettes-list__row clickable flex flex-it space-between center', {
            'color-palettes-list__row--selected': selected,
        });
        let editButton;
        if (paletteGroup === 'visualisationType.projectPalettes') {
            if (paletteEditable) {
                editButton = (<button className="btn-flat flex-one" onClick={this.onEditPalette}>
                    {intl.formatMessage({ id: 'edit' }).toUpperCase()}
                </button>);
            }
        }

        const colorPaletteEditorType = ColorPaletteEditor.getEditorType(palette.type);
        const paletteTitleClasses = classNames('color-palettes-list__palette-title ellipsis', colorPaletteEditorType.toLowerCase(), {
            'edit-enabled': paletteGroup === 'Project palettes' && paletteEditable,
        });
        return (<div
            onClick={this.onColorPaletteClick}
            className={paletteRowClasses}
            tabIndex="0"
            role="button"
        >
            <div className="color-palettes-list__wrapper flex-it space-between center grow">
                <div className="flex-it center flex-start grow stretch-height">
                    <ColorPaletteSample colorPalette={palette} />
                    <div className={paletteTitleClasses}>{palette.title}</div>
                </div>
                {editButton}
            </div>
        </div>);
    }
}

ColorPaletteRow.propTypes = {
    onColorPaletteClick: PropTypes.func.isRequired,
    onEditPaletteClick: PropTypes.func,
    intl: PropTypes.object.isRequired,
    paletteGroup: PropTypes.string.isRequired,
    palette: PropTypes.object.isRequired,
    paletteEditable: PropTypes.bool,
    selected: PropTypes.bool,
};

ColorPaletteRow.defaultProps = {
    paletteEditable: true,
    selected: false,
    onEditPaletteClick: undefined,
};

export default injectIntl(ColorPaletteRow);
