class DataSource {
    constructor(values) {
        if (values) {
            Object.keys(values).forEach(k => {
                if (this.constructor.prototype.hasOwnProperty(k)) {
                    this[k] = values[k];
                }
            });
        }
    }

    get id() {
        return this._id;
    }

    set id(id) {
        this._id = id;
    }

    /** @type {string} */
    get layerId() {
        return this._layerId;
    }

    set layerId(layerId) {
        this._layerId = layerId;
    }

    get source() {
        return this._source;
    }

    set source(source) {
        this._source = source;
    }

    get location() {
        return this._location;
    }

    set location(location) {
        this._location = location;
    }

    /**
     * @returns {Object.<string, import('./Dataset').default>}
     */
    get datasets() {
        return this._datasets;
    }

    set datasets(datasets) {
        this._datasets = datasets;
    }
    /**
     * @returns {import('../../..').SummaryLevel}
     */
    get summaryLevel() {
        return this._summaryLevel;
    }

    set summaryLevel(summaryLevel) {
        this._summaryLevel = summaryLevel;
    }

    get datasetsAsArray() {
        if (this.datasets) {
            return Object.keys(this.datasets).map(d => this.datasets[d]);
        }
        return [];
    }

    findDataset(surveyName, datasetAbbreviation) {
        return Object
            .keys(this._datasets)
            .map(datasetId => this._datasets[datasetId])
            .find(dataset => dataset.surveyName === surveyName && dataset.datasetAbbreviation === datasetAbbreviation) || { id: 0 };
    }

    clone() {
        return new DataSource({
            id: this.id,
            layerId: this.layerId,
            source: this.source,
            location: this.location,
            datasets: Object.keys(this.datasets).reduce((datasets, datasetId) => {
                datasets[datasetId] = this.datasets[datasetId].clone();
                return datasets;
            }, {}),
            summaryLevel: this.summaryLevel ? JSON.parse(JSON.stringify(this.summaryLevel)) : undefined,
        });
    }
}

export default DataSource;
