import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Slider from '../../form/Slider';

const BUBBLE_SIZE_SLIDER_SCALE = {
    min: -1,
    step: 0.1,
    max: 5,
};

const BubbleControls = props => {
    let value = props.currentBubbleSizeLog10;
    // We need to make sure that the value doesn't go outside the min/max boundaries
    value = Math.max(value, BUBBLE_SIZE_SLIDER_SCALE.min);
    value = Math.min(value, BUBBLE_SIZE_SLIDER_SCALE.max);

    return (
        <div className="bubble-legend__controls flex-it grow center">
            {props.showLabel && (
                <span className="bubble-legend__controls__label">
                    {props.intl.formatMessage({ id: 'legend.bubbleSize' })}
                </span>
            )}
            <div className="bubble-size-slider flex-it row grow center space-between">
                <Slider
                    className="grow"
                    min={BUBBLE_SIZE_SLIDER_SCALE.min}
                    max={BUBBLE_SIZE_SLIDER_SCALE.max}
                    step={BUBBLE_SIZE_SLIDER_SCALE.step}
                    value={value}
                    onChange={props.onBubbleSizeChange}
                />
            </div>
        </div>
    );
};

BubbleControls.propTypes = {
    currentBubbleSizeLog10: PropTypes.number.isRequired,
    onBubbleSizeChange: PropTypes.func.isRequired,
    showLabel: PropTypes.bool,
    intl: PropTypes.object.isRequired,
};

BubbleControls.defaultProps = {
    showLabel: false,
};

export default injectIntl(BubbleControls);
