import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Menu from './Menu';
import Tooltip from '../Tooltip';

const MenuTrigger = props => {
    const { active, label, placement, icon, onClick, extraSmall, id, className } = props;
    const classes = classnames(className, 'btn-icon', Menu.TRIGGER_CLASS, {
        'btn-icon--small': !extraSmall,
        'btn-icon--extra-small': extraSmall,
        'btn-icon--active': active,
    });

    return (
        <Tooltip
            placement={placement}
            mouseEnterDelay={0.5}
            mouseLeaveDelay={0}
            overlay={label}
        >
            <button id={id} className={classes} onClick={onClick} aria-label={label}>
                {icon}
            </button>
        </Tooltip>
    );
};

MenuTrigger.propTypes = {
    id: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    icon: PropTypes.any.isRequired,
    label: PropTypes.string,
    placement: PropTypes.string,
    active: PropTypes.bool,
    extraSmall: PropTypes.bool,
    className: PropTypes.string,
};

MenuTrigger.defaultProps = {
    id: undefined,
    active: false,
    placement: 'bottom',
    label: undefined,
    extraSmall: false,
    className: undefined,
};

export default MenuTrigger;
