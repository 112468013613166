import BaseHandler from './BaseHandler';
import MapSelectionType from '../../enums/MapSelectionType';

class ReportHandler extends BaseHandler {
    constructor(mapViewer) {
        super(mapViewer);
        this._data = mapViewer.dragonflyMapData;

        this._reportLimit = this.mapInstance.dataTheme.rendering[0].reportLimit;
        this._referentLayer = this._data.reportData.layer;
        // add map selection control
        const mapSelectionLayersIds = this._data.mapInstanceData.rendererData.dataLayers.map(dl => dl.id);
        mapSelectionLayersIds.push(this._referentLayer.id);
        this._data.selectionData.selectionControl.updateSelectionLayers(mapSelectionLayersIds);

        this._reportFeatures = {};
        this._reportSummaryLevels = [];

        this.setGluBusEvents({
            MAP_PREFERRED_SUMMARY_LEVEL_CHANGED: this.onMapPreferredSummaryLevelChanged,
            MAP_SELECTION_END: this.onMapSelectionEnd,
            MAP_DESELECT_REPORT_FEATURE: this.onMapDeselectFeature,
            MAP_CLEAR_SELECTED_REPORT_FEATURES: this.onMapClearSelectedFeatures,
            MAP_SELECTION_IMPORT: this.onMapSelectionImport,
            MAP_MASKING_FILTER_REMOVED: this.removeSelectionDataMaskingFilter,
        });
    }

    get mapDataInfo() {
        const activeDataset = this.mapConfig.getActiveDataset(
            this._data.mapInstanceData.preferredSummaryLevel.id,
            this._data.mapInstanceData.rendererData.activeSurveyName,
            this._data.mapInstanceData.rendererData.activeDatasetAbbrevation,
        );

        return {
            summaryLevel: this._data.mapInstanceData.preferredSummaryLevel,
            dataset: activeDataset,
        };
    }

    removeSelectionDataMaskingFilter() {
        this._data.selectionData.clearAvailableFeaturesFilter();
        this._data.selectionData.layers.forEach(dl => {
            this.map.setFilter(dl.id, dl.filter);
        });
    }

    onMapPreferredSummaryLevelChanged() {
        this._data.reportData.applySummaryLevelUpdate();
        this._referentLayer.filter = this._summaryLevelFilter();

        const mapDataDragonflyLayer = this.map.getLayer(this._referentLayer.id);
        mapDataDragonflyLayer.sourceLayer = this._referentLayer['source-layer'];
        this.map.setLayerZoomRange(this._referentLayer.id, this._referentLayer.minzoom, this._referentLayer.maxzoom);
        this.map.setFilter(this._referentLayer.id, this._referentLayer.filter);
        this.map.style._updateLayer(mapDataDragonflyLayer);
    }

    onMapSelectionImport(eventMap) {
        if (eventMap.mapInstanceId !== this.mapInstanceId) {
            return;
        }
        // get map summary level and dataset info
        const mapDataInfo = this.mapDataInfo;
        // if there is no active summary level ignore selection
        if (!mapDataInfo.summaryLevel || !mapDataInfo.dataset) {
            return;
        }

        if (eventMap.appendToExistingSelection) {
            Object.keys(eventMap.selection)
                  .forEach(summaryLevelId => {
                      if (!this._reportFeatures[summaryLevelId]) {
                          this._reportFeatures[summaryLevelId] = [];
                      }

                      const featuresForSummaryLevelSet = new Set(this._reportFeatures[summaryLevelId].map(f => f.geoFips));
                      eventMap.selection[summaryLevelId].forEach(geo => {
                          if (featuresForSummaryLevelSet.has(geo.fips)) {
                              return;
                          }
                          featuresForSummaryLevelSet.add(geo.fips);
                          this._reportFeatures[summaryLevelId].push({
                              geoFips: geo.fips,
                              geoQName: geo.name,
                              isParentSelection: geo.isParentSelection,
                          });
                      });
                  });
        } else {
            this._reportFeatures = {};

            Object.keys(eventMap.selection).forEach(summaryLevelId => {
                this._reportFeatures[summaryLevelId] = eventMap.selection[summaryLevelId].map(geo => (
                    {
                        geoFips: geo.fips,
                        geoQName: geo.name,
                        isParentSelection: geo.isParentSelection,
                    }
                ));
            });
        }

        // geo excluded features because of report limit
        const limitationIgnoredFeatures = eventMap.excluded.map(geo => ({ geoFips: geo.fips }));

        // refresh report summary levels
        this._reportSummaryLevels = Object.keys(this._reportFeatures).map(slId => (
            this.mapConfig.summaryLevels.find(sl => sl.id === slId)
        ));

        this._drawSelectedFeatures();

        this.emit('MAP_REPORT_SELECTED_FEATURES_CHANGED', {
            mapInstanceId: this.mapInstanceId,
            summaryLevels: this._reportSummaryLevels,
            features: this._reportFeatures,
            limitationIgnoredFeatures,
        });
    }

    onMapSelectionEnd(eventMap) {
        if (eventMap.mapInstanceId !== this.mapInstanceId) {
            return;
        }

        // get map summary level and dataset info
        const mapDataInfo = this.mapDataInfo;
        // if there is no active summary level ignore selection
        if (!mapDataInfo.summaryLevel || !mapDataInfo.dataset) {
            return;
        }
        const activeSummaryLevel = mapDataInfo.summaryLevel;
        const activeDataset = mapDataInfo.dataset;

        const selectionFeatures = {};
        eventMap.features.forEach(feature => {
            if (!feature.properties[activeDataset.primaryKeyField] ||
                !feature.properties[activeDataset.geoQNameField]) {
                return;
            }
            if (!selectionFeatures[feature.properties[activeDataset.primaryKeyField]]) {
                selectionFeatures[feature.properties[activeDataset.primaryKeyField]] = {
                    geoFips: feature.properties[activeDataset.primaryKeyField],
                    geoQName: feature.properties[activeDataset.geoQNameField],
                };
            }
        });

        this._reportFeatures[activeSummaryLevel.id] = this._reportFeatures[activeSummaryLevel.id] || [];

        this._reportFeatures[activeSummaryLevel.id] = this._reportFeatures[activeSummaryLevel.id].filter(feature => {
            if (selectionFeatures[feature.geoFips]) {
                delete selectionFeatures[feature.geoFips];
                return eventMap.selectionType !== MapSelectionType.POINT;
            }
            return true;
        });

        // if amongst new selected features are any from some of the parent selections
        // then deselect whole parent selection
        this._reportFeatures[activeSummaryLevel.id] = this._reportFeatures[activeSummaryLevel.id].filter(feature => {
            if (!feature.isParentSelection) return true;
            const inParentSelection = Object.values(selectionFeatures)
                                            .filter(f => f.geoFips.indexOf(feature.geoFips) === 0);
            if (inParentSelection.length === 0) return true;
            inParentSelection.forEach(f => {
                delete selectionFeatures[f.geoFips];
            });
            return false;
        });

        // push newly selected features to the top
        this._reportFeatures[activeSummaryLevel.id] = [...this._reportFeatures[activeSummaryLevel.id], ...Object.values(selectionFeatures)];

        // limit number of selected features to report limit
        const limitationIgnoredFeatures = this._limitNumberOfSelectedFeatures();

        // remove summary level from report selection if none feature selected
        if (this._reportFeatures[activeSummaryLevel.id].length === 0) {
            delete this._reportFeatures[activeSummaryLevel.id];
        }
        // refresh report summary levels
        this._reportSummaryLevels = Object.keys(this._reportFeatures).map(slId => (
            this.mapConfig.summaryLevels.find(sl => sl.id === slId)
        ));

        this._drawSelectedFeatures();

        this.emit('MAP_REPORT_SELECTED_FEATURES_CHANGED', {
            mapInstanceId: this.mapInstanceId,
            summaryLevels: this._reportSummaryLevels,
            features: this._reportFeatures,
            limitationIgnoredFeatures,
        });
    }

    onMapDeselectFeature(eventMap) {
        if (eventMap.mapInstanceId !== this.mapInstanceId) return;
        const features = this._reportFeatures[eventMap.summaryLevel.id] || [];
        const featureIndex = features.findIndex(f => f.geoFips === eventMap.geoFips);
        if (featureIndex !== -1) {
            features.splice(featureIndex, 1);
        }

        // update selected summary levels
        if (features.length === 0) {
            delete this._reportFeatures[eventMap.summaryLevel.id];
            this._reportSummaryLevels = Object.keys(this._reportFeatures).map(slId => (
                this.mapConfig.summaryLevels.find(sl => sl.id === slId)
            ));
        }

        this._drawSelectedFeatures();

        this.emit('MAP_REPORT_SELECTED_FEATURES_CHANGED', {
            mapInstanceId: this.mapInstanceId,
            summaryLevels: this._reportSummaryLevels,
            features: this._reportFeatures,
            limitationIgnoredFeatures: [],
        });
    }

    onMapClearSelectedFeatures(eventMap) {
        if (eventMap.mapInstanceId !== this.mapInstanceId) return;
        this._reportFeatures = {};
        this._reportSummaryLevels = [];

        this._drawSelectedFeatures();

        this.emit('MAP_REPORT_SELECTED_FEATURES_CHANGED', {
            mapInstanceId: this.mapInstanceId,
            summaryLevels: this._reportSummaryLevels,
            features: this._reportFeatures,
            limitationIgnoredFeatures: [],
        });
    }

    _drawSelectedFeatures() {
        this._referentLayer.filter = this._summaryLevelFilter();
        this.map.setFilter(this._referentLayer.id, this._referentLayer.filter);
    }

    _summaryLevelFilter() {
        const id = this._data.mapInstanceData.preferredSummaryLevel.id;
        const features = this._reportFeatures[id] || [];
        const dataset = this.mapConfig.getActiveDataset(
            id,
            this._data.mapInstanceData.rendererData.activeSurveyName,
            this._data.mapInstanceData.rendererData.activeDatasetAbbrevation,
        );
        const filter = ['any'];
        const geoFipsFilter = ['in', dataset.primaryKeyField];
        // add primary key values to filter
        features.filter(f => !f.isParentSelection).forEach(feature => geoFipsFilter.push(feature.geoFips));
        filter.push(geoFipsFilter);
        // temporary fix since we STILL have datasets with numbers for Geo FIPS
        const geoFipsNumbersFilter = ['in', dataset.primaryKeyField];
        features.filter(f => !f.isParentSelection).forEach(feature => {
            if (typeof feature.geoFips === 'string') {
                geoFipsNumbersFilter.push(Number.parseInt(feature.geoFips, 10));
            }
        });
        // filter.push(geoFipsNumbersFilter);
        // add filter for parent summary level selection
        const parentSelectionGeoFipsFilter = ['starts-with', dataset.primaryKeyField];
        features.filter(f => f.isParentSelection)
                .forEach(feature => parentSelectionGeoFipsFilter.push(feature.geoFips));
        if (parentSelectionGeoFipsFilter.length > 2) {
            filter.push(parentSelectionGeoFipsFilter);
        }
        return filter;
    }

    _limitNumberOfSelectedFeatures() {
        const mapDataInfo = this.mapDataInfo;
        const activeSummaryLevel = mapDataInfo.summaryLevel;
        const featuresCount = Object.values(this._reportFeatures)
                                    .reduce((sum, arr) => sum + arr.length, 0);
        let removedFeatures = [];
        if (featuresCount > this._reportLimit) {
            removedFeatures = this._reportFeatures[activeSummaryLevel.id].splice(this._reportLimit - featuresCount);
        }
        return removedFeatures;
    }
}

export default ReportHandler;
