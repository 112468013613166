'use strict';function createImage(ref, channels, data) {
    var width = ref.width;
    var height = ref.height;
    if (!data) {
        data = new Uint8Array(width * height * channels);
    } else if (data.length !== width * height * channels) {
        throw new RangeError('mismatched image size');
    }
    return {
        width: width,
        height: height,
        data: data
    };
}
function resizeImage(image, ref, channels) {
    var width = ref.width;
    var height = ref.height;
    if (width === image.width && height === image.height) {
        return image;
    }
    var newImage = createImage({
        width: width,
        height: height
    }, channels);
    copyImage(image, newImage, {
        x: 0,
        y: 0
    }, {
        x: 0,
        y: 0
    }, {
        width: Math.min(image.width, width),
        height: Math.min(image.height, height)
    }, channels);
    image.width = width;
    image.height = height;
    image.data = newImage.data;
}
function copyImage(srcImg, dstImg, srcPt, dstPt, size, channels) {
    if (size.width === 0 || size.height === 0) {
        return dstImg;
    }
    if (size.width > srcImg.width || size.height > srcImg.height || srcPt.x > srcImg.width - size.width || srcPt.y > srcImg.height - size.height) {
        throw new RangeError('out of range source coordinates for image copy');
    }
    if (size.width > dstImg.width || size.height > dstImg.height || dstPt.x > dstImg.width - size.width || dstPt.y > dstImg.height - size.height) {
        throw new RangeError('out of range destination coordinates for image copy');
    }
    var srcData = srcImg.data;
    var dstData = dstImg.data;
    for (var y = 0; y < size.height; y++) {
        var srcOffset = ((srcPt.y + y) * srcImg.width + srcPt.x) * channels;
        var dstOffset = ((dstPt.y + y) * dstImg.width + dstPt.x) * channels;
        for (var i = 0; i < size.width * channels; i++) {
            dstData[dstOffset + i] = srcData[srcOffset + i];
        }
    }
    return dstImg;
}
var AlphaImage = function AlphaImage() {
};
AlphaImage.create = function create(size, data) {
    return createImage(size, 1, data);
};
AlphaImage.resize = function resize(image, size) {
    resizeImage(image, size, 1);
};
AlphaImage.copy = function copy(srcImg, dstImg, srcPt, dstPt, size) {
    copyImage(srcImg, dstImg, srcPt, dstPt, size, 1);
};
var RGBAImage = function RGBAImage() {
};
RGBAImage.create = function create(size, data) {
    return createImage(size, 4, data);
};
RGBAImage.resize = function resize(image, size) {
    resizeImage(image, size, 4);
};
RGBAImage.copy = function copy(srcImg, dstImg, srcPt, dstPt, size) {
    copyImage(srcImg, dstImg, srcPt, dstPt, size, 4);
};
module.exports = {
    AlphaImage: AlphaImage,
    RGBAImage: RGBAImage
};