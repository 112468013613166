import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import BusComponent from '../BusComponent';
import ApplicationFrameType from '../../enums/ApplicationFrameType';
import ApplicationMode from '../../enums/ApplicationMode';
import ClearDialog from './ClearDialog';
import EditFilterAndMaskDialog from './EditFilterAndMaskDialog';
import Indicator from './Indicator';

const INDICATOR_OPTIONS = {
    DATA_FILTER: 1,
    MASK: 2,
    MASK_AND_FILTER: 3,
    GEOJSON_FILTER: 4,
};

class FilterAndMaskIndicator extends BusComponent {
    constructor(props, context) {
        super(props, context);

        this.state = {
            clearDialogOption: undefined,
            editFilterAndMaskDialogVisible: false,
            geoJsonFiltersApplied: false,
        };
    }

    componentDidMount() {
        this.bindGluBusEvents({
            MAP_MASKING_FILTER_APPLIED: this.onMaskingFilterUpdated,
            MAP_MASKING_FILTER_REMOVED: this.onMaskingFilterUpdated,
            MAP_DATA_FILTER_APPLIED: this.onMaskingFilterUpdated,
            MAP_DATA_FILTER_REMOVED: this.onMaskingFilterUpdated,
            APPLY_POINTS_FILTERS_REQUEST: this.onApplyPointsFilters,
            HIDE_FILTER_AND_MASK_INDICATOR: this.onHideFilterAndMaskIndicator,
        });
    }

    componentWillReceiveProps() {
        this.setState({ clearDialogOption: undefined });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    onMaskingFilterUpdated(eventMap) {
        if (eventMap.source.id === this.props.mapInstance.id) {
            this.forceUpdate();
        }
    }

    onHideFilterAndMaskIndicator = () => {
        this.setState({
            clearDialogOption: undefined,
            editFilterAndMaskDialogVisible: false,
            geoJsonFiltersApplied: false,
        });
    }

    /*
    Before switching to a new frame it's necessary to close the previous and then open the new one.
    This is handled by the controller of the given frame and then further by FrameContainer.
     */
    handleIndicatorClick = newMode => {
        const { mapInstance, applicationFrameType } = this.props;

        switch (true) {
        // Switching from report editor frame is handled in a special way so that the user is always
        // returned to the report frame after going to mask or filter frame.
        case applicationFrameType === ApplicationFrameType.REPORT_EDITOR:
            this.emit('EXIT_REPORT_MODE_AND_ENTER_NEW_MODE', {
                reportParentMapInstanceId: mapInstance.reportParentMapInstanceId,
                newMode,
            });
            this.emit('EXIT_REPORT_MODE');
            break;
        // If the current application frame is mask editor before the switch is made to a different
        // frame the mask frame must be closed first.
        case applicationFrameType === ApplicationFrameType.MASK_EDITOR && newMode !== 'ENTER_MASK_MODE':
            this.emit('EXIT_MASK_MODE', {
                mapInstance,
                mapInstanceId: mapInstance.id,
                newMode,
            });
            break;
        // If the current application frame is filter editor, before the switch is made to a different
        // frame the filter frame must be closed first.
        case applicationFrameType === ApplicationFrameType.DATA_FILTER_EDITOR && newMode !== 'ENTER_DATA_FILTER_MODE':
            this.emit('EXIT_DATA_FILTER_MODE', {
                mapInstance,
                mapInstanceId: mapInstance.id,
                newMode,
            });
            break;
        case applicationFrameType === ApplicationFrameType.MAP_EDITOR:
            this.emit('HIDE_MAP_LAYERS_EDITOR', {
                mapInstance,
                newMode,
            });
            break;
        case this.state.geoJsonFiltersApplied:
            this.emit('SHOW_POINTS_PANEL');
            break;
        default:
            this.emit(newMode, {
                mapInstance,
                mapInstanceId: mapInstance.id,
            });
        }
    };

    toggleEditMaskandFilteView = () => {
        const { editFilterAndMaskDialogVisible } = this.state;
        this.setState({ editFilterAndMaskDialogVisible: !editFilterAndMaskDialogVisible });
    };

    handleClear = clearDialogOption => {
        this.setState({ clearDialogOption });
    };

    handleClearMask = () => {
        const { mapInstance } = this.props;

        this.emit('REMOVE_MASKING_FILTER_REQUEST', {
            mapInstanceId: mapInstance.id,
            dataGeoFilter: Object.values(mapInstance.dataGeoFilters)[0],
        });
        this.setState({ clearDialogOption: undefined });
    };

    onApplyPointsFilters = () => {
        this.setState({ geoJsonFiltersApplied: true });
    }

    handleClearDataFilter = () => {
        const { mapInstance } = this.props;

        this.emit('REMOVE_DATA_FILTER_REQUEST', {
            mapInstanceId: mapInstance.id,
        });
        this.setState({ clearDialogOption: undefined });
    };

    handleClearGeoJsonFilter = () => {
        this.emit('CLEAR_FACILITY_FILTER_FROM_PANEL');
        this.emit('CLEAR_FACILITY_FILTER');
        this.setState({ clearDialogOption: undefined, geoJsonFiltersApplied: false });
    };

    handleCancelClear = () => {
        this.setState({ clearDialogOption: undefined });
    };

    getOptions = clearDialogOption => {
        const { mapInstance } = this.props;
        const isGeoJsonFilter = this.state.geoJsonFiltersApplied;
        switch (true) {
        case isGeoJsonFilter:
            return {
                text: 'Data filter',
                clearDialogOption: INDICATOR_OPTIONS.GEOJSON_FILTER,
                clearDialogFunction: this.handleClearGeoJsonFilter,
                clickHandle: () => this.handleIndicatorClick('ENTER_DATA_FILTER_MODE'),
                mode: INDICATOR_OPTIONS.GEOJSON_FILTER,
            };
        // Both mask and filter applied
        case mapInstance.hasMaskingFilter && mapInstance.hasDataFilter:
            return {
                text: clearDialogOption === INDICATOR_OPTIONS.MASK ? 'Mask' : 'Filter',
                clearDialogFunction: clearDialogOption === INDICATOR_OPTIONS.MASK ? this.handleClearMask : this.handleClearDataFilter,
                clickHandle: this.toggleEditMaskandFilteView,
                mode: INDICATOR_OPTIONS.MASK_AND_FILTER,
            };
        // Only mask present
        case mapInstance.hasMaskingFilter:
            return {
                text: 'Mask',
                clearDialogOption: INDICATOR_OPTIONS.MASK,
                clearDialogFunction: this.handleClearMask,
                clickHandle: () => this.handleIndicatorClick('ENTER_MASK_MODE'),
                mode: INDICATOR_OPTIONS.MASK,
            };
        // Only data filter present
        case mapInstance.hasDataFilter:
            return {
                text: 'Data filter',
                clearDialogOption: INDICATOR_OPTIONS.DATA_FILTER,
                clearDialogFunction: this.handleClearDataFilter,
                clickHandle: () => this.handleIndicatorClick('ENTER_DATA_FILTER_MODE'),
                mode: INDICATOR_OPTIONS.DATA_FILTER,
            };
        default:
            return null;
        }
    }

    render() {
        const { editFilterAndMaskDialogVisible, clearDialogOption } = this.state;
        const { alignLeft, applicationFrameType } = this.props;
        const { applicationMode, isCondensedLayout } = this.context;

        const options = this.getOptions(clearDialogOption);
        // No masking or filtering or unsupported application mode
        if (applicationMode === ApplicationMode.VIEW || applicationMode === ApplicationMode.EMBED || options === null) return null;

        // If ClearDialog is open
        if (clearDialogOption) {
            return (<ClearDialog
                text={options.text}
                onCancel={this.handleCancelClear}
                onClear={options.clearDialogFunction}
            />);
        }

        // If both mask and data filter are present
        if (options.mode === INDICATOR_OPTIONS.MASK_AND_FILTER) {
            return (
                <div className={classNames('mask-filter-indicator', { 'mask-filter-indicator--left': alignLeft })}>
                    <Indicator
                        isCondensedLayout={isCondensedLayout}
                        text="Filter and Mask"
                        clickHandle={options.clickHandle}
                        filterAndMask
                        editFilterAndMaskDialogVisible={editFilterAndMaskDialogVisible}
                    />
                    {editFilterAndMaskDialogVisible && <EditFilterAndMaskDialog
                        onClearMask={() => this.handleClear(INDICATOR_OPTIONS.MASK)}
                        onEditMask={() => this.handleIndicatorClick('ENTER_MASK_MODE')}
                        onClearFilter={() => this.handleClear(INDICATOR_OPTIONS.DATA_FILTER)}
                        onEditFilter={() => this.handleIndicatorClick('ENTER_DATA_FILTER_MODE')}
                    />}
                </div>
            );
        }
        // Only mask or filter present
        return (<Indicator
            isCondensedLayout={isCondensedLayout}
            isReportEditor={applicationFrameType === ApplicationFrameType.REPORT_EDITOR}
            clickHandle={options.clickHandle}
            text={options.text}
            onClickClear={() => this.handleClear(options.clearDialogOption)}
        />);
    }
}

FilterAndMaskIndicator.propTypes = {
    mapInstance: PropTypes.object.isRequired,
    applicationFrameType: PropTypes.string,
    alignLeft: PropTypes.bool,
};

FilterAndMaskIndicator.defaultProps = {
    applicationFrameType: undefined,
    alignLeft: false,
};

export default FilterAndMaskIndicator;
