// @ts-check
import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Checkbox from '../../form/Checkbox';
import BusComponent from '../../BusComponent';

/**
 * @typedef Props
 * @property {import('../../../objects/UserDataLayer').default} userDataLayer
 * @property {import('react-intl').intlShape} intl
 *
 * @typedef State
 * @property {boolean} includeInLegend
 *
 * @extends {BusComponent<Props, State>}
 */

class LegendOptionsSection extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            includeInLegend: !!this.props.userDataLayer.includeInLegend,
        };
    }

    /** @param {Props} nextProps */
    componentWillReceiveProps(nextProps) {
        this.setState({
            includeInLegend: !!nextProps.userDataLayer.includeInLegend,
        });
    }

    toggleShowOnLegend = () => {
        this.emit('TOGGLE_USER_DATA_UPLOAD_LEGEND_VISIBILITY', {
            id: this.props.userDataLayer.id,
        });
        this.setState(prevState => ({
            includeInLegend: !prevState.includeInLegend,
        }));
    }

    render() {
        const { userDataLayer, intl } = this.props;
        const { isPointVisualization } = userDataLayer;
        return (
            <div className="point-layer-editor__section">
                {intl.formatMessage({ id: 'showInLegend' })}
                {!isPointVisualization && <i>({intl.formatMessage({ id: 'dataBrowser.currentlyNotSupported' })})</i>}
                <Checkbox
                    onCheck={this.toggleShowOnLegend}
                    checked={isPointVisualization && this.state.includeInLegend}
                    disabled={!isPointVisualization}
                />
            </div>
        );
    }
}

LegendOptionsSection.propTypes = {
    intl: PropTypes.object.isRequired,
    userDataLayer: PropTypes.object.isRequired,
};

LegendOptionsSection.defaultProps = {
};

export default injectIntl(LegendOptionsSection);
