// @ts-check
class Symbol {
    constructor(values) {
        if (values) {
            Object.keys(values).forEach(k => {
                if (this.constructor.prototype.hasOwnProperty(k)) {
                    this[k] = values[k];
                }
            });
        }
    }

    /** @returns {import('../../../types').SymbolType} */
    get type() {
        return this._type;
    }

    set type(type) {
        this._type = type;
    }

    /** @returns {import('./Brush').default[]} */
    get brushes() {
        return this._brushes;
    }

    set brushes(brushes) {
        this._brushes = brushes;
    }

    equals(that) {
        return (this.type === that.type &&
        this.brushes.every((brush, index) => brush.equals(that.brushes[index])));
    }

    clone() {
        return new Symbol({
            type: this.type,
            brushes: this.brushes.map(brush => brush.clone()),
        });
    }

    toJSON() {
        return {
            type: this.type,
            brushes: this.brushes.map(brush => brush.toJSON()),
        };
    }
}

export default Symbol;
