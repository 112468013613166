module.exports={
  "location": "Location",
  "hotel": "Hotel",
  "geography": "Geography",
  "facility": "Facility",
  "organization": "Find organization",
  "findFacility": "Find a facility",
  "address": "Address",
  "hotelSearchPlaceholder": "Enter a hotel name or address",
  "organizationSearchPlaceholder": "Enter an organization name or address",
  "findFacilitySearchPlaceholder": "Enter a facility name or address",
  "geographyPointsPlaceholder": "Enter a city, county or zip code",
  "sSCriteriaTriggerText": "Find facilities that match specific criteria",
  "findByCriteria": "Find new opportunities",
  "findByCriteriaDescriptionSs": "I am looking for facilities {chunk} of the following criteria",
  "newCriterion": "New criterion",
  "addCriterion": "Add criterion",
  "loadingFacilities": "Loading facilities...",
  "findFacilities": "Apply filter",
  "criteriaMatch": "{count, plural, one {# hotel} other {# hotels}} matching criteria",
  "criteriaMismatch": "Nothing found. Try changing the criteria.",
  "comparisonLimitExceeded": "Comparison list limit reached",
  "compare": "Compare",
  "analysisList": "Analysis List",
  "comparisonList": "Comparison List",
  "compareInsights": "Compare Insights",
  "downloadData": "Download Data"
}
