import MetaSurvey from '../objects/MetaSurvey';
import MetaDataset from '../objects/MetaDataset';
import MetaTable from '../objects/MetaTable';
import MetaVariable from '../objects/MetaVariable';
import MetaDataCategory from '../objects/MetaDataCategory';
import Filter from '../objects/Filter';
import FilterComparisonType from '../enums/FilterComparisonType';
import FilterSet from '../objects/FilterSet';
import CategoryFilters from '../objects/CategoryFilters';
import MetaGeoType from '../objects/MetaGeoType';
import MetaReport from '../objects/MetaReport';
import Metadata from '../objects/Metadata';
import { parseIntWithDefault } from './Util';

const DEFAULT_FILTERSET_NAME = 'General';

/** Helper class from SE metadata parsing */
class MetadataXMLParser {
    /**
     * Constructs MetadataXMLParser for the given document
     * @constructor
     * @param {XMLDocument} metadataXmlDocument
     */
    constructor(metadataXmlDocument) {
        this.metadataXMLDocument = metadataXmlDocument;
    }

    static _parseAndStripHtml(html) {
        if (html && html.length > 0) {
            // convert breaks to space
            // convert nonbreaking space to space
            // convert all consecutive spaces with single space
            // trim whitespaces
            return html.replace(/<br \/>/g, '').replace(/&nbsp;/g, ' ').replace(/ +/g, ' ').trim();
        }

        return html;
    }

    static _parseArrayOfElements(arrayString) {
        const elements = [];
        if (arrayString) {
            const splitted = arrayString.split(/[,;]/);
            splitted.forEach(element => {
                if (element.length > 0) elements.push(element.trim());
            });
        }

        return elements;
    }


    static _parseFilter(filterNode) {
        const filter = new Filter();

        filter.label = filterNode.getAttribute('label');
        filter.labelFrom = filterNode.getAttribute('labelFrom');
        filter.labelTo = filterNode.getAttribute('labelTo');
        filter.valueFormat = filterNode.getAttribute('valueFormat');

        let value = filterNode.getAttribute('value');
        const from = filterNode.getAttribute('from');
        const to = filterNode.getAttribute('to');
        const inclusiveTo = filterNode.getAttribute('inclusiveTo');

        if ((from && from.length !== 0 && !isNaN(parseFloat(from))) || (to && to.length !== 0 && !isNaN(parseFloat(to)))) {
            filter.from = (from && from.length !== 0) ? parseFloat(from) : -Number.MAX_SAFE_INTEGER;
            filter.to = (to && to.length !== 0) ? parseFloat(to) : Number.MAX_SAFE_INTEGER;
            filter.inclusiveTo = (inclusiveTo && inclusiveTo.length !== 0) ? inclusiveTo.toLowerCase() === 'true' : 'true';
            filter.comparisonType = FilterComparisonType.MATCH_RANGE;
        } else if (!isNaN(parseFloat(value))) {
            filter.valueNum = parseFloat(value);
            filter.comparisonType = FilterComparisonType.MATCH_VALUE_NUM;
        } else if (value === '[NULL]') {
            filter.comparisonType = FilterComparisonType.MATCH_NULL;
        } else if (value.length !== 0) {
            if (value.length >= 2 && value[0] === '\'' && value[value.length - 1] === '\'') {
                value = value.substring(1, value.length - 1);
            }
            filter.valueStr = value;
            filter.comparisonType = FilterComparisonType.MATCH_VALUE_STR;
        } else {
            filter.comparisonType = FilterComparisonType.MATCH_DEFAULT_SYMBOL;
        }
        return filter;
    }

    static _parseCategoryFilters(categoryFiltersNode) {
        const categoryFilters = new CategoryFilters();
        categoryFilters.name = categoryFiltersNode.getAttribute('name');
        categoryFilters.valueFormat = categoryFiltersNode.getAttribute('valueFormat');
        categoryFilters.filterSets = [];

        [...categoryFiltersNode.getElementsByTagName('FilterSet')].forEach(filterSetNode => {
            const filterSet = new FilterSet();
            filterSet.valueFormat = filterSetNode.getAttribute('valueFormat') || '';
            if (filterSet.valueFormat.length === 0) filterSet.valueFormat = categoryFilters.valueFormat;
            filterSet.filters = [...filterSetNode.getElementsByTagName('Filter')].map(filterNode => MetadataXMLParser._parseFilter(filterNode));
            // add filter set only if there are any filters
            if (filterSet.filters.length > 0) categoryFilters.filterSets.push(filterSet);
        });

        return categoryFilters;
    }

    static _parseSystemCategoryFilters(node) {
        const systemCategoryFilters = [];
        [...node.getElementsByTagName('CategoryFilters')].forEach(categoryFiltersNode => {
            const categoryFilters = MetadataXMLParser._parseCategoryFilters(categoryFiltersNode);
            // add category filters only if there are any filter sets in it
            if (categoryFilters.filterSets.length > 0) systemCategoryFilters.push(categoryFilters);
        });

        return systemCategoryFilters;
    }

    static _parseDataCategories(node) {
        const dataCategories = {};
        [...node.getElementsByTagName('Category')].forEach(categoryNode => {
            const metaDataCategory = new MetaDataCategory();
            metaDataCategory.id = categoryNode.getAttribute('Id');
            metaDataCategory.name = categoryNode.getAttribute('Name');
            metaDataCategory.iconUrl = categoryNode.getAttribute('IconUrl');
            if (metaDataCategory.iconUrl && metaDataCategory.iconUrl.startsWith('http://')) metaDataCategory.iconUrl = metaDataCategory.iconUrl.replace('http://', 'https://');
            metaDataCategory.itemOrder = parseInt(categoryNode.getAttribute('ItemOrder'), 10) || undefined;
            if (metaDataCategory.name) {
                dataCategories[metaDataCategory.name] = metaDataCategory;
            }
        });

        return dataCategories;
    }

    static _parseReports(node, survey) {
        const reports = {};
        [...node.getElementsByTagName('Report')].forEach(reportNode => {
            const report = new MetaReport();
            report.id = reportNode.getAttribute('Id');
            report.name = reportNode.getAttribute('Name');
            report.isSystemReport = (reportNode.getAttribute('IsSystemReport') || 'false').toLowerCase() === 'true';
            report.productTags = MetadataXMLParser._parseArrayOfElements(reportNode.getAttribute('ProductTags'));
            report.survey = survey;
            report.index = Object.keys(reports).length;
            reports[report.id] = report;
        });

        return reports;
    }

    static _parseGeoTypes(node, survey) {
        const geoTypes = {};
        [...node.getElementsByTagName('GeoType')].forEach((geoTypeNode, index) => {
            const geoType = new MetaGeoType();
            geoType.name = geoTypeNode.getAttribute('N');
            geoType.pluralName = geoTypeNode.getAttribute('PN');
            geoType.label = geoTypeNode.getAttribute('L');
            geoType.indent = parseInt(geoTypeNode.getAttribute('I'), 10) || undefined;
            geoType.summaryLevel = geoTypeNode.getAttribute('SL');
            geoType.fipsCodeFieldName = geoTypeNode.getAttribute('PFN');
            geoType.fipsCodeLength = parseInt(geoTypeNode.getAttribute('FIPSLen'), 10) || undefined;
            geoType.fipsCodeLengthPartial = parseInt(geoTypeNode.getAttribute('FIPSLenP'), 10) || undefined;
            geoType.survey = survey;
            geoType.index = index;

            geoTypes[geoType.name] = geoType;
        });

        return geoTypes;
    }

    static _parseTable(tableNode, dataset) {
        const metaTable = new MetaTable();
        metaTable.uuid = tableNode.getAttribute('GUID');
        metaTable.surveyName = tableNode.getAttribute('SurveyName');
        metaTable.datasetName = tableNode.getAttribute('DatasetName');
        metaTable.name = tableNode.getAttribute('Name');
        metaTable.displayName = tableNode.getAttribute('DisplayName');
        metaTable.title = tableNode.getAttribute('Title');
        metaTable.titleShort = tableNode.getAttribute('TitleShort');
        metaTable.visible = tableNode.getAttribute('Visible');
        metaTable.universe = tableNode.getAttribute('Universe');
        metaTable.dollarYear = parseInt(tableNode.getAttribute('DollarYear'), 10) || undefined;
        metaTable.notes = tableNode.getAttribute('Notes');
        metaTable.categories = MetadataXMLParser._parseArrayOfElements(tableNode.getAttribute('Categories'));
        metaTable.categoryPriorityOrder = tableNode.getAttribute('CategoryPriorityOrder');
        metaTable.showOnFirstPage = tableNode.getAttribute('ShowOnFirstPage');
        // Set 'General' as defaultFilterSetName if it's not defined, since it's needed for adding cutpoints
        metaTable.defaultFilterSetName = tableNode.getAttribute('DefaultFilterSetName') || DEFAULT_FILTERSET_NAME;
        metaTable.productTags = MetadataXMLParser._parseArrayOfElements(tableNode.getAttribute('ProductTags'));
        metaTable.percentBaseMin = parseInt(tableNode.getAttribute('BaseMin'), 10) || 20;
        metaTable.suggestedColorPaletteName = tableNode.getAttribute('PaletteName');
        metaTable.suggestedColorPaletteType = tableNode.getAttribute('PaletteType');
        metaTable.suggestedColorPaletteInverse = (tableNode.getAttribute('PaletteInverse') || 'false').toLowerCase() === 'true';
        metaTable.tableMapInfo = tableNode.getAttribute('TableMapInfo');
        metaTable.dataset = dataset;
        metaTable.variables = {};
        [...tableNode.getElementsByTagName('Variable')].forEach(variableNode => {
            const metaVariable = new MetaVariable();
            metaVariable.uuid = variableNode.getAttribute('GUID');
            metaVariable.name = variableNode.getAttribute('Name');
            metaVariable.label = variableNode.getAttribute('Label');
            if (metaVariable.label.endsWith(':')) {
                metaVariable.label = metaVariable.label.slice(0, -1);
            }
            metaVariable.qLabel = MetadataXMLParser._parseAndStripHtml(variableNode.getAttribute('QLabel'));
            metaVariable.dataType = variableNode.getAttribute('DataType');
            metaVariable.formatting = variableNode.getAttribute('Formatting');
            metaVariable.indent = parseInt(variableNode.getAttribute('Indent'), 10);
            metaVariable.notes = variableNode.getAttribute('Notes');
            metaVariable.suggestedPaletteName = variableNode.getAttribute('PaletteName');
            metaVariable.suggestedPaletteInverse = (variableNode.getAttribute('PaletteInverse') || 'false').toLowerCase() === 'true';
            metaVariable.suggestedPaletteType = variableNode.getAttribute('PaletteType');
            metaVariable.varType = variableNode.getAttribute('VarType');
            metaVariable.tooltipCustom = variableNode.getAttribute('TooltipCustom');
            metaVariable.bubbleSizeHint = parseIntWithDefault(variableNode.getAttribute('BubbleSizeHint'), 10, 1);
            metaVariable.defaultFilterSetName = variableNode.getAttribute('FilterRule');
            metaVariable.dbFieldName = variableNode.getAttribute('DbFieldName');
            metaVariable.mapInfoButtonHtmlText = variableNode.getAttribute('MapInfoButtonHtmlText');
            metaVariable.table = metaTable;
            metaVariable.index = Object.keys(metaTable.variables).length;
            metaVariable.preferredVisualizationType = variableNode.getAttribute('PrefVizType');
            metaVariable.preferredVisualizationValueType = variableNode.getAttribute('PrefVizValType');

            metaTable.variables[metaVariable.name] = metaVariable;
        });

        return metaTable;
    }

    static _parseTables(node, dataset) {
        const tables = {};
        [...node.getElementsByTagName('Table')].forEach(tableNode => {
            const table = MetadataXMLParser._parseTable(tableNode, dataset);
            table.index = Object.keys(tables).length;
            tables[table.name] = table;
        });

        return tables;
    }

    static _parseDataset(datasetNode, survey) {
        const metaDataset = new MetaDataset();
        metaDataset.uuid = datasetNode.getAttribute('GUID');
        metaDataset.surveyName = datasetNode.getAttribute('SurveyName');
        metaDataset.name = datasetNode.getAttribute('Name');
        metaDataset.displayName = datasetNode.getAttribute('DisplayName');
        metaDataset.abbrevation = datasetNode.getAttribute('Abbreviation');
        metaDataset.publisher = datasetNode.getAttribute('Publisher');
        metaDataset.source = datasetNode.getAttribute('Source');
        metaDataset.productTags = MetadataXMLParser._parseArrayOfElements(datasetNode.getAttribute('ProductTags'));
        metaDataset.partial = (datasetNode.getAttribute('Partial') || 'false').toLowerCase() === 'true';
        metaDataset.dataCategoriesNames = [...datasetNode.getElementsByTagName('Category')].map(dataCategoryNode => dataCategoryNode.getAttribute('name'));
        metaDataset.tables = MetadataXMLParser._parseTables(datasetNode, metaDataset);
        metaDataset.survey = survey;

        return metaDataset;
    }

    static _parseDatasets(node, survey) {
        const datasets = {};
        [...node.getElementsByTagName('Dataset')].forEach(datasetNode => {
            const dataset = MetadataXMLParser._parseDataset(datasetNode, survey);
            dataset.index = Object.keys(datasets).length;
            datasets[dataset.abbrevation] = dataset;
        });

        return datasets;
    }

    static _parseSurvey(surveyNode) {
        const metaSurvey = new MetaSurvey();
        metaSurvey.uuid = surveyNode.getAttribute('GUID');
        metaSurvey.name = surveyNode.getAttribute('Name');
        metaSurvey.displayName = surveyNode.getAttribute('DisplayName');
        metaSurvey.year = parseInt(surveyNode.getAttribute('Year'), 10) || undefined;
        metaSurvey.yearTo = parseInt(surveyNode.getAttribute('YearFrom'), 10) || undefined;
        metaSurvey.yearFrom = parseInt(surveyNode.getAttribute('YearTo'), 10) || undefined;
        metaSurvey.publisher = surveyNode.getAttribute('Publisher');
        metaSurvey.productTags = MetadataXMLParser._parseArrayOfElements(surveyNode.getAttribute('ProductTags'));
        metaSurvey.priority = parseInt(surveyNode.getAttribute('Priority'), 10) || 0;
        metaSurvey.datasets = MetadataXMLParser._parseDatasets(surveyNode, metaSurvey);
        metaSurvey.reports = MetadataXMLParser._parseReports(surveyNode, metaSurvey);
        metaSurvey.geoTypes = MetadataXMLParser._parseGeoTypes(surveyNode, metaSurvey);

        return metaSurvey;
    }

    static _parseSurveys(node) {
        const surveys = {};
        [...node.getElementsByTagName('Survey')].forEach(surveyNode => {
            const survey = MetadataXMLParser._parseSurvey(surveyNode);
            survey.index = Object.keys(surveys).length;
            // we have to check if the current survey already exist
            if (!surveys[survey.name]) {
                surveys[survey.name] = survey;
            } else {
                surveys[survey.name].mergePartial(survey);
            }
        });

        return surveys;
    }

    tables(dataset) {
        return MetadataXMLParser._parseTables(this.metadataXMLDocument, dataset);
    }

    datasets(survey) {
        return MetadataXMLParser._parseDatasets(this.metadataXMLDocument, survey);
    }

    get surveys() {
        return MetadataXMLParser._parseSurveys(this.metadataXMLDocument);
    }

    get systemDataCategories() {
        return MetadataXMLParser._parseDataCategories(this.metadataXMLDocument);
    }

    get systemCategoryFilters() {
        return MetadataXMLParser._parseSystemCategoryFilters(this.metadataXMLDocument);
    }

    get metadata() {
        const partialMetadata = new Metadata();
        partialMetadata.surveys = this.surveys;
        partialMetadata.systemDataCategories = this.systemDataCategories;
        partialMetadata.systemCategoryFilters = this.systemCategoryFilters;

        return partialMetadata;
    }
}

export default MetadataXMLParser;
