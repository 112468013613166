import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import MapScale from './MapScale';

const MapFooter = ({ leftMap, mapInstance }) => {
    const footerClasses = classNames('map__footer', { 'map__footer--left-map': leftMap });
    return (
        <div className={footerClasses}>
            <MapScale mapInstance={mapInstance} />
        </div>
    );
};

MapFooter.propTypes = {
    mapInstance: PropTypes.object.isRequired,
    leftMap: PropTypes.bool,
};

MapFooter.defaultProps = {
    leftMap: undefined,
};

export default MapFooter;

