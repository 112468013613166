import React from 'react';
import BusComponent from '../../BusComponent';
import AppConfig from '../../../appConfig';
import NumberPropertyEditor from './propertyEditors/NumberPropertyEditor';
import ImagePropertyEditor from './propertyEditors/ImagePropertyEditor';

class EditImage extends BusComponent {
    constructor(props, context) {
        super(props, context);

        this.state = {
            rotationAngle: props.annotation.rotationAngle,
            fileName: props.annotation.fileName,
            uploading: false,
            opacity: props.annotation.opacity,
        };
    }

    componentWillMount() {
        this.bus.once('CURRENT_PROJECT', this.onCurrentProjectRetrieved);
        this.emit('CURRENT_PROJECT_REQUEST', { source: this });
    }

    componentWillReceiveProps(nextProps) {
        const nextState = {};
        if (nextProps.annotation.fileName && nextProps.annotation.fileName !== '') {
            nextState.fileName = `${AppConfig.constants.links.cdnRoot}/projects/${this.state.project.assetsGuid}/${nextProps.annotation.fileName}`;
        }
        this.setState(nextState);
    }

    componentWillUnmount() {
        this.bus.off('CURRENT_PROJECT', this.onCurrentProjectRetrieved);
        this.bus.off('UPLOAD_FILE_LOAD_SUCCESS', this.onUploadFileLoadSuccess);
        this.unbindGluBusEvents();
    }

    onCurrentProjectRetrieved = e => {
        const nextState = {
            project: e.project,
        };
        if (this.props.annotation.fileName && this.props.annotation.fileName !== '') {
            nextState.fileName = `${AppConfig.constants.links.cdnRoot}/projects/${e.project.assetsGuid}/${this.props.annotation.fileName}`;
        }
        this.setState(nextState);
    }

    onRotationChanged = rotationAngle => {
        this.setState({ rotationAngle });
        this.props.onChange({
            topLeftPoint: this.props.annotation.topLeftPoint,
            bottomRightPoint: this.props.annotation.bottomRightPoint,
            rotationAngle,
        });
    }

    onOpacityChanged = opacity => {
        this.setState({ opacity: opacity / 100 });
        this.props.onChange({
            opacity: opacity / 100,
            useFill: this.props.annotation.useFill,
        });
    }

    onBrowseFile = () => {
        const elem = this.upload;
        if (elem && document.createEvent) {
            const evt = document.createEvent('MouseEvents');
            evt.initEvent('click', true, false);
            elem.dispatchEvent(evt);
        }
    }

    onFileChanged = e => {
        this.bus.once('UPLOAD_FILE_LOAD_SUCCESS', this.onUploadFileLoadSuccess);
        const metaFile = e.target.files[0];
        this.emit('UPLOAD_FILE_LOAD_REQUEST', {
            assetsGuid: this.state.project.assetsGuid,
            fileType: metaFile.type,
            data: metaFile,
        });
        this.setState({
            uploading: true,
        });
    }

    onUploadFileLoadSuccess = e => {
        const name = `${e.fileName}.${e.fileType}`;
        const fileName = this.state.project ? `${AppConfig.constants.links.cdnRoot}/projects/${this.state.project.assetsGuid}/${name}` : undefined;
        this.setState({
            fileName,
            uploading: false,
        }, () => {
            this.emit('CHANGE_ANNOTATION', {
                id: this.props.annotation.id,
                assetsGuid: this.state.project.assetsGuid,
                propsToUpdate: {
                    annotation: this.props.annotation,
                    fileName: name,
                },
            });
        });
    }

    render() {
        return (<div className="flex-it column">
            <ImagePropertyEditor
                fileName={this.state.fileName}
                onBrowseFile={this.onBrowseFile}
                uploading={this.state.uploading}
            />
            <input
                type="file"
                className="hidden-input"
                ref={upload => { this.upload = upload; }}
                onChange={this.onFileChanged}
                accept="image/x-png,image/gif,image/jpeg"
            />
            <NumberPropertyEditor
                label="Rotation"
                value={this.state.rotationAngle}
                onChange={this.onRotationChanged}
                min={-180}
                max={180}
            />
            <NumberPropertyEditor
                label="Opacity"
                value={this.state.opacity * 100}
                onChange={this.onOpacityChanged}
                min={0}
                max={100}
                unit="%"
            />
        </div>);
    }

}

export default EditImage;
