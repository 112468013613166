import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import MapPanel from '../mapPanel/MapPanel';
import MapSwitcher from '../mapSwitcher/MapSwitcher';
import FilterAndMaskIndicator from '../mapIndicators/FilterAndMaskIndicator';
import SearchBoxPanel from '../searchBox/SearchBoxPanel';
import VisualizationLegend from '../visualizationLegend/VisualizationLegend';
import MapControls from '../mapControls/MapControls';
import { HelpTourTargets } from '../../enums/HelpTourDefinitions';
import ApplicationMode from '../../enums/ApplicationMode';
import Orientation from '../../enums/Orientation';

class CondensedFrameComponents extends React.Component {
    state = {};

    handleMapChange = map => {
        this.setState({ searchExpanded: false });
        this.props.onMapChange(map);
    };

    handleSearchVisibilityToggle = searchExpanded => {
        this.setState({ searchExpanded });
    };

    render() {
        const {
            mapInstances,
            isUserPro,
            activeMapIndex,
            children,
            mapViewer,
            onShowDataBrowser,
            applicationMode,
        } = this.props;

        const { searchExpanded } = this.state;
        const activeMapInstance = mapInstances[activeMapIndex];

        const mapSwitcherClasses = classNames('condensed-frame-components__controls-map-switcher', {
            'search-component-expanded': searchExpanded,
        });

        return (
            <div className="frame__components">
                <div className="frame__components-block frame__components-block--top">
                    <div className="condensed-frame-components">
                        {mapViewer &&
                            <div className="click-active">
                                <MapPanel
                                    tourId={HelpTourTargets.MAP_PANEL}
                                    mapInstance={activeMapInstance}
                                    isUserPro={isUserPro} mapViewer={mapViewer}
                                    onShowDataBrowser={onShowDataBrowser}
                                />
                            </div>
                        }
                        <div className="condensed-frame-components__controls">
                            <div className={mapSwitcherClasses}>
                                {mapInstances.length > 1 &&
                                    <MapSwitcher
                                        activeIndex={activeMapIndex}
                                        onSwitch={this.handleMapChange}
                                    />
                                }
                            </div>
                            <div className="flex-it flex-end grow">
                                {!searchExpanded &&
                                    <FilterAndMaskIndicator mapInstance={activeMapInstance} />
                                }
                                {ApplicationMode.isViewMode(applicationMode) &&
                                    <SearchBoxPanel
                                        mapInstanceIds={mapInstances.map(m => m.id)}
                                        activeMapInstanceId={activeMapInstance.id}
                                        onToggleVisibility={this.handleSearchVisibilityToggle}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="frame__components-block frame__components-block--bottom">
                    <VisualizationLegend
                        tourId={HelpTourTargets.MAP_LEGEND}
                        mapInstance={activeMapInstance}
                        isCondensedLayout
                        orientation={Orientation.LEFT}
                    />
                    <div className="flex-it column center flex-end">
                        {children}
                        <MapControls mapInstance={activeMapInstance} />
                    </div>
                </div>
            </div>
        );
    }
}

CondensedFrameComponents.propTypes = {
    mapInstances: PropTypes.array.isRequired,
    mapViewer: PropTypes.object.isRequired,
    activeMapIndex: PropTypes.number.isRequired,
    isUserPro: PropTypes.bool,
    children: PropTypes.any,
    onMapChange: PropTypes.func,
    onShowDataBrowser: PropTypes.func,
    applicationMode: PropTypes.string,
};

CondensedFrameComponents.defaultProps = {
    onMapChange: undefined,
    children: undefined,
    isUserPro: false,
    onShowDataBrowser: undefined,
    applicationMode: undefined,
};

export default CondensedFrameComponents;
