class UserDataLayer {
    static getMarkerUniqueId(markerPathId, markerColor) {
        return `${markerPathId}${markerColor}`;
    }

    constructor(values) {
        // set some default values
        this._allowOverlap = true;
        this._visible = true;
        this._interactive = false;
        this._popupOtherColumns = [];

        if (values) {
            Object.keys(values).forEach(k => {
                if (this.constructor.prototype.hasOwnProperty(k)) {
                    this[k] = values[k];
                }
            });
        }
    }

    set metadata(metadata) {
        this._metadata = metadata;
    }

    get metadata() {
        return this._metadata;
    }

    set geoJSON(geoJSON) {
        this._geoJSON = geoJSON;
    }

    get geoJSON() {
        return this._geoJSON;
    }

    set visible(visible) {
        this._visible = visible;
    }

    get visible() {
        return this._visible;
    }

    set allowOverlap(allowOverlap) {
        this._allowOverlap = allowOverlap;
    }

    get allowOverlap() {
        return this._allowOverlap;
    }

    get title() {
        return this._title;
    }

    set title(title) {
        this._title = title;
    }

    get id() {
        return this._id;
    }

    set id(id) {
        this._id = id;
    }

    get sourceId() {
        return `userDataSource-${this.id}`;
    }

    get featureLayerId() {
        return `userDataLayer-${this.id}`;
    }

    get includeInLegend() {
        return this._includeInLegend;
    }

    set includeInLegend(includeInLegend) {
        this._includeInLegend = includeInLegend;
    }

    get layerStyle() {
        return this._layerStyle;
    }

    set layerStyle(layerStyle) {
        this._layerStyle = layerStyle;
    }

    /** @type {(import('./PointLayerStyle').default)[]} */
    get styleRules() {
        return this._styleRules;
    }

    set styleRules(styleRules) {
        this._styleRules = styleRules;
    }

    get valueColumn() {
        return this._valueColumn;
    }

    set valueColumn(valueColumn) {
        this._valueColumn = valueColumn;
    }

    get labelingColumn() {
        return this._labelingColumn;
    }

    set labelingColumn(labelingColumn) {
        this._labelingColumn = labelingColumn;
    }

    get interactive() {
        return this._interactive;
    }

    set interactive(interactive) {
        this._interactive = interactive;
    }

    get popupTitleColumn() {
        return this._popupTitleColumn;
    }

    set popupTitleColumn(popupTitleColumn) {
        this._popupTitleColumn = popupTitleColumn;
    }

    get popupOtherColumns() {
        return this._popupOtherColumns;
    }

    set popupOtherColumns(popupOtherColumns) {
        this._popupOtherColumns = popupOtherColumns;
    }

    get isPointVisualization() {
        return this.styleRules.every(sr => sr.type === 'symbol');
    }

    get pointLabel() {
        return this._pointLabel;
    }

    set pointLabel(pointLabel) {
        this._pointLabel = pointLabel;
    }

    set allowOverlap(allowOverlap) {
        this._allowOverlap = allowOverlap;
    }

    get allowOverlap() {
        return this._allowOverlap;
    }

    /** @type {string} */
    get popupTitleColumn() {
        return this._popupTitleColumn;
    }

    set popupTitleColumn(popupTitleColumn) {
        this._popupTitleColumn = popupTitleColumn;
    }

    /** @type {string[]} */
    get popupOtherColumns() {
        return this._popupOtherColumns;
    }

    set popupOtherColumns(popupOtherColumns) {
        this._popupOtherColumns = popupOtherColumns;
    }

    get includeInLegend() {
        return this._includeInLegend;
    }

    set includeInLegend(includeInLegend) {
        this._includeInLegend = includeInLegend;
    }

    equals(that) {
        return (JSON.stringify(this.metadata) === JSON.stringify(that.metadata) &&
            this.title === that.title &&
            this.type === that.type &&
            this.id === that.id &&
            this.interactive === that.interactive &&
            this.valueColumn === that.valueColumn &&
            this.labelingColumn === that.labelingColumn &&
            this.popupTitleColumn === that.popupTitleColumn &&
            JSON.stringify(this.popupOtherColumns) === JSON.stringify(that.popupOtherColumns) &&
            JSON.stringify(this.styleRules) === JSON.stringify(that.styleRules) &&
            this.includeInLegend === that.includeInLegend);
    }

    clone() {
        return new UserDataLayer({
            sources: this.sources,
            title: this.title,
            type: this.type,
            id: this.id,
            labelingColumn: this.labelingColumn,
            allowOverlap: this.allowOverlap,
            visible: this.visible,
            valueColumn: this.valueColumn,
            styleRules: this.styleRules.map(s => s.clone()),
            includeInLegend: this.includeInLegend,
            interactive: this.interactive,
            popupTitleColumn: this.popupTitleColumn,
            popupOtherColumns: [...this.popupOtherColumns],
            geoJSON: this.geoJSON ? JSON.parse(JSON.stringify(this.geoJSON)) : {},
            metadata: this.metadata,
        });
    }

    toJSON() {
        return {
            title: this.title,
            type: this.type,
            id: this.id,
            labelingColumn: this.labelingColumn,
            valueColumn: this.valueColumn,
            allowOverlap: this.allowOverlap,
            visible: this.visible,
            styleRules: this.styleRules.map(s => s.clone()),
            includeInLegend: this.includeInLegend,
            interactive: this.interactive,
            popupTitleColumn: this.popupTitleColumn,
            popupOtherColumns: [...this.popupOtherColumns],
            metadata: this.metadata,
        };
    }

    convertToDragonflyLayers(map) {
        /** @type {import('../../..').UserLayerWithMetadata[]} */
        const layers = [];

        this.styleRules
            .filter(style => !style.isHidden)
            .forEach(style => {
                // if `style by value` is applied, create as many layers as unique values of the styleBy column exist
                // all these layers get an ID which consists of the ID of parent(original) user data layer and the corresponding value
                // some examples for UserDataLayer with id=layer1234 are: `layer1234__male`, `layer1234__female` and `layer1234__other`
                // where 'male', 'female' and 'other' are values of selected styleBy column.
                const layerId =
                    style.value == null
                        ? this.featureLayerId
                        : `${this.featureLayerId}__${style.value.toString().replace(/\s/g, '')}`;

                // Custom filter expression for lazy handling column types
                // Project definition currently supports saving only string values
                // But server might send a number value (or any other), so we convert those values to string before comparing
                let filter;
                if (this.valueColumn && style.value !== undefined) {
                    filter = ['==', ['to-string', ['get', this.valueColumn]], `${style.value}`];
                } else {
                    filter = ['all'];
                }

                const featureLayer = {
                    id: layerId,
                    type: style.type,
                    filter,
                    source: this.sourceId,
                    // 'source-layer': this.sourceLayer,
                    metadata: {
                        socialexplorer: {},
                        isUserDataLayer: true,
                        defaultMinZoom: map.minZoom,
                        defaultMaxZoom: map.maxZoom + 1,
                        userData: {
                            // keep a link to the original user data layer, in case `style by value` is applied
                            userDataLayerId: this.id,
                            interactive: this.interactive,
                            highlight: false,
                        },
                    },
                    paint: {
                        'text-color': '#0F0F0F',
                        'text-halo-blur': 0.2,
                        'text-halo-color': '#ffffff',
                        'text-halo-width': 0.8,
                        'icon-opacity': 1,
                    },
                    layout: {
                        visibility: this.visible ? 'visible' : 'none',
                        'icon-size': 1,
                        'icon-image': UserDataLayer.getMarkerUniqueId(
                            style.markerPathId,
                            style.markerColor,
                        ),
                        'icon-padding': 3,
                        'text-font': ['arial-regular'],
                        'text-field': this.pointLabel ? `{${this.pointLabel}}` : '',
                        'text-anchor': 'top',
                        'text-offset': [0, 1.2],
                        'text-size': 12,
                        'text-letter-spacing': 0.1,
                        'icon-allow-overlap':
                            this.allowOverlap !== undefined ? this.allowOverlap : false,
                        'icon-ignore-placement':
                            this.allowOverlap !== undefined ? this.allowOverlap : false,
                        'text-optional': true,
                        'icon-optional':
                            this.allowOverlap !== undefined ? this.allowOverlap : false,
                    },
                };

                layers.push(featureLayer);

                if (this.visible && this.interactive && style.type === 'symbol') {
                    /** @type {import('../../..').UserLayerWithMetadata} */
                    const featureHighlightLayer = {
                        id: `${featureLayer.id}__highlight-layer`,
                        type: 'bubble',
                        paint: {
                            'bubble-opacity': 1,
                            'bubble-color': 'rgba(0,0,0,0)',
                            'bubble-outline-color': 'rgba(0,0,0,0)',
                            'bubble-outline-width': 4,
                            'bubble-max-radius-outline-color': 'rgba(0,0,0,0)',
                            'bubble-max-radius-outline-width': 4,
                            'bubble-max-radius': 9,
                            'bubble-radius-multiplier': 1,
                        },
                        layout: { 'bubble-radius': 10 },
                        metadata: {
                            isUserDataLayer: true,
                            socialexplorer: {},
                            userData: { highlight: true, interactive: false },
                        },
                        source: featureLayer.source,
                    };
                    layers.push(featureHighlightLayer);
                }
            });

        return layers;
    }
}

export default UserDataLayer;
