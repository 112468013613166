import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { geographySearchResultButtonEvents } from '../../enums/GoogleTagManagerEvents';
import googleTagManagerEvents from '../../helpers/GoogleTagManagerEventsWrapper';

const GeoSearchResultButton = googleTagManagerEvents('li', geographySearchResultButtonEvents);

class SearchResultListItem extends React.Component {
    componentDidMount() {
        const { searchTerm } = this.props;
        const { mainResultText, otherResultText, type } = this.parseResults();

        const gtmEventLabel = `${mainResultText}|${otherResultText}|${type}`;
        this.listItem.setCustomAttribute('gtm-eventa', searchTerm);
        this.listItem.setCustomAttribute('gtm-eventl', gtmEventLabel);
    }

    handleMouseDown = () => {
        this.props.onSelect(this.props.item);
    };

    parseResults = () => {
        const { item: { value, type } } = this.props;

        const resultTokens = value.split(',');
        const mainResultText = resultTokens.shift();
        const otherResultText = resultTokens.join(',');

        return { mainResultText, otherResultText, type };
    }

    render() {
        const { active } = this.props;
        const { mainResultText, otherResultText, type } = this.parseResults();

        const classes = classNames('search-results__list-item', {
            'search-results__list-item--selected': active,
        });

        return (
            <GeoSearchResultButton
                ref={c => { this.listItem = c; }}
                className={classes}
                onClick={this.handleMouseDown}
            >
                <button className="flex-it space-between center grow stretch-width">
                    <div
                        className="flex-it stretch flex-item ellipsis"
                        title={`${mainResultText}${otherResultText && `, ${otherResultText}`}`}
                    >
                        <span className="main-content">{mainResultText}</span>
                        {otherResultText !== '' &&
                        <span className="other-content">, {otherResultText}</span>
                        }
                    </div>
                    <div
                        className="flex-one flex-fixed"
                        style={{
                            fontSize: '0.6rem',
                            color: '#666',
                            backgroundColor: '#eee',
                            borderRadius: '3px',
                            textTransform: 'uppercase',
                            paddingLeft: 4,
                            paddingRight: 4,
                        }}
                    >
                        {type}
                    </div>
                </button>
            </GeoSearchResultButton>);
    }
}

SearchResultListItem.propTypes = {
    onSelect: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired,
    active: PropTypes.bool,
    searchTerm: PropTypes.string,
};

SearchResultListItem.defaultProps = {
    active: false,
    searchTerm: undefined,
};

export default SearchResultListItem;
