// @ts-check
import React from 'react';
import PropTypes from 'prop-types';

import CenterMapLayerButton from './CenterMapLayerButton';
import LocationAnalysisCloseButton from './LocationAnalysisCloseButton';
import LocationAnalysisMinimizeButton from './LocationAnalysisMinimizeButton';

/**
 * @typedef Props
 * @property {boolean} isAnalysisOn
 * @property {string} mapInstanceId
 * @property {import('../../objects/LocationAnalysisItem').default} locationAnalysisItem
 *
 * @param {Props} props
 */
const LocationHeaderActions = ({
    isAnalysisOn,
    mapInstanceId,
    locationAnalysisItem,
}) => (
    <div className="flex-it center">
        <CenterMapLayerButton
            mapInstanceId={mapInstanceId}
            zoomPoint={locationAnalysisItem.point}
        />
        <LocationAnalysisMinimizeButton mapInstanceId={mapInstanceId} />
        <div className="location-analysis__divider" />
        <LocationAnalysisCloseButton
            mapInstanceId={mapInstanceId}
            tooltipId={
                isAnalysisOn ? 'dataBrowser.closeAnalysis' : 'dataBrowser.close'
            }
        />
    </div>
);

LocationHeaderActions.propTypes = {
    isAnalysisOn: PropTypes.bool,
    mapInstanceId: PropTypes.string.isRequired,
    locationAnalysisItem: PropTypes.object.isRequired,
};

LocationHeaderActions.defaultProps = {
    isAnalysisOn: false,
};

export default LocationHeaderActions;
