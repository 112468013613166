import BaseHandler from './BaseHandler';
import getCustomMapMarkers from '../../helpers/CustomMapMarkersHelper';

class CustomMapMarkersHandler extends BaseHandler {
    constructor(mapViewer) {
        super(mapViewer);
        this.addCustomMarkers();
    }

    addCustomMarkers() {
        getCustomMapMarkers().forEach(({ id, image, pixelRatio }) => {
            this.map.addImage(id, image, { pixelRatio });
        });
    }
}

export default CustomMapMarkersHandler;
