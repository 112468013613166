import React from 'react';
import PropTypes from 'prop-types';

import LayerLibraryLayer from '../list/LayerLibraryLayer';

class LayerLibraryGroupCardLayers extends React.Component {

    onToggleVisibility = (id, visible) => {
        this.props.onToggleVisibility(id, visible);
    }

    renderLibraryLayers() {
        const { layers } = this.props;

        const libraryLayers = layers.map(layer => {
            const { id, title, visible } = layer;

            return (<LayerLibraryLayer
                id={id}
                key={id}
                title={title}
                visible={visible}
                onToggleVisibility={this.onToggleVisibility}
            />);
        });

        return libraryLayers;
    }

    render() {
        return (<div className="map-library__layers flex-it column">
            {this.renderLibraryLayers()}
        </div>);
    }
}

LayerLibraryGroupCardLayers.propTypes = {
    layers: PropTypes.array.isRequired,
    onToggleVisibility: PropTypes.func.isRequired,
};

export default LayerLibraryGroupCardLayers;
