import React from 'react';
import PropTypes from 'prop-types';

import SearchYearFilter from './SearchYearFilter';
import SearchSurveyFilter from './SearchSurveyFilter';

/**
 *
 * @typedef Props
 * @property {number[]} years
 * @property {number} selectedYear
 * @property {(year: number) => void} onYearChange
 * @property {import('../../../objects/MetaSurvey').default[]} surveys
 * @property {string} selectedSurvey
 * @property {(surveyName: string) => void} onSurveyChange
 *
 * @param {Props} props
 */
const SearchFilters = props => (
    <div className="data-browser-search-filters">
        <SearchYearFilter
            years={props.years}
            selectedYear={props.selectedYear}
            onChange={props.onYearChange}
        />
        <SearchSurveyFilter
            surveys={props.surveys}
            selectedSurvey={props.selectedSurvey}
            onChange={props.onSurveyChange}
        />
    </div>
);

SearchFilters.propTypes = {
    years: PropTypes.array.isRequired,
    selectedYear: PropTypes.number,
    onYearChange: PropTypes.func.isRequired,
    surveys: PropTypes.array.isRequired,
    selectedSurvey: PropTypes.string,
    onSurveyChange: PropTypes.func.isRequired,
};

SearchFilters.defaultProps = {
    selectedYear: undefined,
    selectedSurvey: undefined,
};

export default SearchFilters;
