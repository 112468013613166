import BaseController from './BaseController';
import DataClassificationMethod from '../enums/DataClassificationMethod';
import DataClassificationEvents from '../enums/DataClassificationEvents';
import FilterSet from '../objects/FilterSet';
import Filter from '../objects/Filter';
import { parseFieldName } from '../helpers/Util';
import RendererType from '../enums/RendererType';
import Errors from '../enums/Error';
import FrameType from '../enums/FrameType';
import VariableType from '../enums/VariableType';

import MetadataDataSource from '../dataSources/MetadataDataSource';
import ColorPaletteDataSource from '../dataSources/ColorPaletteDataSource';
import MapDataSource from '../dataSources/MapDataSource';
import AppConfig from '../appConfig';

class DataClassificationController extends BaseController {
    static get name() {
        return 'DataClassificationController';
    }

    static getInstance(options) {
        return new DataClassificationController(options);
    }

    onActivate() {
        this.bindGluBusEvents({
            DATA_CLASSIFICATION_DATA_REQUEST: this.onDataClassificationDataRequest,
            DATA_ANALYSIS_DATA_REQUEST: this.onDataAnalysisRequest,
            CLASSES_NUMBER_CHANGE: this.onDataClassesNumberChange,
            CLASSES_DRAG_END: this.onDataClassesDragEnd,
            CUT_POINTS_APPLY: this.onCutPointApply,
            MODAL_CLOSED: this.onModalClosed,
            CLASSIFICATION_METHOD_CHANGE: this.onDataClassificationMethodChange,
            INSUFFICIENT_BASE_CHANGE: this.onInsufficientBaseChange,
            LOAD_CUT_POINTS_FROM_FILE_REQUEST: this.onLoadCutPointsFromFileRequest,
            SAVE_CUT_POINTS_TO_FILE_REQUEST: this.onSaveCutPointsToFileRequest,
            APPLY_NATURAL_BREAKS_CUTPOINTS_REQUEST: this.onApplyNaturalBreaksCutpointsRequest,
        });

        /** @member {Number} currentBreaks */
        this.currentBreaks = 2;
        /** @member {String[]} allowedBreaks Some description */
        this.allowedBreaks = null;
        /** @member {String} currentMethod */
        this.currentMethod = DataClassificationMethod.CATEGORY_DEFAULT;
        /** @member {MapViewer} mapViewer */
        this.mapViewer = null;
        /** @member {DataTheme} appliedDataTheme */
        this.appliedDataTheme = null;
        /** @member {ColorPalette} currentMetadata */
        this.currentColorPalette = null;
        /** @member {VariableSelection} variableSelection */
        this.variableSelection = null;
        /** @member {MetaVariable} metaVariable */
        this.metaVariable = null;
        /** @member {Boolean} isComputedVisualization */
        this.isComputedVisualization = false;
        /** @member {Boolean} isChangeOverTimeVisualization */
        this.isChangeOverTimeVisualization = false;
        /** @member {String} computedVariableFieldName */
        this.computedVariableFieldName = null;

        this.currentFilterSet = null;
        this.variablesXMin = NaN;
        this.variablesXMax = NaN;
        this.variablesYMin = NaN;
        this.variablesYMax = NaN;

        /** @member {Object} variablesData */
        this.variablesData = null;
        /** @member {String} popupTitle */
        this.popupTitle = '';
        this.rawData = null;
        /** @member {CategoryFilters} categoryFilters */
        this.categoryFilters = null;

        this.insufficientValue = undefined;

        this._opened = false;
        this.canApplyToBothMaps = false;
        this.metadataDataSource = this.activateSource(MetadataDataSource);
        this.colorPaletteDataSource = this.activateSource(ColorPaletteDataSource);
        this.mapDataSource = this.activateSource(MapDataSource);

        this.boundDoDataClassification = this.onMapReadyForDataClassification.bind(this);
        this.boundDoDataAnalysis = this.onMapReadyForDataAnalysis.bind(this);
    }

    onLoadCutPointsFromFileRequest({ files }) {
        if (files && files.length > 0) {
            const fr = new FileReader();
            fr.onload = () => {
                try {
                    const state = JSON.parse(fr.result);

                    if (state.filterSet.valueFormat !== this.currentFilterSet.valueFormat) {
                        console.warn('saved cutpoints value format is not applicable to this variable, expected:', this.currentFilterSet.valueFormat, ', got:', state.filterSet.valueFormat);
                        this.bus.emit(DataClassificationEvents.LOAD_CUT_POINTS_FROM_FILE_ERROR, { error: 'Saved cut-points are not compatible with current variable data (out of range - values are either too big or too small to be of any use)' });
                        return;
                    }

                    if (this.allowedBreaks.every(b => parseInt(b, 10) !== parseInt(state.currentBreaks, 10))) {
                        console.warn('saved cutpoints breaks number is not within the allowed breaks span, expected one of:', this.allowedBreaks.join(', '), 'got:', state.currentBreaks);
                        this.bus.emit(DataClassificationEvents.LOAD_CUT_POINTS_FROM_FILE_ERROR, { error: 'Too many cut-points' });
                        return;
                    }

                    this.currentFilterSet = new FilterSet();
                    this.currentFilterSet.valueFormat = state.filterSet.valueFormat;
                    this.currentFilterSet.filters = state.filterSet.filters.map(f => new Filter({
                        from: f.from !== undefined ? parseFloat(f.from) : undefined,
                        to: f.to !== undefined ? parseFloat(f.to) : undefined,
                    }));

                    this.currentBreaks = parseInt(state.currentBreaks, 10);
                    this.currentMethod = DataClassificationMethod.CUSTOM;
                    this.insufficientValue = state.insufficientValue;

                    this.variablesXMin = Math.min(this.absMin, this.variablesXMin, parseFloat(state.xmin));
                    this.variablesXMax = Math.max(this.absMax, this.variablesXMax, parseFloat(state.xmax));
                    this.variablesYMin = Math.min(this.variablesYMin, parseFloat(state.ymin));
                    this.variablesYMax = Math.max(this.variablesYMax, parseFloat(state.ymax));
                    this.absMin = this.variablesXMin;
                    this.absMax = this.variablesXMax;

                    this.variableSelection.items.forEach((item, itemIdx) => {
                        let variableColors;
                        if (this.variableSelection.isMultiVariable) {
                            variableColors = this.currentColorPalette.colorRamps[itemIdx].interpolateColors(this.currentBreaks);
                        } else {
                            variableColors = this.currentColorPalette.interpolateColors(this.currentBreaks);
                        }

                        this.variablesData[item.variableGuid].colors = variableColors;
                    });
                    this.postFixFilters();

                    this.bus.emit(DataClassificationEvents.DATA_CLASSIFICATION_DATA_REQUEST_DONE, this.getPayload());
                } catch (e) {
                    console.warn('Error occurred while attempting to parse the file', e);
                    this.bus.emit(DataClassificationEvents.LOAD_CUT_POINTS_FROM_FILE_ERROR, { error: 'Error occurred while attempting to parse the file' });
                }
            };

            fr.onerror = e => {
                console.warn('Error occurred while attempting to read the saved cut points file', e);
                this.bus.emit(DataClassificationEvents.LOAD_CUT_POINTS_FROM_FILE_ERROR, { error: 'Error occurred while attempting to read the file' });
            };

            fr.readAsText(files[0]);
        }
    }

    onSaveCutPointsToFileRequest() {
        const state = {
            insufficientValue: this.insufficientValue,
            xmax: this.variablesXMax,
            xmin: this.variablesXMin,
            ymax: this.variablesYMax,
            ymin: this.variablesYMin,
            filterSet: {
                valueFormat: this.currentFilterSet.valueFormat,
                filters: this.currentFilterSet.filters.map(f => ({ from: f.from, to: f.to })),
            },
            currentBreaks: this.currentBreaks,
        };

        this.bus.emit('DOWNLOAD_FILE_REQUEST', {
            source: this,
            content: JSON.stringify(state),
            fileName: 'cutpoints.ctp',
            mimeType: 'text/plain',
        });
    }

    onDataAnalysisRequest(payload) {
        this.bus.once('MAP_CURRENT_MAP_INSTANCE', this.boundDoDataAnalysis);
        this.bus.emit('MAP_GET_CURRENT_MAP_INSTANCE_REQUEST', { source: this, mapInstanceId: payload.mapInstanceId });
    }

    getCurrentMapInstance = mapInstanceId => {
        this.bus.once('MAP_CURRENT_MAP_INSTANCE', this.boundDoDataClassification);
        this.bus.emit('MAP_GET_CURRENT_MAP_INSTANCE_REQUEST', {
            source: this,
            mapInstanceId,
        });
    };

    onApplyNaturalBreaksCutpointsRequest({ mapInstanceId }) {
        this.onDataClassificationDataRequest({ mapInstanceId });
        // Don't allow auto adjusting more than 5000 features
        this.parseRawData();
        if (this.rawData.length > AppConfig.constants.autoAdjustGeographiesLimit) {
            this.bus.emit('AUTO_ADJUST_FAILED_POPUP_REQUEST');
            return;
        }

        this.onDataClassificationMethodChange({
            value: 'natural_breaks',
            mapInstanceId,
        });
        this.onCutPointApply({
            mapInstanceId,
            shouldApplyToBothMaps: false,
        });
        this.bus.emit('APPLY_NATURAL_BREAKS_CUTPOINTS_DONE');
    }

    /**
     *  Event handler for getting all the data ds popup needs
     *  @param {Object} payload ( contains mapInstanceId )
     */
    onDataClassificationDataRequest(payload) {
        this._opened = true;
        this.bus.once('CURRENT_FRAME', frame => {
            this.frame = frame;
            this.getCurrentMapInstance(payload.mapInstanceId);
        });

        this.bus.emit('CURRENT_FRAME_REQUEST');
    }

    onMapReadyForDataAnalysis(eventMap) {
        this.setMapInfo(eventMap.source, eventMap.mapInstance);
        this.setInitialFilterSetAndBreaks();
        this.parseRawData();

        const payload = {
            variablesData: {},
            mapInstanceId: eventMap.mapInstance.id,
        };

        this.variableSelection.items.forEach(item => {
            // If the COT is on we need to use the calculated values for our data analysis
            const variableGuid = this.isChangeOverTimeVisualization ? this.computedVariableFieldName : item.variableGuid;

            const data = this.rawData
                .filter(d => {
                    const value = parseFloat(d.properties[`${variableGuid}`]);
                    return !isNaN(value) && isFinite(value) && value !== 0;
                })
                .map(d => Math.abs(parseFloat(d.properties[`${variableGuid}`])));

            const half = Math.floor(data.length / 2);
            const idx90Pct = Math.floor(data.length * 0.9);
            data.sort((a, b) => a - b);
            payload.variablesData[item.variableGuid] = {
                min: data[0],
                max: data[data.length - 1],
                median: data.length % 2 ? data[half] : (data[half - 1] + data[half]) / 2.0,
                upper90: data.length % 2 ? data[idx90Pct] : (data[idx90Pct - 1] + data[idx90Pct]) / 2.0,
                numberOfValues: data.length,
            };
        });

        this.bus.emit(DataClassificationEvents.DATA_ANALYSIS_DATA_REQUEST_DONE, payload);
    }

    onMapReadyForDataClassification(eventMap) {
        if (!this._opened || eventMap.target !== this) {
            return;
        }

        this.setMapInfo(eventMap.source, eventMap.mapInstance);

        this.currentMethod = this.appliedDataTheme.dataClassificationMethod;
        this.allowedBreaks = this.categoryFilters[0].filterSets.map(filterSet => filterSet.length.toString());

        this.setInitialFilterSetAndBreaks();
        this.setInsufficientValue();
        this.resetVariablesData();
        this.setCurrentClassificationMethods();
        this.parseRawData();
        if (this.rawData.length === 0) {
            this.bus.emit(DataClassificationEvents.DATA_CLASSIFICATION_DATA_REQUEST_ERROR, {
                level: Errors.ERROR,
                additionalInfo: 'There is no data needed for statistic calculations',
            });
            return;
        }
        this.parseCurrentData();
        this.setCategoryDefaultAbsMax();
        this.postFixFilters();

        if (this.frame.type !== FrameType.SINGLE_MAP) {
            const mapViewers = [];
            const onMapViewerRetrieved = mapViewerEvent => {
                mapViewers.push(mapViewerEvent.source);
                if (mapViewers.length === 2) {
                    this.bus.off(onMapViewerRetrieved);
                    this.canApplyToBothMaps = true;
                    mapViewers.forEach(mv => {
                        if (mv.mapInstance.dataTheme.isBubblesPercent || (mv.mapInstance.dataTheme.isShadedVisualization && !mv.mapInstance.dataTheme.isVisualizationCategorical)) {
                            let _filterSet = mv.mapInstance.dataTheme.filterSet;

                            if (!_filterSet) {
                                const _firstVariableSelection = mv.mapInstance.dataTheme.variableSelection.items[0];
                                const _survey = this.metadataDataSource.currentMetadata.surveys[_firstVariableSelection.surveyName];
                                const _surveyDataset = _survey.datasets[_firstVariableSelection.datasetAbbreviation];
                                const _surveyTable = _surveyDataset.getTableByGuid(_firstVariableSelection.tableGuid);
                                const _metaVariable = _surveyTable.getVariableByGuid(_firstVariableSelection.variableGuid);
                                let _categoryFilters = this.metadataDataSource.currentMetadata.systemCategoryFilters.filter(cFilter => cFilter.name === _metaVariable.defaultFilterSetName);
                                if (!_categoryFilters.length) {
                                    _categoryFilters = [this.metadataDataSource.currentMetadata.systemCategoryFilters[0]];
                                }
                                _filterSet = _categoryFilters[0];
                            }

                            if (_filterSet.valueFormat !== this.currentFilterSet.valueFormat) {
                                this.canApplyToBothMaps = false;
                            }
                        } else {
                            this.canApplyToBothMaps = false;
                        }
                    });

                    this.bus.emit(DataClassificationEvents.DATA_CLASSIFICATION_DATA_REQUEST_DONE, this.getPayload());
                }
            };
            this.bus.on('MAP_VIEWER', onMapViewerRetrieved);
            this.bus.emit('MAP_GET_MAP_VIEWER');
        } else {
            this.canApplyToBothMaps = false;
            this.bus.emit(DataClassificationEvents.DATA_CLASSIFICATION_DATA_REQUEST_DONE, this.getPayload());
        }
    }

    handleComputedVisualization() {
        const renderer = this.appliedDataTheme.rendering[0];
        const rules = renderer.type === RendererType.MULTI_VALUE ? renderer.rules[0] : renderer.rules;
        const insufficientDataRuleIndex = renderer.type === RendererType.MULTI_VALUE ? renderer.insufficientDataRuleIndex[0] : renderer.insufficientDataRuleIndex;
        const nullDataRuleIndex = renderer.type === RendererType.MULTI_VALUE ? renderer.nullDataRuleIndex[0] : renderer.nullDataRuleIndex;
        const relevantRule = rules.find((r, idx) => idx !== insufficientDataRuleIndex && idx !== nullDataRuleIndex);
        const valueColumn = parseFieldName(relevantRule.filter.fieldName).variableGuid;
        const columnField = renderer.fieldList.fields.find(field => field.fieldName === valueColumn);

        this.isComputedVisualization = columnField.isComputed;
        this.isChangeOverTimeVisualization = columnField.isChangeOverTimeField;
        this.computedVariableFieldName = columnField.fieldName;
    }

    setMapInfo(source, mapInstance) {
        this.mapViewer = source;
        this.mapInstance = mapInstance;
        this.appliedDataTheme = this.mapInstance.dataTheme;
        this.popupTitle = this.appliedDataTheme.title;

        /** @type {Metadata} currentMetadata */
        const currentMetadata = this.metadataDataSource.currentMetadata;
        /** @type {BaseMap} currentMetadata */
        const currentBaseMap = this.mapDataSource.currentMaps[this.mapInstance.currentMapId];

        this.currentColorPalette = this.colorPaletteDataSource.currentColorPalettes.getColorPaletteByTypeAndId(
            this.appliedDataTheme.colorPaletteType,
            this.appliedDataTheme.colorPaletteId,
            currentBaseMap.colorPalettesURL);
        this.variableSelection = this.appliedDataTheme.variableSelection;
        this.handleComputedVisualization();

        /** @type {VariableSelectionItem} firstVariableSelection */
        const firstVariableSelection = this.variableSelection.items[0];
        /** @type {MetaSurvey} survey */
        const survey = currentMetadata.surveys[firstVariableSelection.surveyName];
        /** @type {MetaDataset} surveyDataset */
        const surveyDataset = survey.datasets[firstVariableSelection.datasetAbbreviation];
        /** @type {MetaTable} surveyTables */
        const surveyTable = surveyDataset.getTableByGuid(firstVariableSelection.tableGuid);

        this.metaVariable = surveyTable.getVariableByGuid(firstVariableSelection.variableGuid);
        this.categoryFilters = currentMetadata.systemCategoryFilters.filter(cFilter => cFilter.name === this.metaVariable.defaultFilterSetName);
        if (!this.categoryFilters.length) {
            this.categoryFilters = [currentMetadata.systemCategoryFilters[0]];
        }
    }

    setCategoryDefaultAbsMax() {
        let absMax = 0, currentAbsMax = 0, absMin = 0, currentAbsMin = 0;
        if (this.appliedDataTheme.filterSet) {
            currentAbsMax = Math.max(...this.appliedDataTheme.filterSet.filters.map(f => f.from));
            currentAbsMin = Math.min(...this.appliedDataTheme.filterSet.filters.map(f => f.to));
        }
        if (this.categoryFilters && this.categoryFilters[0] && this.categoryFilters[0].filterSets) {
            this.categoryFilters[0].filterSets.forEach(fs => {
                fs.filters.forEach(f => {
                    if (f && f.from > absMax) {
                        absMax = f.from;
                    }
                    if (f && f.to < absMin) {
                        absMin = f.to;
                    }
                });
            });
        }

        let rawData = [];
        Object.keys(this.variablesData).forEach(variable => {
            rawData = rawData.concat(this.variablesData[variable].rawData);
        }, this);

        const rawDataMax = Math.max(...rawData);
        const rawDataMin = Math.min(...rawData);

        this.absMax = Math.max(rawDataMax, absMax, currentAbsMax);
        this.absMin = Math.min(rawDataMin, absMin, currentAbsMin);
    }

    setInitialFilterSetAndBreaks() {
        // The default value format from filter set
        let valueFormat = this.categoryFilters[0].filterSets[0].valueFormat;
        const currentVisualizationValueType = this.appliedDataTheme.currentVisualizationValueType;
        // count variables in bubbles and shaded visualization can be presented in absolute value or percentage
        // so if the user selected a different value presentation from the default filter set value format update it
        if (this.metaVariable.varType === VariableType.COUNT &&
            currentVisualizationValueType &&
            currentVisualizationValueType !== valueFormat) {
            valueFormat = currentVisualizationValueType;
        }
        this.currentFilterSet = new FilterSet({
            filters: [],
            valueFormat,
        });
        const insufficientDataRuleIndex = this.appliedDataTheme.rendering[0].insufficientDataRuleIndex;
        const nullDataRuleIndex = this.appliedDataTheme.rendering[0].nullDataRuleIndex;

        const filterList = [];
        let rulesList = this.appliedDataTheme.rendering[0].rules;
        if (this.variableSelection.isMultiVariable) {
            rulesList = rulesList[0];
        }
        rulesList.forEach((r, rIdx) => {
            if (this.variableSelection.isMultiVariable) {
                if ((insufficientDataRuleIndex.indexOf(rIdx) === -1) && (nullDataRuleIndex.indexOf(rIdx) === -1)) {
                    filterList.push(r.filter.clone());
                }
            } else if ((rIdx !== insufficientDataRuleIndex) && (rIdx !== nullDataRuleIndex)) {
                filterList.push(r.filter.clone());
            }
        });
        this.currentFilterSet.filters = filterList;
        this.currentBreaks = filterList.length;
    }

    setInsufficientValue() {
        const isMulti = this.variableSelection.isMultiVariable;
        const renderer = this.appliedDataTheme.rendering[0];
        const insuffRuleIdx = isMulti ? renderer.insufficientDataRuleIndex[0] : renderer.insufficientDataRuleIndex;
        if (insuffRuleIdx >= 0) {
            const insufficientRule = isMulti ? renderer.rules[0][insuffRuleIdx] : renderer.rules[insuffRuleIdx];
            this.insufficientValue = insufficientRule.filter.to;
        } else {
            this.insufficientValue = undefined;
        }
    }

    setCurrentFilterSet() {
        if (this.currentMethod === DataClassificationMethod.CATEGORY_DEFAULT) {
            const filterSet = this.categoryFilters[0].filterSets.find(filtersSet => filtersSet.length === this.currentBreaks);
            if (filterSet) {
                this.currentFilterSet.valueFormat = filterSet.valueFormat;
                // remove filters that have from & to set to : NaN (un-sufficient data)
                this.currentFilterSet.filters = filterSet.filters.filter(f => !isNaN(f.from) || !isNaN(f.to))
                                                         .map(f => f.clone());
                this.currentFilterSet.filters[0].from = NaN;
                this.currentFilterSet.filters[this.currentFilterSet.filters.length - 1].to = NaN;
            }
        } else {
            let rawData = [];
            Object.keys(this.variablesData).forEach(variable => {
                rawData = rawData.concat(this.variablesData[variable].rawData);
            }, this);
            // For Natural Breaks number of elements must be greater then number of classes
            if (this.currentMethod === DataClassificationMethod.NATURAL_BREAKS && rawData.length <= this.currentBreaks) {
                while (rawData.length !== this.currentBreaks + 1) {
                    rawData.unshift(rawData[0]);
                }
            }

            const variableGeoStatsData = new geostats(rawData);  // eslint-disable-line
            this.currentFilterSet.filters = [];

            if (this.currentMethod === DataClassificationMethod.EQUAL_INTERVAL || this.currentMethod === DataClassificationMethod.CUSTOM) {
                variableGeoStatsData.getClassEqInterval(this.currentBreaks);
            } else if (this.currentMethod === DataClassificationMethod.QUANTILE) {
                variableGeoStatsData.getClassQuantile(this.currentBreaks);
            } else if (this.currentMethod === DataClassificationMethod.NATURAL_BREAKS) {
                variableGeoStatsData.getClassJenks(this.currentBreaks);
            } else if (this.currentMethod === DataClassificationMethod.ARITHMETIC_PROGRESSION) {
                variableGeoStatsData.getClassArithmeticProgression(this.currentBreaks);
            }

            variableGeoStatsData.ranges.forEach(range => {
                const rangeDetails = range.split(' - ');
                const f = new Filter();
                f.from = parseFloat(rangeDetails[0]);
                f.to = parseFloat(rangeDetails[1]);
                this.currentFilterSet.filters.push(f);
            });

            this.currentFilterSet.filters[0].from = NaN;
            this.currentFilterSet.filters[this.currentFilterSet.filters.length - 1].to = NaN;
        }
    }

    resetVariablesData() {
        // reset bounds
        this.variablesXMin = Number.MAX_SAFE_INTEGER;
        this.variablesXMax = -Number.MAX_SAFE_INTEGER;
        this.variablesYMin = 0;
        this.variablesYMax = 0;
        this.variablesData = {};
    }

    postFixFilters() {
        // fix situation when `to` and `from` value of two consecutive filters do not match
        this.currentFilterSet.filters.forEach((filter, idx) => {
            const previousFilter = this.currentFilterSet.filters[idx - 1];
            if (previousFilter && previousFilter.to !== filter.from) {
                filter.from = previousFilter.to;
            }
        });
        const firstFilter = this.currentFilterSet.filters[0];
        const lastFilter = this.currentFilterSet.filters[this.currentFilterSet.filters.length - 1];

        firstFilter.from = this.absMin;
        lastFilter.to = this.absMax;

        this.variablesXMin = firstFilter.from;
        this.variablesXMax = lastFilter.to;
    }

    parseRawData() {
        const fieldList = this.appliedDataTheme.rendering[0].fieldList;
        this.rawData = this.mapViewer.dragonflyMap.queryRenderedFeatures(undefined, { layers: [this.mapViewer.higlightLayerId] })
                           .reduce((d, el) => {
                               if (d.findIndex(e => e.id === el.id) === -1) {
                                   d.push({ id: el.id, properties: el.properties });
                               }
                               fieldList.calculateComputed(el.properties);
                               return d;
                           }, []);

        // filter out features that have no relevant data for any of the variables
        this.rawData = this.rawData.filter(d =>
            this.variableSelection.items.find(item => {
                if (this.isComputedVisualization) {
                    return d.properties[this.computedVariableFieldName] !== undefined;
                }
                return d.properties[item.variableGuid] !== undefined;
            })
        );
    }

    parseCurrentData() {
        this.variableSelection.items.forEach((item, itemIdx) => {
            let filteredRawData, variableGeoStatsData, buckets, length, graphData;
            if (this.isComputedVisualization) {
                filteredRawData = this.rawData.filter(d => {
                    const value = parseFloat(d.properties[this.computedVariableFieldName]);
                    return !isNaN(value) && isFinite(value);
                }).map(d => parseFloat(d.properties[this.computedVariableFieldName]));

                variableGeoStatsData = [];
                const seBuckets = [];
                let i = 0;
                const l = 100;
                for (; i < l; i += 1) {
                    variableGeoStatsData.push(0);
                    seBuckets.push({ from: (i === 0 ? -Number.MAX_SAFE_INTEGER : i), to: (i === 99 ? Number.MAX_SAFE_INTEGER : i + 1) });
                }

                const incrementBucket = value => {
                    const bucketIndex = seBuckets.findIndex(b => value >= b.from && value < b.to);
                    if (bucketIndex === -1) {
                        throw new Error('wtf?');
                    }
                    variableGeoStatsData[bucketIndex] += 1;
                };
                filteredRawData.forEach(p => {
                    incrementBucket(p);
                });
                buckets = new geostats(variableGeoStatsData); // eslint-disable-line
                length = 1;
                graphData = DataClassificationController.getGraphData(buckets.serie, length);
            } else {
                filteredRawData = this.rawData.filter(d => {
                    const value = parseFloat(d.properties[item.variableGuid]);
                    return !isNaN(value) && isFinite(value);
                }).map(d => parseFloat(d.properties[item.variableGuid]));

                // In some casses the data team adds a variable with no values due to consistency across years
                // so we need to skip those variables
                if (filteredRawData.length > 0) {
                    variableGeoStatsData = new geostats(filteredRawData); // eslint-disable-line
                    variableGeoStatsData.getEqInterval(100);
                    variableGeoStatsData.doCount();
                    buckets = new geostats(variableGeoStatsData.counter); // eslint-disable-line
                    length = Math.abs(variableGeoStatsData.max() - variableGeoStatsData.min()) / 100;
                    graphData = DataClassificationController.getGraphData(buckets.serie, length);
                }
            }

            this.variablesYMax = Math.max(this.variablesYMax, buckets === undefined ? null : buckets.max());
            this.variablesYMin = Math.min(this.variablesYMin, buckets === undefined ? null : buckets.min());

            let variableColors;
            if (this.variableSelection.isMultiVariable) {
                variableColors = this.currentColorPalette.colorRamps[itemIdx].interpolateColors(this.currentBreaks);
            } else {
                variableColors = this.currentColorPalette.interpolateColors(this.currentBreaks);
            }

            this.variablesData[item.variableGuid] = {
                rawData: filteredRawData,
                buckets: buckets === undefined ? undefined : buckets.serie,
                data: graphData,
                colors: variableColors,
            };
        });
    }

    static getGraphData(buckets, length) {
        return buckets.reduce((newBuckets, b, bIdx) => {
            newBuckets.push({ x: bIdx * length, y: b }, { x: (bIdx * length) + length, y: b });
            return newBuckets;
        }, []);
    }

    getPayload() {
        return {
            allowedBreaks: this.allowedBreaks,
            currentBreaks: this.currentBreaks.toString(),
            currentMethods: this.currentMethods,
            currentMethod: this.currentMethod,
            popupTitle: this.popupTitle,
            insufficientValue: this.insufficientValue,
            canApplyToBothMaps: this.canApplyToBothMaps,
            dsOptions: {
                variablesData: this.variablesData,
                isMultiVariable: this.variableSelection.isMultiVariable,
                xmin: this.variablesXMin,
                xmax: this.variablesXMax,
                ymin: this.variablesYMin,
                ymax: this.variablesYMax,
                filterSet: this.currentFilterSet,
            },
        };
    }

    setCurrentClassificationMethods() {
        this.currentMethods = [];

        this.currentMethods.push(
            {
                value: DataClassificationMethod.CATEGORY_DEFAULT,
                name: DataClassificationMethod.getName(DataClassificationMethod.CATEGORY_DEFAULT),
            },
            {
                value: DataClassificationMethod.EQUAL_INTERVAL,
                name: DataClassificationMethod.getName(DataClassificationMethod.EQUAL_INTERVAL),
            }
        );
        if (!this.variableSelection.isMultiVariable) {
            this.currentMethods.push(
                {
                    value: DataClassificationMethod.ARITHMETIC_PROGRESSION,
                    name: DataClassificationMethod.getName(DataClassificationMethod.ARITHMETIC_PROGRESSION),
                },
                {
                    value: DataClassificationMethod.QUANTILE,
                    name: DataClassificationMethod.getName(DataClassificationMethod.QUANTILE),
                },
                {
                    value: DataClassificationMethod.NATURAL_BREAKS,
                    name: DataClassificationMethod.getName(DataClassificationMethod.NATURAL_BREAKS),
                }
            );
        }
        this.currentMethods.push(
            {
                value: DataClassificationMethod.CUSTOM,
                name: DataClassificationMethod.getName(DataClassificationMethod.CUSTOM),
            }
        );
    }

    /**
     *  Event handler for indicating that user has customized breaks
     */
    onDataClassesDragEnd(plotBars) {
        this.plotBars = plotBars;
        plotBars.forEach((plot, plotIdx) => {
            this.currentFilterSet.filters[plotIdx].label = null;
            this.currentFilterSet.filters[plotIdx].from = plot.from;
            this.currentFilterSet.filters[plotIdx].to = plot.to;
        });

        if (this.currentMethod !== DataClassificationMethod.CUSTOM) {
            this.currentMethod = DataClassificationMethod.CUSTOM;
            this.bus.emit(DataClassificationEvents.CLASSIFICATION_METHOD_CHANGE_DONE, { currentMethod: this.currentMethod });
        }
    }

    /**
     *  Event handler for applying current data classification filter set
     *  @param {Object} payload ( contains mapInstanceId )
     */
    onCutPointApply(payload) {
        this.bus.emit('CLOSE_MODAL');
        this.bus.off('MAP_CURRENT_MAP_INSTANCE', this.boundDoDataClassification);

        const applyCutPoints = (mapInstanceId, currentMethod = this.currentMethod) => {
            if (!this.currentFilterSet || !this.currentFilterSet.filters) return;
            // clear filter data
            this.currentFilterSet.filters.forEach((filter, idx) => {
                if (!isNaN(filter.from)) {
                    filter.from = Math.round(filter.from * 100) / 100;
                }
                if (!isNaN(filter.to)) {
                    filter.to = Math.round(filter.to * 100) / 100;
                }
                if (idx === this.currentFilterSet.filters.length - 1) filter.to = Number.MAX_SAFE_INTEGER;
                if (idx === 0) filter.from = -Number.MAX_SAFE_INTEGER;
            });

            this.bus.emit(DataClassificationEvents.APPLY_FILTERS_REQUEST, {
                filterSet: this.currentFilterSet,
                filters: this.currentFilterSet.filters,
                dataClassificationMethod: currentMethod,
                insufficientBase: this.insufficientValue,
                mapInstanceId,
            });
        };

        if (payload.shouldApplyToBothMaps) {
            const mapViewers = [];
            const onMapViewerRetrieved = mapViewerEvent => {
                mapViewers.push(mapViewerEvent.source);
                if (mapViewers.length === 2) {
                    this.bus.off(onMapViewerRetrieved);
                    mapViewers.forEach(mv => {
                        applyCutPoints(mv.id, mv.id === payload.mapInstanceId ? this.currentMethod : DataClassificationMethod.CUSTOM);
                    });
                }
            };
            this.bus.on('MAP_VIEWER', onMapViewerRetrieved);
            this.bus.emit('MAP_GET_MAP_VIEWER');
        } else {
            applyCutPoints(payload.mapInstanceId);
        }
    }

    /**
     *  Event handler for changing Number of classes
     *  @param {Object} payload ( contains changed value and mapInstanceId )
     */
    onDataClassesNumberChange(payload) {
        if (this.rawData.length === 0) {
            this.bus.emit(DataClassificationEvents.DATA_CLASSIFICATION_DATA_REQUEST_ERROR, {
                level: Errors.ERROR,
                additionalInfo: 'There is no data needed for statistic calculations',
            });
            return;
        }
        const newBreaks = parseInt(payload.value, 10);
        if (newBreaks === this.currentBreaks) return;

        this.currentBreaks = newBreaks;
        this.resetVariablesData();
        this.parseCurrentData();
        this.setCurrentFilterSet();
        this.postFixFilters();
        this.bus.emit(DataClassificationEvents.DATA_CLASSIFICATION_DATA_REQUEST_DONE, this.getPayload());
    }

    onInsufficientBaseChange(payload) {
        if (payload.insufficientValue !== this.insufficientValue) {
            this.insufficientValue = payload.insufficientValue;
            this.bus.emit(DataClassificationEvents.DATA_CLASSIFICATION_DATA_REQUEST_DONE, this.getPayload());
        }
    }

    /**
     *  Event handler for changing classification method
     *  @param {Object} payload ( contains changed value and mapInstanceId )
     */
    onDataClassificationMethodChange(payload) {
        if (this.rawData.length === 0) {
            this.bus.emit(DataClassificationEvents.DATA_CLASSIFICATION_DATA_REQUEST_ERROR, {
                level: Errors.ERROR,
                additionalInfo: 'There is no data needed for statistic calculations',
            });
            return;
        }
        this.currentMethod = payload.value;
        this.resetVariablesData();
        this.parseCurrentData();
        this.setCurrentFilterSet();
        this.postFixFilters();
        this.bus.emit(DataClassificationEvents.DATA_CLASSIFICATION_DATA_REQUEST_DONE, this.getPayload());
    }

    onModalClosed() {
        this._opened = false;
    }

    onDeactivate() {
        this.unbindGluBusEvents();
    }
}

export default DataClassificationController;
