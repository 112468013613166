module.exports={
  "deleteCoverSlide": "Delete cover slide",
  "deleteSlide": "Delete slide",
  "embedUrl": "Embed URL",
  "slideDescription": "Slide description",
  "pasteEmbedUrl": "Paste embed URL",
  "mediaSourceCitation": "Media source citation",
  "editBackground": "Edit background",
  "useImageAsBackground": "Use image as background",
  "browseImage": "Browse image",
  "useVideoAsBackground": "Use video as background",
  "pasteYoutubeOrVimeoLink": "Paste YouTube or Vimeo link",
  "loadVideo": "Load video",
  "duplicateSlide": "Duplicate slide",
  "addNewSlideWithSingleMap": "Add new slide with Single map",
  "addNewSlideWithSideBySideMap": "Add new slide with Side by side map",
  "addNewSlideWithSwipeMap": "Add new slide with Swipe map",
  "singleMapImageWithOverlaidTitleScalebarAndLegend": "Single map image with overlaid title, scalebar, and legend.",
  "separateImagesForMapScalebarAndLegend": "Separate images for map, scalebar, and legend.",
  "zipArchive": "ZIP Archive",
  "pngImage": "PNG Image",
  "scaleToFit": "Scale to fit",
  "zoomToFit": "Zoom to fit",
  "theImageFileSizeIsLargerThan4Mb": "The image file size is larger than 4MB.",
  "notAYoutubeOrVimeoVideo": "Not a Youtube or Vimeo video.",
  "editCoverBackground": "Edit cover background",
  "editSlideInfo": "Edit slide info",
  "useSocialExplorerMapsToPresentAnIdea": "Use Social Explorer maps to present an idea or tell a story enhanced with multimedia elements designed to bring your story to life.",
  "addNewSlide": "Add new slide",
  "addCoverSlide": "Add cover slide",
  "previewStory": "Preview story",
  "discardStory": "Discard story",
  "exportAsPowerPoint": "Export as Power Point",
  "allFramesWillBeDeleted": "All frames will be deleted except the one You are viewing currently!"
}
