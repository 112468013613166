import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Checkbox from '../../form/Checkbox';

import { dataVariableButtonEvents } from '../../../enums/GoogleTagManagerEvents';
import googleTagManagerEvents from '../../../helpers/GoogleTagManagerEventsWrapper';

// GTM events are set only for variable selection
const DataVariableButton = googleTagManagerEvents('button', dataVariableButtonEvents);
const DataVariableCheckBox = googleTagManagerEvents(Checkbox, dataVariableButtonEvents);


class DataVariable extends React.Component {
    componentDidMount() {
        this.setGTMEvents();
    }

    componentDidUpdate() {
        this.setGTMEvents();
    }

    onSelectVariable = e => {
        const { onSelect, variable } = this.props;
        if (onSelect) {
            onSelect({ variable, currentTarget: e.currentTarget });
        }
    }

    onAddVariableToSelection = () => {
        const { onAddToSelection, variable } = this.props;
        if (onAddToSelection) {
            onAddToSelection(variable);
        }
    }

    onRemoveVariableFromSelection = () => {
        const { onRemoveFromSelection, variable } = this.props;
        if (onRemoveFromSelection) {
            onRemoveFromSelection(variable);
        }
    }

    setGTMEvents = () => {
        const { variable } = this.props;
        // SurveyName|TableName|VariableName
        const gtmL = `${variable.table.dataset.survey.name}|${variable.table.name}|${variable.name}`;
        if (this.variableButton) this.variableButton.setCustomAttribute('gtm-eventl', gtmL);
        if (this.checkBox) this.checkBox.setCustomAttribute('gtm-eventl', gtmL);
    }

    renderIndent() {
        const indents = [];
        const { variable } = this.props;
        for (let i = 0; i < variable.indent; i += 1) {
            indents.push(<div key={i} className="data-variable__indent" />);
        }
        return indents;
    }

    render() {
        const { selected, className, variable, isMultipleSelection, availableForMultipleSelection, inputRef } = this.props;
        const label = <div className="body-1">{variable.getLabel(0, false)}</div>;

        const classes = classnames('data-variable flex-it grow space-between', {
            'data-variable--selected': selected,
        }, className);

        switch (true) {
        case (selected && !isMultipleSelection):
            return (<div ref={inputRef} className={classes}>
                <div className="data-variable__title grow flex-it">
                    {this.renderIndent()}
                    {label}
                </div>
                <Checkbox
                    checked
                    clickable={false}
                />
            </div>);

        case (selected && isMultipleSelection):
            return (<div ref={inputRef} className={classes}>
                <div className="data-variable__title grow flex-it clickable" onClick={this.onSelectVariable}>
                    {this.renderIndent()}
                    {label}
                </div>
                <Checkbox className="checkbox--variable" checked onCheck={this.onRemoveVariableFromSelection} />
            </div>);

        case (!selected && availableForMultipleSelection):
            return (<div className={classes}>
                <DataVariableButton ref={c => { this.variableButton = c; }} className="data-variable__title grow flex-it clickable" onClick={this.onSelectVariable}>
                    {this.renderIndent()}
                    {label}
                </DataVariableButton>
                <DataVariableCheckBox ref={c => { this.checkBox = c; }} checked={false} className="checkbox--variable checkbox--add-variable" onCheck={this.onAddVariableToSelection} />
            </div>);
        default:
            return (<DataVariableButton
                ref={c => { this.variableButton = c; }}
                className={classes}
                onClick={this.onSelectVariable}
            >
                <div className="data-variable__title grow flex-it">
                    {this.renderIndent()}
                    {label}
                </div>
                <div className="data-variable__checkbox-placeholder">
                    <i className="material-icons btn-icon hidden">check_box_outline_blank</i>
                </div>
            </DataVariableButton>);
        }
    }
}

DataVariable.propTypes = {
    className: PropTypes.string,
    variable: PropTypes.object.isRequired,
    selected: PropTypes.bool,
    isMultipleSelection: PropTypes.bool,
    availableForMultipleSelection: PropTypes.bool,
    inputRef: PropTypes.func,
    onRemoveFromSelection: PropTypes.func,
    onAddToSelection: PropTypes.func,
    onSelect: PropTypes.func,
};

DataVariable.defaultProps = {
    className: '',
    selected: false,
    isMultipleSelection: false,
    availableForMultipleSelection: false,
    inputRef: undefined,
    onRemoveFromSelection: undefined,
    onAddToSelection: undefined,
    onSelect: undefined,
};

export default DataVariable;
