import React from 'react';
import PropTypes from 'prop-types';

import VisualizationTypeList from './VisualizationTypeList';
import ChangeOverTimeVisualizationTypeList from './ChangeOverTimeVisualizationTypeList';

const VisualizationType = props => {
    const { dataTheme } = props.mapInstance;

    if (dataTheme.isChangeOverTimeApplied) {
        return <ChangeOverTimeVisualizationTypeList mapInstance={props.mapInstance} />;
    }

    return <VisualizationTypeList {...props} />;
};

VisualizationType.propTypes = {
    mapInstance: PropTypes.object.isRequired,
    shadedVisualizationTypeEnabled: PropTypes.bool.isRequired,
    bubbleVisualizationTypeEnabled: PropTypes.bool.isRequired,
    dotDensityVisualizationTypeEnabled: PropTypes.bool.isRequired,
    onChangeVisualizationType: PropTypes.func.isRequired,
};

export default VisualizationType;
