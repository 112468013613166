import React from 'react';
import PropTypes from 'prop-types';

import SelectionListItem from './SelectionListItem';

class SelectionList extends React.Component {
    onItemClick = item => {
        this.props.onChange(item.id);
    };

    render() {
        const { items, selectedItemId } = this.props;
        const selectionItems = items.map(item => (<SelectionListItem
            item={item}
            key={item.id}
            selected={item.id === selectedItemId}
            onClick={this.onItemClick}
        />));
        return (
            <div className="selection-list flex-it grow column">
                {selectionItems}
            </div>
        );
    }
}

SelectionList.propTypes = {
    items: PropTypes.array.isRequired,
    selectedItemId: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

SelectionList.defaultProps = {
    onChange: undefined,
};

export default SelectionList;
