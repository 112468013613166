import React from 'react';
import BusComponent from '../BusComponent';
import classnames from 'classnames';

class CutpointValueInput extends BusComponent {
    constructor(props, context) {
        super(props, context);

        this.state = {
            value: undefined,
            minValue: undefined,
            maxValue: undefined,
            activePlotBar: undefined,
            xAxis: undefined,
            stylePosition: {
                left: 'auto',
                right: 'auto',
                top: 0,
            },
            isHidden: false,
        };

        this.bindGluBusEvents({
            DATA_CLASSIFICATION_CUTPOINT_SELECTED: this.onCutpointSelected,
            DATA_CLASSIFICATION_CUTPOINT_VALUE_CHANGED: this.onCutpointValueChanged,
            DATA_CLASSIFICATION_BRUSH_EVENT: this.onDataClassificationBrushEvent,
            CLASSIFICATION_METHOD_CHANGE: this.onReset,
            CLASSES_NUMBER_CHANGE: this.onReset,
        });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    componentDidUpdate() {
        if (this.input && !this.state.isHidden) {
            this.input.focus();
        } else if (this.input && this.state.isHidden) {
            this.input.blur();
        }
    }

    onReset() {
        this.setState({
            activePlotBar: undefined,
            value: undefined,
            stylePosition: {
                left: 'auto',
                right: 'auto',
                top: 0,
            },
            isHidden: false,
        });
    }

    onDataClassificationBrushEvent(eventMap) {
        if (!this.state.activePlotBar) return;
        const stylePosition = this.state.stylePosition;
        const position = this._getInputPosition(this.state.xAxis, this.state.activePlotBar);
        stylePosition.left = position.left;
        stylePosition.right = position.right;
        const extent = eventMap.brushExtent;
        this.setState({
            isHidden: this.state.value < extent[0] || this.state.value > extent[1],
            stylePosition,
        });
    }

    onCutpointSelected(eventMap) {
        const stylePosition = this._getInputPosition(eventMap.xAxis, eventMap.activePlotBar);
        const value = Number.parseInt(eventMap.activePlotBar.__data__ * 100, 10) / 100;
        this.setState({
            activePlotBar: eventMap.activePlotBar,
            value,
            minValue: eventMap.activePlotBar.__previousBand__.from,
            maxValue: eventMap.activePlotBar.__nextBand__.to,
            xAxis: eventMap.xAxis,
            stylePosition,
            isHidden: value < eventMap.extent[0] || value > eventMap.extent[1],
        });
    }

    onCutpointValueChanged() {
        if (!this.state.activePlotBar) return;
        const stylePosition = this.state.stylePosition;
        const position = this._getInputPosition(this.state.xAxis, this.state.activePlotBar);
        stylePosition.left = position.left;
        stylePosition.right = position.right;
        this.setState({
            value: Number.parseInt(this.state.activePlotBar.__data__ * 100, 10) / 100,
            stylePosition,
        });
    }

    onInputValueChange = e => {
        if (!this.state.activePlotBar) return;
        let newValue = e.target.value;
        if (newValue.endsWith('.') || Number.isNaN(Number.parseFloat(newValue))) {
            this.setState({
                value: newValue,
            });
            return;
        }
        newValue = Number.parseFloat(newValue);
        newValue = Number.parseInt(newValue * 100, 10) / 100;
        this.setState({
            value: newValue,
        });
    };

    onInputValueEditDone = e => {
        e.preventDefault();

        if (!this.state.activePlotBar) return false;

        if (Number.isNaN(Number.parseFloat(this.state.value))) return false;

        this.emit('MANUAL_CUTPOINT_VALUE_REQUEST', {
            plotBar: this.state.activePlotBar,
            value: this.state.value,
        });

        this.setState({
            activePlotBar: undefined,
            value: undefined,
        });

        return false;
    };

    render() {
        if (this.state.value !== undefined && this.state.activePlotBar !== undefined) {
            return (<div
                ref={root => {
                    this.root = root;
                }}
                className={classnames('cutpoint-value-input flex-it center column', { invisible: this.state.isHidden })}
                style={{
                    left: this.state.stylePosition.left,
                    right: this.state.stylePosition.right,
                    top: this.state.stylePosition.top,
                }}
            >
                <form onSubmit={this.onInputValueEditDone}>
                    <input
                        ref={input => {
                            this.input = input;
                        }}
                        className="input"
                        value={this.state.value}
                        onChange={this.onInputValueChange}
                    />
                </form>
            </div>);
        }
        return (<div
            ref={root => {
                this.root = root;
            }}
            className="cutpoint-value-input invisible"
        />);
    }

    _getInputPosition(xAxis, activePlotBar) {
        const offset = 40;
        const width = this.root.getBoundingClientRect().width;
        const parentNodebBox = this.root.parentNode.getBoundingClientRect();
        const circle = [...activePlotBar.childNodes].find(node => node.nodeName === 'circle');
        const bBox = circle.getBoundingClientRect();
        let left = bBox.left + offset;
        if (width + left > parentNodebBox.left + parentNodebBox.width) {
            left -= offset + (offset / 2) + width;
        }
        return {
            left,
            right: 'auto',
            top: bBox.top,
        };
    }
}

export default CutpointValueInput;
