import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

class SelectionToolButton extends React.Component {
    onClick = () => {
        this.props.onClick(this.props.value);
    }

    render() {
        const { active, disabled, icon, title } = this.props;
        const classes = classNames('map-selection-ctrl__tool btn-flat', {
            'btn-flat--selected': active,
            'btn-flat--disabled': disabled,
        });
        return (
            <button className={classes} onClick={this.onClick} title={title}>
                <i className={icon} aria-label={title}>
                    {this.props.children}
                </i>
            </button>
        );
    }
}

SelectionToolButton.propTypes = {
    icon: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    active: PropTypes.bool,
    disabled: PropTypes.bool,
    title: PropTypes.string,
    children: PropTypes.string,
};

SelectionToolButton.defaultProps = {
    active: false,
    disabled: false,
    title: undefined,
    children: undefined,
};

export default SelectionToolButton;
