import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import BusComponent from '../../BusComponent';
import classNames from 'classnames';
import Popover from '../../popover/Popover';

import UserMapViewItem from './UserMapViewItem';

const POPUP_HORIZONTAL_POSITION = ['left', 'right'];
const POPUP_VERTICAL_OFFSET = -20;
const MAP_VIEWS_LIMIT = 20;

class UserMapViewsList extends BusComponent {
    onSelectOption = item => {
        const { mapInstanceId } = this.props;
        const { centerLat, centerLng, zoom } = item.mapView;
        const { boundingBoxSwLng, boundingBoxSwLat, boundingBoxNeLng, boundingBoxNeLat } = item.mapView;

        const center = [centerLng, centerLat];
        const bounds = [[boundingBoxSwLng, boundingBoxSwLat], [boundingBoxNeLng, boundingBoxNeLat]];

        const payload = {
            mapInstanceId,
            center,
            zoom,
            bounds,
            padding: 0,
        };

        this.bus.emit('MAP_FLY_TO_REQUEST', payload);
    }

    onEditOption = item => {
        this.emit('EDIT_USER_MAP_VIEW_POPUP_REQUEST', {
            mapInstanceId: this.props.mapInstanceId,
            item,
        });
    }

    onRemoveOption = item => {
        this.emit('REMOVE_USER_MAP_VIEW_REQUEST', item);
    }

    onSaveCurrentMapView = () => {
        this.emit('ADD_USER_MAP_VIEW_POPUP_REQUEST', { mapInstanceId: this.props.mapInstanceId });
    }

    render() {
        const { anchorElement, alignLeft, open, userMapViews, onClose } = this.props;

        const mapViewsNumber = userMapViews.length;
        const mapViewsCountInfo = `${mapViewsNumber} of ${MAP_VIEWS_LIMIT} saved`;
        const horizontalPosition = alignLeft ? POPUP_HORIZONTAL_POSITION[0] : POPUP_HORIZONTAL_POSITION[1];

        const maxLimitReached = mapViewsNumber === MAP_VIEWS_LIMIT;

        const userMapViewsHeaderClasses = classNames('user-map-views__header', {
            'user-map-views__header--bordered': mapViewsNumber !== 0,
        });

        const userMapViewsSaveButtonClasses = classNames('user-map-views__save-button', {
            disabled: maxLimitReached,
        });

        const userMapViewsContent = userMapViews.map(item =>
            <UserMapViewItem
                key={item.id}
                item={item}
                onSelect={this.onSelectOption}
                onEdit={this.onEditOption}
                onRemove={this.onRemoveOption}
            />
        );

        return (
            <Popover
                anchorEl={anchorElement}
                open={open}
                className="user-map-views__popover"
                autoWidth
                marginTop={POPUP_VERTICAL_OFFSET}
                anchorOrigin={{ horizontal: horizontalPosition, vertical: 'bottom' }}
                targetOrigin={{ horizontal: horizontalPosition, vertical: 'top' }}
                onCloseRequest={onClose}
            >
                <div className="user-map-views">
                    <div className={userMapViewsHeaderClasses}>
                        <div className="user-map-views__header--title">
                            <h5>
                                {this.props.intl.formatMessage({ id: 'map.mapPositions' })}
                            </h5>
                            {mapViewsCountInfo}
                        </div>
                        <button
                            className="btn-icon flex-it justify-center center"
                            onClick={onClose}
                        >
                            <i className="material-icons">close</i>
                        </button>
                    </div>
                    <div className="user-map-views__list">
                        {userMapViewsContent}
                    </div>
                    <div className="user-map-views__footer">
                        <button
                            className={userMapViewsSaveButtonClasses}
                            disabled={maxLimitReached}
                            onClick={this.onSaveCurrentMapView}
                        >
                            <i className="material-icons">add</i>
                            <div className="font-14 font-bold-6 margin-left-5">
                                {this.props.intl.formatMessage({ id: 'map.saveCurrentMapPosition' })}
                            </div>
                        </button>
                    </div>
                </div>
            </Popover>
        );
    }
}

UserMapViewsList.propTypes = {
    mapInstanceId: PropTypes.string.isRequired,
    anchorElement: PropTypes.object.isRequired,
    leftAlignment: PropTypes.bool,
    open: PropTypes.bool,
    userMapViews: PropTypes.array,
    onClose: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
};

UserMapViewsList.defaultProps = {
    leftAlignment: false,
    open: false,
    userMapViews: undefined,
};

export default injectIntl(UserMapViewsList);
