'use strict';var ref = require('./types');
var toString = ref.toString;
var ParsingContext = require('./parsing_context');
var EvaluationContext = require('./evaluation_context');
var CompoundExpression = function CompoundExpression(name, type, evaluate, args) {
    this.name = name;
    this.type = type;
    this._evaluate = evaluate;
    this.args = args;
};
CompoundExpression.prototype.evaluate = function evaluate(ctx) {
    return this._evaluate(ctx, this.args);
};
CompoundExpression.prototype.eachChild = function eachChild(fn) {
    this.args.forEach(fn);
};
CompoundExpression.parse = function parse(args, context) {
    var op = args[0];
    var definition = CompoundExpression.definitions[op];
    if (!definition) {
        return context.error('Unknown expression "' + op + '". If you wanted a literal array, use ["literal", [...]].', 0);
    }
    var type = Array.isArray(definition) ? definition[0] : definition.type;
    var availableOverloads = Array.isArray(definition) ? [[
            definition[1],
            definition[2]
        ]] : definition.overloads;
    var overloads = availableOverloads.filter(function (ref) {
        var signature = ref[0];
        return !Array.isArray(signature) || signature.length === args.length - 1;
    });
    var parsedArgs = [];
    for (var i = 1; i < args.length; i++) {
        var arg = args[i];
        var expected = void 0;
        if (overloads.length === 1) {
            var params = overloads[0][0];
            expected = Array.isArray(params) ? params[i - 1] : params.type;
        }
        var parsed = context.parse(arg, 1 + parsedArgs.length, expected);
        if (!parsed) {
            return null;
        }
        parsedArgs.push(parsed);
    }
    var signatureContext = null;
    for (var i$2 = 0, list = overloads; i$2 < list.length; i$2 += 1) {
        var ref = list[i$2];
        var params$1 = ref[0];
        var evaluate = ref[1];
        signatureContext = new ParsingContext(context.definitions, context.path, null, context.scope);
        if (Array.isArray(params$1)) {
            if (params$1.length !== parsedArgs.length) {
                signatureContext.error('Expected ' + params$1.length + ' arguments, but found ' + parsedArgs.length + ' instead.');
                continue;
            }
        }
        for (var i$1 = 0; i$1 < parsedArgs.length; i$1++) {
            var expected$1 = Array.isArray(params$1) ? params$1[i$1] : params$1.type;
            var arg$1 = parsedArgs[i$1];
            signatureContext.concat(i$1 + 1).checkSubtype(expected$1, arg$1.type);
        }
        if (signatureContext.errors.length === 0) {
            return new CompoundExpression(op, type, evaluate, parsedArgs);
        }
    }
    if (overloads.length === 1) {
        context.errors.push.apply(context.errors, signatureContext.errors);
    } else {
        var expected$2 = overloads.length ? overloads : availableOverloads;
        var signatures = expected$2.map(function (ref) {
            var params = ref[0];
            return stringifySignature(params);
        }).join(' | ');
        var actualTypes = parsedArgs.map(function (arg) {
            return toString(arg.type);
        }).join(', ');
        context.error('Expected arguments of type ' + signatures + ', but found (' + actualTypes + ') instead.');
    }
    return null;
};
CompoundExpression.register = function register(expressions, definitions) {
    CompoundExpression.definitions = definitions;
    for (var name in definitions) {
        expressions[name] = CompoundExpression;
    }
};
function varargs(type) {
    return { type: type };
}
function stringifySignature(signature) {
    if (Array.isArray(signature)) {
        return '(' + signature.map(toString).join(', ') + ')';
    } else {
        return '(' + toString(signature.type) + '...)';
    }
}
module.exports = {
    CompoundExpression: CompoundExpression,
    varargs: varargs
};