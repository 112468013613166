// @ts-check
class ColorPalettes {
    set colorPalettes(colorPalettes) {
        this._colorPalettes = colorPalettes;
    }

    get colorPalettes() {
        return this._colorPalettes;
    }

    /**
     * @param {string} colorPaletteURL
     * @returns {import('./ColorPalette').default[]}
     */
    getCurrentColorPalettes(colorPaletteURL) {
        const combinedColorPalettes = [];
        if (colorPaletteURL === undefined) {
            Object.keys(this.colorPalettes).forEach(colorPalettesId => {
                Array.prototype.push.apply(combinedColorPalettes, this.colorPalettes[colorPalettesId]);
            });
        } else {
            Array.prototype.push.apply(combinedColorPalettes, this.colorPalettes[colorPaletteURL]);
            Array.prototype.push.apply(combinedColorPalettes, this.colorPalettes.project_color_palettes);
        }
        return combinedColorPalettes;
    }

    getColorPaletteById(id, colorPaletteURL) {
        if (!this._colorPalettes) return undefined;
        return this.getCurrentColorPalettes(colorPaletteURL).find(cp => cp.id === id);
    }

    getColorPaletteByType(type, colorPaletteURL, index = 0) {
        if (!this._colorPalettes) return undefined;
        return this.getCurrentColorPalettes(colorPaletteURL).filter(cp => cp.type.toLowerCase() === type.toLowerCase())[index];
    }

    getColorPalettesByType(type, colorPaletteURL) {
        if (!this._colorPalettes) return undefined;
        return this.getCurrentColorPalettes(colorPaletteURL).filter(cp => cp.type.toLowerCase() === type.toLowerCase());
    }

    /**
     * @param {string} type example 'polygon-sequential'
     * @param {string} id color palette id, example 'SE Orange'
     * @param {string} colorPaletteURL example '/json/color-palettes/us-demography-color-palettes.json'
     * @returns {import('./ColorPalette').default | undefined}
     */
    getColorPaletteByTypeAndId(type, id, colorPaletteURL = undefined) {
        if (!this._colorPalettes) return undefined;
        return this.getCurrentColorPalettes(colorPaletteURL).find(
            cp => cp.id === id && cp.type.toLowerCase() === type.toLowerCase(),
        );
    }
}

export default ColorPalettes;
