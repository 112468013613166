/** @type {Object.<string, import('../../..').LocationAnalysisType>} */
const LocationAnalysisType = {
    RADIUS: {
        ID: 'RADIUS',
        NAME: 'Radius',
        VALUES_ARRAY: [1, 2, 3, 4, 5, 6, 8, 10],
        UNIT: 'mile',
        // TODO: I'm not sure where properties UNIT_LONG and UNIT_LONG_PLURAL
        // are used inside the application
        UNIT_LONG: 'mile',
        UNIT_LONG_PLURAL: 'miles',
        UNIT_DESC: 'radius',
        UNIT_DESC_PLURAL: 'radiuses',
        IMG: 'radius',
        ICON: 'album',
        MAPBOX_ROUTING_PROFILE: undefined,
        MAX_CUSTOM_VALUE: 20,
        VISUAL_REPORT_PROFILE: 'radius',
        ISGEOGRAPHY: false,
        CONTOUR_TYPE: 'radius',
    },
    DRIVING_TIME: {
        ID: 'DRIVING_TIME',
        NAME: 'Drive time',
        VALUES_ARRAY: [5, 10, 15, 20, 25, 30, 45, 60],
        UNIT: 'min',
        UNIT_LONG: 'minute',
        UNIT_LONG_PLURAL: 'minutes',
        UNIT_DESC: 'duration',
        UNIT_DESC_PLURAL: 'durations',
        IMG: 'drive-time',
        ICON: 'drive_eta',
        MAPBOX_ROUTING_PROFILE: 'mapbox/driving',
        NOTE: 'We are using average traffic data for drive time. ',
        // maximum value is set to 60 minutes because of limitation in mapbox API
        // ref: https://docs.mapbox.com/api/navigation/isochrone/#isochrone-api-restrictions-and-limits
        MAX_CUSTOM_VALUE: 60,
        VISUAL_REPORT_PROFILE: 'driving',
        ISGEOGRAPHY: false,
        CONTOUR_TYPE: 'driving',
    },
    WALKING_TIME: {
        ID: 'WALKING_TIME',
        NAME: 'Walking',
        VALUES_ARRAY: [5, 10, 15, 20, 25, 30, 45, 60],
        UNIT: 'min',
        UNIT_LONG: 'minute',
        UNIT_LONG_PLURAL: 'minutes',
        UNIT_DESC: 'duration',
        UNIT_DESC_PLURAL: 'durations',
        IMG: 'walking',
        ICON: 'directions_walk',
        MAPBOX_ROUTING_PROFILE: 'mapbox/walking',
        NOTE: 'We are using average traffic data for walking time. ',
        // maximum value is set to 60 minutes because of limitation in mapbox API
        // ref: https://docs.mapbox.com/api/navigation/isochrone/#isochrone-api-restrictions-and-limits
        MAX_CUSTOM_VALUE: 60,
        VISUAL_REPORT_PROFILE: 'walking',
        ISGEOGRAPHY: false,
        CONTOUR_TYPE: 'walking',
    },
    CYCLING_TIME: {
        ID: 'CYCLING_TIME',
        NAME: 'Cycling',
        VALUES_ARRAY: [5, 10, 15, 20, 25, 30, 45, 60],
        UNIT: 'min',
        UNIT_LONG: 'minute',
        UNIT_LONG_PLURAL: 'minutes',
        UNIT_DESC: 'duration',
        UNIT_DESC_PLURAL: 'durations',
        IMG: 'cycling',
        ICON: 'directions_bike',
        MAPBOX_ROUTING_PROFILE: 'mapbox/cycling',
        NOTE: 'We are using average traffic data for cycling time. ',
        // maximum value is set to 60 minutes because of limitation in mapbox API
        // ref: https://docs.mapbox.com/api/navigation/isochrone/#isochrone-api-restrictions-and-limits
        MAX_CUSTOM_VALUE: 60,
        VISUAL_REPORT_PROFILE: 'cycling',
        ISGEOGRAPHY: false,
        CONTOUR_TYPE: 'cycling',
    },
    GEO: {
        ID: 'GEO',
        NAME: 'Geography',
        VALUES_ARRAY: [],
        UNIT: '',
        UNIT_LONG: '',
        UNIT_LONG_PLURAL: '',
        UNIT_DESC: '',
        UNIT_DESC_PLURAL: '',
        IMG: '',
        ICON: '',
        MAPBOX_ROUTING_PROFILE: undefined,
        NOTE: undefined,
        MAX_CUSTOM_VALUE: 20,
        VISUAL_REPORT_PROFILE: '',
        ISGEOGRAPHY: true,
        CONTOUR_TYPE: 'custom_geo',
    },
};

export default LocationAnalysisType;
