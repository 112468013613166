import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import UnavailableDataCategory from './UnavailableDataCategory';
import AvailableDataCategory from './AvailableDataCategory';
import classnames from 'classnames';

class DataCategoriesList extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            availableDataCategories: props.dataCategories.filter(dc => dc.availableYears.indexOf(props.year) !== -1),
            unavailableDataCategories: props.dataCategories.filter(dc => dc.availableYears.indexOf(props.year) === -1),
        };
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.dataCategories !== nextProps.dataCategories || this.props.year !== nextProps.year) {
            this.setState({
                availableDataCategories: nextProps.dataCategories.filter(dc => dc.availableYears.indexOf(nextProps.year) !== -1),
                unavailableDataCategories: nextProps.dataCategories.filter(dc => dc.availableYears.indexOf(nextProps.year) === -1),
            });
        }
    }

    onSelectedDataCategoryChange = (dataCategory, year) => {
        if (this.props.onCategoryChange) {
            this.props.onCategoryChange(dataCategory, year);
        }
    }

    renderAvailableCategories() {
        return this.state.availableDataCategories.map(dt => (<AvailableDataCategory
            className="data-category--available"
            key={dt.name}
            dataCategory={dt}
            onSelectedDataCategoryChange={this.onSelectedDataCategoryChange}
        />));
    }

    renderUnavailableDataCategories() {
        if (this.state.unavailableDataCategories.length) {
            return [
                (<span
                    key="unavailable-categories-title"
                    className="data-categories-list__subtitle"
                >{this.props.intl.formatMessage({ id: 'variablePicker.notAvailableForSelectedYear' })}</span>),
                ...this.state.unavailableDataCategories.map(dc => <UnavailableDataCategory
                    className="data-category--unavailable"
                    key={dc.name}
                    dataCategory={dc}
                    onSelectedDataCategoryChange={this.onSelectedDataCategoryChange}
                />),
            ];
        }
        return null;
    }

    render() {
        const classes = classnames('data-categories-list vertical-scrollbar flex-it column stretch', this.props.className);
        return (<div className={classes}>
            <div className="data-categories-list__available">
                {this.renderAvailableCategories()}
            </div>
            <div className="data-categories-list__unavailable">
                {this.renderUnavailableDataCategories()}
            </div>
        </div>);
    }
}

DataCategoriesList.propTypes = {
    className: PropTypes.string,
    dataCategories: PropTypes.array,
    year: PropTypes.number.isRequired,
    onCategoryChange: PropTypes.func,
    intl: PropTypes.object.isRequired,
};

DataCategoriesList.defaultProps = {
    className: '',
    dataCategories: [],
    onCategoryChange: undefined,
};

export default injectIntl(DataCategoriesList);
