// @ts-check
import React from 'react';
import { injectIntl } from 'react-intl';

import Tooltip from '../Tooltip';
import BusComponent from '../BusComponent';
import ApplicationMode from '../../enums/ApplicationMode';
import CenterMapLayerButton from './CenterMapLayerButton';
import LocationPanelDistance from './LocationPanelDistance';

/**
 * @typedef Props
 * @property {string} mapInstanceId
 * @property {import('../../objects/LocationAnalysisItem').default} locationAnalysisItem
 * @property {import('react-intl').intlShape} intl
 *
 * @extends {BusComponent<Props>}
 */
class LocationPanelMinimized extends BusComponent {
    onClose = () => {
        this.emit('EXIT_LOCATION_ANALYSIS_MODE', {
            mapInstanceId: this.props.mapInstanceId,
        });
    };

    onExpand = () => {
        this.emit('MAXIMIZE_LOCATION_PANEL', {
            mapInstanceId: this.props.mapInstanceId,
        });
    }

    render() {
        const { applicationMode } = this.context;
        // If the application mode is view/embed do not show the panel
        if (ApplicationMode.isViewMode(applicationMode)) {
            return null;
        }

        const { mapInstanceId, locationAnalysisItem } = this.props;

        let distanceContainer;
        // Do not show the measure indicator for:
        // - incomplete analysis since we do not know the measure yet
        // - geography analysis doesn't have a measure
        if (locationAnalysisItem.isComplete && !locationAnalysisItem.isGeography) {
            distanceContainer = (
                <LocationPanelDistance
                    analysisType={locationAnalysisItem.analysisType}
                    selection={locationAnalysisItem.selection}
                />
            );
        }

        const overlay = (
            <div style={{ textAlign: 'center' }}>
                {this.props.intl.formatMessage({
                    id: 'dataBrowser.expandDriveTime',
                })}
            </div>
        );

        return (
            <div className="location-panel-mini">
                <Tooltip
                    placement="bottom"
                    mouseEnterDelay={0.5}
                    mouseLeaveDelay={0}
                    overlay={overlay}
                >
                    <button
                        className="btn location-panel-mini__btn"
                        onClick={this.onExpand}
                    >
                        {distanceContainer}
                        <div className="location-panel-mini__value">
                            <i className="material-icons-outlined location-panel-mini__symbol">
                                place
                            </i>
                            <span className="ellipsis">
                                {locationAnalysisItem.value}
                            </span>
                        </div>
                        <i className="material-icons btn btn-flat-icon btn-flat-icon--outlined">
                            unfold_more
                        </i>
                    </button>
                </Tooltip>
                <CenterMapLayerButton
                    mapInstanceId={mapInstanceId}
                    zoomPoint={locationAnalysisItem.point}
                />
                <Tooltip
                    placement="bottom"
                    mouseEnterDelay={0.5}
                    mouseLeaveDelay={0}
                    overlay={this.props.intl.formatMessage({
                        id: 'dataBrowser.closeAnalysis',
                    })}
                >
                    <button
                        className="material-icons btn-flat-icon"
                        onClick={this.onClose}
                    >
                        close
                    </button>
                </Tooltip>
            </div>
        );
    }
}

export default injectIntl(LocationPanelMinimized);
