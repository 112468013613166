import uuid from 'node-uuid';

import BaseController from './BaseController';

import MetadataDataSource from '../dataSources/MetadataDataSource';
import UserInfoDataSource from '../dataSources/UserInfoDataSource';
import ProjectDataSource from '../dataSources/ProjectDataSource';
import ApplicationModeDataSource from '../dataSources/ApplicationModeDataSource';
import VariableType from '../enums/VariableType';
import VisualizationType from '../enums/VisualizationType';
import VariableValueType from '../enums/VariableValueType';
import ApplicationMode from '../enums/ApplicationMode';
import UserLicenseInfo from '../enums/UserLicenseInfo';
import VariableSelectionItem from '../objects/VariableSelectionItem';

import {
    getMetadataObjectsFromVariableSelectionItem,
    canVariableSelectionUseBubbles,
    canVariableSelectionUseShaded,
    canVariableSelectionUseDotDensity,
} from '../helpers/Util';
import '../helpers/Polyfills';


const SE_DATASET_ABBREVIATION = 'SE';

class MetadataController extends BaseController {
    static get name() {
        return 'MetadataController';
    }

    static getInstance(options) {
        return new MetadataController(options);
    }

    onActivate() {
        this.bindGluBusEvents({
            PARTIAL_METADATA_LOAD_REQUEST: this.onPartialMetadataLoadRequest,
            METADATA_LOAD_REQUEST: this.onMetadataLoadRequest,
            SURVEYS_LOAD_REQUEST: this.onSurveysLoadRequest,
            DATASET_LOAD_REQUEST: this.onDatasetLoadRequested,
            TABLE_LOAD_REQUEST: this.onTableLoadRequest,
            CATEGORY_TABLES_LOAD_REQUEST: this.onCategoryTablesLoadRequest,
            USER_INFO_LOAD_SUCCESS: this.updateMetadataPermissions,
            SIGN_OUT_SUCCESS: this.updateMetadataPermissions,
            CURRENT_METADATA_REQUEST: this.onCurrentMetadataRequest,
            GROUPS_METADATA_LOAD_REQUEST: this.onGroupsMetadataLoadRequest,
            LIBRARY_DATA_LOAD_REQUEST: this.onLibraryDataLoadRequest,
            FEATURED_CATEGORIES_DATA_LOAD_REQUEST: this.onFeaturedCategoriesDataLoadRequest,
            GROUPS_METADATA_REQUEST: this.onGroupsMetadataRequest,
            LIBRARY_DATA_REQUEST: this.onLibraryDataRequest,
            GET_VISUALIZATION_TYPE_AVAILABILITY_INFO_REQUEST: this.onGetVisualizationTypeAvailabilityInfoRequest,
            GET_DATA_CLASSIFICATION_AVAILABILITY_INFO_REQUEST: this.onGetDataClassificationAvailabilityInfoRequest,
            UPDATE_METADATA_BROWSING_INFO_REQUEST: this.onUpdateMetadataBrowsingInfoRequest,
            VARIABLE_SELECTION_METADATA_INFO_REQUEST: this.onVariableSelectionMetadataInfoRequest,
            CHECK_CHANGE_OVER_TIME_AVAILABILITY: this.onCheckChangeOverTimeAvailability,
            CHECK_REPORT_AVAILABILITY: this.onCheckReportAvailability,
            CPI_VALUES_LOAD_REQUEST: this.onCpiValuesLoadRequest,
            CPI_VALUES_REQUEST: this.onCpiValuesRequest,
        });
        this.metadataDataSource = this.activateSource(MetadataDataSource);
        this.userInfoDataSource = this.activateSource(UserInfoDataSource);
        this.projectDataSource = this.activateSource(ProjectDataSource);
        this.applicationModeDataSource = this.activateSource(ApplicationModeDataSource);
    }

    /**
     * This method checks if reporting is available for the selected survey
     * @param {object} param0
     * @param {import('../objects/MetaSurvey').default} param0.surveyCode
     * @param {import('../components/BusComponent').default} param0.source
     */
    onCheckReportAvailability = ({ survey, source }) => {
        /** @type {import('../../..').Survey} */
        const surveyGroupsSurvey = this.metadataDataSource.surveyGroups
            .flatMap(surveyGroup => surveyGroup.dataSets)
            .find(s => s.code === survey.name);

        this.bus.emit('REPORT_AVAILABILITY_STATUS', {
            available: surveyGroupsSurvey
                ? surveyGroupsSurvey.availableInReports
                : false,
            source,
        });
    }

    onCheckChangeOverTimeAvailability = async ({ mapInstanceId, source }) => {
        const mapInstance = this.projectDataSource.getActiveMapInstance(
            mapInstanceId,
        );
        const metadataGroup = this.metadataDataSource.groupsMetadata.find(
            gm => gm.id === mapInstance.metadataGroupId,
        );

        /** @type {VariableSelectionItem[]} */
        const comparableVariables = [];

        if (
            metadataGroup &&
            metadataGroup.changeOverTime &&
            metadataGroup.changeOverTime.length
        ) {
            const currentSelectionMeta = getMetadataObjectsFromVariableSelectionItem(
                mapInstance.dataTheme.variableSelection.items[0],
                this.metadataDataSource.currentMetadata,
            );

            // Avoid loading metadata of COT surveys if current selection is
            // not available for change over time preview
            const changeOverTime = metadataGroup.changeOverTime.find(cot =>
                cot.surveys.some(
                    survey => survey.name === currentSelectionMeta.survey.name,
                ),
            );

            // if the selected survey is not a part of the defined cot
            // don't do anything
            if (!changeOverTime) return;

            const cotSurveys = changeOverTime.surveys;
            const isSeDataset =
                currentSelectionMeta.dataset.abbrevation ===
                SE_DATASET_ABBREVIATION;

            const surveys = cotSurveys.map(survey => {
                // Survey dataset abbreviation is reduced survey name, perform simple replace action when generating items
                // TODO: there is room for improvement here. Maybe find a way to group comparable datasets somehow?!
                const datasetAbbreviation = isSeDataset
                    ? SE_DATASET_ABBREVIATION
                    : survey.datasetAbbreviation;

                return {
                    name: survey.name,
                    datasetAbbreviation,
                };
            });

            // load survey metadata first
            await this.metadataDataSource.loadSurveys(
                surveys.map(survey => survey.name).join(),
            );

            // load table metadata second
            await Promise.allSettled(
                surveys.map(survey =>
                    this.metadataDataSource.loadTable(
                        survey.name,
                        survey.datasetAbbreviation,
                        currentSelectionMeta.table.name,
                    ),
                ),
            );

            // now check in which tables is the current selected variable available
            surveys.forEach(survey => {
                const surveyMetadata = this.metadataDataSource.currentMetadata
                    .surveys[survey.name];

                if (!surveyMetadata) return;
                const datasetMetadata =
                    surveyMetadata.datasets[survey.datasetAbbreviation];

                if (!datasetMetadata) return;
                const tableMetadata =
                    datasetMetadata.tables[currentSelectionMeta.table.name];

                if (!tableMetadata) return;
                const variableMetadata =
                    tableMetadata.variables[currentSelectionMeta.variable.name];

                if (variableMetadata) {
                    comparableVariables.push(
                        new VariableSelectionItem({
                            variableGuid: variableMetadata.uuid,
                            tableGuid: tableMetadata.uuid,
                            datasetAbbreviation: datasetMetadata.abbrevation,
                            surveyName: surveyMetadata.name,
                        }),
                    );
                }
            });

            comparableVariables.sort(
                (a, b) =>
                    this.metadataDataSource.currentMetadata.surveys[
                        a.surveyName
                    ].year -
                    this.metadataDataSource.currentMetadata.surveys[
                        b.surveyName
                    ].year,
            );
        }

        this.bus.emit('CHANGE_OVER_TIME_AVAILABILITY_STATUS', {
            source,
            comparableVariables,
        });
    }

    onCurrentMetadataRequest(currentMetadataRequest) {
        this.bus.emit('CURRENT_METADATA', this.metadataDataSource.currentMetadata, currentMetadataRequest.source);
    }

    onGroupsMetadataRequest(groupsMetadataRequest) {
        this.bus.emit('GROUPS_METADATA', this.metadataDataSource.groupsMetadata, groupsMetadataRequest.source);
    }

    onLibraryDataRequest(libraryDataRequest) {
        this.bus.emit('LIBRARY_DATA', this.metadataDataSource.libraryData, libraryDataRequest.source);
    }


    updateMetadataPermissions() {
        if (this.metadataDataSource.currentMetadata) {
            this.metadataDataSource.currentMetadata.updatePermissions(this.userInfoDataSource.currentUser);
            this.bus.emit('METADATA_PERMISSIONS_UPDATED');
        }
    }

    onVariableSelectionMetadataInfoRequest(e) {
        const metadataSelection = this.metadataDataSource.getMetadataVariableSelection(e.variableSelectionItems);
        if (!metadataSelection || !metadataSelection.survey || !metadataSelection.dataset ||
            !metadataSelection.table || !metadataSelection.variables) {
            this.bus.emit('VARIABLE_SELECTION_METADATA_INFO_ERROR', {
                variableSelectionItems: e.variableSelectionItems,
                source: e.source,
                mapInstanceId: e.mapInstanceId,
                error: { message: 'Metadata not loaded' },
            });
            return;
        }
        let dataDictionaryInfo;
        if (e.dataDictionaryInfo) {
            dataDictionaryInfo = this.metadataDataSource.getDataDictionaryInfo(metadataSelection);
        }
        this.bus.emit('VARIABLE_SELECTION_METADATA_INFO', {
            source: e.source,
            metadataSelection,
            dataDictionaryInfo,
            mapInstanceId: e.mapInstanceId,
        });
    }

    onGetVisualizationTypeAvailabilityInfoRequest(variableSelection) {
        this.bus.emit('VISUALIZATION_TYPE_AVAILABILITY_INFO', {
            bubbleVisualizationTypeEnabled: canVariableSelectionUseBubbles(variableSelection, this.metadataDataSource.currentMetadata),
            shadedVisualizationTypeEnabled: canVariableSelectionUseShaded(variableSelection, this.metadataDataSource.currentMetadata),
            dotDensityVisualizationTypeEnabled: canVariableSelectionUseDotDensity(variableSelection, this.metadataDataSource.currentMetadata),
        });
    }

    onGetDataClassificationAvailabilityInfoRequest(mapInstance) {
        const currentMetadataObject = getMetadataObjectsFromVariableSelectionItem(mapInstance.dataTheme.variableSelection.items[0], this.metadataDataSource.currentMetadata);
        const visualizationType = mapInstance.dataTheme.visualizationType;
        const dataClassificationAvailability = (visualizationType === VisualizationType.SHADED_AREA && currentMetadataObject.variable.varType !== VariableType.NONE) ||
            (visualizationType === VisualizationType.BUBBLES && mapInstance.dataTheme.bubbleValueType !== VariableValueType.NUMBER && currentMetadataObject.variable.varType !== VariableType.NONE);
        this.bus.emit('DATA_CLASSIFICATION_AVAILABILITY_INFO', { dataClassificationAvailability });
    }

    onUpdateMetadataBrowsingInfoRequest(e) {
        this.metadataDataSource.updateMetadataBrowsingInfo(e.mapInstanceId, e.browsingInfo);
    }

    onGroupsMetadataLoadRequest() {
        const onError = error => {
            this.bus.emit('GROUPS_METADATA_LOAD_ERROR', { error });
        };

        const previewAllDatasets = ApplicationMode.isViewMode(this.applicationModeDataSource.currentApplicationMode) ?
            true :
            this.userInfoDataSource.currentUser.licenseInfo === UserLicenseInfo.FREE;

        this.metadataDataSource
            .loadGroupsMetadata({
                filter:
                    !ApplicationMode.isViewMode(this.applicationModeDataSource.currentApplicationMode),
                previewAllDatasets,
            })
            .then(() => {
                this.bus.emit(
                    'GROUPS_METADATA_LOAD_SUCCESS',
                    this.metadataDataSource.groupsMetadata,
                );
            })
            .catch(onError);
    }

    onLibraryDataLoadRequest() {
        const onError = error => {
            this.bus.emit('LIBRARY_DATA_LOAD_ERROR', { error });
        };
        this.metadataDataSource.loadLibraryData().then(() => {
            this.bus.emit('LIBRARY_DATA_LOAD_SUCCESS', this.metadataDataSource.libraryData);
        }).catch(onError);
    }

    onFeaturedCategoriesDataLoadRequest() {
        const onError = error => {
            this.bus.emit('FEATURED_CATEGORIES_DATA_LOAD_ERROR', { error });
        };
        this.metadataDataSource.loadFeaturedCategoriesData().then(() => {
            this.bus.emit('FEATURED_CATEGORIES_DATA_LOAD_SUCCESS', this.metadataDataSource.featuredCategoriesData);
        }).catch(onError);
    }

    onPartialMetadataLoadRequest(partialMetadataLoadRequest) {
        const onError = error => {
            this.bus.emit('PARTIAL_METADATA_LOAD_ERROR',
                {
                    partialMetadataInfo: partialMetadataLoadRequest.partialMetadataInfo,
                    target: partialMetadataLoadRequest.source,
                    error,
                });
        };

        this.metadataDataSource
            .loadPartialMetadata(partialMetadataLoadRequest.partialMetadataInfo)
            .then(partialMetadata => {
                this.metadataDataSource.currentMetadata.updatePermissions(this.userInfoDataSource.currentUser);
                this.bus.emit('PARTIAL_METADATA_LOAD_SUCCESS',
                    {
                        partialMetadata,
                        mapInstanceId: partialMetadataLoadRequest.mapInstanceId,
                        target: partialMetadataLoadRequest.source,
                    });
            })
            .catch(onError);
    }

    onMetadataLoadRequest(e) {
        const onError = error => {
            this.bus.emit('METADATA_LOAD_ERROR', { baseMapGuid: e.baseMapGuid, error });
        };

        const currentGroupMetadata = this.metadataDataSource.groupsMetadata.find(gm => gm.id === e.metadataGroupId);
        const surveysNames = currentGroupMetadata.base_maps_ids.join(',');
        this.metadataDataSource
            .loadSurveys(surveysNames)
            .then(() => {
                this.metadataDataSource.currentMetadata.updatePermissions(this.userInfoDataSource.currentUser);
                let currentMetadataSelection;
                if (e.variableSelectionItems) {
                    currentMetadataSelection = this.metadataDataSource.getMetadataVariableSelection(e.variableSelectionItems);
                }
                this.bus.emit('METADATA_LOAD_SUCCESS', {
                    metadata: this.metadataDataSource.currentMetadata,
                    browsingInfo: this.metadataDataSource.getMetadataBrowsingInfo(e.mapInstanceId),
                    mapInstanceId: e.mapInstanceId,
                    currentMetadataSelection,
                    currentGroupMetadata,
                    mapsGroupSurveys: this.metadataDataSource.getSurveysForMapsGroup(e.metadataGroupId),
                    mapsGroupAvailableDatasets: this.metadataDataSource.getAvailableDatasetsForMapsGroup(e.metadataGroupId),
                    mapsGroupDataCategories: this.metadataDataSource.getDataCategoriesForMapsGroup(e.metadataGroupId),
                    featuredCategoriesData: this.metadataDataSource.featuredCategoriesData,
                });
            }).catch(onError);
    }

    onSurveysLoadRequest(surveysLoadRequest) {
        const onError = error => {
            this.bus.emit('SURVEYS_LOAD_ERROR',
                {
                    names: surveysLoadRequest.names,
                    target: surveysLoadRequest.source,
                    error,
                });
        };

        this.metadataDataSource
            .loadSurveys(surveysLoadRequest.names)
            .then(surveys => {
                this.metadataDataSource.currentMetadata.updatePermissions(this.userInfoDataSource.currentUser);
                this.bus.emit('SURVEYS_LOAD_SUCCESS',
                    {
                        surveys,
                        mapInstanceId: surveysLoadRequest.mapInstanceId,
                        target: surveysLoadRequest.source,
                    });
            }).catch(onError);
    }

    onDatasetLoadRequested(datasetLoadRequest) {
        const onError = error => {
            this.bus.emit('DATASET_LOAD_ERROR', {
                surveyName: datasetLoadRequest.surveyName,
                datasetName: datasetLoadRequest.datasetName,
                target: datasetLoadRequest.source,
                error,
            });
        };

        this.metadataDataSource.loadDataset(datasetLoadRequest.surveyName, datasetLoadRequest.datasetName).then(dataset => {
            this.metadataDataSource.currentMetadata.updatePermissions(this.userInfoDataSource.currentUser);
            this.bus.emit('DATASET_LOAD_SUCCESS',
                {
                    dataset,
                    mapInstanceId: datasetLoadRequest.mapInstanceId,
                    target: datasetLoadRequest.source,
                });
        }).catch(onError);
    }

    onTableLoadRequest(tableLoadRequest) {
        const onError = error => {
            this.bus.emit('TABLE_LOAD_ERROR', {
                surveyName: tableLoadRequest.surveyName,
                datasetName: tableLoadRequest.datasetName,
                tableName: tableLoadRequest.tableName,
                target: tableLoadRequest.source,
                error,
            });
        };

        this.metadataDataSource.loadTable(tableLoadRequest.surveyName, tableLoadRequest.datasetName, tableLoadRequest.tableName).then(table => {
            this.metadataDataSource.currentMetadata.updatePermissions(this.userInfoDataSource.currentUser);
            this.bus.emit('TABLE_LOAD_SUCCESS',
                {
                    table,
                    mapInstanceId: tableLoadRequest.mapInstanceId,
                    target: tableLoadRequest.source,
                });
        }).catch(onError);
    }

    onCategoryTablesLoadRequest(eventMap) {
        const onError = error => {
            this.bus.emit('CATEGORY_TABLES_LOAD_ERROR', {
                surveyNames: eventMap.surveyNames,
                categoryName: eventMap.categoryName,
                target: eventMap.source,
                error,
            });
        };

        this.metadataDataSource.loadCategoryTables(eventMap.categoryName, eventMap.year, eventMap.metadataGroupId).then(tablesBySurveysList => {
            this.metadataDataSource.currentMetadata.updatePermissions(this.userInfoDataSource.currentUser);
            // filter data category tables to have only tables from groups metadata and selected year
            this.bus.emit('CATEGORY_TABLES_LOAD_SUCCESS',
                {
                    dataCategory: this.metadataDataSource.currentMetadata.systemDataCategories[eventMap.categoryName], // remove this after updating Data Filter
                    categoryName: eventMap.categoryName,
                    tablesBySurveysList,
                    year: eventMap.year,
                    metadataGroupId: eventMap.metadataGroupId,
                    mapInstanceId: eventMap.mapInstanceId,
                    target: eventMap.source,
                });
        }).catch(onError);
    }

    onCpiValuesLoadRequest() {
        this.metadataDataSource
            .loadCpiValues()
            .then(() => {
                this.bus.emit('CPI_VALUES_LOAD_SUCCESS');
            })
            .catch(error => {
                console.error(error);
            });
    }

    onCpiValuesRequest(request) {
        this.bus.emit(
            'CPI_VALUES',
            this.metadataDataSource.cpiValuesByYear,
            request.source,
        );
    }

    onDeactivate() {
        this.unbindGluBusEvents();
    }
}

export default MetadataController;
