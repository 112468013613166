import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import AnnotationCard from './AnnotationCard';
import AnnotationExportButton from './AnnotationExportButton';

const AnnotationCardList = ({
    annotations,
    expandedAnnotationId,
    onToggleExpansion,
    onChangeAnnotationVisibilityInLegend,
    onEditClicked,
    onExportClicked,
    intl,
}) => (
    <div className="annotator-section">
        <div className="annotator-section__header">
            <div className="flex-it">
                <span className="body-2">{intl.formatMessage({ id: 'annotations' })}</span>
            </div>
            <div className="flex-it">
                <button className="btn-flat" onClick={onEditClicked}>
                    {intl.formatMessage({ id: 'dataBrowser.editList' })}
                </button>
                <div className="divider margin-horizontal-8" />
                <AnnotationExportButton onExportClicked={onExportClicked} />
            </div>
        </div>
        {annotations.map(a => <AnnotationCard
            key={a.id}
            expanded={expandedAnnotationId === a.id}
            onToggleExpansion={onToggleExpansion}
            onChangeAnnotationVisibilityInLegend={onChangeAnnotationVisibilityInLegend}
            annotation={a}
        />)}
    </div>
);

AnnotationCardList.propTypes = {
    annotations: PropTypes.array.isRequired,
    onChangeAnnotationVisibilityInLegend: PropTypes.func.isRequired,
    onToggleExpansion: PropTypes.func.isRequired,
    expandedAnnotationId: PropTypes.string,
    onEditClicked: PropTypes.func.isRequired,
    onExportClicked: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
};
AnnotationCardList.defaultProps = {
    expandedAnnotationId: undefined,
};

export default injectIntl(AnnotationCardList);
