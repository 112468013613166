import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AppConfig from '../../appConfig';

import BusComponent from '../BusComponent';
import DataBrowserFeatured from './DataBrowserFeatured';
import DataBrowserBySurvey from './DataBrowserBySurvey';
import DataBrowserByCategory from './DataBrowserByCategory';
import DataBrowserHeader from './DataBrowserHeader';
import TabbedBrowser from '../tabbedBrowser/TabbedBrowser';
import Loader from '../Loader';
import DataBrowserBySearch from './dataBrowserSearch/DataBrowserBySearch';

import { hasParentNode, addEvent, removeEvent } from '../../helpers/Util';

// Here we are remaping the config values to match the already in use
// data structur.
const BrowseType = AppConfig.constants.browserTabs.reduce((acc, cur, i) => {
    const { key } = cur;
    return { ...acc, [key]: i };
}, {}
);

const MIN_MOUSE_MOVEMENT = 15;
class DataBrowser extends BusComponent {
    constructor(props, context) {
        super(props, context);

        this.state = {
            hasData: false,
            browseType: BrowseType.CATEGORY,
            currentMetadataSelection: undefined,
            dataCategorySelection: undefined,
            mapsGroupSurveys: undefined,
            mapsGroupAvailableDatasets: undefined,
            mapsGroupDataCategories: undefined,
            featuredCategoriesData: undefined,
            surveysYears: undefined,
            selectedYear: undefined,
        };
    }

    componentDidMount() {
        this.bindGluBusEvents({
            METADATA_LOAD_SUCCESS: this.onMetadataLoadSuccess,
            METADATA_PERMISSIONS_UPDATED: this.onUpdateMetadata,
            MAP_NEW_DATA_THEME_APPLIED: this.onUpdateMetadata,
            FRAME_COMPONENT_DISABLE_EVENTS: this.disableEvents,
            FRAME_COMPONENT_ENABLE_EVENTS: this.enableEvents,
            OVERRIDE_DATA_BROWSER_STATE: this.handleStateOverride,
        });

        this.bus.emit('METADATA_LOAD_REQUEST', {
            metadataGroupId: this.props.mapInstance.metadataGroupId,
            mapInstanceId: this.props.mapInstance.id,
            variableSelectionItems: this.props.mapInstance.dataTheme
                .variableSelection.items,
        });

        this.enableEvents();
    }

    disableEvents = () => {
        removeEvent(document, 'mouseup', this.handleDocumentMouseUp);
        removeEvent(document, 'mousedown', this.handleDocumentMouseDown);
    };

    enableEvents = () => {
        addEvent(document, 'mouseup', this.handleDocumentMouseUp);
        addEvent(document, 'mousedown', this.handleDocumentMouseDown);
    };

    componentWillReceiveProps(nextProps) {
        this.bus.emit('METADATA_LOAD_REQUEST', {
            metadataGroupId: nextProps.mapInstance.metadataGroupId,
            mapInstanceId: nextProps.mapInstance.id,
            variableSelectionItems:
                nextProps.mapInstance.dataTheme.variableSelection.items,
        });

        this.setState({ hasData: false });
    }

    componentWillUnmount() {
        this.disableEvents();
        this.unbindGluBusEvents();
    }

    onUpdateMetadata() {
        this.bus.emit('METADATA_LOAD_REQUEST', {
            metadataGroupId: this.props.mapInstance.metadataGroupId,
            mapInstanceId: this.props.mapInstance.id,
            variableSelectionItems: this.props.mapInstance.dataTheme
                .variableSelection.items,
        });
    }

    onMetadataLoadSuccess(eventMap) {
        const surveysYears = [];
        const {
            mapsGroupSurveys,
            currentMetadataSelection,
            mapsGroupAvailableDatasets,
            currentGroupMetadata,
            mapsGroupDataCategories,
            featuredCategoriesData,
        } = eventMap;

        mapsGroupSurveys.forEach(survey => {
            if (surveysYears.indexOf(survey.year) === -1) {
                surveysYears.push(survey.year);
            }
        });

        const browseType = eventMap.browsingInfo.type || this.state.browseType;
        const dataCategorySelection =
            eventMap.browsingInfo.dataCategorySelection ||
            this.state.dataCategorySelection;

        this.setState({
            browseType,
            dataCategorySelection,
            currentMetadataSelection,
            mapsGroupAvailableDatasets,
            mapsGroupSurveys,
            currentGroupMetadata,
            mapsGroupDataCategories,
            featuredCategoriesData,
            surveysYears: surveysYears.sort(),
            hasData: true,
        });
    }

    /**
     * @param {object} param0
     * @param {string} param0.selectedTab
     * @param {number} param0.year
     * @param {*} param0.dataCategorySelection
     */
    handleStateOverride = ({ selectedTab, year, dataCategorySelection }) => {
        // override selected tab
        if (BrowseType[selectedTab]) {
            this.setState({ browseType: BrowseType[selectedTab] });
        }
        // override data category selection
        if (dataCategorySelection) {
            this.onChangeDataCategorySelection(dataCategorySelection);
        } else if (year) {
            // override year
            this.setState({ selectedYear: year });
        }
    };

    handleDocumentMouseDown = e => {
        this._mousePosition = { x: e.pageX, y: e.pageY };
    };

    handleDocumentMouseUp = e => {
        if (hasParentNode(e.target, this.root)) return;

        if (!this._mousePosition || !this.root) {
            return;
        }
        const deltaX = e.pageX - this._mousePosition.x;
        const deltaY = e.pageY - this._mousePosition.y;
        const euclideanDistance = Math.sqrt((deltaX * deltaX) + (deltaY * deltaY));

        if (euclideanDistance < MIN_MOUSE_MOVEMENT) {
            this.props.onClose();
        }
    };

    onChangeBrowseType = type => {
        this.emit('UPDATE_METADATA_BROWSING_INFO_REQUEST', {
            mapInstanceId: this.props.mapInstance.id,
            browsingInfo: { type },
        });
        this.setState({
            browseType: type,
        });
    };

    onChangeDataCategorySelection = dataCategorySelection => {
        this.emit('UPDATE_METADATA_BROWSING_INFO_REQUEST', {
            mapInstanceId: this.props.mapInstance.id,
            browsingInfo: { dataCategorySelection },
        });
        this.setState({
            dataCategorySelection,
        });
        if (dataCategorySelection) {
            const eventValue = `${dataCategorySelection.dataCategory.name}|${dataCategorySelection.year}`;
            this.emit('COUNTER_LOG_REQUEST', [
                { event_type: 'search_regular', event_value: eventValue },
            ]);
        }
    };

    renderContent() {
        if (!this.state.hasData) {
            return (
                <div className="grow flex-it center justify-center">
                    <Loader
                        text={this.props.intl.formatMessage({
                            id: 'variablePicker.gettingMetadata',
                        })}
                    />
                </div>
            );
        }

        const { mapInstance } = this.props;

        const allTabs = {
            FEATURED: (
                <DataBrowserFeatured
                    key="featured"
                    mapInstanceId={mapInstance.id}
                    hasDataFilter={mapInstance.hasDataFilter}
                    featuredCategoriesData={this.state.featuredCategoriesData}
                    currentMetadataSelection={this.state.currentMetadataSelection}
                />
            ),
            CATEGORY: (
                <DataBrowserByCategory
                    key="categories"
                    className="data-browser__category grow"
                    surveysYears={this.state.surveysYears}
                    selectedYear={this.state.selectedYear}
                    mapsGroupDataCategories={this.state.mapsGroupDataCategories}
                    dataCategorySelection={this.state.dataCategorySelection}
                    mapInstanceId={mapInstance.id}
                    hasDataFilter={mapInstance.hasDataFilter}
                    metadataGroupId={mapInstance.metadataGroupId}
                    currentMetadataSelection={this.state.currentMetadataSelection}
                    onChangeDataCategorySelection={this.onChangeDataCategorySelection}
                />),
            SURVEY: (
                <DataBrowserBySurvey
                    key="allData"
                    className="data-browser__survey grow"
                    mapsGroupSurveys={this.state.mapsGroupSurveys}
                    mapsGroupAvailableDatasets={this.state.mapsGroupAvailableDatasets}
                    currentMetadataSelection={this.state.currentMetadataSelection}
                    mapInstanceId={mapInstance.id}
                    hasDataFilter={mapInstance.hasDataFilter}
                />),
            SEARCH: (
                <DataBrowserBySearch
                    key="data-browser-by-search"
                    mapsGroupSurveys={this.state.mapsGroupSurveys}
                    currentGroupMetadata={this.state.currentGroupMetadata}
                    mapInstanceId={mapInstance.id}
                    mapInstance={mapInstance}
                    currentMetadataSelection={this.state.currentMetadataSelection}
                />),
        };

        const tabNames = AppConfig.constants.browserTabs.map(tab => ({
            name: this.props.intl.formatMessage({ id: tab.id }),
            showDivider: tab.showDivider,
            icon: tab.icon,
        }));

        const tabs = [];
        // Filter the tabs
        AppConfig.constants.browserTabs.forEach(tab => {
            tabs.push(allTabs[tab.key]);
        });

        return (
            <TabbedBrowser
                className="flex-it column grow tabbed-browser-new data-browser__tabbed-browser"
                tabNames={tabNames}
                selectedTabIndex={this.state.browseType}
                tabSwitchedCallback={this.onChangeBrowseType}
            >
                {tabs}
            </TabbedBrowser>
        );
    }

    render() {
        const { className, onClose } = this.props;

        const dataBrowserClassNames = classNames(
            'data-browser flex-it column',
            className,
        );

        return (
            <div
                className={dataBrowserClassNames}
                ref={c => {
                    this.root = c;
                }}
            >
                <DataBrowserHeader onClose={onClose} />
                {this.renderContent()}
            </div>
        );
    }
}

DataBrowser.propTypes = {
    mapInstance: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
};

DataBrowser.defaultProps = {};

export default injectIntl(DataBrowser);
