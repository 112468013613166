// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import SimpleDropdown from '../../dropdown/SimpleDropdown';

const DEFAULT_SELECTION_ID = 'all surveys';

/**
 * @typedef Props
 * @property {import('../../../objects/MetaSurvey').default[]} surveys
 * @property {string} selectedSurvey
 * @property {(surveyName: string) => void} onChange
 * @property {import('react-intl').intlShape} intl
 *
 * @extends {React.Component<Props>}
 */

class SearchSurveyFilter extends React.Component {
    /** @param {string} surveyName */
    onChange = surveyName => {
        const selectedSurveyName =
            surveyName === DEFAULT_SELECTION_ID ? undefined : surveyName;
        this.props.onChange(selectedSurveyName);
    };

    render() {
        const items = this.props.surveys.map(survey => ({
            id: survey.name,
            text: survey.displayName,
        }));
        items.unshift({
            id: DEFAULT_SELECTION_ID,
            text: this.props.intl.formatMessage({
                id: 'allSources',
            }),
        });
        const selectedItem = this.props.selectedSurvey
            ? items.find(item => item.id === this.props.selectedSurvey)
            : {
                id: DEFAULT_SELECTION_ID,
                text: this.props.intl.formatMessage({ id: 'source' }),
            };
        return (
            <SimpleDropdown
                className="data-browser-search-dropdown"
                onItemClick={this.onChange}
                items={items}
                selectedItem={selectedItem}
                showFilter
                filterPlaceholder={this.props.intl.formatMessage({
                    id: 'dataBrowser.searchSource',
                })}
                ariaLabel={this.props.intl.formatMessage({
                    id: 'dataBrowser.searchSource',
                })}
            />
        );
    }
}

SearchSurveyFilter.propTypes = {
    surveys: PropTypes.array.isRequired,
    selectedSurvey: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
};

SearchSurveyFilter.defaultProps = {
    selectedSurvey: undefined,
};

export default injectIntl(SearchSurveyFilter);
