import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InfoBubbleFeature from './InfoBubbleFeature';

const InfoBubbleGroup = ({
    features,
    mapId,
    lastHoveredMap,
    isMimicking,
    isComposite,
    isExpanded,
    mapIdIndex,
    onInfoBubbleClose,
    onInfoBubbleExpand,
    onInfoBubbleCollapse,
}) => {
    const infoBubbleGroupClasses = classNames('info-bubble-group', {
        'info-bubble-group--separate': isComposite && lastHoveredMap !== mapId,
    });
    const infoBubbleGroup = features.map(
        feature => (
            <InfoBubbleFeature
                mapId={mapId}
                feature={feature}
                mapIdIndex={mapIdIndex}
                lastHoveredMap={lastHoveredMap}
                key={`${mapId}-${feature.geoTitle}`}
                isMimicking={isMimicking}
                isComposite={isComposite}
                isExpanded={isExpanded}
                onInfoBubbleClose={onInfoBubbleClose}
                onInfoBubbleExpand={onInfoBubbleExpand}
                onInfoBubbleCollapse={onInfoBubbleCollapse}
            />
        ),
        this
    );
    return <div className={infoBubbleGroupClasses}>{infoBubbleGroup}</div>;
};

InfoBubbleGroup.propTypes = {
    features: PropTypes.array.isRequired,
    mapId: PropTypes.string.isRequired,
    lastHoveredMap: PropTypes.string.isRequired,
    isMimicking: PropTypes.bool,
    isComposite: PropTypes.bool,
    isExpanded: PropTypes.bool,
    mapIdIndex: PropTypes.number.isRequired,
    onInfoBubbleClose: PropTypes.func.isRequired,
    onInfoBubbleExpand: PropTypes.func.isRequired,
    onInfoBubbleCollapse: PropTypes.func.isRequired,
};

InfoBubbleGroup.defaultProps = {
    isMimicking: undefined,
    isComposite: false,
    isExpanded: false,
};

export default InfoBubbleGroup;
