export default {
    ENTER: 'Enter',
    SHIFT_LEFT: 'ShiftLeft',
    SHIFT_RIGHT: 'ShiftRight',
    ESC: 'Escape',
    SPACE: 'Space',
    LEFT: 'ArrowLeft',
    UP: 'ArrowUp',
    RIGHT: 'ArrowRight',
    DOWN: 'ArrowDown',
    TAB: 'Tab',
    DELETE: 'Delete',
    // KeyboardEvent.code ignores the user's keyboard layout,
    // so that if the user presses the key at the "Y" position in a QWERTY
    // keyboard layout (near the middle of the row above the home row),
    // this will always return "KeyY", even if the user has a QWERTZ keyboard
    // (which would mean the user expects a "Z" and all the other properties
    // would indicate a "Z")
    // (https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key)
    Y: 'KeyY',
    Z: 'KeyZ',
};
