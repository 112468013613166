import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Popover from '../../../popover/Popover';
import MarkerEditor from '../../../markerEditor/MarkerEditor';

class MarkerPropertyEditor extends React.Component {
    render() {
        const { markerPathId, markerEditorOpen, fillColor } = this.props;
        const { onTriggerClick, onCloseRequest, onMarkerStyleChange } = this.props;

        const buttonIconClasses = classNames('click-passive', `socex-icon-marker-${markerPathId}`);

        return (
            <div className="annotation-property annotation-property--with-margin">
                <div className="annotation-property__label body-1">Symbol</div>
                <div className="annotation-property__value">
                    <button
                        className="btn-icon"
                        ref={e => { this.markerEditorTrigger = e; }}
                        onClick={onTriggerClick}
                    >
                        <i
                            className={buttonIconClasses}
                            style={{ color: fillColor }}
                        />
                    </button>
                    {this.markerEditorTrigger && (
                        <Popover
                            anchorEl={this.markerEditorTrigger}
                            open={markerEditorOpen}
                            showClose
                            triggerToggle
                            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                            targetOrigin={{ horizontal: 'left', vertical: 'top' }}
                            marginTop={8}
                            onCloseRequest={onCloseRequest}
                        >
                            <MarkerEditor
                                markerPathId={markerPathId}
                                markerColor={fillColor}
                                onChange={onMarkerStyleChange}
                                iconHasBackground={false}
                            />
                        </Popover>
                    )}
                </div>
            </div>
        );
    }
}

MarkerPropertyEditor.propTypes = {
    enabled: PropTypes.bool,
    markerPathId: PropTypes.string.isRequired,
    markerEditorOpen: PropTypes.bool.isRequired,
    fillColor: PropTypes.string.isRequired,
    onCloseRequest: PropTypes.func.isRequired,
    onTriggerClick: PropTypes.func.isRequired,
    onChangeAvailability: PropTypes.func,
    onMarkerStyleChange: PropTypes.func.isRequired,
};

MarkerPropertyEditor.defaultProps = {
    enabled: undefined,
    onChangeAvailability: undefined,
};

export default MarkerPropertyEditor;
