import React from 'react';

const LegendLauncherIcon = () => (<div className="visualization-legend__composite-icon">
    <div className="box" style={{ backgroundColor: '#c1185c' }} />
    <div className="box" style={{ backgroundColor: '#7b9eb0' }} />
    <div className="box" style={{ backgroundColor: '#face84' }} />
    <i className="socex-icon-expand visualization-legend__icon" />
</div>);

export default LegendLauncherIcon;
