// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import BusComponent from '../../BusComponent';
import Loader from '../../Loader';
import Upgrade from '../../upgrade/Upgrade';
import SearchEmpty from './SearchEmpty';
import SearchDefault from './SearchDefault';
import Table from './Table';

/**
 * @typedef Props
 * @property {string} query
 * @property {import('../../../objects/MapInstance').default} mapInstance
 * @property {import('../../../').SearchResultTable[]} results
 * @property {boolean} isSearching
 * @property {number} totalHits
 * @property {number} selectedYear
 * @property {import('react-intl').intlShape} intl
 *
 * @typedef State
 * @property {import('../../../objects/UserInfo').default} userInfo
 *
 * @extends {BusComponent<Props, State>}
 */
class Results extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            userInfo: undefined,
        };
    }

    componentDidMount() {
        this.bus.once('USER_INFO_GET_SUCCESS', this.onUserInfo);
        this.emit('USER_INFO_GET_REQUEST', { source: this });
    }

    componentWillUnmount() {
        this.bus.off('USER_INFO_GET_SUCCESS', this.onUserInfo);
    }

    /**
     * @param {import('../../../objects/UserInfo').default} userInfo
     * @param {BusComponent} source
     */
    onUserInfo = (userInfo, source) => {
        if (source !== this) return;
        this.setState({ userInfo });
    };

    render() {
        const {
            isSearching,
            totalHits,
            results,
            selectedYear,
            mapInstance,
            query,
            intl,
        } = this.props;
        const hasAccess = this.state.userInfo && this.state.userInfo.isProUser;
        let content,
            upgrade,
            showUpgrade = false;
        switch (true) {
        case !isSearching && totalHits < 0:
            content = <SearchDefault />;
            break;
        case !isSearching && totalHits === 0:
            content = <SearchEmpty query={query} />;
            break;
        case !isSearching && totalHits > 0: {
            if (!hasAccess) {
                upgrade = (
                    <Upgrade
                        text={this.props.intl.formatMessage({
                            id: 'header.upgradeToProfessionalPlan',
                        })}
                    />
                );
                showUpgrade = true;
            }
            content = results.map(table => (
                <Table
                    disabled={!hasAccess}
                    table={table}
                    selectedYear={selectedYear}
                    key={table.tableGuid}
                    mapInstance={mapInstance}
                    currentVariableSelectionItems={
                        mapInstance.dataTheme.variableSelection.items
                    }
                    query={query}
                />
            ));
            break;
        }
        default:
            content = (
                <Loader
                    text={intl.formatMessage({
                        id: 'dataBrowser.searching',
                    })}
                />
            );
        }

        if (showUpgrade) {
            return (
                <div className="data-browser-search__upgrade">
                    {upgrade}
                    <div className="data-browser-search__results">
                        {content}
                    </div>
                </div>
            );
        }
        return <div className="data-browser-search__results">{content}</div>;
    }
}

Results.propTypes = {
    intl: PropTypes.object.isRequired,
    mapInstance: PropTypes.object.isRequired,
    results: PropTypes.array.isRequired,
    isSearching: PropTypes.bool,
    totalHits: PropTypes.number.isRequired,
    selectedYear: PropTypes.number,
    query: PropTypes.string,
};

Results.defaultProps = {
    isSearching: false,
    query: undefined,
    selectedYear: undefined,
};

export default injectIntl(Results);
