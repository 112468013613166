export const SELFSTORAGE_POINT_TYPE = {
    SS_CONSTRUCTION: 'ss-construction',
    SS_FACILITY: 'ss-facility',
    HOUSING_STARTS: 'housing-starts',
    INFLUENCER: 'influencer',
};

export const SURVEY_CODES = {
    PACCOMHOUSING2020: 'PACCOMHOUSING2020',
    PACCOMINFLUENCER2022: 'PACCOMINFLUENCER2022',
};

// TODO
// this should be a temporary solution, layer metadata that describes if
// the layer feature can be opened in visual reports should be either
// in config or in dingo-maps;
// also we need a way to describe if the layer contains construction sites
// or better, if the feature is a construction site (because features from selfstorage
// layer can be both facility or construction sites)


export const resolvePointTypeFromSurveyCode = surveyCode => {
    if (!surveyCode) {
        return null;
    }

    let pointType;
    switch (surveyCode) {
    case SURVEY_CODES.PACCOMHOUSING2020:
        pointType = SELFSTORAGE_POINT_TYPE.HOUSING_STARTS;
        break;
    case SURVEY_CODES.PACCOMINFLUENCER2022:
        pointType = SELFSTORAGE_POINT_TYPE.INFLUENCER;
        break;
    default:
        pointType = null;
    }

    return pointType;
};
