'use strict';module.exports = createStructArrayType;
var viewTypes = {
    'Int8': Int8Array,
    'Uint8': Uint8Array,
    'Int16': Int16Array,
    'Uint16': Uint16Array,
    'Int32': Int32Array,
    'Uint32': Uint32Array,
    'Float32': Float32Array
};
var Struct = function Struct(structArray, index) {
    this._structArray = structArray;
    this._pos1 = index * this.size;
    this._pos2 = this._pos1 / 2;
    this._pos4 = this._pos1 / 4;
    this._pos8 = this._pos1 / 8;
};
var DEFAULT_CAPACITY = 128;
var RESIZE_MULTIPLIER = 5;
var StructArray = function StructArray(serialized) {
    this.isTransferred = false;
    if (serialized !== undefined) {
        this.arrayBuffer = serialized.arrayBuffer;
        this.length = serialized.length;
        this.capacity = this.arrayBuffer.byteLength / this.bytesPerElement;
        this._refreshViews();
    } else {
        this.capacity = -1;
        this.resize(0);
    }
};
StructArray.serialize = function serialize() {
    return {
        members: this.prototype.members,
        alignment: this.prototype.StructType.prototype.alignment
    };
};
StructArray.prototype.serialize = function serialize(transferables) {
    this._trim();
    if (transferables) {
        this.isTransferred = true;
        transferables.push(this.arrayBuffer);
    }
    return {
        length: this.length,
        arrayBuffer: this.arrayBuffer
    };
};
StructArray.prototype.get = function get(index) {
    return new this.StructType(this, index);
};
StructArray.prototype._trim = function _trim() {
    if (this.length !== this.capacity) {
        this.capacity = this.length;
        this.arrayBuffer = this.arrayBuffer.slice(0, this.length * this.bytesPerElement);
        this._refreshViews();
    }
};
StructArray.prototype.clear = function clear() {
    this.length = 0;
};
StructArray.prototype.resize = function resize(n) {
    this.length = n;
    if (n > this.capacity) {
        this.capacity = Math.max(n, Math.floor(this.capacity * RESIZE_MULTIPLIER), DEFAULT_CAPACITY);
        this.arrayBuffer = new ArrayBuffer(this.capacity * this.bytesPerElement);
        var oldUint8Array = this.uint8;
        this._refreshViews();
        if (oldUint8Array) {
            this.uint8.set(oldUint8Array);
        }
    }
};
StructArray.prototype._refreshViews = function _refreshViews() {
    var this$1 = this;
    for (var i = 0, list = this$1._usedTypes; i < list.length; i += 1) {
        var type = list[i];
        this$1[getArrayViewName(type)] = new viewTypes[type](this$1.arrayBuffer);
    }
};
StructArray.prototype.toArray = function toArray(startIndex, endIndex) {
    var this$1 = this;
    var array = [];
    for (var i = startIndex; i < endIndex; i++) {
        var struct = this$1.get(i);
        array.push(struct);
    }
    return array;
};
var structArrayTypeCache = {};
function createStructArrayType(options) {
    var key = JSON.stringify(options);
    if (structArrayTypeCache[key]) {
        return structArrayTypeCache[key];
    }
    var alignment = options.alignment === undefined ? 1 : options.alignment;
    var offset = 0;
    var maxSize = 0;
    var usedTypes = ['Uint8'];
    var members = options.members.map(function (member) {
        if (usedTypes.indexOf(member.type) < 0) {
            usedTypes.push(member.type);
        }
        var typeSize = sizeOf(member.type);
        var memberOffset = offset = align(offset, Math.max(alignment, typeSize));
        var components = member.components || 1;
        maxSize = Math.max(maxSize, typeSize);
        offset += typeSize * components;
        return {
            name: member.name,
            type: member.type,
            components: components,
            offset: memberOffset
        };
    });
    var size = align(offset, Math.max(maxSize, alignment));
    var StructType = function (Struct) {
        function StructType() {
            Struct.apply(this, arguments);
        }
        if (Struct)
            StructType.__proto__ = Struct;
        StructType.prototype = Object.create(Struct && Struct.prototype);
        StructType.prototype.constructor = StructType;
        return StructType;
    }(Struct);
    StructType.prototype.alignment = alignment;
    StructType.prototype.size = size;
    for (var i = 0, list = members; i < list.length; i += 1) {
        var member = list[i];
        for (var c = 0; c < member.components; c++) {
            var name = member.name;
            if (member.components > 1) {
                name += c;
            }
            if (name in StructType.prototype) {
                throw new Error(name + ' is a reserved name and cannot be used as a member name.');
            }
            Object.defineProperty(StructType.prototype, name, createAccessors(member, c));
        }
    }
    var StructArrayType = function (StructArray) {
        function StructArrayType() {
            StructArray.apply(this, arguments);
        }
        if (StructArray)
            StructArrayType.__proto__ = StructArray;
        StructArrayType.prototype = Object.create(StructArray && StructArray.prototype);
        StructArrayType.prototype.constructor = StructArrayType;
        return StructArrayType;
    }(StructArray);
    StructArrayType.prototype.members = members;
    StructArrayType.prototype.StructType = StructType;
    StructArrayType.prototype.bytesPerElement = size;
    StructArrayType.prototype.emplaceBack = createEmplaceBack(members, size);
    StructArrayType.prototype._usedTypes = usedTypes;
    structArrayTypeCache[key] = StructArrayType;
    for (var i$1 = 0, list$1 = members; i$1 < list$1.length; i$1 += 1) {
        var member$1 = list$1[i$1];
        for (var c$1 = 0; c$1 < member$1.components; c$1++) {
            var name$1 = 'get' + member$1.name;
            if (member$1.components > 1) {
                name$1 += c$1;
            }
            if (name$1 in StructArrayType.prototype) {
                throw new Error(name$1 + ' is a reserved name and cannot be used as a member name.');
            }
            StructArrayType.prototype[name$1] = createIndexedMemberComponentGetter(member$1, c$1, size);
        }
    }
    return StructArrayType;
}
function align(offset, size) {
    return Math.ceil(offset / size) * size;
}
function sizeOf(type) {
    return viewTypes[type].BYTES_PER_ELEMENT;
}
function getArrayViewName(type) {
    return type.toLowerCase();
}
function createEmplaceBack(members, bytesPerElement) {
    var usedTypeSizes = [];
    var argNames = [];
    var body = 'var i = this.length;\n' + 'this.resize(this.length + 1);\n';
    for (var i = 0, list = members; i < list.length; i += 1) {
        var member = list[i];
        var size = sizeOf(member.type);
        if (usedTypeSizes.indexOf(size) < 0) {
            usedTypeSizes.push(size);
            body += 'var o' + size.toFixed(0) + ' = i * ' + (bytesPerElement / size).toFixed(0) + ';\n';
        }
        for (var c = 0; c < member.components; c++) {
            var argName = 'v' + argNames.length;
            var index = 'o' + size.toFixed(0) + ' + ' + (member.offset / size + c).toFixed(0);
            body += 'this.' + getArrayViewName(member.type) + '[' + index + '] = ' + argName + ';\n';
            argNames.push(argName);
        }
    }
    body += 'return i;';
    return new Function(argNames.toString(), body);
}
function createMemberComponentString(member, component) {
    var elementOffset = 'this._pos' + sizeOf(member.type).toFixed(0);
    var componentOffset = (member.offset / sizeOf(member.type) + component).toFixed(0);
    var index = elementOffset + ' + ' + componentOffset;
    return 'this._structArray.' + getArrayViewName(member.type) + '[' + index + ']';
}
function createIndexedMemberComponentGetter(member, component, size) {
    var componentOffset = (member.offset / sizeOf(member.type) + component).toFixed(0);
    var componentStride = size / sizeOf(member.type);
    return new Function('index', 'return this.' + getArrayViewName(member.type) + '[index * ' + componentStride + ' + ' + componentOffset + '];');
}
function createAccessors(member, c) {
    var code = createMemberComponentString(member, c);
    return {
        get: new Function('return ' + code + ';'),
        set: new Function('x', code + ' = x;')
    };
}