class MetaSurvey {
    constructor() {
        this._enabled = true;
        this._availableToUser = true;
        this._geoNameField = 'Geo_Name';
        this._geoQNameField = 'Geo_QName';
        this._geoFipsField = 'Geo_FIPS';
    }

    get uuid() {
        return this._uuid;
    }

    set uuid(uuid) {
        this._uuid = uuid;
    }

    /** @type {string} surveyCode */
    get name() {
        return this._name;
    }

    set name(name) {
        this._name = name;
    }

    get displayName() {
        return this._displayName;
    }

    set displayName(displayName) {
        this._displayName = displayName;
    }

    /** @type {number} */
    get year() {
        return this._year;
    }

    set year(year) {
        this._year = year;
    }

    get yearFrom() {
        return this._yearFrom;
    }

    set yearFrom(yearFrom) {
        this._yearFrom = yearFrom;
    }

    get yearTo() {
        return this._yearTo;
    }

    set yearTo(yearTo) {
        this._yearTo = yearTo;
    }

    get publisher() {
        return this._publisher;
    }

    set publisher(publisher) {
        this._publisher = publisher;
    }

    get productTags() {
        return this._productTags;
    }

    set productTags(productTags) {
        this._productTags = productTags;
    }

    get priority() {
        return this._priority;
    }

    set priority(priority) {
        this._priority = priority;
    }
    /** @type {Object.<string, import('./MetaDataset').default>} key is dataset abbreviation */
    get datasets() {
        return this._datasets;
    }

    set datasets(datasets) {
        this._datasets = datasets;
    }

    /** @type {Object.<string, import('./MetaReport').default>} key is reportId */
    get reports() {
        return this._reports;
    }

    set reports(reports) {
        this._reports = reports;
    }

    get geoTypes() {
        return this._geoTypes;
    }

    set geoTypes(geoTypes) {
        this._geoTypes = geoTypes;
    }

    get dataCategoriesNames() {
        if (!this._dataCategoriesNames && this.datasets) {
            let dataCategoriesNames = [];
            Object.keys(this.datasets)
                .map(d => this.datasets[d])
                .forEach(dataset => {
                    dataCategoriesNames = dataCategoriesNames.concat(
                        dataset.dataCategoriesNames,
                    );
                });
            this._dataCategoriesNames = dataCategoriesNames;
        }
        return this._dataCategoriesNames;
    }

    set dataCategoriesNames(dataCategoriesNames) {
        this._dataCategoriesNames = dataCategoriesNames;
    }

    get geoNameField() {
        return this._geoNameField;
    }

    set geoNameField(geoNameField) {
        this._geoNameField = geoNameField;
    }

    get geoQNameField() {
        return this._geoQNameField;
    }

    set geoQNameField(geoQNameField) {
        this._geoQNameField = geoQNameField;
    }

    get geoFipsField() {
        return this._geoFipsField;
    }

    set geoFipsField(geoFipsField) {
        this._geoFipsField = geoFipsField;
    }

    get index() {
        return this._index;
    }

    set index(index) {
        this._index = index;
    }

    get enabled() {
        return this._enabled;
    }

    set enabled(enabled) {
        this._enabled = enabled;
    }

    get availableToUser() {
        return this._availableToUser;
    }

    set availableToUser(availableToUser) {
        this._availableToUser = availableToUser;
    }

    get additionalMetadata() {
        return this._additionalMetadata;
    }

    set additionalMetadata(additionalMetadata) {
        this._additionalMetadata = additionalMetadata;
    }

    get datasetsAsArray() {
        if (this.datasets) {
            return Object.keys(this.datasets).map(d => this.datasets[d]);
        }
        return [];
    }

    get reportsAsArray() {
        if (this.reports) {
            return Object.keys(this.reports).map(d => this.reports[d]);
        }
        return [];
    }

    toJSON() {
        return {
            uuid: this.uuid,
            name: this.name,
            displayName: this.displayName,
            year: this.year,
            yearTo: this.yearTo,
            yearFrom: this.yearFrom,
            publisher: this.publisher,
            productTags: this.productTags,
            priority: this.priority,
            datasets: this.datasets,
            reports: this.reports,
            geoTypes: this.geoTypes,
            additionalMetadata: this.additionalMetadata,
        };
    }

    /**
     * Determines if survey is available for reporting.
     * Uses the loaded survey groups because this info is stored in the dataset metadata.
     * @param {import('../').SurveyGroup[]} surveyGroups
     * @returns {boolean}
     */
    isAvailableForReporting(surveyGroups) {
        const survey = surveyGroups
            .flatMap(group => group.dataSets)
            .find(dataset => dataset.code === this.name);
        if (survey) {
            return survey.availableInReports;
        }
        return false;
    }

    hasCategory(categoryName) {
        return (
            this.dataCategoriesNames &&
            this.dataCategoriesNames.find(name => name === categoryName) !==
                undefined
        );
    }
    // Merge partial survey into already existing survey ( adding missing datasets, tables and variables)
    mergePartial(survey) {
        const _survey = this;
        const datasetsAbbreavtions = Object.keys(survey.datasets);
        datasetsAbbreavtions.forEach(datasetAbbrevation => {
            const dataset = survey.datasets[datasetAbbrevation];
            dataset.index = Object.keys(_survey.datasets).length;
            // if there is no this dataset then add it
            if (!_survey.datasets[datasetAbbrevation]) {
                _survey.datasets[datasetAbbrevation] = dataset;
            } else {
                // if dataset is present check for missing tables or variables
                const _dataset = _survey.datasets[datasetAbbrevation];
                const tablesNames = Object.keys(dataset.tables);
                tablesNames.forEach(tableName => {
                    const table = dataset.tables[tableName];
                    table.index = Object.keys(_dataset.tables).length;
                    // if this table is not present in current dataset then add it
                    if (!_dataset.tables[tableName]) {
                        _dataset.tables[tableName] = table;
                    } else {
                        // if table is present check for missing variables
                        const _table = _dataset.tables[tableName];
                        const variableNames = Object.keys(table.variables);
                        variableNames.forEach(variableName => {
                            const variable = table.variables[variableName];
                            variable.index = Object.keys(
                                _table.variables,
                            ).length;
                            // if variable is not present in current table then add it
                            if (!_table.variables[variableName]) {
                                _table.variables[variableName] = variable;
                            }
                        });
                    }
                });
            }
        });
    }
}

export default MetaSurvey;
