import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';

import MapSelectionType from '../../enums/MapSelectionType';
import MapSelectionInclusionType from '../../enums/MapSelectionInclusionType';
import { pointRadius } from '../../helpers/Util';
import MapSelection from '../../components/mapSelection/MapSelection';
import messages, { locale } from '../../languages/languageConfig';


class MapSelectionControl {
    constructor(options) {
        this._layersIds = options.layers || [];
        this._ignoreEmptySelection = options.ignoreEmptySelection || true;
        this._boundOnHandleSelection = this._onHandleEndSelection.bind(this);
    }

    updateSelectionLayers(layersIds) {
        this._layersIds = layersIds || [];
    }

    onAdd(map) {
        this._map = map;
        this._container = window.document.createElement('div');
        this.renderSelectionControls();
        return this._container;
    }

    onRemove() {
        ReactDOM.unmountComponentAtNode(this._container);
    }

    renderSelectionControls() {
        ReactDOM.render(
            <IntlProvider locale={locale} messages={messages} >
                <MapSelection
                    map={this._map}
                    handleSelection={this._boundOnHandleSelection}
                />
            </IntlProvider>, this._container);
    }

    _onHandleEndSelection({ selectionType, inclusionRule, selectionStartPoint, selectionEndPoint, selectionPoints }) {
        if (selectionType === MapSelectionType.POINT && !selectionStartPoint) {
            return;
        }
        if ((selectionType === MapSelectionType.BOX || selectionType === MapSelectionType.CIRCLE) &&
            (!selectionStartPoint || !selectionEndPoint)) {
            return;
        }
        if (selectionType === MapSelectionType.POLYGON && selectionPoints.length < 2) {
            return;
        }
        if (selectionType === MapSelectionType.LINE && selectionPoints.length < 2) {
            return;
        }

        if (!this._map.style || !this._map.style.sourceCaches) {
            // map is not ready so ignore selection
            return;
        }
        let layerIds;
        if (inclusionRule === MapSelectionInclusionType.CENTROID) {
            layerIds = ['helper-fill-layer'];
        } else {
            layerIds = [...this._layersIds, 'helper-fill-layer'];
        }
        // collect layers source data for more stable selection
        const sourceCaches = {}, sourceLayersIds = {}, zoom = this._map.getZoom();
        for (let i = 0; i < layerIds.length; i += 1) {
            const dl = this._map.getLayer(layerIds[i]);
            if (!dl) { // referent layer is not added to the map so ignore selection
                return;
            }
            if (dl.autoSource) {
                const asLayer = dl.autoSource.find(asL => asL.minzoom <= zoom && asL.maxzoom > zoom);
                if (!asLayer) { // referent layer is not visible on this zoom level
                    return;
                }
                sourceLayersIds[dl.id] = asLayer['source-layer'];
            } else {
                if (dl.minzoom > zoom || dl.maxzoom <= zoom) { // referent layer is not visible on this zoom level
                    return;
                }
                sourceLayersIds[dl.id] = dl.sourceLayer;
            }
            if (!sourceCaches[dl.source]) {
                const sourceCache = this._map.style.sourceCaches[dl.source];
                if (!sourceCache || !sourceCache._tiles ||
                    Object.values(sourceCache._tiles).find(tile => tile === undefined)) {
                    // map is not ready so ignore selection
                    return;
                }
                sourceCaches[dl.source] = {
                    sourceCaches,
                    featuresTile: Object.values(sourceCache._tiles),
                };
            }
        }

        const params = {
            inclusionRule,
            layers: layerIds,
        };
        let geometry, radius;
        switch (selectionType) {
        case MapSelectionType.POINT:
            geometry = [selectionEndPoint.x, selectionEndPoint.y];
            break;
        case MapSelectionType.BOX:
            geometry = [
                    [selectionStartPoint.x, selectionStartPoint.y], // top-left
                    [selectionEndPoint.x, selectionStartPoint.y], // top-right
                    [selectionEndPoint.x, selectionEndPoint.y], // bottom-right
                    [selectionStartPoint.x, selectionEndPoint.y], // bottom-left
                    [selectionStartPoint.x, selectionStartPoint.y], // top-left (CLOSE THE GEOMETRY)
            ];

            radius = pointRadius([selectionStartPoint.x, selectionStartPoint.y], [selectionEndPoint.x, selectionEndPoint.y]);
                // check size of selection bbox
                // make this BOX selection into POINT if the bbox area is less than 2px
            if (radius < 2) {
                geometry = [...geometry[0]];
                params.inclusionRule = MapSelectionInclusionType.INTERSECT;
                selectionType = MapSelectionType.POINT;
            }
            break;
        case MapSelectionType.CIRCLE:
            radius = pointRadius([selectionStartPoint.x, selectionStartPoint.y], [selectionEndPoint.x, selectionEndPoint.y]);

                // make this CIRCLE selection into POINT selection when radius === 0
            if (radius === 0) {
                params.inclusionRule = MapSelectionInclusionType.INTERSECT;
                selectionType = MapSelectionType.POINT;
            } else {
                params.radius = radius;
            }

            geometry = [selectionStartPoint.x, selectionStartPoint.y];
            break;
        case MapSelectionType.POLYGON:
            geometry = [...selectionPoints, selectionPoints[0]].map(p => [p.x, p.y]);
            break;
        case MapSelectionType.LINE:
            params.isLine = true; // we need this flag in order to distinguish between 2-point line and 2-point box selection
            geometry = [...selectionPoints].map(p => [p.x, p.y]);
            break;
        }

        let features;

        // if the inclusion type is fully enclosed (CONTAIN) then it is necessary to do some special calculation
        // in order to get all the right features and none of the wrong ones
        if (inclusionRule === MapSelectionInclusionType.CONTAIN) {
            // 1. A = Features from visible layers within the geography
            params.layers = [...this._layersIds];
            const visibleDataLayerFeatures = this._getFeaturesForQuery(geometry, params, sourceCaches, sourceLayersIds);

            // 2. B = Features from helper point layer within the geography
            params.layers = ['helper-fill-layer'];
            const pointLayerFeatures = this._getFeaturesForQuery(geometry, params, sourceCaches, sourceLayersIds);

            // 3. C = All visible features, geography parameter is set to undefined
            params.layers = [...this._layersIds];
            params.radius = undefined;
            // This is an important step that we initially missed but later showed up as a bug.
            // The problem was if a visible polygon was not entirely within the viewport this query would not include it
            // since it's not fully ENCLOSED (in the viewport bounding box) therefore to include ALL the polygons
            // we must use the INTERSECT inclusion type.
            params.inclusionRule = MapSelectionInclusionType.INTERSECT;
            // Do the query and create a map object so we can do a faster filtering in the step 4
            const allVisibleFeaturesMap = this._getFeaturesForQuery(undefined, params, sourceCaches, sourceLayersIds)
                .reduce((map, feature) => {
                    map[feature.id] = feature;
                    return map;
                }, {});

            // 4. Remove all visible features from point features so we get only those point features that are not visible
            // D = B - C
            const nonVisiblePointFeatures = pointLayerFeatures.filter(feature => allVisibleFeaturesMap[feature.id] === undefined);

            // 5. Final list of features is the union of features from visible layers and point features that are not visible
            // E = A + D
            features = [...visibleDataLayerFeatures, ...nonVisiblePointFeatures];
        } else {
            features = this._getFeaturesForQuery(geometry, params, sourceCaches, sourceLayersIds);
        }

        // ignore empty selection
        if (this._ignoreEmptySelection && features.length === 0) {
            return;
        }

        this._map.fire('selectionend', {
            selectionType,
            features,
        });
    }

    _getFeaturesForQuery = (geometry, params, sourceCaches, sourceLayersIds) =>
        this._map.queryRenderedFeatures(geometry, params).filter(feature => {
            const featureCoord = {
                x: feature._vectorTileFeature._x,
                y: feature._vectorTileFeature._y,
                z: feature._vectorTileFeature._z,
            };
            const featureTile = sourceCaches[feature.layer.source].featuresTile.find(tile => (
                tile.coord.x === featureCoord.x && tile.coord.y === featureCoord.y && tile.coord.z === featureCoord.z
            ));
            // select only those features that come from correctly loaded tiles
            return featureTile && featureTile.featureIndex && featureTile.featureIndex.vtLayers &&
                featureTile.featureIndex.vtLayers[sourceLayersIds[feature.layer.id]];
        });

}

export default MapSelectionControl;
