import React from 'react';
import { injectIntl } from 'react-intl';
import BusComponent from '../BusComponent';
import classNames from 'classnames';

import TypeDropdown from './TypeDropdown';
import FilterList from './FilterList';
import FilterCombiner from '../../enums/FilterCombiner';
import PropTypes from 'prop-types';
import Loader from '../Loader';
import AppConfig from '../../appConfig';

class DataFilterPanel extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {};
    }

    onAddFilter = () => {
        const { mapInstanceId } = this.props;
        this.emit('ADD_NEW_FILTER_REQUEST', { mapInstanceId });
        this.emit('FILTER_SET_ACTIVE', { activeFilterId: undefined });
    };

    onCombinerChange = filterCombiner => {
        const { mapInstanceId } = this.props;
        this.emit('UPDATE_FILTER_COMBINER_REQUEST', {
            mapInstanceId,
            filterCombiner,
        });
    };

    render() {
        const {
            loading,
            filters,
            filterCombiner,
            mapInstanceId,
            intl,
        } = this.props;
        const filtersPresent = filters && Object.entries(filters).length > 0;
        const className = classNames('data-filter', 'flex-it', 'column', {
            'data-filter--empty': !filtersPresent,
        });

        if (loading) {
            return (
                <div className="loading-screen">
                    <Loader text={intl.formatMessage({ id: 'loading' })} />
                </div>
            );
        }

        return (
            <div className={className}>
                <div className="flex-it center no-shrink">
                    <TypeDropdown
                        value={filterCombiner}
                        onChange={this.onCombinerChange}
                        className="data-filter__combinator-type"
                        types={Object.values(FilterCombiner).map(type => ({
                            ...type,
                            text: intl.formatMessage({ id: type.text }),
                        }))}
                        dropdownClassName="data-filter__combinator-dropdown"
                    />
                </div>
                <FilterList
                    onAddFilter={this.onAddFilter}
                    filters={filters}
                    mapInstanceId={mapInstanceId}
                />
                {!filtersPresent &&
                    <div className="flex-it center column">
                        <div
                            className="data-filter__no-filters"
                            style={{ backgroundImage: `url('${AppConfig.constants.assetsBaseURL}/svg/filter.svg')` }}
                        />
                        <div className="data-filter__no-filters-title">
                            {intl.formatMessage({ id: 'hints.noFilters' })}
                        </div>
                    </div>
                }
            </div>
        );
    }
}

DataFilterPanel.propTypes = {
    mapInstance: PropTypes.object.isRequired,
    mapInstanceId: PropTypes.string.isRequired,
    filters: PropTypes.object.isRequired,
    filterCombiner: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(DataFilterPanel);
