export const DropdownStyle = {
    height: 20,
    width: 140,
};

export const ButtonStyle = {
    height: 18,
    lineHeight: '16px',
    textAlign: 'left',
};

export const IconButtonStyle = {
    padding: 0,
    marginTop: -16,
    width: 24,
    height: 24,
};

export const LabelStyle = {
    color: '#fff',
    fontWeight: 'bold',
    lineHeight: '18px',
    paddingLeft: 0,
    paddingRight: 0,
    height: 18,
    width: 140,
};

export const UnderlineStyle = {
    borderTopColor: 'transparent',
    borderBottomColor: 'transparent',
};

export const IconStyle = {
    paddingLeft: 0,
    paddingRight: 0,
    marginRight: -32,
    marginTop: -26,
};
