
const getType = require('../util/get_type');
const ValidationError = require('../error/validation_error');

module.exports = function validateString(options) {
    const value = options.value;
    const key = options.key;
    const type = getType(value);

    if (type === 'number' && ( //!
        key.indexOf('bubble-outline-width') !== -1 || //!
            key.indexOf('bubble-radius') !== -1 //!
    )) { //!
        return []; //!
    } //!


    if (type !== 'string') {
        return [new ValidationError(key, value, 'string expected, %s found', type)];
    }

    return [];
};
