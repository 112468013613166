import React from 'react';
import BusComponent from '../../BusComponent';
import classnames from 'classnames';

class ImportSummaryLevelItem extends BusComponent {
    onClick = () => {
        if (this.props.onClick) {
            this.props.onClick(this.props.summaryLevel);
        }
    }

    render() {
        return (<div
            onClick={this.onClick}
            className={classnames(
                                'geo-selection-import-popup__list-item flex-fixed flex-it space-between center padded-10 rounded',
                                { 'geo-selection-import-popup__list-item--selected': this.props.selected },
                                { 'geo-selection-import-popup__list-item--option': !this.props.selected }
            )}
        >
            <span className="geo-selection-import-popup__list-item-label">{this.props.summaryLevel.label}</span>
            {this.props.selected && <i className="material-icons">radio_button_checked</i>}
            {!this.props.selected && <i className="material-icons">radio_button_unchecked</i>}
        </div>);
    }
}

export default ImportSummaryLevelItem;
