import AppConfig from '../appConfig';
import BaseController from './BaseController';
import DataVisualizationController from '../controllers/DataVisualizationController';
import ColorPaletteDataSource from '../dataSources/ColorPaletteDataSource';
import UserStyleDataSource from '../dataSources/UserStyleDataSource';
import MetadataDataSource from '../dataSources/MetadataDataSource';
import ProjectDataSource from '../dataSources/ProjectDataSource';
import MapDataSource from '../dataSources/MapDataSource';

import VariableSelection from '../objects/VariableSelection';
import FieldListField from '../objects/FieldListField';
import FieldList from '../objects/FieldList';
import FilterSet from '../objects/FilterSet';
import DataTheme from '../objects/DataTheme';
import Filter from '../objects/Filter';

import ChangeOverTimeType from '../enums/ChangeOverTimeType';
import ColorPaletteType from '../enums/ColorPaletteType';
import NumberFormat from '../enums/NumberFormat';
import VariableValueType from '../enums/VariableValueType';

import {
  getMetadataObjectsFromVariableSelectionItem,
  variableQualifiedName,
} from '../helpers/Util';

class ChangeOverTimeController extends BaseController {
  static get name() {
    return 'ChangeOverTimeController';
  }

  static getInstance(options) {
    return new ChangeOverTimeController(options);
  }

  onActivate() {
    this.bindGluBusEvents({
      CHANGE_SURVEY_YEAR_REQUEST: this.handleChangeSurveyYearRequest,
      CHANGE_OVER_TIME_REQUEST: this.handleChangeOverTimeRequest,
      APPLY_CHANGE_OVER_TIME_REQUEST: this.applyChangeOverTimeRequest,
      CLEAR_CHANGE_OVER_TIME_REQUEST: this.handleClearChangeOverTimeRequest,
    });

    this.colorPaletteDataSource = this.activateSource(ColorPaletteDataSource);
    this.metadataDataSource = this.activateSource(MetadataDataSource);
    this.projectDataSource = this.activateSource(ProjectDataSource);
    this.mapDataSource = this.activateSource(MapDataSource);
    this.userStyleDataSource = this.activateSource(UserStyleDataSource);
  }

  onDeactivate() {
    this.unbindGluBusEvents();
  }

  handleChangeSurveyYearRequest = payload => {
    const { mapInstanceId, item } = payload;
    const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);

    // Data selection change is automatically triggered when clearing COT
    if (mapInstance.dataTheme.isChangeOverTimeApplied) {
      const dataChangePayload = {
        mapInstanceId: mapInstance.id,
        item,
      };

      this.bus.emit('CLEAR_CHANGE_OVER_TIME_REQUEST', dataChangePayload);
    } else {
      const variableSelection = new VariableSelection();
      variableSelection.items.push(item);

      const dataChangePayload = {
        mapInstanceId: mapInstance.id,
        newDataSelection: { variableSelection },
      };

      this.bus.emit('DATA_SELECTION_CHANGE', dataChangePayload);
      this.bus.emit('COUNTER_LOG_REQUEST', [
        {
          event_type: 'item_request',
          event_value: `${item.variableGuid}|initial-view`,
        },
        {
          event_type: 'item_investigation',
          event_value: `${item.variableGuid}|initial-view`,
        },
      ]);
    }
  };

  /**
   * @param {object} payload
   * @param {string} payload.mapInstanceId
   * @param {import('../objects/VariableSelectionItem').default} payload.baseItem
   * @param {import('../objects/VariableSelectionItem').default} payload.compareItem
   * @param {import('../objects/VariableSelectionItem').default} payload.pointOfReferenceItem
   */
  handleChangeOverTimeRequest = payload => {
    const { mapInstanceId, baseItem, compareItem, pointOfReferenceItem } = payload;
    const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);

    const variableSelection = new VariableSelection();
    variableSelection.items.push(baseItem);

    const baseItemChangePayload = {
      mapInstanceId: mapInstance.id,
      newDataSelection: { variableSelection },
    };

    const cotRequestPayload = {
      mapInstanceId,
      currentItem: baseItem,
      compareWithVariableItem: compareItem,
      pointOfReferenceItem,
      changeType:
        mapInstance.dataTheme.appliedChangeOverTimeType ||
        ChangeOverTimeType.COMPUTE_CHANGE_PERCENT,
    };

    this.bus.once('MAP_NEW_DATA_THEME_APPLIED', () => {
      this.bus.emit('APPLY_CHANGE_OVER_TIME_REQUEST', cotRequestPayload);
    });

    this.bus.emit('APPLYING_CHANGE_OVER_TIME_REQUEST_STARTED', cotRequestPayload);
    this.bus.emit('DATA_SELECTION_CHANGE', baseItemChangePayload);
  };

  /**
   * @param {object} payload
   * @param {string} payload.mapInstanceId
   * @param {import('../objects/VariableSelectionItem').default} payload.compareWithVariableItem
   * @param {import('../objects/VariableSelectionItem').default} payload.pointOfReferenceItem
   * @param {string} payload.changeType
   * @param {string|undefined} payload.titlePrefix
   */
  applyChangeOverTimeRequest = async payload => {
    const {
      mapInstanceId,
      compareWithVariableItem,
      pointOfReferenceItem,
      changeType,
      titlePrefix = 'Change',
    } = payload;

    try {
      const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);
      const metadataGroup = this.metadataDataSource.groupsMetadata.find(
        gm => gm.id === mapInstance.metadataGroupId,
      );
      const currentSelectionMetadata = getMetadataObjectsFromVariableSelectionItem(
        mapInstance.dataTheme.variableSelection.items[0],
        this.metadataDataSource.currentMetadata,
      );

      // load proper map definition
      const changeOverTime = metadataGroup.changeOverTime.find(cot =>
        cot.surveys.some(survey => survey.name === currentSelectionMetadata.survey.name),
      );
      const changeOverTimeMapId = changeOverTime.id;
      const changeOverTimeMap = await this.mapDataSource.loadMapByURL(
        `${AppConfig.constants.mapsURL}/${changeOverTimeMapId}.json`,
      );

      // load required metadata for variable item we compare to
      // build proper query here
      await this.metadataDataSource.loadPartialMetadata({
        surveys: {
          [compareWithVariableItem.surveyName]: {
            datasets: {
              [compareWithVariableItem.datasetAbbreviation]: {
                tables: {
                  [compareWithVariableItem.tableGuid]: true,
                },
              },
            },
          },
        },
      });

      // Loading partial data changes content of MetadataDataSource
      // Previously cached requests are not called and data is not updated
      // Purging cache ensures correct data update
      this.metadataDataSource.purgeDoneReuqests();

      const compareToSelectionMetadata = getMetadataObjectsFromVariableSelectionItem(
        compareWithVariableItem,
        this.metadataDataSource.currentMetadata,
      );

      let multiplier = 1;

      let filterSet, colorPalette, renderer;
      const fieldList = new FieldList();
      fieldList.fields = [];

      // GEOName field
      fieldList.fields.push(
        new FieldListField({
          fieldName: currentSelectionMetadata.survey.geoQNameField,
          surveyName: currentSelectionMetadata.survey.name,
          datasetAbbreviation: currentSelectionMetadata.dataset.abbrevation,
          label: 'Geography full name',
          isGeoNameField: true,
          hideFromUser: false,
        }),
      );

      // FIPS field
      fieldList.fields.push(
        new FieldListField({
          fieldName: currentSelectionMetadata.survey.geoFipsField,
          surveyName: currentSelectionMetadata.survey.name,
          datasetAbbreviation: currentSelectionMetadata.dataset.abbrevation,
          label: 'FIPS',
          isGeoNameField: false,
          hideFromUser: true,
        }),
      );

      // current variable selection field
      const currentVariableField = new FieldListField({
        fieldName: currentSelectionMetadata.variable.uuid,
        surveyName: currentSelectionMetadata.survey.name,
        tableUuid: currentSelectionMetadata.table.uuid,
        datasetAbbreviation: currentSelectionMetadata.dataset.abbrevation,
        label: `${currentSelectionMetadata.survey.year} - ${currentSelectionMetadata.variable.label}`,
        formatting: currentSelectionMetadata.variable.formatting || NumberFormat.FORMAT_NUMBER,
        isGeoNameField: false,
        hideFromUser: false,
      });
      fieldList.fields.push(currentVariableField);

      // For Change over time on currency variables calculate adjusted value of dollar
      const isCurrencyVariable =
        currentSelectionMetadata.variable.isCurrency &&
        compareToSelectionMetadata.variable.isCurrency;

      if (isCurrencyVariable) {
        multiplier =
          this.metadataDataSource.cpiValuesByYear[compareToSelectionMetadata.survey.year] /
          this.metadataDataSource.cpiValuesByYear[currentSelectionMetadata.survey.year];

        // Insert dollar adjustment info when working with currency labels
        // Some variables already have indication of adjustment, change year in label
        // For other variables add postfix notification of changes.
        const currentPostfix = `(In ${currentSelectionMetadata.survey.year} Inflation Adjusted Dollars)`;
        const comparePostfix = `(In ${compareToSelectionMetadata.survey.year} Inflation Adjusted Dollars)`;

        const initialLabel = currentSelectionMetadata.variable.label;
        const initialLabelAdjusted = initialLabel.replace(currentPostfix, comparePostfix);
        let customLabel = `${currentSelectionMetadata.survey.year} - ${initialLabelAdjusted}`;

        if (initialLabelAdjusted === initialLabel) {
          customLabel = `${currentSelectionMetadata.survey.year} - ${initialLabelAdjusted} ${comparePostfix}`;
        }

        const currentVariableFieldMulti = new FieldListField({
          fieldName: `X${currentSelectionMetadata.variable.uuid}`,
          surveyName: currentSelectionMetadata.survey.name,
          datasetAbbreviation: currentSelectionMetadata.dataset.abbrevation,
          label: customLabel,
          formatting: currentSelectionMetadata.variable.formatting || NumberFormat.FORMAT_NUMBER,
          isComputed: true,
          fieldMultiplier: multiplier,
          computeFunction: 'COMPUTE_MULTIPLY',
          fieldNumerator: variableQualifiedName(
            currentSelectionMetadata.survey,
            currentSelectionMetadata.dataset,
            currentSelectionMetadata.variable,
          ),
          isGeoNameField: false,
          hideFromUser: false,
        });
        fieldList.fields.push(currentVariableFieldMulti);
      }

      // field for variable that we are doing comparison against
      const compareVariableField = new FieldListField({
        fieldName: compareToSelectionMetadata.variable.uuid,
        surveyName: compareToSelectionMetadata.survey.name,
        tableUuid: compareToSelectionMetadata.table.uuid,
        datasetAbbreviation: compareToSelectionMetadata.dataset.abbrevation,
        label: `${compareToSelectionMetadata.survey.year} - ${compareToSelectionMetadata.variable.label}`,
        formatting: currentSelectionMetadata.variable.formatting || NumberFormat.FORMAT_NUMBER,
        isGeoNameField: false,
        hideFromUser: false,
      });
      fieldList.fields.push(compareVariableField);

      // composition change requires percent changes so we are
      // adding fields for percent change for current variable and variable we are
      // doing comparison against
      if (changeType === ChangeOverTimeType.COMPUTE_CHANGE_COMPOSITION) {
        currentVariableField.hideFromUser = true;
        compareVariableField.hideFromUser = true;
        // add percent fields of current variable `(currentVariable / currentParentVariable)%`
        fieldList.fields.push(
          new FieldListField({
            fieldName: `percent-${currentSelectionMetadata.variable.defaultPercentBaseVariable.uuid}`,
            surveyName: currentSelectionMetadata.survey.name,
            datasetAbbreviation: currentSelectionMetadata.dataset.abbrevation,
            label: `${currentVariableField.label} (percent of: ${currentSelectionMetadata.variable.defaultPercentBaseVariable.label})`,
            isComputed: true,
            computeFunction: 'COMPUTE_PERCENT',
            formatting: NumberFormat.FORMAT_PERCENT,
            fieldNumerator: variableQualifiedName(
              currentSelectionMetadata.survey,
              currentSelectionMetadata.dataset,
              currentSelectionMetadata.variable,
            ),
            fieldDenominator: variableQualifiedName(
              currentSelectionMetadata.survey,
              currentSelectionMetadata.dataset,
              currentSelectionMetadata.variable.defaultPercentBaseVariable,
            ),
            isGeoNameField: false,
            hideFromUser: false,
          }),
        );

        fieldList.fields.push(
          new FieldListField({
            fieldName: currentSelectionMetadata.variable.defaultPercentBaseVariable.uuid,
            surveyName: currentSelectionMetadata.survey.name,
            datasetAbbreviation: currentSelectionMetadata.dataset.abbrevation,
            label: `${currentSelectionMetadata.survey.year}-${currentSelectionMetadata.variable.defaultPercentBaseVariable.label}`,
            formatting:
              currentSelectionMetadata.variable.defaultPercentBaseVariable.formatting ||
              NumberFormat.FORMAT_NUMBER,
            isGeoNameField: false,
            hideFromUser: true,
          }),
        );

        // add percent fields  for variable that we are doing comparison against
        // `(refVariable / refParentVariable)%`
        fieldList.fields.push(
          new FieldListField({
            fieldName: `percent-${compareToSelectionMetadata.variable.defaultPercentBaseVariable.uuid}`,
            surveyName: compareToSelectionMetadata.survey.name,
            datasetAbbreviation: compareToSelectionMetadata.dataset.abbrevation,
            label: `${compareVariableField.label} (percent of: ${compareToSelectionMetadata.variable.defaultPercentBaseVariable.label})`,
            isComputed: true,
            computeFunction: 'COMPUTE_PERCENT',
            formatting: NumberFormat.FORMAT_PERCENT,
            fieldNumerator: variableQualifiedName(
              compareToSelectionMetadata.survey,
              compareToSelectionMetadata.dataset,
              compareToSelectionMetadata.variable,
            ),
            fieldDenominator: variableQualifiedName(
              compareToSelectionMetadata.survey,
              compareToSelectionMetadata.dataset,
              compareToSelectionMetadata.variable.defaultPercentBaseVariable,
            ),
            isGeoNameField: false,
            hideFromUser: false,
          }),
        );

        fieldList.fields.push(
          new FieldListField({
            fieldName: compareToSelectionMetadata.variable.defaultPercentBaseVariable.uuid,
            surveyName: compareToSelectionMetadata.survey.name,
            datasetAbbreviation: compareToSelectionMetadata.dataset.abbrevation,
            label: `${compareToSelectionMetadata.survey.year}-${compareToSelectionMetadata.variable.defaultPercentBaseVariable.label}`,
            formatting:
              currentSelectionMetadata.variable.defaultPercentBaseVariable.formatting ||
              NumberFormat.FORMAT_NUMBER,
            isGeoNameField: false,
            hideFromUser: true,
          }),
        );
      }

      const numerator = variableQualifiedName(
        compareToSelectionMetadata.survey,
        compareToSelectionMetadata.dataset,
        compareToSelectionMetadata.variable,
      );
      const numeratorParent = variableQualifiedName(
        compareToSelectionMetadata.survey,
        compareToSelectionMetadata.dataset,
        compareToSelectionMetadata.variable.defaultPercentBaseVariable,
      );
      const denominator = variableQualifiedName(
        currentSelectionMetadata.survey,
        currentSelectionMetadata.dataset,
        currentSelectionMetadata.variable,
      );
      const denominatorParent = variableQualifiedName(
        currentSelectionMetadata.survey,
        currentSelectionMetadata.dataset,
        currentSelectionMetadata.variable.defaultPercentBaseVariable,
      );

      // add computed field that calculates requested change over time
      const changeOverTimeField = new FieldListField({
        fieldName: `X${currentSelectionMetadata.variable.uuid}-${compareToSelectionMetadata.variable.uuid}`,
        isComputed: true,
        label: `${changeOverTime.labelPrefix} ${currentSelectionMetadata.survey.year}-${compareToSelectionMetadata.survey.year} ${changeOverTime.labelSuffix}`,
        formatting: currentSelectionMetadata.variable.formatting || NumberFormat.FORMAT_NUMBER,
        isGeoNameField: false,
        hideFromUser: false,
        isChangeOverTimeField: true,
        pointOfReferenceFieldQName: pointOfReferenceItem.qualifiedName,
        computeFunction: changeType,
        fieldMultiplier: multiplier,
        fieldNumerator: numerator,
        fieldNumeratorParent: numeratorParent,
        fieldDenominator: denominator,
        fieldDenominatorParent: denominatorParent,
      });
      fieldList.fields.push(changeOverTimeField);

      // for percent and composition change add percentage filter set
      // with cutpoints: [-50, -40, -30, -20, -10, 0, 10, 20, 30, 40, 50]
      // also assign first diverging color palette color palette from change over time map color palettes
      if (
        changeType === ChangeOverTimeType.COMPUTE_CHANGE_PERCENT ||
        changeType === ChangeOverTimeType.COMPUTE_CHANGE_COMPOSITION
      ) {
        changeOverTimeField.formatting = NumberFormat.FORMAT_PERCENT;
        filterSet = new FilterSet({
          filters: [],
          valueFormat: 'Percent',
        });
        let i = -50;
        while (i < 70) {
          filterSet.filters.push(
            new Filter({
              from: i - 10,
              to: i,
            }),
          );
          i += 10;
        }
        filterSet.filters[0].from = -Number.MAX_SAFE_INTEGER;
        filterSet.filters[filterSet.filters.length - 1].to = Number.MAX_SAFE_INTEGER;
        renderer = DataVisualizationController._createValueRenderer(
          fieldList,
          changeOverTimeField,
          filterSet,
        );

        const preferredStyleSettingsIdx =
          this.projectDataSource.currentFrame.mapInstances.indexOf(mapInstance);
        colorPalette =
          this.userStyleDataSource.settings[preferredStyleSettingsIdx].shadedAreaColorPalette;

        if (!colorPalette) {
          colorPalette = this.colorPaletteDataSource.currentColorPalettes.getColorPaletteByType(
            ColorPaletteType.POLYGON_DIVERGING,
            changeOverTimeMap.colorPalettesURL,
          );
        }
      }

      // for difference change create bubble renderer
      // and assign first single bubble color color palette from change over time map color palettes
      if (changeType === ChangeOverTimeType.COMPUTE_CHANGE) {
        const bubbleSize = this.userStyleDataSource.getBubbleSize(
          mapInstance,
          mapInstance.dataTheme,
          this.metadataDataSource.currentMetadata,
          this.projectDataSource.currentFrame,
        );
        const bubbleSizeFactor = bubbleSize / currentSelectionMetadata.variable.bubbleSizeScale;
        renderer = DataVisualizationController._createDefaultBubbleRenderer(
          fieldList,
          changeOverTimeField,
          bubbleSizeFactor,
        );
        renderer.sizeTitle = `${mapInstance.dataTheme.title}`;
        renderer.colorTitle = '';

        const preferredStyleSettingsIdx =
          this.projectDataSource.currentFrame.mapInstances.indexOf(mapInstance);
        colorPalette =
          this.userStyleDataSource.settings[preferredStyleSettingsIdx].bubbleSingleColorPalette;

        if (!colorPalette) {
          colorPalette = this.colorPaletteDataSource.currentColorPalettes.getColorPaletteByType(
            ColorPaletteType.BUBBLE_SINGLE_COLOR,
            changeOverTimeMap.colorPalettesURL,
          );
        }
      }

      renderer.applyColorPalette(colorPalette, false);

      const newDataTheme = new DataTheme({
        title: `${titlePrefix} of ${currentSelectionMetadata.variable.label} from ${currentSelectionMetadata.survey.year} to ${compareToSelectionMetadata.survey.year}`,
        displayName: `${changeOverTime.labelPrefix} ${currentSelectionMetadata.survey.year}-${compareToSelectionMetadata.survey.year} ${changeOverTime.labelSuffix}`,
        variableSelection: mapInstance.dataTheme.variableSelection.clone(),
        filterSet,
        colorPaletteId: colorPalette.id,
        colorPaletteFlipped: false,
        colorPaletteType: colorPalette.type,
        bubbleValueType: VariableValueType.NUMBER,
      });
      newDataTheme.rendering = [renderer];

      this.bus.emit('APPLYING_CHANGE_OVER_TIME_REQUEST_DONE', payload);
      this.bus.emit('APPLY_NEW_DATA_THEME_REQUEST', {
        source: this,
        mapInstanceId,
        dataTheme: newDataTheme,
        mapId: changeOverTimeMapId,
      });
      this.bus.emit('COUNTER_LOG_REQUEST', [
        {
          event_type: 'item_request',
          event_value: `${newDataTheme.title}`,
        },
        {
          event_type: 'item_investigation',
          event_value: `${newDataTheme.title}`,
        },
      ]);
    } catch (error) {
      console.warn(error);
    }
  };

  handleClearChangeOverTimeRequest = payload => {
    const { mapInstanceId, item } = payload;
    const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);
    mapInstance.dataTheme.filterSet = undefined;

    const variableSelection = new VariableSelection();
    variableSelection.items.push(item);
    const newDataSelection = { variableSelection };

    const dataChangePayload = {
      mapInstanceId,
      newDataSelection,
    };

    this.bus.emit('DATA_SELECTION_CHANGE', dataChangePayload);
  };
}

export default ChangeOverTimeController;
