const applicationMode = {
    VIEW: 'view',
    EXPLORE: 'explore',
    EDIT: 'edit',
    ADMIN: 'admin',
    EMBED: 'embed',
    isViewMode: t => {
        if (t === 'view' || t === 'embed') {
            return true;
        }
        return false;
    },
};

export default applicationMode;
