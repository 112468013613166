import GLU from '../glu2.js/src/index';

import { isStorageAvailable } from '../helpers/Util';
import AppConfig from '../appConfig';

const STORAGE = window.localStorage;
const IS_STORAGE_AVAILABLE = isStorageAvailable(STORAGE);
const INFO_BUBBLE_MODE_STORAGE_KEY = `${AppConfig.constants.environment}-InfoBubbleMode`;
const PREVIOUS_INFO_BUBBLE_MODE_STORAGE_KEY = `${AppConfig.constants.environment}-PreviousInfoBubbleMode`;
const DEFAULT_INFO_BUBBLE_MODE = AppConfig.constants.defaultInfoBubbleMode;

class InfoBubbleModeDataSource extends GLU.DataSource {
    static get name() {
        return 'InfoBubbleModeDataSource';
    }

    static getInstance() {
        return new InfoBubbleModeDataSource();
    }

    constructor() {
        super();
        if (IS_STORAGE_AVAILABLE) {
            const infoBubbleMode = localStorage.getItem(
                INFO_BUBBLE_MODE_STORAGE_KEY,
            );
            this._currentInfoBubbleMode =
                infoBubbleMode || DEFAULT_INFO_BUBBLE_MODE;

            const previousInfoBubbleMode = localStorage.getItem(
                PREVIOUS_INFO_BUBBLE_MODE_STORAGE_KEY,
            );
            this._previousInfoBubbleMode =
                previousInfoBubbleMode || DEFAULT_INFO_BUBBLE_MODE;
        } else {
            this._currentInfoBubbleMode = DEFAULT_INFO_BUBBLE_MODE;
            this._previousInfoBubbleMode = DEFAULT_INFO_BUBBLE_MODE;
        }
    }

    /**
     * @returns {InfoBubbleMode}
     */
    get currentInfoBubbleMode() {
        return this._currentInfoBubbleMode;
    }

    /**
     * @param {InfoBubbleMode} newInfoBubbleMode
     */
    updateInfoBubbleMode(newInfoBubbleMode) {
        this._currentInfoBubbleMode = newInfoBubbleMode;
        if (IS_STORAGE_AVAILABLE) {
            localStorage.setItem(INFO_BUBBLE_MODE_STORAGE_KEY, newInfoBubbleMode);
        }
    }

    /**
     * @returns {InfoBubbleMode}
     */
    get previousInfoBubbleMode() {
        return this._previousInfoBubbleMode;
    }

    /**
     * @param {InfoBubbleMode} previousInfoBubbleMode
     */
    updatePreviousInfoBubbleMode(previousInfoBubbleMode) {
        this._previousInfoBubbleMode = previousInfoBubbleMode;
        if (IS_STORAGE_AVAILABLE) {
            localStorage.setItem(PREVIOUS_INFO_BUBBLE_MODE_STORAGE_KEY, previousInfoBubbleMode);
        }
    }
}

export default InfoBubbleModeDataSource;
