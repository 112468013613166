import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import LegendLayout from '../../../enums/LegendLayout';
import Orientation from '../../../enums/Orientation';

import BubbleControls from './BubbleControls';
import BubbleColorPalette from './BubbleColorPalette';
import BubbleTitle from './BubbleTitle';
import BubblePreviewInfo from './BubblePreviewInfo';
import BubbleGraphicsSimplified from './BubbleGraphicsSimplified';
import AutoAdjust from '../autoAdjust/AutoAdjust';

const BubbleSimplified = props => (
    <div className="bubble-legend flex-it column space-between">
        <BubbleTitle title={props.renderer.sizeTitle} />
        <div className="flex-it space-around grow no-shrink">
            <BubbleGraphicsSimplified bubbleLabel={props.bubbleLabel} />
        </div>
        <BubbleControls
            currentBubbleSizeLog10={props.currentBubbleSizeLog10}
            onBubbleSizeChange={props.onBubbleSizeChange}
        />
        <AutoAdjust
            mapInstance={props.mapInstance}
            onAutoAdjust={props.onAdjustBubblesClick}
            orientation={props.orientation}
        />
        <BubblePreviewInfo maxBubbleLabel={props.maxBubbleLabel} />
        <BubbleColorPalette
            layout={LegendLayout.SIMPLIFIED}
            bubbleValueType={props.bubbleValueType}
            renderer={props.renderer}
            mapInstanceId={props.mapInstanceId}
        />
    </div>
);

BubbleSimplified.propTypes = {
    currentBubbleSizeLog10: PropTypes.number.isRequired,
    bubbleLabel: PropTypes.string.isRequired,
    maxBubbleLabel: PropTypes.string.isRequired,
    mapInstanceId: PropTypes.string.isRequired,
    renderer: PropTypes.object.isRequired,
    bubbleValueType: PropTypes.string.isRequired,
    onBubbleSizeChange: PropTypes.func.isRequired,
    onAdjustBubblesClick: PropTypes.func.isRequired,
    mapInstance: PropTypes.object.isRequired,
    orientation: PropTypes.oneOf(Object.keys(Orientation)).isRequired,
    intl: PropTypes.object.isRequired,
};

BubbleSimplified.defaultProps = {};

export default injectIntl(BubbleSimplified);
