'use strict';var ref = require('../values');
var typeOf = ref.typeOf;
var Match = function Match(inputType, outputType, input, cases, outputs, otherwise) {
    this.inputType = inputType;
    this.type = outputType;
    this.input = input;
    this.cases = cases;
    this.outputs = outputs;
    this.otherwise = otherwise;
};
Match.parse = function parse(args, context) {
    if (args.length < 5) {
        return context.error('Expected at least 4 arguments, but found only ' + (args.length - 1) + '.');
    }
    if (args.length % 2 !== 1) {
        return context.error('Expected an even number of arguments.');
    }
    var inputType;
    var outputType;
    if (context.expectedType && context.expectedType.kind !== 'value') {
        outputType = context.expectedType;
    }
    var cases = {};
    var outputs = [];
    for (var i = 2; i < args.length - 1; i += 2) {
        var labels = args[i];
        var value = args[i + 1];
        if (!Array.isArray(labels)) {
            labels = [labels];
        }
        var labelContext = context.concat(i);
        if (labels.length === 0) {
            return labelContext.error('Expected at least one branch label.');
        }
        for (var i$1 = 0, list = labels; i$1 < list.length; i$1 += 1) {
            var label = list[i$1];
            if (typeof label !== 'number' && typeof label !== 'string') {
                return labelContext.error('Branch labels must be numbers or strings.');
            } else if (typeof label === 'number' && Math.abs(label) > Number.MAX_SAFE_INTEGER) {
                return labelContext.error('Branch labels must be integers no larger than ' + Number.MAX_SAFE_INTEGER + '.');
            } else if (typeof label === 'number' && Math.floor(label) !== label) {
                return labelContext.error('Numeric branch labels must be integer values.');
            } else if (!inputType) {
                inputType = typeOf(label);
            } else if (labelContext.checkSubtype(inputType, typeOf(label))) {
                return null;
            }
            if (typeof cases[String(label)] !== 'undefined') {
                return labelContext.error('Branch labels must be unique.');
            }
            cases[String(label)] = outputs.length;
        }
        var result = context.parse(value, i, outputType);
        if (!result) {
            return null;
        }
        outputType = outputType || result.type;
        outputs.push(result);
    }
    var input = context.parse(args[1], 1, inputType);
    if (!input) {
        return null;
    }
    var otherwise = context.parse(args[args.length - 1], args.length - 1, outputType);
    if (!otherwise) {
        return null;
    }
    return new Match(inputType, outputType, input, cases, outputs, otherwise);
};
Match.prototype.evaluate = function evaluate(ctx) {
    var input = this.input.evaluate(ctx);
    return (this.outputs[this.cases[input]] || this.otherwise).evaluate(ctx);
};
Match.prototype.eachChild = function eachChild(fn) {
    fn(this.input);
    this.outputs.forEach(fn);
    fn(this.otherwise);
};
module.exports = Match;