import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../Tooltip';

import Orientation from '../../../enums/Orientation';

import ShadedColorPaletteHorizontal from './ShadedColorPaletteHorizontal';
import AutoAdjust from '../autoAdjust/AutoAdjust';

const SingleShadedAreaLegendSimplified = props => {
    const { mapInstance, orientation, mapInstanceId, variables, renderer, onAutoAdjust } = props;
    const { fieldList, nullDataRuleIndex, insufficientDataRuleIndex, rules } = renderer;

    const variable = variables[0];
    const title = mapInstance.dataTheme.title || (variable ? variable.title : '');
    const legendHighlighted = rules.some(rule => rule.highlighted);

    return (
        <div className="horizontal-shaded-area-legend flex-it column grow">
            <Tooltip
                className="legend-variable-title-overlay"
                placement="top"
                mouseEnterDelay={0.5}
                mouseLeaveDelay={0}
                overlay={title}
            >
                <span className="legend-variable-title">
                    {title}
                </span>
            </Tooltip>
            <ShadedColorPaletteHorizontal
                className="shaded-color-palette-horizontal"
                mapInstanceId={mapInstanceId}
                fields={fieldList.fields}
                nullDataRuleIndex={nullDataRuleIndex}
                insufficientDataRuleIndex={insufficientDataRuleIndex}
                rules={rules}
                showLabels
                legendHighlighted={legendHighlighted}
            />
            <AutoAdjust
                mapInstance={mapInstance}
                onAutoAdjust={onAutoAdjust}
                orientation={orientation}
            />
        </div>
    );
};

SingleShadedAreaLegendSimplified.propTypes = {
    mapInstance: PropTypes.object.isRequired,
    orientation: PropTypes.oneOf(Object.keys(Orientation)).isRequired,
    renderer: PropTypes.object.isRequired,
    mapInstanceId: PropTypes.string.isRequired,
    variables: PropTypes.array.isRequired,
    onAutoAdjust: PropTypes.func.isRequired,
};

SingleShadedAreaLegendSimplified.defaultProps = {};

export default SingleShadedAreaLegendSimplified;
