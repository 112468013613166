import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import ButtonToggleItem from './ButtonToggleItem';

const ButtonToggle = props => {
    const { items, className, selectedItem, onItemClick } = props;
    const classes = classNames('button-toggle', className);
    return (
        <div className={classes}>
            {
                items.map(item => (
                    <ButtonToggleItem
                        key={item.id}
                        item={item}
                        selected={item.id === selectedItem.id}
                        onClick={onItemClick}
                    />
                ))
            }
        </div>
    );
};


ButtonToggle.propTypes = {
    className: PropTypes.string,
    onItemClick: PropTypes.func.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        text: PropTypes.string.isRequired,
    })).isRequired,
    selectedItem: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        text: PropTypes.string.isRequired,
    }),
};

ButtonToggle.defaultProps = {
    className: undefined,
    selectedItem: { id: -1, text: 'Nothing selected' },
};

export default ButtonToggle;
