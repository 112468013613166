// @ts-check
import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import BusComponent from '../../BusComponent';
import VisualizationType from '../../../enums/VisualizationType';
import VisualizationValueType from '../../../enums/VariableValueType';
import AppConfig from '../../../appConfig';

/**
 * @typedef Props
 * @property {import('../../../objects/DataTheme').default} dataTheme
 * @property  {string} mapInstanceId
 * @property {import('react-intl').intlShape} intl
 *
 * @typedef State
 * @property {boolean} bubbleVisualizationTypeEnabled
 * @property {boolean} shadedVisualizationTypeEnabled
 *
 * @extends {BusComponent<Props, State>}
 */
class ChangeVisualization extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            bubbleVisualizationTypeEnabled: false,
            shadedVisualizationTypeEnabled: false,
        };
    }

    componentDidMount() {
        this.bindGluBusEvents({
            VISUALIZATION_TYPE_AVAILABILITY_INFO: this
                .onVisualizationTypeAvailabilityInfo,
        });
        this.emit(
            'GET_VISUALIZATION_TYPE_AVAILABILITY_INFO_REQUEST',
            this.props.dataTheme.variableSelection,
        );
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    onVisualizationTypeAvailabilityInfo({
        shadedVisualizationTypeEnabled,
        bubbleVisualizationTypeEnabled,
    }) {
        this.setState({
            shadedVisualizationTypeEnabled,
            bubbleVisualizationTypeEnabled,
        });
    }

    setVisualizationType = (visualizationType, valueType) => {
        const { mapInstanceId, dataTheme } = this.props;
        if (dataTheme.visualizationType === visualizationType &&
            dataTheme.getVisualizationValueType(visualizationType) === valueType) {
            return;
        }

        this.emit('VISUALIZATION_TYPE_CHANGE', {
            source: this,
            mapInstanceId,
            newVisualizationType: visualizationType,
            newVisualizationValueType: valueType,
        });
    };

    render() {
        const { intl, dataTheme } = this.props;
        const {
            shadedVisualizationTypeEnabled,
            bubbleVisualizationTypeEnabled,
        } = this.state;
        const { visualizationType } = dataTheme;
        const visualizationValueType = dataTheme.getVisualizationValueType(visualizationType);

        const shadedAreaButtonClassnames = classNames(
            'auto-adjust-change-visualization__button-row',
            {
                selected: visualizationType === VisualizationType.SHADED_AREA &&
                          visualizationValueType === VisualizationValueType.NUMBER,
                disabled: !shadedVisualizationTypeEnabled,
            },
        );
        const shadedAreaPercentButtonClassnames = classNames(
            'auto-adjust-change-visualization__button-row',
            {
                selected: visualizationType === VisualizationType.SHADED_AREA &&
                    visualizationValueType === VisualizationValueType.PERCENT,
                disabled: !shadedVisualizationTypeEnabled,
            },
        );

        const bubblesButtonClassnames = classNames(
            'auto-adjust-change-visualization__button-row',
            {
                selected: visualizationType === VisualizationType.BUBBLES &&
                visualizationValueType === VisualizationValueType.NUMBER,
                disabled: !bubbleVisualizationTypeEnabled,
            },
        );
        const bubblesPercentButtonClassnames = classNames(
            'auto-adjust-change-visualization__button-row',
            {
                selected: visualizationType === VisualizationType.BUBBLES &&
                    visualizationValueType === VisualizationValueType.PERCENT,
                disabled: !bubbleVisualizationTypeEnabled,
            },
        );

        const shadedArea = intl.formatMessage({ id: 'dataBrowser.shadedArea' });
        const bubbles = intl.formatMessage({ id: 'dataBrowser.bubbles' });
        const notAvailableMessage = intl.formatMessage({ id: 'legend.notAvailable' });

        const shadedAreaLabel = shadedVisualizationTypeEnabled
                                ? shadedArea
                                : `${shadedArea} (${notAvailableMessage})`;
        const bubblesLabel = bubbleVisualizationTypeEnabled
                             ? bubbles
                             : `${bubbles} (${notAvailableMessage})`;

        return (
            <div className="auto-adjust-change-visualization">
                <span className="header">{intl.formatMessage({ id: 'legend.visualizeAs' })}:</span>
                <div className={shadedAreaButtonClassnames}>
                    <button
                        className="btn-link"
                        onClick={() => this.setVisualizationType(VisualizationType.SHADED_AREA, VisualizationValueType.NUMBER)}
                        disabled={!shadedVisualizationTypeEnabled}
                        aria-disabled={!shadedVisualizationTypeEnabled}
                        title={shadedAreaLabel}
                        aria-label={shadedAreaLabel}
                    >
                        <img
                            src={`${AppConfig.constants.assetsBaseURL}/svg/shaded_area.svg`}
                            alt="Shaded"
                        />
                        <span>{shadedAreaLabel} (num.)</span>
                    </button>
                    <i className="material-icons" aria-hidden>
                        done
                    </i>
                </div>
                <div className={shadedAreaPercentButtonClassnames}>
                    <button
                        className="btn-link"
                        onClick={() => this.setVisualizationType(VisualizationType.SHADED_AREA, VisualizationValueType.PERCENT)}
                        disabled={!shadedVisualizationTypeEnabled}
                        aria-disabled={!shadedVisualizationTypeEnabled}
                        title={shadedAreaLabel}
                        aria-label={shadedAreaLabel}
                    >
                        <img
                            src={`${AppConfig.constants.assetsBaseURL}/svg/shaded_area.svg`}
                            alt="Shaded"
                        />
                        <span>{shadedAreaLabel} (%)</span>
                    </button>
                    <i className="material-icons" aria-hidden>
                        done
                    </i>
                </div>

                <div className={bubblesButtonClassnames}>
                    <button
                        className="btn-link"
                        onClick={() => this.setVisualizationType(VisualizationType.BUBBLES, VisualizationValueType.NUMBER)}
                        disabled={!bubbleVisualizationTypeEnabled}
                        aria-disabled={!bubbleVisualizationTypeEnabled}
                        title={bubblesLabel}
                        aria-label={bubblesLabel}
                    >
                        <img
                            src={`${AppConfig.constants.assetsBaseURL}/svg/bubbles.svg`}
                            alt="Bubbles"
                        />
                        <span>{bubblesLabel} (num.)</span>
                    </button>
                    <i className="material-icons" aria-hidden>
                        done
                    </i>
                </div>
                <div className={bubblesPercentButtonClassnames}>
                    <button
                        className="btn-link"
                        onClick={() => this.setVisualizationType(VisualizationType.BUBBLES, VisualizationValueType.PERCENT)}
                        disabled={!bubbleVisualizationTypeEnabled}
                        aria-disabled={!bubbleVisualizationTypeEnabled}
                        title={bubblesLabel}
                        aria-label={bubblesLabel}
                    >
                        <img
                            src={`${AppConfig.constants.assetsBaseURL}/svg/shaded_bubbles.svg`}
                            alt="Bubbles percent"
                        />
                        <span>{bubblesLabel} (%)</span>
                    </button>
                    <i className="material-icons" aria-hidden>
                        done
                    </i>
                </div>
            </div>
        );
    }
}

ChangeVisualization.propTypes = {
    intl: PropTypes.object.isRequired,
    dataTheme: PropTypes.object.isRequired,
    mapInstanceId: PropTypes.string.isRequired,
};

export default injectIntl(ChangeVisualization);
