import EventEmitter from 'eventemitter3';

class DataSource extends EventEmitter {
    constructor(findElementCallback) {
        super();

        if (findElementCallback && typeof findElementCallback !== 'function') {
            throw new Error('findElementCallback not a function');
        }

        const defaultFindElementCallback = (storedElement, item) => {
            return storedElement.id === item.id;
        };
        this.findElementCallback = findElementCallback || defaultFindElementCallback;

        this._doneRequests = {};
        this._inProgressRequests = {};
    }

    hashRequest(params) {
        let toHash = params;
        if (typeof params === 'object') {
            toHash = {};
            Object.keys(params)
                .sort()
                .forEach(key => {
                    toHash[key] = params[key];
                });
        }
        return JSON.stringify(toHash);
    }

    parseHashRequest(hashRequest) {
        return JSON.parse(hashRequest);
    }

    addItemsInDoneRequest(doneRequest, items) {
        items.forEach(item => {
            const existingItem = this.find(storedElement => this.findElementCallback(storedElement, item));
            if (existingItem) {
                if (existingItem !== item) {
                    // update existingItem with properties from new item
                    this.updateItem(existingItem, item);
                }

                // make sure that doneRequests for this request contains
                // the same object it is in other done requests
                doneRequest.push(existingItem);
            } else {
                doneRequest.push(item);
            }
        });
    }

    cacheRequest(params, callback) {
        const requestHash = this.hashRequest(params);

        if (this._inProgressRequests[requestHash]) {
            return this._inProgressRequests[requestHash];
        }
        const promise = new Promise((resolve, reject) => {
            if (this._doneRequests[requestHash]) {
                return resolve(this._doneRequests[requestHash]);
            }
            callback(resolve, reject);
        });
        if (!this._doneRequests[requestHash]) {
            this._inProgressRequests[requestHash] = promise;
        }
        promise
            .then(response => {
                delete this._inProgressRequests[requestHash];
                const items = response.splice(0);
                this._doneRequests[requestHash] = response;
                this.addItemsInDoneRequest(this._doneRequests[requestHash], items);
            }, () => {
                delete this._inProgressRequests[requestHash];
            });
        return promise;
    }

    updateItem(item, newContent, cleanExisting = true) {
        if (!item || !newContent) {
            return;
        }
        if (cleanExisting) {
            Object.keys(item)
                .forEach(key => delete item[key]);
        }
        Object.keys(newContent)
            .forEach(key => item[key] = newContent[key]);
    }

    find(callback) {
        let item;
        Object.keys(this._doneRequests)
            .some(requestHash => {
                item = this._doneRequests[requestHash].find(callback);
                return !!item;
            });
        return item;
    }

    filter(callback) {
        const collection = [];
        Object.keys(this._doneRequests)
            .forEach(requestHash => {
                const items = this._doneRequests[requestHash].filter(callback);
                items.forEach(item => {
                    if (collection.indexOf(item) === -1) {
                        collection.push(item);
                    }
                });
            });
        return collection;
    }

    removeItemFromDoneRequests(item) {
        const removedFromDoneHashRequests = {};
        if (item) {
            Object.keys(this._doneRequests)
                .forEach(requestHash => {
                    const indexOfItemInDoneRequests = this._doneRequests[requestHash].findIndex(storedElement => this.findElementCallback(storedElement, item));
                    if (indexOfItemInDoneRequests !== -1) {
                        this._doneRequests[requestHash].splice(indexOfItemInDoneRequests, 1);
                        removedFromDoneHashRequests[requestHash] = indexOfItemInDoneRequests;
                    }
                });
        }

        return removedFromDoneHashRequests;
    }

    belongsToDoneRequest() {
        // data source has to implement belongsToDoneRequest
        return false;
    }

    sort() {
        // data source has to implement sort
        return false;
    }

    addNewItemToDoneRequests(item, positionForHash = {}) {
        if (!item) {
            return;
        }
        Object.keys(this._doneRequests)
            .forEach(requestHash => {
                const requestParams = this.parseHashRequest(requestHash);
                let where = positionForHash[requestHash];
                if (this.belongsToDoneRequest(item, requestParams, where)) {
                    if (where == null) {
                        where = 0;
                    }
                    this._doneRequests[requestHash].splice(where, 0, item);
                    this.sort(this._doneRequests[requestHash], this.parseHashRequest(requestHash));
                }
            });
    }

    repositionItemInDoneRequests(item) {
        const positions = this.removeItemFromDoneRequests(item);
        this.addNewItemToDoneRequests(item, positions);
    }

    addDoneRequest(params, response) {
        const requestHash = this.hashRequest(params);
        const items = response.splice(0);
        this._doneRequests[requestHash] = response;
        this.addItemsInDoneRequest(this._doneRequests[requestHash], items);
    }

    getDoneRequestByParam(params) {
        const requestHash = this.hashRequest(params);
        return this._doneRequests[requestHash];
    }

    deleteDoneRequestByParam(params) {
        const requestHash = this.hashRequest(params);
        delete this._doneRequests[requestHash];
    }

    purgeDoneReuqests() {
        this._doneRequests = {};
    }
}

export default DataSource;
