import React from 'react';
import PropTypes from 'prop-types';

/**
 * @template P
 * @template S
 * @extends {React.Component<P, S>}
 */
class BusComponent extends React.Component {
    /** @param {P} props */
    constructor(props, context) {
        super(props, context);

        this._gluBusEvents = {};
    }

    bindGluBusEvents(events) {
        Object
            .keys(events)
            .forEach(eventName => {
                this._gluBusEvents[eventName] = events[eventName];
                this.bus.on(eventName, this._gluBusEvents[eventName], this);
            });
    }

    unbindGluBusEvents() {
        Object
            .keys(this._gluBusEvents)
            .forEach(eventName => {
                this.bus.off(eventName, this._gluBusEvents[eventName], this);
                delete this._gluBusEvents[eventName];
            });
    }

    emit(eventName, payload) {
        const newPayload = payload || {};

        const hadListeners = this.bus.emit(eventName, newPayload);
        if (!hadListeners) {
            // console.warn(`No one listened to event ${eventName}`);
        }
        return hadListeners;
    }

    get bus() {
        return this.context.bus;
    }
}

BusComponent.contextTypes = {
    viewCode: PropTypes.string,
    container: PropTypes.string,
    appGuid: PropTypes.string,
    bus: PropTypes.object,
    isPhoneDevice: PropTypes.bool,
    isEmbedded: PropTypes.bool,
    isMobileDevice: PropTypes.bool,
    isIframe: PropTypes.bool,
    isCondensedLayout: PropTypes.bool,
    applicationMode: PropTypes.string,
    onError: PropTypes.func,
    frame: PropTypes.string,
    variable: PropTypes.object,
    location: PropTypes.object,
    locationAnalysis: PropTypes.object,
    dataBrowser: PropTypes.object,
};

export default BusComponent;
