import React from 'react';

import BusComponent from '../BusComponent';
import TabbedBrowser from '../tabbedBrowser/TabbedBrowser';
import GeocodeOptions from './GeocodeOptions';
import LatLongOptions from './LatLongOptions';
import Panel from '../panel/Panel';

class LatLongAndGeocodeOptions extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = { selectedTabIndex: 0 };
    }

    static componentName() {
        return 'LAT_LONG_GEOCODE_OPTIONS';
    }

    onTabChange = selectedTabIndex => {
        this.setState({ selectedTabIndex });
    };

    render() {
        const availableTabs = [
            { name: 'Lat & Long' },
            { name: 'Geocode' },
        ];
        const tabs = [
            <LatLongOptions
                key="lat-long"
                mapInstanceId={this.props.mapInstanceId}
                selection={this.props.selection}
                userUpload={this.props.userUpload}
                error={this.props.error}
                onBack={this.props.onBack}
            />,
            <GeocodeOptions
                key="geocode"
                mapInstanceId={this.props.mapInstanceId}
                selection={this.props.selection}
                userUpload={this.props.userUpload}
                error={this.props.error}
                onBack={this.props.onBack}
            />,
        ];
        return (
            <div className="user-data-upload-editor user-data-upload-editor--latlong">
                <Panel
                    title="Choose columns to position your placemarks"
                    headerStyle="white"
                    onBackClick={this.props.onBack}
                >
                    <TabbedBrowser
                        className="user-data-upload-editor__tabs"
                        tabNames={availableTabs}
                        tabSwitchedCallback={this.onTabChange}
                        selectedTabIndex={this.state.selectedTabIndex}
                    >
                        {tabs}
                    </TabbedBrowser>
                </Panel>
            </div>
        );
    }
}

export default LatLongAndGeocodeOptions;
