//      
const fs = require('fs');

function addDragonflyPrelude(shaders     ) {
    const dragonflyPrelude = {
        fragmentSource: '',
        vertexSource: fs.readFileSync(`${__dirname}/../shaders/_dragonfly_prelude.vertex.glsl`, 'utf8')
    };
    shaders.prelude.fragmentSource = shaders.prelude.fragmentSource.concat(dragonflyPrelude.fragmentSource);
    shaders.prelude.vertexSource = shaders.prelude.vertexSource.concat(dragonflyPrelude.vertexSource);

}

function embedDragonflyPragmas(re     , shaders     ) {
    for (const programName in shaders) {
        if (programName !== 'fill' && programName !== 'bubble') continue;

        const program = shaders[programName];

        program.vertexSource = program.vertexSource.replace(re, (match        , operation        , precision        , type        , name        ) => {
            return `
#pragma dragonfly: ${operation} ${precision} ${type} ${name}
#else

${match}

#endif
`;
        });
        program.fragmentSource = program.fragmentSource.replace(re, (match        , operation        , precision        , type        , name        ) => {
            return `
#pragma dragonfly: ${operation} ${precision} ${type} ${name}
#else

${match}

#endif
`;
        });
    }
}

module.exports = function (re     , shaders     ) {
    addDragonflyPrelude(shaders);
    embedDragonflyPragmas(re, shaders);
};
