const NUMBER_FORMAT = {
    FORMAT_NONE: '',
    FORMAT_PERCENT: 'Percent',
    FORMAT_PERCENT_NO_DECIMAL: 'PercentNoDecimal',
    FORMAT_PERCENT_1_DECIMAL: 'Percent1Decimal',
    FORMAT_PERCENT_2_DECIMAL: 'Percent2Decimal',
    FORMAT_PERCENT_3_DECIMAL: 'Percent3Decimal',
    FORMAT_PERCENT_4_DECIMAL: 'Percent4Decimal',
    FORMAT_REAL_PERCENT: 'RealPercent',
    FORMAT_REAL_PERCENT_NO_DECIMAL: 'RealPercentNoDecimal',
    FORMAT_REAL_PERCENT_1_DECIMAL: 'RealPercent1Decimal',
    FORMAT_REAL_PERCENT_2_DECIMAL: 'RealPercent2Decimal',
    FORMAT_REAL_PERCENT_3_DECIMAL: 'RealPercent3Decimal',
    FORMAT_REAL_PERCENT_4_DECIMAL: 'RealPercent4Decimal',

    FORMAT_CURRENCY: 'Currency',
    FORMAT_CURRENCY0_DECIMAL: 'Currency0Decimal',
    FORMAT_CURRENCY1_DECIMAL: 'Currency1Decimal',
    FORMAT_CURRENCY2_DECIMAL: 'Currency2Decimal',

    FORMAT_NUMBER: 'Number',
    FORMAT_NUMBER_NO_DECIMAL: 'NumberNoDecimal',
    FORMAT_NUMBER_1_DECIMAL: 'Number1Decimal',
    FORMAT_NUMBER_2_DECIMAL: 'Number2Decimal',
    FORMAT_NUMBER_3_DECIMAL: 'Number3Decimal',
    FORMAT_NUMBER_4_DECIMAL: 'Number4Decimal',
    FORMAT_NUMBER_5_DECIMAL: 'Number5Decimal',

    FORMAT_NUMBER_NO_FORMAT: 'NumberNoFormat',
    FORMAT_NUMBER_NO_FORMAT_NO_DECIMAL: 'NumberNoFormatNoDecimal',
    FORMAT_NUMBER_NO_FORMAT_1_DECIMAL: 'NumberNoFormat1Decimal',
    FORMAT_NUMBER_NO_FORMAT_2_DECIMAL: 'NumberNoFormat2Decimal',
    FORMAT_NUMBER_NO_FORMAT_3_DECIMAL: 'NumberNoFormat3Decimal',
    FORMAT_NUMBER_NO_FORMAT_4_DECIMAL: 'NumberNoFormat4Decimal',
    FORMAT_NUMBER_NO_FORMAT_5_DECIMAL: 'NumberNoFormat5Decimal',
};

const numeral = require('numeral');

function formatNumber(num, numberFormat) {
    return numeral(num).format(numberFormat);
}

module.exports = function format(params) {
    const num = params.number;

    // check if value is finite number
    if (isNaN(parseFloat(num)) || !isFinite(parseFloat(num))) {
        return num.toString();
    }

    const numberFormat = params.numberFormat;
    const currencySymbol = params.currencySymbol ? params.currencySymbol : '$';
    const placeCurrencySymbolBeforeValue = params.placeCurrencySymbolBeforeValue ? params.placeCurrencySymbolBeforeValue : true;
    numeral.language('socex', {
        delimiters: {
            thousands: ',',
            decimal: '.',
        },
        abbreviations: {
            thousand: 'k',
            million: 'm',
            billion: 'b',
            trillion: 't',
        },
        ordinal: number => {
            const b = number % 10;
            // eslint-disable-next-line
            if (~~(number % 100 / 10) === 1) {
                return 'th';
            }
            switch (b) {
            case 1:
                return 'st';
            case 2:
                return 'nd';
            case 3:
                return 'rd';
            default:
                return 'th';
            }
        },
        currency: {
            symbol: currencySymbol,
        },
    });

    // switch between languages
    numeral.language('socex');
    switch (numberFormat) {
    case NUMBER_FORMAT.FORMAT_PERCENT:
        return `${formatNumber(num, '0.[00]')}%`;
    case NUMBER_FORMAT.FORMAT_PERCENT_NO_DECIMAL:
        return `${formatNumber(num, '0')}%`;
    case NUMBER_FORMAT.FORMAT_PERCENT_1_DECIMAL:
        return `${formatNumber(num, '0.0')}%`;
    case NUMBER_FORMAT.FORMAT_PERCENT_2_DECIMAL:
        return `${formatNumber(num, '0.00')}%`;
    case NUMBER_FORMAT.FORMAT_PERCENT_3_DECIMAL:
        return `${formatNumber(num, '0.000')}%`;
    case NUMBER_FORMAT.FORMAT_PERCENT_4_DECIMAL:
        return `${formatNumber(num, '0.0000')}%`;

    case NUMBER_FORMAT.FORMAT_REAL_PERCENT:
        return `${num * 100}%`;
    case NUMBER_FORMAT.FORMAT_REAL_PERCENT_NO_DECIMAL:
        return formatNumber(num, '0%');
    case NUMBER_FORMAT.FORMAT_REAL_PERCENT_1_DECIMAL:
        return formatNumber(num, '0.0%');
    case NUMBER_FORMAT.FORMAT_REAL_PERCENT_2_DECIMAL:
        return formatNumber(num, '0.00%');
    case NUMBER_FORMAT.FORMAT_REAL_PERCENT_3_DECIMAL:
        return formatNumber(num, '0.000%');
    case NUMBER_FORMAT.FORMAT_REAL_PERCENT_4_DECIMAL:
        return formatNumber(num, '0.0000%');


    case NUMBER_FORMAT.FORMAT_CURRENCY:
        return formatNumber(num, `${placeCurrencySymbolBeforeValue ? '$' : ''}0,0.[00000000]${!placeCurrencySymbolBeforeValue ? '$' : ''}`);
    case NUMBER_FORMAT.FORMAT_CURRENCY0_DECIMAL:
        return formatNumber(num, `${placeCurrencySymbolBeforeValue ? '$' : ''}0,0${!placeCurrencySymbolBeforeValue ? '$' : ''}`);
    case NUMBER_FORMAT.FORMAT_CURRENCY1_DECIMAL:
        return formatNumber(num, `${placeCurrencySymbolBeforeValue ? '$' : ''}0,0.0${!placeCurrencySymbolBeforeValue ? '$' : ''}`);
    case NUMBER_FORMAT.FORMAT_CURRENCY2_DECIMAL:
        return formatNumber(num, `${placeCurrencySymbolBeforeValue ? '$' : ''}0,0.00${!placeCurrencySymbolBeforeValue ? '$' : ''}`);

    case NUMBER_FORMAT.FORMAT_NUMBER:
        return formatNumber(num, '0,0.[00000000]');
    case NUMBER_FORMAT.FORMAT_NUMBER_NO_DECIMAL:
        return formatNumber(num, '0,0');
    case NUMBER_FORMAT.FORMAT_NUMBER_1_DECIMAL:
        return formatNumber(num, '0,0.0');
    case NUMBER_FORMAT.FORMAT_NUMBER_2_DECIMAL:
        return formatNumber(num, '0,0.00');
    case NUMBER_FORMAT.FORMAT_NUMBER_3_DECIMAL:
        return formatNumber(num, '0,0.000');
    case NUMBER_FORMAT.FORMAT_NUMBER_4_DECIMAL:
        return formatNumber(num, '0,0.0000');
    case NUMBER_FORMAT.FORMAT_NUMBER_5_DECIMAL:
        return formatNumber(num, '0,0.00000');

    case NUMBER_FORMAT.FORMAT_NUMBER_NO_FORMAT:
        return num.toString();
    case NUMBER_FORMAT.FORMAT_NUMBER_NO_FORMAT_NO_DECIMAL:
        return formatNumber(num, '0');
    case NUMBER_FORMAT.FORMAT_NUMBER_NO_FORMAT_1_DECIMAL:
        return formatNumber(num, '0.0');
    case NUMBER_FORMAT.FORMAT_NUMBER_NO_FORMAT_2_DECIMAL:
        return formatNumber(num, '0.00');
    case NUMBER_FORMAT.FORMAT_NUMBER_NO_FORMAT_3_DECIMAL:
        return formatNumber(num, '0.000');
    case NUMBER_FORMAT.FORMAT_NUMBER_NO_FORMAT_4_DECIMAL:
        return formatNumber(num, '0.0000');
    case NUMBER_FORMAT.FORMAT_NUMBER_NO_FORMAT_5_DECIMAL:
        return formatNumber(num, '0.00000');

    default:
        return num.toString();

    }
};
