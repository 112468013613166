// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';

import BusComponent from '../BusComponent';
import TextInput from '../form/TextInput';

const LOCATION_NAME_MAX_LENGTH = 60;

/**
 * @typedef Props
 * @property {import('../../objects/LocationAnalysisItem').default} location
 * @property {string} [mapInstanceId] mapInstanceId is passed only when we are saving the location for the first time
 * @property {boolean} [isEdit]
 * @property {import('react-intl').intlShape} intl
 *
 * @typedef State
 * @property {string} title
 *
 * @extends BusComponent<Props, State>
 */
class UserLocationPopup extends BusComponent {
    constructor(props, context) {
        super(props, context);

        /** @type {State} */
        this.state = {
            title: this.props.location.value,
        };
    }

    componentDidMount() {
        this.bindGluBusEvents({
            USER_LOCATION_SAVE_SUCCESS: this.onCloseModal,
            USER_LOCATION_UPDATE_SUCCESS: this.onCloseModal,
        });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    onCloseModal = () => {
        this.emit('CLOSE_MODAL');
    };

    getLocationType = type => {
        switch (type) {
        // SelfStorage specific types
        case 'ss-facility':
            return 'SELFSTORAGE_FACILITY';
        case 'ss-construction':
            return 'SELFSTORAGE_CONSTRUCTION';
        // Unkown type, default to custom point
        default:
            return 'CUSTOM_POINT';
        }
    }

    onSave = () => {
        const { location, isEdit, mapInstanceId } = this.props;
        const { title } = this.state;
        if (isEdit) {
            this.emit('USER_LOCATION_UPDATE_REQUEST', {
                title,
                locationId: location.itemOriginMetadata.id,
            });
        } else {
            let metadata;
            if (this.getLocationType(location.type) !== 'CUSTOM_POINT') {
                metadata = {
                    type: this.getLocationType(location.type),
                    metadata: {
                        id: location.id,
                    },
                };
            } else {
                metadata = {
                    type: this.getLocationType(location.type),
                };
            }
            this.emit('USER_LOCATION_SAVE_REQUEST', {
                title,
                point: location.point,
                mapInstanceId,
                metadata,
            });
        }
    };

    onTitleChanged = title => {
        this.setState({ title });
    };

    render() {
        const { title } = this.state;

        // Checks title for no content and whitespace only content
        const disabled = title.length === 0 || title.match(/^ *$/) !== null;
        const saveButtonClasses = classNames('btn-raised', {
            disabled,
        });

        return (
            <div className="user-location-modal">
                <TextInput
                    initialFocus
                    value={title}
                    onChange={this.onTitleChanged}
                    label={this.props.intl.formatMessage({ id: 'title' })}
                    type="text"
                    maxLength={LOCATION_NAME_MAX_LENGTH}
                />
                {!this.props.isEdit && (
                    <span className="user-location-modal__note">
                        {this.props.intl.formatMessage({
                            id: 'userLocation.note',
                        })}
                    </span>
                )}
                <div className="user-location-modal__actions">
                    <button className="btn-flat" onClick={this.onCloseModal}>
                        {this.props.intl.formatMessage({ id: 'cancel' })}
                    </button>
                    <button
                        className={saveButtonClasses}
                        disabled={disabled}
                        onClick={this.onSave}
                    >
                        {this.props.intl.formatMessage({ id: 'save' })}
                    </button>
                </div>
            </div>
        );
    }
}

UserLocationPopup.propTypes = {
    location: PropTypes.object.isRequired,
    mapInstanceId: PropTypes.string,
    isEdit: PropTypes.bool,
};

UserLocationPopup.defaultProps = {
    isEdit: false,
    mapInstanceId: undefined,
};

export default injectIntl(UserLocationPopup);
