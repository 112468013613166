import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import SimpleDropdown from '../dropdown/SimpleDropdown';

const BaseVariablePicker = ({ filter, onBaseVariableChanged, intl }) => {
    const items = filter.baseVariables.map((v, index) => ({
        text: `${intl.formatMessage({ id: 'of' })} ${v.label}`,
        uuid: v.uuid,
        id: index,
    }));
    const selectedItem = items.find(i => i.uuid === filter.field.baseFieldName);

    // If more than one value is present do show the drop down
    if (items.length > 1) {
        return (<SimpleDropdown
            items={items}
            className="filter__base-variable-picker"
            onItemClick={onBaseVariableChanged}
            selectedItem={selectedItem}
        />);
    }
    // only one value, no need for the drop down
    return <div className="filter__base-variable" title={selectedItem.text}>{selectedItem.text}</div>;
};

BaseVariablePicker.propTypes = {
    filter: PropTypes.object.isRequired,
    onBaseVariableChanged: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(BaseVariablePicker);
