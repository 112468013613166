import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { hasParentNode } from '../../helpers/Util';
import Tooltip from '../Tooltip';

class ButtonMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.onMouseEvent);
    }

    onMouseEvent = e => {
        if (hasParentNode(e.target, this.trigger)) {
            return;
        }
        this.onTriggerClick();
    };

    onTriggerClick = () => {
        this.setState(
            prevState => ({ open: !prevState.open }),
            () => {
                if (this.state.open) {
                    window.addEventListener('click', this.onMouseEvent);
                } else {
                    window.removeEventListener('click', this.onMouseEvent);
                }
            },
        );
    };

    render() {
        const { triggerClassName, label, disabled, tooltip, icon } = this.props;

        const buttonMenuTriggerClasses = classNames(
            'btn-flat',
            'button-menu__trigger',
            triggerClassName,
            {
                'btn-raised--disabled': disabled,
            },
        );
        return (
            <div className="button-menu">
                <Tooltip
                    placement="bottom"
                    mouseEnterDelay={0.5}
                    mouseLeaveDelay={0}
                    overlay={tooltip}
                >
                    <button
                        ref={c => {
                            this.trigger = c;
                        }}
                        className={buttonMenuTriggerClasses}
                        onClick={this.onTriggerClick}
                    >
                        {icon}
                        <div className="margin-left-8">{label}</div>
                    </button>
                </Tooltip>
                {this.state.open && (
                    <div
                        className="button-menu__menu"
                        ref={c => {
                            this.menu = c;
                        }}
                    >
                        {this.props.children}
                    </div>
                )}
            </div>
        );
    }
}

ButtonMenu.propTypes = {
    children: PropTypes.element.isRequired,
    icon: PropTypes.element,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    tooltip: PropTypes.string.isRequired,
    triggerClassName: PropTypes.string,
};

ButtonMenu.defaultProps = {
    icon: undefined,
    disabled: false,
    triggerClassName: undefined,
};

export default ButtonMenu;
