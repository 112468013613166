/* eslint jsx-a11y/aria-props: 0 */
import React from 'react';
import { injectIntl } from 'react-intl';
import BusComponent from './BusComponent';
import Key from '../enums/Key';
import AppConfig from '../appConfig';
import classNames from 'classnames';
import { focusFirstChild, hasParentNode } from '../helpers/Util';

class ModalHandler extends BusComponent {
    constructor(props, context) {
        super(props, context);

        this.state = {
            title: undefined,
            modalContent: undefined,
            modalClass: undefined,
            modalHeaderClasses: undefined,
            canCloseModal: true,
            isVisible: false,
            canClickAway: true,
            customFocusElement: false,
        };

        this.focusBackElement = undefined;

        this.boundOnClose = this.onCloseCallback.bind(this);
        this.boundOnClickAway = this.onClickAway.bind(this);
        this.boundOnLockFocusInsideModal = this.onLockFocusInsideModal.bind(
            this,
        );

        this.bindGluBusEvents({
            OPEN_MODAL: this.onOpenModal,
            CLOSE_MODAL: this.onCloseModal,
            GET_MODAL_STATE: this.onModalStateRequest,
            UPDATE_MODAL_STATE: this.onUpdateModalStateRequest,
            SET_ON_MODAL_CLOSE_FOCUS_ELEMENT: this.onSetOnModalCloseFocusElement,
        });
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.boundOnClickAway);
        document.removeEventListener(
            'focus',
            this.boundOnLockFocusInsideModal,
            true,
        );
        window.removeEventListener('keydown', this.boundOnClose);
        this.unbindGluBusEvents();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.modal &&
            this.state.modalHeaderClasses !== prevState.modalHeaderClasses
        ) {
            const closeTriggers = this.modal.querySelectorAll(
                '.modal-header__modal-close',
            );
            Array.from(closeTriggers).forEach(element => {
                element.addEventListener('click', this.boundOnClose);
            });
        }

        if (this.modal && this.state.isVisible) {
            this.modal.classList.remove('modal--off');

            if (!this.state.customFocusElement) {
                focusFirstChild(this.modal);
            }

            document.addEventListener(
                'focus',
                this.boundOnLockFocusInsideModal,
                true,
            );
        } else if (this.modal) {
            this.modal.classList.add('modal--off');
            document.removeEventListener(
                'focus',
                this.boundOnLockFocusInsideModal,
                true,
            );
        }
    }

    /** @param {import('../').ModalParams} modal */
    onOpenModal(modal) {
        const { intl } = this.props;
        const formattedTitle = modal.title
            ? intl.formatMessage({ id: modal.title })
            : null;
        const nextState = {
            title: modal.titleOverride || formattedTitle,
            modalContent: modal.modalContent,
            modalClasses: modal.modalClasses,
            modalBoxClass: modal.modalBoxClass,
            modalHeaderClasses: modal.modalHeaderClasses,
            isVisible: true,
        };
        if (modal.modalContent.message) {
            nextState.modalContent.message = intl.formatMessage({
                id: modal.modalContent.message,
            });
        }
        if (modal.modalContent.text) {
            nextState.modalContent.text = intl.formatMessage({
                id: modal.modalContent.text,
            });
        }
        if (modal.canCloseModal !== undefined) {
            nextState.canCloseModal = modal.canCloseModal;
        }

        if (modal.canClickAway !== undefined) {
            nextState.canClickAway = modal.canClickAway;
        }

        if (modal.customFocusElement !== undefined) {
            nextState.customFocusElement = modal.customFocusElement;
        }

        document.addEventListener('mousedown', this.boundOnClickAway);

        if (this.modal) {
            const closeTriggers = this.modal.querySelectorAll(
                '.modal-header__modal-close',
            );
            Array.from(closeTriggers).forEach(element => {
                element.addEventListener('click', this.boundOnClose);
            });
        }

        window.addEventListener('keydown', this.boundOnClose);
        AppConfig.sentryRecordEvent(`Popup opened [${modal.name}]`);

        this.setState(nextState);
    }

    onCloseModal() {
        document.removeEventListener('mousedown', this.boundOnClickAway);
        window.removeEventListener('keydown', this.boundOnClose);
        this.emit('MODAL_CLOSED', { id: this.state.title });
        this.setState({
            title: undefined,
            modalContent: undefined,
            modalBoxClass: undefined,
            modalHeaderClasses: undefined,
            canCloseModal: true,
            canClickAway: true,
            isVisible: false,
            customFocusElement: false,
        }, () => {
            if (this.focusBackElement) {
                this.focusBackElement.focus();
            }
        });
    }

    onLockFocusInsideModal() {
        if (
            this._ignoreFocusChanges ||
            hasParentNode(event.target, this.modal)
        ) {
            return;
        }

        this._ignoreFocusChanges = true;
        focusFirstChild(this.modal);
        this._ignoreFocusChanges = false;
    }

    onSetOnModalCloseFocusElement(element) {
        this.focusBackElement = element.trigger ? element.trigger : element;
    }

    onUpdateModalStateRequest(nextState) {
        this.setState({
            ...nextState,
        });
    }

    onModalStateRequest() {
        this.emit('MODAL_STATE', { visible: this.state.isVisible });
    }

    onClickAway(event) {
        if (
            this.state.canClickAway &&
            event.target.classList.contains('modal') &&
            this.state.canCloseModal
        ) {
            this.onCloseModal();
        }
    }

    onCloseCallback(event) {
        if (this.state.canCloseModal) {
            if (event.type === 'keydown' && event.which !== Key.ESC) {
                return;
            }
            this.onCloseModal();
        }
    }

    render() {
        const modalClasses = classNames(
            'modal modal--off',
            this.state.modalClasses,
        );
        const modalBoxClasses = classNames(
            'modal-box',
            this.state.modalBoxClass,
        );
        const modalHeaderClasses = classNames(
            'modal-header',
            this.state.modalHeaderClasses,
        );
        const modalCloseButtonClasses = classNames(
            'btn',
            'btn-icon',
            'modal-header__close',
            'modal-header__modal-close',
            {
                hidden: !this.state.isVisible,
            },
        );

        const headerContent = (
            <div className={modalHeaderClasses}>
                <h1>{this.state.title}</h1>
                <button
                    className={modalCloseButtonClasses}
                    aria-hidden={!this.state.isVisible}
                    tabIndex={this.state.isVisible ? 0 : -1}
                >
                    <i className="material-icons modal-header__close-icon">
                        close
                    </i>
                    <span className="modal-header__close-text">esc</span>
                </button>
            </div>
        );
        return (
            <div
                className={modalClasses}
                aria-modal="true"
                role="dialog"
                ref={modal => {
                    this.modal = modal;
                }}
            >
                <div className={modalBoxClasses}>
                    {headerContent}
                    <div className="modal-content">
                        {this.state.modalContent}
                    </div>
                </div>
            </div>
        );
    }
}

export default injectIntl(ModalHandler);
