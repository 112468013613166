import React from 'react';
import BusComponent from '../../BusComponent';
import ColorPickerCompact from '../../colorPickerCompact/ColorPickerCompact';
import DropdownMenu from '../../dropdown/DropdownMenu';

class GradientHandle extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {};
    }

    componentWillReceiveProps(nextProps) {
        this.setState(nextProps);
    }

    onApplyToColor = to => {
        this.props.applyColor(to, 'to');
    }

    onApplyFromColor = from => {
        this.props.applyColor(from, 'from');
    }

    onApplyColor = color => {
        this.props.applyColor(color);
    }

    onHandleClick = visibility => {
        if (visibility) {
            this.handleRef.style.zIndex = 100;
        } else {
            this.handleRef.style.zIndex = 10;
        }
    }

    render() {
        const isTwoColor = this.props.color === undefined;
        let container;
        const onRemoveHandleClick = () => {
            this.props.removeHandle(this.props.id);
        };
        const removeHandle = this.props.color === undefined ? (
            <div className="gradient-container__remove-handle" onClick={onRemoveHandleClick}>
                <i className="material-icons" >remove_circle</i>
            </div>) : undefined;
        if (isTwoColor) {
            const leftPicker = (<div
                style={{ backgroundColor: this.props.from }}
                className="color-box"
            />);
            const leftDropdown = (
                <DropdownMenu
                    anchor={leftPicker}
                    name="color-gradient-color-picker"
                    onVisibleChange={this.onHandleClick}
                    className="dropdown__menu--color-picker" position="bottomLeft"
                >
                    <ColorPickerCompact
                        onChange={this.onApplyFromColor}
                        color={this.props.from}
                    />
                </DropdownMenu>);
            const rightPicker = (<div
                style={{ backgroundColor: this.props.to }}
                className="color-box" onMouseDown={this.props.onMouseDown}
            />);
            const rightDropdown = (
                <DropdownMenu
                    anchor={rightPicker}
                    name="color-gradient-color-picker"
                    onVisibleChange={this.onHandleClick}
                    className="dropdown__menu--color-picker" position="bottomLeft"
                >
                    <ColorPickerCompact
                        onChange={this.onApplyToColor}
                        color={this.props.to}
                    />
                </DropdownMenu>);
            container = (
                <div className="gradient-container__color-boxes noselect">
                    {leftDropdown}
                    {rightDropdown}
                    {removeHandle}
                </div>
            );
        } else {
            const picker = (<div
                style={{ backgroundColor: this.props.color }}
                className="color-box"
            />);
            const dropdown = (
                <DropdownMenu
                    anchor={picker}
                    name="color-gradient-color-picker"
                    onVisibleChange={this.onHandleClick}
                    className="dropdown__menu--color-picker" position="bottomLeft"
                >
                    <ColorPickerCompact
                        onChange={this.onApplyColor}
                        color={this.props.color}
                    />
                </DropdownMenu>);
            container = (
                <div className="gradient-container__color-boxes noselect">
                    {dropdown}
                </div>
            );
        }

        return (
            <div ref={r => { if (this.props.elementRef) this.props.elementRef(r); this.handleRef = r; }} onMouseDown={this.props.onMouseDown} className="gradient-container__handle" data-id={this.props.id}>
                <div className="gradient-container__triangle" />
                {container}
            </div>);
    }
}

export default GradientHandle;
