module.exports={
  "importGeographies": "Import geographies",
  "ignoredGeographies": "Ignored geographies",
  "aLotOfGeographiesSelected": "That's a lot of geographies selected!",
  "storytellingWithSocialExplorer": "Storytelling with Social Explorer",
  "changeProjectTitleAndDescription": "Change project title and description",
  "saveAs": "Save as",
  "share": "Share",
  "deleteUserLayer": "Delete user layer?",
  "shareWithOthers": "Share with others",
  "newMapPosition": "New map position",
  "editMapPosition": "Edit map position",
  "feedback": "Feedback",
  "editCutpoints": "Edit cutpoints",
  "waitingForUserToLogIn": "Waiting for user to log in...",
  "loggingOut": "Logging out...",
  "userLocationSave": "Save to my places",
  "userLocationEdit": "Edit place",
  "addLocationAnalysisValue": "Add custom value",
  "enterValue": "Enter value",
  "location": "Location",
  "createAnAnalysis": "Create an analysis",
  "analysisSingleMapOnlyWarning": "Analysis is only available in the Single map view.",
  "singleMapSwitchWarning": "If you continue you will be switched to Single map view and you will lose the second map. This action can not be undone.",
  "switchToCompareView": "Switch to compare view?",
  "activeAnalysisWarning": "You have an active analysis on the map.",
  "analysisRemovalWarning": "Analysis is only available in the Single map view. If you continue to side by side view the analysis will be removed from the map.",
  "customValueLimitTitle": "You've reached the geography limit",
  "customValueLimitDescription1": "A report can’t have more than {geographiesLimit} selected geographies.",
  "customValueLimitDescription2": "High-density areas, like Manhattan, usually have a larger number of geographies. To avoid this problem use smaller distances in densely populated areas.",
  "customValueLimitDescription3": "Examples of a number of block groups based on radius:",
  "customValueBoundaries": "*Please enter value between {minValue} and {maxValue}",
  "autoAdjustFailed": "Auto-adjust failed",
  "autoAdjustFailedDescription": "Unfortunately, auto-adjust can’t be done for this view. The technical constraint for which autocorrection can be calculated is {limit} geographies.",
  "autoAdjustFailedOptions": "To be able to use auto-adjust, you can:",
  "autoAdjustFailedOption1": "use a lower zoom level  (zoom in), or",
  "autoAdjustFailedOption2": "use higher geography level ",
  "submitFacility": {
    "category": {
      "label": "Category*",
      "placeholder": "Select category"
    },
    "name": {
      "label": "Name*",
      "placeholder": "Facility name"
    },
    "location": {
      "label": "Location*",
      "placeholder": "Enter address"
    },
    "telephone": {
      "label": "Telephone",
      "placeholder": "Telephone number "
    },
    "website": {
      "label": "Website",
      "placeholder": "Web address"
    },
    "status": {
      "label": "Status",
      "placeholder": "Mark as closed , non-existed or duplicate "
    },
    "details": {
      "label": "Details",
      "placeholder": "Add additional info"
    },
    "reportMissing": "Report a missing facility",
    "suggestEdit": "Suggest Edit",
    "suggestEdits": "Suggest edits",
    "markAsClosed": "Mark as closed, non-existed or duplicate",
    "thankYou": "Thank you for improving Self storage!",
    "youWillGetEmail": "You will get an email when your suggestion is reviewed."
  }
}
