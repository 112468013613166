// @ts-check
import React from 'react';
import BusComponent from '../BusComponent';
import AppConfig from '../../appConfig';
import VisualReportSave from './VisualReportSave';
import CenterMapLayerButton from './CenterMapLayerButton';
import { POI_TYPES } from '../../enums/PoiTypes';

/**
 * @typedef Props
 * @property {string} mapInstanceId
 *
 * @typedef State
 * @property {import('../../types').VisualReport} visualReport
 * @property {boolean} showReport
 * @property {boolean} compare
 *
 * @extends {BusComponent<Props, State>}
 */
class ReportIframe extends BusComponent {
    constructor(props, context) {
        super(props, context);
        /** @type {State} */
        this.state = {
            visualReport: undefined,
            showReport: false,
            compare: false,
        };

        /** @type {HTMLIFrameElement | null} */
        this.iframe = null;
    }

    componentDidMount() {
        this.bindGluBusEvents({
            SHOW_INSIGHTS: this.onInsights,
            ENTER_UPDATE_LOCATION_ANALYSIS_MODE: this.onClose,
            EXIT_LOCATION_ANALYSIS_MODE: this.onClose,
        });
        window.addEventListener('message', this.handlePostMessage);
    }

    componentWillUnmount() {
        this.bus.emit('CLOSE_REPORT_IFRAME');
        this.unbindGluBusEvents();
        window.removeEventListener('message', this.handlePostMessage);
    }

    handlePostMessage = event => {
        if (event.origin !== AppConfig.constants.frontends.insightsBaseUrl) return;
        if (event.data && event.data.url) {
            window.open(event.data.url, '_blank').focus();
        }
    };

    getUrl = (frame = false) => {
        const { point, profile, selection, title, additionalParams, report, pointsOfInterest } =
            this.state.visualReport;

        const profileParam = profile;
        const selectionParam = selection.join(',');

        let configUrl = AppConfig.constants.frontends.insightsReportUrl;
        if (frame) {
            // this is a convention to select view that is suitable for
            // iframe. In visual-report application, App.tsx, it is defined
            // to use /frame suffix to show view more suitable for iframe
            configUrl = configUrl.replace('?', '/frame?');
        }

        // Apply report that will be opened by default
        // Use report if set in visualReport object, otherwise read it from the config
        const urlReport = report || AppConfig.constants.defaultInsightsReport;
        configUrl = configUrl.replace('{report}', urlReport);

        // Apply mandatory search params
        configUrl = configUrl
            .replace('{pois}', `${point.lng};${point.lat}`)
            .replace('{profile}', profileParam)
            .replace('{selection}', selectionParam)
            .replace('{title}', encodeURIComponent(title));

        // Apply additional search params
        if (additionalParams) {
            Object.keys(additionalParams).forEach(param => {
                configUrl = configUrl.concat(`&${param}=${additionalParams[param]}`);
            });
        }

        return configUrl;
    };

    /** @type {React.Ref<HTMLIFrameElement>} */
    setIframeRef = iframe => {
        this.iframe = iframe;
    };

    /**
     * @param {object} param0
     * @param {boolean} param0.showInsights
     */
    onClose = ({ showInsights = false }) => {
        if (showInsights) return;
        // TODO: same as comment in the method below (@onInsights)
        this.bus.emit('CLOSE_REPORT_IFRAME');
        this.setState({ showReport: false, visualReport: undefined });
    };

    /**
     * @param {import('../../types').VisualReport} visualReport
     */
    onInsights = visualReport => {
        this.setState({
            visualReport,
            showReport: true,
            compare: false,
        });
        // TODO: here is emitted an event in order for two components to speak.
        // We try hard not to do so, because it increases the complexity of the
        // application. Instead, communicate with controllers and let them
        // notify components. In this particular case SHOW_INSIGHTS
        // event should be enough for everyone
        this.bus.emit('SHOW_REPORT_IFRAME');
    };

    // TODO: this will always open pricing, instead of the one currently
    // active in the iframe. That needs to be fixed
    onOpenInNewTab = () => {
        this.iframe.contentWindow.postMessage(
            { action: 'openTab' },
            AppConfig.constants.frontends.insightsBaseUrl,
        );
    };

    onEnter = () => {
        this.bus.emit('IFRAME_MOUSE_ENTER');
    };

    render() {
        if (!this.state.showReport) {
            return null;
        }
        const url = this.getUrl(true);

        if (this.state.compare) {
            return (
                <div className="report-iframe report-iframe--fs">
                    <div className="flex-it center flex-end">
                        <button onClick={this.onClose} className="material-icons btn-flat-icon">
                            close
                        </button>
                    </div>
                    <div className="report-iframe__iframe">
                        <iframe src={url} title="Visual report" ref={this.setIframeRef} />
                    </div>
                </div>
            );
        }
        const shouldShowSaveButton = !POI_TYPES.some(
            poiType =>
                this.state.visualReport.additionalParams &&
                poiType === this.state.visualReport.additionalParams['point-type'],
        );
        return (
            <div className="report-iframe" onMouseEnter={this.onEnter}>
                <div className="report-iframe__iframe-header">
                    <div className="report-iframe__iframe-header--actions">
                        <CenterMapLayerButton
                            mapInstanceId={this.props.mapInstanceId}
                            zoomPoint={this.state.visualReport.point}
                        />
                        <div className="divider--btn-separator" />
                        {shouldShowSaveButton && ( // the save button should not be visible if we do analysis of a custom point
                            <VisualReportSave visualReport={this.state.visualReport} />
                        )}
                        <button
                            onClick={this.onOpenInNewTab}
                            className="material-icons btn-flat-icon"
                            title="Open analysis in new window"
                        >
                            open_in_new
                        </button>
                        <div className="divider divider--vertical-small divider--btn-separator" />
                        <button
                            onClick={this.onClose}
                            className="material-icons btn-flat-icon"
                            title="Close analysis panel"
                        >
                            close
                        </button>
                    </div>
                </div>
                <div className="report-iframe__iframe">
                    <iframe src={url} title="Visual report" ref={this.setIframeRef} />
                </div>
            </div>
        );
    }
}

export default ReportIframe;
