import React from 'react';
import BusComponent from '../BusComponent';
import classNames from 'classnames';

import AnnotationEdit from './edit/AnnotationEdit';
import AnnotationCardHeader from './AnnotationCardHeader';
import AnnotationCardFooter from './AnnotationCardFooter';

class AnnotationCard extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            title: props.annotation.title,
        };
    }

    onToggleExpansion = () => {
        const { onToggleExpansion, annotation } = this.props;
        onToggleExpansion(annotation.id);
    }

    componentDidUpdate() {
        // Scroll to expanded AnnotationCard to display editing options
        if (this.props.expanded && this.expandedElementAnchor) {
            this.expandedElementAnchor.scrollIntoView(false);
        }
    }

    onTitleChange = title => {
        const { annotation } = this.props;
        const { id, type } = annotation;

        this.setState({ title });

        // change title with timeout
        setTimeout(() => {
            this.emit('CHANGE_ANNOTATION', {
                id,
                type,
                propsToUpdate: {
                    title: title || 'Untitled',
                },
            });
        }, 200);
    }

    onDeleteAnnotation = () => {
        this.emit('DELETE_ANNOTATION_REQUEST', this.props.annotation.id);
    }

    onChangeAnnotationVisibilityInLegend = includedInLegend => {
        const { onChangeAnnotationVisibilityInLegend, annotation } = this.props;
        onChangeAnnotationVisibilityInLegend({ includedInLegend, annotation });
    }

    render() {
        const { expanded, editMode, annotation } = this.props;
        const { title } = this.state;

        const classes = classNames('annotation-card',
            { 'annotation-card--expanded': expanded && !editMode }
        );

        return (
            <div
                ref={element => { this.expandedElementAnchor = element; }}
                className={classes}
            >
                <AnnotationCardHeader
                    annotation={annotation}
                    expanded={expanded}
                    title={title}
                    onTitleChange={this.onTitleChange}
                    onToggleExpansion={this.onToggleExpansion}
                />
                {expanded && !editMode &&
                    <div>
                        <AnnotationEdit annotation={annotation} />
                        <AnnotationCardFooter
                            visibleInLegend={annotation.includedInLegend}
                            onDeleteAnnotation={this.onDeleteAnnotation}
                            onChangeVisibility={this.onChangeAnnotationVisibilityInLegend}
                        />
                    </div>
                }
            </div>
        );
    }
}

export default AnnotationCard;
