import Map from '../objects/Map';
import DataSource from '../objects/DataSource';
import Dataset from '../objects/Dataset';

class MapParser {
    constructor(mapJSON) {
        this._mapJSON = mapJSON;
    }

    /**
     * @param {object} dataSourceJSON
     * @param {string} surveyName
     */
    static parseDataSource(dataSourceJSON, surveyName) {
        const dataSource = new DataSource();
        dataSource.id = dataSourceJSON.id;
        dataSource.layerId = dataSourceJSON['geo-layer-id'];
        dataSource.summaryLevel = dataSourceJSON['summary-level'];
        dataSource.summaryLevel.visible = true;
        dataSource.summaryLevel.defaultMinZoom =
            dataSource.summaryLevel.minzoom;
        dataSource.summaryLevel.defaultMaxZoom =
            dataSource.summaryLevel.maxzoom;
        dataSource.datasets = {};
        dataSourceJSON.datasets.forEach(datasetJSON => {
            const dataset = new Dataset();
            dataset.id = datasetJSON['dataset-id'];
            dataset.datasetAbbreviation = datasetJSON['dataset-abbreviation'];
            dataset.primaryKeyField = datasetJSON['primary-key-column'];
            dataset.geoNameField = datasetJSON['geo-name-column'];
            dataset.geoQNameField = datasetJSON['geo-qualified-name-column'];
            dataset.geoTypeName = dataSource.summaryLevel.id;
            dataset.surveyName = datasetJSON['survey-name'] || surveyName;
            dataSource.datasets[dataset.id] = dataset;
        });
        return dataSource;
    }

    _parseDataSource(dataSourceJSON) {
        return MapParser.parseDataSource(dataSourceJSON, this.surveyName);
    }

    get surveyName() {
        return this._mapJSON.metadata.socialexplorer['survey-name'];
    }

    get map() {
        const map = new Map();
        map.id = this._mapJSON.metadata.socialexplorer.id;
        map.isDynamicLabelsContrastEnabled =
            this._mapJSON.metadata.dynamicLabelsContrast &&
            this._mapJSON.metadata.dynamicLabelsContrast.enabled === true;
        if (
            this._mapJSON.metadata.dynamicLabelsContrast &&
            this._mapJSON.metadata.dynamicLabelsContrast.styles
        ) {
            map.dynamicLabelsContrastStyles =
                this._mapJSON.metadata.dynamicLabelsContrast.styles;
        }
        map.surveyName = this._mapJSON.metadata.socialexplorer['survey-name'];
        map.groupMetadataGuid =
            this._mapJSON.metadata.socialexplorer['group-metadata-id'];
        map.minZoom = this._mapJSON.metadata.socialexplorer.minzoom;
        map.maxZoom = this._mapJSON.metadata.socialexplorer.maxzoom;
        map.reportMinSummaryLevel =
            this._mapJSON.metadata.socialexplorer['report-min-summary-level'];
        map.version = this._mapJSON.version;
        map.name = this._mapJSON.name;
        map.sprite = this._mapJSON.sprite;
        map.glyphs = this._mapJSON.glyphs;
        map.colorPalettesURL = this._mapJSON['color-palettes'];
        map.dataSources = {};

        this._mapJSON.metadata.socialexplorer['data-source-layers'].forEach(
            dataSourceJSON => {
                const dataSource = this._parseDataSource(dataSourceJSON);
                dataSource.summaryLevel.isAutomatic =
                    dataSource.summaryLevel.minzoom < map.maxZoom;
                map.dataSources[dataSource.id] = dataSource;
            },
        );

        map.dragonflySources = this._mapJSON.sources;
        map.layers = this._mapJSON.layers.map(layer => {
            layer.metadata = layer.metadata || {};
            layer.metadata.defaultMinZoom = layer.minzoom || map.minZoom;
            layer.metadata.defaultMaxZoom = layer.maxzoom || map.maxZoom + 1;
            if (!layer.ref) {
                layer.layout = layer.layout || {};
                layer.layout.visibility = layer.layout.visibility || 'visible';
                layer.minzoom = layer.minzoom || map.minZoom;
                layer.maxzoom = layer.maxzoom || map.maxZoom + 1;
            }
            if (!layer.metadata.socialexplorer) {
                layer.metadata.socialexplorer = {};
            }
            layer.paint = layer.paint || {};
            return layer;
        });
        // move satellite before data placeholder (this is temporary until Nino does the change)
        const dataPlaceholderIdx = map.layers.indexOf(map.dataPlaceholder);
        const satelliteIdx = map.layers.indexOf(map.satelliteLayer);
        if (dataPlaceholderIdx < satelliteIdx) {
            // all layers between data and satellite are to be hidden in satellite view
            for (let i = dataPlaceholderIdx + 1; i < satelliteIdx; i += 1) {
                const dl = map.layers[i];
                dl.metadata.socialexplorer['hidden-when-satellite'] = true;
            }
            // move satellite layer before data layer
            map.layers.splice(dataPlaceholderIdx, 0, map.satelliteLayer);
            map.layers.splice(satelliteIdx + 1, 1);
        }
        return map;
    }
}

export default MapParser;
