import BaseHandler from './BaseHandler';

import { isPointBasedLayer } from '../../helpers/LibraryLayersHelper';

class LibraryLayersHandler extends BaseHandler {
    constructor(mapViewer) {
        super(mapViewer);
        this._data = mapViewer.dragonflyMapData.libraryLayersData;
        this._isMapLoaded = this.map.loaded();

        this.setGluBusEvents({
            MAP_APPLY_LIBRARY_DATA_LAYERS_UPDATE: this.applyLibraryDataLayersUpdate,
            MAP_LOAD: this.onMapLoad,
        });

        this.bus.once('LIBRARY_DATA', this.onLibraryDataRetrieved);
        this.emit('LIBRARY_DATA_REQUEST', { source: this });
    }

    onLibraryDataRetrieved = (libraryData, target) => {
        if (target === this) {
            this._data.setLibraryLayersData(libraryData);
        }
    };

    onMapLoad(e) {
        if (e.source.id !== this.mapInstanceId) return;
        this._isMapLoaded = true;
    }

    applyLibraryDataLayersUpdate(e) {
        if (e.mapInstanceId !== this.mapInstanceId || !this._isMapLoaded) return;

        const oldLibraryDataLayers = this._data.layers;
        const oldLibraryDataSource = this._data.source;
        this._data.update();
        const newLibraryDataLayers = this._data.layers;
        const newLibraryDataSource = this._data.source;

        if (!oldLibraryDataSource.equals(newLibraryDataSource)) {
            this.map.removeSource(oldLibraryDataSource.id);
            this.map.addSource(newLibraryDataSource.id, newLibraryDataSource.toJSON());
        }

        oldLibraryDataLayers.forEach(dl => {
            if (this.map.getLayer(dl.id)) {
                this.map.removeLayer(dl.id);
            }
        });

        const layers = this.map.getStyle().layers;

        const mapSourcesObj = this.map.getStyle().sources;
        const geoJsonSourceIds = Object.keys(mapSourcesObj)
            .filter(sourceId => mapSourcesObj[sourceId].type === 'geojson');

        const firstPointBasedLayer = layers.find(l => isPointBasedLayer(l));
        const firstGeoJsonBasedLayer = layers.find(l => geoJsonSourceIds.includes(l.source));

        // Basic layer rendering order rule (bottom -> top):
        // background/satellite -> fill/data polygons -> lines/borders/outlines -> points (labels, icons, circles, bubbles) -> geoJSON (any type)
        // However, since data is always related to the fill/polygons and point based layers, we are resolving this case.
        // map.addLayer() expects second parameter to be "before this layer" placeholder. Calling with no second parameter
        // triggers simple pushing the layer to the top of the rendering stack
        newLibraryDataLayers.forEach(dl => {
            if (firstPointBasedLayer && firstGeoJsonBasedLayer) {
                // both geojson and point layers present, keep them in them i
                if (geoJsonSourceIds.includes(dl.source)) {
                    // geojson layer goes to the top
                    this.map.addLayer(dl);
                } else if (isPointBasedLayer(dl)) {
                    // point layers go below the first geojson layer
                    this.map.addLayer(dl, firstGeoJsonBasedLayer.id);
                } else {
                    // other layers go below the first point layer
                    this.map.addLayer(dl, firstPointBasedLayer.id);
                }
            } else if (firstGeoJsonBasedLayer && !geoJsonSourceIds.includes(dl.source)) {
                // geojson layers present, keep the on the top (in-order), other layers go below (in-order)
                this.map.addLayer(dl, firstGeoJsonBasedLayer.id);
            } else if (firstPointBasedLayer && !isPointBasedLayer(dl)) {
                // point layers present, keep them on the top (in-order), other layers go below (in-order)
                this.map.addLayer(dl, firstPointBasedLayer.id);
            } else {
                // no point or geojson layers, just add the layer as usual
                this.map.addLayer(dl);
            }
        });

        this.emit('MAP_LIBRARY_LAYERS_UPDATED');
    }
}

export default LibraryLayersHandler;
