import Api from '../apis/Api';
import GLU from '../glu2.js/src/index';
import Metadata from '../objects/Metadata';
import LibraryData from '../objects/LibraryData';
import MetadataXMLParser from '../helpers/MetadataXMLParser';
import AppConfig from '../appConfig';
import {
    formatSystemReport,
    formatTable,
} from '../helpers/AspNetWebServiceResponseJSONParser';

class MetadataDataSource extends GLU.DataSource {
    static get name() {
        return 'MetadataDataSource';
    }

    static getInstance() {
        return new MetadataDataSource();
    }

    constructor() {
        super(() => false);
        this._currentMetadata = new Metadata();
        /** @type {import('../').Groups[]} */
        this._groupsMetadata = undefined;
        this._featuredCategoriesData = undefined;
        this._libraryData = new LibraryData();
        this._metadataBrowsingInfo = {};
        /** @type {import('../').SurveyGroup[] | undefined} */
        this._surveyGroups = undefined;
        /** @type {import('../').CpiByYear} */
        this._cpiValuesByYear = {};
        /** @type {import('../').CustomLocationAnalysisValue} */
        this._customLocationAnalysisValues = [];
    }

    get currentMetadata() {
        return this._currentMetadata;
    }

    get groupsMetadata() {
        return this._groupsMetadata;
    }

    get libraryData() {
        return this._libraryData;
    }

    get featuredCategoriesData() {
        return this._featuredCategoriesData;
    }

    get surveyGroups() {
        return this._surveyGroups;
    }

    /** @type {import('../').CpiByYear} */
    get cpiValuesByYear() {
        return this._cpiValuesByYear;
    }

    get customLocationAnalysisValues() {
        return this._customLocationAnalysisValues;
    }

    /**
     * @param {number} value
     * @param {import('../').LocationAnalysisTypeUnit} analysisTypeUnit
     */
    addCustomLocationAnalysisValue(value, analysisTypeUnit) {
        this._customLocationAnalysisValues.push({
            value,
            analysisTypeUnit,
        });
    }

    resetCustomLocationAnalysisValues() {
        this._customLocationAnalysisValues = [];
    }

    getMetadataBrowsingInfo(viewerId) {
        return this._metadataBrowsingInfo[viewerId] || {};
    }

    updateMetadataBrowsingInfo(viewerId, info) {
        this._metadataBrowsingInfo[viewerId] =
            this._metadataBrowsingInfo[viewerId] || {};
        Object.keys(info).forEach(
            key => (this._metadataBrowsingInfo[viewerId][key] = info[key]),
        );
    }

    getDataDictionaryInfo(metadataSelection) {
        const dataDictionaryContent = [];
        dataDictionaryContent.push({
            title: metadataSelection.table.title,
            info: metadataSelection.table.tableMapInfo,
        });
        const variables = metadataSelection.variables.map(metaVariable => {
            const dataDictionaryVariable = { title: metaVariable.qLabel };
            dataDictionaryVariable.guid = metaVariable.uuid;
            if (metaVariable.indent > 0) {
                dataDictionaryVariable.title = metaVariable.qLabel.replace(
                    `${metaVariable.parentVariable.label}:`,
                    '',
                );
            }
            if (
                metaVariable.mapInfoButtonHtmlText &&
                metaVariable.mapInfoButtonHtmlText !== ''
            ) {
                dataDictionaryVariable.info =
                    metaVariable.mapInfoButtonHtmlText;
            }
            return dataDictionaryVariable;
        });
        return {
            survey: {
                title: metadataSelection.survey.displayName,
            },
            table: {
                title: `${metadataSelection.table.name} ; ${metadataSelection.table.title}`,
                info: metadataSelection.table.tableMapInfo,
            },
            variables,
        };
    }

    getMetadataVariableSelection(variableSelectionItems) {
        if (!this.currentMetadata) return undefined;

        const item = variableSelectionItems[0];
        const survey = this.currentMetadata.surveys[item.surveyName];
        if (!survey) return undefined;

        if (this.surveyGroups) {
            const surveyExtended = this.surveyGroups
                .flatMap(group => group.dataSets)
                .find(dataset => dataset.code === survey.name);
            if (surveyExtended && surveyExtended.additionalMetadata) {
                survey.additionalMetadata = surveyExtended.additionalMetadata;
            }
        }

        const dataset = survey.datasets[item.datasetAbbreviation];
        if (!dataset) {
            // survey not fully loaded
            return { survey };
        }
        const table = dataset.getTableByGuid(item.tableGuid);
        if (!table) {
            // dataset not fully loaded
            return { survey, dataset };
        }
        const variable = table.getVariableByGuid(item.variableGuid);
        if (!variable) {
            // table not fully loaded
            return { survey, dataset, table };
        }
        const variables = table.variablesAsArray.filter(
            v => !!variableSelectionItems.find(t => t.variableGuid === v.uuid),
        );
        return {
            survey,
            dataset,
            table,
            variables,
            variableSelectionInfo: this.getTableMultiVariableSelectionOptions(
                table,
                variables,
            ),
        };
    }

    getTableMultiVariableSelectionOptions(table, selectedVariables) {
        if (selectedVariables.length) {
            const canAddMoreToSelection = selectedVariables.length < 5;
            const parentVariable = selectedVariables[0].parentVariable;
            let canSelectionUseDotDensity = true;
            let canSelectionUseShaded = true;
            selectedVariables.forEach(variable => {
                if (!variable.canUseDotDensity()) {
                    canSelectionUseDotDensity = false;
                }
                if (variable.isVariableCountOnly) {
                    canSelectionUseShaded = false;
                }
            });
            return table.variablesAsArray
                .filter(variable => !variable.isHiddenFromUser)
                .map(variable => {
                    const isSelected = !!selectedVariables.find(
                        v => v.uuid === variable.uuid,
                    );
                    const isParentVariable =
                        parentVariable && variable.uuid === parentVariable.uuid;
                    const canVariableUseDotDensity =
                        canSelectionUseDotDensity &&
                        variable.canUseDotDensity();
                    const canVariableUseShaded =
                        canSelectionUseShaded && !variable.isVariableCountOnly;
                    return {
                        variableGuid: variable.uuid,
                        selected: isSelected,
                        isFirstInSelection:
                            selectedVariables[0].uuid === variable.uuid,
                        isMultipleSelection: selectedVariables.length > 1,
                        availableForMultipleSelection:
                            !isSelected &&
                            canAddMoreToSelection &&
                            !isParentVariable &&
                            (canVariableUseDotDensity ||
                                canVariableUseShaded),
                    };
                });
        }
        return table.variablesAsArray.map(variable => ({
            variableGuid: variable.uuid,
            selected: false,
            isMultipleSelection: false,
            availableForMultipleSelection: false,
        }));
    }

    currentDataset(surveyName, datasetAbbrevation) {
        if (this._currentMetadata.surveys[surveyName]) {
            return this._currentMetadata.surveys[surveyName].datasets[
                datasetAbbrevation
            ];
        }
        return undefined;
    }

    currentTable(surveyName, datasetAbbrevation, tableName) {
        if (
            this._currentMetadata.surveys[surveyName] &&
            this._currentMetadata.surveys[surveyName].datasets[
                datasetAbbrevation
            ]
        ) {
            return this._currentMetadata.surveys[surveyName].datasets[
                datasetAbbrevation
            ].tables[tableName];
        }
        return undefined;
    }

    currentSurvey(surveyName) {
        return this._currentMetadata.surveys[surveyName];
    }

    currentSurveys(surveysNames) {
        const surveys = {};
        if (surveysNames && surveysNames.length > 0) {
            const currentSurveys = this._currentMetadata.surveys;
            surveysNames.forEach(surveyName => {
                if (currentSurveys[surveyName]) {
                    surveys[surveyName] = currentSurveys[surveyName];
                }
            });
        }
        return surveys;
    }

    currentCategoryTables(categoryName) {
        return this._currentMetadata.systemDataCategories[categoryName];
    }

    getMapsGroupMetadata(metadataGroupId) {
        return this._groupsMetadata.find(gm => gm.id === metadataGroupId);
    }

    getDataCategoriesForMapsGroup(metadataGroupId) {
        const mapsGroupSurveys = this.getSurveysForMapsGroup(metadataGroupId);
        const dataCategories = {};
        Object.values(this._currentMetadata.systemDataCategories).forEach(
            dataCategory => {
                // we get all available years for this category when we collect all years of
                // available surveys for this metadata group (old SE base map)
                const availableYears = {};
                mapsGroupSurveys.forEach(survey => {
                    if (survey.hasCategory(dataCategory.name)) {
                        availableYears[survey.year] = Number.parseInt(
                            survey.year,
                            10,
                        );
                    }
                });
                if (Object.keys(availableYears).length > 0) {
                    dataCategories[dataCategory.name] = {
                        name: dataCategory.name,
                        iconUrl: dataCategory.iconUrl,
                        availableYears: Object.values(availableYears),
                    };
                }
            },
        );
        return Object.values(dataCategories);
    }

    /** @param {string} metadataGroupId */
    getSurveysForMapsGroup(metadataGroupId) {
        const mapsGroupMetadata = this.getMapsGroupMetadata(metadataGroupId);
        return this._currentMetadata.sortedSurveys(mapsGroupMetadata);
    }

    getAvailableDatasetsForMapsGroup(metadataGroupId) {
        const mapsGroupMetadata = this.getMapsGroupMetadata(metadataGroupId);
        const availableDatasets = {};
        Object.keys(mapsGroupMetadata.base_maps_metadata).forEach(
            surveyName => {
                availableDatasets[surveyName] =
                    mapsGroupMetadata.base_maps_metadata[surveyName]
                        .available_datasets || [];
            },
        );
        return availableDatasets;
    }

    /**
     * @param {string} metadataGroupId
     * @returns {string[]}
     */
    getSurveysNamesForMapsGroup(metadataGroupId) {
        const mapsGroupMetadata = this.getMapsGroupMetadata(metadataGroupId);
        return mapsGroupMetadata.base_maps_ids;
    }

    loadPartialMetadata(partialMetadataInfo) {
        const tables = Object.keys(partialMetadataInfo.surveys)
            .map(surveyName => {
                const surveyInfo = partialMetadataInfo.surveys[surveyName];
                return Object.keys(surveyInfo.datasets)
                    .map(dsAbbrevation => {
                        const datasetInfo = surveyInfo.datasets[dsAbbrevation];
                        const tableGuids = Object.keys(datasetInfo.tables).join(
                            ',',
                        );
                        return `${surveyName}.${dsAbbrevation}:${tableGuids}`;
                    })
                    .join(';');
            })
            .join(';');

        const params = {
            cmd: 'PartialMeta',
            Tables: tables,
        };
        const logic = (resolve, reject) => {
            Api.metadata.getMetadata({ query: params }).then(response => {
                const responseXMLDocument = response.xhr.responseXML;

                const error =
                    responseXMLDocument.getElementsByTagName('Error')[0];

                if (error) {
                    reject(error.value);
                    return;
                }
                const metadataXMLParser = new MetadataXMLParser(
                    responseXMLDocument,
                );
                const metadata = metadataXMLParser.metadata;
                // map data from surveys to current surveys or add it if not found
                this._currentMetadata.surveys =
                    this._currentMetadata.surveys || {};
                const currentSurveys = this._currentMetadata.surveys;
                const surveysNames = Object.keys(metadata.surveys);
                surveysNames.forEach(surveyName => {
                    if (!currentSurveys[surveyName]) {
                        metadata.surveys[surveyName].index =
                            Object.keys(currentSurveys).length;
                        currentSurveys[surveyName] =
                            metadata.surveys[surveyName];
                    } else {
                        currentSurveys[surveyName].mergePartial(
                            metadata.surveys[surveyName],
                        );
                    }
                });

                // Add the system data category only if it doesn't already exist
                const currentSystemDataCategories =
                    this._currentMetadata.systemDataCategories;
                if (!currentSystemDataCategories) {
                    this._currentMetadata.systemDataCategories =
                        metadata.systemDataCategories;
                } else {
                    const systemDataCategories = Object.keys(
                        metadata.systemDataCategories,
                    );
                    systemDataCategories.forEach(systemDataCategory => {
                        if (!currentSystemDataCategories[systemDataCategory]) {
                            currentSystemDataCategories[systemDataCategory] =
                                metadata.systemDataCategories[
                                    systemDataCategory
                                ];
                        }
                    });
                }

                this._currentMetadata.systemCategoryFilters =
                    metadata.systemCategoryFilters;

                resolve([this._currentMetadata]);
            }, reject);
        };

        return new Promise((resolve, reject) => {
            this.cacheRequest(params, logic).then(responses => {
                resolve(responses[0]);
            }, reject);
        });
    }

    loadDataset(surveyName, datasetAbbrevation) {
        const params = {
            cmd: 'Dataset',
            SurveyName: surveyName,
            DatasetName: datasetAbbrevation,
            IncludeVariables: false,
        };
        const logic = (resolve, reject) => {
            Api.metadata.getMetadata({ query: params }).then(response => {
                const responseXMLDocument = response.xhr.responseXML;

                const error =
                    responseXMLDocument.getElementsByTagName('Error')[0];

                if (error) {
                    reject(error.value);
                    return;
                }

                const metadataXMLParser = new MetadataXMLParser(
                    responseXMLDocument,
                );

                const datasetSurvey = this._currentMetadata.surveys[surveyName];
                const dataset =
                    metadataXMLParser.datasets(datasetSurvey)[
                        datasetAbbrevation
                    ];
                // if dataset is not present in survey just add it and finish
                // otherwise we must merge missing tables to it
                if (!datasetSurvey.datasets[dataset.abbrevation]) {
                    dataset.index = Object.keys(datasetSurvey.datasets).length;
                    datasetSurvey.datasets[dataset.abbrevation] = dataset;
                } else {
                    const currentDataset =
                        datasetSurvey.datasets[dataset.abbrevation];
                    const currentTables = currentDataset.tables;
                    currentDataset.tables = {};

                    // if new table already present among current tables add current table
                    const newTableNames = Object.keys(dataset.tables);
                    newTableNames.forEach(tableName => {
                        const newTable = dataset.tables[tableName];
                        if (currentTables[tableName]) {
                            currentDataset.tables[tableName] =
                                currentTables[tableName];
                        } else {
                            currentDataset.tables[tableName] = newTable;
                            currentDataset.tables[tableName].index =
                                Object.keys(currentDataset.tables).length - 1;
                        }
                    });
                    // if one of the current tables is not added in previous step then add it again
                    const currentTablesNames = Object.keys(currentTables);
                    currentTablesNames.forEach(tableName => {
                        if (!currentDataset.tables[tableName]) {
                            currentDataset.tables[tableName] =
                                currentTables[tableName];
                            currentDataset.tables[tableName].index =
                                Object.keys(currentDataset.tables).length - 1;
                        }
                    });
                }

                resolve([
                    this._currentMetadata.surveys[surveyName].datasets[
                        datasetAbbrevation
                    ],
                ]);
            }, reject);
        };

        return new Promise((resolve, reject) => {
            this.cacheRequest(params, logic).then(responses => {
                resolve(responses[0]);
            }, reject);
        });
    }

    loadTable(surveyName, datasetAbbrevation, tableName) {
        const params = {
            cmd: 'Table',
            SurveyName: surveyName,
            DatasetName: datasetAbbrevation,
            TableName: tableName,
        };
        const logic = (resolve, reject) => {
            Api.metadata.getMetadata({ query: params }).then(response => {
                const responseXMLDocument = response.xhr.responseXML;

                const error =
                    responseXMLDocument.getElementsByTagName('Error')[0];

                if (error) {
                    reject(error.value);
                    return;
                }

                const metadataXMLParser = new MetadataXMLParser(
                    responseXMLDocument,
                );
                const tableDataset =
                    this._currentMetadata.surveys[surveyName].datasets[
                        datasetAbbrevation
                    ];

                const table = metadataXMLParser.tables(tableDataset)[tableName];
                // if table is not present in dataset just add it and finish
                // otherwise we must reset variables to new ones
                if (!tableDataset.tables[table.name]) {
                    table.index = Object.keys(tableDataset.tables).length;
                    tableDataset.tables[table.name] = table;
                } else {
                    const currentTable = tableDataset.tables[table.name];
                    currentTable.variables = table.variables;
                }

                resolve([tableDataset.tables[table.name]]);
            }, reject);
        };

        return new Promise((resolve, reject) => {
            this.cacheRequest(params, logic).then(responses => {
                resolve(responses[0]);
            }, reject);
        });
    }

    loadCategoryTables(categoryName, year, metadataGroupId) {
        // get survey names for selected year
        const surveyNames = this._currentMetadata.surveysAsArray
            .filter(survey => survey.year === year)
            .map(survey => survey.name);
        const params = {
            cmd: 'CategoryTables2',
            SurveyNames: surveyNames.join(','),
            CategoryName: categoryName,
            IncludeVariables: true,
        };
        const logic = (resolve, reject) => {
            Api.metadata.getMetadata({ query: params }).then(response => {
                const responseXMLDocument = response.xhr.responseXML;

                const error =
                    responseXMLDocument.getElementsByTagName('Error')[0];

                if (error) {
                    reject(error.value);
                    return;
                }

                const metadataXMLParser = new MetadataXMLParser(
                    responseXMLDocument,
                );

                const surveys = metadataXMLParser.surveys;
                const currentSurveys = this._currentMetadata.surveys;

                // Collect category tables
                const categoryTables = [];
                const surveysNames = Object.keys(surveys);
                surveysNames.forEach(surveyName => {
                    // for each parsed survey
                    const survey = surveys[surveyName];
                    const currentSurvey = currentSurveys[surveyName];
                    if (!currentSurvey) return;
                    const datasetsAbbrevations = Object.keys(survey.datasets);
                    datasetsAbbrevations.forEach(datasetAbbrevation => {
                        // for each dataset in survey
                        const dataset = survey.datasets[datasetAbbrevation];
                        const currentDataset =
                            currentSurvey.datasets[datasetAbbrevation];
                        if (!currentDataset) return;
                        const tableNames = Object.keys(dataset.tables);
                        tableNames.forEach(tableName => {
                            // for each table in dataset
                            const table = dataset.tables[tableName];
                            if (!currentDataset.tables[tableName]) {
                                // if table is not present in current dataset add it and mark dataset as partial
                                currentDataset.partial = true;
                                table.index = Object.keys(
                                    currentDataset.tables,
                                ).length;
                                table.dataset = currentDataset;
                                currentDataset.tables[tableName] = table;
                            } else if (
                                Object.keys(
                                    currentDataset.tables[tableName].variables,
                                ).length === 0
                            ) {
                                // if table is present in current dataset but variables are not loaded replace it
                                table.index =
                                    currentDataset.tables[tableName].index;
                                table.dataset = currentDataset;
                                currentDataset.tables[tableName] = table;
                            }
                            // add table to list of category tables
                            categoryTables.push(
                                currentDataset.tables[tableName],
                            );
                        });
                    });
                });

                // find data category by name
                const dataCategory =
                    this._currentMetadata.systemDataCategories[categoryName];
                if (dataCategory) {
                    categoryTables.forEach(table => {
                        if (
                            table.categories.find(
                                name => name === categoryName,
                            ) &&
                            !dataCategory.tables[table.uuid]
                        ) {
                            // if table is part of the category and current category does not contain table add it
                            dataCategory.tables[table.uuid] = table;
                        }
                    });
                }
                // filter category tables based on maps metadata
                resolve([
                    this._filterMapAvailableCategoryTables(
                        categoryTables,
                        year,
                        metadataGroupId,
                    ),
                ]);
            }, reject);
        };

        return new Promise((resolve, reject) => {
            this.cacheRequest(params, logic).then(responses => {
                resolve(responses[0]);
            }, reject);
        });
    }

    loadSurveys(surveyNames) {
        const params = {
            cmd: 'Surveys',
            Names: surveyNames,
        };

        const logic = (resolve, reject) => {
            Api.metadata.getMetadata({ query: params }).then(response => {
                const responseXMLDocument = response.xhr.responseXML;

                const error =
                    responseXMLDocument.getElementsByTagName('Error')[0];

                if (error) {
                    reject(error.value);
                    return;
                }

                const metadataXMLParser = new MetadataXMLParser(
                    responseXMLDocument,
                );

                const metadata = metadataXMLParser.metadata;
                // map data from surveys to current surveys or add it if not found

                this._currentMetadata.surveys =
                    this._currentMetadata.surveys || {};

                const currentSurveys = this._currentMetadata.surveys;
                const surveysNames = Object.keys(metadata.surveys);
                surveysNames.forEach(surveyName => {
                    if (!currentSurveys[surveyName]) {
                        currentSurveys[surveyName] =
                            metadata.surveys[surveyName];
                        currentSurveys[surveyName].index =
                            Object.keys(currentSurveys).length - 1;
                    } else {
                        currentSurveys[surveyName].mergePartial(
                            metadata.surveys[surveyName],
                        );
                    }
                });

                // Add the system data category only if it doesn't already exist
                const currentSystemDataCategories =
                    this._currentMetadata.systemDataCategories;
                if (!currentSystemDataCategories) {
                    this._currentMetadata.systemDataCategories =
                        metadata.systemDataCategories;
                } else {
                    const systemDataCategories = Object.keys(
                        metadata.systemDataCategories,
                    );
                    systemDataCategories.forEach(systemDataCategory => {
                        if (!currentSystemDataCategories[systemDataCategory]) {
                            currentSystemDataCategories[systemDataCategory] =
                                metadata.systemDataCategories[
                                    systemDataCategory
                                ];
                        }
                    });
                }

                this._currentMetadata.systemCategoryFilters =
                    metadata.systemCategoryFilters;
                resolve([this._currentMetadata.surveys]);
            }, reject);
        };

        return new Promise((resolve, reject) => {
            this.cacheRequest(params, logic).then(responses => {
                resolve(responses[0]);
            }, reject);
        });
    }

    /**
     * @param {string[]} surveyNames Array of survey codes for which we want to fetch the system reports
     * @returns {Promise<import('../../../types').PremadeReport[][]>}
     */
    loadPremadeSurveyReports(surveyNames) {
        const calls = surveyNames.map(surveyName => {
            const params = {
                query: { sSurveyName: `"${surveyName}"` },
                headers: [{ name: 'Content-Type', value: 'application/json' }],
            };
            /** @param {(premadeReports: PremadeReport[]) => void} resolve */
            const logic = (resolve, reject) => {
                Api.report.getSystemReports(params).then(response => {
                    /** @type {import('../../../types').AspNetWebService.SystemReportResults} */
                    const body = response.body;
                    if (body) {
                        const premadeReports = body.d.map(formatSystemReport);
                        resolve(premadeReports);
                    } else {
                        reject();
                    }
                }, reject);
            };

            return this.cacheRequest(params, logic);
        });

        return Promise.all(calls);
    }

    /**
     * @param {string} surveyName
     * @param {string} reportId number in string format
     * @returns {Promise<{ surveyName: string, systemReport: import('../../../types').PremadeReport }>}
     */
    loadSystemReport(surveyName, reportId) {
        const params = {
            query: { reportid: reportId },
            headers: [{ name: 'Content-Type', value: 'application/json' }],
        };
        const logic = (resolve, reject) => {
            Api.report.getSystemReport(params).then(response => {
                /** @type {import('../../../types').AspNetWebService.SystemReportResult} */
                const body = response.body;
                if (body) {
                    const systemReport = formatSystemReport(body.d);
                    resolve([{ surveyName, systemReport }]);
                } else {
                    reject();
                }
            }, reject);
        };

        return new Promise((resolve, reject) => {
            this.cacheRequest(params, logic).then(responses => {
                resolve(responses[0]);
            }, reject);
        });
    }

    /**
     * @param {string} surveyName
     * @param {string[]} tableGuids
     * @returns {Promise<import('../../../types').Table[]>}
     */
    loadTablesWithVariables(surveyName, tableGuids) {
        const payload = {
            sTableGuids: tableGuids.join('|'),
            sSurveyName: surveyName,
            bIncludeVariables: true,
        };
        const logic = (resolve, reject) => {
            Api.report.getTablesByGuids({ payload }).then(response => {
                /** @type {import('../../../types').AspNetWebService.TablesResults} */
                const responseBody = response.body;
                if (responseBody) {
                    const tables = responseBody.d.map(formatTable);
                    resolve(tables);
                } else {
                    reject();
                }
            }, reject);
        };

        return this.cacheRequest(payload, logic);
    }

    /**
     *
     * @param {object} param0
     * @param {boolean} param0.filter
     * @param {boolean} param0.previewAllDatasets - Flag that indicates if user should get a preview of all available surveys/datasets, no matter the access rights
     */
    loadGroupsMetadata({ filter, previewAllDatasets }) {
        return Promise.all([
            Api.dingo.getGroups(),
            this.loadSurveyGroups(previewAllDatasets),
        ])
            .then(([groupsResponse, surveyGroups]) => {
                const groups = groupsResponse.body;
                // Let's get all survey codes from the survey groups (explore-tables endpoint).
                // Those are the surveys that the user has access to.
                const accessibleSurveyCodes = surveyGroups.flatMap(
                    surveyGroup =>
                        surveyGroup.dataSets.map(dataset => dataset.code),
                );
                if (filter) {
                    // filter is set to FALSE only in embed mode
                    groups.forEach(group => {
                        // Let's filter the group data base maps ids (surveys)
                        group.base_maps_ids = group.base_maps_ids.filter(
                            surveyCode =>
                                accessibleSurveyCodes.includes(surveyCode),
                        );
                    });
                }
                // remove groups that have an empty base_maps_ids array after filter
                const filteredGroups = groups.filter(
                    group => group.base_maps_ids.length,
                );
                this._groupsMetadata = filteredGroups;
            })
            .catch(error => {
                console.error(error.message);
            });
    }

    loadLibraryData() {
        return new Promise((resolve, reject) => {
            Api.dingo.getLibrary().then(libraryDataResponse => {
                /** @type {import('../').LayerLibraryItem[]} */
                const libraryData = libraryDataResponse.body;
                // Let's filter the library data
                const filteredLibraryData = libraryData.filter(libraryLayer => {
                    const { report } = libraryLayer.metadata;
                    // If report property is not defined in the library layer,
                    // it means it's allowed for all users to use them
                    if (!report) return true;
                    // Otherwise, we have to check if the user is able to access
                    // the specified survey code
                    // Let's get all survey codes from the survey groups (explore-tables endpoint).
                    // Those are the surveys that the user has access to.
                    const accessibleSurveyCodes = this._surveyGroups.flatMap(
                        surveyGroup =>
                            surveyGroup.dataSets.map(dataset => dataset.code),
                    );
                    return accessibleSurveyCodes.includes(report.surveyCode);
                });
                this._prepareLibraryData(filteredLibraryData);
                resolve(this._libraryData);
            }, reject);
        });
    }

    /**
     * Prepare library data for consumption
     * @param {LayerLibraryItem[]} libraryData
     */
    _prepareLibraryData(libraryData) {
        this._libraryData.definition = libraryData;
        this._libraryData.layers = [];

        this._libraryData.groups = this._libraryData.definition.map(group => {
            const { id, metadata, layers, sources } = group;

            /** @type {Object.<string, import('../').LayerIdTitle>} key is layerGroupId*/
            const layersObject = {};

            layers.forEach(layer => {
                this._libraryData.layers.push(layer);

                const layerInfo = layer.metadata.socialexplorer;
                const layerGroupId = layerInfo['layer-group-id'] || layer.id;

                if (layer.filter) {
                    /**
                     * Parse a string function definition and return a function object.
                     * Does not use eval.
                     *
                     * Copied from: https://gist.github.com/lamberta/3768814
                     *
                     * @example
                     *  var f = function (x, y) { return x * y; };
                     *  var g = parseFunction(f.toString());
                     *  g(33, 3); //=> 99
                     *
                     *
                     * @param {string} str
                     */
                    const parseFunction = str => {
                        const fnBodyIdx = str.indexOf('{'),
                            fnBody = str.substring(
                                fnBodyIdx + 1,
                                str.lastIndexOf('}'),
                            ),
                            fnDeclare = str.substring(0, fnBodyIdx),
                            fnParams = fnDeclare.substring(
                                fnDeclare.indexOf('(') + 1,
                                fnDeclare.lastIndexOf(')'),
                            ),
                            args = fnParams.split(',');

                        args.push(fnBody);

                        // eslint-disable-next-line prefer-spread
                        return Function.apply(undefined, args);
                    };

                    /**
                     * Finds js function strings in filters and replaces them with evaluated functions
                     * @param {Array} filterArray
                     */
                    const evaluateJsFunctions = filterArray => {
                        filterArray.forEach((element, index) => {
                            if (
                                typeof element === 'string' &&
                                element.startsWith('#JS')
                            ) {
                                const jsFn = parseFunction(
                                    element.substring(3, element.length),
                                );
                                filterArray[index] = jsFn();
                            } else if (Array.isArray(element)) {
                                evaluateJsFunctions(element);
                            }
                        });
                    };

                    evaluateJsFunctions(layer.filter);
                }

                if (layersObject[layerGroupId]) {
                    layersObject[layerGroupId].layers.push(layer);
                } else {
                    layersObject[layerGroupId] = {
                        id: layerGroupId,
                        title: layerInfo.title,
                        layers: [layer],
                    };
                }
            });

            return {
                id,
                metadata,
                sources,
                layers: Object.values(layersObject),
            };
        });

        const categories = this._libraryData.groups.map(
            group => group.metadata.category,
        );
        this._libraryData.categories = [...new Set(categories)];
    }

    // returns only those category tables that are available for selected year and
    // are present in groups metadata
    _filterMapAvailableCategoryTables(tables, year, metadataGroupId) {
        const groupMetadata = this._groupsMetadata.find(
            gm => gm.id === metadataGroupId,
        );
        const metadataGroupsSurveysIds = groupMetadata.base_maps_ids;
        const metadataGroupSurveysMetadata = groupMetadata.base_maps_metadata;
        const filteredTables = tables.filter(table => {
            const dsAbbrevation = table.dataset.abbrevation;
            const surveyName = table.dataset.survey.name;
            // table survey year not the same
            if (
                table.dataset.survey.year !== year ||
                !table.dataset.survey.yearTo === year
            ) {
                return false;
            }
            // survey not available on the maps
            if (metadataGroupsSurveysIds.indexOf(surveyName) === -1) {
                return false;
            }
            // dataset not available on the maps
            if (
                !metadataGroupSurveysMetadata[surveyName] ||
                !metadataGroupSurveysMetadata[surveyName].available_datasets ||
                metadataGroupSurveysMetadata[
                    surveyName
                ].available_datasets.indexOf(dsAbbrevation) === -1
            ) {
                return false;
            }
            return true;
        }, this);
        const tablesBySurveys = {};
        filteredTables.forEach(table => {
            tablesBySurveys[table.dataset.survey.name] =
                tablesBySurveys[table.dataset.survey.name] || [];
            tablesBySurveys[table.dataset.survey.name].push(table);
        });
        const sortedSurveysNames = this.getSurveysForMapsGroup(
            metadataGroupId,
        ).map(survey => survey.name);
        return sortedSurveysNames
            .filter(name => tablesBySurveys[name] !== undefined)
            .map(name => ({
                surveyName: name,
                surveyDisplayName:
                    this._currentMetadata.surveys[name].displayName,
                tables: tablesBySurveys[name],
            }));
    }

    loadFeaturedCategoriesData() {
        const logic = (resolve, reject) => {
            if (this._categoriesData === undefined) {
                Api.variable.getFeaturedCategoriesData().then(response => {
                    this._featuredCategoriesData = response.body;
                    resolve(this._featuredCategoriesData);
                }, reject);
            } else {
                resolve(this._featuredCategoriesData);
            }
        };

        return new Promise(logic);
    }

    /**
     * @param {boolean} previewAllDatasets Flag that indicates if user should get a preview of all available surveys/datasets, no matter the access rights
     */
    loadSurveyGroups(previewAllDatasets = false) {
        const params = {
            query: {
                tenant: AppConfig.constants.tenant,
                preview_datasets_not_included_in_current_subscription:
                    previewAllDatasets,
            },
            headers: [
                { name: 'Content-Type', value: 'application/json' },
                { name: 'Accept', value: 'application/json' },
            ],
        };

        /** @param {(surveyGroup: import('../').SurveyGroup[]) => void} resolve */
        const logic = (resolve, reject) => {
            if (this._surveyGroups === undefined) {
                Api.seWebsite
                    .getSurveyGroups(params)
                    .then(
                        response => response.body,
                        () => [],
                    )
                    .then(
                        // eslint-disable-next-line arrow-parens
                        (
                            /** @type {import('../').SurveyGroup[]} */ surveyGroups,
                        ) => {
                            surveyGroups = surveyGroups.filter(
                                sg => sg.enabled,
                            );
                            surveyGroups.forEach(
                                sg =>
                                    (sg.dataSets = sg.dataSets.filter(
                                        dataset =>
                                            dataset.enabled &&
                                            dataset.availableInMaps,
                                    )),
                            );
                            this._surveyGroups = surveyGroups;
                            resolve(this._surveyGroups);
                        },
                        reject,
                    );
            } else {
                resolve(this._surveyGroups);
            }
        };

        return new Promise(logic);
    }

    loadCpiValues() {
        const params = {
            headers: [{ name: 'Content-Type', value: 'application/json' }],
        };

        const logic = (resolve, reject) => {
            Api.report.getCpiValues(params).then(response => {
                if (response.body) {
                    /** @type {import('../../../types').AspNetWebService.ConsumerPriceIndicesResults} */
                    const cpiValuesResponse = response.body;
                    cpiValuesResponse.d.forEach(cpiByYear => {
                        this._cpiValuesByYear[cpiByYear.Year] = cpiByYear.Cpi;
                    });
                    resolve([this._cpiValuesByYear]);
                } else {
                    reject();
                }
            }, reject);
        };

        return new Promise((resolve, reject) => {
            this.cacheRequest(params, logic).then(
                responses => resolve(responses[0]),
                reject,
            );
        });
    }
}

export default MetadataDataSource;
