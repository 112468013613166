import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

const CutpointsSelector = props => (
    <button
        className="visualization-editor__section visualization-editor__cutpoints flex-it flex-start center"
        onClick={props.onShowDataClassificationModalClick}
    >
        <div className="visualization-editor__icon flex-it center justify-center no-shrink">
            <i className="material-icons" aria-label="Cutpoint icon">show_chart</i>
        </div>
        <div className="flex-it column text-left">
            <h4 className="visualization-editor__title">
                {props.intl.formatMessage({ id: 'visualisationType.cutpoints' })}
            </h4>
            <div className="body-2">
                {props.intl.formatMessage({ id: 'visualisationType.adjustCutpointClasses' })}
            </div>
        </div>
    </button>
);

CutpointsSelector.propTypes = {
    onShowDataClassificationModalClick: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
};

CutpointsSelector.defaultProps = {};

export default injectIntl(CutpointsSelector);
