const rendererTypes = {
    VALUE: 'ValueRenderer',
    REPORT: 'ReportRenderer',
    MULTI_VALUE: 'MultiValueRenderer',
    BUBBLE: 'BubbleRenderer',
    SYMBOL: 'SymbolRenderer',
    LABEL: 'LabelRenderer',
    DOT_DENSITY: 'DotDensityRenderer',
    VALUE_DOT_DENSITY: 'ValueDotDensityRenderer',
};

export default rendererTypes;
