import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { hasParentNode, addEvent, removeEvent } from '../../helpers/Util';

import SimpleDropdownMenu from './SimpleDropdownMenu';
import TextInput from '../form/TextInput';

import Keys from '../../enums/Key';

class SimpleDropdown extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            filterText: undefined,
            items: props.items,
            visibleItems: props.items,
            selectedItem: props.selectedItem,
            isOpen: false,
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            filterText: undefined,
            items: nextProps.items,
            visibleItems: nextProps.items,
            selectedItem: nextProps.selectedItem,
            isOpen: false,
        });
    }

    componentWillUnmount() {
        removeEvent(document, 'click', this.onClick);
    }

    onItemClick = id => {
        this.props.onItemClick(id);
        this.setState({
            isOpen: false,
            filterText: undefined,
            visibleItems: this.state.items,
        });
    };

    onFilterTextChange = value => {
        const { items } = this.state;
        let visibleItems = [...items];
        const text = value.toLocaleLowerCase().trim();
        if (text) {
            const filterArray = text.split(' ');
            visibleItems = items.filter(item => {
                const itemText = item.text.toLocaleLowerCase().trim();
                return !filterArray.some(
                    filter => itemText.indexOf(filter) === -1,
                );
            });
        }
        this.setState({
            filterText: value,
            visibleItems,
        });
    };

    onToggle = () => {
        const { isOpen, items } = this.state;
        const willOpen = !isOpen;

        this.setState(
            {
                isOpen: willOpen,
                filterText: undefined,
                visibleItems: items,
            },
            () => {
                // add click event in order to automatically close dropdown
                // on click away
                if (this.state.isOpen) {
                    addEvent(document, 'click', this.onClick);
                }
            },
        );
    };

    onClick = e => {
        // remove event listner set on opening dropdown
        if (this.state.isOpen && !hasParentNode(e.target, this.root)) {
            removeEvent(document, 'click', this.onClick);
            this.onToggle();
        }
    };

    onKeyUp = e => {
        // Prevent interpreting SPACE key press as a button click
        const key = e.which || e.keyCode;
        if (key !== undefined && key !== Keys.SPACE) return;

        e.preventDefault();
    };

    render() {
        const {
            disabled,
            className,
            title,
            showFilter,
            filterPlaceholder,
            forceRenderWithinViewport,
        } = this.props;
        const {
            items,
            isOpen,
            filterText,
            visibleItems,
            selectedItem,
        } = this.state;

        const simpleDropdownClasses = classNames('simple-dropdown', className, {
            'simple-dropdown--disabled': disabled || items.length === 0,
            'simple-dropdown--open': isOpen,
            'simple-dropdown--with-filter': showFilter,
        });

        // Hide title node if title is not provided, or filter input is enabled
        // in open state
        const showFilterInput = isOpen && showFilter;
        const showTitleNode = title && !showFilterInput;

        let menu;
        if (isOpen) {
            menu = (
                <SimpleDropdownMenu
                    items={visibleItems}
                    onItemClick={this.onItemClick}
                    selectedItem={selectedItem}
                    forceRenderWithinViewport={forceRenderWithinViewport}
                    root={this.root}
                />
            );
        }

        return (
            <div
                ref={root => {
                    this.root = root;
                }}
                className={simpleDropdownClasses}
            >
                <button
                    className="simple-dropdown__anchor fit-content"
                    onKeyUp={this.onKeyUp}
                    onClick={this.onToggle}
                    tabIndex={disabled ? -1 : undefined}
                    aria-label={this.props.ariaLabel}
                    aria-disabled={this.props.disabled}
                >
                    <div className="simple-dropdown__content">
                        {showTitleNode && (
                            <span className="simple-dropdown__title">
                                {title}
                            </span>
                        )}
                        {!showFilterInput && (
                            <span
                                className="simple-dropdown__selected body-1"
                                title={selectedItem.text}
                            >
                                {selectedItem.text}
                            </span>
                        )}
                        {showFilterInput && (
                            <TextInput
                                oldStyle
                                className="input-box--dropdown-filter grow"
                                placeholder={filterPlaceholder}
                                value={filterText}
                                initialFocus
                                onChange={this.onFilterTextChange}
                            />
                        )}
                    </div>
                    <div className="simple-dropdown__icon">
                        <i
                            className="material-icons"
                            aria-label={
                                isOpen ? 'Arrow up icon' : 'Arrow down icon'
                            }
                        >
                            {isOpen ? 'arrow_drop_up' : 'arrow_drop_down'}
                        </i>
                    </div>
                </button>
                {menu}
            </div>
        );
    }
}

SimpleDropdown.propTypes = {
    showFilter: PropTypes.bool,
    filterPlaceholder: PropTypes.string,
    items: PropTypes.array.isRequired,
    onItemClick: PropTypes.func.isRequired,
    selectedItem: PropTypes.object,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    title: PropTypes.string,
    forceRenderWithinViewport: PropTypes.bool,
    ariaLabel: PropTypes.string,
};

SimpleDropdown.defaultProps = {
    showFilter: false,
    filterPlaceholder: 'Filter',
    className: undefined,
    disabled: false,
    title: undefined,
    selectedItem: { id: -1, text: 'Nothing selected' },
    forceRenderWithinViewport: false,
    ariaLabel: undefined,
};

export default SimpleDropdown;
