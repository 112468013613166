'use strict';var IndexBuffer = function IndexBuffer(gl, array, dynamicDraw) {
    this.gl = gl;
    this.buffer = gl.createBuffer();
    this.dynamicDraw = Boolean(dynamicDraw);
    this.unbindVAO();
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this.buffer);
    gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, array.arrayBuffer, this.dynamicDraw ? gl.DYNAMIC_DRAW : gl.STATIC_DRAW);
    if (!this.dynamicDraw) {
        delete array.arrayBuffer;
    }
};
IndexBuffer.prototype.unbindVAO = function unbindVAO() {
    if (this.gl.extVertexArrayObject === undefined) {
        this.gl.extVertexArrayObject = this.gl.getExtension('OES_vertex_array_object');
    }
    if (this.gl.extVertexArrayObject) {
        this.gl.extVertexArrayObject.bindVertexArrayOES(null);
    }
};
IndexBuffer.prototype.bind = function bind() {
    this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, this.buffer);
};
IndexBuffer.prototype.updateData = function updateData(array) {
    this.unbindVAO();
    this.bind();
    this.gl.bufferSubData(this.gl.ELEMENT_ARRAY_BUFFER, 0, array.arrayBuffer);
};
IndexBuffer.prototype.destroy = function destroy() {
    if (this.buffer) {
        this.gl.deleteBuffer(this.buffer);
        delete this.buffer;
    }
};
module.exports = IndexBuffer;