import React from 'react';
import PropTypes from 'prop-types';

import BusComponent from '../BusComponent';
import SelectionList from '../selectionList/SelectionList';

const AUTO_SUMMARY_LEVEL_ID = 'automatic';

/**
 * @typedef Props
 * @property {string} mapInstanceId
 * @property {string} [title]
 * @property {() => void} [onChange]
 *
 * @extends {BusComponent<Props>}
 */
class SummaryLevelSelector extends BusComponent {
    static NAME = 'SummaryLevelSelector';

    constructor(props, context) {
        super(props, context);

        this.state = {
            automaticSummaryLevel: undefined,
            automaticSummaryLevelLabel: '',
            activeSummaryLevel: undefined,
            activeSummaryLevelLabel: '',
            /** @type {import('../../../..').SummaryLevel[]} */
            summaryLevels: [],
            isAutomatic: false,
            selectedSummaryLevelId: AUTO_SUMMARY_LEVEL_ID,
        };

        this.bindGluBusEvents({
            MAP_VIEWER: this.handleMapUpdate,
            DRAGONFLY_MAP_CREATED: this.handleMapUpdate,
            MAP_MASKING_FILTER_REMOVED: this.handleMapUpdate,
            MAP_PREFERRED_SUMMARY_LEVEL_CHANGED: this.handleMapUpdate,
            MAP_ZOOM_END: this.handleMapUpdate,
        });
    }

    componentDidMount() {
        this.emit('MAP_GET_MAP_VIEWER');
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    handleMapUpdate(eventMap) {
        if (eventMap.source.id === this.props.mapInstanceId && eventMap.source.dragonflyMapData) {
            const mapViewer = eventMap.source;

            // automatic summary level is visible on current zoom level if preferred level is not set
            let automaticSL, automaticSLLabel = '';
            const canBeAutomatic = mapViewer.dragonflyMapData.visibleSummaryLevels.length > 1;
            if (canBeAutomatic) {
                automaticSL = mapViewer.automaticSummaryLevel;
                automaticSLLabel = automaticSL && automaticSL.title ? automaticSL.title.replace('Data:', '') : '';
            }
            const isAutomatic = !mapViewer.preferredSummaryLevel;

            this.setState({
                activeSummaryLevel: mapViewer.activeSummaryLevel,
                summaryLevels: mapViewer.dragonflyMapData.visibleSummaryLevels,
                automaticSummaryLevel: automaticSL,
                automaticSummaryLevelLabel: automaticSLLabel,
                isAutomatic,
                selectedSummaryLevelId: isAutomatic ? AUTO_SUMMARY_LEVEL_ID : mapViewer.activeSummaryLevel.id,
            });
        }
    }

    /**
     * @param {string} summaryLevelId geo level on what user wants to change.
     * It can be either AUTO_SUMMARY_LEVEL_ID or `SL${geoLevel}`, eg SL150, etc.
     */
    handleSummaryLevelChange = summaryLevelId => {
        const { mapInstanceId, onChange } = this.props;
        if (summaryLevelId === AUTO_SUMMARY_LEVEL_ID) {
            this.emit('CLEAR_PREFERRED_SUMMARY_LEVEL_REQUEST', { mapInstanceId });
        } else {
            const summaryLevel = this.state.summaryLevels.find(sl => sl.id === summaryLevelId);
            this.emit('SET_PREFERRED_SUMMARY_LEVEL_REQUEST', {
                mapInstanceId,
                summaryLevel,
            });
        }

        this.setState({ selectedSummaryLevelId: summaryLevelId });

        if (onChange) {
            onChange();
        }
    }

    render() {
        const { title } = this.props;
        const { selectedSummaryLevelId } = this.state;

        return (
            <div className="summary-level-selector">
                {title && <h4 className="summary-level-selector__title">{title}</h4>}
                <SelectionList
                    items={this.getSummaryLevelListItems()}
                    selectedItemId={selectedSummaryLevelId}
                    onChange={this.handleSummaryLevelChange}
                />
            </div>);
    }

    getSummaryLevelListItems() {
        const {
            automaticSummaryLevel,
            summaryLevels,
            automaticSummaryLevelLabel,
        } = this.state;

        const summaryLevelListItems = summaryLevels.map(sl => ({
            id: sl.id,
            text: sl.title ? sl.title.replace('Data:', '') : undefined,
        }));

        if (automaticSummaryLevel) {
            summaryLevelListItems.unshift({
                id: AUTO_SUMMARY_LEVEL_ID,
                text: `Automatic (${automaticSummaryLevelLabel.trim()})`,
            });
        }

        return summaryLevelListItems;
    }
}

SummaryLevelSelector.propTypes = {
    mapInstanceId: PropTypes.string.isRequired,
    title: PropTypes.string,
    onChange: PropTypes.func,
};

SummaryLevelSelector.defaultProps = {
    title: undefined,
    onChange: undefined,
};

export default SummaryLevelSelector;
