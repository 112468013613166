import React from 'react';
import BusComponent from '../BusComponent';
import classNames from 'classnames';

import Key from '../../enums/Key';
import Popover from '../popover/Popover';

import HelpTourStep from './HelpTourStep';
import HelpTourWelcome from './HelpTourWelcome';
import {
    HelpTourTargets,
    HelpTourStepsOptions,
    HelpTourWelcomeOptions,
    HELP_TOUR_STEPS_NUMBER,
    DEFAULT_ARROW_OFFSET,
} from '../../enums/HelpTourDefinitions';
import { focusFirstChild, hasParentNode } from '../../helpers/Util';

class HelpTour extends BusComponent {
    constructor(props, context) {
        super(props, context);

        this.state = {
            open: false,
            step: 0,
            showTourWelcome: false,
        };

        this.focusBackElement = undefined;

        this.boundOnLockFocusInsideModal = this.onLockFocusInsideModal.bind(this);
    }

    componentDidMount() {
        this.bindGluBusEvents({
            HELP_TOUR_START_REQUEST: this.onHelpTourStartRequest,
            SET_ON_MODAL_CLOSE_FOCUS_ELEMENT: this.onSetOnModalCloseFocusElement,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        // the HelpTour is opened so we can attach the focus and key listener
        if (this.helpTour && this.state.open && !prevState.open) {
            document.addEventListener('keydown', this.onKeydownCallback);
            document.addEventListener('focus', this.boundOnLockFocusInsideModal, true);
        } else if (!this.state.open) {
            // on close help tour remove the listeners
            document.removeEventListener('keydown', this.onKeydownCallback);
            document.removeEventListener('focus', this.boundOnLockFocusInsideModal, true);
        }
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
        this.onClose();
    }

    componentWillUpdate() {
        this.clearAssignedTargetClasses();
    }

    clearAssignedTargetClasses() {
        const tourTargetElements = Array.from(document.querySelectorAll('.help-tour-target'));
        tourTargetElements.forEach((element, i) => tourTargetElements[i].classList.remove('help-tour-target'));
    }

    onKeydownCallback = event => {
        const { step, showTourWelcome } = this.state;
        const lastStepIndex = HELP_TOUR_STEPS_NUMBER - 1;

        const forwardNavigationAvailable = step >= 0 && step < lastStepIndex && !showTourWelcome;
        const backwardsNavigationAvailable = step > 0 && step <= lastStepIndex && !showTourWelcome;

        const stepForwards = event.which === Key.RIGHT && forwardNavigationAvailable;
        const stepBackwards = event.which === Key.LEFT && backwardsNavigationAvailable;

        const tourConfirmation = event.which === Key.ENTER && showTourWelcome;
        const tourCancelation = event.which === Key.ESC;
        const tourCompletion = event.which === Key.ENTER && step === lastStepIndex;

        if (stepForwards) {
            this.onNextStep();
        } else if (stepBackwards) {
            this.onPreviousStep();
        } else if (tourConfirmation) {
            this.onHelpTourStartTourRequest();
            event.stopPropagation();
            event.preventDefault();
        } else if (tourCancelation || tourCompletion) {
            this.onClose();
        }
    }

    onLockFocusInsideModal = event => {
        if (this._ignoreFocusChanges || hasParentNode(event.target, this.helpTour)) {
            return;
        }

        this._ignoreFocusChanges = true;
        focusFirstChild(this.helpTour);
        this._ignoreFocusChanges = false;
    }

    onClose = () => {
        this.bus.emit('HELP_TOUR_STATUS_UPDATE_REQUEST', { isDone: true });
        this.setState({
            open: false,
            step: 0,
            showTourWelcome: false,
        }, () => {
            if (this.focusBackElement) {
                this.focusBackElement.focus();
            }
        });
    }

    onPreviousStep = () => {
        const { step } = this.state;
        this.setState({ step: step < 1 ? step : step - 1 });
    }

    onNextStep = () => {
        const { step } = this.state;
        this.setState({ step: step >= HELP_TOUR_STEPS_NUMBER - 1 ? step : step + 1 });
    }

    onSelectedStep = index => {
        this.setState({ step: index });
    }

    onHelpTourStartRequest = () => {
        this.setState({
            showTourWelcome: true,
            open: true,
        });
    }

    onHelpTourStartTourRequest = () => {
        this.setState({
            showTourWelcome: false,
        });
    }

    onSetOnModalCloseFocusElement(element) {
        this.focusBackElement = element.trigger ? element.trigger : element;
    }

    showWelcome() {
        return (<HelpTourWelcome
            onClose={this.onClose}
            onTourStart={this.onHelpTourStartTourRequest}
            helpTourRef={node => { this.helpTour = node; }}
        />);
    }

    showSteps() {
        const stepOptions = HelpTourStepsOptions[this.state.step];
        return (<HelpTourStep
            options={stepOptions}
            onClose={this.onClose}
            onPrevious={this.onPreviousStep}
            onNext={this.onNextStep}
            onSelected={this.onSelectedStep}
            helpTourRef={node => { this.helpTour = node; }}
        />);
    }

    tourContent() {
        const { open, step, showTourWelcome } = this.state;

        const {
            position,
            targetId,
            highlightId,
            verticalCentering,
        } = showTourWelcome ? HelpTourWelcomeOptions : HelpTourStepsOptions[step];

        const popoverContent = showTourWelcome ? this.showWelcome() : this.showSteps();

        const highlightElementId = highlightId || targetId;

        const anchorElement = document.querySelector(`[data-tourId='${targetId}']`);
        const highlightElement = document.querySelector(`[data-tourId='${highlightElementId}']`);

        if (this.searchlight && highlightElement && !showTourWelcome) {
            const highlightElementBox = highlightElement.getBoundingClientRect();

            this.searchlight.style.width = `${highlightElementBox.width}px`;
            this.searchlight.style.height = `${highlightElementBox.height}px`;
            this.searchlight.style.top = `${highlightElementBox.top}px`;
            this.searchlight.style.left = `${highlightElementBox.left}px`;
        }

        if (verticalCentering) {
            const anchorElementBox = anchorElement.getBoundingClientRect();
            const { anchorOrigin } = position;

            const ARROW_OFFSET = anchorOrigin.vertical === 'top' ? -DEFAULT_ARROW_OFFSET : DEFAULT_ARROW_OFFSET;
            position.marginTop = ARROW_OFFSET + (anchorElementBox.height / 2);
        }


        if (highlightElement && !showTourWelcome) {
            highlightElement.classList.add('help-tour-target');
        }

        return (<Popover
            anchorEl={anchorElement}
            open={open}
            className="help-tour-popover"
            autoWidth
            {...position}
        >
            {popoverContent}
        </Popover>);
    }

    render() {
        const { open, showTourWelcome } = this.state;
        const classes = classNames('help-tour-backdrop', {
            'welcome-step': showTourWelcome,
            hidden: !open,
        });
        return (
            <div
                className={classes}
                data-tourId={HelpTourTargets.WELCOME}
                aria-hidden={!open}
            >
                <div className="help-tour-searchlight" ref={c => { this.searchlight = c; }} />
                {open && this.tourContent()}
            </div>
        );
    }
}

export default HelpTour;
