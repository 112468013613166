import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import MapLocations from './MapLocations';
import MapZoom from './MapZoom';

const MapControls = ({ mapInstance, className, alignLeft }) => (
    <div className={classNames('map-controls', className)}>
        <MapLocations
            mapInstance={mapInstance}
            alignLeft={alignLeft}
        />
        <MapZoom
            mapInstance={mapInstance}
            alignLeft={alignLeft}
        />
    </div>
);

MapControls.propTypes = {
    mapInstance: PropTypes.object.isRequired,
    className: PropTypes.string,
    alignLeft: PropTypes.bool,
};

MapControls.defaultProps = {
    className: undefined,
    alignLeft: false,
};

export default MapControls;

