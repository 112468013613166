import Tag from '../enums/Tag';

class MetaTable {

    constructor() {
        this._enabled = true;
        this._availableToUser = true;
    }

    get uuid() {
        return this._uuid;
    }

    set uuid(uuid) {
        this._uuid = uuid;
    }

    get surveyName() {
        return this._surveyName;
    }

    set surveyName(surveyName) {
        this._surveyName = surveyName;
    }

    get datasetName() {
        return this._datasetName;
    }

    set datasetName(datasetName) {
        this._datasetName = datasetName;
    }

    // eslint-disable-next-line
    get name() {
        return this._name;
    }

    set name(name) {
        this._name = name;
    }

    get displayName() {
        return this._displayName;
    }

    set displayName(displayName) {
        this._displayName = displayName;
    }

    get title() {
        return this._title;
    }

    set title(title) {
        this._title = title;
    }

    get titleShort() {
        return this._titleShort;
    }

    set titleShort(titleShort) {
        this._titleShort = titleShort.replace('<br />', '');
    }

    get visible() {
        return this._visible;
    }

    set visible(visible) {
        this._visible = visible;
    }

    get universe() {
        return this._universe;
    }

    set universe(universe) {
        this._universe = universe;
    }

    get dollarYear() {
        return this._dollarYear;
    }

    set dollarYear(dollarYear) {
        this._dollarYear = dollarYear;
    }

    get notes() {
        return this._notes;
    }

    set notes(notes) {
        this._notes = notes;
    }

    get categories() {
        return this._categories;
    }

    set categories(categories) {
        this._categories = categories;
    }

    get categoryPriorityOrder() {
        return this._categoryPriorityOrder;
    }

    set categoryPriorityOrder(categoryPriorityOrder) {
        this._categoryPriorityOrder = categoryPriorityOrder;
    }

    get showOnFirstPage() {
        return this._showOnFirstPage;
    }

    set showOnFirstPage(showOnFirstPage) {
        this._showOnFirstPage = showOnFirstPage;
    }

    get defaultFilterSetName() {
        return this._defaultFilterSetName;
    }

    set defaultFilterSetName(defaultFilterSetName) {
        this._defaultFilterSetName = defaultFilterSetName;
    }

    get productTags() {
        return this._productTags;
    }

    set productTags(productTags) {
        this._productTags = productTags;
    }

    get percentBaseMin() {
        return this._percentBaseMin;
    }

    set percentBaseMin(percentBaseMin) {
        this._percentBaseMin = percentBaseMin;
    }

    get suggestedColorPaletteName() {
        return this._suggestedColorPaletteName;
    }

    set suggestedColorPaletteName(suggestedColorPaletteName) {
        this._suggestedColorPaletteName = suggestedColorPaletteName;
    }

    get suggestedColorPaletteInverse() {
        return this._suggestedColorPaletteInverse;
    }

    set suggestedColorPaletteInverse(suggestedColorPaletteInverse) {
        this._suggestedColorPaletteInverse = suggestedColorPaletteInverse;
    }

    get suggestedColorPaletteType() {
        return this._suggestedColorPaletteType;
    }

    set suggestedColorPaletteType(suggestedColorPaletteType) {
        this._suggestedColorPaletteType = suggestedColorPaletteType;
    }

    /** @type {Object.<string, import('./MetaVariable').default>} key is variable name, NOT guid*/
    get variables() {
        return this._variables;
    }

    set variables(variables) {
        this._variables = variables;
    }

    get index() {
        return this._index;
    }

    set index(index) {
        this._index = index;
    }

    get dataset() {
        return this._dataset;
    }

    set dataset(dataset) {
        this._dataset = dataset;
    }

    get enabled() {
        return this._enabled;
    }

    set enabled(enabled) {
        this._enabled = enabled;
    }

    get availableToUser() {
        return this._availableToUser;
    }

    set availableToUser(availableToUser) {
        this._availableToUser = availableToUser;
    }

    get tableMapInfo() {
        return this._tableMapInfo;
    }

    set tableMapInfo(tableMapInfo) {
        this._tableMapInfo = tableMapInfo;
    }

    get variablesAsArray() {
        if (this._variablesArray && this._variablesArray.length === Object.keys(this.variables).length) {
            return this._variablesArray;
        }
        this._variablesArray = new Array(Object.keys(this.variables).length);
        Object.keys(this.variables).forEach(name => {
            this._variablesArray[this.variables[name].index] = this.variables[name];
        });
        return this._variablesArray;
    }

    get datasetAbbreviation() {
        return this._datasetAbbreviation;
    }

    set datasetAbbreviation(datasetAbbreviation) {
        this._datasetAbbreviation = datasetAbbreviation;
    }

    get variableCount() {
        return this._variableCount;
    }

    set variableCount(variableCount) {
        this._variableCount = variableCount;
    }

    toJSON() {
        return {
            uuid: this.uuid,
            surveyName: this.surveyName,
            datasetName: this.datasetName,
            name: this.name,
            displayName: this.displayName,
            title: this.title,
            titleShort: this.titleShort,
            visible: this.visible,
            universe: this.universe,
            dollarYear: this.dollarYear,
            notes: this.notes,
            categories: this.categories,
            categoryPriorityOrder: this.categoryPriorityOrder,
            showOnFirstPage: this.showOnFirstPage,
            defaultFilterSetName: this.defaultFilterSetName,
            productTags: this.productTags,
            percentBaseMin: this.percentBaseMin,
            suggestedColorPaletteName: this.suggestedColorPaletteName,
            suggestedColorPaletteType: this.suggestedColorPaletteType,
            suggestedColorPaletteInverse: this.suggestedColorPaletteInverse,
            tableMapInfo: this.tableMapInfo,
            variables: this.variables,
            datasetAbbreviation: this.datasetAbbreviation,
            variableCount: this.variableCount,
        };
    }

    getVariableByIndex(index) {
        return Object.keys(this.variables)
            .map(variableName => this.variables[variableName])
            .find(variable => variable.index === index);
    }

    /**
     * @param {string} guid GUID of the variable in this table
     */
    getVariableByGuid(guid) {
        return Object.keys(this.variables).map(variableName => this.variables[variableName]).find(variable => variable.uuid === guid);
    }

    hasCategoryWithId(categoryId) {
        return Object.keys(this.categories).map(categoryName => this.categories[categoryName]).find(category => category.id === categoryId) !== undefined;
    }

    getTitle(dollarYear) {
        if (dollarYear === 0) {
            return this.title.replace(Tag.DOLLAR_YEAR, this.dollarYear);
        }
        return this.title.replace(Tag.DOLLAR_YEAR, dollarYear);
    }

    getTitleShort(dollarYear) {
        if (!this.titleShort || this.titleShort.length === 0) return this.titleShort;
        if (dollarYear === 0) {
            return this.titleShort.replace(Tag.DOLLAR_YEAR, this.dollarYear);
        }
        return this.titleShort.replace(Tag.DOLLAR_YEAR, dollarYear);
    }
}

export default MetaTable;
