'use strict';var Actor = require('../util/actor');
var StyleLayerIndex = require('../style/style_layer_index');
var VectorTileWorkerSource = require('./vector_tile_worker_source');
var GeoJSONWorkerSource = require('./geojson_worker_source');
var DotdensityBucket = require('../data/bucket/dotdensity_bucket');
var injectCustomFunctions = require('../util/dragonfly_util').injectCustomFunctions;
var globalRTLTextPlugin = require('./rtl_text_plugin');
var Worker = function Worker(self) {
    var this$1 = this;
    this.self = self;
    this.actor = new Actor(self, this);
    injectCustomFunctions(self);
    this.layerIndexes = {};
    this.workerSourceTypes = {
        vector: VectorTileWorkerSource,
        geojson: GeoJSONWorkerSource
    };
    this.workerSources = {};
    this.self.registerWorkerSource = function (name, WorkerSource) {
        if (this$1.workerSourceTypes[name]) {
            throw new Error('Worker source with name "' + name + '" already registered.');
        }
        this$1.workerSourceTypes[name] = WorkerSource;
    };
    this.self.registerRTLTextPlugin = function (rtlTextPlugin) {
        if (globalRTLTextPlugin.applyArabicShaping || globalRTLTextPlugin.processBidirectionalText) {
            throw new Error('RTL text plugin already registered.');
        }
        globalRTLTextPlugin['applyArabicShaping'] = rtlTextPlugin.applyArabicShaping;
        globalRTLTextPlugin['processBidirectionalText'] = rtlTextPlugin.processBidirectionalText;
    };
};
Worker.prototype.setLayers = function setLayers(mapId, layers, callback) {
    this.getLayerIndex(mapId).replace(layers);
    callback();
};
Worker.prototype.clearDotdensityLayer = function clearDotdensityLayer(mapId, params) {
    if (DotdensityBucket.allDots[mapId]) {
        delete DotdensityBucket.allDots[mapId].layers[params.layerId];
    }
};
Worker.prototype.clearDotdensityLayers = function clearDotdensityLayers(mapId, params) {
    if (DotdensityBucket.allDots[mapId]) {
        for (var layerId in DotdensityBucket.allDots[mapId].layers) {
            if (params.source === DotdensityBucket.allDots[mapId].layers[layerId].source) {
                delete DotdensityBucket.allDots[mapId].layers[layerId];
            }
        }
    }
};
Worker.prototype.updateLayers = function updateLayers(mapId, params, callback) {
    this.getLayerIndex(mapId).update(params.layers, params.removedIds);
    callback();
};
Worker.prototype.loadTile = function loadTile(mapId, params, callback) {
    this.clearDotdensityDictionary(mapId);
    this.getWorkerSource(mapId, params.type).loadTile(params, callback, mapId);
};
Worker.prototype.reloadTile = function reloadTile(mapId, params, callback) {
    this.clearDotdensityDictionary(mapId);
    this.getWorkerSource(mapId, params.type).reloadTile(params, callback, mapId);
};
Worker.prototype.abortTile = function abortTile(mapId, params, callback) {
    this.clearDotdensityDictionary(mapId);
    this.getWorkerSource(mapId, params.type).abortTile(params, callback);
};
Worker.prototype.removeTile = function removeTile(mapId, params, callback) {
    this.clearDotdensityDictionary(mapId);
    this.getWorkerSource(mapId, params.type).removeTile(params, callback);
};
Worker.prototype.removeSource = function removeSource(mapId, params, callback) {
    var worker = this.getWorkerSource(mapId, params.type);
    if (worker.removeSource !== undefined) {
        worker.removeSource(params, callback);
    } else {
        callback();
    }
};
Worker.prototype.cancelTile = function cancelTile(mapId, params) {
    this.clearDotdensityDictionary(mapId);
    var workerSource = this.getWorkerSource(mapId, params.type);
    if (workerSource.cancelTile !== undefined) {
        workerSource.cancelTile(params);
    }
};
Worker.prototype.redoPlacement = function redoPlacement(mapId, params, callback) {
    this.getWorkerSource(mapId, params.type).redoPlacement(params, callback);
};
Worker.prototype.loadWorkerSource = function loadWorkerSource(map, params, callback) {
    try {
        this.self.importScripts(params.url);
        callback();
    } catch (e) {
        callback(e);
    }
};
Worker.prototype.loadRTLTextPlugin = function loadRTLTextPlugin(map, pluginURL, callback) {
    try {
        if (!globalRTLTextPlugin.applyArabicShaping && !globalRTLTextPlugin.processBidirectionalText) {
            this.self.importScripts(pluginURL);
            if (!globalRTLTextPlugin.applyArabicShaping || !globalRTLTextPlugin.processBidirectionalText) {
                callback(new Error('RTL Text Plugin failed to import scripts from ' + pluginURL));
            }
        }
    } catch (e) {
        callback(e);
    }
};
Worker.prototype.getLayerIndex = function getLayerIndex(mapId) {
    var layerIndexes = this.layerIndexes[mapId];
    if (!layerIndexes) {
        layerIndexes = this.layerIndexes[mapId] = new StyleLayerIndex();
    }
    return layerIndexes;
};
Worker.prototype.getWorkerSource = function getWorkerSource(mapId, type) {
    var this$1 = this;
    if (!this.workerSources[mapId]) {
        this.workerSources[mapId] = {};
    }
    if (!this.workerSources[mapId][type]) {
        var actor = {
            send: function (type, data, callback, buffers) {
                this$1.actor.send(type, data, callback, buffers, mapId);
            }
        };
        this.workerSources[mapId][type] = new this.workerSourceTypes[type](actor, this.getLayerIndex(mapId));
    }
    return this.workerSources[mapId][type];
};
Worker.prototype.clearDotdensityDictionary = function clearDotdensityDictionary(mapId) {
    if (DotdensityBucket.allDots[mapId]) {
        for (var layerId in DotdensityBucket.allDots[mapId].layers) {
            delete DotdensityBucket.allDots[mapId].layers[layerId];
        }
    }
};
module.exports = function createWorker(self) {
    return new Worker(self);
};