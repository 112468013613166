import React from 'react';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import BusComponent from '../BusComponent';
import TextInput from '../form/TextInput';
import googleTagManagerEvents from '../../helpers/GoogleTagManagerEventsWrapper';
import { submitMissingFacilityEvents, submitEditedFacilityEvents } from '../../enums/GoogleTagManagerEvents';
import SimpleDropdown from '../dropdown/SimpleDropdown';

const SubmitMissingFacilityButton = googleTagManagerEvents('button', submitMissingFacilityEvents);
const SubmitEditedFacilityButton = googleTagManagerEvents('button', submitEditedFacilityEvents);

const CATEGORIES = [
    'Storage Facilities',
    'Housing Starts',
    'Other',
];

const STATUSES = [
    'Open',
    'Permanently closed',
    'Temporary closed',
    'Never existed',
    'Not opened to the public',
    'Moved to a new location',
    'Duplicate of another place',
    'Other',
];

class SubmitFacilityPopup extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            ...props,
            status: STATUSES[0],
            userDetails: {},
        };
        this.onCategoryChanged = this.onChange.bind(this, 'category');
        this.onNameChanged = this.onChange.bind(this, 'name');
        this.onLocationChanged = this.onChange.bind(this, 'location');
        this.onTelephoneChanged = this.onChange.bind(this, 'telephone');
        this.onWebsiteChanged = this.onChange.bind(this, 'website');
        this.onStatusChanged = this.onChange.bind(this, 'status');
        this.onDetailsChanged = this.onChange.bind(this, 'details');
    }

    componentDidMount() {
        this.bus.on('SUBMIT_FACILITY_DATA_CHANGE_ERROR', this.onError);
        this.bus.on('SUBMIT_FACILITY_DATA_CHANGE_SUCCESS', this.onSuccess);
        this.bus.once('USER_INFO_GET_SUCCESS', this.onUserInfo);
        this.emit('USER_INFO_GET_REQUEST', { source: this });
    }

    componentWillUnmount() {
        this.bus.off('SUBMIT_FACILITY_DATA_CHANGE_ERROR', this.onError);
        this.bus.off('SUBMIT_FACILITY_DATA_CHANGE_SUCCESS', this.onSuccess);
        this.bus.off('USER_INFO_GET_SUCCESS', this.onUserInfo);
        this.unbindGluBusEvents();
    }

    onError = payload => {
        console.error(payload);
    }

    onSuccess = () => {
        this.setState({
            submittedSuccessfully: true,
            disabled: false,
        });
    }

    onUserInfo = (userInfo, source) => {
        if (source && this !== source) return;
        this.setState({
            userDetails: {
                email: userInfo.email,
                name: userInfo._name,
                id: userInfo._userId,
            },
        });
    }

    onClose = () => {
        this.bus.emit('CLOSE_MODAL');
    }

    onReset = () => {
        const { editMode } = this.props;
        if (editMode) {
            this.setState({
                ...this.state.existingFacility,
            });
        } else {
            this.setState({
                status: STATUSES[0],
                location: '',
                telephone: '',
                website: '',
                details: '',
            });
        }
    }

    onSubmitFacility = () => {
        const { type } = this.props;
        const { userDetails, category, name, location, telephone, website, status, details, existingFacility } = this.state;
        const facilityInfo = {
            category,
            name,
            location,
            telephone,
            website,
            details,
        };
        this.bus.emit('SUBMIT_FACILITY_DATA_CHANGE_REQUEST', {
            type,
            userDetails,
            existingFacility,
            facilityInfo,
            suggestedChanges: {
                ...facilityInfo,
                status,
            },
        });
        this.setState({
            disabled: true,
        });
    }

    onChange = (key, value) => {
        this.setState({
            [key]: value,
        });
    };

    renderSuccessfullySubmitted() {
        const { intl } = this.props;
        return (<div className="submit-facility-content flex-column">
            <div className="flex-item">
                <h2>
                    {intl.formatMessage({ id: 'modals.submitFacility.thankYou' })}
                </h2>
                <p>
                    {intl.formatMessage({ id: 'modals.submitFacility.youWillGetEmail' })}
                </p>
            </div>
            <div>
                <button
                    className="btn-raised"
                    onClick={this.onClose}
                >
                    {intl.formatMessage({ id: 'done' })}
                </button>
            </div>
        </div>);
    }

    renderForm() {
        const { intl, editMode } = this.props;
        const { category, name, location, telephone, website, status, details, disabled } = this.state;
        const isSubmitDisabled = !category || category.length < 3
            || !name || name.length < 3
            || !location || location.length < 3;
        return (<div className={classNames('submit-facility-content', 'flex-item', 'flex-column', { disabled })}>
            <div className="input-row">
                <FormattedMessage id="modals.submitFacility.category.label" />
                <SimpleDropdown
                    className=""
                    onItemClick={this.onCategoryChanged}
                    items={CATEGORIES.map(s => ({ id: s, text: s }))}
                    selectedItem={{ id: category, text: category }}
                    ariaLabel={intl.formatMessage({ id: 'modals.submitFacility.category.placeholder' })}
                />
            </div>
            <div className="input-row">
                <FormattedMessage id="modals.submitFacility.name.label" />
                <TextInput
                    placeholder={intl.formatMessage({ id: 'modals.submitFacility.name.placeholder' })}
                    value={name || ''}
                    onChange={this.onNameChanged}
                />
            </div>
            <div className="input-row">
                <FormattedMessage id="modals.submitFacility.location.label" />
                <TextInput
                    placeholder={intl.formatMessage({ id: 'modals.submitFacility.location.placeholder' })}
                    value={location || ''}
                    onChange={this.onLocationChanged}
                />
            </div>
            <div className="input-row">
                <FormattedMessage id="modals.submitFacility.telephone.label" />
                <TextInput
                    placeholder={intl.formatMessage({ id: 'modals.submitFacility.telephone.placeholder' })}
                    value={telephone || ''}
                    onChange={this.onTelephoneChanged}
                />
            </div>
            <div className="input-row">
                <FormattedMessage id="modals.submitFacility.website.label" />
                <TextInput
                    placeholder={intl.formatMessage({ id: 'modals.submitFacility.website.placeholder' })}
                    value={website || ''}
                    type="url"
                    onChange={this.onWebsiteChanged}
                />
            </div>
            <div className="input-row">
                <FormattedMessage id="modals.submitFacility.status.label" />
                <SimpleDropdown
                    className=""
                    onItemClick={this.onStatusChanged}
                    items={STATUSES.map(s => ({ id: s, text: s }))}
                    selectedItem={{ id: status, text: status }}
                    ariaLabel={intl.formatMessage({ id: 'modals.submitFacility.status.placeholder' })}
                />
            </div>
            <div className="input-row">
                <FormattedMessage id="modals.submitFacility.details.label" />
                <TextInput
                    placeholder={intl.formatMessage({ id: 'modals.submitFacility.details.placeholder' })}
                    value={details || ''}
                    onChange={this.onDetailsChanged}
                />
            </div>
            <div className="input-row" />
            <div className="flex-it flex-end">
                <button
                    className="btn-flat btn-flat--gray"
                    onClick={editMode ? this.onReset : this.onClose}
                >
                    {intl.formatMessage({ id: editMode ? 'reset' : 'close' })}
                </button>
                {editMode ? <SubmitEditedFacilityButton
                    className={classNames('btn-flat', { 'btn-flat--disabled': isSubmitDisabled })}
                    disabled={isSubmitDisabled}
                    onClick={this.onSubmitFacility}
                >
                    {intl.formatMessage({ id: 'send' })}
                </SubmitEditedFacilityButton> : <SubmitMissingFacilityButton
                    className={classNames('btn-flat', { 'btn-flat--disabled': isSubmitDisabled })}
                    disabled={isSubmitDisabled}
                    onClick={this.onSubmitFacility}
                >
                    {intl.formatMessage({ id: 'send' })}
                </SubmitMissingFacilityButton>}
            </div>
        </div>);
    }

    render() {
        const { submittedSuccessfully } = this.state;
        if (submittedSuccessfully) {
            return this.renderSuccessfullySubmitted();
        }
        return this.renderForm();
    }
}

SubmitFacilityPopup.propTypes = {
    intl: PropTypes.object.isRequired,
};

export default injectIntl(SubmitFacilityPopup);
