// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import Keys from '../../../enums/Key';

/**
 * @typedef Props
 * @property {(e: KeyboardEvent) => void} onKeyDown
 * @property {(e: InputEvent) => void} onChange
 * @property {() => void} onEnter
 * @property {string} value
 * @property {import('react-intl').intlShape} intl
 *
 * @typedef State
 * @property {string} value
 *
 * @extends {React.Component<Props, State>}
 */
class SearchTextInput extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            value: props.value || '',
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.state.value) {
            this.setState({
                value: nextProps.value,
            });
        }
    }

    onKeyDown = e => {
        const key = e.which || e.keyCode;
        if (key !== undefined && key === Keys.ENTER) {
            this.props.onEnter();
        }
        this.props.onKeyDown(e);
    };

    onChange = e => {
        this.props.onChange(e.target.value);
        this.setState({
            value: e.target.value,
        });
    };

    render() {
        return (
            <input
                aria-label={this.props.intl.formatMessage({
                    id: 'dataBrowser.searchInput',
                })}
                className="input search-input-wrapper__input"
                type="text"
                title={this.state.value}
                placeholder="Search for variables"
                value={this.state.value}
                onChange={this.onChange}
                onKeyDown={this.onKeyDown}
                autoFocus
            />
        );
    }
}

SearchTextInput.propTypes = {
    onKeyDown: PropTypes.func.isRequired,
    onEnter: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    intl: PropTypes.object.isRequired,
};

SearchTextInput.defaultProps = {
    value: '',
};

export default injectIntl(SearchTextInput);
