import React from 'react';
import PropTypes from 'prop-types';

import BusComponent from '../../../BusComponent';
import InteractivitySection from '../InteractivitySection';
import LabelOptionsSection from './LabelOptionsSection';
import PointLayerStyleBySection from './PointLayerStyleBySection';
import LegendOptionsSection from '../LegendOptionsSection';

class PointLayerEditor extends BusComponent {
    constructor(props, context) {
        super(props, context);
    }

    onAddPopupContentColumnChange = column => {
        this.emit('ADD_USER_LAYER_POPUP_COLUMN', {
            layerId: this.props.userDataLayer.id,
            column,
            mapInstanceId: this.props.mapInstanceId,
        });
    };

    onRemovePopupContentColumn = column => {
        this.emit('REMOVE_USER_LAYER_POPUP_COLUMN', {
            layerId: this.props.userDataLayer.id,
            column,
            mapInstanceId: this.props.mapInstanceId,
        });
    };

    onPopupTitleColumnChange = column => {
        this.emit('UPDATE_USER_LAYER_POPUP_TITLE_COLUMN', {
            layerId: this.props.userDataLayer.id,
            column,
            mapInstanceId: this.props.mapInstanceId,
        });
    };

    onLabelingColumnChange = pointLabel => {
        this.emit('UPDATE_USER_LAYER_LABELING_COLUMN', {
            layerId: this.props.userDataLayer.id,
            pointLabel: pointLabel === 'none' ? undefined : pointLabel,
            mapInstanceId: this.props.mapInstanceId,
        });
    };

    onStyleByChange = styleByColumn => {
        const discreteProperty = this.props.userDataLayerMetadata.discreteProperties.find(
            d => d.title === styleByColumn,
        );
        if (!discreteProperty) return;
        this.emit('UPDATE_USER_LAYER_STYLE_BY_OPTION', {
            mapInstanceId: this.props.mapInstanceId,
            layerId: this.props.userDataLayer.id,
            styleByColumn,
            dataValues: discreteProperty.data,
        });
    };

    onStyleRuleMarkerStyleChange = (styleRule, newStyle) => {
        this.emit('UPDATE_USER_LAYER_RULE_MARKER_STYLE', {
            layerId: this.props.userDataLayer.id,
            styleRule,
            newStyle,
            mapInstanceId: this.props.mapInstanceId,
        });
    };

    onStyleRuleVisibilityChange = (styleRule, checked) => {
        this.emit('UPDATE_USER_LAYER_RULE_VISIBILITY', {
            layerId: this.props.userDataLayer.id,
            styleRule,
            isHidden: !checked,
            mapInstanceId: this.props.mapInstanceId,
        });
    };

    onAllowOverlap = checked => {
        this.emit('UPDATE_USER_LAYER_OVERLAP', {
            layerId: this.props.userDataLayer.id,
            allowOverlap: checked,
            mapInstanceId: this.props.mapInstanceId,
        });
    };

    onInteractivityFlagChange = checked => {
        // when turning on the interactivity flag also set the first column as popup title column
        if (checked)
            this.onPopupTitleColumnChange(this.props.userDataLayerMetadata.availableProperties[0]);
        this.emit('UPDATE_USER_LAYER_INTERACTIVITY', {
            layerId: this.props.userDataLayer.id,
            interactive: checked,
            mapInstanceId: this.props.mapInstanceId,
        });
    };

    render() {
        const discreteProperties = this.props.userDataLayerMetadata.discreteProperties.map(
            c => c.title,
        );
        return (
            <div className="point-layer-editor">
                <div className="point-layer-editor__content">
                    <PointLayerStyleBySection
                        valueColumn={this.props.userDataLayer.valueColumn}
                        styleRules={this.props.userDataLayer.styleRules}
                        discreteColumns={discreteProperties}
                        allMarkersSame={this.props.userDataLayer.valueColumn == null}
                        onStyleRuleVisibilityChange={this.onStyleRuleVisibilityChange}
                        onStyleByChange={this.onStyleByChange}
                        onStyleRuleMarkerStyleChange={this.onStyleRuleMarkerStyleChange}
                    />
                    <hr />
                    <LabelOptionsSection
                        labelingColumn={this.props.userDataLayer.pointLabel}
                        onAllowOverlap={this.onAllowOverlap}
                        labelColumns={this.props.userDataLayerMetadata.availableProperties}
                        allowOverlap={this.props.userDataLayer.allowOverlap}
                        onLabelingColumnChange={this.onLabelingColumnChange}
                    />
                    <hr />
                    <InteractivitySection
                        userDataLayer={this.props.userDataLayer}
                        onRemovePopupContentColumn={this.onRemovePopupContentColumn}
                        onPopupTitleColumnChange={this.onPopupTitleColumnChange}
                        onInteractivityFlagChange={this.onInteractivityFlagChange}
                        onAddPopupContentColumnChange={this.onAddPopupContentColumnChange}
                        labelColumns={this.props.userDataLayerMetadata.availableProperties}
                    />
                    <hr />
                    <LegendOptionsSection userDataLayer={this.props.userDataLayer} />
                </div>
            </div>
        );
    }
}

PointLayerEditor.propTypes = {
    userDataLayer: PropTypes.object.isRequired,
};

PointLayerEditor.defaultProps = {};

export default PointLayerEditor;
