// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import PointStyleRuleEditor from './PointStyleRuleEditor';

/**
 * @typedef Props
 * @property {object[]} styleRules
 * @property {boolean} allMarkersSame
 * @property {function} onStyleRuleVisibilityChange
 * @property {function} onStyleRuleMarkerStyleChange
 */

const MarkerPicker = ({
    styleRules,
    allMarkersSame,
    onStyleRuleVisibilityChange,
    onStyleRuleMarkerStyleChange,
}) => {
    const markers = styleRules.map(s => (
        <PointStyleRuleEditor
            key={(s.value === undefined || s.value === null) ? 'no-value' : s.value}
            styleRule={s}
            onStyleRuleVisibilityChange={onStyleRuleVisibilityChange}
            onStyleRuleMarkerStyleChange={onStyleRuleMarkerStyleChange}
            allMarkersSame={allMarkersSame}
        />)
    );

    return (
        <div className="point-layer-editor__markers">
            {markers}
        </div>
    );
};

MarkerPicker.propTypes = {
    styleRules: PropTypes.array.isRequired,
    allMarkersSame: PropTypes.bool.isRequired,
    onStyleRuleVisibilityChange: PropTypes.func.isRequired,
    onStyleRuleMarkerStyleChange: PropTypes.func.isRequired,
};

MarkerPicker.defaultProps = {};

export default MarkerPicker;
