import React from 'react';
import PropTypes from 'prop-types';

import BusComponent from '../BusComponent';
import ApplicationMode from '../../enums/ApplicationMode';
import FrameType from '../../enums/FrameType';
import ApplicationFrameType from '../../enums/ApplicationFrameType';

import UserAvatar from './UserAvatar';
import ShareButton from './ShareButton';
import ExportImageButton from './ExportImageButton';
import LoginButton from './LoginButton';
import SignupButton from './SignupButton';
import HeaderLogo from './HeaderLogo';
import EditProjectButton from './EditProjectButton';
import MoreOptionsButton from './MoreOptionsButton';
import MoreOptionsButtonCondensed from './MoreOptionsButtonCondensed';
import SaveButton from './SaveButton';
import CreateReport from './createReport/CreateReport';
import SearchBoxPanel from '../searchBox/SearchBoxPanel';
import FrameTypeSwitcher from '../mapFrame/FrameTypeSwitcher';
import ReportEditorApplicationHeader from './ReportEditorApplicationHeader';
import UserLocations from '../userLocations/UserLocations';
import AppConfig from '../../appConfig';

/**
 * @typedef Props
 * @property {string} currentFrameType
 * @property {string} currentFrameLockMode
 * @property {boolean} isDingoProject
 * @property {boolean} canSave
 * @property {string} projectTitle
 * @property {boolean} isPublic
 * @property {import('../../objects/MapInstance').default[]} mapInstances
 *
 * @typedef State
 * @property {import('../../objects/UserInfo').default|{}} userInfo
 * @property {boolean} isFrameTypeSwitcherOpen
 * @property {boolean} locationAnalysisInProgress
 * @property {string|undefined} applicationFrameType
 * @property {import('../../objects/MapInstance').default|undefined} reportMapInstance
 *
 * @extends {BusComponent<Props, State>}
 */
class ApplicationHeader extends BusComponent {
    constructor(props, context) {
        super(props, context);

        this.state = {
            userInfo: {},
            isFrameTypeSwitcherOpen: false,
            locationAnalysisInProgress: this.props.mapInstances.some(
                mapInstance => mapInstance.isLocationAnalysisActive,
            ),
            applicationFrameType: undefined,
            reportMapInstance: undefined,
        };

        this.bindGluBusEvents({
            USER_INFO_LOAD_SUCCESS: this.onUserInfo,
            LOG_OUT_SUCCESS: this.onUserInfo,
            APPLICATION_FRAME_TYPE_CHANGED: this.onApplicationFrameTypeChanged,
            REPORT_MODE_ACTIVATED: this.onReportModeActivated,
            REPORT_MODE_DEACTIVATED: this.onReportModeDeactivated,
            LOCATION_ANALYSIS_MODE_STARTED: this.onLocationAnalysisActivated,
            LOCATION_ANALYSIS_MODE_EXITED: this.onLocationAnalysisDeactivated,
        });
    }

    componentDidMount() {
        this.bus.once('USER_INFO_GET_SUCCESS', this.onUserInfo);
        this.emit('USER_INFO_GET_REQUEST', { source: this });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
        this.bus.off('USER_INFO_GET_SUCCESS', this.onUserInfo);
    }

    onLocationAnalysisActivated = () => {
        this.setState({ locationAnalysisInProgress: true });
    };

    onLocationAnalysisDeactivated = () => {
        this.setState({ locationAnalysisInProgress: false });
    };

    /**
     * @param {object} param0
     * @param {import('../../objects/MapInstance').default} param0.mapInstance
     */
    onReportModeActivated = ({ mapInstance }) => {
        this.setState({
            reportMapInstance: mapInstance,
        });
    };

    onReportModeDeactivated = () => {
        this.setState({
            reportMapInstance: undefined,
        });
    };

    /**
     * @param {import('../../objects/UserInfo').default} userInfo
     * @param {BusComponent | undefined} source
     */
    onUserInfo = (userInfo, source) => {
        if (source && this !== source) return;
        this.setState({ userInfo });
    };

    /** @param {boolean} isFrameTypeSwitcherOpen */
    handleSwitcherVisibilityChange = isFrameTypeSwitcherOpen => {
        this.setState({ isFrameTypeSwitcherOpen });
    };

    /** @param {string} applicationFrameType */
    onApplicationFrameTypeChanged(applicationFrameType) {
        this.setState({
            applicationFrameType,
        });
    }

    render() {
        const {
            currentFrameType,
            currentFrameLockMode,
            isDingoProject,
            projectTitle,
            mapInstances,
            canSave,
            shouldAllowSaveAs,
        } = this.props;

        const {
            applicationFrameType,
            userInfo,
            isFrameTypeSwitcherOpen,
            locationAnalysisInProgress,
        } = this.state;

        const { isCondensedLayout, applicationMode } = this.context;

        switch (applicationFrameType) {
            // No ApplicationHeader in export mode
        case ApplicationFrameType.MAP_EXPORT:
            return null;
            // Reduced header in report editor mode
        case ApplicationFrameType.REPORT_EDITOR:
            return (
                <ReportEditorApplicationHeader
                    reportMapInstance={this.state.reportMapInstance}
                    userInfo={userInfo}
                />
            );
        }

        const isApplicationInViewMode =
            applicationMode === ApplicationMode.VIEW;

        // Show only limited header options in mobile view with the rest in more menu
        if (isCondensedLayout && isApplicationInViewMode) {
            return (
                <header className="application-header">
                    <div className="section section--full-width">
                        <HeaderLogo />
                        <SearchBoxPanel
                            mapInstanceIds={mapInstances.map(m => m.id)}
                        />
                    </div>
                    <div className="section flex-fixed margin-right-10">
                        <ExportImageButton mapInstances={mapInstances} />   
                        <MoreOptionsButtonCondensed
                            projectTitle={projectTitle}
                            userInfo={userInfo}
                        />
                    </div>
                </header>
            );
        }

        const isEditor = ApplicationFrameType.isEditor(applicationFrameType);

        const showEditProjectControl =
            isApplicationInViewMode && canSave;
        const showEditProjectTitleControl = !isApplicationInViewMode && canSave;
        const showSaveProjectControl =
            !isApplicationInViewMode && canSave && isDingoProject;

        const showTakeTourControl =
            !isCondensedLayout &&
            !isApplicationInViewMode &&
            !isEditor &&
            !locationAnalysisInProgress;

        const showUpgradeAndSaveProjectControl =
            !isApplicationInViewMode && canSave && !isDingoProject;
        const showSaveAsProjectControl =
            shouldAllowSaveAs && userInfo.isLogged && AppConfig.constants.shouldShowSaveAsButton;

        return (
            <header className="application-header">
                <div className="section section--full-width">
                    <HeaderLogo />
                    {!isApplicationInViewMode && (
                        <SearchBoxPanel
                            mapInstanceIds={mapInstances.map(m => m.id)}
                        />
                    )}
                    {userInfo.isLogged &&
                        !isApplicationInViewMode && (
                            <UserLocations
                                mapInstanceIds={mapInstances.map(m => m.id)}
                            />
                        )}
                </div>
                <div className="section flex-fixed">
                    {AppConfig.constants.headerItems.map(headerItem => {
                        switch (headerItem.type) {
                        case ('FRAME_TYPE_SWITCHER'):
                            return (
                                <FrameTypeSwitcher
                                    key="frameTypeSwitcher"
                                    applicationMode={applicationMode}
                                    frameType={currentFrameType}
                                    lockMode={currentFrameLockMode}
                                    visible={isFrameTypeSwitcherOpen}
                                    onVisibilityChange={this.handleSwitcherVisibilityChange}
                                    mapInstances={mapInstances}
                                />
                            );
                        case ('CREATE_REPORT'):
                            return (applicationMode !== ApplicationMode.VIEW &&
                                userInfo.isLogged && (
                                    <CreateReport key="report" mapInstances={mapInstances} />
                                ));
                        case ('EXPORT_IMAGE'):
                            return (<ExportImageButton key="exportImage" mapInstances={mapInstances} />);
                        case ('SHARE'):
                            return (<ShareButton key="share" />);
                        case ('MORE_OPTIONS'):
                            return (
                                <MoreOptionsButton
                                    key="moreOptions"
                                    showEditProjectTitle={showEditProjectTitleControl}
                                    showProjectSaveAs={showSaveAsProjectControl}
                                    showTakeTour={showTakeTourControl}
                                    projectTitle={projectTitle}
                                    isApplicationInViewMode={isApplicationInViewMode}
                                    userInfo={userInfo}
                                />
                            );
                        case ('EDIT_PROJECT'):
                            return (showEditProjectControl && <EditProjectButton key="editProject" />);
                        case ('SAVE'): {
                            if (headerItem.onlyVisibleToProjectOwner) {
                                return (showSaveProjectControl &&
                                    <SaveButton
                                        key="save"
                                        showUpgrade={showUpgradeAndSaveProjectControl}
                                        showSave
                                        showSaveAs={showSaveAsProjectControl}
                                        projectTitle={projectTitle}
                                        buttonVariant="raised"
                                    />
                                );
                            }

                            return (
                                <SaveButton
                                    key="save"
                                    showUpgrade={showUpgradeAndSaveProjectControl}
                                    showSave={showSaveProjectControl}
                                    showSaveAs={showSaveAsProjectControl}
                                    projectTitle={projectTitle}
                                    buttonVariant="raised"
                                />
                            );
                        }
                        default:
                            return <div />;
                        }
                    }
                )}
                    <div className="user-info-wrapper">
                        {userInfo.isLogged && (
                            <UserAvatar userInfo={userInfo} />
                        )}
                        {!userInfo.isLogged && <LoginButton />}
                        {!userInfo.isLogged && <SignupButton />}
                    </div>
                </div>
            </header>
        );
    }
}

ApplicationHeader.propTypes = {
    currentFrameType: PropTypes.string.isRequired,
    currentFrameLockMode: PropTypes.string.isRequired,
    mapInstances: PropTypes.array.isRequired,
    projectTitle: PropTypes.string,
    isDingoProject: PropTypes.bool,
    canSave: PropTypes.bool,
    isPublic: PropTypes.bool,
    shouldAllowSaveAs: PropTypes.bool,
};

ApplicationHeader.defaultProps = {
    projectTitle: '',
    isPublic: undefined,
    isDingoProject: true,
    canSave: false,
    shouldAllowSaveAs: true,
};

export default ApplicationHeader;
