import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class Marker extends React.Component {
    onMarkerClick = () => {
        if (this.props.onClick) {
            this.props.onClick(this.props.marker);
        }
    }

    render() {
        const { selected, backgroundColor, marker, color } = this.props;
        const classes = classNames('marker-picker__item', { 'marker-picker__item--selected': selected });
        return (
            <div className={classes} onClick={this.onMarkerClick} style={{ backgroundColor }}>
                <i className={classNames('marker-picker__icon', `socex-icon-marker-${marker.id}`)} style={{ color }} />
                <div className="marker-picker__item-highlight" />
            </div>
        );
    }
}

Marker.propTypes = {
    onClick: PropTypes.func.isRequired,
    backgroundColor: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    marker: PropTypes.object,
    selected: PropTypes.bool,
};

Marker.defaultProps = {
    marker: undefined,
    selected: undefined,
};


export default Marker;
