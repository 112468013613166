import React from 'react';
import BusComponent from '../../BusComponent';

import EditPolygon from './EditPolygon';
import EditPolyline from './EditPolyline';
import EditMarker from './EditMarker';
import EditLabel from './EditLabel';
import EditImage from './EditImage';
import EditFlowArrow from './EditFlowArrow';
import EditHotspot from './EditHotspot';

class AnnotationEdit extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            opacity: props.annotation.opacity,
            opacityLabel: props.annotation.opacity * 100,
        };
    }

    onChange = (propsToUpdate, opts) => {
        this.emit('CHANGE_ANNOTATION', {
            id: this.props.annotation.id,
            type: this.props.annotation.type,
            propsToUpdate,
            opts,
        });
    }

    render() {
        let annotationSpecifics;

        switch (this.props.annotation.type) {
        case 'Shape':
        case 'Polygon':
            annotationSpecifics = (<EditPolygon
                annotation={this.props.annotation}
                onChange={this.onChange}
            />);
            break;
        case 'Freehand':
        case 'Polyline':
            annotationSpecifics = (<EditPolyline
                annotation={this.props.annotation}
                onChange={this.onChange}
            />);
            break;
        case 'Marker':
            annotationSpecifics = (<EditMarker
                annotation={this.props.annotation}
                onChange={this.onChange}
            />);
            break;
        case 'Label':
            annotationSpecifics = (<EditLabel
                annotation={this.props.annotation}
                onChange={this.onChange}
            />);
            break;
        case 'FlowArrow':
            annotationSpecifics = (<EditFlowArrow
                annotation={this.props.annotation}
                onChange={this.onChange}
            />);
            break;
        case 'Image':
            annotationSpecifics = (<EditImage
                annotation={this.props.annotation}
                onChange={this.onChange}
            />);
            break;
        case 'Hotspot':
            annotationSpecifics = (<EditHotspot
                annotation={this.props.annotation}
                onChange={this.onChange}
            />);
            break;
        }
        return (
            <div className="annotation-edit flex-it column">
                {annotationSpecifics}
            </div>);
    }
}

export default AnnotationEdit;
