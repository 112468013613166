import React from 'react';
import BusComponent from '../../BusComponent';
import SimpleDropdown from '../../dropdown/SimpleDropdown';
import EmbedFrameSize from '../../../enums/EmbedFrameSize';
import TextInput from '../../form/TextInput';
import AppConfig from '../../../appConfig';
import { copyTextFromElementTargetToClipboard } from '../../../helpers/Util';
import { copyToClipboardButtonEmebedEvents } from '../../../enums/GoogleTagManagerEvents';
import googleTagManagerEvents from '../../../helpers/GoogleTagManagerEventsWrapper';

const CopyCodeButton = googleTagManagerEvents('button', copyToClipboardButtonEmebedEvents);

const FRAME_SIZE_ITEMS =
    Object.keys(EmbedFrameSize)
        .map((size, index) => ({ text: EmbedFrameSize[size].label, id: index }));

class EmbedTab extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            selectedSize: EmbedFrameSize.PRESET_3,
            dropdownVisible: false,
            copiedToClipboard: false,
            customSize: { width: 800, height: 600 },
        };
        this.boundOnFrameSizeClick = this.onFrameSizeClick;
    }

    componentWillMount() {
        if (this.state.copiedToClipboard) {
            this.setState({
                copiedToClipboard: false,
            });
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    onCopyToClipboardClicked = () => {
        this.setState({
            copiedToClipboard: true,
        });
        copyTextFromElementTargetToClipboard(this.code);
        this.timeout = setTimeout(() => {
            this.setState({
                copiedToClipboard: false,
            });
        }, 2000);
        this.emit('SET_ACCESSIBLE_ALERT_TEXT', 'Embed code is copied to clipboard');
    }

    onFrameSizeClick = index => {
        const presetName = Object.keys(EmbedFrameSize)[index];
        this.setState({
            selectedSize: EmbedFrameSize[presetName],
        });
        this.emit('CLOSE_DROPDOWN');
    }

    onSizeChange = () => {
        this.width.value = this.width.value.length > 1 && this.width.value.indexOf('0') === 0 ? this.width.value.substr(1) : this.width.value;
        this.height.value = this.height.value.length > 1 && this.height.value.indexOf('0') === 0 ? this.height.value.substr(1) : this.height.value;
        this.width.value = this.width.value !== undefined && this.width.value !== '' ? this.width.value : 0;
        this.height.value = this.height.value !== undefined && this.height.value !== '' ? this.height.value : 0;
        this.width.value = parseInt(this.width.value, 10) < 0 ? 0 : this.width.value;
        this.height.value = parseInt(this.height.value, 10) < 0 ? 0 : this.height.value;
        this.setState({
            customSize: {
                width: this.width.value,
                height: this.height.value,
            },
        });
    }

    render() {
        let selectedItem = this.state.selectedSize;
        const copyToClipboard = this.state.copiedToClipboard ? 'COPIED' : 'COPY CODE';
        const customSize = selectedItem === EmbedFrameSize.CUSTOM;

        selectedItem = selectedItem === EmbedFrameSize.CUSTOM ? {
            label: EmbedFrameSize.CUSTOM.label,
            width: this.state.customSize.width,
            height: this.state.customSize.height,
        } : selectedItem;
        const code = `<iframe frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="${AppConfig.constants.links.shareRootURL}/${this.props.viewCode}/embed" width="${selectedItem.width}" height="${selectedItem.height}" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe>`;
        const selectedSize = FRAME_SIZE_ITEMS.find(item => item.text.toLowerCase() === selectedItem.label.toLowerCase());

        return (
            <div className="embed-tab flex-it column">
                <span className="tab-label">Embed size</span>
                <div className="size-picker flex-it column center">
                    <SimpleDropdown
                        className="size-picker__dropdown"
                        items={FRAME_SIZE_ITEMS}
                        onItemClick={this.boundOnFrameSizeClick}
                        selectedItem={selectedSize}
                    />
                    {customSize &&
                    <div className="flex-it grow size-picker__size">
                        <div className="flex-it column">
                            <span className="tab-label">Width</span>
                            <input
                                type="number"
                                className="text-input-old share-input"
                                onChange={this.onSizeChange}
                                defaultValue={this.state.customSize.width}
                                ref={width => { this.width = width; }}
                            />
                        </div>
                        <div className="size-picker__separator" />
                        <div className="flex-it column">
                            <span className="tab-label">Height</span>
                            <input
                                type="number"
                                className="text-input-old share-input"
                                onChange={this.onSizeChange}
                                defaultValue={this.state.customSize.height}
                                ref={height => { this.height = height; }}
                            />
                        </div>
                    </div>
                    }
                </div>
                <span className="tab-label">Embed code</span>
                <div className="textarea-container">
                    <TextInput
                        className="flex-one grab-code share-input share-input--readonly"
                        elementRef={c => { this.code = c; }}
                        value={code}
                        readOnly
                        multiline
                    />
                </div>
                <div className="flex-it flex-end">
                    <CopyCodeButton
                        className="btn-flat"
                        onClick={this.onCopyToClipboardClicked}
                    >
                        {copyToClipboard}
                    </CopyCodeButton>
                </div>
            </div>
        );
    }

}

export default EmbedTab;
