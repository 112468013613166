// @ts-check
import wellknown from 'wellknown';
import uuid from 'node-uuid';
import { Marker, Polyline } from '../../objects/annotations';

class SearchData {
    constructor() {
        this._results = undefined;
        this._geoJSONMarkersData = undefined;
        this._geoJSONGeometriesData = undefined;
        this._geometriesSourceId = 'search-geometries';
        this._geometriesSource = undefined;
        this._geometriesLayer = undefined;
    }

    build(searchResults) {
        this.remove();
        this._results = searchResults;
        this._buildSearchGeoJSONData();
        this._buildSearchSources();
        this._buildSearchLayers();
    }

    remove() {
        this._results = undefined;
        this._geoJSONMarkersData = undefined;
        this._geoJSONGeometriesData = undefined;
        this._geometriesSource = undefined;
        this._geometriesLayer = undefined;
    }

    get geometriesSourceId() {
        return this._geometriesSourceId;
    }

    get geometriesSource() {
        return this._geometriesSource;
    }

    get markersSource() {
        return this._markersSource;
    }

    get geometriesLayer() {
        return this._geometriesLayer;
    }

    _buildSearchGeoJSONData() {
        this._geoJSONGeometriesData = {
            type: 'FeatureCollection',
            features: [],
        };
        this._geoJSONMarkersData = {
            type: 'FeatureCollection',
            features: [],
        };

        this._results.forEach(r => {
            if (r.geometry) {
                const parsedGeometry = wellknown.parse(r.geometry);

                if (parsedGeometry.type !== 'Point') {
                    this._geoJSONGeometriesData.features.push({
                        type: 'Feature',
                        geometry: parsedGeometry,
                        properties: {
                            id: r.id,
                            value: r.value,
                            type: r.type,
                        },
                    });
                } else {
                    const existingMarker = this._geoJSONMarkersData.features.find(marker => (
                        marker.properties.type === r.type &&
                        marker.geometry.coordinates[0] === r.point.lng &&
                        marker.geometry.coordinates[1] === r.point.lat
                    ));

                    if (existingMarker) {
                        existingMarker.properties.ids.push(r.id);
                        existingMarker.properties.value = `${existingMarker.properties.value}<br/>${r.value}`;
                    } else {
                        this._geoJSONMarkersData.features.push({
                            type: 'Feature',
                            geometry: {
                                type: 'Point',
                                coordinates: [r.point.lng, r.point.lat],
                            },
                            properties: {
                                ids: [r.id],
                                id: r.id,
                                value: r.value,
                                type: r.type,
                            },
                        });
                    }
                }
            }
        });
    }

    _buildSearchSources() {
        this._geometriesSource = {
            type: 'geojson',
            data: this._geoJSONGeometriesData,
        };
        this._markersSource = {
            type: 'geojson',
            data: this._geoJSONMarkersData,
        };
    }

    _buildSearchLayers() {
        this._geometriesLayer = {
            id: 'search-result-lines-and-polygons',
            type: 'line',
            source: this._geometriesSourceId,
            paint: {
                'line-query-as-fill': false,
                'line-color': 'rgba(30, 192, 255, 1)',
                'line-width': 4,
            },
        };
    }

    _buildLineAnnotation({ coordinates, zoom, title, searchId }) {
        return new Polyline({
            id: uuid.v4(),
            coordinates,
            title,
            description: '',
            includedInLegend: true,
            strokeColor: this._getAnnotationColor(searchId),
            strokeWeight: 'thick',
            createdAtZoomLevel: zoom,
            opacity: 1,
            searchId,
        });
    }

    _buildMarkerAnnotation({ coordinates, zoom, title, searchId }) {
        return new Marker({
            id: uuid.v4(),
            coordinates,
            title,
            markerPathId: 'marker-default',
            description: '',
            fillColor: this._getAnnotationColor(searchId),
            labelVisible: false,
            includedInLegend: true,
            rotationalAngle: 0,
            labelPosition: 'bottom',
            createdAtZoomLevel: zoom,
            textColor: '#000000',
            textSize: 'medium',
            opacity: 1,
            size: 'medium',
            searchId,
        });
    }

    _getAnnotationColor(searchId) {
        let color;
        // We set the color of search result annotations as hex value of searchID%16777215
        // This way the legend items for search saved annotations will be separate items
        if (Number.isInteger(searchId)) {
            color = chroma(searchId % 16777215).toString();
        }
        // Generate a random color if the search id is not an integer (this can
        // happen if the SE geocoder is not used for search)
        return color || `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    }
}

export default SearchData;
