class MetaDataCategory {
    constructor() {
        this._tables = {};
        this._enabled = true;
    }

    get id() {
        return this._id;
    }

    set id(id) {
        this._id = id;
    }

    // eslint-disable-next-line
    get name() {
        return this._name;
    }

    set name(name) {
        this._name = name;
    }

    get iconUrl() {
        return this._iconUrl;
    }

    set iconUrl(iconUrl) {
        this._iconUrl = iconUrl;
    }

    get itemOrder() {
        return this._itemOrder;
    }

    set itemOrder(itemOrder) {
        this._itemOrder = itemOrder;
    }

    get tables() {
        return this._tables;
    }

    set tables(tables) {
        this._tables = tables;
    }

    get enabled() {
        return this._enabled;
    }

    set enabled(enabled) {
        this._enabled = enabled;
    }

    get tablesAsArray() {
        if (this.tables) {
            return Object.keys(this.tables).map(t => this.tables[t]);
        }
        return [];
    }

    clone() {
        const metaDataCategory = new MetaDataCategory();
        metaDataCategory.id = this.id;
        metaDataCategory.name = this.name;
        metaDataCategory.iconUrl = this.iconUrl;
        metaDataCategory.itemOrder = this.itemOrder;
        metaDataCategory.enabled = this.enabled;
        Object.keys(this.tables).map(t => this.tables[t]).forEach(table => {
            metaDataCategory.tables[table.uuid] = table;
        });

        return metaDataCategory;
    }

    toJSON() {
        return {
            id: this.id,
            name: this.name,
            iconUrl: this.iconUrl,
            itemOrder: this.itemOrder,
            tables: this.tables,
        };
    }
}

export default MetaDataCategory;
