/* eslint class-methods-use-this: 0 */

class SymbolRenderer {
    constructor(values) {
        if (values) {
            Object.keys(values).forEach(k => {
                if (this.constructor.prototype.hasOwnProperty(k)) {
                    this[k] = values[k];
                }
            });
        }
    }

    get type() {
        return 'SymbolRenderer';
    }

    get symbols() {
        return this._symbols;
    }

    set symbols(symbols) {
        this._symbols = symbols;
    }

    get visibility() {
        return this._visibility;
    }

    set visibility(visibility) {
        this._visibility = visibility;
    }

    equals(that) {
        return (this.type === that.type &&
        this.visibility.every((vis, index) => vis.equals(that.visibility[index])) &&
        this.symbols.every((sym, index) => sym.equals(that.symbol[index])));
    }

    clone() {
        return new SymbolRenderer({
            symbols: this.symbols.map(sym => sym.clone()),
            visibility: this.visibility.map(vis => vis.clone()),
        });
    }

    toJSON() {
        return {
            type: this.type,
            symbols: this.symbols.map(sym => sym.toJSON()),
            visibility: this.visibility.map(vis => vis.toJSON()),
        };
    }
}

export default SymbolRenderer;
