import React from 'react';
import PropTypes from 'prop-types';
import LegendLayout from '../../enums/LegendLayout';
import Tooltip from '../Tooltip';

const LegendLayoutSwitcher = ({ currentLayout, onSwitchToSimplified, onSwitchToDetailed, tooltipSettings }) => {
    let legendLayoutChangeIcon;
    switch (currentLayout) {
    case LegendLayout.DETAILED:
        legendLayoutChangeIcon = (
            <Tooltip
                {... tooltipSettings}
                overlay={<span className="light-text">Simple view</span>}
            >
                <button className="btn-icon" onClick={onSwitchToSimplified} aria-label="Simple view">
                    <i className="material-icons">view_column</i>
                </button>
            </Tooltip>);
        break;
    case LegendLayout.SIMPLIFIED:
        legendLayoutChangeIcon = (
            <Tooltip
                {... tooltipSettings}
                overlay={<span className="light-text">Detailed view</span>}
            >
                <button className="btn-icon" onClick={onSwitchToDetailed} aria-label="Detailed view">
                    <i className="material-icons">vertical_split</i>
                </button>
            </Tooltip>);
        break;
    }

    return legendLayoutChangeIcon;
};

LegendLayoutSwitcher.propTypes = {
    currentLayout: PropTypes.string.isRequired,
    onSwitchToSimplified: PropTypes.func.isRequired,
    onSwitchToDetailed: PropTypes.func.isRequired,
    tooltipSettings: PropTypes.object.isRequired,
};

export default LegendLayoutSwitcher;
