import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const MapSwitchButton = props => (
    <button
        className={classNames('map-switcher__btn', { 'map-switcher__btn--active': props.active })}
        onClick={() => props.onSwitch(props.value)}
    >
        {props.title}
    </button>
);

MapSwitchButton.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    onSwitch: PropTypes.func.isRequired,
    active: PropTypes.bool,
};

MapSwitchButton.defaultProps = {
    active: false,
};

const MapSwitcher = props => (
    <div className="map-switcher">
        <MapSwitchButton onSwitch={props.onSwitch} title="Map 1" active={props.activeIndex === 0} value={0} />
        <MapSwitchButton onSwitch={props.onSwitch} title="Map 2" active={props.activeIndex === 1} value={1} />
    </div>
);

MapSwitcher.propTypes = {
    activeIndex: PropTypes.number.isRequired,
    onSwitch: PropTypes.func.isRequired,
};

export default MapSwitcher;
