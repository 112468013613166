class FilterRule {
    constructor(values) {
        if (values) {
            Object.keys(values).forEach(k => {
                if (this.constructor.prototype.hasOwnProperty(k)) {
                    this[k] = values[k];
                }
            });
        }
    }

    /** @type {string | undefined} */
    get title() {
        return this._title;
    }

    set title(title) {
        this._title = title;
    }

    /** @returns {number} */
    get zoomMin() {
        return this._zoomMin;
    }

    set zoomMin(zoomMin) {
        this._zoomMin = zoomMin;
    }

    /** @returns {number} */
    get zoomMax() {
        return this._zoomMax;
    }

    set zoomMax(zoomMax) {
        this._zoomMax = zoomMax;
    }

    /** @returns {import('./Filter').default} */
    get filter() {
        return this._filter;
    }

    set filter(filter) {
        this._filter = filter;
    }

    /** @returns {import('./Symbol').default[]} */
    get symbols() {
        return this._symbols;
    }

    set symbols(symbols) {
        this._symbols = symbols;
    }

    get displayInLegend() {
        return this._displayInLegend !== false;
    }

    set displayInLegend(displayInLegend) {
        this._displayInLegend = displayInLegend;
    }

    get fieldQualifiedName() {
        return this._fieldQualifiedName;
    }

    set fieldQualifiedName(fieldQualifiedName) {
        this._fieldQualifiedName = fieldQualifiedName;
    }

    get fieldTableIndex() {
        return this._fieldTableIndex || 0;
    }

    set fieldTableIndex(fieldTableIndex) {
        this._fieldTableIndex = fieldTableIndex;
    }

    get highlighted() {
        return this._highlighted;
    }

    set highlighted(highlighted) {
        this._highlighted = highlighted;
    }

    equals(that) {
        return (
        this.title === that.title &&
        this.zoomMin === that.zoomMin &&
        this.zoomMax === that.zoomMax &&
        this.filter.equals(that.filter) &&
        this.symbols.every((sym, index) => sym.equals(that.symbols[index])) &&
        this.displayInLegend === that.displayInLegend &&
        this.fieldQualifiedName === that.fieldQualifiedName &&
        this.fieldTableIndex === that.fieldTableIndex);
    }

    clone() {
        return new FilterRule({
            title: this.title,
            zoomMin: this.zoomMin,
            zoomMax: this.zoomMax,
            filter: this.filter.clone(),
            symbols: this.symbols.map(sym => sym.clone()),
            displayInLegend: this.displayInLegend,
            fieldQualifiedName: this.fieldQualifiedName,
            fieldTableIndex: this.fieldTableIndex,
        });
    }

    toJSON() {
        return {
            title: this.title,
            zoomMin: this.zoomMin,
            zoomMax: this.zoomMax,
            filter: this.filter.toJSON(),
            symbols: this.symbols.map(sym => sym.toJSON()),
            displayInLegend: this.displayInLegend,
            fieldQualifiedName: this.fieldQualifiedName,
            fieldTableIndex: this.fieldTableIndex,
        };
    }
}

export default FilterRule;
