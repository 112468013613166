import React from 'react';
import PropTypes from 'prop-types';

import BusComponent from '../../BusComponent';

import VisibilityToggle from '../../form/VisibilityToggle';
import GeoJsonLibraryLegendItem from './GeoJsonLibraryLegendItem';
import AppConfig from '../../../appConfig';
import Toggle from '../../form/Toggle';

class GeoJsonLibraryLegend extends BusComponent {
    constructor(props, context) {
        super(props, context);

        this.state = {
            useClusters: false,
            visible: true,
            clusteringDisabled: true,
            items: [...props.geoJsonLegendMetadata.layers],
        };
    }

    componentDidMount() {
        this.bindGluBusEvents({
            SHOULD_USE_CLUSTERS_RESPONSE: this.onShouldUseClustersResponse,
            TOGGLE_CLUSTERING: this.onClusterToggleFromFilterPanel,
            POINTS_GEOJSON: this.onPointsFetched,
        });
        this.emit('SHOULD_USE_CLUSTERS_REQUEST');
    }

    onShouldUseClustersResponse = ({ useClusters }) => {
        this.setState({ useClusters });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    // Toggle clusters when user opens filter panel
    onClusterToggleFromFilterPanel = () => {
        if (this.state.visible && !this.state.useClusters) {
            this.setState({ useClusters: true });
            this.emit('MAP_APPLY_GEO_JSON_CLUSTERING_TOGGLE', {
                useClusters: true,
                mapInstanceId: this.props.mapInstanceId,
            });
        }
    }

    // Turn on/off geoJson layer
    onVisibilityToggle = () => {
        this.emit('UPDATE_GEO_JSON_LAYER_GROUP_VISIBILITY', {
            visible: !this.state.visible,
            mapInstanceId: this.props.mapInstanceId,
        });
        this.setState(state => ({
            visible: !state.visible,
        }));
    };

    // Turn on/off geoJson layer clustering
    onClusterToggle = () => {
        this.emit('MAP_APPLY_GEO_JSON_CLUSTERING_TOGGLE', {
            useClusters: !this.state.useClusters,
            mapInstanceId: this.props.mapInstanceId,
        });
        this.setState(state => ({
            useClusters: !state.useClusters,
        }));
    };

    // Single layer visibility toggle
    onLayerVisibilityToggle = layerId => {
        const newItems = [...this.state.items];
        newItems.forEach(el => {
            if (el.layerId === layerId) el.visible = !el.visible;
        });

        this.emit('UPDATE_GEO_JSON_LAYER_VISIBILITY', {
            featurePropertyNameEquivalentToLayerId: this.props.geoJsonLegendMetadata.featurePropertyNameEquivalentToLayerId,
            layerVisibility: newItems.map(l => ({ layerId: l.layerId, visible: l.visible })),
            mapInstanceId: this.props.mapInstanceId,
        });

        this.setState({
            items: newItems,
        });
    }

    // Clustering button gets enabled only after geoJSON is fetched
    onPointsFetched = () => {
        this.setState({ clusteringDisabled: false });
    };

    render() {
        // The legend metadata can be an empty array
        // In that case do not show anything
        if (this.state.items.length === 0) return null;

        const { title } = this.props.geoJsonLegendMetadata;

        return (
            <div className="geo-json-library-legend">
                <div className="geo-json-library-legend__group">
                    <div className="geo-json-library-legend__title">
                        <span>{title}</span>
                        <div className="flex-it center">
                            <VisibilityToggle
                                onClick={this.onVisibilityToggle}
                                visible={this.state.visible}
                            />
                        </div>
                    </div>
                    {this.state.visible && (
                    <div className="geo-json-library-legend__items">
                        {this.state.items.map(item => (
                            <GeoJsonLibraryLegendItem
                                key={item.layerId}
                                item={item}
                                onLayerVisibilityToggle={this.onLayerVisibilityToggle}
                            />
                            ))
                        }
                        { AppConfig.constants.shouldShowPointClusterToggle &&
                        <div className="geo-json-library-legend__item">
                            <div className="layer-library-classes__item-icon layer-library-classes__item-icon--svg">
                                <img
                                    src={`${AppConfig.constants.assetsBaseURL}/svg/cluster.svg`}
                                    width={24}
                                    height={24}
                                    alt="Clustr toggle icon"
                                />
                            </div>
                            <span className="layer-library-classes__item-label">Clustering</span>
                            <Toggle className="cluster-toggle" checked={this.state.useClusters} onChange={this.onClusterToggle} disabled={this.state.clusteringDisabled} />
                        </div>
                        }
                    </div>
                )}
                </div>
            </div>
        );
    }
}

GeoJsonLibraryLegend.propTypes = {
    geoJsonLegendMetadata: PropTypes.object.isRequired,
    mapInstanceId: PropTypes.string.isRequired,
};

export default GeoJsonLibraryLegend;
