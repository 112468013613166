import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import LocationAnalysisType from '../../enums/LocationAnalysisType';

const LocationAnalysisTypeSelection = ({ onSelectType, isUserPro, disabled }) =>
    (
        <div>
            <div className="flex-it center">
                <div className="location-panel__title">Create an analysis</div>
                {!isUserPro && <div className="badge badge--pro">Pro feature</div>}
            </div>

            <div className={classNames('location-panel__options', { 'location-panel__options--disabled': !isUserPro || disabled })}>
                {/**
                  * We want to filter our Analysis Types because we don't
                  * want to display Geography analysis type on Address search
                  */}
                {Object.keys(LocationAnalysisType).filter(analysisTypeId => !LocationAnalysisType[analysisTypeId].ISGEOGRAPHY).map(key =>
                    <button
                        key={key}
                        className={classNames('btn', { disabled: !isUserPro || disabled })}
                        onClick={() => onSelectType(key)}
                        disabled={!isUserPro || disabled}
                    >
                        <i className="material-icons">{LocationAnalysisType[key].ICON}</i>
                        <span className="location-panel__option-name">
                            {LocationAnalysisType[key].NAME}
                        </span>
                    </button>
                )}
            </div>
        </div>);

LocationAnalysisTypeSelection.propTypes = {
    onSelectType: PropTypes.func.isRequired,
    isUserPro: PropTypes.bool,
    disabled: PropTypes.bool,
};

LocationAnalysisTypeSelection.defaultProps = {
    isUserPro: false,
    disabled: false,
};

export default LocationAnalysisTypeSelection;
