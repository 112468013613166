import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import BusComponent from '../BusComponent';

class Tab extends BusComponent {
    onTabClick = () => {
        this.props.onTabClick(this.props.index);
    };
    render() {
        const { selected, showDivider, icon, tabName, id } = this.props;

        const tabClassNames = classNames(
            'tabs__tab',
            'clickable',
            'flex-center',
            {
                'tabs__tab--selected': selected,
                'tabs_tab--show-divider': showDivider,
            },
        );
        const tab = (
            <button
                onClick={this.onTabClick}
                className={tabClassNames}
                key={tabName}
                role="tab"
                aria-selected={selected}
                id={id}
            >
                <i className="material-icons" aria-hidden>{icon}</i>
                {tabName}
            </button>
        );
        if (showDivider) {
            return (
                <div className="flex-it center">
                    <div className="divider divider--vertical-small divider--btn-separator" />
                    {tab}
                </div>
            );
        }
        return tab;
    }
}

Tab.propTypes = {
    selected: PropTypes.bool,
    tabName: PropTypes.string.isRequired,
    onTabClick: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
};

Tab.defaultProps = {
    selected: false,
};

export default Tab;
