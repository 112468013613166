// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * @callback onClickCallback
 * @param {string} mapInstanceId
 *
 * @typedef Props
 * @property {string} mapInstanceId
 * @property {string} text
 * @property {boolean} selected
 * @property {onClickCallback} onClick Callback that handles the map instance selection
 *
 * @extends {React.Component<Props>}
 */
class MapSelectButton extends React.Component {
    handleClick = () => {
        this.props.onClick(this.props.mapInstanceId);
    };

    render() {
        const classes = classNames('map-select__button btn-void', {
            'btn-raised': this.props.selected,
        });
        return (
            <button className={classes} onClickCapture={this.handleClick}>
                {this.props.text}
            </button>
        );
    }
}

MapSelectButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    mapInstanceId: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    selected: PropTypes.bool.isRequired,
};

export default MapSelectButton;
