import GLU from '../glu2.js/src/index';
import Api from '../apis/Api';
import SearchLimits from '../enums/SearchLimit';
import AppConfig from '../appConfig';

class SearchDataSource extends GLU.DataSource {
    static get name() {
        return 'SearchDataSource';
    }

    static getInstance() {
        return new SearchDataSource();
    }

    constructor() {
        super();
        this._recentSearchTerms = [];
    }

    get recentSearchTerms() {
        return this._recentSearchTerms;
    }

    addRecentSearchTerm(searchTerm) {
        const recentSearchTermIndex = this._recentSearchTerms.findIndex(
            term => term === searchTerm,
        );

        if (recentSearchTermIndex === -1) {
            // add new term
            this._recentSearchTerms.unshift(searchTerm);
        } else {
            // push it to the top
            this._recentSearchTerms.splice(recentSearchTermIndex, 1);
            this._recentSearchTerms.unshift(searchTerm);
        }

        if (
            this._recentSearchTerms.length > SearchLimits.RECENT_SEARCHES_LIMIT
        ) {
            this._recentSearchTerms.pop();
        }

        return this._recentSearchTerms;
    }

    hereAutocomplete({ searchTerm, countryCode }) {
        const query = {
            q: searchTerm,
            in: `countryCode:${countryCode}`,
            apiKey: AppConfig.constants.hereApiKey,
        };

        return new Promise((resolve, reject) => {
            Api.hereAutocomplete.autocomplete({ query }).then(response => {
                const results = {
                    data: response.body.items,
                    query: searchTerm,
                };
                resolve(results);
            }, reject);
        });
    }

    hereGeocode({ searchTerm, countryCode }) {
        const query = {
            q: searchTerm,
            in: `countryCode:${countryCode}`,
            apiKey: AppConfig.constants.hereApiKey,
            limit: AppConfig.constants.geocoder.limit,
        };

        return new Promise((resolve, reject) => {
            Api.hereGeocode.geocode({ query }).then(response => {
                const results = {
                    data: response.body.items,
                    query: searchTerm,
                };
                resolve(results);
            }, reject);
        });
    }

    hereReverseGeocode({ point }, structured = false) {
        const query = {
            at: `${point.lat},${point.lng}`,
            apiKey: AppConfig.constants.hereApiKey,
            lang: 'en-US',
        };

        return new Promise((resolve, reject) => {
            // resolve(query);
            Api.hereReverseGeocode.reverseGeocode({ query }).then(response => {
                if (response.body.items.length) {
                    const location = response.body.items[0];
                    if (location.resultType !== 'administrativeArea') {
                        if (structured) {
                            resolve(location.address);
                        } else {
                            // Return the address label
                            resolve(location.address.label);
                        }
                    }
                }
                // No address found
                resolve(null);
            }, reject);
        });
    }
}

export default SearchDataSource;
