import React from 'react';
import PropTypes from 'prop-types';
import AvailableDataCategory from '../dataBrowser/dataCategories/AvailableDataCategory';

const CategoriesList = ({ dataCategories, onChangeCategory }) =>
    <div className="categories-list vertical-scrollbar flex-it column">
        <div className="categories-list__available">
            {dataCategories.map(dt => (<AvailableDataCategory
                className="data-category--filter"
                key={dt.name}
                dataCategory={dt}
                onSelectedDataCategoryChange={onChangeCategory}
            />))}
        </div>
    </div>;

CategoriesList.propTypes = {
    dataCategories: PropTypes.array,
    onChangeCategory: PropTypes.func,
};

CategoriesList.defaultProps = {
    dataCategories: [],
    onChangeCategory: undefined,
};

export default CategoriesList;
