const FeatureTypes = {
    NONE: 0,
    POINT: 1,
    MULTIPOINT: 2,
    MULTILINESTRING: 3,
    MULTIPOLYGON: 4,
    POINTZ: 5,
    POLYLINEZ: 6,
    POLYGONZ: 7,
    MULTIPOINTZ: 8,
    POINTM: 9,
    POLYLINEM: 10,
    POLYGONM: 11,
    MULTIPOINTM: 12,
    UNKNOWN: -1,
    ZIP: -2,
};

// simplified feature types as per Zlatan's request
const FeatureTypesStringsSimple = {
    NONE: 'NONE',
    POINT: 'POINT',
    MULTIPOINT: 'POINT',
    MULTILINESTRING: 'LINE',
    MULTIPOLYGON: 'POLYGON',
    POINTZ: 'POINT',
    POLYLINEZ: 'LINE',
    POLYGONZ: 'POLYGON',
    MULTIPOINTZ: 'POINT',
    POINTM: 'POINT',
    POLYLINEM: 'LINE',
    POLYGONM: 'POLYGON',
    MULTIPOINTM: 'POINT',
    UNKNOWN: 'UNKNOWN',
    ZIP: 'ZIP',
};

const ALL_FEATURE_TYPES = [
    FeatureTypes.NONE, FeatureTypes.POINT, FeatureTypes.MULTIPOINT,
    FeatureTypes.MULTILINESTRING, FeatureTypes.MULTIPOLYGON,
    FeatureTypes.POINTZ, FeatureTypes.POINTM, FeatureTypes.POLYLINEM,
    FeatureTypes.POLYGONM, FeatureTypes.MULTIPOINTM,
];

const GEOGRAPHY_FEATURE_TYPES = [
    FeatureTypes.POINT, FeatureTypes.MULTIPOINT,
    FeatureTypes.MULTILINESTRING, FeatureTypes.MULTIPOLYGON,
    FeatureTypes.POINTZ, FeatureTypes.POINTM, FeatureTypes.POLYLINEM,
    FeatureTypes.POLYGONM, FeatureTypes.MULTIPOINTM,
];

const POLYGON_FEATURE_TYPES = [
    FeatureTypes.MULTIPOLYGON, FeatureTypes.POLYGONZ, FeatureTypes.POLYGONM,
];
const LINE_FEATURE_TYPES = [
    FeatureTypes.MULTILINESTRING, FeatureTypes.POLYLINEZ, FeatureTypes.POLYLINEM,
];
const POINT_FEATURE_TYPES = [
    FeatureTypes.POINT, FeatureTypes.MULTIPOINT, FeatureTypes.POINTZ, FeatureTypes.MULTIPOINTZ, FeatureTypes.POINTM, FeatureTypes.MULTIPOINTM,
];

function isPolygon(featureType) {
    return POLYGON_FEATURE_TYPES.indexOf(featureType) > -1;
}

function isLine(featureType) {
    return LINE_FEATURE_TYPES.indexOf(featureType) > -1;
}

function isPoint(featureType) {
    return POINT_FEATURE_TYPES.indexOf(featureType) > -1;
}

function isTable(featureType) {
    return featureType === FeatureTypes.NONE;
}

function isUnknown(featureType) {
    return featureType === FeatureTypes.UNKNOWN;
}

function isZip(featureType) {
    return featureType === FeatureTypes.ZIP;
}

function featureTypeToString(featureType) {
    const featureTypeKey = Object.keys(FeatureTypes).find(key => FeatureTypes[key] === featureType);
    return FeatureTypesStringsSimple[featureTypeKey];
}

export {
    FeatureTypes,
    ALL_FEATURE_TYPES,
    GEOGRAPHY_FEATURE_TYPES,
    POLYGON_FEATURE_TYPES,
    LINE_FEATURE_TYPES,
    POINT_FEATURE_TYPES,
    isPolygon,
    isLine,
    isPoint,
    isTable,
    isUnknown,
    isZip,
    featureTypeToString,
};
