// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import MapSelectButton from './MapSelectButton';

/**
 * @callback onChangeCallback
 * @param {string} mapInstanceId
 *
 * @typedef Props
 * @property {string} selectedMapInstanceId
 * @property {string[]} mapInstanceIds
 * @property {onChangeCallback} onChange
 * @property {import('react-intl').intlShape} intl
 *
 * @extends {React.Component<Props>}
 */
class MapSelect extends React.Component {
    handleClick = mapInstanceId => {
        this.props.onChange(mapInstanceId);
    };

    render() {
        const { selectedMapInstanceId, mapInstanceIds, intl } = this.props;
        return (
            <div className="map-select">
                <span>{intl.formatMessage({ id: 'map.mapSelection' })}</span>
                <div className="map-select__buttons">
                    <MapSelectButton
                        text={intl.formatMessage({
                            id: 'map.mapSelectionLeft',
                        })}
                        mapInstanceId={mapInstanceIds[0]}
                        selected={mapInstanceIds[0] === selectedMapInstanceId}
                        onClick={this.handleClick}
                    />
                    <MapSelectButton
                        text={intl.formatMessage({
                            id: 'map.mapSelectionRight',
                        })}
                        mapInstanceId={mapInstanceIds[1]}
                        selected={mapInstanceIds[1] === selectedMapInstanceId}
                        onClick={this.handleClick}
                    />
                </div>
            </div>
        );
    }
}

MapSelect.propTypes = {
    onChange: PropTypes.func.isRequired,
    selectedMapInstanceId: PropTypes.string.isRequired,
    mapInstanceIds: PropTypes.array.isRequired,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(MapSelect);
