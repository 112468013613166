import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class VisualizationTypeListItem extends React.Component {
    onClick = () => {
        this.props.onClick(this.props.type);
    }

    render() {
        const {
            selected,
            disabled,
            title,
            icon,
        } = this.props;

        const buttonClasses = classNames('visualization-type-list-item', {
            'visualization-type-list-item--selected': selected,
            'visualization-type-list-item--disabled': disabled,
        });

        return (
            <button
                className={buttonClasses}
                onClick={this.onClick}
                disabled={disabled}
                aria-label={title}
            >
                <div className="visualization-type-list-item__icon">
                    {icon}
                </div>
                <div className="visualization-type-list-item__title">
                    {title}
                </div>
            </button>);
    }
}

VisualizationTypeListItem.propTypes = {
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    icon: PropTypes.element.isRequired,
    onClick: PropTypes.func.isRequired,
    selected: PropTypes.bool,
    disabled: PropTypes.bool,
};

VisualizationTypeListItem.defaultProps = {
    selected: false,
    disabled: false,
};

export default VisualizationTypeListItem;
