import React from 'react';
import PropTypes from 'prop-types';

import YearSelectionListSingular from './YearSelectionListSingular';
import YearSelectionListRange from './YearSelectionListRange';
import YearSelectionMode from '../../../enums/YearSelectionMode';

const YearSelectionList = props => {
    if (!props.metadata) return null;

    const {
        metadata,
        currentMode,
        currentYearItem,
        currentCompareYearItem,
        highlightYearItem,
        comparableVariables,
        onClick,
    } = props;

    if (currentMode === YearSelectionMode.RANGE) {
        return (
            <YearSelectionListRange
                metadata={metadata}
                comparableVariables={comparableVariables}
                currentYear={currentYearItem}
                compareYear={currentCompareYearItem}
                highlightYear={highlightYearItem}
                onClick={onClick}
            />
        );
    }

    return (
        <YearSelectionListSingular
            metadata={metadata}
            comparableVariables={comparableVariables}
            currentYear={currentYearItem}
            highlightYear={highlightYearItem}
            onClick={onClick}
        />
    );
};

YearSelectionList.propTypes = {
    metadata: PropTypes.object,
    comparableVariables: PropTypes.array.isRequired,
    currentMode: PropTypes.string.isRequired,
    currentYearItem: PropTypes.object,
    currentCompareYearItem: PropTypes.object,
    highlightYearItem: PropTypes.object,
    onClick: PropTypes.func.isRequired,
};

YearSelectionList.defaultProps = {
    metadata: undefined,
    currentYearItem: undefined,
    currentCompareYearItem: undefined,
    highlightYearItem: undefined,
};

export default YearSelectionList;
