class LegendItem {
    constructor(values) {
        if (values) {
            Object.keys(values).forEach(k => {
                if (this.constructor.prototype.hasOwnProperty(k)) {
                    this[k] = values[k];
                }
            });
        }
    }

    get color() {
        return this._color;
    }

    set color(color) {
        this._color = color;
    }

    get included() {
        return this._included;
    }

    set included(included) {
        this._included = included;
    }

    get title() {
        return this._title;
    }

    set title(title) {
        this._title = title;
    }

    get order() {
        return this._order;
    }

    set order(order) {
        this._order = order;
    }

    get icon() {
        return this._icon;
    }

    set icon(icon) {
        this._icon = icon;
    }

    get markerPathId() {
        return this._markerPathId;
    }

    set markerPathId(markerPathId) {
        this._markerPathId = markerPathId;
    }

    get type() {
        return this._type;
    }

    set type(type) {
        this._type = type;
    }

    get compound() {
        return this._compound;
    }

    set compound(compound) {
        this._compound = compound;
    }

    get includedInLegend() {
        return this._includedInLegend;
    }

    set includedInLegend(includedInLegend) {
        this._includedInLegend = includedInLegend;
    }

    equals(that) {
        return (this.color === that.color &&
        this.included === that.included &&
        this.title === that.title &&
        this.order === that.order &&
        this.markerPathId === that.markerPathId &&
        this.type === that.type &&
        this.compound === that.compound &&
        this.icon === that.icon);
    }

    clone() {
        return new LegendItem({
            type: this.type,
            color: this.color,
            markerPathId: this.markerPathId,
            included: this.included,
            title: this.title,
            order: this.order,
            icon: this.icon,
            compound: this.compound,
        });
    }

    toJSON() {
        return ({
            type: this.type,
            markerPathId: this.markerPathId,
            color: this.color,
            included: this.included,
            title: this.title,
            order: this.order,
            icon: this.icon,
            compound: this.compound,
        });
    }
}

export default LegendItem;
