import React from 'react';
import PropTypes from 'prop-types';

import TextInput from '../form/TextInput';
import AnnotationIcon from './AnnotationIcon';

const AnnotationCardHeaderExpanded = ({
    annotation,
    title,
    onTitleChange,
    onToggleExpansion,
}) => (
    <div className="annotation-card__header clickable">
        <div className="flex-it center flex-start grow">
            <AnnotationIcon
                annotation={annotation}
                className="annotation-card__icon"
            />
            <TextInput
                value={title}
                className="annotation-card__title-input grow"
                placeholder="Annotation title"
                onChange={onTitleChange}
            />
        </div>
        <button
            className="btn-icon"
            onClick={onToggleExpansion}
        >
            <i className="material-icons" aria-label="Up arrow icon">arrow_drop_up</i>
        </button>
    </div>
);

AnnotationCardHeaderExpanded.propTypes = {
    annotation: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    onToggleExpansion: PropTypes.func.isRequired,
    onTitleChange: PropTypes.func.isRequired,
};

export default AnnotationCardHeaderExpanded;
