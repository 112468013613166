const baseSize = 0.5;

const markerSize = {
    EXTRASMALL: { value: baseSize * 0.65, label: 'Extra small', shortLabel: 'XS' },
    SMALL: { value: baseSize * 0.85, label: 'Small', shortLabel: 'S' },
    MEDIUM: { value: baseSize * 1.1, label: 'Medium', shortLabel: 'M' },
    LARGE: { value: baseSize * 1.4, label: 'Large', shortLabel: 'L' },
    EXTRALARGE: { value: baseSize * 1.8, label: 'Extra large', shortLabel: 'XL' },
};

export default markerSize;
