/* eslint class-methods-use-this: 0 */

class DotDensityRenderer {
    constructor() {
        this._insufficientDataRuleIndex = -1;
        this._nullDataRuleIndex = -1;
    }

    get type() {
        return 'DotDensityRenderer';
    }

    get fieldList() {
        return this._fieldList;
    }

    set fieldList(fieldList) {
        this._fieldList = fieldList;
    }

    get rules() {
        return this._rules || [];
    }

    set rules(rules) {
        this._rules = rules;
    }

    get visibility() {
        return this._visibility || [];
    }

    set visibility(visibility) {
        this._visibility = visibility;
    }

    get dotValue() {
        return this._dotValue;
    }

    set dotValue(dotValue) {
        this._dotValue = dotValue;
    }

    get dotDensityValueHint() {
        return this._dotDensityValueHint;
    }

    set dotDensityValueHint(dotDensityValueHint) {
        this._dotDensityValueHint = dotDensityValueHint;
    }

    get dotValueFieldName() {
        return this._dotValueFieldName;
    }

    set dotValueFieldName(dotValueFieldName) {
        this._dotValueFieldName = dotValueFieldName;
    }

    get dotValueFieldTableIndex() {
        return this._dotValueFieldTableIndex;
    }

    set dotValueFieldTableIndex(dotValueFieldTableIndex) {
        this._dotValueFieldTableIndex = dotValueFieldTableIndex;
    }

    get symbols() {
        return this._symbols;
    }

    set symbols(symbols) {
        this._symbols = symbols;
    }

    applyColorPalette(colorPalette) {
        this.symbols[0].brushes.forEach((b, index) => {
            b.textColor = colorPalette.colors[index];
        });
    }

    equals(that) {
        return (
        this.fieldList.equals(that.fieldList) &&
        this.rules.every((rule, index) => rule.equals(that.rules[index])) &&
        this.visibility.every((vis, index) => vis.equals(that.visibility[index])) &&
        this.type === that.type &&
        this.dotValue === that.dotValue &&
        this.dotValueFieldName === that.dotValueFieldName &&
        this.dotValueFieldTableIndex === that.dotValueFieldTableIndex &&
        this.symbols.every((sym, index) => sym.equals(that.symbols[index])));
    }

    clone() {
        const dotDensityRenderer = new DotDensityRenderer();
        dotDensityRenderer.fieldList = this.fieldList.clone();
        dotDensityRenderer.rules = this.rules.map(rule => rule.clone());
        dotDensityRenderer.visibility = this.visibility.map(vis => vis.clone());
        dotDensityRenderer.dotValue = this.dotValue;
        dotDensityRenderer.dotValueFieldName = this.dotValueFieldName;
        dotDensityRenderer.dotValueFieldTableIndex = this.dotValueFieldTableIndex;
        dotDensityRenderer.symbols = this.symbols.map(sym => sym.clone());
        dotDensityRenderer.dotDensityValueHint = this.dotDensityValueHint;
        return dotDensityRenderer;
    }

    toJSON() {
        return {
            type: this.type,
            fieldList: this.fieldList.toJSON(),
            rules: this.rules.map(rule => rule.toJSON()),
            visibility: this.visibility.map(vis => vis.toJSON()),
            dotValue: this.dotValue,
            dotDensityValueHint: this.dotDensityValueHint,
            dotValueFieldName: this.dotValueFieldName,
            dotValueFieldTableIndex: this.dotValueFieldTableIndex,
            symbols: this.symbols.map(symbol => symbol.toJSON()),
        };
    }
}

export default DotDensityRenderer;
