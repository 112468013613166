'use strict';var EXTENT = require('../data/extent');
var OpacityState = require('./opacity_state');
var roundingFactor = 512 / EXTENT / 2;
var TileLayerIndex = function TileLayerIndex(coord, sourceMaxZoom, symbolInstances) {
    var this$1 = this;
    this.coord = coord;
    this.sourceMaxZoom = sourceMaxZoom;
    this.symbolInstances = {};
    for (var i = 0, list = symbolInstances; i < list.length; i += 1) {
        var symbolInstance = list[i];
        var key = symbolInstance.key;
        if (!this$1.symbolInstances[key]) {
            this$1.symbolInstances[key] = [];
        }
        this$1.symbolInstances[key].push({
            instance: symbolInstance,
            coordinates: this$1.getScaledCoordinates(symbolInstance, coord)
        });
        symbolInstance.isDuplicate = false;
        symbolInstance.textOpacityState = new OpacityState();
        symbolInstance.iconOpacityState = new OpacityState();
    }
};
TileLayerIndex.prototype.getScaledCoordinates = function getScaledCoordinates(symbolInstance, childTileCoord) {
    var zDifference = Math.min(this.sourceMaxZoom, childTileCoord.z) - Math.min(this.sourceMaxZoom, this.coord.z);
    var scale = roundingFactor / (1 << zDifference);
    var anchor = symbolInstance.anchor;
    return {
        x: Math.floor((childTileCoord.x * EXTENT + anchor.x) * scale),
        y: Math.floor((childTileCoord.y * EXTENT + anchor.y) * scale)
    };
};
TileLayerIndex.prototype.getMatchingSymbol = function getMatchingSymbol(childTileSymbol, childTileCoord) {
    var this$1 = this;
    if (!this.symbolInstances[childTileSymbol.key]) {
        return;
    }
    var childTileSymbolCoordinates = this.getScaledCoordinates(childTileSymbol, childTileCoord);
    for (var i = 0, list = this$1.symbolInstances[childTileSymbol.key]; i < list.length; i += 1) {
        var thisTileSymbol = list[i];
        if (Math.abs(thisTileSymbol.coordinates.x - childTileSymbolCoordinates.x) <= 1 && Math.abs(thisTileSymbol.coordinates.y - childTileSymbolCoordinates.y) <= 1) {
            return thisTileSymbol.instance;
        }
    }
};
TileLayerIndex.prototype.forEachSymbolInstance = function forEachSymbolInstance(fn) {
    var this$1 = this;
    for (var key in this$1.symbolInstances) {
        var keyedSymbolInstances = this$1.symbolInstances[key];
        for (var i = 0, list = keyedSymbolInstances; i < list.length; i += 1) {
            var symbolInstance = list[i];
            fn(symbolInstance.instance);
        }
    }
};
var CrossTileSymbolLayerIndex = function CrossTileSymbolLayerIndex() {
    this.indexes = {};
};
CrossTileSymbolLayerIndex.prototype.addTile = function addTile(coord, sourceMaxZoom, symbolInstances) {
    var this$1 = this;
    var minZoom = 25;
    var maxZoom = 0;
    for (var zoom in this$1.indexes) {
        minZoom = Math.min(zoom, minZoom);
        maxZoom = Math.max(zoom, maxZoom);
    }
    var tileIndex = new TileLayerIndex(coord, sourceMaxZoom, symbolInstances);
    for (var z = maxZoom; z > coord.z; z--) {
        var zoomIndexes = this$1.indexes[z];
        for (var id in zoomIndexes) {
            var childIndex = zoomIndexes[id];
            if (!childIndex.coord.isChildOf(coord, sourceMaxZoom)) {
                continue;
            }
            this$1.blockLabels(childIndex, tileIndex, false);
        }
    }
    var oldTileIndex = this.indexes[coord.z] && this.indexes[coord.z][coord.id];
    if (oldTileIndex) {
        this.blockLabels(tileIndex, oldTileIndex, true);
        this.removeTile(coord, sourceMaxZoom);
    }
    for (var z$1 = coord.z - 1; z$1 >= minZoom; z$1--) {
        var parentCoord = coord.scaledTo(z$1, sourceMaxZoom);
        var parentIndex = this$1.indexes[z$1] && this$1.indexes[z$1][parentCoord.id];
        if (parentIndex) {
            this$1.blockLabels(tileIndex, parentIndex, true);
        }
    }
    if (this.indexes[coord.z] === undefined) {
        this.indexes[coord.z] = {};
    }
    this.indexes[coord.z][coord.id] = tileIndex;
};
CrossTileSymbolLayerIndex.prototype.removeTile = function removeTile(coord, sourceMaxZoom) {
    var this$1 = this;
    var removedIndex = this.indexes[coord.z][coord.id];
    delete this.indexes[coord.z][coord.id];
    if (Object.keys(this.indexes[coord.z]).length === 0) {
        delete this.indexes[coord.z];
    }
    var minZoom = Math.min.apply(Math, [25].concat(Object.keys(this.indexes)));
    var parentCoord = coord;
    for (var z = coord.z - 1; z >= minZoom; z--) {
        parentCoord = parentCoord.parent(sourceMaxZoom);
        if (!parentCoord) {
            break;
        }
        var parentIndex = this$1.indexes[z] && this$1.indexes[z][parentCoord.id];
        if (parentIndex) {
            this$1.unblockLabels(removedIndex, parentIndex);
        }
    }
};
CrossTileSymbolLayerIndex.prototype.blockLabels = function blockLabels(childIndex, parentIndex, copyParentOpacity) {
    childIndex.forEachSymbolInstance(function (symbolInstance) {
        if (!symbolInstance.isDuplicate) {
            var parentSymbolInstance = parentIndex.getMatchingSymbol(symbolInstance, childIndex.coord);
            if (parentSymbolInstance !== undefined) {
                if (!parentSymbolInstance.isDuplicate) {
                    parentSymbolInstance.isDuplicate = true;
                    if (copyParentOpacity) {
                        symbolInstance.textOpacityState = parentSymbolInstance.textOpacityState.clone();
                        symbolInstance.iconOpacityState = parentSymbolInstance.iconOpacityState.clone();
                    }
                }
            }
        }
    });
};
CrossTileSymbolLayerIndex.prototype.unblockLabels = function unblockLabels(childIndex, parentIndex) {
    childIndex.forEachSymbolInstance(function (symbolInstance) {
        if (!symbolInstance.isDuplicate) {
            var parentSymbolInstance = parentIndex.getMatchingSymbol(symbolInstance, childIndex.coord);
            if (parentSymbolInstance !== undefined) {
                parentSymbolInstance.isDuplicate = false;
                parentSymbolInstance.textOpacityState = symbolInstance.textOpacityState.clone();
                parentSymbolInstance.iconOpacityState = symbolInstance.iconOpacityState.clone();
                symbolInstance.isDuplicate = true;
            }
        }
    });
};
var CrossTileSymbolIndex = function CrossTileSymbolIndex() {
    this.layerIndexes = {};
};
CrossTileSymbolIndex.prototype.addTileLayer = function addTileLayer(layerId, coord, sourceMaxZoom, symbolInstances) {
    var layerIndex = this.layerIndexes[layerId];
    if (layerIndex === undefined) {
        layerIndex = this.layerIndexes[layerId] = new CrossTileSymbolLayerIndex();
    }
    layerIndex.addTile(coord, sourceMaxZoom, symbolInstances);
};
CrossTileSymbolIndex.prototype.removeTileLayer = function removeTileLayer(layerId, coord, sourceMaxZoom) {
    var layerIndex = this.layerIndexes[layerId];
    if (layerIndex !== undefined) {
        layerIndex.removeTile(coord, sourceMaxZoom);
    }
};
module.exports = CrossTileSymbolIndex;