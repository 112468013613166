import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { injectIntl } from 'react-intl';

import { createReportButtonEvents } from '../../enums/GoogleTagManagerEvents';
import googleTagManagerEvents from '../../helpers/GoogleTagManagerEventsWrapper';
import BusComponent from '../BusComponent';

const ButtonWrapper = googleTagManagerEvents(
    'button',
    createReportButtonEvents,
);

/**
 * @typedef Props
 * @property {boolean} disabled
 * @property {import('react-intl').intlShape} intl
 * @property {() => void} onCreateReport
 * @property {{ id: string, text: string }} selectedSurveyItem
 * @property {string[]} selectedTableGuids
 * @property {string[]} selectedGeographies
 *
 * @extends {BusComponent<Props>}
 */
class CreateReportButton extends BusComponent {
    componentDidMount() {
        this.bindGluBusEvents({
            UPDATE_GTM_CREATE_EVENT: this.onEventUpdate,
        });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    /**
     * @param {object} param0
     * @param {string} param0.label
     */
    onEventUpdate = ({ label }) => {
        this.createReportButton.setCustomAttribute('gtm-eventl', label);
    };

    onCreateReport = () => {
        this.props.onCreateReport();

        this.emit('GENERATE_REPORT_PAYLOAD', {
            selectedSurveyItem: this.props.selectedSurveyItem,
            selectedTableGuids: this.props.selectedTableGuids,
            selectedGeographies: this.props.selectedGeographies,
        });
    };

    render() {
        return (
            <ButtonWrapper
                ref={c => {
                    this.createReportButton = c;
                }}
                className={classnames('btn-raised report-options__btn', {
                    'btn-raised--disabled': this.props.disabled,
                })}
                key="create-btn"
                onClick={this.onCreateReport}
                disabled={this.props.disabled}
            >
                {this.props.intl.formatMessage({ id: 'create' })}
            </ButtonWrapper>
        );
    }
}

CreateReportButton.propTypes = {
    intl: PropTypes.object.isRequired,
    disabled: PropTypes.bool.isRequired,
    onCreateReport: PropTypes.func.isRequired,
    selectedSurveyItem: PropTypes.object.isRequired,
    selectedTableGuids: PropTypes.array.isRequired,
    selectedGeographies: PropTypes.array.isRequired,
};

export default injectIntl(CreateReportButton);
