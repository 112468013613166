import React from 'react';
import BusComponent from '../BusComponent';
import PropTypes from 'prop-types';

/**
 * @typedef Props
 * @property {import('../../').LocationAnalysisType} analysisType
 * @property {number[]} values
 *
 * @extends {BusComponent<Props, {}>}
 */
class AddNewCustomAnalysisValue extends BusComponent {
    render() {
        const { analysisType, values } = this.props;
        return (
            <button
                className="btn-chip location-analysis__btn location-analysis__btn--add-new-value"
                onClick={() => this.emit('ADD_LOCATION_ANALYSIS_VALUE_POPUP_REQUEST', { analysisType, values })}
            >
                <i className="material-icons">add</i>
            </button>
        );
    }
}

AddNewCustomAnalysisValue.propTypes = {
    values: PropTypes.array.isRequired,
    analysisType: PropTypes.object.isRequired,
};

export default AddNewCustomAnalysisValue;
