// @ts-check
import React from 'react';
import { injectIntl } from 'react-intl';

import BusComponent from '../BusComponent';
import Tooltip from '../Tooltip';

/**
 * @typedef Props
 * @property {import('react-intl').intlShape} intl
 * @property {string} tooltipId
 * @property {import('../../objects/MapInstance').default} mapInstanceId
 *
 * @extends BusComponent<Props>
 */
class LocationAnalysisCloseButton extends BusComponent {
    onClick = () => {
        this.emit('CLOSE_LOCATION_ANALYSIS_REQUEST', {
            mapInstanceId: this.props.mapInstanceId,
        });
    };

    render() {
        return (
            <Tooltip
                placement="bottom"
                mouseEnterDelay={0.2}
                mouseLeaveDelay={0}
                overlay={this.props.intl.formatMessage({
                    id: this.props.tooltipId,
                })}
            >
                <button
                    className="material-icons btn-flat-icon btn-close"
                    onClick={this.onClick}
                    aria-label={this.props.intl.formatMessage({
                        id: this.props.tooltipId,
                    })}
                >
                    close
                </button>
            </Tooltip>
        );
    }
}

export default injectIntl(LocationAnalysisCloseButton);
