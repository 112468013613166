/* eslint react/no-array-index-key: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { parseFieldName } from '../../../helpers/Util';

const DotDensityLegendItems = props => {
    const dotLegendItems = [];
    if (props.isMultiVariable) {
        props.renderer.dotValueFieldNames.forEach((dotValueFieldName, idx) => {
            const dotFieldName = parseFieldName(dotValueFieldName).variableGuid;
            const dotField = props.renderer.fieldList.fields.find(field => field.fieldName === dotFieldName);
            const title = dotField ? dotField.label : '';
            dotLegendItems.push(<div key={idx} className="flex-it start dot-density-legend__item">
                <div
                    className="dot-density-legend__dot"
                    style={{ backgroundColor: props.renderer.symbols[idx].brushes[0].textColor }}
                />
                <div className="dot-density-legend__text">{title}</div>
            </div>);
        });
    } else {
        const infoFieldName = parseFieldName(props.renderer.dotValueFieldName).variableGuid;
        const dotField = props.renderer.fieldList.fields.find(field => field.fieldName === infoFieldName);
        const title = dotField ? dotField.label : '';
        dotLegendItems.push(<div key="single" className="flex-it start">
            <div className="dot-density-legend__dot" style={{ backgroundColor: props.renderer.symbols[0].brushes[0].textColor }} />
            <div className="dot-density-legend__text">{title}</div>
        </div>);
    }
    return (<div className="dot-density-legend__items">
        {dotLegendItems}
    </div>);
};


DotDensityLegendItems.propTypes = {
    renderer: PropTypes.object.isRequired,
    isMultiVariable: PropTypes.bool.isRequired,
};

export default DotDensityLegendItems;
