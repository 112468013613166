import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import MapSelectionType from '../../enums/MapSelectionType';
import AppConfig from '../../appConfig';

class Hints extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = { minimized: false };
    }

    onToggleVisibility = () => {
        this.setState({ minimized: !this.state.minimized });
    };

    render() {
        let line, shortcuts;

        switch (this.props.type) {
        case MapSelectionType.POINT:
            line = this.props.intl.formatMessage({ id: 'hints.clickToSelectOrDeselectGeographies' });
            break;
        case MapSelectionType.BOX:
            line = this.props.intl.formatMessage({ id: 'hints.clickAndDragToDrawOnTheMap' });
            shortcuts = [
                {
                    button: 'Space',
                    hints: [this.props.intl.formatMessage({ id: 'hints.panMap' }), this.props.intl.formatMessage({ id: 'hints.moveSelectionWhileDrawing' })],
                },
                { button: 'Esc', hints: [this.props.intl.formatMessage({ id: 'hints.cancelSelection' })] },
            ];
            break;
        case MapSelectionType.CIRCLE:
            line = this.props.intl.formatMessage({ id: 'hints.clickOnTheMapToEnterRadius' });
            shortcuts = [
                {
                    button: 'Space',
                    hints: [this.props.intl.formatMessage({ id: 'hints.panMap' }), this.props.intl.formatMessage({ id: 'hints.moveSelectionWhileDrawing' })],
                },
                { button: 'Esc', hints: [this.props.intl.formatMessage({ id: 'hints.cancelSelection' })] },
            ];
            break;
        case MapSelectionType.POLYGON:
            line = this.props.intl.formatMessage({ id: 'hints.startDrawingAShapeDouble' });
            shortcuts = [
                {
                    button: 'Space',
                    hints: [this.props.intl.formatMessage({ id: 'hints.panMap' }), this.props.intl.formatMessage({ id: 'hints.moveSelectionWhileDrawing' })],
                },
                { button: 'Esc', hints: [this.props.intl.formatMessage({ id: 'hints.cancelSelection' })] },
            ];
            break;
        case MapSelectionType.LINE:
            line = this.props.intl.formatMessage({ id: 'hints.startDrawingALineDouble' });
            shortcuts = [
                {
                    button: 'Space',
                    hints: [this.props.intl.formatMessage({ id: 'hints.panMap' }), this.props.intl.formatMessage({ id: 'hints.moveSelectionWhileDrawing' })],
                },
                { button: 'Esc', hints: [this.props.intl.formatMessage({ id: 'hints.cancelSelection' })] },
            ];
            break;
        default:
            return null;
        }

        const shortcutsElement =
            shortcuts &&
            shortcuts.map(shortcut => (
                <div
                    key={shortcut.button}
                    className="shortcut flex-it center space-between"
                >
                    <div className="shortcut__hint">
                        <div className="shortcut__hint-title">
                            {shortcut.hints[0]}
                        </div>
                        <div className="shortcut__hint-description">
                            {shortcut.hints[1]}
                        </div>
                    </div>
                    <div className="shortcut__hint-button">
                        {shortcut.button}
                    </div>
                </div>
            ));

        const selectionClassName = classNames('selection-help', {
            'selection-help--minimized': this.state.minimized,
        });

        if (this.state.minimized) {
            return (
                <div
                    onClick={this.onToggleVisibility}
                    className={selectionClassName}
                >
                    <img
                        className="draw-annotation-helper_image"
                        src={`${AppConfig.constants.assetsBaseURL}/icons/annotationHelp/annotations_helper.svg`}
                        alt={this.props.intl.formatMessage({ id: 'dataBrowser.annotationHelp' })}
                    />
                </div>
            );
        }

        return (
            <div className={selectionClassName}>
                <div className="flex-it center space-between selection-help__header">
                    <div className="body-3">
                        <FormattedMessage id="hints.hint" />
                    </div>
                    <button className="btn-flat-icon" onClick={this.onToggleVisibility}>
                        <i
                            className="material-icons"
                            aria-label={this.props.intl.formatMessage({ id: 'showHide.hide.hint' })}
                        >
                            keyboard_arrow_down
                        </i>
                    </button>
                </div>
                <div className="selection-help__content">
                    <h6>{line}</h6>
                </div>
                {shortcuts && (
                    <div className="selection-help__buttons">
                        <div className="body-3">
                            <FormattedMessage id="hints.keyboardShortcuts" />
                        </div>
                        {shortcutsElement}
                    </div>
                )}
            </div>
        );
    }
}

Hints.propTypes = {
    type: PropTypes.number.isRequired,
    intl: PropTypes.object.isRequired,
};

Hints.defaultProps = {};

export default injectIntl(Hints);
