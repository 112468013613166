import React from 'react';
import BusComponent from '../../BusComponent';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import LayerLibraryGroupCardHeader from './LayerLibraryGroupCardHeader';
import LayerLibraryGroupCardLayers from './LayerLibraryGroupCardLayers';

class LayerLibraryGroupCard extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            expanded: true,
        };
    }

    onToggleGroupVisibility = visible => {
        this.props.onToggleGroupVisibility(this.props.id, visible);
    }

    onToggleLayerVisibility = (id, visible) => {
        this.props.onToggleLayerVisibility(id, this.props.id, visible);
    }

    onToggleExpanded = () => {
        this.setState({ expanded: !this.state.expanded });
    }

    render() {
        const { expanded } = this.state;
        const { id, title, layers } = this.props;

        const incompleteSelection = layers.some(l => l.visible) && layers.some(l => l.visible === false);
        const completeSelection = layers.every(l => l.visible);

        let visibility = completeSelection;
        if (incompleteSelection) {
            visibility = undefined;
        }

        const layerGroupClasses = classNames('map-library__card flex-it column grow', {
            'map-library__card--expanded': expanded,
        });

        return (<div className={layerGroupClasses}>
            <LayerLibraryGroupCardHeader
                id={id}
                title={title}
                checked={visibility}
                onToggleExpanded={this.onToggleExpanded}
                onToggleVisibility={this.onToggleGroupVisibility}
            />
            {expanded && <LayerLibraryGroupCardLayers
                layers={layers}
                onToggleVisibility={this.onToggleLayerVisibility}
            />}
        </div>);
    }
}

LayerLibraryGroupCard.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    layers: PropTypes.array.isRequired,
    onToggleGroupVisibility: PropTypes.func.isRequired,
    onToggleLayerVisibility: PropTypes.func.isRequired,
};

export default LayerLibraryGroupCard;
