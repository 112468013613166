class CategoryFilters {

    // eslint-disable-next-line
    get name() {
        return this._name;
    }

    set name(name) {
        this._name = name;
    }

    get valueFormat() {
        return this._valueFormat;
    }

    set valueFormat(valueFormat) {
        this._valueFormat = valueFormat;
    }

    /** @returns {import('./FilterSet').default[]} */
    get filterSets() {
        return this._filterSets;
    }

    set filterSets(filterSets) {
        this._filterSets = filterSets;
    }

    toJSON() {
        return {
            name: this.name,
            valueFormat: this.valueFormat,
            filterSets: this.filterSets,
        };
    }
}

export default CategoryFilters;
