import uuid from 'node-uuid';
import DataFilter from './dataFilter/DataFilter';
import BaseMap from '../enums/BaseMapTypes';

class MapInstance {
    constructor(values) {
        if (values) {
            Object.keys(values).forEach(k => {
                if (this.constructor.prototype.hasOwnProperty(k)) {
                    this[k] = values[k];
                }
            });
        }
        this._layerOverrides = this._layerOverrides || {};
        this._dataGeoFilters = this._dataGeoFilters || {};
        this._dataFilter = this._dataFilter || new DataFilter();
        this._id = uuid.v4();
        /** @type {import('../objects/LocationAnalysisItem').default[]} */
        this._locationAnalysisItems = [];
    }

    /** @type {string} uuid of map instance */
    get id() {
        return this._id;
    }

    /** @type {string|undefined} */
    get metadataGroupId() {
        return this._metadataGroupId;
    }

    set metadataGroupId(metadataGroupId) {
        this._metadataGroupId = metadataGroupId;
    }

    get reportParentMapInstanceId() {
        return this._reportParentMapInstanceId;
    }

    set reportParentMapInstanceId(reportParentMapInstanceId) {
        this._reportParentMapInstanceId = reportParentMapInstanceId;
    }

    /** This property is actually almost the same as as surveyName except
     * in case of COT maps when it becomes ASC_COT, the "base map" for COT
     * In project xml it is saved as attribute currentMapContextId, and if not set it is
     * read from data theme just like the property surveyName
     * @type {string} currentMapId */
    get currentMapId() {
        return this._currentMapId;
    }

    set currentMapId(currentMapId) {
        this._currentMapId = currentMapId;
    }

    /** @type {string} surveyCode */
    get surveyName() {
        return this.dataTheme.surveyName;
    }

    get isSatelliteVisible() {
        return !!this._isSatelliteVisible;
    }

    set isSatelliteVisible(isSatelliteVisible) {
        this._isSatelliteVisible = isSatelliteVisible;
    }

    get isStreetMapVisible() {
        return (
            this._libraryDataLayers &&
            this._libraryDataLayers.some(
                dl => dl.id === BaseMap.STREET && dl.visible,
            )
        );
    }

    get satelliteDataOverlaySatelliteHasColor() {
        return this._satelliteDataOverlaySatelliteHasColor;
    }

    set satelliteDataOverlaySatelliteHasColor(
        satelliteDataOverlaySatelliteHasColor,
    ) {
        this._satelliteDataOverlaySatelliteHasColor =
            satelliteDataOverlaySatelliteHasColor;
    }

    get satelliteDataOverlayDataOpacity() {
        return this._satelliteDataOverlayDataOpacity;
    }

    set satelliteDataOverlayDataOpacity(satelliteDataOverlayDataOpacity) {
        this._satelliteDataOverlayDataOpacity = satelliteDataOverlayDataOpacity;
    }

    set annotations(annotations) {
        this._annotations = annotations;
    }

    get annotations() {
        return this._annotations;
    }

    get userEnteredTitle() {
        return this._userEnteredTitle;
    }

    set userEnteredTitle(userEnteredTitle) {
        this._userEnteredTitle = userEnteredTitle;
    }

    get scaleUnit() {
        return this._scaleUnit;
    }

    set scaleUnit(scaleUnit) {
        this._scaleUnit = scaleUnit;
    }

    get initialView() {
        return this._initialView;
    }

    set initialView(initialView) {
        this._initialView = initialView;
    }

    /** @returns {import('./DataTheme').default} */
    get dataTheme() {
        return this._dataTheme;
    }

    set dataTheme(dataTheme) {
        this._dataTheme = dataTheme;
    }

    /**
     * Preferred layer to be shown on this map instance. It usually indicates
     * what geo level it should display. If it is set to be undefined, map will
     * display geo level automatically depending on zoom level.
     * @type {string|undefined}
     */
    get preferredDataLayerId() {
        if (
            this._preferredDataLayerId === '' ||
            this._preferredDataLayerId === undefined ||
            this._preferredDataLayerId === null
        ) {
            return undefined;
        }
        return this._preferredDataLayerId;
    }

    set preferredDataLayerId(preferredDataLayerId) {
        this._preferredDataLayerId = preferredDataLayerId;
    }

    /**
     * @returns {'SHADED_AREA' | 'BUBBLES' | 'DOT_DENSITY'}
     */
    get preferredVisualizationType() {
        return this._preferredVisualizationType;
    }

    set preferredVisualizationType(preferredVisualizationType) {
        this._preferredVisualizationType = preferredVisualizationType;
    }

    get layerOverrides() {
        if (this._layerOverrides === undefined) {
            this._layerOverrides = {};
        }
        return this._layerOverrides;
    }

    set layerOverrides(layerOverrides) {
        this._layerOverrides = layerOverrides;
    }

    get shouldShowGeoSelectorInViewMode() {
        return this._shouldShowGeoSelectorInViewMode;
    }

    set shouldShowGeoSelectorInViewMode(shouldShowGeoSelectorInViewMode) {
        this._shouldShowGeoSelectorInViewMode = shouldShowGeoSelectorInViewMode;
    }

    get shouldShowSearchInViewMode() {
        return this._shouldShowSearchInViewMode;
    }

    set shouldShowSearchInViewMode(shouldShowSearchInViewMode) {
        this._shouldShowSearchInViewMode = shouldShowSearchInViewMode;
    }

    get shouldShowVisualizationTypeSwitcherInViewMode() {
        return this._shouldShowVisualizationTypeSwitcherInViewMode;
    }

    set shouldShowVisualizationTypeSwitcherInViewMode(
        shouldShowVisualizationTypeSwitcherInViewMode,
    ) {
        this._shouldShowVisualizationTypeSwitcherInViewMode =
            shouldShowVisualizationTypeSwitcherInViewMode;
    }

    get minZoomLevelRestriction() {
        return this._minZoomLevelRestriction;
    }

    set minZoomLevelRestriction(minZoomLevelRestriction) {
        this._minZoomLevelRestriction = minZoomLevelRestriction;
    }

    get maxZoomLevelRestriction() {
        return this._maxZoomLevelRestriction;
    }

    set maxZoomLevelRestriction(maxZoomLevelRestriction) {
        this._maxZoomLevelRestriction = maxZoomLevelRestriction;
    }

    get annotationLegend() {
        return this._annotationLegend;
    }

    set annotationLegend(annotationLegend) {
        this._annotationLegend = annotationLegend;
    }

    get dataGeoFilters() {
        return this._dataGeoFilters;
    }

    set dataGeoFilters(dataGeoFilters) {
        this._dataGeoFilters = dataGeoFilters;
    }

    get isLocationAnalysisActive() {
        return this._locationAnalysisItem !== undefined;
    }

    /** @returns {import('./LocationAnalysisItem').default} */
    get locationAnalysisItem() {
        return this._locationAnalysisItem;
    }

    set locationAnalysisItem(locationAnalysisItem) {
        this._locationAnalysisItem = locationAnalysisItem;
    }

    /** @returns {import('./LocationAnalysisItem').default[]} */
    get locationAnalysisItems() {
        return this._locationAnalysisItems;
    }

    set locationAnalysisItems(locationAnalysisItems) {
        this._locationAnalysisItems = locationAnalysisItems;
    }

    /** @param {import('./LocationAnalysisItem').default} locationAnalysisItem */
    addLocationAnalysisItem = locationAnalysisItem => {
        this._locationAnalysisItems.push(locationAnalysisItem);
    };

    /** @param {import('./LocationAnalysisItem').default} locationAnalysisItem */
    removeLocationAnalysisItem = locationAnalysisItem => {
        const index = this._locationAnalysisItems.findIndex(
            item => item.id === locationAnalysisItem.id,
        );
        if (index !== -1) {
            this._locationAnalysisItems.splice(index, 1);
        }
    };

    get searchResults() {
        return this._searchResults;
    }

    set searchResults(searchResults) {
        this._searchResults = searchResults;
    }

    get dataFilter() {
        return this._dataFilter;
    }

    set dataFilter(dataFilter) {
        this._dataFilter = dataFilter;
    }

    get hasPreferredSummaryLevel() {
        return (
            this.preferredDataLayerId !== '' &&
            this.preferredDataLayerId !== undefined &&
            this.preferredDataLayerId !== null
        );
    }

    /** @type {import('./UserDataLayer').default[]} */
    get userDataLayers() {
        return this._userDataLayers;
    }

    set userDataLayers(userDataLayers) {
        this._userDataLayers = userDataLayers;
    }

    /** @type {import('./LibraryGroup').default[]} */
    get libraryDataLayers() {
        return this._libraryDataLayers;
    }

    set libraryDataLayers(libraryDataLayers) {
        this._libraryDataLayers = libraryDataLayers;
    }

    get hasMaskingFilter() {
        return Object.values(this.dataGeoFilters)[0] !== undefined;
    }

    get hasDataFilter() {
        return (
            this.dataFilter != null &&
            Object.keys(this.dataFilter).length > 0 &&
            this.dataFilter.hasValidFilters()
        );
    }

    get hasAnnotations() {
        return this.annotations.length !== 0;
    }

    equals(that) {
        let layerOverridesEqual = true;
        if (this.layerOverrides && that.layerOverrides) {
            layerOverridesEqual =
                Object.keys(this.layerOverrides).find(
                    key =>
                        !that.layerOverrides[key] ||
                        !this.layerOverrides[key].equals(
                            that.layerOverrides[key],
                        ),
                ) === undefined;
        } else {
            layerOverridesEqual =
                this.layerOverrides === undefined &&
                that.layerOverrides === undefined;
        }

        const annotationsEqual =
            this.annotations.length === that.annotations.length &&
            this.annotations.every((a, i) => that.annotations[i]);

        const annotationLegendEqual =
            (!this.annotationLegend && !that.annotationLegend) ||
            (this.annotationLegend &&
                that.annotationLegend &&
                this.annotationLegend.equals(that.annotationLegend));

        const dataGeoFiltersEqual =
            Object.keys(this.dataGeoFilters).find(
                key =>
                    !that.dataGeoFilters[key] ||
                    !this.dataGeoFilters[key].equals(that.dataGeoFilters[key]),
            ) === undefined;
        const dataFilterEqual =
            (!this.dataFilter && !that.dataFilter) ||
            (this.dataFilter &&
                that.dataFilter &&
                JSON.stringify(this.dataFilter) ===
                    JSON.stringify(that.dataFilter));

        return (
            this.metadataGroupId === that.metadataGroupId &&
            this.currentMapId === that.currentMapId &&
            this.isSatelliteVisible === that.isSatelliteVisible &&
            this.satelliteDataOverlaySatelliteHasColor ===
                that.satelliteDataOverlaySatelliteHasColor &&
            this.satelliteDataOverlayDataOpacity ===
                that.satelliteDataOverlayDataOpacity &&
            this.userEnteredTitle === that.userEnteredTitle &&
            this.scaleUnit === that.scaleUnit &&
            this.preferredDataLayerId === that.preferredDataLayerId &&
            this.preferredVisualizationType ===
                that.preferredVisualizationType &&
            this.initialView.equals(that.initialView) &&
            annotationLegendEqual &&
            this.dataTheme.equals(that.dataTheme) &&
            layerOverridesEqual &&
            annotationsEqual &&
            dataGeoFiltersEqual &&
            dataFilterEqual &&
            this.shouldShowGeoSelectorInViewMode ===
                that.shouldShowGeoSelectorInViewMode &&
            this.shouldShowSearchInViewMode ===
                that.shouldShowSearchInViewMode &&
            this.shouldShowVisualizationTypeSwitcherInViewMode ===
                that.shouldShowVisualizationTypeSwitcherInViewMode &&
            this.minZoomLevelRestriction === that.minZoomLevelRestriction &&
            this.maxZoomLevelRestriction === that.maxZoomLevelRestriction &&
            this.userDataLayers.every((udl, i) =>
                udl.equals(that.userDataLayers[i]),
            ) &&
            this.libraryDataLayers.every((ol, i) =>
                ol.equals(that.libraryDataLayers[i]),
            )
        );
    }

    clone() {
        let newLayerOverrides;
        const newDataGeoFilter = {};
        if (this.layerOverrides) {
            newLayerOverrides = {};
            Object.keys(this.layerOverrides).forEach(k => {
                newLayerOverrides[k] = this.layerOverrides[k].clone();
            });
        }
        Object.keys(this.dataGeoFilters).forEach(id => {
            newDataGeoFilter[id] = this.dataGeoFilters[id].clone();
        });
        return new MapInstance({
            metadataGroupId: this.metadataGroupId,
            currentMapId: this.currentMapId,
            isSatelliteVisible: this.isSatelliteVisible,
            satelliteDataOverlaySatelliteHasColor:
                this.satelliteDataOverlaySatelliteHasColor,
            satelliteDataOverlayDataOpacity:
                this.satelliteDataOverlayDataOpacity,
            userEnteredTitle: this.userEnteredTitle,
            scaleUnit: this.scaleUnit,
            annotations: this.annotations.map(a => a.clone()),
            annotationLegend: this.annotationLegend
                ? this.annotationLegend.clone()
                : undefined,
            preferredDataLayerId: this.preferredDataLayerId,
            preferredVisualizationType: this.preferredVisualizationType,
            initialView: this.initialView.clone(),
            dataTheme: this.dataTheme.clone(),
            layerOverrides: newLayerOverrides,
            shouldShowGeoSelectorInViewMode:
                this.shouldShowGeoSelectorInViewMode,
            shouldShowSearchInViewMode: this.shouldShowSearchInViewMode,
            shouldShowVisualizationTypeSwitcherInViewMode:
                this.shouldShowVisualizationTypeSwitcherInViewMode,
            minZoomLevelRestriction: this.minZoomLevelRestriction,
            maxZoomLevelRestriction: this.maxZoomLevelRestriction,
            dataGeoFilters: newDataGeoFilter,
            dataFilter: this.hasDataFilter
                ? this.dataFilter.clone()
                : new DataFilter(),
            userDataLayers: this.userDataLayers.map(udl => udl.clone()),
            libraryDataLayers: this.libraryDataLayers.map(ol => ol.clone()),
            locationAnalysisItem:
                this.isLocationAnalysisActive &&
                this.locationAnalysisItem.isComplete
                    ? this.locationAnalysisItem.clone()
                    : undefined,
        });
    }

    toJSON() {
        return {
            metadataGroupId: this.metadataGroupId,
            currentMapId: this.currentMapId,
            isSatelliteVisible: this.isSatelliteVisible,
            satelliteDataOverlaySatelliteHasColor:
                this.satelliteDataOverlaySatelliteHasColor,
            satelliteDataOverlayDataOpacity:
                this.satelliteDataOverlayDataOpacity,
            userEnteredTitle: this.userEnteredTitle,
            scaleUnit: this.scaleUnit,
            annotations: this.annotations.map(a => a.toJSON()),
            annotationLegend: this.annotationLegend
                ? this.annotationLegend.toJSON()
                : undefined,
            preferredDataLayerId: this.preferredDataLayerId,
            preferredVisualizationType: this.preferredVisualizationType,
            initialView: this.initialView.toJSON(),
            dataTheme: this.dataTheme.toJSON(),
            layerOverrides: this.layerOverrides,
            shouldShowGeoSelectorInViewMode:
                this.shouldShowGeoSelectorInViewMode,
            shouldShowSearchInViewMode: this.shouldShowSearchInViewMode,
            shouldShowVisualizationTypeSwitcherInViewMode:
                this.shouldShowVisualizationTypeSwitcherInViewMode,
            minZoomLevelRestriction: this.minZoomLevelRestriction,
            maxZoomLevelRestriction: this.maxZoomLevelRestriction,
            dataGeoFilters: this.dataGeoFilter,
            dataFilter: this.dataFilter
                ? JSON.parse(JSON.stringify(this.dataFilter))
                : undefined,
            userDataLayers: this.userDataLayers.map(udl => udl.toJSON()),
            libraryDataLayers: this.libraryDataLayers.map(ol => ol.toJSON()),
        };
    }
}

export default MapInstance;
