// @ts-check
import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * @typedef Props
 * @property {boolean} singleItem
 * @property {object} item
 * @property {import('react-intl').intlShape} intl
 */

const UserUploadDataLegendItem = ({
    singleItem,
    item,
    intl,
}) => {
    const { isHidden, markerColor, markerPathId, value } = item;
    const containerClasses = classNames('layer-library-legend__item', {
        'layer-library-legend__item--disabled': isHidden,
    });

    let itemLabel = value;

    if (value === undefined || value === null) {
        itemLabel = intl.formatMessage({ id: 'emptyValue' });
    }

    if (singleItem) {
        itemLabel = intl.formatMessage({ id: 'allItems' });
    }

    return (
        <div className={containerClasses}>
            <div className="layer-library-classes__item-icon">
                <div className="point-style-rule__shape" style={{ backgroundColor: markerColor }}>
                    <i className={classNames(`socex-icon-marker-${markerPathId}`)} />
                </div>
            </div>
            <span className="layer-library-classes__item-label">{itemLabel}</span>
        </div>
    );
};

UserUploadDataLegendItem.propTypes = {
    intl: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired,
    singleItem: PropTypes.bool.isRequired,
};

export default injectIntl(UserUploadDataLegendItem);
