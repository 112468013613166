'use strict';var ref = require('../segment');
var SegmentVector = ref.SegmentVector;
var MAX_VERTEX_ARRAY_LENGTH = ref.MAX_VERTEX_ARRAY_LENGTH;
var VertexBuffer = require('../../gl/vertex_buffer');
var IndexBuffer = require('../../gl/index_buffer');
var ref$1 = require('../program_configuration');
var ProgramConfigurationSet = ref$1.ProgramConfigurationSet;
var createVertexArrayType = require('../vertex_array_type');
var ref$2 = require('../index_array_type');
var TriangleIndexArray = ref$2.TriangleIndexArray;
var loadGeometry = require('../load_geometry');
var EXTENT = require('../extent');
var earcut = require('earcut');
var classifyRings = require('../../util/classify_rings');
var EARCUT_MAX_RINGS = 500;
var fillExtrusionInterface = {
    layoutAttributes: [
        {
            name: 'a_pos',
            components: 2,
            type: 'Int16'
        },
        {
            name: 'a_normal',
            components: 3,
            type: 'Int16'
        },
        {
            name: 'a_edgedistance',
            components: 1,
            type: 'Int16'
        }
    ],
    indexArrayType: TriangleIndexArray,
    paintAttributes: [
        { property: 'fill-extrusion-base' },
        { property: 'fill-extrusion-height' },
        { property: 'fill-extrusion-color' }
    ]
};
var FACTOR = Math.pow(2, 13);
function addVertex(vertexArray, x, y, nx, ny, nz, t, e) {
    vertexArray.emplaceBack(x, y, Math.floor(nx * FACTOR) * 2 + t, ny * FACTOR * 2, nz * FACTOR * 2, Math.round(e));
}
var LayoutVertexArrayType = createVertexArrayType(fillExtrusionInterface.layoutAttributes);
var FillExtrusionBucket = function FillExtrusionBucket(options) {
    this.zoom = options.zoom;
    this.overscaling = options.overscaling;
    this.layers = options.layers;
    this.index = options.index;
    this.layoutVertexArray = new LayoutVertexArrayType(options.layoutVertexArray);
    this.indexArray = new TriangleIndexArray(options.indexArray);
    this.programConfigurations = new ProgramConfigurationSet(fillExtrusionInterface, options.layers, options.zoom, options.programConfigurations);
    this.segments = new SegmentVector(options.segments);
};
FillExtrusionBucket.prototype.populate = function populate(features, options) {
    var this$1 = this;
    var autoSourceFilter;
    if (this.layers[0].autoSource) {
        for (var i = 0, list = this$1.layers[0].autoSource; i < list.length; i += 1) {
            var autoLayer = list[i];
            if (autoLayer.minzoom <= this$1.zoom && autoLayer.maxzoom > this$1.zoom && autoLayer.filter) {
                autoSourceFilter = autoLayer.filter;
                break;
            }
        }
    }
    for (var i$1 = 0, list$1 = features; i$1 < list$1.length; i$1 += 1) {
        var ref = list$1[i$1];
        var feature = ref.feature;
        var index = ref.index;
        var sourceLayerIndex = ref.sourceLayerIndex;
        if (autoSourceFilter && !autoSourceFilter({ zoom: this$1.zoom }, feature)) {
            continue;
        }
        if (this$1.layers[0]._featureFilter({ zoom: this$1.zoom }, feature)) {
            var geometry = loadGeometry(feature);
            this$1.addFeature(feature, geometry);
            options.featureIndex.insert(feature, geometry, index, sourceLayerIndex, this$1.index);
        }
    }
};
FillExtrusionBucket.prototype.isEmpty = function isEmpty() {
    return this.layoutVertexArray.length === 0;
};
FillExtrusionBucket.prototype.serialize = function serialize(transferables) {
    return {
        zoom: this.zoom,
        layerIds: this.layers.map(function (l) {
            return l.id;
        }),
        layoutVertexArray: this.layoutVertexArray.serialize(transferables),
        indexArray: this.indexArray.serialize(transferables),
        programConfigurations: this.programConfigurations.serialize(transferables),
        segments: this.segments.get()
    };
};
FillExtrusionBucket.prototype.upload = function upload(gl) {
    this.layoutVertexBuffer = new VertexBuffer(gl, this.layoutVertexArray);
    this.indexBuffer = new IndexBuffer(gl, this.indexArray);
    this.programConfigurations.upload(gl);
};
FillExtrusionBucket.prototype.destroy = function destroy() {
    if (!this.layoutVertexBuffer) {
        return;
    }
    this.layoutVertexBuffer.destroy();
    this.indexBuffer.destroy();
    this.programConfigurations.destroy();
    this.segments.destroy();
};
FillExtrusionBucket.prototype.addFeature = function addFeature(feature, geometry) {
    var this$1 = this;
    for (var i$1 = 0, list = classifyRings(geometry, EARCUT_MAX_RINGS); i$1 < list.length; i$1 += 1) {
        var polygon = list[i$1];
        var numVertices = 0;
        for (var i$2 = 0, list$1 = polygon; i$2 < list$1.length; i$2 += 1) {
            var ring = list$1[i$2];
            numVertices += ring.length;
        }
        var segment = this$1.segments.prepareSegment(4, this$1.layoutVertexArray, this$1.indexArray);
        for (var i$3 = 0, list$2 = polygon; i$3 < list$2.length; i$3 += 1) {
            var ring$1 = list$2[i$3];
            if (ring$1.length === 0) {
                continue;
            }
            var edgeDistance = 0;
            for (var p = 0; p < ring$1.length; p++) {
                var p1 = ring$1[p];
                if (p >= 1) {
                    var p2 = ring$1[p - 1];
                    if (!isBoundaryEdge(p1, p2)) {
                        if (segment.vertexLength + 4 > MAX_VERTEX_ARRAY_LENGTH) {
                            segment = this$1.segments.prepareSegment(4, this$1.layoutVertexArray, this$1.indexArray);
                        }
                        var perp = p1.sub(p2)._perp()._unit();
                        addVertex(this$1.layoutVertexArray, p1.x, p1.y, perp.x, perp.y, 0, 0, edgeDistance);
                        addVertex(this$1.layoutVertexArray, p1.x, p1.y, perp.x, perp.y, 0, 1, edgeDistance);
                        edgeDistance += p2.dist(p1);
                        addVertex(this$1.layoutVertexArray, p2.x, p2.y, perp.x, perp.y, 0, 0, edgeDistance);
                        addVertex(this$1.layoutVertexArray, p2.x, p2.y, perp.x, perp.y, 0, 1, edgeDistance);
                        var bottomRight = segment.vertexLength;
                        this$1.indexArray.emplaceBack(bottomRight, bottomRight + 1, bottomRight + 2);
                        this$1.indexArray.emplaceBack(bottomRight + 1, bottomRight + 2, bottomRight + 3);
                        segment.vertexLength += 4;
                        segment.primitiveLength += 2;
                    }
                }
            }
        }
        if (segment.vertexLength + numVertices > MAX_VERTEX_ARRAY_LENGTH) {
            segment = this$1.segments.prepareSegment(numVertices, this$1.layoutVertexArray, this$1.indexArray);
        }
        var flattened = [];
        var holeIndices = [];
        var triangleIndex = segment.vertexLength;
        for (var i$4 = 0, list$3 = polygon; i$4 < list$3.length; i$4 += 1) {
            var ring$2 = list$3[i$4];
            if (ring$2.length === 0) {
                continue;
            }
            if (ring$2 !== polygon[0]) {
                holeIndices.push(flattened.length / 2);
            }
            for (var i = 0; i < ring$2.length; i++) {
                var p$1 = ring$2[i];
                addVertex(this$1.layoutVertexArray, p$1.x, p$1.y, 0, 0, 1, 1, 0);
                flattened.push(p$1.x);
                flattened.push(p$1.y);
            }
        }
        var indices = earcut(flattened, holeIndices);
        for (var j = 0; j < indices.length; j += 3) {
            this$1.indexArray.emplaceBack(triangleIndex + indices[j], triangleIndex + indices[j + 1], triangleIndex + indices[j + 2]);
        }
        segment.primitiveLength += indices.length / 3;
        segment.vertexLength += numVertices;
    }
    this.programConfigurations.populatePaintArrays(this.layoutVertexArray.length, feature);
};
FillExtrusionBucket.programInterface = fillExtrusionInterface;
module.exports = FillExtrusionBucket;
function isBoundaryEdge(p1, p2) {
    return p1.x === p2.x && (p1.x < 0 || p1.x > EXTENT) || p1.y === p2.y && (p1.y < 0 || p1.y > EXTENT);
}