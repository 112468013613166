import React from 'react';
import { injectIntl } from 'react-intl';
import BusComponent from '../BusComponent';
import SimpleDropdown from '../dropdown/SimpleDropdown';
import NestedSummaryLevelSelector from './NestedSummaryLevelSelector';

class MaskSummaryLevelsSelection extends BusComponent {
    onSelectSummaryLevel = summaryLevelId => {
        const summaryLevel = this.props.summaryLevels.find(sl => sl.id === summaryLevelId);
        this.emit('SET_PREFERRED_SUMMARY_LEVEL_REQUEST', {
            source: this,
            mapInstanceId: this.props.mapInstanceId,
            summaryLevel,
        });
    };

    onChangeSummaryLevel = summaryLevel => {
        this.emit('MASK_TOGGLE_NESTED_SUMMARY_LEVEL_REQUEST', {
            mapInstanceId: this.props.mapInstanceId,
            summaryLevel,
        });
    };

    onChangeAllSummaryLevels = () => {
        this.emit('MASK_TOGGLE_ALL_NESTED_SUMMARY_LEVEL_REQUEST', {
            mapInstanceId: this.props.mapInstanceId,
        });
    }

    render() {
        const { availableNestedSummaryLevels, activeSummaryLevel, mapInstance, mapInstanceId, summaryLevels, selectedNestedSummaryLevels } = this.props;

        const selectedSummaryLevelItem = activeSummaryLevel ? {
            id: activeSummaryLevel.id,
            text: activeSummaryLevel.label,
        } : { id: -1, text: 'Not available' };
        const summaryLevelItems = summaryLevels.map(sl => ({
            id: sl.id,
            text: sl.label,
            fullText: sl.title ? sl.title.replace('Data:', '') : undefined,
        }));

        return (<div className="map-mask-editor__section">
            {summaryLevels && <SimpleDropdown
                title={this.props.intl.formatMessage({ id: 'geography' })}
                className={'map-mask-editor__section__item'}
                disabled={mapInstance.hasMaskingFilter}
                onItemClick={this.onSelectSummaryLevel}
                mapInstanceId={mapInstanceId}
                items={summaryLevelItems}
                selectedItem={selectedSummaryLevelItem}
            />}
            {availableNestedSummaryLevels.length > 0 &&
            <div className="map-mask-editor__section__item">
                <NestedSummaryLevelSelector
                    onChangeSummaryLevel={this.onChangeSummaryLevel}
                    mapInstanceId={mapInstanceId}
                    selectedNestedSummaryLevels={selectedNestedSummaryLevels}
                    availableNestedSummaryLevels={availableNestedSummaryLevels}
                    changeAllSummaryLevels={this.onChangeAllSummaryLevels}
                />
            </div>}
        </div>);
    }
}

export default injectIntl(MaskSummaryLevelsSelection);
