import React from 'react';
import BusComponent from '../BusComponent';
import MapViewer from '../MapViewer';
import ApplicationFrameType from '../../enums/ApplicationFrameType';
import AppConfig from '../../appConfig';
import MapControls from '../mapControls/MapControls';
import DataFilterEditor from '../dataFilter/DataFilterEditor';
import FilterAndMaskIndicator from '../mapIndicators/FilterAndMaskIndicator';
import PropTypes from 'prop-types';

class DataFilterEditorFrame extends BusComponent {
    componentDidMount() {
        this.emit('APPLICATION_FRAME_TYPE_CHANGED', ApplicationFrameType.DATA_FILTER_EDITOR);
        AppConfig.sentryRecordEvent('entered data filter frame');
    }

    render() {
        const { mapInstance, surveyName } = this.props;

        return (
            <div className="frame frame--filtering">
                <div className="frame__map">
                    <MapViewer
                        dataFilter
                        mapInstance={mapInstance}
                    />
                </div>
                <div className="frame__browser frame__browser--left">
                    <DataFilterEditor
                        mapInstanceId={mapInstance.id}
                        mapInstance={mapInstance}
                        surveyName={surveyName}
                    />
                </div>
                <div className="frame__components">
                    <div className="frame__components-block frame__components-block--top-right">
                        <FilterAndMaskIndicator
                            mapInstance={mapInstance}
                            applicationFrameType={ApplicationFrameType.DATA_FILTER_EDITOR}
                        />
                    </div>
                    <div className="frame__components-block frame__components-block--bottom-right">
                        <MapControls mapInstance={mapInstance} />
                    </div>
                </div>
            </div>);
    }
}

DataFilterEditorFrame.propTypes = {
    mapInstance: PropTypes.object.isRequired,
    surveyName: PropTypes.string.isRequired,
};

export default DataFilterEditorFrame;
