import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import uuid from 'node-uuid';
import Key from '../../enums/Key';

import { hasParentNode, hasParentNodeWithClass, focusFirstChild } from '../../helpers/Util';
import messages, { locale } from '../../languages/languageConfig';
import { IntlProvider } from 'react-intl';

class Popover extends React.Component {
    constructor(props) {
        super(props);

        this.anchorDOM = document.createElement('div');
        this.anchorDOM.id = `popover-${uuid.v4()}`;
        this.anchorDOM.classList.add('popover-anchor');
        if (props.anchorClassName) this.anchorDOM.classList.add(props.anchorClassName);
    }

    componentDidMount() {
        if (this.props.open) {
            this.renderContent();
            this.updatePosition();
        }
    }

    componentDidUpdate() {
        if (this.props.open) {
            this.renderContent();
            this.updatePosition();
        } else {
            this.deattach();
        }
    }

    componentWillUnmount() {
        this.deattach();
    }

    onEscape = event => {
        if (this.attached && event.keyCode === Key.ESC && typeof this.props.onCloseRequest === 'function') {
            this.props.onCloseRequest(event);
            event.stopPropagation();
        }
    }

    onMouseEvent = e => {
        // If trigger toggle is enabled take it into consideration when dispatching onCloseRequest
        if (typeof this.props.onCloseRequest === 'function') {
            const isTrigger = this.props.triggerToggle && this.props.anchorEl === e.target;
            const isOutside = !hasParentNode(e.target, this.anchorDOM) && !isTrigger;
            const shouldClose = hasParentNodeWithClass(e.target, 'closes-menu');

            if (isOutside || shouldClose) this.props.onCloseRequest(e);
        }
    }

    onCloseClick= e => {
        this.props.onCloseRequest(e);
    }

    attach = () => {
        if (this.attached) return;

        document.body.appendChild(this.anchorDOM);

        window.addEventListener('click', this.onMouseEvent, { capture: true });
        window.addEventListener('wheel', this.onMouseEvent);
        window.addEventListener('resize', this.resizeThrottler, false);
        this.attached = true;
    }

    deattach = () => {
        this.attached = false;
        window.removeEventListener('click', this.onMouseEvent, { capture: true });
        window.removeEventListener('wheel', this.onMouseEvent);
        window.removeEventListener('resize', this.resizeThrottler);

        ReactDOM.unmountComponentAtNode(this.anchorDOM);

        clearTimeout(this.resizeTimeout);

        if (document.body.contains(this.anchorDOM)) {
            document.body.removeChild(this.anchorDOM);
        }
        document.removeEventListener('keydown', this.onEscape, true);
    }

    actualResizeHandler = () => {
        this.updatePosition();
    }

    resizeThrottler = () => {
        // ignore resize events as long as an actualResizeHandler execution is in the queue
        if (!this.resizeTimeout) {
            this.resizeTimeout = setTimeout(() => {
                this.resizeTimeout = null;
                this.actualResizeHandler();
                // The actualResizeHandler will execute at a rate of 15fps
            }, 66);
        }
    }

    updatePosition = () => {
        // Prevent errors caused by contentWrapperDOM ref being null
        if (!this.contentWrapperDOM) return;
        const anchorElementBoundingBox = this.props.anchorEl.getBoundingClientRect();
        const { horizontal: anchorOriginHorizontal, vertical: anchorOriginVertical } = this.props.anchorOrigin;
        const { horizontal: targetOriginHorizontal, vertical: targetOriginVertical } = this.props.targetOrigin;
        let left, top, remainingHeight, maxHeight;

        switch (anchorOriginHorizontal.toLowerCase()) {
        case 'middle':
            left = anchorElementBoundingBox.left + (anchorElementBoundingBox.width / 2);
            break;
        case 'right':
            left = anchorElementBoundingBox.left + anchorElementBoundingBox.width;
            break;
        case 'left':
        default:
            left = anchorElementBoundingBox.left; // eslint-disable-line prefer-destructuring
        }

        switch (anchorOriginVertical.toLowerCase()) {
        case 'top':
            remainingHeight = window.innerHeight - anchorElementBoundingBox.top;
            top = anchorElementBoundingBox.top; // eslint-disable-line prefer-destructuring
            break;
        case 'bottom':
        default:
            remainingHeight = window.innerHeight - anchorElementBoundingBox.top - anchorElementBoundingBox.height;
            top = anchorElementBoundingBox.top + anchorElementBoundingBox.height;
            break;
        }

        if (remainingHeight < this.contentWrapperDOM.clientHeight) {
            maxHeight = top;
            top -= anchorElementBoundingBox.height;
            this.anchorDOM.style.transform = 'translateY(-100%)';
        } else {
            maxHeight = remainingHeight;
            this.anchorDOM.style.transform = '';
        }

        // When using popover with varying width children components, ensure correct width for each component
        this.anchorDOM.style.width = this.props.autoWidth ? '' : `${this.contentWrapperDOM.clientWidth}px`;
        this.anchorDOM.style.top = `${top + this.props.marginTop}px`;
        this.anchorDOM.style.left = `${left + this.props.marginLeft}px`;
        this.anchorDOM.style.maxHeight = `${maxHeight}px`;

        const contentPlacementTransformations = [];
        switch (targetOriginHorizontal) {
        case 'right':
            contentPlacementTransformations.push('translateX(-100%)');
            break;
        case 'middle':
            contentPlacementTransformations.push('translateX(-50%)');
            break;
        case 'left':
        default:
            break;
        }

        switch (targetOriginVertical) {
        case 'center':
            contentPlacementTransformations.push('translateY(-50%)');
            break;
        case 'bottom':
            contentPlacementTransformations.push('translateY(-100%)');
            break;
        case 'top':
        default:
            break;
        }
        this.contentWrapperDOM.style.transform = contentPlacementTransformations.join(' ');
    }

    renderClose() {
        return (
            <button className="btn-icon popover-anchor__close" onClick={this.onCloseClick}>
                <i className="material-icons">close</i>
            </button>
        );
    }

    renderContent() {
        ReactDOM.render(
            <IntlProvider locale={locale} messages={messages} >
                <div className={classNames('popover-anchor__wrapper', this.props.className)} ref={c => { this.contentWrapperDOM = c; }}>
                    <div className="popover-anchor__content" ref={c => { this.contentDOM = c; }}>
                        {this.props.children}
                        {this.props.showClose && this.renderClose()}
                    </div>
                </div>
            </IntlProvider>, this.anchorDOM, () => {
                this.attach();
                this.updatePosition();
                document.addEventListener('keydown', this.onEscape, true);
                focusFirstChild(this.anchorDOM);
            });
    }

    render() {
        return null;
    }
}

Popover.propTypes = {
    anchorEl: PropTypes.object,
    className: PropTypes.string,
    anchorClassName: PropTypes.string,
    children: PropTypes.element.isRequired,
    open: PropTypes.bool,
    showClose: PropTypes.bool,
    autoWidth: PropTypes.bool,
    triggerToggle: PropTypes.bool.isRequired,
    targetOrigin: PropTypes.object,
    anchorOrigin: PropTypes.object,
    marginTop: PropTypes.number,
    marginLeft: PropTypes.number,
    onCloseRequest: PropTypes.func,
};

Popover.defaultProps = {
    anchorEl: undefined,
    className: '',
    anchorClassName: '',
    open: false,
    showClose: false,
    autoWidth: false,
    triggerToggle: false,
    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
    targetOrigin: { vertical: 'top', horizontal: 'left' },
    marginTop: 0,
    marginLeft: 0,
    onCloseRequest: undefined,
};

export default Popover;

