import PapaParser from 'papaparse';
import { isValidNumber } from '../helpers/NumberHelper';

const DEFAULT_ENCODING = 'UTF-8';

function getDelimiterAsString(delimiter = '') {
    switch (delimiter.charCodeAt()) {
    case 0x3B:
        return 'semicolon';
    case 0x7C:
        return 'verticalLine';
    case 0x20:
        return 'space';
    case 0x09:
        return 'tab';
    case 0x2C:
    default:
        return 'comma';
    }
}

// As per https://en.wikipedia.org/wiki/Byte_order_mark
// (supported by the iconv tool)
const BOM = {
    'UTF-8': [0xEF, 0xBB, 0xBF],
    'UTF-16BE': [0xFE, 0xFF],
    'UTF-16LE': [0xFF, 0xFE],
    'UTF-32BE': [0x00, 0x00, 0xFE, 0xFF],
    'UTF-32LE': [0xFF, 0xFE, 0x00, 0x00],
    GB18030: [0x84, 0x31, 0x95, 0x33],
};

const maxBomLength = Math.max(...Object.keys(BOM).map(encoding => BOM[encoding].length));
const minBomLength = Math.min(...Object.keys(BOM).map(encoding => BOM[encoding].length));

// Function considers at the first minBomLength to maxBomLenght bytes.
// Returns encoding if BOM is present in the first bytes, otherwise undefined.
const encodingFromBytes = bytes => {
    for (let bomLength = maxBomLength; bomLength >= minBomLength; bomLength -= 1) { // start with longest bom lengths first
        const encoding = Object.keys(BOM)
            .find(e => {
                if (BOM[e].length !== bomLength) return false; // current bom length is different
                if (bytes.length < bomLength) return false; // not enough bytes in input to continue
                for (let i = 0; i < bomLength; i += 1) {
                    if (BOM[e][i] !== bytes[i]) break; // byte is different
                    if (i === bomLength - 1) return true; // reached last byte, found match
                }
                return false;
            });
        if (encoding) {
            return encoding;
        }
    }
    return undefined;
};

function getColumns(data, dataStartsAtFirstRow, includeProperties) {
    // perform some CSV validity checks
    // 1. check number of parsed columns
    if (!data || !data[0].length) {
        throw new Error('CSV parse error. Can\'t parse any columns.');
    }
    // 2. check data cells count. Number of data cells must be equal to result of rows x cols product
    if (data.length * data[0].length !== [].concat(...data).length) {
        throw new Error('CSV parse error. Data validity check failed.');
    }

    let columns;
    if (dataStartsAtFirstRow) {
        columns = data.map((value, columnIndex) => ({
            id: columnIndex.toString(),
            title: `Column ${columnIndex}`,
            index: columnIndex,
            data: data.map(r => r[columnIndex]),
        }));
    } else {
        columns = data.shift().map((value, columnIndex) => ({
            id: columnIndex.toString(),
            title: value,
            index: columnIndex,
            data: data.map(r => r[columnIndex]),
        }));
    }
    if (includeProperties) {
        columns.forEach(c => {
            let nullValues = 0, isNumber = true, min = Number.MAX_SAFE_INTEGER, max = -Number.MAX_SAFE_INTEGER;
            c.data.forEach(value => {
                if (value == null || !value.length) {
                    nullValues += 1;
                }
                isNumber = isNumber && isValidNumber(value);
                if (isNumber) {
                    min = Math.min(value, min);
                    max = Math.max(value, max);
                }
            });
            let properties;
            if (isNumber) {
                properties = {
                    type: 'number',
                    isEmpty: c.data.length === nullValues,
                    min,
                    max,
                };
            } else {
                properties = {
                    type: 'text',
                    isEmpty: c.data.length === nullValues,
                };
            }
            c.properties = properties;
        });
    }

    return columns;
}

export default function parse({ file, dataStartsAtFirstRow = false, preview = 0, includeProperties = true }) {
    return new Promise((resolve, reject) => {
        const onParseCompleted = ({ data, meta }) => {
            try {
                const columns = getColumns(data, dataStartsAtFirstRow, includeProperties);
                const delimiter = getDelimiterAsString(meta.delimiter);

                const fileReader = new FileReader();
                fileReader.onload = e => {
                    const encoding = encodingFromBytes(new Uint8Array(e.target.result)) || DEFAULT_ENCODING;

                    resolve({
                        columns,
                        delimiter,
                        encoding,
                    });
                };
                fileReader.onerror = () => {
                    reject('File load error');
                };

                fileReader.readAsArrayBuffer(file);
            } catch (error) {
                reject(error.message);
            }
        };

        PapaParser.parse(file, { preview, complete: onParseCompleted, skipEmptyLines: true });
    });
}
