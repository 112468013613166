import Api from '../apis/Api';
import GLU from '../glu2.js/src/index';
import UserInfoXMLParser from '../helpers/UserInfoXMLParser';
import AppConfig from '../appConfig';
import UserInfo from '../objects/UserInfo';
import Mixpanel from '../helpers/Mixpanel';

class UserInfoDataSource extends GLU.DataSource {
    static get name() {
        return 'UserInfoDataSource';
    }

    static getInstance() {
        return new UserInfoDataSource();
    }

    get authParams() {
        return {
            geobufferToken: this.currentUser.geobufferToken,
            geobufferPermission: this.currentUser.geobufferPermission,
        };
    }

    /**
     * A getter for currently logged in user.
     * If the user is not logged in, the userID
     * value is -1 and user has only guest
     * privileges.
     * @returns {UserInfo}
     */
    get currentUser() {
        return this._currentUser;
    }

    /**
     * Function that retrieves user info
     * from the site. The API returns the following format:
     * <user_id>128791</user_id>
     * <name>Sanjin Šarić</name>
     * <org_name/>
     * <omni_user_id>161</omni_user_id>
     * <geobuffer_permission>20</geobuffer_permission>
     * <geobuffer_token>KxTIxJa20KxEmB00jXVBSttEJyEOEi/al3PNMuI7bDg=</geobuffer_token>
     * <permanent_cookie>
     * BAhbB0kiCzEyODc5MQY6BkVUSSIfc2FuamluLnNhcmljMTk5MUBnbWFpbC5jb20GOwBU--a7d14be1bcaa95a92ebb99af8c7c3fa5c9271f3e
     * </permanent_cookie>
     * </user>
     * @returns {Promise}
     */
    loadUserInfo() {
        return new Promise((resolve, reject) => {
            Api.user.getUserInfo({ query: { tenant: AppConfig.constants.tenant } })
                .then(response => {
                    const responseXMLDocument = response.xhr.responseXML;

                    const error = responseXMLDocument.getElementsByTagName('Error')[0];

                    if (error) {
                        reject(error.textContent);
                        return;
                    }

                    const userInfoXMLParser = new UserInfoXMLParser(responseXMLDocument);
                    const userInfo = userInfoXMLParser.getUserInfo();
                    this._currentUser = userInfo;

                    AppConfig.sentry.configureScope(scope => {
                        scope.setUser({
                            username: userInfo.name,
                            id: userInfo.userId,
                        });
                    });

                    if (this._currentUser.userId !== -1) {
                        Api.accounts.whoAmI({ query: { context: 'socialexplorer' } }).then(omniResponse => {
                            const responseBody = omniResponse.body;
                            this._currentUser.pictureSmallURL = responseBody.pictureSmallURL;
                            this._currentUser.pictureLargeURL = responseBody.pictureLargeURL;
                            this._currentUser.email = responseBody.email;

                            Mixpanel.identifyAndSetUser(userInfo.userId, userInfo.name, responseBody.email);

                            resolve(this._currentUser);
                        });
                    } else {
                        resolve(this._currentUser);
                    }
                }, reject);
        });
    }

    logOut() {
        return new Promise((resolve, reject) => {
            Api.accounts.logOut()
                .then(response => {
                    const user = response.body;
                    const userInfo = new UserInfo();
                    userInfo.userId = user.id;
                    this._currentUser = userInfo;
                    resolve(userInfo);
                }, reject);
        });
    }
}

export default UserInfoDataSource;
