// @ts-check
import FilterTypes from '../../enums/FilterTypes';

class PointsFilterRule {
    /**
     * @param {Object} pointsFilterRule
     * @param {string} pointsFilterRule.property
     * @param {string | number} pointsFilterRule.value
     * @param {string} pointsFilterRule.filterType
     */
    constructor({ property, value, filterType = FilterTypes.EQUAL.type }) {
        this._property = property;
        this._value = value;
        this._filterType = filterType;
    }

    /** @returns {string} The geoJson feature properties property that will be used for filtering */
    get property() {
        return this._property;
    }

    set property(property) {
        this._property = property;
    }

    get value() {
        return this._value;
    }

    set value(value) {
        this._value = value;
    }

    get filterType() {
        return this._filterType;
    }

    set filterType(filterType) {
        this._filterType = filterType;
    }

    get filterTypeObject() {
        return Object.values(FilterTypes).find(
            ft => ft.type === this._filterType,
        );
    }

    /**
     * @param {import('@turf/helpers').Feature} geoJsonPointFeature returns TRUE if the given point feature matches the filter rule, otherwise FALSE will be returned
     */
    isPointFeatureAMatch = geoJsonPointFeature =>
        this.filterTypeObject.evaluateFunction(
            geoJsonPointFeature.properties[this._property],
            this._value,
        );
}

export default PointsFilterRule;
