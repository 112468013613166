import React from 'react';
import PropTypes from 'prop-types';
import Slider from '../../form/Slider';
import NumberFormat from '../../../enums/NumberFormat';
import format from '../../../helpers/NumberFormatter';


const DotDensitySlider = props => {
    const description = format({ number: props.renderer.dotValue, numberFormat: NumberFormat.FORMAT_NUMBER });
    return (
        <div className="dot-density-slider flex-it grow center space-between">
            <span className="dot-density-legend__dot-value">{description}</span>
            <Slider
                className="grow"
                min={-5}
                max={5}
                step={1}
                value={props.value}
                onChange={props.onChange}
            />
        </div>
    );
};


DotDensitySlider.propTypes = {
    renderer: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.number.isRequired,
};

export default DotDensitySlider;
