// @ts-check
class VariableSelection {
    constructor() {
        this._items = [];
        this._visualizationType = undefined;
    }

    /** @returns {import('./VariableSelectionItem').default[]} */
    get items() {
        return this._items;
    }

    set items(items) {
        this._items = items;
    }

    /** @returns {string} */
    get visualizationType() {
        return this._visualizationType;
    }

    /** Used only for saved variables */
    set visualizationType(visualizationType) {
        this._visualizationType = visualizationType;
    }

    get length() {
        return this._items.length;
    }

    get isEmpty() {
        return this._items.length === 0;
    }

    get isMultiVariable() {
        return this._items.length > 1;
    }

    get isSingleVariable() {
        return this._items.length === 1;
    }

    add(variableSelectionItem) {
        const variableSelectionItemIndex = this._items.findIndex(vsi => vsi.equals(variableSelectionItem));
        if (variableSelectionItemIndex === -1) {
            this._items.push(variableSelectionItem);
        } else {
            console.warn('Attempting to add variable selection item that already exists');
        }
    }

    remove(variableSelectionItem) {
        const variableSelectionItemIndex = this._items.findIndex(vsi => vsi.equals(variableSelectionItem));
        if (variableSelectionItemIndex === -1) {
            console.warn('Attempting to remove variable selection item that does not exist');
        } else {
            this._items.splice(variableSelectionItemIndex, 1);
        }
    }

    equals(that) {
        if (this.items.length !== that.items.length) return false;
        return (this.items.every((item, index) => item.equals(that.items[index])));
    }

    clone() {
        const variableSelection = new VariableSelection();
        variableSelection.items = this.items.map(item => item.clone());

        return variableSelection;
    }

    getItemByGuid(guid) {
        return this.items.find(item => item.variableGuid === guid);
    }

    toJSON() {
        return {
            items: this.items.map(item => item.toJSON()),
        };
    }
}

export default VariableSelection;
