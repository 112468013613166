import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import AppConfig from '../../appConfig';
import BusComponent from '../BusComponent';
import IconMenu from '../iconMenu/IconMenu';

class MoreOptionsButtonCondensed extends BusComponent {
    onMakeACopyClick = event => {
        const { projectTitle } = this.props;
        if (event.type === 'keypress') {
            if (event.key === ' ' || event.key === 'Enter') {
                // prevent the default action to stop scrolling when space is pressed
                event.preventDefault();
            } else {
                // user did not press space nor enter key, so we should do nothing
                return;
            }
        }
        this.emit('SAVE_AS_PROJECT_POPUP_REQUEST', { projectTitle, saveAsNew: true });
        this.emit('CLOSE_DROPDOWN');
    };

    onShareClick = () => {
        this.bus.once('SHARE_SNAPSHOT_LOAD_SUCCESS', eventMap => {
            this.bus.emit('SHARE_POPUP_REQUEST', eventMap);
        });
        this.emit('SHARE_SNAPSHOT_LOAD_REQUEST');
    };

    onLoginClick = () => {
        this.emit('LOGIN_REQUEST', {
            checkCount: 30,
            url: AppConfig.constants.links.login,
        });
    };

    onSignUpClick = () => {
        this.emit('SIGN_UP_REQUEST', {
            checkCount: 240,
            url: AppConfig.constants.links.signUp,
        });
    };

    onLogOutClick = () => {
        this.emit('LOG_OUT_REQUEST');
    };

    onEditProjectClick = () => {
        this.emit('EDIT_CURRENT_PROJECT_REQUEST');
    };

    render() {
        const { disabled, userInfo } = this.props;
        return (
            <IconMenu
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                targetOrigin={{ vertical: 'top', horizontal: 'right' }}
                light
                icon="menu"
                className="application-header__popup-wrapper"
                tabIndex="0"
                tooltip={this.props.intl.formatMessage({ id: 'more' })}
                disabled={disabled}
                triggerClassName="application-header__button"
                ariaLabel={this.props.intl.formatMessage({ id: 'moreOptions' })}
            >
                <div className="material-menu">
                    <button
                        className="material-menu__item"
                        onClick={this.onShareClick}
                    >
                        {this.props.intl.formatMessage({ id: 'share' })}
                    </button>
                    {userInfo.isLogged && <button
                        className="material-menu__item"
                        onClick={this.onMakeACopyClick}
                    >
                        {this.props.intl.formatMessage({ id: 'header.saveAs' })}
                    </button>}
                    {userInfo.isLogged && <button
                        className="material-menu__item"
                        onClick={this.onEditProjectClick}
                    >
                        {this.props.intl.formatMessage({ id: 'header.editProject' })}
                    </button>}
                    {userInfo.isLogged && <button
                        className="material-menu__item"
                        onClick={this.onLogOutClick}
                    >
                        {this.props.intl.formatMessage({ id: 'header.signOut' })}
                    </button>}
                    {!userInfo.isLogged && <button
                        className="material-menu__item"
                        onClick={this.onLoginClick}
                    >
                        {this.props.intl.formatMessage({ id: 'header.logIn' })}
                    </button>}
                    {!userInfo.isLogged && <button
                        className="material-menu__item"
                        onClick={this.onSignUpClick}
                    >
                        {this.props.intl.formatMessage({ id: 'header.signUp' })}
                    </button>}
                </div>
            </IconMenu>
        );
    }
}

MoreOptionsButtonCondensed.propTypes = {
    disabled: PropTypes.bool,
    userInfo: PropTypes.object.isRequired,
    projectTitle: PropTypes.string,
    intl: PropTypes.object.isRequired,
};

MoreOptionsButtonCondensed.defaultProps = {
    disabled: false,
    projectTitle: undefined,
};

export default injectIntl(MoreOptionsButtonCondensed);
