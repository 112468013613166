'use strict';var Evented = require('../../util/evented');
var DOM = require('../../util/dom');
var window = require('../../util/window');
var util = require('../../util/util');
var LngLat = require('../../geo/lng_lat');
var Marker = require('../marker');
var defaultOptions = {
    positionOptions: {
        enableHighAccuracy: false,
        timeout: 6000
    },
    fitBoundsOptions: { maxZoom: 15 },
    trackUserLocation: false,
    showUserLocation: true
};
var className = 'dragonfly-ctrl';
var supportsGeolocation;
function checkGeolocationSupport(callback) {
    if (supportsGeolocation !== undefined) {
        callback(supportsGeolocation);
    } else if (window.navigator.permissions !== undefined) {
        window.navigator.permissions.query({ name: 'geolocation' }).then(function (p) {
            supportsGeolocation = p.state !== 'denied';
            callback(supportsGeolocation);
        });
    } else {
        supportsGeolocation = !!window.navigator.geolocation;
        callback(supportsGeolocation);
    }
}
var GeolocateControl = function (Evented) {
    function GeolocateControl(options) {
        Evented.call(this);
        this.options = util.extend({}, defaultOptions, options);
        util.bindAll([
            '_onSuccess',
            '_onError',
            '_finish',
            '_setupUI',
            '_updateCamera',
            '_updateMarker',
            '_onClickGeolocate'
        ], this);
    }
    if (Evented)
        GeolocateControl.__proto__ = Evented;
    GeolocateControl.prototype = Object.create(Evented && Evented.prototype);
    GeolocateControl.prototype.constructor = GeolocateControl;
    GeolocateControl.prototype.onAdd = function onAdd(map) {
        this._map = map;
        this._container = DOM.create('div', className + ' ' + className + '-group');
        checkGeolocationSupport(this._setupUI);
        return this._container;
    };
    GeolocateControl.prototype.onRemove = function onRemove() {
        if (this._geolocationWatchID !== undefined) {
            window.navigator.geolocation.clearWatch(this._geolocationWatchID);
            this._geolocationWatchID = undefined;
        }
        if (this.options.showUserLocation) {
            this._userLocationDotMarker.remove();
        }
        DOM.remove(this._container);
        this._map = undefined;
    };
    GeolocateControl.prototype._onSuccess = function _onSuccess(position) {
        if (this.options.trackUserLocation) {
            this._lastKnownPosition = position;
            switch (this._watchState) {
            case 'WAITING_ACTIVE':
            case 'ACTIVE_LOCK':
            case 'ACTIVE_ERROR':
                this._watchState = 'ACTIVE_LOCK';
                this._geolocateButton.classList.remove('dragonfly-ctrl-geolocate-waiting');
                this._geolocateButton.classList.remove('dragonfly-ctrl-geolocate-active-error');
                this._geolocateButton.classList.add('dragonfly-ctrl-geolocate-active');
                break;
            case 'BACKGROUND':
            case 'BACKGROUND_ERROR':
                this._watchState = 'BACKGROUND';
                this._geolocateButton.classList.remove('dragonfly-ctrl-geolocate-waiting');
                this._geolocateButton.classList.remove('dragonfly-ctrl-geolocate-background-error');
                this._geolocateButton.classList.add('dragonfly-ctrl-geolocate-background');
                break;
            default:
            }
        }
        if (this.options.showUserLocation && this._watchState !== 'OFF') {
            this._updateMarker(position);
        }
        if (!this.options.trackUserLocation || this._watchState === 'ACTIVE_LOCK') {
            this._updateCamera(position);
        }
        if (this.options.showUserLocation) {
            this._dotElement.classList.remove('dragonfly-user-location-dot-stale');
        }
        this.fire('geolocate', position);
        this._finish();
    };
    GeolocateControl.prototype._updateCamera = function _updateCamera(position) {
        var center = new LngLat(position.coords.longitude, position.coords.latitude);
        var radius = position.coords.accuracy;
        this._map.fitBounds(center.toBounds(radius), this.options.fitBoundsOptions, { geolocateSource: true });
    };
    GeolocateControl.prototype._updateMarker = function _updateMarker(position) {
        if (position) {
            this._userLocationDotMarker.setLngLat([
                position.coords.longitude,
                position.coords.latitude
            ]).addTo(this._map);
        } else {
            this._userLocationDotMarker.remove();
        }
    };
    GeolocateControl.prototype._onError = function _onError(error) {
        if (this.options.trackUserLocation) {
            if (error.code === 1) {
                this._watchState = 'OFF';
                this._geolocateButton.classList.remove('dragonfly-ctrl-geolocate-waiting');
                this._geolocateButton.classList.remove('dragonfly-ctrl-geolocate-active');
                this._geolocateButton.classList.remove('dragonfly-ctrl-geolocate-active-error');
                this._geolocateButton.classList.remove('dragonfly-ctrl-geolocate-background');
                this._geolocateButton.classList.remove('dragonfly-ctrl-geolocate-background-error');
                if (this._geolocationWatchID !== undefined) {
                    this._clearWatch();
                }
            } else {
                switch (this._watchState) {
                case 'WAITING_ACTIVE':
                    this._watchState = 'ACTIVE_ERROR';
                    this._geolocateButton.classList.remove('dragonfly-ctrl-geolocate-active');
                    this._geolocateButton.classList.add('dragonfly-ctrl-geolocate-active-error');
                    break;
                case 'ACTIVE_LOCK':
                    this._watchState = 'ACTIVE_ERROR';
                    this._geolocateButton.classList.remove('dragonfly-ctrl-geolocate-active');
                    this._geolocateButton.classList.add('dragonfly-ctrl-geolocate-active-error');
                    this._geolocateButton.classList.add('dragonfly-ctrl-geolocate-waiting');
                    break;
                case 'BACKGROUND':
                    this._watchState = 'BACKGROUND_ERROR';
                    this._geolocateButton.classList.remove('dragonfly-ctrl-geolocate-background');
                    this._geolocateButton.classList.add('dragonfly-ctrl-geolocate-background-error');
                    this._geolocateButton.classList.add('dragonfly-ctrl-geolocate-waiting');
                    break;
                case 'ACTIVE_ERROR':
                    break;
                default:
                }
            }
        }
        if (this._watchState !== 'OFF' && this.options.showUserLocation) {
            this._dotElement.classList.add('dragonfly-user-location-dot-stale');
        }
        this.fire('error', error);
        this._finish();
    };
    GeolocateControl.prototype._finish = function _finish() {
        if (this._timeoutId) {
            clearTimeout(this._timeoutId);
        }
        this._timeoutId = undefined;
    };
    GeolocateControl.prototype._setupUI = function _setupUI(supported) {
        var this$1 = this;
        if (supported === false) {
            return;
        }
        this._container.addEventListener('contextmenu', function (e) {
            return e.preventDefault();
        });
        this._geolocateButton = DOM.create('button', className + '-icon ' + className + '-geolocate', this._container);
        this._geolocateButton.type = 'button';
        this._geolocateButton.setAttribute('aria-label', 'Geolocate');
        if (this.options.trackUserLocation) {
            this._geolocateButton.setAttribute('aria-pressed', 'false');
            this._watchState = 'OFF';
        }
        if (this.options.showUserLocation) {
            this._dotElement = DOM.create('div', 'dragonfly-user-location-dot');
            this._userLocationDotMarker = new Marker(this._dotElement);
            if (this.options.trackUserLocation) {
                this._watchState = 'OFF';
            }
        }
        this._geolocateButton.addEventListener('click', this._onClickGeolocate.bind(this));
        if (this.options.trackUserLocation) {
            this._map.on('movestart', function (event) {
                if (!event.geolocateSource && this$1._watchState === 'ACTIVE_LOCK') {
                    this$1._watchState = 'BACKGROUND';
                    this$1._geolocateButton.classList.add('dragonfly-ctrl-geolocate-background');
                    this$1._geolocateButton.classList.remove('dragonfly-ctrl-geolocate-active');
                    this$1.fire('trackuserlocationend');
                }
            });
        }
    };
    GeolocateControl.prototype._onClickGeolocate = function _onClickGeolocate() {
        if (this.options.trackUserLocation) {
            switch (this._watchState) {
            case 'OFF':
                this._watchState = 'WAITING_ACTIVE';
                this.fire('trackuserlocationstart');
                break;
            case 'WAITING_ACTIVE':
            case 'ACTIVE_LOCK':
            case 'ACTIVE_ERROR':
            case 'BACKGROUND_ERROR':
                this._watchState = 'OFF';
                this._geolocateButton.classList.remove('dragonfly-ctrl-geolocate-waiting');
                this._geolocateButton.classList.remove('dragonfly-ctrl-geolocate-active');
                this._geolocateButton.classList.remove('dragonfly-ctrl-geolocate-active-error');
                this._geolocateButton.classList.remove('dragonfly-ctrl-geolocate-background');
                this._geolocateButton.classList.remove('dragonfly-ctrl-geolocate-background-error');
                this.fire('trackuserlocationend');
                break;
            case 'BACKGROUND':
                this._watchState = 'ACTIVE_LOCK';
                this._geolocateButton.classList.remove('dragonfly-ctrl-geolocate-background');
                if (this._lastKnownPosition) {
                    this._updateCamera(this._lastKnownPosition);
                }
                this.fire('trackuserlocationstart');
                break;
            default:
            }
            switch (this._watchState) {
            case 'WAITING_ACTIVE':
                this._geolocateButton.classList.add('dragonfly-ctrl-geolocate-waiting');
                this._geolocateButton.classList.add('dragonfly-ctrl-geolocate-active');
                break;
            case 'ACTIVE_LOCK':
                this._geolocateButton.classList.add('dragonfly-ctrl-geolocate-active');
                break;
            case 'ACTIVE_ERROR':
                this._geolocateButton.classList.add('dragonfly-ctrl-geolocate-waiting');
                this._geolocateButton.classList.add('dragonfly-ctrl-geolocate-active-error');
                break;
            case 'BACKGROUND':
                this._geolocateButton.classList.add('dragonfly-ctrl-geolocate-background');
                break;
            case 'BACKGROUND_ERROR':
                this._geolocateButton.classList.add('dragonfly-ctrl-geolocate-waiting');
                this._geolocateButton.classList.add('dragonfly-ctrl-geolocate-background-error');
                break;
            case 'OFF':
                break;
            default:
            }
            if (this._watchState === 'OFF' && this._geolocationWatchID !== undefined) {
                this._clearWatch();
            } else if (this._geolocationWatchID === undefined) {
                this._geolocateButton.classList.add('dragonfly-ctrl-geolocate-waiting');
                this._geolocateButton.setAttribute('aria-pressed', 'true');
                this._geolocationWatchID = window.navigator.geolocation.watchPosition(this._onSuccess, this._onError, this.options.positionOptions);
            }
        } else {
            window.navigator.geolocation.getCurrentPosition(this._onSuccess, this._onError, this.options.positionOptions);
            this._timeoutId = setTimeout(this._finish, 10000);
        }
    };
    GeolocateControl.prototype._clearWatch = function _clearWatch() {
        window.navigator.geolocation.clearWatch(this._geolocationWatchID);
        this._geolocationWatchID = undefined;
        this._geolocateButton.classList.remove('dragonfly-ctrl-geolocate-waiting');
        this._geolocateButton.setAttribute('aria-pressed', 'false');
        if (this.options.showUserLocation) {
            this._updateMarker(null);
        }
    };
    return GeolocateControl;
}(Evented);
module.exports = GeolocateControl;