import React, {
    Component,
} from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../form/Checkbox';

class MapLayer extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            visible: props.visible,
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            visible: nextProps.visible,
        });
    }

    onToggleVisibility = checked => {
        this.props.onToggleVisibility(checked, this.props.id);
        this.setState({
            visible: checked,
        });
    }

    onClick = () => this.props.onEditClick(this.props.id);

    render() {
        return (
            <div className="map-layer flex-it center space-between">
                <div className="map-layer__title-wrapper flex-it center grow">
                    <span className="body-1 map-layer__title">{this.props.title}</span>
                </div>
                {this.props.onEditClick && <div
                    className="btn-flat"
                    disabled={!this.props.visible}
                    onClick={this.onClick}
                >
                    EDIT
                </div> }
                <Checkbox
                    checked={this.state.visible}
                    onCheck={this.onToggleVisibility}
                />
            </div>
        );
    }
}

MapLayer.propTypes = {
    id: PropTypes.string.isRequired,
    onToggleVisibility: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    rendered: PropTypes.bool,
    visible: PropTypes.bool,
    onEditClick: PropTypes.func,

};
MapLayer.defaultProps = {
    visible: true,
    rendered: true,
    onEditClick: undefined,
};

export default MapLayer;
