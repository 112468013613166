import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import TextInput from '../form/TextInput';
import LocationAnalysisType from '../../enums/LocationAnalysisType';

import BusComponent from '../BusComponent';

/** @param {number} value */
const countDecimals = value => {
    if (Math.floor(value) === value || isNaN(value)) {
        return 0;
    }
    return value.toString().split('.')[1].length || 0;
};

/** @type {number} */
const ALLOWED_DECIMAL_POINTS = 1;

/**
 * @typedef Props
 * @property {import('../../').LocationAnalysisType} analysisType
 * @property {number[]} existingValues
 * @property {import('react-intl').intlShape} intl
 *
 * @typedef State
 * @property {string | number} value
 * @property {boolean} hasError
 *
 * @extends {BusComponent<Props, State>}
 */
class AddCustomAnalysisValuePopup extends BusComponent {
    constructor(props, context) {
        super(props, context);
        /** @type {State} */
        this.state = {
            value: '',
            hasError: false,
        };
    }

    /**
     * @param {number | undefined} value
     * @param {number} max
     */
    isValueInAllowedRange = (value, max) => !isNaN(value) && value > 0 && value <= max;

    /** @param {string} newValue */
    calculateValue = newValue => {
        switch (this.props.analysisType.UNIT) {
        case LocationAnalysisType.RADIUS.UNIT: {
            if (newValue.charAt(newValue.length - 1) === '.') {
                return newValue;
            }

            const value = parseFloat(newValue);
            if (countDecimals(value) > ALLOWED_DECIMAL_POINTS) {
                return value.toFixed(ALLOWED_DECIMAL_POINTS);
            }
            return value;
        }
        case LocationAnalysisType.DRIVING_TIME.UNIT:
        case LocationAnalysisType.WALKING_TIME.UNIT:
        case LocationAnalysisType.CYCLING_TIME.UNIT:
        default:
            return parseInt(newValue, 10);
        }
    }

    /** @param {string} value */
    onChangeValue = value => {
        const { MAX_CUSTOM_VALUE } = this.props.analysisType;

        const newValue = this.calculateValue(value);
        this.setState({
            value: newValue,
            hasError: !this.isValueInAllowedRange(parseFloat(newValue), MAX_CUSTOM_VALUE),
        });
    }

    onCloseModal = () => {
        this.emit('CLOSE_MODAL');
    }

    onSave = () => {
        const { existingValues, analysisType } = this.props;
        const value = parseFloat(this.state.value);

        if (!this.isValueInAllowedRange(value, analysisType.MAX_CUSTOM_VALUE)) {
            // this check is necessary to prevent Enter key press to save unallowed values
            return;
        }

        if (!existingValues.includes(value)) {
            // Save only a value which is not already saved
            this.emit('SAVE_CUSTOM_LOCATION_ANALYSIS_VALUE_REQUEST', {
                value,
                analysisTypeUnit: analysisType.UNIT,
            });
            // and then forward all custom values to the React component
            this.emit('CUSTOM_LOCATION_ANALYSIS_VALUES_REQUEST');
        }
        // and then select that newly created value
        this.emit('SELECT_EXISTING_LOCATION_ANALYSIS_VALUE', value);
        this.onCloseModal();
    }

    render() {
        const { intl, analysisType } = this.props;
        const { value, hasError } = this.state;

        const textInputClassName = classNames('add-location-analysis-popup-content__input', {
            'add-location-analysis-popup-content__input--error': hasError,
        });
        const hintClassName = classNames('add-location-analysis-popup-content__hint', {
            'add-location-analysis-popup-content__hint--error': hasError,
        });

        let step;
        switch (analysisType.UNIT) {
        case LocationAnalysisType.RADIUS.UNIT:
            step = 0.1;
            break;
        case LocationAnalysisType.DRIVING_TIME.UNIT:
        case LocationAnalysisType.WALKING_TIME.UNIT:
        case LocationAnalysisType.CYCLING_TIME.UNIT:
        default:
            step = 1;
            break;
        }

        return (
            <div className="add-location-analysis-popup-content">
                <div className="add-location-analysis-popup-content__analysis-type-description flex-it center">
                    <i className="material-icons">{analysisType.ICON}</i>
                    <span>{analysisType.NAME}</span>
                </div>
                <TextInput
                    initialFocus
                    className={textInputClassName}
                    value={value}
                    onChange={this.onChangeValue}
                    onEnter={this.onSave}
                    placeholder={intl.formatMessage({ id: 'modals.enterValue' })}
                    label={`${analysisType.UNIT_DESC} (${analysisType.UNIT})`}
                    type="number"
                    hasError={hasError}
                    min={0}
                    max={analysisType.MAX_CUSTOM_VALUE}
                    step={step}
                />
                <div className={hintClassName}>
                    {intl.formatMessage({ id: 'modals.customValueBoundaries' }, {
                        minValue: 0,
                        maxValue: analysisType.MAX_CUSTOM_VALUE,
                    })}
                </div>
                <div className="flex-it center flex-end">
                    <button
                        className="btn-flat"
                        onClick={this.onCloseModal}
                    >
                        {intl.formatMessage({ id: 'cancel' })}
                    </button>
                    <button
                        className="btn-flat"
                        onClick={this.onSave}
                        disabled={!value || hasError}
                    >
                        {intl.formatMessage({ id: 'save' })}
                    </button>
                </div>
            </div>
        );
    }
}

AddCustomAnalysisValuePopup.propTypes = {
    intl: PropTypes.object.isRequired,
    analysisType: PropTypes.object.isRequired,
    existingValues: PropTypes.array.isRequired,
};

export default injectIntl(AddCustomAnalysisValuePopup);
