// @ts-check
// NOTE: similar implementation is present in neo report. If you make any changes
// here, you should check neo-report implementation as well.
/**
 * Since number of client computed functions is relatively small, but it get's
 * created for every geo item displayed (think of excel download), it makes
 * sense to memoize them
 * @type {Object<string, import("../types").ClientFunction>}
 */
const memoize = {};

/**
 * @param {String} str
 * @returns {() => import("../types").ClientFunction}
 */
function parseFunction(str) {
    const isAsync = str.trim().startsWith('async'),
        fnBodyIdx = str.indexOf('{'),
        fnBody = str.substring(fnBodyIdx + 1, str.lastIndexOf('}')),
        fnDeclare = str.substring(0, fnBodyIdx),
        fnParams = fnDeclare.substring(
            fnDeclare.indexOf('(') + 1,
            fnDeclare.lastIndexOf(')'),
        ),
        args = fnParams.split(',');

    args.push(fnBody);

    if (isAsync) {
        const AsyncFunction = Object.getPrototypeOf(
            // eslint-disable-next-line
            async function () {},
        ).constructor;
        // eslint-disable-next-line
        function Fn() {
            return AsyncFunction.apply(this, args);
        }
        Fn.prototype = AsyncFunction.prototype;
        // @ts-ignore
        return new Fn();
    }
    // eslint-disable-next-line prefer-spread
    return Function.apply(undefined, args);
}
const VARIABLE_REGEX = /#([a-zA-Z0-9_]+):([a-zA-Z0-9_]+)/g;
/**
 * @param {string} functionBody
 * @returns {string}
 */
function variableParser(functionBody) {
    return functionBody.replace(
        VARIABLE_REGEX,
        (_, datasetCode, variableCode) =>
            `getValue('${datasetCode}', '${variableCode}')`,
    );
}

/**
 * @param {string} functionBody Javascript function received from metadata backend service
 * @returns {import("../types").ClientFunction}
 */
export const generateClientFunction = functionBody => {
    if (!memoize[functionBody]) {
        const fcWithParsedVariables = variableParser(functionBody);
        const fcString = `function () {
            return async function (getValue, clientValues) {${fcWithParsedVariables}};
        }`;
        const getFc = parseFunction(fcString);
        memoize[functionBody] = getFc();
    }
    return memoize[functionBody];
};

/**
 * Returns getValue function that can be used as a parameter for client computed.
 * function. This implementation doesn't allow nested call for other client
 * computed function
 *
 * @param {Object<string, object[]>} row key is table guid. Value is value for that row
 * @param {import("../types").Table[]} tables
 */
export const generateGetValue =
    (row, tables) =>
    /**
     * @param {string} datasetAbbreviation
     * @param {string} variableCode
     * @returns {object}
     */
    (datasetAbbreviation, variableCode) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const table of tables) {
            if (table.datasetAbbreviation !== datasetAbbreviation) {
                continue;
            }
            // eslint-disable-next-line no-restricted-syntax
            for (const variable of Object.values(table.variables)) {
                if (variable.name === variableCode) {
                    return row[variable.guid];
                }
            }
        }
        return null;
    };
