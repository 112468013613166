import GLU from '../glu2.js/src/index';

class ShareApi extends GLU.Api {
  constructor(endpoint) {
    super(endpoint);

    this.createApiActions({
      name: 'shareSnapshot',
      method: GLU.Api.Post,
      path: '/share_snapshot',
      credentials: true,
    });
  }
}

export default ShareApi;
