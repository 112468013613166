import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import InfoBubbleDataRow from './InfoBubbleDataRow';

const InfoBubbleFeature = ({
    mapIdIndex,
    feature,
    isMimicking,
    isComposite,
    isExpanded,
    onInfoBubbleClose,
    onInfoBubbleExpand,
    onInfoBubbleCollapse,
    intl,
}) => {
    // if there is no geo title feature is unavailable
    if (!feature.geoTitle) {
        return (
            <div className="feature-group">
                <div className="feature-group__heading">
                    <h5>Not available</h5>
                </div>
            </div>
        );
    }
    // display feature title if any
    const title =
        mapIdIndex === 0 ? <h5>{feature.geoTitle}</h5> : undefined;

    // show SECOND map label
    let mapLabel;
    if (isComposite && mapIdIndex === 1) {
        mapLabel = 'SECOND MAP';
    }

    let infoBubbleFeature, infoBubbleFilterFeature = [];
    if (
        Object.keys(feature.dataRows).length === 0 &&
        !feature.geoTitleOnly
    ) {
        infoBubbleFeature = <div className="data-row">Not available</div>;
    } else if (!feature.geoTitleOnly) {
        infoBubbleFeature = Object.keys(feature.dataRows)
            .sort((a, b) => {
                // sort data rows so that the change over time difference field is always first
                if (!feature.dataRows[a].isChangeOverTimeRow && !feature.dataRows[b].isChangeOverTimeRow) {
                    if (!feature.dataRows[a].universe && !feature.dataRows[b].universe) return 0;
                    return feature.dataRows[a].universe ? 1 : -1;
                }
                return feature.dataRows[a].isChangeOverTimeRow ? -1 : 1;
            })
            .map(rowGuid => {
                const dataRow = feature.dataRows[rowGuid];
                const isSelected =
                    isMimicking === undefined &&
                    (dataRow.isWinner === undefined || dataRow.isWinner);
                return (
                    <InfoBubbleDataRow
                        isSelected={isSelected}
                        feature={feature}
                        dataRow={dataRow}
                        key={rowGuid}
                    />
                );
            }, this);

        infoBubbleFilterFeature = Object.keys(feature.filterDataRows)
            .map(rowGuid => {
                const dataRow = feature.filterDataRows[rowGuid];
                return (
                    <InfoBubbleDataRow
                        isSelected={false}
                        feature={feature}
                        dataRow={dataRow}
                        key={rowGuid}
                    />
                );
            }, this);
    }

    if (!infoBubbleFeature) {
        return (
            <div className="feature-group">
                <div className="feature-group__heading">{title}</div>
            </div>
        );
    }

    let infoBubbleExpandIcon, infoBubbleCloseIcon;
    if (mapIdIndex === 0) {
        infoBubbleCloseIcon = (<i
            onClick={onInfoBubbleClose}
            className="close material-icons"
        >
            close
        </i>);
        if (isExpanded) {
            infoBubbleExpandIcon = (<i className="expand material-icons">
                keyboard_arrow_down
            </i>);
        } else {
            infoBubbleExpandIcon = (<i className="expand material-icons">
                keyboard_arrow_up
            </i>);
        }
    }

    let filterInfo;
    if (feature.hasValidFilters) {
        const id = feature.isDataFilterTrue ? 'dataBrowser.filterInfoBubbleTrue' : 'dataBrowser.filterInfoBubbleFalse';
        filterInfo = <div className="feature-group__filter-info">{intl.formatMessage({ id })}:</div>;
    }

    return (
        <div className="feature-group">
            <div className="feature-group__heading">
                <div className="flex-it center grow" onClick={isExpanded ? onInfoBubbleCollapse : onInfoBubbleExpand}>
                    {infoBubbleExpandIcon}
                    {title}
                </div>
                <div className="feature-group__heading__label">
                    {mapLabel}
                </div>
                {infoBubbleCloseIcon}
            </div>
            <ul>{infoBubbleFeature}</ul>
            {filterInfo}
            {infoBubbleFilterFeature.length > 0 && <ul className="feature-group__filter-rows">{infoBubbleFilterFeature}</ul>}
        </div>
    );
};

InfoBubbleFeature.propTypes = {
    mapIdIndex: PropTypes.number.isRequired,
    feature: PropTypes.object.isRequired,
    isMimicking: PropTypes.bool,
    isComposite: PropTypes.bool,
    isExpanded: PropTypes.bool,
    onInfoBubbleClose: PropTypes.func.isRequired,
    onInfoBubbleExpand: PropTypes.func.isRequired,
    onInfoBubbleCollapse: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
};

InfoBubbleFeature.defaultProps = {
    isMimicking: undefined,
    isComposite: false,
    isExpanded: false,
};

export default injectIntl(InfoBubbleFeature);
