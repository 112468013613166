/* eslint class-methods-use-this: 0 */
import React from 'react';
import BaseController from './BaseController';
import Errors from '../enums/Error';
import ErrorPopup from '../components/ErrorPopup';
import AppConfig from '../appConfig';
import ProjectDataSource from '../dataSources/ProjectDataSource';
import UserInfoDataSource from '../dataSources/UserInfoDataSource';

class ErrorController extends BaseController {
    static get name() {
        return 'ErrorController';
    }

    static getInstance(options) {
        return new ErrorController(options);
    }

    onActivate() {
        this.bindGluBusEvents({
            PROJECT_LOAD_ERROR: this.onErrorOccurred,
            PROJECT_UPDATE_ERROR: this.onErrorOccurred,
            PARTIAL_METADATA_LOAD_ERROR: this.onErrorOccurred,
            USER_INFO_LOAD_ERROR: this.onErrorOccurred,
            GEOLOCATION_LOAD_ERROR: this.onErrorOccurred,
            ANNOTATION_STORAGE_ERROR: this.onErrorOccurred,
            APPLICATION_FRAME_TYPE_CHANGED: this.onApplicationFrameTypeChanged,
            VISUALIZATION_TYPE_CHANGE: this.onVisualizationTypeChange,
            MAP_PREFERRED_SUMMARY_LEVEL_CHANGED: this.onMapPreferredSummaryLevelChanged,
            MAP_ZOOM_END: this.onMapZoomEnd,
            MAP_ZOOM: this.onMapZoom,
            DRAGONFLY_MAP_CREATED: this.onMapCreated,
            MAP_NEW_COLOR_PALETTE_APPLIED: this.onMapNewColorPaletteApplied,
        });
        this.projectDataSource = this.activateSource(ProjectDataSource);
        this.userInfoDataSource = this.activateSource(UserInfoDataSource);

        this._applicationState = {};

        AppConfig.sentry.configureScope(scope => {
            scope.addEventProcessor(async event => this.onBeforeSendErrorReport(event));
        });
    }

    onBeforeSendErrorReport = data => {
        const extra = {
            url: window.location,
        };

        if (this.projectDataSource && this.projectDataSource.currentProject) {
            Object.assign(
                extra,
                {
                    viewCode: this.projectDataSource.currentProject.viewCode,
                },
            );
        }

        if (this.applicationState) {
            Object.assign(
                extra,
                {
                    frameType: this.applicationState.applicationFrameType,
                    preferredSummaryLevel: this.applicationState.preferredSummaryLevel,
                    activeSummaryLevel: this.applicationState.activeSummaryLevel,
                    visualizationType: this.applicationState.visualizationType,
                    colorPalette: this.applicationState.colorPalette,
                    mapZoom: this.applicationState.mapZoom,
                },
            );
        }

        // Project data info
        if (this.projectDataSource.currentProject && this.projectDataSource.currentProject.frames) {
            const currentFrameIndex = (this.projectDataSource.currentProject.frames.length === 0 ? -1 : 0);
            const frame = this.projectDataSource.currentProject.frames[currentFrameIndex];
            if (frame) {
                extra.frameType = frame.type;
            }

            if (frame && frame.mapInstances && frame.mapInstances.length > 0) {
                frame.mapInstances.forEach((mapInstance, index) => {
                    const mapInstanceData = {};
                    mapInstanceData.currentMapId = mapInstance.currentMapId;
                    if (mapInstance.dataTheme &&
                        mapInstance.dataTheme.variableSelection &&
                        mapInstance.dataTheme.variableSelection.items &&
                        mapInstance.dataTheme.variableSelection.items.length > 0) {
                        mapInstanceData.survey = mapInstance.dataTheme.variableSelection.items[0].surveyName;
                        mapInstanceData.dataset = mapInstance.dataTheme.variableSelection.items[0].datasetAbbreviation;
                        mapInstanceData.table = mapInstance.dataTheme.variableSelection.items[0].tableGuid;
                        mapInstanceData.variables = mapInstance.dataTheme.variableSelection.items.map(item => item.variableGuid);
                    }
                    extra[`mapInstance-${index}`] = mapInstanceData;
                });
            }
        }

        data.extra = Object.assign({}, data.extra, extra);

        return data;
    };

    get applicationState() {
        return this._applicationState;
    }

    onMapCreated(e) {
        const visualizationType = {
            ...this._applicationState.visualizationType,
        };
        visualizationType[e.source.id] = e.source.mapInstance._preferredVisualizationType;

        const activeSummaryLevel = {
            ...this._applicationState.activeSummaryLevel,
        };
        activeSummaryLevel[e.source.id] = e.source.activeSummaryLevel;

        const preferredSummaryLevel = {
            ...this._applicationState.preferredSummaryLevel,
        };
        preferredSummaryLevel[e.source.id] = e.source.preferredSummaryLevel;

        const colorPalette = {
            ...this._applicationState.colorPalette,
        };
        colorPalette[e.source.id] = {
            colorPaletteId: e.source.mapInstance.dataTheme.colorPaletteId,
            colorPaletteType: e.source.mapInstance.dataTheme.colorPaletteType,
            colorPaletteFlipped: e.source.mapInstance.dataTheme.colorPaletteFlipped === true,
        };

        const mapZoom = {
            ...this._applicationState.mapZoom,
        };
        mapZoom[e.source.id] = e.source.dragonflyMap.getZoom();

        this._applicationState = {
            ...this._applicationState,
            visualizationType,
            activeSummaryLevel,
            preferredSummaryLevel,
            colorPalette,
            mapZoom,
        };
    }

    onMapZoom(e) {
        const mapZoom = {
            ...this._applicationState.mapZoom,
        };
        mapZoom[e.source.id] = e.source.dragonflyMap.getZoom();

        this._applicationState = {
            ...this._applicationState,
            mapZoom,
        };
    }

    onApplicationFrameTypeChanged(applicationFrameType) {
        this._applicationState = {
            ...this._applicationState,
            applicationFrameType,
            visualizationType: {},
            activeSummaryLevel: {},
            preferredSummaryLevel: {},
            colorPalette: {},
            mapZoom: {},
        };
    }

    onMapZoomEnd(e) {
        const activeSummaryLevel = {
            ...this._applicationState.activeSummaryLevel,
        };
        activeSummaryLevel[e.source.id] = e.source.activeSummaryLevel;
        this._applicationState = {
            ...this._applicationState,
            activeSummaryLevel,
        };
    }

    onMapNewColorPaletteApplied(e) {
        const colorPalette = {
            ...this._applicationState.colorPalette,
        };
        colorPalette[e.source.id] = {
            colorPaletteId: e.colorPaletteId,
            colorPaletteType: e.colorPaletteType,
            colorPaletteFlipped: e.colorPaletteFlipped,
        };
        this._applicationState = {
            ...this._applicationState,
            colorPalette,
        };
    }

    onVisualizationTypeChange(e) {
        const visualizationType = {
            ...this._applicationState.visualizationType,
        };
        visualizationType[e.mapInstanceId] = e.newVisualizationType;
        this._applicationState = {
            ...this._applicationState,
            visualizationType,
        };
    }

    onMapPreferredSummaryLevelChanged(e) {
        const preferredSummaryLevel = {
            ...this._applicationState.preferredSummaryLevel,
        };
        preferredSummaryLevel[e.source.id] = e.source.preferredSummaryLevel;
        this._applicationState = {
            ...this._applicationState,
            preferredSummaryLevel,
        };
    }

    onApplicationErrorOccurred(eventMap) {
        AppConfig.sentry.captureException(eventMap.error);
    }

    /**
     * Function that handles error events.
     * @param error
     *  Object containing following fields:
     *      level: Type of error that occoured. Possible values are:
     *              WARNING, ERROR and CRITICAL.
     *      originalError: The info that comes from the event.
     *      additionalInfo: The info that adds more information about the error.
     */

    onErrorOccurred(error) {
        switch (error.level) {
            // Only log to console
        case Errors.WARNING:
            console.warn(`Error Info: ${error.originalError} Additional info: ${error.additionalInfo}`, error.originalError);
            break;
            // Only log to console
        case Errors.ERROR:
            console.error(`Error Info: ${error.originalError} Additional info: ${error.additionalInfo}`, error.originalError);
            break;
            // Show a modal and log to console. This should stop further app execution.
        case Errors.CRITICAL:
            console.error(`Error Info: ${error.originalError} Additional info: ${error.additionalInfo}`, error.originalError);
            this.bus.emit('OPEN_MODAL', {
                name: 'Error',
                modalContent: React.createElement(ErrorPopup, {
                    level: 'Critical Error',
                    originalError: error.originalError ? error.originalError.toString() : '',
                    additionalInfo: error.additionalInfo ? error.additionalInfo.toString() : '',
                }),
                modalBoxClass: 'simple-modal',
                canClickAway: false,
            });
            break;
        default:
            console.error(error);
        }
    }

    onDeactivate() {
        this.unbindGluBusEvents();
    }
}

export default ErrorController;
