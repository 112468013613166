import MetaTable from '../objects/MetaTable';

class TableXMLParser {
    constructor(datasetXMLDocument) {
        this.datasetXMLDocument = datasetXMLDocument;
    }

    _parseTable(tableNode) {
        const table = new MetaTable();

        table.guid = tableNode.getElementsByTagName('GUID')[0].innerHTML;
        table.variableCount = parseInt(tableNode.getElementsByTagName('VariableCount')[0].innerHTML, 10);
        table.datasetAbbreviation = tableNode.getElementsByTagName('DatasetAbbreviation')[0].innerHTML;
        table.name = tableNode.getElementsByTagName('Name')[0].innerHTML;

        return table;
    }

    _parseTables(datasetXMLDocument) {
        const tables = [];
        const tableNodes = datasetXMLDocument.getElementsByTagName('CWsTable');

        for (let i = 0; i < tableNodes.length; i += 1) {
            const tableNode = tableNodes[i];
            tables.push(this._parseTable(tableNode));
        }
        return tables;
    }

    tables() {
        return this._parseTables(this.datasetXMLDocument);
    }
}

export default TableXMLParser;
