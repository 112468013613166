// @ts-check
import BaseController from './BaseController';
import DataTheme from '../objects/DataTheme';
import FieldListField from '../objects/FieldListField';
import FieldList from '../objects/FieldList';
import VariableSelection from '../objects/VariableSelection';
import ReportRenderer from '../objects/ReportRenderer';
import FilterRule from '../objects/FilterRule';
import Filter from '../objects/Filter';
import FilterComparisonType from '../enums/FilterComparisonType';
import Brush from '../objects/Brush';
import Symbol from '../objects/Symbol';

import MetadataDataSource from '../dataSources/MetadataDataSource';
import MapDataSource from '../dataSources/MapDataSource';
import ProjectDataSource from '../dataSources/ProjectDataSource';
import ColorPaletteDataSource from '../dataSources/ColorPaletteDataSource';
import UserDataUploadDataSource from '../dataSources/UserDataUploadDataSource';
import UserInfoDataSource from '../dataSources/UserInfoDataSource';
import ReportDataSource from '../dataSources/ReportDataSource';
import ReportTableTemplateDataSource from '../dataSources/ReportTableTemplateDataSource';
import AppConfig from '../appConfig';
import { POINT_FEATURE_TYPES } from '../enums/FeatureTypes';
import { isSurveyCompatibleWithTableTemplate } from '../helpers/ReportTableTemplate';

const REPORT_LIMIT = AppConfig.constants.reportGeographiesLimit;

class ReportController extends BaseController {
    static get name() {
        return 'ReportController';
    }

    static getInstance(options) {
        return new ReportController(options);
    }

    onActivate() {
        this.bindGluBusEvents({
            ENTER_REPORT_MODE: this.onEnterReportMode,
            EXIT_REPORT_MODE: this.onExitReportMode,
            CHANGE_REPORT_SURVEY: this.onChangeReportSurvey,
            GENERATE_REPORT_PAYLOAD: this.onGenerateReportPayload,
            CREATE_REPORT: this.createReport,
            RETRIEVE_CURRENT_REPORT_INFO_REQUEST: this
                .onRetrieveCurrentReportInfoRequest,
            LOAD_COMPATIBLE_TABLE_TEMPLATES_REQUEST: this
                .loadCompatibleTableTemplates,
            SYSTEM_REPORT_REQUEST: this.onSystemReportRequest,
        });

        this.metadataDataSource = this.activateSource(MetadataDataSource);
        this.mapDataSource = this.activateSource(MapDataSource);
        this.projectDataSource = this.activateSource(ProjectDataSource);
        this.colorPaletteDataSource = this.activateSource(
            ColorPaletteDataSource,
        );
        this.userInfoDataSource = this.activateSource(UserInfoDataSource);
        this.userDataUploadDataSource = this.activateSource(
            UserDataUploadDataSource,
        );
        this.reportDataSource = this.activateSource(ReportDataSource);
        /** @type {import('../dataSources/ReportTableTemplateDataSource').default} */
        this.reportTableTemplateDataSource = this.activateSource(
            ReportTableTemplateDataSource,
        );

        this._reportingIsActive = false;
    }

    /**
     * @param {object} param0
     * @param {string} param0.surveyName survey code
     */
    loadCompatibleTableTemplates({ surveyName }) {
        /** @type {import('../types').ReportTemplate[]} */
        const compatibleTemplateIds = this.reportTableTemplateDataSource.reportTableTemplates
            .filter(template =>
                isSurveyCompatibleWithTableTemplate(
                    surveyName,
                    template,
                    this.metadataDataSource.surveyGroups,
                ),
            )
            .map(template => template.id);

        this.reportTableTemplateDataSource
            .loadTables(surveyName, compatibleTemplateIds)
            .then(templatesWithTableGuids => {
                this.bus.emit('COMPATIBLE_TABLE_TEMPLATES_LOADED', {
                    reportTableTemplates: templatesWithTableGuids,
                });
            });
    }

    onEnterReportMode(e) {
        // Already in reporting
        if (this._reportingIsActive) return;

        this._reportingIsActive = true;
        this.projectDataSource.setActiveReportMapInstance(e.mapInstanceId);
        const reportMapInstance = this.projectDataSource.reportMapInstance;
        // create report mode data theme using value renderer and replace existing data theme with it
        // in report mode data layers should be shown without the variables
        const currentMap = this.mapDataSource.currentMaps[
            reportMapInstance.currentMapId
            ];
        const currentSurvey = this.metadataDataSource.currentMetadata.surveys[
            currentMap.surveyName
            ];
        const currentSummaryLevel = currentMap.getSummaryLevelOnZoom(
            reportMapInstance.initialView.zoom,
        );
        if (
            !reportMapInstance.hasPreferredSummaryLevel &&
            currentSummaryLevel
        ) {
            reportMapInstance.preferredDataLayerId = currentMap.getDataSourceForSummaryLevelId(
                currentSummaryLevel.id,
            ).id;
        }
        reportMapInstance.dataTheme = ReportController._createReportTheme(
            currentSurvey,
        );
        this.bus.emit('DISPLAY_MAP_REPORT_FRAME', {
            mapInstance: reportMapInstance,
            parentMapInstance: this.projectDataSource.getActiveMapInstance(
                e.mapInstanceId,
            ),
        });
    }

    onExitReportMode() {
        if (!this._reportingIsActive) return;

        // transfer annotations made while in report mode to parent map instance
        const reportMapInstance = this.projectDataSource.reportMapInstance;
        const activeMapInstance = this.projectDataSource.getActiveMapInstance(
            reportMapInstance.reportParentMapInstanceId,
        );
        // In case of preview story mode, no active mapInstance exists with reportMapInstance
        // Since no activeMapInstance exists, no annotations setting is needed
        if (activeMapInstance) {
            activeMapInstance.annotations = reportMapInstance.annotations.map(
                a => a.clone(),
            );
        }

        this._reportingIsActive = false;
        this.bus.emit('HIDE_MAP_REPORT_FRAME');
    }

    onRetrieveCurrentReportInfoRequest() {
        const onError = error => {
            this.bus.emit('CURRENT_REPORT_INFO_ERROR', { error });
        };

        // Get survey names available for reporting
        const availableForReporting = this.metadataDataSource.surveyGroups
            .flatMap(surveyGroup => surveyGroup.dataSets)
            .filter(ds => ds.availableInReports)
            .map(ds => ds.code);
        const surveyNames = this.metadataDataSource
            .getSurveysNamesForMapsGroup(
                this.projectDataSource.reportMapInstance.metadataGroupId,
            )
            .filter(surveyName => availableForReporting.includes(surveyName));

        this.metadataDataSource
            .loadSurveys(surveyNames)
            .then(() => {
                const surveys = this.metadataDataSource
                    .getSurveysForMapsGroup(
                        this.projectDataSource.reportMapInstance
                            .metadataGroupId,
                    )
                    .filter(s =>
                        availableForReporting.includes(s.name),
                    );
                const selectedSurvey = surveys.find(
                    survey =>
                        survey.name ===
                        this.projectDataSource.reportMapInstance
                            .surveyName,
                );
                let reports = Object.values(selectedSurvey.reports);
                // There are some premade reports that can be created only
                // using point data and aggregation type 3
                // (example: Opportunity report for PACCOMOPPORTUNITY2020)
                // This reports are featured reports and can be created only
                // using the location analysis reporting tool. They have
                // to be filtered out in the standard report mode.
                // Luckily we can filter them put using the "featuredReports"
                // object from the config file.
                // Note: Don't be confused with the example, you will still
                // be able to see one opportunity report for PACCOMOPPORTUNITY2020
                // since two opportunity system reports exist (one that is
                // created using the point data and the other one without)
                const featuredReports =
                    AppConfig.constants.featuredReports
                        .find(featuredReport => featuredReport.source === 'survey' && featuredReport.properties.surveyName === selectedSurvey.name);
                if (
                    featuredReports &&
                    featuredReports.properties &&
                    featuredReports.properties.aggregationType === 3 &&
                    featuredReports.properties.pointDataSurveys &&
                    featuredReports.properties.reportIdsToShow
                ) {
                    reports = reports.filter(
                        report =>
                            !featuredReports.properties.reportIdsToShow.includes(
                                parseInt(report.id, 10),
                            ),
                    );
                }
                const currentMap = this.mapDataSource.currentMaps[
                    this.projectDataSource.reportMapInstance
                        .currentMapId
                    ];

                this.bus.emit('CURRENT_REPORT_INFO', {
                    surveys,
                    selectedSurvey,
                    reports,
                    selectedReport: reports.length ? reports[0] : undefined,
                    activeSummaryLevel: currentMap.getSummaryLevelForDataSourceId(
                        this.projectDataSource.reportMapInstance
                            .preferredDataLayerId,
                    ),
                    summaryLevels: currentMap.summaryLevels,
                    activeDataSource:
                        currentMap.dataSources[
                            this.projectDataSource.reportMapInstance
                                .preferredDataLayerId
                            ],
                });                    
            })
            .catch(onError);

        if (!this.reportTableTemplateDataSource.areTableTemplatesLoaded()) {
            this.reportTableTemplateDataSource.loadTemplates().catch(onError);
        }
    }

    async onChangeReportSurvey(e) {
        const nextSurvey = this.metadataDataSource.currentMetadata.surveys[
            e.surveyName
            ];
        if (!this.mapDataSource.currentMaps[nextSurvey.name]) {
            await this.mapDataSource
                .loadMapByURL(
                    `${AppConfig.constants.mapsURL}/${nextSurvey.name}.json`,
                )
                .then(async map => {
                    if (map.colorPalettes === undefined) {
                        await this.colorPaletteDataSource.updateMapColorPalettes(
                            map,
                        );
                    }
                });
        }
        const nextReportDataTheme = ReportController._createReportTheme(
            nextSurvey,
        );
        this.bus.emit('APPLY_NEW_DATA_THEME_REQUEST', {
            source: this,
            mapInstanceId: e.mapInstanceId,
            dataTheme: nextReportDataTheme,
            mapId: nextSurvey.name,
            lockSummaryLevel: true,
        });
    }

    onSystemReportRequest = ({ systemReportId, surveyName }) => {
        this.metadataDataSource
            .loadSystemReport(surveyName, systemReportId)
            .then(response => {
                this.bus.emit('SYSTEM_REPORT_RESPONSE', {
                    surveyName: response.surveyName,
                    systemReport: response.systemReport,
                });
            });
    };

    /**
     *
     * @param {object} param0
     * @param {{ id: string, text: string }} param0.selectedSurveyItem
     * @param {string[] | undefined} param0.selectedTableGuids
     * @param {string[]} param0.selectedGeographies
     */
    onGenerateReportPayload({
        selectedSurveyItem,
        selectedTableGuids,
        selectedGeographies,
    }) {
        const definition = {
            geoGroups: [
                {
                    name: selectedSurveyItem.year, // TODO: Figure out what instead of year?
                    aggregationType: 0,
                    columnGroups: [
                        {
                            name: selectedSurveyItem.year, // TODO: Figure out what instead of year?
                            tables: [
                                {
                                    surveyCode: selectedSurveyItem.id,
                                    geoFips: selectedGeographies,
                                    tableGuids: selectedTableGuids,
                                },
                            ],
                        },
                    ],
                },
            ],
        };

        this.createReport(definition);
    }

    createReport = definition => {
        this.reportDataSource
            .createReport(definition)
            .then(reportId => {
                this.bus.emit('CREATE_REPORT_SUCCESS');
                // Open the report in new tab with the report id
                const url = `${AppConfig.constants.links.reportPreviewLink}${reportId}`;
                window.open(url, '_blank');
            })
            .catch(() => {
                this.bus.emit('CREATE_REPORT_ERROR', {
                    message: 'Error creating report!',
                });
            });
    };

    static _createReportTheme(metaSurvey) {
        const reportDataTheme = new DataTheme();

        // geography identity fields
        const geoQNameFieldListField = new FieldListField();
        geoQNameFieldListField.fieldName = metaSurvey.geoQNameField;
        geoQNameFieldListField.surveyName = metaSurvey.name;
        geoQNameFieldListField.datasetAbbreviation =
            metaSurvey.datasetsAsArray[0].abbrevation;
        geoQNameFieldListField.label = 'Geography full name';
        geoQNameFieldListField.isGeoNameField = true;
        geoQNameFieldListField.hideFromUser = false;

        const fipsFieldListField = new FieldListField();
        fipsFieldListField.fieldName = metaSurvey.geoFipsField;
        fipsFieldListField.surveyName = metaSurvey.name;
        fipsFieldListField.datasetAbbreviation =
            metaSurvey.datasetsAsArray[0].abbrevation;
        fipsFieldListField.label = 'FIPS';
        fipsFieldListField.isGeoNameField = false;
        fipsFieldListField.hideFromUser = true;

        const fieldList = new FieldList();
        fieldList.fields = [geoQNameFieldListField, fipsFieldListField];

        // empty variable selection
        reportDataTheme.variableSelection = new VariableSelection();
        reportDataTheme.title = 'Data theme for report';

        // value renderer
        const reportRenderer = new ReportRenderer();
        reportRenderer.fieldList = fieldList;
        reportRenderer.visibility = [];
        const nullGeoQNameFilterRule = ReportController._createNullGeoQNameFilterRule(
            geoQNameFieldListField,
        );
        reportRenderer.rules = [
            nullGeoQNameFilterRule,
            ReportController.createUnselectedGeoAreaRule(),
        ];
        reportRenderer.nullDataRuleIndex = 0;
        reportRenderer.reportLimit = REPORT_LIMIT;
        reportDataTheme.rendering = [reportRenderer];
        return reportDataTheme;
    }

    static _createNullGeoQNameFilterRule(geoQNameFieldListField) {
        const rule = new FilterRule();
        rule.fieldQualifiedName = geoQNameFieldListField.qualifiedName;
        rule.title = 'Geo Name';
        rule.zoomMin = 0;
        rule.zoomMax = 20;

        const brush = new Brush({
            fillColor: '#fafafa',
            fillPattern: 'stripe',
            fillOpacity: 1,
            minZoom: 0,
            maxZoom: 20,
        });
        const polygonSymbol = new Symbol();
        polygonSymbol.type = 'PolygonSymbol';
        polygonSymbol.brushes = [brush];
        rule.symbols = [polygonSymbol];

        const filter = new Filter();
        filter.comparisonType = FilterComparisonType.MATCH_NULL;
        filter.fieldName = geoQNameFieldListField.qualifiedName;
        rule.filter = filter;

        return rule;
    }

    static createUnselectedGeoAreaRule() {
        const rule = new FilterRule();
        rule.fieldQualifiedName = '';
        rule.title = '';
        rule.zoomMin = 0;
        rule.zoomMax = 20;

        const brush = new Brush({
            fillColor: '#999999',
            fillOpacity: 1,
            minZoom: 0,
            maxZoom: 20,
        });
        const polygonSymbol = new Symbol();
        polygonSymbol.type = 'PolygonSymbol';
        polygonSymbol.brushes = [brush];
        rule.symbols = [polygonSymbol];

        const filter = new Filter();
        filter.comparisonType = FilterComparisonType.MATCH_DEFAULT_SYMBOL;
        filter.fieldName = '';
        rule.filter = filter;

        return rule;
    }

    onDeactivate() {
        this.unbindGluBusEvents();
    }
}

export default ReportController;
