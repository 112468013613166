import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { addEvent, removeEvent } from '../../helpers/Util';
import Key from '../../enums/Key';
import Tooltip from '../Tooltip';

class AnnotationPalette extends React.Component {
    componentDidMount() {
        addEvent(document, 'keydown', this.onDocumentKeyDown);

        // select first annotation
        const { annotationTypes } = this.props;
        const types = Object.keys(annotationTypes).map(type => annotationTypes[type]);
        this.onClick(types[0]);
    }

    componentWillUnmount() {
        removeEvent(document, 'keydown', this.onDocumentKeyDown);
    }

    onDocumentKeyDown = event => {
        if (event.which === Key.ESC) {
            this.props.onCancelDrawing();
        }
    }

    onClick = annotation => {
        const { drawingAnnotation, onCancelDrawing } = this.props;
        if (annotation.type === drawingAnnotation) {
            onCancelDrawing();
        } else {
            annotation.callback();
        }
    }

    render() {
        const { annotationTypes, drawingAnnotation, intl } = this.props;

        const types = Object.keys(annotationTypes).map(type => annotationTypes[type]);

        // let's find uniqe groups
        const groups = Array.from(new Set(types.map(type => type.group)));
        const annotationPaletteGroups = [];
        groups.sort().forEach(group => {
            const annotations = types
                .filter(type => type.group === group)
                .map(annotation => {
                    const annotationClasses = classNames('annotation-palette__btn', {
                        'annotation-palette__btn--selected': annotation.type === drawingAnnotation,
                    });

                    return (<Tooltip
                        key={annotation.type}
                        placement="bottom"
                        mouseEnterDelay={0.5}
                        mouseLeaveDelay={0}
                        overlay={intl.formatMessage({ id: `hints.${annotation.type}.label` })}
                    >
                        <button
                            className={annotationClasses}
                            onClick={() => this.onClick(annotation)}
                        >
                            {annotation.icon}
                        </button>
                    </Tooltip>);
                });
            annotationPaletteGroups.push(<div className="annotation-palette__group" key={group}>
                {annotations}
            </div>);
        });

        return (<div className="annotation-palette">
            {annotationPaletteGroups}
        </div>);
    }
}

AnnotationPalette.propTypes = {
    annotationTypes: PropTypes.object.isRequired,
    drawingAnnotation: PropTypes.string,
    onCancelDrawing: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
};

AnnotationPalette.defaultProps = {
    drawingAnnotation: undefined,
};

export default injectIntl(AnnotationPalette);
