import React from 'react';

import BusComponent from '../BusComponent';
import MapViewer from '../MapViewer';
import FilterAndMaskIndicator from '../mapIndicators/FilterAndMaskIndicator';
import ApplicationFrameType from '../../enums/ApplicationFrameType';
import AppConfig from '../../appConfig';
import MapReportEditor from '../reportEditor/MapReportEditor';
import MapControls from '../mapControls/MapControls';

class MapReportFrame extends BusComponent {
    componentWillMount() {
        this.emit('APPLICATION_FRAME_TYPE_CHANGED', ApplicationFrameType.REPORT_EDITOR);
        AppConfig.sentryRecordEvent('entered map report frame');
    }

    render() {
        const { mapInstance, parentMapInstance } = this.props;

        return (
            <div className="frame frame--report">
                <div className="frame__map">
                    <MapViewer
                        mapInstance={mapInstance}
                        selection
                        report
                        annotations
                    />
                </div>
                <div className="frame__browser frame__browser--left">
                    <MapReportEditor
                        mapInstance={mapInstance}
                        parentMapInstance={parentMapInstance}
                    />
                </div>
                <div className="frame__components">
                    <div className="frame__components-block frame__components-block frame__components-block--top-right">
                        <FilterAndMaskIndicator mapInstance={mapInstance} applicationFrameType={ApplicationFrameType.REPORT_EDITOR} />
                    </div>
                    <div className="frame__components-block frame__components-block--bottom-right">
                        <MapControls mapInstance={mapInstance} />
                    </div>
                </div>
            </div>
        );
    }
}

export default MapReportFrame;
