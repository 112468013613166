import React from 'react';
import PropTypes from 'prop-types';

import LabelPosition from '../../../enums/LabelPosition';
import MarkerSize from '../../../enums/MarkerSize';
import NumberPropertyEditor from './propertyEditors/NumberPropertyEditor';
import ColorPropertyEditor from './propertyEditors/ColorPropertyEditor';
import MarkerPropertyEditor from './propertyEditors/MarkerPropertyEditor';
import TogglePropertyEditor from './propertyEditors/TogglePropertyEditor';
import ButtonToggle from '../../form/buttonToggle/ButtonToggle';

const POSITION_ITEMS =
    Object.keys(LabelPosition)
        .map((position, index) => ({ text: LabelPosition[position], id: index }));

class EditMarker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            labelVisible: props.annotation.labelVisible,
            fillColor: props.annotation.fillColor,
            labelPosition: LabelPosition[props.annotation.labelPosition.toUpperCase()],
            textSize: Object.keys(MarkerSize).indexOf(props.annotation.textSize.toUpperCase()),
            markerSize: Object.keys(MarkerSize).indexOf(props.annotation.size.toUpperCase()),
            markerPathId: props.annotation.markerPathId,
            markerEditorOpen: false,
            opacity: props.annotation.opacity,
        };
    }

    onOpacityChanged = opacity => {
        this.setState({ opacity: opacity / 100 });
        this.props.onChange({
            opacity: opacity / 100,
            useFill: this.props.annotation.useFill,
        });
    }

    onTextColorChanged = textColor => {
        this.props.onChange({
            textColor,
        });
    }

    onMarkerSizeChanged = index => {
        const newMarkerSize = Object.keys(MarkerSize)[index];
        this.setState({ markerSize: index });
        this.props.onChange({
            size: newMarkerSize,
        });
    }

    onTextSizeChanged = index => {
        const newTextSize = Object.keys(MarkerSize)[index];
        this.setState({ textSize: index });
        this.props.onChange({
            textSize: newTextSize,
        });
    }

    onPositionChanged = position => {
        const labelPosition = Object.keys(LabelPosition)[position.id].toLowerCase();
        this.setState({ labelPosition });
        this.props.onChange({
            labelPosition,
        });
    }

    onToggleLabel = checked => {
        this.setState({ labelVisible: checked });
        this.props.onChange({
            title: this.props.annotation.title,
            labelVisible: checked,
        });
    }

    onMarkerStyleChange = markerStyle => {
        const { markerColor, markerPathId } = markerStyle;
        this.setState({
            fillColor: markerColor,
            markerPathId,
        });
        this.props.onChange({
            fillColor: markerColor,
            markerPathId,
        });
    }

    onCloseRequest = () => {
        this.setState({ markerEditorOpen: false });
    }

    onTriggerClick = () => {
        this.setState({ markerEditorOpen: !this.state.markerEditorOpen });
    }

    render() {
        const { annotation } = this.props;
        const { markerEditorOpen, markerPathId, fillColor, markerSize, opacity, labelPosition, labelVisible, textSize } = this.state;

        const selectedItem = POSITION_ITEMS.find(item => item.text.toLowerCase() === labelPosition.toLowerCase());

        return (<div className="flex-it column">
            <MarkerPropertyEditor
                markerEditorOpen={markerEditorOpen}
                markerPathId={markerPathId}
                fillColor={fillColor}
                onCloseRequest={this.onCloseRequest}
                onTriggerClick={this.onTriggerClick}
                onMarkerStyleChange={this.onMarkerStyleChange}
            />
            <NumberPropertyEditor
                label="Size"
                value={markerSize}
                onChange={this.onMarkerSizeChanged}
                min={0}
                max={4}
            />
            <NumberPropertyEditor
                label="Opacity"
                value={opacity * 100}
                onChange={this.onOpacityChanged}
                min={0}
                max={100}
                unit="%"
            />
            <div className="divider annotation-card__separator" />
            <TogglePropertyEditor
                onToggleLabel={this.onToggleLabel}
                labelVisible={labelVisible}
            />
            {labelVisible &&
                <ColorPropertyEditor
                    key="color-editor"
                    color={annotation.textColor}
                    label="Color"
                    onColorChanged={this.onTextColorChanged}
                />
            }
            {labelVisible &&
                <NumberPropertyEditor
                    key="size-editor"
                    label="Size"
                    value={textSize}
                    onChange={this.onTextSizeChanged}
                    min={0}
                    max={4}
                />
            }
            {labelVisible &&
                <div className="annotation-property annotation-property--with-margin" key="position">
                    <div className="body-1 annotation-property__label">Position</div>
                    <ButtonToggle
                        items={POSITION_ITEMS}
                        selectedItem={selectedItem}
                        onItemClick={this.onPositionChanged}
                    />
                </div>
            }
        </div>);
    }
}

EditMarker.propTypes = {
    annotation: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default EditMarker;
