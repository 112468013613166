import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import BusComponent from '../BusComponent';

class CustomLocationAnalysisValueLimitPopup extends BusComponent {
    onCloseModal = () => {
        this.emit('CLOSE_MODAL');
    }

    render() {
        const { geographiesLimit, intl } = this.props;
        return (
            <div>
                <p>
                    <FormattedMessage
                        id="modals.customValueLimitDescription1"
                        values={{ geographiesLimit }}
                    />
                </p>
                <p><FormattedMessage id="modals.customValueLimitDescription2" /></p>
                <p><FormattedMessage id="modals.customValueLimitDescription3" /></p>
                <table>
                    <thead>
                        <tr>
                            <th>{intl.formatMessage({ id: 'modals.location' })}</th>
                            <th>5 miles</th>
                            <th>10 miles</th>
                            <th>20 miles</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Manhattan</td>
                            {/* Following values were calculated manually for selected areas (with selected radiuses) */}
                            <td>1868</td>
                            <td>5451</td>
                            <td>9706</td>
                        </tr>
                        <tr>
                            <td>Boston</td>
                            <td>489</td>
                            <td>1357</td>
                            <td>2284</td>
                        </tr>
                        <tr>
                            <td>Los Angeles</td>
                            <td>399</td>
                            <td>1810</td>
                            <td>4285</td>
                        </tr>
                    </tbody>
                </table>

                <div className="flex-it center flex-end footer custom-value-limit-warning-popup-content__footer">
                    <button className="btn-flat" onClick={this.onCloseModal}>
                        {intl.formatMessage({ id: 'ok' })}
                    </button>
                </div>
            </div>
        );
    }
}

CustomLocationAnalysisValueLimitPopup.propTypes = {
    intl: PropTypes.object.isRequired,
    geographiesLimit: PropTypes.number.isRequired,
};

export default injectIntl(CustomLocationAnalysisValueLimitPopup);
