// @ts-check
import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import VisualizationType from '../../../enums/VisualizationType';
import Orientation from '../../../enums/Orientation';

import Popover from '../../popover/Popover';

class AutoAdjustInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
    }

    onToggle = event => {
        event.stopPropagation();
        this.setState(prevState => ({ open: !prevState.open }));
    };

    render() {
        const { intl, mapInstance, orientation } = this.props;
        const { open } = this.state;

        const ariaLabel = open ?
                          intl.formatMessage({ id: 'showHide.hide.autoAdjustInfo' }) :
                          intl.formatMessage({ id: 'showHide.show.autoAdjustInfo' });

        let infoMessage;
        switch (mapInstance.dataTheme.visualizationType) {
        case VisualizationType.BUBBLES:
            infoMessage = intl.formatMessage({ id: 'legend.bubblesAutoAdjustInfo' });
            break;
        case VisualizationType.SHADED_AREA:
        default:
            infoMessage = intl.formatMessage({ id: 'legend.shadedAreaAutoAdjustInfo' });
            break;
        }

        let anchorOrigin, targetOrigin;
        switch (orientation) {
        case Orientation.LEFT:
            anchorOrigin = { horizontal: 'right', vertical: 'bottom' };
            targetOrigin = { horizontal: 'left', vertical: 'top' };
            break;
        case Orientation.RIGHT:
            anchorOrigin = { horizontal: 'left', vertical: 'bottom' };
            targetOrigin = { horizontal: 'right', vertical: 'top' };
            break;
        }

        return (
            <div>
                <button
                    ref={e => { this.trigger = e; }}
                    className="btn-icon btn-icon--small"
                    onClick={this.onToggle}
                    aria-label={ariaLabel}
                >
                    <i className="material-icons" aria-hidden>
                        info_outline
                    </i>
                </button>
                {this.trigger && (
                    <Popover
                        anchorEl={this.trigger}
                        className="auto-adjust__popover"
                        open={open}
                        triggerToggle
                        anchorOrigin={anchorOrigin}
                        targetOrigin={targetOrigin}
                        onCloseRequest={this.onToggle}
                    >
                        <p>
                            {infoMessage}
                        </p>
                    </Popover>
                )}
            </div>
        );
    }
}

AutoAdjustInfo.propTypes = {
    mapInstance: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    orientation: PropTypes.oneOf(Object.keys(Orientation)).isRequired,
};

AutoAdjustInfo.defaultProps = {};

export default injectIntl(AutoAdjustInfo);
