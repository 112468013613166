import Api from '../apis/Api';
import GLU from '../glu2.js/src/index';

import LocationAnalysisItem from '../objects/LocationAnalysisItem';
import UserMapView from '../objects/UserMapView';
import InitialView from '../objects/InitialView';
import LocationAnalysisItemOrigin from '../enums/LocationAnalysisItemOrigin';
import AppConfig from '../appConfig';
import { ALLOWED_POINT_TYPES_FOR_MY_PLACES_ANALYSIS } from '../enums/PoiTypes';

class UserSettingsDataSource extends GLU.DataSource {
    static get name() {
        return 'UserSettingsDataSource';
    }

    static getInstance() {
        return new UserSettingsDataSource();
    }

    constructor() {
        super(() => false);
        this._userMapViews = [];
        /** @type {LocationAnalysisItem[]} */
        this._userLocations = [];
    }

    get userMapViews() {
        return this._userMapViews;
    }

    /** @returns {LocationAnalysisItem[]} */
    get userLocations() {
        return this._userLocations;
    }

    loadUserMapViews() {
        return new Promise((resolve, reject) => {
            Api.userMapView.getUserMapViews().then(response => {
                if (response.body) {
                    this._userMapViews = response.body.map(mapView => {
                        const userMapView = new UserMapView({
                            id: mapView.id,
                            title: mapView.title,
                            mapView: new InitialView(mapView),
                        });

                        return userMapView;
                    });
                }
                resolve(this._userMapViews);
            }, reject);
        });
    }

    saveUserMapView(payload) {
        return new Promise(resolve => {
            Api.userMapView.insertUserMapView({ payload }).then(response => {
                // Add item to data source
                resolve(response.body);
            });
        });
    }

    updateUserMapView(payload) {
        return new Promise((resolve, reject) => {
            Api.userMapView.updateUserMapView({ payload }).then(response => {
                resolve(response.body);
            }, reject);
        });
    }

    removeUserMapView(payload) {
        return new Promise((resolve, reject) => {
            Api.userMapView.deleteUserMapView({ payload }).then(response => {
                resolve(response.body);
            }, reject);
        });
    }

    loadUserLocations() {
        return new Promise((resolve, reject) => {
            Api.userLocation.getUserLocations().then(response => {
                if (response.body) {
                    this._userLocations = response.body
                        .map(locationResponse =>
                            this.parseUserLocation(locationResponse),
                        )
                        // sort user locations by id to show the last added
                        // at the top of the list
                        // locations get sorted by their IDs in the DB (not the location ID itself)
                        .sort((l1, l2) => l2.itemOriginMetadata.id - l1.itemOriginMetadata.id);
                }
                resolve(this._userLocations);
            }, reject);
        });
    }

    /**
     * @param {object} payload
     * @param {string} payload.title
     * @param {number} payload.centerLat
     * @param {number} payload.centerLng
     * @param {object} payload.metadata
     */
    saveUserLocation(payload) {
        return new Promise((resolve, reject) => {
            Api.userLocation.addUserLocation({ payload }).then(response => {
                const userLocation = this.parseUserLocation(response.body);
                this._userLocations.unshift(userLocation);
                resolve(userLocation);
            }, reject);
        });
    }

    /**
     * @param {object} payload
     * @param {string} payload.title
     * @param {number} payload.locationId
     * Update user location. For now only the title is available for update
     */
    updateUserLocation(payload) {
        return new Promise((resolve, reject) => {
            Api.userLocation.updateUserLocation({ payload }).then(response => {
                const userLocation = this.parseUserLocation(response.body);
                // Replace new location with the old one
                // Remove old user location
                const index = this._userLocations.findIndex(
                    location => location.itemOriginMetadata.id === payload.locationId,
                );
                // Add updated user location to the same position
                this._userLocations[index] = userLocation;
                resolve(userLocation);
            }, reject);
        });
    }

    /**
     * @param {number} locationId
     * Delete user location
     */
    deleteUserLocation(locationId) {
        return new Promise((resolve, reject) => {
            Api.userLocation
                .deleteUserLocation({
                    payload: {
                        locationId,
                    },
                })
                .then(() => {
                    // Remove deleted user location
                    const index = this._userLocations.findIndex(
                        location => location.itemOriginMetadata.id === locationId,
                    );
                    this._userLocations.splice(index, 1);
                    resolve(this._userLocations);
                }, reject);
        });
    }


    /**
    *
    * @param {string} input
    * @returns decoded string (e.g. &amp; gets decoded to &)
    */
    htmlDecode(input) {
        const doc = new DOMParser().parseFromString(input, 'text/html');
        return doc.documentElement.textContent;
    }

    getIconForPointType = type => {
        switch (type) {
        case 'SELFSTORAGE_FACILITY':
            return 'warehouse';
        case 'SELFSTORAGE_CONSTRUCTION':
            return 'front_loader';
        default:
            return 'place';
        }
    }

    /**
     * @param {import('../').UserLocationResponse} locationResponse
     * @returns {LocationAnalysisItem}
     * This function parses the user location response object to the needed
     * LocationAnalysisItem object
     */
    parseUserLocation = locationResponse => {
        let locationId;
        let icon;
        if (ALLOWED_POINT_TYPES_FOR_MY_PLACES_ANALYSIS.some(pt => pt === locationResponse.metadata.type)) {
            locationId = locationResponse.metadata.metadata.id;
            icon = this.getIconForPointType(locationResponse.metadata.type);
        } else {
            locationId = `${locationResponse.centerLng};${locationResponse.centerLat}`;
            icon = 'place';
        }
        return new LocationAnalysisItem({
            id: locationId,
            type: 'place',
            value: this.htmlDecode(locationResponse.title),
            point: {
                lat: locationResponse.centerLat,
                lng: locationResponse.centerLng,
            },
            metadata: locationResponse.metadata,
            itemOrigin: LocationAnalysisItemOrigin.USER_SAVED_LOCATION,
            // add default selection and analysis type
            analysisTypeId:
                AppConfig.constants.defaultLocationAnalysisSelectionType,
            selection: new Set(
                AppConfig.constants.defaultLocationAnalysisSelection,
            ),
            icon,
            itemOriginMetadata: {
                id: locationResponse.id,
            },
        });
    }
}

export default UserSettingsDataSource;
