/* eslint react/no-array-index-key: 0 */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ColorPaletteEditorType from '../../../../enums/ColorPaletteEditorType';
import ColorPaletteEditor from '../../../colorPaletteEditor/ColorPaletteEditor';

const ColorPaletteSample = props => {
    const numberOfSamples = props.numberOfSamples || 10;
    let colors;
    let colorList;
    const colorPaletteEditorType = ColorPaletteEditor.getEditorType(props.colorPalette.type);
    switch (colorPaletteEditorType) {
    case ColorPaletteEditorType.GRADIENT:
        colors = props.colorPalette.interpolateColors(numberOfSamples).map((c, i) => (<div
            key={i} className="color-palette-sample__block"
            style={{ backgroundColor: `${c}` }}
        />));
        break;
    case ColorPaletteEditorType.GRADIENT_MULTI:
        colorList = props.colorPalette.colorRamps.map(colorRamp => colorRamp.interpolateColors(3)[1]);
        colors = colorList.map((c, i) => (<div
            key={i} className="color-palette-sample__block"
            style={{ backgroundColor: `${c}` }}
        />));
        break;
    case ColorPaletteEditorType.COLOR_MULTI:
        colors = props.colorPalette.colors.map((c, i) => (<div
            key={i} className="color-palette-sample__block"
            style={{ backgroundColor: `${c}` }}
        />));
        break;
    case ColorPaletteEditorType.COLOR_SINGLE:
        colors = (<div
            key={props.colorPalette.colors[0]}
            className="color-palette-sample__circle"
            style={{ backgroundColor: `${props.colorPalette.colors[0]}` }}
        />);
        break;
    }
    const colorPaletteClasses = classNames('color-palette-sample', {
        'color-palette-sample--single': colorPaletteEditorType === ColorPaletteEditorType.COLOR_SINGLE,
        'color-palette-sample--flipped': props.flipped,
    });
    return (<div className={colorPaletteClasses}>{colors}</div>);
};

ColorPaletteSample.propTypes = {
    numberOfSamples: PropTypes.number,
    className: PropTypes.string,
    colorPalette: PropTypes.object.isRequired,
    flipped: PropTypes.bool,
};

ColorPaletteSample.defaultProps = {
    numberOfSamples: 10,
    className: undefined,
    flipped: false,
};

export default ColorPaletteSample;
