import VariableType from '../enums/VariableType';
import VisualizationType from '../enums/VisualizationType';
import ColorPaletteType from '../enums/ColorPaletteType';
import Tag from '../enums/Tag';
import VariableValueType from '../enums/VariableValueType';

class MetaVariable {

    constructor() {
        this._enabled = true;
        this._availableToUser = true;
    }

    get uuid() {
        return this._uuid;
    }

    set uuid(uuid) {
        this._uuid = uuid;
    }

    // eslint-disable-next-line
    get name() {
        return this._name;
    }

    set name(name) {
        this._name = name;
    }

    get label() {
        return this._label;
    }

    set label(label) {
        this._label = label;
    }

    get qLabel() {
        return this._qLabel;
    }

    set qLabel(qLabel) {
        this._qLabel = qLabel;
    }

    get dataType() {
        return this._dataType;
    }

    set dataType(dataType) {
        this._dataType = dataType;
    }

    get formatting() {
        return this._formatting;
    }

    set formatting(formatting) {
        this._formatting = formatting;
    }

    get indent() {
        return this._indent;
    }

    set indent(indent) {
        this._indent = indent;
    }

    get notes() {
        return this._notes;
    }

    set notes(notes) {
        this._notes = notes;
    }

    get isVirtual() {
        return this._isVirtual === true;
    }

    set isVirtual(isVirtual) {
        this._isVirtual = isVirtual;
    }

    get expression() {
        return this._expression;
    }

    set expression(expression) {
        this._expression = expression;
    }

    get suggestedPaletteName() {
        if (this.table && this.table.suggestedColorPaletteName && this.table.suggestedColorPaletteName !== '') {
            return this.table.suggestedColorPaletteName;
        }
        return this._suggestedPaletteName;
    }

    set suggestedPaletteName(suggestedPaletteName) {
        this._suggestedPaletteName = suggestedPaletteName;
    }

    get suggestedPaletteInverse() {
        if (this.table && this.table.suggestedColorPaletteInverse && this.table.suggestedColorPaletteInverse !== '') {
            return this.table.suggestedColorPaletteInverse;
        }
        return this._suggestedPaletteInverse;
    }

    set suggestedPaletteInverse(suggestedPaletteInverse) {
        this._suggestedPaletteInverse = suggestedPaletteInverse;
    }

    get suggestedPaletteType() {
        if (this.table && this.table.suggestedColorPaletteType && this.table.suggestedColorPaletteType !== '') {
            return this.table.suggestedColorPaletteType;
        }
        return this._suggestedPaletteType;
    }

    set suggestedPaletteType(suggestedPaletteType) {
        this._suggestedPaletteType = suggestedPaletteType;
    }

    get varType() {
        return this._varType || VariableType.NONE;
    }

    set varType(varType) {
        this._varType = varType;
    }

    get tooltipCustom() {
        return this._tooltipCustom;
    }

    set tooltipCustom(tooltipCustom) {
        this._tooltipCustom = tooltipCustom;
    }

    /**
     * Populated by the Data team
     * 0 - small value variables, 1- normal value variables, 2 -large value variables
     * @type {number}
     */
    get bubbleSizeHint() {
        return this._bubbleSizeHint;
    }

    set bubbleSizeHint(bubbleSizeHint) {
        this._bubbleSizeHint = bubbleSizeHint;
    }

    /** @returns {string | undefined} if variable default filter set is set it will return the variable default filter set, otherwise, it will take the table default filter set */
    get defaultFilterSetName() {
        if (this._defaultFilterSetName && this._defaultFilterSetName !== '') {
            return this._defaultFilterSetName;
        }
        return this.table ? this.table.defaultFilterSetName : undefined;
    }

    set defaultFilterSetName(defaultFilterSetName) {
        this._defaultFilterSetName = defaultFilterSetName;
    }

    get dbFieldName() {
        return this._dbFieldName;
    }

    set dbFieldName(dbFieldName) {
        this._dbFieldName = dbFieldName;
    }

    get table() {
        return this._table;
    }

    set table(table) {
        this._table = table;
    }

    get enabled() {
        return this._enabled;
    }

    set enabled(enabled) {
        this._enabled = enabled;
    }

    get availableToUser() {
        return this._availableToUser;
    }

    set availableToUser(availableToUser) {
        this._availableToUser = availableToUser;
    }

    get isHiddenFromUser() {
        return this._isHiddenFromUser;
    }

    set isHiddenFromUser(isHiddenFromUser) {
        this._isHiddenFromUser = isHiddenFromUser;
    }

    get index() {
        return this._index;
    }

    set index(index) {
        this._index = index;
    }

    /** @returns {string} */
    get preferredVisualizationType() {
        return this._preferredVisualizationType;
    }

    set preferredVisualizationType(preferredVisualizationType) {
        this._preferredVisualizationType = preferredVisualizationType;
    }

    /** @returns {string} */
    get preferredVisualizationValueType() {
        return this._preferredVisualizationValueType;
    }

    set preferredVisualizationValueType(preferredVisualizationValueType) {
        this._preferredVisualizationValueType = preferredVisualizationValueType;
    }

    get mapInfoButtonHtmlText() {
        return this._mapInfoButtonHtmlText;
    }

    set mapInfoButtonHtmlText(mapInfoButtonHtmlText) {
        this._mapInfoButtonHtmlText = mapInfoButtonHtmlText;
    }
    /**
     * Derived from bubbleSizeHint
     * This property is used to calculate the bubbleSizeFactor
     * in the BubbleRenderer
     * @type {number}
     */
    get bubbleSizeScale() {
        if (this.bubbleSizeHint === 0) return 1;
        if (this.bubbleSizeHint === 2) return 100000;
        return 8000;
    }

    getIndentedLabel(dollarYear) {
        let indentedLabel = '';
        for (let i = 0; i < this.indent * 5; i += 1) {
            indentedLabel += ' ';
        }
        indentedLabel += this.getLabel(dollarYear, false);
        return indentedLabel;
    }

    // Returns default percent base variable index, which is the index of the first parent variable that has
    // indent == 0, that is the default percent base.
    // Returns -1 if indent == 0 (meaning this var has no universe var), or aggregation is NOT addition.
    get defaultPercentBaseVariable() {
        if (this.varType === VariableType.COUNT) {
            return this.parentVariable;
        }
        return undefined;
    }

    /**
     * Variable is count only if the type is COUNT and
     * if it's not a parent variable to any other variable
     * It's either the first variable in the table or the indent is 0
     * @returns {boolean}
     */
    get isVariableCountOnly() {
        return (
            this.varType === VariableType.COUNT &&
            this.table &&
            (this.table.getVariableByIndex(0).uuid === this.uuid ||
                this.indent === 0)
        );
    }

    /**
     * Show percentage/number control only for count variables
     * that have a parent variable since only in that case it is
     * possible to calculate percentage
     * @returns {boolean}
     */
    get showPercentageControl() {
        return (
            this.varType === VariableType.COUNT && this.parentVariable !== undefined
        );
    }

    get isCurrency() {
        return this.formatting.indexOf('Currency') > -1;
    }

    // parent variable is next first variable up the index tree with indent that equals 0
    get parentVariable() {
        if (this.indent > 0 && this.table) {
            const variableIndex = this.index;
            const variables = this.table.variablesAsArray;
            for (let i = variableIndex; i >= 0; i -= 1) {
                if (variables[i].indent === 0) return variables[i];
            }
            return undefined;
        }

        return undefined;
    }

    get parsedCustomTooltip() {
        if (this._parsedCustomTooltip !== undefined) return this._parsedCustomTooltip;
        this._parsedCustomTooltip = {
            text: undefined,
            variables: [],
        };
        if (!this.tooltipCustom || this.tooltipCustom === '') return this._parsedCustomTooltip;

        // Description of possible custom tooltips
        // TEXT:               Some text
        // VARIABLE:           #SE.A00002.A00002_001
        // MULTIPLE VARIABLES  #SE.A00002.A00002_001;#SE.A00002.A00002_002 (split with ;)

        // check if custom tooltip is text
        if (!this.tooltipCustom.startsWith('#')) {
            this._parsedCustomTooltip.text = this.tooltipCustom;
            return this._parsedCustomTooltip;
        }

        const variables = this.tooltipCustom.split(';');
        variables.forEach(variable => {
            const variableQualifiedName = variable.split('#')[1];
            if (variableQualifiedName) {
                const parts = variableQualifiedName.split('.');
                this._parsedCustomTooltip.variables.push({
                    surveyName: this.table.dataset.survey.name,
                    datasetAbbrevation: parts[0],
                    tableName: parts[1],
                    variableName: parts[2],
                    label: variableQualifiedName,
                });
            }
        });

        return this._parsedCustomTooltip;
    }

    /**
     * @param {number} dollarYear
     * @param {boolean} stripLastColon
     * @returns {string}
     */
    getLabel(dollarYear, stripLastColon) {
        let label = '';
        if (dollarYear === 0) {
            label = this.label.replace(Tag.DOLLAR_YEAR, this.table.dollarYear);
        } else {
            label = this.label.replace(Tag.DOLLAR_YEAR, dollarYear);
        }

        // Trim whitespaces
        label = label.trim();

        if (stripLastColon && label.length > 0 && label[label.length - 1] === ':') {
            label = label.substring(0, label.length - 1);
        }

        return label;
    }

    canUseDotDensity() {
        if (this.varType === VariableType.MEDIAN || this.varType === VariableType.PERCENT ||
            this.varType === VariableType.AVERAGE || this.varType === VariableType.STANDARD_ERROR ||
            this.varType === VariableType.NONE || this.varType === VariableType.QUINTILE ||
            this.varType === VariableType.COMPUTED_INDEX || this.varType === VariableType.RATE) {
            return false;
        }
        return true;
    }

    canUseBubbles() {
        const parentVariable = this.parentVariable;
        if (this.varType === VariableType.NONE && parentVariable &&
            parentVariable.varType === VariableType.COUNT &&
            this.defaultFilterSetName && this.defaultFilterSetName !== '') {
            return true;
        }
        return this.canUseDotDensity();
    }

    /**
     * @returns {import('../../../types').VariablePreferredVisualizationInfo | undefined} Preferred visualization like SHADED - Percent
     */
    preferredVisualizationInfo() {
        if (this.preferredVisualizationType) {
            return {
                preferredVisualizationType: this.preferredVisualizationType,
                preferredVisualizationValueType: this.preferredVisualizationValueType,
            };
        } else if (this.isVariableCountOnly) {
            // if a count only variable doesn't have any preferred options
            // use bubbles since this was the default behavior before shaded was enabled
            // for all variables
            return {
                preferredVisualizationType: VisualizationType.BUBBLES,
                preferredVisualizationValueType: VariableValueType.NUMBER,
            };
        }

        // For all other variables the behavior will remain the same
        return undefined;
    }

    getSuggestedColorPaletteType(visualizationType) {
        const metadataColorPaletteTypeStr = this.suggestedPaletteType || '';
        const isDiverging = metadataColorPaletteTypeStr.toLowerCase().substr(0, 3) === 'div';
        if (visualizationType === VisualizationType.SHADED_AREA) {
            return isDiverging ? ColorPaletteType.POLYGON_DIVERGING : ColorPaletteType.POLYGON_SEQUENTIAL;
        }
        if (visualizationType === VisualizationType.BUBBLES) {
            return isDiverging ? ColorPaletteType.BUBBLE_DIVERGING : ColorPaletteType.BUBBLE_SEQUENTIAL;
        }
        return undefined;
    }

    clone() {
        const metaVariable = new MetaVariable();
        metaVariable.uuid = this.uuid;
        metaVariable.name = this.name;
        metaVariable.label = this.label;
        metaVariable.qLabel = this.qLabel;
        metaVariable.dataType = this.dataType;
        metaVariable.isVirtual = this.isVirtual;
        metaVariable.expression = this.expression;
        metaVariable.indent = this.indent;
        metaVariable.formatting = this.formatting;
        metaVariable.notes = this.notes;
        metaVariable.varType = this.varType;
        metaVariable.tooltipCustom = this.tooltipCustom;
        metaVariable.defaultFilterSetName = this.defaultFilterSetName;
        metaVariable.dbFieldName = this.dbFieldName;
        metaVariable.bubbleSizeHint = this.bubbleSizeHint;
        metaVariable.suggestedPaletteName = this.suggestedPaletteName;
        metaVariable.suggestedPaletteType = this.suggestedPaletteType;
        metaVariable.suggestedPaletteInvers = this.suggestedPaletteInverse;
        metaVariable.table = this.table;
        metaVariable.mapInfoButtonHtmlText = this.mapInfoButtonHtmlText;
        return metaVariable;
    }

    toJSON() {
        return {
            uuid: this.uuid,
            name: this.name,
            label: this.label,
            qLabel: this.qLabel,
            dataType: this.dataType,
            isVirtual: this.isVirtual,
            expression: this.expression,
            indent: this.indent,
            formatting: this.formatting,
            notes: this.notes,
            varType: this.varType,
            tooltipCustom: this.tooltipCustom,
            defaultFilterSetName: this.defaultFilterSetName,
            dbFieldName: this.dbFieldName,
            bubbleSizeHint: this.bubbleSizeHint,
            suggestedPaletteName: this.suggestedPaletteName,
            suggestedPaletteType: this.suggestedPaletteType,
            suggestedPaletteInverse: this.suggestedPaletteInverse,
            mapInfoButtonHtmlText: this.mapInfoButtonHtmlText,
        };
    }
}

export default MetaVariable;
