import GLU from '../glu2.js/src/index';

import { isStorageAvailable } from '../helpers/Util';

const STORAGE = window.localStorage;
const SETTINGS_VERSION = '1';
const STORAGE_PREFIX = 'Export';
const IS_STORAGE_AVAILABLE = isStorageAvailable(STORAGE);

class ExportDataSource extends GLU.DataSource {
    static get name() {
        return 'ExportDataSource';
    }

    static getInstance() {
        return new ExportDataSource();
    }

    hasSavedSettings() {
        return (IS_STORAGE_AVAILABLE && STORAGE.getItem(`${STORAGE_PREFIX}.version`) === SETTINGS_VERSION);
    }

    saveSettings(payload) {
        if (IS_STORAGE_AVAILABLE) {
            const { overlayOrigin, zoom, center, targetAspectRatio, targetZoomLevel, targetDPI, targetFormat } = payload;

            STORAGE.setItem(`${STORAGE_PREFIX}.version`, SETTINGS_VERSION);

            STORAGE.setItem(`${STORAGE_PREFIX}.overlay.ne.lng`, overlayOrigin.lng);
            STORAGE.setItem(`${STORAGE_PREFIX}.overlay.ne.lat`, overlayOrigin.lat);

            STORAGE.setItem(`${STORAGE_PREFIX}.mapviewer.zoom`, zoom.toFixed(2));
            STORAGE.setItem(`${STORAGE_PREFIX}.mapviewer.center.lng`, center.lng);
            STORAGE.setItem(`${STORAGE_PREFIX}.mapviewer.center.lat`, center.lat);

            STORAGE.setItem(`${STORAGE_PREFIX}.targetAspectRatio`, targetAspectRatio);
            STORAGE.setItem(`${STORAGE_PREFIX}.targetZoomLevel`, parseFloat(targetZoomLevel.toFixed(2)));
            STORAGE.setItem(`${STORAGE_PREFIX}.targetDPI`, targetDPI);
            STORAGE.setItem(`${STORAGE_PREFIX}.targetFormat`, targetFormat);
        }

        return undefined;
    }

    loadSettings() {
        if (!IS_STORAGE_AVAILABLE) return undefined;

        const overlayOrigin = {
            lng: STORAGE.getItem(`${STORAGE_PREFIX}.overlay.ne.lng`),
            lat: STORAGE.getItem(`${STORAGE_PREFIX}.overlay.ne.lat`),
        };

        const mapViewerCenter = {
            lng: STORAGE.getItem(`${STORAGE_PREFIX}.mapviewer.center.lng`),
            lat: STORAGE.getItem(`${STORAGE_PREFIX}.mapviewer.center.lat`),
        };

        const mapViewerZoom = parseFloat(STORAGE.getItem(`${STORAGE_PREFIX}.mapviewer.zoom`));

        const targetAspectRatio = STORAGE.getItem(`${STORAGE_PREFIX}.targetAspectRatio`);
        const targetZoomLevel = parseFloat(STORAGE.getItem(`${STORAGE_PREFIX}.targetZoomLevel`));
        const targetDPI = parseFloat(STORAGE.getItem(`${STORAGE_PREFIX}.targetDPI`));
        const targetFormat = STORAGE.getItem(`${STORAGE_PREFIX}.targetFormat`);

        return {
            overlayOrigin,
            mapViewerCenter,
            mapViewerZoom,
            targetAspectRatio,
            targetZoomLevel,
            targetDPI,
            targetFormat,
        };
    }
}

export default ExportDataSource;
