// @ts-check
import React from 'react';
import PropTypes from 'prop-types';

import BusComponent from '../BusComponent';
import LocationAnalysis from './LocationAnalysis';
import LocationAnalysisTypeSelection from './LocationAnalysisTypeSelection';
import LocationHeader from './LocationHeader';

import LocationAnalysisType from '../../enums/LocationAnalysisType';
import FrameType from '../../enums/FrameType';

export const STATE_TYPES = ['administrativeArea', 'state'];

/**
 * @typedef Props
 * @property {import('../../objects/MapInstance').default} mapInstance
 * @property {boolean} isUserPro
 *
 * @typedef State
 * @property {FrameType} frameType
 * @property {import('../../objects/LocationAnalysisItem').default} locationAnalysisItem
 *
 * @extends BusComponent<Props, State>
 */
class LocationPanel extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            frameType: null,
            locationAnalysisItem: this.props.mapInstance.locationAnalysisItem,
        };
    }

    componentDidMount() {
        this.bindGluBusEvents({
            CURRENT_FRAME: this.onCurrentFrame,
            FRAME_UPDATED: this.onFrameUpdated,
            LOCATION_ANALYSIS_ITEM_UPDATED: this.onLocationAnalysisItemUpdated,
        });
        this.bus.emit('CURRENT_FRAME_REQUEST');
    }

    /** @param {Readonly<Props>} prevProps */
    componentDidUpdate(prevProps) {
        if (prevProps.mapInstance !== this.props.mapInstance) {
            this.emit('EXIT_LOCATION_ANALYSIS_MODE', {
                mapInstanceId: this.props.mapInstance.id,
            });
        }
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    /**
     * @param {object} payload
     * @param {import('../../objects/LocationAnalysisItem').default} payload.locationAnalysisItem
     */
    onLocationAnalysisItemUpdated = ({ locationAnalysisItem }) => {
        this.setState({
            locationAnalysisItem,
        });
    };

    /** @param {string} analysisTypeId */
    onSelectType = analysisTypeId => {
        const { mapInstance } = this.props;
        const { frameType } = this.state;

        if (frameType !== FrameType.SINGLE_MAP) {
            this.emit('SINGLE_MAP_SWITCH_WARNING_REQUEST', {
                mapInstanceId: mapInstance.id,
                analysisTypeId,
            });
            return;
        }

        this.emit('SET_LOCATION_ANALYSIS_TYPE', {
            mapInstanceId: mapInstance.id,
            analysisTypeId,
        });
    };

    /**
     * @param {import('../../objects/Frame').default} currentFrame
     */
    onCurrentFrame = currentFrame => {
        this.setState({ frameType: currentFrame.type });
    };

    /**
     * @param {object} param0
     * @param {import('../../objects/Frame').default} param0.frame
     */
    onFrameUpdated = ({ frame }) => {
        this.setState({ frameType: frame.type });
    };

    render() {
        const { mapInstance } = this.props;

        let analysisType;
        if (
            this.state.locationAnalysisItem &&
            this.state.locationAnalysisItem.analysisTypeId
        ) {
            analysisType =
                LocationAnalysisType[
                    this.state.locationAnalysisItem.analysisTypeId
                ];
        }

        if (analysisType && this.state.frameType === FrameType.SINGLE_MAP) {
            return (
                <div>
                    <LocationAnalysis
                        locationAnalysisItem={this.state.locationAnalysisItem}
                        mapInstance={mapInstance}
                    />
                </div>
            );
        }

        // For state types we do not show the location analysis panel
        const isNotExcluded = !STATE_TYPES.includes(
            this.state.locationAnalysisItem.type,
        );

        return (
            <div className="location-panel">
                <LocationHeader
                    mapInstanceId={mapInstance.id}
                    selectedItem={this.state.locationAnalysisItem}
                />
                {isNotExcluded && (
                    <LocationAnalysisTypeSelection
                        onSelectType={this.onSelectType}
                        isUserPro={this.props.isUserPro}
                    />
                )}
            </div>
        );
    }
}

LocationPanel.propTypes = {
    mapInstance: PropTypes.object.isRequired,
    isUserPro: PropTypes.bool,
    onMinimize: PropTypes.func,
};

LocationPanel.defaultProps = {
    isUserPro: false,
};

export default LocationPanel;
