import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import AppConfig from '../../appConfig';

class Upgrade extends React.Component {
    onUpgradeClicked = () => {
        window.open(AppConfig.constants.links.licenses);
    };

    render() {
        const { className, text } = this.props;
        const classes = classnames(
            'upgrade-link flex-it center space-between',
            className,
        );
        return (
            <div className={classes}>
                <h6 className="light-text">
                    {text ||
                        this.props.intl.formatMessage({
                            id: 'header.upgradeToProfessionalPlan',
                        })}
                </h6>
                <button
                    aria-label={this.props.intl.formatMessage({
                        id: 'upgrade',
                    })}
                    className="btn-flat btn-flat--light upgrade-link__btn"
                    onClick={this.onUpgradeClicked}
                >
                    {this.props.intl.formatMessage({ id: 'upgrade' })}
                </button>
            </div>
        );
    }
}

Upgrade.propTypes = {
    className: PropTypes.string,
    text: PropTypes.string,
    intl: PropTypes.object.isRequired,
};

Upgrade.defaultProps = {
    className: '',
    text: null,
};

export default injectIntl(Upgrade);
