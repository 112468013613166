// @ts-check

/**
 *
 * @param {import('../objects/MetaVariable').default[]} tableVariables
 * @param {import('../objects/MetaVariable').default} variable
 * @param {import('../objects/MetaVariable').default[]} tree
 * @returns {import('../objects/MetaVariable').default[]} Returns parent variables in the selected table for a specified variable
 */
export default function buildParentVariableTree(
    tableVariables,
    variable,
    tree,
) {
    if (variable) {
        for (let i = variable.index; i >= 0; i -= 1) {
            if (tableVariables[i].indent === variable.indent - 1) {
                return buildParentVariableTree(
                    tableVariables,
                    tableVariables[i],
                    [tableVariables[i]],
                ).concat(tree);
            }
        }
    }
    return tree;
}
