import React from 'react';
import PropTypes from 'prop-types';

import MapGeoLocator from './MapGeoLocator';
import UserMapViews from './mapViews/UserMapViews';
import BusComponent from '../BusComponent';

class MapLocations extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            isUserLoggedIn: false,
        };
    }

    componentDidMount() {
        // if the user logs in
        this.bindGluBusEvents({ USER_INFO_LOAD_SUCCESS: this.onUserInfo });
        this.bindGluBusEvents({ USER_INFO_GET_SUCCESS: this.onUserInfo });
        this.emit('USER_INFO_GET_REQUEST', { source: this });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    onUserInfo = (userInfo, source) => {
        if (source && this !== source) return;
        this.setState({
            isUserLoggedIn: userInfo && userInfo.userId !== -1,
        });
    }

    render() {
        const { mapInstance, alignLeft } = this.props;
        return (<div className="map-controls__group margin-bottom-10">
            {this.state.isUserLoggedIn &&
                <UserMapViews
                    mapInstanceId={mapInstance.id}
                    alignLeft={alignLeft}
                />
            }
            <MapGeoLocator mapInstanceId={mapInstance.id} />
        </div>);
    }
}

MapLocations.propTypes = {
    mapInstance: PropTypes.object.isRequired,
    alignLeft: PropTypes.bool,
};

MapLocations.defaultProps = {
    alignLeft: false,
};

export default MapLocations;
