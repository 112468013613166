import React from 'react';
import PropTypes from 'prop-types';

import DataClassificationEvents from '../../../enums/DataClassificationEvents';
import LegendLayout from '../../../enums/LegendLayout';
import NumberFormat from '../../../enums/NumberFormat';
import format from '../../../helpers/NumberFormatter';
import {
    getMetadataObjectsFromVariableSelectionItem,
    roundNumberToPrecision,
} from '../../../helpers/Util';

import BusComponent from '../../BusComponent';
import BubbleSimplified from './BubbleSimplified';
import BubbleDetailed from './BubbleDetailed';
import Orientation from '../../../enums/Orientation';

const RADIUS = {
    simplifiedCircle: 25,
    bigCircle: 30,
    smallCircle: 10,
};

class BubbleLegend extends BusComponent {
    constructor(props, context) {
        super(props, context);
        // For bubble size slider we are using an exponential function y=10^x
        // where y is the bubbleSize and x is the value of our slider
        this.state = {
            currentBubbleSizeLog10: 0,
        };

        this.bindGluBusEvents({
            MAP_NEW_BUBBLE_SIZE_FACTOR_APPLIED: this
                .onNewBubbleSizeFactorApplied,
            DATA_ANALYSIS_DATA_REQUEST_DONE: this.onDataAnalysisDone,
        });
    }

    componentDidMount() {
        delete this.currentMetadata;
        this.bus.once('CURRENT_METADATA', this.onCurrentMetadataRetrieved);
        this.emit('CURRENT_METADATA_REQUEST', { source: this });
    }

    componentWillReceiveProps(nextProps) {
        this.currentMetaSelection = getMetadataObjectsFromVariableSelectionItem(
            this.props.mapInstance.dataTheme.variableSelection.items[0],
            this.currentMetadata,
        );
        if (this.currentMetaSelection.variable) {
            const {
                bubbleSizeFactor,
            } = nextProps.mapInstance.dataTheme.rendering[0];

            this.setState({
                currentBubbleSizeLog10: this.currentBubbleSizeLog10(
                    bubbleSizeFactor,
                ),
            });
        }
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
        this.bus.off('CURRENT_METADATA', this.onCurrentMetadataRetrieved);
    }

    onAdjustBubblesClick = () => {
        this.emit(DataClassificationEvents.DATA_ANALYSIS_DATA_REQUEST, {
            mapInstanceId: this.props.mapInstance.id,
        });
    };

    onDataAnalysisDone(payload) {
        if (payload.mapInstanceId !== this.props.mapInstance.id) {
            return;
        }

        const variableDataAnalysis = payload.variablesData[this.currentMetaSelection.variable.uuid];

        const mapDOM = document.getElementById(
            `map-${this.props.mapInstance.id}`,
        );
        const mapWidth = mapDOM.clientWidth;
        const mapHeight = mapDOM.clientHeight;
        const MAP_SURFACE_FOR_BUBBLES = 0.05;
        // Here we calculate the targeted circle radius as if all the circles were the same
        const targetCircleRadius = Math.sqrt(
            (mapWidth * mapHeight * MAP_SURFACE_FOR_BUBBLES) /
            variableDataAnalysis.numberOfValues /
            Math.PI,
        );
        // Here we calculate the bubbleSizeFactor using the targeted circle radius and the upper90 value
        // from the data analysis. This formula is derived formulas for radius and bubbleRadiusMultiplier
        // upper90 value is used so that 90% of bubbles turn out smaller than the bubble with the targetCircleRadius
        const bubbleSizeFactor =
            ((targetCircleRadius ** 2) * Math.PI) / variableDataAnalysis.upper90;

        this.emit('MAP_BUBBLE_SIZE_CHANGE', {
            source: this,
            mapInstanceId: this.props.mapInstance.id,
            bubbleSizeFactor,
            bubbleSize:
                bubbleSizeFactor *
                this.currentMetaSelection.variable.bubbleSizeScale,
        });
    }

    onNewBubbleSizeFactorApplied(e) {
        if (
            e.mapInstanceId === this.props.mapInstance.id &&
            this.currentMetaSelection &&
            this.currentMetaSelection.variable
        ) {
            const {
                bubbleSizeFactor,
            } = this.props.mapInstance.dataTheme.rendering[0];
            this.setState({
                currentBubbleSizeLog10: this.currentBubbleSizeLog10(
                    bubbleSizeFactor,
                ),
            });
        }
    }

    onCurrentMetadataRetrieved = (metadata, target) => {
        if (target !== this) return;
        this.currentMetadata = metadata;
        this.currentMetaSelection = getMetadataObjectsFromVariableSelectionItem(
            this.props.mapInstance.dataTheme.variableSelection.items[0],
            metadata,
        );
        if (this.currentMetaSelection.variable) {
            const {
                bubbleSizeFactor,
            } = this.props.mapInstance.dataTheme.rendering[0];

            this.setState({
                currentBubbleSizeLog10: this.currentBubbleSizeLog10(
                    bubbleSizeFactor,
                ),
            });
        }
    };

    onBubbleSizeChange = value => {
        const bubbleSize = 10 ** value; // y=10^x
        const bubbleSizeFactor =
            bubbleSize / this.currentMetaSelection.variable.bubbleSizeScale;
        this.emit('MAP_BUBBLE_SIZE_CHANGE', {
            source: this,
            mapInstanceId: this.props.mapInstance.id,
            bubbleSizeFactor,
            bubbleSize,
        });
    };

    currentBubbleSizeLog10(bubbleSizeFactor) {
        // calculate the slider value y=10^x  ==> x=log(y)
        const { bubbleSizeScale } = this.currentMetaSelection.variable;
        const bubbleSize = bubbleSizeFactor * bubbleSizeScale;
        return Math.log10(bubbleSize);
    }

    render() {
        const { mapInstance, orientation } = this.props;
        const {
            bubbleSizeFactor,
            maxBubbleSize,
        } = mapInstance.dataTheme.rendering[0];

        const maxBubbleLabel = `> ${format({
            number: roundNumberToPrecision(
                ((maxBubbleSize ** 2) * Math.PI) / bubbleSizeFactor,
            ),
            numberFormat: NumberFormat.FORMAT_NUMBER_NO_DECIMAL,
        })}`;

        if (this.props.layout === LegendLayout.DETAILED) {
            return (
                <BubbleDetailed
                    variableGuid={
                        mapInstance.dataTheme.variableSelection
                            .items[0].variableGuid
                    }
                    mapInstance={mapInstance}
                    orientation={orientation}
                    renderer={mapInstance.dataTheme.rendering[0]}
                    bubbleValueType={
                        mapInstance.dataTheme.bubbleValueType
                    }
                    currentBubbleSizeLog10={this.state.currentBubbleSizeLog10}
                    onBubbleSizeChange={this.onBubbleSizeChange}
                    onAdjustBubblesClick={this.onAdjustBubblesClick}
                    annotationLegend={mapInstance.annotationLegend}
                    libraryLegendInfo={this.props.libraryLegendInfo}
                    maxBubbleLabel={maxBubbleLabel}
                    hasDataFilter={mapInstance.hasDataFilter}
                    onDataFilterInfoClick={this.props.onDataFilterInfoClick}
                    onEditSatelliteDetailsClick={
                        this.props.onEditSatelliteDetailsClick
                    }
                    onCloseSatelliteDetails={this.props.onCloseSatelliteDetails}
                    filterInfoOpen={this.props.filterInfoOpen}
                />
            );
        }

        const bubbleLabel = format({
            number: roundNumberToPrecision(
                ((RADIUS.simplifiedCircle ** 2) * Math.PI) / bubbleSizeFactor,
            ),
            numberFormat: NumberFormat.FORMAT_NUMBER_NO_DECIMAL,
        });

        return (
            <BubbleSimplified
                currentBubbleSizeLog10={this.state.currentBubbleSizeLog10}
                bubbleLabel={bubbleLabel}
                mapInstanceId={mapInstance.id}
                orientation={orientation}
                bubbleValueType={
                    mapInstance.dataTheme.bubbleValueType
                }
                onBubbleSizeChange={this.onBubbleSizeChange}
                onAdjustBubblesClick={this.onAdjustBubblesClick}
                renderer={mapInstance.dataTheme.rendering[0]}
                maxBubbleLabel={maxBubbleLabel}
                mapInstance={mapInstance}
            />
        );
    }
}

BubbleLegend.propTypes = {
    mapInstance: PropTypes.object.isRequired,
    orientation: PropTypes.oneOf(Object.keys(Orientation)).isRequired,
    layout: PropTypes.string.isRequired,
    libraryLegendInfo: PropTypes.array.isRequired,
    onDataFilterInfoClick: PropTypes.func.isRequired,
    onEditSatelliteDetailsClick: PropTypes.func.isRequired,
    onCloseSatelliteDetails: PropTypes.func.isRequired,
    filterInfoOpen: PropTypes.bool.isRequired,
    annotationLegend: PropTypes.object,
};

BubbleLegend.defaultProps = {
    annotationLegend: undefined,
};

export default BubbleLegend;
