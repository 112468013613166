const variableTypes = {
    COUNT: 'Count',
    MEDIAN: 'Median',
    PERCENT: 'Percent',
    STANDARD_ERROR: 'StandardError',
    AVERAGE: 'Average',
    RATE: 'Rate',
    QUINTILE: 'Quintile',
    COMPUTED_INDEX: 'ComputedIndex',
    NONE: 'None',
};

export default variableTypes;
