import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Tooltip from '../Tooltip';

const FlipColors = props => {
    const title = props.intl.formatMessage({ id: 'visualisationType.flipColors' });
    return (<Tooltip
        placement="bottom"
        mouseEnterDelay={0.5}
        mouseLeaveDelay={0}
        overlay={title}
    >
        <button
            className={classNames('btn-flat-icon', {
                'btn-flat-icon--primary': props.colorPaletteFlipped,
            })}
            onClick={props.onFlipColorsClick}
            aria-label={title}
        >
            <i className="material-icons">swap_horiz</i>
        </button>
    </Tooltip>);
};

FlipColors.propTypes = {
    onFlipColorsClick: PropTypes.func,
    colorPaletteFlipped: PropTypes.bool,
    intl: PropTypes.object.isRequired,
};

FlipColors.defaultProps = {
    onFlipColorsClick: undefined,
    colorPaletteFlipped: false,
};

export default injectIntl(FlipColors);
