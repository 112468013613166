// @ts-check
import React from 'react';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';

import BusComponent from '../BusComponent';
import Tooltip from '../Tooltip';
import KeyCode from '../../enums/KeyCode';
import LocationAnalysisItemOrigin from '../../enums/LocationAnalysisItemOrigin';
import LocationAnalysisItem from '../../objects/LocationAnalysisItem';
import { hasParentNodeWithClass } from '../../helpers/Util';
import AppConfig from '../../appConfig';

/**
 * @typedef Props
 * @property {string[]} mapInstanceIds
 * @property {import('react-intl').intlShape} intl
 *
 * @typedef State
 * @property {boolean} customLocationSelectionInProgress
 *
 * @extends {BusComponent<Props, State>}
 */
class UserLocationPin extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            customLocationSelectionInProgress: false,
        };
    }

    componentDidMount() {
        this.bindGluBusEvents({
            CUSTOM_LOCATION_RESPONSE: this.onCustomLocationResponse,
        });
        this.emit('USER_LOCATIONS_REQUEST');
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    /** @param {import('mapbox-gl').LngLat} point */
    onCustomLocationResponse = point => {
        const userLocation = new LocationAnalysisItem({
            id: `${point.lng};${point.lat}`,
            type: 'place',
            value: this.props.intl.formatMessage({
                id: 'userLocation.defaultLocationName',
            }),
            point,
            itemOrigin: LocationAnalysisItemOrigin.CUSTOM_PIN,
            // add default selection and analysis type
            analysisTypeId:
                AppConfig.constants.defaultLocationAnalysisSelectionType,
            selection: new Set(
                AppConfig.constants.defaultLocationAnalysisSelection,
            ),
            icon: 'place',
        });

        this.emit('ENTER_UPDATE_LOCATION_ANALYSIS_MODE', {
            selectedItem: userLocation,
            mapInstanceId: this.props.mapInstanceIds[0],
            showInsights: AppConfig.constants.searchBox.shouldShowInsightsOnPointSearch,
        });

        this.setState({
            customLocationSelectionInProgress: false,
        });
        document.removeEventListener('keydown', this.onKeyDown);
        document.removeEventListener('click', this.onClick);
    };

    onPinToggle = () => {
        this.setState(
            ({ customLocationSelectionInProgress }) => ({
                customLocationSelectionInProgress: !customLocationSelectionInProgress,
            }),
            () => {
                if (this.state.customLocationSelectionInProgress) {
                    document.addEventListener('keydown', this.onKeyDown);
                    document.addEventListener('click', this.onClick);
                    this.emit('CUSTOM_LOCATION_REQUEST');
                } else {
                    document.removeEventListener('keydown', this.onKeyDown);
                    document.removeEventListener('click', this.onClick);
                    this.emit('CUSTOM_LOCATION_CANCEL');
                }
            },
        );
    };

    /**
     * @param {MouseEvent} event
     */
    onClick = event => {
        if (hasParentNodeWithClass(event.target, 'dragonfly-map')) {
            return;
        }
        this.setState({
            customLocationSelectionInProgress: false,
        });
        this.emit('CUSTOM_LOCATION_CANCEL');
        document.removeEventListener('keydown', this.onKeyDown);
        document.removeEventListener('click', this.onClick);
    };

    /**
     * @param {KeyboardEvent} event
     */
    onKeyDown = event => {
        // if ESC key is pressed cancel adding custom location pin
        if (event.code === KeyCode.ESC) {
            this.setState({
                customLocationSelectionInProgress: false,
            });
            document.removeEventListener('keydown', this.onKeyDown);
            this.emit('CUSTOM_LOCATION_CANCEL');
        }
    };

    render() {
        // Disable user location pin if multiple map instances exist
        const disabled = this.props.mapInstanceIds.length > 1;
        return (
            <Tooltip
                placement="bottom"
                mouseEnterDelay={0.5}
                mouseLeaveDelay={0}
                overlay={this.props.intl.formatMessage({
                    id: 'userLocation.selectLocation',
                })}
            >
                <button
                    className={classNames(
                        'user-locations-popup-trigger__pin',
                        'clickable',
                        {
                            'user-locations-popup-trigger__pin--active': this
                                .state.customLocationSelectionInProgress,
                            'user-locations-popup-trigger__pin--disabled': disabled,
                        },
                    )}
                    onClick={this.onPinToggle}
                    disabled={disabled}
                >
                    <i className="material-icons-outlined">place</i>
                </button>
            </Tooltip>
        );
    }
}

export default injectIntl(UserLocationPin);
