import React from 'react';
import PropTypes from 'prop-types';
import TextInput from '../../form/TextInput';

class LayerTitleEditor extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            value: props.value,
        };
    }

    onDoneClick = () => {
        this.props.onDone(this.state.value);
    }

    onChange = value => {
        this.setState({ value });
    }

    render() {
        return (
            <div className="user-data-upload-editor__header user-data-upload-editor__header--colored">
                <div className="flex-it grow column">
                    <TextInput className="user-layer-title-editor__title-input" light value={this.state.value} onChange={this.onChange} placeholder="Layer title" />
                    <div className="flex-it center flex-end">
                        <button className="btn-flat btn-flat--light margin-right-10" onClick={this.props.onCancel}>
                        Cancel
                    </button>
                        <button className="btn-raised btn-raised--mild" onClick={this.onDoneClick}>
                        Save
                    </button>
                    </div>
                </div>
            </div>
        );
    }
}

LayerTitleEditor.propTypes = {
    value: PropTypes.string,
    onCancel: PropTypes.func.isRequired,
    onDone: PropTypes.func.isRequired,
};

LayerTitleEditor.defaultProps = {
    value: '',
};

export default LayerTitleEditor;
