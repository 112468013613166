import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class ColorItem extends React.PureComponent {
    onColorClick = () => {
        this.props.onClick(this.props.value);
    };

    render() {
        const { value, selected } = this.props;
        const classes = classNames('color-picker-compact__item', { 'color-picker-compact__item--selected': selected });

        return (
            <button className={classes} onClick={this.onColorClick} aria-label={`Color code: ${value}`}>
                <div className="color-picker-compact__item__fill" style={{ backgroundColor: value }} />
            </button>
        );
    }
}

ColorItem.propTypes = {
    onClick: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    selected: PropTypes.bool,
};

ColorItem.defaultProps = {
    selected: false,
};

export default ColorItem;
