import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FilterStatus from '../../enums/FilterStatus';

const DataFilterLegendInfo = ({ filters, onCloseDataFilterInfo, isCondensedLayout, intl }) => {
    if (Object.keys(filters).length === 0) return null;
    const items = Object.keys(filters).map(filterId => {
        const filter = filters[filterId];
        if (filter.status !== FilterStatus.VALID) return null;
        const { qLabel, textId, formattedValue, percentageLabel } = filter.toStringDecomposed();
        const percentageText = percentageLabel ? ` ${intl.formatMessage({ id: 'of' })} ${percentageLabel}` : '';
        const label = `${qLabel} ${intl.formatMessage({ id: textId })} ${formattedValue}${percentageText}`;
        return (<li key={filterId}>
            <span className="data-filter-info__item">{label}</span>
        </li>);
    });

    const filterInfoClassNames = classNames('data-filter-info', {
        'data-filter-info--condensed': isCondensedLayout,
    });

    return (
        <div className={filterInfoClassNames}>
            <div className="data-filter-info__header flex-it space-between">
                <span className="data-filter-info__title">Criteria</span>
                <button className="material-icons md-18 clickable" onClick={onCloseDataFilterInfo}>close</button>
            </div>
            <div className="data-filter-info__list vertical-scrollbar">
                <ul>
                    {items}
                </ul>
            </div>
        </div>
    );
};

DataFilterLegendInfo.propTypes = {
    filters: PropTypes.object.isRequired,
    onCloseDataFilterInfo: PropTypes.func.isRequired,
    isCondensedLayout: PropTypes.bool,
    intl: PropTypes.object.isRequired,
};

DataFilterLegendInfo.defaultProps = {
    isCondensedLayout: false,
};

export default injectIntl(DataFilterLegendInfo);
