const DEFAULT_STROKE_COLOR = '#666666';
const DEFAULT_INSUFFICIENT_DATA_COLOR = '#d2d2d2';
const DEFAULT_NULL_DATA_COLOR = '#d2d2d2';

class ColorPalette {
    get id() {
        return this._id;
    }

    set id(id) {
        this._id = id;
    }

    get title() {
        return this._title;
    }

    set title(title) {
        this._title = title;
    }

    get type() {
        return this._type;
    }

    set type(type) {
        this._type = type;
    }

    get insufficientDataColor() {
        return this._insufficientDataColor || DEFAULT_INSUFFICIENT_DATA_COLOR;
    }

    set insufficientDataColor(insufficientDataColor) {
        this._insufficientDataColor = insufficientDataColor;
    }

    get nullDataColor() {
        return this._nullDataColor || DEFAULT_NULL_DATA_COLOR;
    }

    set nullDataColor(nullDataColor) {
        this._nullDataColor = nullDataColor;
    }

    get colors() {
        return this._colors || [];
    }

    set colors(colors) {
        this._colors = colors;
    }

    get strokeColors() {
        return this._strokeColors;
    }

    set strokeColors(strokeColors) {
        this._strokeColors = strokeColors;
    }

    get colorRamps() {
        return this._colorRamps;
    }

    set colorRamps(colorRamps) {
        this._colorRamps = colorRamps;
    }

    get flipped() {
        return this._flipped;
    }

    set flipped(flipped) {
        this._flipped = flipped;
    }

    interpolateColors(count) {
        if (this.colors.length > 0) {
            const colors = [];
            for (let i = 0; i < count; i += 1) {
                colors.push(this.colors[i % this.colors.length]);
            }

            return colors;
        } else if (this.colorRamps.length > 0) {
            let bias = 0;
            const colors = [];
            const domain = [];
            this.colorRamps.forEach((colorRamp, colorRampIndex) => {
                colors.push(colorRamp.from, colorRamp.to);
                let rampBias;
                if (colorRamp.bias === 0) {
                    rampBias = (colorRampIndex + 1) / this.colorRamps.length;
                } else {
                    rampBias = colorRamp.bias;
                }

                domain.push(bias, bias + rampBias);

                bias += colorRamp.bias;
            });

            return chroma.scale(colors).domain(domain).colors(count);
        }
        return null;
    }

    interpolateStrokeColors(count) {
        const colors = [];
        for (let i = 0; i < count; i += 1) {
            colors.push((this.strokeColors && this.strokeColors.length > 0 ? this.strokeColors[i % this.strokeColors.length] : DEFAULT_STROKE_COLOR));
        }

        return colors;
    }

    equals(that) {
        return (this.id === that.id &&
        this.title === that.title &&
        this.type === that.type &&
        this.insufficientDataColor === that.insufficientDataColor &&
        this.nullDataColor === that.nullDataColor &&
        this.colors === that.colors &&
        this.strokeColors === that.strokeColors &&
        this.colorRamps.every((colorRamp, index) => colorRamp.equals(that.colorRamps[index])) &&
        this.flipped === that.flipped);
    }

    clone() {
        const clonedColorRamps = this.colorRamps.map(ramp => ramp.clone());
        const clonedColorPalette = Object.assign(new ColorPalette(), JSON.parse(JSON.stringify(this)));
        clonedColorPalette.colorRamps = clonedColorRamps;
        return clonedColorPalette;
    }

    toJSON() {
        return {
            id: this.id,
            title: this.title,
            type: this.type,
            insufficientDataColor: this.insufficientDataColor,
            nullDataColor: this.nullDataColor,
            colors: this.colors,
            strokeColors: this.strokeColors,
            colorRamps: this.colorRamps.map(colorRamp => colorRamp.toJSON),
            flipped: this.flipped,
        };
    }
}

export default ColorPalette;
