import AppConfig from "../../appConfig";

function toDataURL(url, trim = true) {
    return fetch(url)
        .then((response) => response.blob())
        .then(
            (blob) =>
                new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        let encoded;
                        if (trim) {
                            encoded = reader.result
                                .toString()
                                .replace(/^data:(.*,)?/, '');
                            if (encoded.length % 4 > 0) {
                                encoded += '='.repeat(4 - (encoded.length % 4));
                            }
                        } else {
                            encoded = reader.result;
                        }
                        resolve(encoded);
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                }),
        );
}

export const tractiqLogo = toDataURL(`${AppConfig.constants.assetsBaseURL}/icons/export_logo.svg`, false);

