import React from 'react';
import PropTypes from 'prop-types';

import Slider from 'material-ui/Slider';
import Popover from 'material-ui/Popover';
import FontIcon from 'material-ui/FontIcon';
import IconButton from 'material-ui/IconButton';
import FlatButton from 'material-ui/FlatButton';

import PopoverAnimationUpwards from '../../../dropdown/PopoverAnimationUpwards';
import { ButtonStyle, IconButtonStyle, LabelStyle } from './MapExportFrameControlsStyles';


class MapExportFrameZoomLevelControl extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            targetZoomLevelPopoverOpen: false,
        };
    }

    onChangeZoomLevel = (_, value) => {
        this.props.onChangeZoomLevel(value);
    }

    openTargetZoomLevelPopover = e => {
        e.preventDefault();
        this.setState({
            targetZoomLevelAnchorElement: this.targetZoomLevelAnchorElement,
            targetZoomLevelPopoverOpen: !this.state.targetZoomLevelPopOverOpen,
        });
    };

    render() {
        const { targetZoomLevel } = this.props;
        const { targetZoomLevelAnchorElement, targetZoomLevelPopoverOpen } = this.state;

        return (
            <div className="map-export-frame__export-strip__controls__target-zoom-level">
                <div className="map-export-frame__export-strip__controls__target-zoom-level__label">
                    Zoom
                </div>
                <div
                    className="map-export-frame__export-strip__controls__target-zoom-level__buttons"
                    ref={r => { this.targetZoomLevelAnchorElement = r; }}
                >
                    <FlatButton
                        onTouchTap={this.openTargetZoomLevelPopover}
                        style={ButtonStyle}
                        labelStyle={LabelStyle}
                        disableTouchRipple
                        hoverColor="transparent"
                        label={targetZoomLevel}
                    />
                    <IconButton
                        onTouchTap={this.openTargetZoomLevelPopover}
                        style={IconButtonStyle}
                    >
                        <FontIcon
                            className="material-icons"
                            color="#fff"
                        >
                            arrow_drop_down
                        </FontIcon>
                    </IconButton>
                </div>
                <Popover
                    animation={PopoverAnimationUpwards}
                    open={targetZoomLevelPopoverOpen}
                    anchorEl={targetZoomLevelAnchorElement}
                    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                    targetOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                    onRequestClose={() => {
                        this.setState({ targetZoomLevelPopoverOpen: false });
                    }}
                >
                    <div className="map-export-frame__target-zoom-level-popover">
                        <div>
                            <Slider
                                axis="y"
                                defaultValue={targetZoomLevel}
                                style={{ height: 175, paddingBottom: 20 }}
                                min={2}
                                max={20}
                                value={targetZoomLevel}
                                step={0.01}
                                onChange={this.onChangeZoomLevel}
                            />
                        </div>
                        <div>
                            {this.props.targetZoomLevel}
                        </div>
                    </div>
                </Popover>
            </div>
        );
    }
}

MapExportFrameZoomLevelControl.propTypes = {
    targetZoomLevel: PropTypes.number.isRequired,
    onChangeZoomLevel: PropTypes.func.isRequired,
};

export default MapExportFrameZoomLevelControl;
