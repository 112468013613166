const strokeWeight = {
    NONE: { value: 0, label: 'None' },
    EXTRATHIN: { value: 1, label: 'Extra thin' },
    THIN: { value: 2, label: 'Thin' },
    NORMAL: { value: 3, label: 'Normal' },
    THICK: { value: 4, label: 'Thick' },
    EXTRATHICK: { value: 5, label: 'Extra thick' },
};

export default strokeWeight;
