// @ts-check
import React from 'react';
import { injectIntl } from 'react-intl';

import BusComponent from '../BusComponent';
import LocationAnalysisItemOrigin from '../../enums/LocationAnalysisItemOrigin';
import { ALLOWED_POINT_TYPES_FOR_MY_PLACES_ANALYSIS } from '../../enums/PoiTypes';

/**
 * @typedef Props
 * @property {import('../../objects/LocationAnalysisItem').default} locationAnalysisItem
 * @property {string} mapInstanceId
 * @property {import('react-intl').intlShape} intl
 *
 * @extends BusComponent<Props>
 */
class SaveUserLocationButton extends BusComponent {
    constructor(props) {
        super(props);
        this.state = {
            userSavedLocations: [],
            locationExists: false,
        };
    }

    componentDidMount() {
        this.bindGluBusEvents({
            USER_LOCATIONS: this.onRetrievedUserSavedLocations,
        });
        this.bus.emit('USER_LOCATIONS_REQUEST');
    }

    componentWillReceiveProps(nextProps) {
        this.checkIfLocationExists(nextProps.locationAnalysisItem, this.state.userSavedLocations);
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    saveUserLocation = () => {
        this.emit('SAVE_USER_LOCATION_POPUP_REQUEST', {
            location: this.props.locationAnalysisItem,
            mapInstanceId: this.props.mapInstanceId,
        });
    };

    onRetrievedUserSavedLocations = userLocations => {
        const savedUserLocations = userLocations.userLocations;
        this.setState({ userSavedLocations: savedUserLocations }, () => {
            this.checkIfLocationExists(this.props.locationAnalysisItem, savedUserLocations);
        });
    }

    showUserLocationList = () => {
        this.emit('SHOW_USER_LOCATIONS');
    };

    checkIfLocationExists = (locationAnalysisItem, savedLocations) => {
        if (this.props.locationAnalysisItem.itemOrigin === LocationAnalysisItemOrigin.GEO_JSON) {
            const locationExists = savedLocations.some(location => ALLOWED_POINT_TYPES_FOR_MY_PLACES_ANALYSIS.some(type => type === location.metadata.type) && location.metadata.metadata.id === locationAnalysisItem.id);
            this.setState({ locationExists });
        } else {
            this.setState({ locationExists: false });
        }
    }

    render() {
        if (
            this.props.locationAnalysisItem.itemOrigin ===
            LocationAnalysisItemOrigin.USER_SAVED_LOCATION
            || this.state.locationExists
        ) {
            return (
                <div className="flex-it center">
                    <div>
                        {this.props.intl.formatMessage({
                            id: 'userLocation.savedInMyPlaces',
                        })}
                    </div>
                    <div className="divider divider--vertical divider--btn-separator" />
                    <button
                        className="btn btn-link view-user-location-list"
                        onClick={this.showUserLocationList}
                    >
                        {this.props.intl.formatMessage({
                            id: 'userLocation.viewLocationList',
                        })}
                    </button>
                </div>
            );
        }

        return (
            <button className="btn-chip" onClick={this.saveUserLocation}>
                <i className="material-icons btn-chip__icon">
                    add_location_alt
                </i>
                {this.props.intl.formatMessage({
                    id: 'userLocation.saveToPlaces',
                })}
            </button>
        );
    }
}

export default injectIntl(SaveUserLocationButton);
