import DragonflySource from '../../objects/DragonflySource';
import AppConfig from '../../appConfig';

import { getGroupLayersDefinition, isPointBasedLayer } from '../../helpers/LibraryLayersHelper';

class LibraryDataLayersData {
    constructor(mapInstance, mapConfig, layers, sources) {
        this._mapConfig = mapConfig;
        this._mapInstance = mapInstance;
        this._dragonflyLayers = layers;
        this._dragonflySources = sources;

        this._libraryDragonflyDataSource = undefined;
        this._libraryDragonflyLayers = [];
        this._libraryLayers = [];
        this._libraryDataLayers = [];
    }

    setLibraryLayersData(data) {
        const missingGroupDataIds = [];
        this._mapInstance.libraryDataLayers.forEach(group => {
            const groupData = data.groups.find(groupItem => group.id === groupItem.id);
            if (groupData) {
                // TODO the group data in the mapInstance can differ from the data coming from library_layer.json
                // IN this case we should update the mapInstance withe new data
                // e.g. a project is saved with a certain library layer group which is later altered
                // The ELSE covers only the case when the group has been totally removed
                const groupDataCopy = JSON.parse(JSON.stringify(groupData));
                group.metadata = groupDataCopy.metadata;
                group.sources = groupDataCopy.sources;
                group.layersDefinition = getGroupLayersDefinition(groupDataCopy);
            } else {
                // If for some reason the layer has been removed from layer library we must remove it
                // from this mapInstance as well
                missingGroupDataIds.push(group.id);
            }
        });

        // Remove missing data
        missingGroupDataIds.forEach(groupId => {
            const libraryGroupIndex = this._mapInstance.libraryDataLayers.findIndex(olg => olg.id === groupId);
            this._mapInstance.libraryDataLayers.splice(libraryGroupIndex, 1);
        });

        this.setMapInstanceLibraryDataLayers();
        this.buildLibraryDragonflyLayers();
    }

    get source() {
        return this._libraryDragonflyDataSource;
    }

    get layers() {
        return this._libraryDragonflyLayers;
    }

    update() {
        delete this._dragonflySources[this._libraryDragonflyDataSource.id];
        this._libraryDragonflyDataSource = undefined;
        this._libraryDragonflyLayers = [];
        this._libraryDataLayers = [];
        this._libraryLayers = [];

        this.setMapInstanceLibraryDataLayers();
        this.buildLibraryDragonflyLayers();
    }

    setMapInstanceLibraryDataLayers() {
        this._mapInstance.libraryDataLayers.forEach(group => {
            // we used to check group visibility but switched again to ignore that since the visibility is defined
            // for each layer individually
            this._libraryDataLayers.push(...group.layers);
            this.prepareLayers(group.layersDefinition);
        });
    }

    prepareLayers(layers) {
        const extendedLayers = layers.map(layer => {
            layer.metadata = layer.metadata || {};
            layer.metadata.isLibraryLayer = true;
            layer.metadata.defaultMinZoom = layer.minzoom || this._mapConfig.minZoom;
            layer.metadata.defaultMaxZoom = layer.maxzoom || (this._mapConfig.maxZoom + 1);
            if (!layer.ref) {
                layer.layout = layer.layout || {};
                layer.layout.visibility = layer.layout.visibility || 'visible';
                layer.minzoom = layer.minzoom || this._mapConfig.minZoom;
                layer.maxzoom = layer.maxzoom || (this._mapConfig.maxZoom + 1);
            }
            if (!layer.metadata.socialexplorer) {
                layer.metadata.socialexplorer = {};
            }
            layer.paint = layer.paint || {};
            return layer;
        });

        this._libraryLayers.push(...extendedLayers);
    }

    buildLibraryDragonflyLayers() {
        this._libraryDragonflyDataSource = new DragonflySource([AppConfig.constants.backends.tiles]);
        this._libraryDragonflyDataSource.id = 'socialexplorerlibrarysource';

        // Presumed library layers always have one source
        this._mapInstance.libraryDataLayers.forEach(layer => {
            Object.values(layer.sources).forEach(source => this.updateMapSource(source));
        });

        this._libraryLayers.forEach(layerDef => this.updateMapLayers(layerDef));

        // Removal of already present library layers
        this._dragonflyLayers.slice(0).forEach(dl => {
            if (dl.metadata.isLibraryLayer) this._dragonflyLayers.splice(this._dragonflyLayers.indexOf(dl), 1);
        });

        // Basic layer rendering order rule (bottom -> top):
        // background/satellite -> fill/data polygons -> lines/borders/outlines -> points (labels, icons, circles, bubbles)
        // However, since data is always related to the fill/polygons and point based layers, we are resolving this case.
        const firstLabelLayerIndex = this._dragonflyLayers.findIndex(layer => isPointBasedLayer(layer));
        this._libraryDragonflyLayers.forEach(dl => {
            if (isPointBasedLayer(dl)) {
                this._dragonflyLayers.push(dl);
            } else {
                this._dragonflyLayers.splice(firstLabelLayerIndex, 0, dl);
            }
        });

        this._dragonflySources[this._libraryDragonflyDataSource.id] = this._libraryDragonflyDataSource.toJSON();
    }

    updateMapSource(source) {
        source.layers.forEach(layer => {
            const sourceLayer = this._libraryDragonflyDataSource.getLayer(layer.layerId);

            layer.datasets.forEach(dataset => {
                const sourceDataset = sourceLayer.getDataset(dataset.datasetId);
                dataset.columns.forEach(column => sourceDataset.push(column));
            });
        });
    }

    updateMapLayers(libraryLayer) {
        const layerGroupId = libraryLayer.metadata.socialexplorer['layer-group-id'] || libraryLayer.id;
        const mapInstanceDataLayer = this._libraryDataLayers.find(layer => layer.id === layerGroupId);
        if (!mapInstanceDataLayer) return;

        const newLibraryLayer = JSON.parse(JSON.stringify(libraryLayer));
        // Mapbox style specification defines layer.ref property as a reference to the parent layer
        // In case of "child" layer, having layer.ref property, layout is completely inherited from the parent
        // so "ref" and "layout" becomes mutually exclusive layer properties. We used to have no experience with
        // referenced layers until we introduced Mapbox street map layer set. Simple fix is just to check if
        // the current layer already have layout prop or not before toggling visibility.
        if (newLibraryLayer.layout) {
            newLibraryLayer.layout.visibility = mapInstanceDataLayer.visible ? 'visible' : 'none';
        }
        this._libraryDragonflyLayers.push(newLibraryLayer);
    }
}

export default LibraryDataLayersData;
