import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import BusComponent from '../../BusComponent';
import classNames from 'classnames';

import Loader from '../../Loader';
import LayerLibraryCategory from './LayerLibraryCategory';
import { addLayerButtonEvents } from '../../../enums/GoogleTagManagerEvents';
import googleTagManagerEvents from '../../../helpers/GoogleTagManagerEventsWrapper';

const AddLayerButton = googleTagManagerEvents('button', addLayerButtonEvents);

class LayerLibraryEditor extends BusComponent {
    constructor(props, context) {
        super(props, context);

        this.state = {
            groups: [],
            changedStates: {},
            layerLibraryActive: true,
            loading: true,
        };
    }

    componentDidMount() {
        this.bus.once('LIBRARY_DATA', this.onLayerLibraryDataRetrieved);
        this.emit('LIBRARY_DATA_REQUEST', { source: this });
    }

    onLayerLibraryDataRetrieved = (layerLibraryData, target) => {
        if (target === this) {
            const { groups, categories } = layerLibraryData;

            const groupsMetadata = JSON.parse(JSON.stringify(this.prepareLayerLibraryData(groups)));

            this.setState({
                groups: groupsMetadata,
                categories,
                loading: false,
            });
        }
    }

    prepareLayerLibraryData(groups) {
        return groups.map(group => {
            const { id, metadata, layers } = group;

            const active = this.props.mapInstance.libraryDataLayers.some(libraryGroup => libraryGroup.id === id);

            return {
                id,
                metadata,
                layers,
                active,
            };
        });
    }

    onToggleLibraryGroupSelection = (id, selected) => {
        const { changedStates } = this.state;

        if (selected) {
            changedStates[id] = selected;
            this.setState({ changedStates });
        } else {
            delete changedStates[id];
            this.forceUpdate();
        }
    }

    onAddLayerLibraryGroup = () => {
        const { changedStates, groups } = this.state;
        const { mapInstance } = this.props;
        const eventValue = mapInstance.dataTheme.variableSelection.items.map(v => v.variableGuid).join('|');
        const counterEventsArray = [];
        Object.keys(changedStates).forEach(libraryLayerGroupId => {
            const group = groups.find(olg => olg.id === libraryLayerGroupId);
            counterEventsArray.push([
                {
                    event_type: 'item_request',
                    event_value: `${eventValue}|${libraryLayerGroupId}`,
                }, {
                    event_type: 'item_investigation',
                    event_value: `${eventValue}|${libraryLayerGroupId}`,
                },
            ]);
            this.emit('ADD_LAYER_LIBRARY_GROUP', {
                group,
                mapInstanceId: this.props.mapInstance.id,
            });
        });
        const counterEventsFlatten = [].concat(...counterEventsArray);
        this.emit('COUNTER_LOG_REQUEST', counterEventsFlatten);

        this.emit('CLOSE_MODAL');
    }

    activateLayerLibrary = () => {
        this.setState({ layerLibraryActive: true });
    }

    activateUserDataUpload = () => {
        this.setState({ layerLibraryActive: false });
    }

    renderLayerLibraryCategories() {
        const { categories, groups } = this.state;

        return categories.map(category => {
            const categoryGroups = groups.filter(group => group.metadata.category === category);

            return (<LayerLibraryCategory
                key={category}
                id={category}
                title={category}
                groups={categoryGroups}
                onToggleLibraryGroupSelection={this.onToggleLibraryGroupSelection}
            />);
        });
    }

    render() {
        const { intl } = this.props;

        if (this.state.loading) {
            return (<div className="loading-screen">
                <Loader text={intl.formatMessage({ id: 'loading' })} />
            </div>);
        }

        const { layerLibraryActive, changedStates } = this.state;

        const layerLibraryTabClasses = classNames('layer-library__navigation-item clickable center hidden', {
            'layer-library__navigation-item--active': layerLibraryActive,
        });

        const userDataUploadTabClasses = classNames('layer-library__navigation-item clickable center hidden', {
            'layer-library__navigation-item--active': !layerLibraryActive,
        });

        const selectedLayersCount = Object.keys(changedStates).length;
        const addLayerButtonDisabled = selectedLayersCount === 0;

        let title = intl.formatMessage({ id: 'dataBrowser.addLayers' });
        if (selectedLayersCount) {
            const titlePostfix = selectedLayersCount > 1 ? 'layers' : 'layer';
            title = `${selectedLayersCount} ${titlePostfix} selected`;
        }

        return (<div className="layer-library flex-it column grow">
            <div className="layer-library__header flex-it column center flex-end">
                <div className="layer-library__header-content">
                    <div className="layer-library__controls flex-it row grow space-between center">
                        <div className="flex-it flex-start column">
                            <h1 className="font-24">{title}</h1>
                            <span className="layer-library__controls-subtitle">
                                {intl.formatMessage({ id: 'dataBrowser.selectLayersFromLibrary' })}
                            </span>
                        </div>
                        <div className="flex-it flex-end">
                            <AddLayerButton
                                tabIndex="0"
                                title={intl.formatMessage({ id: 'add' })}
                                disabled={addLayerButtonDisabled}
                                className="layer-library__add-button btn-raised"
                                onClick={this.onAddLayerLibraryGroup}
                            >
                                {intl.formatMessage({ id: 'add' })}
                            </AddLayerButton>
                        </div>
                    </div>
                    <div className="layer-library__navigation flex-it row grow">
                        <div className={layerLibraryTabClasses} onClick={this.activateLayerLibrary}>
                            <FormattedMessage id="dataBrowser.layerLibrary" />
                        </div>
                        <div className={userDataUploadTabClasses} onClick={this.activateUserDataUpload}>
                            <FormattedMessage id="upload" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="layer-library__content flex-it column grow center vertical-scrollbar">
                <div className="layer-library__content-layers">
                    <div className="layer-library__layer-list">
                        {this.renderLayerLibraryCategories()}
                    </div>
                </div>
            </div>
        </div>);
    }
}

export default injectIntl(LayerLibraryEditor);
