import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import AppConfig from '../../../appConfig';

/**
 * @param {object} props
 * @param {import('react-intl').intlShape} props.intl
 */
const SearchDefault = ({ intl }) => (
    <div className="data-browser-search-default">
        <img
            src={`${AppConfig.constants.assetsBaseURL}/svg/search.svg`}
            alt="Search illustration"
        />
        <h1 className="data-browser-search-default__heading">
            {intl.formatMessage({ id: 'dataBrowser.search' })}{' '}
            {AppConfig.constants.applicationName}
        </h1>
        <span className="data-browser-search-default__hint-text">
            {intl.formatMessage({ id: 'dataBrowser.hint' })}
        </span>
    </div>
);

SearchDefault.propTypes = {
    intl: PropTypes.object.isRequired,
};

export default injectIntl(SearchDefault);
