import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import BusComponent from '../../../components/BusComponent';
import AppConfig from '../../../appConfig';
import Tooltip from '../../../components/Tooltip';
import BaseMap from '../../../enums/BaseMapTypes';

class SatelliteLegend extends BusComponent {
    constructor(props, context) {
        super(props, context);
        const streetLayerGroup = props.mapInstance.libraryDataLayers.find(
            layerGroup => layerGroup.id === BaseMap.STREET,
        );
        this.state = {
            activeBaseMap: this.activeBaseMap(props),
            streetLayerGroup,
        };
    }

    componentWillReceiveProps(nextProps) {
        const streetLayerGroup = nextProps.mapInstance.libraryDataLayers.find(
            layerGroup => layerGroup.id === BaseMap.STREET,
        );
        this.setState({
            streetLayerGroup,
        });
    }

    componentDidMount() {
        // First check if additional layer is already active. If so, just set the state with library group setup.
        // If additional layer is not active, initiate loading from metadata based on layer group id. The new event
        // flow doesn't complete if the layer group is not found
        if (!this.state.streetLayerGroup) {
            this.bus.once('LIBRARY_DATA', this.onLayerLibraryDataRetrieved);
            this.emit('LIBRARY_DATA_REQUEST', { source: this });
        }
    }

    onLayerLibraryDataRetrieved = (libraryData, target) => {
        const { mapInstance } = this.props;
        if (target === this && libraryData && libraryData.groups) {
            const streetLayerGroup = libraryData.groups.find(lg => lg.id === BaseMap.STREET);
            if (streetLayerGroup) {
                this.setState({
                    streetLayerGroup,
                }, () => {
                    this.emit('ADD_LAYER_LIBRARY_GROUP', {
                        group: streetLayerGroup,
                        mapInstanceId: mapInstance.id,
                    });
                    this.emit('UPDATE_LAYER_LIBRARY_GROUP_VISIBILITY', {
                        id: BaseMap.STREET,
                        visible: this.state.activeBaseMap === BaseMap.STREET,
                        mapInstanceId: mapInstance.id,
                    });
                });
            }
        }
    }

    activeBaseMap(props) {
        if (!props.mapInstance) return BaseMap.STANDARD;
        if (props.mapInstance.isSatelliteVisible) return BaseMap.SATELLITE;
        if (props.mapInstance.isStreetMapVisible) return BaseMap.STREET;
        return BaseMap.STANDARD;
    }

    onActivateStandard = () => {
        const { mapInstance, onClose } = this.props;
        this.setState({
            activeBaseMap: BaseMap.STANDARD,
        }, () => {
            this.emit('UPDATE_LAYER_LIBRARY_GROUP_VISIBILITY', {
                id: BaseMap.STREET,
                visible: false,
                mapInstanceId: mapInstance.id,
            });
            this.emit('HIDE_SATELLITE_REQUEST', {
                mapInstanceId: mapInstance.id,
            });
        });
        onClose();
    }

    onActivateSatellite = () => {
        const { mapInstance, onClose } = this.props;
        this.setState({
            activeBaseMap: BaseMap.SATELLITE,
        }, () => {
            this.emit('UPDATE_LAYER_LIBRARY_GROUP_VISIBILITY', {
                id: BaseMap.STREET,
                visible: false,
                mapInstanceId: mapInstance.id,
            });
            this.emit('SHOW_SATELLITE_REQUEST', {
                mapInstanceId: mapInstance.id,
            });
        });
        onClose();
    }

    onActivateStreet = () => {
        const { mapInstance, onClose } = this.props;
        this.setState({
            activeBaseMap: BaseMap.STREET,
        }, () => {
            this.emit('UPDATE_LAYER_LIBRARY_GROUP_VISIBILITY', {
                id: BaseMap.STREET,
                visible: true,
                mapInstanceId: mapInstance.id,
            });
            this.emit('HIDE_SATELLITE_REQUEST', {
                mapInstanceId: mapInstance.id,
            });
        });
        onClose();
    }

    render() {
        const { intl, onEdit } = this.props;
        const { activeBaseMap, streetLayerGroup } = this.state;
        const classes = classnames('satellite-layer-legend', this.props.className);
        const optionButtonClasses = classnames(
            'btn-icon',
            'btn-icon--small',
            'material-icons',
        );
        return (
            <div className={classes}>
                <div className="flex-it row grow space-between">
                    <div className="flex-it center dark-text">
                        {`${intl.formatMessage({ id: 'dataBrowser.basemap' })}:`}
                    </div>
                    <div className="flex-it center justify-center">
                        <button
                            type="button"
                            key={BaseMap.STANDARD}
                            className={classnames('btn-icon', 'basemap', { active: activeBaseMap === BaseMap.STANDARD })}
                            onClick={this.onActivateStandard}
                            aria-haspopup
                            disabled={activeBaseMap === BaseMap.STANDARD}
                        >
                            <Tooltip
                                placement="bottomLeft"
                                mouseEnterDelay={0.5}
                                mouseLeaveDelay={0}
                                overlay={intl.formatMessage({ id: 'dataBrowser.standardView' })}
                            >
                                <img
                                    alt={intl.formatMessage({ id: 'dataBrowser.standardView' })}
                                    src={`${AppConfig.constants.assetsBaseURL}/icons/basemap-standard.png`}
                                />
                            </Tooltip>
                        </button>
                        <button
                            type="button"
                            key={BaseMap.SATELLITE}
                            className={classnames('btn-icon', 'basemap', { active: activeBaseMap === BaseMap.SATELLITE })}
                            onClick={this.onActivateSatellite}
                            aria-haspopup
                            disabled={activeBaseMap === BaseMap.SATELLITE}
                        >
                            <Tooltip
                                placement="bottomLeft"
                                mouseEnterDelay={0.5}
                                mouseLeaveDelay={0}
                                overlay={intl.formatMessage({ id: 'dataBrowser.satelliteView' })}
                            >
                                <img
                                    alt={intl.formatMessage({ id: 'dataBrowser.satelliteView' })}
                                    src={`${AppConfig.constants.assetsBaseURL}/icons/basemap-satellite.png`}
                                />
                            </Tooltip>
                        </button>
                        {!!streetLayerGroup && <button
                            type="button"
                            key={BaseMap.STREET}
                            className={classnames('btn-icon', 'basemap', { active: activeBaseMap === BaseMap.STREET })}
                            onClick={this.onActivateStreet}
                            aria-haspopup
                            disabled={activeBaseMap === BaseMap.STREET}
                        >
                            <Tooltip
                                placement="bottomLeft"
                                mouseEnterDelay={0.5}
                                mouseLeaveDelay={0}
                                overlay={intl.formatMessage({ id: 'dataBrowser.streetView' })}
                            >
                                <img
                                    alt={intl.formatMessage({ id: 'dataBrowser.streetView' })}
                                    src={`${AppConfig.constants.assetsBaseURL}/icons/basemap-street.png`}
                                />
                            </Tooltip>
                        </button>}
                    </div>
                </div>
                <div className="divider divider--vertical divider--btn-separator" />
                <button
                    className={optionButtonClasses}
                    onClick={() => {
                        onEdit(activeBaseMap);
                    }}
                    disabled={activeBaseMap !== BaseMap.SATELLITE}
                >
                    <i className="material-icons">more_horiz</i>
                </button>
            </div>
        );
    }
}

SatelliteLegend.propTypes = {
    className: PropTypes.string,
    mapInstance: PropTypes.object.isRequired,
    onEdit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
};

SatelliteLegend.defaultProps = {
    className: '',
    onClose: undefined,
};

export default injectIntl(SatelliteLegend);
