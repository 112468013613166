import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class FileDropArea extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {};
    }

    componentDidMount() {
        this.dropAreaDOM.addEventListener('dragover', this.onDragOver);
        this.dropAreaDOM.addEventListener('dragleave', this.onDropAreaDragLeave);
        this.dropAreaDOM.addEventListener('drop', this.onDropAreaDrop, true);
    }

    componentWillUnmount() {
        this.dropAreaDOM.removeEventListener('dragover', this.onDragOver);
        this.dropAreaDOM.removeEventListener('drop', this.onDropAreaDrop, true);
        this.dropAreaDOM.removeEventListener('dragleave', this.onDropAreaDragLeave);
    }

    onDragOver = e => {
        e.preventDefault();

        const isBeingDraggedOver = this.state.isBeingDraggedOver;
        if (!isBeingDraggedOver) {
            this.setState({ isBeingDraggedOver: true });
        }
    };

    onDropAreaDrop = e => {
        e.preventDefault();
        this.hideFileDropArea();

        const files = e.dataTransfer.files;
        if (files && files.length > 0) {
            this.props.onChange(files[0]);
        }
    };

    onDropAreaDragLeave = e => {
        e.preventDefault();
        this.hideFileDropArea();
    };

    onChange = () => {
        if (this.state.data) {
            this.props.onChange(this.state.data);
        }
    };

    hideFileDropArea() {
        this.setState({ isBeingDraggedOver: false });
    }

    renderFileDropArea() {
        const { isBeingDraggedOver } = this.state;
        const { disabled, graphics, graphicsStyle, children, text } = this.props;

        const classes = classNames('file-drop-area', 'flex-it column center', {
            'dragged-over': isBeingDraggedOver,
            'file-drop-area--disabled': disabled,
        });

        return (<div ref={ref => (this.dropAreaDOM = ref)} className={classes}>
            <div
                className="file-drop-area__graphics"
                style={{ backgroundImage: `url('${graphics}')`, ...graphicsStyle }}
            />

            <div className="file-drop-area__title">{isBeingDraggedOver ? 'Drop right here' : text}</div>
            {!isBeingDraggedOver && children}
        </div>);
    }

    renderError() {
        if (this.props.errors && this.props.errors.length > 0) {
            return (<div className="file-drop-area__errors">{this.props.errors[0]}</div>);
        }
        return undefined;
    }

    render() {
        return (
            <div>
                {this.renderFileDropArea()}
                {this.renderError()}
            </div>
        );
    }
}

FileDropArea.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
    ]),
    onChange: PropTypes.func,
    errors: PropTypes.any,
    disabled: PropTypes.bool,
    graphics: PropTypes.string,
    graphicsStyle: PropTypes.object,
    text: PropTypes.string,
};

FileDropArea.defaultProps = {
    children: undefined,
    onChange: undefined,
    errors: undefined,
    disabled: false,
    graphics: '../svg/upload-cloud.svg',
    graphicsStyle: undefined,
    text: undefined,
};

export default FileDropArea;
