import GLU from '../glu2.js/src/index';

class ApplicationModeDataSource extends GLU.DataSource {
    static get name() {
        return 'ApplicationModeDataSource';
    }

    static getInstance() {
        return new ApplicationModeDataSource();
    }

    get currentApplicationMode() {
        return this._currentApplicationMode;
    }

    updateApplicationMode(newApplicationMode) {
        this._currentApplicationMode = newApplicationMode;
    }
}

export default ApplicationModeDataSource;
