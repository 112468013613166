import FilterComparisonType from '../enums/FilterComparisonType';

class Filter {
    constructor(values) {
        if (values) {
            Object.keys(values).forEach(k => {
                if (this.constructor.prototype.hasOwnProperty(k)) {
                    this[k] = values[k];
                }
            });
        }
    }

    get label() {
        return this._label;
    }

    set label(label) {
        this._label = label;
    }

    get labelFrom() {
        return this._labelFrom;
    }

    set labelFrom(labelFrom) {
        this._labelFrom = labelFrom;
    }

    get labelTo() {
        return this._labelTo;
    }

    set labelTo(labelTo) {
        this._labelTo = labelTo;
    }

    get valueFormat() {
        return this._valueFormat;
    }

    set valueFormat(valueFormat) {
        this._valueFormat = valueFormat;
    }

    get comparisonType() {
        return this._comparisonType;
    }

    set comparisonType(comparisonType) {
        this._comparisonType = comparisonType;
    }

    get inclusiveTo() {
        return this._inclusiveTo;
    }

    set inclusiveTo(inclusiveTo) {
        this._inclusiveTo = inclusiveTo;
    }

    get valueNum() {
        return this._valueNum;
    }

    set valueNum(valueNum) {
        this._valueNum = valueNum;
    }

    get valueStr() {
        return this._valueStr;
    }

    set valueStr(valueStr) {
        this._valueStr = valueStr;
    }

    get value() {
        return this._value;
    }

    set value(value) {
        this._value = value;
    }

    /** @returns {string} example: surveyCode.DatasetAbr.TableGuid */
    get fieldName() {
        return this._fieldName;
    }

    set fieldName(fieldName) {
        this._fieldName = fieldName;
    }

    get from() {
        return this._from;
    }

    set from(from) {
        this._from = from;
    }

    get to() {
        return this._to;
    }

    set to(to) {
        this._to = to;
    }

    get values() {
        return this._values;
    }

    set values(values) {
        this._values = values;
    }

    get isCategorical() {
        return this.comparisonType === FilterComparisonType.MATCH_VALUE_NUM ||
            this.comparisonType === FilterComparisonType.MATCH_VALUE_STR;
    }

    matchValue(val) {
        if (this.comparisonType === FilterComparisonType.MATCH_DEFAULT_SYMBOL) return true;

        if (this.comparisonType === FilterComparisonType.MATCH_NULL) {
            return val === undefined || val === null || val === '';
        }

        if (this.comparisonType === FilterComparisonType.MATCH_VALUE_NUM) {
            return val === this.valueNum;
        }
        if (this.comparisonType === FilterComparisonType.MATCH_VALUE_STR) {
            return val === this.valueStr;
        }
        if (this.comparisonType === FilterComparisonType.MATCH_ANY_VALUE) {
            return this.values.find(v => v === val) !== undefined;
        }
        if (this.value) {
            return val === this.value;
        }
        if (this.from && this.to && this.inclusiveTo) {
            return val > this.from && val <= this.to;
        }
        if (this.from && this.to) {
            return val > this.from && val < this.to;
        }
        if (this.from) {
            return val > this.from;
        }
        if (this.to) {
            return val < this.to;
        }
        return false;
    }

    equals(that) {
        return ((this.label === that.label &&
        this.labelFrom === that.labelFrom &&
        this.labelTo === that.labelTo &&
        this.valueFormat === that.valueFormat &&
        this.comparisonType === that.comparisonType) || ((isNaN(this.comparisonType) && isNaN(that.comparisonType)) &&
        this.inclusiveTo === that.inclusiveTo &&
        this.value === that.value &&
        this.fieldName === that.fieldName &&
        this.valueNum === that.valueNum &&
        this.valueStr === that.valueStr &&
        this.from === that.from) || ((isNaN(this.from) && isNaN(that.from)) &&
        this.to === that.to) || (isNaN(this.to) && isNaN(that.to)));
    }

    clone() {
        return new Filter({
            label: this.label,
            labelFrom: this.labelFrom,
            labelTo: this.labelTo,
            valueFormat: this.valueFormat,
            comparisonType: this.comparisonType,
            inclusiveTo: this.inclusiveTo,
            value: this.value,
            fieldName: this.fieldName,
            valueNum: this.valueNum,
            valueStr: this.valueStr,
            from: this.from,
            to: this.to,
            values: this.values,
        });
    }

    toJSON() {
        return {
            label: this.label,
            labelFrom: this.labelFrom,
            labelTo: this.labelTo,
            valueFormat: this.valueFormat,
            comparisonType: this.comparisonType,
            inclusiveTo: this.inclusiveTo,
            value: this.value,
            fieldName: this.fieldName,
            valueNum: this.valueNum,
            valueStr: this.valueStr,
            from: this.from,
            to: this.to,
            values: this.values,
        };
    }
}

export default Filter;
