import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { isValidNumber } from '../../helpers/Util';
import format from '../../helpers/NumberFormatter';
import NumberFormat from '../../enums/NumberFormat';

/**
 * @param {object} param0
 * @param {object} param0.featureProperties
 * @param {string} [param0.titleColumn]
 * @param {import('../../types').UserDataInteractivityDataColumn[]} param0.dataColumns
 * @param {boolean} param0.isClickMode
 */
const InfoBubbleUserData = ({ featureProperties, titleColumn, dataColumns, isClickMode }) => {
    if (!titleColumn && !dataColumns.length) {
        // If no title or content is selected display the message!
        return (
            <div
                className={classNames('user-data-popup--empty', {
                    'user-data-popup--empty-click-mode': isClickMode,
                })}
            >
                Use interactivity settings to customize popup.
            </div>
        );
    }

    const rows = dataColumns.map(column => (
        <div className="user-data-popup__row" key={column}>
            <span className="user-data-popup__row-title wrap">{column}</span>
            <span className="user-data-popup__row-value text-right wrap">
                {isValidNumber(featureProperties[column])
                    ? format({
                          number: featureProperties[column],
                          numberFormat: NumberFormat.FORMAT_NUMBER_2_DECIMAL,
                      })
                    : featureProperties[column]}
            </span>
        </div>
    ));

    const classes = classNames('user-data-popup', {
        'user-data-popup--with-content-column': dataColumns.length,
        'user-data-popup--click-mode-no-title': isClickMode && !titleColumn,
    });

    return (
        <div className={classes}>
            {titleColumn && (
                <h5
                    className={classNames('user-data-popup__title', {
                        'user-data-popup--title-click-mode': isClickMode,
                    })}
                >
                    {featureProperties[titleColumn]}
                </h5>
            )}
            {!!rows.length && <div className="user-data-popup__rows">{rows}</div>}
        </div>
    );
};

InfoBubbleUserData.propTypes = {
    featureProperties: PropTypes.object.isRequired,
    dataColumns: PropTypes.array,
    titleColumn: PropTypes.string,
    isClickMode: PropTypes.bool.isRequired,
};

InfoBubbleUserData.defaultProps = {
    dataColumns: [],
    titleColumn: undefined,
};

export default InfoBubbleUserData;
