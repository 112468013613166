// @ts-check
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import BusComponent from '../BusComponent';
import Tab from './Tab';

/**
 * @typedef Props
 * @property {number} selectedTabIndex
 * @property {(selectedTabIndex: number) => void} tabSwitchedCallback
 * @property {TabName[]} tabNames
 * @property {string} className
 *
 * @typedef State
 * @property {number} selectedTabIndex
 * @property {boolean} tabSelectionInProgress
 *
 * @extends {BusComponent<Props>}
 */
class TabbedBrowser extends BusComponent {
    constructor(props, context) {
        super(props, context);
        const selectedTabIndex = props.tabNames.length === 1 ? 0 : props.selectedTabIndex;
        this.state = {
            selectedTabIndex,
            tabSelectionInProgress: false,
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedTabIndex === undefined) return;
        this.setState(
            {
                selectedTabIndex: nextProps.selectedTabIndex,
                tabSelectionInProgress: true,
            },
            () => {
                this.setState({
                    tabSelectionInProgress: false,
                });
            },
        );
    }

    onTabClick = index => {
        if (this.state.tabSelectionInProgress) return;
        if (typeof this.props.tabSwitchedCallback === 'function') {
            this.props.tabSwitchedCallback(index);
        }
        this.setState(
            {
                selectedTabIndex: index,
                tabSelectionInProgress: true,
            },
            () => {
                this.setState({
                    tabSelectionInProgress: false,
                });
            },
        );
    };

    render() {
        const { children, tabNames, className, isShowAsTab } = this.props;

        if (!tabNames.length) return null;

        const { selectedTabIndex } = this.state;
        /*
        * Added isShowAsTab prop to fix issue when we have single tab that we want
        * to display as tab instead of plain text
        */
        const tabs = tabNames.length > 1 || (tabNames.length === 1 && isShowAsTab) ? tabNames.map((tabName, index) => (
            <Tab
                key={tabName.name}
                onTabClick={this.onTabClick}
                index={index}
                tabName={tabName.name}
                selected={selectedTabIndex === index}
                id={tabNames[index].name}
                showDivider={tabName.showDivider}
                icon={tabName.icon}
            />
        )) : <h4 className="margin-top-8">{tabNames[0].name}</h4>;

        const tabsContent = (
            <div className="tab-content flex-it column">
                {children[selectedTabIndex]}
            </div>
        );
        return (
            <div
                className={classNames(
                    'tabbed-browser',
                    'flex-column',
                    className,
                )}
                aria-labelledby={tabNames[selectedTabIndex].name}
            >
                <div
                    className="tabs flex-it flex-start center no-shrink"
                    role="tablist"
                >
                    {tabs}
                </div>
                {tabsContent}
            </div>
        );
    }
}

TabbedBrowser.propTypes = {
    className: PropTypes.string,
    selectedTabIndex: PropTypes.number,
    tabNames: PropTypes.array.isRequired,
    tabSwitchedCallback: PropTypes.func.isRequired,
    isShowAsTab: PropTypes.bool,
};

TabbedBrowser.defaultProps = {
    className: undefined,
    selectedTabIndex: 0,
    isShowAsTab: false,
};

export default TabbedBrowser;

/**
 * @typedef TabName
 * @property {string} name
 * @property {string} [icon]
 * @property {boolean} isShowAsTab
 * @property {boolean} [showDivider]
 */
