import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../Tooltip';

const BubbleTitle = props => (
    <Tooltip
        className="legend-variable-title-overlay"
        placement="top"
        mouseEnterDelay={0.5}
        mouseLeaveDelay={0}
        overlay={props.title}
    >{
        // eslint-disable-next-line react/no-danger
        }<div className="bubble-legend__title" dangerouslySetInnerHTML={{ __html: props.title }} />
    </Tooltip>
);

BubbleTitle.propTypes = {
    title: PropTypes.string.isRequired,
};

export default BubbleTitle;
