import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Checkbox from '../../form/Checkbox';

class LayerLibraryGroupHeader extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            active: props.active,
            selected: false,
        };
    }

    onToggleSelected = checked => {
        this.props.onToggleSelected(checked);
        this.setState({
            selected: checked,
        });
    }

    onToggleExpanded = () => {
        const expanded = !this.state.expanded;

        this.props.onToggleExpanded(expanded);
        this.setState({ expanded });
    }

    currentlyActiveNotification = () => (<div className="padded-horizontal-5">Already on layer list</div>);

    selectionCheckbox = () => {
        const { selected } = this.state;

        return (<Checkbox
            checked={selected}
            onCheck={this.onToggleSelected}
            uncheckedIcon={<i className="material-icons active">add_circle_outline</i>}
            checkedIcon={<i className="material-icons">check_circle</i>}
        />);
    }

    render() {
        const { title } = this.props;
        const { expanded, active, selected } = this.state;

        const arrowIcon = expanded ? 'arrow_drop_down' : 'arrow_right';
        const arrowIconTitle = expanded ? 'Down arrow icon' : 'Right arrow icon';
        const groupHeaderClasses = classNames('layer-library-group__header clickable', {
            'layer-library-group__header--active': active,
            'layer-library-group__header--selected': selected,
        });

        const groupHeaderIconClasses = classNames('layer-library-group__header-dropdown', {
            'layer-library-group__header-dropdown--active': active,
        });

        const groupHeaderTitleClasses = classNames('layer-library-group__header-title', {
            'layer-library-group__header-title--active': active,
        });

        const groupHeaderActionsClasses = classNames('layer-library-group__header-actions', {
            'layer-library-group__header-toggle--active': active,
        });

        return (<div className={groupHeaderClasses} onClick={this.onToggleExpanded}>
            <button className={groupHeaderIconClasses} aria-expanded={expanded}>
                <i className="material-icons" aria-label={arrowIconTitle}>{arrowIcon}</i>
            </button>
            <div className={groupHeaderTitleClasses}>
                {title}
            </div>
            <div className={groupHeaderActionsClasses}>
                {active ? this.currentlyActiveNotification() : this.selectionCheckbox()}
            </div>
        </div>);
    }
}

LayerLibraryGroupHeader.propTypes = {
    title: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    onToggleSelected: PropTypes.func.isRequired,
    onToggleExpanded: PropTypes.func.isRequired,
};

export default LayerLibraryGroupHeader;
