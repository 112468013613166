import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import BusComponent from '../../BusComponent';
import Tooltip from '../../Tooltip';

import withReportAvailabilityCheck from '../../../hoc/ReportAvailabilityProvider';
import withVariableSelectionMetadataSurvey from '../../../hoc/VariableSelectionMetadataSurveyProvider';
import Mixpanel, { MIXPANEL_EVENTS, MIXPANEL_LOCATIONS } from '../../../helpers/Mixpanel';

class CreateReportButton extends BusComponent {
    onClick = () => {
        this.bus.emit('ENTER_REPORT_MODE', {
            mapInstanceId: this.props.mapInstance.id,
        });
        Mixpanel.emitUserEvent(MIXPANEL_EVENTS.CREATE_REPORT, {
            Location: MIXPANEL_LOCATIONS.topNav,
        });
    };

    render() {
        const { available, mapInstance, survey } = this.props;

        const { isChangeOverTimeApplied } = mapInstance.dataTheme;
        const disabled = isChangeOverTimeApplied || !survey.reportsAsArray.length || !available;

        return (
            <Tooltip
                placement="bottom"
                mouseEnterDelay={0.5}
                mouseLeaveDelay={0}
                overlay="Create report"
            >
                <button
                    type="button"
                    tabIndex="0"
                    className={classNames(
                        'btn-flat',
                        'btn-flat--light',
                        'application-header__button',
                        {
                            'application-header__button--disabled': disabled,
                        },
                    )}
                    disabled={disabled}
                    onClick={this.onClick}
                    aria-label="Create report"
                >
                    <i className="material-icons">assignment</i>
                    <div className="margin-left-8">Create report</div>
                </button>
            </Tooltip>
        );
    }
}

CreateReportButton.propTypes = {
    mapInstance: PropTypes.object.isRequired,
    survey: PropTypes.object.isRequired,
    available: PropTypes.bool.isRequired,
};

export default withVariableSelectionMetadataSurvey(withReportAvailabilityCheck(CreateReportButton));
