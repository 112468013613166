import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { mapLayersPanelButtonEvents } from '../../enums/GoogleTagManagerEvents';
import googleTagManagerEvents from '../../helpers/GoogleTagManagerEventsWrapper';

const CloseMapLayerButton = googleTagManagerEvents('button', mapLayersPanelButtonEvents);

const MapLayersEditorHeader = props => (
    <div className="panel__header flex-it space-between center">
        <div className="flex-it center flex-start">
            <h4>
                {props.intl.formatMessage({ id: 'dataBrowser.mapLayers' })}
            </h4>
        </div>
        <div className="flex-it center space-between">
            <CloseMapLayerButton onClick={props.onClose} className="btn-flat-icon">
                <i className="material-icons">close</i>
            </CloseMapLayerButton>
        </div>
    </div>
    );

MapLayersEditorHeader.propTypes = {
    onClose: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(MapLayersEditorHeader);
