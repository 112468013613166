// All events have the "label" tag set to empty to avoid the "dynamic" keyword which is set when no label tag is sent.
// Exceptions are those events where we do want to have the label and we set it dynamically (e.g. saveProjectEvents).
// //////////
// HEADER //
// ////////
const backToDashboardEvents = [
    {
        name: 'gtm-track',
        value: '',
    },
    {
        name: 'gtm-eventc',
        value: 'Links',
    },
    {
        name: 'gtm-eventa',
        value: 'Back to dashboard',
    },
    {
        name: 'gtm-eventl',
        value: '',
    },
];
const sendFeedbackEvents = [
    {
        name: 'gtm-track',
        value: '',
    },
    {
        name: 'gtm-eventc',
        value: 'Feedback',
    },
    {
        name: 'gtm-eventa',
        value: 'Send feedback',
    },
    {
        name: 'gtm-eventl',
        value: '',
    },
];
// --> More options
const editProjectDetailsButtonEvents = [
    {
        name: 'gtm-track',
        value: '',
    },
    {
        name: 'gtm-eventc',
        value: 'Map Projects',
    },
    {
        name: 'gtm-eventa',
        value: 'Edit project details',
    },
    {
        name: 'gtm-eventl',
        value: '',
    },
];
const helpButtonEvents = [
    {
        name: 'gtm-track',
        value: '',
    },
    {
        name: 'gtm-eventc',
        value: 'Links',
    },
    {
        name: 'gtm-eventa',
        value: 'Help',
    },
    {
        name: 'gtm-eventl',
        value: '',
    },
];
const goToOldVersionButtonEvents = [
    {
        name: 'gtm-track',
        value: '',
    },
    {
        name: 'gtm-eventc',
        value: 'Map projects',
    },
    {
        name: 'gtm-eventa',
        value: 'Old version',
    },
    {
        name: 'gtm-eventl',
        value: '',
    },
];
// --> End More options
const saveAsProjectEvents = [
    {
        name: 'gtm-track',
        value: '',
    },
    {
        name: 'gtm-eventa',
        value: 'Save as',
    },
];
const saveProjectEvents = [
    {
        name: 'gtm-track',
        value: '',
    },
    {
        name: 'gtm-eventa',
        value: 'Save',
    },
];

// ///////////////
// MAP SHARE  //
// /////////////
const facebookButtonEvents = [
    {
        name: 'gtm-track',
        value: '',
    },
    {
        name: 'gtm-eventc',
        value: 'Map Share',
    },
    {
        name: 'gtm-eventa',
        value: 'Share - facebook',
    },
    {
        name: 'gtm-eventl',
        value: '',
    }];
const twitterButtonEvents = [
    {
        name: 'gtm-track',
        value: '',
    },
    {
        name: 'gtm-eventc',
        value: 'Map Share',
    },
    {
        name: 'gtm-eventa',
        value: 'Share - twitter',
    },
    {
        name: 'gtm-eventl',
        value: '',
    }];
const copyToClipboardButtonLinkEvents = [
    {
        name: 'gtm-track',
        value: '',
    },
    {
        name: 'gtm-eventc',
        value: 'Map Share',
    },
    {
        name: 'gtm-eventa',
        value: 'Share - copy',
    },
    {
        name: 'gtm-eventl',
        value: '',
    }];
const copyToClipboardButtonEmebedEvents = [
    {
        name: 'gtm-track',
        value: '',
    },
    {
        name: 'gtm-eventc',
        value: 'Map Share',
    },
    {
        name: 'gtm-eventa',
        value: 'Embed',
    },
    {
        name: 'gtm-eventl',
        value: '',
    }];
const exportImageButtonWrapperEvents = [
    {
        name: 'gtm-track',
        value: '',
    },
    {
        name: 'gtm-eventc',
        value: 'Map export',
    },
    {
        name: 'gtm-eventa',
        value: 'Download image',
    },
    {
        name: 'gtm-eventl',
        value: '',
    },
];
// ////////
// TOUR //
// //////
const takeTourButtonEvents = [
    {
        name: 'gtm-track',
        value: '',
    },
    {
        name: 'gtm-eventc',
        value: 'Map Tour',
    },
    {
        name: 'gtm-eventa',
        value: 'Tour',
    },
    {
        name: 'gtm-eventl',
        value: '',
    },
];
const startTourButtonEvents = [
    {
        name: 'gtm-track',
        value: '',
    },
    {
        name: 'gtm-eventc',
        value: 'Map Tour',
    },
    {
        name: 'gtm-eventa',
        value: 'Tour start',
    },
    {
        name: 'gtm-eventl',
        value: '',
    },
];
const endTourButtonEvents = [
    {
        name: 'gtm-track',
        value: '',
    },
    {
        name: 'gtm-eventc',
        value: 'Map Tour',
    },
    {
        name: 'gtm-eventa',
        value: 'Tour end',
    },
    {
        name: 'gtm-eventl',
        value: '',
    },
];

// ////////////////
// DATA BROWSER //
// //////////////
const moreInfoButtonEvents = [
    {
        name: 'gtm-track',
        value: '',
    },
    {
        name: 'gtm-eventc',
        value: 'Maps - data browser',
    },
    {
        name: 'gtm-eventa',
        value: 'More info',
    },
    {
        name: 'gtm-eventl',
        value: '',
    },
];
const percentBaseButtonEvents = [
    {
        name: 'gtm-track',
        value: '',
    },
    {
        name: 'gtm-eventc',
        value: 'Maps - data browser',
    },
    {
        name: 'gtm-eventa',
        value: 'Percent base',
    },
    {
        name: 'gtm-eventl',
        value: '',
    },
];
const CPICorrectionButtonEvents = [
    {
        name: 'gtm-track',
        value: '',
    },
    {
        name: 'gtm-eventc',
        value: 'Maps - data browser',
    },
    {
        name: 'gtm-eventa',
        value: 'CPI',
    },
    {
        name: 'gtm-eventl',
        value: '',
    },
];
const variableSearchResultButtonEvents = [
    {
        name: 'gtm-track',
        value: '',
    },
    {
        name: 'gtm-eventc',
        value: 'Variable Search',
    },
];
const dataVariableButtonEvents = [
    {
        name: 'gtm-track',
        value: '',
    },
    {
        name: 'gtm-eventc',
        value: 'Maps - data browser',
    },
    {
        name: 'gtm-eventa',
        value: 'Variable selection',
    },
];

// //////////////
// MAPS TOOLS //
// ////////////
const downloadButtonEvents = [
    {
        name: 'gtm-track',
        value: '',
    },
    {
        name: 'gtm-eventc',
        value: 'Maps - tools',
    },
    {
        name: 'gtm-eventa',
        value: 'Download geography list',
    },
    {
        name: 'gtm-eventl',
        value: '',
    },
];
const uploadGeographyListButtonEvents = [
    {
        name: 'gtm-track',
        value: '',
    },
    {
        name: 'gtm-eventc',
        value: 'Maps - tools',
    },
    {
        name: 'gtm-eventa',
        value: 'Upload geography list',
    },
    {
        name: 'gtm-eventl',
        value: '',
    },
];
// Optional layer
const addLayerButtonEvents = [
    {
        name: 'gtm-track',
        value: '',
    },
    {
        name: 'gtm-eventc',
        value: 'Maps - tools',
    },
    {
        name: 'gtm-eventa',
        value: 'Optional layer added',
    },
    {
        name: 'gtm-eventl',
        value: '',
    },
];
// Upload layer
const doneUploadLayerButtonEvents = [
    {
        name: 'gtm-track',
        value: '',
    },
    {
        name: 'gtm-eventc',
        value: 'Maps - tools',
    },
    {
        name: 'gtm-eventa',
        value: 'Layer uploaded',
    },
    {
        name: 'gtm-eventl',
        value: '',
    },
];
const mapLayersPanelButtonEvents = [
    {
        name: 'gtm-track',
        value: '',
    },
    {
        name: 'gtm-eventc',
        value: 'Maps - tools',
    },
    {
        name: 'gtm-eventa',
        value: 'Map layers panel',
    },
    {
        name: 'gtm-eventl',
        value: '',
    },
];
const createReportButtonEvents = [
    {
        name: 'gtm-track',
        value: '',
    },
    {
        name: 'gtm-eventc',
        value: 'Maps Create report',
    },
    {
        name: 'gtm-eventa',
        value: 'Create report',
    },
];
const doneMaskButtonEvents = [
    {
        name: 'gtm-track',
        value: '',
    },
    {
        name: 'gtm-eventc',
        value: 'Maps - tools',
    },
    {
        name: 'gtm-eventa',
        value: 'Mask done',
    },
    {
        name: 'gtm-eventl',
        value: '',
    },
];
const doneAnnotateButtonEvents = [
    {
        name: 'gtm-track',
        value: '',
    },
    {
        name: 'gtm-eventc',
        value: 'Maps - tools',
    },
    {
        name: 'gtm-eventa',
        value: 'Annotate done',
    },
    {
        name: 'gtm-eventl',
        value: '',
    },
];
const doneDataFilterButtonEvents = [
    {
        name: 'gtm-track',
        value: '',
    },
    {
        name: 'gtm-eventc',
        value: 'Maps - tools',
    },
    {
        name: 'gtm-eventa',
        value: 'Filter done',
    },
    {
        name: 'gtm-eventl',
        value: '',
    },
];

// ////////////
// MAP VIEW //
// //////////
const mapViewButtonEvents = [
    {
        name: 'gtm-track',
        value: '',
    },
    {
        name: 'gtm-eventc',
        value: 'Map view',
    },
    {
        name: 'gtm-eventl',
        value: '',
    },
];
const switchWarningPopupButtonEvents = [
    {
        name: 'gtm-track',
        value: '',
    },
    {
        name: 'gtm-eventc',
        value: 'Map view',
    },
    {
        name: 'gtm-eventa',
        value: 'Switch type warning',
    },
    {
        name: 'gtm-eventl',
        value: '',
    },
];

// ////////////////
// STORY EDITOR //
// //////////////
const exportAsPowerPointButtonEvents = [
    {
        name: 'gtm-track',
        value: '',
    },
    {
        name: 'gtm-eventc',
        value: 'Story editor',
    },
    {
        name: 'gtm-eventa',
        value: 'Export to PPTX ',
    },
    {
        name: 'gtm-eventl',
        value: '',
    },
];
const storyGoToOldVersionButtonEvents = [
    {
        name: 'gtm-track',
        value: '',
    },
    {
        name: 'gtm-eventc',
        value: 'Story editor',
    },
    {
        name: 'gtm-eventa',
        value: 'Story Old version',
    },
    {
        name: 'gtm-eventl',
        value: '',
    },
];
const openMissingFacilityFormEvents = [
    {
        name: 'gtm-track',
        value: '',
    },
    {
        name: 'gtm-eventc',
        value: 'Maps - tools',
    },
    {
        name: 'gtm-eventa',
        value: 'Facilities form start',
    },
    {
        name: 'gtm-eventl',
        value: 'new',
    },
];
const submitMissingFacilityEvents = [
    {
        name: 'gtm-track',
        value: '',
    },
    {
        name: 'gtm-eventc',
        value: 'Maps - tools',
    },
    {
        name: 'gtm-eventa',
        value: 'Facilities form end',
    },
    {
        name: 'gtm-eventl',
        value: 'new',
    },
];
const openEditFacilityFormEvents = [
    {
        name: 'gtm-track',
        value: '',
    },
    {
        name: 'gtm-eventc',
        value: 'Maps - tools',
    },
    {
        name: 'gtm-eventa',
        value: 'Facilities form start',
    },
    {
        name: 'gtm-eventl',
        value: 'edit',
    },
];
const submitEditedFacilityEvents = [
    {
        name: 'gtm-track',
        value: '',
    },
    {
        name: 'gtm-eventc',
        value: 'Maps - tools',
    },
    {
        name: 'gtm-eventa',
        value: 'Facilities form end',
    },
    {
        name: 'gtm-eventl',
        value: 'edit',
    },
];
// //////////////
// SEARCH BOX //
// ////////////
const geographySearchResultButtonEvents = [
    {
        name: 'gtm-track',
        value: '',
    },
    {
        name: 'gtm-eventc',
        value: 'Geography Search',
    },
];
export {
    // HEADER
    backToDashboardEvents,
    sendFeedbackEvents,
    facebookButtonEvents,
    twitterButtonEvents,
    copyToClipboardButtonLinkEvents,
    copyToClipboardButtonEmebedEvents,
    exportImageButtonWrapperEvents,
    editProjectDetailsButtonEvents,
    saveProjectEvents,
    saveAsProjectEvents,
    helpButtonEvents,
    takeTourButtonEvents,
    startTourButtonEvents,
    endTourButtonEvents,
    goToOldVersionButtonEvents,
    // DATA BROWSER
    moreInfoButtonEvents,
    percentBaseButtonEvents,
    CPICorrectionButtonEvents,
    variableSearchResultButtonEvents,
    dataVariableButtonEvents,
    // MAPS TOOLS
    downloadButtonEvents,
    uploadGeographyListButtonEvents,
    mapLayersPanelButtonEvents,
    addLayerButtonEvents,
    doneUploadLayerButtonEvents,
    doneMaskButtonEvents,
    doneAnnotateButtonEvents,
    doneDataFilterButtonEvents,
    // MAP VIEW
    mapViewButtonEvents,
    switchWarningPopupButtonEvents,
    createReportButtonEvents,
    // STORY EDITOR
    storyGoToOldVersionButtonEvents,
    exportAsPowerPointButtonEvents,
    // SEARCH BOX
    geographySearchResultButtonEvents,
    // Missing facilities
    openMissingFacilityFormEvents,
    submitMissingFacilityEvents,
    openEditFacilityFormEvents,
    submitEditedFacilityEvents,
};
