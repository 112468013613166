import { taggedTemplate } from '../helpers/Util';

const SIMPLE_DATE = { month: 'long', year: 'numeric', day: 'numeric' };
const LONG_DATE = {
    month: 'long',
    year: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short',
    hour12: false,
};

const CitationFormats = [
    {
        id: 'CMS',
        text: 'The Chicago Manual of Style',
        template: taggedTemplate `${'title'}, ${'surveyYear'}. Social Explorer, (based on data from ${'source'}; accessed ${'date'}).`,
        dateOptions: LONG_DATE,
    },
    {
        id: 'MLA',
        text: 'Modern Language Association (MLA)',
        template: taggedTemplate `"${'title'}, ${'surveyYear'}.” Map. Social Explorer. Social Explorer, n.d. Web. ${'date'}. (based on data from ${'source'})`,
        dateOptions: LONG_DATE,
    },
    {
        id: 'APA',
        text: 'American Psychological Association (APA)',
        template: taggedTemplate `${'title'} [Map]. In SocialExplorer.com. ${'surveyName'} Retrieved ${'date'}, from ${'link'}`,
        dateOptions: SIMPLE_DATE,
    },
];

export default CitationFormats;
