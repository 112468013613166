import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import dragonfly from 'dragonfly-v3';

import BusComponent from '../../BusComponent';
import MapPreview from '../../export/MapPreview';

import { getCanvasLimits } from '../../../helpers/Export';
import { removeEvent, addEvent } from '../../../helpers/Util';

import Keys from '../../../enums/Key';
import MapExportAspectRatio from '../../../enums/MapExportAspectRatio';

import LinearProgress from 'material-ui/LinearProgress';

import MapZoom from '../../mapControls/MapZoom';

const { maxSupportedOutputWidth, maxSupportedOutputHeight, maxSupportedOutputArea } = getCanvasLimits();

// Minimum overlay frame dimension (height or width) should ensure overlay sizing anchors (arrows) do not merge
const MINIMUM_OVERLAY_DIMENSION = 50;

class MapExportFrameOverLay extends BusComponent {
    constructor(props, context) {
        super(props, context);

        const bodyClientRect = document.body.getBoundingClientRect();

        this.state = {
            minOverlayDimension: MINIMUM_OVERLAY_DIMENSION,
            maxOverlayDimensionWidth: bodyClientRect.width * 0.9,
            maxOverlayDimensionHeight: bodyClientRect.height * 0.8,
            aspectRatioFactor: MapExportAspectRatio.SIXTEEN_NINE.factor,
            targetaspectRatioFactor: MapExportAspectRatio.SIXTEEN_NINE.value,
            targetZoomLevel: parseFloat(props.mapInstance.initialView.zoom.toFixed(2)),
            mapInitialView: props.mapInstance.initialView.clone(),
            hasSavedSettings: false,
        };

        // Based on initial width set height to achieve 16:9 aspect ratio
        this.overlayWidth = bodyClientRect.width * 0.8;
        this.overlayHeight = this.overlayWidth / this.state.aspectRatioFactor;
    }

    componentDidMount() {
        this.isCanvasDirty = true;
        this.drawOverlay();

        addEvent(document, 'mousedown', this.onMouseDown);
        addEvent(window, 'resize', this.onResize);
        addEvent(document, 'keydown', this.onKeyDown);

        this.bindGluBusEvents({
            MAP_MOVE: this.onMapMoved,
            EXPORT_SETTINGS_RESTORED: this.onExportSettingsRestored,
        });
    }

    componentWillUnmount() {
        removeEvent(document, 'mousedown', this.onMouseDown);
        removeEvent(window, 'resize', this.onResize);
        removeEvent(document, 'keydown', this.onKeyDown);

        this.unbindGluBusEvents();
    }

    componentWillReceiveProps(nextProps) {
        const newState = {};
        if (!this.state.mapInitialView.equals(nextProps.mapInstance.initialView)) {
            newState.targetZoomLevel = parseFloat(nextProps.mapInstance.initialView.zoom.toFixed(2));
            newState.mapInitialView = nextProps.mapInstance.initialView.clone();
        }

        // For selected aspect ratio item find multiplication factor
        const aspectRatioItem = Object.values(MapExportAspectRatio).find(item => item.value === nextProps.targetAspectRatio);
        newState.aspectRatioFactor = aspectRatioItem.factor;

        this.setState(newState);

        // If overlayOrigin is present in props restore previously saved settings
        if (nextProps.overlayOrigin) {
            this.restoreOverlaySettings(nextProps);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { aspectRatioFactor } = this.state;
        const { maxOverlayDimensionWidth, maxOverlayDimensionHeight, minOverlayDimension } = this.state;

        if (maxOverlayDimensionWidth !== prevState.maxOverlayDimensionWidth || maxOverlayDimensionHeight !== prevState.maxOverlayDimensionHeight) {
            if (prevState.aspectRatioFactor === aspectRatioFactor && prevState.aspectRatioFactor !== undefined) {
                // locked aspect ratio
                if (aspectRatioFactor < 1) {
                    this.overlayHeight = Math.max(minOverlayDimension, Math.min(maxOverlayDimensionHeight, this.overlayWidth / aspectRatioFactor));
                    this.overlayWidth = this.overlayHeight * aspectRatioFactor;
                } else {
                    this.overlayWidth = Math.max(minOverlayDimension, Math.min(maxOverlayDimensionWidth, this.overlayHeight * aspectRatioFactor));
                    this.overlayHeight = this.overlayWidth / aspectRatioFactor;
                }
            } else {
                // free aspect ratio
                this.overlayHeight = Math.min(maxOverlayDimensionHeight, this.overlayHeight);
                this.overlayWidth = Math.min(maxOverlayDimensionWidth, this.overlayWidth);
            }
        }

        if (aspectRatioFactor !== prevState.aspectRatioFactor && aspectRatioFactor !== undefined) {
            if (aspectRatioFactor < 1) {
                this.overlayHeight = Math.max(minOverlayDimension, Math.min(maxOverlayDimensionHeight, this.overlayWidth / aspectRatioFactor));
                this.overlayWidth = this.overlayHeight * aspectRatioFactor;
            } else {
                this.overlayWidth = Math.max(minOverlayDimension, Math.min(maxOverlayDimensionWidth, this.overlayHeight * aspectRatioFactor));
                this.overlayHeight = this.overlayWidth / aspectRatioFactor;
            }
        }

        this.isCanvasDirty = true;
        this.drawOverlay();
    }

    onKeyDown = event => {
        if (event.keyCode === Keys.ESC) {
            this.onExit();
        }
    }

    onExit = () => {
        this.emit('HIDE_MAP_EXPORT_FRAME');
    }

    onMapMoved = e => {
        if (this.mapPreviewComponent) {
            this.mapPreviewComponent.map.jumpTo({ center: e.center });
        }
    };

    onExportSettingsRestored(payload) {
        const { overlayOrigin, mapViewerCenter, mapViewerZoom } = payload;
        const { maxOverlayDimensionWidth, maxOverlayDimensionHeight } = this.state;

        this.props.mapViewer.dragonflyMap.jumpTo({ zoom: mapViewerZoom, center: mapViewerCenter });

        const point = this.props.mapViewer.dragonflyMap.project(overlayOrigin);

        const overlayWidth = (2 * point.x) - (this.canvas.width / window.devicePixelRatio);
        const overlayHeight = (this.canvas.height / window.devicePixelRatio) - (2 * point.y);

        this.overlayWidth = overlayWidth;
        this.overlayHeight = overlayHeight;
        let desiredZoomLevel = mapViewerZoom;

        if (overlayWidth > maxOverlayDimensionWidth || overlayHeight > maxOverlayDimensionHeight) {
            const widthScale = overlayWidth / maxOverlayDimensionWidth;
            const heightScale = overlayHeight / maxOverlayDimensionHeight;
            const scale = Math.max(widthScale, heightScale);

            desiredZoomLevel = Math.log2((1 / scale)) + desiredZoomLevel;

            this.overlayWidth = this.overlayWidth / scale;
            this.overlayHeight = this.overlayHeight / scale;
        }

        this.props.mapViewer.dragonflyMap.jumpTo({ zoom: desiredZoomLevel, center: mapViewerCenter });
        this.isCanvasDirty = true;
    }

    onResize = () => {
        const bodyClientRect = document.body.getBoundingClientRect();

        this.setState({
            maxOverlayDimensionWidth: bodyClientRect.width * 0.9,
            maxOverlayDimensionHeight: bodyClientRect.height * 0.8,
        });
    };

    onMouseDown = e => {
        if (e.button !== 0) {
            return;
        }

        if (this.props.exportStatus !== undefined) {
            return;
        }

        const dragonflyMapContainer = document.querySelector('.dragonfly-canvas');

        const targets = [this.canvas, this.bottomRightDiv, this.topLeftDiv, this.bottomLeftDiv, this.topRightDiv, dragonflyMapContainer];

        if (targets.indexOf(e.target) === -1) {
            return;
        }

        switch (e.target) {
        case this.topLeftDiv:
            this.dragPoint = 'tl';
            break;
        case this.topRightDiv:
            this.dragPoint = 'tr';
            break;
        case this.bottomLeftDiv:
            this.dragPoint = 'bl';
            break;
        case this.bottomRightDiv:
            this.dragPoint = 'br';
            break;
        default:
            this.dragPoint = undefined;
        }

        this.isMoving = true;

        if (this.exportStrip) {
            const exportStripBoundingRect = this.exportStrip.getBoundingClientRect();
            const bottomLeftDivBoundingRect = this.bottomLeftDiv.getBoundingClientRect();
            if (exportStripBoundingRect.top <= bottomLeftDivBoundingRect.top + (bottomLeftDivBoundingRect.height / 2)) {
                this.exportStrip.style.opacity = 0;
            }
        }

        if (this.dragPoint !== undefined) {
            e.preventDefault();
            e.stopImmediatePropagation();
            this.topLeftDiv.style.pointerEvents = 'none';
            this.topRightDiv.style.pointerEvents = 'none';
            this.bottomLeftDiv.style.pointerEvents = 'none';
            this.bottomRightDiv.style.pointerEvents = 'none';
            addEvent(document, 'mousemove', this.onMouseMove);
        }

        addEvent(document, 'mouseup', this.onMouseUp);

        this.isCanvasDirty = true;
        this.drawOverlay();
    };

    onMouseMove = e => {
        const { aspectRatioFactor, maxOverlayDimensionWidth, maxOverlayDimensionHeight, minOverlayDimension } = this.state;

        const canvasRect = this.canvas.getBoundingClientRect();
        const canvasWidth = this.canvas.width / window.devicePixelRatio;
        const canvasHeight = this.canvas.height / window.devicePixelRatio;

        const centerX = (canvasWidth / 2) + canvasRect.left;
        const centerY = (canvasHeight / 2) + canvasRect.top;

        const dx = Math.abs(e.clientX - centerX);
        const dy = Math.abs(e.clientY - centerY);

        if (this.state.aspectRatioFactor !== undefined) {
            const adjustedMaxDimension = maxOverlayDimensionHeight * aspectRatioFactor;
            const adjustedMinDimension = Math.max(minOverlayDimension * aspectRatioFactor, Math.floor(aspectRatioFactor * 2 * dy));

            this.overlayWidth = Math.min(adjustedMaxDimension, maxOverlayDimensionWidth, adjustedMinDimension);
            this.overlayHeight = this.overlayWidth / aspectRatioFactor;
        } else {
            switch (this.dragPoint) {
            case 'tl':
            case 'tr':
            case 'bl':
            case 'br':
                this.overlayWidth = Math.min(maxOverlayDimensionWidth, Math.max(minOverlayDimension, Math.floor(2 * dx)));
                this.overlayHeight = Math.min(maxOverlayDimensionHeight, Math.max(minOverlayDimension, Math.floor(2 * dy)));
                break;
            }
        }

        this.isCanvasDirty = true;
        this.drawOverlay();
    };

    onMouseUp = e => {
        if (e.button !== 0) {
            return;
        }

        removeEvent(document, 'mouseup', this.onMouseUp);

        this.isMoving = false;
        if (this.exportStrip) {
            this.exportStrip.style.opacity = 1;
        }

        if (this.dragPoint !== undefined) {
            removeEvent(document, 'mousemove', this.onMouseMove);
            this.topLeftDiv.style.pointerEvents = 'auto';
            this.topRightDiv.style.pointerEvents = 'auto';
            this.bottomLeftDiv.style.pointerEvents = 'auto';
            this.bottomRightDiv.style.pointerEvents = 'auto';
        }

        this.isCanvasDirty = true;
        this.drawOverlay();
    };

    drawOverlay = () => {
        const { mapViewer } = this.props;

        if (this.canvas && mapViewer && this.isCanvasDirty) {
            const { isCustomZoomEnabled, exportStatus } = this.props;
            const { minOverlayDimension } = this.state;

            this.isCanvasDirty = false;
            this.canvas.style.width = `${document.body.clientWidth}px`;
            this.canvas.style.height = `${document.body.clientHeight}px`;

            const canvasWidth = parseFloat(this.canvas.style.width.substr(0, this.canvas.style.width.length - 2));
            const canvasHeight = parseFloat(this.canvas.style.height.substr(0, this.canvas.style.height.length - 2));
            this.canvas.width = canvasWidth * window.devicePixelRatio;
            this.canvas.height = canvasHeight * window.devicePixelRatio;

            const ctx = this.canvas.getContext('2d');
            ctx.scale(window.devicePixelRatio, window.devicePixelRatio);
            ctx.clearRect(0, 0, canvasWidth, canvasHeight);
            ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
            ctx.fillRect(0, 0, canvasWidth, canvasHeight);

            if (exportStatus !== undefined) {
                return;
            }

            ctx.textAlign = 'center';
            ctx.font = 'bold 14px Source Sans Pro';

            const targetPixelRatio = this.props.targetDPI / 96;
            const targetZoomLevel = isCustomZoomEnabled ? parseFloat(this.props.targetZoomLevel) : mapViewer.dragonflyMap.getZoom();

            const zoomFactor = 2 ** (targetZoomLevel - mapViewer.dragonflyMap.getZoom());
            const overlayWidthAtTargetZoomLevel = Math.floor(targetPixelRatio * this.overlayWidth * zoomFactor);
            const overlayHeightAtTargetZoomLevel = Math.floor(targetPixelRatio * this.overlayHeight * zoomFactor);

            const areOverlayDimensionsAllowed = (overlayWidthAtTargetZoomLevel > maxSupportedOutputWidth || overlayHeightAtTargetZoomLevel > maxSupportedOutputHeight);
            const isOverlayAreaAllowed = overlayWidthAtTargetZoomLevel * overlayHeightAtTargetZoomLevel > maxSupportedOutputArea;

            const isExportSizeTooLarge = areOverlayDimensionsAllowed || isOverlayAreaAllowed;
            const isExportSizeTooSmall = overlayWidthAtTargetZoomLevel < minOverlayDimension || overlayHeightAtTargetZoomLevel < minOverlayDimension;

            if (!isExportSizeTooLarge && !isExportSizeTooSmall) {
                ctx.clearRect(Math.floor((canvasWidth / 2) - (this.overlayWidth / 2)), Math.floor((canvasHeight / 2) - (this.overlayHeight / 2)), this.overlayWidth, this.overlayHeight);

                ctx.strokeStyle = '#9e9e9e';
                ctx.lineWidth = 2;
                ctx.strokeRect(Math.floor((canvasWidth / 2) - (this.overlayWidth / 2)), Math.floor((canvasHeight / 2) - (this.overlayHeight / 2)), this.overlayWidth, this.overlayHeight);
            }

            if (this.isMoving) {
                ctx.beginPath();
                ctx.setLineDash([]);
                ctx.strokeStyle = 'rgba(33, 33, 33, 0.2)';
                ctx.lineCap = 'round';
                ctx.lineWidth = 3;
                ctx.moveTo(Math.floor(canvasWidth / 2), Math.floor((canvasHeight / 2) - 10));
                ctx.lineTo(Math.floor(canvasWidth / 2), Math.floor((canvasHeight / 2) + 10));

                ctx.moveTo(Math.floor((canvasWidth / 2) - 10), Math.floor(canvasHeight / 2));
                ctx.lineTo(Math.floor((canvasWidth / 2) + 10), Math.floor(canvasHeight / 2));
                ctx.stroke();

                ctx.beginPath();
                ctx.strokeStyle = 'rgba(255, 255, 255, 0.9)';
                ctx.lineWidth = 1;
                ctx.moveTo(Math.floor(canvasWidth / 2), Math.floor((canvasHeight / 2) - 10));
                ctx.lineTo(Math.floor(canvasWidth / 2), Math.floor((canvasHeight / 2) + 10));

                ctx.moveTo(Math.floor((canvasWidth / 2) - 10), Math.floor(canvasHeight / 2));
                ctx.lineTo(Math.floor((canvasWidth / 2) + 10), Math.floor(canvasHeight / 2));
                ctx.stroke();

                ctx.strokeStyle = 'rgba(255, 255, 255, 0.5)';
                ctx.lineCap = 'butt';
                ctx.lineWidth = 2;
                ctx.strokeRect(Math.floor((canvasWidth / 2) - (this.overlayWidth / 2)), Math.floor((canvasHeight / 2) - (this.overlayHeight / 2)), this.overlayWidth, this.overlayHeight);
            }

            if (this.topLeftDiv) {
                const topLeftDivBoundingRect = this.topLeftDiv.getBoundingClientRect();
                this.topLeftDiv.style.left = `${Math.floor((canvasWidth / 2) - (this.overlayWidth / 2) - (topLeftDivBoundingRect.width / 2))}px`;
                this.topLeftDiv.style.top = `${Math.floor((canvasHeight / 2) - (this.overlayHeight / 2) - (topLeftDivBoundingRect.height / 2))}px`;
            }

            if (this.topRightDiv) {
                const topRightDivBoundingRect = this.topRightDiv.getBoundingClientRect();
                this.topRightDiv.style.left = `${Math.floor(((canvasWidth / 2) + (this.overlayWidth / 2)) - (topRightDivBoundingRect.width / 2))}px`;
                this.topRightDiv.style.top = `${Math.floor(((canvasHeight / 2) - (this.overlayHeight / 2)) - (topRightDivBoundingRect.height / 2))}px`;
            }

            if (this.bottomLeftDiv) {
                const bottomLeftDivBoundingRect = this.bottomLeftDiv.getBoundingClientRect();
                this.bottomLeftDiv.style.left = `${Math.floor((canvasWidth / 2) - (this.overlayWidth / 2) - (bottomLeftDivBoundingRect.width / 2))}px`;
                this.bottomLeftDiv.style.top = `${Math.floor(((canvasHeight / 2) + (this.overlayHeight / 2)) - (bottomLeftDivBoundingRect.height / 2))}px`;
            }

            if (this.bottomRightDiv) {
                const bottomRightDivBoundingRect = this.bottomRightDiv.getBoundingClientRect();
                this.bottomRightDiv.style.left = `${Math.floor(((canvasWidth / 2) + (this.overlayWidth / 2)) - (bottomRightDivBoundingRect.width / 2))}px`;
                this.bottomRightDiv.style.top = `${Math.floor(((canvasHeight / 2) + (this.overlayHeight / 2)) - (bottomRightDivBoundingRect.height / 2))}px`;
            }

            const imageSizeEstimate = Math.round((overlayWidthAtTargetZoomLevel * overlayHeightAtTargetZoomLevel) / 1e6); // megapixels

            if (this.mapPreview) {
                const mapPreviewBoundingRect = this.mapPreview.getBoundingClientRect();

                this.mapPreview.style.left = `${Math.floor((canvasWidth / 2) - (this.overlayWidth / 2) - (mapPreviewBoundingRect.width / 2))}px`;
                this.mapPreview.style.top = `${Math.floor((canvasHeight / 2) - (mapPreviewBoundingRect.height / 2))}px`;
                let outerStyle, innerStyle;
                if (isExportSizeTooLarge || isExportSizeTooSmall) {
                    this.mapPreview.classList.add('map-export-frame__zoom-preview--disabled');
                    outerStyle = 'rgba(33, 33, 33, 0.2)';
                    innerStyle = 'rgba(255, 255, 255, 0.2)';
                } else {
                    this.mapPreview.classList.remove('map-export-frame__zoom-preview--disabled');
                    outerStyle = 'rgba(33, 33, 33, 0.2)';
                    innerStyle = 'rgba(255, 255, 255, 0.9)';
                }

                const pixelRatio = 1 / (2 ** (targetZoomLevel - mapViewer.dragonflyMap.getZoom()));
                ctx.strokeStyle = outerStyle;
                ctx.lineCap = 'round';
                ctx.setLineDash([5, 7]);
                ctx.lineWidth = 3;
                ctx.beginPath();
                ctx.moveTo(Math.floor((canvasWidth / 2) - (this.overlayWidth / 2)), Math.floor((canvasHeight / 2) - (mapPreviewBoundingRect.height / 2)));
                ctx.lineTo(Math.floor(canvasWidth / 2), Math.floor((canvasHeight / 2) - (pixelRatio * (mapPreviewBoundingRect.height / 2))));
                ctx.stroke();

                ctx.beginPath();
                ctx.moveTo(Math.floor((canvasWidth / 2) - (this.overlayWidth / 2)), Math.floor(((canvasHeight / 2) + (mapPreviewBoundingRect.height / 2))));
                ctx.lineTo(Math.floor(canvasWidth / 2), Math.floor((canvasHeight / 2) + (pixelRatio * (mapPreviewBoundingRect.height / 2))));
                ctx.stroke();

                ctx.strokeStyle = innerStyle;
                ctx.setLineDash([4, 8]);
                ctx.lineWidth = 1;
                ctx.beginPath();
                ctx.moveTo(Math.floor((canvasWidth / 2) - (this.overlayWidth / 2)), Math.floor((canvasHeight / 2) - (mapPreviewBoundingRect.height / 2)));
                ctx.lineTo(Math.floor(canvasWidth / 2), Math.floor((canvasHeight / 2) - (pixelRatio * (mapPreviewBoundingRect.height / 2))));
                ctx.stroke();

                ctx.beginPath();
                ctx.moveTo(Math.floor((canvasWidth / 2) - (this.overlayWidth / 2)), Math.floor(((canvasHeight / 2) + (mapPreviewBoundingRect.height / 2))));
                ctx.lineTo(Math.floor(canvasWidth / 2), Math.floor((canvasHeight / 2) + (pixelRatio * (mapPreviewBoundingRect.height / 2))));
                ctx.stroke();

                ctx.strokeStyle = outerStyle;
                ctx.setLineDash([]);
                ctx.lineWidth = 3;
                ctx.beginPath();
                ctx.arc(Math.floor(canvasWidth / 2), Math.floor(canvasHeight / 2), (mapPreviewBoundingRect.width / 2) * pixelRatio, 0, 2 * Math.PI);
                ctx.stroke();

                ctx.strokeStyle = innerStyle;
                ctx.lineWidth = 1;
                ctx.beginPath();
                ctx.arc(Math.floor(canvasWidth / 2), Math.floor(canvasHeight / 2), (mapPreviewBoundingRect.width / 2) * pixelRatio, 0, 2 * Math.PI);
                ctx.stroke();
            }

            if (isExportSizeTooLarge || isExportSizeTooSmall) {
                ctx.fillStyle = 'yellow';
            } else {
                ctx.fillStyle = 'white';
            }

            ctx.fillText(`${overlayWidthAtTargetZoomLevel} x ${overlayHeightAtTargetZoomLevel} px (${imageSizeEstimate >= 1 ? `~${imageSizeEstimate}` : '< 1'} MegaPixel${imageSizeEstimate > 1 ? 's' : ''})`, Math.floor(canvasWidth / 2), Math.floor(((canvasHeight / 2) - (this.overlayHeight / 2)) - 20));

            if (isExportSizeTooLarge && !this.isMoving) {
                ctx.fillStyle = 'yellow';
                ctx.fillText('Selected area is too large.', Math.floor(canvasWidth / 2), Math.floor(canvasHeight / 2) - 8);
                ctx.fillText('Adjust the settings to lower the area size.', Math.floor(canvasWidth / 2), Math.floor(canvasHeight / 2) + 8);
            } else if (isExportSizeTooSmall && !this.isMoving) {
                ctx.fillStyle = 'yellow';
                ctx.fillText('Selected area is too small.', Math.floor(canvasWidth / 2), Math.floor(canvasHeight / 2) - 8);
                ctx.fillText('Adjust the settings to increase the area size.', Math.floor(canvasWidth / 2), Math.floor(canvasHeight / 2) + 8);
            }

            // Provide parent components with information
            const sw = mapViewer.dragonflyMap.unproject([Math.floor((canvasWidth / 2) - (this.overlayWidth / 2)), Math.floor((canvasHeight / 2) + (this.overlayHeight / 2))]);
            const ne = mapViewer.dragonflyMap.unproject([Math.floor((canvasWidth / 2) + (this.overlayWidth / 2)), Math.floor((canvasHeight / 2) - (this.overlayHeight / 2))]);

            const bounds = new dragonfly.LngLatBounds(sw, ne);

            this.props.onOverlayChange({
                canvasWidth,
                canvasHeight,
                isExportSizeTooLarge,
                isExportSizeTooSmall,
                bounds,
            });
        }
    };

    render() {
        const { mapViewer } = this.props;

        // Wait for element DOM reference
        if (!mapViewer) return null;

        const { mapInstance } = this.props;

        const { isCustomZoomEnabled, targetZoomLevel, exportWarning } = this.props;
        const { exportStatus } = this.props;

        const exportInProgress = exportStatus !== undefined;

        const customZoomClasses = classNames('map-export-frame__zoom-preview', {
            'map-export-frame__zoom-preview--exporting': exportInProgress,
        });

        const canvasClasses = classNames('map-export-frame__overlay', {
            'map-export-frame_overlay--exporting': exportInProgress,
        });

        const closeButtonClasses = classNames('map-export-frame__exit', {
            'map-export-frame__exit--exporting': exportInProgress,
        });

        const pointClasses = classNames('map-export-frame__drag-point', {
            'map-export-frame__drag-point--exporting': exportInProgress,
            'map-export-frame__drag-point--flash': exportWarning,
        });

        const topLeftPointClasses = classNames(pointClasses, 'map-export-frame__drag-point--top-left');
        const topRightPointClasses = classNames(pointClasses, 'map-export-frame__drag-point--top-right');
        const bottomLeftPointClasses = classNames(pointClasses, 'map-export-frame__drag-point--bottom-left');
        const bottomRightPointClasses = classNames(pointClasses, 'map-export-frame__drag-point--bottom-right');

        return (<div>
            <div className="map-export-frame__export-overlay">
                {isCustomZoomEnabled && <div
                    ref={m => { this.mapPreview = m; }}
                    className={customZoomClasses}
                >
                    <MapPreview
                        ref={m => { this.mapPreviewComponent = m; }}
                        mapViewer={mapViewer}
                        targetZoomLevel={targetZoomLevel}
                    />
                </div>}

                <canvas
                    ref={c => { this.canvas = c; }}
                    className={canvasClasses}
                />
                <div
                    ref={t => { this.topLeftDiv = t; }}
                    className={topLeftPointClasses}
                >
                    &nbsp;
                </div>

                <div
                    ref={t => { this.topRightDiv = t; }}
                    className={topRightPointClasses}
                >
                    &nbsp;
                </div>

                <div
                    ref={t => { this.bottomLeftDiv = t; }}
                    className={bottomLeftPointClasses}
                >
                    &nbsp;
                </div>

                <div
                    ref={t => { this.bottomRightDiv = t; }}
                    className={bottomRightPointClasses}
                >
                    &nbsp;
                </div>

                <div className="map-export-frame__title">
                    {this.props.intl.formatMessage({ id: 'header.exportAsImage' })}
                </div>

                <button
                    className={closeButtonClasses}
                    onClick={this.onExit}
                >
                    <i className="material-icons">close</i>
                </button>

                {exportInProgress &&
                <div className="map-export-frame__export-progress">
                    <div className="map-export-frame__export-progress__message">
                        Exporting map<br />
                        This may take some time so please be patient
                    </div>
                    <LinearProgress
                        style={{ width: 400 }}
                        mode={exportStatus === 0 ? 'indeterminate' : 'determinate'}
                        value={exportStatus}
                    />
                </div>}
            </div>
            <div className="zoom-controls">
                <MapZoom mapInstance={mapInstance} />
            </div>
        </div>);
    }
}

MapExportFrameOverLay.propTypes = {
    targetAspectRatio: PropTypes.string.isRequired,
    targetZoomLevel: PropTypes.number.isRequired,
    targetDPI: PropTypes.number.isRequired,
    targetFormat: PropTypes.string.isRequired,
    exportStatus: PropTypes.number,
    exportWarning: PropTypes.bool.isRequired,
    isCustomZoomEnabled: PropTypes.bool.isRequired,
    onOverlayChange: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    mapInstance: PropTypes.object.isRequired,
};

MapExportFrameOverLay.defaultProps = {
    mapViewer: undefined,
    overlayOrigin: undefined,
    exportStatus: undefined,
};

export default injectIntl(MapExportFrameOverLay);
