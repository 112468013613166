/* eslint class-methods-use-this: 0 */

import BaseController from './BaseController';
import MapDataSource from '../dataSources/MapDataSource';
import ProjectDataSource from '../dataSources/ProjectDataSource';
import LayerOverride from '../objects/LayerOverride';
import LayerOverrideVisibility from '../enums/LayerOverrideVisibility';

class MapVisualizationController extends BaseController {
    static get name() {
        return 'MapVisualizationController';
    }

    static getInstance(options) {
        return new MapVisualizationController(options);
    }

    onActivate() {
        this.bindGluBusEvents({
            SET_PREFERRED_SUMMARY_LEVEL_REQUEST: this
                .onSetPreferredSummaryLevelRequest,
            CLEAR_PREFERRED_SUMMARY_LEVEL_REQUEST: this
                .onClearPreferredSummaryLevelRequest,
            SET_MIN_SUMMARY_LEVEL: this.onSetMinSummaryLevel,
            LOCATION_ANALYSIS_MODE_EXITED: this.onReturnPreviousSummaryLevel,
            SHOW_SATELLITE_REQUEST: this.onShowSatelliteRequest,
            HIDE_SATELLITE_REQUEST: this.onHideSatelliteRequest,
            UPDATE_MAP_LAYER_VISIBILITY_REQUEST: this
                .onUpdateMapLayerVisibilityRequest,
            APPLY_NEW_DATA_THEME_REQUEST: this.onApplyNewDataThemeRequest,
            MAP_ZOOM_END: this.onMapZoomEnd,
            MAP_MOVE_END: this.onMapMoveEnd,
            TOGGLE_SATELLITE_DATA_OVERLAY_SATELLITE_COLOR_REQUEST: this
                .onToggleSatelliteDataOverlaySatelliteColorRequest,
            SET_SATELLITE_DATA_OVERLAY_DATA_OPACITY_REQUEST: this
                .onSetSatelliteDataOverlayDataOpacityRequest,
            MAP_UPDATE_INITIAL_VIEW_REQUEST: this.onMapUpdateInitialViewRequest,
        });
        this.mapDataSource = this.activateSource(MapDataSource);
        this.projectDataSource = this.activateSource(ProjectDataSource);
    }

    onMapUpdateInitialViewRequest = e => {
        const mapInstance = this.projectDataSource.getActiveMapInstance(
            e.mapInstanceId,
        );
        if (!mapInstance) return;
        mapInstance.initialView = e.initialView;
        this.bus.emit('MAP_UPDATE_INITIAL_VIEW_SUCCESS', {
            mapInstanceId: e.mapInstanceId,
            initialView: e.initialView,
        });
    };

    onToggleSatelliteDataOverlaySatelliteColorRequest(e) {
        const mapInstance = this.projectDataSource.getActiveMapInstance(
            e.mapInstanceId,
        );
        if (!mapInstance) return;
        mapInstance.satelliteDataOverlaySatelliteHasColor = !mapInstance.satelliteDataOverlaySatelliteHasColor;
        this.bus.emit(
            'MAP_APPLY_SATELLITE_DATA_OVERLAY_SATELLITE_COLOR_UPDATE',
            { mapInstanceId: e.mapInstanceId },
        );
    }

    onSetSatelliteDataOverlayDataOpacityRequest(e) {
        const mapInstance = this.projectDataSource.getActiveMapInstance(
            e.mapInstanceId,
        );
        if (!mapInstance) return;
        mapInstance.satelliteDataOverlayDataOpacity = e.opacity;
        this.bus.emit('MAP_APPLY_SATELLITE_DATA_OVERLAY_DATA_OPACITY_UPDATE', {
            mapInstanceId: e.mapInstanceId,
        });
    }

    onApplyNewDataThemeRequest(e) {
        const mapInstance = this.projectDataSource.getActiveMapInstance(
            e.mapInstanceId,
        );
        if (!mapInstance) return;
        mapInstance.dataTheme = e.dataTheme;
        if (e.mapId && e.mapId !== mapInstance.currentMapId) {
            const currentMap = this.mapDataSource.currentMaps[
                mapInstance.currentMapId
            ];
            const newMap = this.mapDataSource.currentMaps[e.mapId];
            mapInstance.currentMapId = e.mapId;
            // try to lock the same summary level for the next map
            if (mapInstance.hasPreferredSummaryLevel) {
                const preferredSL = currentMap.getSummaryLevelForDataSourceId(
                    mapInstance.preferredDataLayerId,
                );
                const newPreferredDataSource = newMap.getDataSourceForSummaryLevelId(
                    preferredSL.id,
                );
                mapInstance.preferredDataLayerId = newPreferredDataSource
                    ? newPreferredDataSource.id
                    : undefined;
            }
            // if similar summary level could not be found but it must be locked
            // take the visible one or the first one from the next map
            if (!mapInstance.hasPreferredSummaryLevel && e.lockSummaryLevel) {
                const preferredSL =
                    newMap.getSummaryLevelOnZoom(
                        mapInstance.initialView.zoom,
                    ) || newMap.summaryLevels[0];
                mapInstance.preferredDataLayerId = newMap.getDataSourceForSummaryLevelId(
                    preferredSL.id,
                ).id;
            }
            // filters must be removed because ids of tables and columns are unique for each survey
            this.bus.emit('REMOVE_DATA_FILTER_REQUEST', {
                mapInstanceId: e.mapInstanceId,
            });
        }
        this.bus.emit('MAP_APPLY_DATA_THEME_REQUEST', {
            mapInstanceId: e.mapInstanceId,
        });
    }

    /**
     * @param {object} e
     * @param {string} e.mapInstanceId
     * @param {import('../').SummaryLevel} e.summaryLevel
     */
    onSetPreferredSummaryLevelRequest(e) {
        const mapInstance = this.projectDataSource.getActiveMapInstance(
            e.mapInstanceId,
        );
        if (!mapInstance) return;
        const map = this.mapDataSource.currentMaps[mapInstance.currentMapId];
        const dataSource = map.getDataSourceForSummaryLevelId(
            e.summaryLevel.id,
        );
        if (dataSource) {
            mapInstance.preferredDataLayerId = dataSource.id;
            this.bus.emit('MAP_UPDATE_PREFERRED_SUMMARY_LEVEL_REQUEST', {
                mapInstanceId: e.mapInstanceId,
            });
        }
    }

    /**
     * @param {object} e
     * @param {string} e.mapInstanceId
     */
    onClearPreferredSummaryLevelRequest(e) {
        const mapInstance = this.projectDataSource.getActiveMapInstance(
            e.mapInstanceId,
        );
        if (!mapInstance) return;
        mapInstance.preferredDataLayerId = undefined;
        this.bus.emit('MAP_UPDATE_PREFERRED_SUMMARY_LEVEL_REQUEST', {
            mapInstanceId: e.mapInstanceId,
        });
    }

    /**
     * Method will find minimal summary level for active survey on map and
     * select it unless some other summary level is already selected.
     *
     * We don't want to change user selected preferred summary level. Only if it
     * is set to be automatic, we will find lowest and set it as preferred
     * @param {string} mapInstanceId
     */
    onSetMinSummaryLevel(mapInstanceId) {
        const mapInstance = this.projectDataSource.getActiveMapInstance(
            mapInstanceId,
        );
        if (!mapInstance || (!mapInstance.isLocationAnalysisActive && mapInstance.preferredDataLayerId)) {
            this.mapDataSource.shouldReturnToAutomaticSummaryLevelOnLocationExit = false;
            return;
        }
        const map = this.mapDataSource.currentMaps[mapInstance.currentMapId];
        const minSummaryLevelId = map.reportMinSummaryLevel;
        const minSl = map.summaryLevels.find(sl => sl.id === minSummaryLevelId);
        this.onSetPreferredSummaryLevelRequest({
            mapInstanceId,
            summaryLevel: minSl,
        });
        this.mapDataSource.shouldReturnToAutomaticSummaryLevelOnLocationExit = true;
    }

    /**
     * Method will check if user had previously set preferred summary level. If
     * it as automatic, it will clear current summary level so that it can be
     * automatic again.
     * @param {object} param0
     * @param {string} param0.mapInstanceId
     * @param {import('../objects/LocationAnalysisItem').default} [param0.selectedItem]
     */
    onReturnPreviousSummaryLevel({ mapInstanceId }) {
        if (
            this.mapDataSource.shouldReturnToAutomaticSummaryLevelOnLocationExit
        ) {
            this.onClearPreferredSummaryLevelRequest({ mapInstanceId });
            this.mapDataSource.shouldReturnToAutomaticSummaryLevelOnLocationExit = false;
        }
    }

    onUpdateMapLayerVisibilityRequest({ mapInstanceId, layerId, visible }) {
        const mapInstance = this.projectDataSource.getActiveMapInstance(
            mapInstanceId,
        );
        // Reset the map layer
        const layerOverride = new LayerOverride();
        layerOverride.id = layerId;
        layerOverride.visibility = visible
            ? LayerOverrideVisibility.ALWAYS_SHOW
            : LayerOverrideVisibility.ALWAYS_HIDE;
        mapInstance.layerOverrides[layerOverride.id] = layerOverride;
        // Reset user data upload group visibility flag
        const userDataLayer = mapInstance.userDataLayers.find(dl => dl.id === layerId);
        if (userDataLayer) {
            userDataLayer.visible = visible;
        }
        this.bus.emit('MAP_APPLY_LAYERS_OVERRIDES_REQUEST', {
            mapInstanceId: mapInstance.id,
        });
    }

    onShowSatelliteRequest(e) {
        const mapInstance = this.projectDataSource.getActiveMapInstance(
            e.mapInstanceId,
        );
        if (!mapInstance) return;
        mapInstance.isSatelliteVisible = true;
        this.bus.emit('MAP_UPDATE_SATELLITE_LAYER_VISIBILITY_REQUEST', {
            mapInstanceId: e.mapInstanceId,
        });
    }

    onHideSatelliteRequest(e) {
        const mapInstance = this.projectDataSource.getActiveMapInstance(
            e.mapInstanceId,
        );
        if (!mapInstance) return;
        mapInstance.isSatelliteVisible = false;
        this.bus.emit('MAP_UPDATE_SATELLITE_LAYER_VISIBILITY_REQUEST', {
            mapInstanceId: e.mapInstanceId,
        });
    }

    onMapZoomEnd(e) {
        const mapInstance = this.projectDataSource.getActiveMapInstance(
            e.source.id,
        );
        if (!mapInstance) return;
        mapInstance.initialView.updatePosition(e.boundingBox, e.center, e.zoom);
    }

    onMapMoveEnd(e) {
        const mapInstance = this.projectDataSource.getActiveMapInstance(
            e.source.id,
        );
        if (!mapInstance) return;
        mapInstance.initialView.updatePosition(e.boundingBox, e.center, e.zoom);
    }

    onDeactivate() {
        this.unbindGluBusEvents();
    }
}

export default MapVisualizationController;
