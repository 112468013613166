module.exports={
  "keyboardShortcuts": "Keyboard shortcuts",
  "clickToSelectOrDeselectGeographies": "Click to select or deselect geographies.",
  "clickAndDragToDrawOnTheMap": "Click and drag to draw on the map. Release the mouse button to select.",
  "panMap": "Pan map.",
  "moveSelectionWhileDrawing": "Move selection while drawing.",
  "cancelSelection": "Cancel selection",
  "clickOnTheMapToEnterRadius": "Click on the map to enter radius manually or click and drag to draw the circle. Release the mouse button to select.",
  "startDrawingAShapeDouble": "Click to start drawing a shape. Double-click to finish and select.",
  "startDrawingALineDouble": "Click to start drawing a line. Double-click to finish and select.",
  "hint": "Hint",
  "isolateAreasBySetting": "Isolate areas by setting specific criteria using variables from current survey.",
  "noFilters": "No filter applied.",
  "uploadAndGeocodeYourPOIs": "Upload and geocode your places of interest using coordinates or addresses, and display them on the map.",
  "selection": {
    "pointSelection": "Point selection",
    "rectangleSelection": "Rectangle selection",
    "circleSelection": "Circle selection",
    "polygonSelection": "Polygon selection",
    "lineSelection": "Line selection",
    "selectFeaturesUsingDataset": "Select features using dataset",
    "selectFeatures": "Select features",
    "touching": "Touching",
    "enclosed": "Enclosed",
    "centroid": "Centroid",
    "loadingSelection": "Loading selection...",
    "unavailableDatasets": "Unavailable datasets",
    "dueToSizeConstraint": "due to size constraint"
  },
  "units": {
    "miles": "Miles",
    "yards": "Yards",
    "feet": "Feet",
    "kilometers": "Kilometers",
    "meters": "Meters"
  }
}
