import uuid from 'node-uuid';
import ColorPaletteType from '../enums/ColorPaletteType';

class ShadedBubbleLayerStyle {
    constructor(values) {
        this._isHidden = false;
        this._bubbleSizeFactor = 1;
        this._colorPaletteId = 'clash';
        this._colorPaletteType = ColorPaletteType.BUBBLE_DIVERGING;
        this._numberOfCutpoints = 5;
        this._id = uuid.v4();

        if (values) {
            Object.keys(values).forEach(k => {
                if (this.constructor.prototype.hasOwnProperty(k)) {
                    this[k] = values[k];
                }
            });
        }
    }

    /** @type {'shaded-bubble'} */
    get type() {
        return 'shaded-bubble';
    }

    get id() {
        return this._id;
    }

    get rules() {
        return this._rules;
    }

    set rules(rules) {
        this._rules = rules;
    }

    get isHidden() {
        return this._isHidden;
    }

    set isHidden(isHidden) {
        this._isHidden = isHidden;
    }

    get colorPaletteId() {
        return this._colorPaletteId;
    }

    set colorPaletteId(colorPaletteId) {
        this._colorPaletteId = colorPaletteId;
    }

    get colorPaletteType() {
        return this._colorPaletteType;
    }

    set colorPaletteType(colorPaletteType) {
        this._colorPaletteType = colorPaletteType;
    }

    get isFlipped() {
        return this._isFlipped;
    }

    set isFlipped(isFlipped) {
        this._isFlipped = isFlipped;
    }

    get bubbleSizeFactor() {
        return this._bubbleSizeFactor;
    }

    set bubbleSizeFactor(bubbleSizeFactor) {
        this._bubbleSizeFactor = bubbleSizeFactor;
    }

    get numberOfCutpoints() {
        return this._numberOfCutpoints;
    }

    set numberOfCutpoints(numberOfCutpoints) {
        this._numberOfCutpoints = numberOfCutpoints;
    }

    equals(that) {
        return this.type === that.type && this.isHidden === that.isHidden && this.rules === that.rules &&
        this.colorPaletteId === that.colorPaletteId &&
        this.colorPaletteType === that.colorPaletteType &&
        this.isFlipped === that.isFlipped &&
        this.bubbleSizeFactor === that.bubbleSizeFactor &&
        this.numberOfCutpoints === that.numberOfCutpoints;
    }

    clone() {
        return new ShadedBubbleLayerStyle({
            rules: this.rules,
            isHidden: this.isHidden,
            colorPaletteId: this.colorPaletteId,
            colorPaletteType: this.colorPaletteType,
            bubbleSizeFactor: this.bubbleSizeFactor,
            isFlipped: this.isFlipped,
            numberOfCutpoints: this.numberOfCutpoints,
        });
    }

    toJSON() {
        return {
            id: this.id,
            rules: this.rules.map(rule => rule.toJSON()),
            isHidden: this.isHidden,
            colorPaletteId: this.colorPaletteId,
            colorPaletteType: this.colorPaletteType,
            bubbleSizeFactor: this.bubbleSizeFactor,
            isFlipped: this.isFlipped,
            numberOfCutpoints: this.numberOfCutpoints,
        };
    }
}

export default ShadedBubbleLayerStyle;
