import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Key from '../../enums/Key';

class FileInput extends React.Component {
    onFileSelected = event => {
        const files = event.target.files;
        if (files.length > 0) {
            this.props.onChange(files);
            // clear file input value
            event.target.value = ''; // eslint-disable-line no-param-reassign
        }
    }

    // for the purpose of keyboard accessibility since the default look of file input type has been hidden
    // the user activates the the file upload dialog by pressing enter when on label element
    doInputClick = e => {
        if (e.which === Key.SPACE || e.which === Key.ENTER) {
            e.preventDefault();
            this.inputButton.click();
        }
    }

    render() {
        const { rootClassName, className, accept } = this.props;
        const label = this.props.label || this.props.intl.formatMessage({ id: 'upload' });
        return (<span className={classNames('file-upload-control', rootClassName)}>
            <label className="file-upload-control__label" htmlFor="file-upload-input">
                <input
                    id="file-upload-input"
                    ref={ref => { this.inputButton = ref; }}
                    onChange={this.onFileSelected}
                    className="file-upload-control__file-input" type="file" accept={accept}
                />
                {label &&
                <span
                    className={classNames('file-upload-control__text', className)}
                    role="button"
                    aria-controls="filename"
                    tabIndex="0"
                    onKeyUp={this.doInputClick}
                >{label}</span>}
            </label>
        </span>);
    }
}

FileInput.propTypes = {
    label: PropTypes.string,
    accept: PropTypes.string,
    className: PropTypes.string,
    rootClassName: PropTypes.string,
    onChange: PropTypes.func,
    intl: PropTypes.object.isRequired,
};

FileInput.defaultProps = {
    onChange: undefined,
    accept: '*',
    label: null,
    className: undefined,
    rootClassName: undefined,
};

export default injectIntl(FileInput);
