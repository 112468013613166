'use strict';var browser = require('../util/browser');
var shaders = require('../shaders');
var ref = require('../data/program_configuration');
var ProgramConfiguration = ref.ProgramConfiguration;
var VertexArrayObject = require('./vertex_array_object');
var applyDragonflyPragmas = require('../data/dragonfly_program_configuration').applyDragonflyPragmas;
var Program = function Program(gl, source, configuration, showOverdrawInspector) {
    var this$1 = this;
    this.gl = gl;
    this.program = gl.createProgram();
    var defines = configuration.defines().concat('#define DEVICE_PIXEL_RATIO ' + browser.devicePixelRatio.toFixed(1));
    if (showOverdrawInspector) {
        defines.push('#define OVERDRAW_INSPECTOR;');
    }
    var dragonflySource = applyDragonflyPragmas(configuration, {
        fragmentSource: source.fragmentSource,
        vertexSource: source.vertexSource
    });
    var fragmentSource = defines.concat(shaders.prelude.fragmentSource, dragonflySource.fragmentSource).join('\n');
    var vertexSource = defines.concat(shaders.prelude.vertexSource, dragonflySource.vertexSource).join('\n');
    var fragmentShader = gl.createShader(gl.FRAGMENT_SHADER);
    gl.shaderSource(fragmentShader, fragmentSource);
    gl.compileShader(fragmentShader);
    gl.attachShader(this.program, fragmentShader);
    var vertexShader = gl.createShader(gl.VERTEX_SHADER);
    gl.shaderSource(vertexShader, vertexSource);
    gl.compileShader(vertexShader);
    gl.attachShader(this.program, vertexShader);
    var layoutAttributes = configuration.interface ? configuration.interface.layoutAttributes : [];
    for (var i = 0; i < layoutAttributes.length; i++) {
        gl.bindAttribLocation(this$1.program, i, layoutAttributes[i].name);
    }
    gl.linkProgram(this.program);
    this.numAttributes = gl.getProgramParameter(this.program, gl.ACTIVE_ATTRIBUTES);
    this.attributes = {};
    this.uniforms = {};
    for (var i$1 = 0; i$1 < this.numAttributes; i$1++) {
        var attribute = gl.getActiveAttrib(this$1.program, i$1);
        if (attribute) {
            this$1.attributes[attribute.name] = gl.getAttribLocation(this$1.program, attribute.name);
        }
    }
    var numUniforms = gl.getProgramParameter(this.program, gl.ACTIVE_UNIFORMS);
    for (var i$2 = 0; i$2 < numUniforms; i$2++) {
        var uniform = gl.getActiveUniform(this$1.program, i$2);
        if (uniform) {
            this$1.uniforms[uniform.name] = gl.getUniformLocation(this$1.program, uniform.name);
        }
    }
};
Program.prototype.draw = function draw(gl, drawMode, layerID, layoutVertexBuffer, indexBuffer, segments, configuration, dynamicLayoutBuffer, dynamicLayoutBuffer2, featureMeta, elementPrefix) {
    var this$1 = this;
    var primitiveSize = (obj = {}, obj[gl.LINES] = 2, obj[gl.TRIANGLES] = 3, obj)[drawMode];
    var obj;
    var primitiveOffset, count;
    for (var i = 0, list = segments.get(); i < list.length; i += 1) {
        var segment = list[i];
        var vaos = segment.vaos || (segment.vaos = {});
        var vao = vaos[layerID] || (vaos[layerID] = new VertexArrayObject());
        if (featureMeta && configuration) {
            if (featureMeta['' + configuration.layer.type + (elementPrefix || '') + 'Element']) {
                primitiveOffset = featureMeta['' + configuration.layer.type + (elementPrefix || '') + 'Element'].offset;
                count = featureMeta['' + configuration.layer.type + (elementPrefix || '') + 'Element'].count;
                if (primitiveOffset >= segment.primitiveOffset && primitiveOffset + count <= segment.primitiveOffset + segment.primitiveLength) {
                    vao.bind(gl, this$1, layoutVertexBuffer, indexBuffer, configuration && configuration.paintVertexBuffer, segment.vertexOffset, dynamicLayoutBuffer, dynamicLayoutBuffer2);
                    gl.drawElements(drawMode, count * primitiveSize, gl.UNSIGNED_SHORT, primitiveOffset * primitiveSize * 2);
                }
            }
        } else {
            vao.bind(gl, this$1, layoutVertexBuffer, indexBuffer, configuration && configuration.paintVertexBuffer, segment.vertexOffset, dynamicLayoutBuffer, dynamicLayoutBuffer2);
            gl.drawElements(drawMode, segment.primitiveLength * primitiveSize, gl.UNSIGNED_SHORT, segment.primitiveOffset * primitiveSize * 2);
        }
    }
};
module.exports = Program;