import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import AppConfig from '../../../appConfig';
import BusComponent from '../../BusComponent';

import Loader from '../../Loader';
import TextInput from '../../form/TextInput';
import Upgrade from '../../upgrade/Upgrade';
import DataCategorySurvey from './DataCategorySurvey';
import DataSurveyYears from '../dataSurveyYears/DataSurveyYears';
import Tooltip from '../../Tooltip';

class DataCategoryTables extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            tablesBySurveysList: [],
            availableTablesVariablesBySurveysList: [],
            unavailableTablesVariablesBySurveysList: [],
            hasData: false,
            filterInputText: '',
        };
        this.bindGluBusEvents({
            CATEGORY_TABLES_LOAD_SUCCESS: this.onCategoryTablesLoadSuccess,
        });
    }

    componentDidMount() {
        this.emit('CATEGORY_TABLES_LOAD_REQUEST', {
            mapInstanceId: this.props.mapInstanceId,
            metadataGroupId: this.props.metadataGroupId,
            categoryName: this.props.categoryName,
            year: this.props.year,
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.categoryName !== this.props.categoryName ||
            nextProps.year !== this.props.year ||
            nextProps.metadataGroupId !== this.props.metadataGroupId) {
            this.emit('CATEGORY_TABLES_LOAD_REQUEST', {
                mapInstanceId: nextProps.mapInstanceId,
                metadataGroupId: nextProps.metadataGroupId,
                categoryName: nextProps.categoryName,
                year: nextProps.year,
            });
            this.setState({
                hasData: false,
            });
        }
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    onCategoryTablesLoadSuccess(eventMap) {
        const {
            availableTablesVariablesBySurveysList,
            unavailableTablesVariablesBySurveysList,
        } = this.groupDataByAvailability(eventMap.tablesBySurveysList);

        this.setState(
            {
                tablesBySurveysList: eventMap.tablesBySurveysList,
                availableTablesVariablesBySurveysList,
                unavailableTablesVariablesBySurveysList,
                hasData: true,
                filterInputText: '',
            },
            this.scrollToSelectedAndPrepareListScrollbar,
        );
    }

    onFilterTextChange = value => {
        const filterText = value ? value.toLowerCase().trim() : '';
        if (filterText && filterText.length > 0) {
            const filterArray = filterText.split(' ');
            const availableTablesVariablesBySurveysList = [];
            const unavailableTablesVariablesBySurveysList = [];
            this.state.tablesBySurveysList.forEach(tablesForSurvey => {
                const availableFilteredTables = [];
                const unavailableFilteredTables = [];
                tablesForSurvey.tables.forEach(table => {
                    const filteredVariables = table.variablesAsArray.filter(variable => {
                        const text = variable.getLabel(0, false).toLowerCase();
                        let variableLabelContainsFilterString = true;
                        for (let i = 0; i < filterArray.length; i += 1) {
                            if (text.indexOf(filterArray[i]) === -1) {
                                variableLabelContainsFilterString = false;
                                break;
                            }
                        }
                        return variableLabelContainsFilterString;
                    });
                    if (filteredVariables.length && table.availableToUser && table.enabled) {
                        availableFilteredTables.push({
                            id: table.uuid,
                            name: table.getTitleShort(0),
                            variables: filteredVariables,
                        });
                    }
                    if (filteredVariables.length && (!table.availableToUser || !table.enabled)) {
                        unavailableFilteredTables.push({
                            id: table.uuid,
                            name: table.getTitleShort(0),
                            variables: filteredVariables,
                        });
                    }
                });
                if (availableFilteredTables.length) {
                    availableTablesVariablesBySurveysList.push({
                        name: tablesForSurvey.surveyName,
                        displayName: tablesForSurvey.surveyDisplayName,
                        variablesByTables: availableFilteredTables,
                    });
                }
                if (unavailableFilteredTables.length) {
                    unavailableTablesVariablesBySurveysList.push({
                        name: tablesForSurvey.surveyName,
                        displayName: tablesForSurvey.surveyDisplayName,
                        variablesByTables: unavailableFilteredTables,
                    });
                }
            });
            this.setState({
                availableTablesVariablesBySurveysList,
                unavailableTablesVariablesBySurveysList,
                filterInputText: value,
            });
        } else {
            const { availableTablesVariablesBySurveysList, unavailableTablesVariablesBySurveysList } = this.groupDataByAvailability(this.state.tablesBySurveysList);
            this.setState({
                availableTablesVariablesBySurveysList,
                unavailableTablesVariablesBySurveysList,
                filterInputText: value,
            });
        }
    };

    onExit = () => {
        if (this.props.onExit) {
            this.props.onExit();
        }
    };

    onToggleCollapseCategorySurvey = surveyName => {
        const availableTablesVariablesBySurveysList = this.state.availableTablesVariablesBySurveysList;
        const categorySurvey = availableTablesVariablesBySurveysList.find(cg => cg.name === surveyName);
        categorySurvey.collapsed = !categorySurvey.collapsed;
        this.setState({
            availableTablesVariablesBySurveysList,
        });
    };

    onSelectedYearChange = year => {
        this.props.onYearChange(year);
    };

    renderCategorySurveysList() {
        const {
            hasData,
            availableTablesVariablesBySurveysList,
            unavailableTablesVariablesBySurveysList,
            filterInputText,
        } = this.state;

        const {
            mapInstanceId,
            hasDataFilter,
            currentMetadataSelection,
            intl,
        } = this.props;

        if (!hasData) {
            return (
                <Loader
                    text={intl.formatMessage({ id: 'variablePicker.gettingDataCategoryDetails' })}
                />
            );
        }

        const hasUnavailable = unavailableTablesVariablesBySurveysList.length;
        const hasAvailable = availableTablesVariablesBySurveysList.length;

        // if filter returns no results
        if (!hasAvailable && !hasUnavailable && filterInputText) {
            return (
                <div className="data-category-tables__no-results">
                    <img
                        className="data-category-tables__no-results__illustration"
                        src={`${AppConfig.constants.assetsBaseURL}/svg/search-no-results.svg`}
                        alt={intl.formatMessage({ id: 'searchIllustration' })}
                    />
                    <h2>{intl.formatMessage({ id: 'noResults' })}</h2>
                </div>
            );
        }

        // add available surveys groups
        const availableSurveysComponents = availableTablesVariablesBySurveysList.map(tablesVariablesForSurvey =>
            (
                <DataCategorySurvey
                    selectedVariableRef={node => {
                        this.selectedVariableRef = node;
                    }}
                    onToggleCollapsed={this.onToggleCollapseCategorySurvey}
                    key={`${tablesVariablesForSurvey.name}-available`}
                    available
                    collapsed={tablesVariablesForSurvey.collapsed}
                    surveyName={tablesVariablesForSurvey.name}
                    surveyDisplayName={tablesVariablesForSurvey.displayName}
                    tables={tablesVariablesForSurvey.variablesByTables}
                    mapInstanceId={mapInstanceId}
                    hasDataFilter={hasDataFilter}
                    currentMetadataSelection={currentMetadataSelection}
                />
            )
        );

        // add unavailable surveys groups
        const unavailableSurveysComponents = unavailableTablesVariablesBySurveysList.map(tablesVariablesForSurvey =>
            (
                <DataCategorySurvey
                    key={`${tablesVariablesForSurvey.name}-unavailable`}
                    surveyName={tablesVariablesForSurvey.name}
                    surveyDisplayName={tablesVariablesForSurvey.displayName}
                    tables={tablesVariablesForSurvey.variablesByTables}
                    mapInstanceId={mapInstanceId}
                    hasDataFilter={hasDataFilter}
                    currentMetadataSelection={currentMetadataSelection}
                />
            )
        );

        return (
            <div
                ref={
                    list => {
                        this.listRef = list;
                    }
                }
                className="data-category-tables__surveys-list flex-it column stretch vertical-scrollbar"
            >
                {availableSurveysComponents}
                {unavailableSurveysComponents}
            </div>
        );
    }

    render() {
        const {
            unavailableTablesVariablesBySurveysList,
            filterInputText,
        } = this.state;

        const {
            categoryYears,
            year,
            className,
            categoryIcon,
            categoryName,
            intl,
        } = this.props;

        let proLink;
        if (unavailableTablesVariablesBySurveysList.length) {
            proLink = (
                <Upgrade
                    className="flex-fixed"
                />
            );
        }

        let surveyYearSelector;
        if (categoryYears && categoryYears.length > 1) {
            surveyYearSelector = (
                <div className="data-category-tables__year-selector-years">
                    <DataSurveyYears
                        key="survey-years"
                        className="surveys-years--no-bottom-border surveys-years--no-padding"
                        years={categoryYears}
                        selectedYear={year}
                        onYearChange={this.onSelectedYearChange}
                    />
                </div>
            );
        }

        return (
            <div className={classnames('data-category-tables flex-it column', className)}>
                <div className="data-category-tables__details flex-it no-shrink center column space-between">
                    <div className="data-category-tables__year-selector-title">
                        <Tooltip
                            placement="bottom"
                            mouseEnterDelay={0.5}
                            mouseLeaveDelay={0}
                            overlay={intl.formatMessage({ id: 'dataBrowser.allCategories' })}
                        >
                            <button
                                id="back-to-data-category-selection-button"
                                className="material-icons btn-flat-icon data-category-tables__year-selector-title__back-button"
                                aria-label="Back"
                                onClick={this.onExit}
                            >
                                arrow_back
                            </button>
                        </Tooltip>
                        <div className="flex-it center justify-center grow">
                            <img
                                className="data-category-tables__icon"
                                src={categoryIcon}
                                alt="category icon"
                            />
                            <span className="data-category-tables__title">
                                {`${categoryName}, ${year}`}
                            </span>
                        </div>
                    </div>
                    {surveyYearSelector}
                </div>
                <div className="data-category-tables__filter">
                    <TextInput
                        className="input-box--categories-filter"
                        value={filterInputText}
                        placeholder={intl.formatMessage({ id: 'variablePicker.filterList' })}
                        onChange={this.onFilterTextChange}
                        showClearIcon
                        clearIcon="cancel"
                    />
                </div>
                {proLink}
                {this.renderCategorySurveysList()}
            </div>
        );
    }

    groupDataByAvailability(tablesBySurveysList) {
        const availableTablesVariablesBySurveysList = [];
        const unavailableTablesVariablesBySurveysList = [];
        tablesBySurveysList.forEach(tablesForSurvey => {
            const avaialableTables = tablesForSurvey.tables.filter(t => t.availableToUser && t.enabled);
            const unavailableTables = tablesForSurvey.tables.filter(t => !t.availableToUser || !t.enabled);
            if (avaialableTables.length) {
                availableTablesVariablesBySurveysList.push({
                    name: tablesForSurvey.surveyName,
                    displayName: tablesForSurvey.surveyDisplayName,
                    variablesByTables: avaialableTables.map(table => ({
                        id: table.uuid,
                        name: table.getTitleShort(0),
                        variables: table.variablesAsArray,
                    })),
                });
            }
            if (unavailableTables.length) {
                unavailableTablesVariablesBySurveysList.push({
                    name: tablesForSurvey.surveyName,
                    displayName: tablesForSurvey.surveyDisplayName,
                    variablesByTables: unavailableTables.map(table => ({
                        id: table.uuid,
                        name: table.title,
                        variables: table.variablesAsArray,
                    })),
                });
            }
        });
        return {
            availableTablesVariablesBySurveysList,
            unavailableTablesVariablesBySurveysList,
        };
    }

    // Scroll list so selected variable is centered and
    // if virtual scrollbar is present for survey list scroll it to correct position
    scrollToSelectedAndPrepareListScrollbar() {
        window.requestAnimationFrame(() => {
            let listScrollTop = 0;
            if (this.listRef && this.selectedVariableRef) {
                // offsetTop property returns the top position (in pixels) relative to the top of the offsetParent element
                // Previously this element was the data-browser div since it was the first ancestor with a relative position
                // Now that is changed so that the data-category-tables__surveys-list is the offsetParent
                listScrollTop = this.selectedVariableRef.offsetTop - (this.listRef.clientHeight / 2);
                this.listRef.scrollTop = listScrollTop;
            }
        });
    }
}

DataCategoryTables.propTypes = {
    className: PropTypes.string,
    categoryName: PropTypes.string.isRequired,
    categoryIcon: PropTypes.string.isRequired,
    categoryYears: PropTypes.array.isRequired,
    year: PropTypes.number.isRequired,
    mapInstanceId: PropTypes.string.isRequired,
    hasDataFilter: PropTypes.bool,
    metadataGroupId: PropTypes.string.isRequired,
    currentMetadataSelection: PropTypes.object.isRequired,
    onYearChange: PropTypes.func.isRequired,
    onExit: PropTypes.func,
    intl: PropTypes.object.isRequired,
};

DataCategoryTables.defaultProps = {
    className: '',
    onExit: undefined,
    hasDataFilter: false,
};

export default injectIntl(DataCategoryTables);
