import MapInstanceData from './data/MapInstanceData';
import SearchData from './data/SearchData';
import LocationData from './data/LocationData';
import UserDataLayersData from './data/UserDataLayersData';
import LibraryLayersData from './data/LibraryDataLayersData';
import SelectionData from './data/SelectionData';
import MaskData from './data/MaskData';
import ReportData from './data/ReportData';
import AnnotationData from './data/AnnotationData';
import CustomMapSelectionData from './data/CustomMapSelectionData';
import GeoJsonLayersData from './data/GeoJsonLayersData';

class MapData {
    constructor(mapConfig, mapInstance, options) {
        this._mapConfig = mapConfig;
        this._mapInstance = mapInstance;
        this._dragonflyLayers = this._mapConfig.layers.filter(layer => layer !== mapConfig.dataPlaceholder)
                                                        .map(layer => JSON.parse(JSON.stringify(layer)));
        this._dragonflySources = Object.assign({}, this._mapConfig.dragonflySources);
        this._mapInstanceData = new MapInstanceData(this._mapInstance, this._mapConfig, this._dragonflyLayers, this._dragonflySources);
        if (options.selection) {
            this._selectionData = new SelectionData(this._mapInstance, this._mapConfig, this._dragonflyLayers, this._dragonflySources);
        }
        if (options.mask) {
            this._maskData = new MaskData(this._mapInstance, this._mapConfig, this._dragonflyLayers, this._dragonflySources);
        }
        if (options.report) {
            this._reportData = new ReportData(this._mapInstance, this._mapConfig, this._dragonflyLayers, this._dragonflySources);
            if (mapInstance.hasMaskingFilter) {
                const maskingFilter = Object.values(mapInstance.dataGeoFilters)[0];
                this._selectionData.filterAvailableFeatures(maskingFilter.filteredValues);
            }
        }
        this._searchData = new SearchData(this._mapInstance, this._mapConfig);
        if (mapInstance._searchResults) {
            this._searchData.build([mapInstance._searchResults]);
        }
        this._locationData = new LocationData();
        this._userDataLayersData = new UserDataLayersData(this._mapInstance, this._mapConfig, this._dragonflyLayers, this._dragonflySources);
        this._libraryLayersData = new LibraryLayersData(this._mapInstance, this._mapConfig, this._dragonflyLayers, this._dragonflySources);
        this._customMapSelectionData = new CustomMapSelectionData(this._mapInstance, this._mapConfig, this._dragonflyLayers, this._dragonflySources);
        this._geoJsonLayersData = new GeoJsonLayersData(this._mapInstance, this._mapConfig, this._dragonflyLayers, this._dragonflySources);
        this._annotationData = new AnnotationData();
    }

    get userDataLayersData() {
        return this._userDataLayersData;
    }

    get libraryLayersData() {
        return this._libraryLayersData;
    }

    get mapInstanceData() {
        return this._mapInstanceData;
    }

    get searchData() {
        return this._searchData;
    }

    get locationData() {
        return this._locationData;
    }

    get selectionData() {
        return this._selectionData;
    }

    get maskData() {
        return this._maskData;
    }

    get reportData() {
        return this._reportData;
    }

    get annotationData() {
        return this._annotationData;
    }

    get customMapSelectionData() {
        return this._customMapSelectionData;
    }

    get geoJsonLayersData() {
        return this._geoJsonLayersData;
    }

    get minZoom() {
        if (this._mapInstance.minZoomLevelRestriction !== undefined) {
            return this._mapInstance.minZoomLevelRestriction;
        }
        if (this._mapConfig.minZoom) {
            return this._mapConfig.minZoom;
        }
        return 0;
    }

    get maxZoom() {
        if (this._mapInstance.maxZoomLevelRestriction !== undefined) {
            return this._mapInstance.maxZoomLevelRestriction;
        }
        if (this._mapConfig.maxZoom) {
            return this._mapConfig.maxZoom;
        }
        return 20;
    }

    get layers() {
        return this._dragonflyLayers;
    }

    get sources() {
        return this._dragonflySources;
    }

    get mapClasses() {
        return this._mapInstanceData.mapClasses;
    }

    get summaryLevels() {
        return this._mapConfig.summaryLevels;
    }

    get visibleSummaryLevels() {
        return this.summaryLevels.filter(sl => sl.visible);
    }
}

export default MapData;
