import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';

import { isValidNumber } from '../../helpers/Util';

class CircleRadius extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            radius: '',
            inputIsValid: true,
        };
    }

    componentDidMount() {
        this.input.focus();
    }

    onDone = () => {
        if (this.state.inputIsValid) {
            const value = numeral(this.state.radius).value();
            this.props.onChange(value);
        }
        this.input.focus();
    }

    onCancel = () => {
        this.props.onCancel();
    }

    onRadiusChange = e => {
        const { value } = e.target;
        const inputIsValid = value.length > 0 && isValidNumber(value);
        this.setState({ radius: value, inputIsValid });
    }

    render() {
        const { radius, inputIsValid } = this.state;

        const inputClasses = classNames('selection-circle-radius__input', {
            'selection-circle-radius__input--invalid': radius.trim().length > 0 && !inputIsValid,
        });

        return (
            <div className="selection-circle-radius">
                <div className="selection-circle-radius__title">Circle radius ({this.props.units})</div>
                <div className={inputClasses}>
                    <input ref={c => (this.input = c)} value={radius} onChange={this.onRadiusChange} />
                </div>
                <div className="selection-circle-radius__actions">
                    <button onKeyPress={this.onDone} onClick={this.onCancel} className="btn-flat">
                        {this.props.intl.formatMessage({ id: 'cancel' })}
                    </button>
                    <button disabled={!inputIsValid} onKeyPress={this.onDone} onClick={this.onDone} className="btn-raised">
                        {this.props.intl.formatMessage({ id: 'ok' })}
                    </button>
                </div>
            </div>
        );
    }
}

CircleRadius.propTypes = {
    onChange: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    units: PropTypes.string.isRequired,
    intl: PropTypes.object.isRequired,
};

CircleRadius.defaultProps = {
};

export default injectIntl(CircleRadius);
