// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * @param {object} param0
 * @param {boolean} param0.visible
 * @param {string} param0.className
 * @param {() => void} param0.onClick
 */
const VisibilityToggle = ({ visible, className, onClick }) => {
    const visibilityIcon = visible ? 'visibility' : 'visibility_off';
    const classes = classNames(
        className,
        'btn-icon',
        'btn-icon--small',
        'material-icons',
    );
    return (
        <button className={classes} onClick={onClick}>
            <i className="material-icons">{visibilityIcon}</i>
        </button>
    );
};

export default VisibilityToggle;

VisibilityToggle.propTypes = {
    onClick: PropTypes.func.isRequired,
    visible: PropTypes.bool,
    className: PropTypes.string,
};

VisibilityToggle.defaultProps = {
    visible: true,
    className: undefined,
};
