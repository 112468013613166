// @ts-check
import React from 'react';
import { injectIntl } from 'react-intl';
import BusComponent from '../BusComponent';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/** @type {object[]} */
import AppConfig from '../../appConfig';

/**
 * @typedef Props
 * @property {import('react-intl').intlShape} intl
 *
 * @typedef State
 * @property {object[]} availableSummaryLevels
 * @property {string[]} correspondingSummaryLevelIds
 *
 * @extends {BusComponent<Props, State>}
 */

class CorrespondingGeographiesSelector extends BusComponent {
    constructor(props, context) {
        super(props, context);
        /** @type {State} */
        this.state = {
            availableSummaryLevels: [],
            correspondingSummaryLevelIds: [],
        };
    }

    componentDidMount() {
        this.bindGluBusEvents({
            AVAILABLE_CORRESPONDING_SUMMARY_LEVELS_SET: this.onAvailableSummaryLevelsSet,
        });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    /**
     * @param {object} param0
     * @param {object[]} param0.availableSummaryLevels
     */
    onAvailableSummaryLevelsSet = ({ availableSummaryLevels }) => {
        this.setState({
            availableSummaryLevels: AppConfig.constants.correspondingSummaryLevels.filter(sl => !!availableSummaryLevels.includes(sl.id)),
            correspondingSummaryLevelIds: [],
        });
    };

    /**
     * @param {string} summaryLevelId
     */
    onChangeSummaryLevel = summaryLevelId => {
        const summaryLevelIdAlreadyExists = this.state.correspondingSummaryLevelIds.some(slid => slid === summaryLevelId);

        const correspondingSummaryLevelIds = summaryLevelIdAlreadyExists ?
            this.state.correspondingSummaryLevelIds.filter(slid => slid !== summaryLevelId) :
            [...this.state.correspondingSummaryLevelIds, summaryLevelId];

        this.setState({ correspondingSummaryLevelIds });

        this.emit('SET_CORRESPONDING_SUMMARY_LEVELS', {
            summaryLevels: this.state.availableSummaryLevels.filter(sl => correspondingSummaryLevelIds.includes(sl.id)),
        });
    };

    render() {
        const {
            availableSummaryLevels,
            correspondingSummaryLevelIds,
        } = this.state;

        const { intl } = this.props;

        const renderSummaryLevelPicker = !!availableSummaryLevels.length;

        return (
            <div className="location-analysis__section location-analysis__section--border-bottom location-analysis__section--padded">
                <div className="location-analysis__section-hint flex-it row flex-start center">
                    <span><strong>{intl.formatMessage({ id: 'locationAnalysis.selectCorrespondingGeographies' })}</strong>&nbsp;</span>
                    <span>&nbsp;({intl.formatMessage({ id: 'locationAnalysis.optional' })})</span>
                </div>
                {!renderSummaryLevelPicker && <div className="location-analysis__section-hint flex-it row flex-start center">
                    <i>{intl.formatMessage({ id: 'locationAnalysis.notAvailableForSelectedSurvey' })}</i>
                </div>}
                {renderSummaryLevelPicker && <div className="location-analysis__btn-group">
                    {availableSummaryLevels.map(summaryLevel => <button
                        key={summaryLevel.id}
                        className={classNames('btn-chip', 'location-analysis__btn', 'location-analysis__btn--flexible',
                            { 'location-analysis__btn--active': correspondingSummaryLevelIds.some(slid => slid === summaryLevel.id) }
                        )}
                        onClick={() => this.onChangeSummaryLevel(summaryLevel.id)}
                    >
                        {summaryLevel.label}
                    </button>)}
                </div>}
            </div>
        );
    }
}

CorrespondingGeographiesSelector.propTypes = {
    intl: PropTypes.object.isRequired,
};

export default injectIntl(CorrespondingGeographiesSelector);
