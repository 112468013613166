import dragonfly from 'dragonfly-v3';
import { detect } from 'detect-browser';

export const MARKER_MAPPINGS = {
    polyline: '\u0070',
    polygon: '\u0071',
    freehand: '\u0067',
    flowarrow: '\u0066',
    shape: '\u006b',
    label: '\u006e',
    hotspot: '\u006c',
    image: '\u006c', // TODO this is hotspot, image annotation is missing from current font
    wetland: '\u0041',
    water: '\u0042',
    'waste-basket': '\u0043',
    warehouse: '\u0044',
    village: '\u0045',
    'triangle-stroked': '\u0046',
    triangle: '\u0047',
    'town-hall': '\u0048',
    town: '\u0049',
    toilets: '\u004a',
    theatre: '\u004b',
    shop: '\u004c',
    skiing: '\u004d',
    skins: '\u004e',
    slaughterhouse: '\u004f',
    soccer: '\u0050',
    square: '\u0051',
    'square-stroked': '\u0052',
    star: '\u0053',
    'star-stroked': '\u0054',
    suitcase: '\u0055',
    swimming: '\u0056',
    telephone: '\u0057',
    tennis: '\u0058',
    shells: '\u0059',
    scooter: '\u005a',
    school: '\u0030',
    rocket: '\u0031',
    'road-block': '\u0032',
    restaurant: '\u0033',
    'religious-muslim': '\u0034',
    'religious-jewish': '\u0035',
    'religious-christian': '\u0036',
    rail: '\u0037',
    post: '\u0038',
    prison: '\u0039',
    'polling-place': '\u0021',
    monument: '\u0022',
    museum: '\u0023',
    music: '\u0024',
    'oil-well': '\u0025',
    park: '\u0026',
    parking: '\u0027',
    'parking-garage': '\u0028',
    pharmacy: '\u0029',
    pitch: '\u002a',
    'place-of-workship': '\u002b',
    playground: '\u002c',
    point: '\u002d',
    police: '\u002e',
    'mobile-phone': '\u002f',
    heart: '\u003a',
    farm: '\u003b',
    'clothing-store': '\u003c',
    building: '\u003d',
    boom: '\u003e',
    bicycle: '\u003f',
    beer: '\u0040',
    basketball: '\u005b',
    baseball: '\u005d',
    bar: '\u005e',
    bank: '\u005f',
    bakery: '\u0060',
    'art-gallery': '\u007b',
    airport: '\u007c',
    'america-football': '\u007d',
    airfield: '\u007e',
    bus: '\u005c',
    college: '\ue000',
    'fast-food': '\ue001',
    helioport: '\ue002',
    hospital: '\ue003',
    industrial: '\ue004',
    'land-use': '\ue005',
    library: '\ue006',
    lighthouse: '\ue007',
    'liquor-store': '\ue008',
    lodging: '\ue009',
    logging: '\ue00a',
    'marker-default': '\ue00b',
    minefield: '\ue00c',
    minerals: '\ue00d',
    harbor: '\ue00e',
    explosion: '\ue00f',
    city: '\ue010',
    'circle-2': '\ue011',
    'circle-1': '\ue012',
    circle: '\ue013',
    chemist: '\ue014',
    cinema: '\ue015',
    cemetery: '\ue016',
    car: '\ue017',
    campsite: '\ue018',
    cafe: '\ue019',
    camera: '\ue01a',
    commercial: '\ue01b',
    feathers: '\ue01c',
    ferry: '\ue01d',
    'fire-station': '\ue01e',
    fish: '\ue01f',
    fuel: '\ue020',
    garden: '\ue021',
    golf: '\ue022',
    grapes: '\ue023',
    grocery: '\ue024',
    hairdresser: '\ue025',
    entrance: '\ue026',
    'emergency-telephone': '\ue027',
    embassy: '\ue028',
    'dog-park': '\ue029',
    disability: '\ue02a',
    danger: '\ue02b',
    dam: '\ue02c',
    cross: '\ue02d',
    corn: '\ue02e',
    rectangle: '\ue038',
    'rectangle-stroked': '\ue039',
};

export function getCanvasLimits() {
    let maxSupportedOutputWidth, maxSupportedOutputHeight, maxSupportedOutputArea;
    const browser = detect();
    switch (browser && browser.name) {
    case 'chrome':
    case 'safari':
        maxSupportedOutputWidth = 32767;
        maxSupportedOutputHeight = 32767;
        maxSupportedOutputArea = 134217728;
        break;
    case 'firefox':
        maxSupportedOutputWidth = 32767;
        maxSupportedOutputHeight = 32767;
        maxSupportedOutputArea = 134217728;
        break;
    case 'edge':
    case 'ie':
        maxSupportedOutputWidth = 8192;
        maxSupportedOutputHeight = 8192;
        maxSupportedOutputArea = 8192 * 8192;
        break;
    default:
        /* Default to the lowest settings to be on the safe side... */
        maxSupportedOutputWidth = 8192;
        maxSupportedOutputHeight = 8192;
        maxSupportedOutputArea = 8192 * 8192;
    }

    return { maxSupportedOutputWidth, maxSupportedOutputHeight, maxSupportedOutputArea };
}

export async function snapshotMapBoundsAtZoomLevel(sourceMap, lngLatBounds, zoomLevel, targetDPI = undefined, layersMasks = [], extraImages = [], progressFn = undefined) {
    return new Promise((resolveSnapshotMapBoundsAtZoomLevel, rejectSnapshotMapBoundsAtZoomLevel) => {
        const takeSnapshot = map => new Promise(resolve => {
            const onRendered = () => {
                if (map.loaded()) {
                    // all tiles are looaded so resolve promise now
                    map.off('rendered', onRendered);
                    resolve(map.getCanvas());
                }
            };
            map.on('rendered', onRendered);
        });

        /*
           cameraMapWidth and cameraMapHeight are used for width and height of an offscreen map that we will be
           using as a camera to snapshot parts of the map at the given zoom level.

           n.b. I played around with the numbers, and it turns out that the higher width and height work better
           since there appears to be a small delay after the tiles are loaded and the 'rendered' event is fired,
           which quickly adds up as the number of required camera pans grows... But then again, on machines with low
           resources this may lead to issues such as losing WebGL context.

           Perhaps we should calculate these properties dynamically based on the image size...
        */

        const projectedNorthEast = sourceMap.project(lngLatBounds.getNorthEast());
        const cameraMapWidth = 2048;
        let cameraMapHeight = 2048;
        const topOfTheWorld = sourceMap.project([lngLatBounds.getNorthEast().lng, 85]);

        // Fixes a case when the map is too zoomed out and it cannot be panned pass the top of the world
        // If the difference betweeen top of the world is less than 2048px, then use that height for camera
        if (Math.abs(topOfTheWorld.y - projectedNorthEast.y) < cameraMapHeight) {
            const diff = Math.abs(projectedNorthEast.y - topOfTheWorld.y);
            cameraMapHeight = Math.floor(diff);
        }

        /*
           In order to achieve higher DPI we have to fake window.devicePixelRatio property to trick the map into
           rendering tiles at the desired DPI since it will think our screen is of the given DPI.

           But we also have to remember the actual device pixel ratio so we can restore it when we're done.
         */
        const actualPixelRatio = window.devicePixelRatio;
        let dpi = actualPixelRatio * 96;
        if (targetDPI !== undefined) {
            dpi = targetDPI;
        }

        Object.defineProperty(window, 'devicePixelRatio', {
            get: () => dpi / 96,
        });

        /*
           In order for the map to be able to resize itself to our desired width and height it has to be mounted
           to the DOM, so we create a hidden div element that will contain our map container.
         */
        const hidden = document.createElement('div');
        hidden.style.overflow = 'hidden';
        hidden.style.position = 'fixed';
        hidden.style.height = '0';
        hidden.style.width = '0';

        document.body.appendChild(hidden);

        const container = document.createElement('div');
        container.style.width = `${cameraMapWidth}px`;
        container.style.height = `${cameraMapHeight}px`;

        hidden.appendChild(container);

        /*
           To be able to get the rendered map canvas as an image, we have to set the preserveDrawingBuffer property to true.
         */
        const sourceMapStyle = sourceMap.getStyle();

        const cameraMap = new dragonfly.Map({
            container,
            style: sourceMapStyle,
            preserveDrawingBuffer: true,
            attributionControl: false,
        });

        /*
           To blend streets, we use special painter feature available in dragonfly that will do that for us,
           but we have to add the masks manually.
         */
        layersMasks.forEach(mask => {
            cameraMap.painter.addLayerMask(mask);
        });

        /*
           To render user data layers, we need to load the images that have been used but are not in the map sprite.
         */
        extraImages.forEach(({ id, image, pixelRatio }) => {
            cameraMap.addImage(id, image, { pixelRatio });
        });

        /*
           Since we created map container dynamically, we have to call cameraMap.resize() to get the map to match the container's size.
         */
        cameraMap.resize();

        cameraMap.jumpTo({ center: sourceMap.getCenter(), zoom: zoomLevel, duration: 0 });

        cameraMap.on('load', () => {
            /*
                Thought we would get off easy with this one? Hah! As with everything in this broken world, different browsers have different limitations...
                Calculate the final export image width and height and see if we'll be able to pull it out for the current browser...

                Chrome:
                    Maximum height/width: 32,767 pixels
                    Maximum area: 268,435,456 pixels (e.g., 16,384 x 16,384)

                Firefox:
                    Maximum height/width: 32,767 pixels
                    Maximum area: 472,907,776 pixels (e.g., 22,528 x 20,992)

                IE:
                    Maximum height/width: 8,192 pixels
                    Maximum area: N/A

                Exceeding the maximum length/width/area on most browsers renders the canvas unusable.
                (It will ignore any draw commands, even in the usable area.) Except IE.
                IE will honor all draw commands within the usable space...

                n.b. The only way we could get around this limitation is to have multi image export and let the user combine the images into one using more suitable tools like montage from ImageMagick.
             */

            const ne = lngLatBounds.getNorthEast();
            const sw = lngLatBounds.getSouthWest();

            const topRight = cameraMap.project(ne);
            const bottomLeft = cameraMap.project(sw);

            const outputWidth = Math.round(Math.abs(bottomLeft.x - topRight.x));
            const outputHeight = Math.round(Math.abs(topRight.y - bottomLeft.y));

            const { maxSupportedOutputWidth, maxSupportedOutputHeight, maxSupportedOutputArea } = getCanvasLimits();

            /* Don't be confused by the window.devicePixelRatio usage here, we redefined it earlier to a fake value to get the requested target DPI */
            if ((outputWidth * window.devicePixelRatio > maxSupportedOutputWidth || outputHeight * window.devicePixelRatio > maxSupportedOutputHeight) || (outputWidth * window.devicePixelRatio * outputHeight * window.devicePixelRatio > maxSupportedOutputArea)) {
                rejectSnapshotMapBoundsAtZoomLevel(`Please select smaller area, or decrease the DPI... Calculated width x height => ${outputWidth * window.devicePixelRatio} x ${outputHeight * window.devicePixelRatio}`);
                return;
            }

            const horizontalTiles = Math.ceil(outputWidth / cameraMapWidth);
            const verticalTiles = Math.ceil(outputHeight / cameraMapHeight);

            cameraMap.panBy([-topRight.x, topRight.y], { duration: 0 });

            const canvas = document.createElement('canvas');

            canvas.width = outputWidth * window.devicePixelRatio;
            canvas.height = outputHeight * window.devicePixelRatio;

            const ctx = canvas.getContext('2d');

            const drawPartOfMapFns = [];

            for (let row = 0; row < verticalTiles; row += 1) {
                for (let column = 0; column < horizontalTiles; column += 1) {
                    let fn;
                    if (column === 0 && row !== 0) {
                        fn = () => new Promise(resolve => {
                            cameraMap.panBy([-cameraMapWidth * (horizontalTiles - 1), cameraMapHeight], { duration: 0 });
                            takeSnapshot(cameraMap).then(img => {
                                ctx.drawImage(img, column * cameraMapWidth * window.devicePixelRatio, row * cameraMapHeight * window.devicePixelRatio);
                                if (progressFn) {
                                    progressFn({
                                        row: row + 1,
                                        column: column + 1,
                                        totalRows: verticalTiles,
                                        totalColumns: horizontalTiles,
                                    });
                                }
                                resolve();
                            });
                        });
                    } else {
                        fn = () => new Promise(resolve => {
                            cameraMap.panBy([cameraMapWidth, 0], { duration: 0 });
                            takeSnapshot(cameraMap).then(img => {
                                ctx.drawImage(img, column * cameraMapWidth * window.devicePixelRatio, row * cameraMapHeight * window.devicePixelRatio);
                                if (progressFn) {
                                    progressFn({
                                        row: row + 1,
                                        column: column + 1,
                                        totalRows: verticalTiles,
                                        totalColumns: horizontalTiles,
                                    });
                                }
                                resolve();
                            });
                        });
                    }

                    drawPartOfMapFns.push(fn);
                }
            }

            const executePromisesInSeries = list => {
                const p = Promise.resolve();
                return list.reduce((pacc, fn) => {
                    pacc = pacc.then(fn);
                    return pacc;
                }, p);
            };

            executePromisesInSeries(drawPartOfMapFns.concat([() => {
                Object.defineProperty(window, 'devicePixelRatio', {
                    get: () => actualPixelRatio,
                });

                // do some cleanup before resolving the promise
                cameraMap.remove();
                hidden.parentElement.removeChild(hidden);

                resolveSnapshotMapBoundsAtZoomLevel(canvas);
            }]));
        });
    });
}

export async function canvasToBlob(canvas) {
    return new Promise(resolve => {
        canvas.toBlob(blob => resolve(blob));
    });
}
