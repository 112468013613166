import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import AnnotationMetadata from '../../enums/AnnotationMetadata';

const WHITE = '#ffffff';
const BLACK = '#000000';

const AnnotationIcon = ({ annotation, iconColor, className }) => {
    let color;
    if (iconColor) {
        color = iconColor;
    } else {
        switch (annotation.type.toLowerCase()) {
        case AnnotationMetadata.marker.type:
        case AnnotationMetadata.flowarrow.type:
        case AnnotationMetadata.polygon.type:
        case AnnotationMetadata.shape.type:
            color = annotation.fillColor;
            break;
        case AnnotationMetadata.polyline.type:
        case AnnotationMetadata.freehand.type:
        case AnnotationMetadata.hotspot.type:
            color = annotation.strokeColor;
            break;
        case AnnotationMetadata.label.type:
            color = annotation.textColor;
            break;
        default:
            color = BLACK;
        }
    }

    const classes = classNames('annotation-icon', className);
    const textShadow = color && color.toLowerCase() === WHITE ? '0 0 1px #969696' : 'none';
    const icon = annotation.type === 'Marker' ?
        <i className={classNames('marker-picker__icon', `socex-icon-marker-${annotation.markerPathId}`)} style={{ color }} /> :
        AnnotationMetadata[annotation.type.toLowerCase()].icon;

    return (
        <div
            className={classes}
            style={{ color, textShadow }}
        >
            {icon}
        </div>
    );
};

AnnotationIcon.propTypes = {
    annotation: PropTypes.object.isRequired,
    iconColor: PropTypes.string,
    className: PropTypes.string,
};

AnnotationIcon.defaultProps = {
    iconColor: undefined,
    className: undefined,
};

export default AnnotationIcon;
