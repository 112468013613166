import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Key from '../../../enums/Key';
import { injectIntl } from 'react-intl';

class QuickYearRangeSelectionButton extends React.Component {

    /** @param {React.SyntheticEvent} e */
    onClick = e => {
        if (!this.props.isActive) {
            // Prevent React from resetting event properties
            // Read more here https://reactjs.org/docs/legacy-event-pooling.html
            // and https://reactjs.org/docs/events.html#mouse-events
            e.persist();
            this.props.onClick(e, this.props.currentYear, this.props.compareYear, this.props.pointOfReferenceYear);
        }
    };

    onKeyDown = e => {
        if (e.keyCode === Key.ENTER) {
            this.onClick(e);
        }
    };

    onCancel = e => {
        e.stopPropagation();
        this.props.onCancel();
    };

    onCancelKeyDown = e => {
        if (e.keyCode === Key.ENTER) {
            this.onCancel(e);
        }
    };

    render() {
        const { intl, isActive, currentYear, compareYear, pointOfReferenceYear } = this.props;

        const yearCloseButtonClasses = classNames(
            'map-panel__change-over-time__control-cancel',
            {
                hidden: !isActive,
            },
        );

        const yearClasses = classNames('map-panel__change-over-time__control', {
            'map-panel__change-over-time__control--active': isActive,
        });

        const rangeValue = currentYear === pointOfReferenceYear ?
                           compareYear - currentYear :
                           currentYear - compareYear;

        const rangeValueSignedString = rangeValue > 0 ?
                                       `+${rangeValue}` :
                                       rangeValue.toString();

        const yearsString = rangeValue === 1 || rangeValue === -1 ?
                            intl.formatMessage({ id: 'dataBrowser.year' }) :
                            intl.formatMessage({ id: 'dataBrowser.years' });

        return (
            <div
                className={yearClasses}
                tabIndex="0"
                onClick={this.onClick}
                onKeyDown={this.onKeyDown}
            >
                <div className="click-passive">
                    {rangeValueSignedString} {yearsString}
                </div>
                <div
                    className={yearCloseButtonClasses}
                    tabIndex="0"
                    onClick={this.onCancel}
                    onKeyDown={this.onCancelKeyDown}
                >
                    <i className="material-icons font-16">close</i>
                </div>
            </div>
        );
    }
}

QuickYearRangeSelectionButton.propTypes = {
    currentYear: PropTypes.number.isRequired,
    compareYear: PropTypes.number.isRequired,
    pointOfReferenceYear: PropTypes.number.isRequired,
    isActive: PropTypes.bool.isRequired,
    intl: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

QuickYearRangeSelectionButton.defaultProps = {};

export default injectIntl(QuickYearRangeSelectionButton);
