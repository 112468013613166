const filterComparisonTypes = {
    MATCH_RANGE: 0,
    MATCH_VALUE_STR: 1,
    MATCH_VALUE_NUM: 2,
    MATCH_NULL: 3,
    MATCH_DEFAULT_SYMBOL: 4,
    DO_NOT_MATCH_NULL: 5,
    MATCH_ANY_VALUE: 6,
};

export default filterComparisonTypes;
