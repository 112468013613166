import React from 'react';
import PropTypes from 'prop-types';

const LocationAnalysisCreateReportNote = ({ note, additionalInfo }) => (
    <div className="location-analysis__note">
        Note: {note}
        {additionalInfo}
    </div>
);

LocationAnalysisCreateReportNote.propTypes = {
    note: PropTypes.string,
    additionalInfo: PropTypes.node,
};

LocationAnalysisCreateReportNote.defaultProps = {
    note: '',
    additionalInfo: '',
};

export default LocationAnalysisCreateReportNote;
