//!
//      

const StyleLayer = require('../style_layer');
const BubbleBucket = require('../../data/bucket/bubble_bucket');
const {circleIntersectsPoint} = require('../../util/intersection_tests');
const {translateDistance, translate} = require('../query_utils');

                                                
                                                        
                                          

class BubbleStyleLayer extends StyleLayer {
    createBucket(parameters                  ) {
        return new BubbleBucket(parameters);
    }

    queryRadius()         {
        return 2048 + translateDistance(this.paint['bubble-translate']);
    }

    queryIntersectsFeature(queryGeometry                     ,
                           feature                   ,
                           geometry                     ,
                           zoom        ,
                           bearing        ,
                           pixelsToTileUnits        ,
                           tile      )          {
        const translatedPolygon = translate(queryGeometry,
            this.getPaintValue('bubble-translate', { zoom }, feature),
            this.getPaintValue('bubble-translate-anchor', { zoom }, feature),
            bearing, pixelsToTileUnits);

        const bubbleRadiusMultiplier = this.paint['bubble-radius-multiplier']; //!
        const bucket = tile.metadata.buckets[this.id];
        const evaluatedRadius = bucket ? tile.metadata.buckets[this.id].features[feature.id].calculatedRadius : 0; //!
        if (evaluatedRadius !== undefined) {
            const clippedRadius = this.paint['bubble-max-radius'] === undefined ? bubbleRadiusMultiplier * evaluatedRadius : Math.min(bubbleRadiusMultiplier * evaluatedRadius, this.paint['bubble-max-radius']); //!
            const bubbleRadius = pixelsToTileUnits * clippedRadius; //!
            return !isNaN(bubbleRadius) && circleIntersectsPoint(translatedPolygon, geometry, bubbleRadius); //!
        }
        return false;
    }
}

module.exports = BubbleStyleLayer;
