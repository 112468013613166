module.exports={
  "marker": {
    "label": "Marker",
    "helperText": "Place marker",
    "helperDescription": "Click anywhere on the map to add a marker."
  },
  "polyline": {
    "label": "Line",
    "helperText": "Draw line",
    "helperDescription": "Click to add a point to line. Double click to end drawing."
  },
  "freehand": {
    "label": "Freehand",
    "helperText": "Draw freehand line",
    "helperDescription": "Mouse-down and draw on the map. When done, release mouse button."
  },
  "flowarrow": {
    "label": "Arrow",
    "helperText": "Draw arrow",
    "helperDescription": "Mouse-down and drag to draw an arrow. When done, release mouse button."
  },
  "polygon": {
    "label": "Polygon",
    "helperText": "Draw polygon",
    "helperDescription": "Click to add a point to line. Double click to end drawing."
  },
  "shape": {
    "label": "Shape",
    "helperText": "Draw shape",
    "helperDescription": "Mouse-down and draw on the map. When done, release mouse button."
  },
  "label": {
    "label": "Label",
    "helperText": "Place label",
    "helperDescription": "Click anywhere on the map to add a label."
  },
  "hotspot": {
    "label": "Hotspot",
    "helperText": "Draw hotspot",
    "helperDescription": "Mouse-down and draw a rectangle on the map. Release when done."
  },
  "image": {
    "label": "Image",
    "helperText": "Place image",
    "helperDescription": "Click anywhere on the map to add an image."
  }
}
