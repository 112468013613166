import Api from '../apis/Api';
import GLU from '../glu2.js/src/index';
import ShareXMLParser from '../helpers/ShareXMLParser';
import AppConfig from '../appConfig';
import uuid from 'node-uuid';

function dingoProjectTypeFromOldProjectType(oldProjectType) {
    switch (oldProjectType) {
    case 'CAIMAN_MAP':
        return AppConfig.constants.projectTypes.map;
    default:
        if (AppConfig.projectTypes.includes(oldProjectType)) {
            return oldProjectType;
        }

        throw new Error('Unsupported project type.');
    }
}

class ShareDataSource extends GLU.DataSource {
    static get name() {
        return 'ShareDataSource';
    }

    static getInstance() {
        return new ShareDataSource();
    }

    constructor() {
        super(() => false);
    }

    getProjectThumbnail(url) {
        const cacheParams = {
            url,
        };
        const logic = (resolve, reject) => {
            const httpRequest = new XMLHttpRequest();
            httpRequest.onreadystatechange = () => {
                if (httpRequest.readyState === XMLHttpRequest.DONE) {
                    if (httpRequest.status === 200) {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            const thumbnail = reader.result;
                            this._projectThumbnail = thumbnail.substr(thumbnail.indexOf(',') + 1);
                            resolve([this._projectThumbnail]);
                        };
                        reader.readAsDataURL(httpRequest.response);
                    } else {
                        reject('There was a problem fetching the project thumbnail image!');
                    }
                }
            };

            httpRequest.open('GET', url);
            httpRequest.responseType = 'blob';
            httpRequest.send();
        };

        return new Promise((resolve, reject) => {
            this.cacheRequest(cacheParams, logic)
                .then(response => resolve(response[0]), reject);
        });
    }

    shareSnapshot(request) {
        const payload = {
            projectType: dingoProjectTypeFromOldProjectType(request.currentProject.projectType),
            ClientUserId: -1,
            title: request.currentProject.title,
            description: request.currentProject.description !== undefined && request.currentProject.description !== null ? request.currentProject.description : 'Map on Social Explorer',
            ClientSubUserId: `${request.currentUser.userId}`,
            copyAssetsFromGuid: AppConfig.constants.defaultProjectAssetsGuid,
            assetsGuid: uuid.v4(),
            projectThumb: request.thumbnail,
            projectXml: request.projectXML,
        };
        return new Promise((resolve, reject) => {
            Api.share.shareSnapshot({ payload })
               .then(response => {
                   const responseXMLDocument = response.xhr.responseXML;

                   const error = responseXMLDocument.getElementsByTagName('Error')[0];

                   if (error) {
                       reject(error.textContent);
                       return;
                   }
                   const snapshot = ShareXMLParser._parseSnapshot(responseXMLDocument.getElementsByTagName('ProjectInfo')[0]);
                   resolve({ snapshot });
               }, reject);
        });
    }
}

export default ShareDataSource;
