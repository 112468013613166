import DragonflyDraw from 'dragonfly-draw-v3';
import tokml from 'tokml';
import BaseHandler from './BaseHandler';

import MarkerAssets from '../assets/MarkerAssets';
import Key from '../../enums/Key';
import AnnotationsMarkers from '../../enums/AnnotationsMarkers';
import AnnotationExportTypes from '../../enums/AnnotationExportTypes';
import { markerSvgPath as MarkerSvgPath, annotationMetadata as AnnotationMetadata } from '../../enums/AnnotationMetadata';

import { getLegendItemColor } from '../../helpers/Util';
import AnnotationsHelper from '../../helpers/Annotations';
import { project, unproject } from '../../helpers/Bezier';

import AnnotationLegend from '../../objects/annotations/AnnotationLegend';
import LegendItem from '../../objects/annotations/LegendItem';

class AnnotationsHandler extends BaseHandler {
    constructor(mapViewer) {
        super(mapViewer);
        this._dragonflyMap = mapViewer.dragonflyMap;
        this._annotationsState = mapViewer._annotationsState;
        this._loadedMarkerPngs = {};
        this._data = mapViewer.dragonflyMapData.annotationData;
        this._mapViewer = mapViewer;
        this._mapInstance = mapViewer.mapInstance;

        this._mapInstance.annotations.forEach(a => this.addMarkerToMap(a));

        this.setGluBusEvents({
            CANCEL_DRAWING_REQUEST: this.onCancelDrawingRequest,
            DELETE_ANNOTATION_REQUEST: this.onDeleteAnnotationRequest,
            DELETE_SEARCH_ANNOTATION_REQUEST: this.onDeleteSearchAnnotationRequest,
            DELETE_SELECTED_ANNOTATIONS_REQUEST: this.onDeleteSelectedAnnotationRequest,
            DESELECT_ANNOTATIONS_REQUEST: this.onDeselectAnnotationsRequest,
            ADD_MULTIPLE_ANNOTATIONS_REQUEST: this.onAddMultipleAnnotationsRequest,
            ADD_MULTIPLE_SEARCH_ANNOTATIONS_REQUEST: this.onAddMultipleSearchAnnotationsRequest,
            ADD_SINGLE_ANNOTATION_REQUEST: this.onAddSingleAnnotationRequest,
            ADD_SINGLE_SEARCH_ANNOTATION_REQUEST: this.onAddSingleSearchAnnotationRequest,
            DRAW_POLYLINE_REQUEST: this.onDrawPolylineRequest,
            DRAW_POLYGON_REQUEST: this.onDrawPolygonRequest,
            DRAW_MARKER_REQUEST: this.onDrawMarkerRequest,
            DRAW_LABEL_REQUEST: this.onDrawLabelRequest,
            DRAW_IMAGE_REQUEST: this.onDrawImageRequest,
            DRAW_HOTSPOT_REQUEST: this.onDrawHotspotRequest,
            DRAW_FREEHAND_REQUEST: this.onDrawFreehandRequest,
            DRAW_SHAPE_REQUEST: this.onDrawShapeRequest,
            DRAW_FLOWARROW_REQUEST: this.onDrawFlowArrowRequest,
            EXPORT_ANNOTATIONS_REQUEST: this.onAnnotationsExport,
            ANNOTATION_SELECTED: this.onAnnotationSelected,
            CHANGE_ANNOTATION: this.onChangeAnnotation,
            MAP_LOAD: this.onMapLoad,

            ANNOTATION_LEGEND_UPDATE: this.onAnnotationLegendUpdate,
            FETCH_ANNOTATIONS: this.onAnnotationsRequest,
            CHANGE_LEGEND_ITEM: this.onLegendItemChange,

            ENTER_ANNOTATIONS_EDIT_MODE: this.onAnnotationModeEnter,
            EXIT_ANNOTATIONS_EDIT_MODE: this.onAnnotationModeExit,
        });
    }

    onAnnotationModeEnter() {
        if (this._dragonflyDraw) {
            this._dragonflyDraw.changeMode('simple_select');
            this._annotationsState = 'editor';
            this.addAnotationEvents();
            this.emit('ANNOTATIONS_EDIT_MODE_ENTERED');
        }
    }

    onAnnotationModeExit() {
        this._dragonflyDraw.changeMode('static');
        this._annotationsState = 'static';
    }

    onMapLoad(e) {
        if (e.source.id !== this.mapInstanceId) return;

        this._dragonflyDraw = new DragonflyDraw({
            displayControlsDefault: false,
            styles: [],
        });
        this.addControl(this._dragonflyDraw, 'draw');
        if (this._annotationsState !== 'editor') this._dragonflyDraw.changeMode('static');
        this._annotationsHelper = new AnnotationsHelper(this._dragonflyDraw, this._mapViewer._dragonflyMap, this._annotationsState, this._bus);
        this.drawAnnotations();

        this.emit('MAP_DRAGONFLY_DRAW_CREATED');
    }

    onAnnotationsRequest({ mapInstanceId }) {
        if (this._mapInstance && this._mapInstance.id === mapInstanceId) {
            this.emit('ANNOTATIONS_FETCHED', {
                annotations: this._mapInstance.annotations,
                annotationLegend: this._mapInstance.annotationLegend,
            });
        }
    }

    /* Annotation Legend operations */
    /* **************************** */
    /* **************************** */

    createAnnotationLegend() {
        return new AnnotationLegend({
            title: 'Legend',
            visible: false,
            legendItems: [],
        });
    }

    onAnnotationLegendUpdate = params => {
        Object.keys(params.propsToUpdate).forEach(key => {
            this._mapInstance.annotationLegend[key] = params.propsToUpdate[key];
        });
        this.emit('ANNOTATION_LEGEND_UPDATED', { annotationLegend: this._mapInstance.annotationLegend });
    }

    onLegendItemChange = params => {
        const legendItem = params.legendItem;
        Object.keys(params.propsToUpdate).forEach(key => {
            legendItem[key] = params.propsToUpdate[key];
        });
        this.emit('ANNOTATION_LEGEND_UPDATED', { annotationLegend: this._mapInstance.annotationLegend });
    }

    recreateLegendItems() {
        this.createAnnotationLegend();
        if (!this._mapInstance.annotationLegend) {
            this._mapInstance.annotationLegend = this.createAnnotationLegend();
        }
        // new legend items array
        const newLegendItems = [];
        // existing legend items array (which we need for fetching the titles)
        const existingLegendItems = this._mapInstance.annotationLegend.legendItems;

        // we will try to create a legend item for each annotation
        this._mapInstance.annotations.forEach((annotation, index) => {
            if (!annotation.includedInLegend) return;
            const color = getLegendItemColor(annotation);
            const icon = annotation.type !== 'Marker' ? AnnotationMetadata[annotation.type.toLowerCase()].svgPath : MarkerSvgPath[annotation.markerPathId];

            // a legend item will be created if another item with the same icon + color combination does not exist
            const legendItem = newLegendItems.find(item => item.color.toLowerCase() === color.toLowerCase() && item.icon === icon);

            if (!legendItem) {
                // let us check if the item exists in the initial legend items
                // if so, we will just get the title from it
                const existingLegendItem = existingLegendItems
                    .find(item => item.color.toLowerCase() === color.toLowerCase() && item.icon === icon);
                const included = existingLegendItem ? existingLegendItem.included : true;
                newLegendItems.push(new LegendItem({
                    color,
                    included,
                    title: annotation.title,
                    icon,
                    type: annotation.type,
                    order: index,
                    markerPathId: annotation.type === 'Marker' ? annotation.markerPathId : undefined,
                }));
            }
        });
        this._mapInstance.annotationLegend.legendItems = newLegendItems;
        this.emit('ANNOTATION_LEGEND_UPDATED', { annotationLegend: this._mapInstance.annotationLegend });
    }

    /* **************************** */
    /* **************************** */

    get dragonflyDraw() {
        return this._dragonflyDraw;
    }

    unbind() {
        super.unbind();
        removeEventListener('keydown', this.onKeyDownHandler);
        if (this._annotationsHelper) this._annotationsHelper.destroyWorker();
    }

    onKeyDownHandler = e => {
        if (e.keyCode === Key.ESC &&
            this._dragonflyDraw !== undefined &&
            this._annotationsState === 'editor' &&
            (this._dragonflyDraw.getMode() === 'draw_polygon' ||
            this._dragonflyDraw.getMode() === 'draw_linestring')) {
            this._dragonflyDraw.changeMode('simple_select');
            e.stopPropagation();
        }
    }

    onAddSingleAnnotationRequest(event) {
        this._mapInstance.annotations.push(event.annotation);
        this._annotationsHelper.drawSingleAnnotation(event.annotation);
        this.addMarkerToMap(event.annotation);
        this.recreateLegendItems();
        if (event.displayInLegend) {
            this._mapInstance.annotationLegend.visible = true;
        }
        this._bus.emit('ANNOTATION_CREATED', { annotation: event.annotation, source: this._mapViewer });
    }

    onAddSingleSearchAnnotationRequest(event) {
        if (event.mapInstanceId !== this._mapInstance.id) return;

        this.onAddSingleAnnotationRequest(event);
        this._bus.emit('SEARCH_ANNOTATIONS_CREATED', { annotations: [event.annotation], source: this._mapViewer });
    }

    onAddMultipleAnnotationsRequest(event) {
        if (event.mapInstanceId !== this._mapInstance.id) return;
        const startingIndex = this._mapInstance.annotations.length;
        event.annotations.forEach(a => {
            this._mapInstance.annotations.push(a);
            this.addMarkerToMap(a);
        });

        this._annotationsHelper.drawMultipleAnnotations(this._mapInstance.annotations.slice(startingIndex));
        this.recreateLegendItems();
        if (event.displayInLegend) {
            this._mapInstance.annotationLegend.visible = true;
        }
        this._bus.emit('ANNOTATIONS_CREATED', { annotations: this._mapInstance.annotations.slice(startingIndex), silent: true, source: this._mapViewer });
    }

    onAddMultipleSearchAnnotationsRequest(event) {
        if (event.mapInstanceId !== this._mapInstance.id) return;
        this.onAddMultipleAnnotationsRequest(event);
        const startingIndex = this._mapInstance.annotations.length - event.annotations.length;
        this._bus.emit('SEARCH_ANNOTATIONS_CREATED', { annotations: this._mapInstance.annotations.slice(startingIndex), silent: true, source: this._mapViewer });
    }

    onDrawPolygonRequest() {
        this._dragonflyDraw.changeMode('draw_polygon');
    }

    onDrawFlowArrowRequest() {
        this._dragonflyDraw.changeMode('draw_smooth_line_string', { source_for: 'flowarrow' });
    }

    onDrawFreehandRequest() {
        this._dragonflyDraw.changeMode('draw_smooth_line_string', { source_for: 'freehand' });
    }

    onDrawShapeRequest() {
        this._dragonflyDraw.changeMode('draw_smooth_line_string', { source_for: 'shape' });
    }

    onDrawMarkerRequest() {
        this._dragonflyDraw.changeMode('draw_point', { source_for: 'marker' });
    }

    onDrawLabelRequest() {
        this._dragonflyDraw.changeMode('draw_point', { source_for: 'label' });
    }

    onDrawImageRequest() {
        this._dragonflyDraw.changeMode('draw_point', { source_for: 'image' });
    }

    onDrawHotspotRequest() {
        this._dragonflyDraw.changeMode('draw_regular_polygon', { source_for: 'hotspot' });
    }

    onDrawPolylineRequest() {
        this._dragonflyDraw.changeMode('draw_line_string');
    }

    onCancelDrawingRequest() {
        this._dragonflyDraw.trash();
    }

    onDeleteAnnotationRequest(id) {
        const idsToDelete = Array.isArray(id) ? id : [id];
        const deletedIds = this._mapInstance.annotations.filter(a => idsToDelete.indexOf(a.id) > -1).map(a => a.searchId);
        this._dragonflyDraw.delete(idsToDelete);
        this.recreateLegendItems();

        this.emit('ANNOTATIONS_DELETE_REQUEST_SUCCESS', { deletedIds, source: this._mapViewer });
    }

    onDeleteSearchAnnotationRequest({ mapInstanceId, id }) {
        if (mapInstanceId !== this.mapInstanceId) return;

        const filteredAnnotations = Array.isArray(id) ? this._mapInstance.annotations.filter(a => id.some(i => i === a.searchId)) : this._mapInstance.annotations.filter(a => a.searchId === id);
        const ids = filteredAnnotations.map(a => a.id);
        this.onDeleteAnnotationRequest(ids);
    }

    onDeleteSelectedAnnotationRequest() {
        this._dragonflyDraw.trash();
    }

    // When user selects annotation from list
    onAnnotationSelected(e) {
        const selectedFeatures = e.selectedAnnotationIds;
        this._dragonflyDraw.changeMode('simple_select', { featureIds: selectedFeatures });
    }

    onDeselectAnnotationsRequest() {
        this._dragonflyDraw.changeMode('simple_select');
    }

    onSelectionChanged = e => {
        if (e.features.length === 1) {
            const annotationId = e.features[0].properties.parent_id || e.features[0].id;
            if (!this._mapInstance.annotations.find(a => annotationId === a.id)) return;
            if (e.features[0].geometry.type !== 'Point') this._dragonflyDraw.changeMode('direct_select', { featureId: annotationId });
            this._bus.emit('ANNOTATION_ON_MAP_SELECTED', { id: annotationId });
        } else if (e.features.length === 0) {
            this._bus.emit('ANNOTATIONS_DESELECTED');
        }
    }

    onFeatureInteraction = e => {
        this._dragonflyMap.fitBounds(JSON.parse(e.feature.properties.bounds));
    }

    onModeChanged = e => {
        if (e.mode === 'simple_select' && e.mode === 'direct_select') this._bus.emit('DRAWING_MODE_STOPPED');
    }

    onAnnotationDeleted = e => {
        e.features.forEach(feature => {
            const annotation = this._mapInstance.annotations.find(a => a.id === feature.id);
            if (!annotation) return;
            const index = this._mapInstance.annotations.indexOf(annotation);
            this._mapInstance.annotations.splice(index, 1);
            // Recreate legend items
            this.recreateLegendItems();
            this._bus.emit('ANNOTATIONS_DELETED');
        });
    }

    onChangeAnnotation(e) {
        const annotation = this._mapInstance.annotations.find(a => a.id === e.id);
        const opts = e.opts || {};
        const propsToUpdate = e.propsToUpdate || {};

        Object.keys(propsToUpdate).forEach(p => {
            annotation[p] = propsToUpdate[p];
        });

        this.addMarkerToMap(annotation);

        propsToUpdate.id = annotation.id;
        propsToUpdate.type = annotation.type;
        propsToUpdate.annotation = annotation;
        propsToUpdate.helpers = e.helpers;

        this._annotationsHelper.updateAnnotationStyle(propsToUpdate, opts);

        // Recreate annotation legend items
        this.recreateLegendItems();
        this._bus.emit('ANNOTATION_CHANGED', annotation);
    }

    onAnnotationsExport(payload) {
        if (this._dragonflyDraw) {
            const annotationsGeoJSON = this._annotationsHelper.getGeoJSONDefinition();
            const { type } = payload;

            let content;
            if (type === AnnotationExportTypes.KML) {
                const annotationsKML = tokml(annotationsGeoJSON, { simplestyle: true });
                content = new Blob([annotationsKML], { type: 'text/plain' });
            } else {
                content = new Blob([JSON.stringify(annotationsGeoJSON)], { type: 'text/plain' });
            }

            const url = URL.createObjectURL(content);

            const downloadAnchorElement = document.createElement('a');
            downloadAnchorElement.href = url;
            downloadAnchorElement.download = `annotations.${type}`;
            downloadAnchorElement.click();
            window.URL.revokeObjectURL(url);
        }
    }

    updateFlowArrow(annotation, delta) {
        annotation.curves.forEach(c => {
            c.anchorPoint1[0] += delta.lng;
            c.controlPoint1[0] += delta.lng;
            c.anchorPoint2[0] += delta.lng;
            c.controlPoint2[0] += delta.lng;
            c.anchorPoint1[1] += delta.lat;
            c.controlPoint1[1] += delta.lat;
            c.anchorPoint2[1] += delta.lat;
            c.controlPoint2[1] += delta.lat;
        });
    }

    updateImage(annotation, feature) {
        annotation.coordinates = [];

        const topLeft = feature.geometry.coordinates[0][0];
        const bottomRight = feature.geometry.coordinates[0][2];

        // Save coordinates without rotation offset
        if (annotation.rotationAngle !== undefined && annotation.rotationAngle !== 0) {
            const worldSize = 512 * (2 ** Math.floor(this._dragonflyMap.getZoom()));
            const s = Math.sin((-annotation.rotationAngle / 180) * Math.PI);
            const c = Math.cos((-annotation.rotationAngle / 180) * Math.PI);
            const projectedTopLeft = project({
                lng: topLeft[0],
                lat: topLeft[1],
            }, worldSize);
            const projectedBottomRight = project({
                lng: bottomRight[0],
                lat: bottomRight[1],
            }, worldSize);

            const center = {
                x: (projectedTopLeft.x + projectedBottomRight.x) / 2,
                y: (projectedTopLeft.y + projectedBottomRight.y) / 2,
            };

            projectedTopLeft.x -= center.x;
            projectedTopLeft.y -= center.y;
            projectedBottomRight.x -= center.x;
            projectedBottomRight.y -= center.y;

            annotation.topLeftPoint = unproject({
                x: ((projectedTopLeft.x * c) - (projectedTopLeft.y * s)) + center.x,
                y: ((projectedTopLeft.x * s) + (projectedTopLeft.y * c)) + center.y,
            }, worldSize);

            annotation.bottomRightPoint = unproject({
                x: ((projectedBottomRight.x * c) - (projectedBottomRight.y * s)) + center.x,
                y: ((projectedBottomRight.x * s) + (projectedBottomRight.y * c)) + center.y,
            }, worldSize);
        } else {
            annotation.topLeftPoint = topLeft;
            annotation.bottomRightPoint = bottomRight;
        }
    }

    updateHotspot(annotation, feature) {
        annotation.topLeftPoint = feature.geometry.coordinates[0][0];
        annotation.bottomRightPoint = feature.geometry.coordinates[0][4];
    }

    onAnnotationsUpdated = e => {
        switch (e.action) {
        case 'move':
        case 'change_coordinates':
            e.features.forEach(feature => {
                if (feature.properties.compound) return;
                const annotation = this._mapInstance.annotations.find(a => a.id === feature.id);
                switch (feature.geometry.type) {
                case 'Polygon':
                    if (annotation.type === 'FlowArrow' && e.action === 'move') {
                        this.updateFlowArrow(annotation, e.delta);
                    } else if (annotation.type === 'Image') {
                        this.updateImage(annotation, feature);
                    } else if (annotation.type === 'Hotspot') {
                        this.updateHotspot(annotation, feature);
                    } else {
                        const coordinates = feature.geometry.coordinates[0].slice(0);
                        coordinates.splice(0, 1);
                        annotation.coordinates = coordinates.reverse();
                    }
                    break;
                case 'LineString':
                    annotation.coordinates = feature.geometry.coordinates.slice(0);
                    break;
                case 'Point':
                    annotation.coordinates = [feature.geometry.coordinates.slice(0)];
                    break;
                }
            });
            break;
        }
    }

    onAnnotationCreated = e => {
        const feature = e.features[0];

        const isFeaturePointMarker = feature.geometry.type === 'Point' && feature.properties.source_for === 'marker';
        // Handle creation of annotations for initial annotation mode settings
        // Avoid creating point marker annotation if target is existing marker annotation
        if (feature && isFeaturePointMarker) {
            const coordinates = this._dragonflyMap.project(feature.geometry.coordinates);
            const existingFeatureId = this._dragonflyDraw.getFeatureIdsAt(coordinates)[0];
            const existingFeature = this._dragonflyDraw.get(existingFeatureId);

            const isMarkerFeature = existingFeature &&
                                    existingFeature.geometry.type === 'Point' &&
                                    existingFeature.properties.source_for === 'marker';

            if (existingFeature && isMarkerFeature) {
                this._bus.emit('ANNOTATION_ON_MAP_SELECTED', { id: existingFeature.id });
                this._bus.emit('ANNOTATION_DRAWING_FINISHED');
                return;
            }
        }

        const annotation = this._data.build({
            feature,
            zoom: this._dragonflyMap.getZoom(),
            dragonflyMap: this._dragonflyMap,
        });
        annotation.includedInLegend = true;
        this.addMarkerToMap(annotation);
        this._mapInstance.annotations.push(annotation);

        this._bus.once(`ANNOTATION_DRAWING_FINISHED_${annotation.id}`, () => {
            // Recreate legend items
            this.recreateLegendItems();
            this._bus.emit('ANNOTATION_CREATED', { annotation, source: this._mapViewer });
            // Draw behaves in one way if we handle points, and another if we handle geometries
            // So if we want to get the notification that the newly created marker (or label) is created
            // and selected, we have to pass so that changing modes is not silent.
            const silent = annotation.type !== 'Marker' && annotation.type !== 'Label';
            this._dragonflyDraw.changeMode('simple_select', { featureIds: [annotation.id], silent });
        });
        this._dragonflyDraw.delete(annotation.id, true);
        this._annotationsHelper.drawSingleAnnotation(annotation, { selected: true });
    }

    addAnotationEvents() {
        this._dragonflyDraw.changeMode('simple_select');
        this._dragonflyMap.on('draw.update', this.onAnnotationsUpdated);
        this._dragonflyMap.on('draw.modechange', this.onModeChanged);
        this._dragonflyMap.on('draw.create', this.onAnnotationCreated);
        this._dragonflyMap.on('draw.selectionchange', this.onSelectionChanged);
        addEventListener('keydown', this.onKeyDownHandler, true);
    }

    drawAnnotations() {
        this._dragonflyDraw.setupCommonStyles();
        this._dragonflyMap.on('draw.delete', this.onAnnotationDeleted);
        if (this._annotationsState === 'editor') {
            this.addAnotationEvents();
        }
        this._dragonflyMap.on('draw.featureinteraction', this.onFeatureInteraction);
        if (this._mapInstance.annotations.length > 0) {
            this._bus.once('MULTIPLE_ANNOTATIONS_DRAWING_FINISHED', () => {
                this._bus.emit('ANNOTATIONS_READY');
            });
            this._annotationsHelper.drawMultipleAnnotations(this._mapInstance.annotations);
        } else {
            this._bus.emit('ANNOTATIONS_READY');
        }
    }

    addMarkerToMap(annotation) {
        if (annotation.type !== 'Marker') return false;
        const marker = AnnotationsMarkers.find(am => am.id === annotation.markerPathId);
        const markerId = `${marker.fileName}_${annotation.fillColor}`;
        const markerPng = MarkerAssets.getMarkerPng(markerId, { markerPathId: marker.fileName, markerColor: annotation.fillColor }, {});
        if (this._loadedMarkerPngs[markerId] === undefined) {
            this._loadedMarkerPngs[markerId] = true;
            this._dragonflyMap.addImage(markerId, markerPng);
        }
        return true;
    }

}

export default AnnotationsHandler;
