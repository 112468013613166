import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../Tooltip';

import ShadedColorPaletteHorizontal from './ShadedColorPaletteHorizontal';
import PagedBrowser from '../../PagedBrowser';

const MultiShadedAreaLegendSimplified = props => {
    const { mapInstance, mapInstanceId, renderer, variables } = props;
    const { fieldList, rules, nullDataRuleIndex, insufficientDataRuleIndex } = renderer;

    let activePageIndex = 0;
    const legendHighlighted = rules.some((variableRules, variableIndex) => {
        activePageIndex = variableIndex;
        return variableRules.some(rule => rule.highlighted);
    });

    const currentPageIndex = legendHighlighted ? activePageIndex : undefined;

    const pages = rules
        .filter((rulesArray, idx) => variables[idx] !== undefined)
        .map((rulesArray, idx) => {
            const variable = variables[idx];
            const title = mapInstance.dataTheme.title || (variable ? variable.title : '');
            return (
                <div key={`${variable.guid}-palette`} className="horizontal-shaded-area-legend flex-it column grow">
                    <Tooltip
                        className="legend-variable-title-overlay"
                        placement="top"
                        mouseEnterDelay={0.5}
                        mouseLeaveDelay={0}
                        overlay={title}
                    >
                        <span className="legend-variable-title">
                            {title}
                        </span>
                    </Tooltip>
                    <ShadedColorPaletteHorizontal
                        mapInstanceId={mapInstanceId}
                        fields={fieldList.fields}
                        nullDataRuleIndex={nullDataRuleIndex[idx]}
                        insufficientDataRuleIndex={insufficientDataRuleIndex[idx]}
                        rules={rulesArray}
                        variableIdx={idx}
                        showLabels
                        legendHighlighted={legendHighlighted}
                    />
                </div>
            );
        });
    return (<PagedBrowser currentPage={currentPageIndex} compact>
        {pages}
    </PagedBrowser>);
};

MultiShadedAreaLegendSimplified.propTypes = {
    mapInstance: PropTypes.object.isRequired,
    renderer: PropTypes.object.isRequired,
    mapInstanceId: PropTypes.string.isRequired,
    variables: PropTypes.array.isRequired,
};

MultiShadedAreaLegendSimplified.defaultProps = {};

export default MultiShadedAreaLegendSimplified;
