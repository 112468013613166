import dragonfly from 'dragonfly-v3';
import uuid from 'node-uuid';

/**
 * Function that parses the result of the search.
 * The original result is in either of these formats:
 * @param result
 * * * * * * * * * * * * * * *
 * * * * * POINT * * * * * * *
 * boundingBox: "POINT (-76.60470588235289 39.300588235294136)"
 * id: "usps_15843"
 * point: "POINT (-76.60470588235289 39.300588235294136)"
 * type: "usps"
 * value: "Baltimore, MD"
 * * * * * * * * * * * * * * *
 * * * * * POLYGON * * * * * *
 * boundingBox: "POLYGON ((-76.711519 39.197207, -76.711519 39.372206, -76.52945299999999 39.372206, -76.52945299999999 39.197207, -76.711519 39.197207))"
 * id: "place_2404000"
 * point: "POINT (-76.6104761 39.3000324)"
 * type: "place"
 * value: "Baltimore, MD"
 * * * * * * * * * * * * * * *
 * * * * * LINESTRING * * * * *
 * boundingBox: "LINESTRING (-96.60969 39.19640299999999, -96.60969 39.19789399999999)"
 * id: "road_2164667_usps_44118"
 * point: "POINT (-96.60969 39.19640299999999)"
 * type: "road"
 * value: "Baltimore Ter, Manhattan, KS"
 * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * *
 *
 *
 * @returns {import('../../..').SearchResult}
 * The function parses said formats into new formats which contain parsed GeoFeatures:
 * * * * * * * * * * * * * * * *
 * * * * * POINT * * * * * * * *
 * boundingBox: undefined
 * points: undefined
 * point: LngLat(-76.60470588235289, 39.300588235294136)
 * id: "usps_15843"
 * type: "usps"
 * value: "Baltimore, MD"
 * * * * * * * * * * * * * * *
 * * * * * POLYGON * * * * * *
 * boundingBox: LngLatBounds(LngLat(-76.52945299999999 39.372206), LngLat(-76.711519, 39.197207))
 * points: Array[4] = [LngLat(-76.711519 39.372206), LngLat(-76.52945299999999 39.372206), LngLat(-76.52945299999999 39.197207), LngLat(-76.711519 39.197207)]
 * point: undefined
 * id: "place_2404000"
 * type: "place"
 * value: "Baltimore, MD"
 * * * * * * * * * * * * * * *
 * LatLongBounds Format: LngLatBounds(LngLat(SouthWest pair), LngLat(NorthEast pair))
 * * * * * * * * * * * * * * *
 * * * * * LINESTRING * * * * *
 * boundingBox: undefined
 * points: Array[2] = [LngLat(-96.60969 39.19640299999999), LngLat(-96.60969 39.19789399999999)]
 * point: LngLat(-96.60969 39.19640299999999)
 * id: "road_2164667_usps_44118"
 * type: "road"
 * value: "Baltimore Ter, Manhattan, KS"
 * * * * * * * * * * * * * * *
 */
export function parseSocexResult(result) {
    const WKT_POINT_REGEX = /(-?\d+\.*\d+ -?\d+\.*\d+)/g;

    const parsedResult = {
        id: result.id,
        type: result.type,
        value: result.value,
    };

    const boundingBox = result.bounding;
    result.boundingBox = boundingBox;

    parsedResult.featureType = 'POLYGON';
    let parsedBoundingBox = WKT_POINT_REGEX.exec(result.boundingBox);
    const points = [];
    while (parsedBoundingBox) {
        parsedBoundingBox = WKT_POINT_REGEX.exec(result.boundingBox);
        if (parsedBoundingBox && parsedBoundingBox.length > 0) {
            const unparsedPoint = parsedBoundingBox[0].split(' ');
            points.push(
                new dragonfly.LngLat(
                    parseFloat(unparsedPoint[0]),
                    parseFloat(unparsedPoint[1]),
                ),
            );
        }
    }

    // Sometimes the bounding box is a polygon with all vertices in one point
    if (
        points.length > 3 &&
        (points[3].lng !== points[1].lng || points[3].lat !== points[1].lat)
    ) {
        parsedResult.boundingBox = new dragonfly.LngLatBounds(
            points[3],
            points[1],
        );
    } else {
        parsedResult.featureType = 'POINT';
        parsedResult.boundingBox = undefined;
    }

    const centerPoint = WKT_POINT_REGEX.exec(result.point);

    if (centerPoint) {
        parsedResult.point = new dragonfly.LngLat(
            parseFloat(centerPoint[0].split(' ')[0]),
            parseFloat(centerPoint[0].split(' ')[1]),
        );
    }

    parsedResult.points = points;

    parsedResult.geometry = result.geometry;

    if (parsedResult.type === 'address') {
        parsedResult.featureType = 'POINT';
        parsedResult.points = undefined;
        parsedResult.geometry = result.point;
    }

    return parsedResult;
}

export function parseHereGeocodeResult(result) {
    const { resultType, address } = result;
    const id = uuid.v4();
    const { label } = address;
    const originalLabelComponents = label.split(', ');
    const value =
        originalLabelComponents.length === 1
            ? label
            : originalLabelComponents
                  .slice(0, originalLabelComponents.length - 1)
                  .join(', ');
    if (result.mapView) {
        return {
            id,
            type: resultType || 'place',
            value,
            featureType: 'POINT',
            boundingBox: {
                _sw: {
                    lng: result.mapView.west,
                    lat: result.mapView.south,
                },
                _ne: {
                    lng: result.mapView.east,
                    lat: result.mapView.north,
                },
            },
            point: result.position,
            points: [
                {
                    lng: result.mapView.east,
                    lat: result.mapView.south,
                },
                {
                    lng: result.mapView.east,
                    lat: result.mapView.north,
                },
                {
                    lng: result.mapView.west,
                    lat: result.mapView.north,
                },
                {
                    lng: result.mapView.west,
                    lat: result.mapView.south,
                },
            ],
            geometry: `POINT (${result.position.lng} ${result.position.lat})`,
        };
    }
    return {
        id,
        type: resultType || 'place',
        value,
        featureType: 'POINT',
        point: result.position,
        geometry: `POINT (${result.position.lng} ${result.position.lat})`,
    };
}
