import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Tooltip from '../Tooltip';

const IndicatorItem = ({ icon, title, classes, onClickEdit, onClickClear }) => {
    const indicatorItemClasses = classNames('edit-filter-mask-dialog__section', classes);

    return (<div className={indicatorItemClasses}>
        <div className="edit-filter-mask-dialog__icon">
            {icon}
        </div>
        <div className="edit-filter-mask-dialog__text">{title}</div>
        <div className="edit-filter-mask-dialog__actions">
            <Tooltip overlay="Edit" >
                <button className="material-icons btn-icon font-18" onClick={onClickEdit}>edit</button>
            </Tooltip>
            <Tooltip overlay="Clear">
                <button className="material-icons btn-icon" onClick={onClickClear}>close</button>
            </Tooltip>
        </div>
    </div>);
};

IndicatorItem.propTypes = {
    icon: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    classes: PropTypes.string.isRequired,
    onClickEdit: PropTypes.func.isRequired,
    onClickClear: PropTypes.func.isRequired,
};

export default IndicatorItem;
