/* eslint class-methods-use-this: 0 */

class Marker {
    constructor(values) {
        if (values) {
            Object.keys(values).forEach(k => {
                if (this.constructor.prototype.hasOwnProperty(k)) {
                    this[k] = values[k];
                }
            });
        }
    }

    get type() {
        return 'Marker';
    }

    set id(id) {
        this._id = id;
    }

    get id() {
        return this._id;
    }

    set size(size) {
        this._size = size;
    }

    get size() {
        return this._size;
    }

    set markerPathId(markerPathId) {
        this._markerPathId = markerPathId;
    }

    get markerPathId() {
        return this._markerPathId;
    }

    set labelPosition(labelPosition) {
        this._labelPosition = labelPosition;
    }

    get labelPosition() {
        return this._labelPosition;
    }

    set labelVisible(labelVisible) {
        this._labelVisible = labelVisible;
    }

    get labelVisible() {
        return this._labelVisible;
    }

    set fillColor(fillColor) {
        this._fillColor = fillColor;
    }

    get fillColor() {
        return this._fillColor;
    }

    get textSize() {
        return this._textSize;
    }

    set textSize(textSize) {
        this._textSize = textSize;
    }

    get textColor() {
        return this._textColor;
    }

    set textColor(textColor) {
        this._textColor = textColor;
    }

    get opacity() {
        return this._opacity;
    }

    set opacity(opacity) {
        this._opacity = opacity;
    }

    get title() {
        return this._title;
    }

    set title(title) {
        this._title = title;
    }

    get createdAtZoomLevel() {
        return this._createdAtZoomLevel;
    }

    set createdAtZoomLevel(createdAtZoomLevel) {
        this._createdAtZoomLevel = createdAtZoomLevel;
    }

    get minZoom() {
        return this._minZoom;
    }

    set minZoom(minZoom) {
        this._minZoom = minZoom;
    }

    get maxZoom() {
        return this._maxZoom;
    }

    set maxZoom(maxZoom) {
        this._maxZoom = maxZoom;
    }

    get description() {
        return this._description;
    }

    set description(description) {
        this._description = description;
    }

    get coordinates() {
        return this._coordinates;
    }

    set coordinates(coordinates) {
        this._coordinates = coordinates;
    }

    get legendOrder() {
        return this._legendOrder;
    }

    set legendOrder(legendOrder) {
        this._legendOrder = legendOrder;
    }

    get includedInLegend() {
        return this._includedInLegend;
    }

    set includedInLegend(includedInLegend) {
        this._includedInLegend = includedInLegend;
    }

    get searchId() {
        return this._searchId;
    }

    set searchId(searchId) {
        this._searchId = searchId;
    }

    equals(that) {
        return (this.id === that.id &&
        this.type === that.type &&
        this.opacity === that.opacity &&
        this.textSize === that.textSize &&
        this.size === that.size &&
        this.markerPathId === that.markerPathId &&
        this.textColor === that.textColor &&
        this.fillColor === that.fillColor &&
        this.labelPosition === that.labelPosition &&
        this.labelVisible === that.labelVisible &&
        this.title === that.title &&
        this.createdAtZoomLevel === that.createdAtZoomLevel &&
        this.minZoom === that.minZoom &&
        this.maxZoom === that.maxZoom &&
        this.includedInLegend === that.includedInLegend &&
        this.legendOrder === that.legendOrder &&
        this.description === that.description &&
        this.searchId === that.searchId &&
        this.coordinates === that.coordinates);
    }

    clone() {
        return new Marker({
            id: `${this.id}`,
            textSize: this.textSize,
            textColor: this.textColor,
            fillColor: this.fillColor,
            labelPosition: this.labelPosition,
            labelVisible: this.labelVisible,
            size: this.size,
            markerPathId: this.markerPathId,
            opacity: this.opacity,
            title: this.title,
            createdAtZoomLevel: this.createdAtZoomLevel,
            minZoom: this.minZoom,
            maxZoom: this.maxZoom,
            includedInLegend: this.includedInLegend,
            description: this.description,
            coordinates: this.coordinates,
            legendOrder: this.legendOrder,
            searchId: this.searchId,
        });
    }

    toJSON() {
        return {
            id: `${this.id}`,
            type: this.type,
            textSize: this.textSize,
            textColor: this.textColor,
            fillColor: this.fillColor,
            labelPosition: this.labelPosition,
            labelVisible: this.labelVisible,
            size: this.size,
            markerPathId: this.markerPathId,
            opacity: this.opacity,
            title: this.title,
            createdAtZoomLevel: this.createdAtZoomLevel,
            minZoom: this.minZoom,
            maxZoom: this.maxZoom,
            includedInLegend: this.includedInLegend,
            description: this.description,
            coordinates: this.coordinates,
            legendOrder: this.legendOrder,
            searchId: this.searchId,
        };
    }
}

export default Marker;
