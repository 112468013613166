class Snapshot {
    constructor(values) {
        if (values) {
            Object.keys(values).forEach(k => {
                if (this.constructor.prototype.hasOwnProperty(k)) {
                    this[k] = values[k];
                }
            });
        }
    }
    get projectId() {
        return this._projectId;
    }

    get title() {
        return this._title;
    }

    get createdDate() {
        return this._createdDate;
    }

    get modifiedDate() {
        return this._modifiedDate;
    }

    get description() {
        return this._description;
    }

    get userId() {
        return this._userId;
    }

    get projectType() {
        return this._projectType;
    }

    get newProject() {
        return this._newProject;
    }

    get viewCode() {
        return this._viewCode;
    }

    get etag() {
        return this._etag;
    }

    get ownedBySe() {
        return this._ownedBySe;
    }

    get projectThumbUrl() {
        return this._projectThumbUrl;
    }

    get isShareSnapshot() {
        return this._isShareSnapshot;
    }

    get assetsGuid() {
        return this._assetsGuid;
    }


    set projectId(projectId) {
        this._projectId = projectId;
    }

    set title(title) {
        this._title = title;
    }

    set createdDate(createdDate) {
        this._createdDate = createdDate;
    }

    set modifiedDate(modifiedDate) {
        this._modifiedDate = modifiedDate;
    }

    set description(description) {
        this._description = description;
    }

    set userId(userId) {
        this._userId = userId;
    }

    set projectType(projectType) {
        this._projectType = projectType;
    }

    set newProject(newProject) {
        this._newProject = newProject;
    }

    set viewCode(viewCode) {
        this._viewCode = viewCode;
    }

    set etag(etag) {
        this._etag = etag;
    }

    set ownedBySe(ownedBySe) {
        this._ownedBySe = ownedBySe;
    }

    set projectThumbUrl(projectThumbUrl) {
        this._projectThumbUrl = projectThumbUrl;
    }

    set isShareSnapshot(isShareSnapshot) {
        this._isShareSnapshot = isShareSnapshot;
    }

    set assetsGuid(assetsGuid) {
        this._assetsGuid = assetsGuid;
    }


}

export default Snapshot;
