import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Popover from '../popover/Popover';
import ColorPickerCompact from './ColorPickerCompact';

class ColorPicker extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            open: false,
        };
    }

    onCloseRequest = () => {
        this.setState({ open: false });
    }

    onTriggerClick = () => {
        this.setState({ open: !this.state.open });
    }

    onChange = color => {
        this.props.onChange(color);
    }

    renderExpanded() {
        const { anchorOrigin, targetOrigin } = this.props;
        return (
            <Popover
                anchorEl={this.trigger}
                anchorClassName="popover-anchor--color-picker"
                open={this.state.open}
                showClose
                triggerToggle
                anchorOrigin={anchorOrigin}
                targetOrigin={targetOrigin}
                marginTop={8}
                onCloseRequest={this.onCloseRequest}
            >
                <div className="color-picker">
                    <p>Color</p>
                    <ColorPickerCompact
                        onChange={this.onChange}
                        color={this.props.color}
                    />
                </div>
            </Popover>
        );
    }

    render() {
        const style = {
            background: this.props.color,
        };
        const classes = classNames('color-picker-button flex-it center justify-center', {
            'color-picker-button--border-only': this.props.withBorder,
        });

        return (<div style={this.props.withBorder ? style : undefined} className={classes}>
            <button
                ref={e => { this.trigger = e; }}
                className="color-picker-button__anchor"
                style={!this.props.withBorder ? style : undefined}
                onClick={this.onTriggerClick}
            />
            {this.trigger && this.renderExpanded()}
        </div>);
    }
}

ColorPicker.propTypes = {
    color: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    anchorOrigin: PropTypes.object,
    targetOrigin: PropTypes.object,
    withBorder: PropTypes.bool,
};

ColorPicker.defaultProps = {
    anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
    targetOrigin: { horizontal: 'right', vertical: 'top' },
    withBorder: false,
};

export default ColorPicker;
