// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import LocationHeaderActions from './LocationHeaderActions';
import LocationSubheader from './LocationSubheader';
import Tooltip from '../Tooltip';
import BusComponent from '../BusComponent';

/**
 *
 * @typedef Props
 * @property {import('react-intl').intlShape} intl
 * @property {import('../../objects/LocationAnalysisItem').default} selectedItem
 * @property {import('../../objects/MapInstance').default} mapInstance
 * @property {boolean} createReportExpanded
 *
 * @typedef State
 * @property {boolean} subheaderVisible
 *
 * @extends {BusComponent<Props, State>}
 */

class LocationAnalysisHeader extends BusComponent {
    onCollapseReport = () => {
        this.emit('UPDATE_CREATE_REPORT_VISIBILITY', {
            visible: false,
            mapInstanceId: this.props.mapInstance.id,
        });
    };

    render() {
        const {
            intl,
            selectedItem,
            mapInstance,
            createReportExpanded,
        } = this.props;

        if (createReportExpanded) {
            return (
                <div className="location-analysis__section-header-expanded">
                    <Tooltip
                        placement="bottom"
                        mouseEnterDelay={0.2}
                        mouseLeaveDelay={0}
                        overlay={intl.formatMessage({ id: 'back' })}
                    >
                        <button
                            className="material-icons btn-flat-icon"
                            onClick={this.onCollapseReport}
                            aria-label={intl.formatMessage({ id: 'back' })}
                        >
                            arrow_back
                        </button>
                    </Tooltip>
                    <div className="flex-it center location-analysis__section-header-title">
                        <FormattedMessage id="dataBrowser.createReport" />
                    </div>
                </div>
            );
        }
        return (
            <div className="location-analysis__section-header">
                <div className="flex-it space-between row">
                    <div className="location-analysis__info">
                        <div className="flex-it">
                            <span className="location-analysis__info-title">
                                {selectedItem.value}
                            </span>
                        </div>
                    </div>
                    <div className="location-analysis__header-actions">
                        <div className="flex-it center space-between">
                            <LocationHeaderActions
                                mapInstanceId={mapInstance.id}
                                locationAnalysisItem={selectedItem}
                                isAnalysisOn
                            />
                        </div>
                    </div>
                </div>
                <LocationSubheader
                    selectedItem={selectedItem}
                    mapInstanceId={mapInstance.id}
                />
            </div>
        );
    }
}

LocationAnalysisHeader.propTypes = {
    intl: PropTypes.object.isRequired,
    selectedItem: PropTypes.object.isRequired,
    mapInstance: PropTypes.object.isRequired,
    createReportExpanded: PropTypes.bool.isRequired,
};

export default injectIntl(LocationAnalysisHeader);
