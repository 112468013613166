// @ts-check
import XLSX from 'xlsx-js-style';
import { isStringURL } from '../helpers/Util';

/**
 * @param {import('../').XlsxSheetDefinition[]} sheets
 * @param {string} fileName
 * @param {boolean} useAoa indicates if data is in the form of a matrix.
 * In that case, use aoa function (array of arrays) to sheet
 */
export default function dataToXlsx(
    sheets,
    fileName,
    useAoa = false,
    skipHeader = true,
    headerItems = null,
) {
    // Create workbook
    const workBook = XLSX.utils.book_new();
    // Create and append sheets
    sheets.forEach(sheet => {
        // TODO: Ternary within ternary might be ugly. Consult and refactor if needed
        // eslint-disable-next-line no-nested-ternary
        const workSheet = useAoa
            ? XLSX.utils.aoa_to_sheet(sheet.data)
            : headerItems != null
              ? XLSX.utils.json_to_sheet(sheet.data, {
                    skipHeader,
                    header: headerItems,
                })
              : XLSX.utils.json_to_sheet(sheet.data, {
                    skipHeader,
                });

        const options = sheet.options;

        if (options && options.merges) {
            workSheet['!merges'] = options.merges;
        }

        if (options && options.cellStyles && options.cellStyles.length > 0) {
            options.cellStyles.forEach(cellStyle => {
                if (workSheet[cellStyle.cell]) {
                    workSheet[cellStyle.cell].s = cellStyle.style;
                }
            });
        }

        if (options && options.cellFormats && options.cellFormats.length > 0) {
            options.cellFormats.forEach(cellFormat => {
                workSheet[cellFormat.cell].z = cellFormat.format;
            });
        }

        if (options && options.rowHeights && options.rowHeights.length > 0) {
            /* create !rows array if it does not exist */
            if (!workSheet['!rows']) workSheet['!rows'] = [];
            options.rowHeights.forEach(row => {
                /* create row metadata object if it does not exist */
                if (!workSheet['!rows'][row.idx]) workSheet['!rows'][row.idx] = { hpx: row.height };
                /* set row height */
                workSheet['!rows'][row.idx].hpx = row.height;
            });
        }

        if (options && options.colWidths && options.colWidths.length > 0) {
            /* create !cols array if it does not exist */
            if (!workSheet['!cols']) workSheet['!cols'] = [];
            options.colWidths.forEach(col => {
                /* create column metadata object if it does not exist */
                if (!workSheet['!cols'][col.idx]) workSheet['!cols'][col.idx] = { wpx: col.width };
                /* set column width */
                workSheet['!cols'][col.idx].wpx = col.width;
            });
        }

        Object.keys(workSheet).forEach(key => {
            const { v: value } = workSheet[key];
            if (isStringURL(value)) {
                workSheet[key].l = { Target: value };
                const link = new URL(value);
                workSheet[key].v = link.host;
            }
        });
        XLSX.utils.book_append_sheet(workBook, workSheet, sheet.name);
    });
    XLSX.writeFile(workBook, `${fileName}.xlsx`);
}
