// @ts-check
import React from 'react';
import PropTypes from 'prop-types';

import LocationHeaderActions from './LocationHeaderActions';
import LocationSubheader from './LocationSubheader';
import Tooltip from '../Tooltip';

/**
 * @property {string} prop0.mapInstanceId
 * @property {import('../../objects/LocationAnalysisItem').default} prop0.selectedItem
 */
const LocationHeader = ({ mapInstanceId, selectedItem }) => (
    <div className="location-info">
        <div className="location-info__header">
            <div className="location-info__header-title-group">
                <i className="material-icons-outlined">place</i>
                <div>
                    <div className="flex-it center">
                        <Tooltip
                            placement="bottom"
                            mouseEnterDelay={0.2}
                            mouseLeaveDelay={0}
                            overlay={selectedItem.value}
                        >
                            <span className="location-info__header-title">
                                {selectedItem.value}
                            </span>
                        </Tooltip>
                    </div>
                    <div className="flex-it start">
                        <div className="location-info__type flex-one flex-fixed">
                            {selectedItem.type}
                        </div>
                    </div>
                </div>
            </div>
            <div className="location-info__header__actions">
                <div className="flex-it center space-between">
                    <LocationHeaderActions
                        mapInstanceId={mapInstanceId}
                        locationAnalysisItem={selectedItem}
                    />
                </div>
            </div>
        </div>
        <LocationSubheader
            selectedItem={selectedItem}
            mapInstanceId={mapInstanceId}
        />
    </div>
);

LocationHeader.propTypes = {
    mapInstanceId: PropTypes.string.isRequired,
    selectedItem: PropTypes.object.isRequired,
};


export default LocationHeader;
