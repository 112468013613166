import React from 'react';
import ColorPicker from '../colorPickerCompact/ColorPicker';
import PropTypes from 'prop-types';

class ColorSingleEditor extends React.Component {
    onApplyDataColor = color => {
        const palette = this.props.colorPalette.clone();
        palette.colors[0] = color;
        this.props.applyColorPalette(palette);
    }
    render() {
        return (
            <div className="color-single-editor">
                <div className="additional-controls flex-it space-between center">
                    <div className="flex-it center">
                        <h6 className="margin-right-10">Data color</h6>
                        <ColorPicker
                            onChange={this.onApplyDataColor}
                            color={this.props.colorPalette.colors[0]}
                        />
                    </div>
                </div>
            </div>);
    }
}

ColorSingleEditor.propTypes = {
    colorPalette: PropTypes.object.isRequired,
    applyColorPalette: PropTypes.func.isRequired,
};
ColorSingleEditor.defaultProps = {
};

export default ColorSingleEditor;
