// @ts-check
import React from 'react';
import { injectIntl } from 'react-intl';

import AppConfig from '../../appConfig';

import BusComponent from '../BusComponent';
import AddNewCustomAnalysisValue from './AddNewCustomAnalysisValue';
import LocationAnalysisValueButton from './LocationAnalysisValueButton';
import ResetCustomValuesButton from './ResetCustomValuesButton';
import LocationAnalysisTypeDropdown from './LocationAnalysisTypeDropdown';
import LocationPanelDistance from './LocationPanelDistance';
import { SELECTION_LIMIT } from './LocationAnalysis';

/**
 *
 * @typedef Props
 * @property {import('../../').LocationAnalysisType | undefined} analysisType
 * @property {Set<number>} selection
 * @property {string} mapInstanceId
 * @property {boolean} defaultMinimized
 * @property {import('react-intl').intlShape} intl
 *
 * @typedef State
 * @property {any[]} customValues
 * @property {boolean} minimized
 *
 * @extends {BusComponent<Props, State>}
 */

class LocationAnalysisValueSelection extends BusComponent {
    constructor(props, context) {
        super(props, context);
        /** @type {State} */
        this.state = Object.assign({
            customValues: [],
            minimized: this.props.defaultMinimized,
        });
    }

    componentDidMount() {
        this.bindGluBusEvents({
            CUSTOM_LOCATION_ANALYSIS_VALUES:
                this.onCustomLocationAnalysisValues,
            SELECT_EXISTING_LOCATION_ANALYSIS_VALUE: this.onSelectionChange,
        });
        this.emit('CUSTOM_LOCATION_ANALYSIS_VALUES_REQUEST');
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    onCustomLocationAnalysisValues({ customLocationAnalysisValues }) {
        this.setState({
            customValues: customLocationAnalysisValues
                .filter(
                    customValue =>
                        customValue.analysisTypeUnit ===
                        this.props.analysisType.UNIT,
                )
                .map(customValue => customValue.value),
        });
    }

    toggleMinimized = () => {
        this.setState(prevState => ({ minimized: !prevState.minimized }));
    };

    /**
     * @param {number} value Radius size (in miles or minutes). Value is one of
     * the analysisType.VALUES_ARRAY
     */
    multiSelectionChange = value => {
        const newSelection = new Set(this.props.selection);
        // At least one has to be selected and no more than the SELECTION_LIMIT
        switch (true) {
        case newSelection.has(value) && newSelection.size > 1:
            newSelection.delete(value);
            return newSelection;
        case !newSelection.has(value) &&
            newSelection.size < SELECTION_LIMIT:
            newSelection.add(value);
            return newSelection;
        default:
            return this.props.selection;
        }
    };

    onSelectionChange = value => {
        const newSelection = this.multiSelectionChange(value);
        // If nothing has changed do nothing
        if (newSelection === this.props.selection) return;
        this.emit('SET_LOCATION_ANALYSIS_SELECTION', {
            mapInstanceId: this.props.mapInstanceId,
            selection: newSelection,
        });
    };

    render() {
        const { analysisType, selection, mapInstanceId } = this.props;

        if (this.state.minimized) {
            return (
                <button
                    onClick={this.toggleMinimized}
                    className="flex-it grow clickable border-bottom-light padded-horizontal-16 padded-vertical-8"
                >
                    <div className="flex-it center space-between grow">
                        <LocationPanelDistance
                            analysisType={analysisType}
                            selection={selection}
                        />
                        <span className="btn-icon btn-icon-small material-icons">
                            expand_more
                        </span>
                    </div>
                </button>
            );
        }

        const values = [
            ...analysisType.VALUES_ARRAY,
            ...this.state.customValues,
        ];
        const valueButtons = values
            .sort((a, b) => a - b)
            .map(value => (
                <LocationAnalysisValueButton
                    key={value}
                    value={value}
                    selection={selection}
                    onSelectionChange={this.onSelectionChange}
                    analysisTypeUnit={analysisType.UNIT}
                />
            ));

        return (
            <div className="location-analysis__section location-analysis__section--border-bottom location-analysis__section--top-padded">
                <div className="flex-it center space-between start">
                    <LocationAnalysisTypeDropdown
                        selectedAnalysisType={analysisType}
                        mapInstanceId={mapInstanceId}
                    />
                    <div
                        className="location-analysis__img"
                        style={{
                            backgroundImage: `url('${AppConfig.constants.assetsBaseURL}/svg/${analysisType.IMG}.svg')`,
                        }}
                    />
                    <button
                        onClick={this.toggleMinimized}
                        className="btn-icon btn-icon-small material-icons"
                    >
                        expand_less
                    </button>
                </div>
                <div className="location-analysis__section-hint flex-it row space-between center">
                    <span>
                        <strong>Select area (up to {SELECTION_LIMIT})</strong>
                    </span>
                    <ResetCustomValuesButton />
                </div>
                <div className="location-analysis__btn-group">
                    {valueButtons}
                    <AddNewCustomAnalysisValue
                        key="add-value-button"
                        values={values}
                        analysisType={analysisType}
                    />
                </div>
            </div>
        );
    }
}

export default injectIntl(LocationAnalysisValueSelection);
