const reportType = {
    CURRENT_VARIABLE_TABLE: {
        id: 'current',
        text: 'Current table selection',
    },
    TOPIC: {
        id: 'topic',
        text: 'Topic',
    },
};

export default reportType;
