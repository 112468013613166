import React from 'react';
import BusComponent from '../BusComponent';

import CutpointsSelector from './selectors/cutpointsSelector/CutpointsSelector';
import { getMetadataObjectsFromVariableSelectionItem } from '../../helpers/Util';
import VariableType from '../../enums/VariableType';

class ShadedVisualizationEditor extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = { cutpointsEnabled: false };
    }

    componentDidMount = () => {
        this.bindGluBusEvents({
            CURRENT_METADATA: this.onCurrentMetadataRetrieved,
        });
        this.emit('CURRENT_METADATA_REQUEST', { source: this });
    };

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    onCurrentMetadataRetrieved = (metadata, target) => {
        if (target === this) {
            const currentMetadataObject = getMetadataObjectsFromVariableSelectionItem(
                this.props.mapInstance.dataTheme.variableSelection.items[0],
                metadata
            );
            this.setState({
                cutpointsEnabled: currentMetadataObject.variable.varType !== VariableType.NONE && false, // Remove cutpoints popup for now
            });
        }
    };

    onShowDataClassificationModalClick = () => {
        this.emit('CLOSE_DROPDOWN');
        this.emit(
            'DATA_CLASSIFICATION_POPUP_REQUEST',
            this.props.mapInstanceId
        );
    };

    render() {
        if (!this.state.cutpointsEnabled) {
            return null;
        }
        return (
            <div className="flex-it column no-shrink">
                <CutpointsSelector
                    onShowDataClassificationModalClick={this.onShowDataClassificationModalClick}
                />
            </div>
        );
    }
}

export default ShadedVisualizationEditor;
