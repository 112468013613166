import React from 'react';
import PropTypes from 'prop-types';
import SimpleDropdown from '../../../dropdown/SimpleDropdown';
import Checkbox from '../../../form/Checkbox';

const LabelOptionsSection = props => {
    let selectedItem = { id: 'none', text: 'None' };
    const columnOptions = props.labelColumns.map(c => ({ id: c, text: c }));
    columnOptions.unshift(selectedItem);

    if (props.labelingColumn) {
        selectedItem = columnOptions.find(c => c.id === props.labelingColumn);
    }

    return (
        <div>
            <div className="point-layer-editor__section">
                <div className="point-layer-editor__column">Set labels</div>
                <div className="point-layer-editor__column">
                    <SimpleDropdown
                        className="flex-one"
                        onItemClick={props.onLabelingColumnChange}
                        items={columnOptions}
                        selectedItem={selectedItem}
                    />
                </div>
            </div>
            <div className="point-layer-editor__section">
                Allow markers to overlap
                <Checkbox onCheck={props.onAllowOverlap} checked={props.allowOverlap} />
            </div>
        </div>
    );
};

LabelOptionsSection.propTypes = {
    labelColumns: PropTypes.array.isRequired,
    labelingColumn: PropTypes.string,
    onLabelingColumnChange: PropTypes.func.isRequired,
    onAllowOverlap: PropTypes.func.isRequired,
    allowOverlap: PropTypes.bool,
};

LabelOptionsSection.defaultProps = {
    allowOverlap: false,
    labelingColumn: undefined,
};

export default LabelOptionsSection;
