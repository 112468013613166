import React from 'react';
import PropTypes from 'prop-types';

import LayerLibraryGroup from './LayerLibraryGroup';

const LayerLibraryCategory = props => {
    const layerLibraryCategoryGroups = props.groups.map(group => {
        const { id, metadata, active } = group;

        return (<LayerLibraryGroup
            key={id}
            id={id}
            metadata={metadata}
            active={active}
            onToggleSelected={props.onToggleLibraryGroupSelection}
        />);
    });

    return (<div key={props.id} className="layer-library-category">
        <p className="layer-library-category__title">{props.title}</p>
        <div className="layer-library-category__groups">
            {layerLibraryCategoryGroups}
        </div>
    </div>);
};

LayerLibraryCategory.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    groups: PropTypes.array.isRequired,
    onToggleLibraryGroupSelection: PropTypes.func.isRequired,
};

export default LayerLibraryCategory;
