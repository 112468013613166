import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import TabbedBrowser from '../tabbedBrowser/TabbedBrowser';
import LocationAnalysisFeaturedReports from './LocationAnalysisFeaturedReports';
import LocationAnalysisReportConstruction from './LocationAnalysisReportConstruction';

class LocationAnalysisConstructionSection extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            browseType: 0,
        };
    }

    onChangeBrowseType = type => {
        this.setState({
            browseType: type,
        });
    };
    render() {
        return (
            <div className="location-analysis__section location-analysis__section--tabbed">
                <div className="location-analysis__section-hint">
                    <strong>{this.props.intl.formatMessage({ id: 'legend.selectData' })}</strong>
                </div>
                <TabbedBrowser
                    tabNames={[
                        { name: this.props.intl.formatMessage({ id: 'featured' }) },
                        { name: this.props.intl.formatMessage({ id: 'dataBrowser.allData' }) },
                    ]}
                    selectedTabIndex={this.state.browseType}
                    tabSwitchedCallback={this.onChangeBrowseType}
                >
                    <LocationAnalysisFeaturedReports
                        key="featured-reports"
                        note={this.props.note}
                        mapInstanceId={this.props.mapInstance.id}
                    />
                    <LocationAnalysisReportConstruction
                        key="premade-reports"
                        mapInstance={this.props.mapInstance}
                        selectedSurvey={this.props.selectedSurvey}
                        note={this.props.note}
                        onUpdateCompareSurveyList={this.props.onUpdateCompareSurveyList}
                    />
                </TabbedBrowser>
            </div>
        );
    }
}

LocationAnalysisConstructionSection.propTypes = {
    mapInstance: PropTypes.object.isRequired,
    selectedSurvey: PropTypes.object.isRequired,
    note: PropTypes.string,
    intl: PropTypes.object.isRequired,
    onUpdateCompareSurveyList: PropTypes.func.isRequired,
};

LocationAnalysisConstructionSection.defaultProps = {
    note: '',
};

export default injectIntl(LocationAnalysisConstructionSection);
