import React from 'react';
import Popover from '../../popover/Popover';
import PropTypes from 'prop-types';

const VERTICAL_OFFSET = -5;
const HORIZONTAL_OFFSET = 100;

const YearSelectionInfoPopup = ({ open, anchorElement, onClosePopup }) => (
    <Popover
        anchorEl={anchorElement}
        className="year-selection__info-popup"
        open={open}
        autoWidth
        marginTop={VERTICAL_OFFSET}
        marginLeft={HORIZONTAL_OFFSET}
        onCloseRequest={onClosePopup}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        targetOrigin={{ horizontal: 'left', vertical: 'top' }}
    >
        <div className="content">
            <h5 className="margin-bottom-12">Change over time</h5>
            <div className="margin-bottom-12">
                Find trends or changes over time by visualizing the difference
                between values for the same variable in two different years.
            </div>
        </div>
    </Popover>
);

YearSelectionInfoPopup.propTypes = {
    open: PropTypes.bool,
    anchorElement: PropTypes.object,
    onClosePopup: PropTypes.func,
};

YearSelectionInfoPopup.defaultProps = {
    open: false,
    anchorElement: undefined,
    onClosePopup: undefined,
};

export default YearSelectionInfoPopup;

