// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import AppConfig from '../../appConfig';
import BusComponent from '../BusComponent';

/**
 * @typedef Props
 * @property {string} mapInstanceId
 * @property {import('../../objects/LocationAnalysisItem').default} userLocation
 *
 * @extends {BusComponent<Props>}
 */
class UserLocationListItem extends BusComponent {
    onClick = () => {
        this.bus.emit('START_LOCATION_ANALYSIS', {
            userLocation: this.props.userLocation,
            mapInstanceId: this.props.mapInstanceId,
        });
    };

    onEdit = () => {
        this.emit(
            'UPDATE_USER_LOCATION_POPUP_REQUEST',
            this.props.userLocation,
        );
    };

    onDelete = () => {
        this.emit('USER_LOCATION_DELETE_REQUEST', this.props.userLocation.itemOriginMetadata.id);
    };

    getIconForPointType = type => {
        switch (type) {
        case 'SELFSTORAGE_FACILITY':
            return 'warehouse';
        case 'SELFSTORAGE_CONSTRUCTION':
            return 'front_loader';
        default:
            return 'place';
        }
    }

    render() {
        const poiIcon = this.getIconForPointType(this.props.userLocation.metadata.type);
        return (
            <div className="user-locations-list-item">
                <div className="flex-it center grow" onClick={this.onClick}>
                    <div className="user-locations-list-item__icon">
                        <i className="material-icons">{poiIcon}</i>
                    </div>
                    <span className="ellipsis">
                        {this.props.userLocation.value}
                    </span>
                </div>
                <button
                    className="btn-icon user-locations-list-item__button"
                    onClick={this.onEdit}
                >
                    <i className="material-icons">edit</i>
                </button>
                <button
                    className="btn-icon user-locations-list-item__button"
                    onClick={this.onDelete}
                >
                    <i className="material-icons">delete</i>
                </button>
            </div>
        );
    }
}

UserLocationListItem.propTypes = {
    userLocation: PropTypes.object.isRequired,
    mapInstanceId: PropTypes.string.isRequired,
};

export default UserLocationListItem;
