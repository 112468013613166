import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import DistanceUnits from '../../enums/Unit';
import SimpleDropdown from '../dropdown/SimpleDropdown';

const OPTIONS = [
    { id: DistanceUnits.MILES, text: 'mi', descriptive: 'hints.units.miles' },
    { id: DistanceUnits.YARDS, text: 'yrd', descriptive: 'hints.units.yards' },
    { id: DistanceUnits.FEET, text: 'ft', descriptive: 'hints.units.feet' },
    { id: DistanceUnits.KILOMETERS, text: 'km', descriptive: 'hints.units.kilometers' },
    { id: DistanceUnits.METERS, text: 'm', descriptive: 'hints.units.meters' },
];

class Units extends React.Component {
    static getUnitDetails = unitType => OPTIONS.find(opt => opt.id === unitType);

    onChange = value => {
        this.props.onChange(value);
    };

    render() {
        const { selected, disabled, className, intl } = this.props;
        const options = OPTIONS.map(o => ({
            ...o,
            descriptive: intl.formatMessage({ id: o.descriptive }),
        }));
        return (
            <SimpleDropdown
                title={intl.formatMessage({ id: 'units' })}
                className={classNames('units rounded--bottom-right', className)}
                disabled={disabled}
                items={options}
                onItemClick={this.onChange}
                selectedItem={options.find(opt => opt.id === selected)}
            />
        );
    }
}

Units.propTypes = {
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    selected: PropTypes.number,
    disabled: PropTypes.bool,
    intl: PropTypes.object.isRequired,
};

Units.defaultProps = {
    selected: DistanceUnits.MILES,
    className: undefined,
    disabled: false,
};

export default injectIntl(Units);
