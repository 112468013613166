import React from 'react';
import PropTypes from 'prop-types';
import googleTagManagerEvents from '../../helpers/GoogleTagManagerEventsWrapper';
import { openEditFacilityFormEvents } from '../../enums/GoogleTagManagerEvents';

const OpenEditFacilityFormButton = googleTagManagerEvents('button', openEditFacilityFormEvents);

const SuggestEditFacilityButton = ({
    editFormPayload,
    clickBack,
}) => {
    if (!editFormPayload) return null;
    return (<OpenEditFacilityFormButton
        className="suggest-edit-facility-button"
        onClick={() => {
            clickBack(editFormPayload);
        }}
        aria-label="Suggest Edit"
    >
        Suggest Edit
    </OpenEditFacilityFormButton>);
};

SuggestEditFacilityButton.propTypes = {
    editFormPayload: PropTypes.object.isRequired,
    clickBack: PropTypes.func.isRequired,
};

export default SuggestEditFacilityButton;
