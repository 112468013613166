import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Key from '../../../enums/Key';

class YearSelectionListRange extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            currentlyHoveredYear: undefined,
        };
    }

    handleYearMouseEnter(item) {
        this.setState({
            currentlyHoveredYear: item,
        });
    }

    handleYearMouseLeave() {
        this.setState({
            currentlyHoveredYear: undefined,
        });
    }

    render() {
        const { currentlyHoveredYear } = this.state;
        const { comparableVariables, metadata, onClick, currentYear, compareYear, highlightYear } = this.props;

        const currentYearIndex =
            comparableVariables.findIndex(variable => currentYear && currentYear.surveyName === variable.surveyName);
        const compareYearIndex =
            comparableVariables.findIndex(variable => compareYear && compareYear.surveyName === variable.surveyName);

        const startYearIndex = compareYearIndex === -1 ?
                               currentYearIndex :
                               Math.min(currentYearIndex, compareYearIndex);
        const endYearIndex = compareYearIndex === -1 ?
                             currentYearIndex :
                             Math.max(currentYearIndex, compareYearIndex);

        const currentlyHoveredYearIndex =
            comparableVariables
                .findIndex(item => currentlyHoveredYear && item.surveyName === currentlyHoveredYear.surveyName);
        const isAnyYearHovered = currentlyHoveredYearIndex !== -1;

        let highlightStartIndex = startYearIndex;
        let highlightEndIndex = endYearIndex;
        const shouldDynamicHighlight = isAnyYearHovered && currentYear !== undefined && compareYear === undefined;
        const shouldFixedHighlight = currentYear !== undefined && compareYear !== undefined;
        let doHighlight = false;

        if (shouldFixedHighlight) {
            highlightStartIndex = Math.min(startYearIndex, endYearIndex);
            highlightEndIndex = Math.max(startYearIndex, endYearIndex);
            doHighlight = true;
        } else if (shouldDynamicHighlight) {
            highlightStartIndex = Math.min(startYearIndex, currentlyHoveredYearIndex);
            highlightEndIndex = Math.max(endYearIndex, currentlyHoveredYearIndex);
            doHighlight = startYearIndex !== currentlyHoveredYearIndex;
        }

        const items = comparableVariables.map((item, index) => {
            const surveyMetadata = metadata.surveys[item.surveyName];

            const currentYearSelected = currentYear && currentYear.surveyName === item.surveyName;
            const compareYearSelected = compareYear && compareYear.surveyName === item.surveyName;

            const itemClasses = classNames('year-selection flex-it center justify-center', {
                'year-selection--highlighted': doHighlight && index > highlightStartIndex && index < highlightEndIndex,
                'year-selection--highlighted-start': doHighlight && highlightStartIndex === index,
                'year-selection--highlighted-end': doHighlight && highlightEndIndex === index,
            });

            const yearSelected = currentYearSelected || compareYearSelected;
            const yearHighlighted = currentlyHoveredYear && currentlyHoveredYear.surveyName === item.surveyName && !yearSelected;
            const shouldHighlightOnHover = highlightYear && highlightYear.surveyName === item.surveyName;

            const btnClasses = classNames('year-selection__item', {
                'year-selection__item--selected': yearSelected,
                'year-selection__item--selected-outline': yearHighlighted,
                'year-selection__item--highlighted': shouldHighlightOnHover,
            });

            return (
                <div
                    key={item.variableGuid}
                    tabIndex="0"
                    className={itemClasses}
                    onClick={event => onClick(item, event)}
                    onKeyDown={e => { if (e.keyCode === Key.ENTER) onClick(item, e); }}
                    onMouseEnter={() => this.handleYearMouseEnter(item)}
                    onMouseLeave={() => this.handleYearMouseLeave()}
                >
                    <div className={btnClasses}>
                        {surveyMetadata.year}
                    </div>
                </div>
            );
        });

        return (
            <div className="flex-it wrap">
                {items}
            </div>
        );
    }
}

YearSelectionListRange.propTypes = {
    metadata: PropTypes.object.isRequired,
    comparableVariables: PropTypes.array.isRequired,
    currentYear: PropTypes.object,
    compareYear: PropTypes.object,
    highlightYear: PropTypes.object,
    onClick: PropTypes.func.isRequired,
};

YearSelectionListRange.defaultProps = {
    currentYear: undefined,
    compareYear: undefined,
    highlightYear: undefined,
};

export default YearSelectionListRange;
