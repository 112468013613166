// @ts-check
import React from 'react';

import BusComponent from '../BusComponent';
import GeoSelectionList from '../geoSelection/list/GeoSelectionList';
import GeoSelectionImport from '../geoSelection/import/GeoSelectionImport';

/**
 * @typedef Props
 * @property {import('../../objects/MapInstance').default} mapInstance
 *
 * @typedef State
 * @property {import('../../').SummaryLevel} activeSummaryLevel
 * @property {import('../../').SummaryLevel[]} selectedSummaryLevels
 * @property {Object.<string, {id: string, title: string}[]> | {}} selectedSummaryLevelsGeos
 * @property {boolean} hasSelectedGeos
 *
 * @extends {BusComponent<Props, State>}
 */
class ReportGeoSelection extends BusComponent {
    constructor(props, context) {
        super(props, context);
        /** @type {State} */
        this.state = {
            hasSelectedGeos: false,
            activeSummaryLevel: undefined,
            selectedSummaryLevels: [],
            selectedSummaryLevelsGeos: {},
        };
    }

    componentDidMount() {
        this.bindGluBusEvents({
            CURRENT_REPORT_INFO: this.onCurrentReportInfo,
            MAP_REPORT_SELECTED_FEATURES_CHANGED: this
                .onMapReportSelectedFeaturesChanged,
            MAP_PREFERRED_SUMMARY_LEVEL_CHANGED: this
                .onMapPreferredSummaryLevelChanged,
        });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    /**
     * @param {object} param0
     * @param {import('../../').SummaryLevel} param0.activeSummaryLevel
     */
    onCurrentReportInfo({ activeSummaryLevel }) {
        this.setState({
            activeSummaryLevel,
        });
    }

    /**
     * @param {object} param0
     * @param {string} param0.mapInstanceId
     * @param {Object.<string, {geoFips: string, geoQName: string}[]> } param0.features
     * @param {import('../../').SummaryLevel[]} param0.summaryLevels
     */
    onMapReportSelectedFeaturesChanged = ({
        mapInstanceId,
        features,
        summaryLevels,
    }) => {
        if (mapInstanceId !== this.props.mapInstance.id) return;

        const selectedSummaryLevelsGeos = {};
        let hasSelectedGeos = false;
        Object.keys(features).forEach(summaryLevelId => {
            const summaryLevelFeatures = features[summaryLevelId];
            if (summaryLevelFeatures.length > 0) {
                selectedSummaryLevelsGeos[
                    summaryLevelId
                ] = summaryLevelFeatures.map(f => ({
                    id: f.geoFips,
                    title: f.geoQName,
                }));
                hasSelectedGeos = true;
            }
        });

        this.setState({
            selectedSummaryLevels: summaryLevels,
            selectedSummaryLevelsGeos,
            hasSelectedGeos,
        });
    };

    onRemoveGeo = (geo, summaryLevel) => {
        this.emit('MAP_DESELECT_REPORT_FEATURE', {
            mapInstanceId: this.props.mapInstance.id,
            geoFips: geo.id,
            summaryLevel,
        });
    };

    onMapPreferredSummaryLevelChanged({ source }) {
        if (
            source.id === this.props.mapInstance.id &&
            this.state.activeSummaryLevel !== source.activeSummaryLevel
        ) {
            this.setState({
                activeSummaryLevel: source.activeSummaryLevel,
            });
        }
    }

    render() {
        if (this.state.hasSelectedGeos) {
            return (
                <GeoSelectionList
                    className="report-options__section grow"
                    activeSummaryLevel={this.state.activeSummaryLevel}
                    summaryLevels={this.state.selectedSummaryLevels}
                    summaryLevelsGeos={this.state.selectedSummaryLevelsGeos}
                    surveyName={this.props.mapInstance.dataTheme.surveyName}
                    onRemoveGeo={this.onRemoveGeo}
                />
            );
        }

        return (
            <GeoSelectionImport
                className="report-options__section grow"
                mapInstanceId={this.props.mapInstance.id}
                surveyName={this.props.mapInstance.dataTheme.surveyName}
                limit={
                    this.props.mapInstance.dataTheme.rendering[0].reportLimit
                }
                multipleSummaryLevels
                parentSelection
            />
        );
    }
}

export default ReportGeoSelection;
