'use strict';var Scope = require('./scope');
var ref = require('./values');
var Color = ref.Color;
var geometryTypes = [
    'Unknown',
    'Point',
    'LineString',
    'Polygon'
];
var EvaluationContext = function EvaluationContext() {
    this.scope = new Scope();
    this._parseColorCache = {};
};
EvaluationContext.prototype.id = function id() {
    return this.feature && 'id' in this.feature ? this.feature.id : null;
};
EvaluationContext.prototype.geometryType = function geometryType() {
    return this.feature ? typeof this.feature.type === 'number' ? geometryTypes[this.feature.type] : this.feature.type : null;
};
EvaluationContext.prototype.properties = function properties() {
    return this.feature && this.feature.properties || {};
};
EvaluationContext.prototype.pushScope = function pushScope(bindings) {
    this.scope = this.scope.concat(bindings);
};
EvaluationContext.prototype.popScope = function popScope() {
    this.scope = this.scope.parent;
};
EvaluationContext.prototype.parseColor = function parseColor(input) {
    var cached = this._parseColorCache[input];
    if (!cached) {
        cached = this._parseColorCache[input] = Color.parse(input);
    }
    return cached;
};
module.exports = EvaluationContext;