import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const AdditionalSettingsItem = props => (
    <div className="additional-settings__section" >
        <button className={classNames('additional-settings__actionable', { disabled: props.disabled })} onClick={props.onItemClick}>
            <div
                style={props.iconStyle}
                className="additional-settings__icon flex-it center justify-center no-shrink"
                aria-label="Item icon"
            >
                {props.icon}
            </div>
            <div className="additional-settings__content">
                <div className="flex-it center space-between margin-bottom-5">
                    <h4 className="additional-settings__title">{props.title}</h4>
                    {props.badge}
                </div>
                <div className="body-2">
                    {props.description}
                </div>
            </div>
        </button>
        {props.additionalActions && <div className="additional-settings__additional-actions">
                {props.additionalActions}
            </div>}
    </div>);

AdditionalSettingsItem.propTypes = {
    disabled: PropTypes.bool,
    badge: PropTypes.element,
    additionalActions: PropTypes.array,
    onItemClick: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    icon: PropTypes.object,
    iconStyle: PropTypes.object,
};

AdditionalSettingsItem.defaultProps = {
    disabled: false,
    badge: undefined,
    additionalActions: undefined,
    icon: undefined,
    iconStyle: undefined,
};

export default AdditionalSettingsItem;
