import React from 'react';
import BusComponent from '../../BusComponent';

import DotDensityLegendItems from './DotDensityLegendItems';
import DotDensitySlider from './DotDensitySlider';


class DotDensityLegend extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            dotSizeSliderValue: props.mapInstance.dataTheme.dotDensityValueHint,
        };
    }

    onDotSizeChange = value => {
        this.emit('APPLY_DOT_VALUE_HINT_REQUEST', { mapInstanceId: this.props.mapInstance.id,
            dotDensityDotValueHint: value });
        this.setState({ dotSizeSliderValue: value });
    }

    render() {
        return (<div className="dot-density-legend flex-it column">
            <div className="dot-density-legend__description flex-it grow">
                <span>One dot represents value of:</span>
            </div>
            <DotDensitySlider
                renderer={this.props.mapInstance.dataTheme.rendering[0]}
                value={this.state.dotSizeSliderValue}
                onChange={this.onDotSizeChange}
            />
            <DotDensityLegendItems
                renderer={this.props.mapInstance.dataTheme.rendering[0]}
                isMultiVariable={this.props.mapInstance.dataTheme.variableSelection.isMultiVariable}
            />
        </div>);
    }
}

export default DotDensityLegend;
