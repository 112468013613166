import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import HorizontalShadedStop from './HorizontalShadedStop';
import { getRulesLabels } from '../../../helpers/Util';

const RULES_LENGTH_FOR_LABEL_FORCE = 3;

class ShadedColorPaletteHorizontal extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            rulesLabels: getRulesLabels(props.fields,
                props.rules,
                props.nullDataRuleIndex,
                props.insufficientDataRuleIndex),
            forceLabels: props.rules.length <= RULES_LENGTH_FOR_LABEL_FORCE,
        };

        this._rulesLabelWidths = undefined;
        this._labelPadding = 5;
    }

    componentDidMount() {
        this.handleResize();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.rules !== nextProps.rules) {
            this.setState({
                rulesLabels: getRulesLabels(nextProps.fields,
                    nextProps.rules,
                    nextProps.nullDataRuleIndex,
                    nextProps.insufficientDataRuleIndex),
                forceLabels: nextProps.rules.length <= RULES_LENGTH_FOR_LABEL_FORCE,
            });
        }
    }

    componentDidUpdate() {
        this.handleResize();
    }

    handleResize = () => {
        if (this.state.forceLabels) {
            return;
        }
        // resize rules labels to fit the current available size
        window.requestAnimationFrame(() => {
            if (this.props.showLabels && this.colorPalette && this.colorPalette.clientWidth > 0) {
                const currentRulesLabelWidths = [];
                let availableWidth;
                const ruleElements = [];
                let child;
                if (this.props.insufficientDataRuleIndex !== undefined && this.props.insufficientDataRuleIndex > -1) {
                    child = this.colorPalette.firstChild.nextSibling;
                    availableWidth = this.colorPalette.clientWidth - this.colorPalette.firstChild.getBoundingClientRect().width;
                } else {
                    child = this.colorPalette.firstChild;
                    availableWidth = this.colorPalette.clientWidth;
                }
                while (child) {
                    if (child.firstChild.nextSibling) {
                        child.firstChild.nextSibling.classList.remove('hidden');
                        let ruleWidth = child.firstChild.nextSibling.getBoundingClientRect().width;
                        if (child.firstChild.nextSibling.firstChild.getBoundingClientRect().width > ruleWidth) {
                            ruleWidth = child.firstChild.nextSibling.firstChild.getBoundingClientRect().width;
                        }
                        ruleElements.push(child.firstChild.nextSibling);
                        currentRulesLabelWidths.push(ruleWidth);
                    }
                    child = child.nextSibling;
                }
                if (ruleElements.length === 0) return;
                availableWidth -= (ruleElements.length - 2) * 2 * this._labelPadding;
                // first time all elements are visible and we can get text size in pixels for all of them
                this._rulesLabelWidths = currentRulesLabelWidths;

                // if all rules labels can be seen that show them all
                if (this._rulesLabelWidths.reduce((prev, curr) => prev + curr) < availableWidth) {
                    ruleElements.forEach(ruleElement => ruleElement.classList.remove('hidden'));
                } else {
                    let rulesToDisplay = [];
                    if (ruleElements.length + 1 === 10) {
                        rulesToDisplay = [0, 8, 4, 2, 6];
                    } else if (ruleElements.length + 1 === 9) {
                        rulesToDisplay = [0, 7, 2, 5];
                    } else if (ruleElements.length + 1 === 7) {
                        // show first and last
                        rulesToDisplay = [0, 5];
                    } else if ((ruleElements.length + 1) % 2 === 0) {
                        // show first, last and middle
                        rulesToDisplay = [0, ruleElements.length - 1, (ruleElements.length - 1) / 2];
                    } else if ((ruleElements.length + 1) % 3 === 2) {
                        // add first, last and every other 3k + 1
                        rulesToDisplay = [0, ruleElements.length - 1];
                        for (let i = 1; i < ruleElements.length; i += 1) {
                            if (i * 3 === ruleElements.length - 1) break;
                            rulesToDisplay.push(i * 3);
                        }
                    }
                    ruleElements.forEach(ruleElement => ruleElement.classList.add('hidden'));
                    // only if both first and last label can be seen, try to show them
                    if (availableWidth >= (this._rulesLabelWidths[0] + this._rulesLabelWidths[this._rulesLabelWidths.length - 1])) {
                        // display first rule
                        let ruleElement = ruleElements[rulesToDisplay.shift()];
                        ruleElement.classList.remove('hidden');
                        availableWidth -= this._rulesLabelWidths[0];
                        // display last rule
                        ruleElement = ruleElements[rulesToDisplay.shift()];
                        ruleElement.classList.remove('hidden');
                        availableWidth -= this._rulesLabelWidths[this._rulesLabelWidths.length - 1];
                        while (rulesToDisplay.length > 0) {
                            // if display rules are in pairs show them only if both edges can fit
                            if (rulesToDisplay.length % 2 === 0) {
                                const firstRuleElementIndex = rulesToDisplay.shift();
                                const lastRuleElementIndex = rulesToDisplay.pop();
                                const firstRuleElement = ruleElements[firstRuleElementIndex];
                                const lastRuleElement = ruleElements[lastRuleElementIndex];
                                if (availableWidth >= (this._rulesLabelWidths[firstRuleElementIndex] + this._rulesLabelWidths[lastRuleElementIndex])) {
                                    firstRuleElement.classList.remove('hidden');
                                    availableWidth -= this._rulesLabelWidths[firstRuleElementIndex];
                                    lastRuleElement.classList.remove('hidden');
                                    availableWidth -= this._rulesLabelWidths[lastRuleElementIndex];
                                }
                            } else {
                                const ruleElementIndex = rulesToDisplay.shift();
                                ruleElement = ruleElements[ruleElementIndex];
                                if (availableWidth >= this._rulesLabelWidths[ruleElementIndex]) {
                                    ruleElement.classList.remove('hidden');
                                    availableWidth -= this._rulesLabelWidths[ruleElementIndex];
                                }
                            }
                        }
                    }
                }
            }
        });
    }

    render() {
        const { rulesLabels, forceLabels } = this.state;
        const {
            mapInstanceId,
            rules,
            showLabels,
            variableIdx,
            nullDataRuleIndex,
            insufficientDataRuleIndex,
            hideInsufficient,
            legendHighlighted,
        } = this.props;

        if (!rulesLabels) {
            return (<div
                ref={colorPalette => { this.colorPalette = colorPalette; }}
                className={classNames('rules-color-palette-horizontal flex-it grow', this.props.className)}
            />);
        }

        const ruleItems = [];
        rules.forEach((rule, ruleIdx) => {
            if (ruleIdx === nullDataRuleIndex) return;

            const isInsufficientRule = ruleIdx === insufficientDataRuleIndex;

            if (isInsufficientRule && hideInsufficient) return;

            ruleItems.push((<HorizontalShadedStop
                key={rule.title}
                showLabel={showLabels && !isInsufficientRule && (rulesLabels[ruleIdx] !== undefined)}
                forceLabels={forceLabels}
                legendHighlighted={legendHighlighted}
                mapInstanceId={mapInstanceId}
                variableIdx={variableIdx}
                label={rulesLabels[ruleIdx]}
                isInsufficientDataRule={isInsufficientRule}
                rule={rule}
                ruleIdx={ruleIdx}
            />));
        });

        return (<div
            ref={colorPalette => { this.colorPalette = colorPalette; }}
            className={classNames('shaded-color-palette-horizontal flex-it', this.props.className)}
        >
            {ruleItems}
        </div>);
    }


}

ShadedColorPaletteHorizontal.propTypes = {
    fields: PropTypes.array.isRequired,
    rules: PropTypes.array.isRequired,
    variableIdx: PropTypes.number,
    nullDataRuleIndex: PropTypes.number.isRequired,
    insufficientDataRuleIndex: PropTypes.number.isRequired,
    hideInsufficient: PropTypes.bool,
    className: PropTypes.string,
    mapInstanceId: PropTypes.string.isRequired,
    showLabels: PropTypes.bool,
    legendHighlighted: PropTypes.bool,
};

ShadedColorPaletteHorizontal.defaultProps = {
    showLabels: true,
    variableIdx: undefined,
    hideInsufficient: false,
    className: '',
    legendHighlighted: PropTypes.bool,
};

export default ShadedColorPaletteHorizontal;
