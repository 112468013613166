import React from 'react';
import classNames from 'classnames';

import ApplicationMode from '../../enums/ApplicationMode';
import Key from './../../enums/Key';
import LocationAnalysisItemOrigin from '../../enums/LocationAnalysisItemOrigin';
import { HelpTourTargets } from '../../enums/HelpTourDefinitions';

import LocationAnalysisItem from '../../objects/LocationAnalysisItem';
import { hasParentNode } from '../../helpers/Util';
import AppConfig from '../../appConfig';

import BusComponent from '../BusComponent';
import SearchBoxTrigger from './SearchBoxTrigger';
import SearchInput from './SearchInput';
import SearchResultList from './SearchResultList';
import RecentSearchList from './RecentSearchList';
import MapSelect from '../mapSelect/MapSelect';
import Loader from '../Loader';

const SEARCH_TIMEOUT = 500;
const SEARCH_THRESHOLD = 3; // the minimum number of characters for search to initiate

class SearchBox extends BusComponent {
    constructor(props, context) {
        super(props, context);
        const { mapInstanceIds, activeMapInstanceId } = props;

        this.state = {
            isExpanded: false,
            searchTerm: undefined,
            errorMessageId: undefined,
            results: undefined,
            isFocused: false,
            boundingBoxesById: {},
            selectedItem: undefined,
            activeMapInstanceId: activeMapInstanceId || mapInstanceIds[0],
        };
    }

    componentDidMount() {
        this.bindGluBusEvents({
            MAP_LOAD: this.handleMapUpdate,
            MAP_MOVE_END: this.handleMapUpdate,
            SEARCH_LOAD_SUCCESS: this.onSearchLoadSuccess,
            SEARCH_LOAD_ERROR: this.onSearchLoadError,
            SEARCH_SINGLE_ITEM_LOAD_SUCCESS: this.onSearchSingleItemLoadSuccess,
            SEARCH_GET_CURRENT_RECENT_SEARCH_TERMS_RESPONSE: this.handleRecentSearchTermsUpdate,
            SEARCH_ADD_RECENT_SEARCH_TERM_SUCCESS: this.handleRecentSearchTermsUpdate,
            FOCUS_SEARCH: this.onFocusSearch,
        });

        this.emit('SEARCH_GET_RECENT_SEARCH_TERMS_REQUEST', { source: this });
    }

    componentWillReceiveProps({ mapInstanceIds, activeMapInstanceId }) {
        const nextActiveMapInstanceId =
            activeMapInstanceId || mapInstanceIds[0];
        if (nextActiveMapInstanceId !== this.state.activeMapInstanceId) {
            this.setState(
                {
                    selectedItem: undefined,
                    activeMapInstanceId: nextActiveMapInstanceId,
                },
                () => {
                    this.clearMarkersOnMaps();
                },
            );
        }
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
        clearTimeout(this._searchTimeout);

        document.removeEventListener('keyup', this.handleDocumentKeyUp);
        document.removeEventListener('click', this.handleDocumentClick);
    }

    componentDidUpdate(prevProps, prevState) {
        const { isExpanded } = this.state;
        const { onToggleVisibility } = this.props;

        if (isExpanded) {
            document.addEventListener('click', this.handleDocumentClick);
            document.addEventListener('keyup', this.handleDocumentKeyUp);
        } else {
            document.removeEventListener('click', this.handleDocumentClick);
            document.removeEventListener('keyup', this.handleDocumentKeyUp);
        }

        if (onToggleVisibility && prevState.isExpanded !== isExpanded) {
            onToggleVisibility(isExpanded);
        }
    }

    onFocusSearch = () => {
        if (this.searchInput) {
            this.searchInput.focus();
            this.setState({ isFocused: true });
        }
    };

    // source is MapViewer
    handleMapUpdate = ({ source: mapViewer, boundingBox }) => {
        this.setState(prevState => ({
            boundingBoxesById: {
                ...prevState.boundingBoxesById,
                [mapViewer.id]: boundingBox,
            },
        }));
    };

    handleDocumentClick = e => {
        if (hasParentNode(e.target, this.searchBoxRoot) || !!this.props.isPanelExpanded) return;
        this.closeDropdownMenu();
    };

    handleRecentSearchTermsUpdate = ({ recentSearchTerms }) => {
        this.setState({
            recentSearchTerms,
        });
    };

    onSearchLoadSuccess = ({ query, data }) => {
        if (this.state.searchTerm !== query) return;

        this.setState(
            {
                isSearching: false,
                results: [...data],
                isExpanded: true,
            },
            () => {
                this.searchInput.focus();

                this.emit('COUNTER_LOG_REQUEST', [
                    {
                        event_type: 'search_regular',
                        event_value: query,
                    },
                    {
                        event_type: 'search_platform',
                        event_value: query,
                    },
                ]);
            },
        );
    };

    onSearchLoadError = ({ originalError }) => {
        let errorMessageId;
        switch (originalError.status) {
        case 429:
            errorMessageId = 'dataBrowser.searchSaturated';
            break;
        default:
            errorMessageId = 'dataBrowser.searchDown';
        }

        this.setState({
            isSearching: false,
            results: [],
            errorMessageId,
            isExpanded: true,
        });
    };

    closeDropdownMenu = () => {
        this.setState({
            isFocused: false,
            isExpanded: false,
            errorMessageId: undefined,
        });
    };

    handleDocumentKeyUp = e => {
        if (e.keyCode === Key.ESC) {
            this.closeDropdownMenu();
        }
    };

    handleCloseClick = () => {
        this.clearSearch();
        this.setState({
            isExpanded: false,
            isFocused: false,
        });
    };

    handleTriggerClick = () => {
        this.setState({ isExpanded: true });
    };

    handleInputFocus = () => {
        this.setState({ isExpanded: true, isFocused: true });
    };

    handleSearchTargetMap = activeMapInstanceId => {
        this.setState({ activeMapInstanceId }, () => {
            this.initiateSearch(false);
        });
    };

    handleSearchTermChange = searchTerm => {
        this.setState({ searchTerm }, () => {
            if (searchTerm.length) {
                this.initiateSearch();
            } else {
                this.clearSearch();
            }
        });
    };

    handleRecentSearchTermSelection = searchTerm => {
        this.setState(
            {
                searchTerm,
            },
            () => {
                this.doSearch();
            },
        );
    };

    // Upon successful geocode of the item without spatial data, we are updating the component state
    // and re-initiate handling the results - showing them on the map
    onSearchSingleItemLoadSuccess = item => {
        const { results } = this.state;
        const updatedResults = results.map(result => {
            if (result.id === item.id) {
                return item;
            }
            return result;
        });
        this.setState({ results: updatedResults }, () => {
            this.handleSearchResultSelection(item);
        });
    };

    /**
     * @param {import('../../').SearchResult} item
     * @param {boolean} setSelection
     */
    handleSearchResultSelection = (item, setSelection = true) => {
        const { isReportEditor } = this.props;
        const isEmbedView = ApplicationMode.isViewMode(
            this.context.applicationMode,
        );

        if (!item.featureType) {
            this.emit('SEARCH_GEOCODE_AND_ENRICH_SINGLE_ITEM', item);
            return;
        }

        this.targetMapIds.forEach(mapInstanceId => {
            this.emit('MAP_DISPLAY_SEARCH_RESULTS_MARKERS_REQUEST', {
                results: [item],
                mapInstanceId,
            });

            const objectToEmit = {
                padding: this.context.isMobileDevice ? 40 : undefined,
                selectedResult: item,
                mapInstanceId,
            };

            // These would mostly represent roads and single GeoPoint on map (USPS)
            if (
                item.featureType === 'LINESTRING' ||
                item.featureType === 'MULTILINESTRING' ||
                item.featureType === 'POINT'
            ) {
                objectToEmit.center = item.point;
                objectToEmit.zoom = 15;
                this.emit('MAP_SET_CENTER_REQUEST', objectToEmit);
                // These represent roads and areas (states, places)
            } else if (
                item.featureType === 'POLYGON' ||
                item.featureType === 'MULTIPOLYGON'
            ) {
                objectToEmit.bounds = item.boundingBox;
                this.emit('MAP_FIT_BOUNDS_REQUEST', objectToEmit);
            }

            // Show location analysis panel if NOT in report frame or in view mode

            // object used in location analysis
            const selectedItem = new LocationAnalysisItem({
                id: `${item.point.lng};${item.point.lat}`,
                type: item.type,
                value: item.value,
                point: item.point,
                itemOrigin: LocationAnalysisItemOrigin.SEARCH_RESULT,
                // add default selection and analysis type
                analysisTypeId: AppConfig.constants.defaultLocationAnalysisSelectionType,
                selection: new Set(AppConfig.constants.defaultLocationAnalysisSelection),
                searchBoxOrigin: 'ADDRESS',
                icon: 'place',
            });
            if (!isReportEditor && !isEmbedView) {
                this.emit('ENTER_UPDATE_LOCATION_ANALYSIS_MODE', {
                    selectedItem,
                    mapInstanceId,
                    showInsights: AppConfig.constants.searchBox.shouldShowInsightsOnPointSearch,
                });
            } else {
                this.emit('SHOW_PIN_ON_MAP', {
                    selectedItem,
                    mapInstanceId,
                });
            }
        });

        this.setState({
            searchTerm: item.value,
        });

        if (setSelection) {
            this.setState({
                isFocused: false,
                selectedItem: item,
            });
        }
    };

    clearSearch = () => {
        this.setState(
            {
                results: undefined,
                selectedItem: undefined,
                searchTerm: '',
            },
            () => {
                this.clearMarkersOnMaps();
            },
        );
    };

    initiateSearch(withTimeout = true) {
        clearTimeout(this._searchTimeout);
        const { searchTerm } = this.state;

        if (!searchTerm || searchTerm.trim().length < SEARCH_THRESHOLD) return;

        this._searchTimeout = setTimeout(
            () => {
                this.setState(
                    {
                        selectedItem: undefined,
                        results: undefined,
                    },
                    () => {
                        this.clearMarkersOnMaps();

                        // store recent searches
                        const { recentSearchTerms } = this.state;
                        if (recentSearchTerms.indexOf(searchTerm) === -1) {
                            this.emit('SEARCH_ADD_RECENT_SEARCH_TERM_REQUEST', {
                                searchTerm,
                            });
                        }

                        this.doSearch();
                    },
                );
            },
            withTimeout ? SEARCH_TIMEOUT : 0,
        );
    }

    renderContent() {
        const {
            searchTerm,
            errorMessageId,
            selectedItem,
            isSearching,
            results,
            isFocused,
            isExpanded,
            activeMapInstanceId,
            recentSearchTerms,
        } = this.state;

        const { mapInstanceIds, disableMapSelection } = this.props;

        const { applicationMode } = this.context;
        const isEmbedView = ApplicationMode.isViewMode(applicationMode);

        if (!isExpanded && isEmbedView) {
            return null;
        }

        const showMapSelection =
            !disableMapSelection &&
            isFocused &&
            this.props.activeMapInstanceId == null &&
            mapInstanceIds.length > 1 &&
            results &&
            results.length > 0;

        const clearClasses = classNames('search-box__clear', {
            'search-box__clear--visible': searchTerm && searchTerm.length > 0,
        });

        const searchControlButtons = isEmbedView ? (
            <div className="flex-it center flex-end">
                <button
                    className={clearClasses}
                    onClick={() => this.handleSearchTermChange('')}
                >
                    Clear
                </button>
                <button
                    className="btn-icon flex-it justify-center center"
                    onClick={this.handleCloseClick}
                >
                    <i className="material-icons">close</i>
                </button>
            </div>
        ) : (
            <button
                className={clearClasses}
                onClick={() => this.handleSearchTermChange('')}
            >
                Clear
            </button>
        );

        return (
            <div className="search-box__menu">
                <div className="search-box__header">
                    <SearchInput
                        ref={c => {
                            this.searchInput = c;
                        }}
                        value={searchTerm}
                        onChange={this.handleSearchTermChange}
                        onFocus={this.handleInputFocus}
                    />
                    {isSearching ? (
                        <div className="search-box__spinner">
                            <Loader width="36" height="36" />
                        </div>
                    ) : (
                        searchControlButtons
                    )}
                </div>
                <div className="search-box__content">
                    {!searchTerm && (
                        <RecentSearchList
                            items={recentSearchTerms}
                            onSelect={this.handleRecentSearchTermSelection}
                        />
                    )}
                    {showMapSelection && (
                        <MapSelect
                            onChange={this.handleSearchTargetMap}
                            selectedMapInstanceId={activeMapInstanceId}
                            mapInstanceIds={this.props.mapInstanceIds}
                        />
                    )}
                    <SearchResultList
                        searchTerm={searchTerm}
                        errorMessageId={errorMessageId}
                        selectedItem={selectedItem}
                        items={results}
                        onSelect={this.handleSearchResultSelection}
                    />
                </div>
            </div>
        );
    }

    render() {
        const { isExpanded, boundingBoxesById, isFocused } = this.state;

        const { applicationMode } = this.context;
        const isEmbedView = ApplicationMode.isViewMode(applicationMode);

        // In case of side-by-side maps, all maps and bounding boxes should be
        // loaded in order to perform the search safely
        const hasMissingBoundingBoxes = this.props.mapInstanceIds.some(
            mapInstanceId => !boundingBoxesById[mapInstanceId],
        );

        const classes = classNames('search-box', {
            'search-box--expanded': isExpanded,
            'search-box--embed': isEmbedView,
            'search-box--focused': isFocused,
            'search-box--disabled':
                hasMissingBoundingBoxes && !this.props.forceEnable,
        });

        // If the SearchBox is in embed mode show the trigger
        // If the Search is in the header show the expanded version
        return (
            <div
                className={classes}
                ref={c => (this.searchBoxRoot = c)}
                data-tourId={HelpTourTargets.MAP_SEARCH}
            >
                {isEmbedView && (
                    <SearchBoxTrigger
                        handleTriggerClick={this.handleTriggerClick}
                    />
                )}
                {this.renderContent()}
            </div>
        );
    }

    get targetMapIds() {
        const { mapInstanceIds, disableMapSelection } = this.props;
        const { activeMapInstanceId } = this.state;

        if (disableMapSelection) {
            return mapInstanceIds;
        }

        return activeMapInstanceId ? [activeMapInstanceId] : mapInstanceIds;
    }

    clearMarkersOnMaps() {
        this.props.mapInstanceIds.forEach(mapInstanceId =>
            this.emit('MAP_CLEAR_SEARCH_RESULTS_MARKERS_REQUEST', {
                mapInstanceId,
            }),
        );
    }

    doSearch() {
        const { searchTerm, boundingBoxesById, activeMapInstanceId } =
            this.state;
        if (!searchTerm || !searchTerm.length) return;

        this.setState(
            {
                isSearching: true,
                errorMessageId: undefined,
            },
            () => {
                this.emit('SEARCH_LOAD_REQUEST', {
                    searchTerm,
                    mapInstanceId: activeMapInstanceId,
                    boundingBox: boundingBoxesById[activeMapInstanceId],
                });
            },
        );
    }
}

export default SearchBox;
