// @ts-check
import AppConfig from '../appConfig';
import BaseController from './BaseController';

import MetadataDataSource from '../dataSources/MetadataDataSource';
import ProjectDataSource from '../dataSources/ProjectDataSource';
import MapDataSource from '../dataSources/MapDataSource';
import ReportTableTemplateDataSource from '../dataSources/ReportTableTemplateDataSource';
import CustomMapSelectionDataSource from '../dataSources/CustomMapSelectionDataSource';
import SearchDataSource from '../dataSources/SearchDataSource';

import { getTime } from '../helpers/GeoSpatialHelper';
import dataToXlsx from '../helpers/ExcelHelper';
import QueryFromMap from '../helpers/QueryFromMap';
import MapParser from '../helpers/MapParser';
import { generateClientFunction, generateGetValue } from '../helpers/ClientFunctionParser';
import LocationAnalysisItemOrigin from '../enums/LocationAnalysisItemOrigin';
import { POI_TYPES } from '../enums/PoiTypes';
import PointsDataSource from '../dataSources/PointsDataSource';

/** @type {Array.<import('../').Config.FeaturedReport>} */
const FEATURED_REPORTS = AppConfig.constants.featuredReports;

/** @type {number} */
const CREATE_REPORT_GEOGRAPHIES_LIMIT = AppConfig.constants.reportGeographiesLimit;

const DEFAULT_LOCATION_REPORT_AGGREGATION_TYPE = 2;

/**
 * @class
 * @extends BaseController
 */
class CustomMapSelectionController extends BaseController {
    // @ts-ignore
    static get name() {
        return 'CustomMapSelectionController';
    }

    static getInstance(options) {
        return new CustomMapSelectionController(options);
    }

    onActivate() {
        this.bindGluBusEvents({
            ENTER_UPDATE_LOCATION_ANALYSIS_MODE: this.onEnterUpdateLocationAnalysisMode,
            EXIT_LOCATION_ANALYSIS_MODE: this.onExitLocationAnalysisMode,
            ADD_TO_LOCATION_ANALYSIS_ITEMS: this.onAddToLocationAnalysisItems,
            REMOVE_FROM_LOCATION_ANALYSIS_ITEMS: this.onRemoveFromLocationAnalysisItems,
            LOCATION_ANALYSIS_ITEMS_REQUEST: this.onLocationAnalysisItemsRequest,
            SET_LOCATION_ANALYSIS_TYPE: this.onSetLocationAnalysisType,
            SET_LOCATION_ANALYSIS_SELECTION: this.onSetLocationAnalysisSelection,
            CUSTOM_MAP_SELECTION_REPORT_INITIAL_INFO_REQUEST:
                this.onCustomMapSelectionReportInitialInfoRequest,
            CUSTOM_MAP_SELECTION_REPORT_CONSTRUCTION_INFO_REQUEST:
                this.onCustomMapSelectionReportConstructionInfoRequest,
            CUSTOM_MAP_SELECTION_CONTOUR_REQUEST: this.onCustomMapSelectionContourRequest,
            CUSTOM_MAP_SELECTION_REPORT_TOPICS_REQUEST: this.onUpdateCompareSurveyList,
            CUSTOM_MAP_SELECTION_SURVEYS_REQUEST: this.onSurveysRequest,
            CUSTOM_MAP_SELECTION_FEATURED_REPORTS_REQUEST: this.onFeaturedReportsRequest,
            CUSTOM_LOCATION_ANALYSIS_VALUES_REQUEST: this.onCustomLocationAnalysisValuesRequest,
            SAVE_CUSTOM_LOCATION_ANALYSIS_VALUE_REQUEST: this.onSaveCustomLocationAnalysisValue,
            RESET_CUSTOM_LOCATION_ANALYSIS_VALUES_REQUEST: this.onResetCustomLocationAnalysisValues,
            CLOSE_LOCATION_ANALYSIS_REQUEST: this.onCloseLocationAnalysis,
            SET_CORRESPONDING_SUMMARY_LEVELS: this.onSetCorrespondingSummaryLevels,
            COMPATIBLE_TABLE_TEMPLATES_LOADED: this.onCompatibleTableTemplatesLoaded,
            LOAD_COMPATIBLE_TABLE_TEMPLATES_REQUEST: this.onCompatibleTableTemplatesLoaded,
            COMPATIBLE_TABLE_TEMPLATES_REQUEST: this.onCompatibleTableTemplatesRequest,
            CUSTOM_MAP_SELECTION_REPORT_PARAMS_CHANGE: this.onReportDefinitionChange,
            CUSTOM_MAP_SELECTION_SET_OLAP_REPORT: this.onSetOlapReport,
            CREATE_CUSTOM_MAP_SELECTION_REPORT_REQUEST: this.onCreateReport,
        });

        /** @type {import('../dataSources/MetadataDataSource').default} */
        this.metadataDataSource = this.activateSource(MetadataDataSource);
        /** @type {import('../dataSources/MapDataSource').default} */
        this.mapDataSource = this.activateSource(MapDataSource);
        /** @type {import('../dataSources/ProjectDataSource').default} */
        this.projectDataSource = this.activateSource(ProjectDataSource);
        /** @type {import('../dataSources/CustomMapSelectionDataSource').default} */
        this.customMapSelectionDataSource = this.activateSource(CustomMapSelectionDataSource);
        /** @type {import('../dataSources/ReportTableTemplateDataSource').default} */
        this.reportTableTemplateDataSource = this.activateSource(ReportTableTemplateDataSource);
        /** @type {import('../dataSources/SearchDataSource').default} */
        this.searchDataSource = this.activateSource(SearchDataSource);
        this.pointsDataSource = this.activateSource(PointsDataSource);
    }

    /**
     * @param {object} param0
     * @param {string} param0.mapInstanceId
     */
    async onCreateReport({ mapInstanceId }) {
        const {
            reportDefinition,
            selectedCorrespondingSummaryLevels,
            downloadReport,
            isOlapReport,
            olapReportId,
            olapReportName,
        } = this.customMapSelectionDataSource;
        if (downloadReport && isOlapReport) {
            this.bus.emit('SET_CREATING_REPORT_MESSAGE', {
                creatingReport: true,
                creatingReportMessage: 'dataBrowser.yourReportWillBeDownloaded',
            });
            this.bus.emit('GET_OLAP_REPORT_DATA', {
                mapInstanceId,
                olapReportId,
                olapReportName,
            });
        } else if (downloadReport) {
            this.bus.emit('SET_CREATING_REPORT_MESSAGE', {
                creatingReport: true,
                creatingReportMessage: 'dataBrowser.yourReportWillBeDownloaded',
            });
            await this.onCustomMapSelectionDownloadPointReportRequest({
                surveyCode: reportDefinition.selectedFeaturedReportSurveyCode,
                selectedReportTopic: reportDefinition.selectedReportTopic,
                mapInstanceId,
            });
        } else {
            this.bus.emit('SET_CREATING_REPORT_MESSAGE', {
                creatingReport: true,
            });

            const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);
            const { locationAnalysisItem } = mapInstance; // selectedItem
            const { analysisType } = locationAnalysisItem;
            const analysisTypeTitle = `${analysisType.UNIT} ${analysisType.NAME.toLowerCase()} - ${
                locationAnalysisItem.value
            }`;

            await this.onCustomMapSelectionCreateReportRequest({
                ...reportDefinition,
                analysisTypeTitle,
                selectedSummaryLevels: selectedCorrespondingSummaryLevels.map(l => l.id),
            });
        }
    }

    onSetOlapReport({ reportName, downloadReport, reportId }) {
        this.customMapSelectionDataSource.downloadReport = downloadReport;
        this.customMapSelectionDataSource.isOlapReport = true;
        this.customMapSelectionDataSource.olapReportName = reportName;
        this.customMapSelectionDataSource.olapReportId = reportId;

        this.bus.emit('CUSTOM_MAP_SELECTION_REPORT_PARAMS_SET', {
            downloadReport,
            isOlapReport: true,
        });
        // No option to select higher geo levels for olap reports
        this.bus.emit('AVAILABLE_CORRESPONDING_SUMMARY_LEVELS_SET', {
            availableSummaryLevels: [],
        });
    }

    /**
     * @param {object} param0
     * @param {object} param0.reportParams
     * @param {boolean} param0.downloadReport
     * @param {string} param0.mapInstanceId
     */
    async onReportDefinitionChange({ reportParams, downloadReport, mapInstanceId }) {
        const selectedTemplate =
            this.customMapSelectionDataSource.compatibleTableTemplates &&
            this.customMapSelectionDataSource.compatibleTableTemplates.find(
                template =>
                    reportParams.selectedReportTopic &&
                    template.id.toString() === reportParams.selectedReportTopic.id.toString(),
            );
        let selectedTableGuidsBySurveyName;
        if (selectedTemplate) {
            // if currently selectedReportTopic is actually a report template, save its tableGuids for later use
            selectedTableGuidsBySurveyName = selectedTemplate.tables.map(
                table => table.guidsBySurveyName,
            );
        }

        this.customMapSelectionDataSource.downloadReport = downloadReport;
        this.customMapSelectionDataSource.isOlapReport = false;
        this.customMapSelectionDataSource.reportDefinition = {
            selectedReportTopic: reportParams.selectedReportTopic,
            selectedSurveys: reportParams.selectedSurveys,
            selectedFeaturedReportSurveyCode: reportParams.selectedFeaturedReportSurveyCode,
            defaultAggregationType:
                reportParams.defaultAggregationType || DEFAULT_LOCATION_REPORT_AGGREGATION_TYPE,
            selectedTableGuidsBySurveyName,
            mapInstanceId,
        };

        this.customMapSelectionDataSource.selectedCorrespondingSummaryLevels = [];

        this.bus.emit('CUSTOM_MAP_SELECTION_REPORT_PARAMS_SET', {
            downloadReport,
            isOlapReport: this.customMapSelectionDataSource.isOlapReport,
            reportDefinition: this.customMapSelectionDataSource.reportDefinition,
            compatibleTableTemplates: this.customMapSelectionDataSource.compatibleTableTemplates,
        });
        // Note: reportDefinition.selectedSurveys structure always come with a single survey, or the same survey across
        // multiple years. Considering the fact that the summary level setup should be prepared in the same way for all
        // years combinations, taking just the active (year) survey should be safe.

        // To give ability for user to select upward corresponding geographies for aggregation purpose we need to
        // extract all possible summary level. First we need to get the survey codes selected from the UI
        const surveyCodes = this.customMapSelectionDataSource.reportDefinition.selectedSurveys.map(
            s => s.name,
        );
        // Now we get the arrays of summary levels available for each survey/data source loaded in app store
        let surveysSummaryLevels = surveyCodes
            .filter(sc => !!this.mapDataSource.currentMaps[sc])
            .map(sc => this.mapDataSource.currentMaps[sc].summaryLevels.map(s => s.id));
        // In case of Storage Facility and Housing unit report no survey codes are found so we need to prevent intersecting
        // default CorrespondingSummaryLevels array with itself
        if (surveysSummaryLevels.length) {
            surveysSummaryLevels.push(
                AppConfig.constants.correspondingSummaryLevels.map(l => l.id),
            );
            surveysSummaryLevels = surveysSummaryLevels.reduce((a, b) =>
                a.filter(c => b.includes(c)),
            );
        }
        this.bus.emit('AVAILABLE_CORRESPONDING_SUMMARY_LEVELS_SET', {
            availableSummaryLevels: surveysSummaryLevels,
        });
    }

    /**
     * @param {object} param0
     * @param {import('../types').ReportTemplateTable[]} param0.reportTableTemplates
     */
    onCompatibleTableTemplatesLoaded({ reportTableTemplates }) {
        this.customMapSelectionDataSource.compatibleTableTemplates = reportTableTemplates;
        this.bus.emit('COMPATIBLE_TABLE_TEMPLATES_SET', reportTableTemplates);
        this.bus.emit('CUSTOM_MAP_SELECTION_REPORT_PARAMS_SET', {
            downloadReport: this.customMapSelectionDataSource.downloadReport,
            reportDefinition: this.customMapSelectionDataSource.reportDefinition,
            compatibleTableTemplates: this.customMapSelectionDataSource.compatibleTableTemplates,
        });
    }

    onCompatibleTableTemplatesRequest() {
        this.bus.emit(
            'COMPATIBLE_TABLE_TEMPLATES_SET',
            this.customMapSelectionDataSource.compatibleTableTemplates,
        );
    }

    /**
     * @param {object} param
     * @param {import ('../../..').SummaryLevel[]} param.summaryLevels
     */
    onSetCorrespondingSummaryLevels({ summaryLevels }) {
        this.customMapSelectionDataSource.selectedCorrespondingSummaryLevels = summaryLevels;
    }

    /**
     * @param {object} param0
     * @param {string} param0.mapInstanceId
     */
    onCloseLocationAnalysis({ mapInstanceId }) {
        const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);

        this.bus.emit('EXIT_LOCATION_ANALYSIS_MODE', {
            mapInstanceId: mapInstance.id,
        });
    }

    /**
     * @param {object} param0
     * @param {import('../objects/LocationAnalysisItem').default} param0.selectedItem
     * @param {string} param0.mapInstanceId
     * @param {boolean} param0.showInsights
     */
    async onEnterUpdateLocationAnalysisMode({
        selectedItem,
        mapInstanceId,
        showInsights = false,
        visualReportProps = {},
    }) {
        const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);

        // Get reverse geocode address for custom pins
        if (
            AppConfig.constants.useHereReverseGeocode &&
            selectedItem.itemOrigin === LocationAnalysisItemOrigin.CUSTOM_PIN
        ) {
            /** @type {string | null} */
            let address = null;
            try {
                address = await this.searchDataSource.hereReverseGeocode({
                    point: selectedItem.point,
                });
            } catch (error) {
                console.error(error);
            }

            selectedItem.value = address ? address.toString() : selectedItem.value;
        }

        /**
         * Check if analysis type is null, if it is
         * null map should zoom in to our pin
         */
        if (!selectedItem._analysisTypeId) {
            const objectToEmit = {
                mapInstanceId,
                center: selectedItem.point,
                zoom: 15,
            };
            this.bus.emit('MAP_SET_CENTER_REQUEST', objectToEmit);
        }

        // STARTING A NEW LOCATION ANALYSIS
        // If location analysis is not active for the mapInstance
        if (!mapInstance.isLocationAnalysisActive) {
            mapInstance.locationAnalysisItem = selectedItem;

            // We set the minimal summary level and minimize the legend
            this.bus.emit('SET_MIN_SUMMARY_LEVEL', mapInstanceId);
            this.bus.emit('MINIMIZE_LEGEND');
            this.bus.emit('LOCATION_ANALYSIS_MODE_STARTED', { mapInstanceId });

            // Handle default analysis type and selection
            if (
                mapInstance.locationAnalysisItem.analysisTypeId &&
                mapInstance.locationAnalysisItem.selection.size
            ) {
                this.bus.emit('SET_LOCATION_ANALYSIS_SELECTION', {
                    mapInstanceId,
                    selection: mapInstance.locationAnalysisItem.selection,
                });
            }
        } else if (
            !mapInstance.locationAnalysisItem.equalCenter(selectedItem) ||
            // location items that have the same center but a different origin
            // should be treated as different location items
            mapInstance.locationAnalysisItem.itemOrigin !== selectedItem.itemOrigin
        ) {
            // Remember previous selection
            const previousAnalysisTypeId = mapInstance.locationAnalysisItem.analysisTypeId;
            const previousSelection = mapInstance.locationAnalysisItem.selection;

            /**
             * isSameAnalysisType is used to determine if we should switch our analysis
             * type, we shouldn't switch if we are in same search e.g Address, but we
             * should switch when we switch from Location to Address search.
             * If we search for some Address and select drive time, and then we search
             * for another address we should stay on drive time analysis type, but when
             * we search for Location then we should switch to geography analysis type
             */
            const isSameAnalysisType =
                mapInstance.locationAnalysisItem.isGeoAvailable === selectedItem.isGeoAvailable;
            // Otherwise location analysis is in progress
            // so update mapInstance if the item is changed
            mapInstance.locationAnalysisItem = selectedItem;

            // Remove map layers from previous location and emit the update
            this.bus.emit('CLEAR_CUSTOM_MAP_SELECTION', { mapInstanceId });
            this.bus.emit('MINIMIZE_LEGEND');

            if (isSameAnalysisType) {
                // Set previous analysis type
                mapInstance.locationAnalysisItem.analysisTypeId = previousAnalysisTypeId;
                // Set previous selection
                mapInstance.locationAnalysisItem.selection = previousSelection;
            }

            this.bus.emit('SET_LOCATION_ANALYSIS_SELECTION', {
                mapInstanceId,
                selection: mapInstance.locationAnalysisItem.selection,
            });
        } else if (
            mapInstance.locationAnalysisItem.equalCenter(selectedItem) &&
            mapInstance.locationAnalysisItem.id === selectedItem.id
        ) {
            // if our new location analysis item is same as the one before, we should go in analysis again
            this.bus.emit('SET_LOCATION_ANALYSIS_SELECTION', {
                mapInstanceId,
                selection: mapInstance.locationAnalysisItem.selection,
            });
        }

        this.bus.emit('SHOW_SEARCHED_ITEM_POPUP');

        if (showInsights && mapInstance.locationAnalysisItem) {
            const { value, point, selection, analysisType, id, type } =
                mapInstance.locationAnalysisItem;
            const additionalParams = POI_TYPES.some(poiType => poiType === type)
                ? {
                      'point-type': type,
                      'facility-id': id,
                  }
                : {
                      'point-type': type,
                  };
            const visualReport = {
                title: value,
                selection: Array.from(selection),
                profile: analysisType.VISUAL_REPORT_PROFILE,
                report: AppConfig.constants.defaultInsightsReport,
                point,
                pointsOfInterest: id,
                additionalParams,
                ...visualReportProps,
            };
            this.bus.emit('SHOW_INSIGHTS', visualReport);
        }

        // In order to reconcile visual components upon selection update
        // we need to trigger refresh event but only after the current
        // event cycle, therefore we use setTimeout with no real timeout
        setTimeout(() => {
            this.bus.emit('LOCATION_ANALYSIS_ITEMS_REQUEST');
        }, 0);
    }

    /**
     * @param {object} param0
     * @param {string} param0.mapInstanceId
     */
    onExitLocationAnalysisMode({ mapInstanceId }) {
        const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);
        mapInstance.locationAnalysisItem = undefined;

        // Remove map layers
        this.bus.emit('CLEAR_CUSTOM_MAP_SELECTION', { mapInstanceId });

        this.bus.emit('LOCATION_ANALYSIS_MODE_EXITED', { mapInstanceId });
    }

    /**
     * @param {object} param0
     * @param {import('../objects/LocationAnalysisItem').default} param0.locationAnalysisItem
     * @param {string} param0.mapInstanceId
     */
    onAddToLocationAnalysisItems = ({ locationAnalysisItem, mapInstanceId }) => {
        const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);
        // Check if locationAnalysisItem is already added
        if (mapInstance.locationAnalysisItems.some(item => item.id === locationAnalysisItem.id)) {
            return;
        }

        mapInstance.addLocationAnalysisItem(locationAnalysisItem);
        this.bus.emit('LOCATION_ANALYSIS_ITEMS', mapInstance.locationAnalysisItems);
    };

    /**
     * @param {object} param0
     * @param {import('../objects/LocationAnalysisItem').default} param0.locationAnalysisItem
     * @param {string} param0.mapInstanceId
     */
    onRemoveFromLocationAnalysisItems = ({ locationAnalysisItem, mapInstanceId }) => {
        const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);
        mapInstance.removeLocationAnalysisItem(locationAnalysisItem);
        this.bus.emit('LOCATION_ANALYSIS_ITEMS', mapInstance.locationAnalysisItems);
    };

    onLocationAnalysisItemsRequest = () => {
        // Since this feature is only available for one map instance, we will use the default
        const mapInstance = this.projectDataSource.defaultMapInstance;
        this.bus.emit('LOCATION_ANALYSIS_ITEMS', mapInstance.locationAnalysisItems);
    };

    /**
     * Set analysis type for the active location analysis item
     * @param {string} mapInstanceId
     * @param {string} analysisTypeId
     */
    setActiveLocationAnalysisItemType = (mapInstanceId, analysisTypeId) => {
        const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);

        if (!mapInstance.locationAnalysisItem) return;

        if (analysisTypeId === undefined) {
            mapInstance.locationAnalysisItem.selection = new Set();
        }

        const previousAnalysisTypeId = mapInstance.locationAnalysisItem.analysisTypeId;
        mapInstance.locationAnalysisItem.analysisTypeId = analysisTypeId;

        if (previousAnalysisTypeId !== mapInstance.locationAnalysisItem.analysisTypeId) {
            // Update active location analysis item selection
            const selection = new Set([
                mapInstance.locationAnalysisItem.analysisType.VALUES_ARRAY[0],
            ]);
            this.setActiveLocationAnalysisItemSelection(mapInstanceId, selection);
        }

        this.bus.emit('LOCATION_ANALYSIS_ITEM_UPDATED', {
            mapInstanceId,
            locationAnalysisItem: mapInstance.locationAnalysisItem,
        });
    };

    /**
     * Set selection for the active location analysis item
     * @param {string} mapInstanceId
     * @param {Set<number>} selection
     */
    setActiveLocationAnalysisItemSelection = (mapInstanceId, selection) => {
        const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);

        if (!mapInstance.locationAnalysisItem) return;

        switch (mapInstance.locationAnalysisItem.analysisTypeId) {
            case 'RADIUS':
            case 'DRIVING_TIME':
            case 'CYCLING_TIME':
            case 'WALKING_TIME':
                this.bus.emit('CUSTOM_MAP_SELECTION_CONTOUR_REQUEST', {
                    mapInstanceId: mapInstance.id,
                    selection,
                });
                break;
            default:
            // do nothing
        }

        // In case of "empty" location analysis (location without selection parameters
        // like drive times or radius), we should still re-center the map to point to
        // the location coordinates. This situation happens when user first perform
        // geo-search, opening the location panel without starting the analysis and
        // than pick any saved location. In that moment, we have a point but no selection
        if (selection.size === 0 && mapInstance.locationAnalysisItem.point) {
            this.bus.emit('MAP_SET_CENTER_REQUEST', {
                mapInstanceId,
                center: mapInstance.locationAnalysisItem.point,
            });
        }

        mapInstance.locationAnalysisItem.selection = selection;

        this.bus.emit('LOCATION_ANALYSIS_ITEM_UPDATED', {
            mapInstanceId,
            locationAnalysisItem: mapInstance.locationAnalysisItem,
        });
    };

    /**
     * Set analysis type for the location analysis items from the compare list
     * @param {string} mapInstanceId
     * @param {string} analysisTypeId
     */
    setCompareLocationAnalysisItemsType = (mapInstanceId, analysisTypeId) => {
        const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);

        if (!mapInstance.locationAnalysisItems.length) return;

        const firstComparedItem = mapInstance.locationAnalysisItems[0];

        const previousAnalysisTypeId = firstComparedItem.analysisTypeId;

        mapInstance.locationAnalysisItems.forEach(locationAnalysisItem => {
            locationAnalysisItem.analysisTypeId = analysisTypeId;
        });

        if (previousAnalysisTypeId !== analysisTypeId) {
            // Update compare location analysis items selection
            const selection = new Set([firstComparedItem.analysisType.VALUES_ARRAY[0]]);

            this.setCompareLocationAnalysisItemsSelection(mapInstance.id, selection);
        }

        this.bus.emit('LOCATION_ANALYSIS_ITEMS', mapInstance.locationAnalysisItems);
    };

    /**
     * Set selection for the location analysis items from the compare list
     * @param {string} mapInstanceId
     * @param {Set<number>} selection
     */
    setCompareLocationAnalysisItemsSelection = (mapInstanceId, selection) => {
        const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);

        if (!mapInstance.locationAnalysisItems.length) return;

        mapInstance.locationAnalysisItems.forEach(locationAnalysisItem => {
            locationAnalysisItem.selection = selection;
        });

        this.bus.emit('LOCATION_ANALYSIS_ITEMS', mapInstance.locationAnalysisItems);
    };

    /**
     * Set analysis type for the active location analysis item and location analysis
     * items in the compare list
     * @param {object} payload
     * @param {string} payload.mapInstanceId
     * @param {string} payload.analysisTypeId
     */
    onSetLocationAnalysisType({ mapInstanceId, analysisTypeId }) {
        this.setActiveLocationAnalysisItemType(mapInstanceId, analysisTypeId);
        this.setCompareLocationAnalysisItemsType(mapInstanceId, analysisTypeId);
    }

    /**
     * Set selection for the active location analysis item and location analysis
     * items in the compare list
     * @param {object} payload
     * @param {string} payload.mapInstanceId
     * @param {Set<number>} payload.selection
     */
    onSetLocationAnalysisSelection = ({ mapInstanceId, selection }) => {
        this.setActiveLocationAnalysisItemSelection(mapInstanceId, selection);
        this.setCompareLocationAnalysisItemsSelection(mapInstanceId, selection);
    };

    onCustomLocationAnalysisValuesRequest() {
        const { customLocationAnalysisValues } = this.metadataDataSource;
        this.bus.emit('CUSTOM_LOCATION_ANALYSIS_VALUES', {
            customLocationAnalysisValues,
        });
    }

    /** @param {{value: number; analysisTypeUnit: import('../').LocationAnalysisTypeUnit}} payload */
    onSaveCustomLocationAnalysisValue({ value, analysisTypeUnit }) {
        this.metadataDataSource.addCustomLocationAnalysisValue(value, analysisTypeUnit);
    }

    onResetCustomLocationAnalysisValues() {
        this.metadataDataSource.resetCustomLocationAnalysisValues();
    }

    /**
     * @param {object} param0
     * @param {string[]} param0.surveyNames
     */
    async onSurveysRequest({ surveyNames }) {
        // First, let's load all necessary maps
        const calls = surveyNames
            .filter(surveyName => !this.mapDataSource.currentMaps[surveyName])
            .map(surveyName =>
                this.mapDataSource.loadMapByURL(
                    `${AppConfig.constants.mapsURL}/${surveyName}.json`,
                ),
            );
        await Promise.all(calls);

        this.metadataDataSource.loadSurveys(surveyNames.join(',')).then(surveys => {
            this.bus.emit('CUSTOM_MAP_SELECTION_SURVEYS', {
                surveys,
            });
        });
    }

    /**
     * Used to create a list on feature report tab
     */
    onFeaturedReportsRequest() {
        // If there are no featured reports just return an empty array
        if (FEATURED_REPORTS.length === 0) {
            this.bus.emit('CUSTOM_MAP_SELECTION_FEATURED_REPORTS', []);
            return;
        }

        // we need to find surveyNames in featured reports (if any), so we can retrieve their metadata and assemble the
        // featuredReports response
        const surveyNames = FEATURED_REPORTS.filter(
            featuredReport => featuredReport.source === 'survey',
        ).map(featuredSurveyReport => featuredSurveyReport.properties.surveyName);

        if (surveyNames.length === 0) {
            this.onFeaturedReportsMetaReady();
        } else {
            this.metadataDataSource
                .loadPremadeSurveyReports(surveyNames)
                .then(reportsForSurveys => this.onFeaturedReportsMetaReady(reportsForSurveys));
        }
    }

    onFeaturedReportsMetaReady(reportsForSurveys) {
        /** @type {Array.<import('../types').FeaturedSurveyReport | import('../types').FeaturedOlapReport>} */
        const featuredReports = FEATURED_REPORTS.flatMap(featuredReport => {
            switch (featuredReport.source) {
                case 'olap': {
                    return {
                        source: featuredReport.source,
                        id: featuredReport.properties.id,
                        name: featuredReport.properties.name,
                    };
                }
                case 'survey': {
                    const reportIdsToShow = featuredReport.properties.reportIdsToShow;
                    // surveyReports are provided in-order as they are specified in featuredReports (where source=survey)
                    return reportsForSurveys
                        .shift()
                        .filter(surveyReport => {
                            // discard unwanted report ids
                            if (reportIdsToShow) {
                                return reportIdsToShow.includes(surveyReport.id);
                            }
                            return true;
                        })
                        .map(surveyReport => ({
                            source: featuredReport.source,
                            name: surveyReport.name,
                            // TODO: Find out the best way to implement this
                            // this is survey code of the survey that will be used to get the min summary level
                            // in order to switch the map to that view. For PacComm use case
                            // this was selected to be PACCOMDEM2019, because we used it's block
                            // groups summary level. Opportunities reports are an exception.
                            // In ideal implementation that should be selected either by the user
                            // or read from the current map instance
                            surveyCode: featuredReport.properties.surveyName,
                            reportTopicId: surveyReport.id.toString(),
                            aggregationType: featuredReport.properties.aggregationType,
                            downloadReport: featuredReport.properties.downloadReport,
                        }));
                }
                default:
                    // should not happen
                    throw new Error(
                        `Loading featured report of unknown source '${featuredReport.source}'`,
                    );
            }
        });

        this.bus.emit('CUSTOM_MAP_SELECTION_FEATURED_REPORTS', featuredReports);
    }

    /**
     * @param {object} payload
     * @param {import('../objects/MapInstance').default} payload.mapInstance
     */
    async onCustomMapSelectionReportInitialInfoRequest(payload) {
        const { mapInstance } = payload;
        // Let's make sure surveys metadata is loaded
        const currentGroupMetadata = this.metadataDataSource.groupsMetadata.find(
            gm => gm.id === mapInstance.metadataGroupId,
        );
        const surveysNames = currentGroupMetadata.base_maps_ids.join(',');
        await this.metadataDataSource.loadSurveys(surveysNames);

        // Get the selected
        const surveys = this.metadataDataSource.getSurveysForMapsGroup(mapInstance.metadataGroupId);

        const selectedSurvey = surveys.find(survey => survey.name === mapInstance.surveyName);

        if (!selectedSurvey) return;

        const surveyAvailableForReporting = selectedSurvey.isAvailableForReporting(
            this.metadataDataSource.surveyGroups,
        );

        // if survey is not available for reporting, there is no need to load
        // templates and report topics
        if (!surveyAvailableForReporting) {
            this.bus.emit('CUSTOM_MAP_SELECTION_INITIAL_INFO', {
                selectedSurvey,
                surveyAvailableForReporting,
                reportTopics: [],
            });
            return;
        }

        /** @type {import('../objects/MetaReport').default[]} */
        this._premadeReports = Object.values(selectedSurvey.reports);
        // Filter out report ids that need to be hidden from the location analysis reports
        const featuredSurveyReport = FEATURED_REPORTS.find(
            featuredReport =>
                featuredReport.source === 'survey' &&
                featuredReport.properties.surveyName === selectedSurvey.name,
        );
        if (featuredSurveyReport && featuredSurveyReport.properties.reportIdsToHide) {
            this._premadeReports = this._premadeReports.filter(
                report =>
                    !featuredSurveyReport.properties.reportIdsToHide.includes(
                        parseInt(report.id, 10),
                    ),
            );
        }

        // Get the initial report topics for the selected survey and
        const reportTopics = this.fetchAvailableReportTopics();

        if (!this.reportTableTemplateDataSource.areTableTemplatesLoaded()) {
            // Load templates for selected survey and then emit the success event.
            // That way, after the event, controllers which are bound to the event will
            // know what is the current selectedSurvey.
            this.reportTableTemplateDataSource
                .loadTemplates()
                .then(() => {
                    this.bus.emit('CUSTOM_MAP_SELECTION_INITIAL_INFO', {
                        selectedSurvey,
                        surveyAvailableForReporting,
                        reportTopics,
                    });
                })
                .catch(error => this.bus.emit('CURRENT_REPORT_ERROR', { error }));
        } else {
            this.bus.emit('CUSTOM_MAP_SELECTION_INITIAL_INFO', {
                selectedSurvey,
                surveyAvailableForReporting,
                reportTopics,
            });
        }
    }

    /**
     * @param {object} payload
     * @param {import('../objects/MapInstance').default} payload.mapInstance
     */
    onCustomMapSelectionReportConstructionInfoRequest(payload) {
        const { mapInstance } = payload;
        const surveys = this.metadataDataSource.getSurveysForMapsGroup(mapInstance.metadataGroupId);
        /** @type {import('../objects/MetaSurvey').default[]} */
        const compareSurveysList = [];

        const selectedSurvey = surveys.find(survey => survey.name === mapInstance.surveyName);

        this.metadataDataSource.loadSurveyGroups().then(surveyGroups => {
            // Find the survey group for current survey
            const surveyGroup = surveyGroups.find(s =>
                s.dataSets.some(ds => ds.code === selectedSurvey.name),
            );

            let availableSurveys = [];
            if (surveyGroup) {
                // Find the data set for selected survey
                const selectedSurveyDataSet = surveyGroup.dataSets.find(
                    ds => ds.code === selectedSurvey.name,
                );

                // If selectedSurveyDataSet has a comparabilityGroup set, it
                // means it is available for comparison
                if (selectedSurveyDataSet.comparabilityGroup) {
                    // Filter only the enabled datasets and compatible datasets ( that have the same comparabilityGroup )
                    // and select only code
                    const surveyGroupDataSets = surveyGroup.dataSets
                        .filter(
                            ds =>
                                ds.enabled &&
                                ds.availableInReports &&
                                ds.comparabilityGroup === selectedSurveyDataSet.comparabilityGroup,
                        )
                        .map(ds => ds.code);

                    // Now filter the surveys that belong to the dataSets found in previous step
                    availableSurveys = surveys
                        .filter(
                            s =>
                                surveyGroupDataSets.findIndex(ds => ds === s.name) !== -1 &&
                                Object.keys(s.reports).length,
                        )
                        .sort((a, b) => a.year - b.year);
                }
            }

            // When COT is active, preselect current selection
            if (mapInstance.dataTheme.isChangeOverTimeApplied) {
                const compareSelection =
                    mapInstance.dataTheme.appliedChangeOverTimeCompareSelection;
                const compareSurvey = availableSurveys.find(
                    survey => survey.name === compareSelection.surveyName,
                );
                if (compareSurvey) {
                    compareSurveysList.push(compareSurvey);
                }
            }

            const reportTopics = this.fetchAvailableReportTopics(compareSurveysList);

            this.bus.emit('CUSTOM_MAP_SELECTION_REPORT_CONSTRUCTION_INFO', {
                reportTopics,
                availableSurveys,
                compareSurveysList,
            });
        });
    }

    /**
     * On adding a new survey to we must filter the reports so that only matching reports are available
     * @param {object} payload
     * @param {import('../objects/MetaSurvey').default[]} payload.compareSurveysList
     */
    onUpdateCompareSurveyList(payload) {
        const { compareSurveysList } = payload;
        const reportTopics = this.fetchAvailableReportTopics(compareSurveysList);
        this.bus.emit('CUSTOM_MAP_SELECTION_REPORT_TOPICS', { reportTopics });
    }

    /**
     * @param {import('../objects/MetaSurvey').default[]} compareSurveysList
     */
    fetchAvailableReportTopics = (compareSurveysList = []) => {
        if (compareSurveysList.length === 0) {
            return this._premadeReports.map(report => ({
                id: report.id,
                text: report.name,
            }));
        }

        // The initial list of premade reports
        let filteredReports = this._premadeReports;

        compareSurveysList.forEach(survey => {
            // 1 Get survey reports and select only name
            /** @type {string[]} */
            const surveyReports = Object.values(survey.reports).map(r => r.name);
            // 2. Remove any report that is not also in this list
            const surveyReportsSet = new Set(surveyReports);
            filteredReports = filteredReports.filter(r => surveyReportsSet.has(r.name));
        });

        const reportTopics = filteredReports.map(report => ({
            id: report.id,
            text: report.name,
        }));
        return reportTopics;
    };

    /**
     * CONTOUR SELECTION: radiuses and isochrones
     * @param {import('../').LocationAnalysisContourRequest} payload
     */
    onCustomMapSelectionContourRequest({ mapInstanceId, selection }) {
        const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);
        const { locationAnalysisItem } = mapInstance;

        this.customMapSelectionDataSource
            .getContourFeatures(
                locationAnalysisItem.point,
                locationAnalysisItem.contourType,
                Array.from(selection),
                true,
            )
            .then(locationContourData => {
                locationAnalysisItem.selection = selection;
                locationAnalysisItem.sourceData = locationContourData;
                this.bus.emit('CUSTOM_MAP_SELECTION_SOURCE_DATA', {
                    isIsochrone: locationAnalysisItem.isIsochrone,
                    feature: locationAnalysisItem.selectionFeatures,
                    labels: locationAnalysisItem.polygonLabelFeatures,
                    mapInstanceId,
                });
            });
    }

    /** @param {number} numberOfSelectedGeographies */
    doesSelectedGeoNoExceedLimit = numberOfSelectedGeographies =>
        numberOfSelectedGeographies > CREATE_REPORT_GEOGRAPHIES_LIMIT;

    emitGeoExceedsLimit() {
        this.bus.emit('CUSTOM_ANALYSIS_LIMIT_POPUP_REQUEST', {
            geographiesLimit: CREATE_REPORT_GEOGRAPHIES_LIMIT,
        });
        this.bus.emit('CREATE_REPORT_ABORTED_WITH_WARNING');
    }

    /**
     *
     * @param {import('../types').ReportTemplateTableGuidsBySurveyName[]} selectedTableGuidsBySurveyName
     * @param {import('../objects/MetaSurvey').default[]} selectedSurveys
     * @param {import('../types').ReportTopic} selectedReportTopic
     *
     * @returns {Promise<Object<string, string[]>>}
     */
    getSelectedReportTopicTableGuidsBySurvey = async (
        selectedTableGuidsBySurveyName,
        selectedSurveys,
        selectedReportTopic,
    ) => {
        /** @type {Object<string, string[]>} */
        const selectedReportTopicTableGuidsBySurvey = {};
        // If template, we already have the  necessary report table guids
        if (selectedTableGuidsBySurveyName) {
            selectedTableGuidsBySurveyName.forEach(guidsRecord => {
                Object.keys(guidsRecord).forEach(surveyName => {
                    if (selectedReportTopicTableGuidsBySurvey[surveyName]) {
                        selectedReportTopicTableGuidsBySurvey[surveyName].push(
                            guidsRecord[surveyName],
                        );
                    } else {
                        selectedReportTopicTableGuidsBySurvey[surveyName] = [
                            guidsRecord[surveyName],
                        ];
                    }
                });
            });
        } else {
            // Let's fetch the necessary report table guids based on selected report topic
            const systemReportCalls = [];
            selectedSurveys.forEach(survey => {
                let premadeReport = survey.reports[selectedReportTopic.id];
                // in case we selected compare surveys we have to search the
                // report topic by name, since their IDs are not the same
                if (!premadeReport) {
                    const surveyReports = Object.values(survey.reports);
                    // Find the report topic for survey by name
                    premadeReport = surveyReports.find(r => r.name === selectedReportTopic.text);
                }

                systemReportCalls.push(
                    this.metadataDataSource.loadSystemReport(survey.name, premadeReport.id),
                );
            });

            // Wait for the system reports fetch in order to get the table guids
            const results = await Promise.all(systemReportCalls);
            results.forEach(result => {
                selectedReportTopicTableGuidsBySurvey[result.surveyName] =
                    result.systemReport.tableGuids;
            });
        }

        return selectedReportTopicTableGuidsBySurvey;
    };

    /**
     * @param {object} param0
     * @param {string} param0.surveyCode
     * @param {import('../types').ReportTopic} param0.selectedReportTopic
     * @param {string} param0.mapInstanceId
     */
    onCustomMapSelectionDownloadPointReportRequest = async ({
        surveyCode,
        selectedReportTopic,
        mapInstanceId,
    }) => {
        const { systemReport } = await this.metadataDataSource.loadSystemReport(
            surveyCode,
            selectedReportTopic.id,
        );
        const tables = await this.metadataDataSource.loadTablesWithVariables(
            surveyCode,
            systemReport.tableGuids,
        );

        const columnsFromTiles = tables.flatMap(table =>
            Object.values(table.variables)
                .filter(variable => !variable.clientFunction)
                .map(variable => ({
                    columnName: variable.guid,
                    datasetAbbreviation: table.datasetAbbreviation,
                })),
        );

        const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);
        const { locationAnalysisItem } = mapInstance;
        const sourceDataFeatures = locationAnalysisItem.selectionFeatures.features;
        const analysisType = locationAnalysisItem.analysisType;

        const query = new QueryFromMap();

        const ds = await this._fetchDataSourceBySurveyCode(surveyCode, locationAnalysisItem._type);
        query.addGeoBufferSource(surveyCode, ds, false);
        query.addColumns(surveyCode, columnsFromTiles);

        await query.createMap();

        /** @type {{ [tableGuid: string]: object}[][]} */
        const dataForAllRings = [];
        /** @type {string[]} */
        const sheetNames = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const sourceDataFeature of sourceDataFeatures) {
            // eslint-disable-next-line no-await-in-loop
            const dataForOneRing = await query.fetchDataFromMap(surveyCode, sourceDataFeature);

            dataForAllRings.push(dataForOneRing);
            const time = sourceDataFeature.properties.value;
            sheetNames.push(`${analysisType.NAME} ${time} ${analysisType.UNIT}`);
        }
        query.destroyMap();
        // if every dataForOneRing was empty, that means we have no data in any
        // of the selected rings. In that case, show error msg and exit
        if (dataForAllRings.every(dataForOneRing => !dataForOneRing.length)) {
            this.bus.emit('DOWNLOAD_REPORT_ERROR', {
                message: 'Cannot download Excel report. No data found!',
            });
            return;
        }

        /** @type {import('../types').ClientValues} */
        const clientValues = {
            lat: locationAnalysisItem.point.lat,
            lng: locationAnalysisItem.point.lng,
            ringType: locationAnalysisItem.reportRingType,
            getTime,
        };

        const headers = tables.flatMap(table =>
            Object.values(table.variables).map(variable => variable.label),
        );

        // represents number of async calls we line up before we await them
        const batchSize = 12;

        /** @type {object[][][]} */
        const collectedData = [];
        /** @type {Promise[]} */
        let asyncCallsCollection = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const dataPart of dataForAllRings) {
            // eslint-disable-next-line no-restricted-syntax
            for (const rawRow of dataPart) {
                // eslint-disable-next-line no-restricted-syntax
                for (const table of tables) {
                    // eslint-disable-next-line no-restricted-syntax
                    for (const variable of Object.values(table.variables)) {
                        if (variable.clientFunction) {
                            asyncCallsCollection.push(
                                this._evaluateClientComputedValue(
                                    tables,
                                    variable,
                                    clientValues,
                                    rawRow,
                                ),
                            );
                            if (asyncCallsCollection.length > batchSize) {
                                // eslint-disable-next-line no-await-in-loop
                                await Promise.all(asyncCallsCollection);
                                asyncCallsCollection = [];
                            }
                        }
                    }
                }
            }
        }
        await Promise.all(asyncCallsCollection);

        // eslint-disable-next-line no-restricted-syntax
        for (const dataPart of dataForAllRings) {
            /** @type {object[][]} */
            const collectedDataPart = [];
            collectedDataPart.push(headers);
            // eslint-disable-next-line no-restricted-syntax
            for (const rawRow of dataPart) {
                /** @type {object[]} */
                const collectedRow = [];
                // eslint-disable-next-line no-restricted-syntax
                for (const table of tables) {
                    // eslint-disable-next-line no-restricted-syntax
                    for (const variable of Object.values(table.variables)) {
                        collectedRow.push(rawRow[variable.guid]);
                    }
                }
                collectedDataPart.push(collectedRow);
            }
            collectedData.push(collectedDataPart);
        }
        // map data to sheets
        /** @type {import('../').XlsxSheetDefinition[]} */
        const sheets = [];
        collectedData.forEach((dataPart, index) => {
            sheets.push({ data: dataPart, name: sheetNames[index] });
        });

        dataToXlsx(sheets, selectedReportTopic.text);
        this.bus.emit('CREATE_REPORT_SUCCESS');
    };

    /**
     *
     * @param {import('../types').Table[]} tables
     * @param {import('../types').Variable} variable
     * @param {import('../types').ClientValues} clientValues
     * @param {{ [tableGuid: string]: object }} rawRow
     */
    async _evaluateClientComputedValue(tables, variable, clientValues, rawRow) {
        const getValue = generateGetValue(rawRow, tables);
        const clientFunction = generateClientFunction(variable.clientFunction);
        let value = await clientFunction(getValue, clientValues);
        // we will round values calculated from client function. For real styling
        // of cells in excel, we will need to use different library or pay for xlsx
        if (typeof value === 'number' && value !== Math.floor(value)) {
            value = Number(value.toFixed(2));
        }
        rawRow[variable.guid] = value;
    }

    /**
     * Based on surveyCode, selects DataSource.
     *
     * First look into library.json if there is layer with that surveyCode. If so,
     * it will retrieve DataSource from report metadata.
     *
     * Otherwise, it will look into map json files. If file is not present, then
     * it will fetch it first (that is why this this method is async). Then,
     * based on reportMinSummaryLevel property, it will select appropriate
     * DataSource
     * @param {string} surveyCode
     * @returns {Promise<import('../objects/DataSource').default>}
     */
    _fetchDataSourceBySurveyCode = async (surveyCode, locationAnalysisType) => {
        // first check survey code is defined in library.json
        const libraryGroup = this.metadataDataSource.libraryData.groups.find(
            group =>
                group.metadata.report &&
                group.metadata.report.surveyCode === surveyCode &&
                group.metadata.report.dataSourceLayer,
        );
        if (libraryGroup) {
            const dsJSON = libraryGroup.metadata.report.dataSourceLayer;
            return MapParser.parseDataSource(dsJSON, surveyCode);
        }
        if (!this.mapDataSource.currentMaps[surveyCode]) {
            await this.mapDataSource.loadMapByURL(
                `${AppConfig.constants.mapsURL}/${surveyCode}.json`,
            );
        }
        const surveyMap = this.mapDataSource.currentMaps[surveyCode];
        return Object.values(surveyMap.dataSources).find(
            ds => ds.summaryLevel.id === surveyMap.reportMinSummaryLevel,
        );
    };

    /**
     * Handle report creation request
     * For each specified survey perform querying of rendered features over appropriate geography
     * @param {object} param0
     * @param {import('../types').ReportTopic} param0.selectedReportTopic
     * @param {import('../objects/MetaSurvey').default[]} param0.selectedSurveys
     * @param {string} param0.selectedFeaturedReportSurveyCode,
     * @param {string} param0.analysisTypeTitle
     * @param {number} [param0.defaultAggregationType]
     * @param {import('../types').ReportTemplateTableGuidsBySurveyName[]} [param0.selectedTableGuidsBySurveyName]
     * @param {string} param0.mapInstanceId
     * @param {string[]} param0.selectedSummaryLevels
     */
    async onCustomMapSelectionCreateReportRequest({
        selectedReportTopic,
        selectedSurveys,
        selectedFeaturedReportSurveyCode,
        analysisTypeTitle,
        defaultAggregationType,
        selectedTableGuidsBySurveyName,
        mapInstanceId,
        selectedSummaryLevels,
    }) {
        // First sort surveys by year
        selectedSurveys.sort((a, b) => a.year - b.year);

        const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);
        const { locationAnalysisItem } = mapInstance;

        const calls = selectedSurveys.map(survey =>
            this._fetchDataSourceBySurveyCode(survey.name, locationAnalysisItem._type),
        );
        const dataSources = await Promise.all(calls);
        let useGeoJsonPoints = false;
        let SLCode = '';
        let sourceID;

        /** @type {import('../types').DefinitionGeoGroup[]} */
        const geoGroups = [];
        const query = new QueryFromMap();
        for (let index = 0; index < selectedSurveys.length; index += 1) {
            const surveyCode = selectedSurveys[index].name;
            const selectedFeaturedSurveyReport = FEATURED_REPORTS.find(
                featuredReport =>
                    featuredReport.source === 'survey' &&
                    featuredReport.properties.surveyName === surveyCode,
            );
            query.addGeoBufferSource(surveyCode, dataSources[index], true);
            query.addColumns(surveyCode, query.getGeoColumnNames(surveyCode));
            useGeoJsonPoints =
                selectedFeaturedSurveyReport &&
                selectedFeaturedSurveyReport.properties.useGeoJsonPoints;
            SLCode = selectedFeaturedSurveyReport && selectedFeaturedSurveyReport.properties.SLCode;
            if (useGeoJsonPoints) {
                const metadata = this.pointsDataSource.pointsMetadata;
                sourceID = metadata.mapProperties.sourceId;
                const geoJson = this.pointsDataSource.pointsGeoJson;
                const geoJsonSource = {
                    sourceID,
                    source: {
                        type: 'geojson',
                        data: geoJson,
                    },
                    layerId: metadata.mapProperties.layerId,
                };
                const columns = Object.keys(metadata.properties).map(propertyKey => ({
                    columnName: propertyKey,
                }));
                query.addGeoJsonColumns(sourceID, columns);
                query.addGeoJsonSource(sourceID, geoJsonSource, false);
            }
            const pointDataSurveys =
                selectedFeaturedSurveyReport &&
                selectedFeaturedSurveyReport.properties.pointDataSurveys;
            if (pointDataSurveys) {
                for (let i = 0; i < pointDataSurveys.length; i += 1) {
                    const pointDataSurveyCode = pointDataSurveys[i];
                    // this will not trigger fetch, data will be retrieved from
                    // library.json, so in practice there will be no async call
                    // eslint-disable-next-line no-await-in-loop
                    const ds = await this._fetchDataSourceBySurveyCode(
                        pointDataSurveyCode,
                        locationAnalysisItem._type,
                    );
                    query.addGeoBufferSource(pointDataSurveyCode, ds, false);
                    query.addColumns(
                        pointDataSurveyCode,
                        query.getGeoColumnNames(pointDataSurveyCode),
                    );
                }
            }
            if (selectedSummaryLevels) {
                const surveyDataSources = this.mapDataSource.currentMaps[surveyCode].dataSources;
                selectedSummaryLevels.forEach(contextSL => {
                    const source = Object.values(surveyDataSources).find(
                        ds => ds.summaryLevel.id === contextSL,
                    );
                    query.addGeoBufferSource(`${surveyCode}-${contextSL}`, source, true);
                    query.addColumns(
                        `${surveyCode}-${contextSL}`,
                        query.getGeoColumnNames(`${surveyCode}-${contextSL}`),
                    );
                });
            }
        }

        await query.createMap();

        const ringPolygons = locationAnalysisItem.selectionFeatures.features;
        // Figure out table guids based on the selected report topic and selected surveys
        const selectedReportTopicTableGuidsBySurvey =
            await this.getSelectedReportTopicTableGuidsBySurvey(
                selectedTableGuidsBySurveyName,
                selectedSurveys,
                selectedReportTopic,
            );

        // geosCounter keeps track of number of selected geographies.
        // If total number of selected geographies (sum of selected rings)
        // exceeds the limit, we won't create the report
        let geosCounter = 0;
        // we need to use for-of because of await
        // eslint-disable-next-line no-restricted-syntax
        for (const ringPolygon of ringPolygons) {
            /** @type {import('../types').DefinitionColumnGroup[]} */
            const columnGroups = [];
            // eslint-disable-next-line no-restricted-syntax
            for (const survey of selectedSurveys) {
                const surveyCode = survey.name;
                // eslint-disable-next-line no-await-in-loop
                let geoFips = await query.getGeoFipsForSurvey(surveyCode, ringPolygon);
                let geoFipsGeoJson;
                if (useGeoJsonPoints) {
                    // eslint-disable-next-line no-await-in-loop
                    geoFipsGeoJson = await query.fetchGeoJsonDataFromMap(sourceID, ringPolygon);
                }
                const featuredSurveyReport = FEATURED_REPORTS.find(
                    featuredReport =>
                        featuredReport.source === 'survey' &&
                        featuredReport.properties.surveyName === surveyCode,
                );

                if (featuredSurveyReport && featuredSurveyReport.properties.pointDataSurveys) {
                    // eslint-disable-next-line no-restricted-syntax
                    for (const pointDataSurveyName of featuredSurveyReport.properties
                        .pointDataSurveys) {
                        geoFips = [
                            ...geoFips,
                            // eslint-disable-next-line no-await-in-loop
                            ...(await query.getGeoFipsForSurvey(pointDataSurveyName, ringPolygon)),
                        ];
                    }
                }

                geoFips = Array.from(new Set(geoFips));
                if (useGeoJsonPoints && SLCode) {
                    const geoFipsCodes = geoFipsGeoJson.map(
                        el => `${el.facility_id};${SLCode};${el.name}`,
                    );
                    const geoFipsFromGeojson = Array.from(new Set(geoFipsCodes));
                    geoFips = [...geoFips, ...geoFipsFromGeojson];
                }
                geosCounter += geoFips.length;
                if (this.doesSelectedGeoNoExceedLimit(geosCounter)) {
                    this.emitGeoExceedsLimit();
                    return;
                }

                if (geoFips.length) {
                    columnGroups.push({
                        name: survey.year.toString(),
                        tables: [
                            {
                                surveyCode,
                                geoFips,
                                tableGuids: selectedReportTopicTableGuidsBySurvey[surveyCode],
                            },
                        ],
                    });
                }
            }

            const radius = ringPolygon.properties.value;
            if (columnGroups.length) {
                geoGroups.push({
                    name: `${radius} ${analysisTypeTitle}`,
                    columnGroups,
                    aggregationType: defaultAggregationType,
                });
            }
        }

        // Get the higher geo level config for selected featured report survey if it exists
        let higherGeoLevelReportInfo;
        if (selectedFeaturedReportSurveyCode) {
            const selectedFeaturedSurveyReport = FEATURED_REPORTS.find(
                featuredReport =>
                    featuredReport.source === 'survey' &&
                    featuredReport.properties.surveyName === selectedFeaturedReportSurveyCode,
            );
            higherGeoLevelReportInfo = selectedFeaturedSurveyReport.properties.higherGeoLevelReport;
        }
        // If it not defined use the same GUID and aggregation type as on the radius analysis.
        let higherGeoLevelReportTableGuidsBySurvey = selectedReportTopicTableGuidsBySurvey;
        let higherGeoLevelReportAggregationType = DEFAULT_LOCATION_REPORT_AGGREGATION_TYPE;
        if (higherGeoLevelReportInfo) {
            // get the corresponding report information
            const higherGeoLevelReport = higherGeoLevelReportInfo[selectedReportTopic.id];
            // Get the table guids
            higherGeoLevelReportTableGuidsBySurvey =
                await this.getSelectedReportTopicTableGuidsBySurvey(
                    selectedTableGuidsBySurveyName,
                    selectedSurveys,
                    {
                        text: selectedReportTopic.text,
                        id: higherGeoLevelReport.reportId.toString(),
                    },
                );

            // override aggregation type
            higherGeoLevelReportAggregationType = higherGeoLevelReport.aggregationType;
        }

        // ADD HIGHER LEVEL GEOGRAPHIES SECTION TO REPORT DEFINITION IF SELECTED
        // eslint-disable-next-line no-restricted-syntax
        for (const contextSL of selectedSummaryLevels) {
            const columnGroups = [];
            const geoName = [];
            // eslint-disable-next-line no-restricted-syntax
            for (const survey of selectedSurveys) {
                const surveyCode = survey.name;
                // eslint-disable-next-line no-await-in-loop
                const geoFips = await query.getGeosAtPointForSummaryLevel(
                    `${surveyCode}-${contextSL}`,
                    locationAnalysisItem.point,
                );
                geoName.push(...geoFips.map(fips => fips.split(';')[2]));
                columnGroups.push({
                    name: survey.year.toString(),
                    tables: [
                        {
                            surveyCode,
                            geoFips: [...geoFips],
                            tableGuids: higherGeoLevelReportTableGuidsBySurvey[surveyCode],
                        },
                    ],
                });
            }

            geoGroups.push({
                name: Array.from(new Set(geoName))[0],
                columnGroups,
                aggregationType: higherGeoLevelReportAggregationType,
            });
        }

        // CLEAN UP - release the resources
        query.destroyMap();

        // FINALLY - create the report
        this.createReport({
            geoGroups,
            center: locationAnalysisItem.point,
            ringType: locationAnalysisItem.reportRingType,
        });
    }

    createReport = ({ geoGroups, center, ringType }) => {
        if (!geoGroups.length) {
            this.bus.emit('CREATE_REPORT_ERROR', {
                message: 'No data found!',
            });
        } else {
            this.bus.emit('CREATE_REPORT', {
                geoGroups,
                center,
                ringType,
            });
        }
    };

    onDeactivate() {
        this.unbindGluBusEvents();
    }
}

export default CustomMapSelectionController;

/**
 * @typedef SourceLayerDataset
 * @property {string|number} datasetId
 * @property {string[]} columns
 *
 * @typedef SourceLayer
 * @property {string} layerId
 * @property {SourceLayerDataset[]} datasets
 *
 *
 */
