import React from 'react';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';

import BusComponent from '../BusComponent';

import Popover from '../popover/Popover';

const MARGIN_LEFT = 12;

class ResetCustomValuesInfoPopover extends BusComponent {
    constructor(props) {
        super(props);
        this.state = { open: false };
    }

    onToggle = () => {
        this.setState(prevState => ({ open: !prevState.open }));
    };

    onSendFeedback = () => {
        this.emit('FEEDBACK_POPUP_REQUEST');
    }

    render() {
        const { open } = this.state;
        const { intl } = this.props;
        return (
            <div className="flex-it">
                <button
                    ref={e => { this.trigger = e; }}
                    className={classNames('location-analysis__reset-custom-values-btn', 'material-icons',
                        { 'location-analysis__reset-custom-values-btn--active': open })}
                    onClick={this.onToggle}
                    aria-label={intl.formatMessage({ id: 'dataBrowser.resetCustomAnalysisValues.help' })}
                >
                    help_outline
                </button>
                {this.trigger && (
                    <Popover
                        anchorEl={this.trigger}
                        className="location-analysis__reset-custom-values-popover"
                        open={open}
                        marginLeft={MARGIN_LEFT}
                        triggerToggle
                        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                        targetOrigin={{ horizontal: 'left', vertical: 'top' }}
                        onCloseRequest={this.onToggle}
                    >
                        <div className="location-analysis__reset-custom-values-popover__container">
                            <p className="location-analysis__reset-custom-values-popover__label">
                                <FormattedMessage
                                    id="dataBrowser.resetCustomAnalysisValues.description"
                                />
                            </p>
                            <p className="location-analysis__reset-custom-values-popover__label">
                                <FormattedMessage
                                    id="dataBrowser.resetCustomAnalysisValues.sendFeedback"
                                />
                                <button
                                    className="location-analysis__reset-custom-values-popover__feedback-button"
                                    onClick={this.onSendFeedback}
                                >
                                    {intl.formatMessage({ id: 'dataBrowser.resetCustomAnalysisValues.sendFeedbackButton' })}
                                </button>
                            </p>
                        </div>
                    </Popover>
                )}
            </div>
        );
    }
}

export default injectIntl(ResetCustomValuesInfoPopover);
