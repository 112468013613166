import React from 'react';
import PropTypes from 'prop-types';

import ButtonMenu from '../../buttonMenu/ButtonMenu';
import CreateReportMenuItem from './CreateReportMenuItem';

const CreateReportButtonMenu = ({ leftMapInstance, rightMapInstance }) => (
    <ButtonMenu
        label="Create report"
        className="application-header__popup-wrapper"
        tabIndex="0"
        tooltip="Create report"
        triggerClassName="application-header__button btn-flat--light"
        icon={<i className="material-icons">assignment</i>}
    >
        <div className="material-menu">
            <div className="material-menu__heading">Create report for</div>
            <CreateReportMenuItem
                mapInstance={leftMapInstance}
                icon="socex-icon-export-left-map"
                labelId="map.leftMap"
            />
            <CreateReportMenuItem
                mapInstance={rightMapInstance}
                icon="socex-icon-export-right-map"
                labelId="map.rightMap"
            />
        </div>
    </ButtonMenu>
);

CreateReportButtonMenu.propTypes = {
    leftMapInstance: PropTypes.object.isRequired,
    rightMapInstance: PropTypes.object.isRequired,
};

export default CreateReportButtonMenu;
