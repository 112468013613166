import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'rc-switch';

class Toggle extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            checked: !!props.checked,
        };
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.checked !== !!nextProps.checked) {
            this.setState({ checked: !!nextProps.checked });
        }
    }

    onChange = () => {
        const checked = !this.state.checked;
        this.setState({ checked });
        if (this.props.onChange) {
            this.props.onChange(checked);
        }
    }

    render() {
        return (<Switch
            id={this.props.id}
            className={this.props.className}
            prefixCls="toggle"
            checked={this.state.checked}
            onChange={this.onChange}
            disabled={this.props.disabled}
            checkedChildren={(<span>{this.props.checkedLabel}</span>)}
            unCheckedChildren={(<span>{this.props.unCheckedLabel}</span>)}
        />);
    }
}

Toggle.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    checkedLabel: PropTypes.string,
    unCheckedLabel: PropTypes.string,
};

Toggle.defaultProps = {
    className: undefined,
    id: undefined,
    onChange: undefined,
    checked: false,
    disabled: false,
    checkedLabel: '',
    unCheckedLabel: '',
};

export default Toggle;
