//!



module.exports = function (map) {

    return {

        _attachFeaturesClickListener: function () {
            let featuresClickHandler;
            const clickWait = 300; // ms

            this.on('remove', () => {
                clearTimeout(featuresClickHandler);
            });


            this.on('dblclick', () => {
                if (featuresClickHandler) clearTimeout(featuresClickHandler);
            });

            this.on('click', (e) => {
                if (featuresClickHandler) clearTimeout(featuresClickHandler);
                featuresClickHandler = setTimeout(() => {
                    map.fire('featuresClick', {
                        features: map.queryRenderedFeatures(e.point)
                    });
                }, clickWait);
            });
        },

        // override
        queryRenderedFeatures: function (geometry, params) {
            if (!map.style) {
                return [];
            }
            params = params || {};

            // circle selection
            if (params.radius) {
                const x = Array.isArray(geometry) ? geometry[0] : geometry.x;
                const y = Array.isArray(geometry) ? geometry[1] : geometry.y;
                geometry = [[x - params.radius, y - params.radius],
                    [x + params.radius, y + params.radius]];
            }

            const nonBubbleLayersIds = getNonBubbleLayersIds(getVisibleLayersIds(params.layers));
            // Non-expanded search for non-bubble layers
            let features = map._queryRenderedFeaturesOld(geometry, {
                expanded: false,
                radius: params.radius,
                layers: nonBubbleLayersIds,
                filter: params.filter,
                inclusionRule: params.inclusionRule || 'INTERSECT',
            });

            // Expanded search for bubble layers
            const bubbleLayersIds = getBubbleLayersIds(getVisibleLayersIds(params.layers));
            if (bubbleLayersIds.length > 0) {
                const featuresExpanded = map._queryRenderedFeaturesOld(geometry, {
                    expanded: true,
                    radius: params.radius,
                    layers: bubbleLayersIds,
                    filter: params.filter,
                    inclusionRule: params.inclusionRule || 'INTERSECT',
                });
                features = getUniqueFeatures(features.concat(featuresExpanded));
            }
            return features;
        }

    };

    function getVisibleLayersIds(layersIds) {
        const visibleLayersIds = [];
        for (const layerId in map.style._layers)
            if (map.style._layers.hasOwnProperty(layerId))
                if (isVisibleLayerId(layerId))
                    visibleLayersIds.push(layerId);
        return arraysIntersection(visibleLayersIds, layersIds);
    }

    function getBubbleLayersIds(layersIds) {
        const visibleLayersIds = getVisibleLayersIds(layersIds);
        const bubbleLayersIds = visibleLayersIds.filter((visibleLayerId) => {
            return isBubbleLayerId(visibleLayerId);
        });
        return arraysIntersection(bubbleLayersIds, layersIds);
    }

    function getNonBubbleLayersIds(layersIds) {
        const visibleLayersIds = getVisibleLayersIds(layersIds);
        const nonBubbleLayersIds = visibleLayersIds.filter((visibleLayerId) => {
            return !isBubbleLayerId(visibleLayerId);
        });
        return arraysIntersection(nonBubbleLayersIds, layersIds);
    }

    function isVisibleLayerId(layerId) {
        const layer = map.style._layers[layerId];
        const isWithinMinZoom = layer.minzoom === undefined || layer.minzoom <= map.getZoom();
        const isWithinMaxZoom = layer.maxzoom === undefined || layer.maxzoom >= map.getZoom();
        const isVisible = layer.layout === undefined || layer.layout.visibility !== 'none';
        return isWithinMinZoom && isWithinMaxZoom && isVisible;
    }

    function isBubbleLayerId(layerId) {
        const layer = map.style._layers[layerId];
        return layer.type === 'bubble';
    }

    function arraysIntersection(arr1, arr2) {
        if (!arr1) return arr2;
        if (!arr2) return arr1;
        return arr1.filter((elem) => {
            return arr2.indexOf(elem) !== -1;
        });
    }

    // TODO remove this function when Zeljko's fixes the point layers returned from server (duplicates)
    function getUniqueFeatures(features) {
        const uniqueFeatures = [];
        const keys = {};
        for (let f = 0; f < features.length; f++) {
            const feature = features[f];
            const key = `${feature.layer.id}:${feature.id}`;
            if (keys[key]) continue;
            keys[key] = true;
            uniqueFeatures.push(feature);
        }
        return uniqueFeatures;
    }
};
