import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import BusComponent from '../../BusComponent';

import UserMapViewPopupMode from '../../../enums/UserMapViewPopupMode';
import TextInput from '../../form/TextInput';

class UserMapViewEditPopup extends BusComponent {
    constructor(props) {
        super(props);
        const { title, latitude, longitude, zoom } = this.props;

        this.state = {
            title,
            latitude,
            latitudeValid: true,
            longitude,
            longitudeValid: true,
            zoom,
            zoomValid: true,
        };
    }

    componentDidMount() {
        this.bindGluBusEvents({
            USER_MAP_VIEW_UPDATED: this.closeModal,
            USER_MAP_VIEW_SAVED: this.closeModal,
        });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    closeModal = () => {
        this.emit('CLOSE_MODAL');
    }


    onCancel = () => {
        this.emit('CLOSE_MODAL');
        this.emit('SHOW_USER_MAP_VIEW_LIST_POPUP');
    }

    onSave = () => {
        const { mapViewId, mapInstanceId, mode } = this.props;
        const { title, latitude, longitude, zoom } = this.state;

        const changes = {
            title,
            latitude: Math.round(parseFloat(latitude) * (10 ** 5)) / (10 ** 5),
            longitude: Math.round(parseFloat(longitude) * (10 ** 5)) / (10 ** 5),
            zoom: Math.round(parseFloat(zoom) * 10) / 10,
        };
        if (mode === UserMapViewPopupMode.EDIT) {
            this.emit('EDIT_USER_MAP_VIEW_REQUEST', { mapViewId, changes });
        } else {
            this.emit('SAVE_USER_MAP_VIEW_REQUEST', { mapInstanceId, changes });
        }
    }

    onTitleChanged = title => {
        this.setState({ title });
    }

    onLatitudeChanged = latitude => {
        this.setState({
            latitude,
            latitudeValid: latitude >= -90 && latitude <= 90,
        });
    }

    onLongitudeChanged = longitude => {
        this.setState({
            longitude,
            longitudeValid: longitude >= -180 && longitude <= 180,
        });
    }

    onZoomChanged = zoom => {
        this.setState({
            zoom,
            zoomValid: zoom >= 2 && zoom <= 20,
        });
    }

    render() {
        const { title, latitude, longitude, zoom, latitudeValid, longitudeValid, zoomValid } = this.state;

        // Checks title for no content and whitespace only content
        const hasEmptyTitle = title.length === 0 || title.match(/^ *$/) !== null;
        const saveButtonDisabled = !(latitudeValid && longitudeValid && zoomValid) || hasEmptyTitle;
        const saveButtonClasses = classNames('user-map-views__popup-save-button btn-raised', {
            disabled: saveButtonDisabled,
        });

        return (
            <div className="user-map-view__content">
                <div className="user-map-view__title">
                    <TextInput
                        ref={c => { this.titleInput = c; }}
                        initialFocus
                        value={title}
                        onChange={this.onTitleChanged}
                        label={this.props.intl.formatMessage({ id: 'title' })}
                        type="text"
                    />
                </div>
                <div className="user-map-view__settings">
                    <TextInput
                        value={latitude}
                        onChange={this.onLatitudeChanged}
                        label={this.props.intl.formatMessage({ id: 'map.latitude' })}
                    />
                    <TextInput
                        value={longitude}
                        onChange={this.onLongitudeChanged}
                        label={this.props.intl.formatMessage({ id: 'map.longitude' })}
                    />
                    <TextInput
                        value={zoom}
                        onChange={this.onZoomChanged}
                        label={this.props.intl.formatMessage({ id: 'map.zoom' })}
                    />
                </div>
                <div className="user-map-view__controls">
                    <button
                        className="btn-flat"
                        onClick={this.onCancel}
                    >
                        {this.props.intl.formatMessage({ id: 'cancel' })}
                    </button>
                    <button
                        className={saveButtonClasses}
                        disabled={saveButtonDisabled}
                        onClick={this.onSave}
                    >
                        {this.props.intl.formatMessage({ id: 'save' })}
                    </button>
                </div>
            </div>
        );
    }
}

UserMapViewEditPopup.propTypes = {
    mapInstanceId: PropTypes.string.isRequired,
    mapViewId: PropTypes.number,
    title: PropTypes.string.isRequired,
    mode: PropTypes.string.isRequired,
    latitude: PropTypes.string.isRequired,
    longitude: PropTypes.string.isRequired,
    zoom: PropTypes.string.isRequired,
    intl: PropTypes.object.isRequired,
};

UserMapViewEditPopup.defaultProps = {
    mapViewId: undefined,
};

export default injectIntl(UserMapViewEditPopup);
