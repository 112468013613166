'use strict';var Evented = require('../util/evented');
var StyleLayer = require('./style_layer');
var loadSprite = require('./load_sprite');
var ImageManager = require('../render/image_manager');
var GlyphManager = require('../render/glyph_manager');
var Light = require('./light');
var LineAtlas = require('../render/line_atlas');
var util = require('../util/util');
var ajax = require('../util/ajax');
var mapbox = require('../util/mapbox');
var browser = require('../util/browser');
var Dispatcher = require('../util/dispatcher');
var AnimationLoop = require('./animation_loop');
var validateStyle = require('./validate_style');
var getSourceType = require('../source/source').getType;
var setSourceType = require('../source/source').setType;
var QueryFeatures = require('../source/query_features');
var SourceCache = require('../source/source_cache');
var GeoJSONSource = require('../source/geojson_source');
var styleSpec = require('../style-spec/reference/latest');
var getWorkerPool = require('../util/global_worker_pool');
var deref = require('../style-spec/deref');
var DotDensityHandler = require('../source/dotdensity_handler');
var diff = require('../style-spec/diff');
var diffLayers = require('../style-spec/diff').diffLayers;
var rtlTextPlugin = require('../source/rtl_text_plugin');
var Placement = require('./placement');
var supportedDiffOperations = util.pick(diff.operations, [
    'addLayer',
    'removeLayer',
    'setPaintProperty',
    'setLayoutProperty',
    'setFilter',
    'addSource',
    'removeSource',
    'setLayerZoomRange',
    'setLight',
    'setTransition',
    'setGeoJSONSourceData',
    'setAutoSource'
]);
var ignoredDiffOperations = util.pick(diff.operations, [
    'setCenter',
    'setZoom',
    'setBearing',
    'setPitch'
]);
var Style = function (Evented) {
    function Style(map, options) {
        var this$1 = this;
        if (options === void 0)
            options = {};
        Evented.call(this);
        this.map = map;
        this.animationLoop = map && map.animationLoop || new AnimationLoop();
        this.dispatcher = new Dispatcher(getWorkerPool(), this);
        this.imageManager = new ImageManager();
        this.glyphManager = new GlyphManager(map._transformRequest, options.localIdeographFontFamily);
        this.lineAtlas = new LineAtlas(256, 512);
        this._layers = {};
        this._order = [];
        this.sourceCaches = {};
        this.zoomHistory = {};
        this._loaded = false;
        this._resetUpdates();
        var self = this;
        this._rtlTextPluginCallback = rtlTextPlugin.registerForPluginAvailability(function (args) {
            self.dispatcher.broadcast('loadRTLTextPlugin', args.pluginBlobURL, args.errorCallback);
            for (var id in self.sourceCaches) {
                self.sourceCaches[id].reload();
            }
        });
        this.on('data', function (event) {
            if (event.dataType !== 'source' || event.sourceDataType !== 'metadata') {
                return;
            }
            var sourceCache = this$1.sourceCaches[event.sourceId];
            if (!sourceCache) {
                return;
            }
            var source = sourceCache.getSource();
            if (!source || !source.vectorLayerIds) {
                return;
            }
            for (var layerId in this$1._layers) {
                var layer = this$1._layers[layerId];
                if (layer.source === source.id) {
                    this$1._validateLayer(layer);
                }
            }
        });
    }
    if (Evented)
        Style.__proto__ = Evented;
    Style.prototype = Object.create(Evented && Evented.prototype);
    Style.prototype.constructor = Style;
    Style.prototype.loadURL = function loadURL(url, options) {
        var this$1 = this;
        if (options === void 0)
            options = {};
        this.fire('dataloading', { dataType: 'style' });
        var validate = typeof options.validate === 'boolean' ? options.validate : !mapbox.isMapboxURL(url);
        url = mapbox.normalizeStyleURL(url, options.accessToken);
        var request = this.map._transformRequest(url, ajax.ResourceType.Style);
        ajax.getJSON(request, function (error, json) {
            if (error) {
                this$1.fire('error', { error: error });
            } else if (json) {
                this$1._load(json, validate);
            }
        });
    };
    Style.prototype.loadJSON = function loadJSON(json, options) {
        var this$1 = this;
        if (options === void 0)
            options = {};
        this.fire('dataloading', { dataType: 'style' });
        browser.frame(function () {
            this$1._load(json, options.validate !== false);
        });
    };
    Style.prototype._load = function _load(json, validate) {
        var this$1 = this;
        if (validate && validateStyle.emitErrors(this, validateStyle(json))) {
            return;
        }
        this._loaded = true;
        this.stylesheet = json;
        this.updatePaintProperties();
        for (var id in json.sources) {
            this$1.addSource(id, json.sources[id], { validate: false });
        }
        if (json.sprite) {
            loadSprite(json.sprite, this.map._transformRequest, function (err, images) {
                if (err) {
                    this$1.fire('error', err);
                } else if (images) {
                    for (var id in images) {
                        this$1.imageManager.addImage(id, images[id]);
                    }
                }
                this$1.imageManager.setLoaded(true);
                this$1.fire('data', { dataType: 'style' });
            });
        } else {
            this.imageManager.setLoaded(true);
        }
        this.glyphManager.setURL(json.glyphs);
        var layers = deref(this.stylesheet.layers);
        this._order = layers.map(function (layer) {
            return layer.id;
        });
        this._layers = {};
        for (var i = 0, list = layers; i < list.length; i += 1) {
            var layer = list[i];
            layer = StyleLayer.create(layer);
            layer.setEventedParent(this$1, { layer: { id: layer.id } });
            this$1._layers[layer.id] = layer;
        }
        this.dispatcher.broadcast('setLayers', this._serializeLayers(this._order));
        this.light = new Light(this.stylesheet.light);
        this.fire('data', { dataType: 'style' });
        this.fire('style.load');
    };
    Style.prototype._validateLayer = function _validateLayer(layer) {
        var sourceCache = this.sourceCaches[layer.source];
        if (!sourceCache) {
            return;
        }
        var sourceLayer = layer.sourceLayer;
        if (!sourceLayer) {
            return;
        }
        var source = sourceCache.getSource();
        if (source.type === 'geojson' || source.vectorLayerIds && source.vectorLayerIds.indexOf(sourceLayer) === -1) {
            this.fire('error', { error: new Error('Source layer "' + sourceLayer + '" ' + 'does not exist on source "' + source.id + '" ' + 'as specified by style layer "' + layer.id + '"') });
        }
    };
    Style.prototype.loaded = function loaded() {
        var this$1 = this;
        if (!this._loaded) {
            return false;
        }
        if (Object.keys(this._updatedSources).length) {
            return false;
        }
        for (var id in this$1.sourceCaches) {
            if (!this$1.sourceCaches[id].loaded()) {
                return false;
            }
        }
        if (!this.imageManager.isLoaded()) {
            return false;
        }
        return true;
    };
    Style.prototype._serializeLayers = function _serializeLayers(ids) {
        var this$1 = this;
        return ids.map(function (id) {
            return this$1._layers[id].serialize();
        });
    };
    Style.prototype._applyPaintPropertyUpdates = function _applyPaintPropertyUpdates(options) {
        var this$1 = this;
        if (!this._loaded) {
            return;
        }
        options = options || { transition: true };
        var transition = this.stylesheet.transition || {};
        var layers = this._updatedAllPaintProps ? this._layers : this._updatedPaintProps;
        for (var id in layers) {
            var layer = this$1._layers[id];
            var props = this$1._updatedPaintProps[id];
            if (this$1._updatedAllPaintProps || props.all) {
                layer.updatePaintTransitions(options, transition, this$1.animationLoop, this$1.zoomHistory);
            } else {
                for (var paintName in props) {
                    this$1._layers[id].updatePaintTransition(paintName, options, transition, this$1.animationLoop, this$1.zoomHistory);
                }
            }
        }
        this.light.updateLightTransitions(options, transition, this.animationLoop);
    };
    Style.prototype._recalculate = function _recalculate(z) {
        var this$1 = this;
        if (!this._loaded) {
            return;
        }
        for (var sourceId in this$1.sourceCaches) {
            this$1.sourceCaches[sourceId].used = false;
        }
        this._updateZoomHistory(z);
        for (var i = 0, list = this$1._order; i < list.length; i += 1) {
            var layerId = list[i];
            var layer = this$1._layers[layerId];
            layer.recalculate(z);
            if (!layer.isHidden(z) && layer.source && this$1.sourceCaches[layer.source]) {
                this$1.sourceCaches[layer.source].used = true;
            }
        }
        this.light.recalculate(z);
        var maxZoomTransitionDuration = 300;
        if (Math.floor(this.z) !== Math.floor(z)) {
            this.animationLoop.set(maxZoomTransitionDuration);
        }
        this.z = z;
    };
    Style.prototype._updateZoomHistory = function _updateZoomHistory(z) {
        var zh = this.zoomHistory;
        if (zh.lastIntegerZoom === undefined) {
            zh.lastIntegerZoom = Math.floor(z);
            zh.lastIntegerZoomTime = 0;
            zh.lastZoom = z;
        }
        if (Math.floor(zh.lastZoom) < Math.floor(z)) {
            zh.lastIntegerZoom = Math.floor(z);
            zh.lastIntegerZoomTime = Date.now();
        } else if (Math.floor(zh.lastZoom) > Math.floor(z)) {
            zh.lastIntegerZoom = Math.floor(z + 1);
            zh.lastIntegerZoomTime = Date.now();
        }
        zh.lastZoom = z;
    };
    Style.prototype._checkLoaded = function _checkLoaded() {
        if (!this._loaded) {
            throw new Error('Style is not done loading');
        }
    };
    Style.prototype.update = function update(options) {
        var this$1 = this;
        if (!this._changed) {
            return;
        }
        var updatedIds = Object.keys(this._updatedLayers);
        var removedIds = Object.keys(this._removedLayers);
        if (updatedIds.length || removedIds.length) {
            this._updateWorkerLayers(updatedIds, removedIds);
        }
        for (var id in this$1._updatedSources) {
            var action = this$1._updatedSources[id];
            if (action === 'reload') {
                this$1._reloadSource(id);
            } else if (action === 'clear') {
                this$1._clearSource(id);
            }
        }
        this._applyPaintPropertyUpdates(options);
        this._resetUpdates();
        this.fire('data', { dataType: 'style' });
    };
    Style.prototype._updateWorkerLayers = function _updateWorkerLayers(updatedIds, removedIds) {
        this.dispatcher.broadcast('updateLayers', {
            layers: this._serializeLayers(updatedIds),
            removedIds: removedIds
        });
    };
    Style.prototype._resetUpdates = function _resetUpdates() {
        this._changed = false;
        this._updatedLayers = {};
        this._removedLayers = {};
        this._updatedSources = {};
        this._updatedPaintProps = {};
        this._updatedAllPaintProps = false;
    };
    Style.prototype.setState = function setState(nextState) {
        var this$1 = this;
        this._checkLoaded();
        if (validateStyle.emitErrors(this, validateStyle(nextState, styleSpec))) {
            return false;
        }
        nextState = util.clone(nextState);
        nextState.layers = deref(nextState.layers);
        var changes = diff(this.serialize(), nextState).filter(function (op) {
            return !(op.command in ignoredDiffOperations);
        });
        if (changes.length === 0) {
            return false;
        }
        var unimplementedOps = changes.filter(function (op) {
            return !(op.command in supportedDiffOperations);
        });
        if (unimplementedOps.length > 0) {
            throw new Error('Unimplemented: ' + unimplementedOps.map(function (op) {
                return op.command;
            }).join(', ') + '.');
        }
        changes.forEach(function (op) {
            if (op.command === 'setTransition') {
                return;
            }
            this$1[op.command].apply(this$1, op.args);
        });
        this.stylesheet = nextState;
        return true;
    };
    Style.prototype.setAutoSource = function setAutoSource(layerId, autoSource) {
        if (this._layers[layerId] === undefined) {
            return;
        }
        this._layers[layerId].autoSource = autoSource;
        this._updateLayer(this._layers[layerId]);
    };
    Style.prototype.setLayer = function setLayer(layerObject) {
        var this$1 = this;
        this._checkLoaded();
        if (this._layers[layerObject.id] === undefined) {
            this.addLayer(layerObject);
            return;
        }
        var changes = [];
        diffLayers([this._layers[layerObject.id].serialize()], [layerObject], changes);
        changes = changes.filter(function (op) {
            return !(op.command in ignoredDiffOperations);
        });
        if (changes.length === 0) {
            return false;
        }
        var unimplementedOps = changes.filter(function (op) {
            return !(op.command in supportedDiffOperations);
        });
        if (unimplementedOps.length > 0) {
            throw new Error('Unimplemented: ' + unimplementedOps.map(function (op) {
                return op.command;
            }).join(', ') + '.');
        }
        changes.forEach(function (op) {
            if (op.command === 'setTransition') {
                return;
            }
            this$1[op.command].apply(this$1, op.args);
        });
    };
    Style.prototype.addImage = function addImage(id, image) {
        if (this.imageManager.getImage(id)) {
            return this.fire('error', { error: new Error('An image with this name already exists.') });
        }
        this.imageManager.addImage(id, image);
        this.fire('data', { dataType: 'style' });
    };
    Style.prototype.removeImage = function removeImage(id) {
        if (!this.imageManager.getImage(id)) {
            return this.fire('error', { error: new Error('No image with this name exists.') });
        }
        this.imageManager.removeImage(id);
        this.fire('data', { dataType: 'style' });
    };
    Style.prototype.addSource = function addSource(id, source, options) {
        var this$1 = this;
        this._checkLoaded();
        if (this.sourceCaches[id] !== undefined) {
            throw new Error('There is already a source with this ID');
        }
        if (!source.type) {
            throw new Error('The type property must be defined, but the only the following properties were given: ' + Object.keys(source).join(', ') + '.');
        }
        var builtIns = [
            'vector',
            'raster',
            'geojson',
            'video',
            'image',
            'canvas'
        ];
        var shouldValidate = builtIns.indexOf(source.type) >= 0;
        if (shouldValidate && this._validate(validateStyle.source, 'sources.' + id, source, null, options)) {
            return;
        }
        var sourceCache = this.sourceCaches[id] = new SourceCache(id, source, this.dispatcher);
        sourceCache.style = this;
        sourceCache.setEventedParent(this, function () {
            return {
                isSourceLoaded: this$1.loaded(),
                source: sourceCache.serialize(),
                sourceId: id
            };
        });
        sourceCache.onAdd(this.map);
        this._changed = true;
    };
    Style.prototype.removeSource = function removeSource(id) {
        this._checkLoaded();
        this.sourceRemove(id);
        if (this.sourceCaches[id] === undefined) {
            throw new Error('There is no source with this ID');
        }
        var sourceCache = this.sourceCaches[id];
        delete this.sourceCaches[id];
        delete this._updatedSources[id];
        sourceCache.fire('data', {
            sourceDataType: 'metadata',
            dataType: 'source',
            sourceId: id
        });
        sourceCache.setEventedParent(null);
        sourceCache.clearTiles();
        if (sourceCache.onRemove) {
            sourceCache.onRemove(this.map);
        }
        this._changed = true;
    };
    Style.prototype.setGeoJSONSourceData = function setGeoJSONSourceData(id, data) {
        this._checkLoaded();
        var geojsonSource = this.sourceCaches[id].getSource();
        geojsonSource.setData(data);
        this._changed = true;
    };
    Style.prototype.getSource = function getSource(id) {
        return this.sourceCaches[id] && this.sourceCaches[id].getSource();
    };
    Style.prototype.addLayer = function addLayer(layerObject, before, options) {
        this._checkLoaded();
        var resolvedLayer = layerObject.ref !== undefined ? deref([
            layerObject,
            this.getLayer(layerObject.ref).serialize()
        ])[0] : layerObject;
        var id = resolvedLayer.id;
        if (typeof layerObject.source === 'object') {
            this.addSource(id, layerObject.source);
            layerObject = util.clone(layerObject);
            layerObject = util.extend(layerObject, { source: id });
        }
        if (this._validate(validateStyle.layer, 'layers.' + id, resolvedLayer, { arrayIndex: -1 }, options)) {
            return;
        }
        var layer = StyleLayer.create(resolvedLayer);
        this._validateLayer(layer);
        layer.setEventedParent(this, { layer: { id: id } });
        var index = before ? this._order.indexOf(before) : this._order.length;
        if (before && index === -1) {
            this.fire('error', { message: new Error('Layer with id "' + before + '" does not exist on this map.') });
            return;
        }
        this._order.splice(index, 0, id);
        this._layerOrderChanged = true;
        this._layers[id] = layer;
        if (this._removedLayers[id] && layer.source) {
            var removed = this._removedLayers[id];
            delete this._removedLayers[id];
            if (removed.type !== layer.type) {
                this._updatedSources[layer.source] = 'clear';
            } else {
                this._updatedSources[layer.source] = 'reload';
                this.sourceCaches[layer.source].pause();
            }
        }
        this._updateLayer(layer);
        this.updatePaintProperties(id);
    };
    Style.prototype.moveLayer = function moveLayer(id, before) {
        this._checkLoaded();
        this._changed = true;
        var layer = this._layers[id];
        if (!layer) {
            this.fire('error', { error: new Error('The layer \'' + id + '\' does not exist in ' + 'the map\'s style and cannot be moved.') });
            return;
        }
        var index = this._order.indexOf(id);
        this._order.splice(index, 1);
        var newIndex = before ? this._order.indexOf(before) : this._order.length;
        this._order.splice(newIndex, 0, id);
        this._layerOrderChanged = true;
    };
    Style.prototype.removeLayer = function removeLayer(id) {
        this._checkLoaded();
        this.layerRemove(id);
        var layer = this._layers[id];
        if (!layer) {
            this.fire('error', { error: new Error('The layer \'' + id + '\' does not exist in ' + 'the map\'s style and cannot be removed.') });
            return;
        }
        layer.setEventedParent(null);
        var index = this._order.indexOf(id);
        this._order.splice(index, 1);
        this._layerOrderChanged = true;
        this._changed = true;
        this._removedLayers[id] = layer;
        delete this._layers[id];
        delete this._updatedLayers[id];
        delete this._updatedPaintProps[id];
    };
    Style.prototype.getLayer = function getLayer(id) {
        return this._layers[id];
    };
    Style.prototype.setLayerZoomRange = function setLayerZoomRange(layerId, minzoom, maxzoom) {
        this._checkLoaded();
        var layer = this.getLayer(layerId);
        if (!layer) {
            this.fire('error', { error: new Error('The layer \'' + layerId + '\' does not exist in ' + 'the map\'s style and cannot have zoom extent.') });
            return;
        }
        if (layer.minzoom === minzoom && layer.maxzoom === maxzoom) {
            return;
        }
        this.layerRemove(layerId);
        if (minzoom !== null) {
            layer.minzoom = minzoom;
        }
        if (maxzoom !== null) {
            layer.maxzoom = maxzoom;
        }
        this._updateLayer(layer);
    };
    Style.prototype.setFilter = function setFilter(layerId, filter) {
        this._checkLoaded();
        var layer = this.getLayer(layerId);
        if (!layer) {
            this.fire('error', { error: new Error('The layer \'' + layerId + '\' does not exist in ' + 'the map\'s style and cannot be filtered.') });
            return;
        }
        if (filter !== null && filter !== undefined && this._validate(validateStyle.filter, 'layers.' + layer.id + '.filter', filter)) {
            return;
        }
        if (util.deepEqual(layer.filter, filter)) {
            return;
        }
        layer.filter = util.clone(filter);
        this._updateLayer(layer);
    };
    Style.prototype.getFilter = function getFilter(layer) {
        return util.clone(this.getLayer(layer).filter);
    };
    Style.prototype.setLayoutProperty = function setLayoutProperty(layerId, name, value) {
        var this$1 = this;
        this._checkLoaded();
        var layer = this.getLayer(layerId);
        if (!layer) {
            this.fire('error', { error: new Error('The layer \'' + layerId + '\' does not exist in ' + 'the map\'s style and cannot be styled.') });
            return;
        }
        if (util.deepEqual(layer.getLayoutProperty(name), value)) {
            return;
        }
        if (name === 'visibility' && value === 'none') {
            this.layerRemove(layerId);
        }
        var layers = this._layers;
        for (var id in layers) {
            if (layers.hasOwnProperty(id) && layers[id].sourceLayer === layer.sourceLayer && layers[id].type === 'dotdensity') {
                this$1.layerRemove(layerId);
            }
        }
        layer.setLayoutProperty(name, value);
        this._updateLayer(layer);
    };
    Style.prototype.getLayoutProperty = function getLayoutProperty(layer, name) {
        return this.getLayer(layer).getLayoutProperty(name);
    };
    Style.prototype.setPaintProperty = function setPaintProperty(layerId, name, value) {
        this._checkLoaded();
        var layer = this.getLayer(layerId);
        if (!layer) {
            this.fire('error', { error: new Error('The layer \'' + layerId + '\' does not exist in ' + 'the map\'s style and cannot be styled.') });
            return;
        }
        if (util.deepEqual(layer.getPaintProperty(name), value)) {
            return;
        }
        var wasFeatureConstant = layer.isPaintValueFeatureConstant(name);
        layer.setPaintProperty(name, value);
        var oldValue = layer.getPaintProperty(name);
        var wasZoomConstant = layer.isPaintValueZoomConstant(name);
        var isFeatureConstant = layer.isPaintValueFeatureConstant(name);
        if (isFeatureConstant !== wasFeatureConstant) {
            this._updateLayer(layer);
        } else if (!isFeatureConstant || !wasFeatureConstant) {
            if (!oldValue || !value || oldValue.type !== value.type) {
                this._updateLayer(layer);
            } else if (value.type !== 'dragonfly-interval' && value.type !== 'dragonfly-categorical') {
                this._updateLayer(layer);
            } else if (wasZoomConstant !== layer.isPaintValueZoomConstant(name) || oldValue.property !== value.property) {
                this._updateLayer(layer);
            }
        }
        if (!isFeatureConstant || !wasFeatureConstant) {
            this._updateLayer(layer);
        }
        this.updatePaintProperties(layerId, name);
    };
    Style.prototype.getPaintProperty = function getPaintProperty(layer, name) {
        return this.getLayer(layer).getPaintProperty(name);
    };
    Style.prototype.getTransition = function getTransition() {
        return util.extend({
            duration: 300,
            delay: 0
        }, this.stylesheet && this.stylesheet.transition);
    };
    Style.prototype.updatePaintProperties = function updatePaintProperties(layerId, paintName) {
        this._changed = true;
        if (!layerId) {
            this._updatedAllPaintProps = true;
        } else {
            var props = this._updatedPaintProps;
            if (!props[layerId]) {
                props[layerId] = {};
            }
            props[layerId][paintName || 'all'] = true;
        }
    };
    Style.prototype.serialize = function serialize() {
        var this$1 = this;
        return util.filterObject({
            version: this.stylesheet.version,
            name: this.stylesheet.name,
            metadata: this.stylesheet.metadata,
            light: this.stylesheet.light,
            center: this.stylesheet.center,
            zoom: this.stylesheet.zoom,
            bearing: this.stylesheet.bearing,
            pitch: this.stylesheet.pitch,
            sprite: this.stylesheet.sprite,
            glyphs: this.stylesheet.glyphs,
            transition: this.stylesheet.transition,
            sources: util.mapObject(this.sourceCaches, function (source) {
                return source.serialize();
            }),
            layers: this._order.map(function (id) {
                return this$1._layers[id].serialize();
            })
        }, function (value) {
            return value !== undefined;
        });
    };
    Style.prototype._updateLayer = function _updateLayer(layer) {
        this._updatedLayers[layer.id] = true;
        if (layer.source && !this._updatedSources[layer.source]) {
            this._updatedSources[layer.source] = 'reload';
            this.sourceCaches[layer.source].pause();
        }
        this._changed = true;
    };
    Style.prototype._flattenRenderedFeatures = function _flattenRenderedFeatures(sourceResults) {
        var this$1 = this;
        var features = [];
        for (var l = this._order.length - 1; l >= 0; l--) {
            var layerId = this$1._order[l];
            for (var i = 0, list = sourceResults; i < list.length; i += 1) {
                var sourceResult = list[i];
                var layerFeatures = sourceResult[layerId];
                if (layerFeatures) {
                    for (var i$1 = 0, list$1 = layerFeatures; i$1 < list$1.length; i$1 += 1) {
                        var feature = list$1[i$1];
                        features.push(feature);
                    }
                }
            }
        }
        return features;
    };
    Style.prototype.queryRenderedFeatures = function queryRenderedFeatures(queryGeometry, params, zoom, bearing) {
        var this$1 = this;
        if (params && params.filter) {
            this._validate(validateStyle.filter, 'queryRenderedFeatures.filter', params.filter);
        }
        var includedSources = {};
        if (params && params.layers) {
            if (!Array.isArray(params.layers)) {
                this.fire('error', { error: 'parameters.layers must be an Array.' });
                return [];
            }
            for (var i = 0, list = params.layers; i < list.length; i += 1) {
                var layerId = list[i];
                var layer = this$1._layers[layerId];
                if (!layer) {
                    this$1.fire('error', { error: 'The layer \'' + layerId + '\' does not exist ' + 'in the map\'s style and cannot be queried for features.' });
                    return [];
                }
                includedSources[layer.source] = true;
            }
        }
        var sourceResults = [];
        for (var id in this$1.sourceCaches) {
            if (params.layers && !includedSources[id]) {
                continue;
            }
            var results = QueryFeatures.rendered(this$1.sourceCaches[id], this$1._layers, queryGeometry, params, zoom, bearing);
            sourceResults.push(results);
        }
        return this._flattenRenderedFeatures(sourceResults);
    };
    Style.prototype.querySourceFeatures = function querySourceFeatures(sourceID, params) {
        if (params && params.filter) {
            this._validate(validateStyle.filter, 'querySourceFeatures.filter', params.filter);
        }
        var sourceCache = this.sourceCaches[sourceID];
        return sourceCache ? QueryFeatures.source(sourceCache, params) : [];
    };
    Style.prototype.addSourceType = function addSourceType(name, SourceType, callback) {
        if (getSourceType(name)) {
            return callback(new Error('A source type called "' + name + '" already exists.'));
        }
        setSourceType(name, SourceType);
        if (!SourceType.workerSourceURL) {
            return callback(null, null);
        }
        this.dispatcher.broadcast('loadWorkerSource', {
            name: name,
            url: SourceType.workerSourceURL
        }, callback);
    };
    Style.prototype.getLight = function getLight() {
        return this.light.getLight();
    };
    Style.prototype.setLight = function setLight(lightOptions, transitionOptions) {
        this._checkLoaded();
        var light = this.light.getLight();
        var _update = false;
        for (var key in lightOptions) {
            if (!util.deepEqual(lightOptions[key], light[key])) {
                _update = true;
                break;
            }
        }
        if (!_update) {
            return;
        }
        var transition = this.stylesheet.transition || {};
        this.light.setLight(lightOptions);
        this.light.updateLightTransitions(transitionOptions || { transition: true }, transition, this.animationLoop);
    };
    Style.prototype._validate = function _validate(validate, key, value, props, options) {
        if (options && options.validate === false) {
            return false;
        }
        return validateStyle.emitErrors(this, validate.call(validateStyle, util.extend({
            key: key,
            style: this.serialize(),
            value: value,
            styleSpec: styleSpec
        }, props)));
    };
    Style.prototype._remove = function _remove() {
        var this$1 = this;
        rtlTextPlugin.evented.off('pluginAvailable', this._rtlTextPluginCallback);
        for (var id in this$1.sourceCaches) {
            this$1.sourceCaches[id].clearTiles();
        }
        this.dispatcher.remove();
    };
    Style.prototype._clearSource = function _clearSource(id) {
        this.sourceCaches[id].clearTiles();
    };
    Style.prototype._reloadSource = function _reloadSource(id) {
        this.sourceCaches[id].resume();
        this.sourceCaches[id].reload();
    };
    Style.prototype._updateSources = function _updateSources(transform) {
        var this$1 = this;
        for (var id in this$1.sourceCaches) {
            this$1.sourceCaches[id].update(transform);
        }
    };
    Style.prototype.getNeedsFullPlacement = function getNeedsFullPlacement() {
        var this$1 = this;
        if (this._layerOrderChanged) {
            return true;
        }
        for (var id in this$1.sourceCaches) {
            if (this$1.sourceCaches[id].getNeedsFullPlacement()) {
                return true;
            }
        }
        return false;
    };
    Style.prototype._generateCollisionBoxes = function _generateCollisionBoxes() {
        var this$1 = this;
        for (var id in this$1.sourceCaches) {
            this$1._reloadSource(id);
        }
    };
    Style.prototype._updatePlacement = function _updatePlacement(transform, showCollisionBoxes, fadeDuration) {
        var forceFullPlacement = this.getNeedsFullPlacement();
        if (forceFullPlacement || !this.placement || this.placement.isDone()) {
            this.placement = new Placement(transform, this._order, forceFullPlacement, showCollisionBoxes, fadeDuration, this.placement);
            this._layerOrderChanged = false;
        }
        this.placement.continuePlacement(this._order, this._layers, this.sourceCaches);
        if (this.placement.isDone()) {
            this.collisionIndex = this.placement.collisionIndex;
        }
        var needsRerender = !this.placement.isDone() || this.placement.stillFading();
        return needsRerender;
    };
    Style.prototype.getImages = function getImages(mapId, params, callback) {
        this.imageManager.getImages(params.icons, callback);
    };
    Style.prototype.getGlyphs = function getGlyphs(mapId, params, callback) {
        this.glyphManager.getGlyphs(params.stacks, callback);
    };
    Style.prototype.layerRemove = function layerRemove(layerId) {
        var this$1 = this;
        if (DotDensityHandler.allDots[this.dispatcher.id]) {
            for (var zoom in DotDensityHandler.allDots[this$1.dispatcher.id]) {
                delete DotDensityHandler.allDots[this$1.dispatcher.id][zoom].layers[layerId];
            }
        }
        if (DotDensityHandler.tiles[this.dispatcher.id]) {
            for (var zoom$1 in DotDensityHandler.tiles[this$1.dispatcher.id]) {
                if (DotDensityHandler.tiles[this$1.dispatcher.id][zoom$1]) {
                    for (var tileId in DotDensityHandler.tiles[this$1.dispatcher.id][zoom$1]) {
                        delete DotDensityHandler.tiles[this$1.dispatcher.id][zoom$1][tileId][layerId];
                    }
                }
            }
        }
        this.dispatcher.broadcast('clearDotdensityLayer', { layerId: layerId }, undefined);
    };
    Style.prototype.sourceRemove = function sourceRemove(source) {
        var this$1 = this;
        if (DotDensityHandler.allDots[this.dispatcher.id]) {
            for (var zoom in DotDensityHandler.allDots[this$1.dispatcher.id]) {
                for (var layerId in DotDensityHandler.allDots[this$1.dispatcher.id][zoom].layers) {
                    if (source === DotDensityHandler.allDots[this$1.dispatcher.id][zoom].layers[layerId].source) {
                        delete DotDensityHandler.allDots[this$1.dispatcher.id][zoom].layers[layerId];
                        if (DotDensityHandler.tiles[this$1.dispatcher.id] && DotDensityHandler.tiles[this$1.dispatcher.id][zoom]) {
                            for (var tileId in DotDensityHandler.tiles[this$1.dispatcher.id][zoom]) {
                                delete DotDensityHandler.tiles[this$1.dispatcher.id][zoom][tileId][layerId];
                            }
                        }
                    }
                }
            }
        }
        this.dispatcher.broadcast('clearDotdensityLayers', { source: source }, undefined);
    };
    return Style;
}(Evented);
module.exports = Style;