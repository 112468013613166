import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Checkbox from '../../../form/Checkbox';

const TogglePropertyEditor = ({ labelVisible, onToggleLabel, intl }) => (
    <div className="annotation-property">
        <Checkbox
            label={intl.formatMessage({ id: 'showHide.show.titleAsLabel' })}
            checked={labelVisible}
            onCheck={onToggleLabel}
            className="body-1"
        />
    </div>
);

TogglePropertyEditor.propTypes = {
    onToggleLabel: PropTypes.func.isRequired,
    labelVisible: PropTypes.bool.isRequired,
    intl: PropTypes.object.isRequired,
};
TogglePropertyEditor.defaultProps = {};

export default injectIntl(TogglePropertyEditor);
