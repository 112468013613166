class LayerOverride {

    get id() {
        return this._id;
    }

    set id(id) {
        this._id = id;
    }

    get visibility() {
        return this._visibility;
    }

    set visibility(visibility) {
        this._visibility = visibility;
    }

    equals(that) {
        return this.visibility === that.visibility;
    }

    clone() {
        const layerOverride = new LayerOverride();
        layerOverride.id = this.id;
        layerOverride.visibility = this.visibility;
        return layerOverride;
    }

    toJSON() {
        return {
            id: this.id,
            visibility: this.visibility,
        };
    }

}

export default LayerOverride;
