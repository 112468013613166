// @ts-check
import GLU from '../glu2.js/src/index';
import VisualizationType from '../enums/VisualizationType';
import VariableValueType from '../enums/VariableValueType';

class UserStyleDataSource extends GLU.DataSource {
    static get name() {
        return 'UserStyleDataSource';
    }

    static getInstance() {
        return new UserStyleDataSource();
    }

    constructor() {
        super();
        /** @type {import('../types').PreferredStyleSettings[]} array of preferred user style settings by for each map instance */
        this._settings = [];
        this._preferredBubbleSize = 40;
        this._preferredDotDensityValueHint = 0;
        this._preferredBubbleVariableType = VariableValueType.NUMBER;
        this._preferredShadedVariableType = VariableValueType.PERCENT;
    }

    /** @returns {import('../types').PreferredStyleSettings[]} */
    get settings() {
        return this._settings;
    }

    set settings(settings) {
        this._settings = settings;
    }

    /**
     * @param {import('../types').PreferredStyleSettings} settings
     */
    addFrameSettings(settings) {
        this._settings.push(settings);
    }

    getFrameSettings(index) {
        return this._settings[index];
    }

    setFrameSettings(index, settings) {
        this._settings[index] = settings;
    }

    getBubbleSize(mapInstance, dataTheme, metadata, currentFrame) {
        if (dataTheme && dataTheme.visualizationType === VisualizationType.BUBBLE && dataTheme.variableSelection && dataTheme.variableSelection.items && dataTheme.variableSelection.items.length > 0) {
            const firstVariableSelectionItem = dataTheme.variableSelection.items[0];
            const metaSurvey = metadata.surveys[firstVariableSelectionItem.surveyName];
            const metaDataset = metaSurvey.datasets[firstVariableSelectionItem.datasetAbbreviation];
            const metaTable = metaDataset.getTableByGuid(firstVariableSelectionItem.tableGuid);
            const metaVariable = metaTable.getVariableByGuid(firstVariableSelectionItem.variableGuid);
            return dataTheme.rendering[0].bubbleSizeFactor * metaVariable.bubbleSizeScale;
        }
        const preferredStyleSettingsIdx = currentFrame.mapInstances.indexOf(mapInstance);
        if (this._settings[preferredStyleSettingsIdx]) {
            return this._settings[preferredStyleSettingsIdx].bubbleSize;
        }
        return this._preferredBubbleSize;
    }
}

export default UserStyleDataSource;
