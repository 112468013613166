// eslint-disable-next-line import/prefer-default-export

const ALLOWED_POINT_TYPES_FOR_MY_PLACES_ANALYSIS = [
    'SELFSTORAGE_FACILITY',
    'SELFSTORAGE_CONSTRUCTION',
];

const POI_TYPES = [
    // SS-specific POI types
    'ss-facility',
    'ss-construction',
    // SS and TractIQ shared POI types
    'housing-starts',
    'influencer',
];

export { ALLOWED_POINT_TYPES_FOR_MY_PLACES_ANALYSIS, POI_TYPES };
