import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import Menu from './Menu';
import Tooltip from '../Tooltip';

class Trigger extends React.Component {
    handleClick = () => {
        const { onClick, menu } = this.props;
        onClick(menu);
    };

    renderLabel() {
        const { active, prefix, label } = this.props;

        return (
            <div className="map-panel-trigger__content">
                <div className="flex-it end min-width-0">
                    {prefix && <div className="map-panel-trigger__prefix">{prefix}</div>}
                    <div className="map-panel-trigger__label">{label}</div>
                </div>
                <i className="material-icons map-panel-trigger__icon">{active ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</i>
            </div>
        );
    }

    render() {
        const { children, active, tourId, overlay } = this.props;

        const classes = classNames('map-panel-trigger', Menu.TRIGGER_CLASS, {
            'map-panel-trigger--active': active,
            'map-panel-trigger--simple': children !== undefined,
        });

        const trigger = (<button
            className={classes}
            onClick={this.handleClick}
            data-tourId={tourId}
            aria-label={overlay}
        >
            {children || this.renderLabel()}
        </button>);

        if (overlay && !active) {
            return (<Tooltip
                placement="bottom"
                mouseEnterDelay={0.2}
                mouseLeaveDelay={0}
                overlay={overlay}
            >
                {trigger}
            </Tooltip>);
        }

        return trigger;
    }
}

Trigger.propTypes = {
    children: PropTypes.any,
    onClick: PropTypes.func.isRequired,
    menu: PropTypes.string.isRequired,
    label: PropTypes.string,
    prefix: PropTypes.string,
    active: PropTypes.bool,
    tourId: PropTypes.string,
    overlay: PropTypes.string,
};

Trigger.defaultProps = {
    active: false,
    children: undefined,
    prefix: undefined,
    label: undefined,
    tourId: undefined,
    overlay: undefined,
};

export default Trigger;
