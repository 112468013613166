module.exports={
  "show": {
    "tooltipOn": "Show tooltip on",
    "slider": "Show slider",
    "more": "Show more",
    "percentages": "Show percentages",
    "numbers": "Show numbers",
    "less": "Show less",
    "fewerYears": "Show fewer years",
    "allYears": "Show all years",
    "legend": "Show legend",
    "infoBubbleOnHover": "Show info bubble on hover",
    "allData": "Show all data",
    "infoBubbleOnClick": "Show info bubble on click",
    "titleAsLabel": "Show title as label",
    "slidesPanel": "Show slides panel",
    "satelliteViewOptions": "Show satellite view options",
    "autoAdjustInfo": "Show auto adjust description",
    "autoAdjustMenu": "Show auto adjust menu",
    "filteringDataLimit": "Show limit for filtering data"
  },
  "hide": {
    "slider": "Hide slider",
    "legend": "Hide legend",
    "hint": "Hide hint",
    "slidesPanel": "Hide slides panel",
    "slideInfoPanel": "Hide slide info panel",
    "satelliteViewOptions": "Hide satellite view options",
    "autoAdjustInfo": "Hide auto adjust description",
    "autoAdjustMenu": "Hide auto adjust menu",
    "filteringDataLimit": "Hide limit for filtering data"
  }
}
