import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PanelSubtitleInfoButton from './PanelSubtitleInfoButton';

const Panel = props => {
    const panelClasses = classNames('panel flex-it column stretch', props.className);
    let header;
    if (props.customHeader) header = props.customHeader;
    else {
        switch (props.headerStyle) {
        case 'grey':
        case 'white':
            header = (<div className={`panel__header panel__header--${props.headerStyle}`}><div className={'flex-it space-between center'}>
                <div className="flex-it center flex-start">
                    {props.onBackClick && <button onClick={props.onBackClick} className="btn-flat-icon panel__back" aria-label="Back icon">{props.backIcon}</button>}
                    <h4>{props.title}</h4>
                </div>
                <div className="flex-it center space-between">
                    <div className="flex-it center">
                        {props.headerActions}
                    </div>
                    {props.onCloseClick !== undefined && <button onClick={props.onCloseClick} className="btn-flat-icon panel__close" aria-label="Close icon">{props.closeIcon}</button>}
                </div>
            </div>
                { props.subtitle !== '' && <div className="flex-it center">
                    <span className="filter-data-subtitle" title={props.subtitle}>{props.subtitle}</span>
                    <PanelSubtitleInfoButton mapInstanceId={props.mapInstanceId} />
                </div> }
            </div>);
        }
    }
    return (<div className={panelClasses}>
        {header}
        <div className="panel__content grow flex-it column stretch">
            {props.children}
        </div>

    </div>);
};

Panel.propTypes = {
    headerStyle: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
    ]),
    onBackClick: PropTypes.func,
    onCloseClick: PropTypes.func,
    headerActions: PropTypes.array,
    backIcon: PropTypes.element,
    closeIcon: PropTypes.element,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    mapInstanceId: PropTypes.string,
    className: PropTypes.string,
    customHeader: PropTypes.object,
};

Panel.defaultProps = {
    headerStyle: 'grey',
    headerActions: [],
    children: undefined,
    title: '',
    subtitle: '',
    mapInstanceId: undefined,
    backIcon: <i className="material-icons">arrow_back</i>,
    closeIcon: <i className="material-icons">close</i>,
    className: '',
    onBackClick: undefined,
    onCloseClick: undefined,
    customHeader: undefined,
};

export default Panel;
