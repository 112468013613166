// @ts-check
/* eslint class-methods-use-this: 0 */
import BaseController from './BaseController';
import DataTheme from '../objects/DataTheme';
import FieldList from '../objects/FieldList';
import FieldListField from '../objects/FieldListField';
import ValueRenderer from '../objects/ValueRenderer';
import BubbleRenderer from '../objects/BubbleRenderer';
import MultiValueRenderer from '../objects/MultiValueRenderer';
import DotDensityRenderer from '../objects/DotDensityRenderer';
import ValueDotDensityRenderer from '../objects/ValueDotDensityRenderer';
import Brush from '../objects/Brush';
import Symbol from '../objects/Symbol';
import Filter from '../objects/Filter';
import FilterRule from '../objects/FilterRule';
import VariableType from '../enums/VariableType';
import NumberFormat from '../enums/NumberFormat';
import FilterComparisonType from '../enums/FilterComparisonType';
import VisualizationType from '../enums/VisualizationType';
import ColorPaletteType from '../enums/ColorPaletteType';
import VariableValueType from '../enums/VariableValueType';
import DataClassificationMethod from '../enums/DataClassificationMethod';
import MetadataDataSource from '../dataSources/MetadataDataSource';
import ProjectDataSource from '../dataSources/ProjectDataSource';
import ColorPaletteDataSource from '../dataSources/ColorPaletteDataSource';
import MapDataSource from '../dataSources/MapDataSource';
import UserStyleDataSource from '../dataSources/UserStyleDataSource';

import AppConfig from '../appConfig';

import {
    cloneHashObject,
    canVariableSelectionUseShaded,
    getMetadataObjectsFromVariableSelectionItem,
    getDotValue,
    variableQualifiedName,
} from '../helpers/Util';
import format from '../helpers/NumberFormatter';

const RELATED_COLOR_PALETTE_TYPE = {
    [ColorPaletteType.BUBBLE_USER_DEFINED]: ColorPaletteType.POLYGON_USER_DEFINED,
    [ColorPaletteType.POLYGON_USER_DEFINED]: ColorPaletteType.BUBBLE_USER_DEFINED,
    [ColorPaletteType.BUBBLE_USER_DEFINED_SINGLE_COLOR]: ColorPaletteType.DOT_DENSITY_USER_DEFINED,
    [ColorPaletteType.DOT_DENSITY_USER_DEFINED]: ColorPaletteType.BUBBLE_USER_DEFINED_SINGLE_COLOR,
};

class DataVisualizationController extends BaseController {
    static get name() {
        return 'DataVisualizationController';
    }

    static getInstance(options) {
        return new DataVisualizationController(options);
    }

    onActivate() {
        this._preferredBubbleSize = 40;
        this._preferredDotDensityValueHint = 0;
        this._preferredBubbleVariableType = VariableValueType.NUMBER;
        this._preferredShadedVariableType = VariableValueType.PERCENT;

        this.bindGluBusEvents({
            PROJECT_LOAD_SUCCESS: this.onProjectLoadSuccess,
            DATA_SELECTION_CHANGE: this.onDataSelectionChange,
            VISUALIZATION_TYPE_CHANGE: this.onVisualizationTypeChange,
            FRAME_UPDATED: this.onFrameUpdated,
            FRAME_MAPS_SWAPPED: this.onFrameMapsSwapped,
            APPLY_FILTERS_REQUEST: this.onApplyFiltersRequest,
            APPLY_COLOR_PALETTE_REQUEST: this.onApplyColorPaletteRequest,
            FLIP_COLOR_PALETTE_REQUEST: this.onFlipColorPaletteRequest,
            CREATE_COLOR_PALETTE_REQUEST: this.onCreateColorPaletteRequest,
            MAP_GET_CURRENT_COLOR_PALETTES_REQUEST: this.onMapGetCurrentColorPalettes,
            COLOR_PALETTES_REQUEST: this.onColorPalettesRequest,
            EDIT_COLOR_PALETTE_REQUEST: this.onEditColorPaletteRequest,
            DELETE_COLOR_PALETTE_REQUEST: this.onDeleteColorPaletteRequest,
            MAP_NEW_COLOR_PALETTE_APPLIED: this.onMapNewColorPaletteApplied,
            MAP_NEW_DATA_THEME_APPLIED: this.onMapNewDataThemeApplied,
            MAP_NEW_DOT_VALUE_HINT_APPLIED: this.onMapNewDotValueHintApplied,
            MAP_BUBBLE_SIZE_CHANGE: this.onMapBubbleSizeChange,
            MAP_BUBBLE_VARIABLE_TYPE_CHANGE: this.onMapVariableTypeChange,
            APPLY_DOT_VALUE_HINT_REQUEST: this.onApplyDotValueHintRequest,
            MAP_ZOOM_END: this.onMapZoomEnd,
            APPLY_DOLLAR_YEAR_ADJUSTMENT: this.onApplyDollarYearAdjustmentRequest,
            CLEAR_DOLLAR_YEAR_ADJUSTMENT: this.clearDollarYearAdjustmentRequest,
        });

        this.mapDataSource = this.activateSource(MapDataSource);
        this.projectDataSource = this.activateSource(ProjectDataSource);
        this.metadataDataSource = this.activateSource(MetadataDataSource);
        this.colorPaletteDataSource = this.activateSource(ColorPaletteDataSource);
        this.userStyleDataSource = this.activateSource(UserStyleDataSource);
    }

    onDeactivate() {
        this.unbindGluBusEvents();
    }

    clearDollarYearAdjustmentRequest(e) {
        const mapInstance = this.projectDataSource.getActiveMapInstance(e.mapInstanceId);
        const newDataTheme = mapInstance.dataTheme.clone();
        const current = getMetadataObjectsFromVariableSelectionItem(newDataTheme.variableSelection.items[0], this.metadataDataSource.currentMetadata);
        const fieldList = newDataTheme.rendering[0].fieldList;
        newDataTheme.variableSelection.items.forEach((vsi, i) => {
            const fieldListItem = newDataTheme.rendering[0].fieldList.fields.find(fli => fli.fieldName === vsi.variableGuid);
            const multiplyFieldListItem = newDataTheme.rendering[0].fieldList.fields.find(fli => fli.fieldName === `X${vsi.variableGuid}`);
            const metaVariable = current.table.getVariableByGuid(vsi.variableGuid);
            if (fieldListItem.label.indexOf(mapInstance.dataTheme.adjustmentDollarYear) > -1) fieldListItem.label = current.table.getVariableByGuid(vsi._variableGuid).qLabel;
            if (multiplyFieldListItem) fieldList.fields.splice(fieldList.fields.indexOf(multiplyFieldListItem), 1);
            const rules = newDataTheme.rendering[0].type === 'MultiValueRenderer' ? newDataTheme.rendering[0].rules[i] : newDataTheme.rendering[0].rules;
            rules.forEach(r => (r.filter.fieldName = variableQualifiedName(current.survey, current.dataset, metaVariable)));
        });
        newDataTheme.adjustmentDollarYear = undefined;
        this.bus.emit('APPLY_NEW_DATA_THEME_REQUEST', {
            source: this,
            mapInstanceId: e.mapInstanceId,
            dataTheme: newDataTheme,
            mapId: mapInstance.currentMapId,
        });
    }

    onApplyDollarYearAdjustmentRequest(e) {
        const mapInstance = this.projectDataSource.getActiveMapInstance(e.mapInstanceId);
        const { currentYear, adjustmentYear } = e;
        const factor =
            this.metadataDataSource.cpiValuesByYear[adjustmentYear] /
            this.metadataDataSource.cpiValuesByYear[currentYear];
        const newDataTheme = mapInstance.dataTheme.clone();
        const current = getMetadataObjectsFromVariableSelectionItem(newDataTheme.variableSelection.items[0], this.metadataDataSource.currentMetadata);
        const fieldList = newDataTheme.rendering[0].fieldList;
        newDataTheme.variableSelection.items.forEach((vsi, i) => {
            const fieldListItem = newDataTheme.rendering[0].fieldList.fields.find(fli => fli.fieldName === vsi.variableGuid);
            const multiplyFieldListItem = newDataTheme.rendering[0].fieldList.fields.find(fli => fli.fieldName === `X${vsi.variableGuid}`);
            const metaVariable = current.table.getVariableByGuid(vsi.variableGuid);
            // Correct labels for adjusted year (for proper Info Bubble content)
            const yearToReplace = mapInstance.dataTheme.adjustmentDollarYear !== undefined ? mapInstance.dataTheme.adjustmentDollarYear : currentYear;
            if (fieldListItem.label.indexOf(yearToReplace) > -1) fieldListItem.label = fieldListItem.label.replace(yearToReplace, adjustmentYear);
            else fieldListItem.label = `${fieldListItem.label} (In ${adjustmentYear} Inflation Adjusted Dollars)`;
            // Add FieldListField if it doesn't already exist. If it does, just update the factor
            if (multiplyFieldListItem) multiplyFieldListItem.fieldMultiplier = factor;
            else {
                fieldList.fields.push(new FieldListField({
                    fieldName: `X${vsi.variableGuid}`,
                    surveyName: current.survey.name,
                    datasetAbbreviation: current.dataset.abbrevation,
                    isComputed: true,
                    fieldMultiplier: factor,
                    computeFunction: 'COMPUTE_MULTIPLY',
                    formatting: fieldListItem.formatting,
                    fieldNumerator: variableQualifiedName(current.survey, current.dataset, metaVariable),
                    isGeoNameField: false,
                    hideFromUser: false,
                }));
            }
            // Update rules to use new computed variable.
            const rules = newDataTheme.rendering[0].type === 'MultiValueRenderer' ? newDataTheme.rendering[0].rules[i] : newDataTheme.rendering[0].rules;
            rules.forEach(r => (r.filter.fieldName = `${current.survey.name}.${current.dataset.abbrevation}.X${metaVariable.uuid}`));
        });

        newDataTheme.adjustmentDollarYear = adjustmentYear;

        this.bus.emit('APPLY_NEW_DATA_THEME_REQUEST', {
            source: this,
            mapInstanceId: e.mapInstanceId,
            dataTheme: newDataTheme,
            mapId: mapInstance.currentMapId,
        });
    }

    onApplyDotValueHintRequest(e) {
        const mapInstance = this.projectDataSource.getActiveMapInstance(e.mapInstanceId);
        mapInstance.dataTheme.dotDensityValueHint = e.dotDensityDotValueHint;
        const renderer = mapInstance.dataTheme.rendering[0];
        renderer.dotValue = getDotValue(mapInstance.initialView.zoom, mapInstance.dataTheme.dotDensityValueHint);
        this.bus.emit('DOT_DENSITY_DOT_VALUE_CHANGED', {
            mapInstanceId: e.mapInstanceId,
            source: this,
            dotValue: renderer.dotValue,
        });
        this.bus.emit('MAP_APPLY_DOT_VALUE_HINT_REQUEST', { mapInstanceId: e.mapInstanceId });
    }

    onMapZoomEnd(e) {
        const mapInstance = this.projectDataSource.getActiveMapInstance(e.source.id);
        if (mapInstance && mapInstance.dataTheme.visualizationType === VisualizationType.DOT_DENSITY) {
            const renderer = mapInstance.dataTheme.rendering[0];
            const newDotValue = getDotValue(e.zoom, mapInstance.dataTheme.dotDensityValueHint);
            if (newDotValue !== renderer.dotValue) {
                renderer.dotValue = newDotValue;
                this.bus.emit('DOT_DENSITY_DOT_VALUE_CHANGED', {
                    mapInstanceId: e.source.id,
                    source: this,
                    dotValue: newDotValue,
                });
            }
        }
    }

    onApplyFiltersRequest(e) {
        const mapInstance = this.projectDataSource.getActiveMapInstance(e.mapInstanceId);
        const filterSet = e.filterSet.clone();
        const dataTheme = mapInstance.dataTheme;
        dataTheme.dataClassificationMethod = e.dataClassificationMethod;
        dataTheme.filterSet = filterSet;
        dataTheme.insufficientBase = e.insufficientBase;
        const renderer = dataTheme.rendering[0];
        const isMultiVariable = dataTheme.variableSelection.isMultiVariable;
        const nullRuleIdx = isMultiVariable ? renderer.nullDataRuleIndex : [renderer.nullDataRuleIndex];
        const insuffRuleIdx = isMultiVariable ? renderer.insufficientDataRuleIndex : [renderer.insufficientDataRuleIndex];
        const rulesArrays = isMultiVariable ? renderer.rules : [renderer.rules];
        rulesArrays.forEach((rules, rulesIdx) => {
            const newRules = [];
            const nullRule = rules[nullRuleIdx[rulesIdx]];
            if (nullRule) {
                newRules.push(nullRule);
                nullRuleIdx[rulesIdx] = newRules.length - 1;
            }
            const insuffRule = rules[insuffRuleIdx[rulesIdx]];
            if (insuffRule) {
                if (e.insufficientBase !== undefined) {
                    insuffRule.filter.to = e.insufficientBase;
                }
                newRules.push(insuffRule);
                insuffRuleIdx[rulesIdx] = newRules.length - 1;
            }
            const referentRule = rules.find(r => r !== nullRule && r !== insuffRule);
            filterSet.filters.forEach(f => {
                const newRule = referentRule.clone();
                const newFilter = f.clone();
                newFilter.fieldName = referentRule.filter.fieldName;
                newRule.filter = newFilter;
                newRule.title = DataVisualizationController._figureOutRuleTitle(filterSet, newFilter);
                newRules.push(newRule);
            });
            rulesArrays[rulesIdx] = newRules;
        });
        renderer.nullDataRuleIndex = isMultiVariable ? nullRuleIdx : nullRuleIdx[0];
        renderer.insufficientDataRuleIndex = isMultiVariable ? insuffRuleIdx : insuffRuleIdx[0];
        renderer.rules = isMultiVariable ? rulesArrays : rulesArrays[0];
        const map = this.mapDataSource.currentMaps[mapInstance.currentMapId];
        const colorPalette = this.colorPaletteDataSource.currentColorPalettes.getColorPaletteByTypeAndId(
            dataTheme.colorPaletteType,
            dataTheme.colorPaletteId,
            map.colorPalettesURL);
        renderer.applyColorPalette(colorPalette, dataTheme.colorPaletteFlipped);
        this.bus.emit('MAP_APPLY_CUTPOINTS_REQUEST', {
            source: this,
            mapInstanceId: e.mapInstanceId,
        });
    }

    onProjectLoadSuccess() {
        this.userStyleDataSource.settings = [];
        this.projectDataSource.currentFrame.mapInstances.forEach(mapInstance => {
            const preferredStyleSettings = {};
            this.updatePreferredStyleSettings(preferredStyleSettings, mapInstance);
            this.userStyleDataSource.addFrameSettings(preferredStyleSettings);
        });
    }

    /**
     * Gets called when the user changes between variable type:
     * - Number: shaded map shows absolute values, bubbles show absolute value
     * - Percentage: shaded map shows percentages, bubbles show absolute value and bubble color shows percentages
     * @param {object} param
     * @param {string} param.mapInstanceId
     * @param {string} param.variableType
     */
    onMapVariableTypeChange({ mapInstanceId, variableType }) {
        const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);
        const appliedDataTheme = mapInstance.dataTheme;

        // For dot density do nothing
        if (appliedDataTheme.visualizationType === VisualizationType.DOT_DENSITY) return;

        const preferredStyleSettingsIdx = this.projectDataSource.currentFrame.mapInstances.indexOf(mapInstance);

        // Update the preferred setting
        if (appliedDataTheme.visualizationType === VisualizationType.BUBBLES) {
            this.userStyleDataSource.settings[preferredStyleSettingsIdx].bubbleVariableType = variableType;
        } else {
            this.userStyleDataSource.settings[preferredStyleSettingsIdx].shadedVariableType = variableType;
        }

        // Since the switch changes between percent and number we need to change the filter set
        appliedDataTheme.filterSet = undefined;

        const mapId = appliedDataTheme.variableSelection.items[0].surveyName;
        this.mapDataSource.loadMapByURL(`${AppConfig.constants.mapsURL}/${mapId}.json`).then(async map => {
            if (map.colorPalettes === undefined) {
                await this.colorPaletteDataSource.updateMapColorPalettes(map);
            }
            /** @type {import('../types').DataSelection} */
            const dataSelection = {
                variableSelection: appliedDataTheme.variableSelection,
                mapId,
            };
            this.createDataTheme(
                mapInstance,
                dataSelection,
                appliedDataTheme.visualizationType,
                appliedDataTheme,
            );
        });
    }

    onMapNewDotValueHintApplied(e) {
        const mapInstance = this.projectDataSource.getActiveMapInstance(e.source.id);
        // update preferred style settings
        const preferredStyleSettingsIdx = this.projectDataSource.currentFrame.mapInstances.indexOf(mapInstance);
        this.userStyleDataSource.settings[preferredStyleSettingsIdx].dotDensityDotValueHint = e.dotDensityDotValueHint;
    }

    onMapBubbleSizeChange(e) {
        const mapInstance = this.projectDataSource.getActiveMapInstance(e.mapInstanceId);
        const preferredStyleSettingsIdx = this.projectDataSource.currentFrame.mapInstances.indexOf(mapInstance);
        this.userStyleDataSource.settings[preferredStyleSettingsIdx].bubbleSize = e.bubbleSize;

        const dataTheme = mapInstance.dataTheme;
        if (dataTheme.visualizationType === VisualizationType.BUBBLES) {
            dataTheme.rendering[0].bubbleSizeFactor = e.bubbleSizeFactor;
            this.bus.emit('MAP_APPLY_NEW_BUBBLE_SIZE_FACTOR', {
                mapInstanceId: e.mapInstanceId,
                bubbleSizeFactor: e.bubbleSizeFactor,
            });
        }
    }

    onMapNewDataThemeApplied(e) {
        const mapInstance = this.projectDataSource.getActiveMapInstance(e.source.id);
        const preferredStyleSettingsIdx = this.projectDataSource.currentFrame.mapInstances.indexOf(mapInstance);
        const preferredStyleSettings = this.userStyleDataSource.settings[preferredStyleSettingsIdx];
        if (preferredStyleSettings) this.updatePreferredStyleSettings(preferredStyleSettings, mapInstance);
    }

    onMapNewColorPaletteApplied(e) {
        if (e.isFlipRequest) {
            return;
        }

        const mapInstance = this.projectDataSource.getActiveMapInstance(e.source.id);
        const map = this.mapDataSource.currentMaps[mapInstance.currentMapId];
        const { dataTheme } = mapInstance;

        const colorPalette = this.colorPaletteDataSource.currentColorPalettes.getColorPaletteByTypeAndId(
            dataTheme.colorPaletteType,
            dataTheme.colorPaletteId,
            map.colorPalettesURL);
        const preferredStyleSettingsIdx = this.projectDataSource.currentFrame.mapInstances.indexOf(mapInstance);
        this._updatePreferredColorPalette(preferredStyleSettingsIdx, dataTheme, colorPalette);
    }

    onMapGetCurrentColorPalettes(e) {
        this.bus.emit('CURRENT_COLOR_PALETTES', {
            colorPalettes: this.colorPaletteDataSource.currentColorPalettes,
            selectedColorPalette: this.getSelectedColorPalette(e.mapInstanceId),
            colorPalettesCollection: this.buildColorPalettesCollection(e.mapInstanceId),
            mapInstanceId: e.mapInstanceId,
        });
    }

    onColorPalettesRequest() {
        this.bus.emit('COLOR_PALETTES', { colorPalettes: this.colorPaletteDataSource.currentColorPalettes });
    }

    onApplyColorPaletteRequest(e) {
        const mapInstance = this.projectDataSource.getActiveMapInstance(e.mapInstanceId);
        mapInstance.dataTheme.applyColorPalette(e.colorPalette);
        this.bus.emit('MAP_APPLY_NEW_COLOR_PALETTE_REQUEST', {
            colorPalette: e.colorPalette,
            mapInstanceId: e.mapInstanceId,
        });
    }

    onFlipColorPaletteRequest(e) {
        const mapInstance = this.projectDataSource.getActiveMapInstance(e.mapInstanceId);
        const dataTheme = mapInstance.dataTheme;
        const map = this.mapDataSource.currentMaps[mapInstance.currentMapId];
        const colorPalette = this.colorPaletteDataSource.currentColorPalettes.getColorPaletteByTypeAndId(
            dataTheme.colorPaletteType,
            dataTheme.colorPaletteId,
            map.colorPalettesURL);
        dataTheme.applyColorPalette(colorPalette, !dataTheme.colorPaletteFlipped);
        this.bus.emit('MAP_APPLY_NEW_COLOR_PALETTE_REQUEST', {
            colorPalette,
            colorPaletteFlipped: dataTheme.colorPaletteFlipped,
            isFlipRequest: true,
            mapInstanceId: e.mapInstanceId,
        });
    }

    onEditColorPaletteRequest(e) {
        const changedColorPaletteType = [e.colorPalette.type.toLowerCase()];

        if (RELATED_COLOR_PALETTE_TYPE[e.originalColorPalette.type]) {
            const originalRelatedPalette =
                this.colorPaletteDataSource.currentColorPalettes.getColorPaletteByTypeAndId(
                    RELATED_COLOR_PALETTE_TYPE[e.originalColorPalette.type],
                    e.originalColorPalette.id,
                );
            if (originalRelatedPalette) {
                const newRelatedPalette = e.colorPalette.clone();
                newRelatedPalette.type = originalRelatedPalette.type;

                this.colorPaletteDataSource.editColorPalette(
                    {
                        original: originalRelatedPalette,
                        edited: newRelatedPalette,
                    },
                    this.projectDataSource.currentProject, this.mapDataSource.currentMaps);

                changedColorPaletteType.push(newRelatedPalette.type.toLowerCase());
            }
        }

        this.colorPaletteDataSource.editColorPalette({ original: e.originalColorPalette, edited: e.colorPalette },
            this.projectDataSource.currentProject, this.mapDataSource.currentMaps);
        // check if color palette was applied to any of the frames and reapply it
        this.projectDataSource.currentFrame.mapInstances.forEach(mapInstance => {
            // updated color palette was not applied to this map instance so do nothing
            if (mapInstance.dataTheme.colorPaletteId !== e.colorPalette.id ||
                changedColorPaletteType.every(cpt => cpt !== mapInstance.dataTheme.colorPaletteType.toLowerCase())) {
                return;
            }

            const appliedColorPalette = this.colorPaletteDataSource.currentColorPalettes.getColorPaletteByTypeAndId(
                mapInstance.dataTheme.colorPaletteType,
                mapInstance.dataTheme.colorPaletteId,
            );
            mapInstance.dataTheme.applyColorPalette(appliedColorPalette);
            this.bus.emit('MAP_APPLY_NEW_COLOR_PALETTE_REQUEST', {
                colorPalette: appliedColorPalette,
                mapInstanceId: mapInstance.id,
            });
        });
        const mapInstance = this.projectDataSource.getActiveMapInstance(e.mapInstanceId);
        this.bus.emit('MAP_COLOR_PALETTES_CHANGED', {
            source: mapInstance,
            colorPalettes: this.colorPaletteDataSource.currentColorPalettes,
            selectedColorPalette: this.getSelectedColorPalette(e.mapInstanceId),
            colorPalettesCollection: this.buildColorPalettesCollection(e.mapInstanceId),
        });
    }

    onDeleteColorPaletteRequest(e) {
        const deletedColorPaletteType = [e.colorPalette.type.toLowerCase()];

        if (RELATED_COLOR_PALETTE_TYPE[e.colorPalette.type]) {
            const relatedPalette = this.colorPaletteDataSource.currentColorPalettes.getColorPaletteByTypeAndId(
                RELATED_COLOR_PALETTE_TYPE[e.colorPalette.type],
                e.colorPalette.id,
            );
            if (relatedPalette) {
                this.colorPaletteDataSource.deleteColorPalette(relatedPalette,
                    this.projectDataSource.currentProject,
                    this.mapDataSource.currentMaps);
                deletedColorPaletteType.push(relatedPalette.type.toLowerCase());
            }
        }

        this.colorPaletteDataSource.deleteColorPalette(e.colorPalette,
            this.projectDataSource.currentProject,
            this.mapDataSource.currentMaps);

        // check if deleted color palette was applied on any of the maps and apply new one
        this.projectDataSource.currentFrame.mapInstances.forEach(mapInstance => {
            // deleted color palette was not applied to this mapInstance
            const { dataTheme } = mapInstance;
            const {
                isChangeOverTimeApplied,
                colorPaletteId,
                colorPaletteType,
            } = dataTheme;

            if (colorPaletteId !== e.colorPalette.id ||
                deletedColorPaletteType.every(cpt => cpt !== colorPaletteType.toLowerCase())) {
                return;
            }
            // remove deleted color palettes from preferred settings
            const preferredStyleSettingsIdx = this.projectDataSource.currentFrame.mapInstances.indexOf(mapInstance);
            const preferredSettings = this.userStyleDataSource.settings[preferredStyleSettingsIdx];
            // deleted color palette was applied to this mapInstance so we need to find new one
            const map = this.mapDataSource.currentMaps[mapInstance.currentMapId];
            let newColorPalette;
            let newColorPaletteType;
            switch (colorPaletteType.toLowerCase()) {
            case ColorPaletteType.POLYGON_USER_DEFINED.toLocaleLowerCase():
                preferredSettings.shadedAreaColorPalette = undefined;
                preferredSettings.bubbleRangeColorPalette = undefined;
                newColorPaletteType = isChangeOverTimeApplied ? ColorPaletteType.POLYGON_DIVERGING : ColorPaletteType.POLYGON_SEQUENTIAL;
                newColorPalette = this.colorPaletteDataSource.currentColorPalettes.getColorPaletteByType(
                    newColorPaletteType,
                    map.colorPalettesURL);
                break;
            case ColorPaletteType.MULTI_POLYGON_USER_DEFINED.toLocaleLowerCase():
                preferredSettings.multiShadedAreaColorPalette = undefined;
                newColorPalette = this.colorPaletteDataSource.currentColorPalettes.getColorPaletteByType(
                    ColorPaletteType.MULTI_POLYGON,
                    map.colorPalettesURL);
                break;
            case ColorPaletteType.BUBBLE_USER_DEFINED.toLocaleLowerCase():
                preferredSettings.shadedAreaColorPalette = undefined;
                preferredSettings.bubbleRangeColorPalette = undefined;
                newColorPalette = this.colorPaletteDataSource.currentColorPalettes.getColorPaletteByType(
                    ColorPaletteType.BUBBLE_SEQUENTIAL,
                    map.colorPalettesURL);
                break;
            case ColorPaletteType.BUBBLE_USER_DEFINED_SINGLE_COLOR.toLocaleLowerCase():
                preferredSettings.bubbleSingleColorPalette = undefined;
                preferredSettings.dotDensityColorPalette = undefined;
                newColorPalette = this.colorPaletteDataSource.currentColorPalettes.getColorPaletteByType(
                    ColorPaletteType.BUBBLE_SINGLE_COLOR,
                    map.colorPalettesURL);
                break;
            case ColorPaletteType.DOT_DENSITY_USER_DEFINED.toLocaleLowerCase():
                preferredSettings.bubbleSingleColorPalette = undefined;
                preferredSettings.dotDensityColorPalette = undefined;
                newColorPalette = this.colorPaletteDataSource.currentColorPalettes.getColorPaletteByType(
                    ColorPaletteType.DOT_DENSITY,
                    map.colorPalettesURL);
                break;
            case ColorPaletteType.VALUE_DOT_DENSITY_USER_DEFINED.toLocaleLowerCase():
                preferredSettings.valueDotDensityColorPalette = undefined;
                newColorPalette = this.colorPaletteDataSource.currentColorPalettes.getColorPaletteByType(
                    ColorPaletteType.VALUE_DOT_DENSITY,
                    map.colorPalettesURL);
                break;
            }

            dataTheme.applyColorPalette(newColorPalette);
            this.bus.emit('MAP_APPLY_NEW_COLOR_PALETTE_REQUEST', {
                colorPalette: newColorPalette,
                mapInstanceId: mapInstance.id,
            });
        });

        const mapInstance = this.projectDataSource.getActiveMapInstance(e.mapInstanceId);
        this.bus.emit('MAP_COLOR_PALETTES_CHANGED', {
            source: mapInstance,
            colorPalettes: this.colorPaletteDataSource.currentColorPalettes,
            selectedColorPalette: this.getSelectedColorPalette(e.mapInstanceId),
            colorPalettesCollection: this.buildColorPalettesCollection(e.mapInstanceId),
        });
    }

    onCreateColorPaletteRequest(e) {
        if (RELATED_COLOR_PALETTE_TYPE[e.colorPalette.type]) {
            const newRelatedPalette = e.colorPalette.clone();
            newRelatedPalette.type = RELATED_COLOR_PALETTE_TYPE[e.colorPalette.type];

            this.colorPaletteDataSource.addColorPalette(newRelatedPalette,
                this.projectDataSource.currentProject,
                this.mapDataSource.currentMaps);
        }

        this.colorPaletteDataSource.addColorPalette(e.colorPalette,
            this.projectDataSource.currentProject,
            this.mapDataSource.currentMaps);

        const mapInstance = this.projectDataSource.getActiveMapInstance(e.mapInstanceId);
        mapInstance.dataTheme.applyColorPalette(e.colorPalette);
        this.bus.emit('MAP_APPLY_NEW_COLOR_PALETTE_REQUEST', {
            colorPalette: e.colorPalette,
            mapInstanceId: e.mapInstanceId,
        });

        this.bus.emit('MAP_COLOR_PALETTES_CHANGED', {
            source: mapInstance,
            colorPalettes: this.colorPaletteDataSource.currentColorPalettes,
            selectedColorPalette: this.getSelectedColorPalette(e.mapInstanceId),
            colorPalettesCollection: this.buildColorPalettesCollection(e.mapInstanceId),
        });
    }

    onFrameUpdated() {
        this.projectDataSource.currentFrame.mapInstances.forEach((mapInstance, idx) => {
            const preferredStyleSettings = this.userStyleDataSource.settings[idx] ||
                                           cloneHashObject(this.userStyleDataSource.settings[idx - 1]) || {};
            this.updatePreferredStyleSettings(preferredStyleSettings, mapInstance);
            this.userStyleDataSource.settings[idx] = preferredStyleSettings;
        });
        if (this.projectDataSource.currentFrame.mapInstances.length === 1) {
            this.userStyleDataSource.settings = [this.userStyleDataSource.settings[0]];
        }
    }

    onFrameMapsSwapped() {
        if (this.userStyleDataSource.settings) {
            this.userStyleDataSource.settings.reverse();
        }
    }

    onDataSelectionChange(e) {
        e.newDataSelection.mapId = e.newDataSelection.variableSelection.items[0].surveyName;
        const mapInstance = this.projectDataSource.getActiveMapInstance(e.mapInstanceId);
        if (!this.mapDataSource.currentMaps[e.newDataSelection.mapId]) {
            this.mapDataSource.loadMapByURL(`${AppConfig.constants.mapsURL}/${e.newDataSelection.mapId}.json`).then(async map => {
                if (map.colorPalettes === undefined) {
                    await this.colorPaletteDataSource.updateMapColorPalettes(map);
                }

                mapInstance.userEnteredTitle = undefined;
                mapInstance.dataTheme.adjustmentDollarYear = undefined;
                this.figureOutDataTheme(mapInstance, e.newDataSelection, mapInstance.dataTheme);
            });
        } else {
            mapInstance.userEnteredTitle = undefined;
            mapInstance.dataTheme.adjustmentDollarYear = undefined;
            this.figureOutDataTheme(mapInstance, e.newDataSelection, mapInstance.dataTheme, true, true);
        }
        // In case of active report creation mode, upon map render finish, re-set the minimal summary level
        if (mapInstance && mapInstance.isLocationAnalysisActive) {
            this.bus.once('MAP_RENDERED', () => {
                this.bus.emit('SET_MIN_SUMMARY_LEVEL', mapInstance.id);
            });
        }
    }

    onVisualizationTypeChange(e) {
        const mapInstance = this.projectDataSource.getActiveMapInstance(e.mapInstanceId);
        mapInstance.preferredVisualizationType = e.newVisualizationType;

        // if we are also changing the variable value type
        if (e.newVisualizationValueType) {
            const preferredStyleSettingsIdx = this.projectDataSource.currentFrame.mapInstances.indexOf(mapInstance);

            // Update the preferred setting
            if (e.newVisualizationType === VisualizationType.BUBBLES) {
                this.userStyleDataSource.settings[preferredStyleSettingsIdx].bubbleVariableType = e.newVisualizationValueType;
            } else {
                this.userStyleDataSource.settings[preferredStyleSettingsIdx].shadedVariableType = e.newVisualizationValueType;
            }
        }

        this.figureOutDataTheme(mapInstance, mapInstance.dataTheme, mapInstance.dataTheme);
    }

    /**
     * @param {string} mapInstanceId
     * @returns {import('../objects/ColorPalette').default | undefined} selected color palette
     */
    getSelectedColorPalette(mapInstanceId) {
        const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);
        const map = this.mapDataSource.currentMaps[mapInstance.currentMapId];
        return this.colorPaletteDataSource.currentColorPalettes.getColorPaletteByTypeAndId(
            mapInstance.dataTheme.colorPaletteType,
            mapInstance.dataTheme.colorPaletteId,
            map.colorPalettesURL,
        );
    }

    buildColorPalettesCollection(mapInstanceId) {
        const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);
        const map = this.mapDataSource.currentMaps[mapInstance.currentMapId];

        return this.colorPaletteDataSource.buildColorPalettesList(
            mapInstance.dataTheme.variableSelection.isSingleVariable,
            mapInstance.dataTheme.visualizationType,
            mapInstance.dataTheme.bubbleValueType,
            map.colorPalettesURL,
        );
    }

    /**
     * @param {import('../objects/MapInstance').default} mapInstance
     * @param {import('../types').DataSelection} dataSelection
     * @param {import('../objects/DataTheme').default} appliedDataTheme
     * @param {boolean} applyNewDataTheme
     * @param {boolean} usePreferred
     */
    figureOutDataTheme(mapInstance, dataSelection, appliedDataTheme, applyNewDataTheme = true, usePreferred = false) {
        const { variableSelection } = dataSelection;
        const { currentMetadata } = this.metadataDataSource;

        const firstVariableSelectionItem = variableSelection.items[0];
        const metaSurvey = currentMetadata.surveys[firstVariableSelectionItem.surveyName];
        const metaDataset = metaSurvey.datasets[firstVariableSelectionItem.datasetAbbreviation];
        const metaTable = metaDataset.getTableByGuid(firstVariableSelectionItem.tableGuid);
        const metaVariable = metaTable.getVariableByGuid(firstVariableSelectionItem.variableGuid);
        const variablePreferredVisualizationInfo = metaVariable.preferredVisualizationInfo();

        // if we came where through data selection change usePreferred == true
        // and if the variable has set preferred visualization options try to use those
        let preferredVisualizationType;
        if (usePreferred && variablePreferredVisualizationInfo) {
            preferredVisualizationType = variablePreferredVisualizationInfo.preferredVisualizationType;
            const preferredStyleSettingsIndex = this.projectDataSource.currentFrame.mapInstances.indexOf(mapInstance);
            this.userStyleDataSource.settings[preferredStyleSettingsIndex].bubbleVariableType = variablePreferredVisualizationInfo.preferredVisualizationValueType;
            this.userStyleDataSource.settings[preferredStyleSettingsIndex].shadedVariableType = variablePreferredVisualizationInfo.preferredVisualizationValueType;
        } else {
            preferredVisualizationType = variableSelection.visualizationType || mapInstance.preferredVisualizationType;
        }

        const oldFirstVariableSelectionItem = appliedDataTheme.variableSelection.items[0];
        const oldMetaSurvey = currentMetadata.surveys[oldFirstVariableSelectionItem.surveyName];
        const oldMetaDataset = oldMetaSurvey.datasets[oldFirstVariableSelectionItem.datasetAbbreviation];
        const oldMetaTable = oldMetaDataset.getTableByGuid(oldFirstVariableSelectionItem.tableGuid);
        const oldMetaVariable = oldMetaTable.getVariableByGuid(oldFirstVariableSelectionItem.variableGuid);

        if (metaVariable.defaultFilterSetName !== oldMetaVariable.defaultFilterSetName) {
            appliedDataTheme.filterSet = undefined;
            appliedDataTheme.insufficientBase = undefined;
        } else if (appliedDataTheme.dataClassificationMethod !== DataClassificationMethod.CATEGORY_DEFAULT) {
            appliedDataTheme.dataClassificationMethod = DataClassificationMethod.CUSTOM;
        }

        if (dataSelection.variableSelection.isMultiVariable) {
            const canUseShaded = canVariableSelectionUseShaded(dataSelection.variableSelection, currentMetadata);

            if (preferredVisualizationType === VisualizationType.BUBBLES) {
                this.createMultiVariableDataTheme(mapInstance, dataSelection, appliedDataTheme, VisualizationType.DOT_DENSITY, applyNewDataTheme);
            } else if (preferredVisualizationType === VisualizationType.SHADED_AREA && canUseShaded) {
                this.createMultiVariableDataTheme(mapInstance, dataSelection, appliedDataTheme, VisualizationType.SHADED_AREA, applyNewDataTheme);
            } else {
                this.createMultiVariableDataTheme(mapInstance, dataSelection, appliedDataTheme, VisualizationType.DOT_DENSITY, applyNewDataTheme);
            }
        } else if ((appliedDataTheme.visualizationType === VisualizationType.BUBBLES || appliedDataTheme.visualizationType === VisualizationType.DOT_DENSITY) && !metaVariable.canUseDotDensity()) {
            this.createDataTheme(mapInstance, dataSelection, VisualizationType.SHADED_AREA, appliedDataTheme, applyNewDataTheme);
        } else {
            this.createDataTheme(mapInstance, dataSelection, preferredVisualizationType, appliedDataTheme, applyNewDataTheme);
        }
    }

    createMultiVariableDataTheme(mapInstance, dataSelection, appliedDataTheme, visualizationType, applyNewDataTheme = true) {
        const newMap = this.mapDataSource.currentMaps[dataSelection.mapId || mapInstance.currentMapId];
        const preferredStyleSettingsIndex = this.projectDataSource.currentFrame.mapInstances.indexOf(mapInstance);

        if (newMap.colorPalettesURL !== this.mapDataSource.currentMaps[mapInstance.currentMapId].colorPalettesURL) {
            // invalidate preferred style settings
            this._invalidatePreferredColorPalette(preferredStyleSettingsIndex);
        }
        const firstVariableSelectionItem = dataSelection.variableSelection.items[0];
        const metadata = this.metadataDataSource.currentMetadata;
        const metaSurvey = metadata.surveys[firstVariableSelectionItem.surveyName];
        const metaDataset = metaSurvey.datasets[firstVariableSelectionItem.datasetAbbreviation];
        const metaTable = metaDataset.getTableByGuid(firstVariableSelectionItem.tableGuid);
        const metaVariable = metaTable.getVariableByGuid(firstVariableSelectionItem.variableGuid);
        let filterSet;
        if (appliedDataTheme && appliedDataTheme.filterSet) {
            filterSet = appliedDataTheme.filterSet.clone();
        } else {
            const categoryFilters = metadata.systemCategoryFilters.find(c => c.name === metaVariable.defaultFilterSetName) || metadata.systemCategoryFilters[0];
            filterSet = categoryFilters.filterSets[categoryFilters.filterSets.length - 1].clone();
        }
        const fieldList = new FieldList();

        const dataTheme = new DataTheme({
            title: '',
            variableSelection: dataSelection.variableSelection.clone(),
            filterSet,
        });

        if (appliedDataTheme && appliedDataTheme.filterSet) {
            dataTheme.dataClassificationMethod = appliedDataTheme.dataClassificationMethod;
        }

        const geoQNameFieldListField = new FieldListField();
        geoQNameFieldListField.fieldName = metaSurvey.geoQNameField;
        geoQNameFieldListField.surveyName = firstVariableSelectionItem.surveyName;
        geoQNameFieldListField.datasetAbbreviation = firstVariableSelectionItem.datasetAbbreviation;
        geoQNameFieldListField.label = 'Geography full name';
        geoQNameFieldListField.isGeoNameField = true;
        geoQNameFieldListField.hideFromUser = false;

        const fipsFieldListField = new FieldListField();
        fipsFieldListField.fieldName = metaSurvey.geoFipsField;
        fipsFieldListField.surveyName = firstVariableSelectionItem.surveyName;
        fipsFieldListField.datasetAbbreviation = firstVariableSelectionItem.datasetAbbreviation;
        fipsFieldListField.label = 'FIPS';
        fipsFieldListField.isGeoNameField = false;
        fipsFieldListField.hideFromUser = true;

        fieldList.fields = [geoQNameFieldListField, fipsFieldListField];
        const percentBaseVariable = metaVariable.defaultPercentBaseVariable;

        switch (metaVariable.varType) {
        case VariableType.COUNT:
            if (percentBaseVariable) {
                const percentBaseVariableFieldListField = new FieldListField();
                percentBaseVariableFieldListField.fieldName = percentBaseVariable.uuid;
                percentBaseVariableFieldListField.surveyName = firstVariableSelectionItem.surveyName;
                percentBaseVariableFieldListField.datasetAbbreviation = firstVariableSelectionItem.datasetAbbreviation;
                percentBaseVariableFieldListField.label = percentBaseVariable.qLabel;
                percentBaseVariableFieldListField.formatting = percentBaseVariable.formatting || NumberFormat.FORMAT_NUMBER;
                percentBaseVariableFieldListField.isGeoNameField = false;
                percentBaseVariableFieldListField.hideFromUser = false;
                percentBaseVariableFieldListField.percentBaseMin = appliedDataTheme.insufficientBase !== undefined ? appliedDataTheme.insufficientBase : percentBaseVariable.table.percentBaseMin;
                percentBaseVariableFieldListField.universe = true;
                fieldList.fields.push(percentBaseVariableFieldListField);

                const valueFieldList = [];
                const selectedFieldList = [];

                dataSelection.variableSelection.items.forEach(item => {
                    const selectedMetaVariable = metaTable.getVariableByGuid(item.variableGuid);
                    dataTheme.title = `${dataTheme.title} ${percentBaseVariable.getLabel(0, true)}: ${selectedMetaVariable.getLabel(0, true)},`;

                    const changeOverTimeFieldListField = new FieldListField();
                    changeOverTimeFieldListField.fieldName = item.variableGuid;
                    changeOverTimeFieldListField.surveyName = item.surveyName;
                    changeOverTimeFieldListField.datasetAbbreviation = item.datasetAbbreviation;
                    changeOverTimeFieldListField.label = selectedMetaVariable.qLabel;
                    changeOverTimeFieldListField.formatting = selectedMetaVariable.formatting || NumberFormat.FORMAT_NUMBER;
                    changeOverTimeFieldListField.isGeoNameField = false;
                    changeOverTimeFieldListField.hideFromUser = false;

                    if (selectedMetaVariable.isVirtual) {
                        changeOverTimeFieldListField.isVirtual = true;
                        changeOverTimeFieldListField.expression = selectedMetaVariable.expression;
                    }

                    // Previous logic for this part was faulty because it only took into consideration if the
                    // first variable had a defaultPercentBaseVariable and if so calculated the percentage
                    // for all the other selected variables.
                    // This caused problems when there was a mix of variables (See year 2020 COVID-19 Insights.
                    // For example if the selection consisted of:
                    // Population - White Alone, Population - Black or African American Alone which are COUNT
                    // variables and a variable that is CURRENCY like Median Income we calculated the percentage
                    // of Median Income in Total population which doesn't make sense.
                    // Now it's changed so that we check for each variable if it has a defaultPercentBaseVariable.
                    const selectedVarBaseVariable = selectedMetaVariable.defaultPercentBaseVariable;
                    if (selectedVarBaseVariable) {
                        const computedFieldListField = new FieldListField();
                        const percentBaseVariableQualifiedName = `${firstVariableSelectionItem.surveyName}.${firstVariableSelectionItem.datasetAbbreviation}.${selectedVarBaseVariable.uuid}`;

                        computedFieldListField.fieldName = `X${item.variableGuid}`;
                        computedFieldListField.isComputed = true;
                        computedFieldListField.computeFunction = 'COMPUTE_PERCENT';
                        computedFieldListField.fieldNumerator = item.qualifiedName;
                        computedFieldListField.fieldDenominator = percentBaseVariableQualifiedName;
                        computedFieldListField.surveyName = item.surveyName;
                        computedFieldListField.datasetAbbreviation = item.datasetAbbreviation;
                        computedFieldListField.label = `% ${selectedMetaVariable.qLabel} (percent of: ${selectedVarBaseVariable.qLabel})`;
                        computedFieldListField.formatting = NumberFormat.FORMAT_PERCENT;
                        computedFieldListField.isGeoNameField = false;
                        computedFieldListField.hideFromUser = false;

                        valueFieldList.push(computedFieldListField);
                        selectedFieldList.push(changeOverTimeFieldListField);
                        fieldList.fields.push(changeOverTimeFieldListField);
                        fieldList.fields.push(computedFieldListField);
                    } else {
                        selectedFieldList.push(changeOverTimeFieldListField);
                        fieldList.fields.push(changeOverTimeFieldListField);
                    }
                });
                filterSet.valueFormat = filterSet.valueFormat || NumberFormat.FORMAT_PERCENT;

                if (visualizationType === VisualizationType.BUBBLES) {
                    console.warn('Cannot visualize this');
                } else if (visualizationType === VisualizationType.SHADED_AREA) {
                    dataTheme.title = dataTheme.title.slice(0, -1);
                    const multiValueRenderer = DataVisualizationController._createPercentMultiValueRenderer(fieldList, selectedFieldList, valueFieldList, percentBaseVariableFieldListField, filterSet);
                    dataTheme.rendering = [multiValueRenderer];
                    this._applyRendererColorPalette(dataTheme, newMap, preferredStyleSettingsIndex, dataSelection.variableSelection, appliedDataTheme, metaTable, metaVariable);
                    const eventName = applyNewDataTheme ? 'APPLY_NEW_DATA_THEME_REQUEST' : 'FIGURE_OUT_DATA_THEME_SUCCESS';
                    this.bus.emit(eventName, {
                        source: this,
                        mapInstanceId: mapInstance.id,
                        dataTheme,
                        mapId: dataSelection.mapId || mapInstance.currentMapId,
                    });
                } else if (visualizationType === VisualizationType.DOT_DENSITY) {
                    dataTheme.title = dataTheme.title.slice(0, -1);
                    dataTheme.dotDensityValueHint = this.userStyleDataSource.settings[preferredStyleSettingsIndex].dotDensityDotValueHint;
                    const valueDotDensityRenderer = DataVisualizationController._createValueDotDensityRenderer(fieldList, selectedFieldList, mapInstance.initialView.zoom, dataTheme.dotDensityValueHint);
                    dataTheme.rendering = [valueDotDensityRenderer];
                    this._applyRendererColorPalette(dataTheme, newMap, preferredStyleSettingsIndex, dataSelection.variableSelection, appliedDataTheme, metaTable, metaVariable);
                    const eventName = applyNewDataTheme ? 'APPLY_NEW_DATA_THEME_REQUEST' : 'FIGURE_OUT_DATA_THEME_SUCCESS';
                    this.bus.emit(eventName, {
                        source: this,
                        mapInstanceId: mapInstance.id,
                        dataTheme,
                        mapId: dataSelection.mapId || mapInstance.currentMapId,
                    });
                }
            } else {
                const selectedFieldList = [];
                dataSelection.variableSelection.items.forEach(item => {
                    const selectedMetaVariable = metaTable.getVariableByGuid(item.variableGuid);
                    const changeOverTimeFieldListField = new FieldListField();
                    dataTheme.title = `${dataTheme.title} ${selectedMetaVariable.getLabel(0, true)},`;
                    changeOverTimeFieldListField.fieldName = item.variableGuid;
                    changeOverTimeFieldListField.surveyName = item.surveyName;
                    changeOverTimeFieldListField.datasetAbbreviation = item.datasetAbbreviation;
                    changeOverTimeFieldListField.label = selectedMetaVariable.qLabel;
                    changeOverTimeFieldListField.formatting = selectedMetaVariable.formatting || NumberFormat.FORMAT_NUMBER;
                    changeOverTimeFieldListField.isGeoNameField = false;
                    changeOverTimeFieldListField.hideFromUser = false;

                    if (selectedMetaVariable.isVirtual) {
                        changeOverTimeFieldListField.isVirtual = true;
                        changeOverTimeFieldListField.expression = selectedMetaVariable.expression;
                    }

                    selectedFieldList.push(changeOverTimeFieldListField);
                    fieldList.fields.push(changeOverTimeFieldListField);
                });
                if (visualizationType === VisualizationType.BUBBLES) {
                    console.warn('Can not use this visualization for currently selected variables.');
                } else if (visualizationType === VisualizationType.SHADED_AREA) {
                    console.warn('Can not use this visualization for currently selected variables.');
                } else if (visualizationType === VisualizationType.DOT_DENSITY) {
                    dataTheme.title = dataTheme.title.slice(0, -1);
                    dataTheme.dotDensityValueHint = this.userStyleDataSource.settings[preferredStyleSettingsIndex].dotDensityDotValueHint;
                    const valueDotDensityRenderer = DataVisualizationController._createValueDotDensityRenderer(fieldList, selectedFieldList, mapInstance.initialView.zoom, dataTheme.dotDensityValueHint);
                    dataTheme.rendering = [valueDotDensityRenderer];
                    this._applyRendererColorPalette(dataTheme, newMap, preferredStyleSettingsIndex, dataSelection.variableSelection, appliedDataTheme, metaTable, metaVariable);
                    const eventName = applyNewDataTheme ? 'APPLY_NEW_DATA_THEME_REQUEST' : 'FIGURE_OUT_DATA_THEME_SUCCESS';
                    this.bus.emit(eventName, {
                        source: this,
                        mapInstanceId: mapInstance.id,
                        dataTheme,
                        mapId: dataSelection.mapId || mapInstance.currentMapId,
                    });
                }
            }
            break;
        case VariableType.MEDIAN:
        case VariableType.PERCENT:
        case VariableType.AVERAGE:
        case VariableType.COMPUTED_INDEX:
        case VariableType.QUINTILE:
        case VariableType.RATE:
            if (visualizationType === VisualizationType.SHADED_AREA) {
                const valueFieldList = [];

                dataSelection.variableSelection.items.forEach(item => {
                    const selectedMetaVariable = metaTable.getVariableByGuid(item.variableGuid);
                    dataTheme.title = `${dataTheme.title} ${selectedMetaVariable.getLabel(0, true)},`;
                    const changeOverTimeFieldListField = new FieldListField();
                    changeOverTimeFieldListField.fieldName = item.variableGuid;
                    changeOverTimeFieldListField.surveyName = item.surveyName;
                    changeOverTimeFieldListField.datasetAbbreviation = item.datasetAbbreviation;
                    changeOverTimeFieldListField.label = selectedMetaVariable.qLabel;
                    changeOverTimeFieldListField.formatting = selectedMetaVariable.formatting || NumberFormat.FORMAT_NUMBER;
                    changeOverTimeFieldListField.isGeoNameField = false;
                    changeOverTimeFieldListField.hideFromUser = false;

                    if (selectedMetaVariable.isVirtual) {
                        changeOverTimeFieldListField.isVirtual = true;
                        changeOverTimeFieldListField.expression = selectedMetaVariable.expression;
                    }

                    valueFieldList.push(changeOverTimeFieldListField);
                    fieldList.fields.push(changeOverTimeFieldListField);
                });
                const multiValueRenderer = DataVisualizationController._createMultiValueRenderer(fieldList, valueFieldList, filterSet);
                dataTheme.title = dataTheme.title.slice(0, -1);
                dataTheme.rendering = [multiValueRenderer];
                this._applyRendererColorPalette(dataTheme, newMap, preferredStyleSettingsIndex, dataSelection.variableSelection, appliedDataTheme, metaTable, metaVariable);
                const eventName = applyNewDataTheme ? 'APPLY_NEW_DATA_THEME_REQUEST' : 'FIGURE_OUT_DATA_THEME_SUCCESS';
                this.bus.emit(eventName, {
                    source: this,
                    mapInstanceId: mapInstance.id,
                    dataTheme,
                    mapId: dataSelection.mapId || mapInstance.currentMapId,
                });
            } else {
                console.warn('Can not use this visualization for currently selected variables.');
            }
            break;
        case VariableType.STANDARD_ERROR:
            console.warn('Can not use this visualization for currently selected variable.');
            break;
        case VariableType.NONE:
            console.warn('Can not use this visualization for currently selected variables.');
            break;
        }
    }

    /**
     *
     * @param {import('../objects/MapInstance').default} mapInstance
     * @param {import('../types').DataSelection} dataSelection
     * @param {string} visualizationType
     * @param {import('../objects/DataTheme').default} appliedDataTheme
     * @param {boolean} applyNewDataTheme
     */
    createDataTheme(mapInstance, dataSelection, visualizationType, appliedDataTheme, applyNewDataTheme = true) {
        const baseMaps = this.mapDataSource.currentMaps;
        const newMap = baseMaps[dataSelection.mapId || mapInstance.currentMapId];
        const preferredStyleSettingsIndex = this.projectDataSource.currentFrame.mapInstances.indexOf(mapInstance);

        if (newMap.colorPalettesURL !== this.mapDataSource.currentMaps[mapInstance.currentMapId].colorPalettesURL) {
            // invalidate preferred style settings
            this._invalidatePreferredColorPalette(preferredStyleSettingsIndex);
        }
        const metadata = this.metadataDataSource.currentMetadata;
        const currentFrame = this.projectDataSource.currentFrame;
        const firstVariableSelectionItem = dataSelection.variableSelection.items[0];

        const metaSurvey = metadata.surveys[firstVariableSelectionItem.surveyName];
        const metaDataset = metaSurvey.datasets[firstVariableSelectionItem.datasetAbbreviation];
        const metaTable = metaDataset.getTableByGuid(firstVariableSelectionItem.tableGuid);
        const metaVariable = metaTable.getVariableByGuid(firstVariableSelectionItem.variableGuid);
        const metaVariableLabel = metaVariable.getLabel(0, true);

        const preferredBubbleVariableType = this.userStyleDataSource.settings[preferredStyleSettingsIndex].bubbleVariableType;
        const preferredShadedVariableType = this.userStyleDataSource.settings[preferredStyleSettingsIndex].shadedVariableType;

        // when switching between bubbles and shaded we need to reset the filter set
        // this is especially important when switching from auto adjusted number scale to bubbles percentage scale
        if (preferredBubbleVariableType !== preferredShadedVariableType) {
            appliedDataTheme.filterSet = undefined;
        }

        // Let's figure out the filter set
        /** @type {import('../objects/FilterSet').default} */
        let filterSet;
        if (appliedDataTheme && appliedDataTheme.filterSet) {
            filterSet = appliedDataTheme.filterSet.clone();
        } else {
            // find the system category filter that corresponds the variable default filter set
            // and if not provided or found, just use the fist system category filter
            const categoryFilters =
                metadata.systemCategoryFilters.find(
                    c => c.name === metaVariable.defaultFilterSetName,
                ) || metadata.systemCategoryFilters[0];
            // use the last filter set from the category filters filter sets
            filterSet = categoryFilters.filterSets[categoryFilters.filterSets.length - 1].clone();
        }

        // Let's create a new data theme
        const dataTheme = new DataTheme({
            title: metaVariableLabel,
            variableSelection: dataSelection.variableSelection.clone(),
            filterSet,
            insufficientBase: appliedDataTheme.insufficientBase, // if the new selected variable default filter set name is not equal to the default filter set name of the previous variable, this will be undefined
            // add adjustment dollar year if exists to new theme
            adjustmentDollarYear: appliedDataTheme.adjustmentDollarYear,
        });

        if (appliedDataTheme && appliedDataTheme.filterSet) {
            dataTheme.dataClassificationMethod = appliedDataTheme.dataClassificationMethod;
        }

        // Let's create the default field list field
        const defaultFieldListField = new FieldListField();
        defaultFieldListField.fieldName = firstVariableSelectionItem.variableGuid;
        defaultFieldListField.surveyName = firstVariableSelectionItem.surveyName;
        defaultFieldListField.datasetAbbreviation = firstVariableSelectionItem.datasetAbbreviation;
        defaultFieldListField.label = metaVariable.qLabel;
        defaultFieldListField.formatting = metaVariable.formatting || NumberFormat.FORMAT_NUMBER;
        defaultFieldListField.isGeoNameField = false;
        defaultFieldListField.hideFromUser = false;

        if (metaVariable.isVirtual) {
            defaultFieldListField.isVirtual = true;
            defaultFieldListField.expression = metaVariable.expression;
        }

        // Let's create the geoQName field list field
        const geoQNameFieldListField = new FieldListField();
        geoQNameFieldListField.fieldName = metaSurvey.geoQNameField;
        geoQNameFieldListField.surveyName = firstVariableSelectionItem.surveyName;
        geoQNameFieldListField.datasetAbbreviation = firstVariableSelectionItem.datasetAbbreviation;
        geoQNameFieldListField.label = 'Geography full name';
        geoQNameFieldListField.isGeoNameField = true;
        geoQNameFieldListField.hideFromUser = false;

        // Let's create the FIPS field list field
        const fipsFieldListField = new FieldListField();
        fipsFieldListField.fieldName = metaSurvey.geoFipsField;
        fipsFieldListField.surveyName = firstVariableSelectionItem.surveyName;
        fipsFieldListField.datasetAbbreviation = firstVariableSelectionItem.datasetAbbreviation;
        fipsFieldListField.label = 'FIPS';
        fipsFieldListField.isGeoNameField = false;
        fipsFieldListField.hideFromUser = true;

        // Let's create the field list
        const fieldList = new FieldList();
        fieldList.fields = [geoQNameFieldListField, fipsFieldListField, defaultFieldListField];

        // Now we will figure out if the selected variable has a overridden percent base variable or a default set percent base variable
        let percentBaseVariableUserOverride;
        if (firstVariableSelectionItem.percentBaseVariableGuidUserOverride) {
            percentBaseVariableUserOverride = metaTable.getVariableByGuid(firstVariableSelectionItem.percentBaseVariableGuidUserOverride);
        }
        /** @type {import('../objects/MetaVariable').default | undefined } */
        const percentBaseVariable = percentBaseVariableUserOverride || metaVariable.defaultPercentBaseVariable;

        switch (metaVariable.varType) {
        case VariableType.COUNT:
            // ---------------------------------------------------------
            // VISUALIZATION WITH PERCENT BASE VARIABLE
            // ---------------------------------------------------------
            if (percentBaseVariable) {
                dataTheme.title = `${percentBaseVariable.getLabel(0, true)}: ${dataTheme.title}`;
                // Create a new field for the percent based variable
                const percentBaseVariableFieldListField = new FieldListField();
                percentBaseVariableFieldListField.fieldName = percentBaseVariable.uuid;
                percentBaseVariableFieldListField.surveyName = firstVariableSelectionItem.surveyName;
                percentBaseVariableFieldListField.datasetAbbreviation = firstVariableSelectionItem.datasetAbbreviation;
                percentBaseVariableFieldListField.label = percentBaseVariable.qLabel;
                percentBaseVariableFieldListField.formatting = percentBaseVariable.formatting || NumberFormat.FORMAT_NUMBER;
                percentBaseVariableFieldListField.isGeoNameField = false;
                percentBaseVariableFieldListField.hideFromUser = false;
                percentBaseVariableFieldListField.percentBaseMin =
                    appliedDataTheme.insufficientBase !== undefined
                        ? appliedDataTheme.insufficientBase
                        : percentBaseVariable.table.percentBaseMin;
                percentBaseVariableFieldListField.universe = true;

                // Create a new field for the percentage computation variable
                const computedFieldListField = new FieldListField();
                computedFieldListField.fieldName = `X${firstVariableSelectionItem.variableGuid}`;
                computedFieldListField.isComputed = true;
                computedFieldListField.computeFunction = 'COMPUTE_PERCENT';
                computedFieldListField.fieldNumerator = firstVariableSelectionItem.qualifiedName;
                computedFieldListField.fieldDenominator = percentBaseVariableFieldListField.qualifiedName;
                computedFieldListField.surveyName = firstVariableSelectionItem.surveyName;
                computedFieldListField.datasetAbbreviation = firstVariableSelectionItem.datasetAbbreviation;
                computedFieldListField.label = `% ${metaVariable.qLabel} (percent of: ${percentBaseVariable.qLabel})`;
                computedFieldListField.formatting = NumberFormat.FORMAT_PERCENT;
                computedFieldListField.isGeoNameField = false;
                computedFieldListField.hideFromUser = false;

                // Update the filter set value format
                filterSet.valueFormat = filterSet.valueFormat || NumberFormat.FORMAT_PERCENT;

                if (visualizationType === VisualizationType.BUBBLES) {
                    // ---------------------------------------------------------
                    // BUBBLES
                    // ---------------------------------------------------------
                    const bubbleSize = this.userStyleDataSource.getBubbleSize(mapInstance, appliedDataTheme, metadata, currentFrame);
                    const bubbleSizeFactor = bubbleSize / metaVariable.bubbleSizeScale;
                    // The user has the ability to choose if he/she wants to visualize the calculated percentage or count
                    // Depending on the bubble variable type different renders will be created
                    if (preferredBubbleVariableType === VariableValueType.PERCENT) {
                        fieldList.fields = [
                            geoQNameFieldListField,
                            fipsFieldListField,
                            percentBaseVariableFieldListField,
                            defaultFieldListField,
                            computedFieldListField,
                        ];
                        dataTheme.bubbleValueType = VariableValueType.PERCENT;
                        filterSet.valueFormat = NumberFormat.FORMAT_PERCENT;
                        const percentBubbleRenderer =
                            DataVisualizationController._createPercentBubbleRenderer(
                                fieldList,
                                defaultFieldListField,
                                computedFieldListField,
                                percentBaseVariableFieldListField,
                                filterSet,
                                bubbleSizeFactor,
                            );
                        percentBubbleRenderer.sizeTitle = `${metaVariableLabel}`;
                        percentBubbleRenderer.colorTitle = `Color represents % of  <br><b><font color="#FF0000">${percentBaseVariable.getLabel(0, true)}</font></b>`;
                        dataTheme.rendering = [percentBubbleRenderer];
                    } else {
                        dataTheme.bubbleValueType = VariableValueType.NUMBER;
                        filterSet.valueFormat = NumberFormat.FORMAT_NUMBER;
                        const bubbleRenderer =
                            DataVisualizationController._createDefaultBubbleRenderer(
                                fieldList,
                                defaultFieldListField,
                                bubbleSizeFactor,
                            );
                        bubbleRenderer.sizeTitle = `${metaVariableLabel}`;
                        bubbleRenderer.colorTitle = '';
                        dataTheme.rendering = [bubbleRenderer];
                    }
                    this._applyRendererColorPalette(dataTheme, newMap, preferredStyleSettingsIndex, dataSelection.variableSelection, appliedDataTheme, metaTable, metaVariable);
                    this._resolveDataThemeAdditionalInfo(dataTheme).then(() => {
                        const eventName = applyNewDataTheme ? 'APPLY_NEW_DATA_THEME_REQUEST' : 'FIGURE_OUT_DATA_THEME_SUCCESS';
                        this.bus.emit(eventName, {
                            source: this,
                            mapInstanceId: mapInstance.id,
                            dataTheme,
                            mapId: dataSelection.mapId || mapInstance.currentMapId,
                        });
                    });
                } else if (visualizationType === VisualizationType.SHADED_AREA) {
                    // ---------------------------------------------------------
                    // SHADED AREA
                    // ---------------------------------------------------------
                    // Check if the user selected the option so show percentage or the absolute value
                    if (preferredShadedVariableType === VariableValueType.PERCENT) {
                        fieldList.fields = [
                            geoQNameFieldListField,
                            fipsFieldListField,
                            percentBaseVariableFieldListField,
                            defaultFieldListField,
                            computedFieldListField,
                        ];
                        dataTheme.shadedValueType = VariableValueType.PERCENT;
                        filterSet.valueFormat = NumberFormat.FORMAT_PERCENT;
                        const valueRenderer = DataVisualizationController._createValueRenderer(fieldList, computedFieldListField, filterSet);
                        const insufficientDataFilter = new Filter();
                        insufficientDataFilter.comparisonType = FilterComparisonType.MATCH_RANGE;
                        insufficientDataFilter.to = percentBaseVariableFieldListField.percentBaseMin;
                        insufficientDataFilter.fieldName = percentBaseVariableFieldListField.qualifiedName;
                        insufficientDataFilter.valueFormat = NumberFormat.FORMAT_NUMBER;

                        const brush = new Brush({
                            fillColor: 0,
                            fillOpacity: 1,
                            minZoom: 0,
                            maxZoom: 20,
                        });

                        const polygonSymbol = new Symbol();
                        polygonSymbol.type = 'PolygonSymbol';
                        polygonSymbol.brushes = [brush];

                        const insufficientDataRule = new FilterRule();
                        insufficientDataRule.fieldQualifiedName = defaultFieldListField.qualifiedName;
                        insufficientDataRule.filter = insufficientDataFilter;
                        insufficientDataRule.displayInLegend = false;
                        insufficientDataRule.symbols = [polygonSymbol];
                        insufficientDataRule.zoomMin = 0;
                        insufficientDataRule.zoomMax = 20;
                        insufficientDataRule.title = 'Insufficient data';

                        valueRenderer.rules.unshift(insufficientDataRule);
                        valueRenderer.insufficientDataRuleIndex = 0;
                        valueRenderer.nullDataRuleIndex = 1;

                        dataTheme.rendering = [valueRenderer];
                    } else {
                        dataTheme.shadedValueType = VariableValueType.NUMBER;
                        filterSet.valueFormat = NumberFormat.FORMAT_NUMBER;
                        // create number shaded renderer
                        const valueRenderer = DataVisualizationController._createValueRenderer(fieldList, defaultFieldListField, filterSet);
                        dataTheme.rendering = [valueRenderer];
                    }

                    this._applyRendererColorPalette(dataTheme, newMap, preferredStyleSettingsIndex, dataSelection.variableSelection, appliedDataTheme, metaTable, metaVariable);
                    this._resolveDataThemeAdditionalInfo(dataTheme).then(() => {
                        const eventName = applyNewDataTheme ? 'APPLY_NEW_DATA_THEME_REQUEST' : 'FIGURE_OUT_DATA_THEME_SUCCESS';
                        this.bus.emit(eventName, {
                            source: this,
                            mapInstanceId: mapInstance.id,
                            dataTheme,
                            mapId: dataSelection.mapId || mapInstance.currentMapId,
                        });
                    });
                } else if (visualizationType === VisualizationType.DOT_DENSITY) {
                    // ---------------------------------------------------------
                    // DOT DENSITY
                    // ---------------------------------------------------------
                    // For dot density we show the both the percentage and absolute value
                    fieldList.fields = [
                        geoQNameFieldListField,
                        fipsFieldListField,
                        percentBaseVariableFieldListField,
                        defaultFieldListField,
                        computedFieldListField,
                    ];
                    dataTheme.dotDensityValueHint = this.userStyleDataSource.settings[preferredStyleSettingsIndex].dotDensityDotValueHint;
                    const dotDensityRenderer = DataVisualizationController._createDotDensityRenderer(fieldList, defaultFieldListField, mapInstance.initialView.zoom, dataTheme.dotDensityValueHint);
                    dataTheme.rendering = [dotDensityRenderer];
                    this._applyRendererColorPalette(dataTheme, newMap, preferredStyleSettingsIndex, dataSelection.variableSelection, appliedDataTheme, metaTable, metaVariable);
                    this._resolveDataThemeAdditionalInfo(dataTheme).then(() => {
                        const eventName = applyNewDataTheme ? 'APPLY_NEW_DATA_THEME_REQUEST' : 'FIGURE_OUT_DATA_THEME_SUCCESS';
                        this.bus.emit(eventName, {
                            source: this,
                            mapInstanceId: mapInstance.id,
                            dataTheme,
                            mapId: dataSelection.mapId || mapInstance.currentMapId,
                        });
                    });
                } else {
                    console.warn('cannot use show this variable', metaVariable);
                }
            } else if (visualizationType === VisualizationType.BUBBLES) {
                // ---------------------------------------------------------
                // BUBBLES NO PERCENT
                // ---------------------------------------------------------
                dataTheme.bubbleValueType = VariableValueType.NUMBER;
                const bubbleSize = this.userStyleDataSource.getBubbleSize(mapInstance, appliedDataTheme, metadata, currentFrame);
                const bubbleSizeFactor = bubbleSize / metaVariable.bubbleSizeScale;
                const bubbleRenderer = DataVisualizationController._createDefaultBubbleRenderer(fieldList, defaultFieldListField, bubbleSizeFactor);
                bubbleRenderer.sizeTitle = `${metaVariableLabel}`;
                bubbleRenderer.colorTitle = '';
                filterSet.valueFormat = filterSet.valueFormat || NumberFormat.FORMAT_NUMBER;
                dataTheme.rendering = [bubbleRenderer];
                this._applyRendererColorPalette(dataTheme, newMap, preferredStyleSettingsIndex, dataSelection.variableSelection, appliedDataTheme, metaTable, metaVariable);
                this._resolveDataThemeAdditionalInfo(dataTheme).then(() => {
                    const eventName = applyNewDataTheme ? 'APPLY_NEW_DATA_THEME_REQUEST' : 'FIGURE_OUT_DATA_THEME_SUCCESS';
                    this.bus.emit(eventName, {
                        source: this,
                        mapInstanceId: mapInstance.id,
                        dataTheme,
                        mapId: dataSelection.mapId || mapInstance.currentMapId,
                    });
                });
            } else if (visualizationType === VisualizationType.SHADED_AREA) {
                // ---------------------------------------------------------
                // SHADED AREA NO PERCENT
                // ---------------------------------------------------------
                dataTheme.shadedValueType = VariableValueType.NUMBER;
                // create number shaded renderer
                const valueRenderer = DataVisualizationController._createValueRenderer(fieldList, defaultFieldListField, filterSet);

                // TODO: Figure out the filter set
                // TODO: Shaded area visualization type is disabled after changing to other visualizations - you can not change it back

                dataTheme.rendering = [valueRenderer];
                this._applyRendererColorPalette(
                    dataTheme,
                    newMap,
                    preferredStyleSettingsIndex,
                    dataSelection.variableSelection,
                    appliedDataTheme,
                    metaTable,
                    metaVariable,
                );
                this._resolveDataThemeAdditionalInfo(dataTheme).then(() => {
                    const eventName = applyNewDataTheme ? 'APPLY_NEW_DATA_THEME_REQUEST' : 'FIGURE_OUT_DATA_THEME_SUCCESS';
                    this.bus.emit(eventName, {
                        source: this,
                        mapInstanceId: mapInstance.id,
                        dataTheme,
                        mapId: dataSelection.mapId || mapInstance.currentMapId,
                    });
                });
            } else if (visualizationType === VisualizationType.DOT_DENSITY) {
                // ---------------------------------------------------------
                // DOT DENSITY NO PERCENT
                // ---------------------------------------------------------
                dataTheme.dotDensityValueHint = this.userStyleDataSource.settings[preferredStyleSettingsIndex].dotDensityDotValueHint;
                const dotDensityRenderer = DataVisualizationController._createDotDensityRenderer(fieldList, defaultFieldListField, mapInstance.initialView.zoom, dataTheme.dotDensityValueHint);
                dataTheme.rendering = [dotDensityRenderer];
                this._applyRendererColorPalette(dataTheme, newMap, preferredStyleSettingsIndex, dataSelection.variableSelection, appliedDataTheme, metaTable, metaVariable);
                this._resolveDataThemeAdditionalInfo(dataTheme).then(() => {
                    const eventName = applyNewDataTheme ? 'APPLY_NEW_DATA_THEME_REQUEST' : 'FIGURE_OUT_DATA_THEME_SUCCESS';
                    this.bus.emit(eventName, {
                        source: this,
                        mapInstanceId: mapInstance.id,
                        dataTheme,
                        mapId: dataSelection.mapId || mapInstance.currentMapId,
                    });
                });
            }
            break;
        case VariableType.MEDIAN:
        case VariableType.PERCENT:
        case VariableType.AVERAGE:
        case VariableType.COMPUTED_INDEX:
        case VariableType.QUINTILE:
        case VariableType.RATE:
            if (metaVariable.parentVariable) dataTheme.title = `${metaVariable.parentVariable.getLabel(0, true)}: ${dataTheme.title}`;
            if (visualizationType === VisualizationType.SHADED_AREA) {
                const valueRenderer = DataVisualizationController._createValueRenderer(fieldList, defaultFieldListField, filterSet);
                filterSet.valueFormat = filterSet.valueFormat || NumberFormat.FORMAT_NUMBER;
                dataTheme.rendering = [valueRenderer];
                this._applyRendererColorPalette(dataTheme, newMap, preferredStyleSettingsIndex, dataSelection.variableSelection, appliedDataTheme, metaTable, metaVariable);
                this._resolveDataThemeAdditionalInfo(dataTheme).then(() => {
                    const eventName = applyNewDataTheme ? 'APPLY_NEW_DATA_THEME_REQUEST' : 'FIGURE_OUT_DATA_THEME_SUCCESS';
                    this.bus.emit(eventName, {
                        source: this,
                        mapInstanceId: mapInstance.id,
                        dataTheme,
                        mapId: dataSelection.mapId || mapInstance.currentMapId,
                    });
                });
            } else {
                console.warn('Can not use this visualization for currently selected variable.');
            }
            break;
        case VariableType.STANDARD_ERROR:
            console.warn('Can not use this visualization for currently selected variable.');
            break;
        case VariableType.NONE:
            if (metaVariable.dataType.toLowerCase() === 'string' && visualizationType === VisualizationType.SHADED_AREA && filterSet.length > 0 && filterSet.filters[0].comparisonType === FilterComparisonType.MATCH_VALUE_STR) {
                fieldList.fields = [geoQNameFieldListField, fipsFieldListField, defaultFieldListField];
                const valueRenderer = DataVisualizationController._createValueRenderer(fieldList, defaultFieldListField, filterSet);
                dataTheme.rendering = [valueRenderer];
                this._applyRendererColorPalette(dataTheme, newMap, preferredStyleSettingsIndex, dataSelection.variableSelection, appliedDataTheme, metaTable, metaVariable);
                this._resolveDataThemeAdditionalInfo(dataTheme).then(() => {
                    const eventName = applyNewDataTheme ? 'APPLY_NEW_DATA_THEME_REQUEST' : 'FIGURE_OUT_DATA_THEME_SUCCESS';
                    this.bus.emit(eventName, {
                        source: this,
                        mapInstanceId: mapInstance.id,
                        dataTheme,
                        mapId: dataSelection.mapId || mapInstance.currentMapId,
                    });
                });
            } else if ((metaVariable.dataType.toLowerCase() === 'int16' || metaVariable.dataType.toLowerCase() === 'int32' || metaVariable.dataType.toLowerCase() === 'double64' || metaVariable.dataType.toLowerCase() === 'float32') &&
                       visualizationType === VisualizationType.SHADED_AREA && filterSet.length > 0 && filterSet.filters[0].comparisonType === FilterComparisonType.MATCH_VALUE_NUM) {
                defaultFieldListField.formatting = undefined;
                fieldList.fields = [geoQNameFieldListField, fipsFieldListField, defaultFieldListField];
                const valueRenderer = DataVisualizationController._createValueRenderer(fieldList, defaultFieldListField, filterSet);
                dataTheme.rendering = [valueRenderer];
                this._applyRendererColorPalette(dataTheme, newMap, preferredStyleSettingsIndex, dataSelection.variableSelection, appliedDataTheme, metaTable, metaVariable);
                this._resolveDataThemeAdditionalInfo(dataTheme).then(() => {
                    const eventName = applyNewDataTheme ? 'APPLY_NEW_DATA_THEME_REQUEST' : 'FIGURE_OUT_DATA_THEME_SUCCESS';
                    this.bus.emit(eventName, {
                        source: this,
                        mapInstanceId: mapInstance.id,
                        dataTheme,
                        mapId: dataSelection.mapId || mapInstance.currentMapId,
                    });
                });
            } else if (visualizationType === VisualizationType.BUBBLES && filterSet.length > 0 &&
                       (filterSet.filters[0].comparisonType === FilterComparisonType.MATCH_VALUE_NUM || filterSet.filters[0].comparisonType === FilterComparisonType.MATCH_VALUE_STR) &&
                       metaVariable.parentVariable) {
                defaultFieldListField.formatting = undefined;
                const universeVariable = metaVariable.parentVariable;
                const universeVariableFieldListField = new FieldListField();
                universeVariableFieldListField.fieldName = universeVariable.uuid;
                universeVariableFieldListField.surveyName = firstVariableSelectionItem.surveyName;
                universeVariableFieldListField.datasetAbbreviation = firstVariableSelectionItem.datasetAbbreviation;
                universeVariableFieldListField.label = universeVariable.qLabel;
                universeVariableFieldListField.formatting = universeVariable.formatting || NumberFormat.FORMAT_NUMBER;
                universeVariableFieldListField.isGeoNameField = false;
                universeVariableFieldListField.hideFromUser = false;
                universeVariableFieldListField.universe = true;

                fieldList.fields = [geoQNameFieldListField, fipsFieldListField, universeVariableFieldListField, defaultFieldListField];

                dataTheme.bubbleValueType = VariableValueType.PERCENT;
                dataTheme.title = metaVariableLabel;

                const bubbleSize = this.userStyleDataSource.getBubbleSize(mapInstance, appliedDataTheme, metadata, currentFrame);
                const bubbleSizeFactor = bubbleSize / metaVariable.bubbleSizeScale;
                const bubbleRenderer = DataVisualizationController._createValueListBubbleRenderer(fieldList, universeVariableFieldListField, defaultFieldListField, filterSet, bubbleSizeFactor);
                bubbleRenderer.sizeTitle = `${universeVariable.getLabel(0, true)}`;
                bubbleRenderer.colorTitle = `Color represents % of  <br><b><font color="#FF0000">${metaVariableLabel}</font></b>`;
                dataTheme.rendering = [bubbleRenderer];
                this._applyRendererColorPalette(dataTheme, newMap, preferredStyleSettingsIndex, dataSelection.variableSelection, appliedDataTheme, metaTable, metaVariable);
                this._resolveDataThemeAdditionalInfo(dataTheme).then(() => {
                    const eventName = applyNewDataTheme ? 'APPLY_NEW_DATA_THEME_REQUEST' : 'FIGURE_OUT_DATA_THEME_SUCCESS';
                    this.bus.emit(eventName, {
                        source: this,
                        mapInstanceId: mapInstance.id,
                        dataTheme,
                        mapId: dataSelection.mapId || mapInstance.currentMapId,
                    });
                });
            } else {
                console.warn('Can not use this visualization for currently selected variable.');
            }
            break;
        default:
            console.warn('cannot visualize this!');
            break;
        }
    }

    _resolveDataThemeAdditionalInfo(dataTheme) {
        return new Promise(resolve => {
            if (!dataTheme.variableSelection.isMultiVariable) {
                const currentMetadataObject = getMetadataObjectsFromVariableSelectionItem(dataTheme.variableSelection.items[0], this.metadataDataSource.currentMetadata);
                const renderer = dataTheme.rendering[0];
                if (currentMetadataObject.variable.parsedCustomTooltip.variables.length === 0) resolve(dataTheme);
                const addFieldCallback = refVariable => {
                    const survey = this.metadataDataSource.currentMetadata.surveys[refVariable.surveyName];
                    const dataset = survey.datasets[refVariable.datasetAbbrevation];
                    const table = dataset.tables[refVariable.tableName];
                    const metaVariable = table.variables[refVariable.variableName];
                    const additionalVariableFieldListField = new FieldListField();
                    additionalVariableFieldListField.fieldName = metaVariable.uuid;
                    additionalVariableFieldListField.surveyName = currentMetadataObject.survey.name;
                    additionalVariableFieldListField.datasetAbbreviation = dataset.abbrevation;
                    additionalVariableFieldListField.label = `#${refVariable.label}#${metaVariable.qLabel}`;
                    additionalVariableFieldListField.formatting = metaVariable.formatting || dataTheme.filterSet.valueFormat;
                    additionalVariableFieldListField.isGeoNameField = false;
                    additionalVariableFieldListField.hideFromUser = true;
                    renderer.fieldList.fields.push(additionalVariableFieldListField);
                };
                currentMetadataObject.variable.parsedCustomTooltip.variables.forEach((refVariable, index) => {
                    const survey = this.metadataDataSource.currentMetadata.surveys[refVariable.surveyName];
                    const dataset = survey.datasets[refVariable.datasetAbbrevation];
                    const table = dataset.tables[refVariable.tableName];
                    if (!table || !table.variables[refVariable.variableName]) {
                        this.metadataDataSource.loadTable(refVariable.surveyName, refVariable.datasetAbbrevation, refVariable.tableName)
                            .then(() => {
                                addFieldCallback(refVariable);
                                if (index === currentMetadataObject.variable.parsedCustomTooltip.variables.length - 1) resolve(dataTheme);
                            });
                    } else {
                        addFieldCallback(refVariable);
                        if (index === currentMetadataObject.variable.parsedCustomTooltip.variables.length - 1) resolve(dataTheme);
                    }
                });
            } else resolve(dataTheme);
        });
    }

    _invalidatePreferredColorPalette(preferredStyleSettingsIndex) {
        this.userStyleDataSource.settings[preferredStyleSettingsIndex].shadedAreaColorPalette = undefined;
        this.userStyleDataSource.settings[preferredStyleSettingsIndex].shadedAreaColorPaletteFlipped = undefined;
        this.userStyleDataSource.settings[preferredStyleSettingsIndex].multiShadedAreaColorPalette = undefined;
        this.userStyleDataSource.settings[preferredStyleSettingsIndex].multiShadedAreaColorPaletteFlipped = undefined;
        this.userStyleDataSource.settings[preferredStyleSettingsIndex].bubbleSingleColorPalette = undefined;
        this.userStyleDataSource.settings[preferredStyleSettingsIndex].bubbleRangeColorPalette = undefined;
        this.userStyleDataSource.settings[preferredStyleSettingsIndex].bubbleRangeColorPaletteFlipped = undefined;
        this.userStyleDataSource.settings[preferredStyleSettingsIndex].dotDensityColorPalette = undefined;
        this.userStyleDataSource.settings[preferredStyleSettingsIndex].valueDotDensityColorPalette = undefined;
        this.userStyleDataSource.settings[preferredStyleSettingsIndex].valueDotDensityColorPaletteFlipped = undefined;
        this.userStyleDataSource.settings[preferredStyleSettingsIndex].colorPaletteFlipped = undefined;
    }

    _updatePreferredColorPalette(preferredStyleSettingsIndex, appliedDataTheme, newColorPalette) {
        const preferredSettings = this.userStyleDataSource.settings[preferredStyleSettingsIndex];
        switch (appliedDataTheme.visualizationType) {
        case VisualizationType.SHADED_AREA:
            switch (newColorPalette.type.toLocaleLowerCase()) {
            case ColorPaletteType.POLYGON_SEQUENTIAL.toLocaleLowerCase():
            case ColorPaletteType.POLYGON_DIVERGING.toLocaleLowerCase():
            case ColorPaletteType.POLYGON_USER_DEFINED.toLocaleLowerCase():
                preferredSettings.shadedAreaColorPalette = newColorPalette;
                preferredSettings.shadedAreaColorPaletteFlipped = appliedDataTheme.colorPaletteFlipped;
                break;
            case ColorPaletteType.MULTI_POLYGON.toLocaleLowerCase():
            case ColorPaletteType.MULTI_POLYGON_USER_DEFINED.toLocaleLowerCase():
                preferredSettings.multiShadedAreaColorPalette = newColorPalette;
                preferredSettings.multiShadedAreaColorPaletteFlipped = appliedDataTheme.colorPaletteFlipped;
                break;
            }
            break;
        case VisualizationType.BUBBLES:
            if (appliedDataTheme.bubbleValueType === VariableValueType.NUMBER) {
                preferredSettings.bubbleSingleColorPalette = newColorPalette;
            } else {
                preferredSettings.bubbleRangeColorPalette = newColorPalette;
                preferredSettings.bubbleRangeColorPaletteFlipped = appliedDataTheme.colorPaletteFlipped;
            }
            break;
        case VisualizationType.DOT_DENSITY:
            switch (newColorPalette.type.toLocaleLowerCase()) {
            case ColorPaletteType.DOT_DENSITY.toLocaleLowerCase():
            case ColorPaletteType.DOT_DENSITY_USER_DEFINED.toLocaleLowerCase():
                preferredSettings.dotDensityColorPalette = newColorPalette;
                break;
            case ColorPaletteType.VALUE_DOT_DENSITY.toLocaleLowerCase():
            case ColorPaletteType.VALUE_DOT_DENSITY_USER_DEFINED.toLocaleLowerCase():
                preferredSettings.valueDotDensityColorPalette = newColorPalette;
                preferredSettings.valueDotDensityColorPaletteFlipped = appliedDataTheme.colorPaletteFlipped;
                break;
            }
            break;
        }
        preferredSettings.colorPaletteFlipped = appliedDataTheme.colorPaletteFlipped;
    }

    /**
     *
     * @param {import('../types').PreferredStyleSettings} preferredStyleSettings
     * @param {import('../objects/MapInstance').default} mapInstance
     */
    updatePreferredStyleSettings(preferredStyleSettings, mapInstance) {
        if (preferredStyleSettings.bubbleSize === undefined) preferredStyleSettings.bubbleSize = this._preferredBubbleSize;
        if (preferredStyleSettings.dotDensityDotValueHint === undefined) preferredStyleSettings.dotDensityDotValueHint = this._preferredDotDensityValueHint;
        if (preferredStyleSettings.bubbleVariableType === undefined) preferredStyleSettings.bubbleVariableType = this._preferredBubbleVariableType;
        if (preferredStyleSettings.shadedVariableType === undefined) preferredStyleSettings.shadedVariableType = this._preferredShadedVariableType;

        if (mapInstance.dataTheme.visualizationType === VisualizationType.BUBBLES) {
            const currentMetadataObject =
                getMetadataObjectsFromVariableSelectionItem(
                    mapInstance.dataTheme.variableSelection.items[0],
                    this.metadataDataSource.currentMetadata,
                );
            preferredStyleSettings.bubbleSize = mapInstance.dataTheme.rendering[0].bubbleSizeFactor * currentMetadataObject.variable.bubbleSizeScale;
            preferredStyleSettings.bubbleVariableType = mapInstance.dataTheme.bubbleValueType || this._preferredBubbleVariableType;
        }

        if (mapInstance.dataTheme.visualizationType === VisualizationType.SHADED_AREA) {
            preferredStyleSettings.shadedVariableType = mapInstance.dataTheme.shadedValueType || this._preferredShadedVariableType;
        }

        if (mapInstance.dataTheme.visualizationType === VisualizationType.DOT_DENSITY) {
            preferredStyleSettings.dotDensityDotValueHint = mapInstance.dataTheme.dotDensityValueHint;
        }

        // TODO: Do we need this line?
        mapInstance.preferredVisualizationType = mapInstance.dataTheme.visualizationType;
    }

    static _figureOutRuleTitle(filterSet, filter) {
        let title;
        if (filter.label && filter.label !== '') {
            title = filter.label;
        } else {
            const valueFormat = (filter.valueFormat && filter.valueFormat !== '') ? filter.valueFormat : filterSet.valueFormat;
            if (filter.from === -Number.MAX_SAFE_INTEGER) {
                title = `< ${format({ number: filter.to, numberFormat: valueFormat })}`;
            } else if (filter.to === Number.MAX_SAFE_INTEGER) {
                title = `> ${format({ number: filter.from, numberFormat: valueFormat })}`;
            } else {
                title = `${format({ number: filter.from, numberFormat: valueFormat })} to ${format({
                    number: filter.to,
                    numberFormat: valueFormat,
                })}`;
            }
        }
        return title;
    }

    static _createDotDensityRenderer(fieldList, variableFieldListField, zoom, dotDensityValueHint) {
        const dotDensityRenderer = new DotDensityRenderer();
        dotDensityRenderer.fieldList = fieldList;
        dotDensityRenderer.dotValueFieldName = variableFieldListField.qualifiedName;
        dotDensityRenderer.visibility = [];
        dotDensityRenderer.dotValue = getDotValue(zoom, dotDensityValueHint);
        const dotDensityBrush = new Brush();
        dotDensityBrush.minZoom = 0;
        dotDensityBrush.maxZoom = 20;
        dotDensityBrush.textSize = 1;
        dotDensityBrush.textColor = 0;

        const dotDensitySymbol = new Symbol();
        dotDensitySymbol.type = 'DotDensitySymbol';
        dotDensitySymbol.brushes = [dotDensityBrush];
        dotDensityRenderer.symbols = [dotDensitySymbol];
        return dotDensityRenderer;
    }

    static _createValueDotDensityRenderer(fieldList, variablesFieldListField, zoom, dotDensityValueHint) {
        const dotDensityRenderer = new ValueDotDensityRenderer();
        dotDensityRenderer.fieldList = fieldList;
        dotDensityRenderer.dotValueFieldNames = variablesFieldListField.map(f => f.qualifiedName);
        dotDensityRenderer.visibility = [];
        dotDensityRenderer.dotValue = getDotValue(zoom, dotDensityValueHint);
        const dotDensityBrush = new Brush();
        dotDensityBrush.minZoom = 0;
        dotDensityBrush.maxZoom = 20;
        dotDensityBrush.textSize = 1;
        dotDensityBrush.textColor = 0;

        const dotDensitySymbol = new Symbol();
        dotDensitySymbol.type = 'DotDensitySymbol';
        dotDensitySymbol.brushes = [dotDensityBrush];
        dotDensityRenderer.symbols = [dotDensitySymbol];
        return dotDensityRenderer;
    }

    static _createValueListBubbleRenderer(fieldList, fieldBubbleSize, fieldBubbleColor, filterSet, bubbleSizeFactor) {
        const bubbleRenderer = new BubbleRenderer();
        bubbleRenderer.fieldList = fieldList;
        bubbleRenderer.bubbleSizeFieldName = fieldBubbleSize.qualifiedName;
        bubbleRenderer.visibility = [];
        bubbleRenderer.bubbleSizeFactor = bubbleSizeFactor;
        bubbleRenderer.rules = filterSet.filters.map(f => {
            f.fieldName = fieldBubbleColor.qualifiedName;

            const bubbleBrush = new Brush();
            bubbleBrush.fillColor = 0;
            bubbleBrush.fillOpacity = 0.8;
            bubbleBrush.strokeColor = '#ffffff';
            bubbleBrush.strokeWidth = 1;
            bubbleBrush.strokeOpacity = 0.25;
            bubbleBrush.minZoom = 0;
            bubbleBrush.maxZoom = 20;

            const bubbleSymbol = new Symbol();
            bubbleSymbol.type = 'BubbleSymbol';
            bubbleSymbol.brushes = [bubbleBrush];

            const rule = new FilterRule();
            rule.fieldQualifiedName = fieldBubbleColor.qualifiedName;
            rule.filter = f;
            rule.symbols = [bubbleSymbol];
            rule.zoomMin = 0;
            rule.zoomMax = 20;
            rule.title = DataVisualizationController._figureOutRuleTitle(filterSet, f);

            return rule;
        });
        return bubbleRenderer;
    }

    static _createPercentBubbleRenderer(fieldList, changeOverTimeFieldListField, computedFieldListField, percentBaseVariableFieldListField, filterSet, bubbleSizeFactor) {
        const bubbleRenderer = new BubbleRenderer();
        bubbleRenderer.fieldList = fieldList;
        bubbleRenderer.bubbleSizeFieldName = changeOverTimeFieldListField.qualifiedName;
        bubbleRenderer.visibility = [];
        bubbleRenderer.bubbleSizeFactor = bubbleSizeFactor;
        bubbleRenderer.rules = filterSet.filters.map(f => {
            f.fieldName = computedFieldListField.qualifiedName;

            const bubbleBrush = new Brush({
                fillColor: 0,
                fillOpacity: 0.8,
                strokeColor: '#ffffff',
                strokeWidth: 1,
                strokeOpacity: 0.25,
                minZoom: 0,
                maxZoom: 20,
            });

            const bubbleSymbol = new Symbol();
            bubbleSymbol.type = 'BubbleSymbol';
            bubbleSymbol.brushes = [bubbleBrush];

            const rule = new FilterRule();
            rule.fieldQualifiedName = changeOverTimeFieldListField.qualifiedName;
            rule.filter = f;
            rule.symbols = [bubbleSymbol];
            rule.zoomMin = 0;
            rule.zoomMax = 20;
            rule.title = DataVisualizationController._figureOutRuleTitle(filterSet, f);

            return rule;
        });

        const insufficientDataFilter = new Filter();
        insufficientDataFilter.comparisonType = FilterComparisonType.MATCH_RANGE;
        insufficientDataFilter.to = percentBaseVariableFieldListField.percentBaseMin;
        insufficientDataFilter.fieldName = percentBaseVariableFieldListField.qualifiedName;
        insufficientDataFilter.valueFormat = NumberFormat.FORMAT_NUMBER;

        const insufficientDataBubbleBrush = new Brush({
            fillColor: 0,
            fillOpacity: 0.8,
            strokeColor: '#ffffff',
            strokeWidth: 1,
            strokeOpacity: 0.25,
            minZoom: 0,
            maxZoom: 20,
        });

        const insufficientDataBubbleSymbol = new Symbol();
        insufficientDataBubbleSymbol.type = 'BubbleSymbol';
        insufficientDataBubbleSymbol.brushes = [insufficientDataBubbleBrush];

        const insufficientDataRule = new FilterRule();
        insufficientDataRule.fieldQualifiedName = changeOverTimeFieldListField.qualifiedName;
        insufficientDataRule.filter = insufficientDataFilter;
        insufficientDataRule.displayInLegend = false;
        insufficientDataRule.symbols = [insufficientDataBubbleSymbol];
        insufficientDataRule.zoomMin = 0;
        insufficientDataRule.zoomMax = 20;
        insufficientDataRule.title = 'Insufficient data';

        bubbleRenderer.rules = [insufficientDataRule].concat(bubbleRenderer.rules);
        bubbleRenderer.insufficientDataRuleIndex = 0;
        return bubbleRenderer;
    }

    static _createDefaultBubbleRenderer(fieldList, changeOverTimeFieldListField, bubbleSizeFactor) {
        // default negative rule
        const bubbleBrushNegative = new Brush({
            fillColor: '#0000FF',
            fillOpacity: 0.8,
            strokeColor: '#ffffff',
            strokeWidth: 1,
            strokeOpacity: 0.25,
            minZoom: 0,
            maxZoom: 20,
        });

        const bubbleNegativeSymbol = new Symbol();
        bubbleNegativeSymbol.type = 'BubbleSymbol';
        bubbleNegativeSymbol.brushes = [bubbleBrushNegative];
        const defaultNegativeBubbleFilter = new Filter();
        defaultNegativeBubbleFilter.fieldName = changeOverTimeFieldListField.qualifiedName;
        defaultNegativeBubbleFilter.to = 0;
        const defaultNegativeBubbleRule = new FilterRule();
        defaultNegativeBubbleRule.fieldQualifiedName = changeOverTimeFieldListField.qualifiedName;
        defaultNegativeBubbleRule.filter = defaultNegativeBubbleFilter;
        defaultNegativeBubbleRule.symbols = [bubbleNegativeSymbol];

        // default positive rule
        const bubbleBrush = new Brush({
            fillColor: '#FF0000',
            fillOpacity: 0.8,
            strokeColor: '#ffffff',
            strokeWidth: 1,
            strokeOpacity: 0.25,
            minZoom: 0,
            maxZoom: 20,
        });

        const bubbleSymbol = new Symbol();
        bubbleSymbol.type = 'BubbleSymbol';
        bubbleSymbol.brushes = [bubbleBrush];
        const defaultBubbleFilter = new Filter();
        defaultBubbleFilter.to = Number.MAX_SAFE_INTEGER;
        defaultBubbleFilter.fieldName = changeOverTimeFieldListField.qualifiedName;

        const defaultBubbleRule = new FilterRule();
        defaultBubbleRule.fieldQualifiedName = changeOverTimeFieldListField.qualifiedName;
        defaultBubbleRule.filter = defaultBubbleFilter;
        defaultBubbleRule.symbols = [bubbleSymbol];

        const bubbleRenderer = new BubbleRenderer();
        bubbleRenderer.fieldList = fieldList;
        bubbleRenderer.bubbleSizeFieldName = changeOverTimeFieldListField.qualifiedName;
        bubbleRenderer.bubbleSizeFactor = bubbleSizeFactor;
        bubbleRenderer.visibility = [];
        bubbleRenderer.rules = [defaultNegativeBubbleRule, defaultBubbleRule];

        return bubbleRenderer;
    }

    static _createPercentMultiValueRenderer(fieldList, selectedFieldList, valueFieldListFields, percentBaseVariableFieldListField, filterSet) {
        const multiValueRenderer = new MultiValueRenderer();
        multiValueRenderer.fieldList = fieldList;
        multiValueRenderer.visibility = [];
        multiValueRenderer.valuesFieldsNames = [];
        multiValueRenderer.nullDataRuleIndex = [];
        multiValueRenderer.insufficientDataRuleIndex = [];
        multiValueRenderer.rules = [];
        valueFieldListFields.forEach((variableFieldListField, idx) => {
            const selectedFieldListField = selectedFieldList[idx];
            multiValueRenderer.valuesFieldsNames.push(variableFieldListField.qualifiedName);
            const fieldRules = [];

            const insufficientDataFilter = new Filter();
            insufficientDataFilter.comparisonType = FilterComparisonType.MATCH_RANGE;
            insufficientDataFilter.to = percentBaseVariableFieldListField.percentBaseMin;
            insufficientDataFilter.fieldName = percentBaseVariableFieldListField.qualifiedName;
            insufficientDataFilter.valueFormat = NumberFormat.FORMAT_NUMBER;

            const brush = new Brush({
                fillColor: 0,
                fillOpacity: 1,
                minZoom: 0,
                maxZoom: 20,
            });

            const polygonSymbol = new Symbol();
            polygonSymbol.type = 'PolygonSymbol';
            polygonSymbol.brushes = [brush];

            const insufficientDataRule = new FilterRule();
            insufficientDataRule.fieldQualifiedName = selectedFieldListField.qualifiedName;
            insufficientDataRule.filter = insufficientDataFilter;
            insufficientDataRule.displayInLegend = false;
            insufficientDataRule.symbols = [polygonSymbol];
            insufficientDataRule.zoomMin = 0;
            insufficientDataRule.zoomMax = 20;
            insufficientDataRule.title = 'Insufficient data';

            fieldRules.push(insufficientDataRule);

            const nullDataFilter = new Filter();
            nullDataFilter.comparisonType = FilterComparisonType.MATCH_NULL;
            nullDataFilter.fieldName = variableFieldListField.qualifiedName;

            const nullBrush = new Brush({
                fillColor: 0,
                fillOpacity: 1,
                minZoom: 0,
                maxZoom: 20,
            });

            const nullPolygonSymbol = new Symbol();
            nullPolygonSymbol.type = 'PolygonSymbol';
            nullPolygonSymbol.brushes = [nullBrush];

            const nullDataRule = new FilterRule();
            nullDataRule.fieldQualifiedName = selectedFieldListField.qualifiedName;
            nullDataRule.filter = nullDataFilter;
            nullDataRule.displayInLegend = false;
            nullDataRule.symbols = [nullPolygonSymbol];
            nullDataRule.zoomMin = 0;
            nullDataRule.zoomMax = 20;
            nullDataRule.title = 'No data';

            fieldRules.push(nullDataRule);

            filterSet.filters.forEach(f => {
                f.fieldName = variableFieldListField.qualifiedName;

                const ruleBrush = new Brush({
                    fillColor: 0,
                    fillOpacity: 1,
                    minZoom: 0,
                    maxZoom: 20,
                });

                const rulePolygonSymbol = new Symbol();
                rulePolygonSymbol.type = 'PolygonSymbol';
                rulePolygonSymbol.brushes = [ruleBrush];

                const rule = new FilterRule();
                rule.fieldQualifiedName = selectedFieldListField.qualifiedName;
                rule.filter = f.clone();
                rule.symbols = [rulePolygonSymbol];
                rule.zoomMin = 0;
                rule.zoomMax = 20;
                rule.title = DataVisualizationController._figureOutRuleTitle(filterSet, f);

                fieldRules.push(rule);
            });
            multiValueRenderer.rules.push(fieldRules);
            multiValueRenderer.insufficientDataRuleIndex.push(0);
            multiValueRenderer.nullDataRuleIndex.push(1);
        });
        return multiValueRenderer;
    }

    static _createMultiValueRenderer(fieldList, valueFieldListFields, filterSet) {
        const multiValueRenderer = new MultiValueRenderer();
        multiValueRenderer.fieldList = fieldList;
        multiValueRenderer.visibility = [];
        multiValueRenderer.valuesFieldsNames = [];
        multiValueRenderer.nullDataRuleIndex = [];
        multiValueRenderer.insufficientDataRuleIndex = [];
        multiValueRenderer.rules = [];
        valueFieldListFields.forEach(variableFieldListField => {
            multiValueRenderer.valuesFieldsNames.push(variableFieldListField.qualifiedName);
            const fieldRules = [];

            const nullDataFilter = new Filter();
            nullDataFilter.comparisonType = FilterComparisonType.MATCH_NULL;
            nullDataFilter.fieldName = variableFieldListField.qualifiedName;

            const nullBrush = new Brush({
                fillColor: 0,
                fillOpacity: 1,
                minZoom: 0,
                maxZoom: 20,
            });

            const nullPolygonSymbol = new Symbol();
            nullPolygonSymbol.type = 'PolygonSymbol';
            nullPolygonSymbol.brushes = [nullBrush];

            const nullDataRule = new FilterRule();
            nullDataRule.fieldQualifiedName = variableFieldListField.qualifiedName;
            nullDataRule.filter = nullDataFilter;
            nullDataRule.displayInLegend = false;
            nullDataRule.symbols = [nullPolygonSymbol];
            nullDataRule.zoomMin = 0;
            nullDataRule.zoomMax = 20;
            nullDataRule.title = 'No data';

            fieldRules.push(nullDataRule);

            filterSet.filters.forEach(f => {
                f.fieldName = variableFieldListField.qualifiedName;

                const ruleBrush = new Brush({
                    fillColor: 0,
                    fillOpacity: 1,
                    minZoom: 0,
                    maxZoom: 20,
                });

                const rulePolygonSymbol = new Symbol();
                rulePolygonSymbol.type = 'PolygonSymbol';
                rulePolygonSymbol.brushes = [ruleBrush];

                const rule = new FilterRule();
                rule.fieldQualifiedName = variableFieldListField.qualifiedName;
                rule.filter = f.clone();
                rule.symbols = [rulePolygonSymbol];
                rule.zoomMin = 0;
                rule.zoomMax = 20;
                rule.title = DataVisualizationController._figureOutRuleTitle(filterSet, f);

                fieldRules.push(rule);
            });
            multiValueRenderer.rules.push(fieldRules);
            multiValueRenderer.insufficientDataRuleIndex.push(-1);
            multiValueRenderer.nullDataRuleIndex.push(0);
        });
        return multiValueRenderer;
    }

    static _createValueRenderer(fieldList, variableFieldListField, filterSet) {
        const valueRenderer = new ValueRenderer();
        valueRenderer.fieldList = fieldList;
        valueRenderer.visibility = [];
        // add null data rule
        const nullDataFilter = new Filter();
        nullDataFilter.comparisonType = FilterComparisonType.MATCH_NULL;
        nullDataFilter.fieldName = variableFieldListField.qualifiedName;
        valueRenderer.rules = [];

        const nullBrush = new Brush({
            fillColor: 0,
            fillOpacity: 1,
            minZoom: 0,
            maxZoom: 20,
        });

        const nullPolygonSymbol = new Symbol();
        nullPolygonSymbol.type = 'PolygonSymbol';
        nullPolygonSymbol.brushes = [nullBrush];

        const nullDataRule = new FilterRule();
        nullDataRule.fieldQualifiedName = variableFieldListField.qualifiedName;
        nullDataRule.filter = nullDataFilter;
        nullDataRule.displayInLegend = false;
        nullDataRule.symbols = [nullPolygonSymbol];
        nullDataRule.zoomMin = 0;
        nullDataRule.zoomMax = 20;
        nullDataRule.title = 'No data';
        valueRenderer.rules.push(nullDataRule);
        // add filter set rules
        filterSet.filters.forEach(f => {
            f.fieldName = variableFieldListField.qualifiedName;

            const brush = new Brush({
                fillColor: 0,
                fillOpacity: 1,
                minZoom: 0,
                maxZoom: 20,
            });

            const polygonSymbol = new Symbol();
            polygonSymbol.type = 'PolygonSymbol';
            polygonSymbol.brushes = [brush];

            const rule = new FilterRule();
            rule.fieldQualifiedName = variableFieldListField.qualifiedName;
            rule.filter = f;
            rule.symbols = [polygonSymbol];
            rule.zoomMin = 0;
            rule.zoomMax = 20;
            rule.title = DataVisualizationController._figureOutRuleTitle(filterSet, f);

            valueRenderer.rules.push(rule);
        });
        valueRenderer.nullDataRuleIndex = 0;
        return valueRenderer;
    }

    _applyRendererColorPalette(newDataTheme, newMap, userPrefferedSettingsIndex, variableSelection, appliedDataTheme, metaTable, metaVariable) {
        // get color pallete suggestions
        const metadataSuggestedColorPaleteType = metaVariable.getSuggestedColorPaletteType(newDataTheme.visualizationType);
        const metadataSuggestedColorPaleteId = metaVariable.suggestedPaletteName;
        const metadataSuggestedColorPaleteInverse = metaVariable.suggestedPaletteInverse;
        const preferredSettings = this.userStyleDataSource.settings[userPrefferedSettingsIndex];

        const visualizationType = variableSelection.visualizationType || newDataTheme.visualizationType;
        let colorPalette;

        switch (visualizationType) {
            case VisualizationType.SHADED_AREA:
                if (newDataTheme.variableSelection.isMultiVariable) {
                    if (preferredSettings.multiShadedAreaColorPalette) {
                        colorPalette = preferredSettings.multiShadedAreaColorPalette;
                        newDataTheme.colorPaletteFlipped = preferredSettings.multiShadedAreaColorPaletteFlipped;
                    } else {
                        colorPalette = this.colorPaletteDataSource.currentColorPalettes.getColorPaletteByType(ColorPaletteType.MULTI_POLYGON, newMap.colorPalettesURL);
                    }
                } else if (preferredSettings.shadedAreaColorPalette) {
                    colorPalette = preferredSettings.shadedAreaColorPalette;
                    newDataTheme.colorPaletteFlipped = preferredSettings.shadedAreaColorPaletteFlipped;
                } else if (metadataSuggestedColorPaleteId && metadataSuggestedColorPaleteId !== '') {
                    colorPalette = this.colorPaletteDataSource.currentColorPalettes.getColorPaletteByTypeAndId(metadataSuggestedColorPaleteType, metadataSuggestedColorPaleteId, newMap.colorPalettesURL);
                    if (colorPalette) newDataTheme.colorPaletteFlipped = metadataSuggestedColorPaleteInverse;
                }

                if (!colorPalette) {
                    colorPalette = this.colorPaletteDataSource.currentColorPalettes.getColorPaletteByType(metadataSuggestedColorPaleteType, newMap.colorPalettesURL);
                }
                break;
            case VisualizationType.BUBBLES:
                if (newDataTheme.bubbleValueType === VariableValueType.NUMBER) {
                    if (preferredSettings.bubbleSingleColorPalette) {
                        colorPalette = preferredSettings.bubbleSingleColorPalette;
                    } else if (metadataSuggestedColorPaleteId && metadataSuggestedColorPaleteId !== '') {
                        colorPalette = this.colorPaletteDataSource.currentColorPalettes.getColorPaletteByTypeAndId(ColorPaletteType.BUBBLE_SINGLE_COLOR, metadataSuggestedColorPaleteId, newMap.colorPalettesURL);
                    } else {
                        colorPalette = this.colorPaletteDataSource.currentColorPalettes.getColorPaletteByType(ColorPaletteType.BUBBLE_SINGLE_COLOR, newMap.colorPalettesURL);
                    }
                } else if (preferredSettings.bubbleRangeColorPalette) {
                    colorPalette = preferredSettings.bubbleRangeColorPalette;
                    newDataTheme.colorPaletteFlipped = preferredSettings.bubbleRangeColorPaletteFlipped;
                } else if (metadataSuggestedColorPaleteId && metadataSuggestedColorPaleteId !== '') {
                    colorPalette = newMap.colorPalettes.find(cp => (cp.type.toLowerCase() === ColorPaletteType.BUBBLE_SEQUENTIAL.toLowerCase() ||
                                                                    cp.type.toLowerCase() === ColorPaletteType.BUBBLE_DIVERGING.toLowerCase() ||
                                                                    cp.type.toLowerCase() === ColorPaletteType.BUBBLE_USER_DEFINED.toLowerCase()) && cp.id.toLowerCase() === metadataSuggestedColorPaleteId.toLowerCase());
                    if (colorPalette) newDataTheme.colorPaletteFlipped = metadataSuggestedColorPaleteInverse;
                }

                if (!colorPalette) {
                    colorPalette = this.colorPaletteDataSource.currentColorPalettes.getColorPaletteByType(metadataSuggestedColorPaleteType, newMap.colorPalettesURL);
                }
                break;
            case VisualizationType.DOT_DENSITY:
                if (newDataTheme.variableSelection.isMultiVariable) {
                    if (preferredSettings.valueDotDensityColorPalette) {
                        colorPalette = preferredSettings.valueDotDensityColorPalette;
                    } else {
                        colorPalette = this.colorPaletteDataSource.currentColorPalettes.getColorPaletteByType(ColorPaletteType.VALUE_DOT_DENSITY, newMap.colorPalettesURL);
                    }
                } else if (preferredSettings.dotDensityColorPalette) {
                    colorPalette = preferredSettings.dotDensityColorPalette;
                }

                if (!colorPalette) {
                    colorPalette = this.colorPaletteDataSource.currentColorPalettes.getColorPaletteByType(ColorPaletteType.DOT_DENSITY, newMap.colorPalettesURL);
                }
                break;
        }

        if (colorPalette) {
            newDataTheme.colorPaletteId = colorPalette.id;
            newDataTheme.colorPaletteType = colorPalette.type;
            newDataTheme.rendering[0].applyColorPalette(colorPalette, newDataTheme.colorPaletteFlipped);
        }
    }
}

export default DataVisualizationController;
