import React from 'react';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import MapExportFrameAspectRatioControl from './controls/MapExportFrameAspectRatioControl';
import MapExportFrameZoomLevelControl from './controls/MapExportFrameZoomLevelControl';
import MapExportFrameDPIControl from './controls/MapExportFrameDPIControl';
import MapExportFrameOutputControl from './controls/MapExportFrameFormatControl';

import RaisedButton from 'material-ui/RaisedButton';
import IconButton from 'material-ui/IconButton';
import FontIcon from 'material-ui/FontIcon';

import Tooltip from '../../Tooltip';
import googleTagManagerEvents from '../../../helpers/GoogleTagManagerEventsWrapper';
import { exportImageButtonWrapperEvents } from '../../../enums/GoogleTagManagerEvents';

const ExportImageButtonWrapper = googleTagManagerEvents('div', exportImageButtonWrapperEvents);

const MapExportFrameControls = props => {
    const { showRestoreSettings, targetAspectRatio, targetZoomLevel, targetDPI, targetFormat } = props;
    const { onChangeAspectRatio, onChangeZoomLevel, onChangeDPI, onChangeFormat, onRestoreSettings, onExport } = props;

    const restoreSettingsButtonClasses = classNames('map-export-frame__export-strip__controls__restore', {
        hidden: !showRestoreSettings,
    });

    const restoreSettingsSeparatorClasses = classNames('map-export-frame__export-strip__controls__spacer', {
        hidden: !showRestoreSettings,
    });

    return (
        <div className="map-export-frame__export-strip__controls">
            <div className={restoreSettingsButtonClasses}>
                <Tooltip
                    placement="bottom"
                    overlay="Use previous settings"
                >
                    <IconButton onClick={onRestoreSettings}>
                        <FontIcon
                            color="rgba(255,255,255,0.8)"
                            hoverColor="#fff"
                            className="material-icons"
                        >
                            restore
                        </FontIcon>
                    </IconButton>
                </Tooltip>
            </div>
            <div className={restoreSettingsSeparatorClasses}>&nbsp;</div>
            <MapExportFrameAspectRatioControl
                targetAspectRatio={targetAspectRatio}
                onChangeAspectRatio={onChangeAspectRatio}
            />
            <div className="map-export-frame__export-strip__controls__spacer">&nbsp;</div>
            <MapExportFrameZoomLevelControl
                targetZoomLevel={targetZoomLevel}
                onChangeZoomLevel={onChangeZoomLevel}
            />
            <div className="map-export-frame__export-strip__controls__spacer">&nbsp;</div>
            <MapExportFrameDPIControl
                targetDPI={targetDPI}
                onChangeDPI={onChangeDPI}
            />
            <div className="map-export-frame__export-strip__controls__spacer">&nbsp;</div>
            <MapExportFrameOutputControl
                targetFormat={targetFormat}
                onChangeFormat={onChangeFormat}
            />
            <div className="map-export-frame__export-strip__controls__spacer">&nbsp;</div>
            <div className="map-export-frame__export-strip__controls__export">
                <ExportImageButtonWrapper>
                    <RaisedButton
                        backgroundColor="#FFFFFF"
                        labelColor="#0450E6"
                        label={props.intl.formatMessage({ id: 'export' })}
                        labelStyle={{ textTransform: 'capitalize', fontWeight: 600 }}
                        onTouchTap={onExport}
                    />
                </ExportImageButtonWrapper>
            </div>
        </div>
    );
};

MapExportFrameControls.propTypes = {
    mapViewer: PropTypes.object,
    showRestoreSettings: PropTypes.bool,
    targetAspectRatio: PropTypes.string.isRequired,
    targetZoomLevel: PropTypes.number.isRequired,
    targetDPI: PropTypes.number.isRequired,
    targetFormat: PropTypes.string.isRequired,
    onChangeAspectRatio: PropTypes.func.isRequired,
    onChangeZoomLevel: PropTypes.func.isRequired,
    onChangeDPI: PropTypes.func.isRequired,
    onChangeFormat: PropTypes.func.isRequired,
    onRestoreSettings: PropTypes.func.isRequired,
    onExport: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
};

MapExportFrameControls.defaultProps = {
    mapViewer: undefined,
    showRestoreSettings: false,
};

export default injectIntl(MapExportFrameControls);
