import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import NumberPicker from '../NumberPicker';
import GradientPicker from './pickers/GradientPicker';
import ColorPalettePreview from './GradientColorPalettePreview';
import ColorRamp from '../../objects/ColorRamp';
import ColorPicker from '../colorPickerCompact/ColorPicker';

class GradientEditor extends React.Component {
    static flipColors(currentPalette) {
        const ramps = currentPalette.colorRamps;
        const tempRamps = ramps.map(ramp => Object.assign(new ColorRamp(), ramp));
        tempRamps.reverse();
        tempRamps.forEach(ramp => {
            const temp = ramp.to;
            ramp.to = ramp.from;
            ramp.from = temp;
        });
        ramps.forEach((ramp, index) => {
            ramp.to = tempRamps[index].to;
            ramp.from = tempRamps[index].from;
        });
    }

    constructor(props, context) {
        super(props, context);
        this.state = {
            numberOfSamples: 10,
        };
    }

    onNumberOfSamplesChanged = nr => {
        this.setState({
            numberOfSamples: nr,
        });
    }

    onFlipColors = () => {
        const colorPalette = this.props.colorPalette.clone();
        GradientEditor.flipColors(colorPalette);
        this.props.applyColorPalette(colorPalette);
    }


    render() {
        return (
            <div className="gradient-editor">
                <div className="main-content">
                    <GradientPicker
                        applyNewColorPalette={this.props.applyColorPalette}
                        colorPalette={this.props.colorPalette}
                    />
                </div>
                <div className="additional-controls flex-it space-between center">
                    <button className="btn-raised" onClick={this.onFlipColors}>
                        <i className="material-icons btn-raised__icon">swap_horiz</i>
                        <span className="btn-raised__text">
                            {this.props.intl.formatMessage({ id: 'visualisationType.flipColors' })}
                        </span>
                    </button>
                    <div className="flex-it center">
                        <h6 className="margin-right-10">
                            {this.props.intl.formatMessage({ id: 'noData' })}
                        </h6>
                        <ColorPicker
                            onChange={this.props.applyNoDataColor}
                            color={this.props.colorPalette.nullDataColor}
                        />
                    </div>
                    <div className="flex-it center">
                        <h6 className="margin-right-10">
                            {this.props.intl.formatMessage({ id: 'insufficientData' })}
                        </h6>
                        <ColorPicker
                            onChange={this.props.applyInsufficientDataColor}
                            color={this.props.colorPalette.insufficientDataColor}
                        />
                    </div>
                </div>
                <div className="footer">
                    <div className="preview-text">
                        <i className="socex-icon-visible_true" />
                        <FormattedMessage id="preview" />
                    </div>
                    <NumberPicker
                        min="2" max="11" currentNumber={this.state.numberOfSamples}
                        onNumberChange={this.onNumberOfSamplesChanged}
                    />
                    <ColorPalettePreview
                        colorPalette={this.props.colorPalette}
                        numberOfSamples={this.state.numberOfSamples}
                    />
                </div>
            </div>);
    }
}

GradientEditor.propTypes = {
    colorPalette: PropTypes.object.isRequired,
    applyNoDataColor: PropTypes.func.isRequired,
    applyInsufficientDataColor: PropTypes.func.isRequired,
    applyColorPalette: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
};
GradientEditor.defaultProps = {
};

export default injectIntl(GradientEditor);
