import BaseController from './BaseController';
import parseCSV from '../helpers/CSVParser';
import parseGeoSelection from '../helpers/GeoSelectionParser';
import MetadataDataSource from '../dataSources/MetadataDataSource';
import ReportDataSource from '../dataSources/ReportDataSource';
import MapDataSource from '../dataSources/MapDataSource';

class ImportController extends BaseController {
    static get name() {
        return 'ImportController';
    }

    static getInstance(options) {
        return new ImportController(options);
    }

    onActivate() {
        this.bindGluBusEvents({
            IMPORT_GEO_SELECTION_UPLOAD_REQUEST: this.onImportGeoSelectionUploadRequest,
        });
        this.metadataDataSource = this.activateSource(MetadataDataSource);
        this.reportDataSource = this.activateSource(ReportDataSource);
        this.mapDataSource = this.activateSource(MapDataSource);
    }

    onImportGeoSelectionUploadRequest(e) {
        const { file, surveyName, limit } = e;
        const onError = error => {
            this.bus.emit('IMPORT_GEO_SELECTION_UPLOAD_ERROR', {
                error,
                mapInstanceId: e.mapInstanceId,
            });
        };
        const onParseCSVDone = rawData => {
            // check if correct GEO columns are present and limit number of rows
            parseGeoSelection({ rawData, limit }).then(result => {
                // validate data
                this.reportDataSource.validateGeoSelectionForSurvey(
                    result.data,
                    result.info,
                    surveyName,
                    this.metadataDataSource.currentMetadata.surveys[surveyName].geoTypes,
                    this.mapDataSource.currentMaps[surveyName].summaryLevels,
                ).then(validationResult => {
                    // geo selection upload succeeded
                    const surveySummaryLevels = this.metadataDataSource.currentMetadata.surveys[surveyName].geoTypes;
                    const summaryLevels = {};
                    Object.keys(validationResult.selection).forEach(id => {
                        summaryLevels[id] = surveySummaryLevels[id];
                    });
                    this.bus.emit('IMPORT_GEO_SELECTION_UPLOAD_SUCCESS', {
                        selection: validationResult.selection,
                        summaryLevels,
                        excluded: result.excludedData,
                        warnings: validationResult.warnings,
                        mapInstanceId: e.mapInstanceId,
                    });
                }).catch(onError);
            }).catch(onError);
        };

        const extension = file.name.split('.').pop();
        if (extension === 'csv') {
            parseCSV({ file })
                .then(
                    rawData => onParseCSVDone(rawData),
                    errors => onError(errors[0])
                );
        } else {
            onError('Please choose a CSV file');
        }
    }

    onDeactivate() {
        this.unbindGluBusEvents();
    }
}

export default ImportController;
