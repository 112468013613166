class UserDataLayersData {
    constructor(mapInstance, mapConfig, layers, sources) {
        this._mapConfig = mapConfig;
        this._mapInstance = mapInstance;
        this._dragonflyLayers = layers;
        this._dragonflySources = sources;

        this._userDataLayers = this._mapInstance.userDataLayers;
        this._userDataDragonflyDataSources = [];
        this._userDataDragonflyLayers = [];

        this.buildUserDataDragonflyLayers();

        this._userDataDragonflyLayers.forEach(dl => {
            this._dragonflyLayers.push(dl);
        });
        this._userDataDragonflyDataSources.forEach(ds => {
            this._dragonflySources[ds.id] = ds.source;
        });
    }

    get sources() {
        return this._userDataDragonflyDataSources;
    }

    get layers() {
        return this._userDataDragonflyLayers;
    }

    update() {
        // delete layers
        this._dragonflyLayers.slice(0).forEach(dl => {
            if (dl.metadata.isUserDataLayer) {
                this._dragonflyLayers.splice(this._dragonflyLayers.indexOf(dl), 1);
            }
        });
        // delete sources
        this._userDataDragonflyDataSources.forEach(ds => {
            delete this._dragonflySources[ds.id];
        });

        this._userDataLayers = this._mapInstance.userDataLayers;
        this._userDataDragonflyDataSources = [];
        this._userDataDragonflyLayers = [];

        this.buildUserDataDragonflyLayers();

        this._userDataDragonflyLayers.forEach(dl => {
            this._dragonflyLayers.push(dl);
        });
        this._userDataDragonflyDataSources.forEach(ds => {
            this._dragonflySources[ds.id] = ds.source;
        });
    }

    buildUserDataDragonflyLayers() {
        this._userDataLayers.forEach(layer => {
            // if layer geoJSON does not exist, we need to fetch it first
            if (!layer.geoJSON) return;
            // create sources
            const geoJsonSource = {
                id: layer.sourceId,
                source: {
                    type: 'geojson',
                    data: layer.geoJSON,
                },
            };
            this._userDataDragonflyDataSources.push(geoJsonSource);
            // create layers
            const newLayers = layer.convertToDragonflyLayers(this._mapConfig);
            this._userDataDragonflyLayers = [
                ...this._userDataDragonflyLayers.filter(l => l.id.indexOf(layer.id) === -1),
                ...newLayers,
            ];
        });
    }
}

export default UserDataLayersData;
