class GeoJsonLayersData {
    constructor(mapInstance, mapConfig, layers, sources) {
        this._mapConfig = mapConfig;
        this._mapInstance = mapInstance;
        this._dragonflyLayers = layers;
        this._dragonflySources = sources;

        this._geoJsonDragonflyDataSource = {};
        this._geoJsonDragonflyDataSourceId = undefined;
        /** @type {import('mapbox-gl').Layer[]} */
        this._geoJsonDragonflyLayers = [];
    }

    get sourceId() {
        return this._geoJsonDragonflyDataSourceId;
    }

    get pointsLayerId() {
        return this._pointDataUnClusteredLayerId;
    }

    get source() {
        return this._geoJsonDragonflyDataSource;
    }

    get geoJsonSourceData() {
        return this._geoJsonSourceData;
    }

    get layers() {
        return this._geoJsonDragonflyLayers;
    }

    get idProperty() {
        return this._idProperty;
    }

    get clusterLayer() {
        return this._geoJsonDragonflyLayers.find(l => l.id === this._pointDataClusteredLayerId);
    }

    /**
     * @param {import('../../').PointsMetadata} geoJsonMetadata
     */
    setGeoJsonLayersData(geoJsonMetadata) {
        const { mapProperties, pointPreviewProperties } = geoJsonMetadata;
        this._geoJsonDragonflyDataSource = mapProperties.source;
        this._geoJsonDragonflyDataSourceId = mapProperties.sourceId;
        this._geoJsonDragonflyLayers = [...mapProperties.layers];
        this._pointDataClusteredLayerId = mapProperties.clusteredLayerId;
        this._pointDataUnClusteredLayerId = mapProperties.layerId;
        this._markerColors = mapProperties.markerColors;
        // id of the property that is unique for each feature
        this._idProperty = pointPreviewProperties.idProperty;

        // this._dragonflySources[this._geoJsonDragonflyDataSourceId] = this._geoJsonDragonflyDataSource;
        // this._geoJsonDragonflyLayers.forEach(gl =>
        //     this._dragonflyLayers.push(gl)
        // );
    }

    resetClusterLayerColor() {
        this.clusterLayer.paint['circle-color'] = this._markerColors.default;
    }

    updateClusterLayerColor(visibleLayerId) {
        this.clusterLayer.paint['circle-color'] = this._markerColors[visibleLayerId];
    }

    /**
     * @param geoJson {import('../../').GeoJson}
     */
    setGeoJsonSourceData(geoJson) {
        this._geoJsonSourceData = JSON.parse(JSON.stringify(geoJson));
        this._geoJsonDragonflyDataSource.data = this._geoJsonSourceData;
    }

    setFilteredSourceData(filteredSourceData) {
        this._geoJsonDragonflyDataSource.data = filteredSourceData;
    }

    resetData() {
        this._geoJsonDragonflyDataSource.data = this._geoJsonSourceData;
    }

    update() {
        delete this._dragonflySources[this._geoJSONDragonflyDataSource.id];
        this._geoJSONDragonflyDataSource = undefined;
        this._geoJsonDragonflyLayers = [];
    }

}

export default GeoJsonLayersData;
