import React from 'react';
import BusComponent from '../BusComponent';
import GeoSelectionList from '../geoSelection/list/GeoSelectionList';
import GeoSelectionImport from '../geoSelection/import/GeoSelectionImport';

class MaskDataSelection extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {};
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    onRemoveGeo = geo => {
        this.bus.emit('MAP_DESELECT_MASK_FEATURE', {
            mapInstanceId: this.props.mapInstanceId,
            keyValue: geo.id,
        });
    }


    render() {
        const dataGeoFilter = Object.values(this.props.mapInstance.dataGeoFilters)[0];
        if (!dataGeoFilter) {
            return (<GeoSelectionImport
                className="map-mask-editor__section grow"
                mapInstanceId={this.props.mapInstanceId}
                surveyName={this.props.mapInstance.dataTheme.surveyName}
            />);
        } else if (dataGeoFilter && this.props.activeSummaryLevel) {
            const summaryLevelsGeos = {};
            summaryLevelsGeos[this.props.activeSummaryLevel.id] = dataGeoFilter.features.map(f => ({
                id: f.columnValue,
                title: f.geoQName,
            }));
            return (<GeoSelectionList
                className="selected-geos-list--mask grow"
                activeSummaryLevel={this.props.activeSummaryLevel}
                summaryLevels={[this.props.activeSummaryLevel]}
                summaryLevelsGeos={summaryLevelsGeos}
                surveyName={this.props.mapInstance.dataTheme.surveyName}
                onRemoveGeo={this.onRemoveGeo}
            />);
        }
        return null;
    }
}

export default MaskDataSelection;
