import GLU from '../glu2.js/src/index';

class AccountsApi extends GLU.Api {
    constructor(endpoint) {
        super(endpoint);

        this.createApiActions(
            {
                name: 'logOut',
                method: GLU.Api.Delete,
                path: '/session',
                credentials: true,
            },
            {
                name: 'whoAmI',
                method: GLU.Api.Get,
                path: '/whoami',
                credentials: true,
            },
        );
    }
}

export default AccountsApi;
