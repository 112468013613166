import GLU from '../glu2.js/src/index';

class OlapFacilityReportApi extends GLU.Api {
  constructor(endpoint) {
    super(endpoint);

    this.createApiActions(
      {
        name: 'siteAnalysis',
        method: GLU.Api.Get,
        path: '/api/v1/data/site-analysis/single',
        credentials: true,
      },
    );
  }
}

export default OlapFacilityReportApi;
