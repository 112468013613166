import React from 'react';
import PropTypes from 'prop-types';

import StrokeWeight from '../../../enums/StrokeWeight';
import NumberPropertyEditor from './propertyEditors/NumberPropertyEditor';
import ColorPropertyEditor from './propertyEditors/ColorPropertyEditor';

class EditFlowArrow extends React.Component {
    constructor(props) {
        super(props);
        const strokeWeight = StrokeWeight[props.annotation.strokeWeight.toUpperCase()].value;
        this.state = {
            fromWidth: props.annotation.fromWidth,
            toWidth: props.annotation.toWidth,
            tipWidth: props.annotation.tipWidth,
            useFill: props.annotation.useFill,
            opacity: props.annotation.opacity,
            strokeWeight,
            hasBorder: strokeWeight > 0,
        };
    }

    onToWidthChanged = toWidth => {
        this.setState({ toWidth });
        this.props.onChange({
            toWidth,
            tipWidth: this.state.tipWidth,
            fromWidth: this.state.fromWidth,
            annotation: this.props.annotation,
        }, { silent: true });
    }

    onFromWidthChanged = fromWidth => {
        this.setState({ fromWidth });
        this.props.onChange({
            fromWidth,
            tipWidth: this.state.tipWidth,
            toWidth: this.state.toWidth,
            annotation: this.props.annotation,
        }, { silent: true });
    }

    onTipWidthChanged = tipWidth => {
        this.setState({ tipWidth });
        this.props.onChange({
            tipWidth,
            fromWidth: this.state.fromWidth,
            toWidth: this.state.toWidth,
            annotation: this.props.annotation,
        }, { silent: true });
    }

    onOpacityChanged = opacity => {
        this.setState({ opacity: opacity / 100 });
        this.props.onChange({
            opacity: opacity / 100,
            useFill: this.props.annotation.useFill,
            annotation: this.props.annotation,
        }, { silent: true });
    }

    onUseFillChanged = checked => {
        this.setState({ useFill: !this.state.useFill });
        this.props.onChange({
            useFill: checked,
            opacity: this.props.annotation.opacity,
        });
    }

    onFillColorChanged = fillColor => {
        this.props.onChange({
            fillColor,
        });
    }

    onStrokeColorApplied = strokeColor => {
        this.props.onChange({
            strokeColor,
        });
    }

    onStrokeWeightChange = strokeWeight => {
        this.setState({ strokeWeight });
        this.props.onChange({
            strokeWeight: Object.keys(StrokeWeight)[strokeWeight],
        });
    }

    onHasBorderChecked = checked => {
        this.setState({ hasBorder: checked, strokeWeight: checked ? 1 : 0 });
        this.props.onChange({
            strokeWeight: checked ? Object.keys(StrokeWeight)[1] : Object.keys(StrokeWeight)[0],
        });
    }

    render() {
        return (<div className="flex-it column">
            <NumberPropertyEditor
                label="Start"
                value={this.state.fromWidth}
                onChange={this.onFromWidthChanged}
                min={1}
                max={100}
            />
            <NumberPropertyEditor
                label="End"
                value={this.state.toWidth}
                onChange={this.onToWidthChanged}
                min={1}
                max={100}
            />
            <NumberPropertyEditor
                label="Tip"
                value={this.state.tipWidth}
                onChange={this.onTipWidthChanged}
                min={1}
                max={100}
            />
            <NumberPropertyEditor
                label="Opacity"
                value={this.state.opacity * 100}
                onChange={this.onOpacityChanged}
                min={0}
                max={100}
                unit="%"
            />
            <div className="divider annotation-card__separator" />
            <ColorPropertyEditor
                enabled={this.state.useFill}
                onChangeAvailability={this.onUseFillChanged}
                onColorChanged={this.onFillColorChanged}
                color={this.props.annotation.fillColor}
                label="Fill"
            />
            <ColorPropertyEditor
                enabled={this.state.hasBorder}
                onChangeAvailability={this.onHasBorderChecked}
                onColorChanged={this.onStrokeColorApplied}
                color={this.props.annotation.strokeColor}
                label="Border"
                withBorder
            />
            {this.state.hasBorder && <NumberPropertyEditor
                label="Width"
                value={this.state.strokeWeight}
                onChange={this.onStrokeWeightChange}
                min={1}
                max={5}
            />}
            <div className="edit-annotation__spacer" />
        </div>
        );
    }
}

EditFlowArrow.propTypes = {
    annotation: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default EditFlowArrow;
