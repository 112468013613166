import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class PagedBrowser extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            currentPage: props.currentPage || 0,
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.currentPage !== undefined) {
            this.setState({
                currentPage: nextProps.currentPage,
            });
        }
    }

    onPreviousPage = () => {
        const { onPreviousPage } = this.props;
        const { currentPage } = this.state;

        if (onPreviousPage !== undefined) onPreviousPage(currentPage - 1);
        this.setState({
            currentPage: currentPage - 1 < 0 ? 0 : currentPage - 1,
        });
    }

    onNextPage = () => {
        const { onNextPage, children } = this.props;
        const { currentPage } = this.state;

        if (onNextPage !== undefined) onNextPage(currentPage + 1);
        this.setState({
            currentPage: currentPage + 1 > children.length - 1 ? children.length - 1 : currentPage + 1,
        });
    }

    render() {
        const { children, className, pageClassName, compact } = this.props;
        const { currentPage } = this.state;

        const rootClassNames = classNames('paged-browser flex-it grow column center', className);
        const pageClassNames = classNames('paged-browser__page', pageClassName);
        const pickerClassNames = classNames('paged-browser__page-picker', { 'paged-browser__page-picker--absolute': !compact });

        const disablePrev = currentPage === 0;
        const disableNext = currentPage === children.length - 1;

        return (<div className={rootClassNames}>
            <div className={pageClassNames}>
                {children[currentPage]}
            </div>
            {(children && children.length > 1) &&
                <div className={pickerClassNames}>
                    <button className="btn-icon" onClick={this.onPreviousPage} disabled={disablePrev} aria-label="Previous page">
                        <i className="material-icons">keyboard_arrow_left</i>
                    </button>
                    <span className="paged-browser__current-page">
                        {`${currentPage + 1} / ${children.length}`}
                    </span>
                    <button className="btn-icon" onClick={this.onNextPage} disabled={disableNext} aria-label="Next page">
                        <i className="material-icons">keyboard_arrow_right</i>
                    </button>
                </div>
            }
        </div>);
    }
}
PagedBrowser.propTypes = {
    onPreviousPage: PropTypes.func,
    onNextPage: PropTypes.func,
    className: PropTypes.string,
    pageClassName: PropTypes.string,
    children: PropTypes.array.isRequired,
    currentPage: PropTypes.number,
    compact: PropTypes.bool,
};

PagedBrowser.defaultProps = {
    currentPage: undefined,
    className: '',
    pageClassName: '',
    onPreviousPage: undefined,
    onNextPage: undefined,
    compact: false,
};

export default PagedBrowser;
