//!



const util = require('../util/util');
const dragonflyFeaturesStyles = require('../style/dragonfly_features_styles');
const dragonflyInteraction = require('./dragonfly_interaction');
const dragonflyMaskLayers = require('../style/dragonfly_mask_layers');

const Map = module.exports = require('./map');

// Override queryRenderedFeatures in order to include our logic:
// - extend search query for bubble layers
Map.prototype._queryRenderedFeaturesOld = Map.prototype.queryRenderedFeatures;

// Override _setupContainer in order to include our logic:
// - features hover listener
// - feature click listener
Map.prototype._setupContainerOld = Map.prototype._setupContainer;
Map.prototype._setupContainer = function() {
    this._setupContainerOld();
    util.extend(this, dragonflyInteraction(this));
    this._attachFeaturesClickListener();

    // Wait 500ms after the last render pass and then fire 'rendered' event.
    // Rendered event signals that all styles and source have been loaded, all tiles fetched and rendered.
    const self = this;
    self.on('render', () => {
        clearTimeout(self._renderedTimeout);
        self._renderedTimeout = setTimeout(() => {
            self.fire('rendered');
        }, 750);
    });
};

// Override _setupPainter in order to include our logic:
// - Extend to enable features' styles in painter
Map.prototype._setupPainterOld = Map.prototype._setupPainter;
Map.prototype._setupPainter = function() {
    this._setupPainterOld();
    util.extend(this.painter, dragonflyFeaturesStyles(this));
    util.extend(this.painter, dragonflyMaskLayers(this));
};
