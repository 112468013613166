import Api from '../apis/Api';
import GLU from '../glu2.js/src/index';
import TableXMLParser from '../helpers/TableXMLParser';
import { getTemplateTableGuid } from '../helpers/ReportTableTemplate';

class ReportTableTemplateDataSource extends GLU.DataSource {
    static get name() {
        return 'ReportTableTemplateDataSource';
    }

    static getInstance() {
        return new ReportTableTemplateDataSource();
    }

    constructor() {
        super(() => false);
        /** @type {import('../../../types').ReportTemplateTable[]} */
        this._reportTableTemplates = [];
    }

    get reportTableTemplates() {
        return this._reportTableTemplates;
    }

    areTableTemplatesLoaded() {
        return !!this._reportTableTemplates.length;
    }

    loadTemplates() {
        return new Promise((resolve, reject) => {
            Api.seWebsite.getReportTableTemplates()
                .then(response => {
                    this._reportTableTemplates = response.body;
                    resolve(response.body);
                }, reject);
        });
    }

    loadTables(surveyName, compatibleTemplateIds) {
        const query = { surveyName, includeTables: true };
        return new Promise((resolve, reject) => {
            Api.report.getDatasetsOfSurvey({ query })
                .then(response => {
                    const responseXMLDocument = response.xhr.responseXML;
                    if (responseXMLDocument === null || responseXMLDocument === undefined) {
                        reject('Retrieved empty response, expected XML');
                        return;
                    }
                    const error = responseXMLDocument.getElementsByTagName('Error')[0];
                    if (error) {
                        reject(error.value);
                        return;
                    }

                    const tableXMLParser = new TableXMLParser(responseXMLDocument);
                    const tablesWithGuids = tableXMLParser.tables();

                    this._reportTableTemplates = this._reportTableTemplates.map(
                        template => {
                            if (
                                compatibleTemplateIds.find(
                                    compatibleTemplateId =>
                                        compatibleTemplateId === template.id,
                                )
                            ) {
                                return {
                                    ...template,
                                    tables: template.tables.map(table => ({
                                        ...table,
                                        guidsBySurveyName: {
                                            ...table.guidsBySurveyName,
                                            [surveyName]: getTemplateTableGuid(
                                                table,
                                                tablesWithGuids,
                                            ),
                                        },
                                    })),
                                };
                            }
                            return template;
                        },
                    );

                    const reportTableTemplates = this._reportTableTemplates.filter(
                        template =>
                            compatibleTemplateIds.find(
                                compatibleTemplateId =>
                                    compatibleTemplateId === template.id,
                            ),
                    );
                    resolve(reportTableTemplates);
                }, reject);
        });
    }
}

export default ReportTableTemplateDataSource;
