// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { injectIntl } from 'react-intl';

import BusComponent from '../BusComponent';
import AppConfig from '../../appConfig';
import { hasParentNode } from '../../helpers/Util';

/**
 * @typedef Props
 * @property {import('react-intl').intlShape} intl
 *
 * @typedef State
 * @property {boolean} panelOpen
 *
 * @extends {BusComponent<Props, State>}
 */
class MapLogo extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            panelOpen: false,
        };
    }

    togglePanel = () => {
        // In explore mode there should be no popup visible
        // so no need for event listeners
        if (!this.context.isEmbedded) return;

        this.setState(
            prevState => ({ panelOpen: !prevState.panelOpen }),
            () => {
                if (this.state.panelOpen) {
                    document.addEventListener('click', this.handleClick);
                } else {
                    document.removeEventListener('click', this.handleClick);
                }
            },
        );
    };

    handleClick = e => {
        if (e.target !== this.logo && !hasParentNode(e.target, this.logo)) {
            this.togglePanel();
        }
    };

    render() {
        const { panelOpen } = this.state;
        const { viewCode, isEmbedded } = this.context;
        const logoClasses = classnames('map__logo', {
            'map__logo--clickable': isEmbedded,
        });
        return (
            <div
                className="map__logo-wrapper"
                ref={logo => {
                    this.logo = logo;
                }}
            >
                <button
                    onClick={this.togglePanel}
                    tabIndex={isEmbedded ? 0 : -1}
                >
                    <img
                        className={logoClasses}
                        src={`${AppConfig.constants.assetsBaseURL}/icons/export_logo.svg`}
                        alt="Map logo"
                    />
                </button>
                {isEmbedded && panelOpen && (
                    <div className="map__logo-popup">
                        <a
                            href={AppConfig.constants.backends.website}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="map__logo-popup-link"
                        >
                            {this.props.intl.formatMessage({
                                id: 'visitWebsite',
                            })}
                        </a>
                        <span className="map__logo-bullet">&#8226;</span>
                        <a
                            href={`${AppConfig.constants.links.shareRootURL}/${viewCode}/view`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="map__logo-popup-link"
                        >
                            {this.props.intl.formatMessage({
                                id: 'openEmbedInApp',
                            })}
                        </a>
                    </div>
                )}
            </div>
        );
    }
}

MapLogo.propTypes = {
    intl: PropTypes.object.isRequired,
};

export default injectIntl(MapLogo);
