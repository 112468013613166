import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import DataSurveyYear from './DataSurveyYear';
import classnames from 'classnames';

class DataSurveyYears extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            years: props.years || [],
            selectedYear: props.selectedYear,
            expanded: false,
            animateCenteringSelectedYear: false,
        };
    }

    componentDidMount() {
        this._centerSelectedYear();
        this._addEventsOnEdgeYears();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            years: nextProps.years || [],
            selectedYear: nextProps.selectedYear,
        });
    }

    componentDidUpdate() {
        this._centerSelectedYear();
    }

    componentWillUnmount() {
        this._removeEventsOnEdgeYears();
    }

    onFocusSelectedYear = event => {
        const { relatedTarget } = event;
        if (relatedTarget && this.isRelatedTargetSuchToMoveFocus(relatedTarget.id)) {
            this.selectedYearRef.focus();
        }
    };

    onSelectedYearChange = year => {
        if (year === this.state.selectedYear) {
            return;
        }
        if (this.props.onYearChange) {
            this.props.onYearChange(year);
        }
        this.setState({
            selectedYear: year,
            animateCenteringSelectedYear: !this.state.expanded,
        });
    };

    onToggleExpandYears = () => {
        this.setState({
            expanded: !this.state.expanded,
            animateCenteringSelectedYear: false,
        });
    };

    isRelatedTargetSuchToMoveFocus = relatedTargetId => {
        switch (relatedTargetId) {
        case 'back-to-data-category-selection-button':
        case 'Saved':
        case 'show-all-years-button':
            return true;
        default:
            return false;
        }
    };

    _centerSelectedYear() {
        window.requestAnimationFrame(() => {
            if (this.listRef && this.selectedYearRef) {
                let translateValue = 0;
                const listOverflows = this.listRef.scrollWidth > this.listRef.clientWidth;
                const shouldCenterSelectedYear = this.selectedYearRef.offsetLeft > this.listRef.clientWidth / 2;
                const shouldTranslate = !this.state.expanded && listOverflows && shouldCenterSelectedYear;
                if (shouldTranslate) {
                    // translate list so selected year is visible
                    translateValue = (this.selectedYearRef.offsetLeft - this.listRef.clientWidth) + this.selectedYearRef.offsetWidth;
                    // center selected year
                    let availableWidth = this.listRef.clientWidth / 2, nextYear = this.selectedYearRef.nextSibling;
                    while (nextYear && availableWidth > 0) {
                        translateValue += nextYear.offsetWidth;
                        availableWidth -= nextYear.offsetWidth;
                        nextYear = nextYear.nextSibling;
                    }
                }
                this.listRef.style.transform = `translateX(-${translateValue}px)`;
                // Hide 'Show more' control and center years if list does not overflows
                if (!this.state.expanded && !listOverflows && this.expandControlRef) {
                    this.expandControlRef.style.display = 'none';
                } else if (this.expandControlRef) {
                    this.expandControlRef.style.display = '';
                }
            }
        });
    }

    // While tab navigating through the list of data survey years, we want the currently selected year to be the first one to receive focus, unless years are expanded.
    // That way we will prevent focus to go to a visually hidden year, and make it go to the selected and visible year.
    // Focus change will happen in two cases:
    // 1. When user navigates forward and focus hits the first year in the list
    // 2. When user navigates backwards (shift + tab) and focus hits the last year element in the list
    _addEventsOnEdgeYears() {
        if (this.state.expanded) {
            return;
        }

        const { firstElementChild, lastElementChild } = this.listRef;
        this.surveyYearsEdgeElements = [firstElementChild, lastElementChild];
        this.surveyYearsEdgeElements.forEach(edgeYear => edgeYear.addEventListener('focus', this.onFocusSelectedYear));
        this.edgeYearsEventsAdded = true;
    }

    _removeEventsOnEdgeYears() {
        if (this.edgeYearsEventsAdded) {
            this.surveyYearsEdgeElements.forEach(edgeYear => edgeYear.removeEventListener('focus', this.onFocusSelectedYear));
        }
    }

    render() {
        const years = this.state.years.map(year => {
            const isSelected = year === this.state.selectedYear;
            let inputRefCallback;
            if (isSelected) {
                inputRefCallback = selectedYear => {
                    this.selectedYearRef = selectedYear;
                };
            }

            return (
                <DataSurveyYear
                    inputRef={inputRefCallback}
                    key={year}
                    year={year}
                    active={isSelected}
                    onClick={this.onSelectedYearChange}
                />
            );
        });

        return (
            <div className={classnames('surveys-years', this.props.className)}>
                <div className="surveys-years__list-container flex-it center justify-center">
                    <div
                        ref={list => {
                            this.listRef = list;
                        }}
                        className={classnames('surveys-years__list flex-it center', {
                            wrap: this.state.expanded,
                            'flex-start': this.state.expanded,
                            'surveys-years__list--transition': this.state.animateCenteringSelectedYear,
                        })}
                    >
                        {years}
                    </div>
                </div>
                <div className="surveys-years__controls flex-it flex-end center">
                    <button
                        ref={expand => {
                            this.expandControlRef = expand;
                        }}
                        className="btn-link"
                        onClick={this.onToggleExpandYears}
                        id="show-all-years-button"
                    >
                        { this.state.expanded ? this.props.intl.formatMessage({ id: 'showHide.show.fewerYears' }) : this.props.intl.formatMessage({ id: 'showHide.show.allYears' }) }
                    </button>
                </div>
            </div>
        );
    }
}

DataSurveyYears.propTypes = {
    className: PropTypes.string,
    years: PropTypes.array,
    selectedYear: PropTypes.number,
    onYearChange: PropTypes.func,
    intl: PropTypes.object.isRequired,
};

DataSurveyYears.defaultProps = {
    className: '',
    years: [],
    selectedYear: undefined,
    onYearChange: undefined,

};

export default injectIntl(DataSurveyYears);
