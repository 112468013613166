/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Paper from 'material-ui/Paper';
import transitions from 'material-ui/styles/transitions';
import propTypes from 'material-ui/utils/propTypes';

function getStyles(props, context, state) {
    const { targetOrigin } = props;
    const { open } = state;
    const { muiTheme } = context;
    const horizontal = targetOrigin.horizontal.replace('middle', 'center');
    return {
        root: {
            position: 'fixed',
            zIndex: muiTheme.zIndex.popover,
            opacity: open ? 1 : 0,
            transform: open ? 'scaleY(1)' : 'scaleY(0)',
            transformOrigin: `${horizontal} bottom`,
            transition: transitions.easeOut('450ms', ['transform', 'opacity']),
            maxHeight: '100%',
        },
    };
}

class PopoverAnimationUpwards extends Component {
    static propTypes = {
        children: PropTypes.node,
        className: PropTypes.string,
        open: PropTypes.bool.isRequired,
        /**
         * Override the inline-styles of the root element.
         */
        style: PropTypes.object,
        targetOrigin: propTypes.origin.isRequired,
        zDepth: propTypes.zDepth,
    };

    static defaultProps = {
        style: {},
        zDepth: 1,
    };

    static contextTypes = {
        muiTheme: PropTypes.object.isRequired,
    };

    state = {
        open: false,
    };

    componentDidMount() {
        this.setState({ open: true }); // eslint-disable-line react/no-did-mount-set-state
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            open: nextProps.open,
        });
    }

    render() {
        const {
            className,
            style,
            zDepth,
        } = this.props;

        const styles = getStyles(this.props, this.context, this.state);

        return (
            <Paper
                style={Object.assign(styles.root, style)}
                zDepth={zDepth}
                className={className}
            >
                {this.props.children}
            </Paper>
        );
    }
}

export default PopoverAnimationUpwards;
