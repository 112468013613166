import React from 'react';
import PropTypes from 'prop-types';

import MarkerPicker from '../markerPicker/MarkerPicker';

const MarkerEditorMarkers = props => {
    const { markerColor, markerPathId, iconHasBackground, onExitMoreIconsClick, onMarkerChange } = props;
    const backgroundColor = iconHasBackground ? markerColor : 'transparent';
    const color = iconHasBackground ? '#fff' : markerColor;

    return (
        <div className="marker-editor-compact__content">
            <div className="marker-editor-compact__title-row">
                <button className="btn-icon" onClick={onExitMoreIconsClick}>
                    <i className="material-icons">arrow_back</i>
                </button>
                Icon
            </div>
            <div className="marker-editor-compact__icons-row">
                <MarkerPicker
                    backgroundColor={backgroundColor}
                    color={color}
                    marker={markerPathId}
                    onChange={onMarkerChange}
                />
            </div>
        </div>
    );
};

MarkerEditorMarkers.propTypes = {
    markerPathId: PropTypes.string.isRequired,
    markerColor: PropTypes.string.isRequired,
    iconHasBackground: PropTypes.bool,
    onExitMoreIconsClick: PropTypes.func.isRequired,
    onMarkerChange: PropTypes.func.isRequired,
};

MarkerEditorMarkers.defaultProps = {
    iconHasBackground: true,
};

export default MarkerEditorMarkers;

