import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { hexToRgb } from '../../helpers/Util';

const InfoBubbleDataRow = ({ feature, isSelected, dataRow }) => {
    const infoBubbleDataRowClasses = classNames('data-row', {
        'data-row--selected': isSelected && feature.isMultivariable,
        'data-row--multivariable': feature.isMultivariable,
        'data-row--universe': dataRow.universe,
        'data-row--filter-row': dataRow.isFilterRow,
        'data-row--filter-row-invalid': dataRow.isFilterRuleInvalid,
    });

    const labelClasses = classNames('data-row__label', {
        'data-row__label--full-width':
            dataRow.isAdditionalInfo && dataRow.isTextInfo,
    });

    const valueClasses = classNames('data-row__value-wrapper__value', {
        'data-row__value-wrapper__value--main':
            !dataRow.additionalValue &&
            !dataRow.universe &&
            !dataRow.isAdditionalInfo,
        'data-row__value-wrapper__value--categorical': dataRow.isCategorical,
        'data-row__value-wrapper__value--red': dataRow.isFilterRuleInvalid && !dataRow.additionalValue,
    });

    const additionalValueClasses = classNames(
        'data-row__value-wrapper__value',
        {
            'data-row__value-wrapper__value--additional': dataRow.additionalValue,
            'data-row__value-wrapper__value--main': dataRow.additionalValue,
            'data-row__value-wrapper__value--red': dataRow.isFilterRuleInvalid,
        }
    );

    const style = {};
    if (dataRow.labelColor && feature.isMultivariable) {
        style.borderLeft = `3px solid ${dataRow.labelColor}`;
        if (isSelected) {
            const rgb = hexToRgb(dataRow.labelColor);
            const { r, g, b } = rgb;
            style.backgroundColor = `rgba(${r}, ${g}, ${b}, 0.18)`;
        }
    }

    const name = dataRow.universe ? `Percent base: ${dataRow.name}` : dataRow.name;

    return (
        <li className={infoBubbleDataRowClasses} style={style}>
            <div className={labelClasses}>{name}</div>
            <div className="data-row__value-wrapper">
                <div className={valueClasses}>{dataRow.value}</div>
                {dataRow.additionalValue && (
                    <div className={additionalValueClasses}>
                        {dataRow.additionalValue}
                    </div>
                )}
            </div>
        </li>
    );
};

InfoBubbleDataRow.propTypes = {
    feature: PropTypes.object.isRequired,
    isSelected: PropTypes.bool.isRequired,
    dataRow: PropTypes.object.isRequired,
};

export default InfoBubbleDataRow;
