const imageScreenSize = {
    CURRENT: { label: 'Current screen resolution' },
    PRESET_1: { width: 1920, height: 1080, label: 'HD 1080p (1920x1080, 16:9)' },
    PRESET_2: { width: 1280, height: 720, label: 'HD 720p (1280x720, 16:9)' },
    PRESET_3: { width: 2560, height: 1440, label: 'WQHD (2560x1440, 16:9)' },
    PRESET_5: { width: 1920, height: 1200, label: 'WUXGA (1920x1200, 16:10)' },
    PRESET_6: { width: 1400, height: 1050, label: 'SXGA+ (1400x1050, 4:3)' },
    PRESET_7: { width: 800, height: 600, label: 'SVGA (800x600, 4:3)' },
};

export default imageScreenSize;
