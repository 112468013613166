//      

                                                   
                                                               
                                                      
                                             
                                                                        
                                                                     
const {ProgramConfiguration} = require('../data/program_configuration');

// binder for dragonfly feature dependent paint properties
// determines uniforms, attributes and calculations needed to render `dragonfly-interval` and `dragonfly-categorical`
// this code and variables will be dynamically inserted into shaders source code during program creation
// place where dynamic code is inserted is determined with dragonfly #pragma abstraction that we embed into
// shaders code
class DragonflySourceFunctionBinder {
                 
                 
                  
                         
                       
                           
                             
                                                                        
                       
                         
               
                      
                               
                                  
                       
                      
      

    constructor(name        , type        , property     , layer            ) {
        this.name = name;
        this.type = type;
        this.property = property;
        this.components = type === 'color' ? 4 : 1;
        this.multiplier = type === 'color' ? 255 : 1;
        const paintProperty      = layer.getPaintProperty(property);
        this.columnsCount = paintProperty.property.split(',').length;
        this.propertyType = paintProperty.type;
        this.attributes = [{
            name: `a_${this.name}_property`,
            type: 'Float32',
            components: 2
        }];
        this.uniforms = {
            rules: `u_${name}_rules`,
            undefinedValue: `u_${name}_undefined_value`,
            insufficientValue: `u_${name}_insufficient_value`,
            values: `u_${name}_values`,
            count: `u_${name}_count`
        };
        const computedOptions = paintProperty['computed-options'] || {};
        this.overrideOpacity = computedOptions['alpha-threshold'] !== undefined && this.type === 'color';
        this.alphaThreshold = computedOptions['alpha-threshold'];
    }

    defines() {
        return [`#define HAS_DRAGONFLY_PROPERTY_${this.name}`];
    }

    id() {
        return `/a_${this.name}_${this.propertyType}_${this.columnsCount}`;
    }

    setAttributes(attributes) {
        for (let i = 0; i < this.attributes.length; i++) {
            attributes.push(this.attributes[i]);
        }
    }

    fragmentDragonflyPragmaOverride(operation        , precision        , type        , name        ) {
        if (operation === 'define') {
            return `
#ifdef HAS_DRAGONFLY_PROPERTY_${name}
varying ${precision} ${type} ${this.name};
${this.overrideOpacity ? `uniform ${precision} ${type} u_${this.name}_undefined_value;` : ''}
`;
        }

        if (operation === 'initialize') {
            return `
#ifdef HAS_DRAGONFLY_PROPERTY_${name}
${this.overrideOpacity ? `if (${this.name}[3] < ${this.alphaThreshold} && u_${this.name}_undefined_value[3] == 0.0) opacity = ${name}[3];` : ''}
`;
        }

    }

    vertexDragonflyPragmaOverride(operation        , precision        , type        ) {
        const attrType = type === 'vec4' ? 'color' : 'value';
        const propType = this.propertyType.split('-')[1];
        if (operation === 'define') {
            return `
#ifdef HAS_DRAGONFLY_PROPERTY_${this.name}
attribute highp vec2 a_${this.name}_property;
varying ${precision} ${type} ${this.name};
uniform highp float u_${this.name}_rules[12];
uniform lowp int u_${this.name}_count;
uniform ${precision} ${type} u_${this.name}_undefined_value;
uniform ${precision} ${type} u_${this.name}_insufficient_value;
uniform ${precision} ${type} u_${this.name}_values0[12];
${this.columnsCount > 2 ? `uniform ${precision} ${type} u_${this.name}_values1[12];` : ''}
${this.columnsCount > 4 ? `uniform ${precision} ${type} u_${this.name}_values2[12];` : ''}
`;
        } else /* if (operation === 'initialize') */ {
            return `
#ifdef HAS_DRAGONFLY_PROPERTY_${this.name}
if (a_${this.name}_property[1] < 0.0) {
    ${this.name} = determine_dragonfly_no_data_${attrType}(
                a_${this.name}_property,
                u_${this.name}_undefined_value,
                u_${this.name}_insufficient_value
                );
}
if (a_${this.name}_property[1] >= 0.0 && a_${this.name}_property[1] < 2.0) {
    ${this.name} = determine_dragonfly_${propType}_${attrType}(
            a_${this.name}_property,
            u_${this.name}_values0,
            u_${this.name}_rules,
            u_${this.name}_count
            );
}
${this.columnsCount > 2 ? `
if (a_${this.name}_property[1] >= 2.0 && a_${this.name}_property[1] < 4.0) {
    ${this.name} = determine_dragonfly_${propType}_${attrType}(
            a_${this.name}_property,
            u_${this.name}_values1,
            u_${this.name}_rules,
            u_${this.name}_count
            );
}
` : ''}
${this.columnsCount > 4 ? `
if (a_${this.name}_property[1] >= 4.0) {
    ${this.name} = determine_dragonfly_${propType}_${attrType}(
            a_${this.name}_property,
            u_${this.name}_values2,
            u_${this.name}_rules,
            u_${this.name}_count
            );
}
` : ''}
`;
        }
    }

    populatePaintArray(layer            ,
                       paintArray             ,
                       statistics                         ,
                       start        ,
                       length        ,
                       feature         ) {
        const property = layer.getPaintProperty(this.property);
        const result = DragonflySourceFunctionBinder.calculatePropertyComputedVariable(
            property,
            layer._paintTransitions[this.property].declaration,
            feature.properties
        );

        for (let i = start; i < length; i++) {
            const struct      = paintArray.get(i);
            struct[`a_${this.name}_property0`] = result[0];
            struct[`a_${this.name}_property1`] = result[1];
        }

        if (this.type !== 'color') {
            const value = layer.getPaintValue(this.property, {zoom: 0}, feature);
            const stats = statistics[this.property];
            stats.max = Math.max(stats.max, value);
        }
    }

    setUniforms(gl                       , program         , layer            , globalProperties                  ) {
        const property      = layer.getPaintProperty(this.property);
        if (!property.stops) return;
        const columnsCount = property.property.split(',').length;
        let rules = [];
        if (property.type === 'dragonfly-categorical') {
            const paintDeclaration                   = layer._paintTransitions[this.property].declaration;
            const categoricalValues = paintDeclaration.expression.categoricalValues;
            if (categoricalValues !== undefined) {
                rules = Object.keys(categoricalValues).map(k => categoricalValues[k]);
                rules.sort();
            }
        } else {
            rules = property.stops.map(stop => stop[0]);
        }
        const rulesPerColumn = rules.length / columnsCount;
        rules = rules.slice(0, rulesPerColumn);
        const values = layer.transitionDisabled ? layer.paint[this.property] : layer.getPaintValue(this.property, globalProperties);

        // set undefined and insufficient for `dragonfly-interval`
        let hasUndefined, hasInsufficient;
        if (property.type === 'dragonfly-interval' && rules[0] === undefined) {
            hasUndefined = true;
            rules.shift();
            if (this.type === 'color') {
                gl.uniform4fv(gl.getUniformLocation(program.program, this.uniforms.undefinedValue), values.slice(0, 4));
            } else {
                gl.uniform1f(gl.getUniformLocation(program.program, this.uniforms.undefinedValue), values[0]);
            }
        }
        if (property.type === 'dragonfly-interval' && rules[0] === -Infinity) {
            hasInsufficient = true;
            rules.shift();
            if (this.type === 'color') {
                gl.uniform4fv(gl.getUniformLocation(program.program, this.uniforms.insufficientValue), values.slice(4, 8));
            } else {
                gl.uniform1f(gl.getUniformLocation(program.program, this.uniforms.insufficientValue), values[1]);
            }
        }

        //set rules
        gl.uniform1fv(gl.getUniformLocation(program.program, this.uniforms.rules), rules);
        gl.uniform1i(gl.getUniformLocation(program.program, this.uniforms.count), rules.length);

        // set values
        let i = 0, columnIndex = 0;
        while (i < values.length) {
            let ruleIndex = 0;
            const columnValues = [];
            while (ruleIndex < rulesPerColumn) {
                if (hasUndefined && ruleIndex === 0) {
                    i += this.components; ruleIndex++; continue;
                }
                if (hasInsufficient && ruleIndex === 1) {
                    i += this.components; ruleIndex++; continue;
                }
                for (let k = 0; k < this.components; k++) {
                    let value = values[i + k] * this.multiplier;
                    // encode colors of two columns into single array `color1 * 256 + color2`
                    if (this.components === 4) value = Math.floor(value) * 256;
                    if (columnIndex + 1 < columnsCount && this.type === 'color') {
                        value += Math.floor(values[rulesPerColumn * this.components + i + k] * this.multiplier);
                    }
                    columnValues.push(value);
                }
                i += this.components; ruleIndex++;
            }
            if (this.type === 'color') {
                gl.uniform4fv(gl.getUniformLocation(program.program, `${this.uniforms.values}${Math.floor(columnIndex / 2)}`), columnValues);
            } else {
                gl.uniform1fv(gl.getUniformLocation(program.program, `${this.uniforms.values}${columnIndex}`), columnValues);
            }
            if (columnIndex + 1 < columnsCount && this.type === 'color') {
                i += rulesPerColumn * this.components;
                columnIndex += 2;
            } else {
                columnIndex++;
            }
        }
    }

    // Calculate values to be provided to program buffer from paint property
    // This code implements logic for computed variables
    static calculatePropertyComputedVariable(paintProperty     , paintDeclaration                  , featureProperties                   ) {
        let value, additionalInfo = 0;
        if (paintProperty.type === 'dragonfly-categorical') {
            value = featureProperties[paintProperty.property];

            if (paintDeclaration.expression.categoricalValues !== undefined && paintDeclaration.expression.categoricalValues.hasOwnProperty(value)) {
                value = paintDeclaration.expression.categoricalValues[value];
            } else {
                value = -1;
            }
            return [value, additionalInfo];
        }

        const variables = paintProperty.property.split(",");
        const computedOptions = paintProperty['computed-options'] || {};

        const isComputed = computedOptions['is-computed'];
        let computeFunction = isComputed ? (computedOptions['function'] || 'percent') : undefined;

        variables.forEach((variable, index) => {
            let variableValue = featureProperties[variable];
            let valueForVariable = undefined;

            switch (computeFunction) {
                case 'percent':
                    const percentDenominatorValue = featureProperties[computedOptions['percent:denominator']];
                    if (percentDenominatorValue) {
                        valueForVariable = variableValue * 100 / percentDenominatorValue;
                    }
                    break;
                case 'ratio':
                    const ratioDenominatorValue = featureProperties[computedOptions['ratio:denominator']];
                    if (ratioDenominatorValue) {
                        valueForVariable = variableValue / ratioDenominatorValue;
                    }
                    break;
                case 'multiply':
                    const multiplier = computedOptions['multiply:factor'] || 0;
                    valueForVariable = variableValue * multiplier;
                    break;
                case 'change-difference':
                    const differenceFactor = computedOptions['multiply:factor'] == null ? 1 : computedOptions['multiply:factor'];
                    const changeDiffFromValue = featureProperties[computedOptions['change-difference:from']];
                    valueForVariable = variableValue - (changeDiffFromValue * differenceFactor);
                    break;
                case 'change-percent':
                    const percentFactor = computedOptions['multiply:factor'] == null ? 1 : computedOptions['multiply:factor'];
                    if (variableValue) {
                        const changePercentFromValue = featureProperties[computedOptions['change-percent:from']];
                        const adjustedChangePercentFromValue = changePercentFromValue * percentFactor;
                        valueForVariable = (variableValue - adjustedChangePercentFromValue) * 100 / adjustedChangePercentFromValue;
                    }
                    break;
                case 'change-composition':
                    const changeAccumFromValue = featureProperties[computedOptions['change-composition:from']];
                    const changeAccumParentValue = featureProperties[computedOptions['change-composition:parent']];
                    const changeAccumFromParentValue = featureProperties[computedOptions['change-composition:from-parent']];
                    if (changeAccumParentValue && changeAccumFromParentValue) {
                        const variablePercent = (variableValue * 100) / changeAccumParentValue;
                        const fromPercent = (changeAccumFromValue * 100) / changeAccumFromParentValue;
                        valueForVariable = variablePercent - fromPercent;
                    }
                    break;
                default:
                    valueForVariable = variableValue;
            }

            // skip current calculated value if it is not a number, but previous one is
            if (!isNaN(value) && isNaN(valueForVariable)) return;

            // if both current and previous calculated values were not a number set additional info to undefined flag (-2)
            if (isNaN(value) && isNaN(valueForVariable)) {
                additionalInfo = -2;
                return;
            }
            if (isNaN(value) || value < valueForVariable) {
                value = valueForVariable;
                additionalInfo = index;
            }
        });

        // check insufficient treshold
        if (additionalInfo !== -2) {
            const insufficientThreshold = computedOptions['insufficient-threshold'];
            const insufficientVariable = computedOptions['insufficient-variable'];
            if (insufficientThreshold !== undefined && insufficientVariable !== undefined &&
                featureProperties[insufficientVariable] < insufficientThreshold) {
                return [0, -1];
            }
        }

        return [value, additionalInfo];
    }
}

// override standard process of binding paint attributes for program configuration
// instead of generating standard binders use dragonfly specific binders
function bindDragonflyAttribute(programConfiguration                      , attributes     , layer            , property     , name        , type        ) {
    if (!layer.isPaintValueFeatureConstant(property) && layer.isPaintValueZoomConstant(property)) {
        const paintProperty = layer.getPaintProperty(property);
        if (paintProperty && (paintProperty.type === 'dragonfly-interval' || paintProperty.type === 'dragonfly-categorical')) {
            programConfiguration.binders[name] = new DragonflySourceFunctionBinder(name, type, property, layer);
            programConfiguration.cacheKey += programConfiguration.binders[name].id();
            programConfiguration.binders[name].setAttributes(attributes);
        }
    }
}

// expand embedded dragonfly pragmas in shaders to #ifdef HAS_DRAGONFLY_PROPERTY_name branch
// that overrides existing #ifdef branches in shaders and contains code for definition and initialization
// needed for dragonfly specific attributes
function applyDragonflyPragmas(programConfiguration                      , source     ) {
    const dragonflyPragma = /#pragma dragonfly: ([\w]+) ([\w]+) ([\w]+) ([\w]+)/g;
    const binders = programConfiguration.binders;
    source.vertexSource = source.vertexSource.replace(dragonflyPragma, (match        , operation        , precision        , type        , name        ) => {
        if (binders[name] && binders[name] instanceof DragonflySourceFunctionBinder && binders[name].vertexDragonflyPragmaOverride) {
            return binders[name].vertexDragonflyPragmaOverride(operation, precision, type);
        }
        return `#ifdef HAS_DRAGONFLY_PROPERTY_${name}`;
    });
    source.fragmentSource = source.fragmentSource.replace(dragonflyPragma, (match        , operation        , precision        , type        , name        ) => {
        if (binders[name] && binders[name] instanceof DragonflySourceFunctionBinder && binders[name].fragmentDragonflyPragmaOverride) {
            return binders[name].fragmentDragonflyPragmaOverride(operation, precision, type, name);
        }
        return `#ifdef HAS_DRAGONFLY_PROPERTY_${name}`;
    });
    return source;
}

module.exports = {
    bindDragonflyAttribute,
    applyDragonflyPragmas
};
