import GLU from '../glu2.js/src/index';

class HereReverseGeocodeApi extends GLU.Api {
  constructor(endpoint) {
    super(endpoint);

    this.createApiActions({
      name: 'reverseGeocode',
      method: GLU.Api.Get,
      path: '/revgeocode',
      credentials: false,
    });
  }
}

export default HereReverseGeocodeApi;
