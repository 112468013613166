import React from 'react';
import PropTypes from 'prop-types';

import BusComponent from '../BusComponent';
import { hasParentNode, hasParentNodeWithClass } from '../../helpers/Util';
import { addResizeListener, removeResizeListener } from '../../helpers/DomResizeListener';

const MAX_HEIGHT = 600;

class Menu extends BusComponent {
    static TRIGGER_CLASS = 'menu-trigger';

    componentDidMount() {
        this.bindGluBusEvents({
            FRAME_COMPONENT_DISABLE_EVENTS: this.disableEvents,
            FRAME_COMPONENT_ENABLE_EVENTS: this.enableEvents,
        });

        document.addEventListener('mousedown', this.handleDocumentMouseDown);
        window.addEventListener('resize', this.scheduleMeasure);
        addResizeListener(this.root, this.scheduleMeasure);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleDocumentMouseDown);
        window.removeEventListener('resize', this.scheduleMeasure);
        removeResizeListener(this.root, this.scheduleMeasure);

        this.unbindGluBusEvents();
    }

    disableEvents = () => {
        document.removeEventListener('mousedown', this.handleDocumentMouseDown);
    }

    enableEvents = () => {
        document.addEventListener('mousedown', this.handleDocumentMouseDown);
    }

    handleDocumentMouseDown = e => {
        if (hasParentNodeWithClass(e.target, Menu.TRIGGER_CLASS) || hasParentNode(e.target, this.root)) return;

        this.props.onClose();
    };

    scheduleMeasure = () => {
        window.requestAnimationFrame(this.measure);
    }

    measure = () => {
        if (!this.root) return;

        const { top } = this.root.getBoundingClientRect();
        const spareHeight = Math.min(window.innerHeight - top - 16, MAX_HEIGHT);
        this.root.style.maxHeight = `${spareHeight}px`;
    };

    renderHeader() {
        const { title } = this.props;
        if (!title) return null;

        return (
            <div className="map-panel__menu-header">
                {title}
            </div>
        );
    }

    render() {
        const { children } = this.props;
        return (
            <div ref={c => { this.root = c; }} className="map-panel__menu" style={{ maxHeight: MAX_HEIGHT }}>
                {this.renderHeader()}
                <div className="map-panel__menu-content">
                    {children}
                </div>
            </div>
        );
    }
}

Menu.propTypes = {
    children: PropTypes.any.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string,
};

Menu.defaultProps = {
    title: undefined,
};

export default Menu;
