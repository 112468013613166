/* eslint class-methods-use-this: 0 */

class ValueDotDensityRenderer {
    constructor() {
        this._dotValueFieldNames = [];
        this._dotValueFieldTableIndexes = [];
    }

    get type() {
        return 'ValueDotDensityRenderer';
    }

    get fieldList() {
        return this._fieldList;
    }

    set fieldList(fieldList) {
        this._fieldList = fieldList;
    }

    get rules() {
        return this._rules || [];
    }

    set rules(rules) {
        this._rules = rules;
    }

    get visibility() {
        return this._visibility || [];
    }

    set visibility(visibility) {
        this._visibility = visibility;
    }

    get dotValue() {
        return this._dotValue;
    }

    set dotValue(dotValue) {
        this._dotValue = dotValue;
    }

    get dotDensityValueHint() {
        return this._dotDensityValueHint;
    }

    set dotDensityValueHint(dotDensityValueHint) {
        this._dotDensityValueHint = dotDensityValueHint;
    }

    get dotValueFieldNames() {
        return this._dotValueFieldNames || [];
    }

    set dotValueFieldNames(dotValueFieldNames) {
        this._dotValueFieldNames = dotValueFieldNames;
    }

    get dotValueFieldTableIndexes() {
        return this._dotValueFieldTableIndexes;
    }

    set dotValueFieldTableIndexes(dotValueFieldTableIndexes) {
        this._dotValueFieldTableIndexes = dotValueFieldTableIndexes;
    }

    get symbols() {
        return this._symbols;
    }

    set symbols(symbols) {
        this._symbols = symbols;
    }

    applyColorPalette(colorPalette) {
        const referenceSymbol = this.symbols[0];
        this.symbols = [];
        for (let i = 0; i < this.dotValueFieldNames.length; i += 1) {
            const newSymbol = referenceSymbol.clone();
            newSymbol.brushes[0].textColor = colorPalette.colors[i];
            this.symbols.push(newSymbol);
        }
    }

    equals(that) {
        return (this.type === that.type &&
        this.fieldList.equals(that.fieldList) &&
        this.rules.every((rule, index) => rule.equals(that.rules[index])) &&
        this.visibility.every((vis, index) => vis.equals(that.visibility[index])) &&
        this.dotValue === that.dotValue &&
        this.dotValueFieldTableIndexes === that.dotValueFieldTableIndexes &&
        this.dotValueFieldNames === that.dotValueFieldNames &&
        this.symbols.every((sym, index) => sym.equals(that.symbols[index])));
    }

    clone() {
        const valueDotDensityRenderer = new ValueDotDensityRenderer();
        valueDotDensityRenderer.fieldList = this.fieldList.clone();
        valueDotDensityRenderer.rules = this.rules.map(rule => rule.clone());
        valueDotDensityRenderer.visibility = this.visibility.map(vis => vis.clone());
        valueDotDensityRenderer.dotValue = this.dotValue;
        valueDotDensityRenderer.dotValueFieldNames = [...this.dotValueFieldNames];
        valueDotDensityRenderer.dotValueFieldTableIndexes = this.dotValueFieldTableIndexes;
        valueDotDensityRenderer.symbols = this.symbols.map(sym => sym.clone());
        valueDotDensityRenderer.dotDensityValueHint = this.dotDensityValueHint;
        return valueDotDensityRenderer;
    }

    toJSON() {
        return {
            type: this.type,
            fieldList: this.fieldList.toJSON(),
            rules: this.rules.map(rule => rule.toJSON()),
            visibility: this.visibility.map(vis => vis.toJSON()),
            dotValue: this.dotValue,
            dotValueFieldTableIndexes: this.dotValueFieldTableIndexes,
            dotValueFieldNames: this.dotValueFieldNames,
            dotDensityValueHint: this.dotDensityValueHint,
            symbols: this.symbols.map(symbol => symbol.toJSON()),
        };
    }
}

export default ValueDotDensityRenderer;
