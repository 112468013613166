//!



const browser = require('../util/browser');

module.exports = drawDotdensity;

function drawDotdensity(painter, sourceCache, layer, coords) {
    if (painter.renderPass !== 'translucent') return;

    const gl = painter.gl;

    painter.setDepthSublayer(0);
    painter.depthMask(true);

    const depthTestEnabled = gl.getParameter(gl.DEPTH_TEST);
    if (layer.paint['dotdensity-opacity'] === 0) gl.disable(gl.DEPTH_TEST);
    else gl.enable(gl.DEPTH_TEST);
    // Allow dots to be drawn across boundaries, so that
    // large dots are not clipped to tiles
    // const mapZoom = Math.floor(painter.style.map.getZoom());
    gl.disable(gl.STENCIL_TEST);
    for (let i = 0; i < coords.length; i++) {
        const coord = coords[i];
        // if (mapZoom !== coord.z) continue; //! If uncommented, then will hide dots from parent zoom
        const tile = sourceCache.getTile(coord);
        const bucket = tile.getBucket(layer);
        if (!bucket) continue;
        const programConfiguration = bucket.programConfigurations.get(layer.id);
        const program = painter.useProgram('dotdensity', programConfiguration);
        programConfiguration.setUniforms(gl, program, layer, {zoom: painter.transform.zoom});

        gl.uniform2fv(program.uniforms.u_extrude_scale, painter.transform.pixelsToGLUnits);
        gl.uniform1f(program.uniforms.u_devicepixelratio, browser.devicePixelRatio);

        gl.uniformMatrix4fv(program.uniforms.u_matrix, false, painter.translatePosMatrix(
            coord.posMatrix,
            tile,
            0,
            0
        ));

        program.draw(
            gl,
            gl.TRIANGLES,
            layer.id,
            bucket.layoutVertexBuffer,
            bucket.indexBuffer,
            bucket.segments,
            programConfiguration);
    }
    gl.clear(gl.DEPTH_BUFFER_BIT);
    if (depthTestEnabled) gl.enable(gl.DEPTH_TEST);
    else gl.disable(gl.DEPTH_TEST);


}
