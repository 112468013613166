import React from 'react';
import PropTypes from 'prop-types';
import MobileSubMenu from './MobileSubMenu';

class MobileMenuItem extends React.Component {
    state = {
        isSubMenuVisible: false,
    }

    handleClick = () => {
        const { submenu, onClick } = this.props;

        if (submenu) {
            this.setState({ isSubMenuVisible: true });
        } else {
            onClick();
        }
    };

    handleSubmenuBack = () => {
        this.setState({ isSubMenuVisible: false });
    };

    render() {
        const {
            label,
            description,
            icon,
            submenu,
            onClose,
            disabled,
        } = this.props;

        if (this.state.isSubMenuVisible) {
            return (
                <MobileSubMenu title={label} onClose={onClose} onBack={this.handleSubmenuBack}>
                    {submenu}
                </MobileSubMenu>
            );
        }

        return (
            <button className="map-mobile-menu__item" onClick={this.handleClick} disabled={disabled}>
                <div className="flex-it center min-width-0">
                    {icon}
                    <div className="map-mobile-menu__item__text">
                        <h4>{label}</h4>
                        {description && <span className="map-mobile-menu__item__sub">{description}</span>}
                    </div>
                </div>
                {submenu && <i className="material-icons md-36">keyboard_arrow_right</i>}
            </button>
        );
    }
}

MobileMenuItem.propTypes = {
    label: PropTypes.string.isRequired,
    icon: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    onClick: PropTypes.func,
    submenu: PropTypes.object,
    description: PropTypes.string,
    hasNested: PropTypes.bool,
    disabled: PropTypes.bool,
};

MobileMenuItem.defaultProps = {
    onClose: undefined,
    onClick: undefined,
    description: undefined,
    submenu: undefined,
    hasNested: false,
    disabled: false,
};

export default MobileMenuItem;
