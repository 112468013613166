import BaseController from './BaseController';

import VisualReportDataSource from '../dataSources/VisualReportDataSource';

class VisualReportsController extends BaseController {
    static get name() {
        return 'VisualReportsController';
    }

    static getInstance(options) {
        return new VisualReportsController(options);
    }

    onActivate() {
        this.bindGluBusEvents({
            VISUAL_REPORT_SAVE_REQUEST: this.onVisualReportSaveRequest,
        });

        this.visualReportDataSource = this.activateSource(VisualReportDataSource);
    }

    /**
     * @param {object} param0
     * @param {import('../types').VisualReport} param0.visualReport
     */
    onVisualReportSaveRequest = ({ visualReport }) => {
        this.visualReportDataSource.saveVisualReport(visualReport).then(
            vReport => {
                this.bus.emit('VISUAL_REPORT_SAVE_SUCCESS', {
                    visualReport: vReport,
                });
            },
            error => console.error(error),
        );
    };

    onDeactivate() {
        this.unbindGluBusEvents();
    }
}

export default VisualReportsController;
