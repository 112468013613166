import BaseController from './BaseController';
import SessionLoggingDataSource from '../dataSources/SessionLoggingDataSource';
import AppConfig from '../appConfig';

class SessionLoggerController extends BaseController {
    static get name() {
        return 'SessionLoggerController';
    }

    static getInstance(options) {
        return new SessionLoggerController(options);
    }

    onActivate() {
        this.bindGluBusEvents({
            COUNTER_LOG_REQUEST: this.onCounterLogRequest,
        });

        this.dataSource = this.activateSource(SessionLoggingDataSource);
    }

    onCounterLogRequest(payload) {
        if (
            AppConfig.constants.counterEnabled &&
            Array.isArray(payload) &&
            payload.length
        ) {
            this.dataSource.logCounterEvent(payload);
        }
    }

    onDeactivate() {
        this.unbindGluBusEvents();
    }
}

export default SessionLoggerController;
