export const isSurveyCompatibleWithTableTemplate = (
    selectedSurveyName,
    template,
    surveyGroups,
) => {
    if (selectedSurveyName === template.surveyCode) {
        return true;
    }

    const selectedSurveyGroup = surveyGroups.find(surveyGroup => surveyGroup.dataSets.find(dataset =>
        dataset.code === selectedSurveyName
    ));
    const templateSurveyGroup = surveyGroups.find(({ id }) => template.surveyGroupId === id);
    if (!selectedSurveyGroup || !templateSurveyGroup) {
        // This case happens only when there is metadata for a survey, but its data is missing.
        // It should not happen on prod but I added a check just in case.
        return false;
    }

    if (selectedSurveyGroup.id !== templateSurveyGroup.id) {
        return false;
    }

    const matchingSurvey = templateSurveyGroup.dataSets.find(dataSet => dataSet.code === template.surveyCode);
    if (!matchingSurvey) {
        return false;
    }

    return matchingSurvey.comparabilityGroup === template.comparabilityGroup;
};

/**
 * @param {import('../types').ReportTemplateTable} templateTable
 * @param {import('../types').NormalizedTable[]} allTablesWithGuids
 */
export const getTemplateTableGuid = (templateTable, allTablesWithGuids) => {
    const matchingTables = allTablesWithGuids.filter(tableWithGuid =>
        tableWithGuid.variableCount === templateTable.variableCount &&
        tableWithGuid.name === templateTable.name
    );

    if (matchingTables.length === 1) {
        return matchingTables[0].guid;
    } else if (matchingTables.length > 1) {
        // If an edge case occurs, where there is more than one table with same
        // table name and number of variables, we will pick the right one based on datasetAbbreviation.
        return matchingTables.find(matchingTable =>
            !!matchingTable && matchingTable.datasetAbbreviation === templateTable.datasetAbbreviation
        ).guid;
    }
    return null;
};

export const preparePremadeReportsAndTemplatesWithDividers = (compatibleTableTemplates, reportsListItems) => {
    if (!compatibleTableTemplates.length) {
        return reportsListItems;
    }
    return [
        { text: 'Your Templates', isDivider: true },
        ...compatibleTableTemplates.map(template => ({ id: template.id, text: template.title })),
        { text: 'General', isDivider: true },
        ...reportsListItems,
    ];
};
