const numberFormat = {
    FORMAT_NONE: '',
    FORMAT_PERCENT: 'Percent',
    FORMAT_PERCENT_NO_DECIMAL: 'PercentNoDecimal',
    FORMAT_PERCENT_1_DECIMAL: 'Percent1Decimal',
    FORMAT_PERCENT_2_DECIMAL: 'Percent2Decimal',
    FORMAT_PERCENT_3_DECIMAL: 'Percent3Decimal',
    FORMAT_PERCENT_4_DECIMAL: 'Percent4Decimal',
    FORMAT_REAL_PERCENT: 'RealPercent',
    FORMAT_REAL_PERCENT_NO_DECIMAL: 'RealPercentNoDecimal',
    FORMAT_REAL_PERCENT_1_DECIMAL: 'RealPercent1Decimal',
    FORMAT_REAL_PERCENT_2_DECIMAL: 'RealPercent2Decimal',
    FORMAT_REAL_PERCENT_3_DECIMAL: 'RealPercent3Decimal',
    FORMAT_REAL_PERCENT_4_DECIMAL: 'RealPercent4Decimal',

    FORMAT_CURRENCY: 'Currency',
    FORMAT_CURRENCY0_DECIMAL: 'Currency0Decimal',
    FORMAT_CURRENCY1_DECIMAL: 'Currency1Decimal',
    FORMAT_CURRENCY2_DECIMAL: 'Currency2Decimal',

    FORMAT_NUMBER: 'Number',
    FORMAT_NUMBER_NO_DECIMAL: 'NumberNoDecimal',
    FORMAT_NUMBER_1_DECIMAL: 'Number1Decimal',
    FORMAT_NUMBER_2_DECIMAL: 'Number2Decimal',
    FORMAT_NUMBER_3_DECIMAL: 'Number3Decimal',
    FORMAT_NUMBER_4_DECIMAL: 'Number4Decimal',
    FORMAT_NUMBER_5_DECIMAL: 'Number5Decimal',

    FORMAT_NUMBER_NO_FORMAT: 'NumberNoFormat',
    FORMAT_NUMBER_NO_FORMAT_NO_DECIMAL: 'NumberNoFormatNoDecimal',
    FORMAT_NUMBER_NO_FORMAT_1_DECIMAL: 'NumberNoFormat1Decimal',
    FORMAT_NUMBER_NO_FORMAT_2_DECIMAL: 'NumberNoFormat2Decimal',
    FORMAT_NUMBER_NO_FORMAT_3_DECIMAL: 'NumberNoFormat3Decimal',
    FORMAT_NUMBER_NO_FORMAT_4_DECIMAL: 'NumberNoFormat4Decimal',
    FORMAT_NUMBER_NO_FORMAT_5_DECIMAL: 'NumberNoFormat5Decimal',
};

export default numberFormat;
