import GLU from '../glu2.js/src/index';

class UserMapViewsApi extends GLU.Api {
  constructor(endpoint) {
    super(endpoint);

    this.createApiActions(
      {
        name: 'getUserMapViews',
        method: GLU.Api.Get,
        path: '',
        credentials: true,
      },
      {
        name: 'insertUserMapView',
        method: GLU.Api.Post,
        path: '',
        credentials: true,
      },
      {
        name: 'updateUserMapView',
        method: GLU.Api.Post,
        path: '/update',
        credentials: true,
      },
      {
        name: 'deleteUserMapView',
        method: GLU.Api.Delete,
        path: '',
        credentials: true,
      },
    );
  }
}

export default UserMapViewsApi;
