import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import BusComponent from '../../BusComponent';
import PropTypes from 'prop-types';

import IconMenu from '../../iconMenu/IconMenu';

class LayerLibraryGroupCardHeaderMenu extends BusComponent {
    onDetailsOptionClicked = () => {
        this.emit('MAP_LIBRARY_GROUP_DETAILS_REQUEST', { id: this.props.id });
        this.emit('CLOSE_DROPDOWN');
    }

    onRemoveOptionClicked = () => {
        this.emit('MAP_LIBRARY_GROUP_REMOVAL_REQUEST', { id: this.props.id });
        this.emit('CLOSE_DROPDOWN');
    }

    render() {
        return (<IconMenu
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            targetOrigin={{ vertical: 'top', horizontal: 'right' }}
            icon="more_horiz"
            tooltip={this.props.intl.formatMessage({ id: 'more' })}
        >
            <div className="map-library__options material-menu">
                <div onClick={this.onDetailsOptionClicked} className="material-menu__item material-menu__item">
                    <FormattedMessage id="details" />
                </div>
                <div onClick={this.onRemoveOptionClicked} className="material-menu__item material-menu__item--border">
                    <FormattedMessage id="dataBrowser.removeFromList" />
                </div>
            </div>
        </IconMenu>);
    }
}

LayerLibraryGroupCardHeaderMenu.propTypes = {
    id: PropTypes.string.isRequired,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(LayerLibraryGroupCardHeaderMenu);
