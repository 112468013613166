import React from 'react';
import { injectIntl } from 'react-intl';
import BusComponent from '../BusComponent';
import classNames from 'classnames';
import format from '../../helpers/NumberFormatter';
import InfoBubbleGroup from './InfoBubbleGroup';
import VisualizationType from '../../enums/VisualizationType';
import InfoBubbleMode from '../../enums/InfoBubbleMode';
import FilterStatus from '../../enums/FilterStatus';
import {
    hasParentNode,
    parseFieldName,
    removeEvent,
    addEvent,
    getMetadataObjectsFromVariableSelection,
} from '../../helpers/Util';

const INFO_BUBBLE_OFFSET = 40;
const INFO_BUBBLE_MIN_WIDTH = 320;

class InfoBubble extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            infoBubbleVisible: false,
            currentBaseMaps: undefined,
            lastHoveredMap: undefined,
            infoBubble: {},
            currentMetadata: undefined,
        };
        this.animationFrame = undefined;
        this.listenersAdded = false;
    }

    componentDidMount() {
        this.bindGluBusEvents({
            MAP_FEATURES_ROLL_OVER: this.onMapFeaturesRollOver,
            MAP_FEATURES_ROLL_OUT: this.onMapFeaturesRollOut,
            MAP_MOUSE_MOVE_CLICK_MODE: this.addListeners,
            INFO_BUBBLE_MODE_UPDATE_SUCCESS: this.onSwitchMode,
            IFRAME_MOUSE_ENTER: this.onIframeMouseEnter,
        });
        // Document does not register click events on iframe components that are embedded.
        // In order to update the info bubble visibility on iframe click, we have to listen
        // to the window blur event, because of security reasons we can not capture
        // the iframe click.
        addEvent(window, 'blur', this.onWindowBlurred);
    }

    componentWillMount() {
        this.bus.once('COLOR_PALETTES', this.onCurrentColorPalettesRetrieved);
        this.bus.once('CURRENT_METADATA', this.onCurrentMetadataRetrieved);
        this.bus.once('CURRENT_INFO_BUBBLE_MODE', this.onCurrentInfoBubbleModeRetrieved);
        this.emit('CURRENT_INFO_BUBBLE_MODE_REQUEST');
        this.emit('COLOR_PALETTES_REQUEST');
        this.emit('CURRENT_METADATA_REQUEST', { source: this });
    }

    componentWillUnmount() {
        if (this.animationFrame) {
            window.cancelAnimationFrame(this.animationFrame);
        }
        this.bus.off(
            'CURRENT_COLOR_PALETTES',
            this.onCurrentColorPalettesRetrieved
        );
        this.bus.off('CURRENT_METADATA', this.onCurrentMetadataRetrieved);
        removeEvent(document, 'click', this.updateInfoBubble);
        removeEvent(window, 'blur', this.onWindowBlurred);
        removeEvent(document, 'mousemove', this.updateInfoBubble);
        removeEvent(document, 'mouseout', this.onMouseOut);
        this.unbindGluBusEvents();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.mapInstances.length !== nextProps.mapInstances.length) {
            this.setState({
                infoBubble: {},
            });
        }
    }

    onIframeMouseEnter = () => {
        // Hide the info bubble when hovering iframe, but only in info bubble hover mode
        if (
            this.state.infoBubbleVisible &&
            this.state.infoBubbleMode === InfoBubbleMode.HOVER
        ) {
            this.setState({ infoBubbleVisible: false });
        }
    }

    onWindowBlurred = () => {
        if (this.state.infoBubbleVisible) {
            this.setState({ infoBubbleVisible: false });
        }
    }

    onCurrentInfoBubbleModeRetrieved = ({ infoBubbleMode }) => {
        this.setState({ infoBubbleMode });
    }

    onCurrentMetadataRetrieved = (metadata, target) => {
        if (target === this) {
            this.setState({
                currentMetadata: metadata,
            });
        }
    };

    onCurrentColorPalettesRetrieved = e => {
        this.setState({
            currentColorPalettes: e.colorPalettes,
        });
    };

    // Switch between click and hover mode
    onSwitchMode = ({ infoBubbleMode }) => {
        // When switching to click mode mouse move and mouse out events most be removed
        if (infoBubbleMode === InfoBubbleMode.CLICK) {
            removeEvent(document, 'mousemove', this.updateInfoBubble);
            removeEvent(document, 'mouseout', this.onMouseOut);
        }
        this.setState({ infoBubbleMode });
    };

    // Add listeners for click and remove listeners for mouse move and mouse out
    addListeners = () => {
        if (!this.listenersAdded) {
            addEvent(document, 'click', this.updateInfoBubble);
            removeEvent(document, 'mousemove', this.updateInfoBubble);
            removeEvent(document, 'mouseout', this.onMouseOut);
            this.listenersAdded = true;
        }
    }

    onMapFeaturesRollOver = featuresRollOverEvent => {
        if (this.state.infoBubbleMode === InfoBubbleMode.HOVER) {
            clearTimeout(this.timeout);
            addEvent(document, 'mousemove', this.updateInfoBubble);
            addEvent(document, 'mouseout', this.onMouseOut);
        }

        const { features, isMimicking, source } = featuresRollOverEvent;
        const { lastHoveredMap } = this.state;

        this.setState({
            infoBubbleVisible: features && features.length > 0,
            lastHoveredMap:
                lastHoveredMap === undefined || isMimicking === undefined
                    ? source.id
                    : lastHoveredMap,
        });

        if (features && features.length > 0) {
            this.setState({
                infoBubble: this.setBubbleContent(featuresRollOverEvent),
            });
        }
        this.rolledOut = false;
    };

    onMapFeaturesRollOut = featuresRollOutEvent => {
        const { isPhoneDevice } = this.context;
        const { lastHoveredMap, infoBubbleVisible, infoBubbleMode } = this.state;

        // Don't close tooltip in embed mode on phones
        // it has a close button to do that
        if (isPhoneDevice) {
            return;
        }

        if (infoBubbleMode === InfoBubbleMode.HOVER) {
            this.timeout = setTimeout(() => {
                removeEvent(document, 'mousemove', this.updateInfoBubble);
                removeEvent(document, 'mouseout', this.onMouseOut);
            }, 200);
        } else {
            removeEvent(document, 'click', this.updateInfoBubble);
            this.listenersAdded = false;
        }

        this.rolledOut = true;
        this.setState({
            infoBubbleVisible:
                featuresRollOutEvent.source.id === lastHoveredMap
                    ? false
                    : infoBubbleVisible,
        });
    };

    onMouseOut = e => {
        const event = e || window.event;
        const from = event.relatedTarget || event.toElement;
        if (!from || from.nodeName === 'HTML') {
            this.setState({ infoBubbleVisible: false });
        }
    };

    updateInfoBubble = event => {
        const { infoBubble, infoBubbleMode } = this.state;

        // when in click mode clicking on the info bubble should not move the info bubble
        if (infoBubbleMode === InfoBubbleMode.CLICK && hasParentNode(event.target, this.infoBubble)) return;

        const isHoveringOverMap = infoBubble && Object.keys(infoBubble).some(mapId =>
            hasParentNode(event.target, document.getElementById(`map-${mapId}`))
        );

        // if the user clicks on an element other than the map remove the info bubble and deselect the geography
        if (!isHoveringOverMap && !hasParentNode(event.target, this.infoBubble) && infoBubbleMode === InfoBubbleMode.CLICK) {
            this.emit('MAP_FEATURES_ROLL_OUT', {
                source: this,
                originalEvent: event.originalEvent,
                sender: this,
            });
            return;
        }

        if (!isHoveringOverMap && !hasParentNode(event.target, this.infoBubble)) {
            this.setState({ infoBubbleVisible: false });
        }

        const self = this;
        const animationHandler = () => {
            if (!self.infoBubble) {
                this.animationFrame = undefined;
                return;
            }
            const maxY =
                window.innerHeight ||
                document.documentElement.clientHeight ||
                document.body.clientHeight;
            const maxX =
                window.innerWidth ||
                document.documentElement.clientWidth ||
                document.body.clientWidth;
            // const offset = 40;
            const mouseX = event.pageX;
            const mouseY = event.pageY;
            const infoBubbleHeight = self.infoBubble.clientHeight;
            // in some cases the width can be zero which results in wrong positioning
            const infoBubbleWidth = self.infoBubble.clientWidth || INFO_BUBBLE_MIN_WIDTH;
            let infoBubbleX = 0;
            let infoBubbleY = 0;

            // Figure out vertical position
            if (infoBubbleHeight + INFO_BUBBLE_OFFSET + mouseY < maxY) {
                // bottom
                infoBubbleY = mouseY + INFO_BUBBLE_OFFSET;
            } else if (infoBubbleHeight + INFO_BUBBLE_OFFSET < mouseY) {
                // top
                infoBubbleY = mouseY - infoBubbleHeight - INFO_BUBBLE_OFFSET;
            }

            // Figure out horizontal position
            const halfInfoBubbleWidth = Math.ceil(infoBubbleWidth / 2);
            if (halfInfoBubbleWidth < mouseX && mouseX + halfInfoBubbleWidth < maxX) {
                // center
                infoBubbleX = mouseX - halfInfoBubbleWidth;
            } else if (infoBubbleWidth + INFO_BUBBLE_OFFSET < mouseX) {
                // left
                infoBubbleX = mouseX - (infoBubbleWidth + INFO_BUBBLE_OFFSET);
            } else {
                // right
                infoBubbleX = mouseX + INFO_BUBBLE_OFFSET;
            }
            self.infoBubble.style.left = `${infoBubbleX}px`;
            self.infoBubble.style.top = `${infoBubbleY}px`;
            this.animationFrame = undefined;
        };
        if (!this.animationFrame) {
            this.animationFrame = window.requestAnimationFrame(animationHandler);
        }
    };

    onInfoBubbleClose = e => {
        this.setState({
            infoBubbleVisible: false,
            infoBubbleExpanded: false,
        });
        if (this.state.infoBubbleMode === InfoBubbleMode.CLICK) {
            removeEvent(document, 'click', this.updateInfoBubble);
            this.rolledOut = true;
            this.listenersAdded = false;
            // also clear highlighted features on map
            this.emit('CLEAR_HIGHLIGHTED_FEATURES', { source: this, originalEvent: e });
        }
    };

    onInfoBubbleExpand = () => {
        // if it's a phone device clicking on info bubble title should not work
        if (!this.context.isPhoneDevice) return;
        this.setState({ infoBubbleExpanded: true });
    };

    onInfoBubbleCollapse = () => {
        // if it's a phone device clicking on info bubble title should not work
        if (!this.context.isPhoneDevice) return;
        this.setState({ infoBubbleExpanded: false });
    };

    render() {
        const { isPhoneDevice, isCondensedLayout } = this.context;
        const { geoTitleOnly } = this.props;
        const {
            infoBubbleVisible,
            infoBubbleExpanded,
            infoBubble,
            lastHoveredMap,
            infoBubbleMode,
        } = this.state;

        const infoBubbleClasses = classNames('info-bubble', {
            'info-bubble--embedded-phone': isPhoneDevice && isCondensedLayout,
            'info-bubble--expanded': infoBubbleExpanded,
            'info-bubble--visible': infoBubbleVisible,
            'info-bubble--invisible': !infoBubbleVisible,
            'info-bubble--fixed-width': !geoTitleOnly,
            'info-bubble--click': infoBubbleMode === InfoBubbleMode.CLICK,
        });

        let infoBubbleGroups;
        if (infoBubble && Object.keys(infoBubble).length > 0) {
            const mapIdsToBeMapped = this.prepareMapIds();
            // make sure that the last hovered map is always shown first
            mapIdsToBeMapped.splice(mapIdsToBeMapped.indexOf(lastHoveredMap), 1);
            mapIdsToBeMapped.unshift(lastHoveredMap);

            infoBubbleGroups = mapIdsToBeMapped.map(
                mapId => (
                    <InfoBubbleGroup
                        key={mapId}
                        onInfoBubbleClose={this.onInfoBubbleClose}
                        onInfoBubbleExpand={this.onInfoBubbleExpand}
                        onInfoBubbleCollapse={this.onInfoBubbleCollapse}
                        isExpanded={infoBubbleExpanded}
                        isComposite={mapIdsToBeMapped.length === 2}
                        isMimicking={infoBubble[mapId] ? infoBubble[mapId].isMimicking : undefined}
                        features={infoBubble[mapId] ? infoBubble[mapId].features : []}
                        mapId={mapId}
                        mapIdIndex={mapIdsToBeMapped.indexOf(mapId)}
                        lastHoveredMap={lastHoveredMap}
                    />
                ),
                this
            );
        }

        return (
            <div ref={c => (this.infoBubble = c)} className={infoBubbleClasses}>
                {infoBubbleGroups}
            </div>
        );
    }

    prepareMapIds() {
        const { infoBubble, lastHoveredMap } = this.state;
        if (this.isBubbleComposite(infoBubble)) {
            return Object.keys(infoBubble) ? Object.keys(infoBubble) : [];
        }
        return [lastHoveredMap];
    }

    isBubbleComposite(infoBubble) {
        const { mapInstances } = this.props;
        if (Object.keys(infoBubble).length === 2 && mapInstances.length > 1) {
            const variableSelectionA = mapInstances[0].dataTheme.variableSelection;
            const variableSelectionB = mapInstances[1].dataTheme.variableSelection;

            const mapIds = Object.keys(infoBubble);
            // Check if two maps are at the same geographical zoom (state vs county etc.) Check if the two variables are the same
            const areGeoTypesEqual =
                infoBubble[mapIds[0]].mapViewer.activeSummaryLevel &&
                infoBubble[mapIds[1]].mapViewer.activeSummaryLevel &&
                infoBubble[mapIds[0]].mapViewer.activeSummaryLevel.id === infoBubble[mapIds[1]].mapViewer.activeSummaryLevel.id;
            const areVariablesEqual =
                !variableSelectionA.items.some(selectedVariable => variableSelectionB.getItemByGuid(selectedVariable.variableGuid) === undefined) &&
                variableSelectionA.items.length === variableSelectionB.items.length;
            return areGeoTypesEqual && !areVariablesEqual;
        }
        return false;
    }

    static checkWinner(guid, properties, source) {
        const variableGuids = Object.keys(properties);
        const winnerVariable = `winner_${guid}`;
        // Why? Because some come with qualified form and some in raw form.
        const variableSelectionItem = source.appliedDataTheme.variableSelection.getItemByGuid(guid);
        const altWinnerVariable = variableSelectionItem ? `winner_${variableSelectionItem.qualifiedName.toLowerCase()}` : 'none';
        const exists =
            variableGuids.includes(winnerVariable) ||
            variableGuids.includes(altWinnerVariable);
        const isWinner =
            (properties[winnerVariable] && properties[winnerVariable]) ||
            (properties[altWinnerVariable] && properties[altWinnerVariable]);
        return exists && isWinner;
    }

    static isUniverseVariable(variableSelection, guid) {
        return !variableSelection.getItemByGuid(guid);
    }

    static addComputedVariableValue(
        infoBubbleDataRows,
        field,
        element,
        variableGuid
    ) {
        const fieldNumeratorGuid = parseFieldName(field.fieldNumerator).variableGuid;
        const fieldDenominator = field.fieldDenominator && element.properties[parseFieldName(field.fieldDenominator).variableGuid];
        const fieldNumerator = element.properties[fieldNumeratorGuid];
        const fieldMultiplier = field.fieldMultiplier;

        // For computed variables change existing variable data row
        if (field.computeFunction === 'COMPUTE_MULTIPLY') {
            if (element.properties[field.fieldName] !== undefined) {
                infoBubbleDataRows[fieldNumeratorGuid].value = format({
                    number: element.properties[field.fieldName],
                    numberFormat: field.formatting,
                });

                if (field.label) {
                    infoBubbleDataRows[fieldNumeratorGuid].name = field.label;
                }
            } else if (fieldMultiplier === undefined || (fieldNumerator === 0 && fieldMultiplier === 0)) {
                infoBubbleDataRows[fieldNumeratorGuid].value = 'n/a';
            }
        } else if (element.properties[variableGuid] !== undefined) {
            const value = format({
                number: element.properties[variableGuid],
                numberFormat: field.formatting,
            });

            if (field.isChangeOverTimeField) {
                infoBubbleDataRows[variableGuid] = {
                    labelColor: undefined,
                    name: field.label,
                    value,
                    isWinner: undefined,
                    isChangeOverTimeRow: true,
                    universe: field.universe,
                };
            } else {
                infoBubbleDataRows[fieldNumeratorGuid].additionalValue = value;
            }
        } else if (fieldDenominator === undefined || (fieldNumerator === 0 && fieldDenominator === 0)) {
            infoBubbleDataRows[fieldNumeratorGuid].additionalValue = 'n/a';
        }
    }

    static figureOutLabelStyle(content, currentColorPalette, variable, element, colorIndex) {
        const { visualizationType, variableSelection } = content.source.appliedDataTheme;
        const hasLabelColor = !this.isUniverseVariable(variableSelection, variable.variableGuid);

        let labelColor, isWinner = false;
        if (hasLabelColor) {
            if (visualizationType === VisualizationType.DOT_DENSITY && currentColorPalette.colors) {
                labelColor = currentColorPalette.colors[colorIndex];
            } else if (visualizationType === VisualizationType.SHADED_AREA) {
                labelColor = currentColorPalette.colorRamps[colorIndex].interpolateColors(3)[1];
                isWinner = InfoBubble.checkWinner(variable.variableGuid, element.properties, content.source);
            }
        }
        return { labelColor, isWinner };
    }

    addRegularVariableValue(infoBubbleDataRows, field, element, variable, content, currentColorPalette) {
        const colorIndex = Object.keys(infoBubbleDataRows)
            .map(k => infoBubbleDataRows[k])
            .filter(row => row.labelColor !== undefined).length;
        let labelStyle = {};
        if (content.source.appliedDataTheme.variableSelection.isMultiVariable) {
            labelStyle = InfoBubble.figureOutLabelStyle(
                content,
                currentColorPalette,
                variable,
                element,
                colorIndex
            );
        }
        let value, isCategorical = false;
        if (element.properties[variable.variableGuid] === undefined) {
            value = 'n/a';
        } else if (
            !field.formatting &&
            content.source.mapInstance.dataTheme.isVisualizationCategorical
        ) {
            isCategorical = true;
            const rule = content.source.mapInstance.dataTheme.getRuleForData(
                element.properties,
                variable.variableGuid
            );
            if (
                rule &&
                rule.filter &&
                rule.filter.label &&
                rule.filter.label !== ''
            ) {
                value = rule.filter.label;
            } else {
                value = element.properties[variable.variableGuid].toString();
            }
        } else {
            value = format({
                number: element.properties[variable.variableGuid],
                numberFormat: field.formatting,
            });
        }
        infoBubbleDataRows[variable.variableGuid] = {
            labelColor: labelStyle.labelColor,
            name: field.label,
            value,
            isWinner: labelStyle.isWinner,
            isCategorical,
            universe: field.universe,
        };
        this.addVariableAdditionalInfo(infoBubbleDataRows, content, variable);
        // if the value is valid, return true
        return value !== 'n/a';
    }

    addVariableAdditionalInfo(infoBubbleDataRows, content, variable) {
        // add variable additional metadata info to tooltip if it is present and only single variable is selected
        if (content.source.appliedDataTheme.variableSelection.isMultiVariable || !this.state.currentMetadata) {
            return;
        }

        const metaSelection = getMetadataObjectsFromVariableSelection(
            content.source.appliedDataTheme.variableSelection,
            this.state.currentMetadata
        );
        const metaVariable = metaSelection.table.variablesAsArray
            .find(v => v.uuid === variable.variableGuid);

        if (!metaVariable) {
            return;
        }

        const { parsedCustomTooltip } = metaVariable;
        if (parsedCustomTooltip.text) {
            infoBubbleDataRows[`${variable.variableGuid}_info`] = {
                value: undefined,
                name: parsedCustomTooltip.text,
                isAdditionalInfo: true,
                isTextInfo: true,
            };
        } else if (parsedCustomTooltip.variables.length) {
            parsedCustomTooltip.variables.forEach(
                refVariable => {
                    const refVariableField = content.source.mapInstance.dataTheme.rendering[0].fieldList.fields
                        .find(field => field.label.startsWith(`#${refVariable.label}#`));
                    if (refVariableField) {
                        let refVariableValue = content.features[0].properties[refVariableField.fieldName];
                        if (refVariableValue) {
                            refVariableValue = format({
                                number: refVariableValue,
                                numberFormat: refVariableField.formatting,
                            });
                        }
                        infoBubbleDataRows[`${variable.variableGuid}_${refVariable.label}_info`] = {
                            value: refVariableValue,
                            name: refVariableField.label.replace(`#${refVariable.label}#`, ''),
                            isAdditionalInfo: true,
                        };
                    }
                }
            );
        }
    }

    addFilterValue(infoBubbleFilterDataRows, element, content, filterRule) {
        const { intl } = this.props;
        const { field } = filterRule;
        const { fieldName, formatting, universe } = field;
        const { mapInstance } = content.source;
        let value, isCategorical = false;
        if (element.properties[fieldName] === undefined) {
            value = 'n/a';
        } else if (
            !field.formatting &&
            mapInstance.dataTheme.isVisualizationCategorical
        ) {
            isCategorical = true;
            const rule = mapInstance.dataTheme.getRuleForData(
                element.properties,
                fieldName
            );
            if (
                rule &&
                rule.filter &&
                rule.filter.label &&
                rule.filter.label !== ''
            ) {
                value = rule.filter.label;
            } else {
                value = element.properties[fieldName].toString();
            }
        } else {
            value = format({
                number: element.properties[fieldName],
                numberFormat: formatting,
            });
        }

        const { qLabel, textId, formattedValue, percentageLabel } = filterRule.toStringDecomposed();
        const percentageText = percentageLabel ? ` ${intl.formatMessage({ id: 'of' })} ${percentageLabel}` : '';
        const label = <span>{qLabel} <strong>{intl.formatMessage({ id: textId })} {formattedValue}</strong>{percentageText}</span>;

        const { filters } = mapInstance.dataFilter;
        const filterId = Object.keys(filters).find(fId => filters[fId].field.fieldName === fieldName);
        if (filters[filterId].status === FilterStatus.VALID) {
            infoBubbleFilterDataRows[fieldName] = {
                name: label,
                value,
                isCategorical,
                universe,
                isFilterRow: true,
                isFilterRuleInvalid: !filterRule.isValid(element.properties),
                additionalValue: filterRule.getPercentValueFormatted(element.properties),
            };
        }

        // if the value is valid, return true
        return value !== 'n/a';
    }

    setBubbleContent(content) {
        let infoBubble = this.state.infoBubble ? this.state.infoBubble : {};
        if (this.rolledOut) {
            infoBubble = {};
        }
        const infoBubbleGroup = {
            features: [],
            mapViewer: content.source,
        };

        // Add features
        content.features.forEach(element => {
            let infoBubbleDataRows = {};
            const infoBubbleFilterDataRows = {};
            const infoBubbleFeature = {
                isMultivariable: content.source.appliedDataTheme.variableSelection.isMultiVariable,
            };
            let fieldList = content.source.appliedDataTheme.rendering[0].fieldList.fields;
            let allFieldsWithoutData = true;

            if (this.props.geoTitleOnly) {
                fieldList = fieldList.filter(field => field.isGeoNameField);
                allFieldsWithoutData = false;
            }

            // ----------------
            // Add Geo title
            const geoNameField = fieldList.find(field => field.isGeoNameField);
            const geoVariable = parseFieldName(geoNameField.fieldName);
            infoBubbleFeature.geoTitle = element.properties[geoVariable.variableGuid];

            // ----------------
            // Add filter data rows if any
            infoBubbleFeature.hasValidFilters = content.source.mapInstance.dataFilter.hasValidFilters();
            infoBubbleFeature.isDataFilterTrue = content.source.mapInstance.dataFilter.isDataFilterTrue(element.properties);
            // add filter variables
            content.source.mapInstance.dataFilter.filtersAsArray.forEach(filterRule => {
                const isValidValue = this.addFilterValue(
                    infoBubbleFilterDataRows,
                    element,
                    content,
                    filterRule,
                );
                if (isValidValue) allFieldsWithoutData = false;
            });

            // ----------------------------------------------------
            // Calculate winner if multi variables and SHADED AREA
            if (infoBubbleFeature.isMultivariable
                && content.source.appliedDataTheme.visualizationType === VisualizationType.SHADED_AREA
            ) {
                const renderer = content.source.appliedDataTheme.rendering[0];
                let maxValue;
                const winners = [];
                renderer.rules.forEach((rulesArray, index) => {
                    const insufficientDataRule =
                        rulesArray[renderer.insufficientDataRuleIndex[index]];
                    const nullDataRule =
                        rulesArray[renderer.nullDataRuleIndex[index]];
                    const valueRule = rulesArray.find(
                        r => r !== nullDataRule && r !== insufficientDataRule
                    );
                    const valueColumn = parseFieldName(
                        valueRule.filter.fieldName
                    ).variableGuid;
                    const value = element.properties[valueColumn];
                    if (
                        (maxValue === undefined && value !== undefined) ||
                        (maxValue !== undefined && value !== undefined && maxValue <= value)
                    ) {
                        maxValue = value;
                        winners.push({
                            value,
                            column:
                            content.source.appliedDataTheme
                                .variableSelection.items[index]
                                .variableGuid,
                        });
                    }
                });
                winners.forEach(winner => { if (winner.value === maxValue)element.properties[`winner_${winner.column}`] = true; });
            }

            // ------------------------------
            // Add data rows for each feature
            fieldList.filter(field => !field.isGeoNameField).forEach(field => {
                const variable = parseFieldName(field.fieldName);
                if (field.hideFromUser) return;

                if (!field.isComputed || content.source.appliedDataTheme.isCompareOverTime) {
                    const currentColorPalette = this.state.currentColorPalettes.getColorPaletteByTypeAndId(
                        content.source.appliedDataTheme.colorPaletteType,
                        content.source.appliedDataTheme.colorPaletteId,
                        content.source.currentBaseMap.colorPalettesURL
                    );

                    const isValidValue = this.addRegularVariableValue(
                        infoBubbleDataRows,
                        field,
                        element,
                        variable,
                        content,
                        currentColorPalette
                    );
                    if (isValidValue) allFieldsWithoutData = false;
                } else {
                    InfoBubble.addComputedVariableValue(
                        infoBubbleDataRows,
                        field,
                        element,
                        variable.variableGuid
                    );
                }
            });

            if (allFieldsWithoutData) infoBubbleDataRows = {};
            infoBubbleFeature.dataRows = infoBubbleDataRows;
            infoBubbleFeature.filterDataRows = infoBubbleFilterDataRows;
            infoBubbleGroup.features.push(infoBubbleFeature);
            infoBubbleGroup.isMimicking = content.isMimicking;
            infoBubbleFeature.geoTitleOnly = this.props.geoTitleOnly;
        });

        infoBubble[content.source.id] = infoBubbleGroup;
        return infoBubble;
    }
}

export default injectIntl(InfoBubble);
