// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

/**
 * @param {object} props
 * @param {string} props.className
 * @param {() => void} props.onClick
 * @param {import('react-intl').intlShape} props.intl
 * @param {boolean} props.disabled
 */
const UploadDataButton = ({ className, onClick, disabled, intl }) => (
    <button className={className} onClick={onClick} disabled={disabled}>
        <i className="material-icons btn-chip__icon">cloud_upload</i>
        {intl.formatMessage({ id: 'dataBrowser.uploadData' })}
    </button>
);

UploadDataButton.propTypes = {
    className: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
};

UploadDataButton.defaultProps = {
    disabled: false,
};

export default injectIntl(UploadDataButton);
