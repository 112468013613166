import GLU from '../glu2.js/src/index';

class ContoursApi extends GLU.Api {
  constructor(endpoint) {
    super(endpoint);

    this.createApiActions({
      name: 'getContours',
      method: GLU.Api.Get,
      path: '/contours',
      credentials: true,
    });
  }
}

export default ContoursApi;
