const DataType = {
    ID: 0,
    INTEGER: 1,
    DOUBLE: 2,
    TEXT: 3,
    DATE: 4,
    BOOLEAN: 5,
    GEOGRAPHY: 6,
    JSON: 8,
    JSONB: 9,
    GEOGRAPHY_REFERENCE: 10,
    FOREIGN_KEY: 11,
};

export default DataType;
