import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DataFilterWarningTypes from '../../../enums/DataFilterWarningTypes';

import BusComponent from '../../BusComponent';
import MenuTrigger from '../MenuTrigger.';
import QuickYearRangeSelectionButton from './QuickYearRangeSelectionButton';

const getAvailableCompareYearIndices = (currentYearItem, currentYearIndex, comparableVariables, metadata) => {
    const comparableBeforeCurrentYear = comparableVariables.slice(0, currentYearIndex);
    const comparableAfterCurrentYear = comparableVariables.slice(currentYearIndex + 1);

    // try to determine if there is any comparable future year
    // future is determined in real-time (is not relative to the currently selected year)
    let comparableFutureYearIndex;
    if (comparableAfterCurrentYear.length) {
        const lastComparableAfterCurrentYear = comparableAfterCurrentYear[comparableAfterCurrentYear.length - 1];
        const lastComparableYear = metadata.surveys[lastComparableAfterCurrentYear.surveyName].year;
        const realtimeCurrentYear = new Date().getFullYear();
        if (lastComparableYear > realtimeCurrentYear) {
            comparableFutureYearIndex = comparableVariables.length - 1;
        }
    }

    // calculate year differences for years before the currently selected year
    const comparableYearDifferences =
        comparableBeforeCurrentYear
            .slice(0, comparableBeforeCurrentYear.length - 1)
            .map(
                (comparableVariable, idx) => {
                    const currentYear = metadata.surveys[comparableVariable.surveyName].year;
                    const nextComparableYear = metadata.surveys[comparableVariables[idx + 1].surveyName].year;
                    return nextComparableYear - currentYear;
                }
            );
    const allYearDifferencesSame = comparableYearDifferences.every(diff => diff === comparableYearDifferences[0]);

    // first, determine comparables before current year, if any
    let compareYearIndices;
    if (allYearDifferencesSame) {
        switch (comparableBeforeCurrentYear.length) {
        case 0:
        case 1:
        case 2: {
            compareYearIndices = comparableBeforeCurrentYear.map((item, idx) => idx).reverse();
            break;
        }
        case 3: {
            compareYearIndices = [currentYearIndex - 1, currentYearIndex - 3];
            break;
        }
        case 4: {
            compareYearIndices = [currentYearIndex - 2, currentYearIndex - 4];
            break;
        }
        default: {
            // 5+
            compareYearIndices = [currentYearIndex - 3, currentYearIndex - 5];
            break;
        }
        }
    } else {
        switch (comparableBeforeCurrentYear.length) {
        case 0:
        case 1:
        case 2: {
            compareYearIndices = comparableBeforeCurrentYear.map((item, idx) => idx).reverse();
            break;
        }
        case 3: {
            compareYearIndices = [currentYearIndex - 1, currentYearIndex - 3];
            break;
        }
        default: {
            // 4+
            compareYearIndices = [currentYearIndex - 2, currentYearIndex - 4];
            break;
        }
        }
    }

    // inject the future comparable if it exists, by removing the more recent past year, if there is any
    // and by adding future year as the second comparable
    if (comparableFutureYearIndex) {
        if (compareYearIndices.length === 2) {
            compareYearIndices.shift();
        }
        compareYearIndices.push(comparableFutureYearIndex);
    }

    return compareYearIndices;
};

class ChangeOverTime extends BusComponent {
    static NAME = 'ChangeOverTime';

    constructor(props, context) {
        super(props, context);

        const { mapInstance, comparableVariables, metadata } = props;
        const { dataTheme } = mapInstance;

        const currentYearItem = dataTheme.variableSelection.items[0];
        const currentYearIndex = comparableVariables.findIndex(variable => currentYearItem.surveyName === variable.surveyName);

        const compareYearItem = dataTheme.isChangeOverTimeApplied ?
                                dataTheme.appliedChangeOverTimeCompareSelection :
                                undefined;
        const compareYearIndex = dataTheme.isChangeOverTimeApplied ?
                                 comparableVariables.findIndex(variable => compareYearItem.surveyName === variable.surveyName) :
                                 undefined;

        const pointOfReferenceYearItem = dataTheme.isChangeOverTimeApplied ?
                                         dataTheme.appliedChangeOverTimePointOfReference :
                                         currentYearItem;

        this.state = {
            availableCompareYearIndices:
                getAvailableCompareYearIndices(currentYearItem, currentYearIndex, comparableVariables, metadata),
            currentYearItem,
            currentYearIndex,
            pointOfReferenceYearItem,
            compareYearItem,
            compareYearIndex,
        };
    }

    componentWillMount() {
        this.bindGluBusEvents({
            APPLYING_CHANGE_OVER_TIME_REQUEST_STARTED: this.onApplyingChangeOverTimeRequestStarted,
            APPLYING_CHANGE_OVER_TIME_REQUEST_DONE: this.onApplyingChangeOverTimeRequestDone,
        });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !nextState.isApplyingChangeOverTimeInProgress;
    }

    componentWillReceiveProps(nextProps) {
        const { mapInstance, comparableVariables, metadata } = nextProps;
        const { dataTheme } = mapInstance;

        if (this.state.isApplyingChangeOverTimeInProgress) {
            return;
        }

        const currentYearItem = dataTheme.variableSelection.items[0];
        const currentYearIndex = comparableVariables.findIndex(variable => currentYearItem.surveyName === variable.surveyName);

        const compareYearItem = dataTheme.isChangeOverTimeApplied ?
                                dataTheme.appliedChangeOverTimeCompareSelection :
                                undefined;
        const compareYearIndex = dataTheme.isChangeOverTimeApplied ?
                                 comparableVariables.findIndex(variable => compareYearItem.surveyName === variable.surveyName) :
                                 undefined;

        const pointOfReferenceYearItem = dataTheme.isChangeOverTimeApplied ?
                                         dataTheme.appliedChangeOverTimePointOfReference :
                                         currentYearItem;

        this.setState({
            availableCompareYearIndices:
                getAvailableCompareYearIndices(currentYearItem, currentYearIndex, comparableVariables, metadata),
            currentYearItem,
            currentYearIndex,
            compareYearItem,
            compareYearIndex,
            pointOfReferenceYearItem,
        });
    }

    onApplyingChangeOverTimeRequestStarted = () => {
        this.setState(
            {
                isApplyingChangeOverTimeInProgress: true,
            },
        );
    }

    onApplyingChangeOverTimeRequestDone = cotRequest => {
        this.setState(
            {
                pointOfReferenceYearItem: cotRequest.pointOfReferenceItem,
                isApplyingChangeOverTimeInProgress: undefined,
            },
        );
    }

    onQuickYearRangeSelectionButtonClick = (e, currentYear, compareYear, pointOfReferenceYear) => {
        const currentYearIndex =
            this.props.comparableVariables
                .findIndex(variable => this.props.metadata.surveys[variable.surveyName].year === currentYear);

        const compareYearIndex =
            this.props.comparableVariables
                .findIndex(variable => this.props.metadata.surveys[variable.surveyName].year === compareYear);

        const pointOfReferenceYearIndex = currentYear === pointOfReferenceYear ?
                                          currentYearIndex :
                                          compareYearIndex;
        const pointOfReferenceYearItem = this.props.comparableVariables[pointOfReferenceYearIndex];

        if (this.state.currentYearIndex !== currentYearIndex || this.state.compareYearIndex !== compareYearIndex) {
            this.setState(
                { pointOfReferenceYearItem },
                () => {
                    this.setChangeOverTime(currentYearIndex, compareYearIndex, pointOfReferenceYearIndex, e);
                }
            );
        }
    }

    onCancelChangeOverTime = () => {
        this.emit('CLEAR_CHANGE_OVER_TIME_REQUEST', {
            mapInstanceId: this.props.mapInstance.id,
            item: this.state.pointOfReferenceYearItem,
        });
    }

    setChangeOverTime = (currentYearIndex, compareYearIndex, pointOfReferenceYearIndex, event) => {
        const requestChangeOverTime =
            () => this.onChangeOverTimeRequest(currentYearIndex, compareYearIndex, pointOfReferenceYearIndex, event);

        // DataFilter needs to be cleared if changing survey or activating COT
        if (this.props.mapInstance.hasDataFilter) {
            this.onClearDataFilter(requestChangeOverTime, event);
        } else {
            requestChangeOverTime();
        }
    }

    onClearDataFilter = (requestChangeOverTime, event) => {
        const { mapInstance } = this.props;

        const warningPayload = {
            mapInstanceId: mapInstance.id,
            position: DataFilterWarningTypes.CHANGE_OVER_TIME_MAP_PANEL_WARNING,
            anchorElement: event.target,
            onConfirm: () => requestChangeOverTime(),
            onCancel: () => {},
        };

        this.bus.emit('SHOW_CLEAR_DATA_FILTER_WARNING', warningPayload);
    }

    onChangeOverTimeRequest = (currentYearIndex, compareYearIndex, pointOfReferenceYearIndex, event) => {
        const { mapInstance, comparableVariables } = this.props;

        // COT changes current year selection,
        // In order to achieve expected behaviour of calculating range from single year
        // Use newer year item when switching change year spans
        const dataChangePayload = {
            mapInstanceId: mapInstance.id,
            baseItem: comparableVariables[compareYearIndex],
            compareItem: comparableVariables[currentYearIndex],
            pointOfReferenceItem: comparableVariables[pointOfReferenceYearIndex],
            event,
        };

        this.emit('CHANGE_OVER_TIME_REQUEST', dataChangePayload);
    }

    render() {
        const { currentYearItem, currentYearIndex, compareYearIndex, pointOfReferenceYearItem, availableCompareYearIndices } = this.state;
        const { mapInstance, metadata, comparableVariables, isYearSelectorMenuActive, onConfirm } = this.props;
        const isCOTActive = mapInstance.dataTheme.isChangeOverTimeApplied;

        const compareYearIndices = isCOTActive ? [compareYearIndex] : availableCompareYearIndices;

        const cotQuickOptions =
            compareYearIndices.map(
                idx => {
                    const isFuture = isCOTActive ?
                                     compareYearIndex < idx :
                                     currentYearIndex < idx;

                    const currentYear = isFuture ?
                                        metadata.surveys[comparableVariables[idx].surveyName].year :
                                        metadata.surveys[currentYearItem.surveyName].year;

                    const compareYear = isFuture ?
                                        metadata.surveys[currentYearItem.surveyName].year :
                                        metadata.surveys[comparableVariables[idx].surveyName].year;

                    const pointOfReferenceYear = metadata.surveys[pointOfReferenceYearItem.surveyName].year;

                    return (
                        <QuickYearRangeSelectionButton
                            key={`cot-quick-option-${idx}`}
                            currentYear={currentYear}
                            compareYear={compareYear}
                            pointOfReferenceYear={pointOfReferenceYear}
                            isActive={isCOTActive && compareYearIndex === idx}
                            onClick={this.onQuickYearRangeSelectionButtonClick}
                            onCancel={this.onCancelChangeOverTime}
                        />
                    );
                }
            );

        const yearPickerButtonClasses = classNames('btn-icon', 'map-panel__change-over-time__control');
        return (
            <div className="map-panel__change-over-time">
                <div className="map-panel__change-over-time__title">
                    {this.props.intl.formatMessage({ id: 'dataBrowser.exploreChangeOverTime' })}
                </div>
                <div className="map-panel__change-over-time__controls">
                    {cotQuickOptions}
                    <MenuTrigger
                        active={isYearSelectorMenuActive}
                        className={yearPickerButtonClasses}
                        label={this.props.intl.formatMessage({ id: 'dataBrowser.changeYear' })}
                        onClick={onConfirm}
                        icon={<i className="material-icons">date_range</i>}
                    />
                </div>
            </div>
        );
    }
}

ChangeOverTime.propTypes = {
    mapInstance: PropTypes.object.isRequired,
    comparableVariables: PropTypes.array.isRequired,
    metadata: PropTypes.object.isRequired,
    isYearSelectorMenuActive: PropTypes.bool.isRequired,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(ChangeOverTime);
