/* eslint class-methods-use-this: 0 */

import BaseController from './BaseController';
import ProjectDataSource from '../dataSources/ProjectDataSource';
import MetadataDataSource from '../dataSources/MetadataDataSource';
import LibraryLayer from '../objects/LibraryLayer';
import LibraryGroup from '../objects/LibraryGroup';

class LayerLibraryController extends BaseController {
    static get name() {
        return 'LayerLibraryController';
    }

    static getInstance(options) {
        return new LayerLibraryController(options);
    }

    onActivate() {
        this.bindGluBusEvents({
            UPDATE_LAYER_LIBRARY_LAYER_VISIBILITY: this.onUpdateLibraryLayerVisibility,
            UPDATE_LAYER_LIBRARY_GROUP_VISIBILITY: this.onUpdateLibraryGroupVisibility,
            ADD_LAYER_LIBRARY_GROUP: this.onAddLibraryGroup,
            REMOVE_LAYER_LIBRARY_GROUP: this.onRemoveLibraryGroup,
        });

        this.projectDataSource = this.activateSource(ProjectDataSource);
        this.metadataDataSource = this.activateSource(MetadataDataSource);
    }

    /**
     * @param {object} e
     * @param {string} e.id
     * @param {string} e.groupId
     * @param {boolean} e.visible
     * @param {string} e.mapInstanceId
     */
    onUpdateLibraryLayerVisibility(e) {
        const { id, groupId, visible, mapInstanceId } = e;

        const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);
        const libraryGroup = mapInstance.libraryDataLayers.find(olg => olg.id === groupId);

        const { layers } = libraryGroup;
        const libraryLayer = layers.find(l => l.id === id);

        libraryLayer.visible = visible;

        this.bus.emit('MAP_APPLY_LIBRARY_DATA_LAYERS_UPDATE', { mapInstanceId: mapInstance.id });
    }

    onUpdateLibraryGroupVisibility(e) {
        const { id, visible, mapInstanceId } = e;

        const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);

        const libraryGroup = mapInstance.libraryDataLayers.find(olg => olg.id === id);

        if (!libraryGroup) return;

        const { layers } = libraryGroup;

        libraryGroup.visible = visible;

        layers.forEach(layer => {
            layer.visible = visible;
        });

        this.bus.emit('MAP_APPLY_LIBRARY_DATA_LAYERS_UPDATE', { mapInstanceId: mapInstance.id });
    }

    onAddLibraryGroup(e) {
        const { group, mapInstanceId } = e;
        const { id, metadata, layers } = group;

        const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);

        if (mapInstance.libraryDataLayers.some(libraryDataLayer => libraryDataLayer.id === id)) {
            return;
        }

        const groupDefinition = this.metadataDataSource.libraryData.groups.find(g => g.id === id);
        const layersDefinitions = groupDefinition.layers.map(lay => lay.layers);
        const layersDefinitionsFlatten = [].concat(...layersDefinitions);

        const libraryGroup = new LibraryGroup();
        libraryGroup.id = id;
        libraryGroup.title = metadata.title;
        libraryGroup.visible = true;
        libraryGroup.metadata = groupDefinition.metadata;
        libraryGroup.sources = groupDefinition.sources;
        libraryGroup.layersDefinition = layersDefinitionsFlatten;
        libraryGroup.layers = layers.map(layer => new LibraryLayer({
            id: layer.id,
            title: layer.title,
            visible: true,
        }));

        mapInstance.libraryDataLayers.push(libraryGroup);

        this.bus.emit('MAP_APPLY_LIBRARY_DATA_LAYERS_UPDATE', { mapInstanceId: mapInstance.id });
    }

    onRemoveLibraryGroup(e) {
        const { id, mapInstanceId } = e;

        const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);
        const libraryGroupIndex = mapInstance.libraryDataLayers.findIndex(olg => olg.id === id);
        mapInstance.libraryDataLayers.splice(libraryGroupIndex, 1);

        this.bus.emit('MAP_APPLY_LIBRARY_DATA_LAYERS_UPDATE', { mapInstanceId: mapInstance.id });
    }

    onDeactivate() {
        this.unbindGluBusEvents();
    }
}

export default LayerLibraryController;
