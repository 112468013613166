import React from 'react';
import PropTypes from 'prop-types';
import BusComponent from '../BusComponent';
import classNames from 'classnames';

import WarningPopup from './WarningPopup';

class DataFilterWarning extends BusComponent {
    constructor(props, context) {
        super(props, context);

        this.state = {
            open: false,
        };
    }

    componentDidMount() {
        this.bindGluBusEvents({
            SHOW_CLEAR_DATA_FILTER_WARNING: this.onShowWarningPopup,
        });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    onCancel = () => {
        this.setState({
            open: false,
        });

        this.state.onCancel();

        // Enable events on data browser
        this.emit('FRAME_COMPONENT_ENABLE_EVENTS');
    }

    onConfirm = () => {
        this.setState({
            open: false,
        });

        this.emit('REMOVE_DATA_FILTER_REQUEST', { mapInstanceId: this.state.mapInstanceId });
    }

    onFilterRemoved = () => {
        this.state.onConfirm();

        // Enable mouse events on frame floating components
        this.emit('FRAME_COMPONENT_ENABLE_EVENTS');
    }

    onShowWarningPopup = ({ mapInstanceId, anchorElement, position, onConfirm, onCancel }) => {
        // Disable mouse events on origin components
        this.emit('FRAME_COMPONENT_DISABLE_EVENTS');

        // On confirmation and filter deletion, remove popup.
        this.bus.once('MAP_DATA_FILTER_REMOVED', this.onFilterRemoved);

        this.setState({
            open: true,
            mapInstanceId,
            anchorElement,
            position,
            onConfirm,
            onCancel,
        });
    }

    render() {
        const { open, anchorElement, mapInstanceId, position } = this.state;
        const { mapInstances } = this.props;

        if (!open) return null;

        const classes = classNames('data-filter-warning-backdrop', { hidden: !open });
        // When displaying popup it might get cut off, for those instance we provide separate position definitions
        const isRightMap = mapInstances.findIndex(instance => instance.id === mapInstanceId);
        const positionAdjusted = isRightMap ? position.RIGHT || position : position.LEFT || position;

        return (<div className={classes}>
            <div className="data-filter-warning-searchlight" ref={c => { this.searchlight = c; }} />
            <WarningPopup
                open={open}
                anchorElement={anchorElement}
                position={positionAdjusted}
                onCancel={this.onCancel}
                onConfirm={this.onConfirm}
            />
        </div>);
    }
}

DataFilterWarning.propTypes = {
    mapInstances: PropTypes.array.isRequired,
};

export default DataFilterWarning;
