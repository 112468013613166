import BaseController from './BaseController';
import { isStorageAvailable } from '../helpers/Util';
import AppConfig from '../appConfig';

const STORAGE = window.localStorage;
const IS_STORAGE_AVAILABLE = isStorageAvailable(STORAGE);
const HELP_TOUR_STORAGE_KEY = `${AppConfig.constants.environment}-${AppConfig.constants.helpTourCompletedFlagName}`;

class HelpController extends BaseController {
    static get name() {
        return 'HelpController';
    }

    static getInstance(options) {
        return new HelpController(options);
    }

    onActivate() {
        this.bindGluBusEvents({
            HELP_TOUR_STATUS_REQUEST: this.onHelpTourStatusRequest,
            HELP_TOUR_STATUS_UPDATE_REQUEST: this.onHelpTourStatusUpdateRequest,
        });
        this.isHelpTourDone = undefined;
    }

    onHelpTourStatusRequest() {
        if (this.isHelpTourDone === undefined && IS_STORAGE_AVAILABLE) {
            this.isHelpTourDone = STORAGE.getItem(HELP_TOUR_STORAGE_KEY) === 'true';
            this.bus.emit('HELP_TOUR_STATUS_UPDATE_REQUEST', { isDone: this.isHelpTourDone });
        }

        if (this.isHelpTourDone === false) {
            this.bus.emit('HELP_TOUR_START_REQUEST');
        }
    }

    onHelpTourStatusUpdateRequest(params) {
        this.isHelpTourDone = params.isDone;
        if (IS_STORAGE_AVAILABLE) {
            STORAGE.setItem(HELP_TOUR_STORAGE_KEY, this.isHelpTourDone);
        }
    }

    onDeactivate() {
        this.unbindGluBusEvents();
    }
}

export default HelpController;
