// @ts-check
import React from 'react';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Loader from '../Loader';
import BusComponent from '../BusComponent';

/**
 * @typedef Props
 * @property {import('react-intl').intlShape} intl
 *
 * @typedef State
 * @property {boolean} creatingReport
 * @property {string} creatingReportMessage
 *
 * @extends {BusComponent<Props, State>}
 */

class LocationAnalysisCreateReportLoader extends BusComponent {
    constructor(props, context) {
        super(props, context);
        /** @type {State} */
        this.state = {
            creatingReport: false,
            creatingReportMessage: undefined,
        };
    }

    componentDidMount() {
        this.bindGluBusEvents({
            SET_CREATING_REPORT_MESSAGE: this.onReportCreateMessage,
            CREATE_REPORT_SUCCESS: this.onCreatingReportCompleted,
            CREATE_REPORT_ABORTED_WITH_WARNING: this.onCreatingReportCompleted,
            CREATE_REPORT_ERROR: this.onCreatingReportCompleted,
            DOWNLOAD_REPORT_ERROR: this.onCreatingReportCompleted,
        });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    /** @param {State} payload */
    onReportCreateMessage = ({ creatingReport, creatingReportMessage }) => {
        this.setState({
            creatingReport,
            creatingReportMessage,
        });
    };

    onCreatingReportCompleted = () => {
        this.setState({
            creatingReport: false,
            creatingReportMessage: undefined,
        });
    };

    render() {
        const { intl } = this.props;
        const {
            creatingReport,
            creatingReportMessage,
        } = this.state;
        return (
            <div
                className={classNames('location-analysis--loader', {
                    hidden: !creatingReport,
                })}
            >
                <Loader className="location-analysis__loader" />
                <span className="location-analysis__loader-header">
                    {intl.formatMessage({ id: 'dataBrowser.creatingReport' })}
                </span>
                <span className="location-analysis__loader-text">
                    {creatingReportMessage && intl.formatMessage({ id: creatingReportMessage })}
                    {!creatingReportMessage && <FormattedMessage
                        id={'dataBrowser.yourReportWillBeDisplayedInNewWindow'}
                        values={{
                            br: <br />,
                        }}
                    />}
                </span>
            </div>
        );
    }
}

LocationAnalysisCreateReportLoader.propTypes = {
    intl: PropTypes.object.isRequired,
};

LocationAnalysisCreateReportLoader.defaultProps = {
};

export default injectIntl(LocationAnalysisCreateReportLoader);
