import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import BusComponent from '../BusComponent';
import Tooltip from '../Tooltip';
import IconMenu from '../iconMenu/IconMenu';
import Mixpanel, { MIXPANEL_EVENTS, MIXPANEL_LOCATIONS } from '../../helpers/Mixpanel';

class ExportImageButton extends BusComponent {
    onExportLeftClick = () => {
        this.bus.emit('DISPLAY_MAP_EXPORT_FRAME', {
            mapInstance: this.props.mapInstances[0],
        });
        Mixpanel.emitUserEvent(MIXPANEL_EVENTS.EXPORT, {
            Location: MIXPANEL_LOCATIONS.topNav,
        });
    };

    onExportRightClick = () => {
        this.bus.emit('DISPLAY_MAP_EXPORT_FRAME', {
            mapInstance: this.props.mapInstances[1],
        });
    };

    render() {
        const { mapInstances, disabled } = this.props;

        if (!mapInstances.length) {
            return null;
        }

        if (mapInstances.length === 1) {
            return (
                <Tooltip
                    placement="bottom"
                    mouseEnterDelay={0.5}
                    mouseLeaveDelay={0}
                    overlay={this.props.intl.formatMessage({ id: 'header.exportAsImage' })}
                >
                    <button
                        type="button"
                        tabIndex="0"
                        className={classNames(
                            'btn-flat',
                            'btn-flat--light',
                            'application-header__button',
                            {
                                'btn-flat--disabled': disabled,
                            },
                        )}
                        onClick={this.onExportLeftClick}
                        aria-label={this.props.intl.formatMessage({ id: 'header.exportImage' })}
                    >
                        <i className="socex-icon-export-image" />
                        <div className="margin-left-8">
                            {this.props.intl.formatMessage({ id: 'export' })}
                        </div>
                    </button>
                </Tooltip>
            );
        }

        return (
            <IconMenu
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                targetOrigin={{ vertical: 'top', horizontal: 'right' }}
                light
                label={this.props.intl.formatMessage({ id: 'export' })}
                icon=""
                iconClassName="socex-icon-export-image"
                className="application-header__popup-wrapper"
                tabIndex="0"
                tooltip={this.props.intl.formatMessage({ id: 'header.exportAsImage' })}
                disabled={disabled}
                triggerClassName="application-header__button"
            >
                <div className="material-menu">
                    <div className="material-menu__heading">
                        <FormattedMessage id="header.exportAsImage" />
                    </div>
                    <div className="material-menu__item" onClick={this.onExportLeftClick}>
                        <div className="flex-it center">
                            <i className="material-menu__item__icon socex-icon-export-left-map" />
                            <FormattedMessage id="map.leftMap" />
                        </div>
                    </div>
                    <div className="material-menu__item" onClick={this.onExportRightClick}>
                        <div className="flex-it center">
                            <i className="material-menu__item__icon socex-icon-export-right-map" />
                            <FormattedMessage id="map.rightMap" />
                        </div>
                    </div>
                </div>
            </IconMenu>
        );
    }
}

ExportImageButton.propTypes = {
    disabled: PropTypes.bool,
    mapInstances: PropTypes.array.isRequired,
    intl: PropTypes.object.isRequired,
};

ExportImageButton.defaultProps = {
    disabled: false,
};

export default injectIntl(ExportImageButton);
