// @ts-check
import React from 'react';
import PropTypes from 'prop-types';

import AnalyzeButton from './AnalyzeButton';
import LocationAnalysisCreateReportButton from './LocationAnalysisCreateReportButton';
import AppConfig from '../../appConfig';

/**
 * @param {object} props
 * @param {import('../../objects/MapInstance').default} props.mapInstance
 */
const LocationAnalysisExpandButton = ({ mapInstance }) => (
    <div className="padded-horizontal-16 padded-vertical-8 flex-it center space-between">
        <LocationAnalysisCreateReportButton
            mapInstanceId={mapInstance.id}
            locationAnalysisItem={mapInstance.locationAnalysisItem}
        />
        {AppConfig.constants.shouldShowAnalyzeButton && (
            <div className="flex-it center grow flex-end">
                <AnalyzeButton
                    mapInstanceId={mapInstance.id}
                    locationAnalysisItem={mapInstance.locationAnalysisItem}
                    variant="raised"
                />
            </div>
        )}
    </div>
);

LocationAnalysisExpandButton.propTypes = {
    mapInstance: PropTypes.object.isRequired,
};

export default LocationAnalysisExpandButton;
