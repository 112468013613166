// @ts-check
import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import LayerLibraryButton from './LayerLibraryButton';
import UploadDataButton from './UploadDataButton';

/**
 * @typedef Props
 * @property {import('react-intl').intlShape} intl
 * @property {boolean} isUserPro
 * @property {() => void} onAddLayersClick
 * @property {() => void} onDataUploadClick
 *
 * @param {Props} props
 */
const MapLayersEditorActions = ({
    intl,
    isUserPro,
    onAddLayersClick,
    onDataUploadClick,
}) => {
    const chipClasses = classNames('btn-chip', {
        'btn-chip--disabled': !isUserPro,
    });

    return (
        <div className="map-layers-editor__actions">
            <div className="body-2">
                {intl.formatMessage({ id: 'dataBrowser.addNewLayerFrom' })}
            </div>
            <div className="flex-it center">
                <LayerLibraryButton
                    className={chipClasses}
                    onClick={onAddLayersClick}
                    icon="folder"
                    label={intl.formatMessage({
                        id: 'dataBrowser.layerLibrary',
                    })}
                />
                <UploadDataButton
                    className={chipClasses}
                    onClick={onDataUploadClick}
                />
            </div>
        </div>
    );
};

MapLayersEditorActions.propTypes = {
    isUserPro: PropTypes.bool.isRequired,
    onAddLayersClick: PropTypes.func.isRequired,
    onDataUploadClick: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(MapLayersEditorActions);
