import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import BusComponent from '../BusComponent';
import { getMetadataObjectsFromVariableSelectionItem } from '../../helpers/Util';
import VariableValueType from '../../enums/VariableValueType';
import VisualizationTypes from '../../enums/VisualizationType';

class VisualizationPercentagesControl extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            percentButtonEnabled: undefined,
        };

        this.bindGluBusEvents({
            CURRENT_METADATA: this.onCurrentMetadataRetrieved,
        });
    }

    componentWillMount() {
        this.emit('CURRENT_METADATA_REQUEST', { source: this });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    onVariableTypeChange = () => {
        const { id, dataTheme } = this.props.mapInstance;
        const variableType = dataTheme.isVisualizationNumber
            ? VariableValueType.PERCENT
            : VariableValueType.NUMBER;

        this.emit('MAP_BUBBLE_VARIABLE_TYPE_CHANGE', {
            mapInstanceId: id,
            variableType,
        });
    };

    onCurrentMetadataRetrieved = (metadata, target) => {
        if (target === this) {
            const { dataTheme } = this.props.mapInstance;

            const currentMetadataObject =
                getMetadataObjectsFromVariableSelectionItem(
                    dataTheme.variableSelection.items[0],
                    metadata,
                );

            this.setState({
                percentButtonEnabled:
                    currentMetadataObject.variable.showPercentageControl,
            });
        }
    };

    render() {
        // This control is disabled for COT visualizations,
        // if the visualization type is DOT DENSITY,
        // for multi variable visualizations,
        // or when percent button is not enabled
        const { dataTheme } = this.props.mapInstance;
        const controlDisabled =
            dataTheme.isChangeOverTimeApplied ||
            dataTheme.variableSelection.isMultiVariable ||
            dataTheme.visualizationType === VisualizationTypes.DOT_DENSITY ||
            !this.state.percentButtonEnabled;
        if (controlDisabled) return null;

        return (
            <button className="btn-link" onClick={this.onVariableTypeChange}>
                {this.props.intl.formatMessage({
                    id: dataTheme.isVisualizationNumber
                        ? 'showHide.show.percentages'
                        : 'showHide.show.numbers',
                })}
            </button>
        );
    }
}

VisualizationPercentagesControl.propTypes = {
    mapInstance: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(VisualizationPercentagesControl);
