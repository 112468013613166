/* eslint-disable */
import { isNumberFinite } from './Util';
const unconstrainedTangent = { x: 0, y: 0 };

function createPointVector(len) {
    const vec = [];
    for (let i = 0; i < len; i++) {
        vec.push({ x: 0, y: 0 });
    }

    return vec;
}

function distance(a, b) {
    const dx = a.x - b.x;
    const dy = a.y - b.y;
    return (Math.sqrt((dx * dx) + (dy * dy)));
}

function cubicBezier(curves, anchor1, anchor2, control1, control2) {
    const curve = [];
    curve.push(anchor1, control1, control2, anchor2);
    curves.push(curve);
}

function negate(v) {
    return { x: -v.x, y: -v.y };
}

function rot90(p) {
    return { x: -p.y, y: p.x };
}

function isZero(v) {
    return v.x === 0 && v.y === 0;
}

function divide(_pt, s) {
    return { x: _pt.x / s, y: _pt.y / s };
}

function scale(v, s) {
    return { x: v.x * s, y: v.y * s };
}

function add(a, b) {
    return { x: a.x + b.x, y: a.y + b.y };
}

function subtract(a, b) {
    return { x: a.x - b.x, y: a.y - b.y };
}

function createNumberVector(len) {
    const c = [];
    for (let j = 0; j < len; j++) {
        c.push(0.0);
    }
    return c;
}

function b0(u) {
    const tmp = 1.0 - u;
    return (tmp * tmp * tmp);
}


function b1(u) {
    const tmp = 1.0 - u;
    return (3 * u * (tmp * tmp));
}


function b2(u) {
    const tmp = 1.0 - u;
    return (3 * u * u * tmp);
}

function b3(u) {
    return (u * u * u);
}

function isFinite(n) {
    return !isNaN(n) && isNumberFinite(n);
}


function v2SquaredLength(a) {
    return (a.x * a.x) + (a.y * a.y);
}

function v2Length(a) {
    return Math.sqrt(v2SquaredLength(a));
}

function normalize(v) {
    // var newV:Vector2 = new Vector2(v.x,v.y);
    const len = v2Length(v);
    if (len !== 0.0) {
        v.x /= len;
        v.y /= len;
    }
    return (v);
}

function unitVector(a) {
    const ret = JSON.parse(JSON.stringify(a));
    normalize(ret);
    return ret;
}

function dot(a, b) {
    return ((a.x * b.x) + (a.y * b.y));
}

function lensq(p) {
    return dot(p, p);
}

function darrayLeftTangent(d) {
    return unitVector(subtract(d[1], d[0]));
}

export function createBezierResult(maxNumberOfBeziers = 10, pointsPerBezier = 4) {
    const beziers = [];

    for (let i = 0; i < maxNumberOfBeziers * pointsPerBezier; i++) {
        beziers.push({ x: 0, y: 0 });
    }
    return beziers;
}

function darrayCenterTangent(d, center) {
    let ret;
    if (d[center + 1] === d[center - 1]) {
        /* Rotate 90 degrees in an arbitrary direction. */
        let diff = subtract(d[center], d[center - 1]);
        ret = rot90(diff);
    } else {
        ret = subtract(d[center - 1], d[center + 1]);
    }
    normalize(ret); // ret.normalize();
    return ret;
}


function bezierPt(degree, V, t) {
    /** Pascal's triangle. */

    let pascal = [];

    for (let j = 0; j < 4; j++) {
        let values = [];
        for (let k = 0; k < 4; k++) {
            values.push(0.0);
        }
        pascal.push(values);
    }

    pascal[0][0] = 1;

    pascal[1][0] = 1;
    pascal[1][1] = 1;

    pascal[2][0] = 1;
    pascal[2][1] = 2;
    pascal[2][2] = 1;

    pascal[3][0] = 1;
    pascal[3][1] = 3;
    pascal[3][2] = 3;
    pascal[3][3] = 1;

    const s = 1.0 - t;

    /* Calculate powers of t and s. */
    let spow = createNumberVector(4);
    let tpow = createNumberVector(4);
    spow[0] = 1.0;
    spow[1] = s;
    tpow[0] = 1.0;
    tpow[1] = t;
    for (let i = 1; i < degree; ++i) {
        spow[i + 1] = spow[i] * s;
        tpow[i + 1] = tpow[i] * t;
    }

    let ret = scale(V[0], spow[degree]);// * V[0];
    for (let i = 1; i <= degree; ++i) {
        ret = add(ret, scale(V[i], pascal[degree][i] * spow[degree - i] * tpow[i])); // pascal[degree][i] * spow[degree - i] * tpow[i] * V[i];
    }
    return ret;
}

function newtonRaphsonRootFind(q, p, u) {
    /* Generate control vertices for Q'. */
    let q1 = createPointVector(3);
    for (let i = 0; i < 3; i++) {
        q1[i] = scale(subtract(q[i + 1], q[i]), 3.0); // 3.0 * ( Q[i+1] - Q[i] );
    }

    /* Generate control vertices for Q''. */
    let q2 = createPointVector(2);
    for (let i = 0; i < 2; i++) {
        q2[i] = scale(subtract(q1[i + 1], q1[i]), 2.0);// 2.0 * ( Q1[i+1] - Q1[i] );
    }

    /* Compute Q(u), Q'(u) and Q''(u). */
    let qU = bezierPt(3, q, u);
    let q1u = bezierPt(2, q1, u);
    let q2u = bezierPt(1, q2, u);

    /* Compute f(u)/f'(u), where f is the derivative wrt u of distsq(u) = 0.5 * the square of the
     distance from P to Q(u).  Here we're using Newton-Raphson to find a stationary point in the
     distsq(u), hopefully corresponding to a local minimum in distsq (and hence a local minimum
     distance from P to Q(u)). */
    let diff = subtract(qU, p);
    let numerator = dot(diff, q1u);
    let denominator = dot(q1u, q1u) + dot(diff, q2u);

    let improvedU;
    if (denominator > 0) {
        /* One iteration of Newton-Raphson:
         improved_u = u - f(u)/f'(u) */
        improvedU = u - ( numerator / denominator );
    } else {
        /* Using Newton-Raphson would move in the wrong direction (towards a local maximum rather
         than local minimum), so we move an arbitrary amount in the right direction. */
        if (numerator > 0.0) {
            improvedU = u * 0.98 - 0.01;
        } else if (numerator < 0.0) {
            /* Deliberately asymmetrical, to reduce the chance of cycling. */
            improvedU = 0.031 + u * 0.98;
        } else {
            improvedU = u;
        }
    }

    if (!isFinite(improvedU)) {
        improvedU = u;
    } else if (improvedU < 0.0) {
        improvedU = 0.0;
    } else if (improvedU > 1.0) {
        improvedU = 1.0;
    }

    /* Ensure that improved_u isn't actually worse. */
    {
        let diffLensq = lensq(diff);
        for (let proportion = 0.125; ; proportion += 0.125) {
            if (lensq(subtract(bezierPt(3, q, improvedU), p)) > diffLensq) {
                if (proportion > 1.0) {
                    // g_warning("found proportion %g", proportion);
                    //						trace("found proportion %g");
                    improvedU = u;
                    break;
                }
                improvedU = ( ( 1 - proportion ) * improvedU + proportion * u);
            } else {
                break;
            }
        }
    }
    return improvedU;
}


function darrayLeftTangent3(d, len, toleranceSq) {
    let i = 1;
    while (true) {
        const pi = JSON.parse(JSON.stringify(d[i]));
        const t = subtract(pi, d[0]); // problem ???
        const distsq = dot(t, t);
        if (toleranceSq < distsq) {
            return unitVector(t);
        }
        ++i;
        if (i === len) {
            return ( distsq === 0 ?
                darrayLeftTangent(d, len) :
                unitVector(t) );
        }
    }

    return null;
}

function darrayRightTangent3(d, len, toleranceSq) {
    let last = len - 1;
    let i = last - 1;
    while (true) {
        let pi = JSON.parse(JSON.stringify(d[i]));
        let t = JSON.parse(JSON.stringify(subtract(pi, (d[last]))));
        let distsq = dot(t, t);
        if (toleranceSq < distsq) {
            return unitVector(t);
        }
        if (i === 0) {
            return ( distsq === 0 ?
                darrayRightTangent3(d, len) :
                unitVector(t) );
        }
        i--;
    }

    return null;
}

function reparameterize(d, len, u, bezCurve) {
    const last = len - 1;
    for (let i = 1; i < last; i++) {
        u[i] = newtonRaphsonRootFind(bezCurve, d[i], u[i]);
    }
}

function estimateBi(bezier, ei, data, u, len) {
    if (!(ei >= 1 && ei <= 2)) return;
    const oi = 3 - ei;
    const num = createNumberVector(2);
    let den = 0.0;
    for (let i = 0; i < len; ++i) {
        const ui = u[i];
        const b = createNumberVector(4);
        b[0] = b0(ui);
        b[1] = b1(ui);
        b[2] = b2(ui);
        b[3] = b3(ui);


        for (let d = 0; d < 2; ++d) {
            let prop = 'x';
            if (d === 1) prop = 'y';
            num[d] += b[ei] * (b[0] * bezier[0][prop] +
                b[oi] * bezier[oi][prop] +
                b[3] * bezier[3][prop] + (-data[i][prop]));
        }
        den -= b[ei] * b[ei];
    }

    if (den !== 0.0) {
        for (let d = 0; d < 2; ++d) {
            let prop = 'x';
            if (d === 1) prop = 'y';
            bezier[ei][prop] = num[d] / den;
        }
        //			trace('NOT tricky');
    } else {
//				trace('tricky');
        bezier[ei] = divide(add(scale(bezier[0], oi), scale(bezier[3], ei)), 3.0); // ( oi * bezier[0] + ei * bezier[3] ) / 3.;
    }
}

function estimateLengths(bezier, data, uPrime, len, tHat1, tHat2) {
    let c = [];
    for (let j = 0; j < 2; j++) {
        c[j] = [];
        for (let jj = 0; jj < 2; jj++) {
            c[j][jj] = 0;
        }
    }

    const x = createNumberVector(2);

    /* Create the C and X matrices. */
    c[0][0] = 0.0;
    c[0][1] = 0.0;
    c[1][0] = 0.0;
    c[1][1] = 0.0;
    x[0] = 0.0;
    x[1] = 0.0;

    /* First and last control points of the Bezier curve are positioned exactly at the first and
     last data points. */
    bezier[0] = data[0];
    bezier[3] = data[len - 1];

    for (let i = 0; i < len; i++) {
        /* Bezier control point coefficients. */
        const b0v = b0(uPrime[i]);
        const b1v = b1(uPrime[i]);
        const b2v = b2(uPrime[i]);
        const b3v = b3(uPrime[i]);

        /* rhs for eqn */
        const a1 = scale(tHat1, b1v); // b1 * tHat1;
        const a2 = scale(tHat2, b2v); // b2 * tHat2;

        c[0][0] += dot(a1, a1);
        c[0][1] += dot(a1, a2);
        c[1][0] = c[0][1];
        c[1][1] += dot(a2, a2);

        /* Additional offset to the data point from the predicted point if we were to set bezier[1]
         to bezier[0] and bezier[2] to bezier[3]. */
//				const shortfall :Point
//				= ( data[i]
//					- ( ( b0 + b1 ) * bezier[0] )
//					- ( ( b2 + b3 ) * bezier[3] ) );

        const shortfall = subtract(subtract(data[i], scale(bezier[0], b0v + b1v)), scale(bezier[3], b2v + b3v));


        x[0] += dot(a1, shortfall);
        x[1] += dot(a2, shortfall);
    }

    /* We've constructed a pair of equations in the form of a matrix product C * alpha = X.
     Now solve for alpha. */
    let alphaL, alphaR;

    /* Compute the determinants of C and X. */
    const detC0C1 = c[0][0] * c[1][1] - c[1][0] * c[0][1];
    if (detC0C1 !== 0) {
        /* Apparently Kramer's rule. */
        const detC0X = c[0][0] * x[1] - c[0][1] * x[0];
        const detXC1 = x[0] * c[1][1] - x[1] * c[0][1];
        alphaL = detXC1 / detC0C1;
        alphaR = detC0X / detC0C1;
    } else {
        /* The matrix is under-determined.  Try requiring alpha_l == alpha_r.
         *
         * One way of implementing the constraint alpha_l == alpha_r is to treat them as the same
         * constiable in the equations.  We can do this by adding the columns of C to form a single
         * column, to be multiplied by alpha to give the column vector X.
         *
         * We try each row in turn.
         */
        const c0 = c[0][0] + c[0][1];
        if (c0 !== 0) {
            alphaL = alphaR = x[0] / c0;
        } else {
            const c1 = c[1][0] + c[1][1];
            if (c1 !== 0) {
                alphaL = alphaR = x[1] / c1;
            } else {
                /* Let the below code handle  */
                alphaL = alphaR = 0.0;
            }
        }
    }

    /* If alpha negative, use the Wu/Barsky heuristic (see text).  (If alpha is 0, you get
     coincident control points that lead to divide by zero in any subsequent
     NewtonRaphsonRootFind() call.) */
    if (alphaL < 1.0e-6 ||
        alphaR < 1.0e-6) {
        //		trace('alpha_r = alpha_l');
        alphaL = alphaR = distance(data[0], data[len - 1]) / 3.0;
    }

    /* Control points 1 and 2 are positioned an alpha distance out on the tangent vectors, left and
     right, respectively.
     bezier[1] = alpha_l * tHat1 + bezier[0];
     bezier[2] = alpha_r * tHat2 + bezier[3];
     */
    bezier[1] = add(scale(tHat1, alphaL), bezier[0]);
    bezier[2] = add(scale(tHat2, alphaR), bezier[3]);
}

function generateBezier(bezier, data, u, len, tHat1, tHat2, toleranceSq) {
    const est1 = isZero(tHat1);
    const est2 = isZero(tHat2);
    let estHat1;

    if (est1) {
        estHat1 = JSON.parse(JSON.stringify((darrayLeftTangent3(data, len, toleranceSq))));
    } else {
        estHat1 = JSON.parse(JSON.stringify(tHat1));
    }

    let estThat2;

    if (est2) {
        estThat2 = JSON.parse(JSON.stringify((darrayRightTangent3(data, len, toleranceSq))));
    } else {
        estThat2 = JSON.parse(JSON.stringify(tHat2));
    }

    estimateLengths(bezier, data, u, len, estHat1, estThat2);
    /* We find that darray_right_tangent tends to produce better results
     for our current freehand tool than full estimation. */
    if (est1) {
        estimateBi(bezier, 1, data, u, len);
        if (bezier[1] !== bezier[0]) {
            estHat1 = unitVector(subtract(bezier[1], bezier[0]));
        }
        estimateLengths(bezier, data, u, len, estHat1, estThat2);
    }
}


function chordLengthParameterize(d, u, len) {
    if (!( len >= 2 )) return;

    /* First let u[i] equal the distance travelled along the path from d[0] to d[i]. */
    u[0] = 0.0;
    for (let i = 1; i < len; i++) {
        let dist = distance(d[i], d[i - 1]);
        u[i] = u[i - 1] + dist;
    }

    /* Then scale to [0.0 .. 1.0]. */
    let totLen = u[len - 1];
    if (!( totLen !== 0 )) return;
    if (isFinite(totLen)) {
        for (let i = 1; i < len; ++i) {
            u[i] /= totLen;
        }
    } else {
        /* We could do better, but this probably never happens anyway. */
        for (let i = 1; i < len; ++i) {
            u[i] = i / ((Number)(len - 1));
        }
    }

    if (u[len - 1] !== 1) {
        u[len - 1] = 1;
    }
}

function computeHook(a, b, u, bezCurve, tolerance) {
    let p = bezierPt(3, bezCurve, u);
    let dist = distance((scale(add(a, b), 0.5)), p);
    if (dist < tolerance) {
        return 0;
    }
    let allowed = distance(a, b) + tolerance;
    return dist / allowed;
    /**
     * effic: Hooks are very rare.  We could start by comparing
     * distsq, only resorting to the more expensive L2 in cases of
     * uncertainty.
     */
}

function computeMaxErrorRatio(d, u, len, bezCurve, tolerance, splitPoint) {
    let last = len - 1;
    /* I.e. assert that the error for the first & last points is zero.
     * Otherwise we should include those points in the below loop.
     * The assertion is also necessary to ensure 0 < splitPoint < last.
     */

    let maxDistsq = 0.0;
    /* Maximum error */
    let maxHookRatio = 0.0;
    let snapEnd = 0;
    let prev = bezCurve[0];
    for (let i = 1; i <= last; i++) {
        let curr = bezierPt(3, bezCurve, u[i]);
        let distsq = lensq(subtract(curr, d[i]));
        if (distsq > maxDistsq) {
            maxDistsq = distsq;
            splitPoint[0] = i; // *splitPoint = i;
        }
        let hookRatio = computeHook(prev, curr, 0.5 * (u[i - 1] + u[i]), bezCurve, tolerance);
        if (maxHookRatio < hookRatio) {
            maxHookRatio = hookRatio;
            snapEnd = i;
        }
        prev = curr;
    }

    let distRatio = Math.sqrt(maxDistsq) / tolerance;
    let ret;
    if (maxHookRatio <= distRatio) {
        ret = distRatio;
    } else {
        ret = -maxHookRatio;
        splitPoint[0] = snapEnd - 1; // *splitPoint = snap_end - 1;
    }
    return ret;
}

function bezierFitCubicFull(bezier, splitPoints, data, len, tHat1, tHat2, error, maxBeziers, targetCurves) {
    const maxIterations = 4;
    /* std::max times to try iterating */

    if (!(bezier !== null) || !(data !== null) || !(len > 0) || !(maxBeziers >= 1) || !(error >= 0.0)) return -1;

    if (len < 2) return 0;

    if (len === 2) {
        /* We have 2 points, which can be fitted trivially. */
        bezier[0] = data[0];
        bezier[3] = data[len - 1];
        const dist = distance(bezier[0], bezier[3]) / 3.0;
        if (isNaN(dist)) {
            /* Numerical problem, fall back to straight line segment. */
//					trace('first control points - line segment');
            bezier[1] = bezier[0];
            bezier[2] = bezier[3];
        } else {
            bezier[1] = ( isZero(tHat1) ?
                divide(( add(scale(bezier[0], 2), bezier[3]) ), 3.0) :
                add(bezier[0], scale(tHat1, dist)) );

            bezier[2] = ( isZero(tHat2) ?
                divide(( add(bezier[0], scale(bezier[3], 2)) ), 3.0) :
                add(bezier[3], scale(tHat2, dist)) );
        }
        cubicBezier(targetCurves, bezier[0], bezier[3], bezier[1], bezier[2]);

        return 1;
    }

    /*  Parameterize points, and attempt to fit curve */
    const splitPoint = [0];
    /* Point to split point set at. */
    let isCorner;
    {
        let u = createNumberVector(len);// new double[len];
        chordLengthParameterize(data, u, len);
        if (u[len - 1] === 0.0) {
            /* Zero-length path: every point in data[] is the same.
             *
             * (Clients aren't allowed to pass such data; handling the case is defensive
             * programming.)
             */
            return 0;
        }

        generateBezier(bezier, data, u, len, tHat1, tHat2, error);
        reparameterize(data, len, u, bezier);

        /* Find max deviation of points to fitted curve. */
        const tolerance = Math.sqrt(error + 1e-9);
        let maxErrorRatio = computeMaxErrorRatio(data, u, len, bezier, tolerance, splitPoint); // split point ref ????

        if (Math.abs(maxErrorRatio) <= 1.0) {
            cubicBezier(targetCurves, bezier[0], bezier[3], bezier[1], bezier[2]);
            return 1;
        }

        /* If error not too large, then try some reparameterization and iteration. */
        if (maxErrorRatio >= 0.0 && maxErrorRatio <= 3.0) {
            for (let i = 0; i < maxIterations; i++) {
                generateBezier(bezier, data, u, len, tHat1, tHat2, error);
                reparameterize(data, len, u, bezier);
                maxErrorRatio = computeMaxErrorRatio(data, u, len, bezier, tolerance, splitPoint); // split point ref ????
                if (Math.abs(maxErrorRatio) <= 1.0) {
                    cubicBezier(targetCurves, bezier[0], bezier[3], bezier[1], bezier[2]);
                    return 1;
                }
            }
        }
        // delete[] u;
        isCorner = (maxErrorRatio < 0);
    }

    if (isCorner) {
        if (splitPoint[0] === 0) {
            if (isZero(tHat1)) {
                /* Got spike even with unconstrained initial tangent. */
                ++splitPoint[0];
            } else {
                return bezierFitCubicFull(bezier, splitPoints, data, len, unconstrainedTangent, tHat2,
                    error, maxBeziers, targetCurves);
            }
        } else if (splitPoint[0] === (len - 1)) {
            if (isZero(tHat2)) {
                /* Got spike even with unconstrained final tangent. */
                --splitPoint[0];
            } else {
                return bezierFitCubicFull(bezier, splitPoints, data, len, tHat1, unconstrainedTangent,
                    error, maxBeziers, targetCurves);
            }
        }
    }

    if (maxBeziers > 1) {
        const recMaxBeziers1 = maxBeziers - 1;

        let recTHat2, recTHat1;
        if (isCorner) {
            if (!(splitPoint[0] > 0 && splitPoint[0] < (len - 1))) return -1;
            recTHat1 = recTHat2 = unconstrainedTangent;
        } else {
            recTHat2 = darrayCenterTangent(data, splitPoint[0], len);
            recTHat1 = negate(recTHat2);
        }
        let nsegs1 = bezierFitCubicFull(bezier, splitPoints, data, splitPoint[0] + 1, tHat1, recTHat2, error, recMaxBeziers1, targetCurves);
        if (nsegs1 < 0) return -1;
        if (splitPoints !== null) {
            splitPoints[nsegs1 - 1] = splitPoint[0];
        }
        const recMaxBeziers2 = maxBeziers - nsegs1;
        let nsegs2 = bezierFitCubicFull(bezier.slice(nsegs1 * 4), ( splitPoints === null ? null : splitPoints + nsegs1 ),
            data.slice(splitPoint[0]), len - splitPoint[0],
            recTHat1, tHat2, error, recMaxBeziers2, targetCurves);
        if (nsegs2 < 0) {
            return -1;
        }
        return nsegs1 + nsegs2;
    }
    return -1;
}

function copyWithoutNansOrAdjacentDuplicates(src, srcLen, dest) {
    let si = 0;
    while (true) {
        if (si === srcLen) {
            return 0;
        }
        if (!isNaN(src[si].x) && !isNaN(src[si].y)) {
            dest[0] = JSON.parse(JSON.stringify(src[si]));
            ++si;
            break;
        }
        si++;
    }
    let di = 0;
    for (; si < srcLen; ++si) {
        let srcPt = JSON.parse(JSON.stringify(src[si]));
        if (srcPt !== dest[di] && !isNaN(srcPt.x) && !isNaN(srcPt.y)) {
            dest[++di] = srcPt;
        }
    }
    const destLen = di + 1;
    return destLen;
}

function bezierFitCubicR(bezier, data, len, error, maxBeziers) {
    const retObj = {};

    if (bezier === null ||
        data === null ||
        len <= 0 || maxBeziers >= (1 << (31 - 2 - 1 - 3))) {
        retObj.numberOfCurves = -1;
        return retObj;
    }
    const uniquedData = createPointVector(len); // new Point[len];
    const uniquedLen = copyWithoutNansOrAdjacentDuplicates(data, len, uniquedData);

    if (uniquedLen < 2) {
        return retObj;
    }
    const targetCurves = [];

    /* Call fit-cubic function with recursion. */
    const ret = bezierFitCubicFull(bezier, null, uniquedData, uniquedLen, unconstrainedTangent, unconstrainedTangent, error, maxBeziers, targetCurves);

    retObj.numberOfCurves = ret;
    retObj.curves = targetCurves;
    return retObj;
}

export function bezierFitCubic(bezier, data, len, error) {
    return bezierFitCubicR(bezier, data, len, error, 10);
}

function perpendicularDistance(point1, point2, pointX) {
    // Area = |(1/2)(x1y2 + x2y3 + x3y1 - x2y1 - x3y2 - x1y3)|   *Area of triangle
    // Base = v((x1-x2)²+(x1-x2)²)                               *Base of Triangle*
    // Area = .5*Base*H                                          *Solve for height
    // Height = Area/.5/Base

    const areaV = Math.abs(0.5 * (point1.x * point2.y + point2.x *
        pointX.y + pointX.x * point1.y - point2.x * point1.y - pointX.x *
        point2.y - point1.x * pointX.y));
    const bottom = Math.sqrt(Math.pow(point1.x - point2.x, 2) +
        Math.pow(point1.y - point2.y, 2));
    const height = areaV / bottom * 2;

    return height;
}

// <summary>
// Douglases the peucker reduction.
// </summary>
// <param name="points">The points.</param>
// <param name="firstPoint">The first point.</param>
// <param name="lastPoint">The last point.</param>
// <param name="tolerance">The tolerance.</param>
// <param name="pointIndexsToKeep">The point index to keep.</param>
function douglasPeuckerReductionX(points, firstPoint, lastPoint, tolerance, pointIndexsToKeep) {
    let maxDistance = 0;
    let indexFarthest = 0;
    for (let index = firstPoint; index < lastPoint; index++) {
        let d = perpendicularDistance(points[firstPoint], points[lastPoint], points[index]);
        if (d > maxDistance) {
            maxDistance = d;
            indexFarthest = index;
        }
    }

    if (maxDistance > tolerance && indexFarthest !== 0) {
        // Add the largest point that exceeds the tolerance
        pointIndexsToKeep.push(indexFarthest);

        douglasPeuckerReductionX(points, firstPoint, indexFarthest, tolerance, pointIndexsToKeep);
        douglasPeuckerReductionX(points, indexFarthest, lastPoint, tolerance, pointIndexsToKeep);
    }
}


// <summary>
// Uses the Douglas Peucker algorithm to reduce the number of points.
// </summary>
// <param name="Points">The points.</param>
// <param name="Tolerance">The tolerance.</param>
// <returns></returns>
export function douglasPeuckerReduction(points, tolerance) {
    if (points === null || points.length < 3) return points;
    const firstPoint = 0;
    let lastPoint = points.length - 1;
    const pointIndexsToKeep = [];

    // Add the first and last index to the keepers
    pointIndexsToKeep.push(firstPoint);
    pointIndexsToKeep.push(lastPoint);

    // The first and the last point cannot be the same
    while (points[firstPoint] === (points[lastPoint])) {
        lastPoint--;
    }

    douglasPeuckerReductionX(points, firstPoint, lastPoint, tolerance, pointIndexsToKeep); // ref

    const returnPoints = [];
    pointIndexsToKeep.sort((a, b) => a - b);

    pointIndexsToKeep.forEach(index => {
        returnPoints.push(points[index]);
    });

    return returnPoints;
}

function compare(a, b) {
    return a[1].area - b[1].area;
}

function area(t) {
    return Math.abs((t[0].x - t[2].x) * (t[1].y - t[0].y) - (t[0].x - t[1].x) * (t[2].y - t[0].y));
}

function minHeap() {
    var heap = {},
        array = [];

    heap.push = function() {
        for (var i = 0, n = arguments.length; i < n; ++i) {
            var object = arguments[i];
            up(object.index = array.push(object) - 1);
        }
        return array.length;
    };

    heap.pop = function() {
        var removed = array[0],
            object = array.pop();
        if (array.length) {
            array[object.index = 0] = object;
            down(0);
        }
        return removed;
    };

    heap.remove = function(removed) {
        var i = removed.index,
            object = array.pop();
        if (i !== array.length) {
            array[object.index = i] = object;
            (compare(object, removed) < 0 ? up : down)(i);
        }
        return i;
    };

    function up(i) {
        var object = array[i];
        while (i > 0) {
            var up = ((i + 1) >> 1) - 1,
                parent = array[up];
            if (compare(object, parent) >= 0) break;
            array[parent.index = i] = parent;
            array[object.index = i = up] = object;
        }
    }

    function down(i) {
        var object = array[i];
        while (true) {
            var right = (i + 1) << 1,
                left = right - 1,
                down = i,
                child = array[down];
            if (left < array.length && compare(array[left], child) < 0) child = array[down = left];
            if (right < array.length && compare(array[right], child) < 0) child = array[down = right];
            if (down === i) break;
            array[child.index = i] = child;
            array[object.index = i = down] = object;
        }
    }

    return heap;
}


export function simplify(points) {
    let heap = minHeap(),
        maxArea = 0,
        triangle;

    let triangles = [];

    for (let i = 1, n = points.length - 1; i < n; ++i) {
        triangle = points.slice(i - 1, i + 2);
        triangle[1].area = area(triangle);
        if (triangle[1].area) {
            triangles.push(triangle);
            heap.push(triangle);
        }
    }

    for (let i = 0, n = triangles.length; i < n; ++i) {
        triangle = triangles[i];
        triangle.previous = triangles[i - 1];
        triangle.next = triangles[i + 1];
    }

    while (triangle = heap.pop()) {
        // If the area of the current point is less than that of the previous point
        // to be eliminated, use the latter’s area instead. This ensures that the
        // current point cannot be eliminated without eliminating previously-
        // eliminated points.
        if (triangle[1].area < maxArea) triangle[1].area = maxArea;
        else maxArea = triangle[1].area;

        if (triangle.previous) {
            triangle.previous.next = triangle.next;
            triangle.previous[2] = triangle[2];
            update(triangle.previous);
        } else {
            triangle[0].area = triangle[1].area;
        }

        if (triangle.next) {
            triangle.next.previous = triangle.previous;
            triangle.next[0] = triangle[0];
            update(triangle.next);
        } else {
            triangle[2].area = triangle[1].area;
        }
    }

    function update(triangle) {
        heap.remove(triangle);
        triangle[1].area = area(triangle);
        heap.push(triangle);
    }

    return points.filter((p, i) => p.area > 0.03 || i === 0 || i === points.length - 1);
}

export function visvalingamWhyattReduction(points) {
    return simplify(points);
}
