import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import MenuItem from 'material-ui/MenuItem';
import DropDownMenu from 'material-ui/DropDownMenu';

import PopoverAnimationUpwards from '../../../dropdown/PopoverAnimationUpwards';
import { DropdownStyle, LabelStyle, IconStyle, UnderlineStyle } from './MapExportFrameControlsStyles';

import MapExportFormat from '../../../../enums/MapExportFormat';

const MapExportFrameFormatControl = props => {
    const { targetFormat, onChangeFormat } = props;

    return (
        <div className="map-export-frame__export-strip__controls__output-format">
            <div>
                {props.intl.formatMessage({ id: 'format' })}
            </div>
            <div>
                <DropDownMenu
                    className="dropdown-menu-trigger"
                    animation={PopoverAnimationUpwards}
                    value={targetFormat}
                    style={DropdownStyle}
                    labelStyle={LabelStyle}
                    iconStyle={IconStyle}
                    underlineStyle={UnderlineStyle}
                    onChange={onChangeFormat}
                >
                    <MenuItem
                        label="image"
                        value={MapExportFormat.PNG}
                        style={{ paddingTop: 10, paddingBottom: 10 }}
                    >
                        <div style={{ lineHeight: '16px', height: 16 }}>
                            {props.intl.formatMessage({ id: 'storyPlayer.pngImage' })}
                        </div>
                        <div
                            style={{
                                fontSize: '14px',
                                lineHeight: '16px',
                                height: 16,
                                margin: '4px 0px 0px',
                                color: 'rgba(0, 0, 0, 0.54)',
                                width: '100%',
                            }}
                        >
                            <FormattedMessage id="storyPlayer.singleMapImageWithOverlaidTitleScalebarAndLegend" />
                        </div>
                    </MenuItem>
                    <MenuItem
                        label="archive"
                        value={MapExportFormat.ZIP}
                        style={{ paddingTop: 10, paddingBottom: 10 }}
                    >
                        <div style={{ lineHeight: '16px', height: 16 }}>
                            {props.intl.formatMessage({ id: 'storyPlayer.zipArchive' })}
                        </div>
                        <div
                            style={{
                                fontSize: '14px',
                                lineHeight: '16px',
                                height: 16,
                                margin: '4px 0px 0px',
                                color: 'rgba(0, 0, 0, 0.54)',
                                width: '100%',
                            }}
                        >
                            <FormattedMessage id="storyPlayer.separateImagesForMapScalebarAndLegend" />
                        </div>
                    </MenuItem>
                </DropDownMenu>
            </div>
        </div>
    );
};

MapExportFrameFormatControl.propTypes = {
    targetFormat: PropTypes.string.isRequired,
    onChangeFormat: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(MapExportFrameFormatControl);
