import React from 'react';
import classNames from 'classnames';

import BusComponent from '../BusComponent';
import ApplicationFrameType from '../../enums/ApplicationFrameType';
import ApplicationMode from '../../enums/ApplicationMode';
import Orientation from '../../enums/Orientation';
import AppConfig from '../../appConfig';
import MapViewer from '../MapViewer';
import HorizontalSwiper from './HorizontalSwiper';
import MapPanelContainer from '../mapPanel/MapPanelContainer';
import MapLogo from '../mapControls/MapLogo';
import SearchBoxPanel from '../searchBox/SearchBoxPanel';
import FilterAndMaskIndicator from '../mapIndicators/FilterAndMaskIndicator';
import VisualizationLegend from '../visualizationLegend/VisualizationLegend';
import MapControls from '../mapControls/MapControls';
import { resizeCanvas } from '../../helpers/Util';
import { HelpTourTargets } from '../../enums/HelpTourDefinitions';
import DataBrowser from '../dataBrowser/DataBrowser';
import CondensedFrameComponents from './CondensedFrameComponents';
import MapInfoBubbleSwitch from '../mapControls/MapInfoBubbleSwitch';

class SwipeMapsFrame extends BusComponent {
    constructor(props, context) {
        super(props, context);

        this.state = {
            swipeAt: 0.5,
            activeMapIndex: 0,
        };

        this.bindGluBusEvents({
            MAP_DISPLAY_CURRENT_LOCATION_REQUEST: this.onDisplayCurrentLocationRequest,
            MAP_CLEAR_CURRENT_LOCATION_REQUEST: this.onClearCurrentLocationRequest,
        });
    }

    componentWillMount() {
        this.emit('APPLICATION_FRAME_TYPE_CHANGED', ApplicationFrameType.SWIPE);
        AppConfig.sentryRecordEvent('entered swipe map frame');

        if (this.props.displayDataBrowser) {
            const leftMap = this.props.frame.mapInstances[0];
            if (this.props.mapInstanceId === leftMap.id) {
                this.handleLeftDataBrowserRequest();
            } else {
                this.handleRightDataBrowserRequest();
            }
        }
    }

    componentDidMount() {
        this.updateSwipeClippingMask();
    }

    componentDidUpdate() {
        this.updateSwipeClippingMask();
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    onDisplayCurrentLocationRequest = e => {
        const [leftMapInstance] = this.props.frame.mapInstances;
        if (e.mapInstanceId === leftMapInstance.id) {
            return;
        }

        const fakeEvent = Object.assign({}, e, { mapInstanceId: leftMapInstance.id });
        this.emit('MAP_DISPLAY_CURRENT_LOCATION_REQUEST', fakeEvent);
    };

    onClearCurrentLocationRequest = e => {
        const [leftMapInstance] = this.props.frame.mapInstances;
        if (e.mapInstanceId === leftMapInstance.id) {
            return;
        }

        const fakeEvent = Object.assign({}, e, { mapInstanceId: leftMapInstance.id });
        this.emit('MAP_CLEAR_CURRENT_LOCATION_REQUEST', fakeEvent);
    };

    handleMapMove = ({ originalEvent, source }) => {
        if (originalEvent.lockMode) return;

        const [leftMapInstance, rightMapInstance] = this.props.frame.mapInstances;

        const lockMode = {
            lockMode: true,
        };

        const location = {
            center: source.dragonflyMap.getCenter(),
            zoom: source.dragonflyMap.getZoom(),
            bearing: source.dragonflyMap.getBearing(),
            pitch: source.dragonflyMap.getPitch(),
        };

        if (source.id === rightMapInstance.id && this.mapLeftRef && this.mapLeftRef.dragonflyMap) {
            this.mapLeftRef.dragonflyMap.jumpTo(location, lockMode);
        } else if (source.id === leftMapInstance.id && this.mapRightRef && this.mapRightRef.dragonflyMap) {
            this.mapRightRef.dragonflyMap.jumpTo(location, lockMode);
        }
    };

    generateThumbnail() {
        if (!this._topMapImageData || !this._bottomMapImageData) return;

        const canvas = document.createElement('canvas');
        canvas.width = Math.max(this._topMapImageData.width, this._bottomMapImageData.width);
        canvas.height = Math.max(this._topMapImageData.height, this._bottomMapImageData.height);

        const ctx = canvas.getContext('2d');
        ctx.putImageData(this._topMapImageData, 0, 0);
        ctx.putImageData(this._bottomMapImageData, 0, 0, 0, 0, this._bottomMapImageData.width / 2, this._bottomMapImageData.height);
        ctx.globalCompositeOperation = 'copy';
        ctx.scale(1, -1);
        ctx.translate(0, -canvas.height);
        ctx.drawImage(canvas, 0, 0);

        const thumbnail = resizeCanvas(canvas).toDataURL();
        const thumbnailLarge = canvas.toDataURL('image/jpeg', 0.7);

        this.emit('UPDATE_CURRENT_FRAME_THUMBNAIL_REQUEST', { thumbnail, thumbnailLarge });
    }

    handleTopMapImageReady = imageData => {
        this._topMapImageData = imageData;
        this.generateThumbnail();
    };

    handleBottomMapImageReady = imageData => {
        this._bottomMapImageData = imageData;
        this.generateThumbnail();
    };

    handleSwipeStart = () => {
        this.props.frame.mapInstances.forEach(m => {
            this.emit('MAP_DISABLE_FEATURE_INTERACTIVITY_REQUEST', { mapInstanceId: m.id });
        });
    };

    handleSwipeStop = () => {
        this.props.frame.mapInstances.forEach(m => {
            this.emit('MAP_ENABLE_FEATURE_INTERACTIVITY_REQUEST', { mapInstanceId: m.id });
        });
    };

    handleSwipeChange = swipeAt => {
        this.setState({ swipeAt });
    };

    handleMapResize = () => {
        this.forceUpdate();
    };

    handleMapSwitch = activeMapIndex => {
        this.setState({ activeMapIndex });
    };

    handleLeftDataBrowserRequest = () => {
        this.setState({ showLeftDataBrowser: true });
    };

    handleRightDataBrowserRequest = () => {
        this.setState({ showRightDataBrowser: true });
    };

    handleLeftDataBrowserClose = () => {
        this.setState({ showLeftDataBrowser: false });
    };

    handleRightDataBrowserClose = () => {
        this.setState({ showRightDataBrowser: false });
    };

    handleSearchVisibilityToggle = searchExpanded => {
        this.setState({ searchExpanded });
    };

    updateSwipeClippingMask() {
        let clipStyle = '';

        if (!this.props.isCondensedLayout) {
            const clipAt = Math.ceil(this.frameRoot.clientWidth * this.state.swipeAt);
            clipStyle = `rect(0, 999em, 999em, ${clipAt}px)`;
        }

        this.clippedMapDomEl.style.clip = clipStyle;
    }

    renderFrameComponents() {
        const {
            frame,
            isUserPro,
            isCondensedLayout,
            children,
        } = this.props;

        const { activeMapIndex, searchExpanded, showLeftDataBrowser, showRightDataBrowser } = this.state;
        const { applicationMode } = this.context;
        const [leftMapInstance, rightMapInstance] = frame.mapInstances;

        if (isCondensedLayout) {
            return (
                <CondensedFrameComponents
                    isUserPro={isUserPro}
                    mapInstances={frame.mapInstances}
                    mapViewer={activeMapIndex === 0 ? this.mapLeftRef : this.mapRightRef}
                    onShowDataBrowser={activeMapIndex === 0 ? this.handleLeftDataBrowserRequest : this.handleRightDataBrowserRequest}
                    activeMapIndex={activeMapIndex}
                    onMapChange={this.handleMapSwitch}
                    applicationMode={applicationMode}
                >
                    {children}
                </CondensedFrameComponents>
            );
        }

        return (
            <div className="frame__components">
                <div className="frame__components-block frame__components-block--top-left">
                    {this.mapLeftRef && !showLeftDataBrowser &&
                        <MapPanelContainer
                            mapInstance={leftMapInstance}
                            mapInstanceIndex={frame.mapInstances.indexOf(leftMapInstance)}
                            isUserPro={isUserPro}
                            onShowDataBrowser={this.handleLeftDataBrowserRequest}
                            mapViewer={this.mapLeftRef}
                            tourId={HelpTourTargets.MAP_PANEL}
                        />
                    }
                    <FilterAndMaskIndicator mapInstance={leftMapInstance} alignLeft />
                </div>
                <div className="frame__components-block frame__components-block--bottom-left">
                    <VisualizationLegend
                        mapInstance={leftMapInstance}
                        orientation={Orientation.LEFT}
                    />
                </div>
                <div className="frame__components-block frame__components-block--top-right">
                    {this.mapRightRef && !showRightDataBrowser &&
                        <MapPanelContainer
                            mapInstance={rightMapInstance}
                            mapInstanceIndex={frame.mapInstances.indexOf(rightMapInstance)}
                            isUserPro={isUserPro}
                            onShowDataBrowser={this.handleRightDataBrowserRequest}
                            mapViewer={this.mapRightRef}
                        />
                    }
                    <div className="flex-it flex-end stretch-width">
                        {!searchExpanded &&
                            <FilterAndMaskIndicator mapInstance={rightMapInstance} />
                        }
                        {ApplicationMode.isViewMode(applicationMode) &&
                            <SearchBoxPanel
                                mapInstanceIds={frame.mapInstances.map(m => m.id)}
                                disableMapSelection
                                onToggleVisibility={this.handleSearchVisibilityToggle}
                            />
                        }
                    </div>
                </div>
                <div className="frame__components-block frame__components-block--bottom-right">
                    <VisualizationLegend
                        mapInstance={rightMapInstance}
                        tourId={HelpTourTargets.MAP_LEGEND}
                        orientation={Orientation.RIGHT}
                    />
                    <div className="flex-it column center">
                        {this.props.children}
                        <MapControls mapInstance={rightMapInstance} />
                    </div>
                    <MapInfoBubbleSwitch mapInstance={rightMapInstance} />
                </div>
                <MapLogo />
            </div>
        );
    }

    render() {
        const { frame, isCondensedLayout } = this.props;
        const { activeMapIndex, showLeftDataBrowser, showRightDataBrowser } = this.state;
        const [leftMapInstance, rightMapInstance] = frame.mapInstances;

        const frameClasses = classNames('frame frame--swipe', {
            'frame--condensed-layout': isCondensedLayout,
        });
        const bottomMapClasses = classNames('frame__map', {
            'frame__map--inactive': isCondensedLayout && activeMapIndex === 1,
        });
        const topMapClasses = classNames('frame__map', {
            'frame__map--inactive': isCondensedLayout && activeMapIndex === 0,
        });

        return (<div className={frameClasses} ref={c => (this.frameRoot = c)}>
            <div className={bottomMapClasses}>
                <MapViewer
                    ref={c => (this.mapLeftRef = c)}
                    mapInstance={leftMapInstance}
                    onImageReady={this.handleBottomMapImageReady}
                    onMove={this.handleMapMove}
                    onResize={this.handleMapResize}
                    annotations
                    leftMap
                />
            </div>
            <div className={topMapClasses} ref={c => (this.clippedMapDomEl = c)}>
                <MapViewer
                    ref={c => (this.mapRightRef = c)}
                    mapInstance={rightMapInstance}
                    onImageReady={this.handleTopMapImageReady}
                    onMove={this.handleMapMove}
                    annotations
                />
            </div>
            {!isCondensedLayout && <div className="frame__map-swiper">
                <HorizontalSwiper
                    root={this.frameRoot}
                    value={this.state.swipeAt}
                    onStart={this.handleSwipeStart}
                    onStop={this.handleSwipeStop}
                    onChange={this.handleSwipeChange}
                />
            </div>}
            {this.renderFrameComponents()}
            <div className="frame__browser frame__browser--left">
                {showLeftDataBrowser && <DataBrowser mapInstance={leftMapInstance} onClose={this.handleLeftDataBrowserClose} />}
            </div>
            <div className="frame__browser frame__browser--right">
                {showRightDataBrowser && <DataBrowser mapInstance={rightMapInstance} onClose={this.handleRightDataBrowserClose} />}
            </div>
        </div>
        );
    }
}

export default SwipeMapsFrame;
