import React from 'react';
import { injectIntl } from 'react-intl';

import BusComponent from '../BusComponent';
import Tooltip from '../Tooltip';

class Share extends BusComponent {
    constructor(props, context) {
        super(props, context);

        this.bindGluBusEvents({
            SHARE_SNAPSHOT_LOAD_SUCCESS: this.onShareSnapshotLoadSuccess,
        });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    onShareSnapshotLoadSuccess(eventMap) {
        this.emit('HIDE_OVERLAY_REQUEST');
        this.emit('SHARE_EMBED_POPUP_REQUEST', eventMap);
    }

    onClick = () => {
        this.emit('DISPLAY_OVERLAY_REQUEST');
        this.emit('SHARE_SNAPSHOT_LOAD_REQUEST');
    }

    render() {
        return (
            <Tooltip
                trigger={['hover']}
                placement="left"
                mouseLeaveDelay={0}
                overlay={<span className="light-text">{this.props.intl.formatMessage({ id: 'share' })}</span>}
            >
                <button onClick={this.onClick} className="map-btn map-btn--single map-floating-element margin-bottom-10">
                    <div className="btn--location rounded clickable flex-it justify-center center">
                        <i className="material-icons">share</i>
                    </div>
                </button>
            </Tooltip>
        );
    }
}

export default injectIntl(Share);
