import React from 'react';
import PropTypes from 'prop-types';
import BusComponent from './BusComponent';
import RcTooltip from 'rc-tooltip';


class Tooltip extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            container: document.querySelector(`#${context.container}`),
        };
    }

    render() {
        return (<RcTooltip
            overlayClassName={this.props.className || ''}
            trigger={this.props.trigger || ['hover']}
            mouseEnterDelay={this.props.mouseEnterDelay || 0}
            mouseLeaveDelay={this.props.mouseLeaveDelay !== undefined ? this.props.mouseLeaveDelay : 0.1}
            overlayStyle={this.props.overlayStyle || {}}
            prefixCls={this.props.prefixCls || 'tooltip'}
            onVisibleChange={this.props.onVisibleChange}
            afterVisibleChange={this.props.afterVisibleChange}
            defaultVisible={this.props.defaultVisible}
            placement={this.props.placement || 'bottomLeft'}
            onPopupAlign={this.props.onPopupAlign}
            overlay={this.props.overlay}
            destroyTooltipOnHide={this.props.destroyTooltipOnHide}
            getPopupContainer={() => this.state.container}
        >
            {this.props.children}
        </RcTooltip>);
    }
}

Tooltip.propTypes = {
    placement: PropTypes.string,
    mouseEnterDelay: PropTypes.number,
    mouseLeaveDelay: PropTypes.number,
};

Tooltip.defaultProps = {
    placement: 'bottom',
    mouseEnterDelay: 0.5,
    mouseLeaveDelay: 0,
};

export default Tooltip;
