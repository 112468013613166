// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import BusComponent from '../../BusComponent';
import Variables from './Variables';
import VariablesIndent from './VariablesIndent';
import Loader from '../../Loader';

import TableHeader from './TableHeader';

/**
 * @typedef Props
 * @property {import('../../../').SearchResultTable} table
 * @property {number} selectedYear
 * @property {import('../../../objects/MapInstance').default} mapInstance
 * @property {import('../../../objects/VariableSelectionItem').default[]} currentVariableSelectionItems
 * @property {boolean} disabled
 * @property {string} query
 *
 * @typedef State
 * @property {import('../../../').TableComparableSurvey} selectedSurvey
 * @property {boolean} expanded
 * @property {boolean} loading describes if loading all table variables is in progress
 * @property {import('../../../').SearchResultTableFullVariables} fullVariables
 *
 * @extends {BusComponent<Props, State>}
 */
class Table extends BusComponent {
    /** @param {Props} props */
    constructor(props, context) {
        super(props, context);

        const selectedSurvey =
            props.table.comparableSurveys.find(
                s => s.surveyYear === props.selectedYear,
            ) || props.table.comparableSurveys[0];
        /** @type {State} */
        this.state = {
            selectedSurvey,
            expanded: false,
            loading: false,
            fullVariables: {},
        };
    }

    onSurveyChange = selectedSurveyName => {
        const selectedSurvey = this.props.table.comparableSurveys.find(
            s => s.surveyName === selectedSurveyName,
        );
        this.setState({ selectedSurvey, loading: this.state.expanded }, () => {
            if (this.state.expanded) {
                this.onFetchAllVariables();
            }
        });
    };

    onToggle = () => {
        if (this.props.disabled) {
            return;
        }

        this.setState(
            state => ({
                expanded: !state.expanded,
                loading: !state.expanded,
            }),
            () => {
                if (this.state.expanded) {
                    this.onFetchAllVariables();
                }
            },
        );
    };

    onFetchAllVariables = () => {
        const { selectedSurvey } = this.state;

        if (this.state.fullVariables[selectedSurvey.surveyName]) {
            // already fetched
            this.setState({ loading: false });
            return;
        }

        // Fetch all variables for selected table
        this.bus.once('DATASET_LOAD_SUCCESS', () => {
            this.bus.once('TABLE_LOAD_SUCCESS', response => {
                /** @type {import('../../../objects/MetaTable').default} */
                const table = response.table;
                const variables = table.variables;
                this.setState({
                    loading: false,
                    fullVariables: {
                        ...this.state.fullVariables,
                        [selectedSurvey.surveyName]: variables,
                    },
                });
            });

            this.emit('TABLE_LOAD_REQUEST', {
                surveyName: selectedSurvey.surveyName,
                datasetName: selectedSurvey.datasetAbbreviation,
                tableName: this.props.table.tableName,
                mapInstanceId: this.props.mapInstance.id,
                source: this,
            });
        });

        this.emit('DATASET_LOAD_REQUEST', {
            surveyName: selectedSurvey.surveyName,
            datasetName: selectedSurvey.datasetAbbreviation,
            mapInstanceId: this.props.mapInstance.id,
            source: this,
        });
    };

    render() {
        const wrapperClass = classNames('data-browser-search-result-table', {
            'data-browser-search-result-table--disabled': this.props.disabled,
        });
        return (
            <div className={wrapperClass}>
                <TableHeader
                    disabled={this.props.disabled}
                    table={this.props.table}
                    selectedSurvey={this.state.selectedSurvey}
                    expanded={this.state.expanded}
                    onToggle={this.onToggle}
                    onSurveyChange={this.onSurveyChange}
                />
                {this.state.loading && <Loader width="80px" height="80px" />}
                {this.state.expanded && !this.state.loading && (
                    <VariablesIndent
                        variables={Object.keys(
                            this.state.fullVariables[
                                this.state.selectedSurvey.surveyName
                            ],
                        ).map(
                            variableName =>
                                this.state.fullVariables[
                                    this.state.selectedSurvey.surveyName
                                ][variableName],
                        )}
                        mapInstance={this.props.mapInstance}
                        currentVariableSelectionItems={
                            this.props.currentVariableSelectionItems
                        }
                        query={this.props.query}
                    />
                )}
                {!this.state.expanded && (
                    <Variables
                        variables={this.props.table.variables}
                        selectedSurvey={this.state.selectedSurvey}
                        mapInstanceId={this.props.mapInstance.id}
                        currentVariableSelectionItems={
                            this.props.currentVariableSelectionItems
                        }
                        disabled={this.props.disabled}
                        query={this.props.query}
                    />
                )}
            </div>
        );
    }
}

Table.propTypes = {
    table: PropTypes.object.isRequired,
    mapInstance: PropTypes.object.isRequired,
    currentVariableSelectionItems: PropTypes.array.isRequired,
    selectedYear: PropTypes.number,
    query: PropTypes.string.isRequired,
};

Table.defaultProps = {
    selectedYear: undefined,
};

export default Table;
