import React from 'react';
import { injectIntl } from 'react-intl';
import BusComponent from '../BusComponent';
import MaskDataSelection from './MaskDataSelection';
import MaskSummaryLevelsSelection from './MaskSummaryLevelsSelection';
import googleTagManagerEvents from '../../helpers/GoogleTagManagerEventsWrapper';
import { doneMaskButtonEvents } from '../../enums/GoogleTagManagerEvents';
import { getMetadataObjectsFromVariableSelection } from '../../helpers/Util';
import Panel from '../panel/Panel';

const DoneButton = googleTagManagerEvents('button', doneMaskButtonEvents);

class MapMaskEditor extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            availableSummaryLevels: [],
            activeSummaryLevel: undefined,
            maskDirty: Object.keys(props.mapInstance.dataGeoFilters).length > 0,
            availableNestedSummaryLevels: [],
            selectedNestedSummaryLevels: [],
        };
        this.bindGluBusEvents({
            MAP_PREFERRED_SUMMARY_LEVEL_CHANGED: this.onMapPreferredSummaryLevelChanged,
            MAP_MASKING_FILTER_APPLIED: this.onMapMaskingFilterUpdate,
            MAP_MASKING_FILTER_REMOVED: this.onMapMaskingFilterUpdate,
            MAP_MASK_SELECTED_FEATURES_CHANGED: this.onMapMaskSelectedFeaturesChanged,
            MASK_TOGGLE_NESTED_SUMMARY_LEVEL_REQUEST: this.onMaskToggleNestedSummaryLevelRequest,
            MASK_TOGGLE_ALL_NESTED_SUMMARY_LEVEL_REQUEST: this.onMaskToggleAllNestedSummaryLevelRequest,
            MAP_MASK_SELECTED_FEATURES_IMPORTED: this.onMapMaskSelectedFeaturesImported,
        });
        this.boundOnCurrentMapsRetrieved = this.onCurrentMapsRetrieved;
        this.boundOnCurrentMetadataRetrieved = this.onCurrentMetadataRetrieved;
        this.boundOnMapViewerRetrieved = this.onMapViewerRetrieved;

        this._map = undefined;
        this._metadataObject = undefined;
        this._mapViewer = undefined;
    }

    componentDidMount() {
        this.bus.once('CURRENT_MAPS', this.boundOnCurrentMapsRetrieved);
        this.bus.once('CURRENT_METADATA', this.boundOnCurrentMetadataRetrieved);
        this.bus.once('MAP_VIEWER', this.boundOnMapViewerRetrieved);
        this.emit('CURRENT_MAPS_REQUEST', { source: this });
        this.emit('CURRENT_METADATA_REQUEST', { source: this });
        this.emit('MAP_GET_MAP_VIEWER', { source: this });
    }

    componentWillUnmount() {
        this.bus.off('CURRENT_MAPS', this.boundOnCurrentMapsRetrieved);
        this.bus.off('CURRENT_METADATA', this.boundOnCurrentMetadataRetrieved);
        this.bus.off('MAP_VIEWER', this.boundOnMapViewerRetrieved);
        this.unbindGluBusEvents();
    }

    onMapViewerRetrieved = eventMap => {
        this._mapViewer = eventMap.source;
        this._initializeSummaryLevelSelection();
    };

    onCurrentMapsRetrieved = currentMaps => {
        this._map = currentMaps[this.props.mapInstance.currentMapId];
        this.setState({
            availableSummaryLevels: this._map.summaryLevels,
        }, () => {
            this._initializeSummaryLevelSelection();
        });
    };

    onCurrentMetadataRetrieved = metadata => {
        this._metadataObject = getMetadataObjectsFromVariableSelection(
            this.props.mapInstance.dataTheme.variableSelection,
            metadata,
        );
        this.setState({
            availableGeoTypes: Object.values(this._metadataObject.survey.geoTypes).sort((a, b) => a.index - b.index),
        }, () => {
            this._initializeSummaryLevelSelection();
        });
    };

    onMapMaskSelectedFeaturesImported(eventMap) {
        if (eventMap.mapInstanceId === this.props.mapInstance.id) {
            if (eventMap.features.length === 0 && this.props.mapInstance.hasMaskingFilter) {
                this.emit('REMOVE_MASKING_FILTER_REQUEST', {
                    mapInstanceId: this.props.mapInstance.id,
                    dataGeoFilter: Object.values(this.props.mapInstance.dataGeoFilters)[0],
                });
            }
            if (eventMap.features.length > 0) {
                this.emit('APPLY_MASKING_FILTER_REQUEST', {
                    mapInstanceId: this.props.mapInstance.id,
                    summaryLevel: eventMap.summaryLevel,
                    nestedSummaryLevels: [],
                    selectedFeatures: eventMap.features,
                });
            }
        }
    }

    onMapMaskSelectedFeaturesChanged(eventMap) {
        if (eventMap.mapInstanceId === this.props.mapInstance.id) {
            if (eventMap.features.length === 0 && this.props.mapInstance.hasMaskingFilter) {
                this.emit('REMOVE_MASKING_FILTER_REQUEST', {
                    mapInstanceId: this.props.mapInstance.id,
                    dataGeoFilter: Object.values(this.props.mapInstance.dataGeoFilters)[0],
                });
            }
            if (eventMap.features.length > 0) {
                this.emit('APPLY_MASKING_FILTER_REQUEST', {
                    mapInstanceId: this.props.mapInstance.id,
                    summaryLevel: this.state.activeSummaryLevel,
                    nestedSummaryLevels: this.state.selectedNestedSummaryLevels,
                    selectedFeatures: eventMap.features,
                });
            }
        }
        this.setState({
            maskDirty: true,
        });
    }

    onMaskToggleNestedSummaryLevelRequest(eventMap) {
        if (eventMap.mapInstanceId === this.props.mapInstance.id) {
            let selectedNestedSummaryLevels = this.state.selectedNestedSummaryLevels;
            const summaryLevel = eventMap.summaryLevel;
            if (this.state.selectedNestedSummaryLevels.some(sl => sl.id === summaryLevel.id)) {
                // remove selection
                selectedNestedSummaryLevels = this.state.selectedNestedSummaryLevels.filter(sl => sl.id !== summaryLevel.id);
            } else {
                // add selection
                selectedNestedSummaryLevels.push(summaryLevel);
            }
            if (this.props.mapInstance.hasMaskingFilter) {
                this.emit('APPLY_MASKING_FILTER_REQUEST', {
                    mapInstanceId: this.props.mapInstance.id,
                    nestedSummaryLevels: selectedNestedSummaryLevels,
                });
            }
            this.setState({
                selectedNestedSummaryLevels,
            });
        }
    }

    onMaskToggleAllNestedSummaryLevelRequest(eventMap) {
        if (eventMap.mapInstanceId === this.props.mapInstance.id) {
            let selectedNestedSummaryLevels;
            if (this.state.selectedNestedSummaryLevels.length === this.state.availableNestedSummaryLevels.length) {
                selectedNestedSummaryLevels = [];
            } else {
                selectedNestedSummaryLevels = this.state.availableNestedSummaryLevels;
            }
            this.setState({
                selectedNestedSummaryLevels,
            });
        }
    }

    onMapMaskingFilterUpdate() {
        const dataGeoFilter = Object.values(this.props.mapInstance.dataGeoFilters)[0];
        if (dataGeoFilter &&
            (!this._mapViewer.preferredSummaryLevel ||
                this._mapViewer.preferredSummaryLevel.id !== dataGeoFilter.summaryLevelId)) {
            this.emit('SET_PREFERRED_SUMMARY_LEVEL_REQUEST', {
                source: this,
                mapInstanceId: this.props.mapInstance.id,
                summaryLevel: this.state.availableSummaryLevels.find(sl => sl.id === dataGeoFilter.summaryLevelId),
            });
        }
        this.forceUpdate();
    }

    onMapPreferredSummaryLevelChanged() {
        this.emit('CLOSE_DROPDOWN');
        if (this._mapViewer && this._mapViewer.activeSummaryLevel !== this.state.activeSummaryLevel) {
            this._initializeSummaryLevelSelection();
        }
    }

    onExitMapMaskEditor = () => {
        this.emit('EXIT_MASK_MODE', { mapInstanceId: this.props.mapInstance.id });
    }

    onClearGeoSelection = () => {
        this.emit('MAP_CLEAR_SELECTED_MASK_FEATURES', { mapInstanceId: this.props.mapInstance.id });
    }

    render() {
        const maskPanelHeaderActions = [];
        if (this.state.maskDirty) {
            maskPanelHeaderActions.push(
                (<DoneButton
                    className="btn-raised map-mask-editor__done-button"
                    key="done-btn"
                    onClick={this.onExitMapMaskEditor}
                >
                    {this.props.intl.formatMessage({ id: 'done' })}
                </DoneButton>));

            if (Object.keys(this.props.mapInstance.dataGeoFilters).length > 0) {
                maskPanelHeaderActions.unshift((<button
                    className="btn-flat"
                    key="clear-btn"
                    onClick={this.onClearGeoSelection}
                >
                    {this.props.intl.formatMessage({ id: 'dataBrowser.clearMask' })}
                </button>));
            }
        }

        return (<div className="map-mask-editor flex-it column stretch">
            <Panel
                title={this.props.intl.formatMessage({ id: 'dataBrowser.maskMapData' })}
                headerStyle="white"
                headerActions={maskPanelHeaderActions}
                onCloseClick={this.state.maskDirty ? undefined : this.onExitMapMaskEditor}
                closeIcon={<i className="material-icons">close</i>}
            >
                <MaskSummaryLevelsSelection
                    mapInstanceId={this.props.mapInstance.id}
                    mapInstance={this.props.mapInstance}
                    summaryLevels={this.state.availableSummaryLevels}
                    activeSummaryLevel={this.state.activeSummaryLevel}
                    availableNestedSummaryLevels={this.state.availableNestedSummaryLevels}
                    selectedNestedSummaryLevels={this.state.selectedNestedSummaryLevels}
                />
                <div className="map-mask-editor__divider" />
                <MaskDataSelection
                    mapInstanceId={this.props.mapInstance.id}
                    mapInstance={this.props.mapInstance}
                    activeSummaryLevel={this.state.activeSummaryLevel}
                />
            </Panel>
        </div>);
    }

    _initializeSummaryLevelSelection() {
        if (this._map && this._metadataObject && this._mapViewer) {
            const activeSummaryLevel = this._mapViewer.activeSummaryLevel;
            const availableNestedSummaryLevels = this.getNestedSummaryLevels(activeSummaryLevel);
            const dataGeoFilter = Object.values(this.props.mapInstance.dataGeoFilters)[0];
            const selectedNestedSummaryLevels = availableNestedSummaryLevels.filter(sl => (
                !dataGeoFilter || dataGeoFilter.nestedSummaryLevelsIds.find(id => id === sl.id)
            ));
            this.setState({
                activeSummaryLevel,
                availableNestedSummaryLevels,
                selectedNestedSummaryLevels,
            });
        }
    }

    getNestedSummaryLevels(summaryLevel) {
        const { availableGeoTypes } = this.state;
        if (!summaryLevel || !availableGeoTypes) {
            return [];
        }

        // find geoType metadata index for input summaryLevel
        const refGeoTypeIndex = availableGeoTypes.findIndex(g => g.name === summaryLevel.id);
        const refGeoTypeIndent = availableGeoTypes[refGeoTypeIndex].indent;

        const nestedSummaryLevels = [];

        // loop through available geo types starting from reference (summary level) type + 1 (take the next type)
        // and compare nesting values. Break when nesting of current is lower than reference geo type
        for (let i = refGeoTypeIndex + 1; i < availableGeoTypes.length; i += 1) {
            const currentGeoType = availableGeoTypes[i];
            if (currentGeoType.indent <= refGeoTypeIndent) break;

            // geo type is nested under summary level
            // check if map supports this geo type. if so, push into output array
            const sumLvl = this._map.summaryLevels.find(s => s.id === currentGeoType.name);
            if (sumLvl) {
                nestedSummaryLevels.push(sumLvl);
            }
        }

        return nestedSummaryLevels;
    }
}

export default injectIntl(MapMaskEditor);
