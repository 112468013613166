class LibraryData {
    constructor(values) {
        if (values) {
            Object.keys(values).forEach(k => {
                if (this.constructor.prototype.hasOwnProperty(k)) {
                    this[k] = values[k];
                }
            });
        }
    }

    /** @type {import('../../..').LayerLibraryItem[]} */
    get definition() {
        return this._definition;
    }

    /** @type {string[]} */
    get categories() {
        return this._categories;
    }

    /** @type {import('../../..').LayerDataGroup[]} */
    get groups() {
        return this._groups;
    }

    /** @type {import('../../..').LayerLibraryLayersWithMetadata[]} */
    get layers() {
        return this._layers;
    }

    set definition(definition) {
        this._definition = definition;
    }

    set categories(categories) {
        this._categories = categories;
    }

    set groups(groups) {
        this._groups = groups;
    }

    set layers(layers) {
        this._layers = layers;
    }

    clone() {
        return new LibraryData({
            definition: this.definition,
            categories: this.categories,
            groups: this.groups,
            layers: this.layers,
        });
    }

    toJSON() {
        return {
            definition: this.definition,
            categories: this.categories,
            groups: this.groups,
            layers: this.layers,
        };
    }
}

export default LibraryData;
