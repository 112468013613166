import FilterStatus from '../../enums/FilterStatus';
import FilterCombiner from '../../enums/FilterCombiner';
import MetadataDataType from '../../enums/MetadataDataType';

class DataFilter {
    constructor(values) {
        this.features = [];
        if (values) {
            Object.keys(values).forEach(k => {
                if (this.constructor.prototype.hasOwnProperty(k)) {
                    this[k] = values[k];
                }
            });
        }
        this._values = values;
        if (!this.filters) this.filters = {};
        if (!this.filterCombiner) this.filterCombiner = FilterCombiner.MATCH_ALL.type;
    }

    get filterCombiner() {
        return this._filterCombiner;
    }

    set filterCombiner(filterCombiner) {
        this._filterCombiner = filterCombiner;
    }

    get filters() {
        return this._filters;
    }

    get filtersAsArray() {
        return Object.values(this._filters);
    }

    set filters(filters) {
        this._filters = filters;
    }

    hasValidFilters() {
        return Object.keys(this.filters).some(filterId => this.filters[filterId].status === FilterStatus.VALID);
    }

    // Check feature values and filter values
    isDataFilterTrue(featureProperties) {
        const filterRulesValidity = [];

        // get validity of each filter rule
        this.filtersAsArray.forEach(filter => filterRulesValidity.push(filter.isValid(featureProperties)));

        // evaluate the entire data filter in regards to the filter combiner used
        switch (this._filterCombiner) {
        case FilterCombiner.MATCH_ALL.type:
            return filterRulesValidity.every(v => v);
        case FilterCombiner.MATCH_ANY.type:
            return filterRulesValidity.some(v => v);
        case FilterCombiner.MATCH_NONE.type:
            return !filterRulesValidity.some(v => v);
        default:
            return false;
        }
    }

    isDataFilterInvalid(featureProperties) {
        return !this.isDataFilterTrue(featureProperties);
    }

    // Get the filter object in form that is used by dragonfly
    // SAMPLE:
    // filter:
    // [
    //     'all',
    //     [
    //         '>',
    //         [
    //             'number',
    //             [
    //                 'get',
    //                 'PopulationDensity'
    //             ]
    //         ],
    //         100
    //     ]
    // ]
    getFormattedFilter() {
        const validFilterKeys = Object.keys(this.filters).filter(key => this.filters[key].status === FilterStatus.VALID);

        const fields = validFilterKeys.map(key => this.filters[key].field);
        const hasFieldFilters = validFilterKeys.map(key => (['has', this.filters[key].field.fieldName]));
        const mappedFilters = validFilterKeys.map(key => {
            const { type, field, value } = this.filters[key];
            const { fieldDataType, formatting } = field;

            if (fieldDataType === MetadataDataType.STRING) {
                const selection = ['string', ['get', field.fieldName], ''];
                return ([type, selection, value]);
            }

            let selection = ['to-number', ['get', field.fieldName]];

            // Extract decimal precision considering all available formats available in enums/NumberFormat.js
            const formatDecimalPlaces = parseInt(formatting.substring(formatting.length - 8), 10) || 0;

            // In case of defined decimal precision, format the new map filter to comply with the values rounded to the
            // specified number of decimal places. Original issue: https://socex-charts.atlassian.net/browse/SOCEX-1986
            if (formatDecimalPlaces) {
                selection = ['/', ['round', ['*', selection, 10 ** formatDecimalPlaces]], 10 ** formatDecimalPlaces];
            }

            // check if percentage input
            if (/^\d+(\.\d+)?%$/.test(value)) {
                const total = ['to-number', ['get', field.baseFieldName]];
                return ([type, ['/', ['*', selection, 100], total], numeral(value).value() * 100]);
            }

            return ([type, selection, numeral(value).value()]);
        });

        const dataFilterWrapper = {
            fields,
        };

        let mainFilter;
        // If none is selected then we flip every filter in the list
        if (this.filterCombiner === 'none') {
            mainFilter = ['all', ...mappedFilters.map(mf => ['!', ...[mf]])];
        } else {
            mainFilter = [this.filterCombiner, ...mappedFilters];
        }


        dataFilterWrapper.filter = mainFilter;
        // Filter for helper layer is a combination of check for field filters and
        // inverted user filter since it should appear on geographies that do not meet the criteria
        // but not on geographies that do not have data
        dataFilterWrapper.helperLayerFilter = ['all', ...hasFieldFilters, ['!', mainFilter]];

        return dataFilterWrapper;
    }

    equals(that) {
        return this.filterCombiner === that.filterCombiner && this.filters.equals(that.filters);
    }

    clone() {
        const newFilters = {};
        Object.keys(this.filters).forEach(key => {
            newFilters[key] = this.filters[key].clone();
        });

        return new DataFilter({
            filterCombiner: this.filterCombiner,
            filters: newFilters,
        });
    }

    toJSON() {
        return {
            filterCombiner: this.filterCombiner,
            filters: this.filters,
        };
    }
}

export default DataFilter;
