const mapSelectionType = {
    POINT: 0,
    BOX: 1,
    CIRCLE: 2,
    POLYGON: 3,
    LINE: 4,
    FEATURE: 5,
};

export default mapSelectionType;
