class BaseHandler {
    constructor(mapViewer) {
        this._mapConfig = mapViewer.currentMap;
        this._mapInstance = mapViewer.mapInstance;
        this._map = mapViewer.dragonflyMap;
        this._mapViewer = mapViewer;
        this._mapInstanceId = mapViewer.id;
        this._bus = mapViewer.bus;

        this._gluBusEvents = {};
    }

    get mapConfig() {
        return this._mapConfig;
    }

    /** @type {import('../../objects/MapInstance').default} */
    get mapInstance() {
        return this._mapInstance;
    }

    /** @type {import('dragonfly-v3').Map} */
    get map() {
        return this._map;
    }

    get mapViewer() {
        return this._mapViewer;
    }

    get mapInstanceId() {
        return this._mapInstanceId;
    }

    get bus() {
        return this._bus;
    }

    addControl(control, id) {
        this.map.addControl(control);
        this._mapViewer.dragonflyMapControls[id] = control;
    }

    removeControl(control, id) {
        this.map.removeControl(control);
        delete this._mapViewer.dragonflyMapControls[id];
    }

    setGluBusEvents(events) {
        Object.keys(events).forEach(eventName => {
            this._gluBusEvents[eventName] = events[eventName];
        });
    }

    bind() {
        Object.keys(this._gluBusEvents).forEach(eventName =>
            this._bus.on(eventName, this._gluBusEvents[eventName], this),
        );
    }

    unbind() {
        Object.keys(this._gluBusEvents).forEach(eventName =>
            this._bus.off(eventName, this._gluBusEvents[eventName], this),
        );
        this._gluBusEvents = {};
    }

    emit(eventName, payload) {
        const newPayload = payload || {};
        this._bus.emit(eventName, newPayload);
    }
}

export default BaseHandler;
