/** @param {number|string} number */
export function getNumberOfDecimals(number) {
    return (number.toString().split('.')[1] || []).length;
}

export function getRoundedNumber(number, numberOfDecimals) {
    return Number(number.toFixed(numberOfDecimals));
}

export function isValidNumber(value) {
    return typeof value === 'number' || (typeof value === 'string' && !isNaN(value.replace(',', '')));
}
