// @ts-check
import dragonfly from 'dragonfly-v3';
import BaseHandler from './BaseHandler';

const POPUP_OFFSET = 40;

class UserLocationHandler extends BaseHandler {
    constructor(mapViewer) {
        super(mapViewer);
        this.setGluBusEvents({
            // Attach map click event for custom location selection
            CUSTOM_LOCATION_REQUEST: this.addMapEvents,
            CUSTOM_LOCATION_CANCEL: this.removeMapEvents,
        });

        /** @type {import('mapbox-gl').Popup} */
        this._pinPopup = undefined;
    }

    onMouseUp = e => {
        this.emit('CUSTOM_LOCATION_RESPONSE', e.lngLat);
        this.removeMapEvents();
    };

    onMapMoveStart = () => {
        this.map.off('mouseup', this.onMouseUp);
    };

    onMapMoveEnd = () => {
        this.map.on('mouseup', this.onMouseUp);
    };

    onMouseMove = e => {
        /** @type {[number, number]} */
        const coordinates = [e.lngLat.lng, e.lngLat.lat];
        if (this._pinPopup) {
            this._pinPopup.setLngLat(coordinates);
        } else {
            // create popup
            this._pinPopup = new dragonfly.Popup({
                closeOnClick: false,
                closeButton: false,
                offset: POPUP_OFFSET,
            });
            this._pinPopup
                .setLngLat(coordinates)
                .setHTML('<div>Select a location</div>')
                .addTo(this.map);
            // add popup class and ignore ts problem, since there is no different
            // way to add a class to the popup container in the mapbox/dragonfly
            // version that we use
            // @ts-ignore
            this._pinPopup._container.classList.add(
                'select-user-location-popup',
            );
        }
    };

    addMapEvents = () => {
        const canvas = this.map.getCanvas();
        canvas.style.cursor = 'crosshair';

        this.map.on('mouseup', this.onMouseUp);
        this.map.on('movestart', this.onMapMoveStart);
        this.map.on('moveend', this.onMapMoveEnd);
        this.map.on('mousemove', this.onMouseMove);
    };

    removeMapEvents = () => {
        const canvas = this.map.getCanvas();
        canvas.style.cursor = 'default';
        // remove popup
        if (this._pinPopup) {
            this._pinPopup.remove();
            this._pinPopup = undefined;
        }

        this.map.off('mouseup', this.onMouseUp);
        this.map.off('movestart', this.onMapMoveStart);
        this.map.off('moveend', this.onMapMoveEnd);
        this.map.off('mousemove', this.onMouseMove);
    };
}

export default UserLocationHandler;
