import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import BusComponent from '../../BusComponent';
import Tooltip from '../../Tooltip';
import YearSelectionTabs from './YearSelectionTabs';
import YearSelectionList from './YearSelectionList';

import YearSelectionMode from '../../../enums/YearSelectionMode';
import DataFilterWarningTypes from '../../../enums/DataFilterWarningTypes';

const SELECTION_INFO = {
    SINGLE: 'Select year',
    RANGE_FIRST: 'Select the first year',
    RANGE_SECOND: 'Select the second year',
};

class YearSelection extends BusComponent {
    static NAME = 'YearSelection';

    constructor(props, context) {
        super(props, context);

        const { dataTheme } = this.props.mapInstance;
        const { variableSelection, appliedChangeOverTimeCompareSelection } = dataTheme;

        this.initialMode = dataTheme.isChangeOverTimeApplied ?
                           YearSelectionMode.RANGE :
                           YearSelectionMode.SINGLE;
        this.initialCurrentYear = variableSelection.items[0];
        this.initialCompareYear = dataTheme.isChangeOverTimeApplied ? appliedChangeOverTimeCompareSelection : undefined;

        this.state = {
            currentMode: this.initialMode,
            currentYearItem: this.initialCurrentYear,
            currentCompareYearItem: this.initialCompareYear,
            modeWithSelectedYearItems: this.initialMode,
            selectedYearItem: this.initialCurrentYear,
            selectedCompareYearItem: this.initialCompareYear,
        };
    }

    componentWillReceiveProps(nextProps) {
        const { dataTheme } = nextProps.mapInstance;

        this.initialMode = dataTheme.isChangeOverTimeApplied ?
                           YearSelectionMode.RANGE :
                           YearSelectionMode.SINGLE;
        this.initialCurrentYear = dataTheme.variableSelection.items[0];
        this.initialCompareYear = dataTheme.isChangeOverTimeApplied ? dataTheme.appliedChangeOverTimeCompareSelection : undefined;

        this.setState({
            currentMode: this.initialMode,
            currentYearItem: this.initialCurrentYear,
            currentCompareYearItem: this.initialCompareYear,
            modeWithSelectedYearItems: this.initialMode,
            selectedYearItem: this.initialCurrentYear,
            selectedCompareYearItem: this.initialCompareYear,
        });
    }

    handleModeChange = newMode => {
        const {
            currentMode,
            modeWithSelectedYearItems,
            selectedYearItem,
            selectedCompareYearItem,
        } = this.state;

        if (newMode !== currentMode) {
            this.setState({
                currentMode: newMode,
                currentYearItem: newMode === modeWithSelectedYearItems ?
                                 selectedYearItem :
                                 undefined,
                compareYearItem: newMode === modeWithSelectedYearItems ?
                                 selectedCompareYearItem :
                                 undefined,
            });
        }
    }

    onApplyChanges = () => {
        const { mapInstance, onClose } = this.props;
        const { currentMode, currentYearItem, currentCompareYearItem } = this.state;

        switch (currentMode) {
        case YearSelectionMode.SINGLE: {
            const isSwitchedMode = this.initialMode !== currentMode;
            if (isSwitchedMode || (this.initialCurrentYear.surveyName !== currentYearItem.surveyName)) {
                this.emit(
                    'CHANGE_SURVEY_YEAR_REQUEST',
                    {
                        mapInstanceId: mapInstance.id,
                        item: currentYearItem,
                    }
                );
            }
            break;
        }
        case YearSelectionMode.RANGE: {
            const currentYearChanged = this.initialCurrentYear ?
                                       this.initialCurrentYear.surveyName !== currentYearItem.surveyName :
                                       true;
            const compareYearChanged = this.initialCompareYear ?
                                       this.initialCompareYear.surveyName !== currentCompareYearItem.surveyName :
                                       true;
            if (currentYearChanged || compareYearChanged) {
                this.emit(
                    'CHANGE_OVER_TIME_REQUEST',
                    {
                        mapInstanceId: mapInstance.id,
                        baseItem: currentYearItem,
                        compareItem: currentCompareYearItem,
                        pointOfReferenceItem: currentCompareYearItem,
                    }
                );
            }
            break;
        }
        default:
            console.warn(`Unsupported mode:${currentMode}`);
        }

        onClose();
    }

    onClick = (item, event) => {
        const { mapInstance } = this.props;

        // DataFilter needs to be cleared if changing survey or activating COT
        if (mapInstance.hasDataFilter) {
            this.onClearDataFilter(item, event);
        } else {
            this.onYearItemClick(item);
        }
    }

    onClearDataFilter = (item, event) => {
        const { mapInstance } = this.props;

        this.setState({
            highlightYearItem: item,
        });

        const warningPayload = {
            mapInstanceId: mapInstance.id,
            position: DataFilterWarningTypes.CHANGE_OVER_TIME_WARNING,
            anchorElement: event.target,
            onConfirm: () => this.onYearItemClick(item, event),
            onCancel: () => this.resetHighlight(),
        };

        this.bus.emit('SHOW_CLEAR_DATA_FILTER_WARNING', warningPayload);
    }

    onYearItemClick = item => {
        if (this.state.currentMode === YearSelectionMode.SINGLE) {
            this.onSingleYearClick(item);
        } else {
            this.onRangeYearClick(item);
        }
    }

    onSingleYearClick = item => {
        this.setState(
            {
                modeWithSelectedYearItems: YearSelectionMode.SINGLE,
                currentYearItem: item,
                selectedYearItem: item,
                currentCompareYearItem: undefined,
                selectedCompareYearItem: undefined,
            }
        );
    }

    onRangeYearClick = item => {
        const { metadata } = this.props;
        const { currentYearItem, currentCompareYearItem } = this.state;

        const hasCurrentYearSelected = currentYearItem !== undefined;
        const hasCompareYearSelected = currentCompareYearItem !== undefined;

        if ((!hasCurrentYearSelected && !hasCompareYearSelected) || (hasCurrentYearSelected && hasCompareYearSelected)) {
            this.setState({
                modeWithSelectedYearItems: YearSelectionMode.RANGE,
                currentYearItem: item,
                selectedYearItem: item,
                currentCompareYearItem: undefined,
                selectedCompareYearItem: undefined,
            });
        } else if (item.surveyName !== currentYearItem.surveyName) {
            // items may be selected in reverse order, ie. 2015 - 2010
            // it is product decision not to allow this, but rather always compare 2010 - 2015 regardless
            const currentYearItemYear = metadata.surveys[currentYearItem.surveyName].year;
            const compareYearItemYear = metadata.surveys[item.surveyName].year;

            if (currentYearItemYear < compareYearItemYear) {
                // expected order - current is less than compare
                this.setState(
                    {
                        modeWithSelectedYearItems: YearSelectionMode.RANGE,
                        currentYearItem,
                        selectedYearItem: currentYearItem,
                        currentCompareYearItem: item,
                        selectedCompareYearItem: item,
                    }
                );
            } else {
                // undesired order - compare is less than current, reorder
                this.setState(
                    {
                        modeWithSelectedYearItems: YearSelectionMode.RANGE,
                        currentYearItem: item,
                        selectedYearItem: item,
                        currentCompareYearItem: currentYearItem,
                        selectedCompareYearItem: currentYearItem,
                    }
                );
            }
        }
    }

    resetHighlight = () => {
        this.setState({ highlightYearItem: undefined });
    }

    render() {
        const { comparableVariables, metadata, onClose } = this.props;
        const {
            currentMode,
            currentYearItem,
            currentCompareYearItem,
            highlightYearItem,
        } = this.state;

        // Show notification for single year selection when no year is selected
        // Show notification for range year selection when second year is not selected
        const isSingleYearMode = currentMode === YearSelectionMode.SINGLE;
        let selectionInfo;
        switch (true) {
        case currentMode === YearSelectionMode.SINGLE:
            selectionInfo = SELECTION_INFO.SINGLE;
            break;
        case currentYearItem === undefined:
            selectionInfo = SELECTION_INFO.RANGE_FIRST;
            break;
        case currentCompareYearItem === undefined:
            selectionInfo = SELECTION_INFO.RANGE_SECOND;
            break;
        }

        const shouldHideNotification = (isSingleYearMode && !currentCompareYearItem) || (!isSingleYearMode && currentCompareYearItem);
        const selectionInfoClasses = classNames('year-selection__notification', {
            hidden: shouldHideNotification,
        });

        const shouldDisableDoneButton = (currentMode === YearSelectionMode.SINGLE && !currentYearItem) ||
                                        (currentMode === YearSelectionMode.RANGE && (!currentCompareYearItem || !currentYearItem));
        const doneButtonClasses = classNames('year-selection__done-button btn btn-flat', {
            disabled: shouldDisableDoneButton,
        });

        return (
            <div className="data-info year-selection-panel">
                <div className="data-info__header">
                    <h4>
                        {this.props.intl.formatMessage({ id: 'dataBrowser.timePeriod' })}
                    </h4>
                    <Tooltip overlay={this.props.intl.formatMessage({ id: 'close' })}>
                        <button className="material-icons btn-flat-icon" onClick={onClose}>close</button>
                    </Tooltip>
                </div>
                <div className="data-info__content data-info__content--with-overflow column">
                    <YearSelectionTabs
                        currentMode={currentMode}
                        onChangeMode={this.handleModeChange}
                    />
                    <YearSelectionList
                        metadata={metadata}
                        comparableVariables={comparableVariables}
                        currentYearItem={currentYearItem}
                        currentCompareYearItem={currentCompareYearItem}
                        highlightYearItem={highlightYearItem}
                        currentMode={currentMode}
                        onClick={this.onClick}
                    />
                </div>
                <div className="data-info__actions">
                    <div className="flex-it flex-end row">
                        <div className={selectionInfoClasses}>
                            {selectionInfo}
                        </div>
                        <button
                            className={doneButtonClasses}
                            disabled={shouldDisableDoneButton}
                            onClick={this.onApplyChanges}
                        >
                            {this.props.intl.formatMessage({ id: 'done' })}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

YearSelection.propTypes = {
    mapInstance: PropTypes.object.isRequired,
    metadata: PropTypes.object.isRequired,
    comparableVariables: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(YearSelection);
