import GLU from '../glu2.js/src/index';
import Api from '../apis/Api';
import LocationContourData from '../objects/LocationContourData';
import { getBoundaryWithValidatedSearchPoint } from '../helpers/GeoSpatialHelper';

class CustomMapSelectionDataSource extends GLU.DataSource {
    static get name() {
        return 'CustomMapSelectionDataSource';
    }

    static getInstance() {
        return new CustomMapSelectionDataSource();
    }

    constructor() {
        super(() => false);
        /** @type {Object.<string, LocationContourData>} */
        this._contours = {};
        this._createReportVisibility = false;
        this._selectedCorrespondingSummaryLevels = [];
        this._compatibleTableTemplates = undefined;
        this._downloadReport = false;
        this._reportDefinition = undefined;
        this._isOlapReport = false;
        this._olapReportName = '';
        this._olapReportId = '';
    }

    get reportDefinition() {
        return this._reportDefinition;
    }

    set reportDefinition(reportDefinition) {
        this._reportDefinition = reportDefinition;
    }

    get downloadReport() {
        return this._downloadReport;
    }

    set downloadReport(downloadReport) {
        this._downloadReport = downloadReport;
    }

    get compatibleTableTemplates() {
        return this._compatibleTableTemplates;
    }

    set compatibleTableTemplates(templates) {
        this._compatibleTableTemplates = templates;
    }

    get selectedCorrespondingSummaryLevels() {
        return this._selectedCorrespondingSummaryLevels;
    }

    set selectedCorrespondingSummaryLevels(summaryLevels) {
        this._selectedCorrespondingSummaryLevels = summaryLevels;
    }

    get isOlapReport() {
        return this._isOlapReport;
    }

    set isOlapReport(value) {
        this._isOlapReport = value;
    }

    get olapReportName() {
        return this._olapReportName;
    }

    set olapReportName(value) {
        this._olapReportName = value;
    }

    get olapReportId() {
        return this._olapReportId;
    }

    set olapReportId(value) {
        this._olapReportId = value;
    }

    /**
     * Retrieves contours created by SE contour API.
     * @param point {import('mapbox-gl').LngLat | import('../').Point} point of SearchResult
     * @param type {import('../').ContourType}
     * @param values {Array.<number>}
     * @param polygons {boolean}
     */
    getContourFeatures(point, type, values, polygons = true) {
        const payload = {
            lat: point.lat,
            lng: point.lng,
            type,
            values: `${values.join(',')}`,
            polygons,
        };

        // prepare contour data object
        let contourData = new LocationContourData({ point });

        // check if cached, if not, set up a blank cache entry, else use cached value
        if (!this._contours[contourData.id]) {
            this._contours[contourData.id] = contourData;
        } else {
            contourData = this._contours[contourData.id];
        }

        /**
         * Check if this data already exists, if so do not make any calls
         * Else get the data from mapbox
         * @param {(value: LocationContourData) => void} resolve
         */
        const logic = (resolve, reject) => {
            if (contourData[type][values] === undefined) {
                Api.contours.getContours({ query: payload })
                    .then(response => {
                        /** @type{import('../../..').Boundary[]}*/
                        const features = response.body.features;

                        features.forEach((feature, idx) => {
                            // Set the feature value and name in properties
                            feature.properties.name = 'isochrone-selection';
                            feature.properties.value = values[idx];
                            feature.properties.point = point;
                            contourData[type][values[idx]] = getBoundaryWithValidatedSearchPoint(feature);
                        });

                        // Save it to this data source so we can reuse it if
                        // the same point is selected again later
                        this._contours[contourData.id] = contourData;
                        resolve(contourData);
                    }, reject);
            } else {
                resolve(contourData);
            }
        };

        return new Promise(logic);
    }

    // For each map instance do the preload by populating its respective location analysis item
    async preloadProjectContourFeatures(currentProject) {
        await Promise.all(
            currentProject.frames.map(async frame => {
                await Promise.all(
                    frame.mapInstances.map(async mapInstance => {
                        const { locationAnalysisItem } = mapInstance;
                        // Preload isochrone drive time layers source data if any
                        if (locationAnalysisItem && locationAnalysisItem.contourType !== 'custom_geo') {
                            const contourData = await this.getContourFeatures(
                                locationAnalysisItem.point,
                                locationAnalysisItem.contourType,
                                locationAnalysisItem.sortedSelectionAsArray,
                                true,
                            );

                            // Save it to this data source so we can reuse it if
                            // the same point is selected again later
                            this._contours[contourData.id] = contourData;

                            // Update the map instance location analysis item with the isochrone features data
                            locationAnalysisItem.sourceData = contourData;
                        }
                    }),
                );
            }),
        );
    }
}

export default CustomMapSelectionDataSource;
