class FieldListField {
    constructor(values) {
        if (values) {
            Object.keys(values).forEach(k => {
                if (this.constructor.prototype.hasOwnProperty(k)) {
                    this[k] = values[k];
                }
            });
        }
    }

    /** @returns {string} */
    get fieldName() {
        return this._fieldName;
    }

    set fieldName(fieldName) {
        this._fieldName = fieldName;
    }

    /** @returns {boolean} */
    get universe() {
        return this._universe;
    }

    set universe(universe) {
        this._universe = universe;
    }

    /** @returns {boolean} */
    get hideFromUser() {
        return this._hideFromUser;
    }

    set hideFromUser(hideFromUser) {
        this._hideFromUser = hideFromUser;
    }

    /** @returns {boolean} */
    get isGeoNameField() {
        return this._isGeoNameField;
    }

    set isGeoNameField(isGeoNameField) {
        this._isGeoNameField = isGeoNameField;
    }

    get isVirtual() {
        return this._isVirtual === true;
    }

    set isVirtual(isVirtual) {
        this._isVirtual = isVirtual;
    }

    get expression() {
        return this._expression;
    }

    set expression(expression) {
        this._expression = expression;
    }

    /** @returns {boolean} */
    get isChangeOverTimeField() {
        return this._isChangeOverTimeField;
    }

    set isChangeOverTimeField(isChangeOverTimeField) {
        this._isChangeOverTimeField = isChangeOverTimeField;
    }

    /**
     * Qualified name for field used as point of reference in COT
     * @type {string}
     */
    get pointOfReferenceFieldQName() {
        return this._pointOfReferenceFieldQName;
    }

    /**
     * Qualified name for field used as point of reference in COT
     * @type {void}
     */
    set pointOfReferenceFieldQName(pointOfReferenceFieldQName) {
        this._pointOfReferenceFieldQName = pointOfReferenceFieldQName;
    }

    /** @returns {string} */
    get label() {
        return this._label;
    }

    set label(label) {
        this._label = label;
    }

    /** @returns {string} */
    get formatting() {
        return this._formatting;
    }

    set formatting(formatting) {
        this._formatting = formatting;
    }

    /** @returns {string} */
    get surveyName() {
        return this._surveyName;
    }

    set surveyName(surveyName) {
        this._surveyName = surveyName;
    }

    get tableUuid() {
        return this._tableUuid;
    }

    set tableUuid(tableUuid) {
        this._tableUuid = tableUuid;
    }

    get datasetAbbreviation() {
        return this._datasetAbbreviation;
    }

    set datasetAbbreviation(datasetAbbreviation) {
        this._datasetAbbreviation = datasetAbbreviation;
    }

    /** @returns {boolean} */
    get isComputed() {
        return this._isComputed;
    }

    set isComputed(isComputed) {
        this._isComputed = isComputed;
    }

    get computeFunction() {
        return this._computeFunction;
    }

    set computeFunction(computeFunction) {
        this._computeFunction = computeFunction;
    }

    /**
     * Qualified name for field used as numerator
     * @type {string}
     */
    get fieldNumerator() {
        return this._fieldNumerator;
    }

    /**
     * Qualified name for field used as numerator
     * @type {void}
     */
    set fieldNumerator(fieldNumerator) {
        this._fieldNumerator = fieldNumerator;
    }

    /**
     * Qualified name for field used as denominator
     * @type {string}
     */
    get fieldDenominator() {
        return this._fieldDenominator;
    }

    /**
     * Qualified name for field used as denominator
     * @type {void}
     */
    set fieldDenominator(fieldDenominator) {
        this._fieldDenominator = fieldDenominator;
    }

    get fieldMultiplier() {
        return this._fieldMultiplier;
    }

    set fieldMultiplier(fieldMultiplier) {
        this._fieldMultiplier = fieldMultiplier;
    }

    get fieldNumeratorParent() {
        return this._fieldNumeratorParent;
    }

    set fieldNumeratorParent(fieldNumeratorParent) {
        this._fieldNumeratorParent = fieldNumeratorParent;
    }

    get fieldDenominatorParent() {
        return this._fieldDenominatorParent;
    }

    set fieldDenominatorParent(fieldDenominatorParent) {
        this._fieldDenominatorParent = fieldDenominatorParent;
    }

    get percentBaseMin() {
        return (this._percentBaseMin === undefined) ? 20 : this._percentBaseMin;
    }

    set percentBaseMin(percentBaseMin) {
        this._percentBaseMin = percentBaseMin;
    }

    get qualifiedName() {
        if (this.surveyName) {
            return `${this.surveyName}.${this.datasetAbbreviation}.${this.fieldName}`;
        }

        return this.fieldName;
    }

    equals(that) {
        return (this.fieldName === that.fieldName &&
                this.hideFromUser === that.hideFromUser &&
                this.universe === that.universe &&
                this.isGeoNameField === that.isGeoNameField &&
                this.label === that.label &&
                this.formatting === that.formatting &&
                this.surveyName === that.surveyName &&
                this.tableUuid === that.tableUuid &&
                this.datasetAbbreviation === that.datasetAbbreviation &&
                this.isComputed === that.isComputed &&
                this.computeFunction === that.computeFunction &&
                this.fieldNumerator === that.fieldNumerator &&
                this.fieldDenominator === that.fieldDenominator &&
                this.fieldMultiplier === that.fieldMultiplier &&
                this.fieldNumeratorParent === that.fieldNumeratorParent &&
                this.fieldDenominatorParent === that.fieldDenominatorParent &&
                this.isChangeOverTimeField === that.isChangeOverTimeField &&
                this.pointOfReferenceFieldQName === that.pointOfReferenceFieldQName &&
                this.percentBaseMin === that.percentBaseMin);
    }

    clone() {
        const fieldListField = new FieldListField();
        fieldListField.fieldName = this.fieldName;
        fieldListField.hideFromUser = this.hideFromUser;
        fieldListField.universe = this.universe;
        fieldListField.isGeoNameField = this.isGeoNameField;
        fieldListField.isVirtual = this.isVirtual;
        fieldListField.expression = this.expression;
        fieldListField.label = this.label;
        fieldListField.formatting = this.formatting;
        fieldListField.surveyName = this.surveyName;
        fieldListField.datasetAbbreviation = this.datasetAbbreviation;
        fieldListField.isComputed = this.isComputed;
        fieldListField.computeFunction = this.computeFunction;
        fieldListField.fieldNumerator = this.fieldNumerator;
        fieldListField.fieldDenominator = this.fieldDenominator;
        fieldListField.fieldMultiplier = this.fieldMultiplier;
        fieldListField.fieldNumeratorParent = this.fieldNumeratorParent;
        fieldListField.fieldDenominatorParent = this.fieldDenominatorParent;
        fieldListField.isChangeOverTimeField = this.isChangeOverTimeField;
        fieldListField.pointOfReferenceFieldQName = this.pointOfReferenceFieldQName;
        fieldListField.percentBaseMin = this.percentBaseMin;
        fieldListField.tableUuid = this.tableUuid;

        return fieldListField;
    }

    toJSON() {
        return {
            fieldName: this.fieldName,
            hideFromUser: this.hideFromUser,
            isGeoNameField: this.isGeoNameField,
            isVirtual: this.isVirtual,
            expression: this.expression,
            label: this.label,
            formatting: this.formatting,
            surveyName: this.surveyName,
            tableUuid: this.tableUuid,
            datasetAbbreviation: this.datasetAbbreviation,
            isComputed: this.isComputed,
            computeFunction: this.computeFunction,
            fieldNumerator: this.fieldNumerator,
            fieldMultiplier: this.fieldMultiplier,
            fieldDenominator: this.fieldDenominator,
            fieldNumeratorParent: this.fieldNumeratorParent,
            fieldDenominatorParent: this.fieldDenominatorParent,
            isChangeOverTimeField: this.isChangeOverTimeField,
            pointOfReferenceFieldQName: this.pointOfReferenceFieldQName,
            percentBaseMin: this.percentBaseMin,
        };
    }
}

export default FieldListField;
