import React from 'react';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';

import BusComponent from '../BusComponent';
import SimpleDropdown from '../dropdown/SimpleDropdown';
import Checkbox from '../form/Checkbox';
import Loader from '../Loader';
import { GEOCODE_FIELDS } from '../../helpers/UserDataUploadHelper';

const buildPreviewLine = (geocodeFields, columns) => rowIndex =>
    GEOCODE_FIELDS.reduce((prev, current) => {
        const field = geocodeFields[current.id];
        if (field) {
            const column = columns.find(c => c.id === field);
            if (column && column.data && column.data.length >= rowIndex) {
                prev.push(columns.find(c => c.id === field).data[rowIndex]);
            }
        }
        return prev;
    }, []).join(', ');

class GeocodeOptions extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            addressInSingleField: false,
            geocodeFields: this.props.selection || {},
            geocoding: false,
        };
    }

    onDoneClick = () => {
        this.setState({ geocoding: true });
        this.bus.emit('GEOCODE_USER_DATA', {
            mapInstanceId: this.props.mapInstanceId,
            userUpload: this.props.userUpload,
            geocodeFields: this.state.geocodeFields,
        });
    };

    onSingleFieldAddressCheck = checked => {
        this.setState({ addressInSingleField: checked });
    };

    renderGeoCodeField({ id, label }) {
        const { addressInSingleField } = this.state;
        const { userUpload } = this.props;
        let { geocodeFields } = this.state;

        const disabled = addressInSingleField && id !== GEOCODE_FIELDS[0].id;
        const onChange = columnId => {
            if (columnId === '-1') {
                delete geocodeFields[id];
            } else {
                geocodeFields = { ...geocodeFields, [id]: columnId };
            }
            this.setState({ geocodeFields });
        };
        let selectedItem = { id: '-1', text: 'None' };
        const items = userUpload.columns.map(col => ({
            id: col.id,
            text: (
                <div className="flex-it space-between">
                    <span className="flex-it no-shrink">{col.title}</span>
                    <span className="ellipsis text-muted margin-left-20p font-13">
                        {col.data
                            .filter(v => v)
                            .splice(0, 5)
                            .join(', ')}
                    </span>
                </div>
            ),
        }));
        items.unshift(selectedItem);

        if (geocodeFields[id]) {
            const column = userUpload.columns.find(c => geocodeFields[id] === c.id);
            selectedItem = { id: column.id, text: column.title };
        }
        const l = id === GEOCODE_FIELDS[0].id && addressInSingleField ? 'Full Address' : label;
        return (
            <div>
                <span className={classnames('font-14 margin-bottom-10', { disabled })}>{l}</span>
                <SimpleDropdown
                    className={classnames('margin-top-10 margin-bottom-20', { disabled })}
                    onItemClick={onChange}
                    items={items}
                    selectedItem={selectedItem}
                />
            </div>
        );
    }

    renderActions() {
        const disabled = Object.keys(this.state.geocodeFields).length === 0;
        const classes = classnames('btn-raised', {
            'btn-raised--disabled': disabled,
        });

        return (
            <div className="user-data-upload-editor__actions">
                <div className={classes} disabled={disabled} onClick={this.onDoneClick}>
                    {this.props.intl.formatMessage({ id: 'dataBrowser.addLayer' })}
                </div>
            </div>
        );
    }

    renderError() {
        const { error } = this.props;
        if (error) {
            return <div className="user-data-upload-editor__errors">{error}</div>;
        }
        return undefined;
    }

    renderPreview() {
        const { geocodeFields } = this.state;

        if (Object.keys(geocodeFields).some(k => geocodeFields[k])) {
            const line = buildPreviewLine(geocodeFields, this.props.userUpload.columns);
            return (
                <div>
                    <div className="margin-vertical-10">Sample addresses to geocode:</div>
                    <div className="margin-bottom-5">{line(0)}</div>
                    <div className="margin-bottom-5">{line(1)}</div>
                    <div className="margin-bottom-5">{line(2)}</div>
                </div>
            );
        }
        return undefined;
    }

    renderGeoFields() {
        return (
            <div>
                {GEOCODE_FIELDS.map(field => (
                    <div key={field.id}>{this.renderGeoCodeField(field)}</div>
                ))}
            </div>
        );
    }

    render() {
        const { addressInSingleField } = this.state;
        if (this.state.geocoding) {
            return (
                <div className="flex-it column grow">
                    <Loader text="Geocoding... This could take a while" />
                </div>
            );
        }
        return (
            <div className="flex-it column grow vertical-scrollbar">
                <div className="flex-it column grow no-shrink padded-20">
                    <div className="user-data-upload-editor__description">
                        Select columns from your file that tell us where to put placemarks on the
                        map, using highest precision available like address or just zip code, city,
                        state, etc.
                    </div>
                    <span className="text-highlight margin-vertical-10">
                        * Geocoder currently only works with the United States addresses.
                    </span>
                    <Checkbox
                        onCheck={this.onSingleFieldAddressCheck}
                        label="Full address is in a single column"
                        checked={addressInSingleField}
                    />
                    {this.renderGeoFields()}
                    {this.renderPreview()}
                    {this.renderError()}
                </div>
                {this.renderActions()}
            </div>
        );
    }
}

export default injectIntl(GeocodeOptions);
