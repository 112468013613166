import React from 'react';
import PropTypes from 'prop-types';
import Loader from '../../../Loader';

const ImagePropertyEditor = props => {
    if (!props.fileName) {
        return (<div className="annotation-image-upload" onClick={props.onBrowseFile}>
            <div className="annotation-image-upload__icon">
                <i className="material-icons">photo_camera</i>
            </div>
            <div className="annotation-image-upload__info">
                <div className="annotation-image-upload__title">
                    {props.uploading ? 'Uploading...' : 'Upload image'}
                </div>
                <div className="annotation-image-upload__description">Place an image as ground overlay on the map</div>
            </div>
        </div>);
    } else if (props.fileName && props.uploading) {
        return (<div className="annotation-image-upload annotation-image-upload--uploading">
            <Loader text="Uploading..." />
        </div>);
    } else if (props.fileName) {
        const style = {
            backgroundImage: `url("${props.fileName}")`,
        };
        return (<div className="annotation-image-upload annotation-image-upload--with-background" style={style}>
            <div className="annotation-image-upload__icon" onClick={props.onBrowseFile} title="Replace image">
                <i className="material-icons">photo_camera</i>
            </div>
        </div>);
    }
    return null;
};

ImagePropertyEditor.propTypes = {
    fileName: PropTypes.string,
    onBrowseFile: PropTypes.func.isRequired,
    uploading: PropTypes.bool.isRequired,
};
ImagePropertyEditor.defaultProps = {
    fileName: undefined,
};

export default ImagePropertyEditor;
