class Brush {
    constructor(values) {
        if (values) {
            Object.keys(values).forEach(k => {
                if (this.constructor.prototype.hasOwnProperty(k)) {
                    this[k] = values[k];
                }
            });
        }
    }

    get fillColor() {
        return this._fillColor;
    }

    set fillColor(fillColor) {
        this._fillColor = fillColor;
    }

    get fillOpacity() {
        return this._fillOpacity;
    }

    set fillOpacity(fillOpacity) {
        this._fillOpacity = fillOpacity;
    }

    get fillPattern() {
        return this._fillPattern;
    }

    set fillPattern(fillPattern) {
        this._fillPattern = fillPattern;
    }

    get strokeStyle() {
        return this._strokeStyle;
    }

    set strokeStyle(strokeStyle) {
        this._strokeStyle = strokeStyle;
    }

    get strokeWidth() {
        return this._strokeWidth;
    }

    set strokeWidth(strokeWidth) {
        this._strokeWidth = strokeWidth;
    }

    get strokeColor() {
        return this._strokeColor;
    }

    set strokeColor(strokeColor) {
        this._strokeColor = strokeColor;
    }

    get strokeOpacity() {
        return this._strokeOpacity;
    }

    set strokeOpacity(strokeOpacity) {
        this._strokeOpacity = strokeOpacity;
    }

    get strokeLineCap() {
        return this._strokeLineCap;
    }

    set strokeLineCap(strokeLineCap) {
        this._strokeLineCap = strokeLineCap;
    }

    get strokeFill() {
        return this._strokeFill;
    }

    set strokeFill(strokeFill) {
        this._strokeFill = strokeFill;
    }

    get strokeFillOpacity() {
        return this._strokeFillOpacity;
    }

    set strokeFillOpacity(strokeFillOpacity) {
        this._strokeFillOpacity = strokeFillOpacity;
    }

    get strokeFillWidth() {
        return this._strokeFillWidth;
    }

    set strokeFillWidth(strokeFillWidth) {
        this._strokeFillWidth = strokeFillWidth;
    }

    get textSize() {
        return this._textSize;
    }

    set textSize(textSize) {
        this._textSize = textSize;
    }

    get textColor() {
        return this._textColor;
    }

    set textColor(textColor) {
        this._textColor = textColor;
    }

    get textOpacity() {
        return this._textOpacity;
    }

    set textOpacity(textOpacity) {
        this._textOpacity = textOpacity;
    }

    get textBaselineShift() {
        return this._textBaselineShift;
    }

    set textBaselineShift(textBaselineShift) {
        this._textBaselineShift = textBaselineShift;
    }

    get textPaddingLeft() {
        return this._textPaddingLeft;
    }

    set textPaddingLeft(textPaddingLeft) {
        this._textPaddingLeft = textPaddingLeft;
    }

    get textPaddingRight() {
        return this._textPaddingRight;
    }

    set textPaddingRight(textPaddingRight) {
        this._textPaddingRight = textPaddingRight;
    }

    get textLetterCasing() {
        return this._textLetterCasing;
    }

    set textLetterCasing(textLetterCasing) {
        this._textLetterCasing = textLetterCasing;
    }

    get textLetterSpacing() {
        return this._textLetterSpacing;
    }

    set textLetterSpacing(textLetterSpacing) {
        this._textLetterSpacing = textLetterSpacing;
    }

    get textFont() {
        return this._textFont;
    }

    set textFont(textFont) {
        this._textFont = textFont;
    }

    get textLabelPositions() {
        return this._textLabelPositions;
    }

    set textLabelPositions(textLabelPositions) {
        this._textLabelPositions = textLabelPositions;
    }

    get textHaloColor() {
        return this._textHaloColor;
    }

    set textHaloColor(textHaloColor) {
        this._textHaloColor = textHaloColor;
    }

    get textHaloBlur() {
        return this._textHaloBlur;
    }

    set textHaloBlur(textHaloBlur) {
        this._textHaloBlur = textHaloBlur;
    }

    get textHaloOpacity() {
        return this._textHaloOpacity;
    }

    set textHaloOpacity(textHaloOpacity) {
        this._textHaloOpacity = textHaloOpacity;
    }

    get marker() {
        return this._marker;
    }

    set marker(marker) {
        this._marker = marker;
    }

    get minZoom() {
        return this._minZoom;
    }

    set minZoom(minZoom) {
        this._minZoom = minZoom;
    }

    get maxZoom() {
        return this._maxZoom;
    }

    set maxZoom(maxZoom) {
        this._maxZoom = maxZoom;
    }

    equals(that) {
        return (this.fillColor === that.fillColor &&
        (this.fillOpacity === that.fillOpacity || (isNaN(this.fillOpacity) && isNaN(that.fillOpacity))) &&
        (this.fillPattern === that.fillPattern) &&
        (this.strokeStyle === that.strokeStyle) &&
        (this.strokeWidth === that.strokeWidth || (isNaN(this.strokeWidth) && isNaN(that.strokeWidth))) &&
        (this.strokeColor === that.strokeColor) &&
        (this.strokeOpacity === that.strokeOpacity || ((isNaN(this.strokeOpacity)) && isNaN(that.strokeOpacity))) &&
        this.strokeLineCap === that.strokeLineCap &&
        this.strokeFill === that.strokeFill &&
        this.strokeFillOpacity === that.strokeFillOpacity &&
        (this.strokeFillWidth === that.strokeFillWidth || (isNaN(this.strokeFillWidth) && isNaN(that.strokeFillWidth))) &&
        (this.textSize === that.textSize || (isNaN(this.textSize) && isNaN(that.textSize))) &&
        this.textColor === that.textColor &&
        (this.textHaloBlur === that.textHaloBlur || (isNaN(this.textHaloBlur) && isNaN(that.textHaloBlur))) &&
        (this.textOpacity === that.textOpacity || (isNaN(this.textOpacity) && isNaN(that.textOpacity))) &&
        this.textBaselineShift === that.textBaselineShift &&
        this.textPaddingLeft === that.textPaddingLeft &&
        this.textPaddingRight === that.textPaddingRight &&
        this.textLetterCasing === that.textLetterCasing &&
        (this.textLetterSpacing === that.textLetterSpacing || (isNaN(this.textLetterSpacing) && isNaN(that.textLetterSpacing))) &&
        this.textFont === that.textFont &&
        this.textLabelPositions === that.textLabelPositions &&
        this.textHaloColor === that.textHaloColor &&
        (this.textHaloOpacity === that.textHaloOpacity || (isNaN(this.textHaloOpacity) && isNaN(that.textHaloOpacity))) &&
        this.marker === that.marker &&
        (this.minZoom === that.minZoom || (isNaN(this.minZoom) && isNaN(that.minZoom))) &&
        (this.maxZoom === that.maxZoom || (isNaN(this.maxZoom) && isNaN(that.maxZoom))));
    }

    clone() {
        return new Brush({
            fillColor: this.fillColor,
            fillOpacity: this.fillOpacity,
            fillPattern: this.fillPattern,
            strokeStyle: this.strokeStyle,
            strokeWidth: this.strokeWidth,
            strokeColor: this.strokeColor,
            strokeOpacity: this.strokeOpacity,
            strokeLineCap: this.strokeLineCap,
            strokeFill: this.strokeFill,
            strokeFillOpacity: this.strokeFillOpacity,
            strokeFillWidth: this.strokeFillWidth,
            textSize: this.textSize,
            textColor: this.textColor,
            textOpacity: this.textOpacity,
            textBaselineShift: this.textBaselineShift,
            textPaddingLeft: this.textPaddingLeft,
            textPaddingRight: this.textPaddingRight,
            textLetterCasing: this.textLetterCasing,
            textLetterSpacing: this.textLetterSpacing,
            textFont: this.textFont,
            textLabelPositions: this.textLabelPositions,
            textHaloColor: this.textHaloColor,
            textHaloBlur: this.textHaloBlur,
            textHaloOpacity: this.textHaloOpacity,
            marker: this.marker,
            minZoom: this.minZoom,
            maxZoom: this.maxZoom,
        });
    }

    toJSON() {
        return {
            fillColor: this.fillColor,
            fillOpacity: this.fillOpacity,
            fillPattern: this.fillPattern,
            strokeStyle: this.strokeStyle,
            strokeWidth: this.strokeWidth,
            strokeColor: this.strokeColor,
            strokeOpacity: this.strokeOpacity,
            strokeLineCap: this.strokeLineCap,
            strokeFill: this.strokeFill,
            strokeFillOpacity: this.strokeFillOpacity,
            strokeFillWidth: this.strokeFillWidth,
            textSize: this.textSize,
            textColor: this.textColor,
            textOpacity: this.textOpacity,
            textBaselineShift: this.textBaselineShift,
            textPaddingLeft: this.textPaddingLeft,
            textPaddingRight: this.textPaddingRight,
            textLetterCasing: this.textLetterCasing,
            textLetterSpacing: this.textLetterSpacing,
            textFont: this.textFont,
            textLabelPositions: this.textLabelPositions,
            textHaloColor: this.textHaloColor,
            textHaloBlur: this.textHaloBlur,
            textHaloOpacity: this.textHaloOpacity,
            marker: this.marker,
            minZoom: this.minZoom,
            maxZoom: this.maxZoom,
        };
    }
}

export default Brush;
