'use strict';var ref = require('../types');
var ObjectType = ref.ObjectType;
var ValueType = ref.ValueType;
var StringType = ref.StringType;
var NumberType = ref.NumberType;
var BooleanType = ref.BooleanType;
var RuntimeError = require('../runtime_error');
var ref$1 = require('../types');
var checkSubtype = ref$1.checkSubtype;
var toString = ref$1.toString;
var ref$2 = require('../values');
var typeOf = ref$2.typeOf;
var types = {
    string: StringType,
    number: NumberType,
    boolean: BooleanType,
    object: ObjectType
};
var Assertion = function Assertion(type, args) {
    this.type = type;
    this.args = args;
};
Assertion.parse = function parse(args, context) {
    if (args.length < 2) {
        return context.error('Expected at least one argument.');
    }
    var name = args[0];
    var type = types[name];
    var parsed = [];
    for (var i = 1; i < args.length; i++) {
        var input = context.parse(args[i], i, ValueType);
        if (!input) {
            return null;
        }
        parsed.push(input);
    }
    return new Assertion(type, parsed);
};
Assertion.prototype.evaluate = function evaluate(ctx) {
    var this$1 = this;
    for (var i = 0; i < this.args.length; i++) {
        var value = this$1.args[i].evaluate(ctx);
        var error = checkSubtype(this$1.type, typeOf(value));
        if (!error) {
            return value;
        } else if (i === this$1.args.length - 1) {
            throw new RuntimeError('Expected value to be of type ' + toString(this$1.type) + ', but found ' + toString(typeOf(value)) + ' instead.');
        }
    }
    return null;
};
Assertion.prototype.eachChild = function eachChild(fn) {
    this.args.forEach(fn);
};
module.exports = Assertion;