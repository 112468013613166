import RendererType from '../../enums/RendererType';
import FilterComparisonType from '../../enums/FilterComparisonType';
import VisualizationType from '../../enums/VisualizationType';
import VariableValueType from '../../enums/VariableValueType';
import DragonflySource from '../../objects/DragonflySource';
import { parseFieldName, optimizeStopFunction, getDotValue } from '../../helpers/Util';
import AppConfig from '../../appConfig';
import uuid from 'node-uuid';

const parseCSSColor = require('csscolorparser').parseCSSColor;

class RendererData {
    constructor(dataTheme, mapConfig) {
        this._dataTheme = dataTheme;
        this._renderer = dataTheme.rendering[0];
        this._mapConfig = mapConfig;

        this._dragonflySource = undefined;
        this._dragonflyAutoSource = undefined;
        this._dragonflyLayers = [];
        this._dataDragonflyLayers = [];
        this._highlightDragonflyLayer = undefined;
        this._dragonflyLayersMasks = [];
        this._mapClasses = [];

        this._createSource();
        this._createAutoSource();
        this._createMapClasses();
        this._createDataLayers();
        this._createHighlightLayer();
        this._createDataFilterBackgroundLayers();
        this._createDragonflyLayersMasks();
    }

    get source() {
        return this._dragonflySource;
    }

    get autoSource() {
        return this._dragonflyAutoSource;
    }

    get layers() {
        return this._dragonflyLayers;
    }

    get dataLayers() {
        return this._dataDragonflyLayers;
    }

    get highlightLayer() {
        return this._highlightDragonflyLayer;
    }

    get layersMasks() {
        return this._dragonflyLayersMasks;
    }

    get dataFilterHelperLayer() {
        return this._dataFilterHelperLayer;
    }

    get mapClasses() {
        return this._mapClasses;
    }

    set mapClasses(mapClasses) {
        this._mapClasses = mapClasses;
    }

    get dataMinZoom() {
        return Math.min(...this._dragonflyAutoSource.map(autoSourceLayer => autoSourceLayer.minzoom));
    }

    get activeSurveyName() {
        return this._renderer.fieldList.fields[0].surveyName;
    }

    get activeDatasetAbbrevation() {
        return this._renderer.fieldList.fields[0].datasetAbbreviation;
    }

    applyColorPaletteUpdate() {
        switch (this._renderer.type) {
        case RendererType.DOT_DENSITY:
            this._dragonflyLayers[0].paint['dotdensity-color'] = this._renderer.symbols[0].brushes[0].textColor;
            break;
        case RendererType.VALUE_DOT_DENSITY:
            this._renderer.dotValueFieldNames.forEach((fieldName, idx) => {
                const dl = this._dragonflyLayers.find(l => l.metadata.fieldQualifiedName === fieldName);
                dl.paint['dotdensity-color'] = this._renderer.symbols[idx].brushes[0].textColor;
            });
            break;
        case RendererType.BUBBLE:
            this._applyRulesToDragonflyLayers();
            break;
        case RendererType.MULTI_VALUE:
        case RendererType.VALUE:
            this._applyRulesToDragonflyLayers();
            break;
        }
    }

    applyBubbleSizeFactorUpdate() {
        this._dataDragonflyLayers.forEach(dl => {
            dl.paint['bubble-radius-multiplier'] = this._renderer.bubbleRadiusMultiplier;
        });
    }

    applyDotValueHintUpdate() {
        const fieldNames = this._renderer.dotValueFieldNames || [this._renderer.dotValueFieldName];
        fieldNames.forEach(fieldName => {
            const parsedFieldName = parseFieldName(fieldName);
            const dl = this._dataDragonflyLayers.find(l => l.metadata.fieldQualifiedName === fieldName);
            dl.layout['dotdensity-count'].stops.forEach(stop => {
                const zoom = stop[0];
                const dotValue = getDotValue(zoom, this._renderer.dotDensityValueHint);
                stop[1] = `floor({${parsedFieldName.variableGuid}} / (${dotValue}))`;
            });
        });
    }

    applyDataFilter(dataFilter) {
        if (dataFilter.filter) {
            // Go through each data layer and add the filter
            this._dataDragonflyLayers.forEach(dataLayer => {
                dataLayer.filter = dataFilter.filter;
            });
            // Add filter to the HelperLayer
            this._dataFilterHelperLayer.filter = dataFilter.helperLayerFilter;
        } else {
            this._dataDragonflyLayers.forEach(dataLayer => {
                delete dataLayer.filter;
            });
            delete this._highlightDragonflyLayer.filter;
            delete this._dataFilterHelperLayer.filter;
        }
    }

    addFilterFiledsToDataSet(fields) {
        const dataSources = Object.values(this._mapConfig.dataSources);
        // Adding the fileds in dataset list of fields
        fields.forEach(field => {
            for (let i = 0; i < dataSources.length; i += 1) {
                const dataSource = dataSources[i];
                const dataset = dataSource.findDataset(field.surveyName, field.datasetAbbreviation);
                if (this._dataDragonflyLayers[0].type === 'bubble') {
                    this._dragonflySource
                        .getLayer(`${dataSource.layerId}p`)
                        .getDataset(dataset.id)
                        .push(field.fieldName)
                        .push(field.baseFieldName);
                }
                this._dragonflySource
                    .getLayer(`${dataSource.layerId}`)
                    .getDataset(dataset.id)
                    .push(field.fieldName)
                    .push(field.baseFieldName);
            }
        });
    }

    highlightRule(ruleIdx) {
        const isMulti = this._renderer.type === RendererType.MULTI_VALUE;
        const nullIdx = isMulti ? this._renderer.nullDataRuleIndex[0] : this._renderer.nullDataRuleIndex;
        const color = this._renderer.type === RendererType.BUBBLE ? 'rgba(0,0,0,0)' : 'rgb(240,240,240)';
        const numberOfVariables = isMulti ? this._renderer.rules.length : 1;
        const rule = this._renderer.rules[ruleIdx];

        let stopsToRuleIdx = ruleIdx;
        if (nullIdx === -1) {
            stopsToRuleIdx += 1;
        } else if (nullIdx !== -1 && nullIdx === ruleIdx) {
            stopsToRuleIdx = 0;
        }

        rule.highlighted = true;

        this._dataDragonflyLayers.forEach(dl => {
            const colorProperty = dl.paint[`${dl.type}-color`];
            const stopsPerVariable = colorProperty.stops.length / numberOfVariables;
            colorProperty.stops = colorProperty.stops.map((stop, idx) => {
                // if multi variable then stops for each of the variables will be adjacent to each other
                // when we pass the stop to highlight for one variable we need to set highlight stop idx for next variable
                if (idx > stopsToRuleIdx) {
                    stopsToRuleIdx += stopsPerVariable;
                }
                if (idx !== stopsToRuleIdx && stop[0] !== undefined) {
                    stop[1] = color;
                }
                if (idx === stopsToRuleIdx && dl.type === 'bubble') {
                    const clr = parseCSSColor(stop[1]);
                    stop[1] = `rgba(${clr[0]}, ${clr[1]}, ${clr[2]}, 0)`;
                }
                return stop;
            });
        });
    }

    highlightMultiRule(ruleIdx, rulesIdx) {
        const computedVariableGuid = parseFieldName(this._renderer.valuesFieldsNames[rulesIdx]).variableGuid;
        const selectedField = this._renderer.fieldList.fields.find(f => f.fieldName === computedVariableGuid);
        const variableGuid = parseFieldName(selectedField.fieldNumerator || computedVariableGuid).variableGuid;
        const nullIdx = this._renderer.nullDataRuleIndex;
        const numberOfVariables = this._renderer.rules.length;
        const rule = this._renderer.rules[rulesIdx][ruleIdx];
        const color = 'rgb(240,240,240)';

        let stopsToRuleIdx = ruleIdx;
        if (nullIdx === -1) {
            stopsToRuleIdx += 1;
        } else if (nullIdx !== -1 && nullIdx === ruleIdx) {
            stopsToRuleIdx = 0;
        }

        rule.highlighted = true;

        this._dataDragonflyLayers.forEach(dl => {
            const colorProperty = dl.paint[`${dl.type}-color`];
            colorProperty.property = variableGuid;
            const stopsPerVariable = colorProperty.stops.length / numberOfVariables;
            const newStops = [];
            colorProperty.stops.forEach((stop, idx) => {
                if (idx > stopsToRuleIdx) {
                    stopsToRuleIdx += stopsPerVariable;
                }
                if (idx !== stopsToRuleIdx && stop[0] !== undefined) {
                    stop[1] = color;
                }
                if (idx >= (stopsPerVariable * rulesIdx) && idx < (stopsPerVariable * (rulesIdx + 1))) newStops.push(stop);
            });
            colorProperty.stops = newStops;
        });
    }

    applyCutpointsUpdate() {
        if (this._dataTheme.isShadedVisualization || this._dataTheme.isBubblesPercent) {
            this._applyRulesToDragonflyLayers();
        }
    }

    applyDataLayersOpacity(opacity) {
        this.dataLayers.forEach(dl => (dl.paint[`${dl.type}-opacity`] = opacity));
        this._createDragonflyLayersMasks();
    }

    _createSource() {
        this._dragonflySource = new DragonflySource([AppConfig.constants.backends.tiles]);
        this._dragonflySource.id = `socialexplorerdata-${uuid.v4()}`;
        Object.values(this._mapConfig.dataSources).forEach(dataSource => {
            this._dragonflySource.createLayer(dataSource.layerId);
            this._renderer.fieldList.fields.forEach(field => {
                if (field.isComputed || field.fieldName.indexOf('__') !== -1) return;

                if (field.isVirtual) {
                    const dependencies = field.expression.match(/{[a-zA-Z0-9-_]+}/g);
                    const fieldName = `${field.fieldName}->${field.expression}`;

                    if (dependencies !== null) {
                        dependencies.forEach(dependency => {
                            const cleanDependency = dependency.replace('{', '').replace('}', '');
                            this._dragonflySource.getLayer(dataSource.layerId)
                                .getDataset(dataSource.findDataset(field.surveyName, field.datasetAbbreviation).id)
                                .push(cleanDependency);

                            if (this._renderer.type === RendererType.BUBBLE) {
                                this._dragonflySource.getLayer(`${dataSource.layerId}p`)
                                    .getDataset(dataSource.findDataset(field.surveyName, field.datasetAbbreviation).id)
                                    .push(cleanDependency);
                            }
                        });
                    }

                    if (this._renderer.type === RendererType.BUBBLE) {
                        this._dragonflySource.getLayer(`${dataSource.layerId}p`)
                            .getDataset(dataSource.findDataset(field.surveyName, field.datasetAbbreviation).id)
                            .push(fieldName);
                    }

                    this._dragonflySource.getLayer(dataSource.layerId)
                        .getDataset(dataSource.findDataset(field.surveyName, field.datasetAbbreviation).id)
                        .push(fieldName);
                    return;
                }

                if (this._renderer.type === RendererType.BUBBLE) {
                    this._dragonflySource.getLayer(`${dataSource.layerId}p`)
                        .getDataset(dataSource.findDataset(field.surveyName, field.datasetAbbreviation).id)
                        .push(field.fieldName);
                }
                this._dragonflySource.getLayer(dataSource.layerId)
                    .getDataset(dataSource.findDataset(field.surveyName, field.datasetAbbreviation).id)
                    .push(field.fieldName);
            });
            const dragonflySourceLayer = this._dragonflySource.layers[dataSource.layerId];
            Object.keys(dragonflySourceLayer.datasets).map(id => dragonflySourceLayer.datasets[id]).forEach(ds => {
                if (ds.id > 0) {
                    ds.push(dataSource.datasets[ds.id].primaryKeyField);
                }
            });
        });
    }

    _createAutoSource() {
        this._dragonflyAutoSource = Object.values(this._mapConfig.dataSources).map(dataSource => {
            let autoSourceId = dataSource.layerId;
            if (this._renderer.type === RendererType.BUBBLE) {
                autoSourceId = `${autoSourceId}p`;
            }
            return {
                type: 'fill',
                id: dataSource.summaryLevel.id,
                source: this._dragonflySource.id,
                minzoom: dataSource.summaryLevel.minzoom,
                maxzoom: dataSource.summaryLevel.maxzoom,
                'source-layer': autoSourceId,
            };
        });
    }

    _createDataLayers() {
        switch (this._renderer.type) {
        case RendererType.MULTI_VALUE: {
            const rule = this._renderer.rules[0].find((r, idx) => idx !== this._renderer.insufficientDataRuleIndex[0] && idx !== this._renderer.nullDataRuleIndex[0]);
            this._createFillDragonflyLayer(rule.symbols[0]);
            this._applyRulesToDragonflyLayers();
            break;
        }
        case RendererType.VALUE: {
            const rule = this._renderer.rules.find((r, idx) => idx !== this._renderer.insufficientDataRuleIndex && idx !== this._renderer.nullDataRuleIndex);
            this._createFillDragonflyLayer(rule.symbols[0]);
            this._applyRulesToDragonflyLayers();
            break;
        }
        case RendererType.BUBBLE: {
            const rule = this._renderer.rules.find((r, idx) => idx !== this._renderer.insufficientDataRuleIndex && idx !== this._renderer.nullDataRuleIndex);
            this._createBubbleDragonflyLayer(rule.symbols[0]);
            this._applyRulesToDragonflyLayers();
            break;
        }
        case RendererType.DOT_DENSITY:
        case RendererType.VALUE_DOT_DENSITY:
            this._renderer.symbols.forEach((symbol, symbolIdx) => {
                this._createDotDensityDragonflyLayer(symbol, symbolIdx);
            });
            break;
        case RendererType.REPORT:
            this._renderer.rules.forEach(rule => {
                const dragonflyFilter = RendererData._createDragonflyFilterFromRule(
                    this._renderer.rules[this._renderer.insufficientDataRuleIndex],
                    this._renderer.rules[this._renderer.nullDataRuleIndex],
                    rule);
                this._createFillDragonflyLayer(rule.symbols[0], dragonflyFilter);
            });
            this._dataDragonflyLayers.forEach((dl, idx) => {
                dl.id = `report-data-${idx}`;
                const fillColor = dl.paint['fill-color'];
                dl.paint['fill-color'] = { stops: [[21, fillColor]], type: 'interval' };
            });
            break;
        }
        this._dataDragonflyLayers.forEach(dl => this._dragonflyLayers.push(dl));
    }

    _createHighlightLayer() {
        this._highlightDragonflyLayer = {
            id: 'data-highlight-layer',
            metadata: {
                isHighlightLayer: true,
                socialexplorer: {
                    'layer-group-id': this._mapConfig.dataPlaceholder.metadata.socialexplorer['layer-group-id'],
                },
            },
            type: 'line',
            paint: {
                'line-color': 'rgba(0,0,0,0)',
            },
            layout: {
                visibility: this._mapConfig.dataPlaceholder.layout.visibility,
            },
            source: this._dragonflySource.id,
            'source-layer': '0',
        };
        this._highlightDragonflyLayer['auto-source'] = this._dragonflyAutoSource.map(autoSource => Object.assign({},
            autoSource,
            { 'source-layer': autoSource['source-layer'].replace('p', '') }));
        this._dragonflyLayers.push(this._highlightDragonflyLayer);
    }

    _createMapClasses() {
        switch (this._dataTheme.visualizationType) {
        case VisualizationType.SHADED_AREA:
            this._mapClasses = ['shaded'];
            break;
        case VisualizationType.BUBBLES:
            this._mapClasses = ['bubbles'];
            break;
        case VisualizationType.DOT_DENSITY:
            this._mapClasses = ['dot-density'];
            break;
        }
    }

    _createDataFilterBackgroundLayers() {
        const dataLayer = this.dataLayers[0];
        this._dataFilterHelperLayer = {
            ...dataLayer,
            id: 'filter-data-negative-background-layer',
            paint: {
                'fill-pattern': 'line',
            },
            layout: {
                visibility: 'visible',
            },
            type: 'fill',
        };
        this._dataFilterHelperLayer['auto-source'] = this._dragonflyAutoSource.map(autoSource => Object.assign({},
            autoSource,
            { 'source-layer': autoSource['source-layer'].replace('p', '') }));
    }

    _createDragonflyLayersMasks() {
        this._dragonflyLayersMasks = [];
        const dl = this._dataDragonflyLayers[this._dataDragonflyLayers.length - 1];
        if (this._dataTheme.isShadedVisualization && this._mapConfig.streetsLayer && dl.paint['fill-opacity'] === 1) {
            const maskBlendFunc = value => {
                const opacity = 0.9;
                for (let i = 0; i < value.stops.length; i += 1) {
                    const color = parseCSSColor(value.stops[i][1]);
                    for (let c = 0; c < 3; c += 1) {
                        color[c] = Math.min(Math.round(color[c] / opacity), 255);
                    }
                    color[3] = opacity;
                    value.stops[i][1] = `rgba(${color[0]},${color[1]}, ${color[2]},${color[3]})`;
                }
            };
            const parentBlendFunc = value => {
                const opacity = 0.9;
                for (let i = 0; i < value.stops.length; i += 1) {
                    const color = parseCSSColor(value.stops[i][1]);
                    const maxClrValue = opacity * 255;
                    for (let c = 0; c < 3; c += 1) {
                        color[c] = Math.round(color[c] > maxClrValue ? (color[c] - maxClrValue) / (1.0 - opacity) : 0);
                    }
                    value.stops[i][1] = `rgb(${color[0]},${color[1]}, ${color[2]})`;
                }
            };
            const mask1 = {
                parentId: dl.id,
                predecessorId: dl.id,
                minZoom: this._mapConfig.streetsLayer.minzoom,
                maxZoom: this._mapConfig.streetsLayer.maxzoom,
                paint: {
                    'fill-color': {
                        maskBlendFunc: parentBlendFunc,
                    },
                },
            };
            const mask2 = {
                parentId: dl.id,
                predecessorId: this._mapConfig.streetsLayer.id,
                minZoom: this._mapConfig.streetsLayer.minzoom,
                maxZoom: this._mapConfig.streetsLayer.maxzoom,
                paint: {
                    'fill-color': {
                        maskBlendFunc,
                    },
                },
            };
            this._dragonflyLayersMasks.push(mask1);
            this._dragonflyLayersMasks.push(mask2);
        }
        if (this._dataTheme.isBubblesVisualization && this._dataTheme.bubbleValueType === VariableValueType.PERCENT) {
            const maskBlendFunc = value => {
                let visibleColorsCount = 0;
                let visibleColorIdx, visibleColor;
                for (let i = 0; i < value.stops.length; i += 1) {
                    const color = parseCSSColor(value.stops[i][1]);
                    if (color[0] > 0 && value.stops[i][0] !== undefined) {
                        visibleColorsCount += 1;
                        visibleColorIdx = i;
                        visibleColor = color;
                    }
                    value.stops[i][1] = 'rgba(0,0,0,0)';
                }
                if (visibleColorsCount === 1) {
                    value.stops[visibleColorIdx][1] = `rgba(${visibleColor[0]}, ${visibleColor[1]}, ${visibleColor[2]}, 0.8)`;
                }
            };
            const mask = {
                parentId: dl.id,
                predecessorId: this._dragonflyLayers[this._dragonflyLayers.length - 1].id,
                paint: {
                    'bubble-color': {
                        maskBlendFunc,
                    },
                },
            };
            this._dragonflyLayersMasks.push(mask);
        }
    }

    _applyRulesToDragonflyLayers() {
        let rules, insuffRuleIdx, nullRuleIdx;
        if (this._renderer.type === RendererType.MULTI_VALUE) {
            rules = this._renderer.rules;
            insuffRuleIdx = this._renderer.insufficientDataRuleIndex;
            nullRuleIdx = this._renderer.nullDataRuleIndex;
        } else {
            rules = [this._renderer.rules];
            insuffRuleIdx = [this._renderer.insufficientDataRuleIndex];
            nullRuleIdx = [this._renderer.nullDataRuleIndex];
        }
        let colorProperty;
        rules.forEach((rulesArray, index) => {
            const insufficientDataRule = rulesArray[insuffRuleIdx[index]];
            const nullDataRule = rulesArray[nullRuleIdx[index]];
            const rule = rulesArray.find(r => r !== nullDataRule && r !== insufficientDataRule);
            const valueColumn = parseFieldName(rule.filter.fieldName).variableGuid;
            const columnField = this._renderer.fieldList.fields.find(field => field.fieldName === valueColumn);
            const tempColorProperty = RendererData._createDragonflyFeaturePaintProperty(valueColumn,
                columnField,
                insufficientDataRule,
                nullDataRule,
                rulesArray);
            if (!colorProperty) colorProperty = tempColorProperty;
            else {
                colorProperty.property = `${colorProperty.property},${tempColorProperty.property}`;
                colorProperty.stops = colorProperty.stops.concat(tempColorProperty.stops);
            }
        });
        if (this._renderer.type === RendererType.BUBBLE) {
            colorProperty['computed-options']['alpha-threshold'] = 0.75;
        }
        this._dataDragonflyLayers.forEach(dl => {
            dl.paint[`${dl.type}-color`] = colorProperty;
        });
    }

    _createFillDragonflyLayer(symbol, dragonflyFilter) {
        let fillColor = {
            base: 1.5,
            stops: [],
        };

        let fillOutlineColor = {
            base: 1.5,
            stops: [],
        };

        let fillPattern = {
            base: 1.5,
            stops: [],
        };

        symbol.brushes.forEach(brush => {
            const maxZoom = brush.maxZoom || 20;
            if (brush.fillColor) fillColor.stops.push([maxZoom, chroma(brush.fillColor).alpha(brush.fillOpacity).css()]);
            if (brush.strokeColor && brush.strokeWidth > 0) fillOutlineColor.stops.push([maxZoom, chroma(brush.strokeColor).alpha(brush.strokeWidth).css()]);
            if (brush.fillPattern) fillPattern.stops.push([maxZoom, brush.fillPattern]);
        });

        const dragonflyLayer = {
            id: `fill-data-${uuid.v4()}`,
            metadata: {
                isDataLayer: true,
                socialexplorer: {
                    'layer-group-id': this._mapConfig.dataPlaceholder.id,
                },
            },
            'auto-source': this._dragonflyAutoSource,
            source: this._dragonflySource.id,
            'source-layer': '0',
            type: 'fill',
            layout: {
                visibility: this._mapConfig.dataPlaceholder.layout.visibility,
            },
            paint: {
                'fill-opacity': 1,
                'fill-outline-color': 'rgba(0,0,0,0)',
            },
        };

        fillColor = optimizeStopFunction(fillColor);
        if (fillColor) dragonflyLayer.paint['fill-color'] = fillColor;

        fillOutlineColor = optimizeStopFunction(fillOutlineColor);
        if (fillOutlineColor) dragonflyLayer.paint['fill-outline-color'] = fillOutlineColor;

        fillPattern = optimizeStopFunction(fillPattern);
        if (fillPattern) dragonflyLayer.paint['fill-pattern'] = fillPattern;

        if (dragonflyFilter) dragonflyLayer.filter = dragonflyFilter;

        this._dataDragonflyLayers.push(dragonflyLayer);
    }

    _createBubbleDragonflyLayer(symbol) {
        let bubbleOutlineColor = {
            base: 1.5,
            stops: [],
        };

        symbol.brushes.forEach(brush => {
            if (brush.maxZoom) {
                if (brush.strokeColor) bubbleOutlineColor.stops.push([brush.maxZoom, chroma(brush.strokeColor).alpha(brush.strokeOpacity).css()]);
            }
        });

        const parsedFieldName = parseFieldName(this._renderer.bubbleSizeFieldName);
        let bubbleRadiusFormula = `sqrt(abs({${parsedFieldName.variableGuid}}))`;
        if (this._dataTheme.isChangeOverTimeApplied) {
            const computedField = this._renderer.fieldList.fields.find(f => f.isChangeOverTimeField);
            const fieldNumerator = parseFieldName(computedField.fieldNumerator).variableGuid;
            const fieldDenominator = parseFieldName(computedField.fieldDenominator).variableGuid;
            bubbleRadiusFormula = `sqrt(abs({${fieldNumerator}} - {${fieldDenominator}}))`;
        }

        const dragonflyLayer = {
            id: `${parsedFieldName.variableGuid}_bubble`,
            metadata: {
                isDataLayer: true,
                socialexplorer: {
                    'layer-group-id': this._mapConfig.dataPlaceholder.id,
                },
            },
            'auto-source': this._dragonflyAutoSource,
            source: this._dragonflySource.id,
            'source-layer': '0p',
            type: 'bubble',
            layout: {
                'bubble-radius': bubbleRadiusFormula,
                visibility: this._mapConfig.dataPlaceholder.layout.visibility,
            },
            paint: {
                'bubble-outline-width': 1,
                'bubble-radius-multiplier': this._renderer.bubbleRadiusMultiplier,
                'bubble-max-radius': this._renderer.maxBubbleSize,
                'bubble-max-radius-outline-color': 'rgba(88,88,88, 0.9)',
                'bubble-max-radius-outline-width': 4,
            },
        };

        bubbleOutlineColor = optimizeStopFunction(bubbleOutlineColor);
        if (bubbleOutlineColor) dragonflyLayer.paint['bubble-outline-color'] = bubbleOutlineColor;

        this._dataDragonflyLayers.push(dragonflyLayer);
    }

    _createDotDensityDragonflyLayer(symbol, symbolIdx) {
        let fieldName = this._renderer.dotValueFieldName;
        if (this._renderer.dotValueFieldNames) {
            fieldName = this._renderer.dotValueFieldNames[symbolIdx];
        }
        const parsedFieldName = parseFieldName(fieldName);

        const dragonflyLayer = {
            id: `${parsedFieldName.variableGuid}_dotdensity`,
            metadata: {
                symbolIndex: symbolIdx,
                fieldQualifiedName: fieldName,
                isDataLayer: true,
                socialexplorer: {
                    'layer-group-id': this._mapConfig.dataPlaceholder.id,
                },
            },
            'auto-source': this._dragonflyAutoSource,
            source: this._dragonflySource.id,
            'source-layer': '0',
            type: 'dotdensity',
            layout: {
                'dotdensity-count': {
                    stops: [],
                },
                visibility: this._mapConfig.dataPlaceholder.layout.visibility,
            },
            paint: {
                'dotdensity-blur': 0,
                'dotdensity-radius': symbol.brushes[0].textSize * 2,
                'dotdensity-opacity': 0.8,
                'dotdensity-color': symbol.brushes[0].textColor,
            },
        };

        for (let zoom = 0; zoom <= 20; zoom += 1) {
            const dotValue = getDotValue(zoom, this._renderer.dotDensityValueHint);
            const formula = `floor({${parsedFieldName.variableGuid}} / (${dotValue}))`;
            dragonflyLayer.layout['dotdensity-count'].stops.push([zoom, formula]);
        }

        this._dataDragonflyLayers.push(dragonflyLayer);
    }

    static _createDragonflyFilterFromRule(insufficientDataRule, nullDataRule, rule) {
        const column = parseFieldName(rule.filter.fieldName).variableGuid;
        const dragonflyLayerFilter = ['all'];

        if (insufficientDataRule && rule !== insufficientDataRule && rule !== nullDataRule) {
            dragonflyLayerFilter.push([
                `>${insufficientDataRule.filter.inclusiveTo ? '' : '='}`,
                parseFieldName(insufficientDataRule.filter.fieldName).variableGuid,
                insufficientDataRule.filter.to,
            ]);
        }

        if (nullDataRule && rule !== nullDataRule) {
            const nullDataRuleColumn = parseFieldName(nullDataRule.filter.fieldName).variableGuid;

            dragonflyLayerFilter.push(['all',
                ['!=', nullDataRuleColumn, undefined],
                ['!=', nullDataRuleColumn, ''],
            ]);
        }

        if (rule.filter.comparisonType === FilterComparisonType.MATCH_NULL) {
            dragonflyLayerFilter.push(['any',
                ['==', column, undefined],
                ['==', column, ''],
            ]);
        } else if (rule.filter.comparisonType === FilterComparisonType.MATCH_VALUE_NUM) {
            if (rule.filter.valueNum) {
                dragonflyLayerFilter.push(['==', column, rule.filter.valueNum]);
            }
        } else if (rule.filter.comparisonType === FilterComparisonType.MATCH_VALUE_STR) {
            if (rule.filter.valueStr) {
                dragonflyLayerFilter.push(['==', column, rule.filter.valueStr]);
            }
        } else if (rule.filter.comparisonType === FilterComparisonType.MATCH_ANY_VALUE) {
            if (rule.filter.values) {
                const filterArray = ['in', column];
                rule.filter.values.forEach(v => filterArray.push(v));
                dragonflyLayerFilter.push(filterArray);
            }
        } else {
            if (rule.filter.value) {
                dragonflyLayerFilter.push(['==', column, rule.filter.value]);
            }

            if (rule.filter.from) {
                dragonflyLayerFilter.push(['>=', column, rule.filter.from]);
            }

            if (rule.filter.to) {
                dragonflyLayerFilter.push([`<${rule.filter.inclusiveTo ? '=' : ''}`, column, rule.filter.to]);
            }
        }
        return dragonflyLayerFilter;
    }

    static _createDragonflyFeaturePaintProperty(valueColumn, columnField, insufficientDataRule, nullDataRule, rules) {
        const property = { property: valueColumn, stops: [], type: 'dragonfly-categorical', 'computed-options': {} };
        // add null data rule
        if (nullDataRule) {
            const brush = nullDataRule.symbols[0].brushes[0];
            property.stops.push([undefined, chroma(brush.fillColor).alpha(brush.fillOpacity).css()]);
        } else if (!nullDataRule && insufficientDataRule) {
            const brush = insufficientDataRule.symbols[0].brushes[0];
            property.stops.push([undefined, chroma(brush.fillColor).alpha(brush.fillOpacity).css()]);
        } else {
            property.stops.push([undefined, 'rgb(224,224,224)']);
        }
        if (insufficientDataRule) {
            const brush = insufficientDataRule.symbols[0].brushes[0];
            property.stops.push([-Number.MAX_SAFE_INTEGER, chroma(brush.fillColor).alpha(brush.fillOpacity).css()]);

            property['computed-options']['insufficient-threshold'] = insufficientDataRule.filter.to;
            property['computed-options']['insufficient-variable'] = parseFieldName(insufficientDataRule.filter.fieldName).variableGuid;
        }

        rules.forEach(rule => {
            const brush = rule.symbols[0].brushes[0];
            if (rule !== insufficientDataRule && rule !== nullDataRule) {
                switch (rule.filter.comparisonType) {
                case FilterComparisonType.MATCH_NULL:
                    property.stops.push([undefined, chroma(brush.fillColor).alpha(brush.fillOpacity).css()]);
                    property.stops.push(['', chroma(brush.fillColor).alpha(brush.fillOpacity).css()]);
                    break;
                case FilterComparisonType.MATCH_VALUE_NUM:
                    if (rule.filter.valueNum !== undefined) {
                        property.stops.push([rule.filter.valueNum, chroma(brush.fillColor).alpha(brush.fillOpacity)
                                                                                          .css()]);
                    }
                    break;
                case FilterComparisonType.MATCH_VALUE_STR:
                    if (rule.filter.valueStr !== undefined) {
                        property.stops.push([rule.filter.valueStr, chroma(brush.fillColor).alpha(brush.fillOpacity)
                                                                                          .css()]);
                    }
                    break;
                case FilterComparisonType.MATCH_ANY_VALUE:
                    if (rule.filter.values !== undefined) {
                        rule.filter.values.forEach(v => property.stops.push([v, chroma(brush.fillColor)
                            .alpha(brush.fillOpacity).css()]));
                    }
                    break;
                default:
                    if (rule.filter.value !== undefined) {
                        property.stops.push([rule.filter.value, chroma(brush.fillColor).alpha(brush.fillOpacity)
                                                                                       .css()]);
                    } else if (rule.filter.to === undefined) {
                        property.stops.push([Number.MAX_SAFE_INTEGER, chroma(brush.fillColor).alpha(brush.fillOpacity).css()]);
                        property.type = 'dragonfly-interval';
                    } else {
                        property.stops.push([rule.filter.to, chroma(brush.fillColor).alpha(brush.fillOpacity).css()]);
                        property.type = 'dragonfly-interval';
                    }
                }
            }
        });

        if (columnField.isComputed) {
            property.property = parseFieldName(columnField.fieldNumerator).variableGuid;
            property['computed-options']['is-computed'] = true;
            switch (columnField.computeFunction) {
            case 'COMPUTE_PERCENT':
                property['computed-options'].function = 'percent';
                property['computed-options']['percent:denominator'] = parseFieldName(columnField.fieldDenominator).variableGuid;
                break;
            case 'COMPUTE_RATIO':
                property['computed-options'].function = 'ratio';
                property['computed-options']['ratio:denominator'] = parseFieldName(columnField.fieldDenominator).variableGuid;
                break;
            case 'COMPUTE_CHANGE':
                property['computed-options'].function = 'change-difference';
                property['computed-options']['multiply:factor'] = columnField.fieldMultiplier;
                property['computed-options']['change-difference:from'] = parseFieldName(columnField.fieldDenominator).variableGuid;
                break;
            case 'COMPUTE_MULTIPLY':
                property['computed-options'].function = 'multiply';
                property['computed-options']['multiply:factor'] = columnField.fieldMultiplier;
                break;
            case 'COMPUTE_CHANGE_PERCENT':
                property['computed-options'].function = 'change-percent';
                property['computed-options']['multiply:factor'] = columnField.fieldMultiplier;
                property['computed-options']['change-percent:from'] = parseFieldName(columnField.fieldDenominator).variableGuid;
                break;
            case 'COMPUTE_CHANGE_COMPOSITION':
                property['computed-options'].function = 'change-composition';
                property['computed-options']['change-composition:parent'] = parseFieldName(columnField.fieldNumeratorParent).variableGuid;
                property['computed-options']['change-composition:from'] = parseFieldName(columnField.fieldDenominator).variableGuid;
                property['computed-options']['change-composition:from-parent'] = parseFieldName(columnField.fieldDenominatorParent).variableGuid;
                break;
            }
        }
        return property;
    }
}

export default RendererData;
