import GLU from '../glu2.js/src/index';

class ReportApi extends GLU.Api {
  constructor(endpoint) {
    super(endpoint);

    this.createApiActions(
      {
        name: 'getGeosByFipsCodes',
        method: GLU.Api.Post,
        path: '/SeWebService.asmx/GetGeoItemsByFipsCodes',
        credentials: true,
      },
      {
        name: 'getSystemReports',
        method: GLU.Api.Get,
        path: '/SeWebService.asmx/GetSystemReports',
        credentials: true,
      },
      {
        name: 'getSystemReport',
        method: GLU.Api.Get,
        path: '/SeWebService.asmx/GetSystemReport',
        credentials: true,
      },
      {
        name: 'getDatasetsOfSurvey',
        method: GLU.Api.Get,
        path: '/SeWebService.asmx/GetDatasets?sSurveyName={surveyName}&bIncludeTables={includeTables}',
        credentials: true,
      },
      {
        name: 'getCpiValues',
        method: GLU.Api.Get,
        path: '/SeWebService.asmx/GetConsumerPriceIndices',
        credentials: true,
      },
      {
        name: 'getTablesByGuids',
        method: GLU.Api.Post,
        path: '/SeWebService.asmx/GetTablesByGuids',
        credentials: true,
      },
    );
  }
}

export default ReportApi;
