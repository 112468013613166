import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Trigger from 'rc-trigger';

import AppConfig from '../../appConfig';
import Tooltip from '../Tooltip';
import BusComponent from '../BusComponent';
import { focusFirstChild } from '../../helpers/Util';

const popupAlignOptions = {
    points: ['tr', 'br'],
    offset: [0, 10],
};

class UserAvatar extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {};

        this.boundAfterPopupRender = this.afterPopupRender.bind(this);
    }

    onMyAccountClick = () => {
        window.open(`${AppConfig.constants.backends.website}/account`);
    }

    onLogOutClick = () => {
        this.emit('LOG_OUT_REQUEST');
    }

    afterPopupRender = visible => {
        if (visible) {
            focusFirstChild(this.popupContainer);
        }
    };

    render() {
        const { userInfo, intl } = this.props;
        return (
            <Trigger
                action={['click']}
                onPopupAlign={this.boundAfterPopupRender}
                popup={<div
                    className="menu-popup"
                    ref={e => { this.popupContainer = e; }}
                >
                    <div className="user-options">
                        <div className="user-options__user">
                            <img
                                alt={intl.formatMessage({ id: 'header.userAvatar' })}
                                title={intl.formatMessage({ id: 'header.userAvatar' })}
                                className={classNames('user-options__user__avatar', { 'user-options__user__avatar--pro': userInfo.isProUser })}
                                src={userInfo.pictureLargeURL}
                            />
                            <div className="user-options__user__info">
                                <div className="user-options__user__info__name" title={userInfo.name}>{userInfo.name}</div>
                                <div className="user-options__user__info__email" title={userInfo.email}>{userInfo.email}</div>
                            </div>
                        </div>
                        <div className="user-options__controls">
                            {false && <button
                                type="button"
                                title={intl.formatMessage({ id: 'header.myAccount' })}
                                className="user-options__button"
                                onClick={this.onMyAccountClick}
                            >
                                {intl.formatMessage({ id: 'header.myAccount' })}
                            </button>}
                            <button
                                type="button"
                                title={intl.formatMessage({ id: 'header.signOut' })}
                                className="user-options__button user-options__button--signout"
                                onClick={this.onLogOutClick}
                            >
                                {intl.formatMessage({ id: 'header.signOut' })}
                            </button>
                        </div>
                    </div>
                </div>}
                popupAlign={popupAlignOptions}
            >
                <button
                    type="button"
                    className="btn-icon user-avatar"
                    aria-haspopup
                >
                    <Tooltip
                        placement="bottomLeft"
                        mouseEnterDelay={0.5}
                        mouseLeaveDelay={0}
                        overlay={intl.formatMessage({ id: 'header.myAccount' })}
                    >
                        <img
                            alt={intl.formatMessage({ id: 'header.userAvatar' })}
                            src={userInfo.pictureSmallURL}
                        />
                    </Tooltip>
                </button>
            </Trigger>
        );
    }
}

UserAvatar.propTypes = {
    userInfo: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(UserAvatar);
