import Api from '../apis/Api';
import uuid from 'node-uuid';
import GLU from '../glu2.js/src/index';
import ProjectXMLParser from '../helpers/ProjectXMLParser';
import AppConfig from '../appConfig';
import FrameType from '../enums/FrameType';
import FrameLockMode from '../enums/FrameLockMode';
import Frame from '../objects/Frame';
import RendererType from '../enums/RendererType';

function dingoProjectTypeFromOldProjectType(oldProjectType) {
    switch (oldProjectType) {
    case 'CAIMAN_MAP':
        return AppConfig.constants.projectTypes.map;
    case 'CAIMAN_STORY':
        return AppConfig.constants.projectTypes.story;
    default:
        if (AppConfig.projectTypes.includes(oldProjectType)) {
            return oldProjectType;
        }

        throw new Error('Unsupported project type.');
    }
}

class ProjectDataSource extends GLU.DataSource {
    static get name() {
        return 'ProjectDataSource';
    }

    static getInstance() {
        return new ProjectDataSource();
    }

    constructor() {
        super();
        this._currentProject = undefined;
        this._currentFrameIndex = 0;
        /** @type {Frame} */
        this._currentFrame = undefined;
        /** @type {import('../objects/MapInstance').default} */
        this._reportMapInstance = undefined;
        /** @type {import('../objects/MapInstance').default} */
        this._customSelectionMapInstance = undefined;
        this._overrideProjectOptions = undefined;
    }

    get currentProject() {
        return this._currentProject;
    }

    get currentFrame() {
        return this._currentFrame;
    }

    get currentFrameIndex() {
        return this._currentFrameIndex;
    }

    get reportMapInstance() {
        return this._reportMapInstance;
    }

    get selectionMapInstance() {
        return this._customSelectionMapInstance;
    }

    get currentProjectXML() {
        return this._currentProjectXML;
    }

    /** @returns {import('../../../').OverrideProjectOptions} */
    get overrideProjectOptions() {
        return this._overrideProjectOptions;
    }

    /** @type {import('../objects/MapInstance').default} */
    get defaultMapInstance() {
        return this.currentFrame.mapInstances[0];
    }

    // determine if dingo can open project
    // dingo cannot open project if:
    // - project contains unsupported base maps
    // - story project contains non map frames or media items
    canDingoOpenProject(groupsMetadata) {
        const hasUnsupportedMaps = !!this.currentProject.frames.filter(frame => frame.isMapFrame).find(frame => (
            !!frame.mapInstances.find(mapInstance => !groupsMetadata.find(g => g.id === mapInstance.metadataGroupId))
        ));
        if (hasUnsupportedMaps) {
            return false;
        }
        // We support only projects that only have allowed map frames
        const allowedMapFrames = [FrameType.SINGLE_MAP, FrameType.SIDE_BY_SIDE_MAPS, FrameType.SWIPE];
        return this._currentProject.frames.some(frame => allowedMapFrames.indexOf(frame.type) !== -1);
    }

    /**
     * @param {string} mapInstanceId
     */
    getActiveMapInstance(mapInstanceId) {
        if (this._customSelectionMapInstance && this._customSelectionMapInstance.id === mapInstanceId) {
            return this._customSelectionMapInstance;
        }

        if (this._reportMapInstance && this._reportMapInstance.id === mapInstanceId) {
            return this._reportMapInstance;
        }
        return this.currentFrame.mapInstances.find(mi => mi.id === mapInstanceId);
    }

    updateCurrentFrameThumbnail(thumbnail, thumbnailLarge) {
        this.currentFrame.thumbnail = thumbnail;
        this.currentFrame.thumbnailLarge = thumbnailLarge;
    }

    updateTitlePageStartButtonLabel(startButtonLabel) {
        if (this.currentProject.titleFrame) {
            this.currentProject.titleFrame.startButtonLabel = startButtonLabel;
        }
    }

    updateTitlePageTitle(title) {
        if (this.currentProject.titleFrame) {
            this.currentProject.titleFrame.title = title;
        }
    }

    updateTitlePageDescription(description) {
        if (this.currentProject.titleFrame) {
            this.currentProject.titleFrame.description = description;
        }
    }

    reorderProjectFrame(oldIndex, newIndex) {
        const [frame] = this.currentProject.frames.splice(oldIndex, 1);
        this.currentProject.frames.splice(newIndex, 0, frame);
        this._currentFrameIndex = this.currentProject.frames.findIndex(f => f === this.currentFrame);
    }

    updateTitlePageMedia(media) {
        if (this.currentProject.titleFrame) {
            this.currentProject.titleFrame.media[0] = media;
        }
    }

    deleteCurrentFrame() {
        const currentIndex = this.currentProject.frames.indexOf(this.currentFrame);
        if (currentIndex > -1) {
            // current frame is a map frame, so remove it
            this.currentProject.frames.splice(currentIndex, 1);
            this._currentFrameIndex = Math.max(0, currentIndex - 1);
            this._currentFrame = this.currentProject.frames[this._currentFrameIndex];
        } else {
            // current frame is the title frame so remove title frame(page)
            this.currentProject.titleFrame = undefined;
            this._currentFrameIndex = 0;
            this._currentFrame = this.currentProject.frames[0];
        }
    }

    duplicateCurrentFrame() {
        const currentIndex = this.currentProject.frames.indexOf(this.currentFrame);
        this._currentFrame = this.currentFrame.clone();
        this._currentFrameIndex = currentIndex + 1;
        this.currentProject.frames.splice(currentIndex + 1, 0, this._currentFrame);
    }

    updateCurrentFrameInfo(title, description, media) {
        this.currentFrame.title = title;
        this.currentFrame.description = description;
        this.currentFrame.media = media;
    }

    setCurrentFrame(frameIdx, isViewMode = false) {
        if (frameIdx === -1) {
            // -1 stands for title frame(page)
            this._currentFrame = this.currentProject.titleFrame;
        } else {
            this._currentFrame = this.currentProject.frames[frameIdx];
        }
        this._currentFrameIndex = frameIdx;
    }

    setActiveReportMapInstance(mapInstanceId) {
        const mapInstance = this.currentFrame.mapInstances.find(mi => mi.id === mapInstanceId);
        this._reportMapInstance = mapInstance.clone();
        this._reportMapInstance.reportParentMapInstanceId = mapInstance.id;
    }

    clearActiveReportMapInstance() {
        this._reportMapInstance = undefined;
    }

    setActiveCustomSelectionMapInstance(mapInstanceId) {
        const mapInstance = this.currentFrame.mapInstances.find(mi => mi.id === mapInstanceId);
        this._customSelectionMapInstance = mapInstance.clone();
        this._customSelectionMapInstance.reportParentMapInstanceId = mapInstance.id;
    }

    clearActiveCustomSelectionMapInstance() {
        this._customSelectionMapInstance = undefined;
    }

    switchFrameMapInstances() {
        this._currentFrame.mapInstances.reverse();
    }

    toggleFrameLockMode() {
        if (this.currentFrame.lockMode === FrameLockMode.NONE) {
            this.currentFrame.lockMode = FrameLockMode.POSITION;
        } else {
            this.currentFrame.lockMode = FrameLockMode.NONE;
        }
    }

    updateProjectFrameType(frameType, options = {}) {
        this.currentFrame.type = frameType;
        switch (this.currentFrame.type) {
        case FrameType.SINGLE_MAP:
            if (options.keepMapInstanceId) {
                const mapInstance = this.currentFrame.mapInstances.find(map =>
                    map.id === options.keepMapInstanceId,
                );
                this.currentFrame.mapInstances = [mapInstance];
            } else {
                this.currentFrame.mapInstances = [this.currentFrame.mapInstances[0]];
            }
            break;
        case FrameType.SIDE_BY_SIDE_MAPS:
            if (this.currentFrame.mapInstances.length === 1) {
                this.currentFrame.mapInstances = [this.currentFrame.mapInstances[0], this.currentFrame.mapInstances[0].clone()];
            }
            break;
        case FrameType.SWIPE:
            if (this.currentFrame.mapInstances.length === 1) {
                this.currentFrame.mapInstances = [this.currentFrame.mapInstances[0], this.currentFrame.mapInstances[0].clone()];
            } else {
                this.currentFrame.mapInstances[1].initialView = this.currentFrame.mapInstances[0].initialView.clone();
            }
            break;
        }
    }

    createNewProjectFromCurrent(newProjectTitle, newProjectDescription, newProjectXML, thumbnail, userId) {
        const payload = {
            cmd: 'SaveProject',
            title: newProjectTitle.length > 100 ? newProjectTitle.substr(0, 99) : newProjectTitle,
            description: newProjectDescription,
            ClientUserId: -1,
            ClientSubUserId: `${userId}`,
            projectXml: newProjectXML,
            isShared: 0,
            projectId: this._currentProject.projectId,
            etag: this._currentProject.etag,
            assetsGuid: uuid.v4(),
            copyAssetsFromGuid: this._currentProject.assetsGuid,
            projectType: dingoProjectTypeFromOldProjectType(this._currentProject.projectType),
            projectVersion: AppConfig.constants.projectVersion,
            projectThumb: thumbnail.image,
            loginRequired: this._currentProject.loginRequired,
        };

        return new Promise((resolve, reject) => {
            Api.project.mapProject({ payload })
               .then(response => {
                   const responseXMLDocument = response.xhr.responseXML;
                   const error = ProjectXMLParser.parseError(responseXMLDocument);
                   if (error) {
                       reject(error);
                       return;
                   }

                   const projectXMLParser = new ProjectXMLParser(responseXMLDocument);

                   const projectInfo = projectXMLParser.getProject();
                   this._currentProject.projectId = projectInfo.projectId;
                   this._currentProject.title = projectInfo.title;
                   this._currentProject.viewCode = projectInfo.viewCode;
                   this._currentProject.createdDate = projectInfo.createdDate;
                   this._currentProject.modifiedDate = projectInfo.modifiedDate;
                   this._currentProject.projectType = projectInfo.projectType;
                   this._currentProject.ownedBySe = projectInfo.ownedBySe;
                   this._currentProject.projectThumbUrl = projectInfo.projectThumbUrl;
                   this._currentProject.assetsGuid = projectInfo.assetsGuid;
                   this._currentProject.isShareSnapshot = projectInfo.isShareSnapshot;
                   this._currentProject.canSave = true;
                   this._currentProject.isPublic = projectInfo.isPublic;
                   resolve(this._currentProject);
               }, reject);
        });
    }

    /**
     * @param {import('../').OverrideProjectOptions} overrideProjectOptions
     */
    setOverrideProjectOptions(overrideProjectOptions) {
        this._overrideProjectOptions = overrideProjectOptions;
    }

    handleProjectOverrideOptions = project => {
        if (!this._overrideProjectOptions) {
            // nothing to override
            return;
        }
        // Apply same overrides for all map instances, for now :)
        // Use first frame.
        const frame = project.frames[this.currentFrameIndex];
        frame.mapInstances.forEach(mapInstance => {
            // Override initial view
            if (this._overrideProjectOptions.initialView) {
                mapInstance.initialView = this._overrideProjectOptions.initialView;
            }
            // Override location analysis item
            if (this._overrideProjectOptions.locationAnalysisItem) {
                mapInstance.locationAnalysisItem = this._overrideProjectOptions.locationAnalysisItem;
            }
            // Override search results
            if (this._overrideProjectOptions.searchResults) {
                mapInstance.searchResults = this._overrideProjectOptions.searchResults;
            }
        });
    }

    loadProjectFromXML(projectXML, isViewMode, resolve, reject) {
        this._currentProjectXML = projectXML;
        const error = this._currentProjectXML.getElementsByTagName('Error')[0];

        if (error) {
            reject(error.value);
            return;
        }

        const project = new ProjectXMLParser(this._currentProjectXML).getProject();

        // Handle override options
        this.handleProjectOverrideOptions(project);

        this._initializeCurrentProject(project, isViewMode);
        resolve(this._currentProject);
    }

    loadProjectByViewCode(viewCode, frameIndex = 0, isViewMode = false) {
        const params = {
            v: viewCode,
        };

        return new Promise((resolve, reject) => {
            Api.project.getProject({ query: params }).then(response => {
                this._currentFrameIndex = frameIndex;
                this.loadProjectFromXML(response.xhr.responseXML, isViewMode, resolve, reject);
            }, reject);
        });
    }

    loadProjectByURL(projectURL, frameIndex = 0, isViewMode = false) {
        const logic = (resolve, reject) => {
            const httpRequest = new XMLHttpRequest();

            httpRequest.onreadystatechange = () => {
                if (httpRequest.readyState === XMLHttpRequest.DONE) {
                    if (httpRequest.status === 200) {
                        this._currentFrameIndex = frameIndex;
                        this.loadProjectFromXML(httpRequest.responseXML, isViewMode, resolve, reject);
                    } else {
                        reject('There was a problem fetching the project.');
                    }
                }
            };

            httpRequest.open('GET', projectURL);
            httpRequest.send();
        };

        return new Promise(logic);
    }

    updateProject(projectXml, thumbnail, userId) {
        if (!this._currentProject.isMadeWithDingo) {
            throw new Error('Please upgrade the current project to the latest version.');
        }

        const query = {
            projectId: this._currentProject.projectId,
        };
        const payload = {
            cmd: 'SaveProject',
            title: this._currentProject.title,
            ClientUserId: -1,
            ClientSubUserId: `${userId}`,
            projectXml,
            isShared: 0,
            projectId: this._currentProject.projectId,
            etag: this._currentProject.etag,
            projectType: this._currentProject.projectType,
            assetsGuid: this._currentProject.assetsGuid,
            projectThumb: thumbnail.image,
            overwriteFlag: true,
        };
        return new Promise((resolve, reject) => {
            Api.project.saveProject({ query, payload })
               .then(response => {
                   const responseXMLDocument = response.xhr.responseXML;
                   const error = ProjectXMLParser.parseError(responseXMLDocument);
                   if (error) {
                       reject(error);
                       return;
                   }
                   const projectXMLParser = new ProjectXMLParser(responseXMLDocument);
                   const project = projectXMLParser.getProject();
                   this._currentProject.projectThumbUrl = project.projectThumbUrl;
                   this._currentProject.modifiedDate = project.modifiedDate;
                   this._currentProjectXML = projectXml;
                   resolve(this._currentProject);
               }, reject);
        });
    }

    upgradeProject(thumbnail, userId) {
        if (this._currentProject.isMadeWithDingo) {
            throw new Error('Project is already Dingo project');
        }

        const query = {
            projectId: this._currentProject.projectId,
        };

        const newProjectType = dingoProjectTypeFromOldProjectType(this._currentProject.projectType);

        this._currentProject.projectType = newProjectType;

        const projectXml = this._currentProject.toXML();

        const payload = {
            cmd: 'SaveProject',
            title: this._currentProject.title,
            ClientUserId: -1,
            ClientSubUserId: `${userId}`,
            projectXml,
            isShared: 0,
            projectId: this._currentProject.projectId,
            etag: this._currentProject.etag,
            projectType: newProjectType,
            projectVersion: AppConfig.constants.projectVersion,
            assetsGuid: this._currentProject.assetsGuid,
            projectThumb: thumbnail.image,
            overwriteFlag: true,
        };
        return new Promise((resolve, reject) => {
            Api.project.saveProject({ query, payload })
               .then(response => {
                   const responseXMLDocument = response.xhr.responseXML;
                   const error = ProjectXMLParser.parseError(responseXMLDocument);
                   if (error) {
                       reject(error);
                       return;
                   }
                   const projectXMLParser = new ProjectXMLParser(responseXMLDocument);
                   const project = projectXMLParser.getProject();
                   this._currentProject.projectType = project.projectType;
                   this._currentProject.modifiedDate = project.modifiedDate;
                   this._currentProjectXML = projectXml;
                   resolve(this._currentProject);
               }, reject);
        });
    }

    editCurrentProjectInfo(title, description, userId) {
        if (!this._currentProject.isMadeWithDingo) {
            throw new Error('Please upgrade the current project to the latest version.');
        }

        const query = {
            projectId: this._currentProject.projectId,
        };
        const payload = {
            oldTitle: this._currentProject.title,
            oldDescription: this._currentProject.description,
            newTitle: title,
            newDescription: description,
            ClientUserId: -1,
            ClientSubUserId: `${userId}`,
        };
        return new Promise((resolve, reject) => {
            Api.project.editProjectInfo({ query, payload })
               .then(response => {
                   const responseXMLDocument = response.xhr.responseXML;
                   const error = ProjectXMLParser.parseError(responseXMLDocument);
                   if (error) {
                       reject(error);
                       return;
                   }
                   this._currentProject.title = title;
                   this._currentProject.description = description;
                   resolve(this._currentProject);
               }, reject);
        });
    }

    updateCurrentProjectEditRights(userId) {
        const query = {
            projectId: this._currentProject.projectId,
            ClientUserId: -1,
            ClientSubUserId: `${userId}`,
        };
        return new Promise((resolve, reject) => {
            Api.project.canSaveProject({ query })
               .then(response => {
                   const responseXMLDocument = response.xhr.responseXML;
                   const error = ProjectXMLParser.parseError(responseXMLDocument);
                   if (error) {
                       reject(error);
                       return;
                   }
                   const responseNode = responseXMLDocument.getElementsByTagName('Response')[0];
                   if (!responseNode) {
                       const invalidResponseError = {
                           message: 'Invalid Response',
                       };
                       reject(invalidResponseError);
                       return;
                   }
                   this._currentProject.canSave = responseNode.getElementsByTagName('CanSave')[0].textContent === 'true';
                   resolve(this._currentProject);
               }, reject);
        });
    }

    updateVisiblity(userId, isPublic) {
        const query = {
            projectId: this._currentProject.projectId,
        };

        const payload = {
            project_id: this._currentProject.projectId,
            is_public: isPublic,
            ClientUserId: -1,
            ClientSubUserId: `${userId}`,
        };

        return new Promise((resolve, reject) => {
            Api.project.updateProjectVisibility({ query, payload })
               .then(response => {
                   const responseXMLDocument = response.xhr.responseXML;
                   const error = ProjectXMLParser.parseError(responseXMLDocument);
                   if (error) {
                       reject(error);
                       return;
                   }

                   this._currentProject.isPublic = response.body.isPublic;
                   resolve(this._currentProject);
               }, reject);
        });
    }

    _initializeCurrentProject(project, isViewMode) {
        this._currentProject = project;
        this._currentFrame = this._currentProject.frames[this.currentFrameIndex];
        // shared snapshot has no title
        if (this._currentProject.isShareSnapshot) {
            this._currentProject.title = '';
        }
        // every browser instance that opens SE default project gets its own assets folder
        // this is important because otherwise every user who opens default project
        // and adds annotations images will be uploading to the same folder
        // This folder will then get huge and saving project (`copyFromAssetsGuid`) will be slow
        if (this._currentProject.ownedBySe || !this._currentProject.assetsGuid) {
            this._currentProject.assetsGuid = uuid.v4();
        }
        // initialize satellite data overlay
        // old project will have `undefined` values and for them dataOpacity = 0.4 and satellite has no color
        this._currentProject.frames.forEach(frame => {
            frame.mapInstances.forEach(mapInstance => {
                if (mapInstance.isSatelliteVisible) {
                    if (mapInstance.satelliteDataOverlaySatelliteHasColor === undefined) {
                        mapInstance.satelliteDataOverlaySatelliteHasColor = true;
                    }
                    if (mapInstance.satelliteDataOverlayDataOpacity === undefined) {
                        mapInstance.satelliteDataOverlayDataOpacity = 0;
                    }
                } else {
                    mapInstance.satelliteDataOverlaySatelliteHasColor = true;
                    mapInstance.satelliteDataOverlayDataOpacity = 0;
                }
            });
        });
    }

    applyColorPalettesToProject(isViewMode, currentMaps, currentColorPalettes) {
        this._currentProject.frames.forEach(frame => {
            frame.mapInstances.forEach(mapInstance => {
                const map = currentMaps[mapInstance.currentMapId];
                let colorPalette = currentColorPalettes.getColorPaletteByTypeAndId(mapInstance.dataTheme.colorPaletteType, mapInstance.dataTheme.colorPaletteId, map.colorPalettesURL);

                // Some old projects had issues with the naming of color palette so the previous call would
                // result with an undefined colorPalette, so to prevent that case we'll set the colorPalette to the first
                // in the list of palettes of the same type
                if (!colorPalette) {
                    colorPalette = currentColorPalettes.getColorPaletteByType(
                        mapInstance.dataTheme.colorPaletteType,
                        map.colorPalettesURL,
                    );
                }

                mapInstance.dataTheme.rendering.forEach(renderer => {
                    if (renderer.type === RendererType.DOT_DENSITY || renderer.type === RendererType.BUBBLES ||
                        renderer.type === RendererType.VALUE || renderer.type === RendererType.MULTI_VALUE ||
                        renderer.type === RendererType.VALUE_DOT_DENSITY) {
                        renderer.applyColorPalette(colorPalette, mapInstance.dataTheme.colorPaletteFlipped);
                    }
                });
            });
        });
    }
}

export default ProjectDataSource;
