// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import SimpleDropdown from '../../dropdown/SimpleDropdown';

/**
 *
 * @param {object} props
 * @param {(surveyName: string) => void} props.onChange
 * @param {import('../../../').TableComparableSurvey[]} props.comparableSurveys
 * @param {import('../../../').TableComparableSurvey} props.selectedSurvey
 * @param {boolean} props.disabled
 * @param {import('react-intl').intlShape} props.intl
 */
const YearPicker = ({ onChange, comparableSurveys, selectedSurvey, disabled, intl }) => (
    <SimpleDropdown
        className="data-browser-search-dropdown data-browser-search-dropdown--year-picker"
        onItemClick={onChange}
        items={comparableSurveys.map(s => ({
            id: s.surveyName,
            text: s.surveyYear,
        }))}
        selectedItem={{
            id: selectedSurvey.surveyName,
            text: selectedSurvey.surveyYear,
        }}
        disabled={comparableSurveys.length === 1 || disabled}
        ariaLabel={intl.formatMessage({ id: 'year' })}
    />
);
YearPicker.propTypes = {
    onChange: PropTypes.func.isRequired,
    comparableSurveys: PropTypes.array.isRequired,
    selectedSurvey: PropTypes.object.isRequired,
    disabled: PropTypes.bool.isRequired,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(YearPicker);
