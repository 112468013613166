import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import AppConfig from '../../appConfig';

/**
 * @param {object} pram0
 * @param {import('../../').LayerLibraryItemMetadataClassSvg} pram0.svg
 * @param {string} pram0.type
 * @param {React.CSSProperties} pram0.style
 */
const ClassesItemImage = ({ svg, type, style }) => {
    let icon;
    if (svg) {
        icon = (
            <img
                src={`${AppConfig.constants.assetsBaseURL}/svg/${svg.icon}.svg`}
                width={svg.width}
                height={svg.height}
                alt="Legend item icon"
            />
        );
    } else {
        icon = <i className={`socex-icon-${type}`} />;
    }

    return (
        <div
            className={classNames('layer-library-classes__item-icon', {
                'layer-library-classes__item-icon--svg': svg,
            })}
            style={style}
        >
            {icon}
        </div>
    );
};

ClassesItemImage.propTypes = {
    svg: PropTypes.object,
    type: PropTypes.string,
    style: PropTypes.object,
};

ClassesItemImage.defaultProps = {
    svg: undefined,
    type: 'circle',
    style: {},
};

export default ClassesItemImage;
