// @ts-check
import classNames from 'classnames';
import React from 'react';
import { injectIntl } from 'react-intl';

import BusComponent from '../BusComponent';
import Tooltip from '../Tooltip';

/**
 * @typedef Props
 * @property {import('react-intl').intlShape} intl
 * @property {import('../../objects/MapInstance').default} mapInstanceId
 * @property {boolean} chip
 *
 * @extends {BusComponent<Props>}
 */
class LocationAnalysisMinimizeButton extends BusComponent {
    onClick = () => {
        this.emit('MINIMIZE_LOCATION_PANEL', {
            mapInstanceId: this.props.mapInstanceId,
        });
    };

    render() {
        return (
            <Tooltip
                placement="bottom"
                mouseEnterDelay={0.2}
                mouseLeaveDelay={0}
                overlay={this.props.intl.formatMessage({
                    id: 'dataBrowser.minimizeDriveTime',
                })}
            >
                <button
                    className={classNames({
                        'btn-icon btn-icon--small btn-icon--raised margin-right-8':
                            !this.props.chip,
                        'btn-chip btn-chip--active margin-right-8':
                            this.props.chip,
                    })}
                    onClick={this.onClick}
                    aria-label={this.props.intl.formatMessage({
                        id: 'dataBrowser.minimizeDriveTime',
                    })}
                >
                    <i className="material-icons btn-chip__icon">unfold_less</i>
                    {this.props.chip && (
                        <span className="btn-chip__text">
                            {this.props.intl.formatMessage({
                                id: 'locationAnalysis.minimizePanel',
                            })}
                        </span>
                    )}
                </button>
            </Tooltip>
        );
    }
}

export default injectIntl(LocationAnalysisMinimizeButton);
