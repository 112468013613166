// @ts-check
import React from 'react';
import AppConfig from '../../../appConfig';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import VisualizationType from '../../../enums/VisualizationType';
import ChangeOverTimeType from '../../../enums/ChangeOverTimeType';
import BusComponent from '../../BusComponent';

/**
 * @typedef Props
 * @property {import('../../../objects/DataTheme').default} dataTheme
 * @property  {string} mapInstanceId
 * @property {import('react-intl').intlShape} intl
 *
 * @extends {BusComponent<Props, {}>}
 */
class ChangeVisualizationCOT extends BusComponent {
    setShadedAreaVisualizationType = () => {
        const { mapInstanceId, dataTheme } = this.props;
        if (dataTheme.visualizationType === VisualizationType.SHADED_AREA) {
            return;
        }
        this.emit('APPLY_CHANGE_OVER_TIME_REQUEST', {
            changeType: ChangeOverTimeType.COMPUTE_CHANGE_PERCENT,
            compareWithVariableItem: dataTheme.appliedChangeOverTimeCompareSelection,
            mapInstanceId,
            pointOfReferenceItem: dataTheme.appliedChangeOverTimePointOfReference,
        });
    };

    setBubblesVisualizationType = () => {
        const { mapInstanceId, dataTheme } = this.props;
        if (dataTheme.visualizationType === VisualizationType.BUBBLES) {
            return;
        }

        this.emit('APPLY_CHANGE_OVER_TIME_REQUEST', {
            changeType: ChangeOverTimeType.COMPUTE_CHANGE,
            compareWithVariableItem: dataTheme.appliedChangeOverTimeCompareSelection,
            mapInstanceId,
            pointOfReferenceItem: dataTheme.appliedChangeOverTimePointOfReference,
        });
    };

    render() {
        const { intl, dataTheme } = this.props;
        const { visualizationType } = dataTheme;

        const shadedAreaButtonClassnames = classNames(
            'auto-adjust-change-visualization__button-row',
            {
                selected: visualizationType === VisualizationType.SHADED_AREA,
            },
        );
        const bubblesButtonClassnames = classNames(
            'auto-adjust-change-visualization__button-row',
            {
                selected: visualizationType === VisualizationType.BUBBLES,
            },
        );

        const shadedAreaLabel = intl.formatMessage({ id: 'dataBrowser.percentChange' });
        const bubblesLabel = intl.formatMessage({ id: 'dataBrowser.difference' });

        return (
            <div className="auto-adjust-change-visualization">
                <span className="header">{intl.formatMessage({ id: 'legend.visualizeAs' })}:</span>
                <div className={shadedAreaButtonClassnames}>
                    <button
                        className="btn-link"
                        onClick={this.setShadedAreaVisualizationType}
                        title={shadedAreaLabel}
                        aria-label={shadedAreaLabel}
                    >
                        <img
                            src={`${AppConfig.constants.assetsBaseURL}/svg/shaded_area.svg`}
                            alt="Shaded"
                        />
                        <span>{shadedAreaLabel}</span>
                    </button>
                    <i className="material-icons" aria-hidden>
                        done
                    </i>
                </div>

                <div className={bubblesButtonClassnames}>
                    <button
                        className="btn-link"
                        onClick={this.setBubblesVisualizationType}
                        title={bubblesLabel}
                        aria-label={bubblesLabel}
                    >
                        <img
                            src={`${AppConfig.constants.assetsBaseURL}/svg/shaded_bubbles.svg`}
                            alt="Bubbles percent"
                        />
                        <span>{bubblesLabel}</span>
                    </button>
                    <i className="material-icons" aria-hidden>
                        done
                    </i>
                </div>
            </div>
        );
    }
}

ChangeVisualizationCOT.propTypes = {
    intl: PropTypes.object.isRequired,
    dataTheme: PropTypes.object.isRequired,
    mapInstanceId: PropTypes.string.isRequired,
};

export default injectIntl(ChangeVisualizationCOT);
