// @ts-check
import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import BusComponent from '../BusComponent';
import RadioButton from '../../components/form/RadioButton';
import AddMoreYears from './AddMoreYears';
import InfoPopover from '../popover/InfoPopover';
import LocationAnalysisCreateReportNote from './LocationAnalysisCreateReportNote';
import Loader from '../Loader';

import { preparePremadeReportsAndTemplatesWithDividers } from '../../helpers/ReportTableTemplate';
import AppConfig from '../../appConfig';

/**
 *
 * @typedef Props
 * @property {import('../../objects/MapInstance').default} mapInstance
 * @property {import('../../objects/MetaSurvey').default} selectedSurvey
 * @property {string} note
 * @property {() => void} onUpdateCompareSurveyList
 * @property {import('react-intl').intlShape} intl
 *
 * @typedef State
 * @property {import('../../types').ReportTopic[]} reportTopics
 * @property {import('../../objects/MetaSurvey').default[]} availableSurveys
 * @property {import('../../objects/MetaSurvey').default[]} compareSurveysList
 * @property {import('../../types').ReportTopic} selectedReportTopic
 * @property {import('../../types').ReportTemplateTable[]} compatibleTableTemplates
 * @property {boolean} noteVisible
 *
 * @extends {BusComponent<Props, State>}
 */

class LocationAnalysisReportConstruction extends BusComponent {
    constructor(props, context) {
        super(props, context);

        /** @type {State} */
        this.state = {
            reportTopics: [],
            availableSurveys: [],
            compareSurveysList: [],
            selectedReportTopic: undefined,
            compatibleTableTemplates: undefined,
            noteVisible: false,
        };
    }

    componentDidMount() {
        this.bindGluBusEvents({
            CUSTOM_MAP_SELECTION_REPORT_CONSTRUCTION_INFO: this.onReportInfo,
            CUSTOM_MAP_SELECTION_REPORT_TOPICS: this.onReportTopics,
            COMPATIBLE_TABLE_TEMPLATES_SET:
                this.onCompatibleTableTemplatesLoaded,
            CREATE_REPORT_ERROR: this.onCreateReportError,
            DOWNLOAD_REPORT_ERROR: this.onCreateReportError,
        });
        this.emit('COMPATIBLE_TABLE_TEMPLATES_REQUEST');
        this.emit('CUSTOM_MAP_SELECTION_REPORT_CONSTRUCTION_INFO_REQUEST', {
            mapInstance: this.props.mapInstance,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const { selectedReportTopic, compareSurveysList } = this.state;
        const { id: mapInstanceId } = this.props.mapInstance;
        const {
            selectedReportTopic: prevSelectedReportTopic,
            compareSurveysList: prevCompareSurveyList,
        } = prevState;
        // If selected topic or compare surveys list has changed update report parameters
        const topicChanged =
            !prevSelectedReportTopic || !selectedReportTopic ||
            prevSelectedReportTopic.id !== selectedReportTopic.id;
        const compareSurveysListChanged =
            JSON.stringify(compareSurveysList) !==
            JSON.stringify(prevCompareSurveyList);
        if (topicChanged || compareSurveysListChanged) {
            // Check if we are in a featured report. We do that
            // by reading the featured reports. If this variable is
            // undefined, then the current survey is not featured and
            // thus does not have a defined aggregation type
            const featuredReport =
                AppConfig.constants.featuredReports
                    .find(report => report.source === 'survey' && report.properties.surveyName === this.props.selectedSurvey.name);
            // Update parent report parameters
            this.emit('CUSTOM_MAP_SELECTION_REPORT_PARAMS_CHANGE', {
                reportParams: {
                    selectedReportTopic,
                    defaultAggregationType: featuredReport
                        ? featuredReport.properties.aggregationType
                        : undefined,
                    selectedSurveys: [
                        ...compareSurveysList,
                        this.props.selectedSurvey,
                    ],
                },
                mapInstanceId,
            });
        }
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    /** @param {import('../../types').ReportTemplateTable[]} compatibleTableTemplates */
    onCompatibleTableTemplatesLoaded(compatibleTableTemplates) {
        this.setState({
            compatibleTableTemplates,
        });
    }

    /** @param {object} error Error object with message */
    onCreateReportError = error => {
        const { message } = error;
        this.setState({
            noteVisible: !message,
        });
    };

    /**
     * @param {object} param0
     * @param {import('../../types').ReportTopic[]} param0.reportTopics
     * @param {import('../../objects/MetaSurvey').default[]} param0.compareSurveysList
     * @param {import('../../objects/MetaSurvey').default[]} param0.availableSurveys
     */
    onReportInfo = ({ reportTopics, compareSurveysList, availableSurveys }) => {
        this.setState({
            reportTopics,
            selectedReportTopic: reportTopics[0],
            availableSurveys,
            compareSurveysList,
        });
        this.props.onUpdateCompareSurveyList(
            compareSurveysList.map(survey => survey.name),
        );
    };

    /**
     * @param {object} param0
     * @param {import('../../types').ReportTopic[]} param0.reportTopics
     */
    onReportTopics = ({ reportTopics }) => {
        this.setState({
            reportTopics,
            selectedReportTopic: reportTopics[0],
        });
    };

    getPremadeReportsAndTemplatesWithDividers = () => {
        const { reportTopics, compatibleTableTemplates } = this.state;
        return preparePremadeReportsAndTemplatesWithDividers(
            compatibleTableTemplates,
            reportTopics,
        );
    };

    /** @param {string} item */
    onReportTopicItemChange = item => {
        const selectedReportTopic =
            this.getPremadeReportsAndTemplatesWithDividers().find(
                topic => String(topic.id) === item,
            );
        this.setState({ selectedReportTopic });
    };

    /**
     * After updating compare survey list we must update the reportTopics since we will show
     * only reports compatible between the selected survey years (matching is done by name)
     * @param {import('../../objects/MetaSurvey').default[]} compareSurveysList
     */
    updateCompareSurveyList = compareSurveysList => {
        this.setState({ compareSurveysList });
        this.props.onUpdateCompareSurveyList(
            compareSurveysList.map(survey => survey.name),
        );
        this.emit('CUSTOM_MAP_SELECTION_REPORT_TOPICS_REQUEST', {
            compareSurveysList,
        });
    };

    render() {
        const {
            availableSurveys,
            compareSurveysList,
            selectedReportTopic,
            compatibleTableTemplates,
            noteVisible,
            reportTopics,
        } = this.state;

        const { selectedSurvey, note, intl } = this.props;

        const showReports = reportTopics && reportTopics.length > 0;

        if (!compatibleTableTemplates) {
            return <Loader className="location-analysis__loader-select-data" />;
        }

        const radioButtons =
            this.getPremadeReportsAndTemplatesWithDividers().map(report => {
                const { id, text, isDivider } = report;
                if (isDivider) {
                    return (
                        <div
                            className="report-topic-list__divider"
                            key={`divider-${text}`}
                        >
                            {text}
                        </div>
                    );
                }
                return (
                    <RadioButton
                        key={id}
                        name={text}
                        value={String(id)}
                        checked={
                            selectedReportTopic && id === selectedReportTopic.id
                        }
                        onClick={this.onReportTopicItemChange}
                    />
                );
            });

        return (
            <div>
                <div className="flex-it space-between center">
                    <div className="location-analysis__survey">
                        {intl.formatMessage({ id: 'survey' })}
                        <span className="location-analysis__survey-name">
                            {selectedSurvey.displayName}
                        </span>
                    </div>
                    <InfoPopover
                        infoMessage="To create a report from a different survey go to the data browser to change the survey and start again."
                        infoImgUrl={`${AppConfig.constants.assetsBaseURL}/svg/survey-info.svg`}
                    />
                </div>
                <AddMoreYears
                    availableSurveys={availableSurveys}
                    selectedSurveyName={selectedSurvey.name}
                    compareSurveysList={compareSurveysList}
                    onDone={this.updateCompareSurveyList}
                />
                <div className="report-topic-list__title">
                    {intl.formatMessage({ id: 'topic' })}
                </div>
                {showReports ? (
                    <div className="report-topic-list">{radioButtons}</div>
                ) : (
                    <div>{intl.formatMessage({ id: 'dataBrowser.noReportsAvailable' })}</div>
                )}
                {noteVisible && (
                    <LocationAnalysisCreateReportNote
                        note={note}
                        additionalInfo={intl.formatMessage({
                            id: 'dataBrowser.dataIsCalculatedBasedOnTheLowestGeographyAvailable',
                        })}
                    />
                )}
            </div>
        );
    }
}

LocationAnalysisReportConstruction.propTypes = {
    mapInstance: PropTypes.object.isRequired,
    selectedSurvey: PropTypes.object.isRequired,
    note: PropTypes.string,
    onUpdateCompareSurveyList: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
};

LocationAnalysisReportConstruction.defaultProps = {
    note: undefined,
};

export default injectIntl(LocationAnalysisReportConstruction);
