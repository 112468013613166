import Api from '../apis/Api';
import GLU from '../glu2.js/src/index';
import AppConfig from '../appConfig';
import { GEOJSON_FILENAME } from '../helpers/UserDataUploadHelper';

class UserDataUploadDataSource extends GLU.DataSource {
    static get name() {
        return 'UserDataUploadDataSource';
    }

    static getInstance() {
        return new UserDataUploadDataSource();
    }

    constructor() {
        super();
        this._userDataLoaded = false;
        this._loadedGeoJson = {};
        this._userData = [];
    }

    get loadedGeoJson() {
        return this._loadedGeoJson;
    }

    get userData() {
        return this._userData;
    }

    updateLoadedGeoJson = (userDataId, geoJson) => {
        this._loadedGeoJson[userDataId] = geoJson;
    };

    loadUserUploadedData = async () => {
        if (this._userDataLoaded) {
            return;
        }
        try {
            const response = await Api.userData.getUserData();
            if (response.body) {
                this._userData = response.body.items.map(item => ({
                    id: item.id,
                    title: item.name,
                })); // TODO: map data
                this._userDataLoaded = true;
            }
        } catch (e) {
            console.error(e);
        }
    };

    addUserData = async ({ userDataId }) => {
        try {
            const response = await Api.userData.addUserData({
                query: { userDataId },
            });
            if (response.body) {
                const userData = {
                    id: response.body.id,
                    title: response.body.name,
                };
                this._userData[userData.id] = userData;
                return userData;
            }
        } catch (e) {
            console.error(e);
            return undefined;
        }
    };

    loadUserDataGeoJson = async userDataId => {
        if (this._loadedGeoJson[userDataId]) {
            return; // already loaded
        }
        try {
            let signedUrl;
            // First, obtain the one-time-use access URL
            const signedUrlResponse = await Api.userData.getUserDataById({ query: { userDataId } });
            if (signedUrlResponse) {
                signedUrl = signedUrlResponse.body.signedS3AccessUrl;
            }
            // Try to retrieve the geojson using the one-time-use access URL
            const response = await fetch(signedUrl);
            let geoJSON;
            switch (response.status) {
                case 200: {
                    /** @type {Blob} */
                    const blob = await response.blob();
                    const zip = new JSZip();
                    const zipContents = await zip.loadAsync(blob);
                    // Find the geojson specific file in the zip
                    const file = zipContents.file(GEOJSON_FILENAME);

                    if (file) {
                        const content = await file.async('string');
                        geoJSON = JSON.parse(content);
                    } else {
                        throw new Error(`${targetFileName} not found in the zip file.`);
                    }
                    break;
                }
                case 304: {
                    // TODO
                    break;
                }
                default: {
                    geoJSON = undefined;
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
            }
            if (!geoJSON || !geoJSON.features) return;
            this._loadedGeoJson = {
                ...this._loadedGeoJson,
                [userDataId]: geoJSON,
            };
        } catch (e) {
            console.error(e);
        }
    };

    deleteUserData = async userDataId => {
        try {
            // TODO: Remove geojson file from s3
            await Api.userData.deleteUserData({ query: { userDataId } });
            const index = this._userData.findIndex(ud => ud.id === userDataId);
            if (index > -1) {
                this._userData.splice(index, 1);
            }
        } catch (e) {
            // TODO: remove this
            const index = this._userData.findIndex(ud => ud.id === userDataId);
            if (index > -1) {
                this._userData.splice(index, 1);
            }
            console.error(e);
        }
    };

    geocode = async searchTerm => {
        const query = {
            q: searchTerm,
            in: `countryCode:${AppConfig.constants.geocoder.countryCode}`,
            apiKey: AppConfig.constants.hereApiKey,
            limit: 1,
        };

        const response = await Api.hereGeocode.geocode({ query });
        if (response.body.items.length) {
            return response.body.items[0].position;
        }
        return {};
    };

    getUserDataUploadUrl = async ({ fileName, contentType, contentLength }) => {
        try {
            const response = await Api.userData.getUserDataUploadUrl({
                payload: {
                    fileName,
                    contentLength,
                    contentType,
                },
            });
            if (response.body) {
                return {
                    fileId: response.body.fileId,
                    signedS3UploadUrl: response.body.signedS3UploadUrl,
                };
            }
        } catch (e) {
            console.error(e);
            return undefined;
        }
    };

    uploadFileToSignedS3Url = async (file, signedUrl) => {
        let uploaded = false;
        try {
            const response = await fetch(signedUrl, {
                method: 'PUT',
                body: file,
                headers: {
                    'Content-Type': file.type,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            uploaded = true;
        } catch (e) {
            console.error(e);
        }
        return uploaded;
    };
}

export default UserDataUploadDataSource;
