import React from 'react';
import BusComponent from './BusComponent';
import classNames from 'classnames';

class ErrorPopup extends BusComponent {
    render() {
        const errorClasses = classNames('error-icon', 'material-icons');
        return (<div className="error">
            <i className={errorClasses}>error</i>
            <div className="error-text">
                <div className="level">{this.props.level}</div>
                <div className="info">Error info: <p>{this.props.originalError}</p></div>
                <div className="info">Additional info: <p>{this.props.additionalInfo}</p></div>
            </div>
        </div>);
    }
}

export default ErrorPopup;

