import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';

import ApplicationMode from '../../enums/ApplicationMode';
import BusComponent from '../BusComponent';
import Loader from '../Loader';
import TabbedBrowser from '../tabbedBrowser/TabbedBrowser';
import SearchBox from './SearchBox';
import PointSearchBox from './PointSearchBox';
import SearchBoxPanelFooter from './SearchBoxPanelFooter';

import { hasParentNode } from '../../helpers/Util';
import { HelpTourTargets } from '../..//enums/HelpTourDefinitions';
import AppConfig from '../../appConfig';

const SearchType = {
    POINT: 0,
    LOCATION: 1,
};

/**
 * @typedef Props
 * @property {import('react-intl').intlShape} intl
 * @property {string[]} mapInstanceIds
 * @property {string} [activeMapInstanceId]
 * @property {boolean} [isReportEditor]
 * @property {boolean} [disableMapSelection]
 * @property {() => void} [onToggleVisibility]
 *
 * @typedef State
 * @property {boolean} expanded
 * @property {number} searchType
 * @property {boolean} disabled
 *
 * @extends {BusComponent<Props, State>}
 */
class SearchBoxPanel extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            expanded: false,
            searchType: SearchType.POINT,
            disabled: true,
        };
    }

    componentDidMount() {
        this.bindGluBusEvents({
            POINTS_GEOJSON: this.onPointsFetched,
            ENTER_UPDATE_LOCATION_ANALYSIS_MODE: this.closeSearchPanel,
        });
    }

    componentDidUpdate() {
        if (this.state.expanded) {
            window.addEventListener('click', this.onMouseEvent);
        } else {
            window.removeEventListener('click', this.onMouseEvent);
        }
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
        window.removeEventListener('click', this.onMouseEvent);
    }

    onMouseEvent = event => {
        // Issue: When clicking on the search results list item in the recent search list
        // the list is removed from the DOM and looses it's parent node before we get
        // to check if it is contained in the search box panel
        // TODO: Figure a better way to handle this racing condition issue
        if (event.target.classList.contains('search-results__list-item')) {
            return;
        }

        if (!hasParentNode(event.target, this.searchPanel)) {
            this.toggleSearchPanel();
        }
    };

    onPointsFetched = () => {
        this.setState({ disabled: false });
    };

    toggleSearchPanel = () => {
        this.setState(
            prevState => ({ expanded: !prevState.expanded }),
            () => {
                if (this.state.expanded) {
                    this.focusSearchInput();
                    window.addEventListener('click', this.onMouseEvent);
                } else {
                    window.removeEventListener('click', this.onMouseEvent);
                }
            },
        );
    };

    closeSearchPanel = () => {
        this.setState({ expanded: false });
    };

    /** @param {number} type */
    changeSearchType = type => {
        this.setState(
            {
                searchType: type,
            },
            this.focusSearchInput,
        );
    };

    focusSearchInput = () => {
        if (this.state.expanded) {
            this.emit('FOCUS_SEARCH');
        }
    };

    render() {
        const { applicationMode } = this.context;
        const isEmbedView = ApplicationMode.isViewMode(applicationMode);
        const search = isEmbedView ? (
            <SearchBox
                mapInstanceIds={this.props.mapInstanceIds}
                activeMapInstanceId={this.props.activeMapInstanceId}
                isReportEditor={this.props.isReportEditor}
                onToggleVisibility={this.props.onToggleVisibility}
                disableMapSelection={this.props.disableMapSelection}
            />
        ) : (
            <div
                className="search-panel-wrapper"
                ref={ref => (this.searchPanel = ref)}
                data-tourId={HelpTourTargets.MAP_SEARCH_PANEL_TRIGER}
            >
                <button
                    onClick={this.toggleSearchPanel}
                    className={classNames('search-panel-wrapper__trigger', {
                        disabled: this.state.disabled,
                        clickable: !this.state.clickable,
                    })}
                    disabled={this.state.disabled}
                >
                    {this.props.intl.formatMessage({
                        id: this.state.disabled
                            ? 'points.loadingFacilities'
                            : 'dataBrowser.search',
                    })}
                    {this.state.disabled ? (
                        <div className="search-box__spinner">
                            <Loader />
                        </div>
                    ) : (
                        <i className="material-icons">search</i>
                    )}
                </button>
                {!this.state.disabled && (
                    <div
                        className={classNames('search-panel', {
                            'search-panel__expanded': this.state.expanded,
                        })}
                    >
                        <TabbedBrowser
                            className="flex-it column grow tabbed-browser-new"
                            tabNames={AppConfig.constants.searchBox.tabs.map(
                                tab => ({
                                    name: this.props.intl.formatMessage({
                                        id: tab.name,
                                    }),
                                    showDivider: tab.showDivider,
                                    icon: tab.icon,
                                }),
                            )}
                            selectedTabIndex={this.state.searchType}
                            tabSwitchedCallback={this.changeSearchType}
                            isShowAsTab
                        >
                            {AppConfig.constants.searchBox.tabs.map(tab => {
                                switch (tab.type) {
                                case 'POINT_SEARCH_BOX':
                                    return (
                                        <PointSearchBox
                                            tabPlaceholder={tab.name}
                                            mapInstanceIds={
                                                this.props.mapInstanceIds
                                            }
                                            activeMapInstanceId={
                                                this.props
                                                    .activeMapInstanceId
                                            }
                                            disableMapSelection={
                                                this.props
                                                    .disableMapSelection
                                            }
                                            key="point"
                                        />
                                    );
                                case 'SEARCH_BOX':
                                    return (
                                        <SearchBox
                                            key="location"
                                            mapInstanceIds={
                                                    this.props.mapInstanceIds
                                                }
                                            activeMapInstanceId={
                                                    this.props
                                                        .activeMapInstanceId
                                                }
                                            isReportEditor={
                                                    this.props.isReportEditor
                                                }
                                            onToggleVisibility={
                                                    this.props
                                                        .onToggleVisibility
                                                }
                                            disableMapSelection={
                                                    this.props
                                                        .disableMapSelection
                                                }
                                            isPanelExpanded={this.state.expanded}
                                            focus
                                            forceEnable
                                        />);
                                default:
                                    return <div />;
                                }
                            })
                            }
                        </TabbedBrowser>
                        {AppConfig.constants.searchBox.hasFooter && (
                            <SearchBoxPanelFooter
                                toggleSearchPanel={this.toggleSearchPanel}
                            />
                        )}
                    </div>
                )}
            </div>
        );
        return (search);
    }
}

SearchBoxPanel.propTypes = {
    mapInstanceIds: PropTypes.array.isRequired,
    isReportEditor: PropTypes.bool,
    disableMapSelection: PropTypes.bool,
    activeMapInstanceId: PropTypes.string,
    onToggleVisibility: PropTypes.func,
};

SearchBoxPanel.defaultProps = {
    isReportEditor: false,
    disableMapSelection: false,
    activeMapInstanceId: null,
    onToggleVisibility: undefined,
};

export default injectIntl(SearchBoxPanel);
