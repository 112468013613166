const ExcelNumberFormat = {
    FORMAT_NONE: '',
    FORMAT_PERCENT: '#,##0.0%',

    FORMAT_CURRENCY: '"$"#,##0',

    FORMAT_NUMBER: '#,##0',
    FORMAT_NUMBER_1_DECIMAL: '#,##0.0',
    FORMAT_NUMBER_2_DECIMAL: '#,##0.00',

    FORMAT_NUMBER_NO_FORMAT: '0',
};

export default ExcelNumberFormat;
