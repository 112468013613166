import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import AnnotationMetadata from '../../enums/AnnotationMetadata';
import AppConfig from '../../appConfig';

class AnnotationHelper extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = { hide: false };
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.drawingAnnotation !== nextProps.drawingAnnotation) {
            this.state = { hide: false };
        }
    }

    onClose = () => {
        this.setState({ hide: true });
    };

    onOpen = () => {
        this.setState({ hide: false });
    };

    render() {
        const { drawingAnnotation, intl } = this.props;
        const { hide } = this.state;

        if (hide || localStorage.getItem(drawingAnnotation) === '1') {
            return (
                <div
                    className="draw-annotation-helper-anchor"
                    onClick={this.onOpen}
                >
                    <img
                        className="draw-annotation-helper_image"
                        src={`${AppConfig.constants.assetsBaseURL}/icons/annotationHelp/annotations_helper.svg`}
                        alt={intl.formatMessage({ id: 'dataBrowser.annotationHelp' })}
                    />
                </div>
            );
        }

        const currentDrawMode = AnnotationMetadata[drawingAnnotation];
        return (
            <div className="draw-annotation-helper">
                <div className="draw-annotation-helper__header flex-it space-between center">
                    <span className="body-3">
                        {intl.formatMessage({ id: 'hints.hint' })}
                    </span>
                    <button className="btn-flat-icon" onClick={this.onClose}>
                        <i className="material-icons">keyboard_arrow_down</i>
                    </button>
                </div>
                <div className="draw-annotation-helper__content">
                    <h5 className="draw-annotation-helper__title">
                        {intl.formatMessage({ id: `hints.${currentDrawMode.type}.helperText` })}
                    </h5>
                    <div className="draw-annotation-helper__image-wrapper flex-it justify-center center">
                        <img
                            className="draw-annotation-helper_image"
                            src={`${AppConfig.constants.assetsBaseURL}/icons/annotationHelp/${currentDrawMode.helperImage}.svg`}
                            alt={intl.formatMessage({ id: 'dataBrowser.annotationHelp' })}
                        />
                    </div>
                    <span className="draw-annotation-helper__description">
                        {intl.formatMessage({ id: `hints.${currentDrawMode.type}.helperDescription` })}
                    </span>
                </div>
            </div>
        );
    }
}

AnnotationHelper.propTypes = {
    drawingAnnotation: PropTypes.string.isRequired,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(AnnotationHelper);
