// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import Tooltip from '../../Tooltip';
import YearPicker from './YearPicker';
import Tag from '../../../enums/Tag';

/**
 * @typedef Props
 * @property {import('../../../').SearchResultTable} table
 * @property {import('../../../').TableComparableSurvey} selectedSurvey
 * @property {boolean} expanded
 * @property {() => void} onToggle
 * @property {(selectedSurveyName: string) => void} onSurveyChange
 * @property {boolean} disabled
 * @property {import('react-intl').intlShape} intl
 *
 * @param {Props} props
 */
const TableHeader = props => {
    const tableTitle = props.table.tableTitle.replace(
        Tag.DOLLAR_YEAR,
        props.selectedSurvey.surveyYear.toString(),
    );
    return (
        <div className="data-browser-search-result-table__header">
            <button
                className="flex-it start grow clickable"
                onClick={props.onToggle}
                aria-label={`${props.intl.formatMessage({
                    id: props.expanded
                        ? 'dataBrowser.collapse'
                        : 'dataBrowser.expand',
                })} - ${tableTitle}`}
                aria-disabled={props.disabled}
                disabled={props.disabled}
            >
                <i className="material-icons table-icon">grid_on</i>
                <div className="data-browser-search-result-table__header--title">
                    <Tooltip
                        placement="bottom"
                        mouseEnterDelay={0.2}
                        mouseLeaveDelay={0}
                        overlay={tableTitle}
                    >
                        <h5 className="table-title ellipsis">{tableTitle}</h5>
                    </Tooltip>
                    <div className="table-name">
                        {props.selectedSurvey.surveyName}
                        <div className="bullet bullet--separator" />
                        {props.selectedSurvey.datasetAbbreviation}:{' '}
                        {props.table.tableName}
                    </div>
                </div>
            </button>
            <div className="data-browser-search-result-table__header--actions">
                <YearPicker
                    onChange={props.onSurveyChange}
                    comparableSurveys={props.table.comparableSurveys}
                    selectedSurvey={props.selectedSurvey}
                    disabled={props.disabled}
                />
                <button
                    className="btn-icon"
                    onClick={props.onToggle}
                    aria-label={props.intl.formatMessage({
                        id: props.expanded
                            ? 'dataBrowser.collapse'
                            : 'dataBrowser.expand',
                    })}
                    aria-disabled={props.disabled}
                    disabled={props.disabled}
                >
                    <i className="material-icons">
                        {props.expanded ? 'unfold_less' : 'unfold_more'}
                    </i>
                </button>
            </div>
        </div>
    );
};

TableHeader.propTypes = {
    table: PropTypes.object.isRequired,
    selectedSurvey: PropTypes.object.isRequired,
    expanded: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired,
    onSurveyChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(TableHeader);
