/* eslint react/no-array-index-key: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import VerticalShadedStop from './VerticalShadedStop';

class ShadedColorPaletteVertical extends React.Component {
    render() {
        const {
            mapInstanceId,
            insufficientDataRuleIndex,
            rules,
            variableIdx,
            nullDataRuleIndex,
            legendHighlighted,
        } = this.props;

        const rulesItems = [];
        const insufficientDataRule = insufficientDataRuleIndex !== undefined &&
            insufficientDataRuleIndex >= 0 &&
            (<VerticalShadedStop
                mapInstanceId={mapInstanceId}
                rule={rules[insufficientDataRuleIndex]}
                legendHighlighted={legendHighlighted}
                isInsufficientDataRule
                onClick={this.onVerticalStopClicked}
                ruleIdx={insufficientDataRuleIndex}
            />);

        rules.forEach((rule, ruleIdx) => ruleIdx !== nullDataRuleIndex &&
            ruleIdx !== insufficientDataRuleIndex &&
            rulesItems.push(<VerticalShadedStop
                mapInstanceId={mapInstanceId}
                rule={rule}
                legendHighlighted={legendHighlighted}
                variableIdx={variableIdx}
                key={ruleIdx}
                ruleIdx={ruleIdx}
            />));

        return (<div className="shaded-color-palette-vertical">
            {insufficientDataRule}
            {rulesItems}
        </div>);
    }
}

ShadedColorPaletteVertical.propTypes = {
    rules: PropTypes.array.isRequired,
    variableIdx: PropTypes.number,
    mapInstanceId: PropTypes.string.isRequired,
    insufficientDataRuleIndex: PropTypes.number.isRequired,
    nullDataRuleIndex: PropTypes.number.isRequired,
    legendHighlighted: PropTypes.bool,
};

ShadedColorPaletteVertical.defaultProps = {
    variableIdx: undefined,
    legendHighlighted: false,
};

export default ShadedColorPaletteVertical;
