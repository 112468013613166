class LibraryGroup {
    constructor(values) {
        if (values) {
            Object.keys(values).forEach(k => {
                if (this.constructor.prototype.hasOwnProperty(k)) {
                    this[k] = values[k];
                }
            });
        }
    }

    get id() {
        return this._id;
    }

    set id(id) {
        this._id = id;
    }

    get title() {
        return this._title;
    }

    set title(title) {
        this._title = title;
    }

    get visible() {
        return this._visible;
    }

    set visible(visible) {
        this._visible = visible;
    }

    get metadata() {
        return this._metadata;
    }

    set metadata(metadata) {
        this._metadata = metadata;
    }

    get sources() {
        return this._sources;
    }

    set sources(sources) {
        this._sources = sources;
    }

    get layersDefinition() {
        return this._layersDefinition;
    }

    set layersDefinition(layersDefinition) {
        this._layersDefinition = layersDefinition;
    }

    /** @type {import('./LibraryLayer').default[]} */
    get layers() {
        return this._layers;
    }

    set layers(layers) {
        this._layers = layers;
    }

    equals(that) {
        return (this.id === that.id &&
            this.title === that.title &&
            this.visible === that.visible &&
            JSON.stringify(this.metadata) === JSON.stringify(that.metadata) &&
            JSON.stringify(this.sources) === JSON.stringify(that.sources) &&
            JSON.stringify(this.layersDefinition) === JSON.stringify(that.layersDefinition) &&
            JSON.stringify(this.layers) === JSON.stringify(that.layers));
    }

    clone() {
        const libraryGroup = new LibraryGroup();
        libraryGroup.id = this.id;
        libraryGroup.title = this.title;
        libraryGroup.visible = this.visible;
        libraryGroup.metadata = this.metadata;
        libraryGroup.sources = this.sources;
        libraryGroup.layersDefinition = this.layersDefinition;
        libraryGroup.layers = this.layers;
        return libraryGroup;
    }

    toJSON() {
        return {
            id: this.id,
            title: this.title,
            visible: this.visible,
            metadata: this.metadata,
            sources: this.sources,
            layersDefinition: this.layersDefinition,
            layers: this.layers,
        };
    }

}

export default LibraryGroup;
