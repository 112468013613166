import React from 'react';
import PropTypes from 'prop-types';

const FileUploadError = props => (
    <div className="file-upload-error">
        <div className="file-upload-error__wrapper flex-it space-between">
            <div className="file-upload-error__text">
                <strong className="file-upload-error__label">Upload failed. </strong>
                {props.error}
            </div>
            <button className="btn-flat btn-flat--red-light" onClick={props.onErrorOKClick}>OK</button>
        </div>
    </div>);

FileUploadError.propTypes = {
    error: PropTypes.string.isRequired,
    onErrorOKClick: PropTypes.func.isRequired,
};

export default FileUploadError;
