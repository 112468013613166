import React from 'react';
import { injectIntl } from 'react-intl';
import BusComponent from '../../components/BusComponent';
import DataClassificationEvents from '../../enums/DataClassificationEvents';
import DataClassificationChart from './DataClassificationChart';
import CutpointValueInput from './CutpointValueInput';
import NumberPicker from '../NumberPicker';
import SimpleDropdown from '../dropdown/SimpleDropdown';
import FileInput from '../form/FileInput';
import Checkbox from '../form/Checkbox';
import Loader from '../Loader';
import FileUploadError from '../uploadFile/FileUploadError';
import classNames from 'classnames';

class DataClassificationPopup extends BusComponent {
    constructor(props, context) {
        super(props, context);
        const chartId = `${context.appGuid}_dsChart`;
        this.state = {
            chartId,
            allowedBreaks: [],
            currentBreaks: '',
            currentMethods: [],
            currentMethod: undefined,
            mapInstanceId: this.props.mapInstanceId,
            popupTitle: '',
            insufficientValue: undefined,
            dsOptions: {
                variablesData: undefined,
                xmin: 0,
                xmax: 100,
                ymin: 0,
                ymax: 100,
                colorPalettes: [],
                filterSet: {},
            },
            error: undefined,
            shouldApplyToBothMaps: false,
        };

        this.bindGluBusEvents({
            DATA_CLASSIFICATION_DATA_REQUEST_DONE: this.onDataRequestDone,
            CLASSIFICATION_METHOD_CHANGE_DONE: this.onClassificationMethodChangeDone,
            CLASSES_NUMBER_CHANGE_DONE: this.onClassificationNumberChangeDone,
            DATA_CLASSIFICATION_DATA_REQUEST_ERROR: this.onDataRequestError,
            LOAD_CUT_POINTS_FROM_FILE_ERROR: this.onLoadCutPointsFromFileError,
        });

        this.onClassNumberChangeEvent = this.onClassNumberChange;
        this.onInsufficientBaseChangeEvent = this.onInsufficientBaseChange;
        this.onApplyDsChangeEvent = this.onApplyDsChange;
    }

    componentDidMount() {
        this.emit(DataClassificationEvents.DATA_CLASSIFICATION_DATA_REQUEST, { mapInstanceId: this.props.mapInstanceId });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    onDataRequestDone(payload) {
        this.setState({
            popupTitle: payload.popupTitle,
            allowedBreaks: payload.allowedBreaks,
            currentBreaks: payload.currentBreaks,
            currentMethods: payload.currentMethods,
            currentMethod: payload.currentMethods.find(m => m.value === payload.currentMethod),
            insufficientValue: payload.insufficientValue,
            canApplyToBothMaps: payload.canApplyToBothMaps,
            dsOptions: payload.dsOptions,
            error: undefined,
        });
    }

    onDataRequestError(payload) {
        this.setState({
            error: payload.additionalInfo,
        });
    }

    onClassificationMethodChange = value => {
        const method = this.state.currentMethods.find(m => m.value === value);
        this.setState({
            currentMethod: method,
        });
        this.emit(DataClassificationEvents.CLASSIFICATION_METHOD_CHANGE, {
            value: method.value,
            mapInstanceId: this.props.mapInstanceId,
        });
        this.emit('CLOSE_DROPDOWN');
    };

    onClassNumberChange = number => {
        const newBreaks = `${number}`;
        if (this.state.currentBreaks === newBreaks) {
            return;
        }
        this.setState({
            currentBreaks: newBreaks,
        });
        this.emit(DataClassificationEvents.CLASSES_NUMBER_CHANGE, {
            value: newBreaks,
            mapInstanceId: this.props.mapInstanceId,
        });
    };

    onApplyDsChange = () => {
        this.emit(DataClassificationEvents.CUT_POINTS_APPLY, {
            mapInstanceId: this.props.mapInstanceId,
            shouldApplyToBothMaps: this.state.shouldApplyToBothMaps,
        });
        this.emit('CLOSE_MODAL');
    };

    onClassificationMethodChangeDone = payload => {
        if (payload && payload.hasOwnProperty('currentMethod')) {
            this.setState({
                currentMethod: this.state.currentMethods.find(m => m.value === payload.currentMethod),
            });
        }
    };

    onClassificationNumberChangeDone(payload) {
        if (payload && payload.hasOwnProperty('currentBreaks')) {
            this.setState({
                currentBreaks: payload.currentBreaks,
            });
        }
    }

    onInsufficientBaseChange = number => {
        this.emit(DataClassificationEvents.INSUFFICIENT_BASE_CHANGE, {
            insufficientValue: number,
            mapInstanceId: this.props.mapInstanceId,
        });
    };

    onLoadCutPointsFromFile = files => {
        this.setState({ loadCutPointsFromFileError: undefined });
        this.emit(DataClassificationEvents.LOAD_CUT_POINTS_FROM_FILE_REQUEST, { files });
    };

    onSaveToFileClick = () => {
        this.setState({ loadCutPointsFromFileError: undefined });
        this.emit(DataClassificationEvents.SAVE_CUT_POINTS_TO_FILE_REQUEST);
    };

    onLoadCutPointsFromFileError = event => {
        this.setState({ loadCutPointsFromFileError: event.error },
            () => {
                this.chart.forceReRender();
            });
    };

    onErrorOKClick = () => {
        this.setState({ loadCutPointsFromFileError: undefined },
            () => {
                this.chart.forceReRender();
            });
    };

    render() {
        const { intl } = this.props;
        if (this.state.error) {
            return (<div className="data-classification-body flex-it column stretch">
                <div className="data-classification-header flex-it center space-between">
                    <h1>
                        {intl.formatMessage({ id: 'visualisationType.editCutpoints' })}
                    </h1>
                    <button className="btn-icon">
                        <i className="material-icons modal-header__modal-close">close</i>
                    </button>
                </div>
                <div className="data-classification-error flex-it justify-center center font-bold font-15">{this.state.error}</div>
            </div>);
        }
        const actionsClasses = classNames('data-classification-actions flex-it center', { 'space-between': this.state.insufficientValue });

        return (<div className="data-classification-body flex-it column stretch">
            <div className="data-classification-header flex-it center space-between">
                <h1>
                    {intl.formatMessage({ id: 'visualisationType.editCutpoints' })}
                </h1>
                <div className="flex-it flex-end">
                    <FileInput
                        accept=".ctp"
                        onChange={this.onLoadCutPointsFromFile}
                        className="btn-flat"
                    />
                    <div className="data-classification-header__divider" />
                    <div onClick={this.onSaveToFileClick}>
                        <i className="material-icons btn-icon">file_download</i>
                    </div>
                    <div className="data-classification-header__divider" />
                    <i className="material-icons btn-icon modal-header__modal-close">close</i>
                </div>
            </div>
            <div className="data-classification-content flex-it flex-column grow">
                { this.state.loadCutPointsFromFileError && <FileUploadError error={this.state.loadCutPointsFromFileError} onErrorOKClick={this.onErrorOKClick} />}
                <div className={actionsClasses}>
                    <div className="nr-of-classes">
                        <div className="nr-of-classes__text">
                            {intl.formatMessage({ id: 'visualisationType.numberOfClasses' })}
                        </div>
                        <NumberPicker
                            allowedNumbers={this.state.allowedBreaks}
                            mapInstanceId={this.props.mapInstanceId}
                            currentNumber={this.state.currentBreaks}
                            onNumberChange={this.onClassNumberChangeEvent}
                        />
                    </div>
                    <div className="classification-method">
                        <div className="classification-method__text">
                            {intl.formatMessage({ id: 'visualisationType.classificationMethod' })}
                        </div>
                        <SimpleDropdown
                            onItemClick={this.onClassificationMethodChange}
                            mapInstanceId={this.props.mapInstanceId}
                            items={this.state.currentMethods.map(method => ({ id: method.value, text: intl.formatMessage({ id: method.name }) }))}
                            selectedItem={this.state.currentMethod ? {
                                id: this.state.currentMethod.value,
                                text: intl.formatMessage({ id: this.state.currentMethod.name }),
                            } : undefined}
                        />
                    </div>
                    {this.state.insufficientValue && (
                    <div className="insufficient-base">
                        <div className="insufficient-base__text">
                            {intl.formatMessage({ id: 'visualisationType.insufficientBase' })}
                        </div>
                        <NumberPicker
                            min={0.01}
                            mapInstanceId={this.props.mapInstanceId}
                            currentNumber={this.state.insufficientValue}
                            onNumberChange={this.onInsufficientBaseChangeEvent}
                        />
                    </div>)}
                </div>

                <div className="data-classification-panel grow">
                    <CutpointValueInput />
                    {this.state.dsOptions.variablesData ? <DataClassificationChart
                        ref={chart => { this.chart = chart; }}
                        chartID={this.state.chartId}
                        className="dsChartHolder"
                        width="100%"
                        height="100%"
                        dsOptions={this.state.dsOptions}
                    /> : <Loader text={intl.formatMessage({ id: 'variablePicker.gettingCurrentData' })} />}
                </div>
            </div>
            <div className="data-classification-footer flex-it center space-between">
                {this.state.canApplyToBothMaps && <Checkbox
                    label={intl.formatMessage({ id: 'variablePicker.applyToBothMaps' })}
                    checked={this.state.shouldApplyToBothMaps}
                    onCheck={checked => this.setState({ shouldApplyToBothMaps: checked })}
                />}
                <div className="data-classification-footer__controls flex-it center">
                    <button className="btn-flat btn-flat--gray modal-header__modal-close">
                        {intl.formatMessage({ id: 'cancel' })}
                    </button>
                    <button className="btn-raised" onClick={this.onApplyDsChangeEvent}>
                        {intl.formatMessage({ id: 'done' })}
                    </button>
                </div>
            </div>
        </div>);
    }
}

export default injectIntl(DataClassificationPopup);
