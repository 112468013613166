// @ts-check
import React from 'react';
import { injectIntl } from 'react-intl';
import BusComponent from '../BusComponent';

import MapLayers from './MapLayers';
import MapLayersEditorActions from './MapLayersEditorActions';
import UserDataUploads from '../userDataUploadEditor/UserDataUploads';
import UserDataLayerEditor from '../userDataUploadEditor/UserDataLayerEditor';
import Panel from '../panel/Panel';
import MapAnnotator from '../mapAnnotator/MapAnnotator';
import MapLayersEditorHeader from './MapLayersEditorHeader';
import Upgrade from '../upgrade/Upgrade';

import AppConfig from '../../appConfig';

export const ActiveView = {
    INITIAL_VIEW: 0,
    DATA_UPLOAD: 1,
    SETTINGS: 2,
    ANNOTATIONS: 3,
    USER_LAYER_EDIT: 4,
};

class MapLayersEditor extends BusComponent {
    constructor(props, context) {
        super(props, context);

        this.bindGluBusEvents({
            EDIT_USER_LAYER_REQUEST: this.onEditUserLayerRequest,
        });

        this.state = {
            activeView: props.initialView || ActiveView.INITIAL_VIEW,
        };
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.initialView !== nextProps.initialView) {
            this.setState({ activeView: nextProps.initialView });
        }
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    onEditUserLayerRequest(userDataLayer) {
        this.setState({ activeView: ActiveView.USER_LAYER_EDIT, userDataLayerId: userDataLayer.id });
    }

    onClose = () => {
        this.emit('HIDE_MAP_LAYERS_EDITOR');
    }

    onEditUserLayer = userDataLayerId => {
        this.setState({ activeView: ActiveView.USER_LAYER_EDIT, userDataLayerId });
    }

    onDataUploadClick = () => {
        if (this.props.isUserPro) {
            this.setState({ activeView: ActiveView.DATA_UPLOAD });
        } else {
            this.emit('LOGIN_REQUEST', { checkCount: 30, url: AppConfig.constants.links.licenses });
        }
    }

    onAddLayersClick = () => {
        const { mapInstanceId, mapInstance } = this.props;

        this.emit('ADD_LAYER_LIBRARY_LAYERS_POPUP_REQUEST', {
            mapInstanceId,
            mapInstance,
        });
    }

    onSetInitialView = () => {
        this.setState({ activeView: ActiveView.INITIAL_VIEW });
    }

    render() {
        const { isUserPro, mapInstance, intl } = this.props;
        let content, upgrade;

        switch (this.state.activeView) {
        case ActiveView.DATA_UPLOAD:
            content = (<UserDataUploads
                onBack={this.onSetInitialView}
                onClose={this.onClose}
                mapInstanceId={mapInstance.id}
            />);
            break;
        case ActiveView.USER_LAYER_EDIT:
            content = (<UserDataLayerEditor
                mapInstanceId={mapInstance.id}
                userDataLayerId={this.state.userDataLayerId}
                onBack={this.onSetInitialView}
            />);
            break;
        case ActiveView.ANNOTATIONS:
            content = (<MapAnnotator
                onBack={this.onSetInitialView}
                onClose={this.onClose}
                mapInstance={mapInstance}
            />);
            break;
        default: {
            if (!isUserPro) {
                upgrade = (<Upgrade
                    text={intl.formatMessage({ id: 'header.upgradeToProfessionalPlan' })}
                    className="flex-fixed"
                />);
            }

            content = (<Panel
                className="panel--layers-editor"
                customHeader={<MapLayersEditorHeader
                    onClose={this.onClose}
                />}
            >
                <div className="map-layers-editor__content flex-it column grow">
                    {upgrade}
                    <MapLayersEditorActions
                        isUserPro={isUserPro}
                        onAddLayersClick={this.onAddLayersClick}
                        onDataUploadClick={this.onDataUploadClick}
                    />
                    <div className="map-layers-editor__layers vertical-scrollbar flex-it column">
                        <MapLayers
                            mapInstanceId={mapInstance.id}
                            mapInstance={mapInstance}
                            onEditUserLayer={this.onEditUserLayer}
                        />
                    </div>
                </div>
            </Panel>
            );
        }
        }

        return (<div className="map-layers-editor flex-column flex-stretch">
            {content}
        </div>);
    }
}

export default injectIntl(MapLayersEditor);
