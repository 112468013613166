// @ts-check
import React from 'react';
import PropTypes from 'prop-types';

import BusComponent from '../BusComponent';
import PointSearchResultListItem from './PointSearchResultListItem';

/**
 * @typedef Props
 * @property {import('@turf/helpers').Feature[]} results
 * @property {string[]} searchFields
 *
 * @extends {BusComponent<Props>}
 */
class PointSearchResultList extends BusComponent {
    render() {
        if (!this.props.results.length) return null;
        return (
            <div className="search-results search-results--points">
                <ul className="search-results__list">
                    {this.props.results.map((feature, index) => (
                        <PointSearchResultListItem
                            key={index} // eslint-disable-line react/no-array-index-key
                            feature={feature}
                            searchFields={this.props.searchFields}
                            mapInstanceId={this.props.mapInstanceId}
                        />
                    ))}
                </ul>
            </div>
        );
    }
}

PointSearchResultList.propTypes = {
    results: PropTypes.array,
    searchFields: PropTypes.array,
    mapInstanceId: PropTypes.string,
};

PointSearchResultList.defaultProps = {
    results: [],
    searchFields: [],
};

export default PointSearchResultList;
