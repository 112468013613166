import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from '../../form/Checkbox';
import LayerLibraryGroupCardHeaderMenu from './LayerLibraryGroupCardHeaderMenu';

class LayerLibraryGroupCardHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
        };
    }

    onToggleVisibility = visible => {
        this.props.onToggleVisibility(visible);
        this.setState({ visible });
    }

    onToggleExpanded = () => {
        this.props.onToggleExpanded();
        this.setState({ expanded: !this.state.expanded });
    }

    render() {
        const { id, title, checked } = this.props;
        const { expanded } = this.state;

        const arrowIcon = expanded ? 'arrow_drop_down' : 'arrow_right';

        return (<div className="map-library__header flex-it">
            <div className="flex-it row center grow clickable" onClick={this.onToggleExpanded}>
                <div className="flex-it center">
                    <i className="material-icons">{arrowIcon}</i>
                </div>
                <div className="map-library__group-title-wrapper padded-left-10">
                    <span className="map-library__group-title">{title}</span>
                </div>
            </div>
            <div className="flex-it row center">
                <div className="padded-right-15">
                    <LayerLibraryGroupCardHeaderMenu
                        id={id}
                    />
                </div>
                <div className="flex-it center margin-left-15">
                    <Checkbox
                        checked={checked}
                        onCheck={this.onToggleVisibility}
                    />
                </div>
            </div>
        </div>);
    }
}

LayerLibraryGroupCardHeader.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    onToggleExpanded: PropTypes.func.isRequired,
    onToggleVisibility: PropTypes.func.isRequired,
};

LayerLibraryGroupCardHeader.defaultProps = {
    checked: undefined,
};

export default LayerLibraryGroupCardHeader;
