import {
    Polygon,
    Polyline,
    Freehand,
    FlowArrow,
    Marker,
    Image,
    Hotspot,
    Shape,
    Label,
    Curve,
} from '../../objects/annotations';

export const annotationClasses = {
    Polygon,
    Polyline,
    Freehand,
    FlowArrow,
    Marker,
    Image,
    Hotspot,
    Shape,
    Label,
};
import { getFlowArrowCurves } from '../../helpers/Bezier';

const featureSourceTypes = {
    HOTSPOT: 'hotspot',
    FLOWARROW: 'flowarrow',
    FREEHAND: 'freehand',
    SHAPE: 'shape',
    MARKER: 'marker',
    LABEL: 'label',
    IMAGE: 'image',
};

class AnnotationData {
    build({ feature, zoom, dragonflyMap }) {
        switch (feature.geometry.type) {
        case 'Polygon':
            if (feature.properties.source_for === featureSourceTypes.HOTSPOT) {
                return this.createDefaultHotspot({ feature, zoom });
            }
            return this.createDefaultPolygon({ feature, zoom });
        case 'LineString':
            if (feature.properties.source_for === featureSourceTypes.FLOWARROW) {
                return this.createDefaultFlowArrow({ feature, zoom, dragonflyMap });
            } else if (feature.properties.source_for === featureSourceTypes.FREEHAND) {
                return this.createDefaultFreehand({ feature, zoom });
            } else if (feature.properties.source_for === featureSourceTypes.SHAPE) {
                return this.createDefaultShape({ feature, zoom });
            }
            return this.createDefaultPolyline({ feature, zoom });
        case 'Point':
            if (feature.properties.source_for === featureSourceTypes.MARKER) {
                return this.createDefaultMarker({ feature, zoom });
            } else if (feature.properties.source_for === featureSourceTypes.LABEL) {
                return this.createDefaultLabel({ feature, zoom });
            } else if (feature.properties.source_for === featureSourceTypes.IMAGE) {
                return this.createDefaultImage({ feature, zoom, dragonflyMap });
            }
        }
        return undefined;
    }


    createDefaultPolygon({ feature, zoom }) {
        const coordinates = feature.geometry.coordinates[0].slice(0);
        coordinates.splice(0, 1);
        return new Polygon({
            id: feature.id,
            coordinates: coordinates.reverse(),
            title: 'Untitled',
            description: '',
            strokeColor: '#333333',
            strokeWeight: 'normal',
            createdAtZoomLevel: zoom,
            fillColor: '#747474',
            opacity: 1,
            useFill: true,
        });
    }

    createDefaultHotspot({ feature, zoom }) {
        return new Hotspot({
            id: feature.id,
            title: 'Untitled',
            description: '',
            strokeColor: '#333333',
            strokeWeight: 'normal',
            createdAtZoomLevel: zoom,
            opacity: 1,
            topLeftPoint: feature.geometry.coordinates[0].find(c => feature.geometry.coordinates[0].every(o => c[0] <= o[0] && c[1] >= o[1])),
            bottomRightPoint: feature.geometry.coordinates[0].find(c => feature.geometry.coordinates[0].every(o => c[0] >= o[0] && c[1] <= o[1])),
        });
    }


    createDefaultPolyline({ feature, zoom }) {
        return new Polyline({
            id: feature.id,
            coordinates: feature.geometry.coordinates.slice(0),
            title: 'Untitled',
            description: '',
            strokeColor: '#333333',
            strokeWeight: 'normal',
            createdAtZoomLevel: zoom,
            opacity: 1,
        });
    }

    createDefaultFlowArrow({ feature, zoom, dragonflyMap }) {
        const coordinates = feature.geometry.coordinates.slice(0);
        return new FlowArrow({
            id: feature.id,
            coordinates: feature.geometry.coordinates.slice(0),
            curves: this.generateCurves({ coordinates, dragonflyMap }),
            title: 'Untitled',
            description: '',
            strokeColor: '#000000',
            strokeWeight: 'normal',
            createdAtZoomLevel: zoom,
            fillColor: '#000000',
            toWidth: 8,
            fromWidth: 8,
            tipWidth: 12,
            opacity: 1,
            useFill: true,
        });
    }

    createDefaultFreehand({ feature, zoom }) {
        const coordinates = feature.geometry.coordinates.slice(0);
        coordinates.splice(0, 1);
        return new Freehand({
            id: feature.id,
            coordinates,
            title: 'Untitled',
            description: '',
            strokeColor: '#333333',
            createdAtZoomLevel: zoom,
            strokeWeight: 'normal',
            opacity: 1,
        });
    }

    createDefaultShape({ feature, zoom }) {
        return new Shape({
            id: feature.id,
            coordinates: feature.geometry.coordinates.slice(0),
            title: 'Untitled',
            description: '',
            strokeColor: '#333333',
            strokeWeight: 'normal',
            createdAtZoomLevel: zoom,
            fillColor: '#747474',
            opacity: 1,
            useFill: true,
        });
    }

    createDefaultMarker({ feature, zoom }) {
        return new Marker({
            id: feature.id,
            coordinates: [feature.geometry.coordinates],
            title: 'Untitled',
            markerPathId: 'marker-default',
            description: '',
            fillColor: '#ff0000',
            labelVisible: false,
            rotationalAngle: 0,
            labelPosition: 'bottom',
            createdAtZoomLevel: zoom,
            textColor: '#000000',
            textSize: 'medium',
            opacity: 1,
            size: 'medium',
        });
    }

    createDefaultLabel({ feature, zoom }) {
        return new Label({
            id: feature.id,
            coordinates: [feature.geometry.coordinates],
            title: 'Untitled',
            useFill: false,
            description: '',
            haloWidth: 1,
            haloBlur: 1,
            rotationAngle: 0,
            createdAtZoomLevel: zoom,
            textColor: '#000000',
            fillColor: '#ffffff',
            textSize: 'medium',
            opacity: 1,
            size: 'medium',
        });
    }

    createDefaultImage({ feature, zoom, dragonflyMap }) {
        const projectedCenter = dragonflyMap.project(feature.geometry.coordinates);
        return new Image({
            id: feature.id,
            title: 'Untitled',
            rotationAngle: 0,
            fileName: '',
            description: '',
            createdAtZoomLevel: zoom,
            opacity: 1,
            topLeftPoint: dragonflyMap.unproject({
                x: projectedCenter.x - 80,
                y: projectedCenter.y - 50,
            }).toArray(),
            bottomRightPoint: dragonflyMap.unproject({
                x: projectedCenter.x + 80,
                y: projectedCenter.y + 50,
            }).toArray(),
        });
    }

    generateCurves({ coordinates, dragonflyMap }) {
        const points = coordinates.map(c => dragonflyMap.project(c));
        const curves = getFlowArrowCurves(points);
        return curves.map(c => new Curve({
            anchorPoint1: dragonflyMap.unproject(c[0]).toArray(),
            controlPoint1: dragonflyMap.unproject(c[1]).toArray(),
            anchorPoint2: dragonflyMap.unproject(c[2]).toArray(),
            controlPoint2: dragonflyMap.unproject(c[3]).toArray(),
        }));
    }

}

export default AnnotationData;
