import React from 'react';
import PropTypes from 'prop-types';

import BusComponent from '../BusComponent';
import DataUploadOptions from './DataUploadOptions';
import LatLongAndGeocode from './LatLongAndGeocode';

class UserDataUploads extends BusComponent {
    constructor(props, context) {
        super(props, context);

        this.bindGluBusEvents({
            USER_DATA_UPLOAD_PARSED: this.onDataUploadParsed,
            USER_DATA_ERROR: this.onUserDataError,
        });

        this.state = {
            step: DataUploadOptions.componentName,
        };
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    onUserDataError(payload) {
        const { type, userMessage: error } = payload;
        switch (type) {
            case 'UPLOAD_FILE_ERROR':
                this.setState({ step: DataUploadOptions.componentName, error });
                break;
            default:
                break;
        }
    }

    onDataUploadParsed({ userUpload }) {
        this.setState({ userUpload, step: LatLongAndGeocode.componentName, error: undefined });
    }

    onLatLongBack = () => {
        this.setState({
            step: DataUploadOptions.componentName,
            error: undefined,
            selection: undefined,
        });
    };

    onClearError = () => {
        this.setState({ error: undefined });
    };

    render() {
        let content;
        const { step, userUpload, selection, error } = this.state;
        const { mapInstanceId, onClose, onBack } = this.props;

        switch (step) {
            case LatLongAndGeocode.componentName:
                content = (
                    <LatLongAndGeocode
                        mapInstanceId={mapInstanceId}
                        userUpload={userUpload}
                        error={error}
                        selection={selection}
                        onBack={this.onLatLongBack}
                        onClose={onClose}
                    />
                );
                break;
            case DataUploadOptions.componentName:
                content = (
                    <DataUploadOptions
                        mapInstanceId={mapInstanceId}
                        error={error}
                        onClearError={this.onClearError}
                        onBack={onBack}
                        onClose={onClose}
                    />
                );
                break;
        }
        return content;
    }
}

UserDataUploads.propTypes = {
    mapInstanceId: PropTypes.string.isRequired,
};

UserDataUploads.defaultProps = {};

export default UserDataUploads;
