//!

const StyleLayer = require('../style_layer');
const DotDensityBucket = require('../../data/bucket/dotdensity_bucket');

class DotDensityStyleLayer extends StyleLayer {
    constructor(layer) {
        layer.layout['dotdensity-seed'] = Math.random();
        super(layer);
    }

    queryRadius() {
        return 0;
    }

    createBucket(parameters) {
        return new DotDensityBucket(parameters);
    }
}

module.exports = DotDensityStyleLayer;
