import React from 'react';
import BusComponent from '../../BusComponent';

class AddGradientStop extends BusComponent {
    onAddHandle = () => {
        this.props.onAddHandle(this.props.ramp);
    };

    render() {
        return (<i
            ref={this.props.elementRef} onClick={this.onAddHandle}
            className="material-icons gradient-container__add-handle-icon"
        >add_circle</i>);
    }
}

export default AddGradientStop;
