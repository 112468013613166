import React from 'react';
import PropTypes from 'prop-types';

const BubbleGraphics = props => (
    <div className="bubble-size__graph flex-start">
        <svg width="122px" height="139px" viewBox="0 0 122 122">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Bubble-size" fillRule="nonzero">
                    <path d="M60,1.50543478 C93.137085,1.50543478 120,28.3683498 120,61.5054348 C120,94.6425198 93.137085,121.505435 60,121.505435 C26.862915,121.505435 4.05812251e-15,94.6425198 0,61.5054348 C-4.05812251e-15,28.3683498 26.862915,1.50543478 60,1.50543478 Z" id="Rectangle-5-Copy-66" stroke="#DDDDDD" fill="#F0F0F0" />
                    <path d="M60,41.5054348 C82.09139,41.5054348 100,59.4140448 100,81.5054348 C100,103.596825 82.09139,121.505435 60,121.505435 C37.90861,121.505435 20,103.596825 20,81.5054348 C20,59.4140448 37.90861,41.5054348 60,41.5054348 Z" id="Rectangle-5-Copy-68" strokeOpacity="0.08" stroke="#000000" fillOpacity="0.03" fill="#000000" />
                    <path d="M60,65.5054348 C75.463973,65.5054348 88,78.0414618 88,93.5054348 C88,108.969408 75.463973,121.505435 60,121.505435 C44.536027,121.505435 32,108.969408 32,93.5054348 C32,78.0414618 44.536027,65.5054348 60,65.5054348 Z" id="Rectangle-5-Copy-70" strokeOpacity="0.08" stroke="#000000" fillOpacity="0.03" fill="#000000" />
                    <path d="M60,89.5054348 C68.836556,89.5054348 76,96.6688788 76,105.505435 C76,114.341991 68.836556,121.505435 60,121.505435 C51.163444,121.505435 44,114.341991 44,105.505435 C44,96.6688788 51.163444,89.5054348 60,89.5054348 Z" id="Rectangle-5-Copy-72" strokeOpacity="0.08" stroke="#000000" fillOpacity="0.03" fill="#000000" />
                    <path d="M60,1.00543478 L145,1.00543478" id="Combined-Shape-Copy-2" stroke="#747474" strokeLinecap="round" strokeDasharray="1,3" />
                    <path d="M60.5,41.5054348 L145.5,41.5054348" id="Line-10-Copy-7" stroke="#747474" strokeLinecap="round" strokeDasharray="1,3" />
                    <path d="M60.5,65.5054348 L145.5,65.5054348" id="Line-10-Copy-9" stroke="#747474" strokeLinecap="round" strokeDasharray="1,3" />
                    <path d="M60.5,89.5054348 L145.5,89.5054348" id="Line-10-Copy-11" stroke="#747474" strokeLinecap="round" strokeDasharray="1,3" />
                </g>
            </g>
        </svg>
        <div className="bubble-size__labels flex-column">
            <span className="bubble-size__label">{props.veryHighCircleLabel}</span>
            <span className="bubble-size__label">{props.highCircleLabel}</span>
            <span className="bubble-size__label">{props.mediumCircleLabel}</span>
            <span className="bubble-size__label">{props.lowCircleLabel}</span>
        </div>
    </div>);

BubbleGraphics.propTypes = {
    veryHighCircleLabel: PropTypes.string.isRequired,
    highCircleLabel: PropTypes.string.isRequired,
    mediumCircleLabel: PropTypes.string.isRequired,
    lowCircleLabel: PropTypes.string.isRequired,
};

export default BubbleGraphics;
