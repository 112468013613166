import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import BusComponent from '../BusComponent';
import IconMenu from '../iconMenu/IconMenu';
import AnnotationExportTypes from '../../enums/AnnotationExportTypes';

const HORIZONTAL_OFFSET = 10;
const VERTICAL_OFFSET = 10;

class AnnotationExportButton extends BusComponent {
    onExportClick = type => {
        this.props.onExportClicked({ type });
        this.emit('CLOSE_DROPDOWN');
    }

    render() {
        const { disabled } = this.props;

        return (
            <IconMenu
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                targetOrigin={{ vertical: 'top', horizontal: 'left' }}
                marginLeft={HORIZONTAL_OFFSET}
                marginTop={VERTICAL_OFFSET}
                icon="get_app"
                tooltip={this.props.intl.formatMessage({ id: 'export' })}
                ariaLabel="Export annotations"
                disabled={disabled}
            >
                <div className="material-menu">
                    <div className="material-menu__heading">
                        Export annotations as
                    </div>
                    <div className="material-menu__item" onClick={() => this.onExportClick(AnnotationExportTypes.GEOJSON)}>
                        <span>GeoJSON</span>
                    </div>
                    <div className="material-menu__item" onClick={() => this.onExportClick(AnnotationExportTypes.KML)}>
                        <span>KML</span>
                    </div>
                </div>
            </IconMenu>
        );
    }
}

AnnotationExportButton.propTypes = {
    disabled: PropTypes.bool,
    onExportClicked: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
};

AnnotationExportButton.defaultProps = {
    disabled: false,
};

export default injectIntl(AnnotationExportButton);
