import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import AppConfig from '../../appConfig';

const UserLocationEmptyList = ({ intl, onClose }) => (
    <div className="user-locations-list__empty">
        <div className="user-locations-list__empty--description">
            <div className="user-locations-header">
                {intl.formatMessage({
                    id: 'userLocation.myPlaces',
                })}
                <button
                    className="btn-icon"
                    onClick={onClose}
                >
                    <i className="material-icons">close</i>
                </button>
            </div>
            <div className="user-locations-text">
                {intl.formatMessage({
                    id: 'userLocation.description',
                })}
            </div>
        </div>
        <div className="flex-it justify-center">
            <img
                src={`${AppConfig.constants.assetsBaseURL}/svg/my-locations.svg`}
                alt={intl.formatMessage({
                    id: 'userLocation.myPlaces',
                })}
            />
        </div>
    </div>
);

UserLocationEmptyList.propTypes = {
    onClose: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(UserLocationEmptyList);
