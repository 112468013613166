import React from 'react';
import PropTypes from 'prop-types';

import { getDistance } from '../../helpers/GeoSpatialHelper';

/**
 * @param {object} props
 * @param {import('mapbox-gl').LngLat | import('../../').Point} props.startPoint
 * @param {import('../../').Point} props.endPoint
 */
const Distance = ({ startPoint, endPoint }) => {
    const distanceInMiles = getDistance(startPoint, endPoint);

    return <div>{distanceInMiles} miles</div>;
};

Distance.propTypes = {
    startPoint: PropTypes.object.isRequired,
    endPoint: PropTypes.object.isRequired,
};

export default Distance;
