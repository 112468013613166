// @ts-check
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import BusComponent from '../BusComponent';

import Popover from '../popover/Popover';

/**
 * @typedef Props
 * @property {object} intl
 * @property {string} mapInstanceId
 *
 * @typedef State
 * @property {boolean} open
 *
 * @extends {BusComponent<Props, State>}
 */
class PanelSubtitleInfoButton extends BusComponent {
    constructor(props, context) {
        super(props, context);
        /** @type {State} */
        this.state = {
            open: false,
        };
    }

    onToggle = event => {
        event.stopPropagation();
        this.setState(prevState => ({ open: !prevState.open }));
    };

    handleDataBrowser = () => {
        const mapInstanceId = this.props.mapInstanceId;
        this.emit('EXIT_DATA_FILTER_MODE', {
            mapInstanceId,
            displayDataBrowser: true,
        });
    };

    render() {
        const { intl } = this.props;
        const { open } = this.state;

        const ariaLabel = open
            ? intl.formatMessage({ id: 'showHide.hide.filteringDataLimit' })
            : intl.formatMessage({ id: 'showHide.show.filteringDataLimit' });

        return (
            <div>
                <button
                    ref={e => {
                        this.trigger = e;
                    }}
                    className="btn-icon btn-icon--extra-small-green"
                    onClick={this.onToggle}
                    aria-label={ariaLabel}
                >
                    <i className="material-icons" aria-hidden>
                        info_outline
                    </i>
                </button>
                {this.trigger && (
                    <Popover
                        anchorEl={this.trigger}
                        className="filter-data-limit__popover"
                        open={open}
                        onCloseRequest={this.onToggle}
                    >
                        <div>
                            <p>
                                <FormattedMessage id="dataBrowser.limitedData.infoText" />
                            </p>
                            <ol>
                                <li>
                                    <FormattedMessage
                                        id="dataBrowser.limitedData.stepOne"
                                        values={{
                                            dataBrowserLink: (
                                                <button
                                                    onClick={() =>
                                                        this.handleDataBrowser()
                                                    }
                                                >
                                                    {intl.formatMessage({
                                                        id: 'dataBrowser.limitedData.dataBrowserName',
                                                    })}
                                                </button>
                                            ),
                                        }}
                                    />
                                </li>
                                <li>
                                    <FormattedMessage id="dataBrowser.limitedData.stepTwo" />
                                </li>
                                <li>
                                    <FormattedMessage id="dataBrowser.limitedData.stepThree" />
                                </li>
                            </ol>
                        </div>
                    </Popover>
                )}
            </div>
        );
    }
}

PanelSubtitleInfoButton.propTypes = {
    intl: PropTypes.object.isRequired,
    mapInstanceId: PropTypes.string.isRequired,
};

PanelSubtitleInfoButton.defaultProps = {};

export default injectIntl(PanelSubtitleInfoButton);
