/* eslint react/no-array-index-key: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import RampRow from './RampRow';
import ColorPicker from '../colorPickerCompact/ColorPicker';

class GradientMultiEditor extends React.Component {
    onFlipColors = index => {
        const colorPalette = this.props.colorPalette.clone();
        const temp = colorPalette.colorRamps[index].to;
        colorPalette.colorRamps[index].to = colorPalette.colorRamps[index].from;
        colorPalette.colorRamps[index].from = temp;
        this.props.applyColorPalette(colorPalette);
    }

    onApplyStartColor = (newColor, index) => {
        const colorPalette = this.props.colorPalette.clone();
        colorPalette.colorRamps[index].from = newColor;
        this.props.applyColorPalette(colorPalette);
    }

    onApplyEndColor = (newColor, index) => {
        const colorPalette = this.props.colorPalette.clone();
        colorPalette.colorRamps[index].to = newColor;
        this.props.applyColorPalette(colorPalette);
    }

    render() {
        const rampRows = this.props.colorPalette.colorRamps.map((ramp, index) => <RampRow
            index={index}
            ramp={ramp}
            key={index}
            onFlipColors={this.onFlipColors}
            onApplyStartColor={this.onApplyStartColor}
            onApplyEndColor={this.onApplyEndColor}
        />);
        return (
            <div className="gradient-multi-editor">
                <span className="color-palette-editor__text">Data Colors</span>
                <div className="main-controls">
                    {rampRows}
                </div>
                <div className="additional-controls flex-it space-between center">
                    <div className="flex-it center">
                        <h6 className="margin-right-10">No color</h6>
                        <ColorPicker
                            onChange={this.props.applyNoDataColor}
                            color={this.props.colorPalette.nullDataColor}
                        />
                    </div>
                    <div className="flex-it center">
                        <h6 className="margin-right-10">Insufficient color</h6>
                        <ColorPicker
                            onChange={this.props.applyInsufficientDataColor}
                            color={this.props.colorPalette.insufficientDataColor}
                        />
                    </div>
                </div>
            </div>);
    }
}

GradientMultiEditor.propTypes = {
    colorPalette: PropTypes.object.isRequired,
    applyColorPalette: PropTypes.func.isRequired,
    applyNoDataColor: PropTypes.func.isRequired,
    applyInsufficientDataColor: PropTypes.func.isRequired,
};

GradientMultiEditor.defaultProps = {
};

export default GradientMultiEditor;
