import React from 'react';
import PropTypes from 'prop-types';

import LayerLibraryGroupHeader from './LayerLibraryGroupHeader';
import LayerLibraryGroupInfo from './LayerLibraryGroupInfo';

class LayerLibraryGroup extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            expanded: false,
        };
    }

    onToggleSelected = active => {
        this.props.onToggleSelected(this.props.id, active);
    }

    onToggleExpanded = expanded => {
        this.setState({ expanded });
    }

    render() {
        const { expanded } = this.state;
        const { metadata, active } = this.props;
        const { title } = metadata;

        return (<div className="layer-library-group flex-it column grow">
            <LayerLibraryGroupHeader
                title={title}
                active={active}
                onToggleSelected={this.onToggleSelected}
                onToggleExpanded={this.onToggleExpanded}
            />
            { expanded && <LayerLibraryGroupInfo
                metadata={metadata}
            />}
        </div>);
    }
}

LayerLibraryGroup.propTypes = {
    id: PropTypes.string.isRequired,
    metadata: PropTypes.object.isRequired,
    active: PropTypes.bool.isRequired,
    onToggleSelected: PropTypes.func.isRequired,
};

export default LayerLibraryGroup;
