import React from 'react';

const ThreeDotsLoader = () => (
    <div className="three-dots-loader">
        <div className="bounce1" />
        <div className="bounce2" />
        <div className="bounce3" />
    </div>
);

export default ThreeDotsLoader;
