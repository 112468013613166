import { basePngSize } from '../enums/AnnotationsMarkers';
import MarkerSvgElements from './MarkerSvgElements';

import canvg from 'canvg-browser';

export default function convertSvgElementToPng(marker, options) {
    const { fillColor, strokeColor = '#FFFFFF', strokeWidth = 0 } = options;

    const div = document.createElement('div');
    div.innerHTML = MarkerSvgElements[marker];

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    let offsetX, offsetY, scaleWidth, scaleHeight;
    const outputWidth = options.outputWidth || basePngSize;
    const outputHeight = options.outputHeight || basePngSize;

    if (options.useBackground) {
        div.children[0].style.fill = '#FFFFFF';
        const graphicsSizeRatio = options.graphicsSizeRatio || 0.55;
        // draw circle background
        ctx.beginPath();
        ctx.fillStyle = fillColor;
        ctx.strokeStyle = strokeColor;
        ctx.lineWidth = strokeWidth;
        ctx.arc(outputHeight / 2, outputHeight / 2, (outputHeight / 2) - strokeWidth, 0, 2 * Math.PI);
        ctx.fill();
        ctx.stroke();

        const originalSvgSize = div.children[0].height.baseVal.value;
        const graphicsToIconRatio = graphicsSizeRatio * (outputHeight / originalSvgSize);
        offsetX = offsetY = ((outputHeight - (originalSvgSize * graphicsToIconRatio)) / (2 * graphicsToIconRatio));
        scaleWidth = scaleHeight = originalSvgSize * graphicsToIconRatio;
    } else {
        let childElement = div.children[0];
        if (options.focusElement) {
            childElement = div.querySelector(`#${options.focusElement}`);
        }
        childElement.style.fill = fillColor;
        offsetX = offsetY = 0;
        scaleWidth = outputWidth;
        scaleHeight = outputHeight;
    }

    const serializedString = new XMLSerializer().serializeToString(div.children[0]);
    const canvgOptions = {
        ignoreMouse: true,
        ignoreAnimation: true,
        ignoreDimensions: true,
        ignoreClear: true,
        offsetX,
        offsetY,
        scaleWidth,
        scaleHeight,
    };

    canvg(canvas, serializedString, canvgOptions);
    return (ctx.getImageData(0, 0, outputWidth, outputHeight));
}
