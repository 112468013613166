import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../Tooltip';
import classNames from 'classnames';
import withHighlight from './withHighlight';

const HorizontalShadedStop = props => {
    let ruleLabel;
    if ((props.showLabel || props.forceLabels) && !props.isInsufficientDataRule) {
        const ruleLabelClasses = classNames('flex-it center justify-center', 'shaded-color-palette-horizontal__label', {
            'shaded-color-palette-horizontal__label--force': props.forceLabels, 'shaded-color-palette-horizontal__label--highlighted': props.highlighted });
        ruleLabel = (<div
            className={ruleLabelClasses}
        >
            <div style={{ paddingRight: 5 }}>
                {props.forceLabels ? props.rule.title : props.label}
            </div>
        </div>);
    }

    const className = classNames('shaded-color-palette-horizontal__stop flex-it grow',
        {
            'shaded-color-palette-horizontal__stop--insufficient': props.isInsufficientDataRule,
            'shaded-color-palette-horizontal__stop--highlighted': props.highlighted,

        });

    let rectangleStyle;
    if (props.legendHighlighted && !props.highlighted) {
        rectangleStyle = {
            backgroundColor: 'rgb(224, 224, 224)',
        };
    } else {
        rectangleStyle = {
            backgroundColor: props.rule.symbols[0].brushes[0].fillColor,
        };
    }

    return (<div
        className={className}
    >
        <Tooltip
            mouseLeaveDelay={0}
            placement="bottom"
            trigger="hover"
            overlay={<span className="light-text">{props.rule.title}</span>}
        >
            <button
                className="shaded-color-palette-horizontal__color-rectangle flex-it grow"
                style={rectangleStyle}
                onClick={props.onClick}
                aria-label={props.rule.title}
            />
        </Tooltip>
        {ruleLabel}
    </div>);
};

HorizontalShadedStop.propTypes = {
    onClick: PropTypes.func,
    rule: PropTypes.object.isRequired,
    label: PropTypes.string,
    showLabel: PropTypes.bool,
    highlighted: PropTypes.bool,
    legendHighlighted: PropTypes.bool,
    forceLabels: PropTypes.bool.isRequired,
    isInsufficientDataRule: PropTypes.bool,
};

HorizontalShadedStop.defaultProps = {
    isInsufficientDataRule: false,
    showLabel: false,
    highlighted: false,
    legendHighlighted: false,
    label: '',
    onClick: undefined,
};

export default withHighlight(HorizontalShadedStop);
