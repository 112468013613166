module.exports={
  "closePanelHeader": "Clear drive time analysis",
  "closePanelQuestion": "Are you sure you want to clear the analysis?",
  "closeConfirmation": "Clear",
  "minimizePanel": "Minimize",
  "singleRing": "Single ring",
  "multiRing": "Multi ring",
  "selectCorrespondingGeographies": "Select corresponding (upward) geographies",
  "optional": "optional",
  "notAvailableForSelectedSurvey": "Not available for selected survey/report",
  "somethingWentWrong": "Oops. Something went wrong",
  "closeErrorMessagePanel": "Close error message panel",
  "pleaseChangeTheParameters": "Please change the parameters and try again."
}
