import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { hasParentNode, addEvent, removeEvent } from '../../helpers/Util';

import NestedSummaryLevel from './NestedSummaryLevel';
import Checkbox from '../form/Checkbox';

class NestedSummaryLevelSelector extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            isOpen: false,
        };
    }

    componentWillUnmount() {
        removeEvent(document, 'click', this.onClick);
    }

    onClick = e => {
        if (!hasParentNode(e.target, this.root)) {
            this.onToggle();
        }
    }

    onToggle = () => {
        const { isOpen } = this.state;
        const willOpen = !isOpen;
        // add click event in order to automatically close dropdown
        // on click away
        if (willOpen) {
            addEvent(document, 'click', this.onClick);
        } else {
            removeEvent(document, 'click', this.onClick);
        }
        this.setState({ isOpen: willOpen });
    }

    render() {
        const nestedSummaryLevels = this.props.availableNestedSummaryLevels.map(summaryLevel => <NestedSummaryLevel
            onClick={this.props.onChangeSummaryLevel}
            key={summaryLevel.id}
            mapInstanceId={this.props.mapInstanceId}
            selected={this.props.selectedNestedSummaryLevels.some(sl => sl.id === summaryLevel.id)}
            summaryLevel={summaryLevel}
        />);

        let checked;
        if (this.props.selectedNestedSummaryLevels.length === this.props.availableNestedSummaryLevels.length) {
            checked = true;
        } else if (this.props.selectedNestedSummaryLevels.length === 0) {
            checked = false;
        }
        return (
            <div
                ref={c => { this.root = c; }}
                className="mask-summary-selector flex-it column"
            >
                <div className="flex-it center space-between">
                    <Checkbox
                        onCheck={this.props.changeAllSummaryLevels}
                        checked={checked}
                    />
                    <button className="flex-it grow center space-between clickable" onClick={this.onToggle}>
                        <span className="body-1">{this.props.intl.formatMessage({ id: 'dataBrowser.showSubGeographies' })}</span>
                        <div className="mask-summary-selector__anchor_icon">
                            <i className="material-icons" aria-label={this.state.isOpen ? 'Up arrow icon' : 'Down arrow icon'}>
                                {this.state.isOpen ? 'arrow_drop_up' : 'arrow_drop_down'}
                            </i>
                        </div>
                    </button>
                </div>
                <div className={classNames('mask-summary-selector__list', { 'mask-summary-selector__list--visible': this.state.isOpen })}>
                    {nestedSummaryLevels}
                </div>
            </div>
        );
    }
}

NestedSummaryLevelSelector.propTypes = {
    allSummaryLevelsChecked: PropTypes.bool,
    mapInstanceId: PropTypes.string.isRequired,
    availableNestedSummaryLevels: PropTypes.array,
    changeAllSummaryLevels: PropTypes.func.isRequired,
    onChangeSummaryLevel: PropTypes.func.isRequired,
    selectedNestedSummaryLevels: PropTypes.array.isRequired,
    intl: PropTypes.object.isRequired,
};

NestedSummaryLevelSelector.defaultProps = {
    allSummaryLevelsChecked: false,
    availableNestedSummaryLevels: [],
};

export default injectIntl(NestedSummaryLevelSelector);
