import BaseHandler from './BaseHandler';

class SearchHandler extends BaseHandler {
    constructor(mapViewer) {
        super(mapViewer);
        this._data = mapViewer.dragonflyMapData.searchData;

        this.setGluBusEvents({
            MAP_DISPLAY_SEARCH_RESULTS_MARKERS_REQUEST:
                this.displaySearchResults,
            MAP_CLEAR_SEARCH_RESULTS_MARKERS_REQUEST: this.clearSearchResults,
            MAP_ADD_SEARCH_RESULTS_AS_ANNOTATION:
                this.addSearchResultsAsAnnotation,
            // Clear search when user exited the location analysis
            LOCATION_ANALYSIS_MODE_EXITED: this.clearSearchResults,
        });
    }

    addSearchResultsAsAnnotation({ mapInstanceId }) {
        if (mapInstanceId !== this.mapInstanceId) return;

        if (
            this._data._geoJSONGeometriesData &&
            this._data._geoJSONGeometriesData.features.length > 0
        ) {
            let annotations = [];
            this._data._geoJSONGeometriesData.features.forEach(feature => {
                switch (feature.geometry.type) {
                case 'Polygon':
                case 'MultiLineString':
                    annotations = [...this.handlePolygon(feature)];
                    break;
                case 'MultiPolygon':
                    annotations = [...this.handleMultiPolygon(feature)];
                    break;
                }
            });
            this.emit('ADD_MULTIPLE_SEARCH_ANNOTATIONS_REQUEST', {
                mapInstanceId,
                annotations,
                displayInLegend: true,
            });
        }

        if (
            this._data._geoJSONMarkersData &&
            this._data._geoJSONMarkersData.features.length > 0
        ) {
            const coordinates = [
                this._data._geoJSONMarkersData.features[0].geometry.coordinates,
            ];
            const annotation = this._data._buildMarkerAnnotation({
                zoom: Math.floor(this.map.getZoom()),
                coordinates,
                title: this._data._geoJSONMarkersData.features[0].properties
                    .value,
                searchId:
                    this._data._geoJSONMarkersData.features[0].properties.id,
            });
            this.emit('ADD_SINGLE_SEARCH_ANNOTATION_REQUEST', {
                mapInstanceId,
                annotation,
                displayInLegend: true,
            });
        }
    }

    handlePolygon(polygon) {
        const annotations = [];
        polygon.geometry.coordinates.forEach(ring => {
            annotations.push(
                this._data._buildLineAnnotation({
                    zoom: Math.floor(this.map.getZoom()),
                    coordinates: ring,
                    title: polygon.properties.value,
                    searchId: polygon.properties.id,
                }),
            );
        });
        return annotations;
    }

    handleMultiPolygon(multiPolygon) {
        const annotations = [];
        multiPolygon.geometry.coordinates.forEach(polygon => {
            polygon.forEach(ring => {
                annotations.push(
                    this._data._buildLineAnnotation({
                        zoom: Math.floor(this.map.getZoom()),
                        coordinates: ring,
                        title: multiPolygon.properties.value,
                        searchId: multiPolygon.properties.id,
                    }),
                );
            });
        });
        return annotations;
    }

    displaySearchResults(e) {
        if (e.mapInstanceId !== this.mapInstanceId) return;

        this._data.build(e.results);

        const mapSearchGeometriesSource = this.map.getSource(
            this._data.geometriesSourceId,
        );
        if (mapSearchGeometriesSource) {
            mapSearchGeometriesSource.setData(this._data.geometriesSource.data);
        } else {
            this.map.addSource(
                this._data.geometriesSourceId,
                this._data.geometriesSource,
            );
            this.map.addLayer(this._data.geometriesLayer);
        }
    }

    /**
     * @param {object} param0
     * @param {string} param0.mapInstanceId
     */
    clearSearchResults({ mapInstanceId }) {
        if (mapInstanceId !== this.mapInstanceId) return;

        if (
            this._data.geometriesLayer &&
            this.map.getLayer(this._data.geometriesLayer.id)
        ) {
            this.map.removeLayer(this._data.geometriesLayer.id);
        }
        if (this.map.getSource(this._data.geometriesSourceId)) {
            this.map.removeSource(this._data.geometriesSourceId);
        }

        this._data.remove();
    }
}
export default SearchHandler;
