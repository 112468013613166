// @ts-check
import React from 'react';
import classnames from 'classnames';
import { injectIntl } from 'react-intl';

import BusComponent from '../BusComponent';
import ReportType from '../../enums/ReportType';
import { preparePremadeReportsAndTemplatesWithDividers } from '../../helpers/ReportTableTemplate';

import SimpleDropdown from '../dropdown/SimpleDropdown';
import Loader from '../Loader';
import Panel from '../panel/Panel';
import ReportInfoOverlay from './ReportInfoOverlay';
import SummaryLevelSelection from './SummaryLevelSelection';
import ClearGeoSelection from './ClearGeoSelection';
import CurrentVariableSelection from './CurrentVariableSelection';
import CreateReportButton from './CreateReportButton';
import ReportGeoSelection from './ReportGeoSelection';

const overlayCallbackTime = 5000; // 5 second

/**
 * @typedef Props
 * @property {import('../../objects/MapInstance').default} mapInstance
 * @property {import('../../objects/MapInstance').default} parentMapInstance
 * @property {import('react-intl').intlShape} intl
 *
 * @typedef DropdownItem
 * @property {string | number} id
 * @property {string} text
 *
 * @typedef State
 * @property {string[]} selectedGeographies
 * @property {DropdownItem[]} surveysListItems
 * @property {DropdownItem} selectedSurveyItem
 * @property {DropdownItem[]} reportsListItems
 * @property {DropdownItem} selectedReportItem
 * @property {boolean} areTemplatesLoaded
 * @property {boolean} hasData
 * @property {boolean} showInfoOverlay
 * @property {import('../../types').ReportTemplateTable[]} compatibleTableTemplates
 * @property {DropdownItem} reportType
 * @property {string[]} selectedTableGuids
 * @property {*} currentVariableMetadataSelection // TODO: Document metadata variable selection when you find time :) whoever finds the time should do this :)
 *
 * @extends {BusComponent<Props, State>}
 */
class MapReportEditor extends BusComponent {
    constructor(props, context) {
        super(props, context);

        /** @type State */
        this.state = {
            // main variables needed for report creation
            selectedGeographies: [],
            selectedTableGuids: [],
            selectedSurveyItem: { id: '', text: '' },

            surveysListItems: [],
            reportsListItems: [],
            areTemplatesLoaded: false,
            compatibleTableTemplates: [],
            selectedReportItem: { id: '', text: '' },
            hasData: false,
            showInfoOverlay: false,
            reportType: ReportType.TOPIC,
            currentVariableMetadataSelection: undefined,
        };
    }

    componentDidMount() {
        this.bindGluBusEvents({
            CURRENT_REPORT_INFO: this.onCurrentReportInfo,
            COMPATIBLE_TABLE_TEMPLATES_LOADED: this.onTemplatesLoaded,
            DRAGONFLY_MAP_CREATED: this.onDragonflyMapCreated,
            MAP_REPORT_SELECTED_FEATURES_CHANGED: this
                .onMapReportSelectedFeaturesChanged,
            VARIABLE_SELECTION_METADATA_INFO: this
                .onVariableSelectionMetadataInfoRetrieved,
            SYSTEM_REPORT_RESPONSE: this.onSystemReportRetrieved,
        });

        this.emit('RETRIEVE_CURRENT_REPORT_INFO_REQUEST');
        this.emit('VARIABLE_SELECTION_METADATA_INFO_REQUEST', {
            source: this,
            mapInstanceId: this.props.parentMapInstance.id,
            variableSelectionItems: this.props.parentMapInstance.dataTheme
                .variableSelection.items,
        });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    componentDidUpdate() {
        if (!this.state.selectedReportItem) {
            return;
        }
        // TODO: Figure out a better way to do this... One day :)
        const { table, survey } = this.state.currentVariableMetadataSelection;
        this.emit('UPDATE_GTM_CREATE_EVENT', {
            label:
                this.state.reportType.id ===
                ReportType.CURRENT_VARIABLE_TABLE.id
                    ? `${survey.name}-${table.name}`
                    : `${this.state.selectedSurveyItem.text}-${this.state.selectedReportItem.text}`,
        });
    }

    /**
     * @param {object} param0
     * @param {import('../../types').ReportTemplateTable[]} param0.reportTableTemplates
     */
    onTemplatesLoaded({ reportTableTemplates }) {
        this.setState({
            compatibleTableTemplates: reportTableTemplates,
            areTemplatesLoaded: true,
        });
    }

    onVariableSelectionMetadataInfoRetrieved = ({
        source,
        metadataSelection,
    }) => {
        if (source === this) {
            this.setState({
                currentVariableMetadataSelection: metadataSelection,
            });
        }
    };

    /**
     * @param {object} eventMap
     * @param {import ('../../objects/MetaSurvey').default[]} eventMap.surveys
     * @param {import ('../../objects/MetaSurvey').default} eventMap.selectedSurvey
     * @param {import ('../../objects/MetaReport').default[]} eventMap.reports
     * @param {import ('../../objects/MetaReport').default} eventMap.selectedReport
     * @param {import ('../../../..').SummaryLevel[]} eventMap.summaryLevels
     */
    onCurrentReportInfo(eventMap) {
        // filter our LEIP surveys since they cannot be used for reporting
        // the same condition is used as for check on the Create Report AdditionalSettingsItem
        const filteredSurveys = eventMap.surveys.filter(
            survey => survey.reportsAsArray && survey.reportsAsArray.length,
        );
        this.setState({
            surveysListItems: filteredSurveys.map(survey => ({
                id: survey.name,
                text: survey.displayName,
            })),
            selectedSurveyItem: {
                id: eventMap.selectedSurvey.name,
                text: eventMap.selectedSurvey.displayName,
            },
            reportsListItems: eventMap.reports.map(report => ({
                id: report.id,
                text: report.name,
            })),
            selectedReportItem: eventMap.selectedReport ? {
                id: eventMap.selectedReport.id,
                text: eventMap.selectedReport.name,
            } : undefined,
            hasData: true,
            selectedTableGuids: [],
        });
        if (eventMap.selectedReport) {
            // Fetch initial report topic tables
            this.emit('SYSTEM_REPORT_REQUEST', {
                systemReportId: eventMap.selectedReport.id,
                surveyName: eventMap.selectedSurvey.name,
            });
        }
        // Fetch initial survey table templates
        this.emit('LOAD_COMPATIBLE_TABLE_TEMPLATES_REQUEST', {
            surveyName: eventMap.selectedSurvey.name,
        });
    }

    onDragonflyMapCreated() {
        this.emit('RETRIEVE_CURRENT_REPORT_INFO_REQUEST');
        this.setState({
            hasData: false,
        });
    }

    /**
     * @param {object} param0
     * @param {string} param0.mapInstanceId
     * @param {Object.<string, {geoFips: string, geoQName: string}[]> } param0.features
     * @param {{geoFips: string, geoQName: string}[]} param0.limitationIgnoredFeatures
     */
    onMapReportSelectedFeaturesChanged({
        mapInstanceId,
        features,
        limitationIgnoredFeatures,
    }) {
        if (mapInstanceId !== this.props.mapInstance.id) return;

        const summaryLevelsSelectedFeatures = features;
        let selectedGeographies = [];
        Object.keys(summaryLevelsSelectedFeatures).forEach(summaryLevelId => {
            const selectedFeatures =
                summaryLevelsSelectedFeatures[summaryLevelId];
            const summaryLevelSplitted = summaryLevelId.replace('SL', '');
            const summaryLevelGeographies = selectedFeatures.map(
                f => `${f.geoFips};${summaryLevelSplitted};${f.geoQName}`,
            );
            selectedGeographies = selectedGeographies.concat(
                summaryLevelGeographies,
            );
        });
        if (limitationIgnoredFeatures.length > 0) {
            this.emit('REPORT_GEOS_LIMIT_POPUP_REQUEST', {
                maxLimit: this.props.mapInstance.dataTheme.rendering[0]
                    .reportLimit,
                ignoredGeos: limitationIgnoredFeatures.map(f => ({
                    id: f.geoFips,
                    title: f.geoQName,
                })),
                mapInstanceId: this.props.mapInstance.id,
            });
        }
        this.setState({
            selectedGeographies,
        });
    }

    onRemoveGeo = (geo, summaryLevel) => {
        this.emit('MAP_DESELECT_REPORT_FEATURE', {
            mapInstanceId: this.props.mapInstance.id,
            geoFips: geo.id,
            summaryLevel,
        });
    };

    onExitMapReportEditor = () => {
        this.emit('EXIT_REPORT_MODE', { source: this });
    };

    onChangeReportSurvey = surveyName => {
        this.emit('CHANGE_REPORT_SURVEY', {
            surveyName,
            mapInstanceId: this.props.mapInstance.id,
        });
        // On each survey change we need to check which templates are compatible
        // with selected survey
        this.setState({
            areTemplatesLoaded: false,
        });
    };

    onSystemReportRetrieved = ({ surveyName, systemReport }) => {
        if (this.state.selectedSurveyItem.id !== surveyName) return;
        this.setState({ selectedTableGuids: systemReport.tableGuids });
    };

    onChangeReportTopic = reportId => {
        const selectedReportItem = preparePremadeReportsAndTemplatesWithDividers(
            this.state.compatibleTableTemplates,
            this.state.reportsListItems,
        ).find(r => r.id === reportId);
        this.setState({
            selectedReportItem,
        });

        const selectedTemplate = this.state.compatibleTableTemplates.find(
            template => template.id === selectedReportItem.id,
        );
        let selectedTableGuids;
        if (selectedTemplate) {
            selectedTableGuids = selectedTemplate.tables.map(
                table =>
                    table.guidsBySurveyName[this.state.selectedSurveyItem.id],
            );
            this.setState({ selectedTableGuids });
            return;
        }

        // Fetch report topic tables
        this.emit('SYSTEM_REPORT_REQUEST', {
            systemReportId: reportId,
            surveyName: this.state.selectedSurveyItem.id,
        });
    };

    onCreateReport = () => {
        this.setState({ showInfoOverlay: true });
        // register timeout to remove callback
        setTimeout(() => {
            this.setState({ showInfoOverlay: false });
        }, overlayCallbackTime);
    };

    render() {
        const content = [];
        let reportPanelActions;

        if (!this.state.hasData || !this.state.areTemplatesLoaded) {
            content.push(
                <div
                    className="grow flex-it center justify-center"
                    key="loader"
                >
                    <Loader
                        text={this.props.intl.formatMessage({
                            id: 'dataBrowser.loadingReportInformation',
                        })}
                    />
                </div>,
            );
            reportPanelActions = [];
        } else {
            const {
                table,
                survey,
            } = this.state.currentVariableMetadataSelection;
            const sourceDisabled =
                !!this.state.selectedGeographies.length ||
                this.props.mapInstance.hasDataFilter;
            content.push(
                <div
                    className="report-options__section flex-column"
                    key="selection"
                >
                    <CurrentVariableSelection
                        table={table}
                        survey={survey}
                        reportTypeId={this.state.reportType.id}
                    />
                    {this.state.reportType.id !==
                        ReportType.CURRENT_VARIABLE_TABLE.id && (
                        <div className="flex-it column">
                            <SimpleDropdown
                                title={this.props.intl.formatMessage({
                                    id: 'source',
                                })}
                                className={classnames(
                                    'simple-dropdown__report-option',
                                    {
                                        disabled: sourceDisabled,
                                    },
                                )}
                                onItemClick={this.onChangeReportSurvey}
                                mapInstanceId={this.props.mapInstance.id}
                                items={this.state.surveysListItems}
                                selectedItem={this.state.selectedSurveyItem}
                                showFilter
                                filterPlaceholder={this.props.intl.formatMessage(
                                    {
                                        id: 'dataBrowser.searchSource',
                                    },
                                )}
                                disabled={sourceDisabled}
                            />
                            <SimpleDropdown
                                title={this.props.intl.formatMessage({
                                    id: 'topic',
                                })}
                                className="simple-dropdown__report-option"
                                onItemClick={this.onChangeReportTopic}
                                mapInstanceId={this.props.mapInstance.id}
                                items={preparePremadeReportsAndTemplatesWithDividers(
                                    this.state.compatibleTableTemplates,
                                    this.state.reportsListItems,
                                )}
                                selectedItem={this.state.selectedReportItem}
                            />
                        </div>
                    )}
                </div>,
                <ReportGeoSelection
                    key="geoSelection"
                    mapInstance={this.props.mapInstance}
                />,
            );

            reportPanelActions = [
                <CreateReportButton
                    disabled={!this.state.selectedGeographies.length}
                    onCreateReport={this.onCreateReport}
                    selectedReportName={this.state.selectedReportItem ? this.state.selectedReportItem.text : undefined}
                    selectedSurveyItem={this.state.selectedSurveyItem}
                    selectedTableGuids={this.state.selectedTableGuids}
                    selectedGeographies={this.state.selectedGeographies}
                    key="create-btn"
                />,
                <div
                    key="divider"
                    className="divider divider--vertical divider--btn-separator"
                />,
            ];

            if (this.state.selectedGeographies.length) {
                reportPanelActions.unshift(
                    <ClearGeoSelection
                        key="clear"
                        mapInstanceId={this.props.mapInstance.id}
                    />,
                );
            }
        }

        return (
            <div className="report-options flex-column flex-stretch">
                {this.state.showInfoOverlay && <ReportInfoOverlay />}
                <Panel
                    className="panel--report-options"
                    title={this.props.intl.formatMessage({
                        id: 'dataBrowser.createaReport',
                    })}
                    headerStyle="white"
                    headerActions={reportPanelActions}
                    onCloseClick={this.onExitMapReportEditor}
                >
                    <SummaryLevelSelection
                        mapInstanceId={this.props.mapInstance.id}
                    />
                    {content}
                </Panel>
            </div>
        );
    }
}

export default injectIntl(MapReportEditor);
