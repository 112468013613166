import React from 'react';
import classNames from 'classnames';
import AppConfig from '../../appConfig';

import BusComponent from '../BusComponent';
import VisualizationType from '../../enums/VisualizationType';
import LegendLayout from '../../enums/LegendLayout';
import Orientation from '../../enums/Orientation';
import { isStorageAvailable } from '../../helpers/Util';

import BubbleLegend from './bubbleLegend/BubbleLegend';
import ShadedAreaLegend from './shadedAreaLegend/ShadedAreaLegend';
import DotDensityLegend from './dotDensityLegend/DotDensityLegend';
import LegendStyleOptions from './LegendStyleOptions';
import AnnotationLegend from '../mapAnnotator/legend/AnnotationLegend';
import LayerLibraryLegend from '../layerLibrary/legend/LayerLibraryLegend';
import DataFilterLegend from '../dataFilter/DataFilterLegend';
import DataFilterLegendInfo from '../dataFilter/DataFilterLegendInfo';
import SatelliteLegend from './layersLegend/SatelliteLegend';
import SatelliteLegendDetails from './layersLegend/SatelliteLegendDetails';
import OpportunityZonesLegend from './layersLegend/OpportunityZonesLegend';
import SchoolRankLegend from './layersLegend/SchoolRankLegend';
import UserUploadDataLegend from '../userDataUploadEditor/legend/UserUploadDataLegend';
import GeoJsonLibraryLegend from '../geoJsonLibrary/legend/GeoJsonLibraryLegend';

const STORAGE = window.localStorage;
const LEGEND_MINIMIZED_STATE_STORAGE_KEY = `${AppConfig.constants.environment}-LegendMinimizedState`;
const IS_STORAGE_AVAILABLE = isStorageAvailable(STORAGE);

/**
 * @typedef Props
 * @property {import('../../objects/MapInstance').default} mapInstance
 *
 * @typedef State
 * @property {import('../../').LayerDataGroup[]} layerLibraryMetadata
 *
 * @extends BusComponent<Props, State>
 */
class VisualizationLegend extends BusComponent {
    constructor(props, context) {
        super(props, context);

        // Keep the legend minimized user choice in local storage and set the
        // last user choice as default state
        let legendMinimized = this.context.isMobileDevice;
        if (IS_STORAGE_AVAILABLE) {
            const minimized = localStorage.getItem(
                LEGEND_MINIMIZED_STATE_STORAGE_KEY,
            );
            if (minimized !== null) {
                legendMinimized = minimized === 'true';
            }
        }

        /** @type {State} */
        this.state = {
            layout: LegendLayout.SIMPLIFIED,
            legendMinimized,
            filterInfoOpen: false,
            satelliteDetailsOpen: false,
            schoolLegendVisible: false,
        };
    }

    componentDidMount() {
        this.bindGluBusEvents({
            MAP_NEW_DATA_THEME_APPLIED: this.updateLegend,
            MAP_COLOR_PALETTES_CHANGED: this.updateLegend,
            MAP_NEW_COLOR_PALETTE_APPLIED: this.updateLegend,
            MAP_NEW_CUTPOINTS_APPLIED: this.updateLegend,
            VARIABLE_SELECTION_METADATA_INFO: this.onVariableSelectionMetadataInfo,
            ANNOTATION_CREATED: this.updateLegend,
            ANNOTATIONS_CREATED: this.updateLegend,
            ANNOTATIONS_DELETE_REQUEST_SUCCESS: this.updateLegend,
            MAP_LEGEND_RULE_HIGHLIGHTED: this.updateLegend,
            MAP_DATA_FILTER_REMOVED: this.onFilterRemoved,
            MAP_SCHOOL_LEGEND_VISIBILITY_CHANGED: this.onSchoolLegendAvailabilityChanged,
            MINIMIZE_LEGEND: this.onMinimizeLegend,
            ADD_LAYER_LIBRARY_GROUP: this.getLayerLibraryMetadata,
            TOGGLE_USER_DATA_UPLOAD_LEGEND_VISIBILITY: this.getLayerLibraryMetadata,
            UPDATE_USER_LAYER_VISIBILITY: this.getLayerLibraryMetadata,
            MAP_LIBRARY_LAYERS_UPDATED: this.onLibraryLayersUpdated,
            TOGGLE_CLUSTERING: this.onToggleClustering,
        });

        this.getVariableMetadata();
        this.getLayerLibraryMetadata();
        this.getGeoJsonMetadata();
        this.emit('MAP_CLEAR_ALL_HIGHLIGHT_RULES');
    }

    componentDidUpdate(prevProps) {
        if (prevProps.mapInstance.id !== this.props.mapInstance.id) {
            this.getVariableMetadata();
        }
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    /**
     * @param {import('../../objects/LibraryData').default} layerLibraryData
     * @param {BusComponent} target
     */
    onLayerLibraryDataRetrieved = (layerLibraryData, target) => {
        if (target === this) {
            /**
             * @type {import('../../objects/LibraryData').default}
             * This is not instance of the LibraryData, but deep copy that has
             * same properties. You can't call methods on this object
             */
            const layerLibraryDataCopy = JSON.parse(JSON.stringify(layerLibraryData));
            this.setState({
                layerLibraryMetadata: layerLibraryDataCopy.groups,
            });
        }
    }

    onGeoJsonMetadataRetrieved = (geoJsonMetadata, target) => {
        if (target === this && geoJsonMetadata) {
            const { mapProperties } = geoJsonMetadata;
            this.setState({
                geoJsonLegendMetadata: mapProperties.legend,
            });
        }
    }

    onToggleClustering = () => {
        if (AppConfig.constants.openLegendOnGeoJsonFilter) {
            this.setState({ legendMinimized: false });
        }
    }

    onLibraryLayersUpdated = () => {
        // Toggle on visibility of the layer doesn't update DOM if you remove
        // this line.
        this.forceUpdate();
    }

    onVariableSelectionMetadataInfo(eventMap) {
        const { mapInstance } = this.props;
        if (eventMap.source === this && eventMap.mapInstanceId === mapInstance.id) {
            // Sort datadictionary variables by variable selection
            // since metadata for variables is not sorted as the variable selection is.
            // We need the right order so we can create legend pages
            const variables = mapInstance.dataTheme.variableSelection.items.map(vsi => eventMap.dataDictionaryInfo.variables.find(v => v.guid === vsi.variableGuid));
            const isLayoutDotDensity = mapInstance.dataTheme.visualizationType === VisualizationType.DOT_DENSITY;
            const dataDictionaryInfo = {
                ...eventMap.dataDictionaryInfo,
                variables,
            };
            this.setState(prevState => ({
                dataDictionaryInfo,
                layout: isLayoutDotDensity ? LegendLayout.SIMPLIFIED : prevState.layout,
            }));
        }
    }

    updateLegend(eventMap) {
        if (eventMap.source.id === this.props.mapInstance.id) {
            this.getVariableMetadata();
        }
    }

    onFilterRemoved = () => {
        this.setState({ filterInfoOpen: false });
    }

    switchToSimplified = () => {
        this.setState({ layout: LegendLayout.SIMPLIFIED });
    }

    switchToDetailed = () => {
        this.setState({
            layout: LegendLayout.DETAILED,
            filterInfoOpen: false,
            satelliteDetailsOpen: false,
        });
    }

    onSchoolLegendAvailabilityChanged = payload => {
        this.setState({ schoolLegendVisible: payload.visible });
    }

    toggleLegend = () => {
        this.setState(
            prevState => ({
                legendMinimized: !prevState.legendMinimized,
                filterInfoOpen: false,
                satelliteDetailsOpen: false,
            }),
            () => {
                if (IS_STORAGE_AVAILABLE) {
                    localStorage.setItem(
                        LEGEND_MINIMIZED_STATE_STORAGE_KEY,
                        this.state.legendMinimized,
                    );
                }
            },
        );
    }

    onMinimizeLegend = () => {
        if (!this.state.legendMinimized) {
            this.setState({
                legendMinimized: true,
                filterInfoOpen: false,
                satelliteDetailsOpen: false,
            });
        }
    }

    onDataFilterInfoClick = () => {
        this.setState(prevState => ({
            filterInfoOpen: !prevState.filterInfoOpen,
            satelliteDetailsOpen: false,
            legendMinimized: this.props.isCondensedLayout,
        }));
    }

    onEditSatelliteDetailsClick = () => {
        this.setState(prevState => ({
            satelliteDetailsOpen: !prevState.satelliteDetailsOpen,
            filterInfoOpen: false,
            legendMinimized: this.props.isCondensedLayout,
        }));
    }

    onCloseSatelliteDetails = () => {
        this.setState({ satelliteDetailsOpen: false, legendMinimized: false });
    }

    onCloseDataFilterInfo = () => {
        this.setState({ filterInfoOpen: false, legendMinimized: false });
    }

    onScroll = () => {
        if (this.legend.scrollTop > 10) {
            this.setState({ scrolling: true });
        } else {
            this.setState({ scrolling: false });
        }
    }

    render() {
        if (!this.state.dataDictionaryInfo) {
            return (<div className="data-visualization-legend" />);
        }

        const { mapInstance, className, tourId, orientation, isCondensedLayout } = this.props;
        const { layout, dataDictionaryInfo, legendMinimized, layerLibraryMetadata, geoJsonLegendMetadata, filterInfoOpen, satelliteDetailsOpen, scrolling } = this.state;
        const { annotationLegend, dataTheme, libraryDataLayers, userDataLayers, hasDataFilter, dataFilter } = mapInstance;

        /** @type {import('../../../..').LayerDataGroupSHOULD_BE_REFACTORED[]} */
        const libraryGroupsInfo = libraryDataLayers
            // .filter(layersGroup => layersGroup.layers.some(layer => layer.visible))
            // We are removing this filter in order to show data layers (opportunity zones and school digger even if both layer group and all group's are invisible
            .map(layersGroup => {
                const groupInfo = layerLibraryMetadata.find(groupMetadata => groupMetadata.id === layersGroup.id);
                groupInfo.layers = layersGroup.layers;
                return groupInfo;
            });

        // detailed legend type
        let visualizationLegend;
        let visualizationLegendClass = 'visualization-legend';
        switch (dataTheme.visualizationType) {
        case VisualizationType.SHADED_AREA:
            visualizationLegendClass += '--shaded';
            visualizationLegend = (
                <ShadedAreaLegend
                    mapInstance={mapInstance}
                    orientation={orientation}
                    layout={layout}
                    dataDictionaryInfo={dataDictionaryInfo}
                    libraryLegendInfo={libraryGroupsInfo}
                    annotationLegend={annotationLegend}
                    onDataFilterInfoClick={this.onDataFilterInfoClick}
                    onEditSatelliteDetailsClick={this.onEditSatelliteDetailsClick}
                    onCloseSatelliteDetails={this.onCloseSatelliteDetails}
                    filterInfoOpen={filterInfoOpen}
                />);
            break;
        case VisualizationType.BUBBLES:
            visualizationLegendClass += '--bubbles';
            visualizationLegend = (
                <BubbleLegend
                    mapInstance={mapInstance}
                    orientation={orientation}
                    layout={layout}
                    libraryLegendInfo={libraryGroupsInfo}
                    annotationLegend={annotationLegend}
                    onDataFilterInfoClick={this.onDataFilterInfoClick}
                    onEditSatelliteDetailsClick={this.onEditSatelliteDetailsClick}
                    onCloseSatelliteDetails={this.onCloseSatelliteDetails}
                    filterInfoOpen={filterInfoOpen}
                />);
            break;
        case VisualizationType.DOT_DENSITY:
            visualizationLegendClass += '--dot-density';
            visualizationLegend = (
                <DotDensityLegend
                    mapInstance={mapInstance}
                    orientation={orientation}
                    layout={layout}
                />);
            break;
        }

        const additionalLegendElementsVisible = !legendMinimized && (layout === LegendLayout.SIMPLIFIED || dataTheme.visualizationType === VisualizationType.DOT_DENSITY);

        const hasDataFilterLegend = hasDataFilter && additionalLegendElementsVisible;

        const hasAnnotationLegend = annotationLegend !== undefined &&
            annotationLegend.legendItems.length > 0 &&
            annotationLegend.visible &&
            (layout === LegendLayout.SIMPLIFIED ||
            dataTheme.visualizationType === VisualizationType.DOT_DENSITY);

        const visualizationLegendClassNames = classNames('visualization-legend',
            visualizationLegendClass, {
                'visualization-legend--minimized': legendMinimized,
                'visualization-legend--simple': layout === LegendLayout.SIMPLIFIED,
                'visualization-legend--detailed': layout === LegendLayout.DETAILED && !legendMinimized,
            }, className);

        const layoutSupportsLegend = layout === LegendLayout.SIMPLIFIED ||
                                     dataTheme.visualizationType === VisualizationType.DOT_DENSITY;

        const hasLayerLibraryLegend = libraryGroupsInfo.length > 0 && layoutSupportsLegend;

        const hasUserUploadDataLegend = userDataLayers.length > 0 && layoutSupportsLegend;

        // The orientation prop is used to determine where to show components (ie. is the legend in the left or right corner of the map)
        const containerClassName = classNames(
            'visualization-legend-container',
            { 'visualization-legend-container--right': orientation === Orientation.RIGHT }
        );

        return (
            <div className={containerClassName}>
                <div className={visualizationLegendClassNames} data-tourId={tourId}>
                    <LegendStyleOptions
                        legendMinimized={legendMinimized}
                        toggleLegend={this.toggleLegend}
                        currentLayout={layout}
                        onSwitchToSimplified={this.switchToSimplified}
                        onSwitchToDetailed={this.switchToDetailed}
                        hasLayouts={dataTheme.visualizationType !== VisualizationType.DOT_DENSITY}
                        scrolling={scrolling}
                    />
                    <div className="visualization-legend__main vertical-scrollbar" ref={ref => { this.legend = ref; }} onScroll={this.onScroll}>
                        {!legendMinimized && <div className="visualization-legend__content">{visualizationLegend}</div>}
                        {additionalLegendElementsVisible &&
                            <SatelliteLegend
                                className="satellite-layer-legend--simplified"
                                mapInstance={mapInstance}
                                onEdit={this.onEditSatelliteDetailsClick}
                                onClose={this.onCloseSatelliteDetails}
                            />
                        }
                        {additionalLegendElementsVisible &&
                            <SchoolRankLegend
                                className="school-ranking-legend--simplified"
                                mapInstance={mapInstance}
                            />
                        }
                        {additionalLegendElementsVisible &&
                            <OpportunityZonesLegend
                                className="opportunity-zones-legend--simplified"
                                mapInstance={mapInstance}
                            />
                        }
                        {(!legendMinimized && hasUserUploadDataLegend) &&
                            <UserUploadDataLegend className="layer-library-legend--simplified" userUploadDataGroups={userDataLayers} mapInstanceId={mapInstance.id} />
                        }
                        {(!legendMinimized && hasAnnotationLegend) &&
                            <AnnotationLegend className="annotations-legend--simplified" annotationLegend={annotationLegend} />
                        }
                        {!legendMinimized && geoJsonLegendMetadata &&
                            <GeoJsonLibraryLegend
                                geoJsonLegendMetadata={geoJsonLegendMetadata}
                                mapInstanceId={mapInstance.id}
                            />
                        }
                        {(!legendMinimized && hasLayerLibraryLegend) &&
                        <LayerLibraryLegend className="layer-library-legend--simplified" libraryGroups={libraryGroupsInfo} mapInstanceId={mapInstance.id} />
                        }
                        {hasDataFilterLegend &&
                            <DataFilterLegend
                                className="data-filter-legend--simplified"
                                onDataFilterInfoClick={this.onDataFilterInfoClick}
                                filterInfoOpen={filterInfoOpen}
                            />
                        }
                    </div>
                </div>
                {filterInfoOpen &&
                    <DataFilterLegendInfo
                        filters={dataFilter.filters}
                        onCloseDataFilterInfo={this.onCloseDataFilterInfo}
                        isCondensedLayout={isCondensedLayout}
                    />
                }
                {satelliteDetailsOpen &&
                    <SatelliteLegendDetails
                        mapInstance={mapInstance}
                        isCondensedLayout={isCondensedLayout}
                        onClose={this.onCloseSatelliteDetails}
                    />
                }
            </div>
        );
    }

    getGeoJsonMetadata = () => {
        this.bus.once('GEO_JSON_METADATA', this.onGeoJsonMetadataRetrieved);
        this.emit('GEO_JSON_METADATA_REQUEST', { source: this });
    }

    getLayerLibraryMetadata = () => {
        this.bus.once('LIBRARY_DATA', this.onLayerLibraryDataRetrieved);
        this.emit('LIBRARY_DATA_REQUEST', { source: this });
    }

    getVariableMetadata = () => {
        this.emit('VARIABLE_SELECTION_METADATA_INFO_REQUEST', {
            source: this,
            mapInstanceId: this.props.mapInstance.id,
            dataDictionaryInfo: true,
            variableSelectionItems: this.props.mapInstance.dataTheme.variableSelection.items,
        });
    }
}

export default VisualizationLegend;
