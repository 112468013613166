

const {TriangleIndexArray} = require('../data/index_array_type');
const Buffer = require('../gl/vertex_buffer');
const allDots = {};
const tiles = {};

class DotDensityHandler {

    static get allDots() {
        return allDots;
    }

    static get tiles() {
        return tiles;
    }

    static addDotDensityData(data, tile, mapId) {
        if (!data) return;
        if (tiles[mapId] === undefined) tiles[mapId] = {};
        if (allDots[mapId] === undefined) allDots[mapId] = {};
        if (tiles[mapId][tile.coord.z] === undefined) tiles[mapId][tile.coord.z] = {};
        data.buckets.forEach(b => {
            if (b.data !== undefined) {
                if (DotDensityHandler.allDots[mapId][b.zoom] === undefined) {
                    DotDensityHandler.allDots[mapId][b.zoom] = {
                        layers: {},
                    };
                }
                if (tiles[mapId][tile.coord.z][tile.coord.id] === undefined) tiles[mapId][tile.coord.z][tile.coord.id] = {};
                // Reset dots per layer if any of the following is true:
                //   1. not already initialized
                //   2. 'source-layer' has been changed, but the 'id' is still the same (e.g. smooth transitions between geos within the same layer)
                for (const lid in b.data.layers) {
                    // layer removed from style
                    if (!tile.buckets[lid]) {
                        delete tiles[mapId][tile.coord.z][tile.coord.id][lid];
                        delete DotDensityHandler.allDots[mapId][b.zoom].layers[lid];
                        continue;
                    }

                    if (tiles[mapId][tile.coord.z][tile.coord.id][lid] === undefined) tiles[mapId][tile.coord.z][tile.coord.id][lid] = {};
                    const dotsDictionary = DotDensityHandler.allDots[mapId][b.zoom].layers[lid];
                    const formula = b.data.layers[lid].metadata.formula;
                    const sourceLayer = b.data.layers[lid].metadata.sourceLayer;
                    const source = b.data.layers[lid].metadata.source;
                    if (dotsDictionary === undefined || // 1.
                        DotDensityHandler.allDots[mapId][b.zoom].layers[lid].formula !== formula ||
                        dotsDictionary.sourceLayer !== sourceLayer) { // 2.
                        DotDensityHandler.allDots[mapId][b.zoom].layers[lid] = {
                            source: source,
                            sourceLayer: sourceLayer,
                            formula: formula,
                            features: {}
                        };
                    }
                    b.data.layers[lid].fids.forEach(feature => {
                        const currentAvailableDots = DotDensityHandler.allDots[mapId][b.zoom].layers[lid].features[feature.id];
                        DotDensityHandler.allDots[mapId][b.zoom].layers[lid].features[feature.id] = currentAvailableDots !== undefined ? currentAvailableDots : feature.countTotal;
                    });
                    // Each bucket will try to draw a particular number of dots regardless of what the other
                    // buckets drew. So this is the rendezvous point for all buckets to decide whether they can
                    // or can not draw those dots.
                    const buffer = tile.buckets[lid].indexArray.arrayBuffer;
                    const indexArray = new TriangleIndexArray();
                    const uint16View = new Uint16Array(buffer);
                    b.data.layers[lid].fids.forEach(feature => {
                        const fid = feature.id;
                        const remainingDots = DotDensityHandler.allDots[mapId][b.zoom].layers[lid].features[fid];
                        if (feature.countPartial > remainingDots) {
                            // We have to slice the arrays
                            const elementArraySkipStartIndex = feature.offset + 6 * remainingDots;
                            const elementArrayFeatureEndIndex = feature.offset + feature.size;
                            for (let j = elementArraySkipStartIndex; j < elementArrayFeatureEndIndex; j++) {
                                uint16View[j] = 0;
                            }
                            DotDensityHandler.allDots[mapId][b.zoom].layers[lid].features[fid] = 0;
                            tiles[mapId][tile.coord.z][tile.coord.id][lid][fid] = remainingDots;
                        } else {
                            // Everything is cool, keep everything as is and subtract the number of dots from
                            // dictionary
                            tiles[mapId][tile.coord.z][tile.coord.id][lid][fid] = feature.countPartial;
                            DotDensityHandler.allDots[mapId][b.zoom].layers[lid].features[fid] -= feature.countPartial;
                        }
                    });
                    indexArray.arrayBuffer = uint16View.buffer;
                    indexArray.length = uint16View.buffer.byteLength;
                    indexArray.capacity = uint16View.buffer.byteLength;
                    indexArray._trim();
                    tile.buckets[lid].indexArray = indexArray;
                }
            }
        });
    }

    static removeDotDensityData(tile, mapId) {
        if (tiles[mapId] === undefined || tiles[mapId][tile.coord.z] === undefined || tiles[mapId][tile.coord.z][tile.coord.id] === undefined) return;
        const zoom = tile.coord.z;
        if (DotDensityHandler.allDots[mapId][zoom] === undefined || Object.keys(DotDensityHandler.allDots[mapId][zoom].layers).length === 0) return;
        for (const lid in tiles[mapId][tile.coord.z][tile.coord.id])
            if (DotDensityHandler.allDots[mapId][zoom].layers.hasOwnProperty(lid))
                for (const fid in tiles[mapId][tile.coord.z][tile.coord.id][lid]) {
                    if (tiles[mapId][tile.coord.z][tile.coord.id][lid][fid] !== undefined)
                        DotDensityHandler.allDots[mapId][zoom].layers[lid].features[fid] += tiles[mapId][tile.coord.z][tile.coord.id][lid][fid];
                }
        delete tiles[mapId][tile.coord.z][tile.coord.id];
    }

}

module.exports = DotDensityHandler;
