import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import BusComponent from '../BusComponent';
import Loader from '../Loader';
import TextInput from '../form/TextInput';
import Tooltip from '../Tooltip';
import CategoryTablesList from './CategoryTablesList';

class CategoryTables extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            availableTableVariables: [],
            hasData: false,
            filterInputText: '',
        };
        this.bindGluBusEvents({
            CATEGORY_TABLES_LOAD_SUCCESS: this.onCategoryTablesLoadSuccess,
        });
    }

    componentDidMount() {
        const { mapInstanceId, metadataGroupId, categoryName, year } = this.props;
        this.emit('CATEGORY_TABLES_LOAD_REQUEST', { mapInstanceId, metadataGroupId, categoryName, year });
    }

    componentWillReceiveProps(nextProps) {
        const { metadataGroupId, categoryName, year } = this.props;
        if (nextProps.categoryName !== categoryName ||
            nextProps.year !== year ||
            nextProps.metadataGroupId !== metadataGroupId) {
            this.emit('CATEGORY_TABLES_LOAD_REQUEST', {
                mapInstanceId: nextProps.mapInstanceId,
                metadataGroupId: nextProps.metadataGroupId,
                categoryName: nextProps.categoryName,
                year: nextProps.year,
            });
            this.setState({
                hasData: false,
            });
        }
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    onCategoryTablesLoadSuccess(eventMap) {
        const { availableTableVariables } = this.getAvailableTableVariables(eventMap.dataCategory);
        this.setState({
            dataCategory: eventMap.dataCategory,
            availableTableVariables,
            hasData: true,
            filterInputText: '',
        });
    }

    getAvailableTableVariables(dataCategory) {
        const selectedSurveyName = this.props.currentMetadataSelection.survey.name;
        const availableTableVariables = [];
        // filtering only variables from the current survey
        const availableTables = dataCategory.tablesAsArray.filter(t => t.dataset.survey.name === selectedSurveyName);
        availableTables.forEach(table => {
            availableTableVariables.push({
                tableUuid: table.uuid,
                tableDisplayName: table.displayName,
                tableTitle: table.title,
                tableName: table.getTitleShort(0),
                tableVariables: table.variablesAsArray,
            });
        });
        return { availableTableVariables };
    }

    onFilterChange = value => {
        const { dataCategory } = this.state;
        const filterText = value ? value.toLowerCase().trim() : '';
        if (filterText && filterText.length > 0) {
            const filterArray = filterText.split(' ');

            const filteredAvailableTableVariables = [];
            // first we must get only the tables from the current survey
            const { availableTableVariables } = this.getAvailableTableVariables(dataCategory);
            availableTableVariables.forEach(table => {
                const filteredVariables = table.tableVariables.filter(variable => {
                    const text = variable.getLabel(0, false).toLowerCase();
                    let variableLabelContainsFilterString = true;
                    for (let i = 0; i < filterArray.length; i += 1) {
                        if (text.indexOf(filterArray[i]) === -1) {
                            variableLabelContainsFilterString = false;
                            break;
                        }
                    }
                    return variableLabelContainsFilterString;
                });

                if (filteredVariables.length) {
                    filteredAvailableTableVariables.push({ ...table, tableVariables: filteredVariables });
                }
            });

            this.setState({
                availableTableVariables: filteredAvailableTableVariables,
                filterInputText: value,
            });
        } else {
            // Show all tables when text is cleared
            const { availableTableVariables } = this.getAvailableTableVariables(dataCategory);
            this.setState({
                availableTableVariables,
                filterInputText: value,
            });
        }
    }

    render() {
        const { hasData, availableTableVariables, filterInputText } = this.state;
        const { className, onBack, categoryIcon, categoryName, year, onSelectVariable, fieldName, intl } = this.props;
        return (<div
            className={classNames('category-tables flex-it column', className)}
        >
            <div className="category-tables__details flex-it no-shrink center space-between">
                <Tooltip overlay={intl.formatMessage({ id: 'dataBrowser.allCategories' })}>
                    <button onClick={onBack} className="material-icons btn-flat-icon" aria-label="Back">arrow_back</button>
                </Tooltip>
                <div className="flex-it center justify-center grow">
                    <img
                        className="category-tables__icon"
                        src={categoryIcon}
                        alt="category icon"
                    />
                    <span className="category-tables__title">
                        {`${categoryName}, ${year}`}
                    </span>
                </div>
            </div>
            <div className="category-tables__filter">
                <TextInput
                    className="input-box--categories-filter"
                    value={filterInputText}
                    placeholder={intl.formatMessage({ id: 'variablePicker.filterList' })}
                    onChange={this.onFilterChange}
                    showClearIcon
                    clearIcon="cancel"
                />
            </div>
            {hasData &&
            <CategoryTablesList
                fieldName={fieldName}
                availableTableVariables={availableTableVariables}
                noResults={availableTableVariables.length === 0 && filterInputText.length > 0}
                onSelectVariable={onSelectVariable}
            />}
            {!hasData && <Loader text={intl.formatMessage({ id: 'variablePicker.gettingDataCategoryDetails' })} />}
        </div>);
    }
}

CategoryTables.propTypes = {
    className: PropTypes.string,
    categoryName: PropTypes.string.isRequired,
    categoryIcon: PropTypes.string.isRequired,
    year: PropTypes.number.isRequired,
    mapInstanceId: PropTypes.string.isRequired,
    metadataGroupId: PropTypes.string.isRequired,
    currentMetadataSelection: PropTypes.object.isRequired,
    onBack: PropTypes.func.isRequired,
    onSelectVariable: PropTypes.func.isRequired,
    fieldName: PropTypes.string,
    intl: PropTypes.object.isRequired,
};

CategoryTables.defaultProps = {
    className: '',
    fieldName: '',
};

export default injectIntl(CategoryTables);
