// @ts-check
import React from 'react';

import BusComponent from '../BusComponent';

/**
 * @typedef Props
 * @property {import('@turf/helpers').Feature} feature
 * @property {string} mapInstanceId
 * @property {string[]} searchFields
 *
 * @extends {BusComponent<Props>}
 */
class PointSearchResultListItem extends BusComponent {
    onClick = () => {
        this.emit('FEATURE_TO_LOCATION_ANALYSIS', { feature: this.props.feature, mapInstanceId: this.props.mapInstanceId });
    };

    render() {
        const title = this.props.searchFields
            .filter(searchField => searchField.isTitle)
            .map(
                searchField =>
                    this.props.feature.properties[searchField.property],
            )
            .join('');

        const other = this.props.searchFields
            .filter(searchField => !searchField.isTitle)
            .map(
                searchField =>
                    this.props.feature.properties[searchField.property],
            )
            .join(', ');
        return (
            <li className="search-results__list-item" onClick={this.onClick}>
                <div
                    className="flex-it space-between center grow stretch-width"
                    title={title}
                >
                    <div className="flex-it stretch flex-item ellipsis">
                        <span className="main-content">{title}</span>
                        <span className="other-content">, {other}</span>
                    </div>
                </div>
            </li>
        );
    }
}

export default PointSearchResultListItem;
