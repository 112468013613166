import React from 'react';
import BusComponent from '../BusComponent';
import TabbedBrowser from '../tabbedBrowser/TabbedBrowser';
import LinkTab from './tabs/LinkTab';
import EmbedTab from './tabs/EmbedTab';
import Loader from '../Loader';
import Toggle from '../form/Toggle';

class SharePopup extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            showLoader: props.showLoader,
            snapshot: props.snapshot,
            isPublic: props.project.isPublic,
        };
        this.bindGluBusEvents({
            SHARE_SNAPSHOT_LOAD_SUCCESS: this.onShareSnapshotLoadSuccess,
            SHARE_SNAPSHOT_LOAD_ERROR: this.onShareSnapshotLoadError,
            PROJECT_UPDATE_SUCCESS: this.onTogglePublicFlagSuccess,
            PROJECT_UPDATE_ERROR: this.onTogglePublicFlagError,
        });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    onUpdateVisibility = () => {
        const { isPublic } = this.state;
        this.emit('PROJECT_UPDATE_VISIBILITY_REQUEST', { isPublic: !isPublic });
    }


    onShareSnapshotLoadSuccess = payload => {
        this.setState({ snapshot: payload.snapshot, showLoader: false });
    }

    onShareSnapshotLoadError = payload => {
        this.setState({ error: payload.additionalInfo });
    }

    onTogglePublicFlagSuccess = payload => {
        this.setState({ isPublic: payload.project.isPublic });
    }

    onTogglePublicFlagError = payload => {
        this.setState({ error: payload.additionalInfo });
    }

    renderWarning() {
        if (this.props.hasUnsavedChanges) {
            return (
                <div className="share-warning-container">
                    <div className="share-warning">
                        <b>Warning:</b> You have some unsaved changes.
                    </div>
                </div>);
        }
        return null;
    }

    render() {
        const { project, currentUser } = this.props;
        const { isPublic, showLoader, error, snapshot } = this.state;

        if (showLoader) {
            return <Loader text="Preparing snapshot..." />;
        }
        if (error) {
            return <div>{error}</div>;
        }

        const userLoggedIn = currentUser && currentUser.userId !== -1;
        const editable = userLoggedIn && project.canSave;
        const availableTabs = [
            { name: 'Link' },
            { name: 'Embed' },
        ];

        const tabs = [
            <LinkTab key={0} snapshot={snapshot} isPublic={isPublic} />,
            <EmbedTab viewCode={snapshot.viewCode} key={2} />,
        ];

        return (<div ref={sharePopup => { this.sharePopup = sharePopup; }} className="flex-it column grow">
            {this.renderWarning()}
            {editable &&
            <div className="visibility-switch">
                <div className="flex-space-between">
                    <div className="">
                        <div className="visibility-switch__title">{isPublic ? 'Public' : 'Private'} project</div>
                        <span className="visibility-switch__info">{isPublic ? 'Visible to anyone with the link' : 'Visible only to you and people you invite'}</span>
                    </div>
                    <Toggle className="visibility-switch__toggle" checked={isPublic} onChange={this.onUpdateVisibility} />
                </div>
            </div>
            }
            <TabbedBrowser tabNames={availableTabs} tabSwitchedCallback={() => {}} className="grow" >
                {tabs}
            </TabbedBrowser>
        </div>);
    }

}

export default SharePopup;

