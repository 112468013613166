import React from 'react';
import { injectIntl } from 'react-intl';
import Popover from '../popover/Popover';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const WarningPopup = ({ open, anchorElement, position, onCancel, onConfirm, intl }) => {
    const { arrowClasses, horizontalOffset, verticalOffset, targetOrigin } = position;
    const dataFilterClasses = classNames('data-filter-warning', arrowClasses);

    return (<Popover
        anchorEl={anchorElement}
        open={open}
        className="data-filter-warning-popover"
        autoWidth
        marginTop={verticalOffset}
        marginLeft={horizontalOffset}
        targetOrigin={targetOrigin}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
    >
        <div>
            <div className={dataFilterClasses}>
                <div className="data-filter-warning__header">
                    <h1 className="data-filter-warning__title">
                        {intl.formatMessage({ id: 'confirm' })}?
                    </h1>
                    <button className="btn-flat-icon" onClick={onCancel}>
                        <i className="material-icons">close</i>
                    </button>
                </div>
                <div className="data-filter-warning__text">
                    {intl.formatMessage({ id: 'variablePicker.areYouSureYouWantToChooseVariable' })}
                </div>
                <div className="data-filter-warning__buttons">
                    <button className="btn-flat btn-flat--gray" onClick={onCancel}>
                        {intl.formatMessage({ id: 'cancel' })}
                    </button>
                    <button className="btn-flat" onClick={onConfirm}>
                        {intl.formatMessage({ id: 'confirm' })}
                    </button>
                </div>
            </div>
        </div>
    </Popover>);
};

WarningPopup.propTypes = {
    open: PropTypes.bool.isRequired,
    anchorElement: PropTypes.object.isRequired,
    position: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(WarningPopup);
