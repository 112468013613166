'use strict';var WebWorker = require('./web_worker');
var WorkerPool = function WorkerPool() {
    this.active = {};
};
WorkerPool.prototype.acquire = function acquire(mapId) {
    var this$1 = this;
    if (!this.workers) {
        var workerCount = require('../').workerCount;
        this.workers = [];
        while (this.workers.length < workerCount) {
            this$1.workers.push(new WebWorker());
        }
    }
    this.active[mapId] = true;
    return this.workers.slice();
};
WorkerPool.prototype.release = function release(mapId) {
    delete this.active[mapId];
    if (Object.keys(this.active).length === 0) {
        this.workers.forEach(function (w) {
            w.terminate();
        });
        this.workers = null;
    }
};
module.exports = WorkerPool;