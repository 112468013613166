/* eslint class-methods-use-this: 0 */
import BaseController from './BaseController';
import ProjectDataSource from '../dataSources/ProjectDataSource';
import FilterStatus from '../enums/FilterStatus';
import DataFilterRule from '../objects/dataFilter/DataFilterRule';
import DataFilterField from '../objects/dataFilter/DataFilterField';
import uuid from 'node-uuid';
import FilterTypes from '../enums/FilterTypes';
import MetadataDataType from '../enums/MetadataDataType';
import DataFilterBaseVariable from '../objects/dataFilter/DataFilterBaseVariable';
import MetadataDataSource from '../dataSources/MetadataDataSource';
import buildParentVariableTree from '../helpers/Metadata';

class DataFilterController extends BaseController {
    static get name() {
        return 'DataFilterController';
    }

    static getInstance(options) {
        return new DataFilterController(options);
    }

    onActivate() {
        this.bindGluBusEvents({
            ENTER_DATA_FILTER_MODE: this.onEnterDataFilterMode,
            EXIT_DATA_FILTER_MODE: this.onExitDataFilterMode,
            REMOVE_DATA_FILTER_REQUEST: this.onRemoveDataFilterRequest,
            ADD_NEW_FILTER_REQUEST: this.onAddFilter,
            REMOVE_FILTER_REQUEST: this.onRemoveFilter,
            UPDATE_FILTER_VARIABLE_REQUEST: this.onVariableUpdate,
            UPDATE_FILTER_COMBINER_REQUEST: this.onFilterCombinerUpdate,
            UPDATE_FILTER_TYPE_REQUEST: this.onFilterTypeUpdate,
            UPDATE_FILTER_BASE_VARIABLE_REQUEST: this.onBaseVariableUpdate,
            UPDATE_FILTER_VALUE_REQUEST: this.onFilterValueUpdate,
        });
        this._mapFilteringActive = false;
        this.projectDataSource = this.activateSource(ProjectDataSource);
        this.metadataDataSource = this.activateSource(MetadataDataSource);

        this.mapViewer = null;
    }

    onEnterDataFilterMode({ mapInstanceId }) {
        // already in filtering mode
        if (this._mapFilteringActive) {
            return;
        }
        const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);
        const variableSelection = mapInstance.dataTheme.variableSelection.items;
        const metadataSelection = this.metadataDataSource.getMetadataVariableSelection(variableSelection);

        this._mapFilteringActive = true;
        this.bus.emit('DISPLAY_DATA_FILTER_EDITOR_FRAME', {
            mapInstance,
            surveyName: metadataSelection.survey.displayName,
        });
    }

    onExitDataFilterMode({ mapInstanceId, newMode, displayDataBrowser }) {
        if (!this._mapFilteringActive) {
            return;
        }

        const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);
        this._mapFilteringActive = false;
        this.bus.emit('HIDE_DATA_FILTER_EDITOR_FRAME', { mapInstance, newMode, displayDataBrowser });
    }

    // After the map is rendered we can calculate the min and max values for selected filter variables
    onMapRendered = eventMap => {
        this.mapViewer = eventMap.source;
        const mapInstance = this.projectDataSource.getActiveMapInstance(eventMap.mapInstanceId);
        this.parseRawData();

        // get the fields used in filters
        const filters = mapInstance.dataFilter.filters;
        const fields = Object.keys(filters).map(key => filters[key].field);

        const payload = { variablesData: {} };

        // For each field calculate the min and max values
        fields.forEach(item => {
            const data = this.rawData
                .filter(d => {
                    const value = parseFloat(d.properties[`${item.fieldName}`]);
                    return !isNaN(value) && isFinite(value) && value !== 0;
                })
                .map(d => d.properties[`${item.fieldName}`]);

            data.sort((a, b) => a - b);
            payload.variablesData[item.fieldName] = {
                min: data[0],
                max: data[data.length - 1],
            };
        });
        this.bus.emit('FILTER_VARIABLES_MIN_MAX', payload);
    }

    getFilters = (mapInstanceId, filterId) => {
        const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);
        const { filters } = mapInstance.dataFilter;

        return { filters, filterToUpdate: filters[filterId] };
    }

    onAddFilter = ({ mapInstanceId }) => {
        const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);
        const { filters } = mapInstance.dataFilter;
        const filter = new DataFilterRule({
            filterId: uuid.v4(),
            type: '>',
            field: new DataFilterField(),
            value: '',
            status: FilterStatus.NEW,
            baseVariables: [],
        });
        filters[filter.filterId] = filter;

        this.bus.emit('DATA_FILTERS_ADD_FILTER_SUCCESS', { filters, filter });
    };

    onRemoveFilter = ({ filterId, mapInstanceId }) => {
        const { filters, filterToUpdate } = this.getFilters(mapInstanceId, filterId);
        const applyToMap = filterToUpdate && filterToUpdate.status === FilterStatus.VALID;
        delete filters[filterId];

        this.doUpdate(applyToMap, filters, mapInstanceId);
    };

    onVariableUpdate = ({ filterId, variable, mapInstanceId, categoryName }) => {
        const { filters, filterToUpdate } = this.getFilters(mapInstanceId, filterId);
        const selection = filterToUpdate.field;

        selection.categoryName = categoryName;

        const baseVariables = buildParentVariableTree(
            Object.values(variable.table.variables),
            variable,
            [],
        );

        selection.surveyName = variable.table.dataset.survey.name; // 'ACS2015_5yr';
        selection.datasetAbbreviation = variable.table.dataset.abbrevation; // 'SE'
        selection.datasetId = variable.table.dataset.uuid; // '7a422ab2-749e-4e8e-a2ea-e2c85c9c4546';
        selection.tableGuid = variable.table.uuid; // 'b3bc6c88-996b-41c9-8354-80e9a0637567';
        selection.tableName = variable.table.name;
        selection.fieldName = variable.uuid; // '8882d48f-6991-4b1a-bcda-011eae565f0d';
        selection.variableCode = variable.name; // A00002_002
        selection.qLabel = variable.qLabel;
        selection.baseFieldName = (baseVariables.length > 0 && baseVariables[0].uuid) || variable.uuid; // store base variable uuid to compute percents
        selection.fieldDataType = variable.dataType;
        selection.formatting = variable.formatting;

        filterToUpdate.baseVariables = baseVariables.map(v => new DataFilterBaseVariable({
            uuid: v.uuid,
            label: v.label,
            qLabel: v.qLabel,
        }));

        // If the data type is STRING set the comparison operator to equal and save the options list
        if (variable.dataType === MetadataDataType.STRING) {
            filterToUpdate.type = FilterTypes.EQUAL.type;
            // find the system category for the selected string variable
            const systemCategoryFilter = this.metadataDataSource._currentMetadata.systemCategoryFilters.find(c => c.name === variable.defaultFilterSetName);
            // remap the filters array to select only the label
            selection.optionsList = systemCategoryFilter.filterSets[0].filters.map(f => f._label);
        }
        // UPDATE FILTER STATUS
        if (filterToUpdate.status !== FilterStatus.VALID) {
            filterToUpdate.status = FilterStatus.VARIABLE_SELECTED;
        }

        // Subscribe to event fired on map render so we can get min/max values for the variable
        // after the tiles have been delivered
        this.bus.once('MAP_RENDERED', this.onMapRendered);

        this.doUpdate(true, filters, mapInstanceId);
    };

    parseRawData = () => {
        if (this.mapViewer === null) return;
        this.rawData = this.mapViewer.dragonflyMap.queryRenderedFeatures(undefined, { layers: [this.mapViewer.higlightLayerId] })
            .reduce((d, el) => {
                if (d.findIndex(e => e.id === el.id) === -1) {
                    d.push({ id: el.id, properties: el.properties });
                }
                return d;
            }, []);
    }

    onFilterTypeUpdate = ({ filterId, type, mapInstanceId }) => {
        const { filters, filterToUpdate } = this.getFilters(mapInstanceId, filterId);
        filterToUpdate.type = type;

        const applyToMap = filterToUpdate.status === FilterStatus.VALID;
        this.doUpdate(applyToMap, filters, mapInstanceId);
    }

    onBaseVariableUpdate = ({ filterId, baseFieldName, mapInstanceId }) => {
        const { filters, filterToUpdate } = this.getFilters(mapInstanceId, filterId);
        const selection = filterToUpdate.field;
        selection.baseFieldName = baseFieldName; // store base variable uuid to compute percents;

        const applyToMap = filterToUpdate.status === FilterStatus.VALID;
        this.doUpdate(applyToMap, filters, mapInstanceId);
    }

    onFilterValueUpdate = ({ filterId, value, mapInstanceId }) => {
        const { filterToUpdate } = this.getFilters(mapInstanceId, filterId);
        if (filterToUpdate.value !== value) {
            filterToUpdate.value = value;
            filterToUpdate.status = value.length === 0 ? FilterStatus.VARIABLE_SELECTED : FilterStatus.VALID;
            this.applyDataFilter(mapInstanceId);
        }
    };

    onFilterCombinerUpdate = ({ filterCombiner, mapInstanceId }) => {
        const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);
        mapInstance.dataFilter.filterCombiner = filterCombiner;

        if (mapInstance.hasDataFilter) {
            this.applyDataFilter(mapInstanceId);
        } else {
            this.bus.emit('FILTER_COMBINER_UPDATED', { filterCombiner });
        }
    }

    // If the filter update doesn't require a map update just update the state of the react component
    doUpdate = (applyToMap, filters, mapInstanceId) => {
        if (applyToMap) {
            this.applyDataFilter(mapInstanceId);
        } else {
            this.bus.emit('DATA_FILTERS_UPDATE_SUCCESS', { filters });
        }
    }

    applyDataFilter = mapInstanceId => {
        const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);

        this.bus.emit('MAP_APPLY_DATA_FILTER_REQUEST', { mapInstanceId });
        if (mapInstance.hasMaskingFilter) {
            this.bus.emit('MAP_RECONSTRUCT_CURRENT_MAP', { mapInstanceId });
        }
    }

    onRemoveDataFilterRequest = ({ mapInstanceId }) => {
        const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);
        if (!mapInstance.hasDataFilter) {
            mapInstance.dataFilter.filters = {};
            return;
        }
        mapInstance.dataFilter.filters = {};

        this.bus.emit('MAP_APPLY_DATA_FILTER_REQUEST', { mapInstanceId });
        if (mapInstance.hasMaskingFilter) {
            this.bus.emit('MAP_RECONSTRUCT_CURRENT_MAP', { mapInstanceId });
        }
    }

    onDeactivate() {
        this.unbindGluBusEvents();
    }
}

export default DataFilterController;
