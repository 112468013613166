/* eslint react/no-array-index-key: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import ColorPicker from '../colorPickerCompact/ColorPicker';

class ColorMultiEditor extends React.Component {
    onApplyDataColor = (color, index) => {
        const colorPalette = this.props.colorPalette.clone();
        colorPalette.colors[index] = color;
        this.props.applyColorPalette(colorPalette);
    };

    render() {
        const dataColorButtons = this.props.colorPalette.colors.map((color, index) => {
            const onApplyDataColor = c => {
                this.onApplyDataColor(c, index);
            };
            return (<ColorPicker
                key={index}
                onChange={onApplyDataColor}
                color={color}
            />);
        });

        return (
            <div className="color-multi-editor">
                <span className="color-palette-editor__text">Data Colors</span>
                <div className="additional-controls flex-it space-between center">
                    {dataColorButtons}
                </div>
            </div>);
    }
}


ColorMultiEditor.propTypes = {
    colorPalette: PropTypes.object.isRequired,
    applyColorPalette: PropTypes.func.isRequired,
};
ColorMultiEditor.defaultProps = {
};

export default ColorMultiEditor;
