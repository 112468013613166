// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import BusComponent from '../../BusComponent';
import VariableSelection from '../../../objects/VariableSelection';
import VariableSelectionItem from '../../../objects/VariableSelectionItem';

import { variableSearchResultButtonEvents } from '../../../enums/GoogleTagManagerEvents';
import googleTagManagerEvents from '../../../helpers/GoogleTagManagerEventsWrapper';

const VariableWrapper = googleTagManagerEvents(
    'button',
    variableSearchResultButtonEvents,
);

/**
 * @typedef Props
 * @property {import('../../../objects/MetaVariable').default} variable
 * @property {import('../../../objects/MapInstance').default} mapInstance
 * @property {VariableSelectionItem[]} currentVariableSelectionItems
 * @property {string} query
 *
 * @extends {BusComponent<Props>}
 */
class VariableIndent extends BusComponent {
    componentDidMount() {
        const gtmEventLabel = `${this.props.variable.table.surveyName}|${this.props.variable.table.name}|${this.props.variable.name}`;
        this.variable.setCustomAttribute('gtm-eventa', this.props.query);
        this.variable.setCustomAttribute('gtm-eventl', gtmEventLabel);
    }

    onVariableClick = () => {
        const { variable } = this.props;
        const variableSelectionItem = new VariableSelectionItem({
            surveyName: variable.table.surveyName,
            datasetAbbreviation: variable.table.dataset.abbrevation,
            variableGuid: variable.uuid,
            tableGuid: variable.table.uuid,
        });

        const variableSelection = new VariableSelection();
        variableSelection.items = [variableSelectionItem];

        this.emit('DATA_SELECTION_CHANGE', {
            source: this,
            mapInstanceId: this.props.mapInstance.id,
            newDataSelection: { variableSelection },
        });

        this.emit('COUNTER_LOG_REQUEST', [
            {
                event_type: 'item_request',
                event_value: variable.uuid,
            },
            {
                event_type: 'item_investigation',
                event_value: variable.uuid,
            },
        ]);
    };

    render() {
        const currentSelection = this.props.currentVariableSelectionItems.find(
            vsi => vsi.variableGuid === this.props.variable.uuid,
        );
        return (
            <div
                className={classNames(
                    'search-variable search-variable--indent',
                    {
                        'search-variable--selected': !!currentSelection,
                    },
                )}
            >
                <VariableWrapper
                    ref={c => {
                        this.variable = c;
                    }}
                    className="flex-it grow search-variable__label"
                    style={{
                        paddingLeft: `${
                            (this.props.variable.indent + 1) * 12
                        }px`,
                    }}
                    onClick={this.onVariableClick}
                    aria-label={this.props.variable.label}
                >
                    {this.props.variable.label}
                </VariableWrapper>
            </div>
        );
    }
}

VariableIndent.propTypes = {
    variable: PropTypes.object.isRequired,
    mapInstance: PropTypes.object.isRequired,
};

export default VariableIndent;
