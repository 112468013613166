import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import BusComponent from '../../BusComponent';
import { ActiveView } from '../../mapLayersEditor/MapLayersEditor';
import LayerLibraryButton from '../../mapLayersEditor/LayerLibraryButton';
import UploadDataButton from '../../mapLayersEditor/UploadDataButton';
import MapSatelliteButton from '../../mapLayersEditor/MapSatelliteButton';

import AdditionalSettingsIconColors from '../../../enums/AdditionalSettingsIconColors';
import AdditionalSettingsItem from './AdditionalSettingsItem';

class MapLayersItem extends BusComponent {
    onMapLayersClick = () => {
        this.emit('DISPLAY_MAP_EDITOR_FRAME', {
            mapInstanceId: this.props.mapInstance.id,
        });
    };

    onUploadDataClick = e => {
        e.stopPropagation();
        this.emit('DISPLAY_MAP_EDITOR_FRAME', {
            mapInstanceId: this.props.mapInstance.id,
            initialView: ActiveView.DATA_UPLOAD,
        });
    };

    onLayerLibraryClick = e => {
        e.stopPropagation();
        const { mapInstance } = this.props;
        this.emit('DISPLAY_MAP_EDITOR_FRAME', {
            mapInstanceId: mapInstance.id,
        });
        this.emit('ADD_LAYER_LIBRARY_LAYERS_POPUP_REQUEST', {
            mapInstanceId: mapInstance.id,
            mapInstance,
        });
    };

    render() {
        const { isUserPro, additionalActions } = this.props;

        const uploadDataButtonClasses = classnames(
            'btn-chip btn-chip--tiny-padding btn-chip--with-margin',
            { 'btn-chip--disabled': !isUserPro },
        );

        const allActions = {
            uploadData: <UploadDataButton
                key="upload-data"
                className={uploadDataButtonClasses}
                onClick={this.onUploadDataClick}
                disabled={!isUserPro}
            />,
            layerLibrary: <LayerLibraryButton
                key="layer-library"
                className="btn-chip btn-chip--tiny-padding btn-chip--with-margin"
                onClick={this.onLayerLibraryClick}
                icon="add_circle"
                label={this.props.intl.formatMessage({
                    id: 'dataBrowser.addLayer',
                })}
            />,
            satelliteView: <MapSatelliteButton
                key="satellite-view"
                mapInstanceId={this.props.mapInstance.id}
            />,
        };

        // const filteredActions = allActions.
        const filteredActions = [];
        Object.keys(additionalActions).forEach(key => {
            if (additionalActions[key]) filteredActions.push(allActions[key]);
        });

        return (
            <AdditionalSettingsItem
                icon={<i className="material-icons">layers</i>}
                onItemClick={this.onMapLayersClick}
                title={this.props.intl.formatMessage({
                    id: 'dataBrowser.mapLayers',
                })}
                description={this.props.intl.formatMessage({
                    id: 'dataBrowser.uploadDataEditBasemapShowSatelliteView',
                })}
                iconStyle={{ background: AdditionalSettingsIconColors.LAYERS }}
                additionalActions={filteredActions}
            />
        );
    }
}

MapLayersItem.propTypes = {
    mapInstance: PropTypes.object.isRequired,
    isUserPro: PropTypes.bool,
    intl: PropTypes.object.isRequired,
    additionalActions: PropTypes.object.isRequired,
};

MapLayersItem.defaultProps = {
    isUserPro: false,
};

export default injectIntl(MapLayersItem);
