'use strict';var ref = require('../types');
var NullType = ref.NullType;
var NumberType = ref.NumberType;
var StringType = ref.StringType;
var BooleanType = ref.BooleanType;
var ColorType = ref.ColorType;
var ObjectType = ref.ObjectType;
var ValueType = ref.ValueType;
var ErrorType = ref.ErrorType;
var array = ref.array;
var toString = ref.toString;
var ref$1 = require('../values');
var typeOf = ref$1.typeOf;
var Color = ref$1.Color;
var validateRGBA = ref$1.validateRGBA;
var ref$2 = require('../compound_expression');
var CompoundExpression = ref$2.CompoundExpression;
var varargs = ref$2.varargs;
var RuntimeError = require('../runtime_error');
var Let = require('./let');
var Var = require('./var');
var Literal = require('./literal');
var Assertion = require('./assertion');
var ArrayAssertion = require('./array');
var Coercion = require('./coercion');
var At = require('./at');
var Match = require('./match');
var Case = require('./case');
var Curve = require('./curve');
var Step = require('./step');
var Interpolate = require('./interpolate');
var Coalesce = require('./coalesce');
var expressions = {
    'let': Let,
    'var': Var,
    'literal': Literal,
    'string': Assertion,
    'number': Assertion,
    'boolean': Assertion,
    'object': Assertion,
    'array': ArrayAssertion,
    'to-number': Coercion,
    'to-color': Coercion,
    'at': At,
    'case': Case,
    'match': Match,
    'coalesce': Coalesce,
    'curve': Curve,
    'step': Step,
    'interpolate': Interpolate
};
function rgba(ctx, ref) {
    var r = ref[0];
    var g = ref[1];
    var b = ref[2];
    var a = ref[3];
    r = r.evaluate(ctx);
    g = g.evaluate(ctx);
    b = b.evaluate(ctx);
    a = a && a.evaluate(ctx);
    var error = validateRGBA(r, g, b, a);
    if (error) {
        throw new RuntimeError(error);
    }
    return new Color(r / 255, g / 255, b / 255, a);
}
function has(key, obj) {
    return key in obj;
}
function get(key, obj) {
    var v = obj[key];
    return typeof v === 'undefined' ? null : v;
}
function length(ctx, ref) {
    var v = ref[0];
    return v.evaluate(ctx).length;
}
function eq(ctx, ref) {
    var a = ref[0];
    var b = ref[1];
    return a.evaluate(ctx) === b.evaluate(ctx);
}
function ne(ctx, ref) {
    var a = ref[0];
    var b = ref[1];
    return a.evaluate(ctx) !== b.evaluate(ctx);
}
function lt(ctx, ref) {
    var a = ref[0];
    var b = ref[1];
    return a.evaluate(ctx) < b.evaluate(ctx);
}
function gt(ctx, ref) {
    var a = ref[0];
    var b = ref[1];
    return a.evaluate(ctx) > b.evaluate(ctx);
}
function lteq(ctx, ref) {
    var a = ref[0];
    var b = ref[1];
    return a.evaluate(ctx) <= b.evaluate(ctx);
}
function gteq(ctx, ref) {
    var a = ref[0];
    var b = ref[1];
    return a.evaluate(ctx) >= b.evaluate(ctx);
}
CompoundExpression.register(expressions, {
    'error': [
        ErrorType,
        [StringType],
        function (ctx, ref) {
            var v = ref[0];
            throw new RuntimeError(v.evaluate(ctx));
        }
    ],
    'typeof': [
        StringType,
        [ValueType],
        function (ctx, ref) {
            var v = ref[0];
            return toString(typeOf(v.evaluate(ctx)));
        }
    ],
    'to-string': [
        StringType,
        [ValueType],
        function (ctx, ref) {
            var v = ref[0];
            v = v.evaluate(ctx);
            var type = typeof v;
            if (v === null || type === 'string' || type === 'number' || type === 'boolean') {
                return String(v);
            } else if (v instanceof Color) {
                return 'rgba(' + v.r * 255 + ',' + v.g * 255 + ',' + v.b * 255 + ',' + v.a + ')';
            } else {
                return JSON.stringify(v);
            }
        }
    ],
    'to-boolean': [
        BooleanType,
        [ValueType],
        function (ctx, ref) {
            var v = ref[0];
            return Boolean(v.evaluate(ctx));
        }
    ],
    'to-rgba': [
        array(NumberType, 4),
        [ColorType],
        function (ctx, ref) {
            var v = ref[0];
            var ref$1 = v.evaluate(ctx);
            var r = ref$1.r;
            var g = ref$1.g;
            var b = ref$1.b;
            var a = ref$1.a;
            return [
                r,
                g,
                b,
                a
            ];
        }
    ],
    'rgb': [
        ColorType,
        [
            NumberType,
            NumberType,
            NumberType
        ],
        rgba
    ],
    'rgba': [
        ColorType,
        [
            NumberType,
            NumberType,
            NumberType,
            NumberType
        ],
        rgba
    ],
    'length': {
        type: NumberType,
        overloads: [
            [
                [StringType],
                length
            ],
            [
                [array(ValueType)],
                length
            ]
        ]
    },
    'has': {
        type: BooleanType,
        overloads: [
            [
                [StringType],
                function (ctx, ref) {
                    var key = ref[0];
                    return has(key.evaluate(ctx), ctx.properties());
                }
            ],
            [
                [
                    StringType,
                    ObjectType
                ],
                function (ctx, ref) {
                    var key = ref[0];
                    var obj = ref[1];
                    return has(key.evaluate(ctx), obj.evaluate(ctx));
                }
            ]
        ]
    },
    'get': {
        type: ValueType,
        overloads: [
            [
                [StringType],
                function (ctx, ref) {
                    var key = ref[0];
                    return get(key.evaluate(ctx), ctx.properties());
                }
            ],
            [
                [
                    StringType,
                    ObjectType
                ],
                function (ctx, ref) {
                    var key = ref[0];
                    var obj = ref[1];
                    return get(key.evaluate(ctx), obj.evaluate(ctx));
                }
            ]
        ]
    },
    'properties': [
        ObjectType,
        [],
        function (ctx) {
            return ctx.properties();
        }
    ],
    'geometry-type': [
        StringType,
        [],
        function (ctx) {
            return ctx.geometryType();
        }
    ],
    'id': [
        ValueType,
        [],
        function (ctx) {
            return ctx.id();
        }
    ],
    'zoom': [
        NumberType,
        [],
        function (ctx) {
            return ctx.globals.zoom;
        }
    ],
    'heatmap-density': [
        NumberType,
        [],
        function (ctx) {
            return ctx.globals.heatmapDensity || 0;
        }
    ],
    '+': [
        NumberType,
        varargs(NumberType),
        function (ctx, args) {
            var result = 0;
            for (var i = 0, list = args; i < list.length; i += 1) {
                var arg = list[i];
                result += arg.evaluate(ctx);
            }
            return result;
        }
    ],
    '*': [
        NumberType,
        varargs(NumberType),
        function (ctx, args) {
            var result = 1;
            for (var i = 0, list = args; i < list.length; i += 1) {
                var arg = list[i];
                result *= arg.evaluate(ctx);
            }
            return result;
        }
    ],
    '-': {
        type: NumberType,
        overloads: [
            [
                [
                    NumberType,
                    NumberType
                ],
                function (ctx, ref) {
                    var a = ref[0];
                    var b = ref[1];
                    return a.evaluate(ctx) - b.evaluate(ctx);
                }
            ],
            [
                [NumberType],
                function (ctx, ref) {
                    var a = ref[0];
                    return -a.evaluate(ctx);
                }
            ]
        ]
    },
    '/': [
        NumberType,
        [
            NumberType,
            NumberType
        ],
        function (ctx, ref) {
            var a = ref[0];
            var b = ref[1];
            return a.evaluate(ctx) / b.evaluate(ctx);
        }
    ],
    '%': [
        NumberType,
        [
            NumberType,
            NumberType
        ],
        function (ctx, ref) {
            var a = ref[0];
            var b = ref[1];
            return a.evaluate(ctx) % b.evaluate(ctx);
        }
    ],
    'ln2': [
        NumberType,
        [],
        function () {
            return Math.LN2;
        }
    ],
    'pi': [
        NumberType,
        [],
        function () {
            return Math.PI;
        }
    ],
    'e': [
        NumberType,
        [],
        function () {
            return Math.E;
        }
    ],
    '^': [
        NumberType,
        [
            NumberType,
            NumberType
        ],
        function (ctx, ref) {
            var b = ref[0];
            var e = ref[1];
            return Math.pow(b.evaluate(ctx), e.evaluate(ctx));
        }
    ],
    'sqrt': [
        NumberType,
        [NumberType],
        function (ctx, ref) {
            var x = ref[0];
            return Math.sqrt(x.evaluate(ctx));
        }
    ],
    'log10': [
        NumberType,
        [NumberType],
        function (ctx, ref) {
            var n = ref[0];
            return Math.log10(n.evaluate(ctx));
        }
    ],
    'ln': [
        NumberType,
        [NumberType],
        function (ctx, ref) {
            var n = ref[0];
            return Math.log(n.evaluate(ctx));
        }
    ],
    'log2': [
        NumberType,
        [NumberType],
        function (ctx, ref) {
            var n = ref[0];
            return Math.log2(n.evaluate(ctx));
        }
    ],
    'sin': [
        NumberType,
        [NumberType],
        function (ctx, ref) {
            var n = ref[0];
            return Math.sin(n.evaluate(ctx));
        }
    ],
    'cos': [
        NumberType,
        [NumberType],
        function (ctx, ref) {
            var n = ref[0];
            return Math.cos(n.evaluate(ctx));
        }
    ],
    'tan': [
        NumberType,
        [NumberType],
        function (ctx, ref) {
            var n = ref[0];
            return Math.tan(n.evaluate(ctx));
        }
    ],
    'asin': [
        NumberType,
        [NumberType],
        function (ctx, ref) {
            var n = ref[0];
            return Math.asin(n.evaluate(ctx));
        }
    ],
    'acos': [
        NumberType,
        [NumberType],
        function (ctx, ref) {
            var n = ref[0];
            return Math.acos(n.evaluate(ctx));
        }
    ],
    'atan': [
        NumberType,
        [NumberType],
        function (ctx, ref) {
            var n = ref[0];
            return Math.atan(n.evaluate(ctx));
        }
    ],
    'abs': [
        NumberType,
        [NumberType],
        function (ctx, ref) {
            var n = ref[0];
            return Math.abs(n.evaluate(ctx));
        }
    ],
    'ceil': [
        NumberType,
        [NumberType],
        function (ctx, ref) {
            var n = ref[0];
            return Math.ceil(n.evaluate(ctx));
        }
    ],
    'floor': [
        NumberType,
        [NumberType],
        function (ctx, ref) {
            var n = ref[0];
            return Math.floor(n.evaluate(ctx));
        }
    ],
    'round': [
        NumberType,
        [NumberType],
        function (ctx, ref) {
            var n = ref[0];
            return Math.round(n.evaluate(ctx));
        }
    ],
    'min': [
        NumberType,
        varargs(NumberType),
        function (ctx, args) {
            return Math.min.apply(Math, args.map(function (arg) {
                return arg.evaluate(ctx);
            }));
        }
    ],
    'max': [
        NumberType,
        varargs(NumberType),
        function (ctx, args) {
            return Math.max.apply(Math, args.map(function (arg) {
                return arg.evaluate(ctx);
            }));
        }
    ],
    '==': {
        type: BooleanType,
        overloads: [
            [
                [
                    NumberType,
                    NumberType
                ],
                eq
            ],
            [
                [
                    StringType,
                    StringType
                ],
                eq
            ],
            [
                [
                    BooleanType,
                    BooleanType
                ],
                eq
            ],
            [
                [
                    NullType,
                    NullType
                ],
                eq
            ]
        ]
    },
    '!=': {
        type: BooleanType,
        overloads: [
            [
                [
                    NumberType,
                    NumberType
                ],
                ne
            ],
            [
                [
                    StringType,
                    StringType
                ],
                ne
            ],
            [
                [
                    BooleanType,
                    BooleanType
                ],
                ne
            ],
            [
                [
                    NullType,
                    NullType
                ],
                ne
            ]
        ]
    },
    '>': {
        type: BooleanType,
        overloads: [
            [
                [
                    NumberType,
                    NumberType
                ],
                gt
            ],
            [
                [
                    StringType,
                    StringType
                ],
                gt
            ]
        ]
    },
    '<': {
        type: BooleanType,
        overloads: [
            [
                [
                    NumberType,
                    NumberType
                ],
                lt
            ],
            [
                [
                    StringType,
                    StringType
                ],
                lt
            ]
        ]
    },
    '>=': {
        type: BooleanType,
        overloads: [
            [
                [
                    NumberType,
                    NumberType
                ],
                gteq
            ],
            [
                [
                    StringType,
                    StringType
                ],
                gteq
            ]
        ]
    },
    '<=': {
        type: BooleanType,
        overloads: [
            [
                [
                    NumberType,
                    NumberType
                ],
                lteq
            ],
            [
                [
                    StringType,
                    StringType
                ],
                lteq
            ]
        ]
    },
    'all': {
        type: BooleanType,
        overloads: [
            [
                [
                    BooleanType,
                    BooleanType
                ],
                function (ctx, ref) {
                    var a = ref[0];
                    var b = ref[1];
                    return a.evaluate(ctx) && b.evaluate(ctx);
                }
            ],
            [
                varargs(BooleanType),
                function (ctx, args) {
                    for (var i = 0, list = args; i < list.length; i += 1) {
                        var arg = list[i];
                        if (!arg.evaluate(ctx)) {
                            return false;
                        }
                    }
                    return true;
                }
            ]
        ]
    },
    'any': {
        type: BooleanType,
        overloads: [
            [
                [
                    BooleanType,
                    BooleanType
                ],
                function (ctx, ref) {
                    var a = ref[0];
                    var b = ref[1];
                    return a.evaluate(ctx) || b.evaluate(ctx);
                }
            ],
            [
                varargs(BooleanType),
                function (ctx, args) {
                    for (var i = 0, list = args; i < list.length; i += 1) {
                        var arg = list[i];
                        if (arg.evaluate(ctx)) {
                            return true;
                        }
                    }
                    return false;
                }
            ]
        ]
    },
    '!': [
        BooleanType,
        [BooleanType],
        function (ctx, ref) {
            var b = ref[0];
            return !b.evaluate(ctx);
        }
    ],
    'upcase': [
        StringType,
        [StringType],
        function (ctx, ref) {
            var s = ref[0];
            return s.evaluate(ctx).toUpperCase();
        }
    ],
    'downcase': [
        StringType,
        [StringType],
        function (ctx, ref) {
            var s = ref[0];
            return s.evaluate(ctx).toLowerCase();
        }
    ],
    'concat': [
        StringType,
        varargs(StringType),
        function (ctx, args) {
            return args.map(function (arg) {
                return arg.evaluate(ctx);
            }).join('');
        }
    ]
});
module.exports = expressions;