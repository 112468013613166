const DataFilterWarningTypes = {
    CHANGE_SURVEY_WARNING: {
        verticalOffset: -20,
        horizontalOffset: 23,
        arrowClasses: 'arrow-pointer arrow-south arrow-right-30',
        targetOrigin: { horizontal: 'right', vertical: 'bottom' },
        anchorOrigin: { horizontal: 'right', vertical: 'top' },
    },
    CHANGE_OVER_TIME_WARNING: {
        LEFT: {
            verticalOffset: -20,
            horizontalOffset: -64,
            arrowClasses: 'arrow-pointer arrow-south arrow-left-30',
            targetOrigin: { horizontal: 'left', vertical: 'bottom' },
            anchorOrigin: { horizontal: 'right', vertical: 'top' },
        },
        RIGHT: {
            verticalOffset: -20,
            horizontalOffset: 20,
            arrowClasses: 'arrow-pointer arrow-south arrow-right-30',
            targetOrigin: { horizontal: 'right', vertical: 'bottom' },
            anchorOrigin: { horizontal: 'right', vertical: 'top' },
        },
    },
    CHANGE_OVER_TIME_MAP_PANEL_WARNING: {
        LEFT: {
            verticalOffset: 48,
            horizontalOffset: -74,
            arrowClasses: 'arrow-pointer arrow-north arrow-left-30',
            targetOrigin: { horizontal: 'left', vertical: 'top' },
            anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        },
        RIGHT: {
            verticalOffset: 48,
            horizontalOffset: 20,
            arrowClasses: 'arrow-pointer arrow-north arrow-right-30',
            targetOrigin: { horizontal: 'right', vertical: 'top' },
            anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        },
    },
};

export default DataFilterWarningTypes;
