// @ts-check
import React from 'react';
import classnames from 'classnames';
import BusComponent from '../../BusComponent';

import DataBrowserBasicSearch from './DataBrowserBasicSearch';
import Results from './Results';
import DidYouMean from './DidYouMean';
import Suggestions from './Suggestions';
import SearchFilters from './SearchFilters';

import Keys from '../../../enums/Key';
import Loader from '../../Loader';
import { isStorageAvailable } from '../../../helpers/Util';

const STORAGE = window.localStorage;
const STORAGE_PREFIX = 'DataBrowserSearchPersistance';
const IS_STORAGE_AVAILABLE = isStorageAvailable(STORAGE);

// remove key each time application is reloaded, so the fresh state is used
if (IS_STORAGE_AVAILABLE) STORAGE.removeItem(STORAGE_PREFIX);

class DataBrowserBySearch extends BusComponent {
    constructor(props, context) {
        super(props, context);

        const yearsSet = new Set();
        this.props.mapsGroupSurveys.forEach(ms => yearsSet.add(ms.year));

        if (IS_STORAGE_AVAILABLE) {
            const storedJSON = localStorage.getItem(STORAGE_PREFIX);
            try {
                this.state = JSON.parse(
                    storedJSON.replace(/null/g, '"undefined"'),
                );
            } catch (error) {
                this.state = {
                    query: '',
                    searchTerm: '', // searchTerm represents the last successful searched query
                    totalHits: -1,
                    results: [],
                    applyingVariable: false,
                    yearsList: [...yearsSet].sort().reverse(),
                    didYouMean: [],
                    suggestions: [],
                    selectedYear: undefined,
                    selectedSurvey: undefined,
                };
            }
        }
    }

    componentDidMount() {
        this.bindGluBusEvents({
            VARIABLE_SEARCH_SUCCESS: this.onSearchSuccess,
            APPLY_VARIABLE_REQUEST: this.onApplyVariableRequest,
            APPLY_VARIABLE_SUCCESS: this.onApplyVariableSuccess,
        });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
        if (IS_STORAGE_AVAILABLE) {
            STORAGE.setItem(STORAGE_PREFIX, JSON.stringify(this.state));
        }
    }

    onApplyVariableRequest = () => {
        this.setState({ applyingVariable: true });
    };

    onApplyVariableSuccess = () => {
        this.setState({ applyingVariable: false });
    };

    /**
     *
     * @param {object} param0
     * @param {import('../../../').SearchResultTable[]} param0.tables
     * @param {string} param0.mapInstanceId
     * @param {number} param0.total
     * @param {import('../../../').SearchDidYouMean[]} param0.didYouMean
     * @param {string[]} param0.suggestions
     * @param {string} param0.searchTerm
     */
    onSearchSuccess = ({
        tables,
        total,
        didYouMean,
        suggestions,
        mapInstanceId,
        searchTerm,
    }) => {
        if (this.props.mapInstanceId !== mapInstanceId) {
            return;
        }

        this.setState({
            isSearching: false,
            results: tables,
            totalHits: total,
            didYouMean,
            suggestions,
            searchTerm,
        });
        this.emit('COUNTER_LOG_REQUEST', [
            {
                event_type: 'search_platform',
                event_value: this.state.query,
            },
            {
                event_type: 'search_regular',
                event_value: this.state.query,
            },
        ]);
    };

    onSearchQueryChange = query => {
        let newTotalHits = this.state.totalHits;

        // if the previous search was empty reset the total hits when the user deletes the search keyword
        if (this.state.totalHits === 0 && query.trim() === '') {
            newTotalHits = -1;
        }
        this.setState({
            query,
            totalHits: newTotalHits,
        });
    };

    onSearch = () => {
        const { query } = this.state;
        if (query.trim() === '') {
            this.clearSearch();
            return false;
        }

        this.setState(
            {
                isSearching: true,
                totalHits: -1,
            },
            () => {
                this.emit('VARIABLE_SEARCH_REQUEST', {
                    mapOnly: true,
                    searchTerm: query,
                    surveyNames: this.state.selectedSurvey
                                 ? [this.state.selectedSurvey]
                                 : this.props.mapsGroupSurveys.map(ms => ms.name),
                    years: this.state.selectedYear
                           ? [this.state.selectedYear]
                           : [],
                    mapInstanceId: this.props.mapInstanceId,
                });
            },
        );

        return false;
    };

    clearSearch = () => {
        this.setState({
            results: [],
            query: '',
            totalHits: -1,
            suggestions: [],
            didYouMean: [],
        });
    };

    onKeyDown = e => {
        const key = e.which || e.keyCode;
        switch (key) {
        case Keys.ESC:
            this.clearSearch();
            break;
        }
    };

    /** @param {number} selectedYear */
    onYearChange = selectedYear => {
        this.setState({ selectedYear }, () => {
            this.onSearch();
        });
    };

    /** @param {string} selectedSurvey */
    onSurveyChange = selectedSurvey => {
        this.setState({ selectedSurvey }, () => {
            this.onSearch();
        });
    };

    /**
     * Replace a term in a query with a "did you mean" term
     * @param {import('../../../').SearchDidYouMean} suggestion
     * @param {number} index
     */
    onDidYouMeanSuggestion = (suggestion, index) => {
        const query =
            this.state.query.substr(0, suggestion.offset) +
            suggestion.options[index] +
            this.state.query.substr(suggestion.offset + suggestion.length, this.state.query.length);
        this.setState({ query, totalHits: -1 }, () => {
            this.onSearch();
        });
    };

    /**
     * Append suggestion term at the end of the query
     * @param {string} suggestion
     */
    onSuggestion = suggestion => {
        const query = `${this.state.query} ${suggestion}`;
        this.setState({ query, totalHits: -1 }, () => {
            this.onSearch();
        });
    };

    render() {
        const { mapInstance, mapsGroupSurveys } = this.props;
        const {
            results,
            query,
            applyingVariable,
            searchTerm,
            suggestions,
            selectedYear,
            selectedSurvey,
            yearsList,
            totalHits,
            isSearching,
            didYouMean,
        } = this.state;

        const dataBrowserSearchClasses = classnames(
            'data-browser-search__content',
            { invisible: applyingVariable },
        );
        const dataBrowserSearchLoaderClasses = classnames({
            hidden: !applyingVariable,
        });

        return (
            <div className="data-browser-search__wrapper">
                <div className={dataBrowserSearchLoaderClasses}>
                    <Loader text="Applying new variable..." />
                </div>
                <div className={dataBrowserSearchClasses}>
                    <DataBrowserBasicSearch
                        query={query}
                        onKey={this.onKeyDown}
                        onChange={this.onSearchQueryChange}
                        onSearch={this.onSearch}
                        onClearSearch={this.clearSearch}
                    />
                    <SearchFilters
                        years={yearsList}
                        selectedYear={selectedYear}
                        onYearChange={this.onYearChange}
                        surveys={mapsGroupSurveys}
                        selectedSurvey={selectedSurvey}
                        onSurveyChange={this.onSurveyChange}
                    />
                    <Suggestions
                        suggestions={suggestions}
                        onClick={this.onSuggestion}
                    />
                    {totalHits === 0 && (
                        <DidYouMean
                            suggestions={didYouMean}
                            onClick={this.onDidYouMeanSuggestion}
                        />
                    )}
                    <Results
                        selectedYear={selectedYear}
                        mapInstance={mapInstance}
                        results={results}
                        isSearching={isSearching}
                        totalHits={totalHits}
                        query={searchTerm}
                    />
                </div>
            </div>
        );
    }
}

export default DataBrowserBySearch;
