import React from 'react';
import { injectIntl } from 'react-intl';
import BusComponent from '../BusComponent';

import AppConfig from '../../appConfig';

import FileDropArea from '../uploadFile/FileDropArea';
import FileInput from '../form/FileInput';
import MyUploads from './MyUploads';
import Panel from '../panel/Panel';
import FileUploadError from '../uploadFile/FileUploadError';

const MAX_FILE_UPLOAD_SIZE_IN_MB = 100;

class DataUploadOptions extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {};
    }

    static componentName() {
        return 'DATA_UPLOAD_OPTIONS';
    }

    onDataUploaded = file => {
        if (file.size > MAX_FILE_UPLOAD_SIZE_IN_MB * 1024 * 1024) {
            this.emit('USER_DATA_ERROR', { type: 'UPLOAD_FILE_ERROR', userMessage: `${this.props.intl.formatMessage({ id: 'dataBrowser.csvFileTooLarge' })} ${MAX_FILE_UPLOAD_SIZE_IN_MB}MB.` });
            return;
        }
        this.emit('PARSE_USER_DATA_UPLOAD', { file });
    }

    onFileSelected = files => {
        if (files && files.length) {
            this.onDataUploaded(files[0]);
        }
    }

    render() {
        const { error, onBack, onClearError, mapInstanceId } = this.props;
        const graphicsStyle = { height: 100 };

        return (<div className="user-data-upload-editor">
            <Panel
                title={this.props.intl.formatMessage({ id: 'dataBrowser.uploadYourPointData' })}
                headerStyle="white"
                onBackClick={onBack}
            >
                { error && <FileUploadError error={error} onErrorOKClick={onClearError} /> }
                <div className="data-upload-options">
                    <div className="data-upload-options__description">
                        {this.props.intl.formatMessage({ id: 'hints.uploadAndGeocodeYourPOIs' })}
                    </div>
                    <div className="data-upload-options__upload_box">
                        <FileDropArea
                            graphics={`${AppConfig.constants.assetsBaseURL}/svg/upload-data.svg`}
                            graphicsStyle={graphicsStyle}
                            onChange={this.onDataUploaded}
                            accept="text/csv,.csv"
                            text={this.props.intl.formatMessage({ id: 'dataBrowser.dropYourCsvFileHere' })}
                            errors={undefined}
                        >
                            <div className="flex-it center justify-center">
                                <div className="user-data-upload-editor__upload_description">
                                    {this.props.intl.formatMessage({ id: 'dataBrowser.orIfYouPrefer' })}
                                </div>
                                <FileInput
                                    accept="text/csv,.csv"
                                    onChange={this.onFileSelected}
                                    label={this.props.intl.formatMessage({ id: 'browse' })}
                                    className="btn-link"
                                />
                            </div>
                        </FileDropArea>
                    </div>
                </div>
                <MyUploads mapInstanceId={mapInstanceId} />
            </Panel>
        </div>);
    }
}

export default injectIntl(DataUploadOptions);
