import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import AppConfig from '../../appConfig';

class AboutVariableTab extends React.Component {
    handleOpenDataDictionaryClick = () => {
        const { metadataSelection } = this.props;

        let metaObject;
        const survey = metadataSelection.survey.name;
        const dataset = metadataSelection.dataset.abbrevation;

        if (metadataSelection.variables.length === 1) {
            metaObject = `var=${metadataSelection.variables[0].name}`;
        } else {
            metaObject = `table=${metadataSelection.table.name}`;
        }

        window.open(`${AppConfig.constants.backends.docs}/data/${survey}/metadata/?ds=${dataset}&${metaObject}`);
    };

    render() {
        const { title, dataDictionaryInfo } = this.props;

        const variables = dataDictionaryInfo.variables.map(v => (
            <li key={v.guid} className="data-info__list-item">
                {v.title}
            </li>));

        return (
            <div>
                <div className="data-info__content column">
                    <div className="data-info__title">
                        {title}
                    </div>
                    <div className="data-info__item">
                        <span className="data-info__item-type">{this.props.intl.formatMessage({ id: 'survey' })} </span>
                        {dataDictionaryInfo.survey.title}
                    </div>
                    <div className="data-info__item">
                        <span className="data-info__item-type">{this.props.intl.formatMessage({ id: 'table' })} </span>
                        {dataDictionaryInfo.table.title}
                    </div>
                    <div className="data-info__item">
                        <span className="data-info__item-type">
                            {`${this.props.intl.formatMessage({ id: 'variable' })}${variables.length > 1 ? 's' : ''}`}
                        </span>
                        <ul>{variables}</ul>
                    </div>
                </div>
                <button className="btn btn-link data-info__link" onClick={this.handleOpenDataDictionaryClick}>
                    {this.props.intl.formatMessage({ id: 'dataBrowser.openDataDictionary' })}
                    <i className="material-icons" style={{ marginLeft: 6 }}>open_in_new</i>
                </button>
            </div>
        );
    }
}

AboutVariableTab.propTypes = {
    dataDictionaryInfo: PropTypes.object.isRequired,
    metadataSelection: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(AboutVariableTab);
