import React from 'react';
import BusComponent from '../../BusComponent';
import ImportSummaryLevelItem from './ImportSummaryLevelItem';

class ImportSummaryLevelPopup extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            selectedSummaryLevel: props.summaryLevels[0],
        };
    }

    onSummaryLevelSelectionChange = summaryLevel => {
        this.setState({
            selectedSummaryLevel: summaryLevel,
        });
    }

    onSelectionDone = () => {
        this.emit('IMPORT_GEO_SELECTION_SUMMARY_LEVEL', {
            summaryLevel: this.state.selectedSummaryLevel,
        });
    }

    render() {
        const summaryLevels = this.props.summaryLevels.map(sl => (<ImportSummaryLevelItem
            key={sl.name}
            summaryLevel={sl}
            onClick={this.onSummaryLevelSelectionChange}
            selected={sl === this.state.selectedSummaryLevel}
        />));
        return (<div className="geo-selection-import-popup flex-one flex-column">
            <div className="geo-selection-import-popup__info">
                We found multiple geography types in file. Please select which geography type to import:
            </div>
            <div className="geo-selection-import-popup__list flex-column vertical-scrollbar">
                {summaryLevels}
            </div>
            <div className="flex-it center flex-end">
                <div className="btn-raised" onClick={this.onSelectionDone}>Import</div>
            </div>
        </div>);
    }
}

export default ImportSummaryLevelPopup;
