/* eslint class-methods-use-this: 0 */

class Label {
    constructor(values) {
        if (values) {
            Object.keys(values).forEach(k => {
                if (this.constructor.prototype.hasOwnProperty(k)) {
                    this[k] = values[k];
                }
            });
        }
    }

    get type() {
        return 'Label';
    }

    set id(id) {
        this._id = id;
    }

    get id() {
        return this._id;
    }

    set rotationAngle(rotationAngle) {
        this._rotationAngle = rotationAngle;
    }

    get rotationAngle() {
        return this._rotationAngle;
    }

    set fillColor(fillColor) {
        this._fillColor = fillColor;
    }

    get fillColor() {
        return this._fillColor;
    }

    get useFill() {
        return this._useFill;
    }

    set useFill(useFill) {
        this._useFill = useFill;
    }

    get textSize() {
        return this._textSize;
    }

    set textSize(textSize) {
        this._textSize = textSize;
    }

    /* Dingo specific */

    get haloWidth() {
        return this._haloWidth;
    }

    set haloWidth(haloWidth) {
        this._haloWidth = haloWidth;
    }

    get haloBlur() {
        return this._haloBlur;
    }

    set haloBlur(haloBlur) {
        this._haloBlur = haloBlur;
    }

    /* ------------- */

    set textColor(textColor) {
        this._textColor = textColor;
    }

    get textColor() {
        return this._textColor;
    }

    get opacity() {
        return this._opacity;
    }

    set opacity(opacity) {
        this._opacity = opacity;
    }

    get title() {
        return this._title;
    }

    set title(title) {
        this._title = title;
    }

    get createdAtZoomLevel() {
        return this._createdAtZoomLevel;
    }

    set createdAtZoomLevel(createdAtZoomLevel) {
        this._createdAtZoomLevel = createdAtZoomLevel;
    }

    get minZoom() {
        return this._minZoom;
    }

    set minZoom(minZoom) {
        this._minZoom = minZoom;
    }

    get maxZoom() {
        return this._maxZoom;
    }

    set maxZoom(maxZoom) {
        this._maxZoom = maxZoom;
    }

    get description() {
        return this._description;
    }

    set description(description) {
        this._description = description;
    }

    get coordinates() {
        return this._coordinates;
    }

    set coordinates(coordinates) {
        this._coordinates = coordinates;
    }

    get legendOrder() {
        return this._legendOrder;
    }

    set legendOrder(legendOrder) {
        this._legendOrder = legendOrder;
    }

    get includedInLegend() {
        return this._includedInLegend;
    }

    set includedInLegend(includedInLegend) {
        this._includedInLegend = includedInLegend;
    }

    equals(that) {
        return (this.id === that.id &&
        this.type === that.type &&
        this.opacity === that.opacity &&
        this.rotationAngle === that.rotationAngle &&
        this.textSize === that.textSize &&
        this.textColor === that.textColor &&
        this.fillColor === that.fillColor &&
        this.haloWidth === that.haloWidth &&
        this.haloBlur === that.haloBlur &&
        this.useFill === that.useFill &&
        this.title === that.title &&
        this.createdAtZoomLevel === that.createdAtZoomLevel &&
        this.minZoom === that.minZoom &&
        this.maxZoom === that.maxZoom &&
        this.includedInLegend === that.includedInLegend &&
        this.legendOrder === that.legendOrder &&
        this.description === that.description &&
        this.coordinates === that.coordinates);
    }

    clone() {
        return new Label({
            id: `${this.id}`,
            textSize: this.textSize,
            rotationAngle: this.rotationAngle,
            textColor: this.textColor,
            fillColor: this.fillColor,
            useFill: this.useFill,
            haloWidth: this.haloWidth,
            haloBlur: this.haloBlur,
            opacity: this.opacity,
            title: this.title,
            createdAtZoomLevel: this.createdAtZoomLevel,
            minZoom: this.minZoom,
            maxZoom: this.maxZoom,
            includedInLegend: this.includedInLegend,
            description: this.description,
            coordinates: this.coordinates,
            legendOrder: this.legendOrder,
        });
    }

    toJSON() {
        return {
            id: `${this.id}`,
            type: this.type,
            textSize: this.textSize,
            rotationAngle: this.rotationAngle,
            textColor: this.textColor,
            fillColor: this.fillColor,
            useFill: this.useFill,
            haloWidth: this.haloWidth,
            haloBlur: this.haloBlur,
            opacity: this.opacity,
            title: this.title,
            createdAtZoomLevel: this.createdAtZoomLevel,
            minZoom: this.minZoom,
            maxZoom: this.maxZoom,
            includedInLegend: this.includedInLegend,
            description: this.description,
            coordinates: this.coordinates,
            legendOrder: this.legendOrder,
        };
    }
}

export default Label;
