// @ts-check
import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import Keys from '../../enums/Key';

/**
 * @typedef Props
 * @property {boolean} initialFocus
 * @property {boolean} preventSelectionOnClick
 * @property {() => void} onClick
 * @property {() => void} onEnter
 * @property {(value: string) => void} onChange
 * @property {(value: HTMLElement) => void} elementRef
 * @property {string} className
 * @property {string} label
 * @property {string} value
 * @property {string} placeholder
 * @property {boolean} multiline
 * @property {boolean} oldStyle
 * @property {boolean} light
 * @property {boolean} readOnly
 * @property {boolean} showClearIcon
 * @property {string} clearIcon
 * @property {string} ariaLabel
 * @property {string} type
 * @property {boolean} hasError
 * @property {number} min
 * @property {number} max
 * @property {number} step
 * @property {number} maxLength
 *
 * @extends {React.Component<Props>}
 */
class TextInput extends React.Component {
    componentDidMount() {
        if (this.props.initialFocus && this.inputRef) {
            this.inputRef.focus();
            setTimeout(() => {
                if (this.inputRef) {
                    this.inputRef.select();
                }
            }, 250);
        }
    }

    onClick = () => {
        if (this.props.onClick) {
            this.props.onClick();
        }
    };

    onEnterClicked = e => {
        const key = e.which || e.keyCode;
        if (key !== undefined && key !== Keys.ENTER) return;
        if (this.props.onEnter) {
            this.props.onEnter();
        }
    };

    onChange = e => {
        const newValue = e.target.value;
        if (this.props.onChange) {
            this.props.onChange(newValue);
        }
    };

    onClearInput = () => {
        if (this.props.onChange) {
            this.props.onChange(undefined);
        }
    };

    get input() {
        return this.props.elementRef;
    }

    render() {
        const textInputClasses = classNames(
            'text-input',
            this.props.className,
            {
                'text-input--light': this.props.light,
                'text-input__error': this.props.hasError,
            },
        );

        const inputFieldClasses = classNames('text-input__input', {
            'text-input__input--valid': this.props.value !== '',
        });

        if (this.props.multiline) {
            return (
                <textarea
                    ref={i => {
                        this.inputRef = i;
                        if (this.props.elementRef) this.props.elementRef(i);
                    }}
                    className={classNames('input', this.props.className)}
                    placeholder={this.props.placeholder || ''}
                    readOnly={this.props.readOnly}
                    value={this.props.value}
                    onChange={this.onChange}
                    onKeyDown={this.onEnterClicked}
                    onClick={this.onClick}
                    aria-label={this.props.ariaLabel}
                    maxLength={this.props.maxLength}
                />
            );
        }
        if (this.props.showClearIcon) {
            return (
                <div className={classNames('input-box', this.props.className)}>
                    <input
                        ref={i => {
                            this.inputRef = i;
                            if (this.props.elementRef) this.props.elementRef(i);
                        }}
                        className="input-box__input"
                        type={this.props.type}
                        readOnly={this.props.readOnly}
                        value={this.props.value}
                        placeholder={this.props.placeholder}
                        onChange={this.onChange}
                        onKeyDown={this.onEnterClicked}
                        onClick={this.onClick}
                        min={this.props.min}
                        max={this.props.max}
                        step={this.props.step}
                        maxLength={this.props.maxLength}
                    />
                    {this.props.value && (
                        <button
                            className="material-icons input-box__clear-icon"
                            onClick={this.onClearInput}
                        >
                            {this.props.clearIcon}
                        </button>
                    )}
                </div>
            );
        }
        if (this.props.oldStyle) {
            return (
                <input
                    ref={i => {
                        this.inputRef = i;
                        if (this.props.elementRef) this.props.elementRef(i);
                    }}
                    className={classNames(
                        'text-input-old',
                        this.props.className,
                    )}
                    type={this.props.type}
                    placeholder={this.props.placeholder}
                    readOnly={this.props.readOnly}
                    value={this.props.value}
                    onChange={this.onChange}
                    onKeyDown={this.onEnterClicked}
                    onClick={this.onClick}
                    min={this.props.min}
                    max={this.props.max}
                    step={this.props.step}
                    maxLength={this.props.maxLength}
                />
            );
        }
        return (
            <div className={textInputClasses}>
                <input
                    ref={i => {
                        this.inputRef = i;
                        if (this.props.elementRef) this.props.elementRef(i);
                    }}
                    className={inputFieldClasses}
                    type={this.props.type}
                    readOnly={this.props.readOnly}
                    value={this.props.value}
                    onChange={this.onChange}
                    onKeyDown={this.onEnterClicked}
                    onClick={this.onClick}
                    placeholder={this.props.placeholder}
                    min={this.props.min}
                    max={this.props.max}
                    step={this.props.step}
                    maxLength={this.props.maxLength}
                />
                <span className="text-input__bar" />
                {this.props.label && (
                    <label htmlFor="input" className="text-input__label">
                        {this.props.label}
                    </label>
                )}
            </div>
        );
    }
}

TextInput.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.any,
    placeholder: PropTypes.string,
    multiline: PropTypes.bool,
    initialFocus: PropTypes.bool,
    oldStyle: PropTypes.bool,
    light: PropTypes.bool,
    readOnly: PropTypes.bool,
    showClearIcon: PropTypes.bool,
    clearIcon: PropTypes.string,
    elementRef: PropTypes.func,
    onClick: PropTypes.func,
    onEnter: PropTypes.func,
    onChange: PropTypes.func,
    ariaLabel: PropTypes.string,
    preventSelectionOnClick: PropTypes.bool,
    type: PropTypes.string,
    hasError: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    maxLength: PropTypes.number,
};

TextInput.defaultProps = {
    className: undefined,
    label: undefined,
    value: '',
    placeholder: '',
    multiline: false,
    initialFocus: false,
    oldStyle: false,
    light: false,
    readOnly: false,
    showClearIcon: false,
    clearIcon: 'clear',
    elementRef: undefined,
    onClick: undefined,
    onEnter: undefined,
    onChange: undefined,
    ariaLabel: undefined,
    preventSelectionOnClick: true,
    type: 'text',
    hasError: false,
    min: undefined,
    max: undefined,
    step: undefined,
    maxLength: undefined,
};

export default TextInput;
