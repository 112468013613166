import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import AppConfig from '../../appConfig';

import TextInput from '../form/TextInput';
import BusComponent from '../BusComponent';
import SimpleDropdown from '../dropdown/SimpleDropdown';

import { copyTextFromElementTargetToClipboard } from '../../helpers/Util';
import CitationFormats from '../../enums/CitationFormats';

class CitationTab extends BusComponent {
    constructor(props, context) {
        super(props, context);

        this.state = {
            selectedCitationId: CitationFormats[0].id,
            currentDate: new Date(),
        };
    }

    onCopyToClipboardClicked = () => {
        this.setState({
            copiedToClipboard: true,
        });
        copyTextFromElementTargetToClipboard(this.citation);
        this.timeout = setTimeout(() => {
            this.setState({
                copiedToClipboard: false,
            });
        }, 2000);
        this.emit('SET_ACCESSIBLE_ALERT_TEXT', 'Link is copied to clipboard');
    };

    selectCitationStyle = selectedCitationId => {
        this.setState({ selectedCitationId });
    };

    render() {
        const { selectedCitationId, currentDate } = this.state;
        const { dataDictionaryInfo, metadataSelection, intl } = this.props;

        const selectedCitation = CitationFormats.find(style => style.id === selectedCitationId);
        const formattedDate = new Intl.DateTimeFormat('en-AU', selectedCitation.dateOptions).format(currentDate);

        const variables = dataDictionaryInfo.variables.map(v => {
            const values =
                {
                    title: v.title,
                    date: formattedDate,
                    source: metadataSelection.survey.publisher,
                    surveyYear: metadataSelection.survey.year,
                    surveyName: metadataSelection.survey.displayName,
                    link: `${AppConfig.constants.links.shareRootURL}/${this.context.viewCode}/view`,
                };

            // apply the template
            return selectedCitation.template(values);
        });

        return (
            <div className="data-info__citation column">
                <SimpleDropdown
                    title={intl.formatMessage({ id: 'dataBrowser.citationFormat' })}
                    className="flex-one"
                    onItemClick={this.selectCitationStyle}
                    items={CitationFormats}
                    selectedItem={selectedCitation}
                />
                <TextInput
                    className="data-info__citation-text"
                    multiline
                    oldStyle
                    elementRef={citation => {
                        this.citation = citation;
                    }}
                    value={variables.join('\n')}
                    readOnly
                />
                <button className="btn btn-link data-info__citation-button" onClick={this.onCopyToClipboardClicked}>
                    {intl.formatMessage({ id: 'dataBrowser.copyCitation' })}
                </button>
                <div className="flex-it column">
                    <span className="data-info__disclaimer">{intl.formatMessage({ id: 'dataBrowser.disclaimer' })}:</span>
                    <span className="data-info__disclaimer-text">
                        {intl.formatMessage({ id: 'dataBrowser.citationDisclaimer' })}
                    </span>
                </div>
            </div>
        );
    }
}

CitationTab.propTypes = {
    dataDictionaryInfo: PropTypes.object.isRequired,
    metadataSelection: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(CitationTab);
