'use strict';var FeatureIndex = require('../data/feature_index');
var CollisionBoxArray = require('../symbol/collision_box');
var ref = require('../symbol/symbol_layout');
var performSymbolLayout = ref.performSymbolLayout;
var DictionaryCoder = require('../util/dictionary_coder');
var SymbolBucket = require('../data/bucket/symbol_bucket');
var util = require('../util/util');
var ref$1 = require('../render/image_atlas');
var makeImageAtlas = ref$1.makeImageAtlas;
var ref$2 = require('../render/glyph_atlas');
var makeGlyphAtlas = ref$2.makeGlyphAtlas;
var dragonflySource = require('./dragonfly_source');
var WorkerTile = function WorkerTile(params) {
    this.coord = params.coord;
    this.uid = params.uid;
    this.zoom = params.zoom;
    this.pixelRatio = params.pixelRatio;
    this.tileSize = params.tileSize;
    this.source = params.source;
    this.selectionMode = params.selectionMode;
    this.overscaling = params.overscaling;
    this.showCollisionBoxes = params.showCollisionBoxes;
    this.sources = params.sources;
    this.url = params.request ? params.request.url : undefined;
    this.cancel = false;
    this.chunkedLayerIdPattern = /_c\d+$/;
};
WorkerTile.prototype.parse = function parse(data, layerIndex, actor, callback, mapId, shouldStop, endProcessing) {
    var this$1 = this;
    this.status = 'parsing';
    this.data = data;
    var result = dragonflySource.calculateComputedColumns(this.sources, data.vectorTile);
    if (result) {
        data.vectorTile.layers = result.vectorTile.layers;
        data.rawData = result.pbf.buffer;
    }
    if (!data.rawData) {
        var vectorTilePbf = dragonflySource.fromVectorTile(data.vectorTile, []);
        data.rawData = vectorTilePbf.buffer;
    }
    this.collisionBoxArray = new CollisionBoxArray();
    var sourceLayerCoder = new DictionaryCoder(Object.keys(data.vectorTile.layers).sort());
    var featureIndex = new FeatureIndex(this.coord, this.overscaling);
    featureIndex.bucketLayerIDs = [];
    var buckets = {};
    var bucketIndex = 0;
    var options = {
        featureIndex: featureIndex,
        iconDependencies: {},
        glyphDependencies: {}
    };
    var layerFamilies = layerIndex.familiesBySource[this.source];
    if (!layerFamilies) {
        return;
    }
    var familyList = Object.keys(layerFamilies);
    var maxReadSize = 10000;
    var sourceLayerId, sourceLayer, sourceLayerIndex, sourceLayerChunkIdIndex, familyBuckets, i;
    var layerChunkIdsBySourceLayer = Object.keys(data.vectorTile.layers).sort().reduce(function (group, chunkId) {
        var patternMatchInfo = chunkId.match(this$1.chunkedLayerIdPattern);
        var groupingValue;
        if (patternMatchInfo) {
            groupingValue = chunkId.substring(0, patternMatchInfo.index);
        } else {
            groupingValue = chunkId;
        }
        group[groupingValue] = group[groupingValue] || [];
        group[groupingValue].push(chunkId);
        return group;
    }, {});
    var familyIndex = 0;
    var lastGeometry = 0;
    nextFamily.bind(this)();
    function nextFamily() {
        var this$1 = this;
        if (familyIndex >= familyList.length) {
            makeSymbolBuckets.bind(this)();
            return;
        }
        sourceLayerId = familyList[familyIndex];
        sourceLayerChunkIdIndex = 0;
        var sourceLayerChunkId = (layerChunkIdsBySourceLayer[sourceLayerId] || [])[sourceLayerChunkIdIndex];
        i = 0;
        sourceLayer = data.vectorTile.layers[sourceLayerChunkId];
        if (!sourceLayer) {
            familyIndex++;
            nextFamily.bind(this)();
            return;
        }
        if (sourceLayer.version === 1) {
            util.warnOnce('Vector tile source "' + this.source + '" layer "' + sourceLayerId + '" ' + 'does not use vector tile spec v2 and therefore may have some rendering errors.');
        }
        sourceLayerIndex = sourceLayerCoder.encode(sourceLayerChunkId);
        familyBuckets = {};
        for (var i$1 = 0, list = layerFamilies[sourceLayerId]; i$1 < list.length; i$1 += 1) {
            var family = list[i$1];
            var layer = family[0];
            if (layer.minzoom && this$1.zoom < Math.floor(layer.minzoom)) {
                continue;
            }
            if (layer.minzoom && this$1.zoom < layer.minzoom) {
                continue;
            }
            if (layer.maxzoom && this$1.zoom >= layer.maxzoom) {
                continue;
            }
            if (layer.layout && layer.layout.visibility === 'none') {
                continue;
            }
            for (var i$2 = 0, list$1 = family; i$2 < list$1.length; i$2 += 1) {
                var layer$1 = list$1[i$2];
                layer$1.recalculate(this$1.zoom);
            }
            familyBuckets[layer.id] = buckets[layer.id] = layer.createBucket({
                index: bucketIndex,
                layers: family,
                zoom: this$1.zoom,
                mapId: mapId,
                selectionMode: this$1.selectionMode,
                coord: this$1.coord,
                pixelRatio: this$1.pixelRatio,
                overscaling: this$1.overscaling,
                collisionBoxArray: this$1.collisionBoxArray
            });
            featureIndex.bucketLayerIDs[bucketIndex] = family.map(function (l) {
                return l.id;
            });
            bucketIndex++;
        }
        processChunk.bind(this)();
    }
    function processChunk() {
        if (shouldStop && shouldStop()) {
            return;
        }
        var features = [];
        for (; i < sourceLayer.length; i++) {
            var feature = sourceLayer.feature(i);
            if (i === 0) {
                lastGeometry = feature._geometry;
            } else if (feature._geometry - lastGeometry > maxReadSize) {
                lastGeometry = feature._geometry;
                break;
            }
            features.push({
                feature: feature,
                index: i,
                sourceLayerIndex: sourceLayerIndex
            });
        }
        for (var id in familyBuckets) {
            buckets[id].populate(features, options);
        }
        if (i < sourceLayer.length) {
            setTimeout(processChunk.bind(this), 0);
        } else {
            sourceLayerChunkIdIndex++;
            var chunkId = (layerChunkIdsBySourceLayer[sourceLayerId] || [])[sourceLayerChunkIdIndex];
            sourceLayer = data.vectorTile.layers[chunkId];
            if (sourceLayer) {
                sourceLayerIndex = sourceLayerCoder.encode(chunkId);
                i = 0;
                setTimeout(processChunk.bind(this), 0);
            } else {
                if (++familyIndex < familyList.length) {
                    setTimeout(nextFamily.bind(this), 0);
                } else {
                    makeSymbolBuckets.bind(this)();
                }
            }
        }
    }
    function makeSymbolBuckets() {
        var this$1 = this;
        function maybePrepare() {
            var this$1 = this;
            if (error) {
                return callback(error);
            } else if (glyphMap && imageMap) {
                var glyphAtlas = makeGlyphAtlas(glyphMap);
                var imageAtlas = makeImageAtlas(imageMap);
                for (var key in buckets) {
                    var bucket = buckets[key];
                    if (bucket instanceof SymbolBucket) {
                        recalculateLayers(bucket, this$1.zoom);
                        performSymbolLayout(bucket, glyphMap, glyphAtlas.positions, imageMap, imageAtlas.positions, this$1.showCollisionBoxes);
                    }
                }
                this.status = 'done';
                var transferables = [
                    glyphAtlas.image.data.buffer,
                    imageAtlas.image.data.buffer
                ];
                var metadata = { buckets: {} };
                var loop = function (bucketId) {
                    if (buckets[bucketId].layers.length > 1) {
                        buckets[bucketId].layers.forEach(function (l) {
                            return metadata.buckets[l.id] = buckets[bucketId].metadata;
                        });
                    } else {
                        metadata.buckets[bucketId] = buckets[bucketId].metadata;
                    }
                };
                for (var bucketId in buckets)
                    loop(bucketId);
                callback(null, {
                    metadata: metadata,
                    buckets: serializeBuckets(util.values(buckets), transferables),
                    featureIndex: featureIndex.serialize(transferables),
                    collisionBoxArray: this.collisionBoxArray.serialize(),
                    glyphAtlasImage: glyphAtlas.image,
                    iconAtlasImage: imageAtlas.image
                }, transferables);
            }
        }
        var error;
        var glyphMap;
        var imageMap;
        var stacks = util.mapObject(options.glyphDependencies, function (glyphs) {
            return Object.keys(glyphs).map(Number);
        });
        if (Object.keys(stacks).length) {
            actor.send('getGlyphs', {
                uid: this.uid,
                stacks: stacks
            }, function (err, result) {
                if (!error) {
                    error = err;
                    glyphMap = result;
                    maybePrepare.call(this$1);
                }
            });
        } else {
            glyphMap = {};
        }
        var icons = Object.keys(options.iconDependencies);
        if (icons.length) {
            actor.send('getImages', { icons: icons }, function (err, result) {
                if (!error) {
                    error = err;
                    imageMap = result;
                    maybePrepare.call(this$1);
                }
            });
        } else {
            imageMap = {};
        }
        maybePrepare.call(this);
        if (endProcessing !== undefined) {
            endProcessing();
        }
    }
};
function recalculateLayers(bucket, zoom) {
    for (var i = 0, list = bucket.layers; i < list.length; i += 1) {
        var layer = list[i];
        layer.recalculate(zoom);
    }
}
function serializeBuckets(buckets, transferables) {
    return buckets.filter(function (b) {
        return !b.isEmpty();
    }).map(function (b) {
        return b.serialize(transferables);
    });
}
module.exports = WorkerTile;