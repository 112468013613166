module.exports={
  "name": "map",
  "version": "2.423.0",
  "private": true,
  "repository": {
    "type": "git",
    "url": "https://github.com/SocialExplorer/selfstorage"
  },
  "devDependencies": {
    "gulp": "3.9.1",
    "prettier": "3.2.5"
  },
  "dependencies": {
    "@sentry/browser": "5.10.2",
    "@turf/turf": "5.1.6",
    "babel-eslint": "7.2.3",
    "babel-plugin-transform-class-properties": "6.24.1",
    "babel-polyfill": "6.23.0",
    "babel-preset-env": "1.4.0",
    "babel-preset-es2015": "6.3.13",
    "babel-preset-react": "6.24.1",
    "babel-preset-stage-2": "6.24.1",
    "babelify": "7.3.0",
    "canvg-browser": "1.0.0",
    "classnames": "2.2.5",
    "connect-modrewrite": "0.8.1",
    "cors": "2.8.5",
    "csscolorparser": "1.0.3",
    "detect-browser": "2.2.0",
    "dragonfly-draw-v3": "git+ssh://git@github.com/SocialExplorer/dragonfly-draw.git#b6f7415d8ed3c317e71353e358d6c07a7fa7dbee",
    "dragonfly-v3": "git+ssh://git@github.com/SocialExplorer/dragonfly.git#5df4a64647a882c3b3071df3d5c2d38808ce901d",
    "eventemitter3": "1.1.1",
    "fuse.js": "3.2.0",
    "gulp-autoprefixer": "2.3.1",
    "gulp-babel": "6.1.2",
    "gulp-clean-css": "2.0.3",
    "gulp-concat": "2.6.0",
    "gulp-concat-css": "2.2.0",
    "gulp-connect": "2.2.0",
    "gulp-eslint": "3.0.1",
    "gulp-header": "1.7.1",
    "gulp-inject": "1.3.1",
    "gulp-less": "3.0.3",
    "gulp-livereload": "3.8.1",
    "gulp-rename": "1.2.2",
    "gulp-replace-task": "0.11.0",
    "gulp-sequence": "0.3.2",
    "gulp-sourcemaps": "2.6.0",
    "gulp-uglify": "2.1.2",
    "less-plugin-clean-css": "1.5.1",
    "material-ui": "0.18.0",
    "merge-stream": "0.1.8",
    "mergeify": "0.0.5",
    "mixpanel-browser": "^2.51.0",
    "mobile-detect": "1.3.6",
    "mold-source-map": "0.4.0",
    "moment": "2.13.0",
    "node-uuid": "1.4.3",
    "papaparse": "4.1.3",
    "rc-slider": "8.7.1",
    "rc-switch": "1.6.0",
    "rc-tooltip": "3.4.3",
    "rc-trigger": "1.11.2",
    "react": "15.5.4",
    "react-dom": "15.5.4",
    "react-intl": "2.8.0",
    "react-router": "3.0.5",
    "react-tap-event-plugin": "2.0.1",
    "rimraf": "2.4.1",
    "sortablejs": "1.10.2",
    "superagent": "1.5.0",
    "tokml": "0.4.0",
    "vinyl-buffer": "1.0.0",
    "vinyl-source-stream": "1.1.0",
    "watchify": "3.2.3",
    "webworkify": "1.4.0",
    "wellknown": "0.5.0",
    "xlsx-js-style": "^1.2.0"
  },
  "scripts": {
    "test": "mocha --compilers js:babel-core/register"
  }
}
