import React from 'react';
import PropTypes from 'prop-types';
import PopupContentColumn from './PopupContentColumn';
import SimpleDropdown from '../../dropdown/SimpleDropdown';

const PopupOptions = props => {
    const { userDataLayer } = props;
    let popupOtherColumns;

    const columnOptions = props.labelColumns.map(c => ({ id: c, text: c }));
    columnOptions.unshift({ id: '-1', text: 'None' });
    let selectedItem = { id: columnOptions[0].id, text: columnOptions[0].text };

    const popupColumnOptions = props.labelColumns
        .filter(
            c =>
                !userDataLayer.popupOtherColumns.length ||
                !userDataLayer.popupOtherColumns.find(c1 => c1 === c),
        )
        .map(c => ({ id: c, text: c }));

    if (userDataLayer.popupTitleColumn) {
        selectedItem = columnOptions.find(c => c.id === userDataLayer.popupTitleColumn);
    }

    if (userDataLayer.popupOtherColumns && userDataLayer.popupOtherColumns.length) {
        popupOtherColumns = userDataLayer.popupOtherColumns.map(c => (
            <PopupContentColumn key={c} c={c} onRemove={props.onRemovePopupContentColumn} />
        ));
    }

    return (
        <div>
            <div className="point-layer-editor__section point-layer-editor__section--without-border">
                <div className="point-layer-editor__column">Popup title</div>
                <div className="point-layer-editor__column">
                    <SimpleDropdown
                        className="flex-one"
                        onItemClick={props.onPopupTitleColumnChange}
                        items={columnOptions}
                        selectedItem={selectedItem}
                    />
                </div>
            </div>
            <div className="point-layer-editor__section">
                <div className="point-layer-editor__column point-layer-editor__column--aligned">
                    Popup content
                </div>
                <div className="point-layer-editor__column">
                    {popupOtherColumns}
                    <SimpleDropdown
                        className="flex-one"
                        onItemClick={props.onAddPopupContentColumnChange}
                        items={popupColumnOptions}
                        selectedItem={{ id: '-1', text: 'Add column' }}
                    />
                </div>
            </div>
        </div>
    );
};

PopupOptions.propTypes = {
    userDataLayer: PropTypes.object.isRequired,
    onRemovePopupContentColumn: PropTypes.func.isRequired,
    onPopupTitleColumnChange: PropTypes.func.isRequired,
    onAddPopupContentColumnChange: PropTypes.func.isRequired,
    labelColumns: PropTypes.array.isRequired,
};

PopupOptions.defaultProps = {};

export default PopupOptions;
