import React from 'react';
import BusComponent from '../../BusComponent';
import PropTypes from 'prop-types';

import LayerLibraryGroupCard from './LayerLibraryGroupCard';

class LayerLibraryDataLayers extends BusComponent {
    constructor(props, context) {
        super(props, context);

        this.bindGluBusEvents({
            MAP_LIBRARY_GROUP_DETAILS_REQUEST: this.onLibraryGroupDetailsRequest,
            MAP_LIBRARY_GROUP_REMOVAL_REQUEST: this.onLibraryGroupRemovalRequest,
        });
    }

    componentDidMount() {
        this.bus.once('LIBRARY_DATA', this.onLayerLibraryDataRetrieved);
        this.emit('LIBRARY_DATA_REQUEST', { source: this });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    onLayerLibraryDataRetrieved = (layerLibraryData, target) => {
        if (target === this) {
            this.setState({
                libraryGroups: layerLibraryData.groups,
            });
        }
    }

    onLibraryGroupDetailsRequest = payload => {
        const layerGroup = this.state.libraryGroups.find(libraryGroup => libraryGroup.id === payload.id);
        this.emit('SHOW_LAYER_LIBRARY_GROUP_DETAILS_POPUP_REQUEST', layerGroup.metadata);
    }

    onLibraryGroupRemovalRequest = payload => {
        this.emit('REMOVE_LAYER_LIBRARY_GROUP', {
            id: payload.id,
            mapInstanceId: this.props.mapInstance.id,
        });
    }

    onToggleLibraryGroupVisibility = (id, visible) => {
        this.emit('UPDATE_LAYER_LIBRARY_GROUP_VISIBILITY', {
            id,
            visible,
            mapInstanceId: this.props.mapInstance.id,
        });
    }

    onToggleLibraryLayerVisibility = (id, groupId, visible) => {
        this.emit('UPDATE_LAYER_LIBRARY_LAYER_VISIBILITY', {
            id,
            groupId,
            visible,
            mapInstanceId: this.props.mapInstance.id,
        });
    }

    render() {
        const libraryLayers = this.props.mapInstance.libraryDataLayers.map(libraryGroup => {
            const { id, title, layers } = libraryGroup;

            return (<LayerLibraryGroupCard
                id={id}
                key={id}
                title={title}
                layers={layers}
                onToggleGroupVisibility={this.onToggleLibraryGroupVisibility}
                onToggleLayerVisibility={this.onToggleLibraryLayerVisibility}
                onRemoveLibraryGroup={this.onRemoveLibraryGroup}
            />);
        });

        return (<div className="map-layers__section">
            <div className="map-layers__section-title">Additional layers</div>
            {libraryLayers}
        </div>);
    }
}

LayerLibraryDataLayers.propTypes = {
    mapInstance: PropTypes.object.isRequired,
};

export default LayerLibraryDataLayers;
