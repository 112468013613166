// @ts-check
import React from 'react';
import { injectIntl } from 'react-intl';

import BusComponent from '../BusComponent';

/**
 * @typedef Props
 * @property {string} mapInstanceId
 * @property {import('react-intl').intlShape} intl
 *
 * @extends BusComponent<Props>
 */
class ClearGeoSelection extends BusComponent {
    onClick = () => {
        this.emit('MAP_CLEAR_SELECTED_REPORT_FEATURES', {
            mapInstanceId: this.props.mapInstanceId,
        });
    };

    render() {
        return (
            <button
                className="btn-flat margin-right-10"
                key="reset-btn"
                onClick={this.onClick}
            >
                {this.props.intl.formatMessage({ id: 'reset' })}
            </button>
        );
    }
}

export default injectIntl(ClearGeoSelection);
