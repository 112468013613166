// @ts-check
/**
 * @param {import('../types').AspNetWebService.CWsTable} table
 * @returns {import('../types').Table}
 */
export const formatTable = table => {
    /** @type {import('../types').VariableByGuid} */
    const variablesByGuid = {};
    /** @type {import('../types').Table} */
    const output = {
        datasetAbbreviation: table.DatasetAbbreviation,
        datasetName: table.DatasetName,
        displayName: table.DisplayName,
        // docLinks: table.DocLinks,
        dollarYear: table.DollarYear,
        guid: table.GUID,
        name: table.Name,
        // notes: table.Notes,
        outputColumns: table.OutputColumns.OutputColumns.map(column => ({
            tableGuid: column.TableGuid,
            outputPercent: column.OutputPercent,
            caption: column.Caption,
            colSpan: column.OutputPercent ? 2 : 1,
        })),
        // sideBySideTitle: table.SideBySideTitle,
        surveyCode: table.SurveyName,
        title: table.Title,
        // titleShort: table.TitleShort,
        // universe: table.Universe,
        originalTitle: table.OriginalTitle,
        variableCount: table.VariableCount,
        colSpan: 0,
        variables: table.Variables.reduce((acc, variable) => {
            acc[variable.GUID] = {
                name: variable.Name,
                guid: variable.GUID,
                label: variable.Label,
                originalLabel: variable.OriginalLabel,
                labelNoBrakes: variable.LabelNoBrakes,
                qLabel: variable.QLabel,
                tableGuid: variable.TableGuid,
                notes: variable.Notes,
                indent: variable.Indent,
                aggregationMethod: variable.AggregationMethod,
                parentVariableGuid: variable.ParentVariableGuid,
                percentBaseUniverseVarIndex:
                    variable.PercentBaseUniverseVarIndex,
                docLinks: variable.DocLinks,
                formatting: variable.Formatting,
                dollarYear: table.DollarYear,
                clientFunction: variable.Jsf || '',
            };
            return acc;
        }, variablesByGuid),
    };
    output.colSpan = Math.max(
        2,
        output.outputColumns.reduce((acc, column) => acc + column.colSpan, 0),
    );
    return output;
};

/**
 * @param {import('../types').AspNetWebService.CWsSystemReport} systemReport
 * @returns {import('../types').PremadeReport}
 */
export const formatSystemReport = systemReport => ({
    id: systemReport.Id,
    name: systemReport.Name,
    tableGuids: systemReport.TableGuids,
});
