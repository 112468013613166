//      

const StyleLayer = require('../style_layer');
const FillBucket = require('../../data/bucket/fill_bucket');
const {translateDistance, translate} = require('../query_utils');
const DragonflyIntersectionTests = require('../../data/dragonfly_intersection_tests'); //!

                                                                           
                                                        
                                                
                                          

class FillStyleLayer extends StyleLayer {

    getPaintValue(name        , globals                  , feature          ) {
        if (name === 'fill-outline-color') {
            // Special-case handling of undefined fill-outline-color values
            if (this.getPaintProperty('fill-outline-color') === undefined) {
                return super.getPaintValue('fill-color', globals, feature);
            }

            // Handle transitions from fill-outline-color: undefined
            let transition = this._paintTransitions['fill-outline-color'];
            while (transition) {
                const declaredValue = (
                    transition &&
                    transition.declaration &&
                    transition.declaration.value
                );

                if (!declaredValue) {
                    return super.getPaintValue('fill-color', globals, feature);
                }

                transition = transition.oldTransition;
            }
        }

        return super.getPaintValue(name, globals, feature);
    }

    getPaintInterpolationFactor(name        , ...args   ) {
        if (name === 'fill-outline-color' && this.getPaintProperty('fill-outline-color') === undefined) {
            return super.getPaintInterpolationFactor('fill-color', ...args);
        } else {
            return super.getPaintInterpolationFactor(name, ...args);
        }
    }

    isPaintValueFeatureConstant(name        ) {
        if (name === 'fill-outline-color' && this.getPaintProperty('fill-outline-color') === undefined) {
            return super.isPaintValueFeatureConstant('fill-color');
        } else {
            return super.isPaintValueFeatureConstant(name);
        }
    }

    isPaintValueZoomConstant(name        ) {
        if (name === 'fill-outline-color' && this.getPaintProperty('fill-outline-color') === undefined) {
            return super.isPaintValueZoomConstant('fill-color');
        } else {
            return super.isPaintValueZoomConstant(name);
        }
    }

    createBucket(parameters                  ) {
        return new FillBucket(parameters);
    }

    isOpacityZero(zoom        ) {
        return this.isPaintValueFeatureConstant('fill-opacity') &&
            this.getPaintValue('fill-opacity', { zoom: zoom }) === 0;
    }

    queryRadius()         {
        return translateDistance(this.paint['fill-translate']);
    }

    queryIntersectsFeature(queryGeometry                     ,
                           feature                   ,
                           geometry                     ,
                           zoom        ,
                           bearing        ,
                           pixelsToTileUnits        ,
                           tile      , //!
                           radius         , //!
                           inclusionRule         )          { //!

        const typePrefix = this.type; //!
        const translatedPolygon = translate(queryGeometry, //!
            this.getPaintValue(`${typePrefix}-translate`, {zoom}, feature), //!
            this.getPaintValue(`${typePrefix}-translate-anchor`, {zoom}, feature), //!
            bearing, pixelsToTileUnits); //!
        return DragonflyIntersectionTests.advancedIntersectionTest(inclusionRule, radius, translatedPolygon, geometry, feature, tile, this);
    }
}

module.exports = FillStyleLayer;
