import FilterTypes from '../../enums/FilterTypes';
import NumberFormat from '../../enums/NumberFormat';
import format from '../../helpers/NumberFormatter';

class DataFilterRule {
    constructor(values) {
        if (values) {
            Object.keys(values).forEach(k => {
                if (this.constructor.prototype.hasOwnProperty(k)) {
                    this[k] = values[k];
                }
            });
        }
        if (!this.baseVariables) this.baseVariables = [];
    }

    get status() {
        return this._status;
    }

    set status(status) {
        this._status = status;
    }

    get filterId() {
        return this._filterId;
    }

    set filterId(filterId) {
        this._filterId = filterId;
    }

    get value() {
        return this._value;
    }

    set value(value) {
        this._value = value;
    }

    get field() {
        return this._field;
    }

    set field(field) {
        this._field = field;
    }

    get type() {
        return this._type;
    }

    set type(type) {
        this._type = type;
    }

    get baseVariables() {
        return this._baseVariables;
    }

    set baseVariables(baseVariables) {
        this._baseVariables = baseVariables;
    }

    // check if filter rule is fulfilled
    isValid(featureValues) {
        const rawValue = featureValues[this.field.fieldName];
        const { formatting } = this.field;
        let filterFeatureValue = rawValue;
        // If a string that cannot be converted to a number gets passed, do not do numeric calculations
        if (!isNaN(Number(rawValue))) {
            const formatDecimalPlaces = parseInt(formatting.substring(formatting.length - 8), 10) || 0;
            filterFeatureValue = Math.round(rawValue * (10 ** formatDecimalPlaces)) / (10 ** formatDecimalPlaces);
        }
        const filterTypeId = Object.keys(FilterTypes)
            .find(typeKey => FilterTypes[typeKey].type === this.type);

        // if percent is selected
        if (this.isPercentAvailable()) {
            const baseVariableValue = featureValues[this.field.baseFieldName];
            const featureValuePercent = filterFeatureValue / baseVariableValue;
            const filterValuePercent = numeral(this.value).value();

            return FilterTypes[filterTypeId].evaluateFunction(featureValuePercent, filterValuePercent);
        }
        const value = isNaN(Number(this.value)) ? this.value : Number(this.value);
        return FilterTypes[filterTypeId].evaluateFunction(filterFeatureValue, value);
    }

    // Calculate the percentage value for the current feature
    getPercentValueFormatted(featureValues) {
        if (!this.isPercentAvailable()) return undefined;

        const filterFeatureValue = featureValues[this.field.fieldName];
        const filterFeatureBaseValue = featureValues[this.field.baseFieldName];

        if (filterFeatureValue && filterFeatureBaseValue) {
            return format({
                number: (filterFeatureValue / filterFeatureBaseValue) * 100,
                numberFormat: NumberFormat.FORMAT_PERCENT_2_DECIMAL,
            });
        }
        return 'n/a';
    }

    isPercentAvailable() {
        return /^\d+(\.\d+)?%$/.test(this.value) && this.baseVariables.length > 0;
    }

    equals(that) {
        return this.value === that.value
            && this.field === that.field
            && this.type === that.type
            && this.status === that.status
            && this.filterId === that.filterId
            && (this.baseVariables.length === that.baseVariables.length && !this.baseVariables.find(v1 => !that.baseVariables.find(v2 => v1.equals(v2))));
    }

    clone() {
        return new DataFilterRule({
            value: this.value,
            field: this.field.clone(),
            type: this.type,
            status: this.status,
            filterId: this.filterId,
            baseVariables: this.baseVariables.map(v => v.clone()),
        });
    }

    toJSON() {
        return {
            value: this.value,
            field: this.field,
            type: this.type,
            status: this.status,
            filterId: this.filterId,
            baseVariables: this.baseVariables.map(v => v.toJSON()),
        };
    }

    toString(decomposed = false) {
        const typeId = Object.keys(FilterTypes).filter(key => FilterTypes[key].type === this.type)[0];

        let percentageText = '';
        let formattedValue = format({ number: this.value, numberFormat: this.field.formatting });
        let selectedBaseVariable;
        if (this.isPercentAvailable()) {
            selectedBaseVariable = this.baseVariables.find(b => b.uuid === this.field.baseFieldName);
            percentageText = `of ${selectedBaseVariable.label}`;
            formattedValue = this.value;
        }
        if (decomposed) {
            return {
                qLabel: this.field.qLabel,
                textId: FilterTypes[typeId].text,
                formattedValue,
                percentageLabel: this.isPercentAvailable() ? selectedBaseVariable.label : null,
            };
        }
        return `${this.field.qLabel} ${FilterTypes[typeId].text} ${formattedValue} ${percentageText}`;
    }

    toStringDecomposed() {
        return this.toString(true);
    }
}

export default DataFilterRule;
