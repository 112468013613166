// @ts-check
import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import BusComponent from '../../BusComponent';
import ApplicationMode from '../../../enums/ApplicationMode';
import Orientation from '../../../enums/Orientation';
import VariableType from '../../../enums/VariableType';

import AutoAdjustMenu from './AutoAdjustMenu';
import AutoAdjustInfo from './AutoAdjustInfo';
import ThreeDotsLoader from '../../ThreeDotsLoader';
import { getMetadataObjectsFromVariableSelectionItem } from '../../../helpers/Util';

/**
 * @typedef Props
 * @property {import('react-intl').intlShape} intl
 * @property {import('../../../objects/MapInstance').default} mapInstance
 * @property {string} orientation
 * @property {() => void} onAutoAdjust
 *
 * @typedef State
 * @property {boolean} showLoader
 * @property {boolean} autoAdjustEnabled
 *
 * @extends BusComponent<Props, State>
 */
class AutoAdjust extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            showLoader: false,
            autoAdjustEnabled: false,
            showPercentageControl: false,
        };
    }

    componentDidMount() {
        this.bindGluBusEvents({
            AUTO_ADJUST_FAILED_POPUP_REQUEST: this.hideLoader,
            APPLY_NATURAL_BREAKS_CUTPOINTS_DONE: this.hideLoader,
            DATA_ANALYSIS_DATA_REQUEST_DONE: this.hideLoader, // bubbles autoadjust done event
            MAP_NEW_DATA_THEME_APPLIED: this.updateAutoAdjust,
            CURRENT_METADATA: this.onCurrentMetadataRetrieved,
        });

        this.emit('CURRENT_METADATA_REQUEST', { source: this });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    updateAutoAdjust() {
        this.emit('CURRENT_METADATA_REQUEST', { source: this });
    }

    onCurrentMetadataRetrieved = (metadata, target) => {
        if (target === this) {
            const currentMetadataObject = getMetadataObjectsFromVariableSelectionItem(
                this.props.mapInstance.dataTheme.variableSelection.items[0],
                metadata
            );
            // Variable type NONE is for variables that are categorical and do not support
            // auto adjust functionality
            this.setState({
                autoAdjustEnabled: currentMetadataObject.variable.varType !== VariableType.NONE,
                showPercentageControl: currentMetadataObject.variable.showPercentageControl,
            });
        }
    };

    applyAutoAdjust = () => {
        this.setState({ showLoader: true }, () => {
            // Sometimes this.props.onAutoAdjust function runs before React manages to render the new state (with showLoader: true).
            // In the meantime onAutoAdjust takes up all the browser memory and freezes the browser. Consequently the `showLoader` state
            // is updated and rendered after all auto adjust work is done (which can last for multiple seconds).
            // So I force the `onAutoAdjust` to happen after the loader is rendered - using setTimeout.
            //
            // TODO: Right now the natural cutpoints auto adjust can be very resource consuming.
            // There are scenarios where user can select block groups and attempt to do the auto adjust
            // with more than 30k features. We allow 5k features right now but it is still hard on the browser
            // to handle that - so it freezes and blocks the main thread.
            // That's why we need to handle that heavy logic inside a separate thread - using worker
            // ( https://socex-charts.atlassian.net/browse/SOCEX-1658 )
            // Once that is implemented, there will be no need for the `setTimeout`
            setTimeout(() => this.props.onAutoAdjust());
        });
    }

    hideLoader = () => this.setState({ showLoader: false });

    render() {
        const { applicationMode } = this.context;
        const {
            intl,
            mapInstance,
            orientation,
        } = this.props;
        const { showLoader, autoAdjustEnabled, showPercentageControl } = this.state;

        if (!autoAdjustEnabled) {
            return null;
        }

        const canEditVisualization = applicationMode === ApplicationMode.EXPLORE || applicationMode === ApplicationMode.EDIT;

        const autoAdjustButton = showLoader ?
                                 (
                                     <ThreeDotsLoader />
                                 ) :
                                 (
                                     <button
                                         className="btn-link auto-adjust__main-button"
                                         onClick={this.applyAutoAdjust}
                                     >
                                         {intl.formatMessage({ id: 'legend.autoAdjust' })}
                                     </button>
                                 );
        return (
            <div className="auto-adjust">
                {autoAdjustButton}
                <AutoAdjustInfo
                    mapInstance={mapInstance}
                    orientation={orientation}
                />
                {canEditVisualization && (
                    <div className="flex-it center">
                        <div className="divider divider--vertical divider--btn-separator" />
                        <AutoAdjustMenu
                            mapInstance={mapInstance}
                            showPercentageControl={showPercentageControl}
                        />
                    </div>
                )}
            </div>
        );
    }
}

AutoAdjust.propTypes = {
    intl: PropTypes.object.isRequired,
    mapInstance: PropTypes.object.isRequired,
    orientation: PropTypes.oneOf(Object.keys(Orientation)).isRequired,
    onAutoAdjust: PropTypes.func.isRequired,
};

export default injectIntl(AutoAdjust);
