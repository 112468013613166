import GLU from '../glu2.js/src/index';

class BaseController extends GLU.Controller {
  constructor({ bus, activateSource, props }) {
    super();

    this._bus = bus;
    this._activateSource = activateSource;
    this._props = props;
  }

  bindGluBusEvents(events) {
    this._busEvents = events;
    Object.keys(this._busEvents).forEach(eventName =>
      this._bus.on(eventName, this._busEvents[eventName], this),
    );
  }

  unbindGluBusEvents() {
    Object.keys(this._busEvents).forEach(eventName =>
      this._bus.off(eventName, this._busEvents[eventName]),
    );

    this._busEvents = null;
  }

  get bus() {
    return this._bus;
  }

  /**
   * @template T
   * @param {new() => T} sourceInstance
   * @returns {T}
   */
  activateSource(sourceInstance) {
    if (this._activateSource) return this._activateSource(sourceInstance);
    return sourceInstance;
  }

  get props() {
    return this._props;
  }
}

export default BaseController;
