import React from 'react';
import PropTypes from 'prop-types';
import BusComponent from '../BusComponent';

import Tooltip from '../Tooltip';
import Popover from '../popover/Popover';
import classNames from 'classnames';

class IconMenu extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            menuOpen: false,
        };

        this.bindGluBusEvents({
            CLOSE_DROPDOWN: this.onCloseDropdown,
        });
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.unbindGluBusEvents();
    }

    onCloseDropdown() {
        if (this._isMounted) {
            this.setState({ menuOpen: false });
        }
    }

    onCloseRequest = e => {
        if (e.target !== this.trigger && e.target.parentElement !== this.trigger) {
            this.onCloseDropdown();
        }
    }

    onTriggerClick = () => {
        this.setState(prevState => ({ menuOpen: !prevState.menuOpen }));
    }

    renderPopover() {
        const { anchorOrigin, targetOrigin, children, className, marginLeft, marginTop } = this.props;

        return (
            <Popover
                onCloseRequest={this.onCloseRequest}
                anchorOrigin={anchorOrigin}
                targetOrigin={targetOrigin}
                marginLeft={marginLeft}
                marginTop={marginTop}
                anchorEl={this.trigger}
                open={this.state.menuOpen}
                className={className}
            >
                <div className="icon-menu__menu">
                    {children}
                </div>
            </Popover>
        );
    }

    render() {
        const { triggerClassName, light, disabled, icon, iconClassName, tooltip, ariaLabel, label } = this.props;
        const { menuOpen } = this.state;
        let labelElement = null;

        const iconMenuTriggerClasses = classNames('icon-menu__trigger', triggerClassName, {
            'icon-menu__trigger--opened': menuOpen && !light,
            'icon-menu__trigger--opened-light': menuOpen && light,
        });

        let buttonClasses = classNames('btn-icon', iconMenuTriggerClasses, {
            'btn-icon--light': light,
            'btn-icon--disabled': disabled,
        });

        if (label) {
            buttonClasses = classNames('btn-flat', iconMenuTriggerClasses, {
                'btn-flat--light': light,
                'btn-flat--disabled': disabled,
            });

            labelElement = (<div className="margin-left-8">{label}</div>);
        }

        let anchor = (<button
            className={buttonClasses}
            onClick={this.onTriggerClick}
            ref={e => { this.trigger = e; }}
            aria-haspopup
            aria-label={ariaLabel}
        >
            <i className={iconClassName || 'material-icons'}>{icon}</i>
            {labelElement}
        </button>);

        if (tooltip) {
            anchor = (<Tooltip
                placement="bottom"
                mouseEnterDelay={0.5}
                mouseLeaveDelay={0}
                overlay={tooltip}
            >
                {anchor}
            </Tooltip>);
        }

        return (
            <div className="icon-menu">
                {anchor}
                {this.trigger && this.renderPopover()}
            </div>
        );
    }

}

IconMenu.propTypes = {
    children: PropTypes.element.isRequired,
    anchorOrigin: PropTypes.object,
    targetOrigin: PropTypes.object,
    marginTop: PropTypes.number,
    marginLeft: PropTypes.number,
    light: PropTypes.bool,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    icon: PropTypes.string,
    iconClassName: PropTypes.string,
    className: PropTypes.string,
    tooltip: PropTypes.string,
    triggerClassName: PropTypes.string,
    ariaLabel: PropTypes.string,
};

IconMenu.defaultProps = {
    icon: 'more_vert',
    iconClassName: undefined,
    className: '',
    tooltip: undefined,
    label: undefined,
    light: false,
    disabled: false,
    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
    targetOrigin: { vertical: 'top', horizontal: 'left' },
    marginTop: 0,
    marginLeft: 0,
    triggerClassName: undefined,
    ariaLabel: undefined,
};


export default IconMenu;
