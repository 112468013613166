import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../form/Checkbox';

const AnnotationCardFooter = props => (
    <div className="annotation-card__footer flex-it center space-between">
        <Checkbox
            label="Show in legend"
            className="body-1"
            checked={props.visibleInLegend}
            onCheck={props.onChangeVisibility}
        />
        <div className="flex-it center">
            <div className="divider divider--vertical divider--btn-separator" />
            <button className="btn-icon" onClick={props.onDeleteAnnotation}>
                <i
                    className="material-icons"
                >
               delete
            </i>
            </button>
        </div>
    </div>
);

AnnotationCardFooter.propTypes = {
    visibleInLegend: PropTypes.bool,
    onChangeVisibility: PropTypes.func.isRequired,
    onDeleteAnnotation: PropTypes.func.isRequired,
};
AnnotationCardFooter.defaultProps = {
    visibleInLegend: false,
};

export default AnnotationCardFooter;
