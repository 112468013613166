import React from 'react';
import { injectIntl } from 'react-intl';
import Popover from '../popover/Popover';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const COMPARE_YEARS_LIMIT = 2;

class AddMoreYears extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            addYearOpen: false,
            selectedSurveys: [...props.compareSurveysList],
        };
    }

    componentWillReceiveProps(nextProps) {
        this.state = {
            selectedSurveys: [...nextProps.compareSurveysList],
        };
    }

    onCloseRequest = () => {
        this.setState({ addYearOpen: false });
    };

    onTriggerClick = () => {
        this.setState(prevState => ({ addYearOpen: !prevState.addYearOpen }));
    };

    onClear = () => {
        this.setState({
            selectedSurveys: [],
            addYearOpen: false,
        });
        this.props.onDone([]);
    };

    onDone = () => {
        this.setState({ addYearOpen: false });
        this.props.onDone(this.state.selectedSurveys);
    };

    // check if year exists, if present remove it
    // if not add it taking into account the comparison limit
    addYear = survey => {
        const { selectedSurveys } = this.state;
        const newSelectedSurveys = [...selectedSurveys];
        const index = newSelectedSurveys.findIndex(el => el.name === survey.name);

        if (index !== -1) {
            newSelectedSurveys.splice(index, 1);
        } else if (newSelectedSurveys.length < COMPARE_YEARS_LIMIT) {
            newSelectedSurveys.push(survey);
        }
        this.setState({ selectedSurveys: newSelectedSurveys });
    };

    render() {
        const { addYearOpen, selectedSurveys } = this.state;
        const { availableSurveys, compareSurveysList, selectedSurveyName } = this.props;

        if (availableSurveys.length < 2) return null;

        // No compare years yet, use simple button
        let addButton = (
            <button
                ref={e => { this.addYearTrigger = e; }}
                className="btn-chip margin-right-10"
                onClick={this.onTriggerClick}
            >
                <i className="material-icons btn-chip__icon">add</i>
                Add year for comparison
            </button>
        );
        // Years for comparison added, different button
        if (compareSurveysList.length > 0) {
            const compareYears = compareSurveysList.map(s => s.year).join(',');
            addButton = (
                <div className="compare-survey-btn" ref={e => { this.addYearTrigger = e; }}>
                    <button
                        className="compare-survey-btn__title flex-it justify-center center"
                        onClick={this.onTriggerClick}
                    >
                        {this.props.intl.formatMessage({ id: 'dataBrowser.compareWithYear' }, { compareYears })}
                    </button>

                    <button className="compare-survey-btn__close" onClick={this.onClear}>
                        <i className="material-icons md-18">close</i>
                    </button>
                </div>
            );
        }

        return (
            <div className="flex-it location-analysis__add-years-container">
                {addButton}
                {this.addYearTrigger && (
                    <Popover
                        anchorEl={this.addYearTrigger}
                        className="location-analysis__add-years"
                        open={addYearOpen}
                        showClose
                        triggerToggle
                        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
                        targetOrigin={{ horizontal: 'left', vertical: 'top' }}
                        onCloseRequest={this.onCloseRequest}
                    >
                        <div className="location-analysis__add-years-content">
                            <div className="location-analysis__add-years-title">
                                {this.props.intl.formatMessage({ id: 'dataBrowser.selectUpToLimitYears' }, { limit: COMPARE_YEARS_LIMIT })}
                            </div>
                            <div className="location-analysis__add-years-buttons">
                                {availableSurveys.map(survey =>
                                    <button
                                        key={survey.name}
                                        className={classNames('btn-chip', 'location-analysis__btn', 'location-analysis__btn--round',
                                            { 'location-analysis__btn--active': selectedSurveys.findIndex(s => s.name === survey.name) !== -1 })}
                                        onClick={() => this.addYear(survey)}
                                        disabled={survey.name === selectedSurveyName}
                                    >
                                        {survey.year}
                                    </button>)}
                            </div>
                            <div className="flex-it flex-end">
                                <button
                                    className="btn-flat"
                                    onClick={this.onDone}
                                >
                                    {this.props.intl.formatMessage({ id: 'done' })}
                                </button>
                            </div>
                        </div>
                    </Popover>
                )}
            </div>
        );
    }
}

AddMoreYears.propTypes = {
    selectedSurveyName: PropTypes.string.isRequired,
    availableSurveys: PropTypes.array.isRequired,
    compareSurveysList: PropTypes.array.isRequired,
    onDone: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(AddMoreYears);
