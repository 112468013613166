import React from 'react';
import BusComponent from '../BusComponent';
import Loader from '../Loader';
import UserUploadItem from './UserUploadItem';

class MyUploads extends BusComponent {
    constructor(props, context) {
        super(props, context);

        this.bindGluBusEvents({
            USER_DATA: this.onDataRetrieved,
        });

        this.state = {
            isLoading: true,
            userUploadedData: [],
        };
    }

    componentDidMount() {
        this.emit('RETRIEVE_USER_DATA');
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    onDataRetrieved = userUploadedData => {
        this.setState({
            userUploadedData,
            isLoading: false,
        });
    };

    render() {
        if (!this.state.isLoading && !this.state.userUploadedData.length) return null;

        return (
            <div className="user-data-uploads flex-it grow column">
                <div className="user-data-uploads__title">Previous uploads</div>
                {this.state.isLoading && <Loader text="Loading user data layers..." />}
                {!this.state.isLoading && (
                    <div className="user-data-uploads__list vertical-scrollbar">
                        {this.state.userUploadedData.map(ud => (
                            <UserUploadItem
                                key={ud.id}
                                mapInstanceId={this.props.mapInstanceId}
                                userData={ud}
                            />
                        ))}
                    </div>
                )}
            </div>
        );
    }
}

export default MyUploads;
