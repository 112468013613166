/* eslint class-methods-use-this: 0 */
import BaseController from './BaseController';
import MapDataSource from '../dataSources/MapDataSource';

class MapController extends BaseController {
    static get name() {
        return 'MapController';
    }

    static getInstance(options) {
        return new MapController(options);
    }

    onActivate() {
        this.bindGluBusEvents({
            CURRENT_MAPS_REQUEST: this.onCurrentMapsRequest,
        });
        this.mapDataSource = this.activateSource(MapDataSource);
    }

    onCurrentMapsRequest() {
        this.bus.emit('CURRENT_MAPS', this.mapDataSource.currentMaps);
    }

    onDeactivate() {
        this.unbindGluBusEvents();
    }
}

export default MapController;
