import React from 'react';
import PropTypes from 'prop-types';

class PopupContentColumn extends React.Component {
    onRemoveClick = () => this.props.onRemove(this.props.c);
    render() {
        return (
            <div className="layer-editor-popup-content-column flex-it center no-shrink space-between rounded">
                {this.props.c}
                <i
                    title="Remove column"
                    onClick={this.onRemoveClick}
                    className="material-icons clickable clickable--24"
                >
                    close
                </i>
            </div>
        );
    }
}

PopupContentColumn.propTypes = {
    c: PropTypes.string.isRequired,
    onRemove: PropTypes.func.isRequired,
};

export default PopupContentColumn;
