class MetaReport {

    /**
     * Id of the report. It is a number in string format.
     * @type {string}
     */
    get id() {
        return this._id;
    }

    set id(id) {
        this._id = id;
    }

    /**
     * User friendly name of the premade report
     * @type {string}
     */
    get name() {
        return this._name;
    }

    set name(name) {
        this._name = name;
    }

    /** @type {boolean} */
    get isSystemReport() {
        return this._isSystemReport;
    }

    set isSystemReport(isSystemReport) {
        this._isSystemReport = isSystemReport;
    }

    /**
     * @type {('pub'|'pro'|'student')[]}
     */
    get productTags() {
        return this._productTags;
    }

    set productTags(productTags) {
        this._productTags = productTags;
    }

    /** @type {number} */
    get index() {
        return this._index;
    }

    set index(index) {
        this._index = index;
    }

    /** @type {import('./MetaSurvey').default} */
    get survey() {
        return this._survey;
    }

    set survey(survey) {
        this._survey = survey;
    }

    toJSON() {
        return {
            id: this.id,
            name: this.name,
            isSystemReport: this.isSystemReport,
            productTags: this.productTags,
        };
    }
}

export default MetaReport;
