// @ts-check
import React from 'react';
import { injectIntl } from 'react-intl';

import AppConfig from '../../appConfig';
import BusComponent from '../BusComponent';

/**
 * @typedef Props
 * @property {import('react-intl').intlShape} intl
 * @property {string} mapInstanceId
 *
 * @extends {BusComponent<Props>}
 */
class MapSatelliteButton extends BusComponent {
    onClick = e => {
        e.stopPropagation();
        this.emit('SHOW_SATELLITE_REQUEST', {
            mapInstanceId: this.props.mapInstanceId,
        });
        this.emit('DISPLAY_MAP_EDITOR_FRAME', {
            mapInstanceId: this.props.mapInstanceId,
        });
    };

    render() {
        return (
            <button
                className="btn-chip btn-chip--tiny-padding btn-chip--with-margin"
                onClick={this.onClick}
            >
                <img
                    className="map-satellite__image map-satellite__image--rounded"
                    src={`${AppConfig.constants.assetsBaseURL}/icons/sat_map.png`}
                    alt={this.props.intl.formatMessage({
                        id: 'dataBrowser.satelliteMap',
                    })}
                    aria-hidden="true"
                />
                {this.props.intl.formatMessage({
                    id: 'dataBrowser.satelliteView',
                })}
            </button>
        );
    }
}

export default injectIntl(MapSatelliteButton);
