import dragonfly from 'dragonfly-v3';
import MarkerAssets from '../../map/assets/MarkerAssets';
import PropTypes from 'prop-types';
import React from 'react';
import AnnotationsMarkers from '../../enums/AnnotationsMarkers';

class MapPreview extends React.Component {
    componentDidMount() {
        this.buildDragonflyMap();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.mapViewer !== this.props.mapViewer) {
            this.rebuild = true;
        }

        if (!this.rebuild && this.dragonflyMap && nextProps.targetZoomLevel !== this.props.targetZoomLevel && this.dragonflyMap.getZoom() !== nextProps.targetZoomLevel) {
            if (this.dragonflyMap.isMoving()) {
                this.dragonflyMap.stop();
            }
            this.dragonflyMap.flyTo({ zoom: nextProps.targetZoomLevel, duration: 100 }, { reaction: true });
        }
    }

    shouldComponentUpdate() {
        return this.rebuild === true;
    }

    componentDidUpdate() {
        this.rebuild = false;
        if (this.dragonflyMap) {
            this.dragonflyMap.remove();
        }
        this.buildDragonflyMap();
    }

    componentWillUnmount() {
        if (this.dragonflyMap) {
            this.dragonflyMap.remove();
        }
    }

    get map() {
        return this.dragonflyMap;
    }

    buildDragonflyMap = () => {
        const targetZoomLevel = parseFloat(this.props.targetZoomLevel);

        const dragonflyMapData = this.props.mapViewer._dragonflyMapData;
        const sourceMapLayersMasks = dragonflyMapData.mapInstanceData.layersMasks;
        const userDataLayers = dragonflyMapData.userDataLayersData._userDataLayers;

        const markersInUse = userDataLayers
            .reduce((prev, curr) => {
                curr._styleRules
                    .filter(style => style.type === 'symbol')
                    .forEach(style => {
                        const markerAssetsUndefined = !style._markerPathId || !style._markerColor;
                        if (markerAssetsUndefined) return;

                        const existingMarkerAssets = prev.some(p => p.markerPathId === style._markerPathId && p.markerColor === style._markerColor);
                        if (existingMarkerAssets) return;

                        prev.push({
                            markerPathId: style._markerPathId,
                            markerColor: style._markerColor,
                        });
                    });
                return prev;
            }, []);

        const extraImages = markersInUse.map(marker => {
            const markerId = `${marker.markerPathId}${marker.markerColor}`;
            const markerPng = MarkerAssets.getMarkerPng(markerId, marker, {
                useBackground: true,
                strokeWidth: 2,
                outputSize: 44,
            });
            return { id: markerId, image: markerPng, pixelRatio: 2 };
        });

        this.props.mapViewer.mapInstance.annotations.reduce((annotations, annotation) => {
            if (annotation.type !== 'Marker') return annotations;
            const marker = AnnotationsMarkers.find(am => am.id === annotation.markerPathId);
            const markerId = `${marker.fileName}_${annotation.fillColor}`;
            const markerPng = MarkerAssets.getMarkerPng(markerId, {
                markerPathId: marker.fileName,
                markerColor: annotation.fillColor,
            }, {});
            if (extraImages.every(ei => ei.id !== markerId)) {
                extraImages.push({ id: markerId, image: markerPng, pixelRatio: 2 });
            }
            return annotations;
        }, []);

        this.dragonflyMap = new dragonfly.Map({
            container: this.container,
            style: this.props.mapViewer.dragonflyMap.getStyle(),
            preserveDrawingBuffer: false,
            attributionControl: false,
            center: this.props.mapViewer.dragonflyMap.getCenter(),
            zoom: targetZoomLevel,
            interactive: false,
        });

        /*
         To blend streets, we use special painter feature available in dragonfly that will do that for us,
         but we have to add the masks manually.
       */
        sourceMapLayersMasks.forEach(mask => {
            this.dragonflyMap.painter.addLayerMask(mask);
        });

        /*
           To render user data layers, we need to load the images that have been used but are not in the map sprite.
         */
        extraImages.forEach(image => {
            this.dragonflyMap.addImage(image.id, image.image, { pixelRatio: image.pixelRatio });
        });

        this.dragonflyMap.resize();
    };

    render() {
        return (<div
            style={{ width: '100%', height: '100%', position: 'relative' }}
            ref={r => {
                this.container = r;
            }}
        />);
    }
}

MapPreview.propTypes = {
    mapViewer: PropTypes.object.isRequired,
    targetZoomLevel: PropTypes.number.isRequired,
};

export default MapPreview;
