import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../Tooltip';
import Key from '../../enums/Key';
import classNames from 'classnames';

class SimpleDropdownItem extends React.Component {
    onItemClick = () => {
        const { onItemClick, item } = this.props;
        if (item.isDivider) {
            return;
        }
        onItemClick(item.id);
    };

    onItemKeyUp = e => {
        if (e.keyCode === Key.ENTER) {
            this.onItemClick();
        }
    }

    render() {
        const { selected, item } = this.props;
        const { disabled, style, text, fullText, isDivider, leftIcon, rightIcon } = item;

        const clickable = !selected && !isDivider;
        const itemClasses = classNames('simple-dropdown__item', {
            'simple-dropdown__item--selected': selected,
            'simple-dropdown__item--divider': isDivider,
            clickable,
            disabled: disabled || isDivider,
        });

        const itemNode = (
            <li
                role="option"
                aria-selected={selected}
                tabIndex={clickable ? 0 : -1}
                className={itemClasses}
                onClick={this.onItemClick}
                onKeyDown={this.onItemKeyUp}
                aria-hidden={!clickable}
            >
                {leftIcon && <i className="material-icons">{leftIcon}</i>}
                <div
                    className="simple-dropdown__item__text"
                    style={style}
                >
                    {text}
                </div>
                {rightIcon && <i className="material-icons">{rightIcon}</i>}
            </li>
        );

        // if item has full text show it in tooltip
        if (fullText) {
            return (
                <Tooltip
                    placement="bottom"
                    mouseEnterDelay={0.5}
                    mouseLeaveDelay={0}
                    overlay={fullText}
                >
                    {itemNode}
                </Tooltip>
            );
        }

        return itemNode;
    }
}

SimpleDropdownItem.propTypes = {
    selected: PropTypes.bool,
    onItemClick: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired,
};

SimpleDropdownItem.defaultProps = {
    selected: false,
};

export default SimpleDropdownItem;
