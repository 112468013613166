import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

const BubbleGraphicsSimplified = props => (
    <div className="bubble-size__graph flex-start">
        <svg width="49px" height="49px" viewBox="0 0 49 49">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Bubble-size" fillRule="nonzero">
                    <circle id="Oval-Copy-37" stroke="#DDDDDD" fill="#F0F0F0" cx="24" cy="24" r="24" />
                    <circle id="Oval-Copy-26" fill="#5F6368" cx="24" cy="24" r="1" />
                    <path d="M24,24.5 L54,24.5" id="Line-6-Copy-2" stroke="#747474" strokeLinecap="square" strokeDasharray="1,3" />
                </g>
            </g>
        </svg>
        <div className="bubble-size__labels flex-column">
            <FormattedMessage id="legend.sizeRepresentsValueOf" />
            <strong className="bubble-size__label">{props.bubbleLabel}</strong>
        </div>
    </div>);

BubbleGraphicsSimplified.propTypes = {
    bubbleLabel: PropTypes.string.isRequired,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(BubbleGraphicsSimplified);
