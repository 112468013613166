// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AppConfig from '../../appConfig';
import format from '../../helpers/NumberFormatter';
import LocationAnalysisItem from '../../objects/LocationAnalysisItem';
import LocationAnalysisItemOrigin from '../../enums/LocationAnalysisItemOrigin';
import Distance from '../distance/Distance';
import Time from '../distance/Time';
import SuggestEditFacilityButton from './SuggestEditFacilityButton';
import { POI_TYPES } from '../../enums/PoiTypes';
import FacilityManagementTypes from '../../enums/FacilityManagementTypes';

/**
 * @typedef Props
 * @property {import('../../').PopupFields} popupFields
 * @property {import('@turf/helpers').Feature[]} features
 * @property {() => void} onSelect
 *
 * @param {Props} props
 */
class InfoBubblePointFeature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPoiIndex: 0,
    };
  }

  render() {
    const {
      features,
      popupFields,
      onOpenPointInfo,
      locationAnalysisItem,
      suggestEditFormClickBack,
    } = this.props;
    const feature = features[this.state.currentPoiIndex];
    const featureType = feature.properties[popupFields.typeProperty];
    const featureTypePopupFields = popupFields.sameForAllPointTypes
      ? popupFields.propertiesForPointType.default
      : popupFields.propertiesForPointType[featureType];
    const pointType = feature.properties.category;
    const managementType = feature.properties.management_type;

    let pointTag;
        
    switch (managementType) {
      case FacilityManagementTypes.reit:
          pointTag = 'REIT';
          break;
      case FacilityManagementTypes.sophisticated:
          pointTag = 'SOPHISTICATED OPERATOR';
          break;
      case FacilityManagementTypes.nonDesignated:
          pointTag = 'NON-DESIGNATED';
          break;
      default:
          pointTag = pointType === 'construction' ? 'CONSTRUCTION SITE' : 'NON-DESIGNATED';
    }

    const title = featureTypePopupFields
      .filter(field => field.isTitle)
      .map(field => <div key={field.property}>{feature.properties[field.property]}</div>);

    const getUrlDomain = website => {
      try {
        return new URL(website.toString()).hostname;
      } catch (e) {
        console.error(`Website URL ${website} is malformed.`);
        return null;
      }
    };

    const editFormPayload = {
      category: 'Storage Facility',
      location: feature.properties.full_address,
      name: feature.properties.name,
      telephone: feature.properties.telephone,
      website: feature.properties.website,
    };

    const openInGoogleMaps = (
      <div className="open-in-google-maps__geojson">
        <a
          className="google-map-link"
          target="_blank"
          rel="noopener noreferrer"
          href={`https://www.google.com/maps/search/?api=1&query=${feature.geometry.coordinates[1]},${feature.geometry.coordinates[0]}`}
        >
          Open in Google Maps
        </a>
        <div className="suggest-edit-button-margin">
          <SuggestEditFacilityButton
            editFormPayload={editFormPayload}
            clickBack={suggestEditFormClickBack}
          />
        </div>
      </div>
    );

    const openMoreInfo = () => {
      const facilityType =
        feature.properties.category.toString() === 'construction'
          ? 'ss-construction'
          : 'ss-facility';
      const newLocationAnalysisItem = new LocationAnalysisItem({
        type: facilityType,
        id: feature.properties.facility_id,
        value: feature.properties.name,
        point: { lng: feature.geometry.coordinates[0], lat: feature.geometry.coordinates[1] },
        itemOrigin: LocationAnalysisItemOrigin.LIBRARY_LAYER_FEATURE,
        analysisTypeId: AppConfig.constants.defaultLocationAnalysisSelectionType,
        selection: new Set(AppConfig.constants.defaultLocationAnalysisSelection),
      });
      const additionalParams = POI_TYPES.some(poiType => poiType === facilityType)
        ? {
            'point-type': facilityType,
            'facility-id': feature.properties.facility_id,
          }
        : {
            'point-type': facilityType,
          };
      const visualReportProps = {
        report: 'selfstorage-point-info',
        additionalParams,
      };
      onOpenPointInfo(newLocationAnalysisItem, visualReportProps);
    };

    let distanceFromPin;
    if (locationAnalysisItem) {
      const lng = feature.geometry.coordinates[0];
      const lat = feature.geometry.coordinates[1];

      const showTime = locationAnalysisItem.isComplete && locationAnalysisItem.isIsochrone;

      distanceFromPin = (
        <div className="self-storage-info-bubble">
          <div className="padded-12 flex-it center">
            {showTime && (
              <Time
                startPoint={locationAnalysisItem.point}
                endPoint={{ lng, lat }}
                mapboxRoutingProfile={locationAnalysisItem.mapboxRoutingProfile}
              />
            )}
            {showTime && <i className="divider divider--vertical-small divider--btn-separator" />}
            <div className="tag">
              <Distance startPoint={locationAnalysisItem.point} endPoint={{ lng, lat }} />
            </div>
          </div>
        </div>
      );
    }

    const rows = featureTypePopupFields
      .filter(
        field =>
          !field.isTitle &&
          feature.properties[field.property] !== null &&
          feature.properties[field.property] !== 'null',
      )
      .filter(field => feature.properties[field.property] != null)
      .map(field => {
        let value = feature.properties[field.property];
        if (!field.isWebsite && field.format) {
          value = format({ number: value, numberFormat: field.format });
        }
        if (typeof value === 'boolean') {
          value = value ? 'Yes' : 'No';
        }

        return (
          <li
            key={field.property}
            className={classNames('flex-it center space-between data-row', {
              'data-row--highlighted': field.isHighlighted,
              'data-row--indented': field.isIndented,
            })}
          >
            <div className="data-row__label">
              {field.isIndented && <span>&#x2022;</span>} {field.label}
            </div>
            <div className="data-row__value ellipsis">
              {field.isWebsite ? (
                <a
                  href={feature.properties[field.property]}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {getUrlDomain(feature.properties[field.property])}
                </a>
              ) : (
                value
              )}
            </div>
          </li>
        );
      });

    return (
      <div className="geojson-point-popup geojson-point-popup__ss_facility">
        <div className="geojson-point-popup__header">
          <div className="geojson-point-popup__margin-bottom">
            <span className="margin-right-10">SELF STORAGE</span>
            <span className="geojson-point-popup__tag">{pointTag}</span>
          </div>
          <h5 className="geojson-point-popup__ss-facility-title">{title}</h5>
          {openInGoogleMaps}
          {distanceFromPin}
        </div>
        <ul>{rows}</ul>
        <div className="flex-it center geojson-point-popup__footer flex-center">
          <button onClick={openMoreInfo} className="btn btn-flat">
            View more details
          </button>
        </div>
        {this.props.features.length > 1 && (
          <div className="data-row flex-it center space-between">
            <div className="current-item">
              {this.state.currentPoiIndex + 1}/{this.props.features.length}
            </div>
            <div className="flex-it center">
              <button
                disabled={this.state.currentPoiIndex === 0}
                className="btn btn-pagination"
                onClick={() =>
                  this.setState(prevState => ({ currentPoiIndex: prevState.currentPoiIndex - 1 }))
                }
              >
                <i className="material-icons">keyboard_arrow_left</i>
              </button>
              <button
                disabled={this.state.currentPoiIndex === this.props.features.length - 1}
                className="btn btn-pagination"
                onClick={() =>
                  this.setState(prevState => ({ currentPoiIndex: prevState.currentPoiIndex + 1 }))
                }
              >
                <i className="material-icons">keyboard_arrow_right</i>
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

InfoBubblePointFeature.propTypes = {
  popupFields: PropTypes.object.isRequired,
  features: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
  onOpenPointInfo: PropTypes.func,
  suggestEditFormClickBack: PropTypes.func,
  locationAnalysisItem: PropTypes.object,
};

InfoBubblePointFeature.defaultProps = {
  onSelect: undefined,
  onOpenPointInfo: undefined,
  suggestEditFormClickBack: undefined,
  locationAnalysisItem: undefined,
};

export default InfoBubblePointFeature;
