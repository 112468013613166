import BaseHandler from './BaseHandler';

class SelectionHandler extends BaseHandler {
    constructor(mapViewer) {
        super(mapViewer);
        this._data = mapViewer.dragonflyMapData.selectionData;
        this.addControl(this._data.selectionControl, 'selection');

        this._boundOnDisableFeatureInteractivityRequest = this._onDisableFeatureInteractivityRequest.bind(this);
        this._boundOnEnableFeatureInteractivityRequest = this._onEnableFeatureInteractivityRequest.bind(this);
        this._boundOnSelectionEnd = this._onSelectionEnd.bind(this);

        this.setGluBusEvents({
            MAP_UPDATE_PREFERRED_SUMMARY_LEVEL_REQUEST: this.applySummaryLevelUpdate,
        });

        this.map.on('disablefeatureinteractivityrequest', this._boundOnDisableFeatureInteractivityRequest);
        this.map.on('enablefeatureinteractivityrequest', this._boundOnEnableFeatureInteractivityRequest);
        this.map.on('selectionend', this._boundOnSelectionEnd);
    }

    remove() {
        this.map.off('disablefeatureinteractivityrequest', this._boundOnDisableFeatureInteractivityRequest);
        this.map.off('enablefeatureinteractivityrequest', this._boundOnEnableFeatureInteractivityRequest);
        this.map.off('selectionend', this._boundOnSelectionEnd);
        this.removeControl(this._data.selectionControl, 'selection');
    }

    applySummaryLevelUpdate(e) {
        if (e.mapInstanceId !== this.mapInstanceId) return;
        this._data.applySummaryLevelUpdate();
        // apply dragonfly layers zoom range and auto source changes to dragonfly map
        this._data.layers.forEach(dl => {
            const mapDl = this.map.getLayer(dl.id);
            mapDl.sourceLayer = dl['source-layer'];
            this.map.setLayerZoomRange(dl.id, dl.minzoom, dl.maxzoom);
            this.map.style._updateLayer(mapDl);
        });
    }

    _onSelectionEnd(e) {
        this.emit('MAP_SELECTION_END', {
            selectionType: e.selectionType,
            mapInstanceId: this.mapInstanceId,
            features: e.features,
        });
    }

    _onDisableFeatureInteractivityRequest() {
        this.emit('MAP_DISABLE_FEATURE_INTERACTIVITY_REQUEST', { mapInstanceId: this.mapInstanceId });
    }

    _onEnableFeatureInteractivityRequest() {
        this.emit('MAP_ENABLE_FEATURE_INTERACTIVITY_REQUEST', { mapInstanceId: this.mapInstanceId });
    }
}

export default SelectionHandler;
