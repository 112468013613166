const DataClassificationEvents = {
    DATA_CLASSIFICATION_POPUP_REQUEST: 'DATA_CLASSIFICATION_POPUP_REQUEST',
    DATA_CLASSIFICATION_DATA_REQUEST: 'DATA_CLASSIFICATION_DATA_REQUEST',
    DATA_CLASSIFICATION_DATA_REQUEST_DONE: 'DATA_CLASSIFICATION_DATA_REQUEST_DONE',
    DATA_CLASSIFICATION_DATA_REQUEST_ERROR: 'DATA_CLASSIFICATION_DATA_REQUEST_ERROR',
    CUT_POINTS_APPLY: 'CUT_POINTS_APPLY',
    CLASSES_NUMBER_CHANGE: 'CLASSES_NUMBER_CHANGE',
    CLASSES_DRAG_END: 'CLASSES_DRAG_END',
    CLASSES_NUMBER_CHANGE_DONE: 'CLASSES_NUMBER_CHANGE_DONE',
    CLASSIFICATION_METHOD_CHANGE: 'CLASSIFICATION_METHOD_CHANGE',
    CLASSIFICATION_METHOD_CHANGE_DONE: 'CLASSIFICATION_METHOD_CHANGE_DONE',
    APPLY_FILTERS_REQUEST: 'APPLY_FILTERS_REQUEST',
    INSUFFICIENT_BASE_CHANGE: 'INSUFFICIENT_BASE_CHANGE',
    DATA_ANALYSIS_DATA_REQUEST: 'DATA_ANALYSIS_DATA_REQUEST',
    DATA_ANALYSIS_DATA_REQUEST_DONE: 'DATA_ANALYSIS_DATA_REQUEST_DONE',
    LOAD_CUT_POINTS_FROM_FILE_ERROR: 'LOAD_CUT_POINTS_FROM_FILE_ERROR',
    LOAD_CUT_POINTS_FROM_FILE_REQUEST: 'LOAD_CUT_POINTS_FROM_FILE_REQUEST',
    SAVE_CUT_POINTS_TO_FILE_REQUEST: 'SAVE_CUT_POINTS_TO_FILE_REQUEST',
};

export default DataClassificationEvents;
