import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import BusComponent from '../BusComponent';
import DataFilterPanel from './DataFilterPanel';
import FilterStatus from '../../enums/FilterStatus';
import Panel from '../panel/Panel';
import FilterCombiner from '../../enums/FilterCombiner';
import VariableEditor from './VariableEditor';
import googleTagManagerEvents from '../../helpers/GoogleTagManagerEventsWrapper';
import { doneDataFilterButtonEvents } from '../../enums/GoogleTagManagerEvents';

const DoneButton = googleTagManagerEvents('button', doneDataFilterButtonEvents);

class DataFilterEditor extends BusComponent {
    constructor(props, context) {
        super(props, context);
        const { dataFilter, hasDataFilter } = props.mapInstance;

        const filters = (dataFilter && dataFilter.filters) || {};
        const filterCombiner = (dataFilter && dataFilter.filterCombiner) || FilterCombiner.MATCH_ALL.type;

        this.state = {
            showVariableEditor: false,
            showDoneButton: hasDataFilter,
            mapLoaded: false,
            filters,
            filterCombiner,
        };
    }

    componentDidMount() {
        this.bindGluBusEvents({
            MAP_DATA_FILTER_APPLIED: this.onMapDataFilterApplied,
            MAP_DATA_FILTER_REMOVED: this.onMapDataFilterApplied,
            MAP_LOAD: this.onMapLoad,
            DATA_FILTERS_ADD_FILTER_SUCCESS: this.onAddFilter,
            DATA_FILTERS_UPDATE_SUCCESS: this.onFiltersUpdated,
            FILTER_COMBINER_UPDATED: this.onFilterCombinerUpdated,
            EDIT_VARIABLE: this.onEditVariable,
        });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    onEditVariable = ({ filter }) => {
        this.setState({
            showVariableEditor: true,
            filter,
        });
    }

    onAddFilter = ({ filters, filter }) => {
        this.setState({ filters, showVariableEditor: true, filter });
    }

    onFiltersUpdated = ({ filters }) => {
        this.setState({ filters, showVariableEditor: false });
    }

    onFilterCombinerUpdated = ({ filterCombiner }) => {
        this.setState({ filterCombiner });
    }

    onClearAllFilters = () => {
        const { mapInstanceId } = this.props;
        this.emit('REMOVE_DATA_FILTER_REQUEST', {
            mapInstanceId,
        });
    }

    onExitDataFilterEditor = () => {
        const { mapInstanceId } = this.props;
        this.emit('EXIT_DATA_FILTER_MODE', { mapInstanceId });
    }

    onCloseVariableEditor = () => {
        const { mapInstanceId } = this.props;
        const { filter } = this.state;
        // if no variable has been selected remove the filter else just close the variable editor
        if (filter.status < FilterStatus.VARIABLE_SELECTED) {
            this.emit('REMOVE_FILTER_REQUEST', { filterId: filter.filterId, mapInstanceId });
        } else {
            this.setState({
                showVariableEditor: false,
            });
        }
    }

    onMapDataFilterApplied = ({ dataFilter }) => {
        const filters = (dataFilter && dataFilter.filters) || {};
        const filterCombiner = (dataFilter && dataFilter.filterCombiner) || FilterCombiner.MATCH_ALL.type;
        const { showDoneButton } = this.state;

        this.setState({
            filters,
            filterCombiner,
            showDoneButton: showDoneButton || Object.keys(filters).length > 0,
            showVariableEditor: false,
        });
    }

    onMapLoad = e => {
        if (e.source.id !== this.props.mapInstanceId) return;
        this.setState({
            mapLoaded: true,
        });
    }

    render() {
        const { filters, filterCombiner, mapLoaded, showDoneButton, showVariableEditor, filter } = this.state;
        const { mapInstance, mapInstanceId, surveyName } = this.props;
        const filterPanelActions = [];
        const clearFiltersButtonClasses = classNames('btn-flat margin-right-10', {
            disabled: !mapLoaded,
        });
        const doneButtonClasses = classNames('btn-raised data-filter__done');

        if (mapInstance.hasDataFilter) {
            filterPanelActions.push(<button
                className={clearFiltersButtonClasses}
                key="clear-btn"
                onClick={this.onClearAllFilters}
            >
                {this.props.intl.formatMessage({ id: 'dataBrowser.clearFilters' })}
            </button>);
        }

        // Only for when the user opens the data filter editor first time and no filters are already set
        const doneButtonAvailable = showDoneButton && mapLoaded;
        if (doneButtonAvailable) {
            filterPanelActions.push(<DoneButton
                className={doneButtonClasses}
                key="done-btn"
                onClick={this.onExitDataFilterEditor}
            >
                {this.props.intl.formatMessage({ id: 'done' })}
            </DoneButton>);
        }

        const panelSubtitle = this.props.intl.formatMessage(
            {
                id: 'dataBrowser.limitedData.subtitle',
            },
            {
                surveyName,
            }
         );
        return (<div className="map-filter-editor flex-it column stretch">
            {showVariableEditor &&
            <VariableEditor
                mapInstance={mapInstance}
                mapInstanceId={mapInstanceId}
                filter={filter}
                onClose={this.onCloseVariableEditor}
            />}
            <Panel
                title={this.props.intl.formatMessage({ id: 'dataBrowser.filterAreasByCriteria' })}
                subtitle={panelSubtitle}
                mapInstanceId={mapInstanceId}
                headerStyle="white"
                headerActions={filterPanelActions}
                onCloseClick={doneButtonAvailable ? undefined : this.onExitDataFilterEditor}
                closeIcon={doneButtonAvailable ? undefined : <i className="material-icons">close</i>}
            >
                <DataFilterPanel
                    mapInstance={mapInstance}
                    mapInstanceId={mapInstanceId}
                    loading={!mapLoaded}
                    filters={filters}
                    filterCombiner={filterCombiner}
                />
            </Panel>
        </div>);
    }
}

DataFilterEditor.propTypes = {
    mapInstance: PropTypes.object.isRequired,
    mapInstanceId: PropTypes.string.isRequired,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(DataFilterEditor);
