import React from 'react';

import BaseController from './BaseController';
import UserInfoDataSource from '../dataSources/UserInfoDataSource';
import Loader from '../components/Loader';
import Errors from '../enums/Error';

class UserInfoController extends BaseController {
    static get name() {
        return 'UserInfoController';
    }

    static getInstance(options) {
        return new UserInfoController(options);
    }

    onActivate() {
        // Listen to the storage event for the logOut action across tabs
        window.addEventListener('storage', this.onLocalStorageChange);

        this.bindGluBusEvents({
            USER_INFO_LOAD_REQUEST: this.onUserInfoLoadRequest,
            USER_INFO_GET_REQUEST: this.onUserInfoGetRequest,
            LOGIN_REQUEST: this.onSessionRequest,
            SIGN_UP_REQUEST: this.onSessionRequest,
            LOG_OUT_REQUEST: this.onLogOutRequest,
        });

        this.onBoundLoginClickAway = this.onLoginPopupClickAway.bind(this);
        this.userInfoDataSource = this.activateSource(UserInfoDataSource);
    }

    onLocalStorageChange = e => {
        // In case userLogOut is set to true,
        // it means that the user logged out in another tab
        if (e.key === 'userLogOut' && e.newValue === 'true') {
            this.userInfoDataSource
                .loadUserInfo()
                .then(userInfo => {
                    this.bus.emit('LOG_OUT_SUCCESS', userInfo);
                });
        }
    }

    /**
     * Function that is used for setting up a session
     * for user. The function redirects user to a
     * (currently) login or signUp page. While the user
     * is trying to sign in, a popup is created which times
     * out after some time. During that time, the function
     * checks for user info every second for 'checkCount' times.
     * If the user info is changed (from guest to a real user),
     * the popup automatically closes.
     * @param params
     *      params.checkCount => number of checks after which the session request
     *                        is closed.
     *      params.url => the url user will be redirected when this function
     *                    is executed
     */

    // Callback for modal to check for user one more time
    // before it closes.
    onLoginPopupClickAway() {
        window.clearTimeout(this.timer);
        document.removeEventListener('visibilitychange', this.visibilityChangeCallbackFunction);
        this.bus.off('MODAL_CLOSED', this.onBoundLoginClickAway);
        this.loginPopupOpened = false;
    }

    onSessionRequest(params) {
        this.backoff = 2000;
        this.iteration = 0;
        this.bus.on('MODAL_CLOSED', this.onBoundLoginClickAway);

        if (params.checkCount === undefined) {
            params.checkCount = 32;
        }

        const onError = error => {
            // dispatch error event for error handler
            console.error(error);
            window.clearTimeout(this.timer);
            this.bus.emit('USER_INFO_LOAD_ERROR', {
                level: Errors.ERROR,
                originalError: error,
                additionalInfo: 'Loading user info failed when Session Request(onSessionRequest) was made.\n Check routes to www.socialexplorer.com',
            });
            this.bus.emit('CLOSE_MODAL');
        };
        // Callback function when timer expires or user logs in
        // Currently times out after 32 attempts (default)
        const checkUser = () => {
            this.userInfoDataSource.loadUserInfo()
                .then(currentUser => {
                    if (this.loginPopupOpened) {
                        this.iteration += 1;
                        if (currentUser && currentUser.userId !== -1) {
                            this.bus.emit('USER_INFO_LOAD_SUCCESS', this.userInfoDataSource.currentUser);
                            window.clearTimeout(this.timer);
                            this.bus.emit('CLOSE_MODAL');
                        } else if (this.iteration > params.checkCount) {
                            window.clearTimeout(this.timer);
                            this.bus.emit('CLOSE_MODAL');
                        } else {
                            window.clearTimeout(this.timer);
                            this.timer = setTimeout(checkUser, this.backoff);
                        }
                    }
                }).catch(onError);
        };

        // Callback function for visibility change
        this.visibilityChangeCallbackFunction = () => {
            if (document.visibilityState === 'visible') {
                window.clearTimeout(this.timer);
                checkUser();
            }
        };

        document.addEventListener('visibilitychange', this.visibilityChangeCallbackFunction);
        // Set modal message, open modal and start initial timer...
        this.bus.emit('OPEN_MODAL', {
            modalContent: React.createElement(Loader, {
                intlId: 'modals.waitingForUserToLogIn',
            }),
            name: 'Waiting for user loader',
            modalBoxClass: 'simple-modal',
        });
        this.loginPopupOpened = true;
        window.open(params.url);
        checkUser();
    }

    /**
     * Function that is responsible for handling currently available user info
     */
    onUserInfoGetRequest({ source }) {
        this.bus.emit('USER_INFO_GET_SUCCESS', this.userInfoDataSource.currentUser, source);
    }

    /**
     * Function that is responsible for handling user info request events
     */
    onUserInfoLoadRequest() {
        const onError = error => {
            this.bus.emit('USER_INFO_LOAD_ERROR', {
                level: Errors.CRITICAL,
                originalError: error,
                additionalInfo: 'Loading user info failed when communicating with API. \n Check routes to www.socialexplorer.com',
            });
        };

        this.userInfoDataSource.loadUserInfo()
            .then(userInfo => {
                this.bus.emit('USER_INFO_LOAD_SUCCESS', userInfo);
                localStorage.removeItem('userLogOut');
            }).catch(onError);
    }

    onLogOutRequest() {
        const onError = error => {
            this.bus.emit('USER_INFO_LOAD_ERROR', {
                level: Errors.CRITICAL,
                originalError: error,
                additionalInfo: 'Logging out failed when communicating with API. \n Check routes to www.socialexplorer.com',
            });
        };

        this.userInfoDataSource.logOut()
            .then(userInfo => {
                this.bus.emit('CLOSE_MODAL');
                this.bus.emit('LOG_OUT_SUCCESS', userInfo);
                // Set the local storage item userLogOut to true,
                // so all other open tabs can handle this logout
                localStorage.setItem('userLogOut', true);
            }).catch(onError);
        this.bus.emit('OPEN_MODAL', {
            name: 'Logging out loader',
            modalContent: React.createElement(Loader, {
                intlId: 'modals.loggingOut',
            }),
            modalBoxClass: 'simple-modal',
            canCloseModal: false,
        });
    }

    onDeactivate() {
        window.removeEventListener('storage', this.onLocalStorageChange);
        this.unbindGluBusEvents();
        this.bus.off('MODAL_CLOSED', this.onBoundLoginClickAway);
    }
}

export default UserInfoController;
