import BaseController from './BaseController';
import Errors from '../enums/Error';

import ShareDataSource from '../dataSources/ShareDataSource';
import ProjectDataSource from '../dataSources/ProjectDataSource';
import UserInfoDataSource from '../dataSources/UserInfoDataSource';

import AppConfig from '../appConfig';
import { composeUrl } from '../helpers/Util';

class ShareController extends BaseController {
    static get name() {
        return 'ShareController';
    }

    static getInstance(options) {
        return new ShareController(options);
    }

    onActivate() {
        this.bindGluBusEvents({
            SHARE_SNAPSHOT_LOAD_REQUEST: this.onShareSnapshotRequest,
            INITIAL_VIEW_BOUNDING_BOX_APPLIED: this.onInitialViewBoundingBoxApplied,
            PROJECT_LOAD_SUCCESS: this.updateSystemState,
            PROJECT_UPDATE_SUCCESS: this.updateSystemState,
            PROJECT_UPGRADE_SUCCESS: this.updateSystemState,
            EDIT_CURRENT_PROJECT_INFO_SUCCESS: this.updateSystemState,
        });
        this.shareDataSource = this.activateSource(ShareDataSource);
        this.projectDataSource = this.activateSource(ProjectDataSource);
        this.userInfoDataSource = this.activateSource(UserInfoDataSource);
        this._originalSystemState = undefined;
    }

    get lastSystemState() {
        return this._lastSystemState;
    }

    hasStateChanged() {
        if (this.lastSystemState === undefined) {
            return true;
        }
        const currentProject = this.projectDataSource.currentProject;
        return !currentProject.equals(this.lastSystemState);
    }

    updateSystemState() {
        this._originalSystemState = this.projectDataSource.currentProject.clone();
        this._lastSystemState = undefined;
    }

    onInitialViewBoundingBoxApplied() {
        if (!this.projectDataSource.currentProject.frames[0].mapInstances.find(mi => mi.initialView.applyBoundingBox)) {
            this._originalSystemState = this.projectDataSource.currentProject.clone();
        }
    }

    onShareSnapshotRequest() {
        // if this is user project just generate url
        if (!this.projectDataSource.currentProject.ownedBySe) {
            const response = {
                hasUnsavedChanges: !this.projectDataSource.currentProject.equals(this._originalSystemState),
                snapshot: {
                    viewCode: this.projectDataSource.currentProject.viewCode,
                    title: this.projectDataSource.currentProject.title,
                    projectThumbUrl: this.projectDataSource.currentProject.projectThumbUrl,
                    ownedBySe: this.projectDataSource.currentProject.ownedBySe,
                    projectType: this.projectDataSource.currentProject.projectType,
                },
            };
            this.bus.emit('SHARE_SNAPSHOT_LOAD_SUCCESS', response);
            return;
        }
        // if project is not owned by SE always create new instance
        const onError = error => {
            this.bus.emit('SHARE_SNAPSHOT_LOAD_ERROR', {
                level: Errors.CRITICAL,
                originalError: error,
                additionalInfo: 'Failed on snapshot.',
            });
            this.bus.emit('HIDE_OVERLAY_REQUEST');
        };
        this.bus.emit('DISPLAY_OVERLAY_REQUEST', { message: 'Please wait...', classNames: 'overlay--see-through' });
        if (this.hasStateChanged()) {
            this._lastSystemState = this.projectDataSource.currentProject.clone();
            this._projectXML = this.projectDataSource.currentProject.toXML();
            if (this.projectDataSource.currentProject.projectThumbUrl) {
                this.shareDataSource.getProjectThumbnail(this.projectDataSource.currentProject.projectThumbUrl).then(thumbnail => {
                    this._projectThumbUrl = this.projectDataSource.currentProject.projectThumbUrl;
                    this._thumbnail = thumbnail;
                    this._shareSnapshot(onError);
                }).catch(onError);
            } else {
                this.shareDataSource.getProjectThumbnail(`${AppConfig.constants.assetsBaseURL}/icons/no_image.png`).then(thumbnail => {
                    this._thumbnail = thumbnail;
                    this._shareSnapshot(onError);
                }).catch(onError);
            }
        } else {
            this._shareSnapshot(onError);
        }
    }

    _shareSnapshot(onError) {
        const shareSnapshotRequest = {
            thumbnail: this._thumbnail,
            projectXML: this._projectXML,
            currentProject: this.projectDataSource.currentProject,
            currentUser: this.userInfoDataSource.currentUser || { userId: -1 }, // in case of embedded application, there is no user session
        };
        this.shareDataSource.shareSnapshot(shareSnapshotRequest)
            .then(response => {
                response.snapshot.projectThumbUrl = response.snapshot.projectThumbUrl ? response.snapshot.projectThumbUrl : this._projectThumbUrl;
                this.bus.emit('SHARE_SNAPSHOT_LOAD_SUCCESS', response);
                this.bus.emit('HIDE_OVERLAY_REQUEST');
            }).catch(onError);
    }

    onDeactivate() {
        this.unbindGluBusEvents();
    }
}
export default ShareController;
