/* eslint class-methods-use-this: 0 */

class Polyline {
    constructor(values) {
        if (values) {
            Object.keys(values).forEach(k => {
                if (this.constructor.prototype.hasOwnProperty(k)) {
                    this[k] = values[k];
                }
            });
        }
    }

    get type() {
        return 'Polyline';
    }

    get id() {
        return this._id;
    }

    set id(id) {
        this._id = id;
    }

    get strokeColor() {
        return this._strokeColor;
    }

    set strokeColor(strokeColor) {
        this._strokeColor = strokeColor;
    }

    get strokeWeight() {
        return this._strokeWeight;
    }

    set strokeWeight(strokeWeight) {
        this._strokeWeight = strokeWeight;
    }

    get opacity() {
        return this._opacity;
    }

    set opacity(opacity) {
        this._opacity = opacity;
    }

    get title() {
        return this._title;
    }

    set title(title) {
        this._title = title;
    }

    get createdAtZoomLevel() {
        return this._createdAtZoomLevel;
    }

    set createdAtZoomLevel(createdAtZoomLevel) {
        this._createdAtZoomLevel = createdAtZoomLevel;
    }

    get minZoom() {
        return this._minZoom;
    }

    set minZoom(minZoom) {
        this._minZoom = minZoom;
    }

    get maxZoom() {
        return this._maxZoom;
    }

    set maxZoom(maxZoom) {
        this._maxZoom = maxZoom;
    }

    get description() {
        return this._description;
    }

    set description(description) {
        this._description = description;
    }

    get coordinates() {
        return this._coordinates;
    }

    set coordinates(coordinates) {
        this._coordinates = coordinates;
    }

    get legendOrder() {
        return this._legendOrder;
    }

    set legendOrder(legendOrder) {
        this._legendOrder = legendOrder;
    }

    get includedInLegend() {
        return this._includedInLegend;
    }

    set includedInLegend(includedInLegend) {
        this._includedInLegend = includedInLegend;
    }

    get searchId() {
        return this._searchId;
    }

    set searchId(searchId) {
        this._searchId = searchId;
    }

    equals(that) {
        return (this.id === that.id &&
        this.type === that.type &&
        this.strokeColor === that.strokeColor &&
        this.strokeWeight === that.strokeWeight &&
        this.opacity === that.opacity &&
        this.title === that.title &&
        this.createdAtZoomLevel === that.createdAtZoomLevel &&
        this.minZoom === that.minZoom &&
        this.maxZoom === that.maxZoom &&
        this.includedInLegend === that.includedInLegend &&
        this.legendOrder === that.legendOrder &&
        this.description === that.description &&
        this.searchId === that.searchId &&
        this.coordinates === that.coordinates);
    }

    clone() {
        return new Polyline({
            id: `${this.id}`,
            strokeColor: this.strokeColor,
            strokeWeight: this.strokeWeight,
            opacity: this.opacity,
            title: this.title,
            createdAtZoomLevel: this.createdAtZoomLevel,
            minZoom: this.minZoom,
            maxZoom: this.maxZoom,
            includedInLegend: this.includedInLegend,
            description: this.description,
            coordinates: this.coordinates,
            legendOrder: this.legendOrder,
            searchId: this.searchId,
        });
    }

    toJSON() {
        return {
            id: `${this.id}`,
            type: this.type,
            strokeColor: this.strokeColor,
            strokeWeight: this.strokeWeight,
            opacity: this.opacity,
            title: this.title,
            createdAtZoomLevel: this.createdAtZoomLevel,
            minZoom: this.minZoom,
            maxZoom: this.maxZoom,
            includedInLegend: this.includedInLegend,
            description: this.description,
            coordinates: this.coordinates,
            legendOrder: this.legendOrder,
            searchId: this.searchId,
        };
    }
}

export default Polyline;
