import React from 'react';
import BusComponent from '../components/BusComponent';

export default function withVariableSelectionMetadataSurvey(WrappedComponent) {
    return class extends BusComponent {
        constructor(props, context) {
            super(props, context);
            this.state = {
                /** @type {import '../objects/MetaSurvey'.default} */
                survey: undefined,
            };
        }

        componentDidMount() {
            this.bindGluBusEvents({
                VARIABLE_SELECTION_METADATA_INFO: this.onMetadataRetrieved,
                MAP_NEW_DATA_THEME_APPLIED: this.onMapNewDataThemeApplied,
            });

            const { mapInstance } = this.props;
            this.emit('VARIABLE_SELECTION_METADATA_INFO_REQUEST', {
                source: this,
                dataDictionaryInfo: false,
                mapInstanceId: mapInstance.id,
                variableSelectionItems:
                    mapInstance.dataTheme.variableSelection.items,
            });
        }

        componentWillUnmount() {
            this.unbindGluBusEvents();
        }

        onMapNewDataThemeApplied = () => {
            // request new metadata when data theme is changed
            const { mapInstance } = this.props;
            this.emit('VARIABLE_SELECTION_METADATA_INFO_REQUEST', {
                source: this,
                dataDictionaryInfo: false,
                mapInstanceId: mapInstance.id,
                variableSelectionItems:
                    mapInstance.dataTheme.variableSelection.items,
            });
        };

        /**
         *
         * @param {object} param0
         * @param {BusComponent} param0.source
         */
        onMetadataRetrieved = ({ metadataSelection, source }) => {
            if (source === this) {
                this.setState({ survey: metadataSelection.survey });
            }
        };

        render() {
            const { survey } = this.state;

            if (!survey) {
                return null;
            }

            return <WrappedComponent survey={survey} {...this.props} />;
        }
    };
}
