class MetaGeoType {

    // eslint-disable-next-line
    get name() {
        return this._name;
    }

    set name(name) {
        this._name = name;
    }

    get pluralName() {
        return this._pluralName;
    }

    set pluralName(pluralName) {
        this._pluralName = pluralName;
    }

    get label() {
        return this._label;
    }

    set label(label) {
        this._label = label;
    }

    get indent() {
        return this._indent;
    }

    set indent(indent) {
        this._indent = indent;
    }

    get summaryLevel() {
        return this._summaryLevel;
    }

    set summaryLevel(summaryLevel) {
        this._summaryLevel = summaryLevel;
    }

    get fipsCodeFieldName() {
        return this._fipsCodeFieldName;
    }

    set fipsCodeFieldName(fipsCodeFieldName) {
        this._fipsCodeFieldName = fipsCodeFieldName;
    }

    get fipsCodeLength() {
        return this._fipsCodeLength;
    }

    set fipsCodeLength(fipsCodeLength) {
        this._fipsCodeLength = fipsCodeLength;
    }

    get fipsCodeLengthPartial() {
        return this._fipsCodeLengthPartial;
    }

    set fipsCodeLengthPartial(fipsCodeLengthPartial) {
        this._fipsCodeLengthPartial = fipsCodeLengthPartial;
    }

    get index() {
        return this._index;
    }

    set index(index) {
        this._index = index;
    }

    get survey() {
        return this._survey;
    }

    set survey(survey) {
        this._survey = survey;
    }

    toJSON() {
        return {
            name: this.name,
            pluralName: this.pluralName,
            label: this.label,
            indent: this.indent,
            summaryLevel: this.summaryLevel,
            fipsCodeFieldName: this.fipsCodeFieldName,
            fipsCodeLength: this.fipsCodeLength,
            fipsCodeLengthPartial: this.fipsCodeLengthPartial,
        };
    }
}

export default MetaGeoType;
