//      

const ajax = require('../util/ajax');
const vt = require('@mapbox/vector-tile');
const Protobuf = require('pbf');
const WorkerTile = require('./worker_tile');
const util = require('../util/util');
const dragonflySource = require('./dragonfly_source'); //!

             
                 
                         
                       
                   
                         
                                 

                                       
                                                              
                                                

                                    
                           
                         
                  
                       
  

/**
 * @callback LoadVectorDataCallback
 * @param error
 * @param vectorTile
 * @private
 */
                                                                     

                                         
                                                                                                                  

/**
 * @private
 */
function loadVectorTile(params                      , callback                        ) {
    if (params.request && params.request.url === undefined) return; //!
    const xhr = ajax.getArrayBuffer(params.request, (err, response) => {
        if (err) {
            if (err.status === 400) callback(`URL is invalid or tiles are unavailable. URL: ${params.request && params.request.url}`);
            else callback(err);
        } else if (response) {
            callback(null, {
                vectorTile: new vt.VectorTile(new Protobuf(response.data)),
                rawData: response.data,
                cacheControl: response.cacheControl,
                expires: response.expires
            });
        }
    });
    return () => {
        xhr.abort();
        callback();
    };
}

/**
 * The {@link WorkerSource} implementation that supports {@link VectorTileSource}.
 * This class is designed to be easily reused to support custom source types
 * for data formats that can be parsed/converted into an in-memory VectorTile
 * representation.  To do so, create it with
 * `new VectorTileWorkerSource(actor, styleLayers, customLoadVectorDataFunction)`.
 *
 * @private
 */
class VectorTileWorkerSource                         {
                 
                                
                                   
                                                    
                                                   
                                                        //!
    /**
     * @param [loadVectorData] Optional method for custom loading of a VectorTile
     * object based on parameters passed from the main-thread Source. See
     * {@link VectorTileWorkerSource#loadTile}. The default implementation simply
     * loads the pbf at `params.url`.
     */
    constructor(actor       , layerIndex                 , loadVectorData                 ) {
        this.actor = actor;
        this.layerIndex = layerIndex;
        this.loadVectorData = loadVectorData || loadVectorTile;
        this.loading = {};
        this.loaded = {};
        this.processing = {};//!
    }

    /**
     * Implements {@link WorkerSource#loadTile}. Delegates to
     * {@link VectorTileWorkerSource#loadVectorData} (which by default expects
     * a `params.url` property) for fetching and producing a VectorTile object.
     */
    loadTile(params                      , callback                    , mapId         ) {
        const source = params.source,
            uid = params.uid;
        if (!this.loading[source])
            this.loading[source] = {};

        if (!this.processing[source]) //!
            this.processing[source] = {};//!

        this.abortTile(params); //!

        if (params.request) params.request.url = dragonflySource.url(this.layerIndex._layers, params);  //!
        const workerTile = this.loading[source][uid] = new WorkerTile(params);
        workerTile.abort = this.loadVectorData(params, (err, response) => {
            delete this.loading[source][uid];

            if (err || !response) {
                return callback(err);
            }
            this.parseTile(response, params, callback, mapId);
        });
    }

    /**
     * Implements {@link WorkerSource#reloadTile}.
     */
    reloadTile(params                      , callback                    , mapId         ) { //!
        const loaded = this.loaded[params.source],
            processing = this.processing[params.source], //!
            uid = params.uid; //!

        if (params.request) params.request.url = dragonflySource.url(this.layerIndex._layers, params);//!

        this.abortTile(params);  //!

        if (loaded && loaded[uid]) { //!
            const workerTile = loaded[uid];//!
            workerTile.showCollisionBoxes = params.showCollisionBoxes;
            delete loaded[uid];//!

            if (params.request && workerTile.url === params.request.url) { //!
                this.parseTile(workerTile.data, params, callback, mapId);//!
                return;//!
            }//!

        } else if (processing && processing[uid]) { //!
            const workerTile = processing[uid];//!
            workerTile.showCollisionBoxes = params.showCollisionBoxes;
            workerTile.cancel = true;//!

            if (params.request && params.request.url === workerTile.url) { //!
                this.parseTile(workerTile.data, params, callback, mapId);//!
                return;//!
            } else {
                delete processing[uid];//!
            }
        }//!

        this.loadTile(params, callback, mapId); //!
    }

    /**
     * Implements {@link WorkerSource#abortTile}.
     *
     * @param params
     * @param params.source The id of the source for which we're loading this tile.
     * @param params.uid The UID for this tile.
     */
    abortTile(params                ) {
        const loading = this.loading[params.source],
            uid = params.uid;
        if (loading && loading[uid] && loading[uid].abort) {
            loading[uid].abort();
            delete loading[uid];
        }
    }

    /**
     * Implements {@link WorkerSource#removeTile}.
     *
     * @param params
     * @param params.source The id of the source for which we're loading this tile.
     * @param params.uid The UID for this tile.
     */
    removeTile(params                ) {
        const loaded = this.loaded[params.source],
            uid = params.uid;
        if (loaded && loaded[uid]) {
            delete loaded[uid];
        }
    }

    cancelTile(params                      ) { //!
        const processing  = this.processing[params.source], //!
            uid = params.uid;//!
        if (processing && processing[uid]) { //!
            delete processing[uid];//!
        }//!
    }//!


    parseTile(data                      , params                      , callback                    , mapId         ) { //!
        const source = params.source, //!
            uid = params.uid, //!
            processing = this.processing[params.source];//!

        const workerTile = new WorkerTile(params);//!
        workerTile.data = data;//!
        processing[uid] = workerTile;//!
        // extend worker data with raw data  //!
        const callbackWrapper = (err, result, transferrables) => { //!
            if (err || !result) return callback(err);

            const rawTileData = data.rawData;
            const cacheControl = {};
            if (data.expires) cacheControl.expires = data.expires;
            if (data.cacheControl) cacheControl.cacheControl = data.cacheControl;

            // Not transferring rawTileData because the worker needs to retain its copy.  //!
            callback(null, //!
                util.extend({rawTileData}, result, cacheControl), //!
                transferrables); //!
        }; //!

        workerTile.parse(data, this.layerIndex, this.actor, callbackWrapper, mapId, shouldStop.bind(this), endProc.bind(this));//!

        function shouldStop() { //!
            return !this.processing[source][uid] || workerTile.cancel;//!
        }//!

        function endProc() { //!
            const processing = this.processing[source];//!
            if (processing[uid] && !workerTile.cancel) { //!
                this.loaded[source] = this.loaded[source] || {};//!
                this.loaded[source][uid] = processing[uid];//!
                delete processing[uid];//!
            }
        }//!
    }//!
}


                                                
                                         


module.exports = VectorTileWorkerSource;
