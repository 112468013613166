import MapInstanceHandler from './handler/MapInstanceHandler';
import SearchHandler from './handler/SearchHandler';
import LocationHandler from './handler/LocationHandler';
import UserDataLayersHandler from './handler/UserDataLayersHandler';
import LibraryDataLayersHandler from './handler/LibraryLayersHandler';
import MaskHandler from './handler/MaskHandler';
import InteractivityHandler from './handler/InteractivityHandler';
import ViewHandler from './handler/ViewHandler';
import AnnotationsHandler from './handler/AnnotationsHandler';
import ReportHandler from './handler/ReportHandler';
import SelectionHandler from './handler/SelectionHandler';
import DynamicLabelsContrastHandler from './handler/DynamicLabelsContrastHandler';
import CustomMapMarkersHandler from './handler/CustomMapMarkersHandler';
import CustomMapSelectionHandler from './handler/CustomMapSelectionHandler';
import UserLocationHandler from './handler/UserLocationHandler';
import PointsHandler from './handler/PointsHandler';

class MapHandler {
    constructor(mapViewer, options) {
        this.mapViewer = mapViewer;
        this._options = options;

        this._handlers = {};
        this._handlers.mapInstance = new MapInstanceHandler(this.mapViewer);
        if (this._options.search) this._handlers.search = new SearchHandler(this.mapViewer);
        if (this._options.location) this._handlers.location = new LocationHandler(this.mapViewer);
        if (this._options.libraryData) this._handlers.libraryData = new LibraryDataLayersHandler(this.mapViewer);
        if (this._options.userData) this._handlers.userData = new UserDataLayersHandler(this.mapViewer);
        if (this._options.interactivity) this._handlers.interactivity = new InteractivityHandler(this.mapViewer);
        if (this._options.view) this._handlers.view = new ViewHandler(this.mapViewer);
        if (this._options.annotations) this._handlers.annotations = new AnnotationsHandler(this.mapViewer);
        if (this._options.selection) this._handlers.selection = new SelectionHandler(this.mapViewer);
        if (this._options.report) this._handlers.report = new ReportHandler(this.mapViewer);
        if (this._options.mask) this._handlers.mask = new MaskHandler(this.mapViewer);
        if (this._options.dynamicLabelsContrast) this._handlers.dynamicLabelsContrast = new DynamicLabelsContrastHandler(this.mapViewer);
        if (this._options.customMapMarkers) this._handlers.customMapMarkers = new CustomMapMarkersHandler(this.mapViewer);
        if (this._options.customMapSelection) this._handlers.customMapSelection = new CustomMapSelectionHandler(this.mapViewer);
        if (this._options.userLocation) this._handlers.userLocation = new UserLocationHandler(this.mapViewer);
        this._handlers.points = new PointsHandler(this.mapViewer);
    }

    bind() {
        Object.values(this._handlers).forEach(handler => handler.bind());
    }

    unbind() {
        Object.values(this._handlers).forEach(handler => handler.unbind());
        Object.values(this._handlers).forEach(handler => {
            if (handler.remove) {
                handler.remove();
            }
        });
    }

    update(options) {
        this._options = options;
        // remove handlers
        Object.keys(this._options).filter(key => !this._options[key] && this._handlers[key]).forEach(key => {
            if (key === 'mapInstance') return;
            const handler = this._handlers[key];
            handler.unbind();
            if (handler.remove) {
                handler.remove();
            }
            delete this._handlers[key];
        });

        // add handlers
        Object.keys(this._options).filter(key => this._options[key] && !this._handlers[key]).forEach(key => {
            let handler;
            switch (key) {
            case 'search':
                handler = new SearchHandler(this.mapViewer);
                break;
            case 'location':
                handler = new LocationHandler(this.mapViewer);
                break;
            case 'libraryData':
                handler = new LibraryDataLayersHandler(this.mapViewer);
                break;
            case 'userData':
                handler = new UserDataLayersHandler(this.mapViewer);
                break;
            case 'interactivity':
                handler = new InteractivityHandler(this.mapViewer);
                break;
            case 'view':
                handler = new ViewHandler(this.mapViewer);
                break;
            case 'annotations':
                handler = new AnnotationsHandler(this.mapViewer);
                break;
            case 'selection':
                handler = new SelectionHandler(this.mapViewer);
                break;
            case 'report':
                handler = new ReportHandler(this.mapViewer);
                break;
            case 'mask':
                handler = new MaskHandler(this.mapViewer);
                break;
            case 'dynamicContrastLabels':
                handler = new DynamicLabelsContrastHandler(this.mapViewer);
                break;
            case 'customMapMarkers':
                handler = new CustomMapMarkersHandler(this.mapViewer);
                break;
            case 'customMapSelection':
                handler = new CustomMapSelectionHandler(this.mapViewer);
                break;
            case 'userLocation':
                handler = new UserLocationHandler(this.mapViewer);
                break;
            }
            if (handler) {
                this._handlers[key] = handler;
                handler.bind();
            }
        });
    }

    get mapInstanceHandler() {
        return this._handlers.mapInstance;
    }

    get searchHandler() {
        return this._handlers.search;
    }

    get locationHandler() {
        return this._handlers.location;
    }

    get libraryDataLayersHandler() {
        return this._handlers.libraryData;
    }

    get userDataLayersHandler() {
        return this._handlers.userData;
    }
    
    get interactivityHandler() {
        return this._handlers.interactivity;
    }

    get viewHandler() {
        return this._handlers.view;
    }

    get annotationsHandler() {
        return this._handlers.annotations;
    }

    get selectionHandler() {
        return this._handlers.selection;
    }

    get maskHandler() {
        return this._handlers.mask;
    }

    get reportHandler() {
        return this._handlers.report;
    }

    get dynamicLabelsContrastHandler() {
        return this._handlers.dynamicLabelsContrast;
    }

    get customMapMarkersHandler() {
        return this._handlers.customMapMarkers;
    }

    get customMapSelectionHandler() {
        return this._handlers.customMapSelection;
    }

    get userLocationHandler() {
        return this._handlers.userLocation;
    }
}

export default MapHandler;
