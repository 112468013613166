export const baseLabelSize = 25;

const labelSize = {
    EXTRASMALL: { value: baseLabelSize * 0.65, label: 'Extra small' },
    SMALL: { value: baseLabelSize * 0.85, label: 'Small' },
    MEDIUM: { value: baseLabelSize * 1.1, label: 'Medium' },
    LARGE: { value: baseLabelSize * 1.4, label: 'Large' },
    EXTRALARGE: { value: baseLabelSize * 1.8, label: 'Extra large' },
};


export default labelSize;
