import AppConfig from '../appConfig';
// eslint-disable-next-line
import DataSource from './DataSource';

const DEFAULT_LIGHT_LABEL_STYLE = {
    'text-color': '#fff',
    'text-halo-color': '#000',
    'text-halo-blur': 1,
    'text-halo-width': 0.5,
};

const DEFAULT_DARK_LABEL_STYLE = {
    'text-color': '#000',
    'text-halo-color': '#fff',
    'text-halo-blur': 1,
    'text-halo-width': 0.5,
};

class Map {
    constructor(values) {
        if (values) {
            Object.keys(values).forEach(k => {
                if (this.constructor.prototype.hasOwnProperty(k)) {
                    this[k] = values[k];
                }
            });
        }
    }

    get id() {
        return this._id;
    }

    set id(id) {
        this._id = id;
    }

    get version() {
        return this._version;
    }

    set version(version) {
        this._version = version;
    }

    // eslint-disable-next-line
    get name() {
        return this._name;
    }

    set name(name) {
        this._name = name;
    }

    get sprite() {
        return this._sprite;
    }

    set sprite(sprite) {
        if (
            sprite.toLocaleLowerCase().indexOf('http://') === 0 ||
            sprite.toLocaleLowerCase().indexOf('https://') === 0
        ) {
            this._sprite = sprite;
        } else {
            this._sprite = `${AppConfig.constants.assetsBaseURL}${
                sprite[0] === '/' ? '' : '/'
            }${sprite}`;
        }
    }

    get glyphs() {
        return this._glyphs;
    }

    set glyphs(glyphs) {
        if (
            glyphs.toLocaleLowerCase().indexOf('http://') === 0 ||
            glyphs.toLocaleLowerCase().indexOf('https://') === 0 ||
            glyphs.toLocaleLowerCase().indexOf('socex://') === 0
        ) {
            this._glyphs = glyphs;
        } else {
            this._glyphs = `${AppConfig.constants.assetsBaseURL}${
                glyphs[0] === '/' ? '' : '/'
            }${glyphs}`;
        }
    }

    get minZoom() {
        return this._minZoom === undefined ? 0 : this._minZoom;
    }

    set minZoom(minZoom) {
        this._minZoom = minZoom;
    }

    get maxZoom() {
        return this._maxZoom === undefined ? 20 : this._maxZoom;
    }

    set maxZoom(maxZoom) {
        this._maxZoom = maxZoom;
    }

    get backgroundColor() {
        return this._backgroundColor;
    }

    set backgroundColor(backgroundColor) {
        this._backgroundColor = backgroundColor;
    }

    get colorPalettesURL() {
        return this._colorPalettesURL;
    }

    set colorPalettesURL(colorPalettesURL) {
        this._colorPalettesURL = colorPalettesURL;
    }

    get surveyName() {
        return this._surveyName;
    }

    set surveyName(surveyName) {
        this._surveyName = surveyName;
    }

    get groupMetadataGuid() {
        return this._groupMetadataGuid;
    }

    set groupMetadataGuid(groupMetadataGuid) {
        this._groupMetadataGuid = groupMetadataGuid;
    }

    get colorPalettes() {
        return this._colorPalettes;
    }

    set colorPalettes(colorPalettes) {
        this._colorPalettes = colorPalettes;
    }

    get layers() {
        return this._layers;
    }

    set layers(layers) {
        this._layers = layers;
    }

    get dragonflySources() {
        return this._dragonflySources;
    }

    set dragonflySources(dragonflySources) {
        this._dragonflySources = dragonflySources;
    }

    /** @type {Object.<string, DataSource>} */
    get dataSources() {
        return this._dataSources;
    }

    set dataSources(dataSources) {
        this._dataSources = dataSources;
    }

    get summaryLevels() {
        if (!this._summaryLevels) {
            this._summaryLevels = Object.keys(this.dataSources).map(
                id => this.dataSources[id].summaryLevel,
            );
        }
        return this._summaryLevels;
    }

    get layersAsArray() {
        if (this.layers) {
            return Object.keys(this.layers).map(l => this.layers[l]);
        }
        return [];
    }

    get satelliteLayer() {
        if (this._satelliteLayer === undefined) {
            this._satelliteLayer = this.layers.find(
                dl => dl.metadata.socialexplorer['layer-type'] === 'satellite',
            );
        }
        return this._satelliteLayer;
    }

    get satelliteLayerIdx() {
        if (this._satelliteLayerIdx === undefined) {
            this._satelliteLayerIdx = this.layers.indexOf(this.satelliteLayer);
        }
        return this._satelliteLayerIdx;
    }

    get streetsLayer() {
        if (this._streetsLayer === undefined) {
            this._streetsLayer = this.layers.find(
                layer =>
                    layer.metadata &&
                    layer.metadata.socialexplorer &&
                    layer.metadata.socialexplorer['layer-type'] === 'streets',
            );
        }
        return this._streetsLayer;
    }

    get streetsLayerIdx() {
        if (this._streetsLayerIdx === undefined) {
            this._streetsLayerIdx = this.layers.indexOf(this.streetsLayer);
        }
        return this._streetsLayerIdx;
    }

    get dataPlaceholder() {
        if (this._dataPlaceholder === undefined) {
            this._dataPlaceholder = this.layers.find(
                l => l.type === 'placeholder',
            );
        }
        return this._dataPlaceholder;
    }

    get dataPlaceholderIdx() {
        if (this._dataPlaceholderIdx === undefined) {
            this._dataPlaceholderIdx = this.layers.indexOf(
                this.dataPlaceholder,
            );
        }
        return this._dataPlaceholderIdx;
    }

    get isDynamicLabelsContrastEnabled() {
        return this._isDynamicLabelsContrastEnabled === true;
    }

    set isDynamicLabelsContrastEnabled(isDynamicLabelsContrastEnabled) {
        this._isDynamicLabelsContrastEnabled = isDynamicLabelsContrastEnabled;
    }

    get dynamicLabelsContrastStyles() {
        if (this._dynamicLabelsContrastStyles) {
            return this._dynamicLabelsContrastStyles;
        }

        return {
            light: DEFAULT_LIGHT_LABEL_STYLE,
            dark: DEFAULT_DARK_LABEL_STYLE,
        };
    }

    set dynamicLabelsContrastStyles(dynamicLabelsContrastStyles) {
        this._dynamicLabelsContrastStyles = {
            light:
                dynamicLabelsContrastStyles.light || DEFAULT_LIGHT_LABEL_STYLE,
            dark: dynamicLabelsContrastStyles.dark || DEFAULT_DARK_LABEL_STYLE,
        };
    }

    /** @type {string | undefined} */
    get reportMinSummaryLevel() {
        // In case of missing information on minimum SummaryLevelId, we are taking
        // the most bottom summary level - the one with largest maxzoom.
        // Mind the fact that maxzoom by default mapbox standard is 22
        // This is however just a workaround since this data should ALWAYS be populated
        if (!this._reportMinSummaryLevel) {
            const minSL = this.summaryLevels
                .map(sl => ({
                    ...sl,
                    maxzoom: sl.maxzoom || 22,
                }))
                .sort((a, b) => b.maxzoom - a.maxzoom)[0];

            return minSL.id;
        }
        return this._reportMinSummaryLevel;
    }

    set reportMinSummaryLevel(reportMinSummaryLevel) {
        this._reportMinSummaryLevel = reportMinSummaryLevel;
    }

    getActiveDataset(summaryLevelId, surveyName, datasetAbbreviation) {
        const dataSource = this.getDataSourceForSummaryLevelId(summaryLevelId);
        if (dataSource) {
            return dataSource.findDataset(surveyName, datasetAbbreviation);
        }
        return undefined;
    }

    /** @param {string} summaryLevelId */
    getDataSourceForSummaryLevelId(summaryLevelId) {
        return Object.values(this.dataSources).find(
            ds => ds.summaryLevel && ds.summaryLevel.id === summaryLevelId,
        );
    }

    getSummaryLevelForDataSourceId(dataSourceId) {
        return this.dataSources[dataSourceId]
            ? this.dataSources[dataSourceId].summaryLevel
            : undefined;
    }

    getSummaryLevelOnZoom(zoom) {
        return this.summaryLevels.find(
            sl => sl.minzoom <= zoom && sl.maxzoom > zoom,
        );
    }

    nestedSummaryLevels(summaryLevel) {
        if (!summaryLevel) return [];
        return this.summaryLevels.filter(
            sl =>
                sl.defaultMinZoom < this.maxZoom &&
                sl.defaultMinZoom > summaryLevel.defaultMinZoom,
        );
    }

    hasDataset(surveyName, datasetAbbrevation) {
        const sName = surveyName.toLowerCase();
        const dAbbrevation = datasetAbbrevation.toLowerCase();
        return (
            this.layersAsArray.find(
                layer =>
                    layer.dataSource &&
                    layer.dataSource.datasetsAsArray.find(
                        dataset =>
                            dataset.surveyName.toLowerCase() === sName &&
                            dataset.datasetAbbreviation.toLowerCase() ===
                                dAbbrevation,
                    ) !== undefined,
            ) !== undefined
        );
    }

    hasSurvey(surveyName) {
        const sName = surveyName.toLowerCase();
        return (
            this.layersAsArray.find(
                layer =>
                    layer.dataSource &&
                    layer.dataSource.datasetsAsArray.find(
                        dataset => dataset.surveyName.toLowerCase() === sName,
                    ) !== undefined,
            ) !== undefined
        );
    }

    toJSON() {
        return {
            id: this.id,
            version: this.version,
            name: this.name,
            sprite: this.sprite,
            glyphs: this.glyphs,
            minZoom: this.minZoom,
            maxZoom: this.maxZoom,
            backgroundColor: this.backgroundColor,
            colorPalettesURL: this.colorPalettesURL,
            surveyName: this.surveyName,
            groupMetadataGuid: this.groupMetadataGuid,
            colorPalettes: this.colorPalettes,
            layers: this.layers,
            dragonflySources: this.dragonflySources,
            dataSources: this.dataSources,
            summaryLevels: this.summaryLevels,
            dynamicLabelsContrastStyles: this.dynamicLabelsContrastStyles,
            isDynamicLabelsContrastEnabled: this.isDynamicLabelsContrastEnabled,
        };
    }

    clone() {
        return new Map({
            id: this.id,
            version: this.version,
            name: this.name,
            sprite: this.sprite,
            glyphs: this.glyphs,
            minZoom: this.minZoom,
            maxZoom: this.maxZoom,
            backgroundColor: this.backgroundColor,
            colorPalettesURL: this.colorPalettesURL,
            surveyName: this.surveyName,
            groupMetadataGuid: this.groupMetadataGuid,
            colorPalettes: this.colorPalettes,
            dynamicLabelsContrastStyles: this.dynamicLabelsContrastStyles,
            isDynamicLabelsContrastEnabled: this.isDynamicLabelsContrastEnabled,
            layers: this.layers
                ? this.layers.map(l => JSON.parse(JSON.stringify(l)))
                : undefined,
            dragonflySources: this.dragonflySources
                ? JSON.parse(JSON.stringify(this.dragonflySources))
                : undefined,
            dataSources: this.dataSources
                ? Object.keys(this.dataSources)
                      .map(id => this.dataSources[id])
                      .reduce((dataSources, ds) => {
                          dataSources[ds.id] = ds.clone();
                          return dataSources;
                      }, {})
                : undefined,
        });
    }
}

export default Map;
