class DataFilterBaseVariable {
    constructor(values) {
        if (values) {
            Object.keys(values).forEach(k => {
                if (this.constructor.prototype.hasOwnProperty(k)) {
                    this[k] = values[k];
                }
            });
        }
        this._values = values;
    }

    get uuid() {
        return this._uuid;
    }

    set uuid(uuid) {
        this._uuid = uuid;
    }

    get label() {
        return this._label;
    }

    set label(label) {
        this._label = label;
    }

    get qLabel() {
        return this._qLabel;
    }

    set qLabel(qLabel) {
        this._qLabel = qLabel;
    }

    equals(that) {
        return this.uuid === that.uuid
            && this.label === that.label
            && this.qLabel === that.qLabel;
    }

    clone() {
        return new DataFilterBaseVariable({
            uuid: this.uuid,
            label: this.label,
            qLabel: this.qLabel,
        });
    }

    toJSON() {
        return {
            uuid: this.uuid,
            label: this.label,
            qLabel: this.qLabel,
        };
    }
}

export default DataFilterBaseVariable;
