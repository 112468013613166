import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

const Placeholder = props => (<div className="map-annotator-placeholder flex-it column center flex-start flex-item">
    <h2 className="map-annotator-placeholder__title">
        {props.intl.formatMessage({ id: 'dataBrowser.annotateMap' })}
    </h2>
    <div className="map-annotator-placeholder__description">
        {props.intl.formatMessage({ id: 'dataBrowser.useDrawingToolsToDraw' })}
    </div>
    <div className="map-annotator-placeholder__graphics" />
</div>);

Placeholder.propTypes = {
    intl: PropTypes.object.isRequired,
};
Placeholder.defaultProps = {};

export default injectIntl(Placeholder);
