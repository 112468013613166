import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Tooltip from '../../Tooltip';
import Key from '../../../enums/Key';

class YearSelectionTab extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            infoPopupOpen: false,
        };
    }

    onTabClick = () => {
        if (!this.props.active) {
            this.props.onClick();
        }
    };

    onTabKeyDown = e => {
        if (e.keyCode === Key.ENTER) {
            this.onTabClick();
        }
    }

    onInfoIconClick = e => {
        this.props.onInfoClick();
        e.stopPropagation();
    };

    render() {
        const { title, active, infoSelected, onInfoClick } = this.props;

        const classes = classNames('year-selection__tab', {
            'year-selection__tab--active': active,
        });

        const iconClasses = classNames('year-selection__tab-icon material-icons', {
            hidden: !onInfoClick,
            selected: infoSelected,
        });

        return (
            <div
                className={classes}
                tabIndex="0"
                onClick={this.onTabClick}
                onKeyDown={this.onTabKeyDown}
            >
                <div className="noselect">{title}</div>
                <Tooltip
                    placement="top"
                    mouseEnterDelay={0.5}
                    mouseLeaveDelay={0}
                    overlay="Info"
                >
                    <i className={iconClasses} onClick={this.onInfoIconClick}>error_outline</i>
                </Tooltip>
            </div>
        );
    }
}

YearSelectionTab.propTypes = {
    title: PropTypes.string.isRequired,
    active: PropTypes.bool,
    infoSelected: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    onInfoClick: PropTypes.func,
};

YearSelectionTab.defaultProps = {
    active: false,
    infoSelected: false,
    onInfoClick: undefined,
};

export default YearSelectionTab;
