import React from 'react';
import PropTypes from 'prop-types';

import RecentSearchListItem from './RecentSearchListItem';

const RecentSearchList = props => {
    const { items } = props;
    if (!items || !items.length) return null;

    return (
        <div className="search-results">
            <div className="search-results__title">Recent searches</div>
            <ul className="search-results__list">
                {items.map(item => (
                    <RecentSearchListItem
                        key={item}
                        value={item}
                        onClick={props.onSelect}
                    />
                ))}
            </ul>
        </div>
    );
};

RecentSearchList.propTypes = {
    onSelect: PropTypes.func.isRequired,
    items: PropTypes.array,
};

RecentSearchList.defaultProps = {
    items: undefined,
};

export default RecentSearchList;
