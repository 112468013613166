import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const LocationAnalysisValueButton = ({ value, selection, onSelectionChange, analysisTypeUnit }) => (
    <button
        className={classNames('btn-chip', 'location-analysis__btn',
            { 'location-analysis__btn--active': selection.has(value) }
        )}
        onClick={() => onSelectionChange(value)}
    >
        {value} {analysisTypeUnit}
    </button>
);

LocationAnalysisValueButton.propTypes = {
    value: PropTypes.number.isRequired,
    selection: PropTypes.object.isRequired,
    onSelectionChange: PropTypes.func.isRequired,
    analysisTypeUnit: PropTypes.string.isRequired,
};

export default LocationAnalysisValueButton;
