class DragonflySourceLayerDataset {
    constructor(id, columns) {
        this._id = id;
        this._columns = columns || [];
    }

    push(column) {
        if (this._columns.indexOf(column) === -1) {
            this.columns.push(column);
        }

        return this;
    }

    get id() {
        return this._id;
    }

    get columns() {
        return this._columns;
    }

    merge(other) {
        other.columns.forEach(column => {
            this.push(column);
        });

        return this;
    }

    remove(other) {
        const newColumns = [];
        this._columns.forEach(column => {
            const columnIdx = other.columns.indexOf(column);
            if (columnIdx === -1) newColumns.push(column);
        });
        this._columns = newColumns;
        return this;
    }

    clone() {
        return new DragonflySourceLayerDataset(this._id, this._columns.map(c => c));
    }

    equals(that) {
        return (
            this.id === that.id &&
            this._columns.length === that.columns.length &&
            this._columns.every((c, i) => c === that._columns[i])
        );
    }

    toJSON() {
        return {
            datasetId: this.id,
            columns: this._columns,
        };
    }
}

export default DragonflySourceLayerDataset;
