'use strict';var ref = require('../types');
var BooleanType = ref.BooleanType;
var Case = function Case(type, branches, otherwise) {
    this.type = type;
    this.branches = branches;
    this.otherwise = otherwise;
};
Case.parse = function parse(args, context) {
    if (args.length < 4) {
        return context.error('Expected at least 3 arguments, but found only ' + (args.length - 1) + '.');
    }
    if (args.length % 2 !== 0) {
        return context.error('Expected an odd number of arguments.');
    }
    var outputType;
    if (context.expectedType && context.expectedType.kind !== 'value') {
        outputType = context.expectedType;
    }
    var branches = [];
    for (var i = 1; i < args.length - 1; i += 2) {
        var test = context.parse(args[i], i, BooleanType);
        if (!test) {
            return null;
        }
        var result = context.parse(args[i + 1], i + 1, outputType);
        if (!result) {
            return null;
        }
        branches.push([
            test,
            result
        ]);
        outputType = outputType || result.type;
    }
    var otherwise = context.parse(args[args.length - 1], args.length - 1, outputType);
    if (!otherwise) {
        return null;
    }
    return new Case(outputType, branches, otherwise);
};
Case.prototype.evaluate = function evaluate(ctx) {
    var this$1 = this;
    for (var i = 0, list = this$1.branches; i < list.length; i += 1) {
        var ref = list[i];
        var test = ref[0];
        var expression = ref[1];
        if (test.evaluate(ctx)) {
            return expression.evaluate(ctx);
        }
    }
    return this.otherwise.evaluate(ctx);
};
Case.prototype.eachChild = function eachChild(fn) {
    var this$1 = this;
    for (var i = 0, list = this$1.branches; i < list.length; i += 1) {
        var ref = list[i];
        var test = ref[0];
        var expression = ref[1];
        fn(test);
        fn(expression);
    }
    fn(this.otherwise);
};
module.exports = Case;