import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import AppConfig from '../../appConfig';
import TableVariableElement from './TableVariableElement';

const CategoryTablesList = ({ noResults, availableTableVariables, onSelectVariable, fieldName, intl }) => {
    // If filter returns no results
    if (noResults) {
        return (
            <div className="category-tables__no-results">
                <img
                    className="category-tables__no-results__illustration"
                    src={`${AppConfig.constants.assetsBaseURL}/svg/search-no-results.svg`}
                    alt={intl.formatMessage({ id: 'searchIllustration' })}
                />
                <h2>{intl.formatMessage({ id: 'noResults' })}</h2>
            </div>
        );
    }

    const tableContent = availableTableVariables.map(table => {
        const variables = Object.values(table.tableVariables).map(v => (
            <TableVariableElement
                key={v.uuid}
                onSelectVariable={() => onSelectVariable(v)}
                variable={v}
                selected={fieldName === v.uuid}
            />
        ));
        return (
            <ol key={table.tableUuid} className="category-tables__table">
                <li className="category-tables__table-name flex-it center flex-fixed">
                    {table.tableDisplayName} - {table.tableTitle}
                    <div className="category-tables__vertical-divider" />
                </li>
                {variables}
            </ol>
        );
    });

    return (
        <div className="category-tables__surveys-list flex-it column stretch vertical-scrollbar">
            <div className="flex-it column grow">
                {tableContent}
            </div>
        </div>
    );
};

CategoryTablesList.propTypes = {
    onSelectVariable: PropTypes.func.isRequired,
    availableTableVariables: PropTypes.array.isRequired,
    fieldName: PropTypes.string,
    noResults: PropTypes.bool.isRequired,
    intl: PropTypes.object.isRequired,
};

CategoryTablesList.defaultProps = {
    fieldName: '',
};

export default injectIntl(CategoryTablesList);
