import BaseHandler from './BaseHandler';
import MarkerAssets from '../assets/MarkerAssets';

class UserDataLayersHandler extends BaseHandler {
    constructor(mapViewer) {
        super(mapViewer);
        this._data = mapViewer.dragonflyMapData.userDataLayersData;
        this._loadedMarkerPngs = {};
        this._cancelAddingMarkers = false;
        this._isMapLoaded = this.map.loaded();

        this.loadMarkersInUse();

        this.setGluBusEvents({
            MAP_APPLY_USER_LAYERS_UPDATE: this.applyUserDataLayersUpdate,
            MAP_LOAD: this.onMapLoad,
            TOGGLE_USER_DATA_UPLOAD_LEGEND_VISIBILITY: this.toggleUserDataUploadLegendVisibility,
        });
    }

    remove() {
        this._cancelAddingMarkers = true;
    }

    onMapLoad(e) {
        if (e.source.id !== this.mapInstanceId) return;
        this._isMapLoaded = true;
    }

    applyUserDataLayersUpdate(e) {
        if (e.mapInstanceId !== this.mapInstanceId || !this._isMapLoaded) return;

        const oldUserDataLayers = this._data.layers;
        const oldUserDataSources = this._data.sources;

        this._data.update();

        const newUserDataLayers = this._data.layers;
        const newUserDataSources = this._data.sources;

        // remove all old data sources from map
        oldUserDataSources.forEach(ds => {
            if (this.map.getSource(ds.id)) {
                this.map.removeSource(ds.id);
            }
        });
        // add new datasources to map
        newUserDataSources.forEach(ds => {
            this.map.addSource(ds.id, ds.source);
        });

        // remove all old layers from map
        oldUserDataLayers.forEach(dl => {
            if (this.map.getLayer(dl.id)) {
                this.map.removeLayer(dl.id);
            }
        });
        // first load markers, then set new layers
        this.loadMarkersInUse();
        // add new layers to map
        newUserDataLayers.forEach(dl => this.map.setLayer(dl));
    }

    loadMarkersInUse() {
        // find all markers which are used in any user data layer
        const markersInUse = this._data._userDataLayers.reduce((prev, curr) => {
            const symbolStyleRules = curr._styleRules.filter(style => style.type === 'symbol');
            return [
                ...prev,
                ...symbolStyleRules.map(s => ({
                    markerPathId: s._markerPathId,
                    markerColor: s._markerColor,
                })),
            ];
        }, []);

        const defaultOptions = { useBackground: true, strokeWidth: 2, outputSize: 44 };

        markersInUse.forEach(marker => {
            const markerId = `${marker.markerPathId}${marker.markerColor}`;
            const markerPng = MarkerAssets.getMarkerPng(markerId, marker, defaultOptions);
            if (this._loadedMarkerPngs[markerId] === undefined) {
                this._loadedMarkerPngs[markerId] = true;
                this.map.addImage(markerId, markerPng, { pixelRatio: 2 });
            }
        });
    }

    toggleUserDataUploadLegendVisibility = ({ id }) => {
        // TODO: This should be handled by the controller
        const userDataLayer = this._mapInstance.userDataLayers.find(dl => dl.id === id);
        if (userDataLayer) {
            userDataLayer.includeInLegend = !userDataLayer.includeInLegend;
        }
    };
}

export default UserDataLayersHandler;
