import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import BusComponent from '../BusComponent';
import classNames from 'classnames';

import VariableSelection from '../../objects/VariableSelection';
import VariableSelectionItem from '../../objects/VariableSelectionItem';
import DataFilterWarningTypes from '../../enums/DataFilterWarningTypes';

class DataBrowserFeatured extends BusComponent {
    onShowMoreClick = elementId => {
        const tableElement = document.getElementById(elementId);
        tableElement.classList.toggle(
            'data-browser-simplified__table--expanded',
        );
    };

    onDataSelectionChange = (variable, newDataSelection) => {
        const { mapInstanceId } = this.props;

        this.bus.once('SURVEYS_LOAD_SUCCESS', () => {
            this.bus.once('DATASET_LOAD_SUCCESS', ({ dataset }) => {
                this.bus.once('TABLE_LOAD_SUCCESS', () => {
                    // Make data change
                    this.emit('DATA_SELECTION_CHANGE', {
                        mapInstanceId,
                        newDataSelection,
                    });
                });

                const metaTable = dataset.getTableByGuid(variable.table_guid);

                this.emit('TABLE_LOAD_REQUEST', {
                    surveyName: variable.survey_name,
                    datasetName: variable.dataset,
                    tableName: metaTable.name,
                    mapInstanceId,
                    source: this,
                });
            });

            this.emit('DATASET_LOAD_REQUEST', {
                surveyName: variable.survey_name,
                datasetName: variable.dataset,
                mapInstanceId,
                source: this,
            });
        });

        this.emit('SURVEYS_LOAD_REQUEST', {
            names: variable.survey_name,
            source: this,
        });
    };

    onVariableSelected = (event, variable) => {
        const {
            hasDataFilter,
            currentMetadataSelection,
            mapInstanceId,
        } = this.props;
        const variableSelection = new VariableSelection();
        const newVariableSelection = new VariableSelectionItem({
            variableGuid: variable.guid,
            tableGuid: variable.table_guid,
            datasetAbbreviation: variable.dataset,
            surveyName: variable.survey_name,
        });
        variableSelection.items = [newVariableSelection];
        const newDataSelection = { variableSelection };

        // If a filter is present and a user selects a variable from a survey that's different
        // from the current variable's survey a warning is shown
        if (
            hasDataFilter &&
            currentMetadataSelection.survey.name !==
                newVariableSelection.surveyName
        ) {
            // Show the popup
            this.emit('SHOW_CLEAR_DATA_FILTER_WARNING', {
                mapInstanceId,
                anchorElement: event.target,
                position: DataFilterWarningTypes.CHANGE_SURVEY_WARNING,
                onConfirm: () =>
                    this.onDataSelectionChange(variable, newDataSelection),
                onCancel: () => {},
            });
        } else {
            this.onDataSelectionChange(variable, newDataSelection);
        }
    };

    getContent = () => this.props.featuredCategoriesData.map((category, index) => {
        const tables = category.tables.map((table, tableIndex) => {
            const variables = table.variables.map(variable => {
                const currentVariableGuid = this.props
                        .currentMetadataSelection.variables[0].uuid;
                const variableClasses = classNames(
                        'data-browser-simplified__variable',
                    {
                        'data-browser-simplified__variable--highlighted':
                            variable.highlighted,
                        'data-browser-simplified__variable--selected':
                                variable.guid === currentVariableGuid,
                    },
                    );

                return (
                    <button
                        key={variable.guid}
                        className={variableClasses}
                        onClick={e => this.onVariableSelected(e, variable)}
                    >
                        <div
                            className={`data-browser-simplified__variable--indent-${variable.indent}`}
                        >
                            {variable.title}
                        </div>
                    </button>
                );
            });

            const allVariablesHighlighted = table.variables.every(
                    variable => variable.highlighted,
                );
            const showButtonClasses = classNames(
                    'data-browser-simplified__table-show-more',
                {
                    hidden: allVariablesHighlighted,
                },
                );

            const tableElementId = `data-browser-${table.title}`;
            const tableKey = `${category.title}-${tableIndex}`;
            return (
                <div
                    key={tableKey}
                    id={tableElementId}
                    className="data-browser-simplified__table"
                >
                    <div className="data-browser-simplified__table-header">
                        <div className="flex-it row center">
                            <img
                                src={table.icon}
                                width="30"
                                height="30"
                                alt={table.title}
                            />
                            <div className="data-browser-simplified__table-header-title">
                                {table.title}
                            </div>
                        </div>
                    </div>
                    <div className="data-browser-simplified__table-variables">
                        {variables}
                    </div>
                    <div className={showButtonClasses}>
                        <button
                            className="btn btn-flat show-more-button"
                            onClick={() =>
                                    this.onShowMoreClick(tableElementId)
                                }
                        >
                            <div className="show-more-label">
                                <FormattedMessage id="showHide.show.more" />
                            </div>
                            <div className="show-less-label">
                                <FormattedMessage id="showHide.show.less" />
                            </div>
                        </button>
                    </div>
                </div>
            );
        });
        const categoryKey = `${category.title}-${index}`;
        return (
            <div
                key={categoryKey}
                className="data-browser-simplified__category"
            >
                <div className="data-browser-simplified__category-title">
                    {category.title}
                </div>
                <div className="data-browser-simplified__category-tables">
                    {tables}
                </div>
            </div>
        );
    })

    render() {
        const { featuredCategoriesData } = this.props;
        let content;
        if (featuredCategoriesData.length === 0) {
            content = (
                <div className="data-browser-simplified__empty">
                    <span>No featured data defined.</span>
                </div>
            );
        } else {
            content = this.getContent();
        }

        return (
            <div className="data-browser-simplified flex-it column stretch data-browser__featured">
                {content}
            </div>
        );
    }
}

DataBrowserFeatured.propTypes = {
    mapInstanceId: PropTypes.string.isRequired,
    hasDataFilter: PropTypes.bool,
    featuredCategoriesData: PropTypes.array.isRequired,
    currentMetadataSelection: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
};

DataBrowserFeatured.defaultProps = {
    hasDataFilter: false,
};

export default injectIntl(DataBrowserFeatured);
