import React from 'react';
import PropTypes from 'prop-types';

class DataCategoryAvailableIn extends React.Component {
    onYearClick = () => {
        if (this.props.onSelectedDataCategoryChange) {
            this.props.onSelectedDataCategoryChange(this.props.dataCategory, this.props.year);
        }
    };

    render() {
        return (
            <button
                className="data-category__year"
                onClick={this.onYearClick}
            >
                {this.props.year}{this.props.lastElement ? '' : ','}&nbsp;
            </button>
        );
    }
}

DataCategoryAvailableIn.propTypes = {
    year: PropTypes.number.isRequired,
    dataCategory: PropTypes.object.isRequired,
    onSelectedDataCategoryChange: PropTypes.func,
    lastElement: PropTypes.bool.isRequired,
};

DataCategoryAvailableIn.defaultProps = {
    onSelectedDataCategoryChange: undefined,
};

export default DataCategoryAvailableIn;
