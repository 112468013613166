import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../Tooltip';

import ShadedColorPaletteVertical from './ShadedColorPaletteVertical';
import PagedBrowser from '../../PagedBrowser';

import Orientation from '../../../enums/Orientation';

import AnnotationLegend from '../../mapAnnotator/legend/AnnotationLegend';
import LayerLibraryLegend from '../../layerLibrary/legend/LayerLibraryLegend';
import DataFilterLegend from '../../dataFilter/DataFilterLegend';
import SatelliteLegend from '../layersLegend/SatelliteLegend';
import OpportunityZonesLegend from '../layersLegend/OpportunityZonesLegend';
import SchoolRankLegend from '../layersLegend/SchoolRankLegend';
import AutoAdjust from '../autoAdjust/AutoAdjust';
import BaseMap from '../../../enums/BaseMapTypes';

const CUSTOM_LIBRARY_LAYER_IDS = ['school_digger', 'opportunity_zones', BaseMap.STREET];

const ShadedAreaLegendDetailed = props => {
    const {
        mapInstance, orientation, variables, renderer, annotationLegend, libraryLegendInfo, onAutoAdjust,
        hasDataFilter, onDataFilterInfoClick, filterInfoOpen, onEditSatelliteDetailsClick, onCloseSatelliteDetails,
    } = props;
    const { nullDataRuleIndex, insufficientDataRuleIndex, rules } = renderer;

    const variable = variables[0];
    const title = mapInstance.dataTheme.title || (variable ? variable.title : '');
    const pages = [];
    const legendHighlighted = rules.some(rule => rule.highlighted);

    const shadedColorPaletteVertical = (
        <div
            key="shaded-color-palette"
            className="vertical-shaded-area-legend flex-it column"
        >
            <Tooltip
                className="legend-variable-title-overlay"
                placement="top"
                mouseEnterDelay={0.5}
                mouseLeaveDelay={0}
                overlay={title}
            >
                <span className="legend-variable-title">
                    {title}
                </span>
            </Tooltip>
            <ShadedColorPaletteVertical
                mapInstanceId={mapInstance.id}
                nullDataRuleIndex={nullDataRuleIndex}
                insufficientDataRuleIndex={insufficientDataRuleIndex}
                rules={rules}
                legendHighlighted={legendHighlighted}
            />
            <AutoAdjust
                mapInstance={mapInstance}
                onAutoAdjust={onAutoAdjust}
                orientation={orientation}
            />
        </div>
    );

    pages.push(shadedColorPaletteVertical);

    pages.push(
        <SatelliteLegend
            key="satellite-legend"
            mapInstance={mapInstance}
            onEdit={onEditSatelliteDetailsClick}
            onClose={onCloseSatelliteDetails}
        />,
    );

    libraryLegendInfo.filter(lg => CUSTOM_LIBRARY_LAYER_IDS.indexOf(lg.id) !== -1)
                     .forEach(layerGroup => {
                         switch (layerGroup.id) {
                         case 'school_digger':
                             pages.push(<SchoolRankLegend key="school-rank-legend" mapInstance={mapInstance} />);
                             break;
                         case 'opportunity_zones':
                             pages.push(
                                 <OpportunityZonesLegend
                                     key="opportunity-zones-legend"
                                     mapInstance={mapInstance}
                                 />
                             );
                             break;
                         }
                     });

    // list of library layers without the custom ones
    const filteredLibraryLayers = libraryLegendInfo && libraryLegendInfo.filter(lg => CUSTOM_LIBRARY_LAYER_IDS.indexOf(lg.id) === -1);

    if (filteredLibraryLayers && filteredLibraryLayers.length > 0) {
        pages.push(
            <LayerLibraryLegend
                key="layer-library-legend"
                className="layer-library-legend"
                libraryGroups={filteredLibraryLayers}
                mapInstanceId={mapInstance.id}
            />
        );
    }

    if (annotationLegend && annotationLegend.visible && annotationLegend.legendItems.length > 0) {
        pages.push(<AnnotationLegend key="annotation-legend" annotationLegend={annotationLegend} />);
    }

    if (hasDataFilter) {
        pages.push(
            <DataFilterLegend
                key="data-filter-legend"
                onDataFilterInfoClick={onDataFilterInfoClick}
                filterInfoOpen={filterInfoOpen}
            />
        );
    }

    return (
        <PagedBrowser>
            {pages}
        </PagedBrowser>
    );
};

ShadedAreaLegendDetailed.propTypes = {
    renderer: PropTypes.object.isRequired,
    orientation: PropTypes.oneOf(Object.keys(Orientation)).isRequired,
    mapInstance: PropTypes.object.isRequired,
    variables: PropTypes.array.isRequired,
    annotationLegend: PropTypes.object,
    libraryLegendInfo: PropTypes.array,
    hasDataFilter: PropTypes.bool,
    onDataFilterInfoClick: PropTypes.func,
    onEditSatelliteDetailsClick: PropTypes.func,
    onCloseSatelliteDetails: PropTypes.func,
    filterInfoOpen: PropTypes.bool,
    onAutoAdjust: PropTypes.func.isRequired,
};

ShadedAreaLegendDetailed.defaultProps = {
    annotationLegend: undefined,
    libraryLegendInfo: undefined,
    hasDataFilter: false,
    onDataFilterInfoClick: undefined,
    onEditSatelliteDetailsClick: undefined,
    onCloseSatelliteDetails: undefined,
    filterInfoOpen: false,
};

export default ShadedAreaLegendDetailed;
