//      

const DOM = require('../../util/dom');
const util = require('../../util/util');
const window = require('../../util/window');

                              
                                                

const inertiaLinearity = 0.3,
    inertiaEasing = util.bezier(0, 0, inertiaLinearity, 1),
    inertiaMaxSpeed = 1400, // px/s
    inertiaDeceleration = 2500;

/**
 * The `DragonflyTouchPanHandler` allows the user to pan the map by clicking and dragging
 * the cursor.
 *
 * @param {Map} map The Mapbox GL JS map to add the handler to.
 */
class DragonflyTouchPanHandler {
              
                     
                      
                     
                
                     
                                     

    constructor(map     ) {
        this._map = map;
        this._el = map.getCanvasContainer();

        util.bindAll([
            '_onDown',
            '_onMove',
            '_onUp',
            '_onTouchEnd',
        ], this);
    }

    /**
     * Returns a Boolean indicating whether the "drag to pan" interaction is enabled.
     *
     * @returns {boolean} `true` if the "drag to pan" interaction is enabled.
     */
    isEnabled() {
        return !!this._enabled;
    }

    /**
     * Returns a Boolean indicating whether the "drag to pan" interaction is active, i.e. currently being used.
     *
     * @returns {boolean} `true` if the "drag to pan" interaction is active.
     */
    isActive() {
        return !!this._active;
    }

    /**
     * Enables the "drag to pan" interaction.
     *
     * @example
     * map.dragPan.enable();
     */
    enable() {
        if (this.isEnabled()) return;
        this._el.classList.add('dragonfly-touch-pan');
        this._el.addEventListener('touchstart', this._onDown);
        this._enabled = true;
    }

    /**
     * Disables the "drag to pan" interaction.
     *
     * @example
     * map.dragPan.disable();
     */
    disable() {
        if (!this.isEnabled()) return;
        this._el.classList.remove('dragonfly-touch-pan');
        this._el.removeEventListener('touchstart', this._onDown);
        this._enabled = false;
    }

    _onDown(e                         ) {
        if (e.touches.length !== 2) return;

        if (this._ignoreEvent(e)) return;
        if (this.isActive()) return;

        window.document.addEventListener('touchmove', this._onMove);
        window.document.addEventListener('touchend', this._onTouchEnd);

        this._active = false;
        this._startPos = this._pos = DOM.mousePos(this._el, e);
        this._inertia = [[Date.now(), this._pos]];
    }

    _onMove(e                         ) {
        if (e.touches.length !== 2) return;

        if (this._ignoreEvent(e)) return;

        if (!this.isActive()) {
            this._active = true;
            this._map.moving = true;
            this._fireEvent('dragstart', e);
            this._fireEvent('movestart', e);
        }

        const pos = DOM.mousePos(this._el, e),
            map = this._map;

        map.stop();
        this._drainInertiaBuffer();
        this._inertia.push([Date.now(), pos]);

        map.transform.setLocationAtPoint(map.transform.pointLocation(this._pos), pos);

        this._fireEvent('drag', e);
        this._fireEvent('move', e);

        this._pos = pos;

        e.preventDefault();
    }

    _onUp(e                                      ) {
        if (!this.isActive()) return;

        this._active = false;
        this._fireEvent('dragend', e);
        this._drainInertiaBuffer();

        const finish = () => {
            this._map.moving = false;
            this._fireEvent('moveend', e);
        };

        const inertia = this._inertia;
        if (inertia.length < 2) {
            finish();
            return;
        }

        const last = inertia[inertia.length - 1],
            first = inertia[0],
            flingOffset = last[1].sub(first[1]),
            flingDuration = (last[0] - first[0]) / 1000;

        if (flingDuration === 0 || last[1].equals(first[1])) {
            finish();
            return;
        }

        // calculate px/s velocity & adjust for increased initial animation speed when easing out
        const velocity = flingOffset.mult(inertiaLinearity / flingDuration);
        let speed = velocity.mag(); // px/s

        if (speed > inertiaMaxSpeed) {
            speed = inertiaMaxSpeed;
            velocity._unit()._mult(speed);
        }

        const duration = speed / (inertiaDeceleration * inertiaLinearity),
            offset = velocity.mult(-duration / 2);

        this._map.panBy(offset, {
            duration: duration * 1000,
            easing: inertiaEasing,
            noMoveStart: true
        }, { originalEvent: e });
    }

    _onTouchEnd(e            ) {
        if (this._ignoreEvent(e)) return;
        this._onUp(e);
        window.document.removeEventListener('touchmove', this._onMove);
        window.document.removeEventListener('touchend', this._onTouchEnd);
    }

    _fireEvent(type        , e       ) {
        return this._map.fire(type, { originalEvent: e });
    }

    _ignoreEvent() {
        const map = this._map;

        if (map.boxZoom && map.boxZoom.isActive()) return true;
        if (map.dragRotate && map.dragRotate.isActive()) return true;
    }

    _drainInertiaBuffer() {
        const inertia = this._inertia,
            now = Date.now(),
            cutoff = 160;   // msec

        while (inertia.length > 0 && now - inertia[0][0] > cutoff) inertia.shift();
    }
}

module.exports = DragonflyTouchPanHandler;
