module.exports={
  "sizeRepresentsValueOf": "Size represents value of",
  "maxBubbleSize": "max. bubble size",
  "bubbleSize": "Bubble size",
  "selectData": "Select Data",
  "autoAdjust": "AUTO ADJUST",
  "shadedAreaAutoAdjustInfo": "Auto adjust calculates the ranges based on the currently visible part of the map. We use 11 cutpoints and the classification method Jenks (Natural breaks).",
  "bubblesAutoAdjustInfo": "Auto adjust calculates the bubble radius factor based on the currently visible part of the map. We adjust the sizes of the bubbles so that they all combined cover 5% of the viewport.",
  "notAvailable": "Not available",
  "changeColorPalette": "Change color palette",
  "editCutpoints": "Edit cutpoints",
  "differenceBubbles": "Difference (bubbles)",
  "percentChangeShadedArea": "Percent change (shaded area)",
  "visualizeAs": "Visualize as"
}
