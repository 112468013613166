class VariableSelectionItem {
    constructor(values) {
        if (values) {
            Object.keys(values).forEach(k => {
                if (this.constructor.prototype.hasOwnProperty(k)) {
                    this[k] = values[k];
                }
            });
        }
    }

    get variableGuid() {
        return this._variableGuid;
    }

    set variableGuid(variableGuid) {
        this._variableGuid = variableGuid;
    }

    get tableGuid() {
        return this._tableGuid;
    }

    set tableGuid(tableGuid) {
        this._tableGuid = tableGuid;
    }

    /** @type {string} */
    get datasetAbbreviation() {
        return this._datasetAbbreviation;
    }

    set datasetAbbreviation(datasetAbbreviation) {
        this._datasetAbbreviation = datasetAbbreviation;
    }

    /** @type {import("../../../types").SurveyCode} */
    get surveyName() {
        return this._surveyName;
    }

    set surveyName(surveyName) {
        this._surveyName = surveyName;
    }

    get qualifiedName() {
        return `${this.surveyName}.${this.datasetAbbreviation}.${this.variableGuid}`;
    }

    get percentBaseVariableGuidUserOverride() {
        // we only store the variable guid because the override percent base variable has to belong to the same dataset & table as the currently selected variable
        return this._percentBaseVariableGuidUserOverride;
    }

    set percentBaseVariableGuidUserOverride(percentBaseVariableGuidUserOverride) {
        this._percentBaseVariableGuidUserOverride = percentBaseVariableGuidUserOverride;
    }

    equals(that) {
        return (this.variableGuid === that.variableGuid &&
            this.tableGuid === that.tableGuid &&
            this.datasetAbbreviation === that.datasetAbbreviation &&
            this.surveyName === that.surveyName &&
            this.percentBaseVariableGuidUserOverride === that.percentBaseVariableGuidUserOverride);
    }

    clone() {
        const variableSelectionItem = new VariableSelectionItem();
        variableSelectionItem.variableGuid = this.variableGuid;
        variableSelectionItem.tableGuid = this.tableGuid;
        variableSelectionItem.datasetAbbreviation = this.datasetAbbreviation;
        variableSelectionItem.surveyName = this.surveyName;
        variableSelectionItem.percentBaseVariableGuidUserOverride = this.percentBaseVariableGuidUserOverride;
        return variableSelectionItem;
    }

    toJSON() {
        return {
            variableGuid: this.variableGuid,
            tableGuid: this.tableGuid,
            datasetAbbreviation: this.datasetAbbreviation,
            surveyName: this.surveyName,
            percentBaseVariableGuidUserOverride: this.percentBaseVariableGuidUserOverride,
        };
    }
}

export default VariableSelectionItem;
