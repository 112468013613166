// @ts-check
import AppConfig from '../appConfig';
import Api from '../apis/Api';
import { pointsWithinPolygon, centroid, point } from '@turf/turf';

/** @param {number} deg */
const deg2rad = deg => deg * (Math.PI / 180);

/**
 *
 * @param {import('mapbox-gl').LngLat | import('../').Point} startPoint
 * @param {import('../').Point} endPoint
 * @returns {string} Returns distance in miles
 */
export const getDistance = (startPoint, endPoint) => {
    const earthRadius = 6371; // Radius of the earth in km
    const distanceLatitude = deg2rad(endPoint.lat - startPoint.lat); // deg2rad is defined below
    const distanceLongitude = deg2rad(endPoint.lng - startPoint.lng);
    const a =
        (Math.sin(distanceLatitude / 2) * Math.sin(distanceLatitude / 2)) +
        (Math.cos(deg2rad(startPoint.lat)) *
            Math.cos(deg2rad(endPoint.lat)) *
            Math.sin(distanceLongitude / 2) *
            Math.sin(distanceLongitude / 2));
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distanceInKm = earthRadius * c; // Distance in km
    // conversion factor
    const factor = 0.621371;
    // calculate miles
    const distanceInMiles = (distanceInKm * factor).toFixed(2); // Distance in miles
    return distanceInMiles;
};

/**
 *
 * @param {import('mapbox-gl').LngLat | import('../').Point} startPoint
 * @param {import('../').Point} endPoint
 * @param {string} profile isochrone profile mapbox/driving | mapbox/walking | mapbox/cycling
 * @returns {Promise<number | null>} Returns time in min or null
 */
export async function getTime(startPoint, endPoint, profile) {
    const coordinates = `${startPoint.lng},${startPoint.lat};${endPoint.lng},${endPoint.lat}`;

    const response = await Api.mapbox.getDirections({
        query: {
            profile,
            coordinates,
            access_token: AppConfig.constants.mapboxAccessToken,
        },
    });

    const data = response.body;
    if (!data.routes.length) {
        return null;
    }
    // We will use the first route
    const route = data.routes[0];

    const durationInMin = Math.round(route.duration / 60);
    return durationInMin;
}


/**
 * The problem we are resolving with this helper method emerged when we discovered that
 * drive/walk/cycle time distance polygon (mapbox isohrones) doesn't have to encapsulate
 * the starting/search point itself. So, in case of manual pin or search result point
 * somewhere in inhabitant area, drive time is going to be related to the nearest road.
 * The following steps (searching for map features under the point) are failing due to
 * the algorithm we use to query rendered features. Basically, the map fits to the polygon
 * bounds but tries to project the point which is in that case outside the map and therefore
 * finds no features underneath.
 * Solution: in case of a search point outside the boundary, try using boundary polygon
 * centroid as a reference instead so we basically overwrite the start point.
 *
 * @param {import('../').Boundary} boundary polygon which will be used
 * @returns {import('../').Boundary} edited boundary
 */
export const getBoundaryWithValidatedSearchPoint = boundary => {
    if (!boundary.properties || !boundary.properties.point) {
        return boundary;
    }
    // The point we've started from, either manual pin, geo search point or geo search polygon centroid
    const { lat, lng } = boundary.properties.point;
    const searchPoint = point([lng, lat]);
    // Check if the start point is inside of drive time isohrone polygon
    const pointsWithinPolygonCollection = pointsWithinPolygon(searchPoint, boundary);
    const isPointOutsidePolygon = !pointsWithinPolygonCollection.features.length;
    if (isPointOutsidePolygon) {
        const boundaryCentroid = centroid(boundary);
        // Setting both [lng, lat] or {lat:,lng:} is legit since the map.project() accept both formats
        return {
            ...boundary,
            properties: {
                ...boundary.properties,
                point: boundaryCentroid.geometry.coordinates,
            },
        };
    }
    return boundary;
};
