import GLU from '../glu2.js/src/index';
import AppConfig from '../appConfig';

class DingoApi extends GLU.Api {
  constructor(endpoint) {
    super(endpoint);

    this.createApiActions(
      {
        name: 'getGroups',
        method: GLU.Api.Get,
        path: '/json/groups.json',
        // this is static json file, no credentials needed.
        // Also, with credentials: true, if run in local
        // environment, it will not load from dingo-maps (localhost:3001)
        // because of CORS issue
        credentials: false,
      },
      {
        name: 'getLibrary',
        method: GLU.Api.Get,
        path: `/json/${AppConfig.constants.layerLibraryFileName}`,
        // this is static json file, no credentials needed.
        // Also, with credentials: true, if run in local
        // environment, it will not load from dingo-maps (localhost:3001)
        // because of CORS issue
        credentials: false,
      },
    );
  }
}

export default DingoApi;
