import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Slider from '../../../form/Slider';

class NumberPropertyEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value.toFixed(0),
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ value: nextProps.value.toFixed(0) });
    }

    onIputChange = event => {
        this.setState({ value: event.target.value });
    }

    onInputBlur = event => {
        if (!event.target.value) {
            this.setState({ value: this.props.value });
            return;
        }
        const { min, max } = this.props;
        let value = parseInt(event.target.value, 10);
        if (value > max) {
            value = max;
        } else if (value < min) {
            value = min;
        }
        this.props.onChange(value);
    }

    render() {
        const { label, value, onChange, min, max, step, unit } = this.props;
        const inputClasses = classNames('annotation-property__value__input', {
            'annotation-property__value__input--padded': unit,
        });
        return (
            <div className="annotation-property annotation-property--with-margin">
                <Slider
                    className="slider--map grow"
                    labelClassName="annotation-property__label"
                    label={label}
                    value={value}
                    onChange={onChange}
                    tooltip
                    min={min}
                    max={max}
                    step={step}
                />
                <div className="annotation-property__value">
                    <input
                        className={inputClasses}
                        value={this.state.value}
                        onChange={this.onIputChange}
                        onBlur={this.onInputBlur}
                        type="number"
                        min={min}
                        max={max}
                    />
                    <span className="annotation-property__value__unit">{unit}</span>
                </div>
            </div>
        );
    }
}

NumberPropertyEditor.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    step: PropTypes.number,
    unit: PropTypes.string,
};
NumberPropertyEditor.defaultProps = {
    step: 1,
    unit: '',
};

export default NumberPropertyEditor;
