import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import BusComponent from '../../../components/BusComponent';
import VisibilityToggle from '../../form/VisibilityToggle';
import AppConfig from '../../../appConfig';

const OPPORTUNITY_ZONES_GROUP_ID = 'opportunity_zones';

class OpportunityZonesLegend extends BusComponent {
    constructor(props, context) {
        super(props, context);

        const opportunityZonesGroup = props.mapInstance.libraryDataLayers.find(
            layerGroup => layerGroup.id === OPPORTUNITY_ZONES_GROUP_ID,
        );
        const opportunityZonesVisible =
            opportunityZonesGroup &&
            opportunityZonesGroup.layers.some(layer => layer.visible);

        this.state = {
            opportunityZonesGroup,
            opportunityZonesVisible,
        };
    }

    componentWillReceiveProps(nextProps) {
        const opportunityZonesGroup = nextProps.mapInstance.libraryDataLayers.find(
            layerGroup => layerGroup.id === OPPORTUNITY_ZONES_GROUP_ID,
        );
        const opportunityZonesVisible =
            opportunityZonesGroup &&
            opportunityZonesGroup.layers.some(layer => layer.visible);

        this.setState({
            opportunityZonesGroup,
            opportunityZonesVisible,
        });
    }

    onButtonClick = () => {
        this.setState(
            {
                opportunityZonesVisible: !this.state.opportunityZonesVisible,
            },
            () => {
                this.emit('UPDATE_LAYER_LIBRARY_GROUP_VISIBILITY', {
                    id: OPPORTUNITY_ZONES_GROUP_ID,
                    visible: this.state.opportunityZonesVisible,
                    mapInstanceId: this.props.mapInstance.id,
                });
            },
        );
    };

    render() {
        const { opportunityZonesVisible, opportunityZonesGroup } = this.state;
        if (!opportunityZonesGroup) return null;
        const contentClasses = classnames('flex-it row grow', {
            disabled: !opportunityZonesVisible,
        });

        const classes = classnames(
            'opportunity-zones-legend',
            this.props.className,
        );

        return (
            <div className={classes}>
                <div className={contentClasses}>
                    <img
                        className="opportunity-zones-legend__image"
                        src={`${AppConfig.constants.assetsBaseURL}/svg/opportunity-zones-icon.svg`}
                        alt={this.props.intl.formatMessage({
                            id: 'dataBrowser.satelliteView',
                        })}
                    />
                    <div className="flex-it center dark-text">
                        Opportunity zones
                    </div>
                </div>
                <div className="flex-it">
                    <VisibilityToggle
                        visible={opportunityZonesVisible}
                        onClick={this.onButtonClick}
                    />
                </div>
            </div>
        );
    }
}

OpportunityZonesLegend.propTypes = {
    className: PropTypes.string,
    mapInstance: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
};

OpportunityZonesLegend.defaultProps = {
    className: '',
};

export default injectIntl(OpportunityZonesLegend);
