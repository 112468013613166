module.exports={
  "displaysDataAsColors": "Displays data as darker or lighter colors corresponding to areas of higher or lower value.",
  "displaysDataAsCircles": "Displays data as circles of varying sizes. The size of each circle corresponds to its value - the higher the value, the larger the circle.",
  "displaysDataAsDots": "Displays data as dots representing a discrete amount of values per dot. The dots are randomly scattered across the area.",
  "colorPalette": "Color palette",
  "newPalette": "New Palette",
  "cutpoints": "Cutpoints",
  "adjustCutpointClasses": "Adjust cutpoint classes to the data visualized on the map.",
  "editCutpoints": "Edit cutpoints",
  "numberOfClasses": "Number of classes",
  "classificationMethod": "Classification method",
  "insufficientBase": "Insufficient base",
  "categoryDefault": "Category (Default)",
  "equalInterval": "Equal interval",
  "arithmeticProgression": "Arithmetic Progression",
  "quantile": "Quantile",
  "naturalBreaksJenks": "Natural breaks (Jenks)",
  "custom": "Custom",
  "changeColorPalette": "Change color palette",
  "flipColors": "Flip colors",
  "projectPalettes": "Project palettes",
  "sequential": "Sequential",
  "diverging": "Diverging",
  "multi": "Multi",
  "createNewPalette": "Create new palette",
  "editColorPalette": "Edit color palette"
}
