import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../../form/Checkbox';
import PopupOptions from './PopupOptions';

const InteractivitySection = props => (
    <div className="flex-it column">
        <div className="point-layer-editor__section point-layer-editor">
            Display popup window with additional information on hover
            <Checkbox
                onCheck={props.onInteractivityFlagChange}
                checked={props.userDataLayer.interactive}
            />
        </div>
        {props.userDataLayer.interactive && (
            <PopupOptions
                userDataLayer={props.userDataLayer}
                onRemovePopupContentColumn={props.onRemovePopupContentColumn}
                onPopupTitleColumnChange={props.onPopupTitleColumnChange}
                onAddPopupContentColumnChange={props.onAddPopupContentColumnChange}
                labelColumns={props.labelColumns}
            />
        )}
    </div>
);

InteractivitySection.propTypes = {
    userDataLayer: PropTypes.object.isRequired,
    onRemovePopupContentColumn: PropTypes.func.isRequired,
    onPopupTitleColumnChange: PropTypes.func.isRequired,
    onInteractivityFlagChange: PropTypes.func.isRequired,
    onAddPopupContentColumnChange: PropTypes.func.isRequired,
    labelColumns: PropTypes.array,
};

InteractivitySection.defaultProps = {
    labelColumns: [],
};

export default InteractivitySection;
