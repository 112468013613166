import React from 'react';
import BusComponent from '../BusComponent';
import AppConfig from '../../appConfig';

class EditProjectErrorPopup extends BusComponent {
    constructor(props, context) {
        super(props, context);
        const error = props.error || {};
        this.state = {
            isSessionTimeoutError: error.isSessionTimeoutError,
            isLoginError: error.isLoginError,
        };
    }

    onLoginClicked = () => {
        this.emit('LOGIN_REQUEST', { checkCount: 30, url: AppConfig.constants.links.login });
    }

    onSignUpClicked = () => {
        this.emit('SIGN_UP_REQUEST', { checkCount: 240, url: AppConfig.constants.links.signUp });
    }

    onExit = () => {
        this.emit('CLOSE_MODAL');
    }

    render() {
        // expired session
        if (this.state.isSessionTimeoutError) {
            return (<div className="edit-project-error">
                <div className="edit-project-error__icon flex-center flex-column">
                    <img
                        src={`${AppConfig.constants.assetsBaseURL}/svg/session-expired.svg`}
                        alt="Error illustration"
                        width="221"
                    />
                </div>
                <div className="edit-project-error__message">
                    <h3>Looks like your session has expired!</h3>
                    <div className="edit-project-error__additional-info padded-10 text-center">
                Please log in before saving your project.
                </div>
                    <div className="edit-project-error__controls flex-it center">
                        <div className="btn-raised" onClick={this.onLoginClicked}>Log In</div>
                    </div>
                </div>
            </div>);
        }
        // not logged
        if (this.state.isLoginError) {
            return (<div className="edit-project-error">
                <div className="edit-project-error__icon flex-center flex-column">
                    <img
                        src={`${AppConfig.constants.assetsBaseURL}/svg/login.svg`}
                        alt="Error illustration"
                        width="221"
                    />
                </div>
                <div className="edit-project-error__message">
                    <h3>You are not logged in!</h3>
                    <div className="edit-project-error__additional-info padded-10 text-center">
                You have to log in if you wish to save your project.
                </div>
                    <div className="edit-project-error__additional-info padded-10 text-center">
                If you do not have an account, you can create one for free by clicking on the Sign up button.
                </div>
                    <div className="edit-project-error__controls flex-it center">
                        <div className="btn-flat" onClick={this.onLoginClicked}>Log In</div>
                        <div className="btn-raised" onClick={this.onSignUpClicked}>Sign Up</div>
                    </div>
                </div>
            </div>);
        }
        // unknown error
        return (<div className="edit-project-error">
            <div className="edit-project-error__icon flex-center flex-column">
                <img
                    src={`${AppConfig.constants.assetsBaseURL}/svg/save_error.svg`}
                    alt="Error illustration"
                    width="221"
                />
            </div>
            <div className="edit-project-error__message">
                <h3>Failed to save</h3>
                <div className="edit-project-error__additional-info padded-10 text-center">
                An unknown error occurred during save.<br /> Please try again.
                </div>
                <div className="edit-project-error__controls flex-center">
                    <div className="btn-raised" onClick={this.onExit}>OK</div>
                </div>
            </div>
        </div>);
    }
}

export default EditProjectErrorPopup;
