// @ts-check
import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import BusComponent from '../BusComponent';

/**
 * @typedef State
 * @property {string} error
 *
 * @extends {BusComponent<any, State>}
 */

class ErrorMessage extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            error: undefined,
        };
    }

    componentDidMount() {
        this.bindGluBusEvents({
            CREATE_REPORT_ERROR: this.onCreateReportError,
            DOWNLOAD_REPORT_ERROR: this.onCreateReportError,
        });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    /** @param {string} error Error message */
    onCreateReportError = error => {
        this.setState({
            error,
        });
    };

    onClose = () => {
        // Sending event without payload, setting the error to undefined
        this.emit('CREATE_REPORT_ERROR');
    };

    render() {
        const { error } = this.state;
        if (!error || !error.message) return null;

        const { intl } = this.props;

        return (
            <div className="location-analysis__error">
                <div className="location-analysis__error-header">
                    <span className="location-analysis__error-title">
                        {intl.formatMessage({ id: 'locationAnalysis.somethingWentWrong' })}
                    </span>
                    <button
                        className="material-icons btn-flat-icon"
                        onClick={this.onClose}
                        aria-label={intl.formatMessage({ id: 'locationAnalysis.closeErrorMessagePanel' })}
                    >
                        close
                    </button>
                </div>
                <div className="location-analysis__error-message">
                    {error.message}
                    <br />
                    {intl.formatMessage({ id: 'locationAnalysis.pleaseChangeTheParameters' })}
                </div>
            </div>
        );
    }
}

ErrorMessage.propTypes = {
    intl: PropTypes.object.isRequired,
};

export default injectIntl(ErrorMessage);
