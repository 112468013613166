// @ts-check
import AppConfig from '../../appConfig';
import uuid from 'node-uuid';
import DragonflySource from '../../objects/DragonflySource';

const SOURCE_ID = 'socialexplorercustomselectionsource';
const DISTANCE_LABLES_SOURCE_ID = 'socialexplorerdistancelabelssource';
const ISOCHRONE_FILL_LAYER_ID = 'custom-selection-layer-isochrone-fill';
const ISOCHRONE_OUTLINE_LAYER_ID_1 =
    'custom-selection-layer-isochrone-outline_1';
const ISOCHRONE_OUTLINE_LAYER_ID_2 =
    'custom-selection-layer-isochrone-outline_2';
const CIRCLE_FILL_LAYER_ID = 'custom-selection-layer-circle-fill';
const CIRCLE_OUTLINE_LAYER_ID_1 = 'custom-selection-layer-circle-outline_1';
const CIRCLE_OUTLINE_LAYER_ID_2 = 'custom-selection-layer-circle-outline_2';
const DISTANCE_LABEL_LAYER_ID = 'custom-selection-distance-label';
const DISTANCE_LABEL_ON_LINE_LAYER_ID =
    'custom-selection-distance-label-on-line';
const COLORS = ['#ED6A5E', '#F6BD4F', '#29CBC5'];
const OUTLINE_COLORS = ['#D24D40', '#DCA24C', '#1CB4AE'];
// Pin source
const PIN_SOURCE_ID = 'location-analysis-pin';
const PIN_LAYER_ID = 'location-analysis-pin-layer';

class CustomMapSelectionData {
    constructor(mapInstance, mapConfig, layers, sources) {
        this._mapConfig = mapConfig;
        this._mapInstance = mapInstance;
        this._dragonflyLayers = layers;
        this._dragonflySources = sources;
        this._renderer = this._mapInstance.dataTheme.rendering[0];
        this._dataHighlightLayer = layers.find(
            dl => dl.metadata.isHighlightLayer,
        );

        // helper layer(s)
        this._customSelectionHelperLayers = [];
        this._customSelectionHelperSourceLayerId = `${this._dataHighlightLayer['auto-source'][0]['source-layer']}p`;
        this._customSelectionHelperSource = undefined;
        this._customSelectionHelperFillLayer = undefined;

        let fields, filter;
        // If filter is present we need to add the filter to the selection helper layer
        // and the filter fields to the selection source layer
        if (this._mapInstance.hasDataFilter) {
            const dataFilter = this._mapInstance.dataFilter;
            ({ filter, fields } = dataFilter.getFormattedFilter());
        }

        this._createSelectionHelperSource(fields);
        this._createSelectionHelperLayer(filter);

        // Add helper layer and source to the map.
        // This layer will be present in the map until we create a separate mode for drive time
        // so we can attach the custom selection handler, data and layers only when needed.
        this._dragonflySources[this._customSelectionHelperSource.id] =
            this._customSelectionHelperSource.toJSON();
        this._customSelectionHelperLayers.forEach(sl =>
            this._dragonflyLayers.push(sl),
        );

        this._customMapSelectionDataSource = {};
        this._distanceLabelsDataSource = {};
        this._customMapSelectionLayers = [];

        // Unify pins for all selected location analysis items
        this._pinSource = undefined;
        this._pinLayer = undefined;
        this._pinPoint = {};

        // Add (if any) saved location analysis source and layers
        if (
            this._mapInstance.isLocationAnalysisActive &&
            this._mapInstance.locationAnalysisItem.isComplete
        ) {
            const { isIsochrone, selectionFeatures, polygonLabelFeatures } =
                this._mapInstance.locationAnalysisItem;

            this.update(isIsochrone, selectionFeatures, polygonLabelFeatures);
        }
        // Add pin layer if location analysis is active
        if (this._mapInstance.isLocationAnalysisActive) {
            this.updatePin(this._mapInstance.locationAnalysisItem);
        }
    }

    get source() {
        return this._customMapSelectionDataSource;
    }

    get distanceLabelsSource() {
        return this._distanceLabelsDataSource;
    }

    get layers() {
        return this._customMapSelectionLayers;
    }

    get helperSource() {
        return this._customSelectionHelperSource;
    }

    get helperSelectionLayers() {
        return this._customSelectionHelperLayers;
    }

    // Remove custom selection layers from dragonflyLayers and source from dragonflySources
    clear() {
        this._dragonflyLayers.slice(0).forEach(dl => {
            if (
                dl.metadata.isCustomSelectionLayer ||
                dl.metadata.isDescriptionLayer
            ) {
                this._dragonflyLayers.splice(
                    this._dragonflyLayers.indexOf(dl),
                    1,
                );
            }
        });

        delete this._dragonflySources[SOURCE_ID];
        delete this._dragonflySources[DISTANCE_LABLES_SOURCE_ID];
    }

    update(isIsochrone, sourceData, labelsSourceData) {
        this.clear();

        this._customMapSelectionDataSource = {};
        this._distanceLabelsDataSource = {};
        this._customMapSelectionLayers = [];

        // Add different colors for each feature (max 3 multi ring options)
        sourceData.features.forEach((feature, i) => {
            feature.properties.color = COLORS[i];
            feature.properties.outlineColor = OUTLINE_COLORS[i];
        });

        this._createCustomSelectionSource(sourceData);
        this._createDistanceLabelsSource(labelsSourceData);
        this._createCustomMapSelectionDragonflyLayers(isIsochrone);

        this._customMapSelectionLayers.forEach(dl =>
            this._dragonflyLayers.push(dl),
        );

        this._dragonflySources[this._customMapSelectionDataSource.id] =
            this._customMapSelectionDataSource.source;

        this._dragonflySources[this._distanceLabelsDataSource.id] =
            this._distanceLabelsDataSource.source;
    }

    applySummaryLevelUpdate() {
        this._customSelectionHelperSourceLayerId = `${this._dataHighlightLayer['auto-source'][0]['source-layer']}p`;
        this._customSelectionHelperLayers.forEach(dl => {
            dl['source-layer'] = this._customSelectionHelperSourceLayerId;
            dl.minzoom = this._dataHighlightLayer.minzoom;
            dl.maxzoom = this._dataHighlightLayer.maxzoom;
            dl.metadata.defaultMinZoom = dl.minzoom;
            dl.metadata.defaultMaxZoom = dl.maxzoom;
        });
    }

    _createSelectionHelperSource(filterFields) {
        this._customSelectionHelperSource = new DragonflySource([
            AppConfig.constants.backends.tiles,
        ]);
        this._customSelectionHelperSource.id = `socialexplorerpointdata-${uuid.v4()}`;
        Object.values(this._mapConfig.dataSources).forEach(dataSource => {
            this._customSelectionHelperSource.createLayer(
                `${dataSource.layerId}p`,
            );
            this._renderer.fieldList.fields.forEach(field => {
                if (field.isComputed || field.fieldName.indexOf('__') !== -1) {
                    return;
                }
                this._customSelectionHelperSource
                    .getLayer(`${dataSource.layerId}p`)
                    .getDataset(
                        dataSource.findDataset(
                            field.surveyName,
                            field.datasetAbbreviation,
                        ).id,
                    )
                    .push(field.fieldName);
            });

            if (filterFields) {
                filterFields.forEach(field => {
                    this._customSelectionHelperSource
                        .getLayer(`${dataSource.layerId}p`)
                        .getDataset(
                            dataSource.findDataset(
                                field.surveyName,
                                field.datasetAbbreviation,
                            ).id,
                        )
                        .push(field.fieldName)
                        .push(field.baseFieldName);
                });
            }

            const dragonflySourceLayer =
                this._customSelectionHelperSource.layers[
                    `${dataSource.layerId}p`
                ];
            Object.keys(dragonflySourceLayer.datasets)
                .map(id => dragonflySourceLayer.datasets[id])
                .forEach(ds => {
                    if (ds.id > 0) {
                        ds.push(dataSource.datasets[ds.id].primaryKeyField);
                    }
                });
        });
    }

    /**
     * @param {{type: 'FeatureCollection', features: import('mapbox-gl').MapboxGeoJSONFeature[] }} sourceData
     */
    _createCustomSelectionSource(sourceData) {
        this._customMapSelectionDataSource = {
            id: SOURCE_ID,
            source: {
                type: 'geojson',
                data: sourceData,
            },
        };
    }

    // Sets the data source for the custom map radius selection distance labels
    // In case of no labels needed, fail-over empty geojson feature collection is set
    /**
     * @param {{type: 'FeatureCollection', features: import('mapbox-gl').MapboxGeoJSONFeature[] }} sourceData
     */
    _createDistanceLabelsSource(
        sourceData = {
            type: 'FeatureCollection',
            features: [],
        },
    ) {
        this._distanceLabelsDataSource = {
            id: DISTANCE_LABLES_SOURCE_ID,
            source: {
                type: 'geojson',
                data: sourceData,
            },
        };
    }

    _createCustomMapSelectionDragonflyLayers(isIsochrone) {
        if (isIsochrone) {
            this._buildIsochroneLayerDefinitions();
        } else {
            this._buildRadiusLayerDefinitions();
        }
        this._buildDistanceLabelsDefinitions();
    }

    _buildIsochroneLayerDefinitions() {
        const customMapSelectionIsochroneLayerFill = {
            id: ISOCHRONE_FILL_LAYER_ID,
            metadata: {
                isCustomSelectionLayer: true,
                socialexplorer: {},
            },
            type: 'fill',
            source: SOURCE_ID,
            paint: {
                'fill-color': '#000',
                'fill-opacity': 0.02,
            },
        };

        const customMapSelectionIsochroneOutlineLayerOuter = {
            id: ISOCHRONE_OUTLINE_LAYER_ID_1,
            metadata: {
                isCustomSelectionLayer: true,
                socialexplorer: {},
            },
            type: 'line',
            source: SOURCE_ID,
            paint: {
                'line-color': [
                    'case',
                    ['has', 'outlineColor'],
                    ['get', 'outlineColor'],
                    '#FFF',
                ],
                'line-opacity': {
                    stops: [
                        [10, 0.8],
                        [16, 0.8],
                        [21, 0.8],
                    ],
                },
                'line-width': {
                    stops: [
                        [10, 4],
                        [16, 6],
                        [21, 7],
                    ],
                },
            },
            layout: {},
        };

        const customMapSelectionIsochroneOutlineLayerInner = {
            id: ISOCHRONE_OUTLINE_LAYER_ID_2,
            metadata: {
                isCustomSelectionLayer: true,
                socialexplorer: {},
            },
            type: 'line',
            source: SOURCE_ID,
            paint: {
                'line-color': [
                    'case',
                    ['has', 'color'],
                    ['get', 'color'],
                    '#FFF',
                ],
                'line-opacity': {
                    stops: [
                        [10, 1],
                        [16, 1],
                        [21, 1],
                    ],
                },
                'line-width': {
                    stops: [
                        [10, 2],
                        [16, 5],
                        [21, 6],
                    ],
                },
            },
            layout: {},
        };

        this._customMapSelectionLayers.push(
            customMapSelectionIsochroneLayerFill,
            customMapSelectionIsochroneOutlineLayerOuter,
            customMapSelectionIsochroneOutlineLayerInner,
        );
    }

    _buildRadiusLayerDefinitions() {
        const customMapSelectionCircleFillLayer = {
            id: CIRCLE_FILL_LAYER_ID,
            metadata: {
                isCustomSelectionLayer: true,
                socialexplorer: {},
            },
            type: 'fill',
            source: SOURCE_ID,
            paint: {
                'fill-color': '#000',
                'fill-opacity': 0.02,
            },
        };

        const customMapSelectionCircleOutlineLayerOuter = {
            id: CIRCLE_OUTLINE_LAYER_ID_1,
            metadata: {
                isCustomSelectionLayer: true,
                socialexplorer: {},
            },
            type: 'line',
            source: SOURCE_ID,
            paint: {
                'line-color': [
                    'case',
                    ['has', 'outlineColor'],
                    ['get', 'outlineColor'],
                    '#FFF',
                ],
                'line-opacity': {
                    stops: [
                        [10, 0.8],
                        [16, 0.8],
                        [21, 0.8],
                    ],
                },
                'line-width': {
                    stops: [
                        [10, 4],
                        [16, 6],
                        [21, 7],
                    ],
                },
            },
            layout: {},
        };

        const customMapSelectionCircleOutlineLayerInner = {
            id: CIRCLE_OUTLINE_LAYER_ID_2,
            metadata: {
                isCustomSelectionLayer: true,
                socialexplorer: {},
            },
            type: 'line',
            source: SOURCE_ID,
            paint: {
                'line-color': [
                    'case',
                    ['has', 'color'],
                    ['get', 'color'],
                    '#FFF',
                ],
                'line-opacity': {
                    stops: [
                        [10, 1],
                        [16, 1],
                        [21, 1],
                    ],
                },
                'line-width': {
                    stops: [
                        [10, 2],
                        [16, 5],
                        [21, 6],
                    ],
                },
            },
            layout: {},
        };

        this._customMapSelectionLayers.push(
            customMapSelectionCircleFillLayer,
            customMapSelectionCircleOutlineLayerOuter,
            customMapSelectionCircleOutlineLayerInner,
        );
    }

    _buildDistanceLabelsDefinitions() {
        const customMapSelectionDistanceLineLabel = {
            id: DISTANCE_LABEL_ON_LINE_LAYER_ID,
            metadata: {
                socialexplorer: {},
                isDescriptionLayer: true,
            },
            type: 'symbol',
            source: DISTANCE_LABLES_SOURCE_ID,
            paint: {
                'text-color': '#000',
                'text-halo-color': '#fff',
                'text-halo-width': 2,
            },
            layout: {
                'text-field': '{label}',
                'text-size': 10,
                'symbol-placement': 'line',
                'symbol-spacing': 500, // Default 250
            },
            minzoom: 13,
            filter: ['==', '$type', 'LineString'],
        };

        const customMapSelectionDistanceLabel = {
            id: DISTANCE_LABEL_LAYER_ID,
            metadata: {
                socialexplorer: {},
                isDescriptionLayer: true,
            },
            type: 'symbol',
            source: DISTANCE_LABLES_SOURCE_ID,
            paint: {
                'text-color': '#000',
            },
            layout: {
                'text-field': '{label}',
                'icon-image': 'distance_label',
                'text-size': 10,
                'icon-padding': 4,
                'icon-allow-overlap': true,
                'text-allow-overlap': true,
                'icon-text-fit': 'width',
                'icon-text-fit-padding': [0, 4, 0, 4],
            },
            filter: ['==', '$type', 'Point'],
        };

        this._customMapSelectionLayers.push(
            customMapSelectionDistanceLineLabel,
            customMapSelectionDistanceLabel,
        );
    }

    _createSelectionHelperLayer(filter) {
        this._customSelectionHelperFillLayer = {
            id: 'helper-selection-layer',
            metadata: {
                isHelperPointLayer: true,
                defaultMinZoom: this._dataHighlightLayer.minzoom,
                defaultMaxZoom: this._dataHighlightLayer.maxzoom,
                socialexplorer: {
                    'layer-group-id':
                        this._mapConfig.dataPlaceholder.metadata.socialexplorer[
                            'layer-group-id'
                        ],
                },
            },
            type: 'fill',
            paint: {
                'fill-opacity': 0,
            },
            layout: {},
            source: this._customSelectionHelperSource.id,
            'source-layer': this._customSelectionHelperSourceLayerId,
        };

        // we need to add filter (if exists) to the selection layer as well otherwise the user will be able
        // to select the geographies that are filtered out
        if (filter) this._customSelectionHelperFillLayer.filter = filter;

        this._customSelectionHelperLayers.push(
            this._customSelectionHelperFillLayer,
        );
    }

    /* Pin handlers */
    /* **************************** */
    /* **************************** */

    get pinSource() {
        return this._pinSource;
    }

    get pinLayer() {
        return this._pinLayer;
    }

    get pinSourceId() {
        return PIN_SOURCE_ID;
    }

    updatePin = locationAnalysisItem => {
        // Handle the pin
        this._buildPin(locationAnalysisItem);
    };

    removePin = () => {
        // Remove from dragonfly layers and sources
        const index = this._dragonflyLayers.findIndex(
            layer => layer.id === PIN_LAYER_ID,
        );
        if (index > -1) {
            this._dragonflyLayers.splice(index, 1);
        }
        delete this._dragonflySources[PIN_SOURCE_ID];

        // Remove pin
        this._pinSource = undefined;
        this._pinLayer = undefined;
        this._pinPoint = {};
    };

    _buildPin = locationAnalysisItem => {
        if (
            locationAnalysisItem.point.lng === this._pinPoint.lng &&
            locationAnalysisItem.point.lat === this._pinPoint.lat
        ) {
            // If the pin point has not been changed, do nothing
            return;
        }

        this._pinPoint = {
            lat: locationAnalysisItem.point.lat,
            lng: locationAnalysisItem.point.lng,
        };
        // First, let's create the geoJson
        const geoJsonPinData = {
            type: 'FeatureCollection',
            features: [
                {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [this._pinPoint.lng, this._pinPoint.lat],
                    },
                    properties: {
                        ids: [locationAnalysisItem.id],
                        id: locationAnalysisItem.id,
                        value: locationAnalysisItem.value,
                        type: locationAnalysisItem.type,
                    },
                },
            ],
        };
        // Creating the pin source
        this._pinSource = {
            type: 'geojson',
            data: geoJsonPinData,
        };

        // Creating the pin layer
        this._pinLayer = {
            id: PIN_LAYER_ID,
            type: 'symbol',
            source: PIN_SOURCE_ID,
            metadata: {
                socialexplorer: {},
                isDescriptionLayer: true,
            },
            filter: ['==', '$type', 'Point'],
            layout: {
                'icon-image': 'custom_pin',
                'icon-offset': [0, 0],
                'icon-anchor': 'bottom',
                'icon-allow-overlap': true,
            },
        };

        // Add to dragonfly layers and sources
        this._dragonflyLayers.push(this._pinLayer);
        this._dragonflySources[PIN_SOURCE_ID] = this._pinSource;
    };

    /* **************************** */
    /* **************************** */
}

export default CustomMapSelectionData;
