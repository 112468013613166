import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Filter from './Filter';
import FilterStatus from '../../enums/FilterStatus';

const FilterList = ({ onAddFilter, filters, mapInstanceId, intl }) => {
    const allFiltersValid = Object.keys(filters).every(filterId =>
                filters[filterId].status === FilterStatus.VALID);
    const currentFilters = Object.keys(filters).map(filterId => (
        <Filter
            key={filterId}
            filterId={filterId}
            filter={filters[filterId]}
            mapInstanceId={mapInstanceId}
        />
        ));
    // List of filters
    return (
        <div className="filters flex-it column no-shrink">
            {currentFilters}
            {allFiltersValid &&
            <div className="filters__add flex-it center">
                <div className="filters__vertical-line" />
                <i className="material-icons">add_circle</i>
                <div className="filter__line-join" />
                <button
                    disabled={!allFiltersValid}
                    className="btn-void flex-it flex-start"
                    onClick={onAddFilter}
                >
                    {intl.formatMessage({ id: 'dataBrowser.addFilter' })}
                </button>
            </div>
            }
        </div>
    );
};

FilterList.propTypes = {
    onAddFilter: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
    mapInstanceId: PropTypes.string.isRequired,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(FilterList);
