// @ts-check
import dragonfly from 'dragonfly-v3';
import { multiPolygon, pointsWithinPolygon, polygon } from '@turf/turf';

import AppConfig from '../appConfig';
import { getFeatureBoundingBox } from './Util';

const GEOBUFFER_SOURCE_NAME = 'geobuffer';

export default class QueryFromMap {
    constructor() {
        this._initialize();
    }

    /**
     * Add source that will be used to query data from the map. Id will be used
     * as a reference to retrieve data and to set columns to query
     * @param {string} id Id used to connect source and columns, as well as a
     * key by which to retrieve data from the map
     * @param {import('../objects/DataSource').default} source
     * @param {boolean} isPolygon
     * */
    addGeoBufferSource(id, source, isPolygon) {
        if (this._map) {
            throw new Error(
                'Adding sources after map is initialized is not allowed. You should destroy this map and create a new one',
            );
        }
        this._geobufferSources[id] = source;
        this._isPolygon[id] = isPolygon;
    }

    /**
     * Add source that will be used to query data from the map. Id will be used
     * as a reference to retrieve data and to set columns to query
     * @param {string} id Id used to connect source and columns, as well as a
     * key by which to retrieve data from the map
     * @param {import('../objects/DataSource').default} source
     * @param {boolean} isPolygon
     * */
    addGeoJsonSource(id, source, isPolygon) {
        if (this._map) {
            throw new Error(
                'Adding sources after map is initialized is not allowed. You should destroy this map and create a new one',
            );
        }
        this._geoJsonSources[id] = source;
        this._isPolygon[id] = isPolygon;
    }

    /**
     * Add columns that will be used to query map.
     * @param {string} id Id used to connect source and columns, as well as a
     * key by which to retrieve data from the map
     * @param {import('../types').DatasetAbbreviationColumnName[]} columns Name of the columns in geobuffer with dataset abbreviation prefix
     */
    addColumns(id, columns) {
        if (this._map) {
            throw new Error(
                'Adding columns after map is initialized is not allowed. You should destroy this map and create a new one',
            );
        }
        this._columns[id] = columns;
    }

    /**
     * Add columns that will be used to query map.
     * @param {string} id Id used to connect source and columns, as well as a
     * key by which to retrieve data from the map
     * @param {{columnName: string}[]} columns Name of the columns in geojson
     */
    addGeoJsonColumns(id, columns) {
        if (this._map) {
            throw new Error(
                'Adding columns after map is initialized is not allowed. You should destroy this map and create a new one',
            );
        }
        this._geoJsonColumns[id] = columns;
    }

    /**
     * Get column names in geobuffer with dataset abbreviation of geo fips code
     * and geo name
     * @param {string} id Id used to connect source and columns, as well as a
     * key by which to retrieve data from the map
     * @returns {[import('../types').DatasetAbbreviationColumnName, import('../types').DatasetAbbreviationColumnName]}
     * First element is geoFips, second is geo name
     */
    getGeoColumnNames(id) {
        const source = this._geobufferSources[id];
        const firstDataset = Object.values(source.datasets)[0];
        return [
            {
                datasetAbbreviation: firstDataset.datasetAbbreviation,
                columnName: firstDataset.primaryKeyField,
            },
            {
                datasetAbbreviation: firstDataset.datasetAbbreviation,
                columnName: firstDataset.geoNameField,
            },
        ];
    }

    getSources() {
        /** @type {import('../').dragonfly.Sources} */
        const sources = {};
        if (Object.keys(this._geobufferSources).length > 0) {
            sources[GEOBUFFER_SOURCE_NAME] = {
                type: 'vector',
                tiles: [AppConfig.constants.backends.tiles],
                layers: this._getSourceLayers(),
            };
        }
        Object.keys(this._geoJsonSources).forEach(id => {
            const geoJsonSource = this._geoJsonSources[id];
            sources[id] = geoJsonSource.source;
        });
        return sources;
    }

    createMap() {
        if (this._map) {
            throw new Error(
                'Map is already created. Please destroy this one and create a new map',
            );
        }
        return new Promise(resolve => {
            // Create hidden map container
            const container = document.createElement('div');
            container.classList.add('hidden-selection-map');
            document.body.appendChild(container);

            const sources = this.getSources();
            const presentationLayers = this._getPresentationLayers();

            /** @type {import('../').dragonfly.MapboxOptions} */
            const mapJSON = {
                container,
                dragRotate: false,
                style: {
                    version: 8,
                    sprite: `${AppConfig.constants.assetsBaseURL}/sprite/sprite`,
                    sources,
                    layers: presentationLayers,
                },
                zoom: 20,
                center: [0, 0],
            };

            /** @type {import('../').dragonfly.Map} */
            this._map = new dragonfly.Map(mapJSON);

            this._map.once('rendered', resolve);
        });
    }

    destroyMap() {
        const map = this._map;
        const container = map.getContainer();
        map.remove();
        container.remove();

        this._initialize();
    }

    /**
     * @param {string} id Id used to connect source and columns, as well as a
     * key by which to retrieve data from the map */
    getSL(id) {
        return this._geobufferSources[id].summaryLevel.id;
    }

    async fetchGeoJsonDataFromMap(geoJsonSourceId, boundary) {
        const map = this._map;
        const features = [];
        const { layerId } = this._geoJsonSources[geoJsonSourceId];
        await this._fitBounds(boundary);

        // create turf polygon/multipolygon from the projected user selection
        let turfPolygon;
        if (boundary.geometry.type === 'MultiPolygon') {
            turfPolygon = multiPolygon(boundary.geometry.coordinates);
        } else {
            turfPolygon = polygon(boundary.geometry.coordinates);
        }

        const queryFeatures = map.queryRenderedFeatures(undefined, {
            layers: [layerId],
        });

        // Create the FeatureCollection object that is used by the pointsWithinPolygon function
        /** @type {import('@turf/helpers').FeatureCollection<import('@turf/helpers').Point>} */
        const pointsList = {
            type: 'FeatureCollection',
            features: queryFeatures.map(f => {
                if (f.geometry.type === 'Point') {
                    /** @type {import('@turf/helpers').Point} */
                    const p = {
                        type: 'Point',
                        coordinates: [
                            f.geometry.coordinates[0],
                            f.geometry.coordinates[1],
                        ],
                    };

                    /** @type {import('@turf/helpers').Feature<import('@turf/helpers').Point>} */
                    const x = {
                        type: 'Feature',
                        properties: f.properties,
                        geometry: p,
                    };
                    return x;
                }
                throw new Error('Type of the received geometry is not point.');
            }),
        };

        // Do the math
        const featuresInPolygon = pointsWithinPolygon(pointsList, turfPolygon);

        features.push(...featuresInPolygon.features);

        return this._mapGeoJsonFeatureToData(geoJsonSourceId, features);
    }

    // Idea is to call this method for each ring/radius and for each
    // year/geography. This approach fixes a bug where previously to select
    // features one should first find the largest radius and fit map with it. So
    // let's say you create a report that is a 1 mile radius and then create a
    // new report that is 1 and 10 mile radius. In that case data for 1 mile
    // radius in second report can sometimes contains more geographies. Reason for that
    // was that selection for second report is done on a smaller zoom level and
    // in that case distance between radius and centroid could be 0px, while in
    // first case it could be 1px or 2px.
    //
    // Also, in practice, if we follow pattern to first fetch data for one
    // boundary on all ids, then there will not be any new tile fetch requests
    // and draw life cycles.
    /**
     * @param {string} id Id used to connect source and columns, as well as a
     * key by which to retrieve data from the map
     * @param {import('../').Boundary} boundary polygon which will be used
     * to query data from the map.
     * @returns {Promise<{ [tableGuid: string]: object }[]>}
     */
    async fetchDataFromMap(id, boundary) {
        const map = this._map;
        if (!map) {
            throw new Error(
                'Map is not created. Please call `createMap` first',
            );
        }
        await this._fitBounds(boundary);

        /** @type {GeoJSON.Feature<GeoJSON.Geometry>[]} */
        const features = [];
        const isPolygon = this._isPolygon[id];
        if (isPolygon) {
            const { layerId } = this._geobufferSources[id];
            const projectedPoint = map.project(boundary.properties.point);
            const queryFeatures = map.queryRenderedFeatures(projectedPoint, {
                layers: [layerId],
            });
            features.push(...queryFeatures);
        }

        const layerId = isPolygon
            ? `${this._geobufferSources[id].layerId}p`
            : this._geobufferSources[id].layerId;

        // fips column is used to remove duplicate features
        const [fipsColumnDatasetAbbreviation] = this.getGeoColumnNames(id);
        const fipsColumnName = fipsColumnDatasetAbbreviation.columnName;

        // create turf polygon from the projected user selection
        let turfPolygon;
        if (boundary.geometry.type === 'MultiPolygon') {
            turfPolygon = multiPolygon(boundary.geometry.coordinates);
        } else {
            turfPolygon = polygon(boundary.geometry.coordinates);
        }

        // get all the features in the viewport
        const queryFeatures = map
            .queryRenderedFeatures(undefined, {
                layers: [layerId],
            }) // remove features that are already queried
            .filter(
                feature =>
                    !features.some(
                        f =>
                            f.properties[fipsColumnName] ===
                            feature.properties[fipsColumnName],
                    ),
            );

        // Create the FeatureCollection object that is used by the pointsWithinPolygon function
        /** @type {import('@turf/helpers').FeatureCollection<import('@turf/helpers').Point>} */
        const pointsList = {
            type: 'FeatureCollection',
            features: queryFeatures.map(f => {
                if (f.geometry.type === 'Point') {
                    /** @type {import('@turf/helpers').Point} */
                    const p = {
                        type: 'Point',
                        coordinates: [
                            f.geometry.coordinates[0],
                            f.geometry.coordinates[1],
                        ],
                    };

                    /** @type {import('@turf/helpers').Feature<import('@turf/helpers').Point>} */
                    const x = {
                        type: 'Feature',
                        properties: f.properties,
                        geometry: p,
                    };
                    return x;
                }
                throw new Error('Type of the received geometry is not point.');
            }),
        };

        // Do the math
        const featuresInPolygon = pointsWithinPolygon(pointsList, turfPolygon);

        features.push(...featuresInPolygon.features);

        return this._mapFeatureToData(id, features);
    }

    /**
     * @param {string} layer layer Id
     * @param {import('../types').Point} point lan/lng pair object
     */
    async getGeosAtPointForSummaryLevel(layer, point) {
        const map = this._map;
        if (!map) {
            throw new Error(
                'Map is not created. Please call `createMap` first',
            );
        }
        await map.flyTo({ center: point, duration: 0 });
        const [fipsColumn, geoNameColumn] = this.getGeoColumnNames(layer);

        const projectedPoint = map.project([point.lng, point.lat]);
        const features = map.queryRenderedFeatures(projectedPoint, {
            layers: [this._geobufferSources[layer].layerId],
        });
        const slNumber = this.getSL(layer).replace('SL', '');
        return this._mapFeatureToData(layer, features).map(
            feature =>
                `${feature[fipsColumn.columnName]};${slNumber};${
                    feature[geoNameColumn.columnName]
                }`,
        );
    }
    /**
     * Creates array of geofips for survey
     * @param {string} surveyCode
     * @param {import('../').Boundary} ringPolygon
     */
    async getGeoFipsForSurvey(surveyCode, ringPolygon) {
        // backend is working only with number part. We don't need SL prefix
        const slNumber = this.getSL(surveyCode).replace('SL', '');
        const [fipsColumn, geoNameColumn] = this.getGeoColumnNames(surveyCode);

        const data = await this.fetchDataFromMap(surveyCode, ringPolygon);

        const geoFips = data.map(
            feature =>
                `${feature[fipsColumn.columnName]};${slNumber};${
                    feature[geoNameColumn.columnName]
                }`,
        );
        return geoFips;
    }

    _initialize() {
        /** @type {import('../').dragonfly.Map} */
        this._map = undefined;
        /** @type {Object<string, import('../objects/DataSource').default>} key is id */
        this._geobufferSources = {};
        this._geoJsonSources = {};
        /** @type {Object<string, boolean>} key is id */
        this._isPolygon = {};
        /** @type {Object<string, import('../types').DatasetAbbreviationColumnName[]>} key is id */
        this._columns = {};
        this._geoJsonColumns = {};
    }

    /**
     * @param {string} id
     * @param {import('mapbox-gl').MapboxGeoJSONFeature[] | GeoJSON.Feature<GeoJSON.Geometry>[]} queryFeatures
     * @returns {Object<string, object>[]} Key is column name. Value is value
     * retrieved from data tiles. Type of the value depends on column type.
     */
    _mapFeatureToData(id, queryFeatures) {
        const columns = this._columns[id];
        return queryFeatures.map(feature =>
            columns.reduce((memo, { columnName }) => {
                memo[columnName] = feature.properties[columnName];
                return memo;
            }, {}),
        );
    }

    /**
     * @param {string} id
     * @param {import('mapbox-gl').MapboxGeoJSONFeature[] | GeoJSON.Feature<GeoJSON.Geometry>[]} queryFeatures
     * @returns {Object<string, object>[]} Key is column name. Value is value
     * retrieved from data tiles. Type of the value depends on column type.
     */
    _mapGeoJsonFeatureToData(id, queryFeatures) {
        const columns = this._geoJsonColumns[id];
        return queryFeatures.map(feature =>
            columns.reduce((memo, { columnName }) => {
                memo[columnName] = feature.properties[columnName];
                return memo;
            }, {}),
        );
    }

    /**
     * Fits map to provided boundary
     * @param {import('../').Boundary} boundary
     * @returns
     */
    _fitBounds(boundary) {
        return new Promise(resolve => {
            const boundingBox = getFeatureBoundingBox([boundary]);
            /** @type {[[number, number], [number, number]]} */
            const bounds = [
                [boundingBox.xMin, boundingBox.yMin],
                [boundingBox.xMax, boundingBox.yMax],
            ];
            const fitBoundsOptions = {
                duration: 0,
                animate: false,
                padding: 10,
            };

            this._map.fitBounds(bounds, fitBoundsOptions);
            const ensureMapIsLoaded = () => {
                if (!this._map.loaded()) {
                    setTimeout(ensureMapIsLoaded, 10);
                } else {
                    resolve();
                }
            };
            ensureMapIsLoaded();
        });
    }

    /** @return {import('../types').SourceLayerDataset[]} */
    _getGeobufferDatasetIdAndColumns(id) {
        // to be sure that we don't have duplicate features queried (eg. get
        // feature where the center of the radius is, and then again get same
        // feature by polygon/circle), we need to always get fips column.
        // That column will be used in fetchDataFromMap for de-duplication
        const columns = this._columns[id];
        const [fipsGeoColumn] = this.getGeoColumnNames(id);
        const fipsColumnFromColumns = columns.find(
            ({ columnName, datasetAbbreviation }) =>
                fipsGeoColumn.columnName === columnName &&
                fipsGeoColumn.datasetAbbreviation === datasetAbbreviation,
        );
        // add fips column only if developer didn't add it already
        if (!fipsColumnFromColumns) {
            columns.push(fipsGeoColumn);
        }

        const source = this._geobufferSources[id];
        /** @type { { [datasetAbbreviation: string] : string[] } } */
        const columnsByDatasetAbbreviation = columns.reduce((memo, value) => {
            const { datasetAbbreviation, columnName } = value;
            if (!memo[datasetAbbreviation]) {
                memo[datasetAbbreviation] = [];
            }
            memo[datasetAbbreviation].push(columnName);
            return memo;
        }, {});

        return Object.keys(columnsByDatasetAbbreviation).map(
            datasetAbbreviation => {
                const geobufferDataset = Object.values(source.datasets).find(
                    dataset =>
                        dataset.datasetAbbreviation === datasetAbbreviation,
                );
                return {
                    datasetId: geobufferDataset.id.toString(),
                    columns: columnsByDatasetAbbreviation[datasetAbbreviation],
                };
            },
        );
    }

    /**
     * @returns {import('../types').SourceLayer[]}
     */
    _getSourceLayers() {
        /** @type {import('../types').SourceLayer[]} */
        const layers = [];
        Object.keys(this._geobufferSources).forEach(id => {
            const source = this._geobufferSources[id];
            const isPolygon = this._isPolygon[id];
            // Check if the source layer exist. This can happen if surveys being
            // compared (multi year) are using the same geo layers like in the
            // case of EASI data
            const layerExists = layers.some(
                el => el.layerId === source.layerId,
            );
            if (layerExists) return;

            const datasets = this._getGeobufferDatasetIdAndColumns(id);
            layers.push({
                layerId: source.layerId,
                datasets,
            });
            if (isPolygon) {
                layers.push({
                    layerId: `${source.layerId}p`,
                    datasets,
                });
            }
        });
        return layers;
    }

    /**
     * @param {string} layerId
     * @param {string} source
     * @param {boolean} includeSourceLayer
     * @returns {import('mapbox-gl').Layer}
     */
    _createPointPresentationLayer(layerId, source, includeSourceLayer = true) {
        const layer = {
            id: layerId,
            source,
            type: 'symbol',
            layout: {
                'icon-image': 'circle',
                'icon-allow-overlap': true,
                'icon-ignore-placement': true,
                'icon-padding': 0,
                'icon-size': 0.05,
            },
        };
        if (includeSourceLayer) layer['source-layer'] = layerId;
        return layer;
    }

    /**
     * @param {string} layerId
     * @returns {import('mapbox-gl').Layer}
     */
    _createFillPresentationLayer(layerId) {
        return {
            id: layerId,
            source: GEOBUFFER_SOURCE_NAME,
            'source-layer': layerId,
            type: 'fill',
            paint: {
                'fill-outline-color': '#FF0000',
                'fill-color': '#111111',
                'fill-opacity': 0.15,
            },
        };
    }

    _getPresentationLayers() {
        /** @type {import('mapbox-gl').Layer[]} */
        const layers = [];
        Object.keys(this._geobufferSources).forEach(id => {
            const source = this._geobufferSources[id];
            const isPolygon = this._isPolygon[id];
            // Check if the presentation layer exist. This can happen if surveys
            // being compared (multi year) are using the same geo layers like in
            // the case of EASI data
            const layerExists = layers.some(
                layer => layer.id === source.layerId,
            );
            if (layerExists) return;
            if (isPolygon) {
                layers.push(
                    this._createFillPresentationLayer(source.layerId),
                    this._createPointPresentationLayer(`${source.layerId}p`, GEOBUFFER_SOURCE_NAME),
                );
            } else {
                layers.push(this._createPointPresentationLayer(source.layerId, GEOBUFFER_SOURCE_NAME));
            }
        });

        // geoJson layers
        Object.keys(this._geoJsonSources).forEach(id => {
            const source = this._geoJsonSources[id];
            layers.push(
                this._createPointPresentationLayer(source.layerId, id, false)
            );
        });
        return layers;
    }
}
