module.exports={
  "search": "Search",
  "searching": "Searching...",
  "noSearchResults": "No search results for {query}",
  "noSearchResultsHint": "Make sure that all words are spelled correctly. {br} Try different keywords. {br} Try more general keywords. {br} Try fewer keywords.",
  "searchDown": "Our search service is down. Please try again later or contact support.",
  "searchSaturated": "We are sorry, but our search service received too many requests. Please try again in a second.",
  "hint": "Try Income, Race, Households...",
  "didYouMean": "Did you mean:",
  "didYouMeanConnection": "instead of",
  "searchInput": "Search input",
  "clearSearchInput": "Clear search input",
  "suggestion": "Suggestion",
  "expand": "Expand table variables",
  "collapse": "Collapse table variables"
}
