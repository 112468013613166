import React from 'react';
import PropTypes from 'prop-types';
import Keys from '../../enums/Key';

class SearchInput extends React.Component {
    handleSearchChange = e => {
        this.props.onChange(e.target.value);
    };

    handleFocus = () => {
        if (this.props.onFocus) {
            this.props.onFocus();
        }
    };

    handleBlur = () => {
        if (this.props.onBlur) {
            this.props.onBlur();
        }
    };

    handleKeyDown = e => {
        const key = e.which || e.keyCode;
        if (key === Keys.ENTER) {
            this.input.blur();
        }
    };

    focus() {
        if (this.input) {
            this.input.focus();
        }
    }

    render() {
        return (
            <div className="search-box__input-section">
                <i className="material-icons">search</i>
                <input
                    ref={c => {
                        this.input = c;
                    }}
                    className="search-box__input"
                    value={this.props.value}
                    placeholder={this.props.placeholder}
                    onChange={this.handleSearchChange}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    onKeyDown={this.handleKeyDown}
                    disabled={this.props.disabled}
                />
            </div>
        );
    }
}

SearchInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
};

SearchInput.defaultProps = {
    value: '',
    onFocus: undefined,
    onBlur: undefined,
    disabled: false,
    placeholder: 'Search address or geography',
};

export default SearchInput;
