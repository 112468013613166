import React from 'react';

import AppConfig from '../../appConfig';
import BusComponent from '../BusComponent';

class SignupButton extends BusComponent {
    onClick = () => {
        this.emit('SIGN_UP_REQUEST', {
            checkCount: 240,
            url: AppConfig.constants.links.signUp,
        });
    };

    render() {
        return (
            <button
                type="button"
                key="signup"
                tabIndex="0"
                title="Sign Up"
                className="btn-raised application-header__button"
                onClick={this.onClick}
            >
                Sign Up
            </button>
        );
    }
}

export default SignupButton;
