import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class SelectionListItem extends React.Component {
    onClick = () => {
        const { selected, item, onClick } = this.props;
        // if selected, fo nothing
        if (selected) {
            return;
        }
        onClick(item);
    };

    render() {
        const { item, selected } = this.props;
        const classes = classnames('selection-list__item', {
            'selection-list__item--selected': selected,
        });

        return (<button
            className={classes}
            onClick={this.onClick}
        >
            <span className="body-1">
                {item.text}
            </span>
        </button>);
    }
}

SelectionListItem.propTypes = {
    item: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    selected: PropTypes.bool,
};

SelectionListItem.defaultProps = {
    selected: false,
};

export default SelectionListItem;
