const colorPaletteType = {
    POLYGON_SEQUENTIAL: 'POLYGON-SEQUENTIAL',
    POLYGON_DIVERGING: 'POLYGON-DIVERGING',
    POLYGON_USER_DEFINED: 'POLYGON-USER-DEFINED',
    MULTI_POLYGON: 'MULTI-POLYGON',
    MULTI_POLYGON_USER_DEFINED: 'MULTI-POLYGON-USER-DEFINED',
    BUBBLE_SEQUENTIAL: 'BUBBLE-SEQUENTIAL',
    BUBBLE_DIVERGING: 'BUBBLE-DIVERGING',
    BUBBLE_USER_DEFINED: 'BUBBLE-USER-DEFINED',
    BUBBLE_SINGLE_COLOR: 'BUBBLE-SINGLE-COLOR',
    BUBBLE_USER_DEFINED_SINGLE_COLOR: 'BUBBLE-USER-DEFINED-SINGLE-COLOR',
    DOT_DENSITY: 'DOT-DENSITY',
    DOT_DENSITY_USER_DEFINED: 'DOT-DENSITY-USER-DEFINED',
    VALUE_DOT_DENSITY: 'VALUE-DOT-DENSITY',
    VALUE_DOT_DENSITY_USER_DEFINED: 'VALUE-DOT-DENSITY-USER-DEFINED',
};

export default colorPaletteType;
