import ConvertSvgElementToPng from './ConvertSvgElementToPng';

const SCHOOL_RANK_MARKERS = [
    'school_rank_primary',
    'school_rank_middle',
    'school_rank_high',
    'school_rank_alternative',
];

const SCHOOL_RANK_COLORS = {
    red: '#f66c6a',
    less_red: '#f69575',
    lesser_red: '#fabc7d',
    yellow: '#fde680',
    lesser_green: '#c9d881',
    less_green: '#97c97e',
    green: '#65be7a',
};

const SELFSTORAGE_HOUSING_MARKERS = {
    warehouse_grey: { width: 64, height: 68 },
    warehouse_grey_blue: { width: 64, height: 68 },
    warehouse_orange: { width: 64, height: 68 },
    warehouse_red_faded: { width: 64, height: 68 },
    warehouse_green_faded: { width: 64, height: 68 },
    warehouse_yellow: { width: 64, height: 68 },
    housing_start_small: { width: 48, height: 48 },
    housing_start_medium: { width: 72, height: 72 },
    housing_start_large: { width: 98, height: 98 },
};

const INFLUENCER_MARKERS = {
    place_casino: { width: 54, height: 54 },
    place_conference_convention_centers: { width: 54, height: 54 },
    place_day_care_centers: { width: 54, height: 54 },
    place_educational: { width: 54, height: 54 },
    place_financial: { width: 54, height: 54 },
    place_fitness_rec_centers: { width: 54, height: 54 },
    place_hotel_motel: { width: 54, height: 54 },
    place_industrial: { width: 54, height: 54 },
    place_manufacturing: { width: 54, height: 54 },
    place_medical: { width: 54, height: 54 },
    place_military: { width: 54, height: 54 },
    place_office: { width: 54, height: 54 },
    place_restaurant: { width: 54, height: 54 },
    place_retail: { width: 54, height: 54 },
};

const CUSTOM_MARKERS = {
    custom_pin: { width: 50, height: 61 },
    distance_label: { width: 72, height: 32 },
};

const PIXEL_RATIO = 2;

const addSchoolRankMarkers = () => {
    // add school data marker icons
    const markersOptions = {
        focusElement: 'marker-pin',
        outputWidth: 44,
        outputHeight: 60,
    };
    const schoolRankMarkers = [];
    SCHOOL_RANK_MARKERS.forEach(markerId => {
        Object.keys(SCHOOL_RANK_COLORS).forEach(colorId => {
            const imageName = `${markerId}_${colorId}`;
            const fillColor = SCHOOL_RANK_COLORS[colorId];

            const markerPng = ConvertSvgElementToPng(markerId, {
                fillColor,
                ...markersOptions,
            });
            schoolRankMarkers.push({
                id: imageName,
                image: markerPng,
                pixelRatio: PIXEL_RATIO,
            });
        });
    });
    return schoolRankMarkers;
};

const addSelfstorageAndHousingMarkers = () => Object.keys(SELFSTORAGE_HOUSING_MARKERS).map(markerId => {
    const markerPng = ConvertSvgElementToPng(markerId, {
        outputWidth: SELFSTORAGE_HOUSING_MARKERS[markerId].width,
        outputHeight: SELFSTORAGE_HOUSING_MARKERS[markerId].height,
    });
    return {
        id: markerId,
        image: markerPng,
        pixelRatio: PIXEL_RATIO,
    };
});

const addInfluencerMarkers = () => Object.keys(INFLUENCER_MARKERS).map(markerId => {
    const markerPng = ConvertSvgElementToPng(markerId, {
        outputWidth: INFLUENCER_MARKERS[markerId].width,
        outputHeight: INFLUENCER_MARKERS[markerId].height,
    });
    return {
        id: markerId,
        image: markerPng,
        pixelRatio: PIXEL_RATIO,
    };
});

const addCustomMarkers = () => Object.keys(CUSTOM_MARKERS).map(markerId => {
    const markerPng = ConvertSvgElementToPng(markerId, {
        outputWidth: CUSTOM_MARKERS[markerId].width,
        outputHeight: CUSTOM_MARKERS[markerId].height,
    });
    return {
        id: markerId,
        image: markerPng,
        pixelRatio: PIXEL_RATIO,
    };
});

const getCustomMapMarkers = () => [
    ...addSchoolRankMarkers(),
    ...addSelfstorageAndHousingMarkers(),
    ...addCustomMarkers(),
    ...addInfluencerMarkers(),
];

export default getCustomMapMarkers;
