import NumberFormat from '../../enums/NumberFormat';

class DataFilterField {
    constructor(values) {
        if (values) {
            Object.keys(values).forEach(k => {
                if (this.constructor.prototype.hasOwnProperty(k)) {
                    this[k] = values[k];
                }
            });
        }
        this._values = values;
        if (!this.optionsList) this.optionsList = [];
    }

    get categoryId() {
        return this._categoryId;
    }

    set categoryId(categoryId) {
        this._categoryId = categoryId;
    }

    get categoryName() {
        return this._categoryName;
    }

    set categoryName(categoryName) {
        this._categoryName = categoryName;
    }

    get tableName() {
        return this._tableName;
    }

    set tableName(tableName) {
        this._tableName = tableName;
    }

    get surveyName() {
        return this._surveyName;
    }

    set surveyName(surveyName) {
        this._surveyName = surveyName;
    }

    get datasetAbbreviation() {
        return this._datasetAbbreviation;
    }

    set datasetAbbreviation(datasetAbbreviation) {
        this._datasetAbbreviation = datasetAbbreviation;
    }

    get datasetId() {
        return this._datasetId;
    }

    set datasetId(datasetId) {
        this._datasetId = datasetId;
    }

    get tableGuid() {
        return this._tableGuid;
    }

    set tableGuid(tableGuid) {
        this._tableGuid = tableGuid;
    }

    get fieldName() {
        return this._fieldName;
    }

    set fieldName(fieldName) {
        this._fieldName = fieldName;
    }

    get qLabel() {
        return this._qLabel;
    }

    set qLabel(qLabel) {
        this._qLabel = qLabel;
    }

    get baseFieldName() {
        return this._baseFieldName;
    }

    set baseFieldName(baseFieldName) {
        this._baseFieldName = baseFieldName;
    }

    get fieldDataType() {
        return this._fieldDataType;
    }

    set fieldDataType(fieldDataType) {
        this._fieldDataType = fieldDataType;
    }

    get variableCode() {
        return this._variableCode;
    }

    set variableCode(variableCode) {
        this._variableCode = variableCode;
    }

    get optionsList() {
        return this._optionsList;
    }

    set optionsList(optionsList) {
        this._optionsList = optionsList;
    }

    get formatting() {
        return this._formatting || NumberFormat.FORMAT_NUMBER_NO_DECIMAL;
    }

    set formatting(formatting) {
        this._formatting = formatting;
    }

    equals(that) {
        return this.categoryName === that.categoryName
            && this.tableName === that.tableName
            && this.surveyName === that.surveyName
            && this.datasetAbbreviation === that.datasetAbbreviation
            && this.datasetId === that.datasetId
            && this.tableGuid === that.tableGuid
            && this.fieldName === that.fieldName
            && this.qLabel === that.qLabel
            && this.baseFieldName === that.baseFieldName
            && this.fieldDataType === that.fieldDataType
            && this.variableCode === that.variableCode
            && this.formatting === that.formatting
            && JSON.stringify(this.optionsList) === JSON.stringify(that.optionsList);
    }

    clone() {
        return new DataFilterField({
            categoryName: this.categoryName,
            tableName: this.tableName,
            surveyName: this.surveyName,
            datasetAbbreviation: this.datasetAbbreviation,
            datasetId: this.datasetId,
            tableGuid: this.tableGuid,
            fieldName: this.fieldName,
            qLabel: this.qLabel,
            baseFieldName: this.baseFieldName,
            fieldDataType: this.fieldDataType,
            variableCode: this.variableCode,
            optionsList: [...this.optionsList],
            formatting: this.formatting,
        });
    }

    toJSON() {
        return {
            categoryName: this.categoryName,
            tableName: this.tableName,
            surveyName: this.surveyName,
            datasetAbbreviation: this.datasetAbbreviation,
            datasetId: this.datasetId,
            tableGuid: this.tableGuid,
            fieldName: this.fieldName,
            qLabel: this.qLabel,
            baseFieldName: this.baseFieldName,
            fieldDataType: this.fieldDataType,
            variableCode: this.variableCode,
            optionsList: this.optionsList,
            formatting: this.formatting,
        };
    }
}

export default DataFilterField;
