import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Checkbox from '../form/Checkbox';

class NestedSummaryLevel extends React.Component {
    onClick = () => {
        if (this.props.onClick) {
            this.props.onClick(this.props.summaryLevel);
        }
    };
    render() {
        const classes = classnames('flex-it center nested-summary-level-item');
        return (<div
            className={classes}
            onClick={this.onClick}
        >
            <Checkbox
                checked={this.props.selected}
            />
            <span className="body-1">{this.props.summaryLevel.label}</span>
        </div>);
    }
}

NestedSummaryLevel.propTypes = {
    className: PropTypes.string,
    summaryLevel: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    selected: PropTypes.bool,
    onClick: PropTypes.func,
};

NestedSummaryLevel.defaultProps = {
    className: '',
    disabled: false,
    selected: false,
    onClick: undefined,
};

export default NestedSummaryLevel;
