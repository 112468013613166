// @ts-check
import FilterCombiner from '../../enums/FilterCombiner';

class PointsFilter {
    /**
     * @param {Object} pointsFilter
     * @param {import('./PointsFilterRule').default[]} pointsFilter.filterRules
     * @param {string} pointsFilter.filterCombinerType
     */
    constructor({ filterRules = [], filterCombinerType = FilterCombiner.MATCH_ALL.type }) {
        this._filterRules = filterRules;
        this._filterCombinerType = filterCombinerType;
    }

    get filterCombinerType() {
        return this._filterCombinerType;
    }

    set filterCombinerType(filterCombinerType) {
        this._filterCombinerType = filterCombinerType;
    }

    get filterRules() {
        return this._filterRules;
    }

    set filterRules(filterRules) {
        this._filterRules = filterRules;
    }

    /**
     * @param {import('@turf/helpers').Feature[]} geoJsonPointFeatures
     */
    filter = geoJsonPointFeatures =>
        geoJsonPointFeatures.filter(feature => {
            const filterRulesValidity = this._filterRules.map(filterRule =>
                filterRule.isPointFeatureAMatch(feature),
            );
            // Apply filter combiner
            switch (this._filterCombinerType) {
            case FilterCombiner.MATCH_ALL.type:
                return filterRulesValidity.every(v => v);
            case FilterCombiner.MATCH_ANY.type:
                return filterRulesValidity.some(v => v);
            case FilterCombiner.MATCH_NONE.type:
                return !filterRulesValidity.some(v => v);
            default:
                return false;
            }
        });
}

export default PointsFilter;
