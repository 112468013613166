import React from 'react';
import AppConfig from '../../appConfig';
import BusComponent from '../BusComponent';
import { injectIntl } from 'react-intl';

/**
 * @typedef Props
 * @property {import('react-intl').intlShape} intl
 * @property {() => void} toggleSearchPanel
 *
 * @extends {BusComponent<Props, State>}
 */
class SearchBoxPanelFooter extends BusComponent {
    findByCriteria = () => {
        this.emit('SHOW_POINTS_PANEL');
        this.props.toggleSearchPanel();
    };

    render() {
        return (
            <div className="search-panel-footer">
                <button
                    className="btn btn-link flex-it grow space-between"
                    onClick={this.findByCriteria}
                >
                    {this.props.intl.formatMessage({
                        id: AppConfig.constants.searchBox.criteriaMessage,
                    })}
                    <i className="material-icons">keyboard_arrow_right</i>
                </button>
            </div>
        );
    }
}

export default injectIntl(SearchBoxPanelFooter);
