import FilterComparisonType from '../enums/FilterComparisonType';
import FilterStatus from '../enums/FilterStatus';
import { parseFieldName } from '../helpers/Util';
import AppConfig from '../appConfig';

class Project {
    get isMadeWithDingo() {
        return AppConfig.projectTypes.includes(this._projectType);
    }

    get viewCode() {
        return this._viewCode;
    }

    set viewCode(viewCode) {
        this._viewCode = viewCode;
    }

    get assetsGuid() {
        return this._assetsGuid;
    }

    set assetsGuid(assetsGuid) {
        this._assetsGuid = assetsGuid;
    }

    get etag() {
        return this._etag;
    }

    set etag(etag) {
        this._etag = etag;
    }

    get title() {
        return this._title;
    }

    set title(title) {
        this._title = title;
    }

    get description() {
        return this._description;
    }

    set description(description) {
        this._description = description;
    }

    get canSave() {
        return this._canSave;
    }

    set canSave(canSave) {
        this._canSave = canSave;
    }

    get ownedBySe() {
        return this._ownedBySe;
    }

    set ownedBySe(ownedBySe) {
        this._ownedBySe = ownedBySe;
    }

    get projectType() {
        return this._projectType;
    }

    set projectType(projectType) {
        this._projectType = projectType;
    }

    get projectId() {
        return this._projectId;
    }

    set projectId(projectId) {
        this._projectId = projectId;
    }

    get userId() {
        return this._userId;
    }

    set userId(userId) {
        this._userId = userId;
    }

    get isShareSnapshot() {
        return this._isShareSnapshot;
    }

    set isShareSnapshot(isShareSnapshot) {
        this._isShareSnapshot = isShareSnapshot;
    }

    get modifiedDate() {
        return this._modifiedDate;
    }

    set modifiedDate(modifiedDate) {
        this._modifiedDate = modifiedDate;
    }

    get createdDate() {
        return this._createdDate;
    }

    set createdDate(createdDate) {
        this._createdDate = createdDate;
    }

    get projectThumbUrl() {
        return this._projectThumbUrl;
    }

    set projectThumbUrl(projectThumbUrl) {
        this._projectThumbUrl = projectThumbUrl;
    }

    get titleFrame() {
        return this._titleFrame;
    }

    set titleFrame(frame) {
        this._titleFrame = frame;
    }

    get frames() {
        return this._frames;
    }

    set frames(frames) {
        this._frames = frames;
    }

    get projectColorPalettes() {
        return this._projectColorPalettes;
    }

    set projectColorPalettes(projectColorPalettes) {
        this._projectColorPalettes = projectColorPalettes;
    }

    get readyForVisualization() {
        return this._readyForVisualization;
    }

    set readyForVisualization(readyForVisualization) {
        this._readyForVisualization = readyForVisualization;
    }

    get showThumbsInPlayMode() {
        return this._showThumbsInPlayMode;
    }

    set showThumbsInPlayMode(showThumbsInPlayMode) {
        this._showThumbsInPlayMode = showThumbsInPlayMode;
    }

    get isPublic() {
        return this._isPublic;
    }

    set isPublic(isPublic) {
        this._isPublic = isPublic;
    }

    get loginRequired() {
        return this._loginRequired;
    }

    set loginRequired(loginRequired) {
        this._loginRequired = loginRequired;
    }

    clone() {
        const clonedProject = Object.assign(new Project(), JSON.parse(JSON.stringify(this)));
        clonedProject.frames = this.frames.map(frame => frame.clone());
        clonedProject.titleFrame = this.titleFrame && this.titleFrame.clone();
        clonedProject.projectColorPalettes = this.projectColorPalettes.map(projectColorPalette =>
            projectColorPalette.clone(),
        );
        return clonedProject;
    }

    equals(that) {
        const isTitlePageEqual =
            (!this.titleFrame && !that.titleFrame) ||
            (this.titleFrame && that.titleFrame && this.titleFrame.equals(that.titleFrame));
        return (
            this.viewCode === that.viewCode &&
            isTitlePageEqual &&
            this.title === that.title &&
            this.description === that.description &&
            this.canSave === that.canSave &&
            this.etag === that.etag &&
            this.ownedBySe === that.ownedBySe &&
            this.isPublic === that.isPublic &&
            this.projectType === that.projectType &&
            this.createdDate === that.createdDate &&
            this.projectThumbUrl === that.projectThumbUrl &&
            this.modifiedDate === that.modifiedDate &&
            this.projectId === that.projectId &&
            this.isShareSnapshot === that.isShareSnapshot &&
            this.showThumbsInPlayMode === that.showThumbsInPlayMode &&
            this.frames.every((frame, index) => frame.equals(that.frames[index])) &&
            this.projectColorPalettes.every((projectColorPalette, index) =>
                projectColorPalette.equals(that.projectColorPalettes[index]),
            )
        );
    }

    get partialMetadataInfo() {
        if (!this.frames) return [];
        const info = { surveys: {} };
        this.frames.forEach(frame => {
            frame.mapInstances.forEach(mapInstance => {
                if (
                    mapInstance.dataTheme &&
                    mapInstance.dataTheme.variableSelection &&
                    mapInstance.dataTheme.variableSelection.items
                ) {
                    mapInstance.dataTheme.variableSelection.items.forEach(item => {
                        info.surveys[item.surveyName] = info.surveys[item.surveyName] || {
                            datasets: {},
                        };
                        const surveyInfo = info.surveys[item.surveyName];
                        surveyInfo.datasets[item.datasetAbbreviation] = surveyInfo.datasets[
                            item.datasetAbbreviation
                        ] || { tables: {} };
                        const datasetInfo = surveyInfo.datasets[item.datasetAbbreviation];
                        datasetInfo.tables[item.tableGuid] = true;
                    });
                }
            });
        });
        return info;
    }

    /**
     * Get JSON representation of the project
     * @returns {object} JSON representation of the project
     */
    toJSON() {
        return {
            viewCode: this.viewCode,
            assetsGuid: this.assetsGuid,
            etag: this.etag,
            title: this.title,
            description: this.description,
            canSave: this.canSave,
            ownedBySe: this.ownedBySe,
            isPublic: this.isPublic,
            loginRequired: this.loginRequired,
            projectType: this.projectType,
            projectId: this.projectId,
            userId: this.userId,
            projectColorPalettes: this.projectColorPalettes.map(projectColorPalette =>
                projectColorPalette.toJSON(),
            ),
            titleFrame: this.titleFrame ? this.titleFrame.clone() : undefined,
            frames: this.frames.map(frame => frame.toJSON()),
            isShareSnapshot: this.isShareSnapshot,
            modifiedDate: this.modifiedDate,
            createdDate: this.createdDate,
            projectThumbUrl: this.projectThumbUrl,
            showThumbsInPlayMode: this.showThumbsInPlayMode,
            readyForVisualization: this.readyForVisualization,
        };
    }

    static isValidString(string) {
        return (
            string !== undefined &&
            string !== null &&
            string !== '' &&
            string !== 'undefined' &&
            string !== 'null'
        );
    }

    static isValidNumber(number) {
        return (
            number !== undefined &&
            number !== null &&
            number !== '' &&
            !isNaN(number) &&
            number !== 'undefined' &&
            number !== 'null'
        );
    }

    static createFieldNodes(fields, parentNode) {
        fields.forEach(field => {
            const fieldNode = document.createElementNS(null, 'Field');
            if (Project.isValidString(field.fieldName)) {
                fieldNode.setAttribute('fieldName', field.fieldName);
            }
            if (Project.isValidString(field.surveyName)) {
                fieldNode.setAttribute('surveyName', field.surveyName);
            }
            if (Project.isValidString(field.tableUuid)) {
                fieldNode.setAttribute('tableUuid', field.tableUuid);
            }
            if (Project.isValidString(field.datasetAbbreviation)) {
                fieldNode.setAttribute('datasetName', field.datasetAbbreviation);
            }
            if (Project.isValidString(field.hideFromUser)) {
                fieldNode.setAttribute('hideFromUser', field.hideFromUser ? 'true' : 'false');
            }
            if (Project.isValidString(field.universe)) {
                fieldNode.setAttribute('universe', field.universe ? 'true' : 'false');
            }
            if (Project.isValidString(field.isComputed)) {
                fieldNode.setAttribute('isComputedField', field.isComputed ? 'true' : 'false');
            }
            fieldNode.setAttribute('isFeatureId', 'false');
            if (Project.isValidString(field.isGeoNameField)) {
                fieldNode.setAttribute('isGeoNameField', field.isGeoNameField ? 'true' : 'false');
            }
            if (Project.isValidString(field.label)) {
                fieldNode.setAttribute('label', field.label);
            }
            if (Project.isValidString(field.formatting)) {
                fieldNode.setAttribute('formatting', field.formatting);
            }
            if (Project.isValidString(field.isChangeOverTimeField)) {
                fieldNode.setAttribute(
                    'isChangeOverTimeField',
                    field.isChangeOverTimeField ? 'true' : 'false',
                );
            }
            if (Project.isValidString(field.pointOfReferenceFieldQName)) {
                fieldNode.setAttribute(
                    'pointOfReferenceFieldQName',
                    field.pointOfReferenceFieldQName,
                );
            }
            let fieldNumerator;
            if (Project.isValidString(field.fieldNumerator)) {
                const fieldNumeratorResult = parseFieldName(field.fieldNumerator);
                if (
                    fieldNumeratorResult.surveyName === '' &&
                    fieldNumeratorResult.datasetAbbreviation === ''
                ) {
                    fieldNumerator = `${field.surveyName}.${field.datasetAbbreviation}.${field.fieldNumerator}`;
                } else {
                    fieldNumerator = field.fieldNumerator;
                }
            }
            let fieldDenominator;
            if (Project.isValidString(field.fieldDenominator)) {
                const fieldDenominatorResult = parseFieldName(field.fieldDenominator);
                if (
                    fieldDenominatorResult.surveyName === '' &&
                    fieldDenominatorResult.datasetAbbreviation === ''
                ) {
                    fieldDenominator = `${field.surveyName}.${field.datasetAbbreviation}.${field.fieldDenominator}`;
                } else {
                    fieldDenominator = field.fieldDenominator;
                }
            }
            switch (field.computeFunction) {
                case 'COMPUTE_PERCENT':
                    fieldNode.setAttribute(
                        'computeFormula',
                        `percent(${fieldNumerator},${fieldDenominator})`,
                    );
                    break;
                case 'COMPUTE_RATIO':
                    fieldNode.setAttribute(
                        'computeFormula',
                        `avg(${fieldNumerator},${fieldDenominator})`,
                    );
                    break;
                case 'COMPUTE_MULTIPLY':
                    fieldNode.setAttribute(
                        'computeFormula',
                        `multiply(${fieldNumerator}, ${field.fieldMultiplier})`,
                    );
                    break;
            }
            if (Project.isValidString(field.computeFunction)) {
                fieldNode.setAttribute('computeFunctionName', field.computeFunction);
            }
            if (Project.isValidNumber(field.fieldMultiplier)) {
                fieldNode.setAttribute('fieldMultiplier', field.fieldMultiplier);
            }
            if (Project.isValidString(fieldNumerator)) {
                fieldNode.setAttribute('fieldNumerator', fieldNumerator);
            }
            if (Project.isValidString(fieldDenominator)) {
                fieldNode.setAttribute('fieldDenominator', fieldDenominator);
            }
            if (Project.isValidString(field.fieldNumeratorParent)) {
                fieldNode.setAttribute('fieldNumeratorParent', field.fieldNumeratorParent);
            }
            if (Project.isValidString(field.fieldDenominatorParent)) {
                fieldNode.setAttribute('fieldDenominatorParent', field.fieldDenominatorParent);
            }
            parentNode.appendChild(fieldNode);
        });
    }

    static createSymbolNodes(symbols, parentNode) {
        symbols.forEach(symbol => {
            const symbolNode = document.createElementNS(null, symbol.type);
            symbolNode.setAttribute('id', '');
            symbol.brushes.forEach(brush => {
                const brushNode = document.createElementNS(null, 'Brush');
                if (Project.isValidString(brush.fillColor)) {
                    brushNode.setAttribute('fill', brush.fillColor);
                }
                if (Project.isValidNumber(brush.fillOpacity)) {
                    brushNode.setAttribute('fillOpacity', brush.fillOpacity);
                }
                if (Project.isValidString(brush.strokeStyle)) {
                    brushNode.setAttribute('strokeStyle', brush.strokeStyle);
                }
                if (Project.isValidNumber(brush.strokeWidth)) {
                    brushNode.setAttribute('strokeWidth', brush.strokeWidth);
                }
                if (Project.isValidString(brush.strokeColor)) {
                    brushNode.setAttribute('stroke', brush.strokeColor);
                }
                if (Project.isValidNumber(brush.strokeOpacity)) {
                    brushNode.setAttribute('strokeOpacity', brush.strokeOpacity);
                }
                if (Project.isValidString(brush.strokeLineCap)) {
                    brushNode.setAttribute('strokeLineCap', brush.strokeLineCap);
                }
                if (Project.isValidString(brush.strokeFill)) {
                    brushNode.setAttribute('strokeFill', brush.strokeFill);
                }
                if (Project.isValidNumber(brush.strokeFillOpacity)) {
                    brushNode.setAttribute('strokeFillOpacity', brush.strokeFillOpacity);
                }
                if (Project.isValidNumber(brush.strokeFillWidth)) {
                    brushNode.setAttribute('strokeFillWidth', brush.strokeFillWidth);
                }
                if (Project.isValidNumber(brush.textSize)) {
                    brushNode.setAttribute('size', brush.textSize);
                }
                if (Project.isValidString(brush.textColor)) {
                    brushNode.setAttribute('color', brush.textColor);
                }
                if (Project.isValidNumber(brush.textOpacity)) {
                    brushNode.setAttribute('opacity', brush.textOpacity);
                }
                if (Project.isValidString(brush.textBaselineShift)) {
                    brushNode.setAttribute('baselineShift', brush.textBaselineShift);
                }
                if (Project.isValidString(brush.textPaddingLeft)) {
                    brushNode.setAttribute('paddingLeft', brush.textPaddingLeft);
                }
                if (Project.isValidString(brush.textPaddingRight)) {
                    brushNode.setAttribute('paddingRight', brush.textPaddingRight);
                }
                if (Project.isValidString(brush.textLetterCasing)) {
                    brushNode.setAttribute('letterCasing', brush.textLetterCasing);
                }
                if (Project.isValidNumber(brush.textLetterSpacing)) {
                    brushNode.setAttribute('letterSpacing', brush.textLetterSpacing);
                }
                if (Project.isValidString(brush.textFont)) {
                    brushNode.setAttribute('font', brush.textFont);
                }
                if (Project.isValidString(brush.textLabelPositions)) {
                    brushNode.setAttribute('labelPositions', brush.textLabelPositions);
                }
                if (Project.isValidString(brush.textHaloColor)) {
                    brushNode.setAttribute('glow', brush.textHaloColor);
                }
                if (Project.isValidNumber(brush.textHaloBlur)) {
                    brushNode.setAttribute('glowStrength', brush.textHaloBlur);
                }
                if (Project.isValidNumber(brush.textHaloOpacity)) {
                    brushNode.setAttribute('glowAlpha', brush.textHaloOpacity);
                }
                if (Project.isValidString(brush.marker)) {
                    brushNode.setAttribute('marker', brush.marker);
                }
                if (Project.isValidNumber(brush.minZoom)) {
                    brushNode.setAttribute('zoomMin', brush.minZoom);
                }
                if (Project.isValidNumber(brush.maxZoom)) {
                    brushNode.setAttribute('zoomMax', brush.maxZoom);
                }
                symbolNode.appendChild(brushNode);
            });
            parentNode.appendChild(symbolNode);
        });
    }

    static createRuleNodes(rules, parentNode) {
        rules.forEach(rule => {
            const ruleNode = document.createElementNS(null, 'Rule');
            if (rule.title !== undefined && rule.title !== null && rule.title !== '') {
                ruleNode.setAttribute('title', rule.title);
            }
            if (rule.title !== 'No data' && Project.isValidString(rule.displayInLegend)) {
                ruleNode.setAttribute('displayInLegend', rule.displayInLegend ? 'true' : 'false');
            } else {
                ruleNode.setAttribute('displayInLegend', 'false');
            }
            if (Project.isValidNumber(rule.zoomMin)) {
                ruleNode.setAttribute('zoomMin', rule.zoomMin);
            }
            if (Project.isValidNumber(rule.zoomMax)) {
                ruleNode.setAttribute('zoomMax', rule.zoomMax);
            }
            const filterNode = document.createElementNS(null, 'Filter');
            if (Project.isValidString(rule.filter.label)) {
                filterNode.setAttribute('label', rule.filter.label);
            }
            if (rule.filter.comparisonType === FilterComparisonType.MATCH_NULL) {
                filterNode.setAttribute('value', '[NULL]');
            } else if (rule.filter.valueStr !== undefined) {
                filterNode.setAttribute('value', `'${rule.filter.valueStr}'`);
            } else if (Project.isValidNumber(rule.filter.valueNum)) {
                filterNode.setAttribute('value', rule.filter.valueNum);
            }
            if (Project.isValidString(rule.filter.fieldName)) {
                filterNode.setAttribute('fieldName', rule.filter.fieldName);
            }
            if (Project.isValidNumber(rule.filter.from)) {
                filterNode.setAttribute('from', rule.filter.from);
            }
            if (Project.isValidNumber(rule.filter.to)) {
                filterNode.setAttribute('to', rule.filter.to);
            }
            if (Project.isValidNumber(rule.filter.inclusiveTo)) {
                filterNode.setAttribute('inclusiveTo', rule.filter.inclusiveTo ? 'true' : 'false');
            }
            ruleNode.appendChild(filterNode);
            Project.createSymbolNodes(rule.symbols, ruleNode);
            parentNode.appendChild(ruleNode);
        });
    }

    toXML() {
        const xmlDoc = document.implementation.createDocument(null, 'Project', null);
        const version = document.createAttribute('version');
        version.nodeValue = AppConfig.constants.projectVersion;
        xmlDoc.documentElement.setAttributeNode(version);
        // Create color palette node
        const projectColorPalettesNode = document.createElementNS(null, 'ProjectColorPalettes');
        xmlDoc.documentElement.appendChild(projectColorPalettesNode);
        this.projectColorPalettes.forEach(colorPalette => {
            const colorPaletteNode = document.createElementNS(null, 'ColorPalette');
            colorPaletteNode.setAttribute('id', colorPalette.id);
            colorPaletteNode.setAttribute('title', colorPalette.title);
            colorPaletteNode.setAttribute('type', colorPalette.type);
            colorPaletteNode.setAttribute(
                'insufficientDataColor',
                colorPalette.insufficientDataColor,
            );
            colorPaletteNode.setAttribute('nullDataColor', colorPalette.nullDataColor);
            if (colorPalette.colorRamps.length !== 0) {
                colorPalette.colorRamps.forEach(colorRamp => {
                    const colorRampNode = document.createElementNS(null, 'ColorRamp');
                    colorRampNode.setAttribute('from', colorRamp.from);
                    colorRampNode.setAttribute('to', colorRamp.to);
                    colorRampNode.setAttribute('bias', colorRamp.bias);
                    colorPaletteNode.appendChild(colorRampNode);
                });
            }

            if (colorPalette.colors.length > 1) {
                const colorsNode = document.createElementNS(null, 'Colors');
                colorPalette.colors.forEach(color => {
                    const colorNode = document.createElementNS(null, 'Color');
                    colorNode.setAttribute('value', color);
                    colorsNode.appendChild(colorNode);
                });
                colorPaletteNode.appendChild(colorsNode);
            }
            if (colorPalette.strokeColors.length === 1) {
                const colorNode = document.createElementNS(null, 'Color');
                colorNode.setAttribute('fill', colorPalette.colors[0]);
                colorNode.setAttribute('stroke', colorPalette.strokeColors[0]);
                colorPaletteNode.appendChild(colorNode);
            }

            projectColorPalettesNode.appendChild(colorPaletteNode);
        });

        // Add Frames
        const framesNode = document.createElementNS(null, 'Frames');
        xmlDoc.documentElement.appendChild(framesNode);
        this.frames.forEach(frame => {
            const frameNode = document.createElementNS(null, 'Frame');
            frameNode.setAttribute('type', frame.type);
            frameNode.setAttribute('included', frame.included);
            frameNode.setAttribute('lockMode', frame.lockMode);

            if (frame.title && frame.title.trim().length) {
                const frameTitleNode = document.createElementNS(null, 'Title');
                frameTitleNode.appendChild(document.createTextNode(frame.title));
                frameNode.appendChild(frameTitleNode);
            }

            if (frame.description) {
                const frameDescriptionNode = document.createElementNS(null, 'Description');
                frameDescriptionNode.appendChild(document.createTextNode(frame.description));
                frameNode.appendChild(frameDescriptionNode);
            }

            const mapInstancesNode = document.createElementNS(null, 'MapInstances');
            frame.mapInstances.forEach(mapInstance => {
                const mapInstanceNode = document.createElementNS(null, 'MapInstance');
                mapInstanceNode.setAttribute('baseMapUid', mapInstance.metadataGroupId);
                mapInstanceNode.setAttribute('currentMapContextId', mapInstance.currentMapId);
                mapInstanceNode.setAttribute('satelliteVisible', mapInstance.isSatelliteVisible);
                mapInstanceNode.setAttribute(
                    'satelliteDataOverlaySatelliteHasColor',
                    mapInstance.satelliteDataOverlaySatelliteHasColor,
                );
                mapInstanceNode.setAttribute(
                    'satelliteDataOverlayDataOpacity',
                    mapInstance.satelliteDataOverlayDataOpacity,
                );
                mapInstanceNode.setAttribute(
                    'userEnteredTitle',
                    mapInstance.userEnteredTitle || '',
                );
                if (
                    mapInstance.preferredDataLayerId !== '' &&
                    mapInstance.preferredDataLayerId !== null &&
                    mapInstance.preferredDataLayerId !== undefined
                ) {
                    mapInstanceNode.setAttribute('automaticGeoSelector', 'false');
                    mapInstanceNode.setAttribute(
                        'preferredDataLayerId',
                        mapInstance.preferredDataLayerId,
                    );
                } else {
                    mapInstanceNode.setAttribute('automaticGeoSelector', 'true');
                    mapInstanceNode.setAttribute('preferredDataLayerId', '');
                }
                mapInstanceNode.setAttribute(
                    'shouldShowVisualizationTypeSwitcherInViewMode',
                    mapInstance.shouldShowVisualizationTypeSwitcherInViewMode !== false
                        ? 'true'
                        : 'false',
                );
                mapInstanceNode.setAttribute(
                    'shouldShowSearchInViewMode',
                    mapInstance.shouldShowSearchInViewMode !== false ? 'true' : 'false',
                );
                mapInstanceNode.setAttribute(
                    'shouldShowGeoSelectorInViewMode',
                    mapInstance.shouldShowGeoSelectorInViewMode !== false ? 'true' : 'false',
                );
                if (mapInstance.minZoomLevelRestriction !== undefined) {
                    mapInstanceNode.setAttribute(
                        'minZoomLevelRestriction',
                        mapInstance.minZoomLevelRestriction,
                    );
                }
                if (mapInstance.maxZoomLevelRestriction !== undefined) {
                    mapInstanceNode.setAttribute(
                        'maxZoomLevelRestriction',
                        mapInstance.maxZoomLevelRestriction,
                    );
                }

                const initialViewNode = document.createElementNS(null, 'InitialView');
                initialViewNode.setAttribute('centerLat', mapInstance.initialView.centerLat);
                initialViewNode.setAttribute('centerLon', mapInstance.initialView.centerLng);
                initialViewNode.setAttribute('zoom', mapInstance.initialView.zoom + 1);
                if (mapInstance.initialView.boundingBox) {
                    initialViewNode.setAttribute(
                        'boundingBoxNeLat',
                        mapInstance.initialView.boundingBoxNeLat,
                    );
                    initialViewNode.setAttribute(
                        'boundingBoxNeLng',
                        mapInstance.initialView.boundingBoxNeLng,
                    );
                    initialViewNode.setAttribute(
                        'boundingBoxSwLat',
                        mapInstance.initialView.boundingBoxSwLat,
                    );
                    initialViewNode.setAttribute(
                        'boundingBoxSwLng',
                        mapInstance.initialView.boundingBoxSwLng,
                    );
                }
                mapInstanceNode.appendChild(initialViewNode);

                const userDataLayersNode = document.createElementNS(null, 'UserDataLayers');
                mapInstance.userDataLayers.forEach(udl => {
                    const userDataLayerNode = document.createElementNS(null, 'UserDataLayer');
                    userDataLayerNode.setAttribute('id', udl.id);
                    userDataLayerNode.setAttribute('title', udl.title);
                    userDataLayerNode.setAttribute('includeInLegend', udl.includeInLegend);
                    userDataLayerNode.setAttribute('allowOverlap', udl.allowOverlap);
                    userDataLayerNode.setAttribute('visible', udl.visible);
                    userDataLayerNode.setAttribute('valueColumn', udl.valueColumn);
                    userDataLayerNode.setAttribute('labelingColumn', udl.labelingColumn);
                    userDataLayerNode.setAttribute('popupTitleColumn', udl.labelingColumn);
                    // Append metadata
                    if (udl.metadata) {
                        const userDataLayerMetadataNode = document.createElementNS(
                            null,
                            'UserDataLayerMetadata',
                        );
                        userDataLayerMetadataNode.setAttribute(
                            'userDataId',
                            udl.metadata.userDataId,
                        );
                        userDataLayerNode.appendChild(userDataLayerMetadataNode);
                    }
                    const styleRulesNode = document.createElementNS(null, 'StyleRules');
                    udl.styleRules.forEach(s => {
                        const styleRuleNode = document.createElementNS(null, 'StyleRule');
                        styleRuleNode.setAttribute('type', s.type);
                        styleRuleNode.setAttribute('markerPathId', s.markerPathId);
                        styleRuleNode.setAttribute('markerColor', s.markerColor);
                        if (s.value) {
                            styleRuleNode.setAttribute('value', s.value);
                        }
                        styleRuleNode.setAttribute('isHidden', !!s.isHidden);
                        styleRulesNode.appendChild(styleRuleNode);
                    });

                    userDataLayerNode.appendChild(styleRulesNode);
                    userDataLayerNode.setAttribute('interactive', udl.interactive);

                    if (udl.popupOtherColumns && udl.popupOtherColumns.length) {
                        const popupOtherColumnsNode = document.createElementNS(
                            null,
                            'PopupOtherColumns',
                        );
                        udl.popupOtherColumns.forEach(otherColumn => {
                            const popupOtherColumnNode = document.createElementNS(
                                null,
                                'PopupOtherColumn',
                            );
                            popupOtherColumnNode.setAttribute('popupOtherColumn', otherColumn);
                            popupOtherColumnsNode.appendChild(popupOtherColumnNode);
                        });
                        userDataLayerNode.appendChild(popupOtherColumnsNode);
                    }

                    userDataLayersNode.appendChild(userDataLayerNode);
                });
                mapInstanceNode.appendChild(userDataLayersNode);

                // Add library layers
                const libraryDataLayersNode = document.createElementNS(null, 'LibraryData');
                mapInstance.libraryDataLayers.forEach(olg => {
                    const libraryGroupNode = document.createElementNS(null, 'Group');
                    libraryGroupNode.setAttribute('id', olg.id);
                    libraryGroupNode.setAttribute('title', olg.title);
                    libraryGroupNode.setAttribute('visible', olg.visible);

                    const layersNode = document.createElementNS(null, 'Layers');

                    olg.layers.forEach(l => {
                        const layerNode = document.createElementNS(null, 'Layer');
                        layerNode.setAttribute('id', l.id);
                        layerNode.setAttribute('title', l.title);
                        layerNode.setAttribute('visible', l.visible);
                        layersNode.appendChild(layerNode);
                    });

                    libraryGroupNode.appendChild(layersNode);
                    libraryDataLayersNode.appendChild(libraryGroupNode);
                });
                mapInstanceNode.appendChild(libraryDataLayersNode);

                const annotationsNode = document.createElementNS(null, 'Annotations');
                mapInstance.annotations.forEach(a => {
                    const descriptionNode = document.createElementNS(null, 'description');
                    descriptionNode.appendChild(document.createTextNode(a.description));
                    const coordinatesNode = document.createElementNS(null, 'coordinates');
                    a.createdAtZoomLevel = Math.floor(a.createdAtZoomLevel);
                    let node;
                    let curvesNode;
                    let topLeftNode;
                    let bottomRightNode;
                    switch (a.type) {
                        case 'Polygon':
                            node = document.createElementNS(null, 'Polygon');
                            node.setAttribute('strokeColor', parseInt(a.strokeColor.substr(1), 16));
                            node.setAttribute('strokeWeight', a.strokeWeight);
                            node.setAttribute('fillColor', parseInt(a.fillColor.substr(1), 16));
                            node.setAttribute('useFill', a.useFill);
                            node.setAttribute('opacity', a.opacity);
                            node.setAttribute('title', a.title);
                            node.setAttribute('createdAtZoomLevel', a.createdAtZoomLevel);
                            node.setAttribute('includedInLegend', a.includedInLegend);
                            node.setAttribute(
                                'minZoom',
                                Project.isValidNumber(a.minZoom) ? a.minZoom : -1,
                            );
                            node.setAttribute(
                                'maxZoom',
                                Project.isValidNumber(a.maxZoom) ? a.maxZoom : -1,
                            );

                            node.appendChild(descriptionNode);

                            a.coordinates.forEach(c => {
                                const pointNode = document.createElementNS(null, 'point');
                                pointNode.setAttribute('lat', c[1]);
                                pointNode.setAttribute('long', c[0]);
                                coordinatesNode.appendChild(pointNode);
                            });
                            node.appendChild(coordinatesNode);

                            annotationsNode.appendChild(node);
                            break;
                        case 'Polyline':
                            node = document.createElementNS(null, 'PolyLine');
                            node.setAttribute('strokeColor', parseInt(a.strokeColor.substr(1), 16));
                            node.setAttribute('strokeWeight', a.strokeWeight);
                            node.setAttribute('opacity', a.opacity);
                            node.setAttribute('title', a.title);
                            node.setAttribute('createdAtZoomLevel', a.createdAtZoomLevel);
                            node.setAttribute('includedInLegend', a.includedInLegend);
                            node.setAttribute(
                                'minZoom',
                                Project.isValidNumber(a.minZoom) ? a.minZoom : -1,
                            );
                            node.setAttribute(
                                'maxZoom',
                                Project.isValidNumber(a.maxZoom) ? a.maxZoom : -1,
                            );
                            node.setAttribute(
                                'searchId',
                                Project.isValidNumber(a.searchId) ? a.searchId : -1,
                            );

                            node.appendChild(descriptionNode);

                            a.coordinates.forEach(c => {
                                const pointNode = document.createElementNS(null, 'point');
                                pointNode.setAttribute('lat', c[1]);
                                pointNode.setAttribute('long', c[0]);
                                coordinatesNode.appendChild(pointNode);
                            });
                            node.appendChild(coordinatesNode);

                            annotationsNode.appendChild(node);
                            break;
                        case 'Marker':
                            node = document.createElementNS(null, 'Marker');
                            node.setAttribute('fillColor', parseInt(a.fillColor.substr(1), 16));
                            node.setAttribute('opacity', a.opacity);
                            node.setAttribute('textSize', a.textSize);
                            node.setAttribute('textColor', parseInt(a.textColor.substr(1), 16));
                            node.setAttribute('labelPosition', a.labelPosition);
                            node.setAttribute('labelVisible', a.labelVisible);
                            node.setAttribute('markerPathId', a.markerPathId);
                            node.setAttribute('size', a.size);
                            node.setAttribute('title', a.title);
                            node.setAttribute('createdAtZoomLevel', a.createdAtZoomLevel);
                            node.setAttribute('includedInLegend', a.includedInLegend);
                            node.setAttribute(
                                'minZoom',
                                Project.isValidNumber(a.minZoom) ? a.minZoom : -1,
                            );
                            node.setAttribute(
                                'maxZoom',
                                Project.isValidNumber(a.maxZoom) ? a.maxZoom : -1,
                            );
                            node.setAttribute(
                                'searchId',
                                Project.isValidNumber(a.searchId) ? a.searchId : -1,
                            );

                            node.appendChild(descriptionNode);

                            a.coordinates.forEach(c => {
                                const pointNode = document.createElementNS(null, 'point');
                                pointNode.setAttribute('lat', c[1]);
                                pointNode.setAttribute('long', c[0]);
                                coordinatesNode.appendChild(pointNode);
                            });
                            node.appendChild(coordinatesNode);

                            annotationsNode.appendChild(node);
                            break;
                        case 'Label':
                            node = document.createElementNS(null, 'Text');
                            node.setAttribute('fillColor', parseInt(a.fillColor.substr(1), 16));
                            node.setAttribute('useFill', a.useFill);
                            node.setAttribute('rotationAngle', a.rotationAngle);
                            node.setAttribute('textSize', a.textSize);
                            node.setAttribute('textColor', parseInt(a.textColor.substr(1), 16));
                            node.setAttribute('opacity', a.opacity);
                            node.setAttribute('title', a.title);
                            node.setAttribute('createdAtZoomLevel', a.createdAtZoomLevel);
                            node.setAttribute('includedInLegend', a.includedInLegend);
                            node.setAttribute(
                                'minZoom',
                                Project.isValidNumber(a.minZoom) ? a.minZoom : -1,
                            );
                            node.setAttribute(
                                'maxZoom',
                                Project.isValidNumber(a.maxZoom) ? a.maxZoom : -1,
                            );

                            node.appendChild(descriptionNode);

                            a.coordinates.forEach(c => {
                                const pointNode = document.createElementNS(null, 'point');
                                pointNode.setAttribute('lat', c[1]);
                                pointNode.setAttribute('long', c[0]);
                                coordinatesNode.appendChild(pointNode);
                            });
                            node.appendChild(coordinatesNode);

                            annotationsNode.appendChild(node);
                            break;
                        case 'FlowArrow':
                            node = document.createElementNS(null, 'Arrow');

                            node.setAttribute('strokeWeight', a.strokeWeight);
                            node.setAttribute('strokeColor', parseInt(a.strokeColor.substr(1), 16));
                            node.setAttribute('fromWidth', a.fromWidth);
                            node.setAttribute('toWidth', a.toWidth);
                            node.setAttribute('tipWidth', a.tipWidth);
                            node.setAttribute('fillColor', parseInt(a.fillColor.substr(1), 16));
                            node.setAttribute('useFill', a.useFill);
                            node.setAttribute('opacity', a.opacity);
                            node.setAttribute('title', a.title);
                            node.setAttribute('createdAtZoomLevel', a.createdAtZoomLevel);
                            node.setAttribute('includedInLegend', a.includedInLegend);
                            node.setAttribute(
                                'minZoom',
                                Project.isValidNumber(a.minZoom) ? a.minZoom : -1,
                            );
                            node.setAttribute(
                                'maxZoom',
                                Project.isValidNumber(a.maxZoom) ? a.maxZoom : -1,
                            );

                            node.appendChild(descriptionNode);

                            curvesNode = document.createElementNS(null, 'curves');

                            a.curves.forEach(c => {
                                const curveNode = document.createElementNS(null, 'curve');
                                Object.keys(c).forEach(p => {
                                    const pointNode = document.createElementNS(
                                        null,
                                        p.toLowerCase().substr(1),
                                    );
                                    pointNode.setAttribute('lat', c[p][1]);
                                    pointNode.setAttribute('long', c[p][0]);
                                    curveNode.appendChild(pointNode);
                                });
                                curvesNode.appendChild(curveNode);
                            });
                            node.appendChild(curvesNode);

                            annotationsNode.appendChild(node);
                            break;
                        case 'Freehand':
                            node = document.createElementNS(null, 'FreehandLine');

                            node.setAttribute('strokeWeight', a.strokeWeight);
                            node.setAttribute('strokeColor', parseInt(a.strokeColor.substr(1), 16));
                            node.setAttribute('opacity', a.opacity);
                            node.setAttribute('title', a.title);
                            node.setAttribute('createdAtZoomLevel', a.createdAtZoomLevel);
                            node.setAttribute('includedInLegend', a.includedInLegend);
                            node.setAttribute(
                                'minZoom',
                                Project.isValidNumber(a.minZoom) ? a.minZoom : -1,
                            );
                            node.setAttribute(
                                'maxZoom',
                                Project.isValidNumber(a.maxZoom) ? a.maxZoom : -1,
                            );

                            node.appendChild(descriptionNode);

                            a.coordinates.forEach(c => {
                                const pointNode = document.createElementNS(null, 'point');
                                pointNode.setAttribute('lat', c[1]);
                                pointNode.setAttribute('long', c[0]);
                                coordinatesNode.appendChild(pointNode);
                            });
                            node.appendChild(coordinatesNode);

                            annotationsNode.appendChild(node);
                            break;
                        case 'Image':
                            node = document.createElementNS(null, 'Image');

                            node.setAttribute('opacity', a.opacity);
                            node.setAttribute('title', a.title);
                            node.setAttribute('rotationAngle', a.rotationAngle);
                            node.setAttribute('filename', a.fileName);
                            node.setAttribute('createdAtZoomLevel', a.createdAtZoomLevel);
                            node.setAttribute('includedInLegend', a.includedInLegend);
                            node.setAttribute(
                                'minZoom',
                                Project.isValidNumber(a.minZoom) ? a.minZoom : -1,
                            );
                            node.setAttribute(
                                'maxZoom',
                                Project.isValidNumber(a.maxZoom) ? a.maxZoom : -1,
                            );

                            node.appendChild(descriptionNode);

                            topLeftNode = document.createElementNS(null, 'point');
                            topLeftNode.setAttribute('lat', a.topLeftPoint[1]);
                            topLeftNode.setAttribute('long', a.topLeftPoint[0]);
                            coordinatesNode.appendChild(topLeftNode);

                            bottomRightNode = document.createElementNS(null, 'point');
                            bottomRightNode.setAttribute('lat', a.bottomRightPoint[1]);
                            bottomRightNode.setAttribute('long', a.bottomRightPoint[0]);
                            coordinatesNode.appendChild(bottomRightNode);

                            node.appendChild(coordinatesNode);

                            annotationsNode.appendChild(node);
                            break;
                        case 'Hotspot':
                            node = document.createElementNS(null, 'Hotspot');

                            node.setAttribute('strokeColor', parseInt(a.strokeColor.substr(1), 16));
                            node.setAttribute('strokeWeight', a.strokeWeight);
                            node.setAttribute('opacity', a.opacity);
                            node.setAttribute('title', a.title);
                            node.setAttribute('createdAtZoomLevel', a.createdAtZoomLevel);
                            node.setAttribute('includedInLegend', a.includedInLegend);
                            node.setAttribute(
                                'minZoom',
                                Project.isValidNumber(a.minZoom) ? a.minZoom : -1,
                            );
                            node.setAttribute(
                                'maxZoom',
                                Project.isValidNumber(a.maxZoom) ? a.maxZoom : -1,
                            );

                            node.appendChild(descriptionNode);

                            topLeftNode = document.createElementNS(null, 'topleftpoint');
                            topLeftNode.setAttribute('lat', a.topLeftPoint[1]);
                            topLeftNode.setAttribute('long', a.topLeftPoint[0]);
                            node.appendChild(topLeftNode);

                            bottomRightNode = document.createElementNS(null, 'bottomrightpoint');
                            bottomRightNode.setAttribute('lat', a.bottomRightPoint[1]);
                            bottomRightNode.setAttribute('long', a.bottomRightPoint[0]);
                            node.appendChild(bottomRightNode);

                            annotationsNode.appendChild(node);
                            break;
                        case 'Shape':
                            node = document.createElementNS(null, 'FreehandPolygon');
                            node.setAttribute('strokeColor', parseInt(a.strokeColor.substr(1), 16));
                            node.setAttribute('strokeWeight', a.strokeWeight);
                            node.setAttribute('fillColor', parseInt(a.fillColor.substr(1), 16));
                            node.setAttribute('useFill', a.useFill);
                            node.setAttribute('opacity', a.opacity);
                            node.setAttribute('title', a.title);
                            node.setAttribute('createdAtZoomLevel', a.createdAtZoomLevel);
                            node.setAttribute('includedInLegend', a.includedInLegend);
                            node.setAttribute(
                                'minZoom',
                                Project.isValidNumber(a.minZoom) ? a.minZoom : -1,
                            );
                            node.setAttribute(
                                'maxZoom',
                                Project.isValidNumber(a.maxZoom) ? a.maxZoom : -1,
                            );

                            node.appendChild(descriptionNode);

                            a.coordinates.forEach(c => {
                                const pointNode = document.createElementNS(null, 'point');
                                pointNode.setAttribute('lat', c[1]);
                                pointNode.setAttribute('long', c[0]);
                                coordinatesNode.appendChild(pointNode);
                            });
                            node.appendChild(coordinatesNode);

                            annotationsNode.appendChild(node);
                            break;
                    }
                });
                mapInstanceNode.appendChild(annotationsNode);
                if (mapInstance.annotationLegend) {
                    const annotationsLegendNode = document.createElementNS(
                        null,
                        'AnnotationLegend',
                    );
                    annotationsLegendNode.setAttribute('title', mapInstance.annotationLegend.title);
                    annotationsLegendNode.setAttribute(
                        'visible',
                        mapInstance.annotationLegend.visible,
                    );
                    mapInstance.annotationLegend.legendItems.forEach(li => {
                        const legendItemNode = document.createElementNS(null, 'LegendItem');
                        legendItemNode.setAttribute('color', parseInt(li.color.substr(1), 16));
                        legendItemNode.setAttribute('included', li.included ? 'true' : 'false');
                        legendItemNode.setAttribute('title', li.title);
                        legendItemNode.setAttribute('order', li.order);
                        const iconNode = document.createElementNS(null, 'Icon');
                        iconNode.appendChild(document.createTextNode(li.icon));
                        legendItemNode.appendChild(iconNode);
                        annotationsLegendNode.appendChild(legendItemNode);
                    });
                    mapInstanceNode.appendChild(annotationsLegendNode);
                }

                const dataGeoFiltersNode = document.createElementNS(null, 'DataGeoFilters');
                Object.keys(mapInstance.dataGeoFilters).forEach(id => {
                    const dataGeoFilter = mapInstance.dataGeoFilters[id];
                    const dataGeoFilterNode = document.createElementNS(null, 'DataGeoFilter');
                    dataGeoFilterNode.setAttribute('summaryLevelId', dataGeoFilter.summaryLevelId);
                    dataGeoFilterNode.setAttribute('dataLayerId', dataGeoFilter.dataLayerId);
                    dataGeoFilterNode.setAttribute(
                        'nestedSummaryLevelsIds',
                        dataGeoFilter.nestedSummaryLevelsIds.join(','),
                    );
                    const featuresNode = document.createElementNS(null, 'Features');
                    dataGeoFilter.features.forEach(feature => {
                        const featureNode = document.createElementNS(null, 'Feature');
                        featureNode.setAttribute('columnValue', feature.columnValue);
                        featureNode.setAttribute('geoQName', feature.geoQName);
                        featuresNode.appendChild(featureNode);
                    });
                    dataGeoFilterNode.appendChild(featuresNode);
                    dataGeoFiltersNode.appendChild(dataGeoFilterNode);
                });
                mapInstanceNode.appendChild(dataGeoFiltersNode);

                // /////////////////////
                // DATA FILTERS NODE //
                // ///////////////////
                const dataFiltersNode = document.createElementNS(null, 'DataFilters');
                if (mapInstance.hasDataFilter) {
                    const dataFilter = mapInstance.dataFilter;
                    // Data filter node
                    const dataFilterNode = document.createElementNS(null, 'DataFilter');
                    dataFilterNode.setAttribute('filterCombiner', dataFilter.filterCombiner);
                    // Filters nodes
                    const filtersNode = document.createElementNS(null, 'Filters');

                    Object.keys(dataFilter.filters).forEach(id => {
                        const filter = dataFilter.filters[id];
                        // Do not save invalid filters
                        if (filter.status < FilterStatus.VALID) return;

                        // Filter Rule Node
                        const filterRuleNode = document.createElementNS(null, 'FilterRule');
                        const { filterId, type, field, value, status, baseVariables } = filter;
                        filterRuleNode.setAttribute('filterId', filterId);
                        filterRuleNode.setAttribute('value', value);
                        filterRuleNode.setAttribute('type', type);
                        filterRuleNode.setAttribute('status', status);

                        // Filter Rule Metadata Node
                        const dataFilterMetadata = document.createElementNS(null, 'Metadata');
                        if (field.categoryName) {
                            dataFilterMetadata.setAttribute('categoryName', field.categoryName);
                        }
                        if (field.tableName) {
                            dataFilterMetadata.setAttribute('tableName', field.tableName);
                        }
                        dataFilterMetadata.setAttribute('surveyName', field.surveyName);
                        dataFilterMetadata.setAttribute(
                            'datasetAbbreviation',
                            field.datasetAbbreviation,
                        );
                        dataFilterMetadata.setAttribute('datasetId', field.datasetId);
                        dataFilterMetadata.setAttribute('tableGuid', field.tableGuid);
                        dataFilterMetadata.setAttribute('fieldName', field.fieldName);
                        dataFilterMetadata.setAttribute('qLabel', field.qLabel);
                        dataFilterMetadata.setAttribute('baseFieldName', field.baseFieldName);
                        dataFilterMetadata.setAttribute('fieldDataType', field.fieldDataType);
                        dataFilterMetadata.setAttribute('variableCode', field.variableCode);
                        dataFilterMetadata.setAttribute('formatting', field.formatting);

                        const dataFilterFieldOptions = document.createElementNS(
                            null,
                            'OptionsList',
                        );
                        field.optionsList.forEach(o => {
                            const option = document.createElementNS(null, 'Option');
                            option.setAttribute('value', o);
                            dataFilterFieldOptions.appendChild(option);
                        });
                        dataFilterMetadata.appendChild(dataFilterFieldOptions);

                        const dataFilterBaseVariables = document.createElementNS(
                            null,
                            'BaseVariables',
                        );
                        baseVariables.forEach(v => {
                            const baseVariable = document.createElementNS(null, 'BaseVariable');
                            baseVariable.setAttribute('uuid', v.uuid);
                            baseVariable.setAttribute('label', v.label);
                            baseVariable.setAttribute('qLabel', v.qLabel);

                            dataFilterBaseVariables.appendChild(baseVariable);
                        });

                        filterRuleNode.appendChild(dataFilterMetadata);
                        filterRuleNode.appendChild(dataFilterBaseVariables);
                        filtersNode.appendChild(filterRuleNode);
                    });
                    dataFilterNode.appendChild(filtersNode);
                    dataFiltersNode.appendChild(dataFilterNode);
                }
                mapInstanceNode.appendChild(dataFiltersNode);

                // /////////////////////////
                // LOCATION ANALYSIS NODE //
                // ////////////////////////
                const locationAnalysisNode = document.createElementNS(null, 'LocationAnalysisItem');
                if (
                    mapInstance.isLocationAnalysisActive &&
                    mapInstance.locationAnalysisItem.isComplete
                ) {
                    const locationAnalysisItem = mapInstance.locationAnalysisItem;
                    // Set attributes
                    locationAnalysisNode.setAttribute('id', locationAnalysisItem.id);
                    locationAnalysisNode.setAttribute('type', locationAnalysisItem.type);
                    locationAnalysisNode.setAttribute('value', locationAnalysisItem.value);
                    locationAnalysisNode.setAttribute('lng', locationAnalysisItem.point.lng);
                    locationAnalysisNode.setAttribute('lat', locationAnalysisItem.point.lat);
                    locationAnalysisNode.setAttribute(
                        'analysisTypeId',
                        locationAnalysisItem.analysisTypeId,
                    );
                    locationAnalysisNode.setAttribute(
                        'selection',
                        [...locationAnalysisItem.selection].toString(),
                    );
                    // Add node
                    mapInstanceNode.appendChild(locationAnalysisNode);
                }

                const dataThemeNode = document.createElementNS(null, 'DataTheme');
                if (Project.isValidString(mapInstance.dataTheme.id)) {
                    dataThemeNode.setAttribute('id', mapInstance.dataTheme.id);
                } else {
                    dataThemeNode.setAttribute('id', '');
                }
                dataThemeNode.setAttribute('title', mapInstance.dataTheme.title);
                dataThemeNode.setAttribute('colorPaletteId', mapInstance.dataTheme.colorPaletteId);
                dataThemeNode.setAttribute(
                    'adjustmentDollarYear',
                    mapInstance.dataTheme.adjustmentDollarYear,
                );
                dataThemeNode.setAttribute(
                    'colorPaletteType',
                    mapInstance.dataTheme.colorPaletteType,
                );
                dataThemeNode.setAttribute(
                    'colorPaletteFlipped',
                    mapInstance.dataTheme.colorPaletteFlipped ? 'true' : 'false',
                );
                dataThemeNode.setAttribute(
                    'dotDensityValueHint',
                    mapInstance.dataTheme.dotDensityValueHint,
                );
                dataThemeNode.setAttribute('iconUrl', '');
                dataThemeNode.setAttribute(
                    'bubbleValueType',
                    mapInstance.dataTheme.bubbleValueType,
                );
                dataThemeNode.setAttribute(
                    'shadedValueType',
                    mapInstance.dataTheme.shadedValueType,
                );
                if (mapInstance.dataTheme.insufficientBase !== undefined) {
                    dataThemeNode.setAttribute(
                        'insufficientBase',
                        mapInstance.dataTheme.insufficientBase,
                    );
                }

                const variableSelectionNode = document.createElementNS(null, 'VariableSelection');
                mapInstance.dataTheme.variableSelection.items.forEach(variable => {
                    const variableNode = document.createElementNS(null, 'Variable');
                    variableNode.setAttribute('variableGuid', variable.variableGuid);
                    variableNode.setAttribute('tableGuid', variable.tableGuid);
                    variableNode.setAttribute('datasetAbbrev', variable.datasetAbbreviation);
                    variableNode.setAttribute('surveyName', variable.surveyName);
                    variableNode.setAttribute(
                        'percentBaseVariableGuidUserOverride',
                        variable.percentBaseVariableGuidUserOverride,
                    );
                    variableSelectionNode.appendChild(variableNode);
                });
                dataThemeNode.appendChild(variableSelectionNode);

                const renderingNode = document.createElementNS(null, 'Rendering');
                dataThemeNode.appendChild(renderingNode);
                mapInstance.dataTheme.rendering.forEach(renderer => {
                    let rendererNode;
                    let fieldsNode;
                    let symbolNode;
                    let brushNode;
                    switch (renderer.type) {
                        case 'ValueRenderer':
                            rendererNode = document.createElementNS(null, 'ValueRenderer');
                            rendererNode.setAttribute(
                                'insufDataSymbol',
                                renderer.insufficientDataRuleIndex,
                            );
                            rendererNode.setAttribute('interactive', 'true');
                            rendererNode.setAttribute('displayInLegend', 'true');
                            rendererNode.setAttribute('defaultSymbolIndex', '-1');
                            rendererNode.setAttribute('nullDataSymbol', renderer.nullDataRuleIndex);
                            Project.createRuleNodes(renderer.rules, rendererNode);
                            break;
                        case 'MultiValueRenderer':
                            rendererNode = document.createElementNS(null, 'MultiValueRenderer');
                            rendererNode.setAttribute('interactive', 'true');
                            rendererNode.setAttribute('displayInLegend', 'true');
                            fieldsNode = document.createElementNS(null, 'ValueFields');
                            rendererNode.appendChild(fieldsNode);
                            renderer.rules.forEach((rulesArrayEntry, index) => {
                                const valueFieldNode = document.createElementNS(null, 'ValueField');
                                valueFieldNode.setAttribute(
                                    'name',
                                    renderer.valuesFieldsNames[index],
                                );
                                valueFieldNode.setAttribute(
                                    'insufDataSymbol',
                                    renderer.insufficientDataRuleIndex[index],
                                );
                                valueFieldNode.setAttribute(
                                    'nullDataSymbol',
                                    renderer.nullDataRuleIndex[index],
                                );
                                valueFieldNode.setAttribute('defaultSymbolIndex', '-1');
                                Project.createRuleNodes(rulesArrayEntry, valueFieldNode);
                                fieldsNode.appendChild(valueFieldNode);
                            });
                            break;
                        case 'ValueDotDensityRenderer':
                            rendererNode = document.createElementNS(
                                null,
                                'ValueDotDensityRenderer',
                            );
                            rendererNode.setAttribute('dotValue', renderer.dotValue);
                            fieldsNode = document.createElementNS(null, 'DotValueFields');
                            rendererNode.appendChild(fieldsNode);
                            renderer.dotValueFieldNames.forEach(dotValueFieldName => {
                                const dotValueFieldNode = document.createElementNS(
                                    null,
                                    'DotValueField',
                                );
                                dotValueFieldNode.setAttribute('name', dotValueFieldName);
                                fieldsNode.appendChild(dotValueFieldNode);
                            });
                            symbolNode = document.createElementNS(null, 'DotDensitySymbol');
                            symbolNode.setAttribute('id', '');
                            brushNode = document.createElementNS(null, 'Brush');
                            brushNode.setAttribute('color', '#ff00f0');
                            brushNode.setAttribute('size', '1');
                            symbolNode.appendChild(brushNode);
                            rendererNode.appendChild(symbolNode);
                            // Project.createSymbolNodes(renderer.symbols, rendererNode);

                            break;
                        case 'BubbleRenderer':
                            rendererNode = document.createElementNS(null, 'BubbleRenderer');
                            rendererNode.setAttribute(
                                'insuffDataRuleIndex',
                                renderer.insufficientDataRuleIndex,
                            );
                            rendererNode.setAttribute(
                                'bubbleSizeFactor',
                                renderer.bubbleSizeFactor,
                            );
                            rendererNode.setAttribute('maxBubbleSize', renderer.maxBubbleSize);
                            rendererNode.setAttribute('interactive', 'true');
                            rendererNode.setAttribute('defaultSymbolIndex', '0');
                            rendererNode.setAttribute('title', renderer.sizeTitle);
                            rendererNode.setAttribute(
                                'fieldBubbleSize',
                                renderer.bubbleSizeFieldName,
                            );
                            Project.createRuleNodes(renderer.rules, rendererNode);
                            break;
                        case 'DotDensityRenderer':
                            rendererNode = document.createElementNS(null, 'DotDensityRenderer');
                            rendererNode.setAttribute('dotValue', renderer.dotValue);
                            rendererNode.setAttribute(
                                'dotValueFieldName',
                                renderer.dotValueFieldName,
                            );
                            Project.createSymbolNodes(renderer.symbols, rendererNode);
                            break;
                    }
                    renderingNode.appendChild(rendererNode);
                    const fieldListNode = document.createElementNS(null, 'FieldList');
                    if (Project.isValidString(renderer.fieldList.id)) {
                        fieldListNode.setAttribute('id', renderer.fieldList.id);
                    } else {
                        fieldListNode.setAttribute('id', '');
                    }
                    Project.createFieldNodes(renderer.fieldList.fields, fieldListNode);
                    rendererNode.appendChild(fieldListNode);
                    const interactivityNode = document.createElementNS(null, 'Interactivity');
                    interactivityNode.setAttribute('enabled', 'true');
                    rendererNode.appendChild(interactivityNode);
                });

                mapInstanceNode.appendChild(dataThemeNode);
                const layerOverridesNode = document.createElementNS(null, 'LayerOverrides');
                if (mapInstance.layerOverrides) {
                    Object.keys(mapInstance.layerOverrides)
                        .map(k => mapInstance.layerOverrides[k])
                        .forEach(layerOverride => {
                            const layerOverrideNode = document.createElementNS(
                                null,
                                'LayerOverride',
                            );
                            layerOverrideNode.setAttribute('id', layerOverride.id);
                            layerOverrideNode.setAttribute('visibility', layerOverride.visibility);
                            layerOverridesNode.appendChild(layerOverrideNode);
                        });
                }
                mapInstanceNode.appendChild(layerOverridesNode);

                mapInstancesNode.appendChild(mapInstanceNode);
            });
            frameNode.appendChild(mapInstancesNode);

            framesNode.appendChild(frameNode);
        });

        return new XMLSerializer().serializeToString(xmlDoc);
    }
}

export default Project;
