//!
//      

const {SegmentVector} = require('../segment');
const VertexBuffer = require('../../gl/vertex_buffer');
const IndexBuffer = require('../../gl/index_buffer');
const {ProgramConfigurationSet} = require('../program_configuration');
const createVertexArrayType = require('../vertex_array_type');
const {TriangleIndexArray} = require('../index_array_type');
const loadGeometry = require('../load_geometry');
const EXTENT = require('../extent');
const evaluateExpression = require('../../util/dragonfly_util').evaluateExpression;


                                                                                                            
                                                               
                                                      
                                                         
                                                

const bubbleInterface = {
    layoutAttributes: [
        {name: 'a_pos', components: 2, type: 'Int16'},
        {name: 'a_radius', components: 1, type: 'Int16'},
        {name: 'a_outline_width', components: 1, type: 'Int16'},
    ],
    indexArrayType: TriangleIndexArray,

    paintAttributes: [
        {property: 'bubble-color'},
        {property: 'bubble-opacity'},
        {property: 'bubble-outline-color'},
        {property: 'bubble-radius-multiplier'},
        {property: 'bubble-max-radius'},
        {property: 'bubble-max-radius-outline-color'},
        {property: 'bubble-max-radius-outline-width'}
    ]
};

function addBubbleVertex(layoutVertexArray, x, y, extrudeX, extrudeY, radius, outlineWidth) {
    layoutVertexArray.emplaceBack(
        (x * 2) + ((extrudeX + 1) / 2),
        (y * 2) + ((extrudeY + 1) / 2),
        radius,
        outlineWidth);
}

const LayoutVertexArrayType = createVertexArrayType(bubbleInterface.layoutAttributes);

/**
 * Bubbles are represented by two triangles.
 *
 * Each corner has a pos that is the center of the bubble and an extrusion
 * vector that is where it points.
 * @private
 */
class BubbleBucket                   {
                                              

                  
                 
                        
                              

                                   
                                     

                            
                             

                                                   
                            
                             
                      

    constructor(options     ) {
        this.zoom = options.zoom;
        this.overscaling = options.overscaling;
        this.layers = options.layers;
        this.index = options.index;

        this.layoutVertexArray = new LayoutVertexArrayType(options.layoutVertexArray);
        this.indexArray = new TriangleIndexArray(options.indexArray);
        this.programConfigurations = new ProgramConfigurationSet(bubbleInterface, options.layers, options.zoom, options.programConfigurations);
        this.segments = new SegmentVector(options.segments);
    }

    populate(features                       , options                    ) {
        let autoSourceFilter; //!
        if (this.layers[0].autoSource) { //!
            for (const autoLayer of this.layers[0].autoSource) { //!
                if (autoLayer.minzoom <= this.zoom && autoLayer.maxzoom > this.zoom && autoLayer.filter) { //!
                    autoSourceFilter = autoLayer.filter; //!
                    break; //!
                } //!
            } //!
        } //!
        for (const {feature, index, sourceLayerIndex} of features) {
            if (autoSourceFilter && !autoSourceFilter({zoom: this.zoom}, feature)) continue; //!
            if (this.layers[0]._featureFilter({zoom: this.zoom}, feature)) {
                const geometry = loadGeometry(feature);
                this.addFeature(feature, geometry);
                options.featureIndex.insert(feature, geometry, index, sourceLayerIndex, this.index);
            }
        }
    }
    isEmpty() {
        return this.layoutVertexArray.length === 0;
    }

    serialize(transferables                      )                   {
        return {
            zoom: this.zoom,
            layerIds: this.layers.map((l) => l.id),
            layoutVertexArray: this.layoutVertexArray.serialize(transferables),
            indexArray: this.indexArray.serialize(transferables),
            programConfigurations: this.programConfigurations.serialize(transferables),
            segments: this.segments.get(),
        };
    }

    upload(gl                       ) {
        this.layoutVertexBuffer = new VertexBuffer(gl, this.layoutVertexArray);
        this.indexBuffer = new IndexBuffer(gl, this.indexArray);
        this.programConfigurations.upload(gl);
    }

    destroy() {
        if (!this.layoutVertexBuffer) return;
        this.layoutVertexBuffer.destroy();
        this.indexBuffer.destroy();
        this.programConfigurations.destroy();
        this.segments.destroy();
    }

    addFeature(feature                   , geometry                     ) {
        const radius = Math.min(evaluateExpression(this.layers[0].layout['bubble-radius'], feature.properties), 32767); // Clamp radius to Max Int16 value
        const outlineWidth = evaluateExpression(this.layers[0].paint['bubble-outline-width'], feature.properties);
        this.metadata = this.metadata || { features: {} }; //!
        const bubbleElementLengthBefore = this.indexArray.length; //!
        for (const ring of geometry) {
            for (const point of ring) {
                const x = point.x;
                const y = point.y;

                // Do not include points that are outside the tile boundaries.
                if (x < 0 || x >= EXTENT || y < 0 || y >= EXTENT) continue;

                // this geometry will be of the Point type, and we'll derive
                // two triangles from it.
                //
                // ┌─────────┐
                // │ 3     2 │
                // │         │
                // │ 0     1 │
                // └─────────┘

                const segment = this.segments.prepareSegment(4, this.layoutVertexArray, this.indexArray);
                const index = segment.vertexLength;

                addBubbleVertex(this.layoutVertexArray, x, y, -1, -1, radius, outlineWidth);
                addBubbleVertex(this.layoutVertexArray, x, y, 1, -1, radius, outlineWidth);
                addBubbleVertex(this.layoutVertexArray, x, y, 1, 1, radius, outlineWidth);
                addBubbleVertex(this.layoutVertexArray, x, y, -1, 1, radius, outlineWidth);

                this.indexArray.emplaceBack(index, index + 1, index + 2);
                this.indexArray.emplaceBack(index, index + 3, index + 2);

                segment.vertexLength += 4;
                segment.primitiveLength += 2;
            }
        }

        this.programConfigurations.populatePaintArrays(this.layoutVertexArray.length, feature);

        const bubbleElementLengthAfter = this.indexArray.length; //!
        this.metadata.features[feature.id] = { //!
            calculatedRadius: radius,
            bubbleElement: { //!
                count: bubbleElementLengthAfter - bubbleElementLengthBefore, //!
                offset: bubbleElementLengthBefore //!
            } //!
        }; //!
    }
}

BubbleBucket.programInterface = bubbleInterface;

module.exports = BubbleBucket;
