import React from 'react';

export const annotationMetadata = {
    marker: {
        svgPath: 'M13,35c0,0-13-14.625-13-21.875C0,5.876,5.82,0,13,0c7.181,0,13,5.876,13,13.125C26,20.375,13,35,13,35',
        icon: <i className="socex-icon-marker-marker-default" />,
        helperImage: 'draw_marker_helper',
        group: 0,
        type: 'marker',
    },
    polyline: {
        svgPath: 'M2.652,37c-0.768,0-1.529-0.326-2.053-0.955c-0.774-0.932-0.785-2.229-0.104-3.163c0.747-1.482,4.146-10.758,7.084-19.097c0.356-1.013,1.307-1.707,2.396-1.75c1.078-0.042,2.094,0.573,2.533,1.555l6.146,13.737 l9.197-25.589c0.488-1.355,2.004-2.067,3.381-1.587c1.379,0.479,2.104,1.966,1.617,3.321l-11.43,31.793c-0.361,1.007-1.313,1.695-2.399,1.734c-1.079,0.027-2.088-0.576-2.524-1.554l-6.129-13.702c-4.931,13.779-5.427,14.178-6.04,14.669C3.835,36.807,3.242,37,2.652,37z',
        icon: <i className="socex-icon-polyline-annotation" />,
        helperImage: 'draw_line_helper',
        group: 1,
        type: 'polyline',
    },
    freehand: {
        svgPath: 'M5.523,32.847c1.866-2.748,2.913-5.901,4.137-8.944c0.514-1.275,0.973-2.975,1.888-4.041c1.166-1.359,2.967,0.882,3.882,1.479c4.17,2.719,8.798,0.733,11.402-2.975c2.667-3.796,3.886-8.543,4.666-13.023c0.223-1.279,1.092-3.772-0.156-4.748c-1.435-1.123-4.326-0.599-5.075,1.097c-0.666,1.507-3.723,19.935-8.11,15.394c-3.506-3.629-7.865-4.444-11.097-0.192c-2.58,3.394-3.756,7.601-5.418,11.446c-0.488,1.128-2.144,3.073-1.492,4.295C1.084,34.386,4.438,34.448,5.523,32.847C6.687,31.135,3.947,35.171,5.523,32.847z',
        icon: <i className="socex-icon-draw-line" />,
        helperImage: 'draw_freehand_helper',
        group: 1,
        type: 'freehand',
    },
    flowarrow: {
        svgPath: 'M12.081,8.659C10.991,6.312,10.503,2.983,10.897,0C7.356,0.412,3.722,1.981,0.261,3.587C1.641,4.179,3.02,4.771,4.397,5.363C-2.19,14.091-1.657,26.21,7.908,35c2.03-1.658,4.061-3.313,6.092-4.971C6.008,24.498,3.259,15.063,7.659,6.763C9.133,7.395,10.605,8.028,12.081,8.659z',
        icon: <i className="socex-icon-draw-arrow" />,
        helperImage: 'draw_arrow_helper',
        group: 1,
        type: 'flowarrow',
    },
    polygon: {
        svgPath: 'M0,0L20,0L20,15L35,15L35,30L0,30z',
        icon: <i className="socex-icon-polygon-annotation" />,
        helperImage: 'draw_polygon_helper',
        group: 1,
        type: 'polygon',
    },
    shape: {
        svgPath: 'M10.614,25.755c-0.964-0.47-1.89-1.069-2.765-1.809c-3.089-2.608-6.347-5.763-7.589-9.759c-0.4-1.288-0.384-2.902,0.367-4.083c1.928-3.021,5.261-2.461,8.241-1.988c3.173,0.502,6.296,1.576,9.167-0.443c1.767-1.241,3.043-2.975,4.562-4.48c1.5-1.488,4.572-4.123,6.839-2.86c2.042,1.14,2.518,5.131,2.561,7.178c0.097,4.871-1.718,10.5-4.94,14.164c-1.408,1.601-3.354,3.173-5.3,4.05C18.041,27.396,14.085,27.443,10.614,25.755z',
        icon: <i className="socex-icon-freehand-polyline-annotation" />,
        helperImage: 'draw_shape_helper',
        group: 1,
        type: 'shape',
    },
    label: {
        svgPath: 'M9.498,13.782H4.642l-0.978,3.914H0l4.979-16.46H9.3l4.979,16.46h-3.808L9.498,13.782z M8.765,10.882L8.397,9.364C7.959,7.724,7.521,5.78,7.104,4.063H7.006c-0.391,1.742-0.805,3.66-1.243,5.301l-0.392,1.518H8.765z M15.72,0h3.588v4.343l-0.099,1.918c0.926-0.857,2.074-1.391,3.195-1.391c2.93,0,4.762,2.5,4.762,6.336c0,4.318-2.489,6.791-5.104,6.791c-1.072,0-2.195-0.555-3.146-1.615h-0.1l-0.291,1.313h-2.809L15.72,0L15.72,0z M21.234,14.972c1.244,0,2.244-1.111,2.244-3.688c0-2.248-0.658-3.383-2.102-3.383c-0.73,0-1.392,0.355-2.074,1.135v5.125C19.941,14.771,20.625,14.972,21.234,14.972z M35.291,4.87c1.488,0,2.732,0.582,3.539,1.439l-1.637,2.297c-0.586-0.504-1.123-0.73-1.688-0.73c-1.729,0-2.829,1.391-2.829,3.561c0,2.172,1.146,3.559,2.71,3.559c0.879,0,1.588-0.428,2.174-0.908L39,16.438c-1.174,1.059-2.711,1.563-4.027,1.563c-3.367,0-5.979-2.396-5.979-6.563C28.992,7.271,31.945,4.87,35.291,4.87z',
        icon: <i className="socex-icon-text-annotation" />,
        helperImage: 'draw_label_helper',
        group: 2,
        type: 'label',
    },
    hotspot: {
        svgPath: 'M12,32H2c-1.104,0-2-0.896-2-2V2c0-1.104,0.896-2,2-2h10c1.104,0,2,0.896,2,2s-0.896,2-2,2H4v24h8c1.104,0,2,0.896,2,2S13.104,32,12,32z M39,30V2c0-1.104-0.896-2-2-2H27c-1.104,0-2,0.896-2,2s0.896,2,2,2h8v24h-8c-1.104,0-2,0.896-2,2s0.896,2,2,2h10C38.104,32,39,31.104,39,30z',
        icon: <i className="socex-icon-hotspot-annotation" />,
        helperImage: 'draw_hotspot_helper',
        group: 2,
        type: 'hotspot',
    },
    image: {
        svgPath: 'M16.843,19.73L27.895,6L35,17.307V27H5l9.474-9.693L16.843,19.73z M4.5,9C2.015,9,0,6.986,0,4.5S2.015,0,4.5,0C6.985,0,9,2.014,9,4.5S6.985,9,4.5,9',
        icon: <i className="material-icons">image</i>,
        helperImage: 'draw_image_helper',
        group: 2,
        type: 'image',
    },
};

export const markerSvgPath = {
    'marker-default': 'M6.5-11.5C6.5-8,1.5-2,0,0c-1.5-2-6.5-8-6.5-11.5S-3.229-18,0-18S6.5-15,6.5-11.5z',
    airfield: 'M-5-7.5c-0.277,0-0.5,0.223-0.5,0.5S-5.277-6.5-5-6.5h4l-0.5,3v1l-7,2v2h7v4l-2.5,1v1h8v-1l-2.5-1V3.532V1.5h7v-2l-7-2v-1L1-6.5h4c0.277,0,0.5-0.223,0.5-0.5S5.277-7.5,5-7.5H-5z',
    airport: 'M8.5,2v0.5l-7-1v4l2,2c0,0.5-0.5,1-1,1c-2,0-5,0-5,0c-0.5,0-1-0.5-1-1l2-2v-4l-7,1V2c0-0.5,0.306-0.638,0.5-0.75l6.5-3.75c0,0,0-0.333,0-2c0-1,0.5-4,1.5-4s1.5,3,1.5,4c0,1.667,0,2,0,2L8,1.25C8.278,1.41,8.5,1.491,8.5,2z',
    'america-football': 'M0-6.5C-6.5-6.5-9.5,0-9.5,0s3,6.5,9.5,6.5S9.5,0,9.5,0S6.5-6.5,0-6.5z M-3-1.5c0.277,0,0.5,0.223,0.5,0.5v0.5h2V-1c0-0.277,0.223-0.5,0.5-0.5c0.277,0,0.5,0.223,0.5,0.5v0.5h2V-1c0-0.277,0.223-0.5,0.5-0.5S3.5-1.277,3.5-1v0.5H5c0.277,0,0.5,0.223,0.5,0.5S5.277,0.5,5,0.5H3.5V1c0,0.277-0.223,0.5-0.5,0.5S2.5,1.277,2.5,1V0.5h-2V1c0,0.277-0.223,0.5-0.5,0.5c-0.277,0-0.5-0.223-0.5-0.5V0.5h-2V1c0,0.277-0.223,0.5-0.5,0.5S-3.5,1.277-3.5,1V0.5H-5c-0.277,0-0.5-0.223-0.5-0.5S-5.277-0.5-5-0.5h1.5V-1C-3.5-1.277-3.277-1.5-3-1.5z',
    'art-gallery': 'M-1.5-7.5c-5,0-8,4-8,6s1,3.5,3,3.5s3,0.5,3,2C-3.5,6-2,7.5,0,7.5c6.5,0,9.5-3.5,9.5-7C9.5-5.5,4.5-7.5-1.5-7.5z M-2.75-5.5c0.69,0,1.25,0.56,1.25,1.25S-2.06-3-2.75-3S-4-3.56-4-4.25S-3.44-5.5-2.75-5.5z M1.25-5.5c0.69,0,1.25,0.56,1.25,1.25S1.94-3,1.25-3S0-3.56,0-4.25S0.56-5.5,1.25-5.5z M5.25-3.5c0.69,0,1.25,0.56,1.25,1.25S5.94-1,5.25-1S4-1.56,4-2.25S4.56-3.5,5.25-3.5z M-6.25-2.5C-5.56-2.5-5-1.94-5-1.25S-5.56,0-6.25,0S-7.5-0.56-7.5-1.25S-6.94-2.5-6.25-2.5z M0,2.5c0.828,0,1.5,0.672,1.5,1.5S0.828,5.5,0,5.5c-0.829,0-1.5-0.672-1.5-1.5S-0.829,2.5,0,2.5z',
    bakery: 'M-0.495-5c-1.5,0-2.764,0.536-2.438,2l1.438,6.5C-1.444,3.73-1.306,4-0.995,4h1h1c0.311,0,0.449-0.27,0.5-0.5L2.942-3c0.326-1.464-0.938-2-2.438-2h-0.5H-0.495z M-5.995-2c-1,0-1.951,0.599-1.5,1.5l2,4c0.167,0.334,0.5,0.5,1,0.5h1c0,0,0.615,0.018,0.5-0.5l-1-4.5c-0.112-0.503-0.5-1-1-1H-5.995z M5.005-2c-0.5,0-0.889,0.497-1,1l-1,4.5C2.89,4.018,3.505,4,3.505,4h1c0.5,0,0.834-0.166,1-0.5l2-4c0.451-0.901-0.5-1.5-1.5-1.5H5.005z M-8.558,1.125C-8.751,1.124-8.914,1.243-8.995,1.5l-0.958,2.994c-0.119,0.375-0.038,0.566,0.469,0.469L-6.995,4.5C-6.504,4.406-6.423,4.234-6.558,4l-1.438-2.5C-8.141,1.248-8.364,1.126-8.558,1.125z M8.567,1.125C8.374,1.126,8.15,1.248,8.005,1.5L6.567,4C6.433,4.234,6.514,4.406,7.005,4.5l2.453,0.475c0.507,0.097,0.619-0.095,0.5-0.469L9.005,1.5C8.923,1.243,8.761,1.124,8.567,1.125z',
    bank: 'M-8.5-5.5c-0.554,0-1,0.446-1,1v10c0,0.555,0.446,1,1,1h17c0.555,0,1-0.445,1-1v-10c0-0.554-0.445-1-1-1H-8.5z M-9-3.5H9v2H-9V-3.5z M-8.5,2.5h3v2h-3V2.5z M0.5,3.5h7v1h-7V3.5z',
    bar: 'M-7.5-8.5c-0.5,0-1,0-1,0.25C-8.5-8-8.157-7.703-8-7.5l7.5,7.813V6.5l-4,1c-0.5,0.166-1,0.5-1,1h11c0-0.5-0.5-0.834-1-1l-4-1V0.313L8-7.5C8.157-7.703,8.5-8,8.5-8.25C8.5-8.5,8-8.5,7.5-8.5H-7.5z',
    baseball: 'M-6.5-8.5c-1.252,0-2,0.753-2,2c0,1,2,2.5,3,3.5c2,2,5.302,3.802,8.5,7l3,3L5.5,7.5c0,0.5,0.5,1,1,1l1-1l1-1c0-0.5-0.5-1-1-1L7,6L4,3C1.137,0.137-1-3.5-3-5.5C-4-6.5-5.5-8.5-6.5-8.5z M-3,1.5c-0.829,0-1.5,0.672-1.5,1.5S-3.829,4.5-3,4.5S-1.5,3.828-1.5,3S-2.171,1.5-3,1.5z',
    basketball: 'M-4-8.5l-4.5,4v8c0,0.5,0.5,1,1,1h3.563L-2,8c0.278,0.5,0.5,0.5,1,0.5h2c0.5,0,0.723,0,1-0.5l1.938-3.5H7.5c0.5,0,1-0.5,1-1v-8L4-8.5H-4z M-4.5-4.5h9v6h-9V-4.5z M-3.5-3.5v4h7v-4H-3.5z M-2.938,4.5H-1.5v1h1v-1h1v1h1v-1h1.438l-0.563,1H1.5v1h0.344L1.5,7.125V6.5h-1v1h-1v-1h-1v0.625L-1.844,6.5H-1.5v-1h-0.875L-2.938,4.5z M-0.5,6.5h1v-1h-1V6.5z',
    beer: 'M-7.5-8.5l2,16c0.063,0.5,0.5,1,1,1h9c0.5,0,0.938-0.5,1-1l2-16H-7.5z M-5.25-6.5h10.5l-0.469,4h-9.563L-5.25-6.5z',
    bicycle: 'M2.376-7.499C1.814-7.421,1.739-6.44,2.282-6.28l2.219,0.875v1.906h-7v-1h1.375c0.33,0,0.625-0.295,0.625-0.625s-0.295-0.63-0.625-0.625h-3.875c-0.062-0.01-0.125-0.01-0.188,0C-5.514-5.7-5.767-5.356-5.718-5.03c0.049,0.327,0.392,0.58,0.719,0.531h1.5v1l-1.063,3.125c-0.3-0.072-0.616-0.125-0.938-0.125c-2.209,0-4,1.791-4,4s1.791,4,4,4s4-1.791,4-4c0-0.301-0.062-0.594-0.125-0.875h1.126c0.199-0.01,0.391-0.115,0.5-0.281l3.375-4.844h0.125h1l0.688,2.031C3.126-0.307,1.501,1.398,1.501,3.501c0,2.209,1.791,4,4,4s4-1.791,4-4c0-1.828-1.23-3.335-2.906-3.813L5.501-3.499v-2.344c0-0.105-0.043-0.211-0.094-0.313c-0.004-0.01,0.004-0.024,0-0.031C5.393-6.217,5.364-6.25,5.345-6.28C5.314-6.325,5.29-6.366,5.251-6.405C5.234-6.42,5.207-6.421,5.189-6.436C5.171-6.451,5.146-6.454,5.126-6.466c-0.032-0.019-0.058-0.05-0.094-0.062L5.001-6.56L4.564-6.715L2.72-7.465C2.655-7.491,2.571-7.494,2.501-7.497c-0.047,0-0.078-0.011-0.125,0V-7.499z M-2.499-2.499h4.406l-2.719,3.875h-1.313c-0.31-0.494-0.699-0.901-1.188-1.219L-2.499-2.499z M-5.499,1.001c1.381,0,2.5,1.119,2.5,2.5s-1.119,2.5-2.5,2.5s-2.5-1.119-2.5-2.5S-6.88,1.001-5.499,1.001z M5.501,1.001c1.381,0,2.5,1.119,2.5,2.5s-1.119,2.5-2.5,2.5s-2.5-1.119-2.5-2.5c0-0.173,0.029-0.338,0.063-0.5C3.296,1.863,4.293,1.001,5.501,1.001z',
    boom: 'M0.519-4.25l4.916-2.917l-2.5,4.833l5.631,0.604L3.269,1.833L4.919,8.5l-4.984-4.917l-2.417,4.834l-0.583-5.834l-5.5,2.251l3.649-4.25l-3.649-2.75l4.333,0.071l-1.917-5.238l3.839,3L-0.065-8.5L0.519-4.25z',
    building: 'M-1.5-8.5L-9-2c-0.356,0.309-0.5,0.5-0.5,0.75c0,0.496,0.5,0.75,1,0.75h1v8c0,0.554,0.446,1,1,1H0h6.5c0.555,0,1-0.446,1-1v-8h1c0.5,0,1-0.254,1-0.75C9.5-1.5,9.361-1.698,9-2L1.5-8.5H-1.5z',
    bus: 'M-3.5-8.5v1h-2c-0.5,0-1,0.5-1,1v13h1v2h2v-2h7v2h2v-2h1v-13c0-0.5-0.5-1-1-1h-2v-1H-3.5z M-4.5-5.5h9v5h-9V-5.5z M-3.5,2.5c0.554,0,1,0.445,1,1s-0.446,1-1,1s-1-0.445-1-1S-4.054,2.5-3.5,2.5z M3.5,2.5c0.555,0,1,0.445,1,1s-0.445,1-1,1s-1-0.445-1-1S2.945,2.5,3.5,2.5z',
    cafe: 'M-4.5-6.5c-0.554,0-1,0.446-1,1v9c0,0.554,0.446,1,1,1h11c0.555,0,1-0.446,1-1v-1H8C8.5,2.5,8.5,2,8.5,2V1.5h1c1,0,2-1,2-2v-1v-2c0-0.5-0.5-1-1-1h-3v-1c0-0.554-0.445-1-1-1H-4.5z M7.5-3.5H10c0.5,0,0.5,0.5,0.5,0.5v2.5c0,0.5-0.5,1-1,1h-1h-1V-3.5z M-7.5,5.5l1,1l1,1h13l1-1l1-1H-7.5z',
    camera: 'M8.5-5.5h-4v-1c0-0.553-0.449-1-1-1h-4c-0.553,0-1,0.447-1,1v1h-3c0-0.553-0.448-1-1-1h-1c-0.552,0-1,0.447-1,1h-1c-0.552,0-1,0.447-1,1v10c0,0.553,0.448,1,1,1h17c0.551,0,1-0.447,1-1v-10C9.5-5.053,9.051-5.5,8.5-5.5z M1.5,4.5c-2.209,0-4-1.791-4-4s1.791-4,4-4s4,1.791,4,4S3.709,4.5,1.5,4.5z',
    campsite: 'M-0.061-7.81l1.438-1.047L1.594-8.5L0.205-7.513l7.547,16.37H3.096L-0.061,1.25l-3.035,7.606h-4.657l7.547-16.37L-1.594-8.5l0.217-0.357L0.063-7.81',
    car: 'M-4.5-6.5c-1,0-1.313,0.5-1.5,1l-1.5,4c-0.188,0.5-1,1-1,2v5h2v1c0,0.5,0.5,1,1,1h1c0.5,0,1-0.5,1-1v-1h7v1c0,0.5,0.5,1,1,1h1c0.5,0,1-0.5,1-1v-1h2v-5c0-1-0.738-1.302-1-2L6-5.5c-0.188-0.5-0.5-1-1.5-1C1.167-6.5-4.5-6.5-4.5-6.5z M-4-4.5h8l1,3H-5L-4-4.5z M-4.5,1.5c0.555,0,1,0.446,1,1s-0.445,1-1,1s-1-0.446-1-1S-5.055,1.5-4.5,1.5z M4.5,1.5c0.555,0,1,0.446,1,1s-0.445,1-1,1s-1-0.446-1-1S3.946,1.5,4.5,1.5z',
    cemetery: 'M0-7.5c-3.074,0-4.5,2-4.5,5v5v4c-0.5,0-1,0.5-1,1v1h11v-1c0-0.5-0.5-1-1-1v-9C4.5-5.5,3-7.5,0-7.5z',
    chemist: 'M-3.219-8.5c-0.712,0-1.281,0.446-1.281,1c0,0.554,0.569,1,1.281,1H-2.5v4l-5,10l0.969,1H-3.5h7h1.031h1.938l1.031-1l-5-10v-4h0.719c0.713,0,1.281-0.446,1.281-1c0-0.554-0.568-1-1.281-1H-3.219z M-0.5-6.5h1v4l3,6h-7l3-6V-6.5z',
    cinema: 'M6.5-8.5L4.25-7.844l1.094,2.969L7.5-5.5L6.5-8.5z M2.875-7.469l-2.938,0.844L0-6.5l0.563,1.531l0.5,1.313L4-4.5L2.875-7.469z M-1.406-6.25L-4-5.5l1.125,2.969l2.594-0.75l-0.656-1.688L-1.406-6.25z M-5.344-5.125L-7.5-4.5l1,3l2.25-0.656L-5.344-5.125z M-6.5-1.5v9c0,0.554,0.446,1,1,1h13c0.555,0,1-0.446,1-1v-9H-6.5z M-4.5,0.5h11v3h-11V0.5z',
    'clothing-store': 'M-3.063-7.499C-3.187-7.509-3.335-7.48-3.5-7.405c-1.018,0.443-2,0.906-2,0.906s-2.822,2.604-3.875,3.656c-0.183,0.184-0.192,0.474,0,0.719l2.5,2.469c0.175,0.204,0.544,0.24,0.75,0.031L-5.5-0.499h1v8H0h4.5v-8h1l0.625,0.875C6.33,0.584,6.7,0.549,6.875,0.345l2.5-2.469c0.192-0.245,0.184-0.535,0-0.719C8.322-3.895,5.5-6.499,5.5-6.499s-0.982-0.462-2-0.906c-0.662-0.298-0.986,0.178-1,0.438C2.517-5.928,1.409-4.47,0-4.499C-1.41-4.47-2.518-5.928-2.5-6.968C-2.511-7.163-2.692-7.479-3.063-7.499L-3.063-7.499z',
    circle: 'M-8.5,0c0,4.694,3.806,8.5,8.5,8.5S8.5,4.694,8.5,0S4.694-8.5,0-8.5S-8.5-4.694-8.5,0z',
    circle_1: 'M0-7.771c-4.292,0-7.771,3.479-7.771,7.771c0,4.291,3.479,7.771,7.771,7.771c4.291,0,7.771-3.479,7.771-7.771C7.771-4.292,4.291-7.771,0-7.771z M0,2.874c-1.587,0-2.875-1.287-2.875-2.875c0-1.588,1.287-2.875,2.875-2.875c1.588,0,2.875,1.287,2.875,2.875C2.875,1.587,1.588,2.874,0,2.874z',
    circle_2: 'M-0.035,5.875c-3.244,0-5.874-2.63-5.874-5.875c0-3.245,2.63-5.875,5.874-5.875c3.245,0,5.876,2.63,5.876,5.875C5.841,3.245,3.21,5.875-0.035,5.875z M0-8.229c-4.545,0-8.229,3.684-8.229,8.229c0,4.544,3.684,8.229,8.229,8.229c4.544,0,8.229-3.685,8.229-8.229C8.229-4.545,4.544-8.229,0-8.229z M-0.055,7.726c-4.255,0-7.705-3.45-7.705-7.705c0-4.255,3.45-7.705,7.705-7.705c4.254,0,7.705,3.45,7.705,7.705C7.649,4.275,4.199,7.726-0.055,7.726z',
    city: 'M-6.5-8.5v2h-2v13h8v-13h-2v-2H-6.5z M2.5-6.5v3h-2v12h8v-12h-2v-3h-1v3h-2v-3H2.5z M-7.5-4.5h2v1h-2V-4.5z M-3.5-4.5h2v1h-2V-4.5z M-7.5-2.5h2v1h-2V-2.5z M-3.5-2.5h2v1h-2V-2.5z M1.5-1.5h2v1h-2V-1.5z M5.5-1.5h2v1h-2V-1.5z M-7.5-0.5h2v1h-2V-0.5z M-3.5-0.5h2v1h-2V-0.5z M1.5,0.5h2v1h-2V0.5z M5.5,0.5h2v1h-2V0.5z M-7.5,1.5h2v1h-2V1.5z M-3.5,1.5h2v1h-2V1.5z M1.5,2.5h2v1h-2V2.5z M5.5,2.5h2v1h-2V2.5z M-7.5,3.5h2v1h-2V3.5z M-3.5,3.5h2v1h-2V3.5z M1.5,4.5h2v1h-2V4.5z M5.5,4.5h2v1h-2V4.5z',
    college: 'M0-6.5l-9.5,5l9.5,5l5.5-2.781c0,0,0,2.521,0,3.781c0,2,2,2,2,2v-1c0,0-1-0.057-1-1c0-1.5,0-4.344,0-4.344l3-1.656L0-6.5z M-4.5,2.5v2c0,0.496,0.325,1.162,1,1.5L0,7.5L3,6c0.675-0.338,1.547-1.006,1.5-1.5v-2L0,4.687L-4.5,2.5z',
    commercial: 'M-6.5-9.5c-0.5,0-1,0.5-1,1v18h6v-3h3v3h6v-18c0-0.5-0.5-1-1-1H-6.5z M-4.5-6.5h3v1h-3V-6.5z M1.5-6.5h3v1h-3V-6.5z M-4.5-3.5h3v1h-3V-3.5z M1.5-3.5h3v1h-3V-3.5z M-4.5-0.5h3v1h-3V-0.5z M1.5-0.5h3v1h-3V-0.5z M-4.5,2.5h3v1h-3V2.5z M1.5,2.5h3v1h-3V2.5z',
    corn: 'M0-7.808 M-1.791,0.508 M0-7.808c1.183,1.364,1.821,6.19,1.791,8.316L1.132,5.374L3.25-0.719c0.593-0.296,5.685,0.513,2.938,0.641C2.319,0.099,0.947,7.808,0.947,7.808h-1.843c0,0-1.373-7.709-5.241-7.886c-2.747-0.128,2.344-0.937,2.938-0.641l2.118,6.092c0,0-0.648-3.638-0.659-4.866C-1.792-5.563,0-7.808,0-7.808z',
    cross: 'M-7.417-8.5C-7.958-8.5-8.5-7.958-8.5-7.417c0,0.27,0.088,0.528,0.305,0.745L-1.523,0l-6.672,6.672C-8.412,6.889-8.5,7.146-8.5,7.416C-8.5,7.958-7.958,8.5-7.417,8.5c0.271,0,0.542-0.067,0.778-0.305L0,1.557l6.639,6.639C6.875,8.433,7.146,8.5,7.416,8.5C7.959,8.5,8.5,7.957,8.5,7.416c0-0.27-0.088-0.527-0.305-0.744L1.523,0l6.672-6.672C8.412-6.889,8.5-7.147,8.5-7.417C8.5-7.958,7.959-8.5,7.416-8.5c-0.27,0-0.541,0.067-0.777,0.305L0-1.558l-6.638-6.638C-6.875-8.433-7.146-8.5-7.417-8.5z',
    dam: 'M-8.5-6.5c0,2.208,0,6.625,0,6.625v2.031v1.969V5.5c0,0.5,0.5,1,1,1h5l-4-14c0,0-0.667,0-1,0C-8-7.5-8.5-7-8.5-6.5z M0-0.5c-0.105,0-0.217,0.033-0.313,0.062L-2.75,0.407l0.531,1.875L0,1.5l2.688,0.938C2.791,2.471,2.895,2.5,3,2.5s0.215-0.032,0.313-0.062L6.5,1.5l2,0.656V0.125L6.813-0.437C6.713-0.47,6.605-0.5,6.5-0.5S6.289-0.47,6.188-0.437L3,0.5L0.313-0.437C0.207-0.473,0.105-0.5,0-0.5z M0,3.5c-0.105,0-0.217,0.033-0.313,0.063l-1.375,0.469l0.531,1.875L0,5.5l2.688,0.937C2.791,6.471,2.895,6.5,3,6.5s0.215-0.033,0.313-0.063L6.5,5.5l2,0.656V4.125L6.813,3.563C6.713,3.53,6.605,3.5,6.5,3.5S6.289,3.53,6.188,3.563L3,4.5L0.313,3.563C0.207,3.528,0.105,3.5,0,3.5z',
    danger: 'M0-8.25c-2.5,0-4.5,2-4.5,4v2c0,1,2,1,2,2v1l2.5,1l2.5-1v-1c0-1,2-1,2-2v-2C4.5-6.25,2.5-8.25,0-8.25z M-1.5-5.25c0.552,0,1,0.448,1,1s-0.448,1-1,1s-1-0.448-1-1S-2.052-5.25-1.5-5.25z M1.5-5.25c0.553,0,1,0.448,1,1s-0.447,1-1,1s-1-0.448-1-1S0.947-5.25,1.5-5.25z M-6.5,0.75c-0.5,0-1,0.5-1,1v0.5l5.25,2.25L-7.5,6.75v0.5c0,0.5,0.5,1,1,1l14-6v-0.5c0-0.5-0.5-1-1-1L0,3.531L-6.5,0.75z M4,5.25L1.75,6.219L6.5,8.25c0.5,0,1-0.5,1-1v-0.5L4,5.25z',
    disability: 'M-1-10c-1.381,0-2.5,1.119-2.5,2.5S-2.381-5-1-5s2.5-1.119,2.5-2.5S0.381-10-1-10z M-1.5-4c-1,0-2,1-2,2v3.5C-3.5,3-2.5,4-1,4h5l2.625,3.5C6.852,7.832,7.109,8,7.5,8c0.5,0,1-0.5,1-1c0-0.293-0.166-0.52-0.281-0.719L5.5,2.5C5.318,2.186,5,2,4.5,2H2L1-2C0.758-2.97,0.5-4-0.5-4H-1.5z M-4.5-2c-2.331,0.823-4,3.387-4,6c0,3.313,2.687,6,6,6c2.976,0,5.434-2.158,5.906-5H1.5C0.962,6.73-0.343,8-2.25,8C-4.597,8-6.5,6.098-6.5,3.75c0-1.518,0.803-2.998,2-3.75V-2z',
    'dog-park': 'M6.266-5.5l2-1h1l-1,1V-5l4,2l-0.5,1.5c-2.5,0-4,0-4.5,1l-2-3L6.266-5.5z M6.766,0l-2-3l-1.5,0.5h-5.5l-1.5-2l1-2h-1l-1,2l1,3c-1,1-1,2-1,3l-2,5h2c-0.482-1.389,0.753-2.385,1.5-3.5l-0.5,3.5h2c-1.004-1.586,0.4-3.4,1-5c1,0,1.5,1,4,1l-1,4h2c-0.876-1.117-0.076-1.848,0.5-3l1.5,3h2c-1.578-0.91-1.583-2.332-2-4L6.766,0z',
    embassy: 'M3-18l-2,1l-2,1v15c0,0.5,0.5,1,1,1s1-0.5,1-1v-9c0,0,1-0.5,2-1c2-1,4.211,2.895,6,2c2-1,3-1.5,3-1.5v-7c0,0-1,0.5-3,1.5C7.211-15.105,5-19,3-18z',
    'emergency-telephone': 'M-3.883-8.383l-4.5,4.5h4.5V-8.383z M3.117-7.883c-1,0-1.326,0.326-2,1l-8,8c-0.5,0.5-1,1-1,2v1.5c0,1,0.5,1.5,1,2L-6.32,7.18l3.5-3.5l-0.563-0.563c-0.5-0.5,0-1,0-1l5.5-5.5c0,0,0.5-0.5,1,0L3.68-2.82l3.5-3.5L6.617-6.883c-0.5-0.5-1-1-2-1H3.117z M7.555-5.945l-3.5,3.5l0.563,0.563c0.354,0.354,1.146,0.354,1.5,0l2-2c0.354-0.354,0.354-1.146,0-1.5L7.555-5.945z M-2.445,4.055l-3.5,3.5l0.563,0.563c0.354,0.354,1.146,0.354,1.5,0l2-2c0.354-0.354,0.354-1.146,0-1.5L-2.445,4.055z',
    entrance: 'M-0.5-4.002v2.5H-7c-0.828,0-1.5,0.676-1.5,1.504c0,0.828,0.672,1.496,1.5,1.496h6.499l0.001,2.5c0.005,0.358,0.484,0.636,0.78,0.435l5.011-4.019c0.264-0.174,0.281-0.618,0.031-0.812L0.312-4.371C-0.142-4.683-0.5-4.377-0.5-4.002z M-3.5-8.5c-0.5,0-1.008,0.533-1,1v4h2v-3h9v13h-9v-3h-2v4c0,0.523,0.477,1,1,1h11c0.523,0,1-0.477,1-1v-15c0-0.523-0.477-1-1-1C3.779-8.5,0.205-8.5-3.5-8.5z',
    explosion: 'M-5.652,0L-10-6.931l3.85,2.549l-2.848-7.421l3.939,3.944l-1.726-11.926L-1-9.383v-4.162l1.806,3.351L3-20v10.97l2-2.575v3.28l5-5.855L5.674-3.457L8.783-4.48L6.601-1.984L8.691,0H3.633L5.51-2.169L3.633-1.834L4.759-6.04L1.917-3.682L1.595-7.617l-1.072,2.68l-0.59-1.536l-0.321,2.346l-2.573-3.969l0.233,5.238L-5-4.925L-3.82,0H-5.652z',
    farm: 'M5-3.5v10h5v-10c0-1.5-0.5-3-2.5-3S5-5,5-3.5z M-6-3.5c-0.5,0-1,0.5-1.5,1l-2.5,3h1v6h7v-3h2v3h3v-6h1l-2.5-3C1-3,0.5-3.5,0-3.5H-6z M-1-1.5c0,0,2,1.255,2,2v1h-4v-1C-3-0.245-1-1.5-1-1.5z',
    'fast-food': 'M-2.5-6.5c-3,0-6,1.5-6,4v2h17v-2c0-2.5-3-4-6-4H-2.5z M-2-5.5c0.277,0,0.5,0.223,0.5,0.5S-1.723-4.5-2-4.5S-2.5-4.723-2.5-5S-2.277-5.5-2-5.5z M2-5.5c0.277,0,0.5,0.223,0.5,0.5S2.277-4.5,2-4.5S1.5-4.723,1.5-5S1.723-5.5,2-5.5z M-4-3.5c0.277,0,0.5,0.223,0.5,0.5S-3.723-2.5-4-2.5S-4.5-2.723-4.5-3S-4.277-3.5-4-3.5z M0-3.5c0.277,0,0.5,0.223,0.5,0.5S0.277-2.5,0-2.5c-0.277,0-0.5-0.223-0.5-0.5S-0.277-3.5,0-3.5z M4-3.5c0.277,0,0.5,0.223,0.5,0.5S4.277-2.5,4-2.5S3.5-2.723,3.5-3S3.723-3.5,4-3.5z M-8.5,0.5c-0.5,0-1,0.5-1,1s0.5,1,1,1h17c0.5,0,1-0.5,1-1s-0.5-1-1-1H-8.5z M-8.5,3.5v1c0,1,1,2,2,2h13c1,0,2-1,2-2v-1H-8.5z',
    feathers: 'M-4.397,4.194l2.787-2.761l0.383,0.385L-4,4.598L-4.397,4.194z M3.342-1.107C2.442-0.16-0.542,1.899-0.846,1.94c-0.312,0.042-0.928-0.534-0.928-0.848c0-0.428,1.808-3.454,2.671-4.435C1.875-4.452,4.842-5.647,5.964-6.69C6.636-6.503,4.36-2.18,3.342-1.107z M3.201-3.182l-0.262-0.24L-1.61,1.433l0.383,0.385L3.201-3.182z',
    ferry: 'M-2.5-10c-0.555,0-1,0.446-1,1h-2c-0.555,0-1,0.446-1,1v6.063L-8.5-1l2,4v2C-5,5-4,6-3,6s1.5-1,3-1s2,1,3,1s2-1,3.5-1V3l2-4l-2-0.938V-8c0-0.554-0.445-1-1-1h-2c0-0.554-0.445-1-1-1H-2.5z M-4.5-7h9v4.125L0-5l-4.5,2.125V-7z M-6.5,7c-0.693,0-1.34,0.404-2,0.625v2.031c0,0,1-0.656,2-0.656s2,1,3.5,1s1.974-0.965,3-1c1,0,1.5,1,3,1s2.5-1,3.5-1c0.701,0,2,0.656,2,0.656V7.625C7.858,7.378,7.156,7,6.5,7C5,7,4,8,3,8S1.5,7,0,7s-2,1-3,1S-5,7-6.5,7z',
    fish: 'M4.313,0.109c0,0-3.378,3.454-6.058,3.244c-3.983-0.311-5.079-3.368-5.079-3.368c0.223-1.056,2.585-3.296,5.092-3.296c2.998,0,6.042,3.241,6.042,3.241l2.515-3.139v6.855L4.313,0.109z',
    'fire-station': 'M0-8.5l-3.5,5l-2-3c-0.663,1.165-3,4-3,7c0,4.418,4.082,8,8.5,8s8.5-3.582,8.5-8c0-3-2.338-5.835-3-7l-2,3L0-8.5z M0-0.5c0,0,3.5,2.685,3.5,5c0,1-1.5,2-3.5,2s-3.5-1-3.5-2C-3.5,2.264,0-0.5,0-0.5z',
    fuel: 'M-6.5-9c-0.554,0-1,0.446-1,1V8c0,0.5,0.5,1,1,1h9c0.5,0,1-0.5,1-1V3H4c0.5,0,0.5,0.5,0.5,0.5v2C4.5,6.5,5,7,6,7s1.5-0.5,1.5-1.5c0-1.166,0-4.5,0-5.5s-2-2-2-3v-3h-1l-1-1v-1c0-0.554-0.445-1-1-1H-6.5z M-5.5-7h7v4h-7V-7z M3.5-4h1c0,0,0,0.833,0,1.5c0,1,2,2,2,3v5C6.5,6,6,6,6,6S5.5,6,5.5,5.5c0,0,0-2,0-2.5S5,2,4.5,2c-0.334,0-1,0-1,0V-4z',
    garden: 'M-4.2-8c-0.253,0-0.3,0.56-0.3,1v2c0,2.031,2,3.063,4,3v8c-1-2-4-5-7-4C-7.5,2-5,8,0,8s7.5-6,7.5-6c-3-1-6,2-7,4v-8c2,0.062,4-0.969,4-3v-2c0-0.44-0.047-1-0.3-1C4.007-8,3.774-7.774,3.5-7.5L2-6L0.5-7.688C0.344-7.864,0.253-8,0-8s-0.343,0.136-0.5,0.313L-2-6l-1.5-1.5C-3.774-7.774-4.007-8-4.2-8z',
    golf: 'M-3-9c-0.341,0-0.5,0-0.5,0.5V5.938C-5.5,6-6.5,7.5-6.5,8.5C-6.5,9-6,9-6,9H6c0,0,0.5,0,0.5-0.5C6.5,6,5,4,3.5,4c-1.572,0-2.443,2.78-4,3c-0.683,0.096-1.342-0.346-2-0.688V-3l7-3l-6.499-2.785C-2.375-8.946-2.75-9-3-9z',
    grocery: 'M7.5-7.5c-0.584,0-1.102,0.164-1.469,0.531C5.664-6.601,5.5-6.083,5.5-5.5h-14l2,9h12v1h-11c-1,0-1,1,0,1H-4h8h1.5c0.5,0,1-0.5,1-1v-1v-8v-1c0,0,0.086-0.649,0.219-0.781C6.852-6.413,7.084-6.5,7.5-6.5c0.111,0,0.293,0,0.4,0c0.5,0,0.6-0.249,0.6-0.5c0-0.247-0.1-0.5-0.6-0.5C7.77-7.5,7.637-7.5,7.5-7.5z M4,5.5C3.172,5.5,2.5,6.172,2.5,7S3.172,8.5,4,8.5S5.5,7.828,5.5,7S4.828,5.5,4,5.5z M-4,5.5c-0.829,0-1.5,0.672-1.5,1.5S-4.829,8.5-4,8.5S-2.5,7.828-2.5,7S-3.171,5.5-4,5.5z',
    grapes: 'M-2.963-2.88c0,0.817-0.663,1.48-1.481,1.48s-1.481-0.663-1.481-1.48c0-0.818,0.663-1.481,1.481-1.481S-2.963-3.698-2.963-2.88z M-1.482-4.361c-0.817,0-1.481,0.663-1.481,1.481c0,0.817,0.664,1.48,1.481,1.48c0.819,0,1.481-0.663,1.481-1.48C-0.001-3.698-0.663-4.361-1.482-4.361z M1.48-4.361c-0.816,0-1.481,0.663-1.481,1.481c0,0.817,0.665,1.48,1.481,1.48c0.819,0,1.48-0.663,1.48-1.48C2.96-3.698,2.299-4.361,1.48-4.361z M4.443-4.361c-0.817,0-1.48,0.663-1.48,1.481c0,0.817,0.663,1.48,1.48,1.48c0.819,0,1.482-0.663,1.482-1.48C5.925-3.698,5.262-4.361,4.443-4.361z M-2.964-1.611c-0.816,0-1.48,0.663-1.48,1.481s0.664,1.48,1.48,1.48c0.82,0,1.481-0.662,1.481-1.48S-2.145-1.611-2.964-1.611z M-0.001-1.611c-0.816,0-1.48,0.663-1.48,1.481s0.664,1.48,1.48,1.48c0.82,0,1.481-0.662,1.481-1.48S0.819-1.611-0.001-1.611z M2.962-1.611c-0.817,0-1.48,0.663-1.48,1.481s0.663,1.48,1.48,1.48c0.819,0,1.48-0.662,1.48-1.48S3.782-1.611,2.962-1.611z M-1.482,1.138c-0.817,0-1.481,0.664-1.481,1.482s0.664,1.48,1.481,1.48c0.819,0,1.481-0.662,1.481-1.48S-0.663,1.138-1.482,1.138z M1.48,1.138C0.665,1.138,0,1.802,0,2.62s0.664,1.48,1.479,1.48c0.822,0,1.482-0.662,1.482-1.48S2.302,1.138,1.48,1.138z M-0.001,3.89c-0.816,0-1.48,0.662-1.48,1.48c0,0.816,0.664,1.48,1.48,1.48c0.82,0,1.481-0.664,1.481-1.48C1.48,4.552,0.819,3.89-0.001,3.89z M1.292-6.018c-0.415,0.47-1.083,2.333-1.083,2.333l3.379-3.167C3.588-6.851,1.829-6.625,1.292-6.018z M-0.208-3.642h0.417v-2.625h-0.417V-3.642z',
    hairdresser: 'M-6.75-7c-1.657,0-3,1.343-3,3s1.343,3,3,3c0.685,0,1.308-0.239,1.813-0.625L-2.531,0l-2.406,1.625C-5.442,1.238-6.065,1-6.75,1c-1.657,0-3,1.344-3,3s1.343,3,3,3s3-1.344,3-3c0-0.266-0.06-0.504-0.125-0.75L9.75-5.844c-0.5-0.333-1-0.656-2-0.656h-1.c-1,0-1.501,0.324-2,0.656l-6,4L-3.875-3.25C-3.81-3.495-3.75-3.734-3.75-4C-3.75-5.657-5.093-7-6.75-7z M2.375,0.906l-2.75,1.844L4.25,5.843c0.499,0.332,1,0.656,2,0.656h1.5c1,0,1.5-0.322,2-0.656L2.375,0.906z',
    harbor: 'M5-8.5C3.066-8.5,1.5-6.933,1.5-5c0,0.438,0.104,0.863,0.25,1.25L-6.5,4.5c0-1,0-3,0-4c0-1.5,1-2,1-2l1,1c0,0,0-2.667,0-4c0-1-0.5-0.5-1,0c-3,3-3,2.973-3,5v4v3c0,0.5,0.5,1,1,1h3h4c2.027,0,3.666-1.667,5-3c0.5-0.5,1-0.987,0-1h-4l1,1c0,0-0.5,1-2,1c-1,0-3,0-4,0l8.25-8.25C4.137-1.603,4.562-1.5,5-1.5c1.934,0,3.5-1.567,3.5-3.5S6.934-8.5,5-8.5z M5-6.5c0.828,0,1.5,0.672,1.5,1.5S5.828-3.5,5-3.5S3.5-4.172,3.5-5S4.172-6.5,5-6.5z',
    heart: 'M7.785-1.018C6.267,1.914,2.036,6.148,0,8c-2.036-1.852-6.267-6.086-7.785-9.018C-10.899-7.03-3.015-11.038,0-5.026C3.015-11.038,10.898-7.03,7.785-1.018z',
    helioport: 'M-0.25,6H8.5c0.5,0,1-0.5,1-1V2c0-1-0.48-1.48-1-2c0,0-0.666-0.667-1-1s-1-1-2-1h-3v-2h4h2c0.555,0,1-0.446,1-1s-0.445-1-1-1h-2H3H2h-4.5h-1h-1c-0.554,0-1,0.446-1,1s0.446,1,1,1h1h1h4v2h-3h-4.281C-6.127-2.597-6.76-3-7.5-3c-1.104,0-2,0.896-2,2s0.896,2,2,2c0.577,0,1.072-0.266,1.438-0.656L-2.5,4C-1.5,5.026-0.75,6-0.25,6z M-7.5-2c0.552,0,1,0.448,1,1s-0.448,1-1,1s-1-0.448-1-1S-8.052-2-7.5-2z',
    hospital: 'M-1.5-7.5c-0.555,0-1,0.446-1,1v4h-4c-0.554,0-1,0.446-1,1v3c0,0.555,0.446,1,1,1h4v4c0,0.555,0.445,1,1,1h3c0.556,0,1-0.445,1-1v-4h4c0.556,0,1-0.445,1-1v-3c0-0.554-0.444-1-1-1h-4v-4c0-0.554-0.444-1-1-1H-1.5z',
    industrial: 'M3.5-7c0,1.333,0,4,0,4h-3v-3l-4,3v-3l-4,3c-0.443,0.333-1,0.5-1,1.5V7c0,0.554,0.446,1,1,1h15c0.555,0,1-0.446,1-1v-9c0-0.554-0.445-1-1-1h-2c0,0,0-2.667,0-4c0-0.5-0.529-1-1-1S3.5-7.5,3.5-7z',
    'land-use': 'M-5-8.5c-0.5,0-0.783,0.197-1,0.5l-2.5,3.5h2v3h5v-3h2L-2-8c-0.217-0.303-0.5-0.5-1-0.5H-5z M7.5-8.5v2h-4c-0.5,0-1,0.5-1,1v4h6v-7H7.5z M5.5-0.5l-3,4H4l-1.5,3h2v2h2v-2h2L7,3.5h1.5L5.5-0.5z M-6.5,0.5c-0.5,0-1,0.5-1,1v7h2v-2h1v2h4v-7c0-0.5-0.5-1-1-1H-6.5z M-5.5,2.5h1v1h-1V2.5z M-3.5,2.5h1v1h-1V2.5z M-5.5,4.5h1v1h-1V4.5z M-3.5,4.5h1v1h-1V4.5z M-3.5,6.5h1v1h-1V6.5z',
    library: 'M-8.5-7V3c3,3,7,2,7,4h1v-9C-0.5-5-5.5-5-8.5-7z M8.5-7c-3,2-8,2-8,5v9h1c0-2,4-1,7-4V-7z',
    lighthouse: 'M4-1.5v-2H3V-7c0,0,0.005-0.697-0.5-1L0-9.5L-2.5-8C-3.014-7.692-3-7-3-7v3.5h-1v2l1,1v1l-1,9h8l-1-9v-1    L4-1.5z M1-4h-2v-2c0,0,0-1,1-1s1,1,1,1V-4z',
    'liquor-store': 'M-5-8.5c-0.554,0-0.5,0.627-0.5,1V-5c0,0.5-0.498,1.249-1,1.5c-1,0.5-1,1-1,2v9c0,0.554-0.054,1,0.5,1h5c0.554,0,0.5-0.446,0.5-1v-9c0-1,0-1.5-1-2c-0.499-0.249-1-1-1-1.5v-2.5c0-0.5,0.054-1-0.5-1H-5z M1.5-1.5v3c0,1,0.5,2,1.5,2h1V6c0,0.5-1,1.5-2,1.5s-1,1-1,1h7c0,0,0-1-1-1S5,6.5,5,6V3.5h1c1,0,1.5-1,1.5-2v-3H1.5z',
    lodging: 'M-6.5-6.5c-1.104,0-2,0.896-2,2s0.896,2,2,2s2-0.896,2-2S-5.396-6.5-6.5-6.5z M-3.5-4.5c0,1.657-1.343,3-3,3H-8c-0.252,0-0.501,0-0.5,0.5c0,0.136,0,0.616,0,1c0,0.5,0.389,0.5,0.5,0.5H8.5v-2c0-1,0-3-5-3H-3.5z M-7.5,1.5c-0.5,0-1,0.5-1,1c0,0.99,0,2.145,0,3c0,0.5,0.5,1,1,1s1-0.5,1-1v-2h13v2c0,0.5,0.5,1,1,1s1-0.5,1-1v-3c0-0.5-0.5-1-1-1H-7.5z',
    logging: 'M5.5-8.5L-2-1l1.25,1.25L6.5-7L7-6.5l-7.25,7.25L1,2l7.5-7.5v-2l-1-1H5.5z M-6-3l-2.5,2.5L-8,0h1l1-1l1,1l-3.5,3.5v2l3,3h2L1,4L-6-3z',
    minefield: 'M0-7.5l-2.5,7l-6-4l2,6.5c0.458,1.489,1,1.5,2,1.5h2v-1h5v1h2c1,0,1.506-0.431,2-1.5l2-6.5l-6,4L0-7.5z M-1.5,3.5v1c-2.216,0-4,0.669-4,1.5s1.784,1.5,4,1.5h3c2.216,0,4-0.669,4-1.5s-1.784-1.5-4-1.5v-1H-1.5z',
    minerals: 'M5.852,7.84H8.32l0.219-0.625L7.961,6.809v-0.75l-0.61-0.187L6.804,6.231L6.477,5.622L5.813,5.528L5.156-2.604L3.363-3.823L1.558-3.357l-1.065,5.2v-8.966l-1.47-0.717l-1.399,1.076L-3.237,1.02l-0.717-1.004l-0.742,0.875L-5.57,4.997h-0.719l-0.188,0.094L-6.805,5.7l-0.547-0.359l-0.609,0.188v0.75l-0.578,0.406l0.188,1.155H5.852z',
    'mobile-phone': 'M-4.125-10.5C-4.613-10.5-5-10.113-5-9.625v1.156c-0.559,0.06-1,0.472-1,1V9.469c0,0.568,0.504,1.031,1.125,1.031h9.75C5.496,10.5,6,10.037,6,9.469V-7.469C6-8.038,5.496-8.5,4.875-8.5H-3v-1.125c0-0.488-0.387-0.875-0.875-0.875H-4.125z M-3-6.5h6c0.532,0,1,0.512,1,1v3c0,0.487-0.468,1-1,1h-6c-0.532,0-1-0.513-1-1v-3C-4-5.988-3.532-6.5-3-6.5z M-4,0.5h2v2h-2V0.5z M-1,0.531h2V2.5h-2V0.531z M2,0.531h2V2.5H2V0.531z M-4,3.5h2v2h-2V3.5z M-1,3.531h2V5.5h-2V3.531z M2,3.531h2V5.5H2V3.531z M-4,6.5h2v2h-2V6.5z M-1,6.531h2V8.5h-2V6.531z M2,6.531h2V8.5H2V6.531z',
    monument: 'M0-9.5c0,0-2.59,2.004-2.5,3v11h-2c-0.554,0-1,0.446-1,1v2h-1c-0.554,0-1,0.446-1,1v1h15v-1c0-0.554-0.445-1-1-1h-1v-2c0-0.554-0.445-1-1-1h-2v-11C2.5-7.5,0-9.5,0-9.5z',
    museum: 'M0-9l-6.5,5h13L0-9z M-5.5-3c-0.554,0-0.922,0.452-1,1v7l-1.75,3C-8.398,8.254-8.5,8.356-8.5,8.5C-8.5,9-7.868,9-7.5,9h15c0.355,0,1,0,1-0.5c0-0.146-0.086-0.22-0.25-0.5L6.5,5v-7c0-0.554-0.445-1-1-1H-5.5z M-3.5-1C-3-1-2.5-1-2-1s1,1,1,1l1,2.5L1,0c0.223-0.558,0.5-1,1-1s1,0,1.5,0c0.555,0,1,0.446,1,1v5c0,0.5-0.5,1-1,1s-1-0.5-1-1V1L1,4c0,0-0.5,1-1,1s-1-1-1-1l-1.5-3v4c0,0.5-0.5,1-1,1s-1-0.5-1-1V4V0C-4.5-0.554-4.054-1-3.5-1z',
    music: 'M-2.5-8.5c-0.5,0-1,0.5-1,1v10h-1c-1.657,0-3,1.344-3,3s1.343,3,3,3s3-1.343,3-3v-9h7v6h-1c-1.656,0-3,1.344-3,3s1.344,3,3,3s3-1.343,3-3v-13c0-0.5-0.5-1-1-1H-2.5z',
    'oil-well': 'M-2-9c-0.5,0-0.846,0.5-1,1L-8.344,7.969C-8.382,8.082-8.5,8.385-8.5,8.5C-8.5,9-8,9-7.5,9s0.971,0,1.156-0.5L-5.5,6.25L0,3.5l5.5,2.75L6.344,8.5C6.529,9,7,9,7.5,9s1,0,1-0.5c0-0.115-0.118-0.418-0.156-0.531L3-8C2.846-8.5,2.5-9,2-9H-2z M2.5-2l0.688,1.906L0,1.5l-3.188-1.594L-2.5-2H2.5z M-3.813,1.594L-2,2.5l-2.625,1.313L-3.813,1.594z M3.813,1.594l0.813,2.219L2,2.5L3.813,1.594z',
    park: 'M0-9l-5.5,5h3l-4,4h3l-3,4h5v3v0.5V8c0,0.554,0.446,1,1,1h1c0.555,0,1-0.446,1-1V7.5V7V4h5l-3-4h3l-1-1l-3-3h3L0-9z',
    parking: 'M-1.523-0.336h0.797c0.744,0,1.303-0.145,1.672-0.438C1.314-1.07,1.5-1.5,1.5-2.063c0-0.566-0.156-0.986-0.469-1.258C0.725-3.591,0.24-3.727-0.422-3.727h-1.102V-0.336 M3.945-2.148c0,1.229-0.385,2.17-1.156,2.82C2.023,1.323,0.932,1.648-0.484,1.648h-1.039v4.063h-2.422V-5.71h3.648c1.385,0,2.438,0.299,3.156,0.898C3.584-4.219,3.945-3.33,3.945-2.148',
    'parking-garage': 'M0-7.828l-6.5,2v2l6.5-2l6.5,2v-2L0-7.828z M-3.094-3.578V7.828h2.406V3.765h1.063c1.416,0,2.516-0.318,3.281-0.969c0.771-0.651,1.125-1.584,1.125-2.813c0-1.183-0.338-2.063-1.063-2.656C3-3.271,1.947-3.578,0.563-3.578H-3.094z M-0.688-1.609h1.125c0.662,0,1.131,0.135,1.438,0.406c0.313,0.271,0.469,0.714,0.469,1.281c0,0.563-0.193,0.984-0.563,1.281C1.412,1.651,0.869,1.797,0.125,1.797h-0.813V-1.609z',
    pharmacy: 'M0-9.5c-3.037,0-5.5,1.119-5.5,2.5v3c0,1.381,2.463,2.5,5.5,2.5S5.5-2.619,5.5-4v-3C5.5-8.381,3.037-9.5,0-9.5z M-5.5-0.5V7c0,1.381,2.463,2.5,5.5,2.5S5.5,8.381,5.5,7v-7.5c0-1-0.5-1-1-0.5C3.662-0.106,2.271,0.5,0,0.5c-2.227,0-3.635-0.635-4.5-1.5C-5-1.5-5.5-1.5-5.5-0.5z M-2.5,2.5C-1.748,2.676-0.902,2.781,0,2.781c0.904,0,1.748-0.105,2.5-0.281v4C1.748,6.676,0.904,6.781,0,6.781c-0.902,0-1.748-0.105-2.5-0.281V2.5z',
    pitch: 'M-0.5-9.5c-1.104,0-2,0.896-2,2s0.896,2,2,2s2-0.896,2-2S0.604-9.5-0.5-9.5z M-6.25-5.5l0.5,2c0.25,1,0.847,1,1.594,1H-2.5c0.594,1,0.314,2.471,0.5,4h-0.906c-1,0-2.594,1-1.594,3l1.5,3C-2,9.5-0.5,8-1,7l-1.5-3C-2.75,3.5-2.236,3.5-2,3.5h2.468c0,0,1.185,2.627,2.578,4.428C3.561,8.594,4.131,9.5,4.845,9.5c0.5,0,1.356-0.773,0.444-1.861C3.582,5.602,2.5,3.5,2.5,2.5c0-1.164-0.204-3.613-0.5-5h1.5C3.988-1.522,4.75,0,4.75,0C5.303,0.958,6.5,0.885,6.5,0c0-0.122,0-0.389-0.14-0.678L4.75-4c-0.103-0.212-0.254-0.5-1-0.5H-4L-4.75-7C-5.05-8-6.5-8-6.5-7C-6.5-6.5-6.323-5.893-6.25-5.5z',
    'place-of-workship': 'M0-7.5c0,0-2.5,1-2.5,2c0,0.667,0,1.396,0,2c0,1-2,1-2,2c0,2.333,0,9,0,9h9c0,0,0-6.667,0-9c0-1-2-1-2-2.188c0-0.604,0-1.208,0-1.813C2.5-6.5,0-7.5,0-7.5z M-8.5,0.5c0,2.333,0,7,0,7h2c0,0,0-4.667,0-7c0-1-1-1-1-1S-8.5-0.5-8.5,0.5z M6.5,0.5c0,2.333,0,7,0,7h2c0,0,0-4.667,0-7c0-1-1-1-1-1S6.5-0.5,6.5,0.5z',
    playground: 'M2-9C1.169-9,0.5-8.331,0.5-7.5S1.169-6,2-6s1.5-0.669,1.5-1.5S2.831-9,2-9z M-4-6c-0.831,0-1.5,0.669-1.5,1.5S-4.831-3-4-3s1.5-0.669,1.5-1.5S-3.169-6-4-6z M3.375-5.531C3.201-5.476,3.084-5.241,3-5L1.031,0.688l-3.219,1l-2.406-3.813c-0.35-0.565-0.721-0.458-0.875,0.031l-1.969,5.438L-9.5,4l0.563,1.813l4.656-1.469l-2.031,2.375c-0.309,0.298-0.207,0.811,0,1.031c0.269,0.287,0.757,0.407,1.125,0c0,0,4.047-4.641,3.688-4.25c0.009-0.011-0.006-0.021,0-0.031L0,3l-2.5,6h6L1.063,2.656l1.469-0.469l3.813,2.688C6.719,5.129,7.363,5,7.5,4.469C7.633,3.951,7.348,3.721,7.031,3.5L4.344,1.625L9.5,0L8.938-1.813L6.875-1.156C6.168-2.375,4.469-4.512,4.063-5.031C3.777-5.467,3.549-5.587,3.375-5.531z',
    point: 'M-4,0c0,2.209,1.791,4,4,4s4-1.791,4-4S2.209-4,0-4S-4-2.209-4,0z',
    police: 'M-1.808-10.5L-2.5-9.51v0.989h6V-9.51L2.808-10.5H-1.808z M-2.5-7.5v0.464c0,1.914,1.215,3.536,3,3.536c1.785,0,3-1.622,3-3.536V-7.5H-2.5z M-3.5-2.5c-1.384,0-2,2.394-2,3.878V7.5h2.48l6.52-10H-3.5z M4.5-2.5l-6.135,10H6.5V1.378C6.5-0.106,5.884-2.5,4.5-2.5z',
    'polling-place': 'M-6.507-6.498c-0.554,0-1,0.446-1,1v13c0,0.555,0.446,1,1,1h13c0.555,0,1-0.445,1-1v-6h-2v5h-11v-11h8v-2H-6.507z M6.868-6.938L0.493,0.687l-1.844-2.156C-1.889-2.098-2.962-2.175-3.584-1.63C-4.208-1.085-4.079-0.064-3.602,0.5l2.989,3.533c0.57,0.649,1.73,0.634,2.281-0.03l7.512-9.034C9.494-5.47,9.615-6.277,9.369-6.714C9.073-7.237,8.507-7.525,7.993-7.498C7.492-7.472,7.109-7.247,6.868-6.938L6.868-6.938z',
    post: 'M-7.5-5.5c-1,0-0.5,0.339,0,0.75L0,1.047L7.5-4.75c0.5-0.411,1-0.75,0-0.75h-8H-7.5z M-8.5-4v8.5c0,0.5,0.506,1,0.906,1H7.594c0.4,0,0.906-0.5,0.906-1V-4L0,2.547L-8.5-4z',
    prison: 'M-7.5-7.5c-0.554,0-1,0.446-1,1v3v7v3c0,0.555,0.446,1,1,1s1-0.445,1-1v-2h3v2c0,0.555,0.446,1,1,1s1-0.445,1-1v-2h3v2c0,0.555,0.445,1,1,1s1-0.445,1-1v-2h3v2c0,0.555,0.445,1,1,1s1-0.445,1-1v-13c0-0.554-0.445-1-1-1s-1,0.446-1,1v2h-3v-2c0-0.554-0.445-1-1-1s-1,0.446-1,1v2h-3v-2c0-0.554-0.446-1-1-1s-1,0.446-1,1v2h-3v-2C-6.5-7.054-6.946-7.5-7.5-7.5z M-6.5-2.5h3v5h-3V-2.5z M-1.5-2.5h3v5h-3V-2.5z M3.5-2.5h3v5h-3V-2.5z',
    rail: 'M-3.501-9.5l-3.5,4v7v3c0,0.555-0.055,1,0.5,1h13c0.555,0,0.5-0.445,0.5-1v-3v-7l-3.5-4H-3.501z M-3-7.5h6v2h-6V-7.5z M-6-4.5H6v4H-6V-4.5z M-4.501,1.5c0.552,0,1,0.447,1,1s-0.448,1-1,1s-1-0.447-1-1S-5.054,1.5-4.501,1.5z M4.499,1.5c0.553,0,1,0.447,1,1s-0.447,1-1,1s-1-0.447-1-1S3.946,1.5,4.499,1.5z M-1.5,6.5h-3l-3,3h4L-1.5,6.5z M1.5,6.5h3l3,3h-4L1.5,6.5z',
    'religious-christian': 'M0-9c-0.831,0-1.5,0.669-1.5,1.5V-4h-5v3h5V9H0h1.5V-1h5v-3h-5v-3.5C1.5-8.331,0.831-9,0-9z',
    'religious-jewish': 'M0-9l-3,4h-5l3,5l-3,5h5l3,4l3-4h5L5,0l3-5H3L0-9z',
    'religious-muslim': 'M-0.5-8.5C-5.194-8.5-9-4.694-9,0s3.806,8.5,8.5,8.5c2.625,0,4.973-1.193,6.531-3.063C5.013,6.101,3.807,6.5,2.5,6.5C-1.09,6.5-4,3.59-4,0s2.91-6.5,6.5-6.5c1.307,0,2.513,0.399,3.531,1.063C4.473-7.307,2.125-8.5-0.5-8.5z M4.5-4.5l-1,3H0l3,2L1.5,4l3-2l3,2L6,0.5l3-2H5.5L4.5-4.5z',
    restaurant: 'M-5-9.5C-5.5-9.5-5.5-9-5.5-9v6c0,0.5,0.5,1,1,1.5S-4-0.5-4,0c0,0,0,1,0,1.5S-4.5,8-4.5,8C-4.538,8.498-4,9.5-3,9.5s1.539-1,1.5-1.5C-1.5,8-2,2-2,1.5S-2,0-2,0c0-0.5,0-1,0.5-1.5c0.46-0.46,1-1,1-1.5v-6c0,0,0-0.5-0.5-0.5S-1.5-9-1.5-9v4.5c0,0,0,0.5-0.5,0.5s-0.5-0.5-0.5-0.5V-9c0,0,0-0.5-0.5-0.5S-3.5-9-3.5-9v4.5c0,0,0,0.5-0.5,0.5s-0.5-0.5-0.5-0.5V-9C-4.5-9-4.5-9.5-5-9.5z M5.5-9.5C4.5-9.5,2.633-9.265,2-8C1.5-7,1.5-4.5,1.5-3.5v5c0,0.5,0.5,1,1,1h1v6c0,0.5,0.5,1,1,1s1-0.5,1-1V-9.5z',
    'road-block': 'M0-8.5c-4.694,0-8.5,3.806-8.5,8.5S-4.694,8.5,0,8.5S8.5,4.694,8.5,0S4.694-8.5,0-8.5z M-5.5-1.5h11v3h-11V-1.5z',
    rocket: 'M8-8H7C5-8,2.375-7.375-0.5-4.5C-3.5-1.5-5,2-5,2c0,1,2,3,3,3c0,0,3.25-1.25,6.25-4.25C7.464-2.464,8-5,8-7V-8z M2.5-4C3.328-4,4-3.328,4-2.5S3.328-1,2.5-1S1-1.672,1-2.5S1.672-4,2.5-4z M-7-2l-1,3h2.5l1.75-3H-7z M2,3.75L-1,5.5V8l3-1V3.75z',
    school: 'M2.25-9C1.28-9,0.5-8.22,0.5-7.25S1.28-5.5,2.25-5.5S4-6.28,4-7.25S3.22-9,2.25-9z M-5.75-7C-6.72-7-7.5-6.22-7.5-5.25S-6.72-3.5-5.75-3.5S-4-4.28-4-5.25S-4.78-7-5.75-7z M3.5-5C3.127-5,2.514-4.865,2-4.5l-3.25,2.313C-2.132-1.561-1.323-0.455-0.5-1l2.125-1.406c0,0,1.4,3.004,0.875,4.406C2.482,2.047,2.467,2.09,2.469,2.125L0.281,7.938C0.03,8.606,0.514,9.007,1,9c0.319-0.004,0.625-0.193,0.781-0.563L4.5,2C4.5,2,5,5,7,5c1.201,0,2.084,0,2.75,0c0.75,0,0.75-0.75,0.75-0.75s0-0.75-0.75-0.75c-0.5,0-1.75,0-2.25,0c-1.067,0-1-1.603-1-2.5c0-1.5-1-4-1-4l3,2c0.906,0.604,1.691-0.466,0.813-1.125L6-4.5C5.396-4.93,5.203-5,4.5-5C4.5-5,4-5,3.5-5z M-7-3c-0.5,0-0.736,0.236-1,0.5l-2.125,2.125C-10.398-0.102-10.5,0.144-10.5,0.5c0,0.5,0.764,0.735,1.188,0.313L-7.5-1c1,0,1.375,2.5,1,3.5l-1.5,4C-8.416,7.607-6.856,7.949-6.5,7L-5,3c0,0,0.5,1,1.5,1h3c1,0,1-1.5,0-1.5H-3c-0.5,0-0.5-1-0.5-1.5c0-2-1-4-2-4H-7z',
    scooter: 'M3-7.5c0,0-0.5,0-0.5,0.5S3-6.5,3-6.5h1.5l0.719,2.813L1.75,1.25C1.065,1.986,0.735,2.5,0,2.5h-2v-5h1c0.555,0,1-0.446,1-1s-0.445-1-1-1h-7c-0.554,0-1,0.446-1,1s0.446,1,1,1h0.5C-7.223-2.5-7-2.277-7-2s-0.223,0.5-0.5,0.5H-8c-0.5,0-1,0.5-1,1v5h9c1.629,0,2.619-1.071,3.25-1.75C3.967,1.979,4.396,1.5,5,1.5h4V1c0-0.5-0.813-1-1-1.5l-1-4c0.555,0,1-0.446,1-1v-1c0-0.554-0.445-1-1-1H3z M6.5,2.5C5.119,2.5,4,3.619,4,5s1.119,2.5,2.5,2.5S9,6.381,9,5S7.881,2.5,6.5,2.5z M-7.938,5.5c0.233,1.139,1.229,2,2.438,2s2.205-0.861,2.438-2H-7.938z',
    shells: 'M-7.775,6.547h15.55c0,0-0.188-0.713-0.5-0.779L5.01,5.713C7.635,2.357,8.055,1.43,7.191-0.522C7.016-0.921,7.109-2.04,6.775-2.584C6.38-3.23,5.723-3.512,5.441-3.751C5.178-3.977,4.867-4.829,4.217-5.162C3.464-5.549,2.964-5.321,2.48-5.501C1.986-5.686,1.415-6.202,0.745-6.434c-0.432-0.15-0.963-0.15-1.488,0.001C-1.425-6.237-1.986-5.685-2.48-5.501c-0.483,0.18-0.982-0.048-1.736,0.339c-0.65,0.333-0.962,1.185-1.226,1.411C-5.723-3.511-6.38-3.23-6.775-2.584c-0.333,0.544-0.24,1.663-0.417,2.063C-8.055,1.43-7.635,2.357-5.009,5.713l-2.266,0.055C-7.587,5.834-7.775,6.547-7.775,6.547z M4.876-1.594l0.086,0.03L2.623,4.81L2.455,4.748L4.876-1.594z M2.354-4.81l0.095,0.018L0.577,4.81L0.391,4.773L2.354-4.81z M-2.354-4.81l1.963,9.583L-0.577,4.81l-1.87-9.602L-2.354-4.81z M-4.878-1.594l2.422,6.342L-2.624,4.81l-2.338-6.374L-4.878-1.594z',
    shop: 'M-1.5-8.5c-1,0-1.887,1.006-2,2l-0.344,3H-5.5c-0.5,0-1,0.5-1,1l-1,10c0,0.5,0.5,1,1,1h13c0.5,0,1-0.5,1-1l-1-10c0-0.5-0.5-1-1-1H3.844L3.5-6.5c-0.113-0.994-1-2-2-2H-1.5z M-1.5-7.5h3c0.5,0,0.943,0.503,1,1l0.344,3h-5.688l0.344-3C-2.443-6.997-2-7.5-1.5-7.5z',
    skiing: 'M-0.5-8c-1,0-2,0.5-2,2c0,0.537,0.043,0.628,0.156,1.094l0.688,2.844l-3.719,2.281L-8.75-1.75c-0.112-0.066-0.259-0.068-0.438-0.031C-9.366-1.744-9.5-1.532-9.5-1.313c0,0.22,0.096,0.348,0.25,0.438l14.5,8.5C5.705,7.889,6.117,7.994,6.5,8c0.268,0,0.531-0.035,0.75-0.125c0.53-0.218,0.861-0.611,1.094-0.844C8.46,6.915,8.5,6.773,8.5,6.625c0-0.211-0.129-0.436-0.406-0.469C7.914,6.136,7.77,6.201,7.656,6.344C7.422,6.642,7.129,6.865,6.875,6.969C6.621,7.073,6.342,7.092,5.75,6.75l-9.281-5.438l3.594-2.094c0.332-0.228,0.502-0.67,0.406-1.063L0.063-3.5l3.576,2.105l0.674,2.571c0.077,0.287,0.29,0.536,0.563,0.656l2.281,1c1.605,0.704,1.835-0.826,0.813-1.27L6.094,0.75L4.5-5.499l-3.063-1.75C0.651-7.703,0-7.999-0.5-8L-0.5-8z M7.5-6c-1.104,0-2,0.896-2,2s0.896,2,2,2s2-0.896,2-2S8.604-6,7.5-6z',
    skins: 'M1.614-5.52C2.879-5.323,4.61-4.891,5.708-5.643C6.939-6.484,3.476-3.03,3.349,0.458c-0.136,3.717,3.129,7.26,1.706,6.477C3.172,5.899,0,6.733,0,6.733s-3.652-0.9-5.055,0.202c-1.277,1.005,1.843-2.76,1.708-6.477c-0.127-3.488-3.591-6.943-2.36-6.101c1.106,0.757,2.83,0.322,4.098,0.122l0.393-1.073c0.149-0.126,0.69-0.23,0.69-0.23S-0.403-7.1,0-7.101c0.376-0.001,0.527,0.277,0.527,0.277s0.541,0.105,0.691,0.23L1.614-5.52z',
    slaughterhouse: 'M-8.5-6.5l1,1l-2,2v1l3,0.5c0,1.5,2.529,0.677,3,3.5v5h1l1-4l1,4h1v-4c2,0,3-1,4-1C4.5,1.5,5,3.15,5,4S4.5,6.5,4.5,6.5h1l1.5-4l1.5,4h1c0,0,0-2.667,0-4c0-0.5-1-1-1-1.5c0-1.412,1-1.5,1-2.5c0-0.284,0-2.5,0-3s-0.5-1-1-1h-13l-1-1H-8.5z',
    soccer: 'M0-8.5c-4.684,0-8.5,3.817-8.5,8.5c0,4.684,3.816,8.5,8.5,8.5S8.5,4.684,8.5,0C8.5-4.683,4.684-8.5,0-8.5z M0-7c2.666,0,4.974,1.474,6.156,3.656C5.04-2.604,4.015-2.216,4-1.5C3.99-1,4-0.859,4-0.5C4,0,4.26,0.644,5,1l1.75,0.844c-0.2,0.74-0.522,1.41-0.938,2.031L4.5,3C3.75,2.5,3,2.166,2.5,2.5L1,3.5C0.307,3.963,0.379,4.5,0.5,5l0.469,1.938C0.651,6.981,0.33,7,0,7c-0.693,0-1.369-0.096-2-0.281V5c0-1-0.5-1.5-1-2s-1-1-2-1h-1.719C-6.904,1.369-7,0.693-7,0C-7-3.878-3.878-7,0-7z M-3-3.5l-0.5,2c-0.143,0.567-0.52,1.153,0,1.5L-2,1c1,0.666,1.441,0.279,2,0l1-0.5C1.745,0.127,2,0,2-1v-2c0-0.5,0.008-0.798-1-1l-2.5-0.5C-2.547-4.709-2.744-4.523-3-3.5z',
    star: 'M0-9l-2,6h-6.5l5,5L-6,9l6-4l6,4L3.5,2l5-5h-6L0-9z',
    'star-stroked': 'M0-9l-2,6h-6.5l5,5L-6,9l6-4l6,4L3.5,2l5-5H2L0-9z M0-5l1,3.5h4L1.5,2L3,5.5L0,3l-3,2.5L-1.5,2L-5-1.5h4L0-5z',
    square: 'M-7.5-8.5h15c0.553,0,1,0.448,1,1v15c0,0.553-0.447,1-1,1h-15c-0.553,0-1-0.447-1-1v-15C-8.5-8.052-8.053-8.5-7.5-8.5z',
    'square-stroked': 'M-7.5-8.5c-0.554,0-1,0.446-1,1v15c0,0.555,0.446,1,1,1h15c0.555,0,1-0.445,1-1v-15c0-0.554-0.445-1-1-1H-7.5z M-6.5-6.5h13v13h-13V-6.5z',
    suitcase: 'M7.5-4.5h-4v-2c0-0.553-0.448-1-1-1h-5c-0.552,0-1,0.447-1,1v2h-4c-0.552,0-1,0.447-1,1v10c0,0.553,0.448,1,1,1h15c0.552,0,1-0.447,1-1v-10C8.5-4.053,8.052-4.5,7.5-4.5z M-4.5,6.5h-1v-10h1V6.5z M2.5-4.5h-5v-2h5V-4.5z M5.5,6.5h-1v-10h1V6.5z',
    swimming: 'M2.625-7C2.467-7,2.191-6.907,2.063-6.844l-4,1.938c-0.533,0.252-0.727,1.027-0.375,1.5l1.219,1.656l-4.719,3.656C-4.684,2.135-3.837,2.844-3,2.844c1,0,1.5-1,3-1s2,1,3,1c0.412,0,0.834-0.175,1.281-0.375L0.063-3.656l3.031-1.531c0.602-0.301,0.563-0.817,0.5-1.125C3.535-6.604,3.174-7,2.625-7z M4.5-4c-1.105,0-2,0.896-2,2s0.895,2,2,2c1.104,0,2-0.896,2-2S5.604-4,4.5-4z M-6.5,4c-0.694,0-1.34,0.404-2,0.625v2.031c0,0,1-0.656,2-0.656s2,1,3.5,1s1.973-0.965,3-1c1,0,1.5,1,3,1s2.5-1,3.5-1c0.701,0,2,0.656,2,0.656V4.625C7.857,4.377,7.156,4,6.5,4C5,4,4,5,3,5S1.5,4,0,4s-2,1-3,1S-5,4-6.5,4z',
    telephone: 'M2.867-8.133c-1,0-1.326,0.326-2,1l-8,8c-0.5,0.5-1,1-1,2c0,0.833,0,1,0,1.5c0,1,0.5,1.5,1,2L-6.57,6.93l3.5-3.5l-0.563-0.563c-0.5-0.5,0-1,0-1l5.5-5.5c0,0,0.5-0.5,1,0L3.43-3.07l3.5-3.5c0,0-0.375-0.375-0.563-0.563c-0.5-0.5-1-1-2-1C3.867-8.133,2.867-8.133,2.867-8.133z M7.305-6.195l-3.5,3.5l0.563,0.563c0.354,0.354,1.146,0.354,1.5,0l2-2c0.354-0.354,0.354-1.146,0-1.5L7.305-6.195z M-2.695,3.805l-3.5,3.5l0.563,0.563c0.354,0.354,1.146,0.354,1.5,0l2-2c0.354-0.354,0.354-1.146,0-1.5L-2.695,3.805z',
    tennis: 'M-2.5-8.5c-3.313,0-6,2.687-6,6s2.687,6,6,6h5L7,8c0.316,0.316,0.506,0.5,0.906,0.5c0.125,0,0.281,0,0.438-0.156S8.5,8.031,8.5,7.906C8.5,7.506,8.282,7.282,8,7L3.5,2.5v-5C3.5-5.813,0.813-8.5-2.5-8.5z M2.5-0.5v3h-3L2.5-0.5z M-7,3.5c-0.829,0-1.5,0.672-1.5,1.5S-7.829,6.5-7,6.5S-5.5,5.828-5.5,5S-6.171,3.5-7,3.5z',
    theatre: 'M-7.5-9c-0.471,0-1,0.5-1,1v7c0,2.216,1,5,5,5c0.359,0,0.686-0.018,1-0.063V1h-4c0,0,0.5-2,3-2c0.5,0,0.696,0.074,1,0.188V-3c0-0.917,0.365-1.683,1-2c1-0.5,1.404-0.298,2,0c1,0.5,1,0.5,1,0.5V-8c0-0.5-0.529-1-1-1c-1,0-2,1-4,1S-6.5-9-7.5-9L-7.5-9z M-5.75-5.5c0.69,0,1.25,0.56,1.25,1.25S-5.06-3-5.75-3S-7-3.56-7-4.25S-6.44-5.5-5.75-5.5z M-0.5-4c-0.471,0-1,0.5-1,1v7c0,2.216,1,5,5,5s5-2.784,5-5v-7c0-0.5-0.529-1-1-1c-1,0-2,1-4,1S0.5-4-0.5-4z M1.25-0.5c0.69,0,1.25,0.56,1.25,1.25S1.94,2,1.25,2S0,1.44,0,0.75S0.56-0.5,1.25-0.5z M5.75-0.5C6.44-0.5,7,0.06,7,0.75S6.44,2,5.75,2S4.5,1.44,4.5,0.75S5.06-0.5,5.75-0.5z M0.5,5h6c0,0-0.5,2-3,2S0.5,5,0.5,5z',
    toilets: 'M-3.75-9.5c-1.381,0-2.5,1.119-2.5,2.5s1.119,2.5,2.5,2.5s2.5-1.119,2.5-2.5S-2.369-9.5-3.75-9.5z M4.25-9.5c-1.381,0-2.5,1.119-2.5,2.5s1.119,2.5,2.5,2.5s2.5-1.119,2.5-2.5S5.631-9.5,4.25-9.5z M-4.25-3.5c-1,0-2,1-2,2v2L-7.75,5c0,0.5,0.5,0.5,1,0.5h1.5v4h3v-4h1.5c0.5,0,1,0,1-0.5l-1.5-4.5v-2c0-1-1-2-2-2H-4.25z M1.75-3.5c-0.5,0-1,0.5-1,1l2,6v2v4h3v-4v-2l2-6c0-0.5-0.5-1-1-1H1.75z',
    town: 'M-4.5-6.5l-3,1.5c-0.511,0.256-1,1-1,1.5v8h3v-3h2v3h3v-8c0-0.5-0.502-1.251-1-1.5L-4.5-6.5z M4.5-4.5L1.5-3c-0.512,0.256-1,1-1,1.5v8h3v-3h2v3h3v-8c0-0.5-0.502-1.251-1-1.5L4.5-4.5z M-7.5-2.5h2v1h-2V-2.5z M-3.5-2.5h2v1h-2V-2.5z M-7.5-0.5h2v1h-2V-0.5z M-3.5-0.5h2v1h-2V-0.5z M1.5-0.5h2v1h-2V-0.5z M5.5-0.5h2v1h-2V-0.5z M1.5,1.5h2v1h-2V1.5z M5.5,1.5h2v1h-2V1.5z',
    'town-hall': 'M0-9l-7.5,5h15L0-9z M-6.5-3c-0.555,0-1,0.446-1,1v7l-1.75,3C-9.398,8.254-9.5,8.356-9.5,8.5C-9.5,9-8.868,9-8.5,9h17c0.355,0,1,0,1-0.5c0-0.146-0.086-0.22-0.25-0.5L7.5,5v-7c0-0.554-0.445-1-1-1H-6.5z M-4.5-1c0.555,0,0.908,0.453,1,1v6h-2V4V0C-5.5-0.554-5.055-1-4.5-1z M0-1c0.555,0,1.009,0.446,1,1v4v2h-2V4V0C-1-0.554-0.555-1,0-1z M4.5-1c0.555,0,1,0.446,1,1v6h-2V4V0C3.5-0.554,3.945-1,4.5-1z',
    triangle: 'M0-8c-0.5,0-0.726,0.239-0.875,0.5l-8.5,14C-9.463,6.654-9.5,6.827-9.5,7c0,0.75,0.5,1,1,1h17c0.5,0,1-0.25,1-1c0-0.173-0.037-0.346-0.125-0.5l-8.5-14C0.727-7.761,0.5-8,0-8z',
    'triangle-stroked': 'M0-8.5c-0.5,0-0.727,0.239-0.875,0.5l-8.5,14C-9.463,6.154-9.5,6.327-9.5,6.5c0,0.75,0.5,1,1,1h17c0.5,0,1-0.25,1-1c0-0.173-0.037-0.346-0.125-0.5l-8.5-14C0.727-8.261,0.5-8.5,0-8.5z M0-5.5l6.781,11H-6.781L0-5.5z',
    village: 'M-4.5-6.5l-4.5,4c-0.5,0.5,0,1,0.5,1h1v5h1h1v-3h2v3h1h1v-5h1c0.5,0,1-0.5,0.5-1L-4.5-6.5z M4.5-3.5L0,0.5c-0.5,0.5,0,1,0.5,1h1v5h1h1v-3h2v3h1h1v-5h1c0.5,0,1-0.5,0.5-1L4.5-3.5z',
    warehouse: 'M-1.5-8.5L-9-2c-0.361,0.302-0.5,0.5-0.5,0.75c0,0.496,0.5,0.75,1,0.75h1v8c0,0.555,0.445,1,1,1h13c0.555,0,1-0.445,1-1v-8h1c0.5,0,1-0.254,1-0.75C9.5-1.5,9.361-1.698,9-2L1.5-8.5H-1.5z M-3.5,0.5h7V1h-7V0.5z M-3.5,2.5h7V3h-7V2.5z M-3.5,4.5h7V5h-7V4.5z M-3.5,6.5h7V7h-7V6.5z',
    'waste-basket': 'M-2-9.5c-0.277,0-0.5,0.223-0.5,0.5v1.5h-4c-0.555,0-1,0.446-1,1s0.445,1,1,1h13c0.555,0,1-0.446,1-1s-0.445-1-1-1h-4V-9c0-0.277-0.223-0.5-0.5-0.5H-2z M-1.5-8.5h3v1h-3V-8.5z M-6.5-4.5c-0.555,0-1.076,0.454-0.984,1l1.984,12c0.09,0.547,0.445,1,1,1h9c0.555,0,0.908-0.453,1-1l1.984-12c0.09-0.546-0.43-1-0.984-1H-6.5z',
    water: 'M6.5,2.5C6.5-1,1.5-7,0-9C-1.5-7-6.5-1-6.5,2.5S-3.229,9,0,9S6.5,6,6.5,2.5z',
    wetland: 'M4.5-7c-2,0-3.598,2-4,4l-1.125,5.563C-0.438,2.523-0.229,2.5,0,2.5c1.5,0,2,1,3,1c0.197,0,0.393-0.031,0.594-0.094C3.819,2.285,4.344-0.22,4.5-1c0.191-0.952,1-2,2-2h1c0-0.5-0.5-1-1-1h-1C4.67-4,3.898-3.693,3.344-3.25L3.5-4c0.197-0.98,1-2,2-2h1c0-0.5-0.5-1-1-1H4.5L4.5-7z M-6.5-4c-0.5,0-1,0.5-1,1h1c1,0,1.809,1.048,2,2c0.152,0.756,0.662,3.203,0.906,4.406C-3.393,3.469-3.197,3.5-3,3.5c0.566,0,0.982-0.315,1.5-0.594L-2.5-2c-0.201-1-1.5-2-3-2H-6.5L-6.5-4z M-6.5,4c-0.693,0-1.34,0.404-2,0.625v2.031c0,0,1-0.656,2-0.656s2,1,3.5,1s1.974-0.965,3-1c1,0,1.5,1,3,1s2.5-1,3.5-1c0.701,0,2,0.656,2,0.656V4.625C7.858,4.378,7.156,4,6.5,4C5,4,4,5,3,5S1.5,4,0,4s-2,1-3,1S-5,4-6.5,4z',
};

export default annotationMetadata;
