module.exports={
  "allDataBySource": "All data (by source)",
  "filterList": "Filter list...",
  "notAvailableForSelectedYear": "Not available for selected year",
  "availableIn": "Available in ",
  "searchForData": "Search for data",
  "applyToBothMaps": "Apply to both maps",
  "areYouSureYouWantToChooseVariable": "Are you sure you want to choose variable from different survey?  This action will remove all data filters from the map.",
  "gettingCurrentData": "Getting current data",
  "gettingDataCategoryDetails": "Getting data category details",
  "gettingMetadata": "Getting metadata",
  "searchYear": "Search year",
  "renameTheVariable": "Rename the variable",
  "aboutThisVariable": "About this variable"
}
