import React from 'react';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';

import BusComponent from '../BusComponent';
import FrameType from '../../enums/FrameType';
import FrameLockModes from '../../enums/FrameLockMode';
import Tooltip from '../Tooltip';
import Toggle from '../form/Toggle';
import FrameTypeButton from './FrameTypeButton';
import { mapViewButtonEvents } from '../../enums/GoogleTagManagerEvents';
import { hasParentNode } from '../../helpers/Util';
import { HelpTourTargets } from '../../enums/HelpTourDefinitions';
import ApplicationMode from '../../enums/ApplicationMode';
import Mixpanel, { MIXPANEL_EVENTS } from '../../helpers/Mixpanel';

const CLOSE_BUTTON_ID = 'frame-type-switcher-close';

class FrameTypeSwitcher extends BusComponent {
    componentDidMount() {
        document.addEventListener('mousedown', this.handleDocumentMouseDown);
    }

    componentDidUpdate() {
        if (this.closeButton) {
            mapViewButtonEvents.forEach(({ name, value }) => {
                this.closeButton.setAttribute(name, value);
            });
            this.setGTMAttributes();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleDocumentMouseDown);
    }

    setGTMAttributes = () => {
        const { frameType } = this.props;
        switch (frameType) {
            case FrameType.SINGLE_MAP:
                this.closeButton.setAttribute('gtm-eventa', 'Slider single');
                break;
            case FrameType.SIDE_BY_SIDE_MAPS:
                this.closeButton.setAttribute('gtm-eventa', 'Slider side by side');
                break;
            case FrameType.SWIPE:
                this.closeButton.setAttribute('gtm-eventa', 'Slider swipe');
                break;
        }
    };

    handleDocumentMouseDown = e => {
        if (!this.props.visible) return;

        if (hasParentNode(e.target, this.rootEl)) return;

        // Simulate close click so we can get the GTM events
        const closeButton = document.getElementById(CLOSE_BUTTON_ID);
        if (closeButton) {
            closeButton.click();
        }
    };

    handleFrameClick = frameType => {
        const { mapInstances } = this.props;
        const mapInstanceIdsWithAnalyses = mapInstances
            .filter(map => map.isLocationAnalysisActive && map.locationAnalysisItem.analysisTypeId)
            .map(mapInstance => mapInstance.id);

        if (mapInstanceIdsWithAnalyses.length !== 0 && frameType !== FrameType.SINGLE_MAP) {
            this.emit('ANALYSIS_REMOVAL_WARNING_REQUEST', {
                mapInstanceIds: mapInstanceIdsWithAnalyses,
                frameType,
            });
            return;
        }

        this.emit('CHANGE_PROJECT_FRAME_TYPE_REQUEST', { frameType });
    };

    shrink = () => {
        this.props.onVisibilityChange(false);
    };

    expand = () => {
        this.props.onVisibilityChange(true);
    };

    handleTriggerClick = event => {
        this.props.onVisibilityChange(!this.props.visible);
        this.focusBackElement = event.target;
        Mixpanel.emitUserEvent(MIXPANEL_EVENTS.COMPARE_MENU);
    };

    handleCloseClick = () => {
        this.shrink();
        if (this.focusBackElement) {
            this.focusBackElement.focus();
        }
    };

    handleFlipMapsClick = () => {
        this.emit('SWITCH_PROJECT_FRAME_MAP_INSTANCES_REQUEST');
    };

    handleLinkMapsChange = () => {
        this.emit('TOGGLE_PROJECT_FRAME_LOCK_MODE_REQUEST');
    };

    renderAdditionalOptions() {
        const { frameType, lockMode, intl } = this.props;

        return (
            <div className="frame-type-switcher__additional">
                <button
                    className="frame-type-switcher__option"
                    aria-label={intl.formatMessage({ id: 'map.flipMaps' })}
                    onClick={this.handleFlipMapsClick}
                >
                    <span>{intl.formatMessage({ id: 'map.flipMaps' })}</span>
                    <div className="btn-icon flex-it justify-center center">
                        <i className="material-icons">swap_horiz</i>
                    </div>
                </button>
                <div className="frame-type-switcher__option-separator" />
                <button
                    className="frame-type-switcher__option"
                    disabled={frameType === FrameType.SWIPE}
                    onClick={this.handleLinkMapsChange}
                >
                    <span>{intl.formatMessage({ id: 'map.linkMaps' })}</span>
                    <Toggle
                        checked={lockMode === FrameLockModes.POSITION}
                        disabled={frameType === FrameType.SWIPE}
                    />
                </button>
            </div>
        );
    }

    renderMenu() {
        const { frameType, visible, intl } = this.props;
        const classes = classNames('frame-type-switcher', {
            'frame-type-switcher--expanded': visible,
        });

        return (
            <div className={classes}>
                <div className="frame-type-switcher__menu">
                    <div className="frame-type-switcher__header">
                        <h4>{intl.formatMessage({ id: 'map.chooseCompareView' })}</h4>
                        <button
                            id={CLOSE_BUTTON_ID}
                            ref={c => {
                                this.closeButton = c;
                            }}
                            className="btn-icon flex-it justify-center center"
                            onClick={this.handleCloseClick}
                        >
                            <i className="material-icons">close</i>
                        </button>
                    </div>
                    <div className="frame-types">
                        <FrameTypeButton
                            title={intl.formatMessage({ id: 'off' })}
                            onClick={this.handleFrameClick}
                            icon="socex-icon-frame-single"
                            value={FrameType.SINGLE_MAP}
                            active={frameType === FrameType.SINGLE_MAP}
                        />
                        <FrameTypeButton
                            title={intl.formatMessage({ id: 'map.sideBySide' })}
                            onClick={this.handleFrameClick}
                            icon="socex-icon-frame-side-by-side"
                            value={FrameType.SIDE_BY_SIDE_MAPS}
                            active={frameType === FrameType.SIDE_BY_SIDE_MAPS}
                        />
                        <FrameTypeButton
                            title={intl.formatMessage({ id: 'map.swipe' })}
                            onClick={this.handleFrameClick}
                            icon="socex-icon-frame-swipe"
                            value={FrameType.SWIPE}
                            active={frameType === FrameType.SWIPE}
                        />
                    </div>
                    {frameType !== FrameType.SINGLE_MAP && this.renderAdditionalOptions()}
                </div>
            </div>
        );
    }

    render() {
        const { visible, applicationMode, intl } = this.props;

        if (
            applicationMode !== ApplicationMode.EXPLORE &&
            applicationMode !== ApplicationMode.EDIT
        ) {
            return null;
        }

        return (
            <div
                className="frame-type-switcher__section"
                ref={c => {
                    this.rootEl = c;
                }}
                data-tourId={HelpTourTargets.MAP_FRAME_TYPES}
            >
                <Tooltip
                    placement="bottom"
                    mouseEnterDelay={0.5}
                    mouseLeaveDelay={0}
                    overlay={
                        <span className="light-text">
                            {intl.formatMessage({ id: 'map.changeMapView' })}
                        </span>
                    }
                >
                    <button
                        className={classNames('compare-locations-section', 'clickable')}
                        onClick={this.handleTriggerClick}
                    >
                        <span className="compare-locations-section__label">
                            {intl.formatMessage({ id: 'map.compare' })}
                        </span>
                        <i className="material-icons">
                            {visible ? 'arrow_drop_up' : 'arrow_drop_down'}
                        </i>
                    </button>
                </Tooltip>
                {visible && this.renderMenu()}
            </div>
        );
    }
}

export default injectIntl(FrameTypeSwitcher);
