// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import SearchTextInput from './SearchTextInput';
import Tooltip from '../../Tooltip';

/**
 * @param {object} props
 * @param {string} props.query
 * @param {(e: KeyboardEvent) => void} props.onKey
 * @param {(e: InputEvent) => void} props.onChange
 * @param {() => void} props.onSearch
 * @param {() => void} props.onClearSearch
 * @param {import('react-intl').intlShape} props.intl
 */
const DataBrowserBasicSearch = ({
    query,
    onKey,
    onChange,
    onSearch,
    onClearSearch,
    intl,
}) => (
    <div className="data-browser-search__basic-search">
        <div className="search-input-wrapper">
            <SearchTextInput
                onKeyDown={onKey}
                onEnter={onSearch}
                onChange={onChange}
                value={query}
            />
            <div className="search-input-wrapper__actions">
                {query && (
                    <div className="flex-it center">
                        <button
                            className="search-input-wrapper__clear-button"
                            onClick={onClearSearch}
                            aria-label={intl.formatMessage({
                                id: 'dataBrowser.clearSearchInput',
                            })}
                        >
                            <i className="material-icons">close</i>
                        </button>
                        <div className="search-input-wrapper__divider divider divider--vertical" />
                    </div>
                )}
                <Tooltip
                    placement="bottom"
                    mouseEnterDelay={0.2}
                    mouseLeaveDelay={0}
                    overlay={intl.formatMessage({ id: 'dataBrowser.search' })}
                >
                    <button
                        className="btn-icon btn-icon--raised"
                        onClick={onSearch}
                        aria-label={intl.formatMessage({
                            id: 'dataBrowser.search',
                        })}
                    >
                        <i className="material-icons">search</i>
                    </button>
                </Tooltip>
            </div>
        </div>
    </div>
);

DataBrowserBasicSearch.propTypes = {
    query: PropTypes.string,
    onKey: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    onClearSearch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
};

DataBrowserBasicSearch.defaultProps = {
    query: undefined,
};

export default injectIntl(DataBrowserBasicSearch);
