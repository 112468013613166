// @ts-check
import React from 'react';
import { injectIntl } from 'react-intl';

import AdditionalSettingsIconColors from '../../../enums/AdditionalSettingsIconColors';

import BusComponent from '../../BusComponent';
import AdditionalSettingsItem from './AdditionalSettingsItem';
import { ActiveView } from '../../mapLayersEditor/MapLayersEditor';

/**
 * @typedef Props
 * @property {import('react-intl').intlShape} intl
 * @property {string} mapInstanceId
 *
 * @extends BusComponent<Props>
 */
class AnnotationItem extends BusComponent {
    onAnnotateMapClick = () => {
        this.emit('DISPLAY_MAP_EDITOR_FRAME', {
            mapInstanceId: this.props.mapInstanceId,
            initialView: ActiveView.ANNOTATIONS,
        });
    };

    render() {
        return (
            <AdditionalSettingsItem
                icon={<i className="material-icons">gesture</i>}
                onItemClick={this.onAnnotateMapClick}
                title={this.props.intl.formatMessage({
                    id: 'dataBrowser.annotateMap',
                })}
                description={this.props.intl.formatMessage({
                    id: 'dataBrowser.addMarkersLinesShapesOrGroundOverlaysOnTheMap',
                })}
                iconStyle={{
                    background: AdditionalSettingsIconColors.ANNOTATIONS,
                }}
            />
        );
    }
}

export default injectIntl(AnnotationItem);
