// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';

import Tooltip from '../Tooltip';
import UserLocationPin from './UserLocationPin';

/**
 * @typedef Props
 * @property {React.EventHandler<React.MouseEvent<HTMLButtonElement>>} onClick
 * @property {boolean} open
 * @property {string[]} mapInstanceIds
 * @property {import('react-intl').intlShape} intl
 *
 * @param {Props} props
 */
const UserLocationListTrigger = ({
    onClick,
    open,
    mapInstanceIds,
    intl,
}) => (
    <div className="user-locations-popup-trigger">
        <UserLocationPin
            mapInstanceIds={mapInstanceIds}
        />
        <Tooltip
            placement="bottom"
            mouseEnterDelay={0.5}
            mouseLeaveDelay={0}
            overlay={intl.formatMessage({
                id: 'userLocation.savedPlacesTooltip',
            })}
        >
            <button
                className={classNames(
                    'user-locations-popup-trigger__arrow',
                    'clickable',
                    {
                        'user-locations-popup-trigger__arrow--active': open,
                    },
                )}
                onClick={onClick}
            >
                <i className="material-icons">
                    {open ? 'arrow_drop_up' : 'arrow_drop_down'}
                </i>
            </button>
        </Tooltip>
    </div>
);

UserLocationListTrigger.propTypes = {
    onClick: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    mapInstanceIds: PropTypes.array.isRequired,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(UserLocationListTrigger);
