import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import IconMenu from '../../iconMenu/IconMenu';
import LayerTitleEditor from './LayerTitleEditor';
import Tooltip from '../../Tooltip';
import { doneUploadLayerButtonEvents } from '../../../enums/GoogleTagManagerEvents';
import googleTagManagerEvents from '../../../helpers/GoogleTagManagerEventsWrapper';

const DoneUploadLayerButton = googleTagManagerEvents('button', doneUploadLayerButtonEvents);

class EditorHeader extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            isEditingTitle: false,
        };
    }

    onCancelEditTitleClick = () => {
        this.setState({ isEditingTitle: false });
    }

    onEditTitleClick = () => {
        this.setState({ isEditingTitle: true });
    }

    onSaveTitleClick = title => {
        this.props.onSaveTitleClick(title);
        this.setState({ isEditingTitle: false });
    };

    render() {
        const {
            userDataLayerTitle,
            onFitClick,
            onBack,
            onDeleteClick,
        } = this.props;

        if (this.state.isEditingTitle) {
            return (<LayerTitleEditor
                value={userDataLayerTitle}
                onDone={this.onSaveTitleClick}
                onCancel={this.onCancelEditTitleClick}
            />);
        }
        return (
            <div className="user-data-upload-editor__header user-data-upload-editor__header--colored">
                <div className="flex-it column flex-start min-width-0">
                    <h4 className="light-text">
                        {this.props.intl.formatMessage({ id: 'dataBrowser.editLayer' })}
                    </h4>
                    <h6 className="user-data-upload-editor__title ellipsis" title={userDataLayerTitle}>{userDataLayerTitle}</h6>
                </div>
                <div className="flex-it space-between no-shrink center user-data-upload-editor__header-actions">
                    <Tooltip
                        placement="bottom"
                        mouseEnterDelay={0.5}
                        mouseLeaveDelay={0}
                        overlay={this.props.intl.formatMessage({ id: 'map.centerMapOnLayer' })}
                    >
                        <button className="btn-icon btn-icon--light" onClick={onFitClick}>
                            <i className="material-icons">filter_center_focus</i>
                        </button>
                    </Tooltip>
                    <DoneUploadLayerButton
                        className="btn-raised btn-raised--mild"
                        onClick={onBack}
                    >
                        {this.props.intl.formatMessage({ id: 'done' })}
                    </DoneUploadLayerButton>
                    <IconMenu
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        targetOrigin={{ vertical: 'top', horizontal: 'left' }}
                        light
                    >
                        <div className="material-menu">
                            <div onClick={this.onEditTitleClick} className="material-menu__item">
                                <FormattedMessage id="rename" />
                            </div>
                            <div onClick={onDeleteClick} className="material-menu__item">
                                <FormattedMessage id="dataBrowser.removeLayer" />
                            </div>
                        </div>
                    </IconMenu>
                </div>
            </div>
        );
    }
}

EditorHeader.propTypes = {
    userDataLayerTitle: PropTypes.string.isRequired,
    onFitClick: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
    onSaveTitleClick: PropTypes.func.isRequired,
    onDeleteClick: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
};

EditorHeader.defaultProps = {};

export default injectIntl(EditorHeader);
