import LibraryLayer from '../objects/LibraryLayer';

export function getGroupLayers(group) {
    return group.layers.map(layer => new LibraryLayer({
        id: layer.id,
        title: layer.title,
        visible: true,
    }));
}

export function getGroupLayersDefinition(group) {
    return [].concat(...group.layers.map(lay => lay.layers));
}

export function isPointBasedLayer(layer) {
    return ['symbol', 'circle', 'bubble'].indexOf(layer.type) > -1;
}
