import React from 'react';
import PropTypes from 'prop-types';

import BusComponent from '../../BusComponent';
import CreateReportButton from './CreateReportButton';
import CreateReportButtonMenu from './CreateReportButtonMenu';

class CreateReport extends BusComponent {
    render() {
        const { mapInstances } = this.props;

        // if mapInstances are not defined, don't render anything
        if (!mapInstances.length) {
            return null;
        }

        // if only one map instance is defined,
        // render only create report button for this instance
        if (mapInstances.length === 1) {
            return <CreateReportButton mapInstance={mapInstances[0]} />;
        }

        // otherwise render menu with left/right map options
        return (
            <CreateReportButtonMenu
                leftMapInstance={mapInstances[0]}
                rightMapInstance={mapInstances[1]}
            />
        );
    }
}

CreateReport.propTypes = {
    mapInstances: PropTypes.array.isRequired,
};

export default CreateReport;
