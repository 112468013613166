import AppConfig from '../appConfig';

import LabelSize from '../enums/LabelSize';
import MarkerSize from '../enums/MarkerSize';
import StrokeWeight from '../enums/StrokeWeight';
import AnnotationsMarkers, { basePngSize } from '../enums/AnnotationsMarkers';
import AnnotationsStyleProperties from '../enums/AnnotationsStyleProperties';

import work from 'webworkify';
import { hexToRgb } from './Util';
import { project, unproject } from './Bezier';
import AnnotationsWorker from '../workers/AnnotationsWorker';

class Annotations {
    constructor(dragonflyDrawInstance, dragonflyMapInstance, mode, bus) {
        this._dragonflyMapInstance = dragonflyMapInstance;
        this._dragonflyDrawInstance = dragonflyDrawInstance;
        this._mode = mode;
        this._bus = bus;
        this.w = work(AnnotationsWorker);
        this.w.addEventListener('message', ev => {
            const callback = () => {
                if (!this.dragonflyMapInstance.loaded()) setTimeout(callback.bind(this), 10);
                else {
                    let options;
                    let selected = false;
                    switch (ev.data.type) {
                    case 'batchJobResult':
                        this.dragonflyDrawInstance.addMultipleFeatures(ev.data.data);
                        this.bus.emit('MULTIPLE_ANNOTATIONS_DRAWING_FINISHED');
                        break;
                    default:
                        options = ev.data.data.opts;
                        if (options && options.selected) {
                            selected = options.selected;
                            delete ev.data.data.opts;
                        }
                        this.dragonflyDrawInstance[ev.data.type](ev.data.data);
                        if (selected) {
                            this.dragonflyDrawInstance.changeMode('direct_select', { featureId: ev.data.data.id });
                        }
                        this.bus.emit(`ANNOTATION_DRAWING_FINISHED_${ev.data.data.id}`);
                        this.bus.emit('ANNOTATION_DRAWING_FINISHED');
                        break;
                    }
                }
            };
            callback();
        });
    }

    get bus() {
        return this._bus;
    }

    get currentProject() {
        return this._currentProject;
    }

    get mode() {
        return this._mode;
    }

    get bezier() {
        return this._bezier;
    }

    get dragonflyDrawInstance() {
        return this._dragonflyDrawInstance;
    }

    get dragonflyMapInstance() {
        return this._dragonflyMapInstance;
    }

    getAnnotationJSON(annotation, opts) {
        let clonedAnnotation;
        let annotationJSON;
        let coordinates;
        let assetsGuid = '';
        const callback = e => {
            assetsGuid = e.project.assetsGuid;
        };
        switch (annotation.type) {
        case 'Polyline':
        case 'Marker':
        case 'Label':
            return annotation.toJSON();
        case 'Polygon':
            clonedAnnotation = annotation.clone();
            if (clonedAnnotation.coordinates[0] !== clonedAnnotation.coordinates[clonedAnnotation.coordinates.length - 1]) {
                coordinates = clonedAnnotation.coordinates.slice(0);
                coordinates.push(coordinates[0]);
                clonedAnnotation.coordinates = coordinates.reverse();
            }
            return clonedAnnotation.toJSON();
        case 'FlowArrow':
            annotationJSON = annotation.toJSON();
            annotationJSON.opts = opts;
            return annotationJSON;
        case 'Freehand':
            annotationJSON = annotation.toJSON();
            annotationJSON.opts = opts;
            return annotationJSON;
        case 'Shape':
            clonedAnnotation = annotation.clone();
            if (clonedAnnotation.coordinates[0] !== clonedAnnotation.coordinates[clonedAnnotation.coordinates.length - 1]) {
                coordinates = clonedAnnotation.coordinates.slice(0);
                coordinates.push(coordinates[0]);
                clonedAnnotation.coordinates = coordinates.reverse();
            }
            annotationJSON = clonedAnnotation.toJSON();
            annotationJSON.opts = opts;
            return annotationJSON;
        case 'Image':
            annotationJSON = annotation.toJSON();
            this._bus.once('CURRENT_PROJECT', callback);
            this._bus.emit('CURRENT_PROJECT_REQUEST', { source: this });
            if (annotation.fileName && annotation.fileName !== '' && assetsGuid && assetsGuid !== '') {
                annotationJSON.fileUrl = `${AppConfig.constants.links.cdnRoot}/projects/${assetsGuid}/${annotation.fileName}`;
            } else {
                annotationJSON.fileUrl = `${AppConfig.constants.assetsBaseURL}/icons/no_image.png`;
            }
            annotationJSON.opts = opts;
            return annotationJSON;
        case 'Hotspot':
            annotationJSON = annotation.toJSON();
            // Add all 8 control points
            coordinates = [
                annotation.topLeftPoint,
                [(annotation.topLeftPoint[0] + annotation.bottomRightPoint[0]) / 2, annotation.topLeftPoint[1]],
                [annotation.bottomRightPoint[0], annotation.topLeftPoint[1]],
                [annotation.bottomRightPoint[0], (annotation.topLeftPoint[1] + annotation.bottomRightPoint[1]) / 2],
                annotation.bottomRightPoint,
                [(annotation.topLeftPoint[0] + annotation.bottomRightPoint[0]) / 2, annotation.bottomRightPoint[1]],
                [annotation.topLeftPoint[0], annotation.bottomRightPoint[1]],
                [annotation.topLeftPoint[0], (annotation.topLeftPoint[1] + annotation.bottomRightPoint[1]) / 2],
                annotation.topLeftPoint,
            ];
            annotationJSON.coordinates = coordinates;
            annotationJSON.opts = opts;
            return annotationJSON;
        }
        return null;
    }

    destroyWorker() {
        this.w.terminate();
    }

    drawMultipleAnnotations(annotations) {
        this.bus.emit('MULTIPLE_ANNOTATIONS_DRAWING_STARTED');
        if (annotations.length > 0) this.w.postMessage({ type: 'batchJob', data: [annotations.map(annotation => this.getAnnotationJSON(annotation)), this.mode] });
    }

    drawSingleAnnotation(annotation, opts = {}) {
        if (!opts.silent) {
            this.bus.emit('ANNOTATIONS_DRAWING_STARTED');
        }
        this.w.postMessage([this.getAnnotationJSON(annotation, opts), this.mode, 'addSingleFeature']);
    }

    updateAnnotationStyle(propsToUpdate, opts) {
        const drawStyle = {
            id: propsToUpdate.id,
            type: propsToUpdate.type,
            paint: {},
            layout: {},
        };
        switch (propsToUpdate.type) {
        case 'Polyline':
        case 'Polygon':
        case 'Shape':
        case 'Freehand':
        case 'FlowArrow':
            if (propsToUpdate.toWidth !== undefined && propsToUpdate.fromWidth !== undefined && propsToUpdate.tipWidth !== undefined) this.updateCoordinates(propsToUpdate.annotation, opts);
            if (propsToUpdate.fillColor !== undefined) drawStyle.paint['fill-color'] = propsToUpdate.fillColor;
            if (propsToUpdate.strokeColor !== undefined) drawStyle.paint['line-color'] = propsToUpdate.strokeColor;
            if (propsToUpdate.strokeWeight !== undefined) drawStyle.paint['line-width'] = StrokeWeight[propsToUpdate.strokeWeight.toUpperCase()].value;
            if (propsToUpdate.fillOpacity !== undefined) drawStyle.paint['fill-opacity'] = propsToUpdate.fillOpacity;
            if (propsToUpdate.useFill !== undefined) {
                drawStyle.paint['fill-opacity'] = propsToUpdate.useFill ? propsToUpdate.opacity : 0;
            }
            if (propsToUpdate.opacity !== undefined) {
                drawStyle.paint['line-opacity'] = propsToUpdate.opacity;
                drawStyle.paint['fill-opacity'] = propsToUpdate.useFill ? propsToUpdate.opacity : 0;
                drawStyle.paint['circle-opacity'] = propsToUpdate.opacity;
            }
            break;
        case 'Marker':
            if (propsToUpdate.labelVisible !== undefined) drawStyle.paint['text-opacity'] = propsToUpdate.labelVisible ? propsToUpdate.annotation.opacity : 0;
            if (propsToUpdate.title !== undefined) drawStyle.layout['text-field'] = propsToUpdate.title;
            if (propsToUpdate.opacity !== undefined) {
                drawStyle.paint['icon-opacity'] = propsToUpdate.opacity;
                if (propsToUpdate.annotation.labelVisible) drawStyle.paint['text-opacity'] = propsToUpdate.opacity;
            }
            if (propsToUpdate.textColor !== undefined) drawStyle.paint['text-color'] = propsToUpdate.textColor;

            if (propsToUpdate.labelPosition !== undefined ||
                propsToUpdate.size !== undefined ||
                propsToUpdate.textSize !== undefined ||
                propsToUpdate.markerPathId !== undefined ||
                propsToUpdate.fillColor !== undefined) {
                const currentMarkerSize = MarkerSize[propsToUpdate.annotation.size.toUpperCase()].value;
                const currentLabelSize = LabelSize[propsToUpdate.annotation.textSize.toUpperCase()].value;
                if (propsToUpdate.size !== undefined) drawStyle.layout['icon-size'] = currentMarkerSize;
                if (propsToUpdate.textSize !== undefined) drawStyle.layout['text-size'] = currentLabelSize;
                const annotationMarker = AnnotationsMarkers.find(am => am.id === propsToUpdate.annotation.markerPathId);
                if (propsToUpdate.markerPathId !== undefined || propsToUpdate.fillColor !== undefined) {
                    drawStyle.layout['icon-image'] = `${annotationMarker.fileName}_${propsToUpdate.annotation.fillColor}`;
                    drawStyle.layout['icon-offset'] = annotationMarker.offset ? [0, -MarkerSize.MEDIUM.value * basePngSize] : [0, 0];
                }
                let textOffset = 0;
                let additionalVerticalTextOffset = 0;

                if (!annotationMarker.offset) {
                    textOffset = (((currentMarkerSize * basePngSize) / 2) / currentLabelSize) * 1.1;
                }
                switch (propsToUpdate.annotation.labelPosition) {
                case 'bottom':
                    if (annotationMarker.offset) {
                        textOffset = 0;
                    }
                    drawStyle.layout['text-offset'] = [0, textOffset];
                    drawStyle.layout['text-anchor'] = 'top';
                    break;
                case 'top':
                    if (annotationMarker.offset) {
                        textOffset += textOffset + ((currentMarkerSize * basePngSize) / currentLabelSize);
                    }
                    drawStyle.layout['text-offset'] = [0, -textOffset];
                    drawStyle.layout['text-anchor'] = 'bottom';
                    break;
                case 'left':
                    if (annotationMarker.offset) {
                        textOffset = ((currentMarkerSize * basePngSize) / 2) / currentLabelSize;
                        additionalVerticalTextOffset = -((currentMarkerSize * basePngSize) / currentLabelSize) / 2;
                    }
                    drawStyle.layout['text-offset'] = [-textOffset, additionalVerticalTextOffset];
                    drawStyle.layout['text-anchor'] = 'right';
                    break;
                case 'right':
                    if (annotationMarker.offset) {
                        textOffset = ((currentMarkerSize * basePngSize) / 2) / currentLabelSize;
                        additionalVerticalTextOffset = -((currentMarkerSize * basePngSize) / currentLabelSize) / 2;
                    }
                    drawStyle.layout['text-offset'] = [textOffset, additionalVerticalTextOffset];
                    drawStyle.layout['text-anchor'] = 'left';
                    break;
                }
            }
            break;
        case 'Label':
            if (propsToUpdate.textColor !== undefined) drawStyle.paint['text-color'] = propsToUpdate.textColor;
            if (propsToUpdate.textSize !== undefined) drawStyle.layout['text-size'] = LabelSize[propsToUpdate.textSize.toUpperCase()].value;
            if (propsToUpdate.opacity !== undefined) drawStyle.paint['text-opacity'] = propsToUpdate.opacity;
            if (propsToUpdate.title !== undefined) drawStyle.layout['text-field'] = propsToUpdate.title;
            if (propsToUpdate.rotationAngle !== undefined) drawStyle.layout['text-rotate'] = propsToUpdate.rotationAngle;
            if (propsToUpdate.fillColor !== undefined) drawStyle.paint['text-halo-color'] = propsToUpdate.fillColor;
            if (propsToUpdate.haloWidth !== undefined) drawStyle.paint['text-halo-width'] = propsToUpdate.haloWidth;
            if (propsToUpdate.haloBlur !== undefined) drawStyle.paint['text-halo-blur'] = propsToUpdate.haloBlur;
            if (propsToUpdate.labelVisible !== undefined) drawStyle.paint['text-opacity'] = propsToUpdate.labelVisible ? propsToUpdate.annotation.opacity : 0;
            if (propsToUpdate.fillOpacity !== undefined) {
                const rgbColors = hexToRgb(propsToUpdate.fillColor);
                drawStyle.paint['text-halo-color'] = `rgba(${rgbColors.r}, ${rgbColors.g}, ${rgbColors.b}, ${propsToUpdate.fillOpacity})`;
            }
            if (propsToUpdate.useFill !== undefined) {
                drawStyle.paint['text-halo-width'] = propsToUpdate.useFill ? propsToUpdate.haloWidth : undefined;
                drawStyle.paint['text-halo-blur'] = propsToUpdate.useFill ? propsToUpdate.haloBlur : undefined;
            }
            break;
        case 'Image':
            if (propsToUpdate.opacity !== undefined) drawStyle.paint['raster-opacity'] = propsToUpdate.opacity;
            if (propsToUpdate.fileName !== undefined) {
                let assetsGuid = '';
                const callback = e => {
                    assetsGuid = e.project.assetsGuid;
                };
                this._bus.once('CURRENT_PROJECT', callback);
                this._bus.emit('CURRENT_PROJECT_REQUEST', { source: this });
                if (assetsGuid !== '') {
                    drawStyle.url = `${AppConfig.constants.links.cdnRoot}/projects/${assetsGuid}/${propsToUpdate.fileName}`;
                } else {
                    drawStyle.url = `${AppConfig.constants.assetsBaseURL}/icons/no_image.png`;
                }
                Annotations.calculateImageCoordinates(drawStyle.url, propsToUpdate.annotation).then(coordinates => {
                    drawStyle.coordinates = coordinates;
                    this.dragonflyDrawInstance.updateFeatureStyle(drawStyle);
                });
                return;
            }
            if (propsToUpdate.rotationAngle !== undefined) {
                const worldSize = 512 * (2 ** Math.floor(this.dragonflyMapInstance.getZoom()));
                const projectedTopLeft = project({
                    lng: propsToUpdate.topLeftPoint[0],
                    lat: propsToUpdate.topLeftPoint[1],
                }, worldSize);
                const projectedBottomRight = project({
                    lng: propsToUpdate.bottomRightPoint[0],
                    lat: propsToUpdate.bottomRightPoint[1],
                }, worldSize);
                const projectedTopRight = {
                    x: projectedBottomRight.x,
                    y: projectedTopLeft.y,
                };
                const projectedBottomLeft = {
                    x: projectedTopLeft.x,
                    y: projectedBottomRight.y,
                };
                const center = {
                    x: (projectedTopLeft.x + projectedTopRight.x) / 2,
                    y: (projectedTopLeft.y + projectedBottomLeft.y) / 2,
                };

                const s = Math.sin((propsToUpdate.rotationAngle / 180) * Math.PI);
                const c = Math.cos((propsToUpdate.rotationAngle / 180) * Math.PI);

                // Center the coordinates
                projectedTopLeft.x -= center.x;
                projectedTopLeft.y -= center.y;

                projectedBottomRight.x -= center.x;
                projectedBottomRight.y -= center.y;

                projectedTopRight.x -= center.x;
                projectedTopRight.y -= center.y;

                projectedBottomLeft.x -= center.x;
                projectedBottomLeft.y -= center.y;

                drawStyle.coordinates = [
                    unproject({
                        x: ((projectedTopLeft.x * c) - (projectedTopLeft.y * s)) + center.x,
                        y: ((projectedTopLeft.x * s) + (projectedTopLeft.y * c)) + center.y,
                    }, worldSize),
                    unproject({
                        x: ((projectedTopRight.x * c) - (projectedTopRight.y * s)) + center.x,
                        y: ((projectedTopRight.x * s) + (projectedTopRight.y * c)) + center.y,
                    }, worldSize),
                    unproject({
                        x: ((projectedBottomRight.x * c) - (projectedBottomRight.y * s)) + center.x,
                        y: ((projectedBottomRight.x * s) + (projectedBottomRight.y * c)) + center.y,
                    }, worldSize),
                    unproject({
                        x: ((projectedBottomLeft.x * c) - (projectedBottomLeft.y * s)) + center.x,
                        y: ((projectedBottomLeft.x * s) + (projectedBottomLeft.y * c)) + center.y,
                    }, worldSize),
                ];
            }
            break;
        case 'Hotspot':
            if (propsToUpdate.strokeColor !== undefined) drawStyle.paint['line-color'] = propsToUpdate.strokeColor;
            if (propsToUpdate.strokeWeight !== undefined) drawStyle.paint['line-width'] = StrokeWeight[propsToUpdate.strokeWeight.toUpperCase()].value;
            if (propsToUpdate.opacity !== undefined) {
                drawStyle.paint['line-opacity'] = propsToUpdate.opacity;
                drawStyle.paint['circle-opacity'] = propsToUpdate.opacity;
            }

        }
        this.dragonflyDrawInstance.updateFeatureStyle(drawStyle);
    }

    // Update the image but preserve the user defined width.
    static calculateImageCoordinates(url, annotation) {
        return new Promise(resolve => {
            const img = new Image();
            img.onload = () => {
                const worldSize = 512 * (2 ** annotation.createdAtZoomLevel);
                const projectedTopLeft = project({
                    lng: annotation.topLeftPoint[0],
                    lat: annotation.topLeftPoint[1],
                }, worldSize);
                const projectedBottomRight = project({
                    lng: annotation.bottomRightPoint[0],
                    lat: annotation.bottomRightPoint[1],
                }, worldSize);
                const desiredWidth = projectedBottomRight.x - projectedTopLeft.x;
                const middle = ((projectedBottomRight.y - projectedTopLeft.y) / 2) + projectedTopLeft.y;
                const height = img.height;
                const width = img.width;
                const aspectRatio = width / height;
                const calculatedHeight = desiredWidth / aspectRatio;

                const projectedTopRight = {
                    x: projectedBottomRight.x,
                    y: middle - (calculatedHeight / 2),
                };
                const projectedBottomLeft = {
                    x: projectedTopLeft.x,
                    y: middle + (calculatedHeight / 2),
                };
                projectedTopLeft.y = middle - (calculatedHeight / 2);

                projectedBottomRight.y = middle + (calculatedHeight / 2);

                annotation.topLeftPoint = unproject(projectedTopLeft, worldSize);
                annotation.bottomRightPoint = unproject(projectedBottomRight, worldSize);
                resolve([
                    unproject(projectedTopLeft, worldSize),
                    unproject(projectedTopRight, worldSize),
                    unproject(projectedBottomRight, worldSize),
                    unproject(projectedBottomLeft, worldSize),
                ]);
            };
            img.src = url;
        });
    }

    getGeoJSONDefinition() {
        const annotations = this.dragonflyDrawInstance.getAll();

        // Iterate over every feature (annotation) and transform style properties
        annotations.features.forEach(feature => {
            Object.entries(feature.properties.style).forEach(attribute => {
                const key = AnnotationsStyleProperties[attribute[0]];

                // Add only keys that are present in style map
                if (!key) return;

                // Necessary for marker annotation to separate marker icon and color
                if (key === AnnotationsStyleProperties['layout_icon-image']) {
                    const args = attribute[1].split('_');
                    const icon = args[0];
                    const color = args[1];

                    feature.properties['marker-symbol'] = icon;
                    feature.properties['marker-color'] = color;
                } else {
                    feature.properties[key] = attribute[1];
                }
            });
        });

        return annotations;
    }

    updateCoordinates(e, opts) {
        this.drawSingleAnnotation(e, opts);
    }

    setInitialFeatureStyle(feature) {
        this.bus.emit('ANNOTATIONS_DRAWING_STARTED');
        this.w.postMessage([feature.toJSON(), this.mode, 'setInitialFeatureStyle']);
    }
}

export default Annotations;
