import React from 'react';
import BusComponent from './BusComponent';
import classNames from 'classnames';
import TextInput from './form/TextInput';
import { sendFeedbackEvents } from '../enums/GoogleTagManagerEvents';
import googleTagManagerEvents from '../helpers/GoogleTagManagerEventsWrapper';

const EMAIL_VALIDATION_REGEX = // eslint-disable-next-line
/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const SendFeedbackButton = googleTagManagerEvents('button', sendFeedbackEvents);

class FeedbackPopup extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            currentUser: undefined,
            canSubmit: false,
            isSending: false,
            email: undefined,
            feedbackText: undefined,
        };
    }

    componentDidMount() {
        this.bindGluBusEvents({
            USER_INFO_GET_SUCCESS: this.onUserInfo,
            SEND_FEEDBACK_SUCCESS: this.onFeedbackSendSuccess,
        });
        this.emit('USER_INFO_GET_REQUEST', { source: this });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    onUserInfo(userInfo, source) {
        if (source !== this) return;
        this.setState({
            currentUser: userInfo,
        });
    }

    onFeedbackSendSuccess() {
        this.setState({
            isSending: false,
        });
    }

    onEmailChange = email => {
        this.setState({
            email,
        });
        this.checkFields();
    }

    onFeedbackMessageChange = feedbackText => {
        this.setState({
            feedbackText,
        });
        this.checkFields();
    }

    onSendClick = () => {
        const { currentUser } = this.state;
        const payload = {
            feedbackText: this.comment.value,
            feedbackEmail: currentUser && currentUser.userId !== -1 ? currentUser.email : this.email.value,
        };

        this.setState({
            isSending: true,
        });
        this.emit('SEND_FEEDBACK_REQUEST', payload);
    };

    onClosePopup = () => {
        this.emit('CLOSE_MODAL');
    }

    render() {
        const loaderClasses = classNames('flex-it justify-center center', { hidden: !this.state.isSending });
        const emailClasses = classNames('email', { hidden: this.state.currentUser && this.state.currentUser.userId !== -1 });
        const sendButtonClasses = classNames('btn-flat', { 'btn-flat--disabled': !this.state.canSubmit });
        return (<div className="flex-one flex-it column space-between">
            <div className="font-12">
                <p className="body-1">{'Help us improve the experience for you and the rest of our users. While we can not respond to every request, we take your feedback seriously and will use it to continuously make our service better.'}</p>
            </div>
            <TextInput
                value={this.state.email}
                oldStyle
                elementRef={email => { this.email = email; }}
                onChange={this.onEmailChange}
                className={emailClasses}
                placeholder="Email"
            />
            <TextInput
                multiline
                value={this.state.feedbackText}
                elementRef={comment => { this.comment = comment; }}
                onChange={this.onFeedbackMessageChange}
                className="flex-one flex-it start justify-center lg"
                placeholder="Comments & Suggestions"
            />

            <div className="flex-it space-between center margin-top-10">
                <div className="flex-it justify-center center">
                    <div className={loaderClasses}>
                        <span className="font-12">Sending...</span>
                    </div>
                </div>
                <div className="flex-it flex-end center">
                    <button
                        onClick={this.onClosePopup}
                        className="btn-flat btn-flat--gray"
                    >
                        CANCEL
                    </button>
                    <SendFeedbackButton
                        onClick={this.onSendClick}
                        className={sendButtonClasses}
                        disabled={!this.state.canSubmit}
                    >
                        SEND
                    </SendFeedbackButton>
                </div>
            </div>

        </div>);
    }

    static validateEmail(email) {
        return EMAIL_VALIDATION_REGEX.test(email);
    }

    checkFields = () => {
        const email = this.state.currentUser && this.state.currentUser.userId !== -1 ? 'no@email.com' : this.email.value;
        const comment = this.comment.value;
        const canSubmit = email !== undefined && comment !== undefined && email !== '' && comment !== '' && FeedbackPopup.validateEmail(email);
        this.setState({
            canSubmit,
        });
    }
}


export default FeedbackPopup;

