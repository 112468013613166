import BaseController from './BaseController';
import ApplicationModeDataSource from '../dataSources/ApplicationModeDataSource';
import ProjectDataSource from '../dataSources/ProjectDataSource';

class ApplicationModeController extends BaseController {
  static get name() {
    return 'ApplicationModeController';
  }

  static getInstance(options) {
    return new ApplicationModeController(options);
  }

  onActivate() {
    this.bindGluBusEvents({
      APPLICATION_MODE_UPDATE_REQUEST: this.onApplicationModeUpdateRequest,
    });
    this.applicationModeDataSource = this.activateSource(ApplicationModeDataSource);
    this.projectDataSource = this.activateSource(ProjectDataSource);
  }

  onApplicationModeUpdateRequest(applicationModeUpdateRequest) {
    this.applicationModeDataSource.updateApplicationMode(
      applicationModeUpdateRequest.applicationMode,
    );
    const applicationMode = this.applicationModeDataSource.currentApplicationMode;
    this.bus.emit(
      'APPLICATION_MODE_UPDATE_SUCCESS',
      applicationMode,
      applicationModeUpdateRequest.source,
    );
  }

  onDeactivate() {
    this.unbindGluBusEvents();
  }
}

export default ApplicationModeController;
