import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import BusComponent from '../BusComponent';

import MapLayer from './MapLayer';
import MapSatellite from './MapSatellite';

import LayerLibraryDataLayers from '../layerLibrary/card/LayerLibraryDataLayers';

class MapLayers extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            baseMapLayersInfo: [],
            baseMapLayersVisibility: {},
            mapReady: false,
        };
        this.bindGluBusEvents({
            MAP_ZOOM_END: this.updateBaseMapLayersVisiblity,
            MAP_SATELLITE_LAYER_VISIBILITY_CHANGED: this.updateBaseMapLayersVisiblity,
            USER_INFO_GET_SUCCESS: this.onUserInfo,
            MAP_VIEWER: this.onMapReady,
            MAP_LIBRARY_LAYERS_UPDATED: this.onLibraryLayersUpdated,
        });
    }

    componentDidMount() {
        this.emit('USER_INFO_GET_REQUEST', { source: this });
        this.emit('MAP_GET_MAP_VIEWER');
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    onUserInfo(userInfo, source) {
        if (source && this !== source) return;
        const isUserLoggedIn = userInfo && userInfo.userId !== -1;
        this.setState({
            isUserLoggedIn,
        });
    }

    onMapReady(eventMap) {
        const baseMapLayersInfo = this._collectBaseMapLayersInfo(eventMap.source.currentBaseMap);
        this.setState({
            baseMapLayersInfo,
            baseMapLayersVisibility: this._collectBaseMapLayersVisibility(baseMapLayersInfo, eventMap.source),
            mapReady: true,
        });
    }

    onLibraryLayersUpdated() {
        this.forceUpdate();
    }

    updateBaseMapLayersVisiblity(eventMap) {
        this.setState({
            baseMapLayersVisibility: this._collectBaseMapLayersVisibility(this.state.baseMapLayersInfo, eventMap.source),
        });
    }

    onToggleUserDataLayerVisibility = (visible, layerId) => {
        this.emit('UPDATE_USER_LAYER_VISIBILITY', {
            layerId,
            visible,
            mapInstanceId: this.props.mapInstance.id,
        });
    }

    onToggleBaseMapLayerVisibility = (visible, layerId) => {
        this.emit('UPDATE_MAP_LAYER_VISIBILITY_REQUEST', {
            mapInstanceId: this.props.mapInstance.id,
            layerId,
            visible,
        });
    }

    onUserDataLayerClick = userDataLayerId => {
        if (this.props.onEditUserLayer) {
            this.props.onEditUserLayer(userDataLayerId);
        }
    }

    renderUserDataLayers() {
        if (this.state.isUserLoggedIn && this.props.mapInstance.userDataLayers && this.props.mapInstance.userDataLayers.length) {
            const layers = this.props.mapInstance.userDataLayers.map(layer => <MapLayer
                key={layer.id}
                id={layer.id}
                visible={layer.visible}
                title={layer.title}
                onEditClick={this.onUserDataLayerClick}
                onToggleVisibility={this.onToggleUserDataLayerVisibility}
            />);
            return (<div className="map-layers__section">
                <div className="map-layers__section-title">
                    {this.props.intl.formatMessage({ id: 'dataBrowser.personalLayers' })}
                </div>
                {layers}
            </div>);
        }

        return null;
    }

    renderLibraryDataLayers() {
        if (this.state.isUserLoggedIn && this.props.mapInstance.libraryDataLayers && this.props.mapInstance.libraryDataLayers.length) {
            return (<LayerLibraryDataLayers
                mapInstance={this.props.mapInstance}
            />);
        }

        return null;
    }

    renderBaseMapLayers() {
        const layers = [];
        this.state.baseMapLayersInfo.forEach(info => {
            const visibilityInfo = this.state.baseMapLayersVisibility[info.id];
            if (info.isSatellite) {
                layers.unshift(<MapSatellite
                    key={info.id}
                    title={info.title}
                    visible={visibilityInfo.visible}
                    mapInstance={this.props.mapInstance}
                />);
            } else {
                layers.push(<MapLayer
                    id={info.id}
                    key={info.id}
                    title={info.title}
                    visible={visibilityInfo.visible}
                    rendered={visibilityInfo.rendered}
                    onToggleVisibility={this.onToggleBaseMapLayerVisibility}
                />);
            }
        });

        return (<div className="map-layers__section">
            <div className="map-layers__section-title">
                {this.props.intl.formatMessage({ id: 'dataBrowser.basemapLayers' })}
            </div>
            {layers}
        </div>);
    }

    render() {
        if (!this.state.mapReady) {
            return <div><FormattedMessage id="loadingPleaseWait" /></div>;
        }
        return (<div className="map-layers">
            {this.renderUserDataLayers()}
            {this.renderLibraryDataLayers()}
            {this.renderBaseMapLayers()}
        </div>);
    }

    _collectBaseMapLayersInfo(baseMap) {
        // collect grouped base map layers
        // ex: multiple streets layers will be shown as single base map layer
        const baseMapLayersInfo = [];
        baseMap.layers.forEach(layer => {
            if (layer.type === 'background' || layer.ref) return;
            const id = layer.metadata.socialexplorer['layer-group-id'] || layer.id;
            if (baseMapLayersInfo.find(info => info.id === id)) {
                return;
            }
            baseMapLayersInfo.push({
                id,
                title: layer.metadata.socialexplorer.title || id,
                isSatellite: baseMap.satelliteLayer.id === id,
                isData: baseMap.dataPlaceholder.id === id,
            });
        });
        return baseMapLayersInfo;
    }

    _collectBaseMapLayersVisibility(baseMapLayersInfo, mapViewer) {
        const baseMapLayersVisibility = {};
        const zoom = mapViewer.dragonflyMap.getZoom();
        baseMapLayersInfo.forEach(baseMapLayerInfo => {
            const id = baseMapLayerInfo.id;
            let visible = false, rendered = false;

            const dlLayers = mapViewer.dragonflyMapLayers.filter(dl => (
                dl.id === id || (dl.metadata && dl.metadata.socialexplorer['layer-group-id'] === id)
            ));

            for (let i = 0; i < dlLayers.length; i += 1) {
                const dlLayer = dlLayers[i];
                visible = dlLayer.layout.visibility === 'visible';
                let renderedOnZoom = zoom >= dlLayer.minzoom && zoom <= dlLayer.maxzoom;
                // data is not visible if there is no active summary level
                if (baseMapLayerInfo.isData) {
                    renderedOnZoom = mapViewer.activeSummaryLevel !== undefined;
                }
                if (visible && renderedOnZoom) {
                    rendered = true;
                    break;
                }
            }
            baseMapLayersVisibility[id] = {
                visible,
                rendered,
            };
        });
        return baseMapLayersVisibility;
    }
}

export default injectIntl(MapLayers);
