import React from 'react';
import PropTypes from 'prop-types';
import BubbleNegativePositive from './BubbleNegativePositive';
import ShadedColorPaletteHorizontal from '../shadedAreaLegend/ShadedColorPaletteHorizontal';
import VariableValueType from '../../../enums/VariableValueType';

const BubbleColorPalette = props => {
    const { mapInstanceId, renderer, bubbleValueType } = props;
    const { fieldList, nullDataRuleIndex, insufficientDataRuleIndex, rules } = renderer;

    const legendHighlighted = rules.some(rule => rule.highlighted);

    if (bubbleValueType === VariableValueType.NUMBER) {
        return (<BubbleNegativePositive
            rules={rules}
        />);
    }

    return (<ShadedColorPaletteHorizontal
        className="shaded-color-palette-horizontal--no-margin"
        mapInstanceId={mapInstanceId}
        fields={fieldList.fields}
        nullDataRuleIndex={nullDataRuleIndex}
        insufficientDataRuleIndex={insufficientDataRuleIndex}
        rules={rules}
        legendHighlighted={legendHighlighted}
        showLabels
    />);
};


BubbleColorPalette.propTypes = {
    layout: PropTypes.string.isRequired,
    renderer: PropTypes.object.isRequired,
    mapInstanceId: PropTypes.string.isRequired,
    bubbleValueType: PropTypes.string.isRequired,
    variableGuid: PropTypes.string,
};

BubbleColorPalette.defaultProps = {
    variableGuid: undefined,
};

export default BubbleColorPalette;
