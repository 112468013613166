import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import CopyFromColorPaletteRow from './CopyFromColorPaletteRow';
import { focusFirstChild } from '../../helpers/Util';

class CopyFromColorPaletteList extends React.Component {
    componentDidMount() {
        focusFirstChild(this.paletteListContainer);
    }

    render() {
        const {
            colorPalettesCollection,
            onColorPaletteClick,
            showGroupName,
            intl,
        } = this.props;
        const colorPaletteList = colorPalettesCollection.map(paletteGroup => {
            const colorPaletteGroup = paletteGroup.list.map(palette => (<CopyFromColorPaletteRow
                key={`${palette.id}_${palette.type}`}
                palette={palette}
                onColorPaletteClick={onColorPaletteClick}
            />));
            return (
                <div key={paletteGroup.name} className="color-palettes-list__group">
                    {showGroupName && <div className="menu-subheader color-palettes-list__group-title">
                        {intl.formatMessage({ id: paletteGroup.name })}
                    </div>}
                    {colorPaletteGroup}
                </div>
            );
        });
        return (<div className="color-palettes-list" ref={ref => { this.paletteListContainer = ref; }}>
            {colorPaletteList}
        </div>);
    }
}

CopyFromColorPaletteList.propTypes = {
    colorPalettesCollection: PropTypes.array.isRequired,
    onColorPaletteClick: PropTypes.func.isRequired,
    showGroupName: PropTypes.bool,
    intl: PropTypes.object.isRequired,
};

CopyFromColorPaletteList.defaultProps = {
    showGroupName: false,
};

export default injectIntl(CopyFromColorPaletteList);
