'use strict';var Coalesce = function Coalesce(type, args) {
    this.type = type;
    this.args = args;
};
Coalesce.parse = function parse(args, context) {
    if (args.length < 2) {
        return context.error('Expectected at least one argument.');
    }
    var outputType = null;
    if (context.expectedType && context.expectedType.kind !== 'value') {
        outputType = context.expectedType;
    }
    var parsedArgs = [];
    for (var i = 0, list = args.slice(1); i < list.length; i += 1) {
        var arg = list[i];
        var parsed = context.parse(arg, 1 + parsedArgs.length, outputType);
        if (!parsed) {
            return null;
        }
        outputType = outputType || parsed.type;
        parsedArgs.push(parsed);
    }
    return new Coalesce(outputType, parsedArgs);
};
Coalesce.prototype.evaluate = function evaluate(ctx) {
    var this$1 = this;
    var result = null;
    for (var i = 0, list = this$1.args; i < list.length; i += 1) {
        var arg = list[i];
        result = arg.evaluate(ctx);
        if (result !== null) {
            break;
        }
    }
    return result;
};
Coalesce.prototype.eachChild = function eachChild(fn) {
    this.args.forEach(fn);
};
module.exports = Coalesce;