class DataGeoFilterFeature {
    constructor(values) {
        if (values) {
            Object.keys(values).forEach(k => {
                if (this.constructor.prototype.hasOwnProperty(k)) {
                    this[k] = values[k];
                }
            });
        }
    }

    get columnValue() {
        return this._columnValue;
    }

    set columnValue(columnValue) {
        this._columnValue = columnValue;
    }

    get geoQName() {
        return this._geoQName;
    }

    set geoQName(geoQName) {
        this._geoQName = geoQName;
    }

    equals(that) {
        return this.columnValue === that.columnValue && this.geoQName === that.geoQName;
    }

    clone() {
        return new DataGeoFilterFeature({
            columnValue: this.columnValue,
            geoQName: this.geoQName,
        });
    }

    toJSON() {
        return {
            columnValue: this.columnValue,
            geoQName: this.geoQName,
        };
    }
}

export default DataGeoFilterFeature;
