// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import BusComponent from '../BusComponent';
import ApplicationMode from '../../enums/ApplicationMode';
import ErrorMessage from './ErrorMessage';
import LocationAnalysisConstructionSection from './LocationAnalysisConstructionSection';

import CorrespondingGeographiesSelector from './CorrespondingGeographiesSelector';
import AppConfig from '../../appConfig';

/**
 * @typedef Props
 * @property {import('../../objects/MapInstance').default} mapInstance
 * @property {string} note
 *
 * @typedef State
 * @property {boolean} hideReportOptions
 * @property {import('../../objects/MetaSurvey').default} selectedSurvey
 * @property {boolean} downloadReport
 * To create a report using Table Template, we need to fetch table guid of each table
 * specified in that template.
 * One table (for example `Total Population`) has different guids accross different survey years,
 * and therefore we need to fetch datasets (which contain tables with their corresponding guids)
 * for each survey year to obtain proper table guids.
 * `compareSurveysList` state will hold the list of year for which we have already fetched datasets.
 * @property {string[]} compareSurveysList
 * @property {boolean} surveyAvailableForReporting
 * @property {{
 *   selectedReportTopic: import('../../types').ReportTopic,
 *   selectedSurveys: import('../../objects/MetaSurvey').default[],
 *   selectedFeaturedReportSurveyCode?: string,
 *   defaultAggregationType: number
 *   selectedTableGuids: string[],
 *   mapInstanceId: string,
 * }} reportDefinition
 *
 * @extends {BusComponent<Props, State>}
 */

class LocationAnalysisCreateReport extends BusComponent {
    constructor(props, context) {
        super(props, context);

        /** @type {State} */
        this.state = {
            hideReportOptions: true,
            selectedSurvey: undefined,
            downloadReport: false,
            compareSurveysList: [],
            surveyAvailableForReporting: true,
            reportDefinition: undefined,
        };
    }

    componentDidMount() {
        this.bindGluBusEvents({
            CUSTOM_MAP_SELECTION_INITIAL_INFO: this.onCustomMapSelectionInitialInfo,
            UPDATE_CREATE_REPORT_VISIBILITY: this.onChangeVisibilityOptions,
        });
        // Fetch selected survey and initial report topics in order to know if
        // reporting is allowed for the selected survey
        this.emit('CUSTOM_MAP_SELECTION_REPORT_INITIAL_INFO_REQUEST', {
            mapInstance: this.props.mapInstance,
        });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    /**
     * @param {object} param0
     * @param {import('../../objects/MetaSurvey').default} param0.selectedSurvey
     * @param {boolean} param0.surveyAvailableForReporting
     */
    onCustomMapSelectionInitialInfo = ({ selectedSurvey, surveyAvailableForReporting }) => {
        this.setState({
            selectedSurvey,
            surveyAvailableForReporting,
        });

        // Load templates only if survey is available for reporting
        if (surveyAvailableForReporting) {
            this.emit('LOAD_COMPATIBLE_TABLE_TEMPLATES_REQUEST', {
                surveyName: selectedSurvey.name,
            });
        }
    };

    /** @param {string[]} newCompareSurveysList */
    onUpdateCompareSurveyList = newCompareSurveysList => {
        const { compareSurveysList } = this.state;

        // Fetch datasets for single year once only, no need to fetch it multiple times
        newCompareSurveysList
            .filter(surveyName => !compareSurveysList.includes(surveyName))
            .forEach(surveyName => {
                this.emit('LOAD_COMPATIBLE_TABLE_TEMPLATES_REQUEST', {
                    surveyName,
                });
                this.setState({
                    compareSurveysList: [...compareSurveysList, surveyName],
                });
            });
    };

    /**
     * @param {object} param0
     * @param {string} param0.visible
     * @param {object} param0.mapInstanceId
     */
    onChangeVisibilityOptions = ({ visible, mapInstanceId }) => {
        if (mapInstanceId === this.props.mapInstance.id) {
            this.setState({ hideReportOptions: !visible });
        }
    };

    render() {
        const { selectedSurvey, hideReportOptions } = this.state;
        const { note, mapInstance } = this.props;
        const { applicationMode } = this.context;

        if (hideReportOptions || ApplicationMode.isViewMode(applicationMode) || !selectedSurvey) {
            return null;
        }

        return (
            <div>
                {!!AppConfig.constants.correspondingSummaryLevels.length && (
                    <CorrespondingGeographiesSelector mapInstance={mapInstance} />
                )}
                <LocationAnalysisConstructionSection
                    mapInstance={mapInstance}
                    selectedSurvey={selectedSurvey}
                    note={note}
                    onUpdateCompareSurveyList={this.onUpdateCompareSurveyList}
                />
                <ErrorMessage />
            </div>
        );
    }
}

LocationAnalysisCreateReport.propTypes = {
    mapInstance: PropTypes.object.isRequired,
    note: PropTypes.string,
};

LocationAnalysisCreateReport.defaultProps = {
    note: undefined,
};

export default injectIntl(LocationAnalysisCreateReport);
