class UserMapView {
    constructor(values) {
        if (values) {
            Object.keys(values).forEach(k => {
                if (this.constructor.prototype.hasOwnProperty(k)) {
                    this[k] = values[k];
                }
            });
        }
    }

    get id() {
        return this._id;
    }

    set id(id) {
        this._id = id;
    }

    get title() {
        return this._title;
    }

    set title(title) {
        this._title = title;
    }

    get mapView() {
        return this._mapView;
    }

    set mapView(mapView) {
        this._mapView = mapView;
    }

    equals(that) {
        return (
            this.id === that.id &&
            this.title === that.title &&
            this.mapView === that.mapView
        );
    }

    clone() {
        const userMapView = new UserMapView();
        userMapView.id = this.id;
        userMapView.title = this.title;
        userMapView.mapView = this.mapView.clone();

        return userMapView;
    }

    toJSON() {
        return {
            id: this.id,
            title: this.title,
            mapView: this.mapView.toJSON(),
        };
    }
}

export default UserMapView;
