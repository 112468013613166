import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import BusComponent from '../../BusComponent';
import VisibilityToggle from '../../form/VisibilityToggle';
import AppConfig from '../../../appConfig';

const SCHOOL_RANKING_GROUP_ID = 'school_digger';
const ELEMENTARY_SCHOOL_LAYER_ID = 'Elementary';
const MIDDLE_SCHOOL_LAYER_ID = 'Middle';
const HIGH_SCHOOL_LAYER_ID = 'High';
const ALTERNATIVE_SCHOOL_LAYER_ID = 'Alternative';

class SchoolRankLegend extends BusComponent {
    constructor(props, context) {
        super(props, context);

        const schoolRankGroup = props.mapInstance.libraryDataLayers.find(
            layerGroup => layerGroup.id === SCHOOL_RANKING_GROUP_ID,
        );
        const schoolRankVisible = schoolRankGroup && schoolRankGroup.visible;
        const schoolRankLayersVisibility = {};

        if (schoolRankGroup) {
            schoolRankGroup.layers.forEach(layer => {
                schoolRankLayersVisibility[layer.id] = layer.visible;
            });
        }

        this.state = {
            schoolRankGroup,
            schoolRankVisible,
            schoolRankLayersVisibility,
        };
    }

    componentWillReceiveProps(nextProps) {
        const schoolRankGroup = nextProps.mapInstance.libraryDataLayers.find(
            layerGroup => layerGroup.id === SCHOOL_RANKING_GROUP_ID,
        );
        const schoolRankVisible = schoolRankGroup && schoolRankGroup.visible;
        const schoolRankLayersVisibility = {};

        if (schoolRankGroup) {
            schoolRankGroup.layers.forEach(layer => {
                schoolRankLayersVisibility[layer.id] = layer.visible;
            });
        }

        this.setState({
            schoolRankGroup,
            schoolRankVisible,
            schoolRankLayersVisibility,
        });
    }

    onGroupVisibilityButtonClick = () => {
        this.setState(
            {
                schoolRankVisible: !this.state.schoolRankVisible,
            },
            () => {
                const { schoolRankVisible } = this.state;

                this.emit('UPDATE_LAYER_LIBRARY_GROUP_VISIBILITY', {
                    id: SCHOOL_RANKING_GROUP_ID,
                    visible: schoolRankVisible,
                    mapInstanceId: this.props.mapInstance.id,
                });
            },
        );
    };

    onLayerVisibilityButtonClick = (layerId, visible) => {
        const newSchoolRankLayersVisibility = Object.assign(
            {},
            this.state.schoolRankLayersVisibility,
        );
        newSchoolRankLayersVisibility[layerId] = !visible;

        this.setState(
            {
                schoolRankLayersVisibility: newSchoolRankLayersVisibility,
            },
            () => {
                this.emit('UPDATE_LAYER_LIBRARY_LAYER_VISIBILITY', {
                    id: layerId,
                    groupId: SCHOOL_RANKING_GROUP_ID,
                    visible: newSchoolRankLayersVisibility[layerId],
                    mapInstanceId: this.props.mapInstance.id,
                });
            },
        );
    };

    render() {
        const { schoolRankGroup, schoolRankLayersVisibility } = this.state;

        if (!schoolRankGroup) return null;

        const contentClasses = classnames('flex-it row grow', {
            disabled: !this.state.schoolRankVisible,
        });

        const detailedClasses = classnames('satellite-layer-legend__detailed', {
            hidden: !this.state.schoolRankVisible,
        });

        const primaryLayerVisible =
            schoolRankLayersVisibility[ELEMENTARY_SCHOOL_LAYER_ID];
        const middleLayerVisible =
            schoolRankLayersVisibility[MIDDLE_SCHOOL_LAYER_ID];
        const highLayerVisible =
            schoolRankLayersVisibility[HIGH_SCHOOL_LAYER_ID];
        const alternativeLayerVisible =
            schoolRankLayersVisibility[ALTERNATIVE_SCHOOL_LAYER_ID];

        const primarySchoolLayerClasses = classnames(
            'school-ranking-legend__layer',
            {
                'school-ranking-legend__layer--disabled': !primaryLayerVisible,
            },
        );
        const middleSchoolLayerClasses = classnames(
            'school-ranking-legend__layer',
            {
                'school-ranking-legend__layer--disabled': !middleLayerVisible,
            },
        );
        const highSchoolLayerClasses = classnames(
            'school-ranking-legend__layer',
            {
                'school-ranking-legend__layer--disabled': !highLayerVisible,
            },
        );
        const alternativeSchoolLayerClasses = classnames(
            'school-ranking-legend__layer',
            {
                'school-ranking-legend__layer--disabled': !alternativeLayerVisible,
            },
        );

        const classes = classnames(
            'school-ranking-legend',
            this.props.className,
        );

        return (
            <div className={classes}>
                <div className="flex-it row">
                    <div className={contentClasses}>
                        <div className="flex-it center dark-text">
                            School ranking & types
                        </div>
                    </div>
                    <div className="flex-it">
                        <VisibilityToggle
                            visible={this.state.schoolRankVisible}
                            onClick={this.onGroupVisibilityButtonClick}
                        />
                    </div>
                </div>
                <div className={detailedClasses}>
                    <div className="flex-it column margin-top-12">
                        <div className="school-ranking-legend__gradient" />
                        <div className="flex-it row space-between font-11">
                            <div>Lowest</div>
                            <div>Highest</div>
                        </div>
                    </div>
                    <div className="flex-it column margin-top-18 font-13">
                        <div className="flex-it row space-around">
                            <button
                                className={primarySchoolLayerClasses}
                                onClick={() =>
                                    this.onLayerVisibilityButtonClick(
                                        ELEMENTARY_SCHOOL_LAYER_ID,
                                        primaryLayerVisible,
                                    )
                                }
                            >
                                <img
                                    src={`${AppConfig.constants.assetsBaseURL}/svg/school-pin-P.svg`}
                                    alt="school-pin-primary"
                                />
                                <div className="margin-left-10">Primary</div>
                            </button>
                            <button
                                className={highSchoolLayerClasses}
                                onClick={() =>
                                    this.onLayerVisibilityButtonClick(
                                        HIGH_SCHOOL_LAYER_ID,
                                        highLayerVisible,
                                    )
                                }
                            >
                                <img
                                    src={`${AppConfig.constants.assetsBaseURL}/svg/school-pin-H.svg`}
                                    alt="school-pin-high"
                                />
                                <div className="margin-left-10">High</div>
                            </button>
                        </div>
                        <div className="flex-it row space-around margin-top-10">
                            <button
                                className={middleSchoolLayerClasses}
                                onClick={() =>
                                    this.onLayerVisibilityButtonClick(
                                        MIDDLE_SCHOOL_LAYER_ID,
                                        middleLayerVisible,
                                    )
                                }
                            >
                                <img
                                    src={`${AppConfig.constants.assetsBaseURL}/svg/school-pin-M.svg`}
                                    alt="school-pin-middle"
                                />
                                <div className="margin-left-10">Middle</div>
                            </button>
                            <button
                                className={alternativeSchoolLayerClasses}
                                onClick={() =>
                                    this.onLayerVisibilityButtonClick(
                                        ALTERNATIVE_SCHOOL_LAYER_ID,
                                        alternativeLayerVisible,
                                    )
                                }
                            >
                                <img
                                    src={`${AppConfig.constants.assetsBaseURL}/svg/school-pin-A.svg`}
                                    alt="school-pin-alternative"
                                />
                                <div className="margin-left-10">
                                    Alternative
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

SchoolRankLegend.propTypes = {
    className: PropTypes.string,
    mapInstance: PropTypes.object.isRequired,
};

SchoolRankLegend.defaultProps = {
    className: '',
};

export default SchoolRankLegend;
