import React from 'react';
import PropTypes from 'prop-types';

import AnnotationSelectionCard from './AnnotationSelectionCard';
import Checkbox from '../form/Checkbox';

const AnnotationSelectionCardList = props => (<div className="annotator-section">
    <div className="annotator-section__header">
        <Checkbox
            checked={props.checkedAnnotations.length === props.annotations.length}
            onCheck={props.onAllAnnotationsChecked}
            label="Select all"
            className="body-1"
        />
    </div>
    {props.annotations.map(annotation => <AnnotationSelectionCard
        key={annotation.id}
        title={annotation.title}
        annotation={annotation}
        checked={props.checkedAnnotations.some(caId => caId === annotation.id)}
        onAnnotationChecked={props.onAnnotationChecked}
    />)}
</div>);

AnnotationSelectionCardList.propTypes = {
    checkedAnnotations: PropTypes.array.isRequired,
    annotations: PropTypes.array.isRequired,
    onAnnotationChecked: PropTypes.func.isRequired,
    onAllAnnotationsChecked: PropTypes.func.isRequired,
};

export default AnnotationSelectionCardList;
