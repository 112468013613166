// @ts-check
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

const ReportingNotAvailable = () => (
    <div className="location-analysis__report-not-available badge badge--disabled">
        <FormattedMessage id="dataBrowser.notAvailableForSelectedSurvey" />
    </div>
);

export default injectIntl(ReportingNotAvailable);
