//      

const {isCounterClockwise} = require('./util');

                                                

module.exports = {
    multiPolygonIntersectsBufferedMultiPoint,
    multiPolygonIntersectsMultiPolygon,
    multiPolygonIntersectsBufferedMultiLine,
    multiPolygonIncludesBufferedMultiPoint,
    multiPolygonIncludesMultiPolygon,
    polygonIntersectsPolygon,
    distToSegmentSquared,
    circleIntersectsPoint,
    multiPolygonIntersectsCircle,
};

                         
                             
                         
                            
                                   

function polygonIntersectsPolygon(polygonA         , polygonB         ) {
    for (let i = 0; i < polygonA.length; i++) {
        if (polygonContainsPoint(polygonB, polygonA[i])) return true;
    }

    for (let i = 0; i < polygonB.length; i++) {
        if (polygonContainsPoint(polygonA, polygonB[i])) return true;
    }

    if (lineIntersectsLine(polygonA, polygonB)) return true;

    return false;
}

function multiPolygonIntersectsBufferedMultiPoint(multiPolygon              , rings             , radius        ) {
    for (let j = 0; j < multiPolygon.length; j++) {
        const polygon = multiPolygon[j];
        for (let i = 0; i < rings.length; i++) {
            const ring = rings[i];
            for (let k = 0; k < ring.length; k++) {
                const point = ring[k];
                if (polygonContainsPoint(polygon, point)) return true;
                if (pointIntersectsBufferedLine(point, polygon, radius)) return true;
            }
        }
    }
    return false;
}

function multiPolygonIntersectsCircle(center       , multiPolygon              , radius        ) { //!
    for (let j = 0; j < multiPolygon.length; j++) { //!
        const ring = multiPolygon[j]; //!
        //if the rings include the buffered multipoint i.e. they include the whole circle without intersecting it //!
        if (polygonIncludesCircle(ring, center, radius)) return true; //!
        if (polygonIntersectsCircle(ring, center, radius)) return true; //!
    } //!
    return false; //!
} //!


function polygonIntersectsCircle(ring         , center       , radius        ) { //!
    const dot = (a, b) => { //!
        const mult = a.multByPoint(b); //!
        return mult.x + mult.y; //!
    }; //!
    const radiusSquared = radius * radius;
    for (let k = 1; k < ring.length; k++) { //!
        const p1 = ring[k - 1], p2 = ring[k]; //!
        const v = p2.sub(p1); //!
        if (center.distSqr(p1) < radiusSquared) return true;
        // Circle intersection with line formula //!
        // https://codereview.stackexchange.com/questions/86421/line-segment-to-circle-collision-algorithm //!
        const a = dot(v, v); //!
        const b = 2 * dot(v, p1.sub(center)); //!
        const c = dot(p1, p1) + dot(center, center) - 2 * dot(p1, center) - radius * radius; //!
        const disc = b * b - 4 * a * c; //!
        if (disc < 0) continue; //!
        const sqrtDisc = Math.sqrt(disc); //!
        const t1 = (-b + sqrtDisc) / (2 * a); //!
        const t2 = (-b - sqrtDisc) / (2 * a); //!
        if ((t1 >= 0 && t1 <= 1) || (t2 >= 0 && t2 <= 1)) return true; //!
    } //!
    return false; //!
} //!

function polygonIncludesCircle(ring         , center       , radius        ) { //!
    if (!polygonContainsPoint(ring, center)) return false; //!
    for (let k = 0; k < ring.length; k++) { //!
        const point = ring[k]; //! //!
        if (pointIntersectsBufferedLine(center, [point], radius)) return false; //! //!
    } //!
    return true; //!
} //!

function multiPolygonIncludesBufferedMultiPoint(multiPolygon              , rings             , radius        ) { //!
    for (let j = 0; j < multiPolygon.length; j++) { //!
        const polygon = multiPolygon[j]; //!
        for (let i = 0; i < rings.length; i++) { //!
            const ring = rings[i]; //!
            for (let k = 0; k < ring.length; k++) { //!
                const point = ring[k]; //!
                if (!pointIntersectsBufferedLine(point, polygon, radius)) return false; //!
            } //!
        } //!
    } //!
    return true; //!
} //!

function circleIntersectsPoint(multiPolygon              , rings             , radius        ) {
    const point = multiPolygon[0][0];
    const center = rings[0][0];
    const radiusSquared = radius * radius;
    return point.distSqr(center) < radiusSquared;
}

function multiPolygonIntersectsMultiPolygon(multiPolygonA              , multiPolygonB              ) {

    if (multiPolygonA.length === 1 && multiPolygonA[0].length === 1) {
        return multiPolygonContainsPoint(multiPolygonB, multiPolygonA[0][0]);
    }

    for (let m = 0; m < multiPolygonB.length; m++) {
        const ring = multiPolygonB[m];
        for (let n = 0; n < ring.length; n++) {
            if (multiPolygonContainsPoint(multiPolygonA, ring[n])) return true;
        }
    }

    for (let j = 0; j < multiPolygonA.length; j++) {
        const polygon = multiPolygonA[j];
        for (let i = 0; i < polygon.length; i++) {
            if (multiPolygonContainsPoint(multiPolygonB, polygon[i])) return true;
        }

        for (let k = 0; k < multiPolygonB.length; k++) {
            if (lineIntersectsLine(polygon, multiPolygonB[k])) return true;
        }
    }

    return false;
}

function multiPolygonIncludesMultiPolygon(multiPolygonA              , multiPolygonB              ) { //!
    for (let m = 0; m < multiPolygonB.length; m++) { //!
        const ring = multiPolygonB[m]; //!
        for (let n = 0; n < ring.length; n++) { //!
            if (!multiPolygonContainsPoint(multiPolygonA, ring[n])) return false; //!
        } //!
    } //!
    return true; //!
} //!

function multiPolygonIntersectsBufferedMultiLine(multiPolygon              , multiLine           , radius        ) {
    for (let i = 0; i < multiLine.length; i++) {
        const line = multiLine[i];

        for (let j = 0; j < multiPolygon.length; j++) {
            const polygon = multiPolygon[j];

            if (polygon.length >= 3) {
                for (let k = 0; k < line.length; k++) {
                    if (polygonContainsPoint(polygon, line[k])) return true;
                }
            }

            if (lineIntersectsBufferedLine(polygon, line, radius)) return true;
        }
    }
    return false;
}

function lineIntersectsBufferedLine(lineA      , lineB      , radius        ) {

    if (lineA.length > 1) {
        if (lineIntersectsLine(lineA, lineB)) return true;

        // Check whether any point in either line is within radius of the other line
        for (let j = 0; j < lineB.length; j++) {
            if (pointIntersectsBufferedLine(lineB[j], lineA, radius)) return true;
        }
    }

    for (let k = 0; k < lineA.length; k++) {
        if (pointIntersectsBufferedLine(lineA[k], lineB, radius)) return true;
    }

    return false;
}

function lineIntersectsLine(lineA      , lineB      ) {
    if (lineA.length === 0 || lineB.length === 0) return false;
    for (let i = 0; i < lineA.length - 1; i++) {
        const a0 = lineA[i];
        const a1 = lineA[i + 1];
        for (let j = 0; j < lineB.length - 1; j++) {
            const b0 = lineB[j];
            const b1 = lineB[j + 1];
            if (lineSegmentIntersectsLineSegment(a0, a1, b0, b1)) return true;
        }
    }
    return false;
}

function lineSegmentIntersectsLineSegment(a0       , a1       , b0       , b1       ) {
    return isCounterClockwise(a0, b0, b1) !== isCounterClockwise(a1, b0, b1) &&
        isCounterClockwise(a0, a1, b0) !== isCounterClockwise(a0, a1, b1);
}

function pointIntersectsBufferedLine(p       , line      , radius        ) {
    const radiusSquared = radius * radius;

    if (line.length === 1) return p.distSqr(line[0]) < radiusSquared;

    for (let i = 1; i < line.length; i++) {
        // Find line segments that have a distance <= radius^2 to p
        // In that case, we treat the line as "containing point p".
        const v = line[i - 1], w = line[i];
        if (distToSegmentSquared(p, v, w) < radiusSquared) return true;
    }
    return false;
}

// Code from http://stackoverflow.com/a/1501725/331379.
function distToSegmentSquared(p       , v       , w       ) {
    const l2 = v.distSqr(w);
    if (l2 === 0) return p.distSqr(v);
    const t = ((p.x - v.x) * (w.x - v.x) + (p.y - v.y) * (w.y - v.y)) / l2;
    if (t < 0) return p.distSqr(v);
    if (t > 1) return p.distSqr(w);
    return p.distSqr(w.sub(v)._mult(t)._add(v));
}

// point in polygon ray casting algorithm
function multiPolygonContainsPoint(rings             , p       ) {
    let c = false,
        ring, p1, p2;

    for (let k = 0; k < rings.length; k++) {
        ring = rings[k];
        for (let i = 0, j = ring.length - 1; i < ring.length; j = i++) {
            p1 = ring[i];
            p2 = ring[j];
            if (((p1.y > p.y) !== (p2.y > p.y)) && (p.x < (p2.x - p1.x) * (p.y - p1.y) / (p2.y - p1.y) + p1.x)) {
                c = !c;
            }
        }
    }
    return c;
}

function polygonContainsPoint(ring      , p       ) {
    let c = false;
    for (let i = 0, j = ring.length - 1; i < ring.length; j = i++) {
        const p1 = ring[i];
        const p2 = ring[j];
        if (((p1.y > p.y) !== (p2.y > p.y)) && (p.x < (p2.x - p1.x) * (p.y - p1.y) / (p2.y - p1.y) + p1.x)) {
            c = !c;
        }
    }
    return c;
}
