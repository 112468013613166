//      

const pattern = require('./pattern');
const blendColors = require('../util/dragonfly_util').blendColors; //!
                                     
                                                      
                                                                        
                                                         
                                                  


module.exports = drawFill;

function drawFeature(painter         , sourceCache             , layer                , coord           , method         ) { //!
    if (method !== 'fill' && method !== 'stroke') throw new Error('Invalid method in drawFeature'); //!
    if (painter._featuresStyles.features.length === 0) return; //!

    const features = painter._featuresStyles.features; //!
    const styles = painter._featuresStyles.styles; //!

    if (!features || features.length === 0) return; //!
    if (!styles || styles.length === 0) return; //!
    layer.transitionDisabled = true; //!
    for (let f = 0; f < features.length; f++) { //!
        const feature = features[f]; //!
        const style = styles[f]; //!

        if (layer.id !== feature.layer.id) continue; //!

        const tile = sourceCache.getTile(coord); //!

        const bucketMeta = tile.metadata.buckets[layer.id]; //!
        if (!bucketMeta) continue; //!

        const featureMeta = bucketMeta.features[feature.id]; //!
        if (!featureMeta) continue; //!

        const oldStyle = {}; //!
        for (const prop in style) { //!
            oldStyle[prop] = layer.paint[prop]; //!
            if (prop.endsWith('color'))  //!
            //! blend colors, so we don't have to enable gl.BLEND (which is slow)
                layer.paint[prop] = layer.paint[prop] ? blendColors(layer.paint[prop], style[prop]) : style[prop]; //!
            else //!
                layer.paint[prop] = style[prop]; //!
        } //!

        if (method === 'fill') //!
            drawFillTiles(painter, sourceCache, layer, [coord], drawFillTile, featureMeta); //!
        if (method === 'stroke' && layer.paint['fill-outline-color'])
            drawFillTiles(painter, sourceCache, layer, [coord], drawStrokeTile, featureMeta); //!

        //! revert to previous style
        for (const prop in oldStyle) { //!
            layer.paint[prop] = oldStyle[prop]; //!
        } //!
    } //!
    layer.transitionDisabled = false; //!
} //!
function drawFill(painter         , sourceCache             , layer                , coords                  ) {
    if (layer.isOpacityZero(painter.transform.zoom)) return;

    const gl = painter.gl;
    gl.enable(gl.STENCIL_TEST);

    const pass = (!layer.paint['fill-pattern'] &&
        layer.isPaintValueFeatureConstant('fill-color') &&
        layer.isPaintValueFeatureConstant('fill-opacity') &&
        layer.paint['fill-color'].a === 1 &&
        layer.paint['fill-opacity'] === 1) ? 'opaque' : 'translucent';

    // Draw fill
    if (painter.renderPass === pass) {
        // Once we switch to earcut drawing we can pull most of the WebGL setup
        // outside of this coords loop.
        painter.setDepthSublayer(1);
        painter.depthMask(painter.renderPass === 'opaque');
        layer.paint['fill-color'][3] = layer.paint['fill-color'][3] === 1 ? 1.01 : layer.paint['fill-color'][3]; //! weird bug fix TODO INVESTIGATE THIS
        drawFillTiles(painter, sourceCache, layer, coords, drawFillTile);

        if (painter._featuresStyles.features.length > 0 && !layer.isMask) //!
            for (let ii = 0; ii < coords.length; ii++) //!
                drawFeature(painter, sourceCache, layer, coords[ii], 'fill'); //!
    }

    // Draw stroke
    if (painter.renderPass === 'translucent' && layer.paint['fill-antialias']) {
        painter.lineWidth(2);
        painter.depthMask(false);

        // If we defined a different color for the fill outline, we are
        // going to ignore the bits in 0x07 and just care about the global
        // clipping mask.
        // Otherwise, we only want to drawFill the antialiased parts that are
        // *outside* the current shape. This is important in case the fill
        // or stroke color is translucent. If we wouldn't clip to outside
        // the current shape, some pixels from the outline stroke overlapped
        // the (non-antialiased) fill.
        painter.setDepthSublayer(layer.getPaintProperty('fill-outline-color') ? 2 : 0);
        drawFillTiles(painter, sourceCache, layer, coords, drawStrokeTile);

        if (painter._featuresStyles.features.length > 0 && !layer.isMask) //!
            for (let jj = 0; jj < coords.length; jj++) //!
                drawFeature(painter, sourceCache, layer, coords[jj], 'stroke'); //!
    }
}

function drawFillTiles(painter, sourceCache, layer, coords, drawFn, featureMeta) { //!
    if (pattern.isPatternMissing(layer.paint['fill-pattern'], painter)) return;

    let firstTile = true;
    for (const coord of coords) {
        const tile = sourceCache.getTile(coord);
        const bucket              = (tile.getBucket(layer)     );
        if (!bucket) continue;

        painter.enableTileClippingMask(coord);
        drawFn(painter, sourceCache, layer, tile, coord, bucket, firstTile, featureMeta);
        firstTile = false;
    }
}

function drawFillTile(painter, sourceCache, layer, tile, coord, bucket, firstTile, featureMeta) { //!
    const gl = painter.gl;
    const programConfiguration = bucket.programConfigurations.get(layer.id);

    const program = setFillProgram('fill', layer.paint['fill-pattern'], painter, programConfiguration, layer, tile, coord, firstTile);

    program.draw(
        gl,
        gl.TRIANGLES,
        layer.id,
        bucket.layoutVertexBuffer,
        bucket.indexBuffer,
        bucket.segments,
        programConfiguration,
        undefined,
        undefined,
        featureMeta);
}

function drawStrokeTile(painter, sourceCache, layer, tile, coord, bucket, firstTile, featureMeta) {
    const gl = painter.gl;
    const programConfiguration = bucket.programConfigurations.get(layer.id);
    const usePattern = layer.paint['fill-pattern'] && !layer.getPaintProperty('fill-outline-color');

    const program = setFillProgram('fillOutline', usePattern, painter, programConfiguration, layer, tile, coord, firstTile);
    gl.uniform2f(program.uniforms.u_world, gl.drawingBufferWidth, gl.drawingBufferHeight);

    program.draw(
        gl,
        gl.LINES,
        layer.id,
        bucket.layoutVertexBuffer,
        bucket.indexBuffer2,
        bucket.segments2,
        programConfiguration,
        undefined,
        undefined,
        featureMeta,
        'Second');
}

function setFillProgram(programId, usePattern, painter, programConfiguration, layer, tile, coord, firstTile) {
    let program;
    const prevProgram = painter.currentProgram;
    if (!usePattern) {
        program = painter.useProgram(programId, programConfiguration);
        if (firstTile || program !== prevProgram || layer.isMask) { //!
            programConfiguration.setUniforms(painter.gl, program, layer, {zoom: painter.transform.zoom});
        }
    } else {
        program = painter.useProgram(`${programId}Pattern`, programConfiguration);
        if (firstTile || program !== prevProgram || layer.isMask) { //!
            programConfiguration.setUniforms(painter.gl, program, layer, {zoom: painter.transform.zoom});
            pattern.prepare(layer.paint['fill-pattern'], painter, program);
        }
        pattern.setTile(tile, painter, program);
    }
    painter.gl.uniformMatrix4fv(program.uniforms.u_matrix, false, painter.translatePosMatrix(
        coord.posMatrix, tile,
        layer.paint['fill-translate'],
        layer.paint['fill-translate-anchor']
    ));
    return program;
}
