/* eslint class-methods-use-this: 0 */

class ReportRenderer {
    constructor() {
        this._insufficientDataRuleIndex = -1;
        this._nullDataRuleIndex = -1;
    }

    get type() {
        return 'ReportRenderer';
    }

    get insufficientDataRuleIndex() {
        return this._insufficientDataRuleIndex;
    }

    set insufficientDataRuleIndex(insufficientDataRuleIndex) {
        this._insufficientDataRuleIndex = insufficientDataRuleIndex;
    }

    get nullDataRuleIndex() {
        return this._nullDataRuleIndex;
    }

    set nullDataRuleIndex(nullDataRuleIndex) {
        this._nullDataRuleIndex = nullDataRuleIndex;
    }

    get fieldList() {
        return this._fieldList;
    }

    set fieldList(fieldList) {
        this._fieldList = fieldList;
    }

    get rules() {
        return this._rules;
    }

    set rules(rules) {
        this._rules = rules;
    }

    get visibility() {
        return this._visibility;
    }

    set visibility(visibility) {
        this._visibility = visibility;
    }

    get reportLimit() {
        return this._reportLimit;
    }

    set reportLimit(reportLimit) {
        this._reportLimit = reportLimit;
    }

    applyColorPalette(colorPalette, isFlipped) {
        let specialRulesCount = 0;
        if (this.insufficientDataRuleIndex !== -1) specialRulesCount += 1;
        if (this.nullDataRuleIndex !== -1) specialRulesCount += 1;

        const colors = colorPalette.interpolateColors(this.rules.length - specialRulesCount);

        if (isFlipped) colors.reverse();

        this.rules.forEach((rule, ruleIdx) => {
            let paintColor;

            if (ruleIdx === this.insufficientDataRuleIndex) {
                paintColor = colorPalette.insufficientDataColor;
            } else if (ruleIdx === this.nullDataRuleIndex) {
                paintColor = colorPalette.nullDataColor;
            } else {
                paintColor = colors.shift();
            }

            rule.symbols[0].brushes.forEach(b => {
                b.fillColor = paintColor;
            });
        });
    }

    equals(that) {
        return (
        this.fieldList.equals(that.fieldList) &&
        this.rules.every((rule, index) => rule.equals(that.rules[index])) &&
        this.visibility.every((vis, index) => vis.equals(that.visibility[index])) &&
        this.type === that.type &&
        this.insufficientDataRuleIndex === that.insufficientDataRuleIndex &&
        this.nullDataRuleIndex === that.nullDataRuleIndex);
    }

    clone() {
        const reportRenderer = new ReportRenderer();
        reportRenderer.fieldList = this.fieldList.clone();
        reportRenderer.rules = this.rules.map(rule => rule.clone());
        reportRenderer.visibility = this.visibility.map(vis => vis.clone());
        reportRenderer.insufficientDataRuleIndex = this.insufficientDataRuleIndex;
        reportRenderer.nullDataRuleIndex = this.nullDataRuleIndex;

        return reportRenderer;
    }

    toJSON() {
        return {
            type: this.type,
            fieldList: this.fieldList.toJSON(),
            rules: this.rules.map(rule => rule.toJSON()),
            visibility: this.visibility.map(vis => vis.toJSON()),
            insufficientDataRuleIndex: this.insufficientDataRuleIndex,
            nullDataRuleIndex: this.nullDataRuleIndex,
        };
    }
}

export default ReportRenderer;
