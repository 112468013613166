class MetaDataset {
    constructor() {
        this._enabled = true;
        this._availableToUser = true;
    }

    get uuid() {
        return this._uuid;
    }

    set uuid(uuid) {
        this._uuid = uuid;
    }

    get surveyName() {
        return this._surveyName;
    }

    set surveyName(surveyName) {
        this._surveyName = surveyName;
    }

    get name() {
        return this._name;
    }

    set name(name) {
        this._name = name;
    }

    get displayName() {
        return this._displayName;
    }

    set displayName(displayName) {
        this._displayName = displayName;
    }

    /** @type {string} dataset abbreviation, like SE, ORG, ACS14_5yr */
    get abbrevation() {
        return this._abbrevation;
    }

    set abbrevation(abbrevation) {
        this._abbrevation = abbrevation;
    }

    get publisher() {
        return this._publisher;
    }

    set publisher(publisher) {
        this._publisher = publisher;
    }

    get source() {
        return this._source;
    }

    set source(source) {
        this._source = source;
    }

    get productTags() {
        return this._productTags;
    }

    set productTags(productTags) {
        this._productTags = productTags;
    }

    get partial() {
        return this._partial;
    }

    set partial(partial) {
        this._partial = partial;
    }

    get dataCategoriesNames() {
        return this._dataCategoriesNames;
    }

    set dataCategoriesNames(dataCategoriesNames) {
        this._dataCategoriesNames = dataCategoriesNames;
    }

    /** @type {Object.<string, import('./MetaTable').default>} key is table name (not guid) */
    get tables() {
        return this._tables;
    }

    set tables(tables) {
        this._tables = tables;
    }

    get index() {
        return this._index;
    }

    set index(index) {
        this._index = index;
    }

    get survey() {
        return this._survey;
    }

    set survey(survey) {
        this._survey = survey;
    }

    get enabled() {
        return this._enabled;
    }

    set enabled(enabled) {
        this._enabled = enabled;
    }

    get availableToUser() {
        return this._availableToUser;
    }

    set availableToUser(availableToUser) {
        this._availableToUser = availableToUser;
    }

    get tablesAsArray() {
        if (this.tables) {
            return Object.keys(this.tables).map(t => this.tables[t]);
        }
        return [];
    }

    toJSON() {
        return {
            uuid: this.uuid,
            surveyName: this.surveyName,
            name: this.name,
            displayName: this.displayName,
            abbrevation: this.abbrevation,
            publisher: this.publisher,
            source: this.source,
            productTags: this.productTags,
            partial: this.partial,
            categories: this.categories,
            tables: this.tables,
        };
    }

    /**
     * @param {import("../types").TableGuid} guid GUID of the table in this dataset
     */
    getTableByGuid(guid) {
        return Object.keys(this.tables).map(tableName => this.tables[tableName]).find(table => table.uuid === guid);
    }
}

export default MetaDataset;
