import React from 'react';
import BusComponent from './BusComponent';
import Key from '../enums/Key';
import classNames from 'classnames';

class NumberPicker extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            allowedNumbers: [],
            currentNumber: undefined,
            min: props.min,
            max: props.max,
        };
        this.boundOnInputChange = this.onInputChange;
        this.boundOnKeyDown = this.onKeyDown;
        this.boundOnNumberSubmitted = this.onNumberSubmitted;
    }

    componentWillMount() {
        this.setState(NumberPicker.getNextState(this.props));
    }

    componentWillReceiveProps(nextProps) {
        this.setState(NumberPicker.getNextState(nextProps));
    }

    onInputChange = e => {
        this.setState({
            currentNumber: e.target.value,
        });
    }

    onKeyDown = e => {
        if (e.keyCode === Key.ENTER) {
            this.boundOnNumberSubmitted();
        }
    }

    onNumberDecrease = () => {
        const currentFieldValue = parseInt(this.number.value, 10);
        if (isNaN(currentFieldValue)) {
            this.setState({
                currentNumber: this.props.currentNumber,
            });
            return;
        }
        let nextNumber = currentFieldValue;
        if (this.state.allowedNumbers.length > 0) {
            nextNumber = this.state.allowedNumbers.some(allowedNumber => allowedNumber === nextNumber - 1) ? nextNumber - 1 : this.findClosestNumber(nextNumber);
        } else if (this.state.min !== undefined) {
            nextNumber = nextNumber - 1 < this.state.min ? this.state.min : nextNumber - 1;
        } else {
            nextNumber -= 1;
        }
        this.props.onNumberChange(nextNumber);
    }

    onNumberIncrease = () => {
        const currentFieldValue = parseInt(this.number.value, 10);
        if (isNaN(currentFieldValue)) {
            this.setState({
                currentNumber: this.props.currentNumber,
            });
            return;
        }
        let nextNumber = currentFieldValue;
        if (this.state.allowedNumbers.length > 0) {
            nextNumber = this.state.allowedNumbers.some(allowedNumber => allowedNumber === nextNumber + 1) ? nextNumber + 1 : this.findClosestNumber(nextNumber);
        } else if (this.state.max !== undefined) {
            nextNumber = nextNumber + 1 > this.state.max ? this.state.max : nextNumber + 1;
        } else {
            nextNumber += 1;
        }
        this.props.onNumberChange(nextNumber);
    }

    onNumberSubmitted = () => {
        const currentFieldValue = parseInt(this.number.value, 10);
        if (isNaN(currentFieldValue)) {
            this.setState({
                currentNumber: this.props.currentNumber,
            });
            return;
        }
        let nextNumber = currentFieldValue;
        if (this.state.allowedNumbers.length > 0) {
            nextNumber = this.state.allowedNumbers.some(allowedBreak => allowedBreak === currentFieldValue) ? currentFieldValue : this.findClosestNumber(currentFieldValue);
            this.props.onNumberChange(nextNumber);
        } else {
            if (this.state.max !== undefined) {
                nextNumber = nextNumber > this.state.max ? this.state.max : nextNumber;
            }
            if (this.state.min !== undefined) {
                nextNumber = nextNumber < this.state.min ? this.state.min : nextNumber;
            }
            this.props.onNumberChange(nextNumber);
        }
        this.setState({
            currentNumber: nextNumber,
        });
    }

    static getNextState(props) {
        const nextState = {
            currentNumber: props.currentNumber,
        };
        if (props.allowedNumbers !== undefined && props.allowedNumbers.length > 0) {
            if (typeof props.allowedNumbers[0] === 'string') {
                nextState.allowedNumbers = props.allowedNumbers.map(stringNumber => parseInt(stringNumber, 10));
            } else {
                nextState.allowedNumbers = props.allowedNumbers;
            }
        } else {
            if (props.min !== undefined) {
                if (typeof props.min === 'string') {
                    nextState.min = parseInt(props.min, 10);
                } else if (typeof props.min === 'number') {
                    nextState.min = props.min;
                }
            }
            if (props.max !== undefined) {
                if (typeof props.max === 'string') {
                    nextState.max = parseInt(props.max, 10);
                } else if (typeof props.max === 'number') {
                    nextState.max = props.max;
                }
            }
        }
        return nextState;
    }

    render() {
        const numberPickerClassNames = classNames(this.props.classes, 'number-picker');
        return (
            <div className={numberPickerClassNames}>
                <input
                    onChange={this.boundOnInputChange}
                    onBlur={this.boundOnNumberSubmitted}
                    value={this.state.currentNumber}
                    onKeyDown={this.boundOnKeyDown}
                    ref={number => { this.number = number; }}
                />
                <button className="change-direction" onClick={this.onNumberDecrease}>
                    <i className="material-icons">keyboard_arrow_down</i>
                </button>
                <button className="change-direction" onClick={this.onNumberIncrease}>
                    <i className="material-icons">keyboard_arrow_up</i>
                </button>
            </div>);
    }

    findClosestNumber(nr) {
        if (this.state.allowedNumbers.length === 0) return -1;
        let closestNumberIndex = 0;
        let distance = Number.MAX_SAFE_INTEGER;
        this.state.allowedNumbers.forEach((allowedNumber, index) => {
            if (Math.abs(parseInt(allowedNumber, 10) - nr) < distance) {
                distance = Math.abs(allowedNumber - nr);
                closestNumberIndex = index;
            }
        });
        return this.state.allowedNumbers[closestNumberIndex];
    }

}

export default NumberPicker;
