import React from 'react';
import PropTypes from 'prop-types';

import AppConfig from '../appConfig';

const ErrorPage = ({ errorMessage, additionalErrorMessage }) => (
    <div className="application-content error-page">
        <div className="error-page__illustration">
            <div className="error-page__illustration__logo">
                <a href={AppConfig.constants.backends.website}>
                    <img
                        src={`${AppConfig.constants.assetsBaseURL}/svg/se-logo-monochrome.svg`}
                        alt="SocialExplorer logo"
                    />
                </a>
            </div>
            <div className="error-page__illustration__icon">
                <img
                    src={`${AppConfig.constants.assetsBaseURL}/svg/error.svg`}
                    alt="Error illustration"
                    width="200"
                />
            </div>
        </div>
        <div className="error-page__explanation">
            <div className="error-page__explanation__title">
                {`${AppConfig.constants.applicationName} Maps Application has encountered an error`}
            </div>
            {errorMessage !== undefined && (
                <p className="lead">{errorMessage}</p>
            )}
            <br />
            <p className="lead">
                {additionalErrorMessage ||
                    'Try clearing your browser cache and refreshing the page.'}
            </p>
        </div>
    </div>
);

ErrorPage.propTypes = {
    errorMessage: PropTypes.string,
    additionalErrorMessage: PropTypes.string,
};

ErrorPage.defaultProps = {
    errorMessage: undefined,
    additionalErrorMessage: undefined,
};

export default ErrorPage;
