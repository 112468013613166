import React from 'react';
import BusComponent from '../BusComponent';


class AutomaticDownload extends BusComponent {
    constructor(props, context) {
        super(props, context);

        this.state = {};

        this.bindGluBusEvents({
            DOWNLOAD_FILE: this.onDownloadFile,
        });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    onDownloadFile(link) {
        let downloadLink = link;
        if (this.state.downloadLink === downloadLink) {
            // this enables downloading same file multiple times in one session
            downloadLink += `?_=${new Date().getTime()}`;
        }
        this.setState({ downloadLink });
    }

    render() {
        if (this.state.downloadLink) {
            return <iframe src={this.state.downloadLink} width="1px" height="1px" frameBorder="0" />;
        }
        return null;
    }
}

export default AutomaticDownload;
