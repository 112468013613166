import React from 'react';
import PropTypes from 'prop-types';

const Indicator = ({
       isCondensedLayout,
       isReportEditor,
       clickHandle,
       text,
       onClickClear,
       filterAndMask,
       editFilterAndMaskDialogVisible }) =>
    (<div className="mask-indicator">
        <button
            className="mask-indicator__title padding-left-10 flex-it justify-center center grow"
            disabled={isCondensedLayout}
            onClick={clickHandle}
        >
            {text} is on
            {filterAndMask && <i className="material-icons md-18 mask-indicator__toggle">
                {editFilterAndMaskDialogVisible ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                </i>}
        </button>
        {(!filterAndMask && !isReportEditor) &&
            <button className="mask-indicator__close" onClick={onClickClear}>
                <i className="material-icons md-18">close</i>
            </button>
        }
    </div>);

Indicator.propTypes = {
    isCondensedLayout: PropTypes.bool,
    text: PropTypes.string.isRequired,
    clickHandle: PropTypes.func.isRequired,
    onClickClear: PropTypes.func,
    filterAndMask: PropTypes.bool,
    editFilterAndMaskDialogVisible: PropTypes.bool,
    isReportEditor: PropTypes.bool,
};

Indicator.defaultProps = {
    isCondensedLayout: false,
    filterAndMask: false,
    editFilterAndMaskDialogVisible: false,
    onClickClear: undefined,
    isReportEditor: false,
};

export default Indicator;
