// @ts-check
import React from 'react';
import PropTypes from 'prop-types';

import ReportType from '../../enums/ReportType';

/**
 * @param {object} param0
 * @param {*} param0.survey
 * @param {*} param0.table
 * @param {string | number} param0.reportTypeId
 */
const CurrentVariableSelection = ({ survey, table, reportTypeId }) => {
    if (reportTypeId !== ReportType.CURRENT_VARIABLE_TABLE.id) {
        return null;
    }

    return (
        <div className="report-options__current_variable flex-it column">
            <span className="body-2">{survey.displayName}</span>
            <span className="body-1">
                {table.name} - {table.title}
            </span>
        </div>
    );
};

CurrentVariableSelection.propTypes = {
    survey: PropTypes.object.isRequired,
    table: PropTypes.object.isRequired,
    reportTypeId: PropTypes.string.isRequired,
};

export default CurrentVariableSelection;
