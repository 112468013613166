import GLU from '../glu2.js/src/index';
import AppConfig from '../appConfig';

class SessionLoggingDatasource extends GLU.DataSource {
    static get name() {
        return 'SessionLoggingDatasource';
    }

    static getInstance() {
        return new SessionLoggingDatasource();
    }

    constructor() {
        super();

        // Used in logging controller to ensure we can track multiple instances
        // of our application that are running at the same time on the client and on the same session.
        // see the original implementation here:
        // https://github.com/SocialExplorer/Map-Caiman/blob/63e6876660153574356826bbd96208332f1b6df4/SeApplication/src/application/App.as
        this.instanceId = Math.round(Math.random() * 1000000).toString();
    }

    logCounterEvent(events) {
        const httpRequest = new XMLHttpRequest();
        httpRequest.open('POST', `${AppConfig.constants.backends.sewebsite}/counter/event`, true);
        httpRequest.setRequestHeader('Content-Type', 'application/json');
        httpRequest.send(JSON.stringify({ events }));
    }
}

export default SessionLoggingDatasource;
