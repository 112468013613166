class RendererVisibilityRule {
    get ifRendererTypeOnAnyDataLayer() {
        return this._ifRendererTypeOnAnyDataLayer;
    }

    set ifRendererTypeOnAnyDataLayer(ifRendererTypeOnAnyDataLayer) {
        this._ifRendererTypeOnAnyDataLayer = ifRendererTypeOnAnyDataLayer;
    }

    get ifLayerIdVisible() {
        return this._ifLayerIdVisible;
    }

    set ifLayerIdVisible(ifLayerIdVisible) {
        this._ifLayerIdVisible = ifLayerIdVisible;
    }

    get ifRendererTypeExists() {
        return this._ifRendererTypeExists;
    }

    set ifRendererTypeExists(ifRendererTypeExists) {
        this._ifRendererTypeExists = ifRendererTypeExists;
    }

    get ifDataLayerVisible() {
        return this._ifDataLayerVisible;
    }

    set ifDataLayerVisible(ifDataLayerVisible) {
        this._ifDataLayerVisible = ifDataLayerVisible;
    }

    get ifLayerIdNotVisible() {
        return this._ifLayerIdNotVisible;
    }

    set ifLayerIdNotVisible(ifLayerIdNotVisible) {
        this._ifLayerIdNotVisible = ifLayerIdNotVisible;
    }

    equals(that) {
        return (this.ifRendererTypeOnAnyDataLayer === that.ifRendererTypeOnAnyDataLayer &&
        this.ifLayerIdVisible === that.ifLayerIdVisible &&
        this.ifRendererTypeExists === that.ifRendererTypeExists &&
        this.ifDataLayerVisible === that.ifDataLayerVisible &&
        this.ifLayerIdNotVisible === that.ifLayerIdNotVisible);
    }

    clone() {
        const rendererVisibilityRule = new RendererVisibilityRule();
        rendererVisibilityRule.ifRendererTypeOnAnyDataLayer = this.ifRendererTypeOnAnyDataLayer;
        rendererVisibilityRule.ifLayerIdVisible = this.ifLayerIdVisible;
        rendererVisibilityRule.ifRendererTypeExists = this.ifRendererTypeExists;
        rendererVisibilityRule.ifDataLayerVisible = this.ifDataLayerVisible;
        rendererVisibilityRule.ifLayerIdNotVisible = this.ifLayerIdNotVisible;

        return rendererVisibilityRule;
    }

    toJSON() {
        return {
            ifRendererTypeOnAnyDataLayer: this.ifRendererTypeOnAnyDataLayer,
            ifLayerIdVisible: this.ifLayerIdVisible,
            ifRendererTypeExists: this.ifRendererTypeExists,
            ifDataLayerVisible: this.ifDataLayerVisible,
            ifLayerIdNotVisible: this.ifLayerIdNotVisible,
        };
    }
}

export default RendererVisibilityRule;
