class DataGeoFilter {
    constructor(values) {
        this.features = [];
        if (values) {
            Object.keys(values).forEach(k => {
                if (this.constructor.prototype.hasOwnProperty(k)) {
                    this[k] = values[k];
                }
            });
        }
        if (!this._nestedSummaryLevelsIds) this._nestedSummaryLevelsIds = [];
    }

    get summaryLevelId() {
        return this._summaryLevelId;
    }

    set summaryLevelId(summaryLevelId) {
        this._summaryLevelId = summaryLevelId;
    }

    get features() {
        return this._features;
    }

    set features(features) {
        this._features = features;
    }

    get dataLayerId() {
        return this._dataLayerId;
    }

    set dataLayerId(dataLayerId) {
        this._dataLayerId = dataLayerId;
    }

    get nestedSummaryLevelsIds() {
        return this._nestedSummaryLevelsIds;
    }

    set nestedSummaryLevelsIds(nestedSummaryLevelsIds) {
        this._nestedSummaryLevelsIds = nestedSummaryLevelsIds;
    }

    get filteredValues() {
        return this.features.map(feature => feature.columnValue);
    }

    dragonflyFilter(columnName) {
        const filter = ['starts-with', columnName];
        this.features.forEach(feature => filter.push(feature.columnValue));
        return filter;
    }

    equals(that) {
        return this.summaryLevelId === that.summaryLevelId &&
            (this.nestedSummaryLevelsIds === that.nestedSummaryLevelsIds && !this.nestedSummaryLevelsIds.find(id => !that.nestedSummaryLevelsIds.find(id1 => id1 === id))) &&
            (this.features.length === that.features.length && !this.features.find(f1 => !that.features.find(f2 => f1.equals(f2))));
    }

    clone() {
        return new DataGeoFilter({
            summaryLevelId: this.summaryLevelId,
            features: this.features.map(f => f.clone()),
            nestedSummaryLevelsIds: this.nestedSummaryLevelsIds,
            dataLayerId: this.dataLayerId,
        });
    }

    toJSON() {
        return {
            summaryLevelId: this.summaryLevelId,
            features: this.features.map(f => f.toJSON()),
            nestedSummaryLevelsIds: this.nestedSummaryLevelsIds,
            dataLayerId: this.dataLayerId,
        };
    }
}

export default DataGeoFilter;
