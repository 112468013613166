import BaseHandler from './BaseHandler';
import parseCSSColor from 'csscolorparser';

class DynamicLabelsContrastHandler extends BaseHandler {
    constructor(mapViewer) {
        super(mapViewer);
        this._data = mapViewer.dragonflyMapData;
        this._mapViewer = mapViewer;
        this._labelFeatures = [];
        this._labelFeaturesStyles = [];

        const { light: dynamicContrastLightLabelStyle, dark: dynamicContrastDarkLabelStyle } = this._mapConfig.dynamicLabelsContrastStyles;

        const _seenFeatures = new Set();
        const layers = [];
        this._data.layers.forEach(l => {
            if (l.type === 'symbol') {
                if (l.layout && l.layout['symbol-placement'] && l.layout['symbol-placement'] !== 'point') {
                    return;
                }

                layers.push(l.id);
            }
        });

        const BUFFER_WIDTH = 10;
        const BUFFER_HEIGHT = 10;
        const PIXEL_ARRAY_LENGTH = BUFFER_WIDTH * BUFFER_HEIGHT;
        const PIXEL_ARRAY_SIZE_IN_BYTES = PIXEL_ARRAY_LENGTH * 4;
        const PIXEL_ARRAY_ROW_SIZE = BUFFER_WIDTH * 4;
        const _mapPixelsWithoutLabels = new Uint8Array(PIXEL_ARRAY_SIZE_IN_BYTES);

        const _queryRenderedFeaturesOptions = { layers };
        let _labelsCache = []; // eslint-disable-line
        let _labelsStylesCache = []; // eslint-disable-line
        let _cachedLabelsFeatures = {}; // eslint-disable-line
        let _fs, _fsKey, _labelsCacheIndex, cachedEntryIndex, avg, skippedCount;
        let _isLabelsCacheDirty = true;

        this.touchLabelsCache = () => {
            _isLabelsCacheDirty = true;
            this._mapViewer.dragonflyMap._update(true);
            if (this._mapViewer.dragonflyMap.painter.setLabelFeaturesStyles) {
                this._mapViewer.dragonflyMap.painter.setLabelFeaturesStyles(this._labelFeatures, this._labelFeaturesStyles);
            }
        };

        this.clearLabelsCache = () => {
            _labelsCache = [];
            _labelsStylesCache = [];
            _cachedLabelsFeatures = {};
            this.touchLabelsCache();
        };

        mapViewer.dragonflyMap.on('zoom', this.clearLabelsCache);
        mapViewer.dragonflyMap.on('sourcedata', this.clearLabelsCache);
        mapViewer.dragonflyMap.on('zoomend', this.clearLabelsCache);
        mapViewer.dragonflyMap.on('moveend', this.touchLabelsCache);
        mapViewer.dragonflyMap.on('move', this.touchLabelsCache);
        mapViewer.dragonflyMap.on('styledata', this.clearLabelsCache);

        this.bus.on('MAP_NEW_DATA_THEME_APPLIED', this.clearLabelsCache);
        this.bus.on('MAP_NEW_COLOR_PALETTE_APPLIED', this.clearLabelsCache);
        this.bus.on('MAP_NEW_CUTPOINTS_APPLIED', this.clearLabelsCache);
        this.bus.on('MAP_PREFERRED_SUMMARY_LEVEL_CHANGED', this.clearLabelsCache);
        this.bus.on('MAP_SATELLITE_LAYER_VISIBILITY_CHANGED', this.clearLabelsCache);
        this.bus.on('MAP_LAYERS_OVERRIDES_APPLIED', this.clearLabelsCache);
        this.bus.on('MAP_MASKING_FILTER_APPLIED', this.clearLabelsCache);
        this.bus.on('MAP_MASKING_FILTER_REMOVED', this.clearLabelsCache);

        if (!this._mapViewer.dragonflyMap.setLabelFeaturesStyles) {
            this._mapViewer.dragonflyMap.painter._oldSetFeaturesStyles = this._mapViewer.dragonflyMap.painter.setFeaturesStyles;
            this._mapViewer.dragonflyMap.painter._oldClearFeaturesStyles = this._mapViewer.dragonflyMap.painter.clearFeaturesStyles;

            this._mapViewer.dragonflyMap.painter._originalFeatures = [];
            this._mapViewer.dragonflyMap.painter._originalStyles = [];

            this._mapViewer.dragonflyMap.painter._labelFeatures = [];
            this._mapViewer.dragonflyMap.painter._labelStyles = [];


            this._mapViewer.dragonflyMap.painter.setLabelFeaturesStyles = function (features, styles, update = true) { // eslint-disable-line
                this._labelFeatures = features;
                this._labelStyles = styles;

                if (this._originalFeatures.length > 0) {
                    this._oldSetFeaturesStyles(this._labelFeatures.concat(this._originalFeatures), this._labelStyles.concat(this._originalStyles), update);
                } else {
                    this._oldSetFeaturesStyles(this._labelFeatures, this._labelStyles, update);
                }
            };
            this._mapViewer.dragonflyMap.painter.clearLabelFeaturesStyles = function (update = true) { // eslint-disable-line
                this._labelFeatures = [];
                this._labelStyles = [];

                if (this._originalFeatures.length > 0) {
                    this._oldSetFeaturesStyles(this._originalFeatures, this._originalStyles, update);
                } else {
                    this._oldClearFeaturesStyles(update);
                }
            };
            this._mapViewer.dragonflyMap.painter.setFeaturesStyles = function (features, styles, update = true) { // eslint-disable-line
                this._originalFeatures = features;
                this._originalStyles = styles;

                if (this._labelFeatures.length > 0) {
                    this._oldSetFeaturesStyles(this._labelFeatures.concat(this._originalFeatures), this._labelStyles.concat(this._originalStyles), update);
                } else {
                    this._oldSetFeaturesStyles(this._originalFeatures, this._originalStyles, update);
                }
            };
            this._mapViewer.dragonflyMap.painter.clearFeaturesStyles = function (update = true) { // eslint-disable-line
                this._originalFeatures = [];
                this._originalStyles = [];

                if (this._labelFeatures.length > 0) {
                    this._oldSetFeaturesStyles(this._labelFeatures, this._labelStyles, update);
                } else {
                    this._oldClearFeaturesStyles(update);
                }
            };
        }

        if (!this._mapViewer.dragonflyMap.painter._oldRenderLayer) {
            /* In order to read the colors at a given coordinate before the label layers have been rendered
                       we need to monkey-patch the dragonfly's painter render layer to insert a "before layer render" hook. */
            this._mapViewer.dragonflyMap.painter._oldRenderLayer = this._mapViewer.dragonflyMap.painter.renderLayer;
            this._mapViewer.dragonflyMap.painter.renderLayer = (painter, sourceCache, layer, coords) => {
                if (painter.currentLayer === 0 && painter.renderPass === 'opaque' && _isLabelsCacheDirty && !painter.style.map.animationLoop.stopped()) {
                    window.requestAnimationFrame(() => {
                        this.clearLabelsCache();
                    });
                } else if (_queryRenderedFeaturesOptions.layers[0] === layer.id && painter.renderPass === 'translucent') {
                    if (_isLabelsCacheDirty || painter.style.map.animationLoop.stopped()) {
                        _isLabelsCacheDirty = false;
                        _labelsCacheIndex = _labelsCache.length;
                        _seenFeatures.clear();
                        _fs = painter.style.map._queryRenderedFeaturesOld(_queryRenderedFeaturesOptions);
                        for (let i = 0, n = _fs.length; i < n; ++i) { // eslint-disable-line
                            _fsKey = `${_fs[i].layer.id}:${_fs[i].id}`;
                            if (_seenFeatures.has(_fsKey)) {
                                continue;
                            }

                            _seenFeatures.add(_fsKey);

                            if (_fs[i].geometry.type !== 'Point') {
                                // TODO: handle line labels...
                                _cachedLabelsFeatures[_fsKey] = -1;
                                continue;
                            }

                            cachedEntryIndex = _cachedLabelsFeatures[_fsKey];

                            if (cachedEntryIndex === undefined) {
                                // calculate the feature state
                                let {
                                    x: _fx,
                                    y: _fy,
                                } = painter.style.map.project(_fs[i].geometry.coordinates);
                                if (_fx === undefined || _fy === undefined) {
                                    // don't check for color
                                } else {
                                    _fx = Math.round(_fx * window.devicePixelRatio);
                                    _fy = Math.round(_fy * window.devicePixelRatio);

                                    if (_fx < 0 || _fy < 0 || _fx >= painter.gl.drawingBufferWidth || _fy >= painter.gl.drawingBufferHeight) {
                                        // feature is off screen, don't check for color
                                    } else {
                                        painter.gl.readPixels(_fx - (BUFFER_WIDTH / 2), painter.gl.drawingBufferHeight - (_fy - (BUFFER_HEIGHT / 2)), BUFFER_WIDTH, BUFFER_HEIGHT, painter.gl.RGBA, painter.gl.UNSIGNED_BYTE, _mapPixelsWithoutLabels);

                                        avg = 0;
                                        skippedCount = 0;
                                        for (let j = 0; j < BUFFER_HEIGHT; j++) { // eslint-disable-line
                                            for (let k = 0; k < BUFFER_WIDTH; k++) { // eslint-disable-line
                                                const pos = (j * PIXEL_ARRAY_ROW_SIZE) + (4 * k);
                                                if (_mapPixelsWithoutLabels[pos + 3] === 0) {
                                                    // background color is transparent, skip it
                                                    skippedCount++; // eslint-disable-line
                                                    continue;
                                                }
                                                avg += ((_mapPixelsWithoutLabels[pos] * 299) + (_mapPixelsWithoutLabels[pos + 1] * 587) + (_mapPixelsWithoutLabels[pos + 2] * 114)) / 1000 > 130 ? 1 : 0;
                                            }
                                        }

                                        if (skippedCount <= PIXEL_ARRAY_LENGTH / 3) {
                                            const rgbComponents = parseCSSColor.parseCSSColor((_fs[i].layer.paint || {})['text-color'] || '#000');
                                            const isTextColorLight = ((rgbComponents[0] * 299) + (rgbComponents[1] * 587) + (rgbComponents[2] * 114)) / 1000 > 130;

                                            if (avg / (PIXEL_ARRAY_LENGTH - skippedCount) <= 0.5) {
                                                // background color is dark
                                                if (!isTextColorLight) {
                                                    // text color for the feature is dark, but it should be light
                                                    _labelsCache.push({
                                                        id: _fs[i].id,
                                                        source: _fs[i].layer.source,
                                                        sourceLayer: _fs[i].layer['source-layer'],
                                                        layer: {
                                                            id: _fs[i].layer.id,
                                                            type: 'symbol',
                                                        },
                                                    });
                                                    _labelsStylesCache.push({ ...dynamicContrastLightLabelStyle });
                                                    _cachedLabelsFeatures[_fsKey] = _labelsCacheIndex;
                                                    _labelsCacheIndex++; // eslint-disable-line
                                                } else {
                                                    _cachedLabelsFeatures[_fsKey] = -1;
                                                }
                                            } else if (isTextColorLight) {
                                                // text color for the feature is light, but it should be dark...
                                                _labelsCache.push({
                                                    id: _fs[i].id,
                                                    source: _fs[i].layer.source,
                                                    sourceLayer: _fs[i].layer['source-layer'],
                                                    layer: {
                                                        id: _fs[i].layer.id,
                                                        type: 'symbol',
                                                    },
                                                });

                                                _labelsStylesCache.push({ ...dynamicContrastDarkLabelStyle });
                                                _cachedLabelsFeatures[_fsKey] = _labelsCacheIndex;
                                                _labelsCacheIndex++; // eslint-disable-line
                                            } else {
                                                _cachedLabelsFeatures[_fsKey] = -1;
                                            }
                                        } else {
                                            // feature has mostly transparent background, so don't do anything...
                                            _cachedLabelsFeatures[_fsKey] = -1;
                                        }
                                    }
                                }
                            }
                        }

                        this._labelFeatures = _labelsCache;
                        this._labelFeaturesStyles = _labelsStylesCache;

                        painter.setLabelFeaturesStyles(this._labelFeatures, this._labelFeaturesStyles, false);
                    }
                }

                return painter._oldRenderLayer.call(painter, painter, sourceCache, layer, coords); // eslint-disable-line
            };
            /* End of monkey-patch */
        }
    }

    remove() {
        this._mapViewer.dragonflyMap.off('zoom', this.clearLabelsCache);
        this._mapViewer.dragonflyMap.off('sourcedata', this.clearLabelsCache);
        this._mapViewer.dragonflyMap.off('zoomend', this.clearLabelsCache);
        this._mapViewer.dragonflyMap.off('moveend', this.touchLabelsCache);
        this._mapViewer.dragonflyMap.off('move', this.touchLabelsCache);
        this._mapViewer.dragonflyMap.off('styledata', this.clearLabelsCache);

        this.bus.off('MAP_NEW_DATA_THEME_APPLIED', this.clearLabelsCache);
        this.bus.off('MAP_NEW_COLOR_PALETTE_APPLIED', this.clearLabelsCache);
        this.bus.off('MAP_NEW_CUTPOINTS_APPLIED', this.clearLabelsCache);
        this.bus.off('MAP_PREFERRED_SUMMARY_LEVEL_CHANGED', this.clearLabelsCache);
        this.bus.off('MAP_SATELLITE_LAYER_VISIBILITY_CHANGED', this.clearLabelsCache);
        this.bus.off('MAP_LAYERS_OVERRIDES_APPLIED', this.clearLabelsCache);
        this.bus.off('MAP_MASKING_FILTER_APPLIED', this.clearLabelsCache);
        this.bus.off('MAP_MASKING_FILTER_REMOVED', this.clearLabelsCache);

        // revert the monkey patch...
        this._mapViewer.dragonflyMap.painter.setFeaturesStyles = this._mapViewer.dragonflyMap.painter._oldSetFeaturesStyles;
        this._mapViewer.dragonflyMap.painter.clearFeaturesStyles = this._mapViewer.dragonflyMap.painter._oldClearFeaturesStyles;
        this._mapViewer.dragonflyMap.painter.renderLayer = this._mapViewer.dragonflyMap.painter._oldRenderLayer;

        delete this._mapViewer.dragonflyMap.painter._oldSetFeaturesStyles;
        delete this._mapViewer.dragonflyMap.painter._oldClearFeaturesStyles;
        delete this._mapViewer.dragonflyMap.painter._oldRenderLayer;

        delete this._mapViewer.dragonflyMap.painter.setLabelFeaturesStyles;
        delete this._mapViewer.dragonflyMap.painter.clearLabelFeaturesStyles;
        delete this._mapViewer.dragonflyMap.painter._originalFeatures;
        delete this._mapViewer.dragonflyMap.painter._originalStyles;
        delete this._mapViewer.dragonflyMap.painter._labelFeatures;
        delete this._mapViewer.dragonflyMap.painter._labelStyles;
    }
}

export default DynamicLabelsContrastHandler;
