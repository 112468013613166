import GLU from '../glu2.js/src/index';

class SeApi extends GLU.Api {
  constructor(endpoint) {
    super(endpoint);

    this.createApiActions(
      {
        name: 'getSurveyGroups',
        method: GLU.Api.Get,
        path: '/explore-tables',
        credentials: true,
      },
      {
        name: 'getReportTableTemplates',
        method: GLU.Api.Get,
        path: '/report-tables-template',
        credentials: true,
      },
    );
  }
}

export default SeApi;
