'use strict';var ref = require('../segment');
var SegmentVector = ref.SegmentVector;
var VertexBuffer = require('../../gl/vertex_buffer');
var IndexBuffer = require('../../gl/index_buffer');
var ref$1 = require('../program_configuration');
var ProgramConfigurationSet = ref$1.ProgramConfigurationSet;
var createVertexArrayType = require('../vertex_array_type');
var ref$2 = require('../index_array_type');
var LineIndexArray = ref$2.LineIndexArray;
var TriangleIndexArray = ref$2.TriangleIndexArray;
var loadGeometry = require('../load_geometry');
var earcut = require('earcut');
var classifyRings = require('../../util/classify_rings');
var EARCUT_MAX_RINGS = 500;
var clamp = require('../../util/util').clamp;
var lineclip = require('lineclip');
var EXTENT = require('../extent');
var fillInterface = {
    layoutAttributes: [{
            name: 'a_pos',
            components: 2,
            type: 'Int16'
        }],
    indexArrayType: TriangleIndexArray,
    indexArrayType2: LineIndexArray,
    paintAttributes: [
        { property: 'fill-color' },
        { property: 'fill-outline-color' },
        { property: 'fill-opacity' }
    ]
};
var LayoutVertexArrayType = createVertexArrayType(fillInterface.layoutAttributes);
var FillBucket = function FillBucket(options) {
    this.zoom = options.zoom;
    this.overscaling = options.overscaling;
    this.layers = options.layers;
    this.index = options.index;
    this.selectionMode = options.selectionMode;
    this.coord = options.coord;
    this.layoutVertexArray = new LayoutVertexArrayType(options.layoutVertexArray);
    this.indexArray = new TriangleIndexArray(options.indexArray);
    this.indexArray2 = new LineIndexArray(options.indexArray2);
    this.programConfigurations = new ProgramConfigurationSet(fillInterface, options.layers, options.zoom, options.programConfigurations);
    this.segments = new SegmentVector(options.segments);
    this.segments2 = new SegmentVector(options.segments2);
};
FillBucket.prototype.populate = function populate(features, options) {
    var this$1 = this;
    var autoSourceFilter;
    if (this.layers[0].autoSource) {
        for (var i = 0, list = this$1.layers[0].autoSource; i < list.length; i += 1) {
            var autoLayer = list[i];
            if (autoLayer.minzoom <= this$1.zoom && autoLayer.maxzoom > this$1.zoom && autoLayer.filter) {
                autoSourceFilter = autoLayer.filter;
                break;
            }
        }
    }
    for (var i$1 = 0, list$1 = features; i$1 < list$1.length; i$1 += 1) {
        var ref = list$1[i$1];
        var feature = ref.feature;
        var index = ref.index;
        var sourceLayerIndex = ref.sourceLayerIndex;
        if (autoSourceFilter && !autoSourceFilter({ zoom: this$1.zoom }, feature)) {
            continue;
        }
        if (this$1.layers[0]._featureFilter({ zoom: this$1.zoom }, feature)) {
            var geometry = loadGeometry(feature);
            this$1.addFeature(feature, geometry);
            options.featureIndex.insert(feature, geometry, index, sourceLayerIndex, this$1.index);
        }
    }
};
FillBucket.prototype.isEmpty = function isEmpty() {
    return this.layoutVertexArray.length === 0;
};
FillBucket.prototype.serialize = function serialize(transferables) {
    return {
        zoom: this.zoom,
        layerIds: this.layers.map(function (l) {
            return l.id;
        }),
        layoutVertexArray: this.layoutVertexArray.serialize(transferables),
        indexArray: this.indexArray.serialize(transferables),
        indexArray2: this.indexArray2.serialize(transferables),
        programConfigurations: this.programConfigurations.serialize(transferables),
        segments: this.segments.get(),
        segments2: this.segments2.get()
    };
};
FillBucket.prototype.upload = function upload(gl) {
    this.layoutVertexBuffer = new VertexBuffer(gl, this.layoutVertexArray);
    this.indexBuffer = new IndexBuffer(gl, this.indexArray);
    this.indexBuffer2 = new IndexBuffer(gl, this.indexArray2);
    this.programConfigurations.upload(gl);
};
FillBucket.prototype.destroy = function destroy() {
    if (!this.layoutVertexBuffer) {
        return;
    }
    this.layoutVertexBuffer.destroy();
    this.indexBuffer.destroy();
    this.indexBuffer2.destroy();
    this.programConfigurations.destroy();
    this.segments.destroy();
    this.segments2.destroy();
};
FillBucket.prototype.polygonArea = function polygonArea(geometry) {
    var area = 0;
    for (var g = 0; g < geometry.length; g++) {
        var vs = geometry[g];
        var mappedArray = [];
        for (var l = 0; l < vs.length; l++) {
            mappedArray.push([
                vs[l].x,
                vs[l].y
            ]);
        }
        var clippedGeometry = lineclip.polygon(mappedArray, [
            0,
            0,
            EXTENT,
            EXTENT
        ]);
        var lastIndex = clippedGeometry.length - 1;
        if (clippedGeometry.length > 0 && (clippedGeometry[0][0] !== clippedGeometry[lastIndex][0] || clippedGeometry[0][1] !== clippedGeometry[lastIndex][1])) {
            clippedGeometry.push(clippedGeometry[0]);
        }
        for (var i = 0; i < clippedGeometry.length - 1; i++) {
            area += clippedGeometry[i][0] * clippedGeometry[i + 1][1] - clippedGeometry[i + 1][0] * clippedGeometry[i][1];
        }
    }
    return Math.abs(area / 2);
};
FillBucket.prototype.addFeature = function addFeature(feature, geometry) {
    var this$1 = this;
    this.metadata = this.metadata || { features: {} };
    var fillElementLengthBefore = this.indexArray.length;
    var fillSecondElementLengthBefore = this.indexArray2.length;
    for (var i$2 = 0, list = classifyRings(geometry, EARCUT_MAX_RINGS); i$2 < list.length; i$2 += 1) {
        var polygon = list[i$2];
        var numVertices = 0;
        for (var i$3 = 0, list$1 = polygon; i$3 < list$1.length; i$3 += 1) {
            var ring = list$1[i$3];
            numVertices += ring.length;
        }
        var triangleSegment = this$1.segments.prepareSegment(numVertices, this$1.layoutVertexArray, this$1.indexArray);
        var triangleIndex = triangleSegment.vertexLength;
        var flattened = [];
        var holeIndices = [];
        for (var i$4 = 0, list$2 = polygon; i$4 < list$2.length; i$4 += 1) {
            var ring$1 = list$2[i$4];
            if (ring$1.length === 0) {
                continue;
            }
            if (ring$1 !== polygon[0]) {
                holeIndices.push(flattened.length / 2);
            }
            var lineSegment = this$1.segments2.prepareSegment(ring$1.length, this$1.layoutVertexArray, this$1.indexArray2);
            var lineIndex = lineSegment.vertexLength;
            this$1.layoutVertexArray.emplaceBack(ring$1[0].x, ring$1[0].y);
            this$1.indexArray2.emplaceBack(lineIndex + ring$1.length - 1, lineIndex);
            flattened.push(ring$1[0].x);
            flattened.push(ring$1[0].y);
            for (var i = 1; i < ring$1.length; i++) {
                this$1.layoutVertexArray.emplaceBack(ring$1[i].x, ring$1[i].y);
                this$1.indexArray2.emplaceBack(lineIndex + i - 1, lineIndex + i);
                flattened.push(ring$1[i].x);
                flattened.push(ring$1[i].y);
            }
            lineSegment.vertexLength += ring$1.length;
            lineSegment.primitiveLength += ring$1.length;
        }
        var indices = earcut(flattened, holeIndices);
        for (var i$1 = 0; i$1 < indices.length; i$1 += 3) {
            this$1.indexArray.emplaceBack(triangleIndex + indices[i$1], triangleIndex + indices[i$1 + 1], triangleIndex + indices[i$1 + 2]);
        }
        triangleSegment.vertexLength += numVertices;
        triangleSegment.primitiveLength += indices.length / 3;
    }
    this.programConfigurations.populatePaintArrays(this.layoutVertexArray.length, feature);
    this.metadata.features[feature.id] = {};
    var fillElementLengthAfter = this.indexArray.length;
    var fillSecondElementLengthAfter = this.indexArray2.length;
    if (this.selectionMode) {
        var scale = EXTENT / feature.extent;
        var areaScale = Math.pow(2, 2 * (19 - this.zoom) + 1) * scale;
        var featureArea = typeof feature.properties.__area__ === 'number' ? feature.properties.__area__ : 0;
        var tileApsoluteX = (this.coord.x << 30 - this.coord.z) * 4 + (-1 << 31);
        var tileApsoluteY = (this.coord.y << 30 - this.coord.z) * 4 + (-1 << 31);
        this.metadata.features[feature.id].areaTotal = featureArea / areaScale;
        this.metadata.features[feature.id].areaPartial = this.polygonArea(geometry) / scale;
        this.metadata.features[feature.id].relativeXmin = (feature.properties.__xmin__ - tileApsoluteX >> 20 - this.coord.z) * scale;
        this.metadata.features[feature.id].relativeYmin = (feature.properties.__ymin__ - tileApsoluteY >> 20 - this.coord.z) * scale;
        this.metadata.features[feature.id].relativeXmax = (feature.properties.__xmax__ - tileApsoluteX >> 20 - this.coord.z) * scale;
        this.metadata.features[feature.id].relativeYmax = (feature.properties.__ymax__ - tileApsoluteY >> 20 - this.coord.z) * scale;
    }
    this.metadata.features[feature.id].fillElement = {
        count: fillElementLengthAfter - fillElementLengthBefore,
        offset: fillElementLengthBefore
    };
    this.metadata.features[feature.id].fillSecondElement = {
        count: fillSecondElementLengthAfter - fillSecondElementLengthBefore,
        offset: fillSecondElementLengthBefore
    };
};
FillBucket.programInterface = fillInterface;
module.exports = FillBucket;