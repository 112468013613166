// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import LayerLibraryGroup from './LayerLibraryGroup';
import BaseMap from '../../../enums/BaseMapTypes';

const CUSTOM_LIBRARY_LAYER_IDS = ['school_digger', 'opportunity_zones', BaseMap.STREET];

 /**
  * @param {object} param0
  * @param {import('../../../').LayerDataGroupSHOULD_BE_REFACTORED[]} param0.libraryGroups
  * @param {string} param0.mapInstanceId
  * @param {string|undefined} param0.className
 */
const LayerLibraryLegend = ({ libraryGroups, mapInstanceId, className }) => {
    const groupItems = libraryGroups
        .filter(lg => CUSTOM_LIBRARY_LAYER_IDS.indexOf(lg.id) === -1)
        .map(layerGroup => {
            const { id, metadata, layers } = layerGroup;
            return (
                <LayerLibraryGroup
                    key={id}
                    layers={layers}
                    layerLibraryGroupId={id}
                    mapInstanceId={mapInstanceId}
                    layerGroupMetadata={metadata}
                />
            );
        });

    if (groupItems.length === 0) return null;

    const layerLibraryClasses = classNames(
        'layer-library-legend',
        className,
    );

    return <div className={layerLibraryClasses}>{groupItems}</div>;
};

LayerLibraryLegend.propTypes = {
    libraryGroups: PropTypes.array.isRequired,
    mapInstanceId: PropTypes.string.isRequired,
    className: PropTypes.string,
};

LayerLibraryLegend.defaultProps = {
    className: undefined,
};

export default LayerLibraryLegend;
