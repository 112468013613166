import GLU from '../glu2.js/src/index';

class HereGeocodeApi extends GLU.Api {
  constructor(endpoint) {
    super(endpoint);

    this.createApiActions({
      name: 'geocode',
      method: GLU.Api.Get,
      path: '/geocode',
      credentials: false,
    });
  }
}

export default HereGeocodeApi;
