import React from 'react';
import PropTypes from 'prop-types';

class RecentSearchListItem extends React.Component {
    handleClick = () => {
        this.props.onClick(this.props.value);
    };

    render() {
        return (
            <li
                className="search-results__list-item search-results__list-item--recent"
                onClick={this.handleClick}
            >
                {this.props.value}
            </li>
        );
    }
}

RecentSearchListItem.propTypes = {
    onClick: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
};

export default RecentSearchListItem;
