/* eslint react/no-array-index-key: 0 */
import React from 'react';
import BusComponent from '../BusComponent';
import ColorPicker from '../colorPickerCompact/ColorPicker';

class RampRow extends BusComponent {

    onApplyStartColor = color => {
        this.props.onApplyStartColor(color, this.props.index);
    };

    onApplyEndColor = color => {
        this.props.onApplyEndColor(color, this.props.index);
    };

    onFlipColors = () => {
        this.props.onFlipColors(this.props.index);
    };

    render() {
        const dropdownStartColorPicker = (
            <ColorPicker
                onChange={this.onApplyStartColor}
                color={this.props.ramp.from}
            />
        );
        const colors = this.props.ramp.interpolateColors(11).map((c, i) => (<div
            key={i} className="color-palette-sample__block"
            style={{ backgroundColor: `${c}` }}
        />));
        const colorRampSample = <div className="color-palette-sample">{colors}</div>;

        const dropdownEndColorPicker = (
            <ColorPicker
                onChange={this.onApplyEndColor}
                color={this.props.ramp.to}
            />
        );

        const flipColors = (
            <button className="btn-raised" onClick={this.onFlipColors}>
                <i className="material-icons">swap_horiz</i>
                <span className="btn-raised__text">Flip</span>
            </button>
        );
        return (<div className="ramp-row">
            {dropdownStartColorPicker}
            {colorRampSample}
            {dropdownEndColorPicker}
            {flipColors}
        </div>);
    }
}

export default RampRow;
