import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import AnnotationSelectionHeaderOptions from './AnnotationSelectionHeaderOptions';
import Tooltip from '../Tooltip';

const AnnotationSelectionHeader = props => {
    const {
        numberOfAllAnnotations,
        numberOfSelectedAnnotations,
        isStorageAvailable,
        pasteDisabled,
        onAnnotationsClearSelection,
        onAnnotationsDelete,
        onAnnotationsCopy,
        onAnnotationsPaste,
        onAnnotationsDuplicate,
        intl,
    } = props;

    let annotationSelectionInfo;
    if (numberOfAllAnnotations === numberOfSelectedAnnotations) annotationSelectionInfo = 'All';
    else annotationSelectionInfo = `${numberOfSelectedAnnotations} selected`;

    return (
        <div className="panel__header annotation-selection-header flex-it center space-between">
            <div className="flex-it center">
                <Tooltip
                    placement="bottom"
                    mouseEnterDelay={0.5}
                    mouseLeaveDelay={0}
                    overlay={intl.formatMessage({ id: 'close' })}
                >
                    <button
                        className="btn-icon btn-icon--light"
                        onClick={onAnnotationsClearSelection}
                    >
                        <i className="material-icons" aria-label={intl.formatMessage({ id: 'close' })}>clear</i>
                    </button>
                </Tooltip>
                <div className="annotation-selection-header__number-of-items">
                    <h4 className="light-text">Select annotations</h4>
                    <h6 className="light-text">{annotationSelectionInfo}</h6>
                </div>
            </div>
            <AnnotationSelectionHeaderOptions
                deleteDisabled={numberOfSelectedAnnotations === 0}
                copyDisabled={numberOfSelectedAnnotations === 0 || !isStorageAvailable}
                pasteDisabled={pasteDisabled}
                duplicateDisabled={numberOfSelectedAnnotations === 0}
                onAnnotationsDelete={onAnnotationsDelete}
                onAnnotationsCopy={onAnnotationsCopy}
                onAnnotationsPaste={onAnnotationsPaste}
                onAnnotationsDuplicate={onAnnotationsDuplicate}
            />
        </div>);
};

AnnotationSelectionHeader.propTypes = {
    numberOfSelectedAnnotations: PropTypes.number.isRequired,
    numberOfAllAnnotations: PropTypes.number.isRequired,
    onAnnotationsCopy: PropTypes.func.isRequired,
    onAnnotationsDelete: PropTypes.func.isRequired,
    onAnnotationsPaste: PropTypes.func.isRequired,
    onAnnotationsDuplicate: PropTypes.func.isRequired,
    onAnnotationsClearSelection: PropTypes.func.isRequired,
    isStorageAvailable: PropTypes.bool.isRequired,
    pasteDisabled: PropTypes.bool.isRequired,
    intl: PropTypes.object.isRequired,
};
AnnotationSelectionHeader.defaultProps = {
};

export default injectIntl(AnnotationSelectionHeader);
