import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from '../form/Checkbox';
import AnnotationIcon from './AnnotationIcon';

class AnnotationSelectionCard extends React.Component {

    onAnnotationChecked = checked => {
        this.props.onAnnotationChecked(this.props.annotation.id, checked);
    }

    render() {
        return (
            <div className="annotation-card">
                <div className="annotation-card__header flex-start">
                    <Checkbox
                        className="margin-right-10"
                        checked={this.props.checked}
                        onCheck={this.onAnnotationChecked}
                    />
                    <AnnotationIcon
                        className="annotation-card__icon"
                        annotation={this.props.annotation}
                    />
                    <div className="body-1">{this.props.title}</div>
                </div>
            </div>);
    }
}

AnnotationSelectionCard.propTypes = {
    annotation: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    onAnnotationChecked: PropTypes.func.isRequired,
};
AnnotationSelectionCard.defaultProps = {
};

export default AnnotationSelectionCard;
