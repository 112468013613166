import GLU from '../glu2.js/src/index';

class MetadataApi extends GLU.Api {
  constructor(endpoint) {
    super(endpoint);

    this.createApiActions({
      name: 'getMetadata',
      method: GLU.Api.Get,
      path: '/SpiceMap/data/SeMetadata.ashx',
      credentials: false,
    });
  }
}

export default MetadataApi;
