class Curve {
    constructor(values) {
        if (values) {
            Object.keys(values).forEach(k => {
                if (this.constructor.prototype.hasOwnProperty(k)) {
                    this[k] = values[k];
                }
            });
        }
    }

    get anchorPoint1() {
        return this._anchorPoint1;
    }

    set anchorPoint1(anchorPoint1) {
        this._anchorPoint1 = anchorPoint1;
    }

    get controlPoint1() {
        return this._controlPoint1;
    }

    set controlPoint1(controlPoint1) {
        this._controlPoint1 = controlPoint1;
    }

    get anchorPoint2() {
        return this._anchorPoint2;
    }

    set anchorPoint2(anchorPoint2) {
        this._anchorPoint2 = anchorPoint2;
    }

    get controlPoint2() {
        return this._controlPoint2;
    }

    set controlPoint2(controlPoint2) {
        this._controlPoint2 = controlPoint2;
    }

    equals(that) {
        return (
        this.anchorPoint1 === that.anchorPoint1 &&
        this.controlPoint1 === that.controlPoint1 &&
        this.anchorPoint2 === that.anchorPoint2 &&
        this.controlPoint2 === that.controlPoint2);
    }

    clone() {
        return new Curve({
            anchorPoint1: this.anchorPoint1,
            controlPoint1: this.controlPoint1,
            anchorPoint2: this.anchorPoint2,
            controlPoint2: this.controlPoint2,
        });
    }

    toJSON() {
        return {
            anchorPoint1: this.anchorPoint1,
            controlPoint1: this.controlPoint1,
            anchorPoint2: this.anchorPoint2,
            controlPoint2: this.controlPoint2,
        };
    }
}

export default Curve;
