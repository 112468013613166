import dragonlfy from 'dragonfly-v3';

class LocationData {
    constructor() {
        const userLocationMarkerDiv = window.document.createElement('div');
        userLocationMarkerDiv.className = 'user-location-marker';

        // This is the pin that we are showing when a user requests from us
        // to show his/her current position on the map by clicking on the
        // 'Your location' button above the zoom element
        this._userLocationMarker = new dragonlfy.Marker(userLocationMarkerDiv);
    }

    get userLocationMarker() {
        return this._userLocationMarker;
    }
}

export default LocationData;
