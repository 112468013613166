// @ts-check
import React from 'react';
import PropTypes from 'prop-types';

/**
 *
 * @param {object} props
 * @param {JSX.Element[]} props.children
 */
const LocationActions = ({ children }) => (
    <div className="flex-it center">{children}</div>
);

LocationActions.propTypes = {
    children: PropTypes.node.isRequired,
};

export default LocationActions;
