import React from 'react';
import classNames from 'classnames';

class TouchPanHelp extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = { showPanHelp: false, touchesCount: 0 };
    }

    componentDidMount() {
        document.addEventListener('touchstart', this.handleTouchStart);
        document.addEventListener('touchmove', this.handleTouchMove, { passive: false });
        document.addEventListener('touchend', this.handleTouchEnd);
    }

    componentWillUnmount() {
        document.removeEventListener('touchstart', this.handleTouchStart);
        document.removeEventListener('touchmove', this.handleTouchMove, { passive: false });
        document.removeEventListener('touchend', this.handleTouchEnd);
    }

    handleTouchEnd = () => {
        this.setState({ showPanHelp: false, touchesCount: 0 });
    }

    handleTouchStart = event => {
        let { touchesCount } = this.state;
        // count up the number of touches
        // finger after finger, each event one finger (event.touches.length === 1)
        // or many fingers in a single event (event.touches.length > 1)
        const count = event.touches.length;
        if (count > 1) {
            touchesCount = count;
        } else {
            touchesCount += count;
        }

        this.setState({ touchesCount });
    }

    handleTouchMove = event => {
        const { touchesCount } = this.state;

        let showPanHelp;
        if (touchesCount === 1) {
            // if user is touching with one finger and help is not displayed yet,
            // do show help message
            showPanHelp = true;
        } else {
            // prevent the native browser scroll while user is multi-touching
            event.preventDefault();
            // make sure that help message is removed once multi-touch is active
            showPanHelp = false;
        }
        if (event.srcElement.nodeName !== 'CANVAS') showPanHelp = false;
        this.setState({ showPanHelp });
    }

    render() {
        const classes = classNames('touch-pan-help', {
            'touch-pan-help--displayed': this.state.showPanHelp,
        });
        return (
            <div className={classes}>
                Use two fingers to move the map
            </div>
        );
    }
}

export default TouchPanHelp;
