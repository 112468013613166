import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import DataCategoryAvailableIn from './DataCategoryAvailableIn';

const AvailableYearsLinks = props => {
    const { dataCategory, onSelectedDataCategoryChange } = props;

    const availableYears = dataCategory.availableYears || [];
    const availableYearsLinks = availableYears.map((year, index) =>
        <DataCategoryAvailableIn
            key={year}
            dataCategory={dataCategory}
            year={year}
            onSelectedDataCategoryChange={onSelectedDataCategoryChange}
            lastElement={index === availableYears.length - 1}
        />);

    return (<span className="data-category__years">{props.intl.formatMessage({ id: 'variablePicker.availableIn' })} {availableYearsLinks}</span>);
};

AvailableYearsLinks.propTypes = {
    onSelectedDataCategoryChange: PropTypes.func.isRequired,
    dataCategory: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(AvailableYearsLinks);
