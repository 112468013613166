import React from 'react';
import PropTypes from 'prop-types';
import AppConfig from '../../../appConfig';

const MobileMenu = ({
    title,
    children,
    open,
    onCloseRequest,
    onOpenRequest,
}) => (
    <div className="map-mobile-menu">
        <button className="map-mobile-menu__trigger btn-icon" onClick={onOpenRequest}>
            <i className="material-icons">menu</i>
        </button>
        {open &&
            <div className="map-mobile-menu__menu">
                <div className="map-mobile-menu__header">
                    <div className="flex-it center">
                        <img className="map-mobile-menu__logo"src={`${AppConfig.constants.assetsBaseURL}/svg/logo.svg`} alt={title} />
                        <div className="map-mobile-menu__title">{title}</div>
                    </div>
                    <button className="btn-icon" onClick={onCloseRequest}>
                        <i className="material-icons">close</i>
                    </button>
                </div>
                <div className="map-mobile-menu__content">
                    {children}
                </div>
            </div>}
    </div>
);


MobileMenu.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.any.isRequired,
    open: PropTypes.bool.isRequired,
    onCloseRequest: PropTypes.func.isRequired,
    onOpenRequest: PropTypes.func.isRequired,
};

MobileMenu.defaultProps = {
    open: false,
};

export default MobileMenu;
