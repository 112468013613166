import DragonflySourceLayerDataset from './DragonflySourceLayerDataset';

class DragonflySourceLayer {
    constructor(id, datasets) {
        this._id = id;
        if (datasets) {
            this._datasets = datasets;
        } else {
            this._datasets = {
                0: new DragonflySourceLayerDataset(0),
            };
        }
    }

    get id() {
        return this._id;
    }

    get datasets() {
        return this._datasets;
    }

    getDataset(id) {
        if (this._datasets[id] === undefined) {
            this._datasets[id] = new DragonflySourceLayerDataset(id);
        }

        return this._datasets[id];
    }

    createDataset(id) {
        return this.getDataset(id);
    }

    merge(other) {
        Object.keys(other.datasets).forEach(datasetId => {
            if (this._datasets[datasetId] === undefined) {
                this._datasets[datasetId] = other.datasets[datasetId];
            } else {
                this._datasets[datasetId].merge(other.datasets[datasetId]);
            }
        });

        return this;
    }

    remove(other) {
        Object.keys(other.datasets).forEach(datasetId => {
            if (this._datasets[datasetId] !== undefined) {
                this._datasets[datasetId].remove(other.datasets[datasetId]);
            }

            if (this._datasets[datasetId].columns.length === 0) {
                delete this._datasets[datasetId];
            }
        });

        return this;
    }

    clone() {
        return new DragonflySourceLayer(this._id,
            Object.keys(this._datasets).reduce((datasets, v) => {
                datasets[v] = this._datasets[v].clone();
                return datasets;
            }, {}));
    }

    equals(that) {
        return (
          this.id === that.id &&
          Object.keys(this._datasets).length === Object.keys(that.datasets).length &&
          Object.keys(this._datasets).every(datasetId => this._datasets[datasetId].equals(that.datasets[datasetId])));
    }

    toJSON() {
        const layer = {
            layerId: this._id,
        };

        const datasets = Object.keys(this._datasets).map(datasetId => this._datasets[datasetId].toJSON());
        if (datasets.length > 0) {
            layer.datasets = datasets;
        }

        return layer;
    }
}

export default DragonflySourceLayer;
