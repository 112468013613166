import GLU from '../glu2.js/src/index';

class UserDataApi extends GLU.Api {
    constructor(endpoint) {
        super(endpoint);

        this.createApiActions(
            {
                name: 'getUserDataUploadUrl',
                method: GLU.Api.Post,
                path: '/upload-url',
                credentials: true,
            },
            {
                name: 'getUserData',
                method: GLU.Api.Get,
                path: '',
                credentials: true,
            },
            {
                name: 'addUserData',
                method: GLU.Api.Post,
                path: '/{userDataId}',
                credentials: true,
            },
            {
                name: 'deleteUserData',
                method: GLU.Api.Delete,
                path: '/{userDataId}',
                credentials: true,
            },
            {
                name: 'getUserDataById',
                method: GLU.Api.Get,
                path: '/{userDataId}',
                credentials: true,
            },
        );
    }
}

export default UserDataApi;
