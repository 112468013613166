const filterCombiner = {
    MATCH_ALL: {
        text: 'dataBrowser.matchAll',
        type: 'all',
    },
    MATCH_ANY: {
        text: 'dataBrowser.matchAny',
        type: 'any',
    },
    MATCH_NONE: {
        text: 'dataBrowser.matchNone',
        type: 'none',
    },
};

export default filterCombiner;
