class FilterSet {
    constructor(values) {
        if (values) {
            Object.keys(values).forEach(k => {
                if (this.constructor.prototype.hasOwnProperty(k)) {
                    this[k] = values[k];
                }
            });
        }
    }

    get valueFormat() {
        return this._valueFormat;
    }

    set valueFormat(valueFormat) {
        this._valueFormat = valueFormat;
    }

    get filters() {
        return this._filters;
    }

    set filters(filters) {
        this._filters = filters;
    }

    get length() {
        return this._filters.length;
    }

    equals(that) {
        return this.length === that.length &&
            this.valueFormat === that.valueFormat &&
            !this.filters.find((f, idx) => !that.filters[idx] || !f.equals(that.filters[idx]));
    }

    clone() {
        return new FilterSet({
            valueFormat: this.valueFormat,
            filters: this.filters.map(f => f.clone()),
        });
    }

    toJSON() {
        return {
            valueFormat: this.valueFormat,
            filters: this.filters.map(filter => filter.toJSON()),
        };
    }
}

export default FilterSet;
