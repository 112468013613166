import { parseFieldName } from '../helpers/Util';

class FieldList {
    get id() {
        return this._id;
    }

    set id(id) {
        this._id = id;
    }

    /** @type {import('./FieldListField').default[]} */
    get fields() {
        return this._fields;
    }

    set fields(fields) {
        this._fields = fields;
    }

    calculateComputed(data) {
        this.fields.forEach(field => {
            if (!field.isComputed) return;
            const fieldNumerator = data[parseFieldName(field.fieldNumerator).variableGuid];
            if (fieldNumerator === undefined) return;
            let computedValue = 0, fieldDenominator, multiplier, fieldNumeratorParent, fieldDenominatorParent;
            switch (field.computeFunction) {
            case 'COMPUTE_RATIO':
                fieldDenominator = data[parseFieldName(field.fieldDenominator).variableGuid];
                if (fieldDenominator === undefined || (fieldNumerator === 0 && fieldDenominator === 0)) computedValue = undefined;
                else if (fieldDenominator !== 0) computedValue = fieldNumerator / fieldDenominator;
                break;
            case 'COMPUTE_PERCENT':
                fieldDenominator = data[parseFieldName(field.fieldDenominator).variableGuid];
                if (fieldDenominator === undefined || (fieldNumerator === 0 && fieldDenominator === 0)) computedValue = undefined;
                else if (fieldDenominator !== 0) computedValue = (fieldNumerator * 100) / fieldDenominator;
                break;
            case 'COMPUTE_MULTIPLY':
                multiplier = field.fieldMultiplier;
                if (multiplier === undefined || isNaN(multiplier)) computedValue = undefined;
                else computedValue = fieldNumerator * multiplier;
                break;
            case 'COMPUTE_CHANGE':
                multiplier = field.fieldMultiplier;
                if (isNaN(multiplier)) multiplier = 1;
                fieldDenominator = data[parseFieldName(field.fieldDenominator).variableGuid];
                computedValue = fieldNumerator - (fieldDenominator * multiplier);
                break;
            case 'COMPUTE_CHANGE_PERCENT':
                multiplier = field.fieldMultiplier;
                fieldDenominator = data[parseFieldName(field.fieldDenominator).variableGuid];
                if (isNaN(multiplier)) multiplier = 1;
                else if (fieldDenominator === undefined || (fieldNumerator === 0 && fieldDenominator === 0)) computedValue = undefined;
                else {
                    fieldDenominator = data[parseFieldName(field.fieldDenominator).variableGuid];
                    const adjustedFieldDenominator = fieldDenominator * multiplier;
                    computedValue = ((fieldNumerator - adjustedFieldDenominator) * 100) / adjustedFieldDenominator;
                }
                break;
            case 'COMPUTE_CHANGE_COMPOSITION':
                fieldDenominator = data[parseFieldName(field.fieldDenominator).variableGuid];
                fieldNumeratorParent = data[parseFieldName(field.fieldNumeratorParent).variableGuid];
                fieldDenominatorParent = data[parseFieldName(field.fieldDenominatorParent).variableGuid];
                if (fieldDenominatorParent === undefined ||
                    fieldNumeratorParent === undefined ||
                    (fieldNumerator === 0 && fieldNumeratorParent === 0) ||
                    (fieldDenominator === 0 || fieldDenominatorParent === 0)) {
                    computedValue = undefined;
                } else if (fieldNumeratorParent !== 0 && fieldDenominatorParent !== 0) {
                    const numeratorPercent = (fieldNumerator * 100) / fieldNumeratorParent;
                    const denominatorPercent = (fieldDenominator * 100) / fieldDenominatorParent;
                    computedValue = numeratorPercent - denominatorPercent;
                }
                break;
            default:
                computedValue = 0;
            }
            data[field.fieldName] = computedValue;
        });
    }

    equals(that) {
        return (this.id === that.id &&
        this.fields.every((field, index) => field.equals(that.fields[index])));
    }

    clone() {
        const fieldList = new FieldList();
        fieldList.id = this.id;
        fieldList.fields = this.fields.map(field => field.clone());

        return fieldList;
    }

    toJSON() {
        return {
            id: this.id,
            fields: this.fields.map(field => field.toJSON()),
        };
    }
}

export default FieldList;
