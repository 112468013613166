'use strict';var Scope = require('./scope');
var ref = require('./types');
var checkSubtype = ref.checkSubtype;
var ParsingError = require('./parsing_error');
var Literal = require('./definitions/literal');
var ParsingContext = function ParsingContext(definitions, path, expectedType, scope, errors) {
    if (path === void 0)
        path = [];
    if (scope === void 0)
        scope = new Scope();
    if (errors === void 0)
        errors = [];
    this.definitions = definitions;
    this.path = path;
    this.key = path.map(function (part) {
        return '[' + part + ']';
    }).join('');
    this.scope = scope;
    this.errors = errors;
    this.expectedType = expectedType;
};
ParsingContext.prototype.parse = function parse(expr, index, expectedType, bindings) {
    var context = this;
    if (index) {
        context = context.concat(index, expectedType, bindings);
    }
    if (expr === null || typeof expr === 'string' || typeof expr === 'boolean' || typeof expr === 'number') {
        expr = [
            'literal',
            expr
        ];
    }
    if (Array.isArray(expr)) {
        if (expr.length === 0) {
            return context.error('Expected an array with at least one element. If you wanted a literal array, use ["literal", []].');
        }
        var op = expr[0];
        if (typeof op !== 'string') {
            context.error('Expression name must be a string, but found ' + typeof op + ' instead. If you wanted a literal array, use ["literal", [...]].', 0);
            return null;
        }
        var Expr = context.definitions[op];
        if (Expr) {
            var parsed = Expr.parse(expr, context);
            if (!parsed) {
                return null;
            }
            var expected = context.expectedType;
            var actual = parsed.type;
            if (expected) {
                var canAssert = expected.kind === 'string' || expected.kind === 'number' || expected.kind === 'boolean';
                if (canAssert && actual.kind === 'value') {
                    var Assertion = require('./definitions/assertion');
                    parsed = new Assertion(expected, [parsed]);
                } else if (expected.kind === 'color' && (actual.kind === 'value' || actual.kind === 'string')) {
                    var Coercion = require('./definitions/coercion');
                    parsed = new Coercion(expected, [parsed]);
                }
                if (context.checkSubtype(expected, parsed.type)) {
                    return null;
                }
            }
            if (!(parsed instanceof Literal) && isConstant(parsed)) {
                var ec = new (require('./evaluation_context'))();
                try {
                    parsed = new Literal(parsed.type, parsed.evaluate(ec));
                } catch (e) {
                    context.error(e.message);
                    return null;
                }
            }
            return parsed;
        }
        return context.error('Unknown expression "' + op + '". If you wanted a literal array, use ["literal", [...]].', 0);
    } else if (typeof expr === 'undefined') {
        return context.error('\'undefined\' value invalid. Use null instead.');
    } else if (typeof expr === 'object') {
        return context.error('Bare objects invalid. Use ["literal", {...}] instead.');
    } else {
        return context.error('Expected an array, but found ' + typeof expr + ' instead.');
    }
};
ParsingContext.prototype.concat = function concat(index, expectedType, bindings) {
    var path = typeof index === 'number' ? this.path.concat(index) : this.path;
    var scope = bindings ? this.scope.concat(bindings) : this.scope;
    return new ParsingContext(this.definitions, path, expectedType || null, scope, this.errors);
};
ParsingContext.prototype.error = function error(error$1) {
    var keys = [], len = arguments.length - 1;
    while (len-- > 0)
        keys[len] = arguments[len + 1];
    var key = '' + this.key + keys.map(function (k) {
        return '[' + k + ']';
    }).join('');
    this.errors.push(new ParsingError(key, error$1));
};
ParsingContext.prototype.checkSubtype = function checkSubtype$1(expected, t) {
    var error = checkSubtype(expected, t);
    if (error) {
        this.error(error);
    }
    return error;
};
module.exports = ParsingContext;
function isConstant(expression) {
    var ref = require('./compound_expression');
    var CompoundExpression = ref.CompoundExpression;
    var ref$1 = require('./is_constant');
    var isGlobalPropertyConstant = ref$1.isGlobalPropertyConstant;
    var isFeatureConstant = ref$1.isFeatureConstant;
    var Var = require('./definitions/var');
    if (expression instanceof Var) {
        return false;
    } else if (expression instanceof CompoundExpression && expression.name === 'error') {
        return false;
    }
    var literalArgs = true;
    expression.eachChild(function (arg) {
        if (!(arg instanceof Literal)) {
            literalArgs = false;
        }
    });
    if (!literalArgs) {
        return false;
    }
    return isFeatureConstant(expression) && isGlobalPropertyConstant(expression, [
        'zoom',
        'heatmap-density'
    ]);
}