import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import AnnotationIcon from '../AnnotationIcon';

const AnnotationLegend = props => {
    const items = props.annotationLegend.legendItems
        .filter(item => item.included)
        .map(item => (<li
            className="annotations-legend__item"
            key={`${item.color}-${item.order}-${item.icon}-${item.title}`}
        >
            <AnnotationIcon
                annotation={item}
                iconColor={item.color}
                className="annotations-legend__item-icon"
            />
            <span className="annotations-legend__item-title">{item.title}</span>
        </li>));

    if (props.annotationLegend.title) {
        items.push(<li className="annotations-legend__title" key="title">
            {props.annotationLegend.title}
        </li>);
    }
    const annotationsLegendClasses = classNames('annotations-legend', props.className);
    return (<ul className={annotationsLegendClasses}>{items.reverse()}</ul>);
};

AnnotationLegend.propTypes = {
    annotationLegend: PropTypes.object.isRequired,
    className: PropTypes.string,
};

AnnotationLegend.defaultProps = {
    className: undefined,
};

export default AnnotationLegend;
