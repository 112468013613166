import UserInfo from '../objects/UserInfo';

/** Helper class for User Info XML parsing */
class UserInfoXMLParser {
    /**
     * Constructs UserInfoXMLParser for the given document
     * @constructor
     * @param {XMLDocument} userInfoXMLDocument
     */
    constructor(userInfoXMLDocument) {
        this.userInfoXMLDocument = userInfoXMLDocument;
    }

    /**
     * Get the userInfo userId
     * @type {integer}
     */
    get userId() {
        const userIdNode = this.userInfoXMLDocument.getElementsByTagName('user_id')[0];
        return userIdNode ? parseInt(userIdNode.textContent, 10) : undefined;
    }

    /**
     * Get the userInfo name
     * @type {string}
     */
    get name() {
        const nameNode = this.userInfoXMLDocument.getElementsByTagName('name')[0];
        return nameNode ? nameNode.textContent : undefined;
    }

    /**
     * Get the userInfo orgName
     * @type {string}
     */
    get orgName() {
        const orgNameNode = this.userInfoXMLDocument.getElementsByTagName('org_name')[0];
        return orgNameNode ? orgNameNode.textContent : undefined;
    }

    /**
     * Get the userInfo product
     * @type {Array.<string>}
     */
    get product() {
        const productNode = this.userInfoXMLDocument.getElementsByTagName('product')[0];
        return productNode ? productNode.textContent.split(',') : undefined;
    }

    /**
     * Get the geobuffer token
     * @type {string}
     */
    get geobufferToken() {
        const geobufferTokenNode = this.userInfoXMLDocument.getElementsByTagName('geobuffer_token')[0];
        return geobufferTokenNode ? geobufferTokenNode.textContent : undefined;
    }

    /**
     * Get the Geobuffer permission level
     * @type {integer}
     */
    get geobufferPermission() {
        const geobufferPermissionNode = this.userInfoXMLDocument.getElementsByTagName('geobuffer_permission')[0];
        return geobufferPermissionNode ? parseInt(geobufferPermissionNode.textContent, 10) : undefined;
    }

    /**
     * Get the userInfo permanentCookie
     * @type {string}
     */
    get permanentCookie() {
        const permanentCookieNode = this.userInfoXMLDocument.getElementsByTagName('permanent_cookie')[0];
        return permanentCookieNode ? permanentCookieNode.textContent : undefined;
    }

    /**
     * Get the userLicenseInfo
     * @type {string}
     */
    get licenseInfo() {
        const licenseInfo = this.userInfoXMLDocument.getElementsByTagName('license_info')[0];
        return licenseInfo ? licenseInfo.textContent : undefined;
    }

    /**
     * Get the plan info text
     * @type {string}
     */
    get planInfo() {
        const planInfo = this.userInfoXMLDocument.getElementsByTagName('plan_info')[0];
        return planInfo ? planInfo.textContent : undefined;
    }

    getUserInfo() {
        const userInfo = new UserInfo();
        userInfo.userId = this.userId;
        userInfo.name = this.name;
        userInfo.orgName = this.orgName;
        userInfo.geobufferToken = this.geobufferToken;
        userInfo.geobufferPermission = this.geobufferPermission;
        userInfo.product = this.product;
        userInfo.permanentCookie = this.permanentCookie;
        userInfo.licenseInfo = this.licenseInfo;
        userInfo.planInfo = this.planInfo;
        return userInfo;
    }
}

export default UserInfoXMLParser;
