/* eslint-disable */
import AnnotationsMarkers, { basePngSize } from '../enums/AnnotationsMarkers';
import StrokeWeight from '../enums/StrokeWeight';
import MarkerSize from '../enums/MarkerSize';
import LabelSize from '../enums/LabelSize';
import { hexToRgb } from '../helpers/Util';
import { getArrowFromCurves, getFlowArrowCurves, project, unproject } from '../helpers/Bezier';

import WorkerPolyfills from './WorkerPolyfills';

export default function(self) {
    WorkerPolyfills();
    self.addEventListener('message', ev => {
        switch(ev.data.type) {
        case 'batchJob':
            self.postMessage({ data: ev.data.data[0].map(feature => generateFeatureStyle(feature, ev.data.data[1])), type: 'batchJobResult'});
            break;
        default:
            self.postMessage({ data: generateFeatureStyle(ev.data[0], ev.data[1]), type: ev.data[2]});
        }

    });
};

function calculateCurvePoints(feature) {
    const result = getArrowFromCurves(feature.curves, feature.fromWidth, feature.toWidth, feature.tipWidth, feature.createdAtZoomLevel);
    const coordinates = result.simpleCoordinates;
    if (coordinates[0] !== coordinates[coordinates.length - 1]) coordinates[coordinates.length - 1] = coordinates[0];
    return coordinates;
}

function generateFeatureStyle(feature) {
    const drawStyle = {
        id: feature.id,
        paint: {},
        layout: {},
        type: feature.type,
    };
    if (feature.minZoom !== undefined) drawStyle.minzoom = feature.minZoom;
    if (feature.maxZoom !== undefined) drawStyle.maxzoom = feature.maxZoom;
    if (feature.coordinates) drawStyle.coordinates = feature.coordinates;
    if (feature.curves) drawStyle.coordinates = calculateCurvePoints(feature);
    switch (feature.type) {
    case 'Polyline':
    case 'Freehand':
    case 'Shape':
    case 'Hotspot':
    case 'Polygon':
    case 'FlowArrow':
        if (feature.fillColor !== undefined) {
            drawStyle.paint['fill-color'] = feature.fillColor;
            drawStyle.paint['fill-outline-color'] = feature.fillColor;
        }
        if (feature.strokeColor !== undefined) drawStyle.paint['line-color'] = feature.strokeColor;
        if (feature.strokeWeight !== undefined) drawStyle.paint['line-width'] = StrokeWeight[feature.strokeWeight.toUpperCase()].value;
        if (feature.opacity !== undefined) {
            drawStyle.paint['line-opacity'] = feature.opacity;
            drawStyle.paint['fill-opacity'] = feature.useFill ? feature.opacity : 0;
            drawStyle.paint['circle-opacity'] = feature.opacity;
        }
        break;
    case 'Marker':
        const annotationMarker = AnnotationsMarkers.find(am => am.id === feature.markerPathId);
        const iconImage = `${annotationMarker.fileName}_${feature.fillColor}`;
        const currentLabelSize = LabelSize[feature.textSize.toUpperCase()].value;
        const currentMarkerSize = MarkerSize[feature.size.toUpperCase()].value;

        drawStyle.layout['icon-offset'] = annotationMarker.offset ? [0, -MarkerSize.MEDIUM.value * basePngSize] : [0, 0];

        drawStyle.layout['icon-image'] = iconImage;
        drawStyle.layout['icon-size'] = MarkerSize[feature.size.toUpperCase()].value;
        if (feature.opacity !== undefined) drawStyle.paint['icon-opacity'] = feature.opacity;

        drawStyle.paint = {
            'text-halo-color': 'rgba(255,255,255,0.5)',
            'text-halo-width': 1,
            'text-halo-blur': 1,
        };
        drawStyle.paint['text-color'] = feature.textColor;
        drawStyle.paint['text-opacity'] = feature.labelVisible ? feature.opacity : 0;
        drawStyle.paint['icon-opacity'] = feature.opacity;
        drawStyle.layout['text-size'] = LabelSize[feature.textSize.toUpperCase()].value;
        drawStyle.layout['text-field'] = feature.title;
        drawStyle.coordinates = [feature.coordinates[0]];
        let textOffset = 0;
        let additionalVerticalTextOffset = 0;
        if (!annotationMarker.offset) {
            textOffset = (((currentMarkerSize * basePngSize) / 2) / currentLabelSize) * 1.1;
        }
        switch (feature.labelPosition) {
            case 'bottom':
                if (annotationMarker.offset) {
                    textOffset = 0;
                }
                drawStyle.layout['text-offset'] = [0, textOffset];
                drawStyle.layout['text-anchor'] = 'top';
                break;
            case 'top':
                if (annotationMarker.offset) {
                    textOffset += textOffset + ((currentMarkerSize * basePngSize) / currentLabelSize);
                }
                drawStyle.layout['text-offset'] = [0, -textOffset];
                drawStyle.layout['text-anchor'] = 'bottom';
                break;
            case 'left':
                if (annotationMarker.offset) {
                    textOffset = ((currentMarkerSize * basePngSize) / 2) / currentLabelSize;
                    additionalVerticalTextOffset = -((currentMarkerSize * basePngSize) / currentLabelSize) / 2;
                }
                drawStyle.layout['text-offset'] = [-textOffset, additionalVerticalTextOffset];
                drawStyle.layout['text-anchor'] = 'right';
                break;
            case 'right':
                if (annotationMarker.offset) {
                    textOffset = ((currentMarkerSize * basePngSize) / 2) / currentLabelSize;
                    additionalVerticalTextOffset = -((currentMarkerSize * basePngSize) / currentLabelSize) / 2;
                }
                drawStyle.layout['text-offset'] = [textOffset, additionalVerticalTextOffset];
                drawStyle.layout['text-anchor'] = 'left';
                break;
        }
        break;
    case 'Label':
        drawStyle.layout['text-field'] = feature.title;
        drawStyle.layout['text-size'] = LabelSize[feature.textSize.toUpperCase()].value;
        drawStyle.paint['text-color'] = feature.textColor;
        drawStyle.layout['text-rotate'] = feature.rotationAngle;
        if (feature.useFill) {
            const rgbColors = hexToRgb(feature.fillColor);
            drawStyle.paint['text-halo-color'] = `rgba(${rgbColors.r}, ${rgbColors.g}, ${rgbColors.b}, 1)`;
            drawStyle.paint['text-halo-width'] = feature.haloWidth;
            drawStyle.paint['text-halo-blur'] = feature.haloBlur;
        } else {
            drawStyle.paint['text-halo-color'] = '#fff';
            drawStyle.paint['text-halo-width'] = 0;
            drawStyle.paint['text-halo-blur'] = 0;
        }
        if (feature.opacity !== undefined) drawStyle.paint['text-opacity'] = feature.opacity;
        break;
    case 'Image':
        const worldSize = 512 * Math.pow(2, feature.createdAtZoomLevel);
        const projectedTopLeft = project({
            lng: feature.topLeftPoint[0],
            lat: feature.topLeftPoint[1],
        }, worldSize);
        const projectedBottomRight = project({
            lng: feature.bottomRightPoint[0],
            lat: feature.bottomRightPoint[1],
        }, worldSize);
        const projectedTopRight = {
            x: projectedBottomRight.x,
            y: projectedTopLeft.y,
        };
        const projectedBottomLeft = {
            x: projectedTopLeft.x,
            y: projectedBottomRight.y,
        };
        const center = {
            x: (projectedTopLeft.x + projectedTopRight.x) / 2,
            y: (projectedTopLeft.y + projectedBottomLeft.y) / 2,
        };

        drawStyle.url = feature.fileUrl;

        const s = Math.sin(feature.rotationAngle / 180 * Math.PI);

        const c = Math.cos(feature.rotationAngle / 180 * Math.PI);

        // Center the coordinates
        projectedTopLeft.x -= center.x;
        projectedTopLeft.y -= center.y;

        projectedBottomRight.x -= center.x;
        projectedBottomRight.y -= center.y;

        projectedTopRight.x -= center.x;
        projectedTopRight.y -= center.y;

        projectedBottomLeft.x -= center.x;
        projectedBottomLeft.y -= center.y;

        drawStyle.coordinates = [
            unproject({
                x: projectedTopLeft.x * c - projectedTopLeft.y * s + center.x,
                y: projectedTopLeft.x * s + projectedTopLeft.y * c + center.y,
            }, worldSize),
            unproject({
                x: projectedTopRight.x * c - projectedTopRight.y * s + center.x,
                y: projectedTopRight.x * s + projectedTopRight.y * c + center.y,
            }, worldSize),
            unproject({
                x: projectedBottomRight.x * c - projectedBottomRight.y * s + center.x,
                y: projectedBottomRight.x * s + projectedBottomRight.y * c + center.y,
            }, worldSize),
            unproject({
                x: projectedBottomLeft.x * c - projectedBottomLeft.y * s + center.x,
                y: projectedBottomLeft.x * s + projectedBottomLeft.y * c + center.y,
            }, worldSize),
        ];
        if (feature.opacity !== undefined) drawStyle.paint['raster-opacity'] = feature.opacity;
        break;
    }
    if (feature.opts) drawStyle.opts = feature.opts;
    return drawStyle;
}
