import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import BusComponent from '../BusComponent';
import CategoriesList from './CategoriesList';
import CategoryTables from './CategoryTables';


class CategoriesTab extends BusComponent {
    constructor(props, context) {
        super(props, context);

        this.state = Object.assign({
            categoryName: props.categoryName,
            selectedSurveyYear: props.currentMetadataSelection.survey.year,
        });
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.mapInstanceId !== nextProps.mapInstanceId) {
            this.setState({
                categoryName: nextProps.categoryName,
                selectedSurveyYear: nextProps.currentMetadataSelection.survey.year,
            });
        }
    }

    onChangeCategory = dataCategory => {
        const categoryName = dataCategory.name;
        this.setState({
            categoryName,
        });
        if (this.props.onChangeCategory) {
            this.props.onChangeCategory(categoryName);
        }
    }

    onBack = () => {
        this.setState({
            categoryName: undefined,
        }, () => {
            if (this.props.onChangeCategory) {
                this.props.onChangeCategory(undefined);
            }
        });
    }

    render() {
        const { categoryName, selectedSurveyYear } = this.state;
        const {
            mapInstanceId,
            metadataGroupId,
            currentMetadataSelection,
            mapsGroupDataCategories,
            onSelectVariable,
            fieldName,
            className,
        } = this.props;

        const components = [];
        if (categoryName) {
            const category = mapsGroupDataCategories.find(c => c.name === categoryName);
            components.push(<CategoryTables
                key="category-tables"
                className="grow"
                categoryName={category.name}
                categoryIcon={category.iconUrl}
                year={selectedSurveyYear}
                mapInstanceId={mapInstanceId}
                metadataGroupId={metadataGroupId}
                currentMetadataSelection={currentMetadataSelection}
                onBack={this.onBack}
                onSelectVariable={onSelectVariable}
                fieldName={fieldName}
            />);
        } else {
            components.push(<CategoriesList
                key="categories-list"
                dataCategories={mapsGroupDataCategories}
                onChangeCategory={this.onChangeCategory}
            />);
        }

        return (<div className={classNames('categories-tab flex-it column stretch', className)}>
            {components}
        </div>);
    }
}


CategoriesTab.propTypes = {
    onSelectVariable: PropTypes.func.isRequired,
    onChangeCategory: PropTypes.func.isRequired,
    fieldName: PropTypes.string,
    mapInstanceId: PropTypes.string.isRequired,
    metadataGroupId: PropTypes.string.isRequired,
    mapsGroupDataCategories: PropTypes.array.isRequired,
    currentMetadataSelection: PropTypes.object.isRequired,
};

CategoriesTab.defaultProps = {
    fieldName: '',
};

export default CategoriesTab;
