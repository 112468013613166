// This object keeps the contour data for a single location
import LocationAnalysisType from '../enums/LocationAnalysisType';

class LocationContourData {
    constructor(values) {
        this.point = {};
        if (values) {
            Object.keys(values).forEach(k => {
                if (this.constructor.prototype.hasOwnProperty(k)) {
                    this[k] = values[k];
                }
            });
        }
        /** @type {import('../../..').ContourResponseByValues} */
        this[LocationAnalysisType.RADIUS.CONTOUR_TYPE] = {};
        /** @type {import('../../..').ContourResponseByValues} */
        this[LocationAnalysisType.DRIVING_TIME.CONTOUR_TYPE] = {};
        /** @type {import('../../..').ContourResponseByValues} */
        this[LocationAnalysisType.WALKING_TIME.CONTOUR_TYPE] = {};
        /** @type {import('../../..').ContourResponseByValues} */
        this[LocationAnalysisType.CYCLING_TIME.CONTOUR_TYPE] = {};
        /** @type {import('../../..').ContourResponseByValues} */
        this[LocationAnalysisType.GEO.CONTOUR_TYPE] = {};
    }

    get id() {
        return `${this.point.lng},${this.point.lat}`;
    }

    set point(point) {
        this._point = {
            lng: point.lng,
            lat: point.lat,
        };
    }

    get point() {
        return this._point;
    }

    get savedSelection() {
        return this._savedSelection;
    }

    set savedSelection(savedSelection) {
        this._savedSelection = savedSelection;
    }

    equals(that) {
        return (
            this.point.lat === that.point.lat &&
            this.point.lng === that.point.lng
        );
    }

    clone() {
        // We need to clone data for each profile type and values for that type
        return Object.assign(new LocationContourData(), JSON.parse(JSON.stringify(this)));
    }
}

export default LocationContourData;
