import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import MapSelectionType from '../../enums/MapSelectionType';
import MapSelectionInclusionType from '../../enums/MapSelectionInclusionType';
import CircleRadius from './CircleRadius';
import Units from './Units';
import Hints from './Hints';
import SimpleDropdown from '../dropdown/SimpleDropdown';
import NumberFormat from '../../enums/NumberFormat';
import format from '../../helpers/NumberFormatter';
import SelectionToolButton from './SelectionToolButton';
import {
    addEvent,
    removeEvent,
    calculatePointForBearingAndDistanceInMiles,
    findDistanceInMilesFromLatLong,
    convertToMiles,
    convertFromMiles,
    pointRadius,
    checkRectCointainsRect,
} from '../../helpers/Util';
import Key from '../../enums/Key';
import AppConfig from '../../appConfig';
import DistanceUnits from '../../enums/Unit';

const STYLES = {
    canvasFontStyle: '14px "Source Sans Pro", sans-serif',
    canvasFontColor: 'white',
    selectionStrokeColor: 'rgba(255, 42, 0, 1)',
    selectionFillColor: 'rgba(64, 64, 64, 0.5)',
    tempSelectionStrokeColor: 'rgba(45, 80, 168, 1)',
};

const START_POINT_RADIUS = 6; // in pixel

class MapSelection extends React.Component {
    constructor(props) {
        super(props);
        const { intl } = this.props;
        this.state = {
            selectionType: MapSelectionType.POINT,
            disabled: false,
            inclusionRule: MapSelectionInclusionType.INTERSECT,
            units: AppConfig.useMetricUnits ? DistanceUnits.KILOMETERS : DistanceUnits.MILES,
            inclusionRuleOptions: [
                { id: MapSelectionInclusionType.INTERSECT, text: intl.formatMessage({ id: 'hints.selection.touching' }) },
                { id: MapSelectionInclusionType.CONTAIN, text: intl.formatMessage({ id: 'hints.selection.enclosed' }) },
                { id: MapSelectionInclusionType.CENTROID, text: intl.formatMessage({ id: 'hints.selection.centroid' }) },
            ],
        };
    }

    componentDidMount() {
        const { map } = this.props;
        map.on('mousedown', this.onMapMouseDown);
        map.on('enablemapselection', this.enableMapSelection);
        map.on('disablemapselection', this.disableMapSelection);
        addEvent(document, 'keyup', this.onDocumentKeyUp);
        addEvent(document, 'keydown', this.onDocumentKeyDown);
        addEvent(document, 'dblclick', this.onDocumentDoubleClick);
        this.setMapStyles();
    }

    componentDidUpdate() {
        this.setMapStyles();
    }

    componentWillUnmount() {
        const { map } = this.props;
        const canvasContainer = map.getCanvasContainer();
        canvasContainer.classList.remove('cursor-move');
        canvasContainer.classList.remove('cursor-wait');
        canvasContainer.classList.remove('cursor-crosshair');
        map.off('mousedown', this.onMapMouseDown);
        map.off('enablemapselection', this.enableMapSelection);
        map.off('disablemapselection', this.disableMapSelection);
        removeEvent(document, 'keyup', this.onDocumentKeyUp);
        removeEvent(document, 'keydown', this.onDocumentKeyDown);
        removeEvent(document, 'dblclick', this.onDocumentDoubleClick);
        this.enableMapNavigation();
        this.detachMapListeners();
    }

    onMapRendered = () => {
        if (this.state.mapIsFittingBounds) {
            this.endFeatureSelection();
        }
    }

    onDocumentDoubleClick = () => {
        const { selectionType } = this.state;
        if ((selectionType === MapSelectionType.LINE || selectionType === MapSelectionType.POLYGON) && this.selectionPoints.length > 2) {
            this.endFeatureSelection();
        }
    }

    onDocumentKeyDown = e => {
        // ignore repeating key events
        if (e.repeat) return;
        const { selectionType } = this.state;

        switch (e.which) {
        case Key.SPACE:
            if (selectionType !== MapSelectionType.POINT) {
                this.props.map.dragPan.enable();
                this.setState({ isMovingSelection: true }, () => {
                    this.updateSelectionStyle();
                });
            }
            break;
        case Key.ESC:
            this.resetSelectionState();
            break;
        }
    }

    onDocumentKeyUp = e => {
        const { selectionType } = this.state;
        switch (e.which) {
        case Key.SPACE:
            if (selectionType !== MapSelectionType.POINT) {
                this.props.map.dragPan.disable();
                this.setState({
                    isMovingSelection: false,
                }, () => {
                    this.updateSelectionStyle();
                });
            }
            break;
        case Key.ESC:
            this.resetSelectionState();
            break;
        }
    }

    onMapMove = () => {
        const { map } = this.props;

        if (this.selectionStartPoint) {
            const { x, y } = map.project(this.selectionStartPoint.lngLat);
            this.selectionStartPoint.x = x;
            this.selectionStartPoint.y = y;
        }

        if (this.selectionEndPoint) {
            const { x, y } = map.project(this.selectionEndPoint.lngLat);
            this.selectionEndPoint.x = x;
            this.selectionEndPoint.y = y;
        }

        this.updateSelectionStyle();
    }

    onMapMouseDown = e => {
        const { isMovingSelection, mapIsFittingBounds } = this.state;
        // track last mouse down position
        this.lastMapMouseDownPoint = {
            x: e.point.x,
            y: e.point.y,
            lngLat: e.lngLat,
        };

        if (this.selectionStartPoint || isMovingSelection || mapIsFittingBounds) return;

        // initialize selection, reset state and attach mouse listeners
        this.resetSelectionState();

        this.selectionStartPoint = this.selectionEndPoint = {
            x: e.point.x,
            y: e.point.y,
            lngLat: e.lngLat,
        };
        this.attachMapListeners();
    }

    onMapMouseMove = e => {
        const { mapIsFittingBounds, isMovingSelection } = this.state;
        if (!this.selectionStartPoint || mapIsFittingBounds) return;

        const { map } = this.props;

        const pointUnderMouse = {
            x: e.point.x,
            y: e.point.y,
            lngLat: e.lngLat,
        };

        if (isMovingSelection) {
            const deltaX = this.selectionEndPoint.x - pointUnderMouse.x;
            const deltaY = this.selectionEndPoint.y - pointUnderMouse.y;
            const newStartPoint = {
                x: this.selectionStartPoint.x - deltaX,
                y: this.selectionStartPoint.y - deltaY,
            };
            newStartPoint.lngLat = map.unproject(newStartPoint);
            this.selectionStartPoint = newStartPoint;
        }

        this.selectionEndPoint = pointUnderMouse;

        this.updateSelectionStyle();
    }

    onMapMouseUp = e => {
        const { selectionType, mapIsFittingBounds, circleRadiusManualInput } = this.state;
        if (!this.selectionStartPoint || mapIsFittingBounds) return;

        const mapMouseUpPoint = {
            x: e.point.x,
            y: e.point.y,
            lngLat: e.lngLat,
        };

        const mouseDownUpRadius = pointRadius([mapMouseUpPoint.x, mapMouseUpPoint.y], [this.lastMapMouseDownPoint.x, this.lastMapMouseDownPoint.y]);
        const isMouseUpCloseToLastMouseDown = mouseDownUpRadius < START_POINT_RADIUS;

        // map viewport bounding box
        const mapViewBBox = [{ x: 0, y: 0 }, { x: this.drawingCanvas.width, y: this.drawingCanvas.height }];
        let selectionBBox;

        switch (selectionType) {
        case MapSelectionType.BOX:
            selectionBBox = [
                {
                    x: Math.min(this.selectionStartPoint.x, this.selectionEndPoint.x),
                    y: Math.min(this.selectionStartPoint.y, this.selectionEndPoint.y),
                },
                {
                    x: Math.max(this.selectionStartPoint.x, this.selectionEndPoint.x),
                    y: Math.max(this.selectionStartPoint.y, this.selectionEndPoint.y),
                },
            ];

            // check if selection bounding box is outside of map viewport
            if (checkRectCointainsRect(mapViewBBox, selectionBBox)) {
                // end selection now
                this.endFeatureSelection();
            } else {
                selectionBBox = [
                    {
                        x: Math.min(this.selectionStartPoint.x, this.selectionEndPoint.x),
                        y: Math.min(this.selectionStartPoint.y, this.selectionEndPoint.y),
                    },
                    {
                        x: Math.max(this.selectionStartPoint.x, this.selectionEndPoint.x),
                        y: Math.max(this.selectionStartPoint.y, this.selectionEndPoint.y),
                    },
                ];

                // check if selection bounding box is outside of map viewport
                if (checkRectCointainsRect(mapViewBBox, selectionBBox)) {
                    // end selection now
                    this.endFeatureSelection();
                } else {
                    // fit map to bounds first and end selection second
                    this.fitMapToBounds(selectionBBox);
                }
            }
            break;
        case MapSelectionType.CIRCLE:
            if (isMouseUpCloseToLastMouseDown && !circleRadiusManualInput) {
                this.setState({ circleRadiusManualInput: true });
            } else {
                const radius = pointRadius([this.selectionStartPoint.x, this.selectionStartPoint.y], [this.selectionEndPoint.x, this.selectionEndPoint.y]);
                selectionBBox = [
                    { x: this.selectionStartPoint.x - radius, y: this.selectionStartPoint.y - radius },
                    { x: this.selectionStartPoint.x + radius, y: this.selectionStartPoint.y + radius },
                ];
                // check if selection bounding box is outside of map viewport
                if (checkRectCointainsRect(mapViewBBox, selectionBBox)) {
                    this.endFeatureSelection();
                } else {
                    // fit map to bounds first and end selection second
                    this.fitMapToBounds(selectionBBox);
                }
            }
            break;
        case MapSelectionType.POLYGON:
            if (isMouseUpCloseToLastMouseDown) {
                if (this.selectionPoints.length >= 3 && pointRadius([this.selectionStartPoint.x, this.selectionStartPoint.y], [mapMouseUpPoint.x, mapMouseUpPoint.y]) < START_POINT_RADIUS) {
                    this.endFeatureSelection();
                } else {
                    this.selectionPoints.push(mapMouseUpPoint);
                    this.updateSelectionStyle();
                }
            }
            break;
        case MapSelectionType.LINE:
            if (isMouseUpCloseToLastMouseDown) {
                this.selectionPoints.push(mapMouseUpPoint);
                this.updateSelectionStyle();
            }
            break;
        case MapSelectionType.POINT:
            if (isMouseUpCloseToLastMouseDown) {
                this.endFeatureSelection();
            }
            break;
        }
    }

    onInclusionRuleChange = inclusionRule => {
        this.setState({ inclusionRule });
    }

    onSelectionToolChange = selectionType => {
        switch (selectionType) {
        case MapSelectionType.POINT:
        case MapSelectionType.LINE:
            this.setState({ inclusionRule: MapSelectionInclusionType.INTERSECT });
            break;
        }

        this.setState({ selectionType }, () => this.resetSelectionState());
    }

    onUnitChange = units => {
        this.setState({ units });
    }

    onCircleRadiusInputChange = radius => {
        const { map } = this.props;
        const { units } = this.state;

        // convert the manual radius input into a point on map and set it as `selectionEndPoint`
        const radiusInMiles = convertToMiles(radius, units);
        const endPoint = calculatePointForBearingAndDistanceInMiles(this.selectionStartPoint.lngLat, radiusInMiles, 90);
        const endPointProjected = map.project(endPoint);

        const endPointUnprojected = map.unproject(endPointProjected);
        const { x, y } = endPointProjected;

        this.selectionEndPoint = { x, y, lngLat: endPointUnprojected };

        // trigger the mouseUp logic to handle manual radius input
        this.onMapMouseUp({
            point: {
                x,
                y,
            },
            lngLat: endPointUnprojected,
        });
    }

    setMapStyles = () => {
        const { isMovingSelection, inclusionRule, mapIsFittingBounds } = this.state;
        const { map } = this.props;

        if (map.style._loaded) {
            let opacity = 0;
            // show centroid points while CENTROID inclusion rule
            if (inclusionRule === MapSelectionInclusionType.CENTROID) {
                opacity = 0.4;
            }
            map.setPaintProperty('helper-circle-layer', 'circle-opacity', opacity);
        }
        // adjust proper cursor on map
        const canvasContainer = map.getCanvasContainer();
        canvasContainer.classList.remove('cursor-move');
        canvasContainer.classList.remove('cursor-wait');
        canvasContainer.classList.remove('cursor-crosshair');
        let cursorClass;
        if (isMovingSelection) {
            cursorClass = 'cursor-move';
        } else if (mapIsFittingBounds) {
            cursorClass = 'cursor-wait';
        } else {
            cursorClass = 'cursor-crosshair';
        }
        canvasContainer.classList.add(cursorClass);
    }

    getCanvasContext(clear = true) {
        const context = this.drawingCanvas.getContext('2d');
        if (clear) {
            context.clearRect(0, 0, this.drawingCanvas.width, this.drawingCanvas.height);
        }
        return context;
    }

    updateSelectionStyle = () => {
        const { selectionType } = this.state;
        let handler;
        switch (selectionType) {
        case MapSelectionType.BOX:
            handler = this.updateSelectionBoxStyle;
            break;
        case MapSelectionType.POLYGON:
            handler = this.updateSelectionPolygonStyle;
            break;
        case MapSelectionType.CIRCLE:
            handler = this.updateSelectionCircleStyle;
            break;
        case MapSelectionType.LINE:
            handler = this.updateSelectionLineStyle;
            break;
        }
        if (handler) {
            window.requestAnimationFrame(handler);
        }
    }

    updateSelectionLineStyle = () => {
        if (this.selectionPoints.length) {
            const { map } = this.props;
            const { isMovingSelection } = this.state;

            const context = this.getCanvasContext();

            const coordinates = this.selectionPoints.map(point => {
                const projected = map.project(point.lngLat);
                return [projected.x, projected.y];
            });

            const count = coordinates.length;

            if (count > 0) {
                // render temporary path only if not moving selection/panning map
                if (!isMovingSelection) {
                    context.beginPath();
                    const tempPath = new Path2D(`M ${[coordinates[count - 1], [this.selectionEndPoint.x, this.selectionEndPoint.y]].map(c => c.join(' '))}`);
                    context.strokeStyle = STYLES.tempSelectionStrokeColor;
                    context.lineWidth = 1;
                    context.stroke(tempPath);
                }

                // render line points
                context.beginPath();
                context.strokeStyle = STYLES.selectionStrokeColor;
                context.lineWidth = 2;
                const path = new Path2D(`M ${coordinates.map(c => c.join(' '))}`);
                context.stroke(path);

                // render first point
                const [startX, startY] = coordinates[0];
                context.beginPath();
                context.arc(startX, startY, START_POINT_RADIUS, 0, 2 * Math.PI, false);
                context.fillStyle = STYLES.selectionStrokeColor;
                context.fill();
            }
        }
    }

    updateSelectionPolygonStyle = () => {
        if (this.selectionPoints.length) {
            const { map } = this.props;
            const { isMovingSelection } = this.state;

            const coordinates = this.selectionPoints.map(point => {
                const projected = map.project(point.lngLat);
                return [projected.x, projected.y];
            });

            const context = this.getCanvasContext();
            const count = coordinates.length;

            // render temporary path only if not moving selection/panning map
            if (!isMovingSelection && count > 0) {
                const lastLine = new Path2D(`M ${[coordinates[count - 1], [this.selectionEndPoint.x, this.selectionEndPoint.y], coordinates[0]].map(c => c.join(' '))}`);
                const lastFill = new Path2D(`M ${[coordinates[0], coordinates[count - 1], [this.selectionEndPoint.x, this.selectionEndPoint.y]].map(c => c.join(' '))} Z`);
                context.strokeStyle = STYLES.tempSelectionStrokeColor;
                context.lineWidth = 1;
                context.fillStyle = STYLES.selectionFillColor;
                context.stroke(lastLine);
                context.fill(lastFill);
            }

            if (count > 1) {
                context.strokeStyle = STYLES.selectionStrokeColor;
                context.lineWidth = 2;
                context.fillStyle = STYLES.selectionFillColor;
                const fill = new Path2D(`M ${coordinates.map(c => c.join(' '))}`);
                context.fill(fill);
                context.stroke(fill);
            }

            // render first point
            const [startX, startY] = coordinates[0];
            context.beginPath();
            context.arc(startX, startY, START_POINT_RADIUS, 0, 2 * Math.PI, false);
            context.fillStyle = STYLES.selectionStrokeColor;
            context.fill();
        }
    }

    updateSelectionCircleStyle = () => {
        const { map, intl } = this.props;
        const { isMovingSelection, mapIsFittingBounds, circleRadiusManualInput, units } = this.state;

        const context = this.getCanvasContext();

        if (circleRadiusManualInput) {
            const circleCenter = map.project(this.selectionStartPoint.lngLat);
            // draw center
            context.beginPath();
            context.fillStyle = isMovingSelection ? STYLES.tempSelectionStrokeColor : STYLES.selectionStrokeColor;
            context.arc(circleCenter.x, circleCenter.y, 3, 0, 2 * Math.PI);
            context.fill();
        } else if (this.selectionStartPoint && this.selectionEndPoint) {
            const p1 = [this.selectionStartPoint.x, this.selectionStartPoint.y];
            const p2 = [this.selectionEndPoint.x, this.selectionEndPoint.y];
            const radius = pointRadius(p1, p2);
            if (radius > 0) {
                const circleCenter = map.project(this.selectionStartPoint.lngLat);
                // draw circle (arc)
                context.beginPath();
                context.strokeStyle = isMovingSelection ? STYLES.tempSelectionStrokeColor : STYLES.selectionStrokeColor;
                context.lineWidth = 2;
                context.fillStyle = STYLES.selectionFillColor;
                context.arc(circleCenter.x, circleCenter.y, radius, 0, 2 * Math.PI);
                context.fill();
                context.stroke();

                // draw line from center to circle
                context.beginPath();
                const path = new Path2D(`M ${circleCenter.x} ${circleCenter.y} ${circleCenter.x + radius} ${circleCenter.y}`);
                context.strokeStyle = STYLES.canvasFontColor;
                context.stroke(path);

                // draw circle radius text
                const distanceInMiles = findDistanceInMilesFromLatLong(this.selectionStartPoint.lngLat, this.selectionEndPoint.lngLat);
                const distanceInSelectedUnits = convertFromMiles(distanceInMiles, units);

                let numberFormat = NumberFormat.FORMAT_NUMBER_NO_DECIMAL;
                if (distanceInSelectedUnits < 100) numberFormat = NumberFormat.FORMAT_NUMBER_2_DECIMAL;

                const formattedRadius = `${format({
                    number: distanceInSelectedUnits,
                    numberFormat,
                })} ${Units.getUnitDetails(units).text}`;
                context.font = STYLES.canvasFontStyle;
                context.textAlign = 'left';
                context.fillStyle = STYLES.canvasFontColor;
                const text = mapIsFittingBounds ? intl.formatMessage({ id: 'hints.selection.loadingSelection' }) : formattedRadius;
                context.fillText(text, circleCenter.x, circleCenter.y - 4);

                // draw center
                context.beginPath();
                context.fillStyle = isMovingSelection ? STYLES.tempSelectionStrokeColor : STYLES.selectionStrokeColor;
                context.arc(circleCenter.x, circleCenter.y, 3, 0, 2 * Math.PI);
                context.fill();
            }
        }
    }

    updateSelectionBoxStyle = () => {
        if (this.selectionStartPoint && this.selectionEndPoint) {
            const { map, intl } = this.props;
            const { isMovingSelection, mapIsFittingBounds, units } = this.state;
            const pointA = map.project(this.selectionStartPoint.lngLat);
            const pointB = map.project(this.selectionEndPoint.lngLat);

            const topLeft = {
                x: Math.min(pointA.x, pointB.x),
                y: Math.min(pointA.y, pointB.y),
            };

            const bottomRight = {
                x: Math.max(pointA.x, pointB.x),
                y: Math.max(pointA.y, pointB.y),
            };

            const context = this.getCanvasContext();
            context.strokeStyle = isMovingSelection ? STYLES.tempSelectionStrokeColor : STYLES.selectionStrokeColor;
            context.lineWidth = 2;
            context.fillStyle = STYLES.selectionFillColor;

            const width = Math.abs(bottomRight.x - topLeft.x);
            const height = Math.abs(bottomRight.y - topLeft.y);

            //  draw rectangle
            context.beginPath();
            context.rect(topLeft.x, topLeft.y, width, height);
            context.fill();
            context.stroke();

            // draw center
            context.beginPath();
            context.fillStyle = isMovingSelection ? STYLES.tempSelectionStrokeColor : STYLES.selectionStrokeColor;
            context.arc(topLeft.x + (width / 2), topLeft.y + (height / 2), 3, 0, 2 * Math.PI);
            context.fill();

            const widthPoint = { x: this.selectionEndPoint.x, y: this.selectionStartPoint.y };
            widthPoint.lngLat = map.unproject(widthPoint);
            const widthInMiles = findDistanceInMilesFromLatLong(this.selectionStartPoint.lngLat, widthPoint.lngLat);
            const widthInSelectedUnits = convertFromMiles(widthInMiles, units);
            let widthDistanceNumberFormat = NumberFormat.FORMAT_NUMBER_NO_DECIMAL;
            if (widthInSelectedUnits < 10 && widthInSelectedUnits > 1) widthDistanceNumberFormat = NumberFormat.FORMAT_NUMBER_1_DECIMAL;
            else if (widthInSelectedUnits < 1) widthDistanceNumberFormat = NumberFormat.FORMAT_NUMBER_2_DECIMAL;
            const formattedWidth = `${format({
                number: widthInSelectedUnits,
                numberFormat: widthDistanceNumberFormat,
            })}`;

            const heightPoint = { x: this.selectionStartPoint.x, y: this.selectionEndPoint.y };
            heightPoint.lngLat = map.unproject(heightPoint);
            const heightInMiles = findDistanceInMilesFromLatLong(this.selectionStartPoint.lngLat, heightPoint.lngLat);
            const heightInSelectedUnits = convertFromMiles(heightInMiles, units);
            let heightDistanceNumberFormat = NumberFormat.FORMAT_NUMBER_NO_DECIMAL;
            if (heightInSelectedUnits < 10 && heightInSelectedUnits > 1) heightDistanceNumberFormat = NumberFormat.FORMAT_NUMBER_1_DECIMAL;
            else if (heightInSelectedUnits < 1) heightDistanceNumberFormat = NumberFormat.FORMAT_NUMBER_2_DECIMAL;
            const formattedHeight = `${format({
                number: heightInSelectedUnits,
                numberFormat: heightDistanceNumberFormat,
            })}`;

            context.font = STYLES.canvasFontStyle;
            context.textAlign = 'right';
            context.fillStyle = STYLES.canvasFontColor;
            const text = mapIsFittingBounds ? intl.formatMessage({ id: 'hints.selection.loadingSelection' }) : `${formattedWidth} x ${formattedHeight} ${Units.getUnitDetails(units).text}`;
            context.fillText(text, bottomRight.x - 4, bottomRight.y - 4);
        }
    }

    endFeatureSelection = () => {
        const { selectionType, inclusionRule } = this.state;

        this.props.handleSelection({
            selectionType,
            inclusionRule,
            selectionStartPoint: this.selectionStartPoint,
            selectionEndPoint: this.selectionEndPoint,
            selectionPoints: this.selectionPoints,
        });

        this.resetSelectionState();
    }

    fitMapToBounds(bounds) {
        const { map } = this.props;
        const maxZoom = map.getZoom(); // keep the zoom same as current, while fitting bounds
        const topLeftLngLat = map.unproject(bounds[0]);
        const bottomRightLngLat = map.unproject(bounds[1]);
        const southWest = [topLeftLngLat.lng, bottomRightLngLat.lat];
        const northEast = [bottomRightLngLat.lng, topLeftLngLat.lat];
        map.fitBounds([southWest, northEast], { maxZoom, padding: { top: 50, left: 500, right: 50, bottom: 50 } });

        this.setState({ mapIsFittingBounds: true, circleRadiusManualInput: false });
    }

    resetSelectionState = () => {
        const { selectionType } = this.state;
        if (selectionType === MapSelectionType.POINT) {
            this.enableMapNavigation();
        } else {
            this.disableMapNavigation();
        }

        this.getCanvasContext();

        this.selectionPoints = [];
        this.selectionStartPoint = undefined;
        this.selectionEndPoint = undefined;

        this.setState({ mapIsFittingBounds: false, circleRadiusManualInput: false });
        this.detachMapListeners();
    }

    enableMapSelection = () => {
        const { map } = this.props;
        map.on('mousedown', this.onMapMouseDown);
        this.setState({
            disabled: false,
        });
    }

    disableMapSelection = () => {
        const { map } = this.props;
        map.off('mousedown', this.onMapMouseDown);
        this.setState({
            disabled: true,
        });
    }

    attachMapListeners = () => {
        const { map } = this.props;
        map.on('mousemove', this.onMapMouseMove);
        map.on('move', this.onMapMove);
        map.on('mouseup', this.onMapMouseUp);
        map.on('rendered', this.onMapRendered);
        map.fire('disablefeatureinteractivityrequest');
    }

    detachMapListeners = () => {
        const { map } = this.props;
        map.off('mousemove', this.onMapMouseMove);
        map.off('mouseup', this.onMapMouseUp);
        map.off('move', this.onMapMove);
        map.off('rendered', this.onMapRendered);
        map.fire('enablefeatureinteractivityrequest');
    }

    enableMapNavigation = () => {
        const { map } = this.props;
        map.doubleClickZoom.enable();
        map.dragPan.enable();
    }

    disableMapNavigation = () => {
        const { map } = this.props;
        map.dragPan.disable();
        map.doubleClickZoom.disable();
    }

    renderSelectionHints() {
        const { selectionType } = this.state;
        return <Hints type={selectionType} />;
    }

    renderInclusionTypes() {
        const { inclusionRule, selectionType, inclusionRuleOptions } = this.state;
        const { intl } = this.props;
        const hidden = selectionType === MapSelectionType.POINT || selectionType === MapSelectionType.LINE || selectionType === MapSelectionType.FEATURE;

        if (hidden) return null;

        return (
            <SimpleDropdown
                className="inclusion-rules rounded--bottom-left"
                title={intl.formatMessage({ id: 'hints.selection.selectFeatures' })}
                items={inclusionRuleOptions}
                onItemClick={this.onInclusionRuleChange}
                selectedItem={inclusionRuleOptions.find(opt => opt.id === inclusionRule)}
            />
        );
    }

    renderSelectionTypes() {
        const { selectionType } = this.state;
        const { intl } = this.props;
        return (
            <div className="map-selection-ctrl__toolbox">
                <SelectionToolButton
                    value={MapSelectionType.POINT} active={selectionType === MapSelectionType.POINT}
                    icon="socex-icon-cursor" onClick={this.onSelectionToolChange}
                    title={intl.formatMessage({ id: 'hints.selection.pointSelection' })}
                />
                <SelectionToolButton
                    value={MapSelectionType.BOX} active={selectionType === MapSelectionType.BOX}
                    icon="socex-icon-not-done-outline" onClick={this.onSelectionToolChange}
                    title={intl.formatMessage({ id: 'hints.selection.rectangleSelection' })}
                />
                <SelectionToolButton
                    value={MapSelectionType.CIRCLE} active={selectionType === MapSelectionType.CIRCLE}
                    icon="socex-icon-circle" onClick={this.onSelectionToolChange}
                    title={intl.formatMessage({ id: 'hints.selection.circleSelection' })}
                />
                <SelectionToolButton
                    value={MapSelectionType.POLYGON}
                    active={selectionType === MapSelectionType.POLYGON} icon="socex-icon-polygon-2"
                    onClick={this.onSelectionToolChange}
                    title={intl.formatMessage({ id: 'hints.selection.polygonSelection' })}
                />
                <SelectionToolButton
                    value={MapSelectionType.LINE} active={selectionType === MapSelectionType.LINE}
                    icon="socex-icon-polyline" onClick={this.onSelectionToolChange}
                    title={intl.formatMessage({ id: 'hints.selection.lineSelection' })}
                />
            </div>
        );
    }

    renderSelectionGraphics() {
        const { selectionType, circleRadiusManualInput, isMovingSelection, units } = this.state;
        const { map, intl } = this.props;

        const canvas = map.getCanvasContainer();
        const mapWidth = canvas.parentNode.clientWidth;
        const mapHeight = canvas.parentNode.clientHeight;

        let circleRadiusInput;
        if (this.selectionStartPoint) {
            if (selectionType === MapSelectionType.CIRCLE) {
                const { x, y } = this.selectionStartPoint;
                const classes = classNames('circle-selection-radius-input', {
                    hidden: !circleRadiusManualInput || isMovingSelection,
                });

                // check if the selection popup can fit placed right and bottom from the selection starting point (drawn in red)
                // the logic uses 200 pixels as treshold for remaining space
                // if popup can't fit apply simple CSS transformations (translateX and translateY) to move it
                const isOverflowHorizontal = x + 200 > mapWidth;
                const isOverflowVertical = y + 200 > mapHeight;

                const style = {
                    top: y,
                    left: x + 10,
                    transform: '',
                };

                if (isOverflowHorizontal) {
                    style.transform = 'translateX(calc(-100% - 25px))';
                }

                if (isOverflowVertical) {
                    style.transform += ' translateY(calc(-100% - 10px))';
                }

                circleRadiusInput = (
                    <div className={classes} style={style}>
                        <CircleRadius
                            units={intl.formatMessage({ id: Units.getUnitDetails(units).descriptive })}
                            onChange={this.onCircleRadiusInputChange}
                            onCancel={this.resetSelectionState}
                        />
                    </div>);
            }
        }

        const bb = canvas.getBoundingClientRect();
        const selectionGraphicsStyle = {
            top: bb.top,
            left: bb.left,
            width: mapWidth,
            height: mapHeight,
        };

        return (
            <div style={selectionGraphicsStyle} className="map-selection-drawings">
                <canvas
                    ref={c => { this.drawingCanvas = c; }}
                    width={mapWidth}
                    height={mapHeight}
                />
                {circleRadiusInput}
            </div>
        );
    }

    renderUnitsSelection() {
        const { units, selectionType } = this.state;
        const hidden = selectionType === MapSelectionType.POINT || selectionType === MapSelectionType.LINE || selectionType === MapSelectionType.POLYGON || selectionType === MapSelectionType.FEATURE;
        return <Units className={classNames({ hidden })} selected={units} onChange={this.onUnitChange} />;
    }

    renderSelectionModifiers() {
        return (
            <div className="map-selection-ctrl__modifiers">
                {this.renderInclusionTypes()}
                {this.renderUnitsSelection()}
            </div>
        );
    }

    render() {
        const mapSelectionClassNames = classNames('map-selection-ctrl rounded', { 'map-selection-ctrl--disabled': this.state.disabled });
        return (
            <div>
                {this.renderSelectionGraphics()}
                <div className={mapSelectionClassNames}>
                    {this.renderSelectionTypes()}
                    {this.renderSelectionModifiers()}
                    {this.renderSelectionHints()}
                </div>
            </div>
        );
    }
}

MapSelection.propTypes = {
    map: PropTypes.object.isRequired,
    handleSelection: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
};

MapSelection.defaultProps = {
};

export default injectIntl(MapSelection);
