import React from 'react';
import { findDOMNode } from 'react-dom';

import BusComponent from '../BusComponent';

function withResponsivness(WrappedFrame, breakAt = 768) {
    return class extends BusComponent {
        constructor(props, context) {
            super(props, context);
            this.state = {
                isCondensedLayout: undefined,
            };
            this._isMounted = false;
        }
        componentDidUpdate(prevProps, prevState) {
            const { isCondensedLayout } = this.state;

            if (prevState.isCondensedLayout !== isCondensedLayout) {
                this.emit('FRAME_LAYOUT_CHANGE', { isCondensedLayout });
            }
        }

        componentDidMount() {
            this._isMounted = true;
            window.addEventListener('resize', this.handleWindowResize);
            this.handleWindowResize();
        }

        componentWillUnmount() {
            this._isMounted = false;
            window.removeEventListener('resize', this.handleWindowResize);
        }

        handleWindowResize = () => {
            const frameDomNode = findDOMNode(this.wrappedFrame);

            if (!frameDomNode) return;

            window.requestAnimationFrame(() => {
                if (this._isMounted) {
                    this.setState({
                        isCondensedLayout: frameDomNode.clientWidth < breakAt,
                    });
                }
            });
        };

        render() {
            return (
                <WrappedFrame
                    ref={c => { this.wrappedFrame = c; }}
                    {...this.props}
                    isCondensedLayout={this.state.isCondensedLayout}
                />);
        }
    };
}

export default withResponsivness;
