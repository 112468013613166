/* eslint-disable */
import { createBezierResult, douglasPeuckerReduction, bezierFitCubic } from './BezierUtils';
import simplify from './Simplify';


function lngX(lng, worldSize) {
    return (180 + lng) * (worldSize) / 360;
}
function latY(lat, worldSize) {
    const y = 180 / Math.PI * Math.log(Math.tan(Math.PI / 4 + lat * Math.PI / 360));
    return (180 - y) * (worldSize) / 360;
}

function xLng(x, worldSize) {
    return x * 360 / (worldSize) - 180;
}

function yLat(y, worldSize) {
    const y2 = 180 - y * 360 / (worldSize);
    return 360 / Math.PI * Math.atan(Math.exp(y2 * Math.PI / 180)) - 90;
}

export function project(lnglat, worldSize) {
    return ({
        x: lngX(lnglat.lng, worldSize),
        y: latY(lnglat.lat, worldSize),
    });
}

export function unproject(point, worldSize) {
    return ([
        xLng(point.x, worldSize),
        yLat(point.y, worldSize),
    ]);
}

function createParallelLine(pointA, pointB, difference) {
    const perpendicularX = -(pointA.y - pointB.y);
    const perpendicularY = pointA.x - pointB.x;
    const length = Math.sqrt(perpendicularX * perpendicularX + perpendicularY * perpendicularY);
    // some trigonometry magic
    const distanceX = perpendicularX / length * difference;
    const distanceY = perpendicularY / length * difference;

    return ({
        p1: {
            x: pointA.x - distanceX,
            y: pointA.y - distanceY,
        },
        p2: {
            x: pointB.x - distanceX,
            y: pointB.y - distanceY,
        },
    });
}

function lerp(t, a, b) {
    return a + (b - a) * t;
}

function offsetCurve(be, from = 20, to = 20) {
    let tt = 0;
    const ttInterval = 1 / be.straightLines().length;
    const bezierOffsetResult = {
        newSl1Points: [],
        newSl2Points: [],
    };
    be.straightLines().forEach(sl => {
        const topSl = createParallelLine(sl.p1, sl.p2, lerp(tt, from, to));
        const bottomSl = createParallelLine(sl.p1, sl.p2, lerp(tt, -from, -to));

        bezierOffsetResult.newSl1Points.push(topSl.p1);
        bezierOffsetResult.newSl1Points.push(topSl.p2);

        bezierOffsetResult.newSl2Points.push(bottomSl.p1);
        bezierOffsetResult.newSl2Points.push(bottomSl.p2);

        tt += ttInterval;
    });

    return bezierOffsetResult;
}


function multiplyMatrices(a, b) {
    let aNumRows = a.length, aNumCols = a[0].length,
        bNumCols = b[0].length,
        m = new Array(aNumRows);  // initialize array of rows
    for (let r = 0; r < aNumRows; ++r) {
        m[r] = new Array(bNumCols); // initialize the current row
        for (let c = 0; c < bNumCols; ++c) {
            m[r][c] = 0;             // initialize the current cell
            for (let i = 0; i < aNumCols; ++i) {
                m[r][c] += a[r][i] * b[i][c];
            }
        }
    }
    return m;
}

function rotatePoint(p, angleRadians) {
    const rotationMatrix = [[Math.cos(angleRadians), -Math.sin(angleRadians)], [Math.sin(angleRadians), Math.cos(angleRadians)]];
    return multiplyMatrices(rotationMatrix, [[p.x], [p.y]]);
}

function normalArrow(p, angleRadians, offset1, offset2, arrowWidth = 40) {
    const arrowPoints = [];
    const arrowHeight = arrowWidth * Math.sqrt(3) / 2;
    const baseTip = { x: arrowHeight, y: 0 };
    const baseLeft = { x: 0, y: -arrowWidth };
    const baseRight = { x: 0, y: arrowWidth };

    const rotatedTip = rotatePoint(baseTip, angleRadians);
    const rotatedLeft = rotatePoint(baseLeft, angleRadians);
    const rotatedRight = rotatePoint(baseRight, angleRadians);
    arrowPoints.push({ x: rotatedRight[0][0] + p.x, y: rotatedRight[1][0] + p.y });
    arrowPoints.push({ x: rotatedTip[0][0] + p.x, y: rotatedTip[1][0] + p.y });
    arrowPoints.push({ x: rotatedLeft[0][0] + p.x, y: rotatedLeft[1][0] + p.y });
    return arrowPoints;
}

function calcAngle(x1, y1, x2, y2) {
    return Math.atan2(y2 - y1, x2 - x1);
}

// a1 = 0, cp1 = 1, a2 = 2, cp2 = 3
function cubicBezier(curve, numberOfSteps = 1000) {
    // purposely swapped
    const anchorPoint1 = curve[0];
    const anchorPoint2 = curve[3];
    const controlPoint1 = curve[1];
    const controlPoint2 = curve[2];
    const points = [];
    let posx, posy, counter = 0, x1, x2, y1, y2;

    for (let u = 0; u <= 1; u += 1 / numberOfSteps) {
        posx = Math.pow(u, 3) * (anchorPoint2.x + 3 * (controlPoint1.x - controlPoint2.x) - anchorPoint1.x) + 3 *
            Math.pow(u, 2) * (anchorPoint1.x - 2 * controlPoint1.x + controlPoint2.x) +
            3 * u * (controlPoint1.x - anchorPoint1.x) + anchorPoint1.x;

        posy = Math.pow(u, 3) * (anchorPoint2.y + 3 * (controlPoint1.y - controlPoint2.y) - anchorPoint1.y) +
            3 * Math.pow(u, 2) * (anchorPoint1.y - 2 * controlPoint1.y + controlPoint2.y) +
            3 * u * (controlPoint1.y - anchorPoint1.y) + anchorPoint1.y;

        points.push({ x: posx, y: posy });
        if (counter === numberOfSteps - 2) {
            x1 = posx;
            y1 = posy;
        } else if (counter === numberOfSteps - 1) {
            x2 = posx;
            y2 = posy;
        }
        counter++;
    }

    const angleRadians = calcAngle(x1, y1, x2, y2);
    return { points, angleRadians };
}

// curves in pixels
function cubicBeziers(curves, nrOfSteps) {
    let tempPoints;
    const multiCubicBezierInfo = {
        lastCurveAngleRadians: [],
        curves: [],
    };
    curves.forEach(c => {
        const cubicBezierObject = cubicBezier(c, nrOfSteps);
        multiCubicBezierInfo.lastCurveAngleRadians = cubicBezierObject.angleRadians;
        tempPoints = cubicBezierObject.points;
        const cbi = {
            lines: [],
            allPoints: [],
        };
        for (let i = 0; i < tempPoints.length - 1; i++) {
            cbi.lines.push({ p1: tempPoints[i], p2: tempPoints[i + 1] });
        }
        cbi.anchor1 = c[0];
        cbi.anchor2 = c[3];
        cbi.controlPoint1 = c[1];
        cbi.controlPoint2 = c[2];
        cbi.allPoints = tempPoints;
        multiCubicBezierInfo.curves.push(cbi);
        multiCubicBezierInfo.allPoints = () => {
            const points = [];
            multiCubicBezierInfo.curves.forEach(ci => {
                ci.allPoints.forEach(p => {
                    points.push(p);
                });
            });
            return points;
        };

        multiCubicBezierInfo.straightLines = () => {
            const lines = [];
            multiCubicBezierInfo.curves.forEach(ci => {
                ci.lines.forEach(p => {
                    lines.push(p);
                });
            });

            return lines;
        };
    });
    return multiCubicBezierInfo;
}

export function getArrowFromCurves(curves, fromWidth, toWidth, arrowWidth, createdAtZoomLevel) {
    const coordinates = [];
    const worldSize = 512 * Math.pow(2, createdAtZoomLevel);
    const curvesInPixels = curves.map(c =>
        [project({ lng: c.anchorPoint1[0], lat: c.anchorPoint1[1] }, worldSize),
            project({ lng: c.controlPoint1[0], lat: c.controlPoint1[1] }, worldSize),
            project({ lng: c.anchorPoint2[0], lat: c.anchorPoint2[1] }, worldSize),
            project({ lng: c.controlPoint2[0], lat: c.controlPoint2[1] }, worldSize)]);
    let ret;
    const multiCubicBezier = cubicBeziers(curvesInPixels, 1000);

    multiCubicBezier.allPoints = () => {
        const points = [];
        multiCubicBezier.curves.forEach(c => {
            c.allPoints.forEach(p => {
                points.push(p);
            });
        });
        return points;
    };

    multiCubicBezier.straightLines = () => {
        const lines = [];
        multiCubicBezier.curves.forEach(c => {
            c.lines.forEach(p => {
                lines.push(p);
            });
        });

        return lines;
    };

    const bor = offsetCurve(multiCubicBezier, fromWidth, toWidth);

    const beziersTwo = createBezierResult();

    bor.newSl1Points = simplify(bor.newSl1Points);
    bor.newSl2Points = simplify(bor.newSl2Points);
    ret = bezierFitCubic(beziersTwo, bor.newSl1Points, bor.newSl1Points.length, 80);

    const offsettedB1 = cubicBeziers(ret.curves);

    const beziersThree = createBezierResult();
    ret = bezierFitCubic(beziersThree, bor.newSl2Points, bor.newSl2Points.length, 80);

    const offsettedB2 = cubicBeziers(ret.curves);

    let objPoints = [];

    objPoints = objPoints.concat(offsettedB1.allPoints());
    const arrowPoints = normalArrow(multiCubicBezier.allPoints()[multiCubicBezier.allPoints().length - 1], multiCubicBezier.lastCurveAngleRadians,
        offsettedB1.allPoints[offsettedB1.allPoints().length - 1],
        offsettedB2.allPoints[offsettedB2.allPoints().length - 1],
        arrowWidth
    );
    objPoints = objPoints.concat(arrowPoints);
    objPoints = objPoints.concat(offsettedB2.allPoints().reverse());

    objPoints.push(offsettedB1.allPoints()[0]);

    objPoints.forEach(p => {
        coordinates.push(unproject(p, worldSize));
    });


    let simpleCoordinatesXY = [];
    const simpleCoordinates = [];
    simpleCoordinatesXY = simpleCoordinatesXY.concat(bor.newSl1Points);
    simpleCoordinatesXY = simpleCoordinatesXY.concat(arrowPoints);
    simpleCoordinatesXY = simpleCoordinatesXY.concat(bor.newSl2Points.reverse());
    simpleCoordinatesXY.push(bor.newSl1Points[0]);
    simpleCoordinatesXY.forEach(p => {
        simpleCoordinates.push(unproject(p, worldSize));
    });

    return { simpleCoordinates, complexCoordinates: coordinates };
}

export function getFlowArrowCurves(points, doSimplification = true, simplifierFactor = 0.8, fitterTolerance = 500) {
    let simplifiedPoints;
    if (doSimplification) simplifiedPoints = douglasPeuckerReduction(points, simplifierFactor);

    if (simplifiedPoints.length < 2) return null;

    const beziers = createBezierResult();
    const ret = bezierFitCubic(beziers, simplifiedPoints, simplifiedPoints.length, fitterTolerance);

    return ret.curves;
}
