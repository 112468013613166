import React from 'react';
import PropTypes from 'prop-types';
import withHighlight from './withHighlight';
import classNames from 'classnames';

const VerticalShadedStop = props => {
    const stopRectangleClasses = classNames('flex-it center flex-start shaded-color-palette-vertical__stop', { 'shaded-color-palette-vertical__stop--highlighted': props.highlighted });
    if (props.isInsufficientDataRule) {
        return (<div className="flex-it center flex-start no-shrink">
            <div
                className="shaded-color-palette-vertical__color-rectangle"
                style={{ backgroundColor: props.rule.symbols[0].brushes[0].fillColor }}
            />
            <div className="shaded-color-palette-vertical__label">{props.rule.title}</div>
        </div>);
    }

    let rectangleStyle;
    if (props.legendHighlighted && !props.highlighted) {
        rectangleStyle = {
            backgroundColor: 'rgb(224, 224, 224)',
        };
    } else {
        rectangleStyle = {
            backgroundColor: props.rule.symbols[0].brushes[0].fillColor,
        };
    }

    return (<button
        className={stopRectangleClasses}
        onClick={props.onClick}
    >
        <div className="shaded-color-palette-vertical__color-rectangle" style={rectangleStyle} />
        <div className="shaded-color-palette-vertical__label">{props.rule.title}</div>
    </button>);
};

VerticalShadedStop.propTypes = {
    onClick: PropTypes.func,
    rule: PropTypes.object.isRequired,
    isInsufficientDataRule: PropTypes.bool,
    highlighted: PropTypes.bool,
    legendHighlighted: PropTypes.bool,
};

VerticalShadedStop.defaultProps = {
    isInsufficientDataRule: false,
    legendHighlighted: false,
    highlighted: false,
    onClick: undefined,
    onMouseLeaveColor: undefined,
};

export default withHighlight(VerticalShadedStop);
