import uuid from 'node-uuid';

class SingleColorBubbleLayerStyle {
    constructor(values) {
        this._isHidden = false;
        this._bubbleSizeFactor = 1;
        this._bubbleColor = chroma.random().toString();
        const originalRGB = chroma(this._bubbleColor).rgb();
        this._negativeBubbleColor = chroma(255 - originalRGB[0], 255 - originalRGB[1], 255 - originalRGB[2]).hex().toString();
        this._id = uuid.v4();

        if (values) {
            Object.keys(values).forEach(k => {
                if (this.constructor.prototype.hasOwnProperty(k)) {
                    this[k] = values[k];
                }
            });
        }
    }

    /** @type {'single-color-bubble'} */
    get type() {
        return 'single-color-bubble';
    }

    get id() {
        return this._id;
    }

    get isHidden() {
        return this._isHidden;
    }

    set isHidden(isHidden) {
        this._isHidden = isHidden;
    }

    get bubbleColor() {
        return this._bubbleColor;
    }

    set bubbleColor(bubbleColor) {
        this._bubbleColor = bubbleColor;
    }

    get negativeBubbleColor() {
        return this._negativeBubbleColor;
    }

    set negativeBubbleColor(negativeBubbleColor) {
        this._negativeBubbleColor = negativeBubbleColor;
    }

    get bubbleSizeFactor() {
        return this._bubbleSizeFactor;
    }

    set bubbleSizeFactor(bubbleSizeFactor) {
        this._bubbleSizeFactor = bubbleSizeFactor;
    }

    equals(that) {
        return this.type === that.type && this.isHidden === that.isHidden && this.bubbleColor === that.bubbleColor &&
        this.bubbleSizeFactor === that.bubbleSizeFactor;
    }

    clone() {
        return new SingleColorBubbleLayerStyle({
            bubbleSizeFactor: this.bubbleSizeFactor,
            bubbleColor: this.bubbleColor,
            isHidden: this.isHidden,
            negativeBubbleColor: this.negativeBubbleColor,
        });
    }

    toJSON() {
        return {
            id: this.id,
            type: this.type,
            bubbleSizeFactor: this.bubbleSizeFactor,
            bubbleColor: this.bubbleColor,
            isHidden: this.isHidden,
            negativeBubbleColor: this.negativeBubbleColor,
        };
    }
}

export default SingleColorBubbleLayerStyle;
