import GLU from '../glu2.js/src/index';

class VariableApi extends GLU.Api {
  constructor(endpoint) {
    super(endpoint);

    this.createApiActions(
      {
        name: 'searchVariable',
        method: GLU.Api.Post,
        path: '/variables/search',
        credentials: false,
      },
      {
        name: 'getFeaturedCategoriesData',
        method: GLU.Api.Get,
        path: '/map-featured-variables',
        credentials: true,
      },
    );
  }
}

export default VariableApi;
