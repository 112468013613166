import React from 'react';
import PropTypes from 'prop-types';
import MarkerSize from '../../../enums/MarkerSize';
import NumberPropertyEditor from './propertyEditors/NumberPropertyEditor';
import ColorPropertyEditor from './propertyEditors/ColorPropertyEditor';

class EditLabel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            useFill: props.annotation.useFill,
            haloWidth: props.annotation.haloWidth,
            haloBlur: props.annotation.haloBlur,
            rotationAngle: props.annotation.rotationAngle,
            textSize: Object.keys(MarkerSize).indexOf(props.annotation.textSize.toUpperCase()),
            opacity: props.annotation.opacity,
        };
    }

    onTextSizeChanged = index => {
        const newTextSize = Object.keys(MarkerSize)[index];
        this.setState({ textSize: index });
        this.props.onChange({
            textSize: newTextSize,
        });
    }

    onTextColorChanged = textColor => {
        this.props.onChange({
            textColor,
        });
    }

    onOpacityChanged = opacity => {
        this.setState({ opacity: opacity / 100 });
        this.props.onChange({
            opacity: opacity / 100,
            useFill: this.props.annotation.useFill,
        });
    }

    onUseFillChanged = checked => {
        this.setState({ useFill: checked });
        this.props.onChange({
            useFill: checked,
            haloWidth: this.state.haloWidth,
            haloBlur: this.state.haloBlur,
            opacity: this.props.annotation.opacity,
        });
    }

    onFillColorChanged = fillColor => {
        this.props.onChange({
            fillColor,
        });
    }

    onHaloWidthChanged = haloWidth => {
        this.setState({ haloWidth });
        this.props.onChange({
            haloWidth,
        });
    }

    onHaloBlurChanged = haloBlur => {
        this.setState({ haloBlur });
        this.props.onChange({
            haloBlur,
        });
    }

    onRotationChanged = rotationAngle => {
        this.setState({ rotationAngle });
        this.props.onChange({
            rotationAngle,
        });
    }

    render() {
        const { annotation } = this.props;
        const { textSize, rotationAngle, opacity, useFill, haloWidth, haloBlur } = this.state;

        return (<div className="flex-it column">
            <ColorPropertyEditor
                key="color-editor"
                onColorChanged={this.onTextColorChanged}
                color={annotation.textColor}
                label="Color"
            />
            <NumberPropertyEditor
                key="size-editor"
                label="Size"
                value={textSize}
                onChange={this.onTextSizeChanged}
                min={0}
                max={4}
            />
            <NumberPropertyEditor
                label="Rotation"
                value={rotationAngle}
                onChange={this.onRotationChanged}
                min={-180}
                max={180}
            />
            <NumberPropertyEditor
                label="Opacity"
                value={opacity * 100}
                onChange={this.onOpacityChanged}
                min={0}
                max={100}
                unit="%"
            />
            <ColorPropertyEditor
                enabled={useFill}
                onChangeAvailability={this.onUseFillChanged}
                onColorChanged={this.onFillColorChanged}
                color={annotation.fillColor}
                label="Halo"
            />
            {useFill && <NumberPropertyEditor
                label="Width"
                value={haloWidth}
                onChange={this.onHaloWidthChanged}
                min={1}
                max={10}
            />}
            {useFill &&
            <NumberPropertyEditor
                label="Blur"
                value={haloBlur}
                onChange={this.onHaloBlurChanged}
                min={1}
                max={10}
            />}
        </div>);
    }
}

EditLabel.propTypes = {
    annotation: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default EditLabel;
