import React from 'react';
import PropTypes from 'prop-types';
import StrokeWeight from '../../../enums/StrokeWeight';
import NumberPropertyEditor from './propertyEditors/NumberPropertyEditor';
import ColorPropertyEditor from './propertyEditors/ColorPropertyEditor';

class EditHotspot extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            strokeWeight: StrokeWeight[props.annotation.strokeWeight.toUpperCase()].value,
            opacity: props.annotation.opacity,
        };
    }

    onOpacityChanged = opacity => {
        this.setState({ opacity: opacity / 100 });
        this.props.onChange({
            opacity: opacity / 100,
            useFill: this.props.annotation.useFill,
        });
    }

    onStrokeWeightChange = strokeWeight => {
        this.setState({ strokeWeight });
        this.props.onChange({
            strokeWeight: Object.keys(StrokeWeight)[strokeWeight],
        });
    }

    onStrokeColorApplied = strokeColor => {
        this.props.onChange({
            strokeColor,
        });
    }

    render() {
        return (<div className="flex-it column">
            <ColorPropertyEditor
                onColorChanged={this.onStrokeColorApplied}
                color={this.props.annotation.strokeColor}
                label="Color"
            />
            <NumberPropertyEditor
                label="Weight"
                value={this.state.strokeWeight}
                onChange={this.onStrokeWeightChange}
                min={0}
                max={5}
            />
            <NumberPropertyEditor
                label="Opacity"
                value={this.state.opacity * 100}
                onChange={this.onOpacityChanged}
                min={0}
                max={100}
                unit="%"
            />
        </div>
        );
    }
}

EditHotspot.propTypes = {
    annotation: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default EditHotspot;
