// @ts-check
import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import BusComponent from '../../BusComponent';
import { hasParentNode } from '../../../helpers/Util';
import VisualizationType from '../../../enums/VisualizationType';

import ChangeVisualization from './ChangeVisualization';
import ChangeVisualizationCOT from './ChangeVisualizationCOT';
import ChangeVisualizationSimple from './ChangeVisualizationSimple';

class AutoAdjustMenu extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            open: false,
        };
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.onMouseEvent);
    }

    onMouseEvent = e => {
        if (hasParentNode(e.target, this.trigger)) {
            return;
        }
        this.onToggle();
    };

    onToggle = () => {
        this.setState(
            ({ open }) => ({ open: !open }),
            () => {
                if (this.state.open) {
                    window.addEventListener('click', this.onMouseEvent);
                } else {
                    window.removeEventListener('click', this.onMouseEvent);
                }
            }
        );
    };

    showDataClassificationModal = () => {
        this.emit(
            'DATA_CLASSIFICATION_POPUP_REQUEST',
            this.props.mapInstance.id
        );
        this.onToggle();
    };

    showChangeColorPalette = () => {
        this.emit(
            'REQUEST_VISUALIZATION_TYPE_DROPDOWN_TOGGLE',
            { mapInstanceId: this.props.mapInstance.id }
        );
        // Visualization type dropdown is still not loaded - and its glu bus events are not yet bound
        // therefore we need to wait a milisecond before emitting `OPEN_COLOR_PALETTE_EDITOR`
        setTimeout(() => this.emit('OPEN_COLOR_PALETTE_EDITOR'));
        this.onToggle();
    }

    render() {
        const { intl, mapInstance, showPercentageControl } = this.props;
        const { open } = this.state;

        const ariaLabel = open ?
            intl.formatMessage({ id: 'showHide.hide.autoAdjustMenu' }) :
            intl.formatMessage({ id: 'showHide.show.autoAdjustMenu' });

        const allowEditCutpoints = mapInstance.dataTheme.visualizationType !== VisualizationType.BUBBLES || !mapInstance.dataTheme.isBubblesNumber && false; // Remove cutpoints popup for now
        let changeVisualization;
        if (mapInstance.dataTheme.isChangeOverTimeApplied) {
            changeVisualization = <ChangeVisualizationCOT mapInstanceId={mapInstance.id} dataTheme={mapInstance.dataTheme} />;
        } else {
            changeVisualization = showPercentageControl ?
                <ChangeVisualization mapInstanceId={mapInstance.id} dataTheme={mapInstance.dataTheme} /> :
                <ChangeVisualizationSimple mapInstanceId={mapInstance.id} dataTheme={mapInstance.dataTheme} />;
        }

        return (
            <div className="flex-it auto-adjust__menu">
                <button
                    ref={e => { this.trigger = e; }}
                    className="btn-icon btn-icon--small"
                    onClick={this.onToggle}
                    aria-label={ariaLabel}
                >
                    <i className="material-icons" aria-hidden>
                        more_horiz
                    </i>
                </button>
                {open && (
                    <div className="auto-adjust__popover auto-adjust__menu__popover">
                        {changeVisualization}
                        <div className="actions">
                            <button
                                className="btn-link"
                                onClick={this.showChangeColorPalette}
                            >
                                {intl.formatMessage({ id: 'legend.changeColorPalette' })}
                            </button>
                            {allowEditCutpoints && (<button
                                className="btn-link"
                                onClick={this.showDataClassificationModal}
                            >
                                {intl.formatMessage({ id: 'legend.editCutpoints' })}
                            </button>)}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

AutoAdjustMenu.propTypes = {
    intl: PropTypes.object.isRequired,
    mapInstance: PropTypes.object.isRequired,
    showPercentageControl: PropTypes.bool.isRequired,
};

export default injectIntl(AutoAdjustMenu);
