import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

/**
 * @param {object} props
 * @param {string} props.query
 */
const SearchEmpty = ({ query }) => (
    <div className="data-browser-search-empty">
        <FormattedMessage
            id={'dataBrowser.noSearchResults'}
            values={{
                query: <strong>{query}</strong>,
            }}
        />
        <br />
        <br />
        <FormattedMessage
            id={'dataBrowser.noSearchResultsHint'}
            values={{
                br: <br />,
            }}
        />
    </div>
);

SearchEmpty.propTypes = {
    query: PropTypes.string.isRequired,
};

export default SearchEmpty;
