import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import VisualizationTypeDescription from '../../../../enums/VisualizationTypeDescriptions';

import VisualizationTypes from '../../../../enums/VisualizationType';
import VisualizationTypeListItem from './VisualizationTypeListItem';

const VisualizationTypeList = props => {
    const {
        mapInstance,
        onChangeVisualizationType,
        shadedVisualizationTypeEnabled,
        bubbleVisualizationTypeEnabled,
        dotDensityVisualizationTypeEnabled,
        intl,
    } = props;

    const { visualizationType } = mapInstance.dataTheme;

    return (
        <div className="visualization-editor__section">
            <h4 className="visualization-editor__title">
                {intl.formatMessage({ id: 'dataBrowser.visualizationType' })}
            </h4>
            <div className="visualization-editor__types">
                <VisualizationTypeListItem
                    onClick={onChangeVisualizationType}
                    type={VisualizationTypes.SHADED_AREA}
                    title={props.intl.formatMessage({ id: 'dataBrowser.shadedArea' })}
                    icon={<i className="socex-icon-choropletth-v2" />}
                    selected={visualizationType === VisualizationTypes.SHADED_AREA}
                    disabled={!shadedVisualizationTypeEnabled}
                />
                <VisualizationTypeListItem
                    onClick={onChangeVisualizationType}
                    type={VisualizationTypes.BUBBLES}
                    title={props.intl.formatMessage({ id: 'dataBrowser.bubbles' })}
                    icon={<i className="socex-icon-bubble-v2" />}
                    selected={visualizationType === VisualizationTypes.BUBBLES}
                    disabled={!bubbleVisualizationTypeEnabled}
                />
                <VisualizationTypeListItem
                    onClick={onChangeVisualizationType}
                    type={VisualizationTypes.DOT_DENSITY}
                    title={props.intl.formatMessage({ id: 'dataBrowser.dotDensity' })}
                    icon={<i className="socex-icon-dotdensity-v2" />}
                    selected={visualizationType === VisualizationTypes.DOT_DENSITY}
                    disabled={!dotDensityVisualizationTypeEnabled}
                />
            </div>
            <div className="visualization-editor__description">
                {props.intl.formatMessage({ id: VisualizationTypeDescription[visualizationType] })}
            </div>
        </div>
    );
};

VisualizationTypeList.propTypes = {
    mapInstance: PropTypes.object.isRequired,
    shadedVisualizationTypeEnabled: PropTypes.bool.isRequired,
    bubbleVisualizationTypeEnabled: PropTypes.bool.isRequired,
    dotDensityVisualizationTypeEnabled: PropTypes.bool.isRequired,
    onChangeVisualizationType: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(VisualizationTypeList);
