'use strict';var ParsingError = require('./parsing_error');
var ParsingContext = require('./parsing_context');
var EvaluationContext = require('./evaluation_context');
var ref = require('./compound_expression');
var CompoundExpression = ref.CompoundExpression;
var Step = require('./definitions/step');
var Interpolate = require('./definitions/interpolate');
var Coalesce = require('./definitions/coalesce');
var Let = require('./definitions/let');
var definitions = require('./definitions');
var isConstant = require('./is_constant');
var RuntimeError = require('./runtime_error');
var ref$1 = require('../util/result');
var success = ref$1.success;
var error = ref$1.error;
function isExpression(expression) {
    return Array.isArray(expression) && expression.length > 0 && typeof expression[0] === 'string' && expression[0] in definitions;
}
function createExpression(expression, propertySpec, options) {
    if (options === void 0)
        options = {};
    var parser = new ParsingContext(definitions, [], getExpectedType(propertySpec));
    var parsed = parser.parse(expression);
    if (!parsed) {
        return error(parser.errors);
    }
    var evaluator = new EvaluationContext();
    var evaluate;
    if (options.handleErrors === false) {
        evaluate = function (globals, feature) {
            evaluator.globals = globals;
            evaluator.feature = feature;
            return parsed.evaluate(evaluator);
        };
    } else {
        var warningHistory = {};
        var defaultValue = getDefaultValue(propertySpec);
        var enumValues;
        if (propertySpec.type === 'enum') {
            enumValues = propertySpec.values;
        }
        evaluate = function (globals, feature) {
            evaluator.globals = globals;
            evaluator.feature = feature;
            try {
                var val = parsed.evaluate(evaluator);
                if (val === null || val === undefined) {
                    return defaultValue;
                }
                if (enumValues && !(val in enumValues)) {
                    throw new RuntimeError('Expected value to be one of ' + Object.keys(enumValues).map(function (v) {
                        return JSON.stringify(v);
                    }).join(', ') + ', but found ' + JSON.stringify(val) + ' instead.');
                }
                return val;
            } catch (e) {
                if (!warningHistory[e.message]) {
                    warningHistory[e.message] = true;
                    if (typeof console !== 'undefined') {
                        console.warn(e.message);
                    }
                }
                return defaultValue;
            }
        };
    }
    return success({
        evaluate: evaluate,
        parsed: parsed
    });
}
function createPropertyExpression(expression, propertySpec, options) {
    if (options === void 0)
        options = {};
    expression = createExpression(expression, propertySpec, options);
    if (expression.result === 'error') {
        return expression;
    }
    var ref = expression.value;
    var evaluate = ref.evaluate;
    var parsed = ref.parsed;
    var isFeatureConstant = isConstant.isFeatureConstant(parsed);
    if (!isFeatureConstant && !propertySpec['property-function']) {
        return error([new ParsingError('', 'property expressions not supported')]);
    }
    var isZoomConstant = isConstant.isGlobalPropertyConstant(parsed, ['zoom']);
    if (!isZoomConstant && propertySpec['zoom-function'] === false) {
        return error([new ParsingError('', 'zoom expressions not supported')]);
    }
    var zoomCurve = findZoomCurve(parsed);
    if (!zoomCurve && !isZoomConstant) {
        return error([new ParsingError('', '"zoom" expression may only be used as input to a top-level "step" or "interpolate" expression.')]);
    } else if (zoomCurve instanceof ParsingError) {
        return error([zoomCurve]);
    } else if (zoomCurve instanceof Interpolate && propertySpec['function'] === 'piecewise-constant') {
        return error([new ParsingError('', '"interpolate" expressions cannot be used with this property')]);
    }
    if (!zoomCurve) {
        return success(isFeatureConstant ? {
            kind: 'constant',
            parsed: parsed,
            evaluate: evaluate
        } : {
            kind: 'source',
            parsed: parsed,
            evaluate: evaluate
        });
    }
    var interpolationFactor = zoomCurve instanceof Interpolate ? Interpolate.interpolationFactor.bind(undefined, zoomCurve.interpolation) : function () {
        return 0;
    };
    var zoomStops = zoomCurve.labels;
    return success(isFeatureConstant ? {
        kind: 'camera',
        parsed: parsed,
        evaluate: evaluate,
        interpolationFactor: interpolationFactor,
        zoomStops: zoomStops
    } : {
        kind: 'composite',
        parsed: parsed,
        evaluate: evaluate,
        interpolationFactor: interpolationFactor,
        zoomStops: zoomStops
    });
}
module.exports = {
    isExpression: isExpression,
    createExpression: createExpression,
    createPropertyExpression: createPropertyExpression
};
function findZoomCurve(expression) {
    var result = null;
    if (expression instanceof Let) {
        result = findZoomCurve(expression.result);
    } else if (expression instanceof Coalesce) {
        for (var i = 0, list = expression.args; i < list.length; i += 1) {
            var arg = list[i];
            result = findZoomCurve(arg);
            if (result) {
                break;
            }
        }
    } else if ((expression instanceof Step || expression instanceof Interpolate) && expression.input instanceof CompoundExpression && expression.input.name === 'zoom') {
        result = expression;
    }
    if (result instanceof ParsingError) {
        return result;
    }
    expression.eachChild(function (child) {
        var childResult = findZoomCurve(child);
        if (childResult instanceof ParsingError) {
            result = childResult;
        } else if (!result && childResult) {
            result = new ParsingError('', '"zoom" expression may only be used as input to a top-level "step" or "interpolate" expression.');
        } else if (result && childResult && result !== childResult) {
            result = new ParsingError('', 'Only one zoom-based "step" or "interpolate" subexpression may be used in an expression.');
        }
    });
    return result;
}
var ref$2 = require('./types');
var ColorType = ref$2.ColorType;
var StringType = ref$2.StringType;
var NumberType = ref$2.NumberType;
var BooleanType = ref$2.BooleanType;
var ValueType = ref$2.ValueType;
var array = ref$2.array;
function getExpectedType(spec) {
    var types = {
        color: ColorType,
        string: StringType,
        number: NumberType,
        enum: StringType,
        boolean: BooleanType
    };
    if (spec.type === 'array') {
        return array(types[spec.value] || ValueType, spec.length);
    }
    return types[spec.type] || null;
}
var ref$3 = require('../function');
var isFunction = ref$3.isFunction;
var ref$4 = require('./values');
var Color = ref$4.Color;
function getDefaultValue(spec) {
    if (spec.type === 'color' && isFunction(spec.default)) {
        return new Color(0, 0, 0, 0);
    } else if (spec.type === 'color') {
        return Color.parse(spec.default) || null;
    } else if (spec.default === undefined) {
        return null;
    } else {
        return spec.default;
    }
}