import React from 'react';
import PropTypes from 'prop-types';

import AnnotationIcon from './AnnotationIcon';

import AnnotationCardHeaderExpanded from './AnnotationCardHeaderExpanded';

const AnnotationCardHeader = props => {
    if (props.expanded) {
        return (<AnnotationCardHeaderExpanded
            annotation={props.annotation}
            title={props.title}
            onTitleChange={props.onTitleChange}
            onToggleExpansion={props.onToggleExpansion}
        />);
    }
    return (
        <div
            onClick={props.onToggleExpansion}
            className="annotation-card__header"
        >
            <div className="flex-it center flex-start grow">
                <AnnotationIcon
                    annotation={props.annotation}
                    className="annotation-card__icon"
                />
                <div className="body-1 annotation-card__title">
                    {props.title}
                </div>
            </div>
            <button className="btn-icon">
                <i className="material-icons" aria-label="Down arrow icon">arrow_drop_down</i>
            </button>
        </div>
    );
};

AnnotationCardHeader.propTypes = {
    expanded: PropTypes.bool,
    onToggleExpansion: PropTypes.func.isRequired,
    onTitleChange: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    annotation: PropTypes.object.isRequired,
};
AnnotationCardHeader.defaultProps = {
    expanded: false,
};

export default AnnotationCardHeader;
