import React from 'react';
import PropTypes from 'prop-types';

import LinkTab from './tabs/LinkTab';

const SharePopupEmbed = props => (
    <LinkTab snapshot={props.snapshot} isPublic />
);

SharePopupEmbed.propTypes = {
    snapshot: PropTypes.object.isRequired,
};

export default SharePopupEmbed;
