import React from 'react';
import BusComponent from '../../BusComponent';

import TextInput from '../../form/TextInput';
import Checkbox from '../../form/Checkbox';
import AnnotationIcon from '../AnnotationIcon';

class LegendEditItem extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            title: props.legendItem.title,
        };
    }

    onItemChecked = checked => {
        this.emit('CHANGE_LEGEND_ITEM', {
            legendItem: this.props.legendItem,
            propsToUpdate: {
                included: checked,
            },
        });
    }

    onTitleChange = title => {
        this.setState({ title });
        // change title with timeout
        this.emit('CHANGE_LEGEND_ITEM', {
            legendItem: this.props.legendItem,
            propsToUpdate: {
                title: title || 'Untitled',
            },
        });
    }

    render() {
        const { legendItem } = this.props;

        return (<div className="legend-item flex-it center space-between">
            <div className="flex-it center flex-start grow">
                <AnnotationIcon
                    annotation={legendItem}
                    iconColor={legendItem.color}
                    className="annotation-card__icon"
                />
                <TextInput
                    value={this.state.title}
                    className="annotation-card__title-input grow"
                    placeholder="Untitled"
                    onChange={this.onTitleChange}
                />
            </div>
            <div className="legend-item__action">
                <Checkbox
                    checked={legendItem.included}
                    onCheck={this.onItemChecked}
                />
            </div>
        </div>);
    }
}

export default LegendEditItem;
