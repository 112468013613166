import React from 'react';

function googleTagManagerEvents(WrappedComponent, events) {
    return class WithGoogleTagEvents extends React.Component {
        componentDidMount() {
            events.forEach(({ name, value }) => {
                this.component.setAttribute(name, value);
            });
        }

        setCustomAttribute = (name, value) => {
            this.component.setAttribute(name, value);
        }

        render() {
            return (<div ref={c => { this.component = c; }}><WrappedComponent {...this.props} /></div>);
        }

    };
}

export default googleTagManagerEvents;
