import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import IconMenu from '../iconMenu/IconMenu';

const UserUploadItemOptions = props => (
    <IconMenu
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        targetOrigin={{ vertical: 'top', horizontal: 'left' }}
        className="icon-menu-wrapper icon-menu-wrapper--user-upload"
        tabIndex="0"
    >
        <div className="material-menu">
            <div className="material-menu__heading">
                {props.intl.formatMessage({ id: 'exportLayerAs' })}
            </div>
            <div onClick={props.onGeoJSONFormatClicked} className="material-menu__item">
                <span>GeoJSON</span>
            </div>
            <div className="material-menu__divider" />
            <div onClick={props.onDeleteClick} className="material-menu__item">
                <FormattedMessage id="delete" />
            </div>
        </div>
    </IconMenu>
);

UserUploadItemOptions.propTypes = {
    onGeoJSONFormatClicked: PropTypes.func.isRequired,
    onDeleteClick: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
};
UserUploadItemOptions.defaultProps = {};

export default injectIntl(UserUploadItemOptions);
