import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import uuid from 'node-uuid';

class Checkbox extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.id = uuid.v4();
    }

    onChange = e => {
        if (typeof this.props.onCheck === 'function') {
            e.stopPropagation();
            this.props.onCheck(!this.props.checked);
        }
    }

    render() {
        const {
            checked,
            checkedIcon,
            uncheckedIcon,
            indeterminateIcon,
            disabled,
            title,
            label,
            light,
            className,
            clickable } = this.props;

        const classes = classNames('checkbox flex-it center no-shrink', className, {
            'checkbox--disabled': disabled,
        });
        // Note this is purposely verbose for better reading
        let icon;
        if (checked === true) {
            icon = checkedIcon;
        } else if (checked === false) {
            icon = uncheckedIcon;
        } else {
            icon = indeterminateIcon;
        }
        const iconClass = light ? 'btn-icon--light' : 'btn-icon--green';

        return (<div className={classes} title={title} onClick={this.onChange}>
            <button
                className={classNames('btn-icon', {
                    [`${iconClass}`]: checked === true || checked === undefined,
                    'btn-icon--disabled': disabled && !light,
                })}
                tabIndex={clickable ? undefined : -1}
                aria-hidden={!clickable}
                aria-label="checkbox icon"
            >
                {icon}
            </button>
            <label htmlFor={this.id}>{label}</label>
        </div>);
    }
}

Checkbox.propTypes = {
    label: PropTypes.string,
    title: PropTypes.string,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    onCheck: PropTypes.func,
    checkedIcon: PropTypes.element,
    indeterminateIcon: PropTypes.element,
    uncheckedIcon: PropTypes.element,
    className: PropTypes.string,
    light: PropTypes.bool,
    clickable: PropTypes.bool,
};

Checkbox.defaultProps = {
    disabled: false,
    checked: undefined,
    onCheck: undefined,
    title: undefined,
    label: '',
    className: '',
    light: false,
    checkedIcon: <i className="material-icons">check_box</i>,
    indeterminateIcon: <i className="material-icons">indeterminate_check_box</i>,
    uncheckedIcon: <i className="material-icons">check_box_outline_blank</i>,
    clickable: true,
};

export default Checkbox;
