import React from 'react';
import { injectIntl } from 'react-intl';
import BusComponent from '../BusComponent';
import ColorPaletteEditorType from '../../enums/ColorPaletteEditorType';
import ColorPaletteType from '../../enums/ColorPaletteType';
import ColorPaletteList from './CopyFromColorPaletteList';
import DropdownMenu from '../dropdown/DropdownMenu';
import GradientMultiEditor from './GradientMultiEditor';
import GradientEditor from './GradientEditor';
import ColorMultiEditor from './ColorMultiEditor';
import ColorSingleEditor from './ColorSingleEditor';
import { focusFirstChild } from '../../helpers/Util';
import uuid from 'node-uuid';
import TextInput from '../form/TextInput';

class ColorPaletteEditor extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            colorPalette: props.colorPalette.clone(),
            editorType: ColorPaletteEditor.getEditorType(props.colorPalette.type),
            title: props.colorPalette.title,
        };
    }

    componentDidMount() {
        focusFirstChild(this.colorPaletteEditorWrapper);
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    onTitleChange = title => {
        this.setState({
            title,
        });
    }

    onRowClicked = palette => {
        const clonedPalette = palette.clone();
        if (this.props.editMode) {
            clonedPalette.id = this.props.colorPalette.id;
            clonedPalette.type = this.props.colorPalette.type;
            clonedPalette.title = this.props.colorPalette.title;
        }
        this.setState({
            colorPalette: clonedPalette,
        });
        this.emit('CLOSE_DROPDOWN');
    }

    onDoneClick = () => {
        const colorPalette = this.state.colorPalette;
        colorPalette.title = this.colorPaletteTitle.value !== undefined && this.colorPaletteTitle.value !== '' ? this.colorPaletteTitle.value : 'Untitled';
        colorPalette.id = this.props.editMode ? colorPalette.id : uuid.v4();
        colorPalette.type = this.props.editMode ? colorPalette.type : ColorPaletteEditor.getColorPaletteType(colorPalette.type);
        if (this.props.editMode) {
            this.emit('EDIT_COLOR_PALETTE_REQUEST', {
                colorPalette,
                originalColorPalette: this.props.colorPalette,
                mapInstanceId: this.props.mapInstanceId,
            });
        } else {
            this.emit('CREATE_COLOR_PALETTE_REQUEST', {
                colorPalette,
                mapInstanceId: this.props.mapInstanceId,
            });
        }
        this.bus.emit('CLOSE_MODAL');
    }

    onDeletePaletteClick = () => {
        this.emit('DELETE_COLOR_PALETTE_REQUEST', {
            colorPalette: this.state.colorPalette,
            mapInstanceId: this.props.mapInstanceId,
        });
        this.bus.emit('CLOSE_MODAL');
    }

    onApplyColorPalette = colorPalette => {
        this.setState({
            colorPalette,
        });
    }

    onApplyNoDataColor = color => {
        const colorPalette = this.state.colorPalette.clone();
        colorPalette.nullDataColor = color;
        this.setState({
            colorPalette,
        });
    }

    onApplyInsufficientDataColor = color => {
        const colorPalette = this.state.colorPalette.clone();
        colorPalette.insufficientDataColor = color;
        this.setState({
            colorPalette,
        });
    }


    static getEditorType(colorPaletteType) {
        switch (colorPaletteType.toUpperCase()) {
        case ColorPaletteType.POLYGON_DIVERGING:
        case ColorPaletteType.POLYGON_SEQUENTIAL:
        case ColorPaletteType.POLYGON_USER_DEFINED:
        case ColorPaletteType.BUBBLE_SEQUENTIAL:
        case ColorPaletteType.BUBBLE_DIVERGING:
        case ColorPaletteType.BUBBLE_USER_DEFINED:
            return ColorPaletteEditorType.GRADIENT;
        case ColorPaletteType.MULTI_POLYGON:
        case ColorPaletteType.MULTI_POLYGON_USER_DEFINED:
            return ColorPaletteEditorType.GRADIENT_MULTI;
        case ColorPaletteType.VALUE_DOT_DENSITY:
        case ColorPaletteType.VALUE_DOT_DENSITY_USER_DEFINED:
            return ColorPaletteEditorType.COLOR_MULTI;
        case ColorPaletteType.BUBBLE_SINGLE_COLOR:
        case ColorPaletteType.BUBBLE_USER_DEFINED_SINGLE_COLOR:
        case ColorPaletteType.DOT_DENSITY:
        case ColorPaletteType.DOT_DENSITY_USER_DEFINED:
            return ColorPaletteEditorType.COLOR_SINGLE;
        }
        return 'Unknown type';
    }

    static getColorPaletteType(colorPaletteType) {
        switch (colorPaletteType.toUpperCase()) {
        case ColorPaletteType.POLYGON_DIVERGING:
        case ColorPaletteType.POLYGON_SEQUENTIAL:
        case ColorPaletteType.POLYGON_USER_DEFINED:
            return ColorPaletteType.POLYGON_USER_DEFINED;
        case ColorPaletteType.BUBBLE_SEQUENTIAL:
        case ColorPaletteType.BUBBLE_DIVERGING:
        case ColorPaletteType.BUBBLE_USER_DEFINED:
            return ColorPaletteType.BUBBLE_USER_DEFINED;
        case ColorPaletteType.MULTI_POLYGON:
        case ColorPaletteType.MULTI_POLYGON_USER_DEFINED:
            return ColorPaletteType.MULTI_POLYGON_USER_DEFINED;
        case ColorPaletteType.VALUE_DOT_DENSITY:
        case ColorPaletteType.VALUE_DOT_DENSITY_USER_DEFINED:
            return ColorPaletteType.VALUE_DOT_DENSITY_USER_DEFINED;
        case ColorPaletteType.BUBBLE_SINGLE_COLOR:
        case ColorPaletteType.BUBBLE_USER_DEFINED_SINGLE_COLOR:
            return ColorPaletteType.BUBBLE_USER_DEFINED_SINGLE_COLOR;
        case ColorPaletteType.DOT_DENSITY:
        case ColorPaletteType.DOT_DENSITY_USER_DEFINED:
            return ColorPaletteType.DOT_DENSITY_USER_DEFINED;
        }
        return 'Unknown type';
    }

    render() {
        let editor;
        const { intl, editMode } = this.props;
        const colorPalettesList = this.props.colorPalettesList;
        switch (this.state.editorType) {
        case ColorPaletteEditorType.GRADIENT:
            editor = (<GradientEditor
                colorPalette={this.state.colorPalette}
                applyNoDataColor={this.onApplyNoDataColor}
                applyInsufficientDataColor={this.onApplyInsufficientDataColor}
                applyColorPalette={this.onApplyColorPalette}
            />);
            break;
        case ColorPaletteEditorType.GRADIENT_MULTI:
            editor = (<GradientMultiEditor
                colorPalette={this.state.colorPalette}
                applyNoDataColor={this.onApplyNoDataColor}
                applyInsufficientDataColor={this.onApplyInsufficientDataColor}
                applyColorPalette={this.onApplyColorPalette}
            />);
            break;
        case ColorPaletteEditorType.COLOR_SINGLE:
            editor = (<ColorSingleEditor
                colorPalette={this.state.colorPalette}
                applyColorPalette={this.onApplyColorPalette}
            />);
            break;
        case ColorPaletteEditorType.COLOR_MULTI:
            editor = (<ColorMultiEditor
                colorPalette={this.state.colorPalette}
                applyColorPalette={this.onApplyColorPalette}
            />);
            break;
        }
        const copyColorsButton = (
            <button className="copy-colors-button border-box rounded padded-5 clickable">
                {intl.formatMessage({ id: 'dataBrowser.copyColorsFromAnExistingPalette' })}
                <i className="socex-icon-dropdown" />
            </button>);
        const dropdownPalettePicker = (
            <DropdownMenu
                name="color-palette-editor-color-list"
                anchor={copyColorsButton}
                className="dropdown__menu--palettes"
                destroyOnHide
            >
                <div className="palettes vertical-scrollbar">
                    <ColorPaletteList
                        colorPalettesCollection={colorPalettesList}
                        onColorPaletteClick={this.onRowClicked}
                    />
                </div>
            </DropdownMenu>
        );
        const title = editMode !== undefined && editMode ? intl.formatMessage({ id: 'visualisationType.editColorPalette' }) : intl.formatMessage({ id: 'visualisationType.createNewPalette' });
        return (<div className="color-palette-editor" ref={ref => { this.colorPaletteEditorWrapper = ref; }}>
            <div className="panel__header panel__header--static light-background flex-it center space-between">
                <h4>{title}</h4>
                <button className="btn-icon modal-header__modal-close">
                    <i className="material-icons">close</i>
                </button>
            </div>
            <div className="wrapper">
                <TextInput
                    initialFocus
                    elementRef={colorPaletteTitle => { this.colorPaletteTitle = colorPaletteTitle; }}
                    placeholder={intl.formatMessage({ id: 'dataBrowser.paletteTitle' })}
                    oldStyle
                    onChange={this.onTitleChange}
                    value={this.state.title}
                />
                <span className="color-palette-editor__text">
                    {intl.formatMessage({ id: 'dataBrowser.paletteColors' })}
                </span>
                {dropdownPalettePicker}
            </div>
            {editor}
            <div className="panel__header light-background flex-it center space-between">
                {editMode && <button className="btn-icon" onClick={this.onDeletePaletteClick}>
                    <i className="material-icons">delete</i>
                </button>}
                <div className="flex-it center grow flex-end">
                    <button className="btn-flat modal-header__modal-close margin-right-10">
                        {intl.formatMessage({ id: 'cancel' })}
                    </button>
                    <button className="btn-raised" onClick={this.onDoneClick}>
                        {intl.formatMessage({ id: 'done' })}
                    </button>
                </div>
            </div>
        </div>);
    }
}

export default injectIntl(ColorPaletteEditor);
