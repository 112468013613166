import React from 'react';
import classNames from 'classnames';

import BusComponent from '../BusComponent';
import MapViewer from '../MapViewer';
import ApplicationFrameType from '../../enums/ApplicationFrameType';
import ApplicationMode from '../../enums/ApplicationMode';
import Orientation from '../../enums/Orientation';
import AppConfig from '../../appConfig';
import MapPanelContainer from '../mapPanel/MapPanelContainer';
import SearchBoxPanel from '../searchBox/SearchBoxPanel';
import VisualizationLegend from '../visualizationLegend/VisualizationLegend';
import FilterAndMaskIndicator from '../mapIndicators/FilterAndMaskIndicator';
import MapLogo from '../mapControls/MapLogo';
import MapControls from '../mapControls/MapControls';
import { resizeCanvas } from '../../helpers/Util';
import { HelpTourTargets } from '../../enums/HelpTourDefinitions';
import DataBrowser from '../dataBrowser/DataBrowser';
import CondensedFrameComponents from './CondensedFrameComponents';
import MapInfoBubbleSwitch from '../mapControls/MapInfoBubbleSwitch';
import ReportIframe from '../locationPanel/ReportIframe';
import PointsPanel from '../pointsPanel/PointsPanel';
import Mixpanel, { MIXPANEL_EVENTS } from '../../helpers/Mixpanel';
class SingleMapFrame extends BusComponent {
    constructor(props, context) {
        super(props, context);

        this.state = {
            reportIframeVisible: false,
        };
    }

    componentWillMount() {
        this.emit('APPLICATION_FRAME_TYPE_CHANGED', ApplicationFrameType.SINGLE_MAP);
        AppConfig.sentryRecordEvent('entered single map frame');
        if (this.props.displayDataBrowser) {
            this.handleDataBrowserRequest();
        }
    }

    componentDidMount() {
        this.bindGluBusEvents({
            SHOW_DATA_BROWSER: this.handleDataBrowserRequest,
            SHOW_REPORT_IFRAME: this.handleShowReportIframe,
            CLOSE_REPORT_IFRAME: this.handleCloseReportIframe,
        });
        this.emit('SINGLE_MAP_FRAME_MOUNTED');
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    handleShowReportIframe = () => {
        this.setState({ reportIframeVisible: true });
    };

    handleCloseReportIframe = () => {
        this.setState({ reportIframeVisible: false });
    };

    handleMapImageReady = imageData => {
        const canvas = document.createElement('canvas');
        canvas.width = imageData.width;
        canvas.height = imageData.height;

        const ctx = canvas.getContext('2d');
        ctx.putImageData(imageData, 0, 0);
        ctx.globalCompositeOperation = 'copy';
        ctx.scale(1, -1);
        ctx.translate(0, -canvas.height);
        ctx.drawImage(canvas, 0, 0);

        const thumbnail = resizeCanvas(canvas).toDataURL();
        const thumbnailLarge = canvas.toDataURL('image/jpeg', 0.7);

        this.emit('UPDATE_CURRENT_FRAME_THUMBNAIL_REQUEST', {
            thumbnail,
            thumbnailLarge,
        });
    };

    handleDataBrowserRequest = () => {
        Mixpanel.emitUserEvent(MIXPANEL_EVENTS.CHANGE_DATA);
        this.setState({ showDataBrowser: true });
    };

    handleDataBrowserClose = () => {
        this.setState({ showDataBrowser: false });
    };

    handleSearchVisibilityToggle = searchExpanded => {
        this.setState({ searchExpanded });
    };

    renderFrameComponents() {
        const { frame, isUserPro, isCondensedLayout, children } = this.props;

        const { searchExpanded, showDataBrowser } = this.state;
        const { applicationMode } = this.context;
        const [mapInstance] = frame.mapInstances;

        if (isCondensedLayout) {
            return (
                <CondensedFrameComponents
                    mapViewer={this.map}
                    isUserPro={isUserPro}
                    mapInstances={frame.mapInstances}
                    activeMapIndex={0}
                    onShowDataBrowser={this.handleDataBrowserRequest}
                    applicationMode={applicationMode}
                >
                    {children}
                </CondensedFrameComponents>
            );
        }

        const componentBottomRightClasses = classNames(
            'frame__components-block',
            'frame__components-block--bottom-right',
            {
                'frame__components-block--bottom-right--with-margin':
                    this.state.reportIframeVisible,
            },
        );

        const componentTopRightClasses = classNames(
            'frame__components-block',
            'frame__components-block--top-right',
            {
                'frame__components-block--top-right--with-margin': this.state.reportIframeVisible,
            },
        );

        return (
            <div className="frame__components">
                <div className="frame__components-block frame__components-block--top-left">
                    {AppConfig.constants.hasFilterInSearchPanel && (
                        <PointsPanel mapInstance={mapInstance} />
                    )}
                    {this.map && !showDataBrowser && (
                        <MapPanelContainer
                            mapInstance={mapInstance}
                            mapInstanceIndex={frame.mapInstances.indexOf(mapInstance)}
                            isUserPro={isUserPro}
                            onShowDataBrowser={this.handleDataBrowserRequest}
                            mapViewer={this.map}
                            tourId={HelpTourTargets.MAP_PANEL}
                        />
                    )}
                </div>
                <div className="frame__components-block frame__components-block--bottom-left" />
                <div className={componentTopRightClasses}>
                    <div className="flex-it flex-end stretch-width">
                        {!searchExpanded && <FilterAndMaskIndicator mapInstance={mapInstance} />}
                        {ApplicationMode.isViewMode(applicationMode) && (
                            <SearchBoxPanel
                                mapInstanceIds={frame.mapInstances.map(m => m.id)}
                                onToggleVisibility={this.handleSearchVisibilityToggle}
                            />
                        )}
                    </div>
                </div>
                <div className={componentBottomRightClasses}>
                    <VisualizationLegend
                        mapInstance={mapInstance}
                        tourId={HelpTourTargets.MAP_LEGEND}
                        orientation={Orientation.RIGHT}
                    />
                    <div className="flex-it column center">
                        {this.props.children}
                        <MapControls mapInstance={mapInstance} />
                    </div>
                    <MapInfoBubbleSwitch mapInstance={mapInstance} />
                </div>
                {AppConfig.constants.showMapFooterLogo && <MapLogo />}
            </div>
        );
    }

    render() {
        const { frame, isCondensedLayout } = this.props;

        const [mapInstance] = frame.mapInstances;

        const frameClasses = classNames('frame frame--single', {
            'frame--condensed-layout': isCondensedLayout,
        });

        return (
            <div className={frameClasses}>
                <div className="frame__map">
                    <MapViewer
                        ref={c => (this.map = c)}
                        mapInstance={mapInstance}
                        onImageReady={this.handleMapImageReady}
                        annotations
                    />
                </div>
                {this.renderFrameComponents()}
                <div className="frame__browser frame__browser--left">
                    {this.state.showDataBrowser && (
                        <DataBrowser
                            mapInstance={mapInstance}
                            onClose={this.handleDataBrowserClose}
                        />
                    )}
                </div>
                <ReportIframe mapInstanceId={mapInstance.id} />
            </div>
        );
    }
}

export default SingleMapFrame;
