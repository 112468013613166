// Polyfill allSettled promise handling for older browsers
// Should be removed once babel-polyfill (core-js) adopts stage 4 proposal
Promise.allSettled = Promise.allSettled || (promises =>
        Promise.all(
            promises.map(promise =>
                promise
                    .then(value => ({
                        status: 'fulfilled',
                        value,
                    }))
                    .catch(reason => ({
                        status: 'rejected',
                        reason,
                    }))
            )
        )
);
