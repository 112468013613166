// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import BusComponent from '../BusComponent';
import ApplicationMode from '../../enums/ApplicationMode';
import LocationAnalysisExpandButton from './LocationAnalysisExpandButton';
import LocationAnalysisCreateDownloadReportButton from './LocationAnalysisCreateDownloadReportButton';
import ReportingNotAvailable from './ReportingNotAvailable';

/**
 * @typedef Props
 * @property {import('../../objects/MapInstance').default} mapInstance
 * @property {string} note
 *
 * @typedef State
 * @property {boolean} hideReportOptions
 * @property {import('../../objects/MetaSurvey').default} selectedSurvey
 * @property {boolean} surveyAvailableForReporting
 * @property {boolean} createButtonDisabled
 * @property {boolean} downloadReport
 *
 * @extends {BusComponent<Props, State>}
 *
 * @typedef ReportDefinition
 * @property {import('../../types').ReportTopic[]} selectedReportTopic
 * @property {import('../../objects/MetaSurvey').default[]} selectedSurveys
 * @property {string} analysisTypeTitle
 * @property {any} microData
 * @property {number} defaultAggregationType
 * @property {string[]} selectedTableGuids
 */

class LocationAnalysisFooter extends BusComponent {
    constructor(props, context) {
        super(props, context);

        /** @type {State} */
        this.state = {
            hideReportOptions: true,
            selectedSurvey: undefined,
            surveyAvailableForReporting: true,
            createButtonDisabled: false,
            downloadReport: false,
        };
    }

    componentDidMount() {
        this.bindGluBusEvents({
            CUSTOM_MAP_SELECTION_INITIAL_INFO: this.onCustomMapSelectionInitialInfo,
            UPDATE_CREATE_REPORT_VISIBILITY: this.onChangeVisibilityOptions,
            CUSTOM_MAP_SELECTION_REPORT_PARAMS_SET: this.onReportParamsSet,
        });
        // Fetch selected survey and initial report topics in order to know if
        // reporting is allowed for the selected survey
        this.emit('CUSTOM_MAP_SELECTION_REPORT_INITIAL_INFO_REQUEST', {
            mapInstance: this.props.mapInstance,
        });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    /**
     * @param {object} param0
     * @param {import('../../objects/MetaSurvey').default} param0.selectedSurvey
     * @param {boolean} param0.surveyAvailableForReporting
     */
    onCustomMapSelectionInitialInfo = ({ selectedSurvey, surveyAvailableForReporting }) => {
        this.setState({
            selectedSurvey,
            surveyAvailableForReporting,
        });

        // Load templates only if survey is available for reporting
        if (surveyAvailableForReporting) {
            this.emit('LOAD_COMPATIBLE_TABLE_TEMPLATES_REQUEST', {
                surveyName: selectedSurvey.name,
            });
        }
    };

    /**
     * @param {object} param0
     * @param {string} param0.visible
     * @param {object} param0.mapInstanceId
     */
    onChangeVisibilityOptions = ({ visible, mapInstanceId }) => {
        if (mapInstanceId === this.props.mapInstance.id) {
            this.setState({ hideReportOptions: !visible });
        }
    };

    /**
     * @param {object} param0
     * @param {boolean} param0.downloadReport
     * @param {boolean} param0.isOlapReport
     * @param {ReportDefinition} param0.reportDefinition
     * @param {import('../../types').ReportTemplate[] | undefined} param0.compatibleTableTemplates
     */
    onReportParamsSet = ({
        downloadReport,
        isOlapReport,
        reportDefinition,
        compatibleTableTemplates,
    }) => {
        this.setState({
            downloadReport: !!downloadReport,
            createButtonDisabled:
                (!reportDefinition ||
                    !reportDefinition.selectedReportTopic ||
                    !compatibleTableTemplates) &&
                !isOlapReport,
        });
    };

    render() {
        const {
            hideReportOptions,
            selectedSurvey,
            surveyAvailableForReporting,
            createButtonDisabled,
            downloadReport,
        } = this.state;
        const { mapInstance } = this.props;
        const { applicationMode } = this.context;

        if (ApplicationMode.isViewMode(applicationMode)) {
            return null;
        }

        if (!surveyAvailableForReporting) {
            return <ReportingNotAvailable />;
        }

        if (!selectedSurvey || hideReportOptions) {
            return <LocationAnalysisExpandButton mapInstance={mapInstance} />;
        }

        return (
            <LocationAnalysisCreateDownloadReportButton
                mapInstance={mapInstance}
                createButtonDisabled={createButtonDisabled}
                downloadReport={downloadReport}
            />
        );
    }
}

LocationAnalysisFooter.propTypes = {
    mapInstance: PropTypes.object.isRequired,
};

LocationAnalysisFooter.defaultProps = {};

export default injectIntl(LocationAnalysisFooter);
