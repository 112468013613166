class AnnotationLegend {
    constructor(values) {
        if (values) {
            Object.keys(values).forEach(k => {
                if (this.constructor.prototype.hasOwnProperty(k)) {
                    this[k] = values[k];
                }
            });
        }
    }

    get title() {
        return this._title;
    }

    set title(title) {
        this._title = title;
    }

    get visible() {
        return this._visible;
    }

    set visible(visible) {
        this._visible = visible;
    }

    get legendItems() {
        return this._legendItems;
    }

    set legendItems(legendItems) {
        this._legendItems = legendItems;
    }

    equals(that) {
        const legendItemsEqual = this.legendItems.length === that.legendItems.length && !this.legendItems.find(a => !that.legendItems.find(a1 => a.equals(a1)));
        return (this.title === that.title &&
        this.visible === that.visible &&
        legendItemsEqual);
    }

    clone() {
        return new AnnotationLegend({
            title: this.title,
            visible: this.visible,
            legendItems: this.legendItems.map(li => li.clone()),
        });
    }

    toJSON() {
        return ({
            title: this.title,
            visible: this.visible,
            legendItems: this.legendItems.map(li => li.toJSON()),
        });
    }
}

export default AnnotationLegend;
