// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import SimpleDropdown from '../../dropdown/SimpleDropdown';

const DEFAULT_SELECTION_ID = -1;

/**
 * @typedef Props
 * @property {number[]} years
 * @property {number} selectedYear
 * @property {(year: number) => void} onChange
 * @property {import('react-intl').intlShape} intl
 *
 * @extends {React.Component<Props>}
 */

class SearchYearFilter extends React.Component {
    /** @param {number} year */
    onChange = year => {
        const selectedYear = year === DEFAULT_SELECTION_ID ? undefined : year;
        this.props.onChange(selectedYear);
    };

    render() {
        const yearItems = this.props.years.map(year => ({
            id: year,
            text: year.toString(),
        }));
        yearItems.unshift({
            id: DEFAULT_SELECTION_ID,
            text: this.props.intl.formatMessage({
                id: 'allYears',
            }),
        });
        const selectedItem = this.props.selectedYear
            ? yearItems.find(item => item.id === this.props.selectedYear)
            : {
                id: DEFAULT_SELECTION_ID,
                text: this.props.intl.formatMessage({ id: 'year' }),
            };
        return (
            <SimpleDropdown
                className="data-browser-search-dropdown"
                onItemClick={this.onChange}
                items={yearItems}
                selectedItem={selectedItem}
                showFilter
                filterPlaceholder={this.props.intl.formatMessage({
                    id: 'variablePicker.searchYear',
                })}
                ariaLabel={this.props.intl.formatMessage({
                    id: 'variablePicker.searchYear',
                })}
            />
        );
    }
}

SearchYearFilter.propTypes = {
    years: PropTypes.array.isRequired,
    selectedYear: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
};

SearchYearFilter.defaultProps = {
    selectedYear: undefined,
};

export default injectIntl(SearchYearFilter);
