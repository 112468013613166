import React from 'react';
import PropTypes from 'prop-types';

const BubblePositiveNegative = props => (<div className="flex-it column stretch-width">
    <div className="flex-it column space-between no-shrink">
        <div className="flex-it center grow bubble-legend__palette-sample-wrapper">
            <div className="bubble-legend__palette-sample" style={{ backgroundColor: props.rules[0].symbols[0].brushes[0].fillColor }} />
            <span>Negative</span>
        </div>
        <div className="flex-it center grow bubble-legend__palette-sample-wrapper">
            <div className="bubble-legend__palette-sample" style={{ backgroundColor: props.rules[1].symbols[0].brushes[0].fillColor }} />
            <span>Positive</span>
        </div>
    </div>
</div>);


BubblePositiveNegative.propTypes = {
    rules: PropTypes.array.isRequired,
};

BubblePositiveNegative.defaultProps = {
};

export default BubblePositiveNegative;
