import React from 'react';
import BusComponent from '../BusComponent';
import LegendCard from './legend/LegendCard';
import Placeholder from './Placeholder';
import AnnotationCardList from './AnnotationCardList';

class MapAnnotatorPanel extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            annotationLegendVisible: props.annotationLegend ? props.annotationLegend.visible : undefined,
            expandedAnnotationId: undefined,
        };
        this.bindGluBusEvents({
            ANNOTATION_ON_MAP_SELECTED: this.onAnnotationSelected,
            ANNOTATIONS_DESELECTED: this.onAnnotationsDeselected,
            ANNOTATION_CREATED: this.onAnnotationCreated,
        });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    onAnnotationCreated = ({ annotation }) => {
        // expand annotation card when annotation is created
        this.onToggleExpansion(annotation.id);
    }

    onAnnotationsDeselected = () => {
        this.setState({ expandedAnnotationId: undefined });
    }

    onAnnotationSelected = e => {
        this.setState({ expandedAnnotationId: e.id });
    }

    onChangeAnnotationVisibilityInLegend = ({ includedInLegend, annotation }) => {
        this.emit('CHANGE_ANNOTATION', {
            id: annotation.id,
            type: annotation.type,
            propsToUpdate: { includedInLegend },
        });
    }

    onToggleExpansion = annotationId => {
        const expandedAnnotationId = annotationId === this.state.expandedAnnotationId ? undefined : annotationId;
        this.setState({ expandedAnnotationId });

        if (expandedAnnotationId) {
            this.emit('ANNOTATION_SELECTED', { editMode: true, selectedAnnotationIds: [annotationId] });
        } else {
            this.emit('ANNOTATION_SELECTED', { editMode: true, selectedAnnotationIds: [] });
        }
    }

    render() {
        const { annotations, annotationLegend, onEditClicked, onExportClicked } = this.props;
        const { expandedAnnotationId } = this.state;

        if (!annotations.length) {
            return (<div className="flex-it column grow flex-start stretch vertical-scrollbar light-background">
                <div className="flex-it flex-item">
                    <Placeholder />
                </div>
            </div>);
        }

        return (
            <div className="map-annotator">
                <div className="flex-it column">
                    <AnnotationCardList
                        annotations={annotations}
                        expandedAnnotationId={expandedAnnotationId}
                        onToggleExpansion={this.onToggleExpansion}
                        onEditClicked={onEditClicked}
                        onExportClicked={onExportClicked}
                        onChangeAnnotationVisibilityInLegend={this.onChangeAnnotationVisibilityInLegend}
                    />
                    <div className="divider" />
                    <LegendCard annotationLegend={annotationLegend} key="legend" />
                </div>
            </div>
        );
    }

}

export default MapAnnotatorPanel;
