// @ts-check
import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';

import BusComponent from '../BusComponent';
import Loader from '../Loader';
import FilterPanel from './FilterPanel';

/**
 * @typedef State
 * @property {boolean} visible
 * @property {import('../../').GeoJson | null} geoJson
 * @property {import('../../').AppliedPointFilters} [appliedFilters]
 *
 * @typedef Props
 * @property {import('react-intl').intlShape} intl
 * @property {string} mapInstance
 *
 * @extends {BusComponent<Props, State>}
 */
class PointsPanel extends BusComponent {
    constructor(props) {
        super(props);
        /** @type {State} */
        this.state = {
            geoJson: null,
            visible: false,
            appliedFilters: undefined,
        };
    }

    componentDidMount() {
        this.bindGluBusEvents({
            SHOW_POINTS_PANEL: this.onShow,
            POINTS_GEOJSON: this.onPointsGeoJson,
            TOGGLE_LOCATION_ANALYSIS_COMPARISON_LIST: this.onClose,
        });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    /**
     * @param {import('../../').GeoJson} geoJson
     */
    onPointsGeoJson = geoJson => {
        this.setState({ geoJson });
    };

    onShow = () => {
        this.setState({ visible: true });
    };

    onClose = () => {
        this.setState({ visible: false });
    };

    render() {
        if (!this.state.visible) {
            return null;
        }

        if (!this.state.geoJson) {
            return (
                <div className="points-panel">
                    <Loader />
                </div>
            );
        }

        return (
            <div className="points-panel">
                <div className="flex-it center space-between points-panel__header">
                    <FormattedMessage id="points.findByCriteria" />
                    <button className="btn-icon" onClick={this.onClose}>
                        <i className="material-icons">close</i>
                    </button>
                </div>
                <FilterPanel
                    mapInstance={this.props.mapInstance}
                />
            </div>
        );
    }
}

export default injectIntl(PointsPanel);
