import React from 'react';
import PropTypes from 'prop-types';
import BusComponent from '../../BusComponent';
import LegendCardHeader from './LegendCardHeader';
import LegendEdit from './LegendEdit';

class LegendCard extends BusComponent {
    constructor(props, context) {
        super(props, context);
        const { annotationLegend } = props;

        this.state = {
            expanded: annotationLegend.visible,
            title: annotationLegend.title,
        };
    }

    onLegendToggle = visible => {
        this.emit('ANNOTATION_LEGEND_UPDATE', {
            propsToUpdate: {
                visible,
            },
        });
        this.setState({
            expanded: visible,
        });
    }

    onToggleExpandLegend = () => {
        this.setState({
            expanded: !this.state.expanded,
        });
    }

    onTitleChange = title => {
        this.setState({ title }, () => {
            this.emit('ANNOTATION_LEGEND_UPDATE', {
                propsToUpdate: {
                    title,
                },
            });
        });
    }

    render() {
        const { annotationLegend } = this.props;
        const { expanded, title } = this.state;

        return (
            <div className="annotator-section flex-it column">
                <div className="annotation-card">
                    <LegendCardHeader
                        title={title}
                        expanded={expanded}
                        annotationLegendVisible={annotationLegend.visible}
                        onToggleExpandLegend={this.onToggleExpandLegend}
                        onLegendToggle={this.onLegendToggle}
                        onTitleChange={this.onTitleChange}

                    />
                    {expanded && <LegendEdit annotationLegend={annotationLegend} />}
                </div>
            </div>
        );
    }
}

LegendCard.propTypes = {
    annotationLegend: PropTypes.object.isRequired,
};

export default LegendCard;
