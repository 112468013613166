import React from 'react';
import PropTypes from 'prop-types';

const MobileSubMenu = ({ title, children, onBack, onClose }) => (
    <div className="map-mobile-menu__menu">
        <div className="map-mobile-menu__header">
            <button className="btn-icon" onClick={onBack}>
                <i className="material-icons">arrow_back</i>
            </button>
            <div className="map-mobile-menu__title">
                {title}
            </div>
            <button className="btn-icon" onClick={onClose}>
                <i className="material-icons">close</i>
            </button>
        </div>
        <div className="map-mobile-menu__content">
            {children}
        </div>
    </div>
);

MobileSubMenu.propTypes = {
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
    children: PropTypes.object.isRequired,
};

export default MobileSubMenu;
