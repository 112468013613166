// @ts-check
import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Checkbox from '../../../form/Checkbox';
import Popover from '../../../popover/Popover';
import MarkerEditor from '../../../markerEditor/MarkerEditor';

/**
 * @typedef Props
 * @property {object} styleRule
 * @property {boolean} allMarkersSame
 * @property {function} onStyleRuleMarkerStyleChange
 * @property {function} onStyleRuleVisibilityChange
 * @property {import('react-intl').intlShape} intl
 *
 * @typedef State
 * @property {boolean} open
 *
 * @extends {React.Component<Props, State>}
 */

class PointStyleRuleEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
    }
    /** @param {import('../../../../objects/PointLayerStyle').default[]} markerStyle */
    onMarkerStyleChange = markerStyle => {
        this.props.onStyleRuleMarkerStyleChange(this.props.styleRule, markerStyle);
    }
    /** @param {boolean} checked */
    onMarkerVisibilityChange = checked => {
        this.props.onStyleRuleVisibilityChange(this.props.styleRule, checked);
    }

    onCloseRequest = () => {
        this.setState({ open: false });
    }

    onTriggerClick = () => {
        this.setState({ open: !this.state.open });
    }

    renderEditor() {
        const { styleRule } = this.props;
        return (
            <Popover
                onCloseRequest={this.onCloseRequest}
                anchorEl={this.trigger}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                targetOrigin={{ horizontal: 'right', vertical: 'top' }}
                marginTop={8}
                open={this.state.open}
                showClose
            >
                <div className="icon-menu__menu">
                    <MarkerEditor
                        markerPathId={styleRule.markerPathId}
                        markerColor={styleRule.markerColor}
                        onChange={this.onMarkerStyleChange}
                    />
                </div>
            </Popover>
        );
    }

    render() {
        const { styleRule, allMarkersSame, intl } = this.props;
        const classes = classNames('point-style-rule', { 'point-style-rule--all-items': allMarkersSame });
        const pointStyleRuleValueClasses = classNames('point-style-rule__value', 'ellipsis', 'body-1', { 'point-style-rule__value--empty': styleRule.value === null });

        let pointStyleRuleValueText = styleRule.value;

        if (styleRule.value === undefined || styleRule.value === null) {
            pointStyleRuleValueText = intl.formatMessage({ id: 'emptyValue' });
        }

        if (allMarkersSame) {
            pointStyleRuleValueText = intl.formatMessage({ id: 'allItems' });
        }

        return (
            <div className={classes}>
                {!allMarkersSame && <Checkbox
                    onCheck={this.onMarkerVisibilityChange}
                    checked={!styleRule.isHidden}
                    title={intl.formatMessage({ id: 'toggleVisibility' })}
                />}
                <div className="point-style-rule__content">
                    <div title={pointStyleRuleValueText} className={pointStyleRuleValueClasses}>
                        {pointStyleRuleValueText}
                    </div>
                    <div className="point-style-rule__trigger" ref={e => { this.trigger = e; }} onClick={this.onTriggerClick}>
                        <div className="point-style-rule__shape" style={{ backgroundColor: styleRule.markerColor }}>
                            <i className={classNames(`socex-icon-marker-${styleRule.markerPathId}`)} />
                        </div>
                    </div>
                </div>
                {this.trigger && this.renderEditor()}
            </div>
        );
    }
}

PointStyleRuleEditor.propTypes = {
    intl: PropTypes.object.isRequired,
    onStyleRuleMarkerStyleChange: PropTypes.func.isRequired,
    onStyleRuleVisibilityChange: PropTypes.func.isRequired,
    styleRule: PropTypes.object.isRequired,
    allMarkersSame: PropTypes.bool,
};

PointStyleRuleEditor.defaultProps = {
    allMarkersSame: false,
};

export default injectIntl(PointStyleRuleEditor);
