const applicationFrameType = {
    SINGLE_MAP: 'SINGLE_MAP',
    SWIPE: 'SWIPE_FRAME',
    SIDE_BY_SIDE_MAPS: 'SIDE_BY_SIDE_MAPS',
    MAP_EDITOR: 'MAP_EDITOR',
    ANNOTATION_EDITOR: 'ANNOTATION_EDITOR',
    MASK_EDITOR: 'MASK_EDITOR',
    DATA_FILTER_EDITOR: 'DATA_FILTER_EDITOR',
    REPORT_EDITOR: 'REPORT_EDITOR',
    MAP_EXPORT: 'MAP_EXPORT',
    isEditor: t => {
        if (t === 'REPORT_EDITOR' || t === 'MASK_EDITOR' || t === 'MAP_EDITOR' || t === 'DATA_FILTER_EDITOR') {
            return true;
        }
        return false;
    },
};


export default applicationFrameType;
