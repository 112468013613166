//      

module.exports = require('../style-spec/validate_style.min'); //!

                                           

module.exports.emitErrors = function (emitter         , errors                           ) {
    if (errors && errors.length) {
        errors = errors.filter((error) => { //!
            return error.message.indexOf('string, number, or boolean expected') === -1; //!
        }); //!
        errors = errors.filter((error) => { //!
            return error.message.indexOf('glyphs: "glyphs" url must include') === -1; //!
        }); //!
        if (errors.length === 0) return false; //!
        for (const {message} of errors) {
            emitter.fire('error', { error: new Error(message) });
        }
        return true;
    } else {
        return false;
    }
};
