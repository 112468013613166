const DataClassificationMethod = {
    CATEGORY_DEFAULT: 'category',
    NATURAL_BREAKS: 'natural_breaks',
    ARITHMETIC_PROGRESSION: 'arithmetic_progression',
    QUANTILE: 'quantile',
    EQUAL_INTERVAL: 'equal_interval',
    CUSTOM: 'custom',
    getName: t => {
        if (t === 'category') {
            return 'visualisationType.categoryDefault';
        }
        if (t === 'natural_breaks') {
            return 'visualisationType.naturalBreaksJenks';
        }
        if (t === 'arithmetic_progression') {
            return 'visualisationType.arithmeticProgression';
        }
        if (t === 'quantile') {
            return 'visualisationType.quantile';
        }
        if (t === 'equal_interval') {
            return 'visualisationType.equalInterval';
        }
        return 'visualisationType.custom';
    },
};

export default DataClassificationMethod;
