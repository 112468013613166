// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';

import BusComponent from '../../BusComponent';
import LayerLibraryLegendItem from './LayerLibraryLegendItem';
import VisibilityToggle from '../../form/VisibilityToggle';

/**
 * @typedef Props
 * @property {import('../../../objects/LibraryLayer').default[]} layers
 * @property {string} layerLibraryGroupId
 * @property {string} mapInstanceId
 * @property {import('../../../').LayerLibraryItemMetadata} layerGroupMetadata
 * @property {import('react-intl').intlShape} intl
 *
 * @typedef State
 * @property {boolean} visible
 *
 * @extends {BusComponent<Props, State>}
 */
class LayerLibraryGroup extends BusComponent {
    constructor(props, context) {
        super(props, context);

        // get default visibility state by checking if any visible layer exists
        // for any classes item
        const visible = this.props.layerGroupMetadata.classes.some(
            classesItem =>
                this.props.layers.some(
                    layer =>
                        layer.id === classesItem['layer-group-id'] &&
                        layer.visible,
                ),
        );
        this.state = {
            visible,
        };
    }

    onDetailsOptionClicked = () => {
        this.emit(
            'SHOW_LAYER_LIBRARY_GROUP_DETAILS_POPUP_REQUEST',
            this.props.layerGroupMetadata,
        );
    };

    onVisibilityToggle = () => {
        this.emit('UPDATE_LAYER_LIBRARY_GROUP_VISIBILITY', {
            id: this.props.layerLibraryGroupId,
            visible: !this.state.visible,
            mapInstanceId: this.props.mapInstanceId,
        });
        this.setState(state => ({
            visible: !state.visible,
        }));
    };

    render() {
        const {
            title,
            classes,
            disableClassesInteractivity,
        } = this.props.layerGroupMetadata;

        const interactive = !disableClassesInteractivity;
        const legendClassesItems = classes
            .filter(classesItem => {
                const groupHasVisibleLayers = this.props.layers.some(
                    layer =>
                        layer.id === classesItem['layer-group-id'] &&
                        layer.visible,
                );
                // If layer library group classes are interactive, show them all
                // otherwise show only those in legend who have visible layers
                return interactive || groupHasVisibleLayers;
            })
            .map(classesItem => (
                <LayerLibraryLegendItem
                    key={classesItem.label}
                    item={classesItem}
                    interactive={interactive}
                    layerLibraryGroupId={this.props.layerLibraryGroupId}
                    mapInstanceId={this.props.mapInstanceId}
                    layerVisibility={this.props.layers.some(
                        layer =>
                            layer.id === classesItem['layer-group-id'] &&
                            layer.visible,
                    )}
                />
            ));

        const titleClasses = classNames({
            disabled: !this.state.visible,
        });

        return (
            <div className="layer-library-legend__group">
                <div className="layer-library-legend__title">
                    <span className={titleClasses}>{title}</span>
                    <div className="flex-it center">
                        <button
                            className="btn-icon btn-icon--small"
                            onClick={this.onDetailsOptionClicked}
                            title={this.props.intl.formatMessage(
                                { id: 'detailsAboutTitle' },
                                { title },
                            )}
                            aria-label={this.props.intl.formatMessage(
                                { id: 'detailsAboutTitle' },
                                { title },
                            )}
                        >
                            <i className="material-icons-outlined font-18">
                                info
                            </i>
                        </button>
                        <div className="divider divider--vertical divider--btn-separator" />
                        <VisibilityToggle
                            onClick={this.onVisibilityToggle}
                            visible={this.state.visible}
                        />
                    </div>
                </div>
                {this.state.visible && (
                    <div className="layer-library-legend__items">
                        {legendClassesItems}
                    </div>
                )}
            </div>
        );
    }
}

LayerLibraryGroup.propTypes = {
    layers: PropTypes.array.isRequired,
    layerGroupMetadata: PropTypes.object.isRequired,
    mapInstanceId: PropTypes.string.isRequired,
    layerLibraryGroupId: PropTypes.string.isRequired,
};

export default injectIntl(LayerLibraryGroup);
