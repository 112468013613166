import GLU from '../glu2.js/src/index';
import Api from '../apis/Api';
import SearchLimits from '../enums/SearchLimit';

class OmniSocialExplorerSearchDataSource extends GLU.DataSource {
    static get name() {
        return 'OmniSocialExplorerSearchDataSource';
    }

    static getInstance() {
        return new OmniSocialExplorerSearchDataSource();
    }

    constructor() {
        super();
        this._recentSearchTerms = [];
    }

    get recentSearchTerms() {
        return this._recentSearchTerms;
    }

    addRecentSearchTerm(searchTerm) {
        const recentSearchTermIndex = this._recentSearchTerms.findIndex(term => term === searchTerm);

        if (recentSearchTermIndex === -1) {
            // add new term
            this._recentSearchTerms.unshift(searchTerm);
        } else {
            // push it to the top
            this._recentSearchTerms.splice(recentSearchTermIndex, 1);
            this._recentSearchTerms.unshift(searchTerm);
        }

        if (this._recentSearchTerms.length > SearchLimits.RECENT_SEARCHES_LIMIT) {
            this._recentSearchTerms.pop();
        }

        return this._recentSearchTerms;
    }

    /**
     * @param {object} params
     * @param {string} params.searchTerm
     * @param {number} params.limit
     * @param {number} params.offset
     * @param {string[]} params.surveyNames
     * @param {number[]} params.years
     * @param {boolean} params.mapOnly
     */
    searchVariable(params) {
        const payload = {
            q: params.searchTerm,
            limit: SearchLimits.VARIABLE_SEARCH_LIMIT,
            offset: params.offset,
            surveyNames: params.surveyNames,
            years: params.years,
            mapOnly: params.mapOnly,
        };

        return new Promise((resolve, reject) => {
            Api.variable.searchVariable({ payload })
                .then(response => {
                    /** @type {import('../../../').VariableSearchResponse} */
                    const results = {
                        total: response.body.total,
                        variables: response.body.variables,
                        didYouMean: response.body.didYouMean,
                        suggestions: response.body.suggestions,
                    };

                    resolve(results);
                }, reject);
        });
    }
}

export default OmniSocialExplorerSearchDataSource;
