/* global JSZip */
/* n.b. If you try to import JSZip as a node package, dragonfly workers will explode. You have been warned. */

// @ts-check
import GLU from '../glu2.js/src/index';
import Api from '../apis/Api';
import AppConfig from '../appConfig';
import { dbGet, dbSet } from '../helpers/IndexedDB';

class PointsDataSource extends GLU.DataSource {
    static get name() {
        return 'PointsDataSource';
    }

    static getInstance() {
        return new PointsDataSource();
    }

    constructor() {
        super();
        /** @type {import('../../../').GeoJson} */
        this._pointsGeoJson = undefined;
        /** @type {import('../../../').PointsMetadata} */
        this._pointsMetadata = undefined;
        this._serviceCodes = undefined;
    }

    /** @returns {import('../../../').GeoJson} */
    get pointsGeoJson() {
        return this._pointsGeoJson;
    }

    /**
     * @returns {import('../../../').PointsMetadata}
     */
    get pointsMetadata() {
        return this._pointsMetadata;
    }

    get serviceCodes() {
        return this._serviceCodes;
    }

    get pointsSourceId() {
        return this._pointsMetadata.mapProperties.sourceId;
    }

    get pointsLayerId() {
        return this._pointsMetadata.mapProperties.layerId;
    }

    get pointsClusteredLayerId() {
        return this._pointsMetadata.mapProperties.clusteredLayerId;
    }

    /** @returns {Promise<import('../../../').GeoJson> | undefined} */
    loadPointsGeoJson = async () => {
        try {
            let blob;
            // IndexedDB params
            const { collectionName, collectionKey } = this.pointsMetadata.data.db;
            // File fetch and parse params
            const { archive, raw } = this.pointsMetadata.data.file;

            // First, obtain the one-time-use access URL
            const signedUrlResponse = await fetch(
                `${AppConfig.constants.links.pointsGeoJsonService}?fileName=${archive}`,
                {
                    credentials: 'include',
                },
            );
            const data = await signedUrlResponse.json();
            if (!data || !data.url || !data.url.length) return;
            const { url } = data;

            // Second, get the current ETag of the cached geojson, if any
            const eTagCollectionKeyName = `${collectionKey}-etag`;
            const storedETag = await dbGet(collectionName, eTagCollectionKeyName);

            // Third, try to retrieve the geojson using the one-time-use access URL and If-None-Match etag check
            // it will return 304 - Not Modified if cached ETag matches the current file on S3
            // it will return 200 - OK if cached ETag does not match the current file on S3, causing a cache bust effect
            // Use SE proxy service in order to use S3 locally
            const response = await fetch(
                `${AppConfig.constants.links.proxyService}?url=${encodeURIComponent(url)}`,
                {
                    mode: 'cors',
                    credentials: 'include',
                    headers: storedETag ? { 'if-none-match': storedETag } : undefined,
                },
            );

            /** @type {import('../../../').GeoJson} */
            let geoJson;

            switch (response.status) {
                case 200: {
                    // init or cache bust
                    /** @type {Blob} */
                    blob = await response.blob();
                    const zip = new JSZip();
                    await zip.loadAsync(blob);

                    // TODO: figure out if we should use raw, or hardcode to geojson.json
                    const contentToCache = await zip.file(raw).async('string');
                    const eTagHeaderValue = response.headers.get('etag');

                    // Save the GEOJSON BLOB to indexedDB
                    await dbSet(collectionName, collectionKey, contentToCache);

                    // Save the ETAG to indexedDB
                    await dbSet(collectionName, eTagCollectionKeyName, eTagHeaderValue);

                    geoJson = JSON.parse(contentToCache);
                    break;
                }
                case 304: {
                    // cache verified ok, no need to touch anything, just read from indexeddb
                    /** @type string */
                    const cachedGeoJson = await dbGet(collectionName, collectionKey);
                    geoJson = JSON.parse(cachedGeoJson);
                    break;
                }
                default: {
                    geoJson = undefined;
                }
            }

            if (!geoJson || !geoJson.features) return;

            this._pointsGeoJson = {
                type: 'FeatureCollection',
                features: geoJson.features.filter(f => !!f.geometry),
            };
        } catch (e) {
            console.error(e);
        }
    };

    loadPointsMetadata = async (
        metadataId = AppConfig.constants.customPointMetadataJsonFileName,
    ) => {
        try {
            const response = await fetch(
                `${AppConfig.constants.baseURL}/json/metadata/${metadataId}`,
            );
            const metadata = await response.json();
            // Guards
            if (!metadata.filterProperties || !metadata.filterProperties.length) {
                console.warn('Missing filters in points metadata');
                return;
            }
            if (!metadata.searchProperties || !metadata.searchProperties.length) {
                console.warn('Missing search properties in points metadata');
                return;
            }
            this._pointsMetadata = metadata;
        } catch (e) {
            console.error(e);
        }
    };

    /**
     * @param {import('../types').OlapSiteAnalysisPayload} payload
     * @param {'selfstorage-facility' | 'demography' | 'executive-summary' | 'executive-summary-detailed'} q
     */
    fetchOlapSiteAnalysis(payload, q) {
        let promises = [];
        payload.reportContours.values.forEach(selection => {
            promises.push(
                new Promise((resolve, reject) => {
                    const query = {
                        lat: payload.site.lat,
                        lng: payload.site.lng,
                        q: q,
                        profile: payload.reportContours.type,
                        'profile-value': selection,
                    };
                    Api.olap.siteAnalysis({ query: query }).then(response => {
                        if (response.status === 200) {
                            resolve(response.body);
                        } else {
                            reject(JSON.stringify(response));
                        }
                    }, reject);
                }),
            );
        });
        return promises;
    }
}

export default PointsDataSource;
