import RendererType from '../../enums/RendererType';
import LayerOverrideVisibility from '../../enums/LayerOverrideVisibility';
import RendererData from './RendererData';

class MapInstanceData {
    constructor(mapInstance, mapConfig, layers, sources) {
        this._mapInstance = mapInstance;
        this._mapConfig = mapConfig;
        if (this.hasMaskingFilter) {
            this._applyMaskingFilterToMapConfig();
        }

        this._dragonflyLayers = layers;
        this._dragonflySources = sources;
        this._rendererData = new RendererData(this._mapInstance.dataTheme, this._mapConfig);
        this._addFilterFieldsToSource();
        this._applyDataFilterToRenderer();
        this._dragonflySources[this._rendererData.source.id] = this._rendererData.source.toJSON();
        this._applyMaskingFilterToRenderer();
        this._applyMapClasses();
        this._addDataLayers();
        this._applyLayerOverrides();
        this._applyPreferredSummaryLevel();
        this._applySatelliteFlag();
        this._applySatelliteDataOverlay();
    }

    get rendererData() {
        return this._rendererData;
    }

    get layers() {
        return this._dragonflyLayers;
    }

    get geographyLayers() {
        return this._dragonflyLayers.filter(
            dl =>
                !dl.metadata.isHighlightLayer &&
                !dl.metadata.isDataLayer &&
                !dl.metadata.isDescriptionLayer,
        );
    }

    get dataLayers() {
        return this._rendererData.dataLayers;
    }

    get layersMasks() {
        return this._rendererData.layersMasks;
    }

    get highlightLayer() {
        return this._rendererData.highlightLayer;
    }

    get preferredSummaryLevel() {
        return this._mapConfig.getSummaryLevelForDataSourceId(this._mapInstance.preferredDataLayerId);
    }

    get sources() {
        return this._dragonflySources;
    }

    get mapClasses() {
        return this._rendererData.mapClasses;
    }

    set mapClasses(mapClasses) {
        this._rendererData.mapClasses = mapClasses;
    }

    get satelliteLayer() {
        if (!this.hasSatellite) return undefined;
        return this._dragonflyLayers.find(dl => dl.id === this._mapConfig.satelliteLayer.id);
    }

    get hasSatellite() {
        return this._mapConfig.satelliteLayer !== undefined;
    }

    get hasMaskingFilter() {
        return Object.values(this._mapInstance.dataGeoFilters)[0] !== undefined;
    }

    applyRendererUpdate() {
        delete this._dragonflySources[this._rendererData.source.id];
        this._removeDataLayers();
        this._rendererData = new RendererData(this._mapInstance.dataTheme, this._mapConfig);
        this._dragonflySources[this._rendererData.source.id] = this._rendererData.source.toJSON();
        this._applyMaskingFilterToRenderer();
        this._applyLayerOverrides();
        this._addDataLayers();
        this._addFilterFieldsToSource();
        this._applyDataFilterToRenderer();
        this._applyPreferredSummaryLevel();
        this._applySatelliteFlag();
        this._applySatelliteDataOverlay();
    }

    applySummaryLevelUpdate() {
        const preferredSummaryLevel = this._mapConfig.getSummaryLevelForDataSourceId(this._mapInstance.preferredDataLayerId);
        if (preferredSummaryLevel) {
            this._applyPreferredSummaryLevel();
        } else {
            this._dragonflyLayers.forEach(dl => {
                dl.minzoom = dl.metadata.defaultMinZoom;
                dl.maxzoom = dl.metadata.defaultMaxZoom;
            });
            const minZoom = Math.min(...this._rendererData.autoSource.map(aSLayer => aSLayer.minzoom));
            const maxZoom = Math.max(...this._rendererData.autoSource.map(aSLayer => aSLayer.maxzoom));

            this._updateLayersAutoSource(this._rendererData.autoSource, minZoom, maxZoom);
        }
    }

    applySatelliteVisibilityUpdate() {
        this._applySatelliteFlag();
        // clear layers satellite visibility override
        this.layers.forEach(dl => {
            if (dl.metadata.socialexplorer['hidden-when-satellite'] && dl.layout) {
                dl.layout.visibility = this._mapInstance.isSatelliteVisible ? 'none' : 'visible';
            }
            if (dl.metadata && dl.metadata.isDataLayer && dl.layout) {
                dl.layout.visibility = this._mapInstance.isStreetMapVisible ? 'none' : 'visible';
            }
        });
        this._applyLayerOverrides();
    }

    applyDataFilterUpdate() {
        delete this._dragonflySources[this._rendererData.source.id];
        this._removeDataLayers();
        this._rendererData = new RendererData(this._mapInstance.dataTheme, this._mapConfig);
        this._dragonflySources[this._rendererData.source.id] = this._rendererData.source.toJSON();
        this._addDataLayers();
        this._applyLayerOverrides();
        this._addFilterFieldsToSource();
        this._applyDataFilterToRenderer();
        this._applyPreferredSummaryLevel();
        this._applySatelliteFlag();
        this._applySatelliteDataOverlay();
    }

    applyMaskingFilterUpdate() {
        this._mapConfig.summaryLevels.forEach(sl => {
            if (sl.defaultMinZoom !== undefined) {
                sl.minzoom = sl.defaultMinZoom;
            }
            if (sl.defaultMaxZoom !== undefined) {
                sl.minzoom = sl.defaultMinZoom;
            }
            sl.visible = true;
        });
        this._dragonflyLayers.forEach(dl => {
            if (dl.defaultMinZoom !== undefined) {
                dl.minzoom = dl.defaultMinZoom;
            }
            if (dl.defaultMaxZoom !== undefined) {
                dl.minzoom = dl.defaultMinZoom;
            }
        });
        if (this.hasMaskingFilter) {
            this._applyMaskingFilterToMapConfig();
        }
        this._applyMaskingFilterToRenderer();
        this.applySummaryLevelUpdate();
    }

    applyLayersOverridesUpdate() {
        this._applyLayerOverrides();
        // apply layers satellite visibility override
        this._applySatelliteFlag();
    }

    applySatelliteDataOverlay() {
        this._applySatelliteDataOverlay();
    }

    _applyMapClasses() {
        // Apply classes to dragonfly layers
        // These layers are cloned from map config and they are used when creating a map.
        // The values in these layers can be overwritten because map config holds default values.
        // Later when changing masks while map is loaded, default values are read from mapConfig.
        this.layers.forEach(l => {
            if (l[`paint.${this.mapClasses[0]}`] === undefined) return;
            Object.keys(l[`paint.${this.mapClasses[0]}`]).forEach(pp => (l.paint[pp] = l[`paint.${this.mapClasses[0]}`][pp]));
        });
    }

    _applySatelliteFlag() {
        if (!this.hasSatellite) return;

        this.satelliteLayer.layout.visibility = this._mapInstance.isSatelliteVisible ? 'visible' : 'none';
        if (this._mapInstance.isSatelliteVisible) {
            this.layers.forEach(dl => {
                if (dl.metadata
                    && dl.metadata.socialexplorer
                    && dl.metadata.socialexplorer['hidden-when-satellite']
                    && dl.layout) {
                    dl.layout.visibility = 'none';
                }
            });
        }
    }

    _applySatelliteDataOverlay() {
        const satelliteSaturation = this._mapInstance.satelliteDataOverlaySatelliteHasColor ? 0 : -1;
        let dataOpacity = 1;
        if (this._mapInstance.isStreetMapVisible) {
            dataOpacity = 0;
        }
        if (this._mapInstance.isSatelliteVisible) {
            dataOpacity = this._mapInstance.satelliteDataOverlayDataOpacity;
        }

        this.satelliteLayer.paint['raster-saturation'] = satelliteSaturation;
        this.rendererData.applyDataLayersOpacity(dataOpacity);
        this._removeDataLayers();
        this._addDataLayers();
    }

    _applyMaskingFilterToMapConfig() {
        if (!Object.keys(this._mapInstance.dataGeoFilters)[0]) return;
        const filteredSummaryLevelId = Object.keys(this._mapInstance.dataGeoFilters)[0];
        const maskingFilter = Object.values(this._mapInstance.dataGeoFilters)[0];
        // filter summary levels
        let firstVisibleSummaryLevel, lastVisibleSummaryLevel;
        this._mapConfig.summaryLevels.forEach(sl => {
            // if summary level is not filtered one or one of the nested summary levels, set its zoom high so it will
            // not be visible
            if (sl.id !== filteredSummaryLevelId && !maskingFilter.nestedSummaryLevelsIds.find(id => id === sl.id)) {
                sl.defaultMinZoom = sl.minzoom;
                sl.minzoom = this._mapConfig.maxZoom + 1;
                sl.visible = false;
            } else if (sl.minzoom < this._mapConfig.maxZoom || sl.id === filteredSummaryLevelId) {
                lastVisibleSummaryLevel = sl;
                firstVisibleSummaryLevel = firstVisibleSummaryLevel || sl;
            }
        });
        if (lastVisibleSummaryLevel) {
            lastVisibleSummaryLevel.defaultMaxZoom = lastVisibleSummaryLevel.maxzoom;
            lastVisibleSummaryLevel.maxzoom = this._mapConfig.maxZoom + 1;
        }
        if (firstVisibleSummaryLevel) {
            firstVisibleSummaryLevel.defaultMinZoom = firstVisibleSummaryLevel.minzoom;
            firstVisibleSummaryLevel.minzoom = this._mapConfig.minZoom;
        }
        // filter dragonfly layers that should be visible on non visible summary levels
        this._mapConfig.layers.forEach(dl => {
            let showOnPreferredSummaryLevels = dl.metadata.socialexplorer['show-on-preferred-summary-levels'];
            let zoomRangeOnPreferredSummaryLevels = dl.metadata.socialexplorer['zoom-range-on-preferred-summary-levels'];
            if (showOnPreferredSummaryLevels) {
                showOnPreferredSummaryLevels = showOnPreferredSummaryLevels.filter(id => id === filteredSummaryLevelId ||
                maskingFilter.nestedSummaryLevelsIds.find(id1 => id1 === id));
                dl.metadata.socialexplorer['show-on-preferred-summary-levels'] = showOnPreferredSummaryLevels;
            }
            if (zoomRangeOnPreferredSummaryLevels) {
                zoomRangeOnPreferredSummaryLevels = Object.keys(zoomRangeOnPreferredSummaryLevels)
                    .filter(id => id === filteredSummaryLevelId || maskingFilter.nestedSummaryLevelsIds.find(id1 => id1 === id))
                    .reduce((result, id) => {
                        if (zoomRangeOnPreferredSummaryLevels[id]) result[id] = zoomRangeOnPreferredSummaryLevels[id];
                        return result;
                    }, {});
                dl.metadata.socialexplorer['zoom-range-on-preferred-summary-levels'] = zoomRangeOnPreferredSummaryLevels;
            }
            if ((showOnPreferredSummaryLevels && showOnPreferredSummaryLevels.length === 0) ||
                (zoomRangeOnPreferredSummaryLevels && Object.keys(zoomRangeOnPreferredSummaryLevels).length === 0)) {
                dl.minzoom = this._mapConfig.maxZoom + 1;
                dl.metadata.defaultMinZoom = dl.minzoom;
                dl.maxzoom = this._mapConfig.maxZoom + 2;
                dl.metadata.defaultMaxZoom = dl.maxzoom;
            }
        });
    }

    _applyMaskingFilterToRenderer() {
        const maskingFilter = Object.values(this._mapInstance.dataGeoFilters)[0];
        const featureFirstField = this._mapInstance.dataTheme.rendering[0].fieldList.fields[0];
        this._rendererData.autoSource.forEach(autoSourceLayer => {
            const dataSource = this._mapConfig.getDataSourceForSummaryLevelId(autoSourceLayer.id);
            autoSourceLayer.minzoom = dataSource.summaryLevel.minzoom;
            autoSourceLayer.maxzoom = dataSource.summaryLevel.maxzoom;
            if (maskingFilter) {
                const dataset = dataSource.findDataset(featureFirstField.surveyName, featureFirstField.datasetAbbreviation);
                autoSourceLayer.filter = maskingFilter.dragonflyFilter(dataset.primaryKeyField);
            } else {
                delete autoSourceLayer.filter;
            }
        });
        if (this._mapInstance.hasDataFilter) {
            this._rendererData.dataFilterHelperLayer['auto-source'] = this._rendererData.autoSource.map(autoSource => Object.assign({},
                autoSource,
                { 'source-layer': autoSource['source-layer'].replace('p', '') }));
        }
        this._rendererData.highlightLayer['auto-source'] = this._rendererData.autoSource.map(autoSource => Object.assign({},
            autoSource,
            { 'source-layer': autoSource['source-layer'].replace('p', '') }));
    }

    _applyPreferredSummaryLevel() {
        const summaryLevel = this._mapConfig.getSummaryLevelForDataSourceId(this._mapInstance.preferredDataLayerId);
        if (!summaryLevel) return;
        this._dragonflyLayers.forEach(dl => {
            const showOnSummaryLevels = dl.metadata.socialexplorer['show-on-preferred-summary-levels'];
            const hideOnSummaryLevels = dl.metadata.socialexplorer['hide-on-preferred-summary-levels'];
            const zoomRangeOnSummaryLevels = dl.metadata.socialexplorer['zoom-range-on-preferred-summary-levels'] || {};
            dl.minzoom = dl.metadata.defaultMinZoom;
            dl.maxzoom = dl.metadata.defaultMaxZoom;
            // if dragonfly layer is visible on some other summary level then hide it
            if (showOnSummaryLevels && showOnSummaryLevels.indexOf(summaryLevel.id) === -1) {
                dl.minzoom = this._mapConfig.maxZoom + 1;
                dl.maxzoom = this._mapConfig.maxZoom + 2;
            }
            // if dragonfly layer default zoom range is overriden on this summary level override layer zoom range
            if (zoomRangeOnSummaryLevels[summaryLevel.id]) {
                dl.minzoom = zoomRangeOnSummaryLevels[summaryLevel.id].minzoom || dl.minzoom;
                dl.maxzoom = zoomRangeOnSummaryLevels[summaryLevel.id].maxzoom || dl.maxzoom;
            }
            // if dragonfly layer should be hidden on this summary level then hide it
            if (hideOnSummaryLevels && hideOnSummaryLevels.indexOf(summaryLevel.id) !== -1) {
                dl.minzoom = this._mapConfig.maxZoom + 1;
                dl.maxzoom = this._mapConfig.maxZoom + 2;
            }
        });
        let preferredAutoSourceLayer = this._rendererData.autoSource.find(aSLayer => aSLayer.id === summaryLevel.id);
        preferredAutoSourceLayer = Object.assign({}, preferredAutoSourceLayer);
        if (summaryLevel.visible) {
            preferredAutoSourceLayer.minzoom = summaryLevel['absolute-min-zoom'] !== undefined ? summaryLevel['absolute-min-zoom'] : this._mapConfig.minZoom;
            preferredAutoSourceLayer.maxzoom = this._mapConfig.maxZoom + 1;
        } else {
            preferredAutoSourceLayer.minzoom = this._mapConfig.maxZoom + 1;
            preferredAutoSourceLayer.maxzoom = this._mapConfig.maxZoom + 1;
        }

        this._updateLayersAutoSource([preferredAutoSourceLayer], preferredAutoSourceLayer.minzoom, preferredAutoSourceLayer.maxzoom);
    }

    _updateLayersAutoSource(autoSource, minZoom, maxZoom) {
        const layers = [...this._rendererData.layers];
        // if a filter is present the dataFilterHelper layer auto source propery must also be updated
        if (this._mapInstance.hasDataFilter) layers.push(this.rendererData.dataFilterHelperLayer);

        layers.forEach(dl => {
            // in case of highlight layer or dataFilterHelper layer for bubble visualization we need tu remove the "p"
            // letter from the source id since these two layer are never point layers
            const isFillLayer = dl === this._rendererData.highlightLayer || dl === this._rendererData.dataFilterHelperLayer;
            if (isFillLayer && this._mapInstance.dataTheme.isBubblesVisualization) {
                dl['auto-source'] = autoSource.map(asLayer => Object.assign(
                    {},
                    asLayer,
                    { 'source-layer': asLayer['source-layer'].slice(0, -1) }));
            } else {
                dl['auto-source'] = autoSource;
            }
            dl.minzoom = minZoom;
            dl.maxzoom = maxZoom;
        });
    }

    _applyLayerOverrides() {
        this._dragonflyLayers.forEach(dl => {
            if (dl.ref) return;
            const layerOverrideId = dl.metadata.socialexplorer['layer-group-id'] || dl.id;
            const layerOverride = this._mapInstance.layerOverrides[layerOverrideId];
            if (layerOverride && layerOverride.visibility === LayerOverrideVisibility.ALWAYS_SHOW) {
                dl.layout.visibility = 'visible';
            }
            if (layerOverride && layerOverride.visibility === LayerOverrideVisibility.ALWAYS_HIDE) {
                dl.layout.visibility = 'none';
            }
        });
        if (this._mapInstance.isSatelliteVisible && this._mapConfig.satelliteLayer) {
            const satelliteLayer = this._dragonflyLayers.find(dl => dl.id === this._mapConfig.satelliteLayer.id);
            satelliteLayer.layout.visibility = 'visible';
        }
    }

    _addDataLayers() {
        const renderer = this._mapInstance.dataTheme.rendering[0];
        if (this._mapInstance.dataTheme.isShadedVisualization) {
            this.dataLayers.forEach((dl, index) => {
                if (renderer.type === RendererType.REPORT && this._mapConfig.streetsLayer && index === 2) {
                    this._dragonflyLayers.splice(this._mapConfig.streetsLayerIdx + index, 0, dl);
                    return;
                }
                // streets blending
                if (dl.paint['fill-opacity'] < 1 && this._mapConfig.streetsLayer) {
                    this._dragonflyLayers.splice(this._mapConfig.streetsLayerIdx + index, 0, dl);
                    return;
                }
                this._dragonflyLayers.splice(this._mapConfig.dataPlaceholderIdx + index, 0, dl);
            });
        } else {
            this.dataLayers.forEach(dl => {
                this._dragonflyLayers.push(dl);
            });
        }
        this._addDataFilterHelperLayer();
        this._dragonflyLayers.push(this._rendererData.highlightLayer);
    }

    // Add the helper layer just above the data layer
    _addDataFilterHelperLayer() {
        if (this._mapInstance.hasDataFilter) {
            // Find the index of the first data layer
            const dataLayerIndex = this._dragonflyLayers.indexOf(this.dataLayers[0]);
            // add the helper layer above
            this._dragonflyLayers.splice(dataLayerIndex, 0, this.rendererData.dataFilterHelperLayer);
        }
    }

    _removeDataLayers() {
        this.rendererData.layers.forEach(dl => {
            const idx = this._dragonflyLayers.indexOf(dl);
            // if exists remove it
            if (idx !== -1) this._dragonflyLayers.splice(idx, 1);
        });
        this._removeDataFilterHelperLayer();
    }

    _removeDataFilterHelperLayer() {
        // in case the layer already exists on the map, and the filter is undefined,
        // that means we removed the filter and we must remove the helper layer
        const helperLayerExists = this._dragonflyLayers.find(dl => dl.id === this.rendererData.dataFilterHelperLayer.id);
        if (this._mapInstance.hasDataFilter || helperLayerExists) {
            const dataFilterHelperLayerIndex = this._dragonflyLayers.indexOf(this.rendererData.dataFilterHelperLayer);
            this._dragonflyLayers.splice(dataFilterHelperLayerIndex, 1);
        }
    }

    // For the purpose of hint values in filters we add the filter fields to the map source before the filter becomes
    // valid so wen can use the queryRenderedFeature to get the min and max values
    _addFilterFieldsToSource() {
        const dataFilter = this._mapInstance.dataFilter;
        const filters = dataFilter.filters;
        const validFilterKeys = Object.keys(filters);
        const fields = validFilterKeys.map(key => filters[key].field);

        this._rendererData.addFilterFiledsToDataSet(fields);
    }

    _applyDataFilterToRenderer() {
        if (this._mapInstance.hasDataFilter) {
            const dataFilter = this._mapInstance.dataFilter;
            const dataFilterFormatted = dataFilter.getFormattedFilter();
            this._rendererData.applyDataFilter(dataFilterFormatted);
        } else {
            const dataFilterWrapper = {
                fields: [],
                filter: undefined,
                helperLayerFilter: undefined,
            };
            this._rendererData.applyDataFilter(dataFilterWrapper);
        }
    }
}

export default MapInstanceData;
