import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ColorPaletteSample from '../visualizationEditor/selectors/colorPaletteSelector/ColorPaletteSample';

class CopyFromColorPaletteRow extends React.Component {

    onRowClick = () => {
        this.props.onColorPaletteClick(this.props.palette);
    };

    render() {
        const { palette } = this.props;
        const paletteRowClasses = classNames('palette__row clickable');
        return (<button
            className={paletteRowClasses}
            key={`${palette.id}_${palette.type}`}
            onClick={this.onRowClick}
            aria-label={palette.title}
        >
            <ColorPaletteSample colorPalette={palette} />
            <span className="palette__title">{palette.title}</span>
        </button>);
    }
}

CopyFromColorPaletteRow.propTypes = {
    onColorPaletteClick: PropTypes.func.isRequired,
    palette: PropTypes.object.isRequired,
};

CopyFromColorPaletteRow.defaultProps = {};

export default CopyFromColorPaletteRow;
