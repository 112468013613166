import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../../form/Checkbox';
import TextInput from '../../form/TextInput';

const LegendCardHeader = props => (
    <div className="annotation-card__header annotation-card__header--legend">
        <div className="flex-it center flex-start grow">
            <i className="material-icons annotation-card__header__icon">list</i>
            {props.expanded ? <TextInput
                value={props.title}
                className="annotation-card__title-input grow"
                placeholder="Legend title"
                onChange={props.onTitleChange}
            /> : <div className="body-1 annotation-card__title">{props.title === '' ? 'Legend' : props.title}</div>}
        </div>
        <div className="flex-it center space-between">
            <div className="flex-it center">
                {props.annotationLegendVisible && <button className="btn-icon">
                    <i
                        className="material-icons"
                        onClick={props.onToggleExpandLegend}
                    >
                        {props.expanded ? 'arrow_drop_up' : 'arrow_drop_down'}
                    </i>
                </button>}
                <div className="divider divider--vertical divider--btn-separator" />
            </div>
            <Checkbox
                checked={props.annotationLegendVisible}
                onCheck={props.onLegendToggle}
            />
        </div>
    </div>
);

LegendCardHeader.propTypes = {
    title: PropTypes.string,
    expanded: PropTypes.bool.isRequired,
    annotationLegendVisible: PropTypes.bool.isRequired,
    onToggleExpandLegend: PropTypes.func.isRequired,
    onLegendToggle: PropTypes.func.isRequired,
    onTitleChange: PropTypes.func.isRequired,
};
LegendCardHeader.defaultProps = {
    title: 'Legend',
};

export default LegendCardHeader;
