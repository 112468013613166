import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tooltip from '../Tooltip';

const InputModeSelector = ({ setNumericalMode, setPercentMode, isPercentMode, intl }) =>
    (<div className="flex-it flex-end">
        <Tooltip overlay={intl.formatMessage({ id: 'dataBrowser.count' })} >
            <button
                className={classNames('btn-icon', 'filter__btn', { selected: !isPercentMode })}
                onClick={setNumericalMode}
            >
                #
            </button>
        </Tooltip>
        <Tooltip overlay={intl.formatMessage({ id: 'dataBrowser.percent' })} >
            <button
                className={classNames('btn-icon', 'filter__btn', { selected: isPercentMode })}
                onClick={setPercentMode}
            >
                %
            </button>
        </Tooltip>
    </div>);

InputModeSelector.propTypes = {
    setNumericalMode: PropTypes.func.isRequired,
    setPercentMode: PropTypes.func.isRequired,
    isPercentMode: PropTypes.bool.isRequired,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(InputModeSelector);
