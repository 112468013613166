// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import BusComponent from '../BusComponent';
import Tooltip from '../Tooltip';

/**
 * @typedef Props
 * @property {string} mapInstanceId
 * @property {object} zoomPoint
 * @property {import('react-intl').intlShape} intl
 */
class CenterMapLayerButton extends BusComponent {
    onCenterMapOnLayer = () => {
        const { mapInstanceId, zoomPoint } = this.props;

        this.bus.emit('MAP_FLY_TO_REQUEST', {
            source: this,
            mapInstanceId,
            center: [zoomPoint.lng, zoomPoint.lat],
            zoom: 12,
            lockMode: true,
        });
    }

    render() {
        return (
            <Tooltip
                placement="bottom"
                mouseEnterDelay={0.5}
                mouseLeaveDelay={0}
                overlay={this.props.intl.formatMessage({
                    id: 'map.centerMapOnLayer',
                })}
            >
                <button
                    className="material-icons btn-flat-icon"
                    onClick={this.onCenterMapOnLayer}
                >
                    center_focus_weak
                </button>
            </Tooltip>
        );
    }
}

CenterMapLayerButton.propTypes = {
    intl: PropTypes.object.isRequired,
    mapInstanceId: PropTypes.string.isRequired,
    zoomPoint: PropTypes.object.isRequired,
};

export default injectIntl(CenterMapLayerButton);
