import React from 'react';
import PropTypes from 'prop-types';
import SimpleDropdown from '../dropdown/SimpleDropdown';

const TypeDropdown = ({ types, value, onChange, className, dropdownClassName, disabled }) => {
    const selectedItem = types.find(t => t.type === value);
    return (
        <SimpleDropdown
            onItemClick={onChange}
            items={types.map(opt => ({ id: opt.type, text: opt.text }))}
            selectedItem={value === undefined ? { id: types[0].type, text: types[0].text } : { id: selectedItem.type, text: selectedItem.text }}
            name="filter-type-dropdown"
            selectedItemClassName={className}
            className={dropdownClassName}
            disabled={disabled}
        />
    );
};

TypeDropdown.propTypes = {
    onChange: PropTypes.func.isRequired,
    types: PropTypes.array.isRequired,
    value: PropTypes.string,
    className: PropTypes.string,
    dropdownClassName: PropTypes.string,
    disabled: PropTypes.bool,
};

TypeDropdown.defaultProps = {
    value: undefined,
    className: '',
    dropdownClassName: '',
    disabled: false,
};

export default TypeDropdown;
