// @ts-check
import { getFeatureBoundingBox } from '../../helpers/Util';
import BaseHandler from './BaseHandler';

class CustomMapSelectionHandler extends BaseHandler {
    constructor(mapViewer) {
        super(mapViewer);
        this._data = mapViewer.dragonflyMapData.customMapSelectionData;
        this._isMapLoaded = this.map.loaded();

        this.setGluBusEvents({
            CUSTOM_MAP_SELECTION_SOURCE_DATA: this.onCustomMapSelectionSourceData,
            MAP_LOAD: this.onMapLoad,
            CLEAR_CUSTOM_MAP_SELECTION: this.onMapIsochroneRenderedDataRemoval,
            MAP_UPDATE_PREFERRED_SUMMARY_LEVEL_REQUEST: this.applySummaryLevelUpdate,
            ENTER_UPDATE_LOCATION_ANALYSIS_MODE: this.onEnterUpdateLocationAnalysisMode,
            SHOW_PIN_ON_MAP: this.onEnterUpdateLocationAnalysisMode,
            EXIT_LOCATION_ANALYSIS_MODE: this.onExitLocationAnalysisMode,
        });
    }

    onMapIsochroneRenderedDataRemoval(e) {
        if (!this._isMapLoaded || e.mapInstanceId !== this.mapInstanceId) {
            return;
        }

        const customSelectionDataLayers = this._data.layers;
        const customSelectionDataSource = this._data.source;

        this._data.clear();

        if (this.map.getSource(customSelectionDataSource.id)) {
            this.map.removeSource(customSelectionDataSource.id);
        }

        customSelectionDataLayers.forEach(dl => {
            if (this.map.getLayer(dl.id)) {
                this.map.removeLayer(dl.id);
            }
        });
    }

    onMapLoad(e) {
        if (e.source.id !== this.mapInstanceId) return;
        this._isMapLoaded = true;
    }

    /**
     * @param {object} payload
     * @param {boolean} payload.isIsochrone
     * @param {{features: import('../..').GeoJson[]}} payload.feature
     * @param {{features: import('mapbox-gl').MapboxGeoJSONFeature[]}} payload.labels
     * @param {string} payload.mapInstanceId
     */
    onCustomMapSelectionSourceData(payload) {
        if (!this._isMapLoaded || payload.mapInstanceId !== this.mapInstanceId) {
            return;
        }

        this._data.update(payload.isIsochrone, payload.feature, payload.labels);

        const {
            source: customSelectionDataSource,
            distanceLabelsSource: distanceLabelsDataSource,
        } = this._data;

        [customSelectionDataSource, distanceLabelsDataSource].forEach(
            ({ id, source }) => {
                if (this.map.getSource(id)) {
                    this.map.removeSource(id);
                }
                this.map.addSource(id, source);
            },
        );

        this._data.layers.forEach(dl => {
            if (this.map.getLayer(dl.id)) {
                this.map.removeLayer(dl.id);
            }
            this.map.setLayer(dl);
        });

        this.extendViewBounds(payload.feature.features);
    }

    applySummaryLevelUpdate(e) {
        if (e.mapInstanceId !== this.mapInstanceId) return;
        this._data.applySummaryLevelUpdate();
        // apply dragonfly layers zoom range and auto source changes to dragonfly map
        this._data.helperSelectionLayers.forEach(dl => {
            const mapDl = this.map.getLayer(dl.id);
            mapDl.sourceLayer = dl['source-layer'];
            this.map.setLayerZoomRange(dl.id, dl.minzoom, dl.maxzoom);
            this.map.style._updateLayer(mapDl);
        });
    }

    /**
     * Check for current map bounds. Zoom out only if new bounding box is bigger
     * then current
     */
    extendViewBounds(features) {
        // @ts-ignore
        const featureBoundingBox = getFeatureBoundingBox(features);
        /** @type {[[number, number], [number, number]]} */
        const bounds = [
            [featureBoundingBox.xMin, featureBoundingBox.yMin],
            [featureBoundingBox.xMax, featureBoundingBox.yMax],
        ];
        this.map.fitBounds(bounds, { padding: 10 });
    }

    /**
     * @param {object} payload
     * @param {string} payload.mapInstanceId
     * @param {import('../../objects/LocationAnalysisItem').default} payload.selectedItem
     */
    onEnterUpdateLocationAnalysisMode = ({ selectedItem, mapInstanceId }) => {
        if (this.mapInstanceId !== mapInstanceId) {
            return;
        }

        this._data.updatePin(selectedItem);
        // Add pin source and layer
        const { pinSourceId, pinSource, pinLayer } = this._data;
        // somehow min&max zoom gets cleared so make sure its set before adding to map
        pinLayer.minzoom = pinLayer.minzoom || 1;
        pinLayer.maxzoom = pinLayer.maxzoom || 21;
        if (this.map.getSource(pinSourceId)) {
            this.map.removeSource(pinSourceId);
        }
        this.map.addSource(pinSourceId, pinSource);
        if (pinLayer && this.map.getLayer(pinLayer.id)) {
            this.map.removeLayer(pinLayer.id);
        }
        this.map.setLayer(pinLayer);
    };

    /**
     *
     * @param {object} payload
     * @param {string} payload.mapInstanceId
     */
    onExitLocationAnalysisMode = ({ mapInstanceId }) => {
        if (this.mapInstanceId !== mapInstanceId) {
            return;
        }

        // Remove pin source and layer from map
        const { pinSourceId, pinLayer } = this._data;
        if (this.map.getSource(pinSourceId)) {
            this.map.removeSource(pinSourceId);
        }
        if (pinLayer && this.map.getLayer(pinLayer.id)) {
            this.map.removeLayer(pinLayer.id);
        }

        // Remove pin source and layer from dragonfly layers and sources
        this._data.removePin();
    };
}

export default CustomMapSelectionHandler;
