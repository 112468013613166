import 'babel-polyfill';

import dragonfly from 'dragonfly-v3';
import React from 'react';
import { render } from 'react-dom';
import uuid from 'node-uuid';
import injectTapEventPlugin from 'react-tap-event-plugin';

import Application from './app';
import ApplicationMode from './enums/ApplicationMode';
import AppConfig from './appConfig';
import Api from './apis/Api';
import Mixpanel from './helpers/Mixpanel';

injectTapEventPlugin();

window.DingoDebug = { AppConfig };

export default class {
    constructor(options) {
        if (!options.viewCode) {
            fetch(AppConfig.constants.defaultProjectViewCodeURL, {
                mode: 'cors',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                return null;
            })
            .then(data => {
                options.viewCode = data ? data.view_code : null;
                this.initialize(options);
            })
            .catch(err => console.error(err));
        } else {
            this.initialize(options);
        }
    }

    initialize(options) {
        const self = this;
        // in local environment, even if reportErrors is set to TRUE, entries might not appear in Sentry
        // that is because Sentry might be set up to filter out error reports originating from localhosts
        this._reportErrors = AppConfig.constants.reportErrors;

        /* eslint-disable */
        window.$buoop = {
            vs: { i: 10, f: -8, o: -8, s: 9, c: -8 },
            api: 4,
            reminder: 0,
            url: 'https://www.google.com/search?q=how+to+update+my+browser&oq=how+to+upda&aqs=chrome.0.69i59j69i60j69i57j69i60j35i39j0.1199j0j4&sourceid=chrome&ie=UTF-8',
            l: 'es',
            onshow: infos => {
                infos.div.onclick = null;
                self._reportErrors = false;
            },
            text: '<b>Your web browser ({brow_name}) is out of date.</b>  Please, update your browser for all features and best experience on this site <a{ignore_but}>Continue anyway</a>',
        };

        function $buo_f() {
            const e = document.createElement('script');
            e.src = '//browser-update.org/update.min.js';
            document.body.appendChild(e);
        }

        try {
            document.addEventListener('DOMContentLoaded', $buo_f, false);
        } catch (e) {
            window.attachEvent('onload', $buo_f);
        }

        // Init FB library
        window.fbAsyncInit = () => {
            FB.init({
                appId: `${AppConfig.constants.appIds.facebookAppId}`,
                autoLogAppEvents: false,
                xfbml: false,
                version: 'v2.10',
            });
            FB.AppEvents.logPageView();
        };

        Mixpanel.installSDK();

        (((d, s, id) => {
            const fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            const js = d.createElement(s);
            js.id = id;
            js.src = '//connect.facebook.net/en_US/sdk.js';
            fjs.parentNode.insertBefore(js, fjs);
        })(document, 'script', 'facebook-jssdk'));

        if (AppConfig.constants.appIds.hotjar) {
            (function(h, o, t, j, a, r) {
                h.hj = h.hj || function() { (h.hj.q = h.hj.q || []).push(arguments) };
                h._hjSettings = { hjid: AppConfig.constants.appIds.hotjar, hjsv: 6 };
                a = o.getElementsByTagName('head')[0];
                r = o.createElement('script');
                r.async = 1;
                r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
                a.appendChild(r);
            })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
        }
        /* eslint-enable */

        const mergedOptions = {
            appGuid: uuid.v4(),
            container: options.container || 'application',
            viewCode: options.viewCode,
            mode: options.mode || ApplicationMode.EXPLORE,
            config: options.config || {},
            location: options.location,
            locationAnalysis: options.locationAnalysis,
            variable: options.variable,
            dataBrowser: options.dataBrowser,
            frame: options.frame,
        };

        // override config
        AppConfig.overrideConstants(mergedOptions.config);

        // initialize API
        Api.initialize();

        // attach class to container node
        const containerNode = document.querySelector(`#${mergedOptions.container}`);
        containerNode.classList.add('se-application');

        const onProjectLoaded = project => {
            if (this._reportErrors) {
                // initialize sentry if we're supposed to report errors
                AppConfig.initializeSentry();
            }
        };

        // If the dragonfly is not supported report the error
        if (this._reportErrors && !dragonfly.supported()) {
            AppConfig.initializeSentry();
            AppConfig.sentry.captureMessage('WebGL Error - Dragonfly unsupported');
        }

        render(<Application ref={c => (this.dingoApp = c)} options={mergedOptions} />, containerNode, () => {
            window.DingoDebug.bus = this.dingoApp.bus;
            this.dingoApp.bus.on('PROJECT_LOAD_SUCCESS', onProjectLoaded);

            if (typeof options.onProjectLoaded === 'function') {
                this.dingoApp.bus.on('PROJECT_LOAD_SUCCESS', options.onProjectLoaded);
            }

            if (typeof options.onApplicationModeChanged === 'function') {
                this.dingoApp.bus.on('APPLICATION_MODE_UPDATE_SUCCESS', options.onApplicationModeChanged);
            }
        });
    }
}
