import { invertColor } from '../helpers/Util';

class BubbleRenderer {
    constructor(values) {
        this._insufficientDataRuleIndex = -1;
        this._nullDataRuleIndex = -1;

        if (values) {
            Object.keys(values).forEach(k => {
                if (this.constructor.prototype.hasOwnProperty(k)) {
                    this[k] = values[k];
                }
            });
        }
    }

    get type() {
        return 'BubbleRenderer';
    }

    get insufficientDataRuleIndex() {
        return this._insufficientDataRuleIndex;
    }

    set insufficientDataRuleIndex(insufficientDataRuleIndex) {
        this._insufficientDataRuleIndex = insufficientDataRuleIndex;
    }

    get nullDataRuleIndex() {
        return this._nullDataRuleIndex;
    }

    set nullDataRuleIndex(nullDataRuleIndex) {
        this._nullDataRuleIndex = nullDataRuleIndex;
    }

    get fieldList() {
        return this._fieldList;
    }

    set fieldList(fieldList) {
        this._fieldList = fieldList;
    }

    get rules() {
        return this._rules;
    }

    set rules(rules) {
        this._rules = rules;
    }

    get visibility() {
        return this._visibility;
    }

    set visibility(visibility) {
        this._visibility = visibility;
    }

    get bubbleSizeFieldName() {
        return this._bubbleSizeFieldName;
    }

    set bubbleSizeFieldName(bubbleSizeFieldName) {
        this._bubbleSizeFieldName = bubbleSizeFieldName;
    }
    /**
     * Calculated as UserStyleDataSource.bubbleSize/MetaVariable.bubbleSizeScale
     * @type {number}
     */
    get bubbleSizeFactor() {
        return this._bubbleSizeFactor;
    }

    set bubbleSizeFactor(bubbleSizeFactor) {
        this._bubbleSizeFactor = bubbleSizeFactor;
    }

    get maxBubbleSize() {
        return 150;
    }

    set maxBubbleSize(maxBubbleSize) {
        this._maxBubbleSize = maxBubbleSize;
    }

    get sizeTitle() {
        return this._sizeTitle;
    }

    set sizeTitle(sizeTitle) {
        this._sizeTitle = sizeTitle;
    }

    get colorTitle() {
        return this._colorTitle;
    }

    set colorTitle(colorTitle) {
        this._colorTitle = colorTitle;
    }

    get bubbleRadiusMultiplier() {
        return Math.sqrt(this.bubbleSizeFactor / Math.PI);
    }

    applyColorPalette(colorPalette, isFlipped) {
        let specialRulesCount = 0;
        if (this.insufficientDataRuleIndex !== -1) specialRulesCount += 1;
        if (this.nullDataRuleIndex !== -1) specialRulesCount += 1;

        const colors = colorPalette.interpolateColors(this.rules.length - specialRulesCount);
        const strokeColors = colorPalette.interpolateStrokeColors(this.rules.length);

        if (isFlipped) colors.reverse();

        this.rules.forEach((rule, ruleIdx) => {
            let paintColor;

            if (ruleIdx === this.insufficientDataRuleIndex) {
                paintColor = colorPalette.insufficientDataColor;
            } else if (ruleIdx === this.nullDataRuleIndex) {
                paintColor = colorPalette.nullDataColor;
            } else {
                paintColor = colors.shift();
                if (rule.filter.to === 0) {
                    // this rule is applied to negative values (rule.filter.to === 0)
                    // so invert the original color for `negative` counted bubbles
                    paintColor = invertColor(paintColor);
                }
            }

            rule.symbols[0].brushes.forEach(b => {
                b.fillColor = paintColor;
                b.strokeColor = strokeColors.shift();
                b.strokeOpacity = 0.5;

                return b;
            });
        });
    }

    equals(that) {
        return (this.type === that.type &&
        this.fieldList.equals(that.fieldList) &&
        this.rules.every((rule, index) => rule.equals(that.rules[index])) &&
        this.visibility.every((vis, index) => vis.equals(that.visibility[index])) &&
        this.insufficientDataRuleIndex === that.insufficientDataRuleIndex &&
        this.nullDataRuleIndex === that.nullDataRuleIndex &&
        this.bubbleSizeFieldName === that.bubbleSizeFieldName &&
        this.bubbleSizeFactor === that.bubbleSizeFactor &&
        this.sizeTitle === that.sizeTitle &&
        this.colorTitle === that.colorTitle);
    }

    clone() {
        return new BubbleRenderer({
            fieldList: this.fieldList.clone(),
            rules: this.rules.map(rule => rule.clone()),
            visibility: this.visibility.map(vis => vis.clone()),
            insufficientDataRuleIndex: this.insufficientDataRuleIndex,
            nullDataRuleIndex: this.nullDataRuleIndex,
            bubbleSizeFieldName: this.bubbleSizeFieldName,
            bubbleSizeFactor: this.bubbleSizeFactor,
            sizeTitle: this.sizeTitle,
            colorTitle: this.colorTitle,
        });
    }

    toJSON() {
        return {
            type: this.type,
            fieldList: this.fieldList.toJSON(),
            rules: this.rules.map(rule => rule.toJSON()),
            visibility: this.visibility.map(vis => vis.toJSON()),
            insufficientDataRuleIndex: this.insufficientDataRuleIndex,
            nullDataRuleIndex: this.nullDataRuleIndex,
            bubbleSizeFieldName: this.bubbleSizeFieldName,
            bubbleSizeFactor: this.bubbleSizeFactor,
            sizeTitle: this.sizeTitle,
            colorTitle: this.colorTitle,
        };
    }
}

export default BubbleRenderer;
