// @ts-check
import React from 'react';
import BusComponent from '../components/BusComponent';

/**
 * @template {{available: boolean}} PT
 * @typedef {Omit<PT, "available">} WithoutAvailable
 */
/**
 * @template {{available: boolean}} OT
 * @param {React.ComponentType<OT>} WrappedComponent
 */
export default function withReportAvailabilityCheck(WrappedComponent) {
    /**
     * @typedef Props
     * @property {import('../objects/MetaSurvey').default} survey
     *
     * @typedef State
     * @property {boolean} reportAvailabilityCheckInProgress
     * @property {boolean} reportAvailable
     * @extends BusComponent<Props & WithoutAvailable<OT>>
     */
    class ReportAvailabilityCheck extends BusComponent {
        constructor(props, context) {
            super(props, context);
            /** @type {State} */
            this.state = {
                reportAvailabilityCheckInProgress: true,
                reportAvailable: false,
            };
        }

        componentDidMount() {
            this.bindGluBusEvents({
                REPORT_AVAILABILITY_STATUS: this.onReportAvailabilityCheck,
            });

            this.doCheck(this.props.survey);
        }

        /** @param {Props} nextProps */
        componentWillReceiveProps(nextProps) {
            // No prop types for HoC
            // eslint-disable-next-line react/prop-types
            this.doCheck(nextProps.survey);
        }

        componentWillUnmount() {
            this.unbindGluBusEvents();
        }

        doCheck = survey => {
            if (!survey) {
                return;
            }
            this.emit('CHECK_REPORT_AVAILABILITY', {
                survey,
                source: this,
            });
        };

        /**
         *
         * @param {object} param0
         * @param {boolean} param0.available
         * @param {BusComponent} param0.source
         */
        onReportAvailabilityCheck = ({ available, source }) => {
            if (source === this) {
                this.setState({
                    reportAvailabilityCheckInProgress: false,
                    reportAvailable: available,
                });
            }
        };

        render() {
            const {
                reportAvailabilityCheckInProgress,
                reportAvailable,
            } = this.state;

            return (
                // @ts-ignore For some reason typescripts says that available is
                // not assignable to available.
                <WrappedComponent
                    {...this.props}
                    available={
                        !reportAvailabilityCheckInProgress && reportAvailable
                    }
                />
            );
        }
    }

    return ReportAvailabilityCheck;
}
