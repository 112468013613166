import React from 'react';
import { injectIntl } from 'react-intl';
import BusComponent from '../BusComponent';
import googleTagManagerEvents from '../../helpers/GoogleTagManagerEventsWrapper';
import { switchWarningPopupButtonEvents } from '../../enums/GoogleTagManagerEvents';

const GTMButton = googleTagManagerEvents(
    'button',
    switchWarningPopupButtonEvents,
);

/**
 * @typedef Props
 * @property {string[]} mapInstanceIds
 * @property {FrameType} frameType
 * @property {import('react-intl').intlShape} intl
 *
 * @extends {BusComponent<Props>}
 */
class FrameTypeSwitchWarning extends BusComponent {
    componentDidMount() {
        this.setGTMEvents();
    }

    componentDidUpdate() {
        this.setGTMEvents();
    }

    setGTMEvents = () => {
        const { frameType } = this.props;

        const gtmL = `Switching to ${frameType}, but has analysis.`;
        if (this.cancelButton) {
            this.cancelButton.setCustomAttribute('gtm-eventl', `${gtmL} Canceled.`);
        }
        if (this.continueButton) {
            this.continueButton.setCustomAttribute('gtm-eventl', `${gtmL} Continued.`);
        }
    }

    onContinue = () => {
        const { mapInstanceIds, frameType } = this.props;

        mapInstanceIds.forEach(mapInstanceId => {
            this.emit('EXIT_LOCATION_ANALYSIS_MODE', {
                mapInstanceId,
            });
        });
        this.emit('CHANGE_PROJECT_FRAME_TYPE_REQUEST', {
            frameType,
        });
        this.onClosePopup();
    };

    onClosePopup = () => {
        this.emit('CLOSE_MODAL');
    }

    render() {
        const { intl } = this.props;

        return (
            <div className="switcher-warning-popup">
                <div className="switcher-warning-popup__text">
                    <p className="switcher-warning-popup__text__paragraph">
                        <b>{intl.formatMessage({ id: 'modals.activeAnalysisWarning' })}</b>
                    </p>
                    <p className="switcher-warning-popup__text__paragraph">
                        {intl.formatMessage({ id: 'modals.analysisRemovalWarning' })}
                    </p>
                </div>
                <div className="flex-it flex-end">
                    <GTMButton
                        type="button"
                        tabIndex="0"
                        ref={btnRef => { this.cancelButton = btnRef; }}
                        onClick={this.onClosePopup}
                        className="btn-flat margin-right-10"
                    >
                        {intl.formatMessage({ id: 'cancel' })}
                    </GTMButton>
                    <GTMButton
                        type="button"
                        tabIndex="0"
                        ref={btnRef => { this.continueButton = btnRef; }}
                        className="btn-flat"
                        onClick={this.onContinue}
                    >
                        {intl.formatMessage({ id: 'continue' })}
                    </GTMButton>
                </div>
            </div>
        );
    }
}

export default injectIntl(FrameTypeSwitchWarning);
