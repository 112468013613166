import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../../form/Checkbox';

class LayerLibraryLayer extends React.PureComponent {
    onToggleVisibility = visible => {
        this.props.onToggleVisibility(this.props.id, visible);
    }

    render() {
        const { title, visible } = this.props;

        return (
            <div className="map-library__layer flex-it row center grow">
                <div className="map-library__layer-title flex-it center grow">
                    {title}
                </div>
                <Checkbox
                    checked={visible}
                    onCheck={this.onToggleVisibility}
                />
            </div>
        );
    }
}

LayerLibraryLayer.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    onToggleVisibility: PropTypes.func.isRequired,
};

export default LayerLibraryLayer;
