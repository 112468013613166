class ColorRamp {
    get from() {
        return this._from;
    }

    set from(from) {
        this._from = from;
    }

    get to() {
        return this._to;
    }

    set to(to) {
        this._to = to;
    }

    get bias() {
        return this._bias;
    }

    set bias(bias) {
        this._bias = bias;
    }

    interpolateColors(count) {
        return chroma.scale([this.from, this.to]).domain([this.bias, 1]).colors(count);
    }

    equals(that) {
        return (
        this.from === that.from &&
        this.to === that.to &&
        this.bias === that.bias);
    }

    clone() {
        return Object.assign(new ColorRamp(), JSON.parse(JSON.stringify(this)));
    }

    toJSON() {
        return {
            from: this.from,
            to: this.to,
            bias: this.bias,
        };
    }

}

export default ColorRamp;
