import AppConfig from '../appConfig';
import BaseController from './BaseController';
import SearchDataSource from '../dataSources/SearchDataSource';
import OmniSocialExplorerSearchDataSource from '../dataSources/OmniSocialExplorerSearchDataSource';
import MetadataDataSource from '../dataSources/MetadataDataSource';
import ProjectDataSource from '../dataSources/ProjectDataSource';
import Errors from '../enums/Error';
import Limits from '../enums/SearchLimit';
import {
    parseSocexResult,
    parseHereGeocodeResult,
} from '../helpers/Search';

class SearchController extends BaseController {
    static get name() {
        return 'SearchController';
    }

    static getInstance(options) {
        return new SearchController(options);
    }

    onActivate() {
        this.bindGluBusEvents({
            SEARCH_LOAD_REQUEST: this.onSearchLoadRequest,
            SEARCH_GEOCODE_AND_ENRICH_SINGLE_ITEM: this.onSearchGeocodeSingleItem,
            SEARCH_GET_RECENT_SEARCH_TERMS_REQUEST: this.onGetRecentSearchTermsRequest,
            SEARCH_ADD_RECENT_SEARCH_TERM_REQUEST: this.onAddRecentSearchTermRequest,
            VARIABLE_SEARCH_REQUEST: this.onVariableSearchRequest,
            REVERSE_GEOCODE_REQUEST: this.onReverseGeocodeRequest,
        });

        this.omniSocialExplorerSearchDatasource = this.activateSource(OmniSocialExplorerSearchDataSource);
        this.searchDataSource = this.activateSource(SearchDataSource);
        this.metadataSearchSource = this.activateSource(MetadataDataSource);
        this.projectDataSource = this.activateSource(ProjectDataSource);
        /** @type {(result: any) => import('../../..').SearchResult} */
        this.parseFunction = parseSocexResult;
    }

    /**
     * @param {object} params
     * @param {boolean} params.mapOnly
     * @param {string} params.searchTerm
     * @param {string[]} params.surveyNames
     * @param {number[]} params.years
     * @param {string} params.mapInstanceId
     */
    onVariableSearchRequest = params => {
        if (!params.searchTerm || !params.mapInstanceId) return;
        const onError = error => {
            this.bus.emit('VARIABLE_SEARCH_ERROR', {
                level: Errors.Error,
                originalError: error,
                additionalInfo: 'Searching for variables failed.',
            });
        };

        this.omniSocialExplorerSearchDatasource
            .searchVariable(params)
            .then(
                /** @param {import('../').VariableSearchResponse} response */ response => {
                    // let's group the search response variables by groups
                    /** @type {import('../').SearchResultTable[]} */
                    const groupedByTable = [];
                    response.variables.reduce((memo, variable) => {
                        const mostRecentSurvey = variable.comparableSurveys.find(
                            survey => survey.surveyYear === variable.surveyYear,
                        );
                        const tableGuid = mostRecentSurvey.tableGuid;
                        /** @type {import('../').SearchResultTable} */
                        let table = memo.find(t => t.tableGuid === tableGuid);
                        if (!table) {
                            table = {
                                tableGuid,
                                tableName: variable.tableName,
                                tableTitle: variable.tableTitle,
                                numberOfVariablesInTable:
                                    variable.numberOfVariablesInTable,
                                comparableSurveys: variable.comparableSurveys.map(
                                    s => ({
                                        surveyName: s.surveyName,
                                        surveyDisplayName: s.surveyDisplayName,
                                        surveyYear: s.surveyYear,
                                        datasetAbbreviation:
                                            s.datasetAbbreviation,
                                        tableName: variable.tableName,
                                    }),
                                ),
                                variables: [],
                                tags: variable.tags,
                            };
                            memo.push(table);
                        }
                        // add variables
                        table.variables.push({
                            guid: mostRecentSurvey.variableGuid,
                            name: variable.variableName,
                            shortLabel: variable.variableShortLabel,
                            longLabel: variable.variableLongLabel,
                            comparableSurveys: variable.comparableSurveys,
                            tableName: variable.tableName,
                        });
                        table.variables.sort((a, b) =>
                            a.name.localeCompare(b.name),
                        );
                        return memo;
                    }, groupedByTable);

                    this.bus.emit('VARIABLE_SEARCH_SUCCESS', {
                        tables: groupedByTable,
                        total: response.total,
                        didYouMean: response.didYouMean,
                        suggestions: response.suggestions.keywords,
                        mapInstanceId: params.mapInstanceId,
                        searchTerm: params.searchTerm,
                    });
                },
            )
            .catch(onError);
    };

    onGetRecentSearchTermsRequest = () => {
        const recentSearchTerms = this.searchDataSource.recentSearchTerms;

        this.bus.emit('SEARCH_GET_CURRENT_RECENT_SEARCH_TERMS_RESPONSE', { source: this, recentSearchTerms });
    };

    onAddRecentSearchTermRequest = e => {
        const recentSearchTerms = this.searchDataSource.addRecentSearchTerm(e.searchTerm);

        this.bus.emit('SEARCH_ADD_RECENT_SEARCH_TERM_SUCCESS', { source: this, recentSearchTerms });
    };

    /**
     * Event handler for search.
     *
     * @param params
     *      Params is the object which contains information about
     *      viewBox bounds and search term.
     *      In controller it is appended with limit parameter and passed
     *      to SearchDataSource.
     *      params has following objects:
     *          searchTerm: the string by which suggestions are retrieved.
     *          mapInstanceId: id of the map where search is,
     *          map: information about the map (contains viewBox bounds)
     */

    // For the search result without spatial data, we need to geocode it using the value/label from the first step
    onSearchGeocodeSingleItem(item) {
        const params = {
            searchTerm: item.value, // Use previously returned place label as the 2nd step geocoding
            limit: 1, // Geocoding with search limit 1 - returning most likely result only
            countryCode: AppConfig.constants.geocoder.countryCode,
        };

        this.searchDataSource
            .hereGeocode(params)
            .then(results => {
                this.bus.emit('SEARCH_SINGLE_ITEM_LOAD_SUCCESS', {
                    ...parseHereGeocodeResult(results.data[0]),
                    id: item.id, // Keep the same id from previous/master search
                });
            })
            .catch(error => {
                this.bus.emit('SEARCH_LOAD_ERROR', {
                    level: Errors.Error,
                    originalError: error,
                    additionalInfo: 'Geo-coding explicit item failed. Check availability of Here geocoder',
                });
            });
    }

    onSearchLoadRequest(params) {
        if (!params.searchTerm || !params.mapInstanceId) return;

        params.limit = Limits.SEARCH_LIMIT;
        params.countryCode = AppConfig.constants.geocoder.countryCode

        const onError = error => {
            this.bus.emit('SEARCH_LOAD_ERROR', {
                level: Errors.Error,
                originalError: error,
                additionalInfo: 'Searching for POIs failed. Check availability of geocoder',
            });
        };

        this.searchDataSource
            .hereGeocode(params)
            .then(results => {
                this.parseFunction = parseHereGeocodeResult;
                const data = this.parseResults(results.data);
                const response = {
                    mapInstanceId: params.mapInstanceId,
                    data,
                    query: results.query,
                };
                this.bus.emit('SEARCH_LOAD_SUCCESS', response);
            })
            .catch(onError);
    }

    /**
     * @param {object} param0
     * @param {import('../types').Point} param0.point
     */
    onReverseGeocodeRequest({ point }) {
        // HERE api
        this.searchDataSource
            .hereReverseGeocode({ point })
            .then(address => {
                this.bus.emit('REVERSE_GEOCODE_RESPONSE', { point, address });
            })
            .catch(error => {
                // TODO: avoid using catch. Reason for this is that this will catch
                // on promise. This will catch error done while rendering a
                // view. Instead, pass function as a second parameter of the
                // then method
                this.bus.emit('REVERSE_GEOCODE_ERROR', {
                    level: Errors.Error,
                    originalError: error,
                    additionalInfo: 'Reverse geocode failed.',
                });
            });
    }

    /**
     * Function that handles multiple results.
     * Parses each one and returns parsed array.
     * @param {[]} results
     *         Array of results to be parsed
     * @returns {import('../../..').SearchResult[]}
     *         Returns array of parsed results
     */
    parseResults(results) {
        return results.map(result => this.parseFunction(result));
    }

    static parseHereAutocompleteResult(result) {
        const { id, resultType, address } = result;
        const { label } = address;
        const originalLabelComponents = label.split(', ');
        const value = originalLabelComponents.length > 1 ?
                      originalLabelComponents.slice(0, originalLabelComponents.length - 1).join(', ') :
                      originalLabelComponents[0];
        return {
            id,
            type: resultType || 'place',
            value,
            featureType: null,
            boundingBox: null,
            point: null,
            points: null,
            geometry: null,
        };
    }

    onDeactivate() {
        this.unbindGluBusEvents();
    }
}

export default SearchController;
