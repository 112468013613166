//      

                                     
                                                      
                                                                            
                                                    
                                                  
                                                             

module.exports = draw;

function draw(painter         , sourceCache             , layer                  , coords                  ) {
    drawBubbles(painter, sourceCache, layer, coords);
    if (layer.isMask) return; //!
    drawFeature(painter, sourceCache, layer, coords);
}

function drawFeature(painter         , sourceCache             , layer                  , coords                  ) {

    if (painter._featuresStyles.features.length === 0) return;

    const features = painter._featuresStyles.features;
    const styles = painter._featuresStyles.styles;

    if (!features || features.length === 0) return;
    if (!styles || styles.length === 0) return;
    layer.transitionDisabled = true;

    for (let f = 0; f < features.length; f++) {
        const feature = features[f];
        const style = styles[f];

        if (layer.id !== feature.layer.id) continue;

        for (let c = 0; c < coords.length; c++) {
            const coord = coords[c];
            const tile = sourceCache.getTile(coord);

            const bucketMeta = tile.metadata.buckets[layer.id];
            if (!bucketMeta) continue;

            const featureMeta = bucketMeta.features[feature.id];
            if (!featureMeta) continue;

            const oldStyle = {};
            for (const prop in style) {
                oldStyle[prop] = layer.paint[prop];
                layer.paint[prop] = style[prop];
            }

            drawBubbles(painter, sourceCache, layer, [coord], featureMeta);

            // revert to previous style
            for (const prop in oldStyle) {
                layer.paint[prop] = oldStyle[prop];
            }
        }
    }
    layer.transitionDisabled = false;
}


function drawBubbles(painter         , sourceCache             , layer                  , coords                  , featureMeta                  ) {
    if (painter.renderPass !== 'translucent') return;

    const gl = painter.gl;

    painter.setDepthSublayer(0);
    painter.depthMask(false);

    const depthTestEnabled = gl.getParameter(gl.DEPTH_TEST);
    if (depthTestEnabled) gl.disable(gl.DEPTH_TEST);

    // Allow bubbles to be drawn across boundaries, so that
    // large bubbles are not clipped to tiles
    gl.disable(gl.STENCIL_TEST);

    for (let i = 0; i < coords.length; i++) {
        const coord = coords[i];

        const tile = sourceCache.getTile(coord);
        const bucket                = (tile.getBucket(layer)     );
        if (!bucket) continue;

        const programConfiguration = bucket.programConfigurations.get(layer.id);
        const program = painter.useProgram('bubble', programConfiguration);
        programConfiguration.setUniforms(gl, program, layer, {zoom: painter.transform.zoom});
        gl.uniform2fv(program.uniforms.u_extrude_scale, painter.transform.pixelsToGLUnits);
        gl.uniformMatrix4fv(program.uniforms.u_matrix, false, painter.translatePosMatrix(
            coord.posMatrix,
            tile,
            layer.paint['bubble-translate'],
            layer.paint['bubble-translate-anchor']
        ));

        program.draw(
            gl,
            gl.TRIANGLES,
            layer.id,
            bucket.layoutVertexBuffer,
            bucket.indexBuffer,
            bucket.segments,
            programConfiguration,
            undefined,
            undefined,
            featureMeta);
    }

    if (depthTestEnabled) gl.enable(gl.DEPTH_TEST);
}
