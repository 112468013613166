const colorSpaces = require('../util/color_spaces');
const Color = require('../util/color');
const extend = require('../util/extend');

function createDragonflyFunction(parameters, propertySpec) {
    const zoomAndFeatureDependent = parameters.stops && typeof parameters.stops[0][0] === 'object'; //!
    const featureDependent = zoomAndFeatureDependent || parameters.property !== undefined; //!
    const zoomDependent = zoomAndFeatureDependent || !featureDependent; //!
    const isColor = propertySpec.type === 'color';

    if (isColor) {
        parameters = extend({}, parameters);

        if (parameters.stops) {
            parameters.stops = parameters.stops.map((stop) => {
                return [stop[0], Color.parse(stop[1])];
            });
        }

        if (parameters.default) {
            parameters.default = Color.parse(parameters.default);
        } else {
            parameters.default = Color.parse(propertySpec.default);
        }
    }

    let fun; //!

    let innerFun; //!
    let hashedStops; //!
    let categoricalKeyType; //!
    if (parameters.type === 'dragonfly-interval') { //!
        parameters.stops.forEach(stop => (stop[1] = stop[1].toArray()));
        innerFun = evaluateDragonflyIntervalFunction; ///!
    } else if (parameters.type === 'dragonfly-categorical') { //!
        parameters.stops.forEach(stop => (stop[1] = stop[1].toArray()));
        innerFun = evaluateDragonflyCategoricalFunction; //!
        // For categorical functions, generate an Object as a hashmap of the stops for fast searching //!
        hashedStops = Object.create(null); //!
        for (const stop of parameters.stops) { //!
            hashedStops[stop[0]] = stop[1]; //!
        } //!
    } //!
    let outputFunction; //!
    // If we're interpolating colors in a color system other than RGBA, //!
    // first translate all stop values to that color system, then interpolate //!
    // arrays as usual. The `outputFunction` option lets us then translate //!
    // the result of that interpolation back into RGBA. //!
    if (parameters.colorSpace && parameters.colorSpace !== 'rgb') { //!
        if (colorSpaces[parameters.colorSpace]) { //!
            const colorspace = colorSpaces[parameters.colorSpace]; //!
            // Avoid mutating the parameters value //!
            parameters = JSON.parse(JSON.stringify(parameters)); //!
            for (let s = 0; s < parameters.stops.length; s++) { //!
                parameters.stops[s] = [ //!
                    parameters.stops[s][0], //!
                    colorspace.forward(parameters.stops[s][1]) //!
                ]; //!
            } //!
            outputFunction = colorspace.reverse; //!
        } else { //!
            throw new Error(`Unknown color space: ${parameters.colorSpace}`); //!
        } //!
    } else { //!
        outputFunction = identityFunction; //!
    } //!

    if (zoomAndFeatureDependent) { //!
        const featureFunctions = {}; //!
        const zoomStops = []; //!
        for (let s = 0; s < parameters.stops.length; s++) { //!
            const stop = parameters.stops[s]; //!
            const zoom = stop[0].zoom; //!
            if (featureFunctions[zoom] === undefined) { //!
                featureFunctions[zoom] = { //!
                    zoom: zoom, //!
                    type: parameters.type, //!
                    property: parameters.property, //!
                    default: parameters.default, //!
                    stops: [] //!
                }; //!
                zoomStops.push(zoom); //!
            } //!
            featureFunctions[zoom].stops.push([stop[0].value, stop[1]]); //!
        } //!
        const featureFunctionStops = []; //!
        for (const z of zoomStops) { //!
            featureFunctionStops.push([featureFunctions[z].zoom, createDragonflyFunction(featureFunctions[z], propertySpec)]); //!
        } //!
        // Sort by zoom levels ascending //!
        featureFunctionStops.sort((a, b) => { return a[0] - b[0]; }); //!
        // Check zoom levels if the dragonfly functions used //!
        fun = function(zoom, feature) { //!
            return outputFunction(evaluateZoomDependent(featureFunctionStops, zoom)(zoom, feature)); //!
        }; //!
        fun.kind = 'composite';
        if (parameters.type === 'dragonfly-categorical') { //!
            fun.categoricalValues = {}; //!
            for (let s = 0; s < parameters.stops.length; s++) { //!
                const stop = parameters.stops[s]; //!
                if (!fun.categoricalValues.hasOwnProperty(stop[0].value)) { //!
                    fun.categoricalValues[stop[0].value] = Object.keys(fun.categoricalValues).length; //!
                } //!
            } //!
        } //!
    } else if (zoomDependent) {
        fun = function(zoom) {
            return outputFunction(innerFun(parameters, propertySpec, zoom, hashedStops, categoricalKeyType)); //!
        }; //!
        fun.kind = 'camera';
    } else { //!
        fun = function(zoom, feature) { //!
            const props = feature && feature.properties || {};
            const value = props[parameters.property]; //!
            return outputFunction(innerFun(parameters, propertySpec, value, hashedStops, categoricalKeyType)); //!
        }; //!
        fun.kind = 'source';
        if (parameters.type === 'dragonfly-categorical') { //!
            fun.categoricalValues = {}; //!
            for (let s = 0; s < parameters.stops.length; s++) { //!
                const stop = parameters.stops[s]; //!
                if (!fun.categoricalValues.hasOwnProperty(stop[0])) { //!
                    fun.categoricalValues[stop[0]] = Object.keys(fun.categoricalValues).length; //!
                } //!
            } //!
        } //!
    }
    return fun;
}
function identityFunction(x) {
    return x;
}

function evaluateDragonflyCategoricalFunction(parameters) { //!
    const values = []; //!
    parameters.stops.forEach(stop => { //!
        if (Array.isArray(stop[1])) { //!
            stop[1].forEach(el => values.push(el)); //!
        } else { //!
            values.push(stop[1]); //!
        } //!
    }); //!
    return values; //!
} //!

function evaluateDragonflyIntervalFunction(parameters) { //!
    const values = []; //!
    parameters.stops.forEach(stop => { //!
        if (Array.isArray(stop[1])) { //!
            stop[1].forEach(el => values.push(el)); //!
        } else { //!
            values.push(stop[1]); //!
        } //!
    }); //!
    return values; //!
} //!


function evaluateZoomDependent(featureFunctionStops, zoom) { //!
    const n = featureFunctionStops.length; //!
    if (n === 1) return featureFunctionStops[0][1]; //!
    let zoomLevel, i; //!
    for (i = 0; i < n; i++) { //!
        if (featureFunctionStops[i][0] >= zoom) { //!
            zoomLevel = featureFunctionStops[i]; //!
            break; //!
        } //!
    } //!
    if (zoomLevel === undefined) return featureFunctionStops[n - 1][1]; //!
    return zoomLevel[1]; //!
} //!


module.exports = createDragonflyFunction;
