const annotationsMarkers = [
    { fileName: 'airfield', id: 'airfield', label: 'Airfield' },
    { fileName: 'airport', id: 'airport', label: 'Airport' },
    { fileName: 'america_football', id: 'america-football', label: 'America football' },
    { fileName: 'art_gallery', id: 'art-gallery', label: 'Art gallery' },
    { fileName: 'bakery', id: 'bakery', label: 'Bakery' },
    { fileName: 'bank', id: 'bank', label: 'Bank' },
    { fileName: 'bar', id: 'bar', label: 'Bar' },
    { fileName: 'baseball', id: 'baseball', label: 'Baseball' },
    { fileName: 'basketball', id: 'basketball', label: 'Basketball' },
    { fileName: 'beer', id: 'beer', label: 'Beer' },
    { fileName: 'bicycle', id: 'bicycle', label: 'Bicycle' },
    { fileName: 'boom', id: 'boom', label: 'Boom' },
    { fileName: 'building', id: 'building', label: 'Building' },
    { fileName: 'bus', id: 'bus', label: 'Bus' },
    { fileName: 'cafe', id: 'cafe', label: 'Cafe' },
    { fileName: 'camera', id: 'camera', label: 'Camera' },
    { fileName: 'campsite', id: 'campsite', label: 'Campsite' },
    { fileName: 'car', id: 'car', label: 'Car' },
    { fileName: 'cemetery', id: 'cemetery', label: 'Cemetery' },
    { fileName: 'chemist', id: 'chemist', label: 'Chemist' },
    { fileName: 'cinema', id: 'cinema', label: 'Cinema' },
    { fileName: 'circle', id: 'circle', label: 'Circle' },
    { fileName: 'circle_1', id: 'circle-1', label: 'Circle 1' },
    { fileName: 'circle_2', id: 'circle-2', label: 'Circle 2' },
    { fileName: 'city', id: 'city', label: 'City' },
    { fileName: 'clothing_store', id: 'clothing-store', label: 'Clothing store' },
    { fileName: 'college', id: 'college', label: 'College' },
    { fileName: 'commercial', id: 'commercial', label: 'Commercial' },
    { fileName: 'corn', id: 'corn', label: 'Corn' },
    { fileName: 'cross', id: 'cross', label: 'Cross' },
    { fileName: 'dam', id: 'dam', label: 'Dam' },
    { fileName: 'danger', id: 'danger', label: 'Danger' },
    { fileName: 'disability', id: 'disability', label: 'Disability' },
    { fileName: 'dog_park', id: 'dog-park', label: 'Dog park' },
    { fileName: 'embassy', id: 'embassy', label: 'Embassy', offset: true },
    { fileName: 'emergency_telephone', id: 'emergency-telephone', label: 'Emergency telephone' },
    { fileName: 'entrance', id: 'entrance', label: 'Entrance' },
    { fileName: 'explosion', id: 'explosion', label: 'Explosion' },
    { fileName: 'farm', id: 'farm', label: 'Farm' },
    { fileName: 'fast_food', id: 'fast-food', label: 'Fast food' },
    { fileName: 'feathers', id: 'feathers', label: 'Feathers' },
    { fileName: 'ferry', id: 'ferry', label: 'Ferry' },
    { fileName: 'fire_station', id: 'fire-station', label: 'Fire station' },
    { fileName: 'fish', id: 'fish', label: 'Fish' },
    { fileName: 'fuel', id: 'fuel', label: 'Fuel' },
    { fileName: 'garden', id: 'garden', label: 'Garden' },
    { fileName: 'golf', id: 'golf', label: 'Golf' },
    { fileName: 'grapes', id: 'grapes', label: 'Grapes' },
    { fileName: 'grocery', id: 'grocery', label: 'Grocery' },
    { fileName: 'hairdresser', id: 'hairdresser', label: 'Hairdresser' },
    { fileName: 'harbor', id: 'harbor', label: 'Harbor' },
    { fileName: 'heart', id: 'heart', label: 'Heart' },
    { fileName: 'helioport', id: 'helioport', label: 'Helioport' },
    { fileName: 'hospital', id: 'hospital', label: 'Hospital' },
    { fileName: 'industrial', id: 'industrial', label: 'Industrial' },
    { fileName: 'land_use', id: 'land-use', label: 'Land use' },
    { fileName: 'library', id: 'library', label: 'Library' },
    { fileName: 'lighthouse', id: 'lighthouse', label: 'Lighthouse' },
    { fileName: 'liquor_store', id: 'liquor-store', label: 'Liquor store' },
    { fileName: 'lodging', id: 'lodging', label: 'Lodging' },
    { fileName: 'logging', id: 'logging', label: 'Logging' },
    { fileName: 'marker_default', id: 'marker-default', label: 'Marker default', offset: true },
    { fileName: 'minefield', id: 'minefield', label: 'Minefield' },
    { fileName: 'minerals', id: 'minerals', label: 'Minerals' },
    { fileName: 'mobile_phone', id: 'mobile-phone', label: 'Mobile phone' },
    { fileName: 'monument', id: 'monument', label: 'Monument' },
    { fileName: 'museum', id: 'museum', label: 'Museum' },
    { fileName: 'music', id: 'music', label: 'Music' },
    { fileName: 'oil_well', id: 'oil-well', label: 'Oil_well' },
    { fileName: 'park', id: 'park', label: 'Park' },
    { fileName: 'parking', id: 'parking', label: 'Parking' },
    { fileName: 'parking_garage', id: 'parking-garage', label: 'Parking garage' },
    { fileName: 'pharmacy', id: 'pharmacy', label: 'Pharmacy' },
    { fileName: 'pitch', id: 'pitch', label: 'Pitch' },
    { fileName: 'place_of_workship', id: 'place-of-workship', label: 'Place of worship' },
    { fileName: 'playground', id: 'playground', label: 'Playground' },
    { fileName: 'point', id: 'point', label: 'Point' },
    { fileName: 'police', id: 'police', label: 'Police' },
    { fileName: 'polling_place', id: 'polling-place', label: 'Polling place' },
    { fileName: 'post', id: 'post', label: 'Post' },
    { fileName: 'prison', id: 'prison', label: 'Prison' },
    { fileName: 'rail', id: 'rail', label: 'Rail' },
    { fileName: 'religious_christian', id: 'religious-christian', label: 'Religious christian' },
    { fileName: 'religious_jewish', id: 'religious-jewish', label: 'Religious jewish' },
    { fileName: 'religious_muslim', id: 'religious-muslim', label: 'Religious muslim' },
    { fileName: 'restaurant', id: 'restaurant', label: 'Restaurant' },
    { fileName: 'road_block', id: 'road-block', label: 'Road block' },
    { fileName: 'rocket', id: 'rocket', label: 'Rocket' },
    { fileName: 'school', id: 'school', label: 'School' },
    { fileName: 'scooter', id: 'scooter', label: 'Scooter' },
    { fileName: 'shells', id: 'shells', label: 'Shells' },
    { fileName: 'shop', id: 'shop', label: 'Shop' },
    { fileName: 'skiing', id: 'skiing', label: 'Skiing' },
    { fileName: 'skins', id: 'skins', label: 'Skins' },
    { fileName: 'slaughterhouse', id: 'slaughterhouse', label: 'Slaughterhouse' },
    { fileName: 'soccer', id: 'soccer', label: 'Soccer' },
    { fileName: 'square', id: 'square', label: 'Square' },
    { fileName: 'square_stroked', id: 'square-stroked', label: 'Square stroked' },
    { fileName: 'star', id: 'star', label: 'Star' },
    { fileName: 'star_stroked', id: 'star-stroked', label: 'Star stroked' },
    { fileName: 'suitcase', id: 'suitcase', label: 'Suitcase' },
    { fileName: 'swimming', id: 'swimming', label: 'Swimming' },
    { fileName: 'telephone', id: 'telephone', label: 'Telephone' },
    { fileName: 'tennis', id: 'tennis', label: 'Tennis' },
    { fileName: 'theatre', id: 'theatre', label: 'Theatre' },
    { fileName: 'toilets', id: 'toilets', label: 'Toilets' },
    { fileName: 'town', id: 'town', label: 'Town' },
    { fileName: 'town_hall', id: 'town-hall', label: 'Town hall' },
    { fileName: 'triangle', id: 'triangle', label: 'Triangle' },
    { fileName: 'triangle_stroked', id: 'triangle-stroked', label: 'Triangle stroked' },
    { fileName: 'village', id: 'village', label: 'Village' },
    { fileName: 'warehouse', id: 'warehouse', label: 'Warehouse' },
    { fileName: 'waste_basket', id: 'waste-basket', label: 'Waste basket' },
    { fileName: 'water', id: 'water', label: 'Water' },
    { fileName: 'wetland', id: 'wetland', label: 'Wetland' },
];

export const basePngSize = 64;
export const baseSvgSize = 64;

export default annotationsMarkers;
