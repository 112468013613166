import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import ColorPaletteRow from './ColorPaletteRow';
import { focusFirstChild } from '../../../../../helpers/Util';

class ColorPaletteList extends React.Component {
    componentDidMount() {
        focusFirstChild(this.colorPaletteListContainer);
    }

    render() {
        const {
            mapInstanceId,
            colorPalettesCollection,
            paletteEditable,
            selectedColorPalette,
            onEditPaletteClick,
            onColorPaletteClick,
            intl,
        } = this.props;

        const colorPaletteList = colorPalettesCollection.map(paletteGroup => {
            const paletteTitle = <div className="menu-subheader color-palettes-list__group-title">{intl.formatMessage({ id: paletteGroup.name })}</div>;
            const colorPaletteGroup = paletteGroup.list.map(palette => (<ColorPaletteRow
                key={`${mapInstanceId}_${palette.id}_${palette.type}`}
                palette={palette}
                paletteEditable={paletteEditable}
                selected={selectedColorPalette && selectedColorPalette.id === palette.id}
                paletteGroup={paletteGroup.name}
                onEditPaletteClick={onEditPaletteClick}
                onColorPaletteClick={onColorPaletteClick}
            />));

            return (<div key={paletteGroup.name} className="color-palettes-list__group">
                {paletteTitle}
                {colorPaletteGroup}
            </div>);
        });

        return (<div className="color-palettes-list vertical-scrollbar" ref={ref => { this.colorPaletteListContainer = ref; }}>
            {colorPaletteList}
        </div>);
    }
}

ColorPaletteList.propTypes = {
    colorPalettesCollection: PropTypes.array.isRequired,
    selectedColorPalette: PropTypes.object.isRequired,
    onColorPaletteClick: PropTypes.func.isRequired,
    onEditPaletteClick: PropTypes.func,
    paletteEditable: PropTypes.bool,
    mapInstanceId: PropTypes.string.isRequired,
    intl: PropTypes.object.isRequired,
};

ColorPaletteList.defaultProps = {
    selected: false,
    disabled: false,
    paletteEditable: true,
    onEditPaletteClick: undefined,
};

export default injectIntl(ColorPaletteList);
