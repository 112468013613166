import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import BusComponent from '../../BusComponent';

import AppConfig from '../../../appConfig';
import { uniqueValues } from '../../../helpers/Util';

import FileDropArea from '../../uploadFile/FileDropArea';
import FileInput from '../../form/FileInput';
import Loader from '../../Loader';
import ImportSummaryLevelPopup from './ImportSummaryLevelPopup';
import UnmatchedSelectionPopup from './UnmatchedSelectionPopup';
import Tooltip from '../../Tooltip';
import { uploadGeographyListButtonEvents } from '../../../enums/GoogleTagManagerEvents';
import googleTagManagerEvents from '../../../helpers/GoogleTagManagerEventsWrapper';

const UploadButton = googleTagManagerEvents('div', uploadGeographyListButtonEvents);

class GeoSelectionImport extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            importInProgress: false,
            importError: false,
        };
        this.bindGluBusEvents({
            IMPORT_GEO_SELECTION_UPLOAD_ERROR: this.onImportGeoSelectionUploadError,
            IMPORT_GEO_SELECTION_UPLOAD_SUCCESS: this.onImportGeoSelectionUploadSuccess,
            IMPORT_GEO_SELECTION_SUMMARY_LEVEL: this.onImportGeoSelectionSummaryLevel,
        });
        this.boundOnCancelImportGeoSelection = this.onCancelImportGeoSelection.bind(this);
        this.pendingImport = { selection: undefined, warnings: undefined, excluded: undefined };
    }

    componentWillUnmount() {
        this.bus.off('MODAL_CLOSED', this.boundOnCancelImportGeoSelection);
        this.unbindGluBusEvents();
    }

    onImportGeoSelectionUploadSuccess(eventMap) {
        if (eventMap.mapInstanceId === this.props.mapInstanceId && this.state.importInProgress) {
            const selection = eventMap.selection;
            const warnings = eventMap.warnings;
            const excluded = eventMap.excluded;
            const summaryLevels = Object.values(eventMap.summaryLevels);
            // ask user to select summary level if only one should be imported
            if (!this.props.multipleSummaryLevels && summaryLevels.length > 1) {
                this.emit('OPEN_MODAL', {
                    modalContent: React.createElement(ImportSummaryLevelPopup, {
                        summaryLevels,
                        mapInstanceId: this.props.mapInstanceId,
                    }),
                    name: 'Geographies import',
                    title: 'modals.importGeographies',
                    modalBoxClass: 'modal-box--geo-selection',
                    canClickAway: false,
                });
                this.pendingImport = { selection, warnings, excluded };
                this.bus.once('MODAL_CLOSED', this.boundOnCancelImportGeoSelection);
                return;
            }
            // filter out parent selections if needed
            this._filterParentSelection(selection, warnings);
            this.bus.off('MODAL_CLOSED', this.boundOnCancelImportGeoSelection);
            this._showImportWarnings(warnings, summaryLevels);
            this.pendingImport = undefined;
            this.setState({
                importError: eventMap.error,
                importInProgress: false,
            }, () => {
                this.emit('MAP_SELECTION_IMPORT', {
                    selection,
                    excluded,
                    mapInstanceId: this.props.mapInstanceId,
                });
            });
        }
    }

    onImportGeoSelectionUploadError(eventMap) {
        if (eventMap.mapInstanceId === this.props.mapInstanceId) {
            this.bus.off('MODAL_CLOSED', this.boundOnCancelImportGeoSelection);
            this.pendingImport = undefined;
            this.setState({
                importError: eventMap.error,
                importInProgress: false,
            });
        }
    }

    onImportGeoSelectionSummaryLevel(eventMap) {
        if (this.state.importInProgress && this.pendingImport) {
            this.bus.off('MODAL_CLOSED', this.boundOnCancelImportGeoSelection);
            this.emit('CLOSE_MODAL');
            // get selection, warnings and excluded geos for pending selection
            const selection = {}, warnings = {}, excluded = this.pendingImport.excluded;
            selection[eventMap.summaryLevel.name] = uniqueValues(this.pendingImport.selection[eventMap.summaryLevel.name], 'fips');
            if (this.pendingImport.warnings[eventMap.summaryLevel.name]) {
                warnings[eventMap.summaryLevel.name] = this.pendingImport.warnings[eventMap.summaryLevel.name];
            }
            // filter out parent selections if needed
            this._filterParentSelection(selection, warnings);
            this._showImportWarnings(warnings, [eventMap.summaryLevel]);

            this.pendingImport = undefined;
            this.setState({
                importError: eventMap.error,
                importInProgress: false,
            }, () => {
                this.emit('MAP_SELECTION_IMPORT', {
                    selection,
                    excluded,
                    mapInstanceId: this.props.mapInstanceId,
                });
            });
        }
    }

    onCancelImportGeoSelection() {
        this.bus.off('MODAL_CLOSED', this.boundOnCancelImportGeoSelection);
        this.pendingImport = undefined;
        this.setState({
            importError: undefined,
            importInProgress: false,
        });
    }

    onUpload = file => {
        this.emit('IMPORT_GEO_SELECTION_UPLOAD_REQUEST', {
            file,
            limit: this.props.limit,
            surveyName: this.props.surveyName,
            mapInstanceId: this.props.mapInstanceId,
        });
        this.setState({
            importError: undefined,
            importInProgress: true,
        });
    }

    onFileSelected = files => {
        if (files && files.length) {
            this.onUpload(files[0]);
        }
    }

    render() {
        if (this.state.importInProgress) {
            return (<div className={classnames('geo-selection-import flex-it column', this.props.className)}>
                <Loader text="Importing geo selection" />
            </div>);
        }
        return (<div className={classnames('geo-selection-import flex-it column center', this.props.className)}>
            <div className="flex-column flex-one vertical-scrollbar">
                <h2 className="text-center geo-selection-import__title">
                    {this.props.intl.formatMessage({ id: 'dataBrowser.selectGeographies' })}
                </h2>
                <div className="geo-selection-import__description flex-it center justify-center">
                    <div>
                        {this.props.intl.formatMessage({ id: 'dataBrowser.clickOnTheMapToSelectGeographies' })}
                    </div>
                    <UploadButton>
                        <FileInput
                            accept="text/csv,.csv"
                            onChange={this.onFileSelected}
                            className="btn-link"
                        />
                    </UploadButton>
                    <Tooltip
                        placement="topLeft"
                        mouseEnterDelay={0.05}
                        mouseLeaveDelay={0}
                        overlay={<span className="light-text">{this.props.intl.formatMessage({ id: 'dataBrowser.uploadPreviouslyDownloadedGeoFile' })}</span>}
                    >
                        <i className="material-icons geo-selection-import__icon">help</i>
                    </Tooltip>
                </div>
                <FileDropArea
                    graphics={`${AppConfig.constants.assetsBaseURL}/svg/select-geographies.svg`}
                    onChange={this.onUpload}
                    errors={[this.state.importError]}
                    disabled
                />
            </div>
        </div>);
    }

    _showImportWarnings(warnings, summaryLevels) {
        if (Object.keys(warnings).length) {
            this.emit('OPEN_MODAL', {
                modalContent: React.createElement(UnmatchedSelectionPopup, {
                    warnings,
                    summaryLevels,
                    surveyName: this.props.surveyName,
                }),
                name: 'Ignored geographies warning',
                title: 'modals.ignoredGeographies',
                modalBoxClass: 'modal-box--geo-selection',
                canClickAway: false,
            });
        }
    }

    _filterParentSelection(selection, warnings) {
        if (!this.props.parentSelection) {
            Object.keys(selection).forEach(id => {
                const ignoredGeos = [];
                selection[id] = selection[id].filter(geo => {
                    if (!geo.isParentSelection) return true;
                    ignoredGeos.push(geo.fips);
                    return false;
                });
                if (ignoredGeos.length) {
                    warnings[id] = warnings[id] || [];
                    ignoredGeos.forEach(g => warnings[id].push(g));
                }
            });
        }
    }
}

GeoSelectionImport.propTypes = {
    multipleSummaryLevels: PropTypes.bool,
    parentSelection: PropTypes.bool,
    intl: PropTypes.object.isRequired,
};

GeoSelectionImport.defaultProps = {
    multipleSummaryLevels: false,
    parentSelection: false,
};

export default injectIntl(GeoSelectionImport);
