import GLU from '../glu2.js/src/index';
import MapParser from '../helpers/MapParser';

class MapDataSource extends GLU.DataSource {
    static get name() {
        return 'MapDataSource';
    }

    static getInstance() {
        return new MapDataSource();
    }

    constructor() {
        super(() => false);
        /** @type {Object.<string, import('../objects/Map').default>} */
        this._currentMaps = {};
        this._libraryLayers = [];

        this._shouldReturnToAutomaticSummaryLevelOnLocationExit = false;
    }

    get currentMaps() {
        return this._currentMaps;
    }

    /**
     * ids of layers retrieved from layer library.
     * It is as if you do `layersLibrary.flatMap(layerInLibrary => layerInLibrary.layers.map(layer => layer.id))`
     * @type {string[]}
     */
    get libraryLayers() {
        return this._libraryLayers;
    }

    set libraryLayers(layers) {
        this._libraryLayers = layers;
    }
    /**
     * @type {boolean}
     * This property indicates should property preferredDataLayerId be
     * cleared when user exits location analysis. It is set to true only
     * if selected summary level was automatic when location analysis started.
     */
    get shouldReturnToAutomaticSummaryLevelOnLocationExit() {
        return this._shouldReturnToAutomaticSummaryLevelOnLocationExit;
    }

    set shouldReturnToAutomaticSummaryLevelOnLocationExit(should) {
        this._shouldReturnToAutomaticSummaryLevelOnLocationExit = should;
    }

    removeLibraryLayers(layers) {
        return layers.filter(
            layer => !this.libraryLayers.some(layerId => layerId === layer.id),
        );
    }

    /**
     * @param {string} mapURL
     * @param {boolean} force
     * @returns {Promise<import('../objects/Map').default>}
     */
    loadMapByURL(mapURL, force = false) {
        const params = { mapURL };
        if (force) {
            this.deleteDoneRequestByParam(params);
        }

        /**
         * @param {(value: import('../objects/Map').default[] | PromiseLike<import('../objects/Map').default[]>) => void} resolve
         * @param {() => {})} reject
         */
        const logic = (resolve, reject) => {
            const httpRequest = new XMLHttpRequest();

            httpRequest.onreadystatechange = () => {
                if (httpRequest.readyState === XMLHttpRequest.DONE) {
                    if (httpRequest.status === 200) {
                        const mapJSON = httpRequest.response;
                        const mapJSONParser = new MapParser(
                            JSON.parse(mapJSON),
                        );
                        const map = mapJSONParser.map;

                        map.layers = this.removeLibraryLayers(map.layers);

                        this._currentMaps[map.id] = map;
                        resolve([map]);
                    } else {
                        reject('There was a problem fetching the base map.');
                    }
                }
            };

            httpRequest.open('GET', mapURL);
            httpRequest.send();
        };

        return new Promise((resolve, reject) => {
            this.cacheRequest(params, logic).then(responses => {
                resolve(responses[0]);
            }, reject);
        });
    }
}

export default MapDataSource;
