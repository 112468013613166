import React from 'react';

import BusComponent from '../BusComponent';
import PointLayerEditor from './layerEditor/pointLayerEditor/PointLayerEditor';
import LayerHeader from './layerEditor/EditorHeader';
import Loader from '../Loader';

class UserDataLayerEditor extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            isVisualizationSwitcherPopupOpen: false,
        };
        this.bindGluBusEvents({
            USER_DATA_LAYER_READY: this.onUserDataLayerReady,
            MAP_APPLY_USER_LAYERS_UPDATE: this.onLayerUpdated,
        });
    }

    componentDidMount() {
        this.emit('GET_USER_DATA_LAYER_REQUEST', {
            userDataLayerId: this.props.userDataLayerId,
            mapInstanceId: this.props.mapInstanceId,
        });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    onLayerUpdated({ userDataLayer }) {
        this.setState({ userDataLayer });
    }

    onUserDataLayerReady({ userDataLayer, userDataLayerMetadata }) {
        this.setState({ userDataLayer, userDataLayerMetadata });
    }

    onFitClick = () => {
        // We will fit the bounds to the first point for now
        const coordinates = this.state.userDataLayer.geoJSON.features[0].geometry.coordinates;
        // Define the buffer (in degrees) to add around the point
        var buffer = 0.01;
        // Calculate bounds that include the small buffer around the point
        const bounds = [
            [coordinates[0] - buffer, coordinates[1] - buffer], // Southwest coordinates
            [coordinates[0] + buffer, coordinates[1] + buffer], // Northeast coordinates
        ];

        this.emit('MAP_FIT_BOUNDS_REQUEST', {
            bounds,
            offset: [0, 0],
            mapInstanceId: this.props.mapInstanceId,
        });
    };

    onDeleteClick = () => {
        this.emit('DELETE_USER_LAYER', {
            userDataLayerId: this.props.userDataLayerId,
            mapInstanceId: this.props.mapInstanceId,
        });
        this.props.onBack();
    };

    onSaveTitleClick = title => {
        this.emit('UPDATE_USER_LAYER_TITLE', {
            title,
            layerId: this.props.userDataLayerId,
            mapInstanceId: this.props.mapInstanceId,
        });
    };

    render() {
        if (!this.state.userDataLayer) {
            return (
                <div className="user-data-upload-editor">
                    <div className="user-data-upload-editor__loader">
                        <Loader text="Loading layer data" />
                    </div>
                </div>
            );
        }
        return (
            <div className="user-data-upload-editor">
                <LayerHeader
                    userDataLayerTitle={this.state.userDataLayer.title}
                    onFitClick={this.onFitClick}
                    onBack={this.props.onBack}
                    onSaveTitleClick={this.onSaveTitleClick}
                    onDeleteClick={this.onDeleteClick}
                />
                <PointLayerEditor
                    mapInstanceId={this.props.mapInstanceId}
                    userDataLayer={this.state.userDataLayer}
                    userDataLayerMetadata={this.state.userDataLayerMetadata}
                />
            </div>
        );
    }
}

export default UserDataLayerEditor;
