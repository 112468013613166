//!



const clamp = require('./util').clamp;
const jsExpressionEval = require('./js_expression_eval');
const resolveTokens = require('./token');

/**
 * Blends two colors into a third color compromised of blending destColor on top of srcColor.
 * Returns copy of destColor if destColor.opacity is 1, i.e. no blending.
 * @param srcColor
 * @param destColor
 * @returns {color []}
 */
exports.blendColors = function(srcColor, destColor) {
    if (srcColor instanceof Array && destColor instanceof Array) {
        if (destColor[3] === 1)
            return [
                destColor[0],
                destColor[1],
                destColor[2],
                destColor[3]
            ];
        return [
            clamp(destColor[0] + ((1 - destColor[3]) * srcColor[0]), 0, 1),
            clamp(destColor[1] + ((1 - destColor[3]) * srcColor[1]), 0, 1),
            clamp(destColor[2] + ((1 - destColor[3]) * srcColor[2]), 0, 1),
            clamp(destColor[3] + ((1 - destColor[3]) * srcColor[3]), 0, 1)
        ];
    } else {
        if (destColor.a === 1)
            return {
                r: destColor.r,
                g: destColor.g,
                b: destColor.b,
                a: destColor.a,
            };
        return {
            r: clamp(destColor.r + ((1 - destColor.a) * srcColor.r), 0, 1),
            g: clamp(destColor.g + ((1 - destColor.a) * srcColor.g), 0, 1),
            b: clamp(destColor.b + ((1 - destColor.a) * srcColor.b), 0, 1),
            a: clamp(destColor.a + ((1 - destColor.a) * srcColor.a), 0, 1)
        };
    }
};

exports.evaluateExpression = function(expression, properties) {
    if (expression !== undefined) {
        if (typeof expression === 'number') return expression;
        try {
            const resolved = resolveTokens(properties, expression);
            const value = jsExpressionEval.parse(resolved).evaluate();
            // if  the value is a number and it's not finite, return undefined
            if (typeof value === 'number' && !isFinite(value)) return undefined;
            // otherwise return the result value
            return value;
        } catch (err) {
            // For debugging purposes:
            // console.error({
            //    message: 'could not parse expression',
            //    expression: expression,
            //    evaluation: resolved,
            //    error: err
            // });
        }
    }
    return undefined;
};

// Injects functions once into global scope of main thread or web worker.
// Ref.: https://jsfiddle.net/h4Lhbyvt/
exports.injectCustomFunctions = function(self) {
    // Inject functions only once.
    if (self.injectedCustomFunctions) return;

    // Regular expressions cache, some browsers cache these literals, others don't, so we make sure it's cached.
    self.scientificNotationRegExp = /[eE]/;
    self.trailingDecimalsZeroesRegExp = /\.?0+$/;

    // Rounds the significant mantissa bits of a assumed 4-byte floating point and expands the scientific notation.
    // Outputs a string representation of number that can be matched against a regex.
    self.numberToComparableString = function(value) {
        // Value is not a number.
        if (typeof value !== 'number')
            return value;

        // Value is a whole number without the scientific notation.
        if (value % 1 === 0 && !String(value).match(self.scientificNotationRegExp))
            return String(value);

        // Round to the significant mantissa of 4-byte floating point.
        const rounded = Number(value).toExponential(6);

        // Split on scientific notation character "e" or "E".
        const data = String(rounded).split(self.scientificNotationRegExp);

        const absValue = value < 0 ? data[0].slice(1) : data[0];
        let dotIndex = absValue.indexOf('.');
        const exponent = Number(data[1]);
        let output = absValue.slice(0, dotIndex) + absValue.slice(dotIndex + 1);

        let zeroes = exponent;
        if (exponent > 0)
            while (zeroes-- > 0) output += '0';
        else
            while (zeroes++ < 0) output = `0${output}`;

        dotIndex += exponent;
        if (dotIndex > 0) {
            output = `${output.slice(0, dotIndex)}.${output.slice(dotIndex)}`;
        } else {
            output = `${output.slice(0, 1)}.${output.slice(1)}`;
        }

        // Remove trailing zeroes after the decimal point, inc. trailing dot.
        output = output.replace(self.trailingDecimalsZeroesRegExp, '');

        // Add negative sign if applicable.
        if (value < 0) output = `-${output}`;

        return output;
    };

    self.injectedCustomFunctions = true;
};
