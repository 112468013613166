// @ts-check
import React from 'react';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';

import BusComponent from '../BusComponent';
import TextInput from '../form/TextInput';

const INSIGHT_TITLE_MAX_LENGTH = 60;

/**
 * @typedef Props
 * @property {import('../../types').VisualReport} visualReport
 * @property {import('react-intl').intlShape} intl
 *
 * @typedef State
 * @property {string} title
 *
 * @extends {BusComponent<Props, State>}
 */
class VisualReportSavePopup extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            title: this.props.visualReport.title,
        };
    }

    onSave = () => {
        if (!this.state.title.trim()) return;
        this.emit('VISUAL_REPORT_SAVE_REQUEST', {
            visualReport: {
                ...this.props.visualReport,
                title: this.state.title,
            },
        });
        this.emit('CLOSE_MODAL');
    };

    onClickCancel = () => {
        this.emit('CLOSE_MODAL');
    };

    onTitleChanged = value => {
        this.setState({
            title: value,
        });
    };

    render() {
        const disabled = !this.state.title.trim();
        const classes = classNames('btn-raised', { disabled });
        return (
            <div className="save-as-project-content">
                <TextInput
                    initialFocus
                    className="new-project-title"
                    value={this.state.title}
                    placeholder={this.props.intl.formatMessage({
                        id: 'untitled',
                    })}
                    onChange={this.onTitleChanged}
                    onEnter={this.onSave}
                    label={this.props.intl.formatMessage({ id: 'title' })}
                    maxLength={INSIGHT_TITLE_MAX_LENGTH}
                />
                <div className="save-as-project-actions margin-top-10 flex-it center flex-end">
                    <button className="btn-flat" onClick={this.onClickCancel}>
                        {this.props.intl.formatMessage({ id: 'cancel' })}
                    </button>
                    <button
                        className={classes}
                        disabled={disabled}
                        onClick={this.onSave}
                    >
                        {this.props.intl.formatMessage({ id: 'save' })}
                    </button>
                </div>
            </div>
        );
    }
}

export default injectIntl(VisualReportSavePopup);
