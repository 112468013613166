import React from 'react';
import PropTypes from 'prop-types';

import MarkerEditorColorsAndIcons from './MarkerEditorColorsAndIcons';
import MarkerEditorIcons from './MarkerEditorIcons';

class MarkerEditor extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            moreIcons: false,
        };
    }

    onColorChange = color => {
        this.props.onChange({ markerPathId: this.props.markerPathId, markerColor: color });
    }

    onMarkerChange = marker => {
        this.props.onChange({ markerColor: this.props.markerColor, markerPathId: marker.id });
    }

    onMoreIconsClick = () => {
        this.setState({ moreIcons: true });
    }

    onExitMoreIconsClick = () => {
        this.setState({ moreIcons: false });
    }

    renderColorsAndIcons() {
        return (
            <MarkerEditorColorsAndIcons
                markerPathId={this.props.markerPathId}
                markerColor={this.props.markerColor}
                iconHasBackground={this.props.iconHasBackground}
                onColorChange={this.onColorChange}
                onMarkerChange={this.onMarkerChange}
                onMoreIconsClick={this.onMoreIconsClick}
            />
        );
    }

    renderIcons() {
        return (
            <MarkerEditorIcons
                markerPathId={this.props.markerPathId}
                markerColor={this.props.markerColor}
                iconHasBackground={this.props.iconHasBackground}
                onExitMoreIconsClick={this.onExitMoreIconsClick}
                onMarkerChange={this.onMarkerChange}
            />
        );
    }

    render() {
        const content = this.state.moreIcons ? this.renderIcons() : this.renderColorsAndIcons();
        return (
            <div className="marker-editor-compact">
                {content}
            </div>
        );
    }
}

MarkerEditor.propTypes = {
    markerPathId: PropTypes.string.isRequired,
    markerColor: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    iconHasBackground: PropTypes.bool,
};

MarkerEditor.defaultProps = {
    markerPathId: PropTypes.string.isRequired,
    markerColor: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    iconHasBackground: true,
};

export default MarkerEditor;
