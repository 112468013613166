module.exports={
  "hover": "Hover",
  "click": "Click",
  "tellAStory": "Tell a story",
  "changeMapView": "Change map view",
  "mapPositions": "Map positions",
  "yourLocation": "Your location",
  "zoom": "Zoom",
  "leftMap": "Left map",
  "rightMap": "Right map",
  "compare": "Compare",
  "sideBySide": "Side by side",
  "swipe": "Swipe",
  "chooseCompareView": "Choose compare view",
  "flipMaps": "Flip Maps",
  "linkMaps": "Link Maps",
  "saveCurrentMapPosition": "Save current map position",
  "locating": "Locating...",
  "hideYourPositionMarkerFromTheMap": "Hide your position marker from the map",
  "geolocationIsNotSupportedByThisBrowser": "Geolocation is not supported by this browser.",
  "latitude": "Latitude",
  "longitude": "Longitude",
  "zoomIn": "Zoom in",
  "zoomOut": "Zoom out",
  "centerMapOnLayer": "Center map on layer",
  "mapSettings": "Map settings",
  "singleMap": "Single map",
  "sideBySideMap": "Side by side map",
  "swipeMap": "Swipe map",
  "styleTheVisualization": "Style the visualization",
  "aspectRatio": "Aspect ratio",
  "mapSelection": "Show results on map:",
  "mapSelectionLeft": "Left",
  "mapSelectionRight": "Right"
}
