import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import IconMenu from '../iconMenu/IconMenu';
import Tooltip from '../Tooltip';

const AnnotationSelectionHeaderOptions = ({
        onAnnotationsDelete,
        onAnnotationsCopy,
        onAnnotationsPaste,
        onAnnotationsDuplicate,
        deleteDisabled,
        copyDisabled,
        pasteDisabled,
        duplicateDisabled,
        intl,
    }) => {
    const deleteButtonClasses = classNames('btn-icon btn-icon--light', {
        'btn-icon--disabled': deleteDisabled,
    });
    const copyButtonClasses = classNames('material-menu__item', {
        'material-menu__item--disabled': copyDisabled,
    });
    const pasteButtonClasses = classNames('material-menu__item', {
        'material-menu__item--disabled': pasteDisabled,
    });
    const duplicateButtonClasses = classNames('material-menu__item', {
        'material-menu__item--disabled': duplicateDisabled,
    });

    return (
        <div className="flex-it center">
            <Tooltip
                placement="bottom"
                mouseEnterDelay={0.5}
                mouseLeaveDelay={0}
                overlay={intl.formatMessage({ id: 'delete' })}
            >
                <button
                    className={deleteButtonClasses}
                    onClick={onAnnotationsDelete}
                    disabled={deleteDisabled}
                >
                    <i className="material-icons">delete</i>
                </button>
            </Tooltip>
            <IconMenu
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                targetOrigin={{ vertical: 'top', horizontal: 'right' }}
                light
                icon="more_vert"
                tabIndex="0"
                tooltip={intl.formatMessage({ id: 'more' })}
            >
                <div className="material-menu">
                    <div
                        className={duplicateButtonClasses}
                        onClick={onAnnotationsDuplicate}
                    >
                        <FormattedMessage id="dataBrowser.duplicate" />
                    </div>
                    <div
                        className={copyButtonClasses}
                        onClick={onAnnotationsCopy}
                    >
                        <FormattedMessage id="copyToClipboard" />
                    </div>
                    <div
                        className={pasteButtonClasses}
                        onClick={onAnnotationsPaste}
                    >
                        <FormattedMessage id="pasteFromClipboard" />
                    </div>
                </div>
            </IconMenu>
        </div>
    );
};

AnnotationSelectionHeaderOptions.propTypes = {
    deleteDisabled: PropTypes.bool,
    copyDisabled: PropTypes.bool,
    pasteDisabled: PropTypes.bool,
    duplicateDisabled: PropTypes.bool,
    onAnnotationsDelete: PropTypes.func.isRequired,
    onAnnotationsCopy: PropTypes.func.isRequired,
    onAnnotationsPaste: PropTypes.func.isRequired,
    onAnnotationsDuplicate: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
};

AnnotationSelectionHeaderOptions.defaultProps = {
    deleteDisabled: false,
    copyDisabled: false,
    pasteDisabled: false,
    duplicateDisabled: false,
};

export default injectIntl(AnnotationSelectionHeaderOptions);
