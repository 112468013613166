'use strict';var WhooTS = require('@mapbox/whoots-js');
var Coordinate = require('../geo/coordinate');
var TileCoord = function TileCoord(z, x, y, w) {
    if (w === undefined || isNaN(w)) {
        w = 0;
    }
    this.z = +z;
    this.x = +x;
    this.y = +y;
    this.w = +w;
    w *= 2;
    if (w < 0) {
        w = w * -1 - 1;
    }
    var dim = 1 << this.z;
    this.id = (dim * dim * w + dim * this.y + this.x) * 32 + this.z;
    this.posMatrix = null;
};
TileCoord.prototype.toString = function toString() {
    return this.z + '/' + this.x + '/' + this.y;
};
TileCoord.fromCoordinate = function fromCoordinate(zoom, column, row) {
    var tileScale = Math.pow(2, zoom);
    var w = Math.floor(column / tileScale);
    var y = row;
    var x = column - tileScale * w;
    return new TileCoord(zoom, x, y, w);
};
TileCoord.prototype.toCoordinate = function toCoordinate(sourceMaxZoom) {
    var zoom = Math.min(this.z, sourceMaxZoom === undefined ? this.z : sourceMaxZoom);
    var tileScale = Math.pow(2, zoom);
    var row = this.y;
    var column = this.x + tileScale * this.w;
    return new Coordinate(column, row, zoom);
};
TileCoord.prototype.url = function url(urls, sourceMaxZoom, scheme) {
    var bbox = WhooTS.getTileBBox(this.x, this.y, this.z);
    var quadkey = getQuadkey(this.z, this.x, this.y);
    return urls[(this.x + this.y) % urls.length].replace('{prefix}', (this.x % 16).toString(16) + (this.y % 16).toString(16)).replace('{z}', String(Math.min(this.z, sourceMaxZoom || this.z))).replace('{x}', String(this.x)).replace('{y}', String(scheme === 'tms' ? Math.pow(2, this.z) - this.y - 1 : this.y)).replace('{quadkey}', quadkey).replace('{bbox-epsg-3857}', bbox);
};
TileCoord.prototype.parent = function parent(sourceMaxZoom) {
    if (this.z === 0) {
        return null;
    }
    if (this.z > sourceMaxZoom) {
        return new TileCoord(this.z - 1, this.x, this.y, this.w);
    }
    return new TileCoord(this.z - 1, Math.floor(this.x / 2), Math.floor(this.y / 2), this.w);
};
TileCoord.prototype.wrapped = function wrapped() {
    return new TileCoord(this.z, this.x, this.y, 0);
};
TileCoord.prototype.isLessThan = function isLessThan(rhs) {
    if (this.w < rhs.w) {
        return true;
    }
    if (this.w > rhs.w) {
        return false;
    }
    if (this.z < rhs.z) {
        return true;
    }
    if (this.z > rhs.z) {
        return false;
    }
    if (this.x < rhs.x) {
        return true;
    }
    if (this.x > rhs.x) {
        return false;
    }
    if (this.y < rhs.y) {
        return true;
    }
    return false;
};
TileCoord.prototype.children = function children(sourceMaxZoom) {
    if (this.z >= sourceMaxZoom) {
        return [new TileCoord(this.z + 1, this.x, this.y, this.w)];
    }
    var z = this.z + 1;
    var x = this.x * 2;
    var y = this.y * 2;
    return [
        new TileCoord(z, x, y, this.w),
        new TileCoord(z, x + 1, y, this.w),
        new TileCoord(z, x, y + 1, this.w),
        new TileCoord(z, x + 1, y + 1, this.w)
    ];
};
TileCoord.prototype.scaledTo = function scaledTo(targetZ, sourceMaxZoom) {
    if (this.z > sourceMaxZoom) {
        return new TileCoord(targetZ, this.x, this.y, this.w);
    }
    if (targetZ <= this.z) {
        return new TileCoord(targetZ, this.x >> this.z - targetZ, this.y >> this.z - targetZ, this.w);
    } else {
        return new TileCoord(targetZ, this.x << targetZ - this.z, this.y << targetZ - this.z, this.w);
    }
};
TileCoord.prototype.isChildOf = function isChildOf(parent, sourceMaxZoom) {
    var parentZ = Math.min(sourceMaxZoom, parent.z);
    var childZ = Math.min(sourceMaxZoom, this.z);
    return parent.z === 0 || parent.z < this.z && parent.x === this.x >> childZ - parentZ && parent.y === this.y >> childZ - parentZ;
};
TileCoord.cover = function cover(z, bounds, actualZ, renderWorldCopies) {
    if (renderWorldCopies === undefined) {
        renderWorldCopies = true;
    }
    var tiles = 1 << z;
    var t = {};
    function scanLine(x0, x1, y) {
        var x, w, wx, coord;
        if (y >= 0 && y <= tiles) {
            for (x = x0; x < x1; x++) {
                w = Math.floor(x / tiles);
                wx = (x % tiles + tiles) % tiles;
                if (w === 0 || renderWorldCopies === true) {
                    coord = new TileCoord(actualZ, wx, y, w);
                    t[coord.id] = coord;
                }
            }
        }
    }
    scanTriangle(bounds[0], bounds[1], bounds[2], 0, tiles, scanLine);
    scanTriangle(bounds[2], bounds[3], bounds[0], 0, tiles, scanLine);
    return Object.keys(t).map(function (id) {
        return t[id];
    });
};
TileCoord.fromID = function fromID(id) {
    var z = id % 32, dim = 1 << z;
    var xy = (id - z) / 32;
    var x = xy % dim, y = (xy - x) / dim % dim;
    var w = Math.floor(xy / (dim * dim));
    if (w % 2 !== 0) {
        w = w * -1 - 1;
    }
    w /= 2;
    return new TileCoord(z, x, y, w);
};
function edge(a, b) {
    if (a.row > b.row) {
        var t = a;
        a = b;
        b = t;
    }
    return {
        x0: a.column,
        y0: a.row,
        x1: b.column,
        y1: b.row,
        dx: b.column - a.column,
        dy: b.row - a.row
    };
}
function scanSpans(e0, e1, ymin, ymax, scanLine) {
    var y0 = Math.max(ymin, Math.floor(e1.y0));
    var y1 = Math.min(ymax, Math.ceil(e1.y1));
    if (e0.x0 === e1.x0 && e0.y0 === e1.y0 ? e0.x0 + e1.dy / e0.dy * e0.dx < e1.x1 : e0.x1 - e1.dy / e0.dy * e0.dx < e1.x0) {
        var t = e0;
        e0 = e1;
        e1 = t;
    }
    var m0 = e0.dx / e0.dy;
    var m1 = e1.dx / e1.dy;
    var d0 = e0.dx > 0;
    var d1 = e1.dx < 0;
    for (var y = y0; y < y1; y++) {
        var x0 = m0 * Math.max(0, Math.min(e0.dy, y + d0 - e0.y0)) + e0.x0;
        var x1 = m1 * Math.max(0, Math.min(e1.dy, y + d1 - e1.y0)) + e1.x0;
        scanLine(Math.floor(x1), Math.ceil(x0), y);
    }
}
function scanTriangle(a, b, c, ymin, ymax, scanLine) {
    var ab = edge(a, b), bc = edge(b, c), ca = edge(c, a);
    var t;
    if (ab.dy > bc.dy) {
        t = ab;
        ab = bc;
        bc = t;
    }
    if (ab.dy > ca.dy) {
        t = ab;
        ab = ca;
        ca = t;
    }
    if (bc.dy > ca.dy) {
        t = bc;
        bc = ca;
        ca = t;
    }
    if (ab.dy) {
        scanSpans(ca, ab, ymin, ymax, scanLine);
    }
    if (bc.dy) {
        scanSpans(ca, bc, ymin, ymax, scanLine);
    }
}
function getQuadkey(z, x, y) {
    var quadkey = '', mask;
    for (var i = z; i > 0; i--) {
        mask = 1 << i - 1;
        quadkey += (x & mask ? 1 : 0) + (y & mask ? 2 : 0);
    }
    return quadkey;
}
module.exports = TileCoord;