// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import BusComponent from '../../BusComponent';
import VariableSelection from '../../../objects/VariableSelection';
import VariableSelectionItem from '../../../objects/VariableSelectionItem';
import Tag from '../../../enums/Tag';

import { variableSearchResultButtonEvents } from '../../../enums/GoogleTagManagerEvents';
import googleTagManagerEvents from '../../../helpers/GoogleTagManagerEventsWrapper';

const VariableWrapper = googleTagManagerEvents(
    'button',
    variableSearchResultButtonEvents,
);

/**
 * @typedef Props
 * @property {import('../../../').SearchResultTableVariable} variable
 * @property {import('../../../').TableComparableSurvey} selectedSurvey
 * @property {string} mapInstanceId
 * @property {import('../../../objects/VariableSelectionItem').default[]} currentVariableSelectionItems
 * @property {boolean} disabled
 * @property {string} query
 *
 * @extends {BusComponent<Props>}
 */
class Variable extends BusComponent {
    componentDidMount() {
        const gtmEventLabel = `${this.props.selectedSurvey.surveyName}|${this.props.variable.tableName}|${this.props.variable.name}`;
        this.variable.setCustomAttribute('gtm-eventa', this.props.query);
        this.variable.setCustomAttribute('gtm-eventl', gtmEventLabel);
    }

    onVariableClick = () => {
        const selectedSurvey = this.props.variable.comparableSurveys.find(
            s => s.surveyName === this.props.selectedSurvey.surveyName,
        );

        this.bus.once('DATASET_LOAD_SUCCESS', () => {
            this.bus.once('TABLE_LOAD_SUCCESS', tableLoadPayload => {
                const variableSelectionItem = new VariableSelectionItem({
                    surveyName: selectedSurvey.surveyName,
                    datasetAbbreviation: selectedSurvey.datasetAbbreviation,
                    variableGuid: selectedSurvey.variableGuid,
                    tableGuid: tableLoadPayload.table.uuid,
                });

                const variableSelection = new VariableSelection();
                variableSelection.items = [variableSelectionItem];

                this.emit('DATA_SELECTION_CHANGE', {
                    source: this,
                    mapInstanceId: this.props.mapInstanceId,
                    newDataSelection: { variableSelection },
                });

                this.emit('APPLY_VARIABLE_SUCCESS');
            });

            this.emit('TABLE_LOAD_REQUEST', {
                surveyName: selectedSurvey.surveyName,
                datasetName: selectedSurvey.datasetAbbreviation,
                tableName: this.props.variable.tableName,
                mapInstanceId: this.props.mapInstanceId,
                source: this,
            });
        });

        this.emit('APPLY_VARIABLE_REQUEST');

        this.emit('DATASET_LOAD_REQUEST', {
            surveyName: selectedSurvey.surveyName,
            datasetName: selectedSurvey.datasetAbbreviation,
            mapInstanceId: this.props.mapInstanceId,
            source: this,
        });

        this.emit('COUNTER_LOG_REQUEST', [
            {
                event_type: 'item_request',
                event_value: selectedSurvey.variableGuid,
            },
            {
                event_type: 'item_investigation',
                event_value: selectedSurvey.variableGuid,
            },
        ]);
    };

    render() {
        const selectedSurvey = this.props.variable.comparableSurveys.find(
            s => s.surveyName === this.props.selectedSurvey.surveyName,
        );
        return (
            <VariableWrapper
                ref={c => {
                    this.variable = c;
                }}
                onClick={this.onVariableClick}
                aria-label={this.props.variable.shortLabel}
                aria-disabled={this.props.disabled}
                disabled={this.props.disabled}
                className={classNames('search-variable', {
                    'search-variable--selected': this.props.currentVariableSelectionItems.some(
                        vsi => vsi.variableGuid === selectedSurvey.variableGuid,
                    ),
                })}
            >
                {this.props.variable.shortLabel.replace(
                    Tag.DOLLAR_YEAR,
                    selectedSurvey.surveyYear.toString(),
                )}
            </VariableWrapper>
        );
    }
}

Variable.propTypes = {
    variable: PropTypes.object.isRequired,
    selectedSurvey: PropTypes.object.isRequired,
    mapInstanceId: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    query: PropTypes.string.isRequired,
};

export default Variable;
