// @ts-check

/**
 * returns the indexedDB object store
 * @param {string} collection collection name
 */
const getObjectStore = collection =>
    new Promise((resolve, reject) => {
        if (!window.indexedDB) {
            reject('indexDB not supported');
            return;
        }
        // open the indexeddb collection with fixed version = 1
        // we don't need versioning right now, but we might introduce it later
        const request = indexedDB.open(collection, 1);
        request.onerror = error => {
            // should anything go wrong
            const req = indexedDB.deleteDatabase(collection);
            req.onsuccess = () => {};
            req.onerror = () => {};
            reject('Error opening', error);
        };
        request.onupgradeneeded = event => {
            /** @type {IDBOpenDBRequest} */
            const target = event.target;
            if (!event.target) {
                reject('There was no target db on upgrade');
                return;
            }

            const db = target.result;
            try {
                db.deleteObjectStore(collection);
            } catch (e) {
                // this should only happen if this is the first time database
                // is created. I couldn't find a method to check if objectStore
                // already exists without throwing exception
            }
            db.createObjectStore(collection, { keyPath: 'id' });
        };
        request.onsuccess = event => {
            /** @type {IDBOpenDBRequest} */
            const target = event.target;
            if (!target) {
                reject('There was no target db on success');
                return;
            }
            const db = target.result;
            resolve(
                db.transaction(collection, 'readwrite').objectStore(collection),
            );
        };
    });

/**
 * returns the payload saved for a given key in a given collection
 * @param {string} collection collection name
 * @param {string} key key name
 */
export const dbGet = async (collection, key) => {
    let objectStore;
    try {
        objectStore = await getObjectStore(collection);
    } catch (e) {
        return null;
    }
    const value = await new Promise(resolve => {
        const request = objectStore.get(key);
        request.onerror = () => resolve(null);
        request.onsuccess = event => {
            const target = event.target;
            if (!target) {
                resolve(null);
                return;
            }
            const result = target.result;
            if (!result) {
                resolve(null);
                return;
            }
            resolve(result.payload);
        };
    });
    return value;
};

/**
 * sets the payload for a given key in a given collection
 * @param {string} collection collection name
 * @param {string} key key name
 * @param {Blob | string} payload zip blob that needs to be saved
 */
export const dbSet = async (collection, key, payload) => {
    let objectStore;
    try {
        objectStore = await getObjectStore(collection);
    } catch (e) {
        console.error(`Failed to set ${key} in ${collection}: ${e}`);
        return;
    }
    await new Promise(resolve => {
        const request = objectStore.put({
            id: key,
            payload,
        });
        request.onsuccess = () => resolve();
        request.onerror = () => {
            console.warn(`Failed to set ${key} in ${collection}!`);
        };
    });
};
