import Project from '../objects/Project';
import InitialView from '../objects/InitialView';
import VariableSelectionItem from '../objects/VariableSelectionItem';
import VariableSelection from '../objects/VariableSelection';
import DataTheme from '../objects/DataTheme';
import MapInstance from '../objects/MapInstance';
import FrameType from '../enums/FrameType';
import Unit from '../enums/Unit';
import FilterComparisonType from '../enums/FilterComparisonType';
import FilterStatus from '../enums/FilterStatus';
import FrameLockMode from '../enums/FrameLockMode';
import AnnotationsMarkers from '../enums/AnnotationsMarkers';
import LocationAnalysisItemOrigin from '../enums/LocationAnalysisItemOrigin';
import VariableValueType from '../enums/VariableValueType';

import Frame from '../objects/Frame';
import Filter from '../objects/Filter';
import Brush from '../objects/Brush';
import Symbol from '../objects/Symbol';
import Curve from '../objects/annotations/Curve';
import Polygon from '../objects/annotations/Polygon';
import Shape from '../objects/annotations/Shape';
import Image from '../objects/annotations/Image';
import Polyline from '../objects/annotations/Polyline';
import Hotspot from '../objects/annotations/Hotspot';
import Marker from '../objects/annotations/Marker';
import Label from '../objects/annotations/Label';
import Freehand from '../objects/annotations/Freehand';
import FlowArrow from '../objects/annotations/FlowArrow';
import AnnotationLegend from '../objects/annotations/AnnotationLegend';
import LegendItem from '../objects/annotations/LegendItem';
import RendererVisibilityRule from '../objects/RendererVisibilityRule';
import FilterRule from '../objects/FilterRule';
import FieldList from '../objects/FieldList';
import FieldListField from '../objects/FieldListField';
import LabelRenderer from '../objects/LabelRenderer';
import ColorPalette from '../objects/ColorPalette';
import ColorRamp from '../objects/ColorRamp';
import ValueRenderer from '../objects/ValueRenderer';
import BubbleRenderer from '../objects/BubbleRenderer';
import MultiValueRenderer from '../objects/MultiValueRenderer';
import SymbolRenderer from '../objects/SymbolRenderer';
import DotDensityRenderer from '../objects/DotDensityRenderer';
import ValueDotDensityRenderer from '../objects/ValueDotDensityRenderer';

import LayerOverride from '../objects/LayerOverride';
import UserDataLayer from '../objects/UserDataLayer';
import LibraryGroup from '../objects/LibraryGroup';
import LibraryLayer from '../objects/LibraryLayer';
import PointLayerStyle from '../objects/PointLayerStyle';
import DataGeoFilter from '../objects/dataGeoFilter/DataGeoFilter';
import DataGeoFilterFeature from '../objects/dataGeoFilter/DataGeoFilterFeature';
import DataFilter from '../objects/dataFilter/DataFilter';
import DataFilterRule from '../objects/dataFilter/DataFilterRule';
import DataFilterField from '../objects/dataFilter/DataFilterField';
import DataFilterBaseVariable from '../objects/dataFilter/DataFilterBaseVariable';
import LocationAnalysisItem from '../objects/LocationAnalysisItem';
import {
    parseIntWithDefault,
    parseFloatWithDefault,
    parseColorWithDefault,
    invertColor,
    booleanFromString,
} from './Util';
import AppConfig from '../appConfig';
import SingleColorBubbleLayerStyle from '../objects/SingleColorBubbleLayerStyle';
import ShadedBubbleLayerStyle from '../objects/ShadedBubbleLayerStyle';

const SessionTimeoutError = 'Your session has timed out. Please sign in.';
const SessionTimeoutCode = '100';
const defaultAnnotationMarker = 'marker-default';

/** Helper class for SE Project XML parsing */
class ProjectXMLParser {
    /**
     * Constructs ProjectXMLParser for the given document
     * @constructor
     * @param {XMLDocument} projectXMLDocument
     */
    constructor(projectXMLDocument) {
        this.projectXMLDocument = projectXMLDocument;
    }

    static _parseInitialView(initialViewNode) {
        const initialView = new InitialView();
        initialView.zoom = parseIntWithDefault(initialViewNode.getAttribute('zoom'), 10, undefined);
        if (initialView.zoom !== undefined) initialView.zoom -= 1;
        initialView.centerLat = parseFloatWithDefault(initialViewNode.getAttribute('centerLat'), undefined);
        initialView.centerLng = parseFloatWithDefault(initialViewNode.getAttribute('centerLon'), undefined);
        initialView.boundingBoxNeLat = parseFloatWithDefault(initialViewNode.getAttribute('boundingBoxNeLat'), undefined);
        initialView.boundingBoxNeLng = parseFloatWithDefault(initialViewNode.getAttribute('boundingBoxNeLng'), undefined);
        initialView.boundingBoxSwLat = parseFloatWithDefault(initialViewNode.getAttribute('boundingBoxSwLat'), undefined);
        initialView.boundingBoxSwLng = parseFloatWithDefault(initialViewNode.getAttribute('boundingBoxSwLng'), undefined);
        return initialView;
    }

    static _parseVariableSelection(dataThemeNode) {
        // Handle case when variable selection is embedded in datatheme node
        const variableSelectionNode = dataThemeNode.getElementsByTagName('VariableSelection')[0];
        if (variableSelectionNode === null || variableSelectionNode === undefined) {
            const variableAttr = dataThemeNode.getAttribute('variableGuid');
            const tableAttr = dataThemeNode.getAttribute('tableGuid');
            const datasetAttr = dataThemeNode.getAttribute('datasetAbbrev');
            const surveyAttr = dataThemeNode.getAttribute('surveyName');
            const percentBaseOverrideAttr = dataThemeNode.getAttribute('percentBaseVariableGuidUserOverride');

            if (variableAttr !== null && tableAttr !== null && datasetAttr !== null && surveyAttr !== null) {
                const variableSelection = new VariableSelection();
                const variableSelectionItem = new VariableSelectionItem();
                variableSelectionItem.variableGuid = variableAttr;
                variableSelectionItem.tableGuid = tableAttr;
                variableSelectionItem.datasetAbbreviation = datasetAttr;
                variableSelectionItem.surveyName = surveyAttr;
                variableSelectionItem.percentBaseVariableGuidUserOverride = percentBaseOverrideAttr;
                variableSelection.items.push(variableSelectionItem);
                return variableSelection;
            }
            return undefined;
        }
        const variableSelection = new VariableSelection();
        variableSelection.items = [...variableSelectionNode.getElementsByTagName('Variable')].map(variableNode => {
            const variableSelectionItem = new VariableSelectionItem();
            variableSelectionItem.variableGuid = variableNode.getAttribute('variableGuid');
            variableSelectionItem.tableGuid = variableNode.getAttribute('tableGuid');
            variableSelectionItem.datasetAbbreviation = variableNode.getAttribute('datasetAbbrev');
            variableSelectionItem.surveyName = variableNode.getAttribute('surveyName');
            variableSelectionItem.percentBaseVariableGuidUserOverride = variableNode.getAttribute('percentBaseVariableGuidUserOverride');

            return variableSelectionItem;
        });

        return variableSelection;
    }

    static _parseBrush(brushNode) {
        return new Brush({
            fillColor: parseColorWithDefault(brushNode.getAttribute('fill')),
            fillOpacity: parseFloatWithDefault(brushNode.getAttribute('fillOpacity'), undefined),
            strokeStyle: brushNode.getAttribute('strokeStyle') || undefined,
            strokeWidth: Math.max(0, parseIntWithDefault(brushNode.getAttribute('strokeWidth'), 10, (brushNode.getAttribute('strokeStyle') === 'DoubleSolid' ? 2 : 1))),
            strokeColor: parseColorWithDefault(brushNode.getAttribute('stroke')),
            strokeOpacity: parseFloatWithDefault(brushNode.getAttribute('strokeOpacity'), 1),
            strokeLineCap: brushNode.getAttribute('strokeLineCap') || undefined,
            strokeFill: parseColorWithDefault(brushNode.getAttribute('strokeFill')),
            strokeFillOpacity: parseFloatWithDefault(brushNode.getAttribute('strokeFillOpacity'), 1),
            strokeFillWidth: Math.max(0, parseIntWithDefault(brushNode.getAttribute('strokeFillWidth'), 10, 1)),
            textSize: parseIntWithDefault(brushNode.getAttribute('size'), 10, undefined),
            textColor: parseColorWithDefault(brushNode.getAttribute('color')),
            textOpacity: parseFloatWithDefault(brushNode.getAttribute('opacity'), undefined),
            textBaselineShift: brushNode.getAttribute('baselineShift') || undefined,
            textPaddingLeft: brushNode.getAttribute('paddingLeft') || undefined,
            textPaddingRight: brushNode.getAttribute('paddingRight') || undefined,
            textLetterCasing: brushNode.getAttribute('letterCasing') || undefined,
            textLetterSpacing: parseIntWithDefault(Math.max(0, parseInt(brushNode.getAttribute('letterSpacing'), 10)), 10, undefined),
            textFont: brushNode.getAttribute('font') || undefined,
            textLabelPositions: brushNode.getAttribute('labelPositions') || undefined,
            textHaloColor: parseColorWithDefault(brushNode.getAttribute('glow')),
            textHaloBlur: parseFloatWithDefault(brushNode.getAttribute('glowStrength'), undefined),
            textHaloOpacity: parseFloatWithDefault(brushNode.getAttribute('glowAlpha'), undefined),
            marker: brushNode.getAttribute('marker') || undefined,
            minZoom: parseIntWithDefault(brushNode.getAttribute('zoomMin'), 10, 0),
            maxZoom: parseIntWithDefault(brushNode.getAttribute('zoomMax'), 10, 20) + 1,
        });
    }

    static _parseSymbols(rendererNode) {
        // WARNING: this implementation is not stable! TODO: rewrite this to iterate through children in appearing order
        return [...rendererNode.getElementsByTagName('LineSymbol')]
            .concat([...rendererNode.getElementsByTagName('PolygonSymbol')])
            .concat([...rendererNode.getElementsByTagName('BubbleSymbol')])
            .concat([...rendererNode.getElementsByTagName('LabelSymbol')])
            .concat([...rendererNode.getElementsByTagName('DotDensitySymbol')])
            .reduce((parsedSymbols, symbolNode) => {
                const symbol = new Symbol();
                symbol.type = symbolNode.nodeName;
                symbol.brushes = [...symbolNode.getElementsByTagName('Brush')].map(brushNode => ProjectXMLParser._parseBrush(brushNode));
                parsedSymbols.push(symbol);

                return parsedSymbols;
            }, []);
    }

    static _parseValueRendererFieldListFields(fieldListNode) {
        return [...fieldListNode.getElementsByTagName('Field')].reduce((parsedFields, fieldNode) => {
            const field = new FieldListField();
            field.fieldName = fieldNode.getAttribute('fieldName') || undefined;
            field.hideFromUser = fieldNode.getAttribute('hideFromUser') === 'true';
            field.universe = fieldNode.getAttribute('universe') === 'true';
            field.isGeoNameField = fieldNode.getAttribute('isGeoNameField') === 'true';
            field.isChangeOverTimeField = fieldNode.getAttribute('isChangeOverTimeField') === 'true';
            field.pointOfReferenceFieldQName = fieldNode.getAttribute('pointOfReferenceFieldQName') || undefined;
            field.label = fieldNode.getAttribute('label') || '';
            field.formatting = fieldNode.getAttribute('formatting') || undefined;
            field.surveyName = fieldNode.getAttribute('surveyName') || undefined;
            field.tableUuid = fieldNode.getAttribute('tableUuid') || undefined;
            field.datasetAbbreviation = fieldNode.getAttribute('datasetName') || undefined;
            field.isComputed = fieldNode.getAttribute('isComputedField') === 'true';
            field.computeFunction = fieldNode.getAttribute('computeFunctionName') || undefined;
            field.fieldNumerator = fieldNode.getAttribute('fieldNumerator') || undefined;
            field.fieldDenominator = fieldNode.getAttribute('fieldDenominator') || undefined;
            field.fieldMultiplier = parseFloatWithDefault(fieldNode.getAttribute('fieldMultiplier')) || undefined;
            field.fieldNumeratorParent = fieldNode.getAttribute('fieldNumeratorParent') || undefined;
            field.fieldDenominatorParent = fieldNode.getAttribute('fieldDenominatorParent') || undefined;

            // backward compatibility: if pointOfReference is missing for COT field, use fieldNumerator
            // fieldNumerator is the later year, which is a sane default because it is the "later" year between the two
            // which is behavior for all COT that does not include future estimate data
            if (field.isChangeOverTimeField && field.pointOfReferenceFieldQName === undefined) {
                field.pointOfReferenceFieldQName = field.fieldNumerator;
            }

            parsedFields.push(field);

            return parsedFields;
        }, []);
    }

    static _parseValueRendererFieldList(fieldListNode) {
        const fieldList = new FieldList();

        if (fieldListNode) {
            fieldList.id = fieldListNode.getAttribute('id') || undefined;
            fieldList.fields = ProjectXMLParser._parseValueRendererFieldListFields(fieldListNode);
        } else {
            fieldList.id = '';
            fieldList.fields = [];
        }

        return fieldList;
    }

    static _parseRuleFilter(filterNode) {
        let value = filterNode.getAttribute('value') || undefined;

        const filter = new Filter();
        filter.label = filterNode.getAttribute('label') || undefined;
        filter.labelFrom = filterNode.getAttribute('labelFrom') || undefined;
        filter.labelTo = filterNode.getAttribute('labelTo') || undefined;
        filter.valueFormat = filterNode.getAttribute('valueFormat') || undefined;
        filter.fieldName = filterNode.getAttribute('fieldName') || undefined;

        filter.from = parseFloatWithDefault(filterNode.getAttribute('from'), undefined, true);
        filter.to = parseFloatWithDefault(filterNode.getAttribute('to'), undefined, true);
        filter.inclusiveTo = filterNode.getAttribute('inclusiveTo') === 'true';

        if ((filter.to !== undefined && !isNaN(filter.to)) || (filter.from !== undefined && isNaN(filter.from))) {
            filter.comparisonType = FilterComparisonType.MATCH_RANGE;
        } else if (parseFloatWithDefault(value, undefined) !== undefined) {
            filter.valueNum = parseFloatWithDefault(value, undefined);
            filter.comparisonType = FilterComparisonType.MATCH_VALUE_NUM;
        } else if (value === '[NULL]') {
            filter.comparisonType = FilterComparisonType.MATCH_NULL;
        } else if (value !== undefined && value !== '') {
            if (value.length >= 2 && value[0] === '\'' && value[value.length - 1] === '\'') {
                value = value.substring(1, value.length - 1);
            }
            filter.valueStr = value;
            filter.comparisonType = FilterComparisonType.MATCH_VALUE_STR;
        } else {
            filter.comparisonType = FilterComparisonType.MATCH_DEFAULT_SYMBOL;
        }

        if (value !== undefined) {
            if (value.indexOf('\'') === 0 && value[value.length - 1] === '\'') {
                value = value.slice(1, value.length - 1);
                filter.valueStr = value;
            } else {
                value = parseFloat(value);
                filter.valueNum = value;
            }
        }

        filter.value = value;

        return filter;
    }

    static _parseRendererFilterRules(rendererNode) {
        return [...rendererNode.getElementsByTagName('Rule')].reduce((parsedRules, ruleNode) => {
            if (ruleNode.parentNode.nodeName === 'Visibility') {
                return parsedRules;
            }

            const filterRule = new FilterRule();
            filterRule.title = ruleNode.getAttribute('title') || undefined;
            filterRule.zoomMin = parseIntWithDefault(ruleNode.getAttribute('zoomMin'), 10, undefined);
            filterRule.zoomMax = parseIntWithDefault(parseInt(ruleNode.getAttribute('zoomMax'), 10) + 1, 10, undefined);
            filterRule.filter = ProjectXMLParser._parseRuleFilter(ruleNode.getElementsByTagName('Filter')[0]);
            filterRule.symbols = ProjectXMLParser._parseSymbols(ruleNode);

            parsedRules.push(filterRule);

            return parsedRules;
        }, []);
    }

    static _parseRendererVisibility(visibilityNode) {
        return [...visibilityNode.getElementsByTagName('Rule')].reduce((parsedRules, ruleNode) => {
            const rendererVisibilityRule = new RendererVisibilityRule();
            const ifDataLayerVisible = ruleNode.getAttribute('ifDataLayerVisible') || undefined;
            rendererVisibilityRule.ifRendererTypeOnAnyDataLayer = ruleNode.getAttribute('ifRendererTypeOnAnyDataLayer') || undefined;
            rendererVisibilityRule.ifLayerIdVisible = ruleNode.getAttribute('ifLayerIdVisible') || undefined;
            rendererVisibilityRule.ifRendererTypeExists = ruleNode.getAttribute('ifRendererTypeExists') || undefined;
            rendererVisibilityRule.ifDataLayerVisible = (ifDataLayerVisible ? ifDataLayerVisible === 'true' : undefined);
            rendererVisibilityRule.ifLayerIdNotVisible = ruleNode.getAttribute('ifLayerIdNotVisible') || undefined;

            parsedRules.push(rendererVisibilityRule);

            return parsedRules;
        }, []);
    }

    static _fixRendererNullDataRule(renderer) {
        // if null data rule exists all is well
        if (renderer.nullDataRuleIndex !== -1) return;

        // create null data rule and add it to renderer
        const rule = renderer.rules.find((_, idx) => idx !== renderer.insufficientDataRuleIndex);
        if (!rule) return;
        const nullDataFilter = new Filter();
        nullDataFilter.comparisonType = FilterComparisonType.MATCH_NULL;
        nullDataFilter.fieldName = rule.fieldQualifiedName;

        const nullBrush = new Brush({
            fillColor: 0,
            fillOpacity: 1,
            minZoom: 0,
            maxZoom: 20,
        });

        const nullPolygonSymbol = new Symbol();
        nullPolygonSymbol.type = 'PolygonSymbol';
        nullPolygonSymbol.brushes = [nullBrush];

        const nullDataRule = new FilterRule();
        nullDataRule.fieldQualifiedName = rule.fieldQualifiedName;
        nullDataRule.filter = nullDataFilter;
        nullDataRule.displayInLegend = false;
        nullDataRule.symbols = [nullPolygonSymbol];
        nullDataRule.zoomMin = 0;
        nullDataRule.zoomMax = 20;
        nullDataRule.title = 'No data';
        renderer.rules.unshift(nullDataRule);
        renderer.nullDataRuleIndex = 0;
        if (renderer.insufficientDataRuleIndex !== -1) {
            renderer.insufficientDataRuleIndex += 1;
        }
    }

    static _parseRendering(renderingNode, variableSelection, bubbleValueType) {
        if (!renderingNode) {
            return [];
        }

        return [...renderingNode.childNodes].reduce((nodes, node) => {
            if (node.nodeType === 1) {
                const visibilityNodes = [...node.getElementsByTagName('Visibility')];
                let renderer;
                switch (node.nodeName) {
                case 'LabelRenderer':
                    renderer = new LabelRenderer();
                    renderer.fieldNames = node.getAttribute('fieldNames') || undefined;
                    renderer.priorityFieldName = node.getAttribute('priorityFieldName') || undefined;
                    renderer.labelFieldName = node.getAttribute('labelFieldName') || undefined;
                    renderer.symbols = ProjectXMLParser._parseSymbols(node);
                    renderer.rules = ProjectXMLParser._parseRendererFilterRules(node);
                    renderer.visibility = (visibilityNodes.length > 0 ? ProjectXMLParser._parseRendererVisibility(visibilityNodes[0]) : []);
                    nodes.push(renderer);
                    break;
                case 'SymbolRenderer':
                    renderer = new SymbolRenderer();
                    renderer.symbols = ProjectXMLParser._parseSymbols(node);
                    renderer.visibility = (visibilityNodes.length > 0 ? ProjectXMLParser._parseRendererVisibility(visibilityNodes[0]) : []);
                    nodes.push(renderer);
                    break;
                case 'ValueRenderer':
                    renderer = new ValueRenderer();
                    renderer.rules = ProjectXMLParser._parseRendererFilterRules(node);
                    renderer.fieldList = ProjectXMLParser._parseValueRendererFieldList(node.getElementsByTagName('FieldList')[0]);
                    if (renderer.rules && variableSelection) {
                        renderer.rules.forEach(rule => (rule.fieldQualifiedName = renderer.fieldList.fields.find(field => field.fieldName === variableSelection.items[0].variableGuid).qualifiedName));
                    }
                    renderer.insufficientDataRuleIndex = parseIntWithDefault(node.getAttribute('insufDataSymbol'), 10, -1);
                    renderer.nullDataRuleIndex = parseIntWithDefault(node.getAttribute('nullDataSymbol'), 10, -1);
                    renderer.visibility = (visibilityNodes.length > 0 ? ProjectXMLParser._parseRendererVisibility(visibilityNodes[0]) : []);
                    ProjectXMLParser._fixRendererNullDataRule(renderer);
                    nodes.push(renderer);
                    break;
                case 'BubbleRenderer':
                    renderer = new BubbleRenderer();
                    renderer.rules = ProjectXMLParser._parseRendererFilterRules(node);
                    renderer.fieldList = ProjectXMLParser._parseValueRendererFieldList(node.getElementsByTagName('FieldList')[0]);
                    if (renderer.rules && variableSelection) {
                        renderer.rules.forEach(rule => (rule.fieldQualifiedName = renderer.fieldList.fields.find(field => field.fieldName === variableSelection.items[0].variableGuid).qualifiedName));
                        if (bubbleValueType === VariableValueType.NUMBER && renderer.rules.length === 1) {
                            // support for existing maps with negative bubble counts where these bubbles do not appear on map
                            // alter renderer rules to include Filter for negative counts
                            const originalRuleFieldName = renderer.rules[0].fieldQualifiedName;
                            const originalRuleBrush = renderer.rules[0].symbols[0].brushes[0].clone();

                            const bubbleNegativeSymbol = new Symbol();
                            bubbleNegativeSymbol.type = 'BubbleSymbol';
                            bubbleNegativeSymbol.brushes = [originalRuleBrush];
                            bubbleNegativeSymbol.brushes[0].fillColor = invertColor(bubbleNegativeSymbol.brushes[0].fillColor);
                            const defaultNegativeBubbleFilter = new Filter();
                            defaultNegativeBubbleFilter.fieldName = originalRuleFieldName;
                            defaultNegativeBubbleFilter.to = 0;
                            const defaultNegativeBubbleRule = new FilterRule();
                            defaultNegativeBubbleRule.fieldQualifiedName = originalRuleFieldName;
                            defaultNegativeBubbleRule.filter = defaultNegativeBubbleFilter;
                            defaultNegativeBubbleRule.symbols = [bubbleNegativeSymbol];
                            // insert negative count rule
                            renderer.rules.unshift(defaultNegativeBubbleRule);

                            // alter positive count rule ba deleting its comparisonType and setting `to` as Infinity
                            renderer.rules[1].filter.comparisonType = undefined;
                            renderer.rules[1].filter.to = Number.MAX_SAFE_INTEGER;
                        }
                    }
                    renderer.visibility = (visibilityNodes.length > 0 ? ProjectXMLParser._parseRendererVisibility(visibilityNodes[0]) : []);

                    renderer.bubbleSizeFactor = parseFloatWithDefault(node.getAttribute('bubbleSizeFactor'), 0.001);
                    renderer.maxBubbleSize = parseFloatWithDefault(node.getAttribute('maxBubbleSize'), 255);
                    renderer.insufficientDataRuleIndex = parseIntWithDefault(node.getAttribute('insuffDataRuleIndex'), 10, -1);
                    renderer.nullDataRuleIndex = parseIntWithDefault(node.getAttribute('nullDataRuleIndex'), 10, -1);
                    renderer.sizeTitle = node.getAttribute('title') || undefined;
                    renderer.colorTitle = node.getAttribute('title2') || undefined;
                    renderer.bubbleSizeFieldName = node.getAttribute('fieldBubbleSize') || undefined;

                    nodes.push(renderer);
                    break;
                case 'MultiValueRenderer':
                    renderer = new MultiValueRenderer();
                    renderer.fieldList = ProjectXMLParser._parseValueRendererFieldList(node.getElementsByTagName('FieldList')[0]);
                    renderer.visibility = (visibilityNodes.length > 0 ? ProjectXMLParser._parseRendererVisibility(visibilityNodes[0]) : []);
                    renderer.rules = [];
                    renderer.insufficientDataRuleIndex = [];
                    renderer.nullDataRuleIndex = [];
                    renderer.valuesFieldsNames = [];
                    [...node.getElementsByTagName('ValueField')].forEach((valueFieldNode, idx) => {
                        renderer.valuesFieldsNames.push(valueFieldNode.getAttribute('name') || undefined);
                        const valueFieldRules = ProjectXMLParser._parseRendererFilterRules(valueFieldNode);
                        const insufficientDataRuleIndex = parseIntWithDefault(valueFieldNode.getAttribute('insufDataSymbol'), 10, -1);
                        const nullDataRuleIndex = parseIntWithDefault(valueFieldNode.getAttribute('nullDataSymbol'), 10, -1);
                        if (valueFieldRules && variableSelection) {
                            valueFieldRules.forEach(rule => (rule.fieldQualifiedName = renderer.fieldList.fields.find(field => field.fieldName === variableSelection.items[idx].variableGuid).qualifiedName));
                        }
                        renderer.rules.push(valueFieldRules);
                        renderer.insufficientDataRuleIndex.push(insufficientDataRuleIndex);
                        renderer.nullDataRuleIndex.push(nullDataRuleIndex);
                    });
                    nodes.push(renderer);
                    break;
                case 'DotDensityRenderer':
                    renderer = new DotDensityRenderer();
                    renderer.fieldList = ProjectXMLParser._parseValueRendererFieldList(node.getElementsByTagName('FieldList')[0]);
                    renderer.visibility = (visibilityNodes.length > 0 ? ProjectXMLParser._parseRendererVisibility(visibilityNodes[0]) : []);
                    renderer.symbols = ProjectXMLParser._parseSymbols(node);
                    renderer.dotValue = parseIntWithDefault(node.getAttribute('dotValue'), 10, -1);
                    renderer.dotValueFieldName = node.getAttribute('dotValueFieldName') || undefined;
                    nodes.push(renderer);
                    break;
                case 'ValueDotDensityRenderer':
                    renderer = new ValueDotDensityRenderer();
                    renderer.fieldList = ProjectXMLParser._parseValueRendererFieldList(node.getElementsByTagName('FieldList')[0]);
                    renderer.visibility = (visibilityNodes.length > 0 ? ProjectXMLParser._parseRendererVisibility(visibilityNodes[0]) : []);
                    renderer.symbols = ProjectXMLParser._parseSymbols(node);
                    renderer.dotValue = parseIntWithDefault(node.getAttribute('dotValue'), 10, -1);
                    renderer.dotValueFieldNames = [...node.getElementsByTagName('DotValueField')].map(dotValueFieldNode => dotValueFieldNode.getAttribute('name') || undefined);
                    nodes.push(renderer);
                    break;
                }
            }

            return nodes;
        }, []);
    }

    /**
     * Already saved projects might have the value for bubbleValueType saved as mix of
     * number|Number|percent|Percent
     * We need to use the provided enum VariableValueType
     * @param {string|undefined} value
     * @returns {string|undefined}
     * @private
     */
    static _parseVariableValueType(value) {
        if (value && value.toUpperCase() === VariableValueType.PERCENT.toUpperCase()) {
            return VariableValueType.PERCENT;
        } else if (value && value.toUpperCase() === VariableValueType.NUMBER.toUpperCase()) {
            return VariableValueType.NUMBER;
        }
        return undefined;
    }

    static _parseDataTheme(dataThemeNode) {
        const variableSelection = ProjectXMLParser._parseVariableSelection(dataThemeNode);
        /** @type {string} example 'Number' */
        const bubbleValueType = ProjectXMLParser._parseVariableValueType(dataThemeNode.getAttribute('bubbleValueType'));
        /** @type {string} example 'Number' */
        const shadedValueType = ProjectXMLParser._parseVariableValueType(dataThemeNode.getAttribute('shadedValueType'));
        /** @type {string} */
        const adjustmentDollarYear = dataThemeNode.getAttribute('adjustmentDollarYear');

        return new DataTheme({
            bubbleValueType,
            shadedValueType,
            id: dataThemeNode.getAttribute('id'),
            title: dataThemeNode.getAttribute('title'),
            adjustmentDollarYear: adjustmentDollarYear !== 'undefined' ? adjustmentDollarYear : undefined,
            colorPaletteId: dataThemeNode.getAttribute('colorPaletteId'),
            colorPaletteType: dataThemeNode.getAttribute('colorPaletteType'),
            dotDensityValueHint: parseIntWithDefault(dataThemeNode.getAttribute('dotDensityValueHint'), 10, 0),
            colorPaletteFlipped: (dataThemeNode.getAttribute('colorPaletteFlipped') || 'false').toLowerCase() === 'true',
            variableSelection,
            insufficientBase: dataThemeNode.getAttribute('insufficientBase') || undefined,
            rendering: ProjectXMLParser._parseRendering(dataThemeNode.getElementsByTagName('Rendering')[0], variableSelection, bubbleValueType),
        });
    }

    static _parseLayerOverrides(layerOverridesNode) {
        if (!layerOverridesNode) return {};
        const layerOverrides = {};
        [...layerOverridesNode.getElementsByTagName('LayerOverride')].forEach(layerOverrideNode => {
            const layerOverride = new LayerOverride();
            layerOverride.id = layerOverrideNode.getAttribute('id') || undefined;

            if (layerOverride.id === undefined) {
                throw new Error('Layer override id is undefined');
            }

            layerOverride.visibility = layerOverrideNode.getAttribute('visibility') || undefined;
            layerOverrides[layerOverride.id] = layerOverride;
        });
        return layerOverrides;
    }

    static _parseDataGeoFilters(dataGeoFiltersNode) {
        if (!dataGeoFiltersNode) return {};
        const dataGeoFilters = {};
        [...dataGeoFiltersNode.getElementsByTagName('DataGeoFilter')].forEach(dataGeoFilterNode => {
            const featuresNode = dataGeoFilterNode.getElementsByTagName('Features')[0];
            const features = [...featuresNode.getElementsByTagName('Feature')].map(featureNode => new DataGeoFilterFeature({
                columnValue: featureNode.getAttribute('columnValue'),
                geoQName: featureNode.getAttribute('geoQName'),
            }));
            const dataGeoFilter = new DataGeoFilter({
                summaryLevelId: dataGeoFilterNode.getAttribute('summaryLevelId'),
                dataLayerId: dataGeoFilterNode.getAttribute('dataLayerId'),
                nestedSummaryLevelsIds: dataGeoFilterNode.getAttribute('nestedSummaryLevelsIds') ? dataGeoFilterNode.getAttribute('nestedSummaryLevelsIds').split(',') : [],
                features,
            });
            dataGeoFilters[dataGeoFilter.summaryLevelId] = dataGeoFilter;
        });
        return dataGeoFilters;
    }

    static _parseDataFilters(dataFiltersNode) {
        if (!dataFiltersNode) return new DataFilter();
        let dataFilter = new DataFilter();
        // go through all the filters and construct filter objects
        [...dataFiltersNode.getElementsByTagName('DataFilter')].forEach(dataFilterNode => {
            // FilterRule Node
            const filtersNode = dataFilterNode.getElementsByTagName('Filters')[0];

            const filtersObject = {};
            const filters = [...filtersNode.getElementsByTagName('FilterRule')];
            filters.forEach(filterRuleNode => {
                // get the metadata
                const metadataNode = filterRuleNode.getElementsByTagName('Metadata')[0];
                let optionsList = [];
                const optionsListNode = metadataNode.getElementsByTagName('OptionsList')[0];
                if (optionsListNode) {
                    const options = optionsListNode.getElementsByTagName('Option');
                    optionsList = [...options].map(o => o.getAttribute('value'));
                }
                const field = new DataFilterField({
                    categoryName: metadataNode.getAttribute('categoryName'),
                    tableName: metadataNode.getAttribute('tableName'),
                    surveyName: metadataNode.getAttribute('surveyName'),
                    datasetAbbreviation: metadataNode.getAttribute('datasetAbbreviation'),
                    datasetId: metadataNode.getAttribute('datasetId'),
                    tableGuid: metadataNode.getAttribute('tableGuid'),
                    fieldName: metadataNode.getAttribute('fieldName'),
                    qLabel: metadataNode.getAttribute('qLabel'),
                    baseFieldName: metadataNode.getAttribute('baseFieldName'),
                    fieldDataType: metadataNode.getAttribute('fieldDataType'),
                    variableCode: metadataNode.getAttribute('variableCode'),
                    formatting: metadataNode.getAttribute('formatting'),
                    optionsList,
                });

                // get the baseVariables
                const baseVariablesNode = filterRuleNode.getElementsByTagName('BaseVariables')[0];
                let baseVariables = [];
                if (baseVariablesNode) {
                    // get the BaseVariable nodes
                    const baseVariableNodes = baseVariablesNode.getElementsByTagName('BaseVariable');
                    baseVariables = [...baseVariableNodes].map(baseVariableNode => new DataFilterBaseVariable({
                        uuid: baseVariableNode.getAttribute('uuid'),
                        label: baseVariableNode.getAttribute('label'),
                        qLabel: baseVariableNode.getAttribute('qLabel'),
                    }));
                }

                const filter = new DataFilterRule({
                    filterId: filterRuleNode.getAttribute('filterId'),
                    value: filterRuleNode.getAttribute('value'),
                    type: filterRuleNode.getAttribute('type'),
                    field,
                    status: parseIntWithDefault(filterRuleNode.getAttribute('status'), 10, FilterStatus.INVALID),
                    baseVariables,
                });

                filtersObject[filter.filterId] = filter;
            });
            // DataFilter
            dataFilter = new DataFilter({
                filterCombiner: dataFilterNode.getAttribute('filterCombiner'),
                filters: filtersObject,
            });
        });
        return dataFilter;
    }

    static _parseLocationAnalysis(locationAnalysisNode) {
        if (!locationAnalysisNode) return undefined;

        const selection = locationAnalysisNode.getAttribute('selection')
                                              .split(',').map(el => parseFloatWithDefault(el, 0));
        const selectionSet = new Set(selection);
        selectionSet.delete(0); // Delete if any 0 value was present in the selection

        return new LocationAnalysisItem({
            id: locationAnalysisNode.getAttribute('id'),
            type: locationAnalysisNode.getAttribute('type'),
            value: locationAnalysisNode.getAttribute('value'),
            point: {
                lng: locationAnalysisNode.getAttribute('lng'),
                lat: locationAnalysisNode.getAttribute('lat'),
            },
            itemOrigin: LocationAnalysisItemOrigin.PROJECT_DEFINITION_LOCATION,
            analysisTypeId: locationAnalysisNode.getAttribute('analysisTypeId'),
            selection: selectionSet,
        });
    }

    _parseMapInstance(mapInstanceNode) {
        this._annotations = this._parseAnnotations(mapInstanceNode.getElementsByTagName('Annotations')[0]);
        const mapInstance = new MapInstance({
            metadataGroupId: mapInstanceNode.getAttribute('baseMapUid'),
            userEnteredTitle: mapInstanceNode.getAttribute('userEnteredTitle'),
            initialView: ProjectXMLParser._parseInitialView(mapInstanceNode.getElementsByTagName('InitialView')[0]),
            scaleUnit: Unit.MILES,
            dataTheme: ProjectXMLParser._parseDataTheme(mapInstanceNode.getElementsByTagName('DataTheme')[0]),
            isSatelliteVisible: booleanFromString(mapInstanceNode.getAttribute('satelliteVisible')),
            satelliteDataOverlaySatelliteHasColor: booleanFromString(mapInstanceNode.getAttribute('satelliteDataOverlaySatelliteHasColor')),
            satelliteDataOverlayDataOpacity: parseFloatWithDefault(mapInstanceNode.getAttribute('satelliteDataOverlayDataOpacity'), undefined),
            annotations: this._annotations,
            annotationLegend: this._parseAnnotationLegend(mapInstanceNode.getElementsByTagName('AnnotationLegend')[0]),
            preferredDataLayerId: mapInstanceNode.getAttribute('preferredDataLayerId'),
            layerOverrides: ProjectXMLParser._parseLayerOverrides(mapInstanceNode.getElementsByTagName('LayerOverrides')[0]),
            shouldShowGeoSelectorInViewMode: mapInstanceNode.getAttribute('shouldShowGeoSelectorInViewMode') !== 'false',
            shouldShowSearchInViewMode: mapInstanceNode.getAttribute('shouldShowSearchInViewMode') !== 'false',
            shouldShowVisualizationTypeSwitcherInViewMode: mapInstanceNode.getAttribute('shouldShowVisualizationTypeSwitcherInViewMode') !== 'false',
            minZoomLevelRestriction: parseFloatWithDefault(mapInstanceNode.getAttribute('minZoomLevelRestriction'), undefined),
            maxZoomLevelRestriction: parseFloatWithDefault(mapInstanceNode.getAttribute('maxZoomLevelRestriction'), undefined),
            dataGeoFilters: ProjectXMLParser._parseDataGeoFilters(mapInstanceNode.getElementsByTagName('DataGeoFilters')[0]),
            dataFilter: ProjectXMLParser._parseDataFilters(mapInstanceNode.getElementsByTagName('DataFilters')[0]),
            userDataLayers: ProjectXMLParser._parseUserDataLayers(mapInstanceNode.getElementsByTagName('UserDataLayers')[0]),
            libraryDataLayers: ProjectXMLParser._parseLibraryDataLayers(mapInstanceNode.getElementsByTagName('LibraryData')[0]),
            locationAnalysisItem: ProjectXMLParser._parseLocationAnalysis(mapInstanceNode.getElementsByTagName('LocationAnalysisItem')[0]),
        });
        let currentMapId = mapInstanceNode.getAttribute('currentMapContextId') || undefined;
        if (currentMapId === undefined || currentMapId === '') {
            currentMapId = mapInstance.dataTheme.variableSelection.items[0].surveyName;
        }
        mapInstance.currentMapId = currentMapId;
        delete this._annotations;

        return mapInstance;
    }

    static _parseUserDataLayers(userDataLayersNode) {
        if (!userDataLayersNode) return [];
        return [...userDataLayersNode.getElementsByTagName('UserDataLayer')].map(userDataLayer => ProjectXMLParser._parseUserDataLayer(userDataLayer));
    }

    static _parseUserDataLayer(userDataLayerNode) {
        return new UserDataLayer({
            id: userDataLayerNode.getAttribute('id'),
            title: userDataLayerNode.getAttribute('title'),
            includeInLegend: userDataLayerNode.getAttribute('includeInLegend') === 'true',
            allowOverlap: userDataLayerNode.getAttribute('allowOverlap') === 'true',
            visible: userDataLayerNode.getAttribute('visible') === 'true',
            interactive: userDataLayerNode.getAttribute('interactive') === 'true',
            valueColumn: userDataLayerNode.getAttribute('valueColumn'),
            labelingColumn: userDataLayerNode.getAttribute('labelingColumn'),
            popupTitleColumn: userDataLayerNode.getAttribute('popupTitleColumn'),
            popupOtherColumns: ProjectXMLParser._parseUserDataLayerPopupOtherColumns(userDataLayerNode.getElementsByTagName('PopupOtherColumns')[0]),
            styleRules: ProjectXMLParser._parseUserDataLayerStyleRules(userDataLayerNode.getElementsByTagName('StyleRules')[0]),
            metadata: ProjectXMLParser._parseUserDataLayerMetadata(userDataLayerNode.getElementsByTagName('UserDataLayerMetadata')[0]),
        });
    }

    static _parseUserDataLayerMetadata(userDataLayerMetadataNode) {
        if (!userDataLayerMetadataNode) return {};
        return {
            userDataId: userDataLayerMetadataNode.getAttribute('userDataId'),
        }
    }

    static _parseLibraryDataLayers(libraryDataLayersNode) {
        if (!libraryDataLayersNode) return [];
        return [...libraryDataLayersNode.getElementsByTagName('Group')].map(libraryGroup => ProjectXMLParser._parseLibraryGroup(libraryGroup));
    }

    static _parseLibraryGroup(libraryGroupNode) {
        const libraryGroupLayers = ProjectXMLParser._parseLibraryGroupLayers(libraryGroupNode.getElementsByTagName('Layers')[0]);

        return new LibraryGroup({
            id: libraryGroupNode.getAttribute('id'),
            title: libraryGroupNode.getAttribute('title'),
            visible: libraryGroupNode.getAttribute('visible') === 'true',
            layers: libraryGroupLayers,
        });
    }

    static _parseLibraryGroupLayers(libraryGroupLayersNode) {
        if (!libraryGroupLayersNode) return [];
        return [...libraryGroupLayersNode.getElementsByTagName('Layer')].map(libraryLayer => ProjectXMLParser._parseLibraryLayer(libraryLayer));
    }

    static _parseLibraryLayer(libraryLayerNode) {
        return new LibraryLayer({
            id: libraryLayerNode.getAttribute('id'),
            title: libraryLayerNode.getAttribute('title'),
            visible: libraryLayerNode.getAttribute('visible') === 'true',
        });
    }

    static _parseUserDataLayerPopupOtherColumns(popupOtherColumnsNode) {
        if (!popupOtherColumnsNode) return [];
        return [...popupOtherColumnsNode.getElementsByTagName('PopupOtherColumn')].map(otherColumnNode => otherColumnNode.getAttribute('popupOtherColumn'));
    }

    static _parseUserDataLayerStyleRules(styleRulesNode) {
        if (!styleRulesNode) return undefined;
        return [...styleRulesNode.getElementsByTagName('StyleRule')].map(styleRuleNode => {
            const styleRuleType = styleRuleNode.getAttribute('type');
            let layerStyle;

            switch (styleRuleType) {
            case undefined:
            case null:
            case 'symbol': {
                let markerPathId = styleRuleNode.getAttribute('markerPathId');
                let markerColor = styleRuleNode.getAttribute('markerColor');
                if (!markerPathId || !markerColor) {
                    const convertedStyle = UserDataLayer.convertOldMarker(markerPathId);
                    markerPathId = convertedStyle.markerPathId;
                    markerColor = convertedStyle.markerColor;
                }

                layerStyle = new PointLayerStyle({
                    markerPathId,
                    markerColor,
                    value: styleRuleNode.getAttribute('value'),
                    isHidden: styleRuleNode.getAttribute('isHidden') === 'true',
                });
                break;
            }
            case 'single-color-bubble': {
                const bubbleSizeFactor = parseFloatWithDefault(styleRuleNode.getAttribute('bubbleSizeFactor'), 1);
                const bubbleColor = styleRuleNode.getAttribute('bubbleColor');

                layerStyle = new SingleColorBubbleLayerStyle({
                    bubbleSizeFactor,
                    bubbleColor,
                    isHidden: styleRuleNode.getAttribute('isHidden') === 'true',
                });
                break;
            }
            case 'shaded-bubble': {
                const bubbleSizeFactor = parseFloatWithDefault(styleRuleNode.getAttribute('bubbleSizeFactor'), 1);
                const colorPaletteId = styleRuleNode.getAttribute('colorPaletteId');
                const colorPaletteType = styleRuleNode.getAttribute('colorPaletteType');
                const rules = ProjectXMLParser._parseRendererFilterRules(styleRuleNode);

                layerStyle = new ShadedBubbleLayerStyle({
                    bubbleSizeFactor,
                    colorPaletteId,
                    colorPaletteType,
                    rules,
                    isHidden: styleRuleNode.getAttribute('isHidden') === 'true',
                });
                break;
            }
            }

            return layerStyle;
        });
    }

    _parseMapInstances(mapInstancesNode) {
        return [...mapInstancesNode.getElementsByTagName('MapInstance')].map(mapInstanceNode => this._parseMapInstance(mapInstanceNode));
    }

    _parseFrame(frameNode) {
        const frame = new Frame();
        const type = frameNode.getAttribute('type');
        const titleNode = frameNode.getElementsByTagName('Title')[0];
        if (titleNode) {
            frame.title = titleNode.textContent;
        }
        const descriptionNode = frameNode.getElementsByTagName('Description')[0];
        if (descriptionNode) {
            frame.description = descriptionNode.textContent;
        }
        const sourceNode = frameNode.getElementsByTagName('Source')[0];
        if (sourceNode) {
            frame.source = sourceNode.textContent;
        }

        // MAP FRAMES
        frame.type = type;
        frame.lockMode = frameNode.getAttribute('lockMode');

        // normalize the lock mode: if we get an unknown value default to FrameLockMode.POSITION
        switch (frame.lockMode) {
        case FrameLockMode.NONE:
        case FrameLockMode.POSITION:
            break;
        default:
            frame.lockMode = FrameLockMode.POSITION;
        }

        frame.included = frameNode.getAttribute('included').toLowerCase() === 'true';

        const mapInstancesNodes = frameNode.getElementsByTagName('MapInstances');
        if (mapInstancesNodes.length > 0) {
            frame.mapInstances = this._parseMapInstances(mapInstancesNodes[0]);
        }
        return frame;
    }

    static _parseProjectColorPalettes(colorPalettesNode) {
        if (colorPalettesNode === null || !colorPalettesNode) return [];
        return [...colorPalettesNode.getElementsByTagName('ColorPalette')].map(colorPaletteNode => ProjectXMLParser._parseColorPalette(colorPaletteNode));
    }

    _parseAnnotationLegend(annotationLegendNode) {
        if (!annotationLegendNode) return undefined;
        return new AnnotationLegend({
            title: annotationLegendNode.getAttribute('title'),
            visible: annotationLegendNode.getAttribute('visible') === 'true',
            legendItems: this._parseLegendItems([...annotationLegendNode.getElementsByTagName('LegendItem')]),
        });
    }

    _parseLegendItems(legendItemNodes) {
        // Filter out legend items that have the order attribute undefined
        const filteredLegendItems = legendItemNodes.filter(lin => lin.getAttribute('order') !== 'undefined');
        return filteredLegendItems.map(lin => this._parseLegendItem(lin));
    }

    _parseLegendItem(legendItemNode) {
        // The order value cannot be higher than the number of annotations since it used as the index for
        // accessing annotation elements
        const order = Math.min(parseIntWithDefault(legendItemNode.getAttribute('order'), 10, 0), this._annotations.length - 1);
        return new LegendItem({
            color: parseColorWithDefault(legendItemNode.getAttribute('color')),
            included: legendItemNode.getAttribute('included') === 'true',
            title: legendItemNode.getAttribute('title'),
            order,
            markerPathId: this._annotations[order].markerPathId,
            type: this._annotations[order].type,
            icon: legendItemNode.getElementsByTagName('Icon')[0].textContent,
        });
    }

    _parseAnnotations(annotationsNode) {
        if (annotationsNode === null || annotationsNode === undefined) return undefined;
        const annotationsArray = [];
        [...annotationsNode.childNodes].forEach((child, i) => {
            if (child.nodeType === Node.ELEMENT_NODE) {
                const annotation = ProjectXMLParser[`_parse${child.nodeName}`](child, i);
                annotation.interactive = booleanFromString(child.getAttribute('isInteractive'));
                annotation.includedInLegend = booleanFromString(child.getAttribute('includedInLegend'));
                annotation.navigable = booleanFromString(child.getAttribute('navigable'));
                annotation.navigationOrder = parseIntWithDefault(child.getAttribute('navigationOrder'), -1);
                annotationsArray.push(annotation);
            }
        });
        return annotationsArray;
    }

    static _parseText(textNode, i) {
        return new Label({
            id: `label_${i}`,
            fillColor: parseColorWithDefault(textNode.getAttribute('fillColor')),
            useFill: textNode.getAttribute('useFill') === 'true',
            rotationAngle: parseIntWithDefault(textNode.getAttribute('rotationAngle'), 10, undefined),
            textSize: textNode.getAttribute('textSize'),
            textColor: parseColorWithDefault(textNode.getAttribute('textColor')),
            haloWidth: textNode.getAttribute('useFill') === 'true' ? 2 : 0,
            haloBlur: textNode.getAttribute('useFill') === 'true' ? 1 : 0,
            opacity: parseFloatWithDefault(textNode.getAttribute('opacity'), undefined),
            title: textNode.getAttribute('title') === '' ? 'Untitled' : textNode.getAttribute('title'),
            createdAtZoomLevel: parseFloatWithDefault(textNode.getAttribute('createdAtZoomLevel'), undefined),
            minZoom: parseFloat(textNode.getAttribute('minZoom')) === -1 ? undefined : parseFloatWithDefault(textNode.getAttribute('minZoom'), undefined),
            maxZoom: parseFloat(textNode.getAttribute('maxZoom')) === -1 ? undefined : parseFloatWithDefault(textNode.getAttribute('maxZoom'), undefined),
            description: textNode.getElementsByTagName('description')[0].textContent,
            coordinates: ProjectXMLParser._parseCoordinates(textNode.getElementsByTagName('coordinates')[0]),
        });
    }

    static _parseHotspot(hotspotNode, i) {
        return new Hotspot({
            id: `hotspot_${i}`,
            opacity: parseFloat(hotspotNode.getAttribute('opacity')),
            topLeftPoint: [+(parseFloat(hotspotNode.getElementsByTagName('topleftpoint')[0].getAttribute('long')).toFixed(6)), +parseFloat(hotspotNode.getElementsByTagName('topleftpoint')[0].getAttribute('lat')).toFixed(6)],
            bottomRightPoint: [+(parseFloat(hotspotNode.getElementsByTagName('bottomrightpoint')[0].getAttribute('long')).toFixed(6)), +parseFloat(hotspotNode.getElementsByTagName('bottomrightpoint')[0].getAttribute('lat')).toFixed(6)],
            strokeColor: parseColorWithDefault(hotspotNode.getAttribute('strokeColor')),
            strokeWeight: hotspotNode.getAttribute('strokeWeight'),
            title: hotspotNode.getAttribute('title') === '' ? 'Untitled' : hotspotNode.getAttribute('title'),
            createdAtZoomLevel: parseFloatWithDefault(hotspotNode.getAttribute('createdAtZoomLevel'), undefined),
            minZoom: parseFloat(hotspotNode.getAttribute('minZoom')) === -1 ? undefined : parseFloatWithDefault(hotspotNode.getAttribute('minZoom'), undefined),
            maxZoom: parseFloat(hotspotNode.getAttribute('maxZoom')) === -1 ? undefined : parseFloatWithDefault(hotspotNode.getAttribute('maxZoom'), undefined),
            description: hotspotNode.getElementsByTagName('description')[0].textContent,
        });
    }

    static _parseImage(imageNode, i) {
        return new Image({
            id: `image_${i}`,
            fileName: imageNode.getAttribute('filename'),
            rotationAngle: parseIntWithDefault(imageNode.getAttribute('rotationAngle'), 10, 0),
            opacity: parseFloatWithDefault(imageNode.getAttribute('opacity'), 1),
            title: imageNode.getAttribute('title') === '' ? 'Untitled' : imageNode.getAttribute('title'),
            createdAtZoomLevel: parseFloatWithDefault(imageNode.getAttribute('createdAtZoomLevel'), undefined),
            minZoom: parseFloat(imageNode.getAttribute('minZoom')) === -1 ? undefined : parseFloatWithDefault(imageNode.getAttribute('minZoom'), undefined),
            maxZoom: parseFloat(imageNode.getAttribute('maxZoom')) === -1 ? undefined : parseFloatWithDefault(imageNode.getAttribute('maxZoom'), undefined),
            description: imageNode.getElementsByTagName('description')[0].textContent,
            topLeftPoint: ProjectXMLParser._parseCoordinates(imageNode.getElementsByTagName('coordinates')[0])[0],
            bottomRightPoint: ProjectXMLParser._parseCoordinates(imageNode.getElementsByTagName('coordinates')[0])[1],
        });
    }

    static _parseArrow(arrowNode, i) {
        return new FlowArrow({
            id: `flowArrow_${i}`,
            fillColor: parseColorWithDefault(arrowNode.getAttribute('fillColor')),
            strokeColor: parseColorWithDefault(arrowNode.getAttribute('strokeColor')),
            strokeWeight: arrowNode.getAttribute('strokeWeight'),
            useFill: arrowNode.getAttribute('useFill') === 'true',
            opacity: parseFloatWithDefault(arrowNode.getAttribute('opacity'), 1),
            size: arrowNode.getAttribute('size'),
            fromWidth: parseIntWithDefault(arrowNode.getAttribute('fromWidth'), 10, undefined),
            toWidth: parseIntWithDefault(arrowNode.getAttribute('toWidth'), 10, undefined),
            tipWidth: parseIntWithDefault(arrowNode.getAttribute('tipWidth'), 10, undefined),
            title: arrowNode.getAttribute('title') === '' ? 'Untitled' : arrowNode.getAttribute('title'),
            createdAtZoomLevel: parseFloatWithDefault(arrowNode.getAttribute('createdAtZoomLevel'), undefined),
            minZoom: parseFloat(arrowNode.getAttribute('minZoom')) === -1 ? undefined : parseFloatWithDefault(arrowNode.getAttribute('minZoom'), undefined),
            maxZoom: parseFloat(arrowNode.getAttribute('maxZoom')) === -1 ? undefined : parseFloatWithDefault(arrowNode.getAttribute('maxZoom'), undefined),
            description: arrowNode.getElementsByTagName('description')[0].textContent,
            curves: ProjectXMLParser._parseCurves(arrowNode.getElementsByTagName('curves')[0]),
        });
    }

    static _parseMarker(markerNode, i) {
        let markerPathId = markerNode.getAttribute('markerPathId');
        // check if we have this marker
        // if not assign default marker
        if (!AnnotationsMarkers.find(am => am.id === markerPathId)) {
            markerPathId = defaultAnnotationMarker;
        }
        return new Marker({
            id: `marker_${i}`,
            fillColor: parseColorWithDefault(markerNode.getAttribute('fillColor')),
            textSize: markerNode.getAttribute('textSize'),
            textColor: parseColorWithDefault(markerNode.getAttribute('textColor')),
            opacity: parseFloatWithDefault(markerNode.getAttribute('opacity'), 1),
            size: markerNode.getAttribute('size'),
            labelPosition: markerNode.getAttribute('labelPosition'),
            labelVisible: markerNode.getAttribute('labelVisible') === 'true',
            markerPathId,
            title: markerNode.getAttribute('title') === '' ? 'Untitled' : markerNode.getAttribute('title'),
            createdAtZoomLevel: parseFloatWithDefault(markerNode.getAttribute('createdAtZoomLevel'), undefined),
            minZoom: parseFloat(markerNode.getAttribute('minZoom')) === -1 ? undefined : parseFloatWithDefault(markerNode.getAttribute('minZoom'), undefined),
            maxZoom: parseFloat(markerNode.getAttribute('maxZoom')) === -1 ? undefined : parseFloatWithDefault(markerNode.getAttribute('maxZoom'), undefined),
            description: markerNode.getElementsByTagName('description')[0].textContent,
            coordinates: ProjectXMLParser._parseCoordinates(markerNode.getElementsByTagName('coordinates')[0]),
            searchId: parseFloatWithDefault(markerNode.getAttribute('searchId'), undefined),
        });
    }

    static _parsePolyLine(polylineNode, i) {
        return new Polyline({
            id: `polyline_${i}`,
            strokeColor: parseColorWithDefault(polylineNode.getAttribute('strokeColor')),
            strokeWeight: polylineNode.getAttribute('strokeWeight'),
            opacity: parseFloatWithDefault(polylineNode.getAttribute('opacity'), undefined),
            title: polylineNode.getAttribute('title') === '' ? 'Untitled' : polylineNode.getAttribute('title'),
            createdAtZoomLevel: parseFloatWithDefault(polylineNode.getAttribute('createdAtZoomLevel'), undefined),
            minZoom: parseFloat(polylineNode.getAttribute('minZoom')) === -1 ? undefined : parseFloatWithDefault(polylineNode.getAttribute('minZoom'), undefined),
            maxZoom: parseFloat(polylineNode.getAttribute('maxZoom')) === -1 ? undefined : parseFloatWithDefault(polylineNode.getAttribute('maxZoom'), undefined),
            description: polylineNode.getElementsByTagName('description')[0].textContent,
            coordinates: ProjectXMLParser._parseCoordinates(polylineNode.getElementsByTagName('coordinates')[0]),
            searchId: parseFloatWithDefault(polylineNode.getAttribute('searchId'), undefined),
        });
    }

    static _parseFreehandPolygon(freehandPolygonNode, i) {
        return new Shape({
            id: `shape_${i}`,
            strokeColor: parseColorWithDefault(freehandPolygonNode.getAttribute('strokeColor')),
            strokeWeight: freehandPolygonNode.getAttribute('strokeWeight'),
            fillColor: parseColorWithDefault(freehandPolygonNode.getAttribute('fillColor')),
            useFill: freehandPolygonNode.getAttribute('useFill') === 'true',
            opacity: parseFloatWithDefault(freehandPolygonNode.getAttribute('opacity'), 1),
            title: freehandPolygonNode.getAttribute('title') === '' ? 'Untitled' : freehandPolygonNode.getAttribute('title'),
            createdAtZoomLevel: parseFloatWithDefault(freehandPolygonNode.getAttribute('createdAtZoomLevel'), undefined),
            minZoom: parseFloat(freehandPolygonNode.getAttribute('minZoom')) === -1 ? undefined : parseFloatWithDefault(freehandPolygonNode.getAttribute('minZoom'), undefined),
            maxZoom: parseFloat(freehandPolygonNode.getAttribute('maxZoom')) === -1 ? undefined : parseFloatWithDefault(freehandPolygonNode.getAttribute('maxZoom'), undefined),
            description: freehandPolygonNode.getElementsByTagName('description')[0].textContent,
            coordinates: ProjectXMLParser._parseCoordinates(freehandPolygonNode.getElementsByTagName('coordinates')[0]),
        });
    }

    static _parseFreehandLine(freehandLineNode, i) {
        return new Freehand({
            id: `freehand_${i}`,
            strokeColor: parseColorWithDefault(freehandLineNode.getAttribute('strokeColor')),
            strokeWeight: freehandLineNode.getAttribute('strokeWeight'),
            opacity: parseFloatWithDefault(freehandLineNode.getAttribute('opacity'), 1),
            title: freehandLineNode.getAttribute('title') === '' ? 'Untitled' : freehandLineNode.getAttribute('title'),
            createdAtZoomLevel: parseFloatWithDefault(freehandLineNode.getAttribute('createdAtZoomLevel'), undefined),
            minZoom: parseFloat(freehandLineNode.getAttribute('minZoom')) === -1 ? undefined : parseFloatWithDefault(freehandLineNode.getAttribute('minZoom'), undefined),
            maxZoom: parseFloat(freehandLineNode.getAttribute('maxZoom')) === -1 ? undefined : parseFloatWithDefault(freehandLineNode.getAttribute('maxZoom'), undefined),
            description: freehandLineNode.getElementsByTagName('description')[0].textContent,
            coordinates: ProjectXMLParser._parseCoordinates(freehandLineNode.getElementsByTagName('coordinates')[0]),
        });
    }

    static _parsePolygon(polygonNode, i) {
        return new Polygon({
            id: `polygon_${i}`,
            strokeColor: parseColorWithDefault(polygonNode.getAttribute('strokeColor')),
            strokeWeight: polygonNode.getAttribute('strokeWeight'),
            fillColor: parseColorWithDefault(polygonNode.getAttribute('fillColor')),
            useFill: polygonNode.getAttribute('useFill') === 'true',
            opacity: parseFloatWithDefault(polygonNode.getAttribute('opacity'), 1),
            title: polygonNode.getAttribute('title') === '' ? 'Untitled' : polygonNode.getAttribute('title'),
            createdAtZoomLevel: parseFloatWithDefault(polygonNode.getAttribute('createdAtZoomLevel'), undefined),
            minZoom: parseFloat(polygonNode.getAttribute('minZoom')) === -1 ? undefined : parseFloatWithDefault(polygonNode.getAttribute('minZoom'), undefined),
            maxZoom: parseFloat(polygonNode.getAttribute('maxZoom')) === -1 ? undefined : parseFloatWithDefault(polygonNode.getAttribute('maxZoom'), undefined),
            description: polygonNode.getElementsByTagName('description')[0].textContent,
            coordinates: ProjectXMLParser._parseCoordinates(polygonNode.getElementsByTagName('coordinates')[0]),
        });
    }

    static _parseCoordinates(coordinatesNode) {
        return [...coordinatesNode.getElementsByTagName('point')].map(p => [+(parseFloat(p.getAttribute('long')).toFixed(6)), +parseFloat(p.getAttribute('lat')).toFixed(6)]);
    }

    static _parseCurves(curvesNode) {
        return [...curvesNode.getElementsByTagName('curve')].map(c => {
            const anchorPoint1Node = c.getElementsByTagName('anchorpoint1')[0];
            const controlPoint1Node = c.getElementsByTagName('controlpoint1')[0];
            const anchorPoint2Node = c.getElementsByTagName('anchorpoint2')[0];
            const controlPoint2Node = c.getElementsByTagName('controlpoint2')[0];
            return new Curve({
                anchorPoint1: [+(parseFloat(anchorPoint1Node.getAttribute('long')).toFixed(6)), +(parseFloat(anchorPoint1Node.getAttribute('lat')).toFixed(6))],
                controlPoint1: [+(parseFloat(controlPoint1Node.getAttribute('long')).toFixed(6)), +(parseFloat(controlPoint1Node.getAttribute('lat')).toFixed(6))],
                anchorPoint2: [+(parseFloat(anchorPoint2Node.getAttribute('long')).toFixed(6)), +(parseFloat(anchorPoint2Node.getAttribute('lat')).toFixed(6))],
                controlPoint2: [+(parseFloat(controlPoint2Node.getAttribute('long')).toFixed(6)), +(parseFloat(controlPoint2Node.getAttribute('lat')).toFixed(6))],
            });
        });
    }

    static _parseColorPalette(colorPaletteNode) {
        let colors = [...colorPaletteNode.getElementsByTagName('Color')].map(colorNode => parseColorWithDefault(colorNode.getAttribute('value'), parseColorWithDefault(colorNode.getAttribute('fill'))));
        colors = colors.filter(color => color.length === 7);

        const strokeColors = [...colorPaletteNode.getElementsByTagName('Color')].map(colorNode => parseColorWithDefault(colorNode.getAttribute('stroke'), '#d6d6d6'));

        const colorRamps = [...colorPaletteNode.getElementsByTagName('ColorRamp')].map(colorRampNode => {
            const colorRamp = new ColorRamp();
            colorRamp.from = parseColorWithDefault(colorRampNode.getAttribute('from'));
            colorRamp.to = parseColorWithDefault(colorRampNode.getAttribute('to'));
            colorRamp.bias = parseFloatWithDefault(colorRampNode.getAttribute('bias'), 0);

            return colorRamp;
        });

        const colorPalette = new ColorPalette();
        colorPalette.id = colorPaletteNode.getAttribute('id');
        colorPalette.title = colorPaletteNode.getAttribute('title');
        colorPalette.type = colorPaletteNode.getAttribute('type');
        colorPalette.insufficientDataColor = parseColorWithDefault(colorPaletteNode.getAttribute('insufficientDataColor'));
        colorPalette.nullDataColor = parseColorWithDefault(colorPaletteNode.getAttribute('nullDataColor'));
        colorPalette.colorRamps = colorRamps;
        colorPalette.strokeColors = strokeColors;
        colorPalette.colors = colors;

        return colorPalette;
    }

    _projectInfoNode() {
        if (!this.projectInfoNode) this.projectInfoNode = this.projectXMLDocument.getElementsByTagName('ProjectInfo')[0];
        return this.projectInfoNode;
    }

    /**
     * Assets Guid
     * @type {string}
     */
    get assetsGuid() {
        return this._projectInfoNode() ? this._projectInfoNode().getAttribute('AssetsGuid') : undefined;
    }

    /**
     * User Id
     * @type {int}
     */
    get userId() {
        return this._projectInfoNode() ? this._projectInfoNode().getAttribute('UserId') : undefined;
    }

    /**
     * Modified date
     * @type {date}
     */
    get modifiedDate() {
        return this._projectInfoNode() ? this._projectInfoNode().getAttribute('ModifiedDate') : undefined;
    }

    /**
     * Created date
     * @type {date}
     */
    get createdDate() {
        return this._projectInfoNode() ? this._projectInfoNode().getAttribute('CreatedDate') : undefined;
    }

    /**
     * Can user save?
     * @type {boolean}
     */
    get canSave() {
        return this._projectInfoNode() ? this._projectInfoNode().getAttribute('CanSave') === 'true' : undefined;
    }

    /**
     * Get the project title
     * @type {string}
     */
    get title() {
        return this._projectInfoNode() ? this._projectInfoNode().getAttribute('Title') : undefined;
    }

    /**
     * Get the project description
     * @type {string}
     */
    get description() {
        return this._projectInfoNode() ? this._projectInfoNode().getAttribute('Description') : undefined;
    }

    /**
     * Get the project id
     * @type {int}
     */
    get projectId() {
        const projectId = this._projectInfoNode().getAttribute('ProjectId');
        return parseIntWithDefault(projectId, 10, undefined);
    }

    /**
     * Get the project type
     * @type {string}
     */
    get projectType() {
        return this._projectInfoNode() ? this._projectInfoNode().getAttribute('ProjectType') : undefined;
    }

    /**
     * Get the project view code
     * @type {string}
     */
    get viewCode() {
        return this._projectInfoNode() ? this._projectInfoNode().getAttribute('ViewCode') : undefined;
    }

    /**
     * Get the project thumb url
     * @type {url}
     */
    get projectThumbUrl() {
        return this._projectInfoNode() ? this._projectInfoNode().getAttribute('ProjectThumbUrl') : undefined;
    }

    get projectColorPalettes() {
        return ProjectXMLParser._parseProjectColorPalettes(this.projectXMLDocument.getElementsByTagName('ProjectColorPalettes')[0]);
    }

    get frames() {
        const frames = [];

        [...this.projectXMLDocument.getElementsByTagName('Frame')].forEach(frameNode => {
            frames.push(this._parseFrame(frameNode));
        });

        return frames;
    }

    get isShareSnapshot() {
        return this._projectInfoNode() ? this._projectInfoNode().getAttribute('IsShareSnapshot') === '1' : false;
    }

    get ownedBySe() {
        return this._projectInfoNode() ? this._projectInfoNode().getAttribute('OwnedBySe') === 'true' : false;
    }

    get isPublic() {
        return this._projectInfoNode() ? this._projectInfoNode().getAttribute('IsPublic') === 'true' : false;
    }

    get loginRequired() {
        return this._projectInfoNode() ? this._projectInfoNode().getAttribute('LoginRequired') === 'true' : false;
    }

    get etag() {
        return this._projectInfoNode() ? this._projectInfoNode().getAttribute('etag') : undefined;
    }

    /**
     * Get the Project object
     * @returns {Project}
     */
    getProject() {
        const project = new Project();
        project.projectColorPalettes = this.projectColorPalettes;
        project.viewCode = this.viewCode;
        project.assetsGuid = this.assetsGuid;
        project.title = this.title;
        project.description = this.description;
        project.projectType = this.projectType;
        project.userId = this.userId;
        project.modifiedDate = this.modifiedDate;
        project.createdDate = this.createdDate;
        project.projectId = this.projectId;
        project.frames = this.frames;
        project.canSave = this.canSave;
        project.isShareSnapshot = this.isShareSnapshot;
        project.projectThumbUrl = this.projectThumbUrl;
        project.ownedBySe = this.ownedBySe;
        project.etag = this.etag;
        project.showThumbsInPlayMode = this.showThumbsInPlayMode;
        project.framesThumbs = `${AppConfig.constants.links.amazonS3Bucket}/projects/${project.assetsGuid}/thumbs.png?${(new Date()).getTime()}`;
        project.isPublic = this.isPublic;
        project.loginRequired = this.loginRequired;
        return project;
    }

    static parseError(xmlDocument) {
        if (!xmlDocument) {
            return undefined;
        }
        const errorNode = xmlDocument.getElementsByTagName('Error')[0];
        if (!errorNode) {
            return undefined;
        }
        const code = errorNode.getAttribute('Code');
        const message = errorNode.textContent;
        return {
            isSessionTimeoutError: code === SessionTimeoutCode && message === SessionTimeoutError,
            isLoginError: code === SessionTimeoutCode && message !== SessionTimeoutError,
            code,
            message,
        };
    }

    static getChildElementsByTagName(parentNode, tagName) {
        return [...parentNode.getElementsByTagName(tagName)].filter(node => node.parentNode === parentNode);
    }

    static getTextContentFromNode(parentNode, tagName) {
        const childNode = parentNode.getElementsByTagName(tagName)[0];
        if (childNode) {
            return childNode.textContent;
        }
        return undefined;
    }

}

export default ProjectXMLParser;
