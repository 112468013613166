/* eslint class-methods-use-this: 0 */
import BaseController from './BaseController';
import MapDataSource from '../dataSources/MapDataSource';
import DataGeoFilter from '../objects/dataGeoFilter/DataGeoFilter';
import ProjectDataSource from '../dataSources/ProjectDataSource';

class MaskController extends BaseController {
    static get name() {
        return 'MaskController';
    }

    static getInstance(options) {
        return new MaskController(options);
    }

    onActivate() {
        this.bindGluBusEvents({
            ENTER_MASK_MODE: this.onEnterMaskMode,
            EXIT_MASK_MODE: this.onExitMaskMode,
            APPLY_MASKING_FILTER_REQUEST: this.onApplyMaskingFilterRequest,
            REMOVE_MASKING_FILTER_REQUEST: this.onRemoveMaskingFilterRequest,
        });
        this.mapDataSource = this.activateSource(MapDataSource);
        this.projectDataSource = this.activateSource(ProjectDataSource);

        this._maskingIsActive = false;
        this._oldMaskingFilter = undefined;
        this._oldPreferredDataLayerId = false;
    }

    onEnterMaskMode(e) {
        if (this._maskingIsActive) {
            return;
        }
        this._maskingIsActive = true;

        const mapInstance = this.projectDataSource.getActiveMapInstance(e.mapInstanceId);
        this._oldPreferredDataLayerId = mapInstance.preferredDataLayerId;
        if (mapInstance.hasMaskingFilter) {
            this._oldMaskingFilter = Object.values(mapInstance.dataGeoFilters)[0].clone();
        }
        const currentMap = this.mapDataSource.currentMaps[mapInstance.currentMapId];
        const currentSummaryLevel = currentMap.getSummaryLevelOnZoom(mapInstance.initialView.zoom);
        if (!mapInstance.hasPreferredSummaryLevel && currentSummaryLevel) {
            mapInstance.preferredDataLayerId = currentMap.getDataSourceForSummaryLevelId(currentSummaryLevel.id).id;
        }
        this.bus.emit('DISPLAY_MAP_MASK_EDITOR_FRAME', {
            mapInstance,
        });
    }

    onExitMaskMode(e) {
        if (!this._maskingIsActive) {
            return;
        }
        const mapInstance = this.projectDataSource.getActiveMapInstance(e.mapInstanceId);
        // In case of preview story mode, no active mapInstance exists with provided report ID
        // Skipping further code block in this particular case would just
        // ignore user click on close button. However, it would be enabled
        // first next time after user returns to story edit mode
        if (!mapInstance) return;

        // if masking filter did not change revert to old preferred summary level (if any)
        // if masking filter is set to non automatic summary level then set preferred summary level
        let preferredDataLayerId = this._oldPreferredDataLayerId;
        const newMaskingFilter = Object.values(mapInstance.dataGeoFilters)[0];
        if (newMaskingFilter && newMaskingFilter !== this._oldMaskingFilter) {
            const currentMap = this.mapDataSource.currentMaps[mapInstance.currentMapId];
            const maskedSummaryLevel = currentMap.summaryLevels.find(sl => sl.id === newMaskingFilter.summaryLevelId);
            preferredDataLayerId = maskedSummaryLevel.isAutomatic ? preferredDataLayerId : newMaskingFilter.dataLayerId;
        }
        mapInstance.preferredDataLayerId = preferredDataLayerId;

        this._maskingIsActive = false;
        this.bus.emit('HIDE_MAP_MASK_EDITOR_FRAME', { mapInstance, newMode: e.newMode });
    }

    onApplyMaskingFilterRequest(e) {
        const mapInstance = this.projectDataSource.getActiveMapInstance(e.mapInstanceId);
        const baseMap = this.mapDataSource.currentMaps[mapInstance.currentMapId];
        let dataGeoFilter = Object.values(mapInstance.dataGeoFilters)[0];
        if (!dataGeoFilter) {
            dataGeoFilter = new DataGeoFilter();
            dataGeoFilter.summaryLevelId = e.summaryLevel.id;
            dataGeoFilter.nestedSummaryLevelsIds = [];
            dataGeoFilter.dataLayerId = baseMap.getDataSourceForSummaryLevelId(dataGeoFilter.summaryLevelId).id;
            dataGeoFilter.features = [];
        }
        if (e.nestedSummaryLevels) {
            dataGeoFilter.nestedSummaryLevelsIds = e.nestedSummaryLevels.map(sl => sl.id);
        }
        if (e.selectedFeatures) {
            dataGeoFilter.features = e.selectedFeatures;
        }
        mapInstance.dataGeoFilters[dataGeoFilter.summaryLevelId] = dataGeoFilter;
        this.bus.emit('MAP_APPLY_MASKING_FILTER_REQUEST', { mapInstanceId: e.mapInstanceId });
    }

    onRemoveMaskingFilterRequest(e) {
        const mapInstance = this.projectDataSource.getActiveMapInstance(e.mapInstanceId);
        delete mapInstance.dataGeoFilters[e.dataGeoFilter.summaryLevelId];
        this.bus.emit('MAP_APPLY_MASKING_FILTER_REQUEST', { mapInstanceId: e.mapInstanceId });
    }

    onDeactivate() {
        this.unbindGluBusEvents();
    }
}

export default MaskController;
