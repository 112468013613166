import React from 'react';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';

import PropTypes from 'prop-types';
import BusComponent from '../BusComponent';
import Loader from '../Loader';
import SearchInput from './SearchInput';
import PointSearchResultList from './PointSearchResultList';
import MapSelect from '../mapSelect/MapSelect';

const SEARCH_TIMEOUT = 500;
const SEARCH_THRESHOLD = 3; // the minimum number of characters for search to initiate

/**
 * @typedef State
 * @property {boolean} isFocused
 * @property {boolean} isSearching
 * @property {string} searchTerm
 * @property {import('@turf/helpers').Feature[]} results
 * @property {number} count
 * @property {string[]} searchFields
 *
 * @extends {BusComponent<Props, State>}
 */
class PointSearch extends BusComponent {
    constructor(props, context) {
        super(props, context);
        const { mapInstanceIds, activeMapInstanceId } = props;
        this.state = {
            isFocused: false,
            searchTerm: '',
            isSearching: false,
            results: undefined,
            count: undefined,
            searchFields: [],
            activeMapInstanceId: activeMapInstanceId || mapInstanceIds[0],
        };
    }

    componentDidMount() {
        this.bindGluBusEvents({
            POINTS_SEARCH_FIELDS_RESPONSE: this.onSearchFieldsResponse,
            FOCUS_SEARCH: this.onFocusSearch,
            SEARCH_POINT_GEOJSON_RESPONSE: this.onSearchSuccess,
        });
        this.emit('POINTS_SEARCH_FIELDS_REQUEST');
    }

    clearMarkersOnMaps() {
        this.props.mapInstanceIds.forEach(mapInstanceId =>
            this.emit('MAP_CLEAR_SEARCH_RESULTS_MARKERS_REQUEST', {
                mapInstanceId,
            }),
        );
    }

    componentWillReceiveProps({ mapInstanceIds, activeMapInstanceId }) {
        const nextActiveMapInstanceId =
            activeMapInstanceId || mapInstanceIds[0];
        if (nextActiveMapInstanceId !== this.state.activeMapInstanceId) {
            this.setState(
                {
                    activeMapInstanceId: nextActiveMapInstanceId,
                },
                () => {
                    this.clearMarkersOnMaps();
                },
            );
        }
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
        clearTimeout(this._searchTimeout);
    }

    /**
     *
     * @param {string[]} searchFields
     */
    onSearchFieldsResponse = searchFields => {
        this.setState({ searchFields });
    };

    onFocusSearch = () => {
        if (this.searchInput) {
            this.searchInput.focus();
            this.setState({ isFocused: true });
        }
    };

    /**
     *
     * @param {object} payload
     * @param {number} payload.count
     * @param {import('@turf/helpers').Feature[]} payload.results
     */
    onSearchSuccess = ({ count, results }) => {
        this.setState({ isSearching: false, results, count });
    };

    clearSearch = () => {
        this.setState({
            searchTerm: '',
            results: undefined,
            count: undefined,
        });
    };

    initiateSearch = () => {
        clearTimeout(this._searchTimeout);
        if (
            !this.state.searchTerm ||
            this.state.searchTerm.trim().length < SEARCH_THRESHOLD
        ) {
            // Don't do anything if the search term has less characters than threshold
            return;
        }

        this._searchTimeout = setTimeout(() => {
            this.setState(
                {
                    selectedItem: undefined,
                    results: undefined,
                    isSearching: true,
                },
                () => {
                    this.emit('SEARCH_POINT_GEOJSON_REQUEST', {
                        searchTerm: this.state.searchTerm,
                    });
                },
            );
        }, SEARCH_TIMEOUT);
    };

    handleSearchTermChange = searchTerm => {
        this.setState({ searchTerm }, () => {
            if (searchTerm.length) {
                this.initiateSearch();
            } else {
                this.setState({ results: undefined, count: undefined });
            }
        });
    };

    handleSearchTargetMap = activeMapInstanceId => {
        this.setState({ activeMapInstanceId }, () => {
            this.initiateSearch();
        });
    };

    render() {
        const { isFocused, searchTerm, isSearching, results, searchFields } = this.state;
        const { mapInstanceIds, disableMapSelection } = this.props;

        const showMapSelection =
            !disableMapSelection &&
            isFocused &&
            this.props.activeMapInstanceId === null &&
            mapInstanceIds.length > 1 &&
            results &&
            results.length > 0;
        const { tabPlaceholder } = this.props;
        return (
            <div className="flex-it column">
                <div
                    className={classNames('search-box', {
                        'search-box--focused': isFocused,
                    })}
                >
                    <div className="search-box__menu">
                        <div className="search-box__header">
                            <SearchInput
                                ref={c => {
                                    this.searchInput = c;
                                }}
                                value={searchTerm}
                                onChange={this.handleSearchTermChange}
                                onFocus={this.handleInputFocus}
                                placeholder={this.props.intl.formatMessage({
                                    id: `${tabPlaceholder}SearchPlaceholder`,
                                })}
                            />
                            {isSearching ? (
                                <div className="search-box__spinner">
                                    <Loader width="36" height="36" />
                                </div>
                            ) : (
                                <button
                                    className={classNames('search-box__clear', {
                                        'search-box__clear--visible':
                                            searchTerm &&
                                            searchTerm.length > 0,
                                    })}
                                    onClick={() =>
                                        this.handleSearchTermChange('')
                                    }
                                >
                                    {this.props.intl.formatMessage({
                                        id: 'clear',
                                    })}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
                {showMapSelection && (
                    <MapSelect
                        onChange={this.handleSearchTargetMap}
                        selectedMapInstanceId={this.state.activeMapInstanceId}
                        mapInstanceIds={this.props.mapInstanceIds}
                    />
                    )}
                <PointSearchResultList
                    results={results}
                    searchFields={searchFields}
                    mapInstanceId={this.state.activeMapInstanceId}
                />
            </div>
        );
    }
}

PointSearch.propTypes = {
    mapInstanceIds: PropTypes.array.isRequired,
    activeMapInstanceId: PropTypes.string,
    disableMapSelection: PropTypes.bool,
};

export default injectIntl(PointSearch);
