class InitialView {
    constructor(values) {
        if (values) {
            Object.keys(values).forEach(k => {
                if (this.constructor.prototype.hasOwnProperty(k)) {
                    this[k] = values[k];
                }
            });
        }
        if (this._applyBoundingBox === undefined) {
            this._applyBoundingBox = true;
        }
    }

    get centerLat() {
        return this._centerLat;
    }

    set centerLat(centerLat) {
        this._centerLat = centerLat;
    }

    get centerLng() {
        return this._centerLng;
    }

    set centerLng(centerLng) {
        this._centerLng = centerLng;
    }

    get center() {
        return [this.centerLng, this.centerLat];
    }

    get zoom() {
        return this._zoom;
    }

    set zoom(zoom) {
        this._zoom = zoom;
    }

    get boundingBoxNeLat() {
        return this._boundingBoxNeLat;
    }

    set boundingBoxNeLat(boundingBoxNeLat) {
        this._boundingBoxNeLat = boundingBoxNeLat;
    }

    get boundingBoxNeLng() {
        return this._boundingBoxNeLng;
    }

    set boundingBoxNeLng(boundingBoxNeLng) {
        this._boundingBoxNeLng = boundingBoxNeLng;
    }

    get boundingBoxSwLat() {
        return this._boundingBoxSwLat;
    }

    set boundingBoxSwLat(boundingBoxSwLat) {
        this._boundingBoxSwLat = boundingBoxSwLat;
    }

    get boundingBoxSwLng() {
        return this._boundingBoxSwLng;
    }

    set boundingBoxSwLng(boundingBoxSwLng) {
        this._boundingBoxSwLng = boundingBoxSwLng;
    }

    get boundingBox() {
        if (this.boundingBoxNeLat && this.boundingBoxNeLng && this.boundingBoxSwLat && this.boundingBoxSwLng) {
            return [[this.boundingBoxSwLng, this.boundingBoxSwLat], [this.boundingBoxNeLng, this.boundingBoxNeLat]];
        }
        return null;
    }

    get applyBoundingBox() {
        return this._applyBoundingBox;
    }

    set applyBoundingBox(applyBoundingBox) {
        this._applyBoundingBox = applyBoundingBox;
    }

    updatePosition(boundingBox, center, zoom) {
        // update bounding box
        this.boundingBoxSwLng = boundingBox[0][0];
        this.boundingBoxSwLat = boundingBox[0][1];
        this.boundingBoxNeLng = boundingBox[1][0];
        this.boundingBoxNeLat = boundingBox[1][1];
        // update center
        this.centerLng = center.lng;
        this.centerLat = center.lat;
        // update zoom level
        this.zoom = zoom;
    }

    equals(that) {
        if (this.boundingBox) {
            return this.boundingBoxNeLat === that.boundingBoxNeLat &&
                this.boundingBoxNeLng === that.boundingBoxNeLng &&
                this.boundingBoxSwLng === that.boundingBoxSwLng &&
                this.boundingBoxSwLat === that.boundingBoxSwLat;
        }
        return (
        this.centerLat === that.centerLat &&
        this.centerLng === that.centerLng &&
        this.zoom.toFixed(2) === that.zoom.toFixed(2));
    }

    clone() {
        const initialView = new InitialView();
        initialView.centerLat = this.centerLat;
        initialView.centerLng = this.centerLng;
        initialView.zoom = this.zoom;
        initialView.boundingBoxNeLat = this.boundingBoxNeLat;
        initialView.boundingBoxNeLng = this.boundingBoxNeLng;
        initialView.boundingBoxSwLat = this.boundingBoxSwLat;
        initialView.boundingBoxSwLng = this.boundingBoxSwLng;
        initialView.applyBoundingBox = this.applyBoundingBox;

        return initialView;
    }

    toJSON() {
        return {
            zoom: this.zoom,
            centerLat: this.centerLat,
            centerLng: this.centerLng,
            boundingBoxNeLat: this.boundingBoxNeLat,
            boundingBoxNeLng: this.boundingBoxNeLng,
            boundingBoxSwLat: this.boundingBoxSwLat,
            boundingBoxSwLng: this.boundingBoxSwLng,
            applyBoundingBox: this.applyBoundingBox,
        };
    }
}

export default InitialView;
