// @ts-check
import React from 'react';
import { injectIntl } from 'react-intl';

import BusComponent from '../BusComponent';
import { ActiveView } from '../mapLayersEditor/MapLayersEditor';
import Popover from '../popover/Popover';

const POPUP_VERTICAL_OFFSET = -36;

/**
 * @typedef Props
 * @property {string} mapInstanceId
 * @property {import('../../objects/LocationAnalysisItem').default} selectedItem
 * @property {import('react-intl').intlShape} intl
 *
 * @typedef State
 * @property {string[]} annotationIds
 * @property {boolean} popoverOpen
 *
 * @extends BusComponent<Props, State>
 */
class SearchResultAnnotation extends BusComponent {
    constructor(props, context) {
        super(props, context);

        /** @type {State} */
        this.state = {
            annotationIds: [],
            popoverOpen: false,
        };
    }

    componentDidMount() {
        this.bindGluBusEvents({
            SEARCH_ANNOTATIONS_CREATED: this.onAnnotationsCreated,
            ANNOTATIONS_DELETE_REQUEST_SUCCESS: this.onAnnotationsDeleted,
            ANNOTATIONS_FETCHED: this.onAnnotationsFetched,
        });
        this.emit('FETCH_ANNOTATIONS', {
            mapInstanceId: this.props.mapInstanceId,
        });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    onAnnotationsCreated = ({ annotations }) => {
        const relevantAnnotations = annotations.filter(
            annotation => annotation.searchId === this.props.selectedItem.id,
        );

        const annotationIds = [
            ...this.state.annotationIds,
            ...relevantAnnotations.map(annotation => annotation.searchId),
        ];

        this.setState({ annotationIds });
    };

    onAnnotationsDeleted = ({ deletedIds }) => {
        const annotationIds = this.state.annotationIds.filter(
            id => deletedIds.indexOf(id) === -1,
        );
        this.setState({ annotationIds });
    };

    onAnnotationsFetched = ({ annotations }) => {
        const annotationIds = annotations
            .filter(annotation => annotation.searchId)
            .map(a => a.searchId);
        this.setState({ annotationIds });
    };

    onDeleteAnnotation = () => {
        this.setState({ popoverOpen: false }, () => {
            this.emit('DELETE_SEARCH_ANNOTATION_REQUEST', {
                mapInstanceId: this.props.mapInstanceId,
                id: this.props.selectedItem.id,
            });
        });
    };

    onViewAnnotationList = () => {
        this.emit('DISPLAY_MAP_EDITOR_FRAME', {
            mapInstanceId: this.props.mapInstanceId,
            initialView: ActiveView.ANNOTATIONS,
        });
        this.emit('EXIT_LOCATION_ANALYSIS_MODE', {
            mapInstanceId: this.props.mapInstanceId,
        });
    };

    onAddAnnotation = () => {
        this.emit('MAP_ADD_SEARCH_RESULTS_AS_ANNOTATION', {
            mapInstanceId: this.props.mapInstanceId,
        });
    };

    onClosePopover = () => {
        this.setState({ popoverOpen: false });
    };

    onOpenPopover = () => {
        this.setState({ popoverOpen: true });
    };

    render() {
        const isExistingAnnotation = this.state.annotationIds.some(
            id => this.props.selectedItem.id === id,
        );

        if (isExistingAnnotation) {
            return (
                <div className="location-info__annotations">
                    <button
                        ref={r => {
                            this.anchorElement = r;
                        }}
                        className="btn-chip btn-chip--active margin-right-10"
                        onClick={this.onOpenPopover}
                    >
                        <i className="material-icons btn-chip__icon">gesture</i>
                        {this.props.intl.formatMessage({
                            id: 'header.annotationSaved',
                        })}
                    </button>
                    <Popover
                        anchorEl={this.anchorElement}
                        open={this.state.popoverOpen}
                        className="user-map-views__popover"
                        autoWidth
                        onCloseRequest={this.onClosePopover}
                        marginTop={POPUP_VERTICAL_OFFSET}
                    >
                        <div className="material-menu annotation-actions-menu">
                            <button
                                className="material-menu__item"
                                onClick={this.onViewAnnotationList}
                            >
                                <i className="material-icons">list</i>
                                {this.props.intl.formatMessage({
                                    id: 'viewSavedList',
                                })}
                            </button>
                            <button
                                className="material-menu__item"
                                onClick={this.onDeleteAnnotation}
                            >
                                <i className="material-icons">delete</i>
                                {this.props.intl.formatMessage({
                                    id: 'header.removeAnnotation',
                                })}
                            </button>
                        </div>
                    </Popover>
                </div>
            );
        }

        return (
            <button
                className="btn-chip margin-right-10"
                onClick={this.onAddAnnotation}
            >
                <i className="material-icons btn-chip__icon">gesture</i>
                {this.props.intl.formatMessage({
                    id: 'header.saveAsAnnotation',
                })}
            </button>
        );
    }
}

export default injectIntl(SearchResultAnnotation);
