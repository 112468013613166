import { basePngSize } from '../enums/AnnotationsMarkers';
import MarkerSvgPaths from './MarkerSvgPaths';

import canvg from 'canvg-browser';

export default function convertSvgToPng(marker, options) {
    const div = document.createElement('div');
    const fullSvg = `<?xml version="1.0" encoding="utf-8"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">\n<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="32px" height="32px" viewBox="0 0 32 32" enable-background="new 0 0 32 32" xml:space="preserve">\n<g>\n<path d="${MarkerSvgPaths[marker]}"/>\n</g>\n</svg>`;
    div.innerHTML = fullSvg;

    const { fillColor, strokeColor = '#FFFFFF', strokeWidth = 0 } = options;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    let offsetX, offsetY, scaleWidth, scaleHeight;
    const outputSize = options.outputSize || basePngSize;

    if (options.useBackground) {
        div.children[0].style.fill = '#FFFFFF';
        const graphicsSizeRatio = options.graphicsSizeRatio || 0.55;
        // draw circle background
        ctx.beginPath();
        ctx.fillStyle = fillColor;
        ctx.strokeStyle = strokeColor;
        ctx.lineWidth = strokeWidth;
        ctx.arc(outputSize / 2, outputSize / 2, (outputSize / 2) - strokeWidth, 0, 2 * Math.PI);
        ctx.fill();
        ctx.stroke();

        const originalSvgSize = div.children[0].height.baseVal.value;
        const graphicsToIconRatio = graphicsSizeRatio * (outputSize / originalSvgSize);
        offsetX = offsetY = ((outputSize - (originalSvgSize * graphicsToIconRatio)) / (2 * graphicsToIconRatio));
        scaleWidth = scaleHeight = originalSvgSize * graphicsToIconRatio;
    } else {
        div.children[0].style.fill = fillColor;
        offsetX = offsetY = 0;
        scaleWidth = scaleHeight = outputSize;
    }

    const serializedString = new XMLSerializer().serializeToString(div.children[0]);
    const canvgOptions = {
        ignoreMouse: true,
        ignoreAnimation: true,
        ignoreDimensions: true,
        ignoreClear: true,
        offsetX,
        offsetY,
        scaleWidth,
        scaleHeight,
    };

    canvg(canvas, serializedString, canvgOptions);
    return (ctx.getImageData(0, 0, outputSize, outputSize));
}
