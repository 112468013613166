//!



const reference = require('../style-spec/reference/latest'); //
const Color = require('../style-spec/util/color');

module.exports = function(map) {

    return {

        _featuresStyles: {
            features: [],
            styles: []
        },

        getFeaturesStyles: function() {
            return this._featuresStyles;
        },

        setFeaturesStyles: function(features, styles, update = true) {

            // Validations
            if (!features) throw new Error('No features passed');
            if (!styles) throw new Error('No styles passed');
            if (features.length !== styles.length) throw new Error('Each feature needs a style');

            const parsedStyles = [];

            for (let f = 0; f < styles.length; f++) {
                const feature = features[f];
                const style = styles[f];
                const parsedStyle = {};
                const type = feature.layer.type;
                const paintReference = reference[`paint_${type}`];

                // For all styles for the current layer type:
                // Copy current styles and override with argument provided features' styles if applicable
                for (const property in paintReference) {
                    if (!paintReference.hasOwnProperty(property)) continue;

                    // Assign default value for style property
                    let value;

                    // Set value for style property if it has been passed through features' styles argument
                    if (style[property] !== undefined) {
                        value = style[property];
                    }

                    // Value does not have default value nor has it been passed through features' styles argument
                    if (value === undefined) continue;

                    // Parse passed feature's style property if it is color
                    if (paintReference[property].type === 'color') {
                        value = Color.parse(value);
                    }

                    parsedStyle[property] = value;
                }

                parsedStyles.push(parsedStyle);
            }

            this._featuresStyles = { features: features, styles: parsedStyles };
            if (update) {
                map._update();
            }
        },

        clearFeaturesStyles: function(update = true) {
            this._featuresStyles = { features: [], styles: [] };
            if (update) {
                map._update();
            }
        }

    };
};
