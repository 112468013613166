import ColorRamp from '../objects/ColorRamp';
import ColorPalette from '../objects/ColorPalette';

class ColorPalettesParser {
    constructor(colorPalettesJSON) {
        this._colorPalettesJSON = colorPalettesJSON;
    }

    get colorPalettes() {
        const parsedColorPalettes = this._colorPalettesJSON.map(colorPaletteJSON => {
            const colorPalette = new ColorPalette();
            colorPalette.id = colorPaletteJSON.id;
            colorPalette.title = colorPaletteJSON.title;
            colorPalette.type = colorPaletteJSON.type;
            colorPalette.insufficientDataColor = colorPaletteJSON['insufficient-data-color'];
            colorPalette.nullDataColor = colorPaletteJSON['null-data-color'];
            if (colorPaletteJSON['color-ramps']) {
                colorPalette.colorRamps = colorPaletteJSON['color-ramps'].map(colorRampJSON => {
                    const colorRamp = new ColorRamp();
                    colorRamp.from = colorRampJSON.from;
                    colorRamp.to = colorRampJSON.to;
                    colorRamp.bias = colorRampJSON.bias || 0;
                    return colorRamp;
                });
            }
            colorPalette.strokeColors = colorPaletteJSON['stroke-colors'] || ['#d6d6d6'];
            colorPalette.colors = colorPaletteJSON.colors;
            return colorPalette;
        });

        // I'm really sorry for this terrible hack...
        return parsedColorPalettes.sort((a, b) => {
            if (a.title === 'Blue') {
                return -1;
            }

            if (b.title === 'Blue') {
                return 1;
            }

            return 0;
        });
    }
}

export default ColorPalettesParser;
