import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import TabbedBrowser from '../tabbedBrowser/TabbedBrowser';
import AboutVariableTab from './AboutVariableTab';
import CitationTab from './CitationTab';
import { focusFirstChild } from '../../helpers/Util';

class DataDictionary extends React.Component {
    componentDidMount() {
        focusFirstChild(this.dataDictionaryContainer);
    }

    render() {
        const { dataDictionaryInfo, metadataSelection, title, intl, onClose } = this.props;
        if (!dataDictionaryInfo || Object.keys(dataDictionaryInfo).length === 0) {
            return null;
        }
        return (
            <div className="data-info" ref={ref => { this.dataDictionaryContainer = ref; }}>
                <div className="data-info__header">
                    <h4>
                        {intl.formatMessage({ id: 'variablePicker.aboutThisVariable' })}
                    </h4>
                    <button className="btn-icon" onClick={onClose}>
                        <i className="material-icons">close</i>
                    </button>
                </div>
                <TabbedBrowser
                    tabNames={[
                        {
                            name: intl.formatMessage({ id: 'info' }),
                        },
                        {
                            name: intl.formatMessage({ id: 'citation' }),
                        },
                    ]}
                    className="grow"
                    tabSwitchedCallback={() => {}}
                >
                    {[
                        <AboutVariableTab
                            key={0} title={title}
                            dataDictionaryInfo={dataDictionaryInfo}
                            metadataSelection={metadataSelection}
                        />,
                        <CitationTab
                            key={1}
                            dataDictionaryInfo={dataDictionaryInfo}
                            metadataSelection={metadataSelection}
                        />,
                    ]}
                </TabbedBrowser>
            </div>);
    }
}

DataDictionary.NAME = 'DataDictionary';

DataDictionary.propTypes = {
    dataDictionaryInfo: PropTypes.object.isRequired,
    metadataSelection: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(DataDictionary);
