import BaseHandler from './BaseHandler';

class ViewHandler extends BaseHandler {
    constructor(mapViewer) {
        super(mapViewer);

        if (this.mapInstance.initialView.boundingBox && this.mapInstance.initialView.applyBoundingBox) {
            this.map.fitBounds(this.mapInstance.initialView.boundingBox, { duration: 100 });
        }

        this.setGluBusEvents({
            MAP_ZOOM_IN_REQUEST: this.onMapZoomInRequest,
            MAP_ZOOM_OUT_REQUEST: this.onMapZoomOutRequest,
            MAP_ZOOM_TO_REQUEST: this.onMapZoomToRequest,
            MAP_SET_MAX_ZOOM_REQUEST: this.onMapSetMaxZoom,
            MAP_SET_MIN_ZOOM_REQUEST: this.onMapSetMinZoom,
            MAP_SET_MIN_MAX_ZOOM_REQUEST: this.onMapSetMinMaxZoom,
            MAP_GET_CURRENT_ZOOM_LEVEL: this.onMapGetCurrentZoomLevel,
            MAP_FIT_BOUNDS_REQUEST: this.onMapFitBoundsRequest,
            MAP_SET_CENTER_REQUEST: this.onMapSetCenterRequest,
            MAP_FLY_TO_REQUEST: this.onMapFlyToRequest,
            MAP_UPDATE_INITIAL_VIEW_SUCCESS: this.onInitialViewUpdated,
        });
    }

    onInitialViewUpdated(e) {
        if (e.mapInstanceId === this.mapInstance.id && this.mapInstance.initialView.boundingBox && this.mapInstance.initialView.applyBoundingBox) {
            this.map.fitBounds(this.mapInstance.initialView.boundingBox, { duration: 100 });
        }
    }

    onMapZoomInRequest(e) {
        if (e.mapInstanceId !== this.mapInstanceId) return;
        this.map.zoomIn();
    }

    onMapZoomOutRequest(e) {
        if (e.mapInstanceId !== this.mapInstanceId) return;
        this.map.zoomOut();
    }

    onMapZoomToRequest(e) {
        if (e.mapInstanceId !== this.mapInstanceId) return;
        this.map.zoomTo(e.zoom, undefined, { isPreciseZoomSlider: e.isPreciseZoomSlider });
    }

    onMapSetMaxZoom(e) {
        if (e.mapInstanceId !== this.mapInstanceId) return;
        this.map.setMaxZoom(e.maxZoom);
        this.emit('MAP_MAX_ZOOM_APPLIED', { source: this.mapViewer, maxZoom: e.maxZoom });
    }

    onMapSetMinZoom(e) {
        if (e.mapInstanceId !== this.mapInstanceId) return;
        this.map.setMinZoom(e.minZoom);
        this.emit('MAP_MIN_ZOOM_APPLIED', { source: this.mapViewer, minZoom: e.minZoom });
    }

    onMapSetMinMaxZoom(e) {
        if (e.mapInstanceId !== this.mapInstanceId) return;
        if (this.map.getMinZoom() < e.minZoom && this.map.getMaxZoom() < e.maxZoom) {
            this.map.setMaxZoom(e.maxZoom);
            this.map.setMinZoom(e.minZoom);
        } else if (this.map.getMinZoom() > e.minZoom && this.map.getMaxZoom() > e.maxZoom) {
            this.map.setMinZoom(e.minZoom);
            this.map.setMaxZoom(e.maxZoom);
        } else {
            this.map.setMaxZoom(e.maxZoom);
            this.map.setMinZoom(e.minZoom);
        }
        this.emit('MAP_MAX_ZOOM_APPLIED', { source: this, maxZoom: e.maxZoom });
        this.emit('MAP_MIN_ZOOM_APPLIED', { source: this, minZoom: e.minZoom });
    }

    onMapGetCurrentZoomLevel(e) {
        if (e.mapInstanceId !== this.mapInstanceId) return;
        this.emit('MAP_CURRENT_ZOOM_LEVEL', { source: this, zoomLevel: this.map.getZoom() });
    }

    onMapFitBoundsRequest(e) {
        if (e.mapInstanceId !== this.mapInstanceId) return;
        this.map.fitBounds(e.bounds, {
            duration: 0,
            animate: false,
            padding: isNaN(e.padding) ? 200 : e.padding,
            offset: e.offset || [0, 0],
        });
    }

    onMapSetCenterRequest(e) {
        if (e.mapInstanceId !== this.mapInstanceId) return;
        const eventData = { center: e.center, duration: 0, animate: false, padding: 200 };

        if (e.zoom !== undefined) {
            eventData.zoom = e.zoom;
        }

        this.map.flyTo(eventData);
    }

    onMapFlyToRequest(e) {
        if (e.mapInstanceId !== this.mapInstanceId) return;
        const eventData = {};
        if (e.hasOwnProperty('lockMode')) {
            eventData.lockMode = e.lockMode;
        }

        this.map.flyTo({
            center: e.center,
            zoom: e.zoom,
        }, eventData);
    }
}

export default ViewHandler;
