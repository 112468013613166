// @ts-check
import BaseController from './BaseController';
import { parseSocexResult, parseHereGeocodeResult } from '../helpers/Search';
import { getFeatureBoundingBox } from '../helpers/Util';

import ApplicationFrameType from '../enums/ApplicationFrameType';
import FrameType from '../enums/FrameType';
import LocationAnalysisType from '../enums/LocationAnalysisType';
import LocationAnalysisItemOrigin from '../enums/LocationAnalysisItemOrigin';

import LocationAnalysisItem from '../objects/LocationAnalysisItem';
import VariableSelectionItem from '../objects/VariableSelectionItem';
import VariableSelection from '../objects/VariableSelection';
import InitialView from '../objects/InitialView';

import ProjectDataSource from '../dataSources/ProjectDataSource';
import MetadataDataSource from '../dataSources/MetadataDataSource';
import CustomMapSelectionDataSource from '../dataSources/CustomMapSelectionDataSource';

class OverrideController extends BaseController {
    static get name() {
        return 'OverrideController';
    }

    static getInstance(options) {
        return new OverrideController(options);
    }

    onActivate() {
        // TODO:
        // We have to agree on which of these options should be mutually excluded
        // For example you can not open the location analysis and data browser
        // in the same time.

        this.projectDataSource = this.activateSource(ProjectDataSource);
        this.metadataDataSource = this.activateSource(MetadataDataSource);
        this.customMapSelectionDataSource = this.activateSource(
            CustomMapSelectionDataSource,
        );

        /** @type {import('../../../').OverrideProjectOptions} */
        this._overrideProjectOptions = {};

        // Override location and location analysis if set
        // In order to override location analysis, the location must be set as well
        if (this.props.location) {
            this.handleLocationOverride();
        }

        this.projectDataSource.setOverrideProjectOptions(
            this._overrideProjectOptions,
        );

        // Override variable
        if (this.props.variable) {
            this.bus.once('PROJECT_LOAD_SUCCESS', this.handleVariable);
        }
        // Override data browser
        if (this.props.dataBrowser) {
            // For now we can only override the data browser state for single map frame
            this.bus.once('SINGLE_MAP_FRAME_MOUNTED', this.handleDataBrowser);
        }
        // Override frame if set
        if (this.props.frame) {
            this.bus.once(
                'FRAME_CONTAINER_MOUNTED',
                this.handleApplicationFrame,
            );
        }
    }

    onDeactivate() {
        this.unbindGluBusEvents();
    }

    handleLocationOverride = async () => {
        // Parse location result
        let result;
        switch (this.props.location.searchProvider) {
        case 'here':
            result = parseHereGeocodeResult(
                this.props.location.searchResult,
            );
            break;
        case 'socex':
            result = parseSocexResult(this.props.location.searchResult);
            break;
        default:
            break;
        }

        let locationAnalysisItem;
        if (this.props.locationAnalysis) {
            this.closeDefaultTourPopup();
            const {
                analysisTypeId,
                analysisValues,
                insights,
            } = this.props.locationAnalysis;
            // Set location analysis item
            locationAnalysisItem = new LocationAnalysisItem({
                id: result.id,
                type: result.type,
                value: result.value,
                point: result.point,
                analysisTypeId,
                selection: new Set(analysisValues),
                itemOrigin: LocationAnalysisItemOrigin.PROJECT_DEFINITION_LOCATION,
                icon: result.icon,
            });
            this._overrideProjectOptions.searchResults = result;
            this._overrideProjectOptions.locationAnalysisItem = locationAnalysisItem;

            // Save all custom values for analysis
            const analysisType = LocationAnalysisType[analysisTypeId];
            analysisValues
                .filter(value => !analysisType.VALUES_ARRAY.includes(value))
                .forEach(value => {
                    this.bus.emit('SAVE_CUSTOM_LOCATION_ANALYSIS_VALUE_REQUEST', {
                        value,
                        analysisTypeUnit: analysisType.UNIT,
                    });
                });

            const locationItemContourData = await this.customMapSelectionDataSource.getContourFeatures(
                locationAnalysisItem.point,
                locationAnalysisItem.contourType,
                locationAnalysisItem.sortedSelectionAsArray,
                true,
            );
            const features = Object.values(locationItemContourData[locationAnalysisItem.contourType]);
            const featureBoundingBox = getFeatureBoundingBox(features);

            // Set initial view override based on the radius/isochrone BBox
            this._overrideProjectOptions.initialView = new InitialView({
                zoom: 14, // falsy zoom... figure out what to do with it
                centerLat: result.point.lat,
                centerLng: result.point.lng,
                boundingBoxNeLat: featureBoundingBox.yMax,
                boundingBoxNeLng: featureBoundingBox.xMax,
                boundingBoxSwLat: featureBoundingBox.yMin,
                boundingBoxSwLng: featureBoundingBox.xMin,
            });

            if (insights) {
                /** @type {import('../../../types').VisualReport} */
                const visualReport = {
                    title: locationAnalysisItem.value,
                    point: locationAnalysisItem.point,
                    selection: analysisValues,
                    profile: locationAnalysisItem.analysisType.VISUAL_REPORT_PROFILE,
                    additionalParams: insights.additionalParams,
                    report: insights.report,
                };
                // Insights are available only for single map frame
                this.bus.once('SINGLE_MAP_FRAME_MOUNTED', () => {
                    this.bus.emit('SHOW_INSIGHTS', visualReport);
                });
            }
        } else if (result.boundingBox) {
            this._overrideProjectOptions.initialView = new InitialView({
                zoom: 14,
                centerLat: result.point.lat,
                centerLng: result.point.lng,
                boundingBoxNeLat: result.boundingBox._ne.lat,
                boundingBoxNeLng: result.boundingBox._ne.lng,
                boundingBoxSwLat: result.boundingBox._sw.lat,
                boundingBoxSwLng: result.boundingBox._sw.lng,
            });
        } else {
            this._overrideProjectOptions.initialView = new InitialView({
                zoom: 14,
                centerLat: result.point.lat,
                centerLng: result.point.lng,
            });
        }
        this.bus.emit('MINIMIZE_LEGEND');
    };

    handleVariable = async () => {
        // Note: The initial ide was to override the initial project data theme
        // but this is not an easy task to do, since the data theme contains a lot
        // more info, example: rendering, dollar adjustment, bubbleValueType, etc...
        // Figuring this out for the new predefined variable is a complex task
        // and because of that we will leave this implementation
        // This implementation simulates the variable click from the data browser
        // and it's possible we have to wait for some metadata to be loaded.
        // This means if the variable that should be shown is from a survey that
        // is not the default project survey, we'll show the default variable first
        // and then we will show the one from the override options. This is a noticeable
        // change for the user. It will not be so noticeable if the original project variable
        // and the override variable are from the same survey
        // TODO: Figure out if we can stall the map rendering in case the override exists
        // until all necessary metadata is loaded
        const {
            surveyName,
            datasetAbbreviation,
            variableGuids,
            tableName,
        } = this.props.variable;
        // First let's load the survey in case it's missing
        await this.metadataDataSource.loadSurveys([surveyName]);
        // Let's override the variable for the first map instance for now
        const mapInstanceId = this.projectDataSource.currentFrame
            .mapInstances[0].id;

        this.bus.once('DATASET_LOAD_SUCCESS', () => {
            this.bus.once('TABLE_LOAD_SUCCESS', tableLoadPayload => {
                const variableSelection = new VariableSelection();
                variableSelection.items = [];

                variableGuids.forEach(variableGuid => {
                    const variableSelectionItem = new VariableSelectionItem({
                        surveyName,
                        datasetAbbreviation,
                        variableGuid,
                        tableGuid: tableLoadPayload.table.uuid,
                    });
                    variableSelection.items.push(variableSelectionItem);
                });

                this.bus.emit('DATA_SELECTION_CHANGE', {
                    source: this,
                    mapInstanceId,
                    newDataSelection: { variableSelection },
                });
            });

            this.bus.emit('TABLE_LOAD_REQUEST', {
                surveyName,
                datasetName: datasetAbbreviation,
                tableName,
                mapInstanceId,
                source: this,
            });
        });
        this.bus.emit('DATASET_LOAD_REQUEST', {
            surveyName,
            datasetName: datasetAbbreviation,
            mapInstanceId,
            source: this,
        });
    };

    handleDataBrowser = () => {
        this.bus.emit('SHOW_DATA_BROWSER');
        this.bus.once('METADATA_LOAD_SUCCESS', payload => {
            let dataCategorySelection;
            if (this.props.dataBrowser.dataCategoryName) {
                const { mapsGroupDataCategories } = payload;
                const dataCategory = mapsGroupDataCategories.find(
                    c => c.name === this.props.dataBrowser.dataCategoryName,
                );
                if (!dataCategory) return;
                const year = dataCategory.availableYears.includes(
                    this.props.dataBrowser.year,
                )
                    ? this.props.dataBrowser.year
                    : dataCategory.availableYears.slice(-1)[0];
                dataCategorySelection = {
                    dataCategory,
                    year,
                };
            }
            // Override default selected tab
            this.bus.emit('OVERRIDE_DATA_BROWSER_STATE', {
                selectedTab: this.props.dataBrowser.selectedTab || 'CATEGORY',
                year: this.props.dataBrowser.year,
                dataCategorySelection,
            });
        });
        this.bus.off('SINGLE_MAP_FRAME_MOUNTED', this.handleDataBrowser);
    };

    /**
     * @param {object} param0
     * @param {import('../objects/MapInstance').default[]} param0.mapInstances
     */
    handleApplicationFrame = ({ mapInstances }) => {
        // Don't handle anything in case of multiple map instances
        if (mapInstances.length > 1) return;
        // Otherwise change frame
        const mapInstanceId = mapInstances[0].id;

        switch (this.props.frame) {
        case ApplicationFrameType.REPORT_EDITOR:
            this.closeDefaultTourPopup();
            this.bus.emit('ENTER_REPORT_MODE', {
                source: this,
                mapInstanceId,
            });
            break;
        case ApplicationFrameType.DATA_FILTER_EDITOR:
            this.closeDefaultTourPopup();
            this.bus.emit('ENTER_DATA_FILTER_MODE', {
                source: this,
                mapInstanceId,
            });
            this.bus.once('MAP_LOAD', payload => {
                if (payload.source.mapInstance.id !== mapInstanceId) return;
                this.bus.emit('ADD_NEW_FILTER_REQUEST', { mapInstanceId });
                this.bus.emit('FILTER_SET_ACTIVE', {
                    activeFilterId: undefined,
                });
            });
            break;
        case ApplicationFrameType.SIDE_BY_SIDE_MAPS:
            this.bus.emit('CHANGE_PROJECT_FRAME_TYPE_REQUEST', {
                frameType: FrameType.SIDE_BY_SIDE_MAPS,
            });
            break;
        case ApplicationFrameType.SWIPE:
            this.bus.emit('CHANGE_PROJECT_FRAME_TYPE_REQUEST', {
                frameType: FrameType.SWIPE,
            });
            break;
        }
        this.bus.off('FRAME_CONTAINER_MOUNTED', this.handleApplicationFrame);
    };

    closeDefaultTourPopup = () => {
        // We are opening the 'Take a tour' popup for users that are for
        // the first time opening the map app. This popup is designed the way that it can
        // be only show in cases where some components need to be available on the screen
        // (for example the data browser component)
        // With this override options we can get to the situation where we open the 'FILTER PANEL' (for example)
        // for users that are our first time users, and the tour will cause the app to crash, since it can not
        // find the necessary DOM components.
        // Because of that, we will emit the HELP_TOUR_STATUS_UPDATE_REQUEST with isDone set to true, so that
        // the tour popup is not shown.
        // Note: This has to be changed eventually, when our designers find out when is the best time to show
        // this popup when override is active for first time users.
        this.bus.emit('HELP_TOUR_STATUS_UPDATE_REQUEST', { isDone: true });
    };
}

export default OverrideController;
