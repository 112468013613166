import React from 'react';
import { injectIntl } from 'react-intl';
import BusComponent from '../BusComponent';
import googleTagManagerEvents from '../../helpers/GoogleTagManagerEventsWrapper';
import { openMissingFacilityFormEvents } from '../../enums/GoogleTagManagerEvents';

const OpenMissingFacilityFormButton = googleTagManagerEvents('button', openMissingFacilityFormEvents);

class ReportMissingFacilityButton extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            userInfo: {},
        };
        this.bindGluBusEvents({
            USER_INFO_LOAD_SUCCESS: this.onUserInfo,
        });
    }

    componentDidMount() {
        this.bus.once('USER_INFO_GET_SUCCESS', this.onUserInfo);
        this.emit('USER_INFO_GET_REQUEST', { source: this });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
        this.bus.off('USER_INFO_GET_SUCCESS', this.onUserInfo);
    }

    onUserInfo = (userInfo, source) => {
        if (source && this !== source) return;
        this.setState({ userInfo });
    };

    onClick = () => {
        this.emit('SUBMIT_MISSING_FACILITY_POPUP_REQUEST');
    };

    render() {
        const { userInfo } = this.state;
        if (!userInfo || !userInfo.isLogged) return null;
        return (<OpenMissingFacilityFormButton
            className="report-missing-facility-button"
            onClick={this.onClick}
            aria-label={this.props.intl.formatMessage({ id: 'modals.submitFacility.reportMissing' })}
        >
            {this.props.intl.formatMessage({ id: 'modals.submitFacility.reportMissing' })}
        </OpenMissingFacilityFormButton>);
    }
}
export default injectIntl(ReportMissingFacilityButton);
