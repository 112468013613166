import React from 'react';
import BusComponent from '../../BusComponent';

import classnames from 'classnames';

class GeoSelectionListItem extends BusComponent {
    onRemove = () => {
        if (this.props.onRemove) {
            this.props.onRemove(this.props.geo);
        }
    }

    render() {
        return (<div className={classnames('selected-geo flex-it center space-between', this.props.className)}>
            <span className="body-1 selected-geo__title">{this.props.geo.title}</span>
            <button className="btn-flat-icon flex-it no-shrink" onClick={this.onRemove}>
                <i className="material-icons" aria-label="Remove">clear</i>
            </button>
        </div>);
    }
}

export default GeoSelectionListItem;

