export const HELP_TOUR_STEPS_NUMBER = 6;

export const HelpTourTargets = {
    WELCOME: 'tour-target-welcome',
    MAP_SEARCH: 'tour-target-map-search',
    MAP_PANEL: 'tour-target-map-panel',
    MAP_SEARCH_PANEL_TRIGER: 'tour-target-map-search-panel-trigger',
    MAP_PANEL_GEOGRAPHY: 'tour-target-map-panel-geography',
    MAP_PANEL_VISUALIZATION: 'tour-target-map-panel-visualization',
    MAP_PANEL_SETTINGS: 'tour-target-map-panel-settings',
    MAP_LEGEND: 'tour-target-map-legend',
    MAP_FRAME_TYPES: 'tour-target-map-frame-types',
};

export const HelpTourWelcomeOptions = {
    targetId: HelpTourTargets.WELCOME,
    position: {
        marginTop: 150,
        anchorOrigin: { horizontal: 'middle', vertical: 'top' },
        targetOrigin: { horizontal: 'middle', vertical: 'top' },
    },
};

export const DEFAULT_ARROW_OFFSET = 32;
const DEFAULT_MARGIN_OFFSET = 30;

export const HelpTourStepsOptions = [
    {
        index: 0,
        targetId: HelpTourTargets.MAP_SEARCH_PANEL_TRIGER,
        title: 'Get started with your analysis',
        description: 'To get started, enter a location for analysis. \n' +
        'Selecting the location will open up a panel for drive time and radius analysis. \n' +
        'Once you’ve selected the area for analysis, you will be able to download storage and housing data or create demographic and opportunity reports for your area of interest. \n',
        note: 'Note: You can save your search results as an annotation.',
        classes: 'arrow-pointer arrow-west arrow-top stretched',
        position: {
            marginLeft: 20,
            anchorOrigin: { horizontal: 'right', vertical: 'top' },
            targetOrigin: { horizontal: 'left', vertical: 'top' },
        },
        isFirstStep: true,
        verticalCentering: true,
    },
    {
        index: 1,
        targetId: HelpTourTargets.MAP_PANEL_GEOGRAPHY,
        highlightId: HelpTourTargets.MAP_PANEL,
        title: 'View data by state, county, zipcode and more',
        description: 'The geography level displayed changes automatically based on the map zoom level, but you can also manually select the one you are interested in.',
        classes: 'arrow-pointer arrow-north arrow-left',
        position: {
            marginTop: DEFAULT_MARGIN_OFFSET,
            anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
            targetOrigin: { horizontal: 'left', vertical: 'top' },
        },
    },
    {
        index: 2,
        targetId: HelpTourTargets.MAP_PANEL_VISUALIZATION,
        highlightId: HelpTourTargets.MAP_PANEL,
        title: 'Visualize data as a shaded area, bubbles or dot density',
        description: 'Some variables will not have all three options available because of the nature of the data. Bubbles and dot density are used for counts, and shading is used for percentages, medians, rates, averages or other area normalized measures.',
        classes: 'arrow-pointer arrow-north arrow-left',
        position: {
            marginTop: DEFAULT_MARGIN_OFFSET,
            anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
            targetOrigin: { horizontal: 'left', vertical: 'top' },
        },
    },
    {
        index: 3,
        targetId: HelpTourTargets.MAP_PANEL_SETTINGS,
        highlightId: HelpTourTargets.MAP_PANEL,
        title: 'Use options menu to do more with your map',
        description: 'Mask the map, upload your point data, turn map layers on and off (including satellite view) or add custom annotations.',
        classes: 'arrow-pointer arrow-north arrow-left',
        position: {
            marginTop: DEFAULT_MARGIN_OFFSET,
            anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
            targetOrigin: { horizontal: 'left', vertical: 'top' },
        },
    },
    {
        index: 4,
        targetId: HelpTourTargets.MAP_LEGEND,
        title: 'Customize map colors and ranges',
        description: 'Use the map legend to highlight geographies matching data ranges it represents or click on it to change the colors and edit the cut points. Easily switch to a detailed legend view or hide it altogether.',
        classes: 'arrow-pointer arrow-east',
        position: {
            marginLeft: -DEFAULT_MARGIN_OFFSET,
            anchorOrigin: { horizontal: 'left', vertical: 'top' },
            targetOrigin: { horizontal: 'right', vertical: 'top' },
        },
    },
    {
        index: 5,
        targetId: HelpTourTargets.MAP_FRAME_TYPES,
        title: 'Dive into double-map view',
        description: 'To see relationships between variables, illustrate change over time or contrasts between geographies switch to side-by-side or swipe maps.',
        classes: 'arrow-pointer arrow-north arrow-left stretched',
        position: {
            marginTop: DEFAULT_MARGIN_OFFSET,
            anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
            targetOrigin: { horizontal: 'left', vertical: 'top' },
        },
        isLastStep: true,
    },
];
