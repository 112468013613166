// @ts-check
import RendererType from '../enums/RendererType';
import VisualizationType from '../enums/VisualizationType';
import VariableValueType from '../enums/VariableValueType';
import DataClassificationMethod from '../enums/DataClassificationMethod';
import { parseFieldName } from '../helpers/Util';
import VariableSelectionItem from './VariableSelectionItem';

class DataTheme {
    constructor(values) {
        this._rendering = [];
        this._bubbleValueType = VariableValueType.NUMBER;
        this._shadedValueType = VariableValueType.PERCENT;

        if (values) {
            Object.keys(values).forEach(k => {
                if (this.constructor.prototype.hasOwnProperty(k)) {
                    this[k] = values[k];
                }
            });
        }
    }

    /** @return {string} */
    get id() {
        return this._id;
    }

    set id(id) {
        this._id = id;
    }

    /** @return {string} */
    get title() {
        return this._title;
    }

    set title(title) {
        this._title = title;
    }

    get colorPaletteId() {
        return this._colorPaletteId;
    }

    set colorPaletteId(colorPaletteId) {
        this._colorPaletteId = colorPaletteId;
    }

    get colorPaletteType() {
        return this._colorPaletteType;
    }

    set colorPaletteType(colorPaletteType) {
        this._colorPaletteType = colorPaletteType;
    }

    /** @returns {'true' | 'false'} */
    get colorPaletteFlipped() {
        return this._colorPaletteFlipped;
    }

    set colorPaletteFlipped(colorPaletteFlipped) {
        this._colorPaletteFlipped = colorPaletteFlipped;
    }

    /** @returns {import('./VariableSelection').default} */
    get variableSelection() {
        return this._variableSelection;
    }

    set variableSelection(variableSelection) {
        this._variableSelection = variableSelection;
    }

    /** @returns {string | undefined} */
    get insufficientBase() {
        return this._insufficientBase;
    }

    set insufficientBase(insufficientBase) {
        this._insufficientBase = insufficientBase;
    }

    get rendering() {
        return this._rendering;
    }

    /** @returns {(string | undefined)} */
    get adjustmentDollarYear() {
        return this._adjustmentDollarYear;
    }

    set adjustmentDollarYear(adjustmentDollarYear) {
        this._adjustmentDollarYear = adjustmentDollarYear;
    }

    set rendering(rendering) {
        this._rendering = rendering;
        if (this.rendering[0] && (this.rendering[0].type === RendererType.DOT_DENSITY ||
                                  this.rendering[0].type === RendererType.VALUE_DOT_DENSITY)) {
            this.rendering[0].dotDensityValueHint = this.dotDensityValueHint;
        }
    }

    get bubbleValueType() {
        return this._bubbleValueType;
    }

    set bubbleValueType(bubbleValueType) {
        this._bubbleValueType = bubbleValueType;
    }

    get shadedValueType() {
        return this._shadedValueType;
    }

    set shadedValueType(shadedValueType) {
        this._shadedValueType = shadedValueType;
    }

    get dotDensityValueHint() {
        return this._dotDensityValueHint;
    }

    set dotDensityValueHint(dotDensityValueHint) {
        this._dotDensityValueHint = dotDensityValueHint;
        if (this.rendering[0] && (this.rendering[0].type === RendererType.DOT_DENSITY ||
                                  this.rendering[0].type === RendererType.VALUE_DOT_DENSITY)) {
            this.rendering[0].dotDensityValueHint = dotDensityValueHint;
        }
    }

    /** @returns {import('./FilterSet').default} */
    get filterSet() {
        return this._filterSet;
    }

    set filterSet(filterSet) {
        this._filterSet = filterSet;
    }

    get dataClassificationMethod() {
        return this._dataClassificationMethod || DataClassificationMethod.CATEGORY_DEFAULT;
    }

    set dataClassificationMethod(method) {
        this._dataClassificationMethod = method;
    }

    get isChangeOverTimeApplied() {
        return !!this.rendering[0].fieldList.fields.find(f => f.isChangeOverTimeField);
    }

    get appliedChangeOverTimeFieldName() {
        const changeOverTimeField = this.rendering[0].fieldList.fields.find(f => f.isChangeOverTimeField);
        if (changeOverTimeField) {
            return changeOverTimeField.fieldName;
        }
        return undefined;
    }

    get appliedChangeOverTimeType() {
        const changeOverTimeField = this.rendering[0].fieldList.fields.find(f => f.isChangeOverTimeField);
        if (changeOverTimeField) {
            return changeOverTimeField.computeFunction;
        }
        return undefined;
    }

    get appliedChangeOverTimePointOfReference() {
        const changeOverTimeField = this.rendering[0].fieldList.fields.find(f => f.isChangeOverTimeField);
        if (changeOverTimeField) {
            const refField = this.rendering[0].fieldList.fields.find(f => f.qualifiedName === changeOverTimeField.pointOfReferenceFieldQName);
            return new VariableSelectionItem({
                surveyName: refField.surveyName,
                datasetAbbreviation: refField.datasetAbbreviation,
                tableGuid: refField.tableUuid,
                variableGuid: refField.fieldName,
            });
        }
        return undefined;
    }

    get appliedChangeOverTimeSurveyTitle() {
        const changeOverTimeField = this.rendering[0].fieldList.fields.find(f => f.isChangeOverTimeField);
        if (changeOverTimeField) {
            return changeOverTimeField.label;
        }
        return undefined;
    }

    get appliedChangeOverTimeCompareSelection() {
        const changeOverTimeField = this.rendering[0].fieldList.fields.find(f => f.isChangeOverTimeField);
        if (changeOverTimeField) {
            const refField = this.rendering[0].fieldList.fields.find(f => f.qualifiedName === changeOverTimeField.fieldNumerator);
            return new VariableSelectionItem({
                surveyName: refField.surveyName,
                datasetAbbreviation: refField.datasetAbbreviation,
                tableGuid: refField.tableUuid,
                variableGuid: refField.fieldName,
            });
        }
        return undefined;
    }

    get visualizationType() {
        if (!this.rendering || this.rendering.length < 1) return undefined;

        switch (this.rendering[0].type) {
        case RendererType.VALUE:
        case RendererType.REPORT:
        case RendererType.MULTI_VALUE:
            return VisualizationType.SHADED_AREA;
        case RendererType.BUBBLE:
            return VisualizationType.BUBBLES;
        case RendererType.DOT_DENSITY:
        case RendererType.VALUE_DOT_DENSITY:
            return VisualizationType.DOT_DENSITY;
        default:
            return undefined;
        }
    }

    get isShadedVisualization() {
        return this.visualizationType === VisualizationType.SHADED_AREA;
    }

    get isBubblesVisualization() {
        return this.visualizationType === VisualizationType.BUBBLES;
    }

    get isDotDensityVisualization() {
        return this.visualizationType === VisualizationType.DOT_DENSITY;
    }

    get isVisualizationNumber() {
        return this.isBubblesNumber || this.isShadedNumber;
    }

    get isBubblesPercent() {
        return this.isBubblesVisualization && this.bubbleValueType === VariableValueType.PERCENT;
    }

    get isBubblesNumber() {
        return this.isBubblesVisualization && this.bubbleValueType === VariableValueType.NUMBER;
    }

    get isShadedPercent() {
        return this.isShadedVisualization && this.shadedValueType === VariableValueType.PERCENT;
    }

    get isShadedNumber() {
        return this.isShadedVisualization && this.shadedValueType === VariableValueType.NUMBER;
    }

    /**
     * For bubble and shaded return appropriate type otherwise return undefined
     * @returns {string}
     */
    get currentVisualizationValueType() {
        if (this.isShadedVisualization) return this.shadedValueType;
        if (this.isBubblesVisualization) return this.bubbleValueType;
        return undefined;
    }

    get surveyName() {
        if (this.variableSelection && this.variableSelection.items && this.variableSelection.items[0]) {
            return this.variableSelection.items[0].surveyName;
        }
        const geoField = this.rendering[0].fieldList.fields.find(field => field.isGeoNameField);
        if (geoField) {
            return geoField.surveyName;
        }
        return undefined;
    }

    get isVisualizationCategorical() {
        if (!this.rendering[0]) return false;
        const renderer = this.rendering[0];
        let rules, nullRuleIdx, insufficientRuleIdx;
        if (renderer.rules && renderer.rules[0] && Array.isArray(renderer.rules[0])) {
            rules = renderer.rules[0];
            nullRuleIdx = renderer.nullDataRuleIndex[0];
            insufficientRuleIdx = renderer.insufficientDataRuleIndex[0];
        } else {
            rules = renderer.rules;
            nullRuleIdx = renderer.nullDataRuleIndex;
            insufficientRuleIdx = renderer.insufficientDataRuleIndex;
        }
        return rules && rules.find((rule, idx) => {
            if (idx === nullRuleIdx || idx === insufficientRuleIdx || !rule.filter) return false;
            return rule.filter.isCategorical;
        }) !== undefined;
    }

    getVisualizationValueType(visualizationType) {
        if (visualizationType === VisualizationType.SHADED_AREA) return this.shadedValueType;
        else if (visualizationType === VisualizationType.BUBBLES) return this.bubbleValueType;
        return undefined;
    }

    getRuleForData(data, variableGuid) {
        if (!this.rendering[0]) return undefined;
        const renderer = this.rendering[0];
        let rules;
        if (renderer.type === RendererType.MULTI_VALUE) {
            const rulesIdx = renderer.valuesFieldsNames.findIndex(fieldName => {
                const valueVariableGuid = parseFieldName(fieldName).variableGuid;
                return valueVariableGuid === variableGuid || valueVariableGuid === `X${variableGuid}`;
            });
            rules = renderer.rules[rulesIdx];
        } else {
            rules = renderer.rules;
        }
        if (rules) {
            return rules.find(rule => {
                if (rule.filter && rule.filter.fieldName) {
                    const filterVariableGuid = parseFieldName(rule.filter.fieldName).variableGuid;
                    return filterVariableGuid === variableGuid && rule.filter.matchValue(data[filterVariableGuid]);
                }
                return false;
            });
        }
        return undefined;
    }

    applyColorPalette(colorPalette, isFlipped) {
        this.colorPaletteId = colorPalette.id;
        this.colorPaletteType = colorPalette.type;
        this.colorPaletteFlipped = isFlipped !== undefined ? isFlipped : this.colorPaletteFlipped;
        this.rendering.forEach(renderer => renderer.applyColorPalette(colorPalette, this.colorPaletteFlipped));
    }

    equals(that) {
        return (
            this.id === that.id &&
            this.title === that.title &&
            this.colorPaletteId === that.colorPaletteId &&
            this.colorPaletteType === that.colorPaletteType &&
            this.colorPaletteFlipped === that.colorPaletteFlipped &&
            this.variableSelection.equals(that.variableSelection) &&
            this.adjustmentDollarYear === that.adjustmentDollarYear &&
            this.rendering.every((renderer, index) => renderer.equals(that.rendering[index])) &&
            this.bubbleValueType === that.bubbleValueType &&
            this.shadedValueType === that.shadedValueType &&
            this.dotDensityValueHint === that.dotDensityValueHint &&
            ((!this.filterSet && !that.filterSet) || (this.filterSet && that.filterSet && this.filterSet.equals(that.filterSet))) &&
            this.insufficientBase === that.insufficientBase &&
            this.dataClassificationMethod === that.dataClassificationMethod);
    }

    clone() {
        return new DataTheme({
            id: this.id,
            title: this.title,
            colorPaletteId: this.colorPaletteId,
            colorPaletteType: this.colorPaletteType,
            colorPaletteFlipped: this.colorPaletteFlipped,
            adjustmentDollarYear: this.adjustmentDollarYear,
            variableSelection: this.variableSelection.clone(),
            rendering: this.rendering.map(renderer => renderer.clone()),
            bubbleValueType: this.bubbleValueType,
            shadedValueType: this.shadedValueType,
            dotDensityValueHint: this.dotDensityValueHint,
            filterSet: this.filterSet ? this.filterSet.clone() : undefined,
            insufficientBase: this.insufficientBase,
            dataClassificationMethod: this.dataClassificationMethod,
        });
    }

    toJSON() {
        return {
            id: this.id,
            title: this.title,
            colorPaletteId: this.colorPaletteId,
            colorPaletteType: this.colorPaletteType,
            colorPaletteFlipped: this.colorPaletteFlipped,
            adjustmentDollarYear: this.adjustmentDollarYear,
            variableSelection: this.variableSelection.toJSON(),
            rendering: this.rendering.map(renderer => renderer.toJSON()),
            bubbleValueType: this.bubbleValueType,
            shadedValueType: this.shadedValueType,
            dotDensityValueHint: this.dotDensityValueHint,
            filterSet: this.filterSet ? this.filterSet.toJSON() : undefined,
            insufficientBase: this.insufficientBase,
            dataClassificationMethod: this.dataClassificationMethod,
        };
    }
}

export default DataTheme;
