import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class HorizontalSwiper extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            swipeKnobVisible: true,
        };
    }

    handleMouseMove = e => {
        const { left, width } = this.props.root.getBoundingClientRect();
        const clientX = e.type === 'touchmove' ? e.touches[0].clientX : e.clientX;
        const positionX = clientX - left;

        this.props.onChange(Math.min(Math.max(0, positionX / width), 1));
    };

    handleMouseDown = () => {
        document.addEventListener('mousemove', this.handleMouseMove);
        document.addEventListener('mouseup', this.handleMouseUp);
        document.addEventListener('touchmove', this.handleMouseMove);
        document.addEventListener('touchend', this.handleMouseUp);

        this.setState({
            swipeKnobVisible: false,
        });

        if (this.props.onStart) {
            this.props.onStart();
        }
    };

    handleMouseUp = () => {
        document.removeEventListener('mousemove', this.handleMouseMove);
        document.removeEventListener('mouseup', this.handleMouseUp);
        document.removeEventListener('touchmove', this.handleMouseMove);
        document.removeEventListener('touchend', this.handleMouseUp);

        this.setState({
            swipeKnobVisible: true,
        });

        if (this.props.onStop) {
            this.props.onStop();
        }
    };

    render() {
        if (!this.props.root) return null;

        const swipeKnobClasses = classNames('knob-wrapper clickable', { visible: this.state.swipeKnobVisible });
        return (<div className="swiper" ref={c => (this.domDone = c)} style={{ left: `${this.props.value * 100}%` }}>
            <div className={swipeKnobClasses} onMouseDown={this.handleMouseDown} onTouchStart={this.handleMouseDown}>
                <i className="socex-icon-knob-wrapper-swipe knob-wrapper__swipe-icon" />
            </div>
        </div>);
    }
}

HorizontalSwiper.propTypes = {
    root: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.number,
    onStop: PropTypes.func,
    onStart: PropTypes.func,
};

HorizontalSwiper.defaultProps = {
    value: 0.5,
    onStart: undefined,
    onStop: undefined,
    root: undefined,
};

export default HorizontalSwiper;
