/* eslint class-methods-use-this: 0 */

class LabelRenderer {
    get type() {
        return 'LabelRenderer';
    }

    get fieldNames() {
        return this._fieldNames;
    }

    set fieldNames(fieldNames) {
        this._fieldNames = fieldNames;
    }

    get priorityFieldName() {
        return this._priorityFieldName;
    }

    set priorityFieldName(priorityFieldName) {
        this._priorityFieldName = priorityFieldName;
    }

    get labelFieldName() {
        return this._labelFieldName;
    }

    set labelFieldName(labelFieldName) {
        this._labelFieldName = labelFieldName;
    }

    get symbols() {
        return this._symbols;
    }

    set symbols(symbols) {
        this._symbols = symbols;
    }

    get rules() {
        return this._rules;
    }

    set rules(rules) {
        this._rules = rules;
    }

    get visibility() {
        return this._visibility;
    }

    set visibility(visibility) {
        this._visibility = visibility;
    }

    clone() {
        const labelRenderer = new LabelRenderer();
        labelRenderer.fieldNames = this.fieldNames;
        labelRenderer.priorityFieldName = this.priorityFieldName;
        labelRenderer.labelFieldName = this.labelFieldName;
        labelRenderer.symbols = this.symbols.map(sym => sym.clone());
        labelRenderer.rules = this.rules.map(rule => rule.clone());
        labelRenderer.visibility = this.visibility.map(vis => vis.clone());

        return labelRenderer;
    }

    toJSON() {
        return {
            type: this.type,
            fieldNames: this.fieldNames,
            priorityFieldName: this.priorityFieldName,
            labelFieldName: this.labelFieldName,
            symbols: this.symbols.map(sym => sym.toJSON()),
            rules: this.rules.map(rule => rule.toJSON()),
            visibility: this.visibility.map(vis => vis.toJSON()),
        };
    }
}

export default LabelRenderer;
