import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import NumberFormat from '../../../enums/NumberFormat';
import LegendLayout from '../../../enums/LegendLayout';
import Orientation from '../../../enums/Orientation';
import format from '../../../helpers/NumberFormatter';
import { roundNumberToPrecision } from '../../../helpers/Util';

import BubbleControls from './BubbleControls';
import BubbleTitle from './BubbleTitle';
import BubblePreviewInfo from './BubblePreviewInfo';
import BubbleColorPalette from './BubbleColorPalette';
import BubbleGraphicsDetailed from './BubbleGraphicsDetailed';
import PagedBrowser from '../../PagedBrowser';
import LayerLibraryLegend from '../../layerLibrary/legend/LayerLibraryLegend';
import AnnotationLegend from '../../mapAnnotator/legend/AnnotationLegend';
import DataFilterLegend from '../../dataFilter/DataFilterLegend';
import SatelliteLegend from '../layersLegend/SatelliteLegend';
import OpportunityZonesLegend from '../layersLegend/OpportunityZonesLegend';
import SchoolRankLegend from '../layersLegend/SchoolRankLegend';
import AutoAdjust from '../autoAdjust/AutoAdjust';
import BaseMap from '../../../enums/BaseMapTypes';

const CUSTOM_LIBRARY_LAYER_IDS = ['school_digger', 'opportunity_zones', BaseMap.STREET];

const RADII = {
    lowCircleRadius: 16,
    mediumCircleRadius: 28,
    highCircleRadius: 40,
    veryHighCircleRadius: 60,
};

const BubbleDetailed = props => {
    const {
        mapInstance,
        orientation,
        renderer,
        currentBubbleSizeLog10,
        onBubbleSizeChange,
        onAdjustBubblesClick,
        bubbleValueType,
        variableGuid,
        maxBubbleLabel,
        hasDataFilter,
        onDataFilterInfoClick,
        filterInfoOpen,
        onEditSatelliteDetailsClick,
        onCloseSatelliteDetails,
        annotationLegend,
        libraryLegendInfo,
    } = props;
    const { sizeTitle, rules, bubbleSizeFactor } = renderer;

    const legendHighlighted = rules.some(rule => rule.highlighted);
    const veryHighCircleLabel = `${format({
        number: roundNumberToPrecision(
            ((RADII.veryHighCircleRadius ** 2) * Math.PI) / bubbleSizeFactor,
        ),
        numberFormat: NumberFormat.FORMAT_NUMBER_NO_DECIMAL,
    })}`;
    const highCircleLabel = format({
        number: roundNumberToPrecision(
            ((RADII.highCircleRadius ** 2) * Math.PI) / bubbleSizeFactor,
        ),
        numberFormat: NumberFormat.FORMAT_NUMBER_NO_DECIMAL,
    });
    const mediumCircleLabel = format({
        number: roundNumberToPrecision(
            ((RADII.mediumCircleRadius ** 2) * Math.PI) / bubbleSizeFactor,
        ),
        numberFormat: NumberFormat.FORMAT_NUMBER_NO_DECIMAL,
    });
    const lowCircleLabel = format({
        number: roundNumberToPrecision(
            ((RADII.lowCircleRadius ** 2) * Math.PI) / bubbleSizeFactor,
        ),
        numberFormat: NumberFormat.FORMAT_NUMBER_NO_DECIMAL,
    });
    const bubbleInfo = (
        <div
            key="first-page"
            className="bubble-legend flex-it column space-between"
        >
            <BubbleTitle title={sizeTitle} />
            <div className="flex-it space-around grow no-shrink">
                <BubbleGraphicsDetailed
                    veryHighCircleLabel={veryHighCircleLabel}
                    highCircleLabel={highCircleLabel}
                    mediumCircleLabel={mediumCircleLabel}
                    lowCircleLabel={lowCircleLabel}
                />
            </div>
            <BubbleControls
                currentBubbleSizeLog10={currentBubbleSizeLog10}
                onBubbleSizeChange={onBubbleSizeChange}
                showLabel
            />
            <AutoAdjust
                mapInstance={mapInstance}
                onAutoAdjust={onAdjustBubblesClick}
                orientation={orientation}
            />
            <BubblePreviewInfo maxBubbleLabel={maxBubbleLabel} />
            <BubbleColorPalette
                key="color-palette-page"
                bubbleValueType={bubbleValueType}
                layout={LegendLayout.DETAILED}
                variableGuid={variableGuid}
                renderer={renderer}
                mapInstanceId={mapInstance.id}
            />
        </div>
    );

    const pages = [bubbleInfo];

    pages.push(
        <SatelliteLegend
            key="satellite-legend"
            mapInstance={mapInstance}
            onEdit={onEditSatelliteDetailsClick}
            onClose={onCloseSatelliteDetails}
        />,
    );

    libraryLegendInfo
        .filter(lg => CUSTOM_LIBRARY_LAYER_IDS.indexOf(lg.id) !== -1)
        .forEach(layerGroup => {
            switch (layerGroup.id) {
            case 'school_digger':
                pages.push(
                    <SchoolRankLegend
                        key="school-rank-legend"
                        mapInstance={mapInstance}
                    />,
                );
                break;
            case 'opportunity_zones':
                pages.push(
                    <OpportunityZonesLegend
                        key="opportunity-zones-legend"
                        mapInstance={mapInstance}
                    />,
                );
                break;
            }
        });

    // list of library layers without the custom ones
    const filteredLibraryLayers =
        libraryLegendInfo &&
        libraryLegendInfo.filter(
            lg => CUSTOM_LIBRARY_LAYER_IDS.indexOf(lg.id) === -1,
        );

    if (filteredLibraryLayers && filteredLibraryLayers.length > 0) {
        pages.push(
            <LayerLibraryLegend
                key="layer-library-legend"
                className="layer-library-legend"
                libraryGroups={filteredLibraryLayers}
                mapInstanceId={mapInstance.id}
            />,
        );
    }

    if (
        annotationLegend &&
        annotationLegend.visible &&
        annotationLegend.legendItems.length > 0
    ) {
        pages.push(
            <AnnotationLegend
                key="annotation-legend"
                annotationLegend={annotationLegend}
            />,
        );
    }

    if (hasDataFilter) {
        pages.push(
            <DataFilterLegend
                key="data-filter-legend"
                onDataFilterInfoClick={onDataFilterInfoClick}
                filterInfoOpen={filterInfoOpen}
            />,
        );
    }

    const currentPageIndex = legendHighlighted ? 0 : undefined;

    return <PagedBrowser currentPage={currentPageIndex}>{pages}</PagedBrowser>;
};

BubbleDetailed.propTypes = {
    currentBubbleSizeLog10: PropTypes.number.isRequired,
    mapInstance: PropTypes.object.isRequired,
    orientation: PropTypes.oneOf(Object.keys(Orientation)).isRequired,
    renderer: PropTypes.object.isRequired,
    variableGuid: PropTypes.string.isRequired,
    bubbleValueType: PropTypes.string.isRequired,
    maxBubbleLabel: PropTypes.string.isRequired,
    onBubbleSizeChange: PropTypes.func.isRequired,
    onAdjustBubblesClick: PropTypes.func.isRequired,
    annotationLegend: PropTypes.object,
    libraryLegendInfo: PropTypes.array,
    hasDataFilter: PropTypes.bool,
    onDataFilterInfoClick: PropTypes.func,
    onEditSatelliteDetailsClick: PropTypes.func,
    onCloseSatelliteDetails: PropTypes.func,
    filterInfoOpen: PropTypes.bool,
    intl: PropTypes.object.isRequired,
};

BubbleDetailed.defaultProps = {
    annotationLegend: undefined,
    libraryLegendInfo: undefined,
    hasDataFilter: false,
    onDataFilterInfoClick: undefined,
    onEditSatelliteDetailsClick: undefined,
    onCloseSatelliteDetails: undefined,
    filterInfoOpen: false,
};

export default injectIntl(BubbleDetailed);
