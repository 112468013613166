import BaseHandler from './BaseHandler';
import VisualizationType from '../../enums/VisualizationType';

class MapInstanceHandler extends BaseHandler {
    constructor(mapViewer) {
        super(mapViewer);
        this._data = mapViewer.dragonflyMapData.mapInstanceData;
        this._isMapLoaded = this.map.loaded();
        this._addLayersMasks();
        this._clearAllRendererRuleHighlights();

        this.setGluBusEvents({
            MAP_APPLY_RENDERER_UPDATE: this.applyRendererUpdate,
            MAP_APPLY_NEW_COLOR_PALETTE_REQUEST: this.applyColorPaletteUpdate,
            MAP_APPLY_NEW_BUBBLE_SIZE_FACTOR: this.applyBubbleSizeFactorUpdate,
            MAP_APPLY_DOT_VALUE_HINT_REQUEST: this.applyDotValueHintUpdate,
            MAP_APPLY_CUTPOINTS_REQUEST: this.applyCutpointsUpdate,
            MAP_UPDATE_PREFERRED_SUMMARY_LEVEL_REQUEST: this.applySummaryLevelUpdate,
            MAP_UPDATE_SATELLITE_LAYER_VISIBILITY_REQUEST: this.applySatelliteViewUpdate,
            MAP_APPLY_LAYERS_OVERRIDES_REQUEST: this.applyLayersOverridesUpdate,
            MAP_APPLY_MASKING_FILTER_REQUEST: this.applyMaskingFilterUpdate,
            MAP_SET_HIGHLIGHT_RULE: this.applyRendererRuleHighlight,
            MAP_CLEAR_HIGHLIGHT_RULE: this.clearRendererRuleHighlight,
            MAP_ZOOM_END: this.applyDataVisibilityMapClasses,
            MAP_LOAD: this.onMapLoad,
            MAP_APPLY_DATA_FILTER_REQUEST: this.applyDataFilterUpdate,
            MAP_APPLY_SATELLITE_DATA_OVERLAY_DATA_OPACITY_UPDATE: this.applySatelliteDataOverlayDataOpacityRequest,
            MAP_APPLY_SATELLITE_DATA_OVERLAY_SATELLITE_COLOR_UPDATE: this.applySatelliteDataOverlaySatelliteColorUpdate,
        });
    }

    onMapLoad(e) {
        if (e.source.id !== this.mapInstanceId) return;
        this._isMapLoaded = true;
        this.applyDataVisibilityMapClasses();
    }

    applySatelliteDataOverlayDataOpacityRequest(e) {
        if (e.mapInstanceId !== this.mapInstanceId) return;

        this._applySatelliteDataOverlay();
        this.emit('MAP_SATELLITE_DATA_OVERLAY_DATA_OPACITY_CHANGED', {
            mapInstanceId: this.mapInstanceId,
            source: this.mapViewer,
            opacity: this.mapInstance.satelliteDataOverlayDataOpacity,
        });
    }

    applySatelliteDataOverlaySatelliteColorUpdate(e) {
        if (e.mapInstanceId !== this.mapInstanceId) return;

        this._applySatelliteDataOverlay();
        this.emit('MAP_SATELLITE_DATA_OVERLAY_SATELLITE_COLOR_CHANGED', {
            mapInstanceId: this.mapInstanceId,
            source: this.mapViewer,
            satelliteHasColor: this.mapInstance.satelliteDataOverlaySatelliteHasColor,
        });
    }

    applyRendererUpdate(e) {
        if (e.source.id !== this.mapInstanceId) return;
        const oldRendererData = this._data.rendererData;
        oldRendererData.layers.forEach(dl => {
            // before trying to remove the layer check if it exists and prevent an error if case it doesn't
            if (this.map.getLayer(dl.id)) this.map.removeLayer(dl.id);
        });

        // if the data filter helper layer exist remove it
        if (this.map.getLayer(oldRendererData.dataFilterHelperLayer.id)) {
            this.map.removeLayer(oldRendererData.dataFilterHelperLayer.id);
        }
        this.map.removeSource(oldRendererData.source.id);
        const oldClass = this._data.mapClasses[0];
        this._data.applyRendererUpdate();

        const newRendererData = this._data.rendererData;
        newRendererData.mapClasses.forEach(mapClass => this._mapApplyClass(mapClass, oldClass));
        // hide shaded area if satellite view
        // this.hideShadedAreaOnSatellite();
        // replace socialexplorer data source in dragonfly map
        this.map.addSource(newRendererData.source.id, newRendererData.source.toJSON());
        this._addLayersMasks();
        for (let i = this._data.layers.length - 1; i >= 0; i -= 1) {
            const dl = this._data.layers[i];

            if (this.map.getLayer(dl.id)) continue;

            if (this._data.layers[i + 1]) {
                this.map.addLayer(dl, this._data.layers[i + 1].id);
            } else if (this.map.getLayer('dragonfly-draw-dummy')) {
                this.map.addLayer(dl, 'dragonfly-draw-dummy');
            } else {
                this.map.addLayer(dl);
            }
        }
        this.applyDataVisibilityMapClasses();
        this._applySatelliteDataOverlay();
        this.emit('MAP_NEW_DATA_THEME_APPLIED', {
            source: this.mapViewer,
            appliedDataTheme: this.mapInstance.dataTheme,
        });
    }

    applyColorPaletteUpdate(e) {
        if (e.mapInstanceId !== this.mapInstanceId) return;
        this._data.rendererData.applyColorPaletteUpdate();
        this._data.rendererData.dataLayers.forEach(dl => {
            this.map.setPaintProperty(dl.id, `${dl.type}-color`, dl.paint[`${dl.type}-color`]);
        });

        this.emit('MAP_NEW_COLOR_PALETTE_APPLIED', {
            source: this.mapViewer,
            selectedColorPalette: e.colorPalette,
            colorPaletteId: this.mapInstance.dataTheme.colorPaletteId,
            colorPaletteType: this.mapInstance.dataTheme.colorPaletteType,
            colorPaletteFlipped: this.mapInstance.dataTheme.colorPaletteFlipped,
            isFlipRequest: e.isFlipRequest,
        });
    }

    applyBubbleSizeFactorUpdate(e) {
        if (e.mapInstanceId !== this.mapInstanceId) return;
        this._data.rendererData.applyBubbleSizeFactorUpdate();
        this._data.rendererData.dataLayers.forEach(dl => {
            this.map.setPaintProperty(dl.id, 'bubble-radius-multiplier', dl.paint['bubble-radius-multiplier']);
        });
        this.emit('MAP_NEW_BUBBLE_SIZE_FACTOR_APPLIED', {
            mapInstanceId: this.mapInstanceId,
            source: this.mapViewer,
            bubbleSizeFactor: this.mapInstance.dataTheme.rendering[0].bubbleSizeFactor,
        });
    }

    applyDotValueHintUpdate(e) {
        if (e.mapInstanceId !== this.mapInstanceId) return;
        this._data.rendererData.applyDotValueHintUpdate();
        this._data.rendererData.dataLayers.forEach(dl => {
            this.map.setLayoutProperty(dl.id, 'dotdensity-count', dl.layout['dotdensity-count']);
        });
        this.emit('MAP_NEW_DOT_VALUE_HINT_APPLIED', {
            source: this.mapViewer,
            dotDensityDotValueHint: this.mapInstance.dataTheme.dotDensityValueHint,
        });
    }

    applyCutpointsUpdate(e) {
        if (e.mapInstanceId !== this.mapInstanceId) return;
        this._data.rendererData.applyCutpointsUpdate();
        this._data.rendererData.dataLayers.forEach(dl => {
            this.map.setPaintProperty(dl.id, `${dl.type}-color`, dl.paint[`${dl.type}-color`]);
        });
        this.emit('MAP_NEW_CUTPOINTS_APPLIED', { source: this.mapViewer });
    }

    applySummaryLevelUpdate(e) {
        if (e.mapInstanceId !== this.mapInstanceId) return;
        this._data.applySummaryLevelUpdate();
        // apply dragonfly layers zoom range changes to dragonfly map
        this._data.geographyLayers.forEach(dl => {
            const mapDl = this.map.getLayer(dl.id);
            if (mapDl.minzoom !== dl.minzoom || mapDl.maxzoom !== dl.maxzoom) {
                this.map.setLayerZoomRange(dl.id, dl.minzoom, dl.maxzoom);
            }
        });
        // apply renderer dragonfly layers zoom range and auto source changes to dragonfly map
        this._data.rendererData.layers.forEach(dl => {
            const mapDl = this.map.getLayer(dl.id);
            mapDl.autoSource = dl['auto-source'];
            this.map.setLayerZoomRange(dl.id, dl.minzoom, dl.maxzoom);
            this.map.style._updateLayer(mapDl);
        });

        // Also update the data filter helper layer if exists
        const dataFilterHelperLayer = this._data.rendererData.dataFilterHelperLayer;
        const mapFilterHelperLayer = this.map.getLayer(dataFilterHelperLayer.id);
        if (mapFilterHelperLayer) {
            mapFilterHelperLayer.autoSource = dataFilterHelperLayer['auto-source'];
            this.map.setLayerZoomRange(dataFilterHelperLayer.id, dataFilterHelperLayer.minzoom, dataFilterHelperLayer.maxzoom);
            this.map.style._updateLayer(dataFilterHelperLayer);
        }

        // update map classes
        this.applyDataVisibilityMapClasses();
        // zoom map to min zoom for data layers
        const dataMinZoom = this._data.rendererData.dataMinZoom;
        if (this.map.getZoom() < dataMinZoom) {
            this.map.zoomTo(dataMinZoom);
        }
        this.emit('MAP_PREFERRED_SUMMARY_LEVEL_CHANGED', {
            source: this.mapViewer,
            summaryLevel: this._data.preferredSummaryLevel,
        });
    }

    applySatelliteViewUpdate(e) {
        if (e.mapInstanceId !== this.mapInstanceId) return;
        if (!this._data.hasSatellite) return;

        this._data.applySatelliteVisibilityUpdate();
        this._data.layers
            .filter(l => !!l.layout)
            .forEach(dl => {
                this.map.setLayoutProperty(dl.id, 'visibility', dl.layout.visibility);
            });
        this._applySatelliteDataOverlay();

        this.emit('MAP_SATELLITE_LAYER_VISIBILITY_CHANGED', {
            source: this.mapViewer,
            isSatelliteLayerVisible: this.mapInstance.isSatelliteVisible,
        });
    }

    applyLayersOverridesUpdate(e) {
        if (e.mapInstanceId !== this.mapInstanceId) return;
        this._data.applyLayersOverridesUpdate();
        this._data.layers.forEach(dl => {
            if (dl.layout) {
                this.map.setLayoutProperty(dl.id, 'visibility', dl.layout.visibility);
            }
        });
        this.applyDataVisibilityMapClasses();
        this.emit('MAP_LAYERS_OVERRIDES_APPLIED', {
            source: this.mapViewer,
        });
    }

    applyMaskingFilterUpdate(e) {
        if (e.mapInstanceId !== this.mapInstanceId) return;
        this._data.applyMaskingFilterUpdate();
        // apply dragonfly layers zoom range changes to dragonfly map
        this._data.geographyLayers.forEach(dl => {
            const mapDl = this.map.getLayer(dl.id);
            if (mapDl.minzoom !== dl.minzoom || mapDl.maxzoom !== dl.maxzoom) {
                this.map.setLayerZoomRange(dl.id, dl.minzoom, dl.maxzoom);
            }
        });
        // apply renderer dragonfly layers zoom range and auto source changes to dragonfly map
        this._data.rendererData.layers.forEach(dl => this.map.setLayer(dl));
        if (this.mapInstance.hasDataFilter) this.map.setLayer(this._data.rendererData.dataFilterHelperLayer);
        // update map classes
        this.applyDataVisibilityMapClasses();
        // zoom map to min zoom for data layers
        const dataMinZoom = this._data.rendererData.dataMinZoom;
        if (this.map.getZoom() < dataMinZoom) {
            this.map.zoomTo(dataMinZoom);
        }
        if (this._data.hasMaskingFilter) {
            this.emit('MAP_MASKING_FILTER_APPLIED', { source: this.mapViewer });
        } else {
            this.emit('MAP_MASKING_FILTER_REMOVED', { source: this.mapViewer });
        }
    }

    applyRendererRuleHighlight(e) {
        if (e.mapInstanceId !== this.mapInstanceId || !this._isMapLoaded) return;
        if (!this._isMapLoaded) {
            this._isMapLoaded = this.map.loaded();
        }
        if (!this._isMapLoaded) return;

        if (this._data.rendererData._renderer.type === 'MultiValueRenderer') {
            this._data.rendererData.highlightMultiRule(e.ruleIdx, e.variableIdx);
        } else {
            this._data.rendererData.highlightRule(e.ruleIdx);
        }

        if (this.mapInstance.dataTheme.isShadedVisualization) {
            this._data.rendererData.dataLayers.forEach(dl => {
                this.map.setPaintProperty(dl.id, 'fill-color', dl.paint['fill-color']);
            });
        }

        if (this.mapInstance.dataTheme.isBubblesVisualization) {
            this._data.rendererData.dataLayers.forEach(dl => {
                this.map.setPaintProperty(dl.id, 'bubble-color', dl.paint['bubble-color']);
            });
        }
    }

    clearRendererRuleHighlight(e) {
        if (e.mapInstanceId !== this.mapInstanceId || !this._isMapLoaded) return;
        this._data.rendererData.applyColorPaletteUpdate();

        if (this.mapInstance.dataTheme.isShadedVisualization) {
            this._clearAllRendererRuleHighlights();

            this._data.rendererData.dataLayers.forEach(dl => {
                this.map.setPaintProperty(dl.id, 'fill-color', dl.paint['fill-color']);
            });
        }

        if (this.mapInstance.dataTheme.isBubblesVisualization) {
            this._clearAllRendererRuleHighlights();
            this._data.rendererData.dataLayers.forEach(dl => {
                this.map.setPaintProperty(dl.id, 'bubble-color', dl.paint['bubble-color']);
            });
        }
    }

    _clearAllRendererRuleHighlights() {
        const renderer = this.mapInstance.dataTheme.rendering[0];
        if (renderer.type === 'MultiValueRenderer') {
            renderer.rules.forEach(variableRules => {
                variableRules.forEach(rule => { rule.highlighted = false; });
            });
        } else {
            renderer.rules.forEach(rule => { rule.highlighted = false; });
        }
    }

    applyDataFilterUpdate(e) {
        if (e.mapInstanceId !== this.mapInstanceId || !this._isMapLoaded) return;
        const oldRendererData = this._data.rendererData;
        const oldClass = this._data.mapClasses[0];
        // Remove the layers from map
        oldRendererData.layers.forEach(dl => {
            // before trying to remove the layer check if it exists and prevent an error if case it doesn't
            if (this.map.getLayer(dl.id)) this.map.removeLayer(dl.id);
        });

        // If present also remove the helper layer
        if (this.map.getLayer(oldRendererData._dataFilterHelperLayer.id)) {
            this.map.removeLayer(oldRendererData._dataFilterHelperLayer.id);
        }

        this.map.removeSource(oldRendererData.source.id);
        this._data.applyDataFilterUpdate(this.mapViewer.dragonflyMapData);
        const newRendererData = this._data.rendererData;
        newRendererData.mapClasses.forEach(mapClass => this._mapApplyClass(mapClass, oldClass));

        this.map.addSource(newRendererData.source.id, newRendererData.source.toJSON());

        for (let i = this._data.layers.length - 1; i >= 0; i -= 1) {
            const dl = this._data.layers[i];

            if (this.map.getLayer(dl.id)) continue;

            if (this._data.layers[i + 1]) {
                this.map.addLayer(dl, this._data.layers[i + 1].id);
            } else if (this.map.getLayer('dragonfly-draw-dummy')) {
                this.map.addLayer(dl, 'dragonfly-draw-dummy');
            } else {
                this.map.addLayer(dl);
            }
        }
        this.applyDataVisibilityMapClasses();
        const { hasDataFilter, dataFilter } = this.mapInstance;
        const eventID = hasDataFilter ? 'MAP_DATA_FILTER_APPLIED' : 'MAP_DATA_FILTER_REMOVED';
        this.emit(eventID, { source: this.mapViewer, dataFilter });
    }

    _mapHasClass(mapClass) {
        return this._data.mapClasses.indexOf(mapClass) > -1;
    }

    _applySatelliteDataOverlay() {
        this._data.applySatelliteDataOverlay();
        this._data.rendererData.dataLayers.forEach(dl => {
            this.map.setPaintProperty(dl.id, `${dl.type}-opacity`, dl.paint[`${dl.type}-opacity`]);
        });
        this.map.setPaintProperty(this._data.satelliteLayer.id, 'raster-saturation', this._data.satelliteLayer.paint['raster-saturation']);
        this._data.dataLayers.forEach(dl => {
            const idx = this._data.layers.indexOf(dl);
            const afterLayer = this._data.layers[idx + 1];
            if (afterLayer) {
                this.map.moveLayer(dl.id, afterLayer.id);
            }
        });
        this._addLayersMasks();
    }

    _mapApplyClass(mapClass, oldClass) {
        // Apply layer class to rendered map.
        // The default values are read from map config.
        this._data._mapConfig.layers.forEach(l => {
            if (l[`paint.${oldClass}`] === undefined) return;
            Object.keys(l.paint).forEach(pp => this.map.setPaintProperty(l.id, pp, l.paint[pp]));
        });
        this._data._mapConfig.layers.forEach(l => {
            if (l[`paint.${mapClass}`] === undefined) return;
            Object.keys(l[`paint.${mapClass}`]).forEach(pp => this.map.setPaintProperty(l.id, pp, l[`paint.${mapClass}`][pp]));
        });
    }

    applyDataVisibilityMapClasses() {
        if (!this._isMapLoaded) return;
        let isDataVisible = true;
        const dataDragonflyLayer = this._data.rendererData.dataLayers[0];
        isDataVisible = isDataVisible && dataDragonflyLayer && dataDragonflyLayer.layout.visibility === 'visible';
        isDataVisible = isDataVisible && this._data.rendererData.dataMinZoom <= this.map.getZoom();
        if (!isDataVisible && !this._mapHasClass('no-summary-level')) {
            this._mapApplyClass('no-summary-level', this._data.mapClasses[0]);
            this._data.mapClasses = ['no-summary-level'];
        }
        if (isDataVisible && this._mapHasClass('no-summary-level')) {
            switch (this.mapInstance.dataTheme.visualizationType) {
            case VisualizationType.SHADED_AREA:
                this._data.mapClasses = ['shaded'];
                this._mapApplyClass('shaded', 'no-summary-level');
                break;
            case VisualizationType.BUBBLES:
                this._data.mapClasses = ['bubbles'];
                this._mapApplyClass('bubbles', 'no-summary-level');
                break;
            case VisualizationType.DOT_DENSITY:
                this._data.mapClasses = ['dot-density'];
                this._mapApplyClass('dot-density', 'no-summary-level');
                break;
            }
        }
    }

    _addLayersMasks() {
        this.map.painter.clearMaskLayers();
        this._data.rendererData.layersMasks.forEach(mask => {
            this.map.painter.addLayerMask(mask);
        });
    }
}

export default MapInstanceHandler;
