const key = {
    ENTER: 13,
    SHIFT: 16,
    ESC: 27,
    SPACE: 32,
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40,
    TAB: 9,
    DELETE: 46,
    Y: 89,
    Z: 90,
};

export default key;
