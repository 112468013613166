// @ts-check
import React from 'react';

import BusComponent from '../BusComponent';
import MapPanel from './MapPanel';
import MapPanelMode from '../../enums/MapPanelMode';
import LocationPanel from '../locationPanel/LocationPanel';

/**
 * @typedef Props
 * @property {import('../../objects/MapInstance').default} mapInstance
 * @property {boolean} isUserPro
 * @property {import('../MapViewer').default} mapViewer
 * @property {function} onShowDataBrowser
 * @property {string} tourId
 *
 * @typedef State
 * @property {string} mode
 * @property {string} previouslySelectedMode
 *
 * @extends BusComponent<Props, State>
 */
class MapPanelContainer extends BusComponent {
    constructor(props, context) {
        super(props, context);
        /** @type {Readonly<State>} */
        this.state = {
            // determine mode based on location analysis override
            mode: this.props.mapInstance.locationAnalysisItem && !context.isEmbedded
                ? MapPanelMode.LOCATION_ANALYSIS
                : MapPanelMode.DEFAULT,
            previouslySelectedMode: this.props.mapInstance.locationAnalysisItem && !context.isEmbedded
                ? MapPanelMode.LOCATION_ANALYSIS
                : MapPanelMode.DEFAULT,
        };
    }

    componentDidMount() {
        this.bindGluBusEvents({
            LOCATION_ANALYSIS_MODE_STARTED: this.startUpdateLocationAnalysis,
            LOCATION_ANALYSIS_MODE_EXITED: this.onExitLocationAnalysis,
            FRAME_UPDATED: this.onFrameUpdated,
            MINIMIZE_LOCATION_PANEL: this.onMinimizeLocationPanel,
            MAXIMIZE_LOCATION_PANEL: this.onMaximizeLocationPanel,
            CLOSE_LOCATION_ANALYSIS_ITEMS: this.onCloseLocationAnalysisList,
        });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    // When switching between frames we need to reset the MapPanel to default mode
    onFrameUpdated = () => {
        this.setState({ mode: MapPanelMode.DEFAULT });
    };

    /**
     * @param {object} payload
     * @param {string} payload.mapInstanceId
     */
    startUpdateLocationAnalysis = ({ mapInstanceId }) => {
        if (this.props.mapInstance.id !== mapInstanceId) return;

        this.setState({
            mode: MapPanelMode.LOCATION_ANALYSIS,
        });
    };

    onExitLocationAnalysis = ({ mapInstanceId }) => {
        if (this.props.mapInstance.id !== mapInstanceId) return;
        this.setState({
            mode: MapPanelMode.DEFAULT,
        });
    };

    onCloseLocationAnalysisList = ({ mapInstanceId }) => {
        if (this.props.mapInstance.id !== mapInstanceId) return;
        this.setState({ mode: MapPanelMode.LOCATION_ANALYSIS });
    };

    onMinimizeLocationPanel = ({ mapInstanceId }) => {
        if (this.props.mapInstance.id !== mapInstanceId) return;
        this.setState({ mode: MapPanelMode.DEFAULT });
    };

    onMaximizeLocationPanel = ({ mapInstanceId }) => {
        if (this.props.mapInstance.id !== mapInstanceId) return;
        this.setState({ mode: MapPanelMode.LOCATION_ANALYSIS });
    };

    onCloseComparePanel = () => {
        this.setState(prevState => ({
            mode: prevState.previouslySelectedMode,
        }));
    };

    render() {
        const {
            mapInstance,
            mapInstanceIndex,
            isUserPro,
            mapViewer,
            onShowDataBrowser,
            tourId,
        } = this.props;

        switch (this.state.mode) {
        case MapPanelMode.LOCATION_ANALYSIS:
            return (
                <LocationPanel
                    mapInstance={mapInstance}
                    isUserPro={isUserPro}
                />
            );
        default:
            return (
                <MapPanel
                    mapInstance={mapInstance}
                    mapInstanceIndex={mapInstanceIndex}
                    isUserPro={isUserPro}
                    onShowDataBrowser={onShowDataBrowser}
                    mapViewer={mapViewer}
                    tourId={tourId}
                />
            );
        }
    }
}

export default MapPanelContainer;
