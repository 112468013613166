import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { HELP_TOUR_STEPS_NUMBER } from '../../enums/HelpTourDefinitions';
import { endTourButtonEvents } from '../../enums/GoogleTagManagerEvents';
import googleTagManagerEvents from '../../helpers/GoogleTagManagerEventsWrapper';

const EndTourButton = googleTagManagerEvents('button', endTourButtonEvents);

class HelpTourStep extends React.Component {
    onClose = () => {
        this.props.onClose();
    }

    onPreviousStep = () => {
        this.props.onPrevious();
    }

    onNextStep = () => {
        this.props.onNext();
    }

    onSelectedStep = index => {
        this.props.onSelected(index);
    };

    renderStepHeader() {
        const { title } = this.props.options;
        return (<div className="flex-it row flex-start space-between margin-bottom-15 step-header">
            <h2 className="font-bold-6">{title}</h2>
            <button className="clickable padded-horizontal-4" onClick={this.onClose}>
                <i className="material-icons">close</i>
            </button>
        </div>);
    }

    renderStepContent() {
        const { description, note } = this.props.options;
        return (<div className="margin-bottom-25 step-content">
            <span style={{ whiteSpace: 'pre-line' }}>{description}</span>
            <br />
            <div className="font-italic margin-top-10">{note}</div>
        </div>);
    }

    renderStepNavigation() {
        const buttonClasses = classNames('flex-it center clickable font-bold-6');
        const { isFirstStep, isLastStep, index } = this.props.options;

        const previousButtonClasses = classNames(buttonClasses, {
            hidden: isFirstStep,
        });

        const previousButton = (
            <button
                className={previousButtonClasses}
                onClick={this.onPreviousStep}
                aria-label="Previous step"
            >
                <i className="material-icons">keyboard_arrow_left</i>
                BACK
            </button>
        );

        let nextButton = (
            <button
                className={buttonClasses}
                onClick={this.onNextStep}
                aria-label="Next step"
            >
                NEXT
                <i className="material-icons">keyboard_arrow_right</i>
            </button>);

        if (isLastStep) {
            nextButton = (
                <EndTourButton
                    className={buttonClasses}
                    onClick={this.onClose}
                >
                    OKAY, GOT IT!
                </EndTourButton>
            );
        }

        const stepButtons = [];
        for (let i = 0; i < HELP_TOUR_STEPS_NUMBER; i += 1) {
            const stepClasses = classNames('circle clickable', {
                selected: i === index,
            });
            stepButtons.push(<div key={i} className={stepClasses} onClick={() => this.onSelectedStep(i)} />);
        }

        return (<div className="flex-it space-between step-footer">
            <div className="flex-it control">{previousButton}</div>
            <div className="flex-it row center indicator">{stepButtons}</div>
            <div className="flex-it control ">{nextButton}</div>
        </div>);
    }

    render() {
        const { classes } = this.props.options;
        const stepClasses = classNames('flex-it column help-tour-step', classes);
        return (
            <div
                className={stepClasses}
                ref={this.props.helpTourRef}
            >
                {this.renderStepHeader()}
                {this.renderStepContent()}
                {this.renderStepNavigation()}
            </div>
        );
    }
}

HelpTourStep.propTypes = {
    options: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onPrevious: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    onSelected: PropTypes.func.isRequired,
    helpTourRef: PropTypes.func,
};

HelpTourStep.defaultProps = {
    helpTourRef: undefined,
};

export default HelpTourStep;
