import React from 'react';
import BusComponent from '../BusComponent';
import Unit from '../../enums/Unit';
import NumberFormat from '../../enums/NumberFormat';
import format from '../../helpers/NumberFormatter';
import { findDistanceInMilesFromLatLong, convertFromMiles } from '../../helpers/Util';

class MapScale extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            currentScale: '',
            scaleUnit: props.mapInstance.scaleUnit,
            distanceInMiles: undefined,
            zoom: props.mapInstance.initialView.zoom,
        };

        this.bindGluBusEvents({
            DRAGONFLY_MAP_CREATED: this.onMapUpdate,
            MAP_ZOOM: this.onMapUpdate,
            MAP_MOVE_END: this.onMapUpdate,
        });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.mapInstance.id !== nextProps.mapInstance.id) {
            this.setState({
                currentScale: '',
                scaleUnit: nextProps.mapInstance.scaleUnit,
                distanceInMiles: undefined,
            });
        }
    }

    onMapUpdate(eventMap) {
        if (eventMap.source.id === this.props.mapInstance.id) {
            // calculate horizontal width of map accross center
            const map = eventMap.source.dragonflyMap;
            const startPoint = map.unproject([0, map.getContainer().clientHeight / 2]);
            const endPoint = map.unproject([map.getContainer().clientWidth, map.getContainer().clientHeight / 2]);
            const distanceInMiles = findDistanceInMilesFromLatLong(startPoint, endPoint);
            this.setState({
                distanceInMiles,
                currentScale: this._calculateCurrentScale(distanceInMiles, this.state.scaleUnit),
                zoom: map.getZoom(),
            });
        }
    }

    toggleUnit = () => {
        let nextUnit;

        if (this.state.scaleUnit === Unit.METERS) {
            nextUnit = Unit.MILES;
        } else {
            nextUnit = Unit.METERS;
        }
        this.emit('SET_MAP_SCALE_UNIT_REQUEST', { mapInstanceId: this.props.mapInstance.id, scaleUnit: nextUnit });

        this.setState({
            scaleUnit: nextUnit,
            currentScale: this._calculateCurrentScale(this.state.distanceInMiles, nextUnit),
        });
    };

    render() {
        return (
            <div className="map__footer-scale">
                <span
                    className="map-scale map__footer-item clickable"
                    onClick={this.toggleUnit}
                >
                    {this.state.currentScale}
                </span>
                <div className="map__footer-item">
                    Zoom:
                    <span className="map__footer-item__higlight">
                        {this.state.zoom.toFixed(1)}
                    </span>
                </div>
            </div>
        );
    }

    _calculateCurrentScale(distanceInMiles, unit) {
        if (!distanceInMiles) {
            return '';
        }

        let distanceInSelectedUnit, label;
        if (unit === Unit.METERS) {
            distanceInSelectedUnit = convertFromMiles(distanceInMiles, Unit.METERS);
            label = 'm';
            if (distanceInSelectedUnit > 1000) {
                distanceInSelectedUnit /= 1000;
                label = 'km';
            }
        } else {
            distanceInSelectedUnit = distanceInMiles;
            label = 'mi';
            if (distanceInSelectedUnit < 1) {
                distanceInSelectedUnit *= 5280;
                label = 'ft';
            }
        }
        return `${format({ number: distanceInSelectedUnit, numberFormat: NumberFormat.FORMAT_NUMBER_NO_DECIMAL })} ${label}`;
    }
}

export default MapScale;

