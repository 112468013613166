import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

class ButtonToggleItem extends React.Component {
    onClick = () => {
        this.props.onClick(this.props.item);
    };

    render() {
        const { selected, item } = this.props;
        const classes = classNames('btn-void', {
            'btn-raised': selected,
        });
        return (
            <button
                className={classes}
                onClickCapture={this.onClick}
                aria-expanded={selected}
            >
                {item.text}
            </button>
        );
    }
}

ButtonToggleItem.propTypes = {
    selected: PropTypes.bool,
    item: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
};

ButtonToggleItem.defaultProps = {
    selected: false,
};

export default ButtonToggleItem;
