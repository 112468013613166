import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class FrameTypeButton extends React.Component {
    componentDidMount = () => {
        if (this.props.active) this.button.focus();
    }

    handleClick = () => {
        this.props.onClick(this.props.value);
    };

    render() {
        const { active, title, icon } = this.props;
        const classes = classNames('frame-type-button', {
            'frame-type-button--selected': active,
        });

        return (
            <button
                ref={e => { this.button = e; }}
                className={classes}
                onClick={this.handleClick}
                aria-label={title}
            >
                <div className="frame-type-button__icon">
                    <i className={icon} />
                </div>
                <div className="frame-type-button__label">
                    {title}
                </div>
            </button>
        );
    }
}

FrameTypeButton.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    active: PropTypes.bool,
};

FrameTypeButton.defaultProps = {
    active: false,
};

export default FrameTypeButton;
