import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import AppConfig from '../../appConfig';
import BusComponent from '../BusComponent';
import IconMenu from '../iconMenu/IconMenu';
import { takeTourButtonEvents } from '../../enums/GoogleTagManagerEvents';
import googleTagManagerEvents from '../../helpers/GoogleTagManagerEventsWrapper';

const TakeTourItem = googleTagManagerEvents('button', takeTourButtonEvents);

class MoreOptionsButton extends BusComponent {
    onProjectTitleClick = event => {
        if (event.type === 'keypress') {
            if (event.key === ' ' || event.key === 'Enter') {
                // prevent the default action to stop scrolling when space is pressed
                event.preventDefault();
            } else {
                // user did not press space nor enter key, so we should do nothing
                return;
            }
        }
        this.emit('EDIT_PROJECT_INFO_POPUP_REQUEST');
        this.emit('CLOSE_DROPDOWN');
    };

    onMakeACopyClick = event => {
        if (event.type === 'keypress') {
            if (event.key === ' ' || event.key === 'Enter') {
                // prevent the default action to stop scrolling when space is pressed
                event.preventDefault();
            } else {
                // user did not press space nor enter key, so we should do nothing
                return;
            }
        }
        this.emit('SAVE_AS_PROJECT_POPUP_REQUEST', {
            projectTitle: this.props.projectTitle,
            saveAsNew: true,
        });
        this.emit('CLOSE_DROPDOWN');
    };

    onTourClick = event => {
        if (event.type === 'keypress') {
            if (event.key === ' ' || event.key === 'Enter') {
                // prevent the default action to stop scrolling when space is pressed
                event.preventDefault();
            } else {
                // user did not press space nor enter key, so we should do nothing
                return;
            }
        }
        this.emit('HELP_TOUR_START_REQUEST');
        this.emit('CLOSE_DROPDOWN');
    };

    onSendFeedbackClick = event => {
        if (event.type === 'keypress') {
            if (event.key === ' ' || event.key === 'Enter') {
                // prevent the default action to stop scrolling when space is pressed
                event.preventDefault();
            } else {
                // user did not press space nor enter key, so we should do nothing
                return;
            }
        }
        this.emit('FEEDBACK_POPUP_REQUEST');
        this.emit('CLOSE_DROPDOWN');
    };

    render() {
        const {
            disabled,
            showEditProjectTitle,
            showProjectSaveAs,
            showTakeTour,
            isApplicationInViewMode,
            userInfo,
        } = this.props;

        if (!showTakeTour && !showProjectSaveAs && !showEditProjectTitle) {
            return null;
        }

        let editTitleAndDescription, saveProject, takeTour;

        if (showEditProjectTitle) {
            editTitleAndDescription = (
                <button
                    className="material-menu__item"
                    onClick={this.onProjectTitleClick}
                >
                    <span>Edit title and description</span>
                </button>
            );
        }

        if (showProjectSaveAs) {
            saveProject = (
                <button
                    className="material-menu__item"
                    onClick={this.onMakeACopyClick}
                >
                    <span>{`${this.props.intl.formatMessage({
                        id: 'header.saveAs',
                    })}...`}</span>
                </button>
            );
        }

        if (showTakeTour) {
            takeTour = (
                <TakeTourItem
                    className="material-menu__item"
                    onClick={this.onTourClick}
                >
                    <FormattedMessage id="header.takeATour" />
                </TakeTourItem>
            );
        }

        return (
            <IconMenu
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                targetOrigin={{ vertical: 'top', horizontal: 'right' }}
                light
                icon="more_vert"
                className="application-header__popup-wrapper"
                tabIndex="0"
                tooltip={this.props.intl.formatMessage({ id: 'more' })}
                disabled={disabled}
                triggerClassName="application-header__button"
                ariaLabel={this.props.intl.formatMessage({ id: 'moreOptions' })}
            >
                <div className="material-menu">
                    {editTitleAndDescription}
                    {saveProject}
                    {showProjectSaveAs && takeTour && (
                        <div className="material-menu__divider" />
                    )}
                    {takeTour}
                    {!isApplicationInViewMode && userInfo.userId !== -1 &&
                    <div>
                        <div className="material-menu__divider" />
                        <button
                            className="material-menu__item"
                            onClick={this.onSendFeedbackClick}
                        >
                            <FormattedMessage id="sendFeedback" />
                        </button>
                    </div>
                    }
                </div>
            </IconMenu>
        );
    }
}

MoreOptionsButton.propTypes = {
    disabled: PropTypes.bool,
    showEditProjectTitle: PropTypes.bool,
    showProjectSaveAs: PropTypes.bool,
    showTakeTour: PropTypes.bool,
    projectTitle: PropTypes.string,
    intl: PropTypes.object.isRequired,
    isApplicationInViewMode: PropTypes.bool,
    userInfo: PropTypes.object.isRequired,
};

MoreOptionsButton.defaultProps = {
    disabled: false,
    showEditProjectTitle: false,
    showProjectSaveAs: false,
    showTakeTour: true,
    projectTitle: undefined,
    isApplicationInViewMode: false,
};

export default injectIntl(MoreOptionsButton);
