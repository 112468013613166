import BaseHandler from './BaseHandler';
import MapSelectionType from '../../enums/MapSelectionType';
import DataGeoFilterFeature from '../../objects/dataGeoFilter/DataGeoFilterFeature';

class MaskHandler extends BaseHandler {
    constructor(mapViewer) {
        super(mapViewer);
        this._data = mapViewer.dragonflyMapData;
        this._referentDataLayer = this._data.maskData.dataLayer;
        this._referentHiglightLayer = this._data.maskData.highlightLayer;
        // add map selection control
        this._data.selectionData.selectionControl.updateSelectionLayers([this._referentDataLayer.id]);
        this._maskFeatures = [];
        if (this.mapInstance.hasMaskingFilter) {
            const maskingFilter = Object.values(this.mapInstance.dataGeoFilters)[0];
            this._maskFeatures = maskingFilter.features;
        }

        this.setGluBusEvents({
            MAP_FEATURES_ROLL_OUT: this.onMapFeaturesRollOut,
            MAP_FEATURES_NONE: this.onMapFeaturesRollOut,
            MAP_PREFERRED_SUMMARY_LEVEL_CHANGED: this.onMapPreferredSummaryLevelChanged,
            MAP_SELECTION_END: this.onMapSelectionEnd,
            MAP_DESELECT_MASK_FEATURE: this.onMapDeselectFeature,
            MAP_CLEAR_SELECTED_MASK_FEATURES: this.onMapClearSelectedFeatures,
            MAP_SELECTION_IMPORT: this.onMapSelectionImport,
        });
    }

    get mapDataInfo() {
        let activeSummaryLevel;
        if (this._data.mapInstanceData.preferredSummaryLevel) {
            activeSummaryLevel = this._data.mapInstanceData.preferredSummaryLevel;
        } else {
            activeSummaryLevel = this.mapConfig.getSummaryLevelOnZoom(this.map.getZoom());
        }
        if (!activeSummaryLevel) {
            return {};
        }
        const activeDataset = this.mapConfig.getActiveDataset(
            activeSummaryLevel.id,
            this._data.mapInstanceData.rendererData.activeSurveyName,
            this._data.mapInstanceData.rendererData.activeDatasetAbbrevation,
        );
        const activeAutoSource = this._data.mapInstanceData.rendererData.autoSource.find(asLayer => (
            asLayer.id === activeSummaryLevel.id
        ));

        return {
            summaryLevel: activeSummaryLevel,
            dataset: activeDataset,
            autoSource: activeAutoSource,
        };
    }

    onMapFeaturesRollOut(eventMap) {
        if (eventMap.sender === this || !eventMap.originalEvent || !eventMap.originalEvent.point) return;
        const point = eventMap.originalEvent.point.clone();
        const featuresUnderMouse = this.map.queryRenderedFeatures(point, { layers: [this._referentHiglightLayer.id] });
        const feature = featuresUnderMouse[0];
        if (feature) {
            this.map.painter.setFeaturesStyles([feature, feature],
                [
                    {
                        'line-gap-width': 2,
                        'line-color': 'rgba(180, 180, 180, 0.8)',
                        'line-blur': 2,
                        'line-width': 2,
                    },
                    {
                        'line-color': 'rgba(255, 255, 255, 1)',
                    },
                ]);
            this.emit('MAP_FEATURES_ROLL_OVER', {
                source: this.mapViewer,
                originalEvent: eventMap.originalEvent,
                lngLat: this.map.unproject(point),
                features: [feature],
            });
        } else {
            this.emit('MAP_FEATURES_ROLL_OUT', {
                source: this.mapViewer,
                originalEvent: eventMap.originalEvent,
                sender: this,
            });
        }
    }

    onMapPreferredSummaryLevelChanged() {
        this._data.maskData.applySummaryLevelUpdate();
        this._data.maskData.layers.forEach(dl => {
            const mapDataDragonflyLayer = this.map.getLayer(dl.id);
            mapDataDragonflyLayer.sourceLayer = dl['source-layer'];
            this.map.setLayerZoomRange(dl.id, dl.minzoom, dl.maxzoom);
            this.map.style._updateLayer(mapDataDragonflyLayer);
        });
    }

    onMapSelectionImport(eventMap) {
        if (eventMap.mapInstanceId !== this.mapInstanceId) {
            return;
        }

        const mapDataInfo = this.mapDataInfo;
        if (!mapDataInfo.summaryLevel || !mapDataInfo.dataset) {
            return;
        }

        const uplodedSummaryLevelId = Object.keys(eventMap.selection)[0];
        const uploadedFeatures = eventMap.selection[uplodedSummaryLevelId].filter(f => !f.isParentSelection);
        const summaryLevel = this.mapConfig.summaryLevels.find(sl => sl.id === uplodedSummaryLevelId);
        if (!summaryLevel) return;

        // push newly selected features to the top
        this._maskFeatures = uploadedFeatures.map(feature => (
            new DataGeoFilterFeature({
                columnValue: feature.fips,
                geoQName: feature.name,
            })
        ));

        this.emit('MAP_MASK_SELECTED_FEATURES_IMPORTED', {
            mapInstanceId: this.mapInstanceId,
            features: this._maskFeatures,
            summaryLevel,
        });
    }

    onMapSelectionEnd(eventMap) {
        if (eventMap.mapInstanceId !== this.mapInstanceId) {
            return;
        }

        const mapDataInfo = this.mapDataInfo;
        if (!mapDataInfo.summaryLevel || !mapDataInfo.dataset) {
            return;
        }

        const selectionFeatures = {};
        eventMap.features.forEach(feature => {
            if (!feature.properties[mapDataInfo.dataset.primaryKeyField] ||
                !feature.properties[mapDataInfo.dataset.geoQNameField]) {
                return;
            }
            if (!selectionFeatures[feature.properties[mapDataInfo.dataset.primaryKeyField]]) {
                selectionFeatures[feature.properties[mapDataInfo.dataset.primaryKeyField]] = {
                    columnValue: feature.properties[mapDataInfo.dataset.primaryKeyField],
                    geoQName: feature.properties[mapDataInfo.dataset.geoQNameField],
                };
            }
        });

        // second point selection of the same feature removes it from selection
        this._maskFeatures = this._maskFeatures.filter(maskFeature => {
            // if feature is already selected, then deselect it
            if (selectionFeatures[maskFeature.columnValue]) {
                delete selectionFeatures[maskFeature.columnValue];
                return eventMap.selectionType !== MapSelectionType.POINT;
            }
            return true;
        });

        // push newly selected features to the top
        this._maskFeatures = Object.values(selectionFeatures).map(selectionFeature => (
            new DataGeoFilterFeature({
                columnValue: selectionFeature.columnValue,
                geoQName: selectionFeature.geoQName,
            })
        )).concat(this._maskFeatures);

        this.emit('MAP_MASK_SELECTED_FEATURES_CHANGED', {
            mapInstanceId: this.mapInstanceId,
            features: this._maskFeatures,
        });
    }

    onMapDeselectFeature(eventMap) {
        if (eventMap.mapInstanceId !== this.mapInstanceId) return;
        const mapDataInfo = this.mapDataInfo;
        if (!mapDataInfo.summaryLevel || !mapDataInfo.dataset) {
            return;
        }
        const featureIndex = this._maskFeatures.findIndex(f => (
            f.columnValue === eventMap.keyValue
        ));
        if (featureIndex !== -1) {
            this._maskFeatures.splice(featureIndex, 1);
        }
        this.emit('MAP_MASK_SELECTED_FEATURES_CHANGED', {
            mapInstanceId: this.mapInstanceId,
            features: this._maskFeatures,
        });
    }

    onMapClearSelectedFeatures(eventMap) {
        if (eventMap.mapInstanceId !== this.mapInstanceId) return;
        this._maskFeatures = [];
        this.emit('MAP_MASK_SELECTED_FEATURES_CHANGED', {
            mapInstanceId: this.mapInstanceId,
            features: this._maskFeatures,
        });
    }
}

export default MaskHandler;
