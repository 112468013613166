import React from 'react';
import PropTypes from 'prop-types';
import LegendEditItem from './LegendEditItem';

const LegendEdit = props => (
    <div className="legend-edit flex-it column">
        {props.annotationLegend.legendItems.map(item => (
            <LegendEditItem
                key={`${item.color}-${item.icon}`}
                legendItem={item}
            />
        ))}
    </div>
);

LegendEdit.propTypes = {
    annotationLegend: PropTypes.object.isRequired,
};
LegendEdit.defaultProps = {
    title: 'Legend',
};

export default LegendEdit;
