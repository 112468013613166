import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Tooltip from '../Tooltip';

class MiniSearch extends React.Component {
    componentDidUpdate() {
        if (this.props.searchExpanded) {
            this.inputRef.focus();
        }
    }

    onClick = () => {
        this.inputRef.select();
    }

    onClearInput = () => {
        this.props.onChange(undefined);
    }

    onChange = e => {
        const newValue = e.target.value;
        if (this.props.onChange) {
            this.props.onChange(newValue);
        }
    }

    render() {
        const { className, onSearchIconClicked, placeholder, searchExpanded, value } = this.props;
        const searchClassNames = classNames('mini-search flex-it center', className, { 'mini-search--expanded': searchExpanded });
        let icon;
        if (searchExpanded) {
            icon = (<i className="material-icons">arrow_back</i>);
        } else {
            icon = (<i className="material-icons">search</i>);
        }

        return (
            <div className={searchClassNames}>
                <Tooltip
                    placement="bottom"
                    mouseEnterDelay={0.2}
                    mouseLeaveDelay={0}
                    overlay={searchExpanded ? 'Back' : 'Search'}
                >
                    <button className="btn-flat-icon" onClick={onSearchIconClicked}>
                        {icon}
                    </button>
                </Tooltip>
                <input
                    ref={i => {
                        this.inputRef = i;
                    }}
                    className="mini-search__input"
                    type="text"
                    placeholder={placeholder}
                    value={value}
                    onChange={this.onChange}
                    onKeyDown={this.onEnterClicked}
                    onClick={this.onClick}
                />
                {value && <button className="mini-search__clear" onClick={this.onClearInput}>
                    clear
                </button>}
            </div>
        );
    }
}

MiniSearch.propTypes = {
    placeholder: PropTypes.string,
    value: PropTypes.string,
    searchExpanded: PropTypes.bool,
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onSearchIconClicked: PropTypes.func.isRequired,
};
MiniSearch.defaultProps = {
    placeholder: 'Search',
    searchExpanded: '',
    className: '',
    value: '',
};

export default MiniSearch;
