/* eslint react/no-array-index-key: 0 */
import React from 'react';
import BusComponent from '../../BusComponent';
import GradientHandle from './GradientHandle';
import AddGradientStop from './AddGradientStop';
import ColorRamp from '../../../objects/ColorRamp';
import { hasParentNode, removeEvent, addEvent } from '../../../helpers/Util';

class GradientPicker extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            numberOfColorRamps: props.colorPalette.colorRamps.length,
        };
        this.boundWindowResize = this.positionHandles;
        this.boundOnMouseMove = this.onMouseMove;
        this.boundOnMouseUp = this.onMouseUp;
        this.boundOnMouseClick = this.onMouseClick;
        this.shouldRender = false;
    }

    componentDidMount() {
        addEvent(window, 'resize', this.boundWindowResize);
        this.positionHandles();
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.colorPalette.equals(nextProps.colorPalette)) {
            this.shouldRender = true;
            this.setState({
                numberOfColorRamps: nextProps.colorPalette.colorRamps.length,
            });
        }
    }

    componentDidUpdate() {
        this.positionHandles();
    }

    shouldComponentUpdate() {
        const shouldRender = this.shouldRender;
        this.shouldRender = false;
        return shouldRender;
    }

    componentWillUnmount() {
        removeEvent(window, 'resize', this.boundWindowResize);
    }

    onMouseMove = e => {
        const self = this;
        const animationHandler = () => {
            e.preventDefault();
            const limits = self.findLimits(self.activeHandle);
            const handleRefs = Object.keys(self.handles);
            const addHandleRefs = Object.keys(self.addHandles);
            self.activeHandle.style.left = `${Math.round(Math.max(limits.leftMax, Math.min(limits.rightMax, (self.activeHandleAnchoringPoint + e.clientX) - self.activeMouseAnchoringPoint)))}px`;
            // measure everything
            const barWidth = self.gradientContainer.getBoundingClientRect().width;
            const layoutInfo = handleRefs.map((handleRef, index) => {
                const li = {};
                if (index > 0) {
                    li.width = Math.abs(self.handles[handleRef].offsetLeft - self.handles[handleRefs[index - 1]].offsetLeft);
                    if (li.width > 56) {
                        li.addHandlePosition = self.handles[handleRefs[index - 1]].offsetLeft + (li.width / 2) + (self.handles[handleRefs[index - 1]].offsetWidth / 2);
                        li.addHandleVisible = true;
                    } else if (li.width < 56) {
                        li.addHandleVisible = false;
                    }
                }
                return li;
            });
            // then update
            const colorPalette = self.props.colorPalette;
            layoutInfo.forEach((li, index) => {
                if (index > 0) {
                    if (li.addHandleVisible) {
                        self.addHandles[addHandleRefs[index - 1]].classList.remove('hidden');
                        self.addHandles[addHandleRefs[index - 1]].style.left = `${li.addHandlePosition}px`;
                    } else {
                        self.addHandles[addHandleRefs[index - 1]].classList.add('hidden');
                    }
                    colorPalette.colorRamps[index - 1].bias = li.width / barWidth;
                }
            });
            self.gradientBar.style.backgroundImage = self.generateGradientStyle(colorPalette);
            self.anotherPendingAnimation = false;
            self.props.applyNewColorPalette(colorPalette);
            document.addEventListener('click', self.boundOnMouseClick, true);
            this.emit('CLOSE_DROPDOWN');
        };
        if (!this.anotherPendingAnimation) {
            this.anotherPendingAnimation = true;
            window.requestAnimationFrame(animationHandler);
        }
    };

    onMouseClick = e => {
        e.stopPropagation();
        document.removeEventListener('click', this.boundOnMouseClick, true);
    };

    onMouseDown = e => {
        if (hasParentNode(e.target, e.currentTarget.getElementsByClassName('color-picker')[0])) {
            return;
        }
        document.body.addEventListener('mousemove', this.boundOnMouseMove);
        document.body.addEventListener('mouseup', this.boundOnMouseUp);
        this.activeHandle = e.currentTarget;
        this.activeMouseAnchoringPoint = e.clientX;
        this.activeHandleAnchoringPoint = e.currentTarget.getBoundingClientRect().left;
    }

    onMouseUp = () => {
        document.body.removeEventListener('mousemove', this.boundOnMouseMove);
        document.body.removeEventListener('mouseup', this.boundOnMouseUp);
    }

    onRemoveHandle = handleRef => {
        const callback = () => {
            const colorPalette = this.props.colorPalette.clone();
            const handleId = parseInt(handleRef.split('_')[1], 10);
            const rampToTheLeft = colorPalette.colorRamps[handleId - 1];
            const rampToTheRight = colorPalette.colorRamps[handleId];
            const newRamp = new ColorRamp();
            newRamp.from = rampToTheLeft.from;
            newRamp.to = rampToTheRight.to;

            colorPalette.colorRamps.splice(handleId - 1, 1);
            colorPalette.colorRamps.splice(handleId - 1, 1);
            colorPalette.colorRamps.splice(handleId - 1, 0, newRamp);
            newRamp.bias = colorPalette.colorRamps.length > 1 ? rampToTheLeft.bias + rampToTheRight.bias : 0;
            delete this.handles[handleRef];
            this.props.applyNewColorPalette(colorPalette);
        };
        setTimeout(callback.bind(this), 0);
    };

    onAddHandle = ramp => {
        const colorPalette = this.props.colorPalette.clone();
        const newRampToTheLeft = new ColorRamp();
        const newRampToTheRight = new ColorRamp();
        const middleColor = ramp.interpolateColors(3)[1];
        const rampId = this.props.colorPalette.colorRamps.indexOf(ramp);
        newRampToTheLeft.from = ramp.from;
        newRampToTheLeft.to = middleColor;
        newRampToTheRight.from = middleColor;
        newRampToTheRight.to = ramp.to;
        newRampToTheLeft.bias = ramp.bias === 0 ? 0.5 : ramp.bias / 2;
        newRampToTheRight.bias = ramp.bias === 0 ? 0.5 : ramp.bias / 2;
        colorPalette.colorRamps.splice(rampId, 1);
        colorPalette.colorRamps.splice(rampId, 0, newRampToTheLeft);
        colorPalette.colorRamps.splice(rampId + 1, 0, newRampToTheRight);
        this.props.applyNewColorPalette(colorPalette);
    };

    render() {
        return (
            <div ref={gradientContainer => { this.gradientContainer = gradientContainer; }} className="gradient-container">
                <div
                    className="gradient-container__bar" ref={gradientBar => { this.gradientBar = gradientBar; }}
                    style={{ backgroundImage: this.generateGradientStyle(this.props.colorPalette) }}
                />
                {this.generateHandles()}
                {this.generateAddHandleButtons()}
            </div>);
    }

    generateGradientStyle(colorPalette) {
        let gradientBarStyle = 'linear-gradient(to right, ';
        let bias = 0;
        colorPalette.colorRamps.forEach((ramp, index) => {
            let rampBias;
            if (ramp.bias === 0) {
                rampBias = (index + 1) / this.props.colorPalette.colorRamps.length;
            } else {
                rampBias = ramp.bias;
            }
            gradientBarStyle = `${gradientBarStyle} ${ramp.from} ${bias * 100}%, ${ramp.to} ${(bias + rampBias) * 100}%`;
            if (index !== colorPalette.colorRamps.length - 1) {
                gradientBarStyle = `${gradientBarStyle}, `;
            } else {
                gradientBarStyle = `${gradientBarStyle})`;
            }
            bias += ramp.bias;
        });
        return gradientBarStyle;
    }

    generateAddHandleButtons() {
        this.addHandles = {};
        return this.props.colorPalette.colorRamps.map((ramp, index) => (<AddGradientStop
            key={index} ramp={ramp} elementRef={h => { this.addHandles[`addHandle_${index}`] = h; }} onAddHandle={this.onAddHandle}
        />));
    }

    generateHandles() {
        const handleRefs = [];
        this.handles = {};
        for (let i = 0; i < this.props.colorPalette.colorRamps.length + 1; i += 1) {
            handleRefs.push(`handle_${i}`);
        }
        return handleRefs.map((handleRef, index) => {
            let gradientHandle;
            if (index === 0) {
                const onApplyColor = from => {
                    const colorPalette = this.props.colorPalette.clone();
                    colorPalette.colorRamps[0].from = from;
                    this.props.applyNewColorPalette(colorPalette);
                };
                gradientHandle = (<GradientHandle
                    id={handleRef}
                    elementRef={hr => { this.handles[handleRef] = hr; }}
                    removeHandle={this.onRemoveHandle}
                    applyColor={onApplyColor}
                    key={handleRef}
                    color={this.props.colorPalette.colorRamps[0].from}
                />);
            } else if (index === handleRefs.length - 1) {
                const onApplyColor = to => {
                    const colorPalette = this.props.colorPalette.clone();
                    colorPalette.colorRamps[index - 1].to = to;
                    this.props.applyNewColorPalette(colorPalette);
                };
                gradientHandle = (<GradientHandle
                    id={handleRef}
                    elementRef={hr => { this.handles[handleRef] = hr; }}
                    removeHandle={this.onRemoveHandle}
                    applyColor={onApplyColor}
                    key={handleRef}
                    color={this.props.colorPalette.colorRamps[index - 1].to}
                />);
            } else {
                const onApplyColor = (color, id) => {
                    const colorPalette = this.props.colorPalette.clone();
                    if (id === 'to') {
                        colorPalette.colorRamps[index].from = color;
                    } else {
                        colorPalette.colorRamps[index - 1].to = color;
                    }
                    this.props.applyNewColorPalette(colorPalette);
                };
                gradientHandle = (<GradientHandle
                    id={handleRef}
                    elementRef={hr => { this.handles[handleRef] = hr; }}
                    removeHandle={this.onRemoveHandle}
                    applyColor={onApplyColor}
                    onMouseDown={this.onMouseDown}
                    key={handleRef}
                    from={this.props.colorPalette.colorRamps[index - 1].to}
                    to={this.props.colorPalette.colorRamps[index].from}
                />);
            }
            return gradientHandle;
        });
    }

    findLimits(handle) {
        const handleRef = handle.dataset.id;
        const reactHandleToTheLeft = this.handles[`handle_${parseInt(handleRef.split('_')[1], 10) - 1}`];
        const reactHandleToTheRight = this.handles[`handle_${parseInt(handleRef.split('_')[1], 10) + 1}`];
        return {
            leftMax: reactHandleToTheLeft.getBoundingClientRect().right,
            rightMax: reactHandleToTheRight.getBoundingClientRect().left - reactHandleToTheRight.getBoundingClientRect().width,
        };
    }

    cleanUpNullHandles = () => {
        Object.keys(this.handles).forEach(h => {
            if (this.handles[h] === null) delete this.handles[h];
        });
        Object.keys(this.addHandles).forEach(h => {
            if (this.addHandles[h] === null) delete this.addHandles[h];
        });
    };

    positionHandles = () => {
        const self = this;
        const animationHandler = () => {
            this.cleanUpNullHandles();
            const handleRefs = Object.keys(self.handles);
            const addHandleRefs = Object.keys(self.addHandles);
            const ramp = self.gradientContainer;
            const boundingRect = ramp.getBoundingClientRect();
            let bias = 0;
            handleRefs.forEach((handleRef, i) => {
                const handle = self.handles[handleRef];
                const halfHandleWidth = handle.getBoundingClientRect().width / 2;
                if (i === 0) {
                    handle.style.left = `${Math.round(boundingRect.left - halfHandleWidth)}px`;
                } else if (i === handleRefs.length - 1) {
                    handle.style.left = `${Math.round(boundingRect.right - halfHandleWidth)}px`;
                } else {
                    bias += self.props.colorPalette.colorRamps[i - 1].bias;
                    handle.style.left = `${Math.round(boundingRect.left + ((boundingRect.width * bias) - halfHandleWidth))}px`;
                }
                handle.style.top = `${boundingRect.bottom}px`;
            });

            addHandleRefs.forEach((addHandleRef, index) => {
                if ((index + 1) > addHandleRefs.length) return;
                const width = Math.abs(self.handles[handleRefs[index + 1]].offsetLeft - self.handles[handleRefs[index]].offsetLeft);
                if (width > 56) {
                    self.addHandles[addHandleRef].classList.remove('hidden');
                    self.addHandles[addHandleRef].style.top = `${boundingRect.bottom - 20}px`;
                    self.addHandles[addHandleRef].style.left = `${Number((self.handles[handleRefs[index]].offsetLeft + (width / 2) + (self.handles[handleRefs[index]].offsetWidth / 2)).toFixed(2))}px`;
                } else {
                    self.addHandles[addHandleRef].classList.add('hidden');
                }
            });

            self.pendingAnimation = false;
        };
        if (!this.pendingAnimation) {
            this.pendingAnimation = true;
            window.requestAnimationFrame(animationHandler);
        }
    }

}

export default GradientPicker;
