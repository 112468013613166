// @ts-check
import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param {object} props
 * @param {string} props.className
 * @param {() => void} props.onClick
 * @param {string} props.label
 * @param {string} props.icon
 */
const LayerLibraryButton = ({ className, onClick, label, icon }) => (
    <button className={className} onClick={onClick}>
        <i className="material-icons btn-chip__icon">{icon}</i>
        {label}
    </button>
);

LayerLibraryButton.propTypes = {
    className: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
};

export default LayerLibraryButton;
