import React from 'react';
import { injectIntl } from 'react-intl';
import BusComponent from '../BusComponent';
import VisualizationType from './selectors/visualizationType/VisualizationType';
import ColorPaletteSelector from './selectors/colorPaletteSelector/ColorPaletteSelector';
import BubbleVisualizationEditor from './BubbleVisualizationEditor';
import VisualizationPercentagesControl from './VisualizationPercentagesControl';
import ShadedVisualizationEditor from './ShadedVisualizationEditor';
import ApplicationMode from '../../enums/ApplicationMode';
import VisualizationTypes from '../../enums/VisualizationType';
import VariableValueType from '../../enums/VariableValueType';
import ColorPaletteList from './selectors/colorPaletteSelector/colorPaletteList/ColorPaletteList';
import Panel from '../panel/Panel';

class VisualizationEditor extends BusComponent {
    static NAME = 'VisualizationEditor';

    constructor(props, context) {
        super(props, context);

        this.state = {
            currentColorPalettes: undefined,
            colorPalettesEditable: undefined,
            colorPaletteFlipped: props.mapInstance.dataTheme.colorPaletteFlipped,
            colorPaletteListVisible: false,
            visualizationType: undefined,
            bubbleVisualizationTypeEnabled: false,
            shadedVisualizationTypeEnabled: false,
            dotDensityVisualizationTypeEnabled: false,
        };

        this.bindGluBusEvents({
            MAP_NEW_COLOR_PALETTE_APPLIED: this.onMapNewColorPaletteApplied,
            MAP_COLOR_PALETTES_CHANGED: this.onColorPaletteListChanged,
            CURRENT_COLOR_PALETTES: this.onCurrentColorPalettesRetrieved,
            MAP_NEW_DATA_THEME_APPLIED: this.onMapNewDataThemeApplied,
            VISUALIZATION_TYPE_AVAILABILITY_INFO: this.onVisualizationTypeAvailabilityInfo,
            OPEN_COLOR_PALETTE_EDITOR: this.onPaletteClick,
        });
    }

    componentWillMount() {
        this.emit('GET_VISUALIZATION_TYPE_AVAILABILITY_INFO_REQUEST', this.props.mapInstance.dataTheme.variableSelection);
        this.emit('MAP_GET_CURRENT_COLOR_PALETTES_REQUEST', { mapInstanceId: this.props.mapInstance.id });
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.mapInstance !== nextProps.mapInstance) {
            this.emit('GET_VISUALIZATION_TYPE_AVAILABILITY_INFO_REQUEST', nextProps.mapInstance.dataTheme.variableSelection);
            this.emit('MAP_GET_CURRENT_COLOR_PALETTES_REQUEST', { mapInstanceId: nextProps.mapInstance.id });
        }
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }


    onVisualizationTypeAvailabilityInfo(e) {
        this.setState({
            bubbleVisualizationTypeEnabled: e.bubbleVisualizationTypeEnabled,
            shadedVisualizationTypeEnabled: e.shadedVisualizationTypeEnabled,
            dotDensityVisualizationTypeEnabled: e.dotDensityVisualizationTypeEnabled,
        });
    }

    onMapNewColorPaletteApplied(e) {
        if (this.props.mapInstance.id === e.source.id) {
            this.setState({
                selectedColorPalette: e.selectedColorPalette,
                colorPaletteFlipped: e.colorPaletteFlipped,
            });
        }
    }

    onMapNewDataThemeApplied(e) {
        if (e.source.id === this.props.mapInstance.id) {
            this.emit('GET_VISUALIZATION_TYPE_AVAILABILITY_INFO_REQUEST', this.props.mapInstance.dataTheme.variableSelection);
            this.emit('MAP_GET_CURRENT_COLOR_PALETTES_REQUEST', { mapInstanceId: this.props.mapInstance.id });
            this.setState({
                colorPaletteFlipped: this.props.mapInstance.dataTheme.colorPaletteFlipped,
            });
        }
    }

    onColorPaletteListChanged(e) {
        this.setState({
            colorPalettesCollection: e.colorPalettesCollection,
            selectedColorPalette: e.selectedColorPalette,
        });
    }

    onChangeVisualizationType = visualizationType => {
        if (visualizationType === this.props.mapInstance.dataTheme.visualizationType) return;
        this.emit('VISUALIZATION_TYPE_CHANGE', {
            source: this,
            mapInstanceId: this.props.mapInstance.id,
            newVisualizationType: visualizationType,
        });
    }

    onCurrentColorPalettesRetrieved = e => {
        if (this.props.mapInstance.id === e.mapInstanceId) {
            this.setState({
                selectedColorPalette: e.selectedColorPalette,
                colorPalettesCollection: e.colorPalettesCollection,
            });
        }
    }

    onPaletteClick = () => {
        this.setState({
            colorPaletteListVisible: true,
        });
    }

    onPaletteListBackClick = () => {
        this.setState({
            colorPaletteListVisible: false,
        });
    }

    onApplyColorPalette = palette => {
        if (palette) {
            this.emit('APPLY_COLOR_PALETTE_REQUEST', {
                source: this,
                mapInstanceId: this.props.mapInstance.id,
                colorPalette: palette,
            });
        }
    }

    onEditPaletteClick = palette => {
        this.emit('COLOR_PALETTE_POPUP_REQUEST', {
            paletteEditorType: this.state.paletteEditorType,
            colorPalette: palette,
            colorPalettesList: this.state.colorPalettesCollection,
            mapInstanceId: this.props.mapInstance.id,
            editMode: true,
        });
        this.emit('CLOSE_DROPDOWN');
    }

    onNewPaletteClick = () => {
        this.emit('COLOR_PALETTE_POPUP_REQUEST',
            {
                colorPalette: this.state.selectedColorPalette,
                colorPalettesList: this.state.colorPalettesCollection,
                mapInstanceId: this.props.mapInstance.id,
            }
        );
        this.emit('CLOSE_DROPDOWN');
    }

    onFlipColorPalette = () => {
        this.emit('FLIP_COLOR_PALETTE_REQUEST', {
            source: this,
            mapInstanceId: this.props.mapInstance.id,
        });
    }

    render() {
        const { mapInstance } = this.props;
        const { applicationMode, isCondensedLayout } = this.context;
        const {
            shadedVisualizationTypeEnabled,
            colorPalettesCollection,
            selectedColorPalette,
            colorPaletteFlipped,
            colorPaletteListVisible,
            bubbleVisualizationTypeEnabled,
            dotDensityVisualizationTypeEnabled,
        } = this.state;

        if (colorPaletteListVisible) {
            const headerActions = [];

            // Cuurently we don't see the need for this feature
            // if (!isCondensedLayout) {
            //     headerActions.push(<button key="new-palette-button" className="btn-chip" onClick={this.onNewPaletteClick}>
            //         <i className="material-icons btn-chip__icon" aria-label="Plus icon">add</i>
            //         {this.props.intl.formatMessage({ id: 'visualisationType.newPalette' })}
            //     </button>);
            // }


            return (<div className="visualization-editor">
                <Panel
                    className="panel--color-palette"
                    backIcon={<i className="material-icons">keyboard_arrow_left</i>}
                    title={this.props.intl.formatMessage({ id: 'visualisationType.colorPalette' })}
                    headerActions={headerActions}
                    onBackClick={this.onPaletteListBackClick}
                >
                    <ColorPaletteList
                        colorPalettesCollection={colorPalettesCollection}
                        selectedColorPalette={selectedColorPalette}
                        colorPalettesEditable={applicationMode !== ApplicationMode.VIEW}
                        mapInstanceId={mapInstance.id}
                        onColorPaletteClick={this.onApplyColorPalette}
                        onEditPaletteClick={this.onEditPaletteClick}
                    />
                </Panel>
            </div>);
        }

        const { dataTheme } = mapInstance;
        const { visualizationType, bubbleValueType } = dataTheme;

        let editor;
        if (mapInstance) {
            switch (visualizationType) {
            case VisualizationTypes.SHADED_AREA:
                editor = (<ShadedVisualizationEditor
                    mapInstanceId={mapInstance.id}
                    mapInstance={mapInstance}
                    colorPaletteFlipped={colorPaletteFlipped}
                />);
                break;
            case VisualizationTypes.BUBBLES:
                editor = (<BubbleVisualizationEditor
                    mapInstanceId={mapInstance.id}
                    mapInstance={mapInstance}
                    colorPaletteFlipped={colorPaletteFlipped}
                />);
                break;
            }
        }
        const colorPaletteFlippable = (visualizationType === VisualizationTypes.SHADED_AREA) ||
            (visualizationType === VisualizationTypes.BUBBLES && bubbleValueType !== VariableValueType.NUMBER);

        return (
            <div className="visualization-editor">
                <div className="flex-it column grow flex-start stretch">
                    <div className="flex-it column no-shrink">
                        <VisualizationType
                            mapInstanceId={mapInstance.id}
                            mapInstance={mapInstance}
                            shadedVisualizationTypeEnabled={shadedVisualizationTypeEnabled}
                            bubbleVisualizationTypeEnabled={bubbleVisualizationTypeEnabled}
                            dotDensityVisualizationTypeEnabled={dotDensityVisualizationTypeEnabled}
                            onChangeVisualizationType={this.onChangeVisualizationType}
                        />
                        <ColorPaletteSelector
                            mapInstance={mapInstance}
                            percentageControl={
                                <VisualizationPercentagesControl
                                    mapInstance={this.props.mapInstance}
                                />
                            }
                            selectedColorPalette={selectedColorPalette}
                            colorPaletteFlippable={colorPaletteFlippable}
                            colorPaletteFlipped={colorPaletteFlipped}
                            onPaletteClick={this.onPaletteClick}
                            onFlipColorPalette={this.onFlipColorPalette}
                        />
                    </div>
                    {editor}
                </div>
            </div>
        );
    }
}

export default injectIntl(VisualizationEditor);
