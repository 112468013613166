import Api from '../apis/Api';
import GLU from '../glu2.js/src/index';

class MailHookDataSource extends GLU.DataSource {
    static get name() {
        return 'MailHookDataSource';
    }

    static getInstance() {
        return new MailHookDataSource();
    }

    postSelfStorageFacilityDataChange(payload) {
        return new Promise((resolve, reject) => {
            Api.mailHook.emailNotification({ payload })
                .then(response => {
                    if (response.error) {
                        reject();
                    } else {
                        resolve();
                    }
                }, reject);
        });
    }
}

export default MailHookDataSource;
