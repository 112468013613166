import React from 'react';
import BusComponent from '../BusComponent';

// This component is used to dynamically make a screen reader notify a user that some action has been completed.
class AccessibilityAlertBox extends BusComponent {
    constructor(props, context) {
        super(props, context);

        this.state = { alertText: '' };
    }

    componentDidMount() {
        this.bindGluBusEvents({
            SET_ACCESSIBLE_ALERT_TEXT: this.onSetAlertText,
        });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    onSetAlertText(alertText) {
        this.setState({ alertText });
        setTimeout(() => {
            // It is enought for an alert to be present for a milisecond, and screen readers will pronouce it
            this.setState({ alertText: '' });
        });
    }

    render() {
        return (<div role="region" id="accessible-alert-box" aria-live="polite">
            {this.state.alertText}
        </div>);
    }
}

export default AccessibilityAlertBox;
