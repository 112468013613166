import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import BusComponent from '../BusComponent';
import FilterStatus from '../../enums/FilterStatus';
import Panel from '../panel/Panel';
import Tooltip from '../Tooltip';
import TabbedBrowser from '../tabbedBrowser/TabbedBrowser';
import CategoriesTab from './CategoriesTab';
import AllDataTab from './AllDataTab';
import Loader from '../Loader';

const BrowseType = {
    CATEGORY: 0,
    SURVEY: 1,
};

class VariableEditor extends BusComponent {
    constructor(props, context) {
        super(props, context);
        const { filter } = props;
        let browseType = BrowseType.CATEGORY;
        // if the field is already selected but categoryName is empty show the survey tab
        if (filter.status >= FilterStatus.VARIABLE_SELECTED && !filter.field.categoryName) {
            browseType = BrowseType.SURVEY;
        }
        this.state = {
            categoryName: filter.field.categoryName,
            hasData: false,
            browseType,
            currentMetadataSelection: undefined,
            mapsGroupAvailableDatasets: undefined,
            mapsGroupDataCategories: [],
        };
    }

    componentDidMount() {
        const { mapInstance } = this.props;
        if (this.closeButton) this.closeButton.focus();
        this.bindGluBusEvents({
            METADATA_LOAD_SUCCESS: this.onMetadataLoadSuccess,
            MAP_APPLY_DATA_THEME_REQUEST: this.onMapApplyDataThemeRequest,
        });

        this.bus.emit('METADATA_LOAD_REQUEST', {
            metadataGroupId: mapInstance.metadataGroupId,
            mapInstanceId: mapInstance.id,
            variableSelectionItems: mapInstance.dataTheme.variableSelection.items,
        });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    onMetadataLoadSuccess(eventMap) {
        const {
            currentMetadataSelection,
            mapsGroupAvailableDatasets,
            currentGroupMetadata,
            mapsGroupDataCategories,
        } = eventMap;

        const { survey } = currentMetadataSelection;

        // Filter the available categories by year and survey
        const availableDataCategories = mapsGroupDataCategories.filter(
            dc => dc.availableYears.indexOf(survey.year) !== -1 && survey.hasCategory(dc.name));

        this.setState({
            currentMetadataSelection,
            mapsGroupAvailableDatasets,
            currentGroupMetadata,
            mapsGroupDataCategories: availableDataCategories,
            hasData: true,
        });
    }

    onMapApplyDataThemeRequest(eventMap) {
        const { mapInstanceId } = this.props;
        if (eventMap.mapInstanceId === mapInstanceId) {
            this.emit('GROUPS_METADATA_REQUEST', { mapInstanceId, source: this });
        }
    }

    onChangeCategory = categoryName => {
        this.setState({
            categoryName,
        });
    };

    onChangeBrowseType = type => {
        this.setState({
            browseType: type,
        });
    }

    onSelectVariable = variable => {
        const { browseType, categoryName } = this.state;
        const { filter, mapInstanceId } = this.props;

        this.emit('UPDATE_FILTER_VARIABLE_REQUEST',
            {
                filterId: filter.filterId,
                variable,
                mapInstanceId,
                categoryName: browseType === BrowseType.CATEGORY ? categoryName : undefined,
            });
    };

    render() {
        const {
            hasData,
            mapsGroupDataCategories,
            categoryName,
            currentMetadataSelection,
            mapsGroupAvailableDatasets,
            browseType,
        } = this.state;
        const { mapInstanceId, mapInstance, onClose, filter } = this.props;
        const { fieldName, tableName } = filter.field;
        const tabNames = [
            {
                name: this.props.intl.formatMessage({ id: 'dataBrowser.allData' }),
            }];
        const tabs = [
            <AllDataTab
                key="all-data"
                onSelectVariable={this.onSelectVariable}
                fieldName={fieldName}
                mapsGroupAvailableDatasets={mapsGroupAvailableDatasets}
                currentMetadataSelection={currentMetadataSelection}
                mapInstanceId={mapInstanceId}
                field={filter.field}
                tableName={tableName}
            />,
        ];
        // if there are no categories do not show the categories tab
        if (mapsGroupDataCategories.length > 0) {
            tabNames.unshift(
                {
                    name: this.props.intl.formatMessage({ id: 'categories' }),
                }
            );
            tabs.unshift(<CategoriesTab
                key="categories"
                onSelectVariable={this.onSelectVariable}
                fieldName={fieldName}
                mapsGroupDataCategories={mapsGroupDataCategories}
                categoryName={categoryName}
                mapInstanceId={mapInstanceId}
                metadataGroupId={mapInstance.metadataGroupId}
                currentMetadataSelection={currentMetadataSelection}
                onChangeCategory={this.onChangeCategory}
            />);
        }

        return (<div className="variable-editor-container">
            <div className="variable-editor__strip" />
            <Panel
                className="variable-editor"
                customHeader={(
                    <div className="variable-editor__header no-shrink flex-it center space-between" >
                        <div className="flex-it column grow">
                            <div className="flex-it center variable-editor__header__title">
                                {this.props.intl.formatMessage({ id: 'dataBrowser.chooseVariable' })}
                            </div>
                        </div>
                        <Tooltip overlay={this.props.intl.formatMessage({ id: 'close' })} >
                            <button
                                className="btn-flat-icon material-icons variable-editor__header__cancel-button"
                                ref={ref => { this.closeButton = ref; }}
                                onClick={onClose}
                            >close
                            </button>
                        </Tooltip>
                    </div>
                )}
            >
                {hasData &&
                    <TabbedBrowser
                        className="variable-editor__tabs"
                        tabNames={tabNames}
                        selectedTabIndex={browseType}
                        tabSwitchedCallback={this.onChangeBrowseType}
                    >
                        {tabs}
                        </TabbedBrowser>
                }
                {!hasData && <div className="grow flex-it center justify-center">
                    <Loader text={this.props.intl.formatMessage({ id: 'variablePicker.gettingMetadata' })} />
                </div> }
            </Panel>
        </div>);
    }
}

VariableEditor.propTypes = {
    mapInstance: PropTypes.object.isRequired,
    mapInstanceId: PropTypes.string.isRequired,
    filter: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(VariableEditor);
