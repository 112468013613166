// @ts-check
import React from 'react';
import { injectIntl } from 'react-intl';

import BusComponent from '../BusComponent';
import SimpleDropdown from '../dropdown/SimpleDropdown';

/**
 *
 * @typedef State
 * @property {import('../../').SummaryLevel[]} summaryLevels
 * @property {import('../../').SummaryLevel} activeSummaryLevel
 *
 * @typedef Props
 * @property {string} mapInstanceId
 * @property {import('react-intl').intlShape} intl
 *
 * @extends BusComponent<Props, State>
 */
class SummaryLevelSelection extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            summaryLevels: [],
            activeSummaryLevel: undefined,
        };
    }

    componentDidMount() {
        this.bindGluBusEvents({
            CURRENT_REPORT_INFO: this.onCurrentReportInfo,
            MAP_PREFERRED_SUMMARY_LEVEL_CHANGED: this.onSummaryLevelChanged,
        });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    /**
     * @param {object} param
     * @param {import('../../').SummaryLevel} param.activeSummaryLevel
     * @param {import('../../').SummaryLevel[]} param.summaryLevels
     */
    onCurrentReportInfo = ({ activeSummaryLevel, summaryLevels }) => {
        this.setState({
            summaryLevels,
            activeSummaryLevel,
        });
    };

    onSummaryLevelChanged = ({ source }) => {
        if (source.id !== this.props.mapInstanceId) return;
        if (source.activeSummaryLevel !== this.state.activeSummaryLevel) {
            this.setState({ activeSummaryLevel: source.activeSummaryLevel });
        }
    };

    /** @param {string} summaryLevelId */
    onChangeSummaryLevel = summaryLevelId => {
        this.emit('CLOSE_DROPDOWN');
        const summaryLevel = this.state.summaryLevels.find(
            sl => sl.id === summaryLevelId,
        );
        this.emit('SET_PREFERRED_SUMMARY_LEVEL_REQUEST', {
            mapInstanceId: this.props.mapInstanceId,
            summaryLevel,
        });
    };

    render() {
        const selectedSummaryLevelItem = this.state.activeSummaryLevel
            ? {
                id: this.state.activeSummaryLevel.id,
                text: this.state.activeSummaryLevel.label,
            }
            : { id: -1, text: 'Loading ...' };
        const summaryLevelItems = this.state.summaryLevels.map(sl => ({
            id: sl.id,
            text: sl.label,
            fullText: sl.title ? sl.title.replace('Data:', '') : undefined,
        }));

        return (
            <div className="report-options__summary-level-selector">
                <SimpleDropdown
                    title={this.props.intl.formatMessage({ id: 'geography' })}
                    onItemClick={this.onChangeSummaryLevel}
                    mapInstanceId={this.props.mapInstanceId}
                    items={summaryLevelItems}
                    selectedItem={selectedSummaryLevelItem}
                    showFilter
                    filterPlaceholder={this.props.intl.formatMessage({
                        id: 'header.searchGeography',
                    })}
                />
            </div>
        );
    }
}

export default injectIntl(SummaryLevelSelection);
