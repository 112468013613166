import React from 'react';
import { injectIntl } from 'react-intl';
import BusComponent from '../../BusComponent';
import TextInput from '../../form/TextInput';
import AppConfig from '../../../appConfig';
import { copyTextFromElementTargetToClipboard } from '../../../helpers/Util';
import { copyToClipboardButtonLinkEvents } from '../../../enums/GoogleTagManagerEvents';
import googleTagManagerEvents from '../../../helpers/GoogleTagManagerEventsWrapper';

const CopyLinkButton = googleTagManagerEvents('button', copyToClipboardButtonLinkEvents);

class LinkTab extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            copiedToClipboard: false,
        };
    }

    componentWillMount() {
        if (this.state.copiedToClipboard) {
            this.setState({
                copiedToClipboard: false,
            });
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    onCopyToClipboardClicked = () => {
        this.setState({
            copiedToClipboard: true,
        });
        copyTextFromElementTargetToClipboard(this.link);
        this.timeout = setTimeout(() => {
            this.setState({
                copiedToClipboard: false,
            });
        }, 2000);
        this.emit('SET_ACCESSIBLE_ALERT_TEXT', 'Link is copied to clipboard');
    };

    render() {
        const { isPublic, snapshot } = this.props;
        return (
            <div className="link-tab flex-it grow column">
                <div className="flex-it column padded-24">
                    <span className="tab-label">
                        {this.props.intl.formatMessage({ id: 'header.linkToShare' })}{' '}
                        {!isPublic
                            ? this.props.intl.formatMessage({
                                  id: 'header.notAccessible',
                              })
                            : ''}
                    </span>
                    <TextInput
                        oldStyle
                        className="share-input share-input--readonly"
                        elementRef={link => {
                            this.link = link;
                        }}
                        value={`${AppConfig.constants.links.shareRootURL}/${snapshot.viewCode}/view`}
                        readOnly
                    />
                    <div className="flex-it flex-end">
                        <CopyLinkButton
                            className="btn-flat"
                            onClick={this.onCopyToClipboardClicked}
                        >
                            {this.state.copiedToClipboard ? 'COPIED' : 'COPY LINK'}
                        </CopyLinkButton>
                    </div>
                </div>
            </div>
        );
    }
}

export default injectIntl(LinkTab);
