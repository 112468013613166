module.exports={
  "backToDashboard": "Back to dashboard",
  "headerLogoTooltipText": "Back to dashboard",
  "exportAsImage": "Export as image",
  "takeATour": "Take a tour",
  "saveAs": "Save as",
  "saveAsAnnotation": "Add as annotation",
  "annotationSaved": "Annotation added",
  "removeAnnotation": "Remove annotation",
  "saveAsNew": "Save as new",
  "upgradeToProfessionalPlan": "Upgrade to Professional plan to access all features.",
  "searchGeography": "Search geography",
  "shareWithOthers": "Share with others",
  "linkToShare": "Link to share ",
  "link": "Link",
  "embed": "Embed",
  "copyLink": "COPY LINK",
  "myAccount": "My Account",
  "userAvatar": "User Avatar",
  "myLicenses": "My Licenses",
  "signOut": "Sign Out",
  "mapOptionsAreNotAvailableInCurrentMode": "Map options are not available in current mode",
  "notAccessible": "(not accessible when project is private)",
  "editProject": "Edit project",
  "exportImage": "Export image",
  "logIn": "Log in",
  "shareVia": "Share via",
  "signUp": "Sign up",
  "introductionToMaps": "Introduction to Maps",
  "citation": "Citation",
  "guidesAndVideos": "Guides and Videos",
  "visitHelpCenter": "Visit Help Center",
  "help": "Help",
  "editTitleAndDescription": "Edit title and description",
  "openIntroductionToMapsInNewTab": "Open 'Introduction to Maps' in new tab",
  "openCitationInNewTab": "Open 'Citation' in new tab",
  "openGuidesAndVideosInNewTab": "Open 'Guides and Videos' in new tab",
  "visitHelpCenterInNewTab": "Visit Help Center in new tab"
}
