import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../Tooltip';

const SearchBoxTrigger = props =>
    <Tooltip
        placement="left"
        mouseEnterDelay={0.5}
        mouseLeaveDelay={0}
        overlay={<span className="light-text">Search</span>}
    >
        <button className="search-box__trigger" onClick={props.handleTriggerClick}>
            <div className="btn-search flex-it justify-center center clickable">
                <i className="material-icons">search</i>
            </div>
        </button>
    </Tooltip>;

SearchBoxTrigger.propTypes = {
    handleTriggerClick: PropTypes.func.isRequired,
};

export default SearchBoxTrigger;

