import Api from '../apis/Api';
import GLU from '../glu2.js/src/index';

import AppConfig from '../appConfig';

class VisualReportDataSource extends GLU.DataSource {
    static get name() {
        return 'VisualReportDataSource';
    }

    static getInstance() {
        return new VisualReportDataSource();
    }

    constructor() {
        super(() => false);
        /** @type {import('../types').VisualReport[]} */
        this._visualReports = [];
    }

    /** @returns {import('../types').VisualReport[]}  */
    get visualReports() {
        return this._visualReports;
    }
    /**
     * @returns {Promise<import('../types').VisualReport[]>}
     */
    loadVisualReports = () =>
        new Promise((resolve, reject) => {
            Api.visualReport
                .getVisualReports({
                    query: { tenant: AppConfig.constants.tenant },
                })
                .then(response => {
                    if (response.body) {
                        // TODO: I think this can be replaced with:
                        // this._visualReports = response.body.map(this.parseVisualReportResponse);
                        this._visualReports = response.body.map(
                            visualReportResponse => {
                                const visualReport =
                                    this.parseVisualReportResponse(
                                        visualReportResponse,
                                    );

                                return visualReport;
                            },
                        );
                    }
                    resolve(this._visualReports);
                }, reject);
        });

    /**
     * @param {import('../types').VisualReport} visualReport
     * @returns {Promise<import('../types').VisualReport>}
     */
    saveVisualReport = visualReport => {
        const { title, point, profile, selection, reportType } = visualReport;
        const payload = {
            title,
            definition: {
                point,
                selection,
                profile,
            },
            type: reportType,
            tenant: AppConfig.constants.tenant,
        };
        return new Promise(resolve => {
            Api.visualReport.addVisualReport({ payload }).then(response => {
                const vReport = this.parseVisualReportResponse(response.body);
                this._visualReports.push(vReport);
                resolve(visualReport);
            });
        });
    };

    // TODO: this has nothing to do with data source. It should be a helper method
    // (if not sure where to put it, let it live in this file, but still, it
    // shouldn't be a method of the class)
    /**
     * @param {import('../types').VisualReportResponse} visualReportResponse
     * @returns {import('../types').VisualReport}
     */
    parseVisualReportResponse = visualReportResponse => ({
        title: visualReportResponse.title,
        point: visualReportResponse.definition.point,
        selection: visualReportResponse.definition.selection,
        profile: visualReportResponse.definition.profile,
        reportType: visualReportResponse.type,
    });
}

export default VisualReportDataSource;
