// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import UserLocationListItem from './UserLocationListItem';
import MapSelect from '../mapSelect/MapSelect';
import BusComponent from '../BusComponent';

/**
 * @typedef State
 * @property {string} selectedMapInstanceId
 * @property {boolean} downloadInProgress
 *
 * @typedef Props
 * @property {import('../../objects/LocationAnalysisItem').default[]} userLocations
 * @property {string[]} mapInstanceIds
 * @property {() => void} onClose
 * @property {import('react-intl').intlShape} intl
 *
 * @extends {BusComponent<Props, State>}
 */
class UserLocationList extends BusComponent {
    constructor(props) {
        super(props);
        this.state = {
            selectedMapInstanceId: this.props.mapInstanceIds[0],
            downloadInProgress: false,
        };
    }

    onMapInstanceSelectionChange = selectedMapInstanceId => {
        this.setState({ selectedMapInstanceId });
    };

    render() {
        const {
            userLocations,
            onClose,
            intl,
        } = this.props;

        return (
            <div className="user-locations-list-content">
                <div className="user-locations-header">
                    {intl.formatMessage({
                        id: 'userLocation.myPlaces',
                    })}
                    <button className="btn-icon" onClick={onClose}>
                        <i className="material-icons">close</i>
                    </button>
                </div>
                {this.props.mapInstanceIds.length > 1 && (
                    <MapSelect
                        onChange={this.onMapInstanceSelectionChange}
                        selectedMapInstanceId={this.state.selectedMapInstanceId}
                        mapInstanceIds={this.props.mapInstanceIds}
                    />
                )}
                <div className="user-locations-list-items">
                    {userLocations.map(userLocation => (
                        <UserLocationListItem
                            userLocation={userLocation}
                            mapInstanceId={this.state.selectedMapInstanceId}
                            key={userLocation.itemOriginMetadata.id}
                        />
                    ))}
                </div>
            </div>
        );
    }
}

UserLocationList.propTypes = {
    userLocations: PropTypes.array.isRequired,
    mapInstanceIds: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(UserLocationList);
