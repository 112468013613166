import Api from '../apis/Api';
import GLU from '../glu2.js/src/index';

class FeedbackDataSource extends GLU.DataSource {
    static get name() {
        return 'FeedbackDataSource';
    }

    static getInstance() {
        return new FeedbackDataSource();
    }

    postFeedback(payload) {
        return new Promise((resolve, reject) => {
            Api.feedback.sendFeedback({ payload })
                .then(response => {
                    const responseXMLDocument = response.xhr.responseXML;

                    const error = responseXMLDocument.getElementsByTagName('Error')[0];

                    if (error) {
                        reject(error.textContent);
                        return;
                    }
                    const responseNode = responseXMLDocument.getElementsByTagName('Response')[0];
                    const result = responseNode ? responseNode.getAttribute('result') : undefined;
                    if (result !== undefined && result === 'Sent') {
                        resolve();
                    } else {
                        reject('No result found');
                    }
                }, reject);
        });
    }
}

export default FeedbackDataSource;
