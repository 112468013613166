import uuid from 'node-uuid';
import FrameLockMode from '../enums/FrameLockMode';
import FrameType from '../enums/FrameType';

const MAP_FRAME_TYPES = [
    FrameType.SINGLE_MAP,
    FrameType.MAP_EDITOR,
    FrameType.SWIPE,
    FrameType.SIDE_BY_SIDE_MAPS,
];

class Frame {
    constructor() {
        this._id = uuid.v4();
        this._lockMode = FrameLockMode.POSITION;
        this._type = FrameType.SINGLE_MAP;
        this._title = '';
        this._description = '';
    }

    get id() {
        return this._id;
    }

    get type() {
        return this._type;
    }

    set type(type) {
        this._type = type;
    }

    get lockMode() {
        if (this._type === FrameType.SWIPE) {
            return FrameLockMode.POSITION;
        }

        return this._lockMode || FrameLockMode.POSITION;
    }

    set lockMode(lockMode) {
        this._lockMode = lockMode;
    }

    get included() {
        return this._included || false;
    }

    set included(included) {
        this._included = included;
    }

    get isMapFrame() {
        return MAP_FRAME_TYPES.includes(this._type);
    }

    /** @type {import('./MapInstance').default[]} */
    get mapInstances() {
        return this._mapInstances || [];
    }

    set mapInstances(mapInstances) {
        this._mapInstances = mapInstances || [];
    }

    get media() {
        return this._media || [];
    }

    mediaByType(type) {
        return this.media.filter(mediaItem => mediaItem.type === type);
    }

    set media(media) {
        this._media = media;
    }

    get startButtonLabel() {
        return this._startButtonLabel || 'Start';
    }

    set startButtonLabel(startButtonLabel) {
        this._startButtonLabel = startButtonLabel;
    }

    get title() {
        return this._title;
    }

    set title(title) {
        this._title = title;
    }

    get description() {
        return this._description;
    }

    set description(description) {
        this._description = description;
    }

    get thumbnail() {
        // thumbnails for all other types are generated automatically
        if (this._thumbnail) {
            return this._thumbnail;
        }

        // if thumbnail wasn't resolved up to this point, return hardcoded data
        return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
    }

    set thumbnail(thumbnail) {
        this._thumbnail = thumbnail;
    }

    get thumbnailLarge() {
        return this._thumbnailLarge;
    }

    set thumbnailLarge(thumbnailLarge) {
        this._thumbnailLarge = thumbnailLarge;
    }

    getMapInstanceById(id) {
        return this.mapInstances.find(mi => mi.id === id);
    }

    equals(that) {
        return (this.type === that.type &&
                this.lockMode === that.lockMode &&
                this.included === that.included &&
                this.thumbnail === that.thumbnail &&
                this.mapInstances.every((mapInstance, index) => mapInstance.equals(that.mapInstances[index])) &&
                this.media.every((media, index) => media === that.media[index]) &&
                this.startButtonLabel === that.startButtonLabel &&
                this.title === that.title &&
                this.description === that.description);
    }

    clone() {
        const clonedFrames = Object.assign(new Frame(), JSON.parse(JSON.stringify(this)));
        clonedFrames.mapInstances = this.mapInstances.map(mapInstance => mapInstance.clone());
        return clonedFrames;
    }

    toJSON() {
        return {
            title: this.title,
            description: this.description,
            startButtonLabel: this.startButtonLabel,
            thumbnail: this._thumbnail,
            type: this.type,
            lockMode: this.lockMode,
            included: this.included,
            mapInstances: this.mapInstances,
            media: this.media,
        };
    }
}

export default Frame;
