// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import LocationAnalysisType from '../../enums/LocationAnalysisType';

/**
 * @param {object} Props
 * @param {LocationAnalysisType} Props.analysisType
 * @param {Set<number>} Props.selection
 */
const LocationPanelDistance = ({ analysisType, selection }) => {
    const selectionArray = Array.from(selection);
    const unit = analysisType.MAPBOX_ROUTING_PROFILE ?
        (
            <FormattedMessage id="min" />
        ) :
        (
            <FormattedMessage
                id="miles"
                values={{
                    count:
                        selectionArray.length > 1
                            ? selectionArray.length
                            : selectionArray[0],
                }}
            />
        );
    const distance = selectionArray.sort((a, b) => a - b).join(', ');
    return (
        <div className="location-panel-mini__distance">
            <i className="material-icons location-panel-mini__symbol">
                {analysisType.ICON}
            </i>
            <div className="location-panel-mini__distance">
                <span>{distance}</span>
                {unit}
            </div>
        </div>
    );
};

LocationPanelDistance.propTypes = {
    analysisType: PropTypes.object.isRequired,
    selection: PropTypes.object.isRequired,
};

export default LocationPanelDistance;
