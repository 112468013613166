import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import IndicatorItem from './IndicatorItem';

const EditFilterAndMaskDialog = ({ onClearMask, onClearFilter, onEditMask, onEditFilter, intl }) => (
    <div className="edit-filter-mask-dialog">
        <div className="flex-it column">
            <IndicatorItem
                icon={<i className="socex-icon-mask-1" />}
                title={intl.formatMessage({ id: 'dataBrowser.maskMapData' })}
                classes="indicator-item__mask"
                onClickEdit={onEditMask}
                onClickClear={onClearMask}
            />
            <IndicatorItem
                icon={<i className="material-icons md-16">tune</i>}
                title={intl.formatMessage({ id: 'dataBrowser.filterAreasByCriteria' })}
                classes="indicator-item__filter"
                onClickEdit={onEditFilter}
                onClickClear={onClearFilter}
            />
        </div>
    </div>
);

EditFilterAndMaskDialog.propTypes = {
    onClearMask: PropTypes.func.isRequired,
    onEditMask: PropTypes.func.isRequired,
    onClearFilter: PropTypes.func.isRequired,
    onEditFilter: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(EditFilterAndMaskDialog);
