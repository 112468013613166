import React from 'react';
import PropTypes from 'prop-types';

import BusComponent from '../../BusComponent';
import LegendLayout from '../../../enums/LegendLayout';
import Orientation from '../../../enums/Orientation';

import MultiShadedAreaLegendDetailed from './MultiShadedAreaDetailed';
import MultiShadedAreaLegendSimplified from './MultiShadedAreaSimplified';
import SingleShadedAreaLegendSimplified from './SingleShadedAreaSimplified';
import SingleShadedAreaLegendDetailed from './SingleShadedAreaDetailed';

class ShadedAreaLegend extends BusComponent {
    setNaturalBreaksCutpoints = () => {
        const { mapInstance } = this.props;
        this.emit('APPLY_NATURAL_BREAKS_CUTPOINTS_REQUEST',
            { mapInstanceId: mapInstance.id });
    }

    render() {
        const {
            mapInstance,
            orientation,
            layout,
            libraryLegendInfo,
            dataDictionaryInfo,
            onDataFilterInfoClick,
            onEditSatelliteDetailsClick,
            onCloseSatelliteDetails,
            filterInfoOpen,
        } = this.props;

        if (mapInstance.dataTheme.variableSelection.isMultiVariable) {
            if (layout === LegendLayout.DETAILED) {
                return (
                    <MultiShadedAreaLegendDetailed
                        mapInstance={mapInstance}
                        renderer={mapInstance.dataTheme.rendering[0]}
                        annotationLegend={mapInstance.annotationLegend}
                        libraryLegendInfo={libraryLegendInfo}
                        variables={dataDictionaryInfo.variables}
                        hasDataFilter={mapInstance.hasDataFilter}
                        onDataFilterInfoClick={onDataFilterInfoClick}
                        onEditSatelliteDetailsClick={onEditSatelliteDetailsClick}
                        onCloseSatelliteDetails={onCloseSatelliteDetails}
                        filterInfoOpen={filterInfoOpen}
                    />
                );
            }
            return (
                <MultiShadedAreaLegendSimplified
                    mapInstance={mapInstance}
                    renderer={mapInstance.dataTheme.rendering[0]}
                    mapInstanceId={mapInstance.id}
                    variables={dataDictionaryInfo.variables}
                />
            );
        }
        if (layout === LegendLayout.DETAILED) {
            return (
                <SingleShadedAreaLegendDetailed
                    renderer={mapInstance.dataTheme.rendering[0]}
                    mapInstance={mapInstance}
                    orientation={orientation}
                    variableGuid={mapInstance.dataTheme.variableSelection.items[0].variableGuid}
                    annotationLegend={mapInstance.annotationLegend}
                    libraryLegendInfo={libraryLegendInfo}
                    variables={dataDictionaryInfo.variables}
                    hasDataFilter={mapInstance.hasDataFilter}
                    onDataFilterInfoClick={onDataFilterInfoClick}
                    onEditSatelliteDetailsClick={onEditSatelliteDetailsClick}
                    onCloseSatelliteDetails={onCloseSatelliteDetails}
                    filterInfoOpen={filterInfoOpen}
                    onAutoAdjust={this.setNaturalBreaksCutpoints}
                />
            );
        }
        return (
            <SingleShadedAreaLegendSimplified
                mapInstance={mapInstance}
                orientation={orientation}
                renderer={mapInstance.dataTheme.rendering[0]}
                mapInstanceId={mapInstance.id}
                variableGuid={mapInstance.dataTheme.variableSelection.items[0].variableGuid}
                variables={dataDictionaryInfo.variables}
                onAutoAdjust={this.setNaturalBreaksCutpoints}
            />
        );
    }
}

ShadedAreaLegend.propTypes = {
    mapInstance: PropTypes.object.isRequired,
    orientation: PropTypes.oneOf(Object.keys(Orientation)).isRequired,
    dataDictionaryInfo: PropTypes.object.isRequired,
    libraryLegendInfo: PropTypes.array,
    layout: PropTypes.string,
    hasDataFilter: PropTypes.bool,
    onDataFilterInfoClick: PropTypes.func,
    onEditSatelliteDetailsClick: PropTypes.func,
    onCloseSatelliteDetails: PropTypes.func,
    filterInfoOpen: PropTypes.bool,
};

ShadedAreaLegend.defaultProps = {
    layout: LegendLayout.VERTICAL,
    libraryLegendInfo: undefined,
    hasDataFilter: false,
    onDataFilterInfoClick: undefined,
    onEditSatelliteDetailsClick: undefined,
    onCloseSatelliteDetails: undefined,
    filterInfoOpen: false,
};

export default ShadedAreaLegend;
