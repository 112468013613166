'use strict';var DictionaryCoder = function DictionaryCoder(strings) {
    var this$1 = this;
    this._stringToNumber = {};
    this._numberToString = [];
    for (var i = 0; i < strings.length; i++) {
        var string = strings[i];
        this$1._stringToNumber[string] = i;
        this$1._numberToString[i] = string;
    }
};
DictionaryCoder.prototype.encode = function encode(string) {
    return this._stringToNumber[string];
};
DictionaryCoder.prototype.decode = function decode(n) {
    return this._numberToString[n];
};
module.exports = DictionaryCoder;