import * as Sentry from '@sentry/browser';

import Config from './config';
import { overrideObjectProperties } from './helpers/Util';
import SentryEvents from './enums/SentryEvents';
import manifest from '../package.json';

class AppConfig {
    constructor() {
        this._projectTypes = Object.keys(Config.projectTypes).map(k => Config.projectTypes[k]);
        this._config = Config;
        this._sentry = Sentry;
    }

    initializeSentry() {
        if (this._config.environment === 'local') {
            return;
        }
        this._sentry.init({
            dsn: this._config.sentryUrl,
            release: manifest.version,
            environment: this._config.environment,
            debug: this._config.environment === 'local',
        });
        this._sentry.setTag('application', 'map');
    }

    get constants() {
        return this._config;
    }

    get projectTypes() {
        return this._projectTypes;
    }

    get sentry() {
        return this._sentry;
    }

    get useMetricUnits() {
        return navigator.language.toLowerCase() !== 'en-us';
    }

    sentryRecordClick(target, data) {
        this._sentry.addBreadcrumb({
            message: target,
            category: SentryEvents.CLICK,
            data,
        });
    }

    sentryRecordEvent(name, data) {
        this._sentry.addBreadcrumb({
            message: name,
            category: SentryEvents.EVENT,
            data,
        });
    }

    overrideConstants(override) {
        overrideObjectProperties(override, this._config);
    }
}

export default new AppConfig();
