import React from 'react';
import PropTypes from 'prop-types';

import AnnotationsMarkers from '../../enums/AnnotationsMarkers';
import Marker from './Marker';

class MarkerPicker extends React.Component {
    onMarkerClick = m => {
        this.props.onChange(m);
    }

    renderMarkers() {
        const markers = AnnotationsMarkers
            .slice(0, this.props.limit)
            .map(m => <Marker
                key={m.id}
                backgroundColor={this.props.backgroundColor}
                color={this.props.color}
                marker={m} onClick={this.onMarkerClick}
                selected={this.props.marker.replace('_') === m.id}
            />);

        return (
            <div className="marker-picker__items">
                {markers}
            </div>
        );
    }

    render() {
        return <div className="marker-picker">{this.renderMarkers()}</div>;
    }
}

MarkerPicker.propTypes = {
    onChange: PropTypes.func.isRequired,
    marker: PropTypes.string,
    limit: PropTypes.number,
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
};

MarkerPicker.defaultProps = {
    marker: undefined,
    limit: undefined,
    backgroundColor: '#333',
    color: '#fff',
};

export default MarkerPicker;
