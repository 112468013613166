import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Key from '../../../enums/Key';

const YearSelectionListSingular = props => {
    const { comparableVariables, metadata, onClick, currentYear, highlightYear } = props;

    const items = comparableVariables
        .map(item => {
            const surveyMetadata = metadata.surveys[item.surveyName];

            const itemSelected = currentYear && currentYear.surveyName === item.surveyName;
            const itemHighlighted = highlightYear && highlightYear.surveyName === item.surveyName;

            const itemClasses = classNames('year-selection flex-it center justify-center');
            const btnClasses = classNames('year-selection__item', {
                'year-selection__item--selected': itemSelected,
                'year-selection__item--highlighted': itemHighlighted,
            });

            return (
                <div key={item.variableGuid} className={itemClasses}>
                    <div
                        className={btnClasses}
                        tabIndex="0"
                        onKeyDown={e => { if (e.keyCode === Key.ENTER) onClick(item, e); }}
                        onClick={e => onClick(item, e)}
                    >
                        {surveyMetadata.year}
                    </div>
                </div>
            );
        });

    return (
        <div className="flex-it wrap">
            {items}
        </div>
    );
};

YearSelectionListSingular.propTypes = {
    metadata: PropTypes.object.isRequired,
    comparableVariables: PropTypes.array.isRequired,
    currentYear: PropTypes.object,
    highlightYear: PropTypes.object,
    onClick: PropTypes.func.isRequired,
};

YearSelectionListSingular.defaultProps = {
    currentYear: undefined,
    highlightYear: undefined,
};

export default YearSelectionListSingular;
