import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SimpleDropdownItem from './SimpleDropdownItem';

const BOTTOM_OFFSET = 20;

class SimpleDropdownMenu extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            maxHeight: undefined,
        };
    }

    componentDidMount() {
        const { forceRenderWithinViewport, root } = this.props;
        if (forceRenderWithinViewport) {
            // https://stackoverflow.com/a/8876069
            const viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
            const dropdownRootBottomPosition = root.getBoundingClientRect().bottom;
            const maxHeight = viewportHeight - dropdownRootBottomPosition - BOTTOM_OFFSET;
            if (this.state.maxHeight !== maxHeight) {
                // eslint-disable-next-line react/no-did-mount-set-state
                this.setState({ maxHeight });
            }
        }
        this.scrollToSelectedItem();
    }

    scrollToSelectedItem = () => {
        const { items, selectedItem } = this.props;
        const selectedItemIdx = items.findIndex(
            item => item.id === selectedItem.id
        );
        let itemNode = this.menu.firstChild,
            idx = 0;
        while (itemNode && idx !== selectedItemIdx) {
            itemNode = itemNode.nextSibling;
            idx += 1;
        }
        if (itemNode && idx > 0) {
            this.menu.scrollTop =
                itemNode.offsetTop - (this.menu.clientHeight / 2);
        }
    };

    render() {
        const { items, onItemClick, selectedItem, forceRenderWithinViewport } = this.props;
        let menuContent;
        if (items.length) {
            menuContent = items.map(item => (
                <SimpleDropdownItem
                    onItemClick={onItemClick}
                    item={item}
                    selected={item.id === selectedItem.id}
                    key={item.id || item.text}
                />
            ));
        } else {
            menuContent = (
                <div>No search results.</div>
            );
        }

        const menuClasses = classNames('simple-dropdown__menu', {
            'simple-dropdown__menu--empty': !items.length,
        });

        return (
            <ol
                className={menuClasses}
                ref={c => { this.menu = c; }}
                style={forceRenderWithinViewport ? { maxHeight: this.state.maxHeight } : undefined}
            >
                {menuContent}
            </ol>
        );
    }
}

SimpleDropdownMenu.propTypes = {
    items: PropTypes.array.isRequired,
    onItemClick: PropTypes.func.isRequired,
    selectedItem: PropTypes.object.isRequired,
    forceRenderWithinViewport: PropTypes.bool,
    root: PropTypes.any,
};

SimpleDropdownMenu.defaultProps = {
    forceRenderWithinViewport: false,
    root: undefined,

};

export default SimpleDropdownMenu;
