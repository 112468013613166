import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import TypeDropdown from './TypeDropdown';
import Keys from '../../enums/Key';
import FilterTypes from '../../enums/FilterTypes';
import FilterStatus from '../../enums/FilterStatus';
import Tooltip from '../Tooltip';
import MetadataDataType from '../../enums/MetadataDataType';
import BaseVariablePicker from './BaseVariablePicker';
import BusComponent from '../BusComponent';
import NumberFormat from '../../enums/NumberFormat';
import format from '../../helpers/NumberFormatter';
import InputModeSelector from './InputModeSelector';
import ValueDropDown from './ValueDropDown';

const INPUT_MODE_TYPE = { NUMBER: 'NUMBER', PERCENT: 'PERCENT', STRING: 'STRING' };

class Filter extends BusComponent {
    constructor(props, context) {
        super(props, context);
        const { filter, intl } = props;
        const { type, field, value, status, baseVariables } = filter;
        const baseVariableAvailable = baseVariables && baseVariables.length > 0;
        // if the filter is valid figure out is the mode number or percentage otherwise set it do number
        let valueMode = baseVariableAvailable ? INPUT_MODE_TYPE.PERCENT : INPUT_MODE_TYPE.NUMBER;
        if (status === FilterStatus.VALID) {
            switch (field.fieldDataType) {
            case MetadataDataType.STRING:
                valueMode = INPUT_MODE_TYPE.STRING;
                break;
            default:
                valueMode = this.isValuePercentage(value) && baseVariableAvailable ? INPUT_MODE_TYPE.PERCENT : INPUT_MODE_TYPE.NUMBER;
            }
        }

        this.state = {
            active: false,
            type,
            field,
            value,
            valueMode,
            baseVariableAvailable,
            minMaxHint: intl.formatMessage({ id: 'dataBrowser.range' }),
            firstFocusDone: false,
        };
    }

    componentDidMount() {
        this.bindGluBusEvents({
            FILTER_SET_ACTIVE: this.onSetActiveFilter,
            FILTER_VARIABLES_MIN_MAX: this.onGetVariableMinMax,
        });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    componentWillReceiveProps(nextProps) {
        const { filterId, mapInstanceId } = nextProps;
        const { type, field, value, baseVariables } = nextProps.filter;
        const baseVariableAvailable = baseVariables && baseVariables.length > 0;

        let newValueMode = this.state.valueMode;
        let newValue = value;

        if (!baseVariableAvailable || newValueMode === INPUT_MODE_TYPE.NUMBER) {
            newValueMode = INPUT_MODE_TYPE.NUMBER;

            if (this.isValuePercentage(value)) {
                newValue = value.substr(0, value.length - 1);
                this.emit('UPDATE_FILTER_VALUE_REQUEST', { filterId, value: newValue, mapInstanceId });
            }
        }

        this.setState({
            type,
            field,
            value: newValue,
            valueMode: newValueMode,
            minMaxHint: nextProps.intl.formatMessage({ id: 'dataBrowser.range' }),
            baseVariableAvailable,
        });
    }

    componentDidUpdate() {
        // If the filter variable is selected and it's never been in focus
        // focus the input field
        if (this.props.filter.status === FilterStatus.VARIABLE_SELECTED &&
            !this.state.firstFocusDone &&
            this.inputField
        ) {
            this.inputField.focus();
        }
    }

    onGetVariableMinMax = payload => {
        const { filter } = this.props;
        const { field, minMaxHint } = this.state;
        // if the filter is valid hints are not needed
        if (filter.status === FilterStatus.VALID || field.fieldDataType === MetadataDataType.STRING) return;

        const extremes = payload.variablesData[field.fieldName];
        // If the extremes exist create the filter value hint
        if (extremes.min && extremes.max) {
            // Format the values
            const min = format({ number: extremes.min, numberFormat: NumberFormat.FORMAT_NUMBER_NO_DECIMAL });
            const max = format({ number: extremes.max, numberFormat: NumberFormat.FORMAT_NUMBER_NO_DECIMAL });

            this.setState({
                minMaxHint: `${minMaxHint}: ${min} - ${max}`,
            });
        }
    }

    onSetActiveFilter = ({ activeFilterId }) => {
        const { filter } = this.props;
        const { active } = this.state;
        const newActive = activeFilterId === filter.filterId;
        if (active !== newActive) this.setState({ active: activeFilterId === filter.filterId });
    }

    // When the user focuses on a filter fire an event to set the previously active filter to inactive (if any)
    setActiveFilter = () => {
        const { filter } = this.props;
        const { active } = this.state;
        if (!active) this.emit('FILTER_SET_ACTIVE', { activeFilterId: filter.filterId });
    }

    onKeyDown = e => {
        const key = e.which || e.keyCode;
        if (key === Keys.ENTER) this.onApplyValue();
    }

    onValueChange = e => {
        this.setState({
            value: e.target.value,
        });
    }

    onEditVariable = () => {
        const { filter } = this.props;
        this.emit('EDIT_VARIABLE', { filter });
    }

    setNumericalMode = () => {
        const { value, valueMode } = this.state;
        const { filterId, mapInstanceId } = this.props;

        if (valueMode === INPUT_MODE_TYPE.NUMBER) return;

        if (value.length === 0) {
            this.setState({
                valueMode: INPUT_MODE_TYPE.NUMBER,
            }, () => this.inputField.focus());
            return;
        }

        // if the value is ending in percentage remove the % sign
        const percentIndex = value.lastIndexOf('%');
        const newValue = percentIndex === -1 ? value : value.substr(0, percentIndex);

        // If the new value length is 0 then we need to remove the percentage character
        if (newValue.length === 0) {
            this.setState({
                value: newValue,
                valueMode: INPUT_MODE_TYPE.NUMBER,
            }, () => {
                this.inputField.focus();
                this.emit('UPDATE_FILTER_VALUE_REQUEST', { filterId, value: newValue, mapInstanceId });
            });
        } else {
            // No need for setting value, it is propagated via props
            this.setState({
                value: newValue,
                valueMode: INPUT_MODE_TYPE.NUMBER,
            }, () => {
                this.inputField.focus();
                this.emit('UPDATE_FILTER_VALUE_REQUEST', { filterId, value: newValue, mapInstanceId });
            });
        }
    }

    setPercentMode = () => {
        const { value, valueMode } = this.state;
        const { filterId, mapInstanceId } = this.props;
        if (valueMode === INPUT_MODE_TYPE.PERCENT) return;
        if (value.length === 0) {
            this.setState({
                value: '%',
                valueMode: INPUT_MODE_TYPE.PERCENT,
            }, () => this.inputField.focus());
            return;
        }
        // if the value is NOT ending in percentage ADD the % sign
        const percentIndex = value.lastIndexOf('%');
        const newValue = percentIndex === -1 ? `${value}%` : value;
        // No need for setting value, it is propagated via props
        this.setState({
            value: newValue,
            valueMode: INPUT_MODE_TYPE.PERCENT,
        }, () => {
            this.inputField.focus();
            this.emit('UPDATE_FILTER_VALUE_REQUEST', { filterId, value: newValue, mapInstanceId });
        });
    }

    onApplyValue = () => {
        const { value, valueMode } = this.state;
        const { filterId, filter, mapInstanceId } = this.props;
        const baseVariableAvailable = filter.baseVariables && filter.baseVariables.length > 0;
        // check if percentage is at the end
        const percentIndex = value.lastIndexOf('%');
        // get the number part
        const numberPart = percentIndex === -1 ? value.trim() : value.substr(0, percentIndex).trim();
        // Is new value valid and changed
        const isValueValidForChange = this.isFilterValueValid(numberPart) && value !== filter.value;
        // Is new value percent
        const isNewValuePercent = percentIndex > -1 || valueMode === INPUT_MODE_TYPE.PERCENT;
        // Percent mode flag
        const shouldSwitchToPercent = valueMode === INPUT_MODE_TYPE.NUMBER && percentIndex > -1 && baseVariableAvailable;

        // Check if the value is valid and if it has changed at all
        // If the new value is not valid set the state to the old value from filter
        if (isValueValidForChange) {
            // Percent value has priority. You either type % value in input field, or select % button.
            const finalValue = isNewValuePercent ? `${numberPart}%` : numberPart;
            this.emit('UPDATE_FILTER_VALUE_REQUEST', { filterId, value: finalValue, mapInstanceId });
            // In case of percent typed in input field, force changing mode to Percent
            if (shouldSwitchToPercent) {
                this.setState({ valueMode: INPUT_MODE_TYPE.PERCENT, firstFocusDone: true });
            }
        } else {
            this.setState({ value: filter.value, firstFocusDone: true });
        }
    }

    onOptionChange = value => {
        const { filterId, mapInstanceId } = this.props;
        this.emit('UPDATE_FILTER_VALUE_REQUEST', { filterId, value, mapInstanceId });
        this.emit('CLOSE_DROPDOWN');
    }

    onTypeChange = type => {
        const { filterId, mapInstanceId } = this.props;
        this.emit('UPDATE_FILTER_TYPE_REQUEST', { filterId, type, mapInstanceId });
        this.emit('CLOSE_DROPDOWN');
    }

    onBaseVariableChanged = index => {
        const { filter, filterId, mapInstanceId } = this.props;
        const baseFieldName = filter.baseVariables[index].uuid;
        this.emit('UPDATE_FILTER_BASE_VARIABLE_REQUEST', { filterId, baseFieldName, mapInstanceId });
    }

    isFilterValueValid = value => {
        const { field } = this.props.filter;
        const valueCheck = value !== null && value !== '';
        switch (field.fieldDataType) {
        case MetadataDataType.STRING:
            return valueCheck;
        default:
            return valueCheck && (Number(value) === 0 || Number(value));
        }
    }

    onRemove = () => {
        const { filterId, mapInstanceId } = this.props;
        this.emit('REMOVE_FILTER_REQUEST', { filterId, mapInstanceId });
    }

    isValuePercentage(value) {
        const percentIndex = value.lastIndexOf('%');
        // get the number part
        return percentIndex !== -1;
    }

    render() {
        const { field, type, value, valueMode, baseVariableAvailable, active, minMaxHint, firstFocusDone } = this.state;
        const { fieldName, qLabel, fieldDataType, optionsList } = field;
        const { filter, intl } = this.props;
        const isPercentMode = valueMode === INPUT_MODE_TYPE.PERCENT;
        const placeholder = isPercentMode ? intl.formatMessage({ id: 'dataBrowser.percentage' }) : `${minMaxHint}`;

        let filterTypes = Object.values(FilterTypes);

        // If the field data type is string allow only equal and not equal filtering
        if (fieldDataType === MetadataDataType.STRING) {
            filterTypes = [FilterTypes.EQUAL, FilterTypes.NOT_EQUAL];
        }

        const filterValueClassNames = classNames('filter__value', { 'filter__value--percent': isPercentMode });
        const verticalLineClassNames = classNames('vertical-line', { 'vertical-line--percent': isPercentMode });
        const invalidAndBlurred = filter.status < FilterStatus.VALID && firstFocusDone;
        const filterClassNames = classNames('flex-it', 'grow', 'no-shrink', 'filter',
            { 'filter--invalid': invalidAndBlurred && !active });

        const disabled = filter.status < FilterStatus.VARIABLE_SELECTED;
        return (
            <div className={filterClassNames} onFocus={this.setActiveFilter}>
                <div className="flex-it column filter__body">
                    <div className={verticalLineClassNames} />
                    {(active || invalidAndBlurred) && <div className="active-line" />}
                    <div className="flex-it center filter__field-picker-wrapper">
                        <Tooltip
                            placement="bottom"
                            mouseEnterDelay={0.2}
                            mouseLeaveDelay={0}
                            overlay={<span className="light-text">{intl.formatMessage({ id: 'dataBrowser.removeFilter' })}</span>}
                        >
                            <button className="material-icons filter__remove" onClick={this.onRemove} >cancel</button>
                        </Tooltip>
                        <div className="filter__line-join" />
                        <button
                            className="btn-void filter__field-placeholder"
                            onClick={this.onEditVariable}
                        >
                            {fieldName ?
                                <div className="filter__field-name" title={qLabel}>{qLabel}</div> :
                                <div>{intl.formatMessage({ id: 'dataBrowser.chooseDataVariable' })}</div>}

                            <Tooltip overlay={intl.formatMessage({ id: 'dataBrowser.changeVariable' })} >
                                <i className="filter__edit-icon material-icons btn-flat-icon">edit</i>
                            </Tooltip>
                        </button>
                    </div>
                    <div className="flex-it grow flex-end">
                        <div className="filter__value-wrapper">
                            <TypeDropdown
                                className="filter__type"
                                types={Object.values(filterTypes).map(filterType => ({
                                    ...filterType,
                                    text: intl.formatMessage({ id: filterType.text }),
                                }))}
                                value={type}
                                onChange={this.onTypeChange}
                                dropdownClassName="filter__type-dropdown"
                                disabled={disabled}
                            />
                            <div className={filterValueClassNames}>
                                {fieldDataType === MetadataDataType.STRING ?
                                    <ValueDropDown
                                        className="filter__type"
                                        onChange={this.onOptionChange}
                                        value={value}
                                        options={optionsList}
                                        dropdownClassName="filter__value-dropdown"
                                    /> :
                                    <input
                                        ref={ref => {
                                            this.inputField = ref;
                                        }}
                                        value={value}
                                        placeholder={placeholder}
                                        onChange={this.onValueChange}
                                        onKeyDown={this.onKeyDown}
                                        onBlur={this.onApplyValue}
                                        disabled={disabled}
                                    />
                                }
                            </div>
                            { fieldDataType !== MetadataDataType.STRING && baseVariableAvailable &&
                            <InputModeSelector
                                setNumericalMode={this.setNumericalMode}
                                setPercentMode={this.setPercentMode}
                                isPercentMode={isPercentMode}
                                valueMode={valueMode}
                            /> }
                        </div>
                    </div>
                    { isPercentMode &&
                    <div className="flex-it grow flex-end">
                        <div className="filter__value-wrapper filter__value-wrapper--percent">
                            <BaseVariablePicker filter={filter} onBaseVariableChanged={this.onBaseVariableChanged} />
                        </div>
                    </div> }
                    {filter.status < FilterStatus.VALID && <div className="filter__hint">{this.props.intl.formatMessage({ id: 'dataBrowser.addValueAndPressEnterToApplyFilter' })}</div>}
                </div>
            </div>
        );
    }
}

Filter.propTypes = {
    filter: PropTypes.object.isRequired,
    filterId: PropTypes.string.isRequired,
    mapInstanceId: PropTypes.string.isRequired,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(Filter);
