import React from 'react';
import PropTypes from 'prop-types';
import SimpleDropdown from '../../../dropdown/SimpleDropdown';
import MarkerPicker from './MarkerPicker';

const PointLayerStyleBySection = props => {
    const allSameOption = {
        id: 0,
        text: 'Uniform style',
    };

    const items = props.discreteColumns.map(c => ({ id: c, text: c }));
    items.unshift(allSameOption);

    const selectedItem = props.valueColumn ? items.find(i => i.id === props.valueColumn) : allSameOption;

    return (
        <div className="point-layer-editor__section">
            <div className="point-layer-editor__column point-layer-editor__column--aligned">
                Markers style
            </div>
            <div className="point-layer-editor__column">
                <SimpleDropdown
                    id="stylingType"
                    className="flex-one"
                    onItemClick={props.onStyleByChange}
                    items={items}
                    selectedItem={selectedItem}
                />
                <MarkerPicker
                    styleRules={props.styleRules}
                    allMarkersSame={props.allMarkersSame}
                    onStyleRuleVisibilityChange={props.onStyleRuleVisibilityChange}
                    onStyleRuleMarkerStyleChange={props.onStyleRuleMarkerStyleChange}
                />
            </div>
        </div>
    );
};

PointLayerStyleBySection.propTypes = {
    valueColumn: PropTypes.string,
    onStyleByChange: PropTypes.func.isRequired,
    styleRules: PropTypes.array.isRequired,
    allMarkersSame: PropTypes.bool.isRequired,
    onStyleRuleVisibilityChange: PropTypes.func.isRequired,
    onStyleRuleMarkerStyleChange: PropTypes.func.isRequired,
    discreteColumns: PropTypes.array,
};

PointLayerStyleBySection.defaultProps = {
    discreteColumns: [],
    valueColumn: undefined,
};

export default PointLayerStyleBySection;
