import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const DataFilterLegend = ({ onDataFilterInfoClick, className }) => {
    const classes = classNames('btn-link', 'data-filter-legend__more-info');
    const dataFilterLegendClasses = classNames('data-filter-legend', className);

    return (<div className={dataFilterLegendClasses}>
        <div className="data-filter-legend__title">Data filter</div>
        <div className="data-filter-legend__body">
            <div className="data-filter-legend__icon" />
            <div className="data-filter-legend__message">
                Areas that don&apos;t meet the applied
                <button className={classes} onClick={onDataFilterInfoClick} aria-label="Data filter details">
                    &nbsp;criteria
                </button>
            </div>
        </div>
    </div>);
};

DataFilterLegend.propTypes = {
    onDataFilterInfoClick: PropTypes.func.isRequired,
    filterInfoOpen: PropTypes.bool.isRequired,
    className: PropTypes.string,
};

DataFilterLegend.defaultProps = {
    className: undefined,
};

export default DataFilterLegend;
