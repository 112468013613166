import uuid from 'node-uuid';

class PointLayerStyle {
    constructor(values) {
        this._isHidden = false;
        this._markerPathId = 'star';
        this._markerColor = chroma.random().toString();
        this._id = uuid.v4();

        if (values) {
            Object.keys(values).forEach(k => {
                if (this.constructor.prototype.hasOwnProperty(k)) {
                    this[k] = values[k];
                }
            });
        }
    }

    /** @type {'symbol'} */
    get type() {
        return 'symbol';
    }

    get id() {
        return this._id;
    }

    get value() {
        return this._value;
    }

    set value(value) {
        this._value = value;
    }

    get isHidden() {
        return this._isHidden;
    }

    set isHidden(isHidden) {
        this._isHidden = isHidden;
    }

    get markerPathId() {
        return this._markerPathId;
    }

    set markerPathId(markerPathId) {
        this._markerPathId = markerPathId;
    }

    get markerColor() {
        return this._markerColor;
    }

    set markerColor(markerColor) {
        this._markerColor = markerColor;
    }

    equals(that) {
        return this.markerPathId === that.markerPathId && this.value === that.value && this.markerColor === that.markerColor;
    }

    clone() {
        return new PointLayerStyle({
            markerPathId: this.markerPathId,
            markerColor: this.markerColor,
            value: this.value,
            isHidden: this.isHidden,
        });
    }

    toJSON() {
        return {
            id: this.id,
            type: this.type,
            markerPathId: this.markerPathId,
            markerColor: this.markerColor,
            value: this.value,
            isHidden: this.isHidden,
        };
    }
}

export default PointLayerStyle;
