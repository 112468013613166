import React from 'react';
import { injectIntl } from 'react-intl';
import BusComponent from '../BusComponent';
import AppConfig from '../../appConfig';
import Tooltip from '../Tooltip';
import { backToDashboardEvents } from '../../enums/GoogleTagManagerEvents';
import googleTagManagerEvents from '../../helpers/GoogleTagManagerEventsWrapper';


const BackToDashboardWrapper = googleTagManagerEvents('div', backToDashboardEvents);

class HeaderLogo extends BusComponent {
    onClick = event => {
        if (event.type === 'keypress') {
            if (event.key === ' ' || event.key === 'Enter') {
                // prevent the default action to stop scrolling when space is pressed
                event.preventDefault();
            } else {
                // user did not press space nor enter key, so we should do nothing
                return;
            }
        }
        window.open(AppConfig.constants.links.dashboardLink);
    };

    render() {
        return (
            <Tooltip
                placement="bottom"
                mouseEnterDelay={0.1}
                mouseLeaveDelay={0}
                overlay={this.props.intl.formatMessage({ id: AppConfig.constants.headerLogoTooltipTextId })}
            >
                <BackToDashboardWrapper className="logo-wrapper">
                    <img
                        role="button"
                        tabIndex="0"
                        onKeyPress={this.onClick}
                        className="clickable"
                        onClick={this.onClick}
                        src={`${AppConfig.constants.assetsBaseURL}/icons/logo.svg`}
                        width={AppConfig.constants.logoWidth}
                        height={AppConfig.constants.logoHeight}
                        alt="logo"
                    />
                </BackToDashboardWrapper>
            </Tooltip>
        );
    }
}

export default injectIntl(HeaderLogo);
