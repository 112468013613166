export const ReportBadges = {
    DISABLED: { label: 'dataBrowser.notAvailable' },
    DISABLED_CHANGE_OVER_TIME: { label: 'dataBrowser.notAvailableForCot', info: 'dataBrowser.changeOverTimeIsApplied' },
    DISABLED_SURVEY: { label: 'dataBrowser.notAvailableForSelectedSurvey' },
};

export const DataFilterBadges = {
    DISABLED: { label: 'dataBrowser.notAvailable' },
    DISABLED_CHANGE_OVER_TIME: { label: 'dataBrowser.notAvailableForCot', info: 'dataBrowser.changeOverTimeIsApplied' },
};
