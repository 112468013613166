import React from 'react';
import { injectIntl } from 'react-intl';
import BusComponent from '../BusComponent';

class DeleteUserLayer extends BusComponent {
    onDeleteDatasetClicked = () => {
        this.emit('DELETE_USER_DATA', this.props.userData);
        this.emit('CLOSE_MODAL');
    };

    onCancelDatasetDeletion = () => {
        this.emit('CLOSE_MODAL');
    };

    render() {
        return (
            <div className="user-layer-option flex-it column center">
                <h5 className="text-center">
                    {this.props.intl.formatMessage({ id: 'areYouSureYouWantToDelete' })}?
                </h5>
                <h5 className="user-layer-option__message">{this.props.userData.title}</h5>
                <h5 className="user-layer-option__message">
                    Maps with already added layers may stop working.
                </h5>
                <div className="user-layer-option__container flex-it center space-between">
                    <div className="btn-flat" onClick={this.onCancelDatasetDeletion}>
                        {this.props.intl.formatMessage({ id: 'cancel' })}
                    </div>
                    <div className="btn-raised" onClick={this.onDeleteDatasetClicked}>
                        {this.props.intl.formatMessage({ id: 'delete' })}
                    </div>
                </div>
            </div>
        );
    }
}

export default injectIntl(DeleteUserLayer);
