import convertSvgToPng from '../../helpers/ConvertSvgToPng';

class MarkerAssets {
    constructor() {
        this._markerPngs = {};
    }

    getMarkerPng(id, marker, options) {
        if (!this._markerPngs[id]) {
            // convert to PNG
            const markerFilename = `marker_${marker.markerPathId.replace(/-/g, '_')}`;
            const markerPng = convertSvgToPng(markerFilename, { ...options, fillColor: marker.markerColor });
            this._markerPngs[id] = markerPng;
        }
        return this._markerPngs[id];
    }

}

export default new MarkerAssets();
