// @ts-check
import React from 'react';

import BusComponent from '../BusComponent';
import SimpleDropdown from '../dropdown/SimpleDropdown';
import LocationAnalysisType from '../../enums/LocationAnalysisType';

/**
 * @typedef Props
 * @property {import('../../').LocationAnalysisType} selectedAnalysisType
 * @property {string} mapInstanceId
 *
 * @typedef State
 * @property {string} analysisTypeId
 *
 * @extends {BusComponent<Props, State>}
 */
class LocationAnalysisTypeDropdown extends BusComponent {
    constructor(props, context) {
        super(props, context);
        const analysisTypeId = Object.keys(LocationAnalysisType).find(
            type =>
                LocationAnalysisType[type].ID ===
                this.props.selectedAnalysisType.ID,
        );
        /** @type {State} */
        this.state = {
            analysisTypeId,
        };
    }

    onChange = analysisTypeId => {
        if (analysisTypeId === this.state.analysisTypeId) return;

        this.setState({ analysisTypeId });

        // Reset custom values in case some are added
        this.emit('RESET_CUSTOM_LOCATION_ANALYSIS_VALUES_REQUEST');
        // Once custom values are emptied from the store, show the changes in the view
        this.emit('CUSTOM_LOCATION_ANALYSIS_VALUES_REQUEST');

        this.emit('SET_LOCATION_ANALYSIS_TYPE', {
            mapInstanceId: this.props.mapInstanceId,
            analysisTypeId,
        });
    };

    /**
    * We want to filter our Analysis Types because we don't
    * want to display Geography analysis type on Address search
    */
    render() {
        const items = Object.keys(LocationAnalysisType).filter(analysisTypeId => !LocationAnalysisType[analysisTypeId].ISGEOGRAPHY)
        .map(analysisTypeId => ({
            id: analysisTypeId,
            text: LocationAnalysisType[analysisTypeId].NAME,
            leftIcon: LocationAnalysisType[analysisTypeId].ICON,
            rightIcon:
                analysisTypeId === this.state.analysisTypeId
                    ? 'done'
                    : undefined,
        }));

        const selectedItem = items.find(
            item => item.id === this.state.analysisTypeId,
        );
        return (
            <div>
                <SimpleDropdown
                    className="location-analysis-type-dropdown"
                    items={items}
                    onItemClick={this.onChange}
                    selectedItem={selectedItem}
                />
            </div>
        );
    }
}

export default LocationAnalysisTypeDropdown;
