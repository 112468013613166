//      

const StyleLayer = require('./style_layer');
const util = require('../util/util');

/**
 *
 * Enables creation of `masking` layers that contain overrides for layer paint properties
 * These overrides can be in form of valid dragonfly paint property or blend function applied to parent layer or mask
 * Updates to parent layer paint property will be also reflected in mask
 * Masks can be placed in any rendering order
 * Example:
 * mask = {
 *      "fill-outline-color": "rgb(240,240,240)",
 *      "fill-opacity": {
 *              maskBlendFunc: function(value) { // value is from parent "fill-opacity" property
 *                      return value * 0.1;
 *              }
 *      }
 * }
 */

                                                             
                                             
                                 
                         
                                                                                               
                                                               
                     
                     
            
                      
     
 

module.exports = function(map     ) {

    return {

        _maskLayers: { },

        /**
         * Render masks that are placed after current layer
         * @param painter
         * @param layer
         * @param draw
         */
        renderMaskLayers: function(painter         , layer            , draw     ) {
            if (painter.renderPass === '3d') return;
            // apply paint changes of parent layer to masks based on those layers
            applyParentLayerChangesToMasks(this._maskLayers, layer);
            // draw other layers masks that need to be renderer after this layer
            drawMasksAfterLayer(this._maskLayers, painter, layer.id, draw);
            // clear removed masks on end of render pass
            const layerIds = map.style._order;
            if (layerIds[layerIds.length - 1] === layer.id) {
                clearMasksOfRemovedLayers(this._maskLayers);
            }
        },

        /**
         * Add masks for mask.parentId, to be rendered mask.predecessorId
         * @param mask
         */
        addLayerMask: function(maskConfig           ) {
            const mask = util.clone(maskConfig);
            const predecessorId = mask.predecessorId;
            this._maskLayers[predecessorId] = this._maskLayers[predecessorId] || [];
            this._maskLayers[predecessorId].push(mask);
            const paintProperties = mask.paint;
            for (const name in paintProperties) {
                if (paintProperties[name].maskBlendFunc === undefined) {
                    paintProperties[name] = { value: paintProperties[name] };
                }
            }
        },

        /**
         * Clear all masks for all layers
         */
        clearMaskLayers: function() {
            this._maskLayers = {};
        }
    };

    function drawMasksAfterLayer(maskLayers, painter, layerId, draw) {
        // there are not masks to draw after this layer
        if (!maskLayers[layerId]) return;

        const style = map.style;

        const masks = maskLayers[layerId] || [];
        for (let i = 0; i < masks.length; i += 1) {
            const mask = masks[i];

            if (isMaskHidden(mask, painter.transform.zoom)) continue;

            const parentLayer = style._layers[mask.parentId];
            // parent layer removed from style
            if (!parentLayer) continue;
            // if mask parent layer is not visible do not render mask
            if (parentLayer.isHidden(painter.transform.zoom)) continue;

            const sourceCache = style.sourceCaches[parentLayer.source];
            let coords = [];

            if (sourceCache) {
                if (sourceCache.prepare) sourceCache.prepare(painter.gl);
                painter.clearStencil();
                coords = sourceCache.getVisibleCoordinates();
                if (sourceCache.getSource().isTileClipped) {
                    painter._renderTileClippingMasks(coords);
                }
            }

            if (!(painter.renderPass === 'opaque')) {
                coords.reverse();
            }
            if (!coords.length) continue;

            const maskProperties = mask.paint;

            // update style layer paint declarations
            const parentTransitions = {};
            for (const prop in maskProperties) {
                const maskProperty = maskProperties[prop];
                parentTransitions[prop] = parentLayer._paintTransitions[prop];
                if (!maskProperty.transition && (maskProperty.maskBlendFunc || maskProperty.value)) {
                    maskProperty.transition = createMaskTransition(parentLayer, prop, parentTransitions[prop], mask);
                } else if (!maskProperty.transition) {
                    continue;
                }
                parentLayer._paintTransitions[prop] = maskProperty.transition;
            }

            draw[parentLayer.type](painter, sourceCache, parentLayer, coords);

            // revert parent layer paint declarations
            for (const prop in parentTransitions) {
                parentLayer._paintTransitions[prop] = parentTransitions[prop];
            }
        }
    }

    function applyParentLayerChangesToMasks(maskLayers, layer            ) {
        for (const afterLayerId in  maskLayers) {
            const masks = maskLayers[afterLayerId] || [];
            for (let i = 0; i < masks.length; i++) {
                const mask = masks[i];
                if (mask.parentId !== layer.id) continue;
                const paintProperties = mask.paint || {};
                for (const prop in paintProperties) {
                    const transition = layer._paintTransitions[prop];
                    const needsUpdate = paintProperties[prop].parentTransition !== transition;
                    if (needsUpdate) {
                        paintProperties[prop].transition = createMaskTransition(layer, prop, transition, mask);
                    }
                    paintProperties[prop].parentTransition = layer._paintTransitions[prop];
                }
            }
        }
    }

    function clearMasksOfRemovedLayers(maskLayers) {
        const style = map.style;
        for (const afterLayerId in maskLayers) {
            const masks = maskLayers[afterLayerId] || [];
            let hasRemoved = false;
            for (let i = 0; i < masks.length; i += 1) {
                const mask = masks[i];
                if (!style._layers[mask.parentId]) {
                    hasRemoved = true;
                    break;
                }
            }
            if (hasRemoved) {
                maskLayers[afterLayerId] = [];
                for (let i = 0; i < masks.length; i += 1) {
                    const mask = masks[i];
                    if (style._layers[mask.parentId]) {
                        maskLayers[afterLayerId].push(mask);
                    }
                }
            }
        }
    }

    function isMaskHidden(mask           , zoom        ) {
        if (mask.minZoom !== undefined && zoom < mask.minZoom) return true;
        if (mask.maxZoom !== undefined && zoom >= mask.maxZoom) return true;
        return false;
    }

    function createMaskTransition(layer            , name        , transition                 , mask           ) {
        const maskProperty = mask.paint[name];
        if (!mask.styleLayer) {
            mask.styleLayer = new StyleLayer(layer.serialize());
        }
        const maskStyleLayer = mask.styleLayer;
        const style = map.style;
        const value = maskProperty.value || util.clone(transition.declaration.value);
        if (maskProperty.maskBlendFunc) {
            maskProperty.maskBlendFunc(value);
        }
        maskStyleLayer.setPaintProperty(name, value);
        maskStyleLayer.updatePaintTransition(name, {transition: true}, {}, style.animationLoop, style.zoomHistory);
        return maskStyleLayer._paintTransitions[name];
    }
};
