import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class RadioButton extends React.Component {
    onClick = () => {
        const { onClick, value } = this.props;
        onClick(value);
    };

    render() {
        const { name, checked } = this.props;
        const classes = classnames('radio-button', {
            checked,
        });
        return (
            <div onClick={this.onClick} className={classes}>
                <i className="material-icons">
                    {checked
                        ? 'radio_button_checked'
                        : 'radio_button_unchecked'}
                </i>
                <div>{name}</div>
            </div>
        );
    }
}

RadioButton.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    onClick: PropTypes.func.isRequired,
    checked: PropTypes.bool,
};

RadioButton.defaultProps = {
    checked: false,
};

export default RadioButton;
