/* eslint class-methods-use-this: 0 */
class MultiValueRenderer {
    constructor() {
        this._insufficientDataRuleIndex = -1;
        this._nullDataRuleIndex = -1;
    }

    get type() {
        return 'MultiValueRenderer';
    }

    get insufficientDataRuleIndex() {
        return this._insufficientDataRuleIndex;
    }

    set insufficientDataRuleIndex(insufficientDataRuleIndex) {
        this._insufficientDataRuleIndex = insufficientDataRuleIndex;
    }

    get nullDataRuleIndex() {
        return this._nullDataRuleIndex;
    }

    set nullDataRuleIndex(nullDataRuleIndex) {
        this._nullDataRuleIndex = nullDataRuleIndex;
    }

    get fieldList() {
        return this._fieldList;
    }

    set fieldList(fieldList) {
        this._fieldList = fieldList;
    }

    get rules() {
        return this._rules;
    }

    set rules(rules) {
        this._rules = rules;
    }

    get visibility() {
        return this._visibility;
    }

    set visibility(visibility) {
        this._visibility = visibility;
    }

    get valuesFieldsNames() {
        return this._valuesFieldsNames;
    }

    set valuesFieldsNames(valuesFieldsNames) {
        this._valuesFieldsNames = valuesFieldsNames;
    }

    applyColorPalette(colorPalette, isFlipped) {
        this.rules.forEach((rulesArray, index) => {
            let specialRulesCount = 0;
            if (this.insufficientDataRuleIndex[index] !== -1) specialRulesCount += 1;
            if (this.nullDataRuleIndex[index] !== -1) specialRulesCount += 1;

            const colors = colorPalette.colorRamps[index].interpolateColors(rulesArray.length - specialRulesCount);

            if (isFlipped) colors.reverse();

            rulesArray.forEach((rule, ruleIdx) => {
                let paintColor;

                if (ruleIdx === this.insufficientDataRuleIndex[index]) {
                    paintColor = colorPalette.insufficientDataColor;
                } else if (ruleIdx === this.nullDataRuleIndex[index]) {
                    paintColor = colorPalette.nullDataColor;
                } else {
                    paintColor = colors.shift();
                }

                rule.symbols[0].brushes.forEach(b => {
                    b.fillColor = paintColor;
                });
            });
        });
    }

    equals(that) {
        const rulesEqual = this.rules.every((rule, index) => {
            if (Array.isArray(rule)) return rule.every((r, i) => r.equals(that.rules[index][i]));
            return rule.equals(that.rules[index]);
        });
        return (this.type === that.type &&
        this.fieldList.equals(that.fieldList) &&
        rulesEqual &&
        this.visibility.every((vis, index) => vis.equals(that.visibility[index])) &&
        this.insufficientDataRuleIndex === that.insufficientDataRuleIndex &&
        this.nullDataRuleIndex === that.nullDataRuleIndex);
    }

    clone() {
        const valueRenderer = new MultiValueRenderer();
        valueRenderer.fieldList = this.fieldList.clone();
        valueRenderer.rules = this.rules.map(rulesArray => rulesArray.map(rule => rule.clone()));
        valueRenderer.visibility = this.visibility.map(vis => vis.clone());
        valueRenderer.insufficientDataRuleIndex = this.insufficientDataRuleIndex;
        valueRenderer.nullDataRuleIndex = this.nullDataRuleIndex;
        valueRenderer.valuesFieldsNames = this.valuesFieldsNames;

        return valueRenderer;
    }

    toJSON() {
        return {
            type: this.type,
            fieldList: this.fieldList.toJSON(),
            rules: this.rules.map(ruleArray => ruleArray.map(rule => rule.toJSON())),
            visibility: this.visibility.map(vis => vis.toJSON()),
            insufficientDataRuleIndex: this.insufficientDataRuleIndex,
            nullDataRuleIndex: this.nullDataRuleIndex,
        };
    }
}

export default MultiValueRenderer;
