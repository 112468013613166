// @ts-check
import React from 'react';
import PropTypes from 'prop-types';

import VariableIndent from './VariableIndent';

/**
 * @typedef Props
 * @property {import('../../../objects/VariableSelectionItem').default[]} currentVariableSelectionItems
 * @property {import('../../../objects/MetaVariable').default[]} variables
 * @property {import('../../../objects/MapInstance').default} mapInstance
 * @property {string} query
 *
 *
 * @param {Props} props
 */
const VariablesIndent = props => (
    <div className="data-browser-search-result-table__variables data-browser-search-result-table__variables--indent">
        {props.variables.map(variable => (
            <VariableIndent
                key={variable.uuid}
                variable={variable}
                mapInstance={props.mapInstance}
                currentVariableSelectionItems={
                    props.currentVariableSelectionItems
                }
                query={props.query}
            />
        ))}
    </div>
);

VariablesIndent.propTypes = {
    currentVariableSelectionItems: PropTypes.array.isRequired,
    variables: PropTypes.array.isRequired,
    mapInstance: PropTypes.object.isRequired,
    query: PropTypes.string.isRequired,
};

export default VariablesIndent;
