// @ts-check
import React from 'react';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';

import BusComponent from '../BusComponent';
import Mixpanel, { MIXPANEL_EVENTS } from '../../helpers/Mixpanel';

/**
 * @typedef Props
 * @property {string} mapInstanceId
 * @property {import('react-intl').intlShape} intl
 * @property {import('../../objects/LocationAnalysisItem').default} locationAnalysisItem
 *
 * @extends {BusComponent<Props, any>}
 */
class LocationAnalysisCreateReportButton extends BusComponent {
    onShowOptions = () => {
        this.emit('UPDATE_CREATE_REPORT_VISIBILITY', {
            visible: true,
            mapInstanceId: this.props.mapInstanceId,
        });
        Mixpanel.emitUserEvent(MIXPANEL_EVENTS.TOGGLE_CREATE_REPORT_ACCORDION);
    };

    render() {
        return (
            <button className="btn location-analysis__options-btn" onClick={this.onShowOptions}>
                <i className="material-icons">keyboard_arrow_down</i>
                {this.props.intl.formatMessage({
                    id: 'dataBrowser.createReport',
                })}
            </button>
        );
    }
}

export default injectIntl(LocationAnalysisCreateReportButton);
