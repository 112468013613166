import React from 'react';
import PropTypes from 'prop-types';

const ClearDialog = ({ onCancel, onClear, text }) => (
    <div className="clear-mask-dialog flex-it column space-between">
        <div className="flex-it space-between center">
            <h4>Clear {text}</h4>
            <i className="material-icons btn-icon" onClick={onCancel}>close</i>
        </div>
        <p className="body-1">
            Are you sure you want to clear this {text}? This cannot be undone.
        </p>
        <div className="flex-it flex-end">
            <button onClick={onCancel} className="btn-flat clear-mask-dialog__cancel">
                Cancel
            </button>
            <button onClick={onClear} className="btn-raised">
                Yes, clear
            </button>
        </div>
    </div>
);

ClearDialog.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
};

export default ClearDialog;

