//!
//      
const loadGeometry = require('../load_geometry');
const {SegmentVector, MAX_VERTEX_ARRAY_LENGTH} = require('../segment');
const VertexBuffer = require('../../gl/vertex_buffer');
const IndexBuffer = require('../../gl/index_buffer');
const createVertexArrayType = require('../vertex_array_type');
const {TriangleIndexArray} = require('../index_array_type');
const EXTENT = require('../extent');
const evaluateExpression = require('../../util/dragonfly_util').evaluateExpression;
const clamp = require('../../util/util').clamp;
const {ProgramConfigurationSet} = require('../program_configuration');


                                                                                            
                                                               
                                                      
                                                         

// Threshold of number of dots per feature above which we classify the feature having "many dots" (hard to manually count)
const MANY_DOTS = 50;
// Number of tries for randomly placing a single dot within the polygon if many dots are to be placed.
// This number should be lower than TRIES_FEW_DOTS, because even if we don't get a hit after all tries,
// visually this won't be noticed. (optimization)
const TRIES_MANY_DOTS = 50;
// Number of tries for randomly placing a single dot within the polygon if few dots are to be placed
// This number should be higher than TRIES_FEW_DOTS, because we want to possibly show all dots for a smaller
// number of dots, since the user can visually count few dots.
const TRIES_FEW_DOTS = 200;

const MAX_DENSITY = 50;

const MAX_CIRCLES = 50;


/**
 * Dots are represented by two triangles.
 *
 * Each corner has a pos that is the center of the dot and an extrusion
 * vector that is where it points.
 * @private
 */

const dotDensityInterface = {
    layoutAttributes: [
        { name: 'a_pos', components: 2, type: 'Int16' },
    ],
    indexArrayType: TriangleIndexArray,

    paintAttributes: [
        { property: 'dotdensity-blur'},
        { property: 'dotdensity-radius'},
        { property: 'dotdensity-color'},
        { property: 'dotdensity-opacity'},
    ]
};

function addDotDensityVertex(layoutVertexArray, x, y, extrudeX, extrudeY) {
    layoutVertexArray.emplaceBack(
        (x * 2) + ((extrudeX + 1) / 2),
        (y * 2) + ((extrudeY + 1) / 2));
}

const LayoutVertexArrayType = createVertexArrayType(dotDensityInterface.layoutAttributes);


/**
 * Holds dots' counts per layer per feature (a.k.a. dots dictionary).
 * Used for exact counting of dots when only a few dots are displayed.
 * {
 *      zoom: 12,
 *      layers: {
 *          <layerId1>: {
 *              source: 'source-1',
 *              sourceLayer: '40',
 *              formula: '{column1}/{column2}',
 *              features: {
 *                  <featureId1>: 123,
 *                  <featureId2>: 123,
 *                  <featureId3>: 123,
 *                  ...
 *              }
 *          },
 *          <layerId2>: {
 *              source: 'source-1',
 *              formula: '{column1}/{column2}',
 *              features: {
 *                  <featureId1>: 123,
 *                  <featureId2>: 123,
 *                  <featureId3>: 123,
 *                  ...
 *              }
 *          },
 *          ...
 *      }
 * }
 */
let allDots = {};

                          
              
              
                   
                    
 

                           
               
                       
                         
                   
                 
 

                               
                             
               
                       
                            
                        
      
 

                    
                                        
 

class DotDensityBucket                   {
                                              

                  
                 
                        
                              
                  

                                   
                                     
               
                            
                             

                                                   
                            
                      

    constructor(options     ) {
        this.zoom = options.zoom;
        this.overscaling = options.overscaling;
        this.layers = options.layers;
        this.index = options.index;

        this.layoutVertexArray = new LayoutVertexArrayType(options.layoutVertexArray);
        this.indexArray = new TriangleIndexArray(options.indexArray);
        this.programConfigurations = new ProgramConfigurationSet(dotDensityInterface, options.layers, options.zoom, options.programConfigurations);
        this.segments = new SegmentVector(options.segments);
        this.mapId = options.mapId;
        if (DotDensityBucket.allDots[this.mapId] === undefined) DotDensityBucket.allDots[this.mapId] = {
            zoom: undefined,
            layers: {},
        };
        if (this.data === undefined) {
            this.data = {};
            this.data.layers = {};
            this.data.layers[this.layers[0].id] = {
                metadata: {
                    source: undefined,
                    sourceLayer: undefined,
                    formula: undefined,
                },
                fids: [],
            };
        }
    }

    populate(features                       , options                    ) {
        let autoSourceFilter; //!
        if (this.layers[0].autoSource) { //!
            for (const autoLayer of this.layers[0].autoSource) { //!
                if (autoLayer.minzoom <= this.zoom && autoLayer.maxzoom > this.zoom && autoLayer.filter) { //!
                    autoSourceFilter = autoLayer.filter; //!
                    break; //!
                } //!
            } //!
        } //!
        for (const {feature, index, sourceLayerIndex} of features) {
            if (autoSourceFilter && !autoSourceFilter({zoom: this.zoom}, feature)) continue; //!
            if (this.layers[0]._featureFilter({zoom: this.zoom}, feature)) {
                const geometry = loadGeometry(feature);
                this.addFeature(feature, geometry);
                options.featureIndex.insert(feature, geometry, index, sourceLayerIndex, this.index);
            }
        }
    }

    isEmpty() {
        return this.layoutVertexArray.length === 0;
    }

    serialize(transferables                      )                   {
        return {
            zoom: this.zoom,
            data: this.data, //!
            layerIds: this.layers.map((l) => l.id),
            layoutVertexArray: this.layoutVertexArray.serialize(transferables),
            indexArray: this.indexArray.serialize(transferables),
            programConfigurations: this.programConfigurations.serialize(transferables),
            segments: this.segments.get(),
        };
    }

    upload(gl                       ) {
        this.layoutVertexBuffer = new VertexBuffer(gl, this.layoutVertexArray);
        this.indexBuffer = new IndexBuffer(gl, this.indexArray);
        this.programConfigurations.upload(gl);
    }

    destroy() {
        if (!this.layoutVertexBuffer) return;
        this.layoutVertexBuffer.destroy();
        this.indexBuffer.destroy();
        this.programConfigurations.destroy();
        this.segments.destroy();
    }

    static get allDots() {
        return allDots;
    }

    static set allDots(value) {
        return (allDots = value);
    }

    polygonArea(geometry                     ) {

        let area = 0;

        for (let g = 0; g < geometry.length; g++) {

            const vs = geometry[g];

            for (let i = 0; i < vs.length - 1; i++)
                // mathematically incorrect, should clip each triangle one by one (however visually this works fine)
                area += this.fix(vs[i].x) * this.fix(vs[i + 1].y) - this.fix(vs[i + 1].x) * this.fix(vs[i].y);
        }

        return area / 2;
    }

    // Clamps a coordinate (x or y) to tile extent
    fix(value        ) {
        return clamp(value, 0, EXTENT);
    }

    // Returns number of dots to be placed on partial area
    getNumberOfDotsToPlace(countTotal        , areaPartial        , areaTotal        , layerId        , featureId        ) {
        const ratio = Math.min(1, areaPartial / areaTotal);
        let countPartial = Math.ceil(countTotal * ratio);

        // Prevents rendering too many dots densely.
        if (areaPartial / countPartial < MAX_DENSITY)
            countPartial = Math.ceil(areaPartial / MAX_DENSITY);

        // Initialize dots per feature if not already initialized
        const layerDots = DotDensityBucket.allDots[this.mapId].layers[layerId];
        if (layerDots.features[featureId] === undefined)
            layerDots.features[featureId] = 0;

        // Make sure we don't place too many dots
        if (countTotal < MANY_DOTS && layerDots.features[featureId] + countPartial > countTotal)
            countPartial = Math.max(0, countTotal - layerDots.features[featureId]);
        layerDots.features[featureId] += countPartial;
        return countPartial;
    }

    pointInCircles(x        , y        , areaCircles                   ) {
        const numberOfCircles = areaCircles.length;

        for (let n = 0; n < numberOfCircles; n++) {
            const circle = areaCircles[n];
            const dist = (circle.x - x) * (circle.x - x) + (circle.y - y) * (circle.y - y);

            if (Math.sqrt(dist) < circle.radius)
                return circle.inside;
        }
    }

    pointInPolygon(x        , y        , geometry                     ) {

        // Do not include points that are outside the tile boundaries.
        if (x < 0 || x >= EXTENT || y < 0 || y >= EXTENT) return false;

        let inside = false;
        for (let g = 0; g < geometry.length; g++) {

            const vs = geometry[g];

            for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
                const xi = vs[i].x, yi = vs[i].y;
                const xj = vs[j].x, yj = vs[j].y;

                const intersect = ((yi > y) !== (yj > y)) &&
                    (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
                if (intersect) inside = !inside;
            }
        }

        return inside;
    }

    hausdorffDistance(x        , y        , geometry                     ) {

        let minDistance = 100000;

        for (let g = 0; g < geometry.length; g++) {

            const vs = geometry[g];
            const lastPt = vs.length - 1;

            for (let i = 0; i < lastPt; i++) {

                const dist = this.pointDistanceFromLineSegment(x, y, vs[i].x, vs[i].y, vs[i + 1].x, vs[i + 1].y);

                if (dist < minDistance)
                    minDistance = dist;
            }
        }

        return minDistance;
    }

    pointDistanceFromLineSegment(cx        , cy        , ax        , ay        , bx        , by        ) {

        let distanceSegment;
        const rNumerator = (cx - ax) * (bx - ax) + (cy - ay) * (by - ay);
        const rDenominator = (bx - ax) * (bx - ax) + (by - ay) * (by - ay);
        const r = rNumerator / rDenominator;
        const s = ((ay - cy) * (bx - ax) - (ax - cx) * (by - ay)) / rDenominator;

        const distanceLine = Math.abs(s) * Math.sqrt(rDenominator);

        // (xx,yy) is the point on the lineSegment closest to (cx,cy)

        if ((r >= 0) && (r <= 1)) {
            distanceSegment = distanceLine;
        } else {
            const dist1 = (cx - ax) * (cx - ax) + (cy - ay) * (cy - ay);
            const dist2 = (cx - bx) * (cx - bx) + (cy - by) * (cy - by);
            if (dist1 < dist2) {
                distanceSegment = Math.sqrt(dist1);
            } else {
                distanceSegment = Math.sqrt(dist2);
            }
        }

        return distanceSegment;
    }

    addFeature(feature                   , geometry                     ) {
        const layer             = this.layers[0];
        if (this.zoom !== DotDensityBucket.allDots[this.mapId].zoom)
            DotDensityBucket.allDots[this.mapId] = { zoom: this.zoom, layers: {} };

        // Reset dots per layer if any of the following is true:
        //   1. not already initialized
        //   2. the count formula changed
        //   3. 'source-layer' has been changed, but the 'id' is still the same (e.g. smooth transitions between geos within the same layer)
        const dotsDictionary = DotDensityBucket.allDots[this.mapId].layers[layer.id];
        const formula = layer.layout['dotdensity-count'];
        const sourceLayer         = typeof layer.sourceLayer === 'string' ? layer.sourceLayer : '';
        if (dotsDictionary === undefined || // 1
            dotsDictionary.formula !== formula || // 2
            dotsDictionary.sourceLayer !== sourceLayer) { // 3
            DotDensityBucket.allDots[this.mapId].layers[layer.id] = {
                source: layer.source,
                sourceLayer: sourceLayer,
                formula: formula,
                features: {}
            };
        }

        // Calculate bounding box (inc. scaling)
        const scale = EXTENT / feature.extent;
        const bbox = feature.bbox();
        for (let b = 0; b < 4; b++)
            bbox[b] = this.fix(bbox[b] * scale);

        // Calculate area from geometry
        const areaPartial         = this.polygonArea(geometry) / scale;
        const areaScale         = Math.pow(2, 2 * (19 - this.zoom) + 1) * scale;
        const featureArea         = typeof feature.properties.__area__ === 'number' ? feature.properties.__area__ : 0;
        const areaTotal         = featureArea / areaScale;

        // Skip if area is not defined or empty
        if (!areaTotal || areaTotal <= 0) return;
        if (!areaPartial || areaPartial <= 0) return;

        // Evaluate count
        const countTotal = evaluateExpression(layer.layout['dotdensity-count'], feature.properties);
        const countPartial = this.getNumberOfDotsToPlace(countTotal, areaPartial, areaTotal, layer.id, feature.id);
        this.data.layers[layer.id].metadata = {
            source: layer.source,
            sourceLayer: sourceLayer,
            formula: formula,
        };

        const featureInfo              = {
            id: feature.id,
            countTotal: countTotal,
            countPartial: countPartial,
            offset: -1,
            size: -1,
        };
        // Evaluate dots
        const areaCircles = [];
        const dots = [];
        let x, y;
        for (let n = 0; n < countPartial; n++) {

            let tries = countPartial > MANY_DOTS ? TRIES_MANY_DOTS : TRIES_FEW_DOTS;

            do {
                x = bbox[0] + Math.random() * (bbox[2] - bbox[0]);
                y = bbox[1] + Math.random() * (bbox[3] - bbox[1]);

                let inside = this.pointInCircles(x, y, areaCircles);

                if (inside === undefined)
                    inside = this.pointInPolygon(x, y, geometry);

                if (areaCircles.length < MAX_CIRCLES) {
                    const radius = this.hausdorffDistance(x, y, geometry);
                    areaCircles.push({
                        x: x,
                        y: y,
                        radius: radius,
                        inside: inside
                    });
                }

                if (inside) break;

                x = undefined;
                y = undefined;

            } while (--tries > 0);

            if (!x || !y) continue;

            dots.push([x, y]);
        }
        if (dots.length === 0) return;
        featureInfo.offset = this.indexArray.length / 2 * 6;
        featureInfo.size = dots.length * 6;
        const maxDotArrayLength = Math.floor(MAX_VERTEX_ARRAY_LENGTH / 4);
        let dotsArrayLength = dots.length;
        const totalNumberOfSegments = Math.floor(dots.length / maxDotArrayLength);
        for (let i = 0; i <= totalNumberOfSegments; i++) {
            const currentDotsLength = Math.min(dotsArrayLength, maxDotArrayLength);
            const segment = this.segments.prepareSegment(4 * currentDotsLength, this.layoutVertexArray, this.indexArray);
            const dotsArrayOffset = i * maxDotArrayLength;
            for (let j = dotsArrayOffset; j < dotsArrayOffset + currentDotsLength; j++) {
                const dot = dots[j];
                x = dot[0];
                y = dot[1];
                const index = segment.vertexLength;
                addDotDensityVertex(this.layoutVertexArray, x, y, -1, -1);
                addDotDensityVertex(this.layoutVertexArray, x, y, 1, -1);
                addDotDensityVertex(this.layoutVertexArray, x, y, 1, 1);
                addDotDensityVertex(this.layoutVertexArray, x, y, -1, 1);

                this.indexArray.emplaceBack(index, index + 1, index + 2);
                this.indexArray.emplaceBack(index, index + 3, index + 2);
                segment.vertexLength += 4;
                segment.primitiveLength += 2;
            }
            if (dotsArrayLength > maxDotArrayLength) dotsArrayLength -= maxDotArrayLength;
        }

        this.data.layers[layer.id].fids.push(featureInfo);

        this.programConfigurations.populatePaintArrays(this.layoutVertexArray.length, feature);
    }
}

DotDensityBucket.programInterface = dotDensityInterface;

module.exports = DotDensityBucket;
