import general from './general.json';
import modals from './modals.json';
import dataBrowser from './dataBrowser.json';
import report from './report.json';
import filter from './filter.json';
import header from './header.json';
import map from './map.json';
import showHide from './showHide.json';
import storyPlayer from './storyPlayer.json';
import hints from './hints.json';
import annotations from './annotations.json';
import layerLibrary from './layerLibrary.json';
import legend from './legend.json';
import variablePicker from './variablePicker.json';
import visualisationType from './visualisationType.json';
import userLocation from './userLocation.json';
import search from './search.json';
import locationAnalysis from './locationAnalysis.json';
import points from './points.json';

export default {
  ...general,
  dataBrowser: {
    ...dataBrowser,
    ...report,
    ...filter,
    ...search,
  },
  header,
  map,
  showHide,
  storyPlayer,
  hints: {
    ...hints,
    ...annotations,
  },
  layerLibrary,
  legend,
  variablePicker,
  visualisationType,
  userLocation,
  modals,
  locationAnalysis,
  points,
};
