// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import DidYouMeanSuggestion from './DidYouMeanSuggestion';

/**
 * @typedef Props
 * @property {import('../../../').SearchDidYouMean[]} suggestions
 * @property {(suggest: import('../../../').SearchDidYouMean, optionIndex: number) => void} onClick
 * @property {import('react-intl').intlShape} intl
 *
 * @extends {React.Component<Props>}
 */
class DidYouMean extends React.Component {
    render() {
        const suggestionElements = this.props.suggestions
            .filter(suggestion => suggestion.options.length > 0)
            .map(suggestion => (
                <DidYouMeanSuggestion
                    key={suggestion.text}
                    suggestion={suggestion}
                    onClick={this.props.onClick}
                />
            ));
        if (suggestionElements.length === 0) {
            return null;
        }
        return (
            <div className="search-did-you-mean-suggestions">
                <div className="search-did-you-mean-suggestions__header">
                    {this.props.intl.formatMessage({
                        id: 'dataBrowser.didYouMean',
                    })}
                </div>
                {suggestionElements}
            </div>
        );
    }
}

DidYouMean.propTypes = {
    suggestions: PropTypes.array.isRequired,
    onClick: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(DidYouMean);
