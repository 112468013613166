import GLU from '../glu2.js/src/index';

class FeedbackApi extends GLU.Api {
  constructor(endpoint) {
    super(endpoint);

    this.createApiActions({
      name: 'sendFeedback',
      method: GLU.Api.Post,
      path: '/send_feedback',
      credentials: true,
    });
  }
}

export default FeedbackApi;
