import React from 'react';
import { injectIntl } from 'react-intl';
import BusComponent from '../BusComponent';
import TextInput from '../form/TextInput';
import googleTagManagerEvents from '../../helpers/GoogleTagManagerEventsWrapper';
import { saveAsProjectEvents } from '../../enums/GoogleTagManagerEvents';

const SaveAsButton = googleTagManagerEvents('button', saveAsProjectEvents);

class SaveAsProjectPopup extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            newProjectTitle: '',
            newProjectDescription: '',
        };
    }

    componentDidMount() {
        const { saveAsNew, intl } = this.props;
        this.saveButton.setCustomAttribute('gtm-eventc', 'Map projects');
        this.saveButton.setCustomAttribute('gtm-eventa', saveAsNew ? intl.formatMessage({ id: 'header.saveAsNew' }) : intl.formatMessage({ id: 'header.saveAs' }));
    }

    onCreateNewProject = () => {
        this.emit('SAVE_AS_NEW_PROJECT_REQUEST', {
            title: this.state.newProjectTitle !== '' ? this.state.newProjectTitle : 'Untitled',
            description: this.state.newProjectDescription,
        });
        this.emit('CLOSE_MODAL');
    }

    onClickCancel = () => {
        this.emit('CLOSE_MODAL');
    }

    onNewProjectTitleChanged = value => {
        this.setState({
            newProjectTitle: value,
        }, () => this.saveButton.setCustomAttribute('gtm-eventl', value));
    }

    onNewProjectDescriptionChanged = value => {
        this.setState({
            newProjectDescription: value,
        });
    }

    render() {
        const { intl } = this.props;
        return (<div className="save-as-project-content">
            <TextInput
                initialFocus
                className="new-project-title"
                value={this.state.newProjectTitle}
                placeholder={intl.formatMessage({ id: 'untitledProject' })}
                onChange={this.onNewProjectTitleChanged}
                onEnter={this.onCreateNewProject}
                label={intl.formatMessage({ id: 'title' })}
            />
            <TextInput
                multiline
                className="new-project-description"
                value={this.state.newProjectDescription}
                placeholder={intl.formatMessage({ id: 'descriptionOptional' })}
                onChange={this.onNewProjectDescriptionChanged}
                onEnter={this.onCreateNewProject}
            />
            <div className="save-as-project-actions margin-top-10 flex-it center flex-end">
                <button className="btn-flat" onClick={this.onClickCancel}>
                    {intl.formatMessage({ id: 'cancel' })}
                </button>
                <SaveAsButton
                    ref={c => { this.saveButton = c; }}
                    className="btn-raised"
                    onClick={this.onCreateNewProject}
                >
                    {intl.formatMessage({ id: 'save' })}
                </SaveAsButton>
            </div>
        </div>);
    }
}

export default injectIntl(SaveAsProjectPopup);
