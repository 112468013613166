import React from 'react';
import PropTypes from 'prop-types';
import * as SelfStoragePointType from '../../enums/SelfStoragePointType';

const InfoBubbleHeader = props => {
    const { surveyCode } = props;

    const pointType = SelfStoragePointType.resolvePointTypeFromSurveyCode(surveyCode);
    if (!pointType) {
        return null;
    }

    let title, isSS, pointTag;
    switch (pointType) {
        case SelfStoragePointType.SELFSTORAGE_POINT_TYPE.SS_FACILITY:
            title = 'SELF STORAGE';
            isSS = true;
            pointTag = 'OPERATING FACILITY';
            break;
        case SelfStoragePointType.SELFSTORAGE_POINT_TYPE.SS_CONSTRUCTION:
            title = 'SELF STORAGE';
            isSS = true;
            pointTag = 'CONSTRUCTION SITE';
            break;
        case SelfStoragePointType.SELFSTORAGE_POINT_TYPE.HOUSING_STARTS:
            title = 'HOUSING STARTS';
            isSS = true;
            pointTag = 'CONSTRUCTION SITE';
            break;
        case SelfStoragePointType.SELFSTORAGE_POINT_TYPE.INFLUENCER:
            title = 'COMMERCIAL CONSTRUCTION';
            isSS = true;
            pointTag = 'CONSTRUCTION SITE';
            break;
        default:
            title = 'N/A';
            isSS = false;
            break;
    }

    let ssTag;
    if (isSS) {
        ssTag = <span className="tag">{pointTag}</span>;
    }
    return (
        <div className="header">
            <span className="title">{title}</span>
            {ssTag}
        </div>
    );
};

InfoBubbleHeader.propTypes = {
    surveyCode: PropTypes.string,
};

InfoBubbleHeader.defaultProps = {
    surveyCode: null,
};

export default InfoBubbleHeader;
