import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import BusComponent from '../BusComponent';
import SimpleDropdown from '../dropdown/SimpleDropdown';
import TableVariableElement from './TableVariableElement';

class AllDataTab extends BusComponent {
    constructor(props, context) {
        super(props, context);
        const { currentMetadataSelection, mapsGroupAvailableDatasets, field } = props;
        const datasets = currentMetadataSelection.survey.datasetsAsArray.filter(ds => (
            mapsGroupAvailableDatasets[currentMetadataSelection.survey.name].indexOf(ds.abbrevation) !== -1
        ));

        this.state = {
            hasData: false,
            selectedSurvey: currentMetadataSelection.survey,
            selectedSurveyName: field.surveyName || currentMetadataSelection.survey.name,
            datasets,
            selectedDatasetAbbrevation: field.datasetAbbreviation || currentMetadataSelection.dataset.abbrevation,
            tables: [],
            selectedTable: undefined,
            shouldScrollToSelected: true,
        };

        this.bindGluBusEvents({
            DATASET_LOAD_SUCCESS: this.onDatasetLoadSuccess,
            TABLE_LOAD_SUCCESS: this.onTableLoadSuccess,
        });
    }

    componentDidMount() {
        const { selectedSurveyName, selectedDatasetAbbrevation } = this.state;
        const { mapInstanceId } = this.props;
        // selected dataset may not be fully loaded with all tables so we must load it
        this.emit('DATASET_LOAD_REQUEST', {
            surveyName: selectedSurveyName,
            datasetName: selectedDatasetAbbrevation,
            mapInstanceId,
            source: this,
        });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    onDatasetLoadSuccess({ dataset }) {
        const { mapInstanceId, tableName } = this.props;
        const datasetTables = dataset.tablesAsArray;
        let selectedTable = datasetTables[0];
        if (tableName) {
            selectedTable = datasetTables.find(t => t.name === tableName) || selectedTable;
        }
        this.setState(
            {
                selectedDatasetAbbrevation: dataset.abbrevation,
                tables: datasetTables,
                selectedTable,
            },
            () => {
                // table may not be fully loaded so we must load it
                this.emit('TABLE_LOAD_REQUEST', {
                    surveyName: this.state.selectedSurveyName,
                    datasetName: dataset.abbrevation,
                    tableName: selectedTable.name,
                    mapInstanceId,
                    source: this,
                });
            });
    }

    onTableLoadSuccess({ table }) {
        this.setState({
            selectedTable: table,
            filterInputText: '',
            hasData: true,
        });
    }

    onSelectedDatasetChanged = newDatasetAbbrevation => {
        // show loader
        this.setState(
            {
                hasData: false,
                shouldScrollToSelected: true,
            },
            () => {
                // load dataset
                this.emit('DATASET_LOAD_REQUEST', {
                    surveyName: this.state.selectedSurveyName,
                    datasetName: newDatasetAbbrevation,
                    mapInstanceId: this.props.mapInstanceId,
                    source: this,
                });
            }
        );
    };

    onSelectedTableChanged = newTableName => {
        if (newTableName !== this.state.selectedTable.name) {
            const { selectedSurveyName, selectedDatasetAbbrevation } = this.state;
            this.setState(
                {
                    hasData: false,
                    shouldScrollToSelected: true,
                },
                () => {
                    // load table
                    this.emit('TABLE_LOAD_REQUEST', {
                        surveyName: selectedSurveyName,
                        datasetName: selectedDatasetAbbrevation,
                        tableName: newTableName,
                        mapInstanceId: this.props.mapInstanceId,
                        source: this,
                    });
                }
            );
        }
    };

    render() {
        const { selectedDatasetAbbrevation, datasets, tables, selectedTable } = this.state;
        const { mapInstanceId, onSelectVariable, fieldName, intl } = this.props;

        const mappedDatasets = datasets.map(d => ({ id: d.abbrevation, text: d.displayName }));
        const variables = selectedTable &&
                          Object.values(selectedTable.variables).map(v => (
                              <TableVariableElement
                                  key={v.uuid}
                                  onSelectVariable={() => onSelectVariable(v)}
                                  variable={v}
                                  selected={fieldName === v.uuid}
                              />
                          ));

        return (
            <div className="all-data-tab__content flex-it column stretch">
                <div className="all-data-tab__selection no-shrink">
                    <div className="flex-it center">
                        <div className="flex-it column stretch all-data-tab__selection-item">
                            <SimpleDropdown
                                title={intl.formatMessage({ id: 'dataset' })}
                                className="simple-dropdown--metadata-selection simple-dropdown--metadata-selection--filter"
                                onItemClick={this.onSelectedDatasetChanged}
                                mapInstanceId={mapInstanceId}
                                items={mappedDatasets}
                                selectedItem={mappedDatasets.find(d => d.id === selectedDatasetAbbrevation)}
                            />
                        </div>
                    </div>
                    <div className="flex-it column stretch all-data-tab__selection-item">
                        <SimpleDropdown
                            title={intl.formatMessage({ id: 'table' })}
                            className="simple-dropdown--metadata-selection simple-dropdown--metadata-selection--filter"
                            onItemClick={this.onSelectedTableChanged}
                            mapInstanceId={mapInstanceId}
                            items={tables.map(t => ({ id: t.name, text: `${t.displayName}-${t.title}` }))}
                            selectedItem={selectedTable ? {
                                id: selectedTable.name,
                                text: `${selectedTable.displayName}-${selectedTable.title}`,
                            } : { id: undefined, text: 'Select table' }}
                            showFilter
                            filterPlaceholder="Search table"
                        />
                    </div>
                </div>
                <div className="all-data-tab__variables-list-container flex-it column stretch vertical-scrollbar">
                    <ol className="all-data-tab__variables-list">
                        {variables}
                    </ol>
                </div>
            </div>
        );
    }
}

AllDataTab.propTypes = {
    onSelectVariable: PropTypes.func.isRequired,
    mapInstanceId: PropTypes.string.isRequired,
    currentMetadataSelection: PropTypes.object.isRequired,
    mapsGroupAvailableDatasets: PropTypes.object.isRequired,
    field: PropTypes.object.isRequired,
    fieldName: PropTypes.string,
    tableName: PropTypes.string,
    intl: PropTypes.object.isRequired,
};

AllDataTab.defaultProps = {
    fieldName: '',
    tableName: undefined,
};

export default injectIntl(AllDataTab);
