import React from 'react';
import PropTypes from 'prop-types';
import BusComponent from '../BusComponent';
import classNames from 'classnames';
import DataCategoriesList from './dataCategories/DataCategoriesList';
import DataCategoryTables from './dataCategories/DataCategoryTables';
import DataSurveyYears from './dataSurveyYears/DataSurveyYears';

class DataBrowserByCategory extends BusComponent {
    constructor(props, context) {
        super(props, context);

        this.state = Object.assign({
            dataCategorySelection: props.dataCategorySelection,
            selectedSurveyYear: props.selectedYear || props.currentMetadataSelection.survey.year,
        });
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.mapInstanceId !== nextProps.mapInstanceId) {
            this.setState({
                dataCategorySelection: nextProps.dataCategorySelection,
                selectedSurveyYear: nextProps.selectedYear || nextProps.currentMetadataSelection.survey.year,
            });
        }
    }

    onDataCategorySelectedYearChange = year => {
        const newlySelectedDataCategory =
            this.props.mapsGroupDataCategories
                .find(dataCategory => dataCategory.name === this.state.dataCategorySelection.dataCategory.name);

        const dataCategorySelection = {
            dataCategory: newlySelectedDataCategory,
            year,
        };

        this.setState(
            {
                selectedSurveyYear: year,
                dataCategorySelection,
            },
            () => {
                if (this.props.onChangeDataCategorySelection) {
                    this.props.onChangeDataCategorySelection(dataCategorySelection);
                }
            },
        );
    };

    onSelectedYearChange = year => {
        this.setState({
            selectedSurveyYear: year,
        });
    };

    onSelectedDataCategoryChange = (dataCategory, surveyYear) => {
        const dataCategorySelection = { dataCategory, year: surveyYear || this.state.selectedSurveyYear };
        this.setState({
            dataCategorySelection,
        });
        if (this.props.onChangeDataCategorySelection) {
            this.props.onChangeDataCategorySelection(dataCategorySelection);
        }
    };

    onHideDataCategorySelection = () => {
        this.setState({
            dataCategorySelection: undefined,
        }, () => {
            if (this.props.onChangeDataCategorySelection) {
                this.props.onChangeDataCategorySelection(undefined);
            }
        });
    };

    render() {
        const classes = classNames('data-categories-browser flex-it column stretch', this.props.className);

        return (
            <div className={classes}>
                {this.renderContent()}
            </div>
        );
    }

    renderContent() {
        const {
            dataCategorySelection,
            selectedSurveyYear,
        } = this.state;

        const {
            mapInstanceId,
            hasDataFilter,
            metadataGroupId,
            currentMetadataSelection,
            surveysYears,
            mapsGroupDataCategories,
        } = this.props;

        const components = [];

        if (dataCategorySelection) {
            const {
                dataCategory,
                year,
            } = dataCategorySelection;

            components.push(
                <DataCategoryTables
                    key="category-tables"
                    className="grow"
                    categoryName={dataCategory.name}
                    categoryIcon={dataCategory.iconUrl}
                    categoryYears={dataCategory.availableYears}
                    year={year}
                    mapInstanceId={mapInstanceId}
                    hasDataFilter={hasDataFilter}
                    metadataGroupId={metadataGroupId}
                    currentMetadataSelection={currentMetadataSelection}
                    onYearChange={this.onDataCategorySelectedYearChange}
                    onExit={this.onHideDataCategorySelection}
                />
            );
        } else {
            components.push(
                <DataSurveyYears
                    key="survey-years"
                    years={surveysYears}
                    selectedYear={selectedSurveyYear}
                    onYearChange={this.onSelectedYearChange}
                />,
                <DataCategoriesList
                    key="categories-list"
                    className="data-categories-browser__scrollable"
                    dataCategories={mapsGroupDataCategories}
                    year={selectedSurveyYear}
                    onCategoryChange={this.onSelectedDataCategoryChange}
                />
            );
        }

        return components;
    }
}

DataBrowserByCategory.propTypes = {
    className: PropTypes.string,
    dataCategorySelection: PropTypes.object,
    mapInstanceId: PropTypes.string.isRequired,
    hasDataFilter: PropTypes.bool,
    metadataGroupId: PropTypes.string.isRequired,
    surveysYears: PropTypes.array.isRequired,
    mapsGroupDataCategories: PropTypes.array.isRequired,
    currentMetadataSelection: PropTypes.object.isRequired,
    onChangeDataCategorySelection: PropTypes.func,
};

DataBrowserByCategory.defaultProps = {
    className: '',
    dataCategorySelection: undefined,
    onChangeDataCategorySelection: undefined,
    hasDataFilter: false,
};

export default DataBrowserByCategory;
