import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Loader = props => (
    <div className={classNames(props.className, 'svg-loader')}>
        <svg
            className="svg-loader__icon"
            width={props.width}
            height={props.height}
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            enableBackground="new 0 0 0 0"
        >
            <path
                d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                transform="rotate(72 50 50)"
            >
                <animateTransform
                    attributeName="transform"
                    attributeType="XML"
                    type="rotate"
                    dur="1s"
                    from="0 50 50"
                    to="360 50 50"
                    repeatCount="indefinite"
                />
            </path>
        </svg>
        {props.heading && (
            <span className="svg-loader__heading">{props.heading}</span>
        )}
        {props.text && (
            <span className="svg-loader__text">{props.text}</span>
        )}
        {props.intlId && (
            <span className="svg-loader__text">{props.intl.formatMessage({ id: props.intlId })}</span>
        )}
    </div>
);

Loader.propTypes = {
    className: PropTypes.string,
    text: PropTypes.string,
    intlId: PropTypes.string,
    heading: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    intl: PropTypes.object.isRequired,
};

Loader.defaultProps = {
    className: undefined,
    heading: undefined,
    text: null,
    intlId: null,
    width: '150px',
    height: '150px',
};

export default injectIntl(Loader);
