import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import AppConfig from '../../../appConfig';

import BusComponent from '../../BusComponent';
import Tooltip from '../../Tooltip';

import UserMapViewsList from './UserMapViewsList';

class UserMapViews extends BusComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            popupOpen: false,
        };

        this.bindGluBusEvents({
            USER_SETTINGS_LOAD_SUCCESS: this.onUserMapViews,
            USER_MAP_VIEWS: this.onUserMapViews,
            USER_MAP_VIEW_SAVED: this.onModalAction,
            USER_MAP_VIEW_DELETED: this.onModalAction,
            USER_MAP_VIEW_UPDATED: this.onUserMapViewUpdated,
            EDIT_USER_MAP_VIEW_POPUP_REQUEST: this.onHideUserMapViewsListPopup,
            ADD_USER_MAP_VIEW_POPUP_REQUEST: this.onHideUserMapViewsListPopup,
            SHOW_USER_MAP_VIEW_LIST_POPUP: this.onShowUserMapViewsListPopup,
        });
    }

    componentDidMount = () => {
        this.bus.emit('USER_MAP_VIEWS_REQUEST');
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    onUserMapViews(payload) {
        this.setState({
            userMapViews: payload,
        });
    }

    onModalAction() {
        this.bus.emit('USER_MAP_VIEWS_REQUEST');
        this.setState({ popupOpen: true });
    }

    onUserMapViewUpdated(payload) {
        const { mapInstanceId } = this.props;
        const { updatedItem, updatePosition } = payload;

        if (updatePosition) {
            const { centerLat, centerLng, zoom } = updatedItem.mapView;
            const { boundingBoxSwLng, boundingBoxSwLat, boundingBoxNeLng, boundingBoxNeLat } = updatedItem.mapView;

            const center = [centerLng, centerLat];
            const bounds = [[boundingBoxSwLng, boundingBoxSwLat], [boundingBoxNeLng, boundingBoxNeLat]];

            const mapPayload = {
                mapInstanceId,
                center,
                zoom,
                bounds,
                padding: 0,
            };

            this.bus.emit('MAP_FLY_TO_REQUEST', mapPayload);
        }

        this.onModalAction();
    }

    onShowUserMapViewsListPopup() {
        this.setState({ popupOpen: true });
    }

    onHideUserMapViewsListPopup() {
        this.setState({ popupOpen: false });
    }

    onMapViewsClicked = () => {
        this.setState({ popupOpen: !this.state.popupOpen });
    }

    onClosePopover = () => {
        this.setState({ popupOpen: false });
    }

    render() {
        const { mapInstanceId, alignLeft } = this.props;
        const { popupOpen, userMapViews } = this.state;

        return (
            <div ref={e => { this.anchorElement = e; }}>
                <Tooltip
                    placement="left"
                    mouseLeaveDelay={0}
                    overlay={this.props.intl.formatMessage({ id: 'map.mapPositions' })}
                >
                    <button
                        className="map-btn"
                        aria-label={this.props.intl.formatMessage({ id: 'map.mapPositions' })}
                        onClick={this.onMapViewsClicked}
                    >
                        <img
                            src={`${AppConfig.constants.assetsBaseURL}/svg/map-views.svg`}
                            alt={this.props.intl.formatMessage({ id: 'map.mapPositions' })}
                        />
                    </button>
                </Tooltip>
                {popupOpen &&
                    <UserMapViewsList
                        mapInstanceId={mapInstanceId}
                        anchorElement={this.anchorElement}
                        alignLeft={alignLeft}
                        open={popupOpen}
                        userMapViews={userMapViews}
                        onClose={this.onClosePopover}
                    />
                }
                <div className="map-controls__group-divider" />
            </div>
        );
    }
}

UserMapViews.propTypes = {
    mapInstanceId: PropTypes.string.isRequired,
    alignLeft: PropTypes.bool,
    intl: PropTypes.object.isRequired,
};

UserMapViews.defaultProps = {
    alignLeft: false,
};

export default injectIntl(UserMapViews);
