// @ts-check
import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import AppConfig from '../../../appConfig';

import AdditionalSettingsIconColors from '../../../enums/AdditionalSettingsIconColors';

import BusComponent from '../../BusComponent';
import AdditionalSettingsItem from './AdditionalSettingsItem';
import MapLayersItem from './MapLayersItem';
import FilterAreasByCriteriaItem from './FilterAreasByCriteriaItem';
import AnnotationItem from './AnnotationItem';

/**
 * @typedef Props
 * @property {boolean} isUserPro
 * @property {{ label: string, info: string }} [reportDisabled]
 * @property {{ label: string, info: string }} [dataFilterDisabled]
 * @property {import('../../../objects/MapInstance').default} mapInstance
 * @property {import('../../../objects/MetaSurvey').default} survey
 * @property {import('react-intl').intlShape} intl
 *
 * @extends BusComponent<Props>
 */
class AdditionalSettings extends BusComponent {
    static NAME = 'AdditionalSettings';

    constructor(props, context) {
        super(props, context);

        this.state = {};
    }

    onMaskMapDataClick = () => {
        this.emit('ENTER_MASK_MODE', {
            mapInstanceId: this.props.mapInstance.id,
        });
    };


    render() {
        const {
            isUserPro,
            reportDisabled,
            dataFilterDisabled,
            mapInstance,
            survey,
        } = this.props;
        const additionalSettingsItems = AppConfig.constants.moreOptionsMenu;
        return (
            <div className="additional-settings">
                {additionalSettingsItems.mapMask &&
                    <AdditionalSettingsItem
                        icon={<i className="socex-icon-mask-1" />}
                        onItemClick={this.onMaskMapDataClick}
                        title={this.props.intl.formatMessage({
                            id: 'dataBrowser.maskMapData',
                        })}
                        description={this.props.intl.formatMessage({
                            id: 'dataBrowser.showDataOnlyForSelectedRegion',
                        })}
                        iconStyle={{
                            background: AdditionalSettingsIconColors.MASK,
                        }}
                    />
                }
                {additionalSettingsItems.filterArea &&
                    <FilterAreasByCriteriaItem
                        dataFilterDisabled={dataFilterDisabled}
                        isUserPro={isUserPro}
                        mapInstanceId={mapInstance.id}
                    />
                }
                {additionalSettingsItems.mapLayers &&
                    <MapLayersItem
                        isUserPro={isUserPro}
                        mapInstance={mapInstance}
                        additionalActions={additionalSettingsItems.mapLayers}
                    />
                }
                {additionalSettingsItems.annotations &&
                    <AnnotationItem
                        mapInstanceId={this.props.mapInstance.id}
                    />
                }
            </div>
        );
    }
}

AdditionalSettings.propTypes = {
    mapInstance: PropTypes.object.isRequired,
    survey: PropTypes.object.isRequired,
    isUserPro: PropTypes.bool,
    reportDisabled: PropTypes.object,
    dataFilterDisabled: PropTypes.object,
    intl: PropTypes.object.isRequired,
};

AdditionalSettings.defaultProps = {
    isUserPro: false,
    reportDisabled: undefined,
    dataFilterDisabled: undefined,
};

export default injectIntl(AdditionalSettings);
