const PointGeometry = require('@mapbox/point-geometry');

const {
    multiPolygonIntersectsMultiPolygon,
    multiPolygonIncludesBufferedMultiPoint,
    multiPolygonIntersectsCircle,
    multiPolygonIntersectsBufferedMultiLine,
    multiPolygonIncludesMultiPolygon} = require('../util/intersection_tests');


class DragonflyIntersectionTests {
    static figureOutAreaMetadata(inclusionRule, styleLayer, feature, tile) {
        let areaMetadata;
        if (inclusionRule === 'CONTAIN') { //!
            areaMetadata = {};
            if (tile.metadata.buckets[styleLayer.id].features[feature.id].shouldCheckForPartials) { //!
                areaMetadata.shouldCheckForPartials = tile.metadata.buckets[styleLayer.id].features[feature.id].shouldCheckForPartials;
                areaMetadata.partialArea = tile.metadata.buckets[styleLayer.id].features[feature.id].areaPartial; //!
                areaMetadata.totalArea = tile.metadata.buckets[styleLayer.id].features[feature.id].areaTotal; //!
            } //!
        } //!
        return areaMetadata;
    }

    static advancedIntersectionTest(inclusionRule, radius, translatedPolygon, geometry, feature, tile, layer) {
        const isPointFeature = feature.type === 1; //!
        switch (inclusionRule) {
        case 'CONTAIN': { //!
            let featureBoundingBox; //!
            // point feature has no bounding box
            if (isPointFeature) { //!
                featureBoundingBox = geometry[0]; //!
            } else { //!
                const relativeXmax = tile.metadata.buckets[layer.id].features[feature.id].relativeXmax; //!
                const relativeXmin = tile.metadata.buckets[layer.id].features[feature.id].relativeXmin; //!
                const relativeYmax = tile.metadata.buckets[layer.id].features[feature.id].relativeYmax; //!
                const relativeYmin = tile.metadata.buckets[layer.id].features[feature.id].relativeYmin; //!
                featureBoundingBox = [ //!
                    new PointGeometry(relativeXmin, relativeYmin), //! sw
                    new PointGeometry(relativeXmax, relativeYmin), //!
                    new PointGeometry(relativeXmax, relativeYmax), //! ne
                    new PointGeometry(relativeXmin, relativeYmax), //!
                    new PointGeometry(relativeXmin, relativeYmin), //!
                ]; //!
            } //!
            if (radius !== undefined) { //!
                const circleCenter = [[new PointGeometry((translatedPolygon[0][0].x + translatedPolygon[0][2].x) / 2, //!
                    (translatedPolygon[0][0].y + translatedPolygon[0][2].y) / 2)]]; //!
                const circleRadius = Math.abs(translatedPolygon[0][0].x - translatedPolygon[0][2].x) / 2; //!
                // Check for bounding boxes
                // If the bounding box of testing feature is within queryGeometry, then the entire feature is as well
                // We then don't have to check for partials
                if (multiPolygonIncludesBufferedMultiPoint(circleCenter, [featureBoundingBox], circleRadius)) { //!
                    tile.metadata.buckets[layer.id].features[feature.id].shouldCheckForPartials = false; //!
                    return true; //!
                } //!
                // If the bounding box is outside query geometry, then we have to check for partials
                tile.metadata.buckets[layer.id].features[feature.id].shouldCheckForPartials = true; //!
                return multiPolygonIncludesBufferedMultiPoint(circleCenter, geometry, circleRadius); //!
            }
            // Check for bounding boxes
            if (multiPolygonIncludesMultiPolygon(translatedPolygon, [featureBoundingBox])) { //!
                tile.metadata.buckets[layer.id].features[feature.id].shouldCheckForPartials = false; //!
                return true; //!
            } //!
            tile.metadata.buckets[layer.id].features[feature.id].shouldCheckForPartials = true; //!
            return multiPolygonIncludesMultiPolygon(translatedPolygon, geometry); //!
        }
        case 'CENTROID':
            if (radius !== undefined) { //!
                const circleCenter = [[new PointGeometry((translatedPolygon[0][0].x + translatedPolygon[0][2].x) / 2, //!
                    (translatedPolygon[0][0].y + translatedPolygon[0][2].y) / 2)]]; //!
                const circleRadius = Math.abs(translatedPolygon[0][0].x - translatedPolygon[0][2].x) / 2; //!
                return multiPolygonIncludesBufferedMultiPoint(circleCenter, geometry, circleRadius); //!
            }
            return multiPolygonIncludesMultiPolygon(translatedPolygon, geometry); //!
        case 'INTERSECT':
        default:
            if (radius !== undefined) { //!
                const circleCenter = new PointGeometry((translatedPolygon[0][0].x + translatedPolygon[0][2].x) / 2, //!
                    (translatedPolygon[0][0].y + translatedPolygon[0][2].y) / 2); //!
                const circleRadius = Math.abs(translatedPolygon[0][0].x - translatedPolygon[0][2].x) / 2; //!
                // point feature geometry is not a polygon so we handle it here
                if (isPointFeature) { //!
                    return multiPolygonIncludesBufferedMultiPoint([[circleCenter]], geometry, circleRadius); //!
                } //!
                return multiPolygonIntersectsCircle(circleCenter, geometry, circleRadius); //!
            }

            if (translatedPolygon[0][0].equals(translatedPolygon[0][translatedPolygon[0].length - 1])) {
                // if first point is equal to last, it's a closed geometry so check for polygon intersection
                return multiPolygonIntersectsMultiPolygon(translatedPolygon, geometry); //!
            }
            // if first point is NOT equal to last, it's a multipoint geometry so check for line intersection
            return multiPolygonIntersectsBufferedMultiLine(geometry, translatedPolygon, 0); //!
        }
    }

    static mergePartialFeatures(tileFeature, featuresPerLayer, resultFeatures, wrappedID) {
        const areaMetadata = tileFeature.feature.areaMetadata; //!
        featuresPerLayer[tileFeature.feature.id] = featuresPerLayer[tileFeature.feature.id]  || {}; //!
        featuresPerLayer[tileFeature.feature.id].area = featuresPerLayer[tileFeature.feature.id].area || 0; //!
        featuresPerLayer[tileFeature.feature.id].wrappedIDs = featuresPerLayer[tileFeature.feature.id].wrappedIDs || []; //!
        if (featuresPerLayer[tileFeature.feature.id].wrappedIDs.indexOf(wrappedID) === -1) { //!
            featuresPerLayer[tileFeature.feature.id].area = featuresPerLayer[tileFeature.feature.id].area + areaMetadata.partialArea; //!
            featuresPerLayer[tileFeature.feature.id].wrappedIDs.push(wrappedID); //!
        } //!
        const isWithinOnePercent = Math.abs(1 - (featuresPerLayer[tileFeature.feature.id].area / areaMetadata.totalArea)) < 0.05; //!
        if (isWithinOnePercent) { //!
            resultFeatures.push(tileFeature.feature); //!
        } //!
    }
}
module.exports = DragonflyIntersectionTests;
