import React from 'react';
import classNames from 'classnames';
import BusComponent from '../../BusComponent';
import AppConfig from '../../../appConfig';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

import MapViewer from '../../MapViewer';
import MapExportFrameControls from './MapExportFrameControls';
import MapExportFrameOverlay from './MapExportFrameOverlay';

import MapExportFormat from '../../../enums/MapExportFormat';
import MapExportAspectRatio from '../../../enums/MapExportAspectRatio';
import ApplicationFrameType from '../../../enums/ApplicationFrameType';

const currentScreenDPI = Math.round(window.devicePixelRatio * 96);

class MapExportFrame extends BusComponent {
    constructor(props, context) {
        super(props, context);

        this.state = {
            targetAspectRatio: MapExportAspectRatio.SIXTEEN_NINE.value,
            targetZoomLevel: parseFloat(props.mapInstance.initialView.zoom.toFixed(2)),
            targetDPI: currentScreenDPI,
            targetFormat: MapExportFormat.ZIP,
            mapInitialView: props.mapInstance.initialView.clone(),
            isCustomZoomEnabled: false,
            hasSavedSettings: false,
            showRestoreSettings: true,
            exportStatus: undefined,
            exportWarning: false,
        };

        this.overlayInfo = {};
    }

    componentDidMount() {
        AppConfig.sentryRecordEvent('entered map export frame');

        this.bindGluBusEvents({
            EXPORT_MAP_SUCCESS: this.onExportMapSuccess,
            EXPORT_MAP_ERROR: this.onExportMapError,
            EXPORT_MAP_PROGRESS: this.onExportMapProgress,
            EXPORT_SETTINGS_RESTORED: this.onExportSettingsRestored,
            EXPORT_SETTINGS_SAVED: this.onExportSettingsSaved,
            MAP_ZOOM: this.onMapZoomChanged,
        });

        this.bus.once('EXPORT_SETTINGS_CHECKED', this.onExportSettingsChecked);
        this.emit('CHECK_EXPORT_SETTINGS', { source: this });

        this.emit('APPLICATION_FRAME_TYPE_CHANGED', ApplicationFrameType.MAP_EXPORT);
        this.emit('MAP_DISABLE_FEATURE_INTERACTIVITY_REQUEST', { mapInstanceId: this.props.mapInstance.id });
    }

    componentWillUnmount() {
        this.emit('MAP_ENABLE_FEATURE_INTERACTIVITY_REQUEST', { mapInstanceId: this.props.mapInstance.id });
        this.emit('MAP_UPDATE_INITIAL_VIEW_REQUEST', {
            mapInstanceId: this.props.mapInstance.id,
            initialView: this.state.mapInitialView,
        });
        this.unbindGluBusEvents();
    }

    componentWillReceiveProps(nextProps) {
        if (!this.state.mapInitialView.equals(nextProps.mapInstance.initialView)) {
            this.setState({
                targetZoomLevel: parseFloat(nextProps.mapInstance.initialView.zoom.toFixed(2)),
                mapInitialView: nextProps.mapInstance.initialView.clone(),
            });
        }
    }

    onExportMapSuccess = payload => {
        const { targetAspectRatio, targetDPI, targetZoomLevel, targetFormat, isCustomZoomEnabled } = this.state;

        this.bus.emit('DOWNLOAD_MAP_EXPORT_REQUEST', {
            ...payload,
            targetFormat,
        });

        const overlayOrigin = this.overlayInfo.bounds._ne;
        const center = this.mapViewer.dragonflyMap.getCenter();
        const zoom = this.mapViewer.dragonflyMap.getZoom();

        const customTargetZoomLevel = isCustomZoomEnabled ? parseFloat(targetZoomLevel) : zoom;

        const settingsPayload = {
            overlayOrigin,
            zoom,
            center,
            targetAspectRatio,
            targetZoomLevel: customTargetZoomLevel,
            targetDPI,
            targetFormat,
        };

        this.bus.emit('SAVE_EXPORT_SETTINGS', settingsPayload);

        this.setState({
            exportStatus: undefined,
            showRestoreSettings: false,
        });
    };

    onExportMapError = () => {
        this.setState({
            exportStatus: undefined,
        });
    };

    onExportMapProgress = event => {
        this.setState({
            exportStatus: event.percentage,
        });
    };

    onMapZoomChanged = () => {
        this.setState({
            isCustomZoomEnabled: false,
            showRestoreSettings: true,
            targetZoomLevel: parseFloat(this.mapViewer.dragonflyMap.getZoom().toFixed(2)),
        });
    };

    startExport = () => {
        const { isExportSizeTooLarge, isExportSizeTooSmall, bounds } = this.overlayInfo;

        if (isExportSizeTooLarge || isExportSizeTooSmall) {
            this.setState({ exportWarning: true }, () => {
                setTimeout(() => { this.setState({ exportWarning: false }); }, 1000);
            });
            return;
        }

        const { isCustomZoomEnabled } = this.state;

        const targetPixelRatio = this.state.targetDPI / 96;
        const targetZoomLevel = isCustomZoomEnabled ? parseFloat(this.state.targetZoomLevel) : this.mapViewer.dragonflyMap.getZoom();

        this.setState({
            exportStatus: 0,
        }, () => {
            this.emit('EXPORT_MAP_REQUEST', {
                mapViewer: this.mapViewer,
                bounds,
                targetPixelRatio,
                targetZoomLevel,
            });
        });
    };

    onChangeAspectRatio = (event, key, value) => {
        this.setState({ targetAspectRatio: value, showRestoreSettings: true });
    }

    onChangeZoomLevel = value => {
        const mapViewerCurrentZoom = parseFloat(this.mapViewer.dragonflyMap.getZoom().toFixed(2));
        const targetZoomLevel = Math.max(parseFloat(value), mapViewerCurrentZoom);
        this.setState({
            isCustomZoomEnabled: true,
            targetZoomLevel,
            showRestoreSettings: true,
        });
    }

    onChangeDPI = (event, key, value) => {
        const targetDPI = value === undefined ? currentScreenDPI : parseFloat(value);
        this.setState({ targetDPI, showRestoreSettings: true });
    }

    onChangeFormat = (event, key, value) => {
        this.setState({ targetFormat: value, showRestoreSettings: true });
    }

    restorePreviousSettings = () => {
        this.emit('RESTORE_EXPORT_SETTINGS');
    };

    onExportSettingsSaved() {
        this.setState({ hasSavedSettings: true });
    }

    onExportSettingsRestored(payload) {
        const { targetAspectRatio, targetZoomLevel, targetDPI, targetFormat, mapViewerZoom } = payload;

        this.setState({
            targetAspectRatio,
            targetZoomLevel,
            targetDPI,
            targetFormat,
            showRestoreSettings: false,
            isCustomZoomEnabled: targetZoomLevel !== parseFloat(mapViewerZoom.toFixed(2)),
        });
    }

    onExportSettingsChecked = payload => {
        const { hasSavedSettings } = payload;
        this.setState({ hasSavedSettings });
    }

    onOverlayChanged = payload => {
        this.overlayInfo = { ...payload };
    };

    render() {
        const { isCustomZoomEnabled, hasSavedSettings, showRestoreSettings, exportWarning, exportStatus } = this.state;
        const { targetAspectRatio, targetZoomLevel, targetDPI, targetFormat } = this.state;
        const { mapInstance } = this.props;

        const restoreButtonAvailable = hasSavedSettings && showRestoreSettings;

        const exportStripClasses = classNames('map-export-frame__export-strip', {
            'map-export-frame__export-strip--exporting': exportStatus !== undefined,
        });

        return (
            <MuiThemeProvider>
                <div className="frame frame--exporting map-export-frame">
                    <div className="frame__map">
                        <MapViewer
                            ref={m => { this.mapViewer = m; }}
                            mapInstance={mapInstance}
                            annotations={mapInstance.hasAnnotations}
                            annotationsState="static"
                        />
                        <MapExportFrameOverlay
                            mapInstance={this.props.mapInstance}
                            mapViewer={this.mapViewer}
                            targetAspectRatio={targetAspectRatio}
                            targetZoomLevel={targetZoomLevel}
                            targetDPI={targetDPI}
                            targetFormat={targetFormat}
                            exportWarning={exportWarning}
                            exportStatus={exportStatus}
                            isCustomZoomEnabled={isCustomZoomEnabled}
                            onOverlayChange={this.onOverlayChanged}
                        />
                        <div
                            ref={d => { this.exportStrip = d; }}
                            className={exportStripClasses}
                        >
                            <MapExportFrameControls
                                mapViewer={this.mapViewer}
                                showRestoreSettings={restoreButtonAvailable}
                                targetAspectRatio={targetAspectRatio}
                                targetZoomLevel={targetZoomLevel}
                                targetDPI={targetDPI}
                                targetFormat={targetFormat}
                                onChangeAspectRatio={this.onChangeAspectRatio}
                                onChangeZoomLevel={this.onChangeZoomLevel}
                                onChangeDPI={this.onChangeDPI}
                                onChangeFormat={this.onChangeFormat}
                                onRestoreSettings={this.restorePreviousSettings}
                                onExport={this.startExport}
                            />
                        </div>
                    </div>
                </div>
            </MuiThemeProvider>
        );
    }
}

export default MapExportFrame;
