import React from 'react';

import AppConfig from '../appConfig';

export default () => (<div className="application-content unsupported-page">
    <div className="error-page__illustration">
        <div className="error-page__illustration__logo">
            <a href={AppConfig.constants.backends.website}>
                <img
                    src={`${AppConfig.constants.assetsBaseURL}/svg/se-logo-monochrome.svg`}
                    alt="SocialExplorer logo"
                />
            </a>
        </div>
        <div className="error-page__illustration__icon">
            <img
                src={`${AppConfig.constants.assetsBaseURL}/svg/no-webgl.svg`}
                alt="Error illustration"
                width="200"
            />
        </div>
    </div>
    <div className="error-page__explanation">
        <div className="error-page__explanation__title">
            SocialExplorer Maps Application requires WebGL support
        </div>
        <p className="lead">Please check that you are using a <a href="http://caniuse.com/#feat=webgl">supported browser</a> and that WebGL is <a href="https://get.webgl.org/">enabled</a>.</p>
    </div>
</div>);
