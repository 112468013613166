import React from 'react';
import { injectIntl } from 'react-intl';
import BusComponent from '../BusComponent';
import SimpleDropdown from '../dropdown/SimpleDropdown';
import VariableSelection from '../../objects/VariableSelection';
import VariableSelectionItem from '../../objects/VariableSelectionItem';
import AppConfig from '../../appConfig';
import { percentBaseButtonEvents } from '../../enums/GoogleTagManagerEvents';
import googleTagManagerEvents from '../../helpers/GoogleTagManagerEventsWrapper';

const INDENT_PADDING = 24;
const PercentBaseButton = googleTagManagerEvents('button', percentBaseButtonEvents);

class PercentBase extends BusComponent {
    static NAME = 'PercentBase';

    onClick = guid => {
        const {
            surveyName,
            datasetAbbreviation,
            variableGuid,
            tableGuid,
        } = this.props.variableSelection.items[0];

        const newVariableSelectionItem = new VariableSelectionItem({
            surveyName,
            datasetAbbreviation,
            variableGuid,
            tableGuid,
            percentBaseVariableGuidUserOverride: guid,
        });

        const variableSelection = new VariableSelection();
        variableSelection.items = [newVariableSelectionItem];

        this.emit('DATA_SELECTION_CHANGE', {
            source: this,
            mapInstanceId: this.props.mapInstanceId,
            newDataSelection: { variableSelection },
        });
    };

    render() {
        const {
            percentBaseVariable,
            availablePercentBaseVariables,
        } = this.props;

        const items = availablePercentBaseVariables.map(p => ({
            id: p.uuid,
            text: p.label,
            variable: p,
            style: {
                paddingLeft: p.indent * INDENT_PADDING,
            },
        }));

        return (
            <div className="data-info precent-base">
                <div className="data-info__header">
                    <h4 className="data-info__header-title">Variable percent base</h4>
                    <button className="btn-icon" onClick={this.props.onClose}>
                        <i className="material-icons">close</i>
                    </button>
                </div>
                <div className="data-info__content data-info__content--with-overflow">
                    <div className="flex-it start grow margin-top-10 min-width-0">
                        <div className="flex-it column start">
                            <img
                                className="margin-left-10 margin-right-10"
                                alt="universe-icon"
                                src={`${AppConfig.constants.assetsBaseURL}/svg/universe.svg`}
                            />
                        </div>
                        <div className="flex-it column grow margin-left-20 margin-bottom-20 min-width-0">
                            <div>
                                Calculate variable values as percent of:
                            </div>
                            <SimpleDropdown
                                className="margin-top-20"
                                selectedItem={{
                                    id: percentBaseVariable.uuid,
                                    text: percentBaseVariable.label,
                                }}
                                items={items}
                                onItemClick={this.onClick}
                            />
                        </div>
                    </div>
                </div>
                <div className="data-info__actions">
                    <div className="flex-it flex-end">
                        <PercentBaseButton className="btn btn-raised" onClick={this.props.onClose}>
                            {this.props.intl.formatMessage({ id: 'done' })}
                        </PercentBaseButton>
                    </div>
                </div>
            </div>
        );
    }
}

export default injectIntl(PercentBase);
