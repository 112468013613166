import GLU from '../glu2.js/src/index';

class NewReportApi extends GLU.Api {
  constructor(endpoint) {
    super(endpoint);

    this.createApiActions({
      name: 'create',
      method: GLU.Api.Post,
      path: '/create',
      credentials: true,
    });
  }
}

export default NewReportApi;
