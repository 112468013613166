import React from 'react';
import classNames from 'classnames';

import BusComponent from '../BusComponent';
import UserUploadItemOptions from './UserUploadItemOptions';

class UserUploadItem extends BusComponent {
    constructor(props, context) {
        super(props, context);

        this.state = {
            disabled: false, // it should be disabled if it's already on the map
        };
    }

    onItemClick = () => {
        this.bus.emit('CREATE_USER_DATA_LAYER_REQUEST', {
            userDataId: this.props.userData.id,
            title: this.props.userData.title,
            mapInstanceId: this.props.mapInstanceId,
        });
    };

    onGeoJSONFormatClicked = () => {
        this.bus.emit('DOWNLOAD_USER_DATA', {
            userDataId: this.props.userData.id,
        });
    };

    onDeleteDatasetClicked = () => {
        this.emit('DELETE_USER_LAYER_POPUP_REQUEST', { userData: this.props.userData });
        this.emit('CLOSE_DROPDOWN');
    };

    render() {
        return (
            <div
                className={classNames('user-data-uploads__item flex-it stretch space-between', {
                    disabled: this.state.disabled,
                })}
            >
                <div className="flex-it center grow" onClick={this.onItemClick}>
                    <div className="body-1 ellipsis user-data-uploads__item-label">
                        {this.props.userData.title}
                    </div>
                </div>
                <UserUploadItemOptions
                    onGeoJSONFormatClicked={this.onGeoJSONFormatClicked}
                    onDeleteClick={this.onDeleteDatasetClicked}
                />
            </div>
        );
    }
}

export default UserUploadItem;
