// @ts-check
import GLU from '../glu2.js/src/index';
import ColorPalettes from '../objects/ColorPalettes';
import ColorPalettesParser from '../helpers/ColorPalettesParser';
import AppConfig from '../appConfig';
import VisualizationType from '../enums/VisualizationType';
import ColorPaletteType from '../enums/ColorPaletteType';
import VariableValueType from '../enums/VariableValueType';

class ColorPaletteDataSource extends GLU.DataSource {
    static get name() {
        return 'ColorPaletteDataSource';
    }

    static getInstance() {
        return new ColorPaletteDataSource();
    }

    constructor() {
        super(() => false);
        this._currentColorPalettes = new ColorPalettes();
        this._currentColorPalettes.colorPalettes = {};
        this._loadedColorPalettes = {};
    }

    get currentColorPalettes() {
        return this._currentColorPalettes;
    }

    editColorPalette(palette, project, baseMaps) {
        const indexProjectColorPalettes = project.projectColorPalettes.indexOf(palette.original);
        project.projectColorPalettes.splice(indexProjectColorPalettes, 1);
        project.projectColorPalettes.push(palette.edited);
        this.updateColorPaletteList(project, baseMaps);
    }

    addColorPalette(colorPalette, project, baseMaps) {
        project.projectColorPalettes.push(colorPalette);
        this.updateColorPaletteList(project, baseMaps);
    }

    deleteColorPalette(colorPalette, project, baseMaps) {
        const indexProjectColorPalettes = project.projectColorPalettes.findIndex(pcp => pcp.id === colorPalette.id);
        if (indexProjectColorPalettes > -1) {
            project.projectColorPalettes.splice(indexProjectColorPalettes, 1);
            return true;
        }
        this.updateColorPaletteList(project, baseMaps);
        return false;
    }

    async updateMapColorPalettes(map) {
        map.colorPalettes = await this._loadColorPalettesFromURL(map.colorPalettesURL);
        this._currentColorPalettes.colorPalettes[map.colorPalettesURL] = map.colorPalettes;
    }

    async updateColorPaletteList(project, maps) {
        this._currentColorPalettes.colorPalettes.project_color_palettes = project.projectColorPalettes;
        await Promise.all(project.frames.map(async frame => {
            await Promise.all(frame.mapInstances.map(async mapInstance => {
                const map = maps[mapInstance.currentMapId];
                if (this._currentColorPalettes.colorPalettes[map.colorPalettesURL]) {
                    map.colorPalettes = this._currentColorPalettes.colorPalettes[map.colorPalettesURL];
                } else {
                    map.colorPalettes = await this._loadColorPalettesFromURL(map.colorPalettesURL);
                    this._currentColorPalettes.colorPalettes[map.colorPalettesURL] = map.colorPalettes;
                }
            }));
        }));
    }

    async _loadColorPalettesFromURL(colorPalettesURL) {
        let url;
        if (colorPalettesURL.toLocaleLowerCase().indexOf('http://') === 0 || colorPalettesURL.toLocaleLowerCase().indexOf('https://') === 0) {
            url = colorPalettesURL;
        } else {
            url = `${AppConfig.constants.baseURL}${colorPalettesURL[0] === '/' ? '' : '/'}${colorPalettesURL}`;
        }

        const cacheParams = {
            url,
        };

        const logic = (resolve, reject) => {
            const httpRequest = new XMLHttpRequest();

            httpRequest.onreadystatechange = () => {
                if (httpRequest.readyState === XMLHttpRequest.DONE) {
                    if (httpRequest.status === 200) {
                        const colorPalettesJSON = JSON.parse(httpRequest.response);
                        const colorPalettesParser = new ColorPalettesParser(colorPalettesJSON);
                        const colorPalettes = colorPalettesParser.colorPalettes;
                        resolve([colorPalettes]);
                    } else {
                        reject('There was a problem fetching color palettes.');
                    }
                }
            };

            httpRequest.open('GET', url);
            httpRequest.send();
        };

        return new Promise((resolve, reject) => {
            this.cacheRequest(cacheParams, logic)
                .then(responses => resolve(responses[0]), reject);
        });
    }


    /**
     * @param {boolean} isSingleSelection
     * @param {string} visualizationType example 'SHADED_AREA'
     * @param {string} bubbleValueType example 'Number'
     * @param {string} colorPalettesURL
     * @returns {import('../../../types').ColorPalettesListItem[]}
     */
    buildColorPalettesList(isSingleSelection, visualizationType, bubbleValueType, colorPalettesURL) {
        /** @type {import('../../../types').ColorPalettesListItem[]} */
        const colorPalettesList = [];
        switch (visualizationType) {
        case VisualizationType.SHADED_AREA:
            if (isSingleSelection) {
                if (this.currentColorPalettes.getColorPalettesByType(ColorPaletteType.POLYGON_USER_DEFINED, colorPalettesURL).length > 0) {
                    colorPalettesList.push({ name: 'visualisationType.projectPalettes', list: this.currentColorPalettes.getColorPalettesByType(ColorPaletteType.POLYGON_USER_DEFINED, colorPalettesURL) });
                }
                colorPalettesList.push({ name: 'visualisationType.sequential', list: this.currentColorPalettes.getColorPalettesByType(ColorPaletteType.POLYGON_SEQUENTIAL, colorPalettesURL) });
                colorPalettesList.push({ name: 'visualisationType.diverging', list: this.currentColorPalettes.getColorPalettesByType(ColorPaletteType.POLYGON_DIVERGING, colorPalettesURL) });
            } else if (!isSingleSelection) {
                if (this.currentColorPalettes.getColorPalettesByType(ColorPaletteType.MULTI_POLYGON_USER_DEFINED, colorPalettesURL).length > 0) {
                    colorPalettesList.push({ name: 'visualisationType.projectPalettes', list: this.currentColorPalettes.getColorPalettesByType(ColorPaletteType.MULTI_POLYGON_USER_DEFINED, colorPalettesURL) });
                }
                colorPalettesList.push({ name: 'visualisationType.multi', list: this.currentColorPalettes.getColorPalettesByType(ColorPaletteType.MULTI_POLYGON, colorPalettesURL) });
            }
            break;
        case VisualizationType.BUBBLES:
            if (bubbleValueType === VariableValueType.NUMBER) {
                if (this.currentColorPalettes.getColorPalettesByType(ColorPaletteType.BUBBLE_USER_DEFINED_SINGLE_COLOR, colorPalettesURL).length > 0) {
                    colorPalettesList.push({ name: 'visualisationType.projectPalettes', list: this.currentColorPalettes.getColorPalettesByType(ColorPaletteType.BUBBLE_USER_DEFINED_SINGLE_COLOR, colorPalettesURL) });
                }
                colorPalettesList.push({ name: 'visualisationType.sequential', list: this.currentColorPalettes.getColorPalettesByType(ColorPaletteType.BUBBLE_SINGLE_COLOR, colorPalettesURL) });
            } else {
                if (this.currentColorPalettes.getColorPalettesByType(ColorPaletteType.BUBBLE_USER_DEFINED, colorPalettesURL).length > 0) {
                    colorPalettesList.push({ name: 'visualisationType.projectPalettes', list: this.currentColorPalettes.getColorPalettesByType(ColorPaletteType.BUBBLE_USER_DEFINED, colorPalettesURL) });
                }
                colorPalettesList.push({ name: 'visualisationType.sequential', list: this.currentColorPalettes.getColorPalettesByType(ColorPaletteType.BUBBLE_SEQUENTIAL, colorPalettesURL) });
                colorPalettesList.push({ name: 'visualisationType.diverging', list: this.currentColorPalettes.getColorPalettesByType(ColorPaletteType.BUBBLE_DIVERGING, colorPalettesURL) });
            }
            break;
        case VisualizationType.DOT_DENSITY:
            if (isSingleSelection) {
                if (this.currentColorPalettes.getColorPalettesByType(ColorPaletteType.DOT_DENSITY_USER_DEFINED, colorPalettesURL).length > 0) {
                    colorPalettesList.push({ name: 'visualisationType.projectPalettes', list: this.currentColorPalettes.getColorPalettesByType(ColorPaletteType.DOT_DENSITY_USER_DEFINED, colorPalettesURL) });
                }
                colorPalettesList.push({ name: 'visualisationType.sequential', list: this.currentColorPalettes.getColorPalettesByType(ColorPaletteType.DOT_DENSITY, colorPalettesURL) });
            } else if (!isSingleSelection) {
                if (this.currentColorPalettes.getColorPalettesByType(ColorPaletteType.VALUE_DOT_DENSITY_USER_DEFINED, colorPalettesURL).length > 0) {
                    colorPalettesList.push({ name: 'visualisationType.projectPalettes', list: this.currentColorPalettes.getColorPalettesByType(ColorPaletteType.VALUE_DOT_DENSITY_USER_DEFINED, colorPalettesURL) });
                }
                colorPalettesList.push({ name: 'visualisationType.multi', list: this.currentColorPalettes.getColorPalettesByType(ColorPaletteType.VALUE_DOT_DENSITY, colorPalettesURL) });
            }
            break;
        }
        return colorPalettesList;
    }
}

export default ColorPaletteDataSource;
