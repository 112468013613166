/* eslint func-names:0, no-param-reassign:0 */
/**
* Detect Element Resize
*
* https://github.com/sdecima/javascript-detect-element-resize
* Sebastian Decima
*
* version: 0.5.3 but converted into a es6 module manually
* original: https://github.com/sdecima/javascript-detect-element-resize
**/
const attachEvent = document.attachEvent;
let stylesCreated = false, animationName, animationKeyframes, animationStyle, requestFrame, cancelFrame, animationstartevent;

if (!attachEvent) {
    requestFrame = (function () {
        const raf = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame ||
        function (fn) { return window.setTimeout(fn, 20); };
        return function (fn) { return raf(fn); };
    }());

    cancelFrame = (function () {
        const cancel = window.cancelAnimationFrame || window.mozCancelAnimationFrame || window.webkitCancelAnimationFrame ||
        window.clearTimeout;
        return function (id) { return cancel(id); };
    }());

    /* Detect CSS Animations support to detect element display/re-attach */
    let animation = false,
        keyframeprefix = '',
        pfx = '';

    const domPrefixes = 'Webkit Moz O ms'.split(' ');
    const startEvents = 'webkitAnimationStart animationstart oAnimationStart MSAnimationStart'.split(' ');

    animationstartevent = 'animationstart';

    const elm = document.createElement('fakeelement');
    if (elm.style.animationName !== undefined) { animation = true; }
    if (animation === false) {
        for (let i = 0; i < domPrefixes.length; i += 1) {
            if (elm.style[`${domPrefixes[i]}AnimationName`] !== undefined) {
                pfx = domPrefixes[i];
                keyframeprefix = `-${pfx.toLowerCase()}-`;
                animationstartevent = startEvents[i];
                animation = true;
                break;
            }
        }
    }
    animationName = 'resizeanim';
    animationKeyframes = `@${keyframeprefix}keyframes ${animationName} { from { opacity: 0; } to { opacity: 0; } } `;
    animationStyle = `${keyframeprefix}animation: 1ms ${animationName}; `;
}

function resetTriggers(element) {
    const triggers = element.__resizeTriggers__;
    const expand = triggers.firstElementChild;
    const contract = triggers.lastElementChild;
    const expandChild = expand.firstElementChild;
    contract.scrollLeft = contract.scrollWidth;
    contract.scrollTop = contract.scrollHeight;
    expandChild.style.width = `${expand.offsetWidth + 1}px`;
    expandChild.style.height = `${expand.offsetHeight + 1}px`;
    expand.scrollLeft = expand.scrollWidth;
    expand.scrollTop = expand.scrollHeight;
}

function checkTriggers(element) {
    return element.offsetWidth !== element.__resizeLast__.width || element.offsetHeight !== element.__resizeLast__.height;
}

function scrollListener(e) {
    const element = this;
    resetTriggers(this);
    if (this.__resizeRAF__) {
        cancelFrame(this.__resizeRAF__);
    }
    this.__resizeRAF__ = requestFrame(() => {
        if (checkTriggers(element)) {
            element.__resizeLast__.width = element.offsetWidth;
            element.__resizeLast__.height = element.offsetHeight;
            element.__resizeListeners__.forEach(fn => {
                fn.call(element, e);
            });
        }
    });
}

function createStyles() {
    if (!stylesCreated) {
        // opacity:0 works around a chrome bug https://code.google.com/p/chromium/issues/detail?id=286360
        const css = `${animationKeyframes || ''
        }.resize-triggers { ${animationStyle || ''}visibility: hidden; opacity: 0; } ` +
        '.resize-triggers, .resize-triggers > div, .contract-trigger:before { content: " "; display: block; position: absolute; top: 0; left: 0; height: 100%; width: 100%; overflow: hidden; } .resize-triggers > div { background: #eee; overflow: auto; } .contract-trigger:before { width: 200%; height: 200%; }';
        const head = document.head || document.getElementsByTagName('head')[0];
        const style = document.createElement('style');
        style.type = 'text/css';
        if (style.styleSheet) {
            style.styleSheet.cssText = css;
        } else {
            style.appendChild(document.createTextNode(css));
        }

        head.appendChild(style);
        stylesCreated = true;
    }
}

export function removeResizeListener(element, fn) {
    if (attachEvent) {
        element.detachEvent('onresize', fn);
    } else if (element && element.__resizeListeners__) {
        if (element.__resizeListeners__.indexOf(fn) >= 0) {
            element.__resizeListeners__.splice(element.__resizeListeners__.indexOf(fn), 1);
        }
        if (!element.__resizeListeners__.length) {
            element.removeEventListener('scroll', scrollListener);
            element.__resizeTriggers__ = element.getElementsByClassName('resize-triggers').length > 0 ? !element.removeChild(element.__resizeTriggers__) : undefined;
        }
    }
}

export function addResizeListener(element, fn) {
    if (attachEvent) {
        element.attachEvent('onresize', fn);
    } else {
        element.__resizeTriggers__ = element.getElementsByClassName('resize-triggers').length > 0 ? element.getElementsByClassName('resize-triggers')[0] : undefined;
        if (!element.__resizeTriggers__) {
            if (getComputedStyle(element).position === 'static') element.style.position = 'relative';
            createStyles();
            element.__resizeLast__ = {};
            if (!element.__resizeListeners__) {
                element.__resizeListeners__ = [];
            }
            (element.__resizeTriggers__ = document.createElement('div')).className = 'resize-triggers';
            element.__resizeTriggers__.innerHTML = '<div class="expand-trigger"><div></div></div>' +
            '<div class="contract-trigger"></div>';
            element.appendChild(element.__resizeTriggers__);
            resetTriggers(element);
            element.addEventListener('scroll', scrollListener, true);

            /* Listen for a css animation to detect element display/re-attach */
            if (animationstartevent) {
                element.__resizeTriggers__.addEventListener(animationstartevent, e => {
                    if (e.animationName === animationName) {
                        resetTriggers(element);
                    }
                });
            }
        }
        element.__resizeListeners__.push(fn);
    }
}
