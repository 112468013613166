import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ClassesItemImage from '../../layerLibrary/ClassesItemImage';
import ClassesItemLabel from '../../layerLibrary/ClassesItemLabel';

const GeoJsonLibraryLegendItem = ({ item, onLayerVisibilityToggle }) => {
    const { type, style, label, svg } = item;
    const containerClasses = classNames('geo-json-library-legend__item', {
        'geo-json-library-legend__item--disabled': !item.visible,
    });

    return (
        <div className={containerClasses} onClick={() => onLayerVisibilityToggle(item.layerId)}>
            <ClassesItemImage type={type} style={style} svg={svg} />
            <ClassesItemLabel label={label} />
        </div>
    );
};

GeoJsonLibraryLegendItem.propTypes = {
    item: PropTypes.object.isRequired,
    onLayerVisibilityToggle: PropTypes.func.isRequired,
};

export default GeoJsonLibraryLegendItem;
