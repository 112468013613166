import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

const BubblePreviewInfo = props => (<div className="bubble-legend__bubble-preview-info flex-it space-between">
    <div className="flex-it start">
        <div className="bubble-size__circle">
            <div className="bubble-size__halo" />
        </div>
        <span className="bubble-size__description">{props.maxBubbleLabel} ({props.intl.formatMessage({ id: 'legend.maxBubbleSize' })})</span>
    </div>
</div>);


BubblePreviewInfo.propTypes = {
    maxBubbleLabel: PropTypes.string.isRequired,
    intl: PropTypes.object.isRequired,
};

BubblePreviewInfo.defaultProps = {
};

export default injectIntl(BubblePreviewInfo);
