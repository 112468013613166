import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import BusComponent from '../../BusComponent';

import Checkbox from '../../form/Checkbox';
import Slider from '../../form/Slider';

class SatelliteLegendDetails extends BusComponent {
    constructor(props, context) {
        super(props, context);

        this.state = {
            dataOpacity: props.mapInstance.satelliteDataOverlayDataOpacity * 100,
            hasColor: !!props.mapInstance.satelliteDataOverlaySatelliteHasColor,
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            dataOpacity: nextProps.mapInstance.satelliteDataOverlayDataOpacity * 100,
            hasColor: !!nextProps.mapInstance.satelliteDataOverlaySatelliteHasColor,
        });
    }

    onDataOpacityChanged = dataOpacity => {
        this.setState({ dataOpacity }, () => {
            this.emit('SET_SATELLITE_DATA_OVERLAY_DATA_OPACITY_REQUEST', {
                mapInstanceId: this.props.mapInstance.id,
                opacity: dataOpacity / 100,
            });
        });
    }

    onColorChanged = hasColor => {
        this.setState({ hasColor }, () => {
            this.emit('TOGGLE_SATELLITE_DATA_OVERLAY_SATELLITE_COLOR_REQUEST', {
                mapInstanceId: this.props.mapInstance.id,
            });
        });
    }

    render() {
        const { onClose, isCondensedLayout } = this.props;
        const { dataOpacity, hasColor } = this.state;

        const satelliteDetailsClasses = classNames('satellite-legend-details', {
            'satellite-legend-details--condensed': isCondensedLayout,
        });

        return (
            <div className={satelliteDetailsClasses}>
                <div className="satellite-legend-details__header">
                    <span className="font-14 dark-text">
                        {this.props.intl.formatMessage({ id: 'dataBrowser.styleSatelliteView' })}
                    </span>
                    <button className="material-icons md-24 clickable" onClick={onClose}>close</button>
                </div>
                <div className="flex-it column">
                    <div className="satellite-legend-details__data-opacity">
                        <div className="margin-bottom-15 dark-secondary-text">
                            {this.props.intl.formatMessage({ id: 'dataBrowser.dataOpacity' })}
                        </div>
                        <div className="flex-it space-between center row">
                            <Slider
                                className="grow"
                                min={0}
                                max={100}
                                step={10}
                                value={dataOpacity}
                                onChange={this.onDataOpacityChanged}
                            />
                            <span className="satellite-legend-details__data-opacity-label">{dataOpacity}%</span>
                        </div>
                    </div>
                    <div className="satellite-legend-details__color">
                        <div className="dark-secondary-text">
                            {this.props.intl.formatMessage({ id: 'dataBrowser.satelliteColor' })}
                        </div>
                        <Checkbox
                            checked={hasColor}
                            onCheck={this.onColorChanged}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

SatelliteLegendDetails.propTypes = {
    mapInstance: PropTypes.object.isRequired,
    isCondensedLayout: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
};

SatelliteLegendDetails.defaultProps = {
    isCondensedLayout: false,
};

export default injectIntl(SatelliteLegendDetails);
