import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class AvailableDataCategory extends React.Component {
    onSelectedDataCategoryChange = () => {
        const { onSelectedDataCategoryChange, dataCategory } = this.props;
        if (onSelectedDataCategoryChange) {
            onSelectedDataCategoryChange(dataCategory);
        }
    }

    render() {
        const { className, dataCategory } = this.props;
        return (<button
            className={classnames('data-category flex-it flex-start center no-shrink', className)}
            onClick={this.onSelectedDataCategoryChange}
        >
            <img
                className="data-category__icon"
                src={dataCategory.iconUrl}
                alt="Data category icon"
            />
            <div className="data-category__info flex-it column">
                <h5 className="data-category__title">{dataCategory.name}</h5>
            </div>
        </button>);
    }
}

AvailableDataCategory.propTypes = {
    className: PropTypes.string,
    dataCategory: PropTypes.object.isRequired,
    onSelectedDataCategoryChange: PropTypes.func,
};

AvailableDataCategory.defaultProps = {
    className: '',
    onSelectedDataCategoryChange: undefined,
};

export default AvailableDataCategory;
