// @ts-check
import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Tooltip from '../Tooltip';
import { focusFirstChild } from '../../helpers/Util';

/**
 * @typedef Props
 * @property {() => void} onClose
 * @property {import('react-intl').intlShape} intl
 *
 * @extends {React.Component<Props>}
 */
class DataBrowserHeader extends React.Component {
    componentDidMount() {
        focusFirstChild(this.header);
    }

    render() {
        return (
            <div
                className="data-browser__header no-shrink flex-it center flex-end"
                ref={ref => {
                    this.header = ref;
                }}
            >
                <Tooltip
                    placement="bottom"
                    mouseEnterDelay={0.2}
                    mouseLeaveDelay={0}
                    overlay={this.props.intl.formatMessage({ id: 'close' })}
                >
                    <button
                        className="btn-flat-icon"
                        onClick={this.props.onClose}
                    >
                        <i className="material-icons">close</i>
                    </button>
                </Tooltip>
            </div>
        );
    }
}

DataBrowserHeader.propTypes = {
    onClose: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(DataBrowserHeader);
