import BaseHandler from './BaseHandler';
import dragonlfy from 'dragonfly-v3';

class LocationHandler extends BaseHandler {
    constructor(mapViewer) {
        super(mapViewer);
        this._data = mapViewer.dragonflyMapData.locationData;
        this._bus = mapViewer.bus;
        this.setGluBusEvents({
            MAP_DISPLAY_CURRENT_LOCATION_REQUEST: this.displayCurrentLocation,
            MAP_CLEAR_CURRENT_LOCATION_REQUEST: this.clearCurrentLocation,
        });
    }

    displayCurrentLocation(e) {
        if (e.mapInstanceId !== this.mapInstanceId) return;
        const position = e.position;
        if (position) {
            const point = new dragonlfy.LngLat((e.position.coords.longitude), parseFloat(e.position.coords.latitude));
            this._data.userLocationMarker.setLngLat([position.coords.longitude, position.coords.latitude]).addTo(this.map);
            this.map.flyTo({ center: point, speed: 1, curve: 1, zoom: 12 });
        } else {
            this._data.userLocationMarker.remove();
        }
    }

    clearCurrentLocation(e) {
        if (e.mapInstanceId !== this.mapInstanceId) return;
        this._data.userLocationMarker.remove();
    }
}

export default LocationHandler;
