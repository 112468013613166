import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import classnames from 'classnames';

import BusComponent from '../BusComponent';
import SimpleDropdown from '../dropdown/SimpleDropdown';

class LatLongOptions extends BusComponent {
    constructor(props, context) {
        super(props, context);

        const possibleLongitudeCols = [],
            possibleLatitudeCols = [];
        const { userUpload, selection } = this.props;

        userUpload.columns
            .filter(col => col.properties && !col.properties.isEmpty)
            .forEach(col => {
                if (col.properties.min >= -180.0 && col.properties.max <= 180.0) {
                    possibleLongitudeCols.push(col);
                }
                if (col.properties.min >= -90.0 && col.properties.max <= 90.0) {
                    possibleLatitudeCols.push(col);
                }
            });

        let selectedLong, selectedLat;
        if (selection) {
            const { lat, long } = selection;
            selectedLong = long;
            selectedLat = lat;
        }

        this.state = {
            possibleLongitudeCols,
            possibleLatitudeCols,
            selectedLat,
            selectedLong,
        };
    }

    onDoneClick = () => {
        this.emit('USER_DATA_CSV_TO_GEOJSON_REQUEST', {
            mapInstanceId: this.props.mapInstanceId,
            userUpload: this.props.userUpload,
            lat: this.state.selectedLat,
            lng: this.state.selectedLong,
        });
    };

    renderDropdownItem(col) {
        return (
            <div className="flex-it space-between">
                <span className="flex-it no-shrink">{col.title}</span>
                <span className="ellipsis text-muted margin-left-20p font-13">
                    {col.data
                        .filter(v => v)
                        .splice(0, 5)
                        .join(', ')}
                </span>
            </div>
        );
    }

    renderLatitude() {
        const { possibleLatitudeCols, selectedLat } = this.state;
        const items = possibleLatitudeCols.map(col => ({
            id: col.id,
            text: this.renderDropdownItem(col),
        }));

        let selectedItem = { id: undefined, text: 'Select latitude column' };
        if (items.length) {
            if (selectedLat) {
                const column = this.props.userUpload.columns.find(c => selectedLat === c.id);
                selectedItem = { id: column.id, text: column.title };
            }
        } else {
            items.push({ id: '-1', text: 'There are no suitable columns', disabled: true });
        }

        return (
            <div>
                <SimpleDropdown
                    className="margin-top-10 margin-bottom-20"
                    onItemClick={e => this.setState({ selectedLat: e })}
                    items={items}
                    selectedItem={selectedItem}
                />
            </div>
        );
    }

    renderLongitude() {
        const { possibleLongitudeCols, selectedLong } = this.state;
        const items = possibleLongitudeCols.map(col => ({
            id: col.id,
            text: this.renderDropdownItem(col),
        }));

        let selectedItem = { id: undefined, text: 'Select longitude column' };
        if (items.length) {
            if (selectedLong) {
                const column = this.props.userUpload.columns.find(c => selectedLong === c.id);
                selectedItem = { id: column.id, text: column.title };
            }
        } else {
            items.push({ id: '-1', text: 'There are no suitable columns', disabled: true });
        }

        return (
            <div>
                <SimpleDropdown
                    className="margin-top-10 margin-bottom-20"
                    onItemClick={e => this.setState({ selectedLong: e })}
                    items={items}
                    selectedItem={selectedItem}
                />
            </div>
        );
    }

    renderError() {
        const { error } = this.props;
        if (error) {
            return <div className="user-data-upload-editor__errors">{error}</div>;
        }
        return undefined;
    }

    renderActions() {
        const disabled = !this.state.selectedLat || !this.state.selectedLong;
        const classes = classnames('btn-raised', {
            'btn-raised--disabled': disabled,
        });

        return (
            <div className="user-data-upload-editor__actions">
                <div className={classes} disabled={disabled} onClick={this.onDoneClick}>
                    {this.props.intl.formatMessage({ id: 'dataBrowser.addLayer' })}
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="flex-it column grow vertical-scrollbar">
                <div className="user-data-upload-editor__content">
                    <div className="user-data-upload-editor__description">
                        <FormattedMessage id="dataBrowser.selectTheLatitudeAndLongitudeColumns" />
                    </div>
                    {this.renderLatitude()}
                    {this.renderLongitude()}
                    {this.renderError()}
                </div>
                {this.renderActions()}
            </div>
        );
    }
}

export default injectIntl(LatLongOptions);
