// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import FilterTypes from '../../enums/FilterTypes';

import SimpleDropdown from '../dropdown/SimpleDropdown';
import TypeDropdown from '../dataFilter/TypeDropdown';
import classNames from 'classnames';


/**
 * @typedef Props
 * @property {import('../../').PointsFilterField} filter
 * @property {(filter: import('../../').PointsFilterField, value: string | number | boolean, filterType: string) => void} addFilter
 * @property {(property: string) => void} removeFilter
 * @property {import('react-intl').intlShape} intl
 *
 * @typedef State
 * @property {string | number} [selectedValue]
 * @property {string} filterType
 * @property {string | number} [numberValue]
 *
 * @extends {React.Component<Props, State>}
 */
class Filter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedValue: this.props.filter.value,
            filterType:
                this.props.filter.filterType || FilterTypes.GREATER_THAN.type,
            numberValue: this.props.filter.value,
        };
    }

    onClick = selectedValue => {
        this.setState({ selectedValue }, () => {
            // String type filters have always EQUAL set as filterType
            this.props.addFilter(
                this.props.filter,
                selectedValue,
                FilterTypes.EQUAL.type,
            );
        });
    };

    /** @param {string} filterType  */
    onTypeChange = filterType => {
        this.setState({ filterType }, () => {
            this.props.removeFilter(this.props.filter.property);
            if (this.state.numberValue) {
                this.props.addFilter(
                    this.props.filter,
                    this.state.numberValue,
                    filterType,
                );
            }
        });
    };

    onNumberValueChange = event => {
        this.props.removeFilter(this.props.filter.property);
        if (event.target.value) {
            const numberValue = +event.target.value;
            const { min, max } = this.props.filter;
            if (numberValue >= min && numberValue <= max) {
                this.setState({ numberValue }, () => {
                    this.props.addFilter(
                        this.props.filter,
                        numberValue,
                        this.state.filterType,
                    );
                });
            }
        } else {
            this.setState({ numberValue: undefined });
        }
    };

    boolToText = value => (value === true ? 'Yes' : 'No')

    render() {
        const {
            type,
            prefix,
            suffix,
            property,
            values,
            min,
            max,
        } = this.props.filter;
        const suffixClassNames = classNames({ 'filter-suffix': suffix != null });

        switch (type) {
        case 'string':
            return (
                <div className="flex-it center">
                    <span>{prefix}</span>
                    <SimpleDropdown
                        key={property}
                        onItemClick={this.onClick}
                        items={values.map(value => ({
                            id: value,
                            text: value,
                        }))}
                        selectedItem={{
                            id: this.state.selectedValue,
                            text:
                                this.state.selectedValue ||
                                this.props.intl.formatMessage({
                                    id: 'selectValue',
                                }),
                        }}
                        className="simple-dropdown--primary-rounded simple-dropdown--wider"
                        showFilter
                        filterPlaceholder={this.props.intl.formatMessage({
                            id: 'dataBrowser.search',
                        })}
                    />
                </div>
            );
        case 'number':
            return (
                <div className="flex-it center filter-criteria">
                    <div className="filter-criteria-prefix">
                        <span>{prefix}</span>
                        <TypeDropdown
                            value={this.state.filterType}
                            onChange={this.onTypeChange}
                            types={Object.values(FilterTypes).map(filterType => ({
                                ...filterType,
                                text: this.props.intl.formatMessage({
                                    id: filterType.text,
                                }),
                            }))}
                            dropdownClassName="simple-dropdown--primary-rounded"
                        />
                    </div>
                    <div className="filter-input-wrapper">
                        <div className="suffix-wrapper">
                            <input
                                type="number"
                                onChange={this.onNumberValueChange}
                                min={min}
                                max={max}
                                defaultValue={this.state.numberValue}
                                className="filter-input"
                            />
                            <p className="filter-placeholder-values">{`${min.toLocaleString()} - ${max.toLocaleString()}`}</p>
                        </div>
                        <span className={suffixClassNames}>{suffix}</span>
                    </div>
                </div>
            );
        case 'boolean':
            return (
                <div className="flex-it center">
                    <span>{prefix}</span>
                    <SimpleDropdown
                        key={property}
                        onItemClick={this.onClick}
                        items={values.map(value => ({
                            id: value,
                            text: this.boolToText(value),
                        }))}
                        selectedItem={{
                            id: this.state.selectedValue || undefined,
                            text:
                                this.state.selectedValue !== undefined ? this.boolToText(this.state.selectedValue) : this.props.intl.formatMessage({
                                    id: 'selectValue',
                                }),
                        }}
                        className="simple-dropdown--primary-rounded simple-dropdown"
                        showFilter
                        filterPlaceholder={this.props.intl.formatMessage({
                            id: 'dataBrowser.search',
                        })}
                    />
                    <span>{suffix}</span>
                </div>
            );
        default:
            return null;
        }
    }
}

Filter.propTypes = {
    filter: PropTypes.object.isRequired,
    addFilter: PropTypes.func.isRequired,
    removeFilter: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(Filter);
