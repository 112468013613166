import React from 'react';
import BusComponent from '../BusComponent';

import CutpointsSelector from './selectors/cutpointsSelector/CutpointsSelector';

class BubbleVisualizationEditor extends BusComponent {
    constructor(props, context) {
        super(props, context);

        this.state = {
            dataClassificationAvailable: false,
        };

        this.bindGluBusEvents({
            DATA_CLASSIFICATION_AVAILABILITY_INFO: this.onDataClassificationAvailabilityInfo,
        });
    }

    componentWillMount() {
        this.emit('GET_DATA_CLASSIFICATION_AVAILABILITY_INFO_REQUEST', this.props.mapInstance);
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    componentWillReceiveProps(nextProps) {
        this.emit('GET_DATA_CLASSIFICATION_AVAILABILITY_INFO_REQUEST', nextProps.mapInstance);
    }

    onDataClassificationAvailabilityInfo(e) {
        this.setState({
            dataClassificationAvailable: e.dataClassificationAvailability && false, // Remove cutpoints popup for now
        });
    }

    onShowDataClassificationModalClick = () => {
        this.emit('CLOSE_DROPDOWN');
        this.emit('DATA_CLASSIFICATION_POPUP_REQUEST', this.props.mapInstanceId);
    };

    render() {
        return (<div className="flex-it column no-shrink" >
            {this.state.dataClassificationAvailable && <CutpointsSelector
                mapInstanceId={this.props.mapInstanceId}
                dataClassificationAvailability={this.state.dataClassificationAvailability}
                onShowDataClassificationModalClick={this.onShowDataClassificationModalClick}
            />}
        </div>);
    }
}

export default BubbleVisualizationEditor;
