import GLU from '../glu2.js/src/index';

class MapboxApi extends GLU.Api {
  constructor(endpoint) {
    super(endpoint);

    this.createApiActions({
      name: 'getDirections',
      method: GLU.Api.Get,
      path: '/directions/v5/{profile}/{coordinates}',
      credentials: false,
    });
  }
}

export default MapboxApi;
