import React from 'react';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';

import BusComponent from '../BusComponent';
import VisualizationEditor from '../visualizationEditor/VisualizationEditor';
import SummaryLevelSelector from '../summaryLevelSelector/SummaryLevelSelector';
import AdditionalSettings from './additionalSettings/AdditionalSettings';
import DataDictionary from './DataDictionary';
import CPICorrection from './CPICorrection';
import PercentBase from './PercentBase';
import MenuTrigger from './MenuTrigger.';
import Tooltip from '../Tooltip';
import Trigger from './Trigger';
import Menu from './Menu';
import MobileMenu from './mobileMenu/MobileMenu';
import MobileMenuItem from './mobileMenu/MobileMenuItem';
import YearSelection from './yearSelection/YearSelection';
import ChangeOverTime from './yearSelection/ChangeOverTime';
import LocationPanelMinimized from '../locationPanel/LocationPanelMinimized';

import AppConfig from '../../appConfig';
import ApplicationMode from '../../enums/ApplicationMode';
import VisualizationTypes from '../../enums/VisualizationType';
import changeOverTimeType from '../../enums/ChangeOverTimeType';
import { ReportBadges, DataFilterBadges } from '../../enums/SettingsBadges';
import { HelpTourTargets } from '../../enums/HelpTourDefinitions';
import { moreInfoButtonEvents } from '../../enums/GoogleTagManagerEvents';
import googleTagManagerEvents from '../../helpers/GoogleTagManagerEventsWrapper';
import buildParentVariableTree from '../../helpers/Metadata';

const MoreInfoIcon = googleTagManagerEvents('i', moreInfoButtonEvents);

class MapPanel extends BusComponent {
    constructor(props, context) {
        super(props, context);

        this.state = {
            activeSummaryLevel: props.mapViewer.activeSummaryLevel,
            initialLoadLogged: false,
            metadata: undefined,
        };
    }

    componentWillMount() {
        this.bindGluBusEvents({
            VARIABLE_SELECTION_METADATA_INFO: this.onVariableSelectionMetadataInfoRetrieved,
            MAP_NEW_DATA_THEME_APPLIED: this.onMapNewDataThemeApplied,
            DRAGONFLY_MAP_CREATED: this.onSummaryLevelChange,
            MAP_PREFERRED_SUMMARY_LEVEL_CHANGED: this.onSummaryLevelChange,
            MAP_ZOOM_END: this.onSummaryLevelChange,
            REQUEST_VISUALIZATION_TYPE_DROPDOWN_TOGGLE: this.onVisualizationEditorRequest,
            CHANGE_OVER_TIME_AVAILABILITY_STATUS: this.handleCOTAvailabilityRequest,
            HELP_TOUR_START_REQUEST: this.onHelpTourStartRequest,
            HELP_TOUR_STATUS_UPDATE_REQUEST: this.onHelpTourStatusUpdateRequest,
            LOG_INITIAL_VIEW_TO_COUNTER: this.onLogInitialViewToCounter,
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.mapInstance.id !== prevProps.mapInstance.id) {
            this.loadMetadata();
        }
    }

    componentDidMount() {
        this.loadMetadata();
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    onMapNewDataThemeApplied() {
        this.loadMetadata();
    }

    // In case the Help Tour is shown we must hide the Change Over Time popup since they both use the Popover
    // component which causes overlapping and z-index cannot be used
    onHelpTourStartRequest = () => {
        this.setState({
            tourShown: true,
        });
    };

    // when the Help Tour is closed show the Change Over Time popover if necessary
    onHelpTourStatusUpdateRequest = () => {
        this.setState({ tourShown: false });
    };

    handleCOTAvailabilityRequest = result => {
        if (result.source !== this) return;

        this.setState({ comparableVariables: result.comparableVariables });
    };

    onVariableSelectionMetadataInfoRetrieved({ metadataSelection, dataDictionaryInfo, source }) {
        if (source === this) {
            const { isChangeOverTimeApplied, variableSelection } = this.props.mapInstance.dataTheme;

            const { variableGuid, percentBaseVariableGuidUserOverride } =
                variableSelection.items[0];

            const { table, survey } = metadataSelection;

            const currentlySelectedVariable = table.getVariableByGuid(variableGuid);
            const availablePercentBaseVariables = buildParentVariableTree(
                Object.values(metadataSelection.table.variables),
                currentlySelectedVariable,
                [],
            );
            const percentBaseVariable =
                table.getVariableByGuid(percentBaseVariableGuidUserOverride) ||
                currentlySelectedVariable.parentVariable;

            let dataFilterDisabled;
            let reportDisabled;
            if (isChangeOverTimeApplied) {
                reportDisabled = ReportBadges.DISABLED_CHANGE_OVER_TIME;
                dataFilterDisabled = DataFilterBadges.DISABLED_CHANGE_OVER_TIME;
            } else if (!survey.reportsAsArray || survey.reportsAsArray.length === 0) {
                reportDisabled = ReportBadges.DISABLED_SURVEY;
            }

            this.setState({
                metadataSelection,
                dataDictionaryInfo,
                availablePercentBaseVariables,
                percentBaseVariable,
                reportDisabled,
                dataFilterDisabled,
            });
        }
    }

    onVisualizationEditorRequest({ mapInstanceId }) {
        if (mapInstanceId === this.props.mapInstance.id) {
            this.setState({ activeMenu: VisualizationEditor.NAME });
        }
    }

    onSummaryLevelChange(eventMap) {
        if (eventMap.source.id === this.props.mapInstance.id) {
            const { mapViewer, mapInstance, mapInstanceIndex } = this.props;
            const activeSummaryLevelLabel =
                mapViewer.activeSummaryLevel && mapViewer.activeSummaryLevel.label;
            const lastSummaryLevelLabel =
                this.state.activeSummaryLevel && this.state.activeSummaryLevel.label;

            if (lastSummaryLevelLabel !== activeSummaryLevelLabel) {
                const eventValue = mapInstance.dataTheme.variableSelection.items
                    .map(v => v.variableGuid)
                    .join('|');
                const counterEventsArray = [
                    {
                        event_type: 'item_request',
                        event_value: `${eventValue}|${activeSummaryLevelLabel}|${mapInstanceIndex}`,
                    },
                    {
                        event_type: 'item_investigation',
                        event_value: `${eventValue}|${activeSummaryLevelLabel}|${mapInstanceIndex}`,
                    },
                ];
                this.emit('COUNTER_LOG_REQUEST', counterEventsArray);
            }
            this.setState({ activeSummaryLevel: eventMap.source.activeSummaryLevel });
            this.forceUpdate();
        }
    }

    onLogInitialViewToCounter(eventMap) {
        if (
            eventMap.mapInstanceId === this.props.mapInstance.id &&
            this.state.initialLoadLogged === false
        ) {
            const { mapViewer, mapInstance, mapInstanceIndex } = this.props;
            const activeSummaryLevelLabel =
                mapViewer.activeSummaryLevel && mapViewer.activeSummaryLevel.label;
            const eventValue = mapInstance.dataTheme.variableSelection.items
                .map(v => v.variableGuid)
                .join('|');
            const counterEventsArray = [
                {
                    event_type: 'item_request',
                    event_value: `${eventValue}|${activeSummaryLevelLabel}|${mapInstanceIndex}`,
                },
                {
                    event_type: 'item_investigation',
                    event_value: `${eventValue}|${activeSummaryLevelLabel}|${mapInstanceIndex}`,
                },
            ];
            this.emit('COUNTER_LOG_REQUEST', counterEventsArray);
            this.setState({ initialLoadLogged: true });
        }
    }

    handleDataInfoToggle = () => {
        if (!this.state.activeMenu) {
            this.emit('COUNTER_LOG_REQUEST', [
                {
                    event_type: 'item_investigation',
                    event_value:
                        this.props.mapInstance.dataTheme.variableSelection.items[0].variableGuid,
                },
            ]);
        }
        this.setState(({ activeMenu }) => ({
            activeMenu: activeMenu === DataDictionary.NAME ? undefined : DataDictionary.NAME,
        }));
    };

    handleCpiToggle = () => {
        this.setState(({ activeMenu }) => ({
            activeMenu: activeMenu === CPICorrection.NAME ? undefined : CPICorrection.NAME,
        }));
    };

    handleYearSelectionToggle = () => {
        const currentActiveMenu = this.state.activeMenu;
        const nextActiveMenu =
            currentActiveMenu === YearSelection.NAME ? undefined : YearSelection.NAME;

        this.setState({ activeMenu: nextActiveMenu });
    };

    handleYearSelectionReset = () => {
        this.setState(({ activeMenu }) => ({
            activeMenu: activeMenu === YearSelection.NAME ? undefined : YearSelection.NAME,
        }));
    };

    handlePctBaseToggle = () => {
        this.setState(({ activeMenu }) => ({
            activeMenu: activeMenu === PercentBase.NAME ? undefined : PercentBase.NAME,
        }));
    };

    handleTriggerClick = menu => {
        this.setState(({ activeMenu }) => ({
            activeMenu: activeMenu === menu ? undefined : menu,
        }));
    };

    handleMenuClose = () => {
        this.setState({ activeMenu: undefined });
    };

    handleMobileMenuCloseRequest = () => {
        this.setState({ isMobileMenuOpen: false });
    };

    handleMobileMenuOpenRequest = () => {
        this.setState({ isMobileMenuOpen: true });
    };

    handleShareClick = () => {
        this.emit('DISPLAY_OVERLAY_REQUEST');
        this.emit('SHARE_SNAPSHOT_LOAD_REQUEST');
        this.setState({ isMobileMenuOpen: false });
    };

    renderEditorTrigger() {
        const { activeMenu } = this.state;
        const { mapInstance } = this.props;
        let visualizationType;

        if (mapInstance.dataTheme.isChangeOverTimeApplied) {
            switch (mapInstance.dataTheme.appliedChangeOverTimeType) {
                case changeOverTimeType.COMPUTE_CHANGE:
                    visualizationType = this.props.intl.formatMessage({
                        id: 'dataBrowser.difference',
                    });
                    break;
                case changeOverTimeType.COMPUTE_CHANGE_PERCENT:
                    visualizationType = this.props.intl.formatMessage({
                        id: 'dataBrowser.percentChange',
                    });
                    break;
                case changeOverTimeType.COMPUTE_CHANGE_COMPOSITION:
                    visualizationType = this.props.intl.formatMessage({
                        id: 'dataBrowser.compositionChange',
                    });
                    break;
            }
        } else {
            switch (mapInstance.dataTheme.visualizationType) {
                case VisualizationTypes.SHADED_AREA:
                    visualizationType = this.props.intl.formatMessage({
                        id: 'dataBrowser.shadedArea',
                    });
                    break;
                case VisualizationTypes.BUBBLES:
                    visualizationType = this.props.intl.formatMessage({
                        id: 'dataBrowser.bubbles',
                    });
                    break;
                case VisualizationTypes.DOT_DENSITY:
                    visualizationType = this.props.intl.formatMessage({
                        id: 'dataBrowser.dotDensity',
                    });
                    break;
                default:
                    visualizationType = this.props.intl.formatMessage({
                        id: 'dataBrowser.unknown',
                    });
            }
        }

        return (
            <Trigger
                active={activeMenu === VisualizationEditor.NAME}
                menu={VisualizationEditor.NAME}
                onClick={this.handleTriggerClick}
                prefix={this.props.intl.formatMessage({ id: 'as' })}
                label={visualizationType}
                tourId={HelpTourTargets.MAP_PANEL_VISUALIZATION}
                overlay={this.props.intl.formatMessage({
                    id: 'dataBrowser.changeVisualizationType',
                })}
            />
        );
    }

    renderActiveMenu() {
        const {
            activeMenu,
            metadataSelection,
            metadata,
            dataDictionaryInfo,
            percentBaseVariable,
            reportDisabled,
            dataFilterDisabled,
            availablePercentBaseVariables,
            comparableVariables,
        } = this.state;

        const { mapInstance, isUserPro } = this.props;
        const { isEmbedded } = this.context;

        let menuContent, menuTitle;
        switch (activeMenu) {
            case SummaryLevelSelector.NAME:
                menuContent = (
                    <SummaryLevelSelector
                        mapInstanceId={mapInstance.id}
                        title={this.props.intl.formatMessage({ id: 'dataBrowser.geographyLevel' })}
                        onChange={isEmbedded ? this.handleMenuClose : undefined}
                    />
                );
                break;
            case VisualizationEditor.NAME:
                menuContent = (
                    <VisualizationEditor isUserPro={isUserPro} mapInstance={mapInstance} />
                );
                break;
            case AdditionalSettings.NAME:
                menuContent = (
                    <AdditionalSettings
                        mapInstance={mapInstance}
                        isUserPro={isUserPro}
                        reportDisabled={reportDisabled}
                        dataFilterDisabled={dataFilterDisabled}
                        survey={metadataSelection.survey}
                    />
                );
                break;
            case DataDictionary.NAME:
                menuContent = (
                    <DataDictionary
                        metadataSelection={metadataSelection}
                        dataDictionaryInfo={dataDictionaryInfo}
                        title={mapInstance.dataTheme.title}
                        variableSelectionItems={mapInstance.dataTheme.variableSelection.items}
                        onClose={this.handleDataInfoToggle}
                    />
                );
                break;
            case CPICorrection.NAME:
                menuContent = (
                    <CPICorrection
                        mapInstanceId={mapInstance.id}
                        currentYear={metadataSelection.table.dollarYear}
                        adjustmentDollarYear={mapInstance.dataTheme.adjustmentDollarYear}
                        onClose={this.handleCpiToggle}
                    />
                );
                break;
            case PercentBase.NAME:
                menuContent = (
                    <PercentBase
                        mapInstanceId={mapInstance.id}
                        variableSelection={mapInstance.dataTheme.variableSelection}
                        percentBaseVariable={percentBaseVariable}
                        availablePercentBaseVariables={availablePercentBaseVariables}
                        onClose={this.handlePctBaseToggle}
                    />
                );
                break;
            case YearSelection.NAME:
                if (metadata) {
                    menuContent = (
                        <YearSelection
                            mapInstance={mapInstance}
                            metadata={metadata}
                            comparableVariables={comparableVariables}
                            onClose={this.handleYearSelectionToggle}
                            onCancel={this.handleYearSelectionReset}
                        />
                    );
                }
                break;
            default: {
                let locationPanelMinimized;
                if (mapInstance.isLocationAnalysisActive) {
                    locationPanelMinimized = (
                        <LocationPanelMinimized
                            mapInstanceId={mapInstance.id}
                            locationAnalysisItem={mapInstance.locationAnalysisItem}
                        />
                    );
                }
                return <div className="flex-it column">{locationPanelMinimized}</div>;
            }
        }

        return (
            <Menu title={menuTitle} onClose={this.handleMenuClose}>
                {menuContent}
            </Menu>
        );
    }

    renderMobileMenu() {
        const { isMobileMenuOpen } = this.state;
        const { mapInstance } = this.props;

        return (
            <MobileMenu
                title={AppConfig.constants.applicationName}
                open={isMobileMenuOpen}
                onCloseRequest={this.handleMobileMenuCloseRequest}
                onOpenRequest={this.handleMobileMenuOpenRequest}
            >
                <MobileMenuItem
                    label={this.props.intl.formatMessage({
                        id: 'dataBrowser.changeGeographyLevel',
                    })}
                    description={this.activeSummaryLevelLabel}
                    icon={<i className="material-icons">public</i>}
                    onClose={this.handleMobileMenuCloseRequest}
                    submenu={
                        <SummaryLevelSelector
                            mapInstanceId={mapInstance.id}
                            onChange={this.handleMobileMenuCloseRequest}
                        />
                    }
                />
                <MobileMenuItem
                    label={this.props.intl.formatMessage({ id: 'share' })}
                    icon={<i className="material-icons">share</i>}
                    onClick={this.handleShareClick}
                />
            </MobileMenu>
        );
    }

    renderMobileLayout() {
        const { mapInstance } = this.props;
        const { metadataSelection } = this.state;

        return (
            <div
                className="map-panel map-panel--embedded"
                ref={c => {
                    this.root = c;
                }}
            >
                <div className="map-panel__main">
                    <div className="map-panel__top-section">
                        <div className="flex-it min-width-0 grow">
                            <div className="flex-it min-width-0 grow column">
                                <div className="flex-it center grow">
                                    <div className="map-panel__title-container map-panel__title">
                                        {mapInstance.dataTheme.title}
                                    </div>
                                    {this.renderMobileMenu()}
                                </div>
                                <div className="flex-it center min-width-0 grow">
                                    <span className="map-panel__geography">
                                        {this.props.intl.formatMessage(
                                            { id: 'byWithSufix' },
                                            { suffix: this.activeSummaryLevelLabel },
                                        )}
                                    </span>
                                    <div className="map-panel__round-separator" />
                                    <span className="map-panel__survey">
                                        {metadataSelection.survey.displayName}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderActions() {
        const { applicationMode } = this.context;
        const { mapInstance } = this.props;
        const { activeMenu } = this.state;

        const { metadataSelection, availablePercentBaseVariables } = this.state;

        const canEditVisualization =
            applicationMode === ApplicationMode.EXPLORE || applicationMode === ApplicationMode.EDIT;
        const enableCpi =
            canEditVisualization &&
            metadataSelection.variables.some(mv => mv.isCurrency) &&
            !mapInstance.dataTheme.isChangeOverTimeApplied;

        const enablePctBase =
            canEditVisualization &&
            !mapInstance.dataTheme.variableSelection.isMultiVariable &&
            availablePercentBaseVariables.length >= 2 &&
            !mapInstance.dataTheme.isChangeOverTimeApplied;


        return (
            <div className="map-panel__actions">
                {enableCpi && (
                    <MenuTrigger
                        label={this.props.intl.formatMessage({ id: 'dataBrowser.cpiCorrection' })}
                        active={activeMenu === CPICorrection.NAME}
                        onClick={this.handleCpiToggle}
                        icon={<i className="material-icons">attach_money</i>}
                    />
                )}
                {enablePctBase && (
                    <MenuTrigger
                        label={this.props.intl.formatMessage({
                            id: 'dataBrowser.variablePercentBase',
                        })}
                        active={activeMenu === PercentBase.NAME}
                        onClick={this.handlePctBaseToggle}
                        icon={<i className="socex-icon-percent font-18" />}
                    />
                )}
            </div>
        );
    }

    render() {
        const { applicationMode, isEmbedded, isMobileDevice, isCondensedLayout } = this.context;
        const { mapInstance, onShowDataBrowser, tourId } = this.props;
        const { metadataSelection, activeMenu, comparableVariables, metadata } = this.state;

        if (!metadataSelection) return null;

        if (isEmbedded && isMobileDevice) {
            return this.renderMobileLayout();
        }

        const { dataTheme } = mapInstance;
        const { title, isChangeOverTimeApplied, appliedChangeOverTimeSurveyTitle } = dataTheme;

        const canEditVisualization =
            applicationMode === ApplicationMode.EXPLORE || applicationMode === ApplicationMode.EDIT;
        const currentVariableItem = mapInstance.dataTheme.variableSelection.items[0];
        const showChangeOverTime =
            canEditVisualization &&
            comparableVariables &&
            comparableVariables.length >= 2 &&
            comparableVariables.find(item => item.surveyName === currentVariableItem.surveyName) &&
            !mapInstance.dataTheme.variableSelection.isMultiVariable;

        let surveyName = metadataSelection.survey.displayName;

        // If change over time is applied, use year range in survey name
        if (isChangeOverTimeApplied) surveyName = appliedChangeOverTimeSurveyTitle;

        let changeVariableButton;
        if (canEditVisualization) {
            changeVariableButton = (
                <Tooltip
                    placement="right"
                    mouseEnterDelay={0.5}
                    mouseLeaveDelay={0}
                    overlay={
                        <span className="light-text">
                            {this.props.intl.formatMessage({
                                id: 'dataBrowser.changeCurrentVariable',
                            })}
                        </span>
                    }
                >
                    <button className="btn-raised margin-left-10" onClick={onShowDataBrowser}>
                        {this.props.intl.formatMessage({ id: 'dataBrowser.changeData' })}
                    </button>
                </Tooltip>
            );
        }

        let changeOverTime;
        if (showChangeOverTime && metadata) {
            changeOverTime = (
                <ChangeOverTime
                    mapInstance={mapInstance}
                    metadata={metadata}
                    comparableVariables={comparableVariables}
                    isYearSelectorMenuActive={activeMenu === YearSelection.NAME}
                    onConfirm={this.handleYearSelectionToggle}
                />
            );
        }

        return (
            <div
                className="map-panel"
                ref={c => {
                    this.root = c;
                }}
                data-tourId={tourId}
            >
                <div className="map-panel__main">
                    <div className="map-panel__top-section">
                        <div className="flex-it min-width-0 grow column">
                            <div className="flex-it center grow">
                                <div className="map-panel__title-container">
                                    <Tooltip
                                        placement="bottom"
                                        mouseEnterDelay={0.2}
                                        mouseLeaveDelay={0}
                                        overlay={<span className="light-text">{title}</span>}
                                    >
                                        <span className="map-panel__title">{title}</span>
                                    </Tooltip>
                                </div>
                                {changeVariableButton}
                            </div>
                            <div className="flex-it center">
                                {this.renderActions()}
                            </div>
                            {changeOverTime}
                        </div>
                    </div>
                    <div className="map-panel__bottom-section">
                        <Trigger
                            active={activeMenu === SummaryLevelSelector.NAME}
                            menu={SummaryLevelSelector.NAME}
                            onClick={this.handleTriggerClick}
                            prefix={this.props.intl.formatMessage({
                                id: 'by',
                            })}
                            label={this.activeSummaryLevelLabel}
                            tourId={HelpTourTargets.MAP_PANEL_GEOGRAPHY}
                            overlay={this.props.intl.formatMessage({
                                id: 'dataBrowser.changeGeographyLevel',
                            })}
                        />
                        <div className="map-panel-trigger__divider" />
                        {canEditVisualization && this.renderEditorTrigger()}
                        <div className="map-panel-trigger__divider" />
                        {canEditVisualization && !isCondensedLayout && (
                            <Trigger
                                active={activeMenu === AdditionalSettings.NAME}
                                menu={AdditionalSettings.NAME}
                                onClick={this.handleTriggerClick}
                                tourId={HelpTourTargets.MAP_PANEL_SETTINGS}
                                overlay={this.props.intl.formatMessage({
                                    id: 'moreOptions',
                                })}
                            >
                                <i className="material-icons">menu</i>
                            </Trigger>
                        )}
                    </div>
                </div>
                <div className="map-panel__panel-section">{this.renderActiveMenu()}</div>
            </div>
        );
    }

    get activeSummaryLevelLabel() {
        const activeSummaryLevel = this.props.mapViewer.activeSummaryLevel;
        let activeSummaryLevelLabel = this.props.intl.formatMessage({
            id: 'dataBrowser.noVisibleDataLayers',
        });
        if (activeSummaryLevel) {
            activeSummaryLevelLabel = activeSummaryLevel.label
                ? activeSummaryLevel.label.replace(
                      `${this.props.intl.formatMessage({ id: 'census' })} `,
                      '',
                  )
                : '';
        }

        return activeSummaryLevelLabel;
    }

    loadMetadata() {
        const { mapInstance } = this.props;

        this.bus.once('CURRENT_METADATA', this.onMetadataLoaded);
        this.emit('CURRENT_METADATA_REQUEST', { source: this });

        this.emit('VARIABLE_SELECTION_METADATA_INFO_REQUEST', {
            source: this,
            dataDictionaryInfo: true,
            mapInstanceId: mapInstance.id,
            variableSelectionItems: mapInstance.dataTheme.variableSelection.items,
        });

        // check if it is possible to allow change over time
        this.emit('CHECK_CHANGE_OVER_TIME_AVAILABILITY', {
            source: this,
            mapInstanceId: mapInstance.id,
        });
    }

    onMetadataLoaded = (metadata, source) => {
        if (source !== this) return;
        this.setState({ metadata });
    };
}

export default injectIntl(MapPanel);
