import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import LegendLayoutSwitcher from './LegendLayoutSwitcher';
import Tooltip from '../Tooltip';
import LegendLauncherIcon from './LegendLauncherIcon';

const TOOLTIP_SETTINGS = { placement: 'top', enterDelay: 0.3, leaveDelay: 0 };

class LegendStyleOptions extends React.Component {
    // if the legend is minimized set the focus on the minimized button so the user can toggle the legend back
    // straight away by pressing enter
    componentDidUpdate() {
        if (this.toggleButton && this.props.legendMinimized) this.toggleButton.focus();
    }

    render() {
        const { currentLayout,
            hasLayouts,
            legendMinimized,
            onSwitchToSimplified,
            onSwitchToDetailed,
            toggleLegend,
            scrolling,
            intl,
        } = this.props;

        const overlay = (
            <span className="light-text">
                {legendMinimized ? intl.formatMessage({ id: 'showHide.show.legend' }) : intl.formatMessage({ id: 'map.styleTheVisualization' }) }
            </span>);

        const optionsClassNames = classNames('visualization-legend__options-button', {
            'visualization-legend__options-button--scrolling': scrolling,
        });

        // LEGEND MINIMIZED
        if (legendMinimized) {
            return (<Tooltip
                {... TOOLTIP_SETTINGS}
                overlay={overlay}
            >
                <button
                    className="visualization-legend__options-button--minimized flex-it center space-around"
                    onClick={toggleLegend}
                    aria-label={intl.formatMessage({ id: 'showHide.show.legend' })}
                    aria-expanded={false}
                    ref={ref => { this.toggleButton = ref; }}
                >
                    <LegendLauncherIcon />
                </button>
            </Tooltip>);
        }
        // LEGEND OPENED
        return (<div className={optionsClassNames}>
            {hasLayouts && <LegendLayoutSwitcher
                currentLayout={currentLayout}
                onSwitchToSimplified={onSwitchToSimplified}
                onSwitchToDetailed={onSwitchToDetailed}
                tooltipSettings={TOOLTIP_SETTINGS}
            />}
            <Tooltip
                {... TOOLTIP_SETTINGS}
                overlay={<span className="light-text">
                    {intl.formatMessage({ id: 'showHide.hide.legend' })}
                </span>}
            >
                <button
                    className="btn-icon"
                    onClick={toggleLegend}
                    aria-label={intl.formatMessage({ id: 'showHide.hide.legend' })}
                    aria-expanded
                    ref={ref => { this.toggleButton = ref; }}
                >
                    <i className="socex-icon-collapse visualization-legend__icon" />
                </button>
            </Tooltip>
        </div>);
    }
}

LegendStyleOptions.propTypes = {
    onSwitchToSimplified: PropTypes.func.isRequired,
    onSwitchToDetailed: PropTypes.func.isRequired,
    toggleLegend: PropTypes.func.isRequired,
    currentLayout: PropTypes.string.isRequired,
    hasLayouts: PropTypes.bool,
    legendMinimized: PropTypes.bool,
    scrolling: PropTypes.bool,
    intl: PropTypes.object.isRequired,
};

LegendStyleOptions.defaultProps = {
    hasLayouts: true,
    legendMinimized: false,
    scrolling: false,
};

export default injectIntl(LegendStyleOptions);
